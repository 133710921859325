import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";

import Header from "../../../Component/Header";
import Menu from "../../../Component/Menu";
import Footer from "../../../Component/Footer";
import { Link, useNavigate } from "react-router-dom";
import TourServices from '../../Invoice/TourServices';
import { currentTenant, currentUser } from "../../../Common/Credentials";

import withRouter from "../../../withRouter";
import moment from "moment";



const MHotel = (props) => {
  const [checked, setChecked] = useState(true);

  
  
  const navigate = useNavigate()
  const [data, setData] = useState({
    id: 0,
    hotelcode: "",
    hotelname: "",
    address: "",
    city: "",
    place:"",
    country: "",
    phoneno: "",
    fax: "",
    email: "",
    hotelchain: "",
    starrating: "",
    createddate:new Date(),
    modifieddate:new Date(),
    tennatid:"",
  });
  

  useEffect(() => {
    if(props.location.state.id !== -1)
    {
      TourServices.getByIdMHotel(props.location.state.id).then((r)=>{
        
      setData({
  
      hotelcode: r.data.hotelcode,
      hotelname: r.data.hotelname,
      address: r.data.address,
      city: r.data.city,
      country: r.data.country,
      phoneno: r.data.phoneno,
      place: r.data.place,
      fax: r.data.fax,
      email: r.data.email,
      hotelchain: r.data.hotelchain,
      starrating: r.data.starrating,
      createddate:r.data.createddate,
      modifieddate:r.data.modifieddate,
      tenantid:r.currentTenant,
      })
    })
    }
  },[])

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const changeHandler = (e) => {
    setData(prevValues => {
      return { ...prevValues, [e.target.name]: e.target.value }
    })
  }

  const AddHandler = (e) => {
    e.preventDefault();
   if(props.location.state.id === -1)
   {

     const sdata = {
      
       hotelcode: data.hotelcode,
       hotelname: data.hotelname,
       address: data.address,
       city: data.city,
       country: data.country,
       phoneno: data.phoneno,
       place: data.place,
       fax: data.fax,
       email: data.email,
       hotelchain: data.hotelchain,
       starrating: data.starrating,
       createddate:data.createddate,
       modifieddate:data.modifieddate,
       tenantid:currentTenant,
     }
     TourServices.addMHotel(sdata).then((r) => {
       console.log(r.data);
     })
     navigate("/hotelMasterList");
   }
   else
   {
    const sdata = {
  
      hotelcode: data.hotelcode,
      hotelname: data.hotelname,
      address: data.address,
      city: data.city,
      country: data.country,
      phoneno: data.phoneno,
      place: data.place,
      fax: data.fax,
      email: data.email,
      hotelchain: data.hotelchain,
      starrating: data.starrating,
      createddate:data.createddate,
      modifieddate:new Date(),
      tenantid:currentTenant,
    }
    TourServices.updateMHotel(sdata,props.location.state.id).then((r) => {
      console.log(r.data);
    })
    navigate("/hotelMasterList");
   }

  }

  return (
    <>
      <Header />
      <Menu />
      <div className="content-wrapper w-100% h-100%">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <span
                as="button"
                className=" text-secondary cursorPointer"
                
              >
                <Link to="/hotelMasterList" style={{color:"grey"}}>❮ Back &nbsp;&nbsp;</Link>
                
              </span>
            <li className="breadcrumb-item text-secondary">
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/masterpage">Tours & Travel </Link>
            </li>
            <li className="breadcrumb-item active">Master Hotel</li>
          </ol>
            <form onSubmit={AddHandler}>
              <div className="card-body p-6">
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Hotel Code"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="hotelcode"
                      value={data.hotelcode}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Hotel Name"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="hotelname"
                      value={data.hotelname}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="w-100 my-1 row">
                  <div className="col-sm-12 col-md-6">
                    <fieldset className="">
                      <TextField
                        fullWidth
                        label="Address"
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        multiline
                        rows={5}
                        maxRows={5}
                        name="address"
                        value={data.address}
                        onChange={changeHandler}
                      />
                    </fieldset>
                  </div>
                  <div className="col-sm-12 col-md-6 d-flex flex-column">
                    <fieldset className="my-1">
                      <TextField
                        fullWidth
                        label="Place"
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        name="place"
                        value={data.place}
                        onChange={changeHandler}
                      />
                    </fieldset>
                    <fieldset className="my-1">
                      <TextField
                        fullWidth
                        label="Phone"
                        type="number"
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        name="phoneno"
                        value={data.phoneno}
                        onChange={changeHandler}
                      />
                    </fieldset>
                    <fieldset className="my-1">
                      <TextField
                        fullWidth
                        label="Fax"
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        name="fax"
                        value={data.fax}
                        onChange={changeHandler}
                      />
                    </fieldset>
                  </div>
                </div>
                <div className=" row my-1">
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      label="Email"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="email"
                      value={data.email}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      label="Hotel Chain"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="hotelchain"
                      value={data.hotelchain}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className=" row my-1">
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="City"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="city"
                      value={data.city}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      required
                      label="Star Rating"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="starrating"
                      value={data.starrating}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12"></div>
                  <div className="col-md-6 col-sm-12 my-2">
                    <span> Country : </span>
                  </div>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-5 col-sm-12">
                    <Checkbox
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <span>Need Confirmation</span>
                  </fieldset>
                </div>
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="my-2">
                      <span>Created By : Jnanasoftware</span>
                    </div>
                 
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className=" my-2">
                      <span>Modified By : {currentUser}</span>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <span>Created Time : {moment(data.createddate).format("DD-MM-YYYY")}</span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <span>Modified Time : {moment(data.modifieddate).format("DD-MM-yyyy")} </span>
                    </div>
                  </div>
                  <div className="btn d-flex justify-context-center">
                    <button className="btn buttonColour btn-sm  "  onClick={AddHandler}>ADD</button>
                  </div>
                </div>
              </div>
            </form>
          
        </div>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(MHotel);
