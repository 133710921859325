import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  thousandsdisplay,
  userid,
  financialyearstart,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {NumericFormat} from "react-number-format";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "date-fns";
import { ThemeProvider } from "@mui/material";
import { createTheme,FormControl,InputLabel,Select,MenuItem } from "@mui/material";
import TransportationServices from "./TransportationServices";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
class TransportationReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportsdata: [],
      filterOptions: [
        { id: 0, name: "Vehicles Report" },
        { id: 1, name: "Drivers Report" },
        { id: 2, name: "Trips By vehicles" },
        { id: 3, name: "Trips By drivers" },
        { id: 4, name: "Trips By Places" },
      ],
      months:['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      tripsbymonth:[],
      tripsbyvehicle:[],
      tripsbydrivers:[],
      vehicledata:[],
      driversdata:[],
      monthsvehicledata:[],
      monthsdriversdata:[],
      loading: false,
      selected: 0,
      fromdate: financialyearstart,
      todate: new Date(),
      selectedmonth:moment().format("MMMM")
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange=(option)=> {
    this.setState({ selected: option });
  }

  handleMonthChange = (e) => {
    this.setState({selectedmonth:e.target.value},()=>{
    this.vehicleData(e.target.value)
    this.DriversData(e.target.value)
    }
    );
  }

  onSubmit() {
    let filterdata = this.state.reportsdata.filter(
      (e) =>
        moment(e.createdAt).format("YYYY-MM-DD") >=
          moment(this.state.fromdate).format("YYYY-MM-DD") &&
        moment(e.createdAt).format("YYYY-MM-DD") <=
          moment(this.state.todate).format("YYYY-MM-DD")
    );
    this.setState({ filterdata: filterdata }, () => {
      this.Placesdata();
    });
  }

  Placesdata = () => {
    this.state.filterdata.map(e=>
      Object.assign(e,{place:e.originname+"-"+e.destinationname})
    )
    const data = this.state.filterdata.reduce(
      (
        dest,
        {
          place,
          indate,
        }
      ) => {
        if (!dest[place]) dest[place] = [];
        dest[place].push({
          place:place,
          indate:indate,
        });
        return dest;
      },
      {}
    );

    Object.entries(data).forEach(([key, value]) => {
      let a = {
        place: key,
        jan:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="01"?1:0)), 0),
        feb:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="02"?1:0)), 0),
        mar:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="03"?1:0)), 0),
        apr:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="04"?1:0)), 0),
        may:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="05"?1:0)), 0),
        jun:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="06"?1:0)), 0),
        jul:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="07"?1:0)), 0),
        aug:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="08"?1:0)), 0),
        sep:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="09"?1:0)), 0),
        oct:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="10"?1:0)), 0),
        nov:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="11"?1:0)), 0),
        dec:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="12"?1:0)), 0),
      };
      this.state.tripsbymonth.push(a);
    });

    this.setState({tripsbymonth:[...this.state.tripsbymonth]})
  };

  VehicleTripsdata = () => {
    const data = this.state.filterdata.reduce(
      (
        dest,
        {
          vehicle,
          indate,
        }
      ) => {
        if (!dest[vehicle]) dest[vehicle] = [];
        dest[vehicle].push({
          vehicle:vehicle,
          indate:indate,
        });
        return dest;
      },
      {}
    );

    Object.entries(data).forEach(([key, value]) => {
      let a = {
        vehicle: key,
        jan:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="01"?1:0)), 0),
        feb:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="02"?1:0)), 0),
        mar:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="03"?1:0)), 0),
        apr:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="04"?1:0)), 0),
        may:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="05"?1:0)), 0),
        jun:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="06"?1:0)), 0),
        jul:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="07"?1:0)), 0),
        aug:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="08"?1:0)), 0),
        sep:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="09"?1:0)), 0),
        oct:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="10"?1:0)), 0),
        nov:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="11"?1:0)), 0),
        dec:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="12"?1:0)), 0),
      };
      this.state.tripsbyvehicle.push(a);
    });

    this.setState({tripsbyvehicle:[...this.state.tripsbyvehicle]})
  }

  DriversTripsdata = () => {
    const data = this.state.filterdata.reduce(
      (
        dest,
        {
          driver1name,
          driver2name,
          indate,
        }
      ) => {
        if (!dest[driver1name]) dest[driver1name] = [];
          dest[driver1name].push({
            driver:driver1name,
            indate:indate,
          });
          if (!dest[driver2name]) dest[driver2name] = [];
          dest[driver2name].push({
            driver:driver2name,
            indate:indate,
          });
        return dest;
      },
      {}
    );

    Object.entries(data).forEach(([key, value]) => {
      let a = {
        driver: key,
        jan:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="01"?1:0)), 0),
        feb:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="02"?1:0)), 0),
        mar:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="03"?1:0)), 0),
        apr:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="04"?1:0)), 0),
        may:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="05"?1:0)), 0),
        jun:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="06"?1:0)), 0),
        jul:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="07"?1:0)), 0),
        aug:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="08"?1:0)), 0),
        sep:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="09"?1:0)), 0),
        oct:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="10"?1:0)), 0),
        nov:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="11"?1:0)), 0),
        dec:value.reduce((a, v) => (a = a + (moment(v.indate).format("MM")==="12"?1:0)), 0),
      };
      this.state.tripsbydrivers.push(a);
    });

    this.setState({tripsbydrivers:[...this.state.tripsbydrivers]})
  }

  vehicleData = (s) => {
    this.setState({vehicledata:[],monthsvehicledata: this.state.filterdata.filter(e=>moment(e.indate).format("MMMM")===s) },()=>{
    // let filterdata = this.state.filterdata.filter(e=>moment(e.indate).format("MMMM")===s)

    const data = this.state.monthsvehicledata.reduce(
      (
        dest,
        {
          vehicle,
          differance,
          millage,
          unloading,
          totalexp,
          totalkms,
        }
      ) => {
        if (!dest[vehicle]) dest[vehicle] = [];
        dest[vehicle].push({
          vehicle:vehicle,
          differance:differance,
          millage:millage,
          unloading:unloading,
          totalexp:totalexp,
          totalkms:totalkms,
        });
        return dest;
      },
      {}
    );
    Object.entries(data).forEach(([key, value]) => {
      let a = {
        vehicle: key,
        trips:value.reduce((a, v) => (a = a + 1), 0),
        differance:value.reduce((a,v)=>(a = a + v.differance),0),
        millage:value.reduce((a,v)=>(a = a + v.millage),0)/value.reduce((a, v) => (a = a + 1), 0),
        unloading:value.reduce((a, v) => (a = a + v.unloading), 0),
        totalkms:value.reduce((a, v) => (a = a + v.totalkms), 0),
        totalexp:value.reduce((a, v) => (a = a + v.totalexp), 0)
      }
      this.state.vehicledata.push(a);
    })
    this.setState({vehicledata:[...this.state.vehicledata]})
  })
  }

  DriversData=(s)=>{
    this.setState({driversdata:[],monthsdriversdata:this.state.filterdata.filter(e=>moment(e.indate).format("MMMM")===s)},()=>{
  
      const data = this.state.monthsdriversdata.reduce(
        (
          dest,
          {
            vehicle,
            driver1name,
            driver2name,
            wages1,
            wages2,
          }
        ) => {
          if (!dest[driver1name]) dest[driver1name] = [];
          dest[driver1name].push({
            driver:driver1name,
            wages:wages1,
          });
          if (!dest[driver2name]) dest[driver2name] = [];
          dest[driver2name].push({
            driver:driver2name,
            wages:wages2,
          });
          return dest;
        },
        {}
      );

      Object.entries(data).forEach(([key, value]) => {
        let a = {
          driver: value.map((e) => e.driver)[0],
          trips:value.reduce((a, v) => (a = a + 1), 0),
          wages:value.reduce((a,v)=>(a = a + v.wages),0),
        }
        this.state.driversdata.push(a);
      })
      this.setState({driversdata:[...this.state.driversdata]})
    })
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    TransportationServices.getAllTrips(currentTenant)
      .then((response) => {
        this.setState(
          {
            reportsdata: response.data,
            filterdata: response.data.filter(
              (e) =>
                moment(e.createdAt).format("YYYY-MM-DD") >=
                  moment(this.state.fromdate).format("YYYY-MM-DD") &&
                moment(e.createdAt).format("YYYY-MM-DD") <=
                  moment(this.state.todate).format("YYYY-MM-DD")
            ),
            loading: false,
          },
          () => {
            this.Placesdata();
            this.VehicleTripsdata()
            this.DriversTripsdata()
            this.vehicleData(moment().format("MMMM"))
            this.DriversData(moment().format("MMMM"))
          }
        );
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

  
  render() {
    const columns = [
      {
        name: "place",
        label: "Place",
        options: { filter: true, sort: true,
        setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(1,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(1,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(2,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(2,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(3,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(3,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(4,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(4,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(5,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(5,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(6,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(6,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(7,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(7,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(8,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(8,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(9,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(9,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(10,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(10,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(11,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(11,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
    ];


    const columnsvehicletrips = [
      {
        name: "vehicle",
        label: "Vehicle",
        options: { filter: true, sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(1,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(1,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(2,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(2,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(3,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(3,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(4,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(4,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(5,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(5,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(6,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(6,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(7,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(7,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(8,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(8,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(9,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(9,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(10,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(10,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(11,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(11,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
    ];

    const columnsdriverstrips = [
      {
        name: "driver",
        label: "Driver",
        options: { filter: true, sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(1,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(1,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(2,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(2,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(3,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(3,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(4,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(4,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(5,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(5,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(6,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(6,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(7,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(7,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(8,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(8,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(9,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(9,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(10,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(10,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: `${moment(financialyearstart).add(11,'M').format("MMM").toLowerCase()}`,
        label: `${moment(financialyearstart).add(11,'M').format("MMM")}`,
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
    ];

    const vehicledatacolumns = [
      {
        name: "vehicle",
        label: "Vehicle",
        options: { filter: true, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  className="textColour cursorpointer"
                  to={{
                    pathname: "/transportationreportdetails"
                   }} state = {{
                      data: this.state.monthsvehicledata.filter(
                        (e) => e.vehicle === value
                      ),
                      // data2: this.state.products[value],
                      item: value,
                      pagename: "Vehicle trip details",
                  }}
                >
                  {value}
                </Link>
              </>
            );
          },  
          setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: "trips",
        label: "Trips",
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: "millage",
        label: "Millage",
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: "differance",
        label: "Shortage/Excess",
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: "totalkms",
        label: "Total Kms",
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: "totalexp",
        label: "Total Exp",
        options: { filter: true, sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }), },
      },
      {
        name: "unloading",
        label: "Unloading (Kgs)",
        options: { filter: true, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
    ];

    const columnsdriversdata= [
      {
        name: "driver",
        label: "Driver",
        options: { filter: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  className="textColour cursorpointer"
                  to={{
                    pathname: "/transportationreportdetails"  }} state = {
                      {
                      data: this.state.monthsdriversdata.filter(
                        (e) => e.driver1name === value || e.driver2name === value
                      ),
                      // data2: this.state.products[value],
                      item: value,
                      pagename: "Driver trip details",
                    }
                  }
                >
                  {value}
                </Link>
              </>
            );
          },  
          sort: true },
      },
      {
        name: "trips",
        label: "Trips",
        options: { filter: true, sort: true },
      },
      {
        name: "wages",
        label: "Wages",
        options: { filter: true, sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }), },
      },
    ]

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/assetmanagement">Home</Link>
              </li>
              <li className="breadcrumb-item active">Transportation Report</li>
            </ol>
            <div className="card-body">
              <div>
                {this.state.loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (  
                <>
                
                  {this.state.filterOptions.map((option) => {
                            return (
                              <label key={option.id}>
                                <div className="form-group col-md-9 text-sm">
                                  <div className="btn-group">
                                    <input
                                      className={
                                        this.state.selected === option.id
                                          ? "btn borderedbutton2"
                                          : "btn hovbuttonColour"
                                      }
                                      type="button"
                                      key={option.id}
                                      onClick={()=>this.onChange(option.id)}
                                      style={{ marginRight: 8, width: 180 }}
                                      value={option.name}
                                      disabled={this.state.selected === option.id}
                                    />
                                  </div>
                                </div>
                              </label>
                            );
                          })}

                          {this.state.selected===0|| this.state.selected===3?
                              <>
                              <br/>
                                    <fieldset className="m-2 p-2">
                                  <FormControl variant="standard" sx={{ sm: 1, minWidth: 250 }}>
                                    <InputLabel id="demo-simple-select-standard-label">
                                      Month
                                    </InputLabel>
                                    <Select
                                    id="demo-simple-select-standard"

                                      value={this.state.selectedmonth}
                                      onChange={this.handleMonthChange}
                                      fullWidth
                                    >
                                      {this.state.months.map(
                                        (option) => {
                                          return (
                                            <MenuItem
                                              key={option}
                                              value={option}
                                            >
                                              {option}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </Select>
                                  </FormControl>
                                  </fieldset>
                              </>  :""}
                          {this.state.selected===0?<>
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Vehicles Report"}
                                data={this.state.vehicledata}
                                columns={vehicledatacolumns}
                                options={options}
                              />
                            </ThemeProvider>
                          </>:''}
                          {this.state.selected === 1?
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Drivers Report"}
                                data={this.state.driversdata}
                                columns={columnsdriversdata}
                                options={options}
                              />
                            </ThemeProvider>
                          :''}
                          {this.state.selected === 2?
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Trips By vehicles"}
                                data={this.state.tripsbyvehicle}
                                columns={columnsvehicletrips}
                                options={options}
                              />
                            </ThemeProvider>
                          :''}
                          {this.state.selected === 3?
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Trips By Drivers"}
                                data={this.state.tripsbydrivers}
                                columns={columnsdriverstrips}
                                options={options}
                              />
                            </ThemeProvider>
                          :''}
                          {this.state.selected===4?
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Trips By Places"}
                                data={this.state.tripsbymonth}
                                columns={columns}
                                options={options}
                              />
                            </ThemeProvider>
                          :''}
                          
                          </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(TransportationReport);
