import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  financialyearend,
  financialyearstart,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  Autocomplete,
  Grid,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { FormikTextField } from "../../CommonComponents/FormField.js";
import ProductionServices from "../ProductionServices.js";
import InventoryService from "../../inventory/InventoryService.js";

const theme = createTheme({
  overrides: {
    MuiTable: {
      root: {
        border: [[1, "solid", "#d3d3d3"]],
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
        textAlign: "center !important",
        alignItems: "center"
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class Transactionreports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filtereddata: [],
      productiondata: [],
      fromdate: financialyearstart,
      todate: new Date(),
      itemlist: [],
      item: "",
      itemid: "",
      showsearchbar: false,
      search: "",
      page: 0,
      rowsPerPage: 10,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    // this.clearfilter = this.clearfilter.bind(this);
  }

  onSubmit = () => {
    let datadao = {
      tenantid: currentTenant,
      startdate: this.state.fromdate,
      enddate: this.state.todate,
      userid: userid,
      dataaccess: "",
      itemid: this.state.itemid,
    };
    ProductionServices.GetProductionStoreTransactionReport(datadao).then((response) =>
      this.setState({
        data: response.data,
        filtereddata: response.data
          // .filter((e) => e.inventorytype !== "Production Store Inventory")
          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
      })
    );
  };

  handleitemname = (value, values) => {
    console.log(values)
    if (values !== "" && typeof values === "object" && values !== null) {
      let datadao = {
        tenantid: currentTenant,
        startdate: financialyearstart,
        enddate: financialyearend,
        userid: userid,
        dataaccess: "",
        itemid: values.id,
      };
      this.setState({
        itemid: values.id,
        item: values.name,
      });
      ProductionServices.GetProductionStoreTransactionReport(datadao).then((response) =>
        this.setState({
          data: response.data,
          filtereddata: response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
        })
      );
    } else {
      this.setState({
        itemid: "",
        item: "",
      });
    }
  };

  handleFromDateChange = (date) => {
    this.setState({
      fromdate: date,
      todate:
        moment(date).diff(this.state.todate, "days") >= 0
          ? this.state.todate
          : date,
    });
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  Search = (val) => {
    this.setState({
      search: val,
      filtereddata: this.state.data.filter(
        (e) =>
          e.itemname.toLowerCase().includes(val.toLowerCase()) ||
          e.custombatchid?.toLowerCase().includes(val.toLowerCase())
      ),
    });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    InventoryService.retriveAllItems(currentTenant).then((response) =>
      this.setState({ itemlist: response.data })
    );
  }

  validate(values) {
    let errors = {};
    if (values.from <= values.to && values.to === false) {
      alert("To date must be greater than From date");
      errors.to = "To date must be greater than From date";
    }
    if (values.from === null) {
      alert("Please Select From date");
      errors.from = "Please Select From date";
    }
    if (!values.to === null) {
      alert("Please Select to date");
      errors.to = "Please Select to date";
    }
    return errors;
  }

  render() {
    const columns = [
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (value) => {
            return moment(value).format("DD.MM.YYYY hh:mm");
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
        {
          name: "custombatchid",
          label: "Batch",
          options: {
            filter: false,
            sort: true,
            setCellProps: () => ({ align: "center" }),
          },
        },
        {
          name: "refid",
          label: "Refid",
          options: {
            filter: false,
            sort: true,
            setCellProps: () => ({ align: "center" }),
          },
        },
      {
        name: "from",
        label: "From",
        options: {
          filter: false,
          sort: true,
          setCellProps: () => ({ align: "center" }),
        },
      },

      {
        name: "to",
        label: "To",
        options: {
          filter: false,
          sort: true,
          setCellProps: () => ({ align: "center" }),
        },
      },
      {
        name: "previousstock",
        label: "Opening",
        options: {
          filter: false,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "inquantity",
        label: "Inward",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <span className="text-success">{value > 0 ? value : 0}</span>
            );
          },
          setCellProps: () => ({ align: "right" }),
        },
      },
      {
        name: "outquantity",
        label: "Outward",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (value) => {
            return <span className="text-danger">{value < 0 ? value : 0}</span>;
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalstock",
        label: "Closing",
        options: {
          filter: false,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      resizableColumns: true,
    };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>
              <li className="breadcrumb-item active">
                Stock Transaction Report
              </li>
            </ol>
            <div className="card-body">
              <div className="text-center">
                {this.state.loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <Formik
                    initialValues={{
                      from: this.state.fromdate,
                      to: this.state.todate,
                    }}
                    onSubmit={this.onSubmit}
                    validateOnChange={false}
                    validate={this.validate}
                    enableReinitialize={true}
                  >
                    {(values) => (
                      <Form autoComplete="off" className="row">
                        <fieldset className="col-md-3">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="item"
                            options={this.state.itemlist}
                            getOptionLabel={(option) => option.name}
                            value={values.item ? undefined : undefined}
                            onChange={this.handleitemname}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control"
                                type="text"
                                name="name"
                                value={this.state.item}
                                label={`Select Item (${this.state.item})`}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>
                        <fieldset className="col-md-12 p-2" />
                        {this.state.itemid === "" ||
                        this.state.itemid === null ? (
                          ""
                        ) : (
                          <>
                            {/* <fieldset>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid container justifyContent="space-around">
                                  <DatePicker
                                    variant="inline"
                                    id="date-picker-dialog"
                                    label="From Date"
                                    name="from"
                                    format="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    value={this.state.fromdate}
                                    onChange={this.handleFromDateChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                      />
                                    )}
                                    autoOk={true}
                                  />
                                </Grid>
                              </LocalizationProvider>
                            </fieldset>

                            <fieldset style={{ marginLeft: "30px" }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid container justifyContent="space-around">
                                  <DatePicker
                                    variant="inline"
                                    id="date-picker-dialog"
                                    label="To Date"
                                    name="to"
                                    format="dd/MM/yyyy"
                                    minDate={this.state.fromdate}
                                    value={this.state.todate}
                                    onChange={this.handleToDateChange}
                                    maxDate={new Date()}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                      />
                                    )}
                                    autoOk={true}
                                  />
                                </Grid>
                              </LocalizationProvider>
                            </fieldset>
                            <fieldset className="form-group col-md-1">
                              <button
                                className="btn btn-sm hovbuttonColour mt-3"
                                type="submit"
                              >
                                <i className="fas fa-filter" />
                              </button>
                            </fieldset> */}

                            <fieldset className="col-md-12">
                              <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              id="dirstock"
                              title={"Production Ledger/Stock Transactions"}
                              data={this.state.filtereddata}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                            </fieldset>
                          </>
                        )}
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Transactionreports);
