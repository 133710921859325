import React, { useEffect, useState } from "react";
import Header from "../../Component/Header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../Component/Footer";
import Menu from "../../Component/Menu";
import TourMaster from "./TourMaster";
import Ticket from "./Ticket";
// import Hotel from "./Hotel";
// import Car from "./Car";
import Services from "./Services";
import ARSharing from "./ARSharing";
import Adjustment from "./Adjustment";
import Swal from "sweetalert2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Stock from "./Stock";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import HotelORCar from "./HotelORCar";
import Hotel from "./Hotel";
import TourServices from "./TourServices";
import { currentTenant, currentUser } from "../../Common/Credentials";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CustomerService from "../../Masters/Customers/CustomerService";
import withRouter from "../../withRouter";
import moment from "moment";

function createData(
  Sl_No,
  Type,
  Ticket_No,
  Pax_Or_Guest_Name,
  Sector,
  Cost,
  Output_VAT,
  Profit,
  Selling_Price,
  Status
) {
  return {
    Sl_No,
    Type,
    Ticket_No,
    Pax_Or_Guest_Name,
    Sector,
    Cost,
    Output_VAT,
    Profit,
    Selling_Price,
    Status,
  };
}

const rows = [createData("")];

const Invoices = () => {
  const [selected, setSelected] = useState("Ticket");
  const [isHide, setIsHide] = useState(true);
  const [tableView, setTableView] = useState(false);
  const [ticketdata, setTicketData] = useState([]);
  const [hoteldata, setHotelData] = useState([]);
  const [cardata, setCarData] = useState([]);

  const [masterdata, setMasterData] = useState([]);
  const [servicedata, setServiceData] = useState([]);
  const [Adjustmentdata, setAdjustmentData] = useState([]);
  const nevigate = useNavigate();

  const [age, setAge] = useState("None");
  const [docdate, setDocdate] = useState(new Date());
  const [docno, setDocno] = useState("");
  const [dept, setDept] = useState("");
  const [fileno, setFileno] = useState("");
  const [costcenter, setCostcenter] = useState("");
  const [advancereceipt, setAdvancereceipt] = useState("");
  const [againstdocno, setAgainstdocno] = useState("");
  const [reference, setReference] = useState("");
  const [customercode, setCustomercode] = useState("");
  const [customername, setCustomername] = useState("");
  const [customerroe, setCustomerroe] = useState("");
  const [supcustomercode, setSupcustomercode] = useState("");
  const [supcustomername, setSupcustomername] = useState("");
  const [originalamt, setOriginalamt] = useState("");
  const [salesmancode, setSalesmancode] = useState("");
  const [salesmanname, setSalesmanname] = useState("");
  const [unmatchedamt, setUnmatchedamt] = useState("");
  const [narration, setNarration] = useState("");
  const [invoicefootnote, setInvoicefootnote] = useState("");
  const [preprintedpapper, setPreprintedpapper] = useState(true);
  const [createdby, setCreatedby] = useState("");
  const [updatedby, setUpdatedby] = useState("");
  const [masterstore, setMasterstore] = useState([]);
  const [custData, setCustData] = useState([]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChangeCentercost = (e) => {
    setCostcenter(e.target.value);
  };
  const chnagehandlerPerprinted = (e) => {
    setPreprintedpapper(e.target.checked);
  };
  const chnageHandlerDocdate = (e) => {
    setDocdate(moment(new Date(e)).format("DD/MM/YYYY"));
  };

  const changeHandlerCustomerName = (value, values) => {
    console.log(values);
    if (
      typeof values !== "object" ||
      values === undefined ||
      values === "" ||
      values === null
    ) {
      setCustomername("");
      setCustomercode("");
    } else {
      setCustomername(values.companyname);
      setCustomercode(values.id);
    }
  };

  const changeHandlerSubCustomerName = (value, values) => {
    console.log(values);
    if (
      typeof values !== "object" ||
      values === undefined ||
      values === "" ||
      values === null
    ) {
      setSupcustomername("");
      setSupcustomercode("");
    } else {
      setSupcustomername(values.companyname);
      setSupcustomercode(values.id);
    }
  };

  const getData = (data) => {
    console.log(data);
    setTicketData(data);
  };

  const getMasterData = (data) => {
    console.log(data);
    setMasterData(data, "Hie Master ");
  };

  const getHoteldata = (data) => {
    console.log(data) + "Hie Hotel";
    setHotelData(data);
  };

  const getCarsdata = (data) => {
    console.log(data + "Hie Cars data");
    setCarData(data);
  };

  const getServiceData = (data) => {
    console.log(data) + "Hie Service";
    setServiceData(data);
  };
  const getAdjustmentData = (data) => {
    console.log(data) + "Hie Adjustment";
    setAdjustmentData(data);
  };

  const SaveData = (e) => {
    e.preventDefault();
    let data = {
      docno: docno,
      docdate: docdate,
      fileno: fileno,
      costcenter: costcenter,
      department: dept,
      advancerecipt: advancereceipt,
      againstdocno: againstdocno,
      reference: reference,
      customer: customername,
      custcode: customercode,
      customerroe: customerroe,
      subcustomer: supcustomername,
      subcustcode: supcustomercode,
      originalamt: originalamt,
      unmatchedamt: unmatchedamt,
      narration: narration,
      invoicefootnote: invoicefootnote,
      createdby: currentUser,
      updatedby: currentUser,
      createddate: new Date(),
      updateddate: new Date(),
      tenantid: currentTenant,
      tickets: ticketdata,
      hotels: hoteldata,
      cars: cardata,
      services: servicedata,
      adjustment: Adjustmentdata,
      status: "Pending",
    };
    // if(servicedata.length===0 || ticketdata.size ===0 || hoteldata.length===0 || cardata.length===0 )
    Swal.fire({
      title: "Create Invoice ?",
      text: "Are you sure you want to save?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        TourServices.addInvoiceRequest(data)
          .then((response) => {
            Swal.fire({
              title: "Invoice Added to invoice successfuly",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(()=>nevigate("/salestravelinvoice"));
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });

    console.log(data, "Invoice Data");

    console.log(data, "Sended Data");
    // TourServices.addAdjustmentInvoiceRequestData(data).then(r=>{
    //   console.log(r.data,"Finall Response Data");

    // });
    // nevigate("/invoicemainpage");
  };

  useEffect(() => {
    CustomerService.retriveAllCustomers(currentTenant).then((res) => {
      setCustData(res.data);
    });
  }, []);

  const savehandler = (e) => {
    let d = {
      docno: docno,
      docdate: docdate,
      fileno: fileno,
      costcenter: costcenter,
      customer: customername,
      custcode: customercode,
      department: dept,
      advancerecipt: advancereceipt,
      againstdocno: againstdocno,
      reference: reference,
      subcustomer: supcustomername,
      subcustcode: supcustomercode,
      originalamt: originalamt,
      unmatchedamt: unmatchedamt,
      narration: narration,
      customerroe: customerroe,
      invoicefootnote: invoicefootnote,
      createdby: createdby,
      updatedby: updatedby,
      tenantid: currentTenant,
    };
    setMasterstore(d);
    console.log(d, "master data");

    props.funcMaster(d);
    Swal.fire({
      title: "Add to Invoice ?",
      text: "Are you sure you want to save?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    })
      .then((response) => {
        Swal.fire({
          title: "Added Successfuly",
          text: ".",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
    nevigate("/salestravelinvoice");
    // nevigate("/invoices");
  };

  return (
    <>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <span as="button" className=" text-secondary cursorPointer">
                <Link to="/salestravelinvoice" style={{ color: "grey" }}>
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </span>
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/salestravelinvoice">Invoice</Link>
            </li>
            <li className="breadcrumb-item active">{selected}</li>
          </ol>

          <div className="card-body">
            <form className="form-row">
              <div className="d-flex col-md-12 mb-2">
                <button
                type="button"
                  className="btn btn-sm deletebutton mr-2  text-center col-md-1 col-sm-12"
                  onClick={() => nevigate("/salestravelinvoice")}
                >
                  Cancel
                </button>
                <button
                type="button"
                  className="btn btn-sm hovbuttonColour mr-2 col-md-1 col-sm-12"
                  onClick={(e) => SaveData(e)}
                >
                  Save
                </button>
                </div>
                <fieldset className="col-md-12 m-0 p-0">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor: "#e8ecef" }}
                >
                  <Typography>Masters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="card p-4">
                    <div className="row ">
                      <fieldset className="col-md-3 col-sm-12">
                        <TextField
                          fullWidth
                          label="Document No."
                          id="outlined-size-small"
                          size="small"
                          variant="outlined"
                          value={docno}
                          onChange={(e) => setDocno(e.target.value)}
                        />
                      </fieldset>
                      <div className="col-md-4 col-sm-12 ">
                        {/* <LocalizationProvider  dateAdapter={AdapterDayjs}>
                      <DatePicker  slotProps={{ textField: { size: 'small' } }} label="Document Date" />
                  </LocalizationProvider> */}

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <Grid container>
                            <DatePicker
                              variant="inline"
                              id="date-picker-dialog"
                              label="Document Date "
                              // format="dd/MM/yyyy"
                              value={docdate}
                              onChange={chnageHandlerDocdate}
                              renderInput={(params) => (
                                <TextField {...params} size="small" />
                              )}
                              autoOk={true}
                            />
                          </Grid>
                        </LocalizationProvider>
                      </div>
                      <fieldset className="col-md-4 col-sm-12 my-1">
                        <TextField
                          label="File Number"
                          id="outlined-size-small"
                          size="small"
                          variant="outlined"
                          value={fileno}
                          onChange={(e) => setFileno(e.target.value)}
                        />
                      </fieldset>
                    </div>

                    <div className="row my-2">
                      <fieldset className="col-md-3 col-sm-12">
                        <FormControl
                          sx={{ minWidth: 120 }}
                          size="small"
                          fullWidth
                        >
                          <InputLabel id="demo-select-small">
                            Cost Centre
                          </InputLabel>
                          <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={costcenter}
                            label="Cost Centre"
                            onChange={handleChangeCentercost}
                            size="small"
                            variant="outlined"
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="HEAD OFFICE">HEAD OFFICE</MenuItem>
                            <MenuItem value="SOHAR">SOHAR</MenuItem>
                            <MenuItem value="SALALAH">SALALAH</MenuItem>
                            <MenuItem value="VFS">VFS</MenuItem>
                            <MenuItem value="DUQM">DUQM</MenuItem>
                          </Select>
                        </FormControl>
                      </fieldset>

                      <fieldset className="col-md-4 col-sm-12">
                        <TextField
                          label="Department"
                          id="outlined-size-small"
                          size="small"
                          variant="outlined"
                          value={dept}
                          onChange={(e) => setDept(e.target.value)}
                        />
                      </fieldset>
                      <fieldset className="col-md-4 col-sm-12">
                        <TextField
                          label="Advance Receipt"
                          id="outlined-size-small"
                          size="small"
                          variant="outlined"
                          value={advancereceipt}
                          onChange={(e) => setAdvancereceipt(e.target.value)}
                        />
                      </fieldset>
                    </div>

                    <div className="row my-2">
                      <fieldset className="col-md-3 col-sm-12">
                        <TextField
                          fullWidth
                          label="Against Doc. No."
                          id="outlined-size-small"
                          size="small"
                          variant="outlined"
                          value={againstdocno}
                          onChange={(e) => setAgainstdocno(e.target.value)}
                        />
                      </fieldset>
                      <div className="col-md-4 col-sm-12">
                        <FormLabel>0.00</FormLabel>
                      </div>
                      <fieldset className="col-md-4 col-sm-12">
                        <TextField
                          label="Reference"
                          id="outlined-size-small"
                          size="small"
                          variant="outlined"
                          value={reference}
                          onChange={(e) => setReference(e.target.value)}
                        />
                      </fieldset>
                    </div>

                    <div className="row my-2">
                      <fieldset className="col-md-4 col-sm-12">
                        <Autocomplete
                          freeSolo
                          id="free-solo-2-demo"
                          disableClearable
                          options={custData}
                          getOptionLabel={(option) => option.companyname}
                          onChange={changeHandlerCustomerName}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Customer Name"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              size="small"
                              value={customername}
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="col-md-3 col-sm-12">
                        <TextField
                          fullWidth
                          label="Customer Code"
                          id="outlined-size-small"
                          size="small"
                          variant="outlined"
                          value={customercode}
                        />
                      </fieldset>
                      <fieldset className="col-md-4 col-sm-12">
                        <TextField
                          label="Customer ROE"
                          id="outlined-size-small"
                          size="small"
                          variant="outlined"
                          value={customerroe}
                          onChange={(e) => setCustomerroe(e.target.value)}
                        />
                      </fieldset>
                    </div>

                    <div className="row my-2">
                      <fieldset className="col-md-3 col-sm-12">
                        <Autocomplete
                          freeSolo
                          id="free-solo-2-demo"
                          disableClearable
                          options={custData}
                          getOptionLabel={(option) => option.companyname}
                          onChange={changeHandlerSubCustomerName}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sub Customer Name"
                              InputProps={{
                                ...params.InputProps,
                                type: "search",
                              }}
                              size="small"
                              value={supcustomername}
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="col-md-4 col-sm-12">
                        <TextField
                          fullWidth="true"
                          label="Sub Customer Code"
                          id="outlined-size-small"
                          size="small"
                          variant="outlined"
                          value={supcustomercode}
                        />
                      </fieldset>
                      <fieldset className="col-md-4 col-sm-12">
                        <TextField
                          label="Original Amount In OMR"
                          id="outlined-size-small"
                          size="small"
                          variant="outlined"
                          value={originalamt}
                          onChange={(e) => setOriginalamt(e.target.value)}
                        />
                      </fieldset>
                    </div>

                    <div className="row my-2">
                      <fieldset className="col-md-3 col-sm-12">
                        <TextField
                          fullWidth
                          label="Sales man"
                          id="outlined-size-small"
                          size="small"
                          variant="outlined"
                          value={salesmancode}
                          onChange={(e) => setSalesmancode(e.target.value)}
                        />
                      </fieldset>
                      <fieldset className="col-md-4 col-sm-12">
                        <TextField
                          label="Sales man Code"
                          id="outlined-size-small"
                          size="small"
                          variant="outlined"
                          value={salesmanname}
                          onChange={(e) => setSalesmanname(e.target.value)}
                        />
                      </fieldset>
                      <fieldset className="col-md-4 col-sm-12">
                        <TextField
                          label="Unmatched Amt In OMR"
                          id="outlined-size-small"
                          size="small"
                          variant="outlined"
                          value={unmatchedamt}
                          onChange={(e) => setUnmatchedamt(e.target.value)}
                        />
                      </fieldset>
                    </div>

                    <div className="row my-1">
                      <fieldset className="col-5 my-2 ">
                        <TextField
                          id="outlined-multiline-flexible"
                          label="Narration"
                          variant="outlined"
                          multiline
                          fullWidth
                          size="small"
                          maxRows={4}
                          value={narration}
                          onChange={(e) => setNarration(e.target.value)}
                        />
                      </fieldset>
                    </div>

                    <div className="row ">
                      <fieldset className="col-5 my-2 ">
                        <TextField
                          id="outlined-multiline-flexible"
                          label="Invoice Footnote"
                          variant="outlined"
                          multiline
                          fullWidth
                          size="small"
                          maxRows={4}
                          value={invoicefootnote}
                          onChange={(e) => setInvoicefootnote(e.target.value)}
                        />
                      </fieldset>
                      <fieldset className="col-md-3 col-sm-12">
                        <FormLabel>Pre Printed Paper</FormLabel>
                        <Checkbox
                          value={preprintedpapper}
                          onChange={chnagehandlerPerprinted}
                          defaultChecked
                        />
                      </fieldset>
                      <div className="col-2 my-2">
                        <FormLabel>Created By : </FormLabel>
                      </div>
                      <div className="col-1 my-2">
                        <FormLabel>{createdby}</FormLabel>
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-md-3 col-sm-12 my-2">
                        <FormLabel>Modified By : </FormLabel>
                      </div>
                      <div className="col-md-3 col-sm-12 my-2">
                        <FormLabel>{updatedby}</FormLabel>
                      </div>
                      {/* <div style={{ float: "left" }}>
                        <button
                          className="btn hovbuttonColour btn-sm "
                          onClick={savehandler}
                        >
                          {" "}
                          Save{" "}
                        </button>
                      </div> */}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
              </fieldset>

              <div
                className=" col-sm-12 text-center d-flex justify-content-between pt-3"
              >
                {/* <span
                  id="masterbtn"
                  className={`btn btn-sm ${
                    selected === "tourmaster" ? "hovbuttonColour w-100" : ""
                  } `}
                  onClick={() => {
                    setSelected("tourmaster");
                    setIsHide(true);
                    setTableView(true);
                  }}
                >
                  {" "}
                  Master{" "}
                </span> */}
                <span
                  id="masterbtn"
                  className={`btn btn-sm w-100 ${
                    selected === "Ticket" ? "hovbuttonColour" : ""
                  } `}
                  onClick={() => {
                    setSelected("Ticket");
                    setIsHide(true);
                    setTableView(true);
                  }}
                >
                  {" "}
                  Ticket{" "}
                </span>

                <span
                  id="masterbtn"
                  className={`btn btn-sm w-100 ${
                    selected === "hotel/car" ? "hovbuttonColour" : ""
                  } `}
                  onClick={() => {
                    setSelected("hotel/car");
                    setIsHide(true);
                    setTableView(true);
                  }}
                >
                  {" "}
                  Hotel / Car{" "}
                </span>
                <span
                  id="masterbtn"
                  className={`btn btn-sm w-100 ${
                    selected === "Service" ? "hovbuttonColour" : ""
                  } `}
                  onClick={() => {
                    setSelected("Service");
                    setIsHide(true);
                    setTableView(true);
                  }}
                >
                  {" "}
                  Service{" "}
                </span>
                <span
                  id="masterbtn"
                  className={`btn btn-sm w-100 ${
                    selected === "Arsharing" ? "hovbuttonColour" : ""
                  } `}
                  onClick={() => {
                    setSelected("Arsharing");
                    setIsHide(true);
                    setTableView(true);
                  }}
                >
                  {" "}
                  A/R Sharing{" "}
                </span>
                <span
                  id="masterbtn"
                  className={`btn btn-sm w-100 ${
                    selected === "Adjustment" ? "hovbuttonColour" : ""
                  } `}
                  onClick={() => {
                    setSelected("Adjustment");
                    setIsHide(true);
                    setTableView(true);
                  }}
                >
                  {" "}
                  Adjustment{" "}
                </span>
              </div>
            </form>
            {/* {isHide && selected === "tourmaster" ? <TourMaster funcMaster={getMasterData}/> : ""} */}
            {isHide && selected === "Ticket" ? <Ticket func={getData} /> : ""}
            {/* {isHide && selected === "Hotel" ? <Hotel funcHotel={getHoteldata} /> : ""} */}

            {isHide && selected === "hotel/car" ? (
              <HotelORCar funcHotel={getHoteldata} funcCar={getCarsdata} />
            ) : (
              ""
            )}
            {isHide && selected === "Service" ? (
              <Services funcService={getServiceData} />
            ) : (
              ""
            )}
            {/* {isHide && selected === "Stock" ? <Stock /> : ""} */}
            {isHide && selected === "Arsharing" ? <ARSharing /> : ""}
            {isHide && selected === "Adjustment" ? (
              <Adjustment funcAdjusment={getAdjustmentData} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default withRouter(Invoices)
