import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import { currentTenant, userid } from '../../Common/Credentials.js';
import { spin } from '../../Common/NewLoader.jsx';
import { appmasterid } from '../../Common/Permissions.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import TaxServices from './TaxServices.js';

import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import MUIDataTable from 'mui-datatables';


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});


class Tax extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        message:'',
        taxes:[],
        loading:false,

    }
  }



  deleteTax = (id) =>{
   TaxServices.deleteTax(id)
   .then(()=>{
     this.setState({message:"Tax Deleted Successfully"})
    this.refreshTaxes()
   }
  )
   .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
},()=>this.refreshTaxes())
   
}

  
refreshTaxes = () =>{
  TaxServices.getTaxes(currentTenant)
    .then(
      response => {
          this.setState({taxes:response.data})
        this.setState({loading:false})
        }
      )    
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
  }

    componentDidMount () {

      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }      
      this.setState({loading:true})

      this.refreshTaxes();

     }

    render() {
        const columns = [
            {
              name: "id",
              label: "Id",
              options: { filter: true, sort: true, display:false,viewColumns:false ,sortOrder: "asc" },
            },
            {
              name: "tax",
              label: "TAX",
              options: { filter: true, sort: true },
            },
            {
              name: "Delete",
              options: {
                filter: false,
                sort: false,
                empty: true,
                display: appmasterid === "2" || appmasterid === "3" ? true : false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    tableMeta.rowData[3] === "ALL"?'':
                    <span
                      as="button"
                      data-tip
                      data-for="delete"
                      className="fa fa-trash text-danger"
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure you wish to delete Tax ${tableMeta.rowData.find(
                              (e) => e
                            )}?`
                          )
                        )
                          this.deleteTax(tableMeta.rowData.find((e) => e));
                      }}
                    ></span>
                  );
                },
              },
            },
          ]
    
          const options = {
            filterType: "checkbox",
            selectableRows: "none",
            customToolbar: () => {
              return (
                <>
                  {appmasterid === "2" || appmasterid === "3" ? (
                    <Link
                      as="button"
                      className="float-right btn btn-sm m-0 p-0"
                      to={{pathname: '/addtax'}} state ={ {id: -1,pagename:'Add Tax'}}
                    >
                      <CustomToolbar />
                    </Link>
                  ) : (
                    ""
                  )}
                </>
              );
            },
          };
        return (
            <div>
        <Header />
                <Menu/>
                <div className="content-wrapper">
                    <div className="card">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item text-secondary">
                                <Link to="/masters">Home</Link>
                            </li>
                            
                            <li className="breadcrumb-item active">Taxes</li>
                            </ol>
                        {/* <div className="text-center text-success text-uppercase">{this.state.message}</div> */}
                            <section className="content">
                        <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                            
                            {/* /.card */}
                                
                                <div className="card-body">
                                
                                <div>
                                    {/* {this.state.loading === true ?<div className="text-center">{spin}</div> :  */}
                                    <ThemeProvider theme={theme}>
                                      <MUIDataTable
                                      className="table-sm px-3"
                                      title={"Taxes"}
                                      data={this.state.taxes}
                                      columns={columns}
                                      options={options}
                                    />
                                    </ThemeProvider>
                                    {/* } */}
                                    </div> 
                                </div>
                                {/* /.card-body */}
                            {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>


                        
                    </div>
                                
                    </div>
                </div>    
          
        )
       
    }
    
}
export default withRouter(Tax)