import { Form, Formik } from "formik";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import {NumericFormat} from "react-number-format";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { thousandsdisplay, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";

class PurchaseReportsDetails2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.location.state.data,
      item: this.props.location.state.item,
      pagename: this.props.location.state.pagename,
    };
  }
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
  }
  render() {
    // const column1 = [
    //     {
    //         name: "customer",
    //         label: "Customer",
    //         options: { filter: false, sort: true,sortOrder : 'asc',
    //         customBodyRender: (value, tableMeta, updateValue) => {
    //           return (<>
    //             <Link as="button" data-tip data-for="view details" className="text-info"
    //             to={{pathname: "/productsalesbycustomer",state:{data:this.state.data2.filter(e=>e.customer===value),item:value,pagename:"Sales by Customer"}}}>
    //               {value}
    //             </Link>
    //             //<Tooltip id="view details" place="top" effect="solid">View Details</Tooltip>
    //             </>
    //           );
    //           }
    //       },
    //       },
    //       {
    //         name: "custid",
    //         label: "Customer ID",
    //         options: { filter: false, sort: true,sortOrder : 'asc',},
    //       },
    //     {
    //       name: "quantity",
    //       label: "Quantity",
    //       options: { filter: true, sort: true },
    //     },
    //     {
    //       name: "totalprice",
    //       label: "Total Price",
    //       options: { filter: false, sort: true,
    //         customBodyRender:(value)=>{
    //           return(<NumericFormat className="pr-3" displayType={'text'} value={value} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
    //         },
    //         setCellProps: value => ({ style: { textAlign:'right'}}),
    //     }},
    //     {
    //       name: "totalpricewtax",
    //       label: "Total(Inc.Tax)",
    //       options: { filter: false, sort: true,
    //         customBodyRender:(value)=>{
    //           return(<NumericFormat className="pr-3" displayType={'text'} value={value} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
    //         },
    //         setCellProps: value => ({ style: { textAlign:'right'}}),
    //     }},
    //   ]

    const column = [
      {
        name: "itemname",
        label: "Item",
        options: { filter: false, sort: true, sortOrder: "asc" },
      },
      // {
      //   name: "itemid",
      //   label: "Item ID",
      //   options: { filter: false, sort: true,sortOrder : 'asc',},
      // },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "totalprice",
        label: "Total Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },

      {
        name: "totalpricewtax",
        label: "Total(Inc.Tax)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY hh:mm");
          },
        },
      },
    ];

    const column2 = [
      {
        name: "supplier",
        label: "Supplier",
        options: { filter: false, sort: true, sortOrder: "asc" },
      },
      // {
      //   name: "supid",
      //   label: "Supplier Id",
      //   options: { filter: false, sort: true,sortOrder : 'asc',},
      // },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "totalprice",
        label: "Total Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },

      {
        name: "totalpricewtax",
        label: "Total(Inc.Tax)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY hh:mm");
          },
        },
      },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li className="text-secondary">
              <span
                as="button"
                className="float-left text-secondary "
                onClick={() => this.props.navigate(-1)}
              >
               ❮ Back &nbsp;&nbsp;
              </span>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchasereport">Purchase Reports</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>

            <div className="card-body">
              <div className="text-center">
                {this.state.loading === true ? spin : ""}
              </div>
              <Formik initialValues={{}} enableReinitialize={true}>
                {() => (
                  <Form autoComplete="off">
                    {this.state.pagename ===
                    "Purchase of Perticular Item by Supplier" ? (
                      <MUIDataTable
                        className="table-sm px-3"
                        title={`${this.state.item}`}
                        data={this.state.data}
                        columns={column}
                        options={options}
                      />
                    ) : (
                      ""
                    )}

                    {this.state.pagename ===
                    "Purchase of Perticular Supplier by Item" ? (
                      <MUIDataTable
                        className="table-sm px-3"
                        title={`${this.state.item}`}
                        data={this.state.data}
                        columns={column2}
                        options={options}
                      />
                    ) : (
                      ""
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(PurchaseReportsDetails2);
