import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import { currentTenant, userid } from '../../Common/Credentials.js';
import { spin } from '../../Common/NewLoader.jsx';
import { appmasterid } from '../../Common/Permissions.js';
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import DepartmentServices from './DepartmentServices.js';

import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});



class DepartmentDataTable extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        message:'',
        department:[],
        loading:false,
    }
    // this.refreshCustomer=this.refreshCustomer.bind(this)
    this.deleteDepartment=this.deleteDepartment.bind(this)
  }



  deleteDepartment(id){
   DepartmentServices.deleteDepartment(id)
   .then(()=>{
    this.setState({ message: "Department Deleted Successfully",loading: true})
    this.refreshDepartment()
    }
   )
   .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})
}

refreshDepartment(){
    DepartmentServices.getDepartments(currentTenant)
    .then(
        response => {
            this.setState({department:response.data})
            this.setState({loading:false})
        }
      )   
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })

    //   WarehouseServices.getWarehouses(currentTenant)
    //   .then(
    //     response => {
    //         this.setState({department:response.data})
    //       this.setState({loading:false})
    //       }
    //     )   
  }

    componentDidMount () {

      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }
      
      this.setState({loading:true})

      this.refreshDepartment();

     }

    render() {

      const columns = [
        {
          name: "id",
          label: "Id",
          options: { filter: false, sort: false,sortOrder: "asc",display:false,viewColumns:false },
        },
        {
            name: "name",
            label: "Department",
            options: { filter: true, sort: true },
          },
        {
          name: "warehouse",
          label: "Org Unit",
          options: { filter: true, sort: true },
        },
        {
          name: "Delete",
          options: {
            filter: false,
            sort: false,
            empty: true,
            display: appmasterid === "2" || appmasterid === "3" ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <span
                  as="button"
                  data-tip
                  data-for="delete"
                  className="fa fa-trash text-danger"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you wish to delete Department ${tableMeta.rowData.find(
                          (e) => e
                        )}?`
                      )
                    )
                      this.deleteDepartment(tableMeta.rowData.find((e) => e));
                  }}
                ></span>
              );
            },
          },
        },
      ]

      const options = {
        filterType: "checkbox",
        selectableRows: "none",
        customToolbar: () => {
          return (
            <>
              {appmasterid === "2" || appmasterid === "3" ? (
                <Link
                  as="button"
                  className="float-right btn btn-sm m-0 p-0"
                  to={{pathname: "/newdepartment"}}
                    state={{ id: -1, name:'New Department'}}
                >
                  <CustomToolbar />
                </Link>
              ) : (
                ""
              )}
            </>
          );
        },
      };
        return (
            <div>
              <Header/>
                <Menu/>
                
                <div className="content-wrapper">
                    <div className="card card-info">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item text-secondary">
                                <Link to="/masters">Home</Link>
                            </li>
                            
                            <li className="breadcrumb-item active">Department</li>
                            </ol>
                        {/* <div className="text-center text-success text-uppercase">{this.state.message}</div> */}
                            <section className="content">
                        <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                            
                            {/* /.card */}
                            <div className="card">
                                
                                <div className="card-body">
                                    <div>{this.state.loading === true ?<div className="text-center">{spin}</div> :
                                    <ThemeProvider theme={theme}> 
                                      <MUIDataTable
                                      className="table-sm px-3"
                                      title={"Department"}
                                      data={this.state.department}
                                      columns={columns}
                                      options={options}
                                    />
                                    </ThemeProvider>
                                    }</div>                              
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>


                        
                    </div>
                                
                    </div>
                </div>    
          
        )
       
    }
    
}
export default withRouter(DepartmentDataTable)