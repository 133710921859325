import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

export let currentTenant

class TransporterServices{
    getTransporters(currentTenant) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/gettransporters/${currentTenant}`)
     }
     addTransporter(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addtransporter`,data)
     }
     deletetransporter(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletetransporter/${id}`)
     }
}
export default new TransporterServices()