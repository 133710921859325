import React, { Component } from "react";
import { Formik, Form } from "formik";
import withRouter from "../withRouter.js";
import { FormikTextField } from '../CommonComponents/FormField.js';
import { Autocomplete, Backdrop, CircularProgress } from "@mui/material";
import {
  jname,
  jtype,
  personal,
  real,
  uom,
  appgrn,
  aedata,
  expcombdata,
  totalcombdata,
} from "./Journal";
import {NumericFormat} from "react-number-format";
import {
  ccountry,
  currentTenant,
  currentUser,
  financialyearend,
  financialyearstart,
  prefix1,
  taxtype,
  thousandsdisplay,
  transactionFrom,
  userid,
} from "../Common/Credentials";
import {Box, Button, FormLabel, LinearProgress, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography,Grid,FormControlLabel } from "@mui/material";
import InventoryService from "../inventory/InventoryService";
import { inventory, purchase } from "../Common/Products";
import downloadfiles from "../Common/upload/downloadfiles";
import WarehouseServices from "../Masters/Warehouse/WarehouseServices";
import { Modal, Spinner } from "react-bootstrap";
import CategoryServices from "../Masters/Category/CategoryServices";
import UploadFilesService from "../Common/upload/UploadFilesService";
import FileServices from "../Common/upload/FileServices";
import moment from "moment";
import Swal from "sweetalert2";
import AuthenticationService from "../Authentication/AuthenticationService";
import IOSSwitch from "../CommonComponents/IOSSwitch.js";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import isThisHour from "date-fns/isThisHour";


let jvtype = 0; // Type of Jv 0 for Purchase
let entrytype=0; // Purchase for Orginal or Rectification
let grnsupp, grnpo,grnsuppid,grnsupppid,gsttype,cusdata,custompoid;
let aesup,aeamount,aefile,aedate,aesupid,aepath,supname,supid,podata//invdate
let curmonth,year,day
let whouseid,whousename,filteritemlist=[],grnitemlist

let doctype = "supplierinvoices",scnservicename
// const BorderLinearProgress = LinearProgress;
//styled(() => ({
  // root: {height: 15,borderRadius: 5,},
  // colorPrimary: {backgroundColor: "#EEEEEE",},
  // bar: {borderRadius: 5,backgroundColor: '#1a90ff',},
 // }))(LinearProgress);

const Checkbox = props => (
  <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" {...props} />
)

const getGrnTaxTotal = (value) => {
  let total = 0;
value.forEach(vtotal => {
 total += vtotal;
});
 return total;
};
class JournalPurchase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selgrn: "",
      selectedgrnid: "",
      purinvtype: [
       
        // { id: 1, name: "Direct Purchase Inventory" },
        { id: 2, name: "Advance" },
        { id: 0, name: "From GRN/SCN" },
       // { id: 3, name: "NON Inventory " },
      ],
      gsttype:[{id:1,name:"Within State"},{id:2,name:"Inter State"}],
      cusreq:[{ id: 0, name: "No" },{ id: 1, name: "Yes" }],
      cuschecked:0,
      jname: "",
      jtype: "",
      message: "",
      debselval: "",
      credselval:"",
      wh: [],
      SDdate:false,
      fed:'',
      itemstock: [],
      uom: [],
      invchecked: '',
      displaychecked:false,GRNchecked:false,
      currentFile: undefined,progress: 0,isError: false,fileInfos: [],selectedFiles: undefined,partyid:'',
      grnvaldata:[],datagrn:[],grnitemlist:[],grnitems:[],addedGRN:[],addedGRNamt:'',grnid:[],CategoryList:[],filteritemlist:[],
      scnvaldata:[],datascn:[],scnitemlist:[],scnitems:[],addedSCN:[],addedSCNamt:'',scnid:[],milestone:[],sadata:[],
      gstchecked:"",
      selectedPO:'',SelectedAePoid:'',aepoamount:'',
      aeval:'',
      grntaxval:[],grnunittot:[],grnwithtot:[],invfile:[],invpath:[],itempototal:[],grntaxper:"",scntaxper:"",
      scntaxval:[],scnunittot:[],scnwithtot:[],servicepototal:[],
      invoicepath:[],invoicefile:[],invoicedate:[],invoiceamt:[],
      proinvoicedate:[],proinvoicefile:[],proinvoicepath:[],proinvoiceamt:[],
      quotedate:[],quotefile:[],quotepath:[],
      modal:false,grandservicetotal:0,servicetotal:0,servicetax:0,servicetaxamount:0,openadjusted:0,dcadjusted:0,advanceadjusted:0,
      loader:false,loader1:true,invitemdata:[],jvinsertiontype:0
    };
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.download = this.download.bind(this);
    this.RadioSelect = this.RadioSelect.bind(this);
    this.onPoChange = this.onPoChange.bind(this);
    this.addSelect=this.addSelect.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.selectFile=this.selectFile.bind(this);this.upload=this.upload.bind(this);this.addValues=this.addValues.bind(this)

  }
  handleClose(){
    this.setState({modal:false})
  }
  handleShow(){
    this.setState({modal:true})
  }
  handleCategory = (value, values) => {
  
    //alert(values.category.id)
    if(value!==undefined && values!==undefined && values.length!==0){
      filteritemlist = this.state.itemstock.filter(e=>e.catid === values.category.id)
      if(!value && !values){
    this.setState({
      catid: values.category.id,
      category: value,
      filteritemlist : this.state.itemstock.filter(e=>e.catid === values.category.id)
    });
   
  }
  }
  };
  onCusChange(option){this.setState({ cuschecked: option.id });}

  onDebBlur(values) {
    this.setState({ debselval: values.debit });
  }
  onSubmit(values) {
    this.setState({loader:true})
    let gst, gstpercent, taxval1;
    let cgst, cgstpercent, taxval2;
    let igst, igstpercent, itaxval;
    let vat, vatpercent, vatval,poid
    let gpdata=[], tax,proformainvoiceid,finalinvoiceid
    let invitemled,invitemid,invitemname

    let a,b,c,d=0,e=0
    a=this.state.openadjusted
    if(this.state.grnunittot.length!==0){
      b=getGrnTaxTotal(this.state.grntaxval)
    }else{
      b=getGrnTaxTotal(this.state.scntaxval)
    }
    if(this.state.grnunittot.length!==0){
      c=getGrnTaxTotal(this.state.grnunittot)
      e=getGrnTaxTotal(this.state.itempototal)
    }else{
     c=getGrnTaxTotal(this.state.scnunittot)
     e=getGrnTaxTotal(this.state.servicepototal)
    }
   
    if((a-b)-c===0){
      d=1
    }else if((a-b)-c<0){
      d=2
    }else{
      d=3
    }

    // let val1 = expcombdata.filter(
    //   (e) => (e.type === 13) & (e.tenantId === currentTenant)
    // );
    // let val2 = val1.map((e) => {
    //   return e.type === 13 && e.tenantId === currentTenant ? e.id : "";
    // });
    if(appgrn.porformainvoiceid!==undefined){
      proformainvoiceid=appgrn.porformainvoiceid
    }
    if(appgrn.finalinvoiceid!==undefined){
      finalinvoiceid=appgrn.finalinvoiceid
    }
    let expense 
    if(values.debit.rule === 2){
      expense = values.debit.id;
    }

    if (values.taxvalue1 !== undefined) {
      gst = values.sgst;
      gstpercent = values.taxpercent1;
      taxval1 = values.taxvalue1;
    }
    if (values.taxvalue2 !== undefined) {
      cgst = values.cgst;
      cgstpercent = values.taxpercent2;
      taxval2 = values.taxvalue2;
    }
    if (values.itaxvalue1 !== undefined) {
      igst = values.igst;
      igstpercent = values.itaxpercent1;
      itaxval = values.itaxvalue1;
    }
    if (values.gtaxvalue1 !== undefined) {
      vat = values.vat;
      vatpercent = values.gtaxpercent1;
      vatval = values.gtaxvalue1;
    }

    if (ccountry==="India" && jtype === 2 && gsttype === 1) {
      tax = values.taxvalue1 * 2;
    } else if (ccountry==="India" && jtype === 2 && gsttype === 2) {
      tax = values.itaxvalue1;
    } else {
      tax = values.gtaxvalue1;
    }

    if (this.state.invchecked === 0 ) {
      gpdata=this.state.grnitems
      supname=grnsupp.toLocaleString();
      supid=grnsuppid
      poid=grnpo.toLocaleString()
      custompoid=custompoid.toLocaleString()
      } else if(this.state.invchecked === 1){
        let tax1
        if(ccountry==="India"){
          if (gsttype===1){
            tax1=gstpercent+cgstpercent
          } else if(gsttype===2){
            tax1=igstpercent
          }
          supname=values.credit.pname
          supid=values.credit.pid
      }else{
          tax1=vatpercent
        }
       
    }

    // if(this.state.invchecked===0 && this.state.selectedPO!==''){
     
    //   invitemled=values.invitem.id
    //   invitemid=values.invitem.itemid
    //   invitemname=values.invitem.itemname


    //   //invitem,invitemid
    // }
    let jv 
    let filename= this.state.fileInfos.map((e)=>e.name)
    let invoicepath,invoicefile
    let sdebitid,sdebitname,sdebitamt
    let uom
    
    if(values.sdebit!==undefined){
      sdebitid=values.sdebit.id
      sdebitname=values.sdebit.name
      sdebitamt=values.sdebitval
    }
    if(values.uom!==undefined){
      uom=values.uom.name
    }
    if (this.state.invchecked === 0 ){
      invoicepath=this.state.invoicepath.toLocaleString()!==''?this.state.invoicepath.find(((e,i)=>e.valueOf(i))):''
      invoicefile=this.state.invoicefile.toLocaleString()!==''?this.state.invoicefile.find(((e,i)=>e.valueOf(i))):''
    }else if(this.state.invchecked === 1){
      invoicepath=prefix1 + "/" + doctype + "/" + this.state.partyid + "/" + year + "/" + curmonth + "/" + day
      invoicefile=filename.toLocaleString()
    }
    if (this.state.invchecked === 0 || this.state.invchecked === 1 ) {
      jv= {
      debitid: values.debit.id,debitname: values.debit.name,debitamt: values.debitval,
      sdebitid:sdebitid,sdebitname:sdebitname,sdebitamt:sdebitamt,
      gst: gst,gstpercent: gstpercent,taxval1: taxval1,cgst: cgst,cgstpercent: cgstpercent,
      taxval2: taxval2,igst: igst,igstpercent: igstpercent,itaxval: itaxval,vat: vat,
      vatpercent: vatpercent,vatval: vatval,creditid: values.credit.id,creditname: values.credit.name,
      creditamt: values.creditvalue,gstin: values.credit.gstin,pan: values.credit.pan,
      contactnumber: values.credit.contactnumber,email: values.credit.email,narration: values.narration,
      jvtype: jvtype,createdBy: userid,tenantId: currentTenant,entrytype: entrytype,whid: values.whouse,gwhid:whouseid,gwhname:whousename,
      finalinvoiceid:finalinvoiceid,proformainvoiceid:proformainvoiceid,
      items: values.items,qty: values.itemsqty,uom: uom,uprice: values.uprice,expense: expense,
      gpdata: gpdata,gsttype:gsttype,supplier:supname,poid:poid,custompoid:custompoid,supplierid:supid,sadata:this.state.sadata,
      selgrn:this.state.grnid,poadvance:aeamount.toLocaleString(),
      invoicepath:invoicepath,
      invoicefile:invoicefile,
      invoicedate:this.state.invoicedate.toLocaleString()!==''?this.state.invoicedate.find(((e,i)=>e.valueOf(i))):'',
      invoiceamt:this.state.invoiceamt.toLocaleString()!==''?this.state.invoiceamt.find(((e,i)=>e.valueOf(i))):'',
      proinvoicedate:this.state.proinvoicedate.toLocaleString()!==''?this.state.proinvoicedate.find(((e,i)=>e.valueOf(i))):'',
      proinvoicefile:this.state.proinvoicefile.toLocaleString()!==''?this.state.proinvoicefile.find(((e,i)=>e.valueOf(i))):'',
      proinvoicepath:this.state.proinvoicepath.toLocaleString()!==''?this.state.proinvoicepath.find(((e,i)=>e.valueOf(i))):'',
      proinvoiceamt:this.state.proinvoiceamt.toLocaleString()!==''?this.state.proinvoiceamt.find(((e,i)=>e.valueOf(i))):'',
      quotedate:this.state.quotedate.toLocaleString()!==''?this.state.quotedate.find(((e,i)=>e.valueOf(i))):'',
      quotefile:this.state.quotefile.toLocaleString()!==''?this.state.quotefile.find(((e,i)=>e.valueOf(i))):'',
      quotepath:this.state.quotepath.toLocaleString()!==''?this.state.quotepath.find(((e,i)=>e.valueOf(i))):'',
      poamount:this.state.poamount!==undefined?this.state.poamount.find(((e,i)=>e.valueOf(i))):'',
      purchasetype:this.state.invchecked,//invitemled:invitemled,invitemid:invitemid,invitemname:invitemname,
      date:this.state.fed!==''?this.state.fed:null,openadjusted:this.state.openadjusted,dcadjusted:this.state.dcadjusted,
      jvinsertiontype:this.state.jvinsertiontype,advnum:d
    };
  }else if (this.state.invchecked === 2){
    poid=this.state.SelectedAePoid
    //custompoid=custompoid
    supname=values.credit.pname
    supid=values.credit.pid
    jv={
      debitid: values.debit.id,debitname: values.debit.name,debitamt: values.debitval,
      creditid: values.credit.id,creditname: values.credit.name,expense: expense,
      creditamt: values.creditvalue,gstin: values.credit.gstin,pan: values.credit.pan,
      contactnumber: values.credit.contactnumber,email: values.credit.email,narration: values.narration,
      createdBy: userid,poid:poid,custompoid:custompoid,supplier:supname,supplierid:supid,poadvance:values.creditvalue,
      jvtype:jvtype,entrytype:entrytype,tenantId: currentTenant,purchasetype:this.state.invchecked,
      poamount:this.state.aepoamount!==undefined?this.state.aepoamount.find(((e,i)=>e.valueOf(i))):'',jvinsertiontype:this.state.jvinsertiontype,
      date:this.state.fed!==''?this.state.fed:null,advnum:d
    }
  }else{
    supname=values.credit.pname
    supid=values.credit.pid
    jv={
      debitid: values.debit.id,debitname: values.debit.name,debitamt: values.debitval,
      gst: gst,gstpercent: gstpercent,taxval1: taxval1,cgst: cgst,cgstpercent: cgstpercent,
      taxval2: taxval2,igst: igst,igstpercent: igstpercent,itaxval: itaxval,vat: vat,
      vatpercent: vatpercent,vatval: vatval,creditid: values.credit.id,creditname: values.credit.name,
      creditamt: values.creditvalue,gstin: values.credit.gstin,pan: values.credit.pan,
      contactnumber: values.credit.contactnumber,email: values.credit.email,narration: values.narration,
      createdBy: userid,supplier:supname,supplierid:supid,
      jvtype:jvtype,entrytype:entrytype,tenantId: currentTenant,purchasetype:this.state.invchecked,jvinsertiontype:this.state.jvinsertiontype,
      date:this.state.fed!==''?this.state.fed:null,advnum:d
    }

  }
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      AuthenticationService.logout(currentUser);

      //this.props.navigate(`/`);
    } else {
      if(this.state.invchecked===1 && filename.length===0){
        alert("Please add supplier invoice")
      }else{
        this.props.navigate({pathname:`/journalconfirm`}, {state:jv});
      }
    }
  }

  componentDidMount() {
    this.setState({gstchecked:""})
    year = new Date().getFullYear()
      day= new Date().getDate()
      let mn = new Date().getMonth()+1
      curmonth= moment(mn,'M').format('MMMM')
    this.setState({ jname: jname });
    this.setState({ jtype: jtype});
    grnsupp = "";
    grnpo = "";

    WarehouseServices.getWarehouses(currentTenant).then((response) => {
      this.setState({ wh: response.data });
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })

    CategoryServices.getCategory(currentTenant).then((response) =>
    this.setState({CategoryList: response.data})
    )

    InventoryService.getItemNames(currentTenant).then((response) => {
      this.setState({ itemstock: response.data },()=>this.setState({loader1:false}));
    });

    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      AuthenticationService.logout(currentUser);
      //this.props.navigate(`/`);
    }
   
   

  }
  componentDidUpdate() {
    if (jtype !== this.state.jtype) {
      this.setState({ jtype: jtype });
    }
  
  }
  componentWillUnmount() {
    this.render();
  }
  onInvChange(option) {

   // alert(this.state.invchecked)
   // this.setState({ invchecked:''})
    this.setState({ invchecked: option.id},()=>this.invBehav())
   
  }
  invBehav(){
    if(this.state.invchecked===0 || this.state.invchecked===1){
     // gsttype=1
     // this.setState({gstchecked:1})
    }else{
      gsttype=''
      this.setState({gstchecked:''})
    }
    if(this.state.invchecked!==2){
      this.setState({aeval:''})
      aesup=''
    aeamount=''
    aefile=''
    aedate=''
    aesupid=''
    aepath=''
    }

  }
  handleSDDate = () => {
    this.setState({ SDdate: this.state.SDdate === true ? false : true })
  }
  handleFsdDateChange=(date)=>{
    if(date!==null){
    this.setState({ fsd: date.$d});
    this.setState({
      fed: moment(date.$d).format("yyyy-MM-DD HH:mm:ss"),
    });
  }else{
    this.setState({ SDdate:false})
  }
  };

//   onGstChange(gstoption){
//     this.setState({gstchecked: gstoption.id});
//     gsttype=gstoption.id
//  }
  validate(values) {
    let errors = {};
    let deb = values.debtotal;
    let cre = values.creditvalue;

    if (!values.debit && values.debit === undefined) {
      errors.debit = "Accounts is required";
    }
    if(this.state.gstchecked === 1){
    if (!values.debitval) {
      errors.debitval = "Value Cannot be Blank";
    } 
  //else if (deb !== cre) {
  //     errors.creditvalue = "Value Cannot be be different from debit Value";
  //   }
   }
    if (this.state.invchecked === 0) {
        if(grnsupppid!==grnsuppid){
          errors.credit ="Supplier Cannot be different from GRN   " }
     }
  //   //  if(!values.grn){
  //   //   errors.grn="GRN is Required"
  //   //  }
    if(this.state.gstchecked === 1){
            if (ccountry==="India" && jtype === 2 && gsttype === 1) {
              if (!values.sgst) {
                errors.sgst = "Tax Cannot be Blank";
              }

              if (!values.cgst) {
                errors.cgst = "Tax Cannot be Blank";
              }
              if (values.sgst === values.cgst) {
                errors.cgst = "Tax Cannot be Same";
                errors.sgst = "Tax Cannot be Same";
              }
      if (this.state.invchecked===2 && !values.taxpercent1) {
        errors.taxpercent1 = "Tax percent Required";
      }
    } else if (ccountry==="India" && jtype === 2 && gsttype === 2) {
      if (!values.igst) {
        errors.igst = "Tax Cannot be Blank";
      }
      if (this.state.invchecked===2 && !values.itaxpercent1) {
        errors.itaxpercent1 = "Tax percent Required";
      }
    } else if (ccountry!=="India" && jtype === 2) {
      if (!values.tax) {
        errors.tax = "Tax Cannot be Blank";
      }
      if (this.state.invchecked===2 && !values.gtaxpercent1) {
        errors.gtaxpercent1 = "Tax percent Required";
      }
    }
  }
    if (!values.credit || values.credit === "") {
      errors.credit = "Accounts is required";
    }
  //   // if (!values.creditvalue) {
  //   //   errors.creditvalue = "Value Cannot be Blank";
  //   // } else if (deb !== cre) {
  //   //   errors.creditvalue = "Value Cannot be be different from debit Value";
  //   // }

  //   // if(this.state.invchecked===0 && this.state.selectedPO!==''){
  //   //   if (!values.invitem) {
  //   //     errors.invitem = "Invoice item is required";
  //   //   }
  //   // }
  //   // if (!values.expense) {
  //   //   errors.expense = "Expense is required";
  //   // }
    if (!values.narration) {
      errors.narration = "Narration is required";
    }

    return errors;
  }
  calc(values) {
    let tot;
    tot = values.debitval + values.taxvalue;
    return tot;
  }
  onChange(option) {
    this.setState({ checked: option.id });
  }
  RadioSelect(e) {
    this.setState({ selgrn: e });
  }
  setGloOpenAdjust(gdata){
    let a =gdata.map((e) => e.openadjusted)
    this.setState({openadjusted:getGrnTaxTotal(a)})
  }
  onPoChange(value){
    this.setState({grnitems:[],grandservicetotal:null,servicetotal:null,servicetax:null,servicetaxamount:null})
    this.setState({jvinsertiontype:9})
    if (value !== undefined || value !== "" || value !== null || values.po!==undefined) {
      let grandservicetotal,servicetotal,servicetax,servicetaxamount
      podata=appgrn.filter((e) => e.custompoid===value.custompoid)
      grnpo=podata.map((e)=>e.id)
      custompoid=podata.map((e)=>e.custompoid)
      podata.map((e)=>{return grandservicetotal=e.grandservicetotal})
      podata.map((e)=>{return servicetotal=e.serviceamount})
      podata.map((e)=>{return servicetax=e.servicetax})
      podata.map((e)=>{return whouseid=e.whid})
      podata.map((e)=>{return whousename=e.warehouse})
     
      let gtype ,taxtype
      podata.map((e)=>{return taxtype=e.taxtype})
      podata.map((e)=>{return gtype=e.gsttype})
      if(taxtype==="GST" &&  gtype==="INTRASTATE"){
        this.setState({gstchecked: 1})
        gsttype=1;
      }else if(taxtype==="GST" &&  gtype==="INTERSTATE"){
        this.setState({gstchecked: 2})
        gsttype=2;
      }else{
        gsttype=3;
      }



      servicetaxamount=servicetotal*servicetax/100
      this.setState({grandservicetotal:grandservicetotal,servicetotal:servicetotal,
        servicetax:servicetax,servicetaxamount:servicetaxamount})
      this.setState({selectedPO:grnpo.toLocaleString()})
      grnsupp = podata.map((e) => e.supplier);
      grnsuppid=podata.map((e) => e.supplierid).toLocaleString();
      this.setState({invoicepath:podata.map((e) => e.finalinvoicepath),
                    invoicefile:podata.map((e) => e.finalinvoicefile),
                    invoicedate:podata.map((e) => e.finalinvoicepath),
                    invoiceamt:podata.map((e) => e.invoiceamountupdated),
                    proinvoicedate:podata.map((e) => {return e.proformainvoicedate}),
                    proinvoicefile:podata.map((e) => e.proformainvoicefile),
                    proinvoicepath:podata.map((e) => e.proformainvoicepath),
                    proinvoiceamt:podata.map((e) => e.invoiceamount),
                    quotedate:podata.map((e) => e.quotationdate),
                    quotefile:podata.map((e) => e.quotationfile),
                    quotepath:podata.map((e) => e.quotationpath),
                    poamount:podata.map((e) => e.totalwtax),
                    })
      // invdate=podata.map((e) => e.invoicedate)
      let scnitemlist,gdata,sdata,milestones
      if(podata!==undefined){
        podata.map((e)=> {return gdata=e.grnitemslist})
        this.setGloOpenAdjust(gdata)
        grnitemlist=gdata
        // grnitemlist.map((e) => {return this.setState({openadjusted:e.openadjusted},()=>alert(this.state.openadjusted))})
        podata.map((e)=> {return sdata=e.scndao})
        scnitemlist=sdata
        // console.log(gdata,sdata)
      }
     
      // console.log(grnitemlist)
      // if(gdata!==undefined){  
      // grndata=podata.map((e)=>e.grnitemslist)
      // if(grndata!==undefined){
      // grndata.map((e)=>{return grnitemlist=(e.map((cur)=>cur))})
      // }
      // }else if(sdata!==undefined){
      // scndata =podata.map((e)=>{return e.scndao})
      // if(scndata!==undefined){
      // scndata.map((e)=>{return scnitemlist=(e.map((cur)=>cur))})
      // scnitemlist.map((e)=>{return milestones=e.milestone})
      // console.log(scnitemlist)
      // }
      //}
      if (grnitemlist!==undefined  ){
      if (grnitemlist!==null  ){
        if(grnitemlist.length!==0){
      this.setState({scnid:[]})
     
      this.setState({grnitemlist:grnitemlist,grnitems:grnitemlist},()=>this.addValues())
      let a=[...new Set(grnitemlist.map((e) =>{return e.grnid})),];
      let z=[...new Set(grnitemlist.map((e) =>{return e.openadjusted})),];
      let b
      if(z!==undefined){
        b=true
      }else{
        b=false
      }
      // let d=[]
      // if(this.state.grnitems!==undefined || this.state.grnitems!==null){
      //   if(this.state.grnitems.length!==0){
      a.map((f)=>
        this.setState({grnid:[]},()=>
         this.state.grnid.push({
            name:f,
          isChecked:b}))
        )
      //}
      }
      } else {
        this.setState({grnid:[],scnid:[]})
        this.setState({grnitems:[],scnitems:[]},()=>this.addValues())
    }
    }
    if (scnitemlist!==undefined ){
      if (scnitemlist!==null ){
      if(scnitemlist.length!==0 ){
      this.setState({grnid:[]})
      scnitemlist.map((e)=>{return milestones=e.milestone})
      scnservicename=scnitemlist.map((e) =>{return e.servicename})
      this.setState({scnitemlist:scnitemlist,scnitems:scnitemlist,milestone:milestones},()=>this.addValuesSCN())
      let a1=[...new Set(scnitemlist.map((e) =>{return e.scnid})),];
      let b1=true
      // let d1=[]
      a1.map((f1)=>
        this.setState({scnid:[]},()=>
         this.state.scnid.push({
            name:f1,
          isChecked:b1}))
        )
    }}

  }
    else {
      this.setState({grnid:[],scnid:[]})
      this.setState({grnitems:[],scnitems:[]},()=>this.addValuesSCN())
    }      
    }
  }

  addValues(){
    let gta,gutp,gtp,itempotot,gt,dcadj,advadjust,openadjust

      let invitemdata=[]
  //   if(expcombdata!==undefined){
  //      this.state.grnitems.map((f)=>{return invitemdata = totalcombdata.filter(
  //       (e) => e.itemid === f.itemid)});
  //     ,()=>{this.state.invitemdata.length!==0 && this.state.invitemdata.map((e)=>e.id!==null) ?"":this.ledStatus()})
  //  }

 


   this.state.grnitems.map((a)=>{
   totalcombdata.map((b)=>{
   if(a.itemid===b.itemid){
     invitemdata.push(b)
  }
  })
  })

 this.setState({invitemdata:invitemdata},()=>this.ledStatus())

  //  console.log(invitemdata)
  //  if(invitemdata.length===0){
    
  //  }
   // {a===undefined?alert("cannot continue"):""}
    if(this.state.grnitems!==undefined){
      if(this.state.grnitems!==null){
      if(this.state.grnitems.length!==0){
      this.setState({scntaxval:[],scnunittot:[],scnwithtot:[],servicepototal:[]})
      gta=this.state.grnitems.map((e)=>e.price*e.quantity*e.tax/100) 
      gutp=this.state.grnitems.map((e)=>e.price*e.quantity)
      gtp=this.state.grnitems.map((e)=>e.price*e.quantity*e.tax/100+e.price*e.quantity)
      gt=this.state.grnitems.map((e)=>e.tax)
      this.state.grnitems.map((e)=>{return advadjust=e.advanceadjusted})
      itempotot=podata.map((e)=>e.totalwtax)
      podata.map((e)=>{dcadj=e.creditadjusted})
      if(this.state.grnid.map(e=>e.isChecked)){
        let aa=this.state.grnitems.map((e)=>e.openadjusted) 

       this.state.grnitems.map((e)=>{return openadjust=getGrnTaxTotal(aa)})

      //podata.map((e)=>{return openadjust=e.openadjusted}) 
      }else{
      this.state.grnitems.map((e)=>{return openadjust=e.openadjusted}) 
      } 

  
     // this.state.openadjusted- getGrnTaxTotal(this.state.grntaxval)===
      //this.state.grntaxval.length!==0?getGrnTaxTotal(this.state.grntaxval):getGrnTaxTotal(this.state.scntaxval)!==
      // getGrnTaxTotal(this.state.grnunittot)
  //this.state.grnunittot.length!==0?getGrnTaxTotal(this.state.grnunittot):getGrnTaxTotal(this.state.scnunittot)?


      this.setState({advanceadjusted:advadjust,grntaxval:gta,dcadjusted:dcadj,openadjusted:openadjust,grnunittot:gutp,grnwithtot:gtp,itempototal:itempotot,grntaxper:gt!==undefined?gt.find((e)=>e):""})
      }else{
        this.setState({grntaxval:[],grnunittot:[],grnwithtot:[],})
      }
    }
      }
  }

ledStatus(){

  // console.log(this.state.invitemdata)
  // alert(this.state.grnitems.length + " AND " + this.state.invitemdata.length )

  if(this.state.grnitems.length !==  this.state.invitemdata.length){
    Swal.fire({
      title: "Please Check !!!",
      text: "Item Ledger Missing, Cannot Continue. Please Create Item Ledger " + "\n"
       + "\n"+" Hint :  Asset/Liability --> New + --> Item Ledgers",
       icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#152f5f",
    }).then((result) => {
      if (result.value === true) {
        this.props.navigate({pathname:`/newledgers`});
  }});
    // alert("Item Ledger Missing, Cannot Continue. Please Create Item Ledger")
    //  this.props.navigate({pathname:`/newledgers`});
  }
 

}

  addValuesSCN(){
    let sta,sutp,stp,spoprice,st
      if(this.state.scnitems!==undefined ){
        if(this.state.scnitems!==null ){
        if(this.state.scnitems.length!==0){
        this.setState({grntaxval:[],grnunittot:[],grnwithtot:[],itempototal:[]})
        sta=this.state.scnitems.map((e)=>e.scntaxamount) 
        sutp=this.state.scnitems.map((e)=>e.scntotalprice)
        stp=this.state.scnitems.map((e)=>e.scntotalpricewtax)
        st=this.state.scnitems.map((e)=>e.servicetax)
        spoprice=this.state.scnitems.map((e)=>e.servicetotalwtax)
        this.setState({scntaxval:sta,scnunittot:sutp,scnwithtot:stp,servicepototal:spoprice,scntaxper:st!==undefined?st.find((e)=>e):""})

        // if(this.state.scnitemlist.length!==0 ){
        //   alert("I am in scnitemlist ")
          let tax,a,total,b,scnid,c
          a=this.state.scnitemlist.map((e) =>{return e.servicetax})
          tax=a.find((e,i)=>e.valueOf(i))
          b=this.state.scnitemlist.map((e) =>{return e.servicecost})
          total=b.find((e,i)=>e.valueOf(i))
          c=this.state.scnitemlist.map((e) =>{return e.scnid})
          scnid=c.find((e,i)=>e.valueOf(i))
          let sadata1=[{
            scnid: scnid,
            scnfile: this.state.milestone!==null?this.state.milestone.completionfile:'',
            scnpath: this.state.milestone!==null?this.state.milestone.completionpath:'',
            scnname: scnservicename.toLocaleString(),
            milestone: this.state.milestone!==null?this.state.milestone.name:'',
            percentage: this.state.milestone!==null?this.state.milestone.percentage:'',
            tax: tax,
            total: total,
            completiondate: this.state.milestone!==null?this.state.milestone.completionuploaddate:'',
          }]
          this.setState({sadata:sadata1})
          //this.state.sadata.push(sadata1)
        // }

        }
      }else{
        this.setState({scntaxval:[],scnunittot:[],scnwithtot:[]})
      }
    }
  }

  onCreditChange(values) {
    if(values.credit!==undefined && values.credit.length!==0){
      // alert(values.credit.id)
      this.setState({credselval:values.credit})
      this.setState({partyid:values.credit.id})
      grnsupppid=values.credit.pid

    }
  }

  handleCheckboxChange= event =>{
    this.setState({ displaychecked: event.target.checked })

 }

 handleGrnChange=(i) => () =>{
 

  this.state.grnid[i].isChecked = !this.state.grnid[i].isChecked
  this.setState({grnid:this.state.grnid})
  if(this.state.grnid[i].isChecked===true){
    let selectedgrn =this.state.grnid.filter(e=>e.isChecked===true)?.map(e=>e.name)
    let a=this.state.grnitemlist.filter((e)=>selectedgrn.includes(e.grnid))
    this.setState({grnitems:a},()=>this.addValues())
  }
  else if(this.state.grnid[i].isChecked===false){
    let a=this.state.grnitems.filter((e)=>e.grnid!==this.state.grnid[i].name)
    this.setState({grnitems:a},()=>this.addValues())
  }

}
selectFile=event=> {
  this.setState({selectedFiles: event.target.files,});
}
 

upload() {
  let currentFile = this.state.selectedFiles[0];
   this.setState({progress: 100,currentFile: currentFile});
  UploadFilesService.upload(currentFile,year,curmonth,doctype,this.state.partyid,day, (event) => {
    this.setState({progress: Math.round((100 * event.loaded) / event.total)});
  }).then((response) => {
   this.setState({message: response.data.message,isError: false});
      return FileServices.getFiles1(currentFile.name);
    }).then((files) => {this.setState({fileInfos: files.data});
    }).catch(() => {
      this.setState({progress: 0,message: "Could not upload the file!",currentFile: undefined,
        isError: true}); });
  this.setState({selectedFiles: undefined,});
}
 

 onAeChange(value, values){
   if(value!==undefined || this.state.invchecked===2){
  let ae=aedata.filter((e)=>e.custompoid===value)
  ae.map((e)=>{return custompoid=e.custompoid})
  let apoid
  ae.map((e)=>{return apoid=e.poid})
  this.setState({SelectedAePoid:apoid})
  aesup=ae.map(e=>e.supplier)
  ae.map(e=>{return aeamount=e.amountreq})
  this.setState({aeval:aeamount})
  aefile=ae.map(e=>e.proformainvoicefile)
  aedate=ae.map(e=>e.proformainvoicedate)
 // console.log(ae)
  ae.map(e=>{return aesupid=e.supplierid})
  this.setState({aepoamount:ae.map(e=>e.totalwtax),jvinsertiontype:8})
  aepath=ae.map(e=>e.proformainvoicepath)
 }
 }

 addSelect(e,id){
  let val =this.state.grnitems.filter((e)=>e.id!==id)
  this.setState({grnitems:val})
  this.state.addedGRN.push(e)
 }

 itemSelected(value) {
   let itax
   if(value!==undefined){
    let val=filteritemlist.filter((e)=>e.name===value);
    itax=val.map((e)=>e.tax)
    this.setState({itemtax:itax.toLocaleString()})
   }
}
 download(value){
   if(this.state.invchecked===0){
     if(this.state.proinvoicefile===value){
    return downloadfiles.download(this.state.proinvoicepath,this.state.proinvoicefile)
     }else if(this.state.invoicefile===value){
      return downloadfiles.download(this.state.invoicepath,this.state.invoicefile)
     }else if(this.state.quotefile===value){
      return downloadfiles.download(this.state.quotepath,this.state.quotefile)
     }
   }else if(this.state.invchecked===2){
   return downloadfiles.download(aepath,aefile)
   }
 }



  render() {
   
    let wh = this.state.wh;
    const {selectedFiles,currentFile,progress,message,isError} = this.state;
    let grnval //datagrn
    let val = totalcombdata;
    let uomdata = uom;
    let po = appgrn
    let debvalue,debservice,credvalue
    if(val!==undefined){
    // mpnmp=val.filter((e) => e.type===2)
    // consu=mpnmp.concat(val.filter((e) => e.type===3))
    // consu=val.filter((e) => e.type===2)
    } 
    if(this.state.invchecked===2){
    debvalue=''
    debvalue=totalcombdata.filter((e) =>  e.th==='TH2')
    //debvalue=deb1.filter((e) =>  e.shid===100004 ||e.shid===100003 )
    }else{
    debvalue=''
    debvalue=totalcombdata.filter((e) => e.subheadid==="AH000010")
    debservice=totalcombdata.filter((e) => e.subheadid==="AH000010")
    }
    // if(this.state.grndata!==undefined){
    //   this.state.grndata.map((e) => {
    //   return (datagrn = e.map((cur) => cur));
    //   });} 
    if(this.state.invchecked===2){
      credvalue=''
      credvalue=val.filter((e)=>e.pid===aesupid)
      // console.log(credvalue)
    }else if(this.state.invchecked===0){
      credvalue=''
    // console.log(grnsuppid)
      credvalue=val.filter((e)=>e.pid===grnsuppid)
    }else{
      credvalue=''
      credvalue=val.filter((e) => e.rule === 0);
    }
    
    let tax,taxsecond;
    if (gsttype === 1 ) {
      tax = val.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype===0) & (e.indirectsubtaxtype===0) );
      
      taxsecond = val.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype===0) & (e.indirectsubtaxtype===1) );
     } 
    else if (gsttype === 2) {
      tax = val.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype===0) & (e.indirectsubtaxtype===2) );
    } 
    else if (gsttype === 3) {
      tax = val.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype===1) & (e.indirectsubtaxtype===0) );
     // console.log(tax)
    } 
    else if (gsttype === undefined) {
      tax = val.filter((e) => e.taxtype === 2 & (e.indirecttaxtype===1));
      cusdata=val.filter((e) => e.taxtype === 2 & (e.indirecttaxtype===2))

    }

    let grnvaldata = this.state.grnvaldata;
    let grnitems = this.state.grnitems;
    let sgst;
    let cgst;
    let igst;

    return (
      <div>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Formik
          initialValues={{grnvaldata,grnsupp,grnval,grnitems,tax,uomdata,uom,wh,uprice: "",itemsqty: "",sgst,
            cgst,igst,personal,real,debitval: "",creditvalue: "",debvalue,credvalue,narration: "",
            gst: "",taxvalue1:"",taxvalue2:"",sdebitval:"",itaxvalue1:"",gtaxvalue1:"",debtotal:""
          }}
          onSubmit={this.onSubmit}
          validateOnChange={true}
          validate={this.validate}
          enableReinitialize={true}
        >
          {({ values, setFieldValue }) => (
            <Form autoComplete="off">
          <div className="form-row mt-0 mb-0">
          
          <div className="form-group col-md-3 text-sm mt-0 mb-2 float-right">
          <label className="textColour">Purchase Entry Type : </label></div>
          <div className="form-group col-md-9 text-sm mt-0 mb-2 float-right">
          {this.state.purinvtype.map((option) => {
          return (<label key={option.id} >
          <div className="form-group  mt-0 mb-0">
          <div className="radio-item mt-0 mb-0">
           <input type="radio"  checked={this.state.invchecked === option.id ? true : false } key={option.id} 
           onChange={this.onInvChange.bind(this, option)} style={{marginRight:8}} value={option.id}/>
          <span style={{marginRight:30}}>{option.name}</span>
           </div></div></label>);})}</div>
{/* {this.state.invchecked===0 || this.state.invchecked===1 ? <>
           <div className="form-group col-md-3 text-sm mt-0 mb-2 float-right">
          <label className="textColor">Purchased : </label></div>           
           {ccountry==="India"?
          <div className="form-group col-md-9 text-sm mt-0 mb-0 float-left">
          {this.state.gsttype.map((gstoption) => {
          return (<label key={gstoption.id}>
          <div className="form-group mt-0 mb-0 ">
          <div className="form-group radio-item mt-0 mb-0 ">
           <input type="radio"  checked={this.state.gstchecked === gstoption.id ? true : false} key={gstoption.id} 
           onChange={this.onGstChange.bind(this,gstoption)} style={{marginRight:8 }}value={gstoption.id}  />
          <span style={{marginRight:15}} >{gstoption.name}</span>
           </div></div> </label>);})}</div> :""}</>:""} */}

          
           {this.state.invchecked === 0 ? (
        <div className="form-row col-md-12">
          {purchase === "YES" ? (
            <>
           <fieldset className="form-group col-md-6 mt-0 mb-3">
          <Autocomplete id="apo" className="grn-select " name="ppo" options={po?po:[]} getOptionLabel={(option) => option.custompoid + " ( "+option.supplier+" ) "}
          isOptionEqualToValue={(option) => !option.value}
          value={values.po ? undefined : undefined} onChange={(e, value) => {setFieldValue("grn", value) && this.onPoChange(value);}}
          renderInput={(params) => (
          <FormikTextField {...params} className="form-control mb-3" type="text" name="po" label="PO"
          //  onBlur={(e) => { this.onPoChange(values); }} 
           variant="standard" fullWidth/>)}/></fieldset>


{this.state.selectedPO!=='' && this.state.grnitems!==null?<>
 <fieldset className="form-group col-md-12 align-right">
<Table className="table-sm table-equal table-bordered">
  <TableHead><TableRow>
      <TableCell className="col-md-2 text-sm">Supplier</TableCell>
      <TableCell className="col-md-1 text-sm">PO Price</TableCell>
      <TableCell className="col-md-1 text-sm">GRN INV price</TableCell>
      <TableCell className="col-md-1 text-sm">Total Tax</TableCell>
      <TableCell className="col-md-1 text-sm">Taxed Price</TableCell>
      <TableCell className="col-md-1 text-sm">Adjusted Opening</TableCell>
      <TableCell className="col-md-1 text-sm">Credit Note Adj</TableCell>
      <TableCell className="col-md-1 text-sm">Balance</TableCell>

      {/* <TableCell className="col-md-3 text-sm text-center">Quotation</TableCell> */}
      <TableCell className="col-md-4 text-sm text-center">Invoice</TableCell>
    </TableRow></TableHead>
    <TableBody><TableRow>

      <TableCell  className="col-md-2 text-sm">{grnsupp}</TableCell>
      <TableCell  className="col-md-1 text-sm">
      <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.itempototal.length!==0?getGrnTaxTotal(this.state.itempototal):getGrnTaxTotal(this.state.servicepototal)} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>
      <TableCell className="col-md-1 text-sm">
      <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.grnunittot.length!==0?getGrnTaxTotal(this.state.grnunittot):getGrnTaxTotal(this.state.scnunittot)} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>
      <TableCell  className="col-md-1 text-sm">
      <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.grntaxval.length!==0?getGrnTaxTotal(this.state.grntaxval):getGrnTaxTotal(this.state.scntaxval)} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      ({this.state.grntaxper==="" ?this.state.scntaxper:this.state.grntaxper}%)</TableCell >
      <TableCell  className="col-md-1 text-sm">
      <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.grnwithtot.length!==0?this.state.openadjusted!==null?getGrnTaxTotal(this.state.grnwithtot):getGrnTaxTotal(this.state.grnwithtot):getGrnTaxTotal(this.state.scnwithtot)} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      {/* -parseFloat(this.state.openadjusted) */}
      </TableCell>
      <TableCell  className="col-md-1 text-sm"> 
      <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {parseFloat(this.state.openadjusted).toFixed(2)} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>
      <TableCell  className="col-md-1 text-sm"> 
      <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.dcadjusted!==null?parseFloat(this.state.dcadjusted).toFixed(2):0} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>
      <TableCell  className="col-md-1 text-sm">
      <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = 
      {this.state.grnunittot.length!==0?Math.abs(getGrnTaxTotal(this.state.grnwithtot)-parseFloat(this.state.advanceadjusted+this.state.openadjusted+this.state.dcadjusted).toFixed(2)):Math.abs(getGrnTaxTotal(this.state.scnwithtot)-parseFloat(this.state.advanceadjusted+this.state.openadjusted+this.state.dcadjusted).toFixed(2))}
      // {getGrnTaxTotal(this.state.grnwithtot)-(this.state.advanceadjusted!==null?parseFloat(this.state.advanceadjusted):0+this.state.openadjusted!==null?parseInt(this.state.openadjusted):0+this.state.dcadjusted!=null?parseInt(this.state.dcadjusted):0)} 
      thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>
     
      
      {/* <TableCell className="col-md-3 text-sm">
      {this.state.quotefile.toLocaleString()!==''?<>
      <span className="text-sm mt-0 mb-0">Quotation : <Link as="button" className="text-blue btn btn-link text-sm mt-0 mb-0" onClick={()=>this.download(this.state.quotefile)}>{this.state.quotefile}</Link></span>
      <ul><span className="text-sm mt-0 mb-0">Date : &nbsp; {this.state.quotedate.toLocaleString()!==''?moment(this.state.quotedate.find(((e,i)=>e.valueOf(i)))).format('DD-MM-yyyy HH:mm'):""}</span></ul>
      </>:""}
      </TableCell> */}
      {/* <TableCell className="text-sm"><Link as="button" className="text-blue btn btn-link" onClick={this.download}>{this.state.invfile}</Link></TableCell> */}
      <TableCell className="col-md-4 text-sm text-right ">
      {this.state.proinvoicefile.toLocaleString()!==''?<>
      <span className="text-sm mt-0 mb-0">First : <span as="button" className="text-blue btn btn-link text-sm mt-0 mb-0" onClick={()=>this.download(this.state.proinvoicefile)}>{this.state.proinvoicefile}</span></span>
      <span className="text-sm mt-0 mb-0">Date : &nbsp; {this.state.proinvoicedate.toLocaleString()!==''?(moment(this.state.proinvoicedate.find(((e,i)=>e.valueOf(i)))).format('DD-MM-YYYY HH:mm')):""}
      </span>  </>:""}
      {this.state.invoicefile.toLocaleString()!==''?<>
     <ul> <span className="text-sm mt-0 mb-0">Updated : <span as="button" className="text-blue btn btn-link text-sm mt-0 mb-0" onClick={()=>this.download(this.state.invoicefile)}>{this.state.invoicefile}</span></span>
      <span className="text-sm mt-0 mb-0">Date : &nbsp; {this.state.invoicedate.toLocaleString()!==''?(moment(this.state.invoicedate.find(((e,i)=>e.valueOf(i)))).format('DD-MM-yyyy HH:mm')):""}</span></ul>
      </>:""}
        </TableCell>
    </TableRow></TableBody>
</Table>
    <Modal
      show={this.state.modal}
      onHide={this.handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size='lg'
      
    >
      <Modal.Header closeButton>Invoices</Modal.Header>
      <Modal.Body>
        <label> Original invoice : <span as="button" className="text-blue btn btn-link" onClick={this.download}>{this.state.invfile}</span></label>
      </Modal.Body>
    </Modal>
</fieldset></>:""}

{this.state.servicetotal!==0?<>
{this.state.servicetotal!==null?
<fieldset className="form-group col-md-12 align-right">
<Table className="table-sm table-equal table-bordered">
<TableHead><TableRow>
<TableCell className="col-md-1 text-sm">Sevice price</TableCell>
<TableCell className="col-md-1 text-sm">Sevice Tax %</TableCell>
 <TableCell className="col-md-1 text-sm">Tax Amount</TableCell>
 <TableCell className="col-md-1 text-sm">Total Amount</TableCell>
</TableRow></TableHead>
<TableBody><TableRow>
<TableCell className="col-md-1 text-sm">
<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.servicetotal} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>
<TableCell className="col-md-1 text-sm">{this.state.servicetax}</TableCell>
      <TableCell className="col-md-1 text-sm">
      <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.servicetaxamount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>
      <TableCell className="col-md-1 text-sm">
      <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.grandservicetotal} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>
 </TableRow></TableBody>
</Table>

</fieldset>:""}</>:""}
{this.state.grnitems.length!==0?
           <fieldset className="form-group col-md-12 mt-0 mb-0"> 
           <div className="form-check form-switch mt-0 mb-0">  
           <label className="text-sm">
          <Checkbox
            checked={this.state.displaychecked}
            onChange={this.handleCheckboxChange}
          />
          {this.state.displaychecked===false ?"Complete PO":"Individual Grn/SCN"}
        </label>
           </div>
           </fieldset>:""}

           {this.state.displaychecked===true?
          <fieldset className="form-group col-md-12 mt-0 mb-0"> 
        <Table className="table-sm">
        <TableHead>
           <TableRow className="text-sm" >
              <TableCell >
              GRN/SCN ID : &nbsp;&nbsp;&nbsp;&nbsp;
              {this.state.grnid.length!==0?
              this.state.grnid.map((e,i)=>
              <label className="text-sm m-3" >
                <Checkbox checked={e.isChecked} name={e.name} onChange={this.handleGrnChange(i)} />{e.name}</label>
              ):this.state.scnid.map((e,i)=>
              <label className="text-sm m-3" >
                <Checkbox checked={e.isChecked} name={e.name} onChange={this.handleGrnChange(i)} />{e.name}</label>
              )}
                </TableCell>
              </TableRow>
              </TableHead>
              </Table>
      
       <fieldset className="form-group col-md-12 mt-0 "> 
        <Table className="table-sm">
        {this.state.grnitems.length!==0? <>
       <TableHead><TableRow className="text-sm" >
      <TableCell>Item</TableCell><TableCell>Quantity</TableCell><TableCell>Opening ADJ</TableCell><TableCell>Tax</TableCell>
      <TableCell>Tax Amount</TableCell><TableCell>Unit</TableCell><TableCell>Amount</TableCell>
       <TableCell>Category</TableCell></TableRow></TableHead>
        <TableBody>
         {this.state.grnitems.map((e) => (
          //this.state.grnid.find(a=>a.name===e.grnid)
         <TableRow key={e.id}><TableCell>{e.name}</TableCell> 
          <TableCell>{e.quantity}</TableCell>
          <TableCell>{e.openadjusted!=null?e.openadjusted:"NA"}</TableCell>
           <TableCell>{e.tax}%</TableCell><TableCell>
            <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {(e.price*e.quantity)*e.tax/100} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
            </TableCell>
            <TableCell>
            <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.price} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
             </TableCell>
            <TableCell>
            <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.quantity * e.price} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
            </TableCell>
             <TableCell>{e.category}</TableCell>
            </TableRow>))} </TableBody></>:<>
            <TableHead><TableRow className="text-sm" >
            <TableCell>Service Name</TableCell>
            <TableCell>Milestone</TableCell><TableCell>Status</TableCell><TableCell>Percentage</TableCell>
             <TableCell>Completion Date</TableCell></TableRow></TableHead>
              <TableBody>
               <TableRow >
                 <TableCell>{scnservicename}</TableCell><TableCell>{this.state.milestone.name}</TableCell>
                  <TableCell>{this.state.milestone.servicestatus}</TableCell>
                  <TableCell>{this.state.milestone.percentage}</TableCell>
                   <TableCell>{moment(this.state.milestone.updatedAt).format('DD-MM-yyyy HH:mm')}</TableCell>
                  </TableRow> </TableBody></>}
          </Table></fieldset>
          </fieldset>  
        :""}
                      </>
                      ):""}
                     </div>
                  
                  ) : (
                    ""
                  )}

      
{this.state.invchecked === 1 ? (
<div className="form-row col-md-12">
{inventory === "YES" ? (
<><fieldset className="form-group col-md-4 mt-0 mb-0 test-sm">
<Autocomplete id="wh" className="wh-select" name="awh" options={wh} getOptionLabel={(option) => option.name} 
value={values.name ? undefined : undefined} onChange={(e, value) => {
setFieldValue("whouse", value);}}
renderInput={(params) => (
<FormikTextField {...params} type="text" name="whouse" label="Warehouse" variant="standard" fullWidth/>)}/>
 </fieldset>
 <fieldset className="form-group col-md-4 mt-0 mb-0 test-sm">
 <Autocomplete
    id="subaccount"
    className="subacc-select"
    name="category"
    options={this.state.CategoryList}
    getOptionLabel={(option) => option.category}
    
    
    value={values.category ? undefined : undefined}
    onChange={(e, value) => {
      setFieldValue("category", value);
    }}
    renderInput={(params) => (
      <FormikTextField
        {...params}
        className="form-control"
        type="text"
        name="category"
        onBlur={(e) => {
          this.handleCategory(e.target.value, values);
        }}
        label="Select Category"
        variant="standard"
        fullWidth
      />
    )}
  />
 </fieldset>

<fieldset className="form-group col-md-4 mt-0 mb-0 test-sm">
<Autocomplete id="items" className="items-select" name="aitems" options={filteritemlist}
getOptionLabel={(option) => option.name} 
 value={values.items ? undefined : undefined}
onChange={(e, value) => {setFieldValue("items", value);}}
renderInput={(params) => (
<FormikTextField {...params} type="text" name="items" label="Item"
 onBlur={(e) => this.itemSelected(e.target.value)
 } variant="standard" fullWidth/>)}/>
 </fieldset>

                      <fieldset className="form-group col-md-4 mt-0 mb-0  test-sm">
                        <FormikTextField
                          name="uprice"
                          className="form-control"
                          label="Unit Price"
                          type="text"
                          variant="standard"
                        ></FormikTextField>
                      </fieldset>

                      <fieldset className="form-group col-md-4 mt-0 mb-0 test-sm">
                        <FormikTextField
                          name="itemsqty"
                          className="form-control"
                          label="Quantity"
                          type="text"
                          variant="standard"
                          onBlur={() =>
                            setFieldValue( "debitval",values.uprice * values.itemsqty) &&
                            this.state.invchecked===1 && gsttype===1?
                            setFieldValue("taxpercent1", this.state.itemtax/2) &&
                            setFieldValue("taxpercent2",this.state.itemtax/2) :
                            this.state.invchecked===1 && gsttype===2?
                            setFieldValue("itaxpercent1", this.state.itemtax):
                            this.state.invchecked===1 && gsttype===undefined?
                            setFieldValue("gtaxpercent1", this.state.itemtax):""
                          }
                        ></FormikTextField>
                      </fieldset>

                      <fieldset className="form-group col-md-4 mt-0 mb-0 test-sm">
                        <Autocomplete
                          id="uom"
                          className="items-select"
                          name="auom"
                          options={uomdata}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.items ? undefined : undefined}
                          onChange={(e, value) => {
                            setFieldValue("uom", value);
                          }}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              type="text"
                              name="uom"
                              label="UOM"
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      </>
                  ):""}
                     </div>

                  ) : (
                    ""
                  )}
                  
                
                  {this.state.invchecked === 2 ? (
                    <div className="form-row col-md-12">
<fieldset className="form-group col-md-4 mt-0 mt-0 test-sm">
  <Autocomplete id="ae1" className="ae-select" name="ae1" options={aedata?aedata:[]} getOptionLabel={(option) => option.custompoid}
 value={values.whouse ? undefined : undefined}
   onChange={(e, value) => {setFieldValue("ae", value);}}
    renderInput={(params) => (
    <FormikTextField {...params} type="text" name="ae" label="Select PO"
     variant="standard" onBlur={(e) => {this.onAeChange(e.target.value, values)}}
     fullWidth/>)}/></fieldset>
{this.state.aeval!==''?
<fieldset className="form-group col-md-8 mt-0 mt-0 test-sm">
<Table className="table-sm">
  <TableHead>
    <TableRow>
      <TableCell>Supplier</TableCell>
      <TableCell>Date</TableCell>
      <TableCell>Advance Amount</TableCell>
      <TableCell>Invoice</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    <TableRow>
      <TableCell>{aesup}</TableCell>
      <TableCell>{moment({aedate}).format('DD-MM-yyyy')}</TableCell>
      <TableCell>{aeamount}</TableCell>
      <TableCell className="sm"><span as="button" className="text-blue btn btn-link" onClick={this.download}>{aefile}</span></TableCell>
    </TableRow>
  </TableBody>
</Table>
</fieldset>:""}
                      </div>
                  ):""}
             </div>

{this.state.invchecked!==''?
  <div className="form-row col-md-12 text-left form-switch mt-3">
 <div className="col-md-3 text-left">
 <FormControlLabel
    control={
   <IOSSwitch
    className="sm"
    checked={this.state.SDdate}
    onChange={this.handleSDDate}
    name="checkedB"
  />
  }
  />
     <span className="text-left textColour">{this.state.SDdate===false?"System Date":"Custom Date"}</span>
       </div>
    {this.state.SDdate===true?
     <div className="col-md-3 text-left">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid  justifyContent="space-around">
    <DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
    id="date-picker-dialog" inputVariant="standard"
    minDate={new Date(moment(transactionFrom).format("yyyy-MM-DD"))}
    maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
    format="dd/MM/yyyy HH:mm" value={this.state.fsd}
    // label="Custom Date" 
    InputAdornmentProps={{position: "start"}} onChange={this.handleFsdDateChange}
    renderInput={(params) => <TextField {...params} />}autoOk={true}/>
    </Grid></LocalizationProvider></div>
     :""}
    </div>:""}


  {this.state.invchecked===0 && this.state.grnitems.length!==0?<>

  <div className="form-row mt-0 mb-0 sm "> 
<fieldset className="form-group col-md-3 text-sm mt-1">
  <FormLabel>Customs :</FormLabel>{this.state.cusreq.map((option) => {return (
  <label key={option.id}><div className="form-group col-md-12 mt-0 mb-0"><div className="radio-item mt-0 mb-0 text-sm">
  <input type="radio" checked={this.state.cuschecked === option.id ? true : false} key={option.id}
  onChange={this.onCusChange.bind(this, option)} style={{ marginRight: 8 ,marginTop: 25 }} value={option.id}/>
  {option.name}</div></div> </label>); })} </fieldset>

  {this.state.cuschecked===1?<>
  <fieldset  className="form-group col-md-3 mt-0 mb-0"> 
  <Autocomplete id="itcs" className="tcs-select text-sm" name="atcs" options={cusdata}
  getOptionLabel={(option) => option.name}
    
   value={values.customs?undefined:undefined} onChange={(e, value) => { setFieldValue("customs",value);}}
   renderInput={params => (
   <FormikTextField {...params}  type="text" name="customs" className="text-sm" label="Select Customs"
   onBlur={()=>setFieldValue("cusval",getGrnTaxTotal(this.state.grnunittot))} variant="standard" fullWidth />)}/>
  </fieldset>
   <fieldset  className="form-group col-md-3">   
   <FormikTextField name="cusval"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.cusval?" ":"Value"} type="text" 
  //onBlur={()=>setFieldValue("creditvalue",values.debitval)}
  variant="standard"> </FormikTextField>
   </fieldset>
   <fieldset className="form-group col-md-1 mt-0 mt-0 test-sm">
    <FormikTextField name="cuspercent" className="form-control" label={values.cuspercent ? " " : "%"}
      type="text" onBlur={() => {setFieldValue("custotal",(values.cusval * values.cuspercent) / 100);}}
      variant="standard"/></fieldset>
    <fieldset className="form-group col-md-2 mt-0 mt-0 test-sm" disabled>
      <NumericFormat name="custotal" className="form-control" value={(values.cusval * values.cuspercent) / 100}
       label={values.custotal ? " " : "Value"} displayType={"input"} customInput={FormikTextField} decimalScale={2}
       fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
     </fieldset>
   
   </>:""}

</div></>:" "}
              
{this.state.invchecked!==''?<>
<div className="form-row mt-0 mb-2">
<fieldset className="form-group col-md-6 mt-0 mt-0 test-sm">
<Autocomplete id="debit" className="debit-select" name="debit" options={debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("debit", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="debit" label="Debit" onBlur={() => (
  this.state.openadjusted- getGrnTaxTotal(this.state.grntaxval)===//this.state.grntaxval.length!==0?getGrnTaxTotal(this.state.grntaxval):getGrnTaxTotal(this.state.scntaxval)!==
  getGrnTaxTotal(this.state.grnunittot)?this.state.invchecked===0 && gsttype===1?
  //this.state.grnunittot.length!==0?getGrnTaxTotal(this.state.grnunittot):getGrnTaxTotal(this.state.scnunittot)?
  setFieldValue("debitval", parseFloat(getGrnTaxTotal(this.state.grnunittot)-(this.state.openadjusted+this.state.advanceadjusted+this.state.dcadjusted)).toFixed(2))
&& setFieldValue("taxvalue1",parseFloat(getGrnTaxTotal(this.state.grntaxval)/2+this.state.servicetaxamount/2).toFixed(2)) &&
setFieldValue("taxvalue2",parseFloat(getGrnTaxTotal(this.state.grntaxval)/2+this.state.servicetaxamount/2).toFixed(2))  && setFieldValue("itaxvalue1",0) &&
setFieldValue("gtaxvalue2",0)
:
setFieldValue("debitval", parseFloat(getGrnTaxTotal(this.state.grnunittot)-(this.state.advanceadjusted+this.state.openadjusted+this.state.dcadjusted)).toFixed(2))
&& this.state.invchecked===0 && gsttype===2? setFieldValue("itaxvalue1",getGrnTaxTotal(this.state.grntaxval)+this.state.servicetaxamount) &&
setFieldValue("taxvalue1",0) && setFieldValue("taxvalue2",0) && setFieldValue("gtaxvalue2",0)
:
setFieldValue("taxvalue2",getGrnTaxTotal(this.state.grntaxval)+this.state.servicetaxamount) &&  //&& setFieldValue("itaxvalue1",0)
setFieldValue("taxvalue1",0) && setFieldValue("taxvalue2",0) && setFieldValue("itaxvalue1",0)
:
this.state.invchecked===0 && gsttype===1? this.state.grntaxval.length!==0?
// setFieldValue("debitval", (this.state.advanceadjusted!==null?getGrnTaxTotal(this.state.grnunittot)-parseInt(this.state.advanceadjusted):
// this.state.openadjusted!==null?getGrnTaxTotal(this.state.grnunittot)-parseInt(this.state.openadjusted):this.state.dcadjusted!=null?getGrnTaxTotal(this.state.grnunittot)-parseInt(this.state.dcadjusted):getGrnTaxTotal(this.state.grnunittot))) 
setFieldValue("debitval", parseFloat(getGrnTaxTotal(this.state.grnunittot)-(this.state.advanceadjusted+this.state.openadjusted+this.state.dcadjusted)).toFixed(2))
&&
setFieldValue("taxvalue1",parseFloat(getGrnTaxTotal(this.state.grntaxval)/2+this.state.servicetaxamount/2).toFixed(2)) &&
setFieldValue("taxvalue2",parseFloat(getGrnTaxTotal(this.state.grntaxval)/2+this.state.servicetaxamount/2).toFixed(2)) && parseFloat(setFieldValue("sdebitval",this.state.servicetotal)).toFixed(2):
 setFieldValue("debitval",parseFloat(getGrnTaxTotal(this.state.scnunittot))) &&
setFieldValue("taxvalue1",parseFloat(getGrnTaxTotal(this.state.scntaxval)/2)) && setFieldValue("taxvalue2",getGrnTaxTotal(this.state.scntaxval)/2):
setFieldValue("debitval", parseFloat(getGrnTaxTotal(this.state.scnunittot))) &&
setFieldValue("taxvalue1",parseFloat(getGrnTaxTotal(this.state.scntaxval)/2)) && setFieldValue("taxvalue2",getGrnTaxTotal(this.state.scntaxval))
&& this.state.invchecked===0 && (gsttype===2 || gsttype===3)? this.state.grntaxval.length!==0?
// setFieldValue("debitval", (this.state.advanceadjusted!==null?getGrnTaxTotal(this.state.grnunittot)-parseFloat(this.state.advanceadjusted):
// this.state.openadjusted!==null?getGrnTaxTotal(this.state.grnunittot)-parseFloat(this.state.openadjusted):this.state.dcadjusted!=null?getGrnTaxTotal(this.state.grnunittot)-parseFloat(this.state.dcadjusted):getGrnTaxTotal(this.state.grnunittot))) 
setFieldValue("debitval",
parseFloat(getGrnTaxTotal(this.state.grnunittot)-(this.state.advanceadjusted+this.state.openadjusted+this.state.dcadjusted)).toFixed(2)
)
&&
setFieldValue("itaxvalue1",parseFloat(getGrnTaxTotal(this.state.grntaxval)+this.state.servicetaxamount).toFixed(2)) &&
setFieldValue("gtaxvalue1",parseFloat(getGrnTaxTotal(this.state.grntaxval)+this.state.servicetaxamount).toFixed(2)):
setFieldValue("debitval", getGrnTaxTotal(this.state.scnunittot)) &&
setFieldValue("itaxvalue1",getGrnTaxTotal(this.state.scntaxval)):
this.state.invchecked===2? setFieldValue("debitval", aeamount)
:"" && this.onDebBlur(values)
)}
variant="standard" fullWidth />)}/> </fieldset>
                
<fieldset className="form-group col-md-6 mt-0 mb-2 test-sm">
<FormikTextField name="debitval" className="form-control" label="Value" type="text"
 onBlur={() => this.state.invchecked!==0? setFieldValue("creditvalue", values.debitval-(this.state.openadjusted!==null?parseFloat(this.state.openadjusted).toFixed(2):0+this.state.dcadjusted)) && setFieldValue("debtotal", values.debitval):""}
 variant="standard"></FormikTextField></fieldset>
 
 {this.state.selectedPO!=='' && this.state.servicetotal!==null && this.state.aeval===''?<><fieldset className="form-group col-md-6 mt-0 mt-0 test-sm">
<Autocomplete id="sdebit" className="sdebit-select" name="sadebit" options={debservice!==undefined?debservice.sort((a, b) => -a.subname.localeCompare(b.subname)):debservice}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.sdebit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("sdebit", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="sdebit" label="Service Debit" 
variant="standard" fullWidth />)}/> </fieldset>
                
<fieldset className="form-group col-md-6 mt-0 mb-2 test-sm">
<FormikTextField name="sdebitval" className="form-control" label={this.state.servicetotal!==null?" ":"Value"} type="text"
 onBlur={() => this.state.invchecked!==0? setFieldValue("creditvalue", values.debitval) && setFieldValue("debtotal", values.debitval):""}
 variant="standard" fullWidth></FormikTextField></fieldset></>:""}

 </div>


{ccountry==="India" && jtype === 2 && this.state.gstchecked === 1 ? (
 <> <div className="form-row mt-0 mb-0 ">
 <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<Autocomplete id="SGST" className="Tax-select" name="sgst" options={tax} getOptionLabel={(option) => option.name}
  value={values.sgst ? undefined : undefined}
onChange={(e, value) => {setFieldValue("sgst", value);}}
renderInput={(params) => (
<FormikTextField {...params} type="text" name="sgst" label="Select Tax"
onBlur={() => (
  this.onDebBlur(values))}variant="standard" fullWidth/>)}/></fieldset>

{this.state.invchecked!==0?
<fieldset className="form-group col-md-1 mt-0 mb-0 test-sm">
 <FormikTextField name="taxpercent1" className="form-control" label={values.taxpercent1 ? " " : "%"}
  type="text" onBlur={() => 
  setFieldValue("taxvalue1",(values.debitval * values.taxpercent1) / 100) &&
   setFieldValue("taxpercent2", values.taxpercent1) && setFieldValue("taxvalue2",(values.debitval * values.taxpercent1) / 100)}
   variant="standard"/>{" "}</fieldset>:""}

{this.state.invchecked===0?

<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<FormikTextField name="taxvalue1" className="form-control" label={this.state.grntaxval.length!==0?getGrnTaxTotal(this.state.grntaxval) ? " " : "Value":getGrnTaxTotal(this.state.scntaxval) ? " " : "Value"} type="text"
 onBlur={() =>setFieldValue("creditvalue",parseFloat(values.taxvalue1) + parseFloat(values.debitval) +parseFloat(values.taxvalue2))}
  variant="standard" ></FormikTextField></fieldset>
:
 <fieldset className="form-group col-md-5 mt-0 mb-0 test-sm">
<NumericFormat name="taxvalue1" className="form-control" value={(values.debitval * values.taxpercent1) / 100}
label={values.taxpercent1 ? " " : "Value"} displayType={"input"} customInput={TextField} decimalScale={2}
fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
 onBlur={() => setFieldValue("creditvalue",values.taxvalue1 + values.debitval +values.taxvalue2) &&
 setFieldValue("debtotal",values.taxvalue1 + values.debitval +values.taxvalue2)}></NumericFormat></fieldset>}</div>
 
<div className="form-row">
<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
 <Autocomplete id="CGST" className="Tax-select" name="CGST" options={taxsecond} getOptionLabel={(option) => option.name}
  value={values.cgst ? undefined : undefined}
onChange={(e, value) => {setFieldValue("cgst", value);}}
renderInput={(params) => (
<FormikTextField {...params} type="text" name="cgst" label="Select Tax"
onBlur={() => { this.onDebBlur(values) && setFieldValue("taxpercent2", values.taxpercent1) &&
              setFieldValue("taxvalue2",(values.debitval * values.taxpercent1) / 100);}}
 variant="standard" fullWidth/>)}/></fieldset>
{this.state.invchecked!==0?
<fieldset className="form-group col-md-1 mt-0 mb-0 test-sm" disabled>
<FormikTextField name="taxpercent2" className="form-control" label={values.taxpercent1 ? " " : "%"}
type="text" value={values.taxpercent1}
onBlur={() => {setFieldValue("taxvalue2",(values.debitval * values.taxpercent2) / 100);}}
variant="standard"/></fieldset>:""}

{this.state.invchecked===0?
  <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<FormikTextField name="taxvalue2" className="form-control" label={this.state.grntaxval.length!==0?getGrnTaxTotal(this.state.grntaxval) ? " " : "Value":getGrnTaxTotal(this.state.scntaxval) ? " " : "Value"} type="text"
 onBlur={() =>setFieldValue( "creditvalue",parseFloat(values.taxvalue1) +parseFloat(values.debitval) +parseFloat(values.taxvalue2))}
  variant="standard" ></FormikTextField></fieldset>
:
<fieldset className="form-group col-md-5 mt-0 mb-0 test-sm" disabled>
<NumericFormat name="taxvalue2" className="form-control" value={(values.debitval * values.taxpercent2) / 100}
label={values.taxpercent1 ? " " : "Value"} displayType={"input"} customInput={TextField} decimalScale={2}
fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
onBlur={() =>setFieldValue( "creditvalue",values.taxvalue1 +values.debitval +values.taxvalue2) &&
             setFieldValue("debtotal",values.taxvalue1+values.debitval +values.taxvalue2)}></NumericFormat>
</fieldset>}{" "}
</div>
</>) : ("")}

{ccountry==="India" && jtype === 2 && this.state.gstchecked === 2 ? (
<div className="form-row">
<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<Autocomplete id="IGST" className="Tax-select" name="igst" options={tax} getOptionLabel={(option) => option.name}
  value={values.igst ? undefined : undefined}
onChange={(e, value) => {setFieldValue("igst", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="igst" label="Select Tax" onBlur={() => {this.onDebBlur(values);}}
 variant="standard" fullWidth/>)}/>
 </fieldset>

 {this.state.invchecked!==0?
<fieldset className="form-group col-md-1 mt-0 mb-0 test-sm">
<FormikTextField name="itaxpercent1" className="form-control" label={values.itaxpercent1 ? " " : "%"}
type="text" onBlur={() =>
setFieldValue("itaxvalue1",(values.debitval * values.itaxpercent1) / 100)}
variant="standard"/></fieldset>:""}

{this.state.invchecked===0?
  <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<FormikTextField name="itaxvalue1" className="form-control" label={this.state.grntaxval.length!==0?getGrnTaxTotal(this.state.grntaxval) ? " " : "Value":getGrnTaxTotal(this.state.scntaxval) ? " " : "Value"} type="text"
 onBlur={() =>setFieldValue( "creditvalue",values.itaxvalue1 +values.debitval)}
  variant="standard" ></FormikTextField></fieldset>
:
<fieldset className="form-group col-md-5 mt-0 mb-0 test-sm">
<NumericFormat name="itaxvalue1" className="form-control" value={(values.debitval * values.itaxpercent1) / 100}
label={values.itaxpercent1 ? " " : "Value"} displayType={"input"} customInput={TextField} decimalScale={2}
fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
onBlur={() => setFieldValue("creditvalue",values.itaxvalue1 + values.debitval) &&
              setFieldValue("debtotal",values.itaxvalue1 + values.debitval)}></NumericFormat>
</fieldset>}</div>) : ("")}

{taxtype !== "GST" && jtype === 2 && this.state.invchecked!==2 ? (
<div className="form-row">
<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<Autocomplete id="vat" className="Tax-select" name="vat" options={tax} getOptionLabel={(option) =>option.name} 
//renderOption={(option) =>option.name + " (" + option.amount + ")"}
 value={values.tax ? undefined : undefined}
onChange={(e, value) => {setFieldValue("tax", value);}}renderInput={(params) => (
<FormikTextField {...params} type="text" name="vat" label="Select Vat"
onBlur={() => {this.onDebBlur(values);}} variant="standard" fullWidth/>)}/></fieldset>

{this.state.invchecked!==0?
<fieldset className="form-group col-md-1 mt-0 mb-0 test-sm">
<FormikTextField name="gtaxpercent1" className="form-control" label={values.gtaxpercent1 ? " " : "%"}
type="text" onBlur={() =>
setFieldValue("gtaxvalue1",(parseFloat(values.debitval) * parseFloat(values.gtaxpercent1)) / 100)}variant="standard"/> </fieldset>:""}

{this.state.invchecked===0?
  <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<FormikTextField name="gtaxvalue1" className="form-control" label={this.state.grntaxval.length!==0?getGrnTaxTotal(this.state.grntaxval) ? " " : "Value":getGrnTaxTotal(this.state.scntaxval) ? " " : "Value"} type="text"
 onBlur={() =>setFieldValue( "creditvalue",parseFloat(values.gtaxvalue1) +parseFloat(values.debitval))}
  variant="standard" ></FormikTextField></fieldset>
:
<fieldset className="form-group col-md-5 mt-0 mt-0 test-sm">
<FormikTextField name="gtaxvalue1" className="form-control" label={values.gtaxpercent1 ? " " : "Value"}
type="text" onBlur={() =>
setFieldValue("creditvalue",parseFloat(values.gtaxvalue1) + parseFloat(values.debitval)) &&
setFieldValue("debtotal",parseFloat(values.gtaxvalue1) + parseFloat(values.debitval)) }
variant="standard"/></fieldset>}</div>) : ("")}

<fieldset className="form-group col-md-1 mt-0 mb-0 test-sm" hidden>
<FormikTextField name="debtotal" className="form-control" label="%" type="text" variant="standard"/>
</fieldset>

<div className="form-row">
<fieldset className="form-group col-md-1 mt-0 mb-0 test-sm"> </fieldset>
<fieldset className="form-group col-md-5 mt-0 mb-0 test-sm">
<Autocomplete id="credit" className="credit-select" name="credit" options={credvalue}
getOptionLabel={(option) => option.name} 
 value={values.credit ? undefined : undefined}
onChange={(e, value) => {setFieldValue("credit", value);}}
renderInput={(params) => (
<FormikTextField {...params} type="text" name="credit"label="Credit" variant="standard"
onBlur={() => (
<>
{ccountry==="India" && jtype === 2 && gsttype === 1
?//console.log(parseFloat(values.taxvalue1)+parseFloat(values.taxvalue2)+parseFloat(values.debitval)+parseFloat(values.sdebitval)) &&
 setFieldValue("debtotal",parseFloat(values.taxvalue1)+parseFloat(values.taxvalue2)+parseFloat(values.debitval)) &&
  setFieldValue("creditvalue", parseFloat(values.taxvalue1)+parseFloat(values.taxvalue2)+parseFloat(values.debitval)) &&
  this.onCreditChange(values): ccountry==="India" && jtype === 2 && gsttype === 2? 
  setFieldValue("creditvalue", parseFloat(values.itaxvalue1) + parseFloat(values.debitval)) &&
  setFieldValue("debtotal",parseFloat(values.itaxvalue1) + parseFloat(values.debitval)): ccountry!=="India" && jtype === 2? 
  setFieldValue("creditvalue",parseFloat(values.gtaxvalue1) + parseFloat(values.debitval)) &&
 setFieldValue("debtotal", parseFloat(values.gtaxvalue1) + parseFloat(values.debitval)):setFieldValue("creditvalue", 0) && this.state.invchecked!==null && gsttype === undefined?
 setFieldValue("creditvalue", values.debitval):""} </> && this.onCreditChange(values)
  )}
   fullWidth/>)}
   /></fieldset>

<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm" disabled>
<NumericFormat name="creditvalue" className="form-control" value={values.debtotal!==""?values.debtotal:values.debitval}
label={values.debitval ? " " : "Value"} displayType={"input"} customInput={TextField} decimalScale={2}
fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} variant="standard"></NumericFormat>
</fieldset></div>




<div className="form-row">
{/* options={expensedata}  */}
{/* {this.state.invchecked===0 && this.state.selectedPO!==''?
<fieldset className="form-group col-md-6 mt-2 mb-2 test-sm">
<Autocomplete id="invitem" className="invitem-select" name="invitem" options={this.state.invitemdata.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name}
 
value={values.expense ? undefined : undefined} onChange={(e, value) => {
setFieldValue("invitem", value);}}
renderInput={(params) => (
<FormikTextField {...params} type="text" name="invitem" label="Map Inventory Ledger" variant="standard" fullWidth/>)}/>
 </fieldset>:""} */}

<fieldset className="form-group col-md-12 mt-3 mb-3 ">
<FormikTextField name="narration" className="form-control" multiline rows={2} label="Narration"
 variant="outlined"/></fieldset>
{/* 
{this.state.invchecked===1?
<>
<fieldset className="form-group col-md-3 mt-5 mb-0"><label htmlFor="btn-upload">
<input className="btn-choose" id="btn-upload" name="btn-upload" style={{ display: 'none' }}
type="file" onChange={this.selectFile} />
<Button className="btn-close" variant="outlined" type="upload" component="span" >
<span className="text-info">Select Supplier Invoice</span> </Button></label></fieldset>

<fieldset className="form-group col-md-5 mt-5 mb-0"> 
<ul className="list-group">{selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}</ul>
{currentFile && (
<Box className="mb25" display="flex" alignItems="center">
<Box width="100%" mr={1}><LinearProgress variant="determinate" value={progress}  /></Box>
<Box minWidth={35}><Typography variant="body2" color="textSecondary">{`${progress}%`}</Typography></Box>
</Box>)}</fieldset>
<fieldset  className="form-group col-md-4 mt-5 mb-0"> 
<button className="btn-upload" color="primary" variant="contained" component="span"
disabled={!selectedFiles || !this.state.credselval} onClick={this.upload}> Upload</button>
</fieldset>
<div className="form-group col-md-12 mt-1 mb-0"> 
<Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
<span className="text-green">{message}</span></Typography></div>
</>
:""} */}
                
<fieldset className="form-group col-md-12 mt-5">
 <button className="btn hovbuttonColour mr-1 btn-sm" type="submit"><i className="fas fa-pen"></i>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button></fieldset>

</div></>:""}
              
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withRouter(JournalPurchase);
