export let appmaster
export let auth
export let accounts
export let inventory
export let sales
export let purchase
export let screens
export let production
export let assetmanagement
export let salestravels

class Products {

    setProductAppmaster(pappmaster) {
        appmaster = pappmaster.toLocaleString()
      }
      setProductAuth(pauth) {
        auth = pauth.toLocaleString()
      }
    
      setProductAccounts(paccounts) {
        accounts = paccounts.toLocaleString()
      }
    
      setProductInventory(pinventory) {
        inventory = pinventory.toLocaleString()
      }
    
      setProductSales(psales) {
        sales = psales.toLocaleString()
      }
    
      setProductPurchase(ppurchase) {
        purchase = ppurchase.toLocaleString()
      }

      setProductProduction(pproduction){
        production = pproduction.toLocaleString()
      }

      setProductAssetmanagement(asset){
        assetmanagement = asset.toLocaleString()
      }
      setScreens(screen){
        screens=(screen.find(e=>e).screenslist).map(e=>e.screenname)
      }
    
      setSalesTravels(salesTravel){
        salestravels=salesTravel.toLocaleString()
      }
}
export default new Products()