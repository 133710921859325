import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import Menu from '../../Component/Menu'
import { currentTenant, thousandsdisplay, userid } from '../../Common/Credentials'
import moment from 'moment'
import { spin } from '../../Common/NewLoader'
import SalesServices from '../SalesServices'
import {NumericFormat} from 'react-number-format';
import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});


class TransactionOverdues extends Component {

  constructor(props) {
    super(props)

    this.state = {
      data: [],
      loading:false,
    }
  }
    
  refresh(){
    SalesServices.GetTransactionOverdues(currentTenant)
      .then(response => {  
          this.setState({ data: response.data})
          this.setState({loading:false})
        }   
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }
  
  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    this.setState({loading:true})

    this.refresh()
  }
    
  render() {
let columns=[
    // {name: "custid",label:"Customer Id",options: {filter: true,sort: true,}},
    {name: "customer",label: "Customer",options: {filter: true,sort: true, setCellProps: value => ({ style: { textAlign:'center'}}),}},
    {name: "custominvid",label: "Invoice Id",options: {filter: false,sort: true, setCellProps: value => ({ style: { textAlign:'center'}})}},
    {name: "customsoid",label: "Sale Order Id",options: {filter: false,sort: true, setCellProps: value => ({ style: { textAlign:'center'}})}},
    {name: "creditperiod",label: "Credit Period",options: {filter: false,sort: true,
      customBodyRender:(value)=>{
        return(<span>{value===undefined||value===null?0:value}</span>)
      },
      setCellProps: value => ({ style: { textAlign:'center'}})
    }},
    {name: "dayselapsed",label: "Elapsed Days",options: {filter: false,sort: true, setCellProps: value => ({ style: { textAlign:'center'}})}},
    {name: "balance",label: "Pending Amount",options: {filter: false,sort: true,
      customBodyRender:(tableMeta)=>{
        return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
    {name: "createdAt",label: "Date",options: {filter: false,sort: true,sortOrder: 'asc', 
    customBodyRender:(tableMeta)=>{
      return(moment(tableMeta).format('DD-MM-YYYY hh:mm'))
    }}},
  ];
   const options = {
    filterType: 'checkbox',
    selectableRows: "none",
    setRowProps: row => { 
      if (row[3]<=row[4]) {
        return {
          style: { background: "#f7dddd" }
        };
      }
      // if (row[5] === "Pending") {
      //   return {
      //     style: { background: "#ecf8fb" }
      //   };
      // }
    }
  }

    return (
          
      <div>
        
        <Header />
         <Menu/>
        <div className="content-wrapper">  
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              
              <li className="breadcrumb-item active">Overdues</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">              
              
                 
                      <div className="card-body">
                      <div>{this.state.loading === true ?<div className="text-center">{spin}</div>:
                         <ThemeProvider theme={theme}> 
                        <MUIDataTable
                          className="table-sm px-3"
                          title={"Overdues"}
                          data={this.state.data}
                          columns={columns}
                          options={options}
                        />
                        </ThemeProvider>
                      }</div>
                      </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer/>  
      </div>        
    )  
  }  
}
export default withRouter(TransactionOverdues)


