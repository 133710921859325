import { Autocomplete } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {userid, currentTenant, thousandsdisplay } from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import PurchaseServices  from '../PurchseServices';
import FileServices from "../../Common/upload/FileServices.js";
import { Table, TableCell} from "@mui/material";
import UploadFilesService from "../../Common/upload/UploadFilesService.js";
import moment from "moment";
import Spinner from 'react-bootstrap/Spinner'
import { TableHead } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableContainer } from "@mui/material";
import {NumericFormat} from "react-number-format";

let datalist = [];
// let data2 = [];
let count = 0;

let curmonth,year,day
let doctype = "doinvoive"

class NewBillofEntry extends Component {
  constructor(props) {
    super(props);
    // const id = this.props.location.state.id;
    this.state = {
        data:[],
        poid:[],
        portcode:'',
        purchaseitem:[],
        purchaseorderid:'',
        address:'',
        customduties:0,
        paymentmethod:"",
        transactionno:'',

        itemid: "",
        itemname: "",
        hsn: "",
        whid:'',
        categoryid:'',
        uom:'',
        price: "",
        tax: "",
        taxamount: "",
        totalprice:"",
        totalpricewtax:'',
        quantity: "",

        accepteditems:[],

      selectedFiles: undefined,
      currentFile: undefined,
       progress: 0,
       message: "",
       isError: false,
       fileInfos: [],
       fileInfo:"",
       partyid:'',   
       error: false, //<---- here
       errorMessage: {}, //<-----here
       loader:false,
       show:false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.selectFile=this.selectFile.bind(this);
    this.upload=this.upload.bind(this);
  }


  selectFile=event=> {
    this.setState({
      selectedFiles: event.target.files,
    });
  }

  upload() {
    let currentFile = this.state.selectedFiles[0];
   

    this.setState({currentFile: currentFile,});

    UploadFilesService.upload(currentFile,year,curmonth,doctype,this.state.partyid,day, (event) => {
      // var percent = Math.floor(event.percent);
      // if (percent >= 100) {
      //   this.setState({ progress: 100 });
      // } else {
      //   this.setState({ progress: percent });
      // }


      this.setState({progress: Math.round((100 * event.loaded) / event.total)});
      
    })
      .then((response) => {
        this.setState({
          message: response.data.message,
          isError: false
        });
        return FileServices.getFiles1(currentFile.name);
      })
      .then((files) => {this.setState({fileInfos: files.data,});
      })

      .catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
          isError: true
        });
      });

    this.setState({selectedFiles: undefined,});
  }

  handlePurchaseorder = (value) => {

    if(value !== undefined || value !== "" || value !== null){
    this.setState({ purchaseorderid: value })
    PurchaseServices.GetPurchases(value).then((response) => {
        this.setState({ data: response.data }, () =>
          this.setState({
            purchaseitem: this.state.data.purchaseitem,
          })
    )})
    count = 0;
        }
  }

  removeItemFromList(id) {
    const deleteitem = datalist.filter((item,index)=>item.iid === id);
    // deleteitem.map(data=>this.setState({pendingquantity:this.state.pendingquantity + parseInt(data.quantity)}))
    let delquantity = deleteitem.map(data=>data.quantity)
    const newlist = datalist.filter((item, index) => item.iid !== id);
    
    this.setState({
      accepteditems: newlist,
    });
    datalist = newlist;
    let itemsdetails = this.state.purchaseitem.find((data) => data.id === id);
      let deletedata={
        id: itemsdetails.id,
        itemid:itemsdetails.itemid,
        name: itemsdetails.name,
        hsn: itemsdetails.hsn,
        price: itemsdetails.price,
        uom: itemsdetails.uom,
        categoryid: itemsdetails.categoryid,
        tax: itemsdetails.tax,
        taxamount:itemsdetails.taxamount,
        quantity: itemsdetails.quantity,
        pending: itemsdetails.pending + parseFloat(delquantity),
        totalprice: itemsdetails.totalprice
        
              }
           let remlist=this.state.purchaseitem.filter((data) => data.id !==id)
              remlist.push(deletedata)
              this.setState({purchaseitem:remlist})
  }

  handleSelect = (e) => {
    this.setState({ status: e.target.value });
  };

  handleAccept = (id) => {
    let itemsdetails = this.state.purchaseitem.filter((data) => data.id === id);
    this.setState({ quantity: "", description: "",show:true });
    itemsdetails.map((data) =>
      this.setState(
        {
          id: data.id,
          itemid: data.itemid,
          itemname: data.name,
          hsn: data.hsn,
          price: data.price,
          tax: data.tax,
          categoryid:data.categoryid,
          taxamount: data.taxamount,
          quantity:data.quantity,
          uom:data.uom,
          totalprice:data.totalprice,
          totalpricewtax:data.totalpricewtax,
        })
    );
  };

  handelSubmit = () => {
    
    this.setState({loader:true})
    let data ={
      purchaseorder: this.state.purchaseorderid,
      tenantid: currentTenant,
      portcode:this.state.portcode,
      transactionno:this.state.transactionno,
      payment:this.state.paymentmethod,
      createdby:userid,
      boeitems:this.state.accepteditems,
    }
    PurchaseServices.newBillofEntry(data).then(()=>this.props.navigate("/billofentry"))
  }

  onSubmit() {
    // window.confirm("Are you sure you wish to delete this item?")
    if (this.state.quantity === "0") {
    } else {
      count = count + 1;

      let list = {
        iid: this.state.id,
        itemid: this.state.itemid,
        itemname: this.state.itemname,
        hsn: this.state.hsn,
        categoryid:this.state.categoryid,
        uom:this.state.uom,
        quantity: this.state.quantity,
        price: this.state.price,
        tax: this.state.tax,
        taxamount: this.state.taxamount,
        description: this.state.description,
        totalprice: this.state.totalprice,
        totalpricewtax:this.state.totalpricewtax,
        assessableamount:this.state.totalpricewtax,
        customduties:this.state.customduties,
        total:this.state.totalpricewtax+parseInt(this.state.customduties),

      };

      datalist.push(list);
      
     
        this.setState({purchaseitem:this.state.purchaseitem.filter((data) => data.id !== this.state.id)})
    }
    this.setState({ accepteditems: datalist, quantity: "", description: "",show:0});
    
  }

  refreshData() {
    PurchaseServices.GetIdforbillofentry(currentTenant).then((res) =>{
      this.setState({
        poid: res.data,
      });
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }
  componentDidMount() {
    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    this.refreshData();
    datalist = [];
    count = 0;

    year = new Date().getFullYear()
    day= new Date().getDate()
    let mn = new Date().getMonth()+1
    curmonth= moment(mn,'M').format('MMMM')
  }

  validate(values) {

   let errors = {}
    let isError = false;


    if(values.seller===undefined || values.seller.length ===0){
      isError = true;
      this.setState({
        error: true,
        errorMessage: { seller: "Please Select Suplier" }
      });} else
      if(isError!==true){
      //add else if for validating other fields (if any)
      this.setState(prevState => ({
        activeStep: prevState.activeStep + 1,
        error: false,
        errorMessage: {}
      }));
    
    }

    if(this.state.purchaseorderid ===''){
      errors.purchaseorder = "Please Select Purchase Order"
    }
    if (this.state.quantity === '' || this.state.quantity === undefined || this.state.quantity ==='0') {
      errors.quantity = "Quantity is required"
    } 
    if (this.state.quantity > this.state.pendingquantity) {
      errors.quantity = `Avilable quantity is ${this.state.pendingquantity}`
    }
    if (this.state.description === '') {
      errors.description = "Pelese provide description"
    }
    return errors;
  }


  render() {
    let {
        data,
        poid,
        portcode,
        purchaseitem,
        purchaseorderid,
        address,
        itemid,
        itemname,
        quantity,
        customduties,
        paymentmethod,

        selectedFiles,
        currentFile,
         progress,
         message,
         isError,
         fileInfos,
         fileInfo,
         partyid,   
         error,
         errorMessage,
     
    } = this.state;

    return (
      <div>
      <Header/>
        <Menu/>
        <div className="content-wrapper">
          <div className="card ">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/billofentry" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/billofentry">Bill of Entry </Link>
              </li>
              <li className="breadcrumb-item active">New Bill of Entry</li>
            </ol>
            <div className="card-body">
              <Formik
                initialValues={{
                    data,
                    poid,
                    portcode,
                    purchaseitem,
                    purchaseorderid,
                    address,
                    itemid,
                    itemname,
                    quantity,
                    customduties,
                    paymentmethod,

                    selectedFiles,
                    currentFile,
                     progress,
                     message,
                     isError,
                     fileInfos,
                     fileInfo,
                     partyid,   
                     error,
                     errorMessage,
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({setFieldValue,values}) => (
                  <Form>
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="itemid"
                          placeholder="Id"
                        />
                      </fieldset>
                      {/* {this.state.seller ===''?'': */}
                        <fieldset className="form-group col-md-3">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="poid"
                            options={poid}
                            getOptionLabel={(option) => option}
                            
                            
                            value={values.purchaseorder?undefined:undefined}
                            onChange={(e, value) => {
                            setFieldValue("purchaseorder", value);
                              }}
                            renderInput={params => (  
                              <FormikTextField
                                  {...params}
                                className="form-control"
                                type="text"
                                name="purchaseorder"
                                onBlur={(e) =>{this.handlePurchaseorder(e.target.value)}}
                                label="Select Purchaseorder Id"
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>

                       {/* } */}
                      {/* <div className="form-group col-md-12" /> */}
                      {this.state.purchaseorderid === "" || this.state.purchaseitem.length === 0?'':<>
                          <fieldset className="form-group col-md-12"/>
                    
                          <TableContainer >
                          <Table aria-label="customized table" className="table-sm mb-3">
                            <TableHead>
                              <TableRow>
                                <TableCell  className="text-center">Item</TableCell>
                                <TableCell  className="text-center">Price</TableCell>
                                {/* <TableCell  className="text-center">Tax</TableCell>
                                <TableCell  className="text-center">Tax Amount</TableCell> */}
                                <TableCell  className="text-center">UOM</TableCell>
                                <TableCell  className="text-center">Quantity</TableCell>
                                <TableCell  className="text-center">Tax</TableCell>
                                <TableCell  className="text-center">Total Price</TableCell>
                                <TableCell  className="text-center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.purchaseitem.map((data, index) => (
                                <TableRow key={index}>
                                  <TableCell  className="text-center">{data.name}</TableCell>
                                  {/* <TableCell  className="text-center">{data.hsn}</TableCell> */}
                                  <TableCell  className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {data.price} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></TableCell>
                                  {/* <TableCell  className="text-center">{data.tax}</TableCell> */}
                                 
                                  <TableCell  className="text-center">{data.uom}</TableCell>
                                  <TableCell  className="text-center">{data.quantity}</TableCell>
                                    <TableCell  className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {data.taxamount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></TableCell>
                                  <TableCell  className="text-right">
                                  <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {data.totalpricewtax} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>
                                    </TableCell>
                                  <TableCell  className="text-center">
                                    {/* {this.state.purchaseitem.map((data)=>data.iid).includes(data.id)?'': */}
                                    <button
                                      data-tip
                                      data-for="return"
                                      className="text-primary"
                                      style={{ paddingLeft: "7px" }}
                                      onClick={() => this.handleAccept(data.id)}
                                    >
                                     Add
                                    </button>
                                  {/* } */}
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                  <TableCell className="text-center">Total</TableCell>
                                   <TableCell/> <TableCell/> <TableCell/> <TableCell/>
                              <TableCell  className="text-right">
                                  <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.data.pototalwtax} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>
                                    </TableCell>
                                    <TableCell/>
                              </TableRow>

                            </TableBody>
                          </Table>
                          </TableContainer>

                        {/* </div> */}

                        {this.state.show === false?"":<>
     
                        <fieldset className="form-group col-md-3">
                          <span className="text-dark">Item Name</span>
                          <> : &nbsp; {this.state.itemname}</>
                        </fieldset>
                        <fieldset className="form-group col-md-3"><span>{`Quantity : ${this.state.quantity}`} </span></fieldset>

                        <fieldset className="form-group col-md-6"/>

                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="number"
                            name="customduties"
                            label="Custom Duties + Additional Charges"
                            onChange={(e) => this.setState({ customduties: e.target.value })}
                          />
                        </fieldset>

                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="description"
                            label="Description"
                            onChange={(e) =>this.setState({ description: e.target.value })}
                          />
                        </fieldset>
                        <fieldset className="form-group col-md-12">
                        <button className="btn btn-sm hovbuttonColour" type = "submit">
                          Add
                        </button>
                        </fieldset>
                      </>}
                   
                        </>}
                      <div className="form-group col-md-3" />
                      {count === 0 || this.state.accepteditems.length === 0?'': <>
                        <div className="form-group col-md-12 mt-3">
                          {this.state.accepteditems === [] || this.state.accepteditems.length === 0?'':<>
                          <Table className="table-sm">
                            <TableHead>
                              <TableRow>
                                <TableCell className="text-center">Item Name</TableCell>
                                <TableCell  className="text-center">Price</TableCell>
                                <TableCell  className="text-center">Quantity</TableCell>
                                <TableCell  className="text-center">Assessable Value</TableCell>
                                <TableCell  className="text-center">Custom Duties + Additional Charges</TableCell>
                                <TableCell  className="text-center">Taxableamount</TableCell> 
                                <TableCell  className="text-center">Description</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.accepteditems.map((data, index) => (
                                <TableRow key={index} style={{ textAlign: "center" }}>
                                  <TableCell  className="text-center">{data.itemname}</TableCell>
                                  <TableCell  className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {data.price} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></TableCell>
                                  <TableCell  className="text-center">{data.quantity}</TableCell>
                                  <TableCell  className="text-right">
                                  <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {data.totalpricewtax} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>
                                    </TableCell>
                                  <TableCell  className="text-center">
                                  <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {data.customduties} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>
                                  </TableCell>
                                  <TableCell  className="text-center">
                                  <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {data.total} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>
                                  </TableCell>
                                  <TableCell  className="text-center">{data.description}</TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          </>}
                        </div>

                        {/* <div className="form-row"> */}
                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="portcode"
                            label="Port Code"
                            onChange={(e) => this.setState({ portcode: e.target.value })}
                          />
                        </fieldset>

                        <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="text"
                            name="paymentmethod"
                            label="Paid Through"
                            onChange={(e) => this.setState({ paymentmethod: e.target.value })}
                          />
                        </fieldset>
                      
                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="transactionno"
                            label="Transaction No."
                            onChange={(e) => this.setState({ transactionno: e.target.value })}
                          />
                        </fieldset>

                      </>
                      
                      
                      }
                      <div className="form-group col-md-3" />
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="form-group col-md-12" />
              {count === 0 || this.state.accepteditems === []?'': <>
                <button className="float-center btn btn-sm hovbuttonColour mr-2" onClick={this.handelSubmit}>
                {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:
                  "Submit"}
                </button>
                <Link as="button" className="btn btn-sm deletebutton" to={{ pathname: "/grn" }}>
                  <b> X </b> Cancel 
                </Link>
              </>}
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

        <Footer />
      </div>
    );
  }
}
export default withRouter(NewBillofEntry);
