import { Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { FormikTextField } from '../../CommonComponents/FormField.js';
import { Spinner } from 'react-bootstrap';
import { Autocomplete } from '@mui/material';
import MasterService from '../MasterService.js';


class AddCity extends Component {
  constructor(props) {
    super(props)
    this.state = {
      city: '',
      message:'',
      countriesList:[],
      country:'',
      countryid:'',
      statesList:[],
      state:'',
      stateid:'',
      loader:false,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(values) {
     this.setState({loader:true})
    let data = { 
      name:this.state.city.toUpperCase(),
      state:this.state.stateid,
    }
    MasterService.AddCity(data).then(() => 
    this.props.navigate('/cities'))
    .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
      this.setState({message:e.response.data.message,loader:false})
    })

  }

  handleCountryChange = (value, values) => {
    if (value !== undefined || value !== "") {
      this.setState({ country: value, countryid: values.id });
      MasterService.getStates(values.id).then(res=>{
        this.setState({statesList:res.data})
      })
    }
  };

  handleStateChange = (value, values) => {
    if (value !== undefined || value !== "") {
      this.setState({ state: values.name, stateid: values.id });
    }
  };

  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    MasterService.getAllCountries().then(res=>this.setState({countriesList:res.data,loading:false})) 
    
 }


  validate(values) {

    let errors = {}

     if (this.state.city === undefined|| values.city==='') {
     errors.city = "city is Required";
      }
      if (this.state.state === undefined|| this.state.state==='') {
        errors.state = "State is Required";
         }
         if (this.state.country === undefined|| this.state.country==='') {
          errors.country = "Country is Required";
           }
    return errors;
  }

  render() {
     let {id,city,state,stateid,country,countryid} = this.state;
 
    return (
      
      <div>
         <Header/>
         <Menu/>
        <div className="content-wrapper">
          <div className="card">
           
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/cities"}} state={{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/superadminmasters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/cities">Cities</Link>
              </li>
              <li className="breadcrumb-item active">Add City</li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">Add City</h3>
            </div>
            <div className="card-body">
            
              <Formik
                initialValues={{id,city,state,stateid,country,countryid}}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}>
                {() => (
                  <Form autoComplete="off">
                    <div className="form-row">
                    <fieldset className="col-md-3">
                        <Autocomplete
                          id="controlled-demo"
                          options={this.state.countriesList}
                          getOptionLabel={(option) => option.name}
                          
                          
                          onChange={(e,values) => this.handleCountryChange(e.target.value,values)}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              label="Select Country"
                              variant="standard"
                              type="text"
                              name="country"
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="col-md-3">
                        <Autocomplete
                          id="controlled-demo"
                          options={this.state.statesList}
                          getOptionLabel={(option) => option.name}
                          
                          
                          onChange={(e,values) => this.handleStateChange(e.target.values,values)}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              label="Select State"
                              variant="standard"
                              type="text"
                              value={this.state.state}
                              name="state"
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3">
                      <FormikTextField className="form-control"  type="text" name="city" label="Enter City" onChange={(e)=>this.setState({city:e.target.value,message:''})}/>
                      </fieldset>
                    </div>
                    <div className="form-group col-md-12 text-red">{this.state.message}</div>
                    {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>: <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fas fa-save mr-2"/>Save</button>}
                    <Link as="button" className="btn btn-sm deletebutton ml-1"  to={{pathname: '/city'}} ><b style={{color:"#FA5733"}}> X</b> Cancel</Link> 
                  
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

      <Footer/>
      </div>
    );
  }
}
export default withRouter(AddCity)