import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import MUIDataTable from "mui-datatables";
import {NumericFormat} from "react-number-format";
import { clientname, currentTenant, logo, thousandsdisplay, userid } from "../../Common/Credentials";
import InventoryService from "../InventoryService";
import { inventoryid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import { spin } from "../../Common/NewLoader";
import Menu from "../../Component/Menu";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { FormControlLabel, ThemeProvider } from "@mui/material";
import { createTheme, IconButton, Tooltip } from "@mui/material";
import jsPDF from "jspdf";
import moment from "moment";
import autoTable from "jspdf-autotable"
import IOSSwitch from '../../CommonComponents/IOSSwitch'
import Swal from "sweetalert2";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class DamageTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      completed:[],
      cancelled:[],
      services:[],
      tenantdetails:{},
      message: "",
      loading:false,
      producttype:false,
      active:true,
      comp:false,
      cancel:false,
    };
    this.refreshItems = this.refreshItems.bind(this);
    // this.deleteItem = this.deleteItem.bind(this);
    // this.handleChange = this.handleChange.bind(this)
  }

  
  Approve = (id) =>{
    let data = {
      id:id,
      updatedby:userid
    }
    Swal.fire({
      title: "Approve Losses?",
      text: "Once approved quantity cannot be reverted back.",
      icon: "question",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonColor: "#152f5f",
      confirmButtonText: "Approve",
      reverseButtons: true,
    }).then((result) => {
      if(result.value){
    InventoryService.ApproveLosses(id)
      .then(() => this.refreshItems())
      .catch((err)=>err)
      }})
  }


  delete = (id)=>{
    let data = {
      id:id,
      updatedby:userid
    }
    InventoryService.DeleteLossesentery(data)
      .then(() => this.refreshItems())
      .catch((err)=>err)
  }

  handleActiveChange=()=>{
    this.setState({pending:false,active:true,comp:false,cancel:false})
  }
  handlecompChange=()=>{
    this.setState({pending:false,comp:true,active:false,cancel:false})
  }
  // handleCancelChange=()=>{
  //   this.setState({pending:false,cancel:true,comp:false,active:false})
  // }


  refreshItems() {
    InventoryService.GetTenantdetails(currentTenant).then(res=>
      this.setState({tenantdetails:res.data.country})
      )
    // if(this.state.items.length ===0)
    InventoryService.getDamageData(currentTenant).then((response) => {
      this.setState({ 
        items: response.data.filter(e=>e.status==="Pending" ||e.status==="Approved" ).sort((a, b) => a.date < b.date ? 1 : -1),
        completed: response.data.filter(e=>e.status==="Completed").sort((a, b) => a.date < b.date ? 1 : -1),
        cancelled: response.data.filter(e=>e.status==="Cancelled").sort((a, b) => a.date < b.date ? 1 : -1),
        loading:false
     });
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
   
  }

  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
     this.setState({loading:true})

    this.refreshItems();
    
    if (this.props.location.state !== undefined) {
      this.setState({ message: this.props.location.state.message });
    }
  }


  render() {
    const columns = [
      {name: "id",label: "Item id",options: {filter: false,sort: false,display:false,viewColumns:false}},
      {name: "itemname",label: "Item name",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "category",label: "Category",options: {filter: true,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "whname",label:"Warehouse",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "batchid",label: "Batch",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "quantity",label: "Quantity",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "price",label: "Unit Price",options: {filter: false,sort: true,sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      {name: "totalloss",label: "Total Loss",options: {filter: false,sort: true,sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      {name: "comment",label: "Comment",options: {filter: true,sort: true,setCellProps: () => ({ align: 'center' })}},
    //   {name: "createdAt",label: "Date",options: {filter: false,sort: true,sortOrder: 'asc',
    //   customBodyRender:(value)=>{
    //     return(<>{moment(value).format("DD-MM-YYYY HH:mm")}</>)
    //   },
    // }},
      // {name: "status",label: "Status",options: {filter: true,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "status",label: "Status",options: {filter: false,sort: false,empty: true, display:inventoryid === '2' || inventoryid === '3'?true:false,
         customBodyRender: (value, tableMeta, updateValue) => {
           return (
           value ==="Pending"?
           <>
             <Link as="button" className="fa fa-edit text-primary mr-2" to={{pathname: "/addlosses"}} state ={ { id: tableMeta.rowData.find(e=>e), pagename: "Damage" }}>
            </Link>
              <span className="fa fa-check text-primary cursorPointer" onClick={(e) => this.Approve(tableMeta.rowData.find(e=>e)) }>
              </span>
           </>
           :value
           );
         }
       }
     },
     ];
     
     const options = {
       filterType: 'checkbox',
       selectableRows: "none",
       customToolbar: () => {
        return (<>
          {inventoryid === '2' || inventoryid === '3'?
            <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/addlosses"}} state ={ { id: -1, pagename: "Damage" }}>
              <CustomToolbar />
            </Link>
            :''
          }  
        </>);
      }
     }

    return (
      <div>
      <Header/>
          <Menu/>
          <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/inventory">Home</Link>
            </li>

            <li className="breadcrumb-item active">Losses</li>
          </ol>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                 

                    <div className="card-body p-0">
                      <div>{this.state.loading === true ? <div className="text-center">{spin}</div> : 

                        <div className="row">
                      <fieldset className="form-group col-md-4">
                        <span className="mx-3">Active</span>
                        <FormControlLabel
                          control={<IOSSwitch className="sm" checked={this.state.active} onChange={this.handleActiveChange} name="checkedB" />}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        <span className="mx-3">Completed</span>
                        <FormControlLabel
                          control={<IOSSwitch className="sm" checked={this.state.comp} onChange={this.handlecompChange} name="checkedB" />}
                        />
                      </fieldset>
                      {/* <fieldset className="form-group col-md-4">
                        <span className="mx-3">Cancelled</span>
                        <FormControlLabel
                          control={<IOSSwitch className="sm" checked={this.state.cancel} onChange={this.handleCancelChange} name="checkedB" />}
                        />
                      </fieldset> */}
                      <fieldset className="form-group col-md-12">
                        {this.state.active?
                      <ThemeProvider theme={theme}> 
                      <MUIDataTable
                       className="table-sm px-3"
                       title={"Losses"}
                        data={this.state.items}
                        columns={columns}
                        options={options}
                        />
                      </ThemeProvider>
                        :""}
                        {this.state.comp?
                      <ThemeProvider theme={theme}> 
                      <MUIDataTable
                       className="table-sm px-3"
                       title={"Losses"}
                        data={this.state.completed}
                        columns={columns}
                        options={options}
                        />
                      </ThemeProvider>
                      :""}

                      {/* {this.state.cancel?
                      <ThemeProvider theme={theme}> 
                      <MUIDataTable
                       className="table-sm px-3"
                       title={"Losses"}
                        data={this.state.cancelled}
                        columns={columns}
                        options={options}
                        />
                      </ThemeProvider>
                      :""} */}

                      </fieldset>
                      </div>
                      }
                      </div>

                      </div>
                   
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
        </div>
        <Footer/>   
      </div>
    );
  }
}
export default withRouter(DamageTable);
