import moment from "moment";
import React from "react";

export default function Footer() {
  return (<>
    <footer className="main-footer" >
      <div>
        {/* <div className="textColour text-bold float-right"><b>Version</b> 1.0.0</div> */}
        {/* <br/> */}
        <div className="float-right">
      Copyright©2023-24 <span className="textColour text-bold">Finanzz</span>  All rights reserved.
      </div>
      </div>
      <br/>
        {/* <div className="d-flex justify-content-start">Powered by<span className="textColour text-bold">&nbsp;Jnana Technologies&nbsp;</span> @{moment().format("yyyy")}</div> */}
    </footer>
  </>);
}
