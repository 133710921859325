import { createTheme } from "@mui/material";
import { Form, Formik } from "formik";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import { appmasterid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { ThemeProvider } from "@mui/material";
import MasterService from "../MasterService";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import { Autocomplete } from "@mui/material";
import { Spinner } from "react-bootstrap";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class Screens extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ScreensList: [],
      screen: '',
      newscreen: false,
      ProductsList: [],
      products: '',
      productid: '',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
   this.refresh()
  }
  refresh(){
    MasterService.getProductsScreens(currentTenant).then((response) => {
      this.setState({ ProductsList: response.data.pelist})
    })

    MasterService.getAllScreens().then(response=>{
      this.setState({ScreensList:response.data,newscreen:false})
    })
  }

  handleProducts=(value, values) =>{
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({ productid: values.id,products: values.products  })
    }else{
      this.setState({ products: '', productid: '',screen:'' })
    }
  }

  onSubmit() {
    let data = {
      screenname:this.state.screen,
      pid:this.state.productid,
      products:this.state.products
    }
       MasterService.AddScreen(data).then(()=>this.refresh())
  }
  validate(values) {
    let errors = {};

    if (this.state.screen === '') {
      errors.screen = "Please Enter Screen";
    }

    if (this.state.productid === '') {
      errors.products = "Please Select Product";
    }
    return errors;
  }
  render() {
    let { screens, screen, newscreen, ProductsList, products, productid } = this.state
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          sortOrder: "asc",
        },
      },
      {
        name: "product",
        label: "Product",
        options: { filter: true, sort: true },
      },
      {
        name: "screenname",
        label: "Screen",
        options: { filter: true, sort: true },
      },
      {
        name: "Delete",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <span
                as="button"
                data-tip
                data-for="delete"
                className="fa fa-trash text-danger"
                onClick={() => {
                  if (
                    window.confirm(`Are you sure you wish to delete Screen ${tableMeta.rowData.find((e) => e)}?`)
                  )
                    this.deleteScreen(tableMeta.rowData.find((e) => e));
                }}
              ></span>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <span
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                onClick={() => this.setState({ newscreen: true })}
              >
                <CustomToolbar />
              </span>
            ) : (
              ""
            )}
          </>
        );
      },
    };
    return (
      <div>
      <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/superadminmasters">Home</Link>
              </li>
              <li className="breadcrumb-item active">Screens</li>
            </ol>
            <div className="form-row">
              <div className="card-body">
                <div>
                  {this.state.loading === true ? (
                    <div className="text-center">{spin}</div>
                  ) : (<>
                    <Formik
                      initialValues={{ screens, screen, newscreen, ProductsList, products, productid }}
                      onSubmit={this.onSubmit}
                      validateOnChange={false}
                      validate={this.validate}
                      enableReinitialize={true}>
                      {({ setFieldValue, values }) => (
                        <Form autoComplete="off">
                          <div className="form-row mb-4">
                            {this.state.newscreen === false ? '' : <>
                              <fieldset className="form-group col-md-4">
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="screen"
                                  value={this.state.screen}
                                  label="Screen"
                                  variant="standard"
                                  onChange={(e) =>
                                    this.setState({
                                      screen: e.target.value,
                                    })
                                  }
                                  fullWidth
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-4">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="products"
                                  options={ProductsList}
                                  getOptionLabel={(option) => option.products}
                                  value={values.products ? undefined : undefined}
                                  onChange={this.handleProducts}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      value={this.state.products}
                                      name="products"
                                      label="Select Product"
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-4">
                                {this.state.loader === true ? <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button> : <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fas fa-save mr-2" />Save</button>}
                                <span as="button" className="btn btn-sm bg-white shadow-sm ml-3 deletebutton" onClick={()=>this.setState({newscreen:false})} ><b style={{ color: "#FA5733" }}> X</b> Cancel</span>
                              </fieldset>
                            </>
                            }
                          </div>
                        </Form>
                      )}
                    </Formik>
                    <ThemeProvider theme={theme}>
                      <MUIDataTable
                        className="table-sm px-3"
                        title={"Screens"}
                        data={this.state.ScreensList}
                        columns={columns}
                        options={options}
                      />
                    </ThemeProvider>
                  </>)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Screens);
