import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { spin } from "../../Common/NewLoader";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import ProductionServices from "../ProductionServices";
import { currentTenant, userid } from "../../Common/Credentials";
import { Autocomplete } from "@mui/material";
import Footer from "../../Component/Footer";
import { Form, Formik } from "formik";
import withRouter from "../../withRouter";

const NewInProcess = () => {
  const navigate = useNavigate();
  const [joborder, setJoborder] = useState([]);
  const [joid, setJoId] = useState("");
  const [joborderList, setJoborderList] = useState("");
  const [joborderitemsList, setJobOrderitemsList] = useState([]);
  const [itemname, setItemName] = React.useState("");
  const [itemid, setItemId] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [iquantity, setIQuantity] = React.useState("");
  const [uom,setUom] = React.useState("");
  const [storeList, setStoreList] = React.useState([]);
  const [store,setStore] = React.useState("");
  const [storeid,setStoreId]= React.useState("");
  const [outputitemsList] = React.useState([]);
  

  useEffect(() => {
    ProductionServices.getJobOrderIdListForInprocess(currentTenant).then((response) => {
      setJoborder(response.data);
    });

  }, []);

  const handlejoidChange = (newValue) => {
    newValue === "" || newValue === null || newValue === undefined
      ? setJoId("")
      : setJoId(newValue);
    setJoborderList([]);
    if (newValue) {
      ProductionServices.getallJobOrderItems(newValue).then((response) => {
        setJoborderList(response.data);
        setJobOrderitemsList(response.data.finalproduct)
        // setWorkCenter(response.data.workcenter)
        // setWorkCenterId(response.data.workcenterid)
        // setWarehouse(response.data.warehouse)
        // setWhId(response.data.whid)
        ProductionServices.GetallStoreNames(response.data.workcenterid).then(res=>setStoreList(res.data))
      });
    }
  };

  const handlestoreChange =  (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setStore("")
      setStoreId("")
    }else{
      setStore(newValue.name)
      setStoreId(newValue.id)
    }
  }

  const handleIndentItem = (e)=>{
    setItemName(e.itemname)
    setItemId(e.itemid)
    setQuantity(e.pendingquantity)
    setUom(e.uom)
  }

  const handleIquantityChange = e=>{
    setIQuantity(e.target.value)
  }

  const handleAddtoList =() =>{
   if(itemid !== "" && itemname !== "" && iquantity !== "" && parseFloat(iquantity)>0 && parseFloat(iquantity)<=quantity ){
    if(outputitemsList.find(e=>e.itemid === itemid) === undefined){   
     let data = {
      itemid:itemid,
      itemname:itemname,
      quantity:parseFloat(iquantity),
      uom:uom
    }
    outputitemsList.push(data)
  }else(
    outputitemsList.find(e=>e.itemid === itemid).quantity = outputitemsList.find(e=>e.itemid === itemid).quantity + parseFloat(iquantity) 
  )
    joborderitemsList.find(a=>a.itemid === itemid).pendingquantity = joborderitemsList.find(a=>a.itemid === itemid).pendingquantity - parseFloat(iquantity)
    setItemId("")
    setItemName("")
    setIQuantity(0)
    setQuantity(0)
  }
}

  const handleSubmit = () => {
    let data = {
      joid: joborderList.joborder.id,
      store:storeid,
      tenantid: currentTenant,
      createdby: userid,
      updatedby: userid,
      outputitems:outputitemsList,
    };
    ProductionServices.NewInprocess(data).then((response) =>
      navigate("/inprocess")
    );
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/inprocess" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li> 
            <li className="breadcrumb-item text-secondary">
              <Link to="/production">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/inprocess">InProcess</Link>
            </li>
            <li className="breadcrumb-item active">New InProcess</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              {joborder === [] ? (
                <div className="text-center">{spin}</div>
              ) : (
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title"> New InProcess</h3>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={{}}
                      // onSubmit={onSubmit}
                      validateOnChange={false}
                      // validate={validate}
                      enableReinitialize={true}
                    >
                      {({ setFieldValue, values }) => (
                        <Form
                          autoComplete="off"
                          style={{ paddingLeft: "30px" }}
                        >
                          <div className="form-row mt-3">
                            <fieldset className="col-md-3">
                              <Autocomplete
                                id="controlled-demo"
                                options={joborder}
                                getOptionLabel={(option) => option || ""}
                                
                                
                                value={joid}
                                onChange={(event, newValue) =>
                                  handlejoidChange(newValue)
                                }
                                error={joid === "" || joid === undefined}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select JobOrder Id"
                                    variant="standard"
                                    error={joid === "" || joid === undefined}
                                    helperText={
                                      joid === "" || joid === undefined
                                        ? "Empty field!"
                                        : " "
                                    }
                                  />
                                )}
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-3">
                              <Autocomplete
                                id="subaccount"
                                className="subacc-select"
                                name="store"
                                options={storeList}
                                getOptionLabel={(option) => option.name}
                                
                                
                                value={values.store ? undefined : undefined}
                                onChange={handlestoreChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="form-control"
                                    type="text"
                                    name="store"
                                    value={store}
                                    label={`Select Store (${store})`}
                                    variant="standard"
                                    error={store === ""}
                                    helperText={
                                      store === "" ? "Empty field!" : " "
                                    }
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>
                         

                          {joborderitemsList.length === 0 ? (
                            ""
                          ) : (
                            <>
                              <TableContainer>
                                <Typography> Items List</Typography>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="text-center">
                                        Itemname
                                      </TableCell>
                                      <TableCell className="text-center">
                                       Estimated Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                       Pending Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                       UOM
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {joborderitemsList.map((e, index) => (
                                      <TableRow key={index}>
                                        <TableCell className="text-center">
                                          {e.itemname}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.quantity}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.pendingquantity}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.uom}
                                        </TableCell>
                                        <TableCell className="text-center">
                                         {e.quantity === 0 ? '':<button className="btn btn-sm hovbuttonColour" onClick={()=>handleIndentItem(e)}>
                                            Output 
                                            </button>
                                          }
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              </>
                          )}
                      
                              {itemname === ""?'':<>
                              <fieldset className="col-md-3 mt-3">
                              <TextField
                                    className="form-control"
                                    type="text"
                                    name="itemname"
                                    value={itemname}
                                    label={`Item Name (${itemname})`}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                  />
                              </fieldset>
                              <fieldset className="col-md-3 mt-3">
                              <TextField
                                    className="form-control"
                                    type="text"
                                    name="uom"
                                    value={uom}
                                    label={`Item Name (${uom})`}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                  />
                              </fieldset>

                              <fieldset className="col-md-3 mt-3">
                              <TextField
                                    className="form-control"
                                    type="text"
                                    name="quantity"
                                    value={iquantity}
                                    label={`Output Quantity (Pending quantity: ${quantity})`}
                                    onChange={handleIquantityChange}
                                    variant="standard"
                                    error={iquantity === "" || iquantity>quantity}
                                    helperText={
                                      iquantity === "" ? "Empty field!" : " "
                                    }
                                    fullWidth
                                  />
                              </fieldset>
                              
                              <fieldset className="col-md-12 mt-3">
                                <button
                                  className="btn btn-sm hovbuttonColour"
                                  onClick={handleAddtoList}
                                  type="button"
                                >
                                  Add to List
                                </button>
                              </fieldset>
                              </>}
                             {outputitemsList.length === 0?'':<>
                              <TableContainer className="mt-4">
                                <Typography>Finished Items List</Typography>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="text-center">
                                        Itemname
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                          UOM
                                        </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {outputitemsList.map((e, index) => (
                                      <TableRow key={index}>
                                        <TableCell className="text-center">
                                          {e.itemname}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.quantity}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.uom}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>

                              <fieldset className="col-md-12 mt-3">
                                <button
                                  className="btn btn-sm hovbuttonColour"
                                  onClick={handleSubmit}
                                  type="button"
                                >
                                  Submit
                                </button>
                              </fieldset>
                              </>
                                }
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(NewInProcess);
