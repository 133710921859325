import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { NumericFormat } from "react-number-format";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { currency, thousandsdisplay } from "../../Common/Credentials";

const DataRows = ({ Orders, row, index }) => {
  const [open, setOpen] = useState(false);
  const [showquantity, setShowquantity] = useState(false);

  return (
    <React.Fragment key={index}>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen((op) => !op);
              setShowquantity(false);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {row.soidforadjust === null ||
          row.soidforadjust === "" ||
          row.soidforadjust === undefined
            ? Orders.customsoid
            : row.soidforadjust}
        </TableCell>
        <TableCell className="text-center">{row.name}</TableCell>
        {Orders.taxtype === "NA" ? (
          ""
        ) : (
          <TableCell className="text-center">{row.hsn}</TableCell>
        )}
        <TableCell className="text-right">
          <NumericFormat
            displayType={"text"}
            value={row.price}
            decimalScale={2}
            fixedDecimalScale={true}
            disabled={true}
            thousandSeparator={true}
            thousandsGroupStyle={thousandsdisplay}
          ></NumericFormat>
        </TableCell>
        {currency === Orders.currency ? (
          ""
        ) : (
          <TableCell className="text-right">
            <NumericFormat
              displayType={"text"}
              value={row.price * Orders.curconversionrate}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          </TableCell>
        )}
        {currency === Orders.currency ? (
          ""
        ) : (
          <TableCell className="text-center">{row.pricevariance}</TableCell>
        )}
        <TableCell className="text-center">{row.salesenum}</TableCell>
        <TableCell
          className="text-center cursorPointer"
          onClick={() => {
            setShowquantity((sq) => !sq);
            setOpen(false);
          }}
        >
          {parseFloat(row.quantity)}{" "}
          {row.uom}
          {showquantity ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </TableCell>
        <TableCell className="text-center">
          {parseFloat(row.pending)}{" "}
          {row.uom}
        </TableCell>
        <TableCell className="text-right">
          <NumericFormat
            displayType={"text"}
            value={row.discountamount}
            decimalScale={2}
            fixedDecimalScale={true}
            disabled={true}
            thousandSeparator={true}
            thousandsGroupStyle={thousandsdisplay}
          ></NumericFormat>
          &nbsp;({row.discount}%)
        </TableCell>
        {Orders.currency === currency ? (
          ""
        ) : (
          <TableCell className="text-right">
            <NumericFormat
              displayType={"text"}
              value={row.discountamount * Orders.curconversionrate}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
            &nbsp;({row.discount}%)
          </TableCell>
        )}
        <TableCell className="text-right">
          <NumericFormat
            displayType={"text"}
            value={row.totalprice}
            decimalScale={2}
            fixedDecimalScale={true}
            disabled={true}
            thousandSeparator={true}
            thousandsGroupStyle={thousandsdisplay}
          ></NumericFormat>
        </TableCell>
        {Orders.currency === currency ? (
          ""
        ) : (
          <TableCell className="text-right">
            <NumericFormat
              displayType={"text"}
              value={row.totalprice * Orders.curconversionrate}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          </TableCell>
        )}
        {Orders.taxtype === "NA" ? (
          ""
        ) : (
          <>
            {Orders.taxtype === "GST" && Orders.gsttype === "INTRASTATE" ? (
              <>
                <TableCell className="text-right">
                  <NumericFormat
                    displayType={"text"}
                    value={row.taxamount / 2}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    disabled={true}
                    thousandSeparator={true}
                    thousandsGroupStyle={thousandsdisplay}
                  ></NumericFormat>
                  &nbsp;({row.tax / 2}%)
                </TableCell>
                <TableCell className="text-right">
                  <NumericFormat
                    displayType={"text"}
                    value={row.taxamount / 2}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    disabled={true}
                    thousandSeparator={true}
                    thousandsGroupStyle={thousandsdisplay}
                  ></NumericFormat>
                  &nbsp;({row.tax / 2}%)
                </TableCell>
                {Orders.currency === currency ? (
                  ""
                ) : (
                  <>
                    <TableCell className="text-right">
                      <NumericFormat
                        displayType={"text"}
                        value={
                          (row.taxamount / 2) *
                          Orders.curconversionrate
                        }
                        decimalScale={2}
                        fixedDecimalScale={true}
                        disabled={true}
                        thousandSeparator={true}
                        thousandsGroupStyle={thousandsdisplay}
                      ></NumericFormat>
                      &nbsp;(
                      {row.tax / 2}%)
                    </TableCell>
                    <TableCell className="text-right">
                      <NumericFormat
                        displayType={"text"}
                        value={
                          (row.taxamount / 2) *
                          Orders.curconversionrate
                        }
                        decimalScale={2}
                        fixedDecimalScale={true}
                        disabled={true}
                        thousandSeparator={true}
                        thousandsGroupStyle={thousandsdisplay}
                      ></NumericFormat>
                      &nbsp;(
                      {row.tax / 2}%)
                    </TableCell>
                  </>
                )}
              </>
            ) : (
              <>
                <TableCell className="text-right">
                  <NumericFormat
                    displayType={"text"}
                    value={row.taxamount}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    disabled={true}
                    thousandSeparator={true}
                    thousandsGroupStyle={thousandsdisplay}
                  ></NumericFormat>
                  &nbsp;({row.tax}%)
                </TableCell>
                {Orders.currency === currency ? (
                  ""
                ) : (
                  <TableCell className="text-right">
                    <NumericFormat
                      displayType={"text"}
                      value={
                        row.taxamount * Orders.curconversionrate
                      }
                      decimalScale={2}
                      fixedDecimalScale={true}
                      disabled={true}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat>
                    &nbsp;({row.tax}%)
                  </TableCell>
                )}
              </>
            )}
            <TableCell className="text-right">
              <NumericFormat
                displayType={"text"}
                value={row.totalpricewtax}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            </TableCell>
            {Orders.currency === currency ? (
              ""
            ) : (
              <TableCell className="text-right">
                <NumericFormat
                  displayType={"text"}
                  value={row.totalpricewtax * Orders.curconversionrate}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={true}
                  thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
              </TableCell>
            )}
          </>
        )}
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">Brand</TableCell>
                    <TableCell align="center">Model</TableCell>
                    <TableCell align="center">Colour</TableCell>
                    <TableCell align="center">Size</TableCell>
                    <TableCell align="center">Thickness</TableCell>
                    {/* <TableCell align="center">UOM</TableCell> */}
                    <TableCell align="center">TotalMtrRoll</TableCell>
                    <TableCell align="center">Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row" align="center">
                      {row.brand === "" ? "NA" : row.brand}
                    </TableCell>
                    <TableCell align="center">
                      {row.model === "" ? "NA" : row.model}
                    </TableCell>
                    <TableCell align="center">
                      {row.colour === "" ? "NA" : row.color}
                    </TableCell>
                    <TableCell align="center">
                      {row.size === "" ? "NA" : row.size}
                    </TableCell>
                    <TableCell align="center">
                      {row.thickness === "" ? "NA" : row.thickness}
                    </TableCell>
                    <TableCell align="center">
                      {row.totalmtrroll === undefined ? "NA" : row.totalmtrroll}
                    </TableCell>
                    <TableCell align="center">
                      {row.description === "" ? "NA" : row.description}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={showquantity} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography gutterBottom component="div">
                Quantity Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell className="text-center">
                      Invoice Quantity
                    </TableCell>
                    <TableCell className="text-center">
                      Delivered Quantity
                    </TableCell>
                    <TableCell className="text-center">
                      Return Quantity
                    </TableCell>
                    <TableCell className="text-center">
                      PreClose Quantity
                    </TableCell>
                    <TableCell className="text-center">
                      Adjusted Quantity
                    </TableCell>
                    <TableCell className="text-center">
                      Cancelled Quantity
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell className="text-center">
                      {parseFloat(row.accepted)} {row.uom}
                    </TableCell>
                    <TableCell className="text-center">
                      {parseFloat(row.delivered)} {row.uom}
                    </TableCell>
                    <TableCell className="text-center">
                      {parseFloat(row.returnquantity)} {row.uom}
                    </TableCell>
                    <TableCell className="text-center">
                      {parseFloat(row.preclose)} {row.uom}
                    </TableCell>
                    <TableCell className="text-center">
                      {row.adjusted === null ? 0 : parseFloat(row.adjusted)} {row.uom}
                    </TableCell>
                    <TableCell className="text-center">
                      {row.cancelled === null ? 0 : row.cancelled} {row.uom}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default DataRows;
