import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  userid,
  currentTenant,
  financialyearstart,
  financialyearend,
  transactionFrom,
} from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import { FormikTextField } from "../../CommonComponents/FormField.js";
import PurchaseServices from "../../Purchase/PurchseServices.js";
import "date-fns";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  TextField,
} from "@mui/material";

import InventoryService from "../InventoryService.js";
import { Spinner } from "react-bootstrap";
import WarehouseServices from "../../Masters/Warehouse/WarehouseServices.js";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Swal from "sweetalert2";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";

class AddStock extends Component {
  constructor(props) {
    super(props);
    const { pagename } = this.props.location.state;
    const { id } = this.props.location.state;
    console.log(this.props.location.state);
    this.state = {
      pagename: pagename,
      id:
        this.props.location.state.data === undefined
          ? ""
          : this.props.location.state.data.items.itemid,
      sid: id,
      cid: "",
      name:
        this.props.location.state.data === undefined
          ? ""
          : this.props.location.state.data.items.itemname,
      cost: "",
      stock:
        this.props.location.state.data === undefined
          ? ""
          : this.props.location.state.data.items.pendingquantity,
      itemsList: [],
      model: "",
      brand: "",
      costList: [],
      WarehouseList: [],
      suppliers: [],
      supplier: "",
      supplierid: "",
      purchasedate: new Date(),
      descreption: "",
      wid:
        this.props.location.state.data === undefined
          ? ""
          : this.props.location.state.data.whid,
      warehouse:
        this.props.location.state.data === undefined
          ? ""
          : this.props.location.state.data.warehouse,
      selectvalue: "",
      mfdate: new Date(),
      expdate: new Date(),
      loader: false,
      minnotifier: "",
      status:
        this.props.location.state.data === undefined
          ? this.props.location.state?.stocktype === "ThirdParty"
            ? "Internal Use"
            : "Raw Material"
          : "Finished Goods",
      istatus: "Asset",
      stocktypes: [
        "Internal Use",
        "Reselling",
        "Raw Material",
        "Finished Goods",
      ],
      stockitemtypes: ["Asset", "Consumption"],
      assettypes: ["Own", "ThirdParty"],
      assettype:
        this.props.location.state.data === undefined
          ? this.props.location.state.stocktype
          : "Own",
      data: this.props.location.state.data,
      prevstock: 0,
      uomid: "",
      uom: "",
      addmfdate: false,
      addexpdate: false,
      stockfrom:
        this.props.location.state.data === undefined
          ? "Direct"
          : this.props.location.state.data.type,
      openingexists: false,
      openingstkstatus: false,
      byproduct: false,
      productionid:
        this.props.location.state.data === undefined
          ? null
          : this.props.location.state.data.data.imp === null?this.props.location.state.data.data.joid:this.props.location.state.data.data.imp,
      cdate: false,
      customdate: new Date(),
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handlecreatedAtDateChange = (date) => {
    this.setState({ customdate: date });
    this.setState({
      fed: moment(date).format("yyyy-MM-DD HH:mm:ss"),
    });
  };

  handlecustomDate = () => {
    this.setState({
      cdate: this.state.cdate === true ? false : true,
      customdate: new Date(),
    });
  };

  handleSelect = (e) => {
    this.setState({ status: e.target.value });
  };

  handleChange = (e) => {
    this.setState({ istatus: e.target.value });
  };
  handleAssetTypeChange = (e) => {
    this.setState({ assettype: e.target.value });
  };

  handleStockChange = (e) => {
    this.setState({ stock: e.target.value });
  };

  handleminnotifier = (e) => {
    this.setState({ minnotifier: parseFloat(e) });
  };
  handleaddmfdate = (e) => {
    this.setState({ addmfdate: e.target.checked });
  };

  handleaddexpdate = (e) => {
    this.setState({ addexpdate: e.target.checked });
  };

  handlewarehouse = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({ wid: values.id, warehouse: values.name });
    } else {
      this.setState({ wid: "", warehouse: "" });
    }
  };

  handlesupplier = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      let lv = "Personal";

      if (values.ledgerid === null || values.ledgerid === undefined) {
        // if (accounts === "YES") {
        //   if (
        //     window.confirm(
        //       `Ledger account is not created for supplier ${values.companyname}, so no payment or receipt can be done. Press 'ok' to create ledger or 'cancel' to continue`
        //     )
        //   ) {
        //     this.props.navigate({pathname:"/newparty"},{state: {
        //       id: -1,
        //       name: "New Party Ledger",
        //       lv,
        //     }});
        //   }
        // } else {
        alert(
          `Ledger account is not created for supplier ${values.companyname}, so no payment or receipt can be done`
        );
        // }
      }
      this.setState({
        supplierid: values.id,
        supplier: values.companyname,
      });
    } else {
      this.setState({
        supplierid: "",
        supplier: "",
      });
    }
  };

  handleitemname = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        id: values.id,
        name: values.name,
        cid: "",
        cost: "",
        brand: values.brand,
        model: values.model,
        uom: values.uomname,
        uomid: values.uomid,
      });
      const id = values.id;
      InventoryService.costDataById(id)
        .then((response) =>
          this.setState({
            costList: response.data,
          })
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
      InventoryService.ExistbyItem(currentTenant, id).then((response) =>
        this.setState({ openingexists: response.data })
      );
    } else {
      this.setState({
        id: "",
        name: "",
        cid: "",
        cost: "",
        brand: "",
        model: "",
      });
    }
  };

  handlecost = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({ cid: values.cid, cost: values.rate });
    } else {
      this.setState({ cid: "", cost: "" });
    }
  };

  handlePurchaseDateChange = (date) => {
    this.setState({ purchasedate: date });
  };

  handleMfDateChange = (date) => {
    this.setState({ mfdate: date });
  };

  handleExpDateChange = (date) => {
    this.setState({ expdate: date });
  };

  onSubmit(values) {
    const id = this.props.location.state.id;
    this.setState({ loader: true });
    let addstock = {
      id: this.state.id,
      wid: this.state.wid,
      name: this.state.name,
      supplier: this.state.supplier,
      supplierid: this.state.supplierid === "" ? null : this.state.supplierid,
      currentStock: this.state.stock,
      cid: values.cid,
      createdby: userid,
      tenantid: currentTenant,
      manufacturingdate: this.state.mfdate,
      expirydate: this.state.expdate,
      minnotifier: this.state.minnotifier,
      status:
        this.state.status === "Internal Use"
          ? 0
          : this.state.status === "Reselling"
          ? 1
          : this.state.status === "Raw Material"
          ? 2
          : 3,
      itemtype:
        this.state.status === "Internal Use"
          ? values.istatus === "Asset"
            ? 1
            : 2
          : 0,
      fsid: this.state.data === undefined ? -1 : this.state.data.items.id,
      stockfrom: this.state.stockfrom,
      purchasedate: this.state.purchasedate,
      addmfdate: this.state.addmfdate,
      addexpdate: this.state.addexpdate,
      isopening: this.state.openingstkstatus,
      productionid: this.state.productionid,
      customdate: this.state.customdate,
      cdate: this.state.cdate,
      atype: this.state.assettype === "Own" ? 0 : 1,
      byproduct: this.state.byproduct,
      rate: this.state.cost,
    };

    let updatestock = {
      id: values.id,
      sid: this.state.sid,
      wid: this.state.wid,
      cid: this.state.cid,
      name: this.state.name,
      supplier: this.state.supplier,
      supplierid: this.state.supplierid,
      prevstock: this.state.prevstock,
      currentStock: parseFloat(this.state.stock),
      updatedby: userid,
      tenantid: currentTenant,
      minnotifier: this.state.minnotifier,
      manufacturingdate: this.state.mfdate,
      expirydate: this.state.expdate,
      status:
        this.state.status === "Internal Use"
          ? 0
          : this.state.status === "Reselling"
          ? 1
          : this.state.status === "Raw Material"
          ? 2
          : 3,
      itemtype:
        this.state.status === "Internal Use"
          ? values.istatus === "Asset"
            ? 1
            : 2
          : 0,
      purchasedate: this.state.purchasedate,
      addmfdate: this.state.addmfdate,
      addexpdate: this.state.addexpdate,
      isopening: this.state.openingstkstatus,
      atype: this.state.assettype === "Own" ? 0 : 1,
      byproduct: this.state.byproduct,
    };
    if (
      this.state.status === "Internal Use" &&
      this.state.istatus === "Asset" &&
      this.state.assettype === "ThirdParty"
    ) {
      if (id < 1) {
        InventoryService.addthirdpartystock(addstock)
          .then(() =>
            this.setState({ loader: false }, () =>
              Swal.fire({
                title: `Thirdparty Stock added successfully`,
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(() => this.props.navigate(`/stocks`))
            )
          )
          .catch((e) => {
            this.setState(
              { message: e.response.data.message, loader: false },
              () => {
                if (e.response.data.error === "Unauthorized") {
                  Swal.fire({
                    title: `Your Session is Lost: Please login Back to resume`,
                    text: ".",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  }).then(() => this.props.navigate("/"));
                } else {
                  Swal.fire({
                    title: `${e.response.data.message}`,
                    text: ".",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  });
                }
              }
            );
          });
      }
    } else {
      if (id < 1) {
        InventoryService.addStock(addstock)
          .then(() =>
            this.setState({ loader: false }, () =>
              Swal.fire({
                title: `Stock added successfully`,
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(() => this.props.navigate(`/stocks`))
            )
          )
          .catch((e) => {
            this.setState(
              { message: e.response.data.message, loader: false },
              () => {
                if (e.response.data.error === "Unauthorized") {
                  Swal.fire({
                    title: `Your Session is Lost: Please login Back to resume`,
                    text: ".",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  }).then(() => this.props.navigate("/"));
                } else {
                  Swal.fire({
                    title: `${e.response.data.message}`,
                    text: ".",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  });
                }
              }
            );
          });
      } else {
        InventoryService.updateStock(updatestock)
          .then(() =>
            this.setState({ loader: false }, () =>
              Swal.fire({
                title: `Stock updated successfully`,
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(() => this.props.navigate(`/stocks`))
            )
          )
          .catch((e) => {
            this.setState(
              { message: e.response.data.message, loader: false },
              () => {
                if (e.response.data.error === "Unauthorized") {
                  Swal.fire({
                    title: `Your Session is Lost: Please login Back to resume`,
                    text: ".",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  }).then(() => this.props.navigate("/"));
                } else {
                  Swal.fire({
                    title: `${e.response.data.message}`,
                    text: ".",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  });
                }
              }
            );
          });
      }
    }
  }

  onstockfocus() {
    document
      .getElementById("stock")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document.getElementById("stock").addEventListener("keydown", function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }
  onfocus() {
    document
      .getElementById("minnotifier")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document
      .getElementById("minnotifier")
      .addEventListener("keydown", function (e) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      });
  }

  componentDidMount() {
    console.log(this.state.productionid)
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    const sid = this.props.location.state.id;
    WarehouseServices.getWarehouses(currentTenant)
      .then((response) =>
        this.setState({
          WarehouseList: response.data,
        })
      )
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    PurchaseServices.GetAllSellers(currentTenant).then((res) =>
      this.setState({
        suppliers: res.data,
      })
    );

    // InventoryService.GetDataToAddStock(currentTenant).then((res) =>
    InventoryService.retriveAllItems(currentTenant).then((res) =>
      this.setState({ itemsList: res.data.filter((e) => e.cost !== null) })
    );

    if (this.props.location.state.data !== undefined) {
      InventoryService.costDataById(this.props.location.state.data.items.itemid)
        .then((response) =>
          this.setState({
            costList: response.data,
          })
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    }

    if (sid < 1) {
      return;
    }
    InventoryService.stockDataToUpdate(sid).then((response) =>
      this.setState({
        sid: sid,
        id: response.data.id,
        name: response.data.name,
        warehouse: response.data.waddress,
        supplier: response.data.supplier,
        supplierid: response.data.supplierid,
        stock: response.data.currentStock,
        cost: response.data.rate,
        mfdate: response.data.manufacturingdate,
        expdate: response.data.expirydate,
        cid: response.data.cid,
        wid: response.data.wid,
        minnotifier: response.data.minnotifier,
        prevstock: response.data.currentStock,
        status:
          response.data.stocktype === "INTERNALUSE"
            ? "Internal Use"
            : response.data.stocktype === "RESALE"
            ? "Reselling"
            : response.data.stocktype === "RAWMATERIAL"
            ? "Raw Material"
            : "Finished Goods",
        istatus:
          response.data.stockitemtype === "ASSET"
            ? "Asset"
            : response.data.stockitemtype === "CONSUMPTION"
            ? "Consumption"
            : "Asset",
        addmfdate: response.data.addmfdate,
        addexpdate: response.data.addmfdate,
        purchasedate: response.data.purchasedate,
      })
    );
  }

  validate(values) {
    let errors = {};
    if (
      this.state.wid === "" ||
      this.state.wid === undefined ||
      this.state.warehouse === ""
    ) {
      errors.warehouse = "Please Select Org Unit";
    }

    if (this.state.stock === "") {
      errors.stock = "Stock value is required";
    }
    if (this.state.stock <= 0) {
      errors.stock = "Stock value should be greater than 0";
    }

    if (!values.name || values.name === "") {
      errors.name = "Please Select An Item";
    }
    if (!values.cost || values.cost === "") {
      errors.cost = "Please Select an Price";
    }

    if (
      this.state.openingstkstatus === false &&
      this.state.byproduct === false &&
      this.state.supplierid === "" &&
      this.state.data === undefined
    ) {
      errors.supplier = "Please Select Supplier";
    }
    return errors;
  }

  render() {
    let WarehouseList = this.state.WarehouseList;
    let itemsList = this.state.itemsList;
    let {
      id,
      name,
      stock,
      cost,
      cid,
      warehouse,
      wid,
      minnotifier,
      status,
      istatus,
      brand,
      model,
      uom,
      suppliers,
      supplier,
      supplierid,
    } = this.state;

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <span
                  as="button"
                  className=" text-secondary cursorPointer"
                  onClick={() => this.props.navigate(-1)}
                >
                  ❮ Back &nbsp;&nbsp;
                </span>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/stocks">Stock</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">{this.state.pagename}</h3>
            </div>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.loader}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            <div className="card-body">
              <Formik
                initialValues={{
                  id,
                  name,
                  stock,
                  cost,
                  cid,
                  warehouse,
                  wid,
                  minnotifier,
                  status,
                  istatus,
                  brand,
                  model,
                  uom,
                  suppliers,
                  supplier,
                  supplierid,
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="id"
                          placeholder="Id"
                        />
                      </fieldset>

                      <fieldset hidden className="form-group col-md-6">
                        <label>Cid</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="cid"
                          placeholder="Id"
                        />
                      </fieldset>
                      {this.state.sid === -1 &&
                      (this.state.itemsList === undefined ||
                        this.state.itemsList.length === 0) ? (
                        <fieldset className="form-group col-md-12 deletebg">
                          <ul className="py-3">
                            <li style={{ "list-style": "none" }}>Note:</li>
                            {this.state.itemsList === undefined ||
                            this.state.itemsList.length === 0 ? (
                              <li>No Items are avilable</li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </fieldset>
                      ) : (
                        ""
                      )}
                      {this.state.assettype === "ThirdParty"?"":<>
                      <fieldset className="col-md-12">
                        <div className="row">
                          <FormLabel
                            id="demo-row-radio-buttons-group-label"
                            className="mt-2 mr-3"
                          >
                            Stock Type :
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={this.state.status}
                            onChange={this.handleSelect}
                          >
                            {this.state.stocktypes.map((e) => {
                              return (
                                <FormControlLabel
                                  key={e}
                                  value={e}
                                  control={
                                    <Radio
                                      style={{ color: "#152f5f" }}
                                      disabled={this.state.data !== undefined || this.props.location.state?.stocktype==="ThirdParty"}
                                    />
                                  }
                                  label={e}
                                />
                              );
                            })}
                          </RadioGroup>
                        </div>
                      </fieldset>
                      {this.state.status === "Internal Use" ? (
                        <fieldset className="col-md-12">
                          <div className="row">
                            <FormLabel
                              id="demo-row-radio-buttons-group-label"
                              className="mt-2 mr-3"
                            >
                              Internal Consumption Type :
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={this.state.istatus}
                              onChange={this.handleChange}
                            >
                              {this.state.stockitemtypes.map((e) => {
                                return (
                                  <FormControlLabel
                                    key={e}
                                    value={e}
                                    control={
                                      <Radio style={{ color: "#152f5f" }}  disabled={this.props.location.state?.stocktype==="ThirdParty"}/>
                                    }
                                    label={e}
                                  />
                                );
                              })}
                            </RadioGroup>
                          </div>
                        </fieldset>
                      ) : (
                        ""
                      )}
                      </>}

                      {/* {this.state.status === "Internal Use" &&
                      this.state.istatus === "Asset" ? (
                        <fieldset className="col-md-12">
                          <div className="row">
                            <FormLabel
                              id="demo-row-radio-buttons-group-label"
                              className="mt-2 mr-3"
                            >
                              Asset Type :
                            </FormLabel>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name="row-radio-buttons-group"
                              value={this.state.assettype}
                              onChange={this.handleAssetTypeChange}
                            >
                              {this.state.assettypes.map((e) => {
                                return (
                                  <FormControlLabel
                                    key={e}
                                    value={e}
                                    control={
                                      <Radio style={{ color: "#152f5f" }} disabled/>
                                    }
                                    label={e}
                                  />
                                );
                              })}
                            </RadioGroup>
                          </div>
                        </fieldset>
                      ) : (
                        ""
                      )} */}

                      {this.state.pagename === "Update Stock" ||
                      this.state.data !== undefined ? (
                        <>
                          {this.state.data !== undefined ? (
                            ""
                          ) : (
                            <fieldset className="form-group col-md-3">
                              <FormikTextField
                                className="form-control"
                                type="text"
                                name="supplier"
                                value={this.state.supplier}
                                label="Supplier"
                                disabled
                              />
                            </fieldset>
                          )}

                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="warehouse"
                              value={this.state.warehouse}
                              label="Org Unit"
                              disabled
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="name"
                              value={this.state.name}
                              label="Item"
                              disabled
                            />
                          </fieldset>
                        </>
                      ) : (
                        <>
                          <fieldset className="form-group col-md-3 pb-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="warehouse"
                              options={WarehouseList}
                              getOptionLabel={(option) => option.name}
                              value={values.warehouse ? undefined : undefined}
                              onChange={this.handlewarehouse}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="warehouse"
                                  value={this.state.warehouse}
                                  label={`Select Org Unit (${this.state.warehouse})`}
                                  variant="standard"
                                  fullWidth
                                />
                              )}
                            />
                          </fieldset>
                          {/* <div className="form-group col-md-3" /> */}

                          <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="name"
                              options={itemsList}
                              getOptionLabel={(option) => option.name}
                              value={values.name ? undefined : undefined}
                              onChange={this.handleitemname}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="name"
                                  value={this.state.name}
                                  label={`Select Item (${this.state.name})`}
                                  variant="standard"
                                  fullWidth
                                />
                              )}
                            />
                          </fieldset>
                          {/* <div className="form-group col-md-3" /> */}

                          {/* <div className="form-group col-md-3" /> */}
                        </>
                      )}
                      {this.state.pagename === "Update Stock" ||
                      this.state.data !== undefined ? (
                        ""
                      ) : (
                        <>
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="brand"
                              value={this.state.brand}
                              label="Brand"
                              disabled
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="model"
                              value={this.state.model}
                              label="Model"
                              disabled
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="uom"
                              value={this.state.uom}
                              label="UOM"
                              disabled
                            />
                          </fieldset>
                        </>
                      )}
                      {this.state.status === "Internal Use" &&
                      this.state.istatus === "Asset" &&
                      this.state.assettype === "ThirdParty" ? (
                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="cost"
                            value={this.state.cost}
                            onChange={(e) =>
                              this.setState({ cost: e.target.value })
                            }
                            label="Cost"
                          />
                        </fieldset>
                      ) : this.state.pagename === "Update Stock" ? (
                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="cost"
                            value={this.state.cost}
                            label="Cost"
                            disabled
                          />
                        </fieldset>
                      ) : (
                        <fieldset className="form-group col-md-3">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="cost"
                            options={this.state.costList}
                            getOptionLabel={(option) => "" + option.rate + ""}
                            value={values.cost ? undefined : undefined}
                            onChange={this.handlecost}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control"
                                type="text"
                                name="cost"
                                value={this.state.cost}
                                label={`Select Price (${this.state.cost})`}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>
                      )}
                      {this.state.data !== undefined ? (
                        ""
                      ) : (
                        <fieldset className="form-group col-md-3">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container>
                              <DatePicker
                                id="date-picker-dialog"
                                label="Purchase Date"
                                format="dd/MM/yyyy"
                                maxDate={new Date()}
                                value={this.state.purchasedate}
                                onChange={this.handlePurchaseDateChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                                autoOk={true}
                                fullWidth
                              />
                            </Grid>
                          </LocalizationProvider>
                        </fieldset>
                      )}
                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                          className="form-control"
                          id="stock"
                          type="number"
                          name="stock"
                          value={this.state.stock}
                          label={`Enter Stock`}
                          onChange={this.handleStockChange}
                          onFocus={this.onstockfocus}
                          disabled={this.state.data !== undefined}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-12 m-0" />
                      {this.state.openingexists ? (
                        <fieldset className="form-group col-md-3">
                          <FormGroup row>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.openingstkstatus}
                                  onChange={() =>
                                    this.setState({
                                      openingstkstatus:
                                        !this.state.openingstkstatus,
                                      supplier: "",
                                      supplierid: "",
                                    })
                                  }
                                  name="openingstkstatus"
                                  color="primary"
                                />
                              }
                              label="Opening Stock"
                              disabled={this.state.pagename === "Update Stock"}
                            />
                          </FormGroup>
                        </fieldset>
                      ) : (
                        ""
                      )}
                      {this.state.openingstkstatus ||
                      this.state.data !== undefined ? (
                        ""
                      ) : (
                        <>
                          <fieldset className="form-group col-md-3">
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.byproduct}
                                    onChange={() =>
                                      this.setState({
                                        byproduct: !this.state.byproduct,
                                        supplier: "",
                                        supplierid: "",
                                      })
                                    }
                                    name="byproduct"
                                    color="primary"
                                  />
                                }
                                label="Production Byproduct"
                                disabled={
                                  this.state.pagename === "Update Stock"
                                }
                              />
                            </FormGroup>
                          </fieldset>
                          {this.state.byproduct ? (
                            ""
                          ) : (
                            <fieldset className="form-group col-md-3">
                              <Autocomplete
                                id="subaccount"
                                className="subacc-select"
                                name="supplier"
                                options={suppliers}
                                getOptionLabel={(option) => option.companyname}
                                value={values.supplier ? undefined : undefined}
                                onChange={this.handlesupplier}
                                renderInput={(params) => (
                                  <FormikTextField
                                    {...params}
                                    className="form-control"
                                    type="text"
                                    name="supplier"
                                    value={this.state.supplier}
                                    label={`Select  Supplier(${this.state.supplier})`}
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>
                          )}
                        </>
                      )}

                      <fieldset className="form-group col-md-12 m-0"></fieldset>

                      {/* <div className="form-row"> */}
                      <fieldset className="form-group col-md-3">
                        <FormGroup row className="">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.addmfdate}
                                onChange={this.handleaddmfdate}
                                name="parameters"
                                color="primary"
                              />
                            }
                            label="Add Manufacture date"
                            disabled={this.state.pagename === "Update Stock"}
                          />
                        </FormGroup>
                      </fieldset>
                      {this.state.addmfdate === true ? (
                        <fieldset className="form-group col-md-3">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container justify="space-around">
                              <DatePicker
                                variant="inline"
                                id="date-picker-dialog"
                                label="Manufacture Date "
                                format="dd/MM/yyyy"
                                maxDate={new Date()}
                                value={this.state.mfdate}
                                onChange={this.handleMfDateChange}
                                renderInput={(params) => (
                                  <TextField {...params} variant="standard" />
                                )}
                                autoOk={true}
                              />
                            </Grid>
                          </LocalizationProvider>
                        </fieldset>
                      ) : (
                        ""
                      )}
                      {/* <fieldset className="form-group col-md-12 m-0"></fieldset> */}
                      <fieldset className="form-group col-md-3">
                        <FormGroup row className="">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.addexpdate}
                                onChange={this.handleaddexpdate}
                                name="addecpdate"
                                color="primary"
                              />
                            }
                            label="Add Expiry date"
                            disabled={this.state.pagename === "Update Stock"}
                          />
                        </FormGroup>
                      </fieldset>
                      {this.state.addexpdate === true ? (
                        <>
                          <fieldset className="form-group col-md-3">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justify="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="Expiry Date"
                                  format="dd/MM/yyyy"
                                  minDate={new Date()}
                                  value={this.state.expdate}
                                  onChange={this.handleExpDateChange}
                                  renderInput={(params) => (
                                    <TextField {...params} variant="standard" />
                                  )}
                                  autoOk={true}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>
                          {/* </div> */}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-group col-md-12 m-0" />

                    <div className="row">
                      <fieldset className="form-group col-md-3">
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.cdate}
                                onChange={this.handlecustomDate}
                                name="ordertype"
                                color="primary"
                              />
                            }
                            label="Custom date?"
                          />
                        </FormGroup>
                      </fieldset>
                      {this.state.cdate ? (
                        <fieldset className="form-group col-md-3">
                          {/* <div className="form-row mr-4 mb-4"> */}
                          {/* <fieldset style={{ marginLeft: "30px" }}> */}
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid justifyContent="space-around">
                              <DateTimePicker
                                disableFuture
                                showtodaybutton
                                showcancelbutton
                                //  views={["year", "month","date"]}
                                variant="inline"
                                id="date-picker-dialog"
                                // label="Financial Year Start Date"
                                // inputVariant="outlined"
                                minDate={
                                  new Date(
                                    moment(transactionFrom.$d).format(
                                      "yyyy-MM-DD"
                                    )
                                  )
                                }
                                maxDate={
                                  new Date(
                                    // moment(financialyearend).format(
                                    //   "YYYY-MM-DD"
                                    // )
                                  )
                                }
                                label={`Custom Date : ${
                                  this.state.customdate ? "Custom" : "Default"
                                }`}
                                format="dd/MM/yyyy HH:mm"
                                value={this.state.customdate}
                                InputAdornmentProps={{ position: "start" }}
                                onChange={this.handlecreatedAtDateChange}
                                renderInput={(params) => (
                                  <TextField {...params} variant="standard" />
                                )}
                                autoOk={true}
                                fullWidth
                                disabled={this.state.cdate === false}
                              />
                            </Grid>
                          </LocalizationProvider>
                        </fieldset>
                      ) : (
                        ""
                      )}
                    </div>

                    {this.state.loader === true ? (
                      <button className="btn btn-sm hovbuttonColour" disabled>
                        <Spinner animation="border" size="sm" />
                        &nbsp;{"Saving"}
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm hovbuttonColour"
                        type="submit"
                      >
                        <span className="fa fa-save" /> Save
                      </button>
                    )}
                    <span
                      as="button"
                      className="btn btn-sm deletebutton ml-1"
                      onClick={() => this.props.navigate(-1)}
                    >
                      <b style={{ color: "#FA5733" }}> X</b>Cancel
                    </span>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(AddStock);
