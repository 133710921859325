import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import moment from "moment";
import {
  ccountry,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import {NumericFormat} from "react-number-format";



class PosDetails extends Component {
  constructor(props) {
    super(props);
    const data = this.props.location.state;
    this.state = {
      Orders: data,
    };
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
  }

  render() {
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <span
                  as="button"
                  className=" text-secondary cursorPointer"
                  onClick={() => this.props.navigate(-1)}
                >
                  ❮ Back &nbsp;&nbsp;
                </span>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/pointofsae">Point of Sale</Link>
              </li>
              <li className="breadcrumb-item active">POS Details</li>
            </ol>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div>
                        <div className="card-header">
                          <h3 className="card-title">POS Details</h3>
                          {this.state.sostatus === "Cancelled" ||
                          this.state.sostatus === "Completed" ? (
                            ""
                          ) : (
                            <>
                              <button
                                className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-envelope text-md ml-1"
                                data-tip
                                data-for="mail"
                                onClick={() => this.handleShow()}
                              ></button>
                              <button
                                className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md"
                                data-tip
                                data-for="pdf"
                                onClick={() =>
                                  this.handlepdfDownload(this.state.Orders)
                                }
                              ></button>
                              {/* //<Tooltip
                                id="mail"
                                place="top"
                                effect="solid"
                              >
                                Send Mail
                              </Tooltip>
                              //<Tooltip id="pdf" place="top" effect="solid">
                                Download pdf
                              </Tooltip> */}
                            </>
                          )}
                        </div>

                        <div className="card-body">
                          <div className="form-row">
                            <div className="form-column col-md-4">
                              <table className="float-left m-0">
                                <tbody>
                                  <tr>
                                    <td>Order ID</td>
                                    <td>:&nbsp; {this.state.Orders.id}</td>
                                  </tr>
                                  <tr>
                                    <td>User Id</td>
                                    <td>
                                      :&nbsp; {this.state.Orders.createdby}
                                    </td>
                                  </tr>
                                  {/* <tr>
                                    <td>User</td>
                                    <td>
                                      :&nbsp; {this.state.Orders.createdbyname}
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td>Customer</td>
                                    <td>
                                      :&nbsp; {this.state.Orders.customer}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Contact No</td>
                                    <td>
                                      :&nbsp; {this.state.Orders.contactno}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Paymnet Mode</td>
                                    <td>
                                      :&nbsp; {this.state.Orders.paymentmode}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Date</td>
                                    <td>
                                      :&nbsp;{" "}
                                      {moment(
                                        this.state.Orders.createdAt
                                      ).format("DD-MM-YYYY HH:mm")}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>

                          {this.state.Orders.positems === undefined ? (
                            ""
                          ) : (
                            <TableContainer>
                              <Table className="table-sm">
                                <TableHead>
                                  <TableRow className="text-center">
                                    <TableCell>Itemname</TableCell>
                                    {/* <TableCell>{ccountry === "India" ? "HSN" : "Item Code"}</TableCell> */}
                                    <TableCell>Unit Price</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    {/* <TableCell>Total</TableCell> */}
                                    {ccountry !== "India" ? (
                                      <TableCell>VAT</TableCell>
                                    ) : (
                                      <TableCell>CGST(%)</TableCell>
                                    )}
                                    {ccountry === "India" ? (
                                      <TableCell>SGST(%)</TableCell>
                                    ) : (
                                      ""
                                    )}
                                    <TableCell>Tax Amount</TableCell>
                                    <TableCell>Total Price</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.Orders.positems.map(
                                    (data, index) => (
                                      <TableRow
                                        key={index}
                                        style={{ textAlign: "center" }}
                                      >
                                        <TableCell>{data.itemname}</TableCell>
                                        {/* <TableCell>{data.hsn}</TableCell> */}
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            className="pr-3"
                                            displayType={"text"}
                                            value={data.price}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                        {/* <TableCell>{data.salesenum}</TableCell> */}
                                        <TableCell>{data.quantity}</TableCell>
                                        {/* <TableCell><NumericFormat className="pr-3" displayType={'text'} value={data.totalprice} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell> */}
                                        {ccountry !== "India" ? (
                                          <TableCell>{data.tax}</TableCell>
                                        ) : (
                                          <TableCell>{data.tax / 2}</TableCell>
                                        )}
                                        {ccountry === "India" ? (
                                          <TableCell>{data.tax / 2}</TableCell>
                                        ) : (
                                          ""
                                        )}
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            className="pr-3"
                                            displayType={"text"}
                                            value={data.taxamount}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            className="pr-3"
                                            displayType={"text"}
                                            value={data.totalinctax}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  )}
                                  <TableRow>
                                    <TableCell>Total</TableCell>
                                    <TableCell></TableCell>
                                    {/* <TableCell></TableCell> */}
                                    {ccountry === "India" ? (
                                      <TableCell></TableCell>
                                    ) : (
                                      ""
                                    )}
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell className="text-right">
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={this.state.Orders.totalinctax-this.state.Orders.total}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell className="text-right">
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={this.state.Orders.totalinctax}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(PosDetails);
