import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import {FormControlLabel,Checkbox,FormGroup,Grid,Button,Typography, TextField} from "@mui/material";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import { Autocomplete } from "@mui/material";
import { currentTenant, userid } from "../../Common/Credentials.js";
import { Spinner } from "react-bootstrap";
import { DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import moment from "moment";
import UploadFilesService from "../../Common/upload/UploadFilesService.js";
import FileServices from "../../Common/upload/FileServices.js";
import TransportationServices from "./TransportationServices.js";
import Employeeservices from "../../Masters/Employees/Employeeservices.js";

let curmonth, year, day;
let doctype = "Trips"


class NewTrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
        id:this.props?this.props.location.state.id:"",
        pagename: this.props?this.props.location.state.name:"",
        vehicle:'',
        asset:'',
        vehicles:[],
        outdate:new Date(),
        indate:new Date(),
        places:[],
        origin:'',
        originid:'',
        destination:'',
        destinationid:'',
        loading:0,
        unloading:0,
        differance:0,
        outreading:0,
        inreading:0,
        totalkms:0,
        millage:0,
        dieselinltr:0,
        dieselperltr:0,
        dieselinamt:0, 
        tollamt:0,
        asocamt:0,
        otherexpenses:0,
        comment:'',
        totalexp:0,
        driver1:'',
        driver1id:'',
        wages:0,
        driver2:'',
        driver2id:'',
        wages2:0,
        employees:[],
        type:false,
        loadingstatus:true,
        prefix:'',
        selectedFiles: undefined,
        currentFile: undefined,
        progress: 0,
        message: "",
        isError: false,
        fileInfos: [],
        fileInfo: "",
        partyid: "",
        error: false,
        errorMessage: {},
        file: "",
        loader:false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
  }
 

  Outreading = (e)=>{
    this.setState({outreading: e.target.value},
      ()=>{
        this.setState({totalkms:this.state.inreading - this.state.outreading, millage:(this.state.inreading - this.state.outreading)/this.state.dieselinltr})
      }
    )
  }

  Inreading = (e)=>{
    this.setState({ inreading: e.target.value,errormsg:'' },
      ()=>{
        this.setState({totalkms:this.state.inreading - this.state.outreading,millage:(this.state.inreading - this.state.outreading)/this.state.dieselinltr})
      }
    )
  }

  DieselInLtr = (e) => {
    this.setState({ dieselinltr: e.target.value,errormsg:'' },()=>this.setState({millage:(this.state.inreading - this.state.outreading)/this.state.dieselinltr}))
  }

  ChangeDriver1 = (value,values)=>{
    if(values!=='' && typeof values === 'object' && values!==null){
        this.setState({driver1:values.first +" "+ values.last,driver1id:values.id})
    }else{
        this.setState({driver1:'',driver1id:''})
    }
  }

  ChangeDriver2 = (value,values)=>{
    if(values!=='' && typeof values === 'object' && values!==null){
        this.setState({driver2:values.first +" "+ values.last,driver2id:values.id})
    }else{
        this.setState({driver2:'',driver2id:''})
    }
  }
  
  SlelectVehicle=(value,values)=>{
    if(values!=='' && typeof values === 'object' && values !== null){
    this.setState({vehicle: values.vehicleno,asset: values.id,outreading:values.reading,prefix:values.id})
  }else{
    this.setState({vehicle:'',asset:''})
  }
  }

  SlelectOrigin=(value,values)=>{
    if(values!=='' && typeof values === 'object' && values !== null){
    this.setState({origin: values.place,originid:values.id})
  }else{
    this.setState({origin:''})
  }
  }
  
  SlelectDestination=(value,values)=>{
    if(values!=='' && typeof values === 'object' && values !== null){
    this.setState({destination: values.place,destinationid:values.id})
  }else{
    this.setState({destination:''})
  }
  }
  

  
  selectFile = (event) => {
    this.setState({
      selectedFiles: event.target.files,
      partyid:this.state.vehicle
    });
  };

  upload() {
    let currentFile = this.state.selectedFiles[0];

    this.setState({ progress: 100, currentFile: currentFile });

    UploadFilesService.upload(
      currentFile,
      year,
      curmonth,
      doctype,
      this.state.partyid,
      day,
      // (event) => {
      //   this.setState({
      //     progress: Math.round((100 * event.loaded) / event.total),
      //   });
      //   progress = Math.round((100 * event.loaded) / event.total);
      // }
    )
      .then((response) => {
        this.setState({
          message: response.data.message,
          isError: false,
        });
        return FileServices.getFiles1(currentFile.name);
      })
      .then((files) => {
        this.setState({ fileInfos: files.data });
      })

      .catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
          isError: true,
        });
      });

    this.setState({ selectedFiles: undefined });
  }

  onSubmit(values) {
    let filedata = this.state.fileInfos.map((e) => e.name);
    let path = this.state.prefix + "/" + doctype + "/" +this.state.prefix +"/" +year +"/" +curmonth +"/" +day;
    this.setState({ loader: true });
    let newtrip = {
      vehicle:this.state.vehicle,
      outdate:this.state.outdate,
      indate:this.state.indate,
      origin:this.state.originid,
      destination:this.state.destinationid,
      loading:parseFloat(this.state.loading),
      unloading:parseFloat(this.state.unloading),
      differance:parseFloat(this.state.differance),
      outreading:parseFloat(this.state.outreading),
      inreading:parseFloat(this.state.inreading),
      totalkms:parseFloat(this.state.totalkms),
      millage:parseFloat(this.state.millage.toFixed(2)),
      dieselinltr:parseFloat(this.state.dieselinltr),
      dieselperltr:parseFloat(this.state.dieselperltr),
      dieselinamt:parseFloat(this.state.dieselinamt),
      tollamt:parseFloat(this.state.tollamt),
      asocamt:parseFloat(this.state.asocamt),
      otherexpenses:parseFloat(this.state.otherexpenses),
      comment:this.state.comment,
      totalexp:parseFloat(this.state.totalexp),
      driver1:this.state.driver1id,
      wages1:parseFloat(this.state.wages),
      driver2:this.state.driver2id,
      wages2:parseFloat(this.state.wages2),
      type:this.state.type,
      loadingstatus:this.state.loadingstatus,
      createdby:userid,
      updatedby:userid,
      tenantid:currentTenant,
      
      path:path,
      file:filedata.toLocaleString(),
    
      asset:this.state.asset,
    };
    let updatetrip = {
      id:this.state.id,
      vehicle:this.state.vehicle,
      outdate:this.state.outdate,
      indate:this.state.indate,
      origin:this.state.originid,
      destination:this.state.destinationid,
      loading:parseFloat(this.state.loading),
      unloading:parseFloat(this.state.unloading),
      differance:parseFloat(this.state.differance),
      outreading:parseFloat(this.state.outreading),
      inreading:parseFloat(this.state.inreading),
      totalkms:parseFloat(this.state.totalkms),
      millage:parseFloat(this.state.millage.toFixed(2)),
      dieselinltr:parseFloat(this.state.dieselinltr),
      dieselperltr:parseFloat(this.state.dieselperltr),
      dieselinamt:parseFloat(this.state.dieselinamt),
      tollamt:parseFloat(this.state.tollamt),
      asocamt:parseFloat(this.state.asocamt),
      otherexpenses:parseFloat(this.state.otherexpenses),
      comment:this.state.comment,
      totalexp:parseFloat(this.state.totalexp),
      driver1:this.state.driver1id,
      wages1:parseFloat(this.state.wages),
      driver2:this.state.driver2id,
      wages2:parseFloat(this.state.wages2),
      type:this.state.type,
      loadingstatus:this.state.loadingstatus,
      createdby:userid,
      updatedby:userid,
      tenantid:currentTenant,
      path:this.state.path,
      file:this.state.file,
    
      asset:this.state.asset,
    };
    if (this.state.id < 1) {
      TransportationServices.addNewTrip(newtrip).then(() =>
      this.props.navigate({pathname: `/transportation`}, {state:{message: "",type:0}})
      )
      .catch((e)=>{
        this.setState({loader:false,errormsg:e.response.data.message})
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    } else {
      TransportationServices.updatetrip(updatetrip).then(() =>
        this.props.navigate({pathname: "/transportation"}, {state:{message: "",type:0}})
      )
      .catch((e)=>{
        this.setState({loader:false,errormsg:e.response.data.message})
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    }
  }

  handleIndate = (date) => {
    this.setState({indate:date});
  };

  handleOutdate = (date) => {
    this.setState({outdate:date});
  };

  componentDidMount() {
    const id  = this.props.location.state.id;
    const data = this.props.location.state.data;
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    year = new Date().getFullYear();
    day = new Date().getDate();
    let mn = new Date().getMonth() + 1;
    curmonth = moment(mn, "M").format("MMMM");

    TransportationServices.getTransportAssets(currentTenant).then(res=>{
      this.setState({vehicles:res.data})
    })

    TransportationServices.getAllPlaces(currentTenant).then(res=>{
      this.setState({places:res.data})
    })

    Employeeservices.getAllEmployees(currentTenant).then(res=>{
      this.setState({employees:res.data.filter(e=>e.employeetype===1)})
    })

    if(id!==-1){
      // TransportationServices.getTripById(id).then(res=>{
        this.setState({
          id:data.id,
          vehicle:data.vehicle,
          outdate:data.outdate,
          indate:data.indate,
          originid:data.origin,
          origin:data.originname,
          destinationid:data.destination,
          destination:data.destinationname,
          loading:data.loading,
          unloading:data.unloading,
          differance:data.differance,
          outreading:data.outreading,
          inreading:data.inreading,
          totalkms:data.totalkms,
          millage:data.millage,
          dieselinltr:data.dieselinltr,
          dieselperltr:data.dieselperltr,
          dieselinamt:data.dieselinamt,
          tollamt:data.tollamt,
          asocamt:data.asocamt,
          otherexpenses:data.otherexpenses,
          comment:data.comment,
          totalexp:data.totalexp,
          driver1id:data.driver1,
          driver1:data.driver1name,
          wages:data.wages1,
          driver2id:data.driver2,
          driver2:data.driver2name,
          wages2:data.wages2,
          type:data.type,
          loadingstatus:data.loadingstatus,
          path:data.path,
          file:data.file,
        })
      // })
    }
 
  }

  validate(values) {
    let errors = {};

    if (this.state.vehicle === '' || this.state.vehicle === undefined) {
      errors.vehicle = "Vehicle field is required";
    }
    if (this.state.origin===''||this.state.origin===undefined) {
      errors.origin = "Origin is required";
    }
    if (this.state.destination==='' || this.state.destination === undefined) {
      errors.destination = "Destination is required";
    }

    if (this.state.inreading==='' || this.state.inreading === undefined) {
      errors.inreading = "Inreading is required";
    }

    if (this.state.outreading==='' || this.state.outreading === undefined) {
      errors.outreading = "Outreading is required";
    }

    if (this.state.driver1id==='' || this.state.driver1id === undefined) {
      errors.driver1 = "Driver is required";
    }


    return errors;
  }

  render() {
    // let optionState = this.props.optionState;
    let {
      vehicle='',
      asset='',
      vehicles='',
      outdate='',
      indate='',
      places='',
      origin='',
      destination='',
      loading,
      unloading='',
      differance='',
      outreading='',
      inreading='',
      totalkms='',
      millage='',
      dieselinltr='',
      dieselperltr='',
      dieselinamt='', 
      tollamt='',
      asocamt='',
      otherexpenses='',
      comment='',
      totalexp='',
      driver1='',
      wages='',
      driver2='',
      wages2='',
      employees='',
      type='',
      loadingstatus='',
      selectedFiles='',
      currentFile='',
      progress='',
      message='',
      fileInfos,
      isError,
    } = this.state;

    return (
      <div>
      <Header/>
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            
            <ol className="breadcrumb float-sm-right">
            <li className="text-secondary">
            <Link as="button" className=" text-secondary" to={{ pathname: "/transportation"}} state= {{ message: "",type:0 }}>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/assetmanagement">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to={{pathname: "/transportation"}} state = {{ message: "",type:0 }}>Transportation</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>
            <div className="text-center text-danger">{this.state.errormsg}</div>

            <div className="card-body">
              <Formik
                initialValues={{
                  vehicle,
                  asset,
                  vehicles,
                  outdate,
                  indate,
                  places,
                  origin,
                  destination,
                  loading,
                  unloading,
                  differance,
                  outreading,
                  inreading,
                  totalkms,
                  millage,
                  dieselinltr,
                  dieselperltr,
                  dieselinamt, 
                  tollamt,
                  asocamt,
                  otherexpenses,
                  comment,
                  totalexp,
                  driver1,
                  wages,
                  driver2,
                  wages2,
                  employees,
                  type,
                  loadingstatus,
                  selectedFiles,
                  currentFile,
                  progress,
                  message,
                  fileInfos,
                  isError,
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ props, setFieldValue, values }) => (
                  <Form >
                    <div className={`form-row ${this.state.loader?'cursorProgress':''}`}>
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="id"
                          placeholder="Id"
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                      <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="vehicle"
                          options={vehicles}
                          getOptionLabel={(option) => `${option.vehicleno}-${option.name}`}
                          value={values.vehicle ? undefined : undefined}
                          onChange={this.SlelectVehicle}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="vehicle"
                              value={this.state.vehicle}
                              label={`Select Vehicle (${this.state.vehicle})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="origin"
                          options={places}
                          getOptionLabel={(option) => option.place}
                          value={values.destination ? undefined : undefined}
                          onChange={this.SlelectOrigin}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="origin"
                              value={this.state.origin}
                              label={`Select Origin (${this.state.origin})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="destination"
                          options={places}
                          getOptionLabel={(option) => option.place}
                          value={values.destination ? undefined : undefined}
                          onChange={this.SlelectDestination}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="destination"
                              value={this.state.destination}
                              label={`Select Destination (${this.state.destination})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}
                        >
                          <Grid
                            container
                            justifyContent="space-around"
                          >
                               <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="Out Date"
                                  name="outdate"
                                  format="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  value={this.state.outdate}
                                  onChange={this.handleOutdate}
                                  renderInput={(params) => <TextField {...params} fullWidth variant="standard"/>}
                                  autoOk={true}
                                />
                          </Grid>
                        </LocalizationProvider>
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                        >
                          <Grid
                            container
                            justifyContent="space-around"
                          >
                          <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="In Date"
                                  name="indate"
                                  format="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  value={this.state.indate}
                                  onChange={this.handleIndate}
                                  renderInput={(params) => <TextField {...params} fullWidth variant="standard"/>}
                                  autoOk={true}
                                />
                          </Grid>
                        </LocalizationProvider>
                      </fieldset>
                          <fieldset className="form-group col-md-3">
                        <FormikTextField
                          className="form-control"
                          type="number"
                          name="outreading"
                          label="Out Reading (in Kms)"
                          value={this.state.outreading}
                          onChange={this.Outreading}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="number"
                            name="inreading"
                            label="In Reading (in Kms)"
                            value={this.state.inreading}
                            onChange={this.Inreading}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="number"
                            name="totalkms"
                            label="Total Kms"
                            value={this.state.totalkms}
                            disabled
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="number"
                            name="dieselinltr"
                            label="Diesel (in Ltrs)"
                            value={this.state.dieselinltr}
                            onChange={this.DieselInLtr}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="number"
                            name="dieselinamt"
                            label="Diesel Per Ltr"
                            value={this.state.dieselperltr}
                            onChange={(e) => this.setState({dieselperltr:parseFloat(e.target.value), dieselinamt: parseFloat(e.target.value)*this.state.dieselinltr,errormsg:'' },()=>this.setState({totalexp:this.state.dieselinamt+this.state.tollamt+this.state.asocamt+this.state.otherexpenses+this.state.wages+this.state.wages2})
                            )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="number"
                            name="dieselinamt"
                            label="Diesel Amount"
                            value={this.state.dieselinamt}
                            disabled
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="number"
                            name="millage"
                            label="Millage"
                            value={this.state.millage===undefined|| this.state.millage===null|| this.state.millage===Infinity?0:this.state.millage.toFixed(2)}
                            disabled
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="number"
                            name="tollamt"
                            label="Toll Charges"
                            value={this.state.tollamt}
                            onChange={(e) => this.setState({ tollamt: parseFloat(e.target.value),errormsg:'' },()=>this.setState({totalexp:this.state.dieselinamt+this.state.tollamt+this.state.asocamt+this.state.otherexpenses+this.state.wages+this.state.wages2}))}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="driver1"
                          options={employees}
                          getOptionLabel={(option) => `${option.first} ${option.last}`}
                          value={values.state ? undefined : undefined}
                          onChange={this.ChangeDriver1}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="driver1"
                              value={this.state.driver1}
                              label={`Select Driver1 (${this.state.driver1})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="number"
                            name="wages"
                            label="Driver1 Wages"
                            value={this.state.wages}
                            onChange={(e) => this.setState({ wages: parseFloat(e.target.value),errormsg:'' },()=>this.setState({
                              totalexp:this.state.dieselinamt+this.state.tollamt+this.state.asocamt+this.state.otherexpenses+this.state.wages+this.state.wages2}))}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="driver2"
                          options={employees}
                          getOptionLabel={(option) => `${option.first} ${option.last}`}
                          value={values.state ? undefined : undefined}
                          onChange={this.ChangeDriver2}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="driver2"
                              value={this.state.driver2}
                              label={`Select Driver2 (${this.state.driver2})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="number"
                            name="wages2"
                            label="Driver2 Wages"
                            value={this.state.wages2}
                            onChange={(e) => this.setState({ wages2: parseFloat(e.target.value),errormsg:'' },()=>this.setState({totalexp:
                              this.state.dieselinamt+this.state.tollamt+this.state.asocamt+this.state.otherexpenses+this.state.wages+this.state.wages2}))}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="number"
                            name="asocamt"
                            label="Asoc/Greas"
                            value={this.state.asocamt}
                            onChange={(e) => this.setState({ asocamt: parseFloat(e.target.value),errormsg:'' },()=>this.setState({totalexp:this.state.dieselinamt+this.state.tollamt+this.state.asocamt+this.state.otherexpenses+this.state.wages+this.state.wages2}))}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="number"
                            name="otherexpenses"
                            label="Other Expenses"
                            value={this.state.otherexpenses}
                            onChange={(e) => this.setState({ otherexpenses: parseFloat(e.target.value),errormsg:'' },()=>this.setState({totalexp:this.state.dieselinamt+this.state.tollamt+this.state.asocamt+this.state.otherexpenses+this.state.wages+this.state.wages2}))}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="text"
                            name="comment"
                            label="Comments"
                            value={this.state.comment}
                            onChange={(e) => this.setState({ comment: e.target.value,errormsg:'' })}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                            className="form-control"
                            type="number"
                            name="totalexp"
                            label="Total Expenses"
                            value={this.state.totalexp}
                            disabled
                        />
                      </fieldset> 
                      <fieldset className="form-group col-md-12 m-0"></fieldset>
                      <fieldset className="form-group col-md-3 my-2 m-0">
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.loadingstatus}
                              onChange={()=>this.setState({loadingstatus:!this.state.loadingstatus})}
                              name="loadingstatus"
                              color="primary"
                            />
                          }
                          label="With Loading?"
                        />
                      </FormGroup>
                    </fieldset>
                    {this.state.loadingstatus===true?<>
                      <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="number"
                              name="loading"
                              label="Loading (in Kgs)"
                              value={this.state.loading}
                              onChange={(e) =>
                                this.setState({ loading: e.target.value },()=>this.setState({differance:this.state.unloading-this.state.loading}))
                              }
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="number"
                              name="unloading"
                              label="Unloading (in Kgs)"
                              value={this.state.unloading}
                              onChange={(e) =>
                                this.setState({ unloading: e.target.value },()=>this.setState({differance:this.state.unloading-this.state.loading}))
                              }
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="number"
                              name="differance"
                              label="Differance (in Kgs)"
                              value={this.state.differance}
                              disabled
                            />
                          </fieldset>
                    </>:''
                    }
                      <fieldset className="form-group col-md-12 m-0"></fieldset>
                      <fieldset className="form-group col-md-3 my-2 m-0">
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.type}
                              onChange={()=>this.setState({type:!this.state.type})}
                              name="type"
                              color="primary"
                            />
                          }
                          label="One way Trip?"
                        />
                      </FormGroup>
                    </fieldset>
                    <div className="form-group col-md-3 my-2 m-0">
                        Documents Upload :&nbsp;&nbsp;
                        {/* {this.state.file !== ''? 
                        <Link as="button"  onClick={this.handlePurchaseorder}>{this.state.file}</Link>
                        : */}
                        {/* <> */}
                        {!selectedFiles ? (
                          <label htmlFor="btn-upload">
                            <input
                              className="btn-choose text-sm"
                              id="btn-upload"
                              name="btn-upload"
                              style={{ display: "none" }}
                              type="file"
                              onChange={this.selectFile}
                              vale="btn-upload"
                            />
                            <Button
                              className="btn-close"
                              variant="outlined"
                              type="upload"
                              component="span"
                            >
                              <i className="fas fa-file mr-1" />
                              <span className="text-blue">{"Select"}</span>
                            </Button>
                          </label>
                        ) : (
                          <>
                            {selectedFiles && selectedFiles.length > 0
                              ? selectedFiles[0].name
                              : null}
                            <Button
                              className="btn-sm btn-upload ml-2 text-sm"
                              color="primary"
                              variant="contained"
                              component="span"
                              disabled={!selectedFiles}
                              onClick={this.upload}
                            >
                              upload <i className="fas fa-upload ml-1"></i>
                            </Button>
                            <span
                              as="button"
                              className="btn btn-info ml-3"
                              onClick={() =>
                                this.setState({
                                  selectedFiles: undefined,
                                })
                              }
                            >
                              Reupload
                            </span>
                          </>
                        )}
                        <p id="fileInfo" className="text-red ml-4"></p>
                        <div className="form-group col-md-3" />
                        {/* </fieldset> */}
                        <div className="form-group col-md-9">
                          <Typography
                            variant="subtitle2"
                            className={`upload-message ${
                              isError ? "error" : ""
                            }`}
                          >
                            <span className="text-green">{message}</span>
                          </Typography>
                        </div>
                        {/* </>} */}
                      </div>
                    </div>
                    <fieldset className="form-group col-md-12" />
                    {this.state.loader === true ? (
                        <button className="btn btn-sm hovbuttonColour" disabled >
                          <Spinner animation="border" size="sm" />
                          &nbsp;{"Saving"}
                          </button>
                      ) : (
                        <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fa fa-save"/> Save</button>
                      )
                    }
                    <Link
                      as="button"
                      className="btn btn-sm deletebutton ml-1"
                      to={{ pathname: "/transportation"}} state = {{ message: "",type:0 }}
                    >
                      <b className="text-danger"> X </b>Close
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

        <Footer />
      </div>
    );
  }
}
export default withRouter(NewTrip);
