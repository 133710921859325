import React, {useState } from "react";
import { useLocation } from "react-router";
import {Link} from 'react-router-dom';
import { NumericFormat } from 'react-number-format';import { currentTenant, thousandsdisplay } from '../../Common/Credentials';
import { Spinner, ThemeProvider } from 'react-bootstrap';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import { spin } from '../../Common/NewLoader';
import Footer from '../../Component/Footer';
import InventoryService from "../../inventory/InventoryService";
import MUIDataTable from "mui-datatables";
import { Backdrop, CircularProgress, createTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import  withRouter  from "../../withRouter.js";



function DirectStocks(){
  const history = useLocation();
  const navigate = useNavigate();
  const [loader,setLoader] = useState(true);
  const [dirstock, setDirStock] = useState([]);
  // const [jvinsertiontype, setJvinsertiontype] = useState([0]);


  const theme = createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "#e9ecef !important",
          border: [[1, "solid", "#ece6e6"]],
        },
        body: {
          border: [[1, "solid", "#ece6e6"]],
        },
      },
    },
  });

 React.useEffect(() => {
    InventoryService.getDirStockEntry(currentTenant).then((response) => {
      setDirStock(response.data);
      if(response!==null){
        setLoader(false)
      }
    });
  },[]);

  const submitRecord=(value)=>{
    let directaddedstock
    if(value!==undefined){
    directaddedstock=dirstock.filter(e=>e.id===value.rowData[0])
    } 
    navigate({pathname:'/dirstockentry'},{state:directaddedstock});
   }

  
  const Stockcolumns = [
    {name: "id",label: "id",options: {filter: false,sort: false,display:false,viewColumns:false}},
    {name: "itemname",label: "Item name",options: {filter: false,sort: true,}},
    {name: "quantity",label: "Quantity",options: {filter: true,sort: true,}},
    {name: "warehouse",label:"Branch/Warehouse",options: {filter: false,sort: true,}},
    {name: "price",label: "Unit Price",options: {filter: false,sort: true,sortOrder: 'asc',
      customBodyRender:(tableMeta)=>{
        return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
    {name: "totalamount",label: "Total Amount",options: {filter: false,sort: true,sortOrder: 'asc',
      customBodyRender:(tableMeta)=>{
        return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
    {name: "id",label:"Action",options: {filter: false,sort: false,display:true,viewColumns:false,    
      customBodyRender:(value,tableMeta)=>{
        return (
          value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
          onClick={() => submitRecord(tableMeta)}>  {loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Pass Entry"}
          </button>:<span className="text-red text-bold">No Values</span>
        );      
      },
      setCellProps: value => ({ style: { outerHeight:100}}),
    }},
   ];


   const Stockoptions = {
    filterType: 'checkbox',
    selectableRows: "none",
    resizableColumns: true,

  }

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/accounts">Home</Link>
            </li>
            <li className="breadcrumb-item active">Direct Stock</li>
          </ol>
          <div className="form-row">
            <div className="card-body">
              <div>
                {!dirstock ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <>
                  <div className="mb-5">
                    <ThemeProvider theme={theme}>
                      <MUIDataTable
                        className="table-sm px-3"
                        title={"Direct Stock"}
                        id="dirstock"
                        data={dirstock}
                        columns={Stockcolumns}
                        options={Stockoptions}
                      />
                    </ThemeProvider>
                    </div>

                  </>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );


}         

export default withRouter(DirectStocks);


 
