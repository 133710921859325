import { Autocomplete, Backdrop, CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormikTextField } from '../CommonComponents/FormField.js';
import React, { Component } from "react";
import { Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import {ccountry, currentTenant, userid } from "../Common/Credentials.js";
import Footer from "../Component/Footer.js";
import Header from "../Component/Header.js";
import Menu from "../Component/Menu.js";
import InventoryService from "../inventory/InventoryService.js";
import CategoryServices from "../Masters/Category/CategoryServices.js";
import UomServices from "../Masters/Uom/UomServices.js"
import MasterService from "../Masters/MasterService.js";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import SettingsServices from "../Settings/SettingsServices.js";
import BrandsServices from "../Masters/Brands/BrandsServices.js";
import Swal from "sweetalert2";
import items from "../Excel Templets/Items.xlsx";
import items0 from "../Excel Templets/Items0.xlsx";
import * as XLSX from 'xlsx'; 

class NewItem extends Component {
  constructor(props) {
    super(props);
    const { pagename } = this.props.location.state;
    const { id } = this.props.location.state;
    this.state = {
      hsnloader:false,
      loader:false,
      pagename: pagename,
      id: id,
      itemname: "",
      hsn: "",
      HsnList: [],
      CategoryList: [],
      category: "",
      catid:'',
      CategoryList2: [],
      category2: "",
      catid2:'',
      mCategoryList: [],
      mcategory: "",
      mcatid:'',
      categorytype:'',
      code: "",
      hsnid: "",
      itemcode:'',
      status: "0",
      istatus: "1",
      message: "",
      success:'',
      uomid: "",
      uom:"",
      UOMList: [],
      ledger: "",
      ledgerid:'',
      ledgerlist: [],
      tax: '',
      additionalparamsforitem:'NO',
      parameters:false,
      description:'',
      brandsList:[],
      brand:'',
      brandid:'',
      model:'',
      entrytype:'manual',
      itemslist:[]
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleparameters=(e)=>{
    this.setState({parameters:e.target.checked})
  }

  handleSelect = (e) => {
    this.setState({ status: e.target.value });
  };

  handleCategory = (value,values) => {
    if(values !== '' && typeof values === 'object' && values !==null){
      let data = this.state.mCategoryList.find(e=>e.id=== values.id).categorylvltwo
    this.setState({ mcatid: values.id, mcategory: values.category, categorytype: values.categorytype,CategoryList2:data,catid2:'',category2:'',catid:'',category:'',CategoryList:[]});
    }
    else{
      this.setState({catid:'',category:'',catid2:'',category2:'',CategoryList2:[],mcatid:'',mcategory:'',CategoryList:[]})
    }
  };
  
  handleCategory2 = (value,values) => {
    if(values !== '' && typeof values === 'object' && values !==null){
      let data = this.state.CategoryList2.find(e=>e.id=== values.id).category
    this.setState({ catid2: values.id, category2: values.name,CategoryList:data,catid:'',category:''});
    }
    else{
      this.setState({catid2:'',category2:'',catid:'',category:'',CategoryList:[]})
    }
  };

  handleCategory3 = (value,values) => {
    if(values !== '' && typeof values === 'object' && values !==null){
    this.setState({ catid: values.id, category: values.category});
    }
    else{
      this.setState({catid:'',category:''})
    }
  };

  handleUOM =(value,values)=>{
   
    if(values !== ''&& typeof values === 'object' && values!==null){
    this.setState({ uomid: values.id, uom: values.name });
    }else{
      this.setState({uomid:'',uom:''})
    }
  }
  handlebrand = (value,values) => {
    if(values !== '' && typeof values === 'object' && values!==null){
      this.setState({ brandid: values.id, brand: values.brand });
      }else{
        this.setState({brandid:'',brand:''})
      }
  }

  handleChange = (e) => {
    this.setState({ istatus: e.target.value });
  };

  handletax = (e)=>{
    this.setState({tax:parseInt(e)})
  }
  

  downloadExcel=()=>{
    var link = document.createElement("a");
    link.href = ccountry==="India"?items:items0
    link.download = 'Items';

    document.body.appendChild(link);

    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  }

  readExcel=(file)=>{
    const promise = new Promise((resolve,reject)=>{
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file)

      fileReader.onload=(e)=>{
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray,{type:'buffer'});
        const wsname  = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      }
      fileReader.onerror = error=>{
        reject(error);
      }
    })
   promise.then((d)=>{
    let items = d.filter(e=> 
      e.name !== "" && e.name !== undefined && e.name!== null 
      && e.itemcode !== "" && e.itemcode!== undefined && e.itemcode!== null &&
      e.tax !== "" && e.tax!== undefined && e.tax!== null 
      && e.uom !== "" && e.uom!== undefined && e.uom!== null 
      && e.description  !== "" && e.description !== undefined && e.description !== null
      && (ccountry === "India"?  (e.hsn !== "" && e.hsn !== undefined && e.hsn !== null) : true )
      )
    this.setState({itemslist:items.map(e=>Object.assign(e, { 
      catid:this.state.catid===""||this.state.catid===undefined?0:this.state.catid,
      category:this.state.category,
      categorylvl2:this.state.category2,
      mcategory:this.state.mcategory,
      catidlvl2:this.state.catid2===""||this.state.catid2===undefined?0:this.state.catid2,
      mcatid:this.state.mcatid,
      categorytype:this.state.categorytype,
      createdby: userid,
      tenantid: currentTenant,
      uomid: this.state.UOMList.find(u=>u.name===e.uom)?.id,
      parameters:this.state.parameters,
    }))},()=>console.log(this.state.itemslist))
  })
  }


  Removeitem = (id) =>{
    this.setState({itemslist:this.state.itemslist.filter(e=>e.itemcode !== id)})
  }

  

  onSubmit(values) {
    this.setState({loader:true})
    if(this.state.entrytype === "manual"){
    let newitems = {
      name: values.itemname,
      itemcode:this.state.itemcode,
      hsn: values.hsn,
      createdby: userid,
      tenantid: currentTenant,
      uomid: this.state.uomid,
      brand:this.state.brandid,
      model:this.state.model===""|| this.state.model===undefined?"NA":this.state.model,
      catid:this.state.catid===""||this.state.catid===undefined?0:this.state.catid,
      category:this.state.category,
      categorylvl2:this.state.category2,
      mcategory:this.state.mcategory,
      catidlvl2:this.state.catid2===""||this.state.catid2===undefined?0:this.state.catid2,
      mcatid:this.state.mcatid,
      tax: this.state.tax,
      categorytype:this.state.categorytype,
      parameters:this.state.parameters,
      description:this.state.description
      
    };

    if (values.status === "1") {
      this.setState({
        istatus: "",
      });
    }

    let updateitems = {  
      id: this.state.id,
      name: values.itemname,
      itemcode:this.state.itemcode,
      hsn: this.state.hsnid,
      updatedby: userid,
      tax:this.state.tax,
      tenantid: currentTenant,
      catid:this.state.catid,
      category:this.state.category,
      categorylvl2:this.state.category2,
      mcategory:this.state.mcategory,
      catidlvl2:this.state.catid2,
      mcatid:this.state.mcatid,
      uomid: this.state.uomid,
      model:this.state.model,
      brand:this.state.brandid,
      // status: parseInt(values.status),
      // itemtype: this.state.status === '0'?parseInt(this.state.istatus):0,
      categorytype:this.state.categorytype,
      parameters:this.state.parameters,
      description:this.state.description,
     
    };
    if (this.state.id < 1) {
      InventoryService.createItems(newitems)
      .then((response)=>
        this.setState({loader:false},()=>
      Swal.fire({
        title: `Item Added Successfully`,
        text: ".",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      }).then(()=>this.props.navigate(-1)
      // this.props.navigate(`/items`,{message:response.data.message,success:response.data.success})
        )
    ))    .catch((e)=>{
      this.setState({message:e.response.data.message,loader:false},()=>{
          if(e.response.data.error ==="Unauthorized"){
              Swal.fire({
                title: `Your Session is Lost: Please login Back to resume`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(()=>this.props.navigate('/'))
          }else{
            Swal.fire({
              title: `${e.response.data.message}`,
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            })
          }})
      })
    } else {

      InventoryService.updateItems(updateitems).then((response) =>
      this.setState({loader:false},()=>
        Swal.fire({
          title: `Item Updated Successfully`,
          text: ".",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        }).then(()=>this.props.navigate(-1))
      )
    ) .catch((e)=>
        this.setState({message:e.response.data.message,loader:false},()=>{
          if(e.response.data.error ==="Unauthorized"){
            Swal.fire({
              title: `Your Session is Lost: Please login Back to resume`,
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(()=>this.props.navigate('/'))
        }else{
          Swal.fire({
            title: `${e.response.data.message}`,
            text: ".",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          })
        }
        })
        )
    }
  }else{

    if(this.state.itemslist.length>0){

    if(this.state.itemslist.filter(e=>e.uomid === undefined||e.uomid===null).length === 0){

      InventoryService.Addmultipleitems(this.state.itemslist)
      .then((response)=>
        this.setState({loader:false},()=>
      Swal.fire({
        title: `Items Added Successfully`,
        text: ".",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      }).then(()=>this.props.navigate(-1)
      // this.props.navigate(`/items`,{message:response.data.message,success:response.data.success})
        )
    ))    .catch((e)=>{
      this.setState({message:e.response.data.message,loader:false},()=>{
          if(e.response.data.error ==="Unauthorized"){
              Swal.fire({
                title: `Your Session is Lost: Please login Back to resume`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(()=>this.props.navigate('/'))
          }else{
            Swal.fire({
              title: `${e.response.data.message}`,
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            })
          }})
      })
  }else{
    this.setState({loader: false})
    Swal.fire({
      title: ``,
      text: "Some UOM's are not available in masters",
      icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#152f5f",
    })
  }
}else{
  this.setState({loader:false})
}
  }
  }

  gethsn = () => {
    this.setState({hsnloader:true})
    let hsn = this.state.hsn;
    MasterService.getHSNbyCode(hsn).then((response) => {
      // if (response.data === "") {
      //   alert(`${this.state.hsn} hsn not valid`);
      //   this.setState({ tax: "" });
      // } else {
        // alert(response.data.map(e=>e.gst))
        // if(response.dat ===undefined){}
        // else{
          let tax =response.data.map(e=>e.gst).toLocaleString()
        this.setState({
          tax:parseFloat(tax * 100),
          hsnloader:false
         });
        // }
      // }
     });
  };

  onfocus(){
    document.getElementById('tax').addEventListener('mousewheel', function(event) {
     if (window.document.activeElement.type === 'number') {
       event.preventDefault()
     }
   })
   document.getElementById('tax').addEventListener('keydown', function(e) {
    if (e.which === 38 || e.which === 40) {
        e.preventDefault();
    }
  });
   }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
      
    const { id } = this.props.location.state;
    // MasterService.getHSNbyCode(code).then((response) =>
    //   this.setState({
    //     HsnList: response.data,
    //   })
    // );

    InventoryService.getLedger(currentTenant).then((response) =>
      this.setState({
        ledgerlist: response.data,
      })
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })


    CategoryServices.getMainCategory(currentTenant).then((response) =>
      this.setState({
        mCategoryList: response.data.filter((e) => e.categorytype === "Product"),
      })
    );

    UomServices.getUOM(currentTenant).then((response) =>
      this.setState({
        UOMList: response.data,
      })
    );

    SettingsServices.GetSettings(currentTenant).then((response)=>{
      this.setState({
        additionalparamsforitem:response.data.additionalparamsforitem
       })
     })

     BrandsServices.getBrands(currentTenant).then(response=>
      this.setState({brandsList:response.data})
      )

    if (id < 1) {
      return;
    }
    InventoryService.retriveItem(id).then((response) =>
      this.setState({
        id: id,
        itemname: response.data.name,
        itemcode:response.data.itemcode,
        hsn: response.data.hsn,
        hsnid: response.data.hsn,
        tax:response.data.tax,
        uomid: response.data.uomid,
        uom: response.data.uomname,
        categorytype:response.data.cattypeid,
        category: response.data.category,
        catid: response.data.catid,
        category2: response.data.category,
        catid2: response.data.catid2,
        mcategory: response.data.mcategory,
        mcatid: response.data.mcatid,
        parameters:response.data.parameters === 0?false:true,
        description:response.data.description,
        brand:response.data.brand,
        brandid:response.data.brandid,
        model:response.data.model
        // status:response.data.status.toLocaleString(),
        // istatus:response.data.itemtype.toLocaleString()
      })
    );
  }

  validate(values) {
    let errors = {};
    if (this.state.id < 1){
      if(this.state.entrytype === "manual"){
    if (!values.itemname) {
      errors.itemname = "Item Name is required";
    }
    if (this.state.success === false) {
      errors.itemname = "Item already exists!";
    }
    if (this.state.itemcode === '') {
      errors.itemcode = "Please Enter Item Code";
    }
    
    if (!values.uomid || values.uomid === "") {
      errors.uom = "Unit of Measurement is required";
    }
    if (ccountry==="India" && (!values.hsn || values.hsn === "")) {
      errors.hsn = "HSN is required";
    }
    // if(ccountry === "India" && values.hsn.length !== 6 ){
    //   errors.hsn = "Please Enter 6 digit HSN"
    // }


    if(values.tax === ''){
      errors.tax = "Tax is required";
    }
    if(values.tax <0){
      errors.tax = "Tax should be greater than 0";
    }
    if (this.state.description === "" || this.state.description.length <3 ) {
      errors.description = "Please Enter minimum 3 Letters";
    }
  }
    if (!values.mcategory || values.mcategory === "") {
      errors.mcategory = "Category is required";
    }

    if(this.state.CategoryList2.length !== 0){
    if(this.state.category2 === ""){
      errors.category2 = "Category level 2 is required";
    }
  }

  if(this.state.CategoryList.length !== 0){
    if(this.state.category === ""){
      errors.category = "Category level 3 is required";
    }
  }
  
  }
    return errors;
  }

  render() {
    // let ledgerlist = this.state.ledgerlist;
    let mCategoryList = this.state.mCategoryList;
    let UOMList = this.state.UOMList;
    let {
      id,
      itemname,
      itemcode,
      hsn,
      status,
      istatus,
      category,
      catid,
      category2,
      catid2,
      mcategory,
      mcatid,
      CategoryList2,
      CategoryList,
      uom,
      uomid,
      ledger,
      tax,
      categorytype,
      parameters,
      description,
      model,
    } = this.state;

    return (
      <div>
        <Header />
         <Menu/>
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
            <Link as="button" className=" text-secondary"   to={{ pathname: "/items"}} state ={{producttype:false} }>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link  to={{ pathname: "/items"}} state ={{producttype:false} }>Items</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>

            <div className="card-header">
            <h3 className="card-title">
                    {this.state.pagename}
                  </h3>
            </div>

            <Backdrop
  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
  open={this.state.loader}
>
  <CircularProgress color="inherit" />
</Backdrop>

            <div className="card-body">

            <fieldset className="form-group col-md-12">
                                <fieldset
                                  className={`form-group col-md-4  shadow-sm text-center btn ${
                                    this.state.entrytype === "manual"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.entrytype === "manual"}
                                  onClick={() => this.setState({entrytype:"manual"})}
                                >
                                  Manually
                                </fieldset>
                                <fieldset
                                  className={`form-group col-md-4 shadow-sm btn text-center ${
                                    this.state.entrytype === "upload"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.entrytype === "upload"}
                                  onClick={() => this.setState({entrytype:"upload"})}
                                >
                                  Upload
                                </fieldset>
                              </fieldset>


              <Formik
                initialValues={{
                  id,
                  itemname,
                  itemcode,
                  hsn,
                  status,
                  istatus,
                  category,
                  catid,
                  category2,
                  catid2,
                  mcategory,
                  mcatid,
                  CategoryList,
                  CategoryList2,
                  mCategoryList,
                  uom,
                  uomid,
                  ledger,
                  tax,
                  categorytype,
                  parameters,
                  description,
                  model
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({setFieldValue,values}) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="id"
                          placeholder="Id"
                        />
                      </fieldset>
                      {this.state.id === -1 && (this.state.mCategoryList===undefined && this.state.mCategoryList.length===0 && this.state.brandsList===undefined && this.state.brandsList.length===0 && this.state.UOMList===undefined && this.state.UOMList.length===0)?
                      <fieldset className="form-group col-md-12 deletebg">
              <ul className="py-3">
                <li style={{"list-style": 'none'}}>Note:</li>
              {this.state.mCategoryList===undefined || this.state.mCategoryList.length===0?
              <li>No categories are avilable add category in Masters under Inventory Masters in order to add item</li>
              :""}
              {this.state.brandsList===undefined||this.state.brandsList.length===0?
              <li>No Brands are avilable add brands in Masters under Inventory Masters in order to add item</li>
              :""}
              {this.state.UOMList===undefined||this.state.UOMList.length===0?
              <li>No UOM are avilable add UOM in Masters under Inventory Masters in order to add item</li>
              :""}
              </ul>
              </fieldset>
                    :""}
                      <fieldset className="form-group col-md-3 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="mcategory"
                          options={mCategoryList}
                          getOptionLabel={(option) => option.category}
                          
                          
                          value={values.category?undefined:undefined}
                          onChange={this.handleCategory}
                            disabled={this.state.pagename ==="Update Item"}
                          renderInput={params => (  
                            <FormikTextField
                                {...params}
                              className="form-control"
                              type="text"
                              name="mcategory"
                              value={this.state.mcategory}
                              label={`Select Category (${this.state.mcategory})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      {this.state.CategoryList2.length!==0 || (this.state.catid2!=='')?
                      <fieldset className="form-group col-md-3 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="category2"
                          options={ this.state.CategoryList2}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.category2?undefined:undefined}
                          onChange={this.handleCategory2}
                            disabled={this.state.pagename ==="Update Item"}
                          renderInput={params => (  
                            <FormikTextField
                                {...params}
                              className="form-control"
                              type="text"
                              name="category2"
                              label={`Select Category Level 2 (${this.state.category2})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>:''
                      }
                      {this.state.CategoryList.length!==0|| this.state.catid!==''?
                      <fieldset className="form-group col-md-3 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="category"
                          options={ this.state.CategoryList}
                          getOptionLabel={(option) => option.category}
                          
                          
                          value={values.category?undefined:undefined}
                          onChange={this.handleCategory3}
                            disabled={this.state.pagename ==="Update Item"}
                          renderInput={params => (  
                            <FormikTextField
                                {...params}
                              className="form-control"
                              type="text"
                              name="category"
                              value={this.state.category}
                              label={`Select Category Level 3 (${this.state.category})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>:''
                      }
                    {this.state.entrytype === "manual"?
                      <>
                      <fieldset className="form-group col-md-3 pb-3">
                      <FormikTextField
                        className="form-control"
                        type="text"
                        name="itemname"
                        label="Item Name"
                        value={this.state.itemname}
                        onChange={(e) => this.setState({ itemname: e.target.value })}
                      />
                    </fieldset>

                      <fieldset className="form-group col-md-3 pb-3">
                      <FormikTextField
                        className="form-control"
                        type="text"
                        name="itemcode"
                        label="Item Code"
                        value={this.state.itemcode}
                        onChange={(e) => this.setState({ itemcode: e.target.value })}
                      />
                    </fieldset>
                    <fieldset className="form-group col-md-2">
                    <FormikTextField
                      className="form-control"
                      type="text"
                      name="hsn"
                      label="Search HSN "
                      value={this.state.hsn}
                      onChange={(e) => this.setState({ hsn: e.target.value })}
                      disabled={this.state.pagename ==="Update Item"}
                    /> 
                  </fieldset>
                  <fieldset className="form-group col-md-1" disabled={this.state.pagename ==="Update Item"}>
                     <Button onClick={this.gethsn} className="btn btn-sm hovbuttonColour mt-3">{this.state.hsnloader === false?<i className="fas fa-search"/>:<Spinner animation="border" size="sm" />}</Button>
                  </fieldset>

                  {ccountry === "India"?(<>

                <fieldset className="form-group col-md-3">
                  <FormikTextField
                    className="form-control"
                    id="tax"
                    type="number"
                    name="tax"
                    label="Tax (%)"
                    value={this.state.tax}
                    onChange={(e)=>this.handletax(e.target.value)}
                    onFocus={this.onfocus}
                    // disabled={this.state.pagename ==="Update Item"}
                  />
                </fieldset>
                </>
              ): (<>
                  <fieldset className="form-group col-md-3">
                    <FormikTextField
                      className="form-control"
                      type="text"
                      name="vat"
                      label="VAT (%)"
                      value={this.state.tax}
                      onChange={(e) => this.setState({ tax: e.target.value })}
                    />
                  </fieldset>
                    </>)}
                   
                    <fieldset className="form-group col-md-3">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="uom"
                            options={UOMList}
                            getOptionLabel={(option) => option.name}
                            
                            
                            value={values.uom?undefined:undefined}
                            onChange={this.handleUOM}
                            renderInput={params => (  
                              <FormikTextField
                                  {...params}
                                className="form-control"
                                type="text"
                                name="uom"
                                value={this.state.uom}
                                label={`Select UOM (${this.state.uom})`}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset> 
                        <fieldset className="form-group col-md-3 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="brand"
                          options={ this.state.brandsList}
                          getOptionLabel={(option) => option.brand}
                          value={values.brand?undefined:undefined}
                          onChange={this.handlebrand}
                          renderInput={params => (  
                            <FormikTextField
                                {...params}
                              className="form-control"
                              type="text"
                              name="brand"
                              value={this.state.brand}
                              label={`Select Brand (${this.state.brand})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset> 

                       <fieldset className="form-group col-md-3 pb-3">
                      <FormikTextField
                        className="form-control"
                        type="text"
                        name="model"
                        label="Model"
                        value={this.state.model}
                        onChange={(e) => this.setState({ model: e.target.value })}
                      />
                    </fieldset> 

                        <fieldset className="form-group col-md-12  mt-3 mb-5">
                                      <FormikTextField name="description" className="form-control" multiline rows={2} label="Specification / Description" variant="outlined" value={this.state.description}
                                        onChange={(e) => this.setState({description:e.target.value})}
                                      />
                                    </fieldset>
                       {this.state.additionalparamsforitem==='YES'?
                    <div className="form-group col-md-6 m-0">
                   <FormGroup row className="mt-3">
                    <FormControlLabel
                      control={<Checkbox
                        checked={this.state.parameters}
                        onChange={this.handleparameters}
                        name="parameters" 
                        color="primary"
                      />}
                      label="Additonal Paramerts for Sales"
                    />
                    </FormGroup>
                    </div>
                    :''}
                    </>
                    :
                    this.state.mcategory ==="" || (this.state.CategoryList2.length!==0 && this.state.catid2==='') || (this.state.CategoryList.length!==0 && this.state.catid==='') ?"":
                    <div className="form-row ml-2">
                    <ul className="form-group col-md-12">
                      <label className="text-red">Note: </label>
                     <li> The Excel Sheet must contain columns with name 'name', 'itemcode', {ccountry==="India"?"'hsn', ":''}'tax', 'uom', 'brand', 'model', 'description' accepts only ".xlsx, .xls, .csv"</li>
                     <li> The UOM must match the UOM's added in the Masters </li>
                     <li> 'name', 'itemcode', {ccountry==="India"?"'hsn', ":''}'tax', 'uom', 'description (must be constain min 3 characters)' are mandatory else item will not be added</li>
                  </ul>
                  {this.state.itemslist.length === 0?<>
                  <fieldset className="form-group col-md-4">
                    <label>Download Excel format &nbsp;&nbsp;&nbsp;</label>
                  <button className="btn btn-sm hovbuttonColour shadow-sm fa fa-file-excel text-md" type='buton' data-tip data-for="pdf" onClick={this.downloadExcel}>&nbsp;Download</button>
                    </fieldset>
                    <fieldset className="form-group col-md-12 m-0"></fieldset>
                        <fieldset className="form-group col-md-1">
                        <label>Upload here </label>
                        </fieldset>
                        <fieldset className="form-group col-md-4">
                          <label htmlFor="btn-upload">
                            <input
                              className="btn-choose "
                              id="btn-upload"
                              name="btn-upload"
                              type="file"
                              onChange={e=>{
                                if(e.target.files[0] !== undefined){
                                const file = e.target.files[0];
                                this.readExcel(file)
                                }
                              }}
                              accept=".xlsx, .xls, .csv"
                            />
                          </label>
                        </fieldset>
                        </>:""}
                        <fieldset className="form-group col-md-12">
                          {this.state.itemslist.length === 0?'':
                          <>
                          <label>Items ({this.state.itemslist.length}):&nbsp;</label>
                                    <Table>
                                      <TableHead>
                                        <TableRow>
                                          <TableCell>Name</TableCell>
                                          <TableCell>Item Code</TableCell>
                                          {ccountry==="India"?<TableCell>HSN</TableCell>:""}
                                          <TableCell>Tax %</TableCell>
                                          <TableCell>UOM</TableCell>
                                          <TableCell>Brand</TableCell>
                                          <TableCell>Model</TableCell>
                                          <TableCell>Description</TableCell>
                                          <TableCell>Action</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {this.state.itemslist.map((e,i) =>
                                        <TableRow key={i}>
                                          <TableCell>{e.name}</TableCell>
                                          <TableCell>{e.itemcode}</TableCell>
                                          {ccountry==="India"?<TableCell>{e.hsn}</TableCell>:""}
                                          <TableCell>{e.tax}</TableCell>
                                          <TableCell className={e.uomid === undefined||e.uomid===null?"text-red":""}>{e.uom}</TableCell>
                                          <TableCell>{e.brand}</TableCell>
                                          <TableCell>{e.model}</TableCell>
                                          <TableCell>{e.description}</TableCell>
                                          <TableCell><i className="fa fa-trash cursorPointer text-red" onClick={()=>this.Removeitem(e.itemcode)}></i></TableCell>
                                        </TableRow>
                                            )}
                                      </TableBody>
                                    </Table>

                                 {this.state.itemslist.filter(e=>e.uomid === undefined||e.uomid===null).length === 0?"":
                                 <span>Some of the UOM's are not present in masters either add the UOM's in masters or remove the item</span>

                                 }   
                    </>}
                        </fieldset>
                      </div>

                    }
                      <div className="form-group col-md-12" />
                        
                    </div>
                    <div className="form-group col-md-12 text-red">{this.state.message}</div>

                    {this.state.loader===true? <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" /> Saving</button>:<button className="btn btn-sm hovbuttonColour" type="submit"> <i className="fa fa-save mr-2"/>{"Save"}</button>}
                    <Link
                      as="button"
                      className="btn btn-sm deletebutton ml-1"
                      to={{ pathname: "/items"}} state ={{producttype:false}}
                    >
                      <b className="text-danger mr-1"> X</b>
                     Cancel
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(NewItem);
