import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

class CustomerService{
    
   

   retriveAllCustomers(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/findcustomers/${tenantId}`)
      }

//       retriveAuthUsers(){
//          return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/auth/getauthuser`)
//       }

      retriveCustomer(id){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getcustomers/${id}`)
      }

//       getRoles(){
//          return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/getroles`)
//       }
     
      createCustomer(newCustomer){
         return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addnewcustomers`,newCustomer)
      }

      updateCustomer(updateCustomer){
         return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatecustomers`,updateCustomer)
      }

      getCountries(){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/loadCountries`,)
      }

      getStates(value){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/loadStatesByCountry/${value}`,)
      }

      getCities(id){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/loadCitiesByState/${id}`,)
      }
      deleteCustomer(id){
         return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletecustomer/${id}`,)
      }

      setAsSupplier(id){
         return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addsupplierfromcustomer`,id)
      }
      
      addSalesBalRev(data){
         return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/addSalesBalRev`,data)
      }


//     settenantid(TenantID){
//       currentTenant = TenantID
//     }
// 
}

export default new CustomerService();
