import React, { Component } from 'react'
import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import Menu from '../../Component/Menu'
import CostDataTable from './CostDataTable'

export default class Cost extends Component {
    render() {
        return (
            <div>
            <Header/>
                <Menu/>
                <>
              <div className="content-wrapper">
              <CostDataTable/>

              </div>
              </>
              <Footer/>

            </div>
        )
    }
}
