import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

class SalesCalculationServices {

  itemsList(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getitemforsales/${currentTenant}`,)
  }

  costDataById(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getcostofitemforsales/${id}`,)
  }

  costType(id,type){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/gettypeofcost/${id}&${type}`,)
  }

  SalesCal(salesCal){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/addsalescalc`,salesCal)
  }
  UpdateSalesCal(salesCal){
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/updatesalescalc`,salesCal)
  }

  SalesCalTable(currentTenant) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getallsalescalcitems/${currentTenant}`,)
  }

  SalesCalDetails(id) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsalescalcbyid/${id}`,)
  }
  DeleteSalesCal(id){
    return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/deletesalescalc/${id}`)   
  }
    
 

   }
export default new SalesCalculationServices();