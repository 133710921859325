import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Stack,
  Button,
} from "@mui/material";
// import LineChartSales from "./LineChartSales";
// import ChartOrderRevenue from "./ChartOrderRevenue";
// import JumboContent from "@jumbo/components/JumboContent";
// import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import RenderPurchaseData from "./RenderPurchaseData";
import LineChartComp from "./LineChart";
import RadialChart from "./RadialChart";
import withRouter from "../../../withRouter";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { currency, financialyearend, financialyearstart, thousandsdisplay } from "../../../Common/Credentials";
import { NumericFormat } from "react-number-format";
import PurchaseCards from "./PurchaseCards";
import SuppliersPurchasebyYear from "../Yearly/SuppliersPurchasebyYear";
import Payables from "./Payables";

const Div = styled("div")({});

const StyledButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "5px 7px",
  border: "1px solid",
  letterSpacing: "1px",
  lineHeight: 1.5,
  backgroundColor: "#022533",
  borderColor: "#022533",
  color: "#fff",
  transition:
    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: "4px",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#022533",
    borderColor: "#022533",
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const StyledOutlineButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "5px 7px",
  border: "1px solid",
  letterSpacing: "1px",
  lineHeight: 1.5,
  backgroundColor: "transparent",
  borderColor: "#022533",
  color: "#022533",
  transition:
    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: "4px",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  // '&:hover': {
  //   backgroundColor: '#022533',
  //   borderColor: '#022533',
  //   boxShadow: 'none',
  //   color:'#fff'
  // },
  // '&:active': {
  //   boxShadow: 'none',
  //   backgroundColor: '#0062cc',
  //   borderColor: '#005cbf',
  // },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const salesData = [
  {
    name: "Orders Monthly",
    amount: "48,548",
  },
  {
    name: "Orders Weekly",
    amount: "6,478",
  },
  {
    name: "Average Revenue",
    amount: "$210,213",
  },
  {
    name: "Total Revenue",
    amount: "$692,874",
  },
  {
    name: "Total Orders",
    amount: "9,223",
  },
  {
    name: "Past Orders",
    amount: "8,543",
  },
];

const Item = styled("div")(({ theme }) => ({
  color: "#fff",
  marginBottom: 16,
  width: "15.5%",
  [theme.breakpoints.down("lg")]: {
    width: "16.5%",
  },
  [theme.breakpoints.down("md")]: {
    width: "33.3%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
  padding: theme.spacing(0, 2),
}));

const PurchaseStatistics = ({data,poloader}) => {
  const theme = useTheme();
  const [activeChart, setActiveChart] = React.useState("Orders");
  const [ChartType, setChartType] = React.useState("YEAR");
  const [loader,setLoader] = React.useState(false)
  return (
    <>
      <PurchaseCards data={data} poloader={poloader} theme={false} />
   
    <div className="card my-2">
      <CardContent style={{ backgroundColor: "#ffdd15" }}>
        <CardHeader
          title={
            <Typography variant={"h5"} color={"#022533"} fontSize={"1rem"}>
              Purchase {activeChart} Statistics
            </Typography>
          }
          action={
            <Stack direction={"row"} spacing={1}>
              {activeChart === "GRN" ? (
                <StyledButton size={"small"}>GRN</StyledButton>
              ) : (
                <StyledOutlineButton
                  disableElevation
                  size={"small"}
                  onClick={() => setActiveChart("GRN")}
                >
                  GRN
                </StyledOutlineButton>
              )}
              {activeChart === "Orders" ? (
                <StyledButton size={"small"}>Orders</StyledButton>
              ) : (
                <StyledOutlineButton
                  disableElevation
                  size={"small"}
                  onClick={() => setActiveChart("Orders")}
                >
                  Orders
                </StyledOutlineButton>
              )}
            </Stack>
          }
        />

        <RenderPurchaseData
          activeChart={activeChart}
          loader={poloader}
          orders={[
            {
              name: "Today",
              amount: data.todaystotalPOtransaction,
              order: data.totalpotoday,
            },
            {
              name: "This Week",
              amount: data.totalPOtransactionofweek,
              order: data.totalpothisweek,
            },
            {
              name: "This Month",
              amount: data.totalfyPOtransactionofmonth,
              order: data.totalpothismonth,
            },
            {
              name: "This Year",
              amount: data.totalfyPOtransaction,
              order: data.totalpoinfyyear,
            },
          ]}
          invoice={[
            {
              name: "Today",
              amount: data.todaystotalgrntransaction,
              order: data.totalgrntoday,
            },
            {
              name: "This Week",
              amount: data.totalgrntransactionofweek,
              order: data.totalgrnthisweek,
            },
            {
              name: "This Month",
              amount: data.totalfygrntransactionofmonth,
              order: data.totalgrnthismonth,
            },
            {
              name: "This Year",
              amount: data.totalfygrntransaction,
              order: data.totalgrninfyyear,
            },
          ]}
        />
      </CardContent>

      <Div>
        <Grid container>
          <Grid item md={12} lg={6}>
            <Div
              sx={{
                p: (theme) => theme.spacing(0, 2, 3, 2),
              }}
            >
              <CardHeader
                title={<Typography>Order vs GRN Amount</Typography>}
                action={
                  <Stack direction={"row"} spacing={1}>
                    {/* {ChartType === "WEEk" ? (
                      <StyledButton size={"small"}>Week</StyledButton>
                    ) : (
                      <StyledOutlineButton
                        disableElevation
                        size={"small"}
                        onClick={() => setChartType("WEEk")}
                      >
                        Week
                      </StyledOutlineButton>
                    )} */}
                    {ChartType === "MONTH" ? (
                      <StyledButton size={"small"}>Month</StyledButton>
                    ) : (
                      <StyledOutlineButton
                        disableElevation
                        size={"small"}
                        onClick={() => setChartType("MONTH")}
                      >
                        Month
                      </StyledOutlineButton>
                    )}
                    {ChartType === "YEAR" ? (
                      <StyledButton size={"small"}>Year</StyledButton>
                    ) : (
                      <StyledOutlineButton
                        disableElevation
                        size={"small"}
                        onClick={() => setChartType("YEAR")}
                      >
                        Year
                      </StyledOutlineButton>
                    )}
                  </Stack>
                }
              ></CardHeader>
              <LineChartComp
                order={
                  ChartType === "YEAR"
                    ? data.yearamount
                    : ChartType === "MONTH"
                    ? data.monthamount
                    : data.weekamount
                }
                invoice={
                  ChartType === "YEAR"
                    ? data.grnyearamount
                    : ChartType === "MONTH"
                    ? data.grnmonthamount
                    : data.grnweekamount
                }
                type={ChartType}
                loader={poloader}
              />
            </Div>
          </Grid>
          <Grid item md={12} lg={6}>
            <Div
              sx={{
                // p: (theme) => theme.spacing(3, 0, 3, 2),
                [theme.breakpoints.up("lg")]: {
                  borderLeft: (theme) => `solid 2px ${theme.palette.divider}`,
                },
                [theme.breakpoints.down("lg")]: {
                  borderTop: (theme) => `solid 2px ${theme.palette.divider}`,
                },
              }}
            >
              {/* <RadialChart
                data={[
                  {
                    name: "This Year GRN",
                    amount: data?.totalfygrntransaction,
                    fill: "#2EB5C9",
                  },
                  {
                    name: "This Month GRN",
                    amount: data?.totalfygrntransactionofmonth,
                    fill: "#F39711",
                  },
                  {
                    name: "This Year Purchases",
                    amount: data?.totalfyPOtransaction,
                    fill: "#7352C7",
                  },
                  {
                    name: "This Month Purchases",
                    amount: data?.totalfyPOtransactionofmonth,
                    fill: "#3BD2A2",
                  },
                ]}
                loader={poloader}
              /> */}
              <SuppliersPurchasebyYear theme={false}  start={financialyearstart} end={financialyearend} loading={poloader}/>
            </Div>
          </Grid>
        </Grid>
      </Div>
    </div>

              <Payables data={data} poloader={poloader} theme={false} />

    </>
  );
};

export default withRouter(PurchaseStatistics);
