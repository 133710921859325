import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import { clientname, currentTenant, dataaccess, financialyearend, financialyearstart, logo, thousandsdisplay, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import { purchaseid } from "../../Common/Permissions";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import PurchaseServices from "../PurchseServices";
import CustomToolbar from '../../CommonComponents/CustomToolbar';
import MUIDataTable from 'mui-datatables';
import { NumericFormat } from 'react-number-format';import IOSSwitch from "../../CommonComponents/IOSSwitch";
import { FormControlLabel, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable" 
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

// const theme = createTheme({
//   overrides: {
//     MuiTableCell: {
//       root:{
//       head: {
//         backgroundColor: "#e9ecef !important",
//           border: [[1, 'solid', '#ece6e6']], 
//       },
//       body:
//       {
//         border: [[1, 'solid', '#ece6e6']],
//       },
//     }
//     },
//   },
// });



export class Purchase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      podata: [],
      completedpo:[],
      cancelledpo:[],
      po:true,
      comp:false,
      canl:false,
      pendingpoitems:[],
      completedpoitems:[],
      pendingbyitem:true,
      compbyitem:false,
      // pooritem:true,
      type:"Purchases By Order",
      fromdate: moment(new Date()).subtract(7, "days"),
      todate: new Date(),
    };
  }

  getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides:{
                head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
        }
      }
    }
  })


  handlepoChange=()=>{
    this.setState({po:true,comp:false,canl:false})
  }
  handlecompChange=()=>{
    this.setState({comp:true,po:false,canl:false})
  }
  handlecanlChange=()=>{
    this.setState({canl:true,po:false,comp:false})
  }


  handlePendingChangeByitem=()=>{
    this.setState({pendingbyitem:true,compbyitem:false})
  }
  handlecompChangeByitem=()=>{
    this.setState({pendingbyitem:false,compbyitem:true})
  }
 
  refreshPurchase() {

    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
      userid: userid,
    dataaccess:dataaccess
    }

    PurchaseServices.GetAllPurchases(datadao).then((response) => {
      this.setState({ podata: response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).map(e=>Object.assign(e,{month:moment(e.createdAt).format("MMMM YYYY")}))});
      this.setState({ loading: false });
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })

    PurchaseServices.GetPObyItems(currentTenant).then(res=>{
      this.setState({pendingpoitems:res.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).filter(e=>e.pendingquantity!==0),completedpoitems:res.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).filter(e=>e.pendingquantity===0)})
    })
    
  }

  refreshcompletdorcancelledSO = (from,to)=>{
    let datadao = {
      tenantid: currentTenant,
      startdate: from,
      enddate: to,
      userid: userid,
    dataaccess:dataaccess
    }
    PurchaseServices.GetCompletedPO(datadao).then(response=>{
      this.setState({completedpo:response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).map(e=>Object.assign(e,{month:moment(e.createdAt).format("MMMM YYYY")}))})
    })

    PurchaseServices.GetCancelledPO(datadao).then(response=>{
      this.setState({cancelledpo:response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)})
    })
  }

  
  handleFromDateChange = (date) => {
    this.setState({
      fromdate: date,
      todate:
        moment(date).diff(this.state.todate, "days") >= 0
          ? this.state.todate
          : date,
    });
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  handlecompletedDateChange = () => {
    this.refreshcompletdorcancelledSO(this.state.fromdate, this.state.todate);
  };


  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });

    this.refreshPurchase();
    this.refreshcompletdorcancelledSO( moment(new Date()).subtract(7, "days"),
    new Date())
  }

  render() {

      const columns = [
        {name: "id",label: "PO Id",options: {filter: false,sort: true,display: false,viewColumns:false}},
        {name: "custompoid",label: "Purchase Order Id",options: {filter: false,sort: true,}},
        // {name: "customsupid",label:"Supplier Id",options: {filter: true,sort: true,}},
        {name: "supplier",label: "Supplier",options: {filter: true,sort: true,}},
        {name: "agent",label: "Agent",options: {filter: true,sort: true, customBodyRender:(value)=>
          <span>{value === null || value === undefined || value === ''?"Direct":value}</span>
        }},
        {name: "createdAt",label: "Date",options: {filter: false,sort: true,sortOrder: 'asc', 
        customBodyRender:(tableMeta)=>{
          return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
        }}},
        {name: "potype",label: "PO Type",options: {filter: true,sort: true,}},
        {name: "postatus",label: "PO Status",options: {filter: true,sort: true,}},
        
        {name: "potaxamount",label: "Tax Amount",
        options: {filter: false,sort: true,start:'right',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
        },},
        {name: "pototal",label: "Total Amount",
        options: {filter: false,sort: true,start:'right',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
        },},
        {name: "createdbyname",label: "Created By",options: {filter: true,sort: true,}},
        {name: "createdAt",label: "Aging(in Days)",options: {filter: false,sort: true, 
          customBodyRender:(value)=>{
            let cre = moment(value,'YYYY-MM-DD')
            let curr = moment(new Date(),'YYYY-MM-DD')
            return(<>{curr.diff(cre,"days")} Days</>)
          }}},
          {name: "month",label: "Month",options: {filter: true,sort: true,display:false}},
        {
         name: "View",
         options: {
           filter: false,
           sort: false,
           empty: true,
           customBodyRender: (value, tableMeta, updateValue) => {
             return (<>
               <Link as="button" data-tip data-for="vieworder" className="fa fa-list-alt"
               to={{pathname: "/viewpurchaseorder"}} state={{id:tableMeta.rowData.find(e=>e)}}></Link>
               {/* <Tooltip id="vieworder" place="top" effect="solid">View</Tooltip> */}
               </>
             );
           },
         }
       },
       ];
       const options = {
        filterType: 'checkbox',
        selectableRows: "none",
        customToolbar: () => {
         return (<>
      {purchaseid === '2' || purchaseid === '3'?
          <Link as="button"  to={{pathname: "/newpurchase"}} state= {{ id: -1, pagename:  "New Purchase Order",indentid:''}}>
            <CustomToolbar />
          </Link>
            :''  
          }
         </>
         );
       },

       onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } ,
    downloadOptions:{
      filename:"Purchase Order",
      separator:",",
      filterOptions:{
        useDisplayedColumnsOnly:true,
        useDisplayedRowsOnly:true
      }
    },
       setRowProps: row => { 
        if ((row[4].substr(0, 10)) === moment().format('DD-MM-YYYY')) {
          return {
            style: { background: "#ecf8fb" }
          };
        }
        if (row[6] === "Pending") {
          return {
            style: { background: "#f7dddd" }
          };
        }
        // else{
        //   return {
        //     style: { background: "#ccfbcc" }
        //   };
        // }
      }
      }

      const columns1 = [
        {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "custompoid",label: "PO Id",options: {filter: false,sort: true}},
        // {name: "supplierid",label:"Supplier Id",options: {filter: true,sort: true,}},
        {name: "supplier",label: "Supplier",options: {filter: true,sort: true,}},
        {name: "agent",label: "Agent",options: {filter: true,sort: true, customBodyRender:(value)=>
          <span>{value === null || value === undefined || value === ''?"Direct":value}</span>
        }},
        {name: "createdAt",label: "Date",options: {filter: false,sort: true,sortOrder: 'asc', 
        customBodyRender:(tableMeta)=>{
          return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
        }}},
        {name: "potype",label: "PO Type",options: {filter: true,sort: true,}},
        {name: "potaxamount",label: "Tax Amount",
        options: {filter: false,sort: true,start:'right',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
        },},
        {name: "pototal",label: "Total Price",
        options: {filter: false,sort: true,start:'right',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
        },},
        {name: "createdbyname",label: "Created By",options: {filter: true,sort: true,}},
        {name: "postatus",label: "PO Status",options: {filter: true,sort: true,}},
        {name: "month",label: "Month",options: {filter: this.state.comp,sort: this.state.comp,display:false}},
        {
         name: "View",
         options: {
           filter: false,
           sort: false,
           empty: true,
           customBodyRender: (value, tableMeta, updateValue) => {
             return (<>
               <Link as="button" data-tip data-for="vieworder" className="fa fa-list-alt"
               to={{pathname: "/viewpurchaseorder"}} state={{id:tableMeta.rowData.find(e=>e)}}></Link>
               {/* <Tooltip id="vieworder" place="top" effect="solid">View Orders</Tooltip> */}
               </>
             );
           },
         }
       },
       ];
       const options1 = {
        filterType: 'checkbox',
        selectableRows: "none",
        
       onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } ,
    downloadOptions:{
      filename:"Purchase Order",
      separator:",",
      filterOptions:{
        useDisplayedColumnsOnly:true,
        useDisplayedRowsOnly:true
      }
    },
      }
  
      const columnsbyitem1 = [
        {name: "custompoid",label: "PO Id",options: {filter: false,sort: true,setCellProps: value => ({ style: { textAlign:'center'}}),}},
        // {name: "supplierid",label:"Supplier Id",options: {filter: true,sort: true,}},
        {name: "supplier",label: "Supplier",options: {filter: true,sort: true,setCellProps: value => ({ style: { textAlign:'center'}}),}},
        {name: "agent",label: "Agent",options: {filter: true,sort: true, customBodyRender:(value)=>
          <span>{value === null || value === undefined || value === ''?"Direct":value}</span>
        }},
        {name: "createdbyname",label: "Created By",options: {filter: true,sort: true,setCellProps: value => ({ style: { textAlign:'center'}}),}},
        {name: "itemname",label: "Itemname",options: {filter: true,sort: true,setCellProps: value => ({ style: { textAlign:'center'}}),}},
        {name: "price",label: "Price",
        options: {filter: false,sort: true,start:'right',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
        },},
        {name: "quantity",label: "Total Quantity",options: {filter: true,sort: true, setCellProps: value => ({ style: { textAlign:'center'}}),}},
        {name: "pendingquantity",label: "Pending Quantity",options: {filter: true,sort: true,
          customBodyRender:(value,tableMeta)=>{
            return(<span>{tableMeta.rowData[14]==="Pending"?0:value}</span>)
          },
          setCellProps: value => ({ style: { textAlign:'center'}}),}},
        {name: "acceptedquantity",label: "Accepted Quantity",options: {filter: true,sort: true,setCellProps: value => ({ style: { textAlign:'center'}}),}},
        {name: "adjustedquantity",label: "Adjusted Quantity",options: {filter: true,sort: true,setCellProps: value => ({ style: { textAlign:'center'}}),}},
        {name: "preclosedquantity",label: "Preclosed Quantity",options: {filter: true,sort: true,
          customBodyRender:(value,tableMeta)=>{
            return(<span>{value===undefined||value===null?0:value}</span>)
          },
          setCellProps: value => ({ style: { textAlign:'center'}}),}},
        {name: "uom",label: "UOM",options: {filter: true,sort: true,setCellProps: value => ({ style: { textAlign:'center'}}),}},
        // {name: "tax",label: "Tax (%)",options: {filter: true,sort: true,setCellProps: value => ({ style: { textAlign:'center'}}),}},
        // {name: "totalprice",label: "Total",
        // options: {filter: false,sort: true,start:'right',
        // customBodyRender:(tableMeta)=>{
        //   return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        // },
        // setCellProps: value => ({ style: { textAlign:'right'}}),
        // },},
        {name: "totalpricewtax",label: "Total (Inc. Tax)",
        options: {filter: false,sort: true,start:'right',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
        },},
        {name: "postatus",label: "Status",options: {filter: true,sort: true,setCellProps: value => ({ style: { textAlign:'center'}}),}},
        {name: "createdAt",label: "Date",options: {filter: false,sort: true,sortOrder: 'asc', 
        customBodyRender:(tableMeta)=>{
          return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
        }}},
       ];
       const optionsbyitem1 = {
        filterType: 'checkbox',
        selectableRows: "none",
        onDownload: (buildHead, buildBody, columns, data) => {
          return "\uFEFF" + buildHead(columns) + buildBody(data);
        },
        downloadOptions: {
          filename: "Purchase Order Items",
          separator: ",",
          filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
          },
        },
      }
  
    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>
              <li className="breadcrumb-item active">Purchase Orders</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  {/* <div className="col-12"> */}
                    {/* <div className="card"> */}
                      {/* <div className="card-header">
                        <h3 className="card-title">Purchase Orders</h3>
                      </div> */}
                      <div className="card-body py-0">
                        <div>
                          {this.state.loading === true ?<div  className="text-center">{spin}</div> : 
                          <>
                                <div className="form-row">

                                <fieldset className="mb-2 col-md-12">
                                <fieldset
                                  className={`mb-2 col-md-3 shadow-sm  text-center btn ${
                                    this.state.type === "Purchases By Order"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === "Purchases By Order"}
                                  onClick={() => this.setState({type:"Purchases By Order"})}
                                >
                                  Purchases By Order
                                </fieldset>
                                <fieldset
                                  className={`mb-2 col-md-3  btn shadow-sm text-center ${
                                    this.state.type === "Purchases By Item"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === "Purchases By Item"}
                                  onClick={() => this.setState({type:"Purchases By Item"})}
                                >
                                  Purchases By Item
                                </fieldset>
                              </fieldset>
                              
                            {this.state.type === "Purchases By Order"?<>
                          <fieldset className="mb-2 col-md-4">
                            <span className="mx-3">Active Orders</span>
                            <FormControlLabel
                              control={<IOSSwitch className="sm" checked={this.state.po} onChange={this.handlepoChange} name="checkedB" />}
                            />
                          </fieldset>
                          <fieldset className="mb-2 col-md-4">
                            <span className="mx-3">Completed Orders</span>
                            <FormControlLabel
                              control={<IOSSwitch className="sm" checked={this.state.comp} onChange={this.handlecompChange} name="checkedB" />}
                            />
                          </fieldset>
                          <fieldset className="mb-2 col-md-4">
                            <span className="mx-3">Cancelled Orders</span>
                            <FormControlLabel
                              control={<IOSSwitch className="sm" checked={this.state.canl} onChange={this.handlecanlChange} name="checkedB" />}
                            />
                          </fieldset>
                          </>:<>
                          <fieldset className="mb-2 col-md-4">
                          <span className="mx-3">Active/Pending</span>
                          <FormControlLabel
                            control={<IOSSwitch className="sm" checked={this.state.pendingbyitem} onChange={this.handlePendingChangeByitem} name="checkedB" />}
                          />
                        </fieldset>
                        <fieldset className="mb-2 col-md-4">
                          <span className="mx-3">Completed</span>
                          <FormControlLabel
                            control={<IOSSwitch className="sm" checked={this.state.compbyitem} onChange={this.handlecompChangeByitem} name="checkedB" />}
                          />
                        </fieldset>
                        </>}
                        </div>  

                        {this.state.po === true && this.state.type === "Purchases By Order"?
                         <ThemeProvider theme={this.getMuiTheme()}> 
                          <MUIDataTable
                            className="table-sm"
                            title={"Active Orders"}
                            data={this.state.podata}
                            columns={columns}
                            options={options}
                          /> 
                          </ThemeProvider> 
                        :''
                        }
                         {(this.state.comp === true ||
                              this.state.canl === true) &&
                            this.state.type === "Purchases By Order" ? (
                              <div className="form-row g-0 mb-3">
                                <fieldset>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-around"
                                    >
                                      <DatePicker
                                        variant="inline"
                                        id="date-picker-dialog"
                                        label="From Date"
                                        name="from"
                                        format="dd/MM/yyyy"
                                        maxDate={new Date()}
                                        value={this.state.fromdate}
                                        onChange={this.handleFromDateChange}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                          />
                                        )}
                                        autoOk={true}
                                      />
                                    </Grid>
                                  </LocalizationProvider>
                                </fieldset>

                                <fieldset style={{ marginLeft: "30px" }}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-around"
                                    >
                                      <DatePicker
                                        variant="inline"
                                        id="date-picker-dialog"
                                        label="To Date"
                                        name="to"
                                        format="dd/MM/yyyy"
                                        minDate={this.state.fromdate}
                                        value={this.state.todate}
                                        onChange={this.handleToDateChange}
                                        maxDate={new Date()}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                          />
                                        )}
                                        autoOk={true}
                                      />
                                    </Grid>
                                  </LocalizationProvider>
                                </fieldset>
                                <fieldset className="mb-2 col-md-1">
                                  <button
                                    className="btn btn-sm hovbuttonColour mt-3"
                                    onClick={this.handlecompletedDateChange}
                                  >
                                    <i className="fas fa-filter" />
                                  </button>
                                </fieldset>
                              </div>
                            ) : (
                              ""
                            )}



                        {this.state.comp === true && this.state.type === "Purchases By Order"?
                         <ThemeProvider theme={this.getMuiTheme()}> 
                          <MUIDataTable
                            className="table-sm"
                            title={"Completed Orders"}
                            data={this.state.completedpo}
                            columns={columns1}
                            options={options1}
                          />  
                          </ThemeProvider>
                        :''
                        }

                        {this.state.canl === true && this.state.type === "Purchases By Order"?
                         <ThemeProvider theme={this.getMuiTheme()}> 
                          <MUIDataTable
                            className="table-sm"
                            title={"Cancelled Orders"}
                            data={this.state.cancelledpo}
                            columns={columns1}
                            options={options1}
                          />
                          </ThemeProvider>
                        :''
                        }
      

                    {this.state.pendingbyitem=== true && this.state.type === "Purchases By Item"?
                         <ThemeProvider theme={this.getMuiTheme()}> 
                          <MUIDataTable
                            className="table-sm"
                            title={"Pending/Active"}
                            data={this.state.pendingpoitems}
                            columns={columnsbyitem1}
                            options={optionsbyitem1}
                          />
                          </ThemeProvider>
                        :''
                        }

                        
                    {this.state.compbyitem  === true && this.state.type === "Purchases By Item"?
                         <ThemeProvider theme={this.getMuiTheme()}> 
                          <MUIDataTable
                            className="table-sm"
                            title={"Completed"}
                            data={this.state.completedpoitems}
                            columns={columnsbyitem1}
                            options={optionsbyitem1}
                          />
                          </ThemeProvider>
                        :''
                        }
                          </>}
                        </div>
                      </div>
                    {/* </div> */}
                    {/* /.card */}
                  {/* </div> */}
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
            <Footer />
      </div>
    );
  }
}

export default withRouter(Purchase);
