import React, { Component } from 'react'
import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import Menu from '../../Component/Menu'
import SubHeadDataTable from './SubHeadDataTable'


export default class SubHeads extends Component {
    render() {
        return (
            <div>
        <Header />
                <Menu/>
                <>
              <div className="content-wrapper">
              <SubHeadDataTable/>
              </div>
              </>
              <Footer/>
            </div>
        )
    }
}
