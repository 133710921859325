import React, { Component } from 'react'
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from '../Component/Footer.js';
import Header from '../Component/Header.js';
import Menu from '../Component/Menu.js';
import NewJournalService from './NewJournalService';
import { NumericFormat } from 'react-number-format';
import { ToWords } from 'to-words';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import downloadfiles from '../Common/upload/downloadfiles.js';
import moment from 'moment';
import { Spinner } from 'react-bootstrap';
import { thousandsdisplay, userid } from '../Common/Credentials.js';
import Swal from 'sweetalert2';

//let jvtype=1
let gsttype
class JournalSaleConfirm extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
       
          confirmjv:this.props.location.state,
          loader:false,
          addldata:null
        
        }
        //this.validate=this.validate.bind(this);
        this.onSubmit=this.onSubmit.bind(this);
        this.goBack=this.goBack.bind(this);
        this.download=this.download.bind(this);

    }

  

    onSubmit(values){
      this.setState({loader:true})
      let jv
      let sgstid,sgstpercent,sgstval,sgstname
      let cgstid,cgstpercent,cgstval,cgstname
      let igstid,igstpercent,igstval,igstname
      let tcsid,tcsname,tcsvalue,tcspercentage,tcsamt
      let vatid,vatpercent,vatval,vatname
      let whid,items,qty,uom,cost,itemname

      if(this.state.confirmjv.gst!==undefined){
        sgstid=this.state.confirmjv.gst.id
        sgstpercent=this.state.confirmjv.gstpercent
        sgstval=this.state.confirmjv.taxval1
        sgstname=this.state.confirmjv.gst.name
      }
      if(this.state.confirmjv.cgst!==undefined){
        cgstid=this.state.confirmjv.cgst.id
        cgstpercent=this.state.confirmjv.cgstpercent
        cgstval=this.state.confirmjv.taxval2
        cgstname=this.state.confirmjv.cgst.name
      }
      if(this.state.confirmjv.igst!==undefined){
        igstid=this.state.confirmjv.igst.id
        igstpercent=this.state.confirmjv.cgstpercent
        igstval=this.state.confirmjv.itaxval
        igstname=this.state.confirmjv.igst.name
      }
      if(this.state.confirmjv.tcsid!==undefined){
      tcsid=this.state.confirmjv.tcsid
      tcsname=this.state.confirmjv.tcsname
      tcsvalue=this.state.confirmjv.tcsvalue
      tcspercentage=this.state.confirmjv.tcspercentage
      tcsamt=this.state.confirmjv.tcsamt
      }
      if(this.state.confirmjv.vat!==undefined){
        vatid=this.state.confirmjv.vat.id
        vatpercent=this.state.confirmjv.vatpercent
        vatval=this.state.confirmjv.vatval
        vatname=this.state.confirmjv.vatname
      }
      if(this.state.confirmjv.whid!==undefined){
        whid=this.state.confirmjv.whid.id
        items=this.state.confirmjv.items.id
        itemname=this.state.confirmjv.items.name
        qty=this.state.confirmjv.qty
        uom=this.state.confirmjv.uom
        cost=this.state.confirmjv.uprice
      }

      if(this.state.confirmjv.saletype===1){

        jv={debitid:this.state.confirmjv.debitid,debitname:this.state.confirmjv.debitname,debitamt:this.state.confirmjv.debitamt,
        creditid:this.state.confirmjv.creditid,creditname:this.state.confirmjv.creditname,creditamt:this.state.confirmjv.creditamt,
        narration:this.state.confirmjv.narration,gstin:this.state.confirmjv.gstin,pan:this.state.confirmjv.pan,
        contactnumber:this.state.confirmjv.contactnumber,email: this.state.confirmjv.email,customsoid:this.state.confirmjv.customsoid,
       createdBy:this.state.confirmjv.createdBy,poid:this.state.confirmjv.poid,supplier:this.state.confirmjv.supname,supplierid:this.state.confirmjv.supid,
       soadvance:this.state.confirmjv.soadvance,entrytype:this.state.confirmjv.entrytype,jvtype:this.state.confirmjv.jvtype,
       tenantId:this.state.confirmjv.tenantId,customer:this.state.confirmjv.customer,customerid:this.state.confirmjv.customerid,
       saletype:this.state.confirmjv.saletype,soamount:this.state.confirmjv.soamount,soid:this.state.confirmjv.soid,
       advledger:this.state.confirmjv.advledger,sdebitamt:this.state.confirmjv.sdebitamt,sdebitname:this.state.confirmjv.sdebitname,
       custbalance:this.state.confirmjv.custbalance,debcustamount:this.state.confirmjv.debcustamount,
       date:this.state.confirmjv.date,custominvid:this.state.confirmjv.custominvid,balance:this.state.confirmjv.balance,
       credth:this.state.confirmjv.credth,debdth:this.state.confirmjv.debdth,refundid:this.state.confirmjv.refundid,
       openadjusted:this.state.confirmjv.openadjusted,refund:this.state.confirmjv.refund,dcadjusted:this.state.confirmjv.dcadjusted,
       jvinsertiontype:this.state.confirmjv.jvinsertiontype,etype:this.state.confirmjv.etype,
       jvid:this.state.confirmjv.jvid,soforadjust:this.state.confirmjv.soforadjust,genadv:this.state.confirmjv.genadv,
        cjvid:this.state.confirmjv.cjvid,selectedinvcn:this.state.confirmjv.selectedinvcn,
        lossid:this.state.confirmjv.lossid,lossamt:this.state.confirmjv.lossamt,lossname:this.state.confirmjv.lossname,
        poidcrref:this.state.confirmjv.poid,ctdsid:this.state.confirmjv.ctdsid,ctdsname:this.state.confirmjv.ctdsname,
        ctdsval:this.state.confirmjv.ctdsval
      }
      }else{
      jv={
        debitid:this.state.confirmjv.debitid,
        debitamt:this.state.confirmjv.debitamt,
        debitname:this.state.confirmjv.debitname,
        sdebitid:this.state.confirmjv.sdebitid,
        sdebitamt:this.state.confirmjv.sdebitamt,
        sdebitname:this.state.confirmjv.sdebitname,
        creditid:this.state.confirmjv.creditid,
        creditamt:this.state.confirmjv.creditamt,
        creditname:this.state.confirmjv.creditname,
        narration:this.state.confirmjv.narration,
        jvtype:this.state.confirmjv.jvtype,
        createdBy:userid,
        entrytype:this.state.confirmjv.entrytype,
        sgstid:sgstid,
        sgstpercent:sgstpercent,
        sgstval:sgstval,
        sgstname:sgstname,
        cgstid:cgstid,
        cgstpercent:cgstpercent,
        cgstval:cgstval,
        cgstname:cgstname,
        igstid:igstid,
        igstpercent:igstpercent,
        igstval:igstval,
        igstname:igstname,
        vatid:vatid,
        vatpercent:vatpercent,
        vatval:vatval,
        vatname:vatname,
        whid:whid,
        items:items,
        itemname:itemname,
        qty:qty,
        uom:uom,
        cost:cost,
        rectid:this.state.confirmjv.rectid,
        gsttype:gsttype,
        selinv:this.state.confirmjv.selinv,
        customer:this.state.confirmjv.customer, 
        gstin:this.state.confirmjv.gstin, 
        pan:this.state.confirmjv.pan, 
        email:this.state.confirmjv.email, 
        saletype:this.state.confirmjv.saletype,
        contactnumber:this.state.confirmjv.contactnumber, 
        customerid:this.state.confirmjv.customerid,
        soid:this.state.confirmjv.soid,
        customsoid:this.state.confirmjv.customeinvso,
        customeinvso:this.state.confirmjv.customeinvso,
        custominvid:this.state.confirmjv.custominvid,
        mode:this.state.confirmjv.mode,
        bank:this.state.confirmjv.bank,
        bankid:this.state.confirmjv.bankid,
        receiptref:this.state.confirmjv.receiptref,
        income:this.state.confirmjv.income,
        gpdata:this.state.confirmjv.gpdata,
        ssdata:this.state.confirmjv.ssdata,
        tenantId:this.state.confirmjv.tenantId,
        balance:this.state.confirmjv.balance,
        invoicepath:this.state.confirmjv.invoicepath,invoicefile:this.state.confirmjv.invoicefile,
        invoicedate:moment(this.state.confirmjv.invoicedate),invoiceamt:this.state.confirmjv.invoiceamt,
        proinvoiceamt:this.state.confirmjv.proinvoiceamt,proinvoicedate:moment(this.state.confirmjv.proinvoicedate),
        proinvoicefile:this.state.confirmjv.proinvoicefile,proinvoicepath:this.state.confirmjv.proinvoicepath,
        soamount:this.state.confirmjv.soamount,podate:moment(this.state.confirmjv.podate),
        pofile:this.state.confirmjv.pofile,popath:this.state.confirmjv.popath,invid:this.state.confirmjv.invid,
        tcsid:tcsid,tcsname:tcsname,tcsvalue:tcsvalue,tcspercentage:tcspercentage,tcsamt:tcsamt,soadvance:this.state.confirmjv.soadvance,
        advledger:this.state.confirmjv.advledger,advstatus:this.state.confirmjv.advstatus,salevaltype:this.state.confirmjv.salesvaltype,
        custbalance:this.state.confirmjv.custbalance,debcustamount:this.state.confirmjv.debcustamount,
        insuranceamount:this.state.confirmjv.insuranceamt,insurancetax:this.state.confirmjv.insupercent,
        insurancetot:this.state.confirmjv.insurancetot,insuledger:this.state.confirmjv.insuledger,
        tcspercent:this.state.confirmjv.tcspercent,tcsamount:this.state.confirmjv.tcsamount,soforadjust:this.state.confirmjv.soforadjust,
        // invitemled:this.state.confirmjv.invitemled,invitemid:this.state.confirmjv.invitemid,invitemname:this.state.confirmjv.invitemname,
        date:this.state.confirmjv.date,addl:this.state.confirmjv.addl,credth:this.state.confirmjv.credth,debdth:this.state.confirmjv.debdth,
        refundid:this.state.confirmjv.refundid,etype:this.state.confirmjv.etype,openadjusted:this.state.confirmjv.openadjusted,
        refund:this.state.confirmjv.refund,dcadjusted:this.state.confirmjv.dcadjusted,jvinsertiontype:this.state.confirmjv.jvinsertiontype,
        jvid:this.state.confirmjv.jvid, cjvid:this.state.confirmjv.cjvid,genadv:this.state.confirmjv.genadv,selectedinvcn:this.state.confirmjv.selectedinvcn,
        lossid:this.state.confirmjv.lossid,lossamt:this.state.confirmjv.lossamt,lossname:this.state.confirmjv.lossname,
        poidcrref:this.state.confirmjv.poid,ctdsid:this.state.confirmjv.ctdsid,ctdsname:this.state.confirmjv.ctdsname,
        ctdsval:this.state.confirmjv.ctdsval
      }
    }
      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }else if(this.state.confirmjv.jvinsertiontype==="SALEADV" || this.state.confirmjv.jvinsertiontype==="SALEINV" ){
          Swal.fire({
            title: "Confirmation",
            text: "This action is irreversible, are you sure you want to confirm ? " + "It cannot be deleted or edited. Please be sure before confirming",
            icon: "warning",
            confirmButtonText: "Ok",
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonColor: "#152f5f",
          }).then((result) => {
            if (result.value === true) {
              NewJournalService.insertSalesJv(jv)
              .then(
                () =>this.goBack(values))
                .catch((e)=>{
                  if(e.response.data.error ==="Unauthorized"){
                      alert("Your Session is Lost: Please login Back to resume");
                      this.props.navigate(`/`);
                  }
              })
               } else{
              this.setState({loader:false})
              }
            }) 
          
        }else{
       // console.log(jv)
      NewJournalService.insertSalesJv(jv)
      .then(
        () =>this.goBack(values))
        .catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
      })
 
    }
    }

    goBack(values){

      if(this.state.confirmjv.frompage===`/are`){
        this.props.navigate(`/ar`)
        }else if(this.state.confirmjv.etype!=="Opening" && this.state.confirmjv.jvtype === 14) {
          this.props.navigate(`/debitcreditnotes`)
        }else{
        this.props.navigate({pathname:`/journals`},{state:{message:values + "   : Entered Successfully"}})}
    }
    componentDidMount(){
       console.log(this.state.confirmjv)
      gsttype=this.props.location.state.gsttype
      let a
      if(this.state.confirmjv.addl!==undefined){
      this.state.confirmjv.addl.map((e)=>{return a=e.name})
    }
      this.setState({addldata:a})
  //  console.log(this.state.confirmjv)
    }

    download(){
       return downloadfiles.download(this.state.confirmjv.invoicepath,this.state.confirmjv.invoicefile)
    }


    componentDidUpdate(){
   

     }

    //  goBack(){
    //   this.props.navigate(-1);
    
    // }


    render() {
       
      let jv=this.state.confirmjv,selectedval//,new1
      const toWords = new ToWords();
      let amtwords = toWords.convert(parseInt(jv.debitamt));
      if(jv.soid!==null){
          if(jv.soid!==undefined ){
        selectedval=jv.selectedval
        }
      }else if(jv.soid!==undefined && jv.selectedval!==undefined){
      jv.selectedval.map((e) => { return selectedval=e });
      }

        // console.log(selectedval)
        return (
          <div>
            <Header />
             <Menu/>
   

    <div className="content-wrapper">
    <div className="card card-info">
      {/* <div className="card-header">
        <h3 className="card-title">Confirm Entry</h3>

      </div> */}
      <ol className="breadcrumb float-sm-right">
        <li className="breadcrumb-item text-secondary">
          <Link to="/accounts">Home</Link>
        </li>
        {/* <li className="breadcrumb-item text-secondary">
        <Link to={{pathname: '/journals', state: {message:''}}} >
                    Accounts
                  </Link>
        </li> */}
        <li className="breadcrumb-item active">Confirm Entry</li>

      </ol>

      <div className="text-center text-danger">{this.state.error}</div>
      <div className="card-body">
      <div className="text-center">
      </div>

      <Formik
      initialValues={{jv}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>
      {() => (
        
        <Form>

 {jv.whid!==undefined?  
  
<div className="form-row">
<div><h4>Inventory</h4></div>
<Table className="table table-sm">  

<TableHead  style={{backgroundColor:"#e9ecef"}}>
  <TableRow>
    <TableCell>Item</TableCell>
    <TableCell className="text-right">WareHouse</TableCell>
    <TableCell className="text-right">Cost</TableCell>
    <TableCell className="text-right">Quantity</TableCell>
    <TableCell className="text-right">UOM</TableCell>

  </TableRow>
</TableHead>
<TableBody className="text-sm">
  <TableRow>
    <TableCell>{jv.items.name}</TableCell>
    <TableCell className="text-right">{jv.whid.address}</TableCell>
    <TableCell className="text-right">{jv.uprice}</TableCell>
    <TableCell className="text-right">{jv.qty}</TableCell>    
    <TableCell className="text-right">{jv.uom}</TableCell>
    </TableRow>
    </TableBody>
</Table>
</div> :''}  

{jv.gpdata!==undefined ?<>
 {jv.gpdata.length!==0 ?
  <div className="form-row">
<div><h5>INVOICE</h5></div>
<table className="table table-sm">  

<thead style={{backgroundColor:"#e9ecef"}}>
<tr>
<td>Item</td><td>Invoice</td><td>Sale Order</td><td>Customer</td> <td>Amount</td><td>Quantity</td><td>Tax</td>
</tr>
  </thead>
  {jv.gpdata.map((e,i)=>
  <tbody className="text-sm">
  <tr key={i}>
  <td>{e.name}</td><td>{jv.custominvid}</td><td>{jv.customeinvso}</td> <td>{jv.customer}</td> <td>{e.price}</td>
    <td>{e.quantity}</td><td>{e.tax}</td>
  </tr></tbody>)}
  </table>
  </div>:""}</>:""}

  {/* {jv.ssdata!==undefined ?<>
   {jv.ssdata.length!==0  ?
  <div className="form-row">
<div><h4>SCN</h4></div>
<table className="table table-sm">  

<thead className="bg-info text-sm">
  
<tr>
<td>Service</td><td>SCN</td><td>PO</td><td>Supplier</td> <td>Amount</td><td>Tax</td><td>Total</td><td>Completion</td>
</tr>
  </thead>
  <tbody className="text-sm">
  {jv.ssdata.map((e)=>

  <tr>
  <td>{e.scnname}</td><td>{e.scnid}</td><td>{jv.poid}</td> <td>{jv.supplier}</td> <td>{e.total}</td>
    <td>{e.tax}</td><td>{e.total*e.tax/100+e.total}</td><td>{e.percentage}%</td>
  </tr>)}</tbody>
  </table></div>:""}</>:""} */}


{this.state.confirmjv.insuranceamt!==undefined ?
  <div className="form-row">
<div><h4>Insurance</h4></div>
<Table className="table table-sm">  
<TableHead style={{backgroundColor:"#e9ecef"}}>
  <TableRow>
    <TableCell className="text-left">Amount</TableCell>
    <TableCell className="text-right">Tax Amount</TableCell>
    <TableCell className="text-right">Total Amount</TableCell>
    <TableCell className="text-right">Provider</TableCell>
  </TableRow>
</TableHead>
<TableBody className="text-sm">
  <TableRow>
    <TableCell>{this.state.confirmjv.insuranceamt}</TableCell>
    <TableCell className="text-right">{parseFloat(this.state.confirmjv.insupercent).toFixed(2)}</TableCell>
    <TableCell className="text-right">{parseFloat(this.state.confirmjv.insurancetot).toFixed(2)} </TableCell>
    <TableCell className="text-right">{this.state.confirmjv.insuledger.name} </TableCell>   
    </TableRow>
    </TableBody>
</Table>
</div>:""}


{jv.soadvance!=="" && jv.jvtype===1?
<div className="form-row">
<div><h4>Advance</h4></div>
<Table className="table table-sm">  
<TableHead style={{backgroundColor:"#e9ecef"}}>
  <TableRow>
    <TableCell>SO</TableCell>
    <TableCell className="text-right">Customer</TableCell>
    <TableCell className="text-right">Advance Amount</TableCell>
  </TableRow>
</TableHead>
<TableBody className="text-sm">
  <TableRow>
    <TableCell>{this.state.confirmjv.soid}</TableCell>
    <TableCell className="text-right">{this.state.confirmjv.creditname}</TableCell>
    <TableCell className="text-right">
    <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.confirmjv.soadvance} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>
    </TableCell>
   
    </TableRow>
    </TableBody>
</Table>
</div>:""}

{jv.addl!==undefined?
<div className="form-row">
<div><h5>Additional Charges</h5></div>
<Table className="table table-sm">  
<TableHead  style={{backgroundColor:"#e9ecef"}}>
<TableRow>
    <TableCell>Name</TableCell>
    <TableCell>Amount</TableCell>
    <TableCell>Tax Amount</TableCell>
    <TableCell>Total Amount</TableCell>
    <TableCell>Vendor</TableCell>
    <TableCell>Exp Towards</TableCell>
    </TableRow>
</TableHead>
<TableBody className="text-sm">
{jv.addl.map((addl,index)=> ( 
  <TableRow key={index} >
    <TableCell>{addl.name}</TableCell>
    <TableCell>{addl.value}</TableCell>
    <TableCell>{addl.tax}</TableCell>
    <TableCell>{addl.totamt}</TableCell>
    <TableCell>{addl.name!==null?addl.led.name:""}</TableCell>
    <TableCell>{addl.name!==null?addl.expled.name:""}</TableCell>
  </TableRow>
))}
 </TableBody>
</Table>
</div>:""}


{/* For Payment Table */}
{selectedval!==undefined?
  <div className="form-row">
<div className="form-group col-md-6 float-left text-sm mt-0 mb-0"><h6><span className="text-green">PO:</span> {jv.poid!==""?jv.poid:"Direct Purchase"}</h6></div>
<table className="table table-sm">
  {selectedval.find((e)=>e.grnid)!==undefined?<>

<thead className="bg-info text-sm">
<tr>
<td>Item</td>
    <td>Supplier</td>
    <td>Amount</td>
    <td>Quantity</td>
    <td>Tax</td>
    <td className="text-center">Total</td>
</tr>
  </thead>
  <tbody className="text-sm">
  {selectedval.map((e,i) => (
  <tr key={i}>
  <td>{e.name}</td>
    <td>{jv.debitname}</td>    
    <td>{e.unitprice}</td>
    <td>{e.quantity}</td>
    <td>{e.tax}</td>
    <td className="text-right">
    <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {(e.unitprice*e.quantity)*(e.tax)/100+(e.unitprice*e.quantity)} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>
      </td>

  </tr>))}
  </tbody>
  </>:<><thead className="bg-info text-sm">
  <tr>
  <td>Service</td><td>SCN</td><td>PO</td><td>Supplier</td> <td>Amount</td><td>Tax</td><td>Total</td><td>Completion</td>
  </tr>
    </thead>
    <tbody className="text-sm">
    {selectedval.map((e,i)=>
  
    <tr key={i}>
    <td>{e.scnname}</td><td>{e.scnid}</td><td>{jv.poid}</td> <td>{jv.debitname}</td> <td>{e.total}</td>
      <td>{e.tax}</td><td>{e.total*e.tax/100+e.total}</td><td>{e.percentage}%</td>
    </tr>)}</tbody></>}
  </table>
  </div>:""}
      <div className="form-row">
<table className="table table-sm">
<thead style={{backgroundColor:"#e9ecef"}}>
  <tr>
    <td>Ledgers</td>
    <td className="text-right"> Debit </td>
    <td className="text-right">Credit</td>
  </tr>
</thead>
<tbody>
  <tr>
    <td>{jv.debitname}</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.debitamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
    <td></td>
  </tr>
  {jv.ctdsid!==undefined?
  <tr>
    <td>{jv.ctdsname}</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.ctdsval} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
    <td></td>
  </tr>:""}
  {jv.gpdata!==undefined && jv.sdebitid!==null?
  <tr>
    <td>{jv.sdebitname}</td>
    <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.sdebitamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
  </tr>
  :""}
  {jv.taxval1!==undefined && jv.jvtype===0?
   <tr> <td>{jv.gst.name}</td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.taxval1} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
    </tr>
    :''}
{jv.itaxval!==undefined && jv.jvtype===0?
   <tr> <td>{jv.igst.name}</td>
       <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.itaxval} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    </tr>
    :''}
{jv.taxval2!==undefined && jv.jvtype===0?
   <tr> <td>{jv.cgst.name} </td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.taxval2} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
    </tr>
    :''}
{jv.vatval!==undefined && jv.jvtype===0?
   <tr> <td>{jv.vat.name} </td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.vatval} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    <td></td>
    </tr>
    :''}
  {jv.taxval1!=="" && jv.jvtype===1 && jv.saletype!==1?
   <tr> <td>{jv.gst.name}</td>
       <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.taxval1} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    </tr>
    :''}
{jv.itaxval!==undefined && jv.jvtype===1 && jv.saletype!==1?
   <tr> <td>{jv.igst.name}</td>
       <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.itaxval} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    </tr>
    :''}
{jv.taxval2!=="" && jv.jvtype===1 && jv.saletype!==1?
   <tr> <td>{jv.cgst.name} </td>
       <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.taxval2} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    </tr>
    :''}
{jv.vatval!==undefined && jv.vat!==undefined && jv.jvtype===1 && jv.saletype!==1?
   <tr> <td>{jv.vat.name}</td>
       <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.vatval} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
    </tr>
    :''}
<tr>
    <td>{jv.creditname}</td>
    <td></td>
    <td className="text-right"><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {jv.creditamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat></td>
  </tr>
{jv.invoicefile===""?"":
<tr>
    <td>
    <span className="text-blue text-bold">
      Supplier Invoice :
    </span>
    <span as="button" className="text-blue btn btn-link text-sm mt-0 mb-0" onClick={this.download}>{jv.invoicefile}</span>
  </td>
  <td></td>
  <td></td>
  </tr>}  
      <tr> <td><span className="textColour text-bold">Amount In Words :</span>{amtwords}</td>
      <td></td><td></td></tr>
  <tr>
  <td><span className="textColour text-bold">Narration :  </span>  {jv.narration}</td>
  <td></td>
  <td></td>
  </tr>
</tbody>
</table>
      </div>  
      {/* <hr/>  */}

      <div>
                  <div className="form-row">
                    <fieldset className="form-group col-md-12">
                  <button className="btn hovbuttonColour btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Confirm"}</>:"Confirm"}</button>
                  {/* </fieldset>
                  <fieldset className="form-group col-md-2">  */}
                  <button className="btn btn-sm ml-3 bg-white shadow-sm" type="button" onClick={this.goBack}> <b style={{ color: "#FA5733" }}> X</b> Close</button>
                  </fieldset>
                  
                  </div>
                  </div>
 
      </Form>  

      )}

</Formik>

      </div>
      
    </div>

  </div>




     <Footer/>     
      </div>
        )
    }
}

export default withRouter(JournalSaleConfirm);              