import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import Header from "../../../Component/Header";
import Menu from "../../../Component/Menu";
import Footer from "../../../Component/Footer";
import { Link, useNavigate } from "react-router-dom";
import TourServices from "../../Invoice/TourServices";
import { currentTenant, currentUser } from "../../../Common/Credentials";
import moment from "moment";
import withRouter from "../../../withRouter";
import Swal from "sweetalert2"

const MHotelMealsPlan = (props) => {
  const [checked, setChecked] = useState(true);
  const nevigate = useNavigate();
  const [data, setData] = useState({
    id: 0,
    mealsplan: "",
    createddate:"",
    modifieddate:"",
    tenantid:""
  
  });
 

  useEffect(() => {
    console.log(props.location.state.id);
    if(props.location.state.id !== -1)
    {
      // Swal.fire({
      //   title: "Add Meals Plan?",
      //   text: "Are you sure you want to save?",
      //   icon: "question",
      //   showCancelButton: true,
      //   confirmButtonText: "Yes",
      //   confirmButtonColor: "#152f5f",
      //   cancelButtonText: "No",
      //   reverseButtons: true,
      // }).then((result) => {
      //   if (result.value) {
      //     TourServices.getByIdMMealsPlan(props.location.state.id)
      //       .then((response) => {
      //         Swal.fire({
      //           title: "Meals plan added successfuly",
      //           text: ".",
      //           icon: "success",
      //           confirmButtonText: "Ok",
      //           confirmButtonColor: "#152f5f",
      //         }).then((r)=>{
      //           setData({
      //             id: r.data.id,
      //             mealsplan: r.data.mealsplan,
      //             createddate:r.data.createddate,
      //             modifieddate:r.data.modifieddate,
      //             tenantid:r.data.tenantid
      //           })
      //         })
      //       })
      //       .catch((e) => {
      //         if (e.response.data.error === "Unauthorized") {
      //           alert("Your Session is Lost: Please login Back to resume");
      //           this.props.navigate(`/`);
      //         }
      //       });
      //   }
      // });
      

      TourServices.getByIdMMealsPlan(props.location.state.id).then(r=>{
          setData({
            id: r.data.id,
            mealsplan: r.data.mealsplan,
            createddate:r.data.createddate,
            modifieddate:r.data.modifieddate,
            tenantid:r.data.tenantid
          })
      })
    }

  },[])

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const changeHandler = (e) => {
    setData(prevValues => {
      return { ...prevValues, [e.target.name]: e.target.value }
    })
  }

  const AddHandler = (e) => {
    e.preventDefault();
    if(props.location.state.id === -1)
    {
      const sdata = {
        id: data.id,
        mealsplan: data.mealsplan,
        createddate:new Date(),
        modifieddate:new Date(),
        tenantid:currentTenant,
       
        
      }
      Swal.fire({
        title: "Add Meals Plan?",
        text: "Are you sure you want to save?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          TourServices.addMMealsPlan(sdata)
            .then((response) => {
              Swal.fire({
                title: "Meals plan added successfuly",
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
              
            })
            .catch((e) => {
              if (e.response.data.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
            nevigate("/hotelmealsplanlist");
          }
      });


      // // TourServices.addMMealsPlan(sdata).then((r) => {
      // //   console.log(r.data);
      // // })
      // nevigate("/hotelmealsplanlist");
    }
    else
    {
      const sdata = {
        id: data.id,
        mealsplan: data.mealsplan,
        createddate:data.createddate,
        modifieddate:new Date(),
        tenantid:currentTenant,
      }
      Swal.fire({
        title: "Update Meals Plan?",
        text: "Are you sure you want to save?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          TourServices.updateMMealsPlan(sdata,props.location.state.id)
            .then((response) => {
              Swal.fire({
                title: "Meals plan Updated successfuly",
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
              
            })
            .catch((e) => {
              if (e.response.data.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
            nevigate("/hotelmealsplanlist");
          }
      });






      // TourServices.updateMMealsPlan(sdata,props.location.state.id)
      // nevigate("/hotelmealsplanlist");
    }

    
  }


  return (
    <>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
          <span
                as="button"
                className=" text-secondary cursorPointer"
                
              >
                <Link to="/hotelmealsplanlist" style={{color:"grey"}}>❮ Back &nbsp;&nbsp;</Link>
                
              </span>
            <li className="breadcrumb-item text-secondary">
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/hotelmealsplanlist">Meals Plan List</Link>
            </li>
            <li className="breadcrumb-item active">Master Meals Plans</li>
          </ol>
          <div className="">
            <form >
              <div className="card-body p-6">
                <div className="row my-1">
                  <fieldset className="col-md-5 col-sm-12">
                    <TextField
                      fullWidth
                      label="Meals Name"
                      size="small"
                      required="true"
                      id="outlined-size-small"
                      name="Meals Plan"
                      value={data.mealsplan}
                      onChange={(e)=>setData({mealsplan:e.target.value})}
                    />
                  </fieldset>
                </div>
 
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="my-2">
                      <span>Created By : Jnanasoftware</span>
                    </div>
                 
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className=" my-2">
                      <span>Modified By : {currentUser}</span>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <span>Created Time : {moment(data.createddate).format("DD-MM-YYYY")}</span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <span>Modified Time : {moment(data.modifieddate).format("DD-MM-yyyy")} </span>
                    </div>
                  </div>
                  <div className="btn d-flex justify-context-center">
                    <button className="btn buttonColour btn-sm  "  onClick={AddHandler}>Save</button>
                  </div>
                </div>
              </div>
            </form>
            
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(MHotelMealsPlan);
