import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant } from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import { Spinner } from "react-bootstrap";
import { TextField } from "@mui/material";
import IfscFetch,{bankifsc, bankres, branchres } from "../../Accounts/Ledgers/IfscFetch.jsx";
import BankServices from "./BankServices.js";

let ifsccode,branch,bankname
class Addbank extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.location.state.id,
      pagename:this.props.location.state.pagename,
      bankname: "",
      accno: "",
      accname:'',
      branchname: "",
      ifsc: "",
      message: "",
      loader: false,
      err: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.ifscClick=this.ifscClick.bind(this)
  }


  ifscClick1(){
    this.setState({bankname:''})
    this.setState({branchname:''})
    branch=""
    bankname=""
  }



  ifscClick=(ifsccode)=>{
   this.ifscClick1()
    if(ifsccode!==undefined){
      if(ifsccode!==null){
        if(ifsccode!==''){
          if(ifsccode.length===11){  
     IfscFetch.setIfsc(ifsccode)

     branch=''
    bankname=''
      this.setState({bankname:bankres,branchname:branchres})
      branch=branchres
      bankname=bankres
          }else{
            this.ifscClick1()
            ifsccode=""
            branch=''
            bankname=''

          }
        }
      }
    }
   
    
      //ifsccode=bankifsc
     //}
  }

  onSubmit(values) {
    console.log(this.state.bankname,this.state.accno,this.state.accname,this.state.ifsc,this.state.branchname)
    if (
      this.state.bankname === "" ||
      this.state.accno === "" ||
      this.state.branchname === "" ||
      this.state.ifsc === "" || 
      this.state.accname=== ""
    ) {
      this.setState({ err: true });
    } else {
      this.setState({ loader: true });
      let data = {
        id:this.state.id,
        bankname: this.state.bankname,
        accno: this.state.accno,
        accname: this.state.accname,
        branchname: this.state.branchname,
        ifsc: this.state.ifsc,
        tenantid: currentTenant,
        createdBy: userid,
      };
      if(this.state.id === -1){
      BankServices.addBank(data)
        .then(() => this.props.navigate("/bank"))
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
          this.setState({ message: e.response.data.message, loader: false });
        });
      }else{
        BankServices.updateBank(data)
          .then(() => this.props.navigate("/bank"))
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
            this.setState({ message: e.response.data.message, loader: false });
          });
      }
    }
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.ifscClick1()

      if(this.props.location.state.id>0){
        BankServices.Getbankbyid(this.props.location.state.id).then((res)=>{
          this.setState({ 
            id: res.data.id,
            bankname: res.data.bankname,
            accno: res.data.accno,
            accname:res.data.accname,
            branchname: res.data.branchname,
            ifsc: res.data.ifsc,
        })
      })
    }
  }

  render() {
    let { id, bankname, accno, accname, branchname, ifsc,err } = this.state;

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <Link
                  as="button"
                  className=" text-secondary"
                  to={{ pathname: "/bank"}} state= {{ message: "" }}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/bank">Bank</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">{this.state.pagename}</h3>
            </div>
            <div className="card-body">
              <fieldset className="form-group col-md-12" />

              <Formik
                initialValues={{ id, bankname, accno, accname, branchname, ifsc,err }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {() => (
                  <Form autoComplete="off">
                    <div className="form-row mb-3">

                    <fieldset className="form-group col-md-3 mb-4">
                        <TextField
                          className="form-control"
                          type="text"
                          name="ifsc"
                          label="Enter IFSC/SWIFT code"
                          value={this.state.ifsc}
                          onChange={(e) =>{
                            this.setState({ ifsc: e.target.value, message: "",err:false })
                            e.target.value.length === 11?this.ifscClick(e.target.value):this.ifscClick1()
                          }
                          }
                          error={err || (this.state.ifsc.length!==11 && this.state.ifsc.length !==0)}
                          helperText={err?"IFSC is Required":(this.state.ifsc.length!==11 && this.state.ifsc.length !==0)?"Invalid":''}
                          variant="standard"
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-1">
                      <button className="btn buttonColour btn-sm mt-3" type="button" onClick={()=>this.state.bankname!==''?this.ifscClick1():this.ifscClick(this.state.ifsc)}>{this.state.bankname!==''?"Clear":"Check"} </button>
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <TextField
                          className="form-control"
                          type="text"
                          name="bankname"
                          value={this.state.bankname}
                          label="Enter Bank Name"
                          onChange={(e) =>
                            this.setState({
                              bankname: e.target.value,
                              message: "",
                              err:false
                            })
                          }
                          error={err}
                          helperText={err?"Bank Name is Required":""}
                          variant="standard"
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        <TextField
                          className="form-control"
                          type="text"
                          name="branchname"
                          label="Enter Branch Name"
                          value={this.state.branchname}
                          onChange={(e) =>
                            this.setState({
                              branchname: e.target.value,
                              message: "",
                              err:false
                            })
                          }
                          error={err}
                          helperText={err?"Branch Name is Required":""}
                          variant="standard"
                        />
                      </fieldset>
                    

                    <fieldset className="form-group col-md-4">
                        <TextField
                          className="form-control"
                          type="text"
                          name="accname"
                          label="Enter Account Name"
                          value={this.state.accname}
                          onChange={(e) =>
                            this.setState({
                              accname: e.target.value,
                              message: "",
                              err:false
                            })
                          }
                          error={err}
                          helperText={err?"Account Name is Required":""}
                          variant="standard"
                        />
                      </fieldset>
                      
                      <fieldset className="form-group col-md-4">
                        <TextField
                          className="form-control"
                          type="text"
                          name="accno"
                          label="Enter Account Number"
                          value={this.state.accno}
                          onChange={(e) =>
                            this.setState({
                              accno: e.target.value,
                              message: "",
                              err:false
                            })
                          }
                          error={err}
                          helperText={err?"Account Number is Required":""}
                          variant="standard"
                        />
                      </fieldset>
                      
                      </div>

                    {/* {this.state.loader === true ? ( */}
                    <button
                      className="btn btn-sm hovbuttonColour"
                      disabled={this.state.loader === true}
                      type="submit"
                    >
                      {this.state.loader === true ? (
                        <>
                          <Spinner animation="border" size="sm" />
                          &nbsp;{"Saving"}
                        </>
                      ) : (
                        "Save"
                      )}
                    </button>
                    {/* ) : (
                      <button
                        className="btn btn-sm hovbuttonColour"
                        type="submit"
                      >
                        <i className="fas fa-save mr-2" />
                        Save
                      </button>
                    )} */}
                    <Link
                      as="button"
                      className="btn btn-sm deletebutton ml-1"
                      to={{ pathname: "/bank" }}
                    >
                      <b style={{ color: "#FA5733" }}> X</b> Cancel
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default withRouter(Addbank);
