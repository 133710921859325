import { createTheme, ThemeProvider } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import CustomToolbar from '../../../CommonComponents/CustomToolbar'
import MUIDataTable from 'mui-datatables';
import TourServices from '../../Invoice/TourServices';
import withRouter from '../../../withRouter';
import Footer from '../../../Component/Footer';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';



const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },  
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});


const MHotelList = () => {
  const [hotel, setHotel] = useState([]);

  useEffect(() => {
    getMHotelList();
  },[])

  const getMHotelList = () => {
    TourServices.getAllMHotels().then((r) => {
      console.log(r.data);
      setHotel(r.data, "Updated Hotel data");
    }).catch((r) => console.log(r.data))
  }

  const delteDataHandler =(e)=>{
    if (confirm('Are you sure you want to Delete this thing into the database?')) {
      TourServices.deleteMHotel(e).then((r)=>{
        console.log(r);
        getMHotelList();
      })
            
      console.log('Thing was Deleted to the database.');
    } else {
      
      console.log('Thing was not Deleted to the database.');
    }
    
  }
  const columns = [
    {
      name: "id",
      label: "ID",
      options: { filter: true, sort: true },
    },
    {
      name: "hotelcode",
      label: "Hotel Code",
      options: { filter: true, sort: true },
    },
    {
      name: "hotelname",
      label: "Hotel Name",
      options: { filter: true, sort: true },
    },
    {
      name: "address",
      label: "Address",
      options: { filter: true, sort: true },
    },
    {
      name: "place",
      label: "Place",
      options: { filter: true, sort: true },
    },
    {
      name: "phoneno",
      label: "Phone Number",
      options: { filter: true, sort: true },
    },
    {
      name: "fax",
      label: "Fax",
      options: { filter: true, sort: true },
    },
    {
      name: "email",
      label: "Email",
      options: { filter: true, sort: true },
    },
    {
      name: "hotelchain",
      label: "Hotel Chain",
      options: { filter: true, sort: true },
    },
    {
      name: "city",
      label: "city",
      options: { filter: true, sort: true },
    },
    {
      name: "starrating",
      label: "Star Rating",
      options: { filter: true, sort: true },
    },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Link as="button" className="btn btn-sm hovbuttonColour mr-2"
                  to={{ pathname: "/hotelMaster"}} state= {{id: tableMeta.rowData.find(e=>e) ,pagename:"Edit hotel" }}>Edit</Link>
                <br/>
              <button
                as="button"
                data-tip
                data-for="delete"
                className="btn btn-sm deletebutton"
                // to={{ state: {id: tableMeta.rowData.find(e=>e) ,pagename:"Employee" }  }}
                onClick={() =>
                  delteDataHandler(tableMeta.rowData.find(e => e))
                }
              >Delete</button>

              {/* <Link as="button" className="btn btn-sm hovbuttonColour mr-2"
                  to={{ pathname: "/editemp", state: {id: tableMeta.rowData.find(e=>e) ,pagename:"Edit hotel" }}}>Edit</Link>
                <br/>
                <button
                  as="button"
                  data-tip
                  data-for="delete"
                  className="btn btn-sm deletebutton"
                 // to={{ state: {id: tableMeta.rowData.find(e=>e) ,pagename:"Employee" }  }}
                  onClick={()=>
                delteDataHandler(tableMeta.rowData.find(e=>e))
                  }
                >Delete</button>
                <Link to={{pathname:'/singleDetail', state: {id: tableMeta.rowData.find(e=>e) ,pagename:"Hotel" }  }} className="btn btn-sm hovbuttonColour mr-2" as="button">Details</Link>
     */}
              {/* state={{ id: emp.empid }} */}
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    customToolbar: () => {
      return (
        <>
          (
          <Link
            as="button"
            className="float-right btn btn-sm m-0 p-0"
            to={{ pathname: "/hotelMaster"}} 
            state= {{id: -1,pagename:"Add hotel" }}
          >
            <CustomToolbar />
          </Link>
          )
        </>
      );
    },
  };

  return (
    <>
    <Header />
      <Menu />
      <div className="content-wrapper w-100% h-100%">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
          <span
                as="button"
                className=" text-secondary cursorPointer"
                
              >
                <Link to="/masterpage" style={{color:"grey"}}>❮ Back &nbsp;&nbsp;</Link>
                
              </span>
            <li className="breadcrumb-item text-secondary">
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/masterpage">Tours & Travellers </Link>
            </li>
            <li className="breadcrumb-item active">Master Hotel</li>
          </ol>
          <div className='card'>
            <div className='card-body'>

              <ThemeProvider theme={theme}>
                <MUIDataTable 
                  className="table-sm px-3"
                  title={"Hotels"}
                  data={hotel}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>
            </div>
          </div>
          </div>
          </div>
          <Footer/>

    </>
  )
}

export default withRouter(MHotelList);