import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../Component/consts'

class NewJournalService {

    retriveJournalTypes(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getjournaltypes/${tenantId}`)
    }

    getForms(jtype){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getforms/${jtype}`)

    }

    getAllLedgers(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getallledgers/${tenantId}`)

    }

    getLedgersView(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getledgersview/${tenantId}`)
    }

    getPartyLedgersView(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getpartyledgersview`,data)
    }

    getExpLedTrans(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getexpledtrans/${tenantId}`)
    }

    getRealLedgersAL(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getrealledtrans/${tenantId}`)
    }

    getSuppAdvances(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getadvanceledgers`,data)
    }


    getTaxLedgersLia(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/gettaxledtrans/${tenantId}`)
    }

    getNomLedgers(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getnomledgers/${tenantId}`)

    }

    getParLedgers(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getpersonalledgers/${tenantId}`)

    }

    getRealLedgers(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getrealledgers/${tenantId}`)
    }

    updateSalaryPaid(jvtot){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/updatesalarypaid`,jvtot)
    }

    insertJv(jv){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/insertentry`,jv)
    }

    canConfirm(canreq){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/salesrecti`,canreq)
    }

    purCanConfirm(canreq){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/purchaserecti`,canreq)
    }

    insertexpJv(totselval){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/insertexpcombo`,totselval)
    }

    getJvEnries(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getjventries`,data)
    }

    getCJvEnries(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getcjventries`,data)
    }
   
   getJVDetail(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getjvdetail`,data)
    }

    getCJVDetail(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getcjvdetail`,data)
     }

     deleteCJV(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/comboentrydel`,data)
     }

     deleteJV(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/jventrydel`,data)
     }

     editCJV(cjv){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/comboentryedit`,cjv)
     }

     editJV(jv){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/jventryedit`,jv)
     }
    
    insertEntryComb(dijv){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/insertentrycombo`,dijv)
    }

    insertDirectEntry(dijv){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/insertdirectentry`,dijv)
    }

    getPendingPayments(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getPendingPayments/${tenantId}`)
    }

    getAllSuppAdvances(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getallsuppadvances/${tenantId}`)
    }

    getAllCustAdvances(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getallcustadvances/${tenantId}`)
    }

    getSalaryPendings(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getsalarypendings/${tenantId}`)
    }

    getPendingReceipts(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getPendingReceipts/${tenantId}`)
    }

    getPendingRectifications(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getPendingRectifications/${tenantId}`)
    }

    getPurchasePendingRectifications(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getPurchasePendingRectifications/${tenantId}`)
    }
    
    getitems(poid){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getGRNPaymentitems/${poid}`)
    }

    insertSalesJv(jv){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/insertsalesentry`,jv)
    }

    addSalary(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/insertsalaryentry`,data)
    }

    addAdvance(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/insertsaladventry`,data)
    }

    getInvStatus(daodata){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getinvstatus`,daodata)
    }

    getPoStatus(daodata){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpotatus`,daodata)
    }

    rectificatinEntry(canreq){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/rectientry`,canreq)
    }

    getLiabilities(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getliabilitydata/${tenantId}`)
    }
    getAssets(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getassetdata/${tenantId}`)
    }
    getPendingIndTaxes(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getPendingIndirectDirectTaxes/${tenantId}`)
    }
    getMonthPendingIndirectDirectTaxes(tenantId,month){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getMonthPendingIndirectDirectTaxes/${tenantId}&${month}`)
    }
    getMonthWiseEmpSalary(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/monthwiseempsalaryforacc/${tenantId}`)
    }
    getEmployeeAdvances(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallemployeeadvancesoftenant/${tenantId}`)
    }
    

}
export default new NewJournalService();