import React, { useEffect, useState } from "react";
import withRouter from "../../withRouter";
import Footer from "../../Component/Footer";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Component/Header";
import AMenu from "../../Component/Menu";
import pdfImage from "../../images/google-docs.png";
import { FormikTextField } from "../../CommonComponents/FormField";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  FormLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import ProjectManagementServices from "../ProjectManagementServices";
import { currentTenant, currentUser, userid } from "../../Common/Credentials";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AttachmentIcon from "@mui/icons-material/Attachment";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { Modal } from "react-bootstrap";
import removeIcon from "../../images/Icons/clear.png";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const NewProjectEstimation = () => {
  const navigate = useNavigate();
  const [projectsList, setProjectsList] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [projectName, setprojectName] = useState("");
  const [sitesList, setSitesList] = useState([]);
  const [siteId, setSiteId] = useState("");
  const [siteName, setSiteName] = useState("");
  const [preparedBy, setPreparedBy] = useState(currentUser);
  const [remark, setRemark] = useState("");
  const [activityList, setActivityList] = useState([
    {
      activityName: "",
      estimatedDays: 0,
      startDate: new Date(),
      endDate: new Date(),
      budget: 0,
      remark: "",
      tenantId: currentTenant,
      createdby: userid,
      updatedby: userid,
      assignTos: [],
      milestones: [
        {
          milestoneName: "",
          estimatedDays: "",
          startDate: new Date(),
          endDate: new Date(),
          budget: 0,
          assignto: "",
          remark: "",
          tenantId: currentTenant,
          createdby: userid,
          updatedby: userid,
          attachments: [],
          drawings: [],
        },
      ],
    },
  ]);
  const [showassigntoform, setShowassigntoform] = useState(false);
  const [activityassignidx, setAtivityassignIdx] = useState("");
  const [activityassignName, setAtivityassignName] = useState("");
  const [activityassignType, setAtivityassignType] = useState("");
  const [activityassignremarks, setAtivityassignRemarks] = useState("");
  const [showattachmentsmodal, setShowattachmentsmodal] = useState(false);
  const [showdrawingssmodal, setShowdrawingsmodal] = useState(false);
  const [menuIndex, setMenuindex] = useState("");
  const [activityIndex, setActivityindex] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [drawings, setDrawings] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, mi, i) => {
    setMenuindex(mi);
    setActivityindex(i);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    ProjectManagementServices.GetProjectsId(currentTenant).then((res) =>
      setProjectsList(res.data)
    );
  }, []);

  const handleProjectChange = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      setProjectId(values.projectId);
      setprojectName(values.projectName);
      ProjectManagementServices.getSitesofProject(values.projectId).then(response=>
        setSitesList(response.data)
        )
      // setProjectManagerName(values.projectManagerName);
      // setCustomerName(values.customerName);
    } else {
      setProjectId("");
      setprojectName("");
      setSitesList([])
      setSiteId("")
      setSiteName("")
      // setCustomerName("");
      // setProjectManagerName("");
    }
  };

  const handleSiteChange = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      setSiteId(values.siteId);
      setSiteName(values.siteName);
    } else {
      setSiteId("")
      setSiteName("")
    }
  };

  const AddActivity = () => {
    activityList.push({
      activityName: "",
      estimatedDays: 0,
      startDate: new Date(),
      endDate: new Date(),
      budget: 0,
      remark: "",
      tenantId: currentTenant,
      createdby: userid,
      updatedby: userid,
      assignTos: [],
      milestones: [
        {
          milestoneName: "",
          estimatedDays: "",
          startDate: new Date(),
          endDate: new Date(),
          budget: 0,
          assignto: "",
          remark: "",
          tenantId: currentTenant,
          createdby: userid,
          updatedby: userid,
          attachments: [],
          drawings: [],
        },
      ],
    });
    setActivityList([...activityList]);
  };

  const RemoveActivity = (idx) => {
    Swal.fire({
      title: `Remove Activity?`,
      text: "Are you sure you want to remove?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if(result.value){
    setActivityList(activityList.filter((e, i) => i !== idx));
      }})
  };

  const deleteMilestone = (i) => {
    Swal.fire({
      title: `Delete Milestone?`,
      text: "Are you sure you want to delete?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if(result.value){
        let milestoness = activityList.find((val, idx) => idx === i).milestones;
        let curmilestones = milestoness.filter((f, idx) => idx !== menuIndex);
        activityList.find((val, idx) => idx === i).milestones = curmilestones;
        setActivityList([...activityList]);
        handleClose();
        setMenuindex("");
        setActivityindex("");
      }
    })
  };

  const selectFile = (event) => {
    if (showattachmentsmodal) {
      Object.entries(event.target.files).forEach(([key, value]) =>
        attachments.push({
          path: "",
          fileName: value.name,
          file: value,
          tenantId: currentTenant,
          createdby: userid,
          updatedby: userid,
        })
      );

      let curmilestone = activityList.find(
        (val, idx) => idx === activityIndex
      )?.milestones;
      curmilestone.find((e, idx) => idx === menuIndex).attachments =
        attachments;

      activityList.find((val, idx) => idx === activityIndex).milestones =
        curmilestone;
      setActivityList([...activityList]);
    } else {
      Object.entries(event.target.files).forEach(([key, value]) =>
        drawings.push({
          path: "",
          fileName: value.name,
          file: value,
          tenantId: currentTenant,
          createdby: userid,
          updatedby: userid,
        })
      );
      let curmilestone = activityList.find(
        (val, idx) => idx === activityIndex
      )?.milestones;
      curmilestone.find((e, idx) => idx === menuIndex).drawings = drawings;
      activityList.find((val, idx) => idx === activityIndex).milestones =
        curmilestone;
      setActivityList([...activityList]);
    
    }

    // this.setState({
    //   selectedFiles: event.target.files,
    //   partyid: this.state.Orders.custid,
    // });
  };

  const RemoveFile = (selectedFile) => {
    Swal.fire({
      title: `Remove ${showattachmentsmodal ? "Attachment" : "Drawing"}?`,
      text: "Are you sure you want to remove?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (showattachmentsmodal) {
          let curatt = attachments.filter((e) => e.fileName !== selectedFile);
          setAttachments(curatt);
          let curmilestone = activityList.find(
            (val, idx) => idx === activityIndex
          )?.milestones;
          curmilestone.find((e, idx) => idx === menuIndex).attachments = curatt;
          activityList.find((val, idx) => idx === activityIndex).milestones =
            curmilestone;
          setActivityList([...activityList]);
        } else {
          let curdrawings = drawings.filter((e) => e.fileName !== selectedFile);
          setDrawings(curdrawings);
          let curmilestone = activityList.find(
            (val, idx) => idx === activityIndex
          )?.milestones;
          curmilestone.find((e, idx) => idx === menuIndex).drawings =
            curdrawings;
          activityList.find((val, idx) => idx === activityIndex).milestones =
            curmilestone;
          setActivityList([...activityList]);
        }
      }
    });
  };

  const onSubmit = () => {
    let data = {
      preparedBy: preparedBy,
      projectId: projectId,
      remark: remark,
      activities: activityList,
      tenantId:currentTenant,
      createdby:userid,
      updatedby:userid
    };

    console.log(data);
    ProjectManagementServices.NewProjectEstimation(data).then(() =>
      navigate("/projectestimation")
    );
  };

  const validate = () => {};
  return (
    <div>
      <Header />
      <AMenu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
          <Link as="button" className=" text-secondary" to={-1}>
              ❮ Back &nbsp;&nbsp;
            </Link>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projectmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projectestimation">Project Estimation</Link>
            </li>
            <li className="breadcrumb-item active">New Project Estimation</li>
          </ol>
          <section className="content">
            <div className="container-fluid p-3">
              <div className="row">
                <div className="col-12">
                  <Formik
                    initialValues={{}}
                    onSubmit={onSubmit}
                    validateOnChange={false}
                    validate={validate}
                    enableReinitialize={true}
                  >
                    {({ setFieldValue, values }) => (
                      <Form>
                        <div className="form-row">
                          <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="projectName"
                              options={projectsList}
                              getOptionLabel={(option) => option.projectName}
                              value={values.projectName ? undefined : undefined}
                              onChange={handleProjectChange}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="projectName"
                                  value={projectName}
                                  label={`${
                                    projectName === "" ||
                                    projectName === undefined ||
                                    projectName === null
                                      ? "Project"
                                      : projectName
                                  }`}
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="siteName"
                              options={sitesList}
                              getOptionLabel={(option) => option.siteName}
                              value={values.siteName ? undefined : undefined}
                              onChange={handleSiteChange}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="siteName"
                                  value={siteName}
                                  label={`${
                                    siteName === "" ||
                                    siteName === undefined ||
                                    siteName === null
                                      ? "Site"
                                      : siteName
                                  }`}
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>

                          {/* <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="customerName"
                              label="Customer"
                              value={customerName}
                              disabled
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="projectManagerName"
                              label="Project Manager"
                              value={projectManagerName}
                              disabled
                            />
                          </fieldset> */}

                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="preparedBy"
                              label="Prepared By"
                              value={preparedBy}
                              onChange={(e) => setPreparedBy(e.target.value)}
                            />
                          </fieldset>

                          {/* {projectId !== null && projectId !== ""? */}
                          <div className="col-md-12 px-0">
                            {activityList.map((activity, i) => (
                              <fieldset className="form-group col-md-12">
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ backgroundColor: "#e8ecef" }}
                                  >
                                    <Typography>
                                      {activity?.activityName === "" ||
                                      activity?.activityName === null ||
                                      activity?.activityName === undefined
                                        ? `Activity ${i + 1}`
                                        : activity?.activityName}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <div className="row">
                                      <fieldset className="form-group col-md-4">
                                        <FormikTextField
                                          className="form-control"
                                          type="text"
                                          name="activityName"
                                          label="Activity Name"
                                          value={activity.activityName}
                                          onChange={(e) => {
                                            activityList.find(
                                              (val, idx) => idx === i
                                            ).activityName = e.target.value;
                                            setActivityList([...activityList]);
                                          }}
                                        />
                                      </fieldset>
                                      <fieldset className="form-group col-md-2">
                                        <FormikTextField
                                          className="form-control"
                                          type="text"
                                          name="activitybudget"
                                          label="Budget"
                                          disabled
                                          value={activity.budget}
                                        />
                                      </fieldset>
                                      <fieldset className="form-group col-md-2">
                                        <FormikTextField
                                          className="form-control"
                                          type="text"
                                          name="activityestimateddays"
                                          label="Estimated Days"
                                          disabled
                                          value={activity.estimatedDays}
                                        />
                                      </fieldset>
                                      <fieldset className=" col-md-2">
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <Grid
                                            container
                                            justifyContent="space-around"
                                          >
                                            <DatePicker
                                              variant="inline"
                                              margin="none ml-2"
                                              id="date-picker-dialog"
                                              label="Start Date"
                                              format="dd/MM/yyyy"
                                              value={activity.startDate}
                                              disabled
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  variant="standard"
                                                  disabled
                                                />
                                              )}
                                            />
                                          </Grid>
                                        </LocalizationProvider>
                                      </fieldset>

                                      <fieldset className=" col-md-2">
                                        <LocalizationProvider
                                          dateAdapter={AdapterDayjs}
                                        >
                                          <Grid
                                            container
                                            justifyContent="space-around"
                                          >
                                            <DatePicker
                                              variant="inline"
                                              margin="none ml-2"
                                              id="date-picker-dialog"
                                              label="End Date"
                                              format="dd/MM/yyyy"
                                              value={activity.endDate}
                                              disabled
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  variant="standard"
                                                  disabled
                                                />
                                              )}
                                            />
                                          </Grid>
                                        </LocalizationProvider>
                                      </fieldset>
                                    </div>
                                    <FormLabel className="mr-2 my-2">
                                      Activity Assigned To:
                                    </FormLabel>
                                    {showassigntoform ? (
                                      ""
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-sm hovbuttonColour"
                                        onClick={() =>
                                          setShowassigntoform(true)
                                        }
                                      >
                                        Assign?
                                      </button>
                                    )}
                                    {activity?.assignTos?.map(
                                      (assignto, ati) => (
                                        <div className="row" key={ati}>
                                          <fieldset className="form-group col-md-12">
                                            <span>
                                              {ati + 1}. {assignto.assignName} -{" "}
                                              {assignto.assignType}
                                            </span>
                                            <i
                                              className="fa fa-edit ml-2 text-primary cursorPointer"
                                              type="button"
                                              onClick={() => {
                                                let assigntos =
                                                  activityList.find(
                                                    (val, idx) => idx === i
                                                  ).assignTos;
                                                let curassignto =
                                                  assigntos.find(
                                                    (f, idx) => ati === idx
                                                  );
                                                setAtivityassignName(
                                                  curassignto.assignName
                                                );
                                                setAtivityassignType(
                                                  curassignto.assignType
                                                );
                                                setAtivityassignRemarks(
                                                  curassignto.remark
                                                );
                                                setAtivityassignIdx(ati);
                                              }}
                                            >
                                              {" "}
                                            </i>
                                            <i
                                              className="fa fa-trash ml-2 text-red cursorPointer"
                                              type="button"
                                              onClick={() => {
                                                let assigntos =
                                                  activityList.find(
                                                    (val, idx) => idx === i
                                                  ).assignTos;
                                                let curassignto =
                                                  assigntos.filter(
                                                    (f, idx) => ati !== idx
                                                  );
                                                activityList.find(
                                                  (val, idx) => idx === i
                                                ).assignTos = curassignto;
                                                setActivityList([
                                                  ...activityList,
                                                ]);
                                              }}
                                            >
                                              {" "}
                                            </i>
                                          </fieldset>
                                        </div>
                                      )
                                    )}

                                    {/* {activity?.assignTos?.map((assignto,ati)=>
                                  <div className="row" key={ati}>
                                <fieldset className="form-group col-md-6">
                                  <FormikTextField
                                    className="form-control"
                                    type="text"
                                    name="assignName"
                                    label="Assign To"
                                    value={assignto.assignName}
                                    onChange={(e)=>{
                                      assignto.assignName = e.target.value
                                      let curassignto = activityList.find((val,idx)=>idx === i)?.assignTos
                                     activityList.find((val,idx)=>idx === i).assignTos = curassignto
                                      setActivityList([...activityList])
                                     }}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-6">
                                  <FormikTextField
                                    className="form-control"
                                    type="text"
                                    name="assignType"
                                    label="Assign Type"
                                    value={assignto.assignType}
                                    onChange={(e)=>{
                                      assignto.assignType = e.target.value
                                      let curassignto = activityList.find((val,idx)=>idx === i)?.assignTos
                                     activityList.find((val,idx)=>idx === i).assignTos = curassignto
                                      setActivityList([...activityList])
                                     }}
                                  />
                                </fieldset>
                                  
                                <fieldset className="form-group col-md-11">
                            <FormikTextField
                              name="description"
                              className="form-control"
                              multiline
                              rows={1}
                              label="Remarks"
                              variant="outlined"
                              value={assignto.remark}
                              
                              onChange={(e)=>{
                                assignto.remark = e.target.value
                                let curassignto = activityList.find((val,idx)=>idx === i)?.assignTos
                               activityList.find((val,idx)=>idx === i).assignTos = curassignto
                                setActivityList([...activityList])
                               }}
                            />
                          </fieldset>
                          <button type="button" onClick={()=> {
                                  let assigntos = activityList.find((val,idx)=>idx === i).assignTos
                                  let curassignto = assigntos.filter((f,idx)=>ati!==idx)
                                  activityList.find((val,idx)=>idx === i).assignTos = curassignto
                                   setActivityList([...activityList])
                                }}> - </button>
                                </div>)} */}
                                    {showassigntoform ? (
                                      <>
                                        <div className="row">
                                          <fieldset className="form-group col-md-6">
                                            <FormikTextField
                                              className="form-control"
                                              type="text"
                                              name="assignName"
                                              label="Assign To"
                                              value={activityassignName}
                                              onChange={(e) =>
                                                setAtivityassignName(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-6">
                                            <FormikTextField
                                              className="form-control"
                                              type="text"
                                              name="assignType"
                                              label="Assign Type"
                                              value={activityassignType}
                                              onChange={(e) =>
                                                setAtivityassignType(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </fieldset>

                                          <fieldset className="form-group col-md-12 mb-5">
                                            <FormikTextField
                                              name="description"
                                              className="form-control"
                                              multiline
                                              rows={2}
                                              label="Remarks"
                                              variant="outlined"
                                              value={activityassignremarks}
                                              onChange={(e) =>
                                                setAtivityassignRemarks(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </fieldset>
                                        </div>

                                        <button
                                          type="button"
                                          className="btn btn-sm hovbuttonColour mr-1"
                                          onClick={() => {
                                            if (
                                              activityassignidx === null ||
                                              activityassignidx === ""
                                            ) {
                                              let assigntos = activityList.find(
                                                (val, idx) => idx === i
                                              ).assignTos;
                                              let curassignto = [
                                                ...assigntos,
                                                {
                                                  id: null,
                                                  assignName:
                                                    activityassignName,
                                                  assignType:
                                                    activityassignType,
                                                  remark: activityassignremarks,
                                                },
                                              ];
                                              activityList.find(
                                                (val, idx) => idx === i
                                              ).assignTos = curassignto;
                                              setActivityList([
                                                ...activityList,
                                              ]);
                                            } else {
                                              let curassignto =
                                                activityList.find(
                                                  (val, idx) => idx === i
                                                )?.assignTos;
                                              curassignto.find(
                                                (val, i) =>
                                                  i === activityassignidx
                                              ).assignName = activityassignName;
                                              curassignto.find(
                                                (val, i) =>
                                                  i === activityassignidx
                                              ).assignType = activityassignType;
                                              curassignto.find(
                                                (val, i) =>
                                                  i === activityassignidx
                                              ).remark = activityassignremarks;
                                              activityList.find(
                                                (val, idx) => idx === i
                                              ).assignTos = curassignto;
                                              setActivityList([
                                                ...activityList,
                                              ]);
                                            }
                                            setAtivityassignIdx("");
                                            setAtivityassignName("");
                                            setAtivityassignType("");
                                            setAtivityassignRemarks("");
                                          }}
                                        >
                                          {" "}
                                          <i className="fa fa-plus" /> Add{" "}
                                        </button>
                                        <button
                                          type="button"
                                          className="btn btn-sm deletebutton"
                                          onClick={() =>
                                            setShowassigntoform(false)
                                          }
                                        >
                                          <i className="fa fa-times text-red mr-1"></i>
                                          Hide
                                        </button>
                                      </>
                                    ) : (
                                      ""
                                    )}

                                    {activity.milestones.map(
                                      (milestone, mi) => (
                                        <div className="row" key={mi}>
                                          <fieldset className=" col-md-4">
                                            <FormikTextField
                                              className="form-control"
                                              type="text"
                                              name="milestonename"
                                              label="Milestone Name"
                                              value={milestone.milestoneName}
                                              onChange={(e) => {
                                                milestone.milestoneName =
                                                  e.target.value;
                                                let curmilestone =
                                                  activityList.find(
                                                    (val, idx) => idx === i
                                                  )?.milestones;
                                                activityList.find(
                                                  (val, idx) => idx === i
                                                ).milestones = curmilestone;
                                                setActivityList([
                                                  ...activityList,
                                                ]);
                                              }}
                                            />
                                          </fieldset>
                                          <fieldset className=" col-md-2">
                                            <FormikTextField
                                              className="form-control"
                                              type="text"
                                              name="milestonebudget"
                                              label="Budget"
                                              value={milestone.budget}
                                              onChange={(e) => {
                                                milestone.budget =
                                                  e.target.value;
                                                let curmilestone =
                                                  activityList.find(
                                                    (val, idx) => idx === i
                                                  )?.milestones;
                                                activityList.find(
                                                  (val, idx) => idx === i
                                                ).milestones = curmilestone;

                                                let activitybudget =
                                                  activityList
                                                    .find(
                                                      (val, idx) => idx === i
                                                    )
                                                    .milestones.reduce(
                                                      (a, b) =>
                                                        (a =
                                                          a +
                                                          parseFloat(
                                                            b.budget === ""
                                                              ? 0
                                                              : b.budget
                                                          )),
                                                      0
                                                    );

                                                activityList.find(
                                                  (val, idx) => idx === i
                                                ).budget = activitybudget;

                                                setActivityList([
                                                  ...activityList,
                                                ]);
                                              }}
                                            />
                                          </fieldset>

                                          <fieldset className=" col-md-2">
                                            <FormikTextField
                                              className="form-control"
                                              type="text"
                                              name="milestoneestimateddays"
                                              label="Estimated Days"
                                              value={milestone.estimatedDays}
                                              onChange={(e) => {
                                                milestone.estimatedDays =
                                                  e.target.value;
                                                milestone.endDate = moment(
                                                  milestone.startDate
                                                ).add(
                                                  parseInt(e.target.value),
                                                  "d"
                                                );
                                                let curmilestone =
                                                  activityList.find(
                                                    (val, idx) => idx === i
                                                  )?.milestones;
                                                activityList.find(
                                                  (val, idx) => idx === i
                                                ).milestones = curmilestone;

                                                let activityestimatedDays =
                                                  activityList
                                                    .find(
                                                      (val, idx) => idx === i
                                                    )
                                                    .milestones.reduce(
                                                      (a, b) =>
                                                        (a =
                                                          a +
                                                          parseFloat(
                                                            b.estimatedDays ===
                                                              ""
                                                              ? 0
                                                              : b.estimatedDays
                                                          )),
                                                      0
                                                    );

                                                activityList.find(
                                                  (val, idx) => idx === i
                                                ).estimatedDays =
                                                  activityestimatedDays;

                                                let mindateslist = activityList
                                                  .find((val, idx) => idx === i)
                                                  .milestones.map((val) =>
                                                    moment(
                                                      new Date(val.startDate)
                                                    )
                                                  );

                                                let mindate =
                                                  moment.min(mindateslist);
                                                activityList.find(
                                                  (val, idx) => idx === i
                                                ).startDate = new Date(mindate);

                                                let maxdateslist = activityList
                                                  .find((val, idx) => idx === i)
                                                  .milestones.map((val) =>
                                                    moment(
                                                      new Date(val.endDate)
                                                    )
                                                  );

                                                let maxdate =
                                                  moment.max(maxdateslist);
                                                activityList.find(
                                                  (val, idx) => idx === i
                                                ).endDate = new Date(maxdate);

                                                setActivityList([
                                                  ...activityList,
                                                ]);
                                              }}
                                            />
                                          </fieldset>
                                          <div className=" col-md-4 row">
                                            <fieldset className=" col-md-5">
                                              <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                              >
                                                <Grid
                                                  container
                                                  justifyContent="space-around"
                                                >
                                                  <DatePicker
                                                    variant="inline"
                                                    margin="none ml-2"
                                                    id="date-picker-dialog"
                                                    label="Start Date"
                                                    format="dd/MM/yyyy"
                                                    minDate={new Date()}
                                                    value={milestone.startDate}
                                                    onChange={(date) => {
                                                      milestone.startDate =
                                                        date;
                                                      if (
                                                        moment(
                                                          new Date(
                                                            milestone.startDate
                                                          )
                                                        ).isAfter(
                                                          new Date(
                                                            milestone.endDate
                                                          )
                                                        )
                                                      ) {
                                                        milestone.endDate =
                                                          date;
                                                      }

                                                      milestone.estimatedDays =
                                                        moment(
                                                          new Date(
                                                            milestone.endDate
                                                          )
                                                        ).diff(
                                                          moment(
                                                            new Date(date)
                                                          ),
                                                          "days"
                                                        );
                                                      let curmilestone =
                                                        activityList.find(
                                                          (val, idx) =>
                                                            idx === i
                                                        )?.milestones;
                                                      activityList.find(
                                                        (val, idx) => idx === i
                                                      ).milestones =
                                                        curmilestone;

                                                      let mindateslist =
                                                        activityList
                                                          .find(
                                                            (val, idx) =>
                                                              idx === i
                                                          )
                                                          .milestones.map(
                                                            (val) =>
                                                              moment(
                                                                new Date(
                                                                  val.startDate
                                                                )
                                                              )
                                                          );

                                                      let mindate =
                                                        moment.min(
                                                          mindateslist
                                                        );
                                                      activityList.find(
                                                        (val, idx) => idx === i
                                                      ).startDate = new Date(
                                                        mindate
                                                      );

                                                      let maxdateslist =
                                                        activityList
                                                          .find(
                                                            (val, idx) =>
                                                              idx === i
                                                          )
                                                          .milestones.map(
                                                            (val) =>
                                                              moment(
                                                                new Date(
                                                                  val.endDate
                                                                )
                                                              )
                                                          );

                                                      let maxdate =
                                                        moment.max(
                                                          maxdateslist
                                                        );
                                                      activityList.find(
                                                        (val, idx) => idx === i
                                                      ).endDate = new Date(
                                                        maxdate
                                                      );

                                                      let activityestimatedDays =
                                                        activityList
                                                          .find(
                                                            (val, idx) =>
                                                              idx === i
                                                          )
                                                          .milestones.reduce(
                                                            (a, b) =>
                                                              (a =
                                                                a +
                                                                parseFloat(
                                                                  b.estimatedDays ===
                                                                    ""
                                                                    ? 0
                                                                    : b.estimatedDays
                                                                )),
                                                            0
                                                          );

                                                      activityList.find(
                                                        (val, idx) => idx === i
                                                      ).estimatedDays =
                                                        activityestimatedDays;

                                                      setActivityList([
                                                        ...activityList,
                                                      ]);
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        variant="standard"
                                                      />
                                                    )}
                                                  />
                                                </Grid>
                                              </LocalizationProvider>
                                            </fieldset>

                                            <fieldset className=" col-md-5">
                                              <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                              >
                                                <Grid
                                                  container
                                                  justifyContent="space-around"
                                                >
                                                  <DatePicker
                                                    variant="inline"
                                                    margin="none ml-2"
                                                    id="date-picker-dialog"
                                                    label="End Date"
                                                    format="dd/MM/yyyy"
                                                    minDate={
                                                      milestone.startDate
                                                    }
                                                    value={milestone.endDate}
                                                    onChange={(date) => {
                                                      milestone.endDate = date;
                                                      milestone.estimatedDays =
                                                        moment(
                                                          new Date(date)
                                                        ).diff(
                                                          moment(
                                                            new Date(
                                                              milestone.startDate
                                                            )
                                                          ),
                                                          "days"
                                                        );
                                                      let curmilestone =
                                                        activityList.find(
                                                          (val, idx) =>
                                                            idx === i
                                                        )?.milestones;
                                                      activityList.find(
                                                        (val, idx) => idx === i
                                                      ).milestones =
                                                        curmilestone;

                                                      let mindateslist =
                                                        activityList
                                                          .find(
                                                            (val, idx) =>
                                                              idx === i
                                                          )
                                                          .milestones.map(
                                                            (val) =>
                                                              moment(
                                                                new Date(
                                                                  val.startDate
                                                                )
                                                              )
                                                          );

                                                      let mindate =
                                                        moment.min(
                                                          mindateslist
                                                        );
                                                      activityList.find(
                                                        (val, idx) => idx === i
                                                      ).startDate = new Date(
                                                        mindate
                                                      );

                                                      let maxdateslist =
                                                        activityList
                                                          .find(
                                                            (val, idx) =>
                                                              idx === i
                                                          )
                                                          .milestones.map(
                                                            (val) =>
                                                              moment(
                                                                new Date(
                                                                  val.endDate
                                                                )
                                                              )
                                                          );

                                                      let maxdate =
                                                        moment.max(
                                                          maxdateslist
                                                        );
                                                      activityList.find(
                                                        (val, idx) => idx === i
                                                      ).endDate = new Date(
                                                        maxdate
                                                      );

                                                      let activityestimatedDays =
                                                        activityList
                                                          .find(
                                                            (val, idx) =>
                                                              idx === i
                                                          )
                                                          .milestones.reduce(
                                                            (a, b) =>
                                                              (a =
                                                                a +
                                                                parseFloat(
                                                                  b.estimatedDays ===
                                                                    ""
                                                                    ? 0
                                                                    : b.estimatedDays
                                                                )),
                                                            0
                                                          );
                                                      activityList.find(
                                                        (val, idx) => idx === i
                                                      ).estimatedDays =
                                                        activityestimatedDays;

                                                      setActivityList([
                                                        ...activityList,
                                                      ]);
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        variant="standard"
                                                      />
                                                    )}
                                                  />
                                                </Grid>
                                              </LocalizationProvider>
                                            </fieldset>

                                            <fieldset className=" col-md-2">
                                              <IconButton
                                                aria-label="more"
                                                id="long-button"
                                                aria-controls={
                                                  open ? "long-menu" : undefined
                                                }
                                                aria-expanded={
                                                  open ? "true" : undefined
                                                }
                                                aria-haspopup="true"
                                                onClick={(e) =>
                                                  handleClick(e, mi, i)
                                                }
                                              >
                                                <MoreVertIcon />
                                              </IconButton>
                                              <Menu
                                                id="long-menu"
                                                MenuListProps={{
                                                  "aria-labelledby":
                                                    "long-button",
                                                }}
                                                anchorEl={anchorEl}
                                                open={open}
                                                onClose={handleClose}
                                                PaperProps={{
                                                  style: {
                                                    // maxHeight: ITEM_HEIGHT * 4.5,
                                                    // width: '20ch',
                                                  },
                                                }}
                                              >
                                                <MenuItem
                                                  onClick={() => {
                                                    setShowattachmentsmodal(
                                                      true
                                                    );
                                                    setAttachments(
                                                      activityList
                                                        .find(
                                                          (e, idx) =>
                                                            idx ===
                                                            activityIndex
                                                        )
                                                        .milestones.find(
                                                          (e, idx) =>
                                                            idx === menuIndex
                                                        ).attachments
                                                    );
                                                    handleClose();
                                                  }}
                                                >
                                                  <AttachmentIcon />{" "}
                                                  Attachments
                                                </MenuItem>
                                                <MenuItem
                                                  onClick={() => {
                                                    setShowdrawingsmodal(true);
                                                    setDrawings(
                                                      activityList
                                                        .find(
                                                          (e, idx) =>
                                                            idx ===
                                                            activityIndex
                                                        )
                                                        .milestones.find(
                                                          (e, idx) =>
                                                            idx === menuIndex
                                                        ).drawings
                                                    );
                                                    handleClose();
                                                  }}
                                                >
                                                  <AddPhotoAlternateIcon />{" "}
                                                  Drawings
                                                </MenuItem>

                                                <MenuItem
                                                  onClick={() => {
                                                    deleteMilestone(i);
                                                  }}
                                                >
                                                  <DeleteIcon style={{ color: 'red' }}  /> Delete
                                                </MenuItem>
                                              </Menu>
                                            </fieldset>
                                          </div>
                                          <fieldset className="form-group col-md-12 mb-5">
                                            <FormikTextField
                                              name="milestoneremark"
                                              className="form-control"
                                              multiline
                                              rows={2}
                                              label="Remarks"
                                              variant="outlined"
                                              value={milestone.remark}
                                              onChange={(e) => {
                                                milestone.remark =
                                                  e.target.value;
                                                let curmilestone =
                                                  activityList.find(
                                                    (val, idx) => idx === i
                                                  )?.milestones;
                                                activityList.find(
                                                  (val, idx) => idx === i
                                                ).milestones = curmilestone;
                                                setActivityList([
                                                  ...activityList,
                                                ]);
                                              }}
                                            />
                                          </fieldset>
                                        </div>
                                      )
                                    )}

                                    <div className="row col-md-12 mt-2">
                                      <button
                                        type="button"
                                        className="btn btn-sm hovbuttonColour mr-1"
                                        onClick={() => {
                                          let milestones = activityList.find(
                                            (val, idx) => idx === i
                                          ).milestones;
                                          milestones.push({
                                            milestoneName: "",
                                            estimatedDays: "",
                                            startDate: new Date(),
                                            endDate: new Date(),
                                            budget: 0,
                                            assignto: "",
                                            remark: "",
                                            tenantId: currentTenant,
                                            createdby: userid,
                                            updatedby: userid,
                                            attachments:[],
                                            drawings:[]
                                          });
                                          activityList.find(
                                            (val, idx) => idx === i
                                          ).milestones = milestones;
                                          setActivityList([...activityList]);
                                        }}
                                      >
                                        {" "}
                                        <i className="fa fa-plus" /> Add
                                        Milestone{" "}
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-sm deletebutton"
                                        onClick={() => RemoveActivity(i)}
                                      >
                                        <i className="fa fa-trash text-red "></i>{" "}
                                        Remove Activity
                                      </button>
                                    </div>
                                  </AccordionDetails>
                                </Accordion>
                              </fieldset>
                            ))}
                          </div>
                          {/* :""} */}

                          <Modal
                            show={showattachmentsmodal || showdrawingssmodal}
                            onHide={() => {
                              setShowattachmentsmodal(false);
                              setShowdrawingsmodal(false);
                            }}
                            backdrop="static"
                            keyboard={false}
                            centered
                            size="lg"
                          >
                            <Modal.Header>
                              <b>
                                {showattachmentsmodal
                                  ? "Attachments"
                                  : "Drawings"}
                              </b>
                              <i className="fa fa-times cursorPointer text-lg" onClick={()=>{
                                  setShowattachmentsmodal(false); setShowdrawingsmodal(false);
                              }}></i>
                            </Modal.Header>
                            
                            <Modal.Body>
                              <div className="form-row">
                                <fieldset className=" col-md-12">
                                  <ul className="d-flex flex-wrap pl-0">
                                    {" "}
                                    {showattachmentsmodal
                                      ? attachments.map((e) => (
                                          <li className="m-3 d-flex flex-column justify-content-center align-items-center position-relative">
                                            <img
                                              src={removeIcon}
                                              width="20"
                                              height="20"
                                              className="position-absolute cursorPointer"
                                              style={{ right: 0, top: 0 }}
                                              onClick={() =>
                                                RemoveFile(e.fileName)
                                              }
                                            />
                                            <img
                                              src={
                                                e.file.type.includes("image")
                                                  ? URL.createObjectURL(e.file)
                                                  : pdfImage
                                              }
                                              width="150"
                                              height="150"
                                              className="object-fit-contain"
                                            />
                                            <div
                                              className="mt-2 overflow-hidden text-sm"
                                              style={{ width: "150px" }}
                                            >
                                              {e.fileName}
                                            </div>
                                          </li>
                                        ))
                                      : drawings.map((e) => (
                                        <li className="m-3 d-flex flex-column justify-content-center align-items-center position-relative">
                                            <img
                                              src={removeIcon}
                                              width="20"
                                              height="20"
                                              className="position-absolute cursorPointer"
                                              style={{ right: 0, top: 0 }}
                                              onClick={() =>
                                                RemoveFile(e.fileName)
                                              }
                                            />
                                            <img
                                              src={
                                                e.file.type.includes("image")
                                                  ? URL.createObjectURL(e.file)
                                                  : pdfImage
                                              }
                                              width="150"
                                              height="150"
                                              className="object-fit-contain"
                                            />
                                            <div
                                              className="mt-2 overflow-hidden text-sm"
                                              style={{ width: "150px" }}
                                            >
                                              {e.fileName}
                                            </div>
                                          </li>
                                        ))}
                                  </ul>
                                </fieldset>
                                <fieldset className="form-group col-md-4 m-2">
                                  <label htmlFor="btn-upload">
                                    <input
                                      className="btn-choose"
                                      id="btn-upload"
                                      name="btn-upload"
                                      style={{ display: "none" }}
                                      type="file"
                                      multiple
                                      onChange={selectFile}
                                    />
                                    <Button
                                      className="btn-close"
                                      variant="outlined"
                                      type="upload"
                                      component="span"
                                    >
                                      <span className="text-blue">
                                        Select File to Upload
                                      </span>
                                    </Button>
                                  </label>
                                </fieldset>
                              </div>
                            </Modal.Body>
                          </Modal>

                          <div className="form-group col-md-12">
                            <button
                              className="btn btn-sm hovbuttonColour mr-1 mt-2"
                              type="button"
                              onClick={AddActivity}
                            >
                              <i className="fas fa-plus mr-2" />
                              Add Activity
                            </button>
                          </div>

                          <fieldset className="form-group col-md-12 mb-5">
                            <FormikTextField
                              name="remark"
                              className="form-control"
                              multiline
                              label="Remarks"
                              rows={2}
                              variant="outlined"
                              value={remark}
                              onChange={(e) => {
                                setRemark(e.target.value);
                              }}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-12">
                            <button
                              className="btn btn-sm hovbuttonColour mr-1 mt-2"
                              type="submit"
                            >
                              <i className="fas fa-save mr-2" />
                              Save
                            </button>
                            <Link
                              as="button"
                              className="btn btn-sm deletebutton mt-2"
                              to={{ pathname: "/projectestimation" }}
                              state={{ message: "" }}
                            >
                              <b className="text-danger"> X </b>Close
                            </Link>
                          </fieldset>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(NewProjectEstimation);
