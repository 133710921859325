import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  clientname,
  currentTenant,
  dataaccess,
  financialyearend,
  financialyearstart,
  logo,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
// import moment from "moment";
import SalesServices from "../SalesServices";
import { spin } from "../../Common/NewLoader";
import { salesid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";
import { FormControlLabel, Grid, IconButton, TextField, Tooltip } from "@mui/material";
import IOSSwitch from "../../CommonComponents/IOSSwitch";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import UsercustomerService from "../../Masters/Usercustomers/UsercustomerService";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class QutationTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saleOrder: [],
      usercustomers: [],
      loading: false,
      salestype: false,
      service: [],
      completedso: [],
      cancelledso: [],
      so: true,
      comp: false,
      canl: false,
      fromdate: moment(new Date()).subtract(7, "days"),
      todate: new Date(),
    };
  }

  handlesoChange = () => {
    this.setState({ so: true, comp: false, canl: false });
  };
  handlecompChange = () => {
    this.setState({ comp: true, so: false, canl: false });
  };
  handlecanlChange = () => {
    this.setState({ canl: true, so: false, comp: false });
  };

  refresh=()=>{
    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
      userid: userid,
      dataaccess: dataaccess,
    };

    SalesServices.GetActiveQuotations(datadao)
      .then((response) => {
        this.setState({
          saleOrder: response.data
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            .filter((e) =>
              this.state.usercustomers.length === 0
                ? e
                : this.state.usercustomers.includes(e.customer)
            ),
        });
        this.setState({ loading: false });
      })
      .catch((e) => {
        this.setState({ loading: false });
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    }

    refreshCompandcancelled=(from,to)=>{

      let datadao = {
        tenantid: currentTenant,
        startdate: from,
        enddate: to,
        userid: userid,
        dataaccess: dataaccess,
      };

    SalesServices.GetCompletedQuotations(datadao).then((response) => {
      this.setState({
        completedso: response.data
          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          .filter((e) =>
            this.state.usercustomers.length === 0
              ? e
              : this.state.usercustomers.includes(e.customer)
          ),
      });
    });

    SalesServices.GetClosedQuotations(datadao).then((response) => {
      this.setState({
        cancelledso: response.data
          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          .filter((e) =>
            this.state.usercustomers.length === 0
              ? e
              : this.state.usercustomers.includes(e.customer)
          ),
      });
    });
  }


  handleFromDateChange = (date) => {
    this.setState({
      fromdate: date,
      todate:
        moment(date).diff(this.state.todate, "days") >= 0
          ? this.state.todate
          : date,
    });
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  handlecompletedDateChange = () => {
    this.refreshCompandcancelled(this.state.fromdate, this.state.todate);
  };


  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    UsercustomerService.GetUserCustomersbyUser(userid).then((res) =>
      this.setState({
        usercustomers:
          res.data === "" || res.data === undefined
            ? []
            : res.data.customers.split(","),
      })
    );
    this.refresh();
    this.refreshCompandcancelled(moment(new Date()).subtract(7,'days'),new Date())
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Quotation Id",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          display: false,
        },
      },
      {
        name: "customqtid",
        label: "Quotation Id",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      // {name: "customcustomerid",label:"Customer Id",options: {filter: true,sort: true,}},
      {
        name: "customer",
        label: "Customer",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return <span>{value === null ? "Direct" : value}</span>;
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:MM");
          },
        },
      },
      {
        name: "sotype",
        label: "Type",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "createdbyname",
        label: "Created By",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "sototalwtax",
        label: "Total Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "View",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="fa fa-list-alt"
                  to={{ pathname: "/quotationdetails" }}
                  state={{ id: tableMeta.rowData.find((e) => e), bvstatus: 0 }}
                ></Link>
                {/* <Tooltip id="view details" place="top" effect="solid">View</Tooltip> */}
              </>
            );
          },
          // customHeaderRender:(value)=>{
          //  return(<span style={{background:"red"}}>{value}</span>)
          // }
        },
      },
    ];
    const options = {
      // filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {salesid === "2" || salesid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{ pathname: "/newquotation" }}
                state={{ id: -1, pagename: "New Quotation", salestype: false }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      downloadOptions: {
        filename: "Quotations",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },

      renderExpandableRow: (rowData, rowMeta) => {
        console.log("rowData", rowData);
        console.log("rowMeta", rowMeta)
      }
    };

    const columns1 = [
      {
        name: "id",
        label: "Quotation Id",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          display: false,
        },
      },
      {
        name: "customqtid",
        label: "Quotation Id",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "customer",
        label: "Customer",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return <span>{value === null ? "Direct" : value}</span>;
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:MM");
          },
        },
      },
      {
        name: "sotype",
        label: "Type",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "createdbyname",
        label: "Created By",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "sototalwtax",
        label: "Total Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      // {name: "status",label: "Status",options: {filter: true,sort: true,}},
      {
        name: "View",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="fa fa-list-alt"
                  to={{ pathname: "/quotationdetails" }}
                  state={{ id: tableMeta.rowData.find((e) => e), bvstatus: 1 }}
                ></Link>
                {/* <Tooltip id="view details" place="top" effect="solid">View</Tooltip> */}
              </>
            );
          },
        },
      },
    ];
    const options1 = {
      // filterType: "checkbox",
      selectableRows: "none",
      expandableRows: true,
      expandableRowsHeader: false,
      expandableRowsOnClick: true,

      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data); 
    } ,
    downloadOptions:{
      filename:"Quotations",
      separator:",",
      filterOptions:{
        useDisplayedColumnsOnly:true,
        useDisplayedRowsOnly:true
      }
    },
    renderExpandableRow: (rowData, rowMeta) => {
      console.log(this.props)
      this.props.navigate(
        '/quotationdetails',{
        state:{
          id: rowData[0], bvstatus: 1
         }}
      )
      
    }
    };

    const components = {
      ExpandButton: function(props) {
        return "";
      },
    };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>

              <li className="breadcrumb-item active">Quotation</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Quotation</h3>
                      </div>

                      <div className="card-body">
                        {this.state.loading === true ? (
                          <span className="text-center">{spin}</span>
                        ) : (
                          <>
                            <div className="form-row">
                              <fieldset className="form-group col-md-4">
                                <span className="mx-3">Active</span>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      className="sm"
                                      checked={this.state.so}
                                      onChange={this.handlesoChange}
                                      name="checkedB"
                                    />
                                  }
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-4">
                                <span className="mx-3">Completed</span>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      className="sm"
                                      checked={this.state.comp}
                                      onChange={this.handlecompChange}
                                      name="checkedB"
                                    />
                                  }
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-4">
                                <span className="mx-3">Cancelled/Closed</span>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      className="sm"
                                      checked={this.state.canl}
                                      onChange={this.handlecanlChange}
                                      name="checkedB"
                                    />
                                  }
                                />
                              </fieldset>
                            </div>
                            {this.state.so === true ? (
                              <>
                                <ThemeProvider theme={theme}>
                                  <MUIDataTable
                                    className="table-sm px-3"
                                    title={"Active Quotations"}
                                    data={this.state.saleOrder}
                                    columns={columns}
                                    options={options}
                                  />
                                </ThemeProvider>
                                <fieldset className="form-group col-md-12 my-2">
                                  <label className="text-red">
                                    Note : Quotataions will be automatically
                                    closed at the end of each finanacial year
                                    and will not be avilable for the next
                                    finanacial year.
                                  </label>
                                </fieldset>
                              </>
                            ) : (
                              ""
                            )}

                            {this.state.comp === true ||
                            this.state.canl === true ? (
                              <div className="form-row g-0 mb-3">
                                <fieldset>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-around"
                                    >
                                      <DatePicker
                                        variant="inline"
                                        id="date-picker-dialog"
                                        label="From Date"
                                        name="from"
                                        format="dd/MM/yyyy"
                                        maxDate={new Date()}
                                        minDate={financialyearstart}
                                        value={this.state.fromdate}
                                        onChange={this.handleFromDateChange}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                          />
                                        )}
                                        autoOk={true}
                                      />
                                    </Grid>
                                  </LocalizationProvider>
                                </fieldset>

                                <fieldset style={{ marginLeft: "30px" }}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-around"
                                    >
                                      <DatePicker
                                        variant="inline"
                                        id="date-picker-dialog"
                                        label="To Date"
                                        name="to"
                                        format="dd/MM/yyyy"
                                        minDate={this.state.fromdate}
                                        value={this.state.todate}
                                        onChange={this.handleToDateChange}
                                        maxDate={new Date()}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                          />
                                        )}
                                        autoOk={true}
                                      />
                                    </Grid>
                                  </LocalizationProvider>
                                </fieldset>
                                <fieldset className="form-group col-md-1">
                                  <button
                                    className="btn btn-sm hovbuttonColour mt-3"
                                    onClick={this.handlecompletedDateChange}
                                  >
                                    <i className="fas fa-filter" />
                                  </button>
                                </fieldset>
                              </div>
                            ) : (
                              ""
                            )}

                            {this.state.comp === true ? (
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Completed Quotations"}
                                data={this.state.completedso}
                                columns={columns1}
                                options={options1}
                                components={components}

                              />
                            ) : (
                              ""
                            )}

                            {this.state.canl === true ? (
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Cancelled Quotations"}
                                data={this.state.cancelledso}
                                columns={columns1}
                                options={options1}
                                components={components}
                              />
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
            <Footer />
      </div>
    );
  }
}
export default withRouter(QutationTable);
