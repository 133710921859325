import React from 'react'
//import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import {Loader as ColorRing} from "react-loader-spinner";
import './newloader.css'
export let spin =  <div className="newcontent">
<div className="newloading">
<p>loading...</p>
   <span></span>
</div>
</div>
// {/* <Loader type="Watch" color="#263571" height={100} width={200} timeout={10000} //3 secs /> */}
class NewLoader  {
    
}
export default new NewLoader()