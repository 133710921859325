import { ErrorMessage,  Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currency, currentTenant, thousandsdisplay, userid } from "../../Common/Credentials.js";
import { FormikTextField } from "../../CommonComponents/FormField.js";

import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";

import SalesCalculationServices from "./SalesCalculationServices";
import { Autocomplete } from "@mui/material";
import { Spinner } from "react-bootstrap";
import CategoryServices from "../Category/CategoryServices.js";
import {NumericFormat} from "react-number-format";
import { FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@mui/material";

class SalesCalculation extends Component {
  constructor(props) {
    super(props);
    const pagename  = this.props.location.state.pagename;
    const id  = this.props.location.state.id;

    this.state = {
      pagename:pagename,
      salesCal:'',
      newlist: {},
      id:id,
      itemid: "",
      itemname: "",
      itemsList: [],
      cost: 0,
      margin: 0,
      marginpercent: 0,
      discountpercent: 0,
      discount: 0,
      bankintrest: 0,
      status: "",
      transportation: 0,
      grossprice: 0,
      mtype: "",
      mp: 0,
      CategoryList: [],
      catid: "",
      category: "",
      filteritemlist: [],
      fifoprice:0,
      lifoprice:0,
      avgprice:0,
      loader: false,
      salestypes:[{name:"Average",value:"0"},{name:"FIFO",value:"1"},{name:"LIFO",value:"2"}]
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeMargin = (e) => {
    this.setState({ mtype: e.target.value,grossprice:0 ,loader:false});
    console.log(e.target.value)
    if (e.target.value !== "") {
      if (e.target.value === "0") {
        this.setState({
          mp:
            this.state.cost +
            (parseFloat(this.state.margin) / 100) * this.state.cost,
        });
      } else if (e.target.value === "1") {
        this.setState({ mp: this.state.cost + parseFloat(this.state.margin) });
      }
    }
  };
  handleSelect = (e) => {
    this.setState({ status: e.target.value ,loader:false});
    this.setState({ grossprice: 0 });
    this.setState({ mtype: "" });
    this.setState({ margin: 0 });
    this.setState({ discount: 0 });
    this.setState({ bankintrest: 0 });
    this.setState({ transportation: 0 });

    const id = this.state.itemid;
    const type = e.target.value;

    SalesCalculationServices.costType(id, type).then((response) =>
      this.setState({ cost: response.data })
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  };

  handleChange = (value, values) => {
    if (value !== "") {
      this.setState({ itemid: values.item.id, itemname: value,loader:false });
      this.setState({ status: "" });
      this.setState({ cost: 0 });
      this.setState({ grossprice: 0 });
      this.setState({ mtype: "" });
      this.setState({ margin: 0 });
      this.setState({ discount: 0 });
      this.setState({ bankintrest: 0 });
      this.setState({ transportation: 0 });
    }
  };
  onSubmit = (values) => {
    if (
      this.state.itemid === "" ||
      this.state.status === "" ||
      this.state.mtype === "" ||
      this.state.discount === "" ||
      this.state.bankintrest === "" ||
      this.state.transportation === ""
    ) {
    } else {
      this.setState({
        grossprice:
          this.state.mp -
          (parseFloat(this.state.discount) / 100) * this.state.cost +
          (parseFloat(this.state.bankintrest) / 100) * this.state.cost +
          parseFloat(this.state.transportation),
          loader:false
      });

        SalesCalculationServices.costType(this.state.itemid,"1").then((response) =>
        this.setState({
          fifoprice:
            this.state.mp -
            (parseFloat(this.state.discount) / 100) * response.data +
            (parseFloat(this.state.bankintrest) / 100) * response.data +
            parseFloat(this.state.transportation)
        })
      )
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    SalesCalculationServices.costType(this.state.itemid,"2").then((response) =>
    this.setState({
      lifoprice:
        this.state.mp -
        (parseFloat(this.state.discount) / 100) * response.data +
        (parseFloat(this.state.bankintrest) / 100) * response.data +
        parseFloat(this.state.transportation)
    })
  )
  SalesCalculationServices.costType(this.state.itemid,"0").then((response) =>
    this.setState({
      avgprice:
        this.state.mp -
        (parseFloat(this.state.discount) / 100) * response.data +
        (parseFloat(this.state.bankintrest) / 100) * response.data +
        parseFloat(this.state.transportation)
    })
  )
    }
  };

  handleCategory = (value, values) => {
    if (value !== "") {
      this.setState({
        itemid: "",
        itemname: "",
        cost: "",
        loader:false
      });
      this.setState({
        catid: values.category.id,
        category: value,
        grossprice:0,
        filteritemlist: this.state.itemsList.filter(
          (e) => e.categoryid === values.category.id
        ),
      });
    }
  };

  handleSubmit(values) {
    this.setState({ loader: true });
    let salesCal = {
      id:this.state.id,
      itemid: parseInt(this.state.itemid),
      name: this.state.itemname,
      type: parseInt(this.state.status),
      cost: this.state.cost,
      mtype:parseInt(this.state.mtype),
      margin: this.state.mp - this.state.cost,
      marginpercent: parseFloat(this.state.margin),
      discountpercent: parseFloat(this.state.discount),
      discount: (this.state.discount / 100) * this.state.cost,
      bankintrest: parseFloat(this.state.bankintrest),
      grossValue: this.state.grossprice,
      transportCharges: parseFloat(this.state.transportation),
      tenantid: currentTenant,
      categoryid: this.state.catid,
      createdby:userid,
      fifoprice:this.state.fifoprice,
      lifoprice:this.state.lifoprice,
      avgprice:this.state.avgprice,

    };
    if(this.state.id === -1){
    SalesCalculationServices.SalesCal(salesCal).then(() =>
      this.props.navigate("/salescaltable")
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      } 
      this.setState({message:e.response.data.message +" - edit in order change the pricing",loader:false})
  })
}
else{
  SalesCalculationServices.UpdateSalesCal(salesCal).then(() =>
  this.props.navigate("/salescaltable")
)
.catch((e)=>{
  if(e.response.data.error ==="Unauthorized"){
      alert("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
  }
})
  }
}
  refreshForm() {
    const id =this.props.location.state.id;
    SalesCalculationServices.itemsList(currentTenant).then((response) =>
      this.setState({
        itemsList: response.data,
      })
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })

    CategoryServices.getCategory(currentTenant).then((response) =>
      this.setState({
        CategoryList: response.data.filter((e) => e.categorytype === "Product"),
        // ServiceCategoryList: response.data.filter(
        //   (e) => e.categorytype === "Service"
        // ),
      })
    );
    if(id !== -1){
      SalesCalculationServices.SalesCalDetails(id).then((res)=>{
        this.setState({
          salesCal:res.data,
          catid:res.data.categoryid,
          category:res.data.category,
          itemid:res.data.itemid,
          itemname:res.data.name,
          status:res.data.type.toLocaleString(),
          margin:res.data.mtype === 0?res.data.marginpercent: res.data.margin,
          mtype:res.data.mtype.toLocaleString(),
          mp:res.data.mtype === 0?res.data.cost +(parseFloat(res.data.marginpercent) / 100) * res.data.cost:res.data.cost + parseFloat(res.data.margin) ,
          discount:res.data.discountpercent,
          bankintrest:res.data.bankintrest,
          transportation:res.data.transportCharges,
          fifoprice:res.data.fifoprice,
          lifoprice:res.data.lifoprice,
          avgprice:res.data.avgprice,
          // grossprice:res.data.grossValue,
          cost:res.data.cost
        })
      })
    }
    
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.refreshForm();
  }

  validate(values) {
    let errors = {};

    if (this.state.catid === "") {
      errors.category = "Please Select Category ";
    }

    if (this.state.itemid === "") {
      errors.item = "Please Select Item Name ";
    }

    if (this.state.status === "") {
      errors.status = "Please Select Type ";
    }

    if (this.state.margin === "") {
      errors.margin = "Please Enter Atleast 0";
    }
    if ( this.state.mtype === "") {
      errors.mtype = "Please Select Margin Type";
    }

    if (this.state.discount === "") {
      errors.discount = "Please Enter Atleast 0";
    }
    if (this.state.bankintrest === "") {
      errors.bankintrest = "Please Enter Atleast 0";
    }

    if (this.state.transportation === "") {
      errors.transportation = "Please Enter at least 0";
    }

    return errors;
  }

  render() {
    const {
      id,
      itemid,
      itemname,
      category,
      cost,
      status,
      margin,
      mtype,
      discount,
      bankintrest,
      transportation,
      grossprice,
      itemsList,
      CategoryList,
      filteritemlist,
    } = this.state;
    let displaybutton;
    let displaysubmit;

    if (this.state.grossprice === "" || this.state.grossprice === 0) {
      displaybutton = (
        <button className="btn btn-sm hovbuttonColour" type="onSubmit">
          Calculate Sales Price
        </button>
      );
    } else {
      displaysubmit = (
       
          this.state.loader === true ? (
            <button
            as="button"
            className="btn btn-sm hovbuttonColour"
            disabled
          >
              <Spinner animation="border" size="sm" />
              &nbsp;{"Saving"}
            </button>
          ) : (
            <span
            as="button"
            className="btn btn-sm hovbuttonColour"
            onClick={this.handleSubmit}
          >
            <i className="fa fa-save mr-2"></i>
            Save
            </span>
          )
      );
    }

    return (
      <div>
      <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
            <Link as="button" className=" text-secondary"  to="/salescaltable">
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>

              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/salescaltable">Sales Calculation</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>
            <div className="card">
              <div className="card-body">
                <Formik
                  initialValues={{
                    id,
                    itemid,
                    itemname,
                    category,
                    cost,
                    status,
                    margin,
                    mtype,
                    discount,
                    bankintrest,
                    transportation,
                    grossprice,
                    itemsList,
                    CategoryList,
                    filteritemlist,
                  }}
                  onSubmit={this.onSubmit}
                  validateOnChange={false}
                  validate={this.validate}
                  enableReinitialize={true}
                >
                  {({ setFieldValue, values }) => (
                    <Form autoComplete="off">
                      <div className="form-row">
                        <fieldset hidden className="form-group col-md-12">
                          <label>Id</label>
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="id"
                            placeholder="Id"
                          />
                        </fieldset>

                        {this.state.id === -1?<>
                        <fieldset className="form-group col-md-4">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="category"
                            options={CategoryList}
                            getOptionLabel={(option) => option.category}
                            
                            
                            value={values.category ? undefined : undefined}
                            onChange={(e, value) => {
                              setFieldValue("category", value);
                            }}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control"
                                type="text"
                                name="category"
                                onBlur={(e) => {
                                  this.handleCategory(e.target.value, values);
                                }}
                                label="Select Category"
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>

                        <fieldset className="form-group col-md-4">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="item"
                            options={filteritemlist}
                            getOptionLabel={(option) => option.name}
                            
                            
                            value={values.item ? undefined : undefined}
                            onChange={(e, value) => {
                              setFieldValue("item", value);
                            }}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control"
                                type="text"
                                name="item"
                                onBlur={(e) => {
                                  this.handleChange(e.target.value, values);
                                }}
                                label="Select Item"
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>
                        </>:
                        <>
                          <fieldset className="form-group col-md-4">
                          <TextField
                                  className="form-control"
                                  type="text"
                                  name="category"
                                  value={this.state.category}
                                  label="Category"
                                  variant="standard"
                                  disabled
                                />
                            </fieldset>
                            <fieldset className="form-group col-md-4">
                          <TextField
                                  className="form-control"
                                  type="text"
                                  name="item"
                                  value={this.state.itemname}
                                  label="Item Name"
                                  variant="standard"
                                  disabled
                                />
                            </fieldset>

                        </>
                          }

                        <fieldset className="form-group col-md-4" />
                        <fieldset className="form-group col-md-12" />

                        <fieldset className="form-group col-md-4 m-0">
                        <FormControl className="mt-3">
                                        <FormLabel id="demo-row-radio-buttons-group-label">
                                          Type :
                                        </FormLabel>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="status"
                                          value={this.state.status}
                                          onChange={this.handleSelect}
                                        >
                                          {this.state.salestypes.map((e) => {
                                            return (
                                              <FormControlLabel
                                                key={e.value}
                                                value={e.value}
                                                control={
                                                  <Radio
                                                    style={{ color: "#152f5f" }}
                                                    disabled={this.state.data!==undefined}
                                                  />
                                                }
                                                label={e.name}
                                              />
                                            );
                                          })}
                                        </RadioGroup>
                                      </FormControl>
                          <ErrorMessage
                            name="status"
                            component="div"
                            className="text-danger float-sm-right"
                          />
                        </fieldset>

                        {/* <fieldset className="form-group col-md-4" disabled>
                          <FormikTextField className="form-control" type="number" name="cost" label="Price" value={this.state.cost} />
                        </fieldset> */}
                        <fieldset className="form-group col-md-4 mt-4">
                        <FormLabel id="demo-row-radio-buttons-group-label">
                                          Price :
                                        </FormLabel>
                          <span>
                            {" "}
                            &nbsp;{" "}
                            <NumericFormat  displayType={'text'} value={this.state.cost === "" ? 0 : this.state.cost} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                            
                          </span>
                        </fieldset>
                        {/* <fieldset className="form-group col-md-3 mt-4">
                          <label> &nbsp; {this.state.cost === '' ? "0" : this.state.cost}</label>
                        </fieldset> */}

                        <fieldset className="form-group col-md-4" />
                        <fieldset className="form-group col-md-2">
                          <FormikTextField
                            className="form-control"
                            type="number"
                            name="margin"
                            label="Margin"
                            variant="standard"
                            onChange={(e) =>
                              this.setState({
                                margin: e.target.value,
                                mtype: "",
                                grossprice:0
                              })
                            }
                          />
                        </fieldset>

                        <fieldset className="form-group col-md-1">
                          {/* <Select
                            name="mtype"
                            label="Type"
                            className="form-group col-md-12"
                            onChange={this.handleChangeMargin}
                            options={[
                              { label: "%", value: "0" },
                              { label: "Amount", value: "1" },
                              // { label: "\u20A8", value: "1" },
                            ]}
                            value={this.state.mtype}
                          /> */}
                           <FormControl variant="standard" className="w-100">
                                  <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={this.state.mtype}
                                    onChange={this.handleChangeMargin}
                                    label="Type"
                                  >
                                      <MenuItem value="0">%</MenuItem>
                                      <MenuItem value="1">Amount</MenuItem>
                                  </Select>
                                </FormControl>
                        </fieldset>
                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="number"
                            name="discount"
                            label="Discount %"
                            variant="standard"
                            onChange={(e) =>
                              this.setState({ discount: e.target.value,grossprice:0 })
                            }
                          />
                        </fieldset>

                        {/* <fieldset className="form-group col-md-4"/> */}

                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="number"
                            name="bankintrest"
                            label="Bank Intrest %"
                            variant="standard"
                            onChange={(e) =>
                              this.setState({ bankintrest: e.target.value,grossprice:0 })
                            }
                          />
                        </fieldset>

                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="number"
                            name="transportation"
                            label="Transportation"
                            variant="standard"
                            onChange={(e) =>
                              this.setState({ transportation: e.target.value,grossprice:0 })
                            }
                          />
                        </fieldset>

                        <div className="form-group col-md-12" />
                        <fieldset className="form-group col-md-6">
                        <FormLabel id="demo-row-radio-buttons-group-label">Sales Price&nbsp;&nbsp;</FormLabel>
                          <span>
                            : &nbsp;{" "}
                            {this.state.grossprice === ""
                              ? "0"
                              : <NumericFormat
                              className="pr-3"
                              displayType={"text"}
                              value={this.state.grossprice}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              disabled={true}
                              thousandSeparator={true}
                              thousandsGroupStyle={thousandsdisplay}
                            ></NumericFormat>}
                          </span>
                          ({currency})
                        </fieldset>
                        <fieldset className="form-group col-md-6"></fieldset>
                      </div>
                      {displaybutton}
                      {displaysubmit}
                      <Link
                        as="button"
                        className="btn btn-sm deletebutton ml-3"
                        to={{ pathname: "/salescaltable" }}
                      >
                        <b className="text-red"> X</b> Cancel{" "}
                      </Link>
                      <p className="text-danger">{this.state.message}</p>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(SalesCalculation);
