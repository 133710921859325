import React, { Component } from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Header from "../Component/Header.js";
import Menu from "../Component/Menu.js";
import { thousandsdisplay, userid } from "../Common/Credentials";
import NewJournalService from "./NewJournalService";
import { NumericFormat } from "react-number-format";
import { ToWords } from "to-words";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import downloadfiles from "../Common/upload/downloadfiles.js";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import Swal from 'sweetalert2';

//let jvtype=1
let gsttype;
class JournalConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmjv: this.props.location.state,
      loader: false,
    };
    //this.validate=this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.download = this.download.bind(this);
  }

  // goBack() {
  //   alert("I am here outside")

  //   if(this.state.confirmjv.jvtype===6){
  //     this.props.navigate(`/dtaxes`);
  //   }else{
  //   this.props.navigate(-1);
  //   }
  // }

  onSubmit(values) {
    this.setState({ loader: true });
    let jv; //,dirstock
    let sgstid, sgstpercent, sgstval, sgstname;
    let cgstid, cgstpercent, cgstval, cgstname;
    let igstid, igstpercent, igstval, igstname;
    let tcsid, tcsname, tcsvalue, tcspercentage, tcsamt;
    let vatid, vatpercent, vatval, vatname;
    let whid, items, qty, uom, cost, itemname, warehouse;

    if (this.state.confirmjv.gst !== undefined) {
      sgstid = this.state.confirmjv.gst.id;
      sgstpercent = this.state.confirmjv.gstpercent;
      sgstval = this.state.confirmjv.taxval1;
      sgstname = this.state.confirmjv.gst.name;
    }
    if (this.state.confirmjv.cgst !== undefined) {
      cgstid = this.state.confirmjv.cgst.id;
      cgstpercent = this.state.confirmjv.cgstpercent;
      cgstval = this.state.confirmjv.taxval2;
      cgstname = this.state.confirmjv.cgst.name;
    }
    if (this.state.confirmjv.igst !== undefined) {
      igstid = this.state.confirmjv.igst.id;
      igstpercent = this.state.confirmjv.cgstpercent;
      igstval = this.state.confirmjv.itaxval;
      igstname = this.state.confirmjv.igst.name;
    }
    if (this.state.confirmjv.tcsid !== undefined) {
      tcsid = this.state.confirmjv.tcsid;
      tcsname = this.state.confirmjv.tcsname;
      tcsvalue = this.state.confirmjv.tcsvalue;
      tcspercentage = this.state.confirmjv.tcspercentage;
      tcsamt = this.state.confirmjv.tcsamt;
    }
    if (this.state.confirmjv.vat !== undefined) {
      vatid = this.state.confirmjv.vat.id;
      vatpercent = this.state.confirmjv.vatpercent;
      vatval = this.state.confirmjv.vatval;
      vatname = this.state.confirmjv.vatname;
    }
    if (this.state.confirmjv.whid !== undefined) {
      whid = this.state.confirmjv.whid.id;
    }
    if (
      this.state.confirmjv.whid !== undefined &&
      this.state.confirmjv.entrytype !== 12
    ) {
      items = this.state.confirmjv.items.id;
      itemname = this.state.confirmjv.items.name;
      qty = this.state.confirmjv.qty;
      uom = this.state.confirmjv.uom;
      cost = this.state.confirmjv.uprice;
    }

    if (this.state.confirmjv.gwhid !== undefined) {
      whid = this.state.confirmjv.gwhid;
      warehouse = this.state.confirmjv.warehouse;
    }
    let surchargeid, surchargename, surval;
    let penaltyid, penaltyname, penval;
    if (this.state.confirmjv.surchargeid !== undefined) {
      surchargeid = this.state.confirmjv.surchargeid;
      surchargename = this.state.confirmjv.surchargename;
      surval = this.state.confirmjv.surval;
    }
    if (this.state.confirmjv.penaltyid !== undefined) {
      penaltyid = this.state.confirmjv.penaltyid;
      penaltyname = this.state.confirmjv.penaltyname;
      penval = this.state.confirmjv.penval;
    }

    if (this.state.confirmjv.purchasetype === 2) {
      jv = {
        debitid: this.state.confirmjv.debitid,
        debitname: this.state.confirmjv.debitname,
        debitamt: this.state.confirmjv.debitamt,
        creditid: this.state.confirmjv.creditid,
        creditname: this.state.confirmjv.creditname,
        creditamt: this.state.confirmjv.creditamt,
        narration: this.state.confirmjv.narration,
        gstin: this.state.confirmjv.gstin,
        pan: this.state.confirmjv.pan,
        contactnumber: this.state.confirmjv.contactnumber,
        email: this.state.confirmjv.email,
        surchargeid: surchargeid,
        surchargename: surchargename,
        surval: surval,
        penaltyid: penaltyid,
        penaltyname: penaltyname,
        penval: penval,
        createdBy: this.state.confirmjv.createdBy,
        poid: this.state.confirmjv.poid,
        supplier: this.state.confirmjv.supname,
        supplierid: this.state.confirmjv.supid,
        poadvance: this.state.confirmjv.poadvance,
        entrytype: this.state.confirmjv.entrytype,
        jvtype: this.state.confirmjv.jvtype,
        tenantId: this.state.confirmjv.tenantId,
        supname: this.state.confirmjv.supplier,
        supid: this.state.confirmjv.supplierid,
        purchasetype: this.state.confirmjv.purchasetype,
        poamount: this.state.confirmjv.poamount,
        advledger: this.state.confirmjv.advledger,
        sdebitid: this.state.confirmjv.sdebitid,
        sdebitamt: this.state.confirmjv.sdebitamt,
        sdebitname: this.state.confirmjv.sdebitname,
        expense: this.state.confirmjv.expense,
        custompoid: this.state.confirmjv.custompoid,
        date: this.state.confirmjv.date,
        credth: this.state.confirmjv.credth,
        debdth: this.state.confirmjv.debdth,
        refundid:this.state.confirmjv.refundid,
        openadjusted:this.state.confirmjv.openadjusted,
        jvinsertiontype:this.state.confirmjv.jvinsertiontype,
        jvid:this.state.confirmjv.jvid,
        cjvid:this.state.confirmjv.cjvid,
        invbatchid:this.state.confirmjv.invbatchid,
        genadv:this.state.confirmjv.genadv,
        pmtselectedoption:this.state.confirmjv.pmtselectedoption,inviddrref:this.state.confirmjv.drinvid,
        insurancedn:this.state.confirmjv.insurancedn,tcsdn:this.state.confirmjv.tcsdn,otherdn:this.state.confirmjv.otherdn
        };
    } else if (this.state.confirmjv.frompage === "/dtp") {
      jv = {
        debitid: this.state.confirmjv.debitid,
        debitamt: this.state.confirmjv.debitamt,
        debitname: this.state.confirmjv.debitname,
        creditid: this.state.confirmjv.creditid,
        creditamt: this.state.confirmjv.creditamt,
        creditname: this.state.confirmjv.creditname,
        narration: this.state.confirmjv.narration,
        createdBy: this.state.confirmjv.createdBy,
        jvtype: this.state.confirmjv.jvtype,
        surchargeid: surchargeid,
        surchargename: surchargename,
        surval: surval,
        penaltyid: penaltyid,
        penaltyname: penaltyname,
        penval: penval,
        paymentref: this.state.confirmjv.paymentref,
        tenantId: this.state.confirmjv.tenantId,
        pendingdata: this.state.confirmjv.pendingdata,
        date: this.state.confirmjv.date,
        credth: this.state.confirmjv.credth,
        debdth: this.state.confirmjv.debdth,
        etrype:this.state.confirmjv.etype,
        refund:this.state.confirmjv.refund,
        openadjusted:this.state.confirmjv.openadjusted,
        dcadjusted:this.state.confirmjv.dcadjusted,
        jvinsertiontype:this.state.confirmjv.jvinsertiontype,
        jvid:this.state.confirmjv.jvid,
        cjvid:this.state.confirmjv.cjvid,
        invbatchid:this.state.confirmjv.invbatchid,
        pmtselectedoption:this.state.confirmjv.pmtselectedoption,inviddrref:this.state.confirmjv.drinvid,
        insurancedn:this.state.confirmjv.insurancedn,tcsdn:this.state.confirmjv.tcsdn,otherdn:this.state.confirmjv.otherdn
      };
    } else if (this.state.confirmjv.jvtype === 9) {
      jv = {
        debitid: this.state.confirmjv.debitid,
        debitamt: this.state.confirmjv.debitamt,
        debitname: this.state.confirmjv.debitname,
        creditid: this.state.confirmjv.creditid,
        creditamt: this.state.confirmjv.creditamt,
        creditname: this.state.confirmjv.creditname,
        narration: this.state.confirmjv.narration,
        createdBy: this.state.confirmjv.createdBy,
        jvtype: this.state.confirmjv.jvtype,
        paymentref: this.state.confirmjv.paymentref,
        tenantId: this.state.confirmjv.tenantId,
        curmonth: this.state.confirmjv.curmonth,
        selgsttype: this.state.confirmjv.selgsttype,
        date: this.state.confirmjv.date,
        credth: this.state.confirmjv.credth,
        debdth: this.state.confirmjv.debdth,
        refund:this.state.confirmjv.refund,
        openadjusted:this.state.confirmjv.openadjusted, 
        dcadjusted:this.state.confirmjv.dcadjusted,
        jvinsertiontype:this.state.confirmjv.jvinsertiontype,
        jvid:this.state.confirmjv.jvid,
        cjvid:this.state.confirmjv.cjvid,
        invbatchid:this.state.confirmjv.invbatchid,
        genadv:this.state.confirmjv.genadv,
        pmtselectedoption:this.state.confirmjv.pmtselectedoption,inviddrref:this.state.confirmjv.drinvid,
        insurancedn:this.state.confirmjv.insurancedn,tcsdn:this.state.confirmjv.tcsdn,otherdn:this.state.confirmjv.otherdn,
        advnum:parseInt(this.state.confirmjv.advnum)
      };
    } else {
      jv = {
        debitid: this.state.confirmjv.debitid,
        debitamt: this.state.confirmjv.debitamt,
        debitname: this.state.confirmjv.debitname,
        sdebitid: this.state.confirmjv.sdebitid,
        sdebitamt: this.state.confirmjv.sdebitamt,
        sdebitname: this.state.confirmjv.sdebitname,
        creditid: this.state.confirmjv.creditid,
        creditamt: this.state.confirmjv.creditamt,
        creditname: this.state.confirmjv.creditname,
        narration: this.state.confirmjv.narration,
        jvtype: this.state.confirmjv.jvtype,
        dmgid: parseInt(this.state.confirmjv.dmgid),
        depid: this.state.confirmjv.depid,
        assetidae: parseInt(this.state.confirmjv.assetidae),
        dmgidpos: parseInt(this.state.confirmjv.dmgidpos),
        dmgidps: parseInt(this.state.confirmjv.dmgidps),
        assetidte: this.state.confirmjv.assetidte,
        createdBy: userid,
        entrytype: this.state.confirmjv.entrytype,
        sgstid: sgstid,
        sgstpercent: sgstpercent,
        sgstval: sgstval,
        sgstname: sgstname,
        cgstid: cgstid,
        cgstpercent: cgstpercent,
        cgstval: cgstval,
        cgstname: cgstname,
        igstid: igstid,
        igstpercent: igstpercent,
        igstval: igstval,
        igstname: igstname,
        vatid: vatid,
        vatpercent: vatpercent,
        vatval: vatval,
        vatname: vatname,
        whid: whid,
        warehouse: warehouse,
        items: items,
        itemname: itemname,
        qty: qty,
        uom: uom,
        cost: cost,
        custompoid: this.state.confirmjv.custompoid,
        pmtid: this.state.confirmjv.pmtid,
        gsttype: gsttype,
        selgrn: this.state.confirmjv.selgrn,
        supname: this.state.confirmjv.supplier,
        gstin: this.state.confirmjv.gstin,
        pan: this.state.confirmjv.pan,
        email: this.state.confirmjv.email,
        purchasetype: this.state.confirmjv.purchasetype,
        contactnumber: this.state.confirmjv.contactnumber,
        supid: this.state.confirmjv.supplierid,
        poid: this.state.confirmjv.poid,
        mode: this.state.confirmjv.mode,
        bank: this.state.confirmjv.bank,
        bankid: this.state.confirmjv.bankid,
        paymentref: this.state.confirmjv.paymentref,
        expense: this.state.confirmjv.expense,
        gpdata: this.state.confirmjv.gpdata,
        sadata: this.state.confirmjv.sadata,
        tenantId: this.state.confirmjv.tenantId,
        invoicepath: this.state.confirmjv.invoicepath,
        invoicefile: this.state.confirmjv.invoicefile,
        invoicedate: moment(this.state.confirmjv.invoicedate),
        invoiceamt: this.state.confirmjv.invoiceamt,
        proinvoiceamt: this.state.confirmjv.proinvoiceamt,
        proinvoicedate: moment(this.state.confirmjv.proinvoicedate),
        proinvoicefile: this.state.confirmjv.proinvoicefile,
        proinvoicepath: this.state.confirmjv.proinvoicepath,
        poamount: this.state.confirmjv.poamount,
        quotedate: moment(this.state.confirmjv.quotedate),
        quotefile: this.state.confirmjv.quotefile,
        quotepath: this.state.confirmjv.quotepath,
        tcsid: tcsid,
        tcsname: tcsname,
        tcsvalue: tcsvalue,
        tcspercentage: tcspercentage,
        tcsamt: tcsamt,
        poadvance: this.state.confirmjv.poadvance,
        advledger: this.state.confirmjv.advledger,
        advstatus: this.state.confirmjv.advstatus,
        paymentfor: this.state.confirmjv.paymentfor,
        dirstock:
          this.state.confirmjv.entrytype === 12
            ? this.state.confirmjv.dirstock
             : [],
        invbatchid:this.state.confirmjv.invbatchid,
        invitemled: this.state.confirmjv.invitemled,
        invitemid: this.state.confirmjv.invitemid,
        invitemname: this.state.confirmjv.invitemname,
        date: this.state.confirmjv.date,
        credth: this.state.confirmjv.credth,
        debdth: this.state.confirmjv.debdth,
        refundid:this.state.confirmjv.refundid,
        etype:this.state.confirmjv.etype,
        refund:this.state.confirmjv.refund,openadjusted:this.state.confirmjv.openadjusted,dcadjusted:this.state.confirmjv.dcadjusted,
        jvinsertiontype:this.state.confirmjv.jvinsertiontype,
        jvid:this.state.confirmjv.jvid,
        cjvid:this.state.confirmjv.cjvid,genadv:this.state.confirmjv.genadv,
        pmtselectedoption:this.state.confirmjv.pmtselectedoption,inviddrref:this.state.confirmjv.drinvid,
        insurancedn:this.state.confirmjv.insurancedn,tcsdn:this.state.confirmjv.tcsdn,otherdn:this.state.confirmjv.otherdn,
        advnum:parseInt(this.state.confirmjv.advnum)
      };
    }
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    } else {

      if(this.state.confirmjv.jvinsertiontype==="PURADV" || this.state.confirmjv.jvinsertiontype==="PURINV"
      ||  this.state.confirmjv.jvinsertiontype===15 ){
        Swal.fire({
          title: "Confirmation",
          text: "This action is irreversible, are you sure you want to confirm ? " + "It cannot be deleted or edited. Please be sure before confirming",
          icon: "warning",
          confirmButtonText: "Ok",
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonColor: "#152f5f",
        }).then((result) => {
          if (result.value === true) {
            NewJournalService.insertJv(jv)
            .then(() =>
              this.state.confirmjv.jvtype !== 9
                ? this.goBack()
                : this.props.navigate(
                    { pathname: "/indirtaxes" },
                    { message: values + "   : Entered Successfully" }
                  )
            )
             }
            else{
            this.setState({loader:false})
            // this.goBack()
            }
          }) 
        }else{
          //this.props.navigate({pathname:`/journalconfirm`}, {state:jv});
         NewJournalService.insertJv(jv)
        .then(() =>
          this.state.confirmjv.jvtype !== 9
            ? this.goBack()
            : this.props.navigate(
                { pathname: "/indirtaxes" },
                { message: values + "   : Entered Successfully" }
              )
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    }
  }
  }

  componentDidMount() {
     console.log(this.state.confirmjv)

    gsttype = this.props.location.state.gsttype;
  }

  download() {
    return downloadfiles.download(
      this.state.confirmjv.invoicepath,
      this.state.confirmjv.invoicefile
    );
  }

  componentDidUpdate() {}

  goBack() {
    if (this.state.confirmjv.jvtype === 3) {
      this.props.navigate(`/ap`);
    } else if (this.state.confirmjv.jvtype === 6) {
      this.props.navigate(`/dtaxes`);
    } else if (this.state.confirmjv.jvtype === 7) {
      this.props.navigate(`/lossesl`);
    } else if (this.state.confirmjv.jvtype === 8) {
      this.props.navigate(`/depreciationl`);
    } else if (this.state.confirmjv.entrytype === 12) {
      this.props.navigate(`/dirstock`);
    } else if (this.state.confirmjv.jvtype === 13) {
      this.props.navigate(`/debitcreditnotes`);
    }  else if (this.state.confirmjv.entrytype === 9) {
      this.props.navigate(`/indirtaxes`);
    } else {
      this.props.navigate(-1);
    }
  }

  render() {
    let jv = this.state.confirmjv,
      selectedval; //,new1
    const toWords = new ToWords();
    let amtwords  
    if(jv.creditamt!==null && jv.creditamt>0 ){ 
    amtwords=toWords.convert(!jv.openadjusted && jv.openadjusted!==undefined?jv.creditamt+jv.openadjusted:jv.creditamt);
    }
    if (jv.poid !== undefined) {
      selectedval = jv.selectedval;
    } else if (jv.poid !== undefined && jv.selectedval !== undefined) {
      //  [...new Set(
      jv.selectedval.map((e) => {
        return (selectedval = e);
      });
      // ),];
    }
    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item active">Confirm Entry</li>
            </ol>

            <div className="text-center text-danger">{this.state.error}</div>
            <div className="card-body">
              <div className="text-center"></div>

              <Formik
                initialValues={{ jv }}
                onSubmit={this.onSubmit}
                validateOnChange={true}
                validate={this.validate}
                enableReinitialize={true}
              >
                {() => (
                  <Form>
                    {jv.whid !== undefined && jv.entrytype !== 12 ? (
                      <div className="form-row">
                        <div>
                          <h4>Inventory</h4>
                        </div>
                        <Table className="table table-sm">
                          <TableHead className="backgroundColour text-sm">
                            <TableRow>
                              <TableCell>Item</TableCell>
                              <TableCell className="text-right">
                                WareHouse
                              </TableCell>
                              <TableCell className="text-right">Cost</TableCell>
                              <TableCell className="text-right">
                                Quantity
                              </TableCell>
                              <TableCell className="text-right">UOM</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="text-sm">
                            <TableRow>
                              <TableCell>{jv.items.name}</TableCell>
                              <TableCell className="text-right">
                                {jv.whid.address}
                              </TableCell>
                              <TableCell className="text-right">
                                {jv.uprice}
                              </TableCell>
                              <TableCell className="text-right">
                                {jv.qty}
                              </TableCell>
                              <TableCell className="text-right">
                                {jv.uom}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </div>
                    ) : (
                      ""
                    )}

                    {jv.gpdata !== undefined ? (
                      <>
                        {jv.gpdata.length !== 0 ? (
                          <div className="form-row">
                            <div>
                              <h4>GRN</h4>
                            </div>
                            <table className="table table-sm">
                              <thead className="backgroundColour text-sm text-white">
                                <tr>
                                  <td>Item</td>
                                  <td>GRN</td>
                                  <td>PO</td>
                                  <td>Supplier</td> <td>Amount</td>
                                  <td>Quantity</td>
                                  <td>Tax</td>
                                </tr>
                              </thead>
                                <tbody className="text-sm">
                                {jv.gpdata.map((e,i) => (
                                  <tr key={i}>
                                    <td>{e.name}</td>
                                    <td>{e.grnid}</td>
                                    <td>{e.poid}</td> <td>{jv.supplier}</td>
                                    <td>{e.price}</td>
                                    <td>{e.quantity}</td>
                                    <td>{e.tax}</td>
                                  </tr>
                                  ))}
                                </tbody>
                            </table>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {jv.sadata !== undefined ? (
                      <>
                        {jv.sadata.length !== 0 ? (
                          <div className="form-row">
                            <div>
                              <h4>SCN</h4>
                            </div>

                            <table className="table table-sm">
                              <thead className="backgroundColour text-sm text-white">
                                <tr>
                                  <td>Service</td>
                                  <td>SCN</td>
                                  <td>PO</td>
                                  <td>Supplier</td> <td>Amount</td>
                                  <td>Tax</td>
                                  <td>Total</td>
                                  <td>Completion</td>
                                </tr>
                              </thead>
                              <tbody className="text-sm">
                                {jv.sadata.map((e,i) => (
                                  <tr key={i}>
                                    <td>{e.scnname}</td>
                                    <td>{e.scnid}</td>
                                    <td>{jv.poid}</td> <td>{jv.supplier}</td>
                                    <td>{e.total}</td>
                                    <td>{e.tax}</td>
                                    <td>{(e.total * e.tax) / 100 + e.total}</td>
                                    <td>{e.percentage}%</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}

                    {jv.poadvance !== "" && jv.jvtype === 0 ? (
                      <div className="form-row">
                        <div>
                          <h4>Advance</h4>
                        </div>
                        <table className="table table-sm ">
                          <thead>
                            <tr className="backgroundColour text-sm">
                              <td className="text-white">PO</td>
                              <td className="text-right text-white">
                                Supplier
                              </td>
                              <td className="text-right text-white">
                                Advance Amount
                              </td>
                            </tr>
                          </thead>
                          <tbody className="text-sm">
                            <tr>
                              <td>
                                {this.state.confirmjv.poid !== undefined
                                  ? this.state.confirmjv.poid
                                  : "NA"}
                              </td>
                              <td className="text-right">
                                {this.state.confirmjv.creditname}
                              </td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={
                                    this.state.confirmjv.poadvance !== undefined
                                      ? this.state.confirmjv.poadvance
                                      : 0
                                  }
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* For Payment Table */}
                    {selectedval !== undefined ? (
                      <div className="form-row">
                        <div className="form-group col-md-6 float-left text-sm mt-0 mb-0">
                          <h6>
                            <span className="text-green">PO:</span>
                            {jv.poid !== "" ? jv.poid : "Direct Purchase"}
                          </h6>
                        </div>
                        {/* <div className="form-group col-md-6 float-right text-sm mt-0 mb-0"><h6><span className="text-green">GRN NO:</span>  {jv.selectedval.map((e)=>e.ggrnid)!==""?[...new Set(jv.selectedval.map((e) => { return e.ggrnid })),]:"Non GRN"}</h6></div> */}
                        <table className="table table-sm">
                          {selectedval.find((e) => e.grnid) !== undefined ? (
                            <>
                              <thead className="backgroundColour text-sm">
                                <tr>
                                  <td className="text-white">Item</td>
                                  {/* <td>GRN</td> */}
                                  {/* <td>PO</td> */}
                                  <td className="text-white">Supplier</td>
                                  <td className="text-white">Amount</td>
                                  <td className="text-white">Quantity</td>
                                  <td className="text-white">Tax</td>
                                  <td className="text-white text-center">
                                    Total
                                  </td>
                                </tr>
                              </thead>
                              <tbody className="text-sm">
                                {selectedval.map((e,i) => (
                                  <tr key={i}>
                                    <td>{e.name}</td>
                                    {/* <td>{e.ggrnid}</td> */}
                                    {/* <td>{e.poid}</td> */}
                                    <td>{jv.debitname}</td>
                                    <td>{e.unitprice}</td>
                                    <td>{e.quantity}</td>
                                    <td>{e.tax}</td>
                                    <td className="text-right">
                                      <NumericFormat
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        value={
                                          (e.unitprice * e.quantity * e.tax) /
                                            100 +
                                          e.unitprice * e.quantity
                                        }
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          ) : (
                            <>
                              <thead className="bg-info text-sm">
                                <tr>
                                  <td>Service</td>
                                  <td>SCN</td>
                                  <td>PO</td>
                                  <td>Supplier</td> <td>Amount</td>
                                  <td>Tax</td>
                                  <td>Total</td>
                                  <td>Completion</td>
                                </tr>
                              </thead>
                              <tbody className="text-sm">
                                {selectedval.map((e,i) => (
                                  <tr key={i}>
                                    <td>{e.scnname}</td>
                                    <td>{e.scnid}</td>
                                    <td>{jv.poid}</td> <td>{jv.debitname}</td>
                                    <td>{e.total}</td>
                                    <td>{e.tax}</td>
                                    <td>{(e.total * e.tax) / 100 + e.total}</td>
                                    <td>{e.percentage}%</td>
                                  </tr>
                                ))}
                              </tbody>
                            </>
                          )}
                        </table>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="form-row">
                      <table className="table table-sm">
                        <thead className="backgroundColour">
                          <tr>
                            <td className="text-white">Ledgers</td>
                            <td className="text-white text-right"> Debit </td>
                            <td className="text-white text-right">Credit</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{jv.debitname}</td>
                            <td className="text-right">
                              <NumericFormat
                                displayType={"text"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                value={jv.debitamt}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </td>
                            <td></td>
                          </tr>
                          {jv.gpdata !== undefined &&
                          jv.sdebitid !== null &&
                          jv.sdebitname !== undefined ? (
                            <tr>
                              <td>{jv.sdebitname}</td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.sdebitamt}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                              <td></td>
                            </tr>
                          ) : 
                            ""
                          }
                          {jv.surchargeid !== undefined &&
                          jv.surchargename !== undefined &&
                          (jv.surval !== undefined  || jv.surval !== 0 )? (
                            <tr>
                              <td>{jv.surchargename}</td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.surval}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                              <td></td>
                            </tr>
                          ) : 
                            ""
                          }
                          {jv.penaltyid !== undefined &&
                          jv.penaltyname !== undefined &&
                          jv.penval !== undefined ? (
                            <tr>
                              <td>{jv.penaltyname}</td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.penval}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                              <td></td>
                            </tr>
                          ) : 
                            ""
                          }
                          {jv.gst !== undefined &&
                         (jv.taxval1 !== undefined || jv.taxval1 !== 0)&&
                          jv.jvtype === 0 ? (
                            <tr>
                              <td>{jv.gst.name}</td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.taxval1}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                              <td></td>
                            </tr>
                          ) : 
                            ""
                          }
                          {jv.igst !== undefined &&
                         (jv.itaxval !== undefined ||  jv.itaxval !== 0) &&
                          jv.jvtype === 0 ? (
                            <tr>
                              <td>{jv.igst.name} </td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.itaxval}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                              <td></td>
                            </tr>
                          ) : 
                            ""
                          }
                          {jv.cgst !== undefined &&
                          (jv.taxval2 !== undefined  || jv.taxval2 !== 0 )&&
                          jv.jvtype === 0 ? (
                            <tr>   
                              <td>{jv.cgst.name} </td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.taxval2}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                              <td></td>
                            </tr>
                          ) : 
                            ""
                          }
                          {jv.vat !== undefined  &&
                          ( jv.vatval !== 0)&&
                          jv.jvtype === 0 ? (
                            <tr>

                              <td>{jv.vat.name}</td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.vatval}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                              <td></td>
                            </tr>
                          ) : 
                            ""
                          }
                {!jv.openadjusted && jv.openadjusted!==undefined && jv.openadjusted!==0 ?
                jv.openadjusted!==null?<>
                            <td>Adjusted Opening Balance</td>
                            <td></td>
                            <td className="text-right">
                              <NumericFormat
                                displayType={"text"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                value={jv.openadjusted}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </td></>:"":""}
                          <tr>
                            <td>{jv.creditname}</td>
                            <td></td>
                            <td className="text-right">
                              <NumericFormat
                                displayType={"text"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                value={jv.creditamt}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </td>
                          </tr>
                          {(jv.taxval1 !== undefined) & (jv.jvtype === 1) ? (
                            <tr>
                             
                              <td>
                                {jv.gst.name}@ {jv.gstpercent}%
                              </td>
                              <td></td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.taxval1}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {(jv.itaxval !== undefined) & (jv.jvtype === 1) ? (
                            <tr>
                             
                              <td>
                                {jv.igst.name} @ {jv.igstpercent}%
                              </td>
                              <td></td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.itaxval}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {(jv.taxval2 !== undefined) & (jv.jvtype === 1) ? (
                            <tr>
                             
                              <td>
                                {jv.cgst.name} @ {jv.cgstpercent}%
                              </td>
                              <td></td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.taxval2}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {(jv.vatval !== undefined) & (jv.jvtype === 1) ? (
                            <tr>
                             
                              <td>
                                {jv.vat.name} @ {jv.vatpercent}%
                              </td>
                              <td></td>
                              <td className="text-right">
                                <NumericFormat
                                  displayType={"text"}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  value={jv.vatval}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                            </tr>
                          ) : (
                            ""
                          )}
                          {jv.invoicefile === "" ? (
                            ""
                          ) : (
                            <tr>
                              <td>
                                <span className="textColour text-bold">
                                  Supplier Invoice :
                                </span>
                                <span
                                  as="button"
                                  className="text-blue btn btn-link text-sm mt-0 mb-0"
                                  onClick={this.download}
                                >
                                  {jv.invoicefile}
                                </span>
                              </td>
                              <td></td>
                              <td></td>
                            </tr>
                          )}
                          <tr>
                            <td>
                              <span className="textColour text-bold">
                                Amount In Words :
                              </span>
                              {amtwords}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <span className="textColour text-bold">
                                Narration :
                              </span>
                              {jv.narration}
                            </td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* <hr/>  */}

                    <div>
                      <div className="form-row">
                        <fieldset className="form-group col-md-12">
                          <button
                            className="btn hovbuttonColour mr-1 btn-sm"
                            type="submit"
                          >
                            <i className="fas fa-pen"></i>
                            {this.state.loader === true ? (
                              <>
                                <Spinner animation="border" size="sm" />
                                &nbsp;{"Confirm"}
                              </>
                            ) : (
                              "Confirm"
                            )}
                          </button>
                          {/* </fieldset>
                  <fieldset className="form-group col-md-2">  */}
                          <button
                            className="btn deletebutton btn-sm"
                            type="button"
                            onClick={this.goBack}
                          >
                           
                            <i className="fas fa-times" />
                            Close
                          </button>
                        </fieldset>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(JournalConfirm);
