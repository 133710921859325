import { FormControl, FormGroup, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { Form, Field, Formik } from "formik";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import MasterService from "../MasterService";
import FormLabel from "@mui/material/FormLabel";
import { Spinner } from "react-bootstrap";
// import { CheckboxWithLabel } from "formik-material-ui";

class NewRoles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id:this.props===undefined || this.props.location.state === undefined?'':this.props.location.state.id,
      role: "",
      ProductsList: [],
      product: "",
      productid: "",
      screenList: [],
      AddedScreens: [],
      appmaster: [],
      curraddscr:[],
      loading: false,
      loader:false,
      message:'',
      errormsg:'',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.addscreens = this.addscreens.bind(this)
  }

  componentDidMount() {
    if (userid === undefined) {
      this.props.navigate(`/`);
    }
    this.setState({loading:true})
    MasterService.getProductsScreens(currentTenant).then((response) => {
      this.setState({ ProductsList: response.data.pelist, screenList: response.data.screenslist, loading: false });
    });
    const id = this.props===undefined||this.props.location.state===undefined?'':this.props.location.state.id;
    if(id>-1){
    MasterService.getRolesScreens(id).then(res=>
      this.setState({roles:res.data,AddedScreens:res.data.screenslist.map(e=>Object.assign(e,{pid:e.productid})),role:res.data.name})
      )
    }
  }

  handleChange = (e) => {
    let value = e.target.value
    this.setState({ product: value, productid: this.state.ProductsList.find(e => e.products === value).id ,message:'',errormsg:''});
  };

  addscreens = (values) => {
    if(values.screens!==undefined){
    let a = this.state.screenList.filter(e => e.pid === this.state.productid)
    let b = this.state.AddedScreens.filter(e=>e.pid !== this.state.productid)
    a.filter(e =>values.screens.includes(e.screenname) ?
        // this.state.AddedScreens.find(c => c.name === e.name) ? '' :  
          b.push(Object.assign(e,{screenname:e.screenname,screenid:e.screenid})) : ''
    )

    this.setState({AddedScreens:b,message:`Screens added for product - ${this.state.product} :`,curraddscr:values.screens,errormsg:''},()=>console.log(this.state.AddedScreens))
    }else{
      this.setState({AddedScreens:this.state.AddedScreens.filter(e=>e.pid !== this.state.productid)})
    }
  }

  RemoveScreen=(s)=>{
    this.setState({AddedScreens:this.state.AddedScreens.filter(e=>e.screenname !== s)})
  }

  onSubmit() { 
    this.setState({loader:true})
    let data={
      id:this.state.id,
      name:this.state.role.toUpperCase(),
      screenslist:this.state.AddedScreens,
      tenantid:currentTenant
    }
    if(this.state.id === -1){
    MasterService.addRole(data).then(()=>
    this.props.navigate(`/roles`)
    )
    .catch((e)=>{
      this.setState({loader:false,errormsg:e.response.data.message})
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
    })
  }else{
    MasterService.UpdateRole(data).then(()=>
    this.props.navigate(`/roles`)
    )
    .catch((e)=>{
      this.setState({loader:false,errormsg:e.response.data.message})
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
    })
  }
  } 
  validate(values) {
    let errors = {};

    if (this.state.role === "") {
      errors.role = "Please Enter Role Name";
    }
    return errors;
  }
  render() {
    let { role, product, productid, ProductsList, screenList } = this.state;
    return (
      <div>
      <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
        
            <ol className="breadcrumb float-sm-right">
            <Link className="text-secondary" to={{ pathname: "/roles"}} state= {{ message: ""}}>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/roles">Roles</Link>
              </li>
              <li className="breadcrumb-item active">New Roles</li>
            </ol>
            <div className="card">
              
              {/* <div>
                <Link
                  as="button"
                  className="float-left btn btn-sm bg-white shadow-sm ml-2"
                  to={{ pathname: "/roles" }}
                >
                  <i className="fas fa-arrow-left" /> Back
                </Link>
              </div> */}
              
              <div className="card-body">
                <div className="text-center">
                  {this.state.loading === true ? (
                    spin
                  ) : (
                    <Formik
                      initialValues={{ role, product, productid, ProductsList, screenList, }}
                      onSubmit={this.onSubmit}
                      validateOnChange={false}
                      validate={this.validate}
                      enableReinitialize={true}
                    >
                      {({ values }) => (
                        <Form autoComplete="off">
                          <div className="form-row">
                            <div className="form-group col-md-6">
                              <fieldset className="form-group col-md-8">
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="role"
                                  value={this.state.role}
                                  label="Role Name"
                                  variant="standard"
                                  onChange={(e) =>
                                    this.setState({
                                      role: e.target.value,message:'',errormsg:''
                                    })
                                  }
                                  disabled={this.state.id!==-1}
                                  inputProps={{ style: { textTransform: "uppercase" } }}
                                  fullWidth
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-8">
                                <FormControl  sx={{minWidth: 120 }} className="col-md-12">
                                  <InputLabel id="demo-simple-select-filled-label">Products</InputLabel>
                                  <Select value={this.state.product}   variant="standard" onChange={this.handleChange} >
                                    {ProductsList.map((option,i) => {
                                      return (
                                        option.products ==="auth"?'':
                                        <MenuItem key={i} value={option.products}>
                                          {option.products}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </fieldset>
                              {this.state.message===''|| this.state.message===undefined?'':<>
                              <div className="form-group col-md-8 float-left">
                                {this.state.message}
                                </div>
                                <div className="form-group col-md-8 float-left">
                                {this.state.curraddscr.toLocaleString()}
                                </div>
                                </>}
                            </div>
                            {this.state.productid === ''?'':
                            <div className="form-group col-md-6">
                              <FormControl component="fieldset" style={{ display: "flex" }}>
                                <FormLabel component="legend" className="text-left">Screens</FormLabel>
                                <FormGroup>
                                  {screenList.map((opt,i) => (
                                    opt.pid === this.state.productid ?
                                      <div className="d-flex p-1" key={i}>
                                        <Field
                                          className="mt-1"
                                          type="checkbox"
                                          name="screens"
                                          key={opt.screenname}
                                          value={opt.screenname}
                                          label={{ label: opt.screenname }}
                                          style={{width:'17px',height:'17px'}}
                                        />
                                        <div className="ml-3" component="legend">{opt.screenname}</div>
                                      </div>
                                      : ''
                                  ))}

                                </FormGroup>
                              </FormControl>
                              <span as="button" className="btn btn-sm hovbuttonColour float-left" onClick={() => this.addscreens(values)}>+ Add</span>
                              <br></br>
                            </div>
                          }
                          </div>
                          {this.state.AddedScreens.length === 0?'':
                          <div>
                            <Table className="table table-sm">
                              <TableHead>
                                <TableRow>
                                  <TableCell className="text-center">Product</TableCell>
                                  <TableCell className="text-center">Screen</TableCell>
                                  <TableCell className="text-center">Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.AddedScreens.map((e,i) =>
                                  <TableRow key={i}>
                                    <TableCell className="text-center">{e.product}</TableCell>
                                    <TableCell className="text-center">{e.screenname}</TableCell>
                                    <TableCell className="text-center cursorPointer" onClick={()=>this.RemoveScreen(e.screenname)}><i className="fa fa-trash text-red"/></TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                            <fieldset className="form-group col-md-12 text-red">{this.state.errormsg}</fieldset>
                            
                            <fieldset className="form-group col-md-12">
                        
                        {this.state.loader === true ? (
                          <button className="btn btn-sm hovbuttonColour mr-1" disabled>
                            <Spinner animation="border" size="sm" />
                            &nbsp;{"Saving"}
                          </button>
                        ) : (
                          <button className="btn btn-sm hovbuttonColour mr-1" type="submit"><i className="fas fa-save mr-2"/>Save</button>
                        )}
                      <Link
                        className="btn btn-sm deletebutton"
                        to={{
                          pathname: "/roles"}}
                          state= {{ message: ""}}
                      >
                        <b className="text-danger"> X </b>Close
                      </Link>
                    </fieldset>
                          </div>
                        }
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(NewRoles);
