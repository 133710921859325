import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { FormikTextField } from '../../CommonComponents/FormField.js';
import WarehouseServices from './WarehouseServices.js';
import { Spinner } from 'react-bootstrap';


class NewWarehouse extends Component {
  constructor(props) {
    super(props)
    console.log(this.props)
    this.state = {
      pagename:this.props.location.state.pagename,
      id:this.props===undefined||this.props.location.state===undefined||this.props.location.state.id===undefined?'':this.props.location.state.id,
      name: '',
      address:'',
      loader:false,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(values) {
     this.setState({loader:true})
    if(this.state.pagename==='New Org Unit'){
      let addwarehouse = { 
        name:values.name,
        address:values.address,
        tenantid: currentTenant
      }
    WarehouseServices.addwarehouse(addwarehouse).then(() => 
    this.props.navigate('/warehouse'))
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }else{
    let addwarehouse = { 
      id:values.id,
      name:values.name,
      address:values.address,
      tenantid: currentTenant
    }
  WarehouseServices.EditWarehouse(addwarehouse).then(() => 
    this.props.navigate('/warehouse'))
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }
  }

  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }

    if(this.props.location.state.id!==-1){
      WarehouseServices.getWarehouse(this.props.location.state.id).then(res=>{
        this.setState({name:res.data.name,address:res.data.address})
      })
    }
 }


  validate(values) {

    let errors = {}

     if (!values.name|| values.name==='') {
     errors.name = "Org Unit Name is Required";
      }
    if (!values.address || values.address === '') {
      errors.address = 'Org Unit Address is Required';
    }
    return errors;
  }

  render() {
     let {id,name,address} = this.state;
 
    return (
      
      <div>
      <Header />
         <Menu/>
        <div className="content-wrapper">
          <div className="card">
            
            
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/warehouse"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/warehouse">Org Unit</Link>
              </li>
              <li className="breadcrumb-item active"> {this.state.pagename} </li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">{this.state.pagename}</h3>
            </div>

            <div className="card-body">
            
              <Formik
                initialValues={{id,name,address}}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}>
                {() => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field className="form-control" type="text" name="id" placeholder="Id"/>
                      </fieldset>

                      <fieldset className="form-group col-md-6">
                      <FormikTextField className="form-control"  type="text" name="name" label="Org Unit Name" onChange={(e)=>this.setState({name:e.target.value})}/>
                      </fieldset>
                      <fieldset className="form-group col-md-6">
                      <FormikTextField className="form-control"  type="text" name="address" label="Org Unit Address" onChange={(e)=>this.setState({address:e.target.value})}/>
                      </fieldset>

                    </div>
                    <div className="form-group col-md-12"/>
                    {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>: <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fas fa-save mr-2"/>Save</button>}
                    <Link as="button" className="btn btn-sm deletebutton ml-1" to={{pathname: '/warehouse'}} ><b style={{color:"#FA5733"}}> X</b> Cancel </Link> 
                  
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

      <Footer/>
      </div>
    );
  }
}
export default withRouter(NewWarehouse)