import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant } from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import { Spinner } from "react-bootstrap";
// import * as XLSX from 'xlsx';
// import brand from '../../Excel Templets/Brand.xlsx'
import CustomerService from "../Customers/CustomerService.js";
import UsercustomerService from "./UsercustomerService.js";
import { Autocomplete } from "@mui/material";
import UserService from "../../users/UserService.js";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class Usercustomers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.location.state.id,
      mappeddata:props.location.state.mappeddata,
      UsersData: [],
      user: "",
      userid: "",
      customersData: [],
      customer: "",
      customerid: "",
      customerslist: [],
      customerslist2: [],
      message: "",
      loader: false,
      type: false,
      err:false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleCustomerChange = (event) => {
    const {
      target: { value },
    } = event;
    this.setState({
      customerslist: typeof value === "string" ? value.split(", ") : value,
    });
    let data = [];
    this.state.customersData.map((val) =>
      value.includes(val.companyname) ? data.push(val) : ""
    );
    this.setState({ customerslist2: data });
  };

  removeCustomer = (val, i) => {
    this.setState({
      customerslist2: this.state.customerslist2.filter(
        (e, index) => index !== i
      ),
      customerslist: this.state.customerslist.filter((e) => e !== val),
    });
  };

  handleUserChange = (e, value) => {
    if (value === "" || value === null || value === undefined) {
      this.setState({ userid: "", user: "" });
    } else {
      this.setState({ userid: value.id, user: value.first + " " + value.last });
    }
  };

  handleCustomer = (value, values) => {
    if (value === "" || value === null || value === undefined) {
      this.setState({ customerid: "", customer: "" });
    } else {
      this.setState({ customerid: values.id, customer: values.companyname });
    }
  };

  handleAddcustomer = () => {
    if (
      this.state.customerslist.length !== 0 &&
      this.state.customerslist
        .map((e) => e.customerid)
        .includes(this.state.customerid)
    ) {
    } else {
      this.state.customerslist.push({
        customer: this.state.customer,
        customerid: this.state.customerid,
      });
    }
  };
  onSubmit(values) {
    this.setState({ loader: true });
    let customersidlist = this.state.customersData.filter(e=>this.state.customerslist.includes(e.companyname))
    customersidlist = customersidlist.map(e=>e.id)
    console.log(customersidlist)
    let data = {
      id: this.state.id,
      userid: this.state.userid,
      username: this.state.user,
      customers: customersidlist.toString(),
      createdby: userid,
      updatedby: userid,
      tenantid: currentTenant,
    };
    if (this.state.id === -1) {
      UsercustomerService.AddUsercustomers(data)
        .then(() => this.props.navigate("/usercustomers"))
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
          this.setState({ message: e.response.data.message, loader: false });
        });
    } else {
      UsercustomerService.UpdateUsercustomers(data)
        .then(() => this.props.navigate("/usercustomers"))
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
          this.setState({ message: e.response.data.message, loader: false });
        });
    }
  }

  componentDidMount() {
    const id = this.props.location.state.id;
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    UserService.retriveAllUsers().then((response) => {
      let mappedusers = this.props.location.state.mappeddata.map(e=>e.userid)
      this.setState({ UsersData: response.data.filter(e=>!mappedusers[0]?.includes(e.id)) });
    });
    CustomerService.retriveAllCustomers(currentTenant).then((res) => {
      let mappedcustomers = this.props.location.state.mappeddata.map(e=>e.customers.split(","))
      this.setState({ customersData: res.data.filter(e=>!mappedcustomers[0]?.includes(e.id)) }, () => {
        if (id !== -1) {
          UsercustomerService.GetUserCustomersbyId(id).then((res) => {
            res.data.customers
              .split(",")
              .map((e) => this.state.customerslist2.push({ companyname: e }));
            this.setState({
              userid: res.data.userid,
              user: res.data.username,
              customerslist: res.data.customers.split(","),
              customerslist2: [...this.state.customerslist2],
            });
          });
        }
      });
    });
  }

  validate(values) {
    let errors = {};
    if (this.state.userid === "" || this.state.userid === undefined) {
        this.setState({err:true})
      errors.user = "User is Required";
    }else{
        this.setState({err:false})
    }

    if (this.state.customerslist.length === 0) {
      errors.customer = "Select Atleat One Customers";
    }

    return errors;
  }

  render() {
    let {
      id,
      UsersData,
      user,
      userid,
      customersData,
      customer,
      customerid,
      customerslist,
    } = this.state;

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <Link
                  as="button"
                  className=" text-secondary"
                  to={{ pathname: "/usercustomers"}} state= {{ message: ""  }}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/usercustomers">User-Customer</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.id===-1?"New User-Customer":"Edit User-Customer"}</li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">{this.state.id===-1?"New User-Customer":"Edit User-Customer"}</h3>
            </div>
            <div className="card-body">
              {/* <fieldset className="form-group col-md-12">
                          <fieldset
                                  className={`form-group col-md-3 shadow-sm  text-center btn ${
                                    this.state.type === false
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === false}
                                  onClick={() => this.setState({type:false,usercustomers:[]})}
                                >
                                  Add manualy
                                </fieldset>
                                <fieldset
                                  className={`form-group col-md-3  btn shadow-sm text-center ${
                                    this.state.type === true
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === true}
                                  onClick={() => this.setState({type:true,brand:''})}
                                >
                                  By upload
                                </fieldset>
                             
                              </fieldset> */}

              {/* {this.state.type===false? */}
              <Formik
                initialValues={{
                  id,
                  UsersData,
                  user,
                  userid,
                  customersData,
                  customer,
                  customerid,
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {(values) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset className="form-group col-md-3">
                        <Autocomplete
                          id="toname"
                          className="subacc-select"
                          name="toname"
                          options={UsersData}
                          getOptionLabel={(option) =>
                            option.first +
                            " " +
                            option.last +
                            "(" +
                            option.id +
                            ")"
                          }
                          
                          value={values.to ? undefined : undefined}
                          onChange={this.handleUserChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={`Select User (${this.state.user})`}
                              type="text"
                              value={this.state.user}
                              variant="standard"
                              error={user === "" && this.state.err}
                              helperText={user === "" && this.state.err ? "Empty field!" : " "}
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      {/* <fieldset className="form-group col-md-3 mb-4">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="customer"
                                  options={customersData}
                                  getOptionLabel={(option) => option.companyname}
                                  renderOption={(option) => option.companyname}
                                  getOptionSelected={(option) => option.companyname}
                                  value={values.customer ? undefined : undefined}
                                  onChange={ this.handleCustomer}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="customer"
                                      value={this.state.customer}
                                      label={`Select Customer (${this.state.customer})`}
                                      variant="standard"
                                      fullwhidth="true"
                                    />
                                  )}
                                />
                              </fieldset> */}
                      {/* <fieldset className="form-group col-md-6"> */}
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel id="demo-multiple-checkbox-label">
                          Customers
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={customerslist}
                          onChange={this.handleCustomerChange}
                          input={<OutlinedInput label="Customers" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          {customersData.map((e, i) => (
                            <MenuItem id={e.id} key={i} value={e.companyname}>
                              <Checkbox
                                id={e.id}
                                checked={
                                  customerslist.indexOf(e.companyname) > -1
                                }
                              />
                              <ListItemText primary={e.companyname} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {/* </fieldset> */}
                      <fieldset className="form-group col-md-12">
                        {this.state.customerslist2.length === 0 ? (
                          <p className="">No Customers Avilable</p>
                        ) : (
                          <table className="table table-sm">
                            <thead>
                              <tr>
                                <th>#Id</th>
                                <th>Customer</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.customerslist2.map((val, i) => (
                                <tr key={i}>
                                  <td>{i + 1}</td>
                                  <td>{val.companyname}</td>
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-sm"
                                      onClick={() =>
                                        this.removeCustomer(val.companyname, i)
                                      }
                                    >
                                      Remove
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </fieldset>

                      {/* <fieldset className="form-group col-md-12">
                              <button className="btn btn-sm hovbuttonColour col-md-1 col-sm-12" onClick={this.handleAddcustomer} type="button"><i className="fas fa-save mr-2"/>Save</button>
                              </fieldset> */}
                    </div>

                    {this.state.loader === true ? (
                      <button
                        className="btn btn-sm hovbuttonColour col-md-1 col-sm-12"
                        disabled
                      >
                        <Spinner animation="border" size="sm" />
                        &nbsp;{"Saving"}
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm hovbuttonColour col-md-1 col-sm-12"
                        type="submit"
                      >
                        <i className="fas fa-save mr-2" />
                        Save
                      </button>
                    )}
                    <Link
                      as="button"
                      className="btn btn-sm deletebutton ml-1 col-md-1 col-sm-12"
                      to={{ pathname: "/usercustomers" }}
                    >
                      <b style={{ color: "#FA5733" }}> X</b> Cancel
                    </Link>
                  </Form>
                )}
              </Formik>

              {/* :
              <div className="form-row ml-2">
                        <fieldset className="form-group col-md-12">
                          <label className="text-red">Note: The Excel Sheet must contain only one column with name 'Brand', accepts only ".xlsx, .xls, .csv"</label>
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        <label>Download Excel format &nbsp;&nbsp;&nbsp;</label>
                      <button className="btn btn-sm hovbuttonColour shadow-sm fa fa-file-excel text-md" type='buton' data-tip data-for="pdf" onClick={this.downloadExcel}>&nbsp;Download</button>
                        </fieldset>
                        <fieldset className="form-group col-md-12 m-0"></fieldset>
                            <fieldset className="form-group col-md-1">
                            <label>Upload here </label>
                            </fieldset>
                            <fieldset className="form-group col-md-4">
                              <label htmlFor="btn-upload">
                                <input
                                  className="btn-choose "
                                  id="btn-upload"
                                  name="btn-upload"
                                  type="file"
                                  onChange={e=>{
                                    if(e.target.files[0] !== undefined){
                                    const file = e.target.files[0];
                                    this.readExcel(file)
                                    }
                                  }}
                                  accept=".xlsx, .xls, .csv"
                                />
                              </label>
                            </fieldset>
                            <fieldset className="form-group col-md-12">
                              {this.state.usercustomers.length === 0?'':<label>Brands({this.state.usercustomers.length}):&nbsp;</label>}
                                    {this.state.usercustomers.map((e,i)=>
                                        <span key={i}>{i+1}.{e}{this.state.usercustomers.length-1===i?'':', '}</span>
                                    )}
                            </fieldset>

                            {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>: <Link as='buton' className="btn btn-sm hovbuttonColour" onClick={this.uploadBrands}><i className="fas fa-save mr-2"/>Save</Link>}
                    <Link as="button" className="btn btn-sm deletebutton ml-1"  to={{pathname: '/usercustomers'}} ><b style={{color:"#FA5733"}}> X</b> Cancel</Link> 
                  
                          </div>
  }    */}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default withRouter(Usercustomers);
