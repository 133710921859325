import { FormControl, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, userid } from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import MasterService from "../MasterService.js";
import SupplierService from "./SupplierService.js";

class NewSupplier extends Component {
  constructor(props) {
    super(props);

    const { name } = this.props.location.state;
    this.state = {
      // type: 'password',
      name: name,
      id: "",
      StateId: "",
      state:'',
      CountryId: "",
      country:'',
      city:'',
      CityId: "",
      message: "",

      countryprefix: ["91 IND", "93 AFG"],

      CountryData: [],
      StateData: [],
      CityData: [],
      statecode:'',
      item: [],
      customsupplierid:'',
      companyname: "",
      contactname: "",
      prefix: "",
      contactnumber: "",
      email: "",
      address: "",
      address2: "",
      pan: "",
      gstin: "",
      vat:'',
      zip: "",
      error: "",
      countries: "",
      states: "",
      cities: "",
      alternatenumber:'',
      phonecode:'',
      loader: false,
      currencylist:[],
      currency:'',
      currencyid:'',
      taxtype:'',
      TaxtypeData:[],
      registrationtype:'Unregistered',
      Registrationtypes:[{"id":1,"type":"Registered"},{"id":2,"type":"Unregistered"},{"id":3,"type":"Composition"},{"id":4,"type":"SEZ (Special economic zone)"}]
      //[{0:"Registered"},{1:"Unregistered"},{2:"Composition"},{3:"SEZ (Special economic zone)"}]
    };
    this.onSubmit = this.onSubmit.bind(this);
    //  this.handleChange=this.handleChange.bind(this)
    this.validate = this.validate.bind(this);
    // this.ChangeteState = this.ChangeteState.bind(this);
    // this.ChangeState = this.ChangeState.bind(this);
  }
  

  onSubmit(values) {
    this.setState({ loader: true });
    let newSupplier = {
      companyname: this.state.companyname,
      contactname: this.state.contactname,
      contactnumber: this.state.contactnumber.replace(/[^0-9]+/ig, "") ,
      email: this.state.email,
      address: this.state.address,
      address2: this.state.address2,
      pan: this.state.pan,
      gstin: this.state.gstin,
      zip: this.state.zip,
      country:this.state.CountryId,
      countryname:this.state.country,
      state:this.state.StateId,
      statename:this.state.state,
      city:this.state.CityId,
      cityname:this.state.city,
      statecode:this.state.statecode,
      tenantId: currentTenant,
      createdby: userid,
      alternatenumber:this.state.alternatenumber===undefined || this.state.alternatenumber===null?'':this.state.alternatenumber.replace(/[^0-9]+/ig, ""),
      currency:this.state.currency,
      phonecode:this.state.phonecode,
      taxtype:this.state.taxtype,
      registrationtype:this.state.registrationtype
    };

    let updateSupplier = {
      id: this.state.id,
      companyname: this.state.companyname,
      contactname: this.state.contactname,
      contactnumber: this.state.contactnumber.replace(/[^0-9]+/ig, "") ,
      email: this.state.email,
      address: this.state.address,
      address2: this.state.address2,
      pan: this.state.pan,
      gstin: this.state.gstin,
      zip: this.state.zip,
      country:this.state.CountryId,
      countryname:this.state.country,
      state:this.state.StateId,
      statename:this.state.state,
      city:this.state.CityId,
      cityname:this.state.city,
      statecode:this.state.statecode,
      updatedby: userid,
      alternatenumber:this.state.alternatenumber===undefined || this.state.alternatenumber===null?'':this.state.alternatenumber.replace(/[^0-9]+/ig, "") ,
      customsupplierid:this.state.customsupplierid,
      currency:this.state.currency,
      phonecode:this.state.phonecode,
      taxtype:this.state.taxtype,
      registrationtype:this.state.registrationtype

    };
    if (this.state.id === "") {
      SupplierService.createSupplier(newSupplier)
        .then(() =>
        this.props.navigate({ pathname: "/suppliers"},{state:{message: values.companyname + "   : Created Successfully"}
          })
        )
        .catch((error) =>{
          if(error.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
          this.setState({
            error: error.response.data.message,
            loader: false,
          })
        }
        );
    } else {
      SupplierService.updateSupplier(updateSupplier).then(() =>
     // this.props.navigate({ pathname: "/suppliers"},{state:{message: values.companyname + "   : Updated Successfully"}
      this.props.navigate({pathname: "/suppliers"},{state:{message: values.companyname + "   : Updated Successfully"}

        })
      )
      .catch((e)=>{
        this.setState({loader:false})
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    }
  }

  handleCurrency = (value,values) => {
    if(values !== '' && typeof values === 'object' && values !==null){
      this.setState({currencyid:values.id, currency: values.currency }) 
    }else{
      this.setState({currencyid:'', currency: '' }) 
    }
  }

  handleTaxTypeChange=(e)=>{
    this.setState({taxtype:e.target.value}) 
  }

  handleRegistrationTypeChange=(e)=>{
    this.setState({registrationtype: e.target.value});
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    SupplierService.getCountries().then((response) =>
      this.setState({
        CountryData: response.data,
      })
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
    })

      MasterService.GetAllCurrency().then((response) => {
        this.setState({currencylist: response.data})
      });
      MasterService.getTaxtypes().then((response) =>
      this.setState({
        TaxtypeData: response.data,
      })
    );

    const { id } = this.props.location.state;

    //let {id} = this.props.location.state
    if (id < 1) {
      //  this.setState({id:id})
      return;
    }
    SupplierService.retriveSupplier(id).then((response) =>
      this.setState({
        id: response.data.id,
        companyname: response.data.companyname,
        contactname: response.data.contactname,
        prefix: response.data.prefix,
        alternatenumber:response.data.alternatenumber,
        contactnumber: response.data.contactnumber,
        email: response.data.email,
        address: response.data.address,
        address2: response.data.address2,
        pan: response.data.pan,
        gstin: response.data.gstin,
        zip: response.data.zip,
        country: response.data.countryname,
        state: response.data.statename,
        city:response.data.cityname,
        CountryId: response.data.country,
        StateId: response.data.state,
        CityId: response.data.city,
        statecode:response.data.statecode,
        customsupplierid:response.data.customsupplierid,
        currency:response.data.currency,
        phonecode:response.data.phonecode,
        taxtype:response.data.taxtype,
        registrationtype:response.data.registrationtype
      })
    );
  }

  ChangeteCountry = (value, values) => {
    if(values!=='' && typeof values === 'object' && values!==null){
    this.setState({country:values.name, CountryId: values.id,phonecode:values.phonecode, state:'', StateId: '',city:'',CityId:'',CityData:[],statecode:'' });
    SupplierService.getStates(values.id).then((response) => {
      this.setState({
        StateData: response.data,
      });
    });
  }else{
    this.setState({
      country:'', CountryId:'',state :'',StateData:[],CityData:[], StateId: '',city:'',CityId:'',statecode:''
    })
  }
  };
  ChangeState = (value, values) => {
    if(values!=='' && typeof values === 'object' && values !== null){
    this.setState({state:values.name, StateId: values.id,statecode:values.code });
    SupplierService.getCities(values.id).then((response) => {
      this.setState({
        CityData: response.data,city:'',CityId:'',
      });
    });
   }else{
     this.setState({
    state:'', StateId: '',city:'',CityId:'',statecode:''
     })
   }
  };

  handleCity=(value,values)=>{
    if(values!=='' && typeof values === 'object' && values !== null){
    this.setState({city: values.name,CityId: values.id})
  }else{
    this.setState({city:'',CityId:''})
  }
  }

  validate(values) {
    let errors = {};
    // var chars = /^[A-Za-z ]+$/;
    var num = /^[0-9]+$/;
    if (this.state.companyname === '') {
      errors.companyname = "Company Name is required";
    }
    //  else if (!chars.test(this.state.companyname)) {
    //   errors.companyname = "Only Characters are allowed";
    // }
    if (this.state.contactname==='') {
      errors.contactname = "Contact Name is required";
    } 
    // else if (!chars.test(this.state.contactname)) {
    //   errors.contactname = "Only Characters are allowed";
    // }

    if (this.state.contactnumber==='') {
      errors.contactnumber = "Contact Number is required";
    } 
    else if(this.state.country === "India"){
      if (!/^[7-8-9][0-9]{9}$/i.test(this.state.contactnumber)) {
        errors.contactnumber = "Contact Number should be 10 Numbers";
      }
      }

    if (!this.state.email) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(this.state.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (this.state.address==='') {
      errors.address = "Address is required";
    }

    if (this.state.address2==='') {
      errors.address2 = "Area/Place is required";
    }
    
    if(this.state.country ==='India' && this.state.registrationtype==="Registered"){
    if (this.state.gstin===''||this.state.gstin===undefined) {
      errors.gstin = "GSTIN is required";
    } else if (!/^[A-Z0-9]{15}$/i.test(this.state.gstin)) {
      errors.gstin = "Invalid GST should be 15 Charactors EX: 22ABCDE1234F1Z5";
    } else if (
      !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z0-9]{1}/i.test(
        this.state.gstin
      )
    ) {
      errors.gstin = "Invalid Ex: 22ABCDE1234F1Z5";
    }
    // if(values.gstin.substring(0, 2) !== this.state.statecode){
    //   errors.gstin = `Invalid GSTIN for state ${this.state.state}`;
    // }
  }
  if(this.state.country ==='India'){
    if (this.state.zip===''|| this.state.zip===undefined) {
      errors.zip = "Zip is required";
    } else if (!num.test(this.state.zip)) {
      errors.zip = "Only Numbers allowed";
    } else if (!/^[0-9]{5,6}$/i.test(this.state.zip)) {
      errors.zip = "Should be 5 or 6 Numbers";
    }
  }else{
    if (this.state.zip === '') {
      errors.zip = "PO Box No. is required";
    }
  }
    if (this.state.CountryId === "") {
      errors.country = "Please Select Country";
    }
    if (this.state.StateId === "") {
      errors.state = "Please Select State";
    }
    if (this.state.CityId === "") {
      errors.city = "Please Select City";
    }

    if (this.state.currency === "") {
      errors.currency = "Please Select Currency";
    }

    return errors;
  }

  render() {
    // let countrypre = [this.state.countryprefix]

    let {
      id,
      companyname,
      contactname,
      contactnumber,
      email,
      address,
      address2,
      pan,
      gstin,
      zip,
      CountryData,
      StateData,
      CityData,
      country,
      state,
      city,
      statecode,
      alternatenumber,
      currency,
      currencylist,
      phonecode,
      taxtype,
      registrationtype
    } = this.state;


    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
           
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/suppliers"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to={{ pathname: "/suppliers"}} state={{ message: "" }}>
                  Suppliers
                </Link>
              </li>
              <li className="breadcrumb-item active">{this.state.name}</li>
            </ol>
            <div className="text-center text-danger">{this.state.error}</div>

            <div className="card-header">
              <h3 className="card-title">{this.state.name}</h3>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{
                  id,
                  companyname,
                  contactname,
                  contactnumber,
                  email,
                  address,
                  address2,
                  pan,
                  gstin,
                  zip,
                  CountryData,
                  StateData,
                  CityData,
                  country,
                  state,
                  city,
                  statecode,
                  alternatenumber,
                  currency,
                  currencylist,
                  phonecode,
                  taxtype,
                  registrationtype
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="id"
                          placeholder="Id"
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="companyname"
                          label="Company Name"
                          value={this.state.companyname}
                          onChange={(e) =>
                            this.setState({ companyname: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="contactname"
                          label="Contact Name"
                          value={this.state.contactname}
                          onChange={(e) =>
                            this.setState({ contactname: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="email"
                          label="Email Id"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="address"
                          label="Address"
                          value={this.state.address}
                          onChange={(e) =>
                            this.setState({ address: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="address2"
                          label="Area/Place"
                          value={this.state.address2}
                          onChange={(e) =>
                            this.setState({ address2: e.target.value })
                          }
                          
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="country"
                          options={CountryData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.country ? undefined : undefined}
                          onChange={this.ChangeteCountry}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="country"
                              value={this.state.country}
                              label={`Select Country (${this.state.country})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="country"
                          options={StateData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.state ? undefined : undefined}
                          onChange={this.ChangeState}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="state"
                              value={this.state.state}
                              label={`Select State (${this.state.state})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="city"
                          options={CityData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.city ? undefined : undefined}
                          onChange={this.handleCity}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name='city'
                              value={this.state.city}
                              label={`Select City (${this.state.city})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="zip"
                          label={this.state.country==="India"?"Zip/Pincode":"PO BOX No."}
                          value={this.state.zip}
                          onChange={(e) =>
                            this.setState({ zip: e.target.value })
                          }
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="contactnumber"
                          label="Contact Number"
                          value={this.state.contactnumber}
                          onChange={(e) =>
                            this.setState({ contactnumber: e.target.value.replace(/[^0-9]+/ig, "")})
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +{this.state.phonecode}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="alternatenumber"
                          label="Alternate Number"
                          value={this.state.alternatenumber}
                          onChange={(e) =>
                            this.setState({ alternatenumber: e.target.value.replace(/[^0-9]+/ig, "")})
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +{this.state.phonecode}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="currency"
                          options={currencylist}
                          getOptionLabel={(option) => option.currency}
                          
                          
                          value={values.currency?undefined:undefined}
                          onChange={this.handleCurrency}
                          renderInput={params => (  
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="currency"
                              value={this.state.currency}
                              label={`Select Currency (${this.state.currency})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                                <fieldset className="form-group col-md-4 m-0">
                                <FormControl variant="standard" className="w-100">
                                  <InputLabel id="demo-simple-select-standard-label">Tax Type</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={this.state.taxtype}
                                    onChange={this.handleTaxTypeChange}
                                    label="Tax Type"
                                  >
                                    {this.state.TaxtypeData.map((e,i)=>{
                                      return (
                                      <MenuItem value={e.type} key={i}>{e.type}</MenuItem>)
                                    })}
                                   
                                  </Select>
                                </FormControl>
                                </fieldset>

                                {/* <fieldset className="form-group col-md-12 m-0"/> */}
                                {/* <fieldset className="form-group col-md-3 mt-2">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={this.state.registrationtype}
                                          onChange={() =>
                                            this.setState({
                                              registrationtype: !this.state.registrationtype,
                                            })
                                          }
                                          name="ordertype"
                                          color="primary"
                                          // disabled={this.state.orderfrom === 1}
                                        />
                                      }
                                      label="Tax Registered?"
                                    />
                                  </fieldset> */}

                            {this.state.country==="India"?  
                                <fieldset className="form-group col-md-4 m-0">
                                <FormControl variant="standard" className="w-100">
                                  <InputLabel id="demo-simple-select-standard-label">Registration Type</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={this.state.registrationtype}
                                    onChange={(e)=>this.handleRegistrationTypeChange(e)}
                                    // onChange={(e) =>
                                    //   this.setState({
                                    //     registrationtype: e.target.value,
                                    //   })}
                                    label="Registration Type"
                                  >
                                    {this.state.Registrationtypes.map((e,i)=>{
                                      return (
                                        <MenuItem value={e.type} key={i}>{e.type}</MenuItem>)
                                      })}
                                   
                                  </Select>
                                </FormControl>
                                </fieldset>
                            :''}
                                  

                                {this.state.country==="India"?
                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="pan"
                          label="PAN"
                          value={this.state.pan}
                          onChange={(e) =>
                            this.setState({ pan: e.target.value })
                          }
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </fieldset>:''}

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="gstin"
                          label={this.state.country==="India"?"GSTIN":"GSTIN / VAT"}
                          value={this.state.gstin}
                          onChange={(e) =>
                            this.setState({ gstin: e.target.value,error:'' })
                          }
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </fieldset>


                    </div>
                    <div className="form-row">
                      {/* <fieldset className="form-group col-md-4">
                      {rendercountry}
                        <ErrorMessage name="country" component="div" className="text-danger float-sm-right" />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                       {renderstate}
                        <ErrorMessage name="state" component="div" className="text-danger float-sm-right" />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        {rendercity}
                        <ErrorMessage name="city" component="div" className="text-danger float-sm-right" />
                      </fieldset> */}

                      <fieldset className="form-group col-md-12" />
                      {/* <fieldset className="form-group col-md-4"/> */}
                      <fieldset className="form-group col-md-12">
                      {this.state.loader === true ? (
                            <button className="btn btn-sm hovbuttonColour" disabled>
                              <Spinner animation="border" size="sm" />
                              &nbsp;{"Saving"}
                            </button>
                          ) : (
                            <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fas fa-save mr-2"/>Save</button>
                          )}
                        {/* </fieldset>
                    <fieldset className="form-group col-md-1"> */}
                        <Link
                          as="button"
                          className="btn btn-sm  deletebutton ml-1 "
                          to={{pathname: "/suppliers"}}
                            state= {{ message: ""}}
                        >
                          <b className="text-danger"> X </b>Close
                        </Link>
                      </fieldset>
                    </div>

                    {/* <fieldset className="form-group col-md-12"/>
                   <Link as="button" type="submit" className="btn btn-success" to={{pathname: '/ledgers' }} state ={ {message:this.state.message}}}>Save</Link> 
                     */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

        <Footer />
      </div>
    );
  }
}
export default withRouter(NewSupplier);
