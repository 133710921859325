
export let bankres
export let branchres
export let bankifsc


class IfscFetch  {

getIfsc(res){

        //ifscdata=res 
        branchres=res.BRANCH
        bankres=res.BANK
        bankifsc=res.IFSC
       // console.log(res)
        // console.log(res.BRANCH)
        // console.log(res.BANK)
    }
   
    setIfsc(ifsccode){
    var ifsc = require('ifsc');
    ifsc.validate('KKBK0000261'); // returns true
    ifsc.validate('BOTM0XEEMRA'); // returns false

    ifsc.fetchDetails(ifsccode).then((res) => {
        this.getIfsc(res)
        // this.setState({ifscdata1:res.BANK})
    })
    .catch((e)=>{
        if(e.response ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })

    //ifscdata=this.state.ifscdata1
    //console.log(this.state.ifscdata1)
    }

}

export default new IfscFetch()





// import React from 'react'

// export let ifscdata

// export default function abcd(ifsccode) {
// var ifsc = require('ifsc');

// ifsc.validate('KKBK0000261'); // returns true
// ifsc.validate('BOTM0XEEMRA'); // returns false

// ifsc.fetchDetails(ifsccode).then(function(res) {
//     ifscdata=res
//    console.log(ifscdata);
// });
//     return (
//         <div>
            
//         </div>
//     )
// }
