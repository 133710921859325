import React, { useEffect, useState } from "react";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
import { spin } from "../../Common/NewLoader";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ProjectManagementServices from "../ProjectManagementServices";
import { FormLabel, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

const ProjectDetails = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoading(true);
    ProjectManagementServices.getProjectById(params.id)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [params]);

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
          <Link as="button" className=" text-secondary" to="/projects">
              ❮ Back &nbsp;&nbsp;
            </Link>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projectmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projects">Projects</Link>
            </li>
            <li className="breadcrumb-item active">Project Details</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              <div className="card-body">
                {loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <table className="float-left">
                        <tbody>
                          <tr>
                            <td>Project Name</td>
                            <td> : {data.projectName}</td>
                          </tr>
                          <tr>
                            <td>Project Code</td>
                            <td> : {data.projectCode}</td>
                          </tr>
                          <tr>
                            <td>Status</td>
                            <td> : {data.status}</td>
                          </tr>
                          <tr>
                            <td>Remarks</td>
                            <td> : {data.remark}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  <>
                    <div className="col-md-12">
                      <FormLabel>Project Managers :   <Link
                        as="button"
                        className="btn btn-sm hovbuttonColour mr-2"
                        to={{ pathname: "/addprojectmanager" }}
                        state={{
                          projectId: data.projectId,
                          projectName: data.projectName,
                          projectCode: "POO1",
                        }}
                      >
                        Add Project Manager
                      </Link> </FormLabel>
                      </div>
                      <div className="col-md-12 py-2">
                      {data.projectmanager !== undefined && data.projectmanager !== null && data.projectmanager.length>0?
                    <Table className="float-left table-border" size="small">
                      <TableHead>
                      <TableRow>
                            <TableCell>project Manager Name</TableCell>
                            <TableCell>Contact Number</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Action</TableCell>
                            </TableRow>
                      </TableHead>
                        <TableBody>
                    {data.projectmanager.map(e=>
                          <TableRow key={e.id}>
                            <TableCell>{e.managerName}</TableCell>
                            <TableCell>{e.managerContactNo}</TableCell>
                            <TableCell>{e.managerEmail}</TableCell>
                            <TableCell><i className="fa fa-trash text-danger"/></TableCell>
                          </TableRow>
                      )}
                        </TableBody>
                      </Table>
                      :""}
                    </div>
                    </>

                    <div className=" col-md-12">
                    <div className="col-md-12">
                      <FormLabel>client Assign :   <Link
                        as="button"
                        className="btn btn-sm hovbuttonColour mr-2"
                        to={{ pathname: "/assignclient" }}
                        state={{
                          projectId: data.projectId,
                          projectName: data.projectName,
                          projectCode: "POO1",
                        }}
                      >
                        Assign Client
                      </Link> </FormLabel>
                      </div>
                      <div className="col-md-12 py-2">
                      {data.clientassign !== undefined && data.clientassign !== null && data.clientassign.length>0?
                    <Table className="float-left table-border" size="small">
                      <TableHead>
                      <TableRow>
                            <TableCell>Client Name</TableCell>
                            <TableCell>Contact Number</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Action</TableCell>
                            </TableRow>
                      </TableHead>
                        <TableBody>
                    {data.clientassign.map(e=>
                          <TableRow key={e.id}>
                            <TableCell>{e.customerName}</TableCell>
                            <TableCell>{e.customerContactNo}</TableCell>
                            <TableCell>{e.customerEmailId}</TableCell>
                            <TableCell><i className="fa fa-trash text-danger"/></TableCell>
                          </TableRow>
                      )}
                        </TableBody>
                      </Table>
                      :""}
                    </div>
                    </div>
                    
                    <div className="col-md-12">
                      <FormLabel>Sites :   <Link
                        as="button"
                        className="btn btn-sm hovbuttonColour mr-2"
                        to={{ pathname: "/addsite" }}
                        state={{
                          projectId: data.projectId,
                          projectName: data.projectName,
                          projectCode: "POO1",
                        }}
                      >
                        Add Site
                      </Link> </FormLabel>
                      </div>
                      <div className=" col-md-12">
                      {data.site !== undefined && data.site !== null && data.site.length>0?
                    data.site.map(e=>
                      <div key={e.id}>
                        <div className="">
                    <table  className="col-md-12">
                        <tbody>
                          <tr>
                            <td>Site Name</td>
                            <td> : {e.siteName}</td>
                          </tr>
                          <tr>
                            <td>Site Code</td>
                            <td> : {e.siteCode}</td>
                          </tr>
                          <tr>
                            <td>Status</td>
                            <td> : {e.status}</td>
                          </tr>
                          <tr>
                            <td>Site Address</td>
                            <td> : {e.address}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>   {e.city}, {e.state}, {e.country}</td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      <div className="col-md-12">
                      <FormLabel>Sites Incharge:   <Link
                        as="button"
                        className="btn btn-sm hovbuttonColour mr-2"
                        to={{ pathname: "/addsiteincharge" }}
                        state={{
                          projectId: data.projectId,
                          projectName: data.projectName,
                          projectCode: "POO1",
                          siteId: e.id,
                          siteName: e.siteName,
                        }}
                      >
                        Add Site InCharge
                      </Link> </FormLabel>
                      </div>
                      {e.siteIncharges !== undefined && e.siteIncharges !== null && e.siteIncharges.length>0?
                      <div className="col-md-12">
                    <Table className="float-left table-border" size="small">
                      <TableHead>
                      <TableRow>
                            <TableCell>project Manager Name</TableCell>
                            <TableCell>Contact Number</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Action</TableCell>
                            </TableRow>
                      </TableHead>
                        <TableBody>
                    {e.siteIncharges.map(si=>
                          <TableRow key={si.id}>
                            <TableCell>{si.inchargeEmail}</TableCell>
                            <TableCell>{si.inchargeContactNo}</TableCell>
                            <TableCell>{si.inchargeEmail}</TableCell>
                            <TableCell><i className="fa fa-trash text-danger"/></TableCell>
                          </TableRow>
                      )}
                        </TableBody>
                      </Table>
                      </div>
                      :""}

                      </div>
                    )
                      :""}
                    </div>
                    <div className="col-md-12">
                     
                      <button
                        type="button"
                        className="btn btn-sm hovbuttonColour mr-2"
                        //   onClick={}
                        disabled={loader}
                      >
                        Submit
                      </button>
                      <Link
                        as="button"
                        className="btn btn-sm deletebutton"
                        to={{ pathname: "/customers" }}
                        state={{ message: "" }}
                      >
                        <b className="text-danger"> X </b>Close
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectDetails;
