import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { FormikTextField } from '../../CommonComponents/FormField.js';

import InventoryService from '../../inventory/InventoryService.js';
import { Autocomplete, Backdrop, CircularProgress } from '@mui/material';
import { Spinner } from 'react-bootstrap';
import Swal from 'sweetalert2';


class AddCost extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loader:false,
      pagename:this.props.location.state === undefined?'':this.props.location.state.pagename,
      id:this.props.location.state === undefined?'':this.props.location.state.id,
      cid:'',
      name:'',
      cost:'',
      uom:'',
      itemsList: [],
      dataList:[],
      filterlist:'',
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleitemname = (value,values) => {
    if(values !== '' && typeof values === 'object' && values!==null){
    this.setState({ id: values.id, name: values.name,uom:values.uomname,filterlist:values});
    }else{
      this.setState({ id:'', name:'',uom:'',filterlist:''});
    }
  };  

  onSubmit(values) {
    this.setState({loader:true})
     const id = this.props.location.state.id;
    let addcost = { 
      id: values.id,
      rate: values.cost,
      createdby: userid,
      tenantid: currentTenant
    }

    let updatecost = { 
      id: values.id,
      cid: this.state.cid,
      name: values.name,
      rate: values.cost,
      updatedby: userid,
      tenantid: currentTenant
    }
    
    if (id < 1) {
       
      InventoryService.addCost(addcost).then(() =>
      this.setState({loader:false},()=>
      Swal.fire({
        title: `Cost added successfully`,
        text: ".",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      }).then(()=>this.props.navigate(`/cost`))))
      .catch((e)=>{
        this.setState({message:e.response.data.message,loader:false},()=>{
            if(e.response.data.error ==="Unauthorized"){
                Swal.fire({
                  title: `Your Session is Lost: Please login Back to resume`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                }).then(()=>this.props.navigate('/'))
            }else{
              Swal.fire({
                title: `${e.response.data.message}`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            }})
        })
    }  else{      
      InventoryService.updateCost(updatecost).then(() =>
      this.setState({loader:false},()=>
      Swal.fire({
        title: `Cost updated successfully`,
        text: ".",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      }).then(()=>this.props.navigate(`/cost`))))
      .catch((e)=>{
        this.setState({message:e.response.data.message,loader:false},()=>{
            if(e.response.data.error ==="Unauthorized"){
                Swal.fire({
                  title: `Your Session is Lost: Please login Back to resume`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                }).then(()=>this.props.navigate('/'))
            }else{
              Swal.fire({
                title: `${e.response.data.message}`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            }})
        })
  }
  }

  onfocus(){

   document.getElementById('price').addEventListener('mousewheel', function(event) {
    if (window.document.activeElement.type === 'number') {
      event.preventDefault()
    }
  })

  document.getElementById('price').addEventListener('keydown', function(e) {
    if (e.which === 38 || e.which === 40) {
        e.preventDefault();
    }
  });
  }
  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    
    const cid = this.props.location.state.id;

    // InventoryService.getItemNames(currentTenant).then((response) =>
    InventoryService.retriveAllItems(currentTenant).then((response) => 
    this.setState({
    itemsList: response.data
  }))
  .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})

    if (cid < 1) {
      return    
    }
    InventoryService.costData(cid)
    .then(response => this.setState({
      cid:cid,
      id: response.data.id,
      name: response.data.name,
      cost: response.data.rate
    }))
 }


  validate(values) {

    let errors = {}

     if (!values.name|| values.name==='') {
     errors.name = "Please Select An Item";
      }
    if (!values.cost) {
      errors.cost = 'Price value is required';
    }
    if (values.cost <0) {
      errors.cost = 'Price Should be greather than 0';
    }
    
    return errors;
  }

  render() {
     let items = this.state.itemsList;
     let {id,name,cost} = this.state;
 
    return (
      
      <div>
      <Header/>
         <Menu/>
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
            <Link as="button" className=" text-secondary"  to={{ pathname: "/cost" }}>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/cost">Price</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>

            <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={this.state.loader}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>

            <div className="card-body">
            
              <Formik
                initialValues={{id,name,cost,items}}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}>
                {({setFieldValue,values}) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field className="form-control" type="text" name="id" placeholder="Id"/>
                      </fieldset>

                      {this.state.id === -1 && (this.state.itemsList===undefined || this.state.itemsList.length===0 )?
                      <fieldset className="form-group col-md-12 deletebg">
              <ul className="py-3">
                <li style={{"listStyle": 'none'}}>Note:</li>
              {this.state.itemsList===undefined || this.state.itemsList.length===0?
              <li>No Items are avilable</li>
              :""}
              </ul>
              </fieldset>
                    :""}

                      { this.state.pagename ==="Edit Price"? (<>
                          <fieldset className="form-group col-md-4">
                          
                          <FormikTextField
                              className="form-control"
                              type="text"
                              name="name"
                              label={`Select Item ${this.state.name}`}
                              value={this.state.name}
                              variant="standard"
                              disabled
                              fullWidth
                            />
                            </fieldset> 
                           </>
                      ) :(<>
                       <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="name"
                          options={items}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.name?undefined:undefined}
                          onChange={this.handleitemname}
                          renderInput={params => (  
                            <FormikTextField
                                {...params}
                              className="form-control"
                              type="text"
                              name="name"
                              label={`Select Item (${this.state.name})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                     </>
                      )
                      } 

                      <fieldset className="form-group col-md-4">
                      <FormikTextField className="form-control"  type="number" name="cost" label={`Enter Price per '${this.state.uom}'`} id="price" onFocus={this.onfocus} />
                      </fieldset>

                      {this.state.filterlist===""|| this.state.filterlist=== undefined?'':
                      <fieldset className="form-group col-md-12">
                        <table className='float-left'>
                          <tbody>
                            <tr>
                              <td>Brand</td>
                              <td>&nbsp;:&nbsp;&nbsp;{this.state.filterlist.brand}</td>
                            </tr>
                            <tr>
                              <td>Model</td>
                              <td>&nbsp;:&nbsp;&nbsp;{this.state.filterlist.model}</td>
                            </tr>
                            <tr>
                              <td>Specifications</td>
                              <td>&nbsp;:&nbsp;&nbsp;{this.state.filterlist.description}</td>
                            </tr>
                          </tbody>
                        </table>
                        </fieldset>
                      }
                    </div>
                    <div className="form-group col-md-12 m-0"/>
                    <fieldset className="form-group col-md-12">
                      <label className="text-red">
                        Note : Price must be Exclusive of tax.
                      </label>
                    </fieldset>
                    {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled> <Spinner animation="border" size="sm" />{"Saving"}</button>: <button className="btn btn-sm hovbuttonColour" type="submit"> <i className="fa fa-save mr-1"/>{"Save"}</button>}
                    <Link as="button" className="btn btn-sm deletebutton ml-1" to={{pathname: '/cost'}} ><b className="text-danger"> X</b> Cancel</Link> 
                  
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

      <Footer/>
      </div>
    );
  }
}
export default withRouter(AddCost)