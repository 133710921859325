import {
  FormControlLabel,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MasterService from "../../../Masters/MasterService";
import Swal from "sweetalert2";
import moment from 'moment'
import IOSSwitch from "../../../CommonComponents/IOSSwitch";

const SuperAdminDashboard = () => {
  const [clientsdata, setClientsdata] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    MasterService.retriveAllTenants().then((res) =>{
      setClientsdata(
        res.data === "" || res.data === undefined
          ? []
          : res.data.sort((a, b) => (a.companyname < b.companyname ? 1 : -1))
      ),
      setLoading(false)
      })
    .catch(()=>setLoading(false))
  }, []);

  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const visibleRows = React.useMemo(
    () =>
      clientsdata?.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      )
    // [ page, rowsPerPage],
  );

  const extendTrail = (id) => {
    Swal.fire({
      title: `Start trail period for the next 15 days.`,
      text: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.value) {
        MasterService.extendTrailPeriod(id)
          .then(() => {
            MasterService.retriveAllTenants().then((res) =>
              setClientsdata(
                res.data === "" || res.data === undefined
                  ? []
                  : res.data.sort((a, b) =>
                      a.companyname < b.companyname ? 1 : -1
                    )
              )
            );
            Swal.fire({
              title: `Client trail period has started for the next 15 days.`,
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
          })
          .catch((error) =>
            Swal.fire({
              title: `Request failed: ${error.data}`,
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            })
          );
      }
    });
  };

  const handleSubscription = (client) => {
    if (client.enguagment === "NA") {
      handleSelectEnguagment(client);
    } else {
      let data = {
        id: client.id,
        duration:
          client.enguagment === "SAASMonthly"
            ? moment(new Date())
                .add("1", "month")
                .diff(moment(new Date()), "days")
            : client.enguagment === "SAASQuarterly"
            ? moment(new Date())
                .add("3", "month")
                .diff(moment(new Date()), "days")
            : moment(new Date())
                .add("1", "year")
                .diff(moment(new Date()), "days"),
        plan:
          client.enguagment === "SAASMonthly"
            ? moment(new Date())
                .add("1", "month")
                .diff(moment(new Date()), "days")
            : client.enguagment === "SAASQuarterly"
            ? moment(new Date())
                .add("3", "month")
                .diff(moment(new Date()), "days")
            : moment(new Date())
                .add("1", "year")
                .diff(moment(new Date()), "days"),
      };
      Swal.fire({
        title: `Payment Received, Make client as Active`,
        text: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          MasterService.PaymentStatusUpdate(data)
            .then(() => {
              MasterService.retriveAllTenants().then((res) =>
                setClientsdata(
                  res.data === "" || res.data === undefined
                    ? []
                    : res.data.sort((a, b) =>
                        a.companyname < b.companyname ? 1 : -1
                      )
                )
              );
              Swal.fire({
                title: `Client has been successfully ${
                  client.status === "ACTIVE" ? "Inactivated" : "activated"
                }`,
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              });
            })
            .catch((error) =>
              Swal.fire({
                title: `Request failed: ${error.data}`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            );
        }
      });
    }
  };

  const handleSelectEnguagment = async (client) => {
    const { value: formValues } = await Swal.fire({
      title: `Select Enguagment Type for ${client.companyname}`,
      input: "select",
      inputOptions: {
        SAASMonthly: "SAAS Monthly",
        SAASQuarterly: "SAAS Quarterly",
        SAASYearly: "SAAS Yearly",
      },
      confirmButtonColor: "#152f5f",
      inputPlaceholder: "Select Enguagment Type",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          resolve();
        });
      },
    });
    if (formValues) {
      let data = {
        id: client.id,
        enguagment: formValues,
      };
      MasterService.ChangeEnguagment(data)
        .then(() => {
          MasterService.retriveAllTenants().then((res) =>
            setClientsdata(
              res.data === "" || res.data === undefined
                ? []
                : res.data.sort((a, b) =>
                    a.companyname < b.companyname ? 1 : -1
                  )
            )
          );
          Swal.fire({
            title: `Client's Enguagment Type has been successfully Updated`,
            text: ".",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          });
        })
        .catch((error) =>
          Swal.fire({
            title: `Request failed: ${error.data}`,
            text: ".",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          })
        );
    }
  };

  const handleStatusChange = (client) => {
    if (client.plantype !== "NA" && client.days > 0) {
      Swal.fire({
        title: `Make client as ${
          client.status === "ACTIVE" ? "Inactive" : "Active"
        }`,
        text: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          MasterService.InactiveClient(client.id)
            .then(() => {
              MasterService.retriveAllTenants().then((res) =>
                setClientsdata(
                  res.data === "" || res.data === undefined
                    ? []
                    : res.data.sort((a, b) =>
                        a.companyname < b.companyname ? 1 : -1
                      )
                )
              );
              Swal.fire({
                title: `Client has been successfully ${
                  client.status === "ACTIVE" ? "Inactivated" : "activated"
                }`,
                text: ".",
                icon: "success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              });
            })
            .catch((error) =>
              Swal.fire({
                title: `Request failed: ${error.data}`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            );
        }
      });
    } else {
      Swal.fire({
        title: `Please make a payment or start a trail in order to activate the client`,
        text: ".",
        icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      });
    }
  };

  return (
    <div>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            aria-label="sticky table"
            className="table-sm p-2 pb-4"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>Client Id</TableCell>
                <TableCell>Company Name</TableCell>
                <TableCell>Contact Name</TableCell>
                <TableCell>Contact Number</TableCell>
                <TableCell>Trail</TableCell>
                <TableCell>Payment</TableCell>
                <TableCell>Enguagment Type</TableCell>
                <TableCell>Active Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading?
               <TableRow>
               <TableCell colSpan={12} align="center">
               <Skeleton variant="rounded" height={20} width="100%" animation="wave"  />
               </TableCell>
             </TableRow>:
              visibleRows === null && visibleRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    Sorry, no data avilable
                  </TableCell>
                </TableRow>
              ) : (
                visibleRows?.map((client, index) => (
                  <TableRow key={index}>
                    <TableCell>{client.id}</TableCell>
                    <TableCell>{client.companyname}</TableCell>
                    <TableCell>{client.contactname}</TableCell>
                    <TableCell>
                      +{client.phonecode} {client.contactnumber}
                    </TableCell>
                    <TableCell>
                      {client.trailstatus !== "Active" &&
                      client.plantype !== "Trail" ? (
                        <button
                          className="btn btn-sm hovbuttonColour"
                          onClick={() => extendTrail(client.id)}
                        >
                          Start
                        </button>
                      ) : client.trailstatus === "Active" ? (
                        `${client.days}/${client.plan}`
                      ) : (
                        client.trailstatus
                      )}
                    </TableCell>
                    <TableCell>
                      {client.plantype === "Subscription" ? (
                        `Received`
                      ) : (
                        <button
                          className="btn btn-sm hovbuttonColour"
                          onClick={() => handleSubscription(client)}
                        >
                          Pending
                        </button>
                      )}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="cursorpointer"
                      onClick={() => handleSelectEnguagment(client)}
                    >
                      {client.enguagment === null ||
                      client.enguagment === undefined
                        ? "NA"
                        : client.enguagment}
                      <i className="fas fa-caret-down ml-1"></i>
                    </TableCell>
                    <TableCell>
                      <FormControlLabel
                        control={
                          <IOSSwitch
                            className="sm"
                            checked={
                              client.clientactive === "ACTIVE" ? true : false
                            }
                            onChange={() => handleStatusChange(client)}
                            name="checkedB"
                          />
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
                          rowsPerPageOptions={[5, 10]}
                          component="div"
                          count={clientsdata.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
      </Paper>
    </div>
  );
};

export default SuperAdminDashboard;
