import React from "react";
import { Link } from "react-router-dom";

import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  clientname,
  logo,
  userid,
} from "../../Common/Credentials";
// import moment from "moment";
import { spin } from "../../Common/NewLoader";
import { productionid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";

import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ProductionServices from "../ProductionServices";
import withRouter from "../../withRouter";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class StoreLossesTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.location.state.id,
      finishedstore: this.props.location.state.finishedstore,
      warehouse: this.props.location.state.warehouse,
      whid: this.props.location.state.whid,
      workcenter: this.props.location.state.workcenter,
      wcid: this.props.location.state.wcid,
      name:this.props.location.state.name,
      Options:["Finished Products","Issue"],
      indentissued:this.props.location.state.indentissue,
      selected:"Finished Products",
      loading: false,
      data:[],
    };
  }

  pdf = () => {
    let pdfdata = this.state.active;
    pdfdata.map(
      (e) =>
        (pdfdata.find((i) => i.id === e.id).createdAt = moment(
          pdfdata.find((i) => i.id === e.id).createdAt
        ).format("DD-MM-YYYY hh:mm"))
    );
    pdfdata.map(
      (e) =>
        (pdfdata.find((i) => i.id === e.id).startdate = moment(
          pdfdata.find((i) => i.id === e.id).startdate
        ).format("DD-MM-YYYY"))
    );
    const columns = [
      { header: "Estimation Id", dataKey: "id" },
      { header: "SaleOrder Id", dataKey: "soid" },
      { header: "Workcenter", dataKey: "workcenter" },
      { header: "Type", dataKey: "estimationtype" },
      { header: "Status", dataKey: "status" },
      { header: "Satrt Date", dataKey: "startdate" },
      { header: "Duration", dataKey: "duration" },
      { header: "Date", dataKey: "createdAt" },
    ];
    var doc = new jsPDF("p", "pt");
    doc.addImage(logo, "GIF", 40, 20, 60, 60);
    doc.setFontSize(16);
    doc.setFont("Times-Roman");
    doc.text(`${clientname}`, 180, 40);
    doc.setFontSize(14);
    doc.text(`Active Sale Orders`, 210, 80);
    doc.setFontSize(8);
    doc.text(`${moment().format("DD-MM-YYYY hh:mm")}`, 510, 80);
    doc.line(0, 90, 600, 90);
    autoTable(doc, {
      theme: "grid",
      startY: 100,
      columnStyles: { 7: { halign: "right" } },
      body: pdfdata,
      columns: columns,
    });
    doc.save("ActiveSaleordes.pdf");
    this.refresh();
  };


  refresh() {
    ProductionServices.GetFinishedGoodsByStore(this.state.id).then((response) => {
      this.setState({
        data: response.data,
        loading: false,
      });
    });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.refresh();
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: { filter: true, sort: true },
      },
      {
        name: "joid",
        label: "Job Order Id",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              <Link
                as="button"
                data-tip
                data-for="view details"
                to={{
                  pathname: "/joborder"}}
                  state= {value}
              >
                {value}
              </Link>
            );
          },
        },
      },
      {
        name: "inprocess",
        label: "Inprocess Id",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              <Link
                as="button"
                data-tip
                data-for="view details"
                to={{
                  pathname: "/inprocess"}}
                  state= {value}
              >
                {value}
              </Link>
            );
          },
        },
      },
      {
        name: "qualityanalysis",
        label: "Quality Analysis Id",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              value === null || value === undefined?"NA":
              <Link
                as="button"
                data-tip
                data-for="view details"
                to={{
                  pathname: "/qa"}}
                  state={value}
              >
                {value}
              </Link>
            );
          },
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-yyyy hh:mm");
          },
        },
      },
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
               {console.log(tableMeta)}
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="fa fa-list-alt"
                  style={{ color: "#152f5f"}}
                  to={{
                    pathname: "/finishedproducts"}}
                    state ={{data:this.state.data.find(e=>e.id === tableMeta.rowData[0]), items:this.state.finishedstore.find(e=>e.id === tableMeta.rowData[0]).finishedstoreitems}}
                ></Link>
                {/* //<Tooltip id="view details" place="top" effect="solid">
                  View
                </Tooltip> */}
              </>
            );
          },
        },
      },
    ];
    const options = {
        filterType: "checkbox",
        selectableRows: "none",
        customToolbar: () => {
          return (
            <>
              {productionid === "2" || productionid === "3" ? (
                <Link
                  as="button"
                  className="float-right btn btn-sm m-0 p-0"
                  to={{
                    pathname: "/addstorelosses"}}
                    state= {{
                      id: -1,
                      pagename: "Add Store Losses",
                      wcid: this.state.wcid,
                      workcenter: this.state.workcenter,
                      whid: this.state.whid,
                      warehouse: this.state.warehouse,
                      name:this.state.name,
                  }}
                >
                  <CustomToolbar />
                </Link>
              ) : (
                ""
              )}
            </>
          );
        },
      };
  
    
    // const columns1 = [
    //   {
    //     name: "id",
    //     label: "Indent Issued Id",
    //     options: { filter: true, sort: true },
    //   },
    //   {
    //     name: "indentid",
    //     label: "Indent Id",
    //     options: {
    //       filter: true,
    //       sort: true,
    //       customBodyRender: (value) => {
    //         return (
    //           <Link
    //             as="button"
    //             data-tip
    //             data-for="view details"
    //             to={{
    //               pathname: "/indentingdetails",
    //               state:{id:value},
    //             }}
    //           >
    //             {value}
    //           </Link>
    //         );
    //       },
    //     },
    //   },
    //   {
    //     name: "createdby",
    //     lable: "Issued By",
    //     options:  { filter: true, sort: true },
    //   },
    //   {
    //     name: "id",
    //     label: "Issed Details",
    //     options: {
    //       filter: false,
    //       sort: false,
    //       empty: true,
    //       customBodyRender: (value, tableMeta, updateValue) => {
    //         return (
    //           <>
    //             <Link
    //               as="button"
    //               data-tip
    //               data-for="view details"
    //               className="hovbuttonColour btn btn-sm"
    //               to={{
    //                 pathname: "/indentissueddetails",
    //                 state: this.state.indentissued.find((e) => e.id === value),
    //               }}
    //             >
    //               {" "}
    //               View Details
    //             </Link>
    //             //<Tooltip id="view details" place="top" effect="solid">
    //               View Details
    //             </Tooltip>
    //           </>
    //         );
    //       },
    //     },
    //   },
    // ];
    // const options1 = {
    //   filterType: "checkbox",
    //   selectableRows: "none"
    // };

    

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <Link
                  as="button"
                  className=" text-secondary"
                  to={{ pathname: "/store" }}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/store">Store</Link>
              </li>
              <li className="breadcrumb-item active">Store Losses</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">{this.state.name} - Store Losses</h3>
                      </div>

                      <div className="card-body">
                        {this.state.loading === true ? (
                          <span className="text-center">{spin}</span>
                        ) : (<>
                          {/* {this.state.Options.map((option) => {
                        return (
                          <label key={option}>
                            <div className="btn-group">
                              <input
                                className={
                                  this.state.selected === option
                                    ? "btn hovborderbuttonColour"
                                    : "btn hovbuttonColour"
                                }
                                type="button"
                                key={option}
                                onClick={()=>this.setState({selected:option})}
                                style={{ marginRight: 8, width: 250 }}
                                value={option}
                              />
                            </div>
                          </label>
                        );
                      })} */}
                        {/* {this.state.selected === "Finished Products"? */}
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={`Store Losses`}
                              data={this.state.data}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                          {/* :
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={`Indents issue`}
                              data={this.state.data}
                              columns={columns1}
                              options={options1}
                            />
                          </ThemeProvider>
                            } */}
                          </>
                        )}
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(StoreLossesTable);
