import React, { Component } from "react";
// //import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import { spin } from "../../Common/NewLoader.jsx";
import { currentTenant, thousandsdisplay, userid } from "../../Common/Credentials.js";
import SupplierService from "./SupplierService.js";
import { appmasterid } from "../../Common/Permissions.js";
import MUIDataTable from "mui-datatables";
import CustomToolbar from "../../CommonComponents/CustomToolbar.js";

import { ThemeProvider, Tooltip } from "@mui/material";
import { createTheme } from "@mui/material";
import Swal from "sweetalert2";
import { NumericFormat } from "react-number-format";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});


class SupplierDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      message: "",
      suppliers: [],
      loading: false,
    };
    this.refreshSupplier = this.refreshSupplier.bind(this);
    this.deleteSupplier = this.deleteSupplier.bind(this);
    this.updateSupplier = this.updateSupplier.bind(this);
  }

  deleteSupplier(id) {
    Swal.fire({
      title: "Delete Supplier?",
      text: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        SupplierService.deleteSupplier(id).then(
          () =>{
            this.setState({ message: "Deleted Successfully", loading: false })
            Swal.fire({
              title: "Supplier Deleted Successfully!",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(()=>this.refreshSupplier())
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }else{
              Swal.fire({
                title: "An error occurred",
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            }
          });
      }
    });
  }

  updateSupplier(id) {
    SupplierService.retriveSupplier(id).then((response) => {
      this.setState({ suppliers: response.data, loading: false });
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  refreshSupplier() {
    SupplierService.retriveAllSuppliers(currentTenant).then((response) => {
      this.setState({ suppliers: response.data, loading: false });
    })
    .catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
      })
  }

  createCustomer = (customer) => {
    let data = {
      id: customer,
      tenantid: currentTenant,
      createdby:userid
    }
    Swal.fire({
      title: "Create customer with this supplier details?",
      text: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        SupplierService.addCustomerfromsupplier(data).then(() => {
            Swal.fire({
              title: "Customer Added Successfully!",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(()=>this.refreshSupplier())
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }else{
              Swal.fire({
                title: `${e.response.data}`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            }
          });
      }
    });
  }

  returnOpeningBalance = (id)=>{
    console.log(id)
    let data = {
      partyid:id,
      ledgername:"",
      ledgerid:"",
      amount:"",
      tenantId:currentTenant,
      createdBy:userid
    }
    Swal.fire({
      title: "Return opening balance?",
      text: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        SupplierService.addPurchaseBalRev(data).then(() => {
            Swal.fire({
              title: "Opening balance returned successfully!",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(()=>this.refreshSupplier())
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }else{
              Swal.fire({
                title: `${e.response.data}`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            }
          });
      }
    });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ isLoading: false });
    this.setState({ loading: true });
    this.refreshSupplier();

    // if (this.props.history.location.state.message===null){
    //   this.setState({message:''})
    // }else{
    //   this.setState({message:this.props.history.location.state.message})
    // }
  }

  render() {
    let confirm;
    // let tbody;
    if (this.state.message === "SUP5") {
      confirm = (
        <div className="text-center text-warning text-uppercase">
          {this.state.message}
        </div>
      );
    } else {
      confirm = (
        <div className="text-center text-success text-uppercase">
          {this.state.message}
        </div>
      );
    }

    if (this.state.isLoading === true) return <div className="loading"></div>;

    // let spin = (
    //   <Loader
    //     type="Watch"
    //     color="Blue"
    //     height={100}
    //     width={200}
    //     timeout={this.state.loading === false} //3 secs
    //   />
    // );

    const columns = [
      {
        name: "id",
        label: "Id",
        options: { filter: true, sort: true, sortOrder: "asc", display: false },
      },
      {
        name: "customsupplierid",
        label: "Supplier Id",
        options: { filter: true, sort: true, sortOrder: "asc" },
      },
      {
        name: "companyname",
        label: "Company",
        options: { filter: true, sort: true },
      },
      {
        name: "contactname",
        label: "Contact",
        options: { filter: true, sort: true },
      },
      {
        name: "contactnumber",
        label: "Contact Number",
        options: { filter: false, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <div>+{this.state.suppliers.find((e) => e.id === tableMeta.rowData.find((e) => e)).phonecode} {value}</div>
            )
          }
        },
      },
      { name: "email", label: "Email", options: { filter: false, sort: true } },
      // {name: "active",label: "Status",options: {filter: true,sort: true,sortOrder: 'asc',}},
      {
        name: "cityname",
        label: "City",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "openingbalance",
        label: "Opening",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <div><NumericFormat className="pr-3" displayType={'text'} value={value=== undefined||value=== null?0:value} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></div>
            )
          }
        },
      },
      {
        name: "inuse",
        label:"Action",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (<>
            <Tooltip title="Edit">
              <Link
                as="button"
                data-tip
                data-for="edit"
                className="fa fa-edit cursorPointer mr-2"
                to={{pathname: "/newsupplier"}}
                  state= {{
                    id: tableMeta.rowData.find((e) => e),
                    name: "Update Supplier"
                }}
              ></Link>
              </Tooltip>
              {value==="NO"?
              <Tooltip title="Delete">
               <span
                as="button"
                data-tip
                data-for="delete"
                className="fa fa-trash text-danger cursorPointer mr-2"
                onClick={() => {
                    this.deleteSupplier(tableMeta.rowData.find((e) => e));
                }}
              ></span>
              </Tooltip>:''}
              <Tooltip title="Create Customer">
              <span
              as="button"
              data-tip
              data-for="view"
              className="fa fa-user-plus mr-1 cursorPointer"
              onClick={()=>this.createCustomer(tableMeta.rowData.find((e) => e))}
            ></span>
            </Tooltip>
            {tableMeta.rowData[7] !==undefined && tableMeta.rowData[7] !==0 && tableMeta.rowData[7] !==null?
            <Tooltip title="Return Opening Balance">
              <span
              as="button"
              data-tip
              data-for="view"
              className="mr-1 cursorPointer"
              onClick={()=>this.returnOpeningBalance(tableMeta.rowData.find((e) => e))}
            >Return</span>
            </Tooltip>:""}
            </>);
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{pathname: "/newsupplier"}}
                  state={{ id: -1, name: "New Supplier"}}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };

    return (
      <div>
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/masters">Home</Link>
            </li>

            <li className="breadcrumb-item active">Suppliers </li>
          </ol>
          {confirm}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                    <div className="card-body">
                      <div>
                        {this.state.loading === true ? (
                          <div className="text-center">{spin}</div>
                        ) : (
                          <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Suppliers"}
                            data={this.state.suppliers}
                            columns={columns}
                            options={options}
                          />
                          </ThemeProvider>
                        )}
                      </div>
                    </div>
                    {/* /.card-body */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </div>
    );
  }
}
export default withRouter(SupplierDataTable);
