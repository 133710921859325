import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { currentTenant, userid } from "../Common/Credentials.js";
import { Autocomplete } from "@mui/material";
import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import Footer from "../Component/Footer";
import AssetsService from "./AssetsService.js";
import { spin } from "../Common/NewLoader.jsx";
import { Form, Formik } from "formik";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const NewAssetmaintenance = (props) => {
  // const history = useLocation();
  const navigate = useNavigate();
  const [assetidList,setAssetIdList] = useState([]);
  const [assetid,setAssetId] = useState('')
  const [customid,setCustomId] = useState('')
  // const [assetdetails,setAssetDetails]= useState('')
  const [itemid, setitemid] = useState("");
  const [itemname, setitemname] = useState("");
  // const [quantity, setQuantity] = useState(0);
  // const [avilablequantity,setAvilablequantity] = useState(0)
  const [type,setType] = useState('');
  const [types,setTypes] = useState([]);
  const [amount,setAmount] = useState(0)
  const [description,setDescription] = useState('')
  const [loader,setLoader] = useState(false)
 

  useEffect(() => {
    // if (userid === undefined) {
    //   window.confirm("Your Session is Lost: Please login Back to resume");
    //   history.push(`/`);
    // }
    AssetsService.GetMaintenanceTypes().then(response=>
      setTypes(response.data)
      )
    AssetsService.AssetIds(currentTenant)
      .then((response) => 
      setAssetIdList(response.data)
      )
      .catch((e) => {
        if(e.response!==undefined){
          if (e.response.data.error === "Unauthorized") {
            Swal.fire("Session is Lost!", "Please login Back to resume", "error").then(()=>
            navigate("/")
            )
          }
        }
      });
  },[]);

  const handleAssetChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setAssetId("")
      setCustomId("")
    } else {
      setCustomId(newValue);
      AssetsService.GetAssetById(newValue).then(response=>{
        setAssetId(response.data.id)
        setitemid(response.data.itemid)
        setitemname(response.data.itemname)
        // setAvilablequantity(response.data.quantity)
      })
    }
  };


  const handleTypeChange = (e) => {
    setType(e.target.value);
  };
  const handleamountChange =(e)=>{
    setAmount(e.target.value)
  }

  

  const onSubmit =()=>{
    setLoader(true)
    let data = {
      itemid:itemid,
      itemname:itemname,
      tenantid:currentTenant,
      quantity:1,
      type:type,
      createdby:userid,
      updatedby:userid,
      description:description,
      amount:amount,
      asset:assetid
    }
    AssetsService.NewAssetMaintenance(data).then(()=>
    navigate('/assetmaintenance')
    )
  }
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/assetmaintenance" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/assetmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/assetmaintenance">Asset Maintenance / Expenses</Link>
            </li>
            <li className="breadcrumb-item active">New Assets Maintenance / Expenses</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">New Assets Maintenance  / Expenses</h3>
                      </div>
                      <div className="card-body">
                        <Formik
                          initialValues={{}}
                          // onSubmit={onSubmit}
                          validateOnChange={false}
                          // validate={validate}
                          enableReinitialize={true}
                        >
                          {({ setFieldValue, values }) => (
                            <Form
                              autoComplete="off"
                              style={{ paddingLeft: "30px" }}
                            >
                              <div className="form-row">
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="asset"
                                    options={assetidList}
                                    getOptionLabel={(option) => option}
                                    
                                    
                                    value={
                                      values.asset ? undefined : undefined
                                    }
                                    onChange={handleAssetChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="assetid"
                                        value={assetid}
                                        label={`Select AssetId (${customid})`}
                                        variant="standard"
                                        error={assetid === ""}
                                        helperText={
                                          assetid === "" ? "Empty field!" : " "
                                        }
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3">
                                      <TextField
                                        className="form-control"
                                        type="text"
                                        name="itemname"
                                        label={`Itemname `}
                                        value={itemname}
                                        variant="standard"
                                        // onChange={handleQuantityChange}
                                        fullWidth

                                      />
                                    </fieldset>
                                {/* <fieldset className="form-group col-md-3">
                                      <TextField
                                        className="form-control"
                                        type="text"
                                        name="quantity"
                                        label={`Quantity (Avilable: ${avilablequantity})`}
                                        value={quantity}
                                        onChange={handleQuantityChange}
                                        error={
                                          quantity === "" ||
                                          quantity === undefined ||
                                          quantity > avilablequantity ||
                                          quantity === 0
                                        }
                                        helperText={
                                          quantity === "" ||
                                          quantity === 0 ||
                                          quantity === undefined
                                            ? "Enter Quantity"
                                            : quantity > avilablequantity
                                            ? `Quantity Exceeds`
                                            : " "
                                        }
                                        fullWidth

                                      />
                                    </fieldset> */}
                                    <fieldset className="form-group col-md-3">
                                          <FormControl
                                            sx={{minWidth: 120 }}
                                            className="col-md-12"
                                          >
                                            <InputLabel id="demo-simple-select-filled-label">
                                              Select Type
                                            </InputLabel>
                                            <Select
                                              value={type}
                                              onChange={handleTypeChange}
                                              variant="standard"

                                            >
                                              {types.map(
                                                (option) => {
                                                  return (
                                                    <MenuItem
                                                      key={option}
                                                      value={option}
                                                    >
                                                      {option}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                            </Select>
                                          </FormControl>
                                        </fieldset>
                                        <fieldset className="form-group col-md-3">
                                      <TextField
                                        className="form-control"
                                        type="text"
                                        name="amount"
                                        label={`Amount`}
                                        value={amount}
                                        onChange={handleamountChange}
                                        variant="standard"
                                        error={
                                          amount === "" ||
                                          amount === undefined ||
                                          amount === 0
                                        }
                                        helperText={
                                          amount === "" ||
                                          amount === 0 ||
                                          amount === undefined
                                            ? "Enter amount"
                                            : " "
                                        }
                                        fullWidth

                                      />
                                    </fieldset>
                                    <fieldset className="form-group col-md-12  mt-3 mb-5">
                                      <TextField name="description" className="form-control" multiline rows={2} label="Description" variant="outlined" value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                      />
                                    </fieldset>
                                    <button className="btn btn-sm hovbuttonColour mr-1" type="button" onClick={onSubmit} disabled={loader===true}>  {loader===true? <><Spinner animation="border" size="sm" /> &nbsp;{"Saving"}</>:<><i className="fa fa-save mr-1"/>{"Save"}</>}</button>
                                    <Link as="button" className="btn btn-sm deletebutton" to={{pathname: '/assetmaintenance'}} ><b className="text-danger"> X</b> Cancel</Link> 
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(NewAssetmaintenance);
