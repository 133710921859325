import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

class SubHeadsService{
    
   

   getSubHeads(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getsubheads/${tenantId}`)
      }

   getLedGroups(tenantId){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getledgroups/${tenantId}`)
       }  

      retriveSubAcc(id){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getsubacc/${id}`)
      }

      retriveSubIdName(tenantId){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getsubidname/${tenantId}`)
      }

      retriveSubAccounts(tenantId){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getsubaccts/${tenantId}`)

      }
      
     
      createSubHead(newSubHead){
         return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/addnewsubhead`,newSubHead)
      }

      addNewLedGroup(newLedGroup){
         return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/addnewledgroup`,newLedGroup)
      }
      

      updateSubHead(updateSubHead){
         return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/updatesubhead`,updateSubHead)
      }

   
      deleteSubHeads(id){
         return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/deletesubhead/${id}`,)
      }

      deleteLedGroup(id){
         return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/deleteledgroup/${id}`)
      }


}

export default new SubHeadsService();
