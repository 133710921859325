import { Radio } from "@mui/material";
import React from "react";
import Car from "./Car";
import Hotel from "./Hotel";

 const HotelORCar = (props) => {
  const [selectedValue, setSelectedValue] = React.useState('a');

  const getHotelDataHere=(data)=>{
    console.log(data,"Hello");
    props.funcHotel(data)
  }
  const getCarDataHere = (data) => {
    console.log(data) + "whi Car"
    props.funcCar(data)
    }


  const handleChange = (event) => {
    console.log(selectedValue);
    setSelectedValue(event.target.value);
  };
  
    
  return (
    <div className="small-container mp-2 padding-2 w-100%">
     <div className="card">
     <div className="row">
        <div className="col-md-12 col-sm-12">
          <center>
            <label>Hotel</label>
          <Radio
            checked={selectedValue === "a"}
            onChange={handleChange}
            value="a"
            name="radio-buttons"
            inputProps={{ "aria-label": "A" }}
            
          />
          <label className="ms-5">Car</label>
          <Radio
            checked={selectedValue === "b"}
            onChange={handleChange}
            value="b"
            name="radio-buttons"
            inputProps={{ "aria-label": "B" }}
          />
          </center>
          {selectedValue==='a' ? <Hotel funcHotel={getHotelDataHere}/>: <Car  funcCar={getCarDataHere}/>}

        </div>
      </div>
     </div>
    </div>
  );
};

export default HotelORCar