import { Checkbox, FormControlLabel, FormLabel, Grid } from "@mui/material";
import { Autocomplete,TextField } from "@mui/material";
import { FastField, FieldArray, Form, Formik } from "formik";
import { FormikTextField } from '../CommonComponents/FormField.js';
import React, { Component } from "react";
import withRouter from "../withRouter.js";
import { Link } from "react-router-dom";
import { currentTenant, financialyearend, financialyearstart, transactionFrom, userid } from "../Common/Credentials";
import { combdata, subacclist, expcombdata, typeofentry, indirecttax} from "./Journal";
import { Spinner } from "react-bootstrap";
import Swal from 'sweetalert2'
import IOSSwitch from "../CommonComponents/IOSSwitch.js";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";import moment from "moment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


let jvtype = 11; // Type of Jv 11 for generall  //dtaxtotal
let debtotal,debtname,credname,tcschecked=0,taxchecked=false,pnpentry=false,taxdupe,checkedentry=false;
let val=[],val3=[],indtaxtype,indsubtaxtype//debdupe=1,expdupe,creindividualval=[]

//let expdupe,cretotal,taxtotal=0,taxreqchecked=0,val2=[],debindividualval=[]


let taxbehav=0 // 0-notax,1-directtax, 2-inputindirect 3-outputindirect
class DirectEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
     
      aa: "",
      btncolor:"",
      debval: [],
      dd: [],
      taxingtype: [
        { id: 1, name: "Direct" },
        //{ id: 1, name: "Direct Sales" },
        { id: 2, name: "Indirect" },
      ],
      taxreq:[{ id: 0, name: "No" },{ id: 1, name: "Yes" }],
      tdsreq:[{ id: 0, name: "No" },{ id: 1, name: "Yes" }],
      typeofentrydata:[{ id: 0, name: "Debit" },{ id: 1, name: "Credit" }],
      taxchecked:false,
      tcschecked:0,
      taxreqchecked:0,
      debtotal: "",
      cretotal: "",
      taxtotal:"",
      SDdate:false,
      pnpentry:false,//Payment or non payment
      fed:'',
      displaychecked:false,
      directchecked:false,
      chargeschecked:false,
      noexp:[{"id":"noexp","name":"No Expense Mapping","amount":0.00,"entrytype":0,"subacc":"","tenantId":"","rule":0,"behaviour":0,"subname":"z - NA","totamount":null,"th":"","taxtype":0}],
      loader:false,val:[],val2:[],val3:[],expval:[],jvinsertiontype:0,

    };
    this.onSubmit = this.onSubmit.bind(this);
    this.addVal=this.addVal.bind(this);
    this.validate = this.validate.bind(this);
    this.handleChargesEntry=this.handleChargesEntry.bind(this);
    //this.handlePnpentery=this.handlePnpentery.bind(this);
  }
  componentDidMount(){

    let  expval1 = expcombdata;
    this.setState({expval:expval1.concat(this.state.noexp)})
      
   // alert(typeofentry + "B")
   // val2=combdata.filter(e=>e.rule!==2)
   this.addVal()
    tcschecked=0
    taxbehav=0
    debtotal=""
    //cretotal=""
   // taxtotal=0
    const script = document.createElement("script");
          script.src= '../../js/content.js';
          script.async= true;
          document.body.appendChild(script);
 
  }
  handleCheckboxChange= event =>{
    this.setState({ taxchecked: event.target.checked })
      taxchecked=event.target.checked
      taxbehav=2
 }

 handleFsdDateChange = (date) => {
  if(date!==null){
  this.setState({ fsd: date });
  this.setState({
    fed: moment(date.$d).format("yyyy-MM-DD HH:mm:ss"),
  });
}else{
  this.setState({ SDdate:false });
}
};
 taxLedgers(value,taxone){
  let value1=value
  let tax1=indirecttax
  if(value!==null || taxone!==undefined || taxone!==null){
  let ttype=taxone.map(e=>e.name)
  if(ttype[0]!==null){
  ttype.map(e=>{return indsubtaxtype=e.indirectsubtaxtype})
  ttype.map(e=>{return indtaxtype=e.indirecttaxtype})
  }
  }
  if(tax1!==undefined){
  let duplicate=tax1.filter((value) => value === value1)
  if(duplicate.length===0 || duplicate.length===null){
    taxdupe=0
  }else{
    taxdupe=1
  }
}
  }

  addTaxTotal(values, index) {
    let a = values.tax;
    let b = a.map((e) => e.value);
    let c = b.reduce((a, v) => (a = a + v), 0);
    this.setState({ taxtotal: c });
    if(typeofentry===1){
      if(taxbehav===1){
      debtotal=c+debtotal
      }
    }
    // taxtotal=c

  }

  onSubmit(values, index) {
  //  alert("I am called")
    //console.log(values)
    this.setState({loader:true})
    let debt
    let cred
   // let tax 
    if(tcschecked===0){
      debt=values.prledger
      cred=values.prledger1
    }else{
      debt=values.prledger1
      cred=values.prledger
    }
   // tax=values.dirdebtax
    debtname = debt.name
    credname = cred.name
   //  let taxname=tax!==undefined?tax.map(e=>e.name):""
    // let credsub = credname
    // let a=debtname.concat(credname)
    // let jvval=a.concat(taxname)

     if(this.state.pnpentry===true || this.state.chargeschecked===true){
      jvtype=3
     }else{
      jvtype=11
     }
   
  
    // let duplicate=[...new Set(
    //   jvval.filter((value, index, self) => self.indexOf(value) !== index))]

   // let duplicate=jvval
   // let credcb=credsub === 1
   // let crednoncb=credsub !== 1
    
    //let dupval=duplicate.name


    // if (debtotal+taxtotal !== cretotal+taxtotal) {
    //   Swal.fire({
    //     title: "Please Check !!!",
    //     text: "Debit and Credit totals should be equal",
    //     icon: "warning",
    //   confirmButtonText: "Ok",
    //   confirmButtonColor:'#152f5f',
    //   })
    //   this.setState({loader:false})
    // } else
    //  if(debtname===credname){
     
    // } 
    // if(credcb.length>=1 & crednoncb.length!==0){
    //   alert("Other ledgers are not allowed with Cash and Bank Ledgers");
    //   this.setState({loader:false})
    // }else 
    // if (debtotal === 0 || debtotal ==="" ||this.state.cretotal===0 || this.state.cretotal==="") {
    //   Swal.fire({
    //     title: "Please Check !!!",
    //     text: "Values for Debit and credit should not be blank",
    //     icon: "warning",
    //   confirmButtonText: "Ok",
    //   confirmButtonColor:'#152f5f',
    //   })
     
    //   this.setState({loader:false})
    // } 
      let dijv = {
        debt: debt,
        tax:values.tax,
        cred: cred,
        createdBy: userid,
        tenantId: currentTenant,
        naration: values.narration,
        drcrtype:tcschecked,
        type:jvtype,
       // type: jvtype,
        expense:values.expense,
        debtotal:values.amount,
        taxtotal:this.state.taxtotal,
        cretotal:values.amount,
        taxbehav:taxbehav,
        typeofentry:typeofentry,
        indtaxtype:indtaxtype,
        indsubtaxtype:indsubtaxtype,
        jvinsertiontype:this.state.jvinsertiontype,
        date:this.state.fed!==''?this.state.fed:null,
      };

      if (userid === undefined) {
        Swal.fire({
          title: "Please Check !!!",
          text: "Your Session is Lost: Please login Back to resume",
          icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonColor:'#152f5f',
        })
        this.props.navigate(`/`);
      }
      else if(debtname===credname){
        Swal.fire({
          title: "Please Check !!!",
          text:  "Ledgers are duplicated", //dupval.toLocaleString() +
          icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonColor:'#152f5f',
        })
        this.setState({loader:false})
      } else if (values.prledger1.th==="TH3"){
        let a=values.prledger1.amount-values.amount
        if(a<0){
          Swal.fire({
            title: "Please Check !!!",
            text:  "In-sufficient balance in the account", //dupval.toLocaleString() +
            icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor:'#152f5f',
          })
          this.setState({loader:false})
        } else{
          this.props.navigate({pathname:`/directentryconfirm`}, {state:dijv});
        }
        this.setState({loader:false})
      }else if(this.state.fed!=="" && this.state.fed<debt.map(e=>{return moment(e.name.createdAt).format("yyyy-MM-DD HH:mm:ss")})){
        Swal.fire({
          title: "Please Check !!!",
          text: "Date cannot be before Debit Ledger Creation Date !!",
          icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        });
      
     }else if(this.state.fed!=="" && this.state.fed<cred.map(e=>{return moment(e.name.createdAt).format("yyyy-MM-DD HH:mm:ss")})){
      Swal.fire({
        title: "Please Check !!!",
        text: "Date cannot be before Credit Ledger Creation Date !!",
        icon: "warning",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      });
    
   }
      else {
      //console.log(dijv)
      this.props.navigate({pathname:`/directentryconfirm`},{state:dijv})
      }

    
  }

  addValnew(value){
    debtotal=value
  }
 
  addDirTaxTotal(value) {
  //  let a = values.dtax;
   // let b = a.value;
    //let c = b.reduce((a, v) => (a = a + v), 0);
    // alert(c)
    this.setState({ dtaxtotal: value});
   // dtaxtotal=value

  }

  onDebBlur(values) {
    let a = values.debt;
    let b = a.map((e) => e.name);
    this.setState({ debname: b });
  }

  onTcsChange(option){
    this.setState({ tcschecked: option.id });
    taxbehav=option.id
    tcschecked=option.id
    }

    onTaxChange(option){
      this.setState({ taxreqchecked: option.id });
      // taxreqchecked=option.id
    }

    expLedgers(value){

     // let value1=value
    //  let exp1=expcombdata
     // let expval= exp1.concat(this.state.noexp)
     // let exp=expval.map(e=>e.name)
      // let duplicate=exp.filter((value) => value === value1)
      // if(duplicate.length===0 || duplicate.length===null){
      //   expdupe=0
      // }else{
      //   expdupe=1
      // }
    }

  validate(values, index) {

   //alert(values.amount)
    let errors = {};
    if(!values.prledger){
      errors.prledger = "To Ledger cannot be blank";
    }
    if(!values.prledger1){
      errors.prledger1= "From Ledger cannot be blank";
    }
    if (values.amount === null ||
      values.amount === undefined ||
      values.amount === "") {
      errors.amount = "Amount cannot be blank";
    }
    if(this.state.pnpentry===false){ 
      if(this.state.chargeschecked===false){
    if(!values.expense){
      errors.expense= "Mapping Ladeger cannot be blank";
    }
  }
  }
    
    // if(debtname===credname){
    //   errors.prledger = "Ledgers cannot be same";
    //   errors.prledger1 = "Ledgers cannot be same";
    // }
  //   let deb = values.debittotal;
  //   let cre = values.credittotal;
  //   let a = values.debt.map((e) => e.name);

  //   if (deb !== cre) {
  //     


  //  if(a.value==="dr"){
  //    alert("Debit cannot be blank")
  //  }

    if (
      values.narration === null ||
      values.narration === undefined ||
      values.narration === ""
    ) {
      errors.narration = "Narration cannot be blank";
    }
  


    return errors;
  }
  handleSDDate=()=>{
    this.setState({SDdate:this.state.SDdate===true?false:true})
  }

  handlePnpentery=()=>{
    pnpentry=pnpentry===true?false:true
    this.setState({pnpentry:this.state.pnpentry===true?false:true},()=>this.addVal())  
     
  }

  handleChargesEntry=()=>{
    checkedentry=checkedentry===true?false:true
    this.setState({chargeschecked:this.state.chargeschecked===true?false:true},()=>this.addVal())  
     
  }


  addVal(){

    let a =[],b=[]//,c=[]
    b=combdata.filter((e)=>e.shid!==100003)
    // c=combdata.filter((e)=>e.shid===100003)
    a=b.filter((e)=>e.shid!==100004)
   if(this.state.pnpentry===true || this.state.chargeschecked===true){
      this.setState({val2:[],jvinsertiontype:4})
      this.setState({val2:combdata.filter((e)=>e.shid===100004 || e.shid===100003 || e.shid===100013 || e.shid===100014)})
      // val2=[]
      //  val2=combdata.filter((e)=>e.shid===100004 || e.shid===100003)
   }else if(this.state.pnpentry===false){
       this.setState({val2:[],jvinsertiontype:5})
      this.setState({val2:a.filter(e=>e.rule!==2)})
      // val2=[]
      // val2=a.filter(e=>e.rule!==2)
      // console.log(val2)

   }else if(this.state.chargeschecked){
    this.setState({jvinsertiontype:3})
   }
  }



  render() {
   
    let sub = subacclist,prledger,prledger1;
   // let tcsdata
   // tcsdata=directtax
    val3=indirecttax
    val=combdata.filter(e=>e.rule!==2) 
    
    return (
      <div>
        <Formik
          initialValues={{
            sub,prledger,prledger1,amount:'',
            val,expense:'',narration:'',
          // prledger: [{ name: null, value: null, d1: "d" }],
            tax: [{ name: null, value: null, percent:null,number:null }],
          // prledger1: [{ name: null, value: null ,number:null}],
          }}
          onSubmit={this.onSubmit}
          validateOnChange={false}
          validate={this.validate}
          enableReinitialize={true}
          
        >
          {({ setFieldValue, values }) => (
            <Form autoComplete="off" >

<div className="form-row col-md-12 text-left form-switch mt-3 mb-4">

<fieldset className="form-group col-md-2 text-left ">
<span className="text-left textColour">Charges Entry </span>
</fieldset>
<fieldset className="form-group col-md-3 text-left ">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.chargeschecked} onChange={this.handleChargesEntry} name="checkePnp" />}/>
    <span className="text-left textColour">{this.state.chargeschecked===false?"No":"Yes"}</span>
  </fieldset>

  {this.state.chargeschecked===false?<>
 <fieldset className="form-group col-md-3 text-left ">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.pnpentry} onChange={this.handlePnpentery} name="checkePnp" />}/>
     <span className="text-left textColour">{this.state.pnpentry===false?"Non Payment Entry":"Payment Entry"}</span>
 </fieldset>

   {this.state.pnpentry===false?
  <fieldset className="form-group col-md-3  text-left ">
  <FormLabel>Entry Type :</FormLabel>{this.state.typeofentrydata.map((option) => {return (
  <label key={option.id}>
  <div className="form-group col-md-12 mt-0 mb-0">
  <div className="radio-item mt-0 mb-0 text-sm">
  <input type="radio" checked={this.state.tcschecked === option.id ? true : false} key={option.id}
  onChange={this.onTcsChange.bind(this, option)} style={{ marginRight: 8 ,marginTop: 25 }} value={option.id}/>
  {option.name}
  </div>
  </div>
   </label>); })}
   </fieldset>
   : <fieldset className="form-group col-md-3  text-left"></fieldset>}
  </>:""}

  <div className="form-row col-md-12 text-left">
  <div className="form-group col-md-2 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.SDdate} onChange={this.handleSDDate} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.SDdate===false?"System Date":"Custom Date"}</span>
       </div>
       {this.state.SDdate===true?
     <div className="form-group col-md-3 text-left">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid  justifyContent="space-around">
    <DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
    id="date-picker-dialog" inputVariant="standard"
    minDate={new Date(moment(transactionFrom).format("yyyy-MM-DD"))}
    maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
    format="dd/MM/yyyy HH:mm" value={this.state.fsd}
    // label="Custom Date" 
    InputAdornmentProps={{position: "start"}} onChange={this.handleFsdDateChange}
    renderInput={(params) => <TextField {...params} />}autoOk={true}/>
    </Grid></LocalizationProvider></div>
     :""}
     </div>

<fieldset className="form-group col-md-11 mt-1 mb-4">
  <Autocomplete id="prledger" className="debit-select" name="prledger" value={"prledger" ? undefined : undefined}
  options={this.state.chargeschecked===false?val.sort((a, b) => -a.subname.localeCompare(b.subname)):this.state.expval.sort((a, b) => -a.subname.localeCompare(b.subname))} 
  groupBy={(option) => option.ledgroupname!==null?option.subname + " - " +option.ledgroupname:option.subname}   getOptionLabel={(option) => option.name}  
  onChange={(e, value) => {setFieldValue("prledger", value);}}
  renderInput={(params) => (
  <FormikTextField {...params} type="text" name="prledger"  label="To" className="form-control"
  size='small' 
  variant="standard" fullWidth /> )} /> </fieldset> 
 

<fieldset className="form-group col-md-6">
  <Autocomplete id="prledger1" className="debit-select" name="prledger1" value={"prledger1" ? undefined : undefined}
  options={this.state.val2.sort((a, b) => -a.subname.localeCompare(b.subname))}
  groupBy={(option) =>this.state.pnpentry===true || this.state.chargeschecked===true?option.th==="TH3"?option.subname:<span className='text-red'>{option.subname}</span>:option.subname + " - " +option.ledgroupname}
  getOptionLabel={(option) => option.name+"("+option.amount+")"}  
  onChange={(e, value) => {setFieldValue("prledger1", value);}}
  renderInput={(params) => (
  <FormikTextField {...params} type="text" name="prledger1" label="From" className="form-control" 
  size='small' 
  variant="standard" fullWidth /> )} /></fieldset> 


  <fieldset className="form-group col-md-5 align-right text-sm "> 
    <FormikTextField name="amount"  className="form-group col-md-12 align-right text-sm"
    label="Amount" type="number" 
    onBlur={(e)=>this.addValnew(e.target.value)} style={{ innnerPadding: 0 }} 
    size="small" variant="standard" fullWidth/>
   </fieldset>

{this.state.pnpentry===false && debtotal!==""?<>  
{this.state.chargeschecked===false && debtotal!==""?<>  
 <fieldset className="form-group col-md-10 text-sm float-left mt-4">
  <label className="text-sm">
  <Checkbox checked={this.state.taxchecked} onChange={this.handleCheckboxChange} />
   Taxes </label> </fieldset>



{/* ----------------------Indirect Tax Section --------------------------------               */}


{this.state.taxchecked===true ?
<table className="form-group col-md-12 text-sm"> 
<tbody>
<FieldArray name="tax" render={(arrayHelpers) => (
<>
{values.tax && values.tax.length > 0? values.tax.map((tax, index) => (
  <tr key={index}>
  <td className="text-sm">
  <fieldset className="form-group col-md-12 text-sm">
  <FastField name={`tax[${index}].name`} validate={(value) => value === undefined ||value === null || taxdupe===1? "Please Select a valid Tax Ledger": undefined}>
  { ({meta}) => 
   <Autocomplete id="tax" className="text-sm tagSizeSmall" name={`tax[${index}].name`}
    options={val3!==null?val3.sort((a, b) => -a.subname.localeCompare(b.subname)):null}
    size='small' clearOnBlur={true} groupBy={(option) => option.subname} getOptionLabel={(option) => option.name}
     value={`tax[${index}].name`? undefined : undefined}
   onChange={(e, value) => {setFieldValue(`tax[${index}].name`,value);}}
   renderInput={(params) => (
   <div ref={params.InputProps.ref}>
   <FormikTextField style={{pt:0,mt:0,mb:0,pb:0,textsize:1}} {...params} type="text" name={`tax[${index}].name`}
    label={`Tax ${index + 1}`} className="form-group col-md-12 text-sm"  error={meta.touched && meta.error} size='small'
    onBlur={(e) => this.taxLedgers(e.target.value,values.tax)} variant="standard" fullWidth/></div>)} />}
     </FastField> </fieldset> </td>

     <td className="text-sm">
      <fieldset className="form-group col-md-11 align-right text-sm">
     <FastField name={`tax[${index}].percent`} validate={(value) => value === null ||
    value === undefined || value === ""? "Tax Percent Cannot be blank": undefined}>
    {() => 
    <FormikTextField name={`tax[${index}].percent`} className="form-group col-md-12 align-right text-sm"
    label="Tax Percentage" type="number" 
    // onBlur={() => this.addTotal(values, index)}
    onBlur={(e) => {setFieldValue(`tax[${index}].value`,e.target.value*debtotal/100);}}
    style={{ innnerPadding: 0 }} size="small" variant="standard"/>}
    </FastField> </fieldset>{" "}</td>

     <td className="text-sm">
      <fieldset className="form-group col-md-11 align-right text-sm">
     <FastField name={`tax[${index}].value`} validate={(value) => value === null ||
    value === undefined || value === ""? "Tax Value Cannot be blank": undefined}>
    {() => 
    <FormikTextField name={`tax[${index}].value`} className="form-group col-md-12 align-right text-sm"
    label={e=> e.target.value!=="Tax Amount"?"Tax Amount":""} type="number" onBlur={() => this.addTaxTotal(values, index)}
    style={{ innnerPadding: 0 }} size="small" variant="standard"/>}
    </FastField> </fieldset>{" "}</td>
    
   <td>{debtotal !== 0 &&  values.tax.length > 0 &&  values.tax.length < 2 ? (<>
   {typeofentry!==3  ?
  <div as="button" to={this} className="fa fa-plus-square text-success"
  onClick={() => arrayHelpers.push(" ")} >{" "} |&nbsp;{" "}
  </div>
  :""}</>) : ""}
  {values.tax.length !== 1 ? (
  <div as="button" to={this} className="fa fa-minus-square text-danger"
    onClick={() => arrayHelpers.remove(index)}>
  </div>
  ) :""}</td> </tr>)) : ""} </> )}/> </tbody></table> :""}</>:""}</>:""}
   {/* ----------------------End of Indirect Tax Section --------------------------------               */}




   {this.state.chargeschecked===false?<>
    <fieldset className="form-group col-md-4 mt-3 mb-4">
  <Autocomplete id="expense" className="debit-select" name="expense" value={"expense" ? undefined : undefined}
  options={this.state.expval.sort((a, b) => -a.subname.localeCompare(b.subname))} groupBy={(option) => option.subname}
  getOptionLabel={(option) => option.name}  
  onChange={(e, value) => {setFieldValue("expense", value);}}
  renderInput={(params) => (
  <FormikTextField {...params} type="text" name="expense" label="Map Expense and Losses" className="form-control"
  size='small' onChange={(e) => this.expLedgers(e.target.value)}
  variant="standard" fullWidth /> )} /> </fieldset> </>:""}

  </div>


  <fieldset className="form-group col-md-12 mb-5">
  <FormikTextField name="narration" className="form-control" type="text" label="Narration" variant="filled" />
  </fieldset>


<fieldset className="form-group col-md-12"></fieldset>
  <div> <fieldset className="form-group col-md-2">
  <button className="btn hovbuttonColour mr-1 btn-sm" type="submit"><i className="fas fa-pen"></i> 
  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}
  </button></fieldset> </div>
             
  <fieldset className="form-group col-md-12"></fieldset>
  <div><fieldset className="form-group col-md-12">
  <label className="text-red">Note : If any selected ledger is wrong or with 
   invalid chacters, please cancel the selection with "x" button in selection and reselect the ledger.
   </label> </fieldset></div>
            </Form>
          )}
        </Formik>
      </div>
      
    );
  }
}
export default withRouter(DirectEntry);
