import {
  Backdrop,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import {
  address,
  address2,
  ccity,
  ccountry,
  clientname,
  companyemail,
  contactnumber,
  cstate,
  currentTenant,
  czip,
  gstin,
  logo,
  thousandsdisplay,
  userid,
} from "../Common/Credentials";
import { spin } from "../Common/NewLoader";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import { ToWords } from "to-words";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { NumericFormat } from "react-number-format";
import Footer from "../Component/Footer";
import AssetsService from "./AssetsService";
import Swal from "sweetalert2";
import withRouter from "../withRouter";

const RentSoldAssetDetails = (props) => {
  const navigate = useNavigate();
  const [id] = useState(
    props === undefined ? "" : props.location.state.data.id
  );
  const [data] = useState(props === undefined ? "" : props.location.state.data);
  const [pagename] = useState(
    props === undefined ? "" : props.location.state.pagename
  );
  const [tabledata] = useState(
    props === undefined
      ? ""
      : props.location.state.pagename === "Rental Details"
      ? props.location.state.data.rentassetsitems
      : props.location.state.pagename === "Sales Assets Details"
      ? props.location.state.data.soldassetsitems
      : props.location.state.pagename === "Rental Quotation Details"
      ? props.location.state.data.rentassetsitemsquotation
      : props.location.state.data.soldassetsitemsquotation
  );
  const [invoicedata, setInvoicedata] = useState([]);
  const [loading,setLoading] = useState(false);

  useEffect(() => {
    Refresh();
  }, []);

  const Refresh = () => {
    setLoading(false)
    if (pagename === "Rental Details") {
      AssetsService.GetInvoiceofRentById(id).then((response) =>
        setInvoicedata(response.data)
      );
    }
    if (pagename === "Sales Assets Details") {
      AssetsService.GetInvoiceofSoldById(id).then((response) =>
        setInvoicedata(response.data)
      );
    }
  };

  const downloadinvoice = (id) => {
    let invdata = invoicedata.find((e) => e.id === id);
    let date = moment(invdata.createdAt).format("DD-MM-yyyy");

    let curdate = moment().format("DD-MM-YYYY HH:mm:ss");
    let title = pagename === "Rental Details" ? "Rental Invoice" : "Invoice";
    let newdata = tabledata;
    newdata = newdata.map((v) => ({ ...v, cgst: v.tax / 2 }));
    newdata = newdata.map((v) => ({ ...v, sgst: v.tax / 2 }));
    // pagename==="Rental Details"?
    // newdata.map((e,i)=>{
    //   newdata[i].amount =`${newdata[i].amount}/${newdata[i].amounttype}`
    //   newdata[i].amountinctax =`${newdata[i].amountinctax}/${newdata[i].amounttype}`
    //   newdata[i].period =`${newdata[i].period}-${newdata[i].periodtype}`
    //   newdata[i].startdate =moment(newdata[i].startdate).format("DD-MM-yyyy")
    // })
    // :
    newdata.map(
      (e, i) =>
        (newdata[i].startdate = moment(newdata[i].startdate).format(
          "DD-MM-yyyy"
        ))
    );

    let col;
    // data.taxrequired === true ? (
    //   <>
    //     {data.taxtype === "GST" &&
    //     ccountry === "India" ? (
    //       data.gsttype === "INTRASTATE" ? (

    //          rentassetsitemscol = [{header:"Asset Id", dataKey:"assetid"},{ header: "Item", dataKey: "itemname" },{ header: "Serial no.", dataKey: "serialno" },{ header: "Amount", dataKey: "amount" },
    //                     {header:"CGST(%)", dataKey:"cgst"},{header:"SGST(%)", dataKey:"sgst"},{header:"Amount Inc. Taxes", dataKey:"amountinctax"},{header:"Startdate",dataKey:"startdate"},{header:"Period",dataKey:"period"}]
    //       ) : data.gsttype === "INTERSTATE" ? (

    //        rentassetsitemscol = [{header:"Asset Id", dataKey:"assetid"},{ header: "Item", dataKey: "itemname" },{ header: "Serial no.", dataKey: "serialno" },{ header: "Amount", dataKey: "amount" },
    //        {header:"IGST(%)", dataKey:"tax"},{header:"Amount Inc. Taxes", dataKey:"amountinctax"},{header:"Startdate",dataKey:"startdate"},{header:"Period",dataKey:"period"}]
    //       ) : (
    //         rentassetsitemscol = [{header:"Asset Id", dataKey:"assetid"},{ header: "Item", dataKey: "itemname" },{ header: "Serial no.", dataKey: "serialno" },{ header: "Amount", dataKey: "amount" },
    //        {header:"UTGST(%)", dataKey:"tax"},{header:"Amount Inc. Taxes", dataKey:"amountinctax"},{header:"Startdate",dataKey:"startdate"},{header:"Period",dataKey:"period"}]
    //       )
    //     ) : data.taxtype === "GST" ? (
    //       rentassetsitemscol = [{header:"Asset Id", dataKey:"assetid"},{ header: "Item", dataKey: "itemname" },{ header: "Serial no.", dataKey: "serialno" },{ header: "Amount", dataKey: "amount" },
    //       {header:"GST(%)", dataKey:"tax"},{header:"Amount Inc. Taxes", dataKey:"amountinctax"},{header:"Startdate",dataKey:"startdate"},{header:"Period",dataKey:"period"}]
    //     ) : (
    //       rentassetsitemscol = [{header:"Asset Id", dataKey:"assetid"},{ header: "Item", dataKey: "itemname" },{ header: "Serial no.", dataKey: "serialno" },{ header: "Amount", dataKey: "amount" },
    //       {header:"VAT(%)", dataKey:"tax"},{header:"Amount Inc. Taxes", dataKey:"amountinctax"},{header:"Startdate",dataKey:"startdate"},{header:"Period",dataKey:"period"}]
    //     )}
    //   </>
    // ) : (
    //   rentassetsitemscol = [{header:"Asset Id", dataKey:"assetid"},{ header: "Item", dataKey: "itemname" },{ header: "Serial no.", dataKey: "serialno" },{ header: "Amount", dataKey: "amount" },
    //        {header:"Startdate",dataKey:"startdate"},{header:"Period",dataKey:"period"}]
    // )
    data.taxrequired === true ? (
      <>
        {data.taxtype === "GST" && ccountry === "India"
          ? data.gsttype === "INTRASTATE"
            ? (col = [
                { header: "Asset Id", dataKey: "assetid" },
                { header: "Item", dataKey: "itemname" },
                { header: "Serial no.", dataKey: "serialno" },
                { header: "Amount", dataKey: "amount" },
                { header: "CGST(%)", dataKey: "cgst" },
                { header: "SGST(%)", dataKey: "sgst" },
                { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
              ])
            : data.gsttype === "INTERSTATE"
            ? (col = [
                { header: "Asset Id", dataKey: "assetid" },
                { header: "Item", dataKey: "itemname" },
                { header: "Serial no.", dataKey: "serialno" },
                { header: "Amount", dataKey: "amount" },
                { header: "IGST(%)", dataKey: "tax" },
                { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
              ])
            : (col = [
                { header: "Asset Id", dataKey: "assetid" },
                { header: "Item", dataKey: "itemname" },
                { header: "Serial no.", dataKey: "serialno" },
                { header: "Amount", dataKey: "amount" },
                { header: "UTGST(%)", dataKey: "tax" },
                { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
              ])
          : data.taxtype === "GST"
          ? (col = [
              { header: "Asset Id", dataKey: "assetid" },
              { header: "Item", dataKey: "itemname" },
              { header: "Serial no.", dataKey: "serialno" },
              { header: "Amount", dataKey: "amount" },
              { header: "GST(%)", dataKey: "tax" },
              { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
            ])
          : (col = [
              { header: "Asset Id", dataKey: "assetid" },
              { header: "Item", dataKey: "itemname" },
              { header: "Serial no.", dataKey: "serialno" },
              { header: "Amount", dataKey: "amount" },
              { header: "VAT(%)", dataKey: "tax" },
              { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
            ])}
      </>
    ) : (
      (col = [
        { header: "Asset Id", dataKey: "assetid" },
        { header: "Item", dataKey: "itemname" },
        { header: "Serial no.", dataKey: "serialno" },
        { header: "Amount", dataKey: "amount" },
      ])
    );

    const toWords = new ToWords();
    var totalinwords = toWords.convert(data.totalamountinctax);
    let y = 0;
    const doc = new jsPDF("p", "pt", "a4");
    doc.setFontSize(11);
    doc.text("To,", 40, 180);
    doc.text(`${data.customer}`, 50, 195);
    doc.text(`Ref ID : ${data.id}`, 50, 210);
    doc.text(`Invoice : ${invdata.custominvid}`, 50, 225);
    doc.text(`Date : ${date}`, 50, 240);

    if (pagename === "Rental Details") {
      autoTable(doc, {
        theme: "grid",
        startY: 250,
        columnStyles: { 3: { halign: "right" }, 6: { halign: "right" } },
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        body: newdata,
        columns: col,
      });
    } else {
      autoTable(doc, {
        theme: "grid",
        startY: 250,
        columnStyles: { 3: { halign: "right" }, 6: { halign: "right" } },
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        body: newdata,
        columns: col,
      });
    }
    y = doc.autoTable.previous.finalY + 60;
    if (y > 841) {
      doc.addPage([595.28, 841.89]);
      y = 0;
    }
    if (data.taxrequired === true) {
      doc.text(`Total:`, 480, doc.autoTable.previous.finalY + 15, "right");
      doc.text(
        `${data.totalamount}`,
        550,
        doc.autoTable.previous.finalY + 15,
        "right"
      );
      if (data.taxtype === "GST" && ccountry === "India") {
        if (data.gsttype === "INTRASTATE") {
          doc.text(`SGST:`, 480, doc.autoTable.previous.finalY + 30, "right");
          doc.text(
            `${(data.totalamountinctax - data.totalamount) / 2}`,
            550,
            doc.autoTable.previous.finalY + 30,
            "right"
          );
          doc.text(`CGST:`, 480, doc.autoTable.previous.finalY + 45, "right");
          doc.text(
            `${(data.totalamountinctax - data.totalamount) / 2}`,
            550,
            doc.autoTable.previous.finalY + 45,
            "right"
          );
        } else {
          doc.text(`IGST:`, 480, doc.autoTable.previous.finalY + 45, "right");
          doc.text(
            `${data.totalamountinctax - data.totalamount}`,
            550,
            doc.autoTable.previous.finalY + 45,
            "right"
          );
        }
      } else {
        if (data.taxtype === "GST") {
          doc.text(`GST:`, 480, doc.autoTable.previous.finalY + 45, "right");
          doc.text(
            `${data.totalamountinctax - data.totalamount}`,
            550,
            doc.autoTable.previous.finalY + 45,
            "right"
          );
        } else {
          doc.text(`VAT:`, 480, doc.autoTable.previous.finalY + 45, "right");
          doc.text(
            `${data.totalamountinctax - data.totalamount}`,
            550,
            doc.autoTable.previous.finalY + 45,
            "right"
          );
        }
      }
    }
    doc.text(`Grand Total:`, 480, doc.autoTable.previous.finalY + 60, "right");
    doc.text(
      `${data.totalamountinctax}`,
      550,
      doc.autoTable.previous.finalY + 60,
      "right"
    );

    y = doc.autoTable.previous.finalY + 100;
    if (y > 841) {
      doc.addPage([595.28, 841.89]);
      y = 0;
    }
    doc.text(
      `Grand Total in words : ${totalinwords}`,
      40,
      doc.autoTable.previous.finalY + 80
    );
    doc.text(
      `Advance Amount : ${data.advance}`,
      40,
      doc.autoTable.previous.finalY + 95
    );

    y = doc.autoTable.previous.finalY + 160;
    if (y > 841) {
      doc.addPage([595.28, 841.89]);
      y = 0;
    }

    doc.text("Sd/-.", 40, doc.autoTable.previous.finalY + 130);
    doc.text("Sales Team,", 40, doc.autoTable.previous.finalY + 145);
    doc.text(`${clientname}`, 40, doc.autoTable.previous.finalY + 160);

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;
      doc.setFontSize(8);
      doc.text(`${curdate}`, 560, 20, "right");
      doc.addImage(logo, "GIF", 40, 20, 100, 100);
      doc.setFontSize(16);
      doc.text(`${clientname}`, 560, 40, "right");
      doc.setFontSize(10);
      doc.text(`${address}, ${address2}`, 560, 55, "right");
      doc.text(`${ccity}, ${cstate} - ${czip}`, 560, 70, "right");
      doc.text(`${ccountry}`, 560, 85, "right");
      doc.text(`${companyemail}`, 560, 100, "right");
      doc.text(`${contactnumber}`, 560, 115, "right");
      doc.text(`GSTIN:${gstin}`, 560, 130, "right");
      doc.setFontSize(16);
      doc.text(title, 280, 140, "center");
      doc.line(0, 150, 600, 150);

      doc.setFontSize(10);
      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }

    doc.save(`${title}-${invdata.custominvid}.pdf`);
  };

  const handlepdfDownload = () => {
    let date = moment(data.createdAt).format("DD-MM-yyyy");
    let curdate = moment().format("DD-MM-YYYY HH:mm:ss");
    let newdata = tabledata;
    newdata = newdata.map((v) => ({ ...v, cgst: v.tax / 2 }));
    newdata = newdata.map((v) => ({ ...v, sgst: v.tax / 2 }));
    if (pagename === "Rental Details") {
      newdata.map(
        (e, i) =>
          (newdata[i].amount = `${newdata[i].amount}/${newdata[i].amounttype}`)
      );
      newdata.map(
        (e, i) =>
          (newdata[
            i
          ].amountinctax = `${newdata[i].amountinctax}/${newdata[i].amounttype}`)
      );
      newdata.map(
        (e, i) =>
          (newdata[i].period = `${newdata[i].period}-${newdata[i].periodtype}`)
      );
      newdata.map(
        (e, i) =>
          (newdata[i].startdate = moment(newdata[i].startdate).format(
            "DD-MM-yyyy"
          ))
      );
    } else {
      newdata.map(
        (e, i) =>
          (newdata[i].startdate = moment(newdata[i].startdate).format(
            "DD-MM-yyyy"
          ))
      );
    }

    let rentassetsitemscol, soldassetsitemscol;
    data.taxrequired === true ? (
      <>
        {data.taxtype === "GST" && ccountry === "India"
          ? data.gsttype === "INTRASTATE"
            ? (rentassetsitemscol = [
                { header: "Asset Id", dataKey: "assetid" },
                { header: "Item", dataKey: "itemname" },
                { header: "Serial no.", dataKey: "serialno" },
                { header: "Amount", dataKey: "amount" },
                { header: "CGST(%)", dataKey: "cgst" },
                { header: "SGST(%)", dataKey: "sgst" },
                { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
                { header: "Startdate", dataKey: "startdate" },
                { header: "Period", dataKey: "period" },
              ])
            : data.gsttype === "INTERSTATE"
            ? (rentassetsitemscol = [
                { header: "Asset Id", dataKey: "assetid" },
                { header: "Item", dataKey: "itemname" },
                { header: "Serial no.", dataKey: "serialno" },
                { header: "Amount", dataKey: "amount" },
                { header: "IGST(%)", dataKey: "tax" },
                { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
                { header: "Startdate", dataKey: "startdate" },
                { header: "Period", dataKey: "period" },
              ])
            : (rentassetsitemscol = [
                { header: "Asset Id", dataKey: "assetid" },
                { header: "Item", dataKey: "itemname" },
                { header: "Serial no.", dataKey: "serialno" },
                { header: "Amount", dataKey: "amount" },
                { header: "UTGST(%)", dataKey: "tax" },
                { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
                { header: "Startdate", dataKey: "startdate" },
                { header: "Period", dataKey: "period" },
              ])
          : data.taxtype === "GST"
          ? (rentassetsitemscol = [
              { header: "Asset Id", dataKey: "assetid" },
              { header: "Item", dataKey: "itemname" },
              { header: "Serial no.", dataKey: "serialno" },
              { header: "Amount", dataKey: "amount" },
              { header: "GST(%)", dataKey: "tax" },
              { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
              { header: "Startdate", dataKey: "startdate" },
              { header: "Period", dataKey: "period" },
            ])
          : (rentassetsitemscol = [
              { header: "Asset Id", dataKey: "assetid" },
              { header: "Item", dataKey: "itemname" },
              { header: "Serial no.", dataKey: "serialno" },
              { header: "Amount", dataKey: "amount" },
              { header: "VAT(%)", dataKey: "tax" },
              { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
              { header: "Startdate", dataKey: "startdate" },
              { header: "Period", dataKey: "period" },
            ])}
      </>
    ) : (
      (rentassetsitemscol = [
        { header: "Asset Id", dataKey: "assetid" },
        { header: "Item", dataKey: "itemname" },
        { header: "Serial no.", dataKey: "serialno" },
        { header: "Amount", dataKey: "amount" },
        { header: "Startdate", dataKey: "startdate" },
        { header: "Period", dataKey: "period" },
      ])
    );
    data.taxrequired === true ? (
      <>
        {data.taxtype === "GST" && ccountry === "India"
          ? data.gsttype === "INTRASTATE"
            ? (soldassetsitemscol = [
                { header: "Asset Id", dataKey: "assetid" },
                { header: "Item", dataKey: "itemname" },
                { header: "Serial no.", dataKey: "serialno" },
                { header: "Amount", dataKey: "amount" },
                { header: "CGST(%)", dataKey: "cgst" },
                { header: "SGST(%)", dataKey: "sgst" },
                { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
              ])
            : data.gsttype === "INTERSTATE"
            ? (soldassetsitemscol = [
                { header: "Asset Id", dataKey: "assetid" },
                { header: "Item", dataKey: "itemname" },
                { header: "Serial no.", dataKey: "serialno" },
                { header: "Amount", dataKey: "amount" },
                { header: "IGST(%)", dataKey: "tax" },
                { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
              ])
            : (soldassetsitemscol = [
                { header: "Asset Id", dataKey: "assetid" },
                { header: "Item", dataKey: "itemname" },
                { header: "Serial no.", dataKey: "serialno" },
                { header: "Amount", dataKey: "amount" },
                { header: "UTGST(%)", dataKey: "tax" },
                { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
              ])
          : data.taxtype === "GST"
          ? (soldassetsitemscol = [
              { header: "Asset Id", dataKey: "assetid" },
              { header: "Item", dataKey: "itemname" },
              { header: "Serial no.", dataKey: "serialno" },
              { header: "Amount", dataKey: "amount" },
              { header: "GST(%)", dataKey: "tax" },
              { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
            ])
          : (soldassetsitemscol = [
              { header: "Asset Id", dataKey: "assetid" },
              { header: "Item", dataKey: "itemname" },
              { header: "Serial no.", dataKey: "serialno" },
              { header: "Amount", dataKey: "amount" },
              { header: "VAT(%)", dataKey: "tax" },
              { header: "Amount Inc. Taxes", dataKey: "amountinctax" },
            ])}
      </>
    ) : (
      (soldassetsitemscol = [
        { header: "Asset Id", dataKey: "assetid" },
        { header: "Item", dataKey: "itemname" },
        { header: "Serial no.", dataKey: "serialno" },
        { header: "Amount", dataKey: "amount" },
      ])
    );

    // let declaration = "Declaration: We declare that the invoice shows the acctual price of the goods described and the particulers are true and correct."

    const toWords = new ToWords();
    var totalinwords = toWords.convert(data.totalamountinctax);
    let y = 0;
    const doc = new jsPDF("p", "pt", "a4");
    doc.setFontSize(11);
    doc.text("To,", 40, 180);
    doc.text(`${data.customer}`, 50, 195);
    doc.text(`Ref ID : ${data.id}`, 50, 210);
    doc.text(`Date : ${date}`, 50, 225);
    if (pagename === "Rental Details") {
      autoTable(doc, {
        theme: "grid",
        startY: 250,
        columnStyles: { 3: { halign: "right" }, 6: { halign: "right" } },
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        body: newdata,
        columns: rentassetsitemscol,
      });
    } else {
      autoTable(doc, {
        theme: "grid",
        startY: 250,
        columnStyles: { 3: { halign: "right" }, 6: { halign: "right" } },
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        body: newdata,
        columns: soldassetsitemscol,
      });
    }
    y = doc.autoTable.previous.finalY + 60;
    if (y > 841) {
      doc.addPage([595.28, 841.89]);
      y = 0;
    }
    if (data.taxrequired === true) {
      doc.text(`Total:`, 480, doc.autoTable.previous.finalY + 15, "right");
      doc.text(
        `${data.totalamount}`,
        550,
        doc.autoTable.previous.finalY + 15,
        "right"
      );
      if (data.taxtype === "GST" && ccountry === "India") {
        if (data.gsttype === "INTRASTATE") {
          doc.text(`SGST:`, 480, doc.autoTable.previous.finalY + 30, "right");
          doc.text(
            `${(data.totalamountinctax - data.totalamount) / 2}`,
            550,
            doc.autoTable.previous.finalY + 30,
            "right"
          );
          doc.text(`CGST:`, 480, doc.autoTable.previous.finalY + 45, "right");
          doc.text(
            `${(data.totalamountinctax - data.totalamount) / 2}`,
            550,
            doc.autoTable.previous.finalY + 45,
            "right"
          );
        } else {
          doc.text(`IGST:`, 480, doc.autoTable.previous.finalY + 45, "right");
          doc.text(
            `${data.totalamountinctax - data.totalamount}`,
            550,
            doc.autoTable.previous.finalY + 45,
            "right"
          );
        }
      } else {
        if (data.taxtype === "GST") {
          doc.text(`GST:`, 480, doc.autoTable.previous.finalY + 45, "right");
          doc.text(
            `${data.totalamountinctax - data.totalamount}`,
            550,
            doc.autoTable.previous.finalY + 45,
            "right"
          );
        } else {
          doc.text(`VAT:`, 480, doc.autoTable.previous.finalY + 45, "right");
          doc.text(
            `${data.totalamountinctax - data.totalamount}`,
            550,
            doc.autoTable.previous.finalY + 45,
            "right"
          );
        }
      }
    }
    doc.text(`Grand Total:`, 480, doc.autoTable.previous.finalY + 60, "right");
    doc.text(
      `${data.totalamountinctax}`,
      550,
      doc.autoTable.previous.finalY + 60,
      "right"
    );

    y = doc.autoTable.previous.finalY + 100;
    if (y > 841) {
      doc.addPage([595.28, 841.89]);
      y = 0;
    }
    doc.text(
      `Grand Total in words : ${totalinwords}`,
      40,
      doc.autoTable.previous.finalY + 80
    );
    doc.text(
      `Advance Amount : ${data.advance}`,
      40,
      doc.autoTable.previous.finalY + 95
    );

    y = doc.autoTable.previous.finalY + 160;
    if (y > 841) {
      doc.addPage([595.28, 841.89]);
      y = 0;
    }

    doc.text("Sd/-.", 40, doc.autoTable.previous.finalY + 130);
    doc.text("Sales Team,", 40, doc.autoTable.previous.finalY + 145);
    doc.text(`${clientname}`, 40, doc.autoTable.previous.finalY + 160);

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;
      doc.setFontSize(8);
      doc.text(`${curdate}`, 560, 20, "right");
      doc.addImage(logo, "GIF", 40, 20, 100, 100);
      doc.setFontSize(16);
      doc.text(`${clientname}`, 560, 40, "right");
      doc.setFontSize(10);
      doc.text(`${address}, ${address2}`, 560, 55, "right");
      doc.text(`${ccity}, ${cstate} - ${czip}`, 560, 70, "right");
      doc.text(`${ccountry}`, 560, 85, "right");
      doc.text(`${companyemail}`, 560, 100, "right");
      doc.text(`${contactnumber}`, 560, 115, "right");
      doc.text(`GSTIN:${gstin}`, 560, 130, "right");
      doc.setFontSize(16);
      doc.text(pagename, 280, 140, "center");
      doc.line(0, 150, 600, 150);

      doc.setFontSize(10);
      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }
    doc.save(`${pagename}-${data.customid}.pdf`);
  };

  const generateInvoice = () => {
    let invdata = {
      customer: data.customer,
      custid: data.custid,
      tenantid: currentTenant,
      createdby: userid,
      updatedby: userid,
      rentasset: pagename === "Rental Details" ? data.id : null,
      soldassets: pagename === "Rental Details" ? null : data.id,
      totalamount: data.totalamount,
      totalamountinctax: data.totalamountinctax,
      advance: data.advance>=data.totalamountinctax?data.totalamountinctax:data.advance,
    };

    Swal.fire({
      title: "Generate Invoice",
      text: "Do you want to generate invoice?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        setLoading(true);
        AssetsService.NewAssetInvoice(invdata)
          .then(() => {
            setLoading(false)
            Swal.fire({
              title: "Invoice has been generated successfully",
              text: "",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
            Refresh();
          })
          .catch(() => {
            setLoading(false)
            Swal.fire({
              title: "",
              text: "An error occured please try after sometime",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
          });
      }
    });
  };

  const terminateRentalAsset = () => {
    Swal.fire({
      title: "Terminate Rental Asset",
      text: "Are you sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.value) {
        setLoading(true)
        AssetsService.TerminateRentalasset(id)
          .then(() => {
            setLoading(false)
            Swal.fire({
              title: "Rental Asset has been Terminated successfully",
              text: "",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
            navigate("/asset", {
              state: {
                sold: props.location.state.sold,
                rent: props.location.state.rent,
                active: props.location.state.active,
                type: props.location.state.type,
                stype: props.location.state.stype,
              },
            });
          })
          .catch(() => {
            setLoading(false)
            Swal.fire({
              title: "",
              text: "An error occured please try after sometime",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
          });
      }
    });

    // AssetsService.TerminateRentalasset(id).then(()=>
    //   history.push('/asset')
    // )
  };

  const completerRentalAsset = () => {
    Swal.fire({
      title: "Complete Rental Asset",
      text: "Are You Sure?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
    }).then((result) => {
      setLoading(true)
      if (result.value) {
        setLoading(false)
        AssetsService.CompleteRentalasset(id)
          .then(() => {
            Swal.fire({
              title: "Rental Asset has been Completed successfully",
              text: "",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
            navigate("/asset", {
              state: {
                sold: props.location.state.sold,
                rent: props.location.state.rent,
                active: props.location.state.active,
                type: props.location.state.type,
                stype: props.location.state.stype,
              },
            });
          })
          .catch(() => {
            setLoading(false)
            Swal.fire({
              title: "",
              text: "An error occured please try after sometime",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
          });
      }
    });
    // AssetsService.CompleteRentalasset(id).then(()=>
    //   history.push('/asset')
    // )
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/asset" }}
                state={{
                  sold: props.location.state.sold,
                  rent: props.location.state.rent,
                  active: props.location.state.active,
                  type: props.location.state.type,
                  stype: props.location.state.stype,
                }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/assetmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link
                to={{ pathname: "/asset" }}
                state={{
                  sold: props.location.state.sold,
                  rent: props.location.state.rent,
                  active: props.location.state.active,
                  type: props.location.state.type,
                  stype: props.location.state.stype,
                }}
              >
                Assets
              </Link>
            </li>
            <li className="breadcrumb-item active">{pagename}</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              {data === "" ? (
                <div>{spin}</div>
              ) : (
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">{pagename}</h3>
                    <>
                      <button
                        className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-envelope text-md ml-1"
                        data-tip
                        data-for="mail"
                        onClick={() => this.handleShow()}
                      ></button>
                      <button
                        className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md"
                        data-tip
                        data-for="pdf"
                        onClick={handlepdfDownload}
                      ></button>
                      {/* //<Tooltip id="mail" place="top" effect="solid">
                        Send Mail
                      </Tooltip>
                      //<Tooltip id="pdf" place="top" effect="solid">
                        Download pdf
                      </Tooltip> */}
                    </>
                  </div>
                  <div className="card-body">
                  <Backdrop
                          sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={loading}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                    <table className="float-left">
                      <tbody>
                        <tr>
                          <td> Id</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.customid}</td>
                        </tr>
                        <tr>
                          <td>Customer</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.customer}</td>
                        </tr>
                        <tr>
                          <td>Created By</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.createdby}</td>
                        </tr>
                        <tr>
                          <td>Date</td>
                          <td>
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {moment(data.createdAt).format("DD-MM-YYYY HH:mm")}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Amount</td>
                          <td>
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            <NumericFormat
                              displayType={"text"}
                              value={data.totalamount}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              disabled={true}
                              thousandSeparator={true}
                              thousandsGroupStyle={thousandsdisplay}
                            ></NumericFormat>
                          </td>
                        </tr>
                        {data.taxrequired === true ? (
                          <tr>
                            <td>Total Amount Inc. Tax</td>
                            <td>
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              <NumericFormat
                                displayType={"text"}
                                value={data.totalamountinctax}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                        <tr>
                          <td>Advance</td>
                          <td>
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            <NumericFormat
                              displayType={"text"}
                              value={data.advance}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              disabled={true}
                              thousandSeparator={true}
                              thousandsGroupStyle={thousandsdisplay}
                            ></NumericFormat>
                          </td>
                        </tr>
                        {data.taxrequired === true ? (
                          <>
                            <tr>
                              <td>Tax Type</td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.taxtype}</td>
                            </tr>
                            {data.gsttype === "NA" || data.taxtype === "VAT" ? (
                              ""
                            ) : (
                              <tr>
                                <td>GST Type</td>
                                <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.gsttype}</td>
                              </tr>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        
                      </tbody>
                    </table>
                    
                    {invoicedata.length === 0 ? (
                          ""
                        ) : (
                    <table className="float-right">
                      <tbody>
                          <tr>
                            <td>Invoice</td>
                            <td>
                              &nbsp;&nbsp;:&nbsp;&nbsp;
                              {invoicedata.map((e,idx) => (
                                <Link
                                  key={idx}
                                  to={{ pathname: "/assetinvoicedetails" }}
                                  state={{
                                    data:
                                      pagename === "Rental Details"
                                        ? { rentasset: id,custominvid : e.custominvid}
                                        : { soldassets: id,custominvid:e.custominvid },
                                    pagename:
                                      pagename === "Rental Details"
                                        ? "Rental Invoice"
                                        : "Excess Sales Invoice",
                                    type: "Asset Invoice",
                                    temp: false,
                                  }}
                                >{e.custominvid}&nbsp;&nbsp;&nbsp;&nbsp;</Link>
                              ))}{" "}
                            </td>
                          </tr>
                        </tbody>
                        </table>
                        )}

                    <TableContainer>
                      <Table className="table-sm my-3">
                        <TableHead>
                          <TableRow>
                            <TableCell className="text-center">
                              AssetId
                            </TableCell>
                            <TableCell className="text-center">Item</TableCell>
                            <TableCell className="text-center">
                              Asset Type
                            </TableCell>
                            <TableCell className="text-center">
                              Serial No.
                            </TableCell>
                            <TableCell className="text-center">
                              Amount
                            </TableCell>
                            {data.taxrequired === true ? (
                              <>
                                {data.taxtype === "GST" &&
                                ccountry === "India" ? (
                                  data.gsttype === "INTRASTATE" ? (
                                    <>
                                      <TableCell className="text-center">
                                        CGST(%)
                                      </TableCell>
                                      <TableCell className="text-center">
                                        SGST(%)
                                      </TableCell>
                                    </>
                                  ) : data.gsttype === "INTERSTATE" ? (
                                    <TableCell className="text-center">
                                      IGST(%)
                                    </TableCell>
                                  ) : (
                                    <TableCell className="text-center">
                                      UTGST(%)
                                    </TableCell>
                                  )
                                ) : data.taxtype === "GST" ? (
                                  <TableCell className="text-center">
                                    GST(%)
                                  </TableCell>
                                ) : (
                                  <TableCell className="text-center">
                                    VAT(%)
                                  </TableCell>
                                )}
                                <TableCell className="text-center">
                                  Amount Inc. Taxes
                                </TableCell>
                              </>
                            ) : (
                              ""
                            )}
                            {pagename === "Rental Details" ? (
                              <>
                                <TableCell className="text-center">
                                  Start Date
                                </TableCell>
                                <TableCell className="text-center">
                                  Period
                                </TableCell>
                              </>
                            ) : (
                              ""
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tabledata.map((e, index) => (
                            <TableRow key={index}>
                              <TableCell className="text-center">
                                {e.assetid}
                              </TableCell>
                              <TableCell className="text-center">
                                {e.itemname}
                              </TableCell>
                              <TableCell className="text-center">
                                {e.assettype}
                              </TableCell>
                              <TableCell className="text-center">
                                {e.serialno}
                              </TableCell>
                              <TableCell className="text-right">
                                {
                                  <NumericFormat
                                    displayType={"text"}
                                    value={e.amount}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                }
                                {pagename === "Rental Details"
                                  ? `/${e.amounttype}`
                                  : ""}
                              </TableCell>
                              {data.taxrequired === true ? (
                                <>
                                  {data.taxtype === "GST" &&
                                  ccountry === "India" ? (
                                    data.gsttype === "INTRASTATE" ? (
                                      <>
                                        <TableCell className="text-center">
                                          {e.tax / 2}%
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.tax / 2}%
                                        </TableCell>
                                      </>
                                    ) : (
                                      <TableCell className="text-center">
                                        {e.tax}%
                                      </TableCell>
                                    )
                                  ) : (
                                    <TableCell className="text-center">
                                      {e.tax}%
                                    </TableCell>
                                  )}
                                  <TableCell className="text-right">
                                    {
                                      <NumericFormat
                                        displayType={"text"}
                                        value={e.amountinctax}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    }
                                    {pagename === "Rental Details"
                                      ? `/${e.amounttype}`
                                      : ""}
                                  </TableCell>
                                </>
                              ) : (
                                ""
                              )}
                              {pagename === "Rental Details" ? (
                                <>
                                  <TableCell className="text-center">
                                    {moment(e.startdate).format("DD-MM-YYYY")}
                                  </TableCell>
                                  <TableCell className="text-center">
                                    {e.period} {e.periodtype}
                                    {/* {e.periodyears} Years{" "}
                                                {e.periodmonths} Monhts{" "}
                                                {e.perioddays} Days{" "} */}
                                  </TableCell>
                                </>
                              ) : (
                                ""
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {invoicedata.length === 0 &&
                    (pagename === "Rental Details" ||
                      pagename === "Sales Assets Details") ? (
                      <button
                        className="btn btn-sm hovbuttonColour mr-1"
                        onClick={generateInvoice}
                      >
                        Generate Invoice
                      </button>
                    ) : (
                      ""
                    )}
                    {pagename === "Rental Details" ? (
                      <>
                        <button
                          className="btn btn-sm hovbuttonColour mr-1"
                          onClick={completerRentalAsset}
                        >
                          Completed
                        </button>
                        <button
                          className="btn btn-sm deletebutton"
                          onClick={terminateRentalAsset}
                        >
                          Terminate
                        </button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(RentSoldAssetDetails);
