import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../withRouter.js";
import { userid } from "../../../Common/Credentials.js";
import { spin } from "../../../Common/NewLoader.jsx";
import { appmasterid } from "../../../Common/Permissions.js";
import CustomToolbar from "../../../CommonComponents/CustomToolbar.js";
import Header from "../../../Component/Header.js";
import Menu from "../../../Component/Menu.js";
import UserService from "../../../users/UserService.js";
import CategoryServices from "../CategoryServices.js";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});
class Categorylvl3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      mcategory:this.props.location.state=== undefined?'':this.props.location.state.mcategory,
      mcatid:this.props.location.state=== undefined?'':this.props.location.state.mcatid,
      category2id:this.props.location.state=== undefined?'':this.props.location.state.categorylvl2,
      categorylvl3: [],
      category2: '',
      categoryType:this.props.location.state=== undefined?'':this.props.location.state.categoryType,
      loading: false,
    };
    this.deletecategory = this.deletecategory.bind(this);
  }

  deletecategory(id) {
    CategoryServices.deleteCategory(id).then(
        ()=>this.props.navigate('/category')
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    UserService.UserPermissions(userid).then((response) =>
      this.setState({ up: response.data })
    );
    CategoryServices.getCategorylvl2ById(this.props.location.state.categorylvl2).then(res=>{
      this.setState({category2:res.data.name,categorylvl3:res.data.category})
    })
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: true,
          display: false,
          viewColumns: false,
          sortOrder: "asc",
        },
      },
      {
        name: "category",
        label: "Category",
        options: { filter: true, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <span>
                  {this.state.mcategory}
              </span>)
         },
        },
      },
      {
        name: "category2 ",
        label: "Category lvl 2",
        options: { filter: true, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <span>
                  {this.state.category2}
              </span>)
         },
        },
      },
      {
        name: "category",
        label: "Category lvl 3",
        options: { filter: true, sort: true },
      },
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          // empty: true,
          // display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
              <span
                as="button"
                data-tip
                data-for="delete"
                className="btn btn-sm deletebutton"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you wish to delete Category ${tableMeta.rowData.find(
                        (e, i) => i.valueOf(e)
                      )}?`
                    )
                  )
                    this.deletecategory(tableMeta.rowData.find((e) => e));
                }}
              > Delete</span>
               </>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{pathname: "/newcategorylvl3"}}
                  state= {{ id: -1, name: "New Category Level3",
                  categorylvl2:this.state.category2,catidlvl2:this.state.category2id,
                    mcategory:this.state.mcategory,mcatid:this.state.mcatid,categoryType:this.state.categoryType
                }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
          
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <span
                as="button"
                className=" text-secondary"
                onClick={() => this.props.navigate(-1)}
              >
                ❮ Back &nbsp;&nbsp;
              </span>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to={{ pathname: "/category"}} state={{ message: "" }}>Category</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <span   as="button"
                onClick={() => this.props.navigate(-1)}>Category level 2</span>
              </li>
              <li className="breadcrumb-item active">Category level 3</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    {/* /.card */}
                      <div className="card-body">
                        <div>
                          {this.state.loading === true ? (
                            <div className="text-center">{spin}</div>
                          ) : (
                            <ThemeProvider theme={theme}> 
                            <MUIDataTable
                              className="table-sm px-3"
                              title={`${this.state.mcategory} -> ${this.state.category2} -> Category level 3`}
                              data={this.state.categorylvl3}
                              columns={columns}
                              options={options}
                            />
                            </ThemeProvider>
                          )}
                        </div>
                      </div>
                      {/* /.card-body */}
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Categorylvl3);
