import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import { userid } from '../../Common/Credentials.js';
import { spin } from '../../Common/NewLoader.jsx';
import { appmasterid } from '../../Common/Permissions.js';
// import { currentTenant } from '../../Common/Credentials.js';
import AccTopService from './AccTopService.js';



class AcctopDataTable extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        message:'',
        AccTop:[ ],
        loading:false,
        // UserPermissions:'',

    }
    this.refreshAccTop=this.refreshAccTop.bind(this)
    this.deleteAccTop=this.deleteAccTop.bind(this)
    this.updateAccTop=this.updateAccTop.bind(this)

  }



  deleteAccTop(id){
    this.refreshAccTop()
   AccTopService.deleteAccTop(id)
   .then(response =>
    this.setState({message:response.data}),
    this.refreshAccTop()
    )
}

updateAccTop(id){
  AccTopService.retriveAccTop(id)
    .then(
      response => {
          this.setState({AccTop:response.data})
      })}


  
  refreshAccTop(){
  AccTopService.retriveAllAccTop()
    .then(
      response => {
          this.setState({AccTop:response.data})
          const script = document.createElement("script");
        script.src= '../../../js/content.js';
        script.async= true;
        document.body.appendChild(script);
        this.setState({loading:false})
        }
        
      )   
    
  }

    componentDidMount () {

      if (this.props.history.location.state.message===null){
        this.setState({message:''})
      }else{
        this.setState({message:this.props.history.location.state.message})
      }
      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }
      // UserService.UserPermissions(userid).then(response=>this.setState({UserPermissions:response.data}))
      this.setState({loading:true})
      this.refreshAccTop();

     }

  

    

    render() {
      
      let confirm = this.state.message
      confirm.search("Deleted")?
      confirm = <div className="text-center text-success text-uppercase">{this.state.message}</div>
      :
      confirm = <div className="text-center text-warning text-uppercase">{this.state.message}</div>
        return (
            <div>

      <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title text-uppercase">AccTop</h3>
            </div>
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/index">Home</Link>
              </li>
              
              <li className="breadcrumb-item active">AccTop</li>
            </ol>
          {/* <div className="text-center text-success text-uppercase">{this.state.message}</div> */}
          {confirm}
            <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              
              {/* /.card */}
              <div className="card">
                
                {/* <div className="card-header">
                  <h3 className="card-title ">
                  AccTop 
                  </h3>
                </div> */}
                {/* /.card-header */}
                
                <div className="card-body">
                <div className="text-center">{this.state.loading === true ? spin : ''}</div>
                <div>
                  
                {/* <button className="float-right btn btn-warning" onClick={this.addNewUser}>Add New User</button> */}
                {appmasterid === '3' || appmasterid === '2' ?
                <Link as="button" className="float-right btn btn-sm btn-warning" to={{pathname: '/newacctop'}} state ={ {id: -1,pagename:'New AccTop'}}>New AccTop</Link>
                :''}
                </div>
                
       <table
       id="example1"
       className="table table-bordered table-striped">
      
       <thead>
         <tr>
         <th>Acc Top Head</th>
         <th>Action</th>
         </tr>
       </thead>
       
       <tbody>
         
         {
           this.state.AccTop.map(
             AccTop => 
             <tr key={AccTop.id}>
               <td className="text-blue">{AccTop.name}</td>
               {/* {tdata} */}
               
               {/* <td hidden={AccTop.createdby===0?true:false}>
                 <Link as="button" data-tip data-for="edit" className="fa fa-edit" to={{pathname: '/newacctop' }} state ={ {id: AccTop.id,pagename:'Update AccTop'}}}></Link> | 
          <li data-tip data-for="delete" className="fa fa-trash text-danger" onClick={() => { if (window.confirm('Are you sure you wish to delete AccTop?')) this.deleteAccTop(AccTop.id)}}></li></td>  */}
        
        {AccTop.createdby===0?
        <td className="text-orange">Default Head</td>:
        <td><Link as="button" data-tip data-for="edit" className="fa fa-edit" to={{pathname: '/newacctop' }} state ={ {id: AccTop.id,pagename:'Update AccTop'}}></Link> | 
        <li data-tip data-for="delete" className="fa fa-trash text-danger" onClick={() => { if (window.confirm('Are you sure you wish to delete AccTop?')) this.deleteAccTop(AccTop.id)}}></li>
        </td>}          
               {/* <td >{users.roles.map((id, name) => <li key={id}>{name}</li>)}</td> */}
               {/* {tenants.city.map((id, name) => <td key={id}>{id.name}</td>)} */}
               
   
               {/* <td><button className="btn btn-success" onClick={() => this.updateUserClicked(users.id)}>Update</button></td> */}
               {/* <td><button className="btn btn-sm btn-danger" onClick={() => this.deleteUser(users.id)}>Delete</button></td> */}
             </tr>
           )
         }
        
       </tbody>
                                     
     </table>  
             
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>


        
     </div>
                
            </div>
             
          
        )
       
    }
    
}
export default withRouter(AcctopDataTable)