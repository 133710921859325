import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EmployeeService from "./EmployeeService";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import Footer from "../Component/Footer";
import withRouter from "../withRouter";
import { Link } from "react-router-dom";

const EmpStatusDetails = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllData();
  }, []);

  
  const getAllData=()=>{
    EmployeeService.getAllSalProgress().then((e)=>{
      setData(e.data);
      console.log(e.data);
    });
    console.log(data,"RR");
  }

  return (
    <>
    <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
          <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/employeesalary"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/accounts">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/employeesalary">Salary Progress</Link>
            </li>
            <li className="breadcrumb-item active">
                      Employee Salary Progress
                    </li>
          </ol>
          <div className='card-header'>
                      Employee Salary Progress
                    </div>
          <div className="card-body">
            <div className="row  my-3 ">
              <div className="col-4 text-center ml-2"></div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                      <TableCell>Id</TableCell>
                        <TableCell align="right">Year</TableCell>
                        <TableCell align="right">month</TableCell>
                        {/* <TableCell align="right">EmpId</TableCell> */}
                        <TableCell align="right">EmpId</TableCell>
                        <TableCell align="right">Name</TableCell>
                        <TableCell align="right">Total Deduction</TableCell>
                        <TableCell align="right">NetPay</TableCell>
                        <TableCell align="right">Tax</TableCell>
                        <TableCell align="right">Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((e) => (
                        <TableRow
                          key={e.empId}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                        >
                          <TableCell component="th" scope="e">
                            {e.id}
                          </TableCell>
                          <TableCell align="right">{e.year}</TableCell>
                          <TableCell align="right">{e.month}</TableCell>
                          <TableCell align="right">{e.emp.empid}</TableCell>
                          <TableCell align="right">{e.emp.empFullName}</TableCell>
                          <TableCell align="right">{e.emp.salary.totalDeduction}</TableCell>
                          <TableCell align="right">{e.emp.salary.netpay}</TableCell>
                          <TableCell align="right">{e.emp.salary.professionalTax}</TableCell>
                          <TableCell align="right">{e.status}</TableCell>
                          
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              
          </div>
          
        </div>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(EmpStatusDetails);
