import React, { useEffect, useState } from "react";
import withRouter from "../../withRouter";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, ThemeProvider, createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { spin } from "../../Common/NewLoader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProductionServices from "../ProductionServices";
import { currentTenant } from "../../Common/Credentials";
import moment from "moment";


const theme = createTheme({
  overrides: {
    MuiTable: {
      root: {
        border: [[1, "solid", "#d3d3d3"]],
      },
    },
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
        textAlign: "center !important",
        alignItems: "center",
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
  stickyFooterCell: {
    position: "sticky",
    bottom: 0,
    zIndex: 100,
  },
});


const StoreItemTransactions = () => {
  const history = useLocation();
    const navigate = useNavigate()
    const [id] = useState(history.state.id);
  const [data, setData] = useState([]);
  const [loading,setLoading] = useState([]); 
  const [storeItem,setStoreItem] = useState("")

  useEffect(()=>{
    console.log(id)
    setLoading(true)
    ProductionServices.GetStoreItembyId(id).then((res)=>setStoreItem(res.data))
    ProductionServices.GetStoreItemsTransactionsReport(parseInt(id),currentTenant).then((res)=>{
      setData(res.data)
      setLoading(false)
    }).catch(()=>setLoading(false))
  },[])
  
  const columns = [
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: false,
        sort: true,
        sortOrder: "asc",
        customBodyRender: (value) => {
          return moment(value).format("DD.MM.YYYY hh:mm");
        },
        setCellProps: (value) => ({ style: { textAlign: "center" } }),
      },
    },
    {
      name: "itemname",
      label: "Item",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ align: "center" }),
      },
    },
      {
        name: "custombatchid",
        label: "Batch",
        options: {
          filter: false,
          sort: true,
          setCellProps: () => ({ align: "center" }),
        },
      },
      {
        name: "refid",
        label: "Refid",
        options: {
          filter: false,
          sort: true,
          setCellProps: () => ({ align: "center" }),
        },
      },
    {
      name: "totalstock",
      label: "Quantity",
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ align: "center" }),
      },
    },
    {
      name: "finalproduct",
      label: "Final Product",
      options: {
        filter: false,
        sort: true,
        sortOrder: "asc",
        customBodyRender: (value) => 
          value !== null && value.length>0?
            <TableContainer>
              <Table className="border">
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Pending Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                 {value.map(e=>
                  <TableRow>
                    <TableCell>{e.itemname}</TableCell>
                    <TableCell>{e.quantity} {e.uom}</TableCell>
                    <TableCell>{e.pendingquantity} {e.uom}</TableCell>
                  </TableRow>
                 )}
                </TableBody>
              </Table>
            </TableContainer>
            :""
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    // resizableColumns: true,
  };


  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
          <li className=" text-secondary">
                <span
                  as="button"
                  className=" text-secondary cursorPointer"
                  onClick={() => navigate(-1)}
                >
                  ❮ Back &nbsp;&nbsp;
                </span>
              </li>
            {/* <li className="breadcrumb-item text-secondary">
              <Link to="/production">Home</Link>
            </li> */}
            <li className="breadcrumb-item active">Store Item Transaction</li>
          </ol>
          <div className="card-body">
            <div className="text-center">
              {loading === true ? (
                <div className="text-center">{spin}</div>
              ) : (<>

                {storeItem!==null && storeItem !== ""?
                <fieldset className="col-12 pb-4">
                <TableContainer>
              <Table className="border">
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Batch Id</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Remaining Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{storeItem.itemname}</TableCell>
                    <TableCell>{storeItem.custombatchid}</TableCell>
                    <TableCell>{storeItem.addedquantity}</TableCell>
                    <TableCell>{storeItem.quantity}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            </fieldset>
            :''}

                <ThemeProvider theme={theme}>
                  <MUIDataTable
                    className="table-sm px-3"
                    id="dirstock"
                    title={`Store Item Transactions (${storeItem.itemname})`}
                    data={data}
                    columns={columns}
                    options={options}
                  />
                </ThemeProvider>
             </> )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(StoreItemTransactions);
