import React from "react";
import AuthenticationService from "../Authentication/AuthenticationService";
import { Link, useNavigate } from "react-router-dom";
import {
  clientname,
  currentTenant,
  currentUser,
  logo,
  roles,
  userid,
} from "../Common/Credentials";
import { useIdleTimer } from "react-idle-timer";
import { useLocation } from "react-router-dom";
import {Avatar, Divider, IconButton, Menu, MenuItem, Tooltip} from "@mui/material";
import NotificationServices from "../Notifications/NotificationServices";
import Swal from "sweetalert2";
import { appmaster, screens } from "../Common/Products";
import { appmasterid } from "../Common/Permissions";
import userIcon from "../images/Icons/user.png"
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';

 const Header = () =>{
  // const isUserLoggedIn = AuthenticationService.isUserLoggedIn();
  const navigate = useNavigate();


  let [unread, UnRead] = React.useState(0);
  // let [timerem,settimerem] = React.useState(30000)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (userid === undefined || currentUser === undefined) {
      Swal.fire({
        title: "Session is Lost!",
        text: "Please login Back to resume",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor:'#152f5f',
      }).then(()=>
      navigate("/")
      )
      navigate("/")
    }else{
    
    NotificationServices.GetAllNotifications(userid)
      .then((res) =>
        UnRead(res.data.filter((a) => a.notificationstatus === 0).length)
      )
      .catch((e) => {
        if(e.response!==undefined){
        if (e.response.data.error === "Unauthorized") {
          Swal.fire({
            title: "Session is Lost!",
            text: "Please login Back to resume",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor:'#152f5f',
          }).then(()=>
          navigate("/")
          )
        }
      }
      });
    }
  },[navigate]);

  function logout() {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Logout?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor:'#152f5f',
      cancelButtonText: "No",
      reverseButtons: true
  }).then((result)=>{
      if (result.value) {
        AuthenticationService.logout(currentUser,currentTenant).then
        (() =>{
          Swal.fire({
            title: "You are Logged Out Successfully",
            text: "",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor:'#152f5f',
          }).then(()=>
            navigate("/")
          )
         
          // AuthenticationService.logout(currentUser).then(() => {
          //   alert("You are Logged Out Successfully, Go Back to Login", usertoken);
          //   navigate("/");
          // });
        })
      }
  });
   
  }


  const handleOnIdle = (event) => {   
      AuthenticationService.logout(currentUser,currentTenant).then(() => {
        Swal.fire({
          title: "Session is Lost!",
          text: "Please login Back to resume",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor:'#152f5f',
        }).then(()=>
        navigate("/")
        )
      })
    //  props.navigate(`/`)
  };
  const handleOnActive = event => {}

  const handleOnAction = event => {}

  const { getRemainingTime} = useIdleTimer({
    // 1000 is milliseconds , 60 is seconds , 10 is min
    
    timeout: 1000 * 60 * 20,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

//   setInterval((e) => {
//     if(Math.floor(getRemainingTime() / 1000)===0){}else{
//     if(Math.floor(getRemainingTime() / 1000)<=185){
//     Swal.fire({
//       title: `Session will be lost in ${Math.floor(getRemainingTime() / 60000 ) + 1} min`,
//       text: "",
//       icon: "info",
//       confirmButtonText: "Ok",
//       confirmButtonColor:'#152f5f',
//     })
//     }
//     }
// }, 15000)

  return (
    <nav className="navbar sticky-top navbar-expand navbar-white navbar-light shadow m-0">
      <ul className="navbar-nav">
        <li className="nav-item automargin">
          <div className="nav-link" data-widget="pushmenu" role="button">
            <i className="fas fa-bars " />
          </div>
        </li>

        <Link
          className="nav-link m-0 p-0"
          aria-current="page"
          to="/index"
        >
          <img
            src={logo}
            alt="Logo..."
            width="80"
            height="60"
            className="d-inline-block logoholder"
          />
        </Link>

        <div className="text-dark pl-2 m-auto hidden-text">
          <b>{clientname} ({currentTenant})</b>
        </div>
      </ul>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item automargin">
          <Link
            to="/notifications"
          >
            {/* {unread === 0 ? (
              <> */}
                {/* <i className="far fa-bell" style={{fontSize:'x-large'}} /> */}
                <IconButton
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32,overflow:'visible' }} className="text-newcolor bg-transperent" >
            {unread === 0 ? (<i className="far fa-bell" style={{fontSize:'x-large'}} />)
            :<>
             <i
                  className="far fa-bell automargin textColour shakeanimation" 
                  style={{ color: "#17a2b8",fontSize:'x-large',fontWeight:600 }}
                />
                <span className="badge rounded-circle navbar-badge">
                  {unread>100?99:unread}
                </span>
            </>}
              
              </Avatar>
          </IconButton>
      
            {/* ) : (
              <>
                <i
                  className="far fa-bell automargin textColour" 
                  style={{ color: "#17a2b8",fontSize:'x-large',fontWeight:600 }}
                />
                <span className="badge rounded-circle navbar-badge"  style={{fontSize:'5px',fontWeight:600 }}>
                  {unread>100?99:unread}
                </span>
              </>
            )} */}
          </Link>
        </li>

        {/* Notifications Dropdown Menu */}
        {/* <li className="nav-item dropdown">
          <a className="nav-link d-flex" data-toggle="dropdown" href="fake.url">
           
            <span className="avatar bg-white"><img src={userIcon} className="nav-icon" width="30" alt="user"/></span>
            <span className="m-0 pl-1 hidden-text">
              <h5 className="m-auto textColour">{currentUser}</h5>
              <p className="m-auto text-sm">{roles}</p>
            </span>
            <span className="badge badge-warning navbar-badge"></span>
          </a>
          <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right pb-0" 
          style={{width: '-webkit-fill-available'}}>
           
            <div />
            
             {appmaster==="YES" && (appmasterid === "2" || appmasterid ==="3") && screens.includes("Admin Masters") ?
            <div className="dropdown-item">
              <Link className="textColour pr-2 py-2" to="/settings">
                <i className="fas fa-cog pr-2 py-2" /> Settings
              </Link>
            </div>
            :''}
            {appmaster==="YES" && (appmasterid === "2" || appmasterid ==="3") && screens.includes("Admin Masters")?
            <div className="dropdown-item">
              <Link className="textColour pr-2 py-2" to={{pathname: "/newusers"}} state ={ { id: -1, name: "New User" }}>
               <i className="fa fa-user-plus pr-2 py-2"></i> New User
              </Link>
            </div>
            :''}
            <div className="dropdown-item border-top">
              {
                <span
                  className="text-danger pr-2 py-2 cursorpointer"
                  onClick={() => logout()}
                >
                  <i className="fas fa-sign-out-alt pr-2 py-2" /> Logout
                </span>
              }
            </div>
          </div>
        </li> */}

        <li className="nav-item automargin">

        <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }} className="text-yellow bg-newcolor" >{currentUser && currentUser.toUpperCase().charAt(0)}</Avatar>
          </IconButton>
          <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          {/* <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon> */}
          <div>
          <div className="textColour">{userid}</div>
            <div className="textColour">{currentUser}</div>
            <div className="text-xs">{roles}</div>
          </div>
        </MenuItem>
        <Divider />
        {appmaster==="YES" && (appmasterid === "2" || appmasterid ==="3") && screens.includes("Admin Masters")?
        <MenuItem onClick={()=>navigate({pathname:"/newusers"},{state: { id: -1, name: "New User" }})}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          New user
        </MenuItem>
        :""}
        {appmaster==="YES" && (appmasterid === "2" || appmasterid ==="3") && screens.includes("Admin Masters")?
        <MenuItem onClick={()=>navigate("/settings")}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        :""}
        <MenuItem onClick={() => logout()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
        </li>
      </ul>
      
    </nav>
  );
}
export default Header