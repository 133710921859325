import React, { Component } from 'react'
import AppRoute from './users/Route'

export default class App extends Component {
  render() {
    const spinner = document.getElementById('spinner');

    

if (spinner && !spinner.hasAttribute('hidden')) {
  spinner.setAttribute('hidden', 'true');
}
    return (
      <div>
      
      <AppRoute/>
       
      </div>
    )
  }
}
