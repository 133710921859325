import axios from 'axios'
import { Auth_Proto, Auth_Domain, Auth_Port } from '../Component/consts'

export let currentTenant

class SalesServices {
   //GetTenantdetails
   GetSoIdofProducts(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getproductsobytenantid/${currentTenant}`)
   }
   // Get Customers 
   retriveAllCustomers(tenantId) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/findcustomers/${tenantId}`)
   }

   //Get Customer terms
   GetCustomerTerms(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getcustomerterms/${id}`)
   }

   // Get all Quotation
   GetActiveQuotations(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/activequotationtabledata`,data)
   }

   GetCompletedQuotations(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/completedquotationtabledata`,data)
   }

   GetClosedQuotations(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/closedquotationtabledata`,data)
   }

    // Get Quotation by Id
    GetQuotationbyId(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getquotation/${id}`)
   }
    // Get Active Quotation Id
    GetActiveQuotationId(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getquotationidandcustomidlist`,data)
   }

   //Add New Quotation
   NewQuotation(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/addquotation`, data)
   }

   //Terminate Quotation
   TerminateQO(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/terminatequotation`, data)
   }

   //UpdateQuotationStatus
   UpdateQuotationStatus(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/updatequotationstatus`, data)
   }

   //Get all sale order
   GetSaleOrder(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getallsaleorder`,data)
   }

   //Data to add SaleOrder
   GetData(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getallsaleorderitemsbyorderid/${id}`)
   }

   // Get Stoock and Cost(GrossValue)
   getCost(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getstockgrossvalue/${id}`)
   }

   // Add new SalesOrder
   NewOrdersssssssss(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/addsaleorderitems`, data)
   }

   ConfirmOrders(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/confirmedorder`, data)
   }
   // ItemNames
   // GetItems(currentTenant) {
   //    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales//${currentTenant}`)
   // }

   //  Get Data to Update
   DatatoUpdate(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getdatatoupdatesaleorderitems/${id}`)
   }

   // Updated Data status pending
   UpdateOrder(updateorder) {
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/updatesaleorderitems`, updateorder)
   }

   // Update Data status confirm
   ConfirmUpdateOrders(updatedatalist) {
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/updateconfirmsaleorder`, updatedatalist)
   }

   // Get Data to update
   GetDataToUpdate(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsaleorderbyid/${id}`)
   }

   //GetSaleorderfroadjustment
   GetSoforadjustment(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsoforadjustment/${id}`)
   }

    //GetSaleorderitemsfroadjustment
   GetSoitemsforadjustment(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsoitemsforadjustment/${id}`)
   }

   GetDataforInvoice(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsaleorderbyidforinvoice/${id}`)
   }

   GetInvoiceId(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getinvidofso/${id}`)
   }

   // Delete Sale Order
   DeleteSaleOrder(id,createdby) {
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/deleteorder/${id}/${createdby}`)
   }

   //Status 
   statusData(statusdata) {
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/orderstatus`, statusdata)
   }

   // Requesting for Credit
   RequestCridet(customer) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/requestcredit`, customer)
   }
  
   // Get Order Status to Validate   
   ValidateStatus(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getorderstatus/${id}`,)
   }

   // Delete SaleOrder Item
   deleteSaleOrderItem(id) {
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/deletesaleorderitem/${id}`)
   }

   GetSalesReturns(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getdnreturn`,data)
   }

   GetallSaleOrderOfCustomer(custid) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getallsaleorderbycustid/${custid}`)
   }

   GetProductSoByCustid(custid){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getproductsobycustid/${custid}`)
   }

   GetSoidForReturnsByCustid(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsoidforreturns`,data)
   }

   GetDnidForReturns(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getdnidforreturns`,data)
   }

   GetDnidForReturns2(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getdnidforreturns2/${id}`)
   }

   // GetSoByCustidForInvoice(custid){
   //    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsobycustidforinvoice/${custid}`)
   // }

   getSoByCustidForInvoice(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsobycustidforinvoice`,data)
   }

   GetInvDataOfProducts(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getdndataofproducts/${id}`)
   }

   GetSoidByCustidForDn(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsoidbycustidfordn`,data)
   }
   GetSoidByCustidForDn2(custid){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsoidbycustidfordn2/${custid}`)
   }

   GetInvidByCustidForDn(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getinvidbycustidfordn`,data)
   }

   ApproveDeliveryNote(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/approvedn`,data)
   }

   GetAllSaleorderById(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsaleorderbyid/${id}`)
   }

   

   GetSaleOrderItem(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsaleorderitem/${id}`)
   }

   getApprovedInvoices(currentTenant){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getinvoiceforacounts/${currentTenant}`)
   }

   getDirectInvoices(currentTenant){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getdirectinvoiceforacounts/${currentTenant}`)
   }

   NewSalesReturnOrder(datalist) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/addreturnitem`, datalist)
   }

   GetSalesReturnsItems(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getallsalesreturnitems/${id}`)
   }

   GetDnReturnItemData(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getdnreturnitemdata/${id}`)
   }

   // Invoice

   GetInvoiceByCustomid(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getinvoicebycustomid/${id}`)
   }
   

   GetSalesInvoiceDetails(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getactiveinvoiceoftenant`,data)
   }

   GetPendingInvoiceDetails(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getpendinginvoiceoftenant`,data)
   }

   GetCancelleddInvoices(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getcancelleddinvoiceoftenant`,data)
   }
   

   GetAllDn(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getpendingdn`,data)
   }

   GetAllDnCompleted(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getcompleteddn`,data)
   }

   GenerateInvoice(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/generatesalesinvoice`, data)
   }

   UpdateInvoice(data) {
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/updatesalesinvoice`, data)
   }

   GetInoiceOfCustomer(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getinvoiceofcust/${id}`,)
   }

   GenerateReceipt(receiptdata) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/addreceipt`, receiptdata)
   }

   GetInvoice(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getinvoice/${id}`,)
   }

   GetTempInvoice(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/gettempinvoice/${id}`,)
   }

   GetCompletedInvoice(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getcompletedinvoiceoftenant`,data)
   }

   TerminateInvoice(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/terminateinvoice`,data)
   }

   TerminateTempInvoice(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/terminatetempinvoice`,data)
   }

   GetDnData(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getdndata/${id}`,)      
   }

   GetDirectDnData(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getdirectinvdndata/${id}`,)      
   }
   

   GetDnOfSo(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getdnofso/${id}`,)      
   }

   AddNewService(servicesdata) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/service/addservice`, servicesdata)
   }

   GetPreviousOrder(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getprevso/${id}`,)
   }

   GetSalesReceiptsdataDetails(currentTenant){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getallreceipts/${currentTenant}`,)
   }

   GetInvDataForReceipts(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getinvdataforreceipts/${id}`,)
   }

   GetSOOfCustomerforReceipts(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsoidforreceipts/${id}`,)
   }

   GetInvidOfSo(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getinvidofso/${id}`,)
   }

   UploadPOfile(filedata) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/uploadpo`, filedata)
   }
   SendSOmail(filedata) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/sendsomail`, filedata)
   }

   SendInvoicemail(email){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/sendinvoicemail`, email)
   }

   SendProformaInvoicemail(email){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/sendproformainvoicemail`, email)
   }

   SendEwaybillMail(email){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/sendewaybillmail`, email)
   }

   GetRecurringduration() {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getrecurringtype`)
   }
  
   GetContrctType() {
   return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getcontracttype`)
   }

   GetCompletedSo(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getpaymentcompletedso`,data)
   }

   GetCancelledSo(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getcancelledso`,data)
   }

   getAdvancePayments(currentTenant){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getadvansepayment/${currentTenant}`)
 
   }

   getInvoiceIdfoDN(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getinvoiceidfordn`,data)
   }

   addDn(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/adddn`, data)
   }

   GetTransactionOverdues(currentTenant){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/gettransactiondues/${currentTenant}`)
   }

   gettop5transactiondues(currentTenant){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/gettop5transactiondues/${currentTenant}`)
   }

   // Sales Report data
   getSalesReport(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsalesreports`,data)
   }

    // Sales Return Report data
    getSalesReturnsReport(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsalesreturnreports`,data)
   }

   getInvociereportes(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getinvoicereports`,data)
   }

   AddPOS(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/addpos`, data)
   }

   GetAllCurrency(){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getallcurrency`)
   }

   addDepositDetails(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/adddepositdetails`,data)
   }
   getReceiptbyId(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getreceiptofid/${id}`)
   }
   ApprovereceiptforNonAcc(id){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/updateaccstatusofreceipt/${id}`)
   }

   getFYInv(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getfyinv`,data) 
   }

   getProformaInvoiceofTenant(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getproformainvoiceoftenant`,data) 
   }

   getCompletedProformaInvoiceofTenant(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getcompletedproformainvoiceoftenant`,data) 
   }
   GenerateProformaInvoice(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/generateproformainvoice`, data)
   }

   DeleteProformaInvoice(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/deleteproformainvoice`, data)
   }
   // GetSObycustidforProformaInvoice(id){
   //    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsobycustidforproformainvoice/${id}`) 
   // }
   GetSObycustidforProformaInvoice(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsobycustidforproformainvoice`,data) 
   }
   

   GetProformaInvoice(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getproformainvoice/${id}`) 
   }

   getSalesbyItem(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsalesbyitem`,data) 
   }

   // Add DirectSales
   addDirectSales(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/adddirectsales`,data)
   }

   //get All Direct Sales
   getAllDirectSales(currentTenant){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getdirectsales/${currentTenant}`) 
   }

   // Delete Direct sale

   DeleteDirectSales(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/deletedirectsales/${id}`) 
   }


   //Einvoiceing
   GetEinvoice(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/geteinvoice/${id}`) 
   }

   //GetEwayBill
   GetEwayBill(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getewaybill/${id}`) 
   }
   CancelEinvoice(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/canceleinvoice`,data)
   }

   CancelEwaybill(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/cancelewaybill`,data)
   }

   GenerateEwaybill(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/ewaybillgen`,data)
   }

   GenerateQR(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/einvoicing/getQr`,data)
   }
   
   getSalesRefunds(tenantId){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getsalesreturnsforrefund/${tenantId}`)
  }

  getCreditNotes(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getcreditnotes`,data)
  }

  validateGSTIN(params){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/validategstin`,params)
  }

  ExtendEwaybill(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/extendvalidity`,data)
  }

  UpdateTransporter(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/updatetransporter`,data)
  }

  ChangeVehBefDept(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/vehewb`,data)
  }

  ApproveInvoice(data){
   return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/approveinvoice`,data) 
  }

  ApproveProformainvoice(data){
   return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/approveproformainvoice`,data)
  }

  UpdateTransportation(data){
   return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/updatetransprtationdetails`,data)
  }

  AddAdvance(data){
   return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/updateadvance`,data)
  }

  getAllCreditnotesbyINV(id){
  return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getcreditnotesofinvoice/${id}`)
  }

  GenerateCreditNote(data){
   return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/generatecreditnote`,data)
  }

  GenerateDebitNote(data){
   return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/generatedebitnoteofcustomer`,data)
  }

  GenerateEwaybillAPI(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/ewaybillapi`,data)
  }

  Featcheinvoice(id){
   return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/featchirn/${id}`)
  }

  getdelivereditems(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getdelivereditems`,data)
  }

  GetAgentsPendingOrders(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getagentspendingorders`,data)
  }

  GetAgentsCompletedOrders(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getagentscompletedorders`,data)
  }

  getCreditNoteitemsbyId(id){
   return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getcreditnoteitemsbyid/${id}`)
  }

  geteDebitNotesofinvoice(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getedebitnotesofinvoice/${id}`)
  }

  getInvoiceCreditNotes(id){
   return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getcreditnotesofinvoiceforacc/${id}`)
  }

  GetInvoiceidforreceipts(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getinvidofcustomerforreceipts`,data)
  }

  IsPaymentPending(id){
   return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/ispendingpayment/${id}`)
  }

  AutoJre(id){
   return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/autojrereq/${id}`)
  }

  getinvoiceandsoidbydnid(id){
   return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getinvoiceandsoidbydnid/${id}`)
  }

  InvocieReportsDate(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getdetailedinvoicereports`,data)
  }

  getagentsinvoicereports(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getagentsinvoicereports`,data)
  }
  getagentscompletedinvoicereports(data){
   return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getagentscompletedinvoicereports`,data)
  }

}
export default new SalesServices();