import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currency,
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials.js";
// 

import SalesCalculationServices from "./SalesCalculationServices";
import { spin } from "../../Common/NewLoader.jsx";
// import UserService from "../../users/UserService.js";
import { appmasterid } from "../../Common/Permissions.js";
import {NumericFormat} from "react-number-format";
import MUIDataTable from "mui-datatables";
import CustomToolbar from "../../CommonComponents/CustomToolbar.js";

import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});
class SalesCalTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      loading: false,
    };
  }

  refreshItems() {
    SalesCalculationServices.SalesCalTable(currentTenant).then((response) =>
      this.setState({
        tableData: response.data,
        loading: false,
      })
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  deletesalescal(id) {
    SalesCalculationServices.DeleteSalesCal(id).then((resposne) => {
      this.setState({
        message: `Delete of Todo ${id} Successfull`,
        loading: true,
      });
      this.refreshItems();
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.refreshItems();
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Item id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "name",
        label: "Item name",
        options: { filter: false, sort: true },
      },
      { name: "type", label: "Type", options: { filter: false, sort: true } },
      {
        name: "cost",
        label: `Price (${currency})`,
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "margin",
        label: `Margin (${currency})`,
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "discount",
        label: `Discount (${currency})`,
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "bankintrest",
        label: "Bank Intrest (%)",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (tableMeta) => {
            return <>{tableMeta}%</>;
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "transportCharges",
        label: "Transport Charge",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "grossValue",
        label: `Sales Price (${currency})`,
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        
        name: "Edit",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Link
                className="fa fa-edit textColour"
                to={{
                  pathname: "/salescal"}}
                  state={{ id:tableMeta.rowData.find((e) => e), pagename: "Edit Sales Calculation"}}
              ></Link>
            );
          },
        },
      },
      // {
      //   name: "Delete",
      //   options: {
      //     filter: false,
      //     sort: false,
      //     empty: true,
      //     display: appmasterid === "2" || appmasterid === "3" ? true : false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         <Link
      //           className="fa fa-trash text-danger"
      //           onClick={(e) => {
      //             const id = tableMeta.rowData.find((e) => e);
      //             if (window.confirm(`Are you sure you wish to DELETE ${id}?`))
      //               this.deletesalescal(id);
      //           }}
      //         ></Link>
      //       );
      //     },
      //   },
      // },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{pathname: "/salescal"}}
                  state={{ id: -1, pagename: "New Sales Calculation" }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };

    return (
      <div>
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/masters">Home</Link>
            </li>

            <li className="breadcrumb-item active">Sales Calculation</li>
          </ol>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title"> Sales Calculation </h3>
                    </div>
                    <div className="card-body">
                      <div>
                        {this.state.loading === true ? (
                          <div className="text-center">{spin}</div>
                        ) : (
                          <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Sales Calculation"}
                            data={this.state.tableData}
                            columns={columns}
                            options={options}
                          />
                          </ThemeProvider>
                        )}
                      </div>

                      {/* <table id="example1" className="table table-bordered table-striped table-sm">
                        <thead>
                          <tr style={{textAlign:"center"}}>
                          {appmasterid === '3' || appmasterid === '2' ?<th>Action</th>:''}
                            <th>Item Name</th>
                            <th>Type</th>
                            <th>Price</th>
                            <th>Margin</th>
                            <th>Discount</th>
                            <th>Bank Intrest</th>
                            <th>Transport Charge</th>
                            <th>Gross Price</th>
                          </tr>
                        </thead>
                        <tbody>
      
                          { 
                            this.state.tableData.map(
                              sales => 
                                <tr key={sales.id} style={{ textAlign: "center" }}>
                                  {appmasterid === '3' || appmasterid === '2' ?
                                  <td>

                                    <li data-tip data-for="delete" className="fa fa-trash text-danger" style={{ paddingLeft: '7px' }} onClick={() => { if (window.confirm('Are you sure you wish to DELETE?')) this.deletesalescal(sales.id) }}/> 
                                    
                                    
                                    //<Tooltip id="delete" place="top" effect="solid">Delete</Tooltip>
                                  </td> 
                                  :''}
                                  <td>{sales.name}</td>
                                  <td>{sales.type}</td>
                                  <td>{sales.cost}</td>
                                  <td>{sales.margin}</td>
                                  <td>{sales.discount}</td>
                                  <td>{sales.bankintrest}</td>
                                  <td>{sales.transportCharges}</td>
                                  <td>{sales.grossValue}</td>
                                </tr>
                            )
                          }
                        </tbody>
                      </table>   */}
                    </div>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(SalesCalTable);
