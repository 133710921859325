import React, { Component } from 'react'
import Footer from '../Component/Footer'
import Header from '../Component/Header'
import Menu from '../Component/Menu'
import UsersDataTable from './UsersDataTable'

export default class Users extends Component {
    render() {
        return (
            <div>
            <Header/>
                 <Menu/>
              
              <div className="content-wrapper">
                  {/* <UsersNewDataTable/> */}
              <UsersDataTable/>
              </div>
              
              <Footer/>

            </div>
        )
    }
}
