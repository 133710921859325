import axios from "axios";
import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import Credentials, {
  currentTenant,
  roles,
  thousandsdisplay,
  usertoken,
} from "../Common/Credentials";
import Permissions from "../Common/Permissions";
import Products from "../Common/Products";
// import Menu from '../Component/Menu'
import MasterService from "../Masters/MasterService";
import UserService from "../users/UserService";
import AuthenticationService from "./AuthenticationService";
import Eye from "../users/Eye";
import FileServices from "../Common/upload/FileServices";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";
import çoverimg from "../images/cover.png";
import finanzzlogo from "../images/Finanzz.png";
import Swal from "sweetalert2";
import moment from "moment";
import { Navigate } from "react-router-dom";
import "./Login.css";
let permissionlist;
let productslist;
let logopath;
let logofile;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usernameOrEmail: "",
      password: "",
      errormsg: "",
      showSuccessMesage: false,
      hasLoginFailed: false,
      TenantID: "",
      prefix: "",
      clientname: "",
      loginuserid: "",
      userroles: [],
      UserPermission: "",
      Ipaddress: "",
      userid: "",
      loader: false,
      type: "password",
      logopath: "",
      logofile: "",
    };
    // this.handleUsernameChange = this.handleUsernameChange.bind(this)
    // this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleChange = this.handleChange.bind(this);
    this.loginClicked = this.loginClicked.bind(this);
    this.pageloaded = this.pageloaded.bind(this);
    this.getPermissions = this.getPermissions.bind(this);
    this.dbAssign = this.dbAssign.bind(this);
    this.handleKeypress = this.handleKeypress.bind(this);
  }

  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));

  getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    this.setState({ Ipaddress: res.data.IPv4 });
  };

  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
      loader: false,
    });
  }
  pageloaded() {}
  componentDidMount() {
    if (usertoken !== undefined) {
      window.location.reload(false);
    }
    // alert(usertoken)
    this.setState({ prefix: window.location.hostname.split(".")[0] });
  }

  componentDidUpdate() {
    if (this.state.clientname === "") {
      AuthenticationService.setprefix(this.state.prefix).then((response) => {
        // AuthenticationService.getClient(response.data);
        this.setState({ clientname: response.data.companyname });
        this.setState({ TenantID: response.data.id });
        UserService.settenantid(response.data.id);
      });
    }
  }

  loginClicked() {
    // let permissionsupdated
    this.setState({ loader: true });
    AuthenticationService.executeJwtAuthenticationService(
      this.state.usernameOrEmail,
      this.state.password,
      this.state.TenantID
    )
      .then((response) => {
        AuthenticationService.registerSuccessfullLoginForJwt(
          this.state.usernameOrEmail,
          response.data.token,
          response.data
        );
        this.setState({ loginuserid: response.data.username });
        this.setState({ userroles: response.data.roles });
        this.setState({ userid: response.data.id });
        //  this.setState({logopath:response.data.logopath,logofile:response.data.logofile},
        //   ()=>this.getLogo()
        //   )
        logopath = response.data.logopath;
        logofile = response.data.logofile;
        let currentrole =
          response.data.roles && response.data.roles.map((id, name) => id);
        //this.state.userroles.roles && this.state.userroles.roles.map((index) => index.role)

        Credentials.setclientname(this.state.clientname);
        Credentials.setcurrentUser(this.state.usernameOrEmail);
        Credentials.setcurrentTenant(this.state.TenantID);
        Credentials.setuserToken(response.data.token);
        Credentials.setRoles(currentrole.toLocaleString());
        Credentials.setcurrentuserId(response.data.id);
        Credentials.setPrefix(response.data.prefix);
        Credentials.setCity(response.data.cityname);
        Credentials.setState(response.data.statename);
        Credentials.setccountry(response.data.countryname);
        Credentials.setZip(response.data.zip);
        Credentials.setCompanyemail(response.data.companyemail);
        Credentials.setContactnumber(
          response.data.phonecode + " - " + response.data.phonenum
        );
        Credentials.setcurrency(response.data.currencyname);
        Credentials.setaddress(response.data.address);
        Credentials.setaddress2(response.data.address2);
        Credentials.setcFinancialYearStart(response.data.fystartdate);
        Credentials.setFinancialYearEnd(response.data.fyenddate);
        Credentials.setTaxtype(response.data.taxtype);
        Credentials.setclienttz(response.data.zoneid);
        Credentials.setpan(response.data.pan);
        Credentials.settan(response.data.tan);
        Credentials.setgstin(response.data.gstin);
        Credentials.settaxno(response.data.taxno);
        Credentials.setregistrationno(response.data.registrationno);
        Credentials.setEinvoice(response.data.einvoice);
        Credentials.setStatecode(response.data.statecode);
        Credentials.setDataaccess(response.data.dataaccess);
        Credentials.setUserwhid(response.data.whid);
        Credentials.setNoofusers(response.data.noofusers);
        Credentials.setTransactionFrom(response.data.transactionFrom)
        if (response.data.countryname === "India") {
          Credentials.thousandsdisplay("lakh");
        } else {
          Credentials.thousandsdisplay("thousand");
        }

        // const getData = async () => {
        //   const res = await axios.get('https://geolocation-db.com/json/')
        //   this.setState({Ipaddress:res.data.IPv4})
        // }
        //AuthenticationService.GetIpAddress().then(response=>response))

        //this.state.TenantID.push(Credentials.user)
        // Credentials.user = response.data.username,
        // Credentials.client = this.state.TenantID
      })
      .then(() => {
        let formData = new FormData();
        formData.append("path", logopath);
        formData.append("filename", logofile);

        FileServices.getonefile(formData)
          .then((response) =>
            Credentials.setLogo(
              URL.createObjectURL(
                new Blob([response.data], { type: "application/octet-stream" })
              )
            )
          )
          .then(() => this.getPermissions())
          .catch(() => {
            this.getPermissions();
          });
      })
      .catch((e) => {
        this.setState({ hasLoginFailed: true, loader: false });
        this.setState({ showSuccessMesage: false });
        this.setState({
          errormsg:
            "An unauthorized error occurred, Please try again after sometime",
          loader: false,
        });
        if (e.response.data.message === "User is Logged In!!") {
          Swal.fire({
            title: "User is already logged In!",
            text: "Do you want to teminate?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            confirmButtonColor: "#152f5f",
            cancelButtonText: "No",
          }).then((result) => {
            if (result.value) {
              AuthenticationService.logout(this.state.usernameOrEmail,this.state.TenantID).then(
                (response) => {
                  this.setState({
                    errormsg:
                      response.data.message === ""
                        ? response.data.error
                        : response.data.message,
                    loader: false,
                  });
                  Swal.fire({
                    title: "Please Login !!!",
                    text: "All session Cleared Successfully.",
                    icon: "success",
                    showCancelButton: true,
                    confirmButtonText: "Sign In",
                    confirmButtonColor: "#152f5f",
                    // cancelButtonText: "No",
                  }).then((result) => {
                    if (result.value) {
                      this.loginClicked();
                    }
                  });
                }
              );
            }
          });
          // if (window.confirm(`User is logged In!!, Do you want to teminate`)) {
          //   AuthenticationService.logout(this.state.usernameOrEmail).then
          //     ((response) => this.setState({ errormsg: response.data.message === "" ? response.data.error : response.data.message, loader: false }))
          // }
          // else { }
        }
        if (this.state.errormsg === "") {
          this.setState({ errormsg: e.response.data.message, loader: false });
        } else {
          this.setState({ errormsg: "Invalid", loader: false });
        }
        if (e.response.data.error === "Unauthorized") {
          this.setState({ errormsg: "Invalid Credentials", loader: false });
        } else {
          this.setState({
            errormsg:
              "An unauthorized error occurred, Please try again after sometime",
            loader: false,
          });
        }
      });
  }

  getPermissions() {
    // if(this.state.userroles.includes("ROLE_SUPERADMIN") === true){}
    MasterService.getProducts(this.state.TenantID)
      .then((response) => {
        productslist = response.data;
        let appmaster = productslist.filter((e) =>
          e.productid === 1 ? e.productsEnabled : ""
        );
        Products.setProductAppmaster(appmaster.map((e) => e.productsEnabled));

        let auth = productslist.filter((e) =>
          e.productid === 2 ? e.productsEnabled : ""
        );
        Products.setProductAuth(auth.map((e) => e.productsEnabled));

        let accounts = productslist.filter((e) =>
          e.productid === 3 ? e.productsEnabled : ""
        );
        Products.setProductAccounts(accounts.map((e) => e.productsEnabled));

        let inventory = productslist.filter((e) =>
          e.productid === 4 ? e.productsEnabled : ""
        );
        Products.setProductInventory(inventory.map((e) => e.productsEnabled));

        let sales = productslist.filter((e) =>
          e.productid === 5 ? e.productsEnabled : ""
        );
        Products.setProductSales(sales.map((e) => e.productsEnabled));

        let purchase = productslist.filter((e) =>
          e.productid === 6 ? e.productsEnabled : ""
        );
        Products.setProductPurchase(purchase.map((e) => e.productsEnabled));

        let production = productslist.filter((e) =>
          e.productid === 7 ? e.productsEnabled : ""
        );
        Products.setProductProduction(production.map((e) => e.productsEnabled));

        let asset = productslist.filter((e) =>
          e.productid === 8 ? e.productsEnabled : ""
        );
        Products.setProductAssetmanagement(asset.map((e) => e.productsEnabled));

        let salestravel = productslist.filter((e) =>
        e.productid === 10 ? e.productsEnabled : ""
      );
      Products.setSalesTravels(salestravel.map((e) => e.productsEnabled));
      })
      .then(() => {
        // if(auth !== undefined || auth!== ""){

        UserService.UserPermissions(this.state.userid).then((response) => {
          // this.setState({UserPermission:response.data})
          permissionlist = response.data.permissionlist;
          let appmasterid = permissionlist.filter((e) => e.productid === 1);
          Permissions.setAppmaster(appmasterid.map((e) => e.permission));

          let authid = permissionlist.filter((e) => e.productid === 2);
          Permissions.setAuth(authid.map((e) => e.permission));

          let accountsid = permissionlist.filter((e) => e.productid === 3);
          Permissions.setAccounts(accountsid.map((e) => e.permission));

          let inventoryid = permissionlist.filter((e) => e.productid === 4);
          Permissions.setInventory(inventoryid.map((e) => e.permission));

          let salesid = permissionlist.filter((e) => e.productid === 5);
          Permissions.setSales(salesid.map((e) => e.permission));

          let purchaseid = permissionlist.filter((e) => e.productid === 6);
          Permissions.setPurchase(purchaseid.map((e) => e.permission));

          let productionid = permissionlist.filter((e) => e.productid === 7);
          Permissions.setProduction(productionid.map((e) => e.permission));

          let assetid = permissionlist.filter((e) => e.productid === 8);
          Permissions.setAssetmanagement(assetid.map((e) => e.permission));
        });
      });
    MasterService.getallRoles(currentTenant)
      .then((response) => {
        Products.setScreens(response.data.filter((e) => e.name === roles));
      })
      .then(() => this.dbAssign());
  }

  dbAssign() {
    // if(this.state.userroles.includes("ROLE_ADMIN") === true){
    // if (roles === "ROLE_ADMIN") {
    //   this.props.navigate(`/admin`)
    // } else if (this.state.userroles.includes("ROLE_ACCOUNTANT") === true) {
    //   this.props.navigate(`/accountant`)
    // } else if (this.state.userroles.includes("ROLE_AUDITOR") === true) {
    //   this.props.navigate(`/auditor`)
    // } else if (this.state.userroles.includes("ROLE_MANAGER") === true) {
    //   this.props.navigate(`/manager`)
    // } else if (this.state.userroles.includes("ROLE_SUPERADMIN") === true) {
    //   this.props.navigate(`/superadmin`)
    // } else if (this.state.userroles.includes("ROLE_USER") === true) {
    //   this.props.navigate(`/user`)
    // } else {
    this.props.navigate("/index");
    // <Navigate replace to="/index" />      //  true && (<Navigate to="/index"/>)
    // }
  }

  handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.which === 13) {
      this.loginClicked();
    }
  };

   handleForgotPassword = () =>{
    Swal.fire({
      title: "Please Enter Username Or Email!!!",
      text: "",
      icon: "error",
      // showCancelButton: true,
      // confirmButtonText: "Sign In",
      // confirmButtonColor: "#152f5f",
      // cancelButtonText: "No",
    })
  }


  render() {
    const clientname = this.state.clientname;
    let displayclient;
    if (clientname === "") {
      displayclient = (
        <p className="text-danger">Invalid Domain. Please check </p>
      );
    } else {
      displayclient = (<>
      <p className="brand-text fs-15 popinsbold text-bold mb-1 text-white">
                  WELCOME BACK
                </p>
        <h5 className="fs-20 popinsbold text-bold text-yellow brand-text">
          {this.state.clientname.toUpperCase()}
        </h5>
        </>
      );
    }
    return (
      <div>
        {/* <img src={çoverimg} alt="..." className="w-100 h-100" style={{ position: 'absolute', top: 0 }} /> */}
        <form className="overflow-hidden form form1">
          <meta charSet="utf-8" />
          <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
          <title>AdminLTE 3 | Log in</title>
          {/* Tell the browser to be responsive to screen width */}
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          {/* Font Awesome */}
          <link
            rel="stylesheet"
            href="../../plugins/fontawesome-free/css/all.min.css"
          />
          {/* Ionicons */}
          <link
            rel="stylesheet"
            href="https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css"
          />
          {/* icheck bootstrap */}
          <link
            rel="stylesheet"
            href="../../plugins/icheck-bootstrap/icheck-bootstrap.min.css"
          />
          {/* Theme style */}
          <link rel="stylesheet" href="../../dist/css/adminlte.min.css" />
          {/* Google Font: Source Sans Pro */}
          <link
            href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700"
            rel="stylesheet"
          />
            <div className="d-md-flex">
              <div className="col-lg-6 col-md-4 col-sm-0"></div>
          <div className="col-lg-6 col-md-8 col-sm-12 px-4 my-5">
          <div className="d-md-flex">
            <div className="col-md-10 col-sm-12">
            <div className="">
              <div className="my-4 col-12">
                {displayclient}
              </div>
              <div className="col-md-10 col-sm-12">
              <p className="brand-text fs-15 popinsbold text-bold mb-2 text-white">
                  ACCOUNT LOGIN
                </p>
              </div>
              <div className="input-group mb-2 col-md-8 col-sm-12">
              {this.state.hasLoginFailed && (
              <div className="alert alert-warning text-sm">{this.state.errormsg}</div>
            )}
                <input
                  type="text"
                  name="usernameOrEmail"
                  // onKeyPress={this.handleKeypress}
                  value={this.state.usernameOrEmail}
                  onChange={this.handleChange}
                  className="input text-bold"
                  placeholder="User Id"
                ></input>
                <div className="input-group-append">
                  {/* <div className="input-group-text">
                    <span className="fas fa-user" />
                  </div> */}
                </div>
              </div>
              <div className="input-group col-md-8 col-sm-12 mb-2">
                <input
                  type={this.state.type}
                  name="password"
                  onKeyPress={this.handleKeypress}
                  value={this.state.password}
                  onChange={this.handleChange}
                  className="input text-bold"
                  placeholder="Password"
                  autoComplete="on"
                ></input>
                {/* <div className="input-group-append absolute"> */}
                  <div className="eye">
                    <span className="" onClick={this.handleClick}>
                      <i>
                        <Eye />
                      </i>
                    </span>
                  </div>
                {/* </div> */}
              </div>

              <div className="col-md-8 col-sm-12 mb-2">
                {this.state.loader === true ? (
                  <button className="input bg-yellow text-white popinsbold" disabled>
                    <Spinner
                      animation="border"
                      size="sm"
                      className="mr-1 text-white"
                    />
                    &nbsp;{"Signing..."}
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="input bg-yellow text-white popinsbold"
                    onClick={this.loginClicked}
                  >
                    LOGIN
                  </button>
                )}
              </div>
              <div className="col-md-8 col-sm-12">
                  <button
                    type="submit"
                    className="input bg-white textColour popinsbold"
                  >
                    SIGNUP
                  </button>
              </div>
              <span className="col-12 mt-2">
                {this.state.usernameOrEmail === ""?
                <span
                as="button"
                className="cursorPointer"
                onClick={this.handleForgotPassword}
              >
                <p className="text-white fs-10" style={{fontSize:10}}>FORGET PASSWORD?</p>
              </span>
              
              :
                <Link
                  as="button"
                  to={{ pathname: "/forgotpassword" }}
                  state={{
                    username: this.state.usernameOrEmail,
                    clientname: this.state.clientname,
                  }}
                >
                  <p className="text-white fs-10" style={{fontSize:10}}>FORGET PASSWORD?</p>
                </Link>
                }
              </span>
              </div>
              <div className="col-12">
              <img
                src={finanzzlogo}
                alt="Logo..."
                height="100"
                className="finanzzlogo p-3 ml-0"
              />
              </div>

              {/* <div className="social-auth-links text-center mb-3">
          <p>- OR -</p>
          <a href="fake.url" className="btn btn-block btn-info">
            <i className="fab fa-facebook mr-2" /> Sign in using Facebook
          </a>
          <a href="fake.url" className="btn btn-block btn-danger">
            <i className="fab fa-google-plus mr-2" /> Sign in using Google+
          </a>
        </div> */}
{/*              
              <p className="m-0 text-center" style={{ fontSize: "12px" }}>
                Powered by
              </p>
              <p
                className="brand-text text-secondary text-center"
                style={{ fontSize: "14px" }}
              >
                Jnana Technologies @{moment().format("yyyy")}
              </p> */}
            </div>
          </div>
          <div className="col-md-2 col-sm-0"></div>
          </div>
          </div>
          <div className="fixed-bottom w-100">
          <div className="d-flex justify-content-around py-2">
            <div className="text-white"><span className="fs-12" style={{fontSize:12}}>COPYRIGHT@2023-24 JNANA SOFTWARE TECHNOLOGIES</span></div>
            <div className="text-white text-sm cursorPointer d-none d-sm-block" style={{fontSize:10}}>
              <span className="link fs-12" style={{fontSize:12}} >POLICIES</span>  |   <span className="link fs-12" style={{fontSize:12}}>COOKIE POLICY</span>  |  <span className="link fs-12" style={{fontSize:12}}>TERMS & CONDITIONS</span></div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default withRouter(Login);
