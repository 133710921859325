import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import Header from "../../../Component/Header";
import Menu from "../../../Component/Menu";
import Footer from "../../../Component/Footer";
import { Link } from "react-router-dom";
import TourServices from '../../Invoice/TourServices';
import { currentTenant, currentUser } from "../../../Common/Credentials";
import moment from "moment";
import withRouter from "../../../withRouter";



const MHotelChain = (props) => {
  const [checked, setChecked] = useState(true);
  const [data, setData] = useState({
    id: 0,
    hotelchain: "",
    remarks: "",
    createddate:new Date(),
    modifieddate:new Date()
    
  });
 

  useEffect(() => {
    
    console.log(props.location.state.id);
    if(props.location.state.id !== -1)
    {
        TourServices.getByIdMHotelChain(props.location.state.id).then((r)=>{
            setData({
              id: r.data.id,
              hotelchain: r.data.hotelchain,
              remarks: r.data.remarks,
              createddate:r.data.createddate,
              modifieddate:r.data.modifieddate,
              tenantid:r.data.tenantid
            })
            
        })
    }
  },[])

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const changeHandler = (e) => {
    setData(prevValues => {
      return { ...prevValues, [e.target.name]: e.target.value }
    })
  }

  const AddHandler = (e) => {

    e.preventDefault();
    if(props.location.state.id === -1)
    {
      const sdata = {
        id: data.id,
        hotelchain: data.hotelchain,
        remarks: data.remarks,
        createddate:new Date(),
        modifieddate:new Date(),
        tenantid:currentTenant,  
      }
      TourServices.addMHotelChain(sdata).then((r) => {
        console.log(r.data);
      })
    }
    else
    {
      const sdata = {
        id: data.id,
        hotelchain: data.hotelchain,
        remarks: data.remarks,
        createddate:data.createddate,
        modifieddate:new Date(),
        tenantid:currentTenant,
        
      }
      TourServices.updateMHotelChain(sdata,props.location.state.id).catch(r=>{
        console.log(r);
      })
    }

  }


  return (
    <>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
          <span
                as="button"
                className=" text-secondary cursorPointer"
                
              >
                <Link to="/hotelMasterchainlist" style={{color:"grey"}}>❮ Back &nbsp;&nbsp;</Link>
                
              </span>
            <li className="breadcrumb-item text-secondary">
              <Link to="/ndex">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/hotelMasterchainlist">Hotel chain List </Link>
            </li>
            <li className="breadcrumb-item active">Master Hotel chain</li>
          </ol>
          <div className="">
            <form >
              <div className="card-body p-6">
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Hotel Chain"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="hotelcode"
                      value={data.hotelchain}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Remarks"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="hotelname"
                      value={data.remarks}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
 
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="my-2">
                      <label>Created By : Jnanasoftware</label>
                    </div>
                 
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className=" my-2">
                      <label>Modified By : {currentUser}</label>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <label>Created Time : {moment(data.createddate).format("DD-MM-YYYY")}</label>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <label>Modified Time : {moment(data.modifieddate).format("DD-MM-yyyy")} </label>
                    </div>
                  </div>
                  <div className="btn d-flex justify-context-center">
                    <button className="btn buttonColour btn-sm  "  onClick={AddHandler}>ADD</button>
                  </div>
                </div>
              </div>
            </form>
            
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(MHotelChain);
