import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import CustomToolbar from '../CommonComponents/CustomToolbar'
import MUIDataTable from 'mui-datatables';
import EmployeeService from './EmployeeService';
import Footer from '../Component/Footer';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import withRouter from '../withRouter';
import { createTheme, ThemeProvider } from '@mui/material';


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

const SalaryBreakupComponent = () => {

  // Function start

  const [salaryBk, setSalaryBk] = useState([]);

  // const nevigate = useNavigate();

  // UseEffects
  useEffect(() => {
    apicall()
  }, [])
  const apicall =()=>{    
    EmployeeService.getAllSalaryBrkup().then((response) => {
      console.log(response.data);
      setSalaryBk(response.data);

    })
      .catch(err => console.log(err));
  }

  // Delete Function
  const delteDataHandler=(empid) =>{
    if(window.confirm('Are Sure wants to delete salaryBk ?'))
    {
    fetch(`http://localhost:8080/delSalbk/${empid}`, {
      method: 'DELETE'
    }).then((res) => {
      console.log(res.json,'ress')
      res.json()
      apicall()
            // .then((del) => {
      //    // console.warn(del);
      //   })
      }
      );
    }
  }

  //Columns Data
  const columns = [
    
    {
      name: "bid",
      label: "Id",
      options: { filter: true, sort: true },
    },
    {
      name: "houserentallowance",
      label: "House rent Allowance",
      options: { filter: true, sort: true },
    },
    {
      name: "conveyanceallowance",
      label: "Conveyance Allowance",
      options: { filter: true, sort: true },
    },
    {
      name: "medicalallowance",
      label: "Meidcal Allowance",
      options: { filter: true, sort: true },
    },
    {
      name: "specialallowance",
      label: "Special Allowance",
      options: { filter: true, sort: true },
    },
    {
      name: "epf",
      label: "EPF",
      options: { filter: true, sort: true },
    },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
          <>
            <button
              as="button"
              data-tip
              data-for="delete"
              className="btn btn-sm deletebutton"
             // to={{ state: {id: tableMeta.rowData.find(e=>e) ,pagename:"salaryBk" }  }}
              onClick={()=>
            delteDataHandler(tableMeta.rowData.find(e=>e))
              }
            >Delete</button>

          </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    customToolbar: () => {
      return (
          <Link
            as="button"
            className="float-right btn btn-sm m-0 p-0"
            to={{
              pathname: "/newemployeesalarybreakup",
              state:  "New salaryBk" ,
            }}
          >
            <CustomToolbar />
          </Link>
          )
    },
  };

  // Function ends

  return (
    // <div>
    // <Header/>
    // <Menu/>
    //   <div className='content-wrapper'>
    //     <div className='card'>
    //       <ol className='breadcrumb float-sm-right'>
    //         <li className='breadcrumb-item text-secondary'>
    //           <Link to="/">Home</Link>
    //         </li>
    //         <li className='breadcrumb-item active'>
    //           Salary Breakup
    //         </li>
    //         {/* List Start */}
    //        </ol>
    //       <div className='card-body'>

            <ThemeProvider theme={theme}>
              <MUIDataTable
                className="table-sm px-3"
                title={"Salary Breakup"}
                data={salaryBk}
                columns={columns}
                options={options}
              />
            </ThemeProvider>

    //       </div>
    //     </div>
    //   </div>
    //   <Footer/>
    // </div>
  )
}

export default withRouter(SalaryBreakupComponent);