import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { currentTenant, prefix1, userid } from "../Common/Credentials.js";
import { Autocomplete } from "@mui/material";
import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import Header from "../Component/Header.js";
import Menu from "../Component/Menu.js";
import Footer from "../Component/Footer.js";
import InventoryService from "../inventory/InventoryService.js";
import AssetsService from "./AssetsService.js";
import WarehouseServices from "../Masters/Warehouse/WarehouseServices.js";
import { Form, Formik } from "formik";
import DepartmentServices from "../Masters/Department/DepartmentServices.js";
import WorkCenterServices from "../Masters/WorkCenter/WorkCenterServices.js";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import UploadFilesService from "../Common/upload/UploadFilesService.js";
import { useNavigate } from "react-router-dom";
import FileServices from "../Common/upload/FileServices.js";

let doctype = "Asset Warranty";
let curmonth, year, day, path;

const NewThirdpartyAsset = (props) => {
  const history = useLocation();
  const [id] = useState(
    props.location.state === undefined ? -1 : props.location.state.id
  );
  const [pagedata] = useState(
    props.location.state === undefined
      ? {
          message: "",
          sold: false,
          rent: false,
          active: true,
          type: "Rental",
          stype: "Sales",
        }
      : {
          sold: props.location.state.sold,
          rent: props.location.state.rent,
          active: props.location.state.active,
          type: props.location.state.type,
          stype: props.location.state.stype,
        }
  );
  const [warehouseList, setWarehouseList] = useState([]);
  const [warehouse, setWarehouse] = useState("");
  const [whid, setWhid] = useState("");
  const [data, setData] = useState([]);
  const [itemlist, setitemlist] = useState([]);
  const [itemid, setitemid] = useState("");
  const [itemname, setitemname] = useState("");
  const [category, setCategory] = useState("");
  const [catid, setCatid] = useState("");
  const [hsn, sethsn] = useState("");
  const [uom, setUom] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [filteritems, setFilteritems] = useState([]);
  const [assetTypes] = useState([
    { id: 1, name: "Moveable" },
    { id: 2, name: "Immoveable" },
  ]);
  const [assetType, setAssetType] = useState("");
  const [subassetTypes, setSubAssetTypes] = useState([]);
  const [subassetType, setSubAssetType] = useState("");
  // const [movabletype, setmovableType] = useState([]);
  const [avilablestock, setAvilableStock] = useState(0);
  const [selectbutton, setSelectbutton] = useState(true);
  const [towarehouse, setToWarehouse] = useState("");
  const [towhid, setToWhid] = useState("");
  const [subwarehousetypes] = useState(["Department", "Workcenter"]);
  const [subtype, setSubType] = useState("");

  const [deptid, setDeptid] = useState("");
  const [department, setDepartment] = useState("");
  const [departmentList, setDepartmentList] = useState([]);
  // const [sold, setSold] = useState("");
  // const [rent, setRent] = useState("");
  // const [active, setActive] = useState("");
  // const [type, setType] = useState("");
  // const [stype, setStype] = useState("");

  const [wcid, setWcid] = useState("");
  const [workcenter, setWorkcenter] = useState("");
  const [workcenterList, setWorkcenterList] = useState([]);
  const [user, setUser] = useState("");
  const [location, setLocation] = useState("");
  const [depreciation, setDepreciation] = useState(0);
  const [depreciationperiod, setDepreciationperiod] = useState(0);
  const [manufacturedate, setmanufacturedate] = useState(new Date());
  const [depreciationdate, setdepreciationdate] = useState(new Date());
  const [noofwheels] = useState([
    2, 3, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32,
  ]);
  const [wheels, setWheels] = useState(0);
  const [vehicleno, setvehicleno] = useState("");
  const [itemtypes] = useState(["New", "Used"]);
  const [itemtype, setItemtype] = useState("New");
  const [loader, setLoader] = useState(false);
  const [baseamount, setbaseamount] = useState(0);
  // const [ledger, setLedger] = useState(false);
  const [sid, setSid] = useState("");
  const [bid, setBid] = useState("");
  // const [serialno, setSerialNo] = useState("");
  // const [productno, setProductNo] = useState("");
  const [warranty, setWarranty] = useState(0);

  // const [selectedFiles, setSelectedFiles] = useState(undefined);
  // const [progress,setProgress] = useState(0)
  // const [currentFile,setCurrentFile] = useState(undefined)
  const [isError, setisError] = useState(false);
  const [fileInfos, setfileInfos] = useState([]);
  const [message, setMessage] = useState("");
  const [assetitemslist, setAssetitemslist] = useState([]);

  const [error, setError] = useState(false);
  const navigate = useNavigate();

  // const [path,setPath] = useState('')
  // const [file,setFile] = useState('')

  useEffect(() => {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      navigate(`/`);
    }
    year = new Date().getFullYear();
    day = new Date().getDate();
    let mn = new Date().getMonth() + 1;
    curmonth = moment(mn, "M").format("MMMM");

    // sold:props.location.state.sold,rent:props.location.state.rent,active:props.location.state.active,type:props.location.state.type,stype:props.location.state.stype

    // setSold(props.location.state.sold)
    // setRent(props.location.state.rent)
    // setActive(props.location.state.active)
    // setType(props.location.state.type)
    // setStype(props.location.state.stype)

    WarehouseServices.getWarehouses(currentTenant)
      .then((response) => setWarehouseList(response.data))
      .catch((e) => {
        if (e.response !== undefined) {
          if (e.response.data.error === "Unauthorized") {
            Swal.fire(
              "Session is Lost!",
              "Please login Back to resume",
              "error"
            ).then(() => navigate("/"));
          }
        }
      });
    InventoryService.GetThirdPartyAassetItems(currentTenant).then(
      (response) => {
        setData(response.data);
        setitemlist([...new Set(response.data.map((e) => e.itemname))]);
      }
    );

    if (id === -1) {
      return;
    } else {
      AssetsService.GetAssetById(id).then((response) => {
        setitemid(response.data.itemid);
        setitemname(response.data.itemname);
        setAssetType(response.data.assettype);
        // moveabletype: assetType === "Moveable" ? subassetType : "NA"
        // immoveabletype: assetType === "Immoveable" ? subassetType : "NA"
        setSubAssetType(
          response.data.moveabletype === "NA"
            ? response.data.immoveabletype
            : response.data.moveabletype
        );
        setCategory(response.data.category);
        setCatid(response.data.catid);
        setWhid(response.data.fromwhid);
        setToWhid(response.data.whid);
        setSubType(
          response.data.wcid === "" || response.data.wcid === undefined
            ? "Department"
            : "Workcenter"
        );
        setWcid(response.data.wcid);
        setDeptid(response.data.deptid);
        setUser(response.data.user);
        setDepreciation(response.data.depreciation);
        setDepreciationperiod(response.data.depreciationperiod);
        setDepreciationperiod(response.data.depreciationdate);
        setbaseamount(response.data.baseamount);
        setItemtype(response.data.type);
        setmanufacturedate(response.data.manufacturedate);
        setWheels(response.data.wheels);
        setvehicleno(response.data.vehicleno);
        setLocation(response.data.location);
        // setLedger(response.data.ledger);
        setSid(response.data.sid);
        setBid(response.data.bid);
        setSerialNo(response.data.serialno);
        setSelectbutton(false);
        setWarranty(response.data.warranty);
      });
    }
  }, [history, id]);

  const handleWarehouseChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setWarehouse("");
      setWhid("");
    } else {
      setWarehouse(newValue.name);
      setWhid(newValue.id);
    }
  };

  const handleItemChange = (event, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setitemname("");
      setitemid("");
      setFilteritems([]);
      setSelectbutton(true);
    } else {
      setitemname(newValue);
      setitemid(data.find((e) => e.itemname === newValue).itemid);
      setFilteritems(
        data.filter(
          (e) =>
            e.itemname === newValue &&
            e.warehouse === warehouse &&
            e.currentStock > 0
        )
      );
      setSelectbutton(true);
      setCatid(data.find((e) => e.itemname === newValue).catid);
      setCategory(data.find((e) => e.itemname === newValue).category);
      setUom(data.find((e) => e.itemname === newValue).uomname);
      sethsn(data.find((e) => e.itemname === newValue).hsn);
      setBrand(data.find((e) => e.itemname === newValue).brand);
      setModel(data.find((e) => e.itemname === newValue).model);
    }
  };
  const handleSelect = (id) => {
    if (id !== "") {
      setFilteritems(filteritems.filter((e) => e.id === id));
      setAvilableStock(filteritems.find((e) => e.id === id).currentStock);
      setSelectbutton(false);
      setmanufacturedate(
        filteritems.find((e) => e.id === id).manufacturingdate
      );
      setbaseamount(filteritems.find((e) => e.id === id).price);
      setSid(id);
      setBid(id);
    }
  };

  const handleSerialnoChange = (val, idx) => {
    setError(false);
    let ailist = assetitemslist;
    ailist.find((e, i) => i === idx).serialno = val;
    setAssetitemslist([...ailist]);
  };

  const handleProductnoChange = (val, idx) => {
    setError(false);
    let ailist = assetitemslist;
    ailist.find((e, i) => i === idx).productno = val;
    setAssetitemslist([...ailist]);
  };

  const handleRemarksChange = (val, idx) => {
    setError(false);
    let ailist = assetitemslist;
    ailist.find((e, i) => i === idx).remarks = val;
    setAssetitemslist([...ailist]);
  };

  const handleAssetTypeChange = (e) => {
    setAssetType(e.target.value);
    setSubAssetType("");
    setSubAssetTypes(
      e.target.value === "Moveable"
        ? [
            { id: 0, name: "Electronics" },
            { id: 1, name: "Transports" },
          ]
        : [
            { id: 0, name: "Electronics" },
            { id: 1, name: "Machineries" },
            { id: 2, name: "Furnitures" },
          ]
    );
  };
  const handleSubAssetTypeChange = (e) => {
    setSubAssetType(e.target.value);
  };

  const handleToWarehouseChange = (e, newValue) => {
    setError(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setToWarehouse("");
      setToWhid("");
    } else {
      setToWarehouse(newValue.name);
      setToWhid(newValue.id);
      WorkCenterServices.WorkCenterbywarehouse(newValue.id).then((res) =>
        setWorkcenterList(res.data)
      );
      DepartmentServices.deptbywarehouse(newValue.id).then((response) =>
        setDepartmentList(response.data)
      );
    }
    setWorkcenter("");
    setWcid("");
    setDepartment("");
    setDeptid("");
  };

  const handleSubChange = (e) => {
    setError(false);
    setSubType(e.target.value);
    setWorkcenter("");
    setWcid("");
    setDepartment("");
    setDeptid("");
  };

  const handleWorkcenterChange = (e, newValue) => {
    setError(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setWorkcenter("");
      setWcid("");
    } else {
      setWorkcenter(newValue.name);
      setWcid(newValue.id);
    }
  };

  const handleDepartmentChange = (e, newValue) => {
    setError(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setDepartment("");
      setDeptid("");
    } else {
      setDepartment(newValue.name);
      setDeptid(newValue.id);
    }
  };

  const handleUserChange = (e) => {
    setError(false);
    setUser(e.target.value);
  };
  const handleLocationChange = (e) => {
    setError(false);
    setLocation(e.target.value);
  };

  const handleWheelsChange = (e) => {
    setError(false);
    setWheels(e.target.value);
  };
  const handleVehicleNumberChange = (e) => {
    setError(false);
    setvehicleno(e.target.value);
  };

  const handleQuantityChange = (e) => {
    if (
      parseFloat(e.target.value) > 0 &&
      parseFloat(e.target.value) <= parseFloat(avilablestock)
    ) {
      setQuantity(e.target.value);
      let itemlist = [];

      for (let i = 0; i < parseFloat(e.target.value); i++) {
        itemlist.push({
          slno: i + 1,
          itemname: itemname,
          itemid: itemid,
          quantity: 1,
          serialno: "",
          productno: "",
          remarks: "",
          selectedFiles: "",
          path: "",
          file: "",
          previewImage: "",
        });
      }
      setAssetitemslist(itemlist);
    } else {
      setQuantity(0);
    }
  };

  // const handlewarrantyChange = (e) => {
  //   setError(false)
  //   setWarranty(e.target.value);
  // };

  const onSubmit = (e) => {
    e.preventDefault();
    // setError(true);
    // if (serialno === "" || whid === "") {
    // } else {
      let ailist = assetitemslist;
      // let filedata = fileInfos.map((e) => e.name).toLocaleString();
    console.log(ailist)
      assetitemslist.map((a, idx) => {
        if (a.selectedFiles !== null && a.selectedFiles !=="" && a.selectedFiles !== undefined) {
          upload(a.selectedFiles)

            ailist.find((e, i) => i === idx).file = a.selectedFiles.name
            ailist.find((e, i) => i === idx).path =
              prefix1 +
              "/" +
              "thirdpartAssets" +
              "/" +
              userid +
              "/" +
              year +
              "/" +
              curmonth +
              "/" +
              day
            setAssetitemslist([...ailist]);

        }
      });
      setLoader(true);
      let data = {
        id: id,
        itemid: itemid,
        itemname: itemname,
        assettype: assetType,
        moveabletype: assetType === "Moveable" ? subassetType : "NA",
        immoveabletype: assetType === "Immoveable" ? subassetType : "NA",
        tenantid: currentTenant,
        fromwhid: whid,
        quantity: quantity,
        category: category,
        catid: catid,
        hsn: hsn,
        uom: uom,
        brand: brand,
        model: model,
        whid: towhid,
        wcid: subtype === "Workcenter" ? wcid : "",
        deptid: subtype === "Workcenter" ? "" : deptid,
        user: user,
        baseamount: baseamount,
        type: itemtype,
        manufacturedate: manufacturedate,
        wheels: wheels,
        vehicleno: vehicleno,
        createdby: userid,
        updatedby: userid,
        location: location,
        sid: sid,
        rate:baseamount,
        listdao: assetitemslist,
      };
      if (id === -1) {
        AssetsService.NewThirdPartyAsset(data)
          .then(() => navigate({ pathname: "/asset" }, { state: pagedata }))
          .catch(() => setLoader(fasle));
      } else {
        AssetsService.UpdateThirdPartyAsset(data)
          .then(() => navigate({ pathname: "/asset" }, { state: pagedata }))
          .catch(() => setLoader(fasle));
      }
    // }
  };

  const selectFile = (val, idx) => {
    let ailist = assetitemslist;
    ailist.find((e, i) => i === idx).selectedFiles = val[0];
    setAssetitemslist([...ailist]);
    // ailist.find((e,i)=>i===idx).path =  prefix1 + "/" + "thirdpartAssets" +"/" +userid +"/" +year +"/" +curmonth +"/" +day + ailist.find((e,i)=>i===idx).serialno;
    ailist.find((e, i) => i === idx).previewImage = URL.createObjectURL(val[0]);
    setAssetitemslist([...ailist]);
  };

  const RemoveImage = (idx) => {
    let ailist = assetitemslist;
    ailist.find((e, i) => i === idx).selectedFiles = "";
    setAssetitemslist([...ailist]);
    ailist.find((e, i) => i === idx).previewImage = "";
    setAssetitemslist([...ailist]);
  };

  const upload = (file) => {
    console.log(file)
    let currentFile = file;

    // setProgress(100)
    // setCurrentFile(currentFile)
    UploadFilesService.upload(
      currentFile,
      year,
      curmonth,
      "thirdpartAssets",
      userid,
      day,
      (event) => {
        // setProgress(Math.round((100 * event.loaded) / event.total))
      }
    )
      .then((response) => {
        setMessage(response.data.message);
        setisError(false);
        return FileServices.getFiles1(currentFile.name);
      })
      .then((files) => {
        setfileInfos(files.data);
      })

      .catch(() => {
        // setProgress(0)
        // setCurrentFile(undefined)
        setMessage("Could not upload the file!");
        setisError(true);
      });

    // setSelectedFiles(undefined);
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/asset" }}
                state={pagedata}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/assetmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to={{ pathname: "/asset" }} state={pagedata}>
                Assets
              </Link>
            </li>
            <li className="breadcrumb-item active">New Thirdparty Assets</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              {/* {data === [] || data.length === 0 ? (
                <div className="text-center">{spin}</div>
              ) : ( */}
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">New Thirdparty Assets</h3>
                    </div>
                    <div className="card-body">
                      <Formik
                        initialValues={{}}
                        // onSubmit={onSubmit}
                        validateOnChange={false}
                        // validate={validate}
                        enableReinitialize={true}
                      >
                        {({ setFieldValue, values }) => (
                          <Form
                            autoComplete="off"
                            style={{ paddingLeft: "30px" }}
                          >
                            <div className="form-row">
                              <fieldset className="form-group col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="warehouse"
                                  options={warehouseList}
                                  getOptionLabel={(option) => option.name}
                                  value={
                                    values.warehouse ? undefined : undefined
                                  }
                                  onChange={handleWarehouseChange}
                                  disabled={itemname !== ""}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="warehouse"
                                      value={warehouse}
                                      label={`Select Org Unit (${warehouse})`}
                                      variant="standard"
                                      error={whid === "" && error}
                                      helperText={
                                        whid === "" && error
                                          ? "Empty field!"
                                          : " "
                                      }
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-3">
                                <Autocomplete
                                  id="controlled-demo"
                                  options={itemlist}
                                  getOptionLabel={(option) => option}
                                  isOptionEqualToValue={(option) =>
                                    !option.value
                                  }
                                  value={itemname}
                                  onChange={handleItemChange}
                                  error={
                                    itemname === "" || itemname === undefined
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={`Select Item (${itemname})`}
                                      variant="standard"
                                      error={
                                        (itemname === "" ||
                                          itemname === undefined) &&
                                        error
                                      }
                                      helperText={
                                        (itemname === "" ||
                                          itemname === undefined) &&
                                        error
                                          ? "Empty field!"
                                          : ""
                                      }
                                    />
                                  )}
                                />
                              </fieldset>

                              {/* {filteritems.length !== 0 ? (
                                  <> */}
                              {selectbutton === true ? (
                                filteritems.length === 0 ? (
                                  <div className="col-md-12">
                                    Sorry, no matching records found
                                  </div>
                                ) : (
                                  <TableContainer>
                                    <Typography> Items List</Typography>
                                    <Table className="table-sm">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell className="text-center">
                                            Itemname
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Warehouse
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Price
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Brand
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Model
                                          </TableCell>
                                          <TableCell className="text-center">
                                            UOM
                                          </TableCell>
                                          <TableCell className="text-center">
                                            CurrentStock
                                          </TableCell>
                                          {selectbutton === true ? (
                                            <TableCell className="text-center">
                                              Actions
                                            </TableCell>
                                          ) : (
                                            ""
                                          )}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {filteritems.map((e, index) => (
                                          <TableRow key={index}>
                                            <TableCell className="text-center">
                                              {e.itemname}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {e.warehouse}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {e.price}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {e.brand}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {e.model}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {e.uomname}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {e.currentStock}
                                            </TableCell>

                                            <TableCell className="text-center">
                                              <span
                                                as="buttom"
                                                className="btn btn-sm hovbuttonColour"
                                                onClick={() =>
                                                  handleSelect(e.id)
                                                }
                                              >
                                                Select
                                              </span>
                                            </TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )
                              ) : (
                                ""
                              )}
                              {/* </>
                                ) : (
                                  ""
                                )} */}

                              {selectbutton === false ? (
                                <>
                                  <fieldset className="form-group col-md-3">
                                    <TextField
                                      className="form-control"
                                      type="text"
                                      name="baseamount"
                                      label={`Price`}
                                      value={baseamount}
                                      variant="standard"
                                      error={
                                        baseamount === "" ||
                                        baseamount === undefined ||
                                        baseamount === 0
                                      }
                                      helperText={
                                        baseamount === "" ||
                                        baseamount === 0 ||
                                        baseamount === undefined
                                          ? "Enter price"
                                          : " "
                                      }
                                    />
                                  </fieldset>

                                  <fieldset className="form-group col-md-3">
                                    <TextField
                                      className="form-control"
                                      type="text"
                                      variant="standard"
                                      name="quantity"
                                      label={`Quantity (Avilable: ${avilablestock})`}
                                      value={quantity}
                                      onChange={handleQuantityChange}
                                      error={
                                        quantity === "" ||
                                        quantity === undefined ||
                                        quantity > avilablestock ||
                                        quantity === 0
                                      }
                                      helperText={
                                        quantity === "" ||
                                        quantity === 0 ||
                                        quantity === undefined
                                          ? "Enter Quantity"
                                          : quantity > avilablestock
                                          ? "Out of Stock"
                                          : " "
                                      }
                                    />
                                  </fieldset>
                                </>
                              ) : (
                                ""
                              )}

                              {selectbutton === false &&
                              parseFloat(quantity) > 0 ? (
                                <>
                                  <fieldset className="col-md-6">
                                    <FormControl className="mt-3">
                                      <FormLabel id="demo-row-radio-buttons-group-label">
                                        Asset Type :
                                      </FormLabel>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        value={assetType}
                                        onChange={handleAssetTypeChange}
                                      >
                                        {assetTypes.map((e) => {
                                          return (
                                            <FormControlLabel
                                              key={e.id}
                                              value={e.name}
                                              control={
                                                <Radio
                                                  style={{ color: "#152f5f" }}
                                                />
                                              }
                                              label={e.name}
                                            />
                                          );
                                        })}
                                      </RadioGroup>
                                    </FormControl>
                                  </fieldset>

                                  <fieldset className="col-md-6">
                                    {assetType === "" ||
                                    assetType === undefined ? (
                                      ""
                                    ) : (
                                      <FormControl className="mt-3">
                                        <FormLabel id="demo-row-radio-buttons-group-label">
                                          {assetType}:
                                        </FormLabel>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="row-radio-buttons-group"
                                          value={subassetType}
                                          onChange={handleSubAssetTypeChange}
                                        >
                                          {subassetTypes.map((e) => {
                                            return (
                                              <FormControlLabel
                                                key={e.id}
                                                value={e.name}
                                                control={
                                                  <Radio
                                                    style={{
                                                      color: "#152f5f",
                                                    }}
                                                  />
                                                }
                                                label={e.name}
                                              />
                                            );
                                          })}
                                        </RadioGroup>
                                      </FormControl>
                                    )}
                                  </fieldset>
                                  {subassetType === "" ? (
                                    ""
                                  ) : (
                                    <>
                                      <fieldset className="form-group col-md-3">
                                        <Autocomplete
                                          id="subaccount"
                                          className="subacc-select"
                                          name="towarehouse"
                                          options={warehouseList}
                                          isOptionEqualToValue={(option) =>
                                            !option.value
                                          }
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          value={
                                            values.towarehouse
                                              ? undefined
                                              : undefined
                                          }
                                          onChange={handleToWarehouseChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              className="form-control"
                                              type="text"
                                              name="towarehouse"
                                              value={towarehouse}
                                              label={`Select Org Unit (${towarehouse})`}
                                              variant="standard"
                                              error={towhid === "" && error}
                                              helperText={
                                                towhid === "" && error
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </fieldset>
                                      <fieldset className="form-group col-md-3">
                                        <FormControl
                                          // sx={{ m: 1, minWidth: 120 }}
                                          className="col-md-12"
                                        >
                                          <InputLabel id="demo-simple-select-filled-label">
                                            Select Dept/WC
                                          </InputLabel>
                                          <Select
                                            value={subtype}
                                            onChange={handleSubChange}
                                            variant="standard"
                                          >
                                            {subwarehousetypes.map((option) => {
                                              return (
                                                <MenuItem
                                                  key={option}
                                                  value={option}
                                                >
                                                  {option}
                                                </MenuItem>
                                              );
                                            })}
                                          </Select>
                                        </FormControl>
                                      </fieldset>
                                      {subtype === "Department" ? (
                                        <fieldset className="form-group col-md-3">
                                          <Autocomplete
                                            id="department"
                                            className="subacc-select"
                                            name="department"
                                            options={departmentList}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            isOptionEqualToValue={(option) =>
                                              !option.value
                                            }
                                            value={
                                              values.department
                                                ? undefined
                                                : undefined
                                            }
                                            onChange={handleDepartmentChange}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                className="form-control"
                                                type="text"
                                                name="department"
                                                value={department}
                                                label={`Select Department/Store (${department})`}
                                                variant="standard"
                                                error={deptid === ""}
                                                helperText={
                                                  deptid === ""
                                                    ? "Empty field!"
                                                    : " "
                                                }
                                                fullWidth
                                              />
                                            )}
                                          />
                                        </fieldset>
                                      ) : (
                                        <fieldset className="form-group col-md-3">
                                          <Autocomplete
                                            id="workcenter"
                                            className="subacc-select"
                                            name="workcenter"
                                            options={workcenterList}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            isOptionEqualToValue={(option) =>
                                              !option.value
                                            }
                                            value={
                                              values.workcenter
                                                ? undefined
                                                : undefined
                                            }
                                            onChange={handleWorkcenterChange}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                className="form-control"
                                                type="text"
                                                name="workcenter"
                                                value={workcenter}
                                                label={`Select Workcenter/Production Unit(${workcenter})`}
                                                variant="standard"
                                                error={wcid === "" && error}
                                                helperText={
                                                  wcid === "" && error
                                                    ? "Empty field!"
                                                    : " "
                                                }
                                                fullWidth
                                              />
                                            )}
                                          />
                                        </fieldset>
                                      )}
                                      {subassetType === "Machineries" ||
                                      subassetType === "Furnitures" ? (
                                        ""
                                      ) : (
                                        <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            type="text"
                                            name="user"
                                            label={`User`}
                                            value={user}
                                            onChange={handleUserChange}
                                            variant="standard"
                                          />
                                        </fieldset>
                                      )}

                                      <fieldset className="form-group col-md-3">
                                        <TextField
                                          className="form-control"
                                          type="text"
                                          name="location"
                                          label={`Location/Address`}
                                          value={location}
                                          onChange={handleLocationChange}
                                          variant="standard"
                                          error={
                                            (location === "" ||
                                              location === undefined) &&
                                            error
                                          }
                                          helperText={
                                            (location === "" ||
                                              location === undefined) &&
                                            error
                                              ? "Enter Location/Address"
                                              : " "
                                          }
                                        />
                                      </fieldset>

                                      {/* <fieldset className="form-group col-md-3">
                                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Grid container>
                                              <DatePicker
                                                variant="standard"
                                                id="date-picker-dialog"
                                                label="Purchase Date"
                                                format="dd/MM/yyyy"
                                                value={depreciationdate}
                                                onChange={ handledepreciationdateChange }
                                                renderInput={(params) => <TextField {...params}  variant="standard" fullWidth/>}
                                                autoOk={true}
                                              />
                                            </Grid>
                                          </LocalizationProvider>
                                        </fieldset> */}

                                      {/* <fieldset className="form-group col-md-3">
                                          <FormControl
                                            className="col-md-12"
                                          >
                                            <InputLabel id="demo-simple-select-filled-label">
                                              Select Type
                                            </InputLabel>
                                            <Select
                                              value={itemtype}
                                              onChange={handleItemtypeChange}
                                              variant="standard"
                                              error={
                                                itemtype === "" ||
                                                itemtype === undefined
                                              }
                                              helperText={
                                                itemtype === "" ||
                                                itemtype === undefined
                                                  ? "Enter Itemtype"
                                                  : " "
                                              }
                                            >
                                              {itemtypes.map((option) => {
                                                return (
                                                  <MenuItem
                                                    key={option}
                                                    value={option}
                                                  >
                                                    {option}
                                                  </MenuItem>
                                                );
                                              })}
                                            </Select>
                                          </FormControl>
                                        </fieldset> */}
                                      {/* <fieldset className="form-group col-md-3">
                                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Grid container >
                                              <DatePicker
                                                variant="inline"
                                                id="date-picker-dialog"
                                                label="Manufacture Date "
                                                format="dd/MM/yyyy"
                                                maxDate={new Date()}
                                                value={manufacturedate}
                                                onChange={handlemanufacturedateChange}
                                                renderInput={(params) => <TextField {...params}  variant="standard" fullWidth/>}
                                                autoOk={true}
                                              />
                                            </Grid>
                                          </LocalizationProvider>
                                        </fieldset> */}

                                      {/* <fieldset className="form-group col-md-3">
                                          <TextField
                                            className="form-control"
                                            type="number"
                                            name="warranty"
                                            label={`Warranty in Months`}
                                            value={warranty}
                                            onChange={handlewarrantyChange}
                                            variant="standard"
                                            error={
                                              warranty === "" ||
                                              warranty === undefined
                                            }
                                            helperText={
                                              warranty === "" ||
                                              warranty === undefined
                                                ? "Enter Depreciation Period"
                                                : " "
                                            }
                                          />
                                        </fieldset> */}
                                      {/* <fieldset className="form-group col-md-3">
                                  <TextField
                                    className="form-control"
                                    type="text"
                                    name="serialno"
                                    label={`Serial no.`}
                                    value={serialno}
                                    onChange={handleSerialnoChange}
                                    variant="standard"
                                    error={(serialno === "" || serialno === undefined)&& error}
                                    helperText={(serialno === "" || serialno === undefined)&& error? "Enter serialno": " "}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3">
                                  <TextField
                                    className="form-control"
                                    type="text"
                                    name="productno"
                                    label={`Product no.`}
                                    value={productno}
                                    onChange={handleProductnoChange}
                                    error={(productno === "" || productno === undefined)&& error}
                                    variant="standard"
                                    helperText={(productno === "" || productno === undefined)&& error? "Enter Product No": " "}
                                  />
                                </fieldset> */}
                                      {subassetType === "Transports" ? (
                                        <>
                                          <fieldset className="form-group col-md-12 mt-3">
                                            <h5>Specifications:</h5>
                                          </fieldset>
                                          <fieldset className="form-group col-md-3">
                                            <FormControl
                                              sx={{ m: 1, minWidth: 120 }}
                                              className="col-md-12"
                                            >
                                              <InputLabel id="demo-simple-select-filled-label">
                                                Select No.of Wheels
                                              </InputLabel>
                                              <Select
                                                value={wheels}
                                                onChange={handleWheelsChange}
                                              >
                                                {noofwheels.map((option) => {
                                                  return (
                                                    <MenuItem
                                                      key={option}
                                                      value={option}
                                                    >
                                                      {option}
                                                    </MenuItem>
                                                  );
                                                })}
                                              </Select>
                                            </FormControl>
                                          </fieldset>
                                          <fieldset className="form-group col-md-3">
                                            <TextField
                                              className="form-control"
                                              type="vehicleno"
                                              name="vehicleno"
                                              label={`Vehicle/Registration Number`}
                                              value={vehicleno}
                                              onChange={
                                                handleVehicleNumberChange
                                              }
                                              error={
                                                vehicleno === "" ||
                                                vehicleno === undefined
                                              }
                                              helperText={
                                                vehicleno === "" ||
                                                vehicleno === undefined
                                                  ? "Enter Vehicle Number"
                                                  : " "
                                              }
                                            />
                                          </fieldset>
                                        </>
                                      ) : (
                                        ""
                                      )}

                                      <TableContainer>
                                        <Typography>
                                          Asset Items List
                                        </Typography>
                                        <Table
                                          className="table-sm"
                                          size="small"
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell className="text-center">
                                                Slno.
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Brand
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Model
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Serial number
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Product number
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Remarks
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Uploads
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {assetitemslist.map((e, index) => (
                                              <TableRow key={index}>
                                                <TableCell className="text-center">
                                                  {e.slno}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  {brand}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  {model}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  <TextField
                                                    type="text"
                                                    name={`serialno${index}`}
                                                    label={`Serial no.`}
                                                    value={e.serialno}
                                                    onChange={(e) =>
                                                      handleSerialnoChange(
                                                        e.target.value,
                                                        index
                                                      )
                                                    }
                                                    variant="standard"
                                                  />
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  <TextField
                                                    type="text"
                                                    name={`productno${index}`}
                                                    label={`Product no.`}
                                                    value={e.productno}
                                                    onChange={(e) =>
                                                      handleProductnoChange(
                                                        e.target.value,
                                                        index
                                                      )
                                                    }
                                                    variant="standard"
                                                  />
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  <TextField
                                                    type="text"
                                                    name={`remarks${index}`}
                                                    label={`Remarks`}
                                                    value={e.remarks}
                                                    onChange={(e) =>
                                                      handleRemarksChange(
                                                        e.target.value,
                                                        index
                                                      )
                                                    }
                                                    variant="standard"
                                                  />
                                                </TableCell>
                                                <TableCell>
                                                  {!e.selectedFiles ? (
                                                    <label htmlFor="btn-upload">
                                                      <input
                                                        className="btn-choose"
                                                        id="btn-upload"
                                                        name="btn-upload"
                                                        style={{
                                                          display: "none",
                                                        }}
                                                        type="file"
                                                        onChange={(e) =>
                                                          selectFile(
                                                            e.target.files,
                                                            index
                                                          )
                                                        }
                                                      />
                                                      <Button
                                                        className="btn-close"
                                                        variant="outlined"
                                                        type="upload"
                                                        component="span"
                                                      >
                                                        <span className="text-blue">
                                                          Upload
                                                        </span>
                                                      </Button>
                                                    </label>
                                                  ) : (
                                                    <span className="d-flex flex-column align-items-center">
                                                      <span>
                                                        <img
                                                          alt="."
                                                          // width="100%"
                                                          height="80"
                                                          className="finanzzlogo"
                                                          src={e.previewImage}
                                                        />
                                                      </span>
                                                      <span
                                                        className="text-red text-center cursorPointer"
                                                        onClick={() =>
                                                          RemoveImage(index)
                                                        }
                                                      >
                                                        Remove
                                                      </span>
                                                    </span>
                                                  )}
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>

                                      <div className="form-group col-md-12" />
                                      <button
                                        className="btn btn-sm hovbuttonColour mr-1"
                                        type="button"
                                        onClick={onSubmit}
                                        disabled={loader === true}
                                      >
                                        {loader === true ? (
                                          <>
                                            <Spinner
                                              animation="border"
                                              size="sm"
                                            />
                                            {" Saving"}
                                          </>
                                        ) : (
                                          <>
                                            <i className="fa fa-save mr-1" />
                                            {"Save"}
                                          </>
                                        )}
                                      </button>
                                      <Link
                                        as="button"
                                        className="btn btn-sm deletebutton"
                                        to={{ pathname: "/asset" }}
                                        state={pagedata}
                                      >
                                        <b className="text-danger"> X</b> Cancel
                                      </Link>
                                    </>
                                  )}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                            <fieldset className="form-group col-md-12 mt-2">
                              <label className="text-red">
                                Note : If any selection is wrong or with invalid
                                characters, please cancel the selection with "x"
                                button in selection and reselect the Field.
                              </label>
                            </fieldset>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(NewThirdpartyAsset);
