import React from "react";
import withRouter from "../withRouter";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { currentTenant, thousandsdisplay, userid } from "../Common/Credentials";
import { ToWords } from "to-words";
import Footer from "../Component/Footer";
import { Spinner } from "react-bootstrap";
import NewJournalService from "./NewJournalService";


class MultiEntryConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmjv: this.props.location.state,
      loader:false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    console.log(this.props.location.state);
  }

  onSubmit() {
    this.setState({loader:true})
    let expenseid,expensename
    if(this.state.confirmjv.expense!==undefined){
      expenseid=this.state.confirmjv.expense.id
      expensename=this.state.confirmjv.expense.name
    }

    let debt = []
    let tax = []

    this.state.confirmjv.debit.map(e=>{
      debt.push({
          name: e.debit,
          value: parseFloat(e.debitval),
          d1: "d"
      })
      if(this.state.confirmjv.taxreqchecked === 1 && this.state.confirmjv.taxchecked){
      tax.push({
        name:e.tax1,
        percent:parseFloat(e.tax1per) ,
        value: parseFloat(e.tax1val)
      })
      if(this.state.confirmjv.showtax2){
        tax.push({
          name:e.tax2,
          percent:parseFloat(e.tax2per) ,
          value: parseFloat(e.tax2val)
        })
      }
    }
    })

    let dtax = [{
        name: this.state.confirmjv.tds===""?null:this.state.confirmjv.tds,
        value: this.state.confirmjv.tdsval === 0 ?null :this.state.confirmjv.tdsval,
        percent: this.state.confirmjv.tdsper===0?null:this.state.confirmjv.tdsper,
        number: 0
    }]

    let indsubtaxtype,indtaxtype
      let ttype = tax.map((e) => e.name);
      if (ttype[0] !== null) {
        ttype.map((e) => {
          return (indsubtaxtype = e.indirectsubtaxtype);
        });
        ttype.map((e) => {
          return (indtaxtype = e.indirecttaxtype);
        });
      }


    let cred = [{
      name:this.state.confirmjv.credit,
      value:this.state.confirmjv.creditval,
      number:0
    }]
    let dijv = {
      debt: debt,
      tax:tax,
      dtax:dtax,
      cred: cred,
      createdBy: userid,
      tenantId: currentTenant,
      naration: this.state.confirmjv.narration,
      type: this.state.confirmjv.jvtype,
      // debtotal:this.state.confirmjv.debtotal,
      // taxtotal:this.state.confirmjv.taxtotal,
      // dtaxtotal:this.state.confirmjv.dtaxtotal,
      // cretotal:this.state.confirmjv.cretotal,
      typeofentry:this.state.confirmjv.typeofentry,
      taxbehav:this.state.confirmjv.taxbehav,
      indtaxtype:indtaxtype,
      indsubtaxtype:indsubtaxtype,
      date:this.state.confirmjv.date,
      // advanceentry:parseInt(this.state.confirmjv.advanceentry),
      expenseid:"noexp",
      jvinsertiontype:this.state.confirmjv.jvinsertiontype,ncentry:this.state.confirmjv.ncentry,resentry:this.state.confirmjv.resentry,
      lt:this.state.confirmjv.lt===true?parseInt(0):parseInt(1),ap:this.state.confirmjv.ap===true?parseInt(0):parseInt(1),
      ar:this.state.confirmjv.ar===true?parseInt(0):parseInt(1),dr:this.state.confirmjv.dr===true?parseInt(0):parseInt(1),
      dp:this.state.confirmjv.dp===true?parseInt(0):parseInt(1)
    };

    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    } else {
      //console.log(dijv)
      NewJournalService.insertEntryComb(dijv).then(() =>
      // navigate({pathname:'/dirsjournalstockentry'},{ message: values + "   : Combo Entry Successfull"})
        this.props.navigate({pathname:`/journals`}, {
          message: "   : Combo Entry Successfull",
        })
      )
  //     .catch((e)=>{
  //       if(e.response.data.error ==="Unauthorized"){
  //           alert("Your Session is Lost: Please login Back to resume");
  //           this.props.navigate(`/`);
  //       }
  //  })
     }
  }

  goBack() {
    this.props.navigate(-1);
  }

  render() {

    const toWords = new ToWords();
    let amtwords  
    if(this.state.confirmjv.creditval !==null && this.state.confirmjv.creditval>0 ){ 
    amtwords=toWords.convert(this.state.confirmjv.creditval);
    }

    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item active">Confirm Entry</li>
            </ol>

            <div className="text-center text-danger">{this.state.error}</div>
            <div className="card-body">
              <div className="form-row">
                <table className="table table-sm">
                  <thead className="backgroundColour">
                    <tr>
                      <td className="text-white">Ledgers</td>
                      <td className="text-white text-right">Debit</td>
                      <td className="text-white text-right">Credit</td>
                    </tr>
                  </thead>
                  <tbody>
              {this.state.confirmjv.debit.map((jv) => (
                <tr>
                  <td>{jv.debit.name}</td>
                  <td className="text-right">
                    <NumericFormat
                      displayType={"text"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      disabled={true}
                      value={jv.debitval}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat>
                  </td>
                  <td></td>
                </tr>
              ))}
            
              {this.state.confirmjv.taxreqchecked === 1 && this.state.confirmjv.taxchecked? (
                this.state.confirmjv.debit.map((jv) => (
                <tr>
                  <td>{jv.tax1.name}</td>
                  <td className="text-right">
                    <NumericFormat
                      displayType={"text"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      disabled={true}
                      value={jv.tax1val}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat>
                  </td>
                  <td></td>
                </tr>))
              ) : (
                ""
              )}
             {this.state.confirmjv.taxreqchecked === 1 && this.state.confirmjv.taxchecked && this.state.confirmjv.showtax2 ? (
                this.state.confirmjv.debit.map((jv) => (
                <tr>
                  <td>{jv.tax2.name}</td>
                  <td className="text-right">
                    <NumericFormat
                      displayType={"text"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      disabled={true}
                      value={jv.tax2val}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat>
                  </td>
                  <td></td>
                </tr>))
              ) : (
                ""
              )}
              <tr>
                <td>{this.state.confirmjv.credit.name}</td>
                <td></td>
                <td className="text-right">
                  <NumericFormat
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    disabled={true}
                    value={this.state.confirmjv.creditval}
                    thousandSeparator={true}
                    thousandsGroupStyle={thousandsdisplay}
                  ></NumericFormat>
                </td>
              </tr>
              {this.state.confirmjv.taxreqchecked === 1 && this.state.confirmjv.tcschecked === 1 ? (
                
                <tr>
                  <td>{this.state.confirmjv.tds.name}</td>
                  <td></td>
                  <td className="text-right">
                    <NumericFormat
                      displayType={"text"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      disabled={true}
                      value={this.state.confirmjv.tdsval}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat>
                  </td>
                 
                </tr>
              ) : (
                ""
              )}
              <tr>
                <td>
                  <span className="textColour text-bold">
                    Amount In Words :
                  </span>
                  {amtwords}
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <span className="textColour text-bold">Narration :</span>
                  {this.state.confirmjv.narration}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
                </table>

                <div className="form-row">
                        <fieldset className="form-group col-md-12 mr-3">
                          <button className="btn hovbuttonColour btn-sm" type="submit" onClick={this.onSubmit}>
                          {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Confirm"}</>:"Confirm"}
                          </button>
                        {/* </fieldset>
                        <fieldset className="form-group col-md-2"> */}
                          <button
                            className="btn btn-sm bg-white shadow-sm"
                            type="button"
                            onClick={this.goBack}
                          >
                           <b style={{ color: "#FA5733" }}> X</b>  Close
                          </button>
                        </fieldset>
                </div>
              </div>
            </div>
            
            <div>
             </div>
          </div>
          
        </div>
        <Footer />

      </div>
    );
  }
}

export default withRouter(MultiEntryConfirm);
