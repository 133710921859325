import React, { useState } from "react";
import { Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

export const ARSharing = () => {
  const [date, setDate] = useState('');
  const [againstdocno, setAgainstdocno] = useState('');
  const [accountcode, setAccountcode] = useState('');
  const [accountname, setAccountname] = useState('');
  const [subcustcode, setSubcustcode] = useState('');
  const [subcustname, setSubcustname] = useState('');
  const [amountinomr1, setamountinomr1] = useState('');
  const [roeofomr, setroeofomr] = useState('');
  const [amountinomr2, setamountinomr2] = useState('');
  const [reference, setReference] = useState('');
  const [advancereceipt, setadvancereceipt] = useState('');


  const changeHandlerDate=(e)=>{
    setDate(moment(new Date(e.target.value)).format("DD-MM-YYYY"))
  }
  return (
    <>
    
      <div className="small-container mp-2 padding-2 w-100%">
        <div className="card">

          <div className="card p-4">
          <div className="row ">
          <div className="d-flex ">
              <span className="btn hovbuttonColour ml-3  btn-sm" >Add</span>
              <span className="btn hovbuttonColour ml-3 bg-danger text-color-red btn-sm" >Clear</span>
            </div>
            </div>    
           <div className="row my-2">
            <div className="col-md-3 col-sm-12 my-2"> 
           
              <LocalizationProvider dateAdapter={AdapterDayjs} variant="outline">
                  <Grid container >
                    <DatePicker
                      id="date-picker-dialog"
                      label="Date "
                      renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                      autoOk={true}
                      value={date}
                      onChange={changeHandlerDate}
                      
                    />
                  </Grid>
                </LocalizationProvider>
            </div>
           </div>
           
            <div className="row my-2">
              
              <fieldset className="col-md-8 col-sm-12">
                <TextField
                  label="Date"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={date}
                  onChange={changeHandlerDate}
                />
              </fieldset>
              <div className="col-md-3 col-sm-12">
                <label>0.00</label>
              </div>
            </div>

            <div className="row my-2">
              <fieldset className="col-md-6 col-sm-12">
                <TextField
                  label="Account"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </fieldset>
              <fieldset className="col-md-6 col-sm-12">
                <TextField
                  label=""
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
             </fieldset>
            </div>

            <div className="row my-2">
              <fieldset className="col-md-6 col-sm-12">
                <TextField
                  label="Sub Customer"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </fieldset>
              <fieldset className="col-md-6 col-sm-12">
                <TextField
                  label=""
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
             </fieldset>
            </div>

            <div className="row my-2">
              <fieldset className="col-md-6 col-sm-12">
                <TextField
                  label="Amount in OMR"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </fieldset>
              <fieldset className="col-md-6 col-sm-12">
                <TextField
                  label="Roe of OMR"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
             </fieldset>
            </div>

            <div className="row my-2">
              <fieldset className="col-md-6 col-sm-12">
                <TextField
                  label="Amount in OMR"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </fieldset>
              <fieldset className="col-md-6 col-sm-12">
                <TextField
                  label="Reference"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
             </fieldset>
            </div>

            <div className="row my-2">
              <fieldset className="col-md-6 col-sm-12">
                <TextField
                  label="Advance Receipt"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  fullWidth
                />
              </fieldset>
            </div>
            

          </div>
        </div>
      </div>
    </>
  );
};
export default ARSharing;
