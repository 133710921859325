import { createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, thousandsdisplay, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import {assetmanagementid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { ThemeProvider } from "@mui/material";
import TransportationServices from "./TransportationServices";
import moment from "moment";
import {NumericFormat} from "react-number-format";
import Swal from "sweetalert2";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      cell: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class TransportationTable extends Component {
   constructor(props) {
    super(props);

    this.state = {
        trips:[],
        places: [],
        active:true,
        rent:false,
        type:this.props.location.state.type===undefined|| this.props.location.state.type === null?0:this.props.location.state.type,
        loading:false,
    };
  }

  
  handleTripChange = () => {
    this.setState({ active: true, rent: false });
  };
  handlerentChange = () => {
    this.setState({ rent: true, active: false});
  };


    deleteTrip(id){
      Swal.fire({
        title: "Delete trip?",
        text: "Are you sure you want to delete trip?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor:'#152f5f',
        cancelButtonText: "No",
        reverseButtons: true
    }).then((result)=>{
        if (result.value) {
          TransportationServices.DeleteTrip(id).then
          ((response) =>{
            Swal.fire({
              title: "Trip deleted successfully",
              text: ".",
              icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor:'#152f5f',
            })
          }).then(()=>this.refresh())
          .catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
      })
        }
  })
}

deletePlace(id){
  Swal.fire({
    title: "Delete trip?",
    text: "Are you sure you want to delete place?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Yes",
    confirmButtonColor:'#152f5f',
    cancelButtonText: "No",
    reverseButtons: true
}).then((result)=>{
    if (result.value) {
      TransportationServices.DeletePlace(id).then
      ((response) =>{
        Swal.fire({
          title: "Place deleted successfully",
          text: ".",
          icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor:'#152f5f',
        })
      }).then(()=>this.refresh())
      .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
    }
})
}


    refresh(){
    TransportationServices.getAllTrips(currentTenant).then(res=>{
        this.setState({trips:res.data,loading:false})
    })

    TransportationServices.getAllPlaces(currentTenant).then(res=>{
        this.setState({places:res.data,loading:false})
    })
  }



    componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({loading:true})
   this.refresh()
  }

  render() {
   
        const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          sortOrder: "asc",
        },
      },
      {
        name: "customid",
        label: "TripId",
        options: { filter: true, sort: true },
      },
      {
        name: "vehicle",
        label: "Vehicle",
        options: { filter: true, sort: true },
      },
      {
        name: "originname",
        label: "Origin",
        options: { filter: true, sort: true },
      },
      {
        name: "destinationname",
        label: "Destination",
        options: { filter: true, sort: true },
      },
      {
        name: "driver1name",
        label: "Driver1",
        options: { filter: true, sort: true },
      },
      {
        name: "driver2name",
        label: "Driver2",
        options: { filter: true, sort: true },
      },
      {
        name: "millage",
        label: "Millage",
        options: { filter: true, sort: true },
      },
      {
        name: "loading",
        label: "Loading",
        options: { filter: true, sort: true },
      },
      {
        name: "unloading",
        label: "unloading",
        options: { filter: true, sort: true },
      },
      {
        name: "differance",
        label: "Differance",
        options: { filter: true, sort: true },
      },
      {name: "indate",label: "In Date",options: {filter: false,sort: true,sortOrder: 'asc', 
      customBodyRender:(tableMeta)=>{
        return(moment(tableMeta).format('DD-MM-YYYY'))
      }}},
      {name: "outdate",label: "Out Date",options: {filter: false,sort: true,sortOrder: 'asc', 
      customBodyRender:(tableMeta)=>{
        return(moment(tableMeta).format('DD-MM-YYYY'))
      }}},
      {name: "totalexp",label: "Total Expensives",
          options: {filter: false,sort: true,
            customBodyRender:(tableMeta)=>{
              return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
            },
            setCellProps: value => ({ style: { textAlign:'right'}}),
          }},
      {
        name: "status",
        label:"Action",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: assetmanagementid === "2" || assetmanagementid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (<>
             <Link as="button" className="fa fa-list-alt mr-2"
              to={{pathname: "/tripdetails"}} state ={this.state.trips?.find(
                (e) => e.id === tableMeta.rowData[0]
              )}></Link>
              {value==="Pending"?<>
             <Link as="button" className="fa fa-edit mr-2"
              to={{pathname: "/newtrip"}} state ={{id:tableMeta.rowData.find(e=>e),name:"Edit Trip",data:this.state.trips?.find(
                (e) => e.id === tableMeta.rowData[0]
              )}}></Link>
              <span
                as="button"
                className="fa fa-trash text-danger"
                style={{cursor:'pointer'}}
                onClick={() => {
                    this.deleteTrip(tableMeta.rowData.find((e) => e));
                }}
              ></span>
              </>:''}
              </>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {assetmanagementid === "2" || assetmanagementid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{pathname: "/newtrip"}}
                state= {{
                  id:-1,
                  name: "New Trip"
              }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };


    const columns2 = [
        {
          name: "id",
          label: "Id",
          options: {
            filter: false,
            sort: false,
            display: false,
            sortOrder: "asc",
          },
        },
        {
          name: "place",
          label: "Place",
          options: { filter: true, sort: true, },
        },
        {
            name: "city",
            label: "City",
            options: { filter: true, sort: true },
          },
          {
            name: "state",
            label: "State",
            options: { filter: true, sort: true },
          },
          {
            name: "country",
            label: "Country",
            options: { filter: true, sort: true },
          },
          {
            name: "pincode",
            label: "Pin Code",
            options: { filter: true, sort: true },
          },
        {
          name: "Action",
          options: {
            filter: false,
            sort: false,
            empty: true,
            display: assetmanagementid === "2" || assetmanagementid === "3" ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (<>
               <Link as="button"  className="btn btn-sm hovbuttonColour mr-2"
              to={{pathname: "/addplace"}} state ={{id:tableMeta.rowData.find(e=>e),name: "Edit Place" }}>Edit</Link>

                <span
                  as="button"
                  data-tip
                  data-for="delete"
                  className="btn btn-sm deletebutton"
                  onClick={() => {
                      this.deletePlace(tableMeta.rowData.find((e) => e));
                  }}
                >Delete</span>
                </>
              );
            },
          },
        },
      ];
  
      const options2 = {
        filterType: "checkbox",
        selectableRows: "none",
        customToolbar: () => {
          return (
            <>
              {assetmanagementid === "2" || assetmanagementid === "3" ? (
                <Link
                  as="button"
                  className="float-right btn btn-sm m-0 p-0"
                  to={{
                    pathname: "/addplace"}}
                    state= {{ id: -1, name: "Add Place" }}
                >
                  <CustomToolbar />
                </Link>
              ) : (
                ""
              )}
            </>
          );
        },
      };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/assetmanagement" className="text-info">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">Transportation</li>
            </ol>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    {/* /.card */}

                    {/* <div className="card"> */}
                    <div className="card-header m-0 p-2">
                      <div className="text-center text-green">
                        {/* {this.state.message} */}
                      </div>
                    </div>

                    {/* <div className="card-body p-0"> */}
                    <div>
                      {this.state.loading === true ? (
                        <div className="text-center cursorProgress">{spin}</div>
                      ) : (
                        <>
                          <div className="form-row">
                             <fieldset className={`form-group col-md-6 shadow-sm text-center btn ${this.state.type === 0?'borderedbutton2':' hovbuttonColour'}`} onClick={()=>this.setState({type:0})} disabled={this.state.type===0}>
                       Trips
                       </fieldset>
                       <fieldset className={`form-group col-md-6 shadow-sm btn text-center ${this.state.type === 1?'borderedbutton2':' hovbuttonColour'}`} onClick={()=>this.setState({type:1})} disabled={this.state.type===1}>
                         Places
                       </fieldset>
                          </div>

                          {this.state.type===0 ? (
                              <ThemeProvider theme={theme}>
                                <MUIDataTable
                                  className="table-sm px-3"
                                  title={"Trips"}
                                  id="assets"
                                  data={this.state.trips}
                                  columns={columns}
                                  options={options}
                                />
                              </ThemeProvider>
                          ) : (
                            <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Places"}
                              id="assets"
                              data={this.state.places}
                              columns={columns2}
                              options={options2}
                            />
                          </ThemeProvider>
                          )}
                        </>
                      )}
                    </div>
                    {/* </div> */}
                    {/* </div> */}

                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(TransportationTable);
