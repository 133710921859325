import React, { Suspense, lazy } from "react";
import withRouter from "../../withRouter.js";
import "./adminDashboard.css";

import { Col, Row, Spinner } from "react-bootstrap";
import Credentials, {
  currentTenant,
  currentUser,
  financialyearend,
  financialyearstart,
  roles,
  userid,
  transactionFrom
} from "../../Common/Credentials";

import SalesServices from "../../Sales/SalesServices";
import CountUp from "react-countup";
import AdminDashboardServices from "./AdminDashboardServices";
import {
  assetmanagement,
  inventory,
  purchase,
  sales,
  production,
  screens,
} from "../../Common/Products";
import { Link } from "react-router-dom";
// import { NumericFormat } from "react-number-format";
// import PurchaseServices from "../../Purchase/PurchseServices";
import {
  
  Backdrop,
  Grid,
  
  Skeleton,
  
  TextField,
 
} from "@mui/material";
import AssetsService from "../../AssetManagement/AssetsService";

import InventoryService from "../../inventory/InventoryService";
import finanzz from "../../images/Finanzzi.png";
import { spin } from "../../Common/NewLoader";
import UsercustomerService from "../../Masters/Usercustomers/UsercustomerService";

// import { inventoryid } from "../../Common/Permissions.js";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
// import IOSSwitch from "../../CommonComponents/IOSSwitch.js";
// import MasterService from "../../Masters/MasterService.js";
// import Swal from "sweetalert2";

const UserCustomerDashboard = lazy(() =>
  import("./Yearly/UserCustomerDashboard")
);
// const Saleschart = lazy(() => import("./Yearly/Saleschart"));
// const Purchasechart = lazy(() => import("./Yearly/Purchasechart"));
const Production = lazy(() => import("./Yearly/Production"));
const InventoryChart = lazy(() => import("./Yearly/InventoryChart"));
const SalesVsPurchases = lazy(() => import("./Yearly/SalesVsPurchase"));
// const CustomerSalesbyYear = lazy(() => import("./Yearly/CustomerSalesbyYear"));
// const SuppliersPurchasebyYear = lazy(() =>
//   import("./Yearly/SuppliersPurchasebyYear")
// );
const ProductTrends = lazy(() => import("./Yearly/ProductTrends"));
// const Revenue = lazy(() => import("./Yearly/RevenueByYear"));
// const PaymentsDone = lazy(() => import("./Yearly/Payments/PaymentsDone"));
// const PaymentsDue = lazy(() => import("./Yearly/Payments/PaymentsDue"));
// const PaymentsReceived = lazy(() =>
//   import("./Yearly/Payments/PaymentsReceived")
// );
// const PaymentsPending = lazy(() => import("./Yearly/Payments/PaymentsPending"));
// const Header = lazy(()=>import("../../Component/Header"));
// const Menu = lazy(()=>import("../../Component/Menu"));
// const Footer = lazy(()=>import("../../Component/Footer"));
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
// import filterIcon from "../../images/Icons/filter.png";
import SalesStatistics from "./SalesDashboard/SalesStatistics.js";
// import ProductSalesList from "./Inventory/ProductSalesList.jsx";
import PurchaseStatistics from "./PurchaseDashboard/PurchaseStatistics.js";
import SuperAdminDashboard from "./SuperAdminDashboard/SuperAdminDashboard.js";
// const MaterialUISwitch = styled(Switch)(({ theme }) => ({
//   width: 62,
//   height: 34,
//   padding: 7,
//   '& .MuiSwitch-switchBase': {
//     margin: 1,
//     padding: 0,
//     transform: 'translateX(6px)',
//     '&.Mui-checked': {
//       color: '#fff',
//       transform: 'translateX(22px)',
//       '& .MuiSwitch-thumb:before': {
//         backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
//           '#fff',
//         )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
//       },
//       '& + .MuiSwitch-track': {
//         opacity: 1,
//         backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
//       },
//     },
//   },
//   '& .MuiSwitch-thumb': {
//     backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
//     width: 32,
//     height: 32,
//     '&:before': {
//       content: "''",
//       position: 'absolute',
//       width: '100%',
//       height: '100%',
//       left: 0,
//       top: 0,
//       backgroundRepeat: 'no-repeat',
//       backgroundPosition: 'center',
//       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
//         '#fff',
//       )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
//     },
//   },
//   '& .MuiSwitch-track': {
//     opacity: 1,
//     backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
//     borderRadius: 20 / 2,
//   },
// }));

class AdminDashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      theme: false,
      data: "",
      filters: ["Year", "Month", "Day", "Q1", "Q2", "Q3", "Q4"],
      filter: "Year",
      clientsdata: [],
      buttonClicked: 0,
      inventorydata: [],
      blockedsalesquantity: "",
      blockedpurchasequantity: "",
      saleOrder: [],
      completedso: [],
      soloader: true,
      poloader: true,
      podata: [],
      completedpo: [],
      salesdata: [],
      purchasedata: [],
      top10productsbyquantity: [],
      top10productsbyamount: [],
      top10Customers: [],
      top10Suppliers: [],
      recent5so: [],
      recent5inv: [],
      recent5po: [],
      recent5grns: [],
      type: "Quantity",
      inventory: true,
      sales: true,
      purchase: true,
      accounts: true,
      revenue: true,
      salesvpurchase: true,
      customersstats: true,
      suppliersstats: true,
      inventorystats: true,
      productionstats: true,
      assetsstats: true,
      assetsdata: [],
      assetitemlist: [],
      top10assetitems: [],
      loader: true,
      usercustomers: [],
      invoicedata: [],
      saleorders: [],
      receiptsdata: [],
      startyear: moment(financialyearstart),
      endyear: moment(financialyearend),
      page: 0,
      rowsPerPage: 10,
      showplan: false,
      planamount: "",
      planduration: "",
      selectedtenant: "",
    };
  }

  componentDidMount() {
    Credentials.setSelectedMenu("Home");
    if (
      userid === undefined ||
      currentUser === undefined ||
      currentTenant === undefined
    ) {
      // window.confirm("Your Session is Lost: Please login Back to resume");
      // Swal.fire("Session is Lost!", "Please login Back to resume", "info").then(()=>{
      // this.props.navigate(`/`)
      // })
    } else {
      UsercustomerService.GetUserCustomersbyUser(userid).then((res) =>
        this.setState({
          usercustomers:
            res.data === "" || res.data === undefined
              ? []
              : res.data.customers.split(","),
        })
      );

      this.refresh(moment(financialyearstart), moment(financialyearend));
    }
  }

  loaderupdate = () => {
    this.setState({ loader: false });
  };
  refresh = (start, end) => {
    let datadao = {
      tenantid: currentTenant,
      startdate: start,
      enddate: end,
    };
    // -----------------INVENTORY ----------------
    {
      inventory === "YES"
        ? InventoryService.retriveStock(currentTenant).then((res) => {
            this.setState({ inventorydata: res.data });
          })
        : "";
    }

    //---------------------SALES----------------------------
    if (sales === "YES") {
      AdminDashboardServices.blockedsalesquantity(currentTenant).then((res) =>
        this.setState({ blockedsalesquantity: res.data, soloader: false })
      );

      AdminDashboardServices.LastYearsales(datadao).then((response) =>
        this.setState(
          {
            data: response.data,
            // recent5so:
            //   response.data.recentso === undefined
            //     ? []
            //     : response.data.recentso.sort((a, b) =>
            //         a.createdAt < b.createdAt ? 1 : -1
            //       ),
            // recent5inv:
            //   response.data.recentinv === undefined
            //     ? []
            //     : response.data.recentinv.sort((a, b) =>
            //         a.createdAt < b.createdAt ? 1 : -1
            //       ),
            soloader: false,
          }
          // ,
          // () => {
          //   let l = 0;
          //   l = this.state.recent5so.length;
          //   for (let i = 0; i < 5 - l; i++) {
          //     this.state.recent5so.push({
          //       amount: "",
          //       createdAt: "",
          //       custid: "",
          //       customer: "",
          //       sotype: "",
          //     }
          //     );
          //   }
          //   let l1 = 0;
          //   l1 = this.state.recent5inv.length;
          //   for (let i = 0; i < 5 - l1; i++) {
          //     this.state.recent5inv.push({
          //       amount: "",
          //       createdAt: "",
          //       custid: "",
          //       customer: "",
          //       sotype: "",
          //     });
          //   }
          //   this.setState({
          //     recent5so: this.state.recent5so,
          //     recent5inv: this.state.recent5inv,
          //   });
          // }
        )
      );

     
      SalesServices.getSalesReport(datadao)
        .then((res) =>
          this.setState({ salesdata: res.data, soloader: false }, () => {
            this.Productdata();
            this.Customerdata();
          })
        )
        .catch((e) => {
          this.setState({ loader: false });
        });
    }

    // ---------------------PURCHASE---------------------
    if (purchase === "YES") {
      // AdminDashboardServices.blockedpurchasequantity(currentTenant).then(
      //   (res) => this.setState({ blockedpurchasequantity: res.data })
      // );

      AdminDashboardServices.LastYearPurchase(datadao).then((response) =>
        this.setState(
          {
            podata: response.data,
            recent5po:
              response.data.recentorders === undefined
                ? []
                : response.data.recentorders.sort((a, b) =>
                    a.createdAt < b.createdAt ? 1 : -1
                  ),
            recent5grns:
              response.data.recentgrns === undefined
                ? []
                : response.data.recentgrns.sort((a, b) =>
                    a.createdAt < b.createdAt ? 1 : -1
                  ),
            poloader: false,
          },
          () => {
            let l = 0;
            l = this.state.recent5po.length;
            for (let i = 0; i < 5 - l; i++) {
              this.state.recent5po.push({
                amount: "",
                createdAt: "",
                custid: "",
                customer: "",
                sotype: "",
              });
            }
            let l1 = 0;
            l1 = this.state.recent5grns.length;
            for (let i = 0; i < 5 - l1; i++) {
              this.state.recent5grns.push({
                amount: "",
                createdAt: "",
                custid: "",
                customer: "",
                sotype: "",
              });
            }
            this.setState({
              recent5po: this.state.recent5po,
              recent5grns: this.state.recent5grns,
              poloader: false,
            });
          }
        )
      );
      // PurchaseServices.getPurchaseReport(datadao).then((response) => {
      //   this.setState({ purchasedata: response.data });
      // });
    }

    //------------------------ASSETS---------------------------
    if (assetmanagement === "YES") {
      AssetsService.getAssetsData(currentTenant).then((res) => {
        this.setState({ assetsdata: res.data }, () => {
          this.Assetsdata();
        });
      });
    }

    this.loaderupdate();
  };

  HandleyearChange = (e) => {
    alert("Im changed")
    let diff =
      moment(financialyearstart).format("yyyy") ===
      moment(financialyearend).format("yyyy");
    let smonth = moment(financialyearstart).format("MM");
    let emonth = moment(financialyearend).format("MM");
    let syear = moment(new Date(e)).format("yyyy");
    let eyear = moment(new Date(e))
      .add(diff ? 0 : 1, "y")
      .format("yyyy");
    this.setState({
      startyear: moment(`${syear} ${smonth}`).format("yyyy MM"),
      endyear: moment(`${eyear} ${emonth}`).format("yyyy MM"),
    });
    this.refresh(moment(`${syear} ${smonth}`), moment(`${eyear} ${emonth}`));
    // let data = {
    //   tenantid: currentTenant,
    //   startdate: moment(new Date(e)),
    //   enddate: moment(new Date(e)).add(diff?0:1,'y'),
    // }
  };

  Productdata = () => {
    this.setState({ productslist: [], custbyproducts: [] }, () => {
      // Sorting Sales Products
      const products = this.state.salesdata.reduce(
        (
          item,
          {
            itemname,
            itemid,
            quantity,
            totalprice,
            totalpricewtax,
            createdAt,
            customer,
            custid,
          }
        ) => {
          if (!item[itemname]) item[itemname] = [];
          item[itemname].push({
            itemid: itemid,
            itemname: itemname,
            quantity: quantity,
            totalprice: totalprice,
            totalpricewtax: totalpricewtax,
            createdAt: createdAt,
            customer: customer,
            custid: custid,
          });
          return item;
        },
        {}
      );
      this.setState({ products: products });
      // End of Sorting Products

      // Calcuating total sales by products
      Object.entries(products).forEach(([key, value]) => {
        let a = {
          itemname: key,
          itemid: value.map((e) => e.itemid)[0],
          quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
          totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
          totalpricewtax: value.reduce((a, v) => (a = a + v.totalpricewtax), 0),
        };
        this.state.productslist.push(a);
      });
      // End of Calcuating total sales products

      // Top 10 Products

      const topproductsbyquantity = []
        .concat(this.state.productslist)
        .sort((a, b) => (a.quantity < b.quantity ? 1 : -1));

      const topproductsbyamount = []
        .concat(this.state.productslist)
        .sort((a, b) => (a.totalpricewtax < b.totalpricewtax ? 1 : -1));

      this.setState({
        top10productsbyquantity: topproductsbyquantity.slice(0, 10),
        top10productsbyamount: topproductsbyamount.slice(0, 10),
      });
    });
  };

  Customerdata = () => {
    this.setState({ customerslist: [], productsbycust: [] }, () => {
      const customers = this.state.salesdata.reduce(
        (
          cust,
          {
            itemname,
            itemid,
            quantity,
            totalprice,
            totalpricewtax,
            createdAt,
            customer,
            custid,
          }
        ) => {
          if (!cust[customer]) cust[customer] = [];
          cust[customer].push({
            itemid: itemid,
            itemname: itemname,
            quantity: quantity,
            totalprice: totalprice,
            totalpricewtax: totalpricewtax,
            createdAt: createdAt,
            customer: customer,
            custid: custid,
          });
          return cust;
        },
        {}
      );
      this.setState({ customers: customers });

      Object.entries(customers).forEach(([key, value]) => {
        let a = {
          customer: key,
          custid: value.map((e) => e.custid)[0],
          quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
          totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
          totalpricewtax: value.reduce((a, v) => (a = a + v.totalpricewtax), 0),
        };
        this.state.customerslist.push(a);
      });

      // Top 10 Customers
      const topcustomers = []
        .concat(this.state.customerslist)
        .sort((a, b) => (a.totalpricewtax < b.totalpricewtax ? 1 : -1));

      this.setState({ top10Customers: topcustomers.slice(0, 10) });
    });
  };

  Assetsdata = () => {
    this.setState({ assetitemlist: [] }, () => {
      // Sorting Sales Products
      const products = this.state.assetsdata.items.reduce(
        (
          item,
          {
            itemname,
            itemId,
            quantity,
            category,
            catid,
            assettype,
            moveabletype,
            immoveabletype,
          }
        ) => {
          if (!item[itemname]) item[itemname] = [];
          item[itemname].push({
            itemId: itemId,
            itemname: itemname,
            quantity: quantity,
            category: category,
            catid: catid,
            assettype: assettype,
            moveabletype: moveabletype,
            immoveabletype: immoveabletype,
          });
          return item;
        },
        {}
      );
      // this.setState({ products: products });
      // End of Sorting Products

      // Calcuating total sales by products
      Object.entries(products).forEach(([key, value]) => {
        let a = {
          itemname: key,
          itemId: value.map((e) => e.itemId)[0],
          quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
        };
        this.state.assetitemlist.push(a);
      });
      // End of Calcuating total sales products

      // Top 10 Products

      const top10assetitems = []
        .concat(this.state.assetitemlist)
        .sort((a, b) => (a.quantity < b.quantity ? 1 : -1));
      if (top10assetitems.length < 5) {
        let i = 0;
        let length = 5 - top10assetitems.length;
        for (i = 0; i < length; i++) {
          top10assetitems.push({
            itemname: "",
            itemid: "",
            quantity: "",
          });
        }
      }
      this.setState({
        top10assetitems: top10assetitems.slice(0, 5),
      });
    });
  };

  render() {
    return (<>
       {/* this.state.loader === true?<h1>Loading</h1>: */} 
      <Suspense
        fallback={
          <div className="centerItem" style={{ height: "70vh" }}>
            <div>
              <img
                src={finanzz}
                alt="Logo..."
                width="100%"
                height="100"
                className="finanzzlogo"
              />
              {/* <div>{spin}</div> */}
            </div>
          </div>
        }
      >
        <div>
          <Header />
          <Menu />
          <div
            className={`content-wrapper ${
              this.state.theme === false ? "" : "bg-black5"
            }`}
          >
            {this.state.loader === true ? (
              <div className="centerItem" style={{ height: "70vh" }}>
                <div>
                  <img
                    src={finanzz}
                    alt="Logo..."
                    width="100%"
                    height="100"
                    className="finanzzlogo"
                  />
                  <div>{spin}</div>
                </div>
              </div>
            ) : (
              <div
                className={`p-3 ${
                  this.state.theme === false ? "" : "bg-black5"
                }`}
              >
                <div className="d-flex justify-content-between">
                  <div className="heading">
                    <h4>
                      <b
                        className={`${
                          this.state.theme === false
                            ? "textColour"
                            : "text-white"
                        }`}
                      >
                        Financial Year{" "}
                        {moment(this.state.startyear).format("yyyy")}{" "}
                        {financialyearstart.substring(0,4) ===
                        financialyearend.substring(0,4)
                          ? ""
                          : - moment(this.state.endyear).format("yyyy")}
                      </b>
                    </h4>
                  </div>
                  <Col sm={2} lg={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Grid container justifyContent="space-around">
                        <DatePicker
                          variant="inline"
                          id="date-picker-dialog"
                          label="Financial Start Year"
                          openTo="year"
                          views={["year"]}
                          minDate={"2022"}
                          maxDate={new Date()}
                          value={this.state.startyear}
                          onChange={this.HandleyearChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              className={`${
                                this.state.theme === false ? "" : "text-white"
                              }`}
                            />
                          )}
                          closeOnSelect={true}
                        />
                      </Grid>
                    </LocalizationProvider>
                  </Col>

                  <div>
                    {/* <FormGroup>
      <FormControlLabel
        control={<MaterialUISwitch sx={{ m: 1 }} checked = {this.state.theme} onClick={()=>this.setState({theme:!this.state.theme})} />}
        // label="Mode"
        className={`${this.state.theme===false?'':'text-white'}`}
      />
      </FormGroup> */}
                  </div>
                </div>

                {roles === "ROLE_SUPERADMIN" ? (
                  <Row
                    className={` ${
                      this.state.theme === false ? "" : "bg-black2"
                    }`}
                  >
                    <Col sm={12} lg={12}>
                      <SuperAdminDashboard />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {inventory === "YES" &&
                this.state.inventorystats === true &&
                screens.includes("Inventory Stock Status Dashboard") &&
                moment(this.state.startyear).format("yyyy") ===
                  moment(financialyearstart).format("yyyy") ? (
                  <Suspense fallback="">
                    <InventoryChart
                      data={this.state.inventorydata}
                      sales={this.state.blockedsalesquantity}
                      purchase={this.state.blockedpurchasequantity}
                      theme={this.state.theme}
                    />
                  </Suspense>
                ) : (
                  ""
                )}

                {purchase === "YES" &&
                this.state.purchase === true &&
                (screens.includes("Purchase Dashboard") ||
                  screens.includes("Recent Purchase orders Dashboard") ||
                  screens.includes("Purchase Statistics Chart Dashboard")) ? (
                  <Row
                    className={` ${
                      this.state.theme === false ? "" : "bg-black2"
                    }`}
                  >
                    {screens.includes("Purchase Statistics Chart Dashboard") ? (
                      <Col sm={12} lg={12}>
                          <PurchaseStatistics data={this.state.podata} poloader={this.state.poloader} />
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                ) : (
                  ""
                )}

                {sales === "YES" &&
                this.state.sales === true &&
                (screens.includes("Sales Dashboard") ||
                  screens.includes("Recent Sale Orders Dashboard") ||
                  screens.includes("Sales Statistics Chart Dashboard") ||
                  screens.includes("Overdues Dashboard")) ? (
                  <Row
                    className={` ${
                      this.state.theme === false ? "" : "bg-black2"
                    }`}
                  >
                    <Col sm={12} lg={12} className="heading">
                      {/* <div className="dropdown">
                    <span
                      className={`btn btn-sm dropdown-toggle  ${this.state.theme===false?'textColour':'text-white'}`}
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <b style={{ fontSize: "large" }} >Sales</b>
                    </span>

                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    ></div>
                  </div> */}
                      {/* <h4>
                    <b className={`${this.state.theme===false?'textColour':'text-white'}`}>Sales</b>
                  </h4> */}
                    </Col>


                    <Col lg={12}>
                      <SalesStatistics data={this.state.data} soloader={this.state.soloader} />
                    </Col>

                    {screens.includes("Overdues Dashboard") ? (
                      <Col sm={12} lg={12}>
                          <Suspense>
                            <UserCustomerDashboard
                              // usercustomers={this.state.usercustomers}
                              theme={this.state.theme}
                            />
                          </Suspense>
                        {/* )} */}
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                ) : (
                  ""
                )}

                {/* {accounts === "YES" &&
                this.state.accounts === true &&
                (screens.includes("Accounts Payables Dashboard") ||
                  screens.includes("Accounts Reciveables Dashboard")) ? (
                  <div
                    className={` ${
                      this.state.theme === false ? "" : "bg-black2"
                    }`}
                  >
                  
                    {screens.includes("Accounts Payables Dashboard") ? (
                      <Row>
                        <Col sm={12} lg={12} className="heading">
                          <h4>
                            <b
                              className={`${
                                this.state.theme === false
                                  ? "textColour"
                                  : "text-white"
                              }`}
                            >
                              Accounts
                            </b>
                          </h4>
                        </Col>
                        <Col lg={4}>
                         
                          <div
                            className={`stats-card danger-card mb-4 ${
                              this.state.theme === false ? "" : "bg-black5"
                            }`}
                          >
                            {this.state.poloader === true ? (
                              <span className="d-flex justify-content-center text-danger">
                                <Spinner animation="border" size="lg" />
                              </span>
                            ) : (
                              <>
                                <h1>
                                  <CountUp
                                    start={0}
                                    end={0}
                                    duration={2.5}
                                    className="text-danger"
                                  />
                                </h1>
                                <p
                                  className={`py-1 ${
                                    this.state.theme === false
                                      ? "text-black"
                                      : "text-white"
                                  }`}
                                >
                                  Total Amount to be Paid
                                </p>
                                <i className="fas fa-money-check-alt" />
                              </>
                            )}
                          </div>
                        </Col>
                        <Col lg={4}>
                          <Suspense>
                            <PaymentsDone theme={this.state.theme} />
                          </Suspense>
                        </Col>

                        <Col lg={4}>
                          <Suspense>
                            <PaymentsDue theme={this.state.theme} />
                          </Suspense>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}

                    
                    {screens.includes("Accounts Reciveables Dashboard") ? (
                      <Row>
                        <Col lg={4}>
                          <div
                            className={`stats-card light-blue-card mb-4 ${
                              this.state.theme === false ? "" : "bg-black5"
                            }`}
                          >
                            {this.state.poloader === true ? (
                              <span className="d-flex justify-content-center text-blue">
                                <Spinner animation="border" size="lg" />
                              </span>
                            ) : (
                              <>
                                <h1>
                                  <CountUp
                                    start={0}
                                    end={0}
                                    duration={2.5}
                                    className="text-blue"
                                  />
                                </h1>
                                <p
                                  className={`py-1 ${
                                    this.state.theme === false
                                      ? "text-black"
                                      : "text-white"
                                  }`}
                                >
                                  Total Amount to be Received
                                </p>
                                <i className="fas fa-hand-holding-usd" />
                              </>
                            )}
                          </div>
                        </Col>
                        <Col lg={4}>
                          <Suspense>
                            <PaymentsReceived theme={this.state.theme} />
                          </Suspense>
                        </Col>
                        <Col lg={4}>
                          <Suspense>
                            <PaymentsPending theme={this.state.theme} />
                          </Suspense>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )} */}
                {/* {this.state.revenue === true &&
                screens.includes("Revenue Statistics Dashboard") ? (
                  <Row
                    className={` ${
                      this.state.theme === false ? "" : "bg-black2"
                    }`}
                  >
                    <Col sm={12} lg={12} className="heading">
                      <h4>
                        <b
                          className={`${
                            this.state.theme === false
                              ? "textColour"
                              : "text-white"
                          }`}
                        >
                          Revenue Statistics
                        </b>
                      </h4>
                    </Col>
                    <Col sm={12} lg={12}>
                      {this.state.soloader === true ? (
                        <span className="d-flex justify-content-center textColour">
                          <Spinner animation="border" size="lg" />
                        </span>
                      ) : (
                        <Suspense>
                          <Revenue theme={this.state.theme} />
                        </Suspense>
                      )}
                    </Col>
                  </Row>
                ) : (
                  ""
                )} */}

                {purchase === "YES" &&
                sales === "YES" &&
                this.state.salesvpurchase === true &&
                screens.includes("Sales vs Purchase Dashboard") ? (
                  <Row
                    className={` ${
                      this.state.theme === false ? "" : "bg-black2"
                    }`}
                  >
                    {/* <Col sm={12} lg={12} className="heading">
                      <h4>
                        <b
                          className={`${
                            this.state.theme === false
                              ? "textColour"
                              : "text-white"
                          }`}
                        >
                          Sales vs Purchase
                        </b>
                      </h4>
                    </Col> */}
                    <Col sm={12} lg={12}>
                      {this.state.soloader === true ? (
                        <span className="d-flex justify-content-center textColour">
                          <Skeleton height={500} width="100%"/>
                        </span>
                      ) : (
                        <Suspense>
                          <SalesVsPurchases
                            soyear={this.state.data.year}
                            soyearamount={this.state.data.yearamount}
                            soyearquantity={this.state.data.yearquantity}
                            somonth={this.state.data.month}
                            somonthamount={this.state.data.monthamount}
                            somonthquantity={this.state.data.monthquantity}
                            soweek={this.state.data.week}
                            soweekamount={this.state.data.weekamount}
                            soweekquantity={this.state.data.weekquantity}
                            poyear={this.state.podata.year}
                            poyearamount={this.state.podata.yearamount}
                            poyearquantity={this.state.podata.yearquantity}
                            pomonth={this.state.podata.month}
                            pomonthamount={this.state.podata.monthamount}
                            pomonthquantity={this.state.podata.monthquantity}
                            poweek={this.state.podata.week}
                            poweekamount={this.state.podata.weekamount}
                            poweekquantity={this.state.podata.weekquantity}
                            theme={this.state.theme}
                            startyear={this.state.startyear}
                            endyear={this.state.endyear}
                          />
                        </Suspense>
                      )}
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {/* {sales === "YES" &&
                this.state.customersstats === true &&
                screens.includes("Customers Dashboard") ? (
                  <Row
                    className={` ${
                      this.state.theme === false ? "" : "bg-black2"
                    }`}
                  >
                    <Col sm={12} lg={12} className="heading">
                      <h4>
                        <b
                          className={`${
                            this.state.theme === false
                              ? "textColour"
                              : "text-white"
                          }`}
                        >
                          Customers
                        </b>
                      </h4>
                    </Col>
                    <Col sm={12} lg={12}>
                      {this.state.soloader === true ? (
                        <span className="d-flex justify-content-center textColour">
                          <Spinner animation="border" size="lg" />
                        </span>
                      ) : (
                        <Suspense>
                          <CustomerSalesbyYear
                            theme={this.state.theme}
                            start={this.state.startyear}
                            end={this.state.endyear}
                          />
                        </Suspense>
                      )}
                    </Col>
                  </Row>
                ) : (
                  ""
                )} */}
                {/* {purchase === "YES" &&
                this.state.suppliersstats === true &&
                screens.includes("Suppliers Dashboard") ? (
                  <Row
                    className={` ${
                      this.state.theme === false ? "" : "bg-black2"
                    }`}
                  >
                    <Col sm={12} lg={12} className="heading">
                      <h4>
                        <b
                          className={`${
                            this.state.theme === false
                              ? "textColour"
                              : "text-white"
                          }`}
                        >
                          Suppliers
                        </b>
                      </h4>
                    </Col>
                    <Col sm={12} lg={12}>
                      {this.state.soloader === true ? (
                        <span className="d-flex justify-content-center textColour">
                          <Spinner animation="border" size="lg" />
                        </span>
                      ) : (
                        <Suspense>
                          <SuppliersPurchasebyYear
                            theme={this.state.theme}
                            start={this.state.startyear}
                            end={this.state.endyear}
                          />
                        </Suspense>
                      )}{" "}
                    </Col>
                  </Row>
                ) : (
                  ""
                )} */}
                {inventory === "YES" &&
                this.state.inventorystats === true &&
                screens.includes("Products Trend Dashboard") ? (
                  <Row
                    className={` ${
                      this.state.theme === false ? "" : "bg-black2"
                    }`}
                  >
                    {/* <Col sm={12} lg={12} className="heading">
                      <h4>
                        <b
                          className={`${
                            this.state.theme === false
                              ? "textColour"
                              : "text-white"
                          }`}
                        >
                          Items/Products
                        </b>
                      </h4>
                    </Col> */}
                    <Col sm={12} lg={12}>
                     
                        <>
                        {/* <Col sm={12} lg={6}>
                          <ProductSalesList/>
                          </Col> */}
                            <Col sm={12} lg={12}>
                        <Suspense>
                        <ProductTrends theme={this.state.theme} />
                        </Suspense>
                        </Col>
                        </>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {assetmanagement === "YES" &&
                this.state.assetsstats === true &&
                screens.includes("Assets Dashboard") ? (
                  <Row
                    className={` ${
                      this.state.theme === false ? "" : "bg-black2"
                    }`}
                  >
                    <Col sm={12} lg={12} className="heading">
                      <h4>
                        <b
                          className={`${
                            this.state.theme === false
                              ? "textColour"
                              : "text-white"
                          }`}
                        >
                          Assets
                        </b>
                      </h4>
                    </Col>
                    <Col sm={6} lg={6}>
                      <Row>
                        <Col sm={6} lg={6}>
                          <Link to={{ pathname: "/asset" }}>
                            <div
                              className={`stats-card purple-card mb-4 ${
                                this.state.theme === false ? "" : "bg-black5"
                              }`}
                            >
                              {this.state.soloader === true ? (
                                <span className="d-flex justify-content-center text-purple">
                                  <Spinner animation="border" size="lg" />
                                </span>
                              ) : (
                                <>
                                  <h3>
                                    <CountUp
                                      start={0}
                                      end={
                                        this.state.assetsdata.totalassets ===
                                        undefined
                                          ? 0
                                          : this.state.assetsdata.totalassets
                                      }
                                      duration={1.5}
                                      className="text-purple"
                                    />
                                  </h3>
                                  <p
                                    className={`${
                                      this.state.theme === false
                                        ? "text-dark"
                                        : "text-white"
                                    }`}
                                  >
                                    Total Assets
                                  </p>
                                  <i className="fas fa-toolbox" />
                                </>
                              )}
                            </div>
                          </Link>
                        </Col>
                        <Col sm={6} lg={6}>
                          <Link to={{ pathname: "/asset" }}>
                            <div
                              className={`stats-card success-card mb-4 ${
                                this.state.theme === false ? "" : "bg-black5"
                              }`}
                            >
                              {this.state.soloader === true ? (
                                <span className="d-flex justify-content-center text-success">
                                  <Spinner animation="border" size="lg" />
                                </span>
                              ) : (
                                <>
                                  <h3>
                                    <CountUp
                                      start={0}
                                      end={
                                        this.state.assetsdata.active ===
                                        undefined
                                          ? 0
                                          : this.state.assetsdata.active
                                      }
                                      duration={1.5}
                                      className="text-success"
                                    />
                                  </h3>
                                  <p
                                    className={`${
                                      this.state.theme === false
                                        ? "text-dark"
                                        : "text-white"
                                    }`}
                                  >
                                    Active
                                  </p>
                                  <i className="fas fa-sync" />
                                </>
                              )}
                            </div>
                          </Link>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} lg={6}>
                          <Link to={{ pathname: "/asset" }}>
                            <div
                              className={`stats-card success-card mb-4 ${
                                this.state.theme === false ? "" : "bg-black5"
                              }`}
                            >
                              {this.state.soloader === true ? (
                                <span className="d-flex justify-content-center text-success">
                                  <Spinner animation="border" size="lg" />
                                </span>
                              ) : (
                                <>
                                  <h3>
                                    <CountUp
                                      start={0}
                                      end={
                                        this.state.assetsdata.rental ===
                                        undefined
                                          ? 0
                                          : this.state.assetsdata.rental
                                      }
                                      duration={1.5}
                                      className="text-success"
                                    />
                                  </h3>
                                  <p
                                    className={`${
                                      this.state.theme === false
                                        ? "text-dark"
                                        : "text-white"
                                    }`}
                                  >
                                    Rental
                                  </p>
                                  <i className="fas fa-sync" />
                                </>
                              )}
                            </div>
                          </Link>
                        </Col>
                        <Col sm={6} lg={6}>
                          <Link to={{ pathname: "/asset" }}>
                            <div
                              className={`stats-card danger-card mb-4 ${
                                this.state.theme === false ? "" : "bg-black5"
                              }`}
                            >
                              {this.state.soloader === true ? (
                                <span className="d-flex justify-content-center text-danger">
                                  <Spinner animation="border" size="lg" />
                                </span>
                              ) : (
                                <>
                                  <h3>
                                    <CountUp
                                      start={0}
                                      end={
                                        this.state.assetsdata.sold === undefined
                                          ? 0
                                          : this.state.assetsdata.sold
                                      }
                                      duration={1.5}
                                      className="text-danger"
                                    />
                                  </h3>
                                  <p
                                    className={`${
                                      this.state.theme === false
                                        ? "text-dark"
                                        : "text-white"
                                    }`}
                                  >
                                    Sold
                                  </p>
                                  <i className="fas fa-check-circle" />
                                </>
                              )}
                            </div>
                          </Link>
                        </Col>
                      </Row>
                      <Col sm={6} lg={6}>
                        <Link to={{ pathname: "/asset" }}>
                          <div
                            className={`stats-card danger-card mb-4 ${
                              this.state.theme === false ? "" : "bg-black5"
                            }`}
                          >
                            {this.state.soloader === true ? (
                              <span className="d-flex justify-content-center text-danger">
                                <Spinner animation="border" size="lg" />
                              </span>
                            ) : (
                              <>
                                <h3>
                                  <CountUp
                                    start={0}
                                    end={
                                      this.state.assetsdata.maintenance ===
                                      undefined
                                        ? 0
                                        : this.state.assetsdata.maintenance
                                    }
                                    duration={1.5}
                                    className="text-danger"
                                  />
                                </h3>
                                <p
                                  className={`${
                                    this.state.theme === false
                                      ? "text-dark"
                                      : "text-white"
                                  }`}
                                >
                                  Maintenance
                                </p>
                                <i className="fas fa-wrench"></i>
                              </>
                            )}
                          </div>
                        </Link>
                      </Col>
                    </Col>
                    <Col sm={6} lg={6}>
                      <div
                        className={`card mb-4 ${
                          this.state.theme === false ? "" : "bg-black5"
                        }`}
                      >
                        <div
                          className="card-body"
                          style={{
                            boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
                          }}
                        >
                          <div className="card-header">
                            <h5
                              className={`card-title ${
                                this.state.theme === false
                                  ? "text-black"
                                  : "text-white"
                              }`}
                            >
                              Assets by Item
                            </h5>
                          </div>
                          <div className="height-500">
                            <table
                              className={`table m-0 ${
                                this.state.theme === false
                                  ? "text-black"
                                  : "text-white"
                              }`}
                            >
                              <thead>
                                <tr>
                                  <th className="text-center">#</th>
                                  <th className="text-center">Name</th>
                                  <th className="text-center">Quantity</th>
                                  {/* <th className="text-center">Amount</th> */}
                                  {/* <th>Category</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.top10assetitems.map(
                                  (product, idx) => (
                                    <tr key={idx}>
                                      <td className="text-center">{idx + 1}</td>
                                      <td className="text-center">
                                        {product.itemname}
                                      </td>
                                      <td className="text-center">
                                        {product.quantity}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}

                {production === "YES" &&
                this.state.productionstats === true &&
                screens.includes("Production Dashboard") ? (
                  <Row
                    className={` ${
                      this.state.theme === false ? "" : "bg-black2"
                    }`}
                  >
                    <Col sm={12} lg={12} className="heading">
                      <h4>
                        <b
                          className={`${
                            this.state.theme === false
                              ? "textColour"
                              : "text-white"
                          }`}
                        >
                          Production
                        </b>
                      </h4>
                    </Col>
                    <Col sm={12} lg={12}>
                      <Suspense>
                        <Production theme={this.state.theme} />
                      </Suspense>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
          <Footer />
        </div>
      </Suspense>
   </> )
  }
}

export default withRouter(AdminDashboard)
