import { Field, Formik, Form } from "formik";
import React, { Component } from "react";
import { FormikTextField } from "../../CommonComponents/FormField.js";
import withRouter from "../../withRouter.js";
import { Link } from "react-router-dom";
import {
  currentTenant,
  dataaccess,
  financialyearend,
  financialyearstart,
  thousandsdisplay,
  userid,
  userwhid,
} from "../../Common/Credentials";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import SalesServices from "../SalesServices";
import { spin } from "../../Common/NewLoader";
import { Autocomplete } from "@mui/material";
import { salesid } from "../../Common/Permissions";
import UsercustomerService from "../../Masters/Usercustomers/UsercustomerService.js";
import { NumericFormat } from "react-number-format";
class SalesReceipts extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      usercustomers: [],
      invoiceid: "",
      invoicedao: "",
      invoicedata: [],
      invoicedetails: {},
      saleorderdata: [],
      saleorderid: "",
      custom: [],
      customerdetails: [],
      customer: "",
      custid: "",
      amount: "",
      currency: "0",
      modeofpayment: "0",
      chequedate: "",
      chequenumber: "",
      transactionid: "",
      paid: "",
      due: "",
      msg: 0,
      totalinvoice: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleSelect = (e) => {
    this.setState({ modeofpayment: e.target.value });
  };

  handlePay = (e) => {};

  handleCurrency = (e) => {
    this.setState({ currency: e.target.value });
  };
  handleInvoice = (e, values) => {
    if (
      values !== "" &&
      this.state.invoicedata.includes(values) &&
      values !== null
    ) {
      this.setState({ invoiceid: values });
      let id = values;
      SalesServices.GetInvDataForReceipts(id)
        .then((response) =>
          this.setState({
            invoicedao: response.data,
          })
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    }
  };

  handleCustomer = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        custid: values.id,
        customer: values.companyname,
        invoiceid: "",
      });
      const id = values.id;
      const customerdetails = this.state.custom.filter(
        (data) => data.id === id
      );
      this.setState({ customerdetails: customerdetails });
      // SalesServices.GetSOOfCustomerforReceipts(id).then(res=>
      //   this.setState({saleorderdata: res.data})
      // )
      let datadao = {
        tenantid: currentTenant,
        startdate: financialyearstart,
        enddate: financialyearend,
        userid: userid,
        dataaccess: dataaccess,
        whid: userwhid,
        id: id,
        poupload: this.state.pouploadm === "YES" ? true : false,
      };
      SalesServices.GetInvoiceidforreceipts(datadao)
        .then((response) =>
          this.setState({
            invoicedata: response.data,
          })
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    } else {
      this.setState({
        invoicedata: [],
        custid: "",
        customer: "",
        invoiceid: "",
        customerdetails: [],
      });
    }
  };

  onSubmit = (values) => {
    this.setState({
      paid: this.state.paid + parseFloat(values.amount),
      due: this.state.due - parseFloat(values.amount),
    });
    let receiptdata = {
      invid: this.state.invoicedao.invid,
      billamount: parseFloat(values.amount),
      paymentmode: parseInt(this.state.modeofpayment),
      chequedate: this.state.chequedate,
      chequenumber: this.state.chequenumber,
      transactionid: this.state.transactionid,
      currentTenant: currentTenant,
      userid: userid,
      createdby: userid,
    };
    SalesServices.GenerateReceipt(receiptdata)
      .then(() => this.props.navigate("/salesreceipts"))
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  };

  onfocus() {
    document
      .getElementById("amount")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document.getElementById("amount").addEventListener("keydown", function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    this.setState({ loading: true });
    UsercustomerService.GetUserCustomersbyUser(userid)
      .then((res) =>
        this.setState(
          {
            usercustomers:
              res.data === "" || res.data === undefined
                ? []
                : res.data.customers.split(","),
          },
          () =>
            SalesServices.retriveAllCustomers(currentTenant).then(
              (response) => {
                this.setState({
                  custom: response.data.filter((e) =>
                    this.state.usercustomers.includes(e.companyname)
                  ),
                });
                this.setState({ loading: false });
              }
            )
        )
      )
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

  validate(values) {
    let errors = {};

    if (values.invoice === null) {
      errors.invoice = "Please Select Invoice";
    }

    if (values.amount === "") {
      errors.amount = "Please Enter Amount";
    }
    if (this.state.invoicedao.due < this.state.amount) {
      errors.amount = "Amount exceeds";
    }

    if (this.state.modeofpayment === "1") {
      if (this.state.chequedate === "") {
        errors.chequedate = "Cheque Date is Required";
      }
      if (this.state.chequenumber === "") {
        errors.chequenumber = "Cheque Number is Required";
      }
    }

    if (this.state.modeofpayment === "2") {
      if (this.state.transactionid === "") {
        errors.transactionid = "Transaction Id is Required";
      }
    }
    return errors;
  }
  render() {
    let {
      custom,
      invoicedata,
      amount,
      modeofpayment,
      chequedate,
      chequenumber,
      transactionid,
      currency,
      paid,
      due,
      saleorderdata,
    } = this.state;

    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <Link
                  as="button"
                  className=" text-secondary"
                  to={{ pathname: "/salesreceipts" }}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>

              <li className="breadcrumb-item active">Sales Receipts</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Sales Receipts</h3>
                      </div>

                      <div className="card-body">
                        {this.state.loading === true ? (
                          <div className="text-center">{spin}</div>
                        ) : (
                          <Formik
                            initialValues={{
                              custom,
                              invoicedata,
                              amount,
                              modeofpayment,
                              chequedate,
                              chequenumber,
                              transactionid,
                              currency,
                              paid,
                              due,
                              saleorderdata,
                            }}
                            onSubmit={this.onSubmit}
                            validateOnChange={false}
                            validate={this.validate}
                            enableReinitialize={true}
                          >
                            {({ setFieldValue, values }) => (
                              <Form>
                                <div className="form-row">
                                  <fieldset className="form-group col-md-4">
                                    <Autocomplete
                                      id="subaccount"
                                      className="subacc-select"
                                      name="customer"
                                      options={custom}
                                      getOptionLabel={(option) =>
                                        option.companyname
                                      }
                                      value={
                                        values.customer ? undefined : undefined
                                      }
                                      onChange={this.handleCustomer}
                                      renderInput={(params) => (
                                        <FormikTextField
                                          {...params}
                                          className="form-control"
                                          type="text"
                                          name="customer"
                                          value={this.state.customer}
                                          // onBlur={(e) =>{this.handleCustomer(e.target.value,values)}}
                                          label="Select Customer"
                                          variant="standard"
                                          fullWidth
                                        />
                                      )}
                                    />
                                  </fieldset>
                                  <fieldset className="form-group col-md-4">
                                    <Autocomplete
                                      id="subaccount"
                                      className="subacc-select"
                                      name="invoice"
                                      options={invoicedata}
                                      getOptionLabel={(option) => option}
                                      value={
                                        values.Invoice ? undefined : undefined
                                      }
                                      onChange={this.handleInvoice}
                                      renderInput={(params) => (
                                        <FormikTextField
                                          {...params}
                                          className="form-control"
                                          type="text"
                                          name="invoice"
                                          value={this.state.invid}
                                          label="Select Invoice"
                                          variant="standard"
                                          fullWidth
                                        />
                                      )}
                                    />
                                  </fieldset>
                                  {this.state.saleorderid !== null &&
                                  this.state.saleorderid !== "" ? (
                                    <fieldset className="form-group col-md-4">
                                      <FormikTextField
                                        className="form-control"
                                        type="text"
                                        name="saleorder"
                                        value={this.state.saleorderid}
                                        label="Select SaleOrder"
                                        variant="standard"
                                        fullWidth
                                        disabled
                                      />
                                    </fieldset>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <fieldset className="form-group col-md-12" />
                                {this.state.customer === "" ? (
                                  ""
                                ) : (
                                  <>
                                    <div className="form row">
                                      <div className="form-group col-md-6">
                                        <table className="float-left">
                                          <tr>
                                            <u>Customer Details :</u>
                                          </tr>
                                          <tr>
                                            <td>Customer</td>
                                            <td>
                                              :&nbsp;
                                              {this.state.customerdetails.map(
                                                (data) => data.companyname
                                              )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Email</td>
                                            <td>
                                              :&nbsp;
                                              {this.state.customerdetails.map(
                                                (data) => data.email
                                              )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Address</td>
                                            <td>
                                              :&nbsp;
                                              {this.state.customerdetails.map(
                                                (data) => data.cityname
                                              )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Phone</td>
                                            <td>
                                              :&nbsp;
                                              {this.state.customerdetails.map(
                                                (data) => data.contactnumber
                                              )}
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                      {this.state.invoiceid === "" ? (
                                        ""
                                      ) : (
                                        // <div className="form col">
                                        <div className="form-group col-md-6">
                                          {/* <div className="form row" style={{ marginLeft: "8px" }}>
                                      <label style={{ width: "130px" }}>Total Amount </label>
                                      : &nbsp; {this.state.invoicedetails.map(data => data.saleorder.grandtotal)}
                                    </div> */}
                                          <table className="float-left">
                                            <tr>
                                              <u>Customer Payment Details :</u>
                                            </tr>
                                            <tr>
                                              <td>Paid</td>
                                              <td>
                                                :&nbsp;
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    this.state.invoicedao.paid
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Due</td>
                                              <td>
                                                :&nbsp;
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    this.state.invoicedao.due
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Bill Amount</td>
                                              <td>
                                                :&nbsp;
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    this.state.invoicedao
                                                      .totalinvoice
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </td>
                                            </tr>
                                          </table>
                                        </div>
                                      )}
                                    </div>

                                    <div className="form-group col-md-12" />
                                    {salesid === "3" || salesid === "2" ? (
                                      <>
                                        {this.state.invoiceid === "" ? (
                                          ""
                                        ) : (
                                          <>
                                            <div>
                                              <p className="bg-light">
                                                Payment Session
                                              </p>
                                              <>
                                                <fieldset className="form-group col-md-12 ">
                                                  <div className=" from-row">
                                                    <span>
                                                      Mode of Payment :
                                                    </span>
                                                    <div className="col-sm-12">
                                                      <div className="form-check form-check-inline">
                                                        <span className="radio-inline">
                                                          <Field
                                                            name="modeofpayment"
                                                            value="0"
                                                            id="active"
                                                            clicked={
                                                              this.state
                                                                .modeofpayment
                                                            }
                                                            onClick={
                                                              this.handleSelect
                                                            }
                                                            className="mr-2 leading-tight"
                                                            type="radio"
                                                          />
                                                          Cash
                                                        </span>
                                                      </div>

                                                      <div className="form-check form-check-inline">
                                                        <span className="radio-inline">
                                                          <Field
                                                            name="modeofpayment"
                                                            id="inactive"
                                                            value="1"
                                                            clicked={
                                                              this.state
                                                                .modeofpayment
                                                            }
                                                            onClick={
                                                              this.handleSelect
                                                            }
                                                            className=" mr-2 leading-tight "
                                                            type="radio"
                                                          />
                                                          Cheque / DD
                                                        </span>
                                                      </div>

                                                      {/* <div className="form-check form-check-inline">
                                          <span className="radio-inline">
                                            <Field
                                              name="modeofpayment"
                                              id="inactive"
                                              value="2"
                                              clicked={this.state.modeofpayment}
                                              onClick={this.handleSelect}
                                              className=" mr-2 leading-tight "
                                              type="radio"
                                            />
                                          Transaction
                                        </span>
                                        </div> */}
                                                    </div>
                                                  </div>
                                                </fieldset>
                                              </>
                                            </div>
                                            {this.state.modeofpayment ===
                                            "0" ? (
                                              ""
                                            ) : this.state.modeofpayment ===
                                              "1" ? (
                                              <div>
                                                <fieldset className="form-group col-md-3">
                                                  <FormikTextField
                                                    className="form-control"
                                                    type="text"
                                                    name="chequedate"
                                                    label="Date"
                                                    onChange={(e) =>
                                                      this.setState({
                                                        chequedate:
                                                          e.target.value,
                                                      })
                                                    }
                                                  />
                                                </fieldset>
                                                <fieldset className="form-group col-md-3">
                                                  <FormikTextField
                                                    className="form-control"
                                                    type="text"
                                                    name="chequenumber"
                                                    label="Cheque/DD Number"
                                                    onChange={(e) =>
                                                      this.setState({
                                                        chequenumber:
                                                          e.target.value,
                                                      })
                                                    }
                                                  />
                                                </fieldset>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                            {/* {this.state.msg === 0?   */}
                                            <div className="form row">
                                              <fieldset
                                                className="form-group col-md-3"
                                                style={{ paddingLeft: "13px" }}
                                              >
                                                <FormikTextField
                                                  className="form-control"
                                                  id="amount"
                                                  type="number"
                                                  name="amount"
                                                  label="Amount"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      amount: e.target.value,
                                                    })
                                                  }
                                                  onFocus={this.onocus}
                                                />
                                              </fieldset>
                                              <div className="form-group col-md-12" />
                                              {this.state.due !== 0 ? (
                                                <button
                                                  className="float-center btn btn-sm hovbuttonColour ml-2"
                                                  type="submit"
                                                >
                                                  <i className="fas fa-save mr-1" />
                                                  Save
                                                </button>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                            {/* : */}
                                            {/* <div className="form row">
                                  <fieldset className="form-group col-md-3" style={{paddingLeft:"13px"}} disabled>
                                    <FormikTextField className="form-control" type="number" name="amount" label="Amount"
                                      onChange={(e)=>{this.setState({amount:e.target.value})} } 
                                    />
                                  </fieldset>
                                  <div className="form-group col-md-12" />
                                  <button className="float-center btn btn-sm btn-info" type="onSubmit" disabled>Pay</button>
                                </div> */}
                                            {/* } */}
                                          </>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </Form>
                            )}
                          </Formik>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(SalesReceipts);
