import React, { useEffect, useState } from 'react'
import TourServices from './TourServices';

export const ViewInvoicePage = () => {
    const [selected, setSelected] = useState([]);
    const [storeticketData,setStoreTicketData]=useState('');

    useEffect(()=>{

    },[])


    const columns = [
        {
            name: "id",
            label: "Invoice Id",
            options: { filter: true, sort: true },
        },
        {
            name: "stocktype",
            label: "Stock Type",
            options: { filter: true, sort: true },
        },
        {
            name: "ticketno",
            label: "Ticketno",
            options: { filter: true, sort: true },
        },
        {
            name: "paxname",
            label: "Pax Name",
            options: { filter: true, sort: true },
        },
        {
            name: "issuedate",
            label: "Issue Date",
            options: { filter: true, sort: true },
        },
        {
            name: "profit",
            label: "Profit",
            options: { filter: true, sort: true },
        },
        {
            name: "sellingpriceinomr",
            label: "Selling price ",
            options: { filter: true, sort: true },
        },
        {
            name: "Action",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {/* <button
                      as="button"
                      data-tip
                      data-for="delete"
                      className="btn btn-sm deletebutton"
                     // to={{ state: {id: tableMeta.rowData.find(e=>e) ,pagename:"Employee" }  }}
                      onClick={()=>
                    delteDataHandler(tableMeta.rowData.find(e=>e))
                      }
                    >Delete</button> */}
                            {/* <Link as="button" className="btn btn-sm hovbuttonColour mr-2"
                      to={{ pathname: "/mastercar"}} state= {{id: tableMeta.rowData.find(e=>e) ,pagename:"Edit Master Cards" }}>Edit</Link> */}
                        </>
                    );
                },
            },
        },
    ];
    const options = {
        filterType: "checkbox",
        selectableRows: "none",
        customToolbar: () => {
            return (
                <>
                    (
                    <Link
                        as="button"
                        className="float-right btn btn-sm m-0 p-0"
                        // to={{ pathname: "/mastercar"}} 
                        state={{ id: -1, pagename: "Add Car" }}
                    >
                        <CustomToolbar />
                    </Link>
                    )
                </>
            );
        },
    };



    const getAllData=(e)=>{
            // TourServices.
    }

    return (
        <>
            <Header />
            <Menu />
            <div className="content-wrapper w-100% h-100%">
                <div className="card">
                    <ol className="breadcrumb float-sm-right">
                        <span
                            as="button"
                            className=" text-secondary cursorPointer"

                        >
                            <Link to="/masterlist" style={{ color: "grey" }}>❮ Back &nbsp;&nbsp;</Link>

                        </span>
                        <li className="breadcrumb-item text-secondary">
                            <Link to="/index">Home</Link>
                        </li>
                        <li className="breadcrumb-item text-secondary">
                            <Link to="/masterlist">View Invoice List</Link>
                        </li>
                        <li className="breadcrumb-item active">  </li>
                    </ol>
                    <div className='card'>
                        <div className='container'>
                            <h4>Ticket Invoice Details</h4>
                        </div>
                        <div className='container'>
                            <ThemeProvider theme={theme}>
                                <MUIDataTable
                                    className="table-sm px-3"
                                    //   title={"masterLists"}
                                    data={masterList}
                                    columns={columns}
                                    options={options}
                                />
                            </ThemeProvider>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}
