import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../Component/consts'

class NewReportService {

    retriveTBLedgers(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/gettbledgers/${tenantId}`)
     }

     retrivePLLedgers(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getplledgers/${tenantId}`)
     }

     getDirtaxPaid(tenantId){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getdirtaxspaid/${tenantId}`)
   }

   getInputIndirecttax(tenantId){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getinputindirecttax/${tenantId}`)
   }
     
     
}
export default new NewReportService();