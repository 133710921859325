import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials.js";
import { FormikTextField} from "../../CommonComponents/FormField.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Spinner } from "react-bootstrap";
import CategoryServices from "../../Masters//Category/CategoryServices.js";
import {NumericFormat} from "react-number-format";
import { TextField } from "@mui/material";
import PosService from "./PosService.js";
import Swal from "sweetalert2";
import InventoryService from "../../inventory/InventoryService.js";

class Posprice extends Component {
  constructor(props) {
    super(props);
    const pagename = this.props.location.state.pagename;
    const id = this.props.location.state.id;
    const data = this.props.location.state.data;

    this.state = {
      pagename: pagename,
      data: data,
      salesCal: "",
      newlist: {},
      id: id,
      itemid: "",
      itemname: "",
      itemsList: [],
      cost: "",
      margin: "",
      marginpercent: "",
      discountpercent: "",
      discount: "",
      grossprice: "",
      mtype: "",
      mp: "",
      CategoryList: [],
      catid: "",
      category: "",
      uom: "",
      uomid: "",
      filteritemlist: [],

      loader: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeMargin = (e) => {
    this.setState({ mtype: e.target.value, grossprice: 0 });

    if (e.target.value !== "") {
      if (e.target.value === "0") {
        this.setState({
          mp:
            parseFloat(this.state.cost) +
            (parseFloat(this.state.margin) / 100) * parseFloat(this.state.cost),
        });
      } else if (e.target.value === "1") {
        this.setState({
          mp: parseFloat(this.state.cost) + parseFloat(this.state.margin),
        });
      }
    }
  };
  //   handleSelect = (e) => {
  //     this.setState({ status: e.target.value });
  //     this.setState({ grossprice: 0 });
  //     this.setState({ mtype: "" });
  //     this.setState({ margin: "" });
  //     this.setState({ discount: "" });
  //     this.setState({ bankintrest: "" });
  //     this.setState({ transportation: "" });

  //     const id = this.state.itemid;
  //     const type = e.target.value;

  // //     SalesCalculationServices.costType(id, type).then((response) =>
  // //       this.setState({ cost: response.data })
  // //     )
  // //     .catch((e)=>{
  // //       if(e.response.data.error ==="Unauthorized"){
  // //           alert("Your Session is Lost: Please login Back to resume");
  // //           this.props.navigate(`/`);
  // //       }
  // //   })
  //   };

  handleChange = (value, values) => {
    if (value !== "") {
      this.setState({
        itemid: values.item.id,
        itemname: value,
        uom: values.item.uomname,
        uomid: values.item.uom,
      });
      this.setState({ cost: "" });
      this.setState({ grossprice: 0 });
      this.setState({ mtype: "" });
      this.setState({ margin: "" });
      this.setState({ discount: "" });
    }
  };
  onSubmit = (values) => {
    if (
      this.state.itemid === "" ||
      this.state.mtype === ""
    ) {
    } else {
      this.setState({
        grossprice:
          this.state.mp -
          (parseFloat(this.state.discount) / 100) * parseFloat(this.state.cost),
      });
    }
  };

  handleCategory = (value, values) => {
    if (value !== "") {
      this.setState({
        itemid: "",
        itemname: "",
        cost: "",
      });
      this.setState({
        catid: values.category.id,
        category: value,
        grossprice: 0,
        filteritemlist: this.state.itemsList.filter(
          (e) => e.catid === values.category.id
        ),
      });
    }
  };

  handleSubmit(values) {
    this.setState({ loader: true });
    let data = {
      id: this.state.id,
      itemid: parseInt(this.state.itemid),
      itemname: this.state.itemname,
      cost: parseFloat(this.state.cost),
      mtype: parseInt(this.state.mtype),
      margin: this.state.mp - parseFloat(this.state.cost),
      marginpercent: parseFloat(this.state.margin),
      discountpercent: parseFloat(this.state.discount),
      discount: (this.state.discount / 100) * parseFloat(this.state.cost),
      grossValue: this.state.grossprice,
      tenantid: currentTenant,
      categoryid: this.state.catid,
      uomid: this.state.uomid,
      createdby: userid,
    };
    if (this.state.id === -1) {
      PosService.NewPosPrices(data)
        .then(() =>
          Swal.fire({
            title: "",
            text: `Price for ${this.state.itemname} Added Successfully`,
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          }).then(() => this.props.navigate("/posprice"))
        )
        .catch((e) => {
            if(e.response!==undefined||e.response!==''){
            if (e.response.data.error === "Unauthorized") {
                Swal.fire({
                    title: "Session is Lost!",
                    text: "Please login Back to resume",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  }).then(() => this.props.navigate("/"));
            }
            this.setState({ message: e.response.data.message, loader: false });
            }
          });
    } else {
      PosService.UpdatePosPrices(data)
        .then(() =>
          Swal.fire({
            title: "",
            text: `Price for ${this.state.itemname} Updated Successfully`,
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          }).then(() => this.props.navigate("./posprice"))
        )
        .catch((e) => {
            if(e.response!==undefined||e.response!==''){
            if (e.response.data.error === "Unauthorized") {
                Swal.fire({
                    title: "Session is Lost!",
                    text: "Please login Back to resume",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  }).then(() => this.props.navigate("/"));
            }
            this.setState({ message: e.response.data.message, loader: false });
            }
          });
    }
  }
  refreshForm() {
    const id = this.props.location.state.id;
    const data = this.props.location.state.data;

    InventoryService.retriveAllItems(currentTenant)
      .then((response) =>
        this.setState({
          itemsList: response.data,
          uom:
            data === ""
              ? ""
              : response.data.find((e) => e.id === data.itemid).uomname,
        })
      )
      .catch((e) => {
        if(e.response!==undefined||e.response!==''){
        if (e.response.data.error === "Unauthorized") {
            Swal.fire({
                title: "Session is Lost!",
                text: "Please login Back to resume",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(() => this.props.navigate("/"));
        }
        }
      });

    CategoryServices.getCategory(currentTenant).then((response) =>
      this.setState({
        CategoryList: response.data.filter((e) => e.categorytype === "Product"),
        category:
          data === ""
            ? ""
            : response.data.find((e) => e.id === data.categoryid).category,
      })
    )
    .catch((e) => {
        if(e.response!==undefined||e.response!==''){
        if (e.response.data.error === "Unauthorized") {
            Swal.fire({
                title: "Session is Lost!",
                text: "Please login Back to resume",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(() => this.props.navigate("/"));
        }
        }
      });
    
    if (id !== -1) {
      this.setState({
        salesCal: data,
        catid: data.categoryid,
        itemid: data.itemid,
        itemname: data.itemname,
        cost: data.cost,
        uomid: data.uomid,
        margin: data.mtype === "Percentage" ? data.marginpercent : data.margin,
        mtype: data.mtype === "Percentage" ? "0" : "1",
        mp:
          data.mtype === 0
            ? data.cost + (parseFloat(data.marginpercent) / 100) * data.cost
            : data.cost + parseFloat(data.margin),
        discount: data.discountpercent,
      });
    }
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.refreshForm();
  }

  onpricefocus(){
    document.getElementById('price').addEventListener('mousewheel', function(event) {
      if (window.document.activeElement.type === 'number') {
        event.preventDefault()
      }
    })
    document.getElementById('price').addEventListener('keydown', function(e) {
     if (e.which === 38 || e.which === 40) {
         e.preventDefault();
     }
   });
   }
   ondiscountfocus(){
    document.getElementById('discount').addEventListener('mousewheel', function(event) {
      if (window.document.activeElement.type === 'number') {
        event.preventDefault()
      }
    })
    document.getElementById('discount').addEventListener('keydown', function(e) {
     if (e.which === 38 || e.which === 40) {
         e.preventDefault();
     }
   });
   }
   onmarginfocus(){
    document.getElementById('margin').addEventListener('mousewheel', function(event) {
      if (window.document.activeElement.type === 'number') {
        event.preventDefault()
      }
    })
    document.getElementById('margin').addEventListener('keydown', function(e) {
     if (e.which === 38 || e.which === 40) {
         e.preventDefault();
     }
   });
   }

  validate(values) {
    let errors = {};

    if (this.state.catid === "") {
      errors.category = "Please Select Category ";
    }

    if (this.state.itemid === "") {
      errors.item = "Please Select Item Name ";
    }

    if (values.margin === "") {
      errors.margin = "Please Enter Atleast 0";
    }
    if (!values.mtype || values.mtype === "") {
      errors.mtype = "Please Select Margin Type";
    }

    if (values.discount === "") {
      errors.discount = "Please Enter Atleast 0";
    }
    if (this.state.categoryid === "") {
      errors.category = "Category is required";
    }

    return errors;
  }

  render() {
    const {
      id,
      itemid,
      itemname,
      category,
      cost,
      uom,
      uomid,
      status,
      margin,
      mtype,
      discount,
      bankintrest,
      transportation,
      grossprice,
      itemsList,
      CategoryList,
      filteritemlist,
    } = this.state;
    let displaybutton;
    let displaysubmit;

    if (this.state.grossprice === "" || this.state.grossprice === 0) {
      displaybutton = (
        <button className="btn btn-sm hovbuttonColour mr-1" type="onSubmit">
          Calculate Gross
        </button>
      );
    } else {
      displaysubmit =
        this.state.loader === true ? (
          <button
            as="button"
            className="btn btn-sm hovbuttonColour mr-1"
            disabled
          >
            <Spinner animation="border" size="sm" />
            &nbsp;{"Saving"}
          </button>
        ) : (
          <span
            as="button"
            className="btn btn-sm hovbuttonColour mr-1"
            onClick={this.handleSubmit}
          >
            <i className="fa fa-save mr-2"></i>
            Save
          </span>
        );
    }

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li>
                <Link
                  as="button"
                  className="text-secondary"
                  to={{ pathname: "/posprice" }}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/posprice">Pos Price</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>
            <div className="card">
              <div className="card-body">
                <Formik
                  initialValues={{
                    id,
                    itemid,
                    itemname,
                    category,
                    cost,
                    uom,
                    uomid,
                    status,
                    margin,
                    mtype,
                    discount,
                    bankintrest,
                    transportation,
                    grossprice,
                    itemsList,
                    CategoryList,
                    filteritemlist,
                  }}
                  onSubmit={this.onSubmit}
                  validateOnChange={false}
                  validate={this.validate}
                  enableReinitialize={true}
                >
                  {({ setFieldValue, values }) => (
                    <Form autoComplete="off">
                      <div className="form-row">
                        <fieldset hidden className="form-group col-md-12">
                          <label>Id</label>
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="id"
                            placeholder="Id"
                          />
                        </fieldset>

                        {this.state.id === -1 ? (
                          <>
                            <fieldset className="form-group col-md-3">
                              <Autocomplete
                                id="subaccount"
                                className="subacc-select"
                                name="category"
                                options={CategoryList}
                                getOptionLabel={(option) => option.category}
                                
                                
                                value={values.category ? undefined : undefined}
                                onChange={(e, value) => {
                                  setFieldValue("category", value);
                                }}
                                renderInput={(params) => (
                                  <FormikTextField
                                    {...params}
                                    className="form-control"
                                    type="text"
                                    name="category"
                                    onBlur={(e) => {
                                      this.handleCategory(
                                        e.target.value,
                                        values
                                      );
                                    }}
                                    label="Select Category"
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>

                            <fieldset className="form-group col-md-3">
                              <Autocomplete
                                id="subaccount"
                                className="subacc-select"
                                name="item"
                                options={filteritemlist}
                                getOptionLabel={(option) => option.name}
                                
                                
                                value={values.item ? undefined : undefined}
                                onChange={(e, value) => {
                                  setFieldValue("item", value);
                                }}
                                renderInput={(params) => (
                                  <FormikTextField
                                    {...params}
                                    className="form-control"
                                    type="text"
                                    name="item"
                                    onBlur={(e) => {
                                      this.handleChange(e.target.value, values);
                                    }}
                                    label="Select Item"
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-3">
                              <FormikTextField
                                className="form-control"
                                name="uom"
                                label="Uom"
                                value={this.state.uom}
                                disabled
                              />
                            </fieldset>
                          </>
                        ) : (
                          <>
                            <fieldset className="form-group col-md-3">
                              <TextField
                                className="form-control"
                                type="text"
                                name="category"
                                value={this.state.category}
                                label="Category"
                                disabled
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-3">
                              <TextField
                                className="form-control"
                                type="text"
                                name="item"
                                value={this.state.itemname}
                                label="Item Name"
                                disabled
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-3">
                              <FormikTextField
                                className="form-control"
                                name="uom"
                                label="Uom"
                                value={this.state.uom}
                                disabled
                              />
                            </fieldset>
                          </>
                        )}

                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            id='price'
                            className="form-control"
                            type="number"
                            name="cost"
                            label="Price"
                            value={this.state.cost}
                            onChange={(e) =>
                                this.setState({
                                  cost: e.target.value,
                                  grossprice: 0,
                                  mtype: "" ,
                                  margin: "" ,
                                  discount: "" ,
                                })
                            }
                            onFocus={this.pricefocus}
                          />
                        </fieldset>

                        <fieldset className="form-group col-md-12" />
                        <fieldset className="form-group col-md-2">
                          <FormikTextField
                          id='margin'
                            className="form-control"
                            type="number"
                            name="margin"
                            label="Margin"
                            onChange={(e) =>
                              this.setState({
                                margin: e.target.value,
                                mtype: "",
                                grossprice: 0,
                              })
                            }
                            onFocus={this.marginfocus}
                          />
                        </fieldset>

                        <fieldset className="form-group col-md-1">
                          {/* <FormikSelectField
                            name="mtype"
                            label="Type"
                            className="form-group col-md-12"
                            onChange={this.handleChangeMargin}
                            options={[
                              { label: "%", value: "0" },
                              { label: "Amount", value: "1" },
                              // { label: "\u20A8", value: "1" },
                            ]}
                            value={this.state.mtype}
                            variant="standard"
                          /> */}

                    <FormControl variant="standard" fullwhidth style={{width:'100%'}}>
                            <InputLabel id="demo-simple-select-standard-label">Type</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              value={this.state.mtype}
                              onChange={this.handleChangeMargin}
                            >
                              <MenuItem value={"0"}>%</MenuItem>
                              <MenuItem value={"1"}>Amount</MenuItem>
                            </Select>
                          </FormControl>
                        </fieldset>
                        
                        
                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            id='discount'
                            className="form-control"
                            type="number"
                            name="discount"
                            label="Discount %"
                            onChange={(e) =>
                              this.setState({
                                discount: e.target.value===undefined || e.target.value === null || e.target.value ===''?0:e.target.value,
                                grossprice: 0,
                              })
                            }
                            onFocus={this.discountfocus}
                          />
                        </fieldset>

                        {/* <fieldset className="form-group col-md-4"/> */}

                        {/* <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="number"
                            name="bankintrest"
                            label="Bank Intrest %"
                            onChange={(e) =>
                              this.setState({ bankintrest: e.target.value,grossprice:0 })
                            }
                          />
                        </fieldset> */}

                        {/* <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="number"
                            name="transportation"
                            label="Transportation"
                            onChange={(e) =>
                              this.setState({ transportation: e.target.value,grossprice:0 })
                            }
                          />
                        </fieldset> */}

                        <div className="form-group col-md-12" />
                        <fieldset className="form-group col-md-6">
                          <span>Gross Price&nbsp;&nbsp;</span>
                          <span>
                            : &nbsp;{" "}
                            {this.state.grossprice === "" ? (
                              "0"
                            ) : (
                              <NumericFormat
                                className="pr-3"
                                displayType={"text"}
                                value={this.state.grossprice}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            )}
                          </span>
                        </fieldset>
                        <fieldset className="form-group col-md-6"></fieldset>
                      </div>
                      {displaybutton}
                      {displaysubmit}
                      <Link
                        as="button"
                        className="btn btn-sm deletebutton"
                        to={{ pathname: "/salescaltable" }}
                      >
                        <b className="text-red"> X</b> Cancel{" "}
                      </Link>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(Posprice);
