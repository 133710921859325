import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant, } from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import { FormikTextField } from '../../CommonComponents/FormField.js';

import "date-fns";

import { Autocomplete } from "@mui/material";

import InventoryService from "../../inventory/InventoryService.js";
import { Spinner } from "react-bootstrap";
import WarehouseServices from "../../Masters/Warehouse/WarehouseServices.js";
import DepartmentServices from "../../Masters/Department/DepartmentServices.js";
import CategoryServices from "../../Masters/Category/CategoryServices.js";
import UomServices from "../../Masters/Uom/UomServices.js";
import UserService from "../../users/UserService.js";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

class AddStockIssue extends Component {
  constructor(props) {
    super(props);

    const { pagename } = this.props===undefined || this.props.location.state===undefined?'':this.props.location.state;
    this.state = {
      pagename: pagename,
      id: "",
      WarehouseList:[],
      whdata:'',
      whid:'',
      warehouse:'',
      departmentList:[],
      department:'',
      deptid:'',
      userList:[],
      reciver:'',
      reciverid:'',
      CategoryList:[],
      category:'',
      catid:'',
      itemsList:[],
      itemid:'',
      itemname:'',
      filteritemlist:[],
      cost:'',
      hsn:'',
      quantity:'',
      UOMList:'',
      uom:'',
      brand:'',
      model:'',
      discription:'',
      to:'',
      loader: false,
      filteritems:[],
      selectbutton:false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.handlereciver=this.handlereciver.bind(this);
  }

  handlewarehouse = (value, values) => {
    if(values !== '' && typeof values === 'object' && values!==null){
    this.setState({ whid: values.id, warehouse: values.name })
    const id = values.id;
    DepartmentServices.deptbywarehouse(id).then((response) =>
      this.setState({
        departmentList: response.data
      })
    );
    }
  };
  handledept=(value,values)=>{
    if(values !== '' && typeof values === 'object' && values!==null){
      this.setState({
        department:values.name,
        deptid: values.id,
      })
    this.setState({userList:this.state.userdetails.filter((e)=>(e.whid===this.state.whid) && (e.deptid===values.id))})
    }
  }

  handlereciver(value,values){
    if(values !== '' && typeof values === 'object' && values!==null){
      this.setState({
        reciver:(values.first + values.last),
        reciverid: values.id,
      })
    }else{
      this.setState({
        reciver:'',
        reciverid:''
      })
    }
  }
  handleCategory = (value, values) => {
    if(values !== '' && typeof values === 'object' && values!==null){
    this.setState({
      itemid: "",
      itemname: "",
      hsn: "",
      tax: "",
      cost: "",
      quantity: "",
    });
    this.setState({
      catid: values.id,
      category: values.category,
      filteritemlist: this.state.itemsList.filter(
        (e) => e.categoryid === values.id
      ),
    });
  }else{
    this.setState({
      itemid: "",
      itemname: "",
      hsn: "",
      tax: "",
      cost: "",
      quantity: "",
      catid: '',
      category: '',
      filteritemlist:[]
    });
  }
  };

  
  handleitem = (value, values) => {
    // console.log(values)
    if(values !== '' && typeof values === 'object' && values!==null){
    this.setState({
      itemid: values.id,
      itemname: values.name,
      quantity: "",
      totalpricewtax: "",
      filteritems: this.state.whdata.filter(e=>e.whid===this.state.whid && e.itemid === values.id),
      selectbutton:true
    });
    const id = values.id;
   
    let itemdata = this.state.itemsList.filter(e=>e.id === id)
    itemdata.map(data=>
      this.setState({
        type: data.type,
        // cost: data.price === undefined || data.price === null?0:data.price,
        // stock: data.totalstock,
        hsn: data.hsn,
        tax: data.tax,
        cgst: data.tax / 2,
        sgst: data.tax / 2,
      })
    )
    // let whitems = this.state.whdata.find(e=>e.whid===this.state.whid).saleslist
    //   let quant = whitems.find(e=>e.itemid===id).whstock
    //   this.setState({avlquantity:quant})
    // // );
     }else{
      this.setState({
        itemid: '',
      itemname: '',
      quantity: "",
      totalpricewtax: "",
      filteritems: [],
      selectbutton:true,
        type:'',
        // cost: '', undefined || '', null?0:'',
        // stock: '',
        hsn: '',
        tax: '',
        cgst: '', 
        sgst: ''
      })
     }
  };

  handleSelect = (id) => {
    if (id !== "") {
      // setFilteritems(this.state.filteritems.filter((e) => e.id === id));
      let data = this.state.filteritems.find((e) => e.id === id)

      this.setState({avlquantity:this.state.filteritems.find((e) => e.id === id).currentStock,
      cost:this.state.filteritems.find((e) => e.id === id).price,selectbutton:false, uom:data.uomname,
      brand:data.brand,
      model:data.model,
      });
      // setSelectbutton(false);
      // setmanufacturedate(
      //   filteritems.find((e) => e.id === id).manufacturingdate
      // );
      // setbaseamount();
      // setSid(id);
      // setBid(id);
    }
  };

  handleUOM(value,values){
    if(value !== ""){
      this.setState({
        uom:value,
        uomid: values.department.id,
      })
    }
  }

  onSubmit(values) {
    this.setState({ loader: true });
    let addstockissue = {
      id: this.state.id,
      whid: this.state.whid,
      warehouse:this.state.warehouse,
      deptid:this.state.deptid,
      department:this.state.department,
      catid:this.state.catid,
      category:this.state.category,
      itemid:this.state.itemid,
      itemname:this.state.itemname,
      // hsn:this.state.hsn,
      cost:this.state.cost,
      totalquantity:parseFloat(this.state.quantity),
      uom:this.state.uom,
      uomid:this.state.uomid,
      description:this.state.discription,
      receivedby:this.state.reciverid,
      createdby: userid,
      tenantid: currentTenant,
    };
      InventoryService.AddStockIssue(addstockissue).then(() =>
        this.props.navigate(-1)
      )
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
  }


  onfocus(){

    document.getElementById('quantity').addEventListener('mousewheel', function(e) {
     if (window.document.activeElement.type === 'number') {
       e.preventDefault()
     }
   })
   document.getElementById('quantity').addEventListener('keydown', function(e) {
    if (e.which === 38 || e.which === 40) {
        e.preventDefault();
    }
  });
   }


  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    // const sid = this.props.location.state.id;
    WarehouseServices.getWarehouses(currentTenant).then((response) =>
      this.setState({
        WarehouseList: response.data,
      })
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
    CategoryServices.getCategory(currentTenant).then((response) =>
      this.setState({
        CategoryList: response.data.filter((e) => e.categorytype === "Product"),
      })
    );

    UomServices.getUOM(currentTenant).then((response) =>
      this.setState({
        UOMList: response.data,
      })
    );

    InventoryService.GetDataToAddStock(currentTenant).then((res) =>
      this.setState({ itemsList: res.data })
    );
    InventoryService.GetItemsforStockissue(currentTenant).then(res=>
      this.setState({whdata:res.data})
      )
    
    UserService.Userwhanddpt(currentTenant).then((res)=>{
      this.setState({userdetails:res.data,})
    })


    // if (sid < 1) {
    //   return;
    // }
  //   InventoryService.stockDataToUpdate(sid).then((response) =>
  //     this.setState({
  //       sid: sid,
  //       id: response.data.id,
  //       name: response.data.name,
  //       warehouse: response.data.waddress,
  //       stock: response.data.currentStock,
  //       cost: response.data.rate,
  //       mfdate: response.data.manufacturingdate,
  //       expdate: response.data.expirydate,
  //       cid: response.data.cid,
  //       wid: response.data.wid,
  //     })
  //   );
  }

  validate(values) {
    let errors = {};
    if (this.state.warehouse === "") {
      errors.warehouse = "Please Select Org Unit";
    }

    if (this.state.department === "") {
      errors.department = "Please Select Department";
    }
    if (this.state.reciver === "") {
      errors.reciver = "Please Select User";
    }
    if (this.state.itemname === "") {
      errors.itemname = "Please Select An Item";
    }
    if (this.state.quantity === "") {
      errors.quantity = "Please Enter Quantity";
    }else if(this.state.quantity>this.state.avlquantity){
      errors.quantity = `Quantity Exceeds (avilable:${this.state.avlquantity})`;
    }
    if (this.state.category === "") {
      errors.category = "Please Select An Category";
    }
    if (this.state.uom === "") {
      errors.uom = "Please Select An UOM";
    }
    if (this.state.discription === "") {
      errors.discription = "Please Enter Discription";
    }
    
    return errors;
  }

  render() {
    // let WarehouseList = this.state.WarehouseList;
    // let itemsList = this.state.itemsList;
    let { id,WarehouseList, warehouse, whid,departmentList,deptid,department,CategoryList,category,catid, itemsList,itemid,itemname,filteritemlist,brand,model,cost,hsn,quantity,UOMList,uom,discription,reciver,reciverid,userList
    } = this.state;

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/stockissue"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/stockissue">Stock Issue</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">{this.state.pagename}</h3>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{ id,WarehouseList, warehouse, whid,departmentList,deptid,department,CategoryList,category,catid,itemsList,itemid,itemname,filteritemlist,brand,model,
                  cost,hsn,quantity,UOMList,uom,discription,reciver,reciverid,userList
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="id"
                          placeholder="Id"
                        />
                      </fieldset>

                          <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="warehouse"
                              options={WarehouseList}
                              getOptionLabel={(option) => option.name}
                              
                              
                              value={values.warehouse ? undefined : undefined}
                              onChange={this.handlewarehouse}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="warehouse"
                                  value = {this.state.warehouse}
                                  label={`Select Org Unit (${this.state.warehouse})`}
                                  variant="standard"
                                  fullWidth
                                />
                              )}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="department"
                                    options={departmentList}
                                    getOptionLabel={(option) => option.name}
                                    
                                    getOptionSelected={(option) =>
                                      option.name
                                    }
                                    value={
                                      values.department ? undefined : undefined
                                    }
                                    onChange={this.handledept}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="department"
                                        value={this.state.department}
                                        label={`Select Department/Store (${this.state.department})`}
                                        variant="standard"
                                        fullwhidth="true"
                                      />
                                    )}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="reciver"
                                    options={userList}
                                    getOptionLabel={(option) => option.first +" "+  option.last }
                                    value={values.reciver?undefined:undefined}
                                    onChange={this.handlereciver}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="reciver"
                                        value = {this.state.reciver}
                                        label={`Select User (${this.state.reciver})`}
                                        variant="standard"
                                        fullwhidth="true"
                                      />
                                    )}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="category"
                              options={CategoryList}
                              getOptionLabel={(option) => option.category}                              
                              value={values.category ? undefined : undefined}
                              onChange={this.handleCategory}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="category"
                                  value={this.state.category}
                                  label={`Select Category (${this.state.category})`}
                                  variant="standard"
                                  fullwhidth="true"
                                />
                              )}
                            />
                          </fieldset>
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="itemname"
                                    options={filteritemlist}
                                    getOptionLabel={(option) => option.name}
                                    
                                    
                                    value={values.itemname ? undefined : undefined}
                                    onChange={this.handleitem}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="itemname"
                                        value={this.state.itemname}
                                        label={`Select Item (${this.state.itemname})`}
                                        variant="standard"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>

                                {this.state.selectbutton === true ? (
                                  this.state.filteritems.length === 0 ? (
                                    <div className="col-md-12">
                                      Sorry, no matching records found
                                    </div>
                                  ) : (
                                    <TableContainer>
                                      <Table className="table-sm">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell className="text-center">
                                              Itemname
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Price
                                            </TableCell>
                                            <TableCell className="text-center">
                                              CurrentStock
                                            </TableCell>
                                            {this.state.selectbutton === true ? (
                                              <TableCell className="text-center">
                                                Actions
                                              </TableCell>
                                            ) : (
                                              ""
                                            )}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {this.state.filteritems.map((e, index) => (
                                            e.currentStock !==0?
                                            <TableRow key={index}>
                                              <TableCell className="text-center">
                                                {e.itemname}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.price}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.currentStock}
                                              </TableCell>

                                              <TableCell className="text-center">
                                                <span
                                                  as="buttom"
                                                  className="btn btn-sm hovbuttonColour"
                                                  onClick={() =>
                                                    this.handleSelect(e.id)
                                                  }
                                                >
                                                  Select
                                                </span>
                                              </TableCell>
                                            </TableRow>
                                            :''
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  )
                                ) : (
                                  ""
                                )}
                                <fieldset className="form-group col-md-3">
                              <FormikTextField
                                className="form-control"
                                type="text"
                                name="cost"
                                value={this.state.cost === "" ? 0 : this.state.cost}
                                // onBlur={(e) => this.setState({ cost: e.target.value })}
                                label="Price"
                                variant="standard"
                                fullWidth
                                disabled
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="brand"
                              value={this.state.brand}
                              label="Brand"
                              disabled
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="model"
                              value={this.state.model}
                              label="Model"
                              disabled
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="uom"
                              value={this.state.uom}
                              label= "UOM"
                              disabled
                            />
                          </fieldset>

                  <fieldset className="form-group col-md-3">
                        <FormikTextField
                          id="quantity"
                          className="form-control"
                          type="number"
                          name="quantity"
                          label={`Quantity (Avilable:${this.state.avlquantity})`}
                          min="0"
                          onChange={(e) =>
                            this.setState({
                              quantity: e.target.value,
                              totalpricewtax: "",
                              availabelcredit: this.state.creditvalue,
                            })
                          }
                          onFocus={this.onfocus}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-12  mt-3 mb-5">
                            <FormikTextField name="discription" className="form-control" multiline rows={2} label="Description" variant="outlined"  value={this.state.discription}
                              onChange={(e) => this.setState({discription: e.target.value })}
                            />
                          </fieldset>

                         

                    </div>
                    <div className="form-group col-md-12" />
                   
                      {this.state.loader === true ? (
                         <button className="btn btn-sm hovbuttonColour" disabled>
                        <>
                          <Spinner animation="border" size="sm" />
                          &nbsp;{"Saving"}
                        </>
                        </button>
                      ) : (
                        <button className="btn btn-sm hovbuttonColour"  type="submit">
                      <> <i className="fa fa-save"/> Save</>
                    </button>
                     )}

                    <Link
                      as="button"
                      className="btn btn-sm deletebutton ml-1"
                      to={{ pathname: "/stockissue" }}
                    >
                      <b style={{ color: "#FA5733" }}> X</b>Cancel
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(AddStockIssue);
