import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import { currentTenant, userid } from '../../Common/Credentials.js';
import { spin } from '../../Common/NewLoader.jsx';
import { appmasterid } from '../../Common/Permissions.js';
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import AgentServices from './AgentServices.js';
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});


class AgentTable extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        message:'',
        agentsdata:[],
        loading:false,

    }
    // this.refreshCustomer=this.refreshCustomer.bind(this)
    this.deleteAgents=this.deleteAgents.bind(this)
  }



  deleteAgents(id){
   AgentServices.deleteAgent(id)
   .then(()=>
    AgentServices.getAgents(currentTenant)
    .then(response =>
    this.setState({agentsdata:response.data}),
     this.setState({message:"Agent Deleted Successfully"})
    )
   )
   .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})
   
}

  
refreshbrands(){
  AgentServices.getAgents(currentTenant)
    .then(
      response => {
          this.setState({agentsdata:response.data})
        this.setState({loading:false})
        }
      )  
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })  
  }

    componentDidMount () {

      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }

    //   UserService.UserPermissions(userid).then(response=>this.setState({up:response.data}))
      
      this.setState({loading:true})

      this.refreshbrands();

     }

    render() {

        const columns = [
            {
                name: "id",
                label: "Id",
                options: { filter: true, sort: true, sortOrder: "asc", display: false },
              },
              {
                name:"customagentid",
                label:"Agent Id",
                options: { filter: true, sort: true, sortOrder: "asc" },
              },
              {
                name: "name",
                label: "Agent",
                options: { filter: true, sort: true},
              },
              {
                name: "contactnumber",
                label: "Contact Number",
                options: { filter: false, sort: true,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div>+{this.state.agentsdata.find((e) => e.id === tableMeta.rowData.find((e) => e)).phonecode} {value}</div>
                    )
                  }
                },
              },
              { name: "email", label: "Email", options: { filter: false, sort: true } },
              // {name: "active",label: "Status",options: {filter: true,sort: true,sortOrder: 'asc',}},
              {
                name: "cityname",
                label: "City",
                options: {
                  filter: true,
                  sort: true,
                  // customBodyRender: (value, tableMeta, updateValue) => {
                  //   return this.state.agentsdata.find(
                  //     (e) => e.id === tableMeta.rowData.find((e) => e)
                  //   ).cities.name;
                  // },
                },
              },
              {
                name:"inuse",
                label: "Action",
                options: {
                  filter: false,
                  sort: false,
                  empty: true,
                  display: appmasterid === "2" || appmasterid === "3" ? true : false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (<>
                     <Link
                        as="button"
                        className="fa fa-edit text-info mr-1 cursorPointer"
                        to={{pathname:"/newagent"}} state={{id: tableMeta.rowData.find((e) => e)}}
                      ></Link>
                      {value==="NO"?
                       <span
                        as="button"
                        data-tip
                        data-for="delete"
                        className="fa fa-trash text-danger cursorPointer"
                        onClick={() => {
                          if (
                            window.confirm(
                              `Are you sure you wish to delete Agent ${tableMeta.rowData.find(
                                (e) => e
                              )}?`
                            )
                          )
                            this.deleteAgents(tableMeta.rowData.find((e) => e));
                        }}
                      ></span>
                      :""}
                      </>
                    );
                  },
                },
              },
            ];
        const options = {
            filterType: 'checkbox',
            selectableRows: "none",
            customToolbar: () => {
             return (<>
              {appmasterid === '2' || appmasterid === '3'?
              <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/newagent"}} state={{id:-1}}>
                <CustomToolbar />
              </Link>
                :''  
              }
             </>
             );
           }
          }
        return (
            <div>
                <Header/>
                <Menu/>
                <div className="content-wrapper">
                    <div className="card">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item text-secondary">
                                <Link to="/masters">Home</Link>
                            </li>
                            
                            <li className="breadcrumb-item active">Agents</li>
                        </ol>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                
                                            <div className="card-body">
                                                <div className="text-center">{this.state.loading === true ? spin :
                                                <ThemeProvider theme={theme}> 
                                                <MUIDataTable
                                                    className="table-sm px-3"
                                                    title={"Agents"}
                                                    data={this.state.agentsdata}
                                                    columns={columns}
                                                    options={options}
                                                />  
                                                </ThemeProvider>
                                                 }</div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>    
          
        )
       
    }
    
}
export default withRouter(AgentTable)