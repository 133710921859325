import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  currentTenant,
  userid,
} from "../../Common/Credentials";
// import moment from "moment";
import { spin } from "../../Common/NewLoader";
import { productionid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import ProductionServices from "../ProductionServices";
import CategoryServices from "../../Masters/Category/CategoryServices";
import InventoryService from "../../inventory/InventoryService";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class ProductionStore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false,
      ItemList:[],
      categoryList:[],
      filterItemList:[],
      
    };
  }

  refresh() {
    ProductionServices.getallStoreItems(currentTenant).then((response) => {
      this.setState({
        data: response.data,
        loading: false,
      });
    });
    
    CategoryServices.getCategory(currentTenant).then((response) =>
    this.setState({categoryList:response.data.filter((e) => e.categorytype === "Product")})
  );

  InventoryService.getItemNames(currentTenant).then((response) => this.setState({ItemList:response.data}))

  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.refresh();
  }

  render() {
    const columns = [
      {
        name: "name",
        label: "Store Name",
        options: { filter: true, sort: true },
      },
      {
        name: "workcenter",
        label: "WorkCenter",
        options: { filter: true, sort: true },
      },
      {
        name: "id",
        label: "Store Details",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="hovbuttonColour btn btn-sm mr-3"
                  to={{
                    pathname: "/storedetails"}}
                    state= {this.state.data.find((e) => e.id === value)}
                >
                  {" "}
                  Raw Materials
                </Link>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="hovbuttonColour btn btn-sm mr-3 "
                  to={{
                    pathname: "/finishedproductsstore"}}
                    state= {this.state.data.find((e) => e.id === value)}
                >
                  {" "}
                  Finished Products
                </Link>
              </>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {productionid === "2" || productionid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{
                  pathname: "/newstore"}}
                  state={{
                    id: -1,
                    pagename: "New Store",
                    wcid: "",
                    workcenter: "",
                    whid: "",
                    warehouse: "",
                    name:""
                }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>

              <li className="breadcrumb-item active">Store</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    {/* <div className="card"> */}
                      {/* <div className="card-header">
                        <h3 className="card-title">Store</h3>
                      </div> */}

                      <div className="card-body">
                        {this.state.loading === true ? (
                          <span className="text-center">{spin}</span>
                        ) : (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Store"}
                              data={this.state.data}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                        )}
                      </div>
                    {/* </div> */}
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(ProductionStore);
