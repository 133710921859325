 
 export let userid
 export let currentUser 
 export let currentTenant
 export let clientname 
 export let usertoken
 export let roles
 export let prefix1
 export let thousandsdisplay="lakh"
 export let currency 
 export let ccountry 
 export let taxtype
 export let clienttz
 export let ccity
 export let address
 export let address2
 export let companyemail 
 export let cstate
 export let cstatecode
 export let czip
 export let contactnumber
 export let financialyearstart
 export let financialyearend
 export let pan
 export let tan 
 export let gstin
 export let taxno
 export let registrationno
 export let plprofit
 export let plloss
 export let einvoice
 export let dataaccess
 export let userwhid
 export let logo
 export let noofusers
 export let transactionFrom
 export let selectedMenu

class Credentials {

  setFinancialYearEnd(cfinancialyearend){
    financialyearend = cfinancialyearend
  }

  setcFinancialYearStart(cfinancialyearstart){
    financialyearstart = cfinancialyearstart
  }

  setPlprofit(pplprofit){
    plprofit = pplprofit
  }
  
  setPlloss(pplloss){
    plloss = pplloss
  }
  
  setLogo(logo1){
    logo = logo1
  }

  setCity(city){
    ccity = city
  }

  setCompanyemail(email){
    companyemail=email
  }
  setState(state){
    cstate = state
  }

  setStatecode(statecode){
    cstatecode = statecode
  }
  setZip(zip){
    czip = zip
  }
  setaddress(add){
    address=add
  }
  setaddress2(add2){
    address2 = add2
  }


  setContactnumber(num){
    contactnumber = num
  }

  setcurrentuserId(id) {
    userid = id
  }

  setPrefix(prefix) {
    prefix1 = prefix
  }

  setcurrentUser(username) {
    currentUser = username
  }
  
  setcurrency(cur) {
    currency = cur
  }
   
  setccountry(country) {
    ccountry = country
  }

  setTaxtype(type) {
    taxtype = type
  }
  setcurrentTenant(tenantId) {
    currentTenant = tenantId
  }

  setuserToken(token) {
    usertoken = token
  }
    
      
  setRoles(userroles) {
    roles = userroles
    // roles = "ROLE_ADMIN"
  }

  thousandsdisplay(t) {
      thousandsdisplay = t
  }
//thousand or lakh
  setclientname(name){
    clientname = name
  }

  setclienttz(tz){
  clienttz = tz
  }

  setpan(panno){
    pan=panno
  }

  settan(tanno){
    tan=tanno
  }
  
  setgstin(gst){
    gstin = gst
  }

  settaxno(taxnum){
    taxno = taxnum
  }

  setregistrationno(regno){
    registrationno = regno
  }

  setEinvoice(einvoice1){
    einvoice = einvoice1
  }

  setDataaccess(da){
    dataaccess = da
  }

  setUserwhid(whid){
    userwhid = whid
  }

  setNoofusers(users){
    noofusers = users
  }

  setSelectedMenu(menu){
    selectedMenu = menu
  }
  setTransactionFrom(fromdate) {
    transactionFrom = fromdate;
    // transactionFrom = null;
  }
}
export default new Credentials()