import axios from "axios";
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'


class FileServices {

fileUpload(formData){
  return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/fileupload/upload`,formData)
}

getFiles(){
  return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/fileupload/files`)

}
getFiles1(name){
  return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/fileupload/getfiles/${name}`)
}


getonefile(formData){
  return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/fileupload/files1`,formData,{
    responseType: 'arraybuffer',
  })

}

}
export default new FileServices();