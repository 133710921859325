import { Autocomplete, Backdrop, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select } from "@mui/material";
import { Form, Formik } from "formik";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, userid } from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import InventoryService from "../InventoryService.js";
import CategoryServices from "../../Masters/Category/CategoryServices.js";
import Menu from "../../Component/Menu.js";
import Swal from "sweetalert2";
import { salestravels } from "../../Common/Products.js";
// import UomServices from "../Masters/Uom/UomServices.js"
// import MasterService from "../Masters/MasterService.js";
// import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

class NewServices extends Component {
  constructor(props) {
    super(props);
    const { pagename } = this.props.location.state;
    this.state = {
      loader: false,
      pagename: pagename,
      service: "",
      servicecode: "",
      servicetax: "",
      servicedescription: "",
      CategoryList: [],
      category: "",
      catid:'',
      CategoryList2: [],
      category2: "",
      catid2:'',
      mCategoryList: [],
      mcategory: "",
      mcatid:'',
      categorytype:'',
      istravelservice:false,
      type:"Other"

    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(values) {
    this.setState({loader:true})
    let servicedata = {
      // categorytype:this.state.categorytype,
      catid:this.state.catid===""||this.state.catid===undefined?0:this.state.catid,
      category:this.state.category,
      categorylvl2:this.state.category2,
      mcategory:this.state.mcategory,
      catidlvl2:this.state.catid2===""||this.state.catid2===undefined?0:this.state.catid2,
      mcatid:this.state.mcatid,
      service: this.state.service,
      servicecode: this.state.servicecode,
      servicetax: parseFloat(this.state.servicetax),
      servicedescription: this.state.servicedescription,
      createdby: userid,
      tenantid: currentTenant,
      istravelservice:this.state.istravelservice,
      type:this.state.type
    };
    InventoryService.addService(servicedata).then((response) =>
    this.setState({loader:false},()=>
      Swal.fire({
        title: `Service added successfully`,
        text: ".",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      }).then(() =>this.props.navigate({pathname:`/services`}, {state:{ message: response.data }})))
    )
    .catch((e)=>{
      this.setState({message:e.response.data.message === undefined?e.response.data:e.response.data.message,loader:false},()=>{
          if(e.response.data.error ==="Unauthorized"){
              Swal.fire({
                title: `Your Session is Lost: Please login Back to resume`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(()=>this.props.navigate('/'))
          }else{
            Swal.fire({
              title: `${e.response.data.message === undefined?e.response.data:e.response.data.message}`,
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            })
          }})
      })
  }

  handleCategory = (value, values) => {
    if(values !== '' && typeof values === 'object' && values!==null){
      let data = this.state.mCategoryList.find(e=>e.id=== values.id).categorylvltwo
      this.setState({ mcatid: values.id, mcategory: values.category, categorytype: values.categorytype,CategoryList2:data,catid2:'',category2:'',catid:'',category:'',CategoryList:[]});
    }
  };

  
  handleCategory2 = (value,values) => {
    if(values !== '' && typeof values === 'object' && values!==null){
      let data = this.state.CategoryList2.find(e=>e.id=== values.id).category
    this.setState({ catid2: values.id, category2: values.name,CategoryList:data,catid:'',category:''});
    }
    else{
      this.setState({catid2:'',category2:'',catid:'',category:'',CategoryList:[]})
    }
  };

  handleCategory3 = (value,values) => {
    if(values !== '' && typeof values === 'object' && values!==null){
    this.setState({ catid: values.id, category: values.category});
    }
    else{
      this.setState({catid:'',category:''})
    }
  };


  onfocus(){
    document.getElementById('tax').addEventListener('mousewheel', function(event) {
     if (window.document.activeElement.type === 'number') {
       event.preventDefault()
     }
   })
   document.getElementById('tax').addEventListener('keydown', function(e) {
    if (e.which === 38 || e.which === 40) {
        e.preventDefault();
    }
  });
   }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    CategoryServices.getMainCategory(currentTenant).then((response) =>
      this.setState({
        mCategoryList: response.data.filter((e) => e.categorytype === "Service"),
      })
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  validate(values) {
    let errors = {};
    if (!values.mcategory || values.mcategory === "") {
      errors.mcategory = "Category is required";
    }
    if (!values.service || values.service === "") {
      errors.service = "Service is required";
    }
    if (!values.servicecode || values.servicecode === "") {
      errors.servicecode = "Servicecode is required";
    }
    if (!values.servicetax || values.servicetax === "") {
      errors.servicetax = "Service Tax is required";
    }
    if (values.servicetax <0) {
      errors.servicetax = "Service Tax should be greater than 0";
    }

    if (!values.servicedescription || values.servicedescription === "") {
      errors.servicedescription = "Service Description is required";
    }

    if(this.state.CategoryList2.length !== 0){
      if(this.state.category2 === ""){
        errors.category2 = "Category level 2 is required";
      }
    }
  
    if(this.state.CategoryList.length !== 0){
      if(this.state.category === ""){
        errors.category = "Category level 3 is required";
      }
    }
      
    return errors;
  }

  render() {
    let {
      categorytype,
      service,
      servicecode,
      servicetax,
      servicedescription,
      category,
      catid,
      category2,
      catid2,
      mcategory,
      mcatid,
      CategoryList,
      CategoryList2,
      mCategoryList,
    } = this.state;
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
            <Link as="button" className=" text-secondary"  to={{ pathname: "/services"}} state={{producttype:true}}>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to={{ pathname: "/services", state: { producttype: true } }}>
                  Services
                </Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>
            <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={this.state.loader}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>

            <div className="card-header">
              <h3 className="card-title">{this.state.pagename}</h3>
            </div>

            <div className="card-body">
              <Formik
                initialValues={{
                  categorytype,
                  category,
                  catid,
                  category2,
                  catid2,
                  mcategory,
                  mcatid,
                  CategoryList,
                  CategoryList2,
                  mCategoryList,
                  service,
                  servicecode,
                  servicetax,
                  servicedescription,
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form autoComplete="off">
                    <div className="form-row">

                      { salestravels === "YES"?<>
                      <fieldset className="form-group col-md-12 m-0 p-0">
                      <FormGroup row>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={this.state.istravelservice}
                                          onChange={()=>this.setState({istravelservice:!this.state.istravelservice})}
                                          name="taxrequired"
                                          color="primary"
                                        />
                                      }
                                      label="Is Travel Service?"
                                    />
                                  </FormGroup>
                      </fieldset>
                      {this.state.istravelservice?
                         <fieldset className="form-group col-md-4">
                         <FormControl
                           // sx={{ m: 1, minWidth: 120 }}
                           className="col-md-12"
                         >
                           <InputLabel id="demo-simple-select-filled-label">
                             Service Type
                           </InputLabel>
                           <Select
                             value={this.state.type}
                             onChange={(e)=>this.setState({type:e.target.value})}
                             variant="standard"
                           >
                             {["Ticket","Hotel","Other"].map(
                               (option) => {
                                 return (
                                   <MenuItem
                                     key={option}
                                     value={option}
                                   >
                                     {option}
                                   </MenuItem>
                                 );
                               }
                             )}
                           </Select>
                         </FormControl>
                       </fieldset>:""}
                       </>:""
                      }
                      <fieldset className="form-group col-md-4 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="mcategory"
                          options={this.state.mCategoryList}
                          getOptionLabel={(option) => option.category}
                          
                          
                          value={values.category?undefined:undefined}
                          onChange={this.handleCategory}
                            disabled={this.state.pagename ==="Update Item"}
                          renderInput={params => (  
                            <FormikTextField
                                {...params}
                              className="form-control"
                              type="text"
                              name="mcategory"
                              value={this.state.mcategory}
                              label={`Select Category (${this.state.mcategory})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      {this.state.CategoryList2.length===0?'':
                      <fieldset className="form-group col-md-4 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="category2"
                          options={ this.state.CategoryList2}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.category2?undefined:undefined}
                          onChange={this.handleCategory2}
                            disabled={this.state.pagename ==="Update Item"}
                          renderInput={params => (  
                            <FormikTextField
                                {...params}
                              className="form-control"
                              type="text"
                              name="category2"
                              value={this.state.category2}
                              label={`Select Category Level 2 (${this.state.category2})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      }
                      {this.state.CategoryList.length===0?'':
                      <fieldset className="form-group col-md-4 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="category"
                          options={ this.state.CategoryList}
                          getOptionLabel={(option) => option.category}
                          
                          
                          value={values.category?undefined:undefined}
                          onChange={this.handleCategory3}
                            disabled={this.state.pagename ==="Update Item"}
                          renderInput={params => (  
                            <FormikTextField
                                {...params}
                              className="form-control"
                              type="text"
                              name="category"
                              value={this.state.category}
                              label={`Select Category Level 3 (${this.state.category})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      }
                       
                       <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="service"
                          label="Service Name"
                          value={this.state.service}
                          onChange={(e) =>
                            this.setState({ service: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="servicecode"
                          label="HSN/SAC Code"
                          value={this.state.servicecode}
                          onChange={(e) =>
                            this.setState({ servicecode: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          id="tax"
                          className="form-control"
                          type="number"
                          name="servicetax"
                          label="Service Tax (%)"
                          value={this.state.servicetax}
                          onChange={(e) =>
                            this.setState({ servicetax: e.target.value })
                          }
                          onFocus={this.onfocus}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="servicedescription"
                          label="Service Description"
                          value={this.state.servicedescription}
                          onChange={(e) =>
                            this.setState({
                              servicedescription: e.target.value,
                            })
                          }
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-12" />
                     
                        {this.state.loader === true ? (
                           <button className="btn btn-sm hovbuttonColour" disabled>
                            <Spinner animation="border" size="sm mr-1" />
                            {"Saving"}
                          </button>
                        ) : (
                          <button className="btn btn-sm hovbuttonColour" type="submit">
                            <i className="fa fa-save mr-1" />
                            {"Save"}
                          </button>
                        )}
                      <Link
                        as="button"
                        className="btn btn-sm deletebutton ml-1"
                        to={{pathname: "/services"}}
                          state= {{ producttype: true}}
                      >
                        <b className="text-danger mr-1"> X</b>
                        Cancel
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(NewServices);
