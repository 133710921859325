import axios from "axios";
import { Auth_Proto, Auth_Domain, Auth_Port } from "../../Component/consts";

export let currentTenant;

class PosService {

  GetPosoftenant(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getposoftenant/${currentTenant}`
    );
  }

  GetPosPrices(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getposprices/${currentTenant}`
    );
  }
  

  NewPosPrices(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/addposprice`,data
    );
  }

  UpdatePosPrices(data){
    return axios.put(
        `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/updateposprice`,data
      );
  }

  NewPosLosses(data){
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/addposlosses`,data
    );
  }
  GetPosLosses(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getposlosses/${currentTenant}`)
  }
  GetPosLossesForAcc(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getposlossesforacc/${currentTenant}`)
  }
  
}

export default new PosService();
