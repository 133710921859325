import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { FormikTextField } from '../../CommonComponents/FormField.js';
import CategoryServices from './CategoryServices.js';
import { Autocomplete } from '@mui/material';
import { Spinner } from 'react-bootstrap';

const CategoryList = [{id:0,name:"Products"},{id:1,name:"Services"}]
class NewCategory extends Component {

  
  constructor(props) {
    super(props)
    this.state = {
      pagename:this.props.location.state.pagename,
      name: '',
      typeid:'',
      type:'',
      message:'',
       loader:false,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(values) {
    this.setState({loader:true})
    let addcategory = { 
      category:this.state.name,
      categorytype:this.state.typeid,
      tenantid: currentTenant
    }
    CategoryServices.addMainCategory(addcategory).then(() => 
    this.props.navigate('/category'))
    .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
      this.setState({message:e.response.data.message,loader:false})
    })
  }

  handletype = (value,values) => {
    if(value !== '' && typeof values.type==='object' ){
    this.setState({ typeid: values.type.id, type: value,message:'' });
    }else{
      this.setState({ typeid:'', type: '',message:'' });
    }
  };  

  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
 }


  validate(values) {

    let errors = {}

     if (!values.name|| values.name==='') {
     errors.name = "Category Name is Required";
      }

      if ( this.state.typeid === '') {
        errors.type = "Category Type is Required";
         }


    return errors;
  }

  render() {
     let {name,type,typeid} = this.state;
 
    return (
      
      <div>
         <Header/>
         <Menu/>
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/category"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/category">Category</Link>
              </li>
              <li className="breadcrumb-item active">New Category</li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">New Category</h3>
            </div>
            <div className="card-body">
            
              <Formik
                initialValues={{name,type,typeid}}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}>
                {({setFieldValue,values}) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field className="form-control" type="text" name="id" placeholder="Id"/>
                      </fieldset>

                      <fieldset className="form-group col-md-6">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="type"
                          options={CategoryList}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.type?undefined:undefined}
                          onChange={(e, value) => {
                          setFieldValue("type", value);
                            }}
                          renderInput={params => (  
                            <FormikTextField
                                {...params}
                              className="form-control"
                              type="text"
                              name="type"
                              onBlur={(e) =>{this.handletype(e.target.value,values)}}
                              label="Select Category Type"
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-6">
                      <FormikTextField className="form-control"  type="text" name="name" label="Category Name" onChange={(e)=>this.setState({name:e.target.value,message:''})}/>
                      </fieldset>

                    </div>
                    <div className="form-group col-md-12 text-red">{this.state.message}</div>
                   {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled> <Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>:<button className="btn btn-sm hovbuttonColour" type="submit">  <i className="fa fa-save mr-2"/>Save</button>}
                    <Link as="button" className="btn btn-sm deletebutton ml-1" to={{pathname: '/category'}} ><b style={{color:"#FA5733"}}> X</b> Cancel</Link> 
                  
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

      <Footer/>
      </div>
    );
  }
}
export default withRouter(NewCategory)