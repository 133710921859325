import {Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { Component } from 'react'
import { NumericFormat } from 'react-number-format';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, thousandsdisplay, userid } from '../../Common/Credentials.js';
import  { spin } from '../../Common/NewLoader.jsx';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import NewJournalService from '../NewJournalService.js';
import NewReportService from '../NewReportService.js';
import './table.css';



const debTotal = (debit) => {
  let total=0 ;
  //  console.log(data)
  debit.forEach(debit => {
    total += debit;
  });
  // console.log(total)
  
  return total;
};
const creTotal = (credit) => {
  let total=0 ;
  //  console.log(data)
  credit.forEach(credit => {
    total += credit;
  });
  // console.log(total)
  return total;
};


export let debitexcess,creditexcess
class TrialBalance extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            subaccdata:[],
            combdata:[],
            loading:false


        }
    }

    componentDidMount() {
      this.setState({loading:true})

      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }

        NewReportService.retriveTBLedgers(currentTenant)
        .then(response =>{
          this.setState({subaccdata:response.data})
          const script = document.createElement("script");
          script.src= '../../js/content.js';
          script.async= true;
          document.body.appendChild(script);
          this.setState({loading:false})

      
        })
      
        NewJournalService.getAllLedgers(currentTenant)
      .then(response=>{
        this.setState({combdata:response.data})
        this.setState({loading:false})

      //  // this.setState({combdata:response.data})
       })
       .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
       


      }

    
    render() {

      let debit = this.state.subaccdata.map(e => {return ((e.rule===(0 || 1 )&& e.entrytype===0) ?(e.rule===2  && e.entrytype===1)?e.totamount:e.totamount:0)})
      let credit = this.state.subaccdata.map(e => {return ((e.rule===(0 || 1) && e.entrytype===1) ?(e.rule===2  && e.entrytype===0)?e.totamount:e.totamount:0)})

      creditexcess = creTotal(credit) > debTotal(debit)? creTotal(credit)-debTotal(debit):null
      debitexcess= debTotal(debit)> creTotal(credit)?debTotal(debit) - creTotal(credit):null
        return (
          
            <div>
              <Header />
               <Menu/>
            
            
             <div className="content-wrapper">
             <div className="card card-info">
             <div className="card-header">
              <h3 className="card-title">Ledgers</h3>
            </div>
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              
              <li className="breadcrumb-item active">Trial Balance</li>
            </ol>
            <div className="form-row">

            <div className="card-body">
              
            <div className="text-center">
            {this.state.loading===true?spin:''}
            </div>


                <Formik
            enableReinitialize={true}>
            {() => (
             
            
             <Form>

             <Table 
       id="tb"
       className="table table-sm table-borderless">
      
       <TableHead className="thead-light thead-bordered" >
         <TableRow className="tr-bordered">
         <TableCell>Account</TableCell>
         <TableCell className="text-right">Debit</TableCell>
         <TableCell className="text-right">Credit</TableCell>

         </TableRow>
       </TableHead>
       
       <TableBody>

       {
           this.state.subaccdata.map(
             resultsub => 
             <TableRow key={resultsub.id} >

             {resultsub.totamount!==null  ?
             <TableCell>
                       
              <Table className="table table-sm table-borderless">
              <TableHead className="thead-light" >
              <TableCell className="text-sm text-left text-black">{resultsub.name}</TableCell>
              <TableCell className="text-sm text-left text-black"></TableCell>
              

              </TableHead>
              
               
              <TableBody>
                
              {
              this.state.combdata.map(
                   resultcomb => 
                <TableRow key={resultcomb.id}>
               
                 {resultsub.id===resultcomb.shid?
               
               <>    
                  <TableCell className="text-sm text-left text-blue"><Link to={{pathname: "/ledgerview"}}state={resultcomb.name}>{resultcomb.name}</Link></TableCell>
                   <TableCell className="text-sm text-right text-blue">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} value={resultcomb.shid===resultsub.id?resultcomb.amount:null}></NumericFormat>}</TableCell></>:'' } 
                   
               </TableRow>
               )
               }
                <>
              <TableCell className="text-sm text-left text-black"></TableCell></> 
               
               </TableBody>
               </Table>
               </TableCell>
              

           :<TableCell></TableCell>}
  
  <TableCell className="text-sm text-right">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value={(resultsub.rule===(0||1) && resultsub.entrytype===0) ?(resultsub.rule===2  && resultsub.entrytype===1)?resultsub.totamount:resultsub.totamount:null}></NumericFormat>}</TableCell>
              <TableCell className="text-sm text-right">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value={(resultsub.rule===(0||1) && resultsub.entrytype===1) ?(resultsub.rule===2  && resultsub.entrytype===0)?resultsub.totamount:resultsub.totamount:null}></NumericFormat>}</TableCell>
  </TableRow>


 )}
  
              <TableRow >
              <TableCell className=" bg-light text-sm text-left text-black  text-bold">To Profit & Loss A/c</TableCell>
              <TableCell className="text-right text-sm ">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
               value = {creTotal(credit) > debTotal(debit)? creTotal(credit)-debTotal(debit):null} ></NumericFormat>}</TableCell>
              <TableCell className="text-right text-sm">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}  
              value = {debTotal(debit)> creTotal(credit)?debTotal(debit) - creTotal(credit):null}></NumericFormat>}</TableCell>
           

            </TableRow>
           <br></br>
             <TableRow>
              <TableCell className="text-right">Total</TableCell>
              <TableCell className="text-right text-green">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {creTotal(credit) > debTotal(debit)?debTotal(debit)+(creTotal(credit)-debTotal(debit)):debTotal(debit)}></NumericFormat>}</TableCell>
              <TableCell className="text-right text-green">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} 
              value = {debTotal(debit)> creTotal(credit)?creTotal(credit)+(debTotal(debit) - creTotal(credit)):creTotal(credit)}></NumericFormat>}</TableCell>
            </TableRow>
  

       </TableBody>    
      
                       
     </Table> 
     </Form>
            )}
     </Formik>
</div>
</div>
</div>
</div>

            <Footer/>    
            </div>
        )
    }
}

export default withRouter(TrialBalance)