import React, { Component } from 'react'
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter  from '../withRouter';
import { NumericFormat } from 'react-number-format';
import { ccountry, currentTenant, currentUser, thousandsdisplay, userid } from '../Common/Credentials';
import {Table, TableCell, TableBody, TableHead, TableRow} from '@mui/material';
import NewJournalService from './NewJournalService';
import AuthenticationService from '../Authentication/AuthenticationService';
import { Spinner } from 'react-bootstrap';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { spin } from '../Common/NewLoader';
import Footer from '../Component/Footer';
import { FormikTextField } from '../CommonComponents/FormField';
import { Autocomplete } from '@mui/material';
import Swal from 'sweetalert2';


const getTotal = (totamt) => {
  let total = 0;
  totamt.forEach(totamt => {
    total += totamt;
  });
  return total;
};


// const theme = createTheme({
//   overrides: {
//     MuiTableCell: {
//       head: {
//         backgroundColor: "#e9ecef !important",
//         border: [[1, "solid", "#ece6e6"]],
//       },
//       body: {
//         border: [[1, "solid", "#ece6e6"]],
//       },
//     },
//   },
// });
let input =[],output=[]
let outsgsttot,outcgsttot,outigsttot,outvattot
let insgsttot,incgsttot,inigsttot,invattot,jvtype=9
let val=[],payablebal=null

let gsttype=null
//totalcombdata=[]
class IndirectTaxMonthWise extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
         selmonth:this.props.location.state!==undefined?this.props.location.state.mth:"",
         monthname:this.props.location.state!==undefined?this.props.location.state.mname:"",
         input:this.props.location.state!==undefined?this.props.location.state.input:"",
         output:this.props.location.state!==undefined?this.props.location.state.output:"",
         curdate:this.props.location.state!==undefined?this.props.location.state.curdate:"",
         totalcombdata:[],
          monthsindtax:[],
         loader:false,payable:false,jvinsertiontype:15,
           // loading:false, 

          }
        this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
        // this.selectFile=this.selectFile.bind(this);this.upload=this.upload.bind(this);
        // this.CheckBoxSelect=this.CheckBoxSelect.bind(this);this.handleAdvpaid=this.handleAdvpaid.bind(this)
        // this.loadData=this.loadData.bind(this)
    }

    onSubmit(values){
      
      let jv = {
      debitid: values.debit.id,debitname: values.debit.name,debitamt: values.debitval,
      creditid: values.credit.id,creditname: values.credit.name,creditamt: payablebal,
      narration: values.narration,createdBy: userid,tenantId: currentTenant,jvtype:jvtype,
      paymentref:values.paymentref,frompath:this.props.location.pathname,
      curmonth:this.state.curdate,selgsttype:gsttype,jvinsertiontype:this.state.jvinsertiontype

      }
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      } 
      else {
          Swal.fire({
            title: "Information",
            text: "This action is irreversible, please recheck before confirming in CONFIRMATION screen ",
            icon: "info",
            confirmButtonText: "Ok",
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonColor: "#152f5f",
          }).then((result) => {
            if (result.value === true) {
              this.props.navigate({pathname:`/journalconfirm`}, {state:jv});
              }else{
              this.setState({loader:false})
             // this.goBack()
              }
            }) 
          
        //this.props.navigate({pathname:`/journalconfirm`}, {state: jv });
      }
    }

    componentDidMount(){
      // console.log(this.props.location)
      // alert(this.state.curdate)
      this.setState({loading: true })
      outsgsttot=getTotal(output.map((e)=>e.outstatetaxamount))
      outcgsttot=getTotal(output.map((e)=>e.outcentraltaxamount))
      outigsttot=getTotal(output.map((e)=>e.outintegratedtaxamount))
      outvattot=getTotal(output.map((e)=>e.outvattaxamount))

      insgsttot=getTotal(input.map((e)=>e.statetaxamount))
      incgsttot=getTotal(input.map((e)=>e.centraltaxamount))
      inigsttot=getTotal(input.map((e)=>e.integratedtaxamount))
      invattot=getTotal(input.map((e)=>e.vattaxamount))

      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }
      this.loadData()
      
      
       }

   
    loadData(){
        NewJournalService.getAllLedgers(currentTenant)
        .then(response=>{
          this.setState({totalcombdata:response.data})
          //totalcombdata=response.data
          this.setState({loading:false})
         })
         .catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
        })

  
      

      // NewJournalService.getMonthPendingIndirectDirectTaxes(currentTenant,parseInt(this.state.selmonth))
      // .then(response =>{
      //   this.setState({monthsindtax:response.data})  
      //   // console.log(response.data)
      // }) 
    //   .catch((e)=>{
    //     if(e.response.error ==="Unauthorized"){
    //         alert("Your Session is Lost: Please login Back to resume");
    //         this.props.navigate(`/`);
    //     }
    // })

    }
     validate(values){
      
     }

 

    

     submitRecord(value){
      payablebal=null
      payablebal=null
      if(value===("SGST")){
        payablebal = outsgsttot-insgsttot
        gsttype=0
        // alert("SGST VAlue" + payablebal)
      }else  if(value===("CGST")){
        payablebal = outcgsttot-incgsttot
        gsttype=1
        // alert("CGST VAlue"+ payablebal)
      }else  if(value==="IGST"){
        payablebal = outigsttot-inigsttot
        // alert("IGST VAlue" + payablebal)
        gsttype=2
      }else if(value==="VAT"){
        payablebal = outvattot-invattot
        gsttype=3
      }
      this.setState({payable:true})
      
     }
   
    render() {
      if(this.state.totalcombdata.length!==0){
        val=this.state.totalcombdata 
      }
      input=this.state.input
      output=this.state.output

     

      // input=this.state.monthsindtax.input
      // output=this.state.monthsindtax.output
      //  let pendinginput=null,pendingout=null
      //  console.log(input)
      //  console.log(output)

       
      //   pendinginput=output.filter((e)=>e.poid!==null && e.transpendingamount!==0)
      //   pendingout=input.filter((e)=>e.poid===null && e.transpendingamount!==0)
      let cred1,credvalue,debdata
     // debdata=val.filter((e)=>e.th=== "TH2")
     debdata=val.filter((e) => e.taxtype === 2)
      credvalue=val.filter((e)=>e.shid===100004 || e.shid===100003 || e.shid===100013 || e.shid===100014)




        return (
         <>
          <Header />
<Menu/>
            <div>
            <div className="content-wrapper">

<div className="card card-info">
  <ol className="breadcrumb float-sm-right">
  <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/indirtaxes"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    {/* <li className="breadcrumb-item text-secondary"> */}
    <li className="breadcrumb-item active">Month Wise</li>

    {/* <Link to={{pathname: '/indirtaxes', state: {message:''}}} >
                Indirect Taxes
              </Link> */}
    {/* </li> */}
  </ol>
  <div className="text-center">
  {this.state.loading===true?spin:''}
  </div>
  <div className="text-center text-danger">{this.state.error}</div>
  <div className="card-body">
  
              
        
    <Formik
      initialValues={{ credit:'',debit:'',debitval:'',creditvalue:'',narration:'',paymentref:''}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({ values,setFieldValue}) => (

        <Form autoComplete="off">


 {/* <div className="mb-12">




                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`Output Tax  `+" - "+"("+this.state.monthname+")"}
                            data={output}
                            columns={pendingout}
                            options={ppoptions}
                          />
                        </ThemeProvider>


              
                      </div>
<br/> */}
<div className="mb-12">
<Table className="table table-sm table-bordered">
<TableHead className="thead-light thead-bordered mt-0 mb-0" >
                            <TableRow className="tr-bordered mt-0 mb-4">
<TableCell className='text-center'><span className="text-left font-weight-bold">{`Output Tax - ( `+this.state.monthname + " )"}</span></TableCell>
<TableCell className='text-center'><span className="text-left font-weight-bold">{`Input Tax  - ( `+this.state.monthname + " )"}</span>
</TableCell>
</TableRow>
</TableHead>
<TableBody>
<TableRow>
<TableCell>
<Table className="table table-sm table-bordered">
<TableHead className="thead-light thead-bordered mt-0 mb-0" >
<TableRow className="tr-bordered mt-0 mb-4">
<TableCell className='text-center'>ITEM</TableCell>
{ccountry==="India"?<>
<TableCell className='text-center'>SGST</TableCell>
<TableCell className='text-center'>CGST</TableCell>
<TableCell className='text-center'>IGST</TableCell></>:
<TableCell className='text-center'>VAT</TableCell>}
</TableRow>
</TableHead>
<TableBody>
{output.length!==0?output.map((e)=>(
<TableRow>
<TableCell>{e.outitemname} </TableCell>
{ccountry==="India"?<>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.outstatetaxamount!==null?e.outstatetaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
 <TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.outcentraltaxamount!==null?e.outcentraltaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.outintegratedtaxamount!==null?e.outintegratedtaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell></>:
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.outvattaxamount!==null?e.outvattaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>}
</TableRow>)):""}
<TableRow>
<TableCell className='text-left font-weight-bold'>Total</TableCell>
{ccountry==="India"?<>
<TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={outsgsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
 <TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={outcgsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
 <TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={outigsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell></>:
 <TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={outvattot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>}
</TableRow>
</TableBody>
</Table>
</TableCell>
<TableCell>
<Table className="table table-sm table-bordered">
                        <TableHead className="thead-light thead-bordered mt-0 mb-0" >
                            <TableRow className="tr-bordered mt-0 mb-4">
<TableCell className='text-center'>ITEM</TableCell>
{ccountry==="India"?<>
<TableCell className='text-center'>SGST</TableCell>
<TableCell className='text-center'>CGST</TableCell>
<TableCell className='text-center'>IGST</TableCell></>:
<TableCell className='text-center'>VAT</TableCell>}
</TableRow>
</TableHead>
<TableBody>
{input.length!==0?input.map((e)=>(
<TableRow>
<TableCell>{e.itemname} </TableCell>
{ccountry==="India"?<>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.statetaxamount!==null?e.statetaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
 <TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.centraltaxamount!==null?e.centraltaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.integratedtaxamount!==null?e.integratedtaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell></>:
<TableCell className='text-right'>
<NumericFormat className="pr-3" displayType={"text"} value={e.vattaxamount!==null?e.vattaxamount:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>}
</TableRow>)):""}
<TableRow>
<TableCell className='text-left font-weight-bold'>Total</TableCell>
{ccountry==="India"?<>
<TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={insgsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
 <TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={incgsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>
 <TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={inigsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell></>:
 <TableCell className='text-right font-weight-bold'>
  <NumericFormat className="pr-3" displayType={"text"} value={invattot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
 </TableCell>}
</TableRow>
</TableBody>
</Table>
</TableCell>
</TableRow>
</TableBody>
</Table>
</div>

{/* <br/> */}
<div className="mb-12">

<span className="text-center font-weight-bold">{`Tax Liability  - ( `+this.state.monthname+ " )"}</span>
<Table className="table table-sm table-bordered">
{/* {input.length!==0?input.map((e)=>(<> */}

                        <TableHead className="thead-light thead-bordered mt-0 mb-0" >
                            <TableRow className="tr-bordered mt-0 mb-4">

<TableCell className='text-center'>TAX</TableCell>
<TableCell className='text-center'>Tax Liability</TableCell>
<TableCell className='text-center'>Action</TableCell>

</TableRow>
</TableHead>
<TableBody>
{ccountry==="India"?<>

<TableRow>


    {/* expval1.sort((a, b) => -a.localeCompare(b) */}

<TableCell className='text-left'>SGST</TableCell>
<TableCell className='text-right'>
{(outsgsttot>insgsttot)===true?
<NumericFormat className="pr-3 text-red" displayType={"text"} value={outsgsttot-inigsttot>0?outsgsttot-inigsttot:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>:
 <NumericFormat className="pr-3 text-green" displayType={"text"} value={insgsttot-outsgsttot>0?insgsttot-outsgsttot:0} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
            }
</TableCell>
<TableCell className='text-left'>
{(outsgsttot>insgsttot)===true && outsgsttot-inigsttot>0?
<span className="hovbuttonColour btn btn-sm"  onClick={() => this.submitRecord("SGST")}>pay</span>:"No Payment"}
                  {/* onClick={() => this.submitRecord(tableMeta)} */}
</TableCell>

</TableRow>
<TableRow>
<TableCell className='text-left'>CGST</TableCell>
<TableCell className='text-right'>
{(outcgsttot>incgsttot)===true?
<NumericFormat className="pr-3 text-red" displayType={"text"} value={outcgsttot-incgsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>:
 <NumericFormat className="pr-3 text-green" displayType={"text"} value={incgsttot-outcgsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
            }
</TableCell>
<TableCell className='text-left'>
{(outcgsttot>incgsttot)===true?
<span className="hovbuttonColour btn btn-sm"  onClick={() => this.submitRecord("CGST")}>pay</span>:"No Payment"}
                  {/* {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>: */}
                  {/* onClick={() => this.submitRecord(tableMeta)} */}
</TableCell>
</TableRow>

<TableRow>
<TableCell className='text-left'>IGST</TableCell>
<TableCell className='text-right'>
{(outigsttot>inigsttot)===true?
<NumericFormat className="pr-3 text-red" displayType={"text"} value={outigsttot-inigsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>:
 <NumericFormat className="pr-3 text-green" displayType={"text"} value={inigsttot-outigsttot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
            }
</TableCell>
<TableCell className='text-left'>
{(outigsttot>inigsttot)===true?
  <span className="hovbuttonColour btn btn-sm"  onClick={() => this.submitRecord("IGST")}>pay</span>:"No Payment"}
                  {/* onClick={() => this.submitRecord(tableMeta)} */}
</TableCell>
</TableRow></>:
<TableRow>
<TableCell className='text-left'>VAT</TableCell>
<TableCell className='text-right'>
{(outvattot>invattot)===true?
<NumericFormat className="pr-3 text-red" displayType={"text"} value={outvattot-invattot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>:
 <NumericFormat className="pr-3 text-green" displayType={"text"} value={invattot-outvattot} decimalScale={2} fixedDecimalScale={true}
              disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
            }
</TableCell>
<TableCell className='text-left'>
{(outvattot>invattot)===true?
  <span className="hovbuttonColour btn btn-sm"  onClick={() => this.submitRecord("VAT")}>pay</span>:"No Payment"}
                  {/* onClick={() => this.submitRecord(tableMeta)} */}
</TableCell>

</TableRow>
}
 {/* </>))} */}

</TableBody>
{/* </>)):""} */}
</Table>
</div>
{this.state.payable===true?<>
<div className="form-row" > 
<fieldset  className="form-group col-md-7 sm mt-0 mb-0"> 
  <Autocomplete id="debit" className="debit-select text-sm sm" name="adebit" options={!debdata?this.loadData():debdata.sort((a, b) => -a.subname.localeCompare(b.subname))}
  getOptionLabel={(option) => option.name }  groupBy={(option) => option.subname}
  isOptionEqualToValue={(option) => !option.value} value={values.debit?undefined:undefined} 
  onChange={(e, value) => {setFieldValue("debit",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="debit" label="Debit" className="text-sm"
  onBlur={()=>payablebal!==null?setFieldValue("debitval",payablebal):""} 
   variant="standard" fullWidth />)}/></fieldset>
     
  <fieldset  className="form-group col-md-5"> 
  <FormikTextField name="debitval"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.debitval?" ":"Value"}  type="number" 
  variant="standard"> </FormikTextField></fieldset>
 
  </div>     

<div className="form-row mt-0 mb-0"> 
<fieldset  className="form-group col-md-1 mt-0 mb-0"> </fieldset>
<fieldset  className="form-group col-md-6 mt-0 mb-4"> 
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" options={!credvalue?this.loadData():credvalue}
getOptionLabel={(option) => option.name +" ("+option.amount+")"}
isOptionEqualToValue={(option) => !option.value}
groupBy={(option) => option.th==="TH3"?option.subname:<span className='text-red'>{option.subname}</span>}
 value={values.credit?undefined:undefined} onChange={(e, value) => { setFieldValue("credit",value);}}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit"
 onBlur={()=>payablebal!==null?setFieldValue("creditvalue",payablebal):""}  variant="standard" 
 fullWidth />)}/></fieldset>
 <fieldset  className="form-group col-md-5" disabled>   
 {/* <NumericFormat  name="creditvalue" className="form-control text-sm mt-0 mb-0"  
 label={values.debitval?" ":"Value"} displayType={'input'} customInput={FormikTextField} decimalScale={2} 
 fixedDecimalScale={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> */}
 <FormikTextField  type="text" name="creditvalue" className="text-sm" label={values.debitval?" ":"Value"}
 variant="standard" 
 fullWidth />
 </fieldset>
 </div>


 <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/></fieldset></div>

  <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="narration" className="form-control" multiline rows={2} label="Narration"
  variant="outlined"/></fieldset></div>
  
  <div className="form-group col-md-12">
 <button className="hovbuttonColour btn btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button></div>

  </>:""}


 {/* <div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`Input Tax  `+" - "+"("+this.state.monthname+")"}
                            data={input}
                            columns={pendingin}
                            options={pnpoptions}
                          />
                        </ThemeProvider>
                      </div>  */}



</Form>)} 
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     
 </div>
 </>
)
}
}

export default withRouter(IndirectTaxMonthWise);              



 
