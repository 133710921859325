import React, { useEffect, useState } from "react";
import Footer from "../../../Component/Footer";
import ProjectManagementServices from "../../ProjectManagementServices";
import { currentTenant, userid } from "../../../Common/Credentials";
import Header from "../../../Component/Header";
import Menu from "../../../Component/Menu";
import withRouter from "../../../withRouter";
import { Form, Formik } from "formik";
import { FormikTextField } from "../../../CommonComponents/FormField";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import CustomerService from "../../../Masters/Customers/CustomerService";
import { Autocomplete } from "@mui/material";

const AddSite = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [siteName, setSiteName] = useState("");
  const [siteCode, setSiteCode] = useState("");
  const [projectId, setProjectId] = useState("");
  const [project, setProject] = useState("");
  const [address,setAddress] = useState("")
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [state, setState] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [city, setCity] = useState([]);
  const [cityId, setCityId] = useState([]);

  useEffect(() => {
    CustomerService.getCountries().then((response) =>
      setCountryList(response.data)
    );
    setProjectId(location.state.projectId);
    setProject(
      location.state.projectName + " (" + location.state.projectCode + ")"
    );
  }, []);

  const handleCountryChange = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      setCountry(values.name);
      setCountryId(values.id);
      setStateList(values.states);
    } else {
      setCountry("");
      setCountryId("");
      setStateList([]);
    }
  };

  const handleStateChange = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      setState(values.name);
      setStateId(values.id);
      setCitiesList(values.cities);
    } else {
      setState("");
      setStateId("");
      setCitiesList([]);
    }
  };

  const handleCityChange = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      setCity(values.name);
      setCityId(values.id);
    } else {
      setCity("");
      setCityId("");
    }
  };

  const onSubmit = () => {
    setLoader(true);
    let data = {
      projectId: projectId,
      siteName: siteName,
      siteCode: siteCode,
      address:address,
      cityId: cityId,
      city: city,
      stateId: stateId,
      state: state,
      countryId: countryId,
      country: country,
      tenantId: currentTenant,
      // remark: remark,
      createdby: userid,
      updatedby: userid,
    };
    ProjectManagementServices.AddSite(data)
      .then(() => navigate(-1))
      .catch(() => setLoader(false));
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <Link as="button" className=" text-secondary" to={-1}>
              ❮ Back &nbsp;&nbsp;
            </Link>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projectmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projects">Projects</Link>
            </li>
            <li className="breadcrumb-item active">Add Site</li>
          </ol>
          <section className="content">
            <div className="container-fluid p-3">
              <div className="row">
                <div className="col-12">
                  <div>
                    <Formik
                      initialValues={{}}
                      onSubmit={onSubmit}
                      validateOnChange={false}
                      // validate={validate}
                      enableReinitialize={true}
                    >
                      {({ setFieldValue, values }) => (
                        <Form className="row">
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="project"
                              label="Project "
                              value={project}
                              disabled
                              //   onChange={(e) => setSiteName(e.target.value)}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-12  m-0 p-0"/>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="siteName"
                              label="Site Name"
                              value={siteName}
                              onChange={(e) => setSiteName(e.target.value)}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="siteCode"
                              label="Site Code"
                              value={siteCode}
                              onChange={(e) => setSiteCode(e.target.value)}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="address"
                              label="Address"
                              value={address}
                              onChange={(e) => setAddress(e.target.value)}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="country"
                              options={countryList}
                              getOptionLabel={(option) => option.name}
                              value={values.country ? undefined : undefined}
                              onChange={handleCountryChange}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="country"
                                  value={country}
                                  label="Country"
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="state"
                              options={stateList}
                              getOptionLabel={(option) => option.name}
                              value={values.state ? undefined : undefined}
                              onChange={handleStateChange}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="state"
                                  value={state}
                                  label="State"
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="city"
                              options={citiesList}
                              getOptionLabel={(option) => option.name}
                              value={values.city ? undefined : undefined}
                              onChange={handleCityChange}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="city"
                                  value={city}
                                  label="City"
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-12  m-0 p-0"/>
                          <button
                            className="btn btn-sm hovbuttonColour mr-2"
                            disabled={loader}
                          >
                            {loader ? (
                              <Spinner
                                animation="border"
                                size="sm"
                                className="mr-1"
                              />
                            ) : (
                              ""
                            )}
                            Submit
                          </button>
                          <Link
                            as="button"
                            className="btn btn-sm deletebutton"
                            to={-1}
                          >
                            <b className="text-danger"> X </b>Cancel
                          </Link>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(AddSite);
