// import { Field, Form, Formik } from 'formik';
import { Form, Formik } from 'formik';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { currentTenant } from '../Common/Credentials.js';
import Footer from '../Component/Footer.js';
import Header from '../Component/Header.js';
import Menu from '../Component/Menu.js';
import NewVoucherService from './NewVoucherService.js';
import VoucherForms from './VoucherForms.jsx';


export let vtype;
export let vname;
export default class Vouchers extends Component {
    constructor(props) {
        super(props)
    
    
        this.state = {
            vouchertypes:[
                // {"id":1,"name":"Journal"},
                // {"id":2,"name":"Purchase"},
                // {"id":3,"name":"Sales"},
                // {"id":4,"name":"Contra"},
                // {"id":5,"name":"Contra New"},
                // {"id":6,"name":"Journal1"},
                // {"id":7,"name":"Purchase1"},
                // {"id":8,"name":"Sales1"},
               
            ]
    
        }
        // this.handleFocus=this.handleFocus.bind(this);
      }

      onChange(option){
        // console.log(option.name)
       this.setState({checked: option.id});
       //console.log(this.state.checked)

       vtype=option.id
      //  console.log(vtype)
       vname=option.name
      //  console.log(vname)
    }

    componentDidMount(){
      NewVoucherService.retriveVoucherTypes(currentTenant)
      .then(response =>{
        this.setState({vouchertypes:response.data})
      })
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    }
    render() {
        const {vouchertypes} = this.state

        return (
            <div>
              <Header />
              <Menu/>
          

          <div className="content-wrapper">
          <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title">Vouchers</h3>
              <h6 className="text-center">{vname}</h6>  

            </div>
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
              <Link to={{pathname: '/acctop'}}state= {{message:''}} >
                          Accounts
                        </Link>
              </li>
              <li className="breadcrumb-item active">Vouchers</li>

            </ol>

            <div className="text-center text-danger">{this.state.error}</div>
            <div className="card-body">


            <Formik
            initialValues={{vouchertypes}}
            enableReinitialize={true}>
            {() => (
              <Form>

            <div className="form-row">


            {vouchertypes.map((option)=>{
                   
                    return <label key={option.id} >

                        <div className="form-group col-md-12">
                          <div className="radio-item">
                          <div className="form-control bg-light border-light">

                                 <input 
                                    type="radio" 
                                    checked={this.state.checked === option.id ? true : false} 
                                    key={option.id}
                                    onChange={this.onChange.bind(this,option)} 
                                    style={{marginRight:8}}
                                    value={option.id} 
                                    />
                                {option.name}
                            
                                </div></div></div>
                            </label>
                           
                          
                })
            }
           
            
             
            </div>  
            <hr/>        
            </Form>  
            )}
</Formik>
              {/* <div className="form-row">
              <div  className="form-group col-md-8"> */}
              <VoucherForms/>
              {/* </div>
              </div> */}
              {/* </div> */}

            
          


            </div>
            
          </div>

        </div>




           <Footer/>     
            </div>
        )
    }
}
