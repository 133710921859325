import moment from "moment";
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currency,
  currentTenant,
  gstin,
  prefix1,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import { purchaseid } from "../../Common/Permissions";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import PurchaseService from "../PurchseServices";

// import download from "../../Common/upload/download";
import downloadfiles from "../../Common/upload/downloadfiles";
import { Modal, Spinner } from "react-bootstrap";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import UploadFilesService from "../../Common/upload/UploadFilesService";
import FileServices from "../../Common/upload/FileServices";

import { NumericFormat } from "react-number-format";
import SettingsServices from "../../Settings/SettingsServices";
import jsPDF from "jspdf";
import {
  address,
  address2,
  ccity,
  ccountry,
  clientname,
  companyemail,
  contactnumber,
  cstate,
  czip,
  logo,
} from "../../Common/Credentials";
import autoTable from "jspdf-autotable";
import { ToWords } from "to-words";
import Swal from "sweetalert2";
import SupplierService from "../../Masters/Suppliers/SupplierService";
import { Tooltip } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AgentServices from "../../Masters/Agents/AgentServices.js";
// import { FormikTextField } from '../CommonComponents/FormField.js';

let curmonth, year, day, path;
let doctype;
// let buffer = 0;
// let progress;
// const styles = (theme) => ({
//   table: {
//     display: "block",
//     width: "100%",
//     overflowX: "auto",
//   },
// });
// const BorderLinearProgress = styled((theme) => ({
//   root: {
//     height: 15,
//     borderRadius: 5,
//   },
//   colorPrimary: {
//     backgroundColor: "#EEEEEE",
//   },
//   bar: {
//     borderRadius: 5,
//     backgroundColor: "#1a90ff",
//   },
// }))(LinearProgress);

class ViewPurchaseorder extends Component {
  constructor(props) {
    super(props);
    const id = this.props.location.state.id;
    const seller = this.props.location.state.seller;
    const sellerid = this.props.location.state.sellerid;
    this.state = {
      Orders: "",
      purchaseitem: [],
      serviceList: [],
      id: id,
      seller: seller,
      sellerid: sellerid,
      date: "",
      status: "",
      message: "",
      loading: false,
      path: "",
      file: "",
      file1: "",
      path1: "",
      invoiceamt: "",
      show: false,
      showModal: false,
      showCancelModal: false,
      quotationfile: "",
      quotationpath: "",
      invoicefile: "",
      invoiceid: "",
      finalinvoiceid: "",
      invoicepath: "",
      finalinvoicefile: "",
      finalinvoicepath: "",
      contractfile: "",
      contractpath: "",
      milestones: [],
      cancellationdescription: "",
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      isError: false,
      fileInfos: [],
      fileInfo: "",
      invuploadm: "",
      partyid: sellerid,
      error: false, //<---- here
      errorMessage: {}, //<-----here
      templete: "",
      cloader: false,
      bankdetails: "",
      supplierdata: "",
      showadv: false,
      advance: 0,
      imgheight: 0,
      imgwidth: 0,
      mailbehavior: "NO",
      agentDetails: "",
      cnlorprecls: false,
    };
    this.handleStatus = this.handleStatus.bind(this);
    this.handledownload = this.handledownload.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
    this.handleFinalSubmit = this.handleFinalSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  downloadpdf = (data, type) => {
    let logowidth = 0;
    logowidth =
      ((this.state.imgwidth - this.state.imgheight) / this.state.imgwidth) *
      100;
    let curdate = moment().format("DD-MM-YYYY HH:mm:ss");
    let podata = data;
    let purchaseitem = [];
    let servicelist = [];
    let servicetaxamt = 0;
    let servicetotal = 0;

    if (podata.potype === 0 || podata.potype === 2) {
      purchaseitem = podata.purchaseitem;
      purchaseitem = purchaseitem.map((v) => ({
        ...v,
        cgstamt: v.taxamount / 2,
      }));
      purchaseitem = purchaseitem.map((v) => ({ ...v, cgst: v.tax / 2 }));
      purchaseitem = purchaseitem.map((v) => ({
        ...v,
        sgstamt: v.taxamount / 2,
      }));
      purchaseitem = purchaseitem.map((v) => ({ ...v, sgst: v.tax / 2 }));
    }
    if (podata.potype === 1 || podata.potype === 2) {
      servicelist = podata.servicelist;
      servicelist = servicelist.map((v) => ({ ...v, cgst: v.servicetax / 2 }));
      servicelist = servicelist.map((v) => ({ ...v, sgst: v.servicetax / 2 }));
      servicetaxamt = servicelist.reduce(
        (a, v) => (a = a + (v.servicetotalwtax - v.servicecost)),
        0
      );
      servicetotal = servicelist.reduce((a, v) => (a = a + v.servicecost), 0);
    }

    let tablecolp, tablecols;

    let total = (podata.pototalwtax - podata.totaltaxamount).toFixed(2);
    let totaltaxamount = podata.totaltaxamount.toFixed(2);
    let pototalwtax = (podata.pototalwtax + podata.tcsamount).toFixed(2);
    let advance = podata.advanceamountreq.toFixed(2);
    let tcsamount = podata.tcsamount.toFixed(2);

    if (podata.currency !== currency) {
      purchaseitem.find((e) => e).price = (
        purchaseitem.find((e) => e).price * podata.curconversionrate
      ).toFixed(2);
      purchaseitem.find((e) => e).taxamount = (
        purchaseitem.find((e) => e).taxamount * podata.curconversionrate
      ).toFixed(2);
      purchaseitem.find((e) => e).cgstamt = (
        purchaseitem.find((e) => e).cgstamt * podata.curconversionrate
      ).toFixed(2);
      purchaseitem.find((e) => e).sgstamt = (
        purchaseitem.find((e) => e).sgstamt * podata.curconversionrate
      ).toFixed(2);
      purchaseitem.find((e) => e).totalpricewtax = (
        purchaseitem.find((e) => e).totalpricewtax * podata.curconversionrate
      ).toFixed(2);

      if (podata.currency !== currency) {
        total = (total * podata.curconversionrate).toFixed(2);
        totaltaxamount = (totaltaxamount * podata.curconversionrate).toFixed(2);
        pototalwtax = (pototalwtax * podata.curconversionrate).toFixed(2);
        advance = (advance * podata.curconversionrate).toFixed(2);
      }
    }

    if (podata.taxrequired === "TRUE") {
      if (
        podata.taxtype === "GST" &&
        ccountry === "India" &&
        podata.importpo === false
      ) {
        if (podata.gsttype === "INTRASTATE") {
          tablecolp = [
            { header: "Item", dataKey: "name" },
            { header: "HSN", dataKey: "hsn" },
            { header: `Unit Price (${podata.currency})`, dataKey: "price" },
            { header: "Quantity", dataKey: "quantity" },
            { header: "CGST (%)", dataKey: "cgst" },
            { header: "SGST (%)", dataKey: "sgst" },
            { header: `CGST Amt. (${podata.currency})`, dataKey: "cgstamt" },
            { header: `SGST Amt. (${podata.currency})`, dataKey: "sgstamt" },
            { header: `Total (${podata.currency})`, dataKey: "totalpricewtax" },
          ];
          tablecols = [
            { header: "Service", dataKey: "service" },
            { header: "HSN/SAC", dataKey: "servicecode" },
            { header: "CGST (%)", dataKey: "cgst" },
            { header: "SGST (%)", dataKey: "sgst" },
            { header: "Service Cost", dataKey: "servicecost" },
            {
              header: `Total (${podata.currency})`,
              dataKey: "servicetotalwtax",
            },
          ];
        } else {
          tablecolp = [
            { header: "Item", dataKey: "name" },
            { header: "HSN", dataKey: "hsn" },
            { header: `Unit Price (${podata.currency})`, dataKey: "price" },
            { header: "Quantity", dataKey: "quantity" },
            { header: "IGST (%)", dataKey: "tax" },
            { header: `IGST Amt. (${podata.currency})`, dataKey: "taxamount" },
            { header: `Total (${podata.currency})`, dataKey: "totalpricewtax" },
          ];
          tablecols = [
            { header: "Service", dataKey: "service" },
            { header: "HSN/SAC", dataKey: "servicecode" },
            { header: "IGST (%)", dataKey: "servicetax" },
            { header: "Service Cost", dataKey: "servicecost" },
            {
              header: `Total (${podata.currency})`,
              dataKey: "servicetotalwtax",
            },
          ];
        }
      } else {
        if (podata.taxtype === "GST") {
          tablecolp = [
            { header: "Item", dataKey: "name" },
            { header: "HSN", dataKey: "hsn" },
            { header: `Unit Price (${podata.currency})`, dataKey: "price" },
            { header: "Quantity", dataKey: "quantity" },
            { header: "GST(%)", dataKey: "tax" },
            { header: `GST Amt. (${podata.currency})`, dataKey: "taxamount" },
            { header: `Total (${podata.currency})`, dataKey: "totalpricewtax" },
          ];
          tablecols = [
            { header: "Service", dataKey: "service" },
            { header: "HSN/SAC", dataKey: "servicecode" },
            { header: "GST (%)", dataKey: "servicetax" },
            { header: "Service Cost", dataKey: "servicecost" },
            {
              header: `Total (${podata.currency})`,
              dataKey: "servicetotalwtax",
            },
          ];
        } else {
          tablecolp = [
            { header: "Item", dataKey: "name" },
            { header: "HSN", dataKey: "hsn" },
            { header: `Unit Price (${podata.currency})`, dataKey: "price" },
            { header: "Quantity", dataKey: "quantity" },
            { header: "VAT(%)", dataKey: "tax" },
            { header: `VAT Amt. (${podata.currency})`, dataKey: "taxamount" },
            { header: `Total (${podata.currency})`, dataKey: "totalpricewtax" },
          ];
          tablecols = [
            { header: "Service", dataKey: "service" },
            { header: "HSN/SAC", dataKey: "servicecode" },
            { header: "VAT (%)", dataKey: "servicetax" },
            { header: "Service Cost", dataKey: "servicecost" },
            {
              header: `Total (${podata.currency})`,
              dataKey: "servicetotalwtax",
            },
          ];
        }
      }
    } else {
      tablecolp = [
        { header: "Item", dataKey: "name" },
        { header: "HSN", dataKey: "hsn" },
        { header: `Unit Price (${podata.currency})`, dataKey: "price" },
        { header: "Quantity", dataKey: "quantity" },
        { header: `Total (${podata.currency})`, dataKey: "totalpricewtax" },
      ];
      tablecols = [
        { header: "Service", dataKey: "service" },
        { header: "HSN/SAC", dataKey: "servicecode" },
        { header: `Total (${podata.currency})`, dataKey: "servicetotalwtax" },
      ];
    }

    let order = "Purchase Order";
    let date = moment(podata.date).format("DD - MM - yyyy");

    let milestones =
      podata.servicelist.length === 0
        ? []
        : podata.servicelist.find((e) => e).milestones;
    let tablecolm = [
      { header: "Name", dataKey: "name" },
      { header: "Expected Date", dataKey: "expecteddate" },
      { header: "Percentage", dataKey: "percentage" },
      { header: "Amount", dataKey: "amount" },
    ];

    //   const toWords = new ToWords();
    //   var totalinwords = toWords.convert(pototalwtax)
    //   const doc = new jsPDF('p', 'pt')
    //   doc.addImage(logo, "GIF",  40, 20, 100, 100)
    //   doc.setFontSize(16)
    //   doc.text(`${clientname}`,560,40,'right')
    //   doc.setFontSize(10)
    //   doc.text(`${address}, ${address2}`,560,55,'right')
    //   doc.text(`${ccity}, ${cstate} - ${czip}`,560,70,'right')
    //   doc.text(`${ccountry}`,560,85,'right')
    //   doc.text(`${companyemail}`,560,100,'right')
    //   doc.text(`${contactnumber}`,560,115,'right')
    //   doc.setFontSize(16)
    //   doc.text(`${order}`,200,140)
    //   doc.line(0, 150, 600, 150);
    //   doc.setFontSize(10)
    //   doc.text(`Date : ${date}`,560,180,'right')
    //   doc.text(`Order ID : ${podata.custompoid}`,560,195,'right')
    //   doc.text('To,',40,180)
    //   doc.text(`${podata.supplier}`,50,195)
    //   doc.text(`${podata.supaddress2}`,50,210)
    //   doc.text(`${podata.supcity}, ${podata.supstate}  - ${podata.supzip}`,50,225)
    //     doc.text(`${podata.supcountry}`,50,240)
    //     doc.text(`${podata.supmail}`,50,255)
    //     doc.text(`${podata.supnumber}`,50,270)

    //   // doc.text(`${tocontact}`,50,225)
    //   let context = doc.splitTextToSize(this.state.templete.content, 510);
    //   doc.text(context,40,300)
    //   if(purchaseitem.length !== 0){
    //   autoTable(doc,{
    //     theme:'grid',
    //     startY: 330,
    //     body: purchaseitem,
    //     columns:tablecolp,
    //     styles: { halign: 'center' } ,
    //     columnStyles: { 1: { halign: 'right'} },
    //   });
    //   if(servicelist.length !== 0){
    //     autoTable(doc,{
    //       theme:'grid',
    //       startY: doc.autoTable.previous.finalY + 20,
    //       body: servicelist,
    //       columns:tablecols,
    //     });
    //     }
    //   }else{
    //   if(servicelist.length !== 0){
    //   autoTable(doc,{
    //     theme:'grid',
    //     startY: 330,
    //     body: servicelist,
    //     columns:tablecols,
    //   });
    //   }
    //   if(milestones.length !== 0){
    //     autoTable(doc,{
    //       theme:'grid',
    //       startY: doc.autoTable.previous.finalY + 20,
    //       body: milestones,
    //       columns:tablecolm,

    //     });
    //     }
    //   }

    //   if(podata.taxrequired === "TRUE"){
    //     doc.text(`Total (${podata.currency}) :`,480, doc.autoTable.previous.finalY + 15,'right' )
    //     doc.text(`${total}`,550, doc.autoTable.previous.finalY + 15,'right')
    //     if(podata.taxtype === "GST" && ccountry === "India" && podata.importpo === false){
    //       if (podata.gsttype === "INTRASTATE") {
    //         doc.text(`SGST (${podata.currency}) :`, 480, doc.autoTable.previous.finalY + 30, 'right')
    //         doc.text(`${totaltaxamount / 2}`, 550, doc.autoTable.previous.finalY + 30, 'right')
    //         doc.text(`CGST (${podata.currency}) :`, 480, doc.autoTable.previous.finalY + 45, 'right')
    //         doc.text(`${totaltaxamount / 2}`, 550, doc.autoTable.previous.finalY + 45, 'right')
    //       } else {
    //         doc.text(`IGST (${podata.currency}) :`, 480, doc.autoTable.previous.finalY + 45, 'right')
    //         doc.text(`${totaltaxamount}`, 550, doc.autoTable.previous.finalY + 45, 'right')
    //       }
    //     }else{
    //       if(podata.taxtype === "GST"){
    //         doc.text(`GST (${podata.currency}) :`, 480, doc.autoTable.previous.finalY + 45, 'right')
    //         doc.text(`${totaltaxamount}`, 550, doc.autoTable.previous.finalY + 45, 'right')
    //       }else{
    //         doc.text(`VAT (${podata.currency}) :`, 480, doc.autoTable.previous.finalY + 45, 'right')
    //         doc.text(`${totaltaxamount}`, 550, doc.autoTable.previous.finalY + 45, 'right')
    //       }
    //     }
    //     }
    //     doc.text(`Grand Total (${podata.currency}) :`,480, doc.autoTable.previous.finalY + 60,'right')
    //     doc.text(`${pototalwtax}`,550, doc.autoTable.previous.finalY + 60,'right')

    //   doc.text(`Total amount in words : ${totalinwords}`,40, doc.autoTable.previous.finalY + 100)

    //   doc.text(`Payments Terms : ${podata.paymentterms} Days`,40, doc.autoTable.previous.finalY + 115)
    //   doc.text(`Advance Amount : ${advance}`,40, doc.autoTable.previous.finalY + 130)

    //   doc.text('Sd/-.',40, doc.autoTable.previous.finalY + 200)
    //   doc.text('Purchase Team,',40, doc.autoTable.previous.finalY + 215)
    //   doc.text(`${clientname}`,40, doc.autoTable.previous.finalY + 230)
    //   doc.save(`${podata.custompoid}.pdf`);
    // }

    const toWords = new ToWords();
    var totalinwords = toWords.convert(pototalwtax);
    var advanceinwords = toWords.convert(advance);
    const doc = new jsPDF("p", "pt", [842, 595], true);
    let y = 0;
    doc.setFontSize(90);
    // doc.setFont("bold", "bold");
    doc.setTextColor("#EEECEC");
    doc.text(`Purchase Order`, 50, 700, { angle: 45 });
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#000");
    doc.setFontSize(8);
    doc.setDrawColor(192, 192, 192);
    doc.setLineWidth(1.0);
    doc.line(10, 180, 585, 180);
    doc.setFontSize(8);
    doc.text(`Order ID/No`, 305, 195);
    doc.text(`-`, 370, 195);
    doc.text(`${podata.custompoid}`, 380, 195);
    doc.text(`Date`, 305, 205);
    doc.text(`-`, 370, 205);
    doc.text(`${date}`, 380, 205);
    doc.text(`Tax type`, 305, 215);
    doc.text(`-`, 370, 215);
    doc.text(`${podata.taxtype}`, 380, 215);
    if (podata.taxtype === "GST" && ccountry === "India") {
      doc.text(`Supply Type`, 305, 225);
      doc.text(`-`, 370, 225);
      doc.text(`${podata.gsttype}`, 380, 225);
    }
    if (podata.agent !== null && podata.agent !== "") {
      doc.text(`Agent`, 305, 235);
      doc.text(`-`, 370, 235);
      doc.text(`${podata.agent}`, 380, 235);
      doc.text(`Address`, 305, 245);
      doc.text(`-`, 370, 245);
      doc.text(`${this.state.agentDetails.address}`, 380, 245);
      doc.text(`${this.state.agentDetails.address2}`, 380, 255);
      doc.text(
        `${this.state.agentDetails.cityname}, ${this.state.agentDetails.statename} -${this.state.agentDetails.zip}`,
        380,
        265
      );
      // doc.text(`${this.state.agentDetails.countryname} - `, 380, 275);
      doc.text(`Phone No.`, 305, 275);
      doc.text(`-`, 370, 275);
      doc.text(`${this.state.agentDetails.contactnumber}`, 380, 275);
    }
    doc.setLineWidth(1.0);
    doc.line(300, 180, 300, 280);
    // doc.text(`Shipment ID : ${podata.dnid}`,560,220,'right')
    doc.text("CUSTOMER DETAILS,", 20, 195);
    doc.text("M/S", 20, 210);
    doc.text(`-`, 65, 210);
    doc.text(`${this.state.supplierdata.companyname}`, 80, 210);
    doc.text(`Address`, 20, 220);
    doc.text(`-`, 65, 220);
    doc.text(`${this.state.supplierdata.address}`, 80, 220);
    doc.text(`${this.state.supplierdata.address2}`, 80, 230);
    doc.text(
      `${this.state.supplierdata.cityname}, ${this.state.supplierdata.statename} `,
      80,
      240
    );
    doc.text(
      `${this.state.supplierdata.countryname} - ${this.state.supplierdata.zip}`,
      80,
      250
    );
    doc.text(`Phone No.`, 20, 260);
    doc.text(`-`, 65, 260);
    doc.text(`${this.state.supplierdata.contactnumber}`, 80, 260);
    if (
      this.state.supplierdata.gstin !== "" &&
      this.state.supplierdata.gstin !== undefined
    ) {
      doc.text(`GSTIN`, 20, 270);
      doc.text(`-`, 65, 270);
      doc.text(`${this.state.supplierdata.gstin}`, 80, 270);
    }
    doc.setLineWidth(1.0);
    doc.line(10, 280, 585, 280);
    if (podata.potype === 0 || podata.potype === 2) {
      doc.text(`Products`, 20, 290);
      autoTable(doc, {
        theme: "grid",
        startY: 300,
        margin: 20,
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        styles: { textColor: [0, 0, 0], fontSize: "8" },
        headStyles: {
          fillColor: [256, 256, 256],
          textColor: [0, 0, 0],
          fontStyle: "default",
        },
        columnStyles: {
          2: { halign: "right" },
          5: {
            halign:
              podata.taxtype === "GST" &&
              ccountry === "India" &&
              podata.gsttype === "INTRASTATE"
                ? "left"
                : "right",
          },
          6: { halign: "right" },
          7: { halign: "right" },
          8: { halign: "right" },
        },
        body: purchaseitem,
        columns: tablecolp,
      });
    }
    if (podata.potype === 2) {
      y = doc.autoTable.previous.finalY + 200;
      if (y > 841) {
        doc.addPage([595.28, 841.89]);
        y = 195;
        doc.setFontSize(150);
        doc.setFont("bold", "bold");
        doc.setTextColor("#EEECEC");
        doc.text(`Purchase Order`, 150, 700, { angle: 45 });
        doc.setFont("helvetica", "normal");
        doc.setTextColor("#000");
        doc.setFontSize(8);
      }
      doc.line(
        10,
        doc.autoTable.previous.finalY + 10,
        585,
        doc.autoTable.previous.finalY + 10
      );
      autoTable(doc, {
        theme: "grid",
        startY: doc.autoTable.previous.finalY + 20,
        margin: 20,
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        styles: { textColor: [0, 0, 0], fontSize: "8" },
        headStyles: {
          fillColor: [256, 256, 256],
          textColor: [0, 0, 0],
          fontStyle: "default",
        },
        columnStyles: {
          3: {
            halign:
              podata.taxtype === "GST" &&
              ccountry === "India" &&
              podata.gsttype === "INTRASTATE"
                ? "left"
                : "right",
          },
          4: { halign: "right" },
          5: { halign: "right" },
        },
        body: servicelist,
        columns: tablecols,
      });
    }

    if (podata.potype === 1) {
      // doc.text(`Services`, 20, 280);
      autoTable(doc, {
        theme: "grid",
        startY: 280,
        margin: 20,
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        styles: { textColor: [0, 0, 0], fontSize: "8" },
        headStyles: {
          fillColor: [256, 256, 256],
          textColor: [0, 0, 0],
          fontStyle: "default",
        },
        columnStyles: {
          3: {
            halign:
              podata.taxtype === "GST" &&
              ccountry === "India" &&
              podata.gsttype === "INTRASTATE"
                ? "left"
                : "right",
          },
          4: { halign: "right" },
          5: { halign: "right" },
        },
        body: servicelist,
        columns: tablecols,
      });
    }

    y = doc.autoTable.previous.finalY + 10;
    if (y + 180 > 841) {
      doc.addPage([595.28, 841.89]);
      y = 195;
      doc.setFontSize(150);
      doc.setFont("bold", "bold");
      doc.setTextColor("#EEECEC");
      doc.text(`Purchase Order`, 100, 700, { angle: 45 });
      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(8);
    }

    doc.setLineWidth(1.0);
    doc.line(10, y, 585, y);
    if (podata.potype === 2) {
      doc.text("Services", 480, y + 13, "right");
      doc.text(`Products`, 570, y + 13, "right");
      doc.line(10, y + 20, 585, y + 20);
      y = y + 20;
    }
    doc.line(
      350,
      y,
      350,
      podata.taxtype === "GST" &&
        ccountry === "India" &&
        podata.gsttype === "INTRASTATE"
        ? y + 180
        : y + 160
    );
    doc.text("Total in words", 150, y + 13);
    doc.text(`${totalinwords}`, 20, y + 33);
    doc.line(10, y + 40, 350, y + 40);
    doc.text(`Advance amount - ${advance} (${advanceinwords})`, 20, y + 53);
    doc.line(10, y + 60, 350, y + 60);
    doc.text("Bank details", 150, y + 73);
    doc.line(10, y + 80, 350, y + 80);
    doc.text("Bank Name", 20, y + 93);
    doc.text("-", 75, y + 93);
    doc.text(
      `${
        this.state.bankdetails?.bankname === undefined
          ? ""
          : this.state.bankdetails?.bankname
      }`,
      85,
      y + 93
    );
    doc.text("Bank Acc No.", 20, y + 113);
    doc.text("-", 75, y + 113);
    doc.text(
      `${
        this.state.bankdetails?.accno === undefined
          ? ""
          : this.state.bankdetails?.accno
      }`,
      85,
      y + 113
    );
    doc.text("Branch Name", 20, y + 133);
    doc.text("-", 75, y + 133);
    doc.text(
      `${
        this.state.bankdetails?.branchname === undefined
          ? ""
          : this.state.bankdetails?.branchname
      }`,
      85,
      y + 133
    );
    doc.text("IFSC", 20, y + 153);
    doc.text("-", 75, y + 153);
    doc.text(
      `${
        this.state.bankdetails?.ifsc === undefined
          ? ""
          : this.state.bankdetails?.ifsc
      }`,
      85,
      y + 153
    );
    doc.line(
      10,
      podata.taxtype === "GST" &&
        ccountry === "India" &&
        podata.gsttype === "INTRASTATE"
        ? y + 180
        : y + 160,
      350,
      podata.taxtype === "GST" &&
        ccountry === "India" &&
        podata.gsttype === "INTRASTATE"
        ? y + 180
        : y + 160
    );

    doc.text("Taxable Amount", 360, y + 13);
    // if (podata.potype === 2 || podata.potype === 1) {
    //   doc.text(
    //     `${servicetotal.toFixed(2)}`,
    //     podata.potype === 2 ? 480 : 570,
    //     y + 13,
    //     "right"
    //   );
    // }
    if (podata.potype === 2 || podata.potype === 0) {
      doc.text(`${total}`, 570, y + 13, "right");
    }
    doc.line(10, y + 20, 585, y + 20);
    if (
      podata.taxtype === "GST" &&
      ccountry === "India" &&
      podata.gsttype === "INTRASTATE"
    ) {
      doc.text("CGST", 360, y + 33);
      if (podata.potype === 2 || podata.potype === 1) {
        doc.text(
          `${(servicetaxamt / 2).toFixed(2)}`,
          podata.potype === 2 ? 480 : 570,
          y + 33,
          "right"
        );
      }
      if (podata.potype === 2 || podata.potype === 0) {
        doc.text(`${(totaltaxamount / 2).toFixed(2)}`, 570, y + 33, "right");
      }
      doc.line(350, y + 40, 585, y + 40);
      doc.text("SGST", 360, y + 53);
      if (podata.potype === 2 || podata.potype === 1) {
        doc.text(
          `${(servicetaxamt / 2).toFixed(2)}`,
          podata.potype === 2 ? 480 : 570,
          y + 53,
          "right"
        );
      }
      if (podata.potype === 2 || podata.potype === 0) {
        doc.text(`${(totaltaxamount / 2).toFixed(2)}`, 570, y + 53, "right");
      }
      y = y + 20;
    } else {
      doc.text(
        podata.taxtype === "GST" && ccountry === "India"
          ? "IGST"
          : podata.taxtype === "GST"
          ? "GST"
          : "Vat",
        360,
        y + 33
      );
      // if (podata.potype === 2 || podata.potype === 1) {
      //   doc.text(
      //     `${servicetaxamt.toFixed(2)}`,
      //     podata.potype === 2 ? 480 : 570,
      //     y + 33,
      //     "right"
      //   );
      // }
      if (podata.potype === 2 || podata.potype === 0) {
        doc.text(`${totaltaxamount}`, 570, y + 33, "right");
      }
    }
    doc.line(350, y + 40, 585, y + 40);
    doc.text("Total Amount", 360, y + 53);
    if (podata.potype === 2 || podata.potype === 1) {
      doc.text(
        `${podata.grandservicetotal.toFixed(2)}`,
        podata.potype === 2 ? 480 : 570,
        y + 53,
        "right"
      );
    }
    if (podata.potype === 2 || podata.potype === 0) {
      doc.text(`${podata.grandproducttotal.toFixed(2)}`, 570, y + 53, "right");
    }

    if (tcsamount !== 0 && tcsamount !== 0.0) {
      doc.line(350, y + 60, 585, y + 60);
      doc.text("TCS", 360, y + 71);
      doc.text(`${tcsamount}`, 570, y + 71, "right");
      y = y + 15;
    }
    doc.line(350, y + 60, 585, y + 60);
    doc.text("Total", 360, y + 73);
    doc.text(`${pototalwtax}`, 570, y + 73, "right");
    doc.line(350, y + 80, 585, y + 80);
    doc.setFontSize(8);
    doc.text(
      "certified that particulars given above are true and correct",
      370,
      y + 88
    );
    doc.setFontSize(8);
    doc.text(`For ${clientname}`, 400, y + 100);
    doc.line(350, y + 105, 585, y + 105);
    doc.line(350, y + 145, 585, y + 145);
    doc.text("Authorised Signatory", 425, y + 155);
    doc.line(10, y + 160, 585, y + 160);
    y = y + 160;
    if (
      this.state.templete.terms !== undefined &&
      this.state.templete.terms !== null &&
      this.state.templete.terms.length > 0
    ) {
      if (y + 50 > 841) {
        doc.addPage([595.28, 841.89]);
        y = 195;
        doc.setFontSize(150);
        doc.setFont("bold", "bold");
        doc.setTextColor("#EEECEC");
        doc.text(`Purchase Order`, 100, 700, { angle: 45 });
        doc.setFont("helvetica", "normal");
        doc.setTextColor("#000");
        doc.setFontSize(8);
      }
      doc.line(10, y, 585, y);
      doc.text("Terms and Conditions", 275, y + 15);
      doc.line(10, y + 20, 585, y + 20);
      var splitTitle = doc.splitTextToSize(this.state.templete.terms, 500);
      doc.text(20, y + 40, splitTitle);
    }

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;
      doc.line(10, 50, 585, 50);
      doc.line(10, pageHeight - 40, 585, pageHeight - 40);
      doc.line(10, 50, 10, pageHeight - 40);
      doc.line(pageWidth - 10, 50, pageWidth - 10, pageHeight - 40);
      doc.setFontSize(8);
      doc.text(`${curdate}`, 20, 60);
      doc.addImage(
        logo,
        "GIF",
        pageWidth - 100 - logowidth,
        60,
        80 + logowidth,
        80
      );
      doc.setFontSize(12);
      doc.text(`${clientname}`, 20, 80);
      doc.setFontSize(8);
      doc.text(`${address}`, 20, 90);
      doc.text(`${address2}`, 20, 100);
      doc.text(`${ccity}, ${cstate}`, 20, 110);
      doc.text(`${ccountry} - ${czip}`, 20, 120);
      doc.text(`${companyemail}`, 20, 130);
      doc.text(`${contactnumber}`, 20, 140);
      doc.setLineWidth(1.0);
      doc.line(10, 150, 585, 150);
      doc.setFontSize(10);
      doc.text(`GSTIN | ${gstin}`, 20, 167);
      doc.setFontSize(12);
      doc.text(`PURCHASE ORDER`, 270, 167);
      doc.setLineWidth(1.0);
      doc.line(10, 175, 585, 175);
      doc.setFontSize(8);
      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 50,
        { baseline: "bottom" }
      );
    }

    if (type === "pdf") {
      doc.autoPrint({ variant: "non-conform" });
      doc.save(`${podata.custompoid}.pdf`);
    } else {
      const blob = doc.output("blob");
      const file = new File([blob], `${podata.custompoid}.pdf`);

      // var fileURL = URL.createObjectURL(blob);
      // window.open(fileURL);
      let formData = new FormData();

      formData.append("file", file);
      formData.append("id", this.state.id);

      PurchaseService.SendPOmail(formData)
        .then((response) =>
          this.setState({ cloader: false }, () =>
            Swal.fire({
              title: `Mail Sent Successfully`,
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            })
          )
        )
        .catch((e) =>
          this.setState(
            { message: e.response.data.message, cloader: false },
            () => {
              if (e.response.data.error === "Unauthorized") {
                Swal.fire({
                  title: `Your Session is Lost: Please login Back to resume`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                }).then(() => this.props.navigate("/"));
              } else {
                Swal.fire({
                  title: `Server Error, please try again later`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                });
              }
            }
          )
        );
    }
  };

  handleShowModal = (uploadtype) => {
    doctype = uploadtype;
    this.setState({ showModal: true });
  };
  handleModalClose = () => {
    this.setState({ showModal: false });
  };

  handleShow = () => {
    doctype = "pomail";
    this.setState({ show: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  handleCancelModalShow = (val) => {
    this.setState({ showCancelModal: true, cnlorprecls: val });
  };
  handleCancelModalClose = () => {
    this.setState({ showCancelModal: false });
  };

  handleCancel = () => {
    this.setState({ cloader: true });
    let statusdata = {
      id: this.state.id,
      postatus: this.state.Orders.grnstatus === "PartiallyGRNgenerated" ? 3 : 2,
      canceldesc: this.state.cancellationdescription,
      updatedby: userid,
    };
    PurchaseService.statusData(statusdata)
      .then(() => {
        this.props.navigate("/purchaseorder");
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  };
  // handlePOinvoive = () => {
  //   // download()
  //   downloadfiles.download(this.state.path, this.state.file);
  // };

  handledownload = (file) => {
    if (this.state.quotationfile === file) {
      downloadfiles.download(
        this.state.quotationpath,
        this.state.quotationfile
      );
    } else if (this.state.invoicefile === file) {
      downloadfiles.download(this.state.invoicepath, this.state.invoicefile);
    } else if (this.state.finalinvoicefile === file) {
      downloadfiles.download(
        this.state.finalinvoicepath,
        this.state.finalinvoicefile
      );
    } else if (this.state.contractfile === file) {
      downloadfiles.download(this.state.contractpath, this.state.contractfile);
    }
  };

  handleDeleteProforma = (id) => {
    if (window.confirm("Are you sure you want to delete proforma invoice")) {
      PurchaseService.DeleteProformaInvoice(id)
        .then(() =>
          this.setState({
            file: "",
            path: "",
          })
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    }
  };

  selectFile = (event) => {
    this.setState({
      selectedFiles: event.target.files,
    });
  };

  upload() {
    let currentFile = this.state.selectedFiles[0];

    this.setState({ progress: 100, currentFile: currentFile });

    UploadFilesService.upload(
      currentFile,
      year,
      curmonth,
      doctype,
      this.state.partyid,
      day,
      (event) => {
        this.setState({
          progress: Math.round((100 * event.loaded) / event.total),
        });
        // progress = Math.round((100 * event.loaded) / event.total);
      }
    )
      .then((response) => {
        this.setState({
          message: response.data.message,
          isError: false,
        });
        return FileServices.getFiles1(currentFile.name);
      })
      .then((files) => {
        this.setState({ fileInfos: files.data });
      })

      .catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
          isError: true,
        });
      });

    this.setState({ selectedFiles: undefined });
  }

  handleFinalSubmit = () => {
    if (this.state.fileInfos.length === 0) {
      this.setState({ message: "Please Upload Delivery note" });
      alert("Please Upload Delivery note");
    } else {
      let filedata = this.state.fileInfos.map((e) => e.name);
      path =
        prefix1 +
        "/" +
        doctype +
        "/" +
        this.state.partyid +
        "/" +
        year +
        "/" +
        curmonth +
        "/" +
        day;

      let uploaddata = {
        file: filedata.toLocaleString(),
        path: path,
        poid: this.state.id,
        porformainvoiceid:
          doctype === "performainvoice" ? this.state.invoiceid : "",
        finalinvoiceid: doctype === "finalinvoice" ? this.state.invoiceid : "",
        amount: parseInt(this.state.invoiceamt),
      };
      if (path !== "") {
        if (doctype === "performainvoice") {
          PurchaseService.UploadInvoicepdf(uploaddata)
            .then((res) =>
              this.setState(
                {
                  invoicefile: res.data.file,
                  invoicepath: res.data.path,
                  selectedFiles: undefined,
                  currentFile: undefined,
                  progress: 0,
                  message: "",
                  isError: false,
                  fileInfos: [],
                  fileInfo: "",
                  partyid: this.state.sellerid,
                  error: false,
                  errorMessage: {},
                },
                () => this.handleModalClose()
              )
            )
            .catch((e) => {
              if (e.response.data.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
        } else if (doctype === "finalinvoice") {
          PurchaseService.UploadFinalInvoice(uploaddata)
            .then((res) =>
              this.setState(
                {
                  finalinvoicefile: res.data.file,
                  finalinvoicepath: res.data.path,
                  selectedFiles: undefined,
                  currentFile: undefined,
                  progress: 0,
                  message: "",
                  isError: false,
                  fileInfos: [],
                  fileInfo: "",
                  partyid: this.state.sellerid,
                  error: false,
                  errorMessage: {},
                },
                () => this.handleModalClose()
              )
            )
            .catch((e) => {
              if (e.response.data.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
        } else if (doctype === "contractfile") {
          PurchaseService.UploadContractFile(uploaddata)
            .then((res) =>
              this.setState(
                {
                  contractfile: filedata.toLocaleString(),
                  contractpath: path,
                  selectedFiles: undefined,
                  currentFile: undefined,
                  progress: 0,
                  message: "",
                  isError: false,
                  fileInfos: [],
                  fileInfo: "",
                  partyid: this.state.sellerid,
                  error: false,
                  errorMessage: {},
                },
                () => this.handleModalClose()
              )
            )
            .catch((e) => {
              if (e.response.data.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
        }
      }
    }
  };

  handleStatus = (status) => {
    let statusdata = {
      id: this.state.id,
      canceldesc: this.state.cancellationdescription,
      postatus: status,
      updatedby: userid,
    };

    Swal.fire({
      title: `${
        status === 1
          ? "Confirm"
          : status === 2
          ? "Cancel"
          : status === 3
          ? "Preclose"
          : "Delete"
      } Purchase Order?`,
      text: `Are you sure you want to ${
        status === 1
          ? "confirm"
          : status === 2
          ? "cancel"
          : status === 3
          ? "preclose"
          : "delete"
      }?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({ cloader: false });
        PurchaseService.statusData(statusdata)
          .then((response) => {
            this.setState({ status: status, cloader: false });
            Swal.fire({
              title: `Purchase order ${
                status === 1
                  ? "confirmed"
                  : status === 2
                  ? "cancelled"
                  : status === 3
                  ? "preclosed"
                  : "deleted"
              } successfully`,
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => {
              if (status === 1 && this.state.mailbehavior === "YES") {
                this.downloadpdf(this.state.Orders, "mail");
              }
              if (status === 1) this.refresh();
              else this.props.navigate("/purchaseorder");
            });
          })
          .catch((e) => {
            this.setState({ cloader: false });
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });

    // this.setState({ status: status }, () => {
    //   let statusdata = {
    //     id: this.state.id,
    //     postatus: status,
    //     updatedby: userid
    //   };
    //   PurchaseService.statusData(statusdata).then(()=>status === 1 ?'':this.props.navigate("/purchaseorder"))
    //   .catch((e)=>{
    //     if(e.response.data.error ==="Unauthorized"){
    //         alert("Your Session is Lost: Please login Back to resume");
    //         this.props.navigate(`/`);
    //     }
    // })
    // });
  };

  handleSendMail = () => {
    if (this.state.fileInfos.length === 0) {
      alert("Please Upload File");
    } else {
      let filedata = this.state.fileInfos.map((e) => e.name);
      path =
        prefix1 +
        "/" +
        doctype +
        "/" +
        this.state.partyid +
        "/" +
        year +
        "/" +
        curmonth +
        "/" +
        day;
      let data = {
        file: filedata.toLocaleString(),
        path: path,
        poid: this.state.id,
      };
      PurchaseService.UploadPo(data)
        .then(() =>
          this.setState({
            show: false,
            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: "",
            isError: false,
            fileInfos: [],
            fileInfo: "",
            partyid: "",
            error: false,
          })
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    }
  };

  handleAddAdvance = () => {
    if (this.state.advance > 0) {
      let data = {
        advance: this.state.advance,
        tenantid: currentTenant,
        id: this.state.id,
        updatedby: userid,
      };

      PurchaseService.AddAdvance(data)
        .then(() => this.refresh())
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    }
  };

  refresh() {
    const id = this.props.location.state.id;
    PurchaseService.GetPurchases(id)
      .then((response) => {
        this.setState({ Orders: response.data }, () =>
          this.setState({
            purchaseitem: this.state.Orders.purchaseitem,
            status: this.state.Orders.postatusint,
            invoicepath: this.state.Orders.invoicepath,
            invoicefile: this.state.Orders.invoicefile,
            serviceList: this.state.Orders.servicelist,
            invoiceamt: this.state.Orders.pototalwtax,
            quotationfile: this.state.Orders.quotationfile,
            quotationpath: this.state.Orders.quotationpath,
            finalinvoicefile: this.state.Orders.finalinvoicefile,
            finalinvoicepath: this.state.Orders.finalinvoicepath,
            contractfile: this.state.Orders.servicelist
              .map((e) => e.contractfile)
              .toLocaleString(),
            contractpath: this.state.Orders.servicelist
              .map((e) => e.contractpath)
              .toLocaleString(),
            milestones:
              this.state.Orders.servicelist.length !== 0
                ? this.state.Orders.servicelist.find((e) => e).milestones
                : this.state.milestones,
            showadv: false,
            advance: 0,
          })
        );
        SupplierService.retriveSupplier(response.data.supplierid).then((res) =>
          this.setState({ supplierdata: res.data })
        );
        if (response.data.agentid !== null && response.data.agentid !== "") {
          AgentServices.getAgentById(response.data.agentid).then((res) =>
            this.setState({ agentDetails: res.data })
          );
        }
        this.setState({ loading: false });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    let data = "Purchase";
    SettingsServices.GetActiveTemplate(data, currentTenant).then((response) =>
      this.setState({ templete: response.data })
    );

    SettingsServices.GetSettings(currentTenant).then((response) =>
      this.setState({
        invuploadm: response.data.invuploadm,
        mailbehavior: response.data.mailbehavior,
      })
    );
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    // UserService.UserPermissions(userid).then(response=>this.setState({UserPermissions:response.data}))
    let i = new Image();
    i.src = logo;

    setTimeout(() => {
      this.setState({ imgwidth: i.naturalWidth, imgheight: i.naturalHeight });
    }, 100);

    this.refresh();
    year = new Date().getFullYear();
    day = new Date().getDate();
    let mn = new Date().getMonth() + 1;
    curmonth = moment(mn, "M").format("MMMM");
  }

  onfocus() {
    document
      .getElementById("amount")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document.getElementById("amount").addEventListener("keydown", function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }

  render() {
    let { selectedFiles, currentFile, progress, message, isError } = this.state;
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb">
              <span
                className="text-secondary mr-2 cursorpointer"
                onClick={() => this.props.navigate(-1)}
              >
                ❮ Back
              </span>

              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchaseorder">Purchase Order</Link>
              </li>
              <li className="breadcrumb-item active">Purchase Order Details</li>
            </ol>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={this.state.cloader}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>

            {this.state.loading === true ? (
              <div className="text-center">{spin}</div>
            ) : (
              <>
                <div className="mb-2">
                  {this.state.payment === "Completed" ||
                  this.state.Orders.postatus === "Preclosed" ? (
                    ""
                  ) : (
                    <>
                      {this.state.status === 2 ? (
                        ""
                      ) : (
                        <div className=" mt-2 pl-3 ">
                          <span className="mr-3"> Process Status :</span>
                          <span
                            className="text-green"
                            style={{ borderBottom: "1px solid #28a745" }}
                          >
                            {"Purchase Order"}&nbsp; (
                            <i className="fa fa-check text-green text-sm" />
                            )&nbsp;<i className="fas fa-long-arrow-alt-right mx-1"></i>
                          </span>
                          {this.state.status === 1 ? (
                            <span
                              className="text-green"
                              style={{ borderBottom: "1px solid #28a745" }}
                            >
                              {"Confirm Order"}&nbsp; (
                              <i className="fa fa-check text-green text-sm" />
                              )&nbsp;<i className="fas fa-long-arrow-alt-right mx-1"></i>
                            </span>
                          ) : (
                            <span
                              className="text-red"
                              style={{ borderBottom: "1px solid red" }}
                              onClick={() => this.handleStatus(1)}
                            >
                              {"Confirm Order (P)"}&nbsp;<i className="fas fa-long-arrow-alt-right mx-1"></i>&nbsp;
                            </span>
                          )}

                          {this.state.status === 0 ||
                          this.state.Orders.advancepaymentreq === "False" ? (
                            ""
                          ) : this.state.invoicefile === null ||
                            this.state.invoicefile === "" ? (
                            <span
                              className="text-red"
                              style={{ borderBottom: "1px solid red" }}
                            >
                              {"Proforma Invoice (P)"}&nbsp;<i className="fas fa-long-arrow-alt-right mx-1"></i>&nbsp;
                            </span>
                          ) : (
                            <span
                              className="text-green"
                              style={{ borderBottom: "1px solid #28a745" }}
                            >
                              {"Proforma Invoice"}&nbsp; (
                              <i className="fa fa-check text-green text-sm" />
                              )&nbsp;<i className="fas fa-long-arrow-alt-right mx-1"></i>
                            </span>
                          )}

                          {/* {this.state.invuploadm==="YES"?<>
                {this.state.invoicefile === null || this.state.invoicefile === ""?<span className="text-red" style={{borderBottom: '1px solid red'}}>{'Upload Invoice (P)'}&nbsp;<i className="fas fa-long-arrow-alt-right mx-1"></i>&nbsp;</span>:<span className="text-green" style={{borderBottom: '1px solid #28a745'}}>{'Upload Invoice'}&nbsp; (<i className="fa fa-check text-green text-sm"/>)&nbsp;<i className="fas fa-long-arrow-alt-right mx-1"></i></span>}
                </>:''} */}

                          {this.state.Orders.potype === 1 ? (
                            <>
                              {this.state.Orders.grnstatus ===
                              "GRNgenerated" ? (
                                <span
                                  className="text-green"
                                  style={{ borderBottom: "1px solid #28a745" }}
                                >
                                  {"SCN"}&nbsp; (
                                  <i className="fa fa-check text-green text-sm" />
                                  )&nbsp;
                                </span>
                              ) : (
                                <span style={{ borderBottom: "1px solid red" }}>
                                  {" "}
                                  {this.state.invoicefile === null ||
                                  this.state.invoicefile === "" ? (
                                    <span className="text-red">SCN (P)</span>
                                  ) : (
                                    <Link
                                      as="button"
                                      className="text-red"
                                      to="/newscn"
                                    >
                                      {"SCN (P)"}
                                    </Link>
                                  )}
                                </span>
                              )}
                            </>
                          ) : (
                            <>
                              {this.state.Orders.grnstatus ===
                              "GRNgenerated" ? (
                                <span
                                  className="text-green"
                                  style={{ borderBottom: "1px solid #28a745" }}
                                >
                                  {"GRN"}&nbsp; (
                                  <i className="fa fa-check text-green text-sm" />
                                  )&nbsp;
                                </span>
                              ) : this.state.Orders.grnstatus ===
                                "PartiallyGRNgenerated" ? (
                                <span
                                  className="text-yellow"
                                  style={{ borderBottom: "1px solid yellow" }}
                                >
                                  {"GRN"}&nbsp; (
                                  <i className="fa fa-check text-yellow text-sm" />
                                  )&nbsp;
                                </span>
                              ) : (
                                <span style={{ borderBottom: "1px solid red" }}>
                                  {" "}
                                  {(this.state.invoicefile === null ||
                                    this.state.invoicefile === "") &&
                                  this.state.Orders.advancepaymentreq ===
                                    "True" ? (
                                    <span className="text-red">GRN (P)</span>
                                  ) : (
                                    <Link
                                      as="button"
                                      className="text-red"
                                      to="/newgrn"
                                      state={{
                                        id: -1,
                                        pagename: "Accepted Goods",
                                        orderid: this.state.Orders.custompoid,
                                        supplier:this.state.Orders.supplierid
                                      }}
                                    >
                                      {"GRN (P)"}
                                    </Link>
                                  )}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  {/* <Link className="float-right btn btn-sm hovbuttonColour mr-6" to={{ pathname: '/pdf', state: { data: this.state.Orders}}}>Print</Link> */}
                </div>
                <section className="content">
                  <div className="container-fluid">
                    <div className="row g-0">
                      <div className="col-12">
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">
                              Purchase Orders Details
                            </h3>
                            {this.state.status === 0 ||
                            this.state.status === 2 ||
                            this.state.Orders.postatus === "Preclosed" ? (
                              ""
                            ) : (
                              <>
                                <span
                                  as="button"
                                  className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-envelope text-md ml-1"
                                  onClick={() =>
                                    this.downloadpdf(this.state.Orders, "mail")
                                  }
                                ></span>
                                <span
                                  as="button"
                                  className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md"
                                  onClick={() =>
                                    this.downloadpdf(this.state.Orders, "pdf")
                                  }
                                ></span>
                              </>
                            )}
                          </div>
                          <div className="card-body">
                            <div>
                              <div className="form-row g-0 mb-3">
                                <div className="form-group col-md-4">
                                  <table className="float-left">
                                    <tbody>
                                      <tr>
                                        <td>Order ID</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          {this.state.Orders.custompoid}
                                        </td>
                                      </tr>
                                      {this.state.Orders.indentid === "" ||
                                      this.state.Orders.indentid ===
                                        undefined ? (
                                        ""
                                      ) : (
                                        <tr>
                                          <td>Indent ID</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            {this.state.Orders.indentid}
                                          </td>
                                        </tr>
                                      )}
                                      {/* <tr>
                              <td>Supplier ID</td>
                              <td>:&nbsp;&nbsp;{this.state.Orders.customsupid}</td>
                            </tr> */}
                                      <tr>
                                        <td>Supplier</td>
                                        <td data-tip data-for="supphno">
                                          :&nbsp;&nbsp;
                                          {this.state.Orders.supplier}
                                        </td>
                                        {/* <Tooltip id="supphno" place="top" effect="solid">Supplier Ph. {this.state.Orders.supnumber}</Tooltip> */}
                                      </tr>
                                      {this.state.Orders.agent === null ||
                                      this.state.Orders.agent === undefined ||
                                      this.state.Orders.agent === "" ? (
                                        ""
                                      ) : (
                                        <tr>
                                          <td>Agent</td>
                                          <td data-tip data-for="agentphno">
                                            :&nbsp;&nbsp;
                                            {this.state.Orders.agent}
                                          </td>
                                          {/* <Tooltip id="agentphno" place="top" effect="solid">Agent Ph. {this.state.Orders.agentphno}</Tooltip> */}
                                        </tr>
                                      )}
                                      <tr>
                                        <td>Created By</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          {this.state.Orders.createdbyname}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Date</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          {moment(
                                            this.state.Orders.createdAt
                                          ).format("DD-MM-yyyy")}
                                        </td>
                                      </tr>
                                      {this.state.Orders.tcsreq === 1 &&
                                      this.state.Orders.tcs !== 0 ? (
                                        <tr>
                                          <td>TCS</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            {`${this.state.Orders.tcsamount} (${this.state.Orders.tcs}%) of ${this.state.Orders.tcsableamount}`}
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.Orders.currency ===
                                      currency ? (
                                        ""
                                      ) : (
                                        <>
                                          <tr>
                                            <td>Currency</td>
                                            <td>
                                              :&nbsp;{" "}
                                              {this.state.Orders.currency}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Currency Conversion Rate</td>
                                            <td>
                                              :&nbsp;{" "}
                                              {
                                                this.state.Orders
                                                  .curconversionrate
                                              }
                                            </td>
                                          </tr>
                                        </>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="form-group col-md-4">
                                  <table className="float-left">
                                    <tbody>
                                      {/* {this.state.Orders.poadjustedamount !==
                                        undefined &&
                                      this.state.Orders.poadjustedamount !==
                                        null &&
                                      this.state.Orders.poadjustedamount !==
                                        0 ? (
                                        <>
                                          <tr>
                                            <td>PO amount&nbsp;&nbsp;</td>
                                            <td>
                                              :&nbsp;&nbsp;
                                              <NumericFormat
                                                className="pr-3"
                                                displayType={"text"}
                                                value={
                                                  this.state.Orders
                                                    .pototalwtax -
                                                  this.state.Orders
                                                    .poadjustedamount
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              PO adjusted amount&nbsp;&nbsp;
                                            </td>
                                            <td>
                                              :&nbsp;&nbsp;
                                              <NumericFormat
                                                className="pr-3"
                                                displayType={"text"}
                                                value={
                                                  this.state.Orders
                                                    .poadjustedamount
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </td>
                                          </tr>
                                        </>
                                      ) : (
                                        ""
                                      )} */}
                                      <tr>
                                        <td>Total amount&nbsp;&nbsp;</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          <NumericFormat
                                            className="pr-3"
                                            displayType={"text"}
                                            value={
                                              this.state.Orders.pototalwtax
                                            }
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </td>
                                      </tr>

                                      {this.state.Orders.openadjusted !==
                                        null &&
                                      this.state.Orders.openadjusted !==
                                        undefined ? (
                                        <tr>
                                          <td>
                                            Opening balance adjusted &nbsp;
                                          </td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            <NumericFormat
                                              className="pr-3"
                                              displayType={"text"}
                                              value={
                                                this.state.Orders.openadjusted
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}

                                      {this.state.Orders.creditadjusted !==
                                        null &&
                                      this.state.Orders.creditadjusted !==
                                        undefined ? (
                                        <tr>
                                          <td>Credit Note Adjusted &nbsp;</td>
                                          <td>
                                            :&nbsp;&nbsp;
                                            <NumericFormat
                                              className="pr-3"
                                              displayType={"text"}
                                              value={
                                                this.state.Orders.creditadjusted
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}

                                      <tr>
                                        <td>Paid</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          <NumericFormat
                                            className="pr-3 text-success"
                                            displayType={"text"}
                                            value={this.state.Orders.paid}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Balance</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          <NumericFormat
                                            className="pr-3 text-danger"
                                            displayType={"text"}
                                            value={this.state.Orders.balance}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </td>
                                      </tr>
                                      {this.state.Orders.advance > 0 ? (
                                        <>
                                          <tr>
                                            <td>
                                              Advance Amount Req.&nbsp;&nbsp;
                                            </td>
                                            <td>
                                              :&nbsp;&nbsp;
                                              <NumericFormat
                                                className="pr-3"
                                                displayType={"text"}
                                                value={
                                                  this.state.Orders.advance
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>Advance Amount Paid</td>
                                            <td>
                                              :&nbsp;&nbsp;
                                              <NumericFormat
                                                className="pr-3"
                                                displayType={"text"}
                                                value={
                                                  this.state.Orders
                                                    .advanceamountpaid
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </td>
                                          </tr>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {/* <tr>
                              <td>Advance Amount Balance&nbsp;&nbsp;</td>
                              <td>:&nbsp;&nbsp;<NumericFormat className="pr-3" displayType={'text'} value={this.state.Orders.advanceamountreq} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                            </tr> */}
                                      <tr>
                                        <td>Payment Terms</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          {this.state.Orders.paymentterms} Days
                                          Credit
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Payment</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          {this.state.Orders.payment}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="form-group col-md-4">
                                  {this.state.payment === "Completed" ? (
                                    ""
                                  ) : (
                                    <>
                                      {this.state.status === 2 ? (
                                        ""
                                      ) : (
                                        <table className="float-left">
                                          <tbody>
                                            {this.state.quotationfile === "" ||
                                            this.state.quotationfile ===
                                              undefined ? (
                                              ""
                                            ) : (
                                              <tr>
                                                <td>Quotation</td>
                                                <td>
                                                  <>
                                                    :&nbsp;
                                                    <button
                                                      className="btn btn-link fa fa-file-pdf text-sm  m-0"
                                                      data-tip
                                                      data-for="download"
                                                      onClick={() =>
                                                        this.handledownload(
                                                          this.state
                                                            .quotationfile
                                                        )
                                                      }
                                                    >
                                                      &nbsp;
                                                      {this.state.quotationfile}
                                                    </button>
                                                    {/* <Tooltip id="download" place="top" effect="solid" >Download</Tooltip> */}
                                                  </>
                                                </td>
                                              </tr>
                                            )}
                                            {this.state.Orders.potype === 1 ? (
                                              <>
                                                <tr>
                                                  <td>
                                                    Contract Type&nbsp;&nbsp;
                                                  </td>
                                                  <td>
                                                    :&nbsp;&nbsp;
                                                    {this.state.serviceList.map(
                                                      (e) => e.contracttype
                                                    )}
                                                  </td>
                                                </tr>
                                                {this.state.status === 0 ? (
                                                  ""
                                                ) : (
                                                  <tr>
                                                    <td>Contract File</td>
                                                    <td>
                                                      :&nbsp;&nbsp;
                                                      {this.state
                                                        .contractfile ===
                                                        null ||
                                                      this.state
                                                        .contractfile ===
                                                        undefined ||
                                                      this.state
                                                        .contractfile === "" ? (
                                                        <>
                                                          <button
                                                            className="text-blue btn btn-sm bg-white shadow-sm m-0"
                                                            data-tip
                                                            data-for="upload"
                                                            onClick={() =>
                                                              this.handleShowModal(
                                                                "contractfile"
                                                              )
                                                            }
                                                          >
                                                            <i className="fa fa-upload text-blue" />{" "}
                                                            Upload
                                                          </button>
                                                          {/* <Tooltip
                                      id="upload"
                                      place="top"
                                      effect="solid"
                                    >
                                      Upload
                                    </Tooltip> */}
                                                        </>
                                                      ) : (
                                                        <>
                                                          <button
                                                            className="btn btn-link fa fa-file-pdf text-sm  m-0"
                                                            data-tip
                                                            data-for="download"
                                                            onClick={() =>
                                                              this.handledownload(
                                                                this.state
                                                                  .contractfile
                                                              )
                                                            }
                                                          >
                                                            <i className="fas fa-download text-blue mr-1" />
                                                            {
                                                              this.state
                                                                .contractfile
                                                            }
                                                          </button>
                                                          <button
                                                            className="text-red btn btn-link m-0"
                                                            onClick={() =>
                                                              this.handleDeleteContractfile(
                                                                this.state.id
                                                              )
                                                            }
                                                            data-tip
                                                            data-for="delete"
                                                          >
                                                            <i className="fa fa-trash"></i>
                                                          </button>

                                                          {/* <Tooltip
                                      id="delete"
                                      place="top"
                                      effect="solid"
                                    >
                                      Delete
                                    </Tooltip>
                                    <Tooltip
                                      id="download"
                                      place="top"
                                      effect="solid"
                                    >
                                      Download
                                    </Tooltip> */}
                                                        </>
                                                      )}
                                                    </td>
                                                  </tr>
                                                )}
                                              </>
                                            ) : (
                                              ""
                                            )}
                                            {this.state.status === 0 ||
                                            this.state.Orders
                                              .advancepaymentreq === "False" ? (
                                              ""
                                            ) : (
                                              <>
                                                {this.state.Orders
                                                  .porformainvoiceid ===
                                                null ? (
                                                  ""
                                                ) : (
                                                  <tr>
                                                    <td>
                                                      Proforma Invoice Id&nbsp;
                                                    </td>
                                                    <td>
                                                      :&nbsp;&nbsp;
                                                      {
                                                        this.state.Orders
                                                          .porformainvoiceid
                                                      }
                                                    </td>
                                                  </tr>
                                                )}
                                                <tr>
                                                  <td>
                                                    Proforma Invoice &nbsp;
                                                  </td>
                                                  <td>
                                                    :&nbsp;&nbsp;
                                                    {this.state.invoicefile ===
                                                      null ||
                                                    this.state.invoicefile ===
                                                      "" ? (
                                                      <>
                                                        <button
                                                          className=" btn btn-sm hovbuttonColour shadow-sm m-0"
                                                          data-tipdata-for="upload"
                                                          onClick={() =>
                                                            this.handleShowModal(
                                                              "performainvoice",
                                                              this.state.Orders
                                                                .pototalwtax
                                                            )
                                                          }
                                                        >
                                                          <i className="fa fa-upload " />
                                                          &nbsp;Upload
                                                        </button>
                                                        {/* <Tooltip id="upload" place="top" effect="solid">Upload</Tooltip> */}
                                                      </>
                                                    ) : (
                                                      <>
                                                        <button
                                                          className="btn btn-link fa fa-file-pdf text-sm m-0"
                                                          data-tip
                                                          data-for="download"
                                                          onClick={() =>
                                                            this.handledownload(
                                                              this.state
                                                                .invoicefile
                                                            )
                                                          }
                                                        >
                                                          &nbsp;&nbsp;
                                                          {
                                                            this.state
                                                              .invoicefile
                                                          }
                                                        </button>
                                                        <button
                                                          className="text-red btn btn-link m-0"
                                                          data-tip
                                                          data-for="delete"
                                                          onClick={() =>
                                                            this.handleDeleteProforma(
                                                              this.state.id
                                                            )
                                                          }
                                                        >
                                                          <i className="fa fa-trash"></i>
                                                        </button>

                                                        {/* <Tooltip id="delete" place="top" effect="solid">Delete</Tooltip> */}
                                                        {/* <Tooltip id="download" place="top" effect="solid">Download</Tooltip> */}
                                                      </>
                                                    )}
                                                  </td>
                                                </tr>
                                                {this.state.Orders
                                                  .finalinvoiceid === null ? (
                                                  ""
                                                ) : (
                                                  <tr>
                                                    <td>Invoice ID</td>
                                                    <td>
                                                      {
                                                        this.state.Orders
                                                          .finalinvoiceid
                                                      }
                                                    </td>
                                                  </tr>
                                                )}
                                              </>
                                            )}
                                          </tbody>
                                        </table>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              {this.state.purchaseitem.length === 0 ? (
                                ""
                              ) : (
                                <TableContainer>
                                  <Typography>Items / Products</Typography>
                                  <Table className="table-sm border">
                                    <TableHead className="text-sm">
                                      <TableRow className="text-center">
                                        {this.state.Orders?.grnitems.length >
                                        0 ? (
                                          <TableCell />
                                        ) : (
                                          ""
                                        )}
                                        <TableCell className="text-center">
                                         PO Id
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Item Name
                                        </TableCell>
                                        <TableCell className="text-center">
                                          HSN
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Price
                                        </TableCell>
                                        {this.state.Orders.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Price({this.state.Orders.currency})
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Quantity
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Pending Quantity
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Item For
                                        </TableCell>
                                        <TableCell className="text-center">
                                          UOM
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Total
                                        </TableCell>
                                        {this.state.Orders.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Total ({this.state.Orders.currency})
                                          </TableCell>
                                        )}
                                        {this.state.Orders.taxrequired ===
                                        "TRUE" ? (
                                          <>
                                            {this.state.Orders.taxtype ===
                                              "GST" &&
                                            ccountry === "India" &&
                                            this.state.Orders.importpo ===
                                              false ? (
                                              this.state.Orders.gsttype ===
                                              "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    CGST
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    SGST
                                                  </TableCell>
                                                  {this.state.Orders
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <>
                                                      {" "}
                                                      <TableCell className="text-center">
                                                        CGST(
                                                        {
                                                          this.state.Orders
                                                            .currency
                                                        }
                                                        )
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                        SGST(
                                                        {
                                                          this.state.Orders
                                                            .currency
                                                        }
                                                        )
                                                      </TableCell>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <TableCell className="text-center">
                                                    IGST
                                                  </TableCell>
                                                  {this.state.Orders
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-center">
                                                      IGST(
                                                      {
                                                        this.state.Orders
                                                          .currency
                                                      }
                                                      )
                                                    </TableCell>
                                                  )}
                                                </>
                                              )
                                            ) : this.state.Orders.taxtype ===
                                              "GST" ? (
                                              <>
                                                <TableCell className="text-center">
                                                  GST
                                                </TableCell>
                                                {this.state.Orders.currency ===
                                                currency ? (
                                                  ""
                                                ) : (
                                                  <TableCell className="text-center">
                                                    GST(
                                                    {this.state.Orders.currency}
                                                    )
                                                  </TableCell>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {" "}
                                                <TableCell className="text-center">
                                                  VAT
                                                </TableCell>
                                                {this.state.Orders.currency ===
                                                currency ? (
                                                  ""
                                                ) : (
                                                  <TableCell className="text-center">
                                                    VAT(
                                                    {this.state.Orders.currency}
                                                    )
                                                  </TableCell>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <TableCell className="text-center">
                                          Total Price
                                        </TableCell>
                                        {this.state.Orders.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Total Price(
                                            {this.state.Orders.currency})
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody className="text-sm">
                                      {this.state.purchaseitem.map(
                                        (data, index) => (
                                          <Row
                                            Orders={this.state.Orders}
                                            data={data}
                                            index={index}
                                          />
                                        )
                                      )}

                                      <TableRow className="text-center">
                                        <TableCell className="text-center">
                                          Total
                                        </TableCell>
                                        {this.state.Orders?.grnitems.length >
                                        0 ? (
                                          <TableCell />
                                        ) : (
                                          ""
                                        )}
                                        {this.state.taxrequired === "FALSE" ? (
                                          ""
                                        ) : (
                                          <TableCell />
                                        )}
                                        {this.state.Orders.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell />
                                        )}
                                        <TableCell />
                                        <TableCell />
                                        <TableCell />
                                        <TableCell />
                                        <TableCell />
                                        <TableCell />
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            className="pr-3"
                                            displayType={"text"}
                                            value={
                                              this.state.Orders
                                                .grandproducttotal -
                                              this.state.Orders.totaltaxamount
                                            }
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                        {this.state.Orders.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              className="pr-3"
                                              displayType={"text"}
                                              value={
                                                (this.state.Orders
                                                  .grandproducttotal -
                                                  this.state.Orders
                                                    .totaltaxamount) *
                                                this.state.Orders
                                                  .curconversionrate
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                        )}

                                        {this.state.Orders.taxrequired ===
                                        "TRUE" ? (
                                          <>
                                            {this.state.Orders.taxtype ===
                                              "GST" &&
                                            this.state.Orders.importpo ===
                                              false &&
                                            this.state.Orders.gsttype ===
                                              "INTRASTATE" ? (
                                              <>
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      this.state.Orders
                                                        .totaltaxamount / 2
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      this.state.Orders
                                                        .totaltaxamount / 2
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              </>
                                            ) : (
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    this.state.Orders
                                                      .totaltaxamount
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                            )}
                                            {this.state.Orders.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <>
                                                {this.state.Orders.taxtype ===
                                                  "GST" &&
                                                this.state.Orders.importpo ===
                                                  false &&
                                                this.state.Orders.gsttype ===
                                                  "INTRASTATE" ? (
                                                  <>
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={
                                                          (this.state.Orders
                                                            .totaltaxamount /
                                                            2) *
                                                          this.state.Orders
                                                            .curconversionrate
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    </TableCell>
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={
                                                          (this.state.Orders
                                                            .totaltaxamount /
                                                            2) *
                                                          this.state.Orders
                                                            .curconversionrate
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    </TableCell>
                                                  </>
                                                ) : (
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        this.state.Orders
                                                          .totaltaxamount *
                                                        this.state.Orders
                                                          .curconversionrate
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            value={
                                              this.state.Orders
                                                .grandproducttotal
                                            }
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                        {this.state.Orders.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.Orders
                                                  .grandproducttotal *
                                                this.state.Orders
                                                  .curconversionrate
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              )}
                              {this.state.serviceList.length === 0 ? (
                                ""
                              ) : (
                                <>
                                  <TableContainer>
                                    <Typography>Services</Typography>
                                    <Table className="table-sm border">
                                      <TableHead className="text-sm">
                                        <TableRow
                                          style={{ textAlign: "center" }}
                                        >
                                          <TableCell className="text-center">
                                            Sl.No.
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Service
                                          </TableCell>
                                          <TableCell className="text-center">
                                            HSN/SAC Code
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Price
                                          </TableCell>
                                          {this.state.Orders.taxrequired ===
                                          "TRUE" ? (
                                            <>
                                              {this.state.Orders.taxtype ===
                                                "GST" &&
                                              ccountry === "India" ? (
                                                this.state.Orders.gsttype ===
                                                "INTRASTATE" ? (
                                                  <>
                                                    <TableCell className="text-center">
                                                      CGST(%)
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      SGST(%)
                                                    </TableCell>
                                                  </>
                                                ) : (
                                                  <TableCell className="text-center">
                                                    IGST(%)
                                                  </TableCell>
                                                )
                                              ) : this.state.Orders.taxtype ===
                                                "GST" ? (
                                                <TableCell className="text-center">
                                                  GST(%)
                                                </TableCell>
                                              ) : (
                                                <TableCell className="text-center">
                                                  VAT(%)
                                                </TableCell>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          <TableCell className="text-center">
                                            TotalPrice
                                          </TableCell>
                                          {this.state.Orders.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              TotalPrice (
                                              {this.state.Orders.currency})
                                            </TableCell>
                                          )}
                                          {this.state.serviceList
                                            .map((e) => e.contracttype)
                                            .toLocaleString() ===
                                          "Recurring" ? (
                                            <TableCell className="text-center">
                                              Recurring Type
                                            </TableCell>
                                          ) : (
                                            ""
                                          )}
                                        </TableRow>
                                      </TableHead>
                                      <TableBody className="text-sm">
                                        {this.state.serviceList.map(
                                          (data, index) => (
                                            <TableRow
                                              key={index}
                                              className="text-center"
                                            >
                                              <TableCell className="text-center">
                                                {index + 1}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {data.service}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {data.servicecode}
                                              </TableCell>
                                              <TableCell className="text-right">
                                                {" "}
                                                <NumericFormat
                                                  className="pr-3"
                                                  displayType={"text"}
                                                  value={data.servicecost}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.Orders.taxrequired ===
                                              "TRUE" ? (
                                                this.state.Orders.taxtype ===
                                                  "GST" &&
                                                ccountry === "India" &&
                                                this.state.Orders.gsttype ===
                                                  "INTRASTATE" ? (
                                                  <>
                                                    <TableCell className="text-center">
                                                      {data.servicetax / 2} %
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      {data.servicetax / 2} %
                                                    </TableCell>
                                                  </>
                                                ) : (
                                                  <TableCell className="text-center">
                                                    {data.servicetax} %
                                                  </TableCell>
                                                )
                                              ) : (
                                                ""
                                              )}
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  className="pr-3"
                                                  displayType={"text"}
                                                  value={data.servicetotalwtax}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.Orders.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    className="pr-3"
                                                    displayType={"text"}
                                                    value={
                                                      data.servicetotalwtax *
                                                      this.state.Orders
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                              {this.state.serviceList
                                                .map((e) => e.contracttype)
                                                .toLocaleString() ===
                                              "Recurring" ? (
                                                <TableCell className="text-center">
                                                  {this.state.serviceList.map(
                                                    (e) => e.recurringtype
                                                  )}
                                                </TableCell>
                                              ) : (
                                                ""
                                              )}
                                            </TableRow>
                                          )
                                        )}
                                        <TableRow>
                                          <TableCell className="text-center">
                                            Total
                                          </TableCell>
                                          <TableCell />
                                          <TableCell />
                                          <TableCell />
                                          {this.state.Orders.taxrequired ===
                                          "TRUE" ? (
                                            <>
                                              <TableCell />
                                              {this.state.Orders.taxtype ===
                                                "GST" &&
                                              ccountry === "India" &&
                                              this.state.Orders.gsttype ===
                                                "INTRASTATE" ? (
                                                <TableCell />
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          <TableCell className="text-right">
                                            <NumericFormat
                                              className="pr-3"
                                              displayType={"text"}
                                              value={
                                                this.state.Orders
                                                  .grandservicetotal
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.Orders.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                className="pr-3"
                                                displayType={"text"}
                                                value={
                                                  this.state.Orders
                                                    .grandservicetotal *
                                                  this.state.Orders
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          {this.state.serviceList
                                            .map((e) => e.contracttype)
                                            .toLocaleString() ===
                                          "Recurring" ? (
                                            <TableCell></TableCell>
                                          ) : (
                                            ""
                                          )}
                                        </TableRow>
                                      </TableBody>
                                    </Table>
                                  </TableContainer>

                                  {this.state.milestones.length === 0 ? (
                                    ""
                                  ) : (
                                    <TableContainer>
                                      <Typography>Milestones</Typography>
                                      <Table className="table-sm mt-4 border">
                                        <TableHead className="text-sm">
                                          <TableRow
                                            style={{ textAlign: "center" }}
                                          >
                                            <TableCell className="text-center">
                                              Name
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Expected Date
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Percentage
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Amount
                                            </TableCell>
                                            {this.state.Orders.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-center">
                                                Amount (
                                                {this.state.Orders.currency})
                                              </TableCell>
                                            )}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {this.state.milestones.map(
                                            (data, index) => (
                                              <TableRow key={index}>
                                                <TableCell className="text-center">
                                                  {data.name}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  {moment(
                                                    data.expecteddate
                                                  ).format("DD-MM-YYYY")}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  {data.percentage}
                                                </TableCell>
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    className="pr-3"
                                                    displayType={"text"}
                                                    value={data.totalpricewtax}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                                {this.state.Orders.currency ===
                                                currency ? (
                                                  ""
                                                ) : (
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      className="pr-3"
                                                      displayType={"text"}
                                                      value={
                                                        data.totalpricewtax *
                                                        this.state.Orders
                                                          .curconversionrate
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                )}
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  )}
                                </>
                              )}

                              {/* E-mail modal */}
                              <Modal
                                show={this.state.show}
                                onHide={this.handleClose}
                                backdrop="static"
                                keyboard={false}
                                centered
                                size="lg"
                              >
                                <Modal.Header closeButton>
                                  <b>Mail</b>
                                </Modal.Header>
                                <div className="form-row mt-5 ml-5">
                                  <fieldset className="form-group col-md-4">
                                    <label htmlFor="btn-upload">
                                      <input
                                        className="btn-choose"
                                        id="btn-upload"
                                        name="btn-upload"
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={this.selectFile}
                                      />
                                      <Button
                                        className="btn-close"
                                        variant="outlined"
                                        type="upload"
                                        component="span"
                                      >
                                        <span className="text-blue">
                                          Select File to Upload
                                        </span>
                                      </Button>
                                    </label>
                                  </fieldset>

                                  <fieldset className="form-group col-md-3">
                                    <ul className="list-group">
                                      {selectedFiles && selectedFiles.length > 0
                                        ? selectedFiles[0].name
                                        : null}
                                    </ul>
                                  </fieldset>
                                  <fieldset className="form-group col-md-3">
                                    <Button
                                      className="btn-upload"
                                      color="primary"
                                      variant="contained"
                                      component="span"
                                      disabled={!selectedFiles}
                                      onClick={this.upload}
                                    >
                                      Upload
                                    </Button>
                                  </fieldset>

                                  <div className="form-group col-md-9">
                                    <Typography
                                      variant="subtitle2"
                                      className={`upload-message ${
                                        isError ? "error" : ""
                                      }`}
                                    >
                                      <span className="text-green">
                                        {message}
                                      </span>
                                    </Typography>
                                  </div>
                                  <div className="form-group col-md-9">
                                    <button
                                      className="btn btn-sm hovbuttonColour float-center"
                                      onClick={this.handleSendMail}
                                    >
                                      Send{" "}
                                      <i
                                        className="fa fa-paper-plane"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </div>

                                <Modal.Body></Modal.Body>
                              </Modal>

                              {/* Upload final po modal */}
                              <Modal
                                show={this.state.showModal}
                                onHide={this.handleModalClose}
                                backdrop="static"
                                keyboard={false}
                                centered
                                size="lg"
                              >
                                <Modal.Header closeButton>
                                  {doctype} file
                                </Modal.Header>
                                <div className="form-row mt-5 ml-5">
                                  <fieldset className="form-group col-md-4">
                                    <label htmlFor="btn-upload">
                                      <input
                                        className="btn-choose"
                                        id="btn-upload"
                                        name="btn-upload"
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={this.selectFile}
                                      />
                                      <Button
                                        className="btn-close"
                                        variant="outlined"
                                        type="upload"
                                        component="span"
                                      >
                                        <span className="text-blue">
                                          Select File to Upload
                                        </span>
                                      </Button>
                                    </label>
                                  </fieldset>

                                  <fieldset className="form-group col-md-3">
                                    <ul className="list-group">
                                      {selectedFiles && selectedFiles.length > 0
                                        ? selectedFiles[0].name
                                        : null}
                                    </ul>

                                    {currentFile && (
                                      // <fieldset  className="form-group col-md-3">

                                      <Box
                                        className="mb25"
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <Box width="100%" mr={1}>
                                          {/* <LinearProgress variant="query"></LinearProgress>  */}
                                          {/* <BorderLinearProgress
                                      variant="determinate"
                                      value={progress}
                                    /> */}

                                          {/* value={this.state.progress} valueBuffer={0} /> */}
                                        </Box>
                                        <Box minWidth={35}>
                                          <Typography
                                            variant="body2"
                                            color="textSecondary"
                                          >{`${progress}%`}</Typography>
                                        </Box>
                                      </Box>
                                    )}
                                  </fieldset>
                                  <fieldset className="form-group col-md-3">
                                    <Button
                                      className="btn-upload"
                                      color="primary"
                                      variant="contained"
                                      component="span"
                                      disabled={!selectedFiles}
                                      onClick={this.upload}
                                    >
                                      Upload
                                    </Button>
                                  </fieldset>

                                  {/* </div> */}
                                  <div className="form-group col-md-9">
                                    <Typography
                                      variant="subtitle2"
                                      className={`upload-message ${
                                        isError ? "error" : ""
                                      }`}
                                    >
                                      <span className="text-green">
                                        {message}
                                      </span>
                                    </Typography>
                                  </div>
                                  <div className="form-group col-md-4">
                                    <TextField
                                      id="invoiceid"
                                      className="form-control"
                                      type="text"
                                      name="invoiceid"
                                      label="Invoice Id"
                                      value={this.state.invoiceid}
                                      onChange={(e) =>
                                        this.setState({
                                          invoiceid: e.target.value,
                                        })
                                      }
                                      variant="standard"
                                    />
                                  </div>
                                  {this.state.Orders.potype === 1 ? (
                                    ""
                                  ) : (
                                    <div className="form-group col-md-4">
                                      <TextField
                                        id="amount"
                                        className="form-control"
                                        type="number"
                                        name="invoiceamt"
                                        label="Amount"
                                        value={this.state.invoiceamt}
                                        onChange={(e) =>
                                          this.setState({
                                            invoiceamt: e.target.value,
                                          })
                                        }
                                        onFocus={this.onfocus}
                                        variant="standard"
                                      />
                                    </div>
                                  )}
                                  {this.state.Orders.potype === 1 ? (
                                    <div className="form-group col-md-9">
                                      <button
                                        className={`btn btn-sm hovbuttonColour float-center mt-2`}
                                        onClick={this.handleFinalSubmit}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  ) : this.state.invoiceamt === "" ||
                                    this.state.fileInfos.length === 0 ||
                                    this.state.invoiceid === "" ? (
                                    ""
                                  ) : (
                                    <div className="form-group col-md-9">
                                      <button
                                        className={`btn btn-sm hovbuttonColour float-center mt-2`}
                                        onClick={this.handleFinalSubmit}
                                      >
                                        Submit
                                      </button>
                                    </div>
                                  )}
                                </div>

                                <Modal.Body></Modal.Body>
                              </Modal>

                              {/* Order Cancllation Modal */}
                              <Modal
                                show={this.state.showCancelModal}
                                onHide={this.handleCancelModalClose}
                                backdrop="static"
                                keyboard={false}
                                centered
                                size="lg"
                              >
                                <Modal.Header closeButton>
                                  Order{" "}
                                  {this.state.cnlorprecls === 0
                                    ? "Preclose"
                                    : "Cancellation"}
                                </Modal.Header>
                                <div className="form-row mt-3 ml-3">
                                  <fieldset className="form-group col-md-8">
                                    <TextField
                                      name="cancellationdescription"
                                      className="form-control"
                                      multiline
                                      rows={2}
                                      label={`Reason For ${
                                        this.state.cnlorprecls === 0
                                          ? "Preclose"
                                          : "Cancellation"
                                      }`}
                                      variant="outlined"
                                      value={this.state.cancellationdescription}
                                      onChange={(e) =>
                                        this.setState({
                                          cancellationdescription:
                                            e.target.value,
                                        })
                                      }
                                    />
                                  </fieldset>
                                  <fieldset className="form-group col-md-12" />
                                  <fieldset className="form-group col-md-12" />
                                  <button
                                    className="btn btn-sm hovbuttonColour float-center ml-2"
                                    onClick={() =>
                                      this.handleStatus(
                                        this.state.cnlorprecls === 0 ? 3 : 2
                                      )
                                    }
                                  >
                                    {this.state.cloader === true ? (
                                      <>
                                        <Spinner
                                          className="mr-1"
                                          animation="border"
                                          size="sm"
                                        />
                                        {"Submitting..."}
                                      </>
                                    ) : (
                                      <>
                                        <i className="fas fa-save mr-1" />
                                        {"Submit"}
                                      </>
                                    )}
                                  </button>
                                </div>
                                <Modal.Body> </Modal.Body>
                              </Modal>
                              {this.state.payment === "Completed" ||
                              this.state.Orders.postatus === "Preclosed" ? (
                                ""
                              ) : (
                                <>
                                  {this.state.status === 2 ? (
                                    ""
                                  ) : (
                                    <div style={{ marginTop: "10px" }}>
                                      {purchaseid === "3" ||
                                      purchaseid === "2" ? (
                                        <>
                                          {this.state.status === 1 ? (
                                            this.state.showadv ? (
                                              <div className="mr-2">
                                                <TextField
                                                  className="form-contr col-md-3"
                                                  type="number"
                                                  name="advance"
                                                  value={this.state.advance}
                                                  onChange={(e) =>
                                                    this.setState({
                                                      advance:
                                                        e.target.value >
                                                        this.state.Orders
                                                          .pototalwtax
                                                          ? this.state.Orders
                                                              .pototalwtax
                                                          : e.target.value,
                                                    })
                                                  }
                                                  variant="standard"
                                                  fullWidth
                                                />
                                                <button
                                                  className="btn btn-sm hovbuttonColour"
                                                  onClick={
                                                    this.handleAddAdvance
                                                  }
                                                >
                                                  <i className="fa fa-money mr-1" />{" "}
                                                  Add Advance
                                                </button>
                                                <button
                                                  className="btn btn-sm ml-1 deletebutton"
                                                  onClick={() =>
                                                    this.setState({
                                                      showadv: false,
                                                      advance: 0,
                                                    })
                                                  }
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            ) : (
                                              <>
                                                {this.state.Orders
                                                  .advancepaymentreq ===
                                                  "False" &&
                                                this.state.Orders.grnstatus ===
                                                  "NoGRNGenerated" ? (
                                                  <button
                                                    className="btn btn-sm hovbuttonColour"
                                                    onClick={() =>
                                                      this.setState({
                                                        showadv: true,
                                                      })
                                                    }
                                                  >
                                                    <i className="fa fa-money mr-1" />{" "}
                                                    Add Advance
                                                  </button>
                                                ) : (
                                                  ""
                                                )}
                                                {this.state.Orders.grnstatus ===
                                                  "PartiallyGRNgenerated" &&
                                                this.state.Orders.grnitems
                                                  .length !== 0 &&
                                                this.state.Orders.grnitems.filter(
                                                  (e) => e.balance !== 0
                                                ).length === 0 ? (
                                                  <button
                                                    className="btn btn-sm ml-1 deletebutton"
                                                    onClick={() =>
                                                      this.handleCancelModalShow(
                                                        0
                                                      )
                                                    }
                                                  >
                                                    <b className="text-red">
                                                      X&nbsp;
                                                    </b>
                                                    Preclose Order
                                                  </button>
                                                ) : this.state.Orders
                                                    .grnstatus ===
                                                    "GRNgenerated" ||
                                                  this.state.Orders.grnitems
                                                    .length !== 0 ? (
                                                  ""
                                                ) : (
                                                  <button
                                                    className="btn btn-sm ml-1 deletebutton"
                                                    onClick={() =>
                                                      this.handleCancelModalShow(
                                                        1
                                                      )
                                                    }
                                                  >
                                                    <b className="text-red">
                                                      X&nbsp;
                                                    </b>
                                                    Cancel Order
                                                  </button>
                                                )}
                                              </>
                                            )
                                          ) : (
                                            <>
                                              {purchaseid === "3" ||
                                              purchaseid === "2" ? (
                                                <>
                                                  {userid ===
                                                  this.state.Orders
                                                    .createdby ? (
                                                    <Link
                                                      as="button"
                                                      data-tip
                                                      data-for="edit"
                                                      className="btn btn-sm hovbuttonColour mx-2"
                                                      to={{
                                                        pathname: `/newpurchase`,
                                                      }}
                                                      state={{
                                                        pagename:
                                                          "Edit Purchase Order",
                                                        id: this.state.id,
                                                        indentid: "",
                                                      }}
                                                    >
                                                      <i className="fa fa-edit mr-1" />
                                                      Edit Order
                                                    </Link>
                                                  ) : (
                                                    ""
                                                  )}

                                                  {purchaseid === "3" ? (
                                                    <button
                                                      className="btn btn-sm hovbuttonColour"
                                                      onClick={() =>
                                                        this.handleStatus(1)
                                                      }
                                                    >
                                                      <i className="fa fa-check mr-1" />
                                                      Confirm Order
                                                    </button>
                                                  ) : (
                                                    ""
                                                  )}
                                                  <button
                                                    className="btn btn-sm deletebutton"
                                                    style={{
                                                      marginLeft: "7px",
                                                    }}
                                                    onClick={() =>
                                                      this.handleStatus(4)
                                                    }
                                                  >
                                                    <i className="fa fa-trash m-0" />{" "}
                                                    Delete Order
                                                  </button>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>

                          {this.state.Orders.canceldesc !== null &&
                          this.state.Orders.canceldesc !== undefined &&
                          this.state.Orders.canceldesc !== "" ? (
                            <fieldset className="form-group col-md-12">
                              <label className="text-red">
                                {this.state.Orders.postatus} Description :{" "}
                                {this.state.Orders.canceldesc}
                              </label>
                            </fieldset>
                          ) : (
                            ""
                          )}
                        </div>

                        {/* /.card */}
                      </div>
                      {/* /.col */}
                    </div>
                    {/* /.row */}
                  </div>
                  {/* /.container-fluid */}
                </section>
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

function Row(props) {
  const { Orders, data, index } = props;
  const [open, setOpen] = React.useState(false);
  const [showquantity, setShowquantity] = useState(false);

  return (
    <>
      <TableRow key={index} sx={{ "& > *": { borderBottom: "unset" } }}>
        {Orders?.grnitems.length > 0 ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setOpen(op=>!op);
                setShowquantity(false);
              }}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        ) : (
          ""
        )}
        <TableCell className="text-center">{data.adjustedfrom === null?Orders.custompoid:data.adjustedfrom}</TableCell>
        <TableCell className="text-center">{data.name}</TableCell>
        <TableCell className="text-center">{data.hsn}</TableCell>
        <TableCell className="text-right">
          <NumericFormat
            className="pr-3"
            displayType={"text"}
            value={data.price}
            decimalScale={2}
            fixedDecimalScale={true}
            disabled={true}
            thousandSeparator={true}
            thousandsGroupStyle={thousandsdisplay}
          ></NumericFormat>
        </TableCell>
        {Orders.currency === currency ? (
          ""
        ) : (
          <TableCell className="text-right">
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={data.price * Orders.curconversionrate}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          </TableCell>
        )}
        <TableCell
          className="text-center cursorPointer"
          onClick={() => {
            setShowquantity(sq=>!sq);
            setOpen(false);
          }}
        >
          {data.quantity}{" "}
          {showquantity ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </TableCell>
        <TableCell className="text-center">{data.pending}</TableCell>
        <TableCell className="text-center">{data.poitemstatus}</TableCell>
        <TableCell className="text-center">{data.uom}</TableCell>
        <TableCell className="text-right">
          <NumericFormat
            className="pr-3"
            displayType={"text"}
            value={data.totalprice}
            decimalScale={2}
            fixedDecimalScale={true}
            disabled={true}
            thousandSeparator={true}
            thousandsGroupStyle={thousandsdisplay}
          ></NumericFormat>
        </TableCell>
        {Orders.currency === currency ? (
          ""
        ) : (
          <TableCell className="text-right">
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={data.totalprice * Orders.curconversionrate}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          </TableCell>
        )}
        {Orders.taxrequired === "TRUE" ? (
          <>
            {Orders.taxtype === "GST" &&
            ccountry === "India" &&
            Orders.importpo === false &&
            Orders.gsttype === "INTRASTATE" ? (
              <>
                <TableCell className="text-right">
                  <NumericFormat
                    displayType={"text"}
                    value={data.taxamount / 2}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    disabled={true}
                    thousandSeparator={true}
                    thousandsGroupStyle={thousandsdisplay}
                  ></NumericFormat>
                  &nbsp;({data.tax / 2}%)
                </TableCell>
                <TableCell className="text-right">
                  <NumericFormat
                    displayType={"text"}
                    value={data.taxamount / 2}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    disabled={true}
                    thousandSeparator={true}
                    thousandsGroupStyle={thousandsdisplay}
                  ></NumericFormat>
                  &nbsp;({data.tax / 2}%)
                </TableCell>
                {Orders.currency === currency ? (
                  ""
                ) : (
                  <>
                    <TableCell className="text-right">
                      <NumericFormat
                        displayType={"text"}
                        value={(data.taxamount / 2) * Orders.curconversionrate}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        disabled={true}
                        thousandSeparator={true}
                        thousandsGroupStyle={thousandsdisplay}
                      ></NumericFormat>
                      &nbsp;({data.tax / 2}%)
                    </TableCell>
                    <TableCell className="text-right">
                      <NumericFormat
                        displayType={"text"}
                        value={(data.taxamount / 2) * Orders.curconversionrate}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        disabled={true}
                        thousandSeparator={true}
                        thousandsGroupStyle={thousandsdisplay}
                      ></NumericFormat>
                      &nbsp;({data.tax / 2}%)
                    </TableCell>
                  </>
                )}
              </>
            ) : (
              <>
                <TableCell className="text-right">
                  <NumericFormat
                    displayType={"text"}
                    value={data.taxamount}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    disabled={true}
                    thousandSeparator={true}
                    thousandsGroupStyle={thousandsdisplay}
                  ></NumericFormat>
                  &nbsp;({data.tax}%)
                </TableCell>
                {Orders.currency === currency ? (
                  ""
                ) : (
                  <TableCell className="text-right">
                    <NumericFormat
                      displayType={"text"}
                      value={data.taxamount * Orders.curconversionrate}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      disabled={true}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat>
                    &nbsp;({data.tax}%)
                  </TableCell>
                )}
              </>
            )}
          </>
        ) : (
          ""
        )}
        <TableCell className="text-right">
          <NumericFormat
            displayType={"text"}
            value={data.totalpricewtax}
            decimalScale={2}
            fixedDecimalScale={true}
            disabled={true}
            thousandSeparator={true}
            thousandsGroupStyle={thousandsdisplay}
          ></NumericFormat>
        </TableCell>
        {Orders.currency === currency ? (
          ""
        ) : (
          <TableCell className="text-right">
            <NumericFormat
              displayType={"text"}
              value={data.totalpricewtax * Orders.curconversionrate}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography gutterBottom component="div">
                GRN Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Grn Id</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell align="right">Date</TableCell>
                    {Orders.advance > 0 ? (
                      <TableCell align="right">Advance Adjusted</TableCell>
                    ) : (
                      ""
                    )}
                    {Orders.openadjusted !== null && Orders.openadjusted > 0 ? (
                      <TableCell align="right">Opening Adjusted</TableCell>
                    ) : (
                      ""
                    )}
                    {Orders.creditadjusted !== null &&
                    Orders.creditadjusted > 0 ? (
                      <TableCell align="right">Credit Adjusted</TableCell>
                    ) : (
                      ""
                    )}
                    <TableCell className="text-center">Quantity</TableCell>
                    <TableCell className="text-center">
                      Weighted Quantity
                    </TableCell>
                    <TableCell className="text-center">
                      Accepted Quantity
                    </TableCell>
                    <TableCell className="text-center">
                      Difference Quantity
                    </TableCell>
                    <TableCell className="text-center">
                      Returned Quantity
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Orders?.grnitems
                    ?.filter((e) => (e.iid === data.id && data.adjustedfromid === null) || (data.adjustedfromid !== null && data.adjustedfromid === e.iid))
                    .map((historyRow) => (
                      <TableRow key={historyRow.date}>
                        <TableCell component="th" scope="row">
                          <Link
                            as="button"
                            to={{ pathname: "/viewgrnitems" }}
                            state={{ id: historyRow.customgrnid, temp: false }}
                          >
                            {historyRow.customgrnid}
                          </Link>
                        </TableCell>
                        <TableCell>{historyRow.quantity}</TableCell>
                        <TableCell align="right">
                          {moment(historyRow.createdAt).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </TableCell>
                        {Orders.advance > 0 ? (
                          <TableCell className="text-right">
                            <NumericFormat
                              displayType={"text"}
                              value={
                                historyRow.advanceadjusted === null
                                  ? 0
                                  : historyRow.advanceadjusted
                              }
                              decimalScale={2}
                              fixedDecimalScale={true}
                              disabled={true}
                              thousandSeparator={true}
                              thousandsGroupStyle={thousandsdisplay}
                            ></NumericFormat>
                          </TableCell>
                        ) : (
                          ""
                        )}

                        {Orders.openadjusted !== null &&
                        Orders.openadjusted > 0 ? (
                          <TableCell className="text-right">
                            <NumericFormat
                              displayType={"text"}
                              value={
                                historyRow.openadjusted === null
                                  ? 0
                                  : historyRow.openadjusted
                              }
                              decimalScale={2}
                              fixedDecimalScale={true}
                              disabled={true}
                              thousandSeparator={true}
                              thousandsGroupStyle={thousandsdisplay}
                            ></NumericFormat>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        {Orders.creditadjusted !== null &&
                        Orders.creditadjusted > 0 ? (
                          <TableCell className="text-right">
                            <NumericFormat
                              displayType={"text"}
                              value={
                                historyRow.creditadjusted === null
                                  ? 0
                                  : historyRow.creditadjusted
                              }
                              decimalScale={2}
                              fixedDecimalScale={true}
                              disabled={true}
                              thousandSeparator={true}
                              thousandsGroupStyle={thousandsdisplay}
                            ></NumericFormat>
                          </TableCell>
                        ) : (
                          ""
                        )}
                        <TableCell className="text-center">
                          {historyRow.quantity}
                        </TableCell>
                        <TableCell className="text-center">
                          {historyRow.weightedquantity}
                        </TableCell>
                        <TableCell className="text-center">
                          {historyRow.accepted}
                        </TableCell>
                        <TableCell className="text-center">
                          {historyRow.weightedquantity - historyRow.quantity}
                        </TableCell>
                        <TableCell className="text-center">
                          {historyRow.returnquantity}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={showquantity} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography gutterBottom component="div">
                Quantity Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                 
                    <TableCell className="text-center">
                      Accepted Quantity
                    </TableCell>
                    <TableCell className="text-center">
                      Adjusted Quantity
                    </TableCell>
                    <TableCell className="text-center">
                      Preclosed Quantity
                    </TableCell>
                    <TableCell className="text-center">
                      Return Quantity
                    </TableCell>
                    <TableCell className="text-center">
                      Cancelled Quantity
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                 
                    <TableCell className="text-center">
                      {data.accepted}
                    </TableCell>
                    <TableCell className="text-center">
                      {data.adjustedquantity}
                    </TableCell>
                    <TableCell className="text-center">
                      {data.preclosequantity === null ||
                      data.preclosequantity === undefined
                        ? 0
                        : data.preclosequantity}
                    </TableCell>
                    <TableCell className="text-center">
                      {data.returnquantity}
                    </TableCell>
                    <TableCell className="text-center">
                      {data.cancelledquantity === "" ||
                      data.cancelledquantity === null ||
                      data.cancelledquantity === undefined
                        ? 0
                        : data.cancelledquantity}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default withRouter(ViewPurchaseorder);
