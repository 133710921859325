import React, { useEffect, useState } from "react";
import withRouter from "../../withRouter";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import { FormikTextField } from "../../CommonComponents/FormField";
import { Autocomplete, FormLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import CategoryServices from "../../Masters/Category/CategoryServices";
import InventoryService from "../../inventory/InventoryService";
import { currentTenant, userid } from "../../Common/Credentials";
import Swal from "sweetalert2";
import ProductionServices from "../ProductionServices";

const NewProductionTemplate = (props) => {
  const navigate = useNavigate();
  const [id] = useState(
    props.location.state === undefined ? "" : props.location.state.id
  );
  const [templatename,setTemplatename] = useState("")
  const [outputcategory, setOutputcategory] = React.useState("");
  const [outputcatid, setOutputcatId] = React.useState("");
  const [outputitemname, setOutputitemname] = React.useState("");
  const [outputitemid, setOutputitemId] = React.useState("");
  const [outputuom, setOutputuom] = React.useState("");
  const [outputuomid, setOutputuomid] = React.useState("");
  const [itemname, setItemname] = React.useState("");
  const [itemid, setItemId] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [categoryList, setCategoryList] = React.useState([]);
  const [category, setCategory] = React.useState("");
  const [catid, setCatId] = React.useState("");
  const [uom, setUom] = React.useState("");
  const [uomid, setUomid] = React.useState("");
  const [itemsList, setItemList] = React.useState([]);
  const [filterItemList, setFilterItemList] = React.useState([]);
  const [outputFilterItemList, setOutputFilterItemList] = React.useState([]);
  const [addedItemList, setAddedItemList] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [showraw,setShowraw] = React.useState(true);

  useEffect(() => {
    CategoryServices.getCategory(currentTenant).then((response) =>
      setCategoryList(response.data.filter((e) => e.categorytype === "Product"))
    );

    InventoryService.getItemNames(currentTenant).then((response) =>
      setItemList(response.data)
    );

    // InventoryService.getRawMaterials(currentTenant).then((response)=>{
    //   setRawItemList(response.data)
    // })
  }, []);

  const handleOutputCategoryChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setOutputcatId("");
      setOutputcategory("");
      setOutputFilterItemList([]);
    } else {
      setOutputcatId(newValue.id);
      setOutputcategory(newValue.category);
      setOutputFilterItemList(itemsList.filter((e) => e.catid === newValue.id));
    }
  };

  const handleSelectOutputItem = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setOutputitemname("");
      setOutputitemId("");
      setOutputuom("")
      setOutputuomid("")
    } else {
      setOutputitemname(newValue.name);
      setOutputitemId(newValue.id);
      setOutputuom(newValue.uomname)
      setOutputuomid(newValue.uom)
    }
  };

  const handleCategoryChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setCatId("");
      setCategory("");
    } else {
      setCatId(newValue.id);
      setCategory(newValue.category);
      setFilterItemList(itemsList.filter((e) => e.catid === newValue.id));
    }
  };

  const handleSelectItem = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setItemname("");
      setItemId("");
      setUom("")
      setUomid("")
    } else {
      setItemname(newValue.name);
      setItemId(newValue.id);
      setUom(newValue.uomname)
      setUomid(newValue.uom)
    }
  };

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleAddItemList = () => {
    if (
      itemname !== "" &&
      quantity !== "" &&
      parseFloat(quantity) > 0 && 
      addedItemList.filter(e=>e.itemid === itemid).length === 0
    ) {
        setShowraw(false)
      let data = {
        category: category,
        catid: catid,
        itemid: itemid,
        itemname: itemname,
        quantity: parseFloat(quantity),
        uom:uom,
        uomid:uomid,
      };
      addedItemList.push(data);
      setAddedItemList([...addedItemList])
      setItemname("")
      setItemId("")
      setQuantity("")
      setUom("")
      setUomid("")
      setShowraw(true)
    }else{
        if(addedItemList.filter(e=>e.itemid === itemid).length !== 0){
           new Swal({
                title:"Item Already exists",
                text:"",
                icon:"warning",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
            })
        }
    }
  };

  const handelRemoveItem = (idx) =>{
    setAddedItemList(addedItemList.filter((item,i) => i !== idx))
  }

  const handleSubmit = () => {
    let data = {
        templatename:templatename,
        itemid:outputitemid,
        itemname:outputitemname,
        category:outputcategory,
        catid:outputcatid,
        uom:outputuom,
        uomid:outputuomid,
        productiontemplaterawmaterials:addedItemList,
        tenantid:currentTenant,
        createdby:userid,
    }
    ProductionServices.addProductionTemplate(data).then((response) => navigate("/productiontemplates"))
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card ">
          <ol className="breadcrumb float-sm-right">
          <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to="/productiontemplates"
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/production">Home</Link>
            </li>

            <li className="breadcrumb-item text-secondary">
              <Link to="/productiontemplates">Production Templates</Link>
            </li>
            <li className="breadcrumb-item active">New Production Template</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div>
                      <Formik
                        initialValues={{}}
                        // onSubmit={onSubmit}
                        validateOnChange={false}
                        // validate={validate}
                        enableReinitialize={true}
                      >
                        {({ setFieldValue, values }) => (
                          <Form
                            autoComplete="off"
                            style={{ paddingLeft: "30px" }}
                          >
                            
                            <div className="form-row mt-3">
                            <fieldset className="col-md-12 m-0 p-0">
                                   <FormLabel>Output Product/ Final Product</FormLabel>   
                              </fieldset>
                            <fieldset className="col-md-3">
                                <TextField
                                  id="standard-error-helper-text"
                                  label={`Template Name`}
                                  type="templatename"
                                  value={templatename}
                                  onChange={(e)=>setTemplatename(e.target.value)}
                                  variant="standard"
                                  helperText={
                                    templatename === "" || parseInt(templatename) <= 0
                                      ? "Empty field!"
                                      : " "
                                  }
                                  fullWidth
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="outputcategory"
                                  options={categoryList}
                                  getOptionLabel={(option) => option.category}
                                  value={values.category}
                                  onChange={handleOutputCategoryChange}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="outputcategory"
                                      value={outputcategory}
                                      label={`Select Output Category (${outputcategory})`}
                                      variant="standard"
                                      error={outputcatid === ""}
                                      helperText={
                                        outputcatid === ""
                                          ? "Empty field!"
                                          : " "
                                      }
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>

                              <fieldset className="col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="itemname"
                                  options={outputFilterItemList}
                                  getOptionLabel={(option) => option.name}
                                  value={values.outputitemname}
                                  onChange={handleSelectOutputItem}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      id="standard-error-helper-text"
                                      label={`Select Output Item (${outputitemname})`}
                                      name="itemname"
                                      value={outputitemname}
                                      variant="standard"
                                      error={outputitemname === ""}
                                      helperText={
                                        outputitemname === ""
                                          ? "Empty field!"
                                          : " "
                                      }
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>
                              <fieldset className="col-md-3">
                              <FormikTextField
                                      id="standard-error-helper-text"
                                      label={`UOM`}
                                      name="outputuom"
                                      value={outputuom}
                                      variant="standard"
                                      fullWidth
                                      disabled
                                    />
                                    </fieldset>
                              <fieldset className="col-md-12 m-0 p-0 mt-2">
                                   <FormLabel>Raw Materials </FormLabel>   
                              </fieldset>
                              {showraw?<>
                              <fieldset className="form-group col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="category"
                                  options={categoryList}
                                  getOptionLabel={(option) => option.category}
                                  value={values.category}
                                  onChange={handleCategoryChange}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="category"
                                      value={category}
                                      label={`Select Category (${category})`}
                                      variant="standard"
                                      error={catid === ""}
                                      helperText={
                                        catid === "" ? "Empty field!" : " "
                                      }
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>

                              <fieldset className="col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="itemname"
                                  options={filterItemList}
                                  getOptionLabel={(option) => option.name}
                                  value={values.itemname}
                                  onChange={handleSelectItem}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      id="standard-error-helper-text"
                                      label={`Select Item (${itemname})`}
                                      name="itemname"
                                      value={itemname}
                                      variant="standard"
                                      error={itemname === ""}
                                      helperText={
                                        itemname === "" ? "Empty field!" : " "
                                      }
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>
                              <fieldset className="col-md-2">
                              <FormikTextField
                                      id="standard-error-helper-text"
                                      label={`UOM`}
                                      name="uom"
                                      value={uom}
                                      variant="standard"
                                      disabled
                                      fullWidth
                                    />
                                </fieldset>
                              <fieldset className="col-md-2">
                                <TextField
                                  id="standard-error-helper-text"
                                  label={`Quantity`}
                                  type="number"
                                  value={quantity}
                                  onChange={handleQuantityChange}
                                  variant="standard"
                                  helperText={
                                    quantity === "" || parseFloat(quantity) <= 0
                                      ? "Empty field!"
                                      : " "
                                  }
                                  fullWidth
                                />
                              </fieldset>
                              <fieldset className="col-md-2 d-flex align-items-center">
                                <span
                                  as="button"
                                  className="btn btn-sm hovbuttonColour"
                                  onClick={handleAddItemList}
                                >
                                  Add
                                </span>
                              </fieldset>
                              </>:""}
                            </div>
                            {addedItemList.length !== 0 ? (
                            <>
                              <TableContainer className="mt-3">
                                <Typography>Raw Materials List</Typography>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="text-center">
                                        Itemname
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Category
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                         UOM
                                        </TableCell>
                                      <TableCell className="text-center">
                                        Actions
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {addedItemList.map((e, index) => (
                                      <TableRow key={index}>
                                        <TableCell className="text-center">
                                          {e.itemname}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.category}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.quantity}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.uom}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          <span
                                            as="buttom"
                                            className="btn btn-sm deletebutton"
                                            onClick={() =>
                                              handelRemoveItem(index)
                                            }
                                          >
                                            Remove
                                          </span>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              <fieldset className="col-md-3 my-3">
                              <button
                              type="submit"
                                className="btn btn-sm hovbuttonColour"
                                onClick={handleSubmit}
                                disabled={
                                  addedItemList.length === 0 ||
                                  outputitemname === "" ||
                                  templatename === "" ||
                                  loader
                                }
                              >
                                Submit
                              </button>
                            </fieldset>
                            </>
                          ) : (
                            ""
                          )}
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(NewProductionTemplate);
