import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {userid, currentTenant, thousandsdisplay, clientname, logo } from '../../Common/Credentials.js';
import InventoryService from '../../inventory/InventoryService.js';
import moment from 'moment'
import { spin } from "../../Common/NewLoader.jsx";
import { inventoryid } from '../../Common/Permissions.js';
import MUIDataTable from "mui-datatables";
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import { NumericFormat } from 'react-number-format';
import { ThemeProvider } from "@mui/material";
import { createTheme, IconButton, Tooltip } from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"
import Swal from 'sweetalert2';


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class CostDataTable extends Component {

  constructor(props) {
    super(props) 
      this.state = {
        cost:[],
        loading:false,
    }
    this.refreshCost=this.refreshCost.bind(this)
    this.checkCid=this.checkCid.bind(this)

  }

  pdf() {
    let pdfdata =  this.state.cost
    pdfdata.map(e=>
      pdfdata.find(i=>i.cid===e.cid).date=moment(pdfdata.find(i=>i.cid===e.cid).date).format('DD-MM-YYYY HH:mm')
       )
       const curdate = moment().format('DD-MM-YYYY HH:mm:ss')
    const columns = [{header:"Item Name",dataKey:'name'},{header:"Date",dataKey:'date'},{header:"Price",dataKey:'rate'},]
    var doc = new jsPDF('p', 'pt', [842, 595]);
    autoTable(doc,{
      theme:'grid',
      startY: 120,
      columnStyles: { 2: { halign: 'right'} },
      didDrawPage: (HookData) => {
        HookData.settings.margin.top = 120;
      },
      body: pdfdata,
      columns:columns,
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;

      doc.addImage(logo, "GIF", 40, 20, 60, 60, { baseline: "top" });
      doc.setFontSize(8);
      doc.text(`${curdate}`,560,20,'right')
      doc.setFontSize(16);
      doc.text(`${clientname}`, 180, 40, { baseline: "top" });
      doc.setFontSize(14);
      doc.text(`Price Details`, 210, 70, { baseline: "top" });
      doc.line(0, 90, 600, 90);
      doc.setFontSize(10);

      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }
    doc.save("PriceTable.pdf");
    this.refreshCost()
  }


  checkCid=(cid)=>{
    if(this.state.cid!==''){
      InventoryService.validateCid(cid)
      .then( 
        response=>{
         this.setState({data:response.data},()=>{
          if(this.state.data !== ''){
            if(this.state.data === false ){
              this.props.navigate({pathname:`/addcost`},{state:{id:cid,pagename:"Edit Price"}})
            } else if(this.state.data === true  ){
              Swal.fire({
                title: `Price cannot be altered as stock is added with this price`,
                text: ".",
                icon: "info",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            }
          }})
        }
      )
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            Swal.fire({
              title: `Your Session is Lost: Please login Back to resume`,
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(()=>this.props.navigate('/'))
        }
    })
    }
    }
  refreshCost(){

    InventoryService.retriveCost(currentTenant)
      .then(
        response => {
            this.setState({cost:response.data.sort((a, b) => a.date < b.date ? 1 : -1)})
            const script = document.createElement("script");
          script.src= 'js/content.js';
          script.async= true;
          document.body.appendChild(script);
          this.setState({loading:false})
          }          
        )
        .catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
      })
  }

  componentDidMount(cid) {
      
    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
     this.setState({loading:true})
     this.refreshCost()
    }

    render() {

      const columns = [
        {name: "cid",label: "Cost id",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "name",label: "Item name",options: {filter: true,sort: true,}},
        {name: "brand",label: "Brand",options: {filter: true,sort: true,}},
        {name: "model",label: "Model/Grade",options: {filter: true,sort: true,}},
        {name: "date",label: "Date",options: {filter: false,sort: true,
          customBodyRender:(tableMeta)=>{
           return(moment(tableMeta).format('DD-MM-YYYY HH:mm'))
          } 
        }},
        {name: "rate",label: "Price",options: {filter: false,sort: true,
          customBodyRender:(tableMeta)=>{
            return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
          },
          setCellProps: value => ({ style: { textAlign:'right'}}),
        }},
        {
          name: "Edit",
          options: {
            filter: false,
            sort: false,
            empty: true,
            display:inventoryid === '2' || inventoryid === '3'?true:false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <span as="button" data-tip data-for="edit" className="fa fa-edit mr-2 cursorPointer textColour" onClick={()=>this.checkCid(tableMeta.rowData.find(e=>e))}
                ></span>
              );
            }
          }
        },
      ]
      const options = {
        filterType: 'checkbox',
        selectableRows: "none",
        customToolbar: () => {
          return (<>
            {inventoryid === '2' || inventoryid === '3'?
              <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/addcost"}} state ={ { id: -1, pagename: 'Add Price' }}>
                <CustomToolbar />
              </Link>
              :''
            }  
             <span as="button" onClick={this.pdf.bind(this)}>
             <React.Fragment>
      <Tooltip title={"pdf"}>
          <IconButton>
          <i className="fa fa-file-pdf"></i>
          </IconButton>
        </Tooltip>
      </React.Fragment>

             </span>
         </>
         );
       }
      }
        return (        
            <div>
      <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              
              <li className="breadcrumb-item active">Price</li>
            </ol>


            <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              
              {/* /.card */}
              <div className="card">
                
                <div className="card-header">
                  <h3 className="card-title">
                    Price
                  </h3>
                </div>
                {/* /.card-header */}
                
                <div className="card-body">
                <div>{this.state.loading === true ? <div className="text-center">{spin}</div> :<>
              <ThemeProvider theme={theme}> 
                <MUIDataTable
                  className="table-sm px-3"
                  title={"Price"}
                  data={this.state.cost}
                  columns={columns}
                  options={options}
                />
                </ThemeProvider>
                </> }</div>
       {/* <table
       id="example1"
       className="table table-bordered table-striped table-sm">
      
       <thead>
         <tr style={{textAlign:"center"}}>
         {inventoryid === '2' || inventoryid === '3' ?
         <th>Action</th> 
          :'' }
         <th>Item Name</th>
         <th>Price </th>
         <th>Date</th>
        
         </tr>
       </thead>
       <tbody>
         {
           this.state.cost.map(
             cost => 
             <tr key={cost.cid} style={{textAlign:"center"}}>
                {inventoryid === '2' || inventoryid === '3' ?
                 <td>
              <Link data-tip data-for="edit" className="fa fa-edit"  style={{paddingLeft:'7px'}} onClick={()=>this.checkCid(cost.cid)}/> 
               //<Tooltip id="edit" place="top" effect="solid">Edit Cost</Tooltip>
               </td> 
               :'' }
               <td>{cost.name}</td>
               <td>{cost.rate}</td>
               <td>{moment(cost.date).format('DD-MM-YYYY HH:mm')}</td>
               
             </tr>
           )
         }
       </tbody>
      
                                      
     </table>   */}
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
    
     </div>
                
    </div>
          
        )      
    }    
}
export default withRouter(CostDataTable)