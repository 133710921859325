import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

export let currentTenant

class SizeService{
    getsize(currentTenant) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallsizes/${currentTenant}`)
     }
     addsize(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addsize`,data)
     }
     deletesize(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletesize/${id}`)
     }
     UploadSizes(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/uploadsizes`,data)
     }
}
export default new SizeService()