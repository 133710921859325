import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid } from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import { FormikTextField } from "../../CommonComponents/FormField.js";
import { Spinner } from "react-bootstrap";
import { Autocomplete } from "@mui/material";
import MasterService from "../MasterService.js";

class AddState extends Component {
  constructor(props) {
    super(props);
    this.state = {
      state: "",
      message: "",
      loader: false,
      countriesList: [],
      country: "",
      countryid: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(values) {
    this.setState({ loader: true });
    let data = {
      name: this.state.state.toUpperCase(),
      country:this.state.countryid
    };
    MasterService.AddState(data)
      .then(() => this.props.navigate("/states"))
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
        this.setState({ message: e.response.data.message, loader: false });
      });
  }

  handleCountryChange = (value, values) => {
    console.log(value,values)
    if (value !== undefined || value !== "" ) {
      this.setState({ country: values.name, countryid: values.id });
    }
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    MasterService.getCountries().then(res=>this.setState({countriesList:res.data,loading:false})) 
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })  
  }

  validate(values) {
    let errors = {};

    if (!values.state || values.state === "") {
      errors.state = "state is Required";
    }
    return errors;
  }

  render() {
    let { id, state, countriesList, country, countryid } = this.state;

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <Link
                  as="button"
                  className=" text-secondary"
                  to={{ pathname: "/states"}} state= {{ message: "" }}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/superadminmasters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/states">States</Link>
              </li>
              <li className="breadcrumb-item active">Add State</li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">Add State</h3>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{ id, state, countriesList, country, countryid }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {() => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset className="col-md-3">
                        <Autocomplete
                          id="controlled-demo"
                          name="country"
                          options={countriesList}
                          getOptionLabel={(option) => option.name ||''}
                          // renderOption={(option) => option.name ||''}
                          // getOptionSelected={(option) => option.name || ''}
                          // value={this.state.country}
                          onChange={(e,values)=>this.handleCountryChange(e.target.value,values)}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              label="Select Country"
                              variant="standard"
                              type="text"
                              name="country"
                              value={this.state.country}
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="state"
                          label="Enter State"
                          onChange={(e) =>
                            this.setState({
                              state: e.target.value,
                              message: "",
                            })
                          }
                        />
                      </fieldset>
                    </div>
                    <div className="form-group col-md-12 text-red">
                      {this.state.message}
                    </div>
                    {this.state.loader === true ? (
                      <button className="btn btn-sm hovbuttonColour" disabled>
                        <Spinner animation="border" size="sm" />
                        &nbsp;{"Saving"}
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm hovbuttonColour"
                        type="submit"
                      >
                        <i className="fas fa-save mr-2" />
                        Save
                      </button>
                    )}
                    <Link
                      as="button"
                      className="btn btn-sm deletebutton ml-1"
                      to={{ pathname: "/states" }}
                    >
                      <b style={{ color: "#FA5733" }}> X</b> Cancel
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default withRouter(AddState);
