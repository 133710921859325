import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

export let currentTenant

class BankServices{
    getBanks(currentTenant) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallbanks/${currentTenant}`)
     }
     addBank(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addbank`,data)
     }

     updateBank(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatebank`,data)
   }
     deleteBank(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletebank/${id}`)
     }

     setActive(id){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/setactive/${id}`)
     }

     Getbankbyid(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getbankbyid/${id}`)
     }

     getActivebank(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getactivebank/${id}`)
     }
}
export default new BankServices()