import React, { Component } from "react";
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Header from "../Component/Header.js";
import Menu from "../Component/Menu.js";
import { currentTenant, currentUser, thousandsdisplay, userid } from "../Common/Credentials";
import NewJournalService from "./NewJournalService";
import { NumericFormat } from "react-number-format";
import { ToWords } from "to-words";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import downloadfiles from "../Common/upload/downloadfiles.js";
import moment from "moment";
import { Spinner } from "react-bootstrap";
import { email } from "@sideway/address";

//let jvtype=1
let gsttype;
class SalaryJournalConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmjv: this.props.location.state,
      loader: false,
    };
    //this.validate=this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.goBack = this.goBack.bind(this);
    this.download = this.download.bind(this);
  }

  // goBack() {
  //   alert("I am here outside")

  //   if(this.state.confirmjv.jvtype===6){
  //     this.props.navigate(`/dtaxes`);
  //   }else{
  //   this.props.navigate(-1);
  //   }
  // }

  onSubmit(values) {
    this.setState({ loader: true });
    let jvtot=[];
    this.state.confirmjv.pendingval.map((e)=>{
      let jv = {
        amount:e.amount,
        contactnumber:e.contactnumber,
        createdby:e.createdby,
        email:e.email,
        empid:e.empid,
        empledgerid:e.empledgerid,
        empname:e.empname,
        expledid:e.expledid,
        expledname:e.expledname,
        id:e.id,
        itemid:e.itemid,
        ledger:e.ledger,
        mainid:e.mainid,
        month:e.month,
        name:e.name,
        pan:e.pan,
        salpaymentStatus:e.salpaymentStatus,
        tenantId:currentTenant,
        updatedby:currentUser,
        year:e.year,
        pmtledgerid:this.state.confirmjv.pmtledgerid,
        pmtledgername:this.state.confirmjv.pmtledgername,
        totalamount:this.state.confirmjv.amount,
        type:this.state.confirmjv.type,
        paymentref:this.state.confirmjv.paymentref,
        creth:this.state.confirmjv.creth,
        jvinsertiontype:this.state.confirmjv.jvinsertiontype
        }

        jvtot.push(jv)
      })

    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    } else {
      NewJournalService.updateSalaryPaid(jvtot)
        .then(() =>
          this.goBack()
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
      // console.log(jvtot)
    }
  }

  componentDidMount() {
   // console.log(this.props)

    gsttype = this.props.location.state.gsttype;
  }

  download() {
    return downloadfiles.download(
      this.state.confirmjv.invoicepath,
      this.state.confirmjv.invoicefile
    );
  }

  componentDidUpdate() {}

  goBack() {
    if (this.state.confirmjv.jvtype === 3) {
      this.props.navigate(`/ap`);
    } else if (this.state.confirmjv.jvtype === 6) {
      this.props.navigate(`/dtaxes`);
    } else if (this.state.confirmjv.jvtype === 7) {
      this.props.navigate(`/lossesl`);
    } else if (this.state.confirmjv.jvtype === 8) {
      this.props.navigate(`/depreciationl`);
    } else if (this.state.confirmjv.entrytype === 12) {
      this.props.navigate(`/dirstock`);
    } else if (this.state.confirmjv.jvtype === 13) {
      this.props.navigate(`/debitcreditnotes`);
    }  else if (this.state.confirmjv.entrytype === 9) {
      this.props.navigate(`/indirtaxes`);
    } else {
      this.props.navigate(-1);
    }
  }

  render() {
    let jv = this.state.confirmjv,
      selectedval; //,new1
    const toWords = new ToWords();
    let amtwords// = toWords.convert(!jv.openadjusted && jv.openadjusted!==undefined?jv.creditamt+jv.openadjusted:jv.creditamt);
    if (jv.poid !== undefined) {
      selectedval = jv.selectedval;
    } else if (jv.poid !== undefined && jv.selectedval !== undefined) {
      //  [...new Set(
      jv.selectedval.map((e) => {
        return (selectedval = e);
      });
      // ),];
    }
    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item active">Confirm Entry</li>
            </ol>

            <div className="text-center text-danger">{this.state.error}</div>
            <div className="card-body">
              <div className="text-center"></div>

              <Formik
                initialValues={{ jv }}
                onSubmit={this.onSubmit}
                validateOnChange={true}
                validate={this.validate}
                enableReinitialize={true}
              >
                {() => (
                  <Form>
<div className="mb-4">
<Table>
  <TableHead>
    <TableRow>
      <TableCell>PMT ID</TableCell>
      <TableCell>Employee</TableCell>
      <TableCell>Debit</TableCell>
      <TableCell>Credit</TableCell>
      <TableCell>Amount</TableCell>
      <TableCell>Receipt</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
{jv.pendingval.map((e)=>
  <TableRow key={e.id}>
      <TableCell>{e.id}</TableCell>
      <TableCell>{e.empname}</TableCell>
      <TableCell>{jv.type===1?e.empname:e.name}</TableCell>
      <TableCell>{jv.pmtledgername}</TableCell>
      <TableCell>
      <NumericFormat
      displayType={"number"} decimalScale={2} fixedDecimalScale={true} disabled={true}
      value={e.amount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell>
      <TableCell>{jv.paymentref}</TableCell>
  </TableRow>)}
  <TableRow>
  <TableCell>Total</TableCell>
  <TableCell></TableCell>
  <TableCell></TableCell>
  <TableCell></TableCell>
  <TableCell><NumericFormat
      displayType={"number"} decimalScale={2} fixedDecimalScale={true} disabled={true}
      value={jv.amount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
  <TableCell></TableCell>
  </TableRow>
  </TableBody>
</Table></div>
                      <div className="form-row">
                        <fieldset className="form-group col-md-12">
                          <button
                            className="btn hovbuttonColour mr-1 btn-sm"
                            type="submit"
                          >
                            <i className="fas fa-pen"></i>
                            {this.state.loader === true ? (
                              <>
                                <Spinner animation="border" size="sm" />
                                &nbsp;{"Confirm"}
                              </>
                            ) : (
                              "Confirm"
                            )}
                          </button>
                          {/* </fieldset>
                  <fieldset className="form-group col-md-2">  */}
                          <button
                            className="btn deletebutton btn-sm"
                            type="button"
                            onClick={this.goBack}
                          >
                           
                            <i className="fas fa-times" />
                            Close
                          </button>
                        </fieldset>
                      </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withRouter(SalaryJournalConfirm);
