import React, { useEffect, useState } from "react";
import withRouter from "../withRouter";
import Menu from "../Component/Menu";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import { Link } from "react-router-dom";
import transactionsIcon from "../images/Icons/transaction.png"
import Credentials from "../Common/Credentials";
import mastersIcom from "../images/Icons/generic.png"

const ProjectManagement = () => {
  useEffect(() => {
    Credentials.setSelectedMenu("Project Management")
  },[])
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Project Management</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
            <div className="row gap-x-8 gap-y-6 mt-0">

            <div className="col-sm-12 col-md-6 col-lg-4 w-full">
              <span className="font-weight-bold my-3">
                {/* <i className="nav-icon fas fas fa-th textColour " /> */}
                <img className="nav-icon" width="25" src={mastersIcom}/>
                <span className="pl-2"> Masters</span>
              </span>
              <ul className=" menuul pl-4 ml-3">
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/estimationparameters" }}
                    state={{ message: "" }}
                  >
                    Estimation Parameters
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 w-full ">
              <span className="font-weight-bold my-3">
              <img className="nav-icon" width="25" src={transactionsIcon}/>
                <span className="pl-2">Transactions</span>
              </span>
              <ul className="menuul pl-4 ml-3">
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/projects" }}
                      state={{ message: "" }}
                    >
                      Projects
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/projectestimation" }}
                      state={{ message: "" }}
                    >
                      Project Estimation
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/projectindenting" }}
                      state={{ message: "" }}
                    >
                      Project Indenting
                    </Link>
                  </li>
              </ul>
            </div>
            </div>
            </div>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default withRouter(ProjectManagement);
