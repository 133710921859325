import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
class WarehouseReport extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.location.state);
    this.state = {
      warehousedata: this.props.location.state.data,
      title: this.props.location.state.item,
      products: [],
      productslist: [],
    };
  }
  Productdata = () => {
    let data = this.props.location.state.data;
    this.setState({ productslist: [], custbyproducts: [] }, () => {
      // Sorting  Products
      const products = data.reduce(
        (
          item,
          {
            itemname,
            itemid,
            category,
            stockitemtype,
            stocktype,
            cost,
            whstock,
            createdAt,
          }
        ) => {
          if (!item[itemname]) item[itemname] = [];
          item[itemname].push({
            itemid: itemid,
            itemname: itemname,
            category: category,
            createdAt: createdAt,
            stockitemtype: stockitemtype,
            stocktype: stocktype,
            cost: cost,
            whstock: whstock,
          });
          return item;
        },
        {}
      );
      this.setState({ products: products });
      // End of Sorting Products

      // Calcuating total products
      Object.entries(products).forEach(([key, value]) => {
        let a = {
          itemname: key,
          itemid: value.map((e) => e.itemid)[0],
          category: value.map((e) => e.category)[0],
          whstock: value.map((e) => e.whstock)[0],
        };
        this.state.productslist.push(a);
      });
      // End of Calcuating total products

      //   // Sorting item  By Warehouse
      //   Object.entries(products).forEach(([key, value]) => {
      //     const custbyproducts = value.reduce(
      //       (
      //         cust,
      //         {
      //           itemname,
      //           itemid,
      //           currentStock,
      //           totalprice,
      //           totalpricewtax,
      //           createdAt,
      //           customer,
      //           custid,
      //         }
      //       ) => {
      //         if (!cust[custid]) cust[custid] = [];
      //         cust[custid].push({
      //           itemid: itemid,
      //           itemname: itemname,
      //           currentStock: currentStock,
      //           totalprice: totalprice,
      //           totalpricewtax: totalpricewtax,
      //           createdAt: createdAt,
      //           customer: customer,
      //           custid: custid,
      //         });
      //         return cust;
      //       },
      //       {}
      //     );
      //     Object.entries(custbyproducts).forEach(([key, value]) => {
      //       let a = {
      //         custid: key,
      //         customer: value.map((e) => e.customer)[0],
      //         itemid: value.map((e) => e.itemid)[0],
      //         itemname: value.map((e) => e.itemname)[0],
      //         currentStock: value.reduce((a, v) => (a = a + v.currentStock), 0),
      //         totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
      //         totalpricewtax: value.reduce(
      //           (a, v) => (a = a + v.totalpricewtax),
      //           0
      //         ),
      //       };
      //       this.state.custbyproducts.push(a);
      //     });
      //   });
      //   //  End of Sorting item sales By Warehouse
    });
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.Productdata();
  }
  render() {
    const columns = [
      {
        name: "itemid",
        label: "ItemId",
        options: {
          filter: false,
          sort: false,
          viewColumns: false,
          display: false,
        },
      },
      {
        name: "itemname",
        label: "Item name",
        options: { filter: false, sort: true },
      },
      {
        name: "category",
        label: "Category",
        options: { filter: true, sort: true },
      },
      //   {
      //     name: "cost",
      //     label: "Unit Cost",
      //     options: {
      //       filter: false, sort: true,
      //       customBodyRender: (value) => {
      //         return (<NumericFormat className="pr-3" displayType={'text'} value={value} decimalScale={2} fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      //       },
      //       setCellProps: value => ({ style: { textAlign: 'right' } }),
      //     }
      //   },
      //   {
      //     name: "whaddress",
      //     label: "Warehouse",
      //     options: { filter: true, sort: true },
      //   },
      //   {
      //     name: "currentStock",
      //     label: "Stock",
      //     options: { filter: false, sort: true },
      //   },
      {
        name: "whstock",
        label: "Org Unit Stock",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <>{value === null || value === undefined ? 0 : value}</>;
          },
        },
      },
      //   {
      //     name: "totalStock",
      //     label: "Total Stock",
      //     options: { filter: false, sort: true, }
      //   },
      //   {
      //     name: "stocktype",
      //     label: "For",
      //     options: {
      //       filter: true, sort: true,
      //     }
      //   },
      //   {
      //     name: "minnotifier",
      //     label: "Inv Min Notifier",
      //     options: {
      //       filter: false, sort: true,
      //       customBodyRender: (value) => {
      //         return (<>{value === null || value === undefined ? "N/A" : value}</>)
      //       },
      //     }
      //   },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    return (
      <div>
        <Header />
        <Menu />
        
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/stockreport">Stock Reports</Link>
              </li>
              <li className="breadcrumb-item active">Org Unit Report</li>
            </ol>

            <div className="card-body">
              <div>
                {this.state.loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <ThemeProvider theme={theme}>
                    <MUIDataTable
                      className="table-sm px-3"
                      title={this.state.title}
                      data={this.state.productslist}
                      columns={columns}
                      options={options}
                    />
                  </ThemeProvider>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(WarehouseReport);
