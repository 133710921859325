import React, { useEffect, useState } from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Menu from "../Component/Menu.js";
import Header from "../Component/Header.js";
import { screens } from "../Common/Products.js";
import UsercustomerService from "../Masters/Usercustomers/UsercustomerService.js";
import SettingsServices from "../Settings/SettingsServices.js";
import Credentials, { currentTenant, transactionFrom, userid } from "../Common/Credentials.js";

import dashboardIcon from "../images/Icons/dashboard.png"
import transactionsIcon from "../images/Icons/transaction.png"
import reportsIcon from "../images/Icons/annual-report.png"
import directtradeIcon from "../images/Icons/agreement.png"
import posIcon from "../images/Icons/pos-terminal.png"
import { Backdrop } from "@mui/material";


const PurchaseMenu = () => {
  const [usercustomers,setUsercustomers] = useState(false)
  useEffect(()=>{
      Credentials.setSelectedMenu("Purchase")
    UsercustomerService.GetUserCustomersbyUser(userid).then((res)=>setUsercustomers(res.data===''||res.data===undefined|| res.data===null?false:true))
  },[])
  return (
    <div>
      <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={transactionFrom === null}
      // onClick={handleClose}
    >
       <div className="flex-column ">
      <div className="d-flex justify-content-center">
      <i className="text-red">Please set transaction start date in order to continue.</i>
      </div>
      <div className="d-flex justify-content-center">
      <Link to="/settings" className="btn btn-sm hovbuttonColour">Set</Link>
      </div>
      </div>
    
    </Backdrop>
      <Header />
      <Menu />
      <section className="content-wrapper">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item text-secondary">
            <Link to="/index">Home</Link>
          </li>
          <li className="breadcrumb-item active">Purchase</li>
        </ol>
        <div className="h-max border-slate-300 bg-white p-4 rounded-lg ">
        <ul className="d-flex flex-row list-unstyled">
              <li className="p-1 mr-4">
                <Link
                  to={{ pathname: "/index" }}
                  state={{ message: "" }}
                  className="textColour py-1"
                >
                   <img className="nav-icon mr-2" width="25" src={dashboardIcon}/>
                  Dashboard
                </Link>
              </li>
              {screens.includes("Direct Trade") ? (
                <li>
                <Link
                  to={{ pathname: "/directtrade" }}
                  state={{ message: "" }}
                  className="textColour py-1"
                >
                  <img className="nav-icon mr-2" width="25" src={directtradeIcon}/>
                  Direct Trade 
                </Link>
                </li>
              ) : (
                ""
              )}
            </ul>
          <div className="row  gap-x-8 gap-y-6">
          {(screens.includes("Purchase")|| screens.includes("Purchase Returns") || screens.includes("GRN") || screens.includes("SCN") || screens.includes("Bill of Entry") || screens.includes("Credit Note")) ?
            <div className="col-sm-12 col-md-6 col-lg-4 w-full">
              <span className="font-weight-bold my-3">
              <img className="nav-icon" width="25" src={transactionsIcon}/>
                <span className="pl-2">Transactions</span>
              </span>
              <ul className="menuul pl-4 ml-3">
              {screens.includes("Purchase") || screens.includes("Purchase Order") ? 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{pathname: "/purchaseorder"}}
                      state= {{ message: ""}}
                  >
                    Purchase Order
                  </Link>
                </li>:""}
                {screens.includes("GRN") ? 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/grn" }}
                    state={{ message: "" }}
                  >
                    GRN
                  </Link>
                </li>:""}
                {screens.includes("SCN") ? 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/scn" }}
                    state={{ message: "" }}
                  >
                    SCN
                  </Link>
                </li>:""}
                {screens.includes("Purchase Returns") ? 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/purchasereturns" }}
                    state={{ message: "" }}
                  >
                    Purchase Returns
                  </Link>
                </li>
                :""}
                {screens.includes("Bill of Entry") ? 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/billofentry" }}
                    state={{ message: "" }}
                  >
                    Bill of Entry
                  </Link>
                </li>:""}
                {screens.includes("Credit Note")?
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/debitnotes" }}
                    state={{ message: "" }}
                  >
                    Debit Note
                  </Link>
                </li>
                :""}
              </ul>
            </div>:""}
            {screens.includes("Purchase Reports")?
            <div className="w-full col-sm-12 col-md-6 col-lg-4">
              <span className="font-weight-bold my-3 ">
              <img className="nav-icon" width="25" src={reportsIcon}/>
                <span className="pl-2"> Reports</span>
              </span>
              <ul className="menuul pl-4 ml-3">
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/purchasereport" }}
                    state={{ message: "" }}
                  >
                    Purchase Report
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/purchasereturnsreport" }}
                    state={{ message: "" }}
                  >
                    Purchase Returns Report
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/purchaseagentsreports" }}
                    state={{ message: "" }}
                  >
                    Agents Report
                  </Link>
                </li>
              </ul>
            </div>
            :""}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default withRouter(PurchaseMenu);
