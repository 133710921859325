import React, { useEffect, useState } from "react";
import GRNServices from "../../../Purchase/GRN/GRNServices";
import {
  currentTenant,
  financialyearend,
  financialyearstart,
  thousandsdisplay,
  userid,
} from "../../../Common/Credentials";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Collapse,
  IconButton,
} from "@mui/material";
import { Col } from "react-bootstrap";
import moment from "moment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";

const Payables = ({ data, poloader, theme }) => {
  const [grns, setGrns] = useState([]);
  const [supplierlist, setSupplierlist] = useState([]);
  const [page, setPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalDue, setTotalDue] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
      userid: userid,
      dataaccess: "All",
    };
    GRNServices.retriveAllActiveGRN(datadao).then((res) => {
      setGrns(res.data);
      const suppliers = res.data.reduce(
        (
          sup,
          {
            customgrnid,
            custompoid,
            grnid,
            poid,
            balance,
            totalprice,
            supplier,
            supplierid,
            createdAt,
          }
        ) => {
          if (!sup[supplier]) sup[supplier] = [];
          sup[supplier].push({
            grnid: grnid,
            customgrnid: customgrnid,
            custompoid: custompoid,
            grnid: grnid,
            poid: poid,
            balance: balance,
            totalprice: totalprice,
            supplier: supplier,
            supplierid: supplierid,
            createdAt: createdAt,
          });
          return sup;
        },
        {}
      );
      let list = [];
      Object.entries(suppliers).forEach(([key, value]) => {
        let a = {
          supplier: key,
          supplierid: value.map((e) => e.supplierid)[0],
          balance: value.reduce((a, v) => (a = a + v.balance), 0),
          //   overdue: value.reduce(
          //     (a, v) =>
          //       (a =
          //         a +
          //         (moment(v.createdAt, "YYYY-MM-DD")
          //           .diff(moment(new Date(), "YYYY-MM-DD"), "days") < 0
          //           ? v.balance
          //           : 0)),
          //     0
          //   ),
          totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
          //   invoices: suppliers[key],
        };
        list.push(a);
      });
      setTotalAmount(list.reduce((a, v) => (a = a + v.totalprice), 0));
      setTotalDue(list.reduce((a, v) => (a = a + v.balance), 0));
      setSupplierlist(list);
      console.log(list);
    });
  }, []);

  const handleChangePage = (event, newPage) => {
    setPages(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPages(0);
  };

  return (
    <div>
      <Col sm={12} lg={12}>
        <Paper>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Typography className="px-4 pt-3">Payables</Typography>
            <Table
              stickyHeader
              aria-label="sticky table"
              size="small"
              className={` mb-4 ${theme === false ? "" : "bg-black5"}`}
            >
              <TableHead className={`${theme === false ? "" : "bg-black5"}`}>
                <TableRow>
                  <TableCell />
                  <TableCell>Supplier</TableCell>
                  <TableCell align="right" className="pr-3">Due</TableCell>
                  <TableCell align="right" className="pr-3">Total</TableCell>
                  {/* <TableCell align="center">Over Dues</TableCell> */}
                </TableRow>
                <TableRow>
                  <TableCell className="bg-yellow">Total</TableCell>
                  <TableCell />
                  <TableCell align="right" className="text-red">
                    <NumericFormat
                      displayType={"text"}
                      value={totalDue}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      disabled={true}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat>
                  </TableCell>
                  <TableCell align="right">
                    <NumericFormat
                      displayType={"text"}
                      value={totalAmount}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      disabled={true}
                      thousandSeparator={true}
                      thousandsGroupStyle={thousandsdisplay}
                    ></NumericFormat>
                  </TableCell>
                  {/* <TableCell align="center">Over Dues</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {supplierlist
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((e, i) => (
                    <GRNlist key={i} row={e} data={grns} />
                  ))}
                
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={supplierlist.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Col>
    </div>
  );
};

function GRNlist(props) {
  const { row, data } = props;
  const [open, setOpen] = React.useState(false);
  

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{row.supplier}</TableCell>
        <TableCell align="right" className="text-red">
          <NumericFormat
            displayType={"text"}
            value={row.balance}
            decimalScale={2}
            fixedDecimalScale={true}
            disabled={true}
            thousandSeparator={true}
            thousandsGroupStyle={thousandsdisplay}
          ></NumericFormat>
        </TableCell>
        <TableCell align="right">
          <NumericFormat
            displayType={"text"}
            value={row.totalprice}
            decimalScale={2}
            fixedDecimalScale={true}
            disabled={true}
            thousandSeparator={true}
            thousandsGroupStyle={thousandsdisplay}
          ></NumericFormat>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            padding: 0,
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* <Box> */}
            {/* <Typography variant="h6" gutterBottom component="div">
                  Invoices
                </Typography> */}
            <Table size="small" aria-label="purchases" className="border">
              <TableHead>
                <TableRow>
                  <TableCell>GRN Id</TableCell>
                  <TableCell>Purchase Order Id</TableCell>
                  <TableCell>Balance</TableCell>
                  <TableCell>Total price</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Elapsed</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((historyRow, index) => (
                  historyRow.supplierid === row.supplierid && historyRow.balance !== 0?
                    <TableRow
                      style={{
                        background: `${
                          moment(historyRow.createdAt, "YYYY-MM-DD").diff(
                            moment(new Date(), "YYYY-MM-DD"),
                            "days"
                          ) < 0
                            ? "#ffde1638"
                            : ""
                        }`,
                      }}
                      key={index}
                    >
                      <TableCell component="th" scope="row">
                        <Link
                          to={{ pathname: "/viewgrnitems" }}
                          state={{
                            id: historyRow.customgrnid,
                            temp: false,
                          }}
                        >
                          {historyRow.customgrnid}
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          to={{ pathname: "/viewpurchaseorder" }}
                          state={{ id: historyRow.custompoid }}
                        >
                          {historyRow.custompoid}
                        </Link>
                      </TableCell>
                      <TableCell align="right" className="text-red">
                        <NumericFormat
                          className="pr-3"
                          displayType={"text"}
                          value={historyRow.balance}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          disabled={true}
                          thousandSeparator={true}
                          thousandsGroupStyle={thousandsdisplay}
                        ></NumericFormat>
                      </TableCell>
                      <TableCell align="right">
                        <NumericFormat
                          className="pr-3"
                          displayType={"text"}
                          value={historyRow.totalprice}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          disabled={true}
                          thousandSeparator={true}
                          thousandsGroupStyle={thousandsdisplay}
                        ></NumericFormat>
                      </TableCell>

                      <TableCell align="center">
                        {moment(historyRow.createdAt).format(
                          "DD-MM-YYYY HH:mm:ss"
                        )}
                      </TableCell>

                      <TableCell align="center">
                        {moment(historyRow.createdAt, "YYYY-MM-DD").diff(
                          moment(new Date(), "YYYY-MM-DD"),
                          "days"
                        )}
                      </TableCell>
                    </TableRow>
                    :""
                  ))}
              </TableBody>
            </Table>
            {/* </Box> */}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default Payables;
