import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import MasterService from './MasterService.js';
import { userid } from "../Common/Credentials.js";
import { spin } from '../Common/NewLoader'
import moment from 'moment';
import CustomToolbar from '../CommonComponents/CustomToolbar.js';
import MUIDataTable from 'mui-datatables';


import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});
class MasterDataTable extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        tenants:[ ],
        loading:false

    }
    this.refreshTenants=this.refreshTenants.bind(this)
    this.deleteTenant=this.deleteTenant.bind(this)
  }



  deleteTenant(id){
   MasterService.deleteTenant(id)
   .then(
    resposne => {
     this.setState({message:`Delete of Todo ${id} Successfull`})
        this.refreshTenants()
    }
).catch((e)=>{
  if(e.response.data.error ==="Unauthorized"){
      alert("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
  }
})

  
}



  
  refreshTenants(){

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }

    this.setState({loading:true})
    MasterService.retriveAllTenants()
    .then(
      response => {
          this.setState({tenants:response.data})
          const script = document.createElement("script");
        script.src= '../js/content.js';
        script.async= true;
        document.body.appendChild(script);
        this.setState({loading:false})
        }     
      )
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
  }

    componentDidMount () {
       

   this.refreshTenants();
      
    }

    render() {

      const columns = [
        {
          name: "id",
          label: "Client Id",
          options: {
            filter: true,
            sort: true,
            // sortOrder: "asc",
            display:false,viewColumns:false
          },
        },
        {
          name: "companyname",
          label: "Company",
          options: { filter: false, sort: true },
        },
        {
          name: "contactname",
          label: "Contact",
          options: { filter: false, sort: true },
        },
        {
          name: "contactnumber",
          label: "Number",
          options: { filter: false, sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
            return (
                <div>+{this.state.tenants.find((e) => e.id === tableMeta.rowData.find((e) => e)).phonecode} {value}</div>
            )
          },
        },
        },
        { name: "email", label: "Email", options: { filter: false, sort: true } },
        {
          name: "prefix",
          label: "Prefix",
          options: { filter: true, sort: true },
        },
        {
          name: "city",
          label: "City",
          options: {
            filter: false,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                  <div>{this.state.tenants.find((e) => e.id === tableMeta.rowData.find((e) => e)).city.name}</div>
              )
            },
          },
        },
        {name: "createdAt",label: "Date",options: {filter: false,sort: false,sortOrder: 'asc',  display:false,viewColumns:false,
        customBodyRender:(tableMeta)=>{
          return(moment(tableMeta).format('DD-MM-YYYY'))
        }}},
        { name: "clientactive", label: "Status", options: { filter: false, sort: true } },
        {
          name: "Actions",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return tableMeta.rowData.find((e) => e) !== userid ? (<>
                <Link
                  as="button"
                  data-tip
                  data-for="edit"
                  className="fa fa-list-alt"
                  to={{pathname: "/tenantdetails"}}
                    state= {{
                      id: tableMeta.rowData.find((e) => e),
                      data: this.state.tenants.find(t=>t.id === tableMeta.rowData.find((e) => e)),
                  }}
                ></Link>
                {/* <Link
                  as="button"
                  data-tip
                  data-for="edit"
                  className="fa fa-edit"
                  to={{
                    pathname: "/newtenant",
                    state: {
                      id: tableMeta.rowData.find((e) => e),
                      name: 'Update Tenant',
                    },
                  }}
                ></Link>
                 <Link
                    as="button"
                    data-tip
                    data-for="delete"
                    className="fa fa-trash text-danger"
                    onClick={() => {
                      if (
                        window.confirm(
                          `Are you sure you wish to delete Tenant ${tableMeta.rowData.find(
                            (e, i) => i.valueOf(e)
                          )}?`
                        )
                      )
                        this.deleteTenant(tableMeta.rowData.find((e) => e));
                    }}
                  ></Link> */}
                  </>
              ) : (
                "NA"
              );
            },
          },
        },
      ];
  
      const options = {
        // filterType: "checkbox",
        selectableRows: "none",
        customToolbar: () => {
          return (
            <>
                <Link
                  as="button"
                  className="float-right btn btn-sm m-0 p-0"
                  to={{
                    pathname: "/newtenant"}}
                    state= {{ id: -1, name: 'New Tenant' }}
                >
                  <CustomToolbar />
                </Link>
            </>
          );
        },
      };
  
     
        return (
            <div>

      <div className="card card-info">
            {/* <div className="card-header">
              <h3 className="card-title">Tenants</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/superadminmasters">Home</Link>
              </li>
              
              <li className="breadcrumb-item active">Tenants</li>
            </ol>


            <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              
              {/* /.card */}
              <div className="card">
                
                {/* <div className="card-header">
                  <h3 className="card-title">
                    Tenants
                  </h3>
                </div> */}
                {/* /.card-header */}
                
                <div className="card-body">
                <div>
                <div>{this.state.loading === true ?<div  className="text-center">{spin}</div> :
                <ThemeProvider theme={theme}>
                <MUIDataTable
                className="table-sm px-3"
                title={"Tenants"}
                data={this.state.tenants}
                columns={columns}
                options={options}
              />
              </ThemeProvider>
              }</div>  
                </div>
             
                </div>
                {/* /.card-body */}
              </div>
              {/* /.card */}
            </div>
            {/* /.col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>


        
     </div>
                
            </div>
             
          
        )
       
    }
    
}
export default withRouter(MasterDataTable)