import axios from "axios";
import { Auth_Proto, Auth_Domain, Auth_Port } from "../../Component/consts";
export let currentTenant;

class CustomerTermsServices {
  // Get all Customers
  retriveAllCustomers(tenantId) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/findcustomers/${tenantId}`
    );
  }

  // Get All Customers Terms Data
  retriveCustomerTerms(tenantId) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getallcustomerterms/${tenantId}`
    );
  }

  // add new customerTerm
  NewCustomerTerms(customerterms) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/addcustomerterms`,
      customerterms
    );
  }

  //Get Customer Terms
  GetCustomerTerms(id) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getcustomerterms/${id}`
    );
  }

  //Get Customer Terms
  GetCustomerTermsById(id) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getcustomertermsbyid/${id}`
    );
  }

  // Update Customer Terms
  UpdateCustomerTerms(customerterms) {
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/updatecustomerterms`,
      customerterms
    );
  }

  //Get All Credit Requests
  GetAllRequestCredits(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getallrequestcredit/${currentTenant}`
    );
  }

  //Get Credit Requests by Customer
  GetAllRequestCreditsByCustomer(id) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getrequestcreditbyid/${id}`
    );
  }

  UpdateRequestedCredit(data) {
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/updatecreditrequest`,
      data
    );
  }
  CancelRequestedCredit(id) {
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/cancelcreditrequest/${id}`,
    );
  }
}

export default new CustomerTermsServices();
