import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../Component/consts'

export let currentTenant
class UserService{
    
   

     retriveAllUsers(TenantID){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/getallusers`)
      }

      retriveAuthUsers(){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/auth/getauthuser`)
      }

      changepassword(details){
         return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/changepassword`,details)

      }

      retriveUser(id){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/getuser/${id}`)
      }

      getRoles(){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/getroles`)
      }
     
      DeleteAuthRoles(id){
         return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/deleterole/${id}`)
      }
     

      createUsers(newusers){
         return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/newuser`,newusers)
      }

      updateUsers (updateusers){
         return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/updateuser`,updateusers)
      }

      deleteUser(id){
         return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/remuser/${id}`)
      }

      ActiveInActiveUser(id){
         return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/inactiveuser/${id}`)
      }

      Resetpassword(id){
         return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/resetpassword/${id}`,)
      }

      getProducts(currentTenant){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getpeonly/${currentTenant}`)
      }

      UserPermissions(id){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/getpermission/${id}`)
      }

      Userwhanddpt(currentTenant){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/getusers/${currentTenant}`)
      }

      FindAllManagers(currentTenant){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/getallmanagers/${currentTenant}`)
      }

    settenantid(TenantID){
      currentTenant = TenantID
    }
}

export default new UserService();
