import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  dataaccess,
  financialyearend,
  financialyearstart,
  thousandsdisplay,
  userid,
  userwhid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import moment from "moment";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import "date-fns";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {
    IconButton,
    Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
  Box, Collapse, Tooltip, Button
} from "@mui/material";
import InventoryService from "../InventoryService";
import SalesServices from "../../Sales/SalesServices.js";
import ProductionServices from "../../Production/ProductionServices.js";
import { NumericFormat } from "react-number-format";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import * as XLSX from 'xlsx';

class ConsumptionReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
        loading: false,
        filteredbatchstockdata:[],
        batchstockdata:[],
        salesconsumptiondata:[],
        productionconsumptiondata:[],
        page: 0,
        rowsPerPage:10,
        fromdate:moment(new Date()).subtract(14,'days'),
        todate:new Date(),
        showsearchbar:false,
        search:""
    }
  }

  exportToExcel=()=> {
    var data = []
    const selectedColumns = ["name","custombatchid","supplier","addedStock","currentStock","stocktype","rate","stockfrom","grnid","waddress","date"] // Specify the columns to select

 data = this.state.filteredbatchstockdata.map((item) => {
  const selectedItem = {};
  selectedColumns.forEach((column) => {
    if (item.hasOwnProperty(column)) {
      selectedItem[column] = item[column];
    }
  });
  return selectedItem;
});
console.log(data)

    let header = ["Item/Product","Batch","Supplier","Added Stock","Current Stock","Stock Type","Price","Stock From","Purchase Order Id/ GRN Id","Warehouse","Date",];
    var ws = XLSX.utils.json_to_sheet(data);

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");
    XLSX.utils.book_append_sheet(wb, ws, "People2");

    /* write workbook (use type 'binary') */
    // XLSX is a ZIP-based format,
    //  Attempts to write a ZIP-based format as type "string" are explicitly blocked due to various issues with getting other browser APIs to play nice with the string.
    //   The "string" output format is primarily for text-based formats like HTML and CSV where you don't want to convert Chinese and other characters back to raw bytes.
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    /* generate a download */
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
    // The preference for application/octet-stream probably has to do with IE6 compatibility.
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "Consumption Report.xlsx"
    );
  }

    handleFromDateChange = (date) => {
        this.setState({ fromdate: date });
    };

    Search = (val) =>{
        this.setState({
            search:val,
            filteredbatchstockdata:this.state.batchstockdata.filter(e=>e.name.toLowerCase().includes(val.toLowerCase()) || e.custombatchid?.toLowerCase().includes(val.toLowerCase()) )
        })
    }

  Submit = () => {
    this.Refresh(this.state.fromdate,this.state.todate)
  }


//   FilterdataBydate = () => {
//       let data = this.state.reportsfiltereddata
//       data.filter((e) =>(data.find((a) => a.createdAt === e.createdAt).createdAt = moment(e.createdAt).format("YYYY-MM-DD")))
//     this.setState({ filterbydate:data })
//   };

handleFromDateChange = (date) => {
    this.setState({ fromdate: moment(new Date(date))});
  };
  handleToDateChange = (date) =>{
    this.setState({ todate: moment(new Date(date))});
  }

  handleChangePage = (event, newPage) => {
    this.setState({page:newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage:+event.target.value,page:0});
  };


Refresh = (start,end) =>{
    
   let datadao = {
    tenantid:currentTenant,
    startdate:start,
    enddate:end,
    userid:userid,
    dataaccess:dataaccess,
    whid:userwhid,
    id:"",
    itemid:null
   }

   InventoryService.getbyitemforconsumption(datadao).then((response) =>{
    this.setState({filteredbatchstockdata: response.data.sort((a, b) => (a.batchid > b.batchid ? 1 : -1)) , batchstockdata: response.data.sort((a, b) => (a.batchid > b.batchid ? 1 : -1)),loading:false})

    let datadao2 = {
      tenantid:currentTenant,
      startdate:start,
      enddate:end,
      userid:userid,
      dataaccess:dataaccess,
      whid:userwhid,
      id:"",
      itemid:null,
      list:response.data.map(e=>e.batchid)
     }
    SalesServices.getdelivereditems(datadao2).then((response) =>
      this.setState({salesconsumptiondata: response.data})
    )

    ProductionServices.Storeaddedquantity(datadao2).then((response) =>
      this.setState({productionconsumptiondata: response.data})
    )

  })

  this.setState({search:"",showsearchbar:false})

}

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.Refresh(moment(new Date()).subtract(14,'days'),moment(new Date()).add(1,'days'))
  }

  render() {
    
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item active">Consumption Report</li>
            </ol>
            <div className="card-body">
              <div>
                {this.state.loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (
                    <>
                    <div className="row">
                     <fieldset className="mr-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      variant="inline"
                      id="date-picker-dialog"
                      label="Slect From Date"
                      name="from"
                      format="dd-MM-yyyy"
                      maxDate={new Date()}
                      value={this.state.fromdate}
                      onChange={this.handleFromDateChange}
                      renderInput={(params) => <TextField {...params} variant="standard"/>}
                      autoOk={true}
                    />
                  </Grid>
                </LocalizationProvider>
              </fieldset>

              <fieldset className="mr-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container justifyContent="space-around">
                    <DatePicker
                      variant="inline"
                      id="date-picker-dialog"
                      label="Slect To Date"
                      name="to"
                      format="DD-MM-YYYY"
                      maxDate={new Date()}
                      minDate={this.state.fromdate}
                      value={this.state.todate}
                      onChange={this.handleToDateChange}
                      renderInput={(params) => <TextField {...params} variant="standard"/>}
                      autoOk={true}
                    />
                  </Grid>
                </LocalizationProvider>
              </fieldset>
              <fieldset>
                <button className="btn btn-sm hovbuttonColour" style={{marginTop:"16px"}} onClick={this.Submit}>
                  Apply
                </button>
              </fieldset>
              </div>
                    <Paper sx={{ width: '100%', overflow: 'hidden',marginTop:'5px' }}>
                      <TableContainer sx={{ maxHeight: 500 }}>
                        <Typography className="px-5 pt-3 d-flex justify-content-between">
                            <h5> Consumption Report</h5>
                           <div>
                              <Button onClick={this.exportToExcel}><CloudDownloadIcon/></Button>
                               {this.state.showsearchbar?<div>
                            <TextField className="mr-2" placeholder="Search" value={this.state.search} onChange={(e)=>this.Search(e.target.value)} id="standard-basic" variant="standard"  />
                            <span className="cursorPointer" onClick={()=>this.setState({showsearchbar:false,filteredbatchstockdata:this.state.batchstockdata,search:""})}>
                            <Tooltip title="Search">
                            <CloseIcon/>
                            </Tooltip>
                            </span>
                            </div>
                            :
                            <span className="cursorPointer" onClick={()=>this.setState({showsearchbar:true})}>
                            <Tooltip title="Search">
                            <SearchIcon/>
                            </Tooltip>
                            </span>
                            }
                            </div>
                            </Typography>
                          <Table size="small"  stickyHeader aria-label="sticky table">
                            <TableHead>
                              <TableRow>
                                <TableCell />
                                <TableCell>Item/Product</TableCell>
                                <TableCell>Batch</TableCell>
                                <TableCell>Supplier</TableCell>
                                <TableCell>Added Stock</TableCell>
                                <TableCell>Current Stock</TableCell>
                                <TableCell>Stock Type</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Stock From</TableCell>
                                <TableCell>Purchase Order Id/ GRN Id</TableCell>
                                <TableCell>Warehouse</TableCell>
                                <TableCell>Date</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.filteredbatchstockdata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row,idx) => (
                                <Row key={idx} row={row} salesconsumptiondata={this.state.salesconsumptiondata.filter(e=>e.batchid === row.batchid)} 
                                  productionconsumptiondata = {this.state.productionconsumptiondata.filter(e=>e.batchid === row.batchid)}
                                />
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <TablePagination
                          rowsPerPageOptions={[10, 25, 100]}
                          component="div"
                          count={this.state.filteredbatchstockdata.length}
                          rowsPerPage={this.state.rowsPerPage}
                          page={this.state.page}
                          onPageChange={this.handleChangePage}
                          onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Paper>
                    </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}


function Row(props) {
    const { row } = props;
    const {salesconsumptiondata} = props;
    const {productionconsumptiondata} =props;
    const [open, setOpen] = React.useState(false);
    
  
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} className={`${open?"bg-light":""}`}>
          <TableCell>
            {salesconsumptiondata.length > 0 || productionconsumptiondata.length>0 ?
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            :""}
          </TableCell>
          <TableCell component="th" scope="row">
            {row.name}
          </TableCell>
          <TableCell>{row.custombatchid}</TableCell>
          <TableCell>{row.stockfrom === "Production"?"Production": row.supplier===undefined||row.supplier === null || row.supplier===""?"Opening":row.supplier}</TableCell>
          <TableCell>{row.addedStock}</TableCell>
          <TableCell>{row.currentStock}</TableCell>
          <TableCell>{row.stocktype}</TableCell>
          <TableCell>
              <NumericFormat
                  className="pr-3"
                  displayType={"text"}
                  value={row.rate}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={true}
                  thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay}
                  ></NumericFormat>
            </TableCell>
            <TableCell>{row.stockfrom}</TableCell>
            <TableCell>{row.stockfrom === "Purchase"? row.poid +" / "+row.grnid :""}</TableCell>
            <TableCell>{row.waddress}</TableCell>
          <TableCell>{moment(row.date).format("DD-MM-YYYY HH:mm")}</TableCell>
        </TableRow>
        <TableRow  className={`${open?"bg-light":""}`}>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Consumption In </TableCell>
                      {salesconsumptiondata.length>0?<>
                      <TableCell>Customer</TableCell>
                      <TableCell>Invoice Id/No</TableCell>
                      <TableCell>Delivery Note</TableCell>
                      </>:""}
                      {productionconsumptiondata.length>0?<>
                      <TableCell>Store</TableCell>
                      <TableCell>Workcenter/Production Unit</TableCell>
                      </>:""}
                      <TableCell>Quantity</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Consumption Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {salesconsumptiondata?.map((historyRow,i) => (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          Sales
                        </TableCell>
                        <TableCell>{historyRow.customer}</TableCell>
                        <TableCell>{historyRow.invid}</TableCell>
                        <TableCell>{historyRow.dnid}</TableCell>
                        {productionconsumptiondata.length>0?<>
                        <TableCell>{historyRow.store}</TableCell>
                        <TableCell>{historyRow.workcenter}</TableCell>
                        </>:""}
                        <TableCell>{historyRow.quantity}</TableCell>
                        <TableCell>
                        <NumericFormat
                            className="pr-3"
                            displayType={"text"}
                            value={historyRow.price}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            disabled={true}
                            thousandSeparator={true}
                            thousandsGroupStyle={thousandsdisplay}
                          ></NumericFormat>
                        </TableCell>
                        <TableCell>{moment(historyRow.date).format("DD-MM-YYYY HH:mm")}</TableCell>
                      </TableRow>
                    ))}
                    {productionconsumptiondata?.map((historyRow,i) => (
                      <TableRow key={i}>
                        <TableCell component="th" scope="row">
                          Production
                        </TableCell>
                        {salesconsumptiondata.length>0?<>
                        <TableCell>{historyRow.customer}</TableCell>
                        <TableCell>{historyRow.invid}</TableCell>
                        <TableCell>{historyRow.dnid}</TableCell>
                        </>:""}
                        <TableCell>{historyRow.store}</TableCell>
                        <TableCell>{historyRow.workcenter}</TableCell>
                        <TableCell>{historyRow.quantity}</TableCell>
                        <TableCell>
                        <NumericFormat
                            className="pr-3"
                            displayType={"text"}
                            value={historyRow.price}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            disabled={true}
                            thousandSeparator={true}
                            thousandsGroupStyle={thousandsdisplay}
                          ></NumericFormat>
                        </TableCell>
                        <TableCell>{moment(historyRow.date).format("DD-MM-YYYY HH:mm")}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  

export default withRouter(ConsumptionReport);
