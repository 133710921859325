import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  clientname,
  logo,
  userid,
} from "../../Common/Credentials";
// import moment from "moment";
import { spin } from "../../Common/NewLoader";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import withRouter from "../../withRouter";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class IndentIssuedDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data:this.props.location.state,
    };
  }

  pdf = () => {
    let pdfdata = this.state.active;
    pdfdata.map(
      (e) =>
        (pdfdata.find((i) => i.id === e.id).createdAt = moment(
          pdfdata.find((i) => i.id === e.id).createdAt
        ).format("DD-MM-YYYY hh:mm"))
    );
    pdfdata.map(
      (e) =>
        (pdfdata.find((i) => i.id === e.id).startdate = moment(
          pdfdata.find((i) => i.id === e.id).startdate
        ).format("DD-MM-YYYY"))
    );
    const columns = [
      { header: "Estimation Id", dataKey: "id" },
      { header: "SaleOrder Id", dataKey: "soid" },
      { header: "Workcenter", dataKey: "workcenter" },
      { header: "Type", dataKey: "estimationtype" },
      { header: "Status", dataKey: "status" },
      { header: "Satrt Date", dataKey: "startdate" },
      { header: "Duration", dataKey: "duration" },
      { header: "Date", dataKey: "createdAt" },
    ];
    var doc = new jsPDF("p", "pt");
    doc.addImage(logo, "GIF", 40, 20, 60, 60);
    doc.setFontSize(16);
    doc.setFont("Times-Roman");
    doc.text(`${clientname}`, 180, 40);
    doc.setFontSize(14);
    doc.text(`Active Sale Orders`, 210, 80);
    doc.setFontSize(8);
    doc.text(`${moment().format("DD-MM-YYYY hh:mm")}`, 510, 80);
    doc.line(0, 90, 600, 90);
    autoTable(doc, {
      theme: "grid",
      startY: 100,
      columnStyles: { 7: { halign: "right" } },
      body: pdfdata,
      columns: columns,
    });
    doc.save("ActiveSaleordes.pdf");
    this.refresh();
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
  }

  render() {
    const columns = [
      {
        name: "itemname",
        label: "Item Name",
        options: { filter: true, sort: true },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <span
                  as="button"
                  className=" text-secondary"
                  onClick={()=>this.props.navigate(-1)}
                >
                  ❮ Back &nbsp;&nbsp;
                </span>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/store">Store</Link>
              </li>
              <li className="breadcrumb-item active">Indent Issued Details</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title"> Indent Issued Details</h3>
                      </div>

                      <div className="card-body">
                        {this.state.loading === true ? (
                          <span className="text-center">{spin}</span>
                        ) : (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={`${this.state.data.id} - Issued Items`}
                              data={this.state.data.indentissueitems}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                        )}
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(IndentIssuedDetails);
