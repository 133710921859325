import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

class Employeeservice{
    getAllEmployees(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallemployeesoftenant/${id}`,)
     }
     AddEmployee(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addemployee`,data)
     }

     UpdateEmployee(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateemployee`,data)
     }

     deleteEmployee(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deleteemployee/${id}`)
     }

     getEmployeeById(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getemployeebyid/${id}`,)
     }

     getEmployeesBankdetailsbyid(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getemployeebankdetailsbyempid/${id}`,)
      }

      AddEmployeeBankDetails(data){
         return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addemployeebankdetails`,data)
      }

      UpdateEmployeeBankDetails(data){
         return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateemployeebankdetails`,data)
      }
     
}
export default new Employeeservice()