import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { currentTenant, currentUser } from "../../Common/Credentials";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2"
import { Autocomplete, Grid } from "@mui/material";
import CustomerService from "../../Masters/Customers/CustomerService";

export const TourMaster = (props) => {
  const [age, setAge] = useState("None");
  const [docdate,setDocdate] =useState('');
  const [docno,setDocno] =useState('');
  const [dept,setDept]=useState('');
  const [fileno,setFileno]=useState('');
  const [costcenter,setCostcenter]=useState('');
  const [advancereceipt,setAdvancereceipt]=useState('');
  const [againstdocno,setAgainstdocno]=useState('');
  const [reference,setReference]=useState('');
  const [customercode,setCustomercode]=useState('');
  const [customername,setCustomername]=useState('');
  const [customerroe,setCustomerroe]=useState('');
  const [supcustomercode,setSupcustomercode]=useState('');
  const [supcustomername,setSupcustomername]=useState('');
  const [originalamt,setOriginalamt]=useState('');
  const [salesmancode,setSalesmancode]=useState('');
  const [salesmanname,setSalesmanname]=useState('');
  const [unmatchedamt,setUnmatchedamt]=useState('');
  const [narration,setNarration]=useState('');
  const [invoicefootnote,setInvoicefootnote]=useState('');
  const [preprintedpapper,setPreprintedpapper]=useState(true);
  const [createdby,setCreatedby]=useState('');
  const [updatedby,setUpdatedby]=useState('');
  const [masterstore,setMasterstore]=useState([]);
  const [custData,setCustData]=useState([]);
  const nevigate =useNavigate();




  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChangeCentercost = (e) => {
    setCostcenter(e.target.value);
  };
  const chnagehandlerPerprinted = (e) => {
    setPreprintedpapper(e.target.checked);
  };
  const chnageHandlerDocdate= (e)=>{
    setDocdate(moment(new Date(e)).format('DD/MM/YYYY'))
  }

  const changeHandlerCustomerName = (value,values) =>{
    console.log(values)
    if(typeof values !== 'object' || values === undefined ||values ==='' || values===null ){
      setCustomername("")
      setCustomercode("")
    }else{
      setCustomername(values.companyname);
      setCustomercode(values.id);
    }
  }

  const changeHandlerSubCustomerName = (value,values) =>{
    console.log(values)
    if(typeof values !== 'object' || values === undefined ||values ==='' || values===null ){
      setSupcustomername("")
      setSupcustomercode("")
    }else{
      setSupcustomername(values.companyname)
      setSupcustomercode(values.id)
    
    }
  }

useEffect(() => {
  getAllAPiData()
}, [])

  const getAllAPiData=()=>{
    CustomerService.retriveAllCustomers(currentTenant).then((res)=>{setCustData(res.data);console.log(res.data,"customer"); })
  }

  const savehandler=(e)=>{
    
    let d = {
      docno:docno,
      docdate:docdate,
      fileno:fileno,
      costcenter:costcenter,
      customer:customername,
      custcode:customercode,
      department:dept,
      advancerecipt:advancereceipt,
      againstdocno:againstdocno,
      reference:reference,
      subcustomer:supcustomername,
      subcustcode:supcustomercode,
      originalamt:originalamt,
      unmatchedamt:unmatchedamt,
      narration:narration,
      customerroe:customerroe,
      invoicefootnote:invoicefootnote,
      createdby:createdby,
      updatedby:updatedby,
      tenantid:currentTenant

    }
    setMasterstore(d)
    console.log( d,"master data")
 
    props.funcMaster(d)
    Swal.fire({
      title: "Add to Invoice ?",
      text: "Are you sure you want to save?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((response) => {
      Swal.fire({
        title: "Added Successfuly",
        text: ".",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      })

    })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
    nevigate("/invoices");
    // nevigate("/invoices");
    
  }
  return (
    <>
      <div className="small-container mp-2 padding-2 w-100%">
        <div className="card">
          <div className="card p-4">
            <div className="row ">
              <fieldset className="col-md-3 col-sm-12">
                <TextField
                  fullWidth
                  label="Document No."
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  value={docno}
                  onChange={(e)=>setDocno(e.target.value)}
                />
              </fieldset>
              <div className="col-md-4 col-sm-12 " >
                {/* <LocalizationProvider  dateAdapter={AdapterDayjs}>
                      <DatePicker  slotProps={{ textField: { size: 'small' } }} label="Document Date" />
                  </LocalizationProvider> */}

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container >
                    <DatePicker
                      variant="inline"
                      id="date-picker-dialog"
                      label="Document Date "
                      // format="dd/MM/yyyy"
                      // maxDate={new Date()}
                      value={docdate}
                      onChange={(e)=>chnageHandlerDocdate(e)}
                      
                      renderInput={(params) => <TextField {...params}  size="small" />}
                      autoOk={true}
                    />
                  </Grid>
                </LocalizationProvider>
              </div>
              <fieldset className="col-md-4 col-sm-12 my-1">
                <TextField
                  label="File Number"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  value={fileno}
                  onChange={(e)=>setFileno(e.target.value)}
                  
                />
              </fieldset>
            </div>

            <div className="row my-2">
              <fieldset className="col-md-3 col-sm-12" >
                <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                  <InputLabel id="demo-select-small">Cost Centre</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={costcenter}
                    label="Cost Centre"
                    onChange={handleChangeCentercost}
                    size="small"
                    variant="outlined"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="HEAD OFFICE">HEAD OFFICE</MenuItem>
                    <MenuItem value="SOHAR">SOHAR</MenuItem>
                    <MenuItem value="SALALAH">SALALAH</MenuItem>
                    <MenuItem value="VFS">VFS</MenuItem>
                    <MenuItem value="DUQM">DUQM</MenuItem>
                  </Select>
                </FormControl>
              </fieldset>

              <fieldset className="col-md-4 col-sm-12">
                <TextField
                  label="Department"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  value={dept}
                  onChange={(e)=>setDept(e.target.value)}
                />
              </fieldset>
              <fieldset className="col-md-4 col-sm-12">
                <TextField
                  label="Advance Receipt"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  value={advancereceipt}
                  onChange={(e)=>setAdvancereceipt(e.target.value)}
                />
              </fieldset>
            </div>

            <div className="row my-2">
              <fieldset className="col-md-3 col-sm-12">
                <TextField
                  fullWidth
                  label="Against Doc. No."
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  value={againstdocno}
                  onChange={(e)=>setAgainstdocno(e.target.value)}

                />
              </fieldset>
              <div className="col-md-4 col-sm-12">
                <lable>0.00</lable>
              </div>
              <fieldset className="col-md-4 col-sm-12">
                <TextField
                  label="Reference"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  value={reference}
                  onChange={(e)=>setReference(e.target.value)}

                />
              </fieldset>
            </div>

            <div className="row my-2">
              
              <fieldset className="col-md-4 col-sm-12">
                
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={custData}
                  getOptionLabel={(option) => option.companyname}
                  onChange={changeHandlerCustomerName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer Name"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                      size="small"
                      value={customername}
                    
                    />
                  )}
                />
              </fieldset>
              <fieldset className="col-md-3 col-sm-12">
                <TextField
                  fullWidth
                  label="Customer Code"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  value={customercode}
                />
              </fieldset>
              <fieldset className="col-md-4 col-sm-12">
                <TextField
                  label="Customer ROE"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  value={customerroe}
                  onChange={(e)=>setCustomerroe(e.target.value)}
                />
              </fieldset>
            </div>

            <div className="row my-2">
              <fieldset className="col-md-3 col-sm-12">
              
                <Autocomplete
                  freeSolo
                  id="free-solo-2-demo"
                  disableClearable
                  options={custData}
                  getOptionLabel={(option) => option.companyname}
                  onChange={changeHandlerSubCustomerName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Customer Name"
                      InputProps={{
                        ...params.InputProps,
                        type: 'search',
                      }}
                      size="small"
                      value={supcustomername}

                    />
                  )}
                />
              </fieldset>
              <fieldset className="col-md-4 col-sm-12">
               <TextField
                  fullWidth="true"
                  label="Sub Customer Code"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  value={supcustomercode}
                />

              </fieldset>
              <fieldset className="col-md-4 col-sm-12">
                <TextField
                  label="Original Amount In OMR"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  value={originalamt}
                  onChange={(e)=>setOriginalamt(e.target.value)}
                />
              </fieldset>
            </div>

            <div className="row my-2">
              <fieldset className="col-md-3 col-sm-12">
                <TextField
                  fullWidth
                  label="Sales man Code"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  value={salesmancode}
                  onChange={(e)=>setSalesmancode(e.target.value)}
                />
              </fieldset>
              <fieldset className="col-md-4 col-sm-12">
                <TextField
                  label="Sales man Code"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  value={salesmanname}
                  onChange={(e)=>setSalesmanname(e.target.value)}
                />
              </fieldset>
              <fieldset className="col-md-4 col-sm-12">
                <TextField
                  label="Unmatched Amt In OMR"
                  id="outlined-size-small"
                  size="small"
                  variant="outlined"
                  value={unmatchedamt}
                  onChange={(e)=>setUnmatchedamt(e.target.value)}
                />
              </fieldset>
            </div>

            <div className="row my-1">
              <fieldset className="col-5 my-2 ">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Narration"
                  variant="outlined"
                  multiline
                  fullWidth
                  size="small"
                  maxRows={4}
                  value={narration}
                  onChange={(e)=>setNarration(e.target.value)}
                />
              </fieldset>
            </div>

            <div className="row ">
              <fieldset className="col-5 my-2 ">
                <TextField
                  id="outlined-multiline-flexible"
                  label="Invoice Footnote"
                  variant="outlined"
                  multiline
                  fullWidth
                  size="small"
                  maxRows={4}
                  value={invoicefootnote}
                  onChange={(e)=>setInvoicefootnote(e.target.value)}
                />
              </fieldset>
              <fieldset className="col-md-3 col-sm-12">
                <label>Pre Printed Paper</label>
                <Checkbox 
                value={preprintedpapper}
                onChange={chnagehandlerPerprinted}
                defaultChecked />
              </fieldset>
              <div className="col-2 my-2">
                <label>Created By : </label>
              </div>
              <div className="col-1 my-2">
                <label>{createdby}</label>
              </div>
            </div>

            <div className="row my-2">
              <div className="col-md-3 col-sm-12 my-2">
                <label>Modified By : </label>
              </div>
              <div className="col-md-3 col-sm-12 my-2">
                <label>{updatedby}</label>
              </div>
              <div style={{float:"left"}}>
                <button className="btn hovbuttonColour btn-sm " onClick={savehandler}>  Save </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TourMaster;
