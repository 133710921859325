import { Field, Form, Formik } from "formik";
import { Autocomplete } from "@mui/material";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import Menu from '../../Component/Menu'
import {
  userid,
  currentTenant,
  ccountry,
  thousandsdisplay,
  logo,
  currency,
  // clientname,
  // address,
  // address2,
} from "../../Common/Credentials.js";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import SalesServices from "../SalesServices";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
// import { Spinner } from "react-bootstrap";
import { Table } from "@mui/material";
import { TableCell } from "@mui/material";
import InventoryService from "../../inventory/InventoryService";
import CategoryServices from "../../Masters/Category/CategoryServices";
import {NumericFormat} from "react-number-format";
// import jsPDF from "jspdf";
import UserService from '../../users/UserService'
import Swal from "sweetalert2";
import PosService from "./PosService";
// import ReactToPrint from "react-to-print";

let datalist = [];
let list = {};

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}

class NewPOS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id:'',
      itemid: "",
      customer:'',
      itemList: [],
      saleorderdata: [],
      posprices:[],
      itemname: "",
      quantity: "",
      cost: "",
      uom:'',
      catid:'',
      stock: "",
      hsn:'',
      tax: "",
      cgst: "",
      sgst: "",
      taxamount: 0,
      totalprice: 0,
      totaliv: 0,
      totalinctax: 0,
      totaltaxamount:0,
      total: 0,
      listData: [],
      mop:"",
      cash:'',
      card:'',
      number:'',
      errmsg:'',
      mpotypes:['Cash','Card','Cash & Card']
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }
  async pay(){
    if (this.state.number === '') {
      this.setState({errmsg:"Phone Number is required"})
    }else if (!/^[7-8-9][0-9]{9}$/i.test(this.state.number)) {
      this.setState({errmsg:'Phone Number should be 10 Numbers'})
   }else{
   
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

if (!res) {
  alert('Razorpay SDK failed to load. Are you online?')
  return
}

// const data = await fetch('http://localhost:1337/razorpay', { method: 'POST' }).then((t) =>
// 	t.json()
// )

    let razorpay_payment_id, razorpay_order_id, razorpay_signature
    // let order_id ="order_I97iv9H2UgMhCj"
    const options = {
        "key": "rzp_test_XR81fxVkWafMLq", // Enter the Key ID generated from the Dashboard
        "amount": "500000000",// Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise must be converted to string
        "currency": currency,
        "name": currentTenant,
        "description": "Sales Transaction",
        "image": logo,
        "order_id": "order_I97iv9H2UgMhCj", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        "handler": function (response){
                razorpay_payment_id = response.razorpay_payment_id
                razorpay_order_id =response.razorpay_order_id
                razorpay_signature= response.razorpay_signature
                // let  generated_signature =sha256(razorpay_order_id + "|" + razorpay_payment_id, "sxORNN7NJy9n0U0mZ5ybY5SJ")
                // if (generated_signature === razorpay_signature) {
                //  alert("payment is successful")
                // }
              },
              "prefill": {
                  "name": "Abdul Saleem",
                  "email": "abdulsaleem@gmail.com",
                  "contact": this.state.number
              },
              "notes": {
                  "address": "Razorpay Corporate Office"
              },
              "theme": {
                  "color": "#3399cc"
              }
          };
  
          console.log(razorpay_payment_id, razorpay_order_id, razorpay_signature)
   
    const rzp1 = new window.Razorpay(options);
    rzp1.on('payment.failed', function (response){
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
    });
   
        rzp1.open();
}
}

  handlemop=(e)=>{
  this.setState({mop:e.target.value})
}

generateReceipt=()=>{
 
      this.setState({ sloader: true });
      let data = {
        // total: this.state.total,
        createdby: userid,
        whid:this.state.whid,
        deptid:this.state.deptid,
        tenantid: currentTenant,
        totaltaxamount: this.state.totaltaxamount,
        totalinctax:this.state.total,
        total:this.state.total-this.state.totaltaxamount,
        // itemlist: datalist,
        paymentmode :this.state.mop,
        cash: this.state.mop==="Cash"?this.state.total:this.state.cash ===''?0:this.state.cash,
        card: this.state.mop==="Card"?this.state.total:this.state.card ===''?0:this.state.card,
        contactno:this.state.number,
        customer:this.state.customer,
        positems:datalist,
      };

      Swal.fire({
        title: "Genearte POS",
        text: `Amount Recived through ${this.state.mop} is ${this.state.total}?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor:'#152f5f',
        cancelButtonText: "No",
        reverseButtons: true
    }).then((result)=>{
        if (result.value) {
          SalesServices.AddPOS(data).then
          ((response) =>{
            Swal.fire({
              title: "POS Generated Successfully",
              text: ".",
              icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor:'#152f5f',
            }).then(()=>this.props.navigate(-1))
          })
          .catch((e) => {
            if(e.response!==undefined||e.response!==''){
            if (e.response.data.error === "Unauthorized") {
                Swal.fire({
                    title: "An Unexpected error Occured",
                    text: "Please login Back to resume",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  }).then(() => this.props.navigate("/"));
            }
            }
          });
        }
    });
}
  // handleSubmit = () => {
  //     this.setState({ sloader: true });
  //     let sodata = {
  //       total: this.state.total,
  //       whid: this.state.whid,
  //       createdby: userid,
  //       updatedby: userid,
  //       tenantid: currentTenant,
  //       taxamount: this.state.taxamount,
  //       paymentterms: parseInt(this.state.paymentterms),
  //       itemlist: datalist,
  //       grandservicetotal: this.state.grandservicetotal,
  //       advancepaymentreq:this.state.advancestatus===false ? 0: 1,
  //       advanceamountreq:parseInt(this.state.advance)
  //     };
  //     SalesServices.NewOrdersssssssss(sodata).then(() =>
  //       this.props.navigate(`/salesorder`)
  //     );
  //   }

  // deleteSaleOrderItem(id) {
  //   let deleteditem = this.state.listData.filter((data) => data.id === id);
  //   let tp = deleteditem.map((data) => data.totalinctax);
  //   let tx = deleteditem.map((data) => data.taxamount);
  //   let ti = deleteditem.map((data) => data.totalprice);
  //   let newitemlist = this.state.listData.filter((data) => data.id !== id);
  //   this.setState({
  //     listData: newitemlist,
  //     total: this.state.total - tp,
  //     taxamount: this.state.taxamount - tx,
  //     totaliv: this.state.totaliv - ti,
  //   });
  //   datalist = newitemlist;
  //   SalesServices.deleteSaleOrderItem(id)
  //   .catch((e)=>{
  //     if(e.response.data.error ==="Unauthorized"){
  //         alert("Your Session is Lost: Please login Back to resume");
  //         this.props.navigate(`/`);
  //     }
  // })
  // }


  // handleQuantityChange = (e) => {
  //   this.setState({ quantity: e.target.value });
  //   this.setState({ totalinctax: "" });
  // };

  onSubmit = () => {
      this.state.itemList.find(e=>e.itemid === this.state.itemid).totalstock = this.state.itemList.find(e=>e.itemid === this.state.itemid).totalstock - parseFloat(this.state.quantity)
      this.setState(
        {
          total: this.state.total + this.state.cost * parseFloat(this.state.quantity) +(parseFloat(this.state.tax) *(this.state.cost * parseFloat(this.state.quantity))) /100,
          totaltaxamount: this.state.totaltaxamount +  (parseFloat(this.state.tax) *(this.state.cost * parseFloat(this.state.quantity))) /100,
          // stock:this.state.stock - parseInt(this.state.quantity)
        },
        () => {
          list = {
            itemid: this.state.itemid,
            itemcode: this.state.itemcode,
            catid:this.state.catid,
            itemname: this.state.itemname,
            price: parseFloat(this.state.cost),
            uom: this.state.uom,
            quantity: parseFloat(this.state.quantity),
            total: this.state.cost * parseFloat(this.state.quantity),
            tax: parseFloat(this.state.tax),
            taxamount: (parseFloat(this.state.tax) *(this.state.cost * parseFloat(this.state.quantity))) /100,
            totalinctax:  this.state.cost * parseFloat(this.state.quantity) +(parseFloat(this.state.tax) *(this.state.cost * parseFloat(this.state.quantity))) /100,
            createdby:userid,
            tenantid:currentTenant,
          };
  
          datalist.push(list);
          this.setState({listData: datalist,quantity:''})
        }
      );
  };

  handleitem = (value,values) => {
    this.setState({itemname:value,quantity: "",cost:'', tax: "",stock: "",cgst: "",sgst: "",});
    let itemdata = this.state.itemList.filter(e=>e.name +" ("+e.itemcode+")" === value)
  
    itemdata.map(data=>
      this.setState({
        itemid:data.itemid,
        itemcode:data.itemcode,
        itemname:data.name,
        type: data.type,
        uom:data.uom,
        catid:data.catid,
        stock: data.totalstock,
        cost:this.state.posprices.find(e=>e.itemid===data.itemid)===undefined?0:this.state.posprices.find(e=>e.itemid===data.itemid)?.grossValue,
        hsn: data.hsn,
        tax: data.tax,
        cgst: data.tax / 2,
        sgst: data.tax / 2,
      })
    )
  };

  removeItemFromList(id) {
    let deleteditem = datalist.filter((person, index) => index === id);
    console.log(deleteditem)
    let dquantity =  deleteditem.map((data) => data.quantity);
    let tp = deleteditem.map((data) => data.totalinctax);
    let tx = deleteditem.map((data) => data.taxamount);
    const newlist = datalist.filter((person, index) => index !== id);
  this.setState({
    listData: newlist,
    total: this.state.total - tp,
    totaltaxamount: this.state.totaltaxamount - tx,
    itemid:deleteditem.find((data) => data).itemid
  },()=>this.state.itemList.find(e=>e.itemid === this.state.itemid).totalstock = this.state.itemList.find(e=>e.itemid === this.state.itemid).totalstock + parseFloat(dquantity))
    datalist = newlist;
  }


  onfocus(){
    document.getElementById('quantity').addEventListener('mousewheel', function(event) {
     if (window.document.activeElement.type === 'number') {
       event.preventDefault()
     }
   })
   document.getElementById('quantity').addEventListener('keydown', function(e) {
    if (e.which === 38 || e.which === 40) {
        e.preventDefault();
    }
  });
  
   }
   oncashfocus(){
    document.getElementById('cash').addEventListener('mousewheel', function(event) {
      if (window.document.activeElement.type === 'number') {
        event.preventDefault()
      }
    })
    document.getElementById('cash').addEventListener('keydown', function(e) {
     if (e.which === 38 || e.which === 40) {
         e.preventDefault();
     }
   });
   }

   oncardfocus(){

    document.getElementById('card').addEventListener('mousewheel', function(event) {
      if (window.document.activeElement.type === 'number') {
        event.preventDefault()
      }
    })
    document.getElementById('card').addEventListener('keydown', function(e) {
     if (e.which === 38 || e.which === 40) {
         e.preventDefault();
     }
    })
   }

   
  refreshData() {
    datalist = [];
    list = {};
    PosService.GetPosPrices(currentTenant)
      .then((response) =>
        this.setState({
          posprices: response.data,
          loading: false,
        })
      ).catch((e) => {
        if(e.response!==undefined||e.response!==''){
        if (e.response.data.error === "Unauthorized") {
            Swal.fire({
                title: "Session is Lost!",
                text: "Please login Back to resume",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              }).then(() => this.props.navigate("/"));
        }
        }
      });

    InventoryService.GetItems(currentTenant).then((response) =>
      this.setState({
        itemList: response.data,
      })
    )
    .catch((e) => {
      if(e.response!==undefined||e.response!==''){
      if (e.response.data.error === "Unauthorized") {
          Swal.fire({
              title: "Session is Lost!",
              text: "Please login Back to resume",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => this.props.navigate("/"));
      }
      }
    });
    UserService.Userwhanddpt(currentTenant).then((response) =>
    this.setState({
      userdata: response.data,
    })
  );

    SalesServices.retriveAllCustomers(currentTenant).then((res) =>
      this.setState({
        custom: res.data,
      })
    );

    CategoryServices.getCategory(currentTenant).then((response) =>
      this.setState({
        CategoryList: response.data.filter((e) => e.categorytype === "Product"),
      })
    );
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    this.refreshData();
  
  }

  validate(values) {
    let errors = {};
    if (this.state.quantity === "") {
      errors.quantity = "Please Enter Quantity";
    }
    if (this.state.quantity <= 0) {
      errors.quantity = "Value Should be greaterthan 0";
    }
    if(this.state.quantity>this.state.stock){
      errors.quantity = `Quantity avilabel ${this.state.stock===null?0:this.state.stock}`;
    }
    if (this.state.itemname === "") {
      errors.itemname = "Please Select Item";
    }

    if (this.state.customer === "") {
      errors.customer = "Please Enter Customer Name";
    }
    if (this.state.number === "") {
      errors.number = "Please Enter Phone No.";
    }

    return errors;
  }

  
  render() {
    let {
      id,
      customer,
      itemList,
      itemid,
      itemname,
      cost,
      stock,
      quantity,
      totalinctax,
      tax,
      cgst,
      sgst,
      saleorderdata,
      catid,
      category,
      filteritemlist,
      mop,
      number
    } = this.state;

    return (
      <div>
         <Header />
        <Menu/>
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li>
                <Link
                  as="button"
                  className="text-secondary"
                  to={{ pathname: "/pointofsale" }}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                  <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item active">Point of Sale</li>
            </ol>
            <div className="card-body">
              <Formik
                initialValues={{ 
                  id,
                  customer,
                  itemList,
                  itemid,
                  itemname,
                  cost,
                  stock,
                  quantity,
                  totalinctax,
                  tax,
                  cgst,
                  sgst,
                  saleorderdata,
                  catid,
                  category,
                  filteritemlist,
                  mop,
                  number,
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({setFieldValue,values}) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="itemid"
                          placeholder="Id"
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="customer"
                          label="Customer Name"
                          onChange={(e) =>this.setState({customer: e.target.value})}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3">
                        <FormikTextField
                          className="form-control"
                          type="number"
                          name="number"
                          label={`Phone no.`}
                          onChange={(e) =>
                            this.setState({
                              number: e.target.value,
                              errmsg:''
                            })
                          }
                        />
                        <span className="text-red mt-1">{this.state.errmsg}</span>
                      </fieldset>
                      <fieldset className="form-group col-md-6"/>

                      <fieldset className="form-group col-md-3 pt-3">
                      <Autocomplete
                      id="partyledger"
                      options={itemList}
                      getOptionLabel={(option) => option.name+" ("+option.itemcode+")"}
                      value={values.item?undefined:undefined}
                      onChange={(e,value) => {
                          // setFieldValue("item", value);
                          values.itemname==='' || values.itemname===null || value===null?
                          setFieldValue("itemname",'')
                          :setFieldValue("itemname", value.name)
                        }}
                      renderInput={params => (
                        <FormikTextField
                          {...params}
                        className="form-control"
                        type="text"
                        name="itemname"
                        label="Item"
                        onBlur={(e) => {
                          this.handleitem(e.target.value,values)
                          }}
                        placeholder="Select Item/Product"
                        variant="standard"
                        fullWidth
                      />
                    )}
                  />
                  </fieldset>
                  <fieldset className="form-group col-md-3 pt-3">
                        <FormikTextField
                          id="cost"
                          className="form-control"
                          type="number"
                          name="cost"
                          label={`Price`}
                          value={this.state.cost}
                         disabled
                        />
                      </fieldset>
                        <fieldset className="form-group col-md-3 pt-3">
                        <FormikTextField
                          id="quantity"
                          className="form-control"
                          type="number"
                          name="quantity"
                          label={`Quantity`}
                          min="0"
                          onChange={(e) =>
                            this.setState({
                              quantity: e.target.value,
                            })
                          }
                          onFocus={this.onfocus}
                        />
                      </fieldset>
                        
                     
                      <fieldset className="form-group col-md-12"/>

                      <table className="float-left m-0">
                        <tbody>
                          {ccountry === "India" ? (<>
                          <tr>
                            <td>CGST&nbsp;</td>
                            <td>:&nbsp; {this.state.tax / 2}%</td>
                            <td>&nbsp;&nbsp;&nbsp;&nbsp;SGST&nbsp;</td>
                            <td>:&nbsp; {this.state.tax / 2}%</td>
                          </tr>
                          </>)
                          :
                          <tr>
                            <td>VAT/GST&nbsp;</td>
                            <td>:&nbsp;{this.state.tax}%</td>
                          </tr>
                          }  

                        </tbody>
                      </table>
                      <div className="form-group col-md-12" />
                      <div className="form-group col-md-3">
                          <button
                            type="submit"
                            className="btn btn-sm hovbuttonColour"
                          >
                            {/* <i className="fas fa-plus mr-1"/> */}
                           Add Item
                          </button>
                      </div>

                    <div className="form-group col-md-12" />
                    {this.state.total === 0 || "" ? (
                      ''
                    ) : (
                      <>
                        <TableContainer>
                          <Table className="table-sm" >
                            <TableHead>
                              <TableRow className="text-center">
                                <TableCell>Itemname</TableCell>
                                {/* <TableCell>{ccountry === "India" ? "HSN" : "Item Code"}</TableCell> */}
                                <TableCell>Unit Price</TableCell>
                                <TableCell>Quantity</TableCell>
                                {/* <TableCell>Total</TableCell> */}
                                {ccountry !== "India"?(<TableCell>VAT</TableCell>) :<TableCell>CGST(%)</TableCell>}
                                {ccountry === "India"?(<TableCell>SGST(%)</TableCell>):""}
                                <TableCell>Tax Amount</TableCell>
                                <TableCell>Total Price</TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.listData.map((data, index) => (
                                <TableRow key={index} style={{ textAlign: "center" }}>
                                  <TableCell>{data.itemname}</TableCell>
                                  {/* <TableCell>{data.hsn}</TableCell> */}
                                  <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                  {/* <TableCell>{data.salesenum}</TableCell> */}
                                  <TableCell>{data.quantity}</TableCell>
                                  {/* <TableCell><NumericFormat className="pr-3" displayType={'text'} value={data.totalprice} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell> */}
                                  {ccountry !== "India" ? 
                                    <TableCell>{data.tax}</TableCell>
                                   : 
                                    <TableCell>{data.tax / 2}</TableCell>
                                   }
                                  {ccountry === "India"? (
                                    <TableCell>{data.tax / 2}</TableCell>
                                  ) : (
                                    ""
                                  )}
                                  <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.taxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                  <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.totalinctax} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                  <TableCell>
                                    <span
                                      as="button"
                                      data-tip
                                      data-for="delete"
                                      className="fa fa-trash text-danger ml-2"
                                      onClick={() => this.removeItemFromList(index)}
                                    />
                                    {/* //<Tooltip
                                      id="delete"
                                      place="top"
                                      effect="solid"
                                    >
                                      Delete
                                    </Tooltip> */}
                                  </TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell>
                                  Total
                                </TableCell>
                                <TableCell></TableCell>
                                {/* <TableCell></TableCell> */}
                                {ccountry === "India"? (<TableCell></TableCell>) : ("")}
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell className="text-right">
                                 <NumericFormat className="pr-3" displayType={'text'} value={this.state.totaltaxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                </TableCell>
                                <TableCell className="text-right">
                                  <NumericFormat className="pr-3" displayType={'text'} value= {this.state.total === ""? 0: this.state.total} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                </TableCell>
                                <TableCell></TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <div className="form-group col-md-12"/> 
                        <u>Receipts  :</u>
                     <div className="form-group col-md-12"/> 
                     <table className="float-left m-0">
                        <tbody>
                          <tr>
                            <td>Total Amount&nbsp;</td>
                            <td>:&nbsp; {this.state.total === "" ? 0 : <NumericFormat className="pr-3" displayType={'text'} value={this.state.total} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>}</td>
                          </tr>
                          <tr>
                            <td>Mode of Payment&nbsp;&nbsp;</td>
                            {/* <td>:&nbsp;&nbsp;
                            <div className="form-check form-check-inline">
                                    <span className="radio-inline">
                                      <Field
                                        name="mop"
                                        value="cash"
                                        id="active"
                                        clicked={this.state.mop}
                                        onClick={this.handlemop}
                                        className="mr-2 leading-tight"
                                        type="radio"
                                      />
                                     Cash
                                    </span>
                                  </div>
                                  
                                
                                  <div className="form-check form-check-inline">
                                    <span className="radio-inline">
                                      <Field
                                        name="mop"
                                        id="inactive"
                                        value="card"
                                        clicked={this.state.mop}
                                        onClick={this.handlemop}
                                        className=" mr-2 leading-tight "
                                        type="radio"
                                      />
                                      Card
                                    </span>
                                  </div>

                                  <div className="form-check form-check-inline">
                                    <span className="radio-inline">
                                      <Field
                                        name="mop"
                                        id="inactive"
                                        value="Cash & Card"
                                        clicked={this.state.mop}
                                        onClick={this.handlemop}
                                        className=" mr-2 leading-tight "
                                        type="radio"
                                      />
                                      Cash & Card
                                    </span>
                                  </div>
                            </td> */}

                            <td>:&nbsp;&nbsp;
                          
                                      <FormControl>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="row-radio-buttons-group"
                                          value={this.state.mop}
                                          onChange={this.handlemop}
                                        >
                                          {this.state.mpotypes.map((e) => {
                                            return (
                                              <FormControlLabel
                                                key={e}
                                                value={e}
                                                control={
                                                  <Radio
                                                    style={{ color: "#152f5f" }}
                                                  />
                                                }
                                                label={e}
                                              />
                                            );
                                          })}
                                        </RadioGroup>
                                      </FormControl>
                            </td>
                          </tr>
                          </tbody>
                          </table>
                          <fieldset className="form-group col-md-12"/>
                          {this.state.mop === "Cash & Card"?
                          <div className="form-row">
                          <fieldset className="form-group col-md-6">
                            <FormikTextField
                              id="cash"
                              className="form-control"
                              type="number"
                              name="cash"
                              label={`Payment with Cash`}
                              onChange={(e) =>
                                this.setState({
                                  cash: e.target.value,
                                })
                              }
                              onFocus={this.oncashfocus}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-6">
                            <FormikTextField
                              id="card"
                              className="form-control"
                              type="number"
                              name="card"
                              label={`Payment with Card`}
                              onChange={(e) =>
                                this.setState({
                                  card: e.target.value,
                                })
                              }
                              onFocus={this.oncardfocus}
                            />
                          </fieldset>
                          </div>
                          :''
                          }
                          <fieldset className="form-group col-md-12"/>
                           {/* {this.state.mop === "Cash & Card" || this.state.mop ==="Card" ?<>
                           <fieldset className="form-group col-md-12"/>
                          <Link id="rzp-button1" className="btn btn-sm hovbuttonColour mr-1" onClick={()=>this.pay()}><i className="fa fa-credit-card"></i>&nbsp;&nbsp;Pay</Link>
                          </>:''} */}
                          {this.state.mop === "Cash & Card"?<>
                       
                          {(parseFloat(this.state.cash) + parseFloat(this.state.card)) === this.state.total?<>
                          <span as="button" className="btn btn-sm hovbuttonColour" onClick={this.generateReceipt}><i className="fas fa-receipt mr-1"></i> Generate Receipt</span>
                          </>:<><button className="btn btn-sm hovbuttonColour" disabled><i className="fas fa-receipt mr-1"></i>Generate Receipt</button></>
                          }</>:''
                        }
                         {this.state.mop === "Cash" || this.state.mop === "Card"?<>
                          <span as="button" className="btn btn-sm hovbuttonColour" onClick={()=>{this.generateReceipt()}}><i className="fas fa-receipt mr-1"></i>Generate Receipt</span>
                          </>:''
                          }
                      </>
                    )}
                    </div>
                     <div className="form-group col-md-12"/> 
{/* 
                      <div id="printpos" ref={el => (this.componentRef = el)} className="text-center card" style={{marginRight:"450px",height:"100%"}} hidden>
                         <div>{clientname}</div>
                         <div>{address}</div>
                         <div className="border-bottom">{address2}</div>
                          <table className="table-sm m-0">
                            <thead>
                              <tr>
                                <td className="text-center">Itemname</td>
                                <td className="text-center">Quantity</td>
                                <td className="text-center">Total Price</td>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.listData.map((data, index) => (
                                <tr key={index}className="border-bottom border-2">
                                  <td className="text-center">{data.name}</td>
                                  <td className="text-center">{data.quantity}</td>
                                  <td className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.quantity*data.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                                </tr>
                              ))}
                              <tr>
                                <td>
                                </td>
                                <td className="text-center">
                                  Amount
                                </td>
                                <td className="text-right">
                                  <NumericFormat className="pr-3" displayType={'text'} value= {this.state.total-this.state.taxamount === ""? 0: this.state.total-this.state.taxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                </td>
                                <td className="text-center">
                                  Tax
                                </td>
                                <td className="text-right">
                                <NumericFormat className="pr-3" displayType={'text'} value={this.state.taxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                </td>
                                <td className="text-center">
                                  Total
                                </td>
                                <td className="text-right">
                                  <NumericFormat className="pr-3" displayType={'text'} value= {this.state.total === ""? 0: this.state.total} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                </td>
                              </tr>
                              
                            </tbody>
                          </table>

                          <div className="border-top">Thanks for your Purchase</div>
                          
                        </div>

                        <ReactToPrint
                      trigger={() => {
                        return <a href="#">Print this out!</a>;
                      }}
                      content={() => this.componentRef}
                      /> */}

                   
                  </Form>
                )}
              </Formik>
            </div>
          </div> 
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(NewPOS);
