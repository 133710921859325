import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {NumericFormat} from "react-number-format";
import moment from "moment";

import "date-fns";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { Button } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import InventoryService from "../InventoryService";

class ConsolidateReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportsdata: [],
      filterdata: [],
      filterbydate: [],
      filterOptions: [
        { id: 0, name: "Production" },
        { id: 1, name: "Full Process" },
        { id: 2, name: "Immediate Process" },
      ],
      overall: true,
      loading: false,
      selected: 0,
      fromdate:new Date(),
      reportsfiltereddata:[],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.clearfilter = this.clearfilter.bind(this);
  }

  handleFromDateChange = (date) => {
    this.setState({ fromdate: date });
  };

  clearfilter() {
    this.setState({
      fromdate: moment().subtract(7,"days"),
      todate: new Date(),
      filterdata: this.state.reportsdata.filter(
        (e) =>moment(e.createdAt).format("YYYY-MM-DD") ===
            moment().format("YYYY-MM-DD")
      ),
    });
  }

  onSubmit() {
    let fdata = this.state.reportsdata.filter(
      (e) =>moment(e.createdAt).format("YYYY-MM-DD") ===
          moment(this.state.fromdate).format("YYYY-MM-DD")
    );
    this.setState({ filterdata: fdata});
  }


//   FilterdataBydate = () => {
//       let data = this.state.reportsfiltereddata
//       data.filter((e) =>(data.find((a) => a.createdAt === e.createdAt).createdAt = moment(e.createdAt).format("YYYY-MM-DD")))
//     this.setState({ filterbydate:data })
//   };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    InventoryService.ConsolidateReport(currentTenant)
      .then((response) => {
        
        this.setState({
            
            filterdata: response.data.filter(
              (e) =>
                e.type === "Full Process" &&
                (moment(e.createdAt).format("YYYY-MM-DD") ===
                  moment().format("YYYY-MM-DD")) 
            ),
            reportsdata: response.data,
            loading: false,
          }
        );
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

  validate(values) {
    let errors = {};
    if (values.from <= values.to && values.to === false) {
      alert("To date must be greater than From date");
      errors.to = "To date must be greater than From date";
    }
    if (values.from === null) {
      alert("Please Select From date");
      errors.from = "Please Select From date";
    }
    if (!values.to === null) {
      alert("Please Select to date");
      errors.to = "Please Select to date";
    }
    return errors;
  }
  render() {
    
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item active">Consolidate Report</li>
            </ol>
            <div className="card-body">
              <div>
                {this.state.loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <Formik
                    initialValues={{
                      from: this.state.fromdate,
                      to: this.state.todate,
                    }}
                    onSubmit={this.onSubmit}
                    validateOnChange={false}
                    validate={this.validate}
                    enableReinitialize={true}
                  >
                    {({ setFieldValue, values }) => (
                      <Form autoComplete="off">
                        <div className="form-row g-0">
                          <fieldset>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justifyContent="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="From Date"
                                  name="from"
                                  format="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  value={this.state.fromdate}
                                  onChange={this.handleFromDateChange}
                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                  autoOk={true}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>

                          <fieldset className="form-group col-md-1">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3"
                              type="submit"
                            >
                              <i className="fas fa-filter" />
                            </button>
                          </fieldset>
                          {this.state.todate !== null ? (
                            <fieldset className="form-group col-md-2">
                              <Button
                                className="btn btn-sm bg-white shadow-sm mt-3"
                                onClick={this.clearfilter}
                              >
                                {" "}
                                <b style={{ color: "#FA5733" }}> X</b> Clear
                                Filter
                              </Button>
                            </fieldset>
                          ) : (
                            ""
                          )}
                          <div className="form-group col-md-12 text-sm" />
                        </div>
                       
                                <div className="form-row">
                                  <div className="col-md-12">
                                   <Typography className=" text-center textColour pt-2 pb-2" style={{backgroundColor:"rgb(189,199,238)"}}><b> { moment(this.state.fromdate).format('dddd')},&nbsp;{ moment(this.state.fromdate).format('LL')}</b></Typography></div>
                                  <div className="col-md-6">
                                    <Typography  className="text-center pt-2 pb-2"><b>Sales</b></Typography>
                                <TableContainer className="mb-3">
                                  <Table style={{border:"1px solid lightgrey"}}>
                                    <TableHead>
                                      <TableRow >
                                        <TableCell className="text-center">Customer</TableCell> 
                                        <TableCell className="text-center">Agent</TableCell>
                                        <TableCell className="text-center">Item</TableCell>
                                        <TableCell className="text-center">Quantity</TableCell>
                                        <TableCell className="text-center">UOM</TableCell>
                                        <TableCell className="text-center">Price</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.filterdata.map((e) => (
                                        e.type ==="SALES"?
                                        <TableRow>
                                            <TableCell className="text-center">{e.company}</TableCell>
                                            <TableCell className="text-center">{e.agent}</TableCell>
                                          <TableCell className="text-center">{e.itemname}</TableCell>
                                          <TableCell className="text-center">{e.quantity}</TableCell>
                                          <TableCell className="text-center">{e.uom}</TableCell>
                                          <TableCell className="text-right">
                                          <NumericFormat className="pr-3" displayType={'text'} value={e.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                          </TableCell>
                                        </TableRow>
                                        :''
                                      ))}
                                      {this.state.filterdata.filter(e=>e.type === "SALES").length === 0 ?
                                    <TableRow>
                                      <TableCell className="text-center" colspan="12">No data avilable</TableCell>
                                    </TableRow>  :''
                                    }
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                </div>
                                <div className="col-md-6">
                                <TableContainer className="mb-3">
                                <Typography  className="text-center pt-2 pb-2"><b>Purchase / Production</b></Typography>
                                  <Table style={{border:"1px solid lightgrey"}}>
                                    <TableHead>
                                      <TableRow >
                                      <TableCell className="text-center">Supplier</TableCell>
                                      <TableCell className="text-center">Agent</TableCell>
                                        <TableCell className="text-center">Item</TableCell>
                                        <TableCell className="text-center">Quantity</TableCell>
                                        <TableCell className="text-center">UOM</TableCell>
                                        <TableCell className="text-center">Price</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.filterdata.map((e) => (
                                        e.type !== "SALES"?
                                        <TableRow>
                                            <TableCell className="text-center">{e.company}</TableCell>
                                            <TableCell className="text-center">{e.agent}</TableCell>
                                          <TableCell className="text-center">{e.itemname}</TableCell>
                                          <TableCell className="text-center">{e.quantity}</TableCell>
                                          <TableCell className="text-center">{e.uom}</TableCell>
                                          <TableCell className="text-right">
                                          <NumericFormat className="pr-3" displayType={'text'} value={e.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                          </TableCell>
                                        </TableRow>
                                        :""
                                      ))}
                                      {this.state.filterdata.filter(e=>e.type !== "SALES").length === 0 ?
                                    <TableRow>
                                      <TableCell className="text-center" colspan="12">No data avilable</TableCell>
                                    </TableRow>  :''
                                    }
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                </div>
                                </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ConsolidateReport);
