import { Autocomplete, CardHeader, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { Formik } from "formik";
import { FormikTextField } from '../../../CommonComponents/FormField.js';
import React from "react";
import Chart from "react-apexcharts";
import {
  currency,
  currentTenant,
  financialyearend,
  financialyearstart,
} from "../../../Common/Credentials";
import SalesServices from "../../../Sales/SalesServices";
import moment from "moment";
import { Col, Row, Form, Spinner } from "react-bootstrap";
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';


class CustomerSalesbyYear extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      custid: "",
      customer: "",
      customerData: [],
      filterdata: [],
      products: "",
      customers: "",
      productslist: [],
      custbyproducts: [],
      customerslist: [],
      productsbycust: [],
      topproducts: [],
      topcustomers: [],
      salesbyuser: [],
      itemsbyusers: [],
      users: [],
      custbyusers: [],
      resdata: [],
      yearamount: [],
      yearquantity: [],
      topcustomersbyquantity:[],
      type:"Amount",
      loader: false,
      startyear:financialyearstart,
      endyear:financialyearend,
      toggle:false
    };
  }

  handleCustomer = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({ customer: values });
      let cusdata = this.state.resdata.filter(
        (e) => e.customer === values.companyname
      );
      let monthlyamount = [];
      let monthlyquantity = [];
      // let k = 0;
      for (let k = 0; k < 12; k++) {
        let am = 0;
        let qu = 0;
        cusdata.forEach((inv) => {
          if (
            moment(inv.createdAt).format("M") ===
            moment(financialyearstart)
              .add(k, "month")
              .startOf("month")
              .format("M")
          ) {
            am = am + inv.totalinvoice;
            qu =
              qu + inv.invoiceitems.reduce((a, v) => (a = a + v.quantity), 0);
          }
        });
        monthlyamount.push(am);
        monthlyquantity.push(qu);
      }
      this.setState({
        yearamount: monthlyamount,
        yearquantity: monthlyquantity,
      });
    } else {
      this.setState({ customer: values, yearamount: [] });
    }
  };

  componentDidMount() {

    this.setState({loader:true})
    SalesServices.retriveAllCustomers(currentTenant).then((res) =>
      this.setState({
        customerData: res.data,
      })
    );
      this.Refresh()
  }

  Refresh=()=>{
    let datadao = {
      tenantid: currentTenant,
      startdate: moment(this.props.start),
      enddate: moment(this.props.end),
    }

    SalesServices.getFYInv(datadao).then((res) => {
      this.setState({ resdata: res.data, filterdata: res.data,loader: false, startyear: this.props.start,endyear: this.props.end,customer:'' }, () => {
        this.Customerdata();
      });
    });
  }
  Customerdata = () => {
    this.setState({ customerslist: [], productsbycust: [] }, () => {
      const customers = this.state.filterdata.reduce(
        (cust, { totalinvoice, createdAt, customer, custid,invoiceitems }) => {
          if (!cust[customer]) cust[customer] = [];
          cust[customer].push({
            totalinvoice: totalinvoice,
            createdAt: createdAt,
            customer: customer,
            custid: custid,
            quantity: invoiceitems.reduce((a, v) => (a = a + v.quantity), 0),
          });
          return cust;
        },
        {}
      );
      this.setState({ customers: customers });

      Object.entries(customers).forEach(([key, value]) => {
        let a = {
          customer: key,
          totalinvoice: value.reduce((a, v) => (a = a + v.totalinvoice), 0),
          quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
        };
   
        if(this.state.customerslist.filter(cu=>cu.customer===a.customer).length === 0){
        this.state.customerslist.push(a);
        }
      });

      // Top 10 Customers by Amount
      const topcustomers = []
        .concat(this.state.customerslist)
        .sort((a, b) => (a.totalinvoice < b.totalinvoice ? 1 : -1));
      this.setState({ topcustomers: topcustomers.slice(0, 5) });
      if (this.state.customer === "") {
        if (topcustomers.length !== 0) {
          let value = topcustomers[0];
          Object.assign(value, { companyname: value.customer });
          this.handleCustomer(1, value);
        }
      }

      // Top 10 Customers by Quantity
      const topcustomersbyquantity = []
        .concat(this.state.customerslist)
        .sort((a, b) => (a.quantity < b.quantity ? 1 : -1));
      this.setState({ topcustomersbyquantity: topcustomersbyquantity.slice(0, 5) });
  
      // if (this.state.customer === "") {
        // if (topcustomersbyquantity.length !== 0) {
          // let value = topcustomersbyquantity[0];
          // Object.assign(value, { companyname: value.customer });
          // this.handleCustomer(1, value);
        // }
      // }
    });
  };

  render() {
    let { custid, customer, customerData } = this.state;
    return (
        <>
        <CardHeader
        title={<Typography>{this.state.toggle ? "Top 5 Customers":
                        <Autocomplete
                        name="customer"
                        options={customerData}
                        getOptionLabel={(option) => option.companyname}
                        id="controlled-demo"
                        value={this.state.customer}
                        onChange={this.handleCustomer}
                        renderInput={(params) => (
                          <TextField {...params} placeholder={`Select Customer`} variant="standard" size="small" />
                        )}
                      />

        }</Typography>}
        subheader=""
        action={<div className="d-flex">
         <Tooltip
                    title={
                      this.state.toggle ? "Top 5 Customers":"Chart view"
                    }
                    arrow
                    placement={"top"}
                    sx={{
                        '& .MuiTooltip-arrow': {
                            marginTop: '-0.65em'
                        }
                    }}
                >
                    <IconButton onClick={() => this.setState({toggle:!this.state.toggle})}>
                        <InsertChartOutlinedIcon/>
                    </IconButton>
                </Tooltip>
          </div>
        }
        // avatar={avatar}
        sx={{
          zIndex: 2,
          position: "relative",
        }}
      />
          {moment(this.state.startyear).format('yyyy') !== moment(this.props.start).format('yyyy')?this.Refresh():''}
          
          {this.state.toggle?   
                <div id="chart" className="apexcharts-content">
                  {this.state.loader?
                   <span className="d-flex justify-content-center">
                   <Spinner animation="border" size="lg" />
                 </span>
                  :
                  <Chart
                    options={{
                      labels: this.state.topcustomers.map((e) => e.customer),
                      responsive: [
                        {
                          options: {
                            legend: {
                              position: "bottom",
                            },
                          },
                        },
                      ],
                      fill: {
                        type: "gradient",
                        gradient: {
                          shade: "dark",
                          type: "vertical",
                          shadeIntensity: 0.5,
                          inverseColors: false,
                          opacityFrom: 1,
                          opacityTo: 0.8,
                          stops: [0, 100],
                        },
                      },
                      dropShadow: {
                        enabled: true,
                        opacity: 0.3,
                        blur: 5,
                        left: -7,
                        top: 22,
                      },
                      theme: {
                        mode: this.props.theme === false ? "light" : "dark",
                        palette: "palette1",
                        monochrome: {
                          enabled: false,
                          color: "#E73145",
                          shadeTo:
                            this.props.theme === false ? "light" : "dark",
                          shadeIntensity: 0.65,
                        },
                      },
                      legend: {
                        horizontalAlign: "center",
                        position: "top",
                        offsetY: 10,
                      },
                    }}
                    type="pie"
                    series={ this.state.type === "Amount" ? this.state.topcustomers.map((e) => e.totalinvoice) ===undefined? []: this.state.topcustomers.map((e) => e.totalinvoice)
                        :  this.state.topcustomersbyquantity.map((e) => e.quantity) === undefined? []: this.state.topcustomersbyquantity.map((e) => e.quantity)
                    }
                    height={200}
                  />
                  }
                </div>
          :
           
              <div>
                {/* <div className="card-header">
                  <Formik
                    initialValues={{
                      customerData,
                      customer,
                      custid,
                    }}
                    enableReinitialize={true}
                  >
                    {({ setFieldValue, values }) => (
                        <Autocomplete
                          id="subaccount"
                          className={`subacc-select}`}
                          name="customer"
                          options={customerData}
                          getOptionLabel={(option) => option.companyname}
                          value={values.customer ? undefined : undefined}
                          onChange={this.handleCustomer}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              type="text"
                              name="customer"
                              // inputProps={{ style: { color: this.props.theme===false?'black':'white' } }}
                              value={this.state.customer}
                              label={`Select Customer(${this.state.customer})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                    )}
                  </Formik>
                </div> */}

                <div id="chart" className="apexcharts-content">
                  <Chart
                    options={{
                      chart: {
                        stacked: false,
                      },
                      plotOptions: {
                        bar: {
                          columnWidth: "50%",
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      stroke: {
                        width: [0, 5, 5],
                        curve: "smooth",
                      },
                      title: {
                        text: "",
                        align: "left",
                      },
                      grid: {
                        row: {
                          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
                        },
                      },
                      fill: {
                        type: "gradient",
                        gradient: {
                          shade: "dark",
                          type: "vertical",
                          shadeIntensity: 0.5,
                          inverseColors: false,
                          opacityFrom: 1,
                          opacityTo: 0.8,
                          stops: [0, 100],
                        },
                      },
                      dropShadow: {
                        enabled: true,
                        opacity: 0.3,
                        blur: 5,
                        left: -7,
                        top: 22,
                      },
                      legend: {
                        show: true,
                        floating: true,
                        horizontalAlign: "left",
                        onItemClick: {
                          toggleDataSeries: false,
                        },
                        position: "top",
                        offsetY: -33,
                        offsetX: 60,
                      },
                      theme: {
                        mode: this.props.theme === false ? "light" : "dark",
                        palette: "palette1",
                        monochrome: {
                          enabled: false,
                          color: "#E73145",
                          shadeTo:
                            this.props.theme === false ? "light" : "dark",
                          shadeIntensity: 0.65,
                        },
                      },
                      tooltip: {
                        theme: "light",
                      },
                      xaxis: {
                        categories: [
                          moment(financialyearstart)
                            .add(0, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(1, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(2, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(3, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(4, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(5, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(6, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(7, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(8, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(9, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(10, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(11, "month")
                            .startOf("month")
                            .format("MMM"),
                        ],
                      },
                      yaxis: [
                        {

                          // title: {
                          //   text: `Quantity`,
                          // },
                          labels:{
                            show:false
                          }
                        },
                        {
                          opposite: true,
                          // title: {
                          //   text: `Amount ( ${currency} )`,
                          // },
                          labels:{
                            show:false
                          }
                        },
                      ],
                    }}
                    series={[
                      {
                        type: "column",
                        name: "Quantity",
                        data:
                          this.state.yearquantity === undefined
                            ? []
                            : this.state.yearquantity,
                      },
                      {
                        type: "line",
                        name: "Amount",
                        data:
                          this.state.yearamount === undefined
                            ? []
                            : this.state.yearamount,
                      },
                    ]}
                    type="line"
                    height={200}
                  />
                </div>
              </div>
  }
        </>
    );
  }
}

export default CustomerSalesbyYear;
