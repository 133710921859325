import { Form, Formik } from "formik";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import {NumericFormat} from "react-number-format";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { thousandsdisplay, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";

class TransportationReportdetails extends Component {
  constructor(props) {
    super(props);
   
    this.state = {
      data: this.props.location.state.data,
      item: this.props.location.state.item,
      pagename: this.props.location.state.pagename,
      selected: "1",
      filterOptions: [
        { id: "1", name: "Item" },
        { id: "2", name: "Customer" },
      ],
    };
  }

  onChange(option) {
    this.setState({ selected: option.id });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
  }
  render() {
    const column1 = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          sortOrder: "asc",
        },
      },
      {
        name: "customid",
        label: "TripId",
        options: { filter: true, sort: true },
      },
      {
        name: "originname",
        label: "Origin",
        options: { filter: true, sort: true },
      },
      {
        name: "destinationname",
        label: "Destination",
        options: { filter: true, sort: true },
      },
      {
        name: "driver1name",
        label: "Driver1",
        options: { filter: true, sort: true },
      },
      {
        name: "driver2name",
        label: "Driver2",
        options: { filter: true, sort: true },
      },
      {
        name: "totalkms",
        label: "Total (kms)",
        options: { filter: true, sort: true },
      },
      {
        name: "dieselinltr",
        label: "Diesel (Ltr)",
        options: { filter: true, sort: true },
      },
      {
        name: "millage",
        label: "Millage",
        options: { filter: true, sort: true },
      },
      {
        name: "loading",
        label: "Loading",
        options: { filter: true, sort: true },
      },
      {
        name: "unloading",
        label: "unloading",
        options: { filter: true, sort: true },
      },
      {
        name: "differance",
        label: "Differance",
        options: { filter: true, sort: true },
      },
      {name: "indate",label: "In Date",options: {filter: false,sort: true,sortOrder: 'asc', 
      customBodyRender:(tableMeta)=>{
        return(moment(tableMeta).format('DD-MM-YYYY'))
      }}},
      {name: "outdate",label: "Out Date",options: {filter: false,sort: true,sortOrder: 'asc', 
      customBodyRender:(tableMeta)=>{
        return(moment(tableMeta).format('DD-MM-YYYY'))
      }}},
      {name: "totalexp",label: "Total Expensives",
          options: {filter: false,sort: true,
            customBodyRender:(tableMeta)=>{
              return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
            },
            setCellProps: value => ({ style: { textAlign:'right'}}),
          }},
    ];

    const column2 = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          sortOrder: "asc",
        },
      },
      {
        name: "customid",
        label: "TripId",
        options: { filter: true, sort: true },
      },
      {
        name: "vehicle",
        label: "Vehicle",
        options: { filter: true, sort: true },
      },
      {
        name: "originname",
        label: "Origin",
        options: { filter: true, sort: true },
      },
      {
        name: "destinationname",
        label: "Destination",
        options: { filter: true, sort: true },
      },
      {
        name: "driver1name",
        label: "Driver1",
        options: { filter: true, sort: true },
      },
      {
        name: "driver2name",
        label: "Driver2",
        options: { filter: true, sort: true },
      },
      {
        name: "totalkms",
        label: "Total (kms)",
        options: { filter: true, sort: true },
      },
      {
        name: "dieselinltr",
        label: "Diesel (Ltr)",
        options: { filter: true, sort: true },
      },
      {
        name: "millage",
        label: "Millage",
        options: { filter: true, sort: true },
      },
      {
        name: "loading",
        label: "Loading",
        options: { filter: true, sort: true },
      },
      {
        name: "unloading",
        label: "unloading",
        options: { filter: true, sort: true },
      },
      {
        name: "differance",
        label: "Differance",
        options: { filter: true, sort: true },
      },
      {name: "indate",label: "In Date",options: {filter: false,sort: true,sortOrder: 'asc', 
      customBodyRender:(tableMeta)=>{
        return(moment(tableMeta).format('DD-MM-YYYY'))
      }}},
      {name: "outdate",label: "Out Date",options: {filter: false,sort: true,sortOrder: 'asc', 
      customBodyRender:(tableMeta)=>{
        return(moment(tableMeta).format('DD-MM-YYYY'))
      }}},
      {name: "totalexp",label: "Total Expensives",
          options: {filter: false,sort: true,
            customBodyRender:(tableMeta)=>{
              return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
            },
            setCellProps: value => ({ style: { textAlign:'right'}}),
          }},
    ];

    const column3 = [
      {
        name: this.state.selected === "1" ? "itemname" : "customer",
        label: this.state.selected === "1" ? "ItemName" : "Customer",
        options: { filter: false, sort: true, sortOrder: "asc" },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "totalprice",
        label: "Total Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total(Inc.Tax)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
           
            <ol className="breadcrumb float-sm-right">
              <li className="text-secondary">
              <span
                as="button"
                className="float-left text-secondary "
                onClick={() => this.props.navigate(-1)}
              >
               ❮ Back &nbsp;&nbsp;
              </span>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/assetmanagement">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/transportationreport">Transportation Reports</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>

            <div className="card-body">
              <div className="text-center">
                {this.state.loading === true ? spin : ""}
              </div>
              <Formik initialValues={{}} enableReinitialize={true}>
                {() => (
                  <Form autoComplete="off">
                    {this.state.pagename === "Vehicle trip details" ? (
                      <MUIDataTable
                        className="table-sm px-3"
                        title={`${this.state.item}`}
                        data={this.state.data}
                        columns={column1}
                        options={options}
                      />
                    ) : (
                      ""
                    )}

                    {this.state.pagename === "Driver trip details" ? (
                      <MUIDataTable
                        className="table-sm px-3"
                        title={`${this.state.item}`}
                        data={this.state.data}
                        columns={column2}
                        options={options}
                      />
                    ) : (
                      ""
                    )}

                    {this.state.pagename === "Sales by User" ? (
                      <MUIDataTable
                        className="table-sm px-3"
                        title={`${this.state.item}`}
                        data={
                          this.state.selected === "1"
                            ? this.state.data.item
                            : this.state.data.cust
                        }
                        columns={column3}
                        options={options}
                      />
                    ) : (
                      ""
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(TransportationReportdetails);
