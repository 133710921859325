import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

class AccTopService{
    
   

   retriveAllAccTop(){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/gettopheads`)
      }

      retriveAccTop(id){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getacctop/${id}`)
      }

     
      createAcctop(newAccTop){
         return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/addnewacctop`,newAccTop)
      }

      updateAcctop(updateAccTop){
         return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/updateacctop`,updateAccTop)
      }

   
      deleteAccTop(id){
         return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/deleteacctop/${id}`,)
      }


}

export default new AccTopService();
