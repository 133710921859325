import axios from "axios";
import { Auth_Proto, Auth_Domain, Auth_Port } from "../Component/consts";

export let currentTenant;
class InventoryService {
  GetTenantdetails(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/gettenants/${currentTenant}`
    );
  }
  // Get Data of All Items
  retriveAllItems(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getiteminfo/${currentTenant}`
    );
  }

  getDamageData(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getalldamagedata/${currentTenant}`
    );
  }

  GetDamagedDataById(id){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getdamagedatabyid/${id}`
    );
  }


  getDamageDataForAcc(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getalldamagedataforacc/${currentTenant}`
    );
  }

  ApproveLosses(id){
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/approvelosses/${id}`,);
  }

  EditLossesentery(data){
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/editlossesentry`,data);
  }

  getDamageDataById(id){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getdamagedatabyid/${id}`
    );
  }

  GetDataToAddStock(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getdatatoaddstock/${currentTenant}`
    );
  }

  GetItemsforStockissue(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getitemsforstockissue/${currentTenant}`
    );
  }

  //  Adding Item
  createItems(newitems) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/additembyhsn`,
      newitems
    );
  }

   //  Adding multipule Items 
  Addmultipleitems(newitems){
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/addmultipleitems`,
      newitems
    );
  }

  getLedger(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getledger/${currentTenant}`
    );
  }

  // getting Category List
  GetItems(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getdataforsales/${currentTenant}`
    );
  }
  // Data to Upadate Item
  retriveItem(id) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/datatoupdate/${id}`
    );
  }

  // Update Item
  updateItems(updateitems) {
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/updateitembyhsn`,
      updateitems
    );
  }

  // Delete Item
  deleteItem(id) {
    return axios.delete(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/deleteitem/${id}`
    );
  }

  // Get Stock table Data
  retriveStock(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getitemstock/${currentTenant}`
    );
  }

  // Data to add Stock(getdatatoaddstock)
  stockData(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getdatatoaddstock/${currentTenant}`
    );
  }

  // Adding Stock
  addStock(addstock) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/additemstock`,
      addstock
    );
  }

  // Add third party stock
  addthirdpartystock(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/addthirdpartystock`,data)
  }

  // Get Thirdparty stock
  GetThirdpartystock(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getthirdpartystock/${currentTenant}`,)
  }

  // Stock data for Update
  stockDataToUpdate(sid) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getdatatoupdatestock/${sid}`
    );
  }

  // Update Stock
  updateStock(updatestock) {
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/updateitemstock`,
      updatestock
    );
  }

  // Stock exist by item
  ExistbyItem(currentTenant,id){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/existsbyitem/${currentTenant}/${id}`
    );
  }
  // Get Cost Table Data
  retriveCost(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getcostoftenant/${currentTenant}`
    );
  }

  // Get UOM Table Data
  getUOM() {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getuom`
    );
  }

  // Adding Cost
  addCost(addCost) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/additemcost`,
      addCost
    );
  }

  // Update Cost
  updateCost(updateCost) {
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/updatecostofitem`,
      updateCost
    );
  }

  // Data to update Cost
  updatedataCost(id) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getdataofcost/${id}`
    );
  }

  // Cost of itemid
  costDataById(id) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getallcostsofitemid/${id}`
    );
  }

  // Warehouse of Stockid
  //   warehousedatabystockid(id) {
  //    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getwarehouseofstock/${id}`)
  // }

  // ItemNames by TenentId
  getItemNames(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getidandnameoftenantid/${currentTenant}`
    );
  }

  // get only Raw Metrials
  getRawMaterialItem(currentTenant, whid) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getrawmaterialstock/${currentTenant}/${whid}`
    );
  }

  getRawMaterials(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getrawmaterials/${currentTenant}`
    );
  }



  // Cost Data
  costData(cid) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getinfoofcost/${cid}`
    );
  }

  // Validating Cid on Sid
  validateCid(cid) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/validatecost/${cid}`
    );
  }

  addService(servicedata) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/addservice`,
      servicedata
    );
  }
  getServices(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getservices/${currentTenant}`
    );
  }

  // Update Service
  updateService(updateservice) {
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/updateservice`,
      updateservice
    );
  }

  // Delete Service
  deleteService(id) {
    return axios.delete(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/deleteservice/${id}`
    );
  }

  // Inventory Reports Data
  GetInvReports(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getinventoryreports/${currentTenant}`
    );
  }

  // Stock Reports Data
  GetStockReports(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/stockreports/${currentTenant}`
    );
  }

  // warehouse Reports Data
  GetWarehouseReports(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getallwhstockreport/${currentTenant}`
    );
  }

  GetWHdata(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getdataforsalesbywh/${currentTenant}`
    );
  }

  GetStockIssue(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getallbsi/${currentTenant}`
    );
  }

  AddStockIssue(addstockissue) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/addbsi`,
      addstockissue
    );
  }
  GetAcceptedStockIssue(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getallstockissues/${currentTenant}`
    );
  }
  AcceptStockIssue(addstockissue) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/addstockissue`,
      addstockissue
    );
  }

  AddDepreciation(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/adddepr`,
      data
    );
  }
  GetDepreciation(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getdeprdata/${currentTenant}`
    );
  }

  DeleteDepreciation(id) {
    return axios.delete(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/deletedepr/${id}`
    );
  }

  getItemsWithCatAndAmt(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/inventorydetails/${currentTenant}`
    );
  }

  AddDamage(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/adddamage`,
      data
    );
  }
  Damage(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getdamage/${currentTenant}`
    );
  }

  //Get All AssetsItems

  GetAssetsItems(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getassetitems/${currentTenant}`
    );
  }

  GetThirdPartyAassetItems(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getthirdpartyassetitems/${currentTenant}`
    )
  }

  ConsolidateReport(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getconsolidatedreport/${currentTenant}`
    );
  }
  invItems(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/inventoryitemtypevalue/${currentTenant}`
    );
  }
  
  getDirStockEntry(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getitemstockforaccounts/${currentTenant}`
    );
    }

    getDamageReportsData(data){
      return axios.post(
        `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getdamagereports`,data
      );
    }

    getbyitemforconsumption(data){
      return axios.post(
        `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getbyitemforconsumption`,data
      );
    }

    getStocktransactionData(data){
      return axios.post(
        `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getstocktransactiondata`,data
      );
    }

    AddInternalConsumption(data){
      return axios.post(
        `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/addinternalconsumption`,data
      );
    }

    GetAllInternalusebyTenantId(currentTenant){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getallinternalconsumption/${currentTenant}`,)
    }

    getinternalconsumptionstock(currentTenant){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getinternalconsumptionstock/${currentTenant}`,)
    }
}

export default new InventoryService();
