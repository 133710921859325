import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import Menu from '../../Component/Menu'
import { currentTenant, dataaccess, financialyearend, financialyearstart, userid, userwhid } from '../../Common/Credentials'
import SalesServices from '../SalesServices'
import { spin } from '../../Common/NewLoader'
import { salesid } from '../../Common/Permissions'
import CustomToolbar from '../../CommonComponents/CustomToolbar'
import MUIDataTable from 'mui-datatables'
import { Grid, TextField, ThemeProvider, } from "@mui/material";
import { createTheme } from "@mui/material";
import UsercustomerService from '../../Masters/Usercustomers/UsercustomerService'
import moment from 'moment';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});
class SalesReturns extends Component {

  constructor(props) {
    super(props) 
    this.state = {
      SalesReturnsData:[],
      usercustomers:[],
      loading:false,
      fromdate: financialyearstart,
      todate: financialyearend
    }
  }
    
  refresh(from,to){

    let datadao = {
      tenantid: currentTenant,
      startdate: from,
      enddate: to,
      userid:userid,
      dataaccess: dataaccess,
      whid:userwhid
    }

    SalesServices.GetSalesReturns(datadao)
      .then(
        response => {  
          this.setState({ SalesReturnsData: response.data?.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).filter(e=>this.state.usercustomers && this.state.usercustomers.length ===0?e:this.state.usercustomers.includes(e.customer)),loading:false })  
        }   
    )
  }

  handleFromDateChange = (date) => {
    this.setState({
      fromdate: date,
      todate:
        moment(date).diff(this.state.todate, "days") >= 0
          ? this.state.todate
          : date,
    });
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  handleDateChange = () => {
    this.refresh(this.state.fromdate, this.state.todate);
  };
  
  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    this.setState({loading:true})
    // UserService.UserPermissions(userid).then(response=>this.setState({UserPermissions:response.data}))

    

    UsercustomerService.GetUserCustomersbyUser(userid).then((res)=>this.setState({usercustomers:res.data===''||res.data===undefined?[]:res.data.customers.split(",")}))
    this.refresh(financialyearstart,financialyearend)
  }

  
    
  render() {

    const columns = [
      {name: "id",label: "Id",options: {filter: false,sort: false,sortOrder : 'asc', display:false,viewColumns:false}},
      {name: "customer",label: "Customer",options: {filter: true,sort: true,}},
      {name: "customsoid",label: "Saleorder Id",options: {filter: false,sort: true,
        customBodyRender:(value)=>{
          return value=== null ? "Direct Invoice" :value
        },
      }},
      {name: "invoice",label:"Invoice",options: {filter: true,sort: true,}},
      {name: "customdnid",label: "DN Id",options: {filter: false,sort: true,}},
      {name: "createdbyname",label:"Created by",options: {filter: true,sort: true,}},
      {name: "createdAt",label: "Date",options: {filter: false,sort: true,sortOrder: 'asc', 
        customBodyRender:(tableMeta)=>{
          return(moment(tableMeta).format('DD-MM-YYYY HH:MM'))
        }}},
      {
        name: "View",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
           return (<>
             <Link as="button" data-tip data-for="view details" className="fa fa-list-alt" 
             to={{pathname: "/salesreturnsorderdetails"}} state ={{id:tableMeta.rowData[4]}}></Link>
             {/* <Tooltip id="view details" place="top" effect="solid">View</Tooltip> */}
             </>
           );
          }
        }
      },
    ];
     const options = {
      filterType: 'checkbox',
      selectableRows: "none",
      customToolbar: () => {
       return (<>
        {salesid === '2' || salesid === '3'?
       <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/newsaleorderreturn"}} state ={{ id: -1, pagename: 'New SalesReturn'  }}>
         <CustomToolbar />
       </Link>
       :''
        }
       </>
       );
     },
     onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    downloadOptions: {
      filename: "Sale Returns",
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    }
     
    return (
          
      <div>
         <Header />
         <Menu/>
      <div className="content-wrapper">  
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/sales">Home</Link>
            </li>
            
            <li className="breadcrumb-item active">Sales Returns</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">              
            
                  <div className="">                
                     
                    <div className="card-body">
                    {this.state.loading === true ?<div className="text-center">{spin}</div>:<>

                    <div className="form-row g-0 mb-3">
                                <fieldset>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-around"
                                    >
                                      <DatePicker
                                        variant="inline"
                                        id="date-picker-dialog"
                                        label="From Date"
                                        name="from"
                                        format="dd/MM/yyyy"
                                        maxDate={new Date()}
                                        value={this.state.fromdate}
                                        onChange={this.handleFromDateChange}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                          />
                                        )}
                                        autoOk={true}
                                      />
                                    </Grid>
                                  </LocalizationProvider>
                                </fieldset>

                                <fieldset style={{ marginLeft: "30px" }}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-around"
                                    >
                                      <DatePicker
                                        variant="inline"
                                        id="date-picker-dialog"
                                        label="To Date"
                                        name="to"
                                        format="dd/MM/yyyy"
                                        minDate={this.state.fromdate}
                                        value={this.state.todate}
                                        onChange={this.handleToDateChange}
                                        maxDate={financialyearend}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                          />
                                        )}
                                        autoOk={true}
                                      />
                                    </Grid>
                                  </LocalizationProvider>
                                </fieldset>
                                <fieldset className="form-group col-md-1">
                                  <button
                                    className="btn btn-sm hovbuttonColour mt-3"
                                    onClick={this.handleDateChange}
                                  >
                                    <i className="fas fa-filter" />
                                  </button>
                                </fieldset>
                              </div>
                      
                         <ThemeProvider theme={theme}> 
                        <MUIDataTable
                              className="table-sm px-3"
                              title={"Sales Returns"}
                              data={this.state.SalesReturnsData}
                              columns={columns}
                              options={options}
                            />
                            </ThemeProvider>
                            </> }
                      </div>
                    </div>
                  {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
              {/* /.row */}
              </div>
            {/* /.container-fluid */}
            </section>
            
          </div>
        </div>
        <Footer />
      </div>      
    )  
  }  
}
export default withRouter(SalesReturns)