import axios from "axios";
import { Auth_Proto, Auth_Domain, Auth_Port } from "../../Component/consts";

class WarehouseServices {

  getWarehouse(id){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/findbywhid/${id}`
    );
  }
  
  getWarehouses(id) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getwhbytenant/${id}`
    );
  }

  getWarehousesidname(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getwhidnameoftenant/${currentTenant}`
    );
  }
  addwarehouse(addwarehouse) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addwarehouse`,
      addwarehouse
    );
  }
  EditWarehouse(addwarehouse) {
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/editwarehouse`,
      addwarehouse
    );
  }

  deleteWarehouse(id) {
    return axios.delete(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletewarehouse/${id}`
    );
  }
}
export default new WarehouseServices();
