import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  clientname,
  currentTenant,
  logo,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
// import moment from "moment";
import { spin } from "../../Common/NewLoader";
import { productionid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ProductionServices from "../ProductionServices";
import { FormControlLabel } from "@mui/material";
import IOSSwitch from "../../CommonComponents/IOSSwitch.js";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class ImmediateTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false,
      salestype: false,
      active: [],
      completed: [],
      cancelled: [],
      so: true,
      comp: false,
      canl: false,
    };
  }

  handlesoChange = () => {
    this.setState({ so: true, comp: false, canl: false });
  };
  handlecompChange = () => {
    this.setState({ comp: true, so: false, canl: false });
  };
  handlecanlChange = () => {
    this.setState({ canl: true, so: false, comp: false });
  };

  refresh() {
    ProductionServices.getallImmediateproductions(currentTenant).then(
      (response) => {
        this.setState({
          data: response.data.sort((a, b) =>
            a.createdAt < b.createdAt ? 1 : -1
          ),
          active: response.data
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            .filter((e) => e.status === "Pending"),
          completed: response.data.filter((e) => e.status === "Completed"),
          cancelled: response.data.filter((e) => e.status === "Cancelled"),
        });
      }
    );
    this.setState({ loading: false });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.refresh();
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumn: false,
        },
      },
      {
        name: "customid",
        label: "Id",
        options: { filter: false, sort: true, sortOrder: "desc" },
      },
      {
        name: "warehouse",
        label: "Warehouse",
        options: { filter: true, sort: true },
      },
      { name: "soid", label: "SOId", options: { filter: true, sort: true } },
      {
        name: "createdbyname",
        label: "Created By",
        options: { filter: true, sort: true },
      },
      {
        name: "appxamount",
        label: "Approximate Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "status",
        label: "Status",
        options: { filter: true, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-yyyy hh:mm");
          },
        },
      },
      {
        name: "View",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view"
                  className="fa fa-list-alt"
                  style={{ color: "#152f5f" }}
                  to={{
                    pathname: "/immediateproductiondetails",
                  }}
                  state={tableMeta.rowData.find((e) => e)}
                ></Link>
                {/* <Tooltip id="view" place="top" effect="solid">
                  View
                </Tooltip> */}
              </>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {productionid === "2" || productionid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{
                  pathname: "/newimmediateproduction",
                }}
                state={{
                  id: -1,
                  pagename: "New Immediate Production",
                  salestype: false,
                }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      downloadOptions: {
        filename: "Purchase Order",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
    };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>

              <li className="breadcrumb-item active">Immediate Production</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Immediate Production</h3>
                      </div>

                      <div className="card-body">
                        {this.state.loading === true ? (
                          <span className="text-center">{spin}</span>
                        ) : (
                          <>
                            <div className="form-row">
                              <fieldset className="form-group col-md-4">
                                <span className="mx-3">Pending</span>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      className="sm"
                                      checked={this.state.so}
                                      onChange={this.handlesoChange}
                                      name="checkedB"
                                    />
                                  }
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-4">
                                <span className="mx-3">Completed</span>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      className="sm"
                                      checked={this.state.comp}
                                      onChange={this.handlecompChange}
                                      name="checkedB"
                                    />
                                  }
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-4">
                                <span className="mx-3">Cancelled</span>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      className="sm"
                                      checked={this.state.canl}
                                      onChange={this.handlecanlChange}
                                      name="checkedB"
                                    />
                                  }
                                />
                              </fieldset>
                            </div>
                            {this.state.so === true ? (
                              <ThemeProvider theme={theme}>
                                <MUIDataTable
                                  className="table-sm px-3"
                                  title={"Immediate Production"}
                                  data={this.state.active}
                                  columns={columns}
                                  options={options}
                                />
                              </ThemeProvider>
                            ) : (
                              ""
                            )}

                            {this.state.comp === true ? (
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Completed Immediate Production"}
                                data={this.state.completed}
                                columns={columns}
                                options={options}
                              />
                            ) : (
                              ""
                            )}

                            {this.state.canl === true ? (
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Cancelled Immediate Production"}
                                data={this.state.cancelled}
                                columns={columns}
                                options={options}
                              />
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(ImmediateTable);
