import { Autocomplete, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { currentTenant, userid } from "../Common/Credentials";
import { FormikTextField } from "../CommonComponents/FormField";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import Employeeservices from "../Masters/Employees/Employeeservices";
import withRouter from "../withRouter";
import EmployeeService from "./EmployeeService";

const AddEmployeeComponent = () => {
  const history = useLocation();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [birthday, setBirthday] = useState("");
  const [dept, setDept] = useState("");
  const [empPresentJobPosition, setEmpPresentJobPosition] = useState("");
  const [tenantid, setTenantid] = useState("");
  const [basicSalary, setBasicSalary] = useState(0);
  const [houseRentAllownace, setHouseRentAllownace] = useState(0);
  const [conveyanceAllowances, setConveyanceAllowances] = useState(0);
  const [medicalAllowances, setMedicalAllowances] = useState(0);
  const [specialAllowances, setSpecialAllowances] = useState(0);
  const [grossSalary, setGrossSalary] = useState(
    basicSalary +
      houseRentAllownace +
      conveyanceAllowances +
      medicalAllowances +
      specialAllowances
  );
  const [epf, setEpf] = useState(0);
  const [healthInsurance, setHealthInsurance] = useState(0);
  const [professionalTax, setProfessionalTax] = useState(0);
  const [totalDeduction, setTotalDeduction] = useState(0);
  const [netpay, setNetpay] = useState(0);
  const [emergencyphoNumber, setEmergencyphoNumber] = useState(0);
  const [empLearningInstitution, setEmpLearningInstitution] = useState("");
  const [empLastJobPosition, setEmpLastJobPosition] = useState("");
  const [empHealthCondition, setEmpHealthCondition] = useState("");
  const [empSpecialSkills, setEmpSpecialSkills] = useState("");
  const [bankName, setBankName] = useState("");
  const [bankNo, setBankNo] = useState(0);
  //SalaryBreakup Stored
  const [getAllSal, setGetAllSal] = useState([]);
  const [salaryfor, setSalaryfor] = useState("");

  const [statusTab, setStatusTab] = useState("");
  const [dd, setdd] = useState([]);
  const [EmployeeList,setEmployeeList] = useState([]);
  const [empid,setEmpid] = useState('')
  const [empname,setEmpname] = useState('')
  const [incometax,setIncometax] = useState(0)
  const [additionalbenefits, setAdditionalbenefits ] = useState(0)


  //nevigate use to nevigate or forward to another page
  const navigate = useNavigate();

  // useEffect function for fetching the all salary Breakup data
  useEffect(() => {
    EmployeeService.getAllSalaryBrkup(currentTenant)
      .then((response) => {
        setGetAllSal(response.data);
      })
      .catch((err) => console.log(err));

      Employeeservices.getAllEmployees(currentTenant).then(res=>setEmployeeList(res.data.filter(e=>!history.state.emplist.includes(e.customid))));
  }, []);

  const notRefresh = (e) => {
    e.preventDefault();
  };

  //SalaryFor onChange Handler
  const handleChangeFor = (e,values) => {
    console.log(values.salaryfor);
    setSalaryfor(values.salaryfor);
    setdd(getAllSal.find(a=>a.salaryfor === values.salaryfor))
    basicCal(basicSalary,getAllSal.find(a=>a.salaryfor === values.salaryfor));
    // EmployeeService.getforByid(e.target.value).then((res) => {
      // setdd(res.data);
    // });
  };

  //Gross Salary Button Calvulation
  const grossSalarycalc = () => {
    if (
      !basicSalary &&
      !houseRentAllownace &&
      !conveyanceAllowances &&
      medicalAllowances &&
      !specialAllowances
    ) {
      alert("all these fields are required");
    } else {
      var tot =
        parseInt(basicSalary) +
        parseInt(houseRentAllownace) +
        parseInt(conveyanceAllowances) +
        parseInt(medicalAllowances) +
        parseInt(specialAllowances);
      if (tot > 0) {
        setGrossSalary(tot);
      } else {
        alert("Please Enter All the Correct Numbers");
        setGrossSalary(0);
      }
    }
  };

  //Gross Salary Total Deduction Calculation
  const calcTotalDeduction = (pf,hi,pt,it) => {
        setTotalDeduction(parseFloat(pf) + parseFloat(hi) + parseFloat(pt) + parseFloat(it))
        setNetpay(parseFloat(grossSalary) - (parseFloat(pf) + parseFloat(hi) + parseFloat(pt) + parseFloat(it)) )
  };

  //OnClick to total Net pay Button Calculation
  const totalNetpay = () => {
    let totnetpay = parseFloat(grossSalary) - parseFloat(totalDeduction);
    setNetpay(totnetpay);
  };

  //Handle Basic Salary function
  const handlebasicsal = (e) => {
    if (basicSalary !== null && basicSalary !== undefined) {
      setBasicSalary(parseFloat(e.target.value===undefined || e.target.value === ''?0:e.target.value));
      setGrossSalary(additionalbenefits + parseFloat(e.target.value===undefined || e.target.value === ''?0:e.target.value))
      setNetpay((additionalbenefits + parseFloat(e.target.value===undefined || e.target.value === ''?0:e.target.value)) - parseFloat(totalDeduction))
    }
  };

  //Calculate Basic Salary Here After getting the data from the user
  const basicCal = (data,brpparams) => {
    console.log(data,brpparams,'ee')
      setHouseRentAllownace((data * brpparams.houserentallowance) / 100);
      setConveyanceAllowances((data * brpparams.conveyanceallowance) / 100);
      setMedicalAllowances((data * brpparams.medicalallowance) / 100);
      setSpecialAllowances((data * brpparams.specialallowance) / 100);
      setEpf((data * brpparams.epf) / 100);
      setHealthInsurance((data * brpparams.healthinsurance) / 100);
      setProfessionalTax(() => {
        if (data > 50000) {
          setProfessionalTax((data * 12) / 100);
        } else {
          setProfessionalTax(0);
        }
      });
  };

  //Form onSubmit Handler function
  const onSubmit = () => {
    // e.preventDefault();
    // console.log("adding function");
    // let emp = {
    //   empFullName: fullName,
    //   empEmail: email,
    //   empAddress: address,
    //   phoNumber: phoneNumber,
    //   birthday: birthday,
    //   bloodGroup: bloodGroup,
    //   dept: dept,
    //   empPresentJobPosition: empPresentJobPosition,
    //   tenantid: tenantid,
    //   salaryDao: {
    //     basicSalary: basicSalary,
    //     houseRentAllowances: houseRentAllownace,
    //     conveyanceAllowances: conveyanceAllowances,
    //     medicalAllowances: medicalAllowances,
    //     specialAllowances: specialAllowances,
    //     grossSalary: grossSalary,
    //     epf: epf,
    //     healthInsurance: healthInsurance,
    //     professionalTax: professionalTax,
    //     totalDeduction: totalDeduction,
    //     netpay: netpay,
    //     salaryfor: salaryfor,
    //   },
    //   employeeDetailsDao: {
    //     emergencyphoNumber: emergencyphoNumber,
    //     empLearningInstitution: empLearningInstitution,
    //     empLastJobPosition: empLastJobPosition,
    //     empHealthCondition: empHealthCondition,
    //     empSpecialSkills: empSpecialSkills,
    //     bankName: bankName,
    //     bankNo: bankNo,
    //   },
    // };

      let  salaryDao= {
        basicSalary: basicSalary,
        houseRentAllowances: houseRentAllownace,
        conveyanceAllowances: conveyanceAllowances,
        medicalAllowances: medicalAllowances,
        specialAllowances: specialAllowances,
        additionalbenefits:additionalbenefits,
        grossSalary: grossSalary,
        epf: epf,
        healthInsurance: healthInsurance,
        professionalTax: professionalTax,
        totalDeduction: totalDeduction,
        netpay: netpay,
        incometax:incometax,
        salaryfor: salaryfor,
        tenantid:currentTenant,
        createdby:userid,
        empid:empid
      }
    EmployeeService.SaveEmployeeSalary(salaryDao).then(() => navigate(-1));
  };

  const handleEmployeeChange = (e,value) => {
    console.log(value)
    if (value === "" || value === null || value === undefined) {
      setEmpid('')
      setEmpname('')
    }else{
    setEmpid(value.id);
    setEmpname(value.first +" "+value.last)
    }
  };


  const validate = () => {

    let errors = {};
    if (empid === ""|| empid ===undefined) {
      errors.employee = "Please select employee";
    }

    if (basicSalary === ""|| basicSalary ===undefined || basicSalary === 0) {
      errors.basicSalary = "Please enter basic salary";
    }
    return errors;
  }

  return (
    <>
      <Header />
      <Menu />

      <div className="content-wrapper">
        <div className="card">
          <ol
            className="breadcrumb float-sm-right"
          >
            <li className=" text-secondary">
            <Link as="button" className=" text-secondary"  to={{ pathname: "/employeesalary" }}>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/accounts">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/employeesalary">Employees Salary</Link>
            </li>
            <li className="breadcrumb-item active ">Add Employee Salary</li>
            </ol>
            <div className="card-header m-0 p-2">
            Employee Salary in CTC(Annually)
            </div>
            <div className="card-body">
          
              <Formik
                initialValues={{}}
                onSubmit={onSubmit}
                validateOnChange={false}
                validate={validate}
                enableReinitialize={true}
              >
                {({setFieldValue,values}) => (
                  <Form autoComplete="off"  className="row">

                    <fieldset className="form-group col-md-4 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="employee"
                          options={EmployeeList}
                          getOptionLabel={(option) =>
                            option.first+" "+option.last
                        }
                          value={values.empname?undefined:undefined}
                          onChange={handleEmployeeChange}
                          renderInput={params => (  
                            <FormikTextField
                                {...params}
                              className="form-control"
                              type="text"
                              value={empname}
                              name="employee"
                              label={`Select Employee`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      {/* <fieldset className="form-group col-md-4 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="employee"
                          options={getAllSal}
                          getOptionLabel={(option) =>
                            option.salaryfor
                        }
                          value={values.emp?undefined:undefined}
                          onChange={handleChangeFor}
                          renderInput={params => (  
                            <FormikTextField
                                {...params}
                              className="form-control"
                              type="text"
                              value={salaryfor}
                              name="employee"
                              label={`Select For`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset> */}
                    
                          <fieldset className="col-md-12 my-2"/>
                        <fieldset className="col-md-3">
                          <TextField
                            label="Basic Salary"
                            required
                            type="number"
                            variant="standard"
                            name="basicSalary"
                            value={basicSalary}
                            onChange={(e) => handlebasicsal(e)}
                            fullWidth
                          />
                        </fieldset>

                        {/* <fieldset className="col-md-3">
                          <TextField
                          required
                            label="houseRentAllownace"
                            // disabled
                            variant="standard"
                            name="houseRentAllownace"
                            value={houseRentAllownace}
                            onChange={(e) =>
                              setHouseRentAllownace(e.target.value)
                            }
                            fullWidth
                          />
                        </fieldset>

                        <fieldset className="col-md-3">
                          <TextField
                          required
                            label="conveyanceAllowances"
                            // disabled
                            variant="standard"
                            name="conveyanceAllowances"
                            value={conveyanceAllowances}
                            onChange={(e) =>
                              setConveyanceAllowances(e.target.value)
                            }
                            fullWidth
                          />
                        </fieldset>

                        <fieldset className="col-md-3">
                          <TextField
                          required
                            label="medicalAllowances"
                            // disabled
                            variant="standard"
                            name="medicalAllowances"
                            value={medicalAllowances}
                            onChange={(e) =>
                              setMedicalAllowances(e.target.value)
                            }
                            fullWidth
                          />
                        </fieldset>

                        <fieldset className="col-md-3">
                          <TextField
                          required
                            label="specialAllowances"
                            // disabled
                            variant="standard"
                            name="specialAllowances"
                            value={specialAllowances}
                            onChange={(e) =>
                              setSpecialAllowances(e.target.value)
                            }
                            fullWidth
                          />
                        </fieldset> */}

                      <fieldset className="col-md-3">
                          <TextField
                            label="Additional Benefits"
                            // disabled
                            variant="standard"
                            name="additionalbenefits"
                            value={additionalbenefits}
                            onChange={(e) =>{
                              setAdditionalbenefits(parseFloat(e.target.value===undefined || e.target.value === ''?0:e.target.value))
                              setGrossSalary(basicSalary + parseFloat(e.target.value===undefined || e.target.value === ''?0:e.target.value))
                              setNetpay((basicSalary + parseFloat(e.target.value===undefined || e.target.value === ''?0:e.target.value)) - parseFloat(totalDeduction))
                            }}
                            fullWidth
                          />
                        </fieldset>

                        <fieldset className="col-md-3">
                          <TextField
                            label="Gross Salary"
                            disabled
                            variant="standard"
                            name="grossSalary"
                            value={grossSalary}
                            onChange={(e) => setGrossSalary(parseFloat(e.target.value))}
                            fullWidth
                          />
                        </fieldset>

                        <fieldset className="col-md-3">
                          <TextField
                            label="EPF"
                            variant="standard"
                            // disabled
                            name="epf"
                            value={epf}
                            onChange={(e) =>{
                            setEpf(parseFloat(e.target.value === undefined || e.target.value === ''?0:e.target.value))
                            calcTotalDeduction(parseFloat(e.target.value === undefined || e.target.value === ''?0:e.target.value),healthInsurance,professionalTax,incometax)
                          }}
                            fullWidth
                          />
                        </fieldset>

                        <fieldset className="col-md-3">
                          <TextField
                      
                            label="Health Insurance"
                            // disabled
                            variant="standard"
                            name="healthInsurance"
                            value={healthInsurance}
                            onChange={(e) =>{
                              setHealthInsurance(parseFloat(e.target.value === undefined || e.target.value === ''?0:e.target.value))
                              calcTotalDeduction(epf,parseFloat(e.target.value === undefined || e.target.value === ''?0:e.target.value),professionalTax,incometax)
                            }}
                            fullWidth
                          />
                        </fieldset>

                        <fieldset className="col-md-3">
                          <TextField
                          
                            label="Professional Tax"
                            // disabled
                            variant="standard"
                            name="professionalTax"
                            value={professionalTax}
                            onChange={(e) =>{
                              setProfessionalTax(parseFloat(e.target.value === undefined || e.target.value === ''?0:e.target.value))
                              calcTotalDeduction(epf, healthInsurance,parseFloat(e.target.value === undefined || e.target.value === ''?0:e.target.value),incometax)
                            }}
                            fullWidth
                          />
                        </fieldset>

                        <fieldset className="col-md-3">
                          <TextField
                          
                            label="Income Tax"
                            // disabled
                            variant="standard"
                            name="incometax"
                            value={incometax}
                            onChange={(e) =>{ 
                              setIncometax(parseFloat(e.target.value === undefined || e.target.value === ''?0:e.target.value))
                              calcTotalDeduction(epf, healthInsurance,professionalTax,parseFloat(e.target.value === undefined || e.target.value === '' ?0:e.target.value))
                            }}
                            fullWidth
                          />
                        </fieldset>

                        <fieldset className="col-md-3">
                          <TextField
                          
                            label="Total Deduction"
                            disabled
                            variant="standard"
                            name="totalDeduction"
                            value={totalDeduction}
                            // onChange={(e) => setTotalDeduction(e.target.value)}
                            fullWidth
                          />
                        </fieldset>

                        <div className="col-md-12 my-2"/>
                          {/* <button
                            className=" btn btn-sm hovbuttonColour mr-2"
                            type="button"
                            onClick={() => grossSalarycalc()}
                          >
                            Get Gross Salary
                          </button> */}
                          {/* <br /> */}
                          {/* <button
                            className="btn btn-sm hovbuttonColour mr-2"
                            type="button"
                            onClick={() => calcTotalDeduction()}
                          >
                            Get Total Deduction
                          </button> */}
                          <div className="col-md-12 my-2"/>

                        <fieldset className="col-md-3">
                          <TextField
                            label="netpay"
                            disabled
                            
                            variant="standard"
                            name="netpay"
                            value={netpay}
                            // onChange={(e) => setNetpay(e.target.value)}
                            fullWidth
                          />
                        </fieldset>

                        {/* <div className="col-md-12 my-2">
                          <button
                            type="button"
                            className="btn col-4 ml-1 btn-sm hovbuttonColour mr-2"
                            onClick={() => totalNetpay()}
                          >
                            Count Total Net Pay
                          </button>
                        </div> */}
                      
                <div />

                {/*Submit button*/}
                <div className="col-md-12"/>
                <div style={{ marginTop: "30px" }} className="ps-4">
                  <center>
                    <button
                      type="submit"
                      value="Add"
                      className=" btn btn-sm hovbuttonColour mr-2"
                    >
                      <i className="fa fa-save mr-1"></i>Submit
                    </button>
                    <Link
                      as="button"
                      className="btn btn-sm ml-1 deletebutton col-md-auto col-sm-12"
                      to={{ pathname: "/employeesalary" }}
                    >
                      <b style={{ color: "#FA5733" }}> X</b> Cancel
                    </Link>
                  </center>
                </div>
              
              </Form>
                )}
              </Formik>
            </div>
          
        </div>
      </div>
      <Footer />
    </>
  );
};
export default withRouter(AddEmployeeComponent);
