import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import moment from "moment";

import {
  address,
  address2,
  ccity,
  ccountry,
  clientname,
  companyemail,
  contactnumber,
  cstate,
  currency,
  currentTenant,
  czip,
  gstin,
  logo,
  pan,
  thousandsdisplay,
  userid,
} from "../Common/Credentials";
import { spin } from "../Common/NewLoader";
// import pdf from "../pdf/pdf";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { ToWords } from "to-words";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {NumericFormat} from 'react-number-format';
// import PurchaseService from "../../Purchase/PurchseServices"
import qr from '../images/E-invoice.jpg'
import SettingsServices from "../Settings/SettingsServices";
import BankServices from "../Masters/Bank/BankServices";
import AssetsService from "./AssetsService";
import CustomerService from '../Masters/Customers/CustomerService'


class RentalSoldInvoice extends Component {
  constructor(props) {
    super(props);
    console.log(this.props.location.state)
    const data = this.props.location.state.data;

    this.state = {
      pagename:this.props.location.state === undefined? "": this.props.location.state.pagename,
      type:this.props.location.state === undefined? "": this.props.location.state.type,
      data:data,
      Invoicedata: "",
      saleorderitems: [],
      milestones: [],
      id: '',
      stat: 0,
      loading: false,
      show: false,
      templete: "",
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: "",
      isError: false,
      fileInfos: [],
      fileInfo: "",
      partyid: "",
      error: false, //<---- here
      bankdetails:{},
      customerdetails:'',
      licenses:[]
    };
  }

  handleDownload = (Invoicedata) => {

    var i = new Image()
    i.src = logo
    let logowidth = 0 
    logowidth = ((i.width-i.height)/i.width)*100 
    let curdate = moment().format("DD-MM-YYYY HH:mm:ss");
    let data;
    let hsn = [];

    let invoicetype
    if(this.state.pagename === "Rental Invoice"){ invoicetype =  Invoicedata.rentassetsitems[0].amounttype}
   
      data = this.state.pagename === "Rental Invoice"? Invoicedata.rentassetsitems: Invoicedata.soldassetsitems
      data = data.map((v) => ({ ...v, cgstamt: ((v.amountinctax - v.amount) / 2).toFixed(2) }));
      data = data.map((v) => ({ ...v, cgst: v.tax / 2 }));
      data = data.map((v) => ({ ...v, sgstamt: ((v.amountinctax - v.amount) / 2).toFixed(2) }));
      data = data.map((v) => ({ ...v, sgst: v.tax / 2 }));
      hsn = data.map((e) => e.hsn);
    
      let supplytype= Invoicedata.gsttype.substring(0,1).toUpperCase() + Invoicedata.gsttype.substring(1,Invoicedata.gsttype.length).toLowerCase();
    let tablecolp;

    let totalinvoice = Invoicedata.totalamount.toFixed(2);
    let taxamount = Invoicedata.totalamountinctax-Invoicedata.totalamount.toFixed(2);
    let totalinvoicewtax = Invoicedata.totalamountinctax.toFixed(2);
    // let advance = Invoicedata.advanceamountreq

    // if (currency !== currency) {
    //   totalinvoice = totalinvoice * Invoicedata.curconversionrate;
    //   taxamount = taxamount * Invoicedata.curconversionrate;
    //   totalinvoicewtax = totalinvoicewtax * Invoicedata.curconversionrate;
    //   // advance = advance * Invoicedata.curconversionrate
    // }

    // if (Invoicedata.taxtype !== "NA") {
    //   if (Invoicedata.taxtype === "GST" && ccountry === "India") {
    //     if (Invoicedata.gsttype === "INTRASTATE") {
    //       tablecolp = [
    //         { header: "Name of Product / Service", dataKey: "itemname" },
    //         { header: "HSN/SCA", dataKey: "hsn" },
    //         {
    //           header: `Unit Price (${currency})`,
    //           dataKey: "amount",
    //         },
    //         { header: "Quantity", dataKey: "quantity" },
    //         {header:'UOM', dataKey:'uom'},
    //         { header: "CGST (%)", dataKey: "cgst" },
    //         { header: "SGST (%)", dataKey: "sgst" },
    //         {
    //           header: `CGST Amt. (${currency})`,
    //           dataKey: "cgstamt",
    //         },
    //         {
    //           header: `SGST Amt. (${currency})`,
    //           dataKey: "sgstamt",
    //         },
    //         {
    //           header: `Total (${currency})`,
    //           dataKey: "amountinctax",
    //         },
    //       ];
    //     } else {
    //       tablecolp = [
    //         { header: "Name of Product / Service", dataKey: "itemname" },
    //         { header: "HSN/SCA", dataKey: "hsn" },
    //         {
    //           header: `Unit Price (${currency})`,
    //           dataKey: "amount",
    //         },
    //         { header: "Quantity", dataKey: "quantity" },
    //         {header:'UOM', dataKey:'uom'},
    //         { header: "IGST (%)", dataKey: "tax" },
    //         {
    //           header: `IGST Amt. (${currency})`,
    //           dataKey: "taxamount",
    //         },
    //         {
    //           header: `Total (${currency})`,
    //           dataKey: "amountinctax",
    //         },
    //       ];
    //     }
    //   } else {
    //     if (Invoicedata.taxtype === "GST") {
    //       tablecolp = [
    //         { header: "Name of Product / Service", dataKey: "itemname" },
    //         { header: "HSN/SCA", dataKey: "hsn" },
    //         {
    //           header: `Unit Price (${currency})`,
    //           dataKey: "amount",
    //         },
    //         { header: "Quantity", dataKey: "quantity" },
    //         {header:'UOM', dataKey:'uom'},
    //         { header: "GST(%)", dataKey: "tax" },
    //         {
    //           header: `GST Amt. (${currency})`,
    //           dataKey: "taxamount",
    //         },
    //         {
    //           header: `Total (${currency})`,
    //           dataKey: "amountinctax",
    //         },
    //       ];
    //     } else {
    //       tablecolp = [
    //         { header: "Name of Product / Service", dataKey: "itemname" },
    //         { header: "HSN/SCA", dataKey: "hsn" },
    //         {
    //           header: `Unit Price (${currency})`,
    //           dataKey: "amount",
    //         },
    //         { header: "Quantity", dataKey: "quantity" },
    //         {header:'UOM', dataKey:'uom'},
    //         { header: "VAT(%)", dataKey: "tax" },
    //         {
    //           header: `VAT Amt. (${currency})`,
    //           dataKey: "taxamount",
    //         },
    //         {
    //           header: `Total (${currency})`,
    //           dataKey: "amountinctax",
    //         },
    //       ];
    //     }
    //   }
    // } else {
    //   tablecolp = [
    //     { header: "Name of Product / Service", dataKey: "itemname" },
    //     { header: "HSN/SCA", dataKey: "hsn" },
    //     { header: `Unit Price (${currency})`, dataKey: "amount" },
    //     { header: "Quantity", dataKey: "quantity" },
    //     {header:'UOM', dataKey:'uom'},
    //     {
    //       header: `Total (${currency})`,
    //       dataKey: "amountinctax",
    //     },
    //   ];
    // }

    tablecolp = [
      { header: "Name of Product / Service", dataKey: "itemname" },
      { header: "HSN/SCA", dataKey: "hsn" },
      { header: "Quantity", dataKey: "quantity" },
      {
        header:"Rate",
        dataKey: "amount",
      },
      { header: "UOM", dataKey: "uom" },
      // { header: "Discount", dataKey: "discountamount" },
      {
        header: `Amount`,
        dataKey: "amountinctax",
      },
    ];

    let order =""
    let date = moment(this.state.Invoicedata.createdAt).format("DD-MM-yyyy");
   
    const toWords = new ToWords();
    var totalinwords = toWords.convert(totalinvoicewtax);
    const doc = new jsPDF("p", "pt", [842, 595], true);

    const pageSize = doc.internal.pageSize;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
    let y = 0;
    y = 10 * this.state.licenses.length;
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#000");

    // doc.setLineWidth(0.1);
    // doc.setDrawColor("#808080")
    // doc.line(30, yax + 5, 580, yax + 5);
    doc.setFontSize(10);
    doc.text(`${order}`, 250, 40);
    doc.setFontSize(7);
    if (order === "TAX INVOICE") {
    doc.setFontSize(7);
    doc.text(`(ORIGINAL FOR RECIPIENT)`, 480,40);
    }

    doc.setDrawColor(192, 192, 192);
    doc.setFontSize(7);
    doc.text(`${curdate}`, 40, 40);
    doc.addImage(
      logo,
      "GIF",
      pageWidth - 100 - logowidth,
      50,
      80 + logowidth,
      80
    );
    doc.setFontSize(10);
    doc.text(`${clientname.toUpperCase()}`, 40, 62);
    doc.setFontSize(7);
    doc.text(`${address}`, 40, 75);
    doc.text(`${address2}`, 40, 85);
    doc.text(`${ccity}, ${cstate},${ccountry} - ${czip}`, 40, 95);
    doc.text(`${companyemail}, ${contactnumber}`, 40, 105);
    if(gstin!==null && gstin!== undefined && ccountry === "India"){
    doc.text(`GSTIN : ${gstin}`, 40, 115);
    }
    if(pan!==null && pan!== undefined && ccountry === "India") {
    doc.text(`PAN : ${pan}`, 40, 125);
    }
    let yax = 125;
    if (
      this.state.licenses !== null &&
      this.state.licenses !== undefined &&
      this.state.licenses !== ""
    ) {
      this.state.licenses.map((e, idx) => {
        yax = yax + 10;
        doc.text(`${e.name} - ${e.number}`, 40, yax);
      });
    }

    doc.setFontSize(7);
    doc.setLineWidth(0.1);
    // doc.setDrawColor("#808080")
    doc.line(30, yax + 10, 580, yax + 10);
    y = yax + 20;
    if (
      Invoicedata.einvoicestatus === "Generated" &&
      this.state.einvoicedata.irn !== undefined &&
      pagename === "Tax Invoice Details"
    ) {
      doc.addImage(this.state.QRcode, "GIF", 495, y-5, 75, 75);
      let irnno = doc.splitTextToSize(this.state.einvoicedata.irn, 300);
      doc.text("IRN", 40, y);
      doc.text(`:`, 120, y);
      doc.text(`${irnno}`, 130, y);
      doc.text(`Acknowledgement No.`, 40, y + 10);
      doc.text(`:`, 120, y + 10);
      doc.text(`${this.state.einvoicedata.ackno}`, 130, y + 10);
      doc.text(`Acknowledgement Date`, 40, y + 20);
      doc.text(`:`, 120, y + 20);
      doc.text(`${ackDt}`, 130, y + 20);
      // doc.text(`e-invoice`, 540, y);
      y = y - 165;
    } else {
      y = y - 195;
    }

    y = y+185

    doc.text(`${this.state.customerdetails.companyname.toUpperCase()}`, 40, y + 10);
    doc.text(`${this.state.customerdetails.address}`, 40, y + 20);
    doc.text(`${this.state.customerdetails.address2}`, 40, y + 30);
    doc.text(
      `${this.state.customerdetails.cityname}, ${this.state.customerdetails.statename}, ${this.state.customerdetails.countryname} - ${this.state.customerdetails.zip}`,
      40,
      y + 40
    );
    doc.text(
      `${this.state.customerdetails.phonecode}-${this.state.customerdetails.contactnumber}`,
      40,
      y + 50
    );
    if (this.state.customerdetails.gstin !== "" && this.state.customerdetails.gstin !== undefined && ccountry === "India") {
      doc.text(`GSTIN : ${this.state.customerdetails.gstin}`, 40, y + 60);
      doc.text(
        `PAN : ${this.state.customerdetails.gstin?.substring(2, 12)}`,
        180,
        y + 60
      );
    }

    doc.line(320, y, 320, y + 80);
    doc.line(415, y, 415, y+80);
    doc.text(`INVOICE ID`, 330, y + 10);
    doc.text(`${this.state.data.custominvid}`, 430, y + 10);
    doc.line(320, y+15, 580, y+15);
    doc.text(`DETAILED INVOICE ID`, 330, y + 25);
    doc.text(`${this.state.data.custominvid}`, 430, y + 25);
    doc.line(320, y+30, 580, y+30);
    doc.text(`INVOICE DATE`, 330, y + 40);
    doc.text(`${date}`, 430, y + 40);
    doc.line(320, y+45, 580, y+45);
    doc.text(`ORDER ID`, 330, y + 55);
    doc.text(`${Invoicedata.customid}`,430,y+55);
    doc.line(320, y+60, 580, y+60);
    if (Invoicedata.taxtype === "GST" && ccountry === "India") {
      doc.text(`SUPPLY TYPE`, 330, y + 70);
      doc.text(`${supplytype.toUpperCase()}`, 430, y + 70);
    }
    y = y + 75;
      doc.setDrawColor("#808080")
    doc.setLineWidth(0.1);
    doc.line(30, y, 580, y);

    //AutoTable

      autoTable(doc, {
        theme: "grid",
        startY: y,
        margin:{top: 0, bottom: 0,right:15,left:30},
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        didParseCell: function (data) {
          var rows = data.table.body;
          if (data.row.index === rows.length - 1 && data.section==="body") {
              data.cell.styles.minCellHeight = 70;
          }
      },
        styles: { textColor: [0, 0, 0], fontSize: "7", cellPadding: 2 },
        headStyles: {
          fillColor: [256, 256, 256],
          textColor: [0, 0, 0],
          fontStyle: "default",
          halign: "center",
          lineWidth: 0.1,
          // lineColor: [128, 128, 128],
        },
        bodyStyles: {
          halign: "center",
          // lineColor: [128, 128, 128],
          lineWidth:0.1
        },
        columnStyles: {
          0:{cellWidth: 225},
          3: { halign: "right" },
          5: { halign: "right" },
          6: { halign: "right" },
        },
        body: data,
        columns: tablecolp,
      });
     
    if(Invoicedata.tcsreq){
    doc.text(`TCS On Sales @${Invoicedata.tcs}%`, 180, doc.autoTable.previous.finalY - 25);
    doc.text(`${(Invoicedata.tcsamount).toFixed(2)}`, 578, doc.autoTable.previous.finalY - 25, "right");
    }

    doc.setDrawColor(192, 192, 192);
    y = doc.autoTable.previous.finalY + 10;
    if (y > 841) {
      doc.addPage([595.28, 841.89]);
      y = 50;

      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(7);
    }

    doc.text(`AMOUNT CHARGEABLE (in words) :`, 40, y);
    doc.text(`${totalinwords} Only`, 40, y+10);

    // doc.text(`ADVANCE : ${advance}`, 40, y+20);
    // doc.text(`${advanceinwords} Only`, 40, y+30);

    doc.text(`SUB TOTAL`, 490, y , "right");
    doc.text(`${totalinvoice}`, 578, y, "right");
    doc.line(430, y+5, 580, y+5);
    y = y + 15
    doc.line(430, y-25, 430, Invoicedata.taxtype === "GST" &&  ccountry === "India" &&
    Invoicedata.gsttype === "INTRASTATE"? y+35:y+20);

    doc.line(500, y-25, 500, Invoicedata.taxtype === "GST" && ccountry === "India" &&
    Invoicedata.gsttype === "INTRASTATE"? y+35:y+20);
    
    if (
      Invoicedata.taxtype === "GST" &&
      ccountry === "India" &&
      Invoicedata.gsttype === "INTRASTATE"
    ) {
      
    doc.text(`CGST`, 490, y , "right");
    doc.text(`${(taxamount / 2).toFixed(2)}`, 578, y, "right");
    doc.line(430, y+5, 580, y+5);
    doc.text(`SGST`, 490, y + 15, "right");
    doc.text(`${(taxamount / 2).toFixed(2)}`, 578, y + 15, "right");
    doc.line(430, y+20, 580, y+20);
    y = y+15
    }else if(Invoicedata.taxtype === "GST" && ccountry === "India"){
    doc.text(`IGST`, 490, y, "right");
    doc.text(`${taxamount}`, 578, y, "right");
    doc.line(430, y+5, 580, y+5);
    }else{

    doc.text(`${Invoicedata.taxtype} :`, 490, y, "right");
    doc.text(`${taxamount}`, 578, y, "right");
    doc.line(430, y+5, 580, y+5);
    }

    doc.text(`TOTAL`, 490, y + 15,"right");
    doc.text(`${totalinvoicewtax}`, 578, y + 15, "right");
    doc.setLineWidth(0.1);
    // doc.setDrawColor("#808080")
    doc.line(30, y + 20, 580, y + 20);

    y = y + 10;
    if (y + 150 > 841) {
      doc.addPage([595.28, 841.89]);
      y = 50;

      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(7);
    }
    // doc.text(`Advance Amount : ${advance} (${advanceinwords})`, 40, y + 10);
    y = y + 10;
    // doc.setLineWidth(0.1);
    // doc.setDrawColor("#808080")
    // doc.line(30, y, 580, y);
    doc.text("HSN / SAC", 100, y + 10);
    var splithsn = doc.splitTextToSize(hsn.join(", ").toString(), 245);
    doc.text(`${splithsn}`, 40, y + 25);
    doc.text("Total", 220, y + 40);
    doc.line(250, y, 250, y + 45);

    if (
      Invoicedata.taxtype === "GST" &&
      ccountry === "India" &&
      Invoicedata.gsttype === "INTRASTATE"
    ) {
      doc.text("Taxable Amount", 260, y + 10);
      doc.text(`${totalinvoice}`, 315, y + 25, "right");
      doc.text(`${totalinvoice}`, 315, y + 40, "right");
      doc.line(320, y, 320, y + 45);
      doc.text("CGST", 340, y + 10);
      // doc.text("%", 330, y + 40);
      // doc.text(`${((((taxamount/(totalinvoicewtax-taxamount-totaladditionalchares))*100)/2)).toFixed(2)}`, 330, y + 25);
      // doc.line(350, y + 20, 350, y + 45);
      // doc.text("Amount", 365, y + 40);
      doc.text(`${Invoicedata.gsttype === "INTRASTATE"?(taxamount / 2).toFixed(2):0.00}`, 370, y + 25, "right");
      doc.text(`${Invoicedata.gsttype === "INTRASTATE"?(taxamount / 2).toFixed(2):0.00}`, 370, y + 40, "right");
      doc.line(380, y, 380, y + 45);
      doc.text("SGST", 400, y + 10);
      // doc.text("%", 430, y + 40);
      // doc.text(`${((((taxamount/(totalinvoicewtax-taxamount-totaladditionalchares))*100)/2)).toFixed(2)}`, 430, y + 25);
      // doc.line(450, y + 20, 450, y + 45);
      // doc.text("Amount", 470, y + 40);
      doc.text(`${Invoicedata.gsttype === "INTRASTATE"?(taxamount / 2).toFixed(2):0}`, 430, y + 25, "right");
      doc.text(`${Invoicedata.gsttype === "INTRASTATE"?(taxamount / 2).toFixed(2):0}`, 430, y + 40, "right");
      doc.line(440, y, 440, y + 45);
      doc.text(`IGST`, 470, y + 10);
      doc.text(`${Invoicedata.gsttype === "INTRASTATE"?0.00:taxamount}`, 510, y + 25, "right");
      doc.text(`${Invoicedata.gsttype === "INTRASTATE"?0.00:taxamount}`, 510, y + 40, "right");
      // doc.line(460, y + 20, 460, y + 20);
      doc.text("Total Tax Amount", 525, y + 10);
      doc.text(`${taxamount}`, 578, y + 25, "right");
      doc.text(`${taxamount}`, 578, y + 40, "right");
      doc.line(520, y, 520, y + 45);
    } else {
      doc.text("Taxable Amount", 260, y + 10);
      doc.text(`${totalinvoice}`, 320, y + 25, "right");
      doc.text(`${totalinvoice}`, 320, y + 40, "right");
      doc.line(330, y, 330, y + 45);
      doc.text(
        Invoicedata.taxtype === "GST" && ccountry === "India"
          ? "IGST"
          : Invoicedata.taxtype === "GST"
          ? "GST"
          : "Vat",
        360,
        y + 10
      );
      // doc.line(500, y + 20, 350, y + 20);
      // // doc.text("%", 385, y + 40);
      // doc.text(`${((taxamount/(totalinvoicewtax-taxamount-totaladditionalchares))*100).toFixed(2)}`, 385, y + 25);
      // doc.line(425, y + 20, 425, y + 45);
      // doc.text("Amount", 442, y + 40);
      doc.text(`${taxamount}`, 390, y + 25, "right");
      doc.text(`${taxamount}`, 390, y + 40, "right");

      doc.line(400, y, 400, y + 45);
      doc.text(`Additional Charges`, 410, y + 10);
      doc.text(`${totaladdincinsu}`, 480, y + 25, "right");
      doc.text(`${totaladdincinsu}`, 480, y + 40, "right");

      doc.line(490, y, 490, y + 45);
      doc.text(`Total (${Invoicedata.currency})`, 520, y + 10);
      doc.text(`${totalinvoicewtax}`, 578, y + 25, "right");
      doc.text(`${totalinvoicewtax}`, 578, y + 40, "right");
    }

    doc.line(30, y + 15, 580, y + 15);
    doc.line(30, y + 30, 580, y + 30);
    doc.line(30, y + 45, 580, y + 45);
    y = y + 45;
    if (y + 150 > 841) {
      doc.addPage([595.28, 841.89]);
      y = 50;

      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(7);
    }
    // doc.line(30, y, 580, y);
    doc.text("Bank Details", 150, y + 15);
    doc.line(30, y + 25, 350, y + 25);
    doc.text("Bank Name", 40, y + 35);
    doc.text(":", 100, y + 35);
    doc.text(
      `${
        this.state.bankdetails.bankname === undefined
          ? ""
          : this.state.bankdetails.bankname
      }`,
      110,
      y + 35
    );
    doc.text("Bank Acc No.", 40, y + 45);
    doc.text(":", 100, y + 45);
    doc.text(
      `${
        this.state.bankdetails.accno === undefined
          ? ""
          : this.state.bankdetails.accno
      }`,
      110,
      y + 45
    );

    doc.text("Branch Name", 40, y + 55);
    doc.text(":", 100, y + 55);
    doc.text(
      `${
        this.state.bankdetails.branchname === undefined
          ? ""
          : this.state.bankdetails.branchname
      }`,
      110,
      y + 55
    );
    doc.text("IFSC", 40, y + 65);
    doc.text(":", 100, y + 65);
    doc.text(
      `${
        this.state.bankdetails.ifsc === undefined
          ? ""
          : this.state.bankdetails.ifsc
      }`,
      110,
      y + 65
    );

    doc.line(350, y, 350, y + 70);
    doc.text(
      "We certified that particulars given above are true and correct",
      380,
      y + 10
    );
    doc.text(`for ${clientname}`, 410, y + 20);
    doc.line(580, y + 25, 350, y + 25);
    doc.text("Authorised Signatory", 425, y + 65);
    doc.line(30, y + 70, 580, y + 70);
    y = y + 90;
    if (
      this.state.templete.terms !== undefined &&
      this.state.templete.terms !== null &&
      this.state.templete.terms.length > 0
    ) {
      if (y + 150 > 841) {
        doc.addPage([595.28, 841.89]);
        y = 50;

        doc.setFont("helvetica", "normal");
        doc.setTextColor("#000");
        doc.setFontSize(7);
      }
      doc.text("Terms and Conditions", 275, y + 15);
      doc.line(30, y + 20, 580, y + 20);
      var splitTitle = doc.splitTextToSize(this.state.templete.terms, 500);
      doc.text(
        40,
        y + 30,
        splitTitle === undefined || splitTitle === "undefined" ? "" : splitTitle
      );
    }

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const footer = `Page ${i} of ${pageCount}`;
      doc.line(30, 45, 580, 45); // top line
      doc.line(30, pageHeight - 45, 580, pageHeight - 45); //bottom line
      doc.line(30, 45, 30, pageHeight - 45); // left
      doc.line(pageWidth - 15, 45, pageWidth - 15, pageHeight - 45);

      doc.setFontSize(7);
      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 25,
        { baseline: "bottom" }
      );
    }
      doc.autoPrint({variant: 'non-conform'});
    

    doc.save(
      `${this.state.data.custominvid}.pdf`
    );
  }

 
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    let id = this.props.location.state.data.rentasset;
    this.setState({ loading: true });

    if(id===null||id===undefined){
        id = this.props.location.state.data.soldassets
        AssetsService.GetSoldAssetById(id)
        .then((response) => {
          this.setState({
            Invoicedata: response.data,
            saleorderitems: response.data.soldassetsitems,
          },()=>CustomerService.retriveCustomer(this.state.Invoicedata.custid).then(res=>this.setState({customerdetails:res.data})));
          this.setState({ loading: false });
        })
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
      }else{
        AssetsService.GetRentassetByid(id)
        .then((response) => {
          this.setState({
            Invoicedata: response.data,
            saleorderitems: response.data.rentassetsitems,
          },()=>CustomerService.retriveCustomer(this.state.Invoicedata.custid).then(res=>this.setState({customerdetails:res.data})));
          this.setState({ loading: false });
        })
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
      }

    let data = "Invoice";
    SettingsServices.GetActivePdfTemplate(data, currentTenant).then(
      (response) => this.setState({ templete: response.data })
    );

    BankServices.getActivebank(currentTenant).then(res=>this.setState({bankdetails:res.data}))

    SettingsServices.Getlicencesoftenant(currentTenant).then((res) =>
      this.setState({ licenses: res.data })
    );
    this.setState({ loading: false });
  }

  render() {
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <span
                as="button"
                className=" text-secondary"
                onClick={() => this.props.navigate(-1)}
              >
                ❮ Back &nbsp;&nbsp;
              </span>
              <li className="breadcrumb-item text-secondary">
                <Link to="/assetmanagement">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link
                  to={{pathname: "/salesinvoice"}}
                    state={{ type: this.state.type}}
                >
                  Sales Invoice
                </Link>
              </li>
              <li className="breadcrumb-item active"> {this.state.pagename}</li>
            </ol>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">{this.state.pagename}</h3>
                        <button
                              className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md"
                              onClick={() =>
                                this.handleDownload(this.state.Invoicedata)
                              }
                            ></button>
                      </div>

                      <div className="card-body">
                        <div>
                          {this.state.loading === true ? (
                            <div className="text-center">{spin}</div>
                          ) : (
                            <>
                              <div className="form-row">
                                <div className="form-column col-md-6">
                                  <table className="float-left mb-3">
                                    <tbody>
                                      <tr>
                                        <td>Invoice&nbsp;Id</td>
                                        <td>
                                          :&nbsp;&nbsp;{this.state.data.custominvid}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Order&nbsp;Id</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          {this.state.Invoicedata.customid}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Customer</td>
                                        <td data-tip data-for="custphno">
                                          :&nbsp;&nbsp;
                                          {this.state.Invoicedata.customer}
                                        </td>
                                      </tr>
                                      {this.state.Invoicedata.agent === null ||
                                      this.state.Invoicedata.agent ===
                                        undefined ||
                                      this.state.Invoicedata.agent === "" ? (
                                        ""
                                      ) : (
                                        <tr>
                                          <td>Agent</td>
                                          <td data-tip data-for="agentphno">
                                            :&nbsp;&nbsp;
                                            {this.state.Invoicedata.agent}
                                          </td>
                                          {/* //<Tooltip
                                            id="agentphno"
                                            place="top"
                                            effect="solid"
                                          >
                                            Agent Ph.{" "}
                                            {this.state.Invoicedata.agentphno}
                                          </Tooltip> */}
                                        </tr>
                                      )}
                                      <tr>
                                        <td>Invoice&nbsp;Date</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          {moment(
                                            this.state.Invoicedata.createdAt
                                          ).format("DD-MM-yyyy")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          Total Invoice Amount&nbsp;
                                        </td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          <NumericFormat
                                            className="pr-3"
                                            displayType={"text"}
                                            value={
                                              this.state.Invoicedata
                                                .totalamountinctax
                                            }
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Tax&nbsp;Type</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          {this.state.Invoicedata.taxtype}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>GST&nbsp;Type</td>
                                        <td>
                                          :&nbsp;&nbsp;
                                          {this.state.Invoicedata.gsttype}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="form-group col-md-3">
                                  <table className="float-left">
                                    <tbody>
                                      <tr>
                                        <td>Bill to,</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.customerdetails.contactname}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.customerdetails.address} {this.state.customerdetails.address2}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.customerdetails.cityname}
                                          ,&nbsp;{this.state.customerdetails.statename}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;{this.state.customerdetails.countryname}{" "}
                                          - {this.state.customerdetails.zip}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.customerdetails.phonecode}-{this.state.customerdetails.contactnumber}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div className="form-group col-md-3">
                                  <table className="float-left mb-3">
                                    <tbody>
                                      <tr>
                                        <td>Ship to,</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.customerdetails.contactname}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.customerdetails.address} {this.state.customerdetails.address2}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.customerdetails.cityname}
                                          ,&nbsp;{this.state.customerdetails.statename}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;{this.state.customerdetails.countryname}{" "}
                                          - {this.state.customerdetails.zip}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.customerdetails.phonecode}-{this.state.customerdetails.contactnumber}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <div className="form-group col-md-12"></div>

                              {this.state.saleorderitems.length === 0 ? (
                                ""
                              ) : (
                                <TableContainer className="mt-5">
                                  <Typography>Products</Typography>
                                  <Table
                                    aria-label="collapsible table"
                                    className="table-sm"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell className="text-center">
                                          Item Name
                                        </TableCell>
                                        {this.state.Invoicedata.taxtype ===
                                        "NA" ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            HSN
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Price
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Quantity
                                        </TableCell>
                                        <TableCell className="text-center">
                                          UOM
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Total{" "}
                                        </TableCell>
                                        
                                        {this.state.Invoicedata.taxtype ===
                                        "NA" ? (
                                          ""
                                        ) : (
                                          <>
                                            {this.state.Invoicedata.taxtype ===
                                              "GST" && ccountry === "India" ? (
                                              this.state.Invoicedata.gsttype ===
                                              "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    CGST(%)
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    SGST(%)
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <TableCell className="text-center">
                                                  IGST(%)
                                                </TableCell>
                                              )
                                            ) : this.state.Invoicedata
                                                .taxtype === "GST" ? (
                                              <TableCell className="text-center">
                                                GST(%)
                                              </TableCell>
                                            ) : (
                                              <TableCell className="text-center">
                                                VAT(%)
                                              </TableCell>
                                            )}
                                            {this.state.Invoicedata.taxtype ===
                                              "GST" && ccountry === "India" ? (
                                              this.state.Invoicedata.gsttype ===
                                              "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    CGST Amount
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    SGST Amount
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <>
                                                  <TableCell className="text-center">
                                                    IGST Amount
                                                  </TableCell>
                                                </>
                                              )
                                            ) : this.state.Invoicedata
                                                .taxtype === "GST" ? (
                                              <>
                                                <TableCell className="text-center">
                                                  GST Amount
                                                </TableCell>
                                              </>
                                            ) : (
                                              <>
                                                {" "}
                                                <TableCell className="text-center">
                                                  VAT Amount
                                                </TableCell>
                                              </>
                                            )}
                                            <TableCell className="text-center">
                                              Total Price
                                            </TableCell>
                                          </>
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.saleorderitems.map(
                                        (row, index) => (
                                            <TableRow key={index}>
                                              <TableCell className="text-center">
                                                {row.itemname}
                                              </TableCell>
                                              {this.state.Invoicedata
                                                .taxtype === "NA" ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  {row.hsn}
                                                </TableCell>
                                              )}
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.amount}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              
                                              <TableCell className="text-center">
                                                1
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {row.uom}
                                              </TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.amount}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.Invoicedata
                                                .taxtype === "NA" ? (
                                                ""
                                              ) : (
                                                <>
                                                  {this.state.Invoicedata
                                                    .taxtype === "GST" &&
                                                  this.state.Invoicedata
                                                    .gsttype ===
                                                    "INTRASTATE" ? (
                                                    <>
                                                      <TableCell className="text-center">
                                                        {row.tax / 2}%
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                        {row.tax / 2}%
                                                      </TableCell>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <TableCell className="text-center">
                                                        {row.tax}%
                                                      </TableCell>
                                                    </>
                                                  )}
                                                  {this.state.Invoicedata
                                                    .taxtype === "GST" &&
                                                  this.state.Invoicedata
                                                    .gsttype ===
                                                    "INTRASTATE" ? (
                                                    <>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            (row.amountinctax-row.amount) * 0.5
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                      </TableCell>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                           (row.amountinctax-row.amount) / 2
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                      </TableCell>
                                                    </>
                                                  ) : (
                                                    <>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={row.amountinctax-row.amount}
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                      </TableCell>
                                                    </>
                                                  )}
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={row.amountinctax}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                </>
                                              )}
                                            </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              )}
                              <div className="form-group col-md-12"></div>
                            </>
                          )}

                        </div>
                      </div>
                    </div>

                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(RentalSoldInvoice);
