import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import { currentTenant, userid } from '../../Common/Credentials.js';
import { spin } from '../../Common/NewLoader.jsx';
import { appmasterid } from '../../Common/Permissions.js';
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import BankServices from './BankServices.js';
import Swal from 'sweetalert2';


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});


class Bank extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        message:'',
        banksdata:[],
        loading:false,

    }
    // this.refreshCustomer=this.refreshCustomer.bind(this)
    this.deletebanks=this.deletebanks.bind(this)
  }


  SetActive(id){
    Swal.fire({
      title: "Set as active bank?",
      text: "Are you sure you want to set as Active bank?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        BankServices.setActive(id)
          .then((response) => {
            Swal.fire({
              title: "Bank is successfully changed as active",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(()=>this.refreshbanks())
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    }); 
  }



  deletebanks(id){

    Swal.fire({
      title: "Delete Bank Order?",
      text: "Are you sure you want to delete?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        BankServices.deleteBank(id)
          .then((response) => {
            Swal.fire({
              title: "Bank deleted successfully",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(()=>this.refreshbanks())
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });  
}

  
refreshbanks(){
  BankServices.getBanks(currentTenant)
    .then(
      response => {
          this.setState({banksdata:response.data})
        this.setState({loading:false})
        }
      )  
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })  
  }

    componentDidMount () {

      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }

    //   UserService.UserPermissions(userid).then(response=>this.setState({up:response.data}))
      
    //   this.setState({loading:true})

      this.refreshbanks();

     }

    render() {

        const columns = [
            {name: "id",label: "Id",options: {filter: false,sort: true,display:false}},
            {name:"accname",label: "Account Name",options:{filter: true,sort: true,}},
            {name: "bankname",label:"Bank name",options: {filter: true,sort: true,}},
            {name: "accno",label:"Account Number",options: {filter: true,sort: true,}},
            {name: "branchname",label:"Branch name",options: {filter: true,sort: true,}},
            {name: "ifsc",label:"IFSC code",options: {filter: true,sort: true,}},
            {name: "status",label:"Status",options: {filter: true,sort: true,
              display: appmasterid === "2" || appmasterid === "3" ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  value==="ACTIVE"?value:
                  <span
                   className='btn btn-sm hovbuttonColour'
                    onClick={() => this.SetActive(tableMeta.rowData.find((e) => e))}
                  >{value}</span>
                );
              },
            }},
            {
              name: "Action",
              options: {
                filter: false,
                sort: false,
                empty: true,
                display: appmasterid === "2" || appmasterid === "3" ? true : false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (<>
                    <Link
                      className="fa fa-edit text-info cursorPointer mr-2"
                      to={{pathname: "/addbank"}} state={{id:tableMeta.rowData.find((e) => e),pagename:"Edit Bank Details"}}
                    ></Link>
                    <span
                      className="fa fa-trash text-danger cursorPointer"
                      onClick={() => this.deletebanks(tableMeta.rowData.find((e) => e))}
                    ></span>
                    </>
                  );
                },
              },
            },
        ];
        const options = {
            filterType: 'checkbox',
            selectableRows: "none",
            customToolbar: () => {
             return (<>
              {appmasterid === '2' || appmasterid === '3'?
              <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/addbank"}} state={{id:-1,pagename:"Add Bank Details"}}>
                <CustomToolbar />
              </Link>
                :''  
              }
             </>
             );
           }
          }
        return (
            <div>
                <Header/>
                <Menu/>
                <div className="content-wrapper">
                    <div className="card">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item text-secondary">
                                <Link to="/masters">Home</Link>
                            </li>
                            
                            <li className="breadcrumb-item active">Banks</li>
                        </ol>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                            <div className="card-body">
                                                {this.state.loading === true ?<div className="text-center"> {spin} </div> :
                                                <ThemeProvider theme={theme}> 
                                                <MUIDataTable
                                                    className="table-sm px-3"
                                                    title={"Banks"}
                                                    data={this.state.banksdata}
                                                    columns={columns}
                                                    options={options}
                                                />  
                                                </ThemeProvider>
                                                 }
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>    
          
        )
       
    }
    
}
export default withRouter(Bank)