import React, { Component } from 'react'
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import { FormikTextField } from '../CommonComponents/FormField.js';
import {Autocomplete, Backdrop, CircularProgress} from '@mui/material';
import { NumericFormat } from 'react-number-format';
import {currentTenant, currentUser, thousandsdisplay, userid } from '../Common/Credentials';
import { Typography} from '@mui/material';
import NewJournalService from './NewJournalService';
import { TableHead,TableBody,TableRow,Table, TableCell} from "@mui/material";
import AuthenticationService from '../Authentication/AuthenticationService';
import { Spinner } from 'react-bootstrap';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { spin } from '../Common/NewLoader';
import Footer from '../Component/Footer';
import moment from 'moment';
import Swal from 'sweetalert2';

// const getTotal = (totamt) => {
//   let total = 0;
//   totamt.forEach(totamt => {
//     total += totamt;
//   });
//   return total;
// };
let totalcombdata=[],pendingdatainv=[]//,pendingdataps=[],entrytype
let selchk=[],debdata,val=[]

let ppaymennts,ppaymennts2


// const Checkbox = props => (
//   <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" {...props} />
// )
class DebitNoteEntry extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            data:this.props.location.state,
            pendingdatainv:[],
            customerid:null,
            partyid:null,poid:null,pmtid:null,total:'',
            tdsreq:[{ id: 0, name: "No" },{ id: 1, name: "Yes" }],
            debselval:'',pmtchecked:'',pnpchecked:'',tcschecked:0,selectedFiles: undefined,serviceamount:null,serviceledger:"",
            currentFile: undefined,progress: 0,message: "",isError: false,fileInfos: [],
            fileInfo:"",pendingPayments:[],selectedPP:[],selectedPP2:[],selectedPPO:[],selectedPPO2:[],
            selchk:[],selchk1:[],selamt:[],selamt2:[],displaychecked:false,prevpmtschecked:false,genpayment:[],
            totalcombdata:[],jvinsertiontype:22,
            totrefund:undefined,refid:undefined,
            loader:false,loader1:true,entrytype:"",refund:1,drinvid:"",insurancedn:"",
            tcsdn:"",otherdn:""
          }
        this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
        this.loadData=this.loadData.bind(this)
        this.AddData=this.AddData.bind(this)
    }

    onDebBlur(values){
      this.setState({debselval:values.debit})
      if(values.debit!==undefined && values.debit.length!==0){
        this.setState({partyid:values.debit.id})
      }
    }

    onSubmit(values){
      this.setState({loader:true})
    
      let jvtype=13
     
      let jv = {
        debitid: values.debit.id,debitname: values.debit.name,debitamt: values.debitval,
        creditid: values.credit.id,creditname: values.credit.name,creditamt:Math.abs(values.creditvalue),
        narration: values.narration,createdBy: userid,tenantId: currentTenant,
        pendingdata:this.state.pendingdatainv,frompage:this.props.location.pathname,
        refundid:this.state.refid,jvinsertiontype:this.state.jvinsertiontype,
        jvtype:jvtype,supplierid:values.credit.id,supplier:values.credit.name,etype:this.state.entrytype,refund:this.state.refund,
        drinvid:this.state.drinvid,insurancedn:this.state.insurancedn,tcsdn:this.state.tcsdn,otherdn:this.state.otherdn
      }
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      } 
      else {
        if(values.debitval>Math.abs(this.state.totrefund)){
          Swal.fire({
            title: "Please Check !!!",
            text: "Entered Value cannot be greater than Pending Value",
            icon: "warning",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          });
          this.setState({loader:false})
        }else{
        this.props.navigate({pathname:`/journalconfirm`}, {state:jv});
        }
      }
    }

    componentDidMount(){
       console.log(this.state.data)
      this.loadData()
      // this.AddData()
    
      if(this.state.totalcombdata.length===0){
        this.loadData()
        this.setState({loading:true})
       }else{
        this.setState({loading:false})
       }
      // let credvalue,cred1
     // let cred1

       // this.state.pendingdatainv.map(e=>{return ledid=e.itemid})
        debdata=val.filter((e)=> e.th==="TH5")
       // cred1=val.filter((e) => e.type === 2)
       // credvalue=cred1.filter((e)=>e.shid===100007 )
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }
      if(this.state.data!==undefined){
      this.setState({pendingdatainv:this.state.data[0]},()=>
      this.setState({entrytype:this.state.pendingdatainv.entrytype},()=>
      this.state.entrytype===undefined?this.setState({refid:this.state.pendingdatainv.id,drinvid:this.state.pendingdatainv.invid,customerid:this.state.pendingdatainv.customerid,
        insurancedn:this.state.pendingdatainv.insurance,tcsdn:this.state.pendingdatainv.tcs,otherdn:this.state.pendingdatainv.other, totrefund:this.state.pendingdatainv.totalwtax}):
      this.setState({customerid:this.state.pendingdatainv.partyid,totrefund:this.state.pendingdatainv.price,refid:this.state.pendingdatainv.id}))
      // this.state.pendingdatainv.map((e)=>{return customerid2=e.partyid}),
      // this.state.pendingdatainv.map((e)=>{return refid=e.id}),
      // this.state.pendingdatainv.map((e)=>{return totalwtax=e.totalwtax}),
      
      )
      // pendingdatainv=this.state.data
      }
      this.setState({loading:false})
       }

    loadData(){
      NewJournalService.getAllLedgers(currentTenant)
      .then(response=>{
        this.setState({totalcombdata:response.data},()=>this.AddData())
        totalcombdata=response.data
       })
       .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
      })

    }

    AddData(){
      if(totalcombdata!==undefined){
        val=totalcombdata 
        this.setState({loader1:false})
      }else(
        this.loadData()
      )
     
    }
     validate(values){
      let errors = {}
      //,type = totalcombdata

       if (!values.debit || values.debit==='') {errors.debit = "Accounts is required";}
     
       if (!values.credit || values.credit==='') {errors.credit = "Accounts is required"}
       if (!values.narration) {errors.narration = "Narration is required";}
       return errors;
     }
     onTcsChange(option){this.setState({ tcschecked: option.id });}

   

  handleCheckboxChange= event =>{
    this.setState({ displaychecked: event.target.checked })
    let a 
    this.state.pendingdatainv.map((e)=>{return a=e.pitems})
    this.setState({selchk:a}) 
    selchk=a

 }

    render() {
      if(this.state.totalcombdata.length!==0){
        val=this.state.totalcombdata 
      }
      const {isError,message} = this.state;
      let credvalue,selpp,selpp2
    //  entrytype=1
     
        debdata=null
        // if(this.state.depid!==undefined){
        // debdata=val.filter((e)=> e.th==="TH5")
        // }else{
         
          debdata=val.filter((e)=> e.th==="TH2")
          credvalue=val.filter((e)=>e.pid===this.state.customerid)

        // }
      // }
    
      
     // cred1=val.filter((e) => e.type === 2)
      // credvalue=cred1.filter((e)=>e.shid===100007)
   //   credvalue=cred1.filter((e)=>e.th==="TH3")

   //   if(this.state.assetexpdata!==undefined){
  //      credvalue=val.filter((e)=>e.shid===100011)
  //    }else if(this.state.tripexpdata!==undefined){
//        credvalue=val.filter((e)=>e.shid===100011)
    //  }else{
    //    cred1=val.filter((e) => e.type === 2)
        // credvalue=cred1.filter((e)=>e.shid===100007)
    //    credvalue=cred1.filter((e)=>e.th==="TH3")   
   //   }
      
         
// -------------------NEW ----------------



// let otherled
// otherled=val.filter((e)=>e.th==="TH2")


        return (
         <>
          <Header />
<Menu/>
            <div>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className="content-wrapper">

<div className="card card-info">

  <ol className="breadcrumb float-sm-right">
  <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={-1}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    {/* <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/depreciationl', state: {message:''}}} >
    Asset Loses & Exp  
              </Link>
    </li> */}
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/dnentry'}} state= {{message:''}} >
         Entry
              </Link>
    </li>

  </ol>

  <div className="text-center text-danger">{this.state.error}</div>
  <div className="card-body">
  <div className="text-center">
  {this.state.loading===true?spin:''}
  </div>
              
        
    <Formik
      initialValues={{ debdata,credvalue,narration:'',selpp,selpp2,ppaymennts,ppaymennts2,selchk,credit:"",debit:""}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({ values,setFieldValue}) => (

        <Form autoComplete="off">




<div className="form-row mt-0 mb-0 text-sm sm">
{this.state.refid!==undefined ?<>
  <fieldset className="form-group col-md-12">
 {/* {this.state.selectedPPO.length!==0 || this.state.selchk.length!==0? */}

<Table className="table-sm" aria-label="customized table">
{this.state.entrytype===undefined?<>
  <TableHead>
    <TableRow>
    <TableCell className="col-md-2 text-sm">Category</TableCell>
    <TableCell className="col-md-2 text-sm">Item Name</TableCell>
    <TableCell className="col-md-2 text-sm">Price</TableCell>
    <TableCell className="col-md-2 text-sm">Qty</TableCell>
    <TableCell className="col-md-2 text-sm">Percentage</TableCell>
    <TableCell className="col-md-2 text-sm">Delivery Note</TableCell>
    <TableCell className="col-md-2 text-sm">Total</TableCell>
  </TableRow>
    </TableHead>
   <TableBody>
   {/* {this.state.pendingdatainv.map((e,i)=> */}
  <TableRow>
  <TableCell className="col-md-2 text-sm">{this.state.pendingdatainv.category}</TableCell>
  <TableCell  className="col-md-2 text-sm">{this.state.pendingdatainv.itemname}</TableCell>
  <TableCell className="col-md-2 text-sm">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.pendingdatainv.price} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
  </TableCell>
  <TableCell className="col-md-2 text-sm">{this.state.pendingdatainv.quantity}</TableCell>
  <TableCell className="form-group col-md-2 text-sm">{this.state.pendingdatainv.tax}</TableCell>
  <TableCell className="form-group col-md-2 text-sm">{this.state.pendingdatainv.dnid}</TableCell>
  <TableCell  className="col-md-1">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.pendingdatainv.totalwtax} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
  </TableCell>
  </TableRow>
</TableBody></>:
<>
 <TableHead>
 <TableRow>
 <TableCell className="col-md-2 text-sm">Party</TableCell>
 <TableCell className="col-md-2 text-sm">Type</TableCell>
 <TableCell className="col-md-2 text-sm">Date</TableCell>
 <TableCell className="col-md-2 text-sm">Note</TableCell>
 <TableCell className="col-md-2 text-sm">Total</TableCell>
</TableRow>
 </TableHead>
   <TableBody>
   {/* {this.state.pendingdatainv.map((e,i)=> */}
  <TableRow >
  <TableCell className="col-md-2 text-sm">{this.state.pendingdatainv.partuname}</TableCell>
  <TableCell  className="col-md-2 text-sm">{this.state.pendingdatainv.entrytype}</TableCell>
  {/* <TableCell className="col-md-2 text-sm">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.pendingdatainv.price} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
  </TableCell> */}
  <TableCell className="col-md-2 text-sm">{moment(this.state.pendingdatainv.createdAt).format("DD-MM-YYYY hh:mm")}</TableCell>
  <TableCell className="form-group col-md-2 text-sm">{this.state.pendingdatainv.type}</TableCell>
  <TableCell  className="col-md-1">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {this.state.pendingdatainv.price} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
  </TableCell>
  </TableRow>
</TableBody>
</>
}
</Table>

</fieldset></>:""}



  </div>
 <div className="form-row" > 
 <fieldset  className="form-group col-md-7 sm mt-0 mb-0"> 
  <Autocomplete id="debit" className="debit-select text-sm sm" name="adebit" options={debdata.sort((a, b) => -a.subname.localeCompare(b.subname))}
  getOptionLabel={(option) => option.name }  groupBy={(option) => option.subname}
 value={values.debit?undefined:undefined} 
  onChange={(e, value) => {setFieldValue("debit",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="debit" label="Debit" className="text-sm"
  onBlur={()=>setFieldValue("debitval",Math.abs(this.state.totrefund)) && this.onDebBlur(values)} 
   variant="standard" fullWidth />)}/></fieldset>
     
  <fieldset  className="form-group col-md-5"> 
  <FormikTextField name="debitval"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.debitval?" ":"Value"}  type="number" 
  variant="standard"> </FormikTextField></fieldset>
  </div>     


<div className="form-row mt-0 mb-0"> 
<fieldset  className="form-group col-md-1 mt-0 mb-0"> </fieldset>
<fieldset  className="form-group col-md-6 mt-0 mb-4"> 
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
getOptionLabel={(option) => option.name +" ("+option.amount+")"} groupBy={(option) => option.subname}
 value={values.credit?undefined:undefined} onChange={(e, value) => { setFieldValue("credit",value);}}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" onBlur={()=> this.state.assetexp!==undefined?setFieldValue("creditvalue",this.state.assetexp):this.state.totrefund!==undefined?setFieldValue("creditvalue",this.state.totrefund):setFieldValue("creditvalue",this.state.tripexp)}
  variant="standard" fullWidth />)}/>
</fieldset>
 <fieldset  className="form-group col-md-5" disabled>   
 <NumericFormat  name="creditvalue" className="form-control text-sm mt-0 mb-0" value={values.debitval} 
 label={values.debitval?" ":"Value"} displayType={'input'} customInput={FormikTextField} decimalScale={2} 
 fixedDecimalScale={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
 </fieldset>
 </div>


  <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="narration" className="form-control" multiline rows={2} label="Narration"
  variant="outlined"/></fieldset></div>
  {/* </>:""} */}


<div className="form-group col-md-9"> 
<Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
<span className="text-green">{message}</span></Typography></div>

<fieldset className="form-group col-md-12">
 <button className="btn hovbuttonColour btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button></fieldset>
</Form> )}
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     

 </div>
 </>
)
}
}

export default withRouter(DebitNoteEntry);              



 
