import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
//
import { currentTenant, userid } from "../../Common/Credentials.js";
import { spin } from "../../Common/NewLoader.jsx";
import { appmasterid } from "../../Common/Permissions.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";

import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import CustomToolbar from "../../CommonComponents/CustomToolbar.js";
import MUIDataTable from "mui-datatables";
import UsercustomerService from "./UsercustomerService.js";
import CustomerService from "../Customers/CustomerService.js";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class UsercustomersTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      data: [],
      loading: false,
    };
    // this.refreshCustomer=this.refreshCustomer.bind(this)
    this.delete = this.delete.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  delete(id) {
    UsercustomerService.DeleteUsercustomers(id)
      .then(() =>
          this.refresh()
      )
      .catch(
        (e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        },
        () => this.refresh()
      );
  }

  refresh() {
    UsercustomerService.retriveUsercustomers(currentTenant)
      .then((response) => {
        let repdata = response.data
        if (response.data.length > 0) {
          CustomerService.retriveAllCustomers(currentTenant).then((res) => 
            this.setState({
              data: repdata.map((e) =>
                Object.assign(e, {
                  customernames:
                    res.data.length > 0
                      ? res.data
                          ?.filter((c) =>
                            e?.customers?.split(",")?.includes(c.id)
                          )
                          ?.map((c1) => c1?.companyname).toString()
                      : ""
                })
              ),
              loading: false
            },()=>console.log(this.state.data))
          )
        }else{
          this.setState({data:response.data,loading: false})
        }
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    //   UserService.UserPermissions(userid).then(response=>this.setState({up:response.data}))

    this.setState({ loading: true });

    this.refresh();
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: true,
          sort: true,
          display: false,
          viewColumns: false,
          sortOrder: "asc",
        },
      },
      {
        name: "username",
        label: "User",
        options: { filter: true, sort: true },
      },
      {
        name: "customernames",
        label: "Customers",
        options: { filter: true, sort: true },
      },
      {
        name: "Delete",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="delete"
                  className="fa fa-edit textColour mr-1"
                  to={{ pathname: "/addusercustomers" }}
                  state={{
                    id: tableMeta.rowData.find((e) => e),
                    pagename: "Update User-Customers",
                  }}
                ></Link>
                <span
                  as="button"
                  data-tip
                  data-for="delete"
                  className="fa fa-trash text-danger"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you wish to delete UOM ${tableMeta.rowData.find(
                          (e) => e
                        )}?`
                      )
                    )
                      this.delete(tableMeta.rowData.find((e) => e));
                  }}
                ></span>
              </>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{ pathname: "/addusercustomers" }}
                state={{
                  id: -1,
                  pagename: "Add User-Customers",
                  mappeddata: this.state.data,
                }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>

              <li className="breadcrumb-item active">User-Customers</li>
            </ol>
            {/* <div className="text-center text-success text-uppercase">{this.state.message}</div> */}
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    {/* /.card */}

                    <div className="card-body">
                      <div>
                        {this.state.loading === true ? (
                          <div className="text-center">{spin}</div>
                        ) : (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"User-Customers"}
                              data={this.state.data}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                        )}
                      </div>
                    </div>
                    {/* /.card-body */}
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(UsercustomersTable);
