import React, { Component } from "react";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { Link } from "react-router-dom";
import { spin } from "../../Common/NewLoader";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { Form, Formik } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { currentTenant, thousandsdisplay, userid } from "../../Common/Credentials";
import SalesServices from "../SalesServices";
import ChartOrders from "./ChartOrders";
import LineChartComp from "./LineChartComp";
import TimelineIcon from '@mui/icons-material/Timeline';
import withRouter from "../../withRouter";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class InvoiceReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportsdata: [],
      filterOptions: [
        { id: 0, name: "Overall" },
        { id: 1, name: "Item/Products" },
        { id: 2, name: "Customer" },
        { id: 3, name: "Top 10 Products" },
        { id: 4, name: "Top 10 Customers" },
        { id: 5, name: "By Users" },
        { id: 6, name: "Sales Outcomes" },
        { id: 7, name: "Agent" },
      ],
      overall: true,
      pro: false,
      cust: false,
      toppro: false,
      topcust: false,
      filterdata: [],
      products: "",
      customers: "",
      agents: "",
      productslist: [],
      custbyproducts: [],
      customerslist: [],
      agentslist: [],
      productsbycust: [],
      productsbyagent: [],
      topproducts: [],
      topcustomers: [],
      salesbyuser: [],
      itemsbyusers: [],
      users: [],
      custbyusers: [],
      loading: false,
      selected: 0,
      fromdate: moment(new Date()).subtract(7, "days"),
      todate: new Date(),
    };
    this.validate = this.validate.bind(this);
  }

  onChange = (option) => {
    this.setState({ selected: option.target.value });
  };

  handleFromDateChange = (date) => {
    this.setState({
      fromdate: date,
      todate:
        moment(date).diff(this.state.todate, "days") >= 0
          ? this.state.todate
          : date,
    });
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  clearfilter = () => {
    this.GetReportsData(
      moment(new Date()).subtract(14, "days"),
      moment(new Date())
    );
  };

  onSubmit = () => {
    this.GetReportsData(this.state.fromdate, this.state.todate);
  };

  
  Productdata = () => {
    this.setState({ productslist: [], custbyproducts: [] }, () => {
      if (
        this.state.productslist.length === 0 &&
        this.state.custbyproducts.length === 0
      ) {
  
        // Sorting Sales Products
        const products = this.state.filterdata.reduce(
          (
            item,
            {
              itemname,
              itemid,
              quantity,
              uom,
              totalprice,
              totalpricewtax,
              createdAt,
              customer,
              custid,
              salesenum,
              fifoprice,
              lifoprice,
              avgprice,
            }
          ) => {
            if (!item[itemname]) item[itemname] = [];
            item[itemname].push({
              itemid: itemid,
              itemname: itemname,
              quantity: quantity,
              uom:uom,
              totalprice: totalprice,
              totalpricewtax: totalpricewtax,
              createdAt: createdAt,
              customer: customer,
              custid: custid,
              salesenum: salesenum,
              fifoprice: fifoprice,
              lifoprice: lifoprice,
              avgprice: avgprice,
            });
            return item;
          },
          {}
        );
        this.setState({ products: products });
        // End of Sorting Products

        // Calcuating total sales by products
        Object.entries(products).forEach(([key, value]) => {
          let a = {
            itemname: key,
            itemid: value.map((e) => e.itemid)[0],
            uom: value.map((e) => e.uom)[0],
            quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
            totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
            totalpricewtax: value.reduce(
              (a, v) => (a = a + v.totalpricewtax),
              0
            ),
          };
          this.state.productslist.push(a);
        });
        // End of Calcuating total sales products

        // Sorting item sales By customer
        Object.entries(products).forEach(([key, value]) => {
          const custbyproducts = value.reduce(
            (
              cust,
              {
                itemname,
                itemid,
                quantity,
                uom,
                totalprice,
                totalpricewtax,
                createdAt,
                customer,
                custid,
                salesenum,
              }
            ) => {
              if (!cust[custid]) cust[custid] = [];
              cust[custid].push({
                itemid: itemid,
                itemname: itemname,
                quantity: quantity,
                uom:uom,
                totalprice: totalprice,
                totalpricewtax: totalpricewtax,
                createdAt: createdAt,
                customer: customer,
                custid: custid,
                salesenum: salesenum,
              });
              return cust;
            },
            {}
          );
          Object.entries(custbyproducts).forEach(([key, value]) => {
            let a = {
              custid: key,
              customer: value.map((e) => e.customer)[0],
              itemid: value.map((e) => e.itemid)[0],
              uom: value.map((e) => e.uom)[0],
              itemname: value.map((e) => e.itemname)[0],
              quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
              totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
              totalpricewtax: value.reduce(
                (a, v) => (a = a + v.totalpricewtax),
                0
              ),
            };
            this.state.custbyproducts.push(a);
          });
        });
        //  End of Sorting item sales By customer

        // Top 10 Products

        const topproducts = []
          .concat(this.state.productslist)
          .sort((a, b) => (a.quantity < b.quantity ? 1 : -1));

        this.setState({ topproducts: topproducts.slice(0, 10) });
      }
    });
  };

  Customerdata = () => {
    this.setState({ customerslist: [], productsbycust: [] }, () => {
      if (
        this.state.customerslist.length === 0 &&
        this.state.productsbycust.length === 0
      ) {
   
        const customers = this.state.filterdata.reduce(
          (
            cust,
            {
              itemname,
              itemid,
              quantity,
              uom,
              totalprice,
              totalpricewtax,
              createdAt,
              customer,
              custid,
              salesenum,
            }
          ) => {
            if (!cust[customer]) cust[customer] = [];
            cust[customer].push({
              itemid: itemid,
              itemname: itemname,
              quantity: quantity,
              uom:uom,
              totalprice: totalprice,
              totalpricewtax: totalpricewtax,
              createdAt: createdAt,
              customer: customer,
              custid: custid,
              salesenum: salesenum,
            });
            return cust;
          },
          {}
        );
        this.setState({ customers: customers });

        Object.entries(customers).forEach(([key, value]) => {
          let a = {
            customer: key,
            custid: value.map((e) => e.custid)[0],
            quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
            uom: value.map((e) => e.uom)[0],
            totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
            totalpricewtax: value.reduce(
              (a, v) => (a = a + v.totalpricewtax),
              0
            ),
          };
          this.state.customerslist.push(a);
        });

        Object.entries(customers).forEach(([key, value]) => {
          const productsbycust = value.reduce(
            (
              item,
              {
                itemname,
                itemid,
                quantity,
                uom,
                totalprice,
                totalpricewtax,
                createdAt,
                customer,
                custid,
                salesenum,
              }
            ) => {
              if (!item[itemid]) item[itemid] = [];
              item[itemid].push({
                itemid: itemid,
                itemname: itemname,
                quantity: quantity,
                uom:uom,
                totalprice: totalprice,
                totalpricewtax: totalpricewtax,
                createdAt: createdAt,
                customer: customer,
                custid: custid,
                salesenum: salesenum,
              });
              return item;
            },
            {}
          );

          Object.entries(productsbycust).forEach(([key, value]) => {
            let a = {
              itemid: key,
              customer: value.map((e) => e.customer)[0],
              custid: value.map((e) => e.custid)[0],
              itemname: value.map((e) => e.itemname)[0],
              quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
              uom: value.map((e) => e.uom)[0],
              totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
              totalpricewtax: value.reduce(
                (a, v) => (a = a + v.totalpricewtax),
                0
              ),
            };
            this.state.productsbycust.push(a);
          });
        });

        // Top 10 Customers
        const topcustomers = []
          .concat(this.state.customerslist)
          .sort((a, b) => (a.totalpricewtax < b.totalpricewtax ? 1 : -1));

        this.setState({ topcustomers: topcustomers.slice(0, 10) });
      }
    });
  };

  Agentsdata = () => {
    this.setState({ agentslist: [], productsbyagent: [] }, () => {
      if (
        this.state.agentslist.length === 0 &&
        this.state.productsbyagent.length === 0
      ) {
 
        const agents = this.state.filterdata.reduce(
          (
            agt,
            {
              itemname,
              itemid,
              quantity,
              uom,
              delivered,
              totalprice,
              totalpricewtax,
              createdAt,
              customer,
              custid,
              salesenum,
              agent,
            }
          ) => {
            if (!agt[agent]) agt[agent] = [];
            agt[agent].push({
              itemid: itemid,
              itemname: itemname,
              quantity: quantity,
              uom:uom,
              totalprice: totalprice,
              totalpricewtax: totalpricewtax,
              createdAt: createdAt,
              customer: customer,
              custid: custid,
              salesenum: salesenum,
              agent: agent,
            });
            return agt;
          },
          {}
        );

        this.setState({ agents: agents });

        Object.entries(agents).forEach(([key, value]) => {
          let a = {
            agent: key,
            quantity: value
              .reduce((a, v) => (a = a + v.quantity), 0)
              .toFixed(2),
              uom: value.map((e) => e.uom)[0],
            totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
            totalpricewtax: value.reduce(
              (a, v) => (a = a + v.totalpricewtax),
              0
            ),
          };
          this.state.agentslist.push(a);
        });

        Object.entries(agents).forEach(([key, value]) => {
          const productsbyagent = value.reduce(
            (
              item,
              {
                itemname,
                itemid,
                quantity,
                uom,
                totalprice,
                totalpricewtax,
                createdAt,
                customer,
                custid,
                agent,
                salesenum,
              }
            ) => {
              if (!item[itemid]) item[itemid] = [];
              item[itemid].push({
                itemid: itemid,
                itemname: itemname,
                quantity: quantity,
                uom:uom,
                totalprice: totalprice,
                totalpricewtax: totalpricewtax,
                createdAt: createdAt,
                customer: customer,
                custid: custid,
                agent: agent,
                salesenum: salesenum,
              });
              return item;
            },
            {}
          );

          Object.entries(productsbyagent).forEach(([key, value]) => {
            let a = {
              itemid: key,
              agent: value.map((e) => e.agent)[0],
              // agentid: value.map((e)=>e.agentid),
              customer: value.map((e) => e.customer)[0],
              custid: value.map((e) => e.custid)[0],
              itemname: value.map((e) => e.itemname)[0],
              quantity: value
                .reduce((a, v) => (a = a + v.quantity), 0)
                .toFixed(2),
                uom: value.map((e) => e.uom)[0],
              totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
              totalpricewtax: value.reduce(
                (a, v) => (a = a + v.totalpricewtax),
                0
              ),
            };
            this.state.productsbyagent.push(a);
          });
        });
      }
    });
  };

  Userdata = () => {
    this.setState(
      { salesbyuser: [], custbyusers: [], itemsbyusers: [] },
      () => {
        if (
          this.state.salesbyuser.length === 0 &&
          this.state.custbyusers.length === 0 &&
          this.state.itemsbyusers.length === 0
        ) {
   
          const users = this.state.filterdata.reduce(
            (
              user,
              {
                itemname,
                itemid,
                quantity,
                uom,
                totalprice,
                totalpricewtax,
                createdAt,
                customer,
                custid,
                createdbyname,
                salesenum,
              }
            ) => {
              if (!user[createdbyname]) user[createdbyname] = [];
              user[createdbyname].push({
                itemid: itemid,
                itemname: itemname,
                quantity: quantity,
                uom:uom,
                totalprice: totalprice,
                totalpricewtax: totalpricewtax,
                createdAt: createdAt,
                customer: customer,
                custid: custid,
                createdbyname: createdbyname,
                salesenum: salesenum,
              });
              return user;
            },
            {}
          );
          this.setState({ users: users });

          Object.entries(users).forEach(([key, value]) => {
            let a = {
              createdbyname: key,
              quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
              uom: value.map((e) => e.uom)[0],
              totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
              totalpricewtax: value.reduce(
                (a, v) => (a = a + v.totalpricewtax),
                0
              ),
            };
            this.state.salesbyuser.push(a);
          });

          // Sorting Item by User
          Object.entries(users).forEach(([key, value]) => {
            const itemsbyusers = value.reduce(
              (
                item,
                {
                  itemname,
                  itemid,
                  quantity,
                  uom,
                  totalprice,
                  totalpricewtax,
                  createdAt,
                  customer,
                  custid,
                  createdbyname,
                  salesenum,
                }
              ) => {
                if (!item[itemid]) item[itemid] = [];
                item[itemid].push({
                  itemid: itemid,
                  itemname: itemname,
                  quantity: quantity,
                  uom:uom,
                  totalprice: totalprice,
                  totalpricewtax: totalpricewtax,
                  createdAt: createdAt,
                  customer: customer,
                  custid: custid,
                  createdbyname: createdbyname,
                  salesenum: salesenum,
                });
                return item;
              },
              {}
            );

            Object.entries(itemsbyusers).forEach(([key, value]) => {
              let a = {
                itemid: key,
                createdbyname: value.map((e) => e.createdbyname)[0],
                customer: value.map((e) => e.customer)[0],
                custid: value.map((e) => e.custid)[0],
                itemname: value.map((e) => e.itemname)[0],
                quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
                uom: value.map((e) => e.uom)[0],
                totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
                totalpricewtax: value.reduce(
                  (a, v) => (a = a + v.totalpricewtax),
                  0
                ),
              };
              this.state.itemsbyusers.push(a);
            });
          });
          //  End of Item customer by User

          // Sorting Customer by User
          Object.entries(users).forEach(([key, value]) => {
            const custbyusers = value.reduce(
              (
                cust,
                {
                  itemname,
                  itemid,
                  quantity,
                  uom,
                  totalprice,
                  totalpricewtax,
                  createdAt,
                  customer,
                  custid,
                  createdbyname,
                  salesenum,
                }
              ) => {
                if (!cust[custid]) cust[custid] = [];
                cust[custid].push({
                  itemid: itemid,
                  itemname: itemname,
                  quantity: quantity,
                  uom:uom,
                  totalprice: totalprice,
                  totalpricewtax: totalpricewtax,
                  createdAt: createdAt,
                  customer: customer,
                  custid: custid,
                  createdbyname: createdbyname,
                  salesenum: salesenum,
                });
                return cust;
              },
              {}
            );
            Object.entries(custbyusers).forEach(([key, value]) => {
              let a = {
                custid: key,
                customer: value.map((e) => e.customer)[0],
                createdbyname: value.map((e) => e.createdbyname)[0],
                itemid: value.map((e) => e.itemid)[0],
                itemname: value.map((e) => e.itemname)[0],
                quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
                uom: value.map((e) => e.uom)[0],
                totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
                totalpricewtax: value.reduce(
                  (a, v) => (a = a + v.totalpricewtax),
                  0
                ),
              };
              this.state.custbyusers.push(a);
            });
          });
          //  End of Sorting Customer by User
        }
      }
    );
  };


  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({
      loading: true,
      fromdate: moment(new Date()).subtract(14, "days"),
      todate: moment(new Date()),
    });

    this.GetReportsData(
      moment(new Date()).subtract(14, "days"),
      moment(new Date())
    );
  }

  GetReportsData = (start, end) => {
    let datadao = {
      tenantid: currentTenant,
      startdate: start,
      enddate: end,
      userid: userid,
      dataaccess: "",
    };

    SalesServices.InvocieReportsDate(datadao)
      .then((response) => {
        console.log(response.data) 
        this.setState(
          {
            reportsdata: response.data,
            filterdata: response.data,
            loading: false,
          },
          () => {
            console.log(this.state.filterdata, "API")
            this.Productdata();
            this.Customerdata();
            this.Agentsdata();
            this.Userdata();
          }
        );
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  };

  validate(values) {
    let errors = {};
    if (this.state.fromdate === null) {
      alert("Please Select From date");
      errors.from = "Please Select From date";
    }
    if (this.state.todate === null) {
      alert("Please Select to date");
      errors.to = "Please Select to date";
    }
    return errors;
  }

  render() {
    const columns = [
      {
        name: "itemname",
        label: "Item name",
        options: { filter: true, sort: true, sortOrder: "asc" },
      },
      {
        name: "customer",
        label: "Customer",
        options: { filter: true, sort: true },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: false, sort: true },
      },
      {
        name: "uom",
        label: "UOM",
        options: { filter: false, sort: true },
      },
      {
        name: "price",
        label: "Unit Cost",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "salesenum",
        label: "Type",
        options: { filter: false, sort: true },
      },
      {
        name: "totalprice",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "tax",
        label: "Tax",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <>{value} %</>;
          },
        },
      },
      {
        name: "taxamount",
        label: "Tax Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY hh:mm");
          },
        },
      },
    ];

    const outcomescolumns = [
      {
        name: "itemname",
        label: "Item name",
        options: { filter: true, sort: true, sortOrder: "asc" },
      },
      {
        name: "customer",
        label: "Customer",
        options: { filter: true, sort: true },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: {
          filter: false,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "uom",
        label: "UOM",
        options: { filter: false, sort: true },
      },
      {
        name: "price",
        label: "Unit Cost",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "salesenum",
        label: "Type",
        options: { filter: false, sort: true },
      },
      {
        name: "totalprice",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "fifoprice",
        label: "FIFO Margin",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return value === null || value === undefined ? (
              "Direct"
            ) : (
              <>
                <NumericFormat
                  className="pr-3"
                  displayType={"text"}
                  value={value * tableMeta.rowData[2] - tableMeta.rowData[5]}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={true}
                  thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
                {value * tableMeta.rowData[2] > tableMeta.rowData[5] ? (
                  <i className="fas fa-arrow-up text-green "></i>
                ) : value * tableMeta.rowData[2] < tableMeta.rowData[5] ? (
                  <i className="fas fa-arrow-down text-red "></i>
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "avgprice",
        label: "Average Margin",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return value === null || value === undefined ? (
              "Direct"
            ) : (
              <>
                <NumericFormat
                  className="pr-3"
                  displayType={"text"}
                  value={value * tableMeta.rowData[2] - tableMeta.rowData[5]}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={true}
                  thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
                {value * tableMeta.rowData[2] > tableMeta.rowData[5] ? (
                  <i className="fas fa-arrow-up text-green "></i>
                ) : value * tableMeta.rowData[2] < tableMeta.rowData[5] ? (
                  <i className="fas fa-arrow-down text-red "></i>
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "lifoprice",
        label: "LIFO Margin",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return value === null || value === undefined ? (
              "Direct"
            ) : (
              <>
                <NumericFormat
                  className="pr-3"
                  displayType={"text"}
                  value={value * tableMeta.rowData[2] - tableMeta.rowData[5]}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={true}
                  thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
                {value * tableMeta.rowData[2] > tableMeta.rowData[5] ? (
                  <i className="fas fa-arrow-up text-green "></i>
                ) : value * tableMeta.rowData[2] < tableMeta.rowData[5] ? (
                  <i className="fas fa-arrow-down text-red "></i>
                ) : (
                  <i className="fas fa-minus"></i>
                )}
              </>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (value) => {
            return moment(value).format("DD-MM-YYYY hh:mm");
          },
        },
      },
    ];

    const productscolumns = [
      {
        name: "itemname",
        label: "Item name",
        options: {
          filter: true,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="text-info"
                  to={{ pathname: "/salesreportdetails" }}
                  state={{
                    data: this.state.custbyproducts.filter(
                      (e) => e.itemname === value
                    ),
                    data2: this.state.products[value],
                    item: value,
                    pagename: "Sales by Products",
                  }}
                >
                  {value}
                </Link>
                {/* //<Tooltip id="view details" place="top" effect="solid">
                  View Details
                </Tooltip> */}
              </>
            );
          },
        },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: false, sort: true },
      },
      {
        name: "uom",
        label: "UOM",
        options: { filter: false, sort: true },
      },
      {
        name: "totalprice",
        label: "Total Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total(Inc.Tax)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    ];

    const customercolumns = [
      {
        name: "customer",
        label: "Customer",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="text-info"
                  to={{ pathname: "/salesreportdetails" }}
                  state={{
                    data: this.state.productsbycust.filter(
                      (e) => e.customer === value
                    ),
                    data2: this.state.customers[value],
                    item: value,
                    pagename: "Sales by Customer",
                  }}
                >
                  {value}
                </Link>
                {/* //<Tooltip id="view details" place="top" effect="solid">
                  View Details
                </Tooltip> */}
              </>
            );
          },
        },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "uom",
        label: "UOM",
        options: { filter: false, sort: true },
      },
      {
        name: "totalprice",
        label: "Total Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total(Inc.Tax)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    ];

    const agentcolumns = [
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="text-info"
                  to={{ pathname: "/salesreportdetails" }}
                  state={{
                    data: this.state.productsbyagent.filter(
                      (e) => e.agent === value
                    ),
                    data2: this.state.agents[value],
                    item: value,
                    pagename: "Sales by Agent",
                  }}
                >
                  {value}
                </Link>
              </>
            );
          },
        },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "uom",
        label: "UOM",
        options: { filter: false, sort: true },
      },
      {
        name: "totalprice",
        label: "Total Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total(Inc.Tax)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    ];

    const userscolumns = [
      {
        name: "createdbyname",
        label: "User",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="text-info"
                  to={{ pathname: "/salesreportdetails" }}
                  state={{
                    data: {
                      cust: this.state.custbyusers.filter(
                        (e) => e.createdbyname === value
                      ),
                      item: this.state.itemsbyusers.filter(
                        (e) => e.createdbyname === value
                      ),
                    },
                    data2: this.state.users[value],
                    item: value,
                    pagename: "Sales by User",
                  }}
                >
                  {value}
                </Link>
                {/* //<Tooltip id="view details" place="top" effect="solid">
                  View Details
                </Tooltip> */}
              </>
            );
          },
        },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "uom",
        label: "UOM",
        options: { filter: false, sort: true },
      },
      {
        name: "totalprice",
        label: "Total Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total(Inc.Tax)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item active">Sales Report(Invoice)</li>
            </ol>
            <div className="card-body">
              <div>
                {this.state.loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <>
                    <Formik
                      initialValues={{
                        from: this.state.fromdate,
                        to: this.state.todate,
                      }}
                      onSubmit={this.onSubmit}
                      validateOnChange={false}
                      validate={this.validate}
                      enableReinitialize={true}
                    >
                      {({ setFieldValue, values }) => (
                        <Form autoComplete="off">
                          <div className="form-row g-0">
                            <fieldset className="mx-2 col-md-2">
                              <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">
                                  Report Type
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={this.state.selected}
                                  onChange={this.onChange}
                                  label="Age"
                                  fullWidth
                                >
                                  {this.state.filterOptions.map((option) => (
                                    <MenuItem value={option.id}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </fieldset>
                            <fieldset>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid container justifyContent="space-around">
                                  <DatePicker
                                    variant="inline"
                                    id="date-picker-dialog"
                                    label="From Date"
                                    name="from"
                                    format="dd/MM/yyyy"
                                    maxDate={new Date()}
                                    value={this.state.fromdate}
                                    onChange={this.handleFromDateChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                      />
                                    )}
                                    autoOk={true}
                                  />
                                </Grid>
                              </LocalizationProvider>
                            </fieldset>

                            <fieldset style={{ marginLeft: "30px" }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid container justifyContent="space-around">
                                  <DatePicker
                                    variant="inline"
                                    id="date-picker-dialog"
                                    label="To Date"
                                    name="to"
                                    format="dd/MM/yyyy"
                                    minDate={this.state.fromdate}
                                    value={this.state.todate}
                                    onChange={this.handleToDateChange}
                                    maxDate={new Date()}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                      />
                                    )}
                                    autoOk={true}
                                  />
                                </Grid>
                              </LocalizationProvider>
                            </fieldset>
                            <fieldset className="form-group col-md-1 ml-2">
                              <button
                                className="btn btn-sm hovbuttonColour mt-3"
                                type="submit"
                              >
                                <i className="fas fa-filter" />
                              </button>
                            </fieldset>
                            <div className="form-group col-md-12 text-sm" />
                          </div>
                        </Form>
                      )}
                    </Formik>
                    <>
                    {/* <Card className="col-2 mb-2" style={{boxShadow:'0 0.5rem 1.25rem rgba(115, 82, 199, 0.175)'}} >
                    <CardHeader
                    title={<Typography variant={"h5"} mb={.5}>Invoices</Typography>}
                    subheader={<Typography variant={"h6"} color={"text.secondary"} mb={0}>293</Typography>}
                    sx={{
                        zIndex: 2,
                        position: "relative",
                        paddingBottom:0
                    }}
                />
                      <ChartOrders/>
                      </Card>
                      <Card className="card col-2" reverse sx={{background:"linear-gradient(#f9cc8a,#f39711)",color: "#fff", borderTop: "4px solid #f39711",flexDirection:'column-reverse',borderRadius:'12px'}} >
                      <CardHeader
                   title={<Typography fontWeight={"500"} variant={"h5"} color={"#fff"}>756+</Typography>}
                   action={<TimelineIcon/>}
                />
                 <CardContent sx={{paddingBottom:'0px', zIndex: 2, position: "relative"}}>
                      <LineChartComp/>
                      </CardContent>
                      </Card> */}
                      {this.state.selected === 0 ? (
                        <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Sales Report(Invocie)"}
                            data={this.state.filterdata}
                            columns={columns}
                            options={options}
                          />
                        </ThemeProvider>
                      ) : (
                        ""
                      )}
                      {this.state.selected === 1 ? (
                        <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Sales Report(Products)"}
                            data={this.state.productslist}
                            columns={productscolumns}
                            options={options}
                          />
                        </ThemeProvider>
                      ) : (
                        ""
                      )}

                      {this.state.selected === 2 ? (
                        <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Sales Report(Customers)"}
                            data={this.state.customerslist}
                            columns={customercolumns}
                            options={options}
                          />
                        </ThemeProvider>
                      ) : (
                        ""
                      )}
                      {this.state.selected === 3 ? (
                        <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Sales Report(Top 10 Products)"}
                            data={this.state.topproducts}
                            columns={productscolumns}
                            options={options}
                          />
                        </ThemeProvider>
                      ) : (
                        ""
                      )}
                      {this.state.selected === 4 ? (
                        <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Sales Report(Top 10 Customers)"}
                            data={this.state.topcustomers}
                            columns={customercolumns}
                            options={options}
                          />
                        </ThemeProvider>
                      ) : (
                        ""
                      )}
                      {this.state.selected === 5 ? (
                        <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Sales Report(By Users)"}
                            data={this.state.salesbyuser}
                            columns={userscolumns}
                            options={options}
                          />
                        </ThemeProvider>
                      ) : (
                        ""
                      )}
                      {this.state.selected === 6 ? (
                        <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Sales Report(Outcomes)"}
                            data={this.state.filterdata}
                            columns={outcomescolumns}
                            options={options}
                          />
                        </ThemeProvider>
                      ) : (
                        ""
                      )}

                      {this.state.selected === 7 ? (
                        <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Sales Report(Agents)"}
                            data={this.state.agentslist}
                            columns={agentcolumns}
                            options={options}
                          />
                        </ThemeProvider>
                      ) : (
                        ""
                      )}
                    </>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(InvoiceReports);
