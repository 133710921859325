import axios from "axios";
import { Auth_Proto, Auth_Domain, Auth_Port } from "../Component/consts";

// export let currentTenant;
class ProductionServices {
  getallImmediateproductions(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getallimmediateproductions/${currentTenant}`
    );
  }

  NewImmediateproduction(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/newimmediateproduction`,data);
  }

  getImmediateproductionbyId(id) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getimmediateproductionbyid/${id}`
    );
  }

  UpdateImmediateProductionStatus(data) {
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/immediateproductionstatus`,
      data
    );
  }

  CancelImmediateProduction(id) {
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/cancelimmediateproduction/${id}`
    );
  }

  UpdateImmediateproduction(data){
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/updatemmediateproduction`,
      data
    );
  }

  getallEstimations(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getallestimations/${currentTenant}`
    );
  }

  NewEstimation(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/newestimation`,
      data
    );
  }

  UpdateEstimation(data){
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/updateestimation`,
      data
    );
  }

  getEstimationsbyId(id) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getestimationbyid/${id}`
    );
  }

  getEstimationsbyCustomId(id){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getestimationbycustomid/${id}`
    );
  }

  UpdateEstimationStatus(data) {
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/estimationstatus`,
      data
    );
  }

  DeleteEstimation(id) {
    return axios.delete(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/deleteestimation/${id}`
    );
  }

  getEstimationsIdList(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getestimationidlist/${currentTenant}`
    );
  }

  getEstimationsIdListOfUser(currentTenant,userid) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getestimationidlistofuser/${currentTenant}/${userid}`
    );
  }

  getallJobOrders(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getalljoborder/${currentTenant}`
    );
  }

  NewJobOrder(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/newjoborder`,
      data
    );
  }

  getJobOrderIdList(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getalljoborderidlist/${currentTenant}`
    );
  }

  
  getallJOidforQA(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getalljoborderidforqa/${currentTenant}`
    );
  }
  getJobOrderIdListByWcId(id){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getalljoborderidofwcid/${id}`
    );
  }

  getallJobOrderItems(id) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getjoborderitemslist/${id}`
    );
  }

  getallIndentinglist(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getallindentinglist/${currentTenant}`
    );
  }

  getIndentingbyId(id) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getindentingbyid/${id}`
    );
  }

  NewIndenting(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/newindenting`,
      data
    );
  }

  getIndentingIdList(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getindentingidlistforstore/${currentTenant}`
    );
  }
  
  getIndentingIdListForPo(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getindentingidlistforpo/${currentTenant}`
    );
  }

  
  getIndentingItems(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getindentingbyidforpo/${currentTenant}`
    );
  }

  AddtoStore(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/addtostore`,
      data
    );
  }

  getallStoreItems(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getallwcstorelist/${currentTenant}`
    );
  }

  getallStoreWcId(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getallwcidstorelist/${currentTenant}`
    );
  }

  GetallStoreNames(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getallstores/${currentTenant}`)
  }
  
  GetStoreItems(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getstoreitems/${id}`)
  }

  NewIndentIssue(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/newindentissue`,data);
  }

  

  getJobOrderIdListForInprocess(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getjoborderidlistforinprocess/${currentTenant}`)
  }

  NewInprocess(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/newinprocess`,data);
  }

  GetAllOutputs(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getallinprocess/${currentTenant}`)
  }

  getOutputItemsbyId(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getinprocessitems/${id}`)
  }

  getInprocessIdforQA(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getinprocessidforqa/${id}`)
  }

  NewQA(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/newqa`,data);
  }
  GetallQA(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getallqa/${currentTenant}`)
  }

  
  GetFinishedGoodsByStore(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getfinishedgoodsofstore/${id}`)
  }

  GetFinishedGoodsByStoreofimp(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getfinishedgoodsofstoreifimp/${id}`)
  }

  AddLosses(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/addlosses`,data);
  }

  AddToInventory(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/addbacktoinventory`,data);
  }

  getStoreItems(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getstoreitems/${id}`)
  }

  

  getallProductionstorelosses(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getallproductionstorelosses/${currentTenant}`)
  }
  

  getallProductionstorelossesForAccounts(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getallproductionstorelossesforaccounts/${currentTenant}`)
  }

  // Productionreports
  ProductionReports(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/productionreports/${currentTenant}`)
  }

  Storeaddedquantity(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getaddstorequantity`,data)
  }

  getallproductiontemplates(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getallproductiontemplates/${currentTenant}`,)
  }
  addProductionTemplate(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/addproductiontemplate`,data)
  }

  UpdateTemplateStatus(data){
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/updatetemplatestatus`,data)
  }
  DeleteTemplate(id){
    return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/deletetemplate/${id}`)
  }

  ProcesswiseReports(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/processwisereports`,data)
  }

  GetProductionStoreTransactionReport(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getproductionstoretransactionreport`,data)
  }

  GetStoreItemsTransactionsReport(id,tenantId){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getstoreitemstransactionbyid/${id}/${tenantId}`,)
  }

  GetStoreItembyId(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/production/getstoreitembyid/${id}`,)
  }

}
export default new ProductionServices();
