import React, { useEffect } from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Menu from "../Component/Menu.js";
import Header from "../Component/Header.js";
import { screens } from "../Common/Products.js";
import Credentials, { transactionFrom } from "../Common/Credentials.js";

import dashboardIcon from "../images/Icons/dashboard.png"
import mastersIcom from "../images/Icons/generic.png"
import transactionsIcon from "../images/Icons/transaction.png"
import reportsIcon from "../images/Icons/annual-report.png"
import { Backdrop } from "@mui/material";

const InventoryMenu = () => {

  useEffect(() =>{
    Credentials.setSelectedMenu("Inventory")
  },[])
  return (
    <div>
      <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={transactionFrom === null}
      // onClick={handleClose}
    >
       <div className="flex-column ">
      <div className="d-flex justify-content-center">
      <i className="text-red">Please set transaction start date in order to continue.</i>
      </div>
      <div className="d-flex justify-content-center">
      <Link to="/settings" className="btn btn-sm hovbuttonColour">Set</Link>
      </div>
      </div>
    
    </Backdrop>
      <Header />
      <Menu />
      <section className="content-wrapper">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item text-secondary">
            <Link to="/index">Home</Link>
          </li>
          <li className="breadcrumb-item active">Inventory</li>
        </ol>
        <div className="h-max border-slate-300 bg-white p-4 rounded-lg ">
          {/* <h4 className="text-lg mt-8 font-weight-bold">Inventory</h4> */}
          <ul className="d-flex flex-row list-unstyled">
              <li className="p-1 mr-4 ">
                <Link
                  to={{ pathname: "/index" }}
                  state={{ message: "" }}
                  className="textColour py-1"
                >
                   <img className="nav-icon mr-2" width="25" src={dashboardIcon}/>

                  Dashboard
                </Link>
              </li>
            </ul>
          <div className="row gap-x-8 gap-y-6 mt-2">
          {screens.includes("Inventory Masters") ?
            <div className="col-sm-12 col-md-6 col-lg-4 w-full">
              <span className="font-weight-bold my-3">
                {/* <i className="nav-icon fas fas fa-th textColour " /> */}
                <img className="nav-icon" width="25" src={mastersIcom}/>
                <span className="pl-2"> Masters</span>
              </span>
              <ul className=" menuul pl-4 ml-3">
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/category" }}
                    state={{ message: "" }}
                  >
                    Category
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/uom" }}
                    state={{ message: "" }}
                  >
                    UOM
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/brand" }}
                    state={{ message: "" }}
                  >
                    Brand
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/size" }}
                    state={{ message: "" }}
                  >
                    Size
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/colour" }}
                    state={{ message: "" }}
                  >
                    Color
                  </Link>
                </li>
              </ul>
            </div>:""}
            {(screens.includes("Items") || screens.includes("Services") || screens.includes("Price")|| screens.includes("Stock") || screens.includes("Stock Issue") )?
            <div className="w-full col-sm-12 col-md-6 col-lg-4">
              <span className="font-weight-bold my-3">
                {/* <TextSnippetIcon sx={{ fontSize: 18 }} />{" "} */}
                <img className="nav-icon" width="25" src={transactionsIcon}/>
                <span className="pl-2"> Transactions</span>
              </span>
              <ul className=" menuul pl-4 ml-3">
              {screens.includes("Items") ? 
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/items" }}
                    state={{ message: "" }}
                  >
                    Items/Products
                  </Link>
                </li>:""}
                {screens.includes("Services") ?
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/services" }}
                    state={{ message: "" }}
                  >
                    Services
                  </Link>
                </li>:""}
                {screens.includes("Price") ?
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/cost" }}
                    state={{ message: "" }}
                  >
                    Price
                  </Link>
                </li>:""}
                {screens.includes("Stock") ? 
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/stocks" }}
                    state={{ message: "" }}
                  >
                    Stock
                  </Link>
                </li>:""}
                {screens.includes("Stock Issue") ? 
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/stockissue" }}
                    state={{ message: "" }}
                  >
                    Stock Issue
                  </Link>
                </li>:""}
                {screens.includes("Damage") || screens.includes("Losses") ?
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/losses" }}
                    state={{ message: "" }}
                  >
                    Losses
                  </Link>
                </li>:""}
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/internalconsumption" }}
                    state={{ message: "" }}
                  >
                    Internal Consumption
                  </Link>
                </li>
              </ul>
            </div>:""}
            {screens.includes("Inventory Reports")?
            <div className="w-full col-sm-12 col-md-6 col-lg-4">
              <span className="font-weight-bold my-3">
                {/* <TextSnippetIcon sx={{ fontSize: 18 }} />{" "} */}
                <img className="nav-icon" width="25" src={reportsIcon}/>
                <span className="pl-2"> Reports</span>
              </span>
              <ul className=" menuul pl-4 ml-3">
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/inventoryreport" }}
                    state={{ message: "" }}
                  >
                    Inventory Report
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/stockreport" }}
                    state={{ message: "" }}
                  >
                    Stock Report
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/damagereport" }}
                    state={{ message: "" }}
                  >
                    Damage Report
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/consolidatereport" }}
                    state={{ message: "" }}
                  >
                    Consolidate Report
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/consumptionreport" }}
                    state={{ message: "" }}
                  >
                    Consumption Report
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/stocktransactionsreport" }}
                    state={{ message: "" }}
                  >
                    Inventory Ledger
                  </Link>
                </li>
              </ul>
            </div>
            :""}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default withRouter(InventoryMenu);
