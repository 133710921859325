import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import MUIDataTable from "mui-datatables";
import {NumericFormat} from "react-number-format";
import { currentTenant, dataaccess, financialyearend, financialyearstart, thousandsdisplay, userid } from "../../Common/Credentials.js";
import { spin } from "../../Common/NewLoader.jsx";
import Menu from "../../Component/Menu.js";
import Header from "../../Component/Header.js";
import Footer from "../../Component/Footer.js";
import { FormControlLabel, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import PurchseServices from "../PurchseServices.js";
import moment from 'moment';
import IOSSwitch from "../../CommonComponents/IOSSwitch.js";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class Debitnotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      purchaserefunds: [],
      initiated:[],
      message: "",
      loading:false,
      pending:true,
      comp:false,
      compdata:[]
    };
    this.refreshItems = this.refreshItems.bind(this);
  }
  handlePendingChange=()=>{
    this.setState({pending:true,comp:false,}) 
  }

  handlecompChange=()=>{
    this.setState({pending:false,comp:true,})
  }
  refreshItems() {
    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
      userid: userid,
    dataaccess:dataaccess,
    }
    PurchseServices.getAllDebitNotes(datadao).then(res=>
      this.setState({
        initiated:res.data.filter(e=> e.status === "Processing").sort((a, b) => a.createdAt < b.createdAt ? 1 : -1),
        compdata:res.data.filter(e=>e.status==="Completed").sort((a, b) => a.createdAt < b.createdAt ? 1 : -1),
        loading:false})
      )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
   
  }

  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
     this.setState({loading:true})

    this.refreshItems();
  }


  render() {
    const columns = [
      {name: "id",label: "id",options: {filter: false,sort: false,display:false,viewColumns:false}},
      {name: "supplier",label: "Supplier",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "category",label: "Category",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "item",label: "Item",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "quantity",label: "Quantity",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "price",label: "Unit Price",options: {filter: false,sort: true,sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      {name: "tax",label: "Tax",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "taxamount",label: "Taxamount",options: {filter: false,sort: true,sortOrder: 'asc',
      customBodyRender:(tableMeta)=>{
        return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
    {name: "totalwtax",label: "Total",options: {filter: false,sort: true,sortOrder: 'asc',
      customBodyRender:(tableMeta)=>{
        return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
    {name: "createdAt",label: "Date",options: {filter: false,sort: true, 
        customBodyRender:(tableMeta)=>{
          return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
        }}},
      {name: "refund",label: "Type",options: {filter: true,sort: true,setCellProps: () => ({ align: 'center' })}},
       {name: "ledgerstatus",label: "Ledger Entry",options: {filter: true,sort: true,
        customBodyRender:(value)=>value==="Processing"?"Pending":"Done",setCellProps: () => ({ align: 'center' })}},
    //   {name: "Delete",options: {filter: false,sort: false,empty: true, display:inventoryid === '2' || inventoryid === '3'?true:false,
    //      customBodyRender: (value, tableMeta, updateValue) => {
    //        return (
    //          <Link className="fa fa-trash text-danger" onClick={(e) => 
    //           { 
    //           const id = tableMeta.rowData.find(e=>e)
    //          if (window.confirm("Are you sure you wish to delete Item?"))this.deleteItem(id)
    //             const data = this.state.items.filter(data => data.id !== id)
                
    //             this.setState({items:data})
    //          }
    //         }>
    //           </Link>
    //        );
    //      }
    //    }
    //  },
    //  {
    //    name: "Edit",
    //    options: {
    //      filter: false,
    //      sort: false,
    //      empty: true,
    //      display:inventoryid === '2' || inventoryid === '3'?true:false,
    //      customBodyRender: (value, tableMeta, updateValue) => {
    //        return (
    //          <Link as="button" data-tip data-for="edit" className="fa fa-edit" style={{ paddingRight: "5px" }}
    //          to={{pathname: "/adddamages"}} state ={{id:tableMeta.rowData.find(e=>e),pagename:"Update item"}}}></Link>
    //        );
    //      }
    //    }
    //  },
     ];
     
     const options = {
       filterType: 'checkbox',
       selectableRows: "none",
    //    customToolbar: () => {
    //     return (<>
    //         <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/addlosses"}} state ={ { id: -1, pagename: "Damage" }}>
    //           <CustomToolbar />
    //         </Link>
    //        <span as="button" onClick={this.pdf.bind(this)}>
    //          <React.Fragment>
    //     <Tooltip title={"pdf"}>
    //       <IconButton>
    //       <i className="fa fa-file-pdf"></i>
    //       </IconButton>
    //     </Tooltip>
    //   </React.Fragment>
    //   </span>
    //     </>);
    //   }
     }

    return (
      <div>
      <Header/>
          <Menu/>
          <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/purchase">Home</Link>
            </li>
            <li className="breadcrumb-item active">Purchase</li>
            <li className="breadcrumb-item active">Debit Notes</li>
          </ol>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                 

                    <div className="card-body p-0">
                      <div>{this.state.loading === true ? <div className="text-center">{spin}</div> : <>
                      <div className="row">
                      <fieldset className="form-group col-md-4">
                              <span className="mx-3">Pending</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.pending} onChange={this.handlePendingChange} name="checkedB" />}
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-4">
                              <span className="mx-3">Completed</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.comp} onChange={this.handlecompChange} name="checkedB" />}
                              />
                            </fieldset>
                            </div>
                      {this.state.pending?
                      <ThemeProvider theme={theme}> 
                      <MUIDataTable
                       className="table-sm px-3"
                        title={"Pending Debit Notes"}
                        data={this.state.initiated}
                        columns={columns}
                       options={options}
                      />
                      </ThemeProvider>

                  :
                         <ThemeProvider theme={theme}> 
                      <MUIDataTable
                       className="table-sm px-3"
                        title={"Completed Debit Notes"}
                        data={this.state.compdata}
                        columns={columns}
                       options={options}
                      />
                      </ThemeProvider>
                }
                     </> }</div>

                      </div>
                   
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
        </div>
        <Footer/>   
      </div>
    );
  }
}
export default withRouter(Debitnotes);
