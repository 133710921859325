import axios from 'axios'
import { Auth_Proto, Auth_Domain, Auth_Port } from '../Component/consts'

export let currentTenant
class NotificationService { 
  GetAllNotifications(userid){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getusernotifications/${userid}`)
  }
  NotificationsReadStatus(id){
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatenotestatus/${id}`)
  }
  MarkAllasRead(userid){
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/markallnoteread/${userid}`)
  }
}
export default new NotificationService();