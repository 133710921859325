import React, { useEffect, useState } from "react";
import ProductionServices from "../ProductionServices";
import { currentTenant, financialyearend, financialyearstart, thousandsdisplay, userid } from "../../Common/Credentials";
import { Form, Formik } from "formik";
import WarehouseServices from "../../Masters/Warehouse/WarehouseServices";
import WorkCenterServices from "../../Masters/WorkCenter/WorkCenterServices";
import {
  Autocomplete,
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import { Spinner } from "react-bootstrap";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SalesServices from "../../Sales/SalesServices";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TemplateImmediateProduction = () => {
  const history = useLocation();
  const navigate = useNavigate();

  const [id] = useState(history.state === undefined ? -1 : history.state.id);
  const [loader, setLoader] = useState(false);

  const [againstso, setAgainstSo] = React.useState(false);
  const [soList, setSOList] = React.useState([]);
  const [soid, setSoId] = React.useState("");
  const [fromstoretype, setFromstoretype] = React.useState("Production Store");
  const [templatelist, setTemplatelist] = useState([]);
  const [template, setTemplate] = useState("");
  const [bomerr, setBomerr] = useState(false);
  const [whid, setWhId] = useState("");
  const [whList, setWhList] = useState([]);
  const [warehouse, setWarehouse] = useState("");
  const [wcList, setWCList] = useState([]);
  const [workcenterid, setWorkCenterId] = useState("");
  const [workcenter, setWorkCenter] = useState("");
  const [StoreList, setStoreList] = useState([]);
  const [store, setStore] = useState("");
  const [storeId, setStoreId] = useState("");
  const [tostore, setTostore] = useState("");
  const [tostoreid, setTostoreId] = useState("");
  const [toworkcenterid, setToWorkCenterId] = useState("");
  const [toworkcenter, setToWorkCenter] = useState("");
  const [towhid, setToWhId] = useState("");
  const [towarehouse, setToWarehouse] = useState("");
  const [bomitems, setBomItems] = useState([]);
  const [productid, setProductId] = useState("");
  const [productname, setProductName] = useState("");
  const [productquantity, setproductQuantity] = useState("");
  const [productuom, setproductUom] = useState("");
  const [finalproductsList, setFinalProductsList] = useState([]);
  const [finalperr, setFinalperr] = useState(false);
  const [RawItemList, setRawItemList] = useState([]);
  const [filterRawmaterials, setFilterRawmaterials] = useState([]);
  const [batchidlist] = useState([]);
  const [storeidlist] = useState([]);
  const [open, setOpen] = useState("");
  const [appxamount, setAppxAmount] = useState(0);
  const [customdate,setCustomdate] = React.useState(false)
  const [date,setDate] = React.useState(false)
  const [addedbomitems,setaddedbomitems]  = React.useState([]) 

  useEffect(() => {
    WarehouseServices.getWarehousesidname(currentTenant).then((response) => {
      setWhList(response.data);
      if (response.data.length === 1) {
        setWarehouse(response.data[0].name);
        setWhId(response.data[0].id);
        setToWarehouse(response.data[0].name);
        setToWhId(response.data[0].id);
        WorkCenterServices.WorkCenterbywarehouse(response.data[0].id).then(
          (res) => {
            setWCList(res.data);
            if (res.data.length === 1) {
              setWorkCenterId(res.data[0].id);
              setWorkCenter(res.data[0].name);
              setToWorkCenterId(res.data[0].id);
              setToWorkCenter(res.data[0].name);
              ProductionServices.GetallStoreNames(res.data[0].id).then(
                (resp) => {
                  setStoreList(resp.data);
                  if (resp.data.length === 1) {
                    setStore(resp.data[0].name);
                    setStoreId(resp.data[0].id);
                    setTostore(resp.data[0].name);
                    setTostoreId(resp.data[0].id);
                    ProductionServices.GetStoreItems(resp.data[0].id).then(
                      (response) =>
                        setRawItemList(
                          response.data.storeitems.filter(
                            (e) => e.quantity !== 0 && e.quantity !== null
                          )
                        )
                    );
                  }
                }
              );
            }
          }
        );
      }
    });

    ProductionServices.getallproductiontemplates(currentTenant).then(
      (response) => setTemplatelist(response.data)
    );

    SalesServices.GetSoIdofProducts(currentTenant).then((response) =>
      setSOList(response.data)
    );

  }, []);

  const handleWarehouseChange = (e, newValue) => {
    setBomerr(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setWhId("");
      setWarehouse("");
      setWCList([]);
      setWorkCenter("");
      setWorkCenterId("");
      setStore("");
      setStoreId("");
      setStoreList([]);
    } else {
      setWhId(newValue.id);
      setWarehouse(newValue.name);
      WorkCenterServices.WorkCenterbywarehouse(newValue.id).then((res) => {
        setWCList(res.data);
        if (res.data.length === 1) {
          setWorkCenterId(res.data[0].id);
          setWorkCenter(res.data[0].name);
          ProductionServices.GetallStoreNames(res.data[0].id).then((resp) => {
            setStoreList(resp.data);
            if (resp.data.length === 1) {
              setStore(resp.data[0].name);
              setStoreId(resp.data[0].id);
              ProductionServices.GetStoreItems(resp.data[0].id).then(
                (response) =>
                  setRawItemList(
                    response.data.storeitems.filter(
                      (e) => e.quantity !== 0 && e.quantity !== null
                    )
                  )
              );
            }
          });
        } else {
          setWorkCenter("");
          setWorkCenterId("");
          setStore("");
          setStoreId("");
          setStoreList([]);
        }
      });
    }
  };

  const handleToWarehouseChange = (e, newValue) => {
    setFinalperr(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setToWhId("");
      setToWarehouse("");
      setToWorkCenter("");
      setToWorkCenterId("");
      setTostore("");
      setTostoreId("");
    } else {
      setToWhId(newValue.id);
      setToWarehouse(newValue.name);
      WorkCenterServices.WorkCenterbywarehouse(newValue.id).then((res) => {
        setWCList(res.data);
        WorkCenterServices.WorkCenterbywarehouse(newValue.id).then((res) => {
          setWCList(res.data);
          if (res.data.length === 1) {
            setToWorkCenterId(res.data[0].id);
            setToWorkCenter(res.data[0].name);
            ProductionServices.GetallStoreNames(res.data[0].id).then((resp) => {
              setStoreList(resp.data);
              if (resp.data.length === 1) {
                setTostore(resp.data[0].name);
                setTostoreId(resp.data[0].id);
              }
            });
          } else {
            setToWorkCenter("");
            setToWorkCenterId("");
            setTostore("");
            setTostoreId("");
            setStoreList([]);
          }
        });
      });
    }
  };

  const handleWorkCenterChange = (e, value) => {
    setBomerr(false);
    if (value === "" || value === null || value === undefined) {
      setWorkCenter("");
      setWorkCenterId("");
      setStore("");
      setStoreId("");
    } else {
      setWorkCenter(value.name);
      setWorkCenterId(value.id);
      ProductionServices.GetallStoreNames(value.id).then((res) => {
        setStoreList(res.data);
        if (res.data.length === 1) {
          setStore(res.data[0].name);
          setStoreId(res.data[0].id);
          ProductionServices.GetStoreItems(res.data[0].id).then((response) =>
            setRawItemList(
              response.data.storeitems.filter(
                (e) => e.quantity !== 0 && e.quantity !== null
              )
            )
          );
        } else {
          setStore("");
          setStoreId("");
        }
      });
    }
  };

  const handleToWorkCenterChange = (e, value) => {
    setFinalperr(false);
    if (value === "" || value === null || value === undefined) {
      setToWorkCenter("");
      setToWorkCenterId("");
      setTostore("");
      setTostoreId("");
    } else {
      setToWorkCenter(value.name);
      setToWorkCenterId(value.id);
      setTostore("");
      setTostoreId("");
      ProductionServices.GetallStoreNames(value.id).then((res) => {
        setStoreList(res.data);
        if (res.data.length === 1) {
          setTostore(res.data[0].name);
          setTostoreId(res.data[0].id);
        } else {
          setTostore("");
          setTostoreId("");
        }
      });
    }
  };

  const handlestoreChange = (e, newValue) => {
    setBomerr(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setStore("");
      setStoreId("");
      setRawItemList([]);
    } else {
      setStore(newValue.name);
      setStoreId(newValue.id);
        ProductionServices.GetStoreItems(newValue.id).then((response) =>
          setRawItemList(
            response.data.storeitems.filter(
              (e) => e.quantity !== 0 && e.quantity !== null
            )
          )
        );
    }
  };

  const handleTostoreChange = (e, newValue) => {
    setFinalperr(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setTostore("");
      setTostoreId("");
    } else {
      setTostore(newValue.name);
      setTostoreId(newValue.id);
    }
  };

  const handletemplateChange = (e, newValue) => {
    setFinalperr(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setTemplate("");
    } else {
      setTemplate(newValue.templatename);
      setFinalProductsList([
        {
          itemid: newValue.itemid,
          itemname: newValue.itemname,
          quantity: 1,
          uom: newValue.uom,
          uomid: newValue.uomid,
        },
      ]);
      setProductId(newValue.itemid);
      setProductName(newValue.itemname);
      setproductQuantity(1);
      setproductUom(newValue.uom);
      setBomItems(
        newValue.productiontemplaterawmaterials.map((e) =>
          Object.assign(e, { perunit: e.quantity,stock:0,id:"" })
        )
      );
    }
  };

  const productQuantityChange = (e) => {
    setproductQuantity(e.target.value);
    let finlist = finalproductsList
    finlist.forEach((item) => {
      item.quantity = e.target.value
    })
    setFinalProductsList(finlist)
    let itemlist = bomitems;
    itemlist.forEach((item) => {
      item.quantity = (
        item.perunit *
        parseFloat(
          e.target.value === "" ||
            e.target.value === undefined ||
            e.target.value === null
            ? 0
            : e.target.value
        )
      ).toFixed(3);
    });
    setBomItems(itemlist);
  };

  const handleonSubmit = (e) => {
    console.log(parseFloat(bomitems.reduce((acc, item) => acc + parseFloat(item.quantity),0)) , parseFloat(bomitems.reduce((acc, item) => acc + item.stock,0)) )
    if (parseFloat(bomitems.reduce((acc, item) => acc + parseFloat(item.quantity),0)) !== parseFloat(bomitems.reduce((acc, item) => acc + item.stock,0))){
      Swal.fire({
        title:"",
        text:"Quantity and stock dosen't match",
        icon:"warning",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      })
    } else {
      setLoader(true);
      let data = {
        id: id,
        bomitems: addedbomitems,
        finalproduct: finalproductsList,
        createdby: userid,
        updatedby: userid,
        tenantid: currentTenant,
        toworkcenterid: workcenterid,
        workcenterid: workcenterid,
        soid: againstso === true ? soid : "NA",
        appxamount: appxamount,
        customdate: customdate,
        date: date,
        whid: whid,
        towhid: whid,
        store: storeId,
        fromstoretype: fromstoretype,
        // tostoretype:tostoretype,
        tostore: storeId,
        blockedstock: storeidlist,
      };
      if (id === -1) {
        ProductionServices.NewImmediateproduction(data)
          .then(() => navigate("/immediateproduction"))
          .catch(() => setLoader(false));
      } else {
        ProductionServices.UpdateImmediateproduction(data)
          .then(() => navigate("/immediateproduction"))
          .catch(() => setLoader(false));
      }
    }
  };

  const handleOpen = (val) => {
    if (val === open) {
      setOpen("");
    } else {
      setOpen(val);
      let data = RawItemList.filter((e) => e.itemid === val).map((e) =>
        Object.assign(e, { enteredquantity: 0 })
      );
      setFilterRawmaterials(data);
    }
  };

  const handleQuantityChange = (e, i, iid) => {
    let q;
    let data = filterRawmaterials;
    if (e === "" || e === undefined || parseFloat(e) < 0) {
      q = 0;
    } else {
      q = parseFloat(e);
    }
    let fd = data.filter((e, idx) => idx !== i);

    let totalqut = fd.reduce((a, b) => (a = a + b.enteredquantity), 0);
    if (
      q > data.find((e, index) => index === i).quantity ||
      totalqut + q > bomitems.find((e) => e.itemid === iid).quantity
    ) {
      if (
        q > data.find((e, index) => index === i).quantity &&
        totalqut + q < bomitems.find((e) => e.itemid === iid).quantity
      ) {
        data.find((e, index) => index === i).enteredquantity = data.find(
          (e, index) => index === i
        ).quantity;
      } else if (
        totalqut + q >
        bomitems.find((e) => e.itemid === iid).quantity
      ) {
        data.find((e, index) => index === i).enteredquantity =
          bomitems.find((e) => e.itemid === iid).quantity - totalqut;
      } else {
        data.find((e, index) => index === i).enteredquantity = q;
      }
    } else {
      data.find((e, index) => index === i).enteredquantity = q;
    }

    setFilterRawmaterials([...data]);
  };


  const handleSaveQuantity = () => {
   
      let data = filterRawmaterials;
      let am = data.filter((a) => a.enteredquantity !== 0);
      let totalqut = am.reduce((acc, a) => acc =   acc + a.enteredquantity,0)

        if (storeidlist.length !== 0) {
          let sid = storeidlist.map((a) => a.id);
          am.map((a) =>
            sid.includes(a.id)
              ? (storeidlist.find((b) => b.id === a.id).quantity =
                  storeidlist.find((b) => b.id === a.id).quantity -
                  a.enteredquantity)
              : storeidlist.push({
                  batchid: null,
                  sid: a.id,
                  quantity: a.enteredquantity,
                  tenantid: currentTenant,
                  whid: whid,
                  store: storeId,
                  itemid: a.itemid,
                })
          );
        } else {
          am.map((a) =>
            storeidlist.push({
              batchid: null,
              sid: a.id,
              quantity: a.enteredquantity,
              tenantid: currentTenant,
              whid: whid,
              store: storeId,
              itemid: a.itemid,
            })
          );
        }

        am.map(
          (a) =>
            (RawItemList.find((sl) => sl.id === a.id).quantity =
              RawItemList.find((sl) => sl.id === a.id).quantity -
              a.enteredquantity)
        );

        let apxamt = appxamount + am.reduce((acc, a) => acc =   acc + (a.enteredquantity * a.price),0)
        setAppxAmount(apxamt)

        bomitems.find(a => a.itemid === open).stock = bomitems.find(a => a.itemid === open).stock + totalqut


        am.map((a) =>{
          if(addedbomitems.length !== 0 &&
           addedbomitems.filter((e) =>a.itemid ===  e.itemid && a.price === e.price).length > 0
           ){ 
             addedbomitems.find(
                 (e) => e.itemid === a.itemid && e.price === a.price
               ).quantity =
                 addedbomitems.find((e) => e.itemid === a.itemid && e.price === a.price)
                   .quantity + a.enteredquantity
                   
                 addedbomitems.find(
                   (e) => e.itemid === a.itemid && e.price === a.price
                 ).totalprice =
                   addedbomitems.find(
                     (e) => e.itemid === a.itemid && e.price === a.price
                   ).totalprice +
                   a.enteredquantity * a.price
   
               } else{ 
                 addedbomitems.push({
                 itemid: a.itemid,
                 itemname: a.itemname,
                 quantity: a.enteredquantity,
                 uom: bomitems.find(a => a.itemid === open).uom,
                 uomid: bomitems.find(a => a.itemid === open).uomid,
                 price: a.price,
                 totalprice: a.price * a.enteredquantity,
                 losses:0,
                 comment:'',
                 batchid:a.id,
               })
             }
     })


        setBomItems([...bomitems])
        setaddedbomitems([...addedbomitems])

      setFilterRawmaterials([]);
      setOpen("")
    // }
  };


  const handleagainstso = () => {
    setAgainstSo(!againstso);
  };


 const handlecreatedAtDateChange = (date) => {
    setDate(date);
  };

 const handlecustomDate=()=>{
    setCustomdate(customdate===true?false:true)
    setDate(new Date())
  }


  return (
    <div>
      <Formik
        initialValues={{}}
        // onSubmit={onSubmit}
        validateOnChange={false}
        // validate={validate}
        enableReinitialize={true}
      >
        {({ setFieldValue, values }) => (
          <Form autoComplete="off" style={{ paddingLeft: "30px" }}>
            <div className="form-row">
              <fieldset className="form-group col-md-4">
                {whList.length === 1 ? (
                  <TextField
                    className="form-control"
                    type="text"
                    name="warehouse"
                    value={warehouse}
                    label={`Select Org Unit (${warehouse})`}
                    variant="standard"
                    fullWidth
                    disabled
                  />
                ) : (
                  <Autocomplete
                    id="warehouse"
                    className="subacc-select"
                    name="warehouse"
                    options={whList}
                    getOptionLabel={(option) => option.name}
                    value={values.warehouse ? undefined : undefined}
                    onChange={handleWarehouseChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="form-control"
                        type="text"
                        name="warehouse"
                        value={warehouse}
                        label={`Select Org Unit (${warehouse})`}
                        variant="standard"
                        error={whid === "" && bomerr}
                        helperText={
                          whid === "" && bomerr ? "Empty field!" : " "
                        }
                        fullWidth
                        disabled={bomitems.length !== 0}
                      />
                    )}
                    disabled={bomitems.length !== 0}
                  />
                )}
              </fieldset>
              <fieldset className="form-group col-md-4">
                {wcList.length === 1 ? (
                  <TextField
                    label={`Select WorkCenter/Production Unit (${workcenter})`}
                    type="text"
                    value={workcenter}
                    variant="standard"
                    fullWidth
                    disabled
                  />
                ) : (
                  <Autocomplete
                    id="workcenter"
                    className="subacc-select"
                    name="workcenter"
                    options={wcList}
                    getOptionLabel={(option) => option.name}
                    value={values.workcenter ? undefined : undefined}
                    onChange={handleWorkCenterChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`Select WorkCenter/Production Unit (${workcenter})`}
                        type="text"
                        value={workcenter}
                        variant="standard"
                        error={workcenter === "" && bomerr}
                        helperText={
                          (workcenter === "" || workcenter === undefined) &&
                          bomerr
                            ? "Empty field!"
                            : " "
                        }
                        fullWidth
                        disabled={bomitems.length !== 0}
                      />
                    )}
                    disabled={bomitems.length !== 0}
                  />
                )}
              </fieldset>
              <fieldset className="form-group col-md-4">
                {StoreList.length === 1 ? (
                  <TextField
                    className="form-control"
                    type="text"
                    name="store"
                    value={store}
                    label={`Select Store (${store})`}
                    variant="standard"
                    fullWidth
                    disabled
                  />
                ) : (
                  <Autocomplete
                    id="subaccount"
                    className="subacc-select"
                    name="store"
                    options={StoreList}
                    getOptionLabel={(option) => option.name}
                    value={values.store ? undefined : undefined}
                    onChange={handlestoreChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="form-control"
                        type="text"
                        name="store"
                        value={store}
                        label={`Select Store (${store})`}
                        variant="standard"
                        error={store === "" && bomerr}
                        helperText={
                          store === "" && bomerr ? "Empty field!" : " "
                        }
                        fullWidth
                        disabled={bomitems.length !== 0}
                      />
                    )}
                    disabled={bomitems.length !== 0}
                  />
                )}
              </fieldset>
              <fieldset className="form-group col-md-4">
                {/* {templatelist.length === 1 ? (
                  <TextField
                    className="form-control"
                    type="text"
                    name="template"
                    value={template}
                    label={`Select Template`}
                    variant="standard"
                    fullWidth
                    disabled
                  />
                ) : ( */}
                  <Autocomplete
                    id="subaccount"
                    className="subacc-select"
                    name="template"
                    options={templatelist}
                    getOptionLabel={(option) => option.templatename}
                    value={values.template ? undefined : undefined}
                    onChange={handletemplateChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="form-control"
                        type="text"
                        name="template"
                        value={template}
                        label={`Select template (${template})`}
                        variant="standard"
                        error={template === "" && bomerr}
                        helperText={
                          template === "" && bomerr ? "Empty field!" : " "
                        }
                        fullWidth
                      />
                    )}
                  />
                {/* )} */}
              </fieldset>

              <fieldset className="col-md-4">
                <TextField
                  className="form-control"
                  type="text"
                  name="finalproduct"
                  value={productname}
                  label="Final Product"
                  variant="standard"
                  fullWidth
                  disabled
                />
              </fieldset>

              <fieldset className="col-md-4">
                <TextField
                  type="number"
                  label="Quantity"
                  id="standard-start-adornment"
                  value={productquantity}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {productuom}
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                  onChange={productQuantityChange}
                  fullWidth
                />
              </fieldset>

              {bomitems.length !== 0 ? (
                <TableContainer>
                  <Typography> Raw Materials List</Typography>
                  <Table className="table table-sm">
                    <TableHead>
                      <TableRow>
                        <TableCell className="text-center">Itemname</TableCell>
                        <TableCell className="text-center">Per Unit Quantity</TableCell>
                        <TableCell className="text-center">Required Quantity</TableCell>
                        <TableCell className="text-center">Added Stock</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {bomitems.map((e, index) => (
                        <>
                          <StyledTableRow key={index}>
                            <TableCell className="text-center">
                              {e.itemname}
                            </TableCell>
                            <TableCell className="text-center">
                              {e.perunit}  {e.uom}
                            </TableCell>
                            <TableCell className="text-center">
                              {e.quantity} {e.uom}
                            </TableCell>
                            <TableCell className="text-center">
                              {e.stock}
                            </TableCell>
                            <TableCell>
                            {parseFloat(e.quantity) === parseFloat(e.stock)?"":
                              <span
                                className="text-primary cursorPointer"
                                onClick={() => handleOpen(e.itemid)}
                              >
                                Select batch{" "}
                                {open === e.itemid ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </span>
                            }
                            </TableCell>
                          </StyledTableRow>
                          {parseFloat(e.quantity === e.stock)?"":
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={12}
                            >
                              <Collapse
                                in={open === e.itemid}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Box sx={{ margin: 1 }}>
                                  <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                  >
                                    Store Items
                                  </Typography>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell className="text-center">
                                          Supplier
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Batch Id
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Price
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Date
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Avilable Quantity
                                        </TableCell>
                                        <TableCell>Enter Quanity</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {filterRawmaterials.length === 0 ? (
                                        <TableRow>
                                          <TableCell
                                            colSpan={12}
                                            className="text-center"
                                          >
                                            No data avilable
                                          </TableCell>
                                        </TableRow>
                                      ) : (
                                        filterRawmaterials.map((a, i) =>
                                          a.quantity > 0 ? (
                                            <TableRow key={i}>
                                              <TableCell className="text-center">
                                                {a.supplier}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {a.custombatchid === null ? a.batchid : a.custombatchid}
                                              </TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={a.price}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {a.invaddeddate === null
                                                  ? a.createdAt
                                                      .substring(0, 19)
                                                      .replace("T", " ")
                                                  : a.invaddeddate
                                                      .substring(0, 19)
                                                      .replace("T", " ")}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {a.quantity}
                                              </TableCell>
                                              <TableCell>
                                                <fieldset className="col-md-12">
                                                  <TextField
                                                    className="form-control"
                                                    type="number"
                                                    name={`enteredquantity${i}`}
                                                    variant="standard"
                                                    value={a.enteredquantity}
                                                    InputProps={{
                                                      inputProps: {
                                                        min: 0,
                                                        max: a.quantity,
                                                      },
                                                    }}
                                                    onChange={(e) =>
                                                      handleQuantityChange(
                                                        e.target.value,
                                                        i,
                                                        a.itemid
                                                      )
                                                    }
                                                    error={
                                                      a.enteredquantity >
                                                        a.quantity ||
                                                      a.enteredquantity < 0
                                                    }
                                                    helperText={
                                                      a.enteredquantity >
                                                      a.quantity
                                                        ? "Exceeds Avliable Quantity"
                                                        : a.enteredquantity < 0
                                                        ? "Enter Positive Quantity"
                                                        : ""
                                                    }
                                                  />
                                                </fieldset>
                                              </TableCell>
                                            </TableRow>
                                          ) : (
                                            ""
                                          )
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                  <button
                                    className="btn btn-sm hovbuttonColour"
                                    onClick={handleSaveQuantity}
                                  >
                                    Add
                                  </button>
                                </Box>
                              </Collapse>
                            </TableCell>
                          </TableRow>
                          }
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                ""
              )}


                        <div className="form-row mt-3">
                                          <fieldset className="col-md-4">
                                            <FormGroup row>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={againstso}
                                                    onChange={handleagainstso}
                                                    name="taxrequired"
                                                    color="primary"
                                                  />
                                                }
                                                label="Against SaleOrder?"
                                              />
                                            </FormGroup>
                                          </fieldset>
                                          {againstso === true ? (
                                            <fieldset className="col-md-3">
                                              <Autocomplete
                                                id="controlled-demo"
                                                options={soList}
                                                getOptionLabel={(option) =>
                                                  option
                                                }

                                                value={soid}
                                                onChange={(event, newValue) => {
                                                  newValue === "" ||
                                                  newValue === null ||
                                                  newValue === undefined
                                                    ? setSoId("")
                                                    : setSoId(newValue);
                                                }}
                                                error={
                                                  soid === "" ||
                                                  soid === undefined
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="Select Sale Order Id"
                                                    variant="standard"
                                                    error={
                                                      soid === "" ||
                                                      soid === undefined
                                                    }
                                                    helperText={
                                                      soid === "" ||
                                                      soid === undefined
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                  />
                                                )}
                                              />
                                            </fieldset>
                                          ) : (
                                            ""
                                          )}
                                          <fieldset className="col-md-12"></fieldset>
                                         
                                          <fieldset className="form-group col-md-4">

                                            <FormGroup row>
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          checked={customdate}
                                                          onChange={handlecustomDate}
                                                          name="ordertype"
                                                          color="primary"
                                                        />
                                                      }
                                                      label="Custom date?"
                                                    />
                                                  </FormGroup>
                                            </fieldset>
                                            {customdate?

                                            <fieldset className="form-group col-md-3">
                                            {/* <div className="form-row mr-4 mb-4"> */}
                                            {/* <fieldset style={{ marginLeft: "30px" }}> */}
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Grid  justifyContent="space-around">
                                            <DateTimePicker
                                            disableFuture
                                            showtodaybutton
                                            showcancelbutton
                                            //  views={["year", "month","date"]}
                                            variant="inline"
                                            id="date-picker-dialog"
                                            // label="Financial Year Start Date"
                                            // inputVariant="outlined"
                                            minDate={new Date(moment(financialyearstart).format("YYYY-MM-DD"))}
                                            maxDate={new Date(moment(financialyearend).format("YYYY-MM-DD"))}

                                            label={`Custom Date : ${customdate?"Custom":"Default"}`}
                                            format="dd/MM/yyyy HH:mm"
                                            value={date}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={handlecreatedAtDateChange}
                                            renderInput={(params) => <TextField {...params} variant="standard"/>}
                                            autoOk={true}
                                            fullWidth
                                            disabled={customdate===false}
                                            />
                                            </Grid>
                                            </LocalizationProvider>
                                            </fieldset>
                                            :''}


              <fieldset className="col-md-12 mt-2">
                {bomitems.length === 0 ||
                finalproductsList.length === 0 ||
                parseFloat(productquantity) <= 0 ? (
                  <button className="btn btn-sm hovbuttonColour" disabled>
                    Submit
                  </button>
                ) : (
                  <button
                    as="button"
                    className="btn btn-sm hovbuttonColour"
                    onClick={handleonSubmit}
                    disabled={loader === true}
                  >
                    {loader === true ? (
                      <>
                        <Spinner animation="border" size="sm" /> Submitting
                      </>
                    ) : (
                      "Submit"
                    )}
                  </button>
                )}
                <Link
                  as="button"
                  className="btn btn-sm ml-3 deletebutton"
                  to="/immediateproduction"
                >
                  <b> X</b> Cancel
                </Link>
              </fieldset>
            </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TemplateImmediateProduction;
