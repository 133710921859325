import {
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { Form, Formik } from "formik";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import {NumericFormat} from "react-number-format";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, thousandsdisplay, userid } from "../../Common/Credentials";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import CategoryServices from "../../Masters/Category/CategoryServices";
import WarehouseServices from "../../Masters/Warehouse/WarehouseServices";
import InventoryService from "../InventoryService";
import Swal from "sweetalert2";

class Damage extends Component {
  constructor() {
    super();
    this.state = {
      id:-1,
      itemsList: [],
      filteritemlist: [],
      WarehouseList: [],
      CategoryList: [],
      stocklist: [],
      filterstocklist: [],
      batchidList: [],
      itemname: "",
      itemid: "",
      warehouse: "",
      whid: "",
      sid:'',
      category: "",
      catid: "",
      quantity: "",
      currentstock: 0,
      batchid: "",
      price: "",
      sloader: false,
      selected: false,
      comments: ["Damaged", "Shortage","Other"],
      comment:'Other',
      totalloss:0,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit() {
   
    let data = {
      id: this.state.id,
      tenantid: currentTenant,
      createdby: userid,
      updatedby: userid,
      itemid: this.state.itemid,
      itemname: this.state.itemname,
      category: this.state.category,
      categoryid: this.state.catid,
      warehouse: this.state.warehouse,
      whid: this.state.whid,
      sid:this.state.sid,
      price: this.state.price,
      quantity: parseFloat(this.state.quantity),
      comment:this.state.comment,
      batchid:this.state.batchid,
      totalloss:this.state.price * parseFloat(this.state.quantity),
    };

    if(this.state.id === -1){
      this.setState({ sloader: true });
    InventoryService.AddDamage(data)
      .then(() =>
      this.setState({sloader:false},()=>
      Swal.fire({
        title: `Losses added successfully`,
        text: ".",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      }).then(()=>this.props.navigate(-1))))
      .catch((e)=>{
        this.setState({message:e.response.data.message,sloader:false},()=>{
            if(e.response.data.error ==="Unauthorized"){
                Swal.fire({
                  title: `Your Session is Lost: Please login Back to resume`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                }).then(()=>this.props.navigate('/'))
            }else{
              Swal.fire({
                title: `${e.response.data.message}`,
                text: ".",
                icon: "error",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            }})
        })
      }else{
        Swal.fire({
          title: "Revert losses quantity?",
          text: "The quantity you added will moved from losses to stock ",
          icon: "question",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonColor: "#152f5f",
          confirmButtonText: "Ok",
          reverseButtons: true,
        }).then((result) => {
          if(result.value){
            this.setState({ sloader: true });
        InventoryService.EditLossesentery(data) .then(() =>
        this.setState({sloader:false},()=>
        Swal.fire({
          title: `Losses Updated successfully`,
          text: ".",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        }).then(()=>this.props.navigate(-1))))
        .catch((e)=>{
          this.setState({message:e.response.data.message,sloader:false},()=>{
              if(e.response.data.error ==="Unauthorized"){
                  Swal.fire({
                    title: `Your Session is Lost: Please login Back to resume`,
                    text: ".",
                    icon: "error",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  }).then(()=>this.props.navigate('/'))
              }else{
                Swal.fire({
                  title: `${e.response.data.message}`,
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                })
              }})
          })
      }})
      }
  }

  handleItemSelect=(value, values)=> {
    if (values !== "" && values !== undefined && values !== null &&  typeof values === "object") {
      this.setState({
        selected: true,
        itemid: values.id,
        itemname: values.name,
        batchidList: this.state.stocklist.filter(
          (e) => e.wid === this.state.whid && e.name === values.name
        ),
      });
    }else{
      this.setState({
        selected: false,
        itemid: '',
        itemname: '',
        batchidList: [],
      });
    }
  }
  handleCommentChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  // handleBatchSelect(value, values) {
  //   if (value !== "") {
  //     this.setState({
  //       batchid: parseInt(value),
  //       price: this.state.batchidList.find((e) => e.batchid === parseInt(value))
  //         .rate,
  //       currentstock: this.state.batchidList.find(
  //         (e) => e.batchid === parseInt(value)
  //       ).currentStock,
  //     });
  //   }
  // }

  handelSelect = (e) => {
    this.setState({
      batchid: e.batchid,
      price: e.rate,
      currentstock: e.currentStock,
      selected: false,
      sid:e.sid,
    });
  };

  warehouseChange = (value,values) =>{
    if (value !== "" && values !== undefined && values !== null && typeof values === "object") {
    this.setState({
      warehouse: values.name,
      whid: values.id,
      selected: false,
      itemid: '',
      itemname: '',
      batchidList: [],
      catid: '',
      category: '',
      filteritemlist:[],
    })
  }else{
    this.setState({
      warehouse: '',
      whid: '',
      selected: false,
      itemid: '',
      itemname: '',
      batchidList: [],
      catid: '',
      category: '',
      filteritemlist:[],
    })
  }
  }

  handleCategory = (value, values) => {
    if (value !== "" && values !== undefined && values !== null && typeof values === "object") {
      this.setState({
        catid: values.id,
        category: values.category,
        filteritemlist: this.state.itemsList.filter(
          (e) => e.catid === values.id
        ),
        selected: false,
        itemid: '',
        itemname: '',
        batchidList: [],
      })
    }else{
      this.setState({
        catid: '',
        category: '',
        filteritemlist:[],
        selected: false,
        itemid: '',
        itemname: '',
        batchidList: [],
      })
  }
}

  onfocus() {
    document
      .getElementById("quantity")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document
      .getElementById("quantity")
      .addEventListener("keydown", function (e) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    CategoryServices.getCategory(currentTenant)
      .then((response) =>
        this.setState({
          CategoryList: response.data.filter(
            (e) => e.categorytype === "Product"
          ),
        })
      )
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
    InventoryService.getItemNames(currentTenant).then((response) =>
      this.setState({
        itemsList: response.data,
      })
    );

    WarehouseServices.getWarehouses(currentTenant).then((response) =>
      this.setState({
        WarehouseList: response.data,
      })
    );
    InventoryService.retriveStock(currentTenant).then((response) =>
      this.setState({
        stocklist: response.data,
      })
    );

    if(this.props.location.state.id !== -1){
      InventoryService.GetDamagedDataById(this.props.location.state.id).then((res)=>
        this.setState({
          id:res.data.id, 
          itemid: res.data.itemid,
          itemname: res.data.itemname,
          category: res.data.category,
          categoryid: res.data.catid,
          warehouse: res.data.whname,
          whid: res.data.whid,
          sid:res.data.sid,
          price: res.data.price,
          quantity: parseFloat(res.data.quantity),
          currentstock: parseFloat(res.data.quantity),
          comment:res.data.comment,
          batchid:res.data.batchid,
        })
      )
    }

  }

  validate() {
    let errors = {};
    if (this.state.quantity === "") {
      errors.quantity = "Please Enter Quantity";
    }
    if (this.state.quantity <= 0 && this.state.id=== -1) {
      errors.quantity = "Value Should be greaterthan 0";
    }else if(this.state.quantity < 0 ){
      errors.quantity = "Value Should be greaterthan or equal to 0";
    }

    if (this.state.quantity > this.state.currentstock) {
      errors.quantity = `Value Should be lessthan or equal to batch quantity ${this.state.currentstock}`;
    }

    if (this.state.whid === "") {
      errors.warehouse = "Please Select Org Unit";
    }

    if (this.state.category === "") {
      errors.category = "Please Select Category";
    }

    if (this.state.itemname === "") {
      errors.itemname = "Please Select Item";
    }
    return errors;
  }

  render() {
    const {
      itemsList,
      CategoryList,
      WarehouseList,
      filteritemlist,
      batchidList,
      itemname,
      itemid,
      category,
      catid,
      warehouse,
      whid,
      price,
      quantity,
      batchid,
    } = this.state;
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <Link
                  as="button"
                  className=" text-secondary"
                  to={{ pathname: "/losses"}} state={{ message: "" }}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              {/* <li className="breadcrumb-item text-secondary">
                <Link to="/losses">Losses</Link>
              </li> */}
              <li className="breadcrumb-item active">{this.state.id === -1?"Add Losses":"Revert Losses"}</li>
            </ol>

            <div className="card-header">
              <h3 className="card-title">{this.state.id === -1?"Add Losses":"Revert Losses"}</h3>
            </div>

            <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={this.state.sloader}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>

            <div className="card-body">
              <Formik
                initialValues={{
                  itemsList,
                  CategoryList,
                  WarehouseList,
                  filteritemlist,
                  batchidList,
                  itemname,
                  itemid,
                  category,
                  catid,
                  warehouse,
                  whid,
                  quantity,
                  price,
                  batchid,
                }}
                validateOnChange={false}
                validate={this.validate}
                onSubmit={this.onSubmit}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form className="form-group row">
                    <fieldset className="form-group col-md-4" disabled={this.state.id !== -1}>
                      <Autocomplete
                        id="subaccount"
                        className="subacc-select"
                        name="warehouse"
                        options={WarehouseList}
                        getOptionLabel={(option) => option.name}
                        
                        
                        value={values.warehouse ? undefined : undefined}
                        onChange={this.warehouseChange}
                        disabled={this.state.id !== -1}
                        renderInput={(params) => (
                          <FormikTextField
                            {...params}
                            className="form-control"
                            type="text"
                            name="warehouse"
                            value={this.state.warehouse}
                            label={`${this.props.location.state.id === -1?"Select Org Unit":this.state.warehouse}`}
                            variant="standard"
                            fullWidth
                            disabled={this.state.id !== -1}
                          />
                        )}
                      />
                    </fieldset>
                    <fieldset className="form-group col-md-4" disabled={this.state.id !== -1}>
                      <Autocomplete
                        id="subaccount"
                        className="subacc-select"
                        name="category"
                        options={CategoryList}
                        getOptionLabel={(option) => option.category}
                        
                        
                        value={values.category ? undefined : undefined}
                        onChange={this.handleCategory}
                        disabled={this.state.id !== -1}
                        renderInput={(params) => (
                          <FormikTextField
                            {...params}
                            className="form-control"
                            type="text"
                            name="category"
                            value={this.state.category}
                            label={`${this.props.location.state.id === -1?"Select Category":this.state.category}`}
                            variant="standard"
                            fullWidth
                            disabled={this.state.id !== -1}
                          />
                        )}
                      />
                    </fieldset>
                    <fieldset className="form-group col-md-4" disabled={this.state.id !== -1}>
                      <Autocomplete
                        id="subaccount"
                        className="subacc-select"
                        name="itemname"
                        options={filteritemlist}
                        getOptionLabel={(option) => option.name}
                        value={values.itemname ? undefined : undefined}
                        onChange={this.handleItemSelect}
                         disabled={this.state.id !== -1}
                        renderInput={(params) => (
                          <FormikTextField
                            {...params}
                            className="form-control"
                            type="text"
                            name="itemname"
                            label={`${this.props.location.state.id === -1?"Select Item":this.state.itemname}`}
                            variant="standard"
                            value={this.state.itemname}
                            fullWidth
                            disabled={this.state.id !== -1}
                          />
                        )}
                      />
                    </fieldset>
                  {this.state.selected === true?
                  <TableContainer>
                    <Table className="table-sm">
                      <TableHead>
                        <TableRow>
                          <TableCell className="text-center">Item</TableCell>
                          <TableCell className="text-center">Batch Id</TableCell>
                          <TableCell className="text-center">Stock Type</TableCell>
                          <TableCell className="text-center">Price</TableCell>
                          <TableCell className="text-center">Stock</TableCell>
                          <TableCell className="text-center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {batchidList.map((e,i) => (
                          <TableRow key={i}>
                            <TableCell className="text-center">{e.name}</TableCell>
                            <TableCell className="text-center">{e.custombatchid}</TableCell>
                            <TableCell className="text-center">{e.stocktype}</TableCell>
                            <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={e.rate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                            <TableCell className="text-center">{e.currentStock}</TableCell>
                            <TableCell className="text-center">
                              {" "}
                              <span
                                as="buttom"
                                className="btn btn-sm hovbuttonColour"
                                onClick={() => this.handelSelect(e)}
                              >
                                Select
                              </span>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    </TableContainer>
                    :<>
                    <fieldset className="form-group col-md-4">
                      <FormikTextField
                        className="form-control"
                        type="text"
                        name="price"
                        label="Price"
                        value={this.state.price}
                        disabled
                      />
                    </fieldset>
                    <fieldset className="form-group col-md-4">
                      <FormikTextField
                        id="quantity"
                        className="form-control"
                        type="number"
                        name="quantity"
                        label={`Quantity (Avilable: ${this.state.currentstock})`}
                        min="0"
                        onChange={(e) =>
                          this.setState({
                            quantity: e.target.value,
                          })
                        }
                        onFocus={this.onfocus}
                      />
                    </fieldset>

                    <fieldset className="form-group col-md-12  mt-2">
                         <FormikTextField name="comment" className="form-control" multiline rows={2} label="Comment/Reason" variant="outlined" value={this.state.comment}
                           onChange={(e) => this.setState({comment:e.target.value})}
                         />
                       </fieldset>
                    {/* <fieldset className="form-group col-md-4">
                  <FormControl
                  //  sx={{ m: 1, minWidth: 120 }}
                    className="col-md-12"
                  >
                    <InputLabel id="demo-simple-select-filled-label">
                      Comments
                    </InputLabel>
                    <Select
                      value={this.state.comment}
                      onChange={this.handleCommentChange}
                      variant="standard"
                      fullWidth
                    >
                      {this.state.comments.map((option) => {
                        return (
                          <MenuItem key={option} value={option} >
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </fieldset> */}
                    </>}

                    {/* {this.state.comment === "Other"? */}
                        

                    <fieldset className="form-group col-md-12 mt-4" />
                    {this.state.sloader === true ? (
                      <button className="btn btn-sm hovbuttonColour" disabled>
                        <Spinner
                          animation="border"
                          size="sm"
                          className="mr-1"
                        />
                        {"Saving..."}{" "}
                      </button>
                    ) : (
                      <button
                        className="btn btn-sm hovbuttonColour"
                        type="submit"
                      >
                        <i className="fa fa-save mr-1" /> {"Save"}
                      </button>
                    )}
                    <Link
                      as="button"
                      className="btn btn-sm deletebutton ml-1"
                      to={{ pathname: "/losses"}} state={{ message: "" }}
                    >
                      <b className="text-danger"> X </b> Cancel
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>

            {this.state.id ===-1?"":
            <fieldset className="form-group col-md-12">
              <label className="text-red">Note : The entered quantity will be moved from losses to corresponding batch stock  
              </label>
            </fieldset>
            }

          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Damage);
