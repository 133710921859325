import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import { currentTenant, userid } from '../../Common/Credentials.js';
import { spin } from '../../Common/NewLoader.jsx';
import { appmasterid } from '../../Common/Permissions.js';
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
// import UserService from '../../users/UserService.js';
import WarehouseServices from './WarehouseServices.js';


import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class WarehouseTable extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        message:'',
        warehouse:[ ],
        loading:false,
        up:''

    }
    // this.refreshCustomer=this.refreshCustomer.bind(this)
    this.deleteWarehouse=this.deleteWarehouse.bind(this)
  }


  deleteWarehouse(id){
   WarehouseServices.deleteWarehouse(id)
   .then(()=>{
    this.setState({ message: "Org Unit Deleted Successfully",loading: true})
    this.refreshWarehouse()
   }
   )
   .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})
   
}

refreshWarehouse(){
  WarehouseServices.getWarehouses(currentTenant)
    .then(
      response => {
          this.setState({warehouse:response.data})
        this.setState({loading:false})
        }
      )  
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    }) 
  }

    componentDidMount () {

      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }

    //   UserService.UserPermissions(userid).then(response=>this.setState({up:response.data}))
      
      this.setState({loading:true})

      this.refreshWarehouse();

     }

    render() {

      const columns = [
        {
          name: "id",
          label: "Id",
          options: { filter: true, sort: true, display:false,viewColumns:false ,sortOrder: "asc" },
        },
        {
          name: "name",
          label: "Org Unit",
          options: { filter: true, sort: true },
        },
        {
          name: "address",
          label: "Address",
          options: { filter: true, sort: true },
        },
        {
          name: "Actions",
          options: {
            filter: false,
            sort: false,
            empty: true,
            display: appmasterid === "2" || appmasterid === "3" ? true : false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <>
                <Link
                  as="button"
                  className="fa fa-edit textColour mr-2"
                  to={{
                    pathname: "/newwarehouse"}}
                    state={{ id:tableMeta.rowData.find((e) => e), pagename:'Edit Org Unit'}}
                >
                </Link>
                {this.state.warehouse.length>1?
                <span
                  as="button"
                  data-tip
                  data-for="delete"
                  className="fa fa-trash text-danger"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you wish to delete Org Unit ${tableMeta.rowData.find(
                          (e) => e
                        )}?`
                      )
                    )
                      this.deleteWarehouse(tableMeta.rowData.find((e) => e));
                  }}
                ></span>:''}
                </>
              );
            },
          },
        },
      ]

      const options = {
        filterType: "checkbox",
        selectableRows: "none",
        customToolbar: () => {
          return (
            <>
              {appmasterid === "2" || appmasterid === "3" ? (
                <Link
                  as="button"
                  className="float-right btn btn-sm m-0 p-0"
                  to={{pathname: "/newwarehouse"}}
                    state= {{ id: -1, pagename:'New Org Unit'}}
                >
                  <CustomToolbar />
                </Link>
              ) : (
                ""
              )}
            </>
          );
        },
      };
        return (
            <div>
            <Header />
                <Menu/>
                <div className="content-wrapper">
                    <div className="card">  
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item text-secondary">
                                <Link to="/masters">Home</Link>
                            </li>
                            
                            <li className="breadcrumb-item active">Org Unit</li>
                            </ol>
                        {/* <div className="text-center text-success text-uppercase">{this.state.message}</div> */}
                            <section className="content">
                        <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                            
                            {/* /.card */}
                            <div className="card">
                                
                                <div className="card-body">
                                    <div>{this.state.loading === true ?<div className="text-center">{spin}</div> : 
                                    <ThemeProvider theme={theme}>
                                      <MUIDataTable
                                      className="table-sm px-3"
                                      title={"Org Unit"}
                                      data={this.state.warehouse}
                                      columns={columns}
                                      options={options}
                                    />
                                    </ThemeProvider>
                                    }</div>                              
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                        </div>
                        {/* /.container-fluid */}
                    </section>


                        
                    </div>
                                
                    </div>
                </div>    
          
        )
       
    }
    
}
export default withRouter(WarehouseTable)