import React, { useEffect } from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Menu from "../Component/Menu.js";
import Header from "../Component/Header.js";
import { screens } from "../Common/Products.js";
import Credentials, { transactionFrom } from "../Common/Credentials.js";

import dashboardIcon from "../images/Icons/dashboard.png"
import mastersIcom from "../images/Icons/generic.png"
import transactionsIcon from "../images/Icons/transaction.png"
import reportsIcon from "../images/Icons/annual-report.png"
import { Backdrop } from "@mui/material";

const AssetMenu = () => {

  useEffect(() =>{
    Credentials.setSelectedMenu("Assets")
  },[])

  return (
    <div>
       <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={transactionFrom === null}
      // onClick={handleClose}
    >
       <div className="flex-column ">
      <div className="d-flex justify-content-center">
      <i className="text-red">Please set transaction start date in order to continue.</i>
      </div>
      <div className="d-flex justify-content-center">
      <Link to="/settings" className="btn btn-sm hovbuttonColour">Set</Link>
      </div>
      </div>
    
    </Backdrop>
      <Header />
      <Menu />
      <section className="content-wrapper">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item text-secondary">
            <Link to="/assetmanagement">Home</Link>
          </li>
          <li className="breadcrumb-item active">Asset Management</li>
        </ol>
        <div className="h-max border-slate-300 bg-white p-4 rounded-lg ">
          <ul className="d-flex flex-row list-unstyled">
              <li className="p-1 mr-4">
                <Link
                  to={{ pathname: "/index" }}
                  state={{ message: "" }}
                  className="textColour py-1"
                >
                  <img className="nav-icon mr-2" width="25" src={dashboardIcon}/>
                  Dashboard
                </Link>
              </li>
            </ul>
          <div className="row  gap-x-8 gap-y-6 mt-2">
          {(screens.includes("Assets") || screens.includes("Maintenance") || screens.includes("Production Usage") ||  screens.includes("Production Usage") || screens.includes("Transportation")   )?
            <div className="col-sm-12 col-md-6 col-lg-4 w-full">
              <span className="font-weight-bold my-3 ">
              <img className="nav-icon" width="25" src={transactionsIcon}/>
                <span className="pl-2">Transactions</span>
              </span>
              <ul className="menuul pl-4 ml-3">
              {screens.includes("Assets") ? 
                <li >
                  <Link
                    className="textColour p-1"
                    to={{pathname: "/asset"}}
                    state={{ message: "",sold:false,rent:false,active:true,type:'Rental',stype:'Sales'}}
                  >
                    Assets
                  </Link>
                </li>:""}
                {screens.includes("Maintenance") ? 
                <li >
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/assetmaintenance" }}
                    state={{ message: "" }}
                  >
                    Maintenance
                  </Link>
                </li>
                :""}
                {screens.includes("Production Usage") ? 
                <li >
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/productionusage" }}
                    state={{ message: "" }}
                  >
                    Production Usage
                  </Link>
                </li>:""}
                {screens.includes("Transportation") ? 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/transportation" }}
                    state={{ message: "" }}
                  >
                    Transportation
                  </Link>
                </li>:""}
              </ul>
            </div>:""}
            {/* {screens.includes("Assets Reports")? */}
            <div className="w-full col-sm-12 col-md-6 col-lg-4">
              <span className="font-weight-bold my-3">
              <img className="nav-icon" width="25" src={reportsIcon}/>
                <span className="pl-2"> Reports</span>
              </span>
              <ul className="menuul pl-4 ml-3">
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/transportationreport" }}
                    state={{ message: "" }}
                  >
                    Transportation Report
                  </Link>
                </li>
              </ul>
            </div>
            {/* :""} */}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default withRouter(AssetMenu);
