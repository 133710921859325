import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import { userid } from '../../Common/Credentials.js';
import { spin } from '../../Common/NewLoader.jsx';
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import MasterService from '../MasterService.js';


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});


class CitiesTable extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        message:'',
        citiesdata:[],
        loading:false,

    }
    // this.refreshCustomer=this.refreshCustomer.bind(this)
    // this.deleteColour=this.deleteColour.bind(this)
  }


  deleteCity(id){
    MasterService.DeleteCity(id)
    .then(()=>
    MasterService.getAllCities()
     .then(response =>
     this.setState({citiesdata:response.data}),
      this.setState({message:"Citiy Deleted Successfully"})
     )
    )
    .catch((e)=>{
     if(e.response.data.error ==="Unauthorized"){
         alert("Your Session is Lost: Please login Back to resume");
         this.props.navigate(`/`);
     }
 })
    
 }


    componentDidMount () {

      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }
      
      this.setState({loading:true})
      MasterService.getAllCities().then(res=>this.setState({citiesdata:res.data,loading:false}))
     }

    render() {

        const columns = [
            {name: "id",label: "Id",options: {filter: false,sort: true,display:false}},
            {name: "name",label:"City",options: {filter: false,sort: true,}},
            {name: "state",label:"State",options: {filter: true,sort: true,}},
            {name: "country",label:"Country",options: {filter: true,sort: true,}},
            {
              name: "Delete",
              options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <span
                      as="button"
                      data-tip
                      data-for="delete"
                      className="fa fa-trash text-danger"
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure you wish to delete Colour ${tableMeta.rowData.find(
                              (e) => e
                            )}?`
                          )
                        )
                          this.deleteCity(tableMeta.rowData.find((e) => e));
                      }}
                    ></span>
                  );
                },
              },
            },
        ];
        const options = {
            filterType: 'checkbox',
            selectableRows: "none",
            customToolbar: () => {
             return (<>
             
              <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/addcity"}}>
                <CustomToolbar />
              </Link>
             </>
             );
           }
          }
        return (
            <div>
                <Header/>
                <Menu/>
                <div className="content-wrapper">
                    <div className="card">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item text-secondary">
                                <Link to="/superadminmasters">Home</Link>
                            </li>
                            
                            <li className="breadcrumb-item active">Cities</li>
                        </ol>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                
                                            <div className="card-body">
                                               {this.state.loading === true ?  <div className="text-center">{spin}</div>:
                                                <ThemeProvider theme={theme}> 
                                                <MUIDataTable
                                                    className="table-sm px-3"
                                                    title={"Cities"}
                                                    data={this.state.citiesdata}
                                                    columns={columns}
                                                    options={options}
                                                />  
                                                </ThemeProvider>
                                                }
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>    
          
        )
       
    }
    
}
export default withRouter(CitiesTable)