import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import { userid } from '../../Common/Credentials.js';
import { spin } from '../../Common/NewLoader.jsx';
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import MasterService from '../MasterService.js';
import Footer from '../../Component/Footer.js';
import Swal from 'sweetalert2';


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});


class CountryTable extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        message:'',
        countriesdata:[],
        loading:false,

    }
  }



  deleteCountry(id){

    Swal.fire({
      title: "Delete Estimation?",
      text: "Are you sure you want Delete?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor:'#152f5f',
      cancelButtonText: "No",
      reverseButtons: true
  }).then((result)=>{
      if (result.value) {
        MasterService.DeleteCountry(id).then
        ((response) =>{
          Swal.fire({
            title: "Country Deleted Successfully",
            text: "",
            icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor:'#152f5f',
          }).then(() =>
          MasterService.getAllCountries().then(response =>
    this.setState({countriesdata:response.data}),
     this.setState({message:"Country Deleted Successfully"})
    )
   )
        })
      }
  });
//    MasterService.DeleteCountry(id)
//    .then(()=>
//    MasterService.getAllCountries()
//     .then(response =>
//     this.setState({countriesdata:response.data}),
//      this.setState({message:"Country Deleted Successfully"})
//     )
//    )
//    .catch((e)=>{
//     if(e.response.data.error ==="Unauthorized"){
//         alert("Your Session is Lost: Please login Back to resume");
//         this.props.navigate(`/`);
//     }
// })
   
}

  
refreshCountries(){
  MasterService.getAllCountries().then(res=>this.setState({countriesdata:res.data,loading:false})) 
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })  
  }

    componentDidMount () {

      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }
      
      this.setState({loading:true})

      this.refreshCountries();

     }

    render() {

        const columns = [
            {name: "id",label: "Id",options: {filter: false,sort: true,display:false}},
            {name: "name",label:"Country",options: {filter: true,sort: true,}},
            {
              name: "Delete",
              options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <span
                      as="button"
                      data-tip
                      data-for="delete"
                      className="fa fa-trash text-danger"
                      onClick={() => {this.deleteCountry(tableMeta.rowData.find((e) => e))}}
                    ></span>
                  );
                },
              },
            },
        ];
        const options = {
            filterType: 'checkbox',
            selectableRows: "none",
            customToolbar: () => {
             return (<>
             
              <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/addcountry"}}>
                <CustomToolbar />
              </Link>
             </>
             );
           }
          }
        return (
            <div>
                <Header/>
                <Menu/>
                <div className="content-wrapper">
                    <div className="card">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item text-secondary">
                                <Link to="/superadminmasters">Home</Link>
                            </li>
                            
                            <li className="breadcrumb-item active">Countries</li>
                        </ol>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                
                                            <div className="card-body">
                                              {this.state.loading === true ?   <div className="text-center">{spin}</div>:
                                                <ThemeProvider theme={theme}> 
                                                <MUIDataTable
                                                    className="table-sm px-3"
                                                    title={"Countries"}
                                                    data={this.state.countriesdata}
                                                    columns={columns}
                                                    options={options}
                                                />  
                                                </ThemeProvider>
                                                }
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <Footer/>
            </div>    
          
        )
       
    }
    
}
export default withRouter(CountryTable)