import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

export let currentTenant

class TaxService{
    getTaxes(currentTenant) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/gettaxes/${currentTenant}`,)
     }
     addTax(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addtax`,data)
     }
     deleteTax(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletetax/${id}`,)
     }
}
export default new TaxService()