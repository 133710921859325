import { Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { FormikTextField } from '../../CommonComponents/FormField.js';
import { Spinner } from 'react-bootstrap';
import MasterService from '../MasterService.js';


class NewCountry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      country: '',
      code:'',
      message:'',
      loader:false,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(values) {
     this.setState({loader:true})
    let data = { 
      name:this.state.country.toUpperCase(),
      code:this.state.code.toUpperCase()
    }
    MasterService.AddCountry(data).then(() => 
    this.props.navigate('/countries'))
    .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
      this.setState({message:e.response.data.message,loader:false})
    })

  }

  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    
 }


  validate(values) {

    let errors = {}

     if (!values.country|| values.country==='') {
     errors.country = "Country is Required";
      }
      if (!values.code|| values.code==='') {
        errors.code = "Code is Required";
         }
    return errors;
  }

  render() {
     let {id,country,code} = this.state;
 
    return (
      
      <div>
         <Header/>
         <Menu/>
        <div className="content-wrapper">
          <div className="card">
           
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/countries"}} state={{ message: ""}}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/superadminmasters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/countries">country</Link>
              </li>
              <li className="breadcrumb-item active">New country</li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">New country</h3>
            </div>
            <div className="card-body">
            
              <Formik
                initialValues={{id,country,code}}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}>
                {() => (
                  <Form autoComplete="off">
                    <div className="form-row">

                      <fieldset className="form-group col-md-3">
                      <FormikTextField className="form-control"  type="text" name="country" label="Enter Country" onChange={(e)=>this.setState({country:e.target.value,message:''})}/>
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                      <FormikTextField className="form-control"  type="text" name="code" label="Enter Country Code" onChange={(e)=>this.setState({code:e.target.value,message:''})}/>
                      </fieldset>
                    </div>
                    <div className="form-group col-md-12 text-red">{this.state.message}</div>
                    {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>: <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fas fa-save mr-2"/>Save</button>}
                    <Link as="button" className="btn btn-sm deletebutton ml-1"  to={{pathname: '/country'}} ><b style={{color:"#FA5733"}}> X</b> Cancel</Link> 
                  
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

      <Footer/>
      </div>
    );
  }
}
export default withRouter(NewCountry)