import React, { useEffect, useState } from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Menu from "../Component/Menu.js";
import Header from "../Component/Header.js";
import { screens } from "../Common/Products.js";
import UsercustomerService from "../Masters/Usercustomers/UsercustomerService.js";
import SettingsServices from "../Settings/SettingsServices.js";
import Credentials, { currentTenant, transactionFrom, userid } from "../Common/Credentials.js";

import dashboardIcon from "../images/Icons/dashboard.png"
import storeIcon from "../images/Icons/shop.png"
import transactionsIcon from "../images/Icons/transaction.png"
import reportsIcon from "../images/Icons/annual-report.png"
import { Backdrop } from "@mui/material";

const ProductionMenu = () => {

  useEffect(() => {
    Credentials.setSelectedMenu("Production")
  },[])

  return (
    <div>
      <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={transactionFrom === null}
      // onClick={handleClose}
    >
       <div className="flex-column ">
      <div className="d-flex justify-content-center">
      <i className="text-red">Please set transaction start date in order to continue.</i>
      </div>
      <div className="d-flex justify-content-center">
      <Link to="/settings" className="btn btn-sm hovbuttonColour">Set</Link>
      </div>
      </div>
    
    </Backdrop>
      <Header />
      <Menu />
      <section className="content-wrapper">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item text-secondary">
            <Link to="/index">Home</Link>
          </li>
          <li className="breadcrumb-item active">Production</li>
        </ol>
        <div className="h-max border-slate-300 bg-white p-4 rounded-lg ">

        <ul className="d-flex flex-row list-unstyled">
              <li className="p-1 mr-4">
                <Link
                  to={{ pathname: "/index" }}
                  state={{ message: "" }}
                  className="textColour py-1"
                >
                   <img className="nav-icon mr-2" width="25" src={dashboardIcon}/>
                  Dashboard
                </Link>
              </li>
            </ul>
          <div className="row  gap-x-8 gap-y-6">
          {(screens.includes("Estimation") || screens.includes("Job Order") || screens.includes("Indenting") || screens.includes("InProcess") || screens.includes("Quality Analysis") || screens.includes("Immediate Production") )?
            <div className="col-sm-12 col-md-6 col-lg-4 w-full">
              <span className="font-weight-bold my-3">
              <img className="nav-icon" width="25" src={transactionsIcon}/>
                <span className="pl-2">Transactions</span>
              </span>
              <ul className="menuul pl-4 ml-3">
                <li >
                  <Link
                    className="textColour p-1"
                    to={{pathname: "/productiontemplates"}}
                      state= {{ message: ""}}
                  >
                  Production Template
                  </Link>
                  </li>
                {/* {screens.includes("Immediate Production") ?  */}
                <li >
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/immediateproduction" }}
                    state={{ message: "" }}
                  >
                    Immediate Production
                  </Link>
                </li>
                {/* :""} */}
                {screens.includes("Estimation") ? 
                <li >
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/estimation" }}
                    state={{ message: "" }}
                  >
                    Estimation
                  </Link>
                </li>:""}
                {screens.includes("Job Order") ? 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/joborder" }}
                    state={{ message: "" }}
                  >
                    Job Order
                  </Link>
                </li>:""}
                {screens.includes("Indenting") ? 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/indenting" }}
                    state={{ message: "" }}
                  >
                    Indenting
                  </Link>
                </li>:""}
                {screens.includes("InProcess")?
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/inprocess" }}
                    state={{ message: "" }}
                  >
                    InProcess
                  </Link>
                </li>:""}
                {screens.includes("Quality Analysis")?
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/qa" }}
                    state={{ message: "" }}
                  >
                    Quality Analysis
                  </Link>
                </li>:""}
              </ul>
            </div>:""}
            {screens.includes("Store") ?
            <div className="w-full col-sm-12 col-md-6 col-lg-4">
              <span className="font-weight-bold my-3">
              <img className="nav-icon " width="25" src={storeIcon}/>
                <span className="pl-2"> Store</span>
              </span>
              <ul className="menuul pl-4 ml-3">
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/store" }}
                    state={{ message: "" }}
                  >
                    Store
                  </Link>
                </li>
                </ul>
                </div>:""}
            {/* {screens.includes("Production Reports")? */}
            <div className="w-full col-sm-12 col-md-6 col-lg-4">
              <span className="font-weight-bold my-3 ">
              <img className="nav-icon" width="25" src={reportsIcon}/>
                <span className="pl-2"> Reports</span>
              </span>
              <ul className="menuul pl-4 ml-3">
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/productionreports" }}
                    state={{ message: "" }}
                  >
                    Production Report
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/productionprocessreports" }}
                    state={{ message: "" }}
                  >
                    Process Report
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/productiontransactionreports" }}
                    state={{ message: "" }}
                  >
                   Stock Transaction Report
                  </Link>
                </li>
              </ul>
            </div>
            {/* :""} */}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default withRouter(ProductionMenu);
