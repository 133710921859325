import { Autocomplete } from "@mui/material";
import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import NewJournalService from "../NewJournalService";
import moment from "moment";
import {
  createTheme,
} from "@mui/material";
import {NumericFormat} from "react-number-format";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material";
import LedgersViewNew from "./LedgersViewNew.jsx";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class Ledgerview extends Component {
  constructor(props) {
    super(props);
    this.state = {
       //name1 :this.props.location.state,
      combdata: [],
      selectedLed: [],
      selectedledger: this.props.location.state !== undefined ? this.props.location.state : "",
      check: this.props.location.state,
    };
  }

  goBack() {
    this.props.navigate(-1);
  }

  goNewLedgerView() {
    this.props.navigate({pathname:`/newledgerview`});
  }
  componentDidMount() {
    // alert(name)
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    const name = this.props.location.state;
    NewJournalService.getLedgersView(currentTenant)
      .then((response) => {
        this.setState({
          combdata: response.data,
          selectedLed: response.data.filter((e) => e.ledgername === name),
        });
        const script = document.createElement("script");
        script.src = "../../js/content.js";
        script.async = true;
        document.body.appendChild(script);
        this.setState({ loading: false });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

  onLedgerSelect(value, values) {
    if (value !== undefined || value !== "") {
      let val = this.state.combdata.filter((e) => e.ledgername === value).sort((a,b)=> a.createdAt<b.createdAt ? 1:-1);
      
      // let val1 = val.filter(e=>e.jvid !== null?e.jvid:e.cjvid)
      // console.log(val1)
      this.setState({ selectedLed: val, selectedledger: value });
    }
  }
  render() {
    // let jvid = this.state.selectedLed.map((e) =>
    //   e.jvid !== null ? e.jvid : e.cjvid
    // );
    // console.log(jvid,this.state.selectedLed)

    let ledgers = [
      ...new Set(
        this.state.combdata.map((e) => {
          return e.ledgername;
        })
      ),
    ];
    const columns = [
      {
        name: "behav",
        label: "EntryType",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: 'asc',
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY hh:mm");
          },
        },
      },
      {
        name: "currentamt",
        label: "Previous Balance",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "jvamount",
        label: "Transaction Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta, values) => {
            // console.log(values.rowData.find(e=>e))
            return (
              <>
                {" "}
                {values.rowData.find((e) => e) === 1 ? "(-)" : "(+)"}
                <NumericFormat
                  className="pr-3"
                  displayType={"text"}
                  value={tableMeta}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={true}
                  thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
              </>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },

      {
        name: "amount",
        label: "Current Balance",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
          //   customHeadRender: (columnMeta,) => (
          //     <div style={{style: { textAlign:'right'}}}>
          //         {columnMeta.name}
          //     </div>
          // )
        },
      },
      {
        name: "jvid",
        label: "Transaction",
        options: {
          filter: true,
          sort: true,

          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log(value)
            return (
              <>
                {value !== null ? (
                  <Link
                    as="button"
                    data-tip
                    data-for="view"
                    to={{ pathname: "/jvdetails"}} state= {{ id: value }}
                  >
                    {value.id}
                  </Link>
                ) : tableMeta.rowData[6] !== null ? (
                  <Link
                    as="button"
                    data-tip
                    data-for="view"
                    to={{
                      pathname: "/jvdetails"}}
                      state={{ id: tableMeta.rowData[6]}}
                  >
                    {tableMeta.rowData[6].id}
                  </Link>
                ) : (
                  "Opening"
                )}
              </>
            );
          },
        },
        setCellProps: (value) => ({ style: { textAlign: "center" } }),
      },
      {
        name: "cjvid",
        label: "Transaction",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log(value)
            return (
              <>
                {value !== null ? (
                  <Link
                    as="button"
                    data-tip
                    data-for="view"
                    to={{ pathname: "/jvdetails"}} state= {{ id: value }}
                  >
                    {value.id}
                  </Link>
                ) : (
                  "Opening"
                )}
              </>
            );
          },
        },
        setCellProps: (value) => ({ style: { textAlign: "center" } }),
      },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      resizableColumns: true,

      // MuiTableRow-roo:

      setRowProps: (row) => {
        if (row[5].props.children !== "Opening") {
          if (row[0] === 1) {
            return {
              style: { background: "#f7dddd" },
            };
          } else {
            return {
              style: { background: "#ecf8fb" },
            };
          }
        }
      },

      //   customToolbar: () => {
      //     return (<>

      //         <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/addcost",state: { id: -1, pagename: 'Add Price' }}}>
      //           <CustomToolbar />
      //         </Link>
      //         :''
      //       }
      //    </>
      //    );
      //  }
    };
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            {/* <div className="card-header">
              <h3 className="card-title">Ledger View</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item active">View Ledgers</li>
            </ol>
            {this.state.check === undefined ? (
              ""
            ) : (
              <div>
                <span
                  as="button"
                  className="float-left btn btn-sm bg-white shadow-sm ml-3"
                  onClick={() => this.goBack()}
                >
                  <i className="fas fa-arrow-left"></i> Back
                </span>

                <span
                  as="button"
                  className="float-left btn btn-sm bg-white shadow-sm ml-3"
                  onClick={() => this.goNewLedgerView()}
                >
                  <i className="fas fa-arrow-right"></i> New Report
                </span>
              </div>
            )}
            
              <div className="card-body">
                <div className="text-center">
                  {this.state.loading === true ? spin : ""}
                </div>
                <Formik
                  initialValues={{ ledgersel: "", ledgers }}
                  enableReinitialize={true}
                >
                  {({ values, setFieldValue }) => (
                    <Form autoComplete="off">
                      <div className="form-row mt-0 mb-0">
                        <fieldset className="form-group col-md-3 mt-0 mb-3">
                          <Autocomplete
                            id="aledgers"
                            className="grn-select "
                            name="aledersel"
                            options={ledgers}
                            getOptionLabel={(option) => option}
                            
                            
                            value={values.ledgersel ? undefined : undefined}
                            onChange={(e, value) => {
                              setFieldValue("ledgersel", value);
                            }}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control mb-3"
                                type="text"
                                name="ledgersel"
                                label="Select Ledger"
                                onBlur={(e) => {
                                  this.onLedgerSelect(e.target.value, values);
                                }}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>

                        {/* {this.state.selectedLed!==''? */}
                        <fieldset className="form-group col-md-12 mt-0 mb-3">
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={`Ledger - ${this.state.selectedledger!==""?this.state.selectedledger:''}`}
                              data={this.state.selectedLed}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                          {/* <Table id="tb" className="table-sm">
<TableHead>
<TableRow>
<TableCell>Date</TableCell>
<TableCell>Previous Balance</TableCell>
<TableCell>Transaction Amount</TableCell>
<TableCell>Current Balance</TableCell>
<TableCell>Transaction</TableCell>
</TableRow>
</TableHead>
<TableBody>
{this.state.selectedLed.map((e)=>
<TableRow key={e.id}>

<TableCell>{moment(e.createdAt).format('DD-MM-yyyy:HH:MM')}</TableCell>
<TableCell>
<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.currentamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>
</TableCell>
<TableCell>
<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.jvamount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>
</TableCell>
<TableCell><NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.amount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}   ></NumericFormat>
</TableCell>
<TableCell>{e.jvid!==null?e.jvid.id:"Opening"}</TableCell>


</TableRow>
)}

</TableBody>
</Table> */}
                        </fieldset>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Ledgerview);
