import axios from 'axios'
import { Auth_Proto, Auth_Domain, Auth_Port } from '../../Component/consts'

export let currentTenant

class GRNService {

  // Get Active Data of All GRN 
  retriveAllPendingGRN(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpendinggrnoftenant`,data)
  }

  retriveAllActiveGRN(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getactivegrnoftenant`,data)
  }

  retriveAllCompletedGRN(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getcompletedgrnoftenant`,data)
  }
  //Get Completed data of  GRN Items 
  retriveGRNItems(id) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getitemsofgrnid/${id}`)
  }

//Get TempGRN Items 
  retriveTempGRNItems(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/gettempitemsofgrnid/${id}`)
  }

  //Get All Sellers
  getAllSellers(currentTenant) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getallsellers/${currentTenant}`)
  }

  getPorderofSellerid(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpoofsellerid`,data)
  }

  getpoofsellerforgrn(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpoofsellerforgrn`,data)
  }

  getDataofpoid(id) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpurchaseorder/${id}`)
  }

  getpendingDataofPOid(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpendingpo/${id}`)
  }

  getpendingDataofPObyGrnid(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpendingpobygrnid/${id}`)
  }

  NewGRN(datalist) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/addtempgrn`, datalist)
  }

  UpdateGRN(data){
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/updategrn`, data)
  }

  GetGRNbyId(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getgrn/${id}`)
  }

  GetTempGRNbyId(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/gettempgrn/${id}`)
  }

  Status(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/grnstatus`, data)
  }

  DeleteGRN(id){
    return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/deletegrn/${id}`)
  }

  PurchaseAutoJRE(id){
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/purchaseautojrereq/${id}`,)
  }

}
export default new GRNService();