import React, { Component } from 'react'
import { Formik, Form, FieldArray } from "formik";
import {FormikTextField} from '../CommonComponents/FormField.js'
import {vname} from './Vouchers'
import {Autocomplete,TextField} from '@mui/material';
import { Spinner } from 'react-bootstrap';


class VoucherForms extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             firstcount:[{"id":1,"name":"debit"}],
             secondcount:[{"id":1,"name":"credit"},{"id":2,"name":"credit"}],
             personal:[{"id":1,"name":"personal"},{"id":2,"name":"personal2"}],
             real:[{"id":1,"name":"Real1"},{"id":2,"name":"Real2"}],
             loader:false
             
        }

    }

    onChange(e){
      // console.log(e.value)
    }

    onSubmit(values){
      this.setState({loader:true})
      // console.log(values)

      // let a
      // let b=0
      // a=values.second.map((index,value)=> index.value===b)
      // a= (id) =>{
      //   return values.values.secondcount.find(value => value.id === id)
      // }

      // a=values.values.secondcount.map(value => value.name)

    //  b= a.map(((val,i)=>val).indexOf(0))

      // console.log(values.id)
      // b=a(1)
      // console.log(b.name)
      // console.log(values.second)
      // console.log(a)


    }

    componentDidMount(){
this.setState({vname:vname})
    }

    render() {
        let {firstcount,personal,real,secondcount} = this.state
        return (
            <div>
        
    <Formik
      initialValues={{firstcount ,personal,secondcount,real}}
      onSubmit={this.onSubmit}

      //   onSubmit={values =>
      //   setTimeout(() => {
      //     alert(JSON.stringify(values, null, 2));
      //   }, 500)
      // }
>
{({ values,setFieldValue,insert }) => (

        <Form>

<FieldArray
            name="debit"
            render={arrayHelpers => (
                
                <>
                  {values.firstcount && values.firstcount.length > 0  ?
                  values.firstcount.map((first, index) =>(
                  <div key={index} >
                    <div className="form-row"> 
                    <fieldset  className="form-group col-md-5"> 
                    <Autocomplete
                      id={first.name}
                      className="debit-select"
                      name={first.id}
                      options={personal}
                      getOptionLabel={(option) => option.name}
                      
                      
                      value={values.firstcount.map(val => val.name)?undefined:undefined}
                      onChange={(e, value) => {
                       setFieldValue((`first[${index}].name`),value);
                        }}
                      renderInput={params => (
                        <FormikTextField
                          {...params}
                        // className="form-control"
                        type="text"
                        name={`first[${index}].name`}
                        // style={{ height }}
                        label="Debit"
                        variant="standard"
                        //onClick={arrayHelpers.}
                        fullWidth
                      />
                    )}
                  /></fieldset>

                <fieldset  className="form-group col-md-4">   
                  <FormikTextField 
                  name={`first[${index}].value`}//{`friends.${index}`}
                  className="form-control"
                  label="Value"
                  // onChange={(e)=>this.onChange(e)}
                  variant="standard"
                  /></fieldset>

                {/* <fieldset  className="form-group col-md-1">    */}
                {/* <button
                type="button"
                onClick={() => arrayHelpers.insert(index, "")} // insert an empty string at a position
                >
                +
                </button> */}
                {/* </fieldset> */}
                  
                  
                  </div>
                  
                </div>
                  )):""}
                </>
        )}  
          />
               
         

        <FieldArray
            name="credit"
            render={arrayHelpers => (
                
                <>
                  {values.secondcount && values.secondcount.length > 0  ?
                  values.secondcount.map((second, index) =>(
                  <div key={index} >
                    <div className="form-row"> 
                    <fieldset  className="form-group col-md-2"> 
                    </fieldset>
                    <fieldset  className="form-group col-md-5"> 
                    <Autocomplete
                      id={second.name}
                      className="credit-select"
                      name={second.id}
                      options={real}
                      getOptionLabel={(option) => option.name}
                      
                      
                      value={values.secondcount.map(val => val.name)?undefined:undefined}
                      onChange={(e, value) => {
                       setFieldValue((`second[${index}].name`),value);
                        }}
                      renderInput={params => (
                        <FormikTextField
                          {...params}
                        // className="form-control"
                        type="text"
                        name={`second[${index}].name`}
                        // style={{ height }}
                        label="Credit"
                        variant="standard"
                        fullWidth
                      />
                    )}
                  /></fieldset>

                <fieldset  className="form-group col-md-4">   
                  <FormikTextField 
                  name={`second[${index}].value`}//{`friends.${index}`}
                  className="form-control"
                  label="Value"
                  // onChange={(e)=>this.onChange(e)}
                  variant="standard"
                  /></fieldset>
                  {/* <button
                type="button"
                onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                >
                +
                </button> */}
                
                </div>
                  
                  
                </div>
                
                  )
                  ):""}
                   
                </>
        )}  
          />
                  <div className="form-row">
                  <fieldset  className="form-group col-md-8">   
                  <FormikTextField 
                  name="textarea"//{`friends.${index}`}
                  className="form-control"
                  type="text-area"
                  label="Narration"
                  variant="filled"
                  /></fieldset>

                  <fieldset className="form-group col-md-12">
                  <button className="btn btn-info btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button>
                  </fieldset></div>
                 

        </Form>

          )}
        
    </Formik>
    </div>
         
        )
    }
}

export default VoucherForms;              