import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  clientname,
  currentTenant,
  dataaccess,
  financialyearend,
  financialyearstart,
  logo,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
// import moment from "moment";
import SalesServices from "../SalesServices";
import { spin } from "../../Common/NewLoader";
import { salesid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import IOSSwitch from "../../CommonComponents/IOSSwitch";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import UsercustomerService from "../../Masters/Usercustomers/UsercustomerService";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class SaleOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saleOrder: [],
      loading: false,
      salestype: false,
      service: [],
      completedso: [],
      cancelledso: [],
      so: true,
      comp: false,
      canl: false,
      pendingsoitems: [],
      completedsoitems: [],
      pendingbyitem: true,
      compbyitem: false,
      usercustomers: [],
      type: "Sales By Order",
      fromdate: moment(new Date()).subtract(7, "days"),
      todate: new Date(),
    };
  }

  handlesoChange = () => {
    this.setState({ so: true, comp: false, canl: false });
  };
  handlecompChange = () => {
    this.setState({ comp: true, so: false, canl: false });
  };
  handlecanlChange = () => {
    this.setState({ canl: true, so: false, comp: false });
  };
  handlePendingChangeByitem = () => {
    this.setState({ pendingbyitem: true, compbyitem: false });
  };
  handlecompChangeByitem = () => {
    this.setState({ pendingbyitem: false, compbyitem: true });
  };
  refreshSalesorder() {
    let datadao = {
      tenantid: currentTenant,
      startdate: moment(new Date()).subtract(7, "days"),
      enddate: new Date(),
      userid: userid,
      dataaccess: dataaccess,
    };

    SalesServices.GetSaleOrder(datadao)
      .then((response) => {
        this.setState({
          saleOrder: response.data
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            .filter((e) =>
              this.state.usercustomers.length === 0
                ? e
                : this.state.usercustomers.includes(e.customer)
            )
            .map((e) =>
              Object.assign(e, {
                month: moment(e.createdAt).format("MMMM YYYY"),
              })
            ),
        });
        this.setState({ loading: false });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    SalesServices.getSalesbyItem(datadao).then((res) => {
      this.setState({
        pendingsoitems: res.data
          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          .filter((e) =>
            this.state.usercustomers.length === 0
              ? e.delivered + e.preclose + e.returned !== e.quantity
              : this.state.usercustomers.includes(e.customer) &&
                e.delivered + e.preclose + e.returned !== e.quantity
          ),
        completedsoitems: res.data
          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          .filter(
            (e) =>
              (this.state.usercustomers.length === 0
                ? e.delivered + e.preclose + e.returned === e.quantity
                : this.state.usercustomers.includes(e.customer)) &&
              e.delivered + e.preclose + e.returned === e.quantity
          ),
      });
    });
  }

  refreshcompletdorcancelledSO(from, to) {
    let datadao = {
      tenantid: currentTenant,
      startdate: from,
      enddate: to,
      userid: userid,
      dataaccess: dataaccess,
    };
    SalesServices.GetCompletedSo(datadao).then((response) => {
      this.setState({
        completedso: response.data
          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          .filter((e) =>
            this.state.usercustomers.length === 0
              ? e
              : this.state.usercustomers.includes(e.customer)
          )
          .map((e) =>
            Object.assign(e, { month: moment(e.createdAt).format("MMMM YYYY") })
          ),
      });
    });

    SalesServices.GetCancelledSo(datadao).then((response) => {
      this.setState({
        cancelledso: response.data
          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          .filter((e) =>
            this.state.usercustomers.length === 0
              ? e
              : this.state.usercustomers.includes(e.customer)
          ),
      });
    });
  }

  handleFromDateChange = (date) => {
    this.setState({
      fromdate: date,
      todate:
        moment(date).diff(this.state.todate, "days") >= 0
          ? this.state.todate
          : date,
    });
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  handlecompletedDateChange = () => {
    this.refreshcompletdorcancelledSO(this.state.fromdate, this.state.todate);
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });

    UsercustomerService.GetUserCustomersbyUser(userid).then((res) =>
      this.setState({
        usercustomers:
          res.data === "" || res.data === undefined
            ? []
            : res.data.customers.split(","),
      })
    );
    this.refreshSalesorder();
    this.refreshcompletdorcancelledSO(
      moment(new Date()).subtract(7, "days"),
      new Date()
    );
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Saleorder Id",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "customsoid",
        label: "SO Id",
        options: {
          filter: false,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      // {name: "customcustomerid",label:"Customer Id",options: {filter: true,sort: true,}},
      {
        name: "customer",
        label: "Customer",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              <>
                {value === undefined || value === null || value === ""
                  ? "Direct"
                  : value}
              </>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return moment(value).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      },
      {
        name: "sotype",
        label: "Type",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "createdbyname",
        label: "Created By",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "grandtotal",
        label: "Total Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "createdAt",
        label: "Aging(in Days)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            let cre = moment(value, "YYYY-MM-DD");
            let curr = moment(new Date(), "YYYY-MM-DD");
            return <>{curr.diff(cre, "days")} Days</>;
          },
        },
      },
      {
        name: "month",
        label: "Month",
        options: { filter: true, sort: true, display: false },
      },
      {
        name: "View",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="fa fa-list-alt"
                  to={{ pathname: "/saleorderdetails" }}
                  state={{ id: tableMeta.rowData.find((e) => e), bvstatus: 0 }}
                ></Link>
              </>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      rowsPerPageOptions:[10,25,50,100],
      customToolbar: () => {
        return (
          <>
            {salesid === "2" || salesid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{ pathname: "/newsalesorder" }}
                state={{ id: -1, pagename: "New Order", salestype: false,orderfrom:0,qid:-1 }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      downloadOptions: {
        filename: "Sale Order",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
      setRowProps: (row) => {
        if (row[4].substr(0, 10) === moment().format("DD-MM-YYYY")) {
          return {
            style: { background: "#ecf8fb" },
          };
        }
        if (row[6] === "Pending") {
          return {
            style: { background: "#f7dddd" },
          };
        }
      },
    };

    const columns1 = [
      {
        name: "id",
        label: "Saleord Id",
        options: { filter: false, sort: true, display: false },
      },
      {
        name: "customsoid",
        label: "SO Id",
        options: {
          filter: false,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      // {name: "customcustomerid",label:"Customer Id",options: {filter: true,sort: true,}},
      {
        name: "customer",
        label: "Customer",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              <>
                {value === undefined || value === null || value === ""
                  ? "Direct"
                  : value}
              </>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "grandtotal",
        label: "Total Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "createdbyname",
        label: "Created By",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      },
      {
        name: "status",
        label: "Status",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "month",
        label: "Month",
        options: {
          filter: this.state.comp,
          sort: this.state.comp,
          display: false,
        },
      },
      {
        name: "View",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="fa fa-list-alt"
                  to={{ pathname: "/saleorderdetails" }}
                  state={{ id: tableMeta.rowData.find((e) => e), bvstatus: 1 }}
                ></Link>
                {/* //<Tooltip id="view details" place="top" effect="solid">View</Tooltip> */}
              </>
            );
          },
        },
      },
    ];
    const options1 = {
      filterType: "checkbox",
      selectableRows: "none",
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      downloadOptions: {
        filename: "Sale Orders",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
    };

    const columnsbyitem1 = [
      {
        name: "customsoid",
        label: "SO Id",
        options: {
          filter: false,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "customer",
        label: "Customer",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              <>
                {value === undefined || value === null || value === ""
                  ? "Direct"
                  : value}
              </>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "createdbyname",
        label: "Created By",
        options: { filter: true, sort: true },
      },
      {
        name: "itemname",
        label: "Itemname",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "price",
        label: "Price",
        options: {
          filter: false,
          sort: true,
          start: "right",
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "uom",
        label: "UOM",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "quantity",
        label: "Total Quantity",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "pending",
        label: "Pending Quantity",
        options: {
          filter: false,
          sort: true,
          start: "right",
          customBodyRender: (value, tableMeta) => {
            return (
              <span>{tableMeta.rowData[16] === "Pending" ? 0 : value}</span>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "sold",
        label: "Sent Quantity",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "delivered",
        label: "Delivered Quantity",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "preclose",
        label: "PreClose Quantity",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "returned",
        label: "Returned Quantity",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "tax",
        label: "Tax (%)",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "totalprice",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          start: "right",
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total (Inc. Tax)",
        options: {
          filter: false,
          sort: true,
          start: "right",
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "status",
        label: "Order Status",
        options: {
          filter: true,
          sort: true,
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      },
    ];
    const optionsbyitem1 = {
      filterType: "checkbox",
      selectableRows: "none",

      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      downloadOptions: {
        filename: "Sale order items",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
    };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>

              <li className="breadcrumb-item active">Sales Order</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      {/* <div className="card-header">
                          <h3 className="card-title">Sales Order</h3>
                        </div> */}

                      <div className="card-body">
                        {this.state.loading === true ? (
                          <span className="text-center">{spin}</span>
                        ) : (
                          <>
                            <div className="form-row">
                              <fieldset className="form-group col-md-12">
                                <fieldset
                                  className={`form-group col-md-3 shadow-sm  text-center btn ${
                                    this.state.type === "Sales By Order"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={
                                    this.state.type === "Sales By Order"
                                  }
                                  onClick={() =>
                                    this.setState({ type: "Sales By Order" })
                                  }
                                >
                                  Sales By Order
                                </fieldset>
                                <fieldset
                                  className={`form-group col-md-3  btn shadow-sm text-center ${
                                    this.state.type === "Sales By Item"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === "Sales By Item"}
                                  onClick={() =>
                                    this.setState({ type: "Sales By Item" })
                                  }
                                >
                                  Sales By Item
                                </fieldset>
                              </fieldset>
                              {this.state.type === "Sales By Order" ? (
                                <>
                                  <fieldset className="form-group col-md-4">
                                    <span className="mx-3">Active Orders</span>
                                    <FormControlLabel
                                      control={
                                        <IOSSwitch
                                          className="sm"
                                          checked={this.state.so}
                                          onChange={this.handlesoChange}
                                          name="checkedB"
                                        />
                                      }
                                    />
                                  </fieldset>
                                  <fieldset className="form-group col-md-4">
                                    <span className="mx-3">
                                      Completed Orders
                                    </span>
                                    <FormControlLabel
                                      control={
                                        <IOSSwitch
                                          className="sm"
                                          checked={this.state.comp}
                                          onChange={this.handlecompChange}
                                          name="checkedB"
                                        />
                                      }
                                    />
                                  </fieldset>
                                  <fieldset className="form-group col-md-4">
                                    <span className="mx-3">
                                      Cancelled Orders
                                    </span>
                                    <FormControlLabel
                                      control={
                                        <IOSSwitch
                                          className="sm"
                                          checked={this.state.canl}
                                          onChange={this.handlecanlChange}
                                          name="checkedB"
                                        />
                                      }
                                    />
                                  </fieldset>
                                </>
                              ) : (
                                <>
                                  <fieldset className="form-group col-md-4">
                                    <span className="mx-3">Active/Pending</span>
                                    <FormControlLabel
                                      control={
                                        <IOSSwitch
                                          className="sm"
                                          checked={this.state.pendingbyitem}
                                          onChange={
                                            this.handlePendingChangeByitem
                                          }
                                          name="checkedB"
                                        />
                                      }
                                    />
                                  </fieldset>
                                  <fieldset className="form-group col-md-4">
                                    <span className="mx-3">Completed</span>
                                    <FormControlLabel
                                      control={
                                        <IOSSwitch
                                          className="sm"
                                          checked={this.state.compbyitem}
                                          onChange={this.handlecompChangeByitem}
                                          name="checkedB"
                                        />
                                      }
                                    />
                                  </fieldset>
                                </>
                              )}
                            </div>
                            {this.state.so === true &&
                            this.state.type === "Sales By Order" ? (
                              <ThemeProvider theme={theme}>
                                <MUIDataTable
                                  className="table-sm px-3"
                                  title={"Active Order"}
                                  data={this.state.saleOrder}
                                  columns={columns}
                                  options={options}
                                />
                              </ThemeProvider>
                            ) : (
                              ""
                            )}
                            {(this.state.comp === true ||
                              this.state.canl === true) &&
                            this.state.type === "Sales By Order" ? (
                              <div className="form-row g-0 mb-3">
                                <fieldset>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-around"
                                    >
                                      <DatePicker
                                        variant="inline"
                                        id="date-picker-dialog"
                                        label="From Date"
                                        name="from"
                                        format="dd/MM/yyyy"
                                        maxDate={new Date()}
                                        value={this.state.fromdate}
                                        onChange={this.handleFromDateChange}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                          />
                                        )}
                                        autoOk={true}
                                      />
                                    </Grid>
                                  </LocalizationProvider>
                                </fieldset>

                                <fieldset style={{ marginLeft: "30px" }}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-around"
                                    >
                                      <DatePicker
                                        variant="inline"
                                        id="date-picker-dialog"
                                        label="To Date"
                                        name="to"
                                        format="dd/MM/yyyy"
                                        minDate={this.state.fromdate}
                                        value={this.state.todate}
                                        onChange={this.handleToDateChange}
                                        maxDate={new Date()}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                          />
                                        )}
                                        autoOk={true}
                                      />
                                    </Grid>
                                  </LocalizationProvider>
                                </fieldset>
                                <fieldset className="form-group col-md-1">
                                  <button
                                    className="btn btn-sm hovbuttonColour mt-3"
                                    onClick={this.handlecompletedDateChange}
                                  >
                                    <i className="fas fa-filter" />
                                  </button>
                                </fieldset>
                              </div>
                            ) : (
                              ""
                            )}

                            {this.state.comp === true &&
                            this.state.type === "Sales By Order" ? (
                              <>
                                <MUIDataTable
                                  className="table-sm px-3"
                                  title={"Completed Orders"}
                                  data={this.state.completedso}
                                  columns={columns1}
                                  options={options1}
                                />
                              </>
                            ) : (
                              ""
                            )}

                            {this.state.canl === true &&
                            this.state.type === "Sales By Order" ? (
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Cancelled Orders"}
                                data={this.state.cancelledso}
                                columns={columns1}
                                options={options1}
                              />
                            ) : (
                              ""
                            )}

                            {this.state.pendingbyitem === true &&
                            this.state.type === "Sales By Item" ? (
                              //  <ThemeProvider theme={this.getMuiTheme()}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Pending/Active"}
                                data={this.state.pendingsoitems}
                                columns={columnsbyitem1}
                                options={optionsbyitem1}
                              />
                            ) : (
                              // </ThemeProvider>
                              ""
                            )}

                            {this.state.compbyitem === true &&
                            this.state.type === "Sales By Item" ? (
                              //  <ThemeProvider theme={this.getMuiTheme()}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Completed"}
                                data={this.state.completedsoitems}
                                columns={columnsbyitem1}
                                options={optionsbyitem1}
                              />
                            ) : (
                              // </ThemeProvider>
                              ""
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(SaleOrder);
