import React, { Component } from "react";
import {Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { cstate, currentTenant, currentUser, taxtype, thousandsdisplay, userid,financialyearend,financialyearstart, transactionFrom} from '../../Common/Credentials';
import { Spinner } from 'react-bootstrap';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import { spin } from '../../Common/NewLoader';
import Footer from '../../Component/Footer';
import { Table, TableBody, TableCell, TableHead, TableRow, TextField,Grid,FormControlLabel, Backdrop, CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { FormikTextField } from "../../CommonComponents/FormField";
import { Form, Formik } from "formik";
import NewJournalService from "../NewJournalService";
import AuthenticationService from "../../Authentication/AuthenticationService";
import moment from 'moment';
import IOSSwitch from "../../CommonComponents/IOSSwitch.js";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import withRouter  from "../../withRouter.js";

// function DirectStockEntry(){
  let jvtype = 0; 
  let entrytype=12; //for direct stock

  class DirectStockEntry extends Component {

    constructor(props) {
      super(props)
  
  
      this.state = {
        selstock:[],SDdate:false,fed:'',
        loader:false,loader1:true,
        debvalue:[],credvalue:[],gsttypeloc:'',totalcombdata:[],
        tax:[],taxsecond:[],debselval:'',credselval:'',
        partyid:'',suppid:'',batchid:'',taxpercent:'',suppstate:'',selamount:'',seltaxamount:"",supname:"",whid:"",
        jvinsertiontype:13,

      }
      this.onSubmit = this.onSubmit.bind(this);
      this.validate = this.validate.bind(this);
      this.goBack=this.goBack.bind(this);
      this.addData=this.addData.bind(this);
      //this.onCheckClick=this.onCheckClick.bind(this);
    }


  validate(values){
    let errors = {};
    let deb = values.debtotal;
    let cre = values.creditvalue;

    if (!values.debit || values.debit === "") {
      errors.debit = "Accounts is required";
    }
    if (!values.debitval) {
      errors.debitval = "Value Cannot be Blank";
    } else if (deb !== cre) {
      errors.creditvalue = "Value Cannot be be different from debit Value";
    }
   
            if (taxtype === "GST" && this.state.gsttypeloc===0) {
              if (!values.sgst) {
                errors.sgst = "Tax Cannot be Blank";
              }

              if (!values.cgst) {
                errors.cgst = "Tax Cannot be Blank";
              }
              if (values.sgst === values.cgst) {
                errors.cgst = "Tax Cannot be Same";
                errors.sgst = "Tax Cannot be Same";
              }
      if (!values.taxpercent1) {
        errors.taxpercent1 = "Tax percent Required";
      }
    } else if (taxtype === "GST"  && this.state.gsttypeloc!==0 ) {
      if (!values.igst) {
        errors.igst = "Tax Cannot be Blank";
      }
      if (this.state.invchecked===2 && !values.itaxpercent1) {
        errors.itaxpercent1 = "Tax percent Required";
      }
    } else if (taxtype !== "GST" ) {
      if (!values.tax) {
        errors.tax = "Tax Cannot be Blank";
      }
      if (taxtype !== "GST") {
        errors.gtaxpercent1 = "Tax percent Required";
      }
    }

    if (!values.credit || values.credit === "") {
      errors.credit = "Accounts is required";
    }
    if (!values.creditvalue) {
      errors.creditvalue = "Value Cannot be Blank";
    } else if (deb !== cre) {
      errors.creditvalue = "Value Cannot be be different from debit Value";
    }
    if (!values.invitem) {
      errors.invitem = "Invoice item is required";
    }
    if (!values.narration) {
      errors.narration = "Narration is required";
    }

    return errors;
  };

  onSubmit(values){
    let gst, gstpercent, taxval1;
    let cgst, cgstpercent, taxval2;
    let igst, igstpercent, itaxval;
    let vat, vatpercent, vatval //,tax
    let invitemled,invitemid,invitemname


    if (values.invitem!==undefined) {
      invitemled=values.invitem.id;
      invitemid=values.invitem.itemid;
      invitemname=values.invitem.itemname
    }

    if (values.taxvalue1 !== undefined) {
      gst = values.sgst; gstpercent = values.taxpercent1;taxval1 = values.taxvalue1;
    }
    if (values.taxvalue2 !== undefined) {
      cgst = values.cgst;cgstpercent = values.taxpercent2; taxval2 = values.taxvalue2;
    }
    if (values.itaxvalue1 !== undefined) {
      igst = values.igst;igstpercent = values.itaxpercent1;itaxval = values.itaxvalue1;
    }
    if (values.gtaxvalue1 !== undefined) {
      vat = values.tax;vatpercent = values.gtaxpercent1;vatval = values.gtaxvalue1;
    }

    // if (taxtype === "GST" && this.state.gsttypeloc===0) {
    //   tax = values.taxvalue1 * 2;
    // } else if (taxtype === "GST"  && this.state.gsttypeloc!==0) {
    //   tax = values.itaxvalue1;
    // } else {
    //   tax = values.gtaxvalue1;
    // }
    let jv 
    

    jv= {
      debitid: values.debit.id,debitname: values.debit.name,debitamt: values.debitval,
      gst: gst,gstpercent: gstpercent,taxval1: taxval1,cgst: cgst,cgstpercent: cgstpercent,
      taxval2: taxval2,igst: igst,igstpercent: igstpercent,itaxval: itaxval,vat: vat,
      vatpercent: vatpercent,vatval: vatval,creditid: values.credit.id,creditname: values.credit.name,
      creditamt: values.creditvalue,gstin: values.credit.gstin,pan: values.credit.pan,
      contactnumber: values.credit.contactnumber,email: values.credit.email,narration: values.narration,whid:this.state.whid,expense:values.debit.id,
      jvtype: jvtype,createdBy:userid,tenantId: currentTenant,entrytype: entrytype,supplier:this.state.supname,supplierid:this.state.suppid,
      dirstock:this.state.selstock,purchasetype:this.state.invchecked,invitemled:invitemled,jvinsertiontype:this.state.jvinsertiontype,
      invitemid:invitemid,invitemname:invitemname,date:this.state.fed!==''?this.state.fed:null,invbatchid:this.state.selstock[0].id
    };

    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      AuthenticationService.logout(currentUser);

      //this.props.navigate(`/`);
    } else {
        this.props.navigate({pathname:`/journalconfirm`}, {state:jv},()=>this.setState({loader:false}));
    }
  }
 
  onDebBlur(values){
    this.setState({debselval:values.debit})
  }
  goBack(){
    this.props.navigate(-1);
  }

  addData(){
    this.state.selstock.map((e)=>{return this.setState({suppid:e.supplierid,suppstate:e.supstate,
      batchid:e.id,taxpercent:e.tax,selamount:e.totalamount,seltaxamount:e.totalamount*e.tax/100,supname:e.supplier,
      whid:e.whid},()=>this.addType())})
    
  }

  handleSDDate=()=>{
    this.setState({SDdate:this.state.SDdate===true?false:true})
  }
  handleFsdDateChange = (date) => {
    if(date!==null){
    this.setState({ fsd: date });
    this.setState({
      fed: moment(date).format("yyyy-MM-DD HH:mm:ss"),
    });
  }else{
    this.setState({ SDdate:false })
  }
  };


 addType(){
  if(this.state.suppstate===cstate){
    this.setState({gsttypeloc:0},()=>this.addTax())
  }else{
    this.setState({gsttypeloc:1},()=>this.addTax())
  }

 
 }

 addTax(){

  if(this.state.gsttypeloc===0){
    this.setState({tax:this.state.totalcombdata.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype===0) & (e.indirectsubtaxtype===0) )})
    this.setState({loader1:false})
  }else if(this.state.gsttypeloc===1){
    this.setState({tax:this.state.totalcombdata.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype===0) & (e.indirectsubtaxtype===2))})
    this.setState({loader1:false})
  }else if(this.state.gsttypeloc===undefined){
      this.setState({tax:this.state.totalcombdata.filter((e) => e.taxtype === 2 & (e.indirecttaxtype===1))})
      this.setState({loader1:false})
 }

 if(this.state.suppid!==null){
 this.setState({credvalue:this.state.totalcombdata.filter((e) => e.pid===this.state.suppid)})
 this.setState({loader1:false})

 }else{
  this.setState({credvalue:this.state.totalcombdata.filter((e) => e.rule === 0 && e.subheadid==='AH000004' && e.partyledtype===0)})
  this.setState({loader1:false})
 }
  this.setState({taxsecond:this.state.totalcombdata.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype===0) & (e.indirectsubtaxtype===1) )})

  this.setState({loader1:false})

 }

  onCreditChange(values){
    if(values.credit!==undefined && values.credit.length!==0){
      // alert(values.credit.id)
      this.setState({credselval:values.credit})
      this.setState({partyid:values.credit.id})
    }
  }

  componentDidMount(){
    this.setState({selstock:this.props.location.state})
    NewJournalService.getAllLedgers(currentTenant)
      .then(response=>{
        this.setState({
          totalcombdata:response.data
        },()=>this.setState({debvalue:this.state.totalcombdata.filter((e) =>e.type===4 && e.th==="TH2" )}// && e.subheadid==="AH000010"
        ,()=>this.addData()))
      })
      
  }

render(){ 
  let invitemdata 
  if(this.state.totalcombdata!==undefined){
    invitemdata = this.state.totalcombdata.filter(
    (e) => (e.itemid !== null) & (e.tenantId === currentTenant)
  );
  }
  return (

<div>
      <Header />
      <Menu />
      <div className="content-wrapper">
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
<div className="card card-info">

  <ol className="breadcrumb float-sm-right">
  <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={-1}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/dirstock'}} state= {{message:''}} >
                Direct Stock
              </Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/dirstockentry'}} state= {{message:''}} >
    Direct Stock Entry
              </Link>
    </li>

  </ol>

  <div className="card-body">
  <div className="text-center">
  {this.state.loader1===true?spin:''}
  </div>    

         
  <Formik
      initialValues={{debit:"",debitval:"",credit:"",creditvalue:"",taxpercent1:'',taxpercent2:'',gtaxpercent1:'',gtaxvalue1:''
     , taxvalue1:'',taxvalue2:'',itaxpercent1:'',itaxpercent2:'',itaxvalue1:'',itaxvalue2:''}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({ values,setFieldValue}) => (

        <Form autoComplete="off">

<div>
<Table className="table-sm table-equal table-bordered">
  <TableHead><TableRow>
      <TableCell className="col-md-2 text-sm">Supplier</TableCell>
      <TableCell className="col-md-1 text-sm">Item</TableCell>
      <TableCell className="col-md-1 text-sm">Branch</TableCell>
      <TableCell className="col-md-1 text-sm">Quantity</TableCell>
      <TableCell className="col-md-1 text-sm">Unit Price</TableCell>
      <TableCell className="col-md-1 text-sm text-center">Amount</TableCell>
      <TableCell className="col-md-1 text-sm">Tax</TableCell>
      <TableCell className="col-md-1 text-sm text-center">Total Tax</TableCell>
      <TableCell className="col-md-1 text-sm text-center">Total Amount</TableCell>

    </TableRow></TableHead>
    <TableBody>
    {this.state.selstock.map((result)=>(
      <TableRow key={result.id}>
      <TableCell  className="col-md-2 text-sm">{result.supplier}</TableCell>
      <TableCell  className="col-md-2 text-sm">{result.itemname}</TableCell>
      <TableCell  className="col-md-1 text-sm">{result.warehouse  }</TableCell>
      <TableCell  className="col-md-1 text-sm">{result.quantity}</TableCell>
      <TableCell className="col-md-1 text-sm text-right">
      <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {result.price} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>
      <TableCell className="col-md-1 text-sm text-right">
      <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {result.totalamount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>
      <TableCell  className="text-sm">{result.tax}%</TableCell>
      <TableCell className="col-md-1 text-sm text-right">
      <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {result.totalamount*result.tax/100} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>
      
      <TableCell className="col-md-1 text-sm text-right">
      <NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {result.totalamount+(result.totalamount*result.tax/100)} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>
      </TableRow>
       ))}
    </TableBody>
</Table>

</div>


<div className="form-row mt-0 mb-0">

<div className="form-row col-md-12 text-left form-switch mt-3">
 <div className="col-md-3 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.SDdate} onChange={this.handleSDDate} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.SDdate===false?"System Date":"Custom Date"}</span>
       </div>
    {this.state.SDdate===true?
     <div className="col-md-3 text-left">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid  justifyContent="space-around">
    <DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
    id="date-picker-dialog" inputVariant="standard"
    minDate={new Date(moment(transactionFrom).format("yyyy-MM-DD"))}
    maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
    format="dd/MM/yyyy HH:mm" value={this.state.fsd}
    // label="Custom Date" 
    InputAdornmentProps={{position: "start"}} onChange={this.handleFsdDateChange}
    renderInput={(params) => <TextField {...params} />}autoOk={true}/>
    </Grid></LocalizationProvider></div>
     :""}
    </div>

  <fieldset className="form-group col-md-6 mt-0 mt-0 test-sm">
<Autocomplete id="debit" className="debit-select" name="adebit" options={this.state.debvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name + " (" + option.amount + ")"}
  value={values.debit ? undefined : undefined}
 onChange={(e, value) => {setFieldValue("debit", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="debit" label="Debit" variant="standard"
 onBlur={()=>(
  setFieldValue("debitval", this.state.selamount) 
   )}
 fullWidth />)}/> </fieldset>
                
<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<FormikTextField name="debitval" className="form-control" label="Value" type="number"
 variant="standard"></FormikTextField>
 </fieldset>
 </div>


{taxtype === "GST" && this.state.gsttypeloc===0 ? (
 <> <div className="form-row mt-0 mb-0 ">
 <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<Autocomplete id="SGST" className="Tax-select" name="sgst" options={this.state.tax} getOptionLabel={(option) => option.name}
  value={values.sgst ? undefined : undefined}
onChange={(e, value) => {setFieldValue("sgst", value);}}
renderInput={(params) => (
<FormikTextField {...params} type="text" name="sgst" label="Select Tax"
onBlur={() => (
  setFieldValue("taxpercent1",this.state.taxpercent/2) &&
  this.onDebBlur(values))}variant="standard" fullWidth/>)}/></fieldset>

<fieldset className="form-group col-md-1 mt-0 mb-0 test-sm">
 <FormikTextField name="taxpercent1" className="form-control" label={values.taxpercent1 ? " " : "%"}
  type="number" onBlur={() => 
  setFieldValue("taxvalue1",(values.debitval * values.taxpercent1) / 100) &&
   setFieldValue("taxpercent2", values.taxpercent1) && setFieldValue("taxvalue2",(values.debitval * values.taxpercent1) / 100)}
   variant="standard"/>{" "}</fieldset>

 <fieldset className="form-group col-md-5 mt-0 mb-0 test-sm">
<NumericFormat name="taxvalue1" className="form-control" value={(values.debitval * values.taxpercent1) / 100}
label={values.taxpercent1 ? " " : "Value"} displayType={"input"} customInput={TextField} decimalScale={2} variant="standard"
fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
 onBlur={() => setFieldValue("creditvalue",values.taxvalue1 + values.debitval +values.taxvalue2) &&
 setFieldValue("debtotal",values.taxvalue1 + values.debitval +values.taxvalue2)} ></NumericFormat></fieldset>
 </div>
 
<div className="form-row">
<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
 <Autocomplete id="CGST" className="Tax-select" name="CGST" options={this.state.taxsecond} getOptionLabel={(option) => option.name}
  value={values.cgst ? undefined : undefined}
onChange={(e, value) => {setFieldValue("cgst", value);}}
renderInput={(params) => (
<FormikTextField {...params} type="text" name="cgst" label="Select Tax"
onBlur={() => { this.onDebBlur(values) && setFieldValue("taxpercent2", values.taxpercent1) &&
              setFieldValue("taxvalue2",(values.debitval * values.taxpercent1) / 100);}}
 variant="standard" fullWidth/>)}/></fieldset>
<fieldset className="form-group col-md-1 mt-0 mb-0 test-sm" disabled>
<FormikTextField name="taxpercent2" className="form-control" label={values.taxpercent1 ? " " : "%"}
type="number" value={values.taxpercent1}
onBlur={() => {setFieldValue("taxvalue2",(values.debitval * values.taxpercent2) / 100);}}
variant="standard"/></fieldset>


<fieldset className="form-group col-md-5 mt-0 mb-0 test-sm" disabled>
<NumericFormat name="taxvalue2" className="form-control" value={(values.debitval * values.taxpercent2) / 100}
label={values.taxpercent1 ? " " : "Value"} displayType={"input"} customInput={TextField} decimalScale={2}
fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} variant="standard"
onBlur={() =>setFieldValue( "creditvalue",values.taxvalue1 +values.debitval +values.taxvalue2) &&
             setFieldValue("debtotal",values.taxvalue1+values.debitval +values.taxvalue2)}></NumericFormat>
</fieldset>
{" "}
</div>
</>) : ("")}

{taxtype === "GST"  && this.state.gsttypeloc!==0 ? (
<div className="form-row">
<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<Autocomplete id="IGST" className="Tax-select" name="igst" options={this.state.tax} getOptionLabel={(option) => option.name}
  value={values.igst ? undefined : undefined}
onChange={(e, value) => {setFieldValue("igst", value);}}
renderInput={(params) => (
 <FormikTextField {...params} type="text" name="igst" label="Select Tax" onBlur={() => (
  setFieldValue("itaxpercent1",this.state.taxpercent) &&
  this.onDebBlur(values))}
 variant="standard" fullWidth/>)}/>
 </fieldset>

<fieldset className="form-group col-md-1 mt-0 mb-0 test-sm">
<FormikTextField name="itaxpercent1" className="form-control" label={values.itaxpercent1 ? " " : "%"}
type="number" onBlur={() =>
setFieldValue("itaxvalue1",(values.debitval * values.itaxpercent1) / 100)}
variant="standard"/></fieldset>

<fieldset className="form-group col-md-5 mt-0 mb-0 test-sm">
<NumericFormat name="itaxvalue1" className="form-control" value={(values.debitval * values.itaxpercent1) / 100}
label={values.itaxpercent1 ? " " : "Value"} displayType={"input"} customInput={TextField} decimalScale={2}
fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} variant="standard"
onBlur={() => setFieldValue("creditvalue",values.itaxvalue1 + values.debitval) &&
              setFieldValue("debtotal",values.itaxvalue1 + values.debitval)}></NumericFormat>
</fieldset>
</div>
) : ("")}

{taxtype !== "GST" ? (
<div className="form-row">
<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
<Autocomplete id="tax" className="Tax-select" name="tax" options={this.state.tax} getOptionLabel={(option) =>
option.name + " (" + option.amount + ")"} renderOption={(option) =>option.name + " (" + option.amount + ")"}
 value={values.tax ? undefined : undefined}
onChange={(e, value) => {setFieldValue("tax", value);}}renderInput={(params) => (
<FormikTextField {...params} type="text" name="tax" label="Select Vat"
onBlur={() => (
  setFieldValue("gtaxpercent1",this.state.taxpercent) &&
  this.onDebBlur(values))} variant="standard" fullWidth/>)}/></fieldset>

<fieldset className="form-group col-md-1 mt-0 mb-0 test-sm">
<FormikTextField name="gtaxpercent1" className="form-control" label={values.gtaxpercent1 ? " " : "%"}
type="number" onBlur={() =>
setFieldValue("gtaxvalue1",(values.debitval * values.gtaxpercent1) / 100)}variant="standard"/> </fieldset>

<fieldset className="form-group col-md-5 mt-0 mt-0 test-sm">
<FormikTextField name="gtaxvalue1" className="form-control" label={values.gtaxpercent1 ? " " : "Value"}
type="number" variant="standard" onBlur={() =>
setFieldValue("creditvalue",values.gtaxvalue1 + values.debitval) &&
setFieldValue("debtotal",values.gtaxvalue1 + values.debitval) }
/></fieldset></div>) : ("")}

<div className="form-row">
<fieldset className="form-group col-md-1 mt-0 mb-0 test-sm"> </fieldset>
<fieldset className="form-group col-md-5 mt-0 mb-0 test-sm">
<Autocomplete id="credit" className="credit-select" name="credit" options={this.state.credvalue}
getOptionLabel={(option) => option.name} 
 value={values.credit ? undefined : undefined}
onChange={(e, value) => {setFieldValue("credit", value);}}
renderInput={(params) => (
<FormikTextField {...params} type="text" name="credit"label="Credit" variant="standard"
onBlur={() => (
<>
{taxtype === "GST" && this.state.gsttypeloc === 0
?  setFieldValue("debtotal",values.taxvalue1+values.debitval+values.taxvalue2 ) &&
setFieldValue("creditvalue", values.taxvalue1+values.debitval+values.taxvalue2) &&
  this.onCreditChange(values): taxtype === "GST"  && this.state.gsttypeloc === 1? 
  setFieldValue("creditvalue", values.itaxvalue1 + values.debitval) &&
  setFieldValue("debtotal",values.itaxvalue1 + values.debitval):taxtype !== "GST" ? 
  setFieldValue("creditvalue",values.gtaxvalue1 + values.debitval) &&
 setFieldValue("debtotal", values.gtaxvalue1 + values.debitval):
 setFieldValue("creditvalue", values.debitval)} </> && this.onCreditChange(values)
  )} fullWidth/>)}/></fieldset>

<fieldset className="form-group col-md-6 mt-0 mb-0 test-sm" disabled>
<NumericFormat name="creditvalue" className="form-control" value={values.debtotal}
label={values.debitval ? " " : "Value"} displayType={"input"} customInput={TextField} decimalScale={2} variant="standard"
fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
</fieldset>

<fieldset className="form-group col-md-6 mt-2 mb-2 test-sm">
<Autocomplete id="invitem" className="invitem-select" name="invitem" options={invitemdata.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.subname} getOptionLabel={(option) => option.name}
 
value={values.expense ? undefined : undefined} onChange={(e, value) => {
setFieldValue("invitem", value);}}
renderInput={(params) => (
<FormikTextField {...params} type="text" name="invitem" label="Map Inventory Ledger" variant="standard" fullWidth/>)}/>
 </fieldset>

<fieldset className="form-group col-md-12 mt-3 mb-5 ">
<FormikTextField name="narration" className="form-control" multiline rows={2} label="Narration"
 variant="outlined"/></fieldset>

</div>


                  <div>
                  <div className="form-row mt-4 mb-0">
                    <fieldset className="form-group col-md-12">
                  <button className="btn hovbuttonColour mr-1 btn-sm" type="submit"><i className="fas fa-pen"/>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</>:"Save"}</button>
                  {/* </fieldset>
                  <fieldset className="form-group col-md-1">  */}
                  <button className="btn deletebutton btn-sm" type="button" onClick={this.goBack}><i className="fas fa-times"/>Close</button>
                  </fieldset>
                  </div>
                  </div>

 </Form>
 )}
</Formik>
</div>
</div>
</div>
      <Footer />
</div>

  )
  }
}
export default withRouter(DirectStockEntry)
