import {
  Box,
  Step,
  StepButton,
  Stepper,
  Typography,
  Button,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import moment from "moment";
import { Formik } from "formik";
import * as React from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
import {
  userid,
  currentTenant,
  thousandsdisplay,
  financialyearstart,
  financialyearend,
} from "../../Common/Credentials";
import ProductionServices from "../ProductionServices";
import { useLocation, useNavigate } from "react-router";
import SalesServices from "../../Sales/SalesServices";
import { Autocomplete } from "@mui/material";
import WarehouseServices from "../../Masters/Warehouse/WarehouseServices";
import WorkCenterServices from "../../Masters/WorkCenter/WorkCenterServices";
import CategoryServices from "../../Masters/Category/CategoryServices";
import InventoryService from "../../inventory/InventoryService";
import {NumericFormat} from "react-number-format";
import withRouter from "../../withRouter";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

 const NewImmediateprocess=(props)=> {
  const history = useLocation();
  const navigate = useNavigate()

  const [id] = React.useState(
    history.state === undefined ? -1 : history.state.id
  );
  const [loader,setLoader] = React.useState(false);
  const [fromstoretype, setFromstoretype] = React.useState("Production Store");
  // const [tostoretype, setTostoretype] = React.useState('Production Store');
  // const [whdata,setWhdata] = React.useState([])
  const [RawItemList, setRawItemList] = React.useState([]);
  const [filterRawmaterials, setFilterRawmaterials] = React.useState([]);
  const [steps] = React.useState([
    "Raw Materials",
    "Final/End Products",
    "Production Losses",
    "Submit",
  ]);
  const [DisplayRawitem, setDisplayRawitem] = React.useState(false);
  const [batchidlist] = React.useState([]);
  const [storeidlist] = React.useState([]);
  const [againstso, setAgainstSo] = React.useState(false);
  const [soList, setSOList] = React.useState([]);
  const [soid, setSoId] = React.useState("");
  const [whid, setWhId] = React.useState("");
  const [whList, setWhList] = React.useState([]);
  const [warehouse, setWarehouse] = React.useState("");
  const [categoryList, setCategoryList] = React.useState([]);
  const [category, setCategory] = React.useState("");
  const [catid, setCatId] = React.useState("");
  const [appxamount, setAppxAmount] = React.useState();
  // const [UOMList,setUOMList]= React.useState([]);
  const [uom, setUOM] = React.useState("");
  const [uomid, setUOMId] = React.useState("");
  // const [price,setPrice] = React.useState(0);

  const [itemsList, setItemList] = React.useState([]);
  const [filterItemList, setFilterItemList] = React.useState([]);
  const [itemid, setItemId] = React.useState("");
  const [itemname, setItemname] = React.useState("");
  // const [itemquantity, setItemquantity] = React.useState("");
  const [bomerr, setBomerr] = React.useState(false);
  const [bomitems, setBomItems] = React.useState([]);

  const [productid, setProductId] = React.useState("");
  const [productname, setProductName] = React.useState("");
  const [productquantity, setproductQuantity] = React.useState("");
  const [finalproductsList, setFinalProductsList] = React.useState([]);
  const [finalperr, setFinalperr] = React.useState(false);

  const [wcList, setWCList] = React.useState([]);
  const [workcenterid, setWorkCenterId] = React.useState("");
  const [workcenter, setWorkCenter] = React.useState("");

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState({});

  const [errormessage, setErrorMsg] = React.useState("");

  const [StoreList, setStoreList] = React.useState([]);
  const [store, setStore] = React.useState("");
  const [storeId, setStoreId] = React.useState("");
  const [tostore, setTostore] = React.useState("");
  const [tostoreid, setTostoreId] = React.useState("");
  const [toworkcenterid, setToWorkCenterId] = React.useState("");
  const [toworkcenter, setToWorkCenter] = React.useState("");
  const [towhid, setToWhId] = React.useState("");
  const [towarehouse, setToWarehouse] = React.useState("");
  const comments =  ["Damaged", "Shortage"]
  const [customdate,setCustomdate] = React.useState(false)
  const [date,setDate] = React.useState(false)

  React.useEffect(() => {
    SalesServices.GetSoIdofProducts(currentTenant).then((response) =>
      setSOList(response.data)
    );

    InventoryService.getRawMaterials(currentTenant).then((response) => {
      setRawItemList(response.data);
    });

    WarehouseServices.getWarehousesidname(currentTenant).then((response) => {
      setWhList(response.data);
      if(response.data.length === 1){
        setWarehouse(response.data[0].name)
        setWhId(response.data[0].id)
        setToWarehouse(response.data[0].name)
        setToWhId(response.data[0].id)
        WorkCenterServices.WorkCenterbywarehouse(response.data[0].id).then((res) =>{
        setWCList(res.data)
        if(res.data.length === 1){
          setWorkCenterId(res.data[0].id)
          setWorkCenter(res.data[0].name)
          setToWorkCenterId(res.data[0].id)
          setToWorkCenter(res.data[0].name)
        ProductionServices.GetallStoreNames(res.data[0].id).then((resp) =>{
        setStoreList(resp.data)
        if(resp.data.length === 1){
          setStore(resp.data[0].name)
          setStoreId(resp.data[0].id)
          setTostore(resp.data[0].name)
          setTostoreId(resp.data[0].id)
            ProductionServices.GetStoreItems(resp.data[0].id).then((response) =>
              setRawItemList(
                response.data.storeitems.filter(
                  (e) => e.quantity !== 0 && e.quantity !== null
                )
              )
            );
        }
        });
      }
      });
      }
    });

    CategoryServices.getCategory(currentTenant).then((response) =>
      setCategoryList(response.data.filter((e) => e.categorytype === "Product"))
    );
    InventoryService.retriveAllItems(currentTenant).then((response) =>
      setItemList(response.data)
    );

    // InventoryService.getItemNames(currentTenant).then((response) =>setItemList(response.data))

    //   UserService.retriveAllUsers(currentTenant).then(response=>setUserList(response.data))

    // UomServices.getUOM(currentTenant).then((response) =>
    // setUOMList(response.data)
    // );
    if (id === -1) {
      return;
    } else {
      ProductionServices.getImmediateproductionbyId(id).then((response) => {
        // setEstimationtype(response.data.estimationtype)
        setBomItems(response.data.bomitems);
        setFinalProductsList(response.data.finalproduct);
        setWorkCenter(response.data.workcenter);
        setWorkCenterId(response.data.workcenterid);
        setAgainstSo(response.data.soid === "NA" ? false : true);
        setSoId(response.data.soid);
        setAppxAmount(
          response.data.appxamount === undefined ? 0 : response.data.appxamount
        );
        setWhId(response.data.whid);
        setWarehouse(response.data.warehouse);
      });
    }
  }, [id]);

  const handleagainstso = () => {
    setAgainstSo(!againstso);
  };


 const handlecreatedAtDateChange = (date) => {
    setDate(date);
  };

 const handlecustomDate=()=>{
    setCustomdate(customdate===true?false:true)
    setDate(new Date())
  }

  /* ----------BOM Start--------- */

  const handleCategoryChange = (e, newValue) => {
    setBomerr(false);
    setFinalperr(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setCatId("");
      setCategory("");
    } else {
      setCatId(newValue.id);
      setCategory(newValue.category);

      setFilterItemList(
        activeStep === 0
          ? itemsList.filter((e) => e.catid === newValue.id && e.cost !== null)
          : itemsList.filter((e) => e.catid === newValue.id)
      );
    }
  };

  const handleSelectItem = (e, newValue) => {
    setBomerr(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setItemname("");
      setItemId("");
      // setPrice(0)
      setDisplayRawitem(false);
      setUOM("");
      setUOMId("");
      setFilterRawmaterials([]);
      setDisplayRawitem(false);
      // setItemquantity(0)
    } else {
      setItemname(newValue.name);
      setItemId(newValue.id);
      // setPrice(itemsList.find(e=>e.id === newValue.id).cost)
      setUOM(itemsList.find((e) => e.id === newValue.id).uomname);
      setUOMId(itemsList.find((e) => e.id === newValue.id).uom);
      let data;
      // if(fromstoretype==="Org Unit Stock"){
      data = RawItemList.filter((e) => e.itemid === newValue.id).map((e) =>
        Object.assign(e, { enteredquantity: 0 })
      );
      // }else{
      //   data = RawItemList.filter(e=>e.itemid === newValue.id)
      // }
      setFilterRawmaterials(data);
      setDisplayRawitem(true);
    }
  };

  const handleQuantityChange = (e, i) => {
    let q;
    let data = filterRawmaterials;
    if (e === "" || e === undefined || parseFloat(e) < 0) {
      q = 0;
    } else {
      q = parseFloat(e);
    }
    // let qa

    if (fromstoretype === "Org Unit Stock") {
      if (q > data.find((e, index) => index === i).currentStock) {
        data.find((e, index) => index === i).enteredquantity = data.find(
          (e, index) => index === i
        ).currentStock;
      } else {
        data.find((e, index) => index === i).enteredquantity = q;
      }
      // qa = data.reduce((a,b)=>a + b.enteredquantity,0)
    } else {
      if (q > data.find((e, index) => index === i).quantity) {
        data.find((e, index) => index === i).enteredquantity = data.find(
          (e, index) => index === i
        ).quantity;
      } else {
        data.find((e, index) => index === i).enteredquantity = q;
      }
      // qa = data.reduce((a,b)=>a + b.enteredquantity,0)
    }
    // setItemquantity(qa)
    setFilterRawmaterials([...data]);
  };

  const handleSaveQuantity = () => {
    if (catid === "" || itemid === "") {
      setBomerr(true);
    } else {
      let data = filterRawmaterials;
      let am = data.filter((a) => a.enteredquantity !== 0);

      // if (fromstoretype === "Org Unit Stock") {
      //   if (batchidlist.length !== 0) {
      //     let bid = batchidlist.map((a) => a.batchid);
      //     am.map((a) =>
      //       bid.includes(a.batchid)
      //         ? (batchidlist.find((b) => b.batchid === a.batchid).quantity =
      //             batchidlist.find((b) => b.batchid === a.batchid).quantity -
      //             a.enteredquantity)
      //         : batchidlist.push({
      //             batchid: a.batchid,
      //             stockid: a.id,
      //             sid: null,
      //             quantity: a.enteredquantity,
      //             tenantid: currentTenant,
      //             whid: whid,
      //             store: null,
      //             itemid: itemid,
      //           })
      //     );
      //   } else {
      //     am.map((a) =>
      //       batchidlist.push({
      //         batchid: a.batchid,
      //         stockid: a.id,
      //         sid: null,
      //         quantity: a.enteredquantity,
      //         tenantid: currentTenant,
      //         whid: whid,
      //         store: null,
      //         itemid: itemid,
      //       })
      //     );
      //   }
      // } else {
        if (storeidlist.length !== 0) {
          let sid = storeidlist.map((a) => a.id);
          am.map((a) =>
            sid.includes(a.id)
              ? (storeidlist.find((b) => b.id === a.id).quantity =
                  storeidlist.find((b) => b.id === a.id).quantity -
                  a.enteredquantity)
              : storeidlist.push({
                  batchid: null,
                  sid: a.id,
                  quantity: a.enteredquantity,
                  tenantid: currentTenant,
                  whid: whid,
                  store: storeId,
                  itemid: itemid,
                })
          );
        } else {
          am.map((a) =>
            storeidlist.push({
              batchid: null,
              sid: a.id,
              quantity: a.enteredquantity,
              tenantid: currentTenant,
              whid: whid,
              store: storeId,
              itemid: itemid,
            })
          );
        }
      // }

      am.map((a) =>{
       if(bomitems.length !== 0 &&
        bomitems.filter((e) =>a.itemid ===  e.itemid && a.price === e.price).length > 0
        ){ 
          bomitems.find(
              (e) => e.itemid === a.itemid && e.price === a.price
            ).quantity =
              bomitems.find((e) => e.itemid === a.itemid && e.price === a.price)
                .quantity + a.enteredquantity
                
              bomitems.find(
                (e) => e.itemid === a.itemid && e.price === a.price
              ).totalprice =
                bomitems.find(
                  (e) => e.itemid === a.itemid && e.price === a.price
                ).totalprice +
                a.enteredquantity * a.price

            } else{ 
              bomitems.push({
              itemid: a.itemid,
              itemname: a.itemname,
              quantity: a.enteredquantity,
              uom: uom,
              uomid: uomid,
              price: a.price,
              totalprice: a.price * a.enteredquantity,
              losses:0,
              comment:'',
              batchid:a.id,
            })
          }
  })

      // if (fromstoretype === "Org Unit Stock") {
      //   am.map(
      //     (a) =>
      //       (RawItemList.find((sl) => sl.batchid === a.batchid).currentStock =
      //         RawItemList.find((sl) => sl.batchid === a.batchid).currentStock -
      //         a.enteredquantity)
      //   );
      // } else {
        am.map(
          (a) =>
            (RawItemList.find((sl) => sl.id === a.id).quantity =
              RawItemList.find((sl) => sl.id === a.id).quantity -
              a.enteredquantity)
        );
      // }
      data.map((e) => (e.enteredquantity = 0));

      setItemname("");
      setItemId("");
      // setPrice(0)
      setUOM("");
      setUOMId("");
      setFilterRawmaterials([]);
      setDisplayRawitem(false);
    }
  };

  // const handleItemQuantity = (e) => {
  //   fromstoretype==="Org Unit Stock"?setDisplayRawitem(true): setItemquantity(e.target.value);
  // };

  // const handleAddBOMItem = () => {
  //   if (itemname === "" && itemquantity === "" && itemquantity === "0" && itemquantity === 0 && uom ===""){
  //   }
  //   else{
  //     let data = filterRawmaterials
  //   let am = data.filter(a=>a.enteredquantity !== 0 )

  //     am.map(a=>
  //     bomitems.push({
  //       itemid: a.itemid,
  //       itemname: a.itemname,
  //       quantity: a.enteredquantity,
  //       uom:uom,
  //       uomid:uomid,
  //       price:a.price,
  //       totalprice:a.price*a.enteredquantity,
  //     })
  // )

  //   if(fromstoretype === "Org Unit Stock"){
  //     am.map(a=>
  //       RawItemList.find(sl=>sl.batchid===a.batchid).currentStock =
  //       RawItemList.find(sl=>sl.batchid===a.batchid).currentStock-a.enteredquantity
  //     )
  //   }else{
  //     am.map(a=>
  //       RawItemList.find(sl=>sl.id===a.id).quantity =
  //       RawItemList.find(sl=>sl.id===a.id).quantity-a.enteredquantity
  //     )
  //   }
  //       data.map(e=>e.enteredquantity = 0)

  //     setItemquantity("")
  //     setItemname("");
  //     setItemId("");
  //     // setPrice(0)
  //     setDisplayRawitem(false);
  //     setUOM('')
  //     setUOMId('')
  //     setFilterRawmaterials([])
  //     setDisplayRawitem(false)

  //   }
  // };

  // const handelRemoveItem = (value) => {
  //   if (value !== "") {
  //     setBomItems(bomitems.filter((e, index) => index !== value));
  //     setAppxAmount(appxamount - bomitems.find((e, index) => index === value).totalprice)
  //   }
  // };

  /* ----------BOM End--------- */

  /* ----------Fianl Products Start--------- */

  const handleSelectProduct = (e, newValue) => {
    setFinalperr(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setProductName("");
      setProductId("");
    } else {
      setProductName(newValue.name);
      setProductId(newValue.id);
      setUOM(newValue.uomname);
      setUOMId(newValue.uomid);
    }
  };

  const handleFinalProductsQuantity = (e) => {
    setproductQuantity(e.target.value);
    setFinalperr(false);
  };

  const handleAddFinalProducts = () => {
    if (
      productname !== "" &&
      productquantity !== "" &&
      productquantity !== "0" &&
      productquantity !== 0
    ) {
      let data = {
        itemid: productid,
        itemname: productname,
        quantity: parseFloat(productquantity),
        uom: uom,
        uomid: uomid,
      };
      finalproductsList.push(data);
      setproductQuantity("");
    } else {
      setFinalperr(true);
    }
  };

  const handleRemoveFinalProduct = (value) => {
    if (value !== "") {
      setFinalProductsList(
        finalproductsList.filter((e, index) => index !== value)
      );
    }
  };

  /* ----------Fianl Products End--------- */

  /* ----------Submit Start--------- */

  const handleTypeChange = (e) => {
    setFromstoretype(e.target.value);
    setWorkCenter("");
    setWorkCenterId("");
    setStore("");
    setStoreId("");
    // if(e.target.value==="Org Unit Stock"){
    //   InventoryService.GetWHdata(currentTenant).then(res=>{
    //     setWhdata(res.data)
    //   })
    // }
  };

  // const handleToTypeChange=(e)=>{
  //   setTostoretype(e.target.value)
  //   setToWorkCenter("");
  //   setToWorkCenterId("");
  //   setTostore("")
  //   setTostoreId("")
  // }

  const handleWarehouseChange = (e, newValue) => {
    setBomerr(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setWhId("");
      setWarehouse("");
      setWCList([])
      setWorkCenter("");
      setWorkCenterId("");
      setStore("");
      setStoreId("");
      setStoreList([])
    } else {
      setWhId(newValue.id);
      setWarehouse(newValue.name);
      WorkCenterServices.WorkCenterbywarehouse(newValue.id).then((res) =>{
        setWCList(res.data)
          if(res.data.length === 1){
            setWorkCenterId(res.data[0].id)
            setWorkCenter(res.data[0].name)
          ProductionServices.GetallStoreNames(res.data[0].id).then((resp) =>{
          setStoreList(resp.data)
          if(resp.data.length === 1){
            setStore(resp.data[0].name)
            setStoreId(resp.data[0].id)
            ProductionServices.GetStoreItems(resp.data[0].id).then((response) =>
              setRawItemList(
                response.data.storeitems.filter(
                  (e) => e.quantity !== 0 && e.quantity !== null
                )
              )
            );
          }
          })
          }else{
            setWorkCenter("");
            setWorkCenterId("");
            setStore("");
            setStoreId("");
            setStoreList([])
          }
        })
    }
  };

  const handleToWarehouseChange = (e, newValue) => {
    setFinalperr(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setToWhId("");
      setToWarehouse("");
      setToWorkCenter("");
      setToWorkCenterId("");
      setTostore("");
      setTostoreId("");
    } else {
      setToWhId(newValue.id);
      setToWarehouse(newValue.name);
      WorkCenterServices.WorkCenterbywarehouse(newValue.id).then((res) =>{
        setWCList(res.data)
        WorkCenterServices.WorkCenterbywarehouse(newValue.id).then((res) =>{
          setWCList(res.data)
            if(res.data.length === 1){
              setToWorkCenterId(res.data[0].id)
              setToWorkCenter(res.data[0].name)
            ProductionServices.GetallStoreNames(res.data[0].id).then((resp) =>{
              setStoreList(resp.data)
            if(resp.data.length === 1){
              setTostore(resp.data[0].name)
              setTostoreId(resp.data[0].id)
            }
            })
            }else{
              setToWorkCenter("");
              setToWorkCenterId("");
              setTostore("");
              setTostoreId("");
              setStoreList([])
            }
          })
      });
    }
  };

  const handleWorkCenterChange = (e, value) => {
    setBomerr(false);
    if (value === "" || value === null || value === undefined) {
      setWorkCenter("");
      setWorkCenterId("");
      setStore("");
      setStoreId("");
    } else {
      setWorkCenter(value.name);
      setWorkCenterId(value.id);
      ProductionServices.GetallStoreNames(value.id).then((res) =>{
        setStoreList(res.data)
        if(res.data.length === 1){
          setStore(res.data[0].name)
          setStoreId(res.data[0].id)
          ProductionServices.GetStoreItems(res.data[0].id).then((response) =>
              setRawItemList(
                response.data.storeitems.filter(
                  (e) => e.quantity !== 0 && e.quantity !== null
                )
              )
            );
        }else{
        setStore("");
        setStoreId("");
        }
    });
    }
  };

  const handleToWorkCenterChange = (e, value) => {
    setFinalperr(false);
    if (value === "" || value === null || value === undefined) {
      setToWorkCenter("");
      setToWorkCenterId("");
      setTostore("");
      setTostoreId("");
    } else {
      setToWorkCenter(value.name);
      setToWorkCenterId(value.id);
      setTostore("");
      setTostoreId("");
      ProductionServices.GetallStoreNames(value.id).then((res) =>{
        setStoreList(res.data)
        if(res.data.length === 1){
          setTostore(res.data[0].name);
          setTostoreId(res.data[0].id);
        }else{
          setTostore("");
          setTostoreId("");
        }
    });
    }
  };

  const handlestoreChange = (e, newValue) => {
    setBomerr(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setStore("");
      setStoreId("");
      setRawItemList([]);
    } else {
      setStore(newValue.name);
      setStoreId(newValue.id);
        ProductionServices.GetStoreItems(newValue.id).then((response) =>
          setRawItemList(
            response.data.storeitems.filter(
              (e) => e.quantity !== 0 && e.quantity !== null
            )
          )
        );
    }
  };

  const handleTostoreChange = (e, newValue) => {
    setFinalperr(false);
    if (newValue === "" || newValue === null || newValue === undefined) {
      setTostore("");
      setTostoreId("");
    } else {
      setTostore(newValue.name);
      setTostoreId(newValue.id);
    }
  };

  const handleonSubmit = (e) => {
    if (appxamount === "" || appxamount === undefined) {
    } else {
      setLoader(true)
      let data = {
        id: id,
        bomitems: bomitems,
        finalproduct: finalproductsList,
        createdby: userid,
        updatedby: userid,
        tenantid: currentTenant,
        toworkcenterid: toworkcenterid,
        workcenterid: workcenterid,
        soid: againstso === true ? soid : "NA",
        appxamount: appxamount,
        customdate:customdate,
        date:date,
        whid: whid,
        towhid: towhid,
        store: storeId,
        fromstoretype: fromstoretype,
        // tostoretype:tostoretype,
        tostore: tostoreid,
        blockedstock:
          fromstoretype === "Org Unit Stock" ? batchidlist : storeidlist,
      };
      if (id === -1) {
        ProductionServices.NewImmediateproduction(data).then(() =>
          navigate("/immediateproduction")
        ).catch(()=>setLoader(false))
      } else {
        ProductionServices.UpdateImmediateproduction(data).then(() =>
          navigate("/immediateproduction")
        ).catch(()=>setLoader(false))
      }
    }
  };

  /* ----------Submit End--------- */

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    let a = 0;
    bomitems.map((e) => (a = a + e.totalprice));
    setAppxAmount(a);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    let a = 0;
    bomitems.map((e) => (a = a + e.totalprice));
    setAppxAmount(a);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    if (step === 1 || step === 2) {
      let a = 0;
      bomitems.map((e) => (a = a + e.totalprice));
      setAppxAmount(a);
    }
  };

  return (
    // <div>
    //   <Header />
    //   <Menu />
    //   <div className="content-wrapper">
    //     <div className="card card-info">
    //       <ol className="breadcrumb float-sm-right">
    //         <li className=" text-secondary">
    //           <Link
    //             as="button"
    //             className=" text-secondary"
    //             to="/immediateproduction"
    //           >
    //             ❮ Back &nbsp;&nbsp;
    //           </Link>
    //         </li>
    //         <li className="breadcrumb-item text-secondary">
    //           <Link to="/index">Home</Link>
    //         </li>
    //         <li className="breadcrumb-item text-secondary">
    //           <Link to="/immediateproduction">Immediate production</Link>
    //         </li>
    //         <li className="breadcrumb-item active">New Immediate production</li>
    //       </ol>
    //       <section className="content">
    //         <div className="container-fluid">
    //           <div className="row">
    //             <div className="col-12">
    //               <div className="card">
    //                 <div className="card-header">
    //                   <h3 className="card-title">New Immediate production</h3>
    //                 </div>

                    <div className="card-body">
                      <Box sx={{ width: "100%" }}>
                        <Stepper nonLinear activeStep={activeStep}>
                          {steps.map((label, index) => (
                            <Step key={label} completed={completed[index]}>
                              <StepButton
                                color="inherit"
                                onClick={handleStep(index)}
                                style={{ outline: "none" }}
                              >
                                {label}
                              </StepButton>
                            </Step>
                          ))}
                        </Stepper>
                        <div className="mt-4">
                          {allStepsCompleted() ? (
                            <React.Fragment></React.Fragment>
                          ) : (
                            <React.Fragment>
                              <Formik
                                initialValues={{}}
                                // onSubmit={onSubmit}
                                validateOnChange={false}
                                // validate={validate}
                                enableReinitialize={true}
                              >
                                {({ setFieldValue, values }) => (
                                  <Form
                                    autoComplete="off"
                                    style={{ paddingLeft: "30px" }}
                                  >
                                    {/* ----------BOM Start--------- */}
                                    {activeStep === 0 ? (
                                      <div className="form-row">
                                        {/* <fieldset className="col-md-3">
                                          <FormControl
                                            variant="standard"
                                            className="w-100"
                                          >
                                            <InputLabel id="demo-simple-select-standard-label">
                                              Select Stock From
                                            </InputLabel>
                                            <Select
                                              labelId="demo-simple-select-standard-label"
                                              id="demo-simple-select-standard"
                                              value={fromstoretype}
                                              onChange={handleTypeChange}
                                              // label="From Store"
                                              fullWidth
                                              disabled={
                                                bomitems.length !== 0 ||
                                                itemid !== ""
                                              }
                                            >
                                              <MenuItem
                                                value={"Org Unit Stock"}
                                              >
                                                Org Unit Stock
                                              </MenuItem>
                                              <MenuItem
                                                value={"Production Store"}
                                              >
                                                Production Store
                                              </MenuItem>
                                            </Select>
                                          </FormControl>
                                        </fieldset> */}

                                        <fieldset className="form-group col-md-4">
                                        {whList.length === 1 ?
                                        <TextField
                                        className="form-control"
                                        type="text"
                                        name="warehouse"
                                        value={warehouse}
                                        label={`Select Org Unit (${warehouse})`}
                                        variant="standard"
                                        fullWidth
                                        disabled
                                      />
                                      :
                                          <Autocomplete
                                            id="warehouse"
                                            className="subacc-select"
                                            name="warehouse"
                                            options={whList}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            value={
                                              values.warehouse
                                                ? undefined
                                                : undefined
                                            }
                                            onChange={handleWarehouseChange}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                className="form-control"
                                                type="text"
                                                name="warehouse"
                                                value={warehouse}
                                                label={`Select Org Unit (${warehouse})`}
                                                variant="standard"
                                                error={whid === "" && bomerr}
                                                helperText={
                                                  whid === "" && bomerr
                                                    ? "Empty field!"
                                                    : " "
                                                }
                                                fullWidth
                                                disabled={bomitems.length !== 0}
                                              />
                                            )}
                                          />
                                              }
                                        </fieldset>
                                        {fromstoretype ===
                                        "Production Store" ? (
                                          <>
                                            <fieldset className="form-group col-md-4">
                                              {wcList.length === 1?
                                                  <TextField
                                                  label={`Select WorkCenter/Production Unit (${workcenter})`}
                                                  type="text"
                                                  value={workcenter}
                                                  variant="standard"
                                                  fullWidth
                                                  disabled
                                                  />
                                              :
                                              <Autocomplete
                                                id="workcenter"
                                                className="subacc-select"
                                                name="workcenter"
                                                options={wcList}
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                value={
                                                  values.workcenter
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={
                                                  handleWorkCenterChange
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label={`Select WorkCenter/Production Unit (${workcenter})`}
                                                    type="text"
                                                    value={workcenter}
                                                    variant="standard"
                                                    error={
                                                      workcenter === "" &&
                                                      bomerr
                                                    }
                                                    helperText={
                                                      (workcenter === "" ||
                                                        workcenter ===
                                                          undefined) &&
                                                      bomerr
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    fullWidth
                                                    disabled={
                                                      bomitems.length !== 0
                                                    }
                                                  />
                                                )}
                                              />
                                                  }
                                            </fieldset>
                                            <fieldset className="form-group col-md-4">
                                              {StoreList.length === 1 ?
                                               <TextField
                                               className="form-control"
                                               type="text"
                                               name="store"
                                               value={store}
                                               label={`Select Store (${store})`}
                                               variant="standard"
                                               fullWidth
                                               disabled
                                             />
                                             :
                                              <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="store"
                                                options={StoreList}
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                
                                                value={
                                                  values.store
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={handlestoreChange}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    className="form-control"
                                                    type="text"
                                                    name="store"
                                                    value={store}
                                                    label={`Select Store (${store})`}
                                                    variant="standard"
                                                    error={
                                                      store === "" && bomerr
                                                    }
                                                    helperText={
                                                      store === "" && bomerr
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    fullWidth
                                                    disabled={
                                                      bomitems.length !== 0
                                                    }
                                                  />
                                                )}
                                              />
                                                  }
                                            </fieldset>
                                          </>
                                        ) : (
                                          <fieldset className="form-group col-md-6"></fieldset>
                                        )}
 <fieldset className="form-group col-md-12 m-0"></fieldset>
                                        {whid !== "" &&
                                        whid !== undefined &&
                                        (fromstoretype === "Production Store"
                                          ? workcenterid !== "" &&
                                            storeId !== ""
                                          : true) ? (
                                          <>
                                            <fieldset className="form-group col-md-3">
                                              <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="category"
                                                options={categoryList}
                                                getOptionLabel={(option) =>
                                                  option.category
                                                }
                                                
                                                value={
                                                  values.category
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={handleCategoryChange}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    className="form-control"
                                                    type="text"
                                                    name="category"
                                                    value={category}
                                                    label={`Select Category (${category})`}
                                                    variant="standard"
                                                    error={
                                                      catid === "" && bomerr
                                                    }
                                                    helperText={
                                                      catid === "" && bomerr
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    fullWidth
                                                  />
                                                )}
                                              />
                                            </fieldset>
                                            <fieldset className="col-md-3">
                                              <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="itemname"
                                                options={filterItemList}
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                
                                                value={
                                                  values.itemname
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={handleSelectItem}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    id="standard-error-helper-text"
                                                    label={`Select Item (${itemname})`}
                                                    value={itemname}
                                                    variant="standard"
                                                    error={
                                                      itemname === "" && bomerr
                                                    }
                                                    helperText={
                                                      itemname === "" && bomerr
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    fullWidth
                                                  />
                                                )}
                                              />
                                            </fieldset>
                                            <fieldset className="form-group col-md-3">
                                              <TextField
                                                className="form-control"
                                                type="text"
                                                name="uom"
                                                label={`UOM `}
                                                variant="standard"
                                                value={uom}
                                                fullWidth
                                                disabled
                                              />
                                            </fieldset>
                                            {DisplayRawitem ? (
                                              <>
                                                <TableContainer>
                                                  <Table>
                                                    <TableHead>
                                                      <TableRow>
                                                      <TableCell className="text-center">
                                                          Supplier
                                                        </TableCell>
                                                        <TableCell className="text-center">
                                                          Batch Id
                                                        </TableCell>
                                                        <TableCell className="text-center">
                                                          Price
                                                        </TableCell>
                                                        <TableCell className="text-center">
                                                          Date
                                                        </TableCell>
                                                        <TableCell className="text-center">
                                                          Avilable Quantity
                                                        </TableCell>
                                                        <TableCell>
                                                          Enter Quanity
                                                        </TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {filterRawmaterials.length ===
                                                      0 ? (
                                                        <TableRow>
                                                          <TableCell
                                                            colSpan={12}
                                                            className="text-center"
                                                          >
                                                            No data avilable
                                                          </TableCell>
                                                        </TableRow>
                                                      ) : (
                                                        filterRawmaterials.map(
                                                          (e, i) => (
                                                            (fromstoretype ===
                                                            "Org Unit Stock"
                                                              ? e.currentStock>0
                                                              : e.quantity>0)?
                                                            <TableRow key={i}>
                                                               <TableCell className="text-center">
                                                                {e.supplier}
                                                              </TableCell>
                                                              <TableCell className="text-center">
                                                                {e.custombatchid}
                                                              </TableCell>
                                                              <TableCell className="text-right">
                                                                <NumericFormat
                                                                  displayType={
                                                                    "text"
                                                                  }
                                                                  value={
                                                                    e.price
                                                                  }
                                                                  decimalScale={
                                                                    2
                                                                  }
                                                                  fixedDecimalScale={
                                                                    true
                                                                  }
                                                                  disabled={
                                                                    true
                                                                  }
                                                                  thousandSeparator={
                                                                    true
                                                                  }
                                                                  thousandsGroupStyle={
                                                                    thousandsdisplay
                                                                  }
                                                                ></NumericFormat>
                                                              </TableCell>
                                                              <TableCell className="text-center">
                                                                {e.invaddeddate === null? e.createdAt.substring(0,19).replace("T"," "):e.invaddeddate.substring(0,19).replace("T"," ")}
                                                              </TableCell>
                                                              <TableCell className="text-center">
                                                                {fromstoretype ===
                                                                "Org Unit Stock"
                                                                  ? e.currentStock
                                                                  : e.quantity}
                                                              </TableCell>
                                                              <TableCell>
                                                                <fieldset className="col-md-12">
                                                                  <TextField
                                                                    className="form-control"
                                                                    type="number"
                                                                    name={`enteredquantity${i}`}
                                                                    variant="standard"
                                                                    value={
                                                                      e.enteredquantity
                                                                    }
                                                                    InputProps={{
                                                                      inputProps:
                                                                        {
                                                                          min: 0,
                                                                          max:
                                                                            fromstoretype ===
                                                                            "Org Unit Stock"
                                                                              ? e.currentStock
                                                                              : e.quantity,
                                                                        },
                                                                    }}
                                                                    onChange={(
                                                                      e
                                                                    ) =>
                                                                      handleQuantityChange(
                                                                        e.target
                                                                          .value,
                                                                        i
                                                                      )
                                                                    }
                                                                    error={
                                                                      e.enteredquantity >
                                                                        (fromstoretype ===
                                                                        "Org Unit Stock"
                                                                          ? e.currentStock
                                                                          : e.quantity) ||
                                                                      e.enteredquantity <
                                                                        0
                                                                    }
                                                                    helperText={
                                                                      e.enteredquantity >
                                                                      (fromstoretype ===
                                                                      "Org Unit Stock"
                                                                        ? e.currentStock
                                                                        : e.quantity)
                                                                        ? "Exceeds Avliable Quantity"
                                                                        : e.enteredquantity <
                                                                          0
                                                                        ? "Enter Positive Quantity"
                                                                        : ""
                                                                    }
                                                                  />
                                                                </fieldset>
                                                              </TableCell>
                                                            </TableRow>
                                                            :''
                                                          )
                                                        )
                                                      )}
                                                    </TableBody>
                                                  </Table>
                                                </TableContainer>

                                                <fieldset className="col-md-12 mt-2">
                                                  <button
                                                    className="btn btn-sm hovbuttonColour"
                                                    onClick={handleSaveQuantity}
                                                  >
                                                    Add
                                                  </button>
                                                </fieldset>
                                              </>
                                            ) : (
                                              <>
                                                {/* <fieldset className="col-md-3">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Price"
                                              type="number"
                                              name="price"
                                              value={price}
                                              variant="standard"
                                              fullWidth
                                            />
                                          </fieldset> */}
                                                {/* {fromstoretype==="Org Unit Stock"?
                                           <fieldset className="col-md-3">
                                           <TextField
                                             id="standard-error-helper-text"
                                             label="Enter Quantity"
                                             type="number"
                                             value={itemquantity}
                                             onClick={handleItemQuantity}
                                             variant="standard"
                                             error={itemquantity === ""}
                                             helperText={
                                               itemquantity === ""
                                                 ? "Empty field!"
                                                 : " "
                                             }
                                             fullWidth
                                           />
                                         </fieldset>
                                          :
                                          <fieldset className="col-md-3">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter Quantity"
                                              type="number"
                                              value={itemquantity}
                                              onChange={handleItemQuantity}
                                              variant="standard"
                                              error={itemquantity === ""}
                                              helperText={
                                                itemquantity === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          </fieldset>
                                          }  */}
                                                {/* <fieldset className="col-md-12 d-flex align-items-center">
                                            <Link
                                              as="button"
                                              className="btn btn-sm hovbuttonColour"
                                              onClick={handleAddBOMItem}
                                            >
                                              Add
                                            </Link>
                                          </fieldset> */}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {bomitems.length !== 0 &&
                                    activeStep === 0 ? (
                                      <TableContainer>
                                        <Typography>
                                          {" "}
                                          Raw Materials List
                                        </Typography>
                                        <Table className="table table-sm">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell className="text-center">
                                                Itemname
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Price
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Quantity
                                              </TableCell>
                                              <TableCell className="text-center">
                                                UOM
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Total
                                              </TableCell>
                                              {/* <TableCell className="text-center">
                                                    Actions
                                                  </TableCell> */}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {bomitems.map((e, index) => (
                                              <TableRow key={index}>
                                                <TableCell className="text-center">
                                                  {e.itemname}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  <NumericFormat
                                                    className="pr-3"
                                                    displayType={"text"}
                                                    value={e.price}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  {e.quantity}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  {e.uom}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  <NumericFormat
                                                    className="pr-3"
                                                    displayType={"text"}
                                                    value={e.totalprice}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                                {/* <TableCell className="text-center">
                                                      <Link
                                                        as="buttom"
                                                        className="btn btn-sm deletebutton"
                                                        onClick={() =>
                                                          handelRemoveItem(index)
                                                        }
                                                      >
                                                        Remove
                                                      </Link>
                                                    </TableCell> */}
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    ) : (
                                      ""
                                    )}
                                    {/* ----------BOM End--------- */}

                                    {/* ----------Final Products Start--------- */}
                                    {activeStep === 1 ? (
                                      <div className="form-row">
                                        <fieldset className="form-group col-md-4">
                                        {whList.length === 1 ?
                                          <TextField
                                          className="form-control"
                                          type="text"
                                          name="warehouse"
                                          value={towarehouse}
                                          label={`Select Org Unit (${towarehouse})`}
                                          variant="standard"
                                          fullWidth
                                          disabled
                                        />
                                        :
                                          <Autocomplete
                                            id="towarehouse"
                                            className="subacc-select"
                                            name="towarehouse"
                                            options={whList}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            
                                            value={
                                              values.warehouse
                                                ? undefined
                                                : undefined
                                            }
                                            onChange={handleToWarehouseChange}
                                            disabled={
                                              finalproductsList.length !== 0
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                className="form-control"
                                                type="text"
                                                name="warehouse"
                                                value={towarehouse}
                                                label={`Select Org Unit (${towarehouse})`}
                                                variant="standard"
                                                error={
                                                  towhid === "" && finalperr
                                                }
                                                helperText={
                                                  towhid === "" && finalperr
                                                    ? "Empty field!"
                                                    : " "
                                                }
                                                fullWidth
                                              />
                                            )}
                                          />
                                        }
                                        </fieldset>

                                        <fieldset className="form-group col-md-4">
                                        {wcList.length === 1?
                                           <TextField
                                           label={`Select WorkCenter/Production Unit (${toworkcenter})`}
                                           type="text"
                                           value={toworkcenter}
                                           variant="standard"
                                           fullWidth
                                           disabled
                                         />
                                        :
                                          <Autocomplete
                                            id="tpworkcenter"
                                            className="subacc-select"
                                            name="toworkcenter"
                                            options={wcList}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            
                                            value={
                                              values.workcenter
                                                ? undefined
                                                : undefined
                                            }
                                            onChange={handleToWorkCenterChange}
                                            disabled={
                                              finalproductsList.length !== 0
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                label={`Select WorkCenter/Production Unit (${toworkcenter})`}
                                                type="text"
                                                value={toworkcenter}
                                                variant="standard"
                                                error={
                                                  toworkcenter === "" &&
                                                  finalperr
                                                }
                                                helperText={
                                                  (toworkcenter === "" ||
                                                    toworkcenter ===
                                                      undefined) &&
                                                  finalperr
                                                    ? "Empty field!"
                                                    : " "
                                                }
                                                fullWidth
                                              />
                                            )}
                                          />
                                              }
                                        </fieldset>
                                        <fieldset className="form-group col-md-4">
                                        {StoreList.length === 1?
                                         <TextField
                                         className="form-control"
                                         type="text"
                                         name="tstore"
                                         value={tostore}
                                         label={`Select Store (${tostore})`}
                                         variant="standard"
                                         fullWidth
                                         disabled
                                       />
                                       :
                                          <Autocomplete
                                            id="subaccount"
                                            className="subacc-select"
                                            name="tstore"
                                            options={StoreList}
                                            getOptionLabel={(option) =>
                                              option.name
                                            }
                                            
                                            value={
                                              values.store
                                                ? undefined
                                                : undefined
                                            }
                                            onChange={handleTostoreChange}
                                            disabled={
                                              finalproductsList.length !== 0
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                className="form-control"
                                                type="text"
                                                name="tstore"
                                                value={tostore}
                                                label={`Select Store (${tostore})`}
                                                variant="standard"
                                                error={
                                                  tostore === "" && finalperr
                                                }
                                                helperText={
                                                  tostore === "" && finalperr
                                                    ? "Empty field!"
                                                    : " "
                                                }
                                                fullWidth
                                              />
                                            )}
                                          />
                                        }
                                        </fieldset>
                                        <fieldset className="form-group col-md-12 m-0"></fieldset>
                                        {towhid !== "" &&
                                        towhid !== undefined &&
                                        toworkcenterid !== "" &&
                                        tostoreid !== "" ? (
                                          <>
                                            <fieldset className="form-group col-md-3">
                                              <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="category"
                                                options={categoryList}
                                                getOptionLabel={(option) =>
                                                  option.category
                                                }
                                                
                                                value={
                                                  values.category
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={handleCategoryChange}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    className="form-control"
                                                    type="text"
                                                    name="category"
                                                    value={category}
                                                    label={`Select Category (${category})`}
                                                    variant="standard"
                                                    error={
                                                      catid === "" && finalperr
                                                    }
                                                    helperText={
                                                      catid === "" && finalperr
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    fullWidth
                                                  />
                                                )}
                                              />
                                            </fieldset>

                                            <fieldset className="col-md-3">
                                              <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="productname"
                                                options={filterItemList}
                                                getOptionLabel={(option) =>
                                                  option.name
                                                }
                                                
                                                value={
                                                  values.productname
                                                    ? undefined
                                                    : undefined
                                                }
                                                onChange={handleSelectProduct}
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    id="standard-error-helper-text"
                                                    label={`Select Final/End Product (${productname})`}
                                                    value={productname}
                                                    variant="standard"
                                                    error={
                                                      productname === "" &&
                                                      finalperr
                                                    }
                                                    helperText={
                                                      productname === "" &&
                                                      finalperr
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                    fullWidth
                                                  />
                                                )}
                                              />
                                            </fieldset>

                                            <fieldset className="form-group col-md-3">
                                              {/* <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="uom"
                                                options={UOMList}
                                                getOptionLabel={(option) => option.name}
                                                
                                                
                                                value={values.uom?undefined:undefined}
                                                onChange={handleUOM}
                                                renderInput={params => (   */}
                                              <TextField
                                                // {...params}
                                                className="form-control"
                                                type="text"
                                                name="uom"
                                                label={`UOM`}
                                                value={uom}
                                                variant="standard"
                                                fullWidth
                                                disabled
                                              />
                                              {/* )}
                                              /> */}
                                            </fieldset>

                                            <fieldset className="col-md-2">
                                              <TextField
                                                id="standard-error-helper-text"
                                                label="Enter Quantity"
                                                type="number"
                                                value={productquantity}
                                                variant="standard"
                                                onChange={
                                                  handleFinalProductsQuantity
                                                }
                                                InputProps={{
                                                  inputProps: { min: 1 },
                                                }}
                                                error={
                                                  productquantity === "" &&
                                                  finalperr
                                                }
                                                helperText={
                                                  productquantity === "" &&
                                                  finalperr
                                                    ? "Please Enter Quantity "
                                                    : " "
                                                }
                                                fullWidth
                                              />
                                            </fieldset>
                                            <fieldset className="col-md-1 d-flex align-items-center">
                                              <span
                                                as="button"
                                                className="btn btn-sm hovbuttonColour"
                                                onClick={handleAddFinalProducts}
                                              >
                                                Add
                                              </span>
                                            </fieldset>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {finalproductsList.length !== 0 &&
                                    activeStep === 1 ? (
                                      <TableContainer>
                                        <Typography>
                                          Final/End Products
                                        </Typography>
                                        <Table className="table-sm">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell className="text-center">
                                                Product
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Quantity
                                              </TableCell>
                                              <TableCell className="text-center">
                                                UOM
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Actions
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {finalproductsList.map(
                                              (e, index) => (
                                                <TableRow key={index}>
                                                  <TableCell className="text-center">
                                                    {e.itemname}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {e.quantity}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {e.uom}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    <span
                                                      as="buttom"
                                                      className="btn btn-sm deletebutton"
                                                      onClick={() =>
                                                        handleRemoveFinalProduct(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      Remove
                                                    </span>
                                                  </TableCell>
                                                </TableRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    ) : (
                                      ""
                                    )}
                                    {/* ----------Final Products End--------- */}

                                      {/* --------------------------Production Losses-------------------------------------- */}
                                      {activeStep === 2 ? 
                                      <>
                                      <TableContainer>
                                        <Typography>
                                          {" "}
                                        </Typography>
                                        <Table className="table-sm">
                                          <TableHead>
                                            <TableRow>
                                              <TableCell className="text-center">
                                                Itemname
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Price
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Quantity
                                              </TableCell>
                                              <TableCell className="text-center">
                                                UOM
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Losses
                                                </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {bomitems.map((e, index) => (
                                              <TableRow key={index}>
                                                <TableCell className="text-center">
                                                  {e.itemname}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  <NumericFormat
                                                    className="pr-3"
                                                    displayType={"text"}
                                                    value={e.price}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  {e.quantity}
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  {e.uom}
                                                </TableCell>
                                                <TableCell className="d-flex items-center text-center">
                                                <fieldset className="col-md-4">
                                                  <TextField
                                                    id="standard-error-helper-text"
                                                    type="number"
                                                    label="Quantity"
                                                    onChange={(val) =>{
                                                      if((val.target.value)=== '' || (val.target.value)===undefined || parseFloat(val.target.value)<0){
                                                        bomitems.find((a,i)=>i===index).comment = ''
                                                        bomitems.find((a,i)=>i===index).losses = 0
                                                        }else{
                                                      bomitems.find((a,i)=>i===index).losses = (parseFloat(val.target.value)>e.quantity?e.quantity:parseFloat(val.target.value))
                                                      bomitems.find((a,i)=>i===index).comment = 'Shortage'
                                                        }
                                                      setBomItems([...bomitems])
                                                    }}
                                                    value={e.losses}
                                                    variant="standard"
                                                    fullWidth
                                                  />
                                                  </fieldset>
                                                  {e.losses>0?
                                                  <fieldset className="col-md-4">
                                                  <FormControl
                                                    className="col-md-12"
                                                    variant="standard"
                                                    fullWidth
                                                  >
                                                    <InputLabel id="demo-simple-select-filled-label">
                                                      Comments
                                                    </InputLabel>
                                                    <Select
                                                      value={e.comment}
                                                      onChange={(val)=>{
                                                        bomitems.find((a,i)=>i===index).comment = val.target.value
                                                        setBomItems([...bomitems])
                                                      }}
                                                    >
                                                      {comments.map((option) => {
                                                        return (
                                                          <MenuItem key={option} value={option}>
                                                            {option}
                                                          </MenuItem>
                                                        );
                                                      })}
                                                    </Select>
                                                  </FormControl>
                                                  </fieldset>:''}
                                                </TableCell>
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                      </>:''}

                                       {/* --------------------------End of Production Losses-------------------------------------- */}

                                    {/* ----------Submit Start--------- */}
                                    <fieldset className="row">
                                    <fieldset className="col-md-6">
                                            <TableContainer>
                                              <Typography>
                                                {" "}
                                                Raw Materials List
                                              </Typography>
                                              <Table className="table-sm">
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell className="text-center">
                                                      Itemname
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      Quantity
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      UOM
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      Price
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      Losses
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                     Comment
                                                      </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {bomitems.map((e, index) => (
                                                    <TableRow key={index}>
                                                      <TableCell className="text-center">
                                                        {e.itemname}
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                        {e.quantity}
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                        {e.uom}
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                        {e.price}
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                        {e.losses}
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                        {e.comment}
                                                      </TableCell>
                                                    </TableRow>
                                                  ))}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </fieldset>
                                          <fieldset className="col-md-6">
                                            <TableContainer>
                                              <Typography>
                                                Final/End Products
                                              </Typography>
                                              <Table className="table-sm">
                                                <TableHead>
                                                  <TableRow>
                                                    <TableCell className="text-center">
                                                      Product
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      Quantity
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      UOM
                                                    </TableCell>
                                                  </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                  {finalproductsList.map(
                                                    (e, index) => (
                                                      <TableRow key={index}>
                                                        <TableCell className="text-center">
                                                          {e.itemname}
                                                        </TableCell>
                                                        <TableCell className="text-center">
                                                          {e.quantity}
                                                        </TableCell>
                                                        <TableCell className="text-center">
                                                          {e.uom}
                                                        </TableCell>
                                                      </TableRow>
                                                    )
                                                  )}
                                                </TableBody>
                                              </Table>
                                            </TableContainer>
                                          </fieldset>
                                      </fieldset>
                                    {activeStep === 3 ? (
                                      <>
                                        <div className="text-red">
                                          {errormessage}
                                        </div>
                                        <div className="form-row mt-3">
                                          <fieldset className="col-md-12">
                                            <FormGroup row>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={againstso}
                                                    onChange={handleagainstso}
                                                    name="taxrequired"
                                                    color="primary"
                                                  />
                                                }
                                                label="Against SaleOrder?"
                                              />
                                            </FormGroup>
                                          </fieldset>
                                          <fieldset className="col-md-12" />
                                          {againstso === true ? (
                                            <fieldset className="col-md-3">
                                              <Autocomplete
                                                id="controlled-demo"
                                                options={soList}
                                                getOptionLabel={(option) =>
                                                  option
                                                }

                                                value={soid}
                                                onChange={(event, newValue) => {
                                                  newValue === "" ||
                                                  newValue === null ||
                                                  newValue === undefined
                                                    ? setSoId("")
                                                    : setSoId(newValue);
                                                  setErrorMsg("");
                                                }}
                                                error={
                                                  soid === "" ||
                                                  soid === undefined
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    label="Select Sale Order Id"
                                                    variant="standard"
                                                    error={
                                                      soid === "" ||
                                                      soid === undefined
                                                    }
                                                    helperText={
                                                      soid === "" ||
                                                      soid === undefined
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                  />
                                                )}
                                              />
                                            </fieldset>
                                          ) : (
                                            ""
                                          )}
                                          <fieldset className="col-md-12"></fieldset>
                                         
                                          <fieldset className="form-group col-md-3">

                                            <FormGroup row>
                                                    <FormControlLabel
                                                      control={
                                                        <Checkbox
                                                          checked={customdate}
                                                          onChange={handlecustomDate}
                                                          name="ordertype"
                                                          color="primary"
                                                        />
                                                      }
                                                      label="Custom date?"
                                                    />
                                                  </FormGroup>
                                            </fieldset>
                                            {customdate?

                                            <fieldset className="form-group col-md-3">
                                            {/* <div className="form-row mr-4 mb-4"> */}
                                            {/* <fieldset style={{ marginLeft: "30px" }}> */}
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Grid  justifyContent="space-around">
                                            <DateTimePicker
                                            disableFuture
                                            showtodaybutton
                                            showcancelbutton
                                            //  views={["year", "month","date"]}
                                            variant="inline"
                                            id="date-picker-dialog"
                                            // label="Financial Year Start Date"
                                            // inputVariant="outlined"
                                            minDate={new Date(moment(financialyearstart).format("YYYY-MM-DD"))}
                                            maxDate={new Date(moment(financialyearend).format("YYYY-MM-DD"))}

                                            label={`Custom Date : ${customdate?"Custom":"Default"}`}
                                            format="dd/MM/yyyy HH:mm"
                                            value={date}
                                            InputAdornmentProps={{ position: "start" }}
                                            onChange={handlecreatedAtDateChange}
                                            renderInput={(params) => <TextField {...params} variant="standard"/>}
                                            autoOk={true}
                                            fullWidth
                                            disabled={customdate===false}
                                            />
                                            </Grid>
                                            </LocalizationProvider>
                                            </fieldset>
                                            :''}


                                          <fieldset className="col-md-12 mt-2">
                                            {bomitems.length === 0 ||
                                            finalproductsList.length === 0 ? (
                                              <button
                                                className="btn btn-sm hovbuttonColour"
                                                disabled
                                              >
                                                Submit
                                              </button>
                                            ) : (
                                                  <button
                                                    as="button"
                                                    className="btn btn-sm hovbuttonColour"
                                                    onClick={handleonSubmit}
                                                    disabled={loader===true}
                                                  >
                                                   {loader=== true?<><Spinner animation="border" size="sm"/> Submitting</>:"Submit"}
                                                  </button>
                                            )}
                                            <Link
                                              as="button"
                                              className="btn btn-sm ml-3 deletebutton"
                                              to="/immediateproduction"
                                            >
                                              <b> X</b> Cancel
                                            </Link>
                                          </fieldset>
                                        </div>
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {/* ----------Submit End--------- */}
                                  </Form>
                                )}
                              </Formik>

                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  pt: 2,
                                }}
                              >
                                <Button
                                  color="inherit"
                                  disabled={activeStep === 0}
                                  onClick={handleBack}
                                  sx={{ mr: 1 }}
                                  style={{ outline: "none" }}
                                >
                                  ❮ Back
                                </Button>
                                <Box sx={{ flex: "1 1 auto" }} />
                                <Button
                                  onClick={handleNext}
                                  sx={{ mr: 1 }}
                                  disabled={activeStep === 3}
                                  style={{ outline: "none" }}
                                >
                                  Next ❯
                                </Button>
                              </Box>
                            </React.Fragment>
                          )}
                        </div>
                      </Box>
                    </div>
    //                </div>
    //             </div>
    //           </div>
    //         </div>
    //       </section>
    //     </div>
    //   </div>
    //   <Footer /> 
    // </div>
  );
}

export default withRouter(NewImmediateprocess)


