import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import moment from "moment";
import PurchaseServices from "../PurchseServices";
import { thousandsdisplay, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import {Table,TableContainer, TableBody,TableRow, TableCell, TableHead } from "@mui/material";
import { NumericFormat } from "react-number-format";

class ViewPurchaseReturns extends Component {
  constructor(props) {
    super(props);
    const id = this.props.location.state.id;
    const date = this.props.location.state.date;
    const seller = this.props.location.state.seller;
    const pagename = this.props.location.state.pagename;
    this.state = {
      Orders: '',
      itemlist:[],
      grnreturnitems:[],
      id: id,
      seller:seller,
      date: date,
      message: "",
      loading:false,
      pagename:pagename,
    };
  }

  refreshSalesorder() {
    const id = this.props.location.state.id;
    const pagename = this.props.location.state.pagename;
    // let username = AuthenticationService.getLoggedInUser()
    if(pagename === "Purchase Returns Details"){
    PurchaseServices.GetPurchaseReturnsItems(id).then((response) => {
      this.setState({ Orders: response.data, itemlist: response.data.itemlist });
      const script = document.createElement("script");
      script.src = "../../../js/content.js";
      script.async = true;
      document.body.appendChild(script);
      this.setState({loading:false})
    }) 
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
    }else{
    PurchaseServices.GetGrnReturnItems(id).then((res) => {
      this.setState({Orders: res.data, grnreturnitems: res.data.itemlist, loading:false});
    }).catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }
  }

 // (this.state.pagename = "Purchase Returns Details") ?
  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    this.setState({loading:true})
    this.refreshSalesorder()
  }

  render() {
    return (
      <div>
         <Header />
         <Menu/>
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/purchasereturns"}} state={{message:'',returntype:this.state.pagename === "Purchase Returns Details" ?"Purchase Returns":"GRN Item Returns"}}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to={{ pathname: "/purchasereturns"}} state={{message:'',returntype:this.state.pagename === "Purchase Returns Details" ?"Purchase Returns":"GRN Item Returns"}}>Purchase Returns</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">{this.state.pagename}</h3>
                      </div>

                      <div className="card-body">
                      <div className="text-center">{this.state.loading === true ? spin : ''}</div>
                        <div className="form-group col-md-6" />
                        <table className="float-left m-0">
                          <tr>
                            <td>PurchaseOrder Id</td>
                            <td>: &nbsp; {this.state.Orders.purchaseorder}</td>
                          </tr>
                          <tr>
                            <td>User Id</td>
                            <td>: &nbsp; {this.state.Orders.createdby}</td>
                          </tr>
                          <tr>
                            <td>User</td>
                            <td>: &nbsp; {this.state.Orders.createdbyname}</td>
                          </tr>
                          {this.state.pagename === "Purchase Returns Details" ? "": 
                           <tr>
                           <td>GRN Id</td>
                           <td>: &nbsp; {this.state.Orders.grnid}</td>
                         </tr>
                         }
                          <tr>
                            <td>Supplier</td>
                            <td>: &nbsp; {this.state.Orders.supplier}</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td>: &nbsp; {moment(this.state.date).format("DD - MM - yyyy")}</td>
                          </tr>
                        </table>

                      {this.state.pagename === "Purchase Returns Details" ? 
                        <TableContainer>
                        <Table className="table-sm">
                          <TableHead>
                            <TableRow style={{ textAlign: "center" ,}}>
                              <TableCell>Id</TableCell>
                              <TableCell>Item Name</TableCell>
                              <TableCell>HSN</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Tax amount</TableCell>
                              <TableCell>TotalPrice</TableCell>
                              <TableCell>Return or Exchange</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.itemlist.map((data, index) => (
                              <TableRow key={index} style={{ textAlign: "center" }}>
                                <TableCell>{data.id}</TableCell>
                                <TableCell>{data.name}</TableCell>
                                <TableCell>{data.hsn}</TableCell>
                                <TableCell>{data.purchaseprice}</TableCell>
                                <TableCell>{data.quantity}</TableCell>
                                <TableCell>{data.description}</TableCell>
                                <TableCell>{data.taxamount}({data.tax}%)</TableCell> 
                                <TableCell>{data.totalprice}</TableCell>
                                <TableCell>{data.exchangereturn}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        </TableContainer>
                         : 
                        <TableContainer>
                        <Table className="table-sm">
                          <TableHead>
                            <TableRow style={{ textAlign: "center" ,}}>
                              <TableCell>Id</TableCell>
                              <TableCell>Item Name</TableCell>
                              <TableCell>HSN</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell>Description</TableCell>
                              <TableCell>Tax amount</TableCell>
                              <TableCell>TotalPrice</TableCell> 
                              <TableCell>Total</TableCell> 
                              <TableCell>Return or Exchange</TableCell>
                              <TableCell>Refund</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.grnreturnitems.map((data, index) => (
                              <TableRow key={index} style={{ textAlign: "center" }}>
                                <TableCell>{data.id}</TableCell>
                                <TableCell>{data.name}</TableCell>
                                <TableCell>{data.hsn}</TableCell>
                                <TableCell><NumericFormat
                                className="pr-3"
                                displayType={"text"}
                                value={
                                  data.price
                                }
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat></TableCell>
                                <TableCell>{data.quantity}</TableCell>
                                <TableCell>{data.description}</TableCell>
                                <TableCell><NumericFormat
                                className="pr-3"
                                displayType={"text"}
                                value={data.taxamount}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>({data.tax}%)</TableCell> 
                                <TableCell><NumericFormat
                                className="pr-3"
                                displayType={"text"}
                                value={data.total}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat></TableCell>
                                <TableCell><NumericFormat
                                className="pr-3"
                                displayType={"text"}
                                value={data.totalwtax}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat></TableCell>
                                <TableCell>{data.exchangereturn}</TableCell>
                                <TableCell>{data.refund}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        </TableContainer>
                        }

                      </div>
                    </div>

                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(ViewPurchaseReturns);
