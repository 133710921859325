import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

class SupplierService{
    
   

   retriveAllSuppliers(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/findsuppliers/${tenantId}`)
      }


      retriveSupplier(id){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getsuppliers/${id}`)
      }

     
      createSupplier(newSupplier){
         return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addnewsuppliers`,newSupplier)
      }

      updateSupplier(updateSupplier){
         return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatesuppliers`,updateSupplier)
      }

      getCountries(){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/loadCountries`,)
      }

      getStates(value){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/loadStatesByCountry/${value}`,)
      }

      getCities(id){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/loadCitiesByState/${id}`,)
      }
      deleteSupplier(id){
         return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletesupplier/${id}`,)
      }


      addCustomerfromsupplier(data){
         return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addcustomerfromsupplier`,data)
      }

      addPurchaseBalRev(data){
         return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/addPurchaseBalRev`,data)
      }
}

export default new SupplierService();
