import { Autocomplete } from '@mui/material';
import {Button,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,Typography} from "@mui/material";
import { Form, Formik } from 'formik';
import { FormikTextField } from '../../CommonComponents/FormField.js';
import { Component } from 'react';
import withRouter from "../../withRouter.js";
import { Link } from 'react-router-dom';
import { currentTenant, dataaccess, financialyearend, financialyearstart, prefix1, thousandsdisplay, userid, userwhid } from '../../Common/Credentials';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import PurchaseServices from '../PurchseServices'
import { Spinner } from 'react-bootstrap';
import FileServices from "../../Common/upload/FileServices.js";
import UploadFilesService from "../../Common/upload/UploadFilesService.js";
import moment from "moment";
import { NumericFormat } from 'react-number-format';


let curmonth,year,day,path
let doctype = "servicecompletion"


// const BorderLinearProgress = styled((theme) => ({
//     root: {
//       height: 15,
//       borderRadius: 5,
//     },
//     colorPrimary: {
//       backgroundColor: "#EEEEEE",
//     },
//     bar: {
//       borderRadius: 5,
//       backgroundColor: '#1a90ff',
//     },
//   }))(LinearProgress);


class NewSCN extends Component {
    constructor(props){
        super(props)
        this.state={
            // pagename:this.props.location.state.pagename,
            suppliersdata:[],
            poidList:[],
            serviceList:[],
            supplier:'',
            supplierid:'',
            poid:'',
            Orders:'',
            milestones:[],
            completed:'',
            loader:false,

            selectedFiles: undefined,
            currentFile: undefined,
            progress: 0,
            message: "",
            isError: false,
            fileInfos: [],
            fileInfo:"",
            partyid:'',   
            error: false, //<---- here
            errorMessage: {}, //<-----here
            uploadloader:false,
            contracttype:'',
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.selectFile=this.selectFile.bind(this);
        this.upload=this.upload.bind(this);
    }

    
    selectFile=event=> {
        this.setState({
           selectedFiles: event.target.files,
        });
    }

    upload() {
        let currentFile = this.state.selectedFiles[0];
    

        this.setState({currentFile: currentFile,uploadloader:true});

        UploadFilesService.upload(currentFile,year,curmonth,doctype,this.state.partyid,day, (event) => {
        // var percent = Math.floor(event.percent);
        // if (percent >= 100) {
        //   this.setState({ progress: 100 });
        // } else {
        //   this.setState({ progress: percent });
        // }


        this.setState({progress: Math.round((100 * event.loaded) / event.total)});
        // progress=Math.round((100 * event.loaded) / event.total)
        })
        .then((response) => {
            this.setState({
            message: response.data.message,
            isError: false
            });
            return FileServices.getFiles1(currentFile.name);
        })
        .then((files) => {this.setState({fileInfos: files.data,});
        })

        .catch(() => {
            this.setState({
            progress: 0,
            message: "Could not upload the file!",
            currentFile: undefined,
            isError: true,
            uploadloader:false
            });
        });

        this.setState({selectedFiles: undefined,uploadloader:false});
    }


    handlesupplier=(value,values)=>{
        if(values!==null && typeof values === 'object'  && values!==undefined){
            this.setState({supplier:values.companyname,supplierid:values.id,partyid:values.id})
            const id = values.id;

            let datadao = {
                tenantid: currentTenant,
                startdate: financialyearstart,
                enddate: financialyearend,
                userid:userid,
                dataaccess: dataaccess,
                whid:userwhid,
                id:id
              }

            PurchaseServices.retrivepoidofservice(datadao).then((res) =>
                this.setState({poidList: res.data})
            );
        }
    }

    handlePurchaseorder=(value,values)=>{
        if(values !==null && values !== undefined && values !=='' && this.state.poidList.includes(values) ){
        this.setState({poid:values})
            PurchaseServices.GetPurchaseOrderForScn(values).then((response) => {
                this.setState({ 
                    Orders: response.data,
                    serviceList:response.data.servicelist, 
                    contracttype:response.data.potype === 1? response.data.servicelist.find(e=>e).contracttype:'',
                    milestones:response.data.servicelist.map(e=>e.milestones).find(e=>e),
                })
            })    
        }
    }

    handlemilestonestatus=(i)=>{
        this.state.milestones.find((e,index)=>index===i).servicestatus='Completed'
        this.setState({completed:this.state.milestones.find((e,index)=>index===i)})
    }

    onSubmit(){
        let filedata= this.state.fileInfos.map((e)=>e.name).toLocaleString()
        path=prefix1+"/"+doctype+"/"+this.state.partyid+"/"+year+"/"+curmonth+"/"+day
        // if(filedata === ''){
        //     this.setState({message:"Please Upload Delivery note"})
        //   }else{
            this.setState({loader:true})
        let data = {
            poid:this.state.poid,
            milestone:this.state.completed === ""? null : this.state.completed,
            serviceid:this.state.serviceList.find(e=>e).serviceid,
            path:path,
            file:filedata,
            createdby:userid
        }
        PurchaseServices.addscn(data).then(()=>this.props.navigate('/scn'))
        .catch((e)=>{
            if(e.response.data.error ==="Unauthorized"){
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
            }
        })
    //    }
    }

    componentDidMount(){
        if(userid===undefined){
            window.confirm('Your Session is Lost: Please login Back to resume')
            this.props.navigate(`/`)
        }

        PurchaseServices.GetAllSellers(currentTenant).then((res) =>{
            this.setState({
                suppliersdata: res.data,
            });
        })
        .catch((e)=>{
            if(e.response.data.error ==="Unauthorized"){
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
            }
        })
        year = new Date().getFullYear()
        day= new Date().getDate()
        let mn = new Date().getMonth()+1
        curmonth= moment(mn,'M').format('MMMM')
    }

    validate(values) {
        let errors = {};

        if(!values.supplierid){
            errors.supplier = "Please Select Supplier";
          }
      
        return errors
    }
    render(){
        let {supplier,supplierid,poid,suppliersdata,poidList,serviceList,selectedFiles,currentFile,isError,message}=this.state
        return(

            <div>
                 <Header />
                <Menu/>
                <div className="content-wrapper">
                    <div className="card">
                        <ol className="breadcrumb float-sm-right">
                        <span as="button" className="float-left mr-3 text-secondary cursorPointer" onClick={()=>this.props.navigate('/scn')}>   ❮ Back</span>
                        <li className="breadcrumb-item text-secondary">
                            <Link to="/purchase">Home</Link>
                        </li>
                        <li className="breadcrumb-item text-secondary">
                            <Link to="/scn">SCN</Link>
                        </li>
                        <li className="breadcrumb-item active">New SCN</li>
                        </ol>
                        <div className="card-body">
                            <Formik
                                initialValues={{supplier,supplierid,poid,suppliersdata,poidList,serviceList,selectedFiles,currentFile,isError,message}}
                                onSubmit={this.onSubmit}
                                validateOnChange={false}
                                validate={this.validate}
                                enableReinitialize={true}
                            >
                                {({setFieldValue,values})=>
                                    <Form className="form-row">
                                        <fieldset className="form-group col-md-3">
                                            <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="supplier"
                                                options={suppliersdata}
                                                getOptionLabel={(option) =>option.companyname}
                                                value={values.supplier ? undefined : undefined}
                                                onChange={this.handlesupplier}
                                                renderInput={(params) => (
                                                    <FormikTextField
                                                        {...params}
                                                        className="form-control"
                                                        type="text"
                                                        name="supplier"
                                                        label="Select  Supplier"
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </fieldset>
                                        <fieldset className="form-group col-md-3">
                                            <Autocomplete
                                                id="subaccount"
                                                className="subacc-select"
                                                name="purchaseorder"
                                                options={poidList}
                                                getOptionLabel={(option) => option}
                                                
                                                
                                                value={values.purchaseorder?undefined:undefined}
                                                onChange={this.handlePurchaseorder}
                                                renderInput={params => (  
                                                    <FormikTextField
                                                        {...params}
                                                        className="form-control"
                                                        type="text"
                                                        name="purchaseorder"
                                                        label="Select Purchaseorder Id"
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </fieldset>
                                       {this.state.poid === ''?'':<>
                                            <TableContainer>
                                                <Table className="table-sm">
                                                <TableHead>Service</TableHead>
                                                    <TableHead className="text-sm">
                                                        <TableRow style={{ textAlign: "center" }}>
                                                        <TableCell className="text-center">Sl. No.</TableCell>
                                                        <TableCell className="text-center">Service</TableCell>
                                                        <TableCell className="text-center">HSN/SAC Code</TableCell>
                                                        <TableCell className="text-center">Price</TableCell>
                                                        <TableCell className="text-center">Tax (%)</TableCell>
                                                        <TableCell className="text-center">TotalPrice</TableCell>
                                                        {this.state.serviceList.map(e=>e.contracttype).toLocaleString() === "Recurring"? <TableCell className="text-center">Recurring Type</TableCell>:''}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className="text-sm">
                                                        {this.state.serviceList.map((data, index) => (
                                                        <TableRow key={index} className="text-center">
                                                            <TableCell className="text-center">{index + 1}</TableCell>
                                                            <TableCell className="text-center">{data.service}</TableCell>
                                                            <TableCell className="text-center">{data.servicecode}</TableCell>
                                                            <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.servicecost} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                                            <TableCell className="text-center">{data.servicetax} %</TableCell>
                                                            <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.servicetotalwtax} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                                            {this.state.serviceList.map(e=>e.contracttype).toLocaleString() === "Recurring"? <TableCell className="text-center">{this.state.serviceList.map(e=>e.recurringtype)}</TableCell>:''}
                                                        </TableRow>
                                                        ))}
                                                        <TableRow>
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell />
                                                        <TableCell className="text-center" >Total</TableCell>
                                                        <TableCell className="text-right">
                                                        <NumericFormat className="pr-3" displayType={'text'} value={this.state.Orders.grandservicetotal} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                                        </TableCell>
                                                        {this.state.serviceList.map(e=>e.contracttype).toLocaleString() === "Recurring"? <TableCell></TableCell>:''}
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        
                                            {this.state.serviceList.map(e=>e.contracttype).toLocaleString() !== "Recurring"?<>
                                                <TableContainer>
                                                    <Table className="table-sm mt-3">
                                                        <TableHead>Milestones</TableHead>
                                                        <TableHead className="text-sm">
                                                        <TableRow style={{ textAlign: "center" }}>
                                                            <TableCell className="text-center">Name</TableCell>
                                                            <TableCell className="text-center">Expected Date</TableCell>
                                                            <TableCell className="text-center">Percentage</TableCell>
                                                            <TableCell  className="text-center">Amount</TableCell>
                                                            <TableCell className="text-center">Status</TableCell>
                                                            <TableCell className="text-center">Actions</TableCell>
                                                        </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                        {this.state.milestones.map((data,index) => (
                                                            <TableRow key={index}>
                                                                <TableCell className="text-center">{data.name}</TableCell>
                                                                <TableCell className="text-center">{moment(data.expecteddate).format('DD-MM-YYYY')}</TableCell>
                                                                <TableCell className="text-center">{data.percentage}</TableCell>
                                                                <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.totalpricewtax} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                                                <TableCell className="text-center">{data.servicestatus}</TableCell>
                                                                {this.state.completed===''?<TableCell className="text-center">{<span as="button" onClick={()=>this.handlemilestonestatus(index)} className='cursorPointer'>Complete <i className="fa fa-check text-green"/> </span>}</TableCell>:<TableCell></TableCell>}
                                                            </TableRow>
                                                        
                                                        ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                {this.state.completed===''?'':<>
                                                    <TableContainer>
                                                        <Table className="table-sm mt-3">
                                                            <TableHead>Completed</TableHead>
                                                            <TableHead className="text-sm">
                                                                <TableRow style={{ textAlign: "center" }}>
                                                                    <TableCell className="text-center">Name</TableCell>
                                                                    <TableCell className="text-center">Expected Date</TableCell>
                                                                    <TableCell className="text-center">Percentage</TableCell>
                                                                    <TableCell  className="text-center">Amount</TableCell>
                                                                    <TableCell className="text-center">Status</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {/* {this.state.completed.map((data,index) => ( */}
                                                                    <TableRow>
                                                                        <TableCell className="text-center">{this.state.completed.name}</TableCell>
                                                                        <TableCell className="text-center">{moment(this.state.completed.expecteddate).format('DD-MM-YYYY')}</TableCell>
                                                                        <TableCell className="text-center">{this.state.completed.percentage}</TableCell>
                                                                        <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={this.state.completed.amount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                                                        <TableCell className="text-center">{this.state.completed.servicestatus}</TableCell>
                                                                    </TableRow>
                                                             
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </>}
                                            </>:''}  

                                            <fieldset className="form-group col-md-3 mt-3">
                                                       <span>Invoice/support File Upload : </span>
                                                    {/* <fieldset className="form-group col-md-3 mt-3">  */}
                                                        {!selectedFiles?
                                                        <label htmlFor="btn-upload">
                                                            <input className="btn-choose"
                                                                id="btn-upload"
                                                                name="btn-upload"
                                                                style={{ display: 'none' }}
                                                                type="file"
                                                                onChange={this.selectFile} />
                                                            <Button
                                                                className="btn-close"
                                                                variant="outlined"
                                                                type="upload"
                                                                component="span" >
                                                               <i className="fas fa-file mr-1"/> <span className="text-blue">Select File</span>
                                                            </Button>
                                                        </label>:<>
                                                        {selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
                                                        <Button
                                                        className="btn-sm btn-upload ml-2"
                                                        color="primary"
                                                        variant="contained"
                                                        component="span"
                                                        disabled={!selectedFiles}
                                                        onClick={this.upload}>
                                                         upload <i className="fas fa-upload ml-1"></i>
                                                        </Button>
                                                       </> }
                                                        </fieldset>
                                                        {/* </div> */}
                                                        <div className="form-group col-md-12"> 
                                                            <Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
                                                               <span className="text-green">{message}</span>
                                                            </Typography>
                                                        </div>

                                            <div className="form-group col-md-12"/> 
                                            {this.state.contracttype==="Recurring"?
                                             <span  as="button" className="btn btn-sm hovbuttonColour mr-2" onClick={this.onSubmit}>{this.state.loader===true?<><Spinner className="mr-1" animation="border" size="sm" />{"Saving"}</>:<><i className="fas fa-save mr-1"/>{"Save"}</>}</span>
                                            :
                                            this.state.completed === ''?
                                                <button className="btn btn-sm hovbuttonColour mr-2" type="submit" disabled><i className="fa fa-save mr-1"/>{"Save"}</button>
                                            :
                                                <button className="btn btn-sm hovbuttonColour mr-2" type="submit">  {this.state.loader===true? <><Spinner className="mr-1" animation="border" size="sm" />{"Saving"}</>:<><i className="fa fa-save mr-1"/>{"Save"}</>}</button>
                                            }
                                            <Link as="button" className="btn btn-sm deletebutton" to={{pathname: '/scn'}}><b className="text-danger"> X</b> Cancel</Link> 
                                        </>}
                                        
                                    </Form>
                                }
                            </Formik>    
                        </div> 
                    </div>
                </div>    
            </div>
        )
    }
}

export default withRouter(NewSCN)