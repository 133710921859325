// import { createTheme, ThemeProvider } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import CustomToolbar from '../../../CommonComponents/CustomToolbar'
import MUIDataTable from 'mui-datatables';
import TourServices from '../../Invoice/TourServices';
import { ThemeProvider, createTheme } from '@mui/material';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import Footer from '../../../Component/Footer';
import withRouter from '../../../withRouter';


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});


const MCarList = () => {
  const [hotel, setHotel] = useState([]);

  useEffect(() => {
    getMCarList();
  },[])

  const delteDataHandler = (e) => {
    if (confirm('Are you sure you want to Delete this thing into the database?')) {
      TourServices.deleteMCar(e).then((r) => {
        console.log(r.data);
        getMCarList();
      })
      console.log('Thing was Deleted to the database.');
    } else {
      
      console.log('Thing was not Deleted to the database.');
    }
    
  }

  const getMCarList = () => {
    TourServices.getAllMCarList().then((r) => {
      console.log(r.data);
      setHotel(r.data);

    })
  }
  const columns = [
    {
      name: "id",
      label: "ID",
      options: { filter: true, sort: true },
    },
    {
      name: "plateno",
      label: "Plate Number",
      options: { filter: true, sort: true },
    },
    {
      name: "make",
      label: "Make",
      options: { filter: true, sort: true },
    },
    {
      name: "model",
      label: "Model",
      options: { filter: true, sort: true },
    },
    {
      name: "year",
      label: "year",
      options: { filter: true, sort: true },
    },
    {
      name: "colour",
      label: "Colour",
      options: { filter: true, sort: true },
    },
    {
      name: "type",
      label: "Type",
      options: { filter: true, sort: true },
    },
    {
      name: "insuranceexpdate",
      label: "Insurance exp Date",
      options: { filter: true, sort: true },
    },
    {
      name: "regexpdate",
      label: "Registration exp. Date",
      options: { filter: true, sort: true },
    },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <button
                  as="button"
                  data-tip
                  data-for="delete"
                  className="btn btn-sm deletebutton"
                 // to={{ state: {id: tableMeta.rowData.find(e=>e) ,pagename:"Employee" }  }}
                  onClick={()=>
                delteDataHandler(tableMeta.rowData.find(e=>e))
                  }
                >Delete</button>
               <Link as="button" className="btn btn-sm hovbuttonColour mr-2"
                  to={{ pathname: "/mastercar"}} state= {{id: tableMeta.rowData.find(e=>e) ,pagename:"Edit Master Cards" }}>Edit</Link>
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    customToolbar: () => {
      return (
        <>
          (
            <Link
            as="button"
            className="float-right btn btn-sm m-0 p-0"
            to={{ pathname: "/mastercar"}} 
            state= {{id: -1,pagename:"Add Car" }}
          >
            <CustomToolbar />
          </Link>
          )
        </>
      );
    },
  };

  return (

    <>
      <Header />
      <Menu />
      <div className="content-wrapper w-100% h-100%">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
          <span
                as="button"
                className=" text-secondary cursorPointer"
                
              >
                <Link to="/masterpage" style={{color:"grey"}}>❮ Back &nbsp;&nbsp;</Link>
                
              </span>
            <li className="breadcrumb-item text-secondary">
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/masterpage">Tours & Travellers </Link>
            </li>
            <li className="breadcrumb-item active">Master Car</li>
          </ol>
          <div className='card'>
            <div className='card-body'>

              <ThemeProvider theme={theme}>
                <MUIDataTable
                  className="table-sm px-3"
                  title={"Hotels"}
                  data={hotel}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>
      <Footer />

    </>
  )
}

export default withRouter(MCarList);