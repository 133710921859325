import axios from 'axios'
import { Auth_Proto, Auth_Domain, Auth_Port } from '../Component/consts'

class ProjectManagementServices {

   GetEstimationParameters(currentTenant){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getestimationparameters/${currentTenant}`,)
   }

   AddEstimationParameters(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/addestimationparameters`,data)
   }

   DeleteEstimationParameters(id){
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/deleteestimationparameters/${id}`,)
   }

    NewProject(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/newproject`,data)
     }

     getActiveProjects(currentTenant) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getactiveprojects/${currentTenant}`,)
     }

     getCompletedProjects(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getcompletedprojects/${currentTenant}`,)
   }

   getCancelledProjects(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getcancelledprojects/${currentTenant}`,)
   }

     getProjectById(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getprojectbyid/${id}`,)
     }

     GetProjectsId(currentTenant){
       return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/findpendingprojects/${currentTenant}`,)
     }

     AddSite(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/addsite`,data)
     }

     AddSiteIncharge(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/addsiteincharge`,data)
     }

     AddClientAssign(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/addclientassign`,data)
     }

     AddProjectmanager(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/addprojectmanager`,data)
     }

     UpdateSiteStatus(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/updatesitestatus`,data)
     }
     
     getSitesofProject(id){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getsitesofproject/${id}`)
     }


   //   ------------------------------ Project Estimations --------------------------------

     NewProjectEstimation(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/newprojectestimation`,data)
     }

     GetActiveEstimations(currentTenant){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getactiveestimations/${currentTenant}`,)
     }

     GetCompletedEstimations(currentTenant){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getcompletedestimations/${currentTenant}`,)
     }

     GetProjectEstimationById(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/projectmanagement/getestimationbyid/${id}`,)
     }


}

export default new ProjectManagementServices();