import { Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant, einvoice } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { FormikTextField } from '../../CommonComponents/FormField.js';
import TransporterService from './TransporterServices';
import { Spinner } from 'react-bootstrap';
import { Backdrop, CircularProgress } from '@mui/material';
import SalesServices from '../../Sales/SalesServices.js';
import Swal from 'sweetalert2';


class Newtransporter extends Component {
  constructor(props) {
    super(props)
    this.state = {
      transid: '',
      transname:'',
      message:'',
      loader:false,
      validgstin:false
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(values) {

     let gstdata = {
      tenantId:currentTenant,
      reqparam:this.state.transid,
    }
    let data = { 
      transid: this.state.transid,
      transname:this.state.transname,
      createdby:userid,
      updatedby:userid,
      tenantid: currentTenant
  }
    if(einvoice===true){
    SalesServices.validateGSTIN(gstdata).then((response)=>{
    if(response.data.body !== null && response.data.body.statusCodeValue === 200){
      Swal.fire({
        title: "Add Transporter?",
        text: "Are you sure?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          this.setState({loader:true})
    TransporterService.addTransporter(data).then(() => {
    this.setState({loader:false})
    Swal.fire({
      title: "Added Successfully!",
      text: ".",
      icon: "success",
      confirmButtonText: "Ok",
      confirmButtonColor: "#152f5f",
    }).then(()=>this.props.navigate('/transporter'))})
    .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
        this.setState({loader:false},()=>
        Swal.fire({
          title: `${e.response.data}`,
          text: ".",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        })
        )
    })
  }})

    }else{
      this.setState({ validgstin:false,loader:false })
      Swal.fire({
        title: `Invalid Transporter ID`,
        text: ".",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      })
    }
  }).catch((e)=>{
    Swal.fire({
      title: `Service Unavailable try after some time`,
      text: ".",
      icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#152f5f",
    })
  })
} else{
  Swal.fire({
    title: "Add Transporter?",
    text: "Are you sure?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Yes",
    confirmButtonColor: "#152f5f",
    cancelButtonText: "No",
    reverseButtons: true,
  }).then((result) => {
    if (result.value) {
      this.setState({loader:true})
TransporterService.addTransporter(data).then(() => {
this.setState({loader:false})
Swal.fire({
  title: "Added Successfully!",
  text: ".",
  icon: "success",
  confirmButtonText: "Ok",
  confirmButtonColor: "#152f5f",
}).then(()=>this.props.navigate('/transporter'))})
.catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
    this.setState({loader:false},()=>
    Swal.fire({
      title: `${e.response.data}`,
      text: ".",
      icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#152f5f",
    })
    )
})
}})
}


  }

  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    
 }


  validate(values) {

    let errors = {}

     if (!this.state.transid|| this.state.transid==='') {
     errors.transid = "Transporter ID is Required";
      } else if (einvoice && !/^[A-Z0-9]{15}$/i.test(this.state.transid)) {
       errors.transid = "Invalid GSTIN should be 15 Charactors EX: 22ABCDE1234F1Z5";
      } else if (einvoice && !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z0-9]{1}/i.test(this.state.transid)) {
       errors.transid = "Invalid Ex: 22ABCDE1234F1Z5";
      }

      if (!this.state.transname|| this.state.transname==='') {
        errors.transname = "Transporter Name is Required";
         }else if (this.state.transname.length <=2){
          errors.transname = "Transporter Name  must be at least 3 characters long";
         }
    return errors;
  }

  render() {
     let {id,transid,transname} = this.state;
 
    return (
      
      <div>
      <Header />
         <Menu/>
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/transporter"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/transporter">Transporter</Link>
              </li>
              <li className="breadcrumb-item active">New Transporter</li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">New Transporter</h3>
            </div>

            <div className="card-body">
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={this.state.loader}
                >
                <CircularProgress color="inherit" />
                </Backdrop>
            
              <Formik
                initialValues={{id,transid,transname}}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}>
                {() => (
                  <Form autoComplete="off">
                    <div className="form-row">

                      <fieldset className="form-group col-md-3">
                      <FormikTextField className="form-control"  type="text" name="transname" label="Transporter Name" onChange={(e)=>this.setState({transname:e.target.value,message:''})}/>
                      </fieldset>
                      <fieldset className="form-group col-md-3">
                      <FormikTextField className="form-control"  type="text" name="transid" label="Transporter ID" onChange={(e)=>this.setState({transid:e.target.value,message:''})}/>
                      </fieldset>
                    </div>
                    <div className="form-group col-md-12 text-red">{this.state.message}</div>
                    {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>: <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fas fa-save mr-2"/>Save</button>}
                    <Link as="button" className="btn btn-sm deletebutton ml-1"  to={{pathname: '/transporter'}} ><b style={{color:"#FA5733"}}> X</b> Cancel</Link> 
                  
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

      <Footer/>
      </div>
    );
  }
}
export default withRouter(Newtransporter)