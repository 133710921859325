import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import { currency, thousandsdisplay } from "../../../Common/Credentials";
import CountUp from "react-countup";
import { Skeleton } from "@mui/material";

const SalesCards = ({ data, soloader, theme }) => {
  return (
    <div>
      <Row>
        <Col sm={6} lg={3}>
          <Link to={{ pathname: "/salesorder" }}>
            <div
              className={`stats-card purple-card mb-4 ${
                theme === false ? "" : "bg-black5"
              }`}
            >
              {soloader ? (
                <Skeleton variant="rounded" width={150} height={20} />
              ) : (
                <h3 className="text-purple">
                  <span style={{ fontSize: "13px" }}>{currency}&nbsp;</span>
                  <NumericFormat
                    className="pr-3"
                    displayType={"text"}
                    value={data.totalfySOtransaction}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    disabled={true}
                    thousandSeparator={true}
                    thousandsGroupStyle={thousandsdisplay}
                  ></NumericFormat>
                </h3>
              )}
              <p className={`${theme === false ? "text-dark" : "text-white"}`}>
                Total Sales Amount
              </p>
              <i className="fas fa-money-bill-wave"></i>
            </div>
          </Link>
        </Col>
        <Col sm={6} lg={3}>
          <Link to={{ pathname: "/salesorder" }}>
            <div
              className={`stats-card light-blue-card mb-4 ${
                theme === false ? "" : "bg-black5"
              }`}
            >
              {soloader ? (
                <Skeleton variant="rounded" width={150} height={20} />
              ) : (
                <h3>
                  <CountUp
                    start={0}
                    end={
                      data.totalsoinfyyear === undefined
                        ? 0
                        : data.totalsoinfyyear
                    }
                    duration={1.5}
                    className="text-blue"
                  />
                </h3>
              )}
              <p className={`${theme === false ? "text-dark" : "text-white"}`}>
                Total Sales
              </p>
              <i className="fas fa-shopping-cart" />
            </div>
          </Link>
        </Col>
        <Col sm={6} lg={3}>
          <Link to={{ pathname: "/salesorder" }}>
            <div
              className={`stats-card success-card mb-4 ${
                theme === false ? "" : "bg-black5"
              }`}
            >
              {soloader ? (
                <Skeleton variant="rounded" width={150} height={20} />
              ) : (
                <h3>
                  <CountUp
                    start={0}
                    end={data.confirm === undefined ? 0 : data.confirm}
                    duration={1.5}
                    className="text-success"
                  />
                </h3>
              )}
              <p className={`${theme === false ? "text-dark" : "text-white"}`}>
                Completed Sales Orders
              </p>
              <i className="fas fa-check-circle" />
            </div>
          </Link>
        </Col>
        <Col sm={6} lg={3}>
          <Link to={{ pathname: "/salesorder" }}>
            <div
              className={`stats-card danger-card mb-4 ${
                theme === false ? "" : "bg-black5"
              }`}
            >
              {soloader ? (
                <Skeleton variant="rounded" width={150} height={20} />
              ) : (
                <h3>
                  <CountUp
                    start={0}
                    end={data.pending === undefined ? 0 : data.pending}
                    duration={1.5}
                    className="text-danger"
                  />
                </h3>
              )}
              <p className={`${theme === false ? "text-dark" : "text-white"}`}>
                Active Sales Orders
              </p>
              <i className="fas fa-sync"></i>
            </div>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default SalesCards;
