import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  Stack,
  Button,
} from "@mui/material";
// import LineChartSales from "./LineChartSales";
// import ChartOrderRevenue from "./ChartOrderRevenue";
// import JumboContent from "@jumbo/components/JumboContent";
// import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import RenderSalesData from "./RenderSalesDate";
import LineChartComp from "./LineChart";
import RadialChart from "./RadialChart";
import withRouter from "../../../withRouter";
import SalesCards from "./SalesCards";
import { financialyearend, financialyearstart } from "../../../Common/Credentials";
import CustomerSalesbyYear from "../Yearly/CustomerSalesbyYear";

const Div = styled("div")({});

const StyledButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "5px 7px",
  border: "1px solid",
  letterSpacing: "1px",
  lineHeight: 1.5,
  backgroundColor: "#022533",
  borderColor: "#022533",
  color: "#fff",
  transition:
    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: "4px",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  "&:hover": {
    backgroundColor: "#022533",
    borderColor: "#022533",
    boxShadow: "none",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});

const StyledOutlineButton = styled(Button)({
  boxShadow: "none",
  textTransform: "none",
  fontSize: 14,
  padding: "5px 7px",
  border: "1px solid",
  letterSpacing: "1px",
  lineHeight: 1.5,
  backgroundColor: "transparent",
  borderColor: "#022533",
  color: "#022533",
  transition:
    "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: "4px",
  fontFamily: [
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  // '&:hover': {
  //   backgroundColor: '#022533',
  //   borderColor: '#022533',
  //   boxShadow: 'none',
  //   color:'#fff'
  // },
  // '&:active': {
  //   boxShadow: 'none',
  //   backgroundColor: '#0062cc',
  //   borderColor: '#005cbf',
  // },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
  },
});


const Item = styled("div")(({ theme }) => ({
  color: "#fff",
  marginBottom: 16,
  width: "15.5%",
  [theme.breakpoints.down("lg")]: {
    width: "16.5%",
  },
  [theme.breakpoints.down("md")]: {
    width: "33.3%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
  padding: theme.spacing(0, 2),
}));

const SalesStatistics = ({data,soloader}) => {
  const theme = useTheme();
  const [activeChart, setActiveChart] = React.useState("Invoice");
  const [ChartType, setChartType] = React.useState("YEAR");
  return (
    <>
    <SalesCards data={data} soloader={soloader} theme={false} />
      <div className="card my-2">
        <CardContent style={{ backgroundColor: "#ffdd15" }}>
          <CardHeader
            title={
              <Typography variant={"h5"} color={"#022533"} fontSize={"1rem"}>
                Sales {activeChart} Statistics
              </Typography>
            }
            action={
              <Stack direction={"row"} spacing={1}>
                {activeChart === "Invoice" ? (
                  <StyledButton size={"small"}>Invoices</StyledButton>
                ) : (
                  <StyledOutlineButton
                    disableElevation
                    size={"small"}
                    onClick={() => setActiveChart("Invoice")}
                  >
                    Invoices
                  </StyledOutlineButton>
                )}
                {activeChart === "Orders" ? (
                  <StyledButton size={"small"}>Orders</StyledButton>
                ) : (
                  <StyledOutlineButton
                    disableElevation
                    size={"small"}
                    onClick={() => setActiveChart("Orders")}
                  >
                    Orders
                  </StyledOutlineButton>
                )}
              </Stack>
            }
          />
          <RenderSalesData
            activeChart={activeChart}
            loader={soloader}
            orders={[
              {
                name: "Today",
                amount: data.todaystotalSOtransaction,
                order: data.totalsotoday,
              },
              {
                name: "This Week",
                amount: data.totalSOtransactionofweek,
                order: data.totalsothisweek,
              },
              {
                name: "This Month",
                amount: data.totalfySOtransactionofmonth,
                order: data.totalsothismonth,
              },
              {
                name: "This Year",
                amount: data.totalfySOtransaction,
                order: data.totalsoinfyyear,
              },
            ]}
            invoice={[
              {
                name: "Today",
                amount: data.todaystotalinvtransaction,
                order: data.totalinvtoday,
              },
              {
                name: "This Week",
                amount: data.totalINVtransactionofweek,
                order: data.totalinvthisweek,
              },
              {
                name: "This Month",
                amount: data.totalfyINVtransactionofmonth,
                order: data.totalinvthismonth,
              },
              {
                name: "This Year",
                amount: data.totalfyINVtransaction,
                order: data.totalinvinfyyear,
              },
            ]}
          />
        </CardContent>

        <Div>
          <Grid container>
            <Grid item xs={12} lg={6}>
              <Div
                sx={{
                  p: (theme) => theme.spacing(0, 2, 3, 2),
                }}
              >
                <CardHeader
                  title={<Typography>Order vs Invocie Amount</Typography>}
                  action={
                    <Stack direction={"row"} spacing={1}>
                      {/* {ChartType === "WEEk" ? (
                      <StyledButton size={"small"}>Week</StyledButton>
                    ) : (
                      <StyledOutlineButton
                        disableElevation
                        size={"small"}
                        onClick={() => setChartType("WEEk")}
                      >
                        Week
                      </StyledOutlineButton>
                    )} */}
                      {ChartType === "MONTH" ? (
                        <StyledButton size={"small"}>Month</StyledButton>
                      ) : (
                        <StyledOutlineButton
                          disableElevation
                          size={"small"}
                          onClick={() => setChartType("MONTH")}
                        >
                          Month
                        </StyledOutlineButton>
                      )}
                      {ChartType === "YEAR" ? (
                        <StyledButton size={"small"}>Year</StyledButton>
                      ) : (
                        <StyledOutlineButton
                          disableElevation
                          size={"small"}
                          onClick={() => setChartType("YEAR")}
                        >
                          Year
                        </StyledOutlineButton>
                      )}
                    </Stack>
                  }
                ></CardHeader>
                <LineChartComp
                  order={
                    ChartType === "YEAR"
                      ? data.yearamount
                      : ChartType === "MONTH"
                      ? data.monthamount
                      : data.weekamount
                  }
                  invoice={
                    ChartType === "YEAR"
                      ? data.invyearamount
                      : ChartType === "MONTH"
                      ? data.invmonthamount
                      : data.invweekamount
                  }
                  type={ChartType}
                  loader={soloader}
                  // year = {data.year}
                  // yearamount={data.yearamount}
                  // yearquantity={data.yearquantity}
                  // month={data.month}
                  // monthamount={data.monthamount}
                  // monthquantity={data.monthquantity}
                  // week={data.week}
                  // weekamount={data.weekamount}
                  // weekquantity={data.weekquantity}
                  // invyear={data.invyear}
                  // invyearamount={data.invyearamount}
                  // invyearquantity={data.invyearquantity}
                  // invmonth={data.invmonth}
                  // invmonthamount={data.invmonthamount}
                  // invmonthquantity={data.invmonthquantity}
                  // invweek={data.invweek}
                  // invweekamount={data.invweekamount}
                  // invweekquantity={data.invweekquantity}
                  // theme={this.state.theme}
                  // startyear={this.state.startyear}
                  // endyear={this.state.endyear}
                />
              </Div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Div
                sx={{
                  // p: (theme) => theme.spacing(3, 0, 3, 2),
                  [theme.breakpoints.up("lg")]: {
                    borderLeft: (theme) => `solid 2px ${theme.palette.divider}`,
                  },
                  [theme.breakpoints.down("lg")]: {
                    borderTop: (theme) => `solid 2px ${theme.palette.divider}`,
                  },
                }}
              >
                {/* <RadialChart
                  data={[
                    {
                      name: "This Year Invoice",
                      amount: data?.totalfyINVtransaction,
                      fill: "#2EB5C9",
                    },
                    {
                      name: "This Month Invoice",
                      amount: data?.totalfyINVtransactionofmonth,
                      fill: "#F39711",
                    },
                    {
                      name: "This Year Sales",
                      amount: data?.totalfySOtransaction,
                      fill: "#7352C7",
                    },
                    {
                      name: "This Month Sales",
                      amount: data?.totalfySOtransactionofmonth,
                      fill: "#3BD2A2",
                    },
                  ]}
                  loader={soloader}
                /> */}

                

<CustomerSalesbyYear theme={false}  start={financialyearstart} end={financialyearend}/>
              </Div>
            </Grid>
          </Grid>
        </Div>
      </div>
    </>
  );
};

export default withRouter(SalesStatistics);
