import axios from 'axios'
import { Component } from 'react';
import { Auth_Domain, Auth_Port, Auth_Proto } from '../Component/consts';
// import authHeader from './auth-header'
import Credentials, {currentUser } from '../Common/Credentials.js';



export const USER_NAME_SESSION_ATTRIBUTE_NAME =  'authoriseduser';



class AuthenticationService extends Component{


setprefix(prefix){

    axios.defaults.headers['X-TENANT-ID']=prefix
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/getclientidname/${prefix}`,{
         }

    

 )}

//  getClient(data){
//     localStorage.setItem("client", JSON.stringify(data));
//     // axios.defaults.headers['X-TenantID']=data.id;
//  }   

//  getCurrentClient(){
//     return JSON.parse(localStorage.getItem('client'))
// }


executeJwtAuthenticationService(usernameOrEmail,password,id){

        // axios.defaults.headers['Access-Control-Allow-Origin']='*'

        // axios.defaults.headers['X-TenantID']=prefix
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/auth/authenticate`,{
        usernameOrEmail,
           password,
           id
    })
    // axios.post.headers['TenantID'] = TenantID
    // axios.defaults.headers.post['Access-Control-Allow-Origin']='*'
   
    

    // return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/auth/authenticate`,{
    //     usernameOrEmail,
    //        password
       
            
       
    // })
    
}

createJwtToken(token){
    return 'Bearer ' + token
    
}
registerSuccessfullLoginForJwt(usernameOrEmail,token,data){
    sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME,usernameOrEmail)
    localStorage.setItem('token', JSON.stringify(token));
    // localStorage.setItem("user", JSON.stringify(data));
    // const authoriseduser = JSON.stringify(data);
    // this.setupAxiosInterceptors(authHeader())
    this.setupAxiosInterceptors(this.createJwtToken(token))
}


isUserLoggedIn(){
    let user = currentUser

    // let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
    // let user = JSON.stringify(this.registerSuccessfullLoginForJwt.authoriseduser.username)
    if(user===null) return false
    return true
}



getCurrentUser(){
    // return JSON.parse(localStorage.getItem('user'))
    return currentUser
}



getLoggedInUser(){
    let user =  currentUser

    //let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
    // let user = this.registerSuccessfullLoginForJwt.authoriseduser.username
    if(user===null) return ''
    return user
}



logout(id,tenantId){
    sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    // Credentials.setcurrentUser('')
    Credentials.setcurrentTenant('')
    Credentials.setuserToken('')
    Credentials.setRoles('')
    Credentials.setcurrentuserId('')

     return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/auth/logout/${id}/${tenantId}`)
}

setupAxiosInterceptors(token){
    
    axios.interceptors.request.use(
        (config) => {
            if(this.isUserLoggedIn()){
            config.headers.authorization = token
        }
            return config

        }
    )

}

GetIpAddress(){
    axios.get('https://geolocation-db.com/json/')
}

GenerateOTP(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/forgotpassword/${id}`)
}

VerifyOTP(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/validateotp`,data)
}
ChangePassword(data){
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/changepassword`,data)
}

}

export default new AuthenticationService();