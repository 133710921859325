import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import MasterService from "./MasterService.js";
import Header from "../Component/Header.js";
import Menu from "../Component/Menu.js";
import Footer from "../Component/Footer.js";
import { Backdrop, Checkbox, CircularProgress, FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import Swal from "sweetalert2";

class Viewtenants extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.location.state.data,
      adminuser: this.props.location.state.data.adminuser,
      clisettings: this.props.location.state.data.clisettings,
      productsstats: this.props.location.state.data.productsstats,
      loading: false,
      showproducts: false,
      accounts: false,
      appmaster: false,
      inventory: false,
      purchase: false,
      sales: false,
      production: false,
      assetmanagement: false,
      salestravel:false,
      dashboard: true,
      loader: false,
      prodloader:false,
    };
    this.deleteTenant = this.deleteTenant.bind(this);
  }

  handleProductChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.checked });
  };

  deleteTenant(id) {
    MasterService.deleteTenant(id)
      .then((resposne) => this.props.navigate(`/`))
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

  handleAddUser = () => {
    Swal.fire({
      title: "Add Default User of Tenant?",
      text: "Are you sure?",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({loader:true})
    MasterService.addAdminUser(this.state.data.id)
      .then(() =>{
       this.setState({ adminuser: 1,loader:false })
       Swal.fire({
        title: "Default user added successfully",
        text: ".",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      });
      })
      .catch((e) => {
        this.setState({loader:false})
        Swal.fire({
          title: "Error occurred, please try again",
          text: ".",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        });
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
    }
  }
  )
  };
  handleAddSettings = () => {
    Swal.fire({
      title: "Add Default Settings of Tenant?",
      text: "Are you sure?",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({loader:true})
        MasterService.addclientssettings(this.state.data.id)
          .then(() => {
            this.setState({ clisettings: 1, loader: false });
            Swal.fire({
              title: "Default settings added successfully",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
          })
          .catch((e) => {
            this.setState({loader:false})
            Swal.fire({
              title: "Error occurred, please try again",
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
            this.setState({ loader: false });
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });
    // MasterService.addclientssettings(this.state.data.id)
    // .then(() => this.setState({clisettings:1}))
    // .catch((e) => {
    //   if (e.response.data.error === "Unauthorized") {
    //     alert("Your Session is Lost: Please login Back to resume");
    //     this.props.navigate(`/`);
    //   }
    // });
  };

  adddefaultprefixes = () => {
    Swal.fire({
      title: "Add Default Prefixes of Tenant?",
      text: "Are you sure?",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.setState({loader:true})
        MasterService.adddefaultprefixes(this.state.data.id)
          .then(() => {
            this.setState({ prefixstatus: 1, loader: false });
            Swal.fire({
              title: "Prefixes added successfully",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
          })
          .catch((e) => {
            this.setState({ loader: false });
            Swal.fire({
              title: "Error occurred, please try again",
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });
  };

  componentDidMount(){
   MasterService.GetClientsProducts(this.props.location.state.data.id)
   .then((response) =>
   response.data.map(e=>{
    if(e.products.products ==="accounts"){this.setState({ accounts:e.productenabled === "YES"?true:false})}
    if(e.products.products ==="appmaster"){this.setState({ appmaster:e.productenabled === "YES"?true:false})}
    if(e.products.products ==="inventory")this.setState({ inventory:e.productenabled === "YES"?true:false})
    if(e.products.products ==="purchase")this.setState({ purchase:e.productenabled === "YES"?true:false})
    if(e.products.products ==="sales")this.setState({ sales:e.productenabled === "YES"?true:false})
    if(e.products.products ==="production")this.setState({ production:e.productenabled === "YES"?true:false})
    if(e.products.products ==="assetmanagement")this.setState({ assetmanagement:e.productenabled === "YES"?true:false})
    if(e.products.products ==="salestravel")this.setState({ salestravel:e.productenabled === "YES"?true:false})
  }))
  }

  handleAddproductsstats = () => {
    this.setState({prodloader:true})
    let data = {
      accounts: this.state.accounts,
      appmaster: this.state.appmaster,
      inventory: this.state.inventory,
      purchase: this.state.purchase,
      sales: this.state.sales,
      production: this.state.production,
      assetmanagement: this.state.assetmanagement,
      dashboard:this.state.dashboard,
      salestravel:this.state.salestravel,
      id: this.state.data.id,
    };
    this.state.data.productsstats === 0
      ? Swal.fire({
          title: "Add products to Tenant?",
          text: "Are you sure?",
          icon: "question",
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#152f5f",
          cancelButtonText: "No",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.setState({ loader: true });
            MasterService.addnewTenantProducts(data)
              .then(() => {
                this.setState({
                  productsstats: 1,
                  showproducts: false,
                  loader: false,
                  prodloader: false,
                });
                Swal.fire({
                  title: "Products added successfully",
                  text: ".",
                  icon: "success",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                });
              })
              .catch((e) => {
                this.setState({ loader: false,prodloader: false, });
                Swal.fire({
                  title: "Error occurred, please try again",
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                });
                if (e.response.data.error === "Unauthorized") {
                  alert("Your Session is Lost: Please login Back to resume");
                  this.props.navigate(`/`);
                }
              });
          }
        })
      : Swal.fire({
          title: "Update products to Tenant?",
          text: "Are you sure?",
          icon: "question",
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#152f5f",
          cancelButtonText: "No",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
            this.setState({ loader: true})
            MasterService.updateTenantProducts(data)
              .then(() => {
                this.setState({
                  productsstats: 1,
                  showproducts: false,
                  loader: false,
                  prodloader: false,
                });
                Swal.fire({
                  title: "Products Updated successfully",
                  text: ".",
                  icon: "success",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                });
              })
              .catch((e) => {
                this.setState({ loader: false,prodloader: false, });
                Swal.fire({
                  title: "Error occurred, please try again",
                  text: ".",
                  icon: "error",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                });
                if (e.response.data.error === "Unauthorized") {
                  alert("Your Session is Lost: Please login Back to resume");
                  this.props.navigate(`/`);
                }
              });
          }
        });
    // MasterService.updateTenantProducts(data).then(() => this.setState({productsstats:1,showproducts:false}))
    // .catch((e) => {
    //   if (e.response.data.error === "Unauthorized") {
    //     alert("Your Session is Lost: Please login Back to resume");
    //     this.props.navigate(`/`);
    //   }
    // })
  };

  render() {
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <Link as="button" className=" text-secondary" to="/tenants">
                ❮ Back &nbsp;&nbsp;
              </Link>
              <li className="breadcrumb-item text-secondary">
                <Link to="/superadminmasters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/tenants">Tenants</Link>
              </li>
              <li className="breadcrumb-item active">Tenants Detais</li>
            </ol>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

            <section className="content">
              <div className="container-fluid">
                
                    <div className="card">
                      <div className="card-body">
                      <div className="form-row">
                                <div className="form-column col-md-12">
                                  <table className="float-left mb-3">
                            <tbody>
                              <tr>
                                <td>Company</td>
                                <td>: {this.state.data.companyname}</td>
                              </tr>
                              <tr>
                                <td>Contact Name</td>
                                <td>: {this.state.data.contactname}</td>
                              </tr>
                              <tr>
                                <td>Prefix</td>
                                <td>: {this.state.data.prefix}</td>
                              </tr>
                              <tr>
                                <td>Contact Number&nbsp;&nbsp;</td>
                                <td>
                                  : +{this.state.data.phonecode}-
                                  {this.state.data.contactnumber}
                                </td>
                              </tr>
                              {this.state.data.alternatenumber ===''|| this.state.data.alternatenumber === null ? '' :
                              <tr>
                                <td>Alternate Number&nbsp;&nbsp;</td>
                                <td>
                                  : +{this.state.data.phonecode}-
                                  {this.state.data.contactnumber}
                                </td>
                              </tr>
                                }
                              <tr>
                                <td>Email</td>
                                <td>: {this.state.data.email}</td>
                              </tr>
                              <tr>
                                <td>Address</td>
                                <td>
                                  : {this.state.data.address}{" "}
                                  {this.state.data.address2}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>
                                  &nbsp;&nbsp;{this.state.data.city.name},
                                  {this.state.data.state.name}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>
                                  &nbsp;&nbsp;
                                  {this.state.data.country.name} -{" "}
                                  {this.state.data.zip}
                                </td>
                              </tr>
                              {this.state.data.country.name==="India"?<>
                              <tr>
                                <td>PAN</td>
                                <td>: {this.state.data.pan}</td>
                              </tr>
                              <tr>
                                <td>GSTIN</td>
                                <td>: {this.state.data.gstin}</td>
                              </tr>
                              </>:""}
                            </tbody>
                          </table>
                          </div>
                            {this.state.showproducts === true ? (
                              <div>
                                <FormLabel>
                                  Select Products
                                </FormLabel>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.appmaster}
                                        onChange={this.handleProductChange}
                                        name="appmaster"
                                        color="primary"
                                      />
                                    }
                                    label="AppMaster"
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.accounts}
                                        onChange={this.handleProductChange}
                                        name="accounts"
                                        color="primary"
                                      />
                                    }
                                    label="Accounts"
                                  />

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.inventory}
                                        onChange={this.handleProductChange}
                                        name="inventory"
                                        color="primary"
                                      />
                                    }
                                    label="Inventory"
                                  />

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.sales}
                                        onChange={this.handleProductChange}
                                        name="sales"
                                        color="primary"
                                      />
                                    }
                                    label="Sales"
                                  />

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.purchase}
                                        onChange={this.handleProductChange}
                                        name="purchase"
                                        color="primary"
                                      />
                                    }
                                    label="Purchase"
                                  />

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.production}
                                        onChange={this.handleProductChange}
                                        name="production"
                                        color="primary"
                                      />
                                    }
                                    label="Production"
                                  />

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.assetmanagement}
                                        onChange={this.handleProductChange}
                                        name="assetmanagement"
                                        color="primary"
                                      />
                                    }
                                    label="Asset Management"
                                  />

                                <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.salestravel}
                                        onChange={this.handleProductChange}
                                        name="salestravel"
                                        color="primary"
                                      />
                                    }
                                    label="Sales Travel"
                                  />


                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.dashboard}
                                        onChange={this.handleProductChange}
                                        name="dashboard"
                                        color="primary"
                                        disabled
                                      />
                                    }
                                    label="Dashboard"
                                  />

                                </FormGroup>
                                
                                <div>
                                <span
                                  as="button"
                                  className="btn btn-sm hovbuttonColour mr-2"
                                  onClick={this.handleAddproductsstats}
                                >
                                  Save
                                </span>
                                <span
                                  as="button"
                                  className="btn btn-sm deletebutton"
                                  onClick={() =>
                                    this.setState({ showproducts: false })
                                  }
                                >
                                  Cancel
                                </span>
                                </div>
                              </div>
                            ) : (
                              <>
                                <Link
                                  as="button"
                                  className="btn btn-sm hovbuttonColour mr-2"
                                  to={{pathname: "/newtenant"}}
                                    state= {{
                                      id: this.state.data.id,
                                      name: "Update Tenant"
                                  }}
                                >
                                  Edit
                                </Link>
                                <button
                                  as="button"
                                  className="btn btn-sm deletebutton mr-2"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        `Are you sure you wish to delete Tenant ${this.state.data.id}?`
                                      )
                                    )
                                      this.deleteTenant(this.state.data.id);
                                  }}
                                >
                                  Delete
                                </button>
                                {this.state.productsstats === 0 ? (
                                  <button
                                    as="button"
                                    className="btn btn-sm hovbuttonColour mr-2"
                                    onClick={() =>
                                      this.setState({ showproducts: true })
                                    }
                                    disabled={this.state.prodloader}
                                  >
                                    Add Products
                                  </button>
                                ) : (
                                  <button
                                    as="button"
                                    className="btn btn-sm hovbuttonColour mr-2"
                                    onClick={() =>
                                      this.setState({ showproducts: true })
                                    }
                                    disabled={this.state.prodloader}
                                  >
                                    Update Products
                                  </button>
                                )}
                                {this.state.adminuser === 0 ? (
                                  <button
                                    as="button"
                                    className="btn btn-sm hovbuttonColour mr-2"
                                    onClick={
                                      this.state.productsstats === 0
                                        ? ""
                                        : this.handleAddUser
                                    }
                                    disabled={this.state.productsstats === 0}
                                  >
                                    Add User
                                  </button>
                                ) : (
                                  ""
                                )}

                                {this.state.clisettings === 0 ? (
                                  <button
                                    as="button"
                                    className="btn btn-sm hovbuttonColour mr-2"
                                    onClick={
                                      this.state.adminuser === 0
                                        ? ""
                                        : this.handleAddSettings
                                    }
                                    disabled={this.state.adminuser === 0}
                                  >
                                    Add Settings
                                  </button>
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                            </div>
                   </div>
                    </div>
                  </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(Viewtenants);
