import React, { Component } from "react";
import "./notfound.css";
export class NotFound extends Component {
  render() {
    return (
      <div>
        <div id="notfound">
          <div className="notfound">
            <div className="notfound-404">
              <h1>:(</h1>
            </div>
            <h2>404 - Page not found</h2>
            <p>
              The page you are looking for might have been removed or its name
              has been changed or is temporarily unavailable.
            </p>
            <a href="/">
              <i className="fas fa-home mr-1"></i>home
            </a>
            <div className="notfound-social">
              {/* <a href="#"> <i className="fab fa-facebook-f"></i></a> */}
              <a href="#">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#">
                <i className="fab fa-linkedin-in"></i>
              </a>
              <a href="#">
                <i className="fab fa-google-plus-g"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NotFound;
