import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import { currentTenant, userid } from "../../Common/Credentials.js";
import { spin } from "../../Common/NewLoader.jsx";
import { appmasterid } from "../../Common/Permissions.js";
import CustomToolbar from "../../CommonComponents/CustomToolbar.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import WorkCenterServices from "./WorkCenterServices.js";

import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class WorkCenterDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      workcenter: [],
      loading: false,
    };
    // this.refreshCustomer=this.refreshCustomer.bind(this)
    this.deleteWorkCenter = this.deleteWorkCenter.bind(this);
  }

  deleteWorkCenter(id) {
    WorkCenterServices.deleteWorkCenter(id)
      .then(() => {
        this.setState({
          message: "WorkCenter Deleted Successfully",
          loading: true,
        });
        this.refreshWorkCenter();
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

  refreshWorkCenter() {
    WorkCenterServices.getWorkCenter(currentTenant)
      .then((response) => {
        this.setState({ workcenter: response.data });
        this.setState({ loading: false });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    //   WarehouseServices.getWarehouses(currentTenant)
    //   .then(
    //     response => {
    //         this.setState({WorkCenter:response.data})
    //       this.setState({loading:false})
    //       }
    //     )
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    this.setState({ loading: true });

    this.refreshWorkCenter();
  }

  render() {
    const columns = [
      {
        name: "wcid",
        label: "Id",
        options: { filter: true, sort: true, sortOrder: "asc" },
      },
      {
        name: "name",
        label: "WorkCenter/Production Unit",
        options: { filter: true, sort: true },
      },
      {
        name: "whname",
        label: "Org unit",
        options: { filter: true, sort: true },
      },
      {
        name: "Delete",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Link
                as="button"
                data-tip
                data-for="delete"
                className="fa fa-trash text-danger"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you wish to delete WorkCenter ${tableMeta.rowData.find(
                        (e) => e
                      )}?`
                    )
                  )
                    this.deleteWorkCenter(tableMeta.rowData.find((e) => e));
                }}
              ></Link>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{
                  pathname: "/newworkcenter",
                  state: { id: -1, name: "New WorkCenter" },
                }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };
    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>

              <li className="breadcrumb-item active">WorkCenter/Production Unit</li>
            </ol>
            {/* <div className="text-center text-success text-uppercase">{this.state.message}</div> */}
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    {/* /.card */}
                    <div className="card">
                      <div className="card-body">
                        <div>
                          {this.state.loading === true ? (
                            <div className="text-center">{spin}</div>
                          ) : (
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"WorkCenter/Production Unit"}
                                data={this.state.workcenter}
                                columns={columns}
                                options={options}
                              />
                            </ThemeProvider>
                          )}
                        </div>
                      </div>
                      {/* /.card-body */}
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(WorkCenterDataTable);
