import jsPDF from "jspdf";
import React, { Component } from "react";
import withRouter from "../../withRouter.js";
import { Link } from "react-router-dom";
import Header from "../../Component/Header";

// const getTotal = (data) => {
//   let total = 0;
//   data.forEach(data => {
//     total += data;
//   });
//   return total;
// };

// let selected = 0
class Twotable extends Component {
  //   state = {
  //     // this is your table represented as a 2D array
  //     // pl: [{id:"Expencives",a:100000 },{id:"Purchases",a:100 },{id:3,a:100 },{id:4,a:500}]

  //   }
  constructor() {
    super();
    this.state = {
         show:false,
         selected:0,
    }
  }

  handleshow=()=>{
    this.setState({show:!this.state.show,selected:1})
  }


  pdf() {
    var doc = new jsPDF();
    doc.autoTable({
      html: "#table",
      didDrawCell: function (data) {
        if (data.column.dataKey === 0 && data.cell.section === "body") {
          doc.autoTable({
            html: "#table2",
            startY: data.cell.y + 2,
            margin: { left: data.cell.x + data.cell.padding("left") },
            tableWidth: 80,
            theme: "grid",
            styles: {
              fontSize: 7,
              cellPadding: 1,
            },
          });
        }
        if (data.column.dataKey === 1 && data.cell.section === "body") {
          doc.autoTable({
            html: "#table3",
            startY: data.cell.y + 2,
            margin: { left: data.cell.x + data.cell.padding("left") },
            tableWidth: 80,
            theme: "grid",
            styles: {
              fontSize: 7,
              cellPadding: 1,
            },
          });
        }
      },

      columnStyles: {
        5: { cellWidth: 40 },
      },
      bodyStyles: {
        minCellHeight: 30,
        maxCellHeight: "auto",
      },
    });
    doc.save("table.pdf");
  }

  render() {
    // const headers = this.state.pl.slice(0, 1)[0]
    // const rows = this.state.pl.slice(1)
    // const data=rows.map(row=>row.a)
    return (
      <div>

<Header/>
  <div style={{height:'90%',position:'fixed',zIndex:'1'}}>
        <aside className="main-sidebar sidebar-light-gray elevation-4">
          <div className="sidebar">
         
            {/* Sidebar user panel (optional) */}
            {/* <a href="index3.html" className="brand-link"> */} 
           
             
            {/* <span className="brand-text font-weight-light">{logo}</span> */}
          {/* </a> */}
            {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="text-white ">
                <p className="text-white text-uppercase justify-content-center">
                  {currentUser}
                </p>
                <p className="text-white text-uppercase justify-content-center">
                  {role}
                </p>
              </div>
            </div> */}
            {/* Sidebar Menu */}
            
           {/* <Nav className="ml-auto mt-2"> */}
            <nav className="mt-2 ml-auto">
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false"
              >
               <li className="nav-item active">
                  <Link to="/index" className="text-dark nav-link ">
                    <i className="text-dark nav-icon fas fa-tachometer-alt textColour" />
                    <p>Dashboard</p>
                  </Link>
                </li>
                {/* -------------------Masters Section --------------------------------- */}
               
                    
                  <li className={`nav-item active ${this.state.selected === 1?'menuselected':''}`}>
                    <div className="nav-link text-dark" onClick={this.handleshow}>
                      <i className="nav-icon fas fas fa-th textColour" />
                      <p> 
                        Masters
                        {this.state.show === false?
                        <i className="right fas fa-angle-left" />
                        :
                        <i className="right fas fa-angle-down" />
                        }
                      </p>
                    </div>
                    
                    {/* <ul className="nav nav-treeview"> */}
                    {this.state.show === false?'':<>
                      <li className=" nav-item  has-treeview ">
                        <div className="  nav-link text-dark">
                        <i className="fas fa-user-cog mr-2 textColour" />
                          <p>Admin Masters
                            <i className="right fas fa-angle-left" />
                          </p>
                        </div>
                        <ul className="nav nav-treeview">
                          <li className="nav-item bg-info">
                            <div className="nav-link">
                              <i className=" far fa-circle nav-icon text-sm mr-2" />
                              <Link to="/users" className="text-dark">
                                <p>Users</p>
                              </Link>
                            </div>
                          </li>
                          <li className="nav-item has-treeview ">
                            <div className="nav-link">
                              <i className=" far fa-circle nav-icon text-sm mr-2" />
                              <Link to="/roles" className="text-dark">
                                <p>Roles</p>
                              </Link>
                            </div>
                          </li>
                          <li className="nav-item has-treeview ">
                            <div className="nav-link">
                              <i className=" far fa-circle nav-icon text-sm mr-2" />
                              <Link to="/salescaltable" className="text-dark">
                                <p>Sales Calculation</p>
                              </Link>
                            </div>
                          </li>
                          <li className="nav-item has-treeview ">
                            <div className="nav-link">
                              <i className=" far fa-circle nav-icon text-sm mr-2" />
                              <Link to="/customerterms" className="text-dark">
                                <p>Customer Terms</p>
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </li>
                     
                      <li className=" nav-item has-treeview ">
                        <div className="  nav-link text-dark">
                          <i className=" fas fa-money-bill-alt mr-2 textColour" />
                          <p>
                            Accounts Masters
                            <i className="right fas fa-angle-left" />
                          </p>
                        </div>
                        <ul className="nav nav-treeview">
                          <li className="nav-item">
                            <div className="nav-link">
                              <i className=" far fa-circle nav-icon text-sm mr-2" />
                              <Link to={{pathname:"/subacc"}} state ={{message:''}} className="text-dark">
                                <p>Ledger Group</p>
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </li>
                     
                      <li className=" nav-item has-treeview ">
                        <div className="  nav-link text-dark">
                          <i className=" fas fa-th mr-2 textColour" />
                          <p>
                          General Masters
                            <i className="right fas fa-angle-left" />
                          </p>
                        </div>
                        <ul className="nav nav-treeview">
                          <li className="nav-item">
                            <div className="nav-link">
                              <i className=" far fa-circle nav-icon text-sm mr-2" />
                              <Link to="/customers" className="text-dark">
                                <p>Customers</p>
                              </Link>
                            </div>
                          </li>
                          <li className="nav-item">
                            <div className="nav-link">
                              <i className=" far fa-circle nav-icon text-sm mr-2" />
                              <Link
                                to="/suppliers"
                                className="text-dark"
                              >
                                <p>Suppliers</p>
                              </Link>
                            </div>
                          </li>
                          <li className="nav-item">
                            <div className="nav-link">
                              <i className=" far fa-circle nav-icon text-sm mr-2" />
                              <Link
                                to="/warehouse"
                                className="text-dark"
                              >
                                <p>Warehouse</p>
                              </Link>
                            </div>
                          </li>
                          <li className="nav-item">
                            <div className="nav-link">
                              <i className=" far fa-circle nav-icon text-sm mr-2" />
                              <Link
                                to="/department"
                                className="text-dark"
                              >
                                <p>Department</p>
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </li>
                     
                      <li className=" nav-item has-treeview ">
                        <div className="  nav-link text-dark">
                          <i className=" fas fas fa-boxes mr-2 textColour" />
                          <p>
                            Inventory Masters
                            <i className="right fas fa-angle-left" />
                          </p>
                        </div>
                        <ul className="nav nav-treeview">
                          <li className="nav-item">
                            <div className="nav-link">
                              <i className=" far fa-circle nav-icon text-sm mr-2" />
                              <Link to="/category" className="text-dark">
                                <p>Category</p>
                              </Link>
                            </div>
                          </li>
                          <li className="nav-item">
                            <div className="nav-link">
                              <i className=" far fa-circle nav-icon text-sm mr-2" />
                              <Link
                                to="/size"
                                className="text-dark"
                              >
                                <p>
                                 Size
                                </p>
                              </Link>
                            </div>
                          </li>
                          <li className="nav-item">
                            <div className="nav-link">
                              <i className=" far fa-circle nav-icon text-sm mr-2" />
                              <Link
                                to="/colour"
                                className="text-dark"
                              >
                                <p>
                                 Colour
                                </p>
                              </Link>
                            </div>
                          </li>
                          <li className="nav-item">
                            <div className="nav-link">
                              <i className=" far fa-circle nav-icon text-sm mr-2" />
                              <Link
                                to="/uom"
                                className="text-dark"
                              >
                                <p>
                                 UOM
                                </p>
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </li>
                     </>}
                      
                    {/* </ul> */}
                  </li>
                

               
                {/* -------------------End Masters Section --------------------------------- */}
                

                {/* -------------------Tenant Section --------------------------------- */}
                
                <li className={`nav-item has-treeview  ${this.state.selected === 2?'menuselected':''}`}  onClick={()=>this.setState({selected:2})}>
                  <div className=" nav-link text-dark">
                    <i className=" nav-icon fas fa-table textColour" />
                    <p className=" ">
                      Tenants
                      <i className=" right fas fa-angle-left" />
                    </p>
                  </div>
                  <ul className=" nav nav-treeview">
                    <li className=" nav-item">
                      <div className=" nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/tenants" className="text-dark">
                          <p className=" ">Tenants</p>
                        </Link>
                      </div>
                    </li>
                    <li className=" nav-item">
                      <div className=" nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/screens" className="text-dark">
                          <p className=" ">Screens</p>
                        </Link>
                      </div>
                    </li>
                    <li className=" nav-item">
                      <div className=" nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/products" className="text-dark">
                          <p className=" ">Products</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </li>
              
                {/* --------------------End Tenant Section ------------------------------ */}

                {/* -------------------Accounts Section --------------------------------- */}
                <>
                <li className=" nav-item has-treeview ">
                  <div className="  nav-link text-dark">
                    <i className=" nav-icon fas fa-money-bill-alt textColour" />
                    <p>
                      Accounts
                      <i className="right fas fa-angle-left" />
                    </p>
                  </div>
                  <ul className="nav nav-treeview">
                 
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/newledgers" className="text-dark">
                          <p >Create Ledger</p>
                        </Link>
                      </div>
                    </li>
                   
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/journals" className="text-dark">
                          <p >Journal Entry</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </li>
                
                </>
               
                {/* --------------------End Accounts Section ------------------------------ */}

                 {/* -------------------Inventory Section --------------------------------- */}
                 <li className=" nav-item has-treeview ">
                  <div className="  nav-link text-dark">
                    <i className=" nav-icon fas fa-boxes textColour" />
                    <p>
                      Inventory
                      <i className="right fas fa-angle-left" />
                    </p>
                  </div>
                
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/items" className="text-dark">
                          <p >Items / Products</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                 
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/services" className="text-dark">
                          <p >Services</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                 
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/cost" className="text-dark">
                          <p >Price</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/stocks" className="text-dark">
                          <p >Stock</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                  
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/stockissue" className="text-dark">
                          <p >Stock Issue</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                 
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/damage" className="text-dark">
                          <p >Damage</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                  
               
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/depreciation" className="text-dark">
                          <p>Depreciation</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </li>
              
                 {/* ---------------------- End of Inventory Section ----------------- */}
               
                {/* -------------------Sales Section --------------------------------- */}
                
                <li className=" nav-item has-treeview ">
                  <div className="  nav-link text-dark">
                    <i className=" nav-icon fab fa-opencart textColour" />
                    <p>
                      Sales
                      <i className="right fas fa-angle-left" />
                    </p>
                  </div>
                 
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2"  />
                        <Link to="/salesorder" className="text-dark">
                          <p >Sale Order</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2"/>
                           <Link to="/deliverynote" className="text-dark">
                          <p >Delivery / Shipment Note</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
               
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2"  />
                        <Link to="/salesinvoice" className="text-dark">
                          <p >Invoice</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                 
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2"  />
                        <Link to="/salesreturns" className="text-dark">
                          <p >Sales Returns</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                 
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2"/>
                           <Link to="/salesreceipts" className="text-dark">
                          <p >Receipts</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </li>
               
              
                 {/*--------------------End of Sales Section -----------------------*/}
                  {/* ----------------------- POS -------------------------------------- */}
                
                {/* {roles === 'ROLE_SUPERADMIN'? */}
              
                <li className=" nav-item has-treeview ">
                  <div className=" nav-link text-dark">
                    <i className=" nav-icon fas fa-cash-register textColour" />
                    <p className=" ">
                      Point of Sale
                      <i className=" right fas fa-angle-left" />
                    </p>
                  </div>
                 
                  <ul className=" nav nav-treeview">
                    <li className=" nav-item">
                      <div className=" nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/pointofsale" className="text-dark">
                          <p className=" ">Point of Sale</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                  
                  <ul className=" nav nav-treeview">
                    <li className=" nav-item">
                      <div className=" nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/acceptingstockissuetable" className="text-dark">
                          <p className=" ">Stock Acceptance</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                 
                </li>
               
                {/* :''} */}
                 {/* ----------------------- End POS ----------------------------------- */}

                {/* --------------------Purchase Section ------------------------------ */}
                 
                <li className=" nav-item has-treeview ">
                  <div className="  nav-link text-dark">
                    <i className=" nav-icon fas fa-cart-arrow-down textColour" />
                    <p>
                      Purchase
                      <i className="right fas fa-angle-left"/>
                    </p>
                  </div>
                
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/purchaseorder" className="text-dark">
                          <p >Purchase</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                 
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/purchasereturns" className="text-dark">
                          <p >Purchase Returns</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/grn" className="text-dark">
                          <p >GRN</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/scn" className="text-dark">
                          <p >SCN</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </li>
               
              
                 {/* -------------------------- End of Purchase Section-------------------*/}
                  {/* ---------------------Reports------------------ */}
 
                <li className=" nav-item has-treeview ">
                  <div className="  nav-link text-dark">
                    <i className=" nav-icon fas fa-file-alt textColour" />
                    <p>
                      Reports
                      <i className="right fas fa-angle-left" />
                    </p>
                  </div>
                  <ul className="nav nav-treeview">
               
                    
                  <li className=" nav-item has-treeview ">
                  <div className="  nav-link text-dark">
                    <i className=" nav-icon" />
                    <p>
                      Accounts
                      <i className="right fas fa-angle-left" />
                    </p>
                  </div>
                  <ul className="nav nav-treeview">
                    
                  <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/trialbalance" className="text-dark">
                          <p >Trial Balance</p>
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/pandl" className="text-dark">
                          <p >P&L</p>
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/balancesheet" className="text-dark">
                          <p>Balance Sheet</p>
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/ledgerview" className="text-dark">
                          <p >Ledgers View</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </li>  
               


                <li className=" nav-item has-treeview ">
                  <div className="  nav-link text-dark">
                    <i className=" nav-icon" />
                    <p>
                      Inventory
                      <i className="right fas fa-angle-left" />
                    </p>
                  </div>
                  <ul className="nav nav-treeview">
                    
                  <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/inventoryreport" className="text-dark">
                          <p>Inventory Report</p>
                        </Link>
                      </div>
                    </li>
                    
                  <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/stockreport" className="text-dark">
                          <p >Stock Report</p>
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/damagereport" className="text-dark">
                          <p>Damage Report</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </li>  
               

               
                <li className=" nav-item has-treeview ">
                  <div className="  nav-link text-dark">
                    <i className=" nav-icon" />
                    <p>
                      Sales
                      <i className="right fas fa-angle-left" />
                    </p>
                  </div>
                  <ul className="nav nav-treeview">
                    
                  <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/salesreport" className="text-dark">
                          <p >Sales Report</p>
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/salesreturnsreport" className="text-dark">
                          <p>Sales Returns Report</p>
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2"/>
                           <Link to="/overdues" className="text-dark">
                          <p >Overdues</p>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </li> 
              

               
                <li className=" nav-item has-treeview ">
                  <div className="  nav-link text-dark">
                    <i className=" nav-icon" />
                    <p>
                      Purchase
                      <i className="right fas fa-angle-left" />
                    </p>
                  </div>
                  <ul className="nav nav-treeview">
                    
                  <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/purchasereport" className="text-dark">
                          <p >Purchase Report</p>
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm mr-2" />
                        <Link to="/purchasereturnsreport" className="text-dark">
                          <p >Purchase Returns Report</p>
                        </Link>
                      </div>
                    </li>
                    {/* <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-circle nav-icon text-sm" />
                        <Link to="/balancesheet" className="text-dark">
                          <p>Balance Sheet</p>
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="nav-link">
                        <i className=" far fa-eye nav-icon" />
                        <Link to="/ledgerview" className="text-dark">
                          <p >Ledgers View</p>
                        </Link>
                      </div>
                    </li> */}
                  </ul>
                </li>  
               

                  </ul>
                </li>  
                  {/* ---------------------End of Reports------------------ */}

              </ul>
            </nav>
            {/* /.sidebar-menu */}
          </div>
          {/* /.sidebar */}
          <div className="myColour py-2 text-center text-white shadow-sm" style={{fontSize:'20px',backgroundColor:'#263571'}}> 
                  <b>Finanzz</b>
                  </div>
       
        </aside>
      </div>
        <table id="table3" hidden>
          <thead>
            <tr>
              <th>ID</th>
              <th>First name</th>
              <th>Last name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Donna</td>
              <td>Moore</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Janice</td>
              <td>Henry</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Ruth</td>
              <td>Wells</td>
            </tr>
            <tr>
              <td>4</td>
              <td>Jason</td>
              <td>Ray</td>
            </tr>
            <tr>
              <td>5</td>
              <td>Jane</td>
              <td>Stephens</td>
            </tr>
            <tr>
              <td>6</td>
              <td>Adam</td>
              <td>Nichols</td>
            </tr>
          </tbody>
        </table>

        <table id="table2" hidden>
          <thead>
            <tr>
              <th>ID</th>
              <th>First name</th>
              <th>Last name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>Donna</td>
              <td>Moore</td>
            </tr>
            <tr>
              <td>2</td>
              <td>Janice</td>
              <td>Henry</td>
            </tr>
            <tr>
              <td>3</td>
              <td>Ruth</td>
              <td>Wells</td>
            </tr>
          </tbody>
        </table>

        <table id="table" hidden>
          <thead>
            <tr>
              <th>Table</th>
              <th>Table2</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>

        {/* <button
          className="btn btn-sm bg-white shadow-sm float-right "
          onClick={() => this.pdf()}
        >
          {" "}
          <i className="fas fa-download text-blue" />
          Pdf
        </button> */}
      </div>
    );
  }
}

export default withRouter(Twotable);
