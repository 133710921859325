import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { currentTenant, thousandsdisplay, userid } from "../../Common/Credentials";
// import moment from "moment";
import { spin } from "../../Common/NewLoader";
import { salesid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";
// import { FormControlLabel } from "@mui/material";
// import IOSSwitch from "../../CommonComponents/IOSSwitch";
import {NumericFormat} from "react-number-format";
import moment from "moment";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import PosService from "./PosService";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class POSTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saleOrder: [],
      loading: false,
      salestype:false,
      service:[],
      completedso:[],
      cancelledso:[],
      so:true,
      comp:false,
      canl:false,
    };
  }

  handlesoChange=()=>{
    this.setState({so:true,comp:false,canl:false})
  }
  handlecompChange=()=>{
    this.setState({comp:true,so:false,canl:false})
  }
  handlecanlChange=()=>{
    this.setState({canl:true,so:false,comp:false})
  }


  refreshSalesorder() {
    PosService.GetPosoftenant(currentTenant).then((response) => {
      this.setState({ saleOrder: response.data });
      this.setState({ loading: false });
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }


  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.refreshSalesorder();
  }

  render() {

    const columns = [
      {name: "id",label: "Saleorder Id",options: {filter: false,sort: false,display:false,viewColumn:false}},
      // {name: "custid",label:"Customer Id",options: {filter: true,sort: true,}},
      {name: "customer",label: "Customer",options: {filter: true,sort: true,}},
      {name: "contactno",label: "Contact no.",options: {filter: true,sort: true,}},
      {name: "createdAt",label: "Date",options: {filter: false,sort: true, 
      customBodyRender:(tableMeta)=>{
        return(moment(tableMeta).format('DD-MM-YYYY hh:mm'))
      }}},
      {name: "totalinctax",label: "Total Amount",options: {filter: false,sort: true,
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      {name: "paymentmode",label: "Payment Mode",options: {filter: true,sort: true,}},
      {
        name: "View",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
          return (<>
            <Link as="button" data-tip data-for="view details" className="fa fa-list-alt" 
            to={{pathname: "/posdetails"}} state ={this.state.saleOrder.find(i=>i.id===tableMeta.rowData.find(e=>e))}></Link>
            </>
          );
          }
        }
      },
    ];
    const options = {
      filterType: 'checkbox',
      selectableRows: "none",
      customToolbar: () => {
      return (<>
        {salesid === '2' || salesid === '3'?
      <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/newpointofsale"}}>
        <CustomToolbar />
      </Link>
      :''
        }
      </>
      );
    },
    }

    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>

              <li className="breadcrumb-item active">Point of Sale</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Point of Sale</h3>
                      </div>

                      <div className="card-body">
                        {this.state.loading === true ?<span className="text-center">{spin}</span> :<>
                        <ThemeProvider theme={theme}> 
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Point of Sale"}
                                data={this.state.saleOrder}
                                columns={columns}
                                options={options}
                              />
                              </ThemeProvider>
                            </>
                            }
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
            <Footer />
      </div>
    );
  }
}
export default withRouter(POSTable);
