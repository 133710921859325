import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { spin } from "../../Common/NewLoader";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import ProductionServices from "../ProductionServices";
import { currentTenant, prefix1, userid } from "../../Common/Credentials";
import { Autocomplete } from "@mui/material";
import moment from "moment";
import Footer from "../../Component/Footer";
import { Form, Formik } from "formik";
import FileServices from "../../Common/upload/FileServices";
import UploadFilesService from "../../Common/upload/UploadFilesService";
import { Spinner } from "react-bootstrap";


let curmonth, year, day, path1;
let doctype = "QA"

const NewQA=()=> {
  const navigate = useNavigate();
  const [loader,setLoader] = useState(false)
  const [joborder, setJoborder] = useState([]);
  const [joid, setJoId] = useState("");
  const [inprocessIdList, setInprocessIdList] = useState([]);
  const [inprocessid,setInprocessId] = useState("");
  const [inprocessitems,setInprocessItems]= useState([]);
  const [itemname, setItemName] = React.useState("");
  const [itemid, setItemId] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [iquantity, setIQuantity] = React.useState("");
  const [report,setReport]  = React.useState("");
  const [qualityanalysisitems] = React.useState([]);
  const [selectedFiles,setSelectedFiles] = React.useState(undefined);
  const [isError,setIsError] = React.useState(false);
  const [fileInfos,setFileInfos] = React.useState([]);
  const [partyId,setPartyId] = React.useState("");
  const [message,setMessage] = React.useState("");
  const [file] = React.useState("");

  useEffect(() => {
    ProductionServices.getallJOidforQA(currentTenant).then((response) => {
      setJoborder(response.data);
    });
    year = new Date().getFullYear();
    day = new Date().getDate();
    let mn = new Date().getMonth() + 1;
    curmonth = moment(mn, "M").format("MMMM");

  }, [joid]);

  const handlejoidChange = (newValue) => {
    newValue === "" || newValue === null || newValue === undefined
      ? setJoId("")
      : setJoId(newValue);
      setInprocessIdList([]);
    if (newValue) {
      ProductionServices.getInprocessIdforQA(newValue).then((response) => {
        setInprocessIdList(response.data);
      });
    }
  };

  const handleInprocessIdChange =  (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setInprocessId("")
    }else{
      setInprocessId(newValue)
      ProductionServices.getOutputItemsbyId(newValue).then(response=>{
        setInprocessItems(response.data.outputitems)
      }
        )
    }
  }

  const handleIndentItem = (e)=>{
    setItemName(e.itemname)
    setItemId(e.itemid)
    setQuantity(e.pendingquantity)
  }

  const handleIquantityChange = e=>{
    setIQuantity(e.target.value)
  }

  const handleReportChange = e =>{
    setReport(e.target.value)
  }

  const selectFile = (event) => {
    setSelectedFiles(event.target.files)
    setPartyId(joid);
  };

  const upload=()=> {
    let currentFile = selectedFiles[0];

    UploadFilesService.upload(
      currentFile,
      year,
      curmonth,
      doctype,
      partyId,
      day,
      // (event) => {
      //   this.setState({
      //     progress: Math.round((100 * event.loaded) / event.total),
      //   });
      //   progress = Math.round((100 * event.loaded) / event.total);
      // }
    )
      .then((response) => {
          setMessage( response.data.message)
          setIsError(false)
        return FileServices.getFiles1(currentFile.name);
      })
      .then((files) => {
       setFileInfos(files.data);
      })

      .catch(() => {
        setMessage( "Could not upload the file!")
        setIsError(true)
      });

      setSelectedFiles(undefined)
  }

  const handleAddtoList =() =>{
   if(itemid !== "" && itemname !== "" && iquantity !== "" && parseFloat(iquantity)>0 && parseFloat(iquantity)<=quantity ){
    if(qualityanalysisitems.find(e=>e.itemid === itemid) === undefined){   
     let data = {
      itemid:itemid,
      itemname:itemname,
      quantity:parseFloat(iquantity),
      report:report,
    }
    qualityanalysisitems.push(data)
  }else(
    qualityanalysisitems.find(e=>e.itemid === itemid).quantity = qualityanalysisitems.find(e=>e.itemid === itemid).quantity + parseFloat(iquantity) 
  )
    inprocessitems.find(a=>a.itemid === itemid).pendingquantity = inprocessitems.find(a=>a.itemid === itemid).pendingquantity - parseFloat(iquantity)
    setItemId("")
    setItemName("")
    setIQuantity(0)
    setQuantity(0)
  }
}

  const handleSubmit = () => {
    let filedata = fileInfos.map((e) => e.name).toLocaleString();
    path1 = prefix1 +"/" +doctype +"/" +partyId +"/" +year +"/" +curmonth +"/" +day;
    if (filedata === "" && file === "") {
      setMessage("Please Upload File");
      setIsError(true)
    } else {
      setLoader(true)
    let data = {
      joid: joid,
      inprocess:inprocessid,
      tenantid: currentTenant,
      createdby: userid,
      updatedby: userid,
      qualityanalysisitems:qualityanalysisitems,
      path:path1,
      file:filedata
      
    };
    ProductionServices.NewQA(data).then(()=>
      navigate("/qa")
    ).catch(()=>{
      setLoader(false)
    })
  };
  }

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary cursorPointer"
                to={{ pathname: "/qa" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li> 
            <li className="breadcrumb-item text-secondary">
              <Link to="/production">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/qa">Quality Analysis</Link>
            </li>
            <li className="breadcrumb-item active">New Quality Analysis</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              {joborder === [] ? (
                <div className="text-center">{spin}</div>
              ) : (
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title"> New Quality Analysis</h3>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={{}}
                      // onSubmit={onSubmit}
                      validateOnChange={false}
                      // validate={validate}
                      enableReinitialize={true}
                    >
                      {({ setFieldValue, values }) => (
                        <Form
                          autoComplete="off"
                          style={{ paddingLeft: "30px" }}
                        >
                          <div className="form-row mt-3">
                            <fieldset className="col-md-3">
                              <Autocomplete
                                id="controlled-demo"
                                options={joborder}
                                getOptionLabel={(option) => option || ""}
                                
                                
                                value={joid}
                                onChange={(event, newValue) =>
                                  handlejoidChange(newValue)
                                }
                                error={joid === "" || joid === undefined}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select JobOrder Id"
                                    variant="standard"
                                    error={joid === "" || joid === undefined}
                                    helperText={
                                      joid === "" || joid === undefined
                                        ? "Empty field!"
                                        : " "
                                    }
                                  />
                                )}
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-3">
                              <Autocomplete
                                id="subaccount"
                                className="subacc-select"
                                name="inprocess"
                                options={inprocessIdList}
                                getOptionLabel={(option) => option}
                                
                                
                                value={values.store ? undefined : undefined}
                                onChange={handleInprocessIdChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="form-control"
                                    type="text"
                                    name="inprocess"
                                    value={inprocessid}
                                    label={`Select Inprocess Id (${inprocessid})`}
                                    variant="standard"
                                    error={inprocessid === ""}
                                    helperText={
                                      inprocessid === "" ? "Empty field!" : " "
                                    }
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>
                         

                          {inprocessitems.length === 0 ? (
                            ""
                          ) : (
                            <>
                              <TableContainer>
                                <Typography> Items List</Typography>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="text-center">
                                        Itemname
                                      </TableCell>
                                      <TableCell className="text-center">
                                       Estimated Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                       Pending Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {inprocessitems.map((e, index) => (
                                      <TableRow key={index}>
                                        <TableCell className="text-center">
                                          {e.itemname}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.quantity}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.pendingquantity}
                                        </TableCell>
                                        <TableCell className="text-center">
                                         {e.quantity === 0 ? '':<button className="btn btn-sm hovbuttonColour" onClick={()=>handleIndentItem(e)}>
                                            Output 
                                            </button>
                                          }
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              </>
                          )}
                      
                              {itemname === ""?'':<>
                              <fieldset className="col-md-3 mt-3">
                              <TextField
                                    className="form-control"
                                    type="text"
                                    name="itemname"
                                    value={itemname}
                                    label={`Item Name (${itemname})`}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                  />
                              </fieldset>

                              <fieldset className="col-md-3 mt-3">
                              <TextField
                                    className="form-control"
                                    type="text"
                                    name="quantity"
                                    value={iquantity}
                                    label={`Output Quantity (Pending quantity: ${quantity})`}
                                    onChange={handleIquantityChange}
                                    variant="standard"
                                    error={iquantity === "" || iquantity>quantity}
                                    helperText={
                                      iquantity === "" ? "Empty field!" : " "
                                    }
                                    fullWidth
                                  />
                              </fieldset>

                              <fieldset className="col-md-3 mt-3">
                              <TextField
                                    className="form-control"
                                    type="text"
                                    name="report"
                                    value={report}
                                    label="Report"
                                    onChange={handleReportChange}
                                    variant="standard"
                                    error={report===""}
                                    helperText={
                                      report === "" ? "Empty field!" : " "
                                    }
                                    fullWidth
                                  />
                              </fieldset>
                              
                              <fieldset className="col-md-12 mt-3">
                                <button
                                  className="btn btn-sm hovbuttonColour"
                                  onClick={handleAddtoList}
                                  type="button"
                                >
                                  Add to List
                                </button>
                              </fieldset>
                              </>}
                             {qualityanalysisitems.length ===0?'':<>
                              <TableContainer className="mt-4">
                                <Typography>QA Items List</Typography>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="text-center">
                                        Itemname
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Report
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {qualityanalysisitems.map((e, index) => (
                                      <TableRow key={index}>
                                        <TableCell className="text-center">
                                          {e.itemname}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.quantity}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.report}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>


                              <fieldset className="form-group col-md-12 mt-3">
                            <span>File upload :&nbsp;&nbsp;</span>
                            {!selectedFiles ? (
                              <label htmlFor="btn-upload">
                                <input
                                  className="btn-choose"
                                  id="btn-upload"
                                  name="btn-upload"
                                  style={{ display: "none" }}
                                  type="file"
                                  onChange={selectFile}
                                />
                                <Button
                                  className="btn-close"
                                  variant="outlined"
                                  type="upload"
                                  component="span"
                                >
                                  <i className="fas fa-file mr-1" />{" "}
                                  <span className="text-blue">Select File</span>
                                </Button>
                              </label>
                            ) : (
                              <>
                                {selectedFiles && selectedFiles.length > 0
                                  ? selectedFiles[0].name
                                  : null}
                                <Button
                                  className="btn-sm btn-upload ml-2"
                                  color="primary"
                                  variant="contained"
                                  component="span"
                                  disabled={!selectedFiles}
                                  onClick={upload}
                                >
                                  upload <i className="fas fa-upload ml-1"></i>
                                </Button>
                              </>
                            )}
                          </fieldset>  
                            {/* </div> */}
                            <div className="form-group col-md-9">
                              <Typography
                                variant="subtitle2"
                                className={`upload-message ${
                                  isError ? "error" : ""
                                }`}
                              >
                                <span className={`${isError === true?"text-red":"text-green"}`}>{message}</span>
                              </Typography>
                            </div>

                              <fieldset className="col-md-12 mt-3"  disabled={loader}>
                                <button
                                  className="btn btn-sm hovbuttonColour"
                                  onClick={handleSubmit}
                                  type="button"
                                  disabled={loader}
                                >
                                  {loader?<><Spinner animation="border" size="sm" className="mr-1"/> &nbsp; "Submiting"</>:"Submit"}
                                </button>
                              </fieldset>
                              </>
                                }
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(NewQA);
