import { Field, FieldArray, Form, Formik } from "formik";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  userid,
  currentTenant,
  taxtype,
  thousandsdisplay,
  ccountry,
  cstate,
} from "../../Common/Credentials.js";
import { FormikTextField } from "../../CommonComponents/FormField.js";
import SalesServices from "../SalesServices";
import {
  Box,
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  styled,
  Slider,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import UserService from "../../users/UserService.js";
import { Button, Card, Spinner } from "react-bootstrap";
import { Table } from "@mui/material";
import { TableCell } from "@mui/material";
import UomServices from "../../Masters/Uom/UomServices.js";
import MasterService from "../../Masters/MasterService.js";
import moment from "moment";
import IOSSwitch from "../../CommonComponents/IOSSwitch.js";
import InventoryService from "../../inventory/InventoryService";
import CategoryServices from "../../Masters/Category/CategoryServices";
import { NumericFormat } from "react-number-format";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import WarehouseServices from "../../Masters/Warehouse/WarehouseServices";
import SettingsServices from "../../Settings/SettingsServices";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AgentServices from "../../Masters/Agents/AgentServices";
import UsercustomerService from "../../Masters/Usercustomers/UsercustomerService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2";

let datalist = [];
let updatedatalist = [];
let list = {};
let count = 0;
let serviceList = [];

// let buffer = 0;
//  let progress
// const styles = (theme) => ({
//   table: {
//     display: "block",
//     width: "100%",
//     overflowX: "auto",
//   },
// });
// const BorderLinearProgress = styled((theme) => ({
//   root: {
//     height: 15,
//     borderRadius: 5,
//   },
//   colorPrimary: {
//     backgroundColor: "#EEEEEE",
//   },
//   bar: {
//     borderRadius: 5,
//     backgroundColor: "#1a90ff",
//   },
// }))(LinearProgress);

const useStyles = styled((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  },
}));

class NewQuotation extends Component {
  constructor(props) {
    super(props);
    const pagename =
      this.props.location.state === undefined
        ? this.history.props.push("/saleorder")
        : this.props.location.state.pagename;
    const id =
      this.props.location.state === undefined
        ? this.history.props.push("/saleorder")
        : this.props.location.state.id;

    this.state = {
      pagename: pagename,
      datatoupdate: "",
      usercustomers: [],
      id: id,
      salestype: "0",
      soid: "",
      ordertype: false,
      iid: "",
      custom: [],
      customer: "",
      agentsdata: [],
      agentreq: false,
      agent: "",
      agentid: "",
      itemid: "",
      itemList: [],
      saleorderdata: [],
      itemname: "",
      hsnid: "",
      hsn: "",
      custid: "",
      quantity: "",
      pricevariance: 0,
      cost: "",
      type: "LIFO",
      uom: "",
      uomid: "",
      UOMList: [],
      warehouse: "",
      whid: null,
      WarehouseList: [],
      whdata: [],
      CategoryList: [],
      categoryid: "",
      category: "",
      stock: "",
      taxrequired: true,
      taxtype: taxtype,
      tax: "",
      cgst: "",
      sgst: "",
      additionalparams: 0,
      taxamount: 0,
      totaltaxamount: 0,
      totalprice: 0,
      totaliv: 0,
      totalpricewtax: 0,
      grandtotal: 0,
      grandproducttotal: 0,
      listData: [],
      status: 0,
      description: "",
      creditperiod: "",
      creditvalue: "",
      creditstatus: "",
      availabelcredit: "",
      initialCredit: "",
      gsttype: false,
      ledgerid: "",
      UserPermissions: "",
      preitemList: [],
      filteritemlist: [],
      size: "",
      colour: "",
      thickness: "",
      brand: "",
      model: "",
      totalmtrroll: "",
      sizelist: [],
      colourlist: [],
      thicknesslist: [],
      brandslist: [],
      advance: 0,
      advancecnvt: 0,
      advancestatus: false,
      paymentterms: 0,
      filterserv: [],
      ServiceCategoryList: [],
      serviceitemList: [],
      serviceList: [],
      deleteditems:[],
      deletedservices:[],
      service: "",
      serviceid: "",
      servicecategory: "",
      servicecatid: "",
      servicecode: "",
      servicetax: "",
      servicecost: "",
      servicecostconv: 0,
      servicecgst: "",
      servicesgst: "",
      serviceigst: "",
      servicegst: "",
      servicevat: "",
      servicetaxamount: "",
      servicecostwttaxconv: 0,
      servicecostwttax: "",
      servicedescription: "",
      servicetotal: "",
      servicetotalwtax: "",
      serviceerr: "",
      grandservicetotal: 0,

      milestone: [],
      recurringtype: [],
      contracttypeList: [],
      contracttype: "",
      contracttime: "NotApplicable",
      contractstartdate: new Date(),
      contractenddate: new Date(),
      milestonesavebtn: false,
      percentagemessage: "",

      pro: true,
      serv: false,
      prosev: false,
      sloader: false,
      soeditprice: "NO",
      open: false,
      currencylist: [],
      currencyid: "",
      currency: "",
      curconversionrate: 1,
      basecurrency: "",
      convertionreq: false,
      custcountry: "",
      advoptions: "",
      settings: "",
      markup:0,
      markup1:0

      // partyid: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleProductChange = (pro,serv,prosev) => {
    this.setState({
      pro: pro,
      serv: serv,
      prosev: prosev,
      salestype: pro?"0":serv?"1":"2",
      itemname: "",
      hsnid: "",
      hsn: "",
      quantity: "",
      pricevariance: 0,
      cost: "",
      uom: "",
      uomid: "",
      description: "",
      creditperiod: "",
      creditvalue: "",
      creditstatus: "",
      availabelcredit: "",
      initialCredit: "",
      gsttype: false,
      size: "",
      colour: "",
      thicknes: "",
      brand: "",
      totalmtrroll: "",
      service: "",
      serviceid: "",
      servicecategory: "",
      servicecatid: "",
      servicecode: "",
      servicetax: "",
      servicecost: "",
      servicecostwttax: "",
      servicetaxamount: "",
      servicedescription: "",
      servicetotal: "",
      serviceerr: "",
      grandservicetotal: 0,
      categoryid: "",
      category: "",
      stock: "",
      tax: "",
      cgst: "",
      sgst: "",
      additionalparams: 0,
      taxamount: 0,
      totaltaxamount: 0,
      totalprice: 0,
      totaliv: 0,
      totalpricewtax: 0,
      grandtotal: 0,
      listData: [],
    });
    datalist = [];
  };
 

  handleCurrency = (value, values) => {
    if (value !== "") {
      this.setState({ currencyid: values.id, currency: values.currency });
    } else {
      this.setState({ currencyid: "", currency: "" });
    }
  };

  handlemarkup1Change = (e)=>{
    this.setState({
      markup1: e.target.value,
      totalpricewtax: 0,
    })
    if (this.state.creditstatus !== "NotRequired") {
      if (this.state.availabelcredit < 0) {
        this.setState({ availabelcredit: this.state.creditvalue });
      } else {
      }
    }
  }

  handleTypeChange = (e) => {
    this.setState({ type: e.target.value });
  };

  handlemulticurrencyrequired = () => {
    this.setState({ convertionreq: !this.state.convertionreq });
  };

  handleCurconversionrate = (e) => {
    this.setState({
      curconversionrate: e.target.value,
    });
  };

  handlesale = (e) => {
    this.setState({ salestype: e.target.value });
  };

  handleadvancestatus = (e) => {
    this.setState({ advancestatus: !this.state.advancestatus, advance: 0 });
  };

  onSubmit = () => {
    count = count + 1;

    // this.setState({
    //     totalpricewtax:
    //       ((this.state.cost * parseFloat(this.state.quantity)  +
    //       (parseFloat(this.state.tax) *
    //         (this.state.cost * parseFloat(this.state.quantity))) /
    //       100) + ((this.state.cost * parseFloat(this.state.quantity)  +
    //       (parseFloat(this.state.tax) *
    //         (this.state.cost * parseFloat(this.state.quantity))) /
    //       100 ) * this.state.pricevariance )/100),

    //     taxamount:
    //     (((parseFloat(this.state.tax) *
    //         (this.state.cost * parseFloat(this.state.quantity))) /
    //       100) + (((parseFloat(this.state.tax) *
    //       (this.state.cost * parseFloat(this.state.quantity))) /
    //     100 )*this.state.pricevariance)/100),

    //     totalprice:((this.state.cost * parseFloat(this.state.quantity)) +
    //     ((this.state.cost * parseFloat(this.state.quantity))*this.state.pricevariance)/100) ,
    //   });

    let total =
      ((this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) * parseFloat(this.state.quantity)) +
      ((this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) *
        parseFloat(this.state.quantity) *
        this.state.pricevariance) /
        100;

    let taxtotal =
      (parseFloat(this.state.tax) *
        ((this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) * parseFloat(this.state.quantity))) /
        100 +
      (((parseFloat(this.state.tax) *
        ((this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) * parseFloat(this.state.quantity))) /
        100) *
        this.state.pricevariance) /
        100;

    let itemtotal =
      (this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) * parseFloat(this.state.quantity) +
      (parseFloat(this.state.tax) *
        ((this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) * parseFloat(this.state.quantity))) /
        100 +
      (((this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) * parseFloat(this.state.quantity) +
        (parseFloat(this.state.tax) *
          ((this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) * parseFloat(this.state.quantity))) /
          100) *
        this.state.pricevariance) /
        100;

        let remcredit = this.state.availabelcredit -
        ((this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) * parseFloat(this.state.quantity) +
          (parseFloat(this.state.tax) *
            ((this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) * parseFloat(this.state.quantity))) /
            100 +
          (((this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) * parseFloat(this.state.quantity) +
            (parseFloat(this.state.tax) *
              ((this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1)) * parseFloat(this.state.quantity))) /
              100) *
            this.state.pricevariance) /
            100)

    if (this.state.creditstatus !== "NotRequired" && remcredit < 0 ) {
      this.setState({availabelcredit:remcredit,totalpricewtax: itemtotal});
    }else{
    this.setState(
      {
        availabelcredit:remcredit,
        totalprice: total,
        totalpricewtax: itemtotal,
        taxamount: taxtotal,
        totaliv: this.state.totaliv + total,
        grandtotal: this.state.grandtotal + itemtotal,
        grandproducttotal: this.state.grandtotal + itemtotal,
        creditvalue: remcredit,
        totaltaxamount: this.state.totaltaxamount + taxtotal,
        stock: this.state.stock,
      },
      () => {
        list = {
          soid: this.state.id,
          customer: this.state.customer,
          custid: this.state.custid,
          itemid: parseInt(this.state.itemid),
          name: this.state.itemname,
          hsn: this.state.hsn,
          price:
            parseFloat((this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1))) +
            (parseFloat((this.state.cost + parseFloat(this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1))) *
              parseFloat(this.state.pricevariance)) /
              100,
          markup:this.state.markup,
          markup1:this.state.markup1===undefined||this.state.markup1===""?0:this.state.markup1,
          salesenum: this.state.type,
          quantity: parseFloat(this.state.quantity),
          pricevariance: parseFloat(this.state.pricevariance),
          totalprice: this.state.totalprice,
          tax: parseFloat(this.state.tax),
          taxamount: this.state.taxamount,
          totalpricewtax: this.state.totalpricewtax,
          size: this.state.size,
          brand: this.state.brand,
          model: this.state.model,
          uom: this.state.uom,
          colour: this.state.colour,
          thickness: this.state.thickness,
          totalmtrroll: this.state.totalmtrroll,
          description: this.state.description,
          catid: this.state.catid,
          category: this.state.category,
          additionalparams: this.state.additionalparams,
          creditperiod: this.state.creditperiod,
          creditvalue:
            this.state.availabelcredit === "" ? 0 : this.state.availabelcredit,
          currencyid: this.state.currencyid,
          currency: this.state.currency,
          curconversionrate: parseFloat(this.state.curconversionrate),
        };

        datalist.push(list);
        updatedatalist.push(list);
        this.setState({
          listData: datalist,
        });

        this.setState({
          size: "",
          colour: "",
          thicknes: "",
          brand: "",
          totalmtrroll: "",
          quantity: "",
          totalpricewtax: "",
          description: "",
        });
      }
    );
    }
  };

  handleSubmit = () => {
    // let filedata = this.state.fileInfos.map((e) => e.name).toLocaleString();
    // let path = prefix1 +"/" +doctype +"/" +this.state.partyid +"/" +year +"/" +curmonth +"/" +day;
    // if (filedata === "" && this.state.pofile === "") {
    //   this.setState({ message: "Please Upload Purchase Order" });
    // } else {
    this.setState({ sloader: true });
    if (this.state.salestype === "1") {
      serviceList = [
        {
          service: this.state.service,
          serviceid: this.state.serviceid,
          servicecatid: this.state.servicecatid,
          servicecode: this.state.servicecode,
          servicecategory: this.state.servicecategory,
          servicecost: parseFloat(this.state.servicecost),
          servicetax: this.state.servicetax,
          description: this.state.servicedescription,
          servicetotal:
            parseFloat(this.state.servicecost) +
            parseFloat(this.state.servicecost) * (this.state.servicetax / 100),
          servicetotalwtax:
            parseFloat(this.state.servicecost) +
            parseFloat(this.state.servicecost) * (this.state.servicetax / 100),
          recurringtype: this.state.contracttime,
          milestonequotation: this.state.milestone,
          contracttype: this.state.contracttype,
          recurrencetill: this.state.contractstartdate,
          currencyid: this.state.currencyid,
          currency: this.state.currency,
          curconversionrate: parseFloat(this.state.curconversionrate),
          // contractenddate: this.state.contractenddate,
          // contractfile:filedata,
          // contractpath:path,
        },
      ];
    }
    let sodata = {
      // pofile: this.state.pofile === "" ? filedata : this.state.pofile,
      // popath: this.state.popath === "" ? path : this.state.popath,
      id: this.state.id,
      sotype:serviceList.length===0?0:parseInt(this.state.salestype),
      agent:
        this.state.agentid === "" || this.state.agentid === undefined
          ? null
          : this.state.agentid,
      creditstatus:
        this.state.creditstatus === undefined ? null : this.state.creditstatus,
      customer: this.state.customer,
      custid: this.state.custid,
      status: this.state.status,
      creditperiod:
        this.state.creditperiod === undefined ||
        this.state.creditperiod === null
          ? 0
          : this.state.creditperiod,
      creditvalue:
        this.state.availabelcredit === null ||
        this.state.availabelcredit === undefined
          ? 0
          : this.state.availabelcredit,
      gsttype: this.state.gsttype === false ? 0 : 1,
      grandtotal: this.state.grandtotal,
      grandproducttotal: this.state.grandtotal,
      whid: this.state.whid,
      createdby: userid,
      updatedby: userid,
      tenantid: currentTenant,
      totaltaxamount: this.state.totaltaxamount,
      paymentterms: this.state.paymentterms,
      itemquotation:this.state.pagename === "Edit/Updated Quotation" ? updatedatalist : datalist,
      deleteditems:this.state.deleteditems, 
      deletedservices:this.state.deletedservices,
      servicequotation: serviceList,
      grandservicetotal: this.state.grandservicetotal,
      advancepaymentreq: this.state.advancestatus === false ? 0 : 1,
      advanceamountreq: parseFloat(this.state.advance),

      currencyid: this.state.currencyid,
      currency: this.state.currency,
      curconversionrate: parseFloat(this.state.curconversionrate),

      taxrequired: this.state.taxrequired,
      taxtype: this.state.taxtype === "GST" ? 0 : 1,
    };
    SalesServices.NewQuotation(sodata)
      .then((response) => {
       this.props.navigate({pathname:`/quotationdetails`}, {state:{id:response.data}});
      })
      .catch((e) => {
        this.setState({ sloader: false }, () => {
          if (e.response.data.error === "Unauthorized") {
            Swal.fire({
              title: `Your Session is Lost: Please login Back to resume`,
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => this.props.navigate("/"));
          } else {
            Swal.fire({
              title: `${e.response.data.message}`,
              text: ".",
              icon: "error",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            });
          }
        });
      });
  };

  handleordertype = (e) => {
    this.setState({ ordertype: !this.state.ordertype }, () =>
      this.state.ordertype === true
        ? SalesServices.GetallSaleOrderOfCustomer(this.state.custid)
            .then((res) => this.setState({ saleorderdata: res.data }))
            .catch((e) => {
              if (e.response.data.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            })
        : ""
    );
    //  SalesServices.GetPreviousOrder(this.state.custid).then(res=>
    //   this.setState(
    //     {
    //       listData: res.data.prevsoitemlist,
    //       creditvalue: res.data.creditvalue,
    //       grandtotal: res.data.grandtotal,
    //       taxamount: res.data.taxamount,
    //       totaltaxamount: res.data.totaltaxamount,
    //       totaliv: res.data.totaliv,
    //       gsttype:res.data.gsttype=== "INTERSTATE"?'0':'1',
    //     }, () => {datalist = res.data.prevsoitemlist})
    //   )
  };
  handlegsttype = (e) => {
    this.setState({ gsttype: !this.state.gsttype });
  };

  handleSaleOrder = (value) => {
    if (value !== "" && this.state.saleorderdata.includes(value)) {
      this.setState({ saleorderid: value });
      const id = value;
      SalesServices.GetDataToUpdate(id)
        .then((response) =>
          this.setState({
            preitemList: response.data.saleorderitems,
          })
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    }
  };

  // handlepreitem = (value,values)=>{
  //   this.setState({ itemid: values.preitemname.id, itemname: value,quantity: '', totalpricewtax: '' });
  // }

  // handleConfirm = () => {
  //   this.setState({ status: 1 });
  //   SalesServices.ConfirmOrders(datalist).then(() =>
  //     this.props.navigate(`/salesorder`)
  //   );
  // };

  deleteSaleOrderItem(id) {
    let deleteditem = this.state.listData.filter((data) => data.id === id);
    let tp = deleteditem.map((data) => data.totalpricewtax);
    let tx = deleteditem.map((data) => data.taxamount);
    let ti = deleteditem.map((data) => data.totalprice);
    let newitemlist = this.state.listData.filter((data) => data.id !== id);
    this.setState({
      listData: newitemlist,
      grandtotal: this.state.grandtotal - tp,
      totaltaxamount: this.state.totaltaxamount - tx,
      totaliv: this.state.totaliv - ti,
      availabelcredit: this.state.availabelcredit - parseFloat(tp),
    });
    datalist = newitemlist;
    SalesServices.deleteSaleOrderItem(id).catch((e) => {
      if (e.response.data.error === "Unauthorized") {
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
      }
    });
  }

  handleRequestCredit = () => {
    let customer = {
      customer: this.state.custid,
      status: 0,
    };
    SalesServices.RequestCridet(customer)
      .then(() => {
        alert("Request Sent Successflly");
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  };

  handleCustomer = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        custid: values.id,
        customer: values.companyname,
        currency: values.currency,
        currencyid: this.state.currencylist.find(
          (e) => e.currency === values.currency
        ).id,
        taxtype: values.taxtype,
        gsttype:
          values.statename === cstate && values.countryname === ccountry
            ? false
            : true,
        custcountry: values.countryname,
        convertionreq:
          values.currency === this.state.basecurrency ? false : true,
        ordertype: false,
        partyid: values.id,
        itemname: "",
        hsnid: "",
        hsn: "",
        quantity: "",
        cost: "",
        uom: "",
        uomid: "",
        description: "",
        size: "",
        colour: "",
        thicknes: "",
        brand: "",
        model: "",
        totalmtrroll: "",
        stock: "",
        tax: "",
        cgst: "",
        sgst: "",
        additionalparams: 0,
        taxamount: 0,
        totaltaxamount: 0,
        totalprice: 0,
        totaliv: 0,
        totalpricewtax: 0,
        grandtotal: 0,
        listData: [],
      });

      let customer = this.state.custom.filter(
        (data, key) => data.id === values.id
      );
      customer.map((data) =>
        this.setState(
          { ledgerid: data.ledgerid }
          //   , () => {
          //   if (this.state.ledgerid === null) {
          //     if (
          //       window.confirm(
          //         `Please Create a Ledger Account of ${this.state.customer}`
          //       )
          //     ) {
          //       this.props.navigate(`/newledgers`);
          //     } else {
          //       this.setState({ customer: "" });
          //       this.props.navigate(`/salesorder`);
          //     }
          //   }
          // }
        )
      );

      const id = values.id;
      SalesServices.GetCustomerTerms(id)
        .then((res) =>
          this.setState({
            creditstatus: res.data.creditstatus,
            creditvalue: res.data.creditleft,
            creditperiod: res.data.creditperiod,
            availabelcredit: res.data.creditleft,
            initialCredit: res.data.creditleft,
          })
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    } else {
      this.setState({ custid: "", customer: "",creditstatus:"",creditvalue:"",creditperiod:"",availabelcredit:"",initialCredit:"" });
    }
  };

  handleQuantityChange = (e) => {
    this.setState({ quantity: e.target.value });
    this.setState({ totalpricewtax: "" });
    if (this.state.creditstatus !== "NotRequired") {
      if (this.state.availabelcredit < 0) {
        this.setState({ availabelcredit: this.state.creditvalue });
      } else {
      }
    }
  };

  handlePricevariance = (e) => {
    this.setState({ pricevariance: e.target.value });
    this.setState({ totalpricewtax: "" });
    if (this.state.creditstatus !== "NotRequired") {
      if (this.state.availabelcredit < 0) {
        this.setState({ availabelcredit: this.state.creditvalue });
      } else {
      }
    }
  };

  handleUOM = (value, values) => {
    if (value !== "" && typeof values.uom === "object") {
      this.setState({ uomid: values.uom.id, uom: value });
    } else {
      this.setState({ uom: "", uomid: "" });
    }
  };

  handleSize = (value, values) => {
    if (
      values !== "" &&
      this.state.sizelist.includes(values) &&
      values !== null
    ) {
      this.setState({ size: values });
    } else {
      this.setState({ size: "" });
    }
  };

  handleColour = (value, values) => {
    if (
      values !== "" &&
      this.state.colourlist.includes(values) &&
      values !== null
    ) {
      this.setState({ colour: values });
    } else {
      this.setState({ colour: "" });
    }
  };

  // onSubmit = () => {
  //   this.setState(
  //     {
  //       totalpricewtax:
  //         this.state.cost * parseFloat(this.state.quantity) +
  //         (parseFloat(this.state.tax) *
  //           (this.state.cost * parseFloat(this.state.quantity))) /
  //           100 +
  //         ((this.state.cost * parseFloat(this.state.quantity) +
  //           (parseFloat(this.state.tax) *
  //             (this.state.cost * parseFloat(this.state.quantity))) /
  //             100) *
  //           this.state.pricevariance) /
  //           100,

  //       taxamount:
  //         (parseFloat(this.state.tax) *
  //           (this.state.cost * parseFloat(this.state.quantity))) /
  //           100 +
  //         (((parseFloat(this.state.tax) *
  //           (this.state.cost * parseFloat(this.state.quantity))) /
  //           100) *
  //           this.state.pricevariance) /
  //           100,

  //       totalprice:
  //         this.state.cost * parseFloat(this.state.quantity) +
  //         (this.state.cost *
  //           parseFloat(this.state.quantity) *
  //           this.state.pricevariance) /
  //           100,
  //     },
  //     () => {
  //       if (this.state.creditstatus !== "NotRequired") {
  //         this.setState({
  //           availabelcredit:
  //             this.state.availabelcredit -
  //             (this.state.cost * parseFloat(this.state.quantity) +
  //               (parseFloat(this.state.tax) *
  //                 (this.state.cost * parseFloat(this.state.quantity))) /
  //                 100 +
  //               ((this.state.cost * parseFloat(this.state.quantity) +
  //                 (parseFloat(this.state.tax) *
  //                   (this.state.cost * parseFloat(this.state.quantity))) /
  //                   100) *
  //                 this.state.pricevariance) /
  //                 100),
  //         });
  //       }
  //     }
  //   );
  // };

  handlepreitem = (value, values) => {
    if (this.state.whid === "" || this.state.warehouse === "") {
      alert("Please Select Org Unit");
    } else {
      if (value !== "" && typeof values.itemname === "object") {
        this.setState({
          itemid: values.preitemname.itemid,
          itemname: value,
          category: values.preitemname.category,
          categoryid: values.preitemname.categoryid,
          // cost: values.preitemname.price,
          uom: values.preitemname.uom,
          quantity: values.preitemname.quantity,
          pricevariance: values.preitemname.pricevariance,
          brand: values.preitemname.brand,
          size: values.preitemname.size,
          colour: values.preitemname.colour,
          thickness: values.preitemname.thickness,
          totalmtrroll: values.preitemname.totalmtrroll,
          totalpricewtax: "",
        });
        if (this.state.creditstatus !== "NotRequired") {
          if (this.state.availabelcredit < 0) {
            this.setState({ availabelcredit: this.state.creditvalue });
          }
        }
        const id = values.preitemname.itemid;
        let itemdata = this.state.itemList.filter((e) => e.itemid === id);
        itemdata.map((data) =>
          this.setState({
            type: data.type,
            cost: data.price,
            // stock: data.totalstock,
            hsn: data.hsn,
            tax: this.state.taxrequired === false ? 0 : data.tax,
            cgst: this.state.taxrequired === false ? 0 : data.tax / 2,
            sgst: this.state.taxrequired === false ? 0 : data.tax / 2,
            additionalparams: data.additionalparams,
          })
        );
        let warehousedata = this.state.whdata.filter(
          (e) => e.whid === this.state.whid
        );
        let saleslist = warehousedata.find((e) => e).saleslist;
        let a = saleslist.filter((e) => e.itemid === id);
        this.setState({ stock: a.map((e) => e.totalstock)[0] });
      }
    }
  };

  handleWarehouse = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        warehouse: values.name,
        whid: values.id,
        ordertype: false,
        itemname: "",
        hsnid: "",
        hsn: "",
        quantity: "",
        pricevariance: 0,
        cost: "",
        uom: "",
        uomid: "",
        description: "",
      });
    } else {
      this.setState({
        warehouse: "",
        whid: "",
        ordertype: false,
        itemname: "",
        hsnid: "",
        hsn: "",
        quantity: "",
        pricevariance: 0,
        cost: "",
        type: "",
        uom: "",
        uomid: "",
        description: "",
      });
    }
  };

  handleAgent = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        agent: values.name,
        agentid: values.id,
      });
    } else {
      this.setState({ agent: "", agentid: "" });
    }
  };

  handleCategory = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        catid: values.id,
        category: values.category,
        filteritemlist: this.state.itemList.filter(
          (e) => e.catid === values.id
        ),
        itemid: "",
        itemname: "",
        hsn: "",
        tax: "",
        cost: "",
        quantity: "",
        pricevariance: 0,
      });
    } else {
      this.setState({
        itemid: "",
        itemname: "",
        hsn: "",
        tax: "",
        cost: "",
        quantity: "",
        pricevariance: 0,
        catid: "",
        category: "",
        filteritemlist: [],
      });
    }
  };

  handleitem = (value, values) => {
    if (this.state.whid === "" || this.state.warehouse === "") {
      alert("Please Select Org Unit");
    } else {
      if (value !== "" && typeof values === "object" && values !== null) {
        this.setState({
          itemid: values.itemid,
          itemname: values.name,
          quantity: "",
          pricevariance: 0,
          totalpricewtax: "",
        });
        if (this.state.creditstatus !== "NotRequired") {
          if (this.state.availabelcredit < 0) {
            this.setState({
              availabelcredit:
                this.state.creditvalue * this.state.curconversionrate,
            });
          }
        }
        const id = values.itemid;
        // SalesServices.getCost(id).then((res) =>
        let itemdata = this.state.itemList.filter((e) => e.itemid === id);
        itemdata.map((data) =>
          this.setState({
            type:
              data.type === undefined || data.type === null || data.type === ""
                ? "LIFO"
                : data.type,
            cost: data.price,
            hsn: data.hsn,
            tax: this.state.taxrequired === true ? data.tax : 0,
            cgst: this.state.taxrequired === true ? data.tax / 2 : 0,
            sgst: this.state.taxrequired === true ? data.tax / 2 : 0,
            additionalparams: data.additionalparams,
            description: data.description,
            uomid: data.uomid,
            uom: data.uom,
            brand: data.brand,
            model: data.model,
          })
        );
        let warehousedata = this.state.whdata.filter(
          (e) => e.whid === this.state.whid
        );
        let saleslist = warehousedata.find((e) => e).saleslist;
        let a = saleslist.filter((e) => e.itemid === id);
        this.setState({
          stock:
            a.map((e) => e.totalstock)[0] === undefined
              ? 0
              : a.map((e) => e.totalstock)[0],
        });
      } else {
        this.setState({ itemname: "", itemid: "" });
      }
    }
  };

  removeItemFromList(id) {
    let deleteditem = datalist.filter((person, index) => index === id);
    let ditem = deleteditem.find((data) => data.itemid);
    if(ditem.id !== undefined && ditem.id !== '' && ditem.id !== null){
      this.state.deleteditems.push(ditem?.id)
    }
    // let dquantity = deleteditem.map((data) => data.quantity);
    let tp = deleteditem.map((data) => data.totalpricewtax);
    let tx = deleteditem.map((data) => data.taxamount);
    let ti = deleteditem.map((data) => data.totalprice);
    const newlist = datalist.filter((person, index) => index !== id);

    if (this.state.creditstatus !== "NotRequired") {
      this.setState({
        availabelcredit:
          this.state.availabelcredit +
          ditem.totalpricewtax / this.state.curconversionrate,
        creditvalue:
          this.state.availabelcredit +
          ditem.totalpricewtax / this.state.curconversionrate,
      });
    }
    datalist = newlist;
    this.setState({
      listData: newlist,
      grandtotal: this.state.grandtotal - tp,
      totaltaxamount: this.state.totaltaxamount - tx,
      totaliv: this.state.totaliv - ti,
    });
    this.state.whdata
      .find((e) => e.whid === this.state.whid)
      .saleslist.find((e) => e.itemid === ditem.itemid).totalstock =
      this.state.whdata
        .find((e) => e.whid === this.state.whid)
        .saleslist.find((e) => e.itemid === ditem.itemid).totalstock +
      ditem.quantity;

    this.setState({
      stock: this.state.whdata
        .find((e) => e.whid === this.state.whid)
        .saleslist.find((e) => e.itemid === ditem.itemid).totalstock,
    });
    // this.state.itemList.find(e => e.itemid = this.state.itemid).totalstock = this.state.itemList.find(e => e.itemid = this.state.itemid).totalstock + dquantity
  }

  refreshData() {
    const { id } = this.props.location.state;
    datalist = [];
    list = {};
    updatedatalist = [];
    count = 0;
    serviceList = [];

    MasterService.GetAllCurrency().then((response) => {
      this.setState({ currencylist: response.data });
    });

    MasterService.GetCurrencyOfTenant(currentTenant).then((response) => {
      this.setState({
        basecurrency: response.data.currency,
        currencyid: response.data.id,
        currency: response.data.currency,
        curconversionrate: 1,
      });
    });

    InventoryService.GetWHdata(currentTenant)
      .then((response) => {
        this.setState({ whdata: response.data });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    InventoryService.GetItems(currentTenant).then((response) =>
      this.setState({
        itemList: response.data,
      })
    );

    SalesServices.retriveAllCustomers(currentTenant).then((res) =>
      this.setState({
        custom: res.data.filter((e) =>
          this.state.usercustomers.length === 0
            ? e
            : this.state.usercustomers.includes(e.companyname)
        ),
      })
    );

    MasterService.getParameters(currentTenant).then((res) => {
      this.setState({
        parameters: res.data,
        sizelist: res.data.size,
        colourlist: res.data.colour,
        thicknesslist: res.data.thickness,
        brandslist: res.data.brands,
      });
    });

    UomServices.getUOM(currentTenant).then((response) =>
      this.setState({
        UOMList: response.data,
      })
    );

    SalesServices.GetContrctType().then((response) => {
      this.setState({ contracttypeList: response.data });
    });
    SalesServices.GetRecurringduration().then((response) => {
      this.setState({ recurringtype: response.data });
    });

    CategoryServices.getCategory(currentTenant).then((response) =>
      this.setState({
        CategoryList: response.data.filter((e) => e.categorytype === "Product"),
        ServiceCategoryList: response.data.filter(
          (e) => e.categorytype === "Service"
        ),
      })
    );
    WarehouseServices.getWarehouses(currentTenant).then((response) =>
      this.setState(
        {
          WarehouseList: response.data,
        },
        () => {
          if (this.state.WarehouseList.length === 1) {
            this.setState({
              warehouse: this.state.WarehouseList[0].name,
              whid: this.state.WarehouseList[0].id,
            });
          }
        }
      )
    );
    InventoryService.getServices(currentTenant).then((res) => {
      this.setState({ serviceitemList: res.data });
    });

    AgentServices.getAgents(currentTenant).then((res) => {
      this.setState({ agentsdata: res.data });
    });

    SettingsServices.GetSettings(currentTenant).then((response) => {
      this.setState({
        settings: response.data,
        soeditprice: response.data.soeditprice,
        salescalreq: response.data.salescalreq,
      });
    });

    if (id < 1) {
      return;
    }

    SalesServices.GetQuotationbyId(id).then((res) =>
      this.setState({
          listData: res.data.itemquotation,
          serviceList: res.data.sotype==="Services"?[]:res.data.servicequotation,
          id: res.data.id,
          salestype: res.data.sotype==="Products"?"0":res.data.sotype==="Services"?"1":"2",
          custid: res.data.custid,
          customer: res.data.customer,
          warehouse: res.data.warehouse,
          whid: res.data.whid,
          agent:res.data.agent,
          agentid:res.data.agentid,
          agentreq:res.data.agent === null?false:true,
          creditvalue: res.data.creditvalue,
          initialCredit: res.data.creditvalue,
          creditperiod: res.data.creditperiod,
          availabelcredit: res.data.creditvalue,
          grandtotal: res.data.grandproducttotal,
          grandservicetotal: res.data.grandservicetotal,
          taxamount: res.data.taxamount,
          totaltaxamount: res.data.totaltaxamount,
          totaliv: res.data.totaliv,
          pofile: res.data.pofile,
          popath: res.data.popath,
          gsttype: res.data.gsttype === "INTERSTATE" ? true : false,
          advance: res.data.advanceamountreq,
          advancestatus: res.data.advancepaymentreq === "True" ? true : false,
          paymentterms: res.data.paymentterms,
        },
        () => {
          datalist = res.data.itemquotation;
          serviceList = res.data.servicequotation;
          SalesServices.GetCustomerTerms(res.data.custid)
          .then((res) =>
            this.setState({
              creditstatus: res.data.creditstatus,
              creditvalue: res.data.creditleft-res.data.creditvalue,
              creditperiod: res.data.creditperiod,
              availabelcredit: res.data.creditleft - res.data.creditvalue,
              initialCredit: res.data.creditleft,
            })
          )
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
        }
      )
    );
  }

  handleServicecategory = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        servicecatid: values.id,
        servicecategory: values.category,
        filterserv: this.state.serviceitemList.filter(
          (e) => e.catid === values.id
        ),
        servicecost: "",
        servicecostwttax: "",
        servicetaxamount: "",
        milestonelist: [],
        percentagemessage: "",
        milestonesavebtn: false,
      });
    } else {
      this.setState({
        servicecatid: "",
        servicecategory: "",
        filterserv: [],
        servicecost: "",
        servicecostwttax: "",
        servicetaxamount: "",
        milestonelist: [],
        percentagemessage: "",
        milestonesavebtn: false,
      });
    }
  };

  handleService = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        serviceid: values.id,
        service: values.service,
        servicetax: this.state.taxrequired === true ? values.servicetax : 0,
        servicecode: values.servicecode,
        servicedescription: values.description,
        servicecost: "",
        servicecostwttax: "",
        servicetaxamount: "",
        milestonelist: [],
        percentagemessage: "",
        milestonesavebtn: false,
      });
    } else {
      this.setState({
        serviceid: "",
        service: "",
        servicetax: 0,
        servicecode: "",
        servicedescription: "",
        servicecost: "",
        servicecostwttax: "",
        servicetaxamount: "",
        milestonelist: [],
        percentagemessage: "",
        milestonesavebtn: false,
      });
    }
  };

  handleServiceAmountChange = (e) => {
    this.setState({
      servicecost: e.target.value,
      servicecostconv:
        parseFloat(e.target.value) * this.state.curconversionrate,
      servicetaxamount:
        (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicecgst:
        ((this.state.servicetax / 100) * parseFloat(e.target.value)) / 2,
      servicesgst:
        ((this.state.servicetax / 100) * parseFloat(e.target.value)) / 2,
      serviceigst: (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicegst: (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicevat: (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicecostwttax:
        parseFloat(e.target.value) +
        (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicecostwttaxconv:
        (parseFloat(e.target.value) +
          (this.state.servicetax / 100) * parseFloat(e.target.value)) *
        this.state.curconversionrate,
    });
  };

  handleContracttype = (value, values) => {
    if (
      values !== "" &&
      this.state.contracttypeList.includes(values) &&
      values !== null
    ) {
      this.setState({ contracttype: values });
    } else {
      this.setState({ contracttype: "" });
    }
  };

  handlecontracttime = (value, values) => {
    if (
      values !== "" &&
      this.state.recurringtype.includes(values) &&
      values !== null
    ) {
      this.setState({ contracttime: values });
    } else {
      this.setState({ contracttime: "" });
    }
  };

  handleStartDateChange = (date) => {
    this.setState({ contractstartdate: date });
  };
  handleEndDateChange = (date) => {
    this.setState({ contractenddate: date });
  };

  validatepercentage = (values) => {
    let totalper = 0;
    values.map((e) => (totalper = e.percentage + totalper));
  };

  handlemilestonevalues = (values) => {
    let totalpercentage = 0;
    values.map((e) => {
      return (totalpercentage = e.percentage + totalpercentage);
    });
    if (totalpercentage > 100) {
      this.setState({ percentagemessage: "Percentage is Greaterthan 100%" });
    } else if (totalpercentage < 100) {
      this.setState({ percentagemessage: "Percentage is Lessthan 100%" });
    } else {
      this.setState({
        milestone: values,
        milestonesavebtn: true,
        percentagemessage: "Milestones Saved Scussesfully",
      });
    }
  };

  removeServiceFromList(id) {
    let deleteservice = serviceList.find((person, index) => index === id);
    let tp = deleteservice.servicetotal
    
    if(deleteservice.id !== undefined && deleteservice.id !== '' && deleteservice.id !== null){
      this.state.deletedservices.push(deleteservice?.id)
    }
    const newlist = serviceList.filter((person, index) => index !== id);
    this.setState({
      serviceList: newlist,
      grandservicetotal: this.state.grandservicetotal - tp,
    });
    serviceList = newlist;
  }

  handleaddService = () => {
    if (
      this.state.supplier === "" ||
      this.state.service === "" ||
      this.state.servicedescription === "" ||
      this.state.servicecost === "" ||
      this.state.servicecategory === ""
    ) {
      this.setState({ serviceerr: "Please fill all the Fields" });
    } else {
      let services = {
        service: this.state.service,
        serviceid: this.state.serviceid,
        servicecatid: this.state.servicecatid,
        servicecode: this.state.servicecode,
        servicecategory: this.state.servicecategory,
        servicecost: parseFloat(this.state.servicecost),
        servicetax: this.state.servicetax,
        description: this.state.servicedescription,
        servicetotal:
          parseFloat(this.state.servicecost) +
          parseFloat(this.state.servicecost) * (this.state.servicetax / 100),
        servicetotalwtax:
          parseFloat(this.state.servicecost) +
          parseFloat(this.state.servicecost) * (this.state.servicetax / 100),
      };
      serviceList.push(services);
      this.setState({
        serviceList: serviceList,
        grandservicetotal:
          this.state.grandservicetotal +
          (parseFloat(this.state.servicecost) +
            parseFloat(this.state.servicecost) * (this.state.servicetax / 100)),
        servicecost: 0,
        service: "",
        serviceerr: "",
        servicetax: 0,
        servicecode: "",
        servicedescription: "",
        servicecostconv: 0,
        servicetaxamount: 0,
        servicecgst: 0,
        servicesgst: 0,
        serviceigst: 0,
        servicegst: 0,
        servicevat: 0,
        servicecostwttax: 0,
        servicecostwttaxconv: 0,
      });
    }
  };

  handletaxrequired = () => {
    this.setState({ taxrequired: !this.state.taxrequired });
  };
  handleTaxTypeChange = (e) => {
    this.setState({ taxtype: e.target.value });
  };

  handleSoRecurringTimeChange = (e) => {
    this.setState({ sorecurringtime: e.target.value });
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    UserService.UserPermissions(userid).then((response) =>
      this.setState({ UserPermissions: response.data })
    );

    UsercustomerService.GetUserCustomersbyUser(userid).then((res) =>
      this.setState({
        usercustomers:
          res.data === "" || res.data === undefined
            ? []
            : res.data.customers.split(","),
      })
    );
    this.refreshData();
  }

  handleSliderChange = (event, newValue) => {
    this.setState({ paymentterms: newValue });
  };

  handlepaymentterms = (event) => {
    this.setState({ paymentterms: Number(event.target.value) });
  };

  handleBlur = () => {
    if (this.state.paymentterms < 0) {
      this.setState({ paymentterms: 0 });
    } else if (this.state.paymentterms > 120) {
      this.setState({ paymentterms: 120 });
    }
  };

  handleAdvanceAmountChange = (e) => {
    this.setState({
      advance: Number(e.target.value),
      advancecnvt: Number(e.target.value) * this.state.curconversionrate,
    });
  };

  handleAdvanceAmountcnvtChange = (e) => {
    this.setState({
      advancecnvt: Number(e.target.value),
      advance: Number(e.target.value) / this.state.curconversionrate,
    });
  };

  handleAdvanceAmountBlur = () => {
    if (this.state.advance < 0) {
      this.setState({ advance: 0 });
    } else if (this.state.advance > this.state.grandtotal) {
      this.setState({ advance: this.state.grandtotal });
    }
  };

  validate(values) {
    let errors = {};
    if (this.state.quantity === "") {
      errors.quantity = "Please Enter Quantity";
    }
    if (this.state.quantity <= 0) {
      errors.quantity = "Value Should be greaterthan 0";
    }

    if (this.state.customer === "") {
      errors.customer = "Please Select Customer";
    }

    if (this.state.whid === "") {
      errors.warehouse = "Please Select Org Unit";
    }

    if (this.state.category === "") {
      errors.category = "Please Select Category";
    }
    if (this.state.description === "") {
      errors.description = "Please Enter Description";
    }

    if (this.state.itemname === "") {
      errors.itemname = "Please Select Item";
    }

    return errors;
  }

  onfocus() {
    document
      .getElementById("quantity")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document
      .getElementById("quantity")
      .addEventListener("keydown", function (e) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      });
  }

  oncostfocus() {
    document
      .getElementById("cost")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document.getElementById("cost").addEventListener("keydown", function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }

  onamountfocus() {
    document
      .getElementById("amount")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document.getElementById("amount").addEventListener("keydown", function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }

  oncurrencyconvtfocus() {
    document
      .getElementById("standard-adornment-amount")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document
      .getElementById("standard-adornment-amount")
      .addEventListener("keydown", function (e) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      });
  }

  onpricevariancefocus() {
    document
      .getElementById("pricevariance")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document
      .getElementById("pricevariance")
      .addEventListener("keydown", function (e) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      });
  }

  render() {
    let {
      ordertype,
      id,
      soid,
      salestype,
      agentsdata,
      agentreq,
      agent,
      agentid,
      itemid,
      itemname,
      cost,
      type,
      hsn,
      hsnid,
      stock,
      quantity,
      pricevariance,
      totalpricewtax,
      tax,
      customer,
      custom,
      custid,
      gsttype,
      itemList,
      cgst,
      sgst,
      UOMList,
      sizelist,
      colourlist,
      thicknesslist,
      brandslist,
      saleorderdata,
      preitemList,
      additionalparams,
      size,
      brand,
      model,
      colour,
      totalmtrroll,
      thicknes,
      description,
      filteritemlist,
      WarehouseList,
      warehouse,
      whid,
      CategoryList,
      catid,
      category,
      uom,
      uomid,
      advance,
      advancecnvt,
      advancestatus,
      paymentterms,
      filterserv,
      serviceitemList,
      serviceList,
      service,
      serviceid,
      ServiceCategoryList,
      servicecategory,
      servicecatid,
      servicetax,
      servicecode,
      servicecost,
      servicecostconv,
      servicecgst,
      servicesgst,
      serviceigst,
      servicegst,
      servicevat,
      servicecostwttax,
      servicecostwttaxconv,
      servicetaxamount,
      servicedescription,
      servicetotal,
      grandservicetotal,
      serviceerr,
      contracttype,
      contracttypeList,
      recurringtype,
      contracttime,
      currencylist,
      convertionreq,
      currencyid,
      currency,
      curconversionrate,
      basecurrency,
    } = this.state;

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={this.state.sloader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <Link
                  as="button"
                  className=" text-secondary"
                  to={-1}
                >
                  {/* <i className="fas fa-arrow-left text-secondary"/>&nbsp; */}
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/quotation">Quotation</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>

            <div className="card-body">
              <Formik
                initialValues={{
                  ordertype,
                  id,
                  salestype,
                  soid,
                  agentsdata,
                  agentreq,
                  agent,
                  agentid,
                  itemid,
                  itemname,
                  filteritemlist,
                  WarehouseList,
                  warehouse,
                  whid,
                  CategoryList,
                  category,
                  catid,
                  hsn,
                  hsnid,
                  cost,
                  type,
                  stock,
                  quantity,
                  pricevariance,
                  tax,
                  cgst,
                  sgst,
                  totalpricewtax,
                  custid,
                  customer,
                  gsttype,
                  custom,
                  itemList,
                  UOMList,
                  uom,
                  uomid,
                  sizelist,
                  colourlist,
                  thicknesslist,
                  brandslist,
                  saleorderdata,
                  preitemList,
                  additionalparams,
                  size,
                  brand,
                  model,
                  colour,
                  totalmtrroll,
                  thicknes,
                  description,

                  advance,
                  advancecnvt,
                  advancestatus,
                  paymentterms,
                  filterserv,
                  serviceitemList,
                  serviceList,
                  service,
                  serviceid,
                  ServiceCategoryList,
                  servicecategory,
                  servicecatid,
                  servicetax,
                  servicecode,
                  servicecost,
                  servicecostconv,
                  servicecgst,
                  servicesgst,
                  serviceigst,
                  servicegst,
                  servicevat,
                  servicecostwttax,
                  servicecostwttaxconv,
                  servicetaxamount,
                  servicedescription,
                  servicetotal,
                  grandservicetotal,
                  serviceerr,
                  contracttype,
                  contracttypeList,
                  recurringtype,
                  contracttime,
                  currencylist,
                  convertionreq,
                  currencyid,
                  currency,
                  curconversionrate,
                  basecurrency,
                  milestone: [
                    {
                      name: "",
                      expecteddate1: "",
                      percentage: 0,
                      amount: 0,
                      amountwttax: 0,
                    },
                  ],
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="itemid"
                          placeholder="Id"
                        />
                      </fieldset>
                      {this.state.id === -1 &&
                      (custom === undefined ||
                        custom.length === 0 ||
                        CategoryList === undefined ||
                        CategoryList.length === 0) ? (
                        <fieldset className="form-group col-md-12 deletebg">
                          <ul className="py-3">
                            <li style={{ "list-style": "none" }}>Note:</li>
                            {custom === undefined || custom.length === 0 ? (
                              <li>No Customers are avilable</li>
                            ) : (
                              ""
                            )}
                            {CategoryList === undefined ||
                            CategoryList.length === 0 ? (
                              <li>No Items are avilable</li>
                            ) : (
                              ""
                            )}
                          </ul>
                        </fieldset>
                      ) : (
                        ""
                      )}

                      {this.state.pagename === "Edit/Updated Quotation" ? (
                        ""
                      ) : (
                        <fieldset className="form-group col-md-12 p-0 m-0">
                          <div className="form-row p-0 m-0">
                            {this.state.settings.salesservice === "NO" &&
                            this.state.settings.salespands === "NO" ? (
                              ""
                            ) : (
                              <fieldset className="form-group col-md-12 p-0 m-0">
                                <div className="form-row p-0 m-0">
                                  <fieldset className="form-group col-md-4 p-0 m-0">
                                    <span className="mx-3">Products</span>
                                    <FormControlLabel
                                      control={
                                        <IOSSwitch
                                          className="sm"
                                          checked={this.state.pro}
                                          onChange={()=>this.handleProductChange(true,false,false)}
                                          name="checkedB"
                                        />
                                      }
                                    />
                                  </fieldset>
                                  {this.state.settings.salesservice ===
                                  "YES" ? (
                                    <fieldset className="form-group col-md-4 p-0 m-0">
                                      <span className="mx-3">Services</span>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            className="sm"
                                            checked={this.state.serv}
                                            // onChange={this.handleServiceChange}
                                            onChange={()=>this.handleProductChange(false,true,false)}
                                            name="checkedB"
                                          />
                                        }
                                      />
                                    </fieldset>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.settings.salespands === "YES" ? (
                                    <fieldset className="form-group col-md-4 p-0 m-0">
                                      <span className="mx-3">
                                        {" "}
                                        Products & Services
                                      </span>
                                      <FormControlLabel
                                        control={
                                          <IOSSwitch
                                            className="sm"
                                            checked={this.state.prosev}
                                            // onChange={this.handleProSerChange}
                                            onChange={()=>this.handleProductChange(false,false,true)}
                                            name="checkedB"
                                          />
                                        }
                                      />
                                    </fieldset>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </fieldset>
                            )}
                          </div>
                        </fieldset>
                      )}
                      <div className="form-group col-md-12 m-0 p-0" />

                      <div className="form-group col-md-12">
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ backgroundColor: "#e8ecef" }}
                          >
                            <Typography>Advance options</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FieldArray
                              name="addsettings"
                              render={(arrayHelpers) => (
                                <div className="form-group col-md-12">
                                  <div className="form-row">
                                    <fieldset className="form-group col-md-3 mt-2 m-0">
                                      <FormGroup row>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={this.state.convertionreq}
                                              onChange={
                                                this.handlemulticurrencyrequired
                                              }
                                              name="taxrequired"
                                              color="primary"
                                              disabled={
                                                this.state.grandtotal !== 0 ||
                                                this.state.grandservicetotal !==
                                                  0 ||
                                                this.state.orderfrom === 1
                                              }
                                            />
                                          }
                                          label="Multi Currency Required?"
                                        />
                                      </FormGroup>
                                    </fieldset>

                                    {this.state.convertionreq === true ? (
                                      <>
                                        <fieldset className="form-group col-md-2">
                                          <FormikTextField
                                            className="form-control"
                                            type="text"
                                            value={this.state.basecurrency}
                                            name="basecurrency"
                                            label="Base Currency"
                                            variant="standard"
                                            fullWidth
                                            disabled
                                          />{" "}
                                        </fieldset>

                                        <fieldset className="form-group col-md-2">
                                          <Autocomplete
                                            id="subaccount"
                                            className="subacc-select"
                                            name="currency"
                                            options={currencylist}
                                            getOptionLabel={(option) =>
                                              option.currency
                                            }
                                            value={
                                              values.currency
                                                ? undefined
                                                : undefined
                                            }
                                            onChange={this.handleCurrency}
                                            disabled={
                                              this.state.grandtotal !== 0 ||
                                              this.state.grandservicetotal !==
                                                0 ||
                                              this.state.orderfrom === 1
                                            }
                                            renderInput={(params) => (
                                              <FormikTextField
                                                {...params}
                                                className="form-control"
                                                type="text"
                                                name="currency"
                                                value={this.state.currency}
                                                // onBlur={(e) =>{this.handleCurrency(e.target.value,values)}}
                                                label={`Select Currency (${this.state.currency})`}
                                                variant="standard"
                                                fullWidth
                                              />
                                            )}
                                          />
                                        </fieldset>

                                        {this.state.currency ===
                                          this.state.basecurrency ||
                                        this.state.currency === undefined ||
                                        this.state.currency === "" ? (
                                          ""
                                        ) : (
                                          <fieldset className="form-group col-md-2">
                                            <FormControl
                                              fullWidth
                                              sx={{ m: 1 }}
                                              variant="standard"
                                            >
                                              <InputLabel htmlFor="standard-adornment-amount">{`Enter Conversion rate`}</InputLabel>
                                              <Input
                                                id="standard-adornment-amount"
                                                value={
                                                  this.state.curconversionrate
                                                }
                                                type="number"
                                                onChange={
                                                  this.handleCurconversionrate
                                                }
                                                onFocus={
                                                  this.oncurrencyconvtfocus
                                                }
                                                startAdornment={
                                                  <InputAdornment position="start">
                                                    1 {this.state.basecurrency}{" "}
                                                    =
                                                  </InputAdornment>
                                                }
                                                endAdornment={
                                                  <InputAdornment position="end">
                                                    {" "}
                                                    {this.state.currency}
                                                  </InputAdornment>
                                                }
                                                disabled={
                                                  this.state.grandtotal !== 0 ||
                                                  this.state
                                                    .grandservicetotal !== 0 ||
                                                  this.state.orderfrom === 1
                                                }
                                              />
                                            </FormControl>
                                          </fieldset>
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                  <fieldset className="form-group col-md-12 m-0" />
                                  {/*  {(this.state.grandtotal === 0 && (this.state.itemname ==='' || this.state.service === '')) && this.state.orderfrom === 0?<> */}
                                  <div className="form-row">
                                    <fieldset className="form-group col-md-3 mt-2 m-0">
                                      <FormGroup row>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={this.state.taxrequired}
                                              onChange={this.handletaxrequired}
                                              name="taxrequired"
                                              color="primary"
                                              disabled={
                                                this.state.grandtotal !== 0 ||
                                                this.state.itemname !== "" ||
                                                this.state.service !== ""
                                              }
                                            />
                                          }
                                          label="Tax is Applicable?"
                                        />
                                      </FormGroup>
                                    </fieldset>
                                    {this.state.taxrequired === true ? (
                                      <fieldset className="form-group col-md-3 m-0">
                                        <FormControl
                                          variant="standard"
                                          className="w-100"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            Tax Type :&nbsp;{" "}
                                            {this.state.taxtype}
                                          </InputLabel>
                                          {/* <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={this.state.taxtype}
                                    onChange={this.handleTaxTypeChange}
                                    label="Tax Type"
                                  >
                                    <MenuItem value={"GST"}>GST</MenuItem>
                                    <MenuItem value={"VAT"}>VAT</MenuItem>
                                  </Select> */}
                                        </FormControl>
                                      </fieldset>
                                    ) : (
                                      ""
                                    )}

                                    {this.state.taxrequired === true &&
                                    this.state.taxtype === "GST" &&
                                    ccountry === "India" &&
                                    this.state.custid !== "" &&
                                    this.state.custcountry === "India" ? (
                                      <fieldset className="form-group col-md-6 m-0">
                                        <FormControl
                                          variant="standard"
                                          className="w-100"
                                        >
                                          <InputLabel id="demo-simple-select-standard-label">
                                            GST Type :&nbsp;{" "}
                                            {this.state.gsttype === false
                                              ? "Intra State (With in the State)"
                                              : "Inter State (Outside the State)"}
                                          </InputLabel>
                                        </FormControl>
                                        {/* GST Type
                                {this.state.gsttype === false ? <span className="text-info">Intra State</span> : 'Intra State'} &nbsp; &nbsp;
                                {this.state.gsttype === true ? <span className="text-info"> Inter State</span> : 'Inter State'} */}
                                      </fieldset>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              )}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </div>

                      <fieldset className="form-group col-md-12 m-0" />

                      <fieldset className="form-group col-md-4 m-0">
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.agentreq}
                                onChange={() =>
                                  this.setState({
                                    agentreq: !this.state.agentreq,
                                    agent: "",
                                    agentid: "",
                                  })
                                }
                                name="ordertype"
                                color="primary"
                                disabled={this.state.orderfrom === 1}
                              />
                            }
                            label="Through Agent?"
                          />
                        </FormGroup>
                      </fieldset>
                      {this.state.agentreq === true ? (
                        <fieldset className="form-group col-md-3 mb-4">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="agent"
                            options={agentsdata}
                            getOptionLabel={(option) => option.name}
                            value={values.agent ? undefined : undefined}
                            onChange={this.handleAgent}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control"
                                type="text"
                                name="agent"
                                value={this.state.agent}
                                label={`Select Agent (${this.state.agent})`}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>
                      ) : (
                        ""
                      )}
                      <fieldset className="form-group col-md-12 m-0 p-0" />
                      {this.state.salestype === "1" ? (
                        ""
                      ) : (
                        <fieldset className="form-group col-md-3">
                          {WarehouseList.length === 1 ? (
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="warehouse"
                              value={this.state.warehouse}
                              label={`Select Org Unit`}
                              variant="standard"
                              fullwhidth="true"
                              disabled
                            />
                          ) : (
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="warehouse"
                              options={WarehouseList}
                              getOptionLabel={(option) => option.name}
                              getOptionSelected={(option) => option.name}
                              value={values.warehouse ? undefined : undefined}
                              onChange={this.handleWarehouse}
                              disabled={this.state.grandtotal !== 0}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="warehouse"
                                  value={this.state.warehouse}
                                  label={`Select Org Unit (${this.state.warehouse})`}
                                  variant="standard"
                                  fullwhidth="true"
                                  disabled={this.state.grandtotal !== 0}
                                />
                              )}
                            />
                          )}
                        </fieldset>
                      )}
                      <fieldset className="form-group col-md-3 mb-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="customer"
                          options={custom}
                          getOptionLabel={(option) => option.companyname}
                          value={values.customer ? undefined : undefined}
                          onChange={this.handleCustomer}
                          disabled={this.state.grandtotal !== 0}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="customer"
                              value={this.state.customer}
                              label={`Select Customer (${this.state.customer})`}
                              variant="standard"
                              disabled={this.state.grandtotal !== 0}
                              fullwhidth="true"
                            />
                          )}
                        />
                      </fieldset>
                      {this.state.salestype === "1" ? (
                        ""
                      ) : (
                        <>
                          {this.state.ordertype === true ? (
                            <>
                              <fieldset className="form-group col-md-3 ">
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="category"
                                  value={this.state.category}
                                  label="Category"
                                  variant="standard"
                                  fullWidth
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-3 ">
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="itemname"
                                  value={this.state.itemname}
                                  label="Item"
                                  variant="standard"
                                  fullWidth
                                />
                              </fieldset>
                            </>
                          ) : (
                            <>
                              <fieldset className="form-group col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="category"
                                  options={CategoryList}
                                  getOptionLabel={(option) => option.category}
                                  value={
                                    values.category ? undefined : undefined
                                  }
                                  onChange={this.handleCategory}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="category"
                                      value={this.state.category}
                                      label={`Select Category (${this.state.category})`}
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>

                              <fieldset className="form-group col-md-3 ">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="itemname"
                                  options={filteritemlist}
                                  getOptionLabel={(option) => option.name}
                                  value={
                                    values.itemname ? undefined : undefined
                                  }
                                  onChange={this.handleitem}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="itemname"
                                      value={this.state.itemname}
                                      label={`Select Item (${this.state.itemname})`}
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>
                            </>
                          )}
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              id="cost"
                              className="form-control"
                              type="number"
                              min="0"
                              name="cost"
                              value={
                                this.state.cost === "" ? "0" : this.state.cost
                              }
                              onChange={(e) =>
                                this.setState({
                                  cost:
                                    e.target.value === undefined
                                      ? 0
                                      : e.target.value,
                                  totalpricewtax: "",
                                })
                              }
                              onFocus={this.oncostfocus}
                              label={`Price (Type: ${this.state.type})`}
                              variant="standard"
                              fullWidth
                              disabled={
                                this.state.orderfrom === 1 ||
                                this.state.settings.soeditprice === "NO"
                              }
                            />
                          </fieldset>
                          {this.state.settings.allowextramakup ==="YES"?
                          <fieldset className="form-group col-md-3">
                          <FormikTextField
                            id="markup1"
                            className="form-control"
                            type="number"
                            name="markup1"
                            label={"Extra markup"}
                            min="0"
                            value={this.state.markup1}
                            onChange={this.handlemarkup1Change}
                          />
                        </fieldset>
                        :""}

                          {this.state.salescalreq === "YES" &&
                          this.state.settings.soeditprice === "YES" ? (
                            <fieldset className="form-group col-md-3">
                              <FormControl variant="standard" fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">
                                  Type
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={this.state.type}
                                  onChange={this.handleTypeChange}
                                  label="Type"
                                >
                                  <MenuItem value={"Average"}>Average</MenuItem>
                                  <MenuItem value={"FIFO"}>FIFO</MenuItem>
                                  <MenuItem value={"LIFO"}>LIFO</MenuItem>
                                </Select>
                              </FormControl>
                            </fieldset>
                          ) : (
                            ""
                          )}

                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              id="quantity"
                              className="form-control"
                              type="number"
                              name="quantity"
                              label={`Quantity (Available: ${
                                this.state.stock === undefined ||
                                this.state.stock === null
                                  ? 0
                                  : this.state.stock
                              })`}
                              min="0"
                              onChange={(e) =>
                                this.setState({
                                  quantity: e.target.value,
                                  totalpricewtax: "",
                                  availabelcredit: this.state.creditvalue,
                                })
                              }
                              onFocus={this.onfocus}
                            />
                          </fieldset>
                          {this.state.basecurrency === this.state.currency ? (
                            ""
                          ) : (
                            <fieldset className="form-group col-md-3">
                              <FormikTextField
                                id="pricevariance"
                                className="form-control"
                                type="number"
                                name="pricevariance"
                                label={"Price variance in %"}
                                min="0"
                                onChange={(e) =>
                                  this.setState({
                                    pricevariance: e.target.value,
                                    totalpricewtax: "",
                                    availabelcredit: this.state.creditvalue,
                                  })
                                }
                                onFocus={this.onpricevariancefocus}
                              />
                            </fieldset>
                          )}
                          {this.state.taxrequired === true ? (
                            <>
                              <fieldset className="form-group col-md-3">
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="hsn"
                                  value={this.state.hsn}
                                  label="HSN Code"
                                  variant="standard"
                                  fullWidth
                                  disabled
                                />
                              </fieldset>

                              {this.state.taxtype === "GST" &&
                              ccountry === "India" ? (
                                this.state.gsttype === false ? (
                                  <>
                                    <fieldset className="form-group col-md-3">
                                      <FormikTextField
                                        className="form-control"
                                        type="number"
                                        name="cgst"
                                        value={this.state.tax / 2}
                                        label="CGST (%)"
                                        variant="standard"
                                        fullWidth
                                        disabled
                                      />
                                    </fieldset>
                                    <fieldset className="form-group col-md-3">
                                      <FormikTextField
                                        className="form-control"
                                        type="number"
                                        name="sgst"
                                        value={this.state.tax / 2}
                                        label="SGST (%)"
                                        variant="standard"
                                        fullWidth
                                        disabled
                                      />
                                    </fieldset>
                                  </>
                                ) : (
                                  <>
                                    <fieldset className="form-group col-md-3">
                                      <FormikTextField
                                        className="form-control"
                                        type="number"
                                        name="tax"
                                        value={this.state.tax}
                                        label="IGST (%)"
                                        variant="standard"
                                        fullWidth
                                        disabled
                                      />
                                    </fieldset>
                                  </>
                                )
                              ) : (
                                <>
                                  {this.state.taxtype === "GST" ? (
                                    <fieldset className="form-group col-md-3">
                                      <FormikTextField
                                        className="form-control"
                                        type="number"
                                        name="tax"
                                        value={this.state.tax}
                                        label="GST (%)"
                                        variant="standard"
                                        fullWidth
                                        disabled
                                      />
                                    </fieldset>
                                  ) : (
                                    <fieldset className="form-group col-md-3">
                                      <FormikTextField
                                        className="form-control"
                                        type="number"
                                        name="vat"
                                        label="VAT (%)"
                                        value={this.state.tax}
                                        min="0"
                                        disabled
                                      />
                                    </fieldset>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            ""
                          )}

                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="uom"
                              value={this.state.uom}
                              label={`UOM`}
                              variant="standard"
                              fullWidth
                              disabled
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3 ">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="brand"
                              label="Brand"
                              value={this.state.brand}
                              disabled
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-3 ">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="model"
                              label="Model"
                              value={this.state.model}
                              disabled
                            />
                          </fieldset>

                          {this.state.additionalparams === 0 ? (
                            ""
                          ) : (
                            <>
                              <fieldset className="form-group col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="size"
                                  options={sizelist}
                                  getOptionLabel={(option) => option}
                                  value={values.size ? undefined : undefined}
                                  onChange={this.handleSize}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="size"
                                      value={this.state.size}
                                      label={`Select Size (${
                                        this.state.size === null ||
                                        this.state.size === undefined
                                          ? ""
                                          : this.state.size
                                      })`}
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>

                              <fieldset className="form-group col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="colour"
                                  options={colourlist}
                                  getOptionLabel={(option) => option}
                                  value={values.colour ? undefined : undefined}
                                  onChange={this.handleColour}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="colour"
                                      value={this.state.colour}
                                      label={`Select Colour (${
                                        this.state.colour === null ||
                                        this.state.colour === undefined
                                          ? ""
                                          : this.state.colour
                                      })`}
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>

                              <fieldset className="form-group col-md-3 ">
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="thikness"
                                  value={this.state.thickness}
                                  onChange={(e) =>
                                    this.setState({ thickness: e.target.value })
                                  }
                                  label={`Thickness (${this.state.thickness})`}
                                  variant="standard"
                                  fullWidth
                                />
                              </fieldset>

                              <fieldset className="form-group col-md-3 ">
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="totalmtrroll"
                                  value={this.state.totalmtrroll}
                                  onChange={(e) =>
                                    this.setState({
                                      totalmtrroll: e.target.value,
                                    })
                                  }
                                  label="TotalMtrRoll"
                                  variant="standard"
                                  fullWidth
                                />
                              </fieldset>
                            </>
                          )}
                          <fieldset className="form-group col-md-12  mb-5">
                            <FormikTextField
                              name="description"
                              className="form-control"
                              multiline
                              rows={2}
                              label="Description"
                              variant="outlined"
                              value={this.state.description}
                              onChange={(e) =>
                                this.setState({ description: e.target.value })
                              }
                            />
                          </fieldset>
                          {/* </div> */}

                          {/* <div className="form-group col-md-12" /> */}

                          {/* <fieldset className="form-group col-md-12 mt-1" /> */}
                            
                            {this.state.totalpricewtax === "" || this.state.totalpricewtax === null || this.state.totalpricewtax === 0?"":
                          <fieldset className="form-group col-md-4 text-sm">
                            <>Total Price</>
                            <>
                              &nbsp; : &nbsp;
                              <NumericFormat
                                displayType={"text"}
                                value={
                                  this.state.totalpricewtax === ""
                                    ? 0
                                    : this.state.totalpricewtax
                                }
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </>
                          </fieldset>}

                          {this.state.creditstatus === "Required" ? (
                            <>
                              <fieldset className="form-group col-md-4 text-sm">
                                <>Available Credit&nbsp;:&nbsp;</>
                                <>
                                  <NumericFormat
                                    displayType={"text"}
                                    value={
                                      this.state.availabelcredit === "" ||
                                      this.state.availabelcredit === undefined
                                        ? 0
                                        : this.state.availabelcredit
                                    }
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                </>
                              </fieldset>
                              <fieldset className="form-group col-md-4 text-sm">
                                <>Credit Value&nbsp;:&nbsp;</>
                                <>
                                  <NumericFormat
                                    displayType={"text"}
                                    value={
                                      this.state.initialCredit === "" ||
                                      this.state.initialCredit === undefined
                                        ? 0
                                        : this.state.initialCredit
                                    }
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                </>
                              </fieldset>
                              {/* <fieldset className="form-group col-md-3">
                                <>Credit Period</>
                                <>
                                  &nbsp; : &nbsp;{" "}
                                  {this.state.creditperiod === ""
                                    ? "0"
                                    : this.state.creditperiod}{" "}
                                  days
                                </>
                              </fieldset> */}
                            </>
                          ) : (
                            ""
                          )}

                          <div className="form-group col-md-12 m-0 p-0" />

                          {this.state.availabelcredit < 0 ? (
                            <>
                              <p className="text-red">
                                Execeded Credit Value, Requsest for credit
                              </p>

                              <button
                                className="btn btn-sm hovbuttonColour"
                                onClick={this.handleRequestCredit}
                                type="button"
                              >
                                Request Credit Value
                              </button>
                            </>
                          ) : (
                            <>
                                <button
                                  className="btn btn-sm hovbuttonColour"
                                  type="submit"
                                >
                                  Add Item to Order
                                </button>
                            </>
                          )}

                          <div className="form-group col-md-12" />
                          {this.state.grandtotal === 0 || "" ? (
                            ""
                          ) : (
                            <>
                              <TableContainer>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow className="text-center">
                                      <TableCell></TableCell>
                                      <TableCell className="text-center">
                                        Itemname
                                      </TableCell>
                                      {this.state.taxrequired === true ? (
                                        <TableCell className="text-center">
                                          HSN Code
                                        </TableCell>
                                      ) : (
                                        ""
                                      )}
                                      <TableCell className="text-center">
                                        Price
                                      </TableCell>
                                      {this.state.currency ===
                                      this.state.basecurrency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-center">
                                          Price({this.state.currency})
                                        </TableCell>
                                      )}
                                      {this.state.currency ===
                                      this.state.basecurrency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-center">
                                          Pricevariance (%)
                                        </TableCell>
                                      )}
                                      <TableCell className="text-center">
                                        Sales Type
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                        UOM
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Total{" "}
                                      </TableCell>
                                      {this.state.currency ===
                                      this.state.basecurrency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-center">
                                          Total ({this.state.currency})
                                        </TableCell>
                                      )}
                                      {this.state.taxrequired === true ? (
                                        <>
                                          {this.state.taxtype === "GST" &&
                                          ccountry === "India" ? (
                                            this.state.gsttype === false ? (
                                              <>
                                                <TableCell className="text-center">
                                                  CGST
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  SGST
                                                </TableCell>
                                                {this.state.currency ===
                                                this.state.basecurrency ? (
                                                  ""
                                                ) : (
                                                  <>
                                                    {" "}
                                                    <TableCell className="text-center">
                                                      CGST({this.state.currency}
                                                      )
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      SGST({this.state.currency}
                                                      )
                                                    </TableCell>
                                                  </>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                <TableCell className="text-center">
                                                  IGST
                                                </TableCell>
                                                {this.state.currency ===
                                                this.state.basecurrency ? (
                                                  ""
                                                ) : (
                                                  <TableCell className="text-center">
                                                    IGST({this.state.currency})
                                                  </TableCell>
                                                )}
                                              </>
                                            )
                                          ) : this.state.taxtype === "GST" ? (
                                            <>
                                              <TableCell className="text-center">
                                                GST
                                              </TableCell>
                                              {this.state.currency ===
                                              this.state.basecurrency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  GST({this.state.currency})
                                                </TableCell>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              {" "}
                                              <TableCell className="text-center">
                                                VAT
                                              </TableCell>
                                              {this.state.currency ===
                                              this.state.basecurrency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  VAT({this.state.currency})
                                                </TableCell>
                                              )}
                                            </>
                                          )}
                                          <TableCell className="text-center">
                                            Total Price
                                          </TableCell>
                                          {this.state.currency ===
                                          this.state.basecurrency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              Total Price({this.state.currency})
                                            </TableCell>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <TableCell className="text-center">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.listData.map((data, index) => (
                                      <React.Fragment>
                                        <TableRow key={index}>
                                          <TableCell className="text-center">
                                            <IconButton
                                              aria-label="expand row"
                                              size="small"
                                              onClick={() =>
                                                this.setState({
                                                  open: !this.state.open,
                                                })
                                              }
                                            >
                                              {this.state.open ? (
                                                <KeyboardArrowUpIcon />
                                              ) : (
                                                <KeyboardArrowDownIcon />
                                              )}
                                            </IconButton>
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {data.name}
                                          </TableCell>
                                          {this.state.taxrequired === true ? (
                                            <TableCell className="text-center">
                                              {data.hsn}
                                            </TableCell>
                                          ) : (
                                            ""
                                          )}
                                          <TableCell className="text-right">
                                            {data.price}
                                          </TableCell>
                                          {this.state.currency ===
                                          this.state.basecurrency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              {data.price *
                                                this.state.curconversionrate}
                                            </TableCell>
                                          )}
                                          {this.state.currency ===
                                          this.state.basecurrency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              {data.pricevariance}
                                            </TableCell>
                                          )}
                                          <TableCell className="text-center">
                                            {data.salesenum}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {data.quantity}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {data.uom}
                                          </TableCell>
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={data.totalprice}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.currency ===
                                          this.state.basecurrency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  data.totalprice *
                                                  this.state.curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          {this.state.taxrequired === true ? (
                                            <>
                                              {this.state.taxtype === "GST" &&
                                              this.state.gsttype === false ? (
                                                <>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={data.taxamount / 2}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                    &nbsp;({data.tax / 2}%)
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={data.taxamount / 2}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                    &nbsp;({data.tax / 2}%)
                                                  </TableCell>
                                                  {this.state.currency ===
                                                  this.state.basecurrency ? (
                                                    ""
                                                  ) : (
                                                    <>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            (data.taxamount /
                                                              2) *
                                                            this.state
                                                              .curconversionrate
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                        &nbsp;({data.tax / 2}%)
                                                      </TableCell>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            (data.taxamount /
                                                              2) *
                                                            this.state
                                                              .curconversionrate
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                        &nbsp;({data.tax / 2}%)
                                                      </TableCell>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={data.taxamount}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                    &nbsp;({data.tax}%)
                                                  </TableCell>
                                                  {this.state.currency ===
                                                  this.state.basecurrency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={
                                                          data.taxamount *
                                                          this.state
                                                            .curconversionrate
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      >
                                                        &nbsp;({data.tax}%)
                                                      </NumericFormat>
                                                    </TableCell>
                                                  )}
                                                </>
                                              )}
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={data.totalpricewtax}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.currency ===
                                              this.state.basecurrency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      data.totalpricewtax *
                                                      this.state
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          <TableCell className="text-center">
                                            <span
                                              as="button"
                                              data-tip
                                              data-for="delete"
                                              className="fa fa-trash text-danger"
                                              style={{ paddingLeft: "7px" }}
                                              onClick={() =>
                                                this.removeItemFromList(index)
                                              }
                                            ></span>
                                          </TableCell>
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            style={{
                                              paddingBottom: 0,
                                              paddingTop: 0,
                                            }}
                                            colSpan={6}
                                          >
                                            <Collapse
                                              in={this.state.open}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <Box margin={1}>
                                                <Table
                                                  size="small"
                                                  aria-label="purchases"
                                                >
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell align="center">
                                                        Brand
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        Model
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        Colour
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        Size
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        Thickness
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        TotalMtrRoll
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        Description
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    <TableRow key={index}>
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                      >
                                                        {data.brand}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {data.model}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {data.colour}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {data.size}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {data.thickness}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {data.totalmtrroll}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {data.description}
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableBody>
                                                </Table>
                                              </Box>
                                            </Collapse>
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    ))}
                                    <TableRow
                                      style={{
                                        textAlign: "center",
                                        backgroundColor: "white",
                                      }}
                                    >
                                      <TableCell className="text-center">
                                        Total
                                      </TableCell>
                                      {this.state.taxrequired === true ? (
                                        <TableCell></TableCell>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.currency ===
                                      this.state.basecurrency ? (
                                        ""
                                      ) : (
                                        <>
                                          <TableCell />
                                          <TableCell />
                                        </>
                                      )}
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      <TableCell className="text-right">
                                        <NumericFormat
                                          displayType={"text"}
                                          value={
                                            this.state.grandtotal -
                                            this.state.totaltaxamount
                                          }
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                      {this.state.currency ===
                                      this.state.basecurrency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            value={
                                              (this.state.grandtotal -
                                                this.state.totaltaxamount) *
                                              this.state.curconversionrate
                                            }
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                      )}
                                      {this.state.taxrequired === true ? (
                                        <>
                                          {this.state.taxtype === "GST" &&
                                          this.state.gsttype === false ? (
                                            <>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    this.state.totaltaxamount /
                                                    2
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    this.state.totaltaxamount /
                                                    2
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                            </>
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  this.state.totaltaxamount
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          {this.state.currency ===
                                          this.state.basecurrency ? (
                                            ""
                                          ) : (
                                            <>
                                              {this.state.taxtype === "GST" &&
                                              this.state.gsttype === false ? (
                                                <>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        (this.state
                                                          .totaltaxamount /
                                                          2) *
                                                        this.state
                                                          .curconversionrate
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        (this.state
                                                          .totaltaxamount /
                                                          2) *
                                                        this.state
                                                          .curconversionrate
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      this.state
                                                        .totaltaxamount *
                                                      this.state
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                            </>
                                          )}
                                          <TableCell className="text-right">
                                            {this.state.grandtotal === "" ? (
                                              "0"
                                            ) : (
                                              <NumericFormat
                                                displayType={"text"}
                                                value={this.state.grandtotal}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            )}
                                          </TableCell>
                                          {this.state.currency ===
                                          this.state.basecurrency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              {this.state.grandtotal === "" ? (
                                                "0"
                                              ) : (
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    this.state.grandtotal *
                                                    this.state.curconversionrate
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              )}
                                            </TableCell>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <TableCell></TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          )}
                        </>
                      )}
                    </div>
                    <div className="form-group col-md-12" />

                    {this.state.salestype !== "0" ? (
                      <div className="form-row">
                        <fieldset className="form-group col-md-3">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="servicecategory"
                            options={ServiceCategoryList}
                            getOptionLabel={(option) => option.category}
                            value={
                              values.servicecategory ? undefined : undefined
                            }
                            onChange={this.handleServicecategory}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control"
                                type="text"
                                name="servicecategory"
                                value={this.state.servicecategory}
                                label={`Select Service Category (${this.state.servicecategory})`}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>

                        <fieldset className="form-group col-md-3">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="service"
                            options={filterserv}
                            getOptionLabel={(option) => option.service}
                            value={values.service ? undefined : undefined}
                            onChange={this.handleService}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control"
                                type="text"
                                name="service"
                                value={this.state.service}
                                label={`Select Service (${this.state.service})`}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>
                        {this.state.taxrequired === true ? (
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="servicecode"
                              label="Code"
                              value={this.state.servicecode}
                              disabled
                            />
                          </fieldset>
                        ) : (
                          ""
                        )}

                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            id="amount"
                            className="form-control"
                            type="number"
                            name="servicecost"
                            label="Amount"
                            onChange={this.handleServiceAmountChange}
                            onFocus={this.onamountfocus}
                          />
                        </fieldset>
                        {this.state.currency === this.state.basecurrency ? (
                          ""
                        ) : (
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              id="amount"
                              className="form-control"
                              type="number"
                              name="servicecostconv"
                              label={`Amount in ${this.state.currency}`}
                              value={this.state.servicecostconv}
                              disabled
                            />
                          </fieldset>
                        )}
                        {this.state.taxrequired === true ? (
                          <>
                            {this.state.taxtype === "GST" &&
                            ccountry === "India" ? (
                              <>
                                {this.state.gsttype === false ? (
                                  <>
                                    <fieldset className="form-group col-md-3">
                                      <FormikTextField
                                        className="form-control"
                                        type="number"
                                        name="servicecgst"
                                        label={`CGST (${
                                          this.state.servicetax === ""
                                            ? 0
                                            : this.state.servicetax / 2
                                        }%)`}
                                        value={this.state.servicecgst}
                                        disabled
                                      />
                                    </fieldset>
                                    <fieldset className="form-group col-md-3">
                                      <FormikTextField
                                        className="form-control"
                                        type="number"
                                        name="servicesgst"
                                        label={`SGST (${
                                          this.state.servicetax === ""
                                            ? 0
                                            : this.state.servicetax / 2
                                        }%)`}
                                        value={this.state.servicesgst}
                                        disabled
                                      />
                                    </fieldset>
                                  </>
                                ) : (
                                  <fieldset className="form-group col-md-3">
                                    <FormikTextField
                                      className="form-control"
                                      type="number"
                                      name="serviceigst"
                                      label={`IGST (${
                                        this.state.servicetax === ""
                                          ? 0
                                          : this.state.servicetax
                                      }%)`}
                                      value={this.state.serviceigst}
                                      disabled
                                    />
                                  </fieldset>
                                )}
                              </>
                            ) : this.state.taxtype === "GST" ? (
                              <fieldset className="form-group col-md-3">
                                <FormikTextField
                                  className="form-control"
                                  type="number"
                                  name="servicegst"
                                  label={`GST (${
                                    this.state.servicetax === ""
                                      ? 0
                                      : this.state.servicetax
                                  }%)`}
                                  value={this.state.servicegst}
                                  disabled
                                />
                              </fieldset>
                            ) : (
                              <fieldset className="form-group col-md-3">
                                <FormikTextField
                                  className="form-control"
                                  type="number"
                                  name="servicevat"
                                  label={`VAT (${
                                    this.state.servicetax === ""
                                      ? 0
                                      : this.state.servicetax
                                  }%)`}
                                  value={this.state.servicevat}
                                  disabled
                                />
                              </fieldset>
                            )}

                            <fieldset className="form-group col-md-3">
                              <FormikTextField
                                className="form-control"
                                type="number"
                                name="servicecostwttax"
                                label="Total Amount"
                                value={this.state.servicecostwttax}
                                disabled
                              />
                            </fieldset>

                            {this.state.currency === this.state.basecurrency ? (
                              ""
                            ) : (
                              <fieldset className="form-group col-md-3">
                                <FormikTextField
                                  className="form-control"
                                  type="number"
                                  name="servicecostwttaxconv"
                                  label={`Total Amount in ${this.state.currency}`}
                                  value={this.state.servicecostwttaxconv}
                                  disabled
                                />
                              </fieldset>
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {this.state.salestype === "1" ? (
                          <>
                            <fieldset className="form-group col-md-3">
                              <Autocomplete
                                id="subaccount"
                                className="subacc-select"
                                name="contracttype"
                                options={contracttypeList}
                                getOptionLabel={(option) => option}
                                value={
                                  values.contracttype ? undefined : undefined
                                }
                                onChange={this.handleContracttype}
                                renderInput={(params) => (
                                  <FormikTextField
                                    {...params}
                                    className="form-control"
                                    type="text"
                                    name="contracttype"
                                    value={this.state.contracttype}
                                    label="Select Contract Type"
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>

                            {this.state.contracttype === "Recurring" ? (
                              <>
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="contracttime"
                                    options={recurringtype}
                                    getOptionLabel={(option) => option}
                                    value={
                                      values.contracttime
                                        ? undefined
                                        : undefined
                                    }
                                    onChange={this.handlecontracttime}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="contracttime"
                                        value={this.state.contracttime}
                                        label="Select Contract Time"
                                        variant="standard"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>
                                <fieldset className="mr-4">
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-around"
                                    >
                                      <DatePicker
                                        variant="inline"
                                        margin="none ml-2"
                                        id="date-picker-dialog"
                                        label="Recurring Till"
                                        format="dd/MM/yyyy"
                                        minDate={new Date()}
                                        value={this.state.contractstartdate}
                                        onChange={this.handleStartDateChange}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </LocalizationProvider>
                                </fieldset>
                                {/* <fieldset>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid container justifyContent="space-around">
                                  <DatePicker
                                    variant="inline"
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="End Date"
                                    format="dd/MM/yyyy"
                                    minDate={new Date()}
                                    value={this.state.contractenddate}
                                    onChange={this.handleEndDateChange}
                                    KeyboardButtonProps={{
                                      'aria-label': 'change date',
                                    }}
                                  />
                                </Grid>
                              </LocalizationProvider>
                            </fieldset> */}
                              </>
                            ) : (
                              ""
                            )}
                            <fieldset className="form-group col-md-12  mt-3 mb-5">
                              <FormikTextField
                                name="servicedescription"
                                className="form-control"
                                multiline
                                rows={2}
                                label="Description"
                                variant="outlined"
                                value={this.state.servicedescription}
                                onChange={(e) =>
                                  this.setState({
                                    servicedescription: e.target.value,
                                  })
                                }
                              />
                            </fieldset>
                            {this.state.contracttype === "Milestone" ? (
                              <div className="form-group col-md-12">
                                <Accordion expanded={true}>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ backgroundColor: "#e8ecef" }}
                                  >
                                    <Typography>MileStones</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <FieldArray
                                      name="milestone"
                                      render={(arrayHelpers) => (
                                        <div className="form-group col-md-12">
                                          {values.milestone.map(
                                            (data, index) => (
                                              <div
                                                key={index}
                                                className="form-row"
                                              >
                                                {/** both these conventions do the same */}
                                                <fieldset className="form-group col-md-2">
                                                  <FormikTextField
                                                    className="form-control"
                                                    name={`milestone[${index}].name`}
                                                    label="Name"
                                                    variant="standard"
                                                    fullWidth
                                                  />
                                                </fieldset>
                                                <fieldset className="form-group col-md-2">
                                                  <FormikTextField
                                                    className="form-control"
                                                    id="amount"
                                                    type="number"
                                                    min={1}
                                                    max={100}
                                                    name={`milestone.${index}.percentage`}
                                                    label="Percentage (%)"
                                                    variant="standard"
                                                    onBlur={() =>
                                                      setFieldValue(
                                                        `milestone.${index}.amountwttax`,
                                                        (data.percentage /
                                                          100) *
                                                          (parseFloat(
                                                            this.state
                                                              .servicecost
                                                          ) +
                                                            (servicetax / 100) *
                                                              parseFloat(
                                                                this.state
                                                                  .servicecost
                                                              ))
                                                      ) &&
                                                      setFieldValue(
                                                        `milestone.${index}.amount`,
                                                        (data.percentage /
                                                          100) *
                                                          parseFloat(
                                                            this.state
                                                              .servicecost
                                                          )
                                                      )
                                                    }
                                                    fullWidth
                                                  />
                                                </fieldset>
                                                <fieldset className="form-group col-md-2">
                                                  <FormikTextField
                                                    className="form-control"
                                                    type="number"
                                                    name={`milestone.${index}.amount`}
                                                    label="Amount"
                                                    variant="standard"
                                                    fullWidth
                                                    disabled
                                                  ></FormikTextField>
                                                </fieldset>
                                                {this.state.taxrequired ===
                                                true ? (
                                                  <fieldset className="form-group col-md-2">
                                                    <FormikTextField
                                                      className="form-control"
                                                      type="number"
                                                      name={`milestone.${index}.amountwttax`}
                                                      label="Amount inc.Tax"
                                                      variant="standard"
                                                      fullWidth
                                                      disabled
                                                    ></FormikTextField>
                                                  </fieldset>
                                                ) : (
                                                  ""
                                                )}
                                                <fieldset className="form-group col-md-3">
                                                  <FormikTextField
                                                    id="date"
                                                    label="Expected Date"
                                                    name={`milestone.${index}.expecteddate1`}
                                                    type="date"
                                                    minDate={new Date()}
                                                    className={
                                                      useStyles.textField
                                                    }
                                                    InputLabelProps={{
                                                      shrink: true,
                                                    }}
                                                  />
                                                </fieldset>
                                                {values.milestone.length !==
                                                1 ? (
                                                  <span
                                                    type="button"
                                                    className="fa fa-minus-square text-danger mt-4"
                                                    onClick={() =>
                                                      arrayHelpers.remove(index)
                                                    }
                                                  ></span>
                                                ) : (
                                                  ""
                                                )}
                                                <span
                                                  type="button"
                                                  className="fa fa-plus-square text-info mt-4 ml-3"
                                                  onClick={() =>
                                                    arrayHelpers.push({
                                                      name: "",
                                                      expecteddate1: "",
                                                      percentage: 0,
                                                      amount: 0,
                                                      amountwttax: 0,
                                                    })
                                                  }
                                                ></span>
                                              </div>
                                            )
                                          )}
                                          <p
                                            className={`${
                                              this.state.percentagemessage ===
                                              "Milestones Saved Scussesfully"
                                                ? "text-green"
                                                : "text-danger"
                                            }`}
                                          >
                                            {this.state.percentagemessage}
                                          </p>
                                          <span
                                            as="button"
                                            className="btn btn-sm float-right"
                                            onClick={() =>
                                              this.handlemilestonevalues(
                                                values.milestone
                                              )
                                            }
                                          >
                                            <i
                                              className={`${
                                                this.state.milestonesavebtn ===
                                                false
                                                  ? "fa fa-save"
                                                  : "fa fa-check text-green"
                                              } mr-1`}
                                            />
                                            Save
                                          </span>
                                        </div>
                                      )}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                                {this.state.milestone.length === 0 ? (
                                  ""
                                ) : (
                                  <TableContainer>
                                    <Table className="table-sm mt-4">
                                      <TableHead>
                                        <TableRow>MileStones</TableRow>
                                      </TableHead>
                                      <TableHead className="text-sm">
                                        <TableRow
                                          style={{ textAlign: "center" }}
                                        >
                                          <TableCell className="text-center">
                                            Name
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Expected Date
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Percentage (%)
                                          </TableCell>
                                          <TableCell className="text-center">
                                            Amount
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {this.state.milestone.map(
                                          (data, index) => (
                                            <TableRow key={index}>
                                              <TableCell className="text-center">
                                                {data.name}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {moment(
                                                  data.expecteddate1
                                                ).format("DD-MM-YYYY")}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {data.percentage} %
                                              </TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={data.amount}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            <fieldset className="form-group col-md-12" />
                          </>
                        ) : (
                          ""
                        )}

                        {this.state.salestype === "1" ? (
                          ""
                        ) : (
                          <>
                            <fieldset className="form-group col-md-12  mt-3 mb-5">
                              <FormikTextField
                                name="servicedescription"
                                className="form-control"
                                multiline
                                rows={2}
                                label="Description"
                                variant="outlined"
                                value={this.state.servicedescription}
                                onChange={(e) =>
                                  this.setState({
                                    servicedescription: e.target.value,
                                  })
                                }
                              />
                            </fieldset>
                            <span
                              as="button"
                              className="btn btn-sm hovbuttonColour m-0"
                              onClick={this.handleaddService}
                            >
                              Add Service
                            </span>

                            <div className="form-group col-md-12 text-red text-sm">
                              {this.state.serviceerr}
                            </div>
                            {this.state.serviceList.length === 0 ? (
                              ""
                            ) : (
                              <TableContainer>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow style={{ textAlign: "center" }}>
                                      <TableCell>Service</TableCell>
                                      {/* <TableCell>Service Category</TableCell> */}
                                      <TableCell>Service Code</TableCell>
                                      <TableCell>Service Description</TableCell>
                                      <TableCell>Service Cost</TableCell>
                                      {this.state.taxrequired === true ? (
                                        <>
                                          {this.state.taxtype === "GST" &&
                                          ccountry === "India" ? (
                                            this.state.gsttype === false ? (
                                              <>
                                                <TableCell>CGST(%)</TableCell>
                                                <TableCell>SGST(%)</TableCell>
                                              </>
                                            ) : (
                                              <TableCell>IGST</TableCell>
                                            )
                                          ) : this.state.taxtype === "GST" ? (
                                            <TableCell>GST</TableCell>
                                          ) : (
                                            <TableCell>VAT</TableCell>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <TableCell>Service Total</TableCell>
                                      {this.state.currency ===
                                      this.state.basecurrency ? (
                                        ""
                                      ) : (
                                        <TableCell>
                                          Service Total ({this.state.currency})
                                        </TableCell>
                                      )}
                                      <TableCell>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.serviceList.map(
                                      (data, index) => (
                                        <TableRow
                                          key={index}
                                          style={{ textAlign: "center" }}
                                        >
                                          <TableCell>{data.service}</TableCell>
                                          <TableCell>
                                            {data.servicecode}
                                          </TableCell>
                                          <TableCell>
                                            {data.description}
                                          </TableCell>
                                          <TableCell>
                                            <NumericFormat
                                              displayType={"text"}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              value={data.servicecost}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.taxrequired === true ? (
                                            <>
                                              {this.state.taxtype === "GST" &&
                                              ccountry === "India" &&
                                              this.state.gsttype === false ? (
                                                <>
                                                  <TableCell>
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      value={
                                                        data.servicetax / 2
                                                      }
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  <TableCell>
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      value={
                                                        data.servicetax / 2
                                                      }
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <TableCell>
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    value={data.servicetax}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          <TableCell>
                                            <NumericFormat
                                              displayType={"text"}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              value={(data.servicetotal === undefined?data.servicetotalwtax:data.servicetotal)}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.currency ===
                                          this.state.basecurrency ? (
                                            ""
                                          ) : (
                                            <TableCell>
                                              <NumericFormat
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                value={
                                                  (data.servicetotal === undefined?data.servicetotalwtax:data.servicetotal) *
                                                  this.state.curconversionrate
                                                }
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          <TableCell>
                                            <span
                                              as="button"
                                              data-tip
                                              data-for="delete"
                                              className="fa fa-trash text-danger"
                                              style={{ paddingLeft: "7px" }}
                                              onClick={() =>
                                                this.removeServiceFromList(
                                                  index
                                                )
                                              }
                                            ></span>
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                    <TableRow style={{ textAlign: "center" }}>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      {this.state.taxrequired === true ? (
                                        <>
                                          <TableCell></TableCell>
                                          {this.state.taxtype === "GST" &&
                                          ccountry === "India" &&
                                          this.state.gsttype === false ? (
                                            <TableCell></TableCell>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <TableCell>
                                        {this.state.grandservicetotal === "" ? (
                                          0
                                        ) : (
                                          <NumericFormat
                                            displayType={"text"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            value={this.state.grandservicetotal}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        )}
                                      </TableCell>
                                      {this.state.currency ===
                                      this.state.basecurrency ? (
                                        ""
                                      ) : (
                                        <TableCell>
                                          {this.state.grandservicetotal ===
                                          "" ? (
                                            0
                                          ) : (
                                            <NumericFormat
                                              displayType={"text"}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              value={
                                                this.state.grandservicetotal *
                                                this.state.curconversionrate
                                              }
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          )}
                                        </TableCell>
                                      )}
                                      <TableCell></TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="form-row mt-2">
                      {/* Payment Terms : */}
                      <fieldset className="form-group col-md-3 text-md">
                        <Box>
                          <Typography id="input-slider" gutterBottom className="d-flex">
                          <fieldset className="form-group col-md-6 text-md"> Payment Terms : </fieldset>
                          <fieldset className="form-group col-md-6 text-md">
                            <FormikTextField
                              className="form-control"
                              type="number"
                              name="paymentterms"
                              onChange={(e)=>this.setState({paymentterms:parseInt(e.target.value)>120?120:parseInt(e.target.value)})}
                              label="Days"
                              variant="standard"
                            />
                            </fieldset>
                          </Typography>
                          <Grid container spacing={2} alignItems="center">
                            {/* <Grid item>
                              <VolumeUp />
                            </Grid> */}
                            <Grid item xs>
                              <Slider
                                value={
                                  typeof this.state.paymentterms === "number"
                                    ? this.state.paymentterms
                                    : 0
                                }
                                onChange={this.handleSliderChange}
                                aria-labelledby="input-slider"
                                min={0}
                                max={120}
                                style={{ color: "darkyellow" }}
                              />
                            </Grid>
                            {/* <Grid item>
                              <Input
                                value={this.state.paymentterms}
                                size="small"
                                defaultValue={0}
                                onChange={this.handlepaymentterms}
                                onBlur={this.handleBlur}
                                valueLabelDisplay="on"
                                inputProps={{
                                  step: 1,
                                  min: 0,
                                  max: 120,
                                  type: 'number',
                                  'aria-labelledby': 'input-slider',
                                }}
                              />
                            </Grid> */}
                          </Grid>
                        </Box>
                      </fieldset>

                      {this.state.paymentterms === "0" ? (
                        ""
                      ) : (
                        <>
                          <fieldset className="form-group col-md-4">
                            <FormGroup row>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={this.state.advancestatus}
                                    onChange={this.handleadvancestatus}
                                    name="ordertype"
                                    color="primary"
                                  />
                                }
                                label="Advance Payment Required?"
                              />
                            </FormGroup>
                          </fieldset>
                          {this.state.advancestatus === true ? (
                            <>
                              <fieldset className="form-group col-md-2">
                                <FormikTextField
                                  className="form-control"
                                  type="number"
                                  name="advance"
                                  onChange={this.handleAdvanceAmountChange}
                                  // onBlur={this.handleAdvanceAmountBlur}
                                  label="Advance Amount"
                                  variant="standard"
                                  fullWidth
                                  inputProps={{
                                    min: 0,
                                    max: this.state.grandtotal,
                                    type: "number",
                                    "aria-labelledby": "input-slider",
                                  }}
                                />
                              </fieldset>
                              {this.state.currency ===
                              this.state.basecurrency ? (
                                ""
                              ) : (
                                <fieldset className="form-group col-md-3">
                                  <FormikTextField
                                    className="form-control"
                                    type="number"
                                    name="advancecnvt"
                                    onChange={
                                      this.handleAdvanceAmountcnvtChange
                                    }
                                    label={`Advance Amount in (${this.state.currency})`}
                                    value={this.state.advancecnvt}
                                    variant="standard"
                                    fullWidth
                                  />
                                </fieldset>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>

                    {this.state.salestype === "1" ? (
                      <>
                        {this.state.customer === "" ||
                        this.state.servicecategory === "" ||
                        this.state.service === "" ||
                        this.state.servicecost === "" ||
                        (this.state.contracttype === "Milestone" &&
                          this.state.milestone.length === 0) ? (
                          <button
                            className="btn btn-sm hovbuttonColour"
                            disabled
                          >
                            <>
                              <i className="fa fa-save mr-1" /> {"Save"}
                            </>
                          </button>
                        ) : (
                          <>
                            {this.state.sloader === true ? (
                              <button
                                className="btn btn-sm hovbuttonColour"
                                disabled
                              >
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  className="mr-1"
                                />
                                {"Saving..."}{" "}
                              </button>
                            ) : (
                              <button
                                className="btn btn-sm hovbuttonColour"
                                type="submit"
                                onClick={this.handleSubmit}
                              >
                                <i className="fa fa-save mr-1" /> {"Save"}
                              </button>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {this.state.salestype === "2" ? (
                          <>
                            {this.state.grandtotal !== 0 ? (
                              <>
                                {this.state.sloader === true ? (
                                  <button
                                    className="btn btn-sm hovbuttonColour"
                                    type="button"
                                    disabled
                                  >
                                    <Spinner
                                      animation="border"
                                      size="sm"
                                      className="mr-1"
                                    />
                                    {"Saving..."}{" "}
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-sm hovbuttonColour"
                                    onClick={this.handleSubmit}
                                    type="button"
                                  >
                                    <i className="fa fa-save mr-1" /> {"Save"}
                                  </button>
                                )}
                              </>
                            ) : (
                              <button
                                className="btn btn-sm hovbuttonColour"
                                type="button"
                                disabled
                              >
                                <>
                                  <i className="fa fa-save mr-1" /> {"Save"}
                                </>
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            {this.state.grandtotal !== 0 ? (
                              <>
                                {this.state.sloader === true ? (
                                  <button
                                    className="btn btn-sm hovbuttonColour"
                                    disabled
                                  >
                                    <Spinner
                                      animation="border"
                                      size="sm"
                                      className="mr-1"
                                    />
                                    {"Saving..."}{" "}
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-sm hovbuttonColour"
                                    onClick={this.handleSubmit}
                                    type="button"
                                  >
                                    <i className="fa fa-save mr-1" /> {"Save"}
                                  </button>
                                )}
                              </>
                            ) : (
                              <button
                                className="btn btn-sm hovbuttonColour"
                                type="button"
                                disabled
                              >
                                <>
                                  <i className="fa fa-save mr-1" /> {"Save"}
                                </>
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                    <Link
                      as="button"
                      className="btn btn-sm deletebutton ml-3"
                      to={{ pathname: "/quotation" }}
                    >
                      <b style={{ color: "#FA5733" }}> X</b> Cancel
                    </Link>
                    <fieldset className="form-group col-md-12" />
                    <fieldset className="form-group col-md-12">
                      <label className="text-red">
                        Note : If any selection is wrong or with invalid
                        characters, please cancel the selection with "x" button
                        in selection and reselect the Field.
                      </label>
                    </fieldset>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(NewQuotation);
