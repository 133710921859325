import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import { currentTenant, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import { purchaseid } from "../../Common/Permissions";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import PurchaseServices from "../PurchseServices";
import CustomToolbar from '../../CommonComponents/CustomToolbar';
import MUIDataTable from 'mui-datatables';
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

 class BillofEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
    };
  }
 
  refreshPurchase() {
    PurchaseServices.Getbillofentry(currentTenant).then((response) => {
      this.setState({ data: response.data });
      this.setState({ loading: false });
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });

    this.refreshPurchase();
  }

  render() {

      const columns = [
        {name: "id",label: "Bill of Entry",options: {filter: false,sort: true,}},
        {name: "custompoid",label:"Purchase Ordetr",options: {filter: true,sort: true,}},
        {name: "supplier",label: "Supplier",options: {filter: true,sort: true,}},
        {name: "createdAt",label: "Date",options: {filter: false,sort: true,sortOrder: 'asc', 
        customBodyRender:(tableMeta)=>{
          return(moment(tableMeta).format('DD-MM-YYYY HH:mm'))
        }}},
        {name: "portcode",label: "Portcode",
        options: {filter: false,sort: true,
        }},
       
        {
         name: "View",
         options: {
           filter: false,
           sort: false,
           empty: true,
           customBodyRender: (value, tableMeta, updateValue) => {
             return (<>
               <Link as="button" data-tip data-for="vieworder" className="fa fa-list-alt"
               to={{pathname: "/billofentrydetails"}} state={{id:tableMeta.rowData.find(e=>e)}}></Link>
               {/* //<Tooltip id="vieworder" place="top" effect="solid">View</Tooltip> */}
               </>
             );
           },
         }
       },
       ];
       const options = {
        filterType: 'checkbox',
        selectableRows: "none",
        customToolbar: () => {
         return (<>
          {purchaseid === '2' || purchaseid === '3'?
          <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/newbillofentry"}} state= {{ id: -1, pagename:  "New Bill of Entry"}}>
            <CustomToolbar />
          </Link>
            :''  
          }
         </>
         );
       },
      }
    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>
              <li className="breadcrumb-item active">BillofEntry</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">BillofEntry</h3>
                      </div>
                      <div className="card-body">
                        <div>
                          {this.state.loading === true ?<div  className="text-center">{spin}</div> : 
                          <>
                         <ThemeProvider theme={theme}> 
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Bill of Entry"}
                            data={this.state.data}
                            columns={columns}
                            options={options}
                          />
                          </ThemeProvider>
                      
                          </>}
                        </div>
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(BillofEntry);
