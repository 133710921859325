import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

export let currentTenant

class ColourService{
    getcolour(currentTenant) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallcolours/${currentTenant}`)
     }
     addcolour(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addcolour`,data)
     }
     deletecolour(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletecolour/${id}`)
     }
     UploadColors(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/uploadcolours`,data)
     }
}
export default new ColourService()