import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { Button } from "react-bootstrap";
import {NumericFormat} from "react-number-format";
import MUIDataTable from "mui-datatables";
import PurchaseServices from "../PurchseServices";

import moment from "moment";

import "date-fns";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";import { TextField, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class PurchaseReturnsReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportsdata: [],
      filterOptions: [
        { id: 0, name: "Overall" },
        { id: 1, name: "Product" },
        { id: 2, name: "Supplier" },
      ],
      filterdata: [],
      products: "",
      suppliers: "",
      productslist: [],
      supbyproducts: [],
      supplierlist: [],
      productsbysup: [],
      topproducts: [],
      topsuppliers: [],
      loading: false,
      selected: 0,
      fromdate: null,
      todate: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.clearfilter = this.clearfilter.bind(this);
  }
  onChange(option) {
    this.setState({ selected: option.id });
  }

  handleFromDateChange = (date) => {
    this.setState({ fromdate: date });
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  clearfilter() {
    this.setState(
      { fromdate: null, todate: null, filterdata: this.state.reportsdata },
      () => {
        this.Productdata();
        this.Supplierdata();
      }
    );
  }

  onSubmit() {
    let filterdata = this.state.reportsdata.filter(
      (e) =>
        moment(e.createdAt).format("DD-MM-YYYY") >=
          moment(this.state.fromdate).format("DD-MM-YYYY") &&
        moment(e.createdAt).format("DD-MM-YYYY") <=
          moment(this.state.todate).format("DD-MM-YYYY")
    );
    this.setState({ filterdata: filterdata }, () => {
      this.Productdata();
      this.Supplierdata();
    });
  }

  Productdata = () => {
    this.setState({ productslist: [], supbyproducts: [] }, () => {
      // Sorting Purchasre Products
      const products = this.state.filterdata.reduce(
        (
          item,
          {
            itemname,
            itemid,
            quantity,
            totalprice,
            totalpricewtax,
            createdAt,
            supplier,
            supid,
          }
        ) => {
          if (!item[itemname]) item[itemname] = [];
          item[itemname].push({
            itemid: itemid,
            itemname: itemname,
            quantity: quantity,
            totalprice: totalprice,
            totalpricewtax: totalpricewtax,
            createdAt: createdAt,
            supplier: supplier,
            supid: supid,
          });
          return item;
        },
        {}
      );
      this.setState({ products: products });
      // End of Sorting Products

      // Calcuating total Purchasre by products
      Object.entries(products).forEach(([key, value]) => {
        let a = {
          itemname: key,
          itemid: value.map((e) => e.itemid)[0],
          quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
          totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
          totalpricewtax: value.reduce((a, v) => (a = a + v.totalpricewtax), 0),
        };
        this.state.productslist.push(a);
      });
      // End of Calcuating total Purchasre products

      // Sorting item Purchasre By supplier
      Object.entries(products).forEach(([key, value]) => {
        const supbyproducts = value.reduce(
          (
            sup,
            {
              itemname,
              itemid,
              quantity,
              totalprice,
              totalpricewtax,
              createdAt,
              supplier,
              supid,
            }
          ) => {
            if (!sup[supid]) sup[supid] = [];
            sup[supid].push({
              itemid: itemid,
              itemname: itemname,
              quantity: quantity,
              totalprice: totalprice,
              totalpricewtax: totalpricewtax,
              createdAt: createdAt,
              supplier: supplier,
              supid: supid,
            });
            return sup;
          },
          {}
        );
        Object.entries(supbyproducts).forEach(([key, value]) => {
          let a = {
            supid: key,
            supplier: value.map((e) => e.supplier)[0],
            itemid: value.map((e) => e.itemid)[0],
            itemname: value.map((e) => e.itemname)[0],
            quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
            totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
            totalpricewtax: value.reduce(
              (a, v) => (a = a + v.totalpricewtax),
              0
            ),
          };
          this.state.supbyproducts.push(a);
        });
      });
      //  End of Sorting item Purchasre By supplier

      // Top 10 Products

      const topproducts = []
        .concat(this.state.productslist)
        .sort((a, b) => (a.totalpricewtax < b.totalpricewtax ? 1 : -1));

      this.setState({ topproducts: topproducts.slice(0, 10) });
    });
  };

  Supplierdata = () => {
    this.setState({ supplierlist: [], productsbysup: [] }, () => {
      const suppliers = this.state.filterdata.reduce(
        (
          sup,
          {
            itemname,
            itemid,
            quantity,
            totalprice,
            totalpricewtax,
            createdAt,
            supplier,
            supid,
          }
        ) => {
          if (!sup[supplier]) sup[supplier] = [];
          sup[supplier].push({
            itemid: itemid,
            itemname: itemname,
            quantity: quantity,
            totalprice: totalprice,
            totalpricewtax: totalpricewtax,
            createdAt: createdAt,
            supplier: supplier,
            supid: supid,
          });
          return sup;
        },
        {}
      );
      this.setState({ suppliers: suppliers });

      Object.entries(suppliers).forEach(([key, value]) => {
        let a = {
          supplier: key,
          supid: value.map((e) => e.supid)[0],
          quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
          totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
          totalpricewtax: value.reduce((a, v) => (a = a + v.totalpricewtax), 0),
        };
        this.state.supplierlist.push(a);
      });

      Object.entries(suppliers).forEach(([key, value]) => {
        const productsbysup = value.reduce(
          (
            item,
            {
              itemname,
              itemid,
              quantity,
              totalprice,
              totalpricewtax,
              createdAt,
              supplier,
              supid,
            }
          ) => {
            if (!item[itemid]) item[itemid] = [];
            item[itemid].push({
              itemid: itemid,
              itemname: itemname,
              quantity: quantity,
              totalprice: totalprice,
              totalpricewtax: totalpricewtax,
              createdAt: createdAt,
              supplier: supplier,
              supid: supid,
            });
            return item;
          },
          {}
        );
        Object.entries(productsbysup).forEach(([key, value]) => {
          let a = {
            itemid: key,
            supplier: value.map((e) => e.supplier)[0],
            supid: value.map((e) => e.supid)[0],
            itemname: value.map((e) => e.itemname)[0],
            quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
            totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
            totalpricewtax: value.reduce(
              (a, v) => (a = a + v.totalpricewtax),
              0
            ),
          };
          this.state.productsbysup.push(a);
        });
      });

      // Top 10 suppliers
      const topsuppliers = []
        .concat(this.state.supplierlist)
        .sort((a, b) => (a.totalpricewtax < b.totalpricewtax ? 1 : -1));

      this.setState({ topsuppliers: topsuppliers.slice(0, 10) });
    });
  };

  validate(values) {
    let errors = {};
    if ((values.from <= values.to) === false) {
      alert("To date must be greater than From date");
      errors.to = "To date must be greater than From date";
    }
    if (values.from === null) {
      alert("Please Select From date");
      errors.from = "Please Select From date";
    }
    if (!values.to === null) {
      alert("Please Select to date");
      errors.to = "Please Select to date";
    }
    return errors;
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    PurchaseServices.getPurchaseReturnsReport(currentTenant)
      .then((response) => {
        this.setState(
          {
            reportsdata: response.data,
            filterdata: response.data,
            loading: false,
          },
          () => {
            this.Productdata();
            this.Supplierdata();
          }
        );
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }
  render() {
    const columns = [
      // {
      //   name: "itemid",
      //   label: "Item Id",
      //   options: { filter: false, sort: true,sortOrder : 'asc' },
      // },
      {
        name: "itemname",
        label: "Item name",
        options: { filter: false, sort: true },
      },
      {
        name: "supplier",
        label: "Supplier",
        options: { filter: false, sort: true },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "price",
        label: "Unit Cost",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalprice",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "tax",
        label: "Tax",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <>{value} %</>;
          },
        },
      },
      {
        name: "totalpricewtax",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY hh:mm");
          },
        },
      },
    ];

    const productscolumns = [
      {
        name: "itemname",
        label: "Item name",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="text-info"
                  to={{
                    pathname: "/purchasereturnsreportdetails"}}
                    state= {{
                      data: this.state.supbyproducts.filter(
                        (e) => e.itemname === value
                      ),
                      data2: this.state.products[value],
                      item: value,
                      pagename: "Purchase Returns by Products",
                  }}
                >
                  {value}
                </Link>
                {/* //<Tooltip id="view details" place="top" effect="solid">
                  View Details
                </Tooltip> */}
              </>
            );
          },
        },
      },
      // {
      //   name: "itemid",
      //   label: "Item Id",
      //   options: { filter: false, sort: true },
      // },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "totalprice",
        label: "Total Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total(Inc.Tax)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    ];

    const suppliercolumns = [
      {
        name: "supplier",
        label: "Supplier",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="text-info"
                  to={{
                    pathname: "/purchasereturnsreportdetails"}}
                    state= {{
                      data: this.state.productsbysup.filter(
                        (e) => e.supplier === value
                      ),
                      data2: this.state.suppliers[value],
                      item: value,
                      pagename: "Purchase Returns by Supplier",
                  }}
                >
                  {value}
                </Link>
                {/* //<Tooltip id="view details" place="top" effect="solid">
                  View Details
                </Tooltip> */}
              </>
            );
          },
        },
      },
      // {
      //   name: "supid",
      //   label: "Supplier Id",
      //   options: { filter: false, sort: true },
      // },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "totalprice",
        label: "Total Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total(Inc.Tax)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>
              <li className="breadcrumb-item active">
                Purchase Returns Report
              </li>
            </ol>

            <div className="card-body">
              <div>
                {this.state.loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <Formik
                    initialValues={{
                      from: this.state.fromdate,
                      to: this.state.todate,
                    }}
                    onSubmit={this.onSubmit}
                    validateOnChange={false}
                    validate={this.validate}
                    enableReinitialize={true}
                  >
                    {({ setFieldValue, values }) => (
                      <Form autoComplete="off">
                        <div className="form-row g-0">
                          <fieldset>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justify="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="From Date"
                                  name="from"
                                  format="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  value={this.state.fromdate}
                                  onChange={this.handleFromDateChange}
                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                  autoOk={true}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>

                          <fieldset style={{ marginLeft: "30px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justify="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="To Date"
                                  name="to"
                                  format="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  value={this.state.todate}
                                  onChange={this.handleToDateChange}
                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>
                          <fieldset className="form-group col-md-1">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3"
                              type="submit"
                            >
                              <i className="fas fa-filter" />
                            </button>
                          </fieldset>
                          {this.state.todate !== null ? (
                            <fieldset className="form-group col-md-2">
                              <Button
                                className="btn btn-sm bg-white shadow-sm mt-3"
                                onClick={this.clearfilter}
                              >
                                {" "}
                                <b style={{ color: "#FA5733" }}> X</b> Clear
                                Filter
                              </Button>
                            </fieldset>
                          ) : (
                            ""
                          )}
                          <div className="form-group col-md-12 text-sm" />

                          {this.state.filterOptions.map((option) => {
                            return (
                              <label key={option.id}>
                                <div className="form-group col-md-9 text-sm">
                                  <div className="btn-group">
                                    <input
                                      className={
                                        this.state.selected === option.id
                                          ? "btn hovborderbuttonColour"
                                          : "btn hovbuttonColour"
                                      }
                                      type="button"
                                      key={option.id}
                                      onClick={this.onChange.bind(this, option)}
                                      style={{ marginRight: 8, width: 200 }}
                                      value={option.name}
                                    />
                                  </div>
                                </div>
                              </label>
                            );
                          })}
                        </div>
                        {this.state.selected === 0 ? (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Purchase Report(OverAll)"}
                              data={this.state.filterdata}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                        ) : (
                          ""
                        )}

                        {this.state.selected === 1 ? (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Purchase Report(Products)"}
                              data={this.state.productslist}
                              columns={productscolumns}
                              options={options}
                            />
                          </ThemeProvider>
                        ) : (
                          ""
                        )}

                        {this.state.selected === 2 ? (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Purchase Report(Suppliers)"}
                              data={this.state.supplierlist}
                              columns={suppliercolumns}
                              options={options}
                            />
                          </ThemeProvider>
                        ) : (
                          ""
                        )}
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(PurchaseReturnsReport);
