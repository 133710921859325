import { createTheme, ThemeProvider } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import CustomToolbar from '../../../CommonComponents/CustomToolbar'
import MUIDataTable from 'mui-datatables';
import TourServices from '../../Invoice/TourServices';
import Footer from '../../../Component/Footer';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import withRouter from '../../../withRouter';


const theme = createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "#e9ecef !important",
          border: [[1, "solid", "#ece6e6"]],
        },
        body: {
          border: [[1, "solid", "#ece6e6"]],
        },
      },
    },
  });


const MRoomtypeList = () => {
    const [hotel, setHotel] = useState([]);
    const nevigate = useNavigate();

useEffect(()=>{
  getAllMRoomTypeList();
},[])

const delteDataHandler = (e)=>{
  if (confirm('Are you sure you want to Delete this thing into the database?')) {
    TourServices.deleteMHotelTax(e).then((r)=>{
      console.log(r.data);
      getAllMRoomTypeList();
  })
          
    console.log('Thing was Deleted to the database.');
  } else {
    
    console.log('Thing was not Deleted to the database.');
  }
   
}

const getAllMRoomTypeList = ()=>{
  TourServices.getAllMRoomTypeList().then((r)=>{
    console.log(r.data);
    setHotel(r.data);

  })
}
    const columns = [
      {
        name: "id",
        label: "ID",
        options: { filter: true, sort: true },
      },
        {
          name: "roomtype",
          label: "Room Type",
          options: { filter: true, sort: true },
        },
        {
          name: "remarks",
          label: "Remarks",
          options: { filter: true, sort: true },
        },
        {
          name: "Action",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
              <>
              <button
                  as="button"
                  data-tip
                  data-for="delete"
                  className="btn btn-sm deletebutton"
                  onClick={()=>
                delteDataHandler(tableMeta.rowData.find(e=>e))
                  }
                >Delete</button>
                 <Link as="button" className="btn btn-sm hovbuttonColour mr-2"
                  to={{ pathname: "/hotelroomtype"}} state= {{id: tableMeta.rowData.find(e=>e) ,pagename:"Edit hotel Room Type" }}>Edit</Link>
                <br/>             
                 </>
              );
            },
          },
        },
      ];
    
      const options = {
        filterType: "checkbox",
        selectableRows: "none",
        customToolbar: () => {
          return (
            <>
              (
                  <Link
              as="button"
              className="float-right btn btn-sm m-0 p-0"
              to={{ pathname: "/hotelroomtype"}} 
              state= {{id: -1,pagename:"Add Master Room Type" }}
            >
              <CustomToolbar />
            </Link>
              )
            </>
          );
        },
      };

    return (
        <>
        <Header />
      <Menu />
      <div className="content-wrapper w-100% h-100%">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
          <span
                as="button"
                className=" text-secondary cursorPointer"
                
              >
                <Link to="/masterpage" style={{color:"grey"}}>❮ Back &nbsp;&nbsp;</Link>
                
              </span>
            <li className="breadcrumb-item text-secondary">
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/masterpage">Tours & Travellers </Link>
            </li>
            <li className="breadcrumb-item active">Hotel Room Type</li>
          </ol>
            <div className='card'>
                <div className='card-body'>

                <ThemeProvider theme={theme}>
                    <MUIDataTable
                        className="table-sm px-3"
                        title={"Room Type"}
                        data={hotel}
                        columns={columns}
                        options={options}
                    />
                </ThemeProvider>
                </div>
            </div>
          </div>
          </div>
          <Footer/>
        </>
    )
}

export default withRouter(MRoomtypeList);
