function downloadCSV(csv, filename) {
    const blob = new Blob([csv], { type: 'text/csv' });
  
    /* taken from react-csv */
    if (navigator && navigator.msSaveOrOpenBlob) {
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const dataURI = `data:text/csv;charset=utf-8,${csv}`;
  
      const URL = window.URL || window.webkitURL;
      const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);
  
      let link = document.createElement('a');
      link.setAttribute('href', downloadURI);
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
  
  function createCSVDownload(columns, data, options, downloadCSV) {
    const csv = buildCSV(columns, data, options);
  
    if (options.onDownload && csv === false) {
      return;
    }
  
    downloadCSV(csv, options.downloadOptions.filename);
  }
  
  export {
    createCSVDownload,
    downloadCSV
  }