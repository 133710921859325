import { Form, Formik } from 'formik'
import { FormikTextField } from '../../CommonComponents/FormField.js';
import React, { Component } from 'react'
import {Link } from 'react-router-dom';
import {Autocomplete} from '@mui/material';
import { currentTenant, userid} from '../../Common/Credentials.js';
import {ruleid} from './NewLedger'
import SubAccService from '../../Masters/Subaccounts/SubAccService.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import NewLedgerService from './NewLedgerService.js';
import { FormLabel, Spinner } from 'react-bootstrap';
import { nominalledgers } from './Nominal.jsx';
import CategoryService from '../../Masters/Category/CategoryServices.js';
import { FormControlLabel } from '@mui/material';
import IOSSwitch from '../../CommonComponents/IOSSwitch.js';
import withRouter  from '../../withRouter.js';


let taxtype=0,subhead=null//ledgertype

class NewNominal extends Component {

  constructor(props) {
    super(props)

    const {pagename} = this.props.location.state;
    this.state = {
      pagename:pagename,
      subaccdata:[],
    error:'',
    ledgername:'',
    id:'',
    catid:'',
    catname:'',
    dir:true,
    cat:false,
    addled:false,
    ledchecked:'',
    subchecked:'',
    category:[],
    lednewtypes:[{id:1,name:"Purchase"},{id:2,name:"Sales"},{id:3,name:"Both"}],
    ledsubtype:[{id:1,name:"Domestic"},{id:2,name:"International"}],
    loader:false
    // ledgertypes:[{id:12,name:"Income"},{id:13,name:"Expense"},
    //             {id:14,name:"Gains"},{id:15,name:"Loses"}],

     

    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.goBack=this.goBack.bind(this);

  }

  componentDidMount() {
    subhead=null

    // let a1,b1,c1
    // a1=ledgertypes.filter((e)=>e==="INCOME")
    // b1=a1.concat(ledgertypes.filter((e)=> e==="EXPENSE"))
    // c1=b1.concat(ledgertypes.filter((e)=> e==="GAINS"))
    // ledgertype=c1.concat(ledgertypes.filter((e)=> e==="LOSES"))

  // ledgertype=ledgertypes.filter((e)=>e==="Income" && e==="Expense" )

  CategoryService.getNaCategory(currentTenant)
  .then(response =>{
    this.setState({category:response.data})
  })
  .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})

  SubAccService.retriveSubIdName(currentTenant,ruleid)
  .then(response =>{
    
    this.setState({subaccdata:response.data})

  })
  .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})
  if(userid===undefined){
    window.confirm('Your Session is Lost: Please login Back to resume')
    this.props.navigate(`/`)
  }

}

handledirChange=()=>{
  this.setState({dir:true,cat:false})
}

handlecatChange=()=>{
  this.setState({cat:true,dir:false})
}

addLedger(result){
  this.setState({addled:true,catname:result.category,catid:result.id})
}

onLedTypeChange(option){
   this.setState({ledchecked:option.id})
}

onSubTypeChange(option){
  this.setState({subchecked:option.id})
}

onSubmit(values) {
  this.setState({loader:true})
  if (this.state.dir!==false) {
    let newIE = {
      name: values.name,
      subhead:values.type.id,
      tenantId:currentTenant,
      createdBy:userid,
      rule:ruleid,
      taxtype:taxtype,
  }
    NewLedgerService.createIE(newIE)
    .then(() =>      
        this.props.navigate(`/newledgers`,{message:values.name + "   : Created Successfully"}))
        .catch(error => this.setState({error:error.message + "   Account Name Already Exists"}))
        .catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
      })
  }else{
    let newCatIE={
      catid:this.state.catid,
      catname:this.state.catname,
      ledid:this.state.ledchecked,
      subid:this.state.subchecked,
      tenantId:currentTenant,
      createdBy:userid,
      rule:ruleid,
      taxtype:taxtype,
  }

  NewLedgerService.createCatIE(newCatIE)
  .then(() =>      
      this.props.navigate(`/newledgers`,{message:values.name + "   : Created Successfully"}))
      .catch(error => this.setState({error:error.message + "   Account Name Already Exists"}))
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })

}   
}

goBack(){
  this.props.navigate(-1);
}


onAccountChange(subacc){

  let val1= this.state.subaccdata.filter((e) => e.rule === 2 )
  if(subacc!==null){
  let val2 = val1.filter((e)=>e.id===subacc.id);
  val2.map((e) => {return subhead = e.subheads})
  // subhead = subheads.filter(e=>e.id!==100003 && e.id!==100004)
}
}


  validate(values) {
    let errors = {};
    // var chars = /^[A-Za-z ]+$/
 
    if(this.state.dir===true){
    if (!values.subacc) {
      errors.subacc = "Accounts is required";
    }
    let a = nominalledgers.map((e)=>e.name)
    if(a.find(e=>e===values.name)){
      errors.name = 'Ledger already exists !! Please use a different name for Ledger';
    }
    if (values.type===undefined || values.type==='' ) {
      errors.type = "Type is required";
    }
    if (!values.name) {
      errors.name = <span className="text-right">"Account Name is required"</span>;
    }
    // else if (!chars.test(values.name)) {
    //   errors.name = 'Only Characters are allowed';
    // }
  }
    
    return errors;

  }


    render() {
      
      //alert(ledgertypes.filter((e)=>e==="INCOME"))
      let {name,subaccdata} = this.state
      // let a=subaccdata.filter((e) => e.th === "TH1")
      // let b=a.concat(subaccdata.filter((e) => e.th === "TH2" ))
      // let c=b.concat(subaccdata.filter((e) => e.th === "TH5" ))
      // // let d=c.concat(subaccdata.filter((e) => e.th === "TH6" ))
      // let val=c.concat(subaccdata.filter((e) => e.th === "TH6" ))

      let val=subaccdata.filter((e) => e.rule === 2  )

        return (
            <div>
              <Header />
          <Menu/>
          <div className="content-wrapper">
          <div className="card card-info">
            {/* <div className="card-header">
              <h3 className="card-title">{this.state.name}</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={-1}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/newledgers">New Ledger</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>

            <div className="card-body">

                <Formik
              
              initialValues={{name,val}}
              onSubmit={this.onSubmit}
              validateOnChange={false}
              validate={this.validate}
              enableReinitialize={true}>
              {({setFieldValue,values}) => (
                
                <Form autoComplete="off">

                  <div className="form-row">

                  <fieldset className="form-group col-md-4">
                              <span className="mx-3">Direct</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.dir} onChange={this.handledirChange} name="checkedB" />}
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-4">
                              <span className="mx-3">From Category</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.cat} onChange={this.handlecatChange} name="checkedB" />}
                              />
                            </fieldset>
{this.state.cat===true?
                  <div className="form-group col-md-12">
<table 
       id="example1"
       className="table table-bordered table-sm text-sm ">
      
       <thead >
         <tr>
         <th className="form-group col-md-5">Category Name</th>
         {/* <th className="form-group col-md-5">Opening Balance</th> */}
         <th className="form-group col-md-1">Action</th>

         {/* <th>Action</th> */}
         </tr>
       </thead>
       
       <tbody>
         
         {
           this.state.category.map(
             result => 
             <tr key={result.id}>
               <td className="form-group col-md-5 text-blue">{result.category}</td>
               {/* <td className="form-group col-md-5 text-right">{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} value = {result.amount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>}</td> */}
               <td><span  className="form-group col-md-1 fa fa-plus text-danger " onClick={()=>this.addLedger(result)}></span></td>
             </tr>
             
           )
         }
              
       </tbody>                                
     </table> 

     <div className="form-group col-md-12">
     <FormLabel>Ledger  : &nbsp; {this.state.catname}</FormLabel>  

</div>

     {this.state.addled===true?
<div className="form-group col-md-12">
<FormLabel>Purchase Entry Type :</FormLabel>{this.state.lednewtypes.map((option) => {return (
  <label key={option.id}><div className="form-group col-md-12 mt-0 mb-0 ">
  <input type="radio" checked={this.state.ledchecked === option.id ? true : false} key={option.id}
  onChange={this.onLedTypeChange.bind(this, option)} style={{ marginRight: 8 ,marginTop: 25 }} value={option.id}/>
  {option.name}</div></label>);})}

  <FormLabel className="ml-5">Sub Entry Type :</FormLabel>{this.state.ledsubtype.map((option) => {return (
  <label key={option.id}><div className="form-group col-md-12 mt-0 mb-0 ">
  <input type="radio" checked={this.state.subchecked === option.id ? true : false} key={option.id}
  onChange={this.onSubTypeChange.bind(this, option)} style={{ marginRight: 8 ,marginTop: 25 }} value={option.id}/>
  {option.name}</div></label>);})}
</div>:""}
    

     </div>:""}

     
                  
     {this.state.cat===false?<>

        <fieldset className="form-group col-md-12">
                      <Autocomplete
                      id="subaccount"
                      className="subacc-select"
                      name="subacc"
                      options={val}
                      getOptionLabel={(option) => option.name +"(" +option.thname +")"}
                      // renderOption={(option) => option.name +"(" +option.thname +")"}
                      value={values.subacc?undefined:undefined}
                      onChange={(e, value) => {
                       setFieldValue("subacc", value);
                        }}
                      renderInput={params => (
                        <FormikTextField
                          {...params}
                        className="form-control"
                        type="text"
                        name="subacc"
                        onBlur={()=>this.onAccountChange(values.subacc)}
                        //style={{ height }}
                        placeholder="Select  Account"
                        variant="standard"
                        fullWidth
                      />
                    )}
                  />
                      </fieldset>
{subhead!==null?
                      <fieldset className="form-group col-md-12">
                      <Autocomplete
                      id="ledgertype"
                      className="type-select"
                      name="type"
                      options={subhead}
                      getOptionLabel={(option) => option.name}
                      
                      
                      value={values.type?undefined:undefined}
                      onChange={(e, value) => {
                       setFieldValue("type", value);
                        }}
                      renderInput={params => (
                        <FormikTextField
                          {...params}
                        className="form-control"
                        type="text"
                        name="type"
                        // style={{ height }}
                        placeholder="Select  Ledger Type"
                        variant="standard"
                        fullWidth
                      />
                    )}
                  />
                      </fieldset>:""}


                      <fieldset className="form-group col-md-6">
                      <FormikTextField

                        className="form-control"
                        name="name"
                        //style={{ height }}
                        placeholder="Ledger Name"
                        variant="standard"
                        fullWidth
                        
                      />
                     </fieldset></>:""}

                    </div>

                  <div>
                  <div className="form-row">
                    <fieldset className="form-group col-md-12">
                  <button className="btn hovbuttonColour mr-1 btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</>:<><i className="fas fa-pen"></i>{"Save"}</>}</button>
                  {/* </fieldset>
                  <fieldset className="form-group col-md-1">  */}
                  <button  className="btn deletebutton btn-sm" type="button" onClick={this.goBack}><i className="fas fa-times"/>Close</button>
                  </fieldset>
                  </div>
                  </div>
                 
                  

                </Form>
              )}
            </Formik>
            </div>
            </div>
            </div>
            <Footer/>
            </div>
        )
    }
}
export default withRouter(NewNominal)