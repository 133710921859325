import axios from 'axios'
import { Auth_Domain, Auth_Port, Auth_Proto } from '../../Component/consts'

class UsercustomerService {

    AddUsercustomers(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addusercustomers`,data)
     }

     UpdateUsercustomers(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateusercustomers`,data)
     }

     retriveUsercustomers(id) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getusercustomers/${id}`)
     }

     GetUserCustomersbyId(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getusercustomersbyid/${id}`)
     }

     GetUserCustomersbyUser(userid){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getusercustomersbyuser/${userid}`)
     }

     DeleteUsercustomers(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deleteusercustomers/${id}`,)
     }  
}

export default new UsercustomerService()