import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials.js";
// 
import { spin } from "../../Common/NewLoader.jsx";
// import UserService from "../../users/UserService.js";
import { appmasterid } from "../../Common/Permissions.js";
import {NumericFormat} from "react-number-format";
import MUIDataTable from "mui-datatables";
import CustomToolbar from "../../CommonComponents/CustomToolbar.js";

import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import Footer from "../../Component/Footer";
import PosService from "./PosService.js";
import moment from "moment";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
class PospriceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      loading: false,
    };
  }

  refreshItems() {
    PosService.GetPosPrices(currentTenant)
      .then((response) =>
        this.setState({
          tableData: response.data,
          loading: false,
        })
      )
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

//   deletesalescal(id) {
//     SalesCalculationServices.DeleteSalesCal(id)
//       .then((resposne) => {
//         this.setState({
//           message: `Delete of Todo ${id} Successfull`,
//           loading: true,
//         });
//         this.refreshItems();
//       })
//       .catch((e) => {
//         if (e.response.data.error === "Unauthorized") {
//           alert("Your Session is Lost: Please login Back to resume");
//           this.props.navigate(`/`);
//         }
//       });
//   }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.refreshItems();
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Item id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "itemname",
        label: "Item name",
        options: { filter: false, sort: true },
      },
      {
        name: "cost",
        label: "Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "margin",
        label: "Margin",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "discount",
        label: "Discount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "grossValue",
        label: "Gross Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {name: "createdAt",label: "Date",options: {filter: false,sort: true, 
        customBodyRender:(tableMeta)=>{
          return(moment(tableMeta).format('DD-MM-YYYY hh:mm'))
        }}},
      {
        name: "Edit",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Link
                className="fa fa-edit text-info"
                to={{pathname: "/newposprice"}}
                  state={ {
                    id: tableMeta.rowData.find((e) => e),
                    data:this.state.tableData.find(t=>t.id===tableMeta.rowData.find((e) => e)),
                    pagename: "Edit Point of Sale Price"
                }}
              ></Link>
            );
          },
        },
      },
      // {
      //   name: "Delete",
      //   options: {
      //     filter: false,
      //     sort: false,
      //     empty: true,
      //     display: appmasterid === "2" || appmasterid === "3" ? true : false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         <Link
      //           className="fa fa-trash text-danger"
      //           onClick={(e) => {
      //             const id = tableMeta.rowData.find((e) => e);
      //             if (window.confirm(`Are you sure you wish to DELETE ${id}?`))
      //               this.deletesalescal(id);
      //           }}
      //         ></Link>
      //       );
      //     },
      //   },
      // },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{pathname: "/newposprice"}}
                  state= {{ id: -1,data:'', pagename: "New Point of Sale Price"}}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>

              <li className="breadcrumb-item active">Point of Sale Price</li>
            </ol>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title"> Point of Sale Price </h3>
                      </div>
                      <div className="card-body">
                        <div>
                          {this.state.loading === true ? (
                            <div className="text-center">{spin}</div>
                          ) : (
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Point of Sale Price"}
                                data={this.state.tableData}
                                columns={columns}
                                options={options}
                              />
                            </ThemeProvider>
                          )}
                        </div>
                      </div>
                      {/* /.card-body */}
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default withRouter(PospriceTable);
