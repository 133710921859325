import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  currency,
  financialyearstart,
  thousandsdisplay,
} from "../../../Common/Credentials";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { Skeleton } from "@mui/material";
// import styled from "@mui/material/styles/styled";

// const Div = styled('div')({});
// const data = [
//   {
//     name: "Page A",
//     month: "Jan",
//     sale: 1200,
//   },
//   {
//     name: "Page B",
//     month: "Feb",
//     sale: 1600,
//   },
//   {
//     name: "Page C",
//     month: "Mar",
//     sale: 3000,
//   },
//   {
//     name: "Page D",
//     month: "Apr",
//     sale: 3600,
//   },
//   {
//     name: "Page E",
//     month: "May",
//     sale: 4800,
//   },
//   {
//     name: "Page F",
//     month: "Jun",
//     sale: 4200,
//   },
//   {
//     name: "Page G",
//     month: "Jul",
//     sale: 4600,
//   },
//   {
//     name: "Page C",
//     month: "Aug",
//     sale: 3800,
//   },
//   {
//     name: "Page D",
//     month: "Sep",
//     sale: 5200,
//   },
//   {
//     name: "Page E",
//     month: "Oct",
//     sale: 5800,
//   },
//   {
//     name: "Page F",
//     month: "Nov",
//     sale: 6800,
//   },
//   {
//     name: "Page G",
//     month: "Dec",
//     sale: 7200,
//   },
// ];

const LineChartComp = ({ order, invoice, type, loader }) => {
  const [data, setData] = useState([]);
  //     const [type, setType] = useState("Year");
  //   const [yearxaxis] = useState([
  //     moment(financialyearstart).add(0, "month").startOf("month").format("MMM"),
  //     moment(financialyearstart).add(1, "month").startOf("month").format("MMM"),
  //     moment(financialyearstart).add(2, "month").startOf("month").format("MMM"),
  //     moment(financialyearstart).add(3, "month").startOf("month").format("MMM"),
  //     moment(financialyearstart).add(4, "month").startOf("month").format("MMM"),
  //     moment(financialyearstart).add(5, "month").startOf("month").format("MMM"),
  //     moment(financialyearstart).add(6, "month").startOf("month").format("MMM"),
  //     moment(financialyearstart).add(7, "month").startOf("month").format("MMM"),
  //     moment(financialyearstart).add(8, "month").startOf("month").format("MMM"),
  //     moment(financialyearstart).add(9, "month").startOf("month").format("MMM"),
  //     moment(financialyearstart).add(10, "month").startOf("month").format("MMM"),
  //     moment(financialyearstart).add(11, "month").startOf("month").format("MMM"),
  //   ]);
  //   const [monthxaxis] = useState([]);
  //   const [weekxaxis] = useState([
  //     "Mon",
  //     "Tue",
  //     "Wed",
  //     "Thu",
  //     "Fri",
  //     "Sat",
  //     "Sun",
  //   ]);

  useEffect(() => {
    if (order !== undefined && order !== null) {
      if (type === "YEAR") {
        let chartdata = [];
        order?.map((e, i) =>
          chartdata.push({
            month: moment(financialyearstart)
              .add(i, "month")
              .startOf("month")
              .format("MMM"),
            order: e,
            invoice:
              invoice === undefined ||
              (invoice === null && invoice.length === 0)
                ? 0
                : invoice[i],
          })
        );
        setData(chartdata);
      }

      if (type === "MONTH") {
        let chartdata = [];
        order?.map((e, i) =>
          chartdata.push({
            month: i + 1,
            order: e,
            invoice:
              invoice === undefined ||
              (invoice === null && invoice.length === 0)
                ? 0
                : invoice[i],
          })
        );
        setData(chartdata);
      }
      if (type === "WEEK") {
        let chartdata = [];
        order?.map((e, i) =>
          chartdata.push({
            month: i + 1,
            order: e,
            invoice:
              invoice === undefined ||
              (invoice === null && invoice.length === 0)
                ? 0
                : invoice[i],
          })
        );
        setData(chartdata);
      }
    }
  }, [order]);

  return (
    loader?
    <Skeleton variant="rectangular" width="100%" height={200}/>:
    <ResponsiveContainer height={200}>
      {/* <LineChart
        width={480}
        height={250}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <defs>
          <filter id="shadow" height="200%">
            <feDropShadow
              dx="0"
              dy="2"
              stdDeviation="2"
              floodColor={"#022533"}
            />
          </filter>
        </defs>
        <CartesianGrid
          strokeDasharray="6 1 2"
          horizontal={false}
          strokeOpacity={0.3}
        />
        <XAxis dataKey="month" axisLine={false} tickLine={false} />
        <YAxis dataKey={"sale"} axisLine={false} tickLine={false} />
        <Tooltip
          content={({ active, label, payload }) => {
            return active ? (
              <div>
                {payload.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className={index !== payload.length - 1 ? "mb-1" : ""}
                    >
                      <div
                        style={{
                          color: row.color,
                          fontSize: 10,
                          letterSpacing: 2,
                          textTransform: "uppercase",
                        }}
                      >
                        {row.name.toUpperCase()}
                      </div>
                      <div
                        style={{
                          color: row.color,
                        }}
                      >
                        {row.value}
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null;
          }}
          wrapperStyle={{
            background: "rgba(0,0,0,0.9)",
            borderRadius: 4,
            padding: "5px 8px",
            fontWeight: 500,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
        />
        <Line
          type="linear"
          strokeWidth={2.5}
          dataKey="sale"
          stroke="#ffdd15"
          filter="url(#shadow)"
        />
         <Line
          type="linear"
          strokeWidth={2.5}
          dataKey="sale"
          stroke="#ffdd15"
          filter="url(#shadow)"
        />
      </LineChart> */}

      <AreaChart data={data === undefined || data === null ?[]:data} margin={{ top: 5, right: 0, left: 0, bottom: 0 }}>
        <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="1%" stopColor="#EAE7FF"/>
                        <stop offset="99%" stopColor="#ffffff"/>
                    </linearGradient>
                    <linearGradient id="colorUv1" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="3%" stopColor="#FFF2E0"/>
                        <stop offset="97%" stopColor="#ffffff"/>
                    </linearGradient>
                </defs>
        <XAxis dataKey="month" axisLine={false} tickLine={false} />
        {/* <YAxis dataKey={"amount"} axisLine={false} tickLine={false} />
        <YAxis dataKey={"order"} axisLine={false} tickLine={false} /> */}
        <Tooltip
          animationEasing={"ease-in-out"}
          content={({ active, label, payload }) => {
            return active ? (
              <div sx={{ color: "common.white" }}>
                {payload?.map((row, index) => {
                  return (
                    <div
                      key={index}
                      className={index !== payload.length - 1 ? "mb-1" : ""}
                    >
                      <div
                        style={{
                          color: row.color,
                          // backgroundColor:row.color,
                          fontSize: 8,
                          letterSpacing: 2,
                          textTransform: "uppercase",
                        }}
                      >
                        {row.name}
                      </div>
                      <div
                        style={{
                          color: row.color,
                        }}
                      >
                        {currency + " "}
                        <NumericFormat
                          displayType={"text"}
                          value={row.value}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          disabled={true}
                          thousandSeparator={true}
                          thousandsGroupStyle={thousandsdisplay}
                        ></NumericFormat>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null;
          }}
          wrapperStyle={{
            background: "rgba(255,255,255,0.8)",
            borderRadius: 4,
            padding: "5px 8px",
            fontWeight: 500,
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          }}
          cursor={false}
        />
        <CartesianGrid
          strokeDasharray="6 1 2"
          horizontal={false}
          strokeOpacity={0.2}
          stroke={"#E73145"}
        />
        <Area
          type={"monotone"}
          dataKey={"order"}
          stackId="2"
          strokeWidth={2.5}
          fillOpacity={0.7}
          stroke={"#022533"}
          fill="url(#colorUv)"
        />
        <Area
          type={"monotone"}
          dataKey={"invoice"}
          stackId="1"
          strokeWidth={2.5}
          fillOpacity={0.7}
          stroke={"#ffdd15"}
          fill="url(#colorUv1)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default LineChartComp;
