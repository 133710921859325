import { createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { ThemeProvider } from "react-bootstrap";
import { thousandsdisplay, userid,currentTenant } from "../../Common/Credentials";
import withRouter from "../../withRouter";
import ProductionServices from "../ProductionServices";
import { NumericFormat } from "react-number-format";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
import { Link } from "react-router-dom";
import moment from "moment";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
class ProductionLosses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  refresh = () => {
    ProductionServices.getallProductionstorelosses(currentTenant).then((res) =>
      this.setState({ data: res.data })
    );
  };
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.refresh();
  }
  render() {
    const locolumns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumn: false,
        },
      },
      {
        name: "storename",
        label: "Strore",
        options: { filter: true, sort: true },
      },
      {
        name: "itemname",
        label: "Item Name",
        options: { filter: true, sort: true },
      },
      {
        name: "price",
        label: "Unit Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "totalloss",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "comments",
        label: "Comments",
        options: { filter: true, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return moment(value).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      },
    ];
    const looptions = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/store">Store</Link>
              </li>
              <li className="breadcrumb-item active">Production Losses</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">
                          {this.state.name} - {this.state.selected}
                        </h3>
                      </div>

                      <div className="card-body">
                        {this.state.loading === true ? (
                          <span className="text-center">{spin}</span>
                        ) : (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={`Losses`}
                              data={this.state.data}
                              columns={locolumns}
                              options={looptions}
                            />
                          </ThemeProvider>
                        )}
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ProductionLosses);
