import React from "react";
import { Link } from "react-router-dom";

import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  clientname,
  logo,
  userid,
} from "../../Common/Credentials";
// import moment from "moment";
import { spin } from "../../Common/NewLoader";

import MUIDataTable from "mui-datatables";

import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ProductionServices from "../ProductionServices";
import withRouter from "../../withRouter";
import { FormControlLabel } from "@mui/material";
import IOSSwitch from "../../CommonComponents/IOSSwitch";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class FinishedStoreTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.location.state.id,
      finishedstore: this.props.location.state.finishedstore,
      warehouse: this.props.location.state.warehouse,
      whid: this.props.location.state.whid,
      workcenter: this.props.location.state.workcenter,
      wcid: this.props.location.state.wcid,
      name:this.props.location.state.name,
      Options:["Finished Products","Immediate Production Finished Products"],
      indentissued:this.props.location.state.indentissue,
      selected:"Finished Products",
      loading: false,
      data:[],
      completeddata:[],
      data2:[],
      completeddata2:[],
      active:true,
      completed:false,
    };
  }

  refresh() {
    ProductionServices.GetFinishedGoodsByStore(this.state.id).then((response) => {
      this.setState({
        data: response.data.filter(e=>e.pendingquantity > 0),
        completeddata: response.data.filter(e=>e.pendingquantity === 0),
        loading: false,
      });
    });

    ProductionServices.GetFinishedGoodsByStoreofimp(this.state.id).then((response) => {
      this.setState({
        data2: response.data.filter(e=>e.pendingquantity > 0),
        completeddata2: response.data.filter(e=>e.pendingquantity === 0),
        loading: false,
      });
    });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.refresh();
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: { filter: true, sort: true },
      },
      {
        name: "joid",
        label: "Job Order Id",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              <Link
                as="button"
                data-tip
                data-for="view details"
                to={{
                  pathname: "/joborder"}}
                  state= {value}
              >
                {value}
              </Link>
            );
          },
        },
      },
      {
        name: "inprocess",
        label: "Inprocess Id",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              <Link
                as="button"
                data-tip
                data-for="view details"
                to={{
                  pathname: "/inprocess"}}
                  state= {value}
              >
                {value}
              </Link>
            );
          },
        },
      },
      {
        name: "qualityanalysis",
        label: "Quality Analysis Id",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              value === null || value === undefined?"NA":
              <Link
                as="button"
                data-tip
                data-for="view details"
                to={{
                  pathname: "/qa"}}
                  state= {value}
              >
                {value}
              </Link>
            );
          },
        },
      },
      {
        name: "pendingquantity",
        label: "Pending Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-yyyy hh:mm");
          },
        },
      },
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
              {this.state.active?
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="fa fa-list-alt"
                  style={{ color: "#152f5f"}}
                  to={{
                    pathname: "/finishedproducts"}}
                    state= {{data:this.state.data.find(e=>e.id === tableMeta.rowData[0]), items:this.state.finishedstore.find(e=>e.id === tableMeta.rowData[0]).finishedstoreitems,name:this.state.name,whid:this.state.whid,warehouse:this.state.warehouse,type:"full",}}
                ></Link>
                :
                <Link
                as="button"
                data-tip
                data-for="view details"
                className="fa fa-list-alt"
                style={{ color: "#152f5f"}}
                to={{
                  pathname: "/finishedproducts"}}
                  state= {{data:this.state.completeddata.find(e=>e.id === tableMeta.rowData[0]), items:this.state.finishedstore.find(e=>e.id === tableMeta.rowData[0]).finishedstoreitems,name:this.state.name,whid:this.state.whid,warehouse:this.state.warehouse,type:"full",}}
              ></Link>
                  }
              </>
            );
          },
        },
      },
    ];
    const columns2 = [
      {
        name: "id",
        label: "Id",
        options: { filter: true, sort: true },
      },
      {
        name: "imp",
        label: "Immediate Production Id",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              <Link
                as="button"
                data-tip
                data-for="view details"
                to={{
                  pathname: "/immediateproductiondetails"}}
                  state= {value}
              >
                {value}
              </Link>
            );
          },
        },
      },
      {
        name: "pendingquantity",
        label: "Pending Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-yyyy hh:mm");
          },
        },
      },
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
              {this.state.active?
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="fa fa-list-alt"
                  style={{ color: "#152f5f"}}
                  to={{
                    pathname: "/finishedproducts"}}
                    state= {{data2:this.state.data2.find(e=>e.id === tableMeta.rowData[0]), items:this.state.finishedstore.find(e=>e.id === tableMeta.rowData[0]).finishedstoreitems,name:this.state.name,whid:this.state.whid,warehouse:this.state.warehouse,type:"imp",}}
                ></Link>
                :
                <Link
                as="button"
                data-tip
                data-for="view details"
                className="fa fa-list-alt"
                style={{ color: "#152f5f"}}
                to={{
                  pathname: "/finishedproducts"}}
                  state= {{data2:this.state.completeddata2.find(e=>e.id === tableMeta.rowData[0]), items:this.state.finishedstore.find(e=>e.id === tableMeta.rowData[0]).finishedstoreitems,name:this.state.name,whid:this.state.whid,warehouse:this.state.warehouse,type:"imp"}}
              ></Link>
              }
              </>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };

    
   
    

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <Link
                  as="button"
                  className=" text-secondary"
                  to={{ pathname: "/store" }}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/store">Store</Link>
              </li>
              <li className="breadcrumb-item active">Store Details</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">{this.state.name} Details</h3>
                      </div>

                      <div className="card-body">
                        {this.state.loading === true ? (
                          <span className="text-center">{spin}</span>
                        ) : (<>
                          {this.state.Options.map((option) => {
                        return (
                          <label key={option}>
                            <div className="btn-group">
                              <input
                                className={
                                  this.state.selected === option
                                    ? "btn hovborderbuttonColour"
                                    : "btn hovbuttonColour"
                                }
                                type="button"
                                key={option}
                                onClick={()=>this.setState({selected:option})}
                                style={{ marginRight: 8, width: 350 }}
                                value={option}
                              />
                            </div>
                          </label>
                        );
                      })}

                        <div className="row">
                          <fieldset className="form-group col-md-4">
                              <span className="mx-3">Active</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.active} onChange={()=>this.setState({active:true,completed:false})} name="checkedB" />}
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-4">
                              <span className="mx-3">Completed</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.completed} onChange={()=>this.setState({active:false,completed:true})} name="checkedB" />}
                              />
                            </fieldset>
                            </div>

                      {this.state.selected==="Finished Products"?
                        this.state.active?
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={`${this.state.name} - Active Finished Products`}
                              data={this.state.data}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                        :
                        <ThemeProvider theme={theme}>
                        <MUIDataTable
                          className="table-sm px-3"
                          title={`${this.state.name} - Completed Finished Products`}
                          data={this.state.completeddata}
                          columns={columns}
                          options={options}
                        />
                        </ThemeProvider>
                          :
                          this.state.active?
                          <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`${this.state.name} - Active Immediate Production Finished Products`}
                            data={this.state.data2}
                            columns={columns2}
                            options={options}
                          />
                        </ThemeProvider>
                        :
                        <ThemeProvider theme={theme}>
                        <MUIDataTable
                          className="table-sm px-3"
                          title={`${this.state.name} - Completed Immediate Production Finished Products`}
                          data={this.state.completeddata2}
                          columns={columns2}
                          options={options}
                        />
                      </ThemeProvider>
                        }
                          </>
                        )}
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(FinishedStoreTable);
