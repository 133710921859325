import { Table,TableHead,TableCell,TableRow,TableBody,TableContainer, TextField, Backdrop, CircularProgress } from '@mui/material'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import withRouter  from '../withRouter';
import { ccountry, currentTenant, financialyearend, financialyearstart, thousandsdisplay, userid } from '../Common/Credentials'
import Footer from '../Component/Footer'
import Header from '../Component/Header'
import Menu from '../Component/Menu'
import moment from 'moment'
import NewJournalService from './NewJournalService'
import {NumericFormat} from 'react-number-format';
import { Form, Formik } from 'formik'
import { spin } from '../Common/NewLoader';

const getTotal = (totamt) => {
    let total = 0;
    totamt.forEach(totamt => {
      total += totamt;
    });
    return total;
  };

let pendingindtaxes=[],input =[],output=[]//,date
let imon1=[],imon2=[],imon3=[],imon4=[],imon5=[],imon6=[],imon7=[],imon8=[],imon9=[],imon10=[],imon11=[],imon12=[]
let omon1=[],omon2=[],omon3=[],omon4=[],omon5=[],omon6=[],omon7=[],omon8=[],omon9=[],omon10=[],omon11=[],omon12=[]
let itots1,itots2,itots3,itots4,itots5,itots6,itots7,itots8,itots9,itots10,itots11,itots12
let itotc1,itotc2,itotc3,itotc4,itotc5,itotc6,itotc7,itotc8,itotc9,itotc10,itotc11,itotc12
let itoti1,itoti2,itoti3,itoti4,itoti5,itoti6,itoti7,itoti8,itoti9,itoti10,itoti11,itoti12
let itotv1,itotv2,itotv3,itotv4,itotv5,itotv6,itotv7,itotv8,itotv9,itotv10,itotv11,itotv12
let otots1,otots2,otots3,otots4,otots5,otots6,otots7,otots8,otots9,otots10,otots11,otots12
let ototc1,ototc2,ototc3,ototc4,ototc5,ototc6,ototc7,ototc8,ototc9,ototc10,ototc11,ototc12
let ototi1,ototi2,ototi3,ototi4,ototi5,ototi6,ototi7,ototi8,ototi9,ototi10,ototi11,ototi12
let ototv1,ototv2,ototv3,ototv4,ototv5,ototv6,ototv7,ototv8,ototv9,ototv10,ototv11,ototv12
let sbalmon1,sbalmon2,sbalmon3,sbalmon4,sbalmon5,sbalmon6,sbalmon7,sbalmon8,sbalmon9,sbalmon10,sbalmon11,sbalmon12
let cbalmon1,cbalmon2,cbalmon3,cbalmon4,cbalmon5,cbalmon6,cbalmon7,cbalmon8,cbalmon9,cbalmon10,cbalmon11,cbalmon12
let ibalmon1,ibalmon2,ibalmon3,ibalmon4,ibalmon5,ibalmon6,ibalmon7,ibalmon8,ibalmon9,ibalmon10,ibalmon11,ibalmon12
let vbalmon1,vbalmon2,vbalmon3,vbalmon4,vbalmon5,vbalmon6,vbalmon7,vbalmon8,vbalmon9,vbalmon10,vbalmon11,vbalmon12
let ifintots1,ifintots2,ifintots3,ifintots4,ifintots5,ifintots6,ifintots7,ifintots8,ifintots9,ifintots10,ifintots11,ifintots12
let ifintotc1,ifintotc2,ifintotc3,ifintotc4,ifintotc5,ifintotc6,ifintotc7,ifintotc8,ifintotc9,ifintotc10,ifintotc11,ifintotc12
let ifintoti1,ifintoti2,ifintoti3,ifintoti4,ifintoti5,ifintoti6,ifintoti7,ifintoti8,ifintoti9,ifintoti10,ifintoti11,ifintoti12
let ifintotv1,ifintotv2,ifintotv3,ifintotv4,ifintotv5,ifintotv6,ifintotv7,ifintotv8,ifintotv9,ifintotv10,ifintotv11,ifintotv12
let ofintots1,ofintots2,ofintots3,ofintots4,ofintots5,ofintots6,ofintots7,ofintots8,ofintots9,ofintots10,ofintots11,ofintots12
let ofintotc1,ofintotc2,ofintotc3,ofintotc4,ofintotc5,ofintotc6,ofintotc7,ofintotc8,ofintotc9,ofintotc10,ofintotc11,ofintotc12
let ofintoti1,ofintoti2,ofintoti3,ofintoti4,ofintoti5,ofintoti6,ofintoti7,ofintoti8,ofintoti9,ofintoti10,ofintoti11,ofintoti12
let ofintotv1,ofintotv2,ofintotv3,ofintotv4,ofintotv5,ofintotv6,ofintotv7,ofintotv8,ofintotv9,ofintotv10,ofintotv11,ofintotv12


class IndirectTaxes extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
         pendingindtaxes:[],
         mth:0,mname:'',loader1:true
          }
          this.onSelect=this.onSelect.bind(this)
    }

    componentDidMount() {
        this.setState({ loading: true })
        if (userid === undefined) {
          window.confirm("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
       // date = moment(new Date()).format('yy-M')
    //    startdate=moment(financialyearstart).format('M')
        // alert(moment(financialyearstart).add(7,'M').format('yy-M')<=date)
        // alert(moment(financialyearstart).add(7,'M').format('yy-M') + " --- " + date)

            NewJournalService.getPendingIndTaxes(currentTenant)
          .then((response) => {
            pendingindtaxes=response.data
            this.setState({
                pendingindtaxes: response.data,
            },()=>this.DataDetails());
            const script = document.createElement("script");
            script.src = "../../js/content.js";
            script.async = true;
            document.body.appendChild(script);
            this.setState({ loading: false });
          })
        //   .catch((e) => {
        //     if (e.response.data.error === "Unauthorized") {
        //       alert("Your Session is Lost: Please login Back to resume");
        //       this.props.navigate(`/`);
        //     }
        //   });
      }


      DataDetails(){

       
        input=pendingindtaxes.input
        output=pendingindtaxes.output
        // console.log(input)

        imon1=input.filter((e)=>e.createdAt===parseInt(moment(financialyearstart).format('M')))
        itots1=getTotal(imon1.map((e)=>e.statetaxamount))
        itotc1=getTotal(imon1.map((e)=>e.centraltaxamount))
        itoti1=getTotal(imon1.map((e)=>e.integratedtaxamount))
        itotv1=getTotal(imon1.map((e)=>e.vatamount))
        imon2=input.filter((e)=>e.createdAt===parseInt(moment(financialyearstart).add(1,'M').format('M')))
        itots2=getTotal(imon2.map((e)=>e.statetaxamount))
        itotc2=getTotal(imon2.map((e)=>e.centraltaxamount))
        itoti2=getTotal(imon2.map((e)=>e.integratedtaxamount))
        itotv2=getTotal(imon2.map((e)=>e.vatamount))
        imon3=input.filter((e)=>e.createdAt===parseInt(moment(financialyearstart).add(2,'M').format('M')))
        itots3=getTotal(imon3.map((e)=>e.statetaxamount))
        itotc3=getTotal(imon3.map((e)=>e.centraltaxamount))
        itoti3=getTotal(imon3.map((e)=>e.integratedtaxamount))
        itotv3=getTotal(imon3.map((e)=>e.vatamount))
        imon4=input.filter((e)=>e.createdAt===parseInt(moment(financialyearstart).add(3,'M').format('M')))
        itots4=getTotal(imon4.map((e)=>e.statetaxamount))
        itotc4=getTotal(imon4.map((e)=>e.centraltaxamount))
        itoti4=getTotal(imon4.map((e)=>e.integratedtaxamount))
        itotv4=getTotal(imon4.map((e)=>e.vatamount))
        imon5=input.filter((e)=>e.createdAt===parseInt(moment(financialyearstart).add(4,'M').format('M')))
        itots5=getTotal(imon5.map((e)=>e.statetaxamount))
        itotc5=getTotal(imon5.map((e)=>e.centraltaxamount))
        itoti5=getTotal(imon5.map((e)=>e.integratedtaxamount))
        itotv5=getTotal(imon5.map((e)=>e.vatamount))
        imon6=input.filter((e)=>e.createdAt===parseInt(moment(financialyearstart).add(5,'M').format('M')))
        itots6=getTotal(imon6.map((e)=>e.statetaxamount))
        itotc6=getTotal(imon6.map((e)=>e.centraltaxamount))
        itoti6=getTotal(imon6.map((e)=>e.integratedtaxamount))
        itotv6=getTotal(imon6.map((e)=>e.vatamount))
        imon7=input.filter((e)=>e.createdAt===parseInt(moment(financialyearstart).add(6,'M').format('M')))
        itots7=getTotal(imon7.map((e)=>e.statetaxamount))
        itotc7=getTotal(imon7.map((e)=>e.centraltaxamount))
        itoti7=getTotal(imon7.map((e)=>e.integratedtaxamount))
        itotv7=getTotal(imon7.map((e)=>e.vatamount))
        imon8=input.filter((e)=>e.createdAt===parseInt(moment(financialyearstart).add(7,'M').format('M')))
        itots8=getTotal(imon8.map((e)=>e.statetaxamount))
        itotc8=getTotal(imon8.map((e)=>e.centraltaxamount))
        itoti8=getTotal(imon8.map((e)=>e.integratedtaxamount))
        itotv8=getTotal(imon8.map((e)=>e.vatamount))
        imon9=input.filter((e)=>e.createdAt===parseInt(moment(financialyearstart).add(8,'M').format('M')))
        itots9=getTotal(imon9.map((e)=>e.statetaxamount))
        itotc9=getTotal(imon9.map((e)=>e.centraltaxamount))
        itoti9=getTotal(imon9.map((e)=>e.integratedtaxamount))
        itotv9=getTotal(imon9.map((e)=>e.vatamount))
        imon10=input.filter((e)=>e.createdAt===parseInt(moment(financialyearstart).add(9,'M').format('M')))
        itots10=getTotal(imon10.map((e)=>e.statetaxamount))
        itotc10=getTotal(imon10.map((e)=>e.centraltaxamount))
        itoti10=getTotal(imon10.map((e)=>e.integratedtaxamount))
        itotv10=getTotal(imon10.map((e)=>e.vatamount))
        imon11=input.filter((e)=>e.createdAt===parseInt(moment(financialyearstart).add(10,'M').format('M')))
        itots11=getTotal(imon11.map((e)=>e.statetaxamount))
        itotc11=getTotal(imon11.map((e)=>e.centraltaxamount))
        itoti11=getTotal(imon11.map((e)=>e.integratedtaxamount))
        itotv11=getTotal(imon11.map((e)=>e.vatamount))
        imon12=input.filter((e)=>e.createdAt===parseInt(moment(financialyearstart).add(11,'M').format('M')))
        itots12=getTotal(imon12.map((e)=>e.statetaxamount))
        itotc12=getTotal(imon12.map((e)=>e.centraltaxamount))
        itoti12=getTotal(imon12.map((e)=>e.integratedtaxamount))
        itotv12=getTotal(imon12.map((e)=>e.vatamount))

        omon1=output.filter((e)=>e.outcreatedAt===parseInt(moment(financialyearstart).format('M')))
        otots1=getTotal(omon1.map((e)=>e.outstatetaxamount))
        ototc1=getTotal(omon1.map((e)=>e.outcentraltaxamount))
        ototi1=getTotal(omon1.map((e)=>e.outintegratedtaxamount))
        ototv1=getTotal(omon1.map((e)=>e.outvatamount))
        omon2=output.filter((e)=>e.outcreatedAt===parseInt(moment(financialyearstart).add(1,'M').format('M')))
        otots2=getTotal(omon2.map((e)=>e.outstatetaxamount))
        ototc2=getTotal(omon2.map((e)=>e.outcentraltaxamount))
        ototi2=getTotal(omon2.map((e)=>e.outintegratedtaxamount))
        ototv2=getTotal(omon2.map((e)=>e.outvatamount))
        omon3=output.filter((e)=>e.outcreatedAt===parseInt(moment(financialyearstart).add(2,'M').format('M')))
        otots3=getTotal(omon3.map((e)=>e.outstatetaxamount))
        ototc3=getTotal(omon3.map((e)=>e.outcentraltaxamount))
        ototi3=getTotal(omon3.map((e)=>e.outintegratedtaxamount))
        ototv3=getTotal(omon3.map((e)=>e.outvatamount))
        omon4=output.filter((e)=>e.outcreatedAt===parseInt(moment(financialyearstart).add(3,'M').format('M')))
        otots4=getTotal(omon4.map((e)=>e.outstatetaxamount))
        ototc4=getTotal(omon4.map((e)=>e.outcentraltaxamount))
        ototi4=getTotal(omon4.map((e)=>e.outintegratedtaxamount))
        ototv4=getTotal(omon4.map((e)=>e.outvatamount))
        omon5=output.filter((e)=>e.outcreatedAt===parseInt(moment(financialyearstart).add(4,'M').format('M')))
        otots5=getTotal(omon5.map((e)=>e.outstatetaxamount))
        ototc5=getTotal(omon5.map((e)=>e.outcentraltaxamount))
        ototi5=getTotal(omon5.map((e)=>e.outintegratedtaxamount))
        ototv5=getTotal(omon5.map((e)=>e.outvatamount))
        omon6=output.filter((e)=>e.outcreatedAt===parseInt(moment(financialyearstart).add(5,'M').format('M')))
        otots6=getTotal(omon6.map((e)=>e.outstatetaxamount))
        ototc6=getTotal(omon6.map((e)=>e.outcentraltaxamount))
        ototi6=getTotal(omon6.map((e)=>e.outintegratedtaxamount))
        ototv6=getTotal(omon6.map((e)=>e.outvatamount))
        omon7=output.filter((e)=>e.outcreatedAt===parseInt(moment(financialyearstart).add(6,'M').format('M')))
        otots7=getTotal(omon7.map((e)=>e.outstatetaxamount))
        ototc7=getTotal(omon7.map((e)=>e.outcentraltaxamount))
        ototi7=getTotal(omon7.map((e)=>e.outintegratedtaxamount))
        ototv7=getTotal(omon7.map((e)=>e.outvatamount))
        omon8=output.filter((e)=>e.outcreatedAt===parseInt(moment(financialyearstart).add(7,'M').format('M')))
        otots8=getTotal(omon8.map((e)=>e.outstatetaxamount))
        ototc8=getTotal(omon8.map((e)=>e.outcentraltaxamount))
        ototi8=getTotal(omon8.map((e)=>e.outintegratedtaxamount))
        ototv8=getTotal(omon8.map((e)=>e.outvatamount))
        omon9=output.filter((e)=>e.outcreatedAt===parseInt(moment(financialyearstart).add(8,'M').format('M')))
        otots9=getTotal(omon9.map((e)=>e.outstatetaxamount))
        ototc9=getTotal(omon9.map((e)=>e.outcentraltaxamount))
        ototi9=getTotal(omon9.map((e)=>e.outintegratedtaxamount))
        ototv9=getTotal(omon9.map((e)=>e.outvatamount))
        omon10=output.filter((e)=>e.outcreatedAt===parseInt(moment(financialyearstart).add(9,'M').format('M')))
        otots10=getTotal(omon10.map((e)=>e.outstatetaxamount))
        ototc10=getTotal(omon10.map((e)=>e.outcentraltaxamount))
        ototi10=getTotal(omon10.map((e)=>e.outintegratedtaxamount))
        ototv10=getTotal(omon10.map((e)=>e.outvatamount))
        omon11=output.filter((e)=>e.outcreatedAt===parseInt(moment(financialyearstart).add(10,'M').format('M')))
        otots11=getTotal(omon11.map((e)=>e.outstatetaxamount))
        ototc11=getTotal(omon11.map((e)=>e.outcentraltaxamount))
        ototi11=getTotal(omon11.map((e)=>e.outintegratedtaxamount))
        ototv11=getTotal(omon11.map((e)=>e.outvatamount))
        omon12=output.filter((e)=>e.outcreatedAt===parseInt(moment(financialyearstart).add(11,'M').format('M')))
        otots12=getTotal(omon12.map((e)=>e.outstatetaxamount))
        ototc12=getTotal(omon12.map((e)=>e.outcentraltaxamount))
        ototi12=getTotal(omon12.map((e)=>e.outintegratedtaxamount))
        ototv12=getTotal(omon12.map((e)=>e.outvatamount))

        sbalmon1=itots1-otots1
        sbalmon2=itots2-otots2
        sbalmon3=itots3-otots3
        sbalmon4=itots4-otots4
        sbalmon5=itots5-otots5
        sbalmon6=itots6-otots6
        sbalmon7=itots7-otots7
        sbalmon8=itots8-otots8
        sbalmon9=itots9-otots9
        sbalmon10=itots10-otots10
        sbalmon11=itots11-otots11
        sbalmon12=itots12-otots12

        cbalmon1=itotc1-ototc1
        cbalmon2=itotc2-ototc2
        cbalmon3=itotc3-ototc3
        cbalmon4=itotc4-ototc4
        cbalmon5=itotc5-ototc5
        cbalmon6=itotc6-ototc6
        cbalmon7=itotc7-ototc7
        cbalmon8=itotc8-ototc8
        cbalmon9=itotc9-ototc9
        cbalmon10=itotc10-ototc10
        cbalmon11=itotc11-ototc11
        cbalmon12=itotc12-ototc12

        ibalmon1=itoti1-ototi1
        ibalmon2=itoti2-ototi2
        ibalmon3=itoti3-ototi3
        ibalmon4=itoti4-ototi4
        ibalmon5=itoti5-ototi5
        ibalmon6=itoti6-ototi6
        ibalmon7=itoti7-ototi7
        ibalmon8=itoti8-ototi8
        ibalmon9=itoti9-ototi9
        ibalmon10=itoti10-ototi10
        ibalmon11=itoti11-ototi11
        ibalmon12=itoti12-ototi12

        vbalmon1=itotv1-ototv1
        vbalmon2=itotv2-ototv2
        vbalmon3=itotv3-ototv3
        vbalmon4=itotv4-ototv4
        vbalmon5=itotv5-ototv5
        vbalmon6=itotv6-ototv6
        vbalmon7=itotv7-ototv7
        vbalmon8=itotv8-ototv8
        vbalmon9=itotv9-ototv9
        vbalmon10=itotv10-ototv10
        vbalmon11=itotv11-ototv11
        vbalmon12=itotv12-ototv12

        ifintots1=sbalmon1>=0?itots1:itots1
        ifintots2=sbalmon2>=0?(otots1-ifintots1>=0?otots1-ifintots1:ifintots1-otots1)+itots2:itots2
        ifintots3=sbalmon3>=0?(otots2-ifintots2>=0?otots2-ifintots2:ifintots3-otots2)+itots3:itots3
        ifintots4=sbalmon4>=0?(otots3-ifintots3>=0?otots3-ifintots3:ifintots3-otots3)+itots4:itots4
        ifintots5=sbalmon5>=0?(otots4-ifintots4>=0?otots4-ifintots4:ifintots4-otots4)+itots5:itots5
        ifintots6=sbalmon6>=0?(otots5-ifintots5>=0?otots5-ifintots5:ifintots5-otots5)+itots6:itots6
        ifintots7=sbalmon7>=0?(otots6-ifintots6>=0?otots6-ifintots6:ifintots6-otots6)+itots7:itots7
        ifintots8=sbalmon8>=0?(otots7-ifintots7>=0?otots7-ifintots7:ifintots7-otots7)+itots8:itots8
        ifintots9=sbalmon9>=0?(otots8-ifintots8>=0?otots8-ifintots8:ifintots8-otots8)+itots9:itots9
        ifintots10=sbalmon10>=0?(otots9-ifintots9>=0?otots9-ifintots9:ifintots9-otots9)+itots10:itots10
        ifintots11=sbalmon11>=0?(otots10-ifintots10>=0?otots10-ifintots10:ifintots10-otots10)+itots11:itots11
        ifintots12=sbalmon12>=0?(otots11-ifintots11>=0?otots11-ifintots11:ifintots11-otots11)+itots12:itots12

        // console.log(ifintots1,ifintots2,ifintots3,ifintots4,ifintots5,ifintots6,ifintots7,ifintots8,ifintots9,ifintots10,ifintots11,ifintots12)

        ifintotc1=cbalmon1>=0?itotc1:itotc1
        ifintotc2=cbalmon2>=0?(ototc1-ifintotc1>=0?ototc1-ifintotc1:ifintotc1-ototc1)+itotc2:itotc2
        ifintotc3=cbalmon3>=0?(ototc2-ifintotc2>=0?ototc2-ifintotc2:ifintotc3-ototc2)+itotc3:itotc3
        ifintotc4=cbalmon4>=0?(ototc3-ifintotc3>=0?ototc3-ifintotc3:ifintotc3-ototc3)+itotc4:itotc4
        ifintotc5=cbalmon5>=0?(ototc4-ifintotc4>=0?ototc4-ifintotc4:ifintotc4-ototc4)+itotc5:itotc5
        ifintotc6=cbalmon6>=0?(ototc5-ifintotc5>=0?ototc5-ifintotc5:ifintotc5-ototc5)+itotc6:itotc6
        ifintotc7=cbalmon7>=0?(ototc6-ifintotc6>=0?ototc6-ifintotc6:ifintotc6-ototc6)+itotc7:itotc7
        ifintotc8=cbalmon8>=0?(ototc7-ifintotc7>=0?ototc7-ifintotc7:ifintotc7-ototc7)+itotc8:itotc8
        ifintotc9=cbalmon9>=0?(ototc8-ifintotc8>=0?ototc8-ifintotc8:ifintotc8-ototc8)+itotc9:itotc9
        ifintotc10=cbalmon10>=0?(ototc9-ifintotc9>=0?ototc9-ifintotc9:ifintotc9-ototc9)+itotc10:itotc10
        ifintotc11=cbalmon11>=0?(ototc10-ifintotc10>=0?ototc10-ifintotc10:ifintotc10-ototc10)+itotc11:itotc11
        ifintotc12=cbalmon12>=0?(ototc11-ifintotc11>=0?ototc11-ifintotc11:ifintotc11-ototc11)+itotc12:itotc12

        ifintoti1=ibalmon1>=0?itoti1:itoti1
        ifintoti2=ibalmon2>=0?(ototi1-ifintoti1>=0?ototi1-ifintoti1:ifintoti1-ototi1)+itoti2:itoti2
        ifintoti3=ibalmon3>=0?(ototi2-ifintoti2>=0?ototi2-ifintoti2:ifintoti3-ototi2)+itoti3:itoti3
        ifintoti4=ibalmon4>=0?(ototi3-ifintoti3>=0?ototi3-ifintoti3:ifintoti3-ototi3)+itoti4:itoti4
        ifintoti5=ibalmon5>=0?(ototi4-ifintoti4>=0?ototi4-ifintoti4:ifintoti4-ototi4)+itoti5:itoti5
        ifintoti6=ibalmon6>=0?(ototi5-ifintoti5>=0?ototi5-ifintoti5:ifintoti5-ototi5)+itoti6:itoti6
        ifintoti7=ibalmon7>=0?(ototi6-ifintoti6>=0?ototi6-ifintoti6:ifintoti6-ototi6)+itoti7:itoti7
        ifintoti8=ibalmon8>=0?(ototi7-ifintoti7>=0?ototi7-ifintoti7:ifintoti7-ototi7)+itoti8:itoti8
        ifintoti9=ibalmon9>=0?(ototi8-ifintoti8>=0?ototi8-ifintoti8:ifintoti8-ototi8)+itoti9:itoti9
        ifintoti10=ibalmon10>=0?(ototi9-ifintoti9>=0?ototi9-ifintoti9:ifintoti9-ototi9)+itoti10:itoti10
        ifintoti11=ibalmon11>=0?(ototi10-ifintoti10>=0?ototi10-ifintoti10:ifintoti10-ototi10)+itoti11:itoti11
        ifintoti12=ibalmon12>=0?(ototi11-ifintoti11>=0?ototi11-ifintoti11:ifintoti11-ototi11)+itoti12:itoti12

        ifintotv1=vbalmon1>=0?itotv1:itotv1
        ifintotv2=vbalmon2>=0?(ototv1-ifintotv1>=0?ototv1-ifintotv1:ifintotv1-ototv1)+itotv2:itotv2
        ifintotv3=vbalmon3>=0?(ototv2-ifintotv2>=0?ototv2-ifintotv2:ifintotv3-ototv2)+itotv3:itotv3
        ifintotv4=vbalmon4>=0?(ototv3-ifintotv3>=0?ototv3-ifintotv3:ifintotv3-ototv3)+itotv4:itotv4
        ifintotv5=vbalmon5>=0?(ototv4-ifintotv4>=0?ototv4-ifintotv4:ifintotv4-ototv4)+itotv5:itotv5
        ifintotv6=vbalmon6>=0?(ototv5-ifintotv5>=0?ototv5-ifintotv5:ifintotv5-ototv5)+itotv6:itotv6
        ifintotv7=vbalmon7>=0?(ototv6-ifintotv6>=0?ototv6-ifintotv6:ifintotv6-ototv6)+itotv7:itotv7
        ifintotv8=vbalmon8>=0?(ototv7-ifintotv7>=0?ototv7-ifintotv7:ifintotv7-ototv7)+itotv8:itotv8
        ifintotv9=vbalmon9>=0?(ototv8-ifintotv8>=0?ototv8-ifintotv8:ifintotv8-ototv8)+itotv9:itotv9
        ifintotv10=vbalmon10>=0?(ototv9-ifintotv9>=0?ototv9-ifintotv9:ifintotv9-ototv9)+itotv10:itotv10
        ifintotv11=vbalmon11>=0?(ototv10-ifintotv10>=0?ototv10-ifintotv10:ifintotv10-ototv10)+itotv11:itotv11
        ifintotv12=vbalmon12>=0?(ototv11-ifintotv11>=0?ototv11-ifintotv11:ifintotv11-ototv11)+itotv12:itotv12
       

        ofintots1=sbalmon1<0?sbalmon1+otots1:otots1
        ofintots2=sbalmon1<0?sbalmon1+otots2:otots2
        ofintots3=sbalmon2<0?sbalmon1+sbalmon2+otots3:otots3
        ofintots4=sbalmon3<0?sbalmon2+sbalmon3+otots4:otots4
        ofintots5=sbalmon4<0?sbalmon3+sbalmon4+otots5:otots5
        ofintots6=sbalmon5<0?sbalmon4+sbalmon5+otots6:otots6
        ofintots7=sbalmon6<0?sbalmon5+sbalmon6+otots7:otots7
        ofintots8=sbalmon7<0?sbalmon6+sbalmon7+otots8:otots8
        ofintots9=sbalmon8<0?sbalmon7+sbalmon8+otots9:otots9
        ofintots10=sbalmon9<0?sbalmon8+sbalmon9+otots10:otots10
        ofintots11=sbalmon10<0?sbalmon9+sbalmon10+otots11:otots11
        ofintots12=sbalmon11<0?sbalmon10+sbalmon11+otots12:otots12

        ofintotc1=cbalmon1<0?cbalmon1+ototc1:ototc1
        ofintotc2=cbalmon1<0?cbalmon1+ototc2:ototc2
        ofintotc3=cbalmon2<0?cbalmon1+cbalmon3+ototc3:ototc3
        ofintotc4=cbalmon3<0?cbalmon2+cbalmon4+ototc4:ototc4
        ofintotc5=cbalmon4<0?cbalmon3+cbalmon5+ototc5:ototc5
        ofintotc6=cbalmon5<0?cbalmon4+cbalmon6+ototc6:ototc6
        ofintotc7=cbalmon6<0?cbalmon5+cbalmon7+ototc7:ototc7
        ofintotc8=cbalmon7<0?cbalmon6+cbalmon8+ototc8:ototc8
        ofintotc9=cbalmon8<0?cbalmon7+cbalmon9+ototc9:ototc9
        ofintotc10=cbalmon9<0?cbalmon8+cbalmon10+ototc10:ototc10
        ofintotc11=cbalmon10<0?cbalmon9+cbalmon11+ototc11:ototc11
        ofintotc12=cbalmon11<0?cbalmon10+cbalmon12+ototc12:ototc12

        ofintoti1=ibalmon1<0?ibalmon1+ototi1:ototi1
        ofintoti2=ibalmon1<0?ibalmon1+ototi2:ototi2
        ofintoti3=ibalmon2<0?ibalmon1+ibalmon2+ototi3:ototi3
        ofintoti4=ibalmon3<0?ibalmon2+ibalmon3+ototi4:ototi4
        ofintoti5=ibalmon4<0?ibalmon3+ibalmon4+ototi5:ototi5
        ofintoti6=ibalmon5<0?ibalmon4+ibalmon5+ototi6:ototi6
        ofintoti7=ibalmon6<0?ibalmon5+ibalmon6+ototi7:ototi7
        ofintoti8=ibalmon7<0?ibalmon6+ibalmon7+ototi8:ototi8
        ofintoti9=ibalmon8<0?ibalmon7+ibalmon8+ototi9:ototi9
        ofintoti10=ibalmon9<0?ibalmon8+ibalmon9+ototi10:ototi10
        ofintoti11=ibalmon10<0?ibalmon9+ibalmon10+ototi11:ototi11
        ofintoti12=ibalmon11<0?ibalmon10+ibalmon11+ototi12:ototi12

        ofintotv1=vbalmon1<0?vbalmon1+ototv1:ototv1
        ofintotv2=vbalmon1<0?vbalmon1+ototv2:ototv2
        ofintotv3=vbalmon2<0?vbalmon1+vbalmon2+ototv3:ototv3
        ofintotv4=vbalmon3<0?vbalmon2+vbalmon3+ototv4:ototv4
        ofintotv5=vbalmon4<0?vbalmon3+vbalmon4+ototv5:ototv5
        ofintotv6=vbalmon5<0?vbalmon4+vbalmon5+ototv6:ototv6
        ofintotv7=vbalmon6<0?vbalmon5+vbalmon6+ototv7:ototv7
        ofintotv8=vbalmon7<0?vbalmon6+vbalmon7+ototv8:ototv8
        ofintotv9=vbalmon8<0?vbalmon7+vbalmon8+ototv9:ototv9
        ofintotv10=vbalmon9<0?vbalmon8+vbalmon9+ototv10:ototv10
        ofintotv11=vbalmon10<0?vbalmon9+vbalmon10+ototv11:ototv11
        ofintotv12=vbalmon11<0?vbalmon10+vbalmon11+ototv12:ototv12


        this.setState({loader1:false})
}
onSelect(month,mname){
     let inpt,outpt//,data
     inpt=input.filter((e)=>e.createdAt===parseInt(month))
     outpt=output.filter((e)=>e.outcreatedAt===parseInt(month))
     let curdate=moment(parseInt(month),'M').endOf('month').format('yyyy-MM-DD HH:mm:ss')
    this.setState({mth:month})
    this.setState({mname:mname},()=>
    this.props.navigate({pathname:`/monthindtax`}, {state:{mth:this.state.mth,mname:this.state.mname,input:inpt,output:outpt,curdate:curdate}}
    ))

}

  render() {

    //this.DataDetails()
    return (
      <div>
         <Header/>
        <Menu/>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="content-wrapper">
            <div className="card">
                <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item text-secondary">
                    <Link to="/accounts">Home</Link>
                </li>
                <li className="breadcrumb-item active">Indirect Taxes</li>
                </ol>
                <div className="card-body">
                {this.state.loading===true?spin:''}

                <Formik
      initialValues={{month:""}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{() => (

        <Form autoComplete="off">
 
                {/* <div><h5 className="text-center">P&L Income Expense Statement</h5></div> */}
                      <div><p className="text-center text-bold">Indirect Taxes for the Financial Year - {moment(financialyearstart).format('DD-MM-YYYY')} --- {moment(financialyearend).format('DD-MM-YYYY')}
                     </p></div>
                     <TableContainer>
                    <Table className="table table-sm table-bordered">
                        <TableHead className="thead-light thead-bordered mt-0 mb-0" >
                            <TableRow className="tr-bordered mt-0 mb-4">
                            {/* <TableCell className='text-center'>Category</TableCell> */}
                            {/* {moment(financialyearstart).format('yy-M')<=date?                                 */}
                                <TableCell className='text-center'>{moment(financialyearstart).format('MMMM-yy')}</TableCell>
                                {/* :""} */}
                            {/* {moment(financialyearstart).add(1,'M').format('yy-M')<=date?                                 */}
                                <TableCell className='text-center'>{moment(financialyearstart).add(1,'M').format('MMMM-yy')}</TableCell>
                                {/* :""} */}
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            <TableRow>

 {/* ------------------------------------1 St Month -----------------------------------------------     */}
 {/* {moment(financialyearstart).format('yy-M')<=date?                                 */}
 <TableCell className='text-center'>
<Table><TableHead><TableRow>
<TableCell className='text-center'>Input</TableCell> <TableCell className='text-center'>Output</TableCell>
 </TableRow> </TableHead>
 <TableRow>

     <TableCell className='text-center'>

     <Table>
    <TableHead>
     {ccountry==="India"?
      <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
       <TableCell className='text-center'>IGST</TableCell></TableRow>:
       <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
       <TableBody>
       {imon1.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.itemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="st4" className="align-right text-sm" value={e.statetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ct4" className="align-right text-sm" value={e.centraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="it4" className="align-right text-sm" value={e.integratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="v4" className="align-right text-sm" value={e.vatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="stt4" className="align-right text-sm" value={itots1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotc1}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itoti1}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotv1}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pi ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Previous Balance</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={sbalmon1!==0?sbalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={cbalmon1!==0?cbalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ibalmon1!==0?ibalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={vbalmon1!==0?vbalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pi end----------------- */}

         {/* ---------------Final Total ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
       
       
       </TableBody>
       </Table>
       </TableCell>

       <TableCell className='text-center'>
        <Table>
        <TableHead>
        {ccountry==="India"?
        <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
        <TableCell className='text-center'>IGST</TableCell></TableRow>:
        <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
        <TableBody>
        {omon1.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.outitemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="ost4" className="align-right text-sm" value={e.outstatetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="oct4" className="align-right text-sm" value={e.outcentraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="oit4" className="align-right text-sm" value={e.outintegratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="ov4" className="align-right text-sm" value={e.outvatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ostt4" className="align-right text-sm" value={otots1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="octt4" className="align-right text-sm" value={ototc1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="oitt4" className="align-right text-sm" value={ototi1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ovtt4" className="align-right text-sm" value={ototv1}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pb ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Tax Dues</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={sbalmon1<0?sbalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={cbalmon1<0?cbalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ibalmon1<0?ibalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={vbalmon1<0?vbalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pb end----------------- */}
 {/* ---------------Final Total ----------------- */}
 {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintots1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotc1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintoti1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotv1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
        {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Balance</TableCell>
        <TableCell className='text-right'>
        {otots1-ifintots1>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={otots1-ifintots1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots1-otots1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell> 
        <TableCell className='text-right'>
        {ototc1-ifintotc1>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototc1-ifintotc1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc1-ototc1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         
        <TableCell className='text-right'>
        {ototi1-ifintoti1>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototi1-ifintoti1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti1-ototi1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        {ototv1-ifintotv1>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototv1-ifintotv1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv1-ototv1} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>        
        </TableRow>}
        {(otots1-itots1)>0 || (ototc1-itotc1)>0 || (ototi1-itoti1)>0 || (ototv1-itotv1)>0?
        <TableRow className='rext-right'>
        <TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell>
        <TableCell className='border-0 text-right font-weight-bold text-blue'>
        <span onClick={() =>this.onSelect(moment(financialyearstart).add(0,'M').format('M'),moment(financialyearstart).add(0,'M').format('MMMM-yy'))}>Details</span>
        </TableCell>
        </TableRow>:""}  
        </TableBody>
        </Table>
         </TableCell>                                                                           
    </TableRow></Table> </TableCell>
    {/* :""} */}
      {/* ------------------------------------2 Nd Month -----------------------------------------------                                      */}
      {/* {moment(financialyearstart).add(1,'M').format('yy-M')<=date?                                  */}
      <TableCell className='text-center'>
<Table><TableHead><TableRow>
<TableCell className='text-center'>Input</TableCell> <TableCell className='text-center'>Output</TableCell>
 </TableRow> </TableHead>
 <TableRow>

     <TableCell className='text-center'>

     <Table>
    <TableHead>
     {ccountry==="India"?
      <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
       <TableCell className='text-center'>IGST</TableCell></TableRow>:
       <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
       <TableBody>
       {imon2.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.itemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="st4" className="align-right text-sm" value={e.statetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ct4" className="align-right text-sm" value={e.centraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="it4" className="align-right text-sm" value={e.integratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="v4" className="align-right text-sm" value={e.vatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="stt4" className="align-right text-sm" value={itots2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotc2}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itoti2}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotv2}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pi ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Previous Balance</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={sbalmon1!==0?sbalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={cbalmon1!==0?cbalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ibalmon1===0?ibalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={vbalmon1!==0?vbalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pi end----------------- */}

         {/* ---------------Final Total ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
       </TableBody>
       </Table>
       </TableCell>

       <TableCell className='text-center'>
        <Table>
        <TableHead>
        {ccountry==="India"?
        <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
        <TableCell className='text-center'>IGST</TableCell></TableRow>:
        <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
        <TableBody>
        {omon2.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.outitemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="ost4" className="align-right text-sm" value={e.outstatetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="oct4" className="align-right text-sm" value={e.outcentraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="oit4" className="align-right text-sm" value={e.outintegratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="ov4" className="align-right text-sm" value={e.outvatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ostt4" className="align-right text-sm" value={otots2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="octt4" className="align-right text-sm" value={ototc2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="oitt4" className="align-right text-sm" value={ototi2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ovtt4" className="align-right text-sm" value={ototv2}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pb ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Tax Dues</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={sbalmon1<0?sbalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={cbalmon1<0?cbalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ibalmon1<0?ibalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={vbalmon1<0?vbalmon1:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pb end----------------- */}
 {/* ---------------Final Total ----------------- */}
 {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintots2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotc2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintoti2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotv2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
        {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Balance</TableCell>
        <TableCell className='text-right'>
        {otots2-ifintots2>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={otots2-ifintots2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots2-otots2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell> 
        <TableCell className='text-right'>
        {ototc2-ifintotc2>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototc2-ifintotc2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc2-ototc2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         
        <TableCell className='text-right'>
        {ototi2-ifintoti2>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototi2-ifintoti2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti2-ototi2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        {ototv2-ifintotv2>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototv2-ifintotv2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv2-ototv2} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>        
        </TableRow>}
        {(otots2-itots2)>0 || (ototc2-itotc2)>0 || (ototi2-itoti2)>0 || (ototv2-itotv2)>0?
        <TableRow className='rext-right'>
        <TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell>
        <TableCell className='border-0 text-right font-weight-bold text-blue'>
        <span onClick={() =>this.onSelect(moment(financialyearstart).add(1,'M').format('M'),moment(financialyearstart).add(1,'M').format('MMMM-yy'))}>Details</span>
        </TableCell>
        </TableRow>:""} 
        </TableBody>
        </Table>
         </TableCell>                                                                           
    </TableRow></Table> </TableCell>
     {/* :""}    */}
                            </TableRow>
                        </TableBody>
      <TableHead className="thead-light thead-bordered mt-0 mb-0" >
                            <TableRow className="tr-bordered mt-0 mb-4">
                            {/* {moment(financialyearstart).add(2,'M').format('yy-M')<=date?                                 */}
                                <TableCell className='text-center'>{moment(financialyearstart).add(2,'M').format('MMMM-yy')}</TableCell>
                                {/* :""} */}
                             {/* {moment(financialyearstart).add(3,'M').format('yy-M')<=date?                                 */}
                                 <TableCell className='text-center'>{moment(financialyearstart).add(3,'M').format('MMMM-yy')}</TableCell>
                                 {/* :""}     */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
 {/* ------------------------------------3 Rd Month -----------------------------------------------                                      */}
 {/* {moment(financialyearstart).add(2,'M').format('yy-M')<=date?                                 */}
 <TableCell className='text-center'>
<Table><TableHead><TableRow>
<TableCell className='text-center'>Input</TableCell> <TableCell className='text-center'>Output</TableCell>
 </TableRow> </TableHead>
 <TableRow>

     <TableCell className='text-center'>

     <Table>
    <TableHead>
     {ccountry==="India"?
      <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
       <TableCell className='text-center'>IGST</TableCell></TableRow>:
       <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
       <TableBody>
       {imon3.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.itemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="st4" className="align-right text-sm" value={e.statetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ct4" className="align-right text-sm" value={e.centraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="it4" className="align-right text-sm" value={e.integratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="v4" className="align-right text-sm" value={e.vatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="stt4" className="align-right text-sm" value={itots3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotc3}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itoti3}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotv3}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pi ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Previous Balance</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={sbalmon2!==0?sbalmon2:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={cbalmon2!==0?cbalmon2:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ibalmon2===0?ibalmon2:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={vbalmon2!==0?vbalmon2:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pi end----------------- */}

         {/* ---------------Final Total ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
       </TableBody>
       </Table>
       </TableCell>

       <TableCell className='text-center'>
        <Table>
        <TableHead>
        {ccountry==="India"?
        <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
        <TableCell className='text-center'>IGST</TableCell></TableRow>:
        <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
        <TableBody>
        {omon3.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.outitemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="ost4" className="align-right text-sm" value={e.outstatetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="oct4" className="align-right text-sm" value={e.outcentraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="oit4" className="align-right text-sm" value={e.outintegratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="ov4" className="align-right text-sm" value={e.outvatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ostt4" className="align-right text-sm" value={otots3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="octt4" className="align-right text-sm" value={ototc3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="oitt4" className="align-right text-sm" value={ototi3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ovtt4" className="align-right text-sm" value={ototv3}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pb ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Tax Dues</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={sbalmon2<0?sbalmon2:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={cbalmon2<0?cbalmon2:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ibalmon2<0?ibalmon2:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={vbalmon2<0?vbalmon2:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pb end----------------- */}
 {/* ---------------Final Total ----------------- */}
 {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintots3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotc3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintoti3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotv3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
        {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Balance</TableCell>
        <TableCell className='text-right'>
        {otots3-ifintots3>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={otots3-ifintots3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots3-otots3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell> 
        <TableCell className='text-right'>
        {ototc3-ifintotc3>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototc3-ifintotc3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc3-ototc3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         
        <TableCell className='text-right'>
        {ototi3-ifintoti3>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototi3-ifintoti3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti3-ototi3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        {ototv3-ifintotv3>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototv3-ifintotv3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv3-ototv3} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>        
        </TableRow>}
        {(otots3-itots3)>0 || (ototc3-itotc3)>0 || (ototi3-itoti3)>0 || (ototv3-itotv3)>0?
        <TableRow className='rext-right'>
        <TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell>
        <TableCell className='border-0 text-right font-weight-bold text-blue'>
        <span onClick={() =>this.onSelect(moment(financialyearstart).add(2,'M').format('M'),moment(financialyearstart).add(2,'M').format('MMMM-yy'))}>Details</span>
        </TableCell>
        </TableRow>:""} 
        </TableBody>
        </Table>
         </TableCell>                                                                           
    </TableRow></Table> </TableCell>
        {/* :""}  */}
   {/* ------------------------------------4 th Month -----------------------------------------------                                      */}
   {/* {moment(financialyearstart).add(3,'M').format('yy-M')<=date?                                 */}
   <TableCell className='text-center'>
<Table><TableHead><TableRow>
<TableCell className='text-center'>Input</TableCell> <TableCell className='text-center'>Output</TableCell>
 </TableRow> </TableHead>
 <TableRow>

     <TableCell className='text-center'>

     <Table>
    <TableHead>
     {ccountry==="India"?
      <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
       <TableCell className='text-center'>IGST</TableCell></TableRow>:
       <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
       <TableBody>
       {imon4.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.itemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="st4" className="align-right text-sm" value={e.statetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ct4" className="align-right text-sm" value={e.centraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="it4" className="align-right text-sm" value={e.integratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="v4" className="align-right text-sm" value={e.vatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="stt4" className="align-right text-sm" value={itots4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotc4}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itoti4}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotv4}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pi ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Previous Balance</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={sbalmon3!==0?sbalmon3:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={cbalmon3!==0?cbalmon3:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ibalmon3!==0?ibalmon3:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={vbalmon3!==0?vbalmon3:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pi end----------------- */}

         {/* ---------------Final Total ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
       
       
       </TableBody>
       </Table>
       </TableCell>

       <TableCell className='text-center'>
        <Table>
        <TableHead>
        {ccountry==="India"?
        <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
        <TableCell className='text-center'>IGST</TableCell></TableRow>:
        <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
        <TableBody>
        {omon4.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.outitemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="ost4" className="align-right text-sm" value={e.outstatetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="oct4" className="align-right text-sm" value={e.outcentraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="oit4" className="align-right text-sm" value={e.outintegratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="ov4" className="align-right text-sm" value={e.outvatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ostt4" className="align-right text-sm" value={otots4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="octt4" className="align-right text-sm" value={ototc4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="oitt4" className="align-right text-sm" value={ototi4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ovtt4" className="align-right text-sm" value={ototv4}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pb ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Tax Dues</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={sbalmon3<0?sbalmon3:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={cbalmon3<0?cbalmon3:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ibalmon3<0?ibalmon3:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={vbalmon3<0?vbalmon3:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pb end----------------- */}
 {/* ---------------Final Total ----------------- */}
 {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintots4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotc4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintoti4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotv4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
        {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Balance</TableCell>
        <TableCell className='text-right'>
        {otots4-ifintots4>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={otots4-ifintots4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots4-otots4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell> 
        <TableCell className='text-right'>
        {ototc4-ifintotc4>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototc4-ifintotc4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc4-ototc4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         
        <TableCell className='text-right'>
        {ototi4-ifintoti4>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototi4-ifintoti4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti4-ototi4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        {ototv4-ifintotv4>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototv4-ifintotv4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv4-ototv4} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>        
        </TableRow>}
        {(otots4-itots4)>0 || (ototc4-itotc4)>0 || (ototi4-itoti4)>0 || (ototv4-itotv4)>0?
        <TableRow className='rext-right'>
        <TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell>
        <TableCell className='border-0 text-right font-weight-bold text-blue'>
        <span onClick={() =>this.onSelect(moment(financialyearstart).add(3,'M').format('M'),moment(financialyearstart).add(3,'M').format('MMMM-yy'))}>Details</span>
        </TableCell>
        </TableRow>:""}  
        </TableBody>
        </Table>
         </TableCell>                                                                           
    </TableRow></Table> </TableCell>
    {/* :""}  */}
    </TableRow>  </TableBody>


    <TableHead className="thead-light thead-bordered mt-0 mb-0" >
                            <TableRow className="tr-bordered mt-0 mb-4">
                            {/* {moment(financialyearstart).add(4,'M').format('yy-M')<=date?                                 */}
                                <TableCell className='text-center'>{moment(financialyearstart).add(4,'M').format('MMMM-yy')}</TableCell>
                                {/* :""} */}
                            {/* {moment(financialyearstart).add(5,'M').format('yy-M')<=date?                                 */}
                                <TableCell className='text-center'>{moment(financialyearstart).add(5,'M').format('MMMM-yy')}</TableCell>
                                {/* :""} */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>

 {/* ------------------------------------5 Th Month -----------------------------------------------                                      */}
 {/* {moment(financialyearstart).add(4,'M').format('yy-M')<=date?                                 */}
 <TableCell className='text-center'>
<Table><TableHead><TableRow>
<TableCell className='text-center'>Input</TableCell> <TableCell className='text-center'>Output</TableCell>
 </TableRow> </TableHead>
 <TableRow>

     <TableCell className='text-center'>

     <Table>
    <TableHead>
     {ccountry==="India"?
      <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
       <TableCell className='text-center'>IGST</TableCell></TableRow>:
       <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
       <TableBody>
       {imon5.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.itemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="st4" className="align-right text-sm" value={e.statetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ct4" className="align-right text-sm" value={e.centraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="it4" className="align-right text-sm" value={e.integratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="v4" className="align-right text-sm" value={e.vatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="stt4" className="align-right text-sm" value={itots5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotc5}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itoti5}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotv5}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pi ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Previous Balance</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={sbalmon4!==0?sbalmon4:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={cbalmon4!==0?cbalmon4:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ibalmon4!==0?ibalmon4:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={vbalmon4!==0?vbalmon4:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pi end----------------- */}

         {/* ---------------Final Total ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
       </TableBody>
       </Table>
       </TableCell>

       <TableCell className='text-center'>
        <Table>
        <TableHead>
        {ccountry==="India"?
        <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
        <TableCell className='text-center'>IGST</TableCell></TableRow>:
        <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
        <TableBody>
        {omon5.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.outitemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="ost4" className="align-right text-sm" value={e.outstatetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="oct4" className="align-right text-sm" value={e.outcentraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="oit4" className="align-right text-sm" value={e.outintegratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="ov4" className="align-right text-sm" value={e.outvatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ostt4" className="align-right text-sm" value={otots5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="octt4" className="align-right text-sm" value={ototc5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="oitt4" className="align-right text-sm" value={ototi5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ovtt4" className="align-right text-sm" value={ototv5}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pb ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Tax Dues</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={sbalmon4<0?sbalmon4:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={cbalmon4<0?cbalmon4:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ibalmon4<0?ibalmon4:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={vbalmon4<0?vbalmon4:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pb end----------------- */}
 {/* ---------------Final Total ----------------- */}
 {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintots5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotc5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintoti5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotv5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
        {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Balance</TableCell>
        <TableCell className='text-right'>
        {otots5-ifintots5>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={otots5-ifintots5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots5-otots5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell> 
        <TableCell className='text-right'>
        {ototc5-ifintotc5>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototc5-ifintotc5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc5-ototc5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         
        <TableCell className='text-right'>
        {ototi5-ifintoti5>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototi5-ifintoti5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti5-ototi5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        {ototv5-ifintotv5>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototv5-ifintotv5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv5-ototv5} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>        
        </TableRow>}
        {(otots5-itots5)>0 || (ototc5-itotc5)>0 || (ototi5-itoti5)>0 || (ototv5-itotv5)>0?
        <TableRow className='rext-right'>
        <TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell>
        <TableCell className='border-0 text-right font-weight-bold text-blue'>
        <span onClick={() =>this.onSelect(moment(financialyearstart).add(4,'M').format('M'),moment(financialyearstart).add(4,'M').format('MMMM-yy'))}>Details</span>
        </TableCell>
        </TableRow>:""} 
        </TableBody>
        </Table>
         </TableCell>                                                                           
    </TableRow></Table> </TableCell>
    {/* :""} */}
      {/* ------------------------------------6 Th Month -----------------------------------------------                                      */}
      {/* {moment(financialyearstart).add(5,'M').format('yy-M')<=date?                                 */}
      <TableCell className='text-center'>
<Table><TableHead><TableRow>
<TableCell className='text-center'>Input</TableCell> <TableCell className='text-center'>Output</TableCell>
 </TableRow> </TableHead>
 <TableRow>

     <TableCell className='text-center'>

     <Table>
    <TableHead>
     {ccountry==="India"?
      <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
       <TableCell className='text-center'>IGST</TableCell></TableRow>:
       <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
       <TableBody>
       {imon6.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.itemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="st4" className="align-right text-sm" value={e.statetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ct4" className="align-right text-sm" value={e.centraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="it4" className="align-right text-sm" value={e.integratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="v4" className="align-right text-sm" value={e.vatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="stt4" className="align-right text-sm" value={itots6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotc6}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itoti6}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotv6}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pi ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Previous Balance</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={sbalmon5!==0?sbalmon5:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={cbalmon5!==0?cbalmon5:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ibalmon5!==0?ibalmon5:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={vbalmon5!==0?vbalmon5:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pi end----------------- */}

         {/* ---------------Final Total ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
       </TableBody>
       </Table>
       </TableCell>

       <TableCell className='text-center'>
        <Table>
        <TableHead>
        {ccountry==="India"?
        <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
        <TableCell className='text-center'>IGST</TableCell></TableRow>:
        <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
        <TableBody>
        {omon6.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.outitemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="ost4" className="align-right text-sm" value={e.outstatetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="oct4" className="align-right text-sm" value={e.outcentraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="oit4" className="align-right text-sm" value={e.outintegratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="ov4" className="align-right text-sm" value={e.outvatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ostt4" className="align-right text-sm" value={otots6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="octt4" className="align-right text-sm" value={ototc6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="oitt4" className="align-right text-sm" value={ototi6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ovtt4" className="align-right text-sm" value={ototv6}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pb ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Tax Dues</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={sbalmon5<0?sbalmon5:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={cbalmon5<0?cbalmon5:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ibalmon5<0?ibalmon5:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={vbalmon5<0?vbalmon5:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pb end----------------- */}
 {/* ---------------Final Total ----------------- */}
 {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintots6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotc6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintoti6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotv6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
        {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Balance</TableCell>
        <TableCell className='text-right'>
        {otots6-ifintots6>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={otots6-ifintots6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots6-otots6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell> 
        <TableCell className='text-right'>
        {ototc6-ifintotc6>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototc6-ifintotc6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc6-ototc6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         
        <TableCell className='text-right'>
        {ototi6-ifintoti6>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototi6-ifintoti6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti6-ototi6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        {ototv6-ifintotv6>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototv6-ifintotv6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv6-ototv6} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>        
        </TableRow>}
        {(otots6-itots6)>0 || (ototc6-itotc6)>0 || (ototi6-itoti6)>0 || (ototv6-itotv6)>0?
        <TableRow className='rext-right'>
        <TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell>
        <TableCell className='border-0 text-right font-weight-bold text-blue'>
        <span onClick={() =>this.onSelect(moment(financialyearstart).add(5,'M').format('M'),moment(financialyearstart).add(5,'M').format('MMMM-yy'))}>Details</span>
        </TableCell>
        </TableRow>:""} 
        </TableBody>
        </Table>
         </TableCell>                                                                           
    </TableRow></Table> </TableCell> 
    {/* :""} */}
                            </TableRow>
                        </TableBody>
     
<TableHead className="thead-light thead-bordered mt-0 mb-0" >
                            <TableRow className="tr-bordered mt-0 mb-4">
                            {/* {moment(financialyearstart).add(6,'M').format('yy-M')<=date?                                 */}
                                 <TableCell className='text-center'>{moment(financialyearstart).add(6,'M').format('MMMM-yy')}</TableCell>
                                 {/* :""} */}
                            {/* {moment(financialyearstart).add(7,'M').format('yy-M')<=date?                                 */}
                                <TableCell className='text-center'>{moment(financialyearstart).add(7,'M').format('MMMM-yy')}</TableCell>
                                {/* :""} */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
   {/* ------------------------------------7 th Month -----------------------------------------------                                      */}
   {/* {moment(financialyearstart).add(6,'M').format('yy-M')<=date?                                 */}
   <TableCell className='text-center'>
<Table><TableHead><TableRow>
<TableCell className='text-center'>Input</TableCell> <TableCell className='text-center'>Output</TableCell>
 </TableRow> </TableHead>
 <TableRow>

     <TableCell className='text-center'>

     <Table>
    <TableHead>
     {ccountry==="India"?
      <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
       <TableCell className='text-center'>IGST</TableCell></TableRow>:
       <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
       <TableBody>
       {imon7.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.itemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="st4" className="align-right text-sm" value={e.statetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ct4" className="align-right text-sm" value={e.centraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="it4" className="align-right text-sm" value={e.integratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="v4" className="align-right text-sm" value={e.vatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="stt4" className="align-right text-sm" value={itots7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotc7}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itoti7}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotv7}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pi ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Previous Balance</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={sbalmon6!==0?sbalmon6:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={cbalmon6!==0?cbalmon6:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ibalmon6!==0?ibalmon6:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={vbalmon6!==0?vbalmon6:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pi end----------------- */}

         {/* ---------------Final Total ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
       </TableBody>
       </Table>
       </TableCell>

       <TableCell className='text-center'>
        <Table>
        <TableHead>
        {ccountry==="India"?
        <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
        <TableCell className='text-center'>IGST</TableCell></TableRow>:
        <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
        <TableBody>
        {omon7.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.outitemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="ost4" className="align-right text-sm" value={e.outstatetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="oct4" className="align-right text-sm" value={e.outcentraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="oit4" className="align-right text-sm" value={e.outintegratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="ov4" className="align-right text-sm" value={e.outvatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ostt4" className="align-right text-sm" value={otots7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="octt4" className="align-right text-sm" value={ototc7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="oitt4" className="align-right text-sm" value={ototi7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ovtt4" className="align-right text-sm" value={ototv7}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pb ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Tax Dues</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={sbalmon6<0?sbalmon6:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={cbalmon6<0?cbalmon6:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ibalmon6<0?ibalmon6:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={vbalmon6<0?vbalmon6:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pb end----------------- */}
 {/* ---------------Final Total ----------------- */}
 {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintots7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotc7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintoti7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotv7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
        {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Balance</TableCell>
        <TableCell className='text-right'>
        {otots7-ifintots7>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={otots7-ifintots7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots7-otots7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell> 
        <TableCell className='text-right'>
        {ototc7-ifintotc7>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototc7-ifintotc7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc7-ototc7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         
        <TableCell className='text-right'>
        {ototi7-ifintoti7>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototi7-ifintoti7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti7-ototi7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        {ototv7-ifintotv7>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototv7-ifintotv7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv7-ototv7} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>        
        </TableRow>}
        {(otots7-itots7)>0 || (ototc7-itotc7)>0 || (ototi7-itoti7)>0 ||( ototv7-itotv7)>0?
        <TableRow className='rext-right'>
        <TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell>
        <TableCell className='border-0 text-right font-weight-bold text-blue'>
        <span onClick={() =>this.onSelect(moment(financialyearstart).add(6,'M').format('M'),moment(financialyearstart).add(6,'M').format('MMMM-yy'))}>Details</span>
        </TableCell>
        </TableRow>:""} 
        </TableBody>
        </Table>
         </TableCell>                                                                           
    </TableRow></Table> </TableCell> 
     {/* :""} */}
 {/* ------------------------------------8 Th Month -----------------------------------------------                                      */}
 {/* {moment(financialyearstart).add(7,'M').format('yy-M')<=date?                                 */}
 <TableCell className='text-center'>
<Table><TableHead><TableRow>
<TableCell className='text-center'>Input</TableCell> <TableCell className='text-center'>Output</TableCell>
 </TableRow> </TableHead>
 <TableRow>

     <TableCell className='text-center'>

     <Table>
    <TableHead>
     {ccountry==="India"?
      <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
       <TableCell className='text-center'>IGST</TableCell></TableRow>:
       <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
       <TableBody>
       {imon8.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.itemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="st4" className="align-right text-sm" value={e.statetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ct4" className="align-right text-sm" value={e.centraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="it4" className="align-right text-sm" value={e.integratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="v4" className="align-right text-sm" value={e.vatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="stt4" className="align-right text-sm" value={itots8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotc8}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itoti8}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotv8}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pi ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Previous Balance</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={sbalmon7!==0?sbalmon7:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={cbalmon7!==0?cbalmon7:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ibalmon7!==0?ibalmon7:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={vbalmon7!==0?vbalmon7:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pi end----------------- */}

         {/* ---------------Final Total ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
       </TableBody>
       </Table>
       </TableCell>

       <TableCell className='text-center'>
        <Table>
        <TableHead>
        {ccountry==="India"?
        <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
        <TableCell className='text-center'>IGST</TableCell></TableRow>:
        <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
        <TableBody>
        {omon8.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.outitemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="ost4" className="align-right text-sm" value={e.outstatetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="oct4" className="align-right text-sm" value={e.outcentraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="oit4" className="align-right text-sm" value={e.outintegratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="ov4" className="align-right text-sm" value={e.outvatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ostt4" className="align-right text-sm" value={otots8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="octt4" className="align-right text-sm" value={ototc8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="oitt4" className="align-right text-sm" value={ototi8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ovtt4" className="align-right text-sm" value={ototv8}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pb ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Tax Dues</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={sbalmon7<0?sbalmon7:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={cbalmon7<0?cbalmon7:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ibalmon7<0?ibalmon7:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={vbalmon7<0?vbalmon7:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pb end----------------- */}
 {/* ---------------Final Total ----------------- */}
 {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintots8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotc8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintoti8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotv8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
        {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Balance</TableCell>
        <TableCell className='text-right'>
        {otots8-ifintots8>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={otots8-ifintots8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots8-otots8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell> 
        <TableCell className='text-right'>
        {ototc8-ifintotc8>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototc8-ifintotc8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc8-ototc8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         
        <TableCell className='text-right'>
        {ototi8-ifintoti8>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototi8-ifintoti8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti8-ototi8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        {ototv8-ifintotv8>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototv8-ifintotv8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv8-ototv8} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>        
        </TableRow>}
        {(otots8-itots8)>0 || (ototc8-itotc8)>0 || (ototi8-itoti8)>0 || (ototv8-itotv8)>0?
        <TableRow className='rext-right'>
        <TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell>
        <TableCell className='border-0 text-right font-weight-bold text-blue'>
        <span onClick={() =>this.onSelect(moment(financialyearstart).add(7,'M').format('M'),moment(financialyearstart).add(7,'M').format('MMMM-yy'))}>Details</span>
        </TableCell>
        </TableRow>:""} 
        </TableBody>
        </Table>
         </TableCell>                                                                           
    </TableRow></Table> </TableCell> 
     {/* :""} */}
</TableRow></TableBody>

    <TableHead className="thead-light thead-bordered mt-0 mb-0" >
                            <TableRow className="tr-bordered mt-0 mb-4">
                            {/* {moment(financialyearstart).add(8,'M').format('yy-M')<=date?                                 */}
                            <TableCell className='text-center'>{moment(financialyearstart).add(8,'M').format('MMMM-yy')}</TableCell>
                            {/* :""} */}
                            {/* {moment(financialyearstart).add(9,'M').format('yy-M')<=date?                                 */}
                             <TableCell className='text-center'>{moment(financialyearstart).add(9,'M').format('MMMM-yy')}</TableCell>
                             {/* :""}         */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
      {/* ------------------------------------9 Th Month -----------------------------------------------                                      */}
      {/* {moment(financialyearstart).add(8,'M').format('yy-M')<=date?                                 */}
      <TableCell className='text-center'>
<Table><TableHead><TableRow>
<TableCell className='text-center'>Input</TableCell> <TableCell className='text-center'>Output</TableCell>
 </TableRow> </TableHead>
 <TableRow>

     <TableCell className='text-center'>

     <Table>
    <TableHead>
     {ccountry==="India"?
      <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
       <TableCell className='text-center'>IGST</TableCell></TableRow>:
       <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
       <TableBody>
       {imon9.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.itemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="st4" className="align-right text-sm" value={e.statetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ct4" className="align-right text-sm" value={e.centraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="it4" className="align-right text-sm" value={e.integratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="v4" className="align-right text-sm" value={e.vatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="stt4" className="align-right text-sm" value={itots9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotc9}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itoti9}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotv9}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pi ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Previous Balance</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={sbalmon8!==0?sbalmon8:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={cbalmon8!==0?cbalmon8:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ibalmon8!==0?ibalmon8:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={vbalmon8!==0?vbalmon8:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pi end----------------- */}

         {/* ---------------Final Total ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
       </TableBody>
       </Table>
       </TableCell>

       <TableCell className='text-center'>
        <Table>
        <TableHead>
        {ccountry==="India"?
        <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
        <TableCell className='text-center'>IGST</TableCell></TableRow>:
        <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
        <TableBody>
        {omon9.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.outitemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="ost4" className="align-right text-sm" value={e.outstatetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="oct4" className="align-right text-sm" value={e.outcentraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="oit4" className="align-right text-sm" value={e.outintegratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="ov4" className="align-right text-sm" value={e.outvatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ostt4" className="align-right text-sm" value={otots9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="octt4" className="align-right text-sm" value={ototc9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="oitt4" className="align-right text-sm" value={ototi9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ovtt4" className="align-right text-sm" value={ototv9}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pb ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Tax Dues</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={sbalmon8<0?sbalmon8:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={cbalmon8<0?cbalmon8:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ibalmon8<0?ibalmon8:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={vbalmon8<0?vbalmon8:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pb end----------------- */}
 {/* ---------------Final Total ----------------- */}
 {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintots9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotc9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintoti9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotv9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
        {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Balance</TableCell>
        <TableCell className='text-right'>
        {otots9-ifintots9>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={otots9-ifintots9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots9-otots9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell> 
        <TableCell className='text-right'>
        {ototc9-ifintotc9>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototc9-ifintotc9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc9-ototc9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         
        <TableCell className='text-right'>
        {ototi9-ifintoti9>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototi9-ifintoti9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti9-ototi9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        {ototv9-ifintotv9>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototv9-ifintotv9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv9-ototv9} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>        
        </TableRow>}
        {(otots9-itots9)>0 || (ototc9-itotc9)>0 || (ototi9-itoti9)>0 || (ototv9-itotv9)>0?
        <TableRow className='rext-right'>
        <TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell>
        <TableCell className='border-0 text-right font-weight-bold text-blue'>
        <span onClick={() =>this.onSelect(moment(financialyearstart).add(8,'M').format('M'),moment(financialyearstart).add(8,'M').format('MMMM-yy'))}>Details</span>
        </TableCell>
        </TableRow>:""} 
        </TableBody>
        </Table>
         </TableCell>                                                                           
    </TableRow></Table> </TableCell> 
     {/* :""} */}
 {/* ------------------------------------10 th Month -----------------------------------------------                                      */}
 {/* {moment(financialyearstart).add(9,'M').format('yy-M')<=date?                                 */}
 <TableCell className='text-center'>
<Table><TableHead><TableRow>
<TableCell className='text-center'>Input</TableCell> <TableCell className='text-center'>Output</TableCell>
 </TableRow> </TableHead>
 <TableRow>

     <TableCell className='text-center'>

     <Table>
    <TableHead>
     {ccountry==="India"?
      <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
       <TableCell className='text-center'>IGST</TableCell></TableRow>:
       <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
       <TableBody>
       {imon10.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.itemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="st4" className="align-right text-sm" value={e.statetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ct4" className="align-right text-sm" value={e.centraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="it4" className="align-right text-sm" value={e.integratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="v4" className="align-right text-sm" value={e.vatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="stt4" className="align-right text-sm" value={itots10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotc10}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itoti10}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotv10}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pi ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Previous Balance</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={sbalmon9!==0?sbalmon9:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={cbalmon9!==0?cbalmon9:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ibalmon9!==0?ibalmon9:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={vbalmon9!==0?vbalmon9:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pi end----------------- */}

         {/* ---------------Final Total ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
       </TableBody>
       </Table>
       </TableCell>

       <TableCell className='text-center'>
        <Table>
        <TableHead>
        {ccountry==="India"?
        <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
        <TableCell className='text-center'>IGST</TableCell></TableRow>:
        <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
        <TableBody>
        {omon10.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.outitemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="ost4" className="align-right text-sm" value={e.outstatetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="oct4" className="align-right text-sm" value={e.outcentraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="oit4" className="align-right text-sm" value={e.outintegratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="ov4" className="align-right text-sm" value={e.outvatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ostt4" className="align-right text-sm" value={otots10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="octt4" className="align-right text-sm" value={ototc10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="oitt4" className="align-right text-sm" value={ototi10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ovtt4" className="align-right text-sm" value={ototv10}displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pb ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Tax Dues</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={sbalmon9<0?sbalmon9:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={cbalmon9<0?cbalmon9:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ibalmon9<0?ibalmon9:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={vbalmon9<0?vbalmon9:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pb end----------------- */}
 {/* ---------------Final Total ----------------- */}
 {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintots10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotc10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintoti10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotv10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
        {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Balance</TableCell>
        <TableCell className='text-right'>
        {otots10-ifintots10>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={otots10-ifintots10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots10-otots10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell> 
        <TableCell className='text-right'>
        {ototc10-ifintotc10>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototc10-ifintotc10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc10-ototc10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         
        <TableCell className='text-right'>
        {ototi10-ifintoti10>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototi10-ifintoti10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti10-ototi10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        {ototv10-ifintotv10>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototv10-ifintotv10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv10-ototv10} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>        
        </TableRow>}
        {(otots10-itots10)>0 || (ototc10-itotc10)>0 || (ototi10-itoti10)>0 || (ototv10-itotv10)>0?
        <TableRow className='rext-right'>
        <TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell>
        <TableCell className='border-0 text-right font-weight-bold text-blue'>
        <span onClick={() =>this.onSelect(moment(financialyearstart).add(9,'M').format('M'),moment(financialyearstart).add(9,'M').format('MMMM-yy'))}>Details</span>
        </TableCell>
        </TableRow>:""} 
        </TableBody>
        </Table>
         </TableCell>                                                                           
    </TableRow></Table> </TableCell> 
    {/* :""} */}
                            </TableRow>
                        </TableBody>
                        <TableHead className="thead-light thead-bordered mt-0 mb-0" >
                            <TableRow className="tr-bordered mt-0 mb-4"> 
                            {/* {moment(financialyearstart).add(10,'M').format('yy-M')<=date?                                 */}
                                <TableCell className='text-center'>{moment(financialyearstart).add(10,'M').format('MMMM-yy')}</TableCell>
                                {/* :""} */}
                            {/* {moment(financialyearstart).add(10,'M').format('yy-M')<=date?                                 */}
                                <TableCell className='text-center'>{moment(financialyearstart).add(11,'M').format('MMMM-yy')}</TableCell>
                                {/* :""} */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
 {/* ------------------------------------11 Th Month -----------------------------------------------                                      */}
 {/* {moment(financialyearstart).add(10,'M').format('yy-M')<=date?                                 */}
 <TableCell className='text-center'>
<Table><TableHead><TableRow>
<TableCell className='text-center'>Input</TableCell> <TableCell className='text-center'>Output</TableCell>
 </TableRow> </TableHead>
 <TableRow>

     <TableCell className='text-center'>

     <Table>
    <TableHead>
     {ccountry==="India"?
      <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
       <TableCell className='text-center'>IGST</TableCell></TableRow>:
       <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
       <TableBody>
       {imon11.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.itemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="st4" className="align-right text-sm" value={e.statetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ct4" className="align-right text-sm" value={e.centraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="it4" className="align-right text-sm" value={e.integratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="v4" className="align-right text-sm" value={e.vatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="stt4" className="align-right text-sm" value={itots11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotc11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itoti11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotv11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pi ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Previous Balance</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={sbalmon10!==0?sbalmon10:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={cbalmon10!==0?cbalmon10:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ibalmon10!==0?ibalmon10:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={vbalmon10!==0?vbalmon10:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pi end----------------- */}

         {/* ---------------Final Total ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
       </TableBody>
       </Table>
       </TableCell>

       <TableCell className='text-center'>
        <Table>
        <TableHead>
        {ccountry==="India"?
        <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
        <TableCell className='text-center'>IGST</TableCell></TableRow>:
        <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
        <TableBody>
        {omon11.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.outitemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="ost4" className="align-right text-sm" value={e.outstatetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="oct4" className="align-right text-sm" value={e.outcentraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="oit4" className="align-right text-sm" value={e.outintegratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="ov4" className="align-right text-sm" value={e.outvatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ostt4" className="align-right text-sm" value={otots11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="octt4" className="align-right text-sm" value={ototc11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="oitt4" className="align-right text-sm" value={ototi11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ovtt4" className="align-right text-sm" value={ototv11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pb ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Tax Dues</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={sbalmon10<0?sbalmon10:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={cbalmon10<0?cbalmon10:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ibalmon10<0?ibalmon10:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={vbalmon10<0?vbalmon10:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pb end----------------- */}
 {/* ---------------Final Total ----------------- */}
 {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintots11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotc11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintoti11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotv11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
        {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Balance</TableCell>
        <TableCell className='text-right'>
        {otots11-ifintots11>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={otots11-ifintots11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots11-otots11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell> 
        <TableCell className='text-right'>
        {ototc11-ifintotc11>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototc11-ifintotc11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc11-ototc11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         
        <TableCell className='text-right'>
        {ototi11-ifintoti11>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototi11-ifintoti11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti11-ototi11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        {ototv11-ifintotv11>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototv11-ifintotv11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv11-ototv11} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>        
        </TableRow>}
        {(otots11-itots11)>0 || (ototc11-itotc11)>0 || (ototi11-itoti11)>0 || (ototv11-itotv11)>0?
        <TableRow className='rext-right'>
        <TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell>
        <TableCell className='border-0 text-right font-weight-bold text-blue'>
        <span onClick={() =>this.onSelect(moment(financialyearstart).add(10,'M').format('M'),moment(financialyearstart).add(10,'M').format('MMMM-yy'))}>Details</span>
        </TableCell>
        </TableRow>:""} 
        </TableBody>
        </Table>
         </TableCell>                                                                           
    </TableRow></Table> </TableCell> 
    {/* :""} */}
      {/* ------------------------------------12 Th Month -----------------------------------------------                                      */}
      {/* {moment(financialyearstart).add(11,'M').format('yy-M')<=date?                                 */}
      <TableCell className='text-center'>
<Table><TableHead><TableRow>
<TableCell className='text-center'>Input</TableCell> <TableCell className='text-center'>Output</TableCell>
 </TableRow> </TableHead>
 <TableRow>

     <TableCell className='text-center'>

     <Table>
    <TableHead>
     {ccountry==="India"?
      <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
       <TableCell className='text-center'>IGST</TableCell></TableRow>:
       <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
       <TableBody>
       {imon12.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.itemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="st4" className="align-right text-sm" value={e.statetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ct4" className="align-right text-sm" value={e.centraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="it4" className="align-right text-sm" value={e.integratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="v4" className="align-right text-sm" value={e.vatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="stt4" className="align-right text-sm" value={itots12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotc12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itoti12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ctt4" className="align-right text-sm" value={itotv12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pi ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Previous Balance</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={sbalmon11!==0?sbalmon11:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={cbalmon11!==0?cbalmon11:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ibalmon11!==0?ibalmon11:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={vbalmon11!==0?vbalmon11:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pi end----------------- */}

         {/* ---------------Final Total ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
       </TableBody>
       </Table>
       </TableCell>

       <TableCell className='text-center'>
        <Table>
        <TableHead>
        {ccountry==="India"?
        <TableRow><TableCell className='text-center'>Items</TableCell><TableCell className='text-center'>SGST</TableCell> <TableCell className='text-center'>CGST</TableCell>
        <TableCell className='text-center'>IGST</TableCell></TableRow>:
        <TableRow><TableCell className='text-center'>VAT</TableCell></TableRow>} </TableHead>
        <TableBody>
        {omon12.map((e)=>(
        ccountry==="India"?
     <TableRow>          
        <TableCell className='text-left'>{e.outitemname}</TableCell>
       <TableCell className='text-right'>
        <NumericFormat name="ost4" className="align-right text-sm" value={e.outstatetaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="oct4" className="align-right text-sm" value={e.outcentraltaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
      <TableCell className='text-center'>
      <NumericFormat name="oit4" className="align-right text-sm" value={e.outintegratedtaxamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
      </TableCell>  </TableRow>:
       <TableRow><TableCell className='text-center'>
        <NumericFormat name="ov4" className="align-right text-sm" value={e.outvatamount} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
       </TableCell> </TableRow>))}
        {/* ---------------totals----------------- */}
        {ccountry==="India"?
        <TableRow>          
        <TableCell className='text-left font-weight-bold'>Total</TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ostt4" className="align-right text-sm" value={otots12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="octt4" className="align-right text-sm" value={ototc12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>
        <TableCell className='text-right font-weight-bold'>
        <NumericFormat name="oitt4" className="align-right text-sm" value={ototi12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell>  
        </TableRow>:
        <TableRow><TableCell className='text-right font-weight-bold'>
        <NumericFormat name="ovtt4" className="align-right text-sm" value={ototv12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> </TableRow>}
        {/* ---------------totals end----------------- */}
         {/* ---------------Pb ----------------- */}
         {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Tax Dues</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={sbalmon11<0?sbalmon11:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={cbalmon11<0?cbalmon11:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ibalmon11<0?ibalmon11:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={vbalmon11<0?vbalmon11:0} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Pb end----------------- */}
 {/* ---------------Final Total ----------------- */}
 {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Net Total</TableCell>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintots12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        </TableCell> 
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotc12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintoti12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ofintotv12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat> 
        </TableCell>        
        </TableRow>}

        {/* ---------------Final Total end----------------- */}
        {ccountry==="India"?
        <TableRow><TableCell className='text-left font-weight-bold text-orange'>Balance</TableCell>
        <TableCell className='text-right'>
        {otots12-ifintots12>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={otots12-ifintots12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintots12-otots12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell> 
        <TableCell className='text-right'>
        {ototc12-ifintotc12>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototc12-ifintotc12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotc12-ototc12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         
        <TableCell className='text-right'>
        {ototi12-ifintoti12>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototi12-ifintoti12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintoti12-ototi12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>         </TableRow>:
        <TableRow>
        <TableCell className='text-right'>
        {ototv12-ifintotv12>0? 
        <NumericFormat name="ostt4" className="align-right text-sm text-red" 
        value={ototv12-ifintotv12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
        :
        <NumericFormat name="ostt4" className="align-right text-sm text-green" 
        value={ifintotv12-ototv12} displayType={"text"} customInput={TextField} 
        decimalScale={2} fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>} 
        </TableCell>        
        </TableRow>}
        {(otots12-itots12)>0 || (ototc12-itotc12)>0 || (ototi12-itoti12)>0 || (ototv12-itotv12)>0?
        <TableRow className='rext-right'>
        <TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell><TableCell className='border-0'></TableCell>
        <TableCell className='border-0 text-right font-weight-bold text-blue'>
        <span onClick={() =>this.onSelect(moment(financialyearstart).add(11,'M').format('M'),moment(financialyearstart).add(11,'M').format('MMMM-yy'))}>Details</span>
        </TableCell>
        </TableRow>:""} 
        </TableBody>
        </Table>
         </TableCell>                                                                           
    </TableRow></Table> </TableCell> 
    {/* :""}  */}
                            </TableRow>
                        </TableBody>
                    </Table>
              </TableContainer>
              </Form>)} 
 </Formik>
                </div>
            </div>
        </div>
        <Footer/>
      </div>
    )
  }
}
export default withRouter(IndirectTaxes);              
