import {
  Backdrop,
  CircularProgress,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import { currentTenant, thousandsdisplay, userid } from "../Common/Credentials";
import { spin } from "../Common/NewLoader";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import ProductionServices from "../Production/ProductionServices";
import {NumericFormat} from "react-number-format";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import InventoryService from "../inventory/InventoryService";
import PosService from "../Sales/PointofSales/PosService"

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class LossesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inventorylosses:[],
      storelosses: [],
      poslosses:[],
      loader:true,
      loading:false
    };
  }

  refresh(){
    InventoryService.getDamageDataForAcc(currentTenant).then((response) => {
      this.setState({ inventorylosses: response.data.sort((a, b) => a.date < b.date ? 1 : -1) });
    })
    ProductionServices.getallProductionstorelossesForAccounts(currentTenant).then(
      (response) => this.setState({ storelosses: response.data})
    )
    PosService.GetPosLossesForAcc(currentTenant).then(response=>
      this.setState({poslosses:response.data},()=>this.setState({loader:false}))
    )
  }

  submitRecord(value){
    let le

    if(value!==undefined){
    le=this.state.inventorylosses.filter(e=>e.id===value.rowData[0])
    } 
    this.props.navigate({pathname:`/lossesentry`}, {state:{le:le}});
  }
  submitPosRecord(value){
    let lepos

    if(value!==undefined){
    lepos=this.state.poslosses.filter(e=>e.id===value.rowData[0])
    } 
    this.props.navigate({pathname:`/lossesentry`}, {state:{lepos:lepos}});
  }
  submitPSRecord(value){
    let leps

    if(value!==undefined){
      leps=this.state.storelosses.filter(e=>e.id===value.rowData[0])
    } 
    this.props.navigate({pathname:`/lossesentry`}, {state:{leps:leps}});
  }
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }else{
    // this.setState({loading:true})
   this.refresh()
    } 

  }

  render() {

    const Invcolumns = [
      {name: "id",label: "Item id",options: {filter: false,sort: false,display:false,viewColumns:false}},
      {name: "itemname",label: "Item name",options: {filter: false,sort: true,}},
      {name: "category",label: "Category",options: {filter: true,sort: true,}},
      {name: "whname",label:"Warehouse",options: {filter: false,sort: true,}},
      {name: "batchid",label: "Batch",options: {filter: false,sort: true,}},
      {name: "quantity",label: "Quantity",options: {filter: false,sort: true,}},
      {name: "price",label: "Unit Price",options: {filter: false,sort: true,sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      {name: "totalloss",label: "Total Loss",options: {filter: false,sort: true,sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      {name: "comment",label: "Comment",options: {filter: true,sort: true,}},
      {
        name: "Action ",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <button as="button" className="hovbuttonColour btn btn-sm" onClick={() => this.submitRecord(tableMeta)}>
                Entry
              </button>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
     ];
     
     const Invoptions = {
       filterType: 'checkbox',
       selectableRows: "none",
     }

    const Poslossescolumns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumn: false,
        },
      },
      {
        name: "itemname",
        label: "Item Name",
        options: { filter: true, sort: true },
      },
      {
        name: "price",
        label: "Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "quantity",
        label: "Loss Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "totalloss",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "comments",
        label: "Comments",
        options: { filter: true, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return moment(value).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      },
      {
        name: "warehouse",
        label: "Org Unit",
        options: { filter: true, sort: true },
      },
      {
        name: "dept",
        label: "Department/Store",
        options: { filter: true, sort: true },
      },

      {
        name: "Action ",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <button as="button" className="hovbuttonColour btn btn-sm" onClick={() => this.submitPosRecord(tableMeta)}>
                Select
              </button>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
    ];

    
    const Poslossesoptions = {
      filterType: "checkbox",
      selectableRows: "none",
    };

    const ProStrcolumns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumn: false,
        },
      },
      {
        name: "itemname",
        label: "Item Name",
        options: { filter: true, sort: true },
      },
      {
        name: "price",
        label: "Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "quantity",
        label: "Loss Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "totalloss",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "comments",
        label: "Comments",
        options: { filter: true, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return moment(value).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      },
      {
        name: "Action ",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <button as="button" className="hovbuttonColour btn btn-sm" onClick={() => this.submitPSRecord(tableMeta)}>
                Select
              </button>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
    ];

     const ProStroptions = {
      filterType: "checkbox",
      selectableRows: "none",
    };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
          <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item active">Losses Entry</li>
            </ol>
            <div className="form-row">
              <div className="card-body">
                <div>
                  {this.state.loading === true ? (
                    <div className="text-center">{spin}</div>
                  ) : (
                    <>
                      <div className="mb-5">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`Inventory Losses`}
                            data={this.state.inventorylosses}
                            columns={Invcolumns}
                            options={Invoptions}
                          />
                        </ThemeProvider>
                      </div>
                      <div className="mb-5">
                      <ThemeProvider theme={theme}>
                        <MUIDataTable
                          className="table-sm px-3"
                          title={`Production Store Losses`}
                          data={this.state.storelosses}
                          columns={ProStrcolumns}
                          options={ProStroptions}
                        />
                      </ThemeProvider>
                      </div>
                      <div className="mb-5">
                      <ThemeProvider theme={theme}>
                        <MUIDataTable
                          className="table-sm px-3"
                          title={`Point of Sale Losses`}
                          data={this.state.poslosses}
                          columns={Poslossescolumns}
                          options={Poslossesoptions}
                        />
                      </ThemeProvider>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(LossesList);
