import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {NumericFormat} from "react-number-format";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "date-fns";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";import { Button } from "react-bootstrap";
import { TextField, ThemeProvider } from "@mui/material";
import {
  createTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ProductionServices from "../ProductionServices";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
class Productionreport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportsdata: [],
      filterdata: [],
      impreportsdata: [],
      impfilterdata: [],
      filterbydate: [],
      filterOptions: [
        { id: 0, name: "Production" },
        { id: 1, name: "Full Process" },
        { id: 2, name: "Immediate Process" },
      ],
      overall: true,
      loading: false,
      selected: 0,
      fromdate: moment().subtract(7,"days"),
      todate: new Date(),
      reportsfiltereddata:[],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.clearfilter = this.clearfilter.bind(this);
  }

  onChange(option) {
    this.setState({ selected: option.id });
  }

  handleFromDateChange = (date) => {
    this.setState({ fromdate: date });
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  clearfilter() {
    this.setState({
      fromdate: moment().subtract(7,"days"),
      todate: new Date(),
      filterdata: this.state.reportsdata.filter(
        (e) =>
          e.type === "Full Process" &&
          moment(e.createdAt).format("YYYY-MM-DD") >=
            moment(this.state.fromdate).format("YYYY-MM-DD") &&
          moment(e.createdAt).format("YYYY-MM-DD") <=
            moment(this.state.todate).format("YYYY-MM-DD")
      ),

      impfilterdata: this.state.reportsdata.filter(
        (e) =>
          e.type === "Immediate Process" &&
          moment(e.createdAt).format("YYYY-MM-DD") >=
            moment(this.state.fromdate).format("YYYY-MM-DD") &&
          moment(e.createdAt).format("YYYY-MM-DD") <=
            moment(this.state.todate).format("YYYY-MM-DD")
      ),

      reportsfiltereddata: this.state.reportsdata.filter(
        (e) =>
          moment(e.createdAt).format("YYYY-MM-DD") >=
            moment(this.state.fromdate).format("YYYY-MM-DD") &&
          moment(e.createdAt).format("YYYY-MM-DD") <=
            moment(this.state.todate).format("YYYY-MM-DD")
      ),
    },()=>this.FilterdataBydate());
  }

  onSubmit() {
    let fdata = this.state.reportsdata.filter(
      (e) => e.type === "Full Process" &&
        moment(e.createdAt).format("YYYY-MM-DD") >=
          moment(this.state.fromdate).format("YYYY-MM-DD") &&
        moment(e.createdAt).format("YYYY-MM-DD") <=
          moment(this.state.todate).format("YYYY-MM-DD")
    );

    let impdata = this.state.reportsdata.filter(
        (e) =>e.type === "Immediate Process" &&
          moment(e.createdAt).format("YYYY-MM-DD") >=
            moment(this.state.fromdate).format("YYYY-MM-DD") &&
          moment(e.createdAt).format("YYYY-MM-DD") <=
            moment(this.state.todate).format("YYYY-MM-DD")  
      );
      let data = this.state.reportsdata.filter(
        (e) =>
          moment(e.createdAt).format("YYYY-MM-DD") >=
            moment(this.state.fromdate).format("YYYY-MM-DD") &&
          moment(e.createdAt).format("YYYY-MM-DD") <=
            moment(this.state.todate).format("YYYY-MM-DD")  
      );

    this.setState({ filterdata: fdata,impfilterdata:impdata,reportsfiltereddata:data },()=>this.FilterdataBydate());
  }


  FilterdataBydate = () => {
      let data2 = this.state.reportsfiltereddata
      data2.filter((e) =>(data2.find((a) => a.createdAt === e.createdAt).createdAt = moment(e.createdAt).format("YYYY-MM-DD")))
    const data = data2.reduce(
      (date, { itemname, itemid, quantity, uom, type, createdAt,itemtype,price }) => {
        if (!date[createdAt]) date[createdAt] = [];
        date[createdAt].push({
          itemid: itemid,
          itemname: itemname,
          quantity: quantity,
          createdAt: createdAt,
          uom: uom,
          type: type,
          itemtype:itemtype,
          price:price,
        });
        return date;
      },
      {}
    );
    let fdata = Object.entries(data).reverse()
    console.log(fdata)
    this.setState({ filterbydate:fdata })
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    ProductionServices.ProductionReports(currentTenant)
      .then((response) => {
        
        this.setState({
            
            filterdata: response.data.filter(
              (e) =>
                e.type === "Full Process" &&
                (moment(e.createdAt).format("YYYY-MM-DD") >=
                  moment(this.state.fromdate).format("YYYY-MM-DD") &&
                moment(e.createdAt).format("YYYY-MM-DD") <=
                  moment(this.state.todate).format("YYYY-MM-DD"))
            ),
            impfilterdata: response.data.filter(
              (e) =>
                e.type === "Immediate Process" &&
                (moment(e.createdAt).format("YYYY-MM-DD") >=
                  moment(this.state.fromdate).format("YYYY-MM-DD") &&
                moment(e.createdAt).format("YYYY-MM-DD") <=
                  moment(this.state.todate).format("YYYY-MM-DD"))
            ),
            reportsdata: response.data,
            reportsfiltereddata:response.data,
            loading: false,
          },
          () => {
            this.FilterdataBydate();
          }
        );
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

  validate(values) {
    let errors = {};
    if (values.from <= values.to && values.to === false) {
      alert("To date must be greater than From date");
      errors.to = "To date must be greater than From date";
    }
    if (values.from === null) {
      alert("Please Select From date");
      errors.from = "Please Select From date";
    }
    if (!values.to === null) {
      alert("Please Select to date");
      errors.to = "Please Select to date";
    }
    return errors;
  }
  render() {
    const columns = [
      {
        name: "itemname",
        label: "Item name",
        options: { filter: true, sort: true, sortOrder: "asc" },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: false, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY hh:mm");
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>
              <li className="breadcrumb-item active">Production Report</li>
            </ol>
            <div className="card-body">
              <div>
                {this.state.loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <Formik
                    initialValues={{
                      from: this.state.fromdate,
                      to: this.state.todate,
                    }}
                    onSubmit={this.onSubmit}
                    validateOnChange={false}
                    validate={this.validate}
                    enableReinitialize={true}
                  >
                    {({ setFieldValue, values }) => (
                      <Form autoComplete="off">
                        <div className="form-row g-0">
                          <fieldset>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justifyContent="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="From Date"
                                  name="from"
                                  format="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  value={this.state.fromdate}
                                  onChange={this.handleFromDateChange}
                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                  autoOk={true}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>

                          <fieldset style={{ marginLeft: "30px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justifyContent="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="To Date"
                                  name="to"
                                  format="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  value={this.state.todate}
                                  onChange={this.handleToDateChange}
                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                  autoOk={true}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>
                          <fieldset className="form-group col-md-1">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3"
                              type="submit"
                            >
                              <i className="fas fa-filter" />
                            </button>
                          </fieldset>
                          {this.state.todate !== null ? (
                            <fieldset className="form-group col-md-2">
                              <Button
                                className="btn btn-sm bg-white shadow-sm mt-3"
                                onClick={this.clearfilter}
                              >
                                {" "}
                                <b style={{ color: "#FA5733" }}> X</b> Clear
                                Filter
                              </Button>
                            </fieldset>
                          ) : (
                            ""
                          )}
                          <div className="form-group col-md-12 text-sm" />

                          {this.state.filterOptions.map((option,idx) => {
                            return (
                              <label key={idx}>
                                <div className="form-group col-md-9 text-sm">
                                  <div className="btn-group">
                                    <input
                                      className={
                                        this.state.selected === option.id
                                          ? "btn hovborderbuttonColour"
                                          : "btn hovbuttonColour"
                                      }
                                      type="button"
                                      key={option.id}
                                      onClick={this.onChange.bind(this, option)}
                                      style={{ marginRight: 8, width: 180 }}
                                      value={option.name}
                                    />
                                  </div>
                                </div>
                              </label>
                            );
                          })}
                        </div>

                        {this.state.selected === 0
                          ? 
                          // Object.entries(this.state.filterbydate).map(
                          //     ([key, value]) => (
                            this.state.filterbydate.map((value,idx)=>(
                                <div className="form-row" key={idx}>
                                  <div className="col-md-12">
                                   <Typography className=" text-center textColour pt-2 pb-2" style={{backgroundColor:"rgb(189,199,238)"}}><b> { moment(value[0]).format('dddd')},&nbsp;{ moment(value[0]).format('LL')}</b></Typography></div>
                                  <div className="col-md-6">
                                    <Typography  className="text-center pt-2 pb-2"><b>Finished Products</b></Typography>
                                <TableContainer className="mb-3">
                                  <Table style={{border:"1px solid lightgrey"}}>
                                    <TableHead>
                                      <TableRow >
                                        <TableCell className="text-center">Item</TableCell>
                                        <TableCell className="text-center">Quantity</TableCell>
                                        <TableCell className="text-center">UOM</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {value[1].map((e,idx) => (
                                        e.itemtype ==="Finished"?
                                        <TableRow key={idx}>
                                          <TableCell className="text-center">{e.itemname}</TableCell>
                                          <TableCell className="text-center">{e.quantity}</TableCell>
                                          <TableCell className="text-center">{e.uom}</TableCell>
                                        </TableRow>
                                        :''
                                      ))}
                                      {value[1].filter(e=>e.itemtype === "Finished").length === 0 ?
                                    <TableRow>
                                      <TableCell className="text-center" colspan="12">No data avilable</TableCell>
                                    </TableRow>  :''
                                    }
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                </div>
                                <div className="col-md-6">
                                <TableContainer className="mb-3">
                                <Typography  className="text-center pt-2 pb-2"><b>Raw Materials</b></Typography>
                                  <Table style={{border:"1px solid lightgrey"}}>
                                    <TableHead>
                                      <TableRow >
                                        <TableCell className="text-center">Item</TableCell>
                                        <TableCell className="text-center">Quantity</TableCell>
                                        <TableCell className="text-center">UOM</TableCell>
                                        <TableCell className="text-center">Price</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {value[1].map((e,idx) => (
                                        e.itemtype === "Raw Material"?
                                        <TableRow key={idx}>
                                          <TableCell className="text-center">{e.itemname}</TableCell>
                                          <TableCell className="text-center">{e.quantity}</TableCell>
                                          <TableCell className="text-center">{e.uom}</TableCell>
                                          <TableCell className="text-right">
                                          <NumericFormat className="pr-3" displayType={'text'} value={e.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                          </TableCell>
                                        </TableRow>
                                        :""
                                      ))}
                                      {value[1].filter(e=>e.itemtype === "Raw Material").length === 0 ?
                                    <TableRow>
                                      <TableCell className="text-center" colspan="12">No data avilable</TableCell>
                                    </TableRow>  :''
                                    }
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                                </div>
                                </div>
                              )
                            )
                          : ""}
                        {this.state.selected === 1 ? (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Production Report"}
                              data={this.state.filterdata}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                        ) : (
                          ""
                        )}

                        {this.state.selected === 2 ? (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Immediate process Report"}
                              data={this.state.impfilterdata}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                        ) : (
                          ""
                        )}
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Productionreport);
