import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { thousandsdisplay, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
// import { purchaseid } from "../../Common/Permissions";
// import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import PurchaseService from "../PurchseServices";
// import download from "../../Common/upload/download";
// import downloadfiles from "../../Common/upload/downloadfiles";
// import { Modal } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
// import UploadFilesService from "../../Common/upload/UploadFilesService";
// import FileServices from "../../Common/upload/FileServices";
// 
import {NumericFormat} from "react-number-format";

import downloadfiles from "../../Common/upload/downloadfiles";
import Footer from "../../Component/Footer";

class ViewSCN extends Component {
  constructor(props) {
    super(props);
    const id = this.props.location.state.id;
    this.state = {
      id: id,
      Orders: "",
      milestone: "",
    };
  }

  handledownload = () => {
    downloadfiles.download(
      this.state.Orders.contractpath,
      this.state.Orders.contractfile
    );
  };
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });

    PurchaseService.GetSCNdata(this.props.location.state.id).then(
      (response) => {
        this.setState({
          Orders: response.data,
          milestone: response.data.milestone,
          loading: false,
        });
      }
    ).catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  render() {
    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
            <span as="button" className="float-left mr-3 text-secondary cursorPointer" onClick={()=>this.props.navigate('/scn')}>   ❮ Back</span>
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/scn">SCN-Service Complition Note</Link>
              </li>
              <li className="breadcrumb-item active">
                SCN-Service Complition Note Details
              </li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">
                          SCN-Service Complition Note Details
                        </h3>
                      </div>
                      <div className="card-body">
                        <div>
                          {this.state.loading === true ? (
                            <div className="text-center">{spin}</div>
                          ) : (
                            <div className="form-group col-md-12">
                              <table className="float-left mb-3">
                                <tbody>
                                  <tr>
                                    <td>SCN ID</td>
                                    <td>:&nbsp;&nbsp; {this.state.Orders.customscnid}</td>
                                  </tr>
                                  <tr>
                                    <td>Order ID</td>
                                    <td>
                                      :&nbsp;&nbsp; {this.state.Orders.custompoid}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Service ID</td>
                                    <td>
                                      :&nbsp;&nbsp;{" "}
                                      {this.state.Orders.serviceid}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Service</td>
                                    <td>
                                      :&nbsp;&nbsp; {this.state.Orders.servicename}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Service Code</td>
                                    <td>
                                      :&nbsp;&nbsp; {this.state.Orders.servicecode}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Description</td>
                                    <td>
                                      :&nbsp;&nbsp; {this.state.Orders.description}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>User</td>
                                    <td>
                                      :&nbsp;&nbsp; {this.state.Orders.createdbyname}
                                    </td> 
                                  </tr>
                                 
                                  <tr>
                                    <td>Supplier</td>
                                    <td>
                                      :&nbsp;&nbsp; {this.state.Orders.supplier}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Date</td>
                                    <td>
                                      :&nbsp;&nbsp;{" "}
                                      {moment(this.state.Orders.date).format(
                                        "DD - MM - yyyy"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Amount&nbsp;&nbsp;</td>
                                    <td>
                                      : &nbsp;&nbsp;
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={
                                          this.state.Orders.scntotalpricewtax
                                        }
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Ledger Entry &nbsp;&nbsp;</td>
                                    <td>
                                      :&nbsp;&nbsp;{" "}
                                      {this.state.Orders.ledgerentry}
                                    </td>
                                  </tr>
                                  {this.state.milestone?.completionfile ===
                                  "" ? (
                                    ""
                                  ) : (
                                    <tr>
                                      <td>Service Completion Note &nbsp;</td>
                                      <td>
                                        :
                                        <>
                                          &nbsp;
                                          <button
                                            className="text-blue btn btn-link m-0"
                                            data-tip
                                            data-for="upload"
                                            onClick={this.handledownload}
                                          >
                                            {
                                              this.state.Orders.contractfile
                                            }
                                          </button>
                                          {/* //<Tooltip
                                            id="download"
                                            place="top"
                                            effect="solid"
                                          >
                                            Download
                                          </Tooltip> */}
                                        </>
                                      </td>
                                    </tr>
                                  )}
                                  {/* <tr>
                                                                <td>Payment Status&nbsp;</td>
                                                                <td>:&nbsp;&nbsp;{" "}{this.state.Orders.status}</td>
                                                            </tr> */}
                                </tbody>
                              </table>
                                {this.state.milestone !== null && this.state.milestone !== '' ?
                              <TableContainer>
                                <Table className="table-sm mt-3">
                                  <TableHead className="text-sm">
                                    <TableRow style={{ textAlign: "center" }}>
                                      <TableCell className="text-center">
                                        Name
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Expected Date
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Percentage
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Amount
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Status
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell className="text-center">
                                        {this.state.milestone?.name}
                                      </TableCell>
                                      <TableCell className="text-center">
                                        {moment(
                                          this.state.milestone?.expecteddate
                                        ).format("DD-MM-YYYY")}
                                      </TableCell>
                                      <TableCell className="text-center">
                                        {this.state.milestone?.percentage}
                                      </TableCell>
                                      <TableCell className="text-right">
                                        <NumericFormat
                                          className="pr-3"
                                          displayType={"text"}
                                          value={
                                            this.state.milestone?.totalpricewtax
                                          }
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                      <TableCell className="text-center">
                                        {this.state.milestone?.servicestatus}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              :
                              <TableContainer>
                              <Table className="table-sm mt-3 border">
                                <TableHead className="text-sm">
                                  <TableRow style={{ textAlign: "center" }}>
                                    <TableCell className="text-center">
                                      Service name
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Service code
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Description
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Amount
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Tax
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Amount
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell className="text-center">
                                      {this.state.Orders?.servicename}
                                    </TableCell>
                                    <TableCell className="text-center">
                                      {this.state.Orders.servicecode}
                                    </TableCell>
                                    <TableCell className="text-center">
                                      {this.state.Orders.description}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={
                                          this.state.Orders.servicecost
                                        }
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell className="text-center">
                                      {this.state.Orders?.servicetax}
                                    </TableCell>
                                    <TableCell className="text-right">
                                      <NumericFormat
                                        className="pr-3"
                                        displayType={"text"}
                                        value={
                                          this.state.Orders.scntotalpricewtax
                                        }
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                              }
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(ViewSCN);
