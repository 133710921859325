import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import { Autocomplete } from "@mui/material";
import { currentTenant, userid } from "../../Common/Credentials.js";
import { Spinner } from "react-bootstrap";
import SupplierService from "../../Masters/Suppliers/SupplierService.js";
import TransportationServices from "./TransportationServices.js";


class NewePlaces extends Component {
  constructor(props) {
    super(props);

    const { name } = this.props.location.state;
    const { id } = this.props.location.state;
    this.state = {
        name: name,
        id:id,
        place:'',
        zip:'',
        CountryData:[],
        StateData:[],
        CityData:[],
        country:'',
        state:'',
        city:'',
        stateId: "",
        CountryId: "",
        CityId: "",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }
 
  
  ChangeteCountry = (value, values) => {
    if(values!=='' && typeof values === 'object' && values!==null){
    this.setState({country:values.name, CountryId: values.id,phonecode:values.phonecode,state :'', stateId: '',city:'',CityId:'',CityData:[] });
    SupplierService.getStates(values.id).then((response) => {
      this.setState({
        StateData: response.data,
      });
    });
  }else{
    this.setState({
      country:'', CountryId:'', state:'',StateData:[],CityData:[], stateId: '',city:'',CityId:''
    })
  }
  };
  ChangeState = (value, values) => {
    if(values!=='' && typeof values === 'object' && values !== null){
    this.setState({state:values.name, stateId: values.id });
    SupplierService.getCities(values.id).then((response) => {
      this.setState({
        CityData: response.data,city:'',CityId:''
      });
    });
   }else{
     this.setState({
    state:'', stateId: '',city:'',CityId:''
     })
   }
  };

  handleCity=(value,values)=>{
    if(values!=='' && typeof values === 'object' && values !== null){
    this.setState({city: values.name,CityId: values.id})
  }else{
    this.setState({city:'',CityId:''})
  }
  }

  onSubmit(values) {
    // let username = AuthenticationService.getLoggedInUser()
    this.setState({ loader: true });
    let newdata = {
      place:this.state.place,
      city:this.state.city,
      cityid:this.state.CityId,
      state:this.state.state,
      stateid:this.state.stateId,
      country:this.state.country,
      countryid:this.state.CountryId,
      pincode:this.state.zip,
      tenantid:currentTenant,
      createdby:userid,
    };
    let updateddata = {
      id: this.state.id,
      place:this.state.place,
      city:this.state.city,
      cityid:this.state.CityId,
      state:this.state.state,
      stateid:this.state.stateId,
      country:this.state.country,
      countryid:this.state.CountryId,
      pincode:this.state.zip,
      tenantid:currentTenant,
      createdby:userid,
    };
    if (this.state.id < 1) {
      TransportationServices.addNewPlace(newdata).then(() =>
        this.props.navigate({pathname: "/transportation"},{state : { message: "",type:1 }})
      )
      .catch((e)=>{
        this.setState({loader:false,errormsg:e.response.data.message})
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    } else {
        TransportationServices.updatePlace(updateddata).then(() =>
        this.props.navigate({pathname:"/transportation"},{state : { message: "",type:1 }})
      )
      .catch((e)=>{
        this.setState({loader:false,errormsg:e.response.data.message})
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    }
  }

  componentDidMount() {
    const { id } = this.props.location.state;

    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    SupplierService.getCountries().then((response) =>
      this.setState({
        CountryData: response.data,
      })
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
    })

    if(id!==1){
        TransportationServices.getPlaceById(id).then(res=>{
            this.setState({
                place:res.data.place,
                city:res.data.city,
                CityId:res.data.cityid,
                state:res.data.state,
                stateId:res.data.stateid,
                country:res.data.country,
                CountryId:res.data.countryid,
                zip:res.data.pincode,
            })
        })
    }

  }

  validate(values) {
    let errors = {};
    var num = /^[0-9]+$/;

    if(this.state.country ==='India'){
      if (!values.zip) {
        errors.zip = "Zip is required";
      } else if (!num.test(values.zip)) {
        errors.zip = "Only Numbers allowed";
      } else if (!/^[0-9]{5,6}$/i.test(values.zip)) {
        errors.zip = "Should be 5 or 6 Numbers";
      }
    }else{
      if (!values.zip|| this.state.zip === '') {
        errors.zip = "PO Box No. is required";
      }
    }
      if (this.state.CountryId === "") {
        errors.country = "Country is required";
      }
      if (this.state.stateId === "") {
        errors.state = "State is required";
      }
      if (this.state.CityId === "") {
        errors.city = "City is required";
      }

      if (this.state.place === "") {
        errors.place = "Place is required";
      }

    return errors;
  }

  render() {
   
    let {
        place,
        zip,
        CountryData,
        StateData,
        CityData,
        country,
        state,
        city,
        CountryId,
        stateId,
        CityId,
    } = this.state;

    return (
      <div>
      <Header/>
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            
            <ol className="breadcrumb float-sm-right">
            <li className="text-secondary">
            <Link as="button" className=" text-secondary" to={{ pathname: "/transportation"}} state={{message: "",type:1 }}>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/assetmanagement">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to={{pathname: "/transportation"}} state= {{ message: "",type:1 }}>Transportation</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.name}</li>
            </ol>
            <div className="text-center text-danger">{this.state.errormsg}</div>

            <div className="card-body">
              <Formik
                initialValues={{
                    place,
                    zip,
                    CountryData,
                    StateData,
                    CityData,
                    country,
                    state,
                    city,
                    CountryId,
                    stateId,
                    CityId,
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ props, setFieldValue, values }) => (
                  <Form >
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="id"
                          placeholder="Id"
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-12 m-0"/>
                      <fieldset className="form-group col-md-4 pb-3">
                        <FormikTextField
                          className="form-control"
                          name="place"
                          label="Place"
                          value={this.state.place}
                          onChange={(e) =>
                            this.setState({ place: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="country"
                          options={CountryData}
                          getOptionLabel={(option) => option.name}
                          value={values.country ? undefined : undefined}
                          onChange={this.ChangeteCountry}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="country"
                              value={this.state.country}
                              label={`Select Country (${this.state.country})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="country"
                          options={StateData}
                          getOptionLabel={(option) => option.name}                         
                          value={values.state ? undefined : undefined}
                          onChange={this.ChangeState}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="state"
                              value={this.state.state}
                              label={`Select State (${this.state.state})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="city"
                          options={CityData}
                          getOptionLabel={(option) => option.name}                                                  
                          value={values.city ? undefined : undefined}
                          onChange={this.handleCity}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name='city'
                              value={this.state.city}
                              label={`Select City (${this.state.city})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4 pb-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="zip"
                          label={this.state.country==="India"?"Zip/Pincode":"PO BOX No."}
                          value={this.state.zip}
                          onChange={(e) =>
                            this.setState({ zip: e.target.value })
                          }
                        />
                      </fieldset>
                    </div>
                    <fieldset className="form-group col-md-12" />
                    {this.state.loader === true ? (
                        <button className="btn btn-sm hovbuttonColour" disabled >
                          <Spinner animation="border" size="sm" />
                          &nbsp;{"Saving"}
                          </button>
                      ) : (
                        <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fa fa-save"/> Save</button>
                      )
                    }
                    <Link
                      as="button"
                      className="btn btn-sm deletebutton ml-1"
                      to={{ pathname: "/transportation"}} state= {{ message: "",type:1 }}
                    >
                      <b className="text-danger"> X </b>Close
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

        <Footer />
      </div>
    );
  }
}
export default withRouter(NewePlaces);
