import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  FormGroup,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  styled,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { Field, FieldArray, Form, Formik } from "formik";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import moment from "moment";
import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  ccountry,
  cstate,
  currentTenant,
  prefix1,
  taxtype,
  thousandsdisplay,
  userid,
  financialyearstart,
  financialyearend,
  dataaccess,
  userwhid,
} from "../../Common/Credentials.js";
// import { purchaseid } from "../../Common/Permissions.js";
import FileServices from "../../Common/upload/FileServices.js";
import UploadFilesService from "../../Common/upload/UploadFilesService.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import { Typography } from "@mui/material";
import InventoryService from "../../inventory/InventoryService.js";
import CategoryServices from "../../Masters/Category/CategoryServices.js";
import WarehouseServices from "../../Masters/Warehouse/WarehouseServices.js";
import PurchaseServices from "../PurchseServices";
import downloadfiles from "../../Common/upload/downloadfiles.js";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";import { Grid } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {NumericFormat} from "react-number-format";
// import TextField from '@mui/material/TextField'
import { FormControlLabel } from "@mui/material";
import IOSSwitch from "../../CommonComponents/IOSSwitch";
import MasterService from "../../Masters/MasterService.js";
import UomServices from "../../Masters/Uom/UomServices.js";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ProductionServices from "../../Production/ProductionServices.js";
import AgentServices from "../../Masters/Agents/AgentServices.js";
import { accounts, production } from "../../Common/Products.js";
import SettingsServices from "../../Settings/SettingsServices.js";
import AdminDashboardServices from "../../DashboardPages/AdminDashboard/AdminDashboardServices.js";

let datalist = [];
let updatedatalist = [];
let list = {};
let count = 0;
let serviceList = [];
let doctype = "quotation";
let curmonth, year, day, path;

const useStyles = styled((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: "bottom",
    height: 20,
    width: 20,
  },
  details: {
    alignItems: "center",
  },
  column: {
    flexBasis: "33.33%",
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  },
}));

// const contracttimeList = ["Monthly","Quarterly","Half-Yearly","Yearly"]

class NewPurchase extends Component {
  constructor(props) {
    super(props);
    const pagename = this.props.location.state.pagename;
    const id = this.props.location.state.id;
    const indentid = this.props.location.state.indentid;
    this.state = {
      sloader: false,
      customPOdate:false,
      createdAt:new Date(),
      potype: "0",
      pagename: pagename,
      id: id,
      indentid: indentid,
      agentsdata: [],
      agentreq: false,
      agent: "",
      agentid: "",
      iid: "",
      data: [],
      supplier: "",
      supplierid: "",
      supcountry: "",
      itemid: "",
      itemname: "",
      itemlist: [],
      filteritemlist: [],
      quantity: "",
      cost: "",
      hsn: "",
      tax: "",
      cgst:'',
      sgst:"",
      addparams: "False",
      taxamount: "",
      totaltaxamount: 0,
      totalprice: "",
      itemprice: "",
      grandtotal: 0,
      listData: [],
      status: 0,
      suppliers: [],
      parameters: 0,
      uomlist: [],
      uom: "",
      uomid: "",
      WarehouseList: [],
      warehouse: "",
      whid: '',
      CategoryList: [],
      category: "",
      catid: "",
      advance: 0,
      advancestatus: false,
      paymentterms: 0,
      serviceitemList: [],
      filterserv: [],
      service: "",
      serviceid: "",
      servicecategory: "",
      servicecatid: "",
      servicecode: "",
      servicetax: "",
      servicecost: "",
      servicecgst: "",
      servicesgst: "",
      serviceigst: "",
      servicegst: "",
      servicevat: "",
      servicetaxamount: "",
      servicecostwttax: "",
      servicedescription: "",
      contracttype: "",
      contracttypeList: [],
      serviceList: [],
      ServiceCategoryList: [],
      servicetotal: "",
      grandservicetotal: 0,
      quotationpath: "",
      quotationfile: "",
      serviceerr: "",
      selectedFiles: undefined,
      progress: 0,
      message: "",
      isError: false,
      fileInfos: [],
      fileInfo: "",
      partyid: "",
      error: false, //<---- here
      errorMessage: {}, //<-----here
      loader: false,
      path: "",
      file: "",
      milestone: [],
      recurringtype: [],
      contracttime: "",
      contractstartdate: new Date(),
      contractenddate: new Date(),
      milestonesavebtn: false,
      percentagemessage: "",
      size: "",
      colour: "",
      thickness: "",
      brand: "",
      model: "",
      totalmtrroll: "",
      poitemstatus: "2",
      poitemtype: "1",
      sizelist: [],
      colourlist: [],
      thicknesslist: [],
      brandslist: [],
      closingStock: 0,
      pro: true,
      serv: false,
      prosev: false,

      currencylist: [],
      currencyid: "",
      currency: "",
      curconversionrate: 1,
      convertionreq: false,
      basecurrency: "",

      porecurring: false,
      porecurringtype: "",
      porecurringfrom: new Date(),
      porecurringtill: new Date(),
      taxrequired: true,
      taxtype: taxtype,
      gsttype: false,
      open: false,

      importpo: false,
      tcsreq: "",
      tcs: 0,
      tcsableamount:0,
      tcsamount: 0,

      IndentIdList: [],
      indentitems: [],
      indentitemid: "",
      itemsfor: [
        { id: "0", name: "Internal Use" },
        { id: "1", name: "Reselling" },
        { id: "2", name: "Raw Material" },
      ],
      Internalusefor: [
        { id: "1", name: "Asset" },
        { id: "2", name: "Consumption" },
      ],
      assettypes:[
        {id:"0",name:"Own"},
        {id:"1", name:"Thirdparty"}
      ],
      assettype:"0",
      orderfrom: 0,
      reqquotationupload: false,

      settings: "",
      inventorydata:[],
      blockedpurchasequantity:0,
      blockedsalesquantity:0,
      openingbalance:0,
      creditamount:0,
      adjustopen:false,
      adjustcredit: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
  }

  handleProductChange = () => {
    this.setState({
      pro: true,
      serv: false,
      prosev: false,
      potype: "0",
      supplier: "",
      supplierid: "",
      itemid: "",
      itemname: "",
      quantity: "",
      cost: "",
      hsn: "",
      tax: "",
      cgst:"",
      sgst:"",
      addparams: "False",
      taxamount: "",
      totaltaxamount: 0,
      totalprice: "",
      itemprice: "",
      grandtotal: 0,
      uom: "",
      uomid: "",
      category: "",
      catid: "",
      advance: 0,
      advancestatus: false,
      paymentterms: 0,
      service: "",
      serviceid: "",
      servicecategory: "",
      servicecatid: "",
      servicecode: "",
      servicetax: "",
      servicecost: "",
      servicetaxamount: "",
      servicecostwttax: "",
      servicedescription: "",
      contracttype: "",
      servicetotal: "",
      grandservicetotal: 0,
      quotationpath: "",
      quotationfile: "",
      serviceerr: "",
      selectedFiles: undefined,
      progress: 0,
      message: "",
      isError: false,
      fileInfos: [],
      fileInfo: "",
      partyid: "",
      size: "",
      colour: "",
      thickness: "",
      brand: "",
      totalmtrroll: "",
      listData: [],
    });
    datalist = [];
  };
  handleServiceChange = () => {
    this.setState({
      serv: true,
      pro: false,
      prosev: false,
      potype: "1",
      supplier: "",
      supplierid: "",
      itemid: "",
      itemname: "",
      quantity: "",
      cost: "",
      hsn: "",
      tax: "",
      cgst:"",
      sgst:"",
      addparams: "False",
      taxamount: "",
      totaltaxamount: 0,
      totalprice: "",
      itemprice: "",
      grandtotal: 0,
      uom: "",
      uomid: "",
      category: "",
      catid: "",
      advance: 0,
      advancestatus: false,
      paymentterms: 0,
      service: "",
      serviceid: "",
      servicecategory: "",
      servicecatid: "",
      servicecode: "",
      servicetax: "",
      servicecost: "",
      servicetaxamount: "",
      servicecostwttax: "",
      servicedescription: "",
      contracttype: "",
      servicetotal: "",
      grandservicetotal: 0,
      quotationpath: "",
      quotationfile: "",
      serviceerr: "",
      selectedFiles: undefined,
      progress: 0,
      message: "",
      isError: false,
      fileInfos: [],
      fileInfo: "",
      partyid: "",
      size: "",
      colour: "",
      thickness: "",
      brand: "",
      totalmtrroll: "",
      listData: [],
    });
    datalist = [];
  };
  handleProSerChange = () => {
    this.setState({
      prosev: true,
      pro: false,
      serv: false,
      potype: "2",
      supplier: "",
      supplierid: "",
      itemid: "",
      itemname: "",
      quantity: "",
      cost: "",
      hsn: "",
      tax: "",
      cgst:"",
      sgst:"",
      addparams: "False",
      taxamount: "",
      totaltaxamount: 0,
      totalprice: "",
      itemprice: "",
      grandtotal: 0,
      uom: "",
      uomid: "",
      category: "",
      catid: "",
      advance: 0,
      advancestatus: false,
      paymentterms: 0,
      service: "",
      serviceid: "",
      servicecategory: "",
      servicecatid: "",
      servicecode: "",
      servicetax: "",
      servicecost: "",
      servicetaxamount: "",
      servicecostwttax: "",
      servicedescription: "",
      contracttype: "",
      servicetotal: "",
      grandservicetotal: 0,
      quotationpath: "",
      quotationfile: "",
      serviceerr: "",
      selectedFiles: undefined,
      progress: 0,
      message: "",
      isError: false,
      fileInfos: [],
      fileInfo: "",
      partyid: "",
      size: "",
      colour: "",
      thickness: "",
      brand: "",
      totalmtrroll: "",
      listData: [],
    });
    datalist = [];
  };

  handleStartDateChange = (date) => {
    this.setState({ contractstartdate: date });
  };
  handleEndDateChange = (date) => {
    this.setState({ contractenddate: date });
  };
  handleQuatation = () => {
    downloadfiles.download(this.state.path, this.state.file);
  };

  handleAgent = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        agent: values.name,
        agentid: values.id,
      });
    } else {
      this.setState({ agent: "", agentid: "" });
    }
  };

  selectFile = (event) => {
    this.setState({
      selectedFiles: event.target.files,
    });
  };

  handlePOtype = (e) => {
    this.setState({
      potype: e.target.value,
      listData: [],
      serviceList: [],
      grandservicetotal: 0,
      grandtotal: 0,
    });
    datalist = [];
    serviceList = [];
  };

  handleList = () => {
    count = count + 1;
    list = {
      // id: this.state.id,
      indentitemid: this.state.indentitemid,
      itemid: this.state.itemid,
      name: this.state.itemname,
      hsn: this.state.hsn,
      price: parseFloat(this.state.cost),
      quantity: parseFloat(this.state.quantity),
      uom: this.state.uom,
      uomid: parseFloat(this.state.uomid),
      catid: this.state.catid,
      category: this.state.category,
      tax: parseFloat(this.state.tax),
      taxamount: this.state.taxamount,
      itemprice: parseFloat(this.state.itemprice),
      totalprice: parseFloat(this.state.totalprice),
      totalpricewtax: parseFloat(this.state.totalprice),
      poitemstatus: this.state.poitemstatus,
      poitemtype: this.state.poitemstatus === "0" ? this.state.poitemtype : "0",
      assettype:this.state.poitemstatus === "0" && this.state.poitemtype==="1" ?this.state.assettype : "2",
      parameters: this.state.parameters,
      brand: this.state.brand,
      size: this.state.size,
      colour: this.state.colour,
      thickness: this.state.thickness,
      totalmtrroll: this.state.totalmtrroll,
    };
    datalist.push(list);
    updatedatalist.push(list);
    this.setState({ listData: datalist });
    if (this.state.indentitems.length !== 0) {
      this.state.indentitems.find(
        (e) => e.id === this.state.indentitemid
      ).quantity =
        this.state.indentitems.find((e) => e.id === this.state.indentitemid)
          .quantity - this.state.quantity;
    }
    this.setState({
      grandtotal: this.state.grandtotal + this.state.totalprice,
      tcsableamount:this.state.grandtotal + this.state.totalprice,
      totaltaxamount: this.state.totaltaxamount + this.state.taxamount,
      quantity: "",
      cost: 0,
      totalprice: 0,
      indentitems: this.state.indentitems,
    });
  };

  validatepercentage = (values) => {
    let totalper = 0;
    values.map((e) => (totalper = e.percentage + totalper));
  };

  handlemilestonevalues = (values) => {
    let totalpercentage = 0;
    values.map((e) => {
      return (totalpercentage = e.percentage + totalpercentage);
    });
    if (totalpercentage > 100) {
      this.setState({ percentagemessage: "Percentage is Greaterthan 100%" });
    } else if (totalpercentage < 100) {
      this.setState({ percentagemessage: "Percentage is Lessthan 100%" });
    } else {
      this.setState({
        milestone: values,
        milestonesavebtn: true,
        percentagemessage: "Milestones Saved Scussesfully",
      });
    }
  };

  handlecreatedAtDateChange = (date) => {
    this.setState({ createdAt: date });
    this.setState({
      fed: moment(date).format("yyyy-MM-DD HH:mm:ss"),
    });
  };
  handlecustomPODate=()=>{
    this.setState({customPOdate:this.state.customPOdate===true?false:true,createdAt:new Date()})
  }


  handleadvancestatus = (e) => {
    this.setState({ advancestatus: !this.state.advancestatus, advance: 0 });
  };

  handleSliderChange = (event, newValue) => {
    this.setState({ paymentterms: newValue });
  };

  handlepaymentterms = (event) => {
    this.setState({ paymentterms: Number(event.target.value) });
  };

  handleBlur = () => {
    if (this.state.paymentterms < 0) {
      this.setState({ paymentterms: 0 });
    } else if (this.state.paymentterms > 120) {
      this.setState({ paymentterms: 120 });
    }
  };

  handleWarehouse = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({ whid: values.id, warehouse: values.name });
    } else {
      this.setState({
        whid: "",
        warehouse: "",
        itemname: "",
        hsnid: "",
        hsn: "",
        quantity: "",
        cost: "",
        uom: "",
        uomid: "",
        description: "",
      });
    }
  };

  handleCategory = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        catid: values.id,
        category: values.category,
        filteritemlist: this.state.itemlist.filter(
          (e) => e.catid === values.id
        ),
        itemid: "",
        itemname: "",
        hsn: "",
        tax: "",
        cgst:"",
      sgst:"",
        cost: "",
        quantity: "",
        addparams: "False",
      });
    } else {
      this.setState({
        itemid: "",
        itemname: "",
        hsn: "",
        tax: "",
        cgst:"",
      sgst:"",
        cost: "",
        quantity: "",
        category: "",
        catid: "",
        addparams: "False",
        filteritemlist: [],
      });
    }
  };

  handlesupplier = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      let lv = "Personal";

      if (values.ledgerid === null || values.ledgerid === undefined) {
        if (accounts === "YES") {
        //   if (
        //     window.confirm(
        //       `Ledger account is not created for supplier ${values.companyname}, so no payment or receipt can be done. Press 'ok' to create ledger or 'cancel' to continue`
        //     )
        //   ) {
        //     this.props.navigate("/newparty", {
        //       id: -1,
        //       name: "New Party Ledger",
        //       lv,
        //     });
        //   }
        // } else {
          alert(
            `Ledger account is not created for supplier ${values.companyname}, so no payment or receipt can be done`
          );
        }
      }
      this.setState({
        supplierid: values.id,
        supplier: values.companyname,
        partyid: values.id,
        taxtype:values.taxtype,
        gsttype:values.statename === cstate && values.countryname === ccountry
            ? false
            : true,
        supcountry: values.countryname,
        creditamount:values.creditamount,
        openingbalance:values.openingbalance,
        convertionreq:
          values.currency === this.state.basecurrency ? false : true,
        currency: values.currency,
        currencyid: this.state.currencylist.find(
          (e) => e.currency === values.currency
        ).id,
        quantity: "",
        cost: "",
        addparams: "False",
        totalprice: "",
        grandtotal: 0,
      });
      //     if(values.ledgerid === null){
      //       if(window.confirm(`Please Create a Ledger Account of ${values.companyname}`)){
      //         this.props.navigate("/newparty", {
      //           id: -1,
      //           name: "New Party Ledger",
      //           lv,
      //         })
      //       }else{
      //         this.setState({
      //           supplierid:'',
      //           supplier: '',
      //           partyid: '',
      //           quantity: "" ,
      //           cost: "",
      //           totalprice: "",
      //           addparams:"False",
      //           grandtotal: 0
      //         });
      //         }
      //        }
    } else {
      this.setState({
        supplierid: "",
        supplier: "",
        partyid: "",
        gsttype: false,
        supcountry: "",
        convertionreq: false,
        currency: "",
        currencyid: "",
        quantity: "",
        cost: "",
        addparams: "False",
        totalprice: "",
        grandtotal: 0,
      });
    }
  };

  onSubmit = (e) => {
    // this.setState({
    //   totalprice:
    //     this.state.cost * this.state.quantity +
    //     ((this.state.tax * this.state.cost) / 100) * this.state.quantity,
    //   taxamount:
    //     ((this.state.tax * this.state.cost) / 100) *
    //     parseFloat(this.state.quantity),
    //   itemprice: this.state.cost * this.state.quantity,
    // });
   

    let halftaxamountroundoff = ((((this.state.tax/2) * this.state.cost) / 100) * parseFloat(this.state.quantity)).toFixed(2)
    let taxamountroundoff = this.state.taxtype === "GST" && ccountry === "India" && this.state.gsttype === false?parseFloat(halftaxamountroundoff) + parseFloat(halftaxamountroundoff) :  (((this.state.tax) * this.state.cost) / 100) * parseFloat(this.state.quantity)
    this.setState({
      totalprice:
        (this.state.cost * this.state.quantity) +
        parseFloat(taxamountroundoff),
      taxamount: taxamountroundoff,
      itemprice: this.state.cost * this.state.quantity,
    });
  };

  handleQuantityChange = (e) => {
    this.setState({ quantity: e.target.value });
    this.setState({ totalprice: "" });
  };

  handleUOM = (value, values) => {
    if (value !== "") {
      this.setState({ uomid: values.uom.id, uom: value });
    }
  };

  handleSize = (value, values) => {
    if (value !== "") {
      this.setState({ size: value });
    }
  };

  handleColour = (value, values) => {
    if (value !== "") {
      this.setState({ colour: value });
    }
  };

  handlepoitemstatus = (e) => {
    this.setState({ poitemstatus: e.target.value });
  };
  handleChange = (e) => {
    this.setState({ poitemtype: e.target.value });
  };

  handleAssetTypeChange=(e)=>{
    this.setState({ assettype: e.target.value });
  }

  handleSelect(value, values) {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        itemid: values.id,
        itemname: values.name,
        totalprice: "",
      });

      let itemdata = this.state.itemlist.filter(
        (data) => data.id === values.id
      );
      itemdata.map((data) =>
        this.setState({
          hsn: data.hsn,
          tax: this.state.taxrequired === false ? 0 : data.tax,
          cgst:this.state.taxrequired === false ? 0 : data.tax/2,
          sgst:this.state.taxrequired === false ? 0 : data.tax/2,
          parameters: data.parameters,
          description: data.description,
          uom: data.uomname,
          uomid: data.id,
          brand: data.brand,
          model: data.model,
          addparams: data.addparams,
        })
      );
    } else {
      this.setState({
        itemid: "",
        itemname: "",
        totalprice: "",
        hsn: "",
        tax: "",
        cgst:"",
        sgst:"",
        parameters: "",
        description: "",
        uom: "",
        brand: "",
        model: "",
        addparams: "False",
      });
    }
  }

  removeItemFromList(id) {
    let deleteditem = datalist.filter((person, index) => index === id);
    let tp = deleteditem.map((data) => data.totalpricewtax);
    let tx = deleteditem.map((data) => data.taxamount);
    const newlist = datalist.filter((person, index) => index !== id);
    if (this.state.indentitems.length !== 0) {
      this.state.indentitems.find(
        (e) => e.id === deleteditem.find((e) => e).indentitemid
      ).quantity =
        this.state.indentitems.find(
          (e) => e.id === deleteditem.find((e) => e).indentitemid
        ).quantity + deleteditem.find((e) => e).quantity;
    }
    this.setState({
      listData: newlist,
      grandtotal: this.state.grandtotal - tp,
      tcsableamount:this.state.tcsableamount-tp,
      totaltaxamount: this.state.totaltaxamount - tx,
      indentitems: this.state.indentitems,
      advance: this.state.advancestatus?this.state.advance<=(this.state.grandtotal - tp)?this.state.advance:(this.state.grandtotal - tp):0
    });
    datalist = newlist;
  }

  removeServiceFromList(id) {
    let deleteservice = serviceList.filter((person, index) => index === id);
    let tp = deleteservice.map((data) => data.servicetotal);
    const newlist = serviceList.filter((person, index) => index !== id);
    this.setState({
      serviceList: newlist,
      grandservicetotal: this.state.grandservicetotal - tp,
    });
    serviceList = newlist;
  }

  handleSubmit = (values) => {
    let filedata = this.state.fileInfos.map((e) => e.name).toLocaleString();
    path =
      prefix1 +
      "/" +
      doctype +
      "/" +
      this.state.partyid +
      "/" +
      year +
      "/" +
      curmonth +
      "/" +
      day;
    if (
      this.state.reqquotationupload === true &&
      filedata === "" &&
      this.state.file === ""
    ) {
      this.setState({ message: "Please Upload Quotation" });
    } else {
      this.setState({ sloader: true });
      if (this.state.potype === "1") {
        serviceList = [
          {
            service: this.state.service,
            serviceid: this.state.serviceid,
            servicecatid: this.state.servicecatid,
            servicecode: this.state.servicecode,
            servicecategory: this.state.servicecategory,
            servicecost: parseFloat(this.state.servicecost),
            servicetax: this.state.servicetax,
            servicedescription: this.state.servicedescription,
            servicetotal:
              parseFloat(this.state.servicecost) +
              parseFloat(this.state.servicecost) * (this.state.servicetax / 100),
            recurringtype: this.state.contracttime,
            milestonelist: this.state.milestone,
            contracttype: this.state.contracttype,
            contractstartdate: this.state.contractstartdate,
            contractenddate: this.state.contractenddate,
            // contractfile:filedata,
            // contractpath:path,
          },
        ];
      }
      let podata = {
        agent: this.state.agentid,
        orderfrom: this.state.orderfrom,
        indentid: this.state.indentid,
        poid: this.state.id.toLocaleString(),
        potype: parseInt(this.state.potype),
        importpo: this.state.importpo,
        supplier: this.state.supplier,
        supplierid: this.state.supplierid,
        invoiceuploadrequired:this.state.settings.invuploadm === "YES"?true:false,
        grandtotal: parseFloat(this.state.grandtotal),
        whid: this.state.whid,
        advanceamountreq: parseFloat(this.state.advance),
        advancepaymentreq: this.state.advancestatus === false ? 0 : 1,
        createdby: userid,
        updatedby: userid,
        tenantid: currentTenant,
        status: this.state.status,
        totaltaxamount: parseFloat(this.state.totaltaxamount),
        paymentterms: this.state.paymentterms,
        podata:datalist,
        servicel:
          this.state.potype === "1" ? serviceList : this.state.serviceList,
        grandservicetotal: parseFloat(this.state.grandservicetotal),
        quotationpath: this.state.path === "" ? path : this.state.path,
        quotationfile: this.state.file === "" ? filedata : this.state.file,

        taxtype: this.state.taxtype,
        taxrequired: this.state.taxrequired,
        gsttype: this.state.gsttype === false ? 0 : 1,
        porecurring: this.state.porecurring === true ? "Blanketpo" : "Onetime",
        recurringtype: this.state.porecurringtype,
        postartdate: this.state.porecurringfrom,
        poenddate: this.state.porecurringtill,
        tcsreq: this.state.tcsreq === false ? 0 : 1,
        tcs: this.state.tcsreq === false ? 0 : this.state.tcs,
        tcsamount: this.state.tcsreq === false ? 0 : parseFloat(this.state.tcsamount),
        tcsableamount: this.state.tcsreq === false ? 0 : parseFloat(this.state.tcsableamount),
        directpo:false,
        currency: this.state.currency,
        curconversionrate: parseFloat(this.state.curconversionrate),
        createdAt:this.state.createdAt,
        customPOdate: this.state.customPOdate,
        adjustcredit:this.state.adjustcredit,
        adjustopen:this.state.adjustopen,
        adjustcredit:this.state.adjustcredit,
      };
      if (this.state.pagename === "Edit Purchase Order") {
        PurchaseServices.NewOrder(podata)
          .then(() => this.props.navigate(-1))
          .catch((e) => {
            this.setState({sloader:false})
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      } else {
        PurchaseServices.NewOrder(podata)
          .then((response) => this.props.navigate({pathname:"/viewpurchaseorder"},{state: {id: response.data}}))
          .catch((e) => {
            this.setState({sloader:false})
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    }
  };

  handleService = (value, values) => {
    // alert(this.state.taxrequired)
    this.setState({
      serviceid: values.service.id,
      service: value,
      servicetax:
        this.state.taxrequired === false ? 0 : values.service.servicetax,
      servicecode: values.service.servicecode,
      servicecost: "",
      servicecostwttax: "",
      servicetaxamount: "",
      milestonelist: [],
      percentagemessage: "",
      milestonesavebtn: false,
    });
  };

  handleServicecategory = (value, values) => {
    this.setState({
      servicecatid: values.servicecategory.id,
      servicecategory: value,
      filterserv: this.state.serviceitemList.filter(
        (e) => e.catid === values.servicecategory.id
      ),
      servicecost: "",
      servicecostwttax: "",
      servicetaxamount: "",
      milestonelist: [],
      percentagemessage: "",
      milestonesavebtn: false,
    });
  };

  handleServiceAmountChange = (e) => {
    this.setState({
      servicecost: e.target.value,
      servicetaxamount:
        (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicecgst:
        ((this.state.servicetax / 100) * parseFloat(e.target.value)) / 2,
      servicesgst:
        ((this.state.servicetax / 100) * parseFloat(e.target.value)) / 2,
      serviceigst: (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicegst: (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicevat: (this.state.servicetax / 100) * parseFloat(e.target.value),
      servicecostwttax:
        parseFloat(e.target.value) +
        (this.state.servicetax / 100) * parseFloat(e.target.value),
    });
  };

  handleaddService = () => {
    if (
      this.state.supplier === "" ||
      this.state.service === "" ||
      this.state.servicedescription === "" ||
      this.state.servicecost === "" ||
      this.state.servicecategory === ""
    ) {
      this.setState({ serviceerr: "Please fill all the Fields" });
    } else {
      let services = {
        service: this.state.service,
        serviceid: this.state.serviceid,
        servicecatid: this.state.servicecatid,
        servicecode: this.state.servicecode,
        servicecategory: this.state.servicecategory,
        servicecost: parseFloat(this.state.servicecost),
        servicetax: this.state.servicetax,
        servicedescription: this.state.servicedescription,
        servicetotal:
          parseFloat(this.state.servicecost) +
          parseFloat(this.state.servicecost) * (this.state.servicetax / 100),
      };
      serviceList.push(services);
      this.setState({
        serviceList: serviceList,
        grandservicetotal:
          this.state.grandservicetotal +
          (parseFloat(this.state.servicecost) +
            parseFloat(this.state.servicecost) * (this.state.servicetax / 100)),
        servicecost: "",
        servicedescription: "",
        serviceerr: "",
      });
    }
  };

  handleCurrency = (value, values) => {
    if (value !== "") {
      this.setState({
        currencyid: values.currency.id,
        currency: values.currency.currency,
      });
    }
  };

  handleCurconversionrate = (e) => {
    this.setState({
      curconversionrate: e.target.value,
    });
  };

  handlemulticurrencyrequired = () => {
    this.setState({ convertionreq: !this.state.convertionreq });
  };

  handletaxrequired = () => {
    this.setState({ taxrequired: !this.state.taxrequired });
  };
  handleTaxTypeChange = (e) => {
    this.setState({ taxtype: e.target.value });
  };

  handleimportpurchase = () => {
    this.setState({ importpo: !this.state.importpo });
  };

  handleRecurringTill = (date) => {
    this.setState({ porecurringtill: date });
  };

  handleRecurringFrom = (date) => {
    this.setState({ porecurringfrom: date });
  };

  handlegsttype = (e) => {
    this.setState({ gsttype: !this.state.gsttype });
  };

  handletcsreq = (e) => {
    this.setState({ tcsreq: !this.state.tcsreq });
  };

  handletcsChange = (e) => {
    this.setState({
      tcs: parseFloat(e.target.value),
      tcsamount: ((this.state.tcsableamount * e.target.value) / 100).toFixed(3),
    });
  };

  refreshData() {
    const { id } = this.props.location.state;
    datalist = [];
    list = {};
    updatedatalist = [];
    count = 0;
    serviceList = [];

    SettingsServices.GetSettings(currentTenant).then((response) => {
      this.setState({ settings: response.data });
    });

    MasterService.getParameters(currentTenant).then((res) => {
      this.setState({
        parameters: res.data,
        sizelist: res.data.size,
        colourlist: res.data.colour,
        thicknesslist: res.data.thickness,
        brandslist: res.data.brands,
      });
    });

    PurchaseServices.GetContrctType()
      .then((response) => {
        this.setState({ contracttypeList: response.data });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
    PurchaseServices.GetRecurringduration().then((response) => {
      this.setState({ recurringtype: response.data });
    });
    UomServices.getUOM(currentTenant).then((response) =>
      this.setState({
        uomlist: response.data,
      })
    );
    WarehouseServices.getWarehouses(currentTenant).then((response) =>
      this.setState(
        {
          WarehouseList: response.data,
        },
        () => {
          if (this.state.WarehouseList.length === 1) {
            this.setState({
              warehouse: this.state.WarehouseList[0].name,
              whid: this.state.WarehouseList[0].id,
            });
          }else if(dataaccess === "Organization" || dataaccess === "User"){
            this.setState({warehouse:this.state.WarehouseList.find(e=>e.id === userwhid).name,whid:this.state.WarehouseList.find(e=>e.id === userwhid).id,WarehouseList:this.state.WarehouseList.filter(e=>e.id===userwhid)})
          }
        }
      )
    );

    PurchaseServices.GetItemlist(currentTenant).then((resp) =>
      this.setState({
        itemlist: resp.data,
        closingStock:
          resp.data === undefined || resp.data.length === 0
            ? 0
            : resp.data.length === 0
            ? 0
            : resp.data[0].closingstock,
      })
    );

    InventoryService.getServices(currentTenant).then((res) => {
      this.setState({ serviceitemList: res.data });
    });

    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
      userid: userid,
    dataaccess:dataaccess
    }

    PurchaseServices.GetAllPurchases(datadao).then((response) =>
      this.setState({
        data: response.data,
      })
    );

    CategoryServices.getCategory(currentTenant).then((response) =>
      this.setState({
        CategoryList: response.data.filter((e) => e.categorytype === "Product"),
        ServiceCategoryList: response.data.filter(
          (e) => e.categorytype === "Service"
        ),
      })
    );

    PurchaseServices.GetAllSellers(currentTenant).then((res) =>
      this.setState({
        suppliers: res.data,
      })
    );

    MasterService.GetAllCurrency().then((response) => {
      this.setState({ currencylist: response.data });
    });

    MasterService.GetCurrencyOfTenant(currentTenant).then((response) => {
      this.setState({
        basecurrency: response.data.currency,
        currencyid: response.data.id,
        currency: response.data.currency,
        curconversionrate: 1,
      });
    });

    AgentServices.getAgents(currentTenant).then((res) => {
      this.setState({ agentsdata: res.data });
    });

    ProductionServices.getIndentingIdListForPo(currentTenant).then(
      (response) => {
        this.setState({ IndentIdList: response.data });
      }
    );

    InventoryService.retriveStock(currentTenant).then(res=>{
      this.setState({inventorydata:res.data})
    })
    AdminDashboardServices.blockedpurchasequantity(currentTenant).then((res) =>
    this.setState({blockedpurchasequantity:res.data})
    )
    AdminDashboardServices.blockedsalesquantity(currentTenant).then((res) =>
    this.setState({blockedsalesquantity:res.data})
    )

    if (id < 1) {
      return;
    }

    PurchaseServices.GetPurchases(id).then((response) =>
      this.setState(
        {
          potype: response.data.potype.toLocaleString(),
          supplier: response.data.supplier,
          agent:response.data.agent,
          agentid:response.data.agentid,
          agentreq:response.data.agentid===undefined||response.data.agentid===null?false:true,
          supplierid: response.data.supplierid,
          listData: response.data.purchaseitem,
          serviceList: response.data.servicelist,
          totaltaxamount: response.data.totaltaxamount,
          grandtotal: response.data.grandproducttotal,
          grandservicetotal: response.data.grandservicetotal,
          whid: response.data.whid,
          warehouse: response.data.warehouse,
          advance: response.data.advanceamountreq,
          advancestatus:response.data.advancepaymentreq === "True" ? true : false,
          paymentterms: response.data.paymentterms,
          path: response.data.quotationpath,
          file: response.data.quotationfile,
          tcsreq: response.data.tcsreq,
          tcs: response.data.tcs,
          tcsamount: response.data.tcsamount,
          tcsableamount: response.data.tcsableamount,
        },
        () => (datalist = response.data.purchaseitem)
      )
    );
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    year = new Date().getFullYear();
    day = new Date().getDate();
    let mn = new Date().getMonth() + 1;
    curmonth = moment(mn, "M").format("MMMM");

    this.refreshData();
  }

  upload() {
    let currentFile = this.state.selectedFiles[0];

    this.setState({ progress: 100, currentFile: currentFile });

    UploadFilesService.upload(
      currentFile,
      year,
      curmonth,
      doctype,
      this.state.partyid,
      day,
      (event) => {
        this.setState({
          progress: Math.round((100 * event.loaded) / event.total),
        });
      }
    )
      .then((response) => {
        this.setState({
          message: response.data.message,
          isError: false,
        });
        return FileServices.getFiles1(currentFile.name);
      })
      .then((files) => {
        this.setState({ fileInfos: files.data });
      })

      .catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
          isError: true,
        });
      });

    this.setState({ selectedFiles: undefined });
  }

  handleporecurringtypeChange = (e) => {
    this.setState({ porecurringtype: e.target.value });
  };

  handleporecurring = (e) => {
    this.setState({ porecurring: !this.state.porecurring });
  };

  handlePOFrom = (value) => {
    this.setState({
      orderfrom: value,
      poitemstatus: "3",
      potype: "0",
      pro: true,
      serv: false,
      prosev: false,
    });
  };

  handleIndent = (value) => {
    if (value !== "") {
      this.setState({ indentid: value });
      ProductionServices.getIndentingItems(value).then((response) =>
        this.setState({
          indentitems: response.data.indentingitems,
          whid: response.data.whid,
          warehouse: response.data.warehouse,
          wcid: response.data.wcid,
          workcenter: response.data.workcenter,
        })
      );
    }
  };

  handleIndentItem = (values) => {
    let itemdata = this.state.itemlist.filter(
      (data) => data.id === values.itemid
    );
    itemdata.map((data) =>
      this.setState({
        indentitemid: values.id,
        itemid: values.itemid,
        itemname: values.itemname,
        hsn: data.hsn,
        tax: this.state.taxrequired === false ? 0 : data.tax,
        cgst: this.state.taxrequired === false ? 0 : data.tax/2,
        sgst: this.state.taxrequired === false ? 0 : data.tax/2,
        parameters: data.parameters,
        description: data.description,
        catid: data.categoryid,
        quantity: values.quantity,
        category: this.state.CategoryList.find((e) => e.id === data.categoryid)
          .category,
        uom: values.uom,
        uomid: this.state.uomlist.find((e) => e.name === values.uom).id,
      })
    );
    this.setState({ totalprice: "" });
  };

  validate(values) {
    let errors = {};

      if(this.state.agentreq===true){
        if(this.state.agentid ===''||this.state.agentid === undefined){
          errors.agent = "Please Select Agent";
        }
      }
    if (this.state.potype === "1") {
      if (this.state.supplierid === "") {
        errors.supplier = "Please Select Supplier";
      }

      if (this.state.service === "") {
        errors.service = "Please Select Service";
      }

      if (this.state.servicecategory === "") {
        errors.servicecategory = "Please Select Servicecategory";
      }

      if (this.state.servicecost === "") {
        errors.servicecost = "Please Enter Amount";
      }

      // if(this.state.servicedescription ===''){
      //   errors.servicedescription = "Please Enter Description";
      // }

      if (this.state.contracttype === "") {
        errors.contracttype = "Please Select Contract type";
      }
    } else {
      if (this.state.supplierid === "") {
        errors.supplier = "Please Select Supplier";
      }

      if (this.state.category === "") {
        errors.category = "Category is required";
      }

      if (this.state.itemname === "") {
        errors.itemname = "Please Select Item";
      }
      if (this.state.quantity === "") {
        errors.quantity = "Please Enter Quantity";
      }

      if (this.state.quantity < 0) {
        errors.quantity = "Value Should be greaterthan or equal to 0";
      }

      if (this.state.warehouse === "") {
        errors.warehouse = "Please Enter Org Unit";
      }
    }
    return errors;
  }

  onfocus() {
    document
      .getElementById("amount")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document.getElementById("amount").addEventListener("keydown", function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }
  onsamountfocus() {
    document
      .getElementById("samount")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document
      .getElementById("samount")
      .addEventListener("keydown", function (e) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      });
  }
  onquantityfocus() {
    document
      .getElementById("quantity")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document
      .getElementById("quantity")
      .addEventListener("keydown", function (e) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      });
  }
  oncostfocus() {
    document
      .getElementById("cost")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document.getElementById("cost").addEventListener("keydown", function (e) {
      if (e.which === 38 || e.which === 40) {
        e.preventDefault();
      }
    });
  }
  onadvancefocus() {
    document
      .getElementById("advance")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document
      .getElementById("advance")
      .addEventListener("keydown", function (e) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      });
  }
  oncurrencyconvtfocus() {
    document
      .getElementById("standard-adornment-amount")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document
      .getElementById("standard-adornment-amount")
      .addEventListener("keydown", function (e) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      });
  }

  render() {
    let {
      agent,
      agentid,
      agentreq,
      agentsdata,
      supcountry,
      potype,
      id,
      itemid,
      itemname,
      cost,
      quantity,
      totalprice,
      itemprice,
      tax,
      cgst,
      sgst,
      supplier,
      supplierid,
      uomlist,
      uom,
      uomid,
      hsn,
      addparams,
      itemlist,
      filteritemlist,
      suppliers,
      WarehouseList,
      warehouse,
      whid,
      CategoryList,
      category,
      catid,
      advance,
      advancestatus,
      paymentterms,
      sizelist,
      colourlist,
      thicknesslist,
      brandslist,
      saleorderdata,
      preitemList,
      parameters,
      size,
      brand,
      model,
      colour,
      totalmtrroll,
      thickness,
      filterserv,
      serviceitemList,
      service,
      serviceid,
      ServiceCategoryList,
      servicecategory,
      servicecatid,
      servicetax,
      servicecgst,
      servicesgst,
      serviceigst,
      servicegst,
      servicevat,
      servicecode,
      servicecost,
      servicecostwttax,
      servicetaxamount,
      serviceList,
      contracttype,
      contracttypeList,
      servicetotal,
      grandservicetotal,
      quotationpath,
      quotationfile,
      servicedescription,
      selectedFiles,
      progress,
      isError,
      message,
      recurringtype,
      contracttime,
      poitemstatus,
      poitemtype,
      currencylist,
      convertionreq,
      currencyid,
      currency,
      curconversionrate,
      basecurrency,
      tcs,
      tcsamount,
      tcsreq,
      importpo,
      reqquotationupload,
    } = this.state;
    let displaybutton;
    let displaysavebutton;
    let displayrequest;

    if (
      this.state.totalprice === "" ||
      this.state.totalprice === "0" ||
      this.state.supplierid === "" ||
      this.state.quantity === "" ||
      this.state.itemname === "" ||
      this.state.cost === ""
    ) {
      displaybutton = (
        <button
          className="btn btn-sm hovbuttonColour"
          name="totalprice"
          type="onSubmit"
        >
          Calculate Total Price
        </button>
      );
    } else {
      displaybutton = (
        <span
          as="button"
          className="btn btn-sm hovbuttonColour"
          name="addlist"
          onClick={this.handleList}
          type="button"
        >
          <i className="fas fa-plus" />
          Add to Order
        </span>
      );
    }
    if (this.state.potype === "2") {
      if (this.state.grandtotal === 0 || this.state.grandservicetotal === 0) {
        displaysavebutton = (
          <button className="btn btn-sm hovbuttonColour" disabled>
            <i className="fa fa-save mr-1" /> {"Save"}
          </button>
        );
      } else {
        displaysavebutton = (
          <button
            className="btn btn-sm hovbuttonColour"
            type="button"
            onClick={this.handleSubmit}
            disabled={this.state.sloader}
          >
            {this.state.sloader === true ? (
              <>
                <Spinner animation="border" size="sm" className="mr-1" />
                {"Saving..."}{" "}
              </>
            ) : (
              <>
                <i className="fa fa-save mr-1" /> {"Save"}
              </>
            )}
          </button>
        );
      }
    } else {
      if (this.state.grandtotal === 0) {
        displaysavebutton = (
          <button className="btn btn-sm hovbuttonColour" disabled>
            <i className="fa fa-save mr-1" /> {"Save"}
          </button>
        );
      } else {
        displaysavebutton = (
          <button
            className="btn btn-sm hovbuttonColour"
            type="button"
            onClick={this.handleSubmit}
            disabled={this.state.sloader}
          >
            {this.state.sloader === true ? (
              <>
                <Spinner animation="border" size="sm" className="mr-1" />
                {"Saving..."}{" "}
              </>
            ) : (
              <>
                <i className="fa fa-save mr-1" /> {"Save"}
              </>
            )}
          </button>
        );
      }
    }

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <span
                as="button"
                className="text-secondary mr-2 cursorPointer"
                onClick={() => this.props.navigate(-1)}
              >
                ❮ Back
              </span>
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchaseorder">Purchase Order</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.sloader}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className="card-body">
              <Formik
                initialValues={{
                  agent,
                  agentid,
                  agentreq,
                  agentsdata,
                  supcountry,
                  potype,
                  id,
                  itemid,
                  itemname,
                  cost,
                  hsn,
                  quantity,
                  tax,
                  cgst,
                  sgst,
                  totalprice,
                  itemprice,
                  supplierid,
                  supplier,
                  uomlist,
                  uom,
                  uomid,
                  addparams,
                  itemlist,
                  filteritemlist,
                  suppliers,
                  WarehouseList,
                  warehouse,
                  whid,
                  CategoryList,
                  category,
                  catid,
                  advance,
                  advancestatus,
                  paymentterms,
                  sizelist,
                  colourlist,
                  thicknesslist,
                  brandslist,
                  saleorderdata,
                  preitemList,
                  parameters,
                  size,
                  brand,
                  model,
                  colour,
                  totalmtrroll,
                  thickness,
                  filterserv,
                  serviceitemList,
                  service,
                  serviceid,
                  ServiceCategoryList,
                  servicecategory,
                  servicecatid,
                  servicetax,
                  servicecgst,
                  servicesgst,
                  serviceigst,
                  servicegst,
                  servicevat,
                  servicecost,
                  servicecostwttax,
                  servicetaxamount,
                  servicetotal,
                  servicecode,
                  serviceList,
                  contracttype,
                  contracttypeList,
                  servicedescription,
                  grandservicetotal,
                  selectedFiles,
                  quotationfile,
                  quotationpath,
                  progress,
                  recurringtype,
                  contracttime,
                  milestone: [
                    {
                      name: "",
                      expecteddate: "",
                      percentage: 0,
                      amount: 0,
                      amountwttax: 0,
                    },
                  ],
                  poitemstatus,
                  poitemtype,
                  currencylist,
                  convertionreq,
                  currencyid,
                  currency,
                  curconversionrate,
                  basecurrency,
                  tcs,
                  tcsamount,
                  tcsreq,
                  importpo,
                  reqquotationupload,
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form autoComplete="off">
                    {this.state.pagename === "Edit Purchase Order" ? (
                      ""
                    ) : (
                      <fieldset className="form-group col-md-12 p-0 m-0">
                        <div className="form-row p-0 m-0">
                          {this.state.pagename === "Edit Purchase Order" ||
                          production === "NO" ? (
                            ""
                          ) : (
                            <>
                              <fieldset
                                className={`form-group col-md-3 mr-2 shadow-sm text-center btn ${
                                  this.state.orderfrom === 0
                                    ? ""
                                    : " hovbuttonColour"
                                }`}
                                onClick={() => this.handlePOFrom(0)}
                                disabled={this.state.orderfrom === 0}
                              >
                                New Purchase Order
                              </fieldset>
                              <fieldset
                                className={`form-group col-md-3 shadow-sm btn text-center ${
                                  this.state.orderfrom === 1
                                    ? ""
                                    : " hovbuttonColour"
                                }`}
                                onClick={() => this.handlePOFrom(1)}
                                disabled={this.state.orderfrom === 1}
                              >
                                Store Indent Order
                              </fieldset>
                              <fieldset className="form-group col-md-12 p-0 m-0" />
                              {this.state.orderfrom === 1 ? (
                                <>
                                  <fieldset className="form-group col-md-4 mb-4">
                                    <Autocomplete
                                      id="subaccount"
                                      className="subacc-select"
                                      name="indentid"
                                      options={this.state.IndentIdList}
                                      getOptionLabel={(option) => `${option}`}
                                      value={
                                        values.indentid ? undefined : undefined
                                      }
                                      onChange={(e, value) => {
                                        setFieldValue("indentid", value);
                                      }}
                                      disabled={datalist.length !== 0}
                                      renderInput={(params) => (
                                        <FormikTextField
                                          {...params}
                                          className="form-control"
                                          type="text"
                                          name="indentid"
                                          onBlur={(e) => {
                                            this.handleIndent(e.target.value);
                                          }}
                                          label={`Select Indent No. (${this.state.indentid})`}
                                          variant="standard"
                                          fullWidth
                                        />
                                      )}
                                    />
                                  </fieldset>
                                  <fieldset className="form-group col-md-12 p-0 m-0" />
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          )}

                          {this.state.orderfrom === 1 ||
                          (this.state.settings.purchaseservice === "NO" &&
                          this.state.settings.purchasepands === "NO") ? (
                            ""
                          ) : (
                            <>
                              <fieldset className="form-group col-md-4 p-0 m-0">
                                <span className="mx-3">Products</span>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      className="sm"
                                      checked={this.state.pro}
                                      onChange={this.handleProductChange}
                                      name="checkedB"
                                    />
                                  }
                                />
                              </fieldset>
                              {this.state.settings.purchaseservice === "YES" ? (
                                <fieldset className="form-group col-md-4 p-0 m-0">
                                  <span className="mx-3">Services</span>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        className="sm"
                                        checked={this.state.serv}
                                        onChange={this.handleServiceChange}
                                        name="checkedB"
                                      />
                                    }
                                  />
                                </fieldset>
                              ) : (
                                ""
                              )}
                              {this.state.settings.purchasepands === "YES" ? (
                                <fieldset className="form-group col-md-4 p-0 m-0">
                                  <span className="mx-3">
                                    {" "}
                                    Products & Services
                                  </span>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        className="sm"
                                        checked={this.state.prosev}
                                        onChange={this.handleProSerChange}
                                        name="checkedB"
                                      />
                                    }
                                  />
                                </fieldset>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      </fieldset>
                    )}
                    <div className="row">
                      <div className="form-group col-md-12 m-0 p-0" />

                      <div className="form-group col-md-12">
                              <Accordion>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{backgroundColor: '#e8ecef'}}
                              >
                                <Typography>Advance options</Typography>
                              </AccordionSummary>
                                 <AccordionDetails>
                                
                                      <FieldArray
                                        name="addsettings"
                                        render={arrayHelpers => (
                                          <div className="form-group col-md-12">
                                            <div className="form-row">

                                      <fieldset className="form-group col-md-4 mt-2 m-0">
                                        <FormGroup row>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={this.state.porecurring}
                                                onChange={this.handleporecurring}
                                                name="porecurring"
                                                color="primary"
                                              />
                                            }
                                            label="Purchase Order Recurring Required?"
                                          />
                                        </FormGroup>
                                      </fieldset>
                                      {this.state.porecurring === true ? (
                                        <>
                                          <fieldset className="form-group col-md-2 m-0 p-0">
                                            <FormControl
                                              variant="standard"
                                              className="w-100"
                                            >
                                              <InputLabel id="demo-simple-select-standard-label">
                                                Select Recurring Duration
                                              </InputLabel>
                                              <Select
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                value={this.state.porecurringtype}
                                                onChange={this.handleporecurringtypeChange}
                                                label="Select Recurring Time"
                                              >
                                                {this.state.recurringtype.map(
                                                  (e, index) => (
                                                    <MenuItem value={e} key={index}>
                                                      {e}
                                                    </MenuItem>
                                                  )
                                                )}
                                              </Select>
                                            </FormControl>
                                          </fieldset>
                                          <fieldset className="form-group col-md-2 m-0 pl-2">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                              <Grid container justifyContent="space-around">
                                                <DatePicker
                                                  variant="inline"
                                                  margin="none"
                                                  id="date-picker-dialog"
                                                  label="Recurring From"
                                                  format="dd/MM/yyyy"
                                                  minDate={new Date()}
                                                  value={this.state.porecurringfrom}
                                                  onChange={this.handleRecurringFrom}
                                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                                />
                                              </Grid>
                                            </LocalizationProvider>
                                          </fieldset>
                                          <fieldset className="form-group col-md-2 m-0 pl-2">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                              <Grid container justifyContent="space-around">
                                                <DatePicker
                                                  variant="inline"
                                                  margin="none"
                                                  id="date-picker-dialog"
                                                  label="Recurring Till"
                                                  format="dd/MM/yyyy"
                                                  minDate={new Date()}
                                                  value={this.state.porecurringtill}
                                                  onChange={this.handleRecurringTill}
                                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                                />
                                              </Grid>
                                            </LocalizationProvider>
                                          </fieldset>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      </div>
                                      <fieldset className="form-group col-md-12 m-0" />
                                      <div className="form-row">
                                      
                                      <fieldset
                                        className="form-group col-md-4 mt-2 m-0"
                                        disabled={
                                          this.state.grandtotal !== 0 ||
                                          this.state.grandservicetotal !== 0
                                        }
                                      >
                                        <FormGroup row>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={this.state.convertionreq}
                                                onChange={this.handlemulticurrencyrequired}
                                                name="taxrequired"
                                                color="primary"
                                              />
                                            }
                                            label="Multi Currency Required?"
                                          />
                                        </FormGroup>
                                      </fieldset>

                                      {this.state.convertionreq === true ? (
                                        <>
                                          <fieldset className="form-group col-md-2">
                                            <FormikTextField
                                              className="form-control"
                                              type="text"
                                              value={this.state.basecurrency}
                                              name="basecurrency"
                                              label="Base Currency"
                                              variant="standard"
                                              fullWidth
                                              disabled
                                            />{" "}
                                          </fieldset>

                                          <fieldset className="form-group col-md-2">
                                            <Autocomplete
                                              id="subaccount"
                                              className="subacc-select"
                                              name="currency"
                                              options={currencylist}
                                              getOptionLabel={(option) => option.currency}
                                              
                                              getOptionSelected={(option) =>
                                                option.currency
                                              }
                                              value={
                                                values.currency ? undefined : undefined
                                              }
                                              onChange={(e, value) => {
                                                setFieldValue("currency", value);
                                              }}
                                              disabled={
                                                this.state.grandtotal !== 0 ||
                                                this.state.grandservicetotal !== 0
                                              }
                                              renderInput={(params) => (
                                                <FormikTextField
                                                  {...params}
                                                  className="form-control"
                                                  type="text"
                                                  name="currency"
                                                  onBlur={(e) => {
                                                    this.handleCurrency(
                                                      e.target.value,
                                                      values
                                                    );
                                                  }}
                                                  label={`Select Currency (${this.state.currency})`}
                                                  variant="standard"
                                                  fullWidth
                                                />
                                              )}
                                            />
                                          </fieldset>

                                          {this.state.currency ===
                                            this.state.basecurrency ||
                                          this.state.currency === undefined ||
                                          this.state.currency === "" ? (
                                            ""
                                          ) : (
                                            <fieldset className="form-group col-md-2">
                                              <FormControl
                                                fullWidth
                                                sx={{ m: 1 }}
                                                variant="standard"
                                              >
                                                <InputLabel htmlFor="standard-adornment-amount">{`Enter Conversion rate`}</InputLabel>
                                                <Input
                                                  id="standard-adornment-amount"
                                                  value={this.state.curconversionrate}
                                                  type="number"
                                                  onChange={this.handleCurconversionrate}
                                                  onFocus={this.oncurrencyconvtfocus}
                                                  startAdornment={
                                                    <InputAdornment position="start">
                                                      1 {this.state.basecurrency} =
                                                    </InputAdornment>
                                                  }
                                                  endAdornment={
                                                    <InputAdornment position="end">
                                                      {" "}
                                                      {this.state.currency}
                                                    </InputAdornment>
                                                  }
                                                  disabled={
                                                    this.state.grandtotal !== 0 ||
                                                    this.state.grandservicetotal !== 0
                                                  }
                                                />
                                              </FormControl>
                                            </fieldset>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      </div>
                                      <fieldset className="form-group col-md-12 m-0" />
                                      <div className="form-row">

                                      <fieldset
                                        className="form-group col-md-4 mt-2 m-0"
                                        disabled={
                                          this.state.grandtotal !== 0 ||
                                          this.state.itemname !== "" ||
                                          this.state.service !== ""
                                        }
                                      >
                                        <FormGroup row>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={this.state.taxrequired}
                                                onChange={this.handletaxrequired}
                                                disabled={
                                                  this.state.grandtotal !== 0 ||
                                                  this.state.itemname !== "" ||
                                                  this.state.service !== ""
                                                }
                                                name="taxrequired"
                                                color="primary"
                                              />
                                            }
                                            label="Tax Required?"
                                          />
                                        </FormGroup>
                                      </fieldset>
                                      {this.state.taxrequired === true ? (
                                        <>
                                          <fieldset className="form-group col-md-2 m-0">
                                            <FormControl
                                              variant="standard"
                                              className="w-100"
                                            >
                                              <InputLabel id="demo-simple-select-standard-label">
                                                Tax Type :&nbsp; {this.state.taxtype}
                                              </InputLabel>
                                              {/* <Select
                                              labelId="demo-simple-select-standard-label"
                                              id="demo-simple-select-standard"
                                              value={this.state.taxtype}
                                              onChange={this.handleTaxTypeChange}
                                              label="Age"
                                              disabled={this.state.grandtotal !== 0 || (this.state.itemname !== "" ||this.state.service !== "")}
                                            >
                                              <MenuItem value={"GST"}>GST</MenuItem>
                                              <MenuItem value={"VAT"}>VAT</MenuItem>
                                            </Select> */}
                                            </FormControl>
                                          </fieldset>
                                          {this.state.taxtype === "GST" &&
                                          ccountry === "India" &&
                                          this.state.supplierid !== "" &&
                                          this.state.supcountry === "India" ? (
                                            <fieldset className="form-group col-md-6 m-0">
                                              <FormControl
                                                variant="standard"
                                                className="w-100"
                                              >
                                                <InputLabel id="demo-simple-select-standard-label">
                                                  GST Type :&nbsp;{" "}
                                                  {this.state.gsttype === false
                                                    ? "Intra State (With in the State)"
                                                    : "Inter State (Outside the State)"}
                                                </InputLabel>
                                              </FormControl>
                                              {/* GST Type
                                            {this.state.gsttype === false ? <span className="text-info">Intra State</span> : 'Intra State'} &nbsp; &nbsp;
                                            {this.state.gsttype === true ? <span className="text-info"> Inter State</span> : 'Inter State'} */}
                                            </fieldset>
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      </div>
                                      <fieldset className="form-group col-md-12 m-0" />
                                      <div className="form-row">
                                      <fieldset
                                        className="form-group col-md-4 mt-2 m-0"
                                        disabled={this.state.grandtotal !== 0}
                                      >
                                        <FormGroup row>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={this.state.importpo}
                                                onChange={this.handleimportpurchase}
                                                name="importpo"
                                                color="primary"
                                              />
                                            }
                                            label="Import Purchase?"
                                          />
                                        </FormGroup>
                                      </fieldset>
                                      </div>
                                  </div>
                                        )}
                                      />
                                   </AccordionDetails>
                              </Accordion>
                            </div>
                    </div>

                    {this.state.potype === "1" ? (
                      ""
                    ) : (
                      <>
                        <div className="form-row">
                          {this.state.orderfrom === 1 &&
                          this.state.indentitems.length !== 0 ? (
                            <TableContainer>
                              <Table className="table-sm">
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Itemanme</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>UOM</TableCell>
                                    <TableCell>Action</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.indentitems.map((e, index) => (
                                    <TableRow key={index}>
                                      <TableCell>{e.itemname}</TableCell>
                                      <TableCell>{e.quantity}</TableCell>
                                      <TableCell>{e.uom}</TableCell>
                                      <TableCell>
                                        {e.quantity === 0 ? (
                                          ""
                                        ) : (
                                          <button
                                            as="button"
                                            className="btn btn-sm hovbuttonColour"
                                            onClick={() =>
                                              this.handleIndentItem(e)
                                            }
                                          >
                                            Select
                                          </button>
                                        )}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          ) : (
                            ""
                          )}
                          <fieldset hidden className="form-group col-md-6">
                            <label>Id</label>
                            <Field
                              className="form-control"
                              type="text"
                              name="itemid"
                              placeholder="Id"
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-12 m-0" />
                          {this.state.currency === this.state.basecurrency|| this.state.currency === undefined || this.state.currency === ''?'':
                        <fieldset className="form-group col-md-12 m-0">
                          <label className="text-red">Note : Please Enter/Check Convertion rate in advance options.
                            </label>
                        </fieldset>}
                          <fieldset className="form-group col-md-3 m-0">
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.agentreq}
                                onChange={() =>
                                  this.setState({
                                    agentreq: !this.state.agentreq,
                                    agent: "",
                                    agentid: "",
                                  })
                                }
                                name="ordertype"
                                color="primary"
                                // disabled={this.state.orderfrom === 1}
                              />
                            }
                            label="Through Agent?"
                          />
                        </FormGroup>
                      </fieldset>
                      {this.state.agentreq === true ? (
                        <fieldset className="form-group col-md-3 mb-4 mr-4">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="agent"
                            options={agentsdata}
                            getOptionLabel={(option) => option.name}
                            
                            
                            value={values.agent ? undefined : undefined}
                            onChange={this.handleAgent}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control"
                                type="text"
                                name="agent"
                                value={this.state.agent}
                                label={`Select Agent (${this.state.agent})`}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>
                      ) : (
                        ""
                      )}
                      <fieldset className="form-group col-md-3">
                        <FormControl variant="standard" className="w-100">
                          <InputLabel id="demo-simple-select-standard-label">
                            Closing Stock:&nbsp;
                            {this.state.inventorydata.length === 0? 0: (this.state.inventorydata.reduce((a, v) => (a = a + v.currentStock), 0) - this.state.blockedsalesquantity + this.state.blockedpurchasequantity).toFixed(3)}
                          </InputLabel>
                        </FormControl>
                      </fieldset>
                      <fieldset className="form-group col-md-12 m-0" />

                          {(this.state.grandtotal === 0 && this.state.orderfrom === 0 && WarehouseList.length!==1) ? (
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="warehouse"
                                    options={WarehouseList}
                                    getOptionLabel={(option) => option.name}
                                    
                                    
                                    value={
                                      values.warehouse ? undefined : undefined
                                    }
                                    onChange={this.handleWarehouse}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="warehouse"
                                        value={this.state.warehouse}
                                        label={`Select Org Unit (${this.state.warehouse})`}
                                        variant="standard"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>
                              ) : (
                                <fieldset className="form-group col-md-3">
                                  <FormikTextField
                                    className="form-control"
                                    type="text"
                                    name="warehouse"
                                    value={this.state.warehouse}
                                    label="Org Unit"
                                    variant="standard"
                                    fullWidth
                                    disabled
                                  />
                                </fieldset>
                              )}
                           

                              {this.state.grandtotal === 0 ? (
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="supplier"
                                    options={suppliers}
                                    getOptionLabel={(option) =>
                                      option.companyname
                                    }
                                    value={
                                      values.supplier ? undefined : undefined
                                    }
                                    onChange={this.handlesupplier}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="supplier"
                                        value={this.state.supplier}
                                        label={`Select  Supplier(${this.state.supplier})`}
                                        variant="standard"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>
                              ) : (
                                <fieldset className="form-group col-md-3">
                                  <FormikTextField
                                    className="form-control"
                                    type="text"
                                    name="supplier"
                                    value={this.state.supplier}
                                    label="Supplier"
                                    variant="standard"
                                    fullWidth
                                    disabled
                                  />
                                </fieldset>
                              )}

                          <fieldset className="form-group col-md-3">
                            {this.state.orderfrom === 0 ? (
                              <Autocomplete
                                id="subaccount"
                                className="subacc-select"
                                name="category"
                                options={CategoryList}
                                getOptionLabel={(option) => option.category}
                                
                                
                                value={values.category ? undefined : undefined}
                                onChange={this.handleCategory}
                                renderInput={(params) => (
                                  <FormikTextField
                                    {...params}
                                    className="form-control"
                                    type="text"
                                    name="category"
                                    label={`Select Category (${this.state.category})`}
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                              />
                            ) : (
                              <FormikTextField
                                className="form-control"
                                type="text"
                                name="category"
                                value={this.state.category}
                                label="Category"
                                variant="standard"
                                fullWidth
                                disabled
                              />
                            )}
                          </fieldset>

                          <fieldset className="form-group col-md-3">
                            {this.state.orderfrom === 0 ? (
                              <Autocomplete
                                id="subaccount"
                                className="subacc-select"
                                name="itemname"
                                options={filteritemlist}
                                getOptionLabel={(option) => option.name}
                                
                                
                                value={values.itemname ? undefined : undefined}
                                onChange={this.handleSelect}
                                renderInput={(params) => (
                                  <FormikTextField
                                    {...params}
                                    className="form-control"
                                    type="text"
                                    name="itemname"
                                    value={this.state.itemname}
                                    label={`Select Item (${this.state.itemname})`}
                                    variant="standard"
                                    fullWidth
                                  />
                                )}
                              />
                            ) : (
                              <FormikTextField
                                className="form-control"
                                type="text"
                                name="itemname"
                                value={this.state.itemname}
                                label="Item"
                                variant="standard"
                                fullWidth
                                disabled
                              />
                            )}
                          </fieldset>

                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              id="cost"
                              className="form-control"
                              type="number"
                              name="cost"
                              label="Price"
                              onChange={(e) =>
                                this.setState({
                                  cost: e.target.value===undefined || e.target.value===null ? 0 : e.target.value,
                                  totalprice: "",
                                  availabelcredit: this.state.creditvalue,
                                })
                              }
                              onFocus={this.oncostfocus}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              id="quantity"
                              className="form-control"
                              type="number"
                              name="quantity"
                              label="Quantity"
                              onChange={(e) =>
                                this.setState({
                                  quantity: e.target.value,
                                  totalprice: "",
                                  availabelcredit: this.state.creditvalue,
                                })
                              }
                              onFocus={this.onquantityfocus}
                            />
                          </fieldset>

                          {this.state.taxrequired === true ? (
                            <>
                              <fieldset className="form-group col-md-3">
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="hsn"
                                  label="Search HSN"
                                  value={this.state.hsn}
                                  disabled
                                />
                              </fieldset>
                              {this.state.taxtype === "GST" ? (
                                ccountry === "India" && importpo === false ? (
                                  this.state.gsttype === false ? (
                                    <>
                                      <fieldset className="form-group col-md-3">
                                        <FormikTextField
                                          className="form-control"
                                          type="number"
                                          name="cgst"
                                          label="CGST (%)"
                                          value={this.state.cgst}
                                          disabled
                                        />
                                      </fieldset>
                                      <fieldset className="form-group col-md-3">
                                        <FormikTextField
                                          className="form-control"
                                          type="number"
                                          name="sgst"
                                          label="SGST (%)"
                                          value={this.state.sgst}
                                          disabled
                                        />
                                      </fieldset>
                                    </>
                                  ) : (
                                    <fieldset className="form-group col-md-3">
                                      <FormikTextField
                                        className="form-control"
                                        type="number"
                                        name="tax"
                                        label="IGST (%)"
                                        value={this.state.tax}
                                        disabled
                                      />
                                    </fieldset>
                                  )
                                ) : (
                                  <fieldset className="form-group col-md-3">
                                    <FormikTextField
                                      className="form-control"
                                      type="number"
                                      name="tax"
                                      label="GST (%)"
                                      value={this.state.tax}
                                      disabled
                                    />
                                  </fieldset>
                                )
                              ) : (
                                <fieldset className="form-group col-md-3">
                                  <FormikTextField
                                    className="form-control"
                                    type="number"
                                    name="tax"
                                    label="VAT (%)"
                                    value={this.state.tax}
                                    disabled
                                  />
                                </fieldset>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control "
                              type="text"
                              name="uom"
                              value={this.state.uom}
                              label="UOM"
                              variant="standard"
                              fullWidth
                              disabled
                            />
                            {/* } */}
                          </fieldset>
                          <fieldset className="form-group col-md-3 ">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="brand"
                              label="Brand"
                              value={this.state.brand}
                              disabled
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-3 ">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="model"
                              label="Model"
                              value={this.state.model}
                              disabled
                            />
                          </fieldset>
                          {this.state.addparams === "True" ? (
                            <>
                              <fieldset className="form-group col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="size"
                                  options={sizelist}
                                  getOptionLabel={(option) => option}
                                  
                                  
                                  value={values.size ? undefined : undefined}
                                  onChange={(e, value) => {
                                    setFieldValue("size", value);
                                  }}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="size"
                                      value={this.state.size}
                                      onBlur={(e) => {
                                        this.handleSize(e.target.value, values);
                                      }}
                                      label={`Select Size (${
                                        this.state.size === null ||
                                        this.state.size === undefined
                                          ? ""
                                          : this.state.size
                                      })`}
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>

                              <fieldset className="form-group col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="colour"
                                  options={colourlist}
                                  getOptionLabel={(option) => option}
                                  
                                  
                                  value={values.colour ? undefined : undefined}
                                  onChange={(e, value) => {
                                    setFieldValue("colour", value);
                                  }}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="colour"
                                      value={this.state.colour}
                                      onBlur={(e) => {
                                        this.handleColour(
                                          e.target.value,
                                          values
                                        );
                                      }}
                                      label={`Select Colour (${
                                        this.state.colour === null ||
                                        this.state.colour === undefined
                                          ? ""
                                          : this.state.colour
                                      })`}
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>

                              <fieldset className="form-group col-md-3 ">
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="thikness"
                                  value={this.state.thickness}
                                  onChange={(e) =>
                                    this.setState({ thickness: e.target.value })
                                  }
                                  label="Thickness"
                                  variant="standard"
                                  fullWidth
                                />
                              </fieldset>

                              <fieldset className="form-group col-md-3 ">
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="totalmtrroll"
                                  value={this.state.totalmtrroll}
                                  onChange={(e) =>
                                    this.setState({
                                      totalmtrroll: e.target.value,
                                    })
                                  }
                                  label="TotalMtrRoll"
                                  variant="standard"
                                  fullWidth
                                />
                              </fieldset>
                            </>
                          ) : (
                            ""
                          )}

                          <fieldset className="form-group col-md-12" />
                          <fieldset className="col-md-12">
                            <div className="row">
                              <FormLabel id="demo-row-radio-buttons-group-label" className="mt-2 mr-3">
                                Item For :
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={this.state.poitemstatus}
                                onChange={this.handlepoitemstatus}
                              >
                                {this.state.itemsfor.map((e, index) => {
                                  return (
                                    <FormControlLabel
                                      key={e.index}
                                      value={e.id}
                                      control={
                                        <Radio style={{ color: "#152f5f" }} />
                                      }
                                      label={e.name}
                                      disabled={this.state.orderfrom === 1}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            </div>
                          </fieldset>

                            {this.state.poitemstatus === "0" ? (
                          <fieldset className="col-md-6">
                              <div className="row">
                                <FormLabel id="demo-row-radio-buttons-group-label" className="mt-2 mr-3">
                                  Internal Use For:
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  value={this.state.poitemtype}
                                  onChange={this.handleChange}
                                >
                                  {this.state.Internalusefor.map((e, index) => {
                                    return (
                                      <FormControlLabel
                                        key={index}
                                        value={e.id}
                                        control={
                                          <Radio
                                            style={{
                                              color: "#152f5f",
                                            }}
                                          />
                                        }
                                        label={e.name}
                                      />
                                    );
                                  })}
                                </RadioGroup>
                              </div>
                          </fieldset>
                            ) : (
                              ""
                            )}
                          {this.state.poitemstatus === "0" && this.state.poitemtype === "1"?
                                    <fieldset className="col-md-6">
                                      <div className="row">
                                        <FormLabel id="demo-row-radio-buttons-group-label" className="mt-2 mr-3">
                                          Asset Type :
                                        </FormLabel>
                                        <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="row-radio-buttons-group"
                                          value={this.state.assettype}
                                          onChange={this.handleAssetTypeChange}
                                          
                                        >
                                          {this.state.assettypes.map((e) => {
                                            return (
                                              <FormControlLabel
                                                key={e.id}
                                                value={e.id}
                                                control={
                                                  <Radio
                                                    style={{ color: "#152f5f" }}
                                                  />
                                                }
                                                label={e.name}
                                              />
                                            );
                                          })}
                                        </RadioGroup>
                                      </div>
                                    </fieldset>
                                    :''}

                          <fieldset className="form-group col-md-12" />
                          <fieldset className="form-group col-md-3">
                            <span>Total Price</span>
                            <span>
                              : &nbsp;
                              {this.state.totalprice === "" ? (
                                "0"
                              ) : (
                                <NumericFormat
                                  className="pr-3"
                                  displayType={"text"}
                                  value={this.state.totalprice}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              )}
                            </span>
                          </fieldset>
                        </div>
                        {displaybutton}
                        {this.state.listData.length === 0 ? (
                          ""
                        ) : (
                          <TableContainer className="mt-4">
                            <Typography>Purchase Items List</Typography>
                            <Table className="table-sm">
                              <TableHead>
                                <TableRow style={{ textAlign: "center" }}>
                                  <TableCell></TableCell>
                                  <TableCell className="text-center">
                                    Item Name
                                  </TableCell>
                                  {this.state.taxrequired === true ? (
                                    <TableCell className="text-center">
                                      HSN
                                    </TableCell>
                                  ) : (
                                    ""
                                  )}
                                  <TableCell className="text-center">
                                    Unit Price
                                  </TableCell>
                                  {this.state.currency ===
                                  this.state.basecurrency ? (
                                    ""
                                  ) : (
                                    <TableCell className="text-center">
                                      Unit Price({this.state.currency})
                                    </TableCell>
                                  )}
                                  <TableCell className="text-center">
                                    Quantity
                                  </TableCell>
                                  <TableCell className="text-center">
                                    UOM
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Item For
                                  </TableCell>
                                  <TableCell className="text-center">
                                    Total Price
                                  </TableCell>
                                  {this.state.currency ===
                                  this.state.basecurrency ? (
                                    ""
                                  ) : (
                                    <TableCell className="text-center">
                                      Total Price({this.state.currency})
                                    </TableCell>
                                  )}

                                  {this.state.taxrequired === true ? (
                                    <>
                                      {this.state.taxtype === "GST" &&
                                      ccountry === "India" ? (
                                        this.state.gsttype === false ? (
                                          <>
                                            <TableCell className="text-center">
                                              CGST
                                            </TableCell>
                                            <TableCell className="text-center">
                                              SGST
                                            </TableCell>
                                            {this.state.currency ===
                                            this.state.basecurrency ? (
                                              ""
                                            ) : (
                                              <>
                                                {" "}
                                                <TableCell className="text-center">
                                                  CGST(
                                                  {this.state.currency})
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  SGST(
                                                  {this.state.currency})
                                                </TableCell>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <TableCell className="text-center">
                                              IGST
                                            </TableCell>
                                            {this.state.currency ===
                                            this.state.basecurrency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-center">
                                                IGST(
                                                {this.state.currency})
                                              </TableCell>
                                            )}
                                          </>
                                        )
                                      ) : this.state.taxtype === "GST" ? (
                                        <>
                                          <TableCell className="text-center">
                                            GST
                                          </TableCell>
                                          {this.state.currency ===
                                          this.state.basecurrency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              GST({this.state.currency})
                                            </TableCell>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {" "}
                                          <TableCell className="text-center">
                                            VAT
                                          </TableCell>
                                          {this.state.currency ===
                                          this.state.basecurrency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              VAT({this.state.currency})
                                            </TableCell>
                                          )}
                                        </>
                                      )}
                                      <TableCell className="text-center">
                                        Total Price
                                      </TableCell>
                                      {this.state.currency ===
                                      this.state.basecurrency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-center">
                                          Total Price({this.state.currency})
                                        </TableCell>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <TableCell className="text-center">
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {this.state.listData.map((data, index) => (
                                  <React.Fragment>
                                    <TableRow key={index}>
                                      <TableCell className="text-center">
                                        {data.parameters === 0 ? (
                                          ""
                                        ) : (
                                          <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() =>
                                              this.setState({
                                                open: !this.state.open,
                                              })
                                            }
                                          >
                                            {this.state.open ? (
                                              <KeyboardArrowUpIcon />
                                            ) : (
                                              <KeyboardArrowDownIcon />
                                            )}
                                          </IconButton>
                                        )}
                                      </TableCell>
                                      <TableCell className="text-center">
                                        {data.name}
                                      </TableCell>
                                      {this.state.taxrequired === false ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-center">
                                          {data.hsn}
                                        </TableCell>
                                      )}
                                      <TableCell className="text-right">
                                        <NumericFormat
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          value={data.price}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                      {this.state.currency ===
                                      this.state.basecurrency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            value={
                                              data.price *
                                              this.state.curconversionrate
                                            }
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                      )}
                                      <TableCell className="text-center">
                                        {data.quantity}
                                      </TableCell>
                                      <TableCell className="text-center">
                                        {data.uom}
                                      </TableCell>
                                      <TableCell className="text-center">
                                        {data.poitemstatus === "0"
                                          ? "Internal Use"
                                          : data.poitemstatus === "1"
                                          ? "Reselling"
                                          : "Raw Material"}
                                      </TableCell>
                                      <TableCell className="text-right">
                                        <NumericFormat
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          value={data.price * data.quantity}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                      {this.state.currency ===
                                      this.state.basecurrency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            value={
                                              data.price *
                                              data.quantity *
                                              this.state.curconversionrate
                                            }
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                      )}
                                      {this.state.taxrequired === true ? (
                                        <>
                                          {this.state.taxtype === "GST" &&
                                          this.state.gsttype === false ? (
                                            <>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={data.taxamount / 2}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>&nbsp;({(data.tax / 2)}%)
                                              </TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={data.taxamount / 2}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>&nbsp;({(data.tax / 2)}%)
                                              </TableCell>
                                              {this.state.currency ===
                                              this.state.basecurrency ? (
                                                ""
                                              ) : (
                                                <>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        (data.taxamount / 2) *
                                                        this.state
                                                          .curconversionrate
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>&nbsp;({(data.tax / 2)}%)
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        (data.taxamount / 2) *
                                                        this.state
                                                          .curconversionrate
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>&nbsp;({(data.tax / 2)}%)
                                                  </TableCell>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={data.taxamount}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>&nbsp;({(data.tax)}%)
                                              </TableCell>
                                              {this.state.currency ===
                                              this.state.basecurrency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      data.taxamount *
                                                      this.state
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>&nbsp;({(data.tax)}%)
                                                </TableCell>
                                              )}
                                            </>
                                          )}
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={data.totalpricewtax}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.currency ===
                                          this.state.basecurrency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  data.totalpricewtax *
                                                  this.state.curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <TableCell className="text-center">
                                        <span
                                          as="button"
                                          data-tip
                                          data-for="delete"
                                          className="fa fa-trash text-danger cursorPointer"
                                          style={{ paddingLeft: "7px" }}
                                          onClick={() =>
                                            this.removeItemFromList(index)
                                          }
                                        ></span>
                                        {/* //<Tooltip
                                          id="delete"
                                          place="top"
                                          effect="solid"
                                        >
                                          Delete
                                        </Tooltip> */}
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell
                                        style={{
                                          paddingBottom: 0,
                                          paddingTop: 0,
                                        }}
                                        colSpan={6}
                                      >
                                        <Collapse
                                          in={this.state.open}
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <Box margin={1}>
                                            <Table
                                              size="small"
                                              aria-label="purchases"
                                            >
                                              <TableHead>
                                                <TableRow>
                                                  <TableCell align="center">
                                                    Brand
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Colour
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Size
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    Thickness
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    TotalMtrRoll
                                                  </TableCell>
                                                  {/* <TableCell align="center">Descreption</TableCell> */}
                                                </TableRow>
                                              </TableHead>
                                              <TableBody>
                                                <TableRow>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                    align="center"
                                                  >
                                                    {data.brand}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    {data.colour}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    {data.size}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    {data.thickness}
                                                  </TableCell>
                                                  <TableCell align="center">
                                                    {data.totalmtrroll}
                                                  </TableCell>
                                                  {/* <TableCell  align="center">{data.description}</TableCell> */}
                                                </TableRow>
                                              </TableBody>
                                            </Table>
                                          </Box>
                                        </Collapse>
                                      </TableCell>
                                    </TableRow>
                                  </React.Fragment>
                                ))}
                                <TableRow>
                                  <TableCell />
                                  <TableCell className="text-center">
                                    Total
                                  </TableCell>
                                  {this.state.taxrequired === false ? (
                                    ""
                                  ) : (
                                    <TableCell></TableCell>
                                  )}
                                  {this.state.currency ===
                                  this.state.basecurrency ? (
                                    ""
                                  ) : (
                                    <TableCell></TableCell>
                                  )}
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell className="text-right">
                                    {this.state.grandtotal === "" ? (
                                      "0"
                                    ) : (
                                      <NumericFormat
                                        displayType={"text"}
                                        value={
                                          this.state.grandtotal -
                                          this.state.totaltaxamount
                                        }
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    )}
                                  </TableCell>
                                  {this.state.currency ===
                                  this.state.basecurrency ? (
                                    ""
                                  ) : (
                                    <TableCell className="text-right">
                                      {this.state.grandtotal === "" ? (
                                        "0"
                                      ) : (
                                        <NumericFormat
                                          displayType={"text"}
                                          value={
                                            (this.state.grandtotal -
                                              this.state.totaltaxamount) *
                                            this.state.curconversionrate
                                          }
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      )}
                                    </TableCell>
                                  )}
                                  {this.state.taxrequired === true ? (
                                    <>
                                      {this.state.taxtype === "GST" &&
                                      this.state.gsttype === false ? (
                                        <>
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.totaltaxamount / 2
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.totaltaxamount / 2
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                        </>
                                      ) : (
                                        <TableCell className="text-right">
                                          <NumericFormat
                                            displayType={"text"}
                                            value={this.state.totaltaxamount}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                      )}
                                      {this.state.currency ===
                                      this.state.basecurrency ? (
                                        ""
                                      ) : (
                                        <>
                                          {this.state.taxtype === "GST" &&
                                          this.state.gsttype === false ? (
                                            <>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    (this.state.totaltaxamount /
                                                      2) *
                                                    this.state.curconversionrate
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    (this.state.totaltaxamount /
                                                      2) *
                                                    this.state.curconversionrate
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                            </>
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  this.state.totaltaxamount *
                                                  this.state.curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                        </>
                                      )}
                                      <TableCell className="text-right">
                                        {this.state.grandtotal === "" ? (
                                          "0"
                                        ) : (
                                          <NumericFormat
                                            displayType={"text"}
                                            value={this.state.grandtotal}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        )}
                                      </TableCell>
                                      {this.state.currency ===
                                      this.state.basecurrency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-right">
                                          {this.state.grandtotal === "" ? (
                                            "0"
                                          ) : (
                                            <NumericFormat
                                              displayType={"text"}
                                              value={
                                                this.state.grandtotal *
                                                this.state.curconversionrate
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          )}
                                        </TableCell>
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <TableCell></TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                        <fieldset className="form-group col-md-12" />
                      </>
                    )}

                    {this.state.potype === "0" ? (
                      ""
                    ) : (
                      <>
                        <div className="form-row">
                          {this.state.potype === "1" ? (
                            <>
                              {this.state.grandservicetotal === 0 ? (
                                <fieldset className="form-group col-md-6">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="supplier"
                                    options={suppliers}
                                    getOptionLabel={(option) =>
                                      option.companyname
                                    }
                                    value={
                                      values.supplier ? undefined : undefined
                                    }
                                    onChange={this.handlesupplier}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="supplier"
                                        value={this.state.supplier}
                                        label={`Select  Supplier(${this.state.supplier})`}
                                        variant="standard"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>
                              ) : (
                                <fieldset
                                  className="form-group col-md-6"
                                  disabled
                                >
                                  <FormikTextField
                                    className="form-control"
                                    type="text"
                                    name="supplier"
                                    value={this.state.supplier}
                                    label="Supplier"
                                    variant="standard"
                                    fullWidth
                                  />
                                </fieldset>
                              )}
                            </>
                          ) : (
                            ""
                          )}

                          <fieldset className="form-group col-md-12 m-0" />

                          <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="servicecategory"
                              options={ServiceCategoryList}
                              getOptionLabel={(option) => option.category}
                              
                              
                              value={
                                values.servicecategory ? undefined : undefined
                              }
                              onChange={(e, value) => {
                                setFieldValue("servicecategory", value);
                              }}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="servicecategory"
                                  onBlur={(e) => {
                                    this.handleServicecategory(
                                      e.target.value,
                                      values
                                    );
                                  }}
                                  label="Select Service Category"
                                  variant="standard"
                                  fullWidth
                                />
                              )}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-3">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="service"
                              options={filterserv}
                              getOptionLabel={(option) => option.service}
                              value={values.service ? undefined : undefined}
                              onChange={(e, value) => {
                                setFieldValue("service", value);
                              }}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="service"
                                  onBlur={(e) => {
                                    this.handleService(e.target.value, values);
                                  }}
                                  label="Select Service"
                                  variant="standard"
                                  fullWidth
                                />
                              )}
                            />
                          </fieldset>
                          {this.state.taxrequired === true ? (
                            <fieldset className="form-group col-md-3">
                              <FormikTextField
                                className="form-control"
                                type="text"
                                name="servicecode"
                                label="Code"
                                value={this.state.servicecode}
                                disabled
                              />
                            </fieldset>
                          ) : (
                            ""
                          )}

                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              id="samount"
                              className="form-control"
                              type="number"
                              name="servicecost"
                              label="Amount"
                              onChange={this.handleServiceAmountChange}
                              onFocus={this.onsamountfocus}
                              // onBlur={(e)=>setFieldValue(`servicecostwttax`,parseFloat(e.target.value)+((servicetax/100)*parseInt(e.target.value)))}
                            />
                          </fieldset>
                          {this.state.taxrequired === true ? (
                            <>
                              {this.state.taxtype === "GST" ? (
                                <>
                                  {ccountry === "India" ? (
                                    <>
                                      {this.state.gsttype === false ? (
                                        <>
                                          <fieldset className="form-group col-md-3">
                                            <FormikTextField
                                              className="form-control"
                                              type="number"
                                              name="servicecgst"
                                              label={`CGST (${
                                                this.state.servicetax === ""
                                                  ? 0
                                                  : this.state.servicetax / 2
                                              }%)`}
                                              value={this.state.servicecgst}
                                              disabled
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-3">
                                            <FormikTextField
                                              className="form-control"
                                              type="number"
                                              name="servicesgst"
                                              label={`SGST (${
                                                this.state.servicetax === ""
                                                  ? 0
                                                  : this.state.servicetax / 2
                                              }%)`}
                                              value={this.state.servicesgst}
                                              disabled
                                            />
                                          </fieldset>
                                        </>
                                      ) : (
                                        <fieldset className="form-group col-md-3">
                                          <FormikTextField
                                            className="form-control"
                                            type="number"
                                            name="serviceigst"
                                            label={`IGST (${
                                              this.state.servicetax === ""
                                                ? 0
                                                : this.state.servicetax
                                            }%)`}
                                            value={this.state.serviceigst}
                                            disabled
                                          />
                                        </fieldset>
                                      )}
                                    </>
                                  ) : (
                                    <fieldset className="form-group col-md-3">
                                      <FormikTextField
                                        className="form-control"
                                        type="number"
                                        name="servicegst"
                                        label={`GST (${
                                          this.state.servicetax === ""
                                            ? 0
                                            : this.state.servicetax
                                        }%)`}
                                        value={this.state.servicegst}
                                        disabled
                                      />
                                    </fieldset>
                                  )}
                                </>
                              ) : (
                                <fieldset className="form-group col-md-3">
                                  <FormikTextField
                                    className="form-control"
                                    type="number"
                                    name="servicevat"
                                    label={`VAT (${
                                      this.state.servicetax === ""
                                        ? 0
                                        : this.state.servicetax
                                    }%)`}
                                    value={this.state.servicevat}
                                    disabled
                                  />
                                </fieldset>
                              )}
                            </>
                          ) : (
                            ""
                          )}

                          {this.state.taxrequired === false ? (
                            ""
                          ) : (
                            <fieldset className="form-group col-md-3">
                              <FormikTextField
                                className="form-control"
                                type="number"
                                name="servicecostwttax"
                                label="Total Amount"
                                value={this.state.servicecostwttax}
                                disabled
                              />
                            </fieldset>
                          )}

                          {this.state.potype === "1" ? (
                            <>
                              <fieldset className="form-group col-md-3">
                                <Autocomplete
                                  id="subaccount"
                                  className="subacc-select"
                                  name="contracttype"
                                  options={contracttypeList}
                                  getOptionLabel={(option) => option}
                                  
                                  
                                  value={
                                    values.contracttype ? undefined : undefined
                                  }
                                  onChange={(e, value) => {
                                    setFieldValue("contracttype", value);
                                  }}
                                  renderInput={(params) => (
                                    <FormikTextField
                                      {...params}
                                      className="form-control"
                                      type="text"
                                      name="contracttype"
                                      onBlur={(e) =>
                                        this.setState({
                                          contracttype: e.target.value,
                                        })
                                      }
                                      label="Select Contract Type"
                                      variant="standard"
                                      fullWidth
                                    />
                                  )}
                                />
                              </fieldset>

                              {this.state.contracttype === "Recurring" ? (
                                <>
                                  <fieldset className="form-group col-md-3">
                                    <Autocomplete
                                      id="subaccount"
                                      className="subacc-select"
                                      name="contracttime"
                                      options={recurringtype}
                                      getOptionLabel={(option) => option}
                                      
                                      
                                      value={
                                        values.contracttime
                                          ? undefined
                                          : undefined
                                      }
                                      onChange={(e, value) => {
                                        setFieldValue("contracttime", value);
                                      }}
                                      renderInput={(params) => (
                                        <FormikTextField
                                          {...params}
                                          className="form-control"
                                          type="text"
                                          name="contracttime"
                                          onBlur={(e) => {
                                            this.setState({
                                              contracttime: e.target.value,
                                            });
                                          }}
                                          label="Select Contract Time"
                                          variant="standard"
                                          fullWidth
                                        />
                                      )}
                                    />
                                  </fieldset>
                                  <fieldset className="form-group col-md-3" />
                                  <fieldset className="mr-4">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Grid
                                        container
                                        justifyContent="space-around"
                                      >
                                        <DatePicker
                                          variant="inline"
                                          margin="normal"
                                          id="date-picker-dialog"
                                          label="Start Date"
                                          format="dd/MM/yyyy"
                                          minDate={new Date()}
                                          value={this.state.contractstartdate}
                                          onChange={this.handleStartDateChange}
                                          renderInput={(params) => <TextField {...params} variant="standard"/>}
                                        />
                                      </Grid>
                                    </LocalizationProvider>
                                  </fieldset>
                                  <fieldset>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Grid
                                        container
                                        justifyContent="space-around"
                                      >
                                        <DatePicker
                                          variant="inline"
                                          margin="normal"
                                          id="date-picker-dialog"
                                          label="End Date"
                                          format="dd/MM/yyyy"
                                          minDate={new Date()}
                                          value={this.state.contractenddate}
                                          onChange={this.handleEndDateChange}
                                          renderInput={(params) => <TextField {...params} variant="standard"/>}
                                        />
                                      </Grid>
                                    </LocalizationProvider>
                                  </fieldset>
                                </>
                              ) : (
                                ""
                              )}
                              <fieldset className="form-group col-md-12  mt-3 mb-5">
                                <FormikTextField
                                  name="description"
                                  className="form-control"
                                  multiline
                                  rows={2}
                                  label="Description"
                                  variant="outlined"
                                  value={this.state.servicedescription}
                                  onChange={(e) =>
                                    this.setState({
                                      servicedescription: e.target.value,
                                    })
                                  }
                                />
                              </fieldset>
                              {this.state.contracttype === "Milestone" ? (
                                <>
                                  <fieldset className="form-group col-md-8" />
                                  <div className="form-group col-md-12">
                                          <Accordion >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                style={{backgroundColor: '#e8ecef'}}
                              >
                                <Typography>Milestones</Typography>
                              </AccordionSummary>
                                 <AccordionDetails>
                                
                                            <FieldArray
                                              name="milestone"
                                              render={(arrayHelpers) => (
                                                <div className="form-group col-md-12">
                                                  {values.milestone.map(
                                                    (data, index) => (
                                                      <div
                                                        key={index}
                                                        className="form-row"
                                                      >
                                                        {/** both these conventions do the same */}
                                                        <fieldset className="form-group col-md-2">
                                                          <FormikTextField
                                                            className="form-control"
                                                            name={`milestone[${index}].name`}
                                                            label="Name"
                                                            variant="standard"
                                                            // onChange={() =>
                                                            //   this.setState({
                                                            //     milestonesavebtn: false,
                                                            //   })
                                                            // }
                                                            fullWidth
                                                          />
                                                        </fieldset>
                                                        <fieldset className="form-group col-md-2 ">
                                                          <FormikTextField
                                                            className="form-control"
                                                            id="amount"
                                                            type="number"
                                                            min={1}
                                                            max={100}
                                                            name={`milestone.${index}.percentage`}
                                                            label="Percentage (%)"
                                                            variant="standard"
                                                            // onChange={() =>
                                                            //   this.setState({
                                                            //     milestonesavebtn: false,
                                                            //   })
                                                            // }
                                                            onBlur={() =>
                                                              setFieldValue(
                                                                `milestone.${index}.amountwttax`,
                                                                (data.percentage /
                                                                  100) *
                                                                  (parseFloat(
                                                                    this.state
                                                                      .servicecost
                                                                  ) +
                                                                    (servicetax /
                                                                      100) *
                                                                      parseFloat(
                                                                        this
                                                                          .state
                                                                          .servicecost
                                                                      ))
                                                              ) &&
                                                              setFieldValue(
                                                                `milestone.${index}.amount`,
                                                                (data.percentage /
                                                                  100) *
                                                                  parseFloat(
                                                                    this.state
                                                                      .servicecost
                                                                  )
                                                              )
                                                            }
                                                            
                                                            onFocus={
                                                              this.onfocus
                                                            }
                                                            fullWidth
                                                          />
                                                        </fieldset>
                                                        <fieldset className="form-group col-md-2">
                                                          <FormikTextField
                                                            className="form-control"
                                                            type="number"
                                                            name={`milestone.${index}.amount`}
                                                            label="Amount"
                                                            variant="standard"
                                                            fullWidth
                                                            disabled
                                                          ></FormikTextField>
                                                        </fieldset>
                                                        <fieldset className="form-group col-md-2">
                                                          <FormikTextField
                                                            className="form-control"
                                                            type="number"
                                                            name={`milestone.${index}.amountwttax`}
                                                            label="Amount inc.Tax"
                                                            variant="standard"
                                                            fullWidth
                                                            disabled
                                                          ></FormikTextField>
                                                        </fieldset>
                                                        <fieldset className="form-group col-md-3">
                                                          <FormikTextField
                                                            id="date"
                                                            label="Expected Date"
                                                            name={`milestone.${index}.expecteddate`}
                                                            type="date"
                                                            minDate={new Date()}
                                                            // onChange={() =>
                                                            //   this.setState({
                                                            //     milestonesavebtn: false,
                                                            //   })
                                                            // }
                                                            className={
                                                              useStyles.textField
                                                            }
                                                            InputLabelProps={{
                                                              shrink: true,
                                                            }}
                                                          />
                                                        </fieldset>
                                                        {values.milestone
                                                          .length !== 1 ? (
                                                          <span
                                                            as="button"
                                                            className="fa fa-minus-square btn btn-sm hovbuttonColour text-danger mt-4"
                                                            onClick={() =>
                                                              arrayHelpers.remove(
                                                                index
                                                              )
                                                            }
                                                          ></span>
                                                        ) : (
                                                          ""
                                                        )}
                                                        <span
                                                          as="button"
                                                          className="fa fa-plus-square text-info mt-4 ml-3"
                                                          onClick={() =>
                                                            arrayHelpers.push({
                                                              name: "",
                                                              expecteddate: "",
                                                              percentage: 0,
                                                              amount: 0,
                                                              amountwttax: 0,
                                                            })
                                                          }
                                                        ></span>
                                                      </div>
                                                    )
                                                  )}
                                                  <p
                                                    className={`${
                                                      this.state
                                                        .percentagemessage ===
                                                      "Milestones Saved Scussesfully"
                                                        ? "text-green"
                                                        : "text-danger"
                                                    }`}
                                                  >
                                                    {
                                                      this.state
                                                        .percentagemessage
                                                    }
                                                  </p>
                                                  <span
                                                    as="button"
                                                    className="btn btn-sm float-right"
                                                    onClick={() =>
                                                      this.handlemilestonevalues(
                                                        values.milestone
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className={`${
                                                        this.state
                                                          .milestonesavebtn ===
                                                        false
                                                          ? "fa fa-save"
                                                          : "fa fa-check text-green"
                                                      } mr-1`}
                                                    />
                                                    Save
                                                  </span>
                                                </div>
                                              )}
                                            />
                                          </AccordionDetails>
                                    </Accordion>
                                    <fieldset className="form-group col-md-3 mt-3">
                                      Advance Payment :
                                      <FormControlLabel
                                        className="ml-2"
                                        control={
                                          <Checkbox
                                            className="sm"
                                            checked={this.state.advancestatus}
                                            onChange={this.handleadvancestatus}
                                            name="checkedB"
                                          />
                                        }
                                      />
                                    </fieldset>
                                    {this.state.advancestatus === false ? (
                                      ""
                                    ) : (
                                      <fieldset className="form-group col-md-3">
                                        <FormikTextField
                                          id="advance"
                                          className="form-control"
                                          type="number"
                                          name="advance"
                                          onChange={(e) => {
                                          
                                            if(this.state.adjustopen && this.state.adjustcredit){
                                            if(this.state.openingbalance + this.state.creditamount>this.state.grandtotal-this.state.totaltaxamount){
                                            this.setState({advance: e.target.value>this.state.totaltaxamount?this.state.totaltaxamount:e.target.value });
                                            }else{
                                              this.setState({advance: e.target.value>this.state.grandtotal-(this.state.openingbalance + this.state.creditamount)?this.state.grandtotal-(this.state.openingbalance + this.state.creditamount):e.target.value });
                                            }
                                          }else if(this.state.adjustopen){
                                            if(this.state.openingbalance >this.state.grandtotal-this.state.totaltaxamount){
                                              this.setState({advance: e.target.value>this.state.totaltaxamount?this.state.totaltaxamount:e.target.value });
                                              }else{
                                                this.setState({advance: e.target.value>this.state.grandtotal-(this.state.openingbalance)?this.state.grandtotal-(this.state.openingbalance ):e.target.value });
                                              }
                                          } else if(this.state.adjustcredit){
                                            if(this.state.creditamount>this.state.grandtotal-this.state.totaltaxamount){
                                              this.setState({advance: e.target.value>this.state.totaltaxamount?this.state.totaltaxamount:e.target.value });
                                              }else{
                                                this.setState({advance: e.target.value>this.state.grandtotal-(this.state.creditamount)?this.state.grandtotal-(this.state.creditamount):e.target.value });
                                              }
                                          }else{
                                            this.setState({advance: e.target.value>this.state.grandtotal?this.state.grandtotal:e.target.value });
                                          }
                                          }}
                                          onFocus={this.onadvancefocus}
                                          label="Advance Amount"
                                          variant="standard"
                                          fullWidth
                                        />
                                      </fieldset>
                                    )}
                                    {this.state.milestone.length === 0 ? (
                                      ""
                                    ) : (
                                      <TableContainer>
                                        <Table className="table-sm mt-4">
                                          <TableHead>
                                            <TableRow>MileStones</TableRow>
                                          </TableHead>
                                          <TableHead className="text-sm">
                                            <TableRow
                                              style={{ textAlign: "center" }}
                                            >
                                              <TableCell className="text-center">
                                                Name
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Expected&nbsp;Date
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Percentage
                                              </TableCell>
                                              <TableCell className="text-center">
                                                Amount
                                              </TableCell>
                                              {this.state.currency ===
                                              this.state.basecurrency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  Amount ({this.state.currency})
                                                </TableCell>
                                              )}
                                               <TableCell className="text-center">
                                                Total
                                              </TableCell>
                                              {this.state.currency ===
                                              this.state.basecurrency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  Total ({this.state.currency})
                                                </TableCell>
                                              )}
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {this.state.milestone.map(
                                              (data, index) => (
                                                <TableRow key={index}>
                                                  <TableCell className="text-center">
                                                    {data.name}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {moment(
                                                      data.expecteddate
                                                    ).format("DD-MM-YYYY")}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {data.percentage}
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      className="pr-3"
                                                      displayType={"text"}
                                                      value={data.amount}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  {this.state.currency ===
                                                  this.state.basecurrency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        className="pr-3"
                                                        displayType={"text"}
                                                        value={
                                                          data.amount *
                                                          this.state
                                                            .curconversionrate
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    </TableCell>
                                                  )}
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      className="pr-3"
                                                      displayType={"text"}
                                                      value={data.amountwttax}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  {this.state.currency ===
                                                  this.state.basecurrency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        className="pr-3"
                                                        displayType={"text"}
                                                        value={
                                                          data.amountwttax *
                                                          this.state
                                                            .curconversionrate
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    </TableCell>
                                                  )}
                                                </TableRow>
                                              )
                                            )}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    )}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              <fieldset className="form-group col-md-12" />
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.potype === "1" ? (
                            ""
                          ) : (
                            <>
                              <fieldset className="form-group col-md-12  mt-3 mb-5">
                                <FormikTextField
                                  name="description"
                                  className="form-control"
                                  multiline
                                  rows={2}
                                  label="Description"
                                  variant="outlined"
                                  value={this.state.servicedescription}
                                  onChange={(e) =>
                                    this.setState({
                                      servicedescription: e.target.value,
                                    })
                                  }
                                />
                              </fieldset>
                              <Button
                                type="button"
                                className="btn btn-sm hovbuttonColour shadow-sm text-white"
                                onClick={this.handleaddService}
                              >
                                Add Service
                              </Button>

                              <div className="form-group col-md-12 text-red text-sm">
                                {this.state.serviceerr}
                              </div>

                              <TableContainer>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow style={{ textAlign: "center" }}>
                                      <TableCell className="text-center">
                                        Service
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Service Category
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Service Cost
                                      </TableCell>
                                      {this.state.currency ===
                                      this.state.basecurrency ? (
                                        ""
                                      ) : (
                                        <TableCell className="text-center">
                                          Service Cost ({this.state.currency})
                                        </TableCell>
                                      )}
                                      {this.state.taxrequired === true ? (
                                        <>
                                          {this.state.taxtype === "GST" ? (
                                            ccountry === "India" ? (
                                              this.state.gsttype === false ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    CGST (%)
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    SGST (%)
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <TableCell className="text-center">
                                                  IGST (%)
                                                </TableCell>
                                              )
                                            ) : (
                                              <TableCell className="text-center">
                                                GST (%)
                                              </TableCell>
                                            )
                                          ) : (
                                            <TableCell className="text-center">
                                              VAT (%)
                                            </TableCell>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <TableCell className="text-center">
                                        Service Description
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Service Total
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.serviceList.map(
                                      (data, index) => (
                                        <TableRow key={index}>
                                          <TableCell className="text-center">
                                            {data.service}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {data.servicecategory}
                                          </TableCell>
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              value={data.servicecost}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.currency ===
                                          this.state.basecurrency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                value={
                                                  data.servicecost *
                                                  this.state.curconversionrate
                                                }
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}

                                          {this.state.taxrequired === true ? (
                                            <>
                                              {this.state.taxtype === "GST" ? (
                                                ccountry === "India" ? (
                                                  this.state.gsttype ===
                                                  false ? (
                                                    <>
                                                      <TableCell className="text-center">
                                                        {this.state.servicecgst}
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                        {this.state.servicesgst}
                                                      </TableCell>
                                                    </>
                                                  ) : (
                                                    <TableCell className="text-center">
                                                      {this.state.serviceigst}
                                                    </TableCell>
                                                  )
                                                ) : (
                                                  <TableCell className="text-center">
                                                    {this.state.servicegst}
                                                  </TableCell>
                                                )
                                              ) : (
                                                <TableCell className="text-center">
                                                  {this.state.servicevat}
                                                </TableCell>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          <TableCell className="text-center">
                                            {data.servicedescription}
                                          </TableCell>
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              value={data.servicetotal}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          <TableCell className="text-center">
                                            <span
                                              as="button"
                                              data-tip
                                              data-for="delete"
                                              className="fa fa-trash text-danger"
                                              style={{ paddingLeft: "7px" }}
                                              onClick={() =>
                                                this.removeServiceFromList(
                                                  index
                                                )
                                              }
                                            ></span>
                                            {/* //<Tooltip
                                              id="delete"
                                              place="top"
                                              effect="solid"
                                            >
                                              Delete
                                            </Tooltip> */}
                                          </TableCell>
                                        </TableRow>
                                      )
                                    )}
                                    <TableRow style={{ textAlign: "center" }}>
                                      <TableCell></TableCell>
                                      {this.state.taxrequired === true ? (
                                        <>
                                          {this.state.taxtype === "GST" ? (
                                            ccountry === "India" ? (
                                              this.state.gsttype === false ? (
                                                <>
                                                  <TableCell className="text-center"></TableCell>
                                                  <TableCell className="text-center"></TableCell>
                                                </>
                                              ) : (
                                                <TableCell className="text-center"></TableCell>
                                              )
                                            ) : (
                                              <TableCell className="text-center"></TableCell>
                                            )
                                          ) : (
                                            <TableCell className="text-center"></TableCell>
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      <TableCell></TableCell>
                                      <TableCell className="text-right">
                                        {this.state.grandservicetotal === "" ? (
                                          0
                                        ) : (
                                          <NumericFormat
                                            displayType={"text"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            value={this.state.grandservicetotal}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        )}
                                      </TableCell>
                                      <TableCell></TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          )}
                        </div>
                      </>
                    )}


                            <fieldset className="form-group col-md-12" />
                           
                        <div className="form-row">
                        {(this.state.openingbalance !==null && this.state.openingbalance !== undefined && this.state.openingbalance>0) || (this.state.creditamount !==null && this.state.creditamount !== undefined && this.state.creditamount>0)?
                            <FormLabel id="demo-row-radio-buttons-group-label" className="mt-2">
                                Adjust Amount From :
                              </FormLabel>
                              :""}
                              {(this.state.openingbalance !==null && this.state.openingbalance !== undefined && this.state.openingbalance>0)?<>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.adjustopen}
                                        onChange={()=>this.setState({adjustopen:!this.state.adjustopen})}
                                        name="adjustopen"
                                        color="primary"
                                      />
                                    }
                                    // disabled={this.state.openingbalance>(this.state.grandtotal - this.state.totaltaxamount)}
                                    label={`Opening Balance (${this.state.openingbalance})`}
                                  />
                                </FormGroup>
                              </>:''}

                            {(this.state.creditamount !==null && this.state.creditamount !== undefined && this.state.creditamount>0)?<>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.adjustcredit}
                                        onChange={()=>this.setState({adjustcredit:!this.state.adjustcredit})}
                                        name="adjustcredit"
                                        color="primary"
                                      />
                                    }
                                    label={`Debit Amount (${this.state.creditamount})`}
                                  />
                                </FormGroup>
                                    </>:""}
</div>
<div className="form-row">
<fieldset className="form-group col-md-3">

                          <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.customPOdate}
                                        onChange={this.handlecustomPODate}
                                        name="ordertype"
                                        color="primary"
                                      />
                                    }
                                    label="PO creation date"
                                  />
                                </FormGroup>
        </fieldset>
   
<fieldset className="form-group col-md-3">
  {/* <div className="form-row mr-4 mb-4"> */}
    {/* <fieldset style={{ marginLeft: "30px" }}> */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid  justifyContent="space-around">
          <DateTimePicker
           disableFuture
           showtodaybutton
           showcancelbutton
          //  views={["year", "month","date"]}
            variant="inline"
            id="date-picker-dialog"
           // label="Financial Year Start Date"
            // inputVariant="outlined"
            minDate={new Date(moment(financialyearstart).format("YYYY-MM-DD"))}
            maxDate={new Date(moment(financialyearend).format("YYYY-MM-DD"))}
            
            label={`PO Creation Date : ${this.state.customPOdate?"Custom":"Default"}`}
            format="dd/MM/yyyy HH:mm"
            value={this.state.createdAt}
             InputAdornmentProps={{ position: "start" }}
            onChange={this.handlecreatedAtDateChange}
            renderInput={(params) => <TextField {...params} variant="standard"/>}
            autoOk={true}
            fullWidth
            disabled={this.state.customPOdate===false}
          />
        </Grid>
      </LocalizationProvider>
    </fieldset>
    </div>
                    {this.state.potype === "1" ? (
                      ""
                    ) : (
                      <>
                        <div className="form-row mt-2">
                          <fieldset className="form-group col-md-4 m-0 text-md">
                            <Box sx={{ width: 250 }}>
                              <Typography id="input-slider" gutterBottom>
                                Payment Terms : ({this.state.paymentterms}) Days
                              </Typography>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs>
                                  <Slider
                                    value={
                                      typeof this.state.paymentterms ===
                                      "number"
                                        ? this.state.paymentterms
                                        : 0
                                    }
                                    onChange={this.handleSliderChange}
                                    aria-labelledby="input-slider"
                                    min={0}
                                    max={120}
                                    style={{ color: "#152f5f" }}
                                  />
                                </Grid>
                              </Grid>
                            </Box>
                          </fieldset>

                          {this.state.paymentterms === "0" ? (
                            ""
                          ) : (
                            <>
                              <fieldset className="form-group col-md-3 m-0">
                                <FormGroup row>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={this.state.advancestatus}
                                        onChange={this.handleadvancestatus}
                                        name="ordertype"
                                        color="primary"
                                      />
                                    }
                                    label="Advance Payment Required?"
                                  />
                                  </FormGroup>
                              </fieldset>
                              {this.state.advancestatus === true ? (
                                <fieldset className="form-group col-md-2 m-0">
                                  <FormikTextField
                                    className="form-control"
                                    type="number"
                                    name="advance"
                                    onChange={(e) => {
                                      
                                      if(this.state.adjustopen && this.state.adjustcredit){
                                      if(this.state.openingbalance + this.state.creditamount>this.state.grandtotal){
                                      this.setState({advance: e.target.value>this.state.totaltaxamount?this.state.totaltaxamount:e.target.value });
                                      }else{
                                        this.setState({advance: e.target.value>this.state.grandtotal-(this.state.openingbalance + this.state.creditamount)?this.state.grandtotal-(this.state.openingbalance + this.state.creditamount):e.target.value });
                                      }
                                    }else if(this.state.adjustopen){
                                      if(this.state.openingbalance >this.state.grandtotal){
                                        this.setState({advance: e.target.value>this.state.totaltaxamount?this.state.totaltaxamount:e.target.value });
                                        }else{
                                          this.setState({advance: e.target.value>this.state.grandtotal-(this.state.openingbalance)?this.state.grandtotal-(this.state.openingbalance ):e.target.value });
                                        }
                                    } else if(this.state.adjustcredit){
                                      if(this.state.creditamount>this.state.grandtotal){
                                        this.setState({advance: e.target.value>this.state.totaltaxamount?this.state.totaltaxamount:e.target.value });
                                        }else{
                                          this.setState({advance: e.target.value>this.state.grandtotal-(this.state.creditamount)?this.state.grandtotal-(this.state.creditamount):e.target.value });
                                        }
                                    }else{
                                      this.setState({advance: e.target.value>this.state.grandtotal?this.state.grandtotal:e.target.value });
                                    }
                                    }}
                                    label="Advance Amount"
                                    variant="standard"
                                    fullWidth
                                  />
                                </fieldset>
                              ) : (
                               ''
                              )}
                            </>
                          )}
                        </div>
                      </>
                    )}


                    <fieldset className="form-group col-md-12 m-0 p-0" />
                    <div className="form-row">
                      <fieldset className="form-group col-md-4 m-0">
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.tcsreq}
                                onChange={this.handletcsreq}
                                name="ordertype"
                                color="primary"
                              />
                            }
                            label="TCS Required?"
                          />
                        </FormGroup>
                      </fieldset>
                      {this.state.tcsreq === true ? (
                        <>
                         <fieldset className="form-group col-md-2">
                                    <TextField
                                      className="form-control"
                                      id="tcsableamount"
                                      name="tcsableamount"
                                      type="number"
                                      label={`TCS on Amount`}
                                      value={this.state.tcsableamount}
                                      variant="standard"
                                      fullwhidth="true"
                                      onChange={(e) =>
                                        this.setState({
                                          tcsableamount: e.target.value,
                                          tcsamount: ((this.state.tcs * e.target.value) / 100).toFixed(3),
                                        })
                                      }
                                    />
                                  </fieldset>
                          <fieldset className="form-group col-md-2">
                            <FormikTextField
                              className="form-control"
                              type="number"
                              name="tcs"
                              onChange={this.handletcsChange}
                              label={`TCS (%)`}
                              value={this.state.tcs}
                              variant="standard"
                              fullWidth
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-2">
                            <FormikTextField
                              className="form-control"
                              type="number"
                              name="tcsamount"
                              label="TCS Amount"
                              value={this.state.tcsamount}
                              variant="standard"
                              fullWidth
                              disabled
                            />
                          </fieldset>
                        </>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="form-row">
                      <fieldset className="form-group col-md-4 m-0">
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.reqquotationupload}
                                onChange={() =>
                                  this.setState({
                                    reqquotationupload:
                                      !this.state.reqquotationupload,
                                  })
                                }
                                name="reqquotationupload"
                                color="primary"
                              />
                            }
                            label="Quotation Upload Required?"
                          />
                        </FormGroup>
                      </fieldset>
                      {this.state.reqquotationupload === false ? (
                        ""
                      ) : this.state.file === "" ? (
                        <>
                          <fieldset className="form-group col-md-4 m-0">
                            <span>Quotation File upload :&nbsp;&nbsp;</span>
                            {!selectedFiles ? (
                              <label htmlFor="btn-upload">
                                <input
                                  className="btn-choose"
                                  id="btn-upload"
                                  name="btn-upload"
                                  style={{ display: "none" }}
                                  type="file"
                                  onChange={this.selectFile}
                                />
                                <Button
                                  className="btn-close"
                                  variant="outlined"
                                  type="upload"
                                  component="span"
                                >
                                  <i className="fas fa-file mr-1" />{" "}
                                  <span className="text-blue">Select File</span>
                                </Button>
                              </label>
                            ) : (
                              <>
                                {selectedFiles && selectedFiles.length > 0
                                  ? selectedFiles[0].name
                                  : null}
                                <Button
                                  className="btn-sm btn-upload ml-2"
                                  color="primary"
                                  variant="contained"
                                  component="span"
                                  disabled={!selectedFiles}
                                  onClick={this.upload}
                                >
                                  upload <i className="fas fa-upload ml-1"></i>
                                </Button>
                              </>
                            )}
                          </fieldset>
                          {/* </div> */}
                          <div className="form-group col-md-9 m-0">
                            <Typography
                              variant="subtitle2"
                              className={`upload-message ${
                                isError ? "error" : ""
                              }`}
                            >
                              <span className="text-green">{message}</span>
                            </Typography>
                          </div>
                        </>
                      ) : (
                        <div>
                          Quatation file : &nbsp;{" "}
                          <span as="button" onClick={this.handleQuatation}>
                            {this.state.file}
                          </span>
                        </div>
                      )}
                    </div>


                    {this.state.potype === "1" ? (
                      <>
                        {this.state.supplier === "" ||
                        this.state.servicecategory === "" ||
                        this.state.service === "" ||
                        this.state.servicecost === "" ||
                        this.state.contracttype === "" ||
                        (this.state.contracttype === "Milestone" &&
                          this.state.milestone.length === 0) ? (
                          <button
                            className="btn btn-sm hovbuttonColour"
                            disabled
                          >
                            <>
                              <i className="fa fa-save mr-1" /> {"Save"}
                            </>
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm hovbuttonColour"
                            type="submit"
                            onClick={this.handleSubmit}
                            disabled={this.state.sloader}
                          >
                            {this.state.sloader === true ? (
                              <>
                                <Spinner
                                  animation="border"
                                  size="sm"
                                  className="mr-1"
                                />
                                {"Saving..."}{" "}
                              </>
                            ) : (
                              <>
                                <i className="fa fa-save mr-1" /> {"Save"}
                              </>
                            )}
                          </button>
                        )}
                      </>
                    ) : (
                      displaysavebutton
                    )}
                    {displayrequest}
                    <span
                      as="button"
                      className="btn btn-sm ml-2 bg-white shadow-sm"
                      // to={{ pathname: "/purchaseorder" }}
                      onClick={() => this.props.navigate(-1)}
                    >
                      <b className="text-danger">X</b> Cancel
                    </span>
                    <fieldset className="form-group col-md-12" />
                    <fieldset className="form-group col-md-12">
                      <label className="text-red">
                        Note : If any selection is wrong or with invalid
                        characters, please cancel the selection with "x" button
                        in selection and reselect the Field.
                      </label>
                    </fieldset>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

        <Footer />
      </div>
    );
  }
}
export default withRouter(NewPurchase);
