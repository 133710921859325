import React, { Component } from 'react'
import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import Menu from '../../Component/Menu'
import AcctopDataTable from './AcctopDataTable'


export default class AccTop extends Component {
    render() {
        return (
            <div>
                <Menu/>
                <Header/>
                <>
                    <div className="content-wrapper">
                    <AcctopDataTable/>
                    </div>
                </>
                <Footer/>

            </div>
        )
    }
}
