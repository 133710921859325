import { Field, Form, Formik } from 'formik';
import { FormikTextField } from '../../CommonComponents/FormField.js';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import AuthenticationService from '../../Authentication/AuthenticationService.jsx';
import {  userid } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import AccTopService from './AccTopService.js';
 




class NewAccTop extends Component {
  constructor(props) {
    super(props)


     const {pagename} = this.props.location.state;
    this.state = {
      // type: 'password',
       pagename:pagename,
      id:'',
      
     name:'',
    error:'',
     

    }
    this.onSubmit = this.onSubmit.bind(this);
    //  this.handleChange=this.handleChange.bind(this)
    this.validate = this.validate.bind(this);
    this.onExit=this.onExit.bind(this)
  }

  onExit(){
    AuthenticationService.logout()
  }

  onSubmit(values) {
    let newAccTop = {
      name: values.name,
      createdby:userid
    }
    let updateAccTop = {
      id:this.state.id,
      name: values.name,
      createdby:userid

       }
    //  MasterService.createTenant(newtenant).then(() => this.props.navigate(`/tenants`))
    if (this.state.id === '') {
      AccTopService.createAcctop(newAccTop,userid)
      .then(() =>      
          this.props.navigate(`/acctop`,{message:values.name + "   : Created Successfully"}))
          .catch(error => this.setState({error:error.message + "   Account Name Already Exists"}))
      
    }else {
      AccTopService.updateAcctop(updateAccTop)
      .then(() => 
       this.props.navigate('/acctop',{message:values.name + "   : Updated Successfully"}))

  }   
  }
  
  
  componentDidMount() {

    const {id} = this.props.location.state;

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    
 //let {id} = this.props.location.state
 if (id < 1) {
  //  this.setState({id:id})
  return    
}
AccTopService.retriveAccTop(id)
.then(response => this.setState({
  id:response.data.id,
  name: response.data.name,
}))

}


  validate(values) {
    let errors = {};
    var chars = /^[A-Za-z]+$/
    if (!values.name) {
      errors.name = "Account Name is required";
    }else if (!chars.test(values.name)) {
      errors.name = 'Only Characters are allowed';
    }
    
    return errors;

  }


  render() {
    // let countrypre = [this.state.countryprefix]
    
     let {id,name} = this.state

    return (
    
      
      <div>
         <Menu/>
          <Header/>
        <div className="content-wrapper">
          <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title">{this.state.pagename}</h3>
            </div>
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/index">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
              <Link to={{pathname: '/acctop'}} state={{message:''}} >
                          Top Heads
                        </Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>
            <div className="text-center text-danger">{this.state.error}</div>
            <div className="card-body">
            
              <Formik
                initialValues={{id,name} }
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}>
                {() => (
                  <Form>

                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field className="form-control" type="text" name="id" placeholder="Id" />
                      </fieldset>


                      {/* <fieldset className="form-group col-md-4">
                      <label><span className="text-sm">Head Name</span><span className="text-danger float-sm-right">*</span></label>
                        <Field className="form-control" type="text" name="name" placeholder="Head Name" />
                        <ErrorMessage name="name" component="div" className="text-danger float-sm-right" />
                      </fieldset> */}
                      <fieldset className="form-group col-md-4" >
                          <FormikTextField className="form-control" type="text" name="name" label="Head Name" value={this.state.name} />
                      </fieldset>
                      <fieldset className="form-group col-md-12"/>

                    </div>
                    <div className="form-row">
                      <fieldset className="form-group col-md-1">
                    <button className="btn btn-sm btn-success" type="submit">Save</button>
                    </fieldset>
                    <fieldset className="form-group col-md-1">
                    <Link as="button" type="submit" className="btn btn-sm btn-success" to={{pathname: '/acctop'}} state= {{message:''}}>Close</Link>
                    </fieldset>
                    </div>
                   
                    {/* <Link as="button" type="submit" className="btn btn-success" to={{pathname: '/ledgers' }} state ={ {message:this.state.message}}}>Save</Link> */}
                    

                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

      <Footer/>
      </div>
    );
  }
}
export default withRouter(NewAccTop)
