import React, { Component } from 'react'
import withRouter from "../../withRouter.js"
import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import Menu from '../../Component/Menu'
import CustomerDataTable from './CustomerDataTable'


class Customers extends Component {
    render() {
        return (
            <div>
                 <Header/>
                 <Menu/>
                <>
              <div className="content-wrapper">
              <CustomerDataTable/>
              </div>
              </>
              <Footer/>

            </div>
        )
    }
}
export default withRouter(Customers)