import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Header from "../Component/Header.js";
import Menu from "../Component/Menu.js";
import { FormikTextField } from "../CommonComponents/FormField.js";
import {
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Typography,
  TextField,
  FormLabel,
  FormGroup,
  Select,
  InputLabel,
  MenuItem,
  FormControl
} from "@mui/material";

import MasterService from "./MasterService.js";
import { Autocomplete } from "@mui/material";
import UploadFilesService from "../Common/upload/UploadFilesService.js";
import FileServices from "../Common/upload/FileServices.js";
import moment from "moment";
import { Spinner } from "react-bootstrap";

import "date-fns";
import Grid from "@mui/material/Grid";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";// import { prefix1 } from '../Common/Credentials.js';
let curmonth, year, day, path;
let doctype = "logo";
let prefix;
// let width;
// let height;

// let formData
class NewTenant extends Component {
  constructor(props) {
    super(props);

    const { name } = this.props.location.state;
    this.state = {
      // type: 'password',
      name: name,
      id: "",
      state: "",
      StateId: "",
      country: "",
      CountryId: "",
      city: "",
      CityId: "",
      zoneid: "",
      fystartdate: new Date(),
      fyenddate: new Date(),

      CountryData: [],
      StateData: [],
      CityData: [],
      CurrencyData: [],
      TaxtypeData: [],
      ZoneIdList: [],

      companyname: "",
      contactname: "",
      prefix: "",
      contactnumber: "",
      phonecode: "",
      phonecodemail: "",
      address: "",
      address2: "",
      pan: "",
      tan: "",
      gstin: "",
      zip: "",
      currency: "",
      currencyId: "",
      taxtype: "",
      taxtypeId: "",
      taxno: "",
      alternatenumber: "",
      registrationno: "",
      fsd: new Date(),
      fed: moment().add(-1, "days").add(12, "month").format("MMM-DD"),
      einvoice:false,
      // fem:'',
      // appmaster: false,
      // accounts: false,
      // inventory: false,
      // sales: false,
      // purchase: false,
      // production: false,
      // assetmanagement: false,

      selectedFiles: undefined,
      currentFile: undefined,
      previewImage: undefined,
      progress: 0,
      message: "",
      isError: false,
      fileInfos: [],
      fileInfo: "",
      partyid: "",
      error: false,
      errorMessage: {},
      invvalidenterymsg: "",
      file: "",
      noofusers: 1,
      loader: false,
      imageloader: false,
      usertype:"Regular",
      usertypes:["Regular","Enterprise"],
      clientTypes:["Individual","Subsidiary"],
      clientType:"Individual",
      parentclient:"",
      parentclientId:"",
      clientsList:[],
      refno:"",
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
  }

  handleprefix(value) {
    prefix = value;
    this.setState({
      prefix: value.toLowerCase(),
      selectedFiles: undefined,
      currentFile: undefined,
      previewImage: undefined,
      progress: 0,
      message: "",
      isError: false,
      fileInfos: [],
      fileInfo: "",
      partyid: "",
      error: false,
      errorMessage: {},
    });
  }

  handleFsdDateChange = (date) => {
    this.setState({ fsd: date });
    this.setState({
      fed: moment(date.toLocaleString()).add(-1, "days").add(12, "month").format("MMM-DD"),
    });
  };

  // handleFedDateChange = (date) => {
  //   this.setState({fed: date});
  // };

  ChangeClient = (value,values) =>{
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({ parentclient: values.companyname, parentclientId: values.id });
    } else {
      this.setState({ parentclient: "", parentclientId: "" });
    }
  }

  ChangeteCurrency = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({ currency: values.currency, currencyId: values.id });
    } else {
      this.setState({ currency: "", currencyId: "" });
    }
  };

  ChangeteTaxttype = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({ taxtype: values.type, taxtypeId: values.id });
    } else {
      this.setState({ taxtype: "", taxtypeId: "" });
    }
  };
  

  selectFile = (event) => {
    var fi = event.target;
    if (fi.files.length > 0) {
      // FIRST CHECK IF ANY FILE IS SELECTED.

      for (var i = 0; i <= fi.files.length - 1; i++) {
        var fileName, fileExtension

        // FILE NAME AND EXTENSION.
        fileName = fi.files.item(i).name;
        fileExtension = fileName.replace(/^.*\./, "");

        if (
          fileExtension === "png" ||
          fileExtension === "jpg" ||
          fileExtension === "jpeg" ||
          fileExtension === "jfif"
        ) {
          // readImageFile(fi.files.item(i)); // GET IMAGE INFO USING fileReader().
          this.setState({
            selectedFiles: event.target.files,
            previewImage: URL.createObjectURL(event.target.files[0]),
          });
          document.getElementById("fileInfo").innerHTML =""
        } else {
          document.getElementById("fileInfo").innerHTML =
            "Unsupported format. Please Upload 'png' or 'jpg' or 'jpeg' type image";
        }
      }

      // GET THE IMAGE WIDTH AND HEIGHT USING fileReader() API.
    //   function readImageFile(file) {
    //     var reader = new FileReader(); // CREATE AN NEW INSTANCE.

    //     reader.onload = function (e) {
    //       var img = new Image();
    //       var w, h;
    //       img.src = e.target.result;

    //       img.onload = function () {
    //         w = this.width;
    //         h = this.height;
    //         setsize(w, h);
    //         // if(w<=150 && h<=150){document.getElementById('fileInfo').innerHTML = ''}
    //         // else{document.getElementById('fileInfo').innerHTML = "File size should be lessthan or equal to 150x150"}
    //       };
    //     };
    //     // const setsize = (w, h) => {
    //     //   width = w;
    //     //   height = h;
    //     //   // this.setState("hi")
    //     // };
    //     reader.readAsDataURL(file);
    //   }
    }
  };

  handlefystartdate = (date) => {
    this.setState({ mfdate: date });
  };

  upload=()=> {
    // if(width<=150 && height<=150){
    let currentFile = this.state.selectedFiles[0];

    this.setState({ progress: 100, currentFile: currentFile });

    UploadFilesService.logoupload(
      currentFile,
      year,
      curmonth,
      doctype,
      this.state.prefix,
      day,
      (event) => {
        this.setState({
          progress: Math.round((100 * event.loaded) / event.total),
          file: currentFile
        });
      }
    )
      .then((response) => {
        this.setState({
          message: response.data.message,
          isError: false,
        });
        return FileServices.getFiles1(currentFile.name);
      })
      .then((files) => {
        this.setState({ fileInfos: files.data,file:files.data.map((e) => e.name).toLocaleString() });
      })

      .catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
          isError: true,
        });
      });
    this.setState({ selectedFiles: undefined });
    // }else{
    //   this.setState({selectedFiles: undefined,previewImage:undefined});
    // }
  }

  handleProductChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.checked });
  };

  onSubmit(values) {
    let filedata = this.state.fileInfos.map((e) => e.name);
    path =
      values.prefix +
      "/" +
      doctype +
      "/" +
      values.prefix +
      "/" +
      year +
      "/" +
      curmonth +
      "/" +
      day;
    // if ((filedata === "" || filedata.length === 0) && this.state.file === "") {
    //   this.setState({ message: "Please Upload Logo" });
    // } else {
      this.setState({ loader: true });
      let newtenant = {
        companyname: this.state.companyname,
        contactname: this.state.contactname,
        prefix: this.state.prefix,
        contactnumber: this.state.contactnumber.replace(/[^0-9]+/ig, "") ,
        email: this.state.email,
        address: this.state.address,
        address2: this.state.address2,
        pan: this.state.pan.toUpperCase(),
        tan: this.state.tan.toUpperCase(),
        gstin: this.state.gstin.toUpperCase(),
        zip: this.state.zip,
        zoneid: this.state.zoneid,
        country: { id: this.state.CountryId },
        state: { id: this.state.StateId },
        city: { id: this.state.CityId },
        fystartmonth: `${moment(this.state.fsd.toLocaleString()).format("MMM-DD")} 00:00:01.000`,
        fyendmonth: `${this.state.fed} 23:59:59.999`,
        currency: { id: this.state.currencyId },
        taxtype: { id: this.state.taxtypeId },
        taxno: this.state.taxno,
        registrationno: this.state.registrationno,
        // appmaster: this.state.appmaster,
        // accounts: this.state.accounts,
        // inventory: this.state.inventory,
        // sales: this.state.sales,
        // purchase: this.state.purchase,
        // production: this.state.production,
        // assetmanagement: this.state.assetmanagement,
        logofile: filedata.toLocaleString(),
        logopath: path,
        alternatenumber: this.state.alternatenumber.replace(/[^0-9]+/ig, "") ,
        phonecode: this.state.phonecode,
        einvoice:this.state.einvoice,
        noofusers:this.state.usertype === "Enterprise"?0: this.state.noofusers,
        refno:this.state.refno,
        clientType:this.state.clientType,
      parentclientId:this.state.parentclientId
      };
      let updatetenant = {
        id: values.id,
        companyname: this.state.companyname,
        contactname: this.state.contactname,
        prefix: this.state.prefix,
        contactnumber: this.state.contactnumber.replace(/[^0-9]+/ig, "") ,
        email: this.state.email,
        address: this.state.address,
        address2: this.state.address2,
        pan: this.state.pan.toUpperCase(),
        gstin: this.state.gstin.toUpperCase(),
        tan: this.state.tan.toUpperCase(),
        zip: this.state.zip,
        zoneid: this.state.zoneid,
        country: { id: this.state.CountryId },
        state: { id: this.state.StateId },
        city: { id: this.state.CityId },
        // appmaster: this.state.appmaster,
        // accounts: this.state.accounts,
        // inventory: this.state.inventory,
        // sales: this.state.sales,
        // purchase: this.state.purchase,
        // production: this.state.production,
        // assetmanagement: this.state.assetmanagement,
        currency: { id: this.state.currencyId },
        taxtype: { id: this.state.taxtypeId },
        taxno: this.state.taxno,
        registrationno: this.state.registrationno,
        alternatenumber: this.state.alternatenumber.replace(/[^0-9]+/ig, ""),
        phonecode: this.state.phonecode,

        fystartmonth: `${moment(this.state.fsd).format("MMM-DD")} 00:00:01.000`,
        fyendmonth: `${moment(this.state.fed).format("MMM-DD")} 23:59:59.999`,
        logofile:this.state.file,
        logopath: path,
        einvoice:this.state.einvoice,
        noofusers:this.state.usertype === "Enterprise"?0: this.state.noofusers,
        refno:this.state.refno,
        clientType:this.state.clientType,
        parentclientId:this.state.parentclientId
      };
      //  MasterService.createTenant(newtenant).then(() => this.props.navigate(`/tenants`))
      if (this.state.id === "") {
        // if(width<=150 && height<=150){
        MasterService.createTenant(newtenant)
          .then(() => this.props.navigate(`/tenants`))
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            } else {
              this.setState({
                invvalidenterymsg: e.response.data.message,
                loader: false,
              });
            }
          });
        // }
      } else {
        MasterService.updateTenant(updatetenant)
          .then(() => this.props.navigate("/tenants"))
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            } else {
              this.setState({
                invvalidenterymsg: e.response.data.message,
                loader: false,
              });
            }
          });
      }
    // }
  }

  componentDidMount() {
    prefix = "";
    MasterService.getCountries()
      .then((response) =>
        this.setState({
          CountryData: response.data,
        })
      )
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    MasterService.getZoneIdList().then((response) =>
      this.setState({
        ZoneIdList: response.data,
      })
    );

    MasterService.getCurrency().then((response) =>
      this.setState({
        CurrencyData: response.data,
      })
    );

    MasterService.getTaxtypes().then((response) =>
      this.setState({
        TaxtypeData: response.data,
      })
    );

    MasterService.retriveAllTenants().then(response=>this.setState({clientsList:response.data}))

    year = new Date().getFullYear();
    day = new Date().getDate();
    let mn = new Date().getMonth() + 1;
    curmonth = moment(mn, "M").format("MMMM");

    //     MasterService.getCountries()
    // .then(response => {
    //   this.setState({
    //     CountryData: response.data
    //   })
    // })

    const { id } = this.props.location.state;

    if (id < 1) {
      return;
    }
    MasterService.retriveTenant(id).then((response) =>
      this.setState(
        {
          id: id,
          companyname: response.data.clients.companyname,
          contactname: response.data.clients.contactname,
          prefix: response.data.clients.prefix,
          contactnumber: response.data.clients.contactnumber,
          email: response.data.clients.email,
          address: response.data.clients.address,
          address2: response.data.clients.address2,
          pan: response.data.clients.pan,
          tan: response.data.clients.tan,
          gstin: response.data.clients.gstin,
          zip: response.data.clients.zip,
          zoneid: response.data.clients.zoneid,
          country: response.data.clients.country.name,
          CountryId: response.data.clients.country.id,
          state: response.data.clients.state.name,
          StateId: response.data.clients.state.id,
          city: response.data.clients.city.name,
          CityId: response.data.clients.city.id,
          currency: response.data.clients.currency.currency,
          currencyId: response.data.clients.currency.id,
          taxtype: response.data.clients.taxtype.type,
          taxtypeId: response.data.clients.taxtype.id,
          taxno: response.data.clients.taxno,
          registrationno: response.data.clients.registrationno,
          einvoice: response.data.clients.einvoice,
          fsd: moment(response.data.clients.fystartdate).format("MMM-DD"),
          fed: moment(response.data.clients.fyenddate).format("MMM-DD"),
          alternatenumber: response.data.clients.alternatenumber,
          phonecode: response.data.phonecode,
          noofusers:response.data.noofusers,
          products: response.data.products,
          appmaster: response.data.products.includes("appmaster"),
          accounts: response.data.products.includes("accounts"),
          inventory: response.data.products.includes("inventory"),
          sales: response.data.products.includes("sales"),
          purchase: response.data.products.includes("purchase"),

          file: response.data.clients.logofile,
          path: response.data.clients.logopath,
          noofusers:response.data.clients.noofusers,
          usertype:response.data.clients.noofusers === 0 || response.data.clients.noofusers === null ?"Enterprise":"Regular"
        },
        () => {
          let formData = new FormData();
          formData.append("path", this.state.path);
          formData.append("filename", this.state.file);
          this.setState({ imageloader: true });
          FileServices.getonefile(formData).then((response) =>
            this.setState({
              previewImage: URL.createObjectURL(
                new Blob(
                  [response.data],
                  { type: "application/octet-stream" }
                )
              ),
            },() => this.setState({ imageloader: false }))
          );
        }
      )
    );
  }

  ChangeCountry = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        country: values.name,
        CountryId: values.id,
        phonecode: values.phonecode,
        state: "",
        StateId: "",
        city: "",
        CityId: "",
        CityData: [],
      });
      MasterService.getStates(values.id).then((response) => {
        this.setState({
          StateData: response.data,
        });
      });
    } else {
      this.setState({
        country: "",
        CountryId: "",
        state: "",
        StateId: "",
        city: "",
        CityId: "",
        StateData: [],
        CityData: [],
      });
    }
  };
  ChangeState = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        state: values.name,
        StateId: values.id,
        city: "",
        CityId: "",
      });
      MasterService.getCities(values.id).then((response) => {
        this.setState({
          CityData: response.data,
        });
      });
    } else {
      this.setState({
        state: "",
        StateId: "",
        city: "",
        CityId: "",
        CityData: [],
      });
    }
  };

  ChangeCity = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({ city: values.name, CityId: values.id });
    } else {
      this.setState({ city: "", CityId: "" });
    }
  };

  ChangeZone = (value, values) => {
    if (
      values !== "" &&
      values !== null &&
      this.state.ZoneIdList.includes(values) === true
    ) {
      this.setState({ zoneid: values });
    } else {
      this.setState({ zoneid: "" });
    }
  };

  validate(values) {
    let errors = {};
    var chars = /^[A-Za-z ]+$/;
    var charspre = /^[A-Za-z]+$/;
    var num = /^[0-9]+$/;
    if (!values.companyname) {
      errors.companyname = "Company Name is required";
    } 
    // else if (!charnum.test(values.companyname)) {
    //   errors.companyname = "Only Characters are allowed";
    // }
    if (!values.contactname) {
      errors.contactname = "Contact Name is required";
    } else if (!chars.test(values.contactname)) {
      errors.contactname = "Only Characters are allowed";
    }
    if (!values.prefix) {
      errors.prefix = "Sub Domain is required";
    } else if (!charspre.test(values.prefix)) {
      errors.prefix = "Only Characters are allowed";
    }
    if (!values.contactnumber) {
      errors.contactnumber = "Contact Number is required";
    } else if (this.state.country === "India") {
      if (!/^[7-8-9][0-9]{9}$/i.test(values.contactnumber)) {
        errors.contactnumber = "Contact Number should be 10 Numbers";
      }
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (!values.address) {
      errors.address = "Address is required";
    }
    if (!values.address2) {
      errors.address2 = "Area/Colony/Street is required";
    }
    if (this.state.country === "India") {
      if (!values.pan) {
        errors.pan = "PAN is required";
      } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}/gi.test(values.pan)) {
        errors.pan = "Invalid Ex: ABCDE1234F";
      }
      if (!values.tan) {
        errors.tan = "TAN is required";
      } else if (!/^[A-Z]{4}[0-9]{5}[A-Z]{1}/gi.test(values.tan)) {
        errors.tan = "Invalid Ex: ABCD12345X";
      }
      if (!values.gstin) {
        errors.gstin = "GSTIN is required";
      } else if (!/^[A-Z0-9]{15}$/i.test(values.gstin)) {
        errors.gstin =
          "Invalid GST should be 15 Charactors EX: 22ABCDE1234F1Z5";
      } else if (
        !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z,0-9]{1}/i.test(
          values.gstin
        )
      ) {
        errors.gstin = "Invalid Ex: 22ABCDE1234F1Z5";
      }
    } else {
    }

    if(this.state.country ==='India'){
      if (!values.zip) {
        errors.zip = "Zip is required";
      } else if (!num.test(values.zip)) {
        errors.zip = "Only Numbers allowed";
      } else if (!/^[0-9]{5,6}$/i.test(values.zip)) {
        errors.zip = "Should be 5 or 6 Numbers";
      }
    }else{
      if (!values.zip|| this.state.zip === '') {
        errors.zip = "PO Box No. is required";
      }
    }
    if (
      values.currency === "Select Currency..." ||
      this.state.currency === ""
    ) {
      errors.currency = "Currency is required";
    }
    if (values.taxtype === "" || this.state.taxtype === "") {
      errors.taxtype = "TaxType is required";
    }
    if (values.zoneid === "" || this.state.zoneid === "") {
      errors.zoneid = "ZoneId is required";
    }
    if (values.country === "" || this.state.CountryId === "") {
      errors.country = "Country is required";
    }
    if (values.state === "" || this.state.StateId === "") {
      errors.state = "State is required";
    }
    if (values.city === "" || this.state.CityId === "") {
      errors.city = "City is required";
    }

    if(this.state.clientType === "Subsidiary"){
      if (this.state.parentclientId === "" || this.state.parentclientId === null) {
        errors.parentclient = "Please select parent company";
      }
    }

    return errors;
  }

  //  handleChange = (event) => {
  //     this.setState({
  //       ...this.state,
  //       value: event.target.value
  //     })
  //   }

  render() {
    let {
      id,
      companyname,
      contactname,
      prefix,
      contactnumber,
      email,
      currency,
      taxtype,
      zoneid,
      country,
      state,
      city,
      address,
      address2,
      tan,
      pan,
      gstin,
      zip,
      taxno,
      registrationno,
      selectedFiles,
      message,
      isError,
      previewImage,
      alternatenumber,
      einvoice,
      refno,
      clientType,
      parentclient
    } = this.state;
  

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/superadminmasters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/tenants">Tenants</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.name}</li>
            </ol>

            <div className="card-body">
              <Formik
                initialValues={{
                  id,
                  companyname,
                  contactname,
                  prefix,
                  contactnumber,
                  email,
                  address,
                  address2,
                  pan,
                  tan,
                  gstin,
                  zip,
                  currency,
                  taxtype,
                  zoneid,
                  country,
                  state,
                  city,
                  taxno,
                  registrationno,
                  alternatenumber,
                  einvoice,
                  refno,
                  clientType,
                  parentclient
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <FormikTextField
                          className="form-control"
                          type="text"
                          label="id"
                          name="id"
                          placeholder="Id"
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        {/* <label><span className="text-sm">Company Name</span><span className="text-danger float-sm-right">*</span></label> */}
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="companyname"
                          label="Company Name"
                          value={this.state.companyname}
                          onChange={(e) =>
                            this.setState({ companyname: e.target.value })
                          }
                        />
                        {/* <ErrorMessage name="companyname" component="div" className="text-danger float-sm-right" /> */}
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        {/* <label><span className="text-sm">Contact Name</span><span className="text-danger float-sm-right">*</span></label> */}
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="contactname"
                          label="Contact Name"
                          value={this.state.contactname}
                          onChange={(e) =>
                            this.setState({ contactname: e.target.value })
                          }
                        />
                        {/* <ErrorMessage name="contactname" component="div" className="text-danger float-sm-right" /> */}
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        {/* <label><span className="text-sm">Sub Domain</span><span className="text-danger float-sm-right">*</span></label> */}
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="prefix"
                          label="Sub Domain"
                          inputProps={{ style: { textTransform: "lowercase" } }}
                          value={this.state.prefix}
                          onChange={(e) => this.handleprefix(e.target.value)}
                        />
                        {/* <ErrorMessage name="prefix" component="div" className="text-danger float-sm-right" /> */}
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        {/* <label><span className="text-sm">Email</span><span className="text-danger float-sm-right">*</span></label> */}
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="email"
                          label="Enter Email Id"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                        {/* <ErrorMessage name="email" component="li" className="text-danger border-danger float-sm-right" /> */}
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        {/* <label><span className="text-sm">Address</span><span className="text-danger float-sm-right">*</span></label> */}
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="address"
                          label="Enter Address"
                          value={this.state.address}
                          onChange={(e) =>
                            this.setState({ address: e.target.value })
                          }
                        />
                        {/* <ErrorMessage name="address" component="div" className="text-danger float-sm-right" /> */}
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        {/* <label><span className="text-sm">Address2</span></label> */}
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="address2"
                          label="Enter Area/Colony/Street"
                          value={this.state.address2}
                          onChange={(e) =>
                            this.setState({ address2: e.target.value })
                          }
                        />
                        {/* <ErrorMessage name="address2" component="div" className="text-danger float-sm-right" /> */}
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="country"
                          className="subacc-select"
                          name="country"
                          options={this.state.CountryData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.country ? undefined : undefined}
                          onChange={this.ChangeCountry}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="country"
                              value={this.state.country}
                              label={`Select Country ( ${this.state.country} )`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="state"
                          className="subacc-select"
                          name="state"
                          options={this.state.StateData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.state ? undefined : undefined}
                          onChange={this.ChangeState}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="state"
                              value={this.state.state}
                              label={`Select State ( ${this.state.state} )`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="city"
                          className="subacc-select"
                          name="city"
                          options={this.state.CityData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.city ? undefined : undefined}
                          onChange={this.ChangeCity}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="city"
                              value={this.state.city}
                              label={`Select City ( ${this.state.city} )`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="zip"
                          label={
                            this.state.country === "India"
                              ? "Enter Zip/Pincode"
                              : "Enter PO BOX NO."
                          }
                          value={this.state.zip}
                          onChange={(e) =>
                            this.setState({ zip: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="zoneid"
                          className="subacc-select"
                          name="zoneid"
                          options={this.state.ZoneIdList}
                          getOptionLabel={(option) => option}
                          
                          
                          value={values.zoneid ? undefined : undefined}
                          onChange={this.ChangeZone}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="zoneid"
                              value={this.state.zoneid}
                              label={`Select Zone ( ${this.state.zoneid} )`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        {/* <label><span className="text-sm">Contact Number</span><span className="text-danger float-sm-right">*</span></label> */}
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="contactnumber"
                          label="Enter Contact Number"
                          value={this.state.contactnumber}
                          onChange={(e) =>
                            this.setState({ contactnumber: e.target.value.replace(/[^0-9]+/ig, "") })
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +{this.state.phonecode}
                              </InputAdornment>
                            ),
                          }}
                        />
                        {/* <ErrorMessage name="contactnumber" component="div" className="text-danger float-sm-right" /> */}
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="alternatenumber"
                          label="Enter Alternate Contact Number"
                          value={this.state.alternatenumber}
                          onChange={(e) =>
                            this.setState({ alternatenumber: e.target.value.replace(/[^0-9]+/ig, "")  })
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +{this.state.phonecode}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="zoneid"
                          className="subacc-select"
                          name="currrency"
                          options={this.state.CurrencyData}
                          getOptionLabel={(option) => option.currency}
                          
                          
                          value={values.currency ? undefined : undefined}
                          onChange={this.ChangeteCurrency}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="currency"
                              value={this.state.currency}
                              label={`Select Currency Type ( ${this.state.currency} )`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="taxtype"
                          className="subacc-select"
                          name="taxtype"
                          options={this.state.TaxtypeData}
                          getOptionLabel={(option) => option.type}
                          value={values.taxtype ? undefined : undefined}
                          onChange={this.ChangeteTaxttype}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="taxtype"
                              value={this.state.taxtype}
                              label={`Select Taxtype Type ( ${this.state.taxtype} )`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      {this.state.country === "India" ? (
                        <>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="pan"
                              label="Enter PAN"
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.pan}
                              onChange={(e) =>
                                this.setState({ pan: e.target.value })
                              }
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="tan"
                              label={"Enter TAN"}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.tan}
                              onChange={(e) =>
                                this.setState({ tan: e.target.value })
                              }
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="gstin"
                              label={"Enter GSTIN"}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.gstin}
                              onChange={(e) =>
                                this.setState({ gstin: e.target.value })
                              }
                            />
                          </fieldset>
                        </>
                      ) : (
                        <>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="taxno"
                              label="Enter Tax no."
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.taxno}
                              onChange={(e) =>
                                this.setState({ taxno: e.target.value })
                              }
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="registrationno"
                              label={"Enter Registration No."}
                              inputProps={{
                                style: { textTransform: "uppercase" },
                              }}
                              value={this.state.registrationno}
                              onChange={(e) =>
                                this.setState({
                                  registrationno: e.target.value,
                                })
                              }
                            />
                          </fieldset>
                        </>
                      )}
                       <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="refno"
                              label={"Enter Reference No."}
                              value={this.state.refno}
                              onChange={(e) =>
                                this.setState({
                                  refno: e.target.value,
                                })
                              }
                            />
                          </fieldset>

                      <fieldset className="form-group col-md-4">
                          <FormControl variant="standard" fullWidth>
                              <InputLabel id="demo-simple-select-standard-label">Company Type</InputLabel>
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={this.state.clientType}
                                onChange={(e)=>this.setState({clientType:e.target.value})}
                                label="Users Type"
                              >
                                {this.state.clientTypes.map(e=>
                                <MenuItem value={e}>{e}</MenuItem>
                                )}
                              </Select>
                            </FormControl>
                          </fieldset>
                          {this.state.clientType === "Subsidiary"?
                          <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="parentclient"
                          className="subacc-select"
                          name="parentclient"
                          options={this.state.clientsList}
                          getOptionLabel={(option) => option.companyname}
                          value={values.parentclient ? undefined : undefined}
                          onChange={this.ChangeClient}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="parentclient"
                              value={this.state.parentclient}
                              label={`Select Parent Company ( ${this.state.parentclient} )`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>:""}

                          <fieldset className="form-group col-md-4">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container justifyContent="space-around">
                              <DatePicker
                                variant="standard"
                                id="date-picker-dialog"
                                label="Financial Year Start Date"
                                // views={['month','day']}
                                // format="MMM/dd"
                                // minDate={new Date()}
                                value={this.state.fsd}
                                onChange={this.handleFsdDateChange}
                                // KeyboardButtonProps={{
                                //   "aria-label": "change date",
                                // }}
                                renderInput={(params) => <TextField {...params}  value={moment(this.state.fsd).format("MMM-dd")}  variant="standard" fullWidth/>}
                                autoOk={true}
                                
                              />
                            </Grid>
                          </LocalizationProvider>
                        </fieldset>
                        <fieldset className="form-group col-md-4">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container justifyContent="space-around">
                              <DatePicker
                                variant="inline"
                                id="date-picker-dialog"
                                label="Financial Year End Date"
                                // format="dd/MM"
                                // views={["month","day"]}
                                // minDate={new Date()}
                                value={this.state.fed}
                                onChange={this.handleFedDateChange}
                                renderInput={(params) =>
                                    <TextField value={this.state.fed} label="Financial Year End Date" disabled variant="standard"  fullWidth/>
                                  }
                                disabled
                              />
                            </Grid>
                          </LocalizationProvider>
                        </fieldset>

                    </div>
                    <div className="form-row">
                      <fieldset className="form-group col-md-12" />
                     
                       
                        {this.state.country === "India"?
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.einvoice}
                              onChange={()=>this.setState({einvoice:!this.state.einvoice})}
                              name="einvoice"
                              color="primary"
                              className="ml-4"
                            />
                          }
                          label="E-Invoice Required"
                        />
                          :''}
                      <fieldset className="form-group col-md-12 m-0"/>
                      <fieldset className="form-group col-md-4" >
                        <FormControl variant="standard" fullWidth>
        <InputLabel id="demo-simple-select-standard-label">Users Type</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={this.state.usertype}
          onChange={(e)=>this.setState({usertype:e.target.value})}
          label="Users Type"
        >
          <MenuItem value={"Regular"}>Regular</MenuItem>
          <MenuItem value={"Enterprise"}>Enterprise</MenuItem>
        </Select>
      </FormControl>
      </fieldset>
      {this.state.usertype ==="Regular"?<div className="form-row align-items-center">
                        {this.state.noofusers>1?<>
                          {this.state.noofusers>5?  <span className="btn btn-sm hovbuttonColour mr-1" onClick={()=>this.setState({noofusers:this.state.noofusers <=5?this.state.noofusers:this.state.noofusers - 5})}>-5</span> :""} 
                     <span className="btn btn-sm hovbuttonColour mr-1" onClick={()=>this.setState({noofusers:this.state.noofusers <=1?this.state.noofusers : this.state.noofusers - 1})}>-1</span>
                      </>:""}
                      <FormLabel className="mx-2 text-center d-flex justify-center"> No. of Users : {this.state.noofusers}</FormLabel>
                      <span className="btn btn-sm hovbuttonColour mr-1" onClick={()=>this.setState({noofusers:this.state.noofusers + 1})}>+1</span>
                      <span className="btn btn-sm hovbuttonColour mr-1" onClick={()=>this.setState({noofusers:this.state.noofusers + 5})}>+5</span>
                          </div>:""}

                      <fieldset className="form-group col-md-12 m-0"/>
                      {/* <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.appmaster}
                              onChange={this.handleProductChange}
                              name="appmaster"
                              color="primary"
                            />
                          }
                          label="AppMaster"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.accounts}
                              onChange={this.handleProductChange}
                              name="accounts"
                              color="primary"
                            />
                          }
                          label="Accounts"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.inventory}
                              onChange={this.handleProductChange}
                              name="inventory"
                              color="primary"
                            />
                          }
                          label="Inventory"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.sales}
                              onChange={this.handleProductChange}
                              name="sales"
                              color="primary"
                            />
                          }
                          label="Sales"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.purchase}
                              onChange={this.handleProductChange}
                              name="purchase"
                              color="primary"
                            />
                          }
                          label="Purchase"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.production}
                              onChange={this.handleProductChange}
                              name="production"
                              color="primary"
                            />
                          }
                          label="Production"
                        />

                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.assetmanagement}
                              onChange={this.handleProductChange}
                              name="assetmanagement"
                              color="primary"
                            />
                          }
                          label="Asset Management"
                        />
                      </FormGroup> */}

                      <fieldset className="form-group col-md-12" />
                      <div>
                        Logo Upload :&nbsp;&nbsp;
                        {/* {this.state.file !== ''? 
                        <Link as="button"  onClick={this.handlePurchaseorder}>{this.state.file}</Link>
                        : */}
                        {/* <> */}
                        {!selectedFiles ? (
                          <label htmlFor="btn-upload">
                            <input
                              className="btn-choose text-sm"
                              id="btn-upload"
                              name="btn-upload"
                              style={{ display: "none" }}
                              type="file"
                              onChange={this.selectFile}
                            />
                            <Button
                              className="btn-close"
                              variant="outlined"
                              type="upload"
                              component="span"
                            >
                              <i className="fas fa-file mr-1" />{" "}
                              <span className="text-blue">{"Select Logo"}</span>
                            </Button>
                          </label>
                        ) : (
                          <>
                            {selectedFiles && selectedFiles.length > 0
                              ? selectedFiles[0].name
                              : null}
                            <Button
                              className="btn-sm btn-upload ml-2 text-sm"
                              color="primary"
                              variant="contained"
                              component="span"
                              disabled={!selectedFiles}
                              onClick={this.upload}
                            >
                              upload <i className="fas fa-upload ml-1"></i>
                            </Button>
                            <span
                              as="button"
                              className="btn btn-info ml-3"
                              onClick={() =>
                                this.setState({
                                  selectedFiles: undefined,
                                  previewImage: undefined,
                                })
                              }
                            >
                              Reupload
                            </span>
                          </>
                        )}
                        <p id="fileInfo" className="text-red ml-4"></p>
                        <div className="form-group col-md-3" />
                        {previewImage && (
                          <div>
                            {this.state.imageloader === true ? (
                              <Spinner />
                            ) : (
                              <img
                                alt="Logo..."
                                // width="100%"
                                height="150"
                                className="finanzzlogo"
                                src={previewImage}
                              />
                            )}
                          </div>
                        )}
                        {/* </fieldset> */}
                        <div className="form-group col-md-9">
                          <Typography
                            variant="subtitle2"
                            className={`upload-message ${
                              isError ? "error" : ""
                            }`}
                          >
                            <span className="text-green">{message}</span>
                          </Typography>
                        </div>
                        {/* </>} */}
                      </div>
                      <p className="col-md-12 text-danger">
                        {this.state.invvalidenterymsg}
                      </p>
                      <div className="form-row col-md-12">
                        <fieldset className="form-group col-md-12">
                          {this.state.loader === true ? (
                            <button
                              className="btn btn-sm hovbuttonColour"
                              disabled
                            >
                              <Spinner animation="border" size="sm" />
                              &nbsp;{"Saving"}
                            </button>
                          ) : (
                            <button
                              className="btn btn-sm hovbuttonColour"
                              type="submit"
                            >
                              <i className="fa fa-save" /> Save
                            </button>
                          )}
                          <Link
                            as="button"
                            type="submit"
                            className="btn btn-sm bg-white shadow-sm ml-3"
                            to={{pathname: "/tenants"}}
                              state= {{ message: "" }}
                          >
                            <b className="text-danger mr-1"> X</b>Close
                          </Link>
                        </fieldset>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

        <Footer />
      </div>
    );
  }
}
export default withRouter(NewTenant);
