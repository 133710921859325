import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import { currentTenant, userid } from '../../Common/Credentials.js';
import { spin } from '../../Common/NewLoader.jsx';
import { appmasterid } from '../../Common/Permissions.js';
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import HSNServices from './HSNServices.js';
import hsn from '../../Excel Templets/HSN.xlsx';
import * as XLSX from 'xlsx'; 


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});


class HSNTable extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        message:'',
        hsnlists:[],
        hsnexcellist:[],
        loading:false,
        showadd:false,

    }
   
  }


  
  downloadExcel=()=>{
    var link = document.createElement("a");
    link.href = hsn
    console.log(link.href)
    link.download = 'HSN';

    document.body.appendChild(link);

    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  }

  readExcel=(file)=>{
    
    const promise = new Promise((resolve,reject)=>{
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file)
      console.log(file)
      fileReader.onload=(e)=>{
        console.log(e,e.target.result)
        const bufferArray = e.target.result;
        console.log(bufferArray)
        const wb = XLSX.read(bufferArray,{type:'buffer'});
        console.log(wb)
        const wsname  = wb.SheetNames[0];
        console.log(wsname)
        const ws = wb.Sheets[wsname];
        console.log(ws)
        const data = XLSX.utils.sheet_to_json(ws);
        console.log(data)
        resolve(data);
      }
      fileReader.onerror = error=>{
        reject(error);
      }
    })
   promise.then((d)=>{
    console.log(d)
    this.setState({hsnexcellist:d})
   })
  }
  
async refreshHSN(){ 

  // console.log(hsn)
  // var link = document.createElement("a");
  // link.href = hsn
  // console.log(link.href)

  fetch('../../Excel Templets/HSN.xlsx')
    .then((r) => r.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.onerror = reject
      reader.readAsDataURL(blob)
      console.log(blob)
     }))


  // const f = await (await fetch(link.href.toString())).arrayBuffer();
  // const wb = read(f); // parse the array buffer
  // const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
  // const data = utils.sheet_to_json(ws); // generate objects
  // setPres(data);
  // console.log(data)
  
  HSNServices.getHSNs(currentTenant)
    .then(
      response => {
          this.setState({hsnlists:response.data})
        this.setState({loading:false})
        }
      )  
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })  
  }

  uploadHSN=()=>{
    if(this.state.hsnexcellist.length!==0){
    this.setState({loader:true})
    let uploadbrand = { 
      names:this.state.hsnexcellist,
      tenantid: currentTenant
    }
    HSNServices.UploadHSN(uploadbrand).then(() => 
    this.setState({showadd:false}))
    .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
      this.setState({message:e.response.data.message,loader:false})
    })
  }
  }

    componentDidMount () {

      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }

    //   UserService.UserPermissions(userid).then(response=>this.setState({up:response.data}))
      
      this.setState({loading:true})

      this.refreshHSN();

     }

    render() {

        const columns = [
            {name: "id",label: "Id",options: {filter: false,sort: true,display:false}},
            {name: "hsn",label:"HSN",options: {filter: true,sort: true,}},
        ];
        const options = {
            filterType: 'checkbox',
            selectableRows: "none",
            customToolbar: () => {
             return (<>
              {appmasterid === '2' || appmasterid === '3'?
              <span as="button" className="float-right btn btn-sm m-0 p-0" onClick={()=>this.setState({showadd:true})}>
                <CustomToolbar />
              </span>
                :''  
              }
             </>
             );
           }
          }
        return (
            <div>
                <Header/>
                <Menu/>
                <div className="content-wrapper">
                    <div className="card">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item text-secondary">
                                <Link to="/superadminmasters">Home</Link>
                            </li>
                            
                            <li className="breadcrumb-item active">HSN</li>
                        </ol>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                
                                            <div className="card-body">
                                                <div>{this.state.loading === true ? <div className="text-center"> {spin}</div> :
                                                <>
                                                {this.state.showadd?
                                                <div className="form-row mb-3">
                                                <fieldset className="form-group col-md-12">
                                                  <label className="text-red">Note: The Excel Sheet must contain only one column with name 'HSN', accepts only ".xlsx, .xls, .csv"</label>
                                              </fieldset>
                                              <fieldset className="mb-2">
                                                <label>Download Excel format &nbsp;&nbsp;&nbsp;</label>
                                              <button className="btn btn-sm hovbuttonColour shadow-sm fa fa-file-excel text-md" type='buton' data-tip data-for="pdf" onClick={this.downloadExcel}>&nbsp;Download</button>
                                                </fieldset>
                                                <fieldset className="form-group col-md-12"></fieldset>
                                                    <fieldset className="form-group col-md-1 text-left">
                                                    <label>Upload here </label>
                                                    </fieldset>
                                                    <fieldset className="form-group col-md-4">
                                                      <label htmlFor="btn-upload">
                                                        <input
                                                          className="btn-choose "
                                                          id="btn-upload"
                                                          name="btn-upload"
                                                          type="file"
                                                          onChange={e=>{
                                                            if(e.target.files[0] !== undefined){
                                                            const file = e.target.files[0];
                                                            this.readExcel(file)
                                                            }
                                                          }}
                                                          accept=".xlsx, .xls, .csv"
                                                        />
                                                      </label>
                                                    </fieldset>
                                                    <fieldset className="form-group col-md-12">
                                                      <table>
                                                        <tbody>
                                                          
                                                      {this.state.hsnexcellist.length === 0?'':<label>HSN({this.state.hsnexcellist.length}):&nbsp;</label>}
                                                            {this.state.hsnexcellist.map((e,i)=>
                                                              <tr key={i}>
                                                                <td className='text-start'>{i+1}</td>
                                                                <td className='text-start'>{e.hsn} </td>
                                                                <td className='text-start'> - {e.description}</td>
                                                              </tr>
                                                            )}
                                                            
                                                            </tbody>
                                                      </table>
                                                    </fieldset>
                                                    
                        
                                                    {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>: <span as='buton' className="btn btn-sm hovbuttonColour" onClick={this.uploadHSN}><i className="fas fa-save mr-2"/>Save</span>}
                                            <span as="button" className="btn btn-sm deletebutton ml-1"  onClick={()=>this.setState({showadd:false})} ><b style={{color:"#FA5733"}}> X</b> Cancel</span> 
                                          
                                                  </div>
                                                  :
                                                <ThemeProvider theme={theme}> 
                                                <MUIDataTable
                                                    className="table-sm px-3"
                                                    title={"HSN"}
                                                    data={this.state.hsnlists}
                                                    columns={columns}
                                                    options={options}
                                                />  
                                                </ThemeProvider>
                                                }
                                                </>
                                                }</div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>    
          
        )
       
    }
    
}
export default withRouter(HSNTable)