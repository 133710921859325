import React, { Component } from 'react'
import withRouter from "../../withRouter.js";
import FileServices from './FileServices'

class download extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            path:this.props.location.state.path,
            filename:this.props.location.state.name
             
        }
        this.download=this.download.bind(this)
        this.goBack=this.goBack.bind(this)
    }
    

    download(){
        let formData = new FormData();

        formData.append("path", this.state.path);
        formData.append("filename", this.state.filename);

        FileServices.getonefile(formData) 
                .then((response) => {
                  var link = document.createElement("a");
                  link.href = window.URL.createObjectURL(
                    new Blob([response.data], { type: "application/octet-stream" })
                  );
                  link.download = this.state.filename;
              
                  document.body.appendChild(link);
              
                  link.click();
                  setTimeout(function () {
                    window.URL.revokeObjectURL(link);
                  }, 200);
                })
                // .catch((error) => {})
                .catch((e)=>{
                  if(e.response.data.error ==="Unauthorized"){
                      alert("Your Session is Lost: Please login Back to resume");
                      this.props.navigate(`/`);
                  }
              })
                
    }

    componentDidMount(){
        this.download()
    }
    goBack(){
        this.props.navigate(-1);
      
      }
    render() {
        return (
            <div>
                 <div className="form-group col-md-2"> 
                  <button className="btn btn-success" type="button" onClick={this.goBack}>Close</button>
                  </div>
            </div>
        )
    }
}

export default withRouter(download)
