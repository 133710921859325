import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, financialyearend, financialyearstart, thousandsdisplay, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import InventoryService from "../InventoryService.js";
import { Button, Grid, TextField, ThemeProvider, createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { NumericFormat } from "react-number-format";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class DamageReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data:[],
      fromdate:financialyearstart,
      todate:new Date(),
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    // this.clearfilter = this.clearfilter.bind(this);
  }

  onSubmit(){
    let datadao = {
      tenantid: currentTenant,
      startdate: this.state.fromdate,
      enddate: this.state.todate,
      userid: userid,
      dataaccess:""
    }
    InventoryService.getDamageReportsData(datadao).then((response) => this.setState({data: response.data}));
  }


  handleFromDateChange = (date) => {
    this.setState({ fromdate: date,todate: moment(date).diff(this.state.todate, 'days')>=0?this.state.todate:date});
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };


  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
      userid: userid,
      dataaccess:""
    }

    InventoryService.getDamageReportsData(datadao).then((response) => this.setState({data: response.data}));
  }

  validate(values) {
    let errors = {};
    if (values.from <= values.to && values.to === false) {
      alert("To date must be greater than From date");
      errors.to = "To date must be greater than From date";
    }
    if (values.from === null) {
      alert("Please Select From date");
      errors.from = "Please Select From date";
    }
    if (!values.to === null) {
      alert("Please Select to date");
      errors.to = "Please Select to date";
    }
    return errors;
  }

  render() {

    const columns = [
      {name: "id",label: "Item id",options: {filter: false,sort: false,display:false,viewColumns:false}},
      {name: "itemname",label: "Item name",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "category",label: "Category",options: {filter: true,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "whname",label:"Warehouse",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "batchid",label: "Batch",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "quantity",label: "Quantity",options: {filter: false,sort: true,setCellProps: () => ({ align: 'center' })}},
      {name: "price",label: "Unit Price",options: {filter: false,sort: true,sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      {name: "totalloss",label: "Total",options: {filter: false,sort: true,sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      // {name: "comment",label: "Comment",options: {filter: true,sort: true,setCellProps: () => ({ align: 'center' })}},
      // {name: "status",label: "Status",options: {filter: true,sort: true,setCellProps: () => ({ align: 'center' })}},
     ];
     
     const options = {
       filterType: 'checkbox',
       selectableRows: "none",
     }

    return (
      <div>
      <Header/>
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item active">Damage Report</li>
            </ol>
            <div className="card-body">
              <div className="text-center">
              {this.state.loading === true ? <div className="text-center">{spin}</div> :
              <Formik initialValues={{
                      from: this.state.fromdate,
                      to: this.state.todate,
                    }}
                    onSubmit={this.onSubmit}
                    validateOnChange={false}
                    validate={this.validate}
                     enableReinitialize={true}>
                {() => <Form autoComplete="off" className="row">

                <fieldset>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justifyContent="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="From Date"
                                  name="from"
                                  format="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  value={this.state.fromdate}
                                  onChange={this.handleFromDateChange}
                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                  autoOk={true}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>

                          <fieldset style={{ marginLeft: "30px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justifyContent="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="To Date"
                                  name="to"
                                  format="dd/MM/yyyy"
                                  minDate={this.state.fromdate}
                                  value={this.state.todate}
                                  onChange={this.handleToDateChange}
                                  maxDate={new Date()}
                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                  autoOk={true}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>
                          <fieldset className="form-group col-md-1">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3"
                              type="submit"
                            >
                              <i className="fas fa-filter" />
                            </button>
                          </fieldset>
                          <fieldset className="col-md-12">
                          <ThemeProvider theme={theme}> 
                      <MUIDataTable
                       className="table-sm px-3"
                        title={"Damage"}
                        data={this.state.data}
                        columns={columns}
                       options={options}
                      />
                      </ThemeProvider>
                      </fieldset>
                  </Form>}
              </Formik>
                      }</div>
          </div>
        </div>
        <Footer />
      </div>
      </div>

    );
  }
}

export default withRouter(DamageReport);
