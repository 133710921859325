import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  userid,
  currentTenant,
  financialyearstart,
  financialyearend,
  dataaccess,
} from "../../Common/Credentials.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import GRNService from "./GRNServices";
import moment from "moment";
import { spin } from "../../Common/NewLoader.jsx";
import UserService from "../../users/UserService.js";
import { purchaseid } from "../../Common/Permissions.js";
import CustomToolbar from "../../CommonComponents/CustomToolbar.js";

import MUIDataTable from "mui-datatables";
import IOSSwitch from "../../CommonComponents/IOSSwitch";
import { FormControlLabel, Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
class GRN extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      completedgrn: [],
      pendinggrn: [],
      message: "",
      loading: false,
      UserPermissions: "",
      active: true,
      comp: false,
      pndg: false,
      fromdate: moment(new Date()).subtract(7, "days"),
      todate: new Date(),
    };
    this.refreshItems = this.refreshItems.bind(this);
  }

  handleActiveChange = () => {
    this.setState({ active: true, comp: false, pndg: false });
  };

  handlePendingChange = () => {
    this.setState({ active: false, comp: false, pndg: true });
  };

  handlecompChange = () => {
    this.setState({ comp: true, active: false, pndg: false });
  };

  refreshItems() {
    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
      userid: userid,
      dataaccess: dataaccess,
    };

    GRNService.retriveAllActiveGRN(datadao)
      .then((response) => {
        this.setState({
          items: response.data
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            .map((e) =>
              Object.assign(e, {
                month: moment(e.createdAt).format("MMMM YYYY"),
              })
            ),
        });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    GRNService.retriveAllPendingGRN(datadao)
      .then((response) => {
        this.setState({
          pendinggrn: response.data
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            .map((e) =>
              Object.assign(e, {
                month: moment(e.createdAt).format("MMMM YYYY"),
              })
            ),
        });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
      this.refreshCompletedGRNs(this.state.fromdate,this.state.todate)
    this.setState({ loading: false });
  }

  refreshCompletedGRNs = (from, to) => {
    let datadao = {
      tenantid: currentTenant,
      startdate: from,
      enddate: to,
      userid: userid,
      dataaccess: dataaccess,
    };
    GRNService.retriveAllCompletedGRN(datadao).then((response) => {
      this.setState({
        completedgrn: response.data
          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          .map((e) =>
            Object.assign(e, { month: moment(e.createdAt).format("MMMM YYYY") })
          ),
      });
    });

    this.setState({ loading: false });
  };

  handleFromDateChange = (date) => {
    this.setState({
      fromdate: date,
      todate:
        moment(date).diff(this.state.todate, "days") >= 0
          ? this.state.todate
          : date,
    });
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  handlecompletedDateChange = () => {
    this.refreshCompletedGRNs(this.state.fromdate, this.state.todate);
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    UserService.UserPermissions(userid).then((response) =>
      this.setState({ UserPermissions: response.data })
    );
    this.setState({ loading: true });
    this.refreshItems();
  }

  render() {
    const columns = [
      {
        name: "grnid",
        label: "GRN Id",
        options: { filter: false, sort: true, display: false },
      },
      {
        name: "customgrnid",
        label: "GRN Id",
        options: { filter: false, sort: true },
      },
      {
        name: "custompoid",
        label: "PO Id",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => (
            <Link to={{ pathname: "/viewpurchaseorder" }} state={{ id: value }}>
              {value}
            </Link>
          ),
        },
      },
      // {name: "supplierid",label:"Supplier Id",options: {filter: true,sort: true,}},
      {
        name: "supplier",
        label: "Supplier",
        options: { filter: true, sort: true },
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <>
                <span as="button" data-tip data-for="phno">
                  {value === undefined || value === null ? "Direct" : value}
                </span>
                {value === null
                  ? ""
                  : // //<Tooltip id="phno" place="top" effect="solid">Ph. {tableMeta.rowData[5]}</Tooltip>
                    ""}
              </>
            );
          },
        },
      },
      {
        name: "agentphno",
        label: "Agent Ph",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "createdbyname",
        label: "Created By",
        options: { filter: true, sort: true },
      },

      {
        name: "createdAt",
        options: {
          filter: false,
          sortOrder: "asc",
          sort: true,
          customBodyRender: (value) => {
            return moment(value).format("DD-MM-YYYY HH:mm:ss");
          },
        },
        label: "Date of Purchase",
      },
      {
        name: "approvestatus",
        label: "Status",
        options: { filter: true, sort: true },
      },
      {
        name: "month",
        label: "Month",
        options: { filter: true, sort: true, display: false },
      },
      {
        name: "View",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <>
                  <Link
                    as="button"
                    data-tip
                    data-for="view details"
                    className="fa fa-list-alt"
                    style={{ paddingRight: "5px" }}
                    to={{ pathname: "/viewgrnitems" }}
                    state={{
                      id: tableMeta.rowData.find((e) => e),
                      temp: this.state.pndg,
                    }}
                  ></Link>
                  {/* //<Tooltip id="view details" place="top" effect="solid">View</Tooltip> */}
                </>
              </>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {purchaseid === "2" || purchaseid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{ pathname: "/newgrn" }}
                state={{ id: -1, pagename: "Accept Goods" }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      downloadOptions: {
        filename: "GRN",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
    };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card ">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>

              <li className="breadcrumb-item active">GRN / Accepted Goods</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    {/* /.card */}
                    {/* <div className="card"> */}
                      <div>
                        {this.state.loading === true ? (
                          <span className="text-center">{spin}</span>
                        ) : (
                          <>
                            <div className="form-row">
                              <fieldset className="form-group col-md-4">
                                <span className="mx-3">Active</span>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      className="sm"
                                      checked={this.state.active}
                                      onChange={this.handleActiveChange}
                                      name="checkedB"
                                    />
                                  }
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-4">
                                <span className="mx-3">Pending</span>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      className="sm"
                                      checked={this.state.pndg}
                                      onChange={this.handlePendingChange}
                                      name="checkedB"
                                    />
                                  }
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-4">
                                <span className="mx-3">Completed</span>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      className="sm"
                                      checked={this.state.comp}
                                      onChange={this.handlecompChange}
                                      name="checkedB"
                                    />
                                  }
                                />
                              </fieldset>
                            </div>
                            {this.state.active === true ? (
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"GRN / Accepted Goods(Active)"}
                                data={this.state.items}
                                columns={columns}
                                options={options}
                              />
                            ) : (
                              ""
                            )}

                            {this.state.pndg === true ? (
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"GRN / Accepted Goods(Pending)"}
                                data={this.state.pendinggrn}
                                columns={columns}
                                options={options}
                              />
                            ) : (
                              ""
                            )}
                            {this.state.comp === true ? (
                              <>
                                <div className="form-row g-0 mb-3 mx-3">
                                  <fieldset>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Grid
                                        container
                                        justifyContent="space-around"
                                      >
                                        <DatePicker
                                          variant="inline"
                                          id="date-picker-dialog"
                                          label="From Date"
                                          name="from"
                                          format="dd/MM/yyyy"
                                          maxDate={new Date()}
                                          value={this.state.fromdate}
                                          onChange={this.handleFromDateChange}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="standard"
                                            />
                                          )}
                                          autoOk={true}
                                        />
                                      </Grid>
                                    </LocalizationProvider>
                                  </fieldset>

                                  <fieldset style={{ marginLeft: "30px" }}>
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Grid
                                        container
                                        justifyContent="space-around"
                                      >
                                        <DatePicker
                                          variant="inline"
                                          id="date-picker-dialog"
                                          label="To Date"
                                          name="to"
                                          format="dd/MM/yyyy"
                                          minDate={this.state.fromdate}
                                          value={this.state.todate}
                                          onChange={this.handleToDateChange}
                                          maxDate={new Date()}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              variant="standard"
                                            />
                                          )}
                                          autoOk={true}
                                        />
                                      </Grid>
                                    </LocalizationProvider>
                                  </fieldset>
                                  <fieldset className="form-group col-md-1">
                                    <button
                                      className="btn btn-sm hovbuttonColour mt-3"
                                      onClick={this.handlecompletedDateChange}
                                    >
                                      <i className="fas fa-filter" />
                                    </button>
                                  </fieldset>
                                </div>
                                <MUIDataTable
                                  className="table-sm px-3"
                                  title={"GRN / Accepted Goods (Completed)"}
                                  data={this.state.completedgrn}
                                  columns={columns}
                                  options={options}
                                />
                              </>
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </div>
                    {/* </div> */}
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(GRN);
