import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import CustomerTermsServices from "./CustomerTermsServices";
import { spin } from "../../Common/NewLoader";
// import UserService from '../../users/UserService'
import { appmasterid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";

import { ThemeProvider,Tooltip } from "@mui/material";
import { createTheme, TextField } from "@mui/material";
import UsercustomerService from "../Usercustomers/UsercustomerService";
import {NumericFormat} from "react-number-format";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
class CustomerTermsData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      CustomerTermsDetails: [],
      CreditRequests: [],
      loading: false,
      type: "Customer Terms",
      usercustomers: [],
      creditvalue: "",
      // UserPermissions:''
    };
  }

  handleApprove = (id) => {
    this.state.CreditRequests.find((e) => e.id === id).approvestate = true;
    this.state.CreditRequests.map(e=>e.id===id?e.approvestate=true:e.approvestate=false)
    this.setState({ CreditRequests: [...this.state.CreditRequests],creditvalue: this.state.CreditRequests.find((e) => e.id === id).reqvalue });
  };

  handleclose = (id) => {
    this.state.CreditRequests.find((e) => e.id === id).approvestate = false;
    this.setState({ CreditRequests: this.state.CreditRequests });
  };

  handleApproveCredit = (id) => {
    let data = {
      id: id,
      customer: this.state.CreditRequests.find((e) => e.id === id).customer,
      custid: this.state.CreditRequests.find((e) => e.id === id).custid,
      approvedby: userid,
      tenantid: currentTenant,
      creditvalue:this.state.creditvalue
    };
    CustomerTermsServices.UpdateRequestedCredit(data).then(() =>
      this.refresh()
    );
  };

  CancelRequest = (id) => {
    CustomerTermsServices.CancelRequestedCredit(id).then(() => this.refresh());
  };

  refresh() {
    // let username = AuthenticationService.getLoggedInUser()
    CustomerTermsServices.retriveCustomerTerms(currentTenant)
      .then((response) => {
        this.setState({
          CustomerTermsDetails: response.data.filter((e) =>
            this.state.usercustomers.length === 0
              ? e
              : this.state.usercustomers.includes(e.customer)
          ),
        });
        this.setState({ loading: false });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    CustomerTermsServices.GetAllRequestCredits(currentTenant)
      .then((response) => {
        this.setState({
          CreditRequests: response.data
            .filter((e) =>
              this.state.usercustomers.length === 0
                ? e
                : this.state.usercustomers.includes(e.customer)
            )
            .map((e) => Object.assign(e, { approvestate: false })),
        });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    UsercustomerService.GetUserCustomersbyUser(userid).then((res) =>
      this.setState({
        usercustomers:
          res.data === "" || res.data === undefined
            ? []
            : res.data.customers.split(","),
      })
    );
    this.refresh();
    this.setState({ loading: true });
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: true,
          display: false,
          viewColumns: false,
          sortOrder: "asc",
        },
      },

      {
        name: "customer",
        label: "Customer",
        options: { filter: false, sort: true },
      },
      {
        name: "creditvalue",
        label: "Credit Value",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "creditleft",
        label: "Credit Left",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "creditperiod",
        label: "Credit Period",
        options: { filter: true, sort: true },
      },
      {
        name: "creditstatus",
        label: "Credit",
        options: { filter: true, sort: true },
      },

      {
        name: "Actions",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="edit"
                  className="fa fa-edit textColour mr-2"
                  to={{pathname: "/newcustomerterms"}}
                    state= {{
                      id: tableMeta.rowData.find((e) => e),
                      pagename: "Edit Customer Terms"
                  }}
                ></Link>
                {/* <span
                  as="button"
                  data-tip
                  data-for="delete"
                  className="fa fa-trash text-danger"
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you wish to delete Customer ${tableMeta.rowData.find(
                          (e, i) => i.valueOf(e)
                        )}?`
                      )
                    )
                      this.deleteCustomer(tableMeta.rowData.find((e) => e));
                  }}
                ></span> */}
              {/* <Tooltip id="edit" place="top" effect="solid">
                  Edit
                </Tooltip>
              <Tooltip id="delete" place="top" effect="solid">
                  Delete
                </Tooltip> */}
              </>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{pathname: "/newcustomerterms"}}
                  state={{ id: -1, name: "New Terms"}}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };

    const columns2 = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: true,
          display: false,
          viewColumns: false,
          sortOrder: "asc",
        },
      },
      {
        name: "customer",
        label: "Customer",
        options: { filter: false, sort: true },
      },
      {
        name: "reqvalue",
        label: "Request credit ",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "requestedby",
        label: "requestedby",
        options: { filter: true, sort: true },
      },
      {
        name: "creditvalue",
        label: "Created Value",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "approvedby",
        label: "ApprovedBy",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <>{value === undefined || value === null ? "NA" : value}</>;
          },
        },
      },
      {
        name: "status",
        label: "Status",
        options: { filter: true, sort: true },
      },

      {
        name: "approvestate",
        label: "Actions",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                {tableMeta.rowData[6] === "Pending" ? (
                  <>
                    {value === true ? (
                      <div className="row">
                        <fieldset className="col-md-8">
                          <TextField
                            id="standard-error-helper-text"
                            type="number"
                            label="Credit Value"
                            value={this.state.creditvalue}
                            onChange={(e) =>
                              this.setState({ creditvalue: e.target.value })
                            }
                            variant="standard"
                            error={
                              this.state.creditvalue > tableMeta.rowData[2]
                            }
                            helperText={
                              this.state.creditvalue > tableMeta.rowData[2]
                                ? "Exceeds Credit Value"
                                : ""
                            }
                            fullWidth
                          />
                        </fieldset>
                        <fieldset className="form-group col-md-3 mt-3">
                          <span
                            as="button"
                            className=" btn btn-sm cursorPointer mr-1 hovbuttonColour"
                            onClick={() =>
                              this.handleApproveCredit(tableMeta.rowData[0])
                            }
                            disabled={
                              this.state.creditvalue === undefined ||
                              this.state.creditvalue === "" ||
                              this.state.creditvalue > tableMeta.rowData[2]
                            }
                          >
                            <i className="fa fa-check"></i>
                          </span>
                          <span
                            as="button"
                            className="cursorPointer btn btn-sm deletebutton"
                            onClick={() =>
                              this.handleclose(tableMeta.rowData[0])
                            }
                          >
                            <i className="fa fa-times"></i>
                          </span>
                        </fieldset>
                      </div>
                    ) : (
                      <>
                        <span
                          as="button"
                          data-tip
                          data-for="approve"
                          className="fa fa-check textColour cursorPointer mr-2"
                          onClick={() =>
                            this.handleApprove(tableMeta.rowData[0])
                          }
                        ></span>

                        <span
                          as="button"
                          data-tip
                          data-for="cancel"
                          className="fa fa-times text-danger cursorPointer"
                          onClick={() => this.CancelRequest(tableMeta.rowData[0])}
                        ></span>
                        {/* <Tooltip id="approve" place="top" effect="solid"> */}
                          {/* Approve */}
                        {/* </Tooltip> */}
                        {/* <Tooltip id="cancel" place="top" effect="solid"> */}
                          {/* Cancel */}
                        {/* </Tooltip> */}
                      </>
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
            );
          },
        },
      },
    ];

    const options2 = {
      filterType: "checkbox",
      selectableRows: "none",
    };

    return (
      <div>
        <Header />
        <Menu />

        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>

              <li className="breadcrumb-item active">Customer Terms</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Customer Terms</h3>
                      </div>

                      <div className="card-body">
                        <div>
                          {this.state.loading === true ? (
                            <div className="text-center">{spin}</div>
                          ) : (
                            <>
                              <fieldset>
                                <fieldset
                                  className={`form-group col-md-3 mr-2 text-center btn ${
                                    this.state.type === "Customer Terms"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={
                                    this.state.type === "Customer Terms"
                                  }
                                  onClick={() =>
                                    this.setState({ type: "Customer Terms" })
                                  }
                                >
                                  Customer Terms
                                </fieldset>
                                <fieldset
                                  className={`form-group col-md-3  btn text-center ${
                                    this.state.type === " Credit Requests"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={
                                    this.state.type === " Credit Requests"
                                  }
                                  onClick={() =>
                                    this.setState({ type: " Credit Requests" })
                                  }
                                >
                                  Credit Requests
                                  {this.state.type === " Credit Requests"?'':
                                  <span className="rounded-circle navbar-badge"  style={{fontSize:'5px',fontWeight:600,top:0,padding:'0px 4px' }}>
                                    {this.state.CreditRequests.filter(e=>e.status === "Pending").length>0?this.state.CreditRequests.filter(e=>e.status === "Pending").length:''}
                                  </span>
                                  }
                                </fieldset>
                              </fieldset>
                              {this.state.type === "Customer Terms" ? (
                                <ThemeProvider theme={theme}>
                                  <MUIDataTable
                                    className="table-sm px-3"
                                    title={"Customer Terms"}
                                    data={this.state.CustomerTermsDetails}
                                    columns={columns}
                                    options={options}
                                  />
                                </ThemeProvider>
                              ) : (
                                <ThemeProvider theme={theme}>
                                  <MUIDataTable
                                    className="table-sm px-3"
                                    title={"Customer Terms"}
                                    data={this.state.CreditRequests}
                                    columns={columns2}
                                    options={options2}
                                  />
                                </ThemeProvider>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(CustomerTermsData);
