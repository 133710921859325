import jsPDF from "jspdf"
import { address, address2, ccity, ccountry, clientname, companyemail, contactnumber, cstate, czip, logo } from "../Common/Credentials"
import autoTable from "jspdf-autotable"
import { ToWords } from "to-words";


class pdf {

 downloadpdf(tablecolp,tablecols,tablecolm,purchaseitem,servicelist,milestones,order,date,context1,podata,pototal){
    const toWords = new ToWords();
    var totalinwords = toWords.convert(pototal)
    const doc = new jsPDF('p', 'pt')
    doc.addImage(logo, "GIF",  40, 20, 100, 100)
    doc.setFontSize(16)
    doc.setFont('Times-Roman')
    
    doc.text(`${clientname}`,560,40,'right')
    doc.setFontSize(11)
    doc.text(`${address}, ${address2}`,560,55,'right')
    doc.text(`${ccity}, ${cstate} - ${czip}`,560,70,'right')
    doc.text(`${ccountry}`,560,85,'right')
    doc.text(`${companyemail}`,560,100,'right')
    doc.text(`${contactnumber}`,560,115,'right')
    doc.setFontSize(16)
    doc.text(`${order}`,200,140)
    doc.line(0, 150, 600, 150);
    doc.setFontSize(11)
    doc.text(`Date : ${date}`,560,180,'right')
    doc.text(`Order ID : ${podata.id}`,560,195,'right')
    doc.text('To,',40,180)
    doc.text(`${podata.supplier}`,50,195)
    doc.text(`${podata.supaddress2}`,50,210)
    // doc.text(`${tocontact}`,50,225)
    let context=doc.splitTextToSize(context1,510);  
    doc.text(context,40,300)
    if(purchaseitem.length !== 0){
    autoTable(doc,{
      theme:'grid',
      startY: 330,
      body: purchaseitem,
      columns:tablecolp,
    });
    if(servicelist.length !== 0){
      autoTable(doc,{
        theme:'grid',
        startY: doc.autoTable.previous.finalY + 20,
        body: servicelist,
        columns:tablecols,
      });
      }
    }else{
    if(servicelist.length !== 0){
    autoTable(doc,{
      theme:'grid',
      startY: 330,
      body: servicelist,
      columns:tablecols,
    });
    }
    if(milestones.length !== 0){
      autoTable(doc,{
        theme:'grid',
        startY: doc.autoTable.previous.finalY + 20,
        body: milestones,
        columns:tablecolm,
      });
      }
    }
   
    doc.text(`Total Amount : ${podata.pototalwtax} `,40, doc.autoTable.previous.finalY + 45)
    // doc.text(`${podata.pototalwtax}`,460, doc.autoTable.previous.finalY + 35)
    doc.text(`Total amount in words : ${totalinwords}`,40, doc.autoTable.previous.finalY + 60)
     // doc.text(`Tax amount`,360, doc.autoTable.previous.finalY + 20)
    // doc.text(`${totaltax}`,460, doc.autoTable.previous.finalY + 20)
    doc.text(`Payments Terms : ${podata.paymentterms} Days`,40, doc.autoTable.previous.finalY + 75)
    doc.text(`Advance Amount : ${podata.advanceamountreq}`,40, doc.autoTable.previous.finalY + 90)
    // doc.text(`${totaltax}`,460, doc.autoTable.previous.finalY + 20)
    doc.text('Sd/-.',40, doc.autoTable.previous.finalY + 200)
    doc.text('Purchase Team,',40, doc.autoTable.previous.finalY + 215)
    doc.text(`${clientname}`,40, doc.autoTable.previous.finalY + 230)
    doc.save(`${podata.id}.pdf`);
    doc.autoPrint(`abc`)
  }
}

export default new pdf()