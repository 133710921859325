import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { spin } from "../../Common/NewLoader";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import ProductionServices from "../ProductionServices";
import { currentTenant, userid } from "../../Common/Credentials";
import { Autocomplete } from "@mui/material";
import Footer from "../../Component/Footer";
import WarehouseServices from "../../Masters/Warehouse/WarehouseServices";
import { Form, Formik } from "formik";
import WorkCenterServices from "../../Masters/WorkCenter/WorkCenterServices";
import CategoryServices from "../../Masters/Category/CategoryServices";
import InventoryService from "../../inventory/InventoryService";
import withRouter from "../../withRouter";
import moment from "moment";

const NewProductionStore=(props)=> {
  const navigate = useNavigate();
  const [joborder, setJoborder] = useState([]);
  const [id] = useState(
    props.location.state === undefined ? "" : props.location.state.id
  );
  // const [joborderList, setJoborderList] = useState([]);
  const [storename,setStoreName]= React.useState(props.location.state === undefined ? "" : props.location.state.name);
  const [warehouse, setWarehouse] = React.useState(
    props.location.state === undefined ? "" : props.location.state.warehouse
  );
  const [whid, setWhId] = React.useState(
    props.location.state === undefined ? "" : props.location.state.whid
  );
  const [whList, setWhList] = React.useState([]);
  const [wcid, setWcId] = React.useState(
    props.location.state === undefined ? "" : props.location.state.wcid
  );
  const [workcenter, setWorkcenter] = React.useState(
    props.location.state === undefined ? "" : props.location.state.workcenter
  );
  const [itemname, setItemname] = React.useState("");
  const [itemid, setItemId] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [wcList, setWcList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [category, setCategory] = React.useState("");
  const [catid, setCatId] = React.useState("");
  const [itemsList, setItemList] = React.useState([]);
  const [filterItemList, setFilterItemList] = React.useState([]);
  const [addedItemList, setAddedItemList] = React.useState([]);
  const [rawItemlist,setRawItemList]=React.useState([]);
  const [filterRawmaterials,setFilterRawmaterials]=React.useState([]);
  const [price,setPrice] = React.useState('');
  const [selectbutton,setSelectbutton] = React.useState(false);
  const [avilQuantity,setAvilQuantity] = React.useState(0);
  const [batchid,setbatchid] = React.useState('');
  const [custombatchid,setCustombatchid] = React.useState("")
  const [supplier,setSupplier] = React.useState("")
  const [supplierid,setSupplierid] = React.useState("")
  const [invaddeddate,setInvaddeddate] = React.useState("")
  const [loader,setLoader] = React.useState(false)

  useEffect(() => {
    ProductionServices.getJobOrderIdList(currentTenant).then((response) => {
      setJoborder(response.data);
    });
    WarehouseServices.getWarehousesidname(currentTenant).then((response) => {
      setWhList(response.data);
    });

    CategoryServices.getCategory(currentTenant).then((response) =>
      setCategoryList(response.data.filter((e) => e.categorytype === "Product"))
    );

    InventoryService.getItemNames(currentTenant).then((response) =>
      setItemList(response.data)
    );

    InventoryService.getRawMaterials(currentTenant).then((response)=>{
      setRawItemList(response.data)
    })
  }, []);

  const handleStoreNameChange = e =>{
    setStoreName(e.target.value);
  }

  const handleWorkCenterChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setWcId("");
      setWorkcenter("");
    } else {
      setWcId(newValue.id);
      setWorkcenter(newValue.name);
    }
  };

  const handleWarehouseChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setWhId("");
      setWarehouse("");
    } else {
      setWhId(newValue.id);
      setWarehouse(newValue.name);
      WorkCenterServices.WorkCenterbywarehouse(newValue.id).then((response) =>
        setWcList(response.data)
      );
    }
  };

  const handleCategoryChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setCatId("");
      setCategory("");
    } else {
      setCatId(newValue.id);
      setCategory(newValue.category);
      setFilterItemList(itemsList.filter((e) => e.catid === newValue.id));
    }
  };

  const handleSelectItem = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setItemname("");
      setItemId("");
    } else {
      setItemname(newValue.name);
      setItemId(newValue.id);
      setFilterRawmaterials(rawItemlist.filter(e=>e.itemid === newValue.id))
      setSelectbutton(true)
    }
  };

  const handelSelect = (id)=>{
    if (id !== "") {
      setbatchid(id)
      setCustombatchid(filterRawmaterials.find((e)=>e.id === id).custombatchid)
      setPrice(filterRawmaterials.find((e)=>e.id === id).price)
      setAvilQuantity(filterRawmaterials.find((e)=>e.id === id).currentStock)
      setSupplier(filterRawmaterials.find((e)=>e.id === id).supplier)
      setSupplierid(filterRawmaterials.find((e)=>e.id === id).supplierid)
      setInvaddeddate(filterRawmaterials.find((e)=>e.id === id).addeddate)
      setSelectbutton(false)
    }
  }

  const handleQuantityChange = (e) => {
    setQuantity(e.target.value);
  };

  const handleAddItemList = () => {
    if (itemname !== "" && quantity !== "" && parseFloat(quantity)!==0 && parseFloat(quantity)<=avilQuantity) {
      let data = {
        id:batchid,
        batchid:batchid,
        itemid: itemid,
        itemname: itemname,
        quantity: parseFloat(quantity),
        price:price,
        custombatchid:custombatchid,
        supplier:supplier,
        supplierid:supplierid,
        invaddeddate:invaddeddate,
      };
      addedItemList.push(data);
      rawItemlist.find(e=>e.id === batchid).currentStock = rawItemlist.find(e=>e.id === batchid).currentStock - parseFloat(quantity)
      setQuantity("");
      // setAvilQuantity(avilQuantity - parseInt(quantity))
      setSelectbutton(true)
      setFilterRawmaterials([])
      setItemname('')
    }
  };

  const handelRemoveItem = (value) => {
    if (value !== "") {
      let i = addedItemList.find((e, index) => index === value).id
      let q = addedItemList.find((e, index) => index === value).quantity
      setAddedItemList(addedItemList.filter((e, index) => index !== value));
      rawItemlist.find(e=>e.id === i).currentStock = rawItemlist.find(e=>e.id === i).currentStock + q
      setAvilQuantity(0)
    }
  };

  const handleSubmit = () => {
    let data = {
      wcid: wcid,
      workcenter: workcenter,
      whid: whid,
      name:storename,
      warehouse: warehouse,
      storeitems: addedItemList,
      tenantid: currentTenant,
      createdby: userid,
      updatedby: userid,
    };
    setLoader(true)
    ProductionServices.AddtoStore(data).then((response) =>
      navigate("/store")
    ).catch((error) =>setLoader(false))
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <span
                as="button"
                className=" text-secondary cursorPointer"
                onClick={() => navigate(-1)}
              >
                ❮ Back &nbsp;&nbsp;
              </span>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/production">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/store">Store</Link>
            </li>
            <li className="breadcrumb-item active">{id===-1||id===""||id===undefined || id === null?"New Store":"Add Store Items"}</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              {joborder === [] ? (
                <div className="text-center">{spin}</div>
              ) : (
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">{id===-1|| id===""||id===undefined || id === null?"New Store":"Add Store Items"}</h3>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={{}}
                      // onSubmit={onSubmit}
                      validateOnChange={false}
                      // validate={validate}
                      enableReinitialize={true}
                    >
                      {({ setFieldValue, values }) => (
                        <Form
                          autoComplete="off"
                          style={{ paddingLeft: "30px" }}
                        >
                          <div className="form-row mt-3">
                            {id !== -1 ? (
                              <>
                                <fieldset className="form-group col-md-4">
                                  <TextField
                                    className="form-control"
                                    type="text"
                                    name="warehouse"
                                    value={warehouse}
                                    label={`Select Org Unit`}
                                    variant="standard"
                                    disabled
                                    fullWidth
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-4">
                                  <TextField
                                    className="form-control"
                                    type="text"
                                    name="workcenter"
                                    value={workcenter}
                                    label={`Select WorkCenter`}
                                    variant="standard"
                                    disabled
                                    fullWidth
                                  />
                                </fieldset>
                              </>
                            ) : (
                              <>
                                <fieldset className="form-group col-md-4">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="warehouse"
                                    options={whList}
                                    getOptionLabel={(option) =>
                                      option.name || ""
                                    }
                                    
                                    
                                    value={
                                      values.warehouse ? undefined : undefined
                                    }
                                    onChange={handleWarehouseChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="warehouse"
                                        value={warehouse}
                                        label={`Select Org Unit (${warehouse})`}
                                        variant="standard"
                                        error={whid === ""}
                                        helperText={
                                          whid === "" ? "Empty field!" : " "
                                        }
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-4">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="workcenter"
                                    options={wcList}
                                    getOptionLabel={(option) =>
                                      option.name || ""
                                    }
                                    
                                    
                                    value={
                                      values.workcenter ? undefined : undefined
                                    }
                                    onChange={handleWorkCenterChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="workcenter"
                                        value={workcenter}
                                        label={`Select WorkCenter/Production Unit (${workcenter})`}
                                        variant="standard"
                                        error={wcid === ""}
                                        helperText={
                                          wcid === "" ? "Empty field!" : " "
                                        }
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>
                              </>
                            )}
                          <fieldset className="col-md-4">
                              <TextField
                                id="standard-error-helper-text"
                                label="Store Name"
                                type="text"
                                value={storename}
                                onChange={handleStoreNameChange}
                                variant="standard"
                                error={storename === ""}
                                helperText={
                                  storename === "" ? "Empty field!" : " "
                                }
                                fullWidth
                                disabled = {id !== -1}
                              />
                            </fieldset>

                            <fieldset className="form-group col-md-4">
                              <Autocomplete
                                id="subaccount"
                                className="subacc-select"
                                name="category"
                                options={categoryList}
                                getOptionLabel={(option) => option.category}
                                
                                
                                value={values.category ? undefined : undefined}
                                onChange={handleCategoryChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="form-control"
                                    type="text"
                                    name="category"
                                    value={category}
                                    label={`Select Category (${category})`}
                                    variant="standard"
                                    error={catid === ""}
                                    helperText={
                                      catid === "" ? "Empty field!" : " "
                                    }
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>
                            <fieldset className="col-md-4">
                              <Autocomplete
                                id="subaccount"
                                className="subacc-select"
                                name="itemname"
                                options={filterItemList}
                                getOptionLabel={(option) => option.name}
                                
                                
                                value={values.itemname ? undefined : undefined}
                                onChange={handleSelectItem}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    id="standard-error-helper-text"
                                    label={`Select Item (${itemname})`}
                                    value={itemname}
                                    variant="standard"
                                    error={itemname === ""}
                                    helperText={
                                      itemname === "" ? "Empty field!" : " "
                                    }
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>
                            {filterRawmaterials.length === 0 ?'':
                           
                           selectbutton === true ? (
                            
                            <TableContainer className="mt-3">
                                <Typography>Items List</Typography>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="text-center">
                                        Itemname
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Supplier
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Batch
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Added Date
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Price
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Actions
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {filterRawmaterials.map((e, index) => (
                                      <TableRow key={index}>
                                        <TableCell className="text-center">
                                          {e.itemname}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.supplier}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.custombatchid}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.addeddate.substring(0,19).replace("T"," ")}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.currentStock}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.price}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          <span
                                            as="buttom"
                                            className="btn btn-sm hovbuttonColour"
                                            onClick={() =>
                                              handelSelect(e.id)
                                            }
                                          >
                                            Select
                                          </span>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>)
                              :''}

                          {selectbutton === false ? <>
                            <fieldset className="col-md-4">
                              <TextField
                                id="standard-error-helper-text"
                                label="Price"
                                type="number"
                                value={price}
                                // onChange={handleQuantityChange}
                                variant="standard"
                                fullWidth
                              />
                            </fieldset>
                            <fieldset className="col-md-4">
                              <TextField
                                id="standard-error-helper-text"
                                label={`Quantity (${avilQuantity})`}
                                type="number"
                                value={quantity}
                                onChange={handleQuantityChange}
                                variant="standard"
                                error={quantity === ""|| parseFloat(quantity)===0 || parseFloat(quantity)>avilQuantity}
                                helperText={
                                  quantity === "" || parseFloat(quantity)===0? "Empty field!" : parseFloat(quantity)>avilQuantity ?"Out of Stock" : " "
                                }
                                fullWidth
                              />
                            </fieldset>
                            <fieldset className="col-md-4 d-flex align-items-center">
                              <span
                                as="button"
                                className="btn btn-sm hovbuttonColour"
                                onClick={handleAddItemList}
                              >
                                Add
                              </span>
                            </fieldset>
                         </>:''}
                          </div>

                          {addedItemList.length !== 0 ? (
                            <>
                              <TableContainer className="mt-3">
                                <Typography>Items List</Typography>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="text-center">
                                        Itemname
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Price
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Actions
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {addedItemList.map((e, index) => (
                                      <TableRow key={index}>
                                        <TableCell className="text-center">
                                          {e.itemname}
                                        </TableCell>
                                        <TableCell className="text-center">
                                        {e.price}
                                      </TableCell>
                                        <TableCell className="text-center">
                                          {e.quantity}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          <span
                                            as="buttom"
                                            className="btn btn-sm deletebutton"
                                            onClick={() =>
                                              handelRemoveItem(index)
                                            }
                                          >
                                            Remove
                                          </span>
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </>
                          ) : (
                            ""
                          )}
                           <fieldset className="col-md-3 mt-3">
                                <button
                                  className="btn btn-sm hovbuttonColour"
                                  onClick={handleSubmit}
                                  disabled={
                                    whid === "" ||
                                    wcid === "" ||
                                    storename === ''||
                                    itemsList.length === 0 || loader
                                  }
                                >
                                  Submit
                                </button>
                              </fieldset>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(NewProductionStore);
