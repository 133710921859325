// import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { Backdrop, CircularProgress, FormControl, InputAdornment, InputLabel, MenuItem, Select } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { Field, Form, Formik } from "formik";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import React, { Component } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, einvoice, userid } from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import MasterService from "../MasterService.js";
import CustomerService from "./CustomerService.js";
import SalesServices from "../../Sales/SalesServices.js";
import Swal from "sweetalert2";
import SettingsServices from "../../Settings/SettingsServices.js";

class NewCustomer extends Component {
  constructor(props) {
    super(props);
    const { name } = this.props.location.state;
    this.state = {
      // type: 'password',
      name: name,
      id: "",
      StateId: "",
      state:'',
      CountryId: "",
      country:'',
      CityId: "",
      city:'',
      message: "",
      customcustomerid:'',
      countryprefix: ["91 IND", "93 AFG"],
      CountryData: [],
      StateData: [],
      CityData: [],
      statecode:'',
      item: [],
      companyname: "",
      contactname: "",
      prefix: "",
      contactnumber: "",
      alternatenumber:'',
      email: "",
      address: "",
      address2: "",
      pan: "",
      gstin: "",
      validgstin:false,
      zip: "",
      error: "",
      loader: false,
      currencylist:[],
      currency:'',
      phonecode:'',
      TaxtypeData:[],
      taxtype:'',
      registrationtype:'Unregistered',
      Registrationtypes:[{"id":0,"type":"Registered"},{"id":1,"type":"Unregistered"},{"id":2,"type":"Composition"},{"id":3,"type":"SEZ (Special economic zone)"}],
      // appmaster:false,
      // accounts:false,
      // inventory:false,
      // sales:false,
      // purchase:false,
      gstvaldloader:false,
      einvoice:false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    //  this.handleChange=this.handleChange.bind(this)
    this.validate = this.validate.bind(this);
  }

  // handleProductChange = (e) => {
  //   this.setState({ ...this.state, [e.target.name]: e.target.checked });
  // };

  onSubmit(values) {
    this.setState({ loader: true });

   
    let data = {
      tenantId:currentTenant,
      reqparam:this.state.gstin,
    }
    let newCustomer = {
      companyname: values.companyname,
      contactname: values.contactname,
      contactnumber: values.contactnumber.replace(/[^0-9]+/ig, "") ,
      email: values.email,
      address: values.address,
      address2: values.address2,
      pan: values.pan?.toUpperCase(),
      gstin: values.gstin?.toUpperCase(),
      zip: values.zip,
      country:this.state.CountryId,
      countryname:this.state.country,
      state:this.state.StateId,
      statename:this.state.state,
      statecode:this.state.statecode,
      city:this.state.CityId,
      cityname:this.state.city,
      updatedby: userid,
      tenantId: currentTenant,
      createdby: userid,
      currency:this.state.currency,
      alternatenumber:this.state.alternatenumber===undefined || this.state.alternatenumber===null?'':this.state.alternatenumber.replace(/[^0-9]+/ig, "") ,
      phonecode:this.state.phonecode,
      taxtype:this.state.taxtype,
      registrationtype:this.state.registrationtype,
      // appmaster:this.state.appmaster,
      // accounts:this.state.accounts,
      // inventory:this.state.inventory,
      // sales:this.state.sales,
      // purchase:this.state.purchase
    };
    let updateCustomer = {
      id: this.state.id,
      companyname: values.companyname,
      contactname: values.contactname,
      contactnumber: values.contactnumber.replace(/[^0-9]+/ig, "") ,
      email: values.email,
      address: values.address,
      address2: values.address2,
      pan: values.pan?.toUpperCase(),
      gstin: values.gstin?.toUpperCase(),
      zip: values.zip,
      country:this.state.CountryId,
      countryname:this.state.country,
      state:this.state.StateId,
      statename:this.state.state,
      statecode:this.state.statecode,
      city:this.state.CityId,
      cityname:this.state.city,
      updatedby: userid,
      currency:this.state.currency,
      alternatenumber:this.state.alternatenumber===undefined || this.state.alternatenumber===null?'':this.state.alternatenumber.replace(/[^0-9]+/ig, "") ,
      customcustomerid:this.state.customcustomerid,
      phonecode:this.state.phonecode,
      taxtype:this.state.taxtype,
      registrationtype:this.state.registrationtype,
    };

    //  MasterService.createTenant(newtenant).then(() => this.props.navigate(`/tenants`))
    if (this.state.id === "") {
      CustomerService.createCustomer(newCustomer)
        .then(() => this.props.navigate({ pathname: "/customers"},{state:{message: values.companyname + "   : Created Successfully"}})
        
        // this.props.navigate(`/customers`, {
        //     message: values.companyname + "   : Created Successfully",
        //   })
        )
        .catch((error) =>{
          if(error.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
          this.setState({
            error: error.response.data.message,
            loader: false,
          })
        }
        );
    } else {
      CustomerService.updateCustomer(updateCustomer).then(() =>
      this.props.navigate({ pathname: "/customers"},{state:{message: values.companyname + "   : Updated Successfully"}
        })
      )
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    }
  }

  handleCurrency = (value,values) => {
    if(values !== '' && typeof values === 'object' && values !==null){
      this.setState({currencyid:values.id, currency: values.currency }) 
    }else{
      this.setState({currencyid:'', currency: '' }) 
    }
  }

  handleTaxTypeChange=(e)=>{
    this.setState({ taxtype: e.target.value,});
  }

  handleRegistrationTypeChange=(e)=>{
    this.setState({ registrationtype: e.target.value,validgstin:false});
  }

  validateGSTIN = () => {

    if(this.state.gstvaldloader === false){
    let data = {
      tenantId:currentTenant,
      reqparam:this.state.gstin.trim(),
    }
    this.setState({gstvaldloader:true});
    
    SalesServices.validateGSTIN(data).then((response)=>{
      this.setState({ gstvaldloader:false})
    if(response.data.body === null || response.data.body.statusCodeValue === 400){
      Swal.fire({
        title: `Invalid GSTIN`,
        text: ".",
        icon: "error",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      })
    }else{
     this.setState({gstvaldloader:false, validgstin:true, pan: this.state.gstin.substring(2,12)})
    }
    }
    ).catch(()=>this.setState({ gstvaldloader:false}))
  }
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    CustomerService.getCountries().then((response) =>
      this.setState({
        CountryData: response.data,
      })
    );

    MasterService.GetAllCurrency().then((response) => {
      this.setState({currencylist: response.data})
    });

    MasterService.getTaxtypes().then((response) =>
      this.setState({
        TaxtypeData: response.data,
      })
    );

    SettingsServices.geteinvoicecredentials(currentTenant).then((response) => {
      this.setState({
        einvoice: response?.data?.status === "ACTIVE" ? true : false,
      });
    });

    const { id } = this.props.location.state;

    //let {id} = this.props.location.state
    if (id < 1) {
      //  this.setState({id:id})
      return;
    }
    CustomerService.retriveCustomer(id).then((response) =>
      this.setState({
        id: response.data.id,
        companyname: response.data.companyname,
        contactname: response.data.contactname,
        prefix: response.data.prefix,
        contactnumber: response.data.contactnumber,
        email: response.data.email,
        address: response.data.address,
        address2: response.data.address2,
        pan: response.data.pan,
        gstin: response.data.gstin,
        zip: response.data.zip,
        CountryId: response.data.country,
        StateId: response.data.state,
        CityId: response.data.city,
        country: response.data.countryname,
        state: response.data.statename,
        city: response.data.cityname,
        currency:response.data.currency,
        alternatenumber:response.data.alternatenumber,
        customcustomerid:response.data.customcustomerid,
        phonecode:response.data.phonecode,
        taxtype:response.data.taxtype,
        registrationtype:response.data.registrationtype,
        statecode:response.data.states.code
      })
    );
  }

  ChangeteCountry = (value, values) => {
    if(values!=='' && typeof values === 'object' && values!==null){
    this.setState({country:values.name, CountryId: values.id,phonecode:values.phonecode,state :'', StateId: '',city:'',CityId:'',CityData:[] });
    CustomerService.getStates(values.id).then((response) => {
      this.setState({
        StateData: response.data,
      });
    });
  }else{
    this.setState({
      country:'', CountryId:'', state :'',StateData:[],CityData:[], StateId: '',city:'',CityId:''
    })
  }
  };
  ChangeState = (value, values) => {
    if(values!=='' && typeof values === 'object' && values !== null){
    this.setState({state:values.name, StateId: values.id,statecode:values.code });
    CustomerService.getCities(values.id).then((response) => {
      this.setState({
        CityData: response.data,city:'',CityId:''
      });
    });
   }else{
     this.setState({
    state:'', StateId: '',city:'',CityId:''
     })
   }
  };

  handleCity=(value,values)=>{
    if(values!=='' && typeof values === 'object' && values !== null){
    this.setState({city: values.name,CityId: values.id})
  }else{
    this.setState({city:'',CityId:''})
  }
  }

  validate(values) {
    let errors = {};
    var num = /^[0-9]+$/;
    if (!values.companyname) {
      errors.companyname = "Company Name is required";
    }
    //  else if (!chars.test(values.companyname)) {
    //   errors.companyname = "Only Characters are allowed";
    // }
    if (!values.contactname) {
      errors.contactname = "Contact Name is required";
    } 
    // else if (!chars.test(values.contactname)) {
    //   errors.contactname = "Only Characters are allowed";
    // }

    if (!values.contactnumber) {
      errors.contactnumber = "Contact Number is required";
    } 
   else if(values.country === "India"){
    if (!/^[7-8-9][0-9]{9}$/i.test(values.contactnumber)) {
      errors.contactnumber = "Contact Number should be 10 Numbers";
    }
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (!values.address) {
      errors.address = "Address is required";
    }else if(einvoice && values.address.length>100){
      errors.address = "Max 100 character";
    }
    if (!values.address2) {
      errors.address2 = "Area/Place is required";
    }else if(values.address2.length>100){
      errors.address2 = "Max 100 character";
    }
    
  if(this.state.country ==='India' && this.state.registrationtype==="Registered"){
    if (!values.gstin) {
      errors.gstin = "GSTIN is required";
    } else if (!/^[A-Z0-9]{15}$/i.test(values.gstin)) {
      errors.gstin = "Invalid GSTIN should be 15 Charactors EX: 22ABCDE1234F1Z5";
    } else if (
      !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z0-9]{1}/i.test(
        values.gstin
      )
    ) {
      errors.gstin = "Invalid Ex: 22ABCDE1234F1Z5";
    }
    
    if(parseInt(values.gstin.substring(0, 2)) !== parseInt(this.state.statecode)){
      errors.gstin = `Invalid GSTIN for state ${this.state.state}`;
    }
  }
  if(this.state.country ==='India'){
    if (!values.zip) {
      errors.zip = "Zip is required";
    } else if (!num.test(values.zip)) {
      errors.zip = "Only Numbers allowed";
    } else if (!/^[0-9]{5,6}$/i.test(values.zip)) {
      errors.zip = "Should be 5 or 6 Numbers";
    }
  }else{
    if (!values.zip|| this.state.zip === '') {
      errors.zip = "PO Box No. is required";
    }
  }

    if (this.state.CountryId === "") {
      errors.country = "Please Select Country";
    }
    if (this.state.StateId === "") {
      errors.state = "Please Select State";
    }
    if (this.state.CityId === "") {
      errors.city = "Please Select City";
    }
    if (this.state.currency === "" || !values.currency) {
      errors.currency = "Please Select Currency";
    }



    return errors;
  }

  //  handleChange = (event) => {
  //     this.setState({
  //       ...this.state,
  //       value: event.target.value
  //     })
  //   }

  render() {
    // let countrypre = [this.state.countryprefix]

    let {
      id,
      companyname,
      contactname,
      contactnumber,
      email,
      address,
      address2,
      pan,
      gstin,
      zip,
      CountryData,
      StateData,
      CityData,
      country,
      state,
      city,
      statecode,
      currencylist,
      currency,
      alternatenumber,
    } = this.state;


    return (
      <div>
         <Header />
        <Menu />
       
        <div className="content-wrapper">
          <div className="card ">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/customers"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to={{ pathname: "/customers"}} state= {{ message: "" }}>
                  Customers
                </Link>
              </li>
              <li className="breadcrumb-item active">{this.state.name}</li>
            </ol>

            <div className="text-center text-danger">{this.state.error}</div>
            <div className="card-body">
              <Formik
                initialValues={{
                  id,
                  companyname,
                  contactname,
                  contactnumber,
                  email,
                  address,
                  address2,
                  pan,
                  gstin,
                  zip,
                  CountryData,
                  StateData,
                  CityData,
                  country,
                  state,
                  statecode,
                  city,
                  currencylist,
                  currency,
                  alternatenumber
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form autoComplete="off">
                    <Backdrop
                          sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                          }}
                          open={this.state.gstvaldloader}
                          // onClick={handleClose}
                        >
                          <CircularProgress color="inherit" />
                        </Backdrop>
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="id"
                          placeholder="Id"
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="companyname"
                          label="Company Name"
                          value={this.state.companyname}
                          onChange={(e) =>
                            this.setState({ companyname: e.target.value })
                          }
                          autoComplete="off"
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="contactname"
                          label="Contact Name"
                          value={this.state.contactname}
                          onChange={(e) =>
                            this.setState({ contactname: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="email"
                          label="Email Id"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="address"
                          label="Address"
                          value={this.state.address}
                          onChange={(e) =>
                            this.setState({ address: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="address2"
                          label="Area/Place"
                          value={this.state.address2}
                          onChange={(e) =>
                            this.setState({ address2: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="country"
                          options={CountryData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.country ? undefined : undefined}
                          onChange={this.ChangeteCountry}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="country"
                              value={this.state.country}
                              label={`Select Country (${this.state.country})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="state"
                          options={StateData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.state ? undefined : undefined}
                          onChange={this.ChangeState}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="state"
                              value={this.state.state}
                              label={`Select State (${this.state.state})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="city"
                          options={CityData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.city ? undefined : undefined}
                          onChange={this.handleCity}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name='city'
                              value={this.state.city}
                              label={`Select City (${this.state.city})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="zip"
                          label={this.state.country==="India"?"Zip/Pincode":"PO BOX No."}
                          value={this.state.zip}
                          onChange={(e) =>
                            this.setState({ zip: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="number"
                          name="contactnumber"
                          label="Contact Number"
                          value={this.state.contactnumber}
                          onChange={(e) =>
                            this.setState({ contactnumber: e.target.value.replace(/[^0-9]+/ig, "")  })
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                               +{this.state.phonecode}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="number"
                          name="alternatenumber"
                          label="Alternate Number"
                          value={this.state.alternatenumber}
                          onChange={(e) =>
                            this.setState({ alternatenumber: e.target.value.replace(/[^0-9]+/ig, "")  })
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +{this.state.phonecode}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="currency"
                          options={currencylist}
                          getOptionLabel={(option) => option.currency}
                          
                          
                          value={values.currency?undefined:undefined}
                          onChange={this.handleCurrency}
                          renderInput={params => (  
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="currency"
                              value={this.state.currency}
                              label={`Select Currency (${this.state.currency})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4 m-0">
                                <FormControl variant="standard" className="w-100">
                                  <InputLabel id="demo-simple-select-standard-label">Tax Type</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={this.state.taxtype}
                                    onChange={this.handleTaxTypeChange}
                                    label="Tax Type"
                                  >
                                    {this.state.TaxtypeData.map((e,i)=>{
                                      return (
                                      <MenuItem value={e.type} key={i}>{e.type}</MenuItem>)
                                    })}
                                   
                                  </Select>
                                </FormControl>
                                </fieldset>

                                {/* <fieldset className="form-group col-md-12 m-0"/> */}
                                {/* <fieldset className="form-group col-md-3 mt-2">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={this.state.registrationtype}
                                          onChange={() =>
                                            this.setState({
                                              registrationtype: !this.state.registrationtype,
                                            })
                                          }
                                          name="ordertype"
                                          color="primary"
                                          // disabled={this.state.orderfrom === 1}
                                        />
                                      }
                                      label="Tax Registered?"
                                    />
                                  </fieldset> */}
                           {this.state.country==="India"?
                            <fieldset className="form-group col-md-4 m-0">
                                <FormControl variant="standard" className="w-100">
                                  <InputLabel id="demo-simple-select-standard-label">Registration Type</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={this.state.registrationtype}
                                    onChange={this.handleRegistrationTypeChange}

                                    // onChange={(e) =>
                                    //   this.setState({
                                    //     registrationtype: e.target.value,
                                    //   })}
                                    label="Registration Type"
                                  >
                                    {this.state.Registrationtypes.map((e,i)=>{
                                      return (
                                        <MenuItem value={e.type} key={i}>{e.type}</MenuItem>)
                                      })}
                                   
                                  </Select>
                                </FormControl>
                                </fieldset>
                                :''}
                                  
                                  <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="gstin"
                          label={this.state.country==="India"?"GSTIN":"GSTIN / VAT No."}
                          value={this.state.gstin}
                          onChange={(e) =>
                            this.setState({ gstin: e.target.value?.trim().toUpperCase(),error:'',validgstin:false })
                          }
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </fieldset>
                     {this.state.country==="India"?
                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="pan"
                          label="PAN"
                          value={this.state.pan}
                          onChange={(e) =>
                            this.setState({ pan: e.target.value })
                          }
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </fieldset>:''}

                     

                               


                      <div className="form-group col-md-12" />
                      <fieldset className="form-group col-md-12">
                      {
                      einvoice && this.state.einvoice && this.state.country ==='India' && this.state.registrationtype==="Registered" && this.state.validgstin===false?
                      <span className="btn btn-sm hovbuttonColour mr-1" onClick={this.validateGSTIN}>Validate GSTIN
                      </span>
                      :
                          this.state.loader === true ? (
                            <button className="btn btn-sm hovbuttonColour mr-1 " disabled>
                              <Spinner animation="border" size="sm" />
                              &nbsp;{"Saving"}
                            </button>
                          ) : (
                            <button className="btn btn-sm hovbuttonColour mr-1" type="submit"><i className="fas fa-save mr-2"/>Save</button>
                          )}
                        <Link
                          as="button"
                          className="btn btn-sm deletebutton"
                          to={{pathname: "/customers"}}
                            state={{ message: "" }}
                        >
                          <b className="text-danger"> X </b>Close
                        </Link>
                      </fieldset>
                    </div>
                    {/* <Link as="button" type="submit" className="btn btn-success" to={{pathname: '/ledgers' }} state ={ {message:this.state.message}}}>Save</Link> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

        <Footer />
      </div>
    );
  }
}
export default withRouter(NewCustomer);
