import React, { useEffect } from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Menu from "../Component/Menu.js";
import Header from "../Component/Header.js";
import { screens } from "../Common/Products.js";
import Credentials from "../Common/Credentials.js";
import mastersIcom from "../images/Icons/generic.png"


const MastersMenu = () => {

  useEffect(() =>{
    Credentials.setSelectedMenu("Masters")
  },[])

  return (
    <div>
      <Header />
      <Menu />
      <section className="content-wrapper">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item text-secondary">
            <Link to="/index">Home</Link>
          </li>
          <li className="breadcrumb-item active">Masters</li>
        </ol>
        <div className="h-max border-slate-300 bg-white p-4 rounded-lg ">
          {/* <Link
            to={{ pathname: "/index" }}
            state={{ message: "" }}
            className="textColour py-2"
          >
            <ArrowOutwardOutlinedIcon fontSize="small" /> Dashboard
          </Link> */}
          <div className="row  gap-x-8 gap-y-6">
          {screens.includes("Admin Masters") ?
            <div className="col-sm-12 col-md-6 col-lg-4 w-full">
              <span className="font-weight-bold my-3">
                {/* <i className="nav-icon fas fas fa-th textColour" /> */}
                <img className="nav-icon" width="25" src={mastersIcom}/>
                <span className="pl-2">Admin Masters</span>
              </span>
              <ul className="menuul pl-4 ml-3">
                <li>
                  <Link
                    className="textColour p-1"
                    to={{pathname: "/users"}}
                    state={{ message: ""}}
                  >
                    Users
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/roles" }}
                    state={{ message: "" }}
                  >
                    Roles
                  </Link>
                </li> 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/usercustomers" }}
                    state={{ message: "" }}
                  >
                    User-Customers
                  </Link>
                  </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/salescaltable" }}
                    state={{ message: "" }}
                  >
                    Sales Calculation
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/customerterms" }}
                    state={{ message: "" }}
                  >
                    Customer Terms
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/bank" }}
                    state={{ message: "" }}
                  >
                    Banks
                  </Link>
                </li>
              </ul>
            </div>:""}
            {screens.includes("General Masters")?
            <div className="w-full col-sm-12 col-md-6 col-lg-4">
              <span className="font-weight-bold my-3">
                {/* <TextSnippetIcon sx={{ fontSize: 18 }} />{" "} */}
                <img className="nav-icon" width="25" src={mastersIcom}/>
                <span className="pl-2"> General Masters</span>
              </span>
              <ul className="menuul pl-4 ml-3">
              {/* <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/taxes" }}
                    state={{ message: ""  }}
                  >
                    Taxes
                  </Link>
                </li> */}
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/employees" }}
                    state={{ message: "",employeetype:0  }}
                  >
                    Employees
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/customers" }}
                    state={{ message: "" }}
                  >
                    Customers
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/suppliers" }}
                    state={{ message: "" }}
                  >
                    Suppliers
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/agents" }}
                    state={{ message: "" }}
                  >
                    Agents
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/warehouse" }}
                    state={{ message: "" }}
                  >
                    Org Unit
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/department" }}
                    state={{ message: "" }}
                  >
                    Department
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/workcenter" }}
                    state={{ message: "" }}
                  >
                    Workcenter/Production Unit
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour pl-1"
                    to={{ pathname: "/transporter" }}
                    state={{ message: "" }}
                  >
                    Transporter
                  </Link>
                </li>
              </ul>
            </div>
            :""}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default withRouter(MastersMenu);
