import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

class DepartmentServices{
    getDepartments(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getalldept/${id}`,)
     }
     addDepartment(adddepartment){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/adddept`,adddepartment)
     }

     deleteDepartment(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletedept/${id}`)
     }

     deptbywarehouse(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getdeptbywhid/${id}`,)
     }
     
}
export default new DepartmentServices()