import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  clientname,
  currentTenant,
  dataaccess,
  financialyearend,
  financialyearstart,
  logo,
  userid,
} from "../../Common/Credentials";
import moment from "moment";
import { spin } from "../../Common/NewLoader";
import SalesServices from "../SalesServices";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import { salesid } from "../../Common/Permissions";
import MUIDataTable from "mui-datatables";
import { Grid, TextField, ThemeProvider } from "@mui/material";
import { createTheme, IconButton, Tooltip } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import IOSSwitch from "../../CommonComponents/IOSSwitch";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import UsercustomerService from "../../Masters/Usercustomers/UsercustomerService";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class DnTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      usercustomers: [],
      dndata: [],
      dncompleteddata: [],
      loading: false,
      active: true,
      comp: false,
      fromdate: moment(new Date()).subtract(7, "days"),
      todate: new Date(),
    };
  }

  pdf() {
    let pdfdata = this.state.dndata;
    pdfdata.map(
      (e) =>
        (pdfdata.find((i) => i.id === e.id).createdAt = moment(
          pdfdata.find((i) => i.id === e.id).createdAt
        ).format("DD-MM-YYYY HH:mm:ss"))
    );
    const curdate = moment().format("DD-MM-YYYY HH:mm:ss");
    const columns = [
      { header: "DN Id", dataKey: "customdnid" },
      { header: "Saleorder Id", dataKey: "customsoid" },
      { header: "Customer", dataKey: "customer" },
      { header: "Date", dataKey: "createdAt" },
    ];
    var doc = new jsPDF("p", "pt", [842, 595]);
    autoTable(doc, {
      theme: "grid",
      startY: 120,
      didDrawPage: (HookData) => {
        HookData.settings.margin.top = 120;
      },
      body: pdfdata,
      columns: columns,
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;

      doc.addImage(logo, "G IF", 40, 20, 60, 60, { baseline: "top" });
      doc.setFontSize(8);
      doc.text(`${curdate}`, 560, 20, "right");
      doc.setFontSize(16);
      doc.text(`${clientname}`, 180, 40, { baseline: "top" });
      doc.setFontSize(14);
      doc.text(`Active Sale Orders`, 210, 70, { baseline: "top" });
      doc.line(0, 90, 600, 90);
      doc.setFontSize(10);

      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }
    doc.save("ActiveDeliverynotes.pdf");
  }

  handleActiveChange = () => {
    this.setState({ active: true, comp: false });
  };
  handlecompChange = () => {
    this.setState({ comp: true, active: false });
  };

  handleFromDateChange = (date) => {
    this.setState({
      fromdate: date,
      todate:
        moment(date).diff(this.state.todate, "days") >= 0
          ? this.state.todate
          : date,
    });
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  handleDateChange = () => {
    this.refreshCompleted(this.state.fromdate, this.state.todate);
  };

  refresh() {
    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
      userid: userid,
      dataaccess: dataaccess,
    };

    SalesServices.GetAllDn(datadao)
      .then((response) => {
        this.setState({
          dndata: response.data
            .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
            .filter((e) =>
              this.state.usercustomers.length === 0
                ? e
                : this.state.usercustomers.includes(e.customer)
            ),
          loading: false,
        });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }
  refreshCompleted = (from, to) => {
    let datadao = {
      tenantid: currentTenant,
      startdate: from,
      enddate: to,
      userid: userid,
      dataaccess: dataaccess,
    };

    SalesServices.GetAllDnCompleted(datadao).then((response) => {
      this.setState({
        dncompleteddata: response.data
          .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
          .filter((e) =>
            this.state.usercustomers.length === 0
              ? e
              : this.state.usercustomers.includes(e.customer)
          ),
        loading: false,
      });
    });
    this.setState({});
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    UsercustomerService.GetUserCustomersbyUser(userid).then((res) =>
      this.setState({
        usercustomers:
          res.data === "" || res.data === undefined
            ? []
            : res.data.customers.split(","),
      })
    );
    this.refresh();
    this.refreshCompleted(moment(new Date()).subtract(7, "days"), new Date());
  }

  render() {
    let columns = [
      // {name: "dnid",label: "DN",options: {filter: false,sort: true, display: false}},
      {
        name: "customdnid",
        label: "DN Id",
        options: { filter: false, sort: true },
      },
      {
        name: "customsoid",
        label: "Saleorder Id",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <>
                {value === null ? (
                  "Direct"
                ) : (
                  <Link
                    to={{ pathname: "/saleorderdetails" }}
                    state={{
                      id: this.state.dndata.find((e) => e.customsoid === value)
                        ?.soid,
                      bvstatus: 0,
                    }}
                  >
                    {value}
                  </Link>
                )}
              </>
            );
          },
        },
      },
      {
        name: "invoice",
        label: "Tax Invoice Id",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <>
                <Link
                  to={{ pathname: "/viewsalesinvoice" }}
                  state={{
                    id: this.state.dndata.find((e) => e.invoice === value)
                      .invid,
                    pagename: "Tax Invoice Details",
                    type: "Tax Invoice",
                  }}
                >
                  {value}
                </Link>
              </>
            );
          },
        },
      },
      {
        name: "customer",
        label: "Customer",
        options: { filter: true, sort: true },
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <span as="button" data-tip data-for="phno">
                {value === undefined || value === null ? "Direct" : value}
              </span>
            );
          },
        },
      },
      {
        name: "createdbyname",
        label: "Created By",
        options: { filter: true, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      },
      { name: "type", label: "Type", options: { filter: true, sort: true } },
      {
        name: "status",
        label: "Status",
        options: { filter: true, sort: true },
      },
      {
        name: "View",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="fa fa-list-alt"
                  to={{ pathname: "/viewdeliverynote" }}
                  state={{
                    id: tableMeta.rowData.find((e) => e),
                    soid: tableMeta.rowData[1],
                  }}
                ></Link>
                {/* <Tooltip id="view details" place="top" effect="solid">View</Tooltip> */}
              </>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {salesid === "2" || salesid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{ pathname: "/newdeliverynote" }}
                state={{ id: -1, pagename: "New Delivery Note" }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      downloadOptions: {
        filename: this.state.active?"Active Delivery Notes":"Completed Delivery Notes",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
    };

    let compcolumns = [
      // {name: "dnid",label: "DN",options: {filter: false,sort: true, display: false}},
      {
        name: "customdnid",
        label: "DN Id",
        options: { filter: false, sort: true },
      },
      {
        name: "customsoid",
        label: "Saleorder Id",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <>
                {value === null ? (
                  "Direct"
                ) : (
                  <Link
                    to={{ pathname: "/saleorderdetails" }}
                    state={{
                      id: this.state.dncompleteddata.find(
                        (e) => e.customsoid === value
                      )?.soid,
                      bvstatus: 0,
                    }}
                  >
                    {value}
                  </Link>
                )}
              </>
            );
          },
        },
      },
      {
        name: "invoice",
        label: "Tax Invoice Id",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <>
                <Link
                  to={{ pathname: "/viewsalesinvoice" }}
                  state={{
                    id: this.state.dncompleteddata.find(
                      (e) => e.invoice === value
                    ).invid,
                    pagename: "Tax Invoice Details",
                    type: "Tax Invoice",
                  }}
                >
                  {value}
                </Link>
              </>
            );
          },
        },
      },
      {
        name: "customer",
        label: "Customer",
        options: { filter: true, sort: true },
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta) => {
            return (
              <span as="button" data-tip data-for="phno">
                {value === undefined || value === null ? "Direct" : value}
              </span>
            );
          },
        },
      },
      {
        name: "createdbyname",
        label: "Created By",
        options: { filter: true, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      },
      { name: "type", label: "Type", options: { filter: true, sort: true } },
      {
        name: "status",
        label: "Status",
        options: { filter: true, sort: true },
      },
      {
        name: "View",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="fa fa-list-alt"
                  to={{ pathname: "/viewdeliverynote" }}
                  state={{
                    id: tableMeta.rowData.find((e) => e),
                    soid: tableMeta.rowData[1],
                  }}
                ></Link>
                {/* <Tooltip id="view details" place="top" effect="solid">View</Tooltip> */}
              </>
            );
          },
        },
      },
    ];

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card ">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>

              <li className="breadcrumb-item active">Delivery Note</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Delivery Note</h3>
                      </div>

                      <div className="card-body">
                        <div>
                          {this.state.loading === true ? (
                            <div className="text-center">{spin}</div>
                          ) : (
                            <>
                              <div className="form-row">
                                <fieldset className="form-group col-md-4">
                                  <span className="mx-3">Active</span>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        className="sm"
                                        checked={this.state.active}
                                        onChange={this.handleActiveChange}
                                        name="checkedB"
                                      />
                                    }
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-4">
                                  <span className="mx-3">Completed</span>
                                  <FormControlLabel
                                    control={
                                      <IOSSwitch
                                        className="sm"
                                        checked={this.state.comp}
                                        onChange={this.handlecompChange}
                                        name="checkedB"
                                      />
                                    }
                                  />
                                </fieldset>
                              </div>
                              {this.state.active === true ? (
                                <ThemeProvider theme={theme}>
                                  <MUIDataTable
                                    className="table-sm px-3"
                                    title={"Delivery Note"}
                                    data={this.state.dndata}
                                    columns={columns}
                                    options={options}
                                  />
                                </ThemeProvider>
                              ) : (
                                ""
                              )}
                              {this.state.comp === true ? (<>
                                <div className="form-row g-0 mb-3">
                                <fieldset>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-around"
                                    >
                                      <DatePicker
                                        variant="inline"
                                        id="date-picker-dialog"
                                        label="From Date"
                                        name="from"
                                        format="dd/MM/yyyy"
                                        maxDate={new Date()}
                                        value={this.state.fromdate}
                                        onChange={this.handleFromDateChange}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                          />
                                        )}
                                        autoOk={true}
                                      />
                                    </Grid>
                                  </LocalizationProvider>
                                </fieldset>

                                <fieldset style={{ marginLeft: "30px" }}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <Grid
                                      container
                                      justifyContent="space-around"
                                    >
                                      <DatePicker
                                        variant="inline"
                                        id="date-picker-dialog"
                                        label="To Date"
                                        name="to"
                                        format="dd/MM/yyyy"
                                        minDate={this.state.fromdate}
                                        value={this.state.todate}
                                        onChange={this.handleToDateChange}
                                        maxDate={new Date()}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                          />
                                        )}
                                        autoOk={true}
                                      />
                                    </Grid>
                                  </LocalizationProvider>
                                </fieldset>
                                <fieldset className="form-group col-md-1">
                                  <button
                                    className="btn btn-sm hovbuttonColour mt-3"
                                    onClick={this.handleDateChange}
                                  >
                                    <i className="fas fa-filter" />
                                  </button>
                                </fieldset>
                              </div>

                                <ThemeProvider theme={theme}>
                                  <MUIDataTable
                                    className="table-sm px-3"
                                    title={"Delivery Note"}
                                    data={this.state.dncompleteddata}
                                    columns={compcolumns}
                                    options={options}
                                  />
                                </ThemeProvider>
                             </> ) : (
                                ""
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(DnTable);
