// import { createTheme, ThemeProvider } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import CustomToolbar from '../../../src/CommonComponents/CustomToolbar'
import MUIDataTable from 'mui-datatables';
import TourServices from '../../Traacs/Invoice/TourServices';
import { ThemeProvider, createTheme } from '@mui/material';
import withRouter from '../../withRouter';
import Footer from '../../Component/Footer';
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Credentials, { currentTenant, thousandsdisplay } from '../../Common/Credentials';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';


const theme = createTheme({
    overrides: {
        MuiTableCell: {
            head: {
                backgroundColor: "#e9ecef !important",
                border: [[1, "solid", "#ece6e6"]],
            },
            body: {
                border: [[1, "solid", "#ece6e6"]],
            },
        },
    },
});


const InvoiceMainPage = () => {
    const [invoiceList, setInvoiceList] = useState([]);


    useEffect(() => {
        Credentials.setSelectedMenu("Salestravel")
        getInvoiceData();
        
    }, [])

    // const delteDataHandler=(e)=>{
        
    // }

      const delteDataHandler = (e) => {
        if (confirm('Are you sure you want to Delete this thing into the database?')) {
          TourServices.deleteByInvoiceId(e).then((r) => {
            console.log(r.data);
            getMasterList();
          })
          console.log('Thing was Deleted to the database.');
        } else {

          console.log('Thing was not Deleted to the database.');
        }

      }

    const getInvoiceData = () => {
       

        TourServices.getByInvoiceTenantId(currentTenant).then((r) => {
          console.log(r.data);
          setInvoiceList(r.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1));

        })
    }
    const columns = [
        {
            name: "customid",
            label: "Invoice Id",
            options: { filter: true, sort: true },
        },
        {
            name: "customer",
            label: "Customer",
            options: { filter: true, sort: true },
        },
        {
            name: "docno",
            label: "Document No",
            options: { filter: true, sort: true },
        },
        {name: "createdAt",label: "Date",options: {filter: false,sort: true, 
            customBodyRender:(tableMeta)=>{
              return(moment(tableMeta).format('DD-MM-YYYY HH:mm'))
            }}},
            {name: "totalinvoiceamount",label: "Total Amount",options: {filter: false,sort: true,
                customBodyRender:(tableMeta)=>{
                  return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
                },
                setCellProps: value => ({ style: { textAlign:'right'}}),
              }},
        {
            name: "ledgerentry",
            label: "Ledger Entry",
            options: { filter: true, sort: true },
        },
        
        // {
        //     name: "department",
        //     label: "Department",
        //     options: { filter: true, sort: true },
        // },
        // {
        //     name: "advancerecipt",
        //     label: "Advance Recipt",
        //     options: { filter: true, sort: true },
        // },
        // {
        //     name: "againstdocno",
        //     label: "Against Doc No",
        //     options: { filter: true, sort: true },
        // },
        {
            name: "status",
            label: "Status",
            options: { filter: true, sort: true },
        },
        {
            name: "Action",
            options: {
                filter: false,
                sort: false,
                empty: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {/* <button
                  as="button"
                  data-tip
                  data-for="delete"
                  className="btn btn-sm "
                 to={{ state: {id: tableMeta.rowData.find(e=>e) ,pagename:"Employee" }  }}
                  onClick={()=>
                delteDataHandler(tableMeta.rowData.find(e=>e))
                  }
                ><i className='fa fa-trash'></i></button> */}
                            {/* <Link as="button" className="btn btn-sm hovbuttonColour mr-2"
                  to={{ pathname: "/mastercar"}} state= {{id: tableMeta.rowData.find(e=>e) ,pagename:"Edit Master Cards" }}>Edit</Link> */}

                <Link as="button" className="btn btn-sm  mr-2"
                  to={{ pathname: "/viewinvoices"}} state= {{id: tableMeta.rowData.find(e=>e) }}><i className='fa fa-list-alt'></i></Link>
                           
                        </>
                    );
                },
            },
        },
    ];

    const options = {
        filterType: "checkbox",
        selectableRows: "none",
        customToolbar: () => {
            return (
                <>
                    
                    <Link
                        as="button"
                        className="float-right btn btn-sm m-0 p-0"
                        to={{ pathname: "/newtravelinvoice"}} 
                        state={{ id: -1, pagename: "Add New Invocies" }}
                    >
                        <CustomToolbar />
                    </Link>
                    
                </>
            );
        },
    };

    return (

        <>
            <Header />
            <Menu />
            <div className="content-wrapper w-100% h-100%">
                <div className="card">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item text-secondary">
                            <Link to="/index">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Sales Travel Invoices </li>
                    </ol>


                 
                            <ThemeProvider theme={theme}>
                                <MUIDataTable
                                    className="table-sm px-3"
                                    title={"Invoices"}
                                    data={invoiceList}
                                    columns={columns}
                                    options={options}
                                />
                            </ThemeProvider>
                        
                </div>
            </div>
            <Footer />

        </>
    )
}

export default withRouter(InvoiceMainPage);