import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  clientname,
  currentTenant,
  logo,
  userid,
} from "../../Common/Credentials";
// import moment from "moment";
import { spin } from "../../Common/NewLoader";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {
  IconButton,
  Tooltip,
} from "@mui/material";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ProductionServices from "../ProductionServices";
import { productionid } from "../../Common/Permissions";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class QATable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false,
    };
  }

  pdf = () => {
    let pdfdata = this.state.active;
    pdfdata.map(
      (e) =>
        (pdfdata.find((i) => i.id === e.id).createdAt = moment(
          pdfdata.find((i) => i.id === e.id).createdAt
        ).format("DD-MM-YYYY hh:mm"))
    );
    pdfdata.map(
      (e) =>
        (pdfdata.find((i) => i.id === e.id).startdate = moment(
          pdfdata.find((i) => i.id === e.id).startdate
        ).format("DD-MM-YYYY"))
    );
    const columns = [
      { header: "Estimation Id", dataKey: "id" },
      { header: "SaleOrder Id", dataKey: "soid" },
      { header: "Workcenter", dataKey: "workcenter" },
      { header: "Type", dataKey: "estimationtype" },
      { header: "Status", dataKey: "status" },
      { header: "Satrt Date", dataKey: "startdate" },
      { header: "Duration", dataKey: "duration" },
      { header: "Date", dataKey: "createdAt" },
    ];
    var doc = new jsPDF("p", "pt");
    doc.addImage(logo, "GIF", 40, 20, 60, 60);
    doc.setFontSize(16);
    doc.setFont("Times-Roman");
    doc.text(`${clientname}`, 180, 40);
    doc.setFontSize(14);
    doc.text(`Active Sale Orders`, 210, 80);
    doc.setFontSize(8);
    doc.text(`${moment().format("DD-MM-YYYY hh:mm")}`, 510, 80);
    doc.line(0, 90, 600, 90);
    autoTable(doc, {
      theme: "grid",
      startY: 100,
      columnStyles: { 7: { halign: "right" } },
      body: pdfdata,
      columns: columns,
    });
    doc.save("ActiveSaleordes.pdf");
    this.refresh();
  };

  handlesoChange = () => {
    this.setState({ so: true, comp: false, canl: false });
  };
  handlecompChange = () => {
    this.setState({ comp: true, so: false, canl: false });
  };
  handlecanlChange = () => {
    this.setState({ canl: true, so: false, comp: false });
  };

  refresh() {
    ProductionServices.GetallQA(currentTenant).then((response) => {
      this.setState({
        data: response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1),
        loading: false,
      });
    });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.refresh();
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Indenting Id",
        options: { filter: false, sort: true },
      },
      {
        name: "joid",
        label: "Job Order Id",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              <Link
                as="button"
                data-tip
                data-for="view details"
                to={{
                  pathname: "/joborder"}}
                  state= {value}
              >
                {value}
              </Link>
            );
          },
        },
      },
      {
        name: "createdby",
        label: "Created By",
        options: { filter: true, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-yyyy hh:mm");
          },
        },
      },
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
               {console.log(tableMeta)}
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="fa fa-list-alt"
                  style={{ color: "#152f5f"}}
                  to={{
                    pathname: "/qadetails"}}
                    state= {this.state.data.find(e=>e.id === tableMeta.rowData[0])}
                ></Link>
                {/* <Tooltip id="view details" place="top" effect="solid">
                  View
                </Tooltip> */}
              </>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
         
            productionid === '2' || productionid === '3'? 
            <>
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{
                  pathname: "/newqa"}}
                  state= {{
                    id: -1,
                    pagename: "New InProcess",
                    salestype: false,
                }}
              >
                <CustomToolbar />
              </Link>
            <span as="button" onClick={this.pdf}>
              <React.Fragment>
                <Tooltip title={"pdf"}>
                  <IconButton>
                    <i className="fa fa-file-pdf"></i>
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            </span>
          </>:''
        );
      },
    };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>

              <li className="breadcrumb-item active">Quality Analysis</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Quality Analysis</h3>
                      </div>

                      <div className="card-body">
                        {this.state.loading === true ? (
                          <span className="text-center">{spin}</span>
                        ) : (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Quality Analysis"}
                              data={this.state.data}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                        )}
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(QATable);
