
import React, { Component } from 'react'

import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import Menu from '../../Component/Menu'

//import SalesCalculation from './SalesCalculation';
import SalesCalTable from './SalesCalTable'

 export default class salesCalculationsMain extends Component {
    render() {
      return (
        <div>
        <Header />
           <Menu/>
            <>
              <div className="content-wrapper">
                <SalesCalTable />
              </div>       
            </>
            <Footer/>
        </div>
      )
    }
 }

