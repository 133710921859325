import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { clientname, logo, thousandsdisplay } from "../Common/Credentials";
import { spin } from "../Common/NewLoader";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
// import { ToWords } from "to-words";
import jsPDF from "jspdf";
import { NumericFormat } from "react-number-format";
import Footer from "../Component/Footer";
import downloadfiles from "../Common/upload/downloadfiles";
import AssetsService from "./AssetsService";
import { assetmanagementid } from "../Common/Permissions";
import withRouter from "../withRouter";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import FileServices from "../Common/upload/FileServices";

const AssetDetails = (props) => {
  const navigate = useNavigate();
  const [id] = useState(props.location.state.data.id);
  const [data] = useState(props.location.state.data);
  const [datalist,setDatalist] = useState([])

  const handlepdfDownload = () => {
    const curdate = moment().format("DD-MM-YYYY HH:mm:ss");
    let date = moment(data.createdAt).format("DD-MM-YYYY");
    let startdate = moment(data.startdate).format("DD-MM-YYYY");
    let mfdate = moment(data.manufacturedate).format("DD-MM-YYYY");

    // const toWords = new ToWords();
    var doc = new jsPDF("p", "pt", [842, 595]);
    doc.setFontSize(10);
    doc.text(`Asset Id : ${id}`, 40, 130);
    doc.text(`Itemname : ${data.itemname}`, 40, 145);
    doc.text(`Asset Type : ${data.assettype}`, 40, 160);
    if (data.immoveabletype !== "NA") {
      doc.text(`Immoveable Type : ${data.immoveabletype}`, 40, 175);
    }
    if (data.moveabletype !== "NA") {
      doc.text(`Moveable Type : ${data.moveabletype}`, 40, 175);
    }
    doc.text(`Date : ${date}`, 40, 190);
    doc.text(`Amount : ${data.baseamount}`, 40, 205);
    doc.text(`Org Unit : ${data.warehouse}`, 40, 220);
    doc.text(`User : ${data.user}`, 40, 235);
    doc.text(`Location : ${data.location}`, 40, 250);
    doc.text(`Depreciation(%) : ${data.depreciation}%`, 40, 265);
    doc.text(`Depreciation Period : ${data.depreciationperiod} Years`, 40, 280);
    doc.text(`Purchase Date : ${startdate}`, 40, 295);
    doc.text(`Type : ${data.type}`, 40, 310);
    doc.text(`Manufacture Date : ${mfdate}`, 40, 325);
    doc.text(`Warranty : ${data.warranty} Months`, 40, 340);

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;

      doc.addImage(logo, "GIF", 40, 20, 60, 60, { baseline: "top" });
      doc.setFontSize(8);
      doc.text(`${curdate}`, 560, 20, "right");
      doc.setFontSize(16);
      doc.text(`${clientname}`, 180, 40, { baseline: "top" });
      doc.setFontSize(14);
      doc.text("Asset Details", 210, 70, { baseline: "top" });
      doc.line(0, 90, 600, 90);
      doc.setFontSize(10);

      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }

    doc.save(`${id} Details.pdf`);
  };

  useEffect(() => {
    if (props.location.state.assettype === "Thirdparty") {
      let list = props?.location?.state.data?.thirdpartyassetitems;
      list?.map((e) => {
        if(e.file !== null && e.file !== undefined && e.file !== ""){
        let formData = new FormData();
        formData.append("path", e.path);
        formData.append("filename", e.file);
        FileServices.getonefile(formData).then(
          (response) =>
            Object.assign(e, { filedata: URL.createObjectURL(
              new Blob([response.data], { type: "application/octet-stream" }))
             })
        );
            }
      });
      list = [...list]
      console.log(list);
      setDatalist([...list])
    }
  }, []);

  const handledownloaddoc = () => {
    downloadfiles.download(data.path, data.file);
  };

  const handleMaintenanceStatus = (status) => {
    AssetsService.MaintenanceStatus(id, status).then(() =>
      navigate("/asset", {
        state: {
          sold: props.location.state.sold,
          rent: props.location.state.rent,
          active: props.location.state.active,
          type: props.location.state.type,
          stype: props.location.state.stype,
          assettype: props.location.state.assettype,
        },
      })
    );
  };

  const handleRemoveThirdpartyAsset=(serialnos)=>{
    let sdata = {
      id:data.customid,
      list:serialnos
    }
      AssetsService.RetunThirdPartyAssets(sdata).then(()=>navigate("/asset",{state:{message: "",sold:false,rent:false,active:true,type:'Rental',stype:'Sales'}}))
  }

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/asset" }}
                state={{
                  sold: props.location.state.sold,
                  rent: props.location.state.rent,
                  active: props.location.state.active,
                  type: props.location.state.type,
                  stype: props.location.state.stype,
                  assettype: props.location.state.assettype,
                }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/assetmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link
                to={{ pathname: "/asset" }}
                state={{
                  sold: props.location.state.sold,
                  rent: props.location.state.rent,
                  active: props.location.state.active,
                  type: props.location.state.type,
                  stype: props.location.state.stype,
                  assettype: props.location.state.assettype,
                }}
              >
                Assets
              </Link>
            </li>
            <li className="breadcrumb-item active">Asset Details</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              {data === "" ? (
                <div>{spin}</div>
              ) : (
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Asset Details</h3>
                    <>
                      <button
                        className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-envelope text-md ml-1"
                        data-tip
                        data-for="mail"
                        onClick={() => this.handleShow()}
                      ></button>
                      <button
                        className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md"
                        data-tip
                        data-for="pdf"
                        onClick={handlepdfDownload}
                      ></button>
                      {/* <Tooltip id="mail" place="top" effect="solid">
                        Send Mail
                      </Tooltip>
                      <Tooltip id="pdf" place="top" effect="solid">
                        Download pdf
                      </Tooltip> */}
                    </>
                  </div>
                  <div className="card-body">
                    <table className="float-left">
                      <tbody>
                        <tr>
                          <td>Asset Id</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.customid}</td>
                        </tr>
                        <tr>
                          <td>Itemname</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.itemname}</td>
                        </tr>
                        <tr>
                          <td>Asset Type</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.assettype}</td>
                        </tr>
                        {data.immoveabletype === "NA" ? (
                          ""
                        ) : (
                          <tr>
                            <td>Immoveable Type</td>
                            <td>
                              &nbsp;&nbsp;:&nbsp;&nbsp; {data.immoveabletype}
                            </td>
                          </tr>
                        )}
                        {data.moveabletype === "NA" ? (
                          ""
                        ) : (
                          <tr>
                            <td>Moveable Type</td>
                            <td>
                              &nbsp;&nbsp;:&nbsp;&nbsp;{data.moveabletype}
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>Date</td>
                          <td>
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {moment(data.createdAt).format("DD-MM-YYYY HH:mm")}
                          </td>
                        </tr>
                        {/* <tr>
                          <td>Quantity</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.quantity}</td>
                        </tr> */}
                        <tr>
                          <td>Amount</td>
                          <td>
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            <NumericFormat
                              className="pr-3"
                              displayType={"text"}
                              value={data.baseamount}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              disabled={true}
                              thousandSeparator={true}
                              thousandsGroupStyle={thousandsdisplay}
                            ></NumericFormat>
                          </td>
                        </tr>
                        <tr>
                          <td>Warehouse</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.warehouse}</td>
                        </tr>
                        {data.department === undefined ||
                        data.department === null ? (
                          ""
                        ) : (
                          <tr>
                            <td>Department</td>
                            <td>&nbsp;&nbsp;:&nbsp;&nbsp; {data.department}</td>
                          </tr>
                        )}
                        {data.workcenter === undefined ||
                        data.workcenter === null ? (
                          ""
                        ) : (
                          <tr>
                            <td>Workcenter</td>
                            <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.workcenter}</td>
                          </tr>
                        )}
                        <tr>
                          <td>User</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp; {data.user || data.username}</td>
                        </tr>
                        <tr>
                          <td>Location</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp; {data.location}</td>
                        </tr>
                        {props.location.state.assettype === "Own" ? (
                          <>
                            <tr>
                              <td>Depreciation per Year (%)</td>
                              <td>
                                &nbsp;&nbsp;:&nbsp;&nbsp; {data.depreciation} %
                              </td>
                            </tr>
                            <tr>
                              <td>Depreciation Period</td>
                              <td>
                                &nbsp;&nbsp;:&nbsp;&nbsp;{" "}
                                {data.depreciationperiod} Years
                              </td>
                            </tr>
                            <tr>
                              <td>Purchase Date</td>
                              <td>
                                &nbsp;&nbsp;:&nbsp;&nbsp;{" "}
                                {moment(data.depreciationdate).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Type</td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp; {data.type}</td>
                            </tr>
                            <tr>
                              <td>Manufacture Date</td>
                              <td>
                                &nbsp;&nbsp;:&nbsp;&nbsp;{" "}
                                {moment(data.manufacturedate).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Warranty</td>
                              <td>
                                &nbsp;&nbsp;:&nbsp;&nbsp; {data.warranty} Months
                              </td>
                            </tr>
                            <tr>
                              <td>Warranty docs</td>

                              <td>
                                &nbsp;&nbsp;:&nbsp;&nbsp;{" "}
                                {data.file === "" ? (
                                  "No docs found"
                                ) : (
                                  <button
                                    className="btn btn-link fa fa-file-pdf text-sm m-0"
                                    data-tip
                                    data-for="download"
                                    onClick={handledownloaddoc}
                                  >
                                    {data.file}
                                  </button>
                                )}
                              </td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                        {data.moveabletype === "Transports" ? (
                          <>
                            <tr>
                              <td>No. of wheels</td>
                              <td>&nbsp;&nbsp;:&nbsp;&nbsp; {data.wheels}</td>
                            </tr>
                            <tr>
                              <td>Vehicle Number</td>
                              <td>
                                &nbsp;&nbsp;:&nbsp;&nbsp; {data.vehicleno}
                              </td>
                            </tr>
                          </>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>

                    {props.location.state.assettype === "Own" || datalist.length ===0 ? (
                      ""
                    ) : (
                      <TableContainer>
                        <Table className="table-sm" size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell className="text-center">
                                Slno.
                              </TableCell>
                              <TableCell className="text-center">
                                Brand
                              </TableCell>
                              <TableCell className="text-center">
                                Model
                              </TableCell>
                              <TableCell className="text-center">
                                Serial number
                              </TableCell>
                              <TableCell className="text-center">
                                Product number
                              </TableCell>
                              <TableCell className="text-center">
                                Remarks
                              </TableCell>
                              <TableCell className="text-center">
                                Uploads
                              </TableCell>
                              <TableCell className="text-center">
                                Status
                              </TableCell>
                              <TableCell className="text-center">
                                Action
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {datalist?.map((e, index) => (
                              <TableRow key={index}>
                                <TableCell className="text-center">
                                  {index + 1}
                                </TableCell>
                                <TableCell className="text-center">
                                  {data.brand}
                                </TableCell>
                                <TableCell className="text-center">
                                  {data.model}
                                </TableCell>
                                <TableCell className="text-center">
                                  {e.serialno}
                                </TableCell>
                                <TableCell className="text-center">
                                  {e.productno}
                                </TableCell>
                                <TableCell className="text-center">
                                  {e.remarks}
                                </TableCell>
                                <TableCell>
                                {e.filedata === undefined || e.filedata === null?"":
                                   <span>
                                     <img
                                       alt="."
                                       // width="100%"
                                       height="50"
                                       className="doc"
                                       src={e.filedata}
                                     />
                                   </span>
                                   }
                             </TableCell>
                             <TableCell className="text-center">
                                {e.status !=="Terminate"?e.assetstatus:e.status}
                              </TableCell>
                              {e.assetstatus==="Active" && e.status !=="Terminate"?
                              <TableCell className="text-center">
                                <button
                                className="btn btn-sm deletebutton mr-2"
                                onClick={() => handleRemoveThirdpartyAsset([e.serialno])}
                              >
                                Return
                              </button>
                              </TableCell>
                              :""}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}

                    {(assetmanagementid === "2" || assetmanagementid === "3") && props.location.state.assettype === "Own" ? (
                      <>
                        {data.assetstatus === "Active" ? (
                          <>
                            <fieldset className="col-md-12 pt-3">
                              <button
                                className="btn btn-sm hovbuttonColour mr-2"
                                onClick={() => handleMaintenanceStatus(2)}
                              >
                                <i className="fa fa-solid fa-wrench"></i>{" "}
                                Maintenance
                              </button>
                              <button
                                className="btn btn-sm hovbuttonColour"
                                onClick={() => handleMaintenanceStatus(1)}
                              >
                                <i className="fa fa-truck"></i> On Trip
                              </button>
                            </fieldset>
                          </>
                        ) : data.assetstatus === "Maintenance" ||
                          data.assetstatus === "OnTrip" ? (
                          <fieldset className="col-md-12 pt-3">
                            <button
                              className="btn btn-sm hovbuttonColour"
                              onClick={() => handleMaintenanceStatus(0)}
                            >
                              Active
                            </button>
                          </fieldset>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(AssetDetails);
