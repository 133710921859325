import React, { useEffect, useState } from "react";
import withRouter from "../../withRouter";
import { ThemeProvider, createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import {
  currentTenant,
  financialyearstart,
  thousandsdisplay,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import { Link } from "react-router-dom";
import Menu from "../../Component/Menu";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import PurchseService from "../PurchseServices";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

const AgentsReport = () => {
  const [pendingdatabyagent, setPendingdatabyagent] = useState([]);
  const [agentcompleteddata, setAgentscompletedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("Pending");

  useEffect(() => {
    setLoading(true);
    let data = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: moment(new Date()),
    };
    PurchseService.GetAgentsPendingOrders(data).then((response) => {
      Agentsdata(response.data, 1);
    });
    PurchseService.GetAgentsCompletedOrders(data).then((response) =>
      Agentsdata(response.data, 2)
    );
  }, []);

  const Agentsdata = (data, idx) => {
    const agents = data.reduce(
      (
        agt,
        {
          itemname,
          itemid,
          quantity,
          totalprice,
          totalpricewtax,
          createdAt,
          supplier,
          custid,
          salesenum,
          agent,
          poid,
          pending,
          accepted,
          uom,
        }
      ) => {
        if (!agt[agent]) agt[agent] = [];
        agt[agent].push({
          itemid: itemid,
          itemname: itemname,
          quantity: quantity,
          totalprice: totalprice,
          totalpricewtax: totalpricewtax,
          createdAt: createdAt,
          supplier: supplier,
          custid: custid,
          salesenum: salesenum,
          agent: agent,
          poid: poid,
          pending: pending,
          accepted: accepted,
          uom: uom,
        });
        return agt;
      },
      {}
    );

    let agentlist = [];
    Object.entries(agents).forEach(([key, value]) => {
      let a = {
        agent: key,
        data: value,
      };
      agentlist.push(a);
    });
    if (idx === 1) {
      setPendingdatabyagent(agentlist);
    } else if (idx === 2) {
      setAgentscompletedData(agentlist);
    }
    setLoading(false);
  };

  const columns = [
    { name: "agent", label: "Agent", options: { filter: true, sort: true } },
    {
      name: "poid",
      label: "Order Id",
      options: { filter: true, sort: true },
    },
    {
      name: "supplier",
      label: "Supplier",
      options: { filter: true, sort: true },
    },
    { name: "itemname", label: "Item", options: { filter: true, sort: true } },
    {
      name: "quantity",
      label: "Quantity",
      options: { filter: true, sort: true },
    },
    {
      name: "pending",
      label: "Pending Quantity",
      options: { filter: true, sort: true },
    },
    {
      name: "accepted",
      label: "GRN Quantity",
      options: { filter: true, sort: true },
    },
    {
      name: "uom",
      label: "UOM",
      options: { filter: true, sort: true },
    },
    {
      name: "totalpricewtax",
      label: "Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (tableMeta) =>
          moment(tableMeta).format("DD-MM-YYYY HH:mm"),
      },
    },
    {
      name: "createdAt",
      label: "Elapsed Days(Aging)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let cre = moment(value, "YYYY-MM-DD");
          let curr = moment(new Date(), "YYYY-MM-DD");
          return <>{curr.diff(cre, "days")} Days</>;
        },
      },
    },
  ];

  const completedcolumns = [
    { name: "agent", label: "Agent", options: { filter: true, sort: true } },
    {
      name: "poid",
      label: "Order Id",
      options: { filter: true, sort: true },
    },
    {
      name: "supplier",
      label: "Supplier",
      options: { filter: true, sort: true },
    },
    { name: "itemname", label: "Item", options: { filter: true, sort: true } },
    {
      name: "quantity",
      label: "Quantity",
      options: { filter: true, sort: true },
    },
    {
      name: "accepted",
      label: "GRN Quantity",
      options: { filter: true, sort: true },
    },
    {
      name: "uom",
      label: "UOM",
      options: { filter: true, sort: true },
    },
    {
      name: "totalpricewtax",
      label: "Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (tableMeta) =>
          moment(tableMeta).format("DD-MM-YYYY HH:mm"),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/purchase" className="text-info">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item active">Purchase Agents Reports</li>
          </ol>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card-header m-0 p-2">
                    <div className="text-center text-green"></div>
                  </div>
                  <div>
                    {loading === true ? (
                      <div className="text-center">{spin}</div>
                    ) : (
                      <>
                        <fieldset className="form-group col-md-12">
                          <fieldset
                            className={`form-group col-md-3  shadow-sm text-center btn ${
                              type === "Pending"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                            }`}
                            disabled={type === "Pending"}
                            onClick={() => setType("Pending")}
                          >
                            Pending
                          </fieldset>
                          <fieldset
                            className={`form-group col-md-3 shadow-sm btn text-center ${
                              type === "Completed"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                            }`}
                            disabled={type === "Completed"}
                            onClick={() => setType("Completed")}
                          >
                            Completed
                          </fieldset>
                        </fieldset>
                        {type === "Pending" ? (
                          <>
                            {pendingdatabyagent.length > 0 ? (
                              pendingdatabyagent?.map((e, idx) => (
                                <div key={idx} className="mb-4">
                                  <ThemeProvider theme={theme}>
                                    <MUIDataTable
                                      className="table-sm px-3"
                                      title={e.agent}
                                      id="assets"
                                      data={e.data}
                                      columns={columns}
                                      options={options}
                                    />
                                  </ThemeProvider>
                                </div>
                              ))
                            ) : (
                              <div className="text-center p-4">
                                No data Avilable
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {agentcompleteddata.length > 0 ? (
                              agentcompleteddata?.map((e, idx) => (
                                <div key={idx} className="mb-4">
                                  <ThemeProvider theme={theme}>
                                    <MUIDataTable
                                      className="table-sm px-3"
                                      title={e.agent}
                                      id="assets"
                                      data={e.data}
                                      columns={completedcolumns}
                                      options={options}
                                    />
                                  </ThemeProvider>
                                </div>
                              ))
                            ) : (
                              <div className="text-center p-4">
                                No data Avilable
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(AgentsReport);
