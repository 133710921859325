import React, { Component } from 'react'
import { Formik, Form } from "formik";
import withRouter from '../withRouter';
import {NumericFormat} from 'react-number-format';
import { ccountry, currentTenant, currentUser, thousandsdisplay, userid } from '../Common/Credentials';
import { ThemeProvider,createTheme, Backdrop, CircularProgress, TextField} from '@mui/material';
import moment from 'moment';
import NewJournalService from './NewJournalService';
import { Link } from 'react-router-dom';
import AuthenticationService from '../Authentication/AuthenticationService';
import { Spinner } from 'react-bootstrap';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { spin } from '../Common/NewLoader';
import Footer from '../Component/Footer';
import MUIDataTable from 'mui-datatables';
import Swal from 'sweetalert2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Grid from "@mui/material/Grid";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class RectificationRequests extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pendingRectifications:[],
      pendingPurchaseRectifications:[],
      jvdata:[],
      cjvdata:[],
      selectedSo:[],
      selectbtn:[
        {id:2,name:"Journals"},
        {id:3,name:"Comp Journals"},
        {id:1,name:"Sales Requests"},
        // {id:4,name:"Purchase Requests"}
       ],
        selectedoption:'',
        fromdate:null,
        from:null,
        todate:null,
        to:null,
        loader:false,
        loader1:true         
      }
    this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
    this.loadData=this.loadData.bind(this);this.submitJV=this.submitJV.bind(this);
    this.getData=this.getData.bind(this);this.handleFromDateChange=this.handleFromDateChange.bind(this);
    this.handleToDateChange=this.handleToDateChange.bind(this);
  }

    onSubmit(values){
      this.setState({loader:true})
    }

    componentDidMount(){

      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }
      this.loadData()
        this.setState({loading:false})
       }

       onChange(option){
        this.setState({selectedoption: option.id});
      }

      handleFromDateChange(date){
        let from=date.$d.toString()
        this.setState({ fromdate: date,from:moment(from).format('yyyy-MM-DD')});
      };
    
      handleToDateChange(date){
        let to=date.$d.toString()
        this.setState({ todate: date,to:moment(to).format('yyyy-MM-DD')});
      };

      getData(){
        let a=this.state.from
        let b=this.state.to
        let st=a+" 00:00:01"
        let et=b+" 23:59:59"
        let data={
          tenantid:currentTenant,
          fromdate:st,
          todate:et
        }
        if(this.state.selectedoption===2){
        NewJournalService.getJvEnries(data)
        .then(response =>{
          this.setState({jvdata:response.data},()=>this.setState({loader1:false}))  
        }) 
        .catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
      })
    }else if(this.state.selectedoption===3){
      this.setState({loader1:true})
      NewJournalService.getCJvEnries(data)
      .then(response =>{
        this.setState({cjvdata:response.data},()=>this.setState({loader1:false}))  
      }) 
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
  }
      }

    loadData(){
      let data={
        tenantid:currentTenant,
        fromdate:this.state.fromdate,
        todate:this.state.todate
      }
      NewJournalService.getPendingRectifications(currentTenant)
      .then(response =>{
        this.setState({pendingRectifications:response.data})  
      }) 
      NewJournalService.getPurchasePendingRectifications(currentTenant)
      .then(response =>{
        this.setState({pendingPurchaseRectifications:response.data})  
      }) 
      NewJournalService.getCJvEnries(data)
      .then(response =>{
        this.setState({cjvdata:response.data})  
      })
      NewJournalService.getJvEnries(data)
      .then(response =>{
        this.setState({jvdata:response.data},()=>this.setState({loader1:false}))  
      }) 
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })

    }
     validate(values){
     }


     submitRecord(value){
      let canreq={
        rectid:value,
        tenantid:currentTenant,
        createdBy:userid
      }
    
      NewJournalService.canConfirm(canreq)
      .then(response=>{
        if(response.status===200){
          Swal.fire({
            title: "Confirmation",
            text: "Request Completed Successfully",
            icon: "info",
            confirmButtonText: "Ok",
            confirmButtonColor:'#152f5f',
           })
           this.loadData() 
        }else{
         Swal.fire({
         title: "Error",
         text: "Request Cannot be  Completed",
         icon: "error",
         confirmButtonText: "Ok",
         confirmButtonColor:'#152f5f',
        })
        }

      })

      this.props.navigate(1);
     }

     submitRecordPurchase(value){
      let canreq={
        pmtid:value,
        tenantid:currentTenant,
        createdBy:userid
      }
      NewJournalService.purCanConfirm(canreq)
      .then(response=>{
        if(response.status===200){
          Swal.fire({
            title: "Confirmation",
            text: "Request Completed Successfully",
            icon: "info",
            confirmButtonText: "Ok",
            confirmButtonColor:'#152f5f',
           })
           this.loadData() 
        }else{
         Swal.fire({
         title: "Error",
         text: "Request Cannot be  Completed",
         icon: "error",
         confirmButtonText: "Ok",
         confirmButtonColor:'#152f5f',
        })
        }
      })
      this.props.navigate(1);
    }
   
     submitJV(value){
      let jv
      if(value!==undefined){
      jv=this.state.jvdata.filter(e=>e.id===value)
      }
      if(this.state.selectedoption===2){
      this.props.navigate({pathname:`/jventrydetails`}, {state:{jv:value}});
      }else if(this.state.selectedoption===3){
        this.props.navigate({pathname:`/jventrydetails`}, {state:{cjv:value}});
      }
    }

    render() {

      let preceipts=null,genpreceipts=null,soids=null,jventrydata=null,cjventrydata=null,purreceipts=null
      genpreceipts=this.state.pendingRectifications
      purreceipts=this.state.getPurchasePendingRectifications
      jventrydata=this.state.jvdata.sort((a,b)=> a.createdAt<b.createdAt ? 1:-1);
      cjventrydata=this.state.cjvdata.sort((a,b)=> a.createdAt<b.createdAt ? 1:-1);

      

     // let val = this.state.combdata.filter((e) => e.ledgername === value).sort((a,b)=> a.createdAt<b.createdAt ? 1:-1);


      const RectificationRequests = [
        {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "customer",label: "From",options: {filter: true,sort: true}},
        {name: "createdAt",label: "Date",options: {filter: false,sort: true, sortOrder: 'asc',
          customBodyRender:(tableMeta)=>{
            return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
          }}},
        {name: "custominvid",label: "Invoice",options: {filter: true,sort: true,
          customBodyRender:(value,tableMeta)=>{
            return (
              value.length!==0 ?value:"NA"
            );   
          },
          setCellProps: () => ({ style: { textAlign:'left'}}), 
        
        }},
        {name: "pendingamount",label: "Amount",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        {name: "id",label: "Action",options: {filter: false,sort: false,display:true,viewColumns:false,
          customBodyRender:(value,tableMeta)=>{
            return (
              value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
              onClick={() => this.submitRecord(tableMeta.rowData[0])}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Confirm"}
              </button>:null
            );   
          },
          setCellProps: () => ({ style: { textAlign:'center'}}),   
        }},
      ]
      const RectiOptions = {
        filterType: 'checkbox',
        selectableRows: "none",
        resizableColumns: true,
      }

      const jventries =[
        {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "createdAt",label: "Date",options: {filter: false,sort: true, sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
        }}},
        {name: "debitname",label: "Debit",options: {filter: true,sort: true}},
        {name: "debitidamt",label: "Dr Amount",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        ccountry === "India"?
        {name: "sgstamt",label: "SGST",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              value!==null?
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
              :0
            );
            
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }}:"",
        ccountry === "India"?
        {name: "cgstamt",label: "CGST",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              value!==null?
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
              :0
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }}:"",
        ccountry === "India"?
        {name: "igstamt",label: "IGST",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              value!==null?
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
              :0
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }}:"",
        ccountry !== "India"?
        {name: "vatamt",label: "VAT",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              value!==null?
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
              :0
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }}:"",
        {name: "creditname",label: "Credit",options: {filter: true,sort: true}},
        {name: "creditidamt",label: "Cr Amount",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        {name: "id",label: "Action",options: {filter: false,sort: false,display:true,viewColumns:false,
          customBodyRender:(value,tableMeta)=>{
            return (
              value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
              onClick={() => this.submitJV(tableMeta.rowData[0])}
              > 
               {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Details"}
              </button>:null
            );   
          },
          setCellProps: () => ({ style: { textAlign:'center'}}),   
        }},

      ]
      const JveOptions = {
        filterType: 'checkbox',
        selectableRows: "none",
        resizableColumns: true,
      }

      const Cjventries =[
        {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "createdAt",label: "Date",options: {filter: false,sort: true, sortOrder: 'asc',
        customBodyRender:(tableMeta)=>{
          return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
        }}},
        {name: "name",label: "Debit",options: {filter: true,sort: true}},
        {name: "debitidamt",label: "Dr Amount",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        ccountry === "India"?
        {name: "taxidoneamt",label: "SGST/IGST",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              value!==null?
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
              :0
            );
            
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }}:"",
        ccountry === "India"?
        {name: "taxidtwoamt",label: "CGST",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              value!==null?
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
              :0
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }}:"",
        ccountry !== "India"?
        {name: "vatamt",label: "VAT",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              value!==null?
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
              :0
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }}:"",
        {name: "cname",label: "Credit",options: {filter: true,sort: true}},
        {name: "creditidamt",label: "Cr Amount",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        {name: "id",label: "Action",options: {filter: false,sort: false,display:true,viewColumns:false,
          customBodyRender:(value,tableMeta)=>{
            return (
              value.length!==0?<button className="hovbuttonColour btn btn-sm"
              onClick={() => this.submitJV(tableMeta.rowData[0])}
              > 
               {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Details"}
              </button>:null
            );   
          },
          setCellProps: () => ({ style: { textAlign:'center'}}),   
        }},

      ]
      const CJveOptions = {
        filterType: 'checkbox',
        selectableRows: "none",
        resizableColumns: true,
      }

      const PurchaseRectificationRequests = [
        {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "Supplier",label: "To",options: {filter: true,sort: true}},
        {name: "createdAt",label: "Date",options: {filter: false,sort: true, sortOrder: 'asc',
          customBodyRender:(tableMeta)=>{
            return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
          }}},
        {name: "custompoid",label: "PO",options: {filter: true,sort: true,
          customBodyRender:(value,tableMeta)=>{
            return (
              value.length!==0 ?value:"NA"
            );   
          },
          setCellProps: () => ({ style: { textAlign:'left'}}), 
        
        }},
        {name: "pendingamount",label: "Amount",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        {name: "id",label: "Action",options: {filter: false,sort: false,display:true,viewColumns:false,
          customBodyRender:(value,tableMeta)=>{
            return (
              value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
              onClick={() => this.submitRecordPurchase(tableMeta.rowData[0])}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Confirm"}
              </button>:null
            );   
          },
          setCellProps: () => ({ style: { textAlign:'center'}}),   
        }},
      ]
      const PurchaseRectiOptions = {
        filterType: 'checkbox',
        selectableRows: "none",
        resizableColumns: true,
      }

        return (
         <>
          <Header />
<Menu/>
            <div>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className="content-wrapper">

<div className="card card-info">
  <ol className="breadcrumb float-sm-right">
    
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/rectifications'}} state= {{message:''}} >
                Rectification Entries
              </Link>
    </li>
  </ol>

  <div className="text-center text-danger">{this.state.error}</div>
  <div className="card-body">
  <div className="text-center">
  {this.state.loading===true?spin:''}
  </div>
              
        
    <Formik
      initialValues={{ preceipts}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({ values, setFieldValue }) => (

        <Form autoComplete="off">

{this.state.selectbtn.map((option)=>{
return <label key={option.id} >

    <div className="text-sm col-md-12">
      <div className="form-group col-md-12">
      {/* <div className="form-control bg-light border-light"> */}

             <input 
                className={
                  this.state.selectedoption === option.id
                    ? "btn buttonColour text-yellow text-bold col-md-12"
                    : "btn hovbuttonColour text-white col-md-12"
                }
                type="button" 
                // checked={this.state.checked === option.id ? true : false} 
                key={option.id}
                onClick={this.onChange.bind(this,option)} 
               style={{marginRight: 8,width:400}}
                value={option.name} 
                />
            {/* {option.name} */}
        
            </div></div>
            {/* </div> */}
        </label>
       

})
}
{this.state.selectedoption===1?
       <div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`Rectification Requests`}
                            data={genpreceipts}
                            columns={RectificationRequests}
                            options={RectiOptions}
                          />
                        </ThemeProvider>
                      </div>
:""}
{this.state.selectedoption===4?
       <div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`Purchase Rectification Requests`}
                            data={purreceipts}
                            columns={PurchaseRectificationRequests}
                            options={PurchaseRectiOptions}
                          />
                        </ThemeProvider>
                      </div>
:""}

{this.state.selectedoption===2?<>

<div className="form-row col-md-12 mt-0 mb-4">
<fieldset>
<LocalizationProvider dateAdapter={AdapterDayjs}>
  <Grid container justify="space-around">
    <DatePicker variant="inline" id="date-picker-dialog" label="From Date" name="from" format="dd/MM/yyyy"
      maxDate={new Date()} value={this.state.fromdate} onChange={this.handleFromDateChange}
      renderInput={(params) => <TextField {...params} variant="standard"/>} />
  </Grid></LocalizationProvider>
  </fieldset>

<fieldset style={{ marginLeft: "30px" }}>
 <LocalizationProvider dateAdapter={AdapterDayjs}>
  <Grid container justify="space-around">
   <DatePicker variant="inline" id="date-picker-dialog" label="To Date" name="to" format="dd/MM/yyyy"
    maxDate={new Date()} value={this.state.todate}  onChange={this.handleToDateChange}
    renderInput={(params) => <TextField {...params} variant="standard"/>} />
   </Grid></LocalizationProvider>
    </fieldset>

    <fieldset style={{ marginLeft: "30px" }}>
     <button className="btn hovbuttonColour mr-1 btn-sm" type="button" onClick={()=>this.getData()} >
     {/* <i className="fas fa-pen"></i> */}
     {this.state.loader === true ? 
     <> <Spinner animation="border" size="sm" /> &nbsp;{"Get"}</>
      :  "Get" }
     </button>
     </fieldset>
    </div>

       <div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`Journal Entries`}
                            data={jventrydata}
                            columns={jventries}
                            options={JveOptions}
                          />
                        </ThemeProvider>
                      </div>
                      </>:""}

 {this.state.selectedoption===3?<>
  
<div className="form-row col-md-12 mt-0 mb-4">
<fieldset>
<LocalizationProvider dateAdapter={AdapterDayjs}>
  <Grid container justify="space-around">
    <DatePicker variant="inline" id="date-picker-dialog" label="From Date" name="from" format="dd/MM/yyyy"
      maxDate={new Date()} value={this.state.fromdate} onChange={this.handleFromDateChange}
      renderInput={(params) => <TextField {...params} variant="standard"/>} />
  </Grid></LocalizationProvider>
  </fieldset>

<fieldset style={{ marginLeft: "30px" }}>
 <LocalizationProvider dateAdapter={AdapterDayjs}>
  <Grid container justify="space-around">
   <DatePicker variant="inline" id="date-picker-dialog" label="To Date" name="to" format="dd/MM/yyyy"
    maxDate={new Date()} value={this.state.todate}  onChange={this.handleToDateChange}
    renderInput={(params) => <TextField {...params} variant="standard"/>} />
   </Grid></LocalizationProvider>
    </fieldset>

    <fieldset style={{ marginLeft: "30px" }}>
     <button className="hovbuttonColour btn  mr-1 btn-sm" type="button" onClick={()=>this.getData()} >
     {/* <i className="fas fa-pen"></i> */}
     {this.state.loader === true ? 
     <> <Spinner animation="border" size="sm" /> &nbsp;{"Get"}</>
      :  "Get" }
     </button>
     </fieldset>
    </div>

       <div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`Composite Entries`}
                            data={cjventrydata}
                            columns={Cjventries}
                            options={CJveOptions}
                          />
                        </ThemeProvider>
                      </div>
                      </>:""}


</Form>)} 
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     

 </div>
 </>
)
}
}

export default withRouter(RectificationRequests);              



 
