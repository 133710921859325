import React, { useState, Fragment, useEffect, Profiler } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import FormControl from "@mui/material/FormControl";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import { Autocomplete, Grid } from "@mui/material";
import { currency, currentTenant } from "../../Common/Credentials";
import { Percent } from "react-feather";
import SupplierService from "../../Masters/Suppliers/SupplierService";
import moment from "moment";
import TourServices from "./TourServices";


const rows = [
  // createData(1, 159, "Name", 24, 4.0)
];
const Hotel = (props) => {
  const [hidetravelid, setHidetravelid] = useState(false);

  const [age, setAge] = useState("None");
  const [stock, setStock] = useState("None");
  const [meals, setMeals] = useState("None");
  const [costcenter, setCostcenter] = useState("None");


  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");

  const [open2, setOpen2] = useState(false);
  const [fullWidth2, setFullWidth2] = useState(true);
  const [maxWidth2, setMaxWidth2] = useState("md");

  const [open3, setOpen3] = useState(false);
  const [fullWidth3, setFullWidth3] = useState(true);
  const [maxWidth3, setMaxWidth3] = useState("sm");

  const [open4, setOpen4] = useState(false);
  const [fullWidth4, setFullWidth4] = useState(true);
  const [maxWidth4, setMaxWidth4] = useState("sm");

  // ---------------------------------------------------------------------------------------------------

  const [roeofomr, setRoeofomr] = useState(1);
  const [fareinomr1, setFareinomr1] = useState(0);
  const [fareinomr2, setFareinomr2] = useState(0);
  const [fareinomrlable, setFareinomrlable] = useState(0);

  const [othertax1, setOthertax1] = useState(0);
  const [othertax2, setOthertax2] = useState(0);

  const [totaltaxinomr1, setTotaltaxinomr1] = useState(0);
  const [totaltaxinomr2, setTotaltaxinomr2] = useState(0);
  const [totaltaxinomrlable, setTotaltaxinomrlable] = useState(0);

  const [commisioninomr, setCommisioninomr] = useState(0);
  const [commisioninomrpercent, setCommisioninomrpercent] = useState(0);

  const [supplieramt, setSupplieramt] = useState(0);

  const [inputvat, setInputvat] = useState(0);
  const [inputvatpercent, setInputvatpercent] = useState(0);

  const [profit, setProfit] = useState(0);


  const [servicefee, setServicefee] = useState(0);
  const [servicefeepercent, setServicefeepercent] = useState(0);

  const [extraearing, setExtraearing] = useState(0);
  const [extraearningpercent, setExtraearningpercent] = useState(0);

  const [addmarkup, setAddmarkup] = useState(0);
  const [addmarkuppercent, setAddmarkuppercent] = useState(0);

  const [disscount, setDisscount] = useState(0);
  const [disscountpercent, setDisscountpercent] = useState(0);

  const [selling, setSelling] = useState(0);
  const [sellinglable, setSellinglable] = useState(0);



  const [checkedcommission, setCheckedcommission] = useState(true);

  const [supplier, setSupplier] = useState('')
  const [suppliercode, setSuppliercode] = useState('')
  const [supplierName, setSupplierName] = useState('');

  const [supplierdata, setSupplierdata] = useState([]);
// ------------------------------------------------------------------------------------------------------------------------------------------------
  const [voucherno,setVoucherno]=useState('');
  const [issuedate,setIssuedate]=useState(new Date());
  const [checkin,setCheckin]=useState('');
  const [corpccnickname,setCorpccnickname]=useState('');
  const [corpccname,setCorpccname]=useState('');
  const [checkout,setCheckout]=useState('');
  const [country,setCountry]=useState('');
  const [city,setCity]=useState('');
  const [orignialcountry,setOrignialcountry]=useState('');
  const [hotelname,setHotelname]=useState('');
  const [hotelcode,setHotelcode]=useState('');
  const [numofrooms,setNumofrooms]=useState('');
  const [travelid,setTravelid]=useState('');
  const [guesters,setGuesters]=useState('');
  const [noofnights,setNoofnights]=useState('');
  const [additionalguesters,setAdditionalguesters]=useState('');
  const [supplierconfno,setSupplierconfno]=useState('');
  const [hotelconfirmno,setHotelconfirmno]=useState('');
  const [bookingstaff,setBookingstaff]=useState('');
  const [issuingstaff,setIssuingstaff]=useState('');

  const [lpoortono,setLpoortono]=useState('');
  const [custempno,setCustempno]=useState('');
  const [refno,setRefno]=useState('');
  const [remarks,setRemarks]=useState('');
  const [adult,setAdult]=useState('');
  const [child,setChild]=useState('');
  const [infant,setInfant]=useState('');
  const [commissioncollectorscode,setCommissioncollectorscode]=useState('');
  const [commissioncollectorsname,setCommissioncollectorsname]=useState('');
  const [roomtype,setRoomtype]=useState('');
  const [bookingdetails,setBookingdetails]=useState('');

  const [adjuestmentstatus,setAdjuestmentstatus]=useState(true);
  const [duplicatestatus,setDuplicatestatus]=useState(true);

  const [pnr,setPnr]=useState('');
  const [hotelstore,setHotelstore]=useState([]);
  const [sellingpriceinomrlabel,setSellingpricelable]=useState('');

  const [hotelMaster,setHotelmaster]=useState([]);

  const [hotelMealsplan,setHotelMealsplans]=useState([]);
  const [mealsplan,setMealsplans]=useState('');
  const [hotelroomtype,setHotelroomtypes]=useState([]);


// =================================================================================================================================================



  useEffect(()=>{
    AllDataHandler();
    AllMasterHotelData();
  },[])



  // -----------------------------------------------------------------------------------------------------------------------------------------------------
  
 const  changeHandlerIssuedate =(e)=>{
  setIssuedate(moment(new Date(e)).format('DD/MM/YYYY'))
 }
  
 const  changeHandlerCheckin =(e)=>{
  setCheckin(moment(new Date(e)).format('DD/MM/YYYY'))
 }
  
 const  changeHandlerCorpccNickName =(e)=>{
  setCorpccnickname(e.target.value===null || e.target.value==="" || e.target.value===undefined ? "" :e.target.value)
 }
  
 const  changeHandlerCorpccName =(e)=>{
  setCorpccname(e.target.value===null || e.target.value==="" || e.target.value===undefined ? "" :e.target.value)
 }
 const  changeHandlerCheckout =(e)=>{
  setCheckout(moment(new Date(e)).format('DD/MM/YYYY'))
 } 
 const  changeHandlerCountry =(e)=>{
  setCountry(e.target.value===null || e.target.value==="" || e.target.value===undefined ? "" :e.target.value)
 }
 const  changeHandlerCity =(e)=>{
  setCity(e.target.value===null || e.target.value==="" || e.target.value===undefined ? "" :e.target.value)
 } 
 const  changeHandlerOrignialcountry =(e)=>{
  setOrignialcountry(e.target.value===null || e.target.value==="" || e.target.value===undefined ? "" :e.target.value)
 } 
 const  changeHandlerHotelcode =(e)=>{
  setHotelcode(e.target.value===null || e.target.value==="" || e.target.value===undefined ? "" :e.target.value)
 }
  
//  const  changeHandlerHotelname =(e)=>{
//   setHotelname(e.target.value===null || e.target.value==="" || e.target.value===undefined ? "" :e.target.value)
//  }
  const changeHandlerNoOfrooms=(e)=>{
    setNumofrooms(e.target.value===null || e.target.value==="" || e.target.value===undefined ? "" :e.target.value)
  }
  
  const changeHandlerAdjustment=(e)=>{
    setAdjuestmentstatus(e.target.checked)
  }
  const changeHandlerDuplicate=(e)=>{
    setDuplicatestatus(e.target.checked)
  }
  const changeHandlerPnr=(e)=>{
    setPnr(e.target.value===null || e.target.value==="" || e.target.value===undefined ? "" :e.target.value)
  }
  
  







  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeStock = (e) => {
    setStock(e.target.value);
  }

  const handleChangeMeals = (e) => {
    setMeals(e.target.value);
  }

  const handleChangeCostCenter = (e) => {
    setCostcenter(e.target.value);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClickOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };
  //------------------------------------------------------------------- -----------------------------------------------

  const changeHandlerFare1 = (e) => {
    setFareinomr1(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

    setFareinomrlable((parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + (parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)));

    setCommisioninomrpercent((parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)) / (parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)))) * parseFloat(100))

    if (checkedcommission === false) {
      setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
    }
    else {
      setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
    }

    // setServicefee(    ((   parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt))    )*( parseFloat(parseFloat(servicefeepercent) === undefined || parseFloat(servicefeepercent) === null || parseFloat(servicefeepercent) === "" ? 0.00 : parseFloat(servicefeepercent)) )) / 100     )

    setServicefeepercent(((parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
    setExtraearningpercent(((parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
    setAddmarkuppercent(((parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
    setDisscountpercent(((parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)


    setSelling(     parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))  + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))   + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)))



  }

  const changeHandlerFare2 = (e) => {
    if (e.target.value === null || e.target.value === "" || e.target.value === undefined) {
      setFareinomr2(0);
    }
    else {

      setFareinomr2(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value));



      setFareinomrlable(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1) + parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value));

      setCommisioninomrpercent((parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)) / (parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))) * parseFloat(100))

      if (checkedcommission === false) {
        setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
      }
      else {
        setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
      }


      setServicefeepercent(((parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
      setExtraearningpercent(((parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
      setAddmarkuppercent(((parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
      setDisscountpercent(((parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
    
      setSelling(     parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))  + parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1))   + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)))
    
    
    }



  }

  const changeHadlerOthertax1 = (e) => {
    setOthertax1((parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
    setTotaltaxinomr1(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)))
    if (checkedcommission === false) {
      setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
    }
    else {
      setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
    }

    setOthertax2(parseFloat(0));

    setSelling(     parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))  + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))   + parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)))


  }

  const changeHadlerOthertax2 = (e) => {
    setOthertax2((parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
    setTotaltaxinomr1((parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + (parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)))

    if (checkedcommission === false) {
      setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
    }
    else {
      setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax1) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
    }

    setInputvat(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
    setInputvatpercent((parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) / (parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)))) * parseFloat(100))

  }

  const changeHadlerTotalTax1 = (e) => {
    setTotaltaxinomrlable(parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)));

  }

  const changeHadlerTotalTax2 = (e) => {
    // if(   (parseFloat(totaltaxinomr1) === undefined || parseFloat(totaltaxinomr1) === null || parseFloat(totaltaxinomr1) === "" ? 0.00 : parseFloat(totaltaxinomr1))  === (parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + (parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2))  )
    // {
    setTotaltaxinomr2(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)));
    if (parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) > 0) {

      setTotaltaxinomr1((parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + (parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - (parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

    }
    else {
      setTotaltaxinomr1((parseFloat(totaltaxinomr1) === undefined || parseFloat(totaltaxinomr1) === null || parseFloat(totaltaxinomr1) === "" ? 0.00 : parseFloat(totaltaxinomr1)))
    }

    setInputvatpercent((parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) / (parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)))) * parseFloat(100))


  }


  const changeHandlerCommission = (e) => {

    setCommisioninomr(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value) );

    setCommisioninomrpercent((parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) / (parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)))) * parseFloat(100))

    
    if (parseFloat(e.target.value !== undefined || e.target.value !== null || e.target.value !== "" ? 0.00 : e.target.value))
    {
      setProfit( setProfit(   (parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))).toFixed(3)   ));
    }
    else
    {
      setInputvat(inputvat);
    }


  }

  const changeHandlerCommissionPercent = (e) => {
    setCommisioninomrpercent((parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

    setCommisioninomr(((parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))) * parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))) / parseFloat(100))

  }


  const changeHandlerSupplierAmt = (e) => {
    setSupplieramt( (parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.0 : parseFloat(fareinomr1)) + (parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.0 : parseFloat(fareinomr2)) + (parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.0 : parseFloat(othertax1))  -   (parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.0 : parseFloat(e.target.value))     );

    
    setFareinomr2(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.0 : parseFloat(e.target.value));

    setServicefeepercent(      (  parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee))       /       parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) ) * 100)



  }

  const changeHadlerInputVat = (e) => {
   
   if(e.target.values!==null || e.target.values!==undefined || e.target.values!=="")
   { 
    setInputvat(e.target.value);
  }
  else
  {
    setInputvat(0);
  }
  }

  const handleChangeCommissionCheck = (e) => {
    setCheckedcommission(e.target.checked);
    console.log(e.target.checked);
    if ((e.target.checked) === true) {
      setSupplieramt(parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) + parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
    }
    else {
      setSupplieramt(parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
    }

  }


  const changeHadlerServicefee = (e) => {

    setServicefee(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

    setServicefeepercent(((parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)

    setSelling(     parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))  + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))   + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1))  + parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)))
    setProfit(   (parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr))  + parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))).toFixed(3)   )
  }

  const changeHadlerServicefeePercent = (e) => {
    setServicefeepercent(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
    setServicefee(((parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt))) * (parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))) / 100)
    setProfit(  (parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr))  + ((parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt))) * (parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))) / 100 + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))).toFixed(3)  );
  }



  const changehandlerExtraEarning=(e)=>{
    setProfit(   (parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))).toFixed(3)   )
    setExtraearing(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

    setExtraearningpercent(((parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)


    setSelling(    parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))  + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))   + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)))
  }


  const changeHandlerExtraEraningPercent = (e) => {
    setExtraearningpercent(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
    setExtraearing(((parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt))) * (parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))) / 100)
    setProfit(   (parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + ((parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt))) * (parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))) / 100 + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))).toFixed(3)   )
  }


  const changeHandlerAddmarkup=(e)=>{
    setAddmarkup(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

    setAddmarkuppercent(((parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)

    setSelling(     parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))  + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))   + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)))
    setProfit(   (parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))).toFixed(3)   )

  }
  const changeHandlerAddmarkupPercent = (e) => {
    setAddmarkuppercent(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
    setAddmarkup(((parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt))) * (parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))) / 100)

  }


  const changeHandlerDisscount=(e)=>{
    setDisscount(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

    setDisscountpercent(((parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)

    setSelling(     parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1))  + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))   + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

    setProfit(   (parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))).toFixed(3)   )
  }
  const changeHandlerDisscountPercent = (e) => {
    setDisscountpercent(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
    setDisscount(((parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt))) * (parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))) / 100)
    setProfit(   (parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - ((parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt))) * (parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))) / 100).toFixed(3)   )
  
  }


  const changeHandlerSelling = (e)=>{
    setSelling(e.target.value===undefined||e.target.value===""||e.target.value===null?"":e.target.value)
    setSellingpricelable(     parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup))  - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))     )
    

  }

  // -------------------------------------------------------------------------------------------------------------------

  const handleSupplierCodeChange = (e) => {
    setSuppliercode(e.target.value)
    let findsupplier = supplierdata.find(a => a.id === e.target.value)
    if (findsupplier !== null && findsupplier !== undefined) {
      setSupplierName(findsupplier.companyname)
    } else {
      setSupplierName('')
    }


  }

  const handleSupplierNameChange = (value, values) => {
    if (values !== null && typeof values === 'object' && values !== undefined) {
      setSupplierName(values.companyname);
      setSuppliercode(values.id);
    }
  }

  const changeHandlerMealsPlan =(e)=>{
    setMeals(e.target.value)
  }
  const changeHandlerRoomType=(e)=>{
    setRoomtype(e.target.value);
  }



  const handleHotelNameChange = (value, values) => {
    if (values !== null && typeof values === 'object' && values !== undefined) {
      setHotelname(values.hotelname);
      setHotelcode(values.hotelcode);
      // console.log(values.companyname);
    }
  }

  const AllDataHandler = () => {
    SupplierService.retriveAllSuppliers(currentTenant).then(r => {
      setSupplierdata(r.data);
      console.log(r.data, "Supplier Data");
    })

   
  }
  const AllMasterHotelData = () =>{
    TourServices.getAllMHotels().then(r=>{
      setHotelmaster(r.data);
      console.log(r.data, "Master Hotel Data");
    })
    TourServices.getAllMMealsPlanist().then(r=>{
      setHotelMealsplans(r.data);
    })
    TourServices.getAllMRoomTypeList().then(r=>{
      setHotelroomtypes(r.data);
    })

  } 

  const changeHadlerVocherNumber =(e)=>{
    console.log(e);
    setVoucherno(e);
    TourServices.getByHotelVoucherNo(e).then(r=>{
      if(r.data !== "" && r.data !==null && r.data !==undefined)
      {
        setCheckin(moment(r.data.checkin).format("MM-DD-YYYY"));
        setCheckout(moment(r.data.checkout).format("MM-DD-YYYY"));
        setCountry(r.data.country);
        setCity(r.data.city);
        setOrignialcountry(r.data.orignialcountry);
        setHotelname(r.data.hotelname);
        setHotelcode(r.data.hotelcode);
        setNumofrooms(r.data.numerofrooms);
        setTravelid(r.data.travelerid);
        setGuesters(r.data.guesters);
        setNoofnights(r.data.noofnights);
        setAdditionalguesters(r.data.additionalguesters);
        setIssuingstaff(r.data.issuingstaff);
        setSupplierconfno(r.data.supplierconfno);
        setHotelconfirmno(r.data.hotelconfirmno);
        setBookingstaff(r.data.bookingstaff);
        setLpoortono(r.data.lpoortono);
        setCustempno(r.data.custempno);
        setRefno(r.data.refno);
        setAdult(r.data.adult);
        setChild(r.data.child);
        setInfant(r.data.infant)
        setCommissioncollectorscode(r.data.commissioncollectorscode);
        setCommissioncollectorsname(r.data.commissioncollectorsname);
        setRoomtype(r.data.roomtype);
        setBookingdetails(r.data.bookingdetails);
        setMealsplans(r.data.mealsplan);
        setPnr(r.data.pnr);
        setFareinomr1(parseFloat(r.data.fareinomr1===null || r.data.fareinomr1=== undefined || r.data.fareinomr1==="" ? 0.00 : parseFloat(r.data.fareinomr1)));
        setFareinomr2(parseFloat(r.data.fareinomr2===null || r.data.fareinomr2=== undefined || r.data.fareinomr2==="" ? 0.00 : parseFloat(r.data.fareinomr2)));
        setFareinomrlable(parseFloat(r.data.fareinomr1===null || r.data.fareinomr1=== undefined || r.data.fareinomr1==="" ? 0.00 : parseFloat(r.data.fareinomr1)) + parseFloat(r.data.fareinomr2===null || r.data.fareinomr2=== undefined || r.data.fareinomr2==="" ? 0.00 : parseFloat(r.data.fareinomr2)))
        setOthertax1(parseFloat(r.data.othertax1===null || r.data.othertax1=== undefined || r.data.othertax1==="" ? 0.00 : parseFloat(r.data.othertax1)));
        setOthertax2(parseFloat(r.data.vat===null || r.data.vat=== undefined || r.data.vat==="" ? 0.00 : parseFloat(r.data.vat)));
        // setTotaltaxinomr1(parseFloat(r.data.totaltaxinomr1===null || r.data.totaltaxinomr1=== undefined || r.data.totaltaxinomr1==="" ? 0.00 : parseFloat(r.data.totaltaxinomr1)));


        setTotaltaxinomr1(  parseFloat(r.data.othertax1===null || r.data.othertax1=== undefined || r.data.othertax1==="" ? 0.00 : parseFloat(r.data.othertax1)) + parseFloat(r.data.othertax2===null || r.data.othertax2=== undefined || r.data.othertax2==="" ? 0.00 : parseFloat(r.data.othertax2)) )
        setTotaltaxinomr2(parseFloat(r.data.totaltaxinomr2===null || r.data.totaltaxinomr2=== undefined || r.data.totaltaxinomr2==="" ? 0.00 : parseFloat(r.data.totaltaxinomr2)));
        setCommisioninomr(parseFloat(r.data.commisioninomr===null || r.data.commisioninomr=== undefined || r.data.commisioninomr==="" ? 0.00 : parseFloat(r.data.commisioninomr)));
        setCommisioninomrpercent(parseFloat(r.data.commissioninomrpercent===null || r.data.commissioninomrpercent=== undefined || r.data.commissioninomrpercent==="" ? 0.00 : parseFloat(r.data.commissioninomrpercent)));
        
        setSupplieramt(   parseFloat(r.data.fareinomr1===null || r.data.fareinomr1=== undefined || r.data.fareinomr1==="" ? 0.00 : parseFloat(r.data.fareinomr1)) +   parseFloat(r.data.fareinomr2===null || r.data.fareinomr2=== undefined || r.data.fareinomr2==="" ? 0.00 : parseFloat(r.data.fareinomr2)) + parseFloat(r.data.othertax1===null || r.data.othertax1=== undefined || r.data.othertax1==="" ? 0.00 : parseFloat(r.data.othertax1)) + parseFloat(r.data.othertax2===null || r.data.othertax2=== undefined || r.data.othertax2==="" ? 0.00 : parseFloat(r.data.othertax2)) - parseFloat(supplieramt===null || supplieramt=== undefined || supplieramt==="" ? 0.00 : parseFloat(supplieramt)) );
        setProfit(parseFloat(r.data.profit===null || r.data.profit=== undefined || r.data.profit==="" ? 0.00 : parseFloat(r.data.profit)));
        setServicefee(parseFloat(r.data.servicefeeinomr===null || r.data.servicefeeinomr=== undefined || r.data.servicefeeinomr==="" ? 0.00 : parseFloat(r.data.servicefeeinomr)))
        setServicefeepercent(parseFloat(r.data.servicefeeinomrpecent===null || r.data.servicefeeinomrpecent=== undefined || r.data.servicefeeinomrpecent==="" ? 0.00 : parseFloat(r.data.servicefeeinomrpecent)));
        setExtraearing(parseFloat(r.data.extraearninginomr===null || r.data.extraearninginomr=== undefined || r.data.extraearninginomr==="" ? 0.00 : parseFloat(r.data.extraearninginomr)));
        setExtraearningpercent(parseFloat(r.data.extraearninginomrpercent===null || r.data.extraearninginomrpercent=== undefined || r.data.extraearninginomrpercent==="" ? 0.00 : parseFloat(r.data.extraearninginomrpercent)) )
        setAddmarkup(parseFloat(r.data.additionalmarkupinomr===null || r.data.additionalmarkupinomr=== undefined || r.data.additionalmarkupinomr==="" ? 0.00 : parseFloat(r.data.additionalmarkupinomr)) )
        setAddmarkuppercent(parseFloat(r.data.additionalmarkupinomrpercent ===null || r.data.additionalmarkupinomrpercent === undefined || r.data.additionalmarkupinomrpercent ==="" ? 0.00 : parseFloat(r.data.additionalmarkupinomrpercent  )) )
        setDisscount(  parseFloat(r.data.discountinomr ===null || r.data.discountinomr === undefined || r.data.discountinomr ==="" ? 0.00 : parseFloat(r.data.discountinomr  )) )
        setDisscountpercent(parseFloat(r.data.discountinomrpercent ===null || r.data.discountinomrpercent === undefined || r.data.discountinomrpercent ==="" ? 0.00 : parseFloat(r.data.discountinomrpercent  )))
        setSelling(  parseFloat(r.data.fareinomr1===null || r.data.fareinomr1=== undefined || r.data.fareinomr1==="" ? 0.00 : parseFloat(r.data.fareinomr1)) +   parseFloat(r.data.fareinomr2===null || r.data.fareinomr2=== undefined || r.data.fareinomr2==="" ? 0.00 : parseFloat(r.data.fareinomr2))   + parseFloat(r.data.othertax2===null || r.data.othertax2=== undefined || r.data.othertax2==="" ? 0.00 : parseFloat(r.data.othertax2)) + parseFloat(r.data.othertax1===null || r.data.othertax1=== undefined || r.data.othertax1==="" ? 0.00 : parseFloat(r.data.othertax1)) + parseFloat(r.data.servicefeeinomr===null || r.data.servicefeeinomr=== undefined || r.data.servicefeeinomr==="" ? 0.00 : parseFloat(r.data.servicefeeinomr))  +  parseFloat(r.data.additionalmarkupinomr===null || r.data.additionalmarkupinomr=== undefined || r.data.additionalmarkupinomr==="" ? 0.00 : parseFloat(r.data.additionalmarkupinomr))  - parseFloat(r.data.discountinomr ===null || r.data.discountinomr === undefined || r.data.discountinomr ==="" ? 0.00 : parseFloat(r.data.discountinomr  ))  )
        setSellingpricelable(  parseFloat(r.data.fareinomr1===null || r.data.fareinomr1=== undefined || r.data.fareinomr1==="" ? 0.00 : parseFloat(r.data.fareinomr1)) +       parseFloat(r.data.othertax1===null || r.data.othertax1=== undefined || r.data.othertax1==="" ? 0.00 : parseFloat(r.data.othertax1)) +  parseFloat(r.data.servicefeeinomr===null || r.data.servicefeeinomr=== undefined || r.data.servicefeeinomr==="" ? 0.00 : parseFloat(r.data.servicefeeinomr))  +  parseFloat(r.data.additionalmarkupinomr===null || r.data.additionalmarkupinomr=== undefined || r.data.additionalmarkupinomr==="" ? 0.00 : parseFloat(r.data.additionalmarkupinomr))  - parseFloat(r.data.discountinomr ===null || r.data.discountinomr === undefined || r.data.discountinomr ==="" ? 0.00 : parseFloat(r.data.discountinomr  ))  )

      }
    })  }




  const addButtonHandler=(e)=>{
    e.preventDefault();
  console.log(stock,"ds");
    console.log('jHoteStore')
    let sdata ={
      type:stock,
      voucherno:voucherno,
      issuedate:issuedate,
      suppliercode:suppliercode,
      suppliername:supplierName,
      checkin:checkin,
      corpccnickname:corpccnickname,
      corpccfullname:corpccname,

      checkout:checkout,
      city:city,
      country:country,
      orignialcountry:orignialcountry,
      hotelcode:hotelcode,
      hotelname:hotelname,
      numofrooms:numofrooms,
      travelid:travelid,
      guesters:guesters,
      noofnights:noofnights,
      additionalguesters:additionalguesters,
      issuingstaff:issuingstaff,
      supplierconfno:supplierconfno,
      hotelconfirmno:hotelconfirmno,
      bookingstaff:bookingstaff,
      lpoortono:lpoortono,
      custempno:custempno,
      refno:refno,
      adult:adult,
      child:child,
      infant:infant,
      commissioncollectorscode:commissioncollectorscode,
      commissioncollectorsname:commissioncollectorsname,
      roomtype:roomtype,
      bookingdetails:bookingdetails,
      meals:mealsplan,
      duplicatestatus:duplicatestatus,
      pnr:pnr,
      remarks:remarks,
      costcenter:costcenter,
      adjuestmentstatus: adjuestmentstatus.toString(),


      roeofrompayable:roeofomr,
      
      fareinomr1:fareinomr1,
      fareinomr2:fareinomr2,
      othertaxes:othertax1,
      vat:othertax2,
      totaltaxinomr1:totaltaxinomr1,
      totaltaxinomr12:totaltaxinomr2,
      commissioninomr:commisioninomr,
      commissioninomrpercent:commisioninomrpercent,
      commissioninstatus:checkedcommission,
      supplieramtinomr:supplieramt,
      inputvatintomr:inputvat,
      roeofromreceived:"1",
      profit: profit,
      servicefeeinomr:servicefee,
      servicefeeinomrpecent:servicefeepercent,
      extraearninginomr:extraearing,
      extraearninginomrpercent:extraearningpercent,
      additionalmarkupinomr:addmarkup,
      additionalmarkupinomrpercent:addmarkuppercent,
      discountinomr:disscount,
      discountinomrpercent:disscountpercent,
      sellingpriceinomr:selling,
      sellingpriceinomrlabel:sellingpriceinomrlabel,

      discountinomrpercent:disscountpercent,
      
      tenantid:currentTenant
   
  
    }
    setHotelstore([...hotelstore,sdata])
    console.log( [...hotelstore,sdata],"dd")
 
    props.funcHotel([...hotelstore,sdata])
  
    // TourServices.addTicketInvoice(ticketstore).then((r)=>{
  
    // })
  
  }
  const clearButtonHandler=(e)=>{
    e.preventDefault();

    setStock('');
    setVoucherno('');
    setIssuedate('');
    setSuppliercode('');
    setSupplierName('');
    setCheckin('');
    setCorpccnickname('');
    setCorpccname('');

    setCheckout('');
    setCity('');
    setCountry('');
    setOrignialcountry('');
    setHotelcode('');
    setHotelname('');
    setNumofrooms('');
    setTravelid('');
    setGuesters('');
    setNoofnights('');
    setAdditionalguesters('');
    setIssuingstaff('');
    setSupplierconfno('');
    setHotelconfirmno('');
    setBookingstaff('');
    setLpoortono('');
    setCustempno('');
    setRefno('');
    setAdult('');
    setChild('');
    setInfant('');
    setCommissioncollectorscode('');
    setCommissioncollectorsname('');
    setRoomtype('');
    setBookingdetails('');
    setMeals('');

    setPnr('');
    setRemarks('');
    setCostcenter('');

    setRoeofomr(0);
    // setAdmoracmexp(0);
    // setPublishedinomr(0);
    setFareinomr1(0);
    setFareinomr2(0);
    // setT("");
    setOthertax1(0);
    setOthertax2(0);
    setTotaltaxinomr1(0);
    setTotaltaxinomr2(0);
    setCommisioninomr(0);
    setCommisioninomrpercent(0);
    setSupplieramt(0);
    setProfit(0);
    setServicefee(0);
    setServicefeepercent(0);
    setExtraearing(0);
    setExtraearningpercent(0);
    setAddmarkuppercent(0);
    setAddmarkup(0);
    setDisscount(0);
    setDisscountpercent(0);
    setSelling(0);
    setMealsplans('');
    
    setTotaltaxinomrlable(0);


  }



  return (
    <div>

      <div className="small-container mp-2 padding-2 w-100%" style={{ fontFamily: "Nunito" }}>
        <div className="card">

          <div className="card">
            <div className="card p-4">
              <div className="row my-1">
                <fieldset className="col-md-3 col-sm-12">
                  <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">Stock type</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={stock}
                      label="stock"
                      onChange={handleChangeStock}
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="VS">VS</MenuItem>
                      <MenuItem value="STOCK">STOCK</MenuItem>
                      <MenuItem value="XO">XO</MenuItem>
                    </Select>
                  </FormControl>
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    label="Voucher No."
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="text"
                    value={voucherno}
                    onChange={(e)=>changeHadlerVocherNumber(e.target.value)}
                  />
                </fieldset>

                <div className="col-md-3 col-sm-12">
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker slotProps={{ textField: { size: 'small' } }} label="Issue Date" />
                  </LocalizationProvider> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container >
                      <DatePicker
                        variant="inline"
                        id="date-picker-dialog"
                        label="Issue Date "
                        
                        value={issuedate}
                        onChange={(e)=>changeHandlerIssuedate(e)}
                        renderInput={(params) => <TextField {...params} size="small"/>}
                        autoOk={true}
                      />
                    </Grid>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="row my-1">
              <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    label=" Supplier Code"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    onChange={handleSupplierCodeChange}
                    value={suppliercode}
                  />
                </fieldset>

                <fieldset className="form-group col-md-5 col-sm-12">
                  <Autocomplete
                    id="supname"
                    className="subacc-select"
                    name="Supplier Name"
                    options={supplierdata}
                    getOptionLabel={(option) => option.companyname}
                    value={supplierName ? undefined : undefined}
                    onChange={handleSupplierNameChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        className="form-control"
                        type="text"
                        name="Supplier Name"
                        value={supplierName}
                        label="Select Supplier Name"
                        variant="outlined"
                        size="small"
                        
                      />
                    )}
                  />
                </fieldset>

                <div className="col-md-3 col-sm-12">
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker slotProps={{ textField: { size: 'small' } }} required label="Check-In" />
                  </LocalizationProvider> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container >
                      <DatePicker
                        variant="inline"
                        id="date-picker-dialog"
                        label="Check-In "
                        // format="dd/MM/yyyy"
                        // maxDate={new Date()}
                        value={checkin}
                        onChange={changeHandlerCheckin}
                        renderInput={(params) => <TextField {...params} size="small" />}
                        autoOk={true}
                      />
                    </Grid>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="row ">
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    label="Corp. CC Nick Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={corpccnickname}
                    onChange={changeHandlerCorpccNickName}
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    label="Corp. CC Full Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={corpccname}
                    onChange={changeHandlerCorpccName}
                  />
                </fieldset>

                <div className="col-md-3 col-sm-12">
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker slotProps={{ textField: { size: 'small' } }} required label="Check-Out" />
                  </LocalizationProvider> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container >
                      <DatePicker
                        variant="inline"
                        id="date-picker-dialog"
                        label="Check-out "
                        // format="dd/MM/yyyy"
                        // maxDate={new Date()}
                        value={checkout}
                        onChange={changeHandlerCheckout}
                        renderInput={(params) => <TextField {...params} size="small" />}
                        autoOk={true}
                      />
                    </Grid>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    required
                    label="City"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={city}
                    onChange={changeHandlerCity}
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    required
                    label="Country"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={country}
                    onChange={changeHandlerCountry}

                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    label="Origin Country"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={orignialcountry}
                    onChange={changeHandlerOrignialcountry}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-3 my-1">
                  <TextField
                    label="Hotel Code"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={hotelcode}
                    onChange={(e)=>setHotelcode}
                    fullWidth
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12 my-1">
                  {/* <TextField
                    label="Hotel Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={hotelname}
                    onChange={}

                  /> */}
                   <Autocomplete
                    id="hotname"
                    className="subacc-select"
                    name="hotelname"
                    options={hotelMaster}
                    getOptionLabel={(option) => option.hotelname}
                    value={supplier ? undefined : undefined}
                    onChange={handleHotelNameChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        className="form-control"
                        type="text"
                        name="hotelname"
                        value={hotelname}
                        label="Select Hotel Name"
                        variant="outlined"
                        size="small"
                        
                      />
                    )}
                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12 my-1">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    label="No. of Rooms"
                    value={numofrooms}
                    onChange={changeHandlerNoOfrooms}
                  />
                </fieldset>
                {/* <div className="col-md-2 col-sm-12 ">
                  <InputLabel className="my-2"> </InputLabel>
                </div> */}
              </div>


              <div className="row my-1">
                <fieldset className="col-md-2 col-sm-12">
                  <TextField

                    label="Traveler ID"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={travelid}
                    onChange={(e)=>setTravelid(e.target.value)}

                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    fullWidth
                    required
                    label="Guest(s)"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={guesters}
                    onChange={(e)=>setGuesters(e.target.value)}
                  />
                </fieldset>
                <div className="col-md-2 col-sm-12">
                  <button className="btn btn-sm hovbuttonColour" onClick={() => setHidetravelid(prev => !prev)}>
                    {hidetravelid ? <i className="fa fa-minus fa-xl " ></i> : <i className="fa fa-plus fa-xl " ></i>}</button>
                </div>
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    required
                    fullWidth
                    label="No. of Nights"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={noofnights}
                    onChange={(e)=>setNoofnights(e.target.value)}

                  />
                </fieldset>

              </div>

              {hidetravelid && <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    fullWidth
                    label="Additional Guest(s)"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={additionalguesters}
                    onChange={(e)=>setAdditionalguesters(e.target.value)}
                  />
                </fieldset>
              </div>}

              <div className="row my-1">
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    fullWidth
                    label="Issuing Staff"
                    id="outlined-size-small"
                    size="small"
                    placeholder="B2B"
                    variant="outlined"
                    value={issuingstaff}
                    onChange={(e)=>setIssuingstaff(e.target.value)}

                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    label="Supplier Conf. No."
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={supplierconfno}
                    onChange={(e)=>setSupplierconfno(e.target.value)}

                  />
                </fieldset>

                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    fullWidth
                    label="Hotel Confirm No."
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={hotelconfirmno}
                    onChange={(e)=>setHotelconfirmno(e.target.value)}


                  />
                </fieldset>

              </div>

              <div className="row my-1">
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    fullWidth
                    label="Booking Staff"
                    id="outlined-size-small"
                    size="small"
                    placeholder="B2B"
                    variant="outlined"
                    value={bookingstaff}
                    onChange={(e)=>setBookingstaff(e.target.value)}


                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    label="LPO/TO No."
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={lpoortono}
                    onChange={(e)=>setLpoortono(e.target.value)}
                  />
                </fieldset>

                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    fullWidth
                    label="Cust. Emp. No."
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={custempno}
                    onChange={(e)=>setCustempno(e.target.value)}


                  />
                </fieldset>

              </div>

              <div className="row my-1 ">
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    fullWidth
                    label="Ref.No"
                    id="outlined-size-small"
                    size="small"
                    placeholder="B2B"
                    variant="outlined"
                    value={refno}
                    onChange={(e)=>setRefno(e.target.value)}

                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    label="Remarks"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={remarks}
                    onChange={(e)=>setRemarks(e.target.value)}
                  />
                </fieldset>

                <fieldset className="col-md-1 col-sm-12">
                  <TextField
                    required
                    label="Adult"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={adult}
                    onChange={(e)=>setAdult(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-1 col-sm-12">
                  <TextField
                    required
                    label="Child"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={child}
                    onChange={(e)=>setChild(e.target.value)}
                    
                  />
                </fieldset>
                <fieldset className="col-md-1 col-sm-12">
                  <TextField
                    required
                    label="Infant"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={infant}
                    onChange={(e)=>setInfant(e.target.value)}
                  />
                </fieldset>

              </div>

              <div className="row my-1">
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Commission Collectors"
                    variant="outlined"
                    multiline
                    size="small"
                    fullWidth
                    value={commissioncollectorscode}
                    onChange={(e)=>setCommissioncollectorscode(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={commissioncollectorsname}
                    onChange={(e)=>setCommissioncollectorsname(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12">
                {/* <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={hotelroomtype.map((option) => option.roomtype)}
                    renderInput={(params) => (
                      <TextField
                      value={roomtype}
                      onChange={(e)=>changeHandlerRoomType(e)}
                        {...params}
                        size="small"
                        label="Room Type"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      
                      />
                    )}
                  /> */}

                  <TextField
                    label="Room Type"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={roomtype}
                    onChange={(e) => setRoomtype(e.target.value)}
                  />  

                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-7 col-sm-12">
                  <TextField
                    label="Booking Details"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={bookingdetails}
                    onChange={(e)=>setBookingdetails(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12">
                  {/* <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">Meals Plan</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={meals}
                      label="Meals Plan"
                      onChange={handleChangeMeals}
                      size="small"
                    >
                      <MenuItem value="">
                        <em>Choose Meals Plans</em>
                      </MenuItem>
                      
                      <MenuItem value={10}>All inclusive</MenuItem>
                      <MenuItem value={10}>Bed & Breakfast</MenuItem>
                      <MenuItem value={10}>Only Bed</MenuItem>
                      <MenuItem value={10}>Half Board</MenuItem>
                      <MenuItem value={10}>Full Board</MenuItem>
                      <MenuItem value={10}>Only Breakfast</MenuItem>
                    </Select>
                  </FormControl> */}


                  {/* <Autocomplete
                    freeSolo
                    id="free-solo-2-demo"
                    disableClearable
                    options={hotelMealsplan.map((option) => option.mealsplan)}
                    renderInput={(params) => (
                      <TextField
                      value={meals}
                      onChange={(e)=>changeHandlerMealsPlan(e)}
                        {...params}
                        size="small"
                        label="Meals Plan"
                        InputProps={{
                          ...params.InputProps,
                          type: 'search',
                        }}
                      
                      />
                    )}
                  /> */}
                  <TextField
                    label="Meals Plan"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={mealsplan}
                    onChange={(e)=>setMealsplans(e.target.value)}
                  />

                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-2 col-sm-12">
                  <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">Cost Centre</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={costcenter}
                      label="Cost Center"
                      onChange={handleChangeCostCenter}
                      size="small"
                    >
                      <MenuItem value="">
                        <InputLabel>None</InputLabel>
                      </MenuItem>
                      <MenuItem value={10}>Head Office</MenuItem>

                    </Select>
                  </FormControl>
                </fieldset>
                <fieldset className="col-md-2 col-sm-12">
                  <InputLabel>Adjustment</InputLabel>
                  <Checkbox 
                  defaultChecked
                  value={adjuestmentstatus}
                  onChange={changeHandlerAdjustment}
                  />
                </fieldset>
                <fieldset className="col-md-1 col-sm-12">
                  <InputLabel>Duplicate</InputLabel>
                  <Checkbox 
                  defaultChecked
                  value={duplicatestatus}
                  onChange={changeHandlerDuplicate}
                  />
                </fieldset>
                <fieldset className="col-md-2 col-sm-12 my-1">
                  <TextField
                    
                    label="Duplicate numbers"
                    defaultValue={1}
                    type="number"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled={duplicatestatus===false}
                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12 my-1">
                  <TextField
                    fullWidth="true"
                    label="PNR "
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={pnr}
                    onChange={changeHandlerPnr}
                  />
                </fieldset>
              </div>
            </div>
          </div>

          {/* --------------------------------------------------- */}
          <div className="row my-1">
            <div className="card p-4 col-md-6 col-sm-12">
              <div className="row my-1">
                <fieldset className="col-md-11">
                  <TextField
                    required
                    fullWidth
                    type="number"
                    label={`ROE of ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    
                    value={ roeofomr === undefined || roeofomr === null || roeofomr === "" ? 0.00 : roeofomr===parseFloat(1.00)}
                    onChange={(e) => setRoeofomr(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value).toFixed(3))}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-6 col-sm-12">
                  <TextField
                    label={`Fare in ${currency} `}
                    id="outlined-size-small"
                    size="small"
                    type="number"
                    variant="outlined"
                    
                    onChange={(e)=>changeHandlerFare1(e.target.value)}
                    value={fareinomr1 === undefined || fareinomr1 === null || fareinomr1 === "" ? 0.00 : fareinomr1.toFixed(3)}
                  />

                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    onChange={(e)=>changeHandlerFare2(e.target.value)}
                    value={fareinomr2 === undefined || fareinomr2 === null || fareinomr2 === "" ? 0.00 : fareinomr2.toFixed(3)}
                  />

                </fieldset>
                <div className="col-md-2 col-sm-12">
                  <label>{(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + (parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))}</label>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-6 col-sm-12">
                  <TextField
                    label="Other Taxes"
                    type="number"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1).toFixed(3)}
                    onChange={(e)=>changeHadlerOthertax1(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-6 col-sm-12">
                  <TextField
                    label="VAT/GSTIN"
                    type="number"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2).toFixed(3)}
                    onChange={(e)=>changeHadlerOthertax2(e.target.value)}
                  />
                </fieldset>

              </div>

              {/* <div className="row my-1">
              <fieldset className="col-md-6 col-sm-12">
                  <TextField
                    label="Total Tax in OMR"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                  />
                </fieldset>
                <div className="col-md-2 col-sm-12">
                    <label>0.00</label>
                </div>
              </div> */}

              <div className="row my-1">
                <fieldset className="col-md-6 col-sm-12">
                  <TextField
                    label=" Toal Tax in OMR"
                    id="outlined-size-small"
                    size="small"
                    type="number"
                    disabled
                    variant="outlined"
                    value={parseFloat(totaltaxinomr1) === undefined || parseFloat(totaltaxinomr1) === null || parseFloat(totaltaxinomr1) === "" ? 0.00 : parseFloat(totaltaxinomr1).toFixed(3)}
                    onChange={(e)=>changeHadlerTotalTax1(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    id="outlined-size-small"
                    type="number"
                    size="small"
                    variant="outlined"
                    value={parseFloat(totaltaxinomr2) === undefined || parseFloat(totaltaxinomr2) === null || parseFloat(totaltaxinomr2) === "" ? 0.00 : parseFloat(totaltaxinomr2).toFixed(3)}
                    onChange={(e)=>changeHadlerTotalTax2(e.target.value)}
                  />
                </fieldset>
                <div className="col-md-2 col-sm-12">
                  <InputLabel>{(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + (parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2))}</InputLabel>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1 col-sm-12">
                  <TextField
                    label="Commission in OMR"
                    id="outlined-size-small"
                    size="small"
                    type="number"
                    variant="outlined"
                    
                    value={commisioninomr === undefined || commisioninomr === null || commisioninomr === "" ? 0 : commisioninomr}
                    onChange={(e)=>changeHandlerCommission(e.target.value)}
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-12">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-4 col-sm-12 my-1 col-sm-12">
                  <TextField
                    label=""
                    type="number"
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={parseFloat(commisioninomrpercent) === undefined || parseFloat(commisioninomrpercent) === null || parseFloat(commisioninomrpercent) === "" ? 0.00 : parseFloat(commisioninomrpercent)}
                    onChange={(e)=>changeHandlerCommissionPercent(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-1 col-sm-12">
                  <Checkbox
                    defaultChecked
                    checked={checkedcommission}
                    onChange={handleChangeCommissionCheck}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-10 col-sm-12">
                  <TextField
                    fullWidth
                    label="Supplier Amount in OMR"
                    id="outlined-size-small"
                    type="number"
                    size="small"
                    variant="outlined"
                    disabled
                    value={parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt).toFixed(3)}
                    onChange={(e)=>changeHandlerSupplierAmt(e.target.value)}
                  />
                </fieldset>
                <div className="col-md-2 col-sm-12">
                  <InputLabel>{parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr))}</InputLabel>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label="Input VAT/GSTIN in OMR"
                    id="outlined-size-small"
                    size="small"
                    type="number"
                    disabled
                    variant="outlined"
                    value={parseFloat(inputvat) === undefined || parseFloat(inputvat) === null || parseFloat(inputvat) === "" ? 0.00 : parseFloat(inputvat).toFixed(3)}
                    onChange={changeHadlerInputVat}
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-12">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    InputLabel=""
                    type="number"
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={parseFloat(inputvatpercent) === undefined || parseFloat(inputvatpercent) === null || parseFloat(inputvatpercent) === "" ? 0.00 : parseFloat(inputvatpercent).toFixed(3)}
                  // onChange={changeHadlerInputVatPercent}

                  />
                </fieldset>
              </div>


            </div>
            {/* ------------------- */}
            <div className="card p-4 col-md-6 col-sm-12">
              <div className="row my-1">
                <fieldset className="col-md-4 col-sm-12 my-3">
                  <InputLabel>ROE OF {currency}</InputLabel>
                </fieldset>

                <div className="col-md-2 my-3 col-sm-12">
                  <InputLabel>1</InputLabel>
                </div>

                <div className="col-md-3 my-3 col-sm-12">
                  <InputLabel>Profit in {currency} </InputLabel>
                </div>

                <div className="col-md-2 my-3 col-sm-12">
                  <InputLabel>{    (parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))).toFixed(3) }</InputLabel>
                </div>

              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label="Service Fee in OMR"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={(servicefee).toFixed(3) }
                    onChange={changeHadlerServicefee}
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-12">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                  
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={(servicefeepercent).toFixed(3)}
                    onChange={changeHadlerServicefeePercent}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label={`Extra Earning in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={(extraearing).toFixed(3)}
                    onChange={changehandlerExtraEarning}
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-12">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                   
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={(extraearningpercent).toFixed(3)}
                    onChange={changeHandlerExtraEraningPercent}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <div className="col-md-4 ms-4">
                  <InputLabel>Payback Account</InputLabel>
                </div>
                <div className="col-md-3 col-sm-12 y-1">
                  <Fragment>
                    <button>
                      <i
                        class="fas fa-folder-plus"
                        variant="outlined"
                        onClick={handleClickOpen2}
                      ></i>
                    </button>
                    <Dialog
                      fullWidth={fullWidth2}
                      maxWidth={maxWidth2}
                      open={open2}
                      onClose={handleClose2}
                    >
                      <DialogTitle
                        className="ms-4"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        Multiple Payback Account
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          noValidate
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                            minWidth: "400px",
                          }}
                        >
                          <div className="row my-1 ms-2">
                            <fieldset className="col-md-4 col-sm-12">
                              <TextField
                                fullWidth="true"
                                label="Payback Account"
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                              />
                            </fieldset>
                            <fieldset className="col-md-3 col-sm-12">
                              <TextField
                                fullWidth="true"
                                label=""
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                              />
                            </fieldset>
                            <fieldset className="col-md-3 col-sm-12">
                              <TextField
                                fullWidth="true"
                                label="Account"
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                              />
                            </fieldset>
                            <div className="col-md-2 col-sm-12">
                              {/* <button
                                className="btn btn-sm btnbuttonColour d-flex"
                                style={{ float: "right" }}
                              >
                                Add{" "}
                                <i
                                  className="fa fa-plus fa-xl ms-2"
                                  style={{
                                    color: "#1fe035",
                                    fontSize: "25px",
                                  }}
                                >
                                  {" "}
                                </i>
                              </button> */}
                            </div>
                          </div>
                          <div className="row my-1 ms-2">
                            <div className="col-md-12 col-sm-12">
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead>
                                    <TableRow>

                                      <TableCell>
                                        Sl No.
                                      </TableCell>
                                      <TableCell align="right">
                                        Code
                                      </TableCell>
                                      <TableCell align="right">
                                        Name
                                      </TableCell>
                                      <TableCell align="right">
                                        Ammount(OMR)
                                      </TableCell>

                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {rows.map((row) => (
                                      <TableRow
                                        key={row.Sl_No}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >

                                        <TableCell component="th" scope="row">
                                          {row.Sl_No}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.Code}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.Name}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.Account}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose2}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label="Payback Amount in OMR"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                  />
                </fieldset>
                <div className="col-md-1 col-sm-12 my-2">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-4 my-1">
                  <TextField
                    label=""
                    type="number"
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                  />
                </fieldset>
                <div className="col-md-2 my-1">
                  {/* <button className="btn btn-sm">
                    <i className="fas fa-sync" />
                  </button> */}
                </div>
              </div>
              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label="Additional Mark up in OMR"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={(addmarkup).toFixed(3)}
                    onChange={changeHandlerAddmarkup}
                  />
                </fieldset>
                <div className="col-md-1 col-sm-12 my-2">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label=""
                    
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={(addmarkuppercent).toFixed(3)}
                    onChange={changeHandlerAddmarkupPercent}
                  />
                </fieldset>
              </div>
              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label="Discount in OMR"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={disscount}
                    onChange={changeHandlerDisscount}
                  />
                </fieldset>
                <div className="col-md-1 col-sm-12 my-2">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-4 col-sm-12 my-1">
                  <TextField
                    defaultValue={0.00}
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={disscountpercent}
                    onChange={changeHandlerDisscountPercent}
                  />
                </fieldset>
                <div className="col-md-2 col-sm-12 my-1">
                  <Fragment>
                    <button className="btn">
                      <i
                        className="fas fa-caret-square-up"
                        variant="outlined"
                        onClick={handleClickOpen3}
                      ></i>
                    </button>
                    <Dialog
                      fullWidth={fullWidth3}
                      maxWidth={maxWidth3}
                      open={open3}
                      onClose={handleClose3}
                    >
                      <DialogTitle
                        className="ms-4"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        Multi-Discount-Details (OMR)
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          noValidate
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                          }}
                          multiline
                        >
                          <div className="card" >
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-10 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label="Normal Discount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-10 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label="PLB Discount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-10 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label="Deal Discount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              {/* <Button className="btn hovbuttonColour btn-sm col-md-5 align-content-center col-sm-12" style={{color:"white"  }}>Set</Button> */}
                            </div>
                          </div>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose3}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div>
              </div>
              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label="Output VAT in OMR"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    // value={outputomr}
                    disabled
                  />
                </fieldset>
                <div className="col-md-1 col-sm-12 my-2">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-4 col-sm-12 my-1">
                  <TextField
                    label=""
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                  />
                </fieldset>
                <div className="col-md-2 col-sm-12 my-1">
                  <Fragment>
                    <button className="btn">
                      <i
                        class="fas fa-undo-alt"
                        variant="outlined"
                        onClick={handleClickOpen4}
                      ></i>
                    </button>
                    <Dialog
                      fullWidth={fullWidth4}
                      maxWidth={maxWidth4}
                      open={open4}
                      onClose={handleClose4}
                    >
                      <DialogTitle
                        className="ms-4"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        Tax Details
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          noValidate
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                          }}
                          multiline
                        >
                          <div className="my-2" >
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-4 col-sm-12">
                                <InputLabel> Tax Code </InputLabel>
                              </fieldset>
                              <fieldset className="col-md-4 col-sm-12">
                                <InputLabel>Amount in OMR </InputLabel>
                              </fieldset>
                              <fieldset className="col-md-4 col-sm-12">
                                <InputLabel>Amount in OMR </InputLabel>
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-4 col-sm-12 my-1">
                                <InputLabel>OMV (OUT OF SCOPE) </InputLabel>
                              </fieldset>
                              <fieldset className="col-md-4 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-md-4 col-sm-12 my-1">
                                <InputLabel>0.00 </InputLabel>
                              </fieldset>

                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-4 col-sm-12 my-1">
                                <InputLabel> Total </InputLabel>
                              </fieldset>
                              <fieldset className="col-md-4 col-sm-12 my-1">
                                <InputLabel>0.00 </InputLabel>
                              </fieldset>
                              <fieldset className="col-md-4 col-sm-12 my-1">
                                <InputLabel>0.00 </InputLabel>
                              </fieldset>

                            </div>

                          </div>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose4}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div>
              </div>
              <div className="row my-1">
                <fieldset className="col-md-6 col-sm-12 my-1">
                  <TextField
                    label="Selling Price in OMR"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                    type="number"
                    value={selling}
                    onChange={changeHandlerSelling}
                  />
                </fieldset>
                <div className="col-md-3 col-sm-12">
                  <InputLabel>{  parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup))  - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))}</InputLabel>
                </div>
                <div className="col-md-3 my-1">
                  <Fragment>
                    <button >
                      <i class="fas fa-folder-plus" variant="outlined" onClick={handleClickOpen}></i>
                    </button>
                    <Dialog
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}
                      open={open}
                      onClose={handleClose}
                    >
                      <DialogTitle className="ms-4" style={{ fontFamily: "sans-serif" }}>Fare Details</DialogTitle>
                      <DialogContent>
                        <Box
                          noValidate
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                          }}
                          multiline
                        >
                          <FormControl
                            sx={{ mt: 2, minWidth: 120 }}
                          ></FormControl>
                          <div className="card ms-4">
                            <div className="row my-1 ms-2">
                              <InputLabel className="" style={{ textAlign: "center", fontStretch: "ultra-condensed" }}>OMR</InputLabel>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-9">
                                <TextField
                                  fullWidth="true"
                                  label="ROE"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Market fare"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-2 my-4">
                                <InputLabel>0.00</InputLabel>
                              </div>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Tax"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-2 my-3">
                                <InputLabel>0.00</InputLabel>
                              </div>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="STD Commission"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"

                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-8">
                                <TextField
                                  fullWidth="true"
                                  label="Supplier Amount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>

                              <div className="col-2 my-3">
                                <InputLabel>0.00</InputLabel>
                              </div>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Service Fee"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Extra Earning"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Payback Amount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Additional Mark up"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Discount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-8">
                                <TextField
                                  fullWidth="true"
                                  label="Selling Price"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>

                              <div className="col-2 my-3">
                                <InputLabel>0.00</InputLabel>
                              </div>
                            </div>
                          </div>

                          {/* <FormControlLabel
                            sx={{ mt: 1 }}
                            control={
                              <Switch
                                checked={fullWidth}
                                onChange={handleFullWidthChange}
                              />
                            }
                            label="Full width"
                          /> */}
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div>
              </div>
            </div>
            <div className="d-flex ">
              <button className="btn hovbuttonColour btn-sm ml-3 " onClick={addButtonHandler}>Add</button>
              <button className="btn hovbuttonColour ml-3 btn-sm bg-danger text-color-red" onClick={(e)=>clearButtonHandler(e)}>Clear</button>
            </div>
          </div>
          <div>
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer component={Paper}  sx={{ maxHeight: 350}}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Supplier Name</TableCell>
            <TableCell align="right">Voucher No</TableCell>
            {/* <TableCell align="right">Output VAT/GSTIN</TableCell> */}
            <TableCell align="right">Profit</TableCell>
            <TableCell align="right">Selling Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {hotelstore.map((r) => (
            <TableRow
              key={r.stocktype}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {r.type}
              </TableCell>
              <TableCell>
                {r.suppliername}
              </TableCell>
              <TableCell align="right">{r.voucherno}</TableCell>
              {/* <TableCell align="right">{r.outputvatinomr}</TableCell> */}
              <TableCell align="right">{r.profit}</TableCell>
              <TableCell align="right">{r.sellingpriceinomr}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Hotel
