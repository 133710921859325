import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

export let currentTenant

class AgentServices{
    getAgents(currentTenant) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallagentsoftenant/${currentTenant}`)
     }
     getAgentById(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getagentbyid/${id}`)
   }
     addAgent(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addagent`,data)
     }
     updateAgent(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateagent`,data)
     }
     deleteAgent(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deleteagent/${id}`)
     }
}
export default new AgentServices()