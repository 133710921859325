
import React from 'react'
import { Component } from 'react';
import withRouter from "../withRouter.js";
// import logo from '../images/';
// import { sha256 } from 'js-sha256';

//  import ThermalPrinter  from 'node-thermal-printer'
// import ThermalPrinter, { PrinterTypes, TableAlign } from "../browser-thermal-printer";
// import epson from '../browser-thermal-printer/dist/interfaces/epson/epson';

//printer.setPrinterDriver('RETSOL TP806')

 

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}

class PaymentGateway extends Component {


   
// async print(){

  
// const printer = new ThermalPrinter('epson');
// printer.print("Hello World"); // Append text
// printer.println("Hello World"); // Append text with new line
// printer.openCashDrawer(); // Kick the cash drawer
// printer.cut(); // Cuts the paper (if printer only supports one mode use this)
// printer.partialCut(); // Cuts the paper leaving a small bridge in middle (if printer supports multiple cut modes)
// // printer.beep(); // Sound internal beeper/buzzer (if available)
// printer.upsideDown(true); // Content is printed upside down (rotated 180 degrees)
// printer.setCharacterSet("SLOVENIA"); // Set character set - default set on init
// // printer.setPrinterDriver("//localhost/printer"); // Set printer drive - default set on init

// printer.bold(true); // Set text bold
// printer.invert(true); // Background/text color inversion
// printer.underline(true); // Underline text (1 dot thickness)
// printer.underlineThick(true); // Underline text with thick line (2 dot thickness)
// printer.drawLine(); // Draws a line
// printer.newLine(); // Insers break line

// printer.alignCenter(); // Align text to center
// printer.alignLeft(); // Align text to left
// printer.alignRight(); // Align text to right

// printer.setTypeFontA(); // Set font type to A (default)
// printer.setTypeFontB(); // Set font type to B

// printer.setTextNormal(); // Set text to normal
// printer.setTextDoubleHeight(); // Set text to double height
// printer.setTextDoubleWidth(); // Set text to double width
// printer.setTextQuadArea(); // Set text to quad area
// printer.setTextSize(7, 7); // Set text height (0-7) and width (0-7)

// printer.leftRight("Left", "Right"); // Prints text left and right
// printer.table(["One", "Two", "Three"]); // Prints table equaly
// printer.tableCustom([
//   // Prints table with custom settings (text, align, width, cols, bold)
//   { text: "Left", align: "LEFT", width: 0.5 },
//   { text: "Center", align: "CENTER", width: 0.25, bold: true },
//   { text: "Right", align: "RIGHT", cols: 8 },
// ]);

// printer.code128("Code128"); // Print code128 bar code
// printer.printQR("QR CODE"); // Print QR code

  
//     }

  async pay(){

        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

		if (!res) {
			alert('Razorpay SDK failed to load. Are you online?')
			return
		}

		// const data = await fetch('http://localhost:1337/razorpay', { method: 'POST' }).then((t) =>
		// 	t.json()
		// )

        let razorpay_payment_id, razorpay_order_id, razorpay_signature
        let order_id ="order_I8iFzmAw3akoxF"
        const options = {
            "key": "rzp_test_XR81fxVkWafMLq", // Enter the Key ID generated from the Dashboard
            "amount": "500", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise must be converted to string
            "currency": "INR",
            "name": "Abdul Saleem",
            "description": "Sales Transaction",
            "image": "",
            "order_id": "order_I8iFzmAw3akoxF", //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "handler": function (response){
                    razorpay_payment_id = response.razorpay_payment_id
                    razorpay_order_id =response.razorpay_order_id
                    razorpay_signature= response.razorpay_signature
                    // let  generated_signature =sha256(razorpay_order_id + "|" + razorpay_payment_id, "sxORNN7NJy9n0U0mZ5ybY5SJ")
                    // if (generated_signature === razorpay_signature) {
                    //  alert("payment is successful")
                    // }
            },
            "prefill": {
                "name": "Abdul Saleem",
                "email": "abdulsaleem@gmail.com",
                "contact": "9999999999"
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            }
        };
       
        const rzp1 = new window.Razorpay(options);
        // rzp1.on('payment.failed', function (response){
        //         alert(response.error.code);
        //         alert(response.error.description);
        //         alert(response.error.source);
        //         alert(response.error.step);
        //         alert(response.error.reason);
        //         alert(response.error.metadata.order_id);
        //         alert(response.error.metadata.payment_id);
        // });
       
            rzp1.open();
    }

    render(){
        return(
            <div>
                
                <button id="rzp-button1" onClick={()=>this.pay()}>Pay</button>
                {/* <button id="rzp-button1" onClick={()=>this.print()}>Print</button> */}

            </div>
        )
    }
}

export default withRouter(PaymentGateway)