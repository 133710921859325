import React, { Component } from 'react'
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import { FormikTextField } from '../CommonComponents/FormField.js';
import {Autocomplete} from '@mui/material';
// import { combdata} from './Journal';
import { currentTenant, currentUser, userid,financialyearend,financialyearstart, transactionFrom } from '../Common/Credentials';
import NewJournalService from './NewJournalService';
import AuthenticationService from '../Authentication/AuthenticationService';
import { Spinner } from 'react-bootstrap';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { spin } from '../Common/NewLoader';
import Footer from '../Component/Footer';
import { TextField,Grid,FormControlLabel} from '@mui/material';
import moment from "moment";
import IOSSwitch from "../CommonComponents/IOSSwitch.js";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// const getTotal = (totamt) => {
//   let total = 0;
//   totamt.forEach(totamt => {
//     total += totamt;
//   });
//   return total;
// };
let totalcombdata=[]



// const Checkbox = props => (
//   <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" {...props} />
// )
let jvtype=5

class Advances extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
          advtype: [
            { id: 0, name: "Receipt" },
            { id: 1, name: "Payment" },
          ],
          advchecked: null,
          SDdate:false,
          fed:'',
          debvalue:[],
          credvalue:[],
          //pendingPayments:[],
            loader:false

             
          }
        this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
        // this.selectFile=this.selectFile.bind(this);this.upload=this.upload.bind(this);
        // this.CheckBoxSelect=this.CheckBoxSelect.bind(this);this.handleAdvpaid=this.handleAdvpaid.bind(this)
        this.loadData=this.loadData.bind(this)
    }

    onSubmit(values){
      this.setState({loader:true})
      let jv={
        debitid: values.debit.id,debitname: values.debit.name,debitamt: values.debitval,
        creditid: values.credit.id,creditname: values.credit.name,
        creditamt: values.creditvalue,jvtype:jvtype,tenantId: currentTenant,narration: values.narration,
        date:this.state.fed!==''?this.state.fed:null
      }
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
    }else{
      this.props.navigate(`/journalconfirm`, {jv});
    }
    }

    componentDidMount(){
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }
      this.loadData()
        this.setState({loading:false})
       }

     handleSDDate=()=>{
        this.setState({SDdate:this.state.SDdate===true?false:true})
      }
      handleFsdDateChange = (date) => {
        this.setState({ fsd: date });
        this.setState({
          fed: moment(date).format("yyyy-MM-DD HH:mm:ss"),
        });
      };
    loadData(){
     NewJournalService.getAllLedgers(currentTenant)
      .then(response=>{
        totalcombdata=response.data
       })
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    }
    onAdvChange(option) {
      this.setState({ advchecked: option.id},()=>this.adddata())
    }
    onDebBlur(values){
      this.setState({debselval:values.debit})
    }
     validate(values){
      
     }

     submitRecord(){
     
     }
     adddata(){

      if(this.state.advchecked===0){
        this.setState({debvalue:[],credvalue:[]})
        this.setState({debvalue:totalcombdata.filter((e)=>e.shid===100004 || e.shid===100003),
          credvalue:totalcombdata.filter((e)=> e.type===0)})
      }else{
        this.setState({debvalue:[],credvalue:[]})
        this.setState({debvalue:totalcombdata.filter((e)=> e.type===1),
        credvalue:totalcombdata.filter((e)=>e.shid===100004 || e.shid===100003)})
      }
     }
   
    render() {
        return (
         <>
          <Header />
<Menu/>
            <div>
            <div className="content-wrapper">

<div className="card card-info">
  <ol className="breadcrumb float-sm-right">
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/directadvances'}} state={{message:''}} >
                Direct Advances
              </Link>
    </li>
  </ol>

  {/* <div className="text-center text-danger">{this.state.error}</div> */}
  <div className="card-body">
  <div className="text-center">
  {this.state.loading===true?spin:''}
  </div>
              
        
    <Formik
      initialValues={{debit:'',debitval:'',credit:'',creditvalue:'',narration:''}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({ values,setFieldValue}) => (

        <Form autoComplete="off">



<div className="form-row mt-0 mb-0">

<div className="form-group col-md-3 text-sm mt-0 mb-2 ">
          <label className="textColour">Advance Entry Type : </label></div>
          <div className="form-group col-md-9 text-sm mt-0 mb-2 float-right">
          {this.state.advtype.map((option) => {
          return (<label key={option.id} >
          <div className="form-group  mt-0 mb-0">
          <div className="radio-item mt-0 mb-0">
           <input type="radio"  checked={this.state.advchecked === option.id ? true : false} key={option.id} 
           onChange={this.onAdvChange.bind(this, option)} style={{marginRight:8}} value={option.id}/>
          <span style={{marginRight:30}}>{option.name}</span>
           </div></div></label>);})}</div>
      {/* <div className="form-row" >  */}

{this.state.advchecked!==null?<>
  <div className="form-row col-md-12 text-left form-switch mt-3">
 <div className="col-md-3 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.SDdate} onChange={this.handleSDDate} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.SDdate===false?"System Date":"Custom Date"}</span>
       </div>
    {this.state.SDdate===true?
     <div className="col-md-3 text-left">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid  justifyContent="space-around">
    <DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
    id="date-picker-dialog" inputVariant="standard"
    minDate={new Date(moment(transactionFrom).format("yyyy-MM-DD"))}
    maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
    format="dd/MM/yyyy HH:mm" value={this.state.fsd}
    // label="Custom Date" 
    InputAdornmentProps={{position: "start"}} onChange={this.handleFsdDateChange}
    renderInput={(params) => <TextField {...params} />}autoOk={true}/>
    </Grid></LocalizationProvider></div>
     :""}
    </div>
<fieldset  className="form-group col-md-5"> 
       
       <Autocomplete id="debit" className="debit-select" name="adebit" options={this.state.debvalue}
       getOptionLabel={(option) => option.name +" ("+option.amount+")"} 
       isOptionEqualToValue={(option) => !option.value }
      onChange={(e, value) => {setFieldValue("debit",value)}} 
      renderInput={params => (
      <FormikTextField {...params}  type="text" name="debit" label="Debit" 
      onBlur={()=>{this.onDebBlur(values)}} variant="standard" fullWidth />)}/></fieldset>

<fieldset  className="form-group col-md-4"> 
<FormikTextField name="debitval" className="form-control" label="Value" type="number" 
onBlur={()=>setFieldValue("creditvalue",values.debitval) &&
           setFieldValue("debtotal",values.debitval)} variant="standard">
           </FormikTextField>
</fieldset></> :""}
</div>
<div className="form-row"> 
{this.state.advchecked!==null?<>
<fieldset  className="form-group col-md-2"> </fieldset>
    <fieldset  className="form-group col-md-5"> 
     <Autocomplete id="credit" className="credit-select" name="acredit" options={this.state.credvalue}
     getOptionLabel={(option) => option.name +" ("+option.amount+")"}
     isOptionEqualToValue={(option, value) => option.name === value.name}
     value={values.credit?undefined:undefined} onChange={(e, value) => { setFieldValue("credit",value);}}
    renderInput={params => (
      <FormikTextField {...params}  type="text" name="credit" label="Credit" variant="standard" fullWidth />)}/>
      </fieldset>
      <fieldset  className="form-group col-md-2" disabled>   
      <FormikTextField name="creditvalue" type="number" className="form-control" label={values.debitval?" ":"Value"}
       variant="standard"/>   
       </fieldset></>:""}
      </div>

{this.state.advchecked!==null?<>
<div className="form-row">
                  <fieldset  className="form-group col-md-9">   
                  <FormikTextField 
                  name="narration"
                  className="form-control"
                  type="text"
                  label="Narration"
                  variant="filled"
                  /></fieldset>

<fieldset className="form-group col-md-12 mt-5">
                  <button className="btn btn-info btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button>
                  </fieldset></div></>:""}


</Form>)} 
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     
 </div>
 </>
)
}
}

export default withRouter(Advances);              



 
