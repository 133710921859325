import React, { useEffect, useState } from 'react'
import Header from '../../Component/Header';
import Menu from '../../Component/Menu';
import Footer from '../../Component/Footer';
import { Link, useLocation } from 'react-router-dom';
import MasterList from './MasterList';
import { Accordion, AccordionDetails, AccordionSummary, InputLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, Typography } from '@mui/material';
import { TableRow } from '@mui/material';
import TourServices from './TourServices';
import withRouter from '../../withRouter';
import { address, address2, ccity, ccountry, clientname, companyemail, contactnumber, cstate, currency, currentTenant, czip, gstin, logo } from '../../Common/Credentials';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ToWords } from "to-words";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment"
import BankServices from '../../Masters/Bank/BankServices';
import CustomerService from '../../Masters/Customers/CustomerService';

const ViewISalesInvoices = (props) => {
  const location = useLocation();
  const [selected, setSelected] = useState('View');
  const [isHide, setIsHide] = useState(false);
  const [tableView, setTableView] = useState(false);
  const [ttable, setTtable] = useState(true);
  const [htable, setHtable] = useState(true);
  const [ctable, setCtable] = useState(true);
  const [stable, setStable] = useState(true);
  const [atable, setAtable] = useState(true);

  const [inv, setInv] = useState([]);
  const [trows, setTrows] = useState([]);
  const [hrows, setHrows] = useState([]);
  const [crows, setCrows] = useState([]);
  const [srows, setSrows] = useState([]);
  const [arows, setArows] = useState([]);
  // const [mrows, setMrows] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [bankdetails,setBankdetails] = useState([])
  const [ customerdata,setCustomerdata] = useState('')




  useEffect(() => {
    console.log(location.state.id, "dd")

    StoreDatahandler();
  }, [])



  const handlepdfDownload = (Invoicedata, type) => {
    console.log(Invoicedata)
    let pagename = ""

    let logowidth = 0;
    // logowidth =
    //   ((this.state.imgwidth - this.state.imgheight) / this.state.imgwidth) *
    //   100;
    let curdate = moment().format("DD-MM-YYYY HH:mm:ss");
    let date = moment(Invoicedata.createdby).format("DD-MM-YYYY")
    let data =[]
    let additionalchares = [];
    let hsn = [];
   
        Invoicedata.ticket.map(e=>
        data.push({
          ticketno:e.ticketno,
          paxname:e.paxname,
          sector:`${e.sector}`,
          flightdetails:`${e.airlinecode} ${e.iatadesignator} ${e.gender} ${moment(e.issuedate).format("DD-MMM")}`,
          basicfare:e.marketfareinomr1 + e.marketfareinomr2,
          tax:e.taxinomr1 +e.taxinomr2,
          total:e.marketfareinomr1 + e.marketfareinomr2 + e.taxinomr1 +e.taxinomr2
        })
        )
   
    

    let tablecolp;

    let totalinvoice = Invoicedata.ticket.reduce((a, e) => (a = a + e.marketfareinomr1 + e.marketfareinomr2), 0)
    let taxamount = Invoicedata.ticket.reduce((a, e) => (a = a + e.taxinomr1 +e.taxinomr2), 0)
    let totalinvoicewtax = Invoicedata.ticket.reduce((a, e) => (a = a + e.marketfareinomr1 + e.marketfareinomr2 + e.taxinomr1 +e.taxinomr2 +e.servicefeeinomr  + e.additionalmarkupinomr), 0)
    let advance = 0
    let totaladditionalchares = Invoicedata.ticket.reduce((a, e) => (a = a + e.additionalmarkupinomr ), 0);
    let insuranceamount = 0;
    let totalservfee = Invoicedata.ticket.reduce((a, e) => (a = a + e.servicefeeinomr ), 0);
    // if (pagename === "Tax Invoice Details") {
    //   totaladditionalchares =
    //     Invoicedata.totaladditionalchares !== undefined &&
    //     Invoicedata.totaladditionalchares !== null
    //       ? Invoicedata.totaladditionalchares.toFixed(2)
    //       : (0).toFixed(2);
    //   insuranceamount = Invoicedata.insurancereq
    //     ? Invoicedata.insuranceamount.toFixed(2)
    //     : 0;
    //   totaladdincinsu = (
    //     (Invoicedata.totaladditionalchares !== undefined &&
    //     Invoicedata.totaladditionalchares !== null
    //       ? Invoicedata.totaladditionalchares
    //       : 0) + (Invoicedata.insurancereq ? Invoicedata.insuranceamount : 0)
    //   ).toFixed(2);
    // }

    // if (Invoicedata.currency !== currency) {
    //   totalinvoice = (totalinvoice * Invoicedata.curconversionrate).toFixed(2);
    //   taxamount = (taxamount * Invoicedata.curconversionrate).toFixed(2);
    //   totalinvoicewtax = (
    //     totalinvoicewtax * Invoicedata.curconversionrate
    //   ).toFixed(2);
    //   advance = (advance * Invoicedata.curconversionrate).toFixed(2);
    //   totaladditionalchares = (
    //     totaladditionalchares * Invoicedata.curconversionrate
    //   ).toFixed(2);
    //   insuranceamount = (
    //     insuranceamount * Invoicedata.curconversionrate
    //   ).toFixed(2);
    //   totaladdincinsu = (
    //     totaladditionalchares * Invoicedata.curconversionrate +
    //     insuranceamount * Invoicedata.curconversionrate
    //   ).toFixed(2);
    // }

      tablecolp = [
        { header: "Ticket No", dataKey: "ticketno" },
        { header: "Pax Name", dataKey: "paxname" },
        { header: `Sector`, dataKey: "sector" },
        { header: "Filght Details", dataKey: "flightdetails"},
        { header: "Basic Fare", dataKey: "basicfare" },
        { header: "Tax & Chrg", dataKey: "tax" },
        { header: "Total Fare", dataKey: "total" },
      ];
      

  
    const toWords = new ToWords();
    var totalinwords = toWords.convert(totalinvoicewtax);
    var advanceinwords = toWords.convert(advance);
    const doc = new jsPDF("p", "pt", [842, 595], true);
    let y = 0;
    // doc.setTextColor(121, 121, 121)
    // if (order === "PROFORMA INVOICE") {
    //   doc.setFontSize(60);
    // } else {
    //   doc.setFontSize(80);
    // }
    doc.setTextColor("#EEECEC");
    doc.text(`INVOICE`, 100, 700, { angle: 45 });
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#000");
    doc.setDrawColor(192, 192, 192);
    doc.setLineWidth(1.0);
    doc.line(10, 180, 585, 180);
    doc.setFontSize(8);
  
    doc.text(`Invoice ID`, 20, y + 205);
    doc.text(`-`, 80, y + 205);
    doc.text(`${Invoicedata.id}`, 90, y + 205);
    doc.text(`Invoice Date`, 20, y + 215);
    doc.text(`-`, 80, y + 215);
    doc.text(`${date}`, 90, y + 215);
    doc.text(`Reference`, 20, y + 225);
    doc.text(`-`, 80, y + 225);
    doc.text(`${Invoicedata.reference}`,90,y + 225);
    if (Invoicedata.taxtype === "GST") {
      doc.text(`Supply Type`, 20, y + 235);
      doc.text(`-`, 80, y + 235);
      doc.text(`${Invoicedata.gsttype}`, 90, y + 235);
    }
    y = y + 60;
    doc.line(10, y + 180, 585, y + 180);
    // doc.text(`Shipment ID : ${Invoicedata.dnid}`,560,220,'right')
    // doc.text("CUSTOMER DETAILS", 20, y+195);
    doc.text(
      `CUSTOMER DETAILS ${
        pagename === "Tax Invoice Details" ? "(Bill to)" : ""
      }`,
      20,
      y + 190
    );
    doc.line(10, y + 195, 585, y + 195);
    doc.text("M/S", 20, y + 205);
    doc.text(`-`, 65, y + 205);
    doc.text(`${customerdata.companyname}`, 75, y + 205);
    doc.text(`Address`, 20, y + 215);
    doc.text(`-`, 65, y + 215);
    doc.text(`${customerdata.address}`, 75, y + 215);
    doc.text(`${customerdata.address2}`, 75, y + 225);
    doc.text(`${customerdata.cityname}, ${customerdata.statename} `, 75, y + 235);
    doc.text(`${customerdata.countryname} - ${customerdata.zip}`, 75, y + 245);
    doc.text(`Phone No.`, 20, y + 255);
    doc.text(`-`, 65, y + 255);
    doc.text(
      `${customerdata.phonecode}-${customerdata.contactnumber}`,
      75,
      y + 255
    );
    if (customerdata.gstin !== "" && customerdata.gstin !== undefined) {
      doc.text(`GSTIN`, 20, y + 265);
      doc.text(`-`, 65, y + 265);
      doc.text(`${customerdata.gstin}`, 75, y + 265);
    }

    doc.setLineWidth(1.0);
    doc.line(10, y + 270, 585, y + 270);

    
      // doc.text(`Products`, 20, y + 280);
      autoTable(doc, {
        theme: "grid",
        startY: y + 290,
        margin: 20,
        didDrawPage: (HookData) => {
          HookData.settings.margin.top = 180;
        },
        styles: { textColor: [0, 0, 0], fontSize: "8" },
        headStyles: {
          fillColor: [256, 256, 256],
          textColor: [0, 0, 0],
          fontStyle: "default",
        },
        // columnStyles: {
        //   2: { halign: "right" },
        //   6: {
        //     halign:
        //       Invoicedata.taxtype === "GST" &&
        //       ccountry === "India" &&
        //       Invoicedata.gsttype === "INTRASTATE"
        //         ? "left"
        //         : "right",
        //   },
        //   7: { halign: "right" },
        //   8: { halign: "right" },
        //   9: { halign: "right" },
        //   10: { halign: "right" },
        // },
        body: data,
        columns: tablecolp,
      });
     



    doc.setDrawColor(192, 192, 192);
    y = doc.autoTable.previous.finalY + 15;
    if (y > 841) {
      doc.addPage([595.28, 841.89]);
      y = 180;
      doc.setFontSize(80);
      doc.setFont("bold", "bold");
      doc.setTextColor("#EEECEC");
      doc.text(`${order}`, 100, 700, { angle: 45 });
      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(8);
    }
    if (pagename === "Tax Invoice Details" && additionalchares.length > 0) {
      doc.text(`Total (${Invoicedata.currency}) :`, 490, y, "right");
      doc.text(
        `${(totalinvoicewtax - totaladditionalchares).toFixed(2)}`,
        570,
        y,
        "right"
      );
      doc.text(
        `Additional charges (${Invoicedata.currency}) :`,
        490,
        y + 10,
        "right"
      );
      doc.text(`${totaladditionalchares}`, 570, y + 10, "right");
      y = y + 10;
      if (Invoicedata.insurancereq) {
        doc.text(`Insurance (${Invoicedata.currency}) :`, 490, y + 10, "right");
        doc.text(`${insuranceamount}`, 570, y + 10, "right");
        y = y + 10;
      }
    }
    doc.text(`Grand Total:`, 490, y + 10, "right");
    doc.text(`${totalinvoicewtax}`, 570, y + 10, "right");
    doc.setLineWidth(1.0);
    doc.line(10, y + 20, 585, y + 20);

    y = y + 35;
    if (y + 150 > 841) {
      doc.addPage([595.28, 841.89]);
      y = 180;
      doc.setFontSize(80);
      doc.setFont("bold", "bold");
      doc.setTextColor("#EEECEC");
      doc.text(`${order}`, 100, 700, { angle: 45 });
      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(8);
    }
    doc.text(`Grand total in words : ${totalinwords}`, 20, y);

    // doc.text(`Advance amount : ${advance} (${advanceinwords})`, 20, y + 15);
 
    y = y + 25;
    doc.setLineWidth(1.0);
    doc.line(10, y, 585, y);
    doc.text("Service Fee", 100, y + 15);
    doc.text(`${totalservfee}`, 220, y + 40);
    doc.text("Total", 220, y + 65);
    doc.line(250, y, 250, y + 75);

      doc.text("Taxable value", 260, y + 15);
      doc.text(`${totalinvoice}`, 320, y + 40, "right");
      doc.text(`${totalinvoice}`, 320, y + 65, "right");
      doc.line(330, y, 330, y + 75);
      doc.text( "Tax",
        360,
        y + 15
      );
    
      doc.text(`${taxamount}`, 390, y + 40, "right");
      doc.text(`${taxamount}`, 390, y + 65, "right");

      doc.line(400, y, 400, y + 75);
      doc.text(`Additional charges`, 410, y + 15);
      doc.text(`${totaladditionalchares}`, 480, y + 40, "right");
      doc.text(`${totaladditionalchares}`, 480, y + 65, "right");

      doc.line(490, y, 490, y + 75);
      doc.text(`Total `, 520, y + 15);
      doc.text(`${totalinvoicewtax}`, 570, y + 40, "right");
      doc.text(`${totalinvoicewtax}`, 570, y + 65, "right");
    

    doc.line(10, y + 25, 585, y + 25);
    doc.line(10, y + 50, 585, y + 50);
    doc.line(10, y + 75, 585, y + 75);
    y = y + 100;
    if (y + 150 > 841) {
      doc.addPage([595.28, 841.89]);
      y = 180;
      doc.setFontSize(80);
      doc.setFont("bold", "bold");
      doc.setTextColor("#EEECEC");
      doc.text(`${order}`, 100, 700, { angle: 45 });
      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(8);
    }
    doc.line(10, y, 585, y);
    doc.text("Bank details", 150, y + 15);
    doc.line(10, y + 20, 350, y + 20);
    doc.text("Bank Name", 20, y + 35);
    doc.text("-", 75, y + 35);
    doc.text(
      `${
        bankdetails.bankname === undefined
          ? ""
          : bankdetails.bankname
      }`,
      83,
      y + 35
    );
    doc.text("Bank Acc No.", 20, y + 50);
    doc.text("-", 75, y + 50);
    doc.text(
      `${
        bankdetails.accno === undefined
          ? ""
          : bankdetails.accno
      }`,
      83,
      y + 50
    );

    doc.text("Branch Name", 20, y + 65);
    doc.text("-", 75, y + 65);
    doc.text(
      `${
        bankdetails.branchname === undefined
          ? ""
          : bankdetails.branchname
      }`,
      83,
      y + 65
    );
    doc.text("IFSC", 20, y + 80);
    doc.text("-", 75, y + 80);
    doc.text(
      `${
        bankdetails.ifsc === undefined
          ? ""
          : bankdetails.ifsc
      }`,
      83,
      y + 80
    );

    doc.line(350, y, 350, y + 90);
    doc.text(
      "Certified that particulars given above are true and correct",
      355,
      y + 15
    );
    doc.text(`for ${clientname}`, 400, y + 30);
    doc.line(585, y + 35, 350, y + 35);
    doc.text("Authorised Signatory", 425, y + 85);
    doc.line(10, y + 90, 585, y + 90);
    y = y + 90;
    if (y + 150 > 841) {
      doc.addPage([595.28, 841.89]);
      y = 180;
      doc.setFontSize(80);
      doc.setFont("bold", "bold");
      doc.setTextColor("#EEECEC");
      doc.text(`${order}`, 100, 700, { angle: 45 });
      doc.setFont("helvetica", "normal");
      doc.setTextColor("#000");
      doc.setFontSize(8);
    }
    doc.text("Terms and Conditions", 275, y + 15);
    doc.line(10, y + 20, 585, y + 20);
    // var splitTitle = doc.splitTextToSize(this.state.templete.terms, 500);
    // doc.text(
    //   20,
    //   y + 30,
    //   splitTitle === undefined || splitTitle === "undefined" ? "" : splitTitle
    // );

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;
      doc.line(10, 50, 585, 50);
      doc.line(10, pageHeight - 40, 585, pageHeight - 40);
      doc.line(10, 50, 10, pageHeight - 40);
      doc.line(pageWidth - 10, 50, pageWidth - 10, pageHeight - 40);
      doc.setFontSize(8);
      doc.text(`${curdate}`, 20, 60);
      doc.addImage(
        logo,
        "GIF",
        pageWidth - 100 - logowidth,
        60,
        80 + logowidth,
        80
      );
      doc.setFontSize(12);
      doc.text(`${clientname}`, 20, 80);
      doc.setFontSize(8);
      doc.text(`${address}`, 20, 90);
      doc.text(`${address2}`, 20, 100);
      doc.text(`${ccity}, ${cstate}`, 20, 110);
      doc.text(`${ccountry} - ${czip}`, 20, 120);
      doc.text(`${companyemail}`, 20, 130);
      doc.text(`${contactnumber}`, 20, 140);
      doc.setLineWidth(1.0);
      doc.line(10, 150, 585, 150);
      doc.setFontSize(10);
      doc.text(`GSTIN | ${gstin}`, 20, 167);
      doc.setFontSize(12);
      doc.text(`Invoice`, 230, 167);
     
      doc.setFontSize(8);
      doc.text(`Original Invoice`, 500, 167);
      doc.setFontSize(8);
      doc.setLineWidth(1.0);
      doc.line(10, 175, 585, 175);
      doc.setFontSize(8);
      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 50,
        { baseline: "bottom" }
      );
    }
      doc.autoPrint({ variant: "non-conform" });
      doc.save(
        `${Invoicedata.id}.pdf`
      );

  };






  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const StoreDatahandler = () => {

    TourServices.getByInvoiceId(location.state.id).then(r => {
      console.log(r.data);
      setTrows(r.data.ticket === undefined || r.data.ticket === null ? [] : r.data.ticket)
      console.log(r.data.ticket, "Ticket Invoice");
      setHrows(r.data.hotel === null || r.data.hotel === undefined ? [] : r.data.hotel)
      console.log(r.data.hotel, "Hotel Invoice");
      setCrows(r.data.car === null || r.data.car === undefined ?  [] : r.data.car)
      console.log(r.data.car, "Cars Invoice");
      setSrows(r.data.services === null || r.data.services === undefined ? [] : r.data.services)
      console.log(r.data.service, "Service Invoice");
      setArows(r.data.adjustment === null || r.data.adjustment === undefined ?  [] : r.data.adjustment)
      console.log(r.data.adjustment, "Adjustment Invoice");
      setInv(r.data);
      console.log(r.data, "Master Invoice");

      CustomerService.retriveCustomer(r.data.custcode).then(res=>
        setCustomerdata(res.data)
        )
      // setMrows(r.data.master===null || r.data.master===undefined ? r.data.master===[] : r.data.master)
      // console.log(r.data.master, "Master Invoice");
    })

    BankServices.getActivebank(currentTenant).then(res=>{setBankdetails(res.data); console.log(res.data,"Bank Details")})
  }

  return (
    <>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <span
                as="button"
                className=" text-secondary cursorPointer"

              >
                <Link to="/salestravelinvoice" style={{ color: "grey" }}>❮ Back &nbsp;&nbsp;</Link>

              </span>
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/salestravelinvoice">Sales Travel Invoices</Link>
            </li>
            <li className="breadcrumb-item active" >{selected}</li>
          </ol>
          <div>
          <div className='card'>
            <div className='card-header '>
            <h3 className="card-title">Invoice Details</h3>
                                  <button
                                    className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md mx-1"
                                    data-tip
                                    data-for="pdf"
                                    onClick={() =>
                                      handlepdfDownload(inv,"pdf")
                                    }
                                  >Ticket</button>
                                    <button
                                    className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md mx-1"
                                    data-tip
                                    data-for="pdf"
                                    onClick={() =>
                                      alert("This feature is in progress,will come soon")
                                    }
                                  >Hotel</button>
                                   <button
                                    className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md mx-1"
                                    data-tip
                                    data-for="pdf"
                                    onClick={() =>
                                      alert("This feature is in progress,will come soon")
                                    }
                                  >Car</button>
                                   <button
                                    className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md mx-1"
                                    data-tip
                                    data-for="pdf"
                                    onClick={() =>
                                      alert("This feature is in progress,will come soon")
                                    }
                                  >Service</button>
              </div>
            <div className='card-body'>
            <div className='row g-0'>
            <div className="col-md-6">
                  <table className="float-left mb-3">
                <tbody>
                    <tr>
                      <tr className=''>
                        <td>
                          Invoice ID
                        </td>
                        <td>: {inv.id} </td>
                      </tr>
                      <tr className=''>
                        <td>
                          Document Date
                        </td>
                        <td>: {inv.docdate} </td>
                      </tr>
                      <tr className=''>
                        <td>
                          File Number
                        </td>
                        <td>: {inv.fileno} </td>
                      </tr>
                      <tr className=''>
                        <td>
                          Cost Center
                        </td>
                        <td>: {inv.costcenter} </td>
                      </tr>
                      <tr className=''>
                        <td>
                          Department
                        </td>
                        <td>: {inv.department} </td>
                      </tr>
                      <tr className=''>
                        <td>
                          Advance Recipt
                        </td>
                        <td>: {inv.advancerecipt} </td>
                      </tr>
                      <tr className=''>
                        <td>
                          Against Document Number
                        </td>
                        <td>: {inv.againstdocno} </td>
                      </tr>
                      <tr className=''>
                        <td>
                          Reference
                        </td>
                        <td>: {inv.reference} </td>
                      </tr>
                      <tr className=''>
                        <td>
                          Customer
                        </td>
                        <td>: {inv.customer} </td>
                      </tr>
                      <tr className=''>

                        <td>
                          Customer Code
                        </td>
                        <td>: {inv.custcode} </td>
                      </tr>
                      <tr className=''>
                        <td>
                          Customer {currency}
                        </td>
                        <td>: {inv.customerroe} </td>
                      </tr>
                      <tr className=''>
                        <td>
                          Sub Customer Name
                        </td>
                        <td>: {inv.subcustomer} </td>
                      </tr>
                    </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
                  <table className="float-left mb-3">
                <tbody>
                    <tr>
                      <tr className=''>
                        <td>
                        Sub Customer Code
                        </td>
                        <td>: {inv.subcustcode} </td>
                      </tr>
                      <tr className=''>
                        <td>
                        Original Amount
                        </td>
                        <td>: {inv.originalamt} </td>
                      </tr>
                      <tr className=''>
                        <td>
                        Unmatched Amount
                        </td>
                        <td>: {inv.unmatchedamt} </td>
                      </tr>
                      <tr className=''>
                        <td>
                        Narration
                        </td>
                        <td>: {inv.narration} </td>
                      </tr>
                      <tr className=''>
                        <td>
                        Invoice Footnote
                        </td>
                        <td>: {inv.invoicefootnote} </td>
                      </tr>
                      <tr className=''>
                        <td>
                        Created By
                        </td>
                        <td>: {inv.createdby} </td>
                      </tr>
                      <tr className=''>
                        <td>
                        Updated By
                        </td>
                        <td>: {inv.updatedby} </td>
                      </tr>
                      <tr className=''>
                        <td>
                          Reference
                        </td>
                        <td>: {inv.reference} </td>
                      </tr>
                      <tr className=''>
                        <td>
                        Status
                        </td>
                        <td>: {inv.status} </td>
                      </tr>
                      
                    </tr>
                </tbody>
              </table>
            </div>
            </div>
              {trows.length > 0 ?
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      Ticket
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <TableContainer >
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Ticket Id</TableCell>
                            <TableCell align="right">Stock Type</TableCell>
                            <TableCell align="right">Ticket No</TableCell>
                            <TableCell align="right">Original Issue</TableCell>
                            <TableCell align="right">Airline Code</TableCell>
                            <TableCell align="right">IATA Designator</TableCell>
                            <TableCell align="right">Airline Name</TableCell>
                            <TableCell align="right">Issue Date </TableCell>
                            <TableCell align="right">Supplier Code </TableCell>
                            <TableCell align="right">Supplier Name </TableCell>
                            <TableCell align="right">Travel Date </TableCell>
                            <TableCell align="right">Corpcc Nick Name </TableCell>
                            <TableCell align="right">Corpcc Full Name </TableCell>
                            <TableCell align="right">Return Date </TableCell>
                            <TableCell align="right">Sector</TableCell>
                            <TableCell align="right">Ticket Type </TableCell>
                            <TableCell align="right">Travelclass Code </TableCell>
                            <TableCell align="right">Travelclass </TableCell>
                            <TableCell align="right">Travelid </TableCell>
                            <TableCell align="right">Paxname </TableCell>
                            <TableCell align="right">Returnclass Code </TableCell>
                            <TableCell align="right">Returnclass </TableCell>
                            <TableCell align="right">Booking Staff Code </TableCell>
                            <TableCell align="right">Booking Staff Prefix </TableCell>
                            <TableCell align="right">Booking Staff Name </TableCell>
                            <TableCell align="right">Total Paxno </TableCell>
                            <TableCell align="right">Pnr No</TableCell>
                            <TableCell align="right">Ticketing Staff Code </TableCell>
                            <TableCell align="right">Ticketing Staff Prefix </TableCell>
                            <TableCell align="right">Ticketing Staff Name </TableCell>
                            <TableCell align="right">GDS </TableCell>
                            <TableCell align="right">Tour Code </TableCell>
                            <TableCell align="right">LOP No</TableCell>
                            <TableCell align="right">Cust Emp No </TableCell>
                            <TableCell align="right">Refno </TableCell>
                            <TableCell align="right">Costcenter </TableCell>
                            <TableCell align="right">Conjtkt </TableCell>
                            <TableCell align="right">Adjuestment Status </TableCell>
                            <TableCell align="right">Restore Status</TableCell>
                            <TableCell align="right">Duplicate Status </TableCell>

                            <TableCell align="right">ROE in payable </TableCell>
                            <TableCell align="right">Admoracm Exp </TableCell>
                            <TableCell align="right">Published fare in {currency} </TableCell>
                            <TableCell align="right">Market Fare 1 </TableCell>
                            <TableCell align="right">Market Fare 2 </TableCell>
                            <TableCell align="right">Tax Details </TableCell>
                            <TableCell align="right">Tax 1 </TableCell>
                            <TableCell align="right">Tax 2 </TableCell>
                            <TableCell align="right">STD Commission </TableCell>
                            <TableCell align="right">STD Commission Percent </TableCell>
                            <TableCell align="right">Supplier Amount </TableCell>
                            <TableCell align="right">ROE  Of ROM Received </TableCell>
                            <TableCell align="right">Profit </TableCell>
                            <TableCell align="right">Service Fee </TableCell>
                            <TableCell align="right">Service Fee Percent </TableCell>
                            <TableCell align="right">Extra Earning </TableCell>
                            <TableCell align="right">Extra Earning percent </TableCell>
                            <TableCell align="right">Payback Account </TableCell>
                            <TableCell align="right">Payback Account in {currency} </TableCell>
                            <TableCell align="right">Additional Markup </TableCell>
                            <TableCell align="right">Additional Markup  Percent </TableCell>
                            <TableCell align="right">Discount  </TableCell>
                            <TableCell align="right">Discount Percent </TableCell>
                            <TableCell align="right">Output VAT/GSTIN in {currency} </TableCell>
                            <TableCell align="right">Selling Price in {currency} </TableCell>



                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {trows.map((row) => (
                            <TableRow
                              // key={row.name}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.id}
                              </TableCell>
                              <TableCell align="right">{row.stocktype}</TableCell>
                              <TableCell align="right">{row.ticketno}</TableCell>
                              <TableCell align="right">{row.originalissue}</TableCell>
                              <TableCell align="right">{row.airlinecode}</TableCell>
                              <TableCell align="right">{row.iatadesignator}</TableCell>
                              <TableCell align="right">{row.airlinename}</TableCell>
                              <TableCell align="right">{row.issuedate}</TableCell>
                              <TableCell align="right">{row.suppliercode}</TableCell>
                              <TableCell align="right">{row.suppliername}</TableCell>
                              <TableCell align="right">{row.traveldate}</TableCell>
                              <TableCell align="right">{row.corpccnickname}</TableCell>
                              <TableCell align="right">{row.corpccfullname}</TableCell>
                              <TableCell align="right">{row.returndate}</TableCell>
                              <TableCell align="right">{row.sector}</TableCell>
                              <TableCell align="right">{row.tickettype}</TableCell>
                              <TableCell align="right">{row.travelclasscode}</TableCell>
                              <TableCell align="right">{row.travelclass}</TableCell>
                              <TableCell align="right">{row.travelid}</TableCell>
                              <TableCell align="right">{row.paxname}</TableCell>
                              <TableCell align="right">{row.returnclasscode}</TableCell>
                              <TableCell align="right">{row.returnclass}</TableCell>
                              <TableCell align="right">{row.bookingstaffcode}</TableCell>
                              <TableCell align="right">{row.bookingstaffprefix}</TableCell>
                              <TableCell align="right">{row.bookingstaffname}</TableCell>
                              <TableCell align="right">{row.totalpaxno}</TableCell>
                              <TableCell align="right">{row.pnrno}</TableCell>
                              <TableCell align="right">{row.ticketingstaffcode}</TableCell>
                              <TableCell align="right">{row.ticketingstaffprefix}</TableCell>
                              <TableCell align="right">{row.ticketingstaffName}</TableCell>
                              <TableCell align="right">{row.gds}</TableCell>
                              <TableCell align="right">{row.tourcode}</TableCell>
                              <TableCell align="right">{row.lopno}</TableCell>
                              <TableCell align="right">{row.custempno}</TableCell>
                              <TableCell align="right">{row.refno}</TableCell>
                              <TableCell align="right">{row.remarks}</TableCell>
                              <TableCell align="right">{row.conjtkt}</TableCell>
                              <TableCell align="right">{row.adjuestmentstatus}</TableCell>
                              <TableCell align="right">{row.restorestatus}</TableCell>
                              <TableCell align="right">{row.duplicatestatus}</TableCell>

                              <TableCell align="right">{row.roeofrompayable}</TableCell>
                              <TableCell align="right">{row.admoracmexp}</TableCell>
                              <TableCell align="right">{row.publishedfareinomr}</TableCell>
                              <TableCell align="right">{row.marketfareinomr1}</TableCell>
                              <TableCell align="right">{row.marketfareinomr2}</TableCell>
                              <TableCell align="right">{row.taxdetails}</TableCell>
                              <TableCell align="right">{row.taxinomr1}</TableCell>
                              <TableCell align="right">{row.taxinomr2}</TableCell>
                              <TableCell align="right">{row.stdcommissioninomr}</TableCell>
                              <TableCell align="right">{row.stdcommissioninomrpercent}</TableCell>
                              <TableCell align="right">{row.supplieramtinomr}</TableCell>
                              <TableCell align="right">{row.roeofromreceived}</TableCell>
                              <TableCell align="right">{row.profit}</TableCell>
                              <TableCell align="right">{row.servicefeeinomr}</TableCell>
                              <TableCell align="right">{row.servicefeeinomrpercent}</TableCell>
                              <TableCell align="right">{row.extraearninginomr}</TableCell>
                              <TableCell align="right">{row.extraearninginomrpercent}</TableCell>
                              <TableCell align="right">{row.paybackaccount}</TableCell>
                              <TableCell align="right">{row.paybackaccountinomr}</TableCell>
                              <TableCell align="right">{row.additionalmarkupinomr}</TableCell>
                              <TableCell align="right">{row.additionalmarkupinomrpercent}</TableCell>
                              <TableCell align="right">{row.discountinomr}</TableCell>
                              <TableCell align="right">{row.discountinomrpercent}</TableCell>
                              <TableCell align="right">{row.outputvatinomr}</TableCell>
                              <TableCell align="right">{row.sellingpriceinomr}</TableCell>

                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
                : ""}


              {hrows.length > 0 ? 
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    Hotel
                  </Typography>

                </AccordionSummary>
                <AccordionDetails className='p-0'>
                    <TableContainer >

                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Hotel Id</TableCell>
                            <TableCell align="right">Type</TableCell>
                            <TableCell align="right">Voucher No</TableCell>
                            <TableCell align="right">Issue Date</TableCell>
                            <TableCell align="right">Supplier Code</TableCell>
                            <TableCell align="right">Supplier Name</TableCell>
                            <TableCell align="right">Check-in</TableCell>
                            <TableCell align="right">Corpcc Nick Name</TableCell>
                            <TableCell align="right">Corpcc Full Name</TableCell>
                            <TableCell align="right">Check-out</TableCell>
                            <TableCell align="right">Country</TableCell>
                            <TableCell align="right">City</TableCell>
                            <TableCell align="right">Orignial Country</TableCell>
                            <TableCell align="right">Hotel Code</TableCell>
                            <TableCell align="right">Hotel Name</TableCell>
                            <TableCell align="right">Numer of Rooms</TableCell>
                            <TableCell align="right">Traveler Id</TableCell>
                            <TableCell align="right">Guesters</TableCell>
                            <TableCell align="right">No of Nights</TableCell>
                            <TableCell align="right">Additional Guesters</TableCell>
                            <TableCell align="right">Issuing Staff</TableCell>
                            <TableCell align="right">Supplier Confno</TableCell>
                            <TableCell align="right">Hotel Confirmno</TableCell>
                            <TableCell align="right">Booking Staff</TableCell>
                            <TableCell align="right">LPO/TO No</TableCell>
                            <TableCell align="right">Cust Emp No</TableCell>
                            <TableCell align="right">Ref No</TableCell>
                            <TableCell align="right">Remarks</TableCell>
                            <TableCell align="right">Adult</TableCell>
                            <TableCell align="right">Child</TableCell>
                            <TableCell align="right">Infant</TableCell>
                            <TableCell align="right">Commission Collectors Code</TableCell>
                            <TableCell align="right">Commission Collectors Name </TableCell>
                            <TableCell align="right"> Room Type </TableCell>
                            <TableCell align="right">Booking Details </TableCell>
                            <TableCell align="right">Meals Plan </TableCell>
                            <TableCell align="right">Cost Center </TableCell>
                            <TableCell align="right">Adjuestment Status </TableCell>
                            <TableCell align="right">Duplicate Status </TableCell>
                            <TableCell align="right">Pnr </TableCell>

                            <TableCell align="right">ROE Payable </TableCell>
                            <TableCell align="right">Fare 1 </TableCell>
                            <TableCell align="right">Fare 2 </TableCell>
                            <TableCell align="right">ADM/ACM Exp </TableCell>
                            <TableCell align="right">Other Taxes in {currency} </TableCell>
                            <TableCell align="right">VAT </TableCell>
                            <TableCell align="right">Total Tax 1 </TableCell>
                            <TableCell align="right">Total Tax 2 </TableCell>
                            <TableCell align="right">Commission  </TableCell>
                            <TableCell align="right">Commission in percent </TableCell>
                            <TableCell align="right">Commission in Status </TableCell>
                            <TableCell align="right">Supplier Amount {currency} </TableCell>
                            <TableCell align="right">Input Vat in {currency} </TableCell>
                            <TableCell align="right">ROE Received </TableCell>
                            <TableCell align="right">Profit </TableCell>
                            <TableCell align="right">Service Fee in {currency} </TableCell>
                            <TableCell align="right">Servicefee Pecentage </TableCell>
                            <TableCell align="right">Extra Earning </TableCell>
                            <TableCell align="right">Extra Earning Percent </TableCell>
                            <TableCell align="right">Payback Account </TableCell>
                            <TableCell align="right">Payback Account </TableCell>
                            <TableCell align="right">Additional Markup </TableCell>
                            <TableCell align="right">Additional Markup Percent </TableCell>
                            <TableCell align="right">Disscount </TableCell>
                            <TableCell align="right">Disscount  percent </TableCell>
                            <TableCell align="right">Output Vat in {currency} </TableCell>
                            <TableCell align="right">Selling Price in {currency} </TableCell>
                            <TableCell align="right">Selling Price label </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {hrows.map((row) => (
                            <TableRow

                              sx={{ '&:last-child td, &:last-child th': { border: 0, width: "100%" } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.id}
                              </TableCell>
                              <TableCell align="right">{row.type}</TableCell>
                              <TableCell align="right">{row.voucherno}</TableCell>
                              <TableCell align="right">{row.issuedate}</TableCell>
                              <TableCell align="right">{row.suppliercode}</TableCell>
                              <TableCell align="right">{row.suppliername}</TableCell>
                              <TableCell align="right">{row.checkin}</TableCell>
                              <TableCell align="right">{row.corpccnickname}</TableCell>
                              <TableCell align="right">{row.corpccfullname}</TableCell>
                              <TableCell align="right">{row.checkout}</TableCell>
                              <TableCell align="right">{row.country}</TableCell>
                              <TableCell align="right">{row.city}</TableCell>
                              <TableCell align="right">{row.orignialcountry}</TableCell>
                              <TableCell align="right">{row.hotelcode}</TableCell>
                              <TableCell align="right">{row.hotelname}</TableCell>
                              <TableCell align="right">{row.numerofrooms}</TableCell>
                              <TableCell align="right">{row.travelerid}</TableCell>
                              <TableCell align="right">{row.guesters}</TableCell>
                              <TableCell align="right">{row.noofnights}</TableCell>
                              <TableCell align="right">{row.additionalguesters}</TableCell>
                              <TableCell align="right">{row.issuingstaff}</TableCell>
                              <TableCell align="right">{row.supplierconfno}</TableCell>
                              <TableCell align="right">{row.hotelconfirmno}</TableCell>
                              <TableCell align="right">{row.bookingstaff}</TableCell>
                              <TableCell align="right">{row.lpoortono}</TableCell>
                              <TableCell align="right">{row.custempno}</TableCell>
                              <TableCell align="right">{row.refno}</TableCell>
                              <TableCell align="right">{row.remarks}</TableCell>
                              <TableCell align="right">{row.adult}</TableCell>
                              <TableCell align="right">{row.child}</TableCell>
                              <TableCell align="right">{row.infant}</TableCell>
                              <TableCell align="right">{row.commissioncollectorscode}</TableCell>
                              <TableCell align="right">{row.commissioncollectorsname}</TableCell>
                              <TableCell align="right">{row.roomtype}</TableCell>
                              <TableCell align="right">{row.bookingdetails}</TableCell>
                              <TableCell align="right">{row.mealsplan}</TableCell>
                              <TableCell align="right">{row.costcenter}</TableCell>
                              <TableCell align="right">{row.adjuestmentstatus}</TableCell>
                              <TableCell align="right">{row.duplicatestatus}</TableCell>
                              <TableCell align="right">{row.pnr}</TableCell>

                              <TableCell align="right">{row.roeofrompayable}</TableCell>
                              <TableCell align="right">{row.fareinomr1}</TableCell>
                              <TableCell align="right">{row.fareinomr2}</TableCell>
                              <TableCell align="right">{row.admoracmexp}</TableCell>
                              <TableCell align="right">{row.othertaxes}</TableCell>
                              <TableCell align="right">{row.vat}</TableCell>
                              <TableCell align="right">{row.totaltaxinomr1}</TableCell>
                              <TableCell align="right">{row.totaltaxinomr2}</TableCell>
                              <TableCell align="right">{row.commissioninomr}</TableCell>
                              <TableCell align="right">{row.commissioninomrpercent}</TableCell>
                              <TableCell align="right">{row.commissioninstatus}</TableCell>
                              <TableCell align="right">{row.supplieramtinomr}</TableCell>
                              <TableCell align="right">{row.inputvatintomr}</TableCell>
                              <TableCell align="right">{row.roeofromreceived}</TableCell>
                              <TableCell align="right">{row.profit}</TableCell>
                              <TableCell align="right">{row.servicefeeinomr}</TableCell>
                              <TableCell align="right">{row.servicefeeinomrpecent}</TableCell>
                              <TableCell align="right">{row.extraearninginomr}</TableCell>
                              <TableCell align="right">{row.extraearninginomrpercent}</TableCell>
                              <TableCell align="right">{row.paybackaccount}</TableCell>
                              <TableCell align="right">{row.paybackaccountinomr}</TableCell>
                              <TableCell align="right">{row.additionalmarkupinomr}</TableCell>
                              <TableCell align="right">{row.additionalmarkupinomrpercent}</TableCell>
                              <TableCell align="right">{row.discountinomr}</TableCell>
                              <TableCell align="right">{row.discountinomrpercent}</TableCell>
                              <TableCell align="right">{row.outputvatinomr}</TableCell>
                              <TableCell align="right">{row.sellingpriceinomr}</TableCell>
                              <TableCell align="right">{row.sellingpriceinomrlabel}</TableCell>


                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                </AccordionDetails>
              </Accordion>
                : ""}

              {crows.length > 0 ?
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      Car
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>


                      <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Car Id</TableCell>
                              <TableCell align="right">Type</TableCell>
                              <TableCell align="right">Voucher No</TableCell>
                              <TableCell align="right">Issue Date</TableCell>
                              <TableCell align="right">Supplier Code</TableCell>
                              <TableCell align="right">Supplier Name</TableCell>
                              <TableCell align="right">From Date</TableCell>
                              <TableCell align="right">Corpcc Nick Name</TableCell>
                              <TableCell align="right">Corpcc Full Name</TableCell>
                              <TableCell align="right">To Date</TableCell>
                              <TableCell align="right">Country</TableCell>
                              <TableCell align="right">Rental Company Code</TableCell>
                              <TableCell align="right">Rental Companyname</TableCell>
                              <TableCell align="right">Traveler Id </TableCell>
                              <TableCell align="right"> Pax Name </TableCell>
                              <TableCell align="right">No of Cars </TableCell>
                              <TableCell align="right">Staff </TableCell>
                              <TableCell align="right">Supplier Confno </TableCell>
                              <TableCell align="right">No of Days </TableCell>
                              <TableCell align="right">LPO/TO No </TableCell>
                              <TableCell align="right">Cust Emp No </TableCell>
                              <TableCell align="right">Ref No </TableCell>
                              <TableCell align="right">Remarks </TableCell>
                              <TableCell align="right">Renting Station </TableCell>
                              <TableCell align="right">Drop Station </TableCell>
                              <TableCell align="right">Booking Details </TableCell>
                              <TableCell align="right">Cost Center </TableCell>
                              <TableCell align="right">Adjuestment Status </TableCell>
                              <TableCell align="right">Duplicate Status </TableCell>
                              <TableCell align="right">No of Duplicates </TableCell>
                              <TableCell align="right">Pnr </TableCell>

                              <TableCell align="right">ROE Payable </TableCell>
                              <TableCell align="right">Fare 1 </TableCell>
                              <TableCell align="right">Fare 2 </TableCell>
                              <TableCell align="right">ADM/ACM Exp </TableCell>
                              <TableCell align="right">Other Taxes in {currency} </TableCell>
                              <TableCell align="right">VAT </TableCell>
                              <TableCell align="right">Total Tax 1 </TableCell>
                              <TableCell align="right">Total Tax 2 </TableCell>
                              <TableCell align="right">Commission  </TableCell>
                              <TableCell align="right">Commission in percent </TableCell>
                              <TableCell align="right">Commission in Status </TableCell>
                              <TableCell align="right">Supplier Amount {currency} </TableCell>
                              <TableCell align="right">Input Vat in {currency} </TableCell>
                              <TableCell align="right">ROE Received </TableCell>
                              <TableCell align="right">Profit </TableCell>
                              <TableCell align="right">Service Fee in {currency} </TableCell>
                              <TableCell align="right">Servicefee Pecentage </TableCell>
                              <TableCell align="right">Extra Earning </TableCell>
                              <TableCell align="right">Extra Earning Percent </TableCell>
                              <TableCell align="right">Payback Account </TableCell>
                              <TableCell align="right">Payback Account </TableCell>
                              <TableCell align="right">Additional Markup </TableCell>
                              <TableCell align="right">Additional Markup Percent </TableCell>
                              <TableCell align="right">Disscount </TableCell>
                              <TableCell align="right">Disscount  percent </TableCell>
                              <TableCell align="right">Output Vat in {currency} </TableCell>
                              <TableCell align="right">Selling Price in {currency} </TableCell>
                              <TableCell align="right">Selling Price label </TableCell>





                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {crows.map((row) => (
                              <TableRow
                                // key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.id}
                                </TableCell>
                                <TableCell align="right">{row.type}</TableCell>
                                <TableCell align="right">{row.voucherno}</TableCell>
                                <TableCell align="right">{row.issuedate}</TableCell>
                                <TableCell align="right">{row.suppliercode}</TableCell>
                                <TableCell align="right">{row.suppliername}</TableCell>
                                <TableCell align="right">{row.fromdate}</TableCell>
                                <TableCell align="right">{row.corpccnickname}</TableCell>
                                <TableCell align="right">{row.corpccfullname}</TableCell>
                                <TableCell align="right">{row.todate}</TableCell>
                                <TableCell align="right">{row.country}</TableCell>
                                <TableCell align="right">{row.rentalcompanycode}</TableCell>
                                <TableCell align="right">{row.rentalcompanyname}</TableCell>
                                <TableCell align="right">{row.travelerid}</TableCell>
                                <TableCell align="right">{row.paxname}</TableCell>
                                <TableCell align="right">{row.noofcars}</TableCell>
                                <TableCell align="right">{row.staff}</TableCell>
                                <TableCell align="right">{row.supplierconfno}</TableCell>
                                <TableCell align="right">{row.lpoortono}</TableCell>
                                <TableCell align="right">{row.custempno}</TableCell>
                                <TableCell align="right">{row.refno}</TableCell>
                                <TableCell align="right">{row.remarks}</TableCell>
                                <TableCell align="right">{row.rentingstation}</TableCell>
                                <TableCell align="right">{row.dropstation}</TableCell>
                                <TableCell align="right">{row.bookingdetails}</TableCell>
                                <TableCell align="right">{row.costcenter}</TableCell>
                                <TableCell align="right">{row.adjuestmentstatus}</TableCell>
                                <TableCell align="right">{row.duplicatestatus}</TableCell>
                                <TableCell align="right">{row.noofduplicates}</TableCell>
                                <TableCell align="right">{row.pnr}</TableCell>

                                <TableCell align="right">{row.roeofrompayable}</TableCell>
                                <TableCell align="right">{row.fareinomr1}</TableCell>
                                <TableCell align="right">{row.fareinomr2}</TableCell>
                                <TableCell align="right">{row.admoracmexp}</TableCell>
                                <TableCell align="right">{row.othertaxes}</TableCell>
                                <TableCell align="right">{row.vat}</TableCell>
                                <TableCell align="right">{row.totaltaxinomr1}</TableCell>
                                <TableCell align="right">{row.totaltaxinomr2}</TableCell>
                                <TableCell align="right">{row.commissioninomr}</TableCell>
                                <TableCell align="right">{row.commissioninomrpercent}</TableCell>
                                <TableCell align="right">{row.commissioninstatus}</TableCell>
                                <TableCell align="right">{row.supplieramtinomr}</TableCell>
                                <TableCell align="right">{row.inputvatintomr}</TableCell>
                                <TableCell align="right">{row.roeofromreceived}</TableCell>
                                <TableCell align="right">{row.profit}</TableCell>
                                <TableCell align="right">{row.servicefeeinomr}</TableCell>
                                <TableCell align="right">{row.servicefeeinomrpecent}</TableCell>
                                <TableCell align="right">{row.extraearninginomr}</TableCell>
                                <TableCell align="right">{row.extraearninginomrpercent}</TableCell>
                                <TableCell align="right">{row.paybackaccount}</TableCell>
                                <TableCell align="right">{row.paybackaccountinomr}</TableCell>
                                <TableCell align="right">{row.additionalmarkupinomr}</TableCell>
                                <TableCell align="right">{row.additionalmarkupinomrpercent}</TableCell>
                                <TableCell align="right">{row.discountinomr}</TableCell>
                                <TableCell align="right">{row.discountinomrpercent}</TableCell>
                                <TableCell align="right">{row.outputvatinomr}</TableCell>
                                <TableCell align="right">{row.sellingpriceinomr}</TableCell>
                                <TableCell align="right">{row.sellingpriceinomrlabel}</TableCell>


                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                  </AccordionDetails>
                </Accordion>
                : ""}

              {srows.length > 0 ?
                <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      Service
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      <TableContainer >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Service Id</TableCell>
                              <TableCell align="right">Type</TableCell>
                              <TableCell align="right">Issue Date</TableCell>
                              <TableCell align="right">Service</TableCell>
                              <TableCell align="right">From Date</TableCell>
                              <TableCell align="right">Supplier Code</TableCell>
                              <TableCell align="right">Supplier Name</TableCell>
                              <TableCell align="right">To date</TableCell>
                              <TableCell align="right">Service Provider Code</TableCell>
                              <TableCell align="right">Service Provider Name</TableCell>
                              <TableCell align="right">No Of Days</TableCell>
                              <TableCell align="right">City</TableCell>
                              <TableCell align="right">Country</TableCell>
                              <TableCell align="right">Supplier Conf No </TableCell>
                              <TableCell align="right"> Corp cc Nick Name </TableCell>
                              <TableCell align="right">Corp cc Name </TableCell>
                              <TableCell align="right">Remarks </TableCell>
                              <TableCell align="right">Particulars </TableCell>
                              <TableCell align="right">Custempno </TableCell>
                              <TableCell align="right">Traveler Id </TableCell>
                              <TableCell align="right">Pax Name </TableCell>
                              <TableCell align="right">Adult </TableCell>
                              <TableCell align="right">Child </TableCell>
                              <TableCell align="right">LPO/TO No </TableCell>
                              <TableCell align="right">Staff </TableCell>
                              <TableCell align="right">Ref No </TableCell>
                              <TableCell align="right">Cost Centre </TableCell>
                              <TableCell align="right">Pnr </TableCell>

                              <TableCell align="right">ROE Payable </TableCell>
                              <TableCell align="right">Fare 1 </TableCell>
                              <TableCell align="right">Fare 2 </TableCell>
                              <TableCell align="right">ADM/ACM Exp </TableCell>
                              <TableCell align="right">Other Taxes in {currency} </TableCell>
                              <TableCell align="right">VAT </TableCell>
                              <TableCell align="right">Total Tax 1 </TableCell>
                              <TableCell align="right">Total Tax 2 </TableCell>
                              <TableCell align="right">Commission  </TableCell>
                              <TableCell align="right">Commission in percent </TableCell>
                              <TableCell align="right">Commission in Status </TableCell>
                              <TableCell align="right">Supplier Amount {currency} </TableCell>
                              <TableCell align="right">Input Vat in {currency} </TableCell>
                              <TableCell align="right">ROE Received </TableCell>
                              <TableCell align="right">Profit </TableCell>
                              <TableCell align="right">Service Fee in {currency} </TableCell>
                              <TableCell align="right">Servicefee Pecentage </TableCell>
                              <TableCell align="right">Extra Earning </TableCell>
                              <TableCell align="right">Extra Earning Percent </TableCell>
                              <TableCell align="right">Payback Account </TableCell>
                              <TableCell align="right">Payback Account </TableCell>
                              <TableCell align="right">Additional Markup </TableCell>
                              <TableCell align="right">Additional Markup Percent </TableCell>
                              <TableCell align="right">Disscount </TableCell>
                              <TableCell align="right">Disscount  percent </TableCell>
                              <TableCell align="right">Output Vat in {currency} </TableCell>
                              <TableCell align="right">Selling Price in {currency} </TableCell>
                              <TableCell align="right">Selling Price label </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {srows.map((row) => (
                              <TableRow
                                // key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.id}
                                </TableCell>
                                <TableCell align="right">{row.type}</TableCell>
                                <TableCell align="right">{row.issuedate}</TableCell>
                                <TableCell align="right">{row.service}</TableCell>
                                <TableCell align="right">{row.fromdate}</TableCell>
                                <TableCell align="right">{row.suppliercode}</TableCell>
                                <TableCell align="right">{row.suppliername}</TableCell>
                                <TableCell align="right">{row.todate}</TableCell>
                                <TableCell align="right">{row.serviceprovercode}</TableCell>

                                <TableCell align="right">{row.serviceprovername}</TableCell>
                                <TableCell align="right">{row.noofdays}</TableCell>
                                <TableCell align="right">{row.city}</TableCell>
                                <TableCell align="right">{row.country}</TableCell>
                                <TableCell align="right">{row.supplierconfno}</TableCell>
                                <TableCell align="right">{row.corpccnickname}</TableCell>

                                <TableCell align="right">{row.corpccname}</TableCell>
                                <TableCell align="right">{row.remarks}</TableCell>
                                <TableCell align="right">{row.particulars}</TableCell>
                                <TableCell align="right">{row.custempno}</TableCell>
                                <TableCell align="right">{row.travelerid}</TableCell>
                                <TableCell align="right">{row.paxname}</TableCell>
                                <TableCell align="right">{row.adult}</TableCell>
                                <TableCell align="right">{row.child}</TableCell>
                                <TableCell align="right">{row.lpoortono}</TableCell>
                                <TableCell align="right">{row.staff}</TableCell>
                                <TableCell align="right">{row.refno}</TableCell>
                                <TableCell align="right">{row.costcentre}</TableCell>
                                <TableCell align="right">{row.pnr}</TableCell>

                                <TableCell align="right">{row.roeofrompayable}</TableCell>
                                <TableCell align="right">{row.fareinomr1}</TableCell>
                                <TableCell align="right">{row.fareinomr2}</TableCell>
                                <TableCell align="right">{row.admoracmexp}</TableCell>
                                <TableCell align="right">{row.othertaxes}</TableCell>
                                <TableCell align="right">{row.vat}</TableCell>
                                <TableCell align="right">{row.totaltaxinomr1}</TableCell>
                                <TableCell align="right">{row.totaltaxinomr2}</TableCell>
                                <TableCell align="right">{row.commissioninomr}</TableCell>
                                <TableCell align="right">{row.commissioninomrpercent}</TableCell>
                                <TableCell align="right">{row.commissioninstatus}</TableCell>
                                <TableCell align="right">{row.supplieramtinomr}</TableCell>
                                <TableCell align="right">{row.inputvatintomr}</TableCell>
                                <TableCell align="right">{row.roeofromreceived}</TableCell>
                                <TableCell align="right">{row.profit}</TableCell>
                                <TableCell align="right">{row.servicefeeinomr}</TableCell>
                                <TableCell align="right">{row.servicefeeinomrpecent}</TableCell>
                                <TableCell align="right">{row.extraearninginomr}</TableCell>
                                <TableCell align="right">{row.extraearninginomrpercent}</TableCell>
                                <TableCell align="right">{row.paybackaccount}</TableCell>
                                <TableCell align="right">{row.paybackaccountinomr}</TableCell>
                                <TableCell align="right">{row.additionalmarkupinomr}</TableCell>
                                <TableCell align="right">{row.additionalmarkupinomrpercent}</TableCell>
                                <TableCell align="right">{row.discountinomr}</TableCell>
                                <TableCell align="right">{row.discountinomrpercent}</TableCell>
                                <TableCell align="right">{row.outputvatinomr}</TableCell>
                                <TableCell align="right">{row.sellingpriceinomr}</TableCell>
                                <TableCell align="right">{row.sellingpriceinomrlabel}</TableCell>


                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                  </AccordionDetails>
                </Accordion>
                : ""}
              {arows.length > 0 ?
                <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                  >
                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                      Adjustment
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                      <TableContainer >
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Service Id</TableCell>
                              <TableCell align="right">Adjustment No</TableCell>
                              <TableCell align="right">Common Amount </TableCell>
                              <TableCell align="right">Common Amount Percent</TableCell>
                              <TableCell align="right">Common Amount Label</TableCell>
                              <TableCell align="right">Narration1</TableCell>
                              <TableCell align="right">Pax Name</TableCell>
                              <TableCell align="right">Account Code</TableCell>
                              <TableCell align="right">Account Name</TableCell>
                              <TableCell align="right">Sub Customer Code</TableCell>
                              <TableCell align="right">Sub Customer Name</TableCell>
                              <TableCell align="right">Reference</TableCell>
                              <TableCell align="right">Narration2</TableCell>
                              <TableCell align="right">ROE</TableCell>
                              <TableCell align="right"> Amount 1 </TableCell>
                              <TableCell align="right">Amount Percent1 </TableCell>
                              <TableCell align="right">Amount 2 </TableCell>
                              <TableCell align="right">Original Amount </TableCell>
                              <TableCell align="right">Transfer Amount</TableCell>
                              <TableCell align="right">Datewise Common </TableCell>
                              <TableCell align="right">Paid Amount </TableCell>
                              <TableCell align="right">Balance Amount </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {arows.map((row) => (
                              <TableRow
                                // key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.id}
                                </TableCell>
                                <TableCell align="right">{row.adjustmentno}</TableCell>
                                <TableCell align="right">{row.commamtomr}</TableCell>
                                <TableCell align="right">{row.commamtomrpercent}</TableCell>
                                <TableCell align="right">{row.commamtomrlabel}</TableCell>
                                <TableCell align="right">{row.narration1}</TableCell>
                                <TableCell align="right">{row.paxname}</TableCell>
                                <TableCell align="right">{row.accountcode}</TableCell>
                                <TableCell align="right">{row.accountname}</TableCell>

                                <TableCell align="right">{row.subcustcode}</TableCell>
                                <TableCell align="right">{row.subcustname}</TableCell>
                                <TableCell align="right">{row.reference}</TableCell>
                                <TableCell align="right">{row.narration2}</TableCell>
                                <TableCell align="right">{row.roeofomr}</TableCell>
                                <TableCell align="right">{row.amountinomr1}</TableCell>
                                <TableCell align="right">{row.amountinomrpercent1}</TableCell>
                                <TableCell align="right">{row.amountinomr2}</TableCell>
                                <TableCell align="right">{row.originalamt}</TableCell>
                                <TableCell align="right">{row.transferamt}</TableCell>
                                <TableCell align="right">{row.datewisecomm}</TableCell>
                                <TableCell align="right">{row.paidamt}</TableCell>
                                <TableCell align="right">{row.balanceamt}</TableCell>

                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                  </AccordionDetails>
                </Accordion>
                : ""
              }
            </div>
          </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
export default withRouter(ViewISalesInvoices);