// import { createTheme, ThemeProvider } from '@material-ui/core';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import CustomToolbar from '../../../src/CommonComponents/CustomToolbar'
import MUIDataTable from 'mui-datatables';
import TourServices from '../../Traacs/Invoice/TourServices';
import { ThemeProvider, createTheme } from '@mui/material';
import withRouter from '../../withRouter';


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});


const TicketList = () => {
  const [masterList, setMasterList] = useState([]);

  useEffect(() => {
    getMasterList();
  }, [])

//   const delteDataHandler = (e) => {
//     if (confirm('Are you sure you want to Delete this thing into the database?')) {
//       TourServices.deleteMCar(e).then((r) => {
//         console.log(r.data);
//         getMasterList();
//       })
//       console.log('Thing was Deleted to the database.');
//     } else {
      
//       console.log('Thing was not Deleted to the database.');
//     }
    
//   }

  const getMasterList = () => {
    TourServices.getAllTicketList().then((r) => {
      console.log(r.data);
      setMasterList(r.data);

    })
  }
  const columns = [
    {
      name: "id",
      label: "Invoice Id",
      options: { filter: true, sort: true },
    },
    {
      name: "stocktype",
      label: "Stock Type",
      options: { filter: true, sort: true },
    },
    {
      name: "ticketno",
      label: "Ticketno",
      options: { filter: true, sort: true },
    },
    {
      name: "paxname",
      label: "Pax Name",
      options: { filter: true, sort: true },
    },
    {
      name: "issuedate",
      label: "Issue Date",
      options: { filter: true, sort: true },
    },
    {
      name: "profit",
      label: "Profit",
      options: { filter: true, sort: true },
    },
    {
      name: "sellingpriceinomr",
      label: "Selling price ",
      options: { filter: true, sort: true },
    },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {/* <button
                  as="button"
                  data-tip
                  data-for="delete"
                  className="btn btn-sm deletebutton"
                 // to={{ state: {id: tableMeta.rowData.find(e=>e) ,pagename:"Employee" }  }}
                  onClick={()=>
                delteDataHandler(tableMeta.rowData.find(e=>e))
                  }
                >Delete</button> */}
               {/* <Link as="button" className="btn btn-sm hovbuttonColour mr-2"
                  to={{ pathname: "/mastercar"}} state= {{id: tableMeta.rowData.find(e=>e) ,pagename:"Edit Master Cards" }}>Edit</Link> */}
            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    customToolbar: () => {
      return (
        <>
          (
            <Link
            as="button"
            className="float-right btn btn-sm m-0 p-0"
            // to={{ pathname: "/mastercar"}} 
            state= {{id: -1,pagename:"Add Car" }}
          >
            <CustomToolbar />
          </Link>
          )
        </>
      );
    },
  };

  return (

    <>
     
     
          <div className='card'>
            <div className='card-body'>

              <ThemeProvider theme={theme}>
                <MUIDataTable
                  className="table-sm px-3"
                //   title={"masterLists"}
                  data={masterList}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>
            </div>
          </div>

    </>
  )
}

export default withRouter(TicketList);