import React, { Component } from "react";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import { Autocomplete } from "@mui/material";
import { Link } from "react-router-dom";
import { userid } from "../../Common/Credentials";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import CustomerService from "./CustomerService";
import { Form, Formik } from "formik";

export default class Customerdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: '',
      showform:false,
      address:'',
      address2:'',
      gstin:'',
      zip:'',
      CountryData:[],
      StateData:[],
      CityData:[],
      country:'',
      state:'',
      city:'',
    };
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    const id = this.props.location.state.id;
    CustomerService.retriveCustomer(id).then(res=>{
        this.setState({data:res.data})
    })
  }
  render() {
      let{ address,
        address2,
        gstin,
        zip,
        CountryData,
        StateData,
        CityData,
        country,
        state,
        city}=this.state
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card ">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <Link
                  as="button"
                  className=" text-secondary"
                  to={{ pathname: "/customers"}} state= {{ message: ""}}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to={{ pathname: "/customers"}} state= {{ message: "" }}>
                  Customers
                </Link>
              </li>
              <li className="breadcrumb-item active">Customer details</li>
            </ol>
            <div className="card-body">
              <div className="form-row">
                <fieldset className="form-group col-md-12">
                  <table className="float-left">
                    <tbody>
                      <tr>
                        <td>Customer Id</td>
                        <td>: {this.state.data.customcustomerid}</td>
                      </tr>
                      <tr>
                        <td>Company</td>
                        <td>: {this.state.data.companyname}</td>
                      </tr>
                      <tr>
                        <td>Contact Name</td>
                        <td>: {this.state.data.contactname}</td>
                      </tr>
                      <tr>
                        <td>Contact Number</td>
                        <td>: {this.state.data.contactnumber}</td>
                      </tr>
                     
                      <tr>
                        <td>Email</td>
                        <td>: {this.state.data.email}</td>
                      </tr>
                      {/* {this.state.data.countries.name==='INDIA'? */}
                      <tr>
                        <td>PAN</td>
                        <td>: {this.state.data.pan}</td>
                      </tr>
                      {/* :""} */}
                       <tr>
                        <td>Address 1</td>
                        <td>&nbsp;&nbsp;{this.state.data.address},<br/>
                        :&nbsp;{this.state.data.address2},<br/>
                        &nbsp;&nbsp;{this.state.data.cityname},{this.state.data.statename},{this.state.data.countryname} - {this.state.data.zip}</td>
                      </tr>
                      <tr>
                          <td>GSTIN</td>
                          <td>: {this.state.data.gstin}</td>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
                {this.state.showform === false?
                <fieldset>
                    <button className="btn btn-sm hovbuttonColour" onClick={()=>this.setState({showform:true})}>Add Address</button>
                </fieldset>:
                <fieldset className="form-group col-md-12">
                 <Formik
                 initialValues={{
                   address,
                   address2,
                   gstin,
                   zip,
                   CountryData,
                   StateData,
                   CityData,
                   country,
                   state,
                   city,
                 }}
                 onSubmit={this.onSubmit}
                 validateOnChange={false}
                 validate={this.validate}
                 enableReinitialize={true}
               >
                 {({ setFieldValue, values }) => (
                   <Form autoComplete="off">
                <div className="form-row">
                 <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="address"
                          label="Address"
                          value={this.state.address}
                          onChange={(e) =>
                            this.setState({ address: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="address2"
                          label="Area/Colony/Street"
                          value={this.state.address2}
                          onChange={(e) =>
                            this.setState({ address2: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="country"
                          options={this.state.CountryData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.country ? undefined : undefined}
                          onChange={this.ChangeteCountry}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="country"
                              value={this.state.country}
                              label={`Select Country (${this.state.country})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="state"
                          options={this.state.StateData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.state ? undefined : undefined}
                          onChange={this.ChangeState}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="state"
                              value={this.state.state}
                              label={`Select State (${this.state.state})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="city"
                          options={this.state.CityData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.city ? undefined : undefined}
                          onChange={this.handleCity}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name='city'
                              value={this.state.city}
                              label={`Select City (${this.state.city})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                </div>
                </Form>
                 )}
                </Formik>
                </fieldset>
                }
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
