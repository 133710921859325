import React, { useEffect, useState } from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Menu from "../Component/Menu.js";
import Header from "../Component/Header.js";
import { screens } from "../Common/Products.js";
import UsercustomerService from "../Masters/Usercustomers/UsercustomerService.js";
import SettingsServices from "../Settings/SettingsServices.js";
import Credentials, { currentTenant, transactionFrom, userid } from "../Common/Credentials.js";

import dashboardIcon from "../images/Icons/dashboard.png"
import transactionsIcon from "../images/Icons/transaction.png"
import reportsIcon from "../images/Icons/annual-report.png"
import directtradeIcon from "../images/Icons/agreement.png"
import posIcon from "../images/Icons/swipe-card.png"
import { Backdrop } from "@mui/material";

const SalesMenu = () => {
  const [dnrequired, setDnrequired] = useState(true);
  const [usercustomers, setUsercustomers] = useState(false);
  useEffect(() => {
    Credentials.setSelectedMenu("Sales")
    SettingsServices.GetSettings(currentTenant).then((response) =>
      setDnrequired(response.data.dnreq)
    );
    UsercustomerService.GetUserCustomersbyUser(userid).then((res) =>
      setUsercustomers(
        res.data === "" || res.data === undefined || res.data === null
          ? false
          : true
      )
    );
  }, []);
  return (
    <div>
       <Backdrop
      sx={{
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      open={transactionFrom === null}
      // onClick={handleClose}
    >
       <div className="flex-column ">
      <div className="d-flex justify-content-center">
      <i className="text-red">Please set transaction start date in order to continue.</i>
      </div>
      <div className="d-flex justify-content-center">
      <Link to="/settings" className="btn btn-sm hovbuttonColour">Set</Link>
      </div>
      </div>
    
    </Backdrop>
      <Header />
      <Menu />
      <section className="content-wrapper">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item text-secondary">
            <Link to="/index">Home</Link>
          </li>
          <li className="breadcrumb-item active">Sales</li>
        </ol>
        <div className="h-max border-slate-300 bg-white p-4 rounded-lg ">
            <ul className="d-flex flex-row list-unstyled">
              <li className="p-1 mr-4">
                <Link
                  to={{ pathname: "/index" }}
                  state={{ message: "" }}
                  className="textColour py-1"
                >
                   <img className="nav-icon mr-2" width="25" src={dashboardIcon}/>
                  Dashboard
                </Link>
              </li>
              {screens.includes("Direct Trade") ? (
                <li>
                <Link
                  to={{ pathname: "/directtrade" }}
                  state={{ message: "" }}
                  className="textColour py-1"
                >
                  <img className="nav-icon mr-2" width="25" src={directtradeIcon}/>
                  Direct Trade 
                </Link>
                </li>
              ) : (
                ""
              )}
            </ul>
          <div className="row gap-x-8 gap-y-6 mt-0">
            <div className="col-sm-12 col-md-6 col-lg-4 w-full ">
              <span className="font-weight-bold my-3">
              <img className="nav-icon" width="25" src={transactionsIcon}/>
                <span className="pl-2">Transactions</span>
              </span>
              <ul className="menuul pl-4 ml-3">
                {screens.includes("Quotation") ? (
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/quotation" }}
                      state={{ message: "" }}
                    >
                      Quotation
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {screens.includes("Sales") || screens.includes("Sale Order") ? (
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/salesorder" }}
                      state={{ message: "" }}
                    >
                      Sale Order
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {screens.includes("Invoice") ? (
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/salesinvoice" }}
                      state={{ message: "", type: "Tax Invoice" }}
                    >
                      Invoice
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {screens.includes("Delivery/Shipment Note") &&
                dnrequired === "YES" ? (
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/deliverynote" }}
                      state={{ message: "" }}
                    >
                      Delivery / Shipment Note
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {screens.includes("Sales Returns") ? (
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/salesreturns" }}
                      state={{ message: "" }}
                    >
                      Sales Returns
                    </Link>
                  </li>
                ) : (
                  ""
                )}
                {/* {screens.includes("Debit Note") ? ( */}
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/creditnotes" }}
                      state={{ message: "" }}
                    >
                      Credit Notes
                    </Link>
                  </li>
                {/* ) : (
                  ""
                )} */}
                {screens.includes("Receipts") && usercustomers ? (
                  <li>
                    <Link
                      className="textColour p-1"
                      to={{ pathname: "/salesreceipts" }}
                      state={{ message: "" }}
                    >
                      Receipts
                    </Link>
                  </li>
                ) : (
                  ""
                )}
              </ul>
            </div>
            {screens.includes("Point of Sale") ||
            screens.includes("Stock Acceptance") ||
            screens.includes("Pos Price Master") ? (
              <div className="w-full col-sm-12 col-md-6 col-lg-4">
                <span className="font-weight-bold my-3">
                <img className="nav-icon" width="25" src={posIcon}/>
                  <span className="pl-2"> Point of Sale</span>
                </span>
                <ul className="menuul pl-4 ml-3">
                  {screens.includes("Pos Price Master") ? (
                    <li>
                      <Link
                        className="textColour p-1"
                        to={{ pathname: "/posprice" }}
                        state={{ message: "" }}
                      >
                        Price Master
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {screens.includes("Point of Sale") ? (
                    <li>
                      <Link
                        className="textColour p-1"
                        to={{ pathname: "/pointofsale" }}
                        state={{ message: "" }}
                      >
                        Point of Sale
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {screens.includes("Stock Acceptance") ? (
                    <li>
                      <Link
                        className="textColour p-1"
                        to={{ pathname: "/acceptingstockissuetable" }}
                        state={{ message: "" }}
                      >
                        Stock Acceptance
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
            ) : (
              ""
            )}
            {/* {screens.includes("Sales Reports")? */}
            <div className="w-full col-sm-12 col-md-6 col-lg-4">
              <span className="font-weight-bold my-3">
              <img className="nav-icon" width="25" src={reportsIcon}/>
                <span className="pl-2"> Reports</span>
              </span>
              <ul className="menuul pl-4 ml-3">
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/salesreport" }}
                    state={{ message: "" }}
                  >
                    Sales Report (Invoice)
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/saleordersreport" }}
                    state={{ message: "" }}
                  >
                    Sale Orders Report
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/salesreturnsreport" }}
                    state={{ message: "" }}
                  >
                    Sales Returns Report
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/hsnsummary" }}
                    state={{ message: "" }}
                  >
                    HSN Summary
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/overdues" }}
                    state={{ message: "" }}
                  >
                    Overdues
                  </Link>
                </li>
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/agentsreports" }}
                    state={{ message: "" }}
                  >
                    Agents Report
                  </Link>
                </li>
              </ul>
            </div>
            {/* :""} */}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default withRouter(SalesMenu);
