import {
  Box,
  Step,
  StepButton,
  Stepper,
  Typography,
  Button,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  TableContainer,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Slider,
} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";import { Formik } from "formik";
import * as React from "react";
import { Form, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
import { userid, currentTenant, thousandsdisplay } from "../../Common/Credentials";
import ProductionServices from "../ProductionServices";
import { useLocation, useNavigate } from "react-router";
import SalesServices from "../../Sales/SalesServices";
import { Autocomplete } from "@mui/material";
import WarehouseServices from "../../Masters/Warehouse/WarehouseServices";
import WorkCenterServices from "../../Masters/WorkCenter/WorkCenterServices";
import CategoryServices from "../../Masters/Category/CategoryServices";
import InventoryService from "../../inventory/InventoryService";
import UserService from "../../users/UserService";
// import UomServices from "../../Masters/Uom/UomServices";
import {NumericFormat} from "react-number-format";
import moment from "moment";
import withRouter from "../../withRouter";


const NewEstimation = (props) => {
  const history = useLocation();
  const navigate = useNavigate();

  const [id] = React.useState(history.state === undefined?-1:history.state.id)
  const [loader, setLoader] = React.useState(false);
  const [estimationtype, setEstimationtype] = React.useState("OneTime");
  const [estimationtypeList] = React.useState([
    "OneTime",
    "Continuous",
  ]);

  const [steps,setSteps] = React.useState([
    "Estimation Details",
    "Raw Materials",
    "Equipments",
    "Man Power",
    "Final/End Products",
    "Submit",
  ])

  const [frequency,setfrequency] = React.useState(0)
  const [userList,setUserList] = React.useState([]); 
  const [filtereduserList,setFiltereduserList] = React.useState([]); 
  const [againstso,setAgainstSo] = React.useState(false);
  const [soList, setSOList] = React.useState([]);
  const [soid, setSoId] = React.useState("");
  const [whid, setWhId] = React.useState("");
  const [whList, setWhList] = React.useState([]);
  const [warehouse, setWarehouse] = React.useState("");
  const [categoryList,setCategoryList] = React.useState([]);
  const [category,setCategory] = React.useState("");
  const [catid,setCatId] = React.useState("");
  const [appxamount, setAppxAmount] = React.useState();
  // const [UOMList,setUOMList]= React.useState([]);
  const [uom,setUOM]  = React.useState("");
  const [uomid,setUOMId]  = React.useState("");
  const [amount,setAmount] = React.useState(0);
  const [price,setPrice] = React.useState(0);

  const [itemsList,setItemList] = React.useState([]);
  const [filterItemList,setFilterItemList]= React.useState([]);
  const [itemid, setItemId] = React.useState("");
  const [itemname, setItemname] = React.useState("");
  const [itemquantity, setItemquantity] = React.useState("");
  const [bomitems, setBomItems] = React.useState([]);

  const [equipmentid, setEquipmentId] = React.useState("");
  const [equipment, setEquipment] = React.useState("");
  const [equipmentquantity, setEquipmentQuantity] = React.useState("");
  const [equipmentList, setEquipmentList] = React.useState([]);

  // const [mantypeid, setManTypeId] = React.useState("");
  const [mantype, setManType] = React.useState("");
  const [mancount, setManCount] = React.useState("");
  const [manpowerList, setManPowerList] = React.useState([]);

  const [productid, setProductId] = React.useState("");
  const [productname, setProductName] = React.useState("");
  const [productquantity, setproductQuantity] = React.useState("");
  const [finalproductsList, setFinalProductsList] = React.useState([]);

  const [to, setTo] = React.useState("");
  const [toname, setToName] = React.useState("");
  const [wcList, setWCList] = React.useState([]);
  const [workcenterid, setWorkCenterId] = React.useState("");
  const [workcenter, setWorkCenter] = React.useState("");
  const [startdate, setStartDate] = React.useState(new Date());
  const [enddate, setEndDate] = React.useState(new Date());
  const [duration, setDuration] = React.useState(0);
  const [qastatus, setQAstatus] = React.useState(true);
  const [advance, setAdvance] = React.useState(0);

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed] = React.useState({});

  const [errormessage,setErrorMsg] = React.useState("");


  React.useEffect(() => {
    SalesServices.GetSoIdofProducts(currentTenant).then((response) =>
      setSOList(response.data)
    );

    WarehouseServices.getWarehousesidname(currentTenant).then((response) => {
      setWhList(response.data);
    });

    CategoryServices.getCategory(currentTenant).then((response) =>
      setCategoryList(response.data.filter((e) => e.categorytype === "Product"))
    );
    InventoryService.retriveAllItems(currentTenant).then((response) =>setItemList(response.data))

    // InventoryService.getItemNames(currentTenant).then((response) =>setItemList(response.data))

    UserService.retriveAllUsers(currentTenant).then(response=>setUserList(response.data))
    
    // UomServices.getUOM(currentTenant).then((response) =>
    // setUOMList(response.data)
    // );
    if(id === -1){
      return
    }else{

    ProductionServices.getEstimationsbyId(id).then(response=>{
      setEstimationtype(response.data.estimationtype)
      setBomItems(response.data.bomitems)
      setEquipmentList(response.data.equipments)
      setManPowerList(response.data.manpower)
      setFinalProductsList(response.data.finalproduct)
      setDuration(response.data.duration)
      setStartDate(response.data.startdate)
      setEndDate(response.data.enddate)
      setQAstatus(response.data.qastatus)
      setWorkCenter(response.data.workcenter)
      setWorkCenterId(response.data.workcenterid)
      setTo(response.data.to)
      setToName(response.data.toname)
      setAgainstSo(response.data.soid === "NA"?false:true)
      setSoId(response.data.soid)
      setAppxAmount(response.data.appxamount === undefined?0:response.data.appxamount)
      setAdvance(response.data.advance)
      setWhId(response.data.whid)
      setWarehouse(response.data.warehouse)
    })
  }
  }, [id]);

  const handelEstimationTypeChange = (e) => {
    setEstimationtype(e.target.value);
    e.target.value === "Continuous"?
    setSteps ([
      "Estimation Details",
      "Final/End Products",
      "Raw Materials",
      "Equipments",
      "Man Power",
      "Submit",
    ]): setSteps([
      "Estimation Details",
      "Raw Materials",
      "Equipments",
      "Man Power",
      "Final/End Products",
      "Submit",
    ])
    setBomItems([])
    setEquipmentList([])
    setManPowerList([])
    setFinalProductsList([])
  };

  const handleagainstso = () =>{
    setAgainstSo(!againstso);
  }

    /* ----------BOM Start--------- */

  const handleCategoryChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setCatId("");
      setCategory("");
    } else {
      setCatId(newValue.id);
      setCategory(newValue.category);
      setFilterItemList(activeStep===1?itemsList.filter(e=>e.catid === newValue.id && e.cost !== null): itemsList.filter(e=>e.catid === newValue.id))
    }
  };

  const handleSelectItem = (e, newValue) => {
    if(newValue === "" || newValue === null || newValue === undefined) {
      setItemname("");
      setItemId("");
      setPrice(0)
    }else{
      setItemname(newValue.name);
      setItemId(newValue.id);
      setPrice(itemsList.find(e=>e.id === newValue.id).cost)
      setUOM(itemsList.find(e=>e.id === newValue.id).uomname)
      setUOMId(itemsList.find(e=>e.id === newValue.id).uom)
    }
  };

  // const handleUOM = (e,newValue) =>{
  //   if(newValue === "" || newValue === null || newValue === undefined) {
  //     setUOM("");
  //     setUOMId("");
  //   }else{
  //     setUOM(newValue.name);
  //     setUOMId(newValue.id);
  //   }
  // }

  const handleItemQuantity = (e) => {
    setItemquantity(e.target.value);
  };

  const handlefrequencyChange = (event, newValue) => {
    setfrequency(newValue)
  }

  const handleAddBOMItem = () => {
    if (itemname === "" && itemquantity === "" && itemquantity === "0" && itemquantity === 0 && uom ===""){
    }
    else{
      if(bomitems.length === 0||bomitems.filter(e=>e.itemid === itemid).length === 0){
      let data = {
        itemid: itemid,
        itemname: itemname,
        quantity: parseFloat(itemquantity),
        uom:uom,
        uomid:uomid,
        frequency:frequency,
        price:price,
        totalprice:price*parseFloat(itemquantity)
      };
      bomitems.push(data)
    }else{
      bomitems.find(e=>e.itemid === itemid).quantity = bomitems.find(e=>e.itemid === itemid).quantity + parseFloat(itemquantity)
      bomitems.find(e=>e.itemid === itemid).totalprice = bomitems.find(e=>e.itemid === itemid).totalprice + (parseFloat(itemquantity)*price)
    }
      setItemquantity("")
      setUOM("")
      setUOMId("")
      setfrequency("")
    }
  };

  const handelRemoveItem = (value) => {
    if (value !== "") {
      setBomItems(bomitems.filter((e, index) => index !== value));
      setAppxAmount(appxamount - bomitems.find((e, index) => index === value).totalprice)
    }
  };


    /* ----------BOM End--------- */

    /* ----------Equipment Start--------- */


  const handleSelectEquipment = (e, newValue) => {
    if(newValue === "" || newValue === null || newValue === undefined) {
      setEquipment("");
      setEquipmentId("");
    }else{
      setEquipment(newValue.name);
      setEquipmentId(newValue.id);
    }
  };

  const handleEquipmentQuantity = (e) => {
    setEquipmentQuantity(e.target.value);
  };

  const handleAmount = (e) => {
    setAmount(e.target.value);
  };



  const handleAddEquipments = () => {
    if (equipment !== "" && equipmentquantity !== "") {
      let data = {
        name: equipment,
        itemid:equipmentid,
        quantity: parseFloat(equipmentquantity),
        amount:parseFloat(amount),
        totalamount:parseFloat(equipmentquantity)*parseFloat(amount)
      };
      equipmentList.push(data);
      setAmount(0)
      setEquipmentQuantity(0)
    }
  };

  const handelRemoveEquipment = (value) => {
    if (value !== "") {
      setEquipmentList(equipmentList.filter((e, index) => index !== value));
      setAppxAmount(appxamount - equipmentList.find((e, index) => index === value).totalamount)
    }
  };

  
    /* ----------Equipment End--------- */
 
    /* ----------Man Power Start--------- */


  const handleSelectManType = (e) => {
    setManType(e.target.value);
    // setManTypeId(e.target.value);
  };

  const handleManCount = (e) => {
    setManCount(e.target.value);
  };

  const handleAddManPower = () => {
    if (mantype !== "" && mancount !== "") {
      let data = {
        mantypeid: 1,
        mantype: mantype,
        mancount: parseFloat(mancount),
        amount:parseFloat(amount),
        totalamount:parseFloat(mancount)*parseFloat(amount)

      };
      manpowerList.push(data);
    }
  };

  const handleRemoveManPower = (value) => {
    if (value !== "") {
      setManPowerList(manpowerList.filter((e, index) => index !== value));
      setAppxAmount(appxamount - manpowerList.filter((e, index) => index === value).totalamount)
    }
  };

  
    /* ----------Man Power End--------- */
 
    /* ----------Fianl Products Start--------- */
  

  const handleSelectProduct = (e,newValue) => {
    if(newValue === "" || newValue === null || newValue === undefined) {
      setProductName("");
      setProductId("");
    }else{
      setProductName(newValue.name);
      setProductId(newValue.id);
      setUOM(newValue.uomname);
      setUOMId(newValue.uomid)
    }
  };

  const handleFinalProductsQuantity = (e) => {
    setproductQuantity(e.target.value);
  };

  const handleAddFinalProducts = () => {
     if(productname !== "" && productquantity !== "" && productquantity !== '0' && productquantity !== 0){
      let data = {
        itemid: productid,
        itemname: productname,
        quantity: parseFloat(productquantity),
        uom:uom,
        uomid:uomid
      }
      finalproductsList.push(data);
      setproductQuantity("")
      setUOM("")
      setUOMId("")
    }    
  };

  const handleRemoveFinalProduct = (value) => {
    if (value !== "") {
      setFinalProductsList(
        finalproductsList.filter((e, index) => index !== value)
      );
    }
  };

  
    /* ----------Fianl Products End--------- */
  
    /* ----------Submit Start--------- */
  

  const handleWarehouseChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setWhId("");
      setWarehouse("");
      setWorkCenter("");
      setWorkCenterId("");
      setTo('')
      setToName('')
      setFiltereduserList([])
    } else {
      setWhId(newValue.id);
      setWarehouse(newValue.name);
      WorkCenterServices.WorkCenterbywarehouse(newValue.id).then((res) =>
        setWCList(res.data)
      );
    }
  };

  const handleToChange = (e,value) => {
    if (value === "" || value === null || value === undefined) {
      setTo('')
      setToName('')
    }
    else{
    // let userdata = filtereduserList.find(u=>u.first +" "+u.last+"("+u.id+")" ===value)
    // console.log(userdata,value)
    setTo(value.id);
    setToName(value.first +" "+value.last)
    }
  };

  const handleWorkCenterChange = (e, value) => {
    if (value === "" || value === null || value === undefined) {
      setWorkCenter("");
      setWorkCenterId("");
      setTo('')
      setToName('')
    } else {
      setWorkCenter(value.name);
      setWorkCenterId(value.id);
      setFiltereduserList(userList.filter(e=>e.wcid === value.id))
    }
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setEndDate(date)
  };

  const handleEndDateChange = (date) =>{
    setEndDate(date)
    const diffInMs = Math.abs(startdate - date)
    setDuration(Math.ceil(diffInMs / (1000 * 60 * 60 * 24)))
  }

  const handleDurationChange = (event,newValue) => {
    setDuration(newValue);
    setEndDate(moment(startdate).add(newValue,"days"))
  };

  const handleAppxAmountChange = (e) => {
    setAppxAmount(e.target.value);
  };

  const handleAdvanceChange =(e) => {
    setAdvance(e.target.value)
  }

  const handleQAstatusChange = () => {
    setQAstatus(!qastatus);
  };

  const handleonSubmit = (e) => {
    e.preventDefault();
    if(appxamount===''|| appxamount === undefined){}else{
    if(againstso===true && soid ===""){
      setErrorMsg("Please Select Sale Order")
    }else{
      setLoader(true)
    let data = {
      id:id,
      estimationtype: estimationtype === "OneTime" ? 0 : 1,
      bomitems: bomitems,
      equipments: equipmentList,
      manpower: manpowerList,
      finalproduct: finalproductsList,
      createdby: userid,
      updatedby: userid,
      duration: parseFloat(duration),
      tenantid: currentTenant,
      startdate: startdate,
      enddate: enddate,
      qastatus: qastatus,
      workcenter: workcenter,
      workcenterid: workcenterid,
      to: to,
      toname:toname,
      soid: againstso===true?soid:"NA",
      appxamount:appxamount,
      advance:advance,
      whid:whid,
      warehouse:warehouse,
    };
    if(id === -1){
    ProductionServices.NewEstimation(data).then(() =>
      navigate("/estimation")
    ).catch(()=>setLoader(false))
    }else{
      ProductionServices.UpdateEstimation(data).then(() =>
      navigate("/estimation")
    ).catch(()=>setLoader(false))
    }
  }
}
  };
  
    /* ----------Submit End--------- */
  

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    if(newActiveStep === 5){
      let a = 0
      let b = 0
      let c = 0
      bomitems.map(e=>a = a + e.totalprice)
      equipmentList.map(e=> b = b + e.totalamount)
      manpowerList.map(e=>c = c+ e.totalamount)
      setAppxAmount(a+b+c)
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    if(step === 5){
      let a = 0
      let b = 0
      let c = 0
      bomitems.map(e=>a = a + e.totalprice)
      equipmentList.map(e=> b = b + e.totalamount)
      manpowerList.map(e=>c = c+ e.totalamount)
      setAppxAmount(a+b+c)

    }
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/estimation" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/production">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/estimation">Estimation/Planning</Link>
            </li>
            <li className="breadcrumb-item active">New Estimation/Planning</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">
                        New Estimation/Planning - {estimationtype}
                      </h3>
                    </div>

                    <div className="card-body">
                      {estimationtypeList.map((option) => {
                        return (
                          <label key={option}>
                            <div className="btn-group">
                              <input
                                className={
                                  estimationtype === option
                                    ? "btn hovborderbuttonColour"
                                    : "btn hovbuttonColour"
                                }
                                type="button"
                                key={option}
                                onClick={handelEstimationTypeChange}
                                style={{ marginRight: 8, width: 250 }}
                                value={option}
                              />
                            </div>
                          </label>
                        );
                      })}
                        <Box sx={{ width: "100%" }}>
                          <Stepper nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => (
                              <Step key={label} completed={completed[index]}>
                                <StepButton
                                  color="inherit"
                                  onClick={handleStep(index)}
                                  style={{ outline: "none" }}
                                >
                                  {label}
                                </StepButton>
                              </Step>
                            ))}
                          </Stepper>
                          <div>
                            {allStepsCompleted() ? (
                              <React.Fragment></React.Fragment>
                            ) : (
                              <React.Fragment>
                                <Formik
                                  initialValues={{}}
                                  // onSubmit={onSubmit}
                                  validateOnChange={false}
                                  // validate={validate}
                                  enableReinitialize={true}
                                >
                                  {({ setFieldValue, values }) => (
                                    <Form
                                      autoComplete="off"
                                      style={{ paddingLeft: "30px" }}
                                    >
                                      {/* ----------Estimation Details Start--------- */}
                                      {activeStep === 0 || activeStep === 5 ? (
                                        <div className="form-row mt-3">
                                          <fieldset className="col-md-3">
                                            <FormGroup row>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={againstso}
                                                    onChange={handleagainstso}
                                                    name="taxrequired"
                                                    color="primary"
                                                  />
                                                }
                                                label="Against SaleOrder?"
                                              />
                                            </FormGroup>
                                          </fieldset> 
                                          <fieldset className="col-md-9"/>
                                          {againstso === true?
                                          <fieldset className="col-md-3">
                                            <Autocomplete
                                              id="controlled-demo"
                                              options={soList}
                                              getOptionLabel={(option) =>
                                                option
                                              }
                                              
                                              getOptionSelected={(option) =>
                                                option
                                              }
                                              value={soid}
                                              onChange={(event, newValue) => {
                                                newValue === "" ||
                                                newValue === null ||
                                                newValue === undefined
                                                  ? setSoId("")
                                                  : setSoId(newValue);
                                                  setErrorMsg("")
                                              }}
                                              error={
                                                soid === "" ||
                                                soid === undefined
                                              }
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label="Select Sale Order Id"
                                                  variant="standard"
                                                  error={
                                                    soid === "" ||
                                                    soid === undefined
                                                  }
                                                  helperText={
                                                    soid === "" ||
                                                    soid === undefined
                                                      ? "Empty field!"
                                                      : " "
                                                  }
                                                />
                                              )}
                                            />
                                          </fieldset>:''}
                                          <fieldset className="form-group col-md-3">
                                            <Autocomplete
                                              id="warehouse"
                                              className="subacc-select"
                                              name="warehouse"
                                              options={whList}
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              
                                              value={
                                                values.warehouse
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={handleWarehouseChange}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  className="form-control"
                                                  type="text"
                                                  name="warehouse"
                                                  value={warehouse}
                                                  label={`Select Org Unit (${warehouse})`}
                                                  variant="standard"
                                                  error={whid === ""}
                                                  helperText={
                                                    whid === ""
                                                      ? "Empty field!"
                                                      : " "
                                                  }
                                                  fullWidth
                                                />
                                              )}
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-3">
                                            <Autocomplete
                                              id="workcenter"
                                              className="subacc-select"
                                              name="workcenter"
                                              options={wcList}
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              
                                              value={
                                                values.workcenter
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={handleWorkCenterChange}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label={`Select WorkCenter/Production Unit (${workcenter})`}
                                                  type="text"
                                                  value={workcenter}
                                                  variant="standard"
                                                  error={workcenter === ""}
                                                  helperText={
                                                    workcenter === "" ||
                                                    workcenter === undefined
                                                      ? "Empty field!"
                                                      : " "
                                                  }
                                                  fullWidth
                                                />
                                              )}
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-3">
                                            <Autocomplete
                                              id="toname"
                                              className="subacc-select"
                                              name="toname"
                                              options={filtereduserList}
                                              getOptionLabel={(option) =>
                                                  option.first+" "+option.last+"("+option.id+")"
                                              }
                                              
                                              value={
                                                values.to
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={handleToChange}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label={`Select To (${toname})`}
                                                  type="text"
                                                  value={toname}
                                                  variant="standard"
                                                  error={to === ""}
                                                  helperText={
                                                    to === "" ? "Empty field!" : " "
                                                  }
                                                  fullWidth
                                                />
                                              )}
                                            />
                                          </fieldset>
                                          {/* <fieldset className="col-md-3">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Select To"
                                              type="text"
                                              value={to}
                                              onChange={handleToChange}
                                              variant="standard"
                                              error={to === ""}
                                              helperText={
                                                to === "" ? "Empty field!" : " "
                                              }
                                              fullWidth
                                            />
                                          </fieldset> */}
                                          <fieldset className="col-md-3">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <Grid container>
                                                <DatePicker
                                                  variant="inline"
                                                  id="date-picker-dialog"
                                                  label="Start Date "
                                                  format="dd/MM/yyyy"
                                                  minDate={new Date()}
                                                  value={startdate}
                                                  onChange={
                                                    handleStartDateChange
                                                  }
                                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                                  error={
                                                    startdate === ""
                                                      ? "Invalid Date"
                                                      : ""
                                                  }
                                                  autoOk={true}
                                                />
                                              </Grid>
                                            </LocalizationProvider>
                                          </fieldset>

                                          <fieldset className="col-md-3">
                                            <LocalizationProvider
                                              dateAdapter={AdapterDayjs}
                                            >
                                              <Grid container>
                                                <DatePicker
                                                  variant="inline"
                                                  id="date-picker-dialog"
                                                  label="End Date "
                                                  format="dd/MM/yyyy"
                                                  minDate={startdate}
                                                  value={enddate}
                                                  onChange={
                                                    handleEndDateChange
                                                  }
                                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                                  error={
                                                    enddate === ""
                                                      ? "Invalid Date"
                                                      : ""
                                                  }
                                                  autoOk={true}
                                                />
                                              </Grid>
                                            </LocalizationProvider>
                                          </fieldset>
                                            {activeStep === 5?<>
                                          <fieldset className="col-md-3">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter Approximate Amount"
                                              type="number"
                                              value={appxamount}
                                              onChange={handleAppxAmountChange}
                                              variant="standard"
                                              error={appxamount === ""}
                                              helperText={
                                                appxamount === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          </fieldset>

                                          <fieldset className="col-md-3">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter Advance Amount"
                                              type="number"
                                              value={advance}
                                              onChange={handleAdvanceChange}
                                              variant="standard"
                                              error={advance === ""}
                                              helperText={
                                                advance === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          </fieldset>
                                          </>:''}
                                          <fieldset className="form-group col-md-12 text-md"/>
                                          <fieldset className="form-group col-md-12 text-md">
                                             <Box sx={{ width: 250 }}>
                                               <Typography id="input-slider" gutterBottom>
                                               Duration : {duration ===""?0:duration} Days 
                                               </Typography>
                                               <Grid container spacing={2} alignItems="center">
                                                 {/* <Grid item>
                                               <VolumeUp />
                                             </Grid> */}
                                                 <Grid item xs>
                                                   <Slider
                                                     value={
                                                       typeof duration ===
                                                       "number"
                                                         ? duration
                                                         : 0
                                                     }
                                                     onChange={handleDurationChange}
                                                     aria-labelledby="input-slider"
                                                     min={0}
                                                     max={365}
                                                     style={{ color: "darkcyan" }}
                                                   />
                                                 </Grid>
                                               </Grid>
                                             </Box>
                                           </fieldset>
                                          <fieldset className="col-md-3">
                                            <FormGroup row className="mt-1">
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={qastatus}
                                                    onChange={
                                                      handleQAstatusChange
                                                    }
                                                    name="parameters"
                                                    color="primary"
                                                  />
                                                }
                                                label="QA Required?"
                                              />
                                            </FormGroup>
                                          </fieldset>
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      {/* ----------Estimation Details End--------- */}
                                      {/* ----------BOM Start--------- */}
                                      { (estimationtype === "Continuous" && activeStep === 2) ||   (estimationtype === "OneTime" && activeStep === 1)  ? (
                                        <div className="form-row">
                                          <fieldset className="form-group col-md-3">
                                            <Autocomplete
                                              id="subaccount"
                                              className="subacc-select"
                                              name="category"
                                              options={categoryList}
                                              getOptionLabel={(option) =>
                                                option.category
                                              }
                                              value={
                                                values.category
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={handleCategoryChange}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  className="form-control"
                                                  type="text"
                                                  name="category"
                                                  value={category}
                                                  label={`Select Category (${category})`}
                                                  variant="standard"
                                                  error={catid === ""}
                                                  helperText={
                                                    catid === ""
                                                      ? "Empty field!"
                                                      : " "
                                                  }
                                                  fullWidth
                                                />
                                              )}
                                            />
                                          </fieldset>
                                          <fieldset className="col-md-3">
                                          <Autocomplete
                                              id="subaccount"
                                              className="subacc-select"
                                              name="itemname"
                                              options={filterItemList}
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              
                                              value={
                                                values.itemname
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={handleSelectItem}
                                              renderInput={(params) => (
                                            <TextField
                                            {...params}
                                              id="standard-error-helper-text"
                                              label={`Select Item (${itemname})`}
                                              value={itemname}
                                              variant="standard"
                                              error={itemname === ""}
                                              helperText={
                                                itemname === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                            )}
                                            />
                                          </fieldset>
                                          <fieldset className="col-md-3">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Price"
                                              type="number"
                                              name="price"
                                              value={price}
                                              variant="standard"
                                              fullWidth
                                            />
                                          </fieldset>
                                          <fieldset className="form-group col-md-3">
                                            {/* <Autocomplete
                                              id="subaccount"
                                              className="subacc-select"
                                              name="uom"
                                              options={UOMList}
                                              getOptionLabel={(option) => option.name}
                                              
                                              
                                              value={values.uom?undefined:undefined}
                                              onChange={handleUOM}
                                              renderInput={params => (   */}
                                                <TextField
                                                    // {...params}
                                                  className="form-control"
                                                  type="text"
                                                  name="uom"
                                                  label={`UOM `}
                                                  variant="standard"
                                                  value={uom}
                                                  fullWidth
                                                  error={uom === ""}
                                                    helperText={
                                                      uom === ""
                                                        ? "Empty field!"
                                                        : " "
                                                    }
                                                />
                                              {/* )}
                                            /> */}
                                          </fieldset>   
                                          <fieldset className="col-md-3">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter Quantity"
                                              type="number"
                                              value={itemquantity}
                                              onChange={handleItemQuantity}
                                              variant="standard"
                                              error={itemquantity === ""}
                                              helperText={
                                                itemquantity === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          </fieldset>
                                          
                                          {estimationtype === "Continuous"?
                                             <fieldset className="form-group col-md-4 text-md">
                                             <Box sx={{ width: 250 }}>
                                               <Typography id="input-slider" gutterBottom>
                                                 Frequency : {frequency ===""?0:frequency} Days 
                                               </Typography>
                                               <Grid container spacing={2} alignItems="center">
                                                 {/* <Grid item>
                                               <VolumeUp />
                                             </Grid> */}
                                                 <Grid item xs>
                                                   <Slider
                                                     value={
                                                       typeof frequency ===
                                                       "number"
                                                         ? frequency
                                                         : 0
                                                     }
                                                     onChange={handlefrequencyChange}
                                                     aria-labelledby="input-slider"
                                                     min={0}
                                                     max={365}
                                                     style={{ color: "darkcyan" }}
                                                   />
                                                 </Grid>
                                               </Grid>
                                             </Box>
                                           </fieldset>
                                          :""}

                                          <fieldset className="col-md-12 d-flex align-items-center">
                                            <span
                                              as="button"
                                              className="btn btn-sm hovbuttonColour"
                                              onClick={handleAddBOMItem}
                                            >
                                              Add
                                            </span>
                                          </fieldset>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {bomitems.length !== 0 && (((estimationtype === "Continuous" && activeStep === 2) || (estimationtype === "OneTime" && activeStep === 1)) || activeStep === 5) ? (
                                        <TableContainer>
                                          <Typography> Raw Materials List</Typography>
                                          <Table className="table-sm">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell className="text-center">
                                                  Itemname
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Price
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Quantity
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  UOM
                                                </TableCell>
                                                {estimationtype === "Continuous"?
                                                  <TableCell className="text-center">
                                                  Frequency (in Days)
                                                  </TableCell>
                                                :""}
                                                <TableCell className="text-center">
                                                  Total
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Actions
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {bomitems.map((e, index) => (
                                                <TableRow key={index}>
                                                  <TableCell className="text-center">
                                                    {e.itemname}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                 <NumericFormat className="pr-3" displayType={'text'} value={e.price}  decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                                </TableCell>
                                                  <TableCell className="text-center">
                                                    {e.quantity}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {e.uom}
                                                  </TableCell>
                                                  {estimationtype === "Continuous"?
                                                  <TableCell className="text-center">
                                                    {e.frequency}
                                                  </TableCell>
                                                :""}
                                                <TableCell className="text-center">
                                                  <NumericFormat className="pr-3" displayType={'text'} value={e.totalprice}  decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                                 </TableCell>
                                                  <TableCell className="text-center">
                                                    <span
                                                      as="buttom"
                                                      className="btn btn-sm deletebutton"
                                                      onClick={() =>
                                                        handelRemoveItem(index)
                                                      }
                                                    >
                                                      Remove
                                                    </span>
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      ) : (
                                        ""
                                      )}
                                      {/* ----------BOM End--------- */}
                                      {/* ----------Equipment Start--------- */}
                                      {((estimationtype === "Continuous" && activeStep === 3) || (estimationtype === "OneTime" && activeStep === 2))  ? (
                                        <div className="form-row">
                                         <fieldset className="form-group col-md-3">
                                            <Autocomplete
                                              id="subaccount"
                                              className="subacc-select"
                                              name="category"
                                              options={categoryList}
                                              getOptionLabel={(option) =>
                                                option.category
                                              }
                                              
                                              value={
                                                values.category
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={handleCategoryChange}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  className="form-control"
                                                  type="text"
                                                  name="category"
                                                  value={category}
                                                  label={`Select Category (${category})`}
                                                  variant="standard"
                                                  error={catid === ""}
                                                  helperText={
                                                    catid === ""
                                                      ? "Empty field!"
                                                      : " "
                                                  }
                                                  fullWidth
                                                />
                                              )}
                                            />
                                          </fieldset>
                                          <fieldset className="col-md-3">
                                          <Autocomplete
                                              id="subaccount"
                                              className="subacc-select"
                                              name="itemname"
                                              options={filterItemList}
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              
                                              value={
                                                values.itemname
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={handleSelectEquipment}
                                              renderInput={(params) => (
                                            <TextField
                                            {...params}
                                            id="standard-error-helper-text"
                                            label={`Select Equipment ${equipment}`}
                                            value={equipment}
                                            variant="standard"
                                            error={equipment === ""}
                                            helperText={
                                              equipment === ""
                                                ? "Empty field!"
                                                : " "
                                            }
                                            fullWidth
                                            />
                                            )}
                                            />
                                          </fieldset> 
                                          <fieldset className="col-md-3">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter Amount (per Quantity)"
                                              type="number"
                                              value={amount}
                                              onChange={handleAmount}
                                              variant="standard"
                                              fullWidth
                                            />
                                          </fieldset>
                                          <fieldset className="col-md-3">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter Quantity"
                                              type="number"
                                              value={equipmentquantity}
                                              onChange={handleEquipmentQuantity}
                                              variant="standard"
                                              error={equipmentquantity === ""}
                                              helperText={
                                                equipmentquantity === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          </fieldset>
                                          <fieldset className="col-md-4 d-flex align-items-center">
                                            <span
                                              as="button"
                                              className="btn btn-sm hovbuttonColour"
                                              onClick={handleAddEquipments}
                                            >
                                              Add
                                            </span>
                                          </fieldset>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {equipmentList.length !== 0 && (((estimationtype === "Continuous" && activeStep === 3) || (estimationtype === "OneTime" && activeStep === 2)) || activeStep === 5) ? (
                                        <TableContainer>
                                          <Typography>Equipments</Typography>
                                          <Table className="table-sm">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell className="text-center">
                                                  Equipment
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Quantity
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Amount
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Total Amount
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Actions
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {equipmentList.map((e, index) => (
                                                <TableRow key={index}>
                                                  <TableCell className="text-center">
                                                    {e.name}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {e.quantity}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                  <NumericFormat className="pr-3" displayType={'text'} value={e.amount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  <NumericFormat className="pr-3" displayType={'text'} value={e.totalamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                                </TableCell>
                                                  
                                                  <TableCell className="text-center">
                                                    <span
                                                      as="buttom"
                                                      className="btn btn-sm deletebutton"
                                                      onClick={() =>
                                                        handelRemoveEquipment(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      Remove
                                                    </span>
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      ) : (
                                        ""
                                      )}

                                      {/* ----------Equipment End--------- */}
                                      {/* ----------Man Power Start--------- */}
                                      {((estimationtype === "Continuous" && activeStep === 4) || (estimationtype === "OneTime" && activeStep === 3)) ? (
                                        <div className="form-row">
                                          <fieldset className="col-md-4">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter Position/Type"
                                              value={mantype}
                                              onChange={handleSelectManType}
                                              variant="standard"
                                              error={mantype === ""}
                                              helperText={
                                                mantype === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          </fieldset>
                                          <fieldset className="col-md-4">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter No. of Employees/Workers/Count"
                                              type="number"
                                              value={mancount}
                                              onChange={handleManCount}
                                              variant="standard"
                                              error={mancount === ""}
                                              helperText={
                                                mancount === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          </fieldset>
                                          <fieldset className="col-md-3">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter Amount (per Head)"
                                              type="number"
                                              value={amount}
                                              onChange={handleAmount}
                                              variant="standard"
                                              fullWidth
                                            />
                                          </fieldset>
                                          <fieldset className="col-md-4 d-flex align-items-center">
                                            <span
                                              as="button"
                                              className="btn btn-sm hovbuttonColour"
                                              onClick={handleAddManPower}
                                            >
                                              Add
                                            </span>
                                          </fieldset>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {manpowerList.length !== 0 &&
                                      (((estimationtype === "Continuous" && activeStep === 4) || (estimationtype === "OneTime" && activeStep === 3)) || activeStep === 5) ? (
                                        <TableContainer>
                                          <Typography>Man Power</Typography>
                                          <Table className="table-sm">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell className="text-center">
                                                  Position/Type
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  No. of Employees/Workers/Count
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Amount
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Total Amount
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Actions
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {manpowerList.map((e, index) => (
                                                <TableRow key={index}>
                                                  <TableCell className="text-center">
                                                    {e.mantype}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {e.mancount}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                  <NumericFormat className="pr-3" displayType={'text'} value={e.amount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  <NumericFormat className="pr-3" displayType={'text'} value={e.totalamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                                </TableCell>
                                                  <TableCell className="text-center">
                                                    <span
                                                      as="buttom"
                                                      className="btn btn-sm deletebutton"
                                                      onClick={() =>
                                                        handleRemoveManPower(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      Remove
                                                    </span>
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      ) : (
                                        ""
                                      )}

                                      {/* ----------Man Power End--------- */}
                                      {/* ----------Final Products Start--------- */}
                                      {((estimationtype === "Continuous" && activeStep === 1) || (estimationtype === "OneTime" && activeStep === 4)) ? (
                                        <div className="form-row">
                                           <fieldset className="form-group col-md-3">
                                            <Autocomplete
                                              id="subaccount"
                                              className="subacc-select"
                                              name="category"
                                              options={categoryList}
                                              getOptionLabel={(option) =>
                                                option.category
                                              }
                                              
                                              value={
                                                values.category
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={handleCategoryChange}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  className="form-control"
                                                  type="text"
                                                  name="category"
                                                  value={category}
                                                  label={`Select Category (${category})`}
                                                  variant="standard"
                                                  error={catid === ""}
                                                  helperText={
                                                    catid === ""
                                                      ? "Empty field!"
                                                      : " "
                                                  }
                                                  fullWidth
                                                />
                                              )}
                                            />
                                          </fieldset>

                                          <fieldset className="col-md-3">
                                          <Autocomplete
                                              id="subaccount"
                                              className="subacc-select"
                                              name="productname"
                                              options={filterItemList}
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              
                                              value={
                                                values.productname
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={handleSelectProduct}
                                              renderInput={(params) => (
                                            <TextField
                                            {...params}
                                              id="standard-error-helper-text"
                                              label={`Select Final/End Product (${productname})`}
                                              value={productname}
                                              variant="standard"
                                              error={productname === ""}
                                              helperText={
                                                productname === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                            )}
                                            />
                                          </fieldset>
                                       
                                          <fieldset className="form-group col-md-3">
                                            {/* <Autocomplete
                                              id="subaccount"
                                              className="subacc-select"
                                              name="uom"
                                              options={UOMList}
                                              getOptionLabel={(option) => option.name}
                                              
                                              
                                              value={values.uom?undefined:undefined}
                                              onChange={handleUOM}
                                              renderInput={params => (   */}
                                                <TextField
                                                    // {...params}
                                                  className="form-control"
                                                  type="text"
                                                  name="uom"
                                                  label={`UOM`}
                                                  value={uom}
                                                  variant="standard"
                                                  fullWidth
                                                />
                                              {/* )}
                                            /> */}
                                          </fieldset> 
                                         
                                          <fieldset className="col-md-2">
                                            <TextField
                                              id="standard-error-helper-text"
                                              label="Enter Quantity"
                                              type="number"
                                              value={productquantity}
                                              onChange={
                                                handleFinalProductsQuantity
                                              }
                                              variant="standard"
                                              error={productquantity === ""}
                                              helperText={
                                                productquantity === ""
                                                  ? "Empty field!"
                                                  : " "
                                              }
                                              fullWidth
                                            />
                                          </fieldset>
                                           
                                          <fieldset className="col-md-1 d-flex align-items-center">
                                            <span
                                              as="button"
                                              className="btn btn-sm hovbuttonColour"
                                              onClick={handleAddFinalProducts}
                                            >
                                              Add
                                            </span>
                                          </fieldset>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {finalproductsList.length !== 0 &&
                                      (((estimationtype === "Continuous" && activeStep === 1) || (estimationtype === "OneTime" && activeStep === 4)) || activeStep === 5) ? (
                                        <TableContainer>
                                          <Typography>
                                            Final/End Products
                                          </Typography>
                                          <Table className="table-sm">
                                            <TableHead>
                                              <TableRow>
                                                <TableCell className="text-center">
                                                  Product
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Quantity
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  UOM
                                                </TableCell>
                                                <TableCell className="text-center">
                                                  Actions
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {finalproductsList.map(
                                                (e, index) => (
                                                  <TableRow key={index}>
                                                    <TableCell className="text-center">
                                                      {e.itemname}
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      {e.quantity}
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      {e.uom}
                                                    </TableCell>
                                                    <TableCell className="text-center">
                                                      <span
                                                        as="buttom"
                                                        className="btn btn-sm deletebutton"
                                                        onClick={() =>
                                                          handleRemoveFinalProduct(
                                                            index
                                                          )
                                                        }
                                                      >
                                                        Remove
                                                      </span>
                                                    </TableCell>
                                                  </TableRow>
                                                )
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      ) : (
                                        ""
                                      )}

                                      {/* ----------Final Products End--------- */}
                                      {/* ----------Submit Start--------- */}
                                      {activeStep === 5 ? (<>
                                      <div className="text-red">{errormessage}</div>
                                        <div className="form-row mt-3">
                                          <fieldset className="col-md-12 mt-2">
                                            {bomitems.length === 0 ||
                                            finalproductsList.length === 0 ||
                                            workcenter === "" ||
                                            duration === "" ? (
                                              <button
                                                className="btn btn-sm hovbuttonColour"
                                                disabled
                                              >
                                                Submit
                                              </button>
                                            ) : (
                                              <>
                                                {loader === false ? (
                                                  <button
                                                    as="button"
                                                    className="btn btn-sm hovbuttonColour"
                                                    onClick={handleonSubmit}
                                                  >
                                                    Submit
                                                  </button>
                                                ) : (
                                                  <button
                                                    className="btn btn-sm hovbuttonColour"
                                                    disabled
                                                  >
                                                    <Spinner
                                                      animation="border"
                                                      size="sm"
                                                      className="mr-1"
                                                    />
                                                    Submit
                                                  </button>
                                                )}
                                              </>
                                            )}
                                            <Link
                                              as="button"
                                              className="btn btn-sm ml-3 deletebutton"
                                              to={{ pathname: "/estimation" }}
                                            >
                                              <b>
                                                {" "}
                                                X
                                              </b>{" "}
                                              Cancel
                                            </Link>
                                          </fieldset>
                                        </div>
                                      </>) : (
                                        ""
                                      )}
                                      {/* ----------Submit End--------- */}
                                    </Form>
                                  )}
                                </Formik>

                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    pt: 2,
                                  }}
                                >
                                  <Button
                                    color="inherit"
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    sx={{ mr: 1 }}
                                    style={{ outline: "none" }}
                                  >
                                    ❮ Back
                                  </Button>
                                  <Box sx={{ flex: "1 1 auto" }} />
                                  <Button
                                    onClick={handleNext}
                                    sx={{ mr: 1 }}
                                    disabled={activeStep === 5}
                                    style={{ outline: "none" }}
                                  >
                                    Next ❯
                                  </Button>
                                </Box>
                              </React.Fragment>
                            )}
                          </div>
                        </Box>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(NewEstimation)
