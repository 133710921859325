import { Autocomplete, FormControl, Grid, InputLabel, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { currentTenant, userid } from "../Common/Credentials";
import { FormikTextField } from "../CommonComponents/FormField";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import Employeeservices from "../Masters/Employees/Employeeservices";
import withRouter from "../withRouter";
import EmployeeService from "./EmployeeService";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

const AddEmployeeAdvance = () => {
    const history = useLocation();
    const [id,setId] = useState(history.state === undefined || history.state === null?-1:history.state.id)
  const [advance,setAdvance] = useState(0)
  const [deduction,setDeduction] = useState(1)
  const [deductionamount,setDeductionamount] =useState(0)
  const [EmployeeList,setEmployeeList] = useState([]);
  const [empid,setEmpid] = useState('')
  const [empname,setEmpname] = useState('')
  const [startdate,setStartdate] = useState(new Date());


  //nevigate use to nevigate or forward to another page
  const navigate = useNavigate();

  // useEffect function for fetching the all salary Breakup data
  useEffect(() => {
      
      if(history.state.id !== undefined && history.state.id !== null && history.state.id !==-1){
        EmployeeService.getEmployeeAdvanceById(history.state.id).then(res=>{
            setAdvance(res.data.advance)
            setDeduction(res.data.deduction)
            setDeductionamount(res.data.deductionamount)
            setEmpid(res.data.empid)
            setEmpname(res.data.empname)
        })
      }else{

        Employeeservices.getAllEmployees(currentTenant).then(res=>setEmployeeList(res.data.filter(e=>!history.state.emplist.includes(e.customid))));
      }
  }, []);

  const dateHandler = (e)=>{
    setStartdate(moment(new Date(e)))
  }

  //Form onSubmit Handler function
  const onSubmit = () => {
    if(id===-1){
      let data= {
        empid:empid,
        empname:empname,
        deduction:deduction,
        advance:advance,
        deductionamount:deductionamount,
        tenantid:currentTenant,
        createdby:userid,
        empid:empid,
        startdate:startdate
      }
    EmployeeService.AddEmployeeAdvance(data).then(() => navigate(-1));
    }else{
        let data= {
            id:id,
            empid:empid,
            empname:empname,
            deduction:deduction,
            advance:advance,
            deductionamount:deductionamount,
            tenantid:currentTenant,
            createdby:userid,
            empid:empid,
            startdate:startdate
          }
        EmployeeService.UpdateEmployeeAdvance(data).then(() => navigate(-1));
    }
  };

  const handleEmployeeChange = (e,value) => {
    console.log(value)
    if (value === "" || value === null || value === undefined) {
      setEmpid('')
      setEmpname('')
      setAdvance(0)
      setDeduction(0)
      setDeductionamount(0)
    }
    else{
    setEmpid(value.id);
    setEmpname(value.first +" "+value.last)
    setAdvance(0)
    setDeduction(0)
    setDeductionamount(0)
    }
  };


  const validate = () => {}

  return (
    <>
      <Header />
      <Menu />

      <div className="content-wrapper">
        <div className="card">
          <ol
            className="breadcrumb float-sm-right"
          >
            <li className=" text-secondary">
            <Link as="button" className=" text-secondary"  to={{ pathname: "/employeesalary" }}>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/accounts">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/employeesalary">Employees Salary</Link>
            </li>
            <li className="breadcrumb-item active ">Add Employee Advance</li>
            </ol>

            <div className="card-body">
              {/* <form onSubmit={handleAdd}> */}
              <Formik
                initialValues={{empid,empname,advance}}
                onSubmit={onSubmit}
                validateOnChange={false}
                validate={validate}
                enableReinitialize={true}
              >
                {({setFieldValue,values}) => (
                  <Form autoComplete="off"  className="row">

                    <fieldset className="form-group col-md-3 pb-3">
                        {id===-1?
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="employee"
                          options={EmployeeList}
                          getOptionLabel={(option) =>
                            option.first+" "+option.last
                        }
                          value={values.empname?undefined:undefined}
                          onChange={handleEmployeeChange}
                          renderInput={params => (  
                            <FormikTextField
                                {...params}
                              className="form-control"
                              type="text"
                              value={empname}
                              name="employee"
                              label={`Select Employee`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                        :
                            <FormikTextField
                            className="form-control"
                              type="text"
                              value={empname}
                              name="employee"
                              label={`Employee`}
                              variant="standard"
                              fullWidth
                            />
                          }
                      </fieldset>

                        <fieldset className="col-md-3">
                          <TextField
                            label="Advance Amount"
                            required
                            type="number"
                            variant="standard"
                            name="advance"
                            value={advance}
                            onChange={(e) =>{
                                setAdvance(parseFloat(e.target.value===undefined || e.target.value<0 ? 0 : e.target.value))
                                setDeduction(0)
                                setDeductionamount(0)
                            }}
                            fullWidth
                          />
                        </fieldset>

                        <fieldset className="col-md-3">
                          <TextField
                            label="No of months"
                            type="number"
                            variant="standard"
                            name="deduction"
                            value={deduction}
                            onChange={(e) =>{
                                setDeduction(parseFloat(e.target.value===undefined || e.target.value<1 ? 1 : e.target.value))
                                setDeductionamount((advance/parseFloat((e.target.value===undefined || e.target.value<0  ? 0 : e.target.value))).toFixed(2))
                            }}
                            fullWidth
                          />
                        </fieldset>

                        <fieldset className="col-md-3">
                          <TextField
                            label="Amount per Month"
                            type="number"
                            variant="standard"
                            name="deductionamount"
                            value={deductionamount}
                            disabled
                            // onChange={(e) =>{
                            //     setDeductionamount(parseFloat(e.target.value===undefined || e.target.value<0 ? 0 : e.target.value>advance?advance:  e.target.value))
                            //     setDeduction(parseFloat((e.target.value===undefined || e.target.value<0  ? 0 : e.target.value> advance?advance: e.target.value))/advance)
                            // }}
                            fullWidth
                          />
                        </fieldset>
                        <fieldset className="col-md-3">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container>
                              <DatePicker
                                variant="inline"
                                id="date-picker-dialog"
                                label="Select Adjustment Start Month"
                                openTo="month"
                                views={["year","month"]}
                                minDate={new Date()}
                                value={startdate}
                                onChange={dateHandler}
                                renderInput={(params) => (
                                  <TextField {...params} variant="standard"  fullWidth/>
                                )}
                              />
                            </Grid>
                          </LocalizationProvider>
                          </fieldset>

                <div />

                {/*Submit button*/}
                <div className="col-md-12"/>
                <div style={{ marginTop: "30px" }} className="ps-4">
                  <center>
                    <button
                      type="submit"
                      value="Add"
                      className=" btn btn-sm hovbuttonColour mr-2"
                    >
                      <i className="fa fa-save mr-1"></i>Submit
                    </button>
                    <Link
                      as="button"
                      className="btn btn-sm ml-1 deletebutton col-md-auto col-sm-12"
                      to={{ pathname: "/employeesalary" }}
                    >
                      <b style={{ color: "#FA5733" }}> X</b> Cancel
                    </Link>
                  </center>
                </div>
              
              </Form>
                )}
              </Formik>
            </div>
          
        </div>
      </div>
      <Footer />
    </>
  );
};
export default withRouter(AddEmployeeAdvance);
