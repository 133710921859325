import axios from "axios";
import { Auth_Proto, Auth_Domain, Auth_Port } from "../../Component/consts";

class TransportationServices {

  getTransportAssets(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/gettransportassets/${currentTenant}`,);
  }

  addNewPlace(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/addplace`,data);
  }

  updatePlace(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/editplace`,data);
  }

  getAllPlaces(currentTenant) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getallplaces/${currentTenant}`);
  }

  getPlaceById(id) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getplacebyid/${id}`);
  }

  DeletePlace(id) {
    return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/deleteplace/${id}`);
  }

  addNewTrip(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/newtrip`,data);
  }

  getAllTrips(currentTenant) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getalltrips/${currentTenant}`);
  }

  getTripById(id) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/gettripbyid/${id}`);
  }

  updatetrip(data){
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/edittrip`,data);
  }

  Confirmtrip(id){
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/confirmtrip/${id}`);
  }
  
  DeleteTrip(id){
    return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/deletetrip/${id}`);
  }

}
export default new TransportationServices();
