import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";

import {
  address,
  address2,
  ccity,
  ccountry,
  clientname,
  companyemail,
  contactnumber,
  cstate,
  czip,
  logo,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import ProductionServices from "../ProductionServices";
// import { ToWords } from "to-words";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {NumericFormat} from "react-number-format";
import Footer from "../../Component/Footer";
import Swal from "sweetalert2";
import { productionid } from "../../Common/Permissions";

const EstimationDetails = (props) => {
  const history = useLocation();
  const navigate = useNavigate();
  const [id] = useState(history.state);
  const [data, setData] = useState("");
  const [status, setStatus] = useState();

  useEffect(() => {
    ProductionServices.getEstimationsbyCustomId(id).then((response) => {
      setData(response.data);
      setStatus(response.data.status);
    });
  }, [id]);

  const handleApprove = () => {
    let sdata = {
      id: data.id,
      updatedby: userid,
      status: 1,
    };
    // if (window.confirm("Are you sure you want to Approve?")) {
    //   ProductionServices.UpdateEstimationStatus(data).then(() =>
    //     setStatus("Approved")
    //   );
    // }
    Swal.fire({
      title: "Approve Estimation?",
      text: "Are you sure you want to Approve?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor:'#152f5f',
      cancelButtonText: "No",
      reverseButtons: true
  }).then((result)=>{
      if (result.value) {
        ProductionServices.UpdateEstimationStatus(sdata).then
        ((response) =>{
          setStatus("Approved")
          Swal.fire({
            title: "Estimation Approved Successfully",
            text: ".",
            icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor:'#152f5f',
          })
        })
      }
  });
  };

  const handleCancel = () => {
    let sdata = {
      id: data.id,
      updatedby: userid,
      status: 2,
    };
    // if (window.confirm("Are you sure you want to Cancel?")) {
    //   ProductionServices.UpdateEstimationStatus(data).then(() =>
    //     navigate("/estimation")
    //   );
    // }
    Swal.fire({
      title: "Cancel Estimation?",
      text: "Are you sure you want to Cancel?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor:'#152f5f',
      cancelButtonText: "No",
      reverseButtons: true
  }).then((result)=>{
      if (result.value) {
        ProductionServices.UpdateEstimationStatus(sdata).then
        ((response) =>{
          Swal.fire({
            title: "Estimation Cancelled Successfully",
            text: "",
            icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor:'#152f5f',
          }).then(() =>
          navigate("/estimation"))
        })
      }
  });
  };

  const handleDelete = () => {
    // if (window.confirm("Are you sure you want Delete?")) {
    //   ProductionServices.DeleteEstimation(id).then(() =>
    //     navigate("/estimation")
    //   );
    // }
    Swal.fire({
      title: "Delete Estimation?",
      text: "Are you sure you want Delete?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor:'#152f5f',
      cancelButtonText: "No",
      reverseButtons: true
  }).then((result)=>{
      if (result.value) {
        ProductionServices.DeleteEstimation(data.id).then
        ((response) =>{
          Swal.fire({
            title: "Estimation Deleted Successfully",
            text: "",
            icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor:'#152f5f',
          }).then(() =>
          navigate("/estimation"))
        })
      }
  });
  };

  const handlepdfDownload = () => {
    let date = moment(data.createdAt).format("DD-MM-yyyy");
    let startdate = moment(data.startdate).format("DD-MM-yyyy");
    let bomitems = [
      { header: "Item", dataKey: "itemname" },
      { header: "Quantity", dataKey: "quantity" },
      { header: "UOM", dataKey: "uom" },
    ];
    let equipments = [
      { header: "Equipments", dataKey: "name" },
      { header: "Quantity", dataKey: "quantity" },
    ];
    let manpower = [
      { header: "Man Type", dataKey: "mantype" },
      { header: "Man Count", dataKey: "mancount" },
    ];
    let finalproducts = [
      { header: "Item", dataKey: "itemname" },
      { header: "Quantity", dataKey: "quantity" },
      { header: "UOM", dataKey: "uom" },
    ];
    let qastatus = data.qastatus === true ? "YES" : "NO";

    // let declaration = "Declaration: We declare that the invoice shows the acctual price of the goods described and the particulers are true and correct."

    // const toWords = new ToWords();
    // var totalinwords = toWords.convert(data.sototalwtax)
    const doc = new jsPDF("p", "pt", "a4");
    doc.addImage(logo, "GIF", 40, 20, 100, 100);
    doc.setFontSize(16);
    doc.text(`${clientname}`, 560, 40, "right");
    doc.setFontSize(11);
    doc.text(`${address}, ${address2}`, 560, 55, "right");
    doc.text(`${ccity}, ${cstate} - ${czip}`, 560, 70, "right");
    doc.text(`${ccountry}`, 560, 85, "right");
    doc.text(`${companyemail}`, 560, 100, "right");
    doc.text(`${contactnumber}`, 560, 115, "right");
    doc.setFontSize(16);
    doc.text("Estimation/Planning", 200, 140);
    doc.line(0, 150, 600, 150);
    doc.setFontSize(11);
    doc.text("To,", 40, 180);
    doc.text(`${data.to}`, 50, 195);
    doc.text(`Estimation ID : ${data.customid}`, 40, 225);
    doc.text(`Date : ${date}`, 40, 240);
    doc.text(`Soid : ${data.soid}`, 40, 255);
    doc.text(`Workcenter : ${data.workcenter}`, 40, 270);
    doc.text(`Type : ${data.estimationtype}`, 40, 285);
    doc.text(`Start date : ${startdate}`, 40, 300);
    doc.text(`Duration : ${data.duration}`, 40, 315);
    doc.text(`QA Required? : ${qastatus}`, 40, 330);
   

    // let context=doc.splitTextToSize(this.state.templete.content,510);
    // doc.text(context,40,300)
    doc.text(`Bom Items`, 40, 350);
    autoTable(doc, {
      theme: "grid",
      startY: 360,
      body: data.bomitems,
      columns: bomitems,
    });

    doc.text(`Equipments`, 40, doc.autoTable.previous.finalY + 30);
    autoTable(doc, {
      theme: "grid",
      startY: doc.autoTable.previous.finalY + 40,
      body: data.equipments,
      columns: equipments,
    });

    doc.text(`Man Power`, 40, doc.autoTable.previous.finalY + 30);
    autoTable(doc, {
      theme: "grid",
      startY: doc.autoTable.previous.finalY + 40,
      body: data.manpower,
      columns: manpower,
    });
    doc.text(`Final Products`, 40, doc.autoTable.previous.finalY + 30);
    autoTable(doc, {
      theme: "grid",
      startY: doc.autoTable.previous.finalY + 40,
      body: data.finalproduct,
      columns: finalproducts,
    });

    doc.text("Sd/-.", 40, doc.autoTable.previous.finalY + 50);
    doc.text("Sales Team,", 40, doc.autoTable.previous.finalY + 65);
    doc.text(`${clientname}`, 40, doc.autoTable.previous.finalY + 80);
    doc.save(`Estimation-${data.customid}.pdf`);
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <span
                as="button"
                className=" text-secondary cursorPointer"
                onClick={() => navigate(-1)}
              >
                ❮ Back &nbsp;&nbsp;
              </span>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/production">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/estimation">Estimation/Planning</Link>
            </li>
            <li className="breadcrumb-item active">
              Estimation/Planning Details
            </li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              {data === "" ? (
                <div className="text-center">{spin}</div>
              ) : (
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title"> Estimation/Planning Details</h3>
                    {status === "Cancelled" || status === "Completed" ? (
                      ""
                    ) : (
                      <>
                        <button
                          className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-envelope text-md ml-1"
                          data-tip
                          data-for="mail"
                          onClick={() => this.handleShow()}
                        ></button>
                        <button
                          className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md"
                          data-tip
                          data-for="pdf"
                          onClick={handlepdfDownload}
                        ></button>
                        {/* //<Tooltip id="mail" place="top" effect="solid">
                          Send Mail
                        </Tooltip>
                        //<Tooltip id="pdf" place="top" effect="solid">
                          Download pdf
                        </Tooltip> */}
                      </>
                    )}
                  </div>
                  <div className="card-body">
                    <table className="float-left m-0 mb-3 ">
                      <tbody>
                        <tr>
                          <td>Estimation Id</td>
                          <td>:&nbsp; {data.customid}</td>
                        </tr>
                        {data.soid==="NA"?'':
                        <tr>
                          <td>Sale Order Id</td>
                          <td>
                            <Link
                              to={{pathname: "/saleorderdetails"}}
                                state= {{ id: data.soid, bvstatus: 1 }}
                            >
                              :&nbsp; {data.soid}
                            </Link>
                          </td>
                        </tr>}
                        <tr>
                          <td>Date</td>
                          <td>
                            :&nbsp;{" "}
                            {moment(data.createdAt).format("DD-MM-YYYY hh:mm")}
                          </td>
                        </tr>
                        <tr>
                          <td>Estimation Type</td>
                          <td>:&nbsp; {data.estimationtype}</td>
                        </tr>
                        <tr>
                          <td>Workcenter </td>
                          <td>:&nbsp; {data.workcenter}</td>
                        </tr>
                        <tr>
                          <td>To </td>
                          <td>:&nbsp; {data.toname}</td>
                        </tr>
                        <tr>
                          <td>Start Date</td>
                          <td>
                            :&nbsp;{" "}
                            {moment(data.startdate).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td>End Date</td>
                          <td>
                            :&nbsp;{" "}
                            {moment(data.enddate).format("DD-MM-YYYY")}
                          </td>
                        </tr>
                        <tr>
                          <td>Duration</td>
                          <td>:&nbsp; {data.duration} days</td>
                        </tr>
                        <tr>
                          <td>Approximate Amount</td>
                          <td>:&nbsp; <NumericFormat className="pr-3" displayType={'text'} value={data.appxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                        </tr>
                        <tr>
                          <td>Advance Amount</td>
                          <td>:&nbsp; <NumericFormat className="pr-3" displayType={'text'} value={data.advance} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                        </tr>
                        <tr>
                          <td>Created by</td>
                          <td>:&nbsp; {data.createdby}</td>
                        </tr>
                        <tr>
                          <td>QA Required?</td>
                          <td>
                            :&nbsp; {data.qastatus === true ? "Yes" : "No"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {data.bomitems.length === 0 ? '':
                    <TableContainer>
                      <Typography> Raw Materials List</Typography>
                      <Table className="table-sm">
                        <TableHead>
                          <TableRow>
                            <TableCell className="text-center">
                              Itemname
                            </TableCell>
                            <TableCell className="text-center">
                              Price
                            </TableCell>
                            <TableCell className="text-center">
                              Quantity
                            </TableCell>
                            <TableCell className="text-center">
                              UOM
                            </TableCell>
                            {data.estimationtype === "Continuous"?
                              <TableCell className="text-center">
                              Frequency
                              </TableCell>
                           :""}
                            <TableCell className="text-center">
                              Total
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.bomitems.map((e, index) => (
                            <TableRow key={index}>
                              <TableCell className="text-center">
                                {e.itemname}
                              </TableCell>
                              <TableCell className="text-right">
                              <NumericFormat className="pr-3" displayType={'text'} value={e.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                            </TableCell>
                              <TableCell className="text-center">
                                {e.quantity}
                              </TableCell>
                              <TableCell className="text-center">
                                {e.uom}
                              </TableCell>
                              {data.estimationtype === "Continuous"?
                              <TableCell className="text-center">
                                {e.frequency}
                              </TableCell>
                           :""}
                            <TableCell className="text-right">
                            <NumericFormat className="pr-3" displayType={'text'} value={e.totalprice} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                            </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    }
                    {data.equipments.length === 0 ? '':
                    <TableContainer>
                      <Typography>Equipments</Typography>
                      <Table className="table-sm">
                        <TableHead>
                          <TableRow>
                            <TableCell className="text-center">
                              Equipment
                            </TableCell>
                            <TableCell className="text-center">
                              Amount
                            </TableCell>
                            <TableCell className="text-center">
                              Quantity
                            </TableCell>
                            <TableCell className="text-center">
                              Total
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.equipments.map((e, index) => (
                            <TableRow key={index}>
                              <TableCell className="text-center">
                                {e.name}
                              </TableCell>
                              <TableCell className="text-right">
                              <NumericFormat className="pr-3" displayType={'text'} value={e.amount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                              </TableCell>
                              <TableCell className="text-center">
                              {e.quantity}
                            </TableCell>
                            <TableCell className="text-right">
                            <NumericFormat className="pr-3" displayType={'text'} value={e.totalamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                            </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                      }
                    {data.manpower.length === 0 ? '':
                    <TableContainer>
                      <Typography>Man Power</Typography>
                      <Table className="table-sm">
                        <TableHead>
                          <TableRow>
                            <TableCell className="text-center">
                              Position/Type
                            </TableCell>
                            <TableCell className="text-center">
                              Amount
                            </TableCell>
                            <TableCell className="text-center">
                              No. of Employees/Workers/Count
                            </TableCell>
                            <TableCell className="text-center">
                              Total
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.manpower.map((e, index) => (
                            <TableRow key={index}>
                              <TableCell className="text-center">
                                {e.mantype}
                              </TableCell>
                              <TableCell className="text-right">
                              <NumericFormat className="pr-3" displayType={'text'} value={e.amount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                              </TableCell>
                              <TableCell className="text-center">
                                {e.mancount}
                              </TableCell>
                              <TableCell className="text-right">
                              <NumericFormat className="pr-3" displayType={'text'} value={e.totalamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                      }
                    {data.finalproduct.length === 0?'':
                    <TableContainer>
                      <Typography>Final/End Products</Typography>
                      <Table className="table-sm">
                        <TableHead>
                          <TableRow>
                            <TableCell className="text-center">
                              Product
                            </TableCell>
                           
                            <TableCell className="text-center">
                              Quantity
                            </TableCell>
                            <TableCell className="text-center">
                              UOM
                            </TableCell>
                           
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data.finalproduct.map((e, index) => (
                            <TableRow key={index}>
                              <TableCell className="text-center">
                                {e.itemname}
                              </TableCell>
                             
                              <TableCell className="text-center">
                                {e.quantity}
                              </TableCell>
                              <TableCell className="text-center">
                                {e.uom}
                            </TableCell>
                           
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  }
                  {productionid === '2' || productionid === '3'?<>
                    {status === "Cancelled" || status === "Completed" ? (
                      ""
                    ) : (
                      <>
                        {status === "Pending" ? (
                          <>
                            <fieldset className="col-md-12 mt-3">
                            <Link
                                className="btn btn-sm hovbuttonColour mr-1"
                                to={{pathname: "/newestimation"}}
                                  state= {{
                                    id: id,
                                    pagename: "New Estimation",
                                    salestype: false,
                                  }}
                              >
                                <i className="fa fa-edit mr-1" />
                                Edit
                              </Link>
                              {data.toid===userid && productionid === '3'?
                              <button
                                className="btn btn-sm hovbuttonColour mr-1"
                                onClick={handleApprove}
                              >
                                <i className="fa fa-check mr-1" />
                                Approve
                              </button>
                              :''}
                              <button
                                className="btn btn-sm deletebutton"
                                onClick={handleDelete}
                              >
                                <i className="fa fa-trash m-0" /> Delete
                              </button>
                            </fieldset>
                          </>
                        ) : (<>{data.estimationtype === "Continuous"?
                        <button
                        className="btn btn-sm deletebutton mt-3"
                        onClick={handleCancel}
                      >
                       <b>X</b> Terminate
                      </button> :""}
                          <button
                            className="btn btn-sm deletebutton mt-3"
                            onClick={handleCancel}
                          >
                           <b>X</b> Cancel
                          </button>
                       </> )}
                      </>
                    )}</>:''}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default EstimationDetails;
