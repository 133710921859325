import React from "react";
import { Col, Row } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import { currency, thousandsdisplay } from "../../../Common/Credentials";
import CountUp from "react-countup";
import { Skeleton } from "@mui/material";

const PurchaseCards = ({ data, poloader, theme }) => {
  return (
    <Row>
      <Col sm={6} lg={3}>
        <Link to={{ pathname: "/purchaseorder" }}>
          <div
            className={`stats-card purple-card mb-4 ${
              theme === false ? "" : "bg-black5"
            }`}
          >
            <>
              {poloader ? (
               <Skeleton variant="rounded" width={150} height={20} />
              ) : (
                <h3 className="text-purple">
                  <span style={{ fontSize: "13px" }}>{currency}&nbsp;</span>
                  <NumericFormat
                    className="pr-3"
                    displayType={"text"}
                    value={data.totalfyPOtransaction}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    disabled={true}
                    thousandSeparator={true}
                    thousandsGroupStyle={thousandsdisplay}
                  ></NumericFormat>
                </h3>
              )}
              <p className={`${theme === false ? "text-dark" : "text-white"}`}>
                Total Purchase Amount
              </p>
              {/* <i className="fas fa-money-bill-wave"></i> */}
            </>
          </div>
        </Link>
      </Col>
      <Col sm={6} lg={3}>
        <Link to={{ pathname: "/purchaseorder" }}>
          <div
            className={`stats-card light-blue-card mb-4 ${
              theme === false ? "" : "bg-black5"
            }`}
          >
            <>
              {poloader ? (
                <Skeleton variant="rounded" width={150} height={20} />
              ) : (
                <h3>
                  <CountUp
                    start={0}
                    end={
                      data.totalpoinfyyear === undefined
                        ? 0
                        : data.totalpoinfyyear
                    }
                    duration={1.5}
                    className="text-blue"
                  />
                </h3>
              )}
              <p className={`${theme === false ? "text-dark" : "text-white"}`}>
                Total Purchases
              </p>
              <i className="fas fa-shopping-bag" />
            </>
          </div>
        </Link>
      </Col>
      <Col sm={6} lg={3}>
        <Link to={{ pathname: "/purchaseorder" }}>
          <div
            className={`stats-card success-card mb-4 ${
              theme === false ? "" : "bg-black5"
            }`}
          >
            <>
              {poloader ? (
                <Skeleton variant="rounded" width={150} height={20} />
              ) : (
                <h3>
                  <CountUp
                    start={0}
                    end={data.confirm === undefined ? 0 : data.confirm}
                    duration={1.5}
                    className="text-success"
                  />
                </h3>
              )}
              <p className={`${theme === false ? "text-dark" : "text-white"}`}>
                Completed Purchase Orders
              </p>
              <i className="fas fa-check-circle" />
            </>
          </div>
        </Link>
      </Col>
      <Col sm={6} lg={3}>
        <Link to={{ pathname: "/purchaseorder" }}>
          <div
            className={`stats-card danger-card mb-4 ${
              theme === false ? "" : "bg-black5"
            }`}
          >
            <>
              {poloader ? (
               <Skeleton variant="rounded" width={150} height={20} />
              ) : (
                <h3>
                  <CountUp
                    start={0}
                    end={data.pending === undefined ? 0 : data.pending}
                    duration={1.5}
                    className="text-danger"
                  />
                </h3>
              )}
              <p className={`${theme === false ? "text-dark" : "text-white"}`}>
                Active Purchase Orders
              </p>
              <i className="fas fa-sync" />
            </>
          </div>
        </Link>
      </Col>
    </Row>
  );
};

export default PurchaseCards;
