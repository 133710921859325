import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import withRouter from "../withRouter.js";
import Swal from "sweetalert2";
import { currentTenant, financialyearend, financialyearstart, userid } from "../Common/Credentials";
import SettingsServices from "./SettingsServices";
import { FormikTextField } from "../CommonComponents/FormField.js";
import SalesServices from "../Sales/SalesServices.js";
import moment from "moment";

class Einvoicesettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id:'',
      einvoice: false,
      gstin: "",
      username: "",
      password: "",
      clientid: "",
      secretkey: "",
      error: false,
      editable:false,
      prefixeditable:false,
      prefix:"",
      serialno:'',
      prefixid:"",
      einvoiceprefix:'',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleChange=()=>{
      Swal.fire({
        title: `${!this.state.einvoice?"Enable":"Disable"} Einvoice`,
        text: `Are you sure you want to ${!this.state.einvoice?"enable":"disable"} e-Invoice`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          if(this.state.id===''|| this.state.id===undefined || this.state.id===null){
            this.setState({ einvoice: !this.state.einvoice })
          }else{
            SettingsServices.updateeinvoicestatus(this.state.id,!this.state.einvoice)
            .then((response) => {
              this.setState({ einvoice: !this.state.einvoice })
              Swal.fire({
                title: `e-Invoice credentials ${this.state.einvoice?"disable":"enable"} successfully`,
                text: ".",
                icon:"success",
                confirmButtonText: "Ok",
                confirmButtonColor: "#152f5f",
              })
            })
            .catch((e) => {
              if (e.response.data.error === "Unauthorized") {
                alert("Your Session is Lost: Please login Back to resume");
                this.props.navigate(`/`);
              }
            });
          }
        }
      })
  }

  onSubmit() {
    console.log(this.state.id)

    if(this.state.editable){

    let einvdata ={
        id:this.state.id,
        username: this.state.username,
        password: this.state.password,
        gstin: this.state.gstin,
        secretkey: this.state.secretkey,
        clientid: this.state.clientid,
        tenantid: currentTenant,
    }
    if(this.state.gstin === "" || this.state.username === "" || this.state.password === "" || this.state.secretkey === "" || this.state.clientid === "" || this.state.gstin === undefined || this.state.username === undefined || this.state.password === undefined || this.state.secretkey === undefined || this.state.clientid === undefined || this.state.gstin === null || this.state.username === null || this.state.password === null || this.state.secretkey === null || this.state.clientid === null){
      this.setState({error:true})
    }else{
      let data = {
        tenantId:currentTenant,
        reqparam:this.state.gstin,
      }
      
      // SalesServices.validateGSTIN(data).then((response)=>{
      // if(response.data.body === null || response.data.body.statusCodeValue === 400){
      //   Swal.fire({
      //     title: `Invalid GSTIN`,
      //     text: ".",
      //     icon: "error",
      //     confirmButtonText: "Ok",
      //     confirmButtonColor: "#152f5f",
      //   })
      // }else{
        Swal.fire({
          title: "Save",
          text: "Are you sure you want to save",
          icon: "question",
          showCancelButton: true,
          confirmButtonText: "Yes",
          confirmButtonColor: "#152f5f",
          cancelButtonText: "No",
          reverseButtons: true,
        }).then((result) => {
          if (result.value) {
              if(this.state.id===''|| this.state.id===undefined || this.state.id===null){
                SettingsServices.addeInvoicecredentials(einvdata)
                .then(() => {
                  this.setState({editable:false})
                  Swal.fire({
                    title: "Credentials Saved successfully",
                    text: ".",
                    icon:"success",
                    confirmButtonText: "Ok",
                    confirmButtonColor: "#152f5f",
                  })
                })
                .catch((e) => {
                  if (e.response.data.error === "Unauthorized") {
                    alert("Your Session is Lost: Please login Back to resume");
                    this.props.navigate(`/`);
                  }
                });
              }else{
                  SettingsServices.updateeInvoicecredentials(einvdata)
              .then(() => {
                this.setState({editable:false})
                Swal.fire({
                  title: "Credentials Updated successfully",
                  text: ".",
                  icon:"success",
                  confirmButtonText: "Ok",
                  confirmButtonColor: "#152f5f",
                })
              })
              .catch((e) => {
                if (e.response.data.error === "Unauthorized") {
                  alert("Your Session is Lost: Please login Back to resume");
                  this.props.navigate(`/`);
                }
              });
            }
          }
        });
      // }
      // }
      // )
    }
  }else{
    this.setState({editable:true});
  }
  }


  prefixSubmit = () =>{
    if(this.state.prefix !== undefined && this.state.prefix !== "" && this.state.prefix.length>0 && this.state.prefix.length<4){
    let data = {
      prefix:this.state.prefix,
      serialno:this.state.serialno,
      id:this.state.prefixid,
      tenantid:currentTenant
    } 
    SettingsServices.UpdateEinvoicePrefix(data).then(()=>
    SettingsServices.GetEinvoicePrefix(currentTenant).then((res)=>this.setState({
      prefix: res.data.prefix,
      serialno:res.data.serialno,
      prefixid:res.data.id,
      prefixeditable:false
     }))
    )
  }
  }
  distance(latitude1 = 15.13680000, longitude1 = 76.92750000, latitude2=12.96040000, longitude2=77.55730000, unit ="K") {
    // var radlat1 = Math.PI * lat1/180
    // var radlat2 = Math.PI * lat2/180
    // var theta = lon1-lon2
    // var radtheta = Math.PI * theta/180
    // var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    // dist = Math.acos(dist)
    // dist = dist * 180/Math.PI
    // dist = dist * 60 * 1.1515
    // console.log(dist)
    // if (unit=="K") { dist = dist * 1.609344 }
    // if (unit=="M") { dist = dist * 0.8684 }
    // console.log(dist)

    // let theta = longitude1 - longitude2;
    // let distance = 60 * 1.1515 * (180/Math.PI) * Math.acos(
    //     Math.sin(latitude1 * (Math.PI/180)) * Math.sin(latitude2 * (Math.PI/180)) + 
    //     Math.cos(latitude1 * (Math.PI/180)) * Math.cos(latitude2 * (Math.PI/180)) * Math.cos(theta * (Math.PI/180))
    // );
    // if (unit == 'miles') {
    //     console.log(Math.round(distance, 2));
    // } else if (unit == 'K') {
    //   console.log(Math.round(distance * 1.609344, 2));
    // }
}



  Refresh(){
    // this.distance()
    SettingsServices.geteinvoicecredentials(currentTenant).then(response=>{
        this.setState({
            id:response.data.id,
            einvoice: response.data.status==="ACTIVE"?true:false,
            gstin: response.data.gstin,
            username:response.data.username,
            password: response.data.password,
            clientid: response.data.clientid,
            secretkey: response.data.secretkey,
        })
      })

      SettingsServices.GetEinvoicePrefix(currentTenant).then((res)=>this.setState({
       prefix: res.data.prefix,
       serialno:res.data.serialno,
       prefixid:res.data.id,
       einvoiceprefix:"/"+moment(financialyearstart).format("YYYY")+(moment(financialyearstart).format("YYYY") === moment(financialyearend).format("YYYY")?"": moment(financialyearend).format("YY"))+"/1"
      }))
  }

  componentDidMount(){
    if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
    this.Refresh()
    
  }

  validate() {
    let errors = {};
    if (this.state.gstin === "") {
      errors.gstin = "GSTIN is Required";
      this.setState({ error: true });
    }
    if (this.state.username === "") {
      errors.username = "UserName is Required";
      this.setState({ error: true });
    }

    if (this.state.password === "") {
      errors.password = "Password is Required";
      this.setState({ error: true });
    }
    if (this.state.clientid === "") {
      errors.clientid = "ClientId is Required";
      this.setState({ error: true });
    }
    if (this.state.secretkey === "") {
      errors.secretkey = "SecretKey is Required";
      this.setState({ error: true });
    }
    return errors;
  }
  render() {
    let { gstin, username, password, clientid, secretkey,editable,prefixeditable } = this.state;
    return (
      <div>
        <div className="card-header text-center text-md text-bold textColour">
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={this.state.einvoice}
                  onChange={this.handleChange}
                  name="ordertype"
                  color="primary"
                />
              }
              label="e-Invoice Required?"
            />
          </FormGroup>
        </div>
        {this.state.einvoice === true?
        <div className="card-body">
          <Formik
            initialValues={{ gstin, username, password, clientid, secretkey,editable,prefixeditable }}
            onSubmit={this.onSubmit}
            validateOnChange={false}
            validate={this.validate}
            enableReinitialize={true}
          >
            {({ setFieldValue, values }) => (
              <Form autoComplete="off">
                <div className="row">
                  <fieldset className="form-group col-md-6">
                    <TextField
                      className="form-control"
                      id="gstin"
                      name="gstin"
                      type="text"
                      label="GSTIN"
                      placeholder="Enter GSTIN"
                      value={this.state.gstin}
                      variant="standard"
                      fullwhidth="true"
                      onChange={(e) =>
                        this.setState({
                          gstin: e.target.value,
                          error: false,
                        })
                      }
                      error={this.state.error && (this.state.gstin===''||this.state.gstin===null || this.state.gstin=== undefined)}
                      helperText={this.state.error && "GSTIN is Required"}
                      disabled={editable === false}
                    />
                  </fieldset>

                  <fieldset className="form-group col-md-6">
                    <TextField
                      className="form-control"
                      id="username"
                      name="username"
                      type="text"
                      label="UserName"
                      placeholder="Enter your username"
                      value={this.state.username}
                      variant="standard"
                      fullwhidth="true"
                      onChange={(e) =>
                        this.setState({
                          username: e.target.value,
                          error: false,
                        })
                      }
                      error={this.state.error && (this.state.username===''||this.state.username===null || this.state.username=== undefined) }
                      helperText={this.state.error && "UserName is Required"}
                      disabled={editable === false}
                    />
                  </fieldset>

                  <fieldset className="form-group col-md-6">
                    <TextField
                      className="form-control"
                      id="password"
                      name="password"
                      type="password"
                      label="Password"
                      placeholder="Enter Password"
                      value={this.state.password}
                      variant="standard"
                      fullwhidth="true"
                      onChange={(e) =>
                        this.setState({
                          password: e.target.value,
                          error: false,
                        })
                      }
                      error={this.state.error && (this.state.password===''||this.state.password===null || this.state.password=== undefined)}
                      helperText={this.state.error && "Password is Required"}
                      disabled={editable === false}
                    />
                  </fieldset>

                  <fieldset className="form-group col-md-6">
                    <TextField
                      className="form-control"
                      id="clientid"
                      name="clientid"
                      type="text"
                      label="Client ID"
                      placeholder="Enter Client ID"
                      value={this.state.clientid}
                      variant="standard"
                      fullwhidth="true"
                      onChange={(e) =>
                        this.setState({
                          clientid: e.target.value,
                          error: false,
                        })
                      }
                      error={this.state.error &&  (this.state.clientid==='' ||this.state.clientid===null || this.state.clientid=== undefined)}
                      helperText={this.state.error && "Client ID is Required"}
                      disabled={editable === false}
                    />
                  </fieldset>

                  <fieldset className="form-group col-md-6">
                    <TextField
                      className="form-control"
                      id="secretkey"
                      name="secretkey"
                      type="password"
                      label={`Secret Key`}
                      placeholder="Enter the secret key"
                      value={this.state.secretkey}
                      variant="standard"
                      fullwhidth="true"
                      onChange={(e) =>
                        this.setState({
                          secretkey: e.target.value,
                          error: false,
                        })
                      }
                      error={this.state.error && (this.state.secretkey===''||this.state.secretkey===null || this.state.secretkey=== undefined)}
                      helperText={this.state.error && "SecretKey is Required"}
                      disabled={editable === false}
                    />
                  </fieldset>
                  <fieldset className="form-group col-md-12" />
                  {editable?<>
                  <button
                    type="submit"
                    className="btn btn-sm hovbuttonColour col-md-2 mr-2"
                  >
                    Save
                  </button>
                  <span
                   type="button"
                   onClick={()=>this.setState({editable:false})}
                   className="btn btn-sm deletebutton col-md-2"
                 >
                   Cancel
                 </span>
                  </>:<>
                  <span
                    type="button"
                    onClick={()=>this.setState({editable:true})}
                    className="btn btn-sm hovbuttonColour col-md-2"
                  >
                    Edit
                  </span>
                </>}
                </div>
              </Form>
            )}
          </Formik>

        <div className="row mt-3">     
          <fieldset className="form-group col-md-6">
                    <TextField
                      className="form-control"
                      id="prefix"
                      name="prefix"
                      type="text"
                      label="E-Invoice Prefix"
                      placeholder="Enter E-Invoice Prefix"
                      value={this.state.prefix}
                      variant="standard"
                      fullwhidth="true"
                      onChange={(e) =>
                        e.target.value.length < 4?
                        this.setState({
                          prefix: e.target.value,
                          error: false,
                        })
                        :''
                      }
                      error={this.state.error &&  (this.state.prefix==='' ||this.state.prefix===null || this.state.prefix=== undefined)}
                      helperText={this.state.error && "E-Invoice Prefix is Required"}
                      disabled={this.state.prefixeditable === false}
                    />
                    </fieldset>
                    <fieldset className="form-group col-md-6">
                    <TextField
                      className="form-control"
                      id="serialno"
                      name="serialno"
                      type="text"
                      label={`Sample E-invoice Number`}
                      value={`${this.state.prefix}${this.state.einvoiceprefix}`}
                      variant="standard"
                      fullwhidth="true"
                      disabled
                    />
                  </fieldset>
                  {/* <fieldset className="form-group col-md-6">
                    <TextField
                      className="form-control"
                      id="serialno"
                      name="serialno"
                      type="number"
                      label={`Serial Number`}
                      placeholder="Enter the Serial Number"
                      value={this.state.serialno}
                      variant="standard"
                      fullwhidth="true"
                      onChange={(e) =>
                       
                        this.setState({
                          serialno: e.target.value,
                          error: false,
                        })
                      }
                      error={this.state.error && (this.state.serialno===''||this.state.serialno===null || this.state.serialno=== undefined)}
                      helperText={this.state.error && "Serial Number is Required"}
                      disabled={this.state.prefixeditable === false}
                    />
                  </fieldset> */}

                  {this.state.prefixeditable?<>
                  <span
                    type="button"
                    onClick={this.prefixSubmit}                  
                      className="btn btn-sm hovbuttonColour col-md-2 mr-2"
                  >
                    Save
                  </span>
                  <span
                   type="button"
                   onClick={()=>this.setState({prefixeditable:false})}
                   className="btn btn-sm deletebutton col-md-2"
                 >
                   Cancel
                 </span>
                  </>:<>
                  <span
                    type="button"
                    onClick={()=>this.setState({prefixeditable:true})}
                    className="btn btn-sm hovbuttonColour col-md-2"
                  >
                    Edit
                  </span>
                </>}
            </div>

        </div>
        :''}
      </div>
    );
  }
}

export default withRouter(Einvoicesettings);
