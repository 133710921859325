import {  Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, userid } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import AssetsLiability from '../Ledgers/AssetsLiability.jsx';
import Bank from '../Ledgers/Bank.jsx';
// import NewLedgerService from './NewLedgerService.js';
import Cash from '../Ledgers/Cash.jsx';
import NewJournalService from '../NewJournalService.js';
import Nominal from '../Ledgers/Nominal.jsx';
import Party from '../Ledgers/Party.jsx';
import { Backdrop, CircularProgress } from '@mui/material';


export let ruleid,ledgertypes,noliaincomeexp,totledgers;
// let frmpurchase;
class NewLedger extends Component {
  constructor(props) {
    super(props)


    this.state = {
      allledgers:[],
      ledgerradio:'',
      ledgertypesbtn:[
        {id:1,name:"Cash"},
        {id:2,name:"Bank"},
        {id:3,name:"Party Account"},
        {id:4,name:"Assets/Liabilities"},
        {id:5,name:"Income&Gain/Expense&Loss"},
    ],
    ledgertypes:[],
    loader1:true,
    btncolor:true,

    }
    // this.handleFocus=this.handleFocus.bind(this);
  }

  onChange(option){
   this.setState({ledgerradio: option.id});
   //alert(this.state.ledgerradio)
   if(option.d===3){
    ruleid=0
  }else if(option.id===4){
    ruleid=1
  }else if(option.id===5){
    this.setState({ledgerradio:option.id})
    ruleid=2
  }
   if(this.state.btncolor===option.id){
     this.setState({btncolor:"btn buttonColour"})
   } else{
    this.setState({btncolor:"btn buttonColour"})
   }
  //  jtype=option.id

  //  console.log(jtype)
  //  jname=option.name
  //  console.log(jname)
}

componentDidMount(){

// NewLedgerService.getLedgerBehaviour()
// .then(response=>{
//   this.setState({ledgertypes:response.data},()=>
//   ledgertypes=this.state.ledgertypes)
// })
// .catch((e)=>{
//   if(e.response.data.error ==="Unauthorized"){
//       alert("Your Session is Lost: Please login Back to resume");
//       this.props.navigate(`/`);
//   }
// })
NewJournalService.getAllLedgers(currentTenant)
.then(response=>{
  totledgers=response.data
  noliaincomeexp=totledgers.filter(e=>e.th==="TH3")
  this.setState({allledgers:totledgers},()=>this.setState({loader1:false}))})
 
  
if(userid===undefined){
    window.confirm('Your Session is Lost: Please login Back to resume')
    this.props.navigate(`/`)
  }
//  if(this.props.history.location.state.lv==='Personal'){
//    this.setState({ledgerradio:this.props.history.location.state.lv})
//     // frmpurchase=this.props.history.location.state.lv
//   //  console.log(this.props.history.location.state.lv),
// }
  
}

  
  componentDidUpdate(){
   // console.log("Component " + this.props)
  

  }
  
 
// handleCash(e){
//   this.setState({ledgerradio:e.target.value})

// }
// handleBank(e){
//   this.setState({ledgerradio:e.target.value})

// }

// handleFocus(e){
//   this.setState({ledgerradio:e.target.value})

// if(e.target.value==="Personal"){
//   ruleid=0
// }else if(e.target.value==="Real"){
//   ruleid=1
// }else if(e.target.value==="Nominal"){
//   this.setState({ledgerradio:e.target.value})
//   ruleid=2
// }
  
// }

  render() {
    
     

    return (
    
      
      <div>
        <Header />
        <Menu/>
          
        <div className="content-wrapper">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
          <div className="card card-info">
            {/* <div className="card-header">
              <h3 className="card-title">Ledgers</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                          Accounts
              </li>
              <li className="breadcrumb-item active">Ledgers</li>
            </ol>
            <div className="text-center text-danger">{this.state.error}</div>
            <div className="card-body">
            <Formik
            enableReinitialize={true}>
            {() => (
              <Form>

<div className="form-row">

{this.state.ledgertypesbtn.map((option)=>{

        return <label key={option.id} >

            <div className="form-group col-md-9 text-sm">
              <div className="btn-group">
              {/* <div className="form-control bg-light border-light"> */}

                     <input 
                        className={
                          this.state.ledgerradio === option.id
                            ? "btn buttonColour text-yellow text-bold"
                            : "btn hovbuttonColour text-white"
                        }
                        type="button" 
                        // checked={this.state.checked === option.id ? true : false} 
                        key={option.id}
                        onClick={this.onChange.bind(this,option)} 
                        style={{marginRight: 8,width:200}}
                        value={option.name} 
                        />
                    {/* {option.name} */}
                
                    </div></div>
                    {/* </div> */}
                </label>
               

    })
}

</div> 
              </Form>
              
                 )}
              </Formik>
              {this.state.ledgerradio===3?<Party/>:""}
              {this.state.ledgerradio===1?<Cash/>:''}
              {this.state.ledgerradio===4?<AssetsLiability/>:''}
              {this.state.ledgerradio===5?<Nominal/>:''}
              {this.state.ledgerradio===2?<Bank/>:''}



            </div>
          </div>
        </div>


      <Footer/>
      </div>
    );
  }
}
   
export default withRouter(NewLedger)


