import React, { useEffect, useState } from "react";
import withRouter from "../../withRouter";
import SalesServices from "../SalesServices";
import { ThemeProvider, createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import {
  currentTenant,
  financialyearstart,
  thousandsdisplay,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import { Link } from "react-router-dom";
import Menu from "../../Component/Menu";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import moment from "moment";
import { NumericFormat } from "react-number-format";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

const AgentsReport = () => {
  const [pendingdatabyagent, setPendingdatabyagent] = useState([]);
  const [pendinginvoicedatabyagent, setPendingInvoicedatabyagent] = useState([]);
  const [agentcompleteddata, setAgentscompletedData] = useState([]);
  const [agentsCompletedInvoicedata,setAgentsCompletedInvoicedata] = useState([])
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("Pending");
  const [ordertype,setOrdertype] = useState("SaleOrder")

  useEffect(() => {
    setLoading(true);
    let data = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: moment(new Date()),
    };
    SalesServices.GetAgentsPendingOrders(data).then((response) => Agentsdata(response.data, 1));
    SalesServices.GetAgentsCompletedOrders(data).then((response) => Agentsdata(response.data, 2));
    SalesServices.getagentsinvoicereports(data).then(response=>AgentsInvoicedata(response.data, 1))
    SalesServices.getagentscompletedinvoicereports(data).then(response=>AgentsInvoicedata(response.data,2))
  }, []);

  const Agentsdata = (data, idx) => {
    const agents = data.reduce(
      (
        agt,
        {
          itemname,
          itemid,
          quantity,
          totalprice,
          totalpricewtax,
          createdAt,
          customer,
          custid,
          salesenum,
          agent,
          soid,
          delivered,
          accepted,
          pending,
          preclose,
          uom,
          sobalance
        }
      ) => {
        if (!agt[agent]) agt[agent] = [];
        agt[agent].push({
          itemid: itemid,
          itemname: itemname,
          quantity: quantity,
          totalprice: totalprice,
          totalpricewtax: totalpricewtax,
          createdAt: createdAt,
          customer: customer,
          custid: custid,
          salesenum: salesenum,
          agent: agent,
          soid: soid,
          delivered: delivered,
          accepted: accepted,
          pending: pending,
          preclose:preclose,
          uom: uom,
          sobalance:sobalance
        });
        return agt;
      },
      {}
    );

    let products = [];
    Object.entries(agents).forEach(([key, value]) => {
      const productsbyagent = value.reduce(
        (
          item,
          { itemname, itemid, quantity, totalprice, totalpricewtax, uom, agent }
        ) => {
          if (!item[itemid]) item[itemid] = [];
          item[itemid].push({
            itemid: itemid,
            itemname: itemname,
            quantity: quantity,
            totalprice: totalprice,
            totalpricewtax: totalpricewtax,
            uom: uom,
            agent: agent,
          });
          return item;
        },
        {}
      );

      Object.entries(productsbyagent).forEach(([key, value]) => {
        let a = {
          itemid: key,
          agent: value.map((e) => e.agent)[0],
          itemname: value.map((e) => e.itemname)[0],
          uom: value.map((e) => e.uom)[0],
          quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
          totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
          totalpricewtax: value.reduce((a, v) => (a = a + v.totalpricewtax), 0),
        };
        products.push(a);
      });
    });
    console.log(products);

    let agentlist = [];
    Object.entries(agents).forEach(([key, value]) => {
      let a = {
        agent: key,
        data: value,
      };
      agentlist.push(a);
    });
    console.log(agentlist)
    if (idx === 1) {
      setPendingdatabyagent(agentlist);
    } else if (idx === 2) {
      setAgentscompletedData(agentlist);
    }
    setLoading(false);
  };

  const AgentsInvoicedata = (data,idx) => {
    const agents = data.reduce(
      (
        agt,
        {
          item,
          quantity,
          totalprice,
          totalpricewtax,
          createdAt,
          customer,
          custid,
          agent,
          soid,
          invid,
          delivered,
          preclosed,
          returned,
          uom,
          balance
        }
      ) => {
        if (!agt[agent]) agt[agent] = [];
        agt[agent].push({
          item: item,
          quantity: quantity,
          totalprice: totalprice,
          totalpricewtax: totalpricewtax,
          createdAt: createdAt,
          customer: customer,
          custid: custid,
          agent: agent,
          soid: soid,
          invid:invid,
          delivered: delivered,
          preclosed: preclosed,
          returned: returned,
          uom: uom,
          balance:balance
        });
        return agt;
      },
      {}
    );

    let agentlist = [];
    Object.entries(agents).forEach(([key, value]) => {
      let a = {
        agent: key,
        data: value,
      };
      agentlist.push(a);
    });

    if (idx === 1) {
      setPendingInvoicedatabyagent(agentlist);
    } 
    else if (idx === 2) {
      setAgentsCompletedInvoicedata(agentlist);
    }
  }

  

  const columns = [
    { name: "agent", label: "Agent", options: { filter: true, sort: true,display: false } },
    {
      name: "soid",
      label: "Order Id",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <Link
            to={{
              pathname: "/saleorderdetails",
            }}
            state={{
              id: value,
            }}
          >
            {value}
          </Link>
        ),
      },
    },
    {
      name: "customer",
      label: "Customer",
      options: { filter: true, sort: true },
    },
    { name: "itemname", label: "Item", options: { filter: true, sort: true } },
    {
      name: "quantity",
      label: "Quantity",
      options: { filter: true, sort: true },
    },
    {
      name: "pending",
      label: "Pending",
      options: { filter: true, sort: true },
    },
    {
      name: "accepted",
      label: "Invoiced",
      options: { filter: true, sort: true },
    },
    {
      name: "delivered",
      label: "Delivered",
      options: { filter: true, sort: true },
    },
    {
      name: "uom",
      label: "UOM",
      options: { filter: true, sort: true },
    },
    {
      name: "totalpricewtax",
      label: "Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "sobalance",
      label: "Balance SO Amount",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (tableMeta) =>
          moment(tableMeta).format("DD-MM-YYYY HH:mm"),
      },
    },
    {
      name: "createdAt",
      label: "Elapsed Days(Aging)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let cre = moment(value, "YYYY-MM-DD");
          let curr = moment(new Date(), "YYYY-MM-DD");
          return <>{curr.diff(cre, "days")} Days</>;
        },
      },
    },
  ];

  const completedcolumns = [
    { name: "agent", label: "Agent", options: { filter: true, sort: true,display: false } },
    {
      name: "soid",
      label: "Order Id",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <Link
            to={{
              pathname: "/saleorderdetails",
            }}
            state={{
              id: value,
            }}
          >
            {value}
          </Link>
        ),
      },
    },
    {
      name: "customer",
      label: "Customer",
      options: { filter: true, sort: true },
    },
    { name: "itemname", label: "Item", options: { filter: true, sort: true } },
    {
      name: "quantity",
      label: "Quantity",
      options: { filter: true, sort: true },
    },
    {
      name: "accepted",
      label: "Invoiced",
      options: { filter: true, sort: true },
    },
    {
      name: "delivered",
      label: "Delivered",
      options: { filter: true, sort: true },
    },
    {
      name: "preclose",
      label: "Preclose",
      options: { filter: true, sort: true },
    },
    {
      name: "uom",
      label: "UOM",
      options: { filter: true, sort: true },
    },
    {
      name: "totalpricewtax",
      label: "Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "sobalance",
      label: "Balance SO Amount",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (tableMeta) =>
          moment(tableMeta).format("DD-MM-YYYY HH:mm"),
      },
    },
  ];

  const invoicecolumns = [
    { name: "agent", label: "Agent", options: { filter: true, sort: true,display: false } },
    {
      name: "soid",
      label: "Order Id",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          value === null?"Direct":
          <Link
            to={{
              pathname: "/saleorderdetails",
            }}
            state={{
              id: value,
            }}
          >
            {value}
          </Link>
        ),
      },
    },
    {
      name: "invid",
      label: "Invoice Id",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          value === null?"Direct":
          <Link
          to={{pathname: "/viewsalesinvoice"}}
          state ={{id:value,pagename:'Tax Invoice Details',type:"Tax Invoice",temp:false}}
          >
            {value}
          </Link>
        ),
      },
    },
    {
      name: "customer",
      label: "Customer",
      options: { filter: true, sort: true },
    },
    { name: "item", label: "Item", options: { filter: true, sort: true } },
    {
      name: "quantity",
      label: "Quantity",
      options: { filter: true, sort: true },
    },
    {
      name: "delivered",
      label: "Delivered",
      options: { filter: true, sort: true },
    },
    {
      name: "returned",
      label: "Returned",
      options: { filter: true, sort: true },
    },
    {
      name: "preclosed",
      label: "Preclosed",
      options: { filter: true, sort: true },
    },
    {
      name: "uom",
      label: "UOM",
      options: { filter: true, sort: true },
    },
    {
      name: "totalpricewtax",
      label: "Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "balance",
      label: "Invoice Balance",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (tableMeta) =>
          moment(tableMeta).format("DD-MM-YYYY HH:mm"),
      },
    },
    {
      name: "createdAt",
      label: "Elapsed Days(Aging)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          let cre = moment(value, "YYYY-MM-DD");
          let curr = moment(new Date(), "YYYY-MM-DD");
          return <>{curr.diff(cre, "days")} Days</>;
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/sales" className="text-info">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item active">Sales Agents Reports</li>
          </ol>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card-header m-0 p-2">
                    <div className="text-center text-green"></div>
                  </div>
                  <div>
                    {loading === true ? (
                      <div className="text-center">{spin}</div>
                    ) : (
                      <>
                       <fieldset className="form-group col-md-12">
                          <fieldset
                            className={`form-group col-md-3  shadow-sm text-center btn ${
                              ordertype === "SaleOrder"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                            }`}
                            disabled={ordertype === "SaleOrder"}
                            onClick={() => setOrdertype("SaleOrder")}
                          >
                            SaleOrder
                          </fieldset>
                          <fieldset
                            className={`form-group col-md-3 shadow-sm btn text-center ${
                              ordertype === "Invoice"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                            }`}
                            disabled={ordertype === "Invoice"}
                            onClick={() => setOrdertype("Invoice")}
                          >
                            Invoice
                          </fieldset>
                        </fieldset>

                        <fieldset className="form-group col-md-12">
                          <fieldset
                            className={`form-group col-md-3  shadow-sm text-center btn ${
                              type === "Pending"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                            }`}
                            disabled={type === "Pending"}
                            onClick={() => setType("Pending")}
                          >
                            Pending
                          </fieldset>
                          <fieldset
                            className={`form-group col-md-3 shadow-sm btn text-center ${
                              type === "Completed"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                            }`}
                            disabled={type === "Completed"}
                            onClick={() => setType("Completed")}
                          >
                            Completed
                          </fieldset>
                        </fieldset>
                        { ordertype === "SaleOrder" && type === "Pending" ? (
                          <>
                            {pendingdatabyagent.length > 0 ? (
                              pendingdatabyagent?.map((e, idx) => (
                                <div key={idx} className="mb-4">
                                  <ThemeProvider theme={theme}>
                                    <MUIDataTable
                                      className="table-sm px-3"
                                      title={e.agent}
                                      id="assets"
                                      data={e.data}
                                      columns={columns}
                                      options={options}
                                    />
                                  </ThemeProvider>
                                </div>
                              ))
                            ) : (
                              <div className="text-center p-4">
                                No data Avilable
                              </div>
                            )}
                          </>
                        ) : (
                          ordertype === "SaleOrder" && type === "Completed" ?
                          <>
                            {agentcompleteddata.length > 0 ? (
                              agentcompleteddata?.map((e, idx) => (
                                <div key={idx} className="mb-4">
                                  <ThemeProvider theme={theme}>
                                    <MUIDataTable
                                      className="table-sm px-3"
                                      title={e.agent}
                                      id="assets"
                                      data={e.data}
                                      columns={completedcolumns}
                                      options={options}
                                    />
                                  </ThemeProvider>
                                </div>
                              ))
                            ) : (
                              <div className="text-center py-4">
                                No data Avilable
                              </div>
                            )}
                          </>
                          :
                          ordertype === "Invoice" && type === "Pending" ?
                          <>
                          {pendinginvoicedatabyagent.length > 0 ? (
                            pendinginvoicedatabyagent?.map((e, idx) => (
                              <div key={idx} className="mb-4">
                                <ThemeProvider theme={theme}>
                                  <MUIDataTable
                                    className="table-sm px-3"
                                    title={e.agent}
                                    id="assets"
                                    data={e.data}
                                    columns={invoicecolumns}
                                    options={options}
                                  />
                                </ThemeProvider>
                              </div>
                            ))
                          ) : (
                            <div className="text-center py-4">
                              No data Avilable
                            </div>
                          )}
                        </>
                        :
                        <>
                        {agentsCompletedInvoicedata.length > 0 ? (
                          agentsCompletedInvoicedata?.map((e, idx) => (
                            <div key={idx} className="mb-4">
                              <ThemeProvider theme={theme}>
                                <MUIDataTable
                                  className="table-sm px-3"
                                  title={e.agent}
                                  id="assets"
                                  data={e.data}
                                  columns={invoicecolumns}
                                  options={options}
                                />
                              </ThemeProvider>
                            </div>
                          ))
                        ) : (
                          <div className="text-center py-4">
                            No data Avilable
                          </div>
                        )}
                      </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(AgentsReport);
