import { Checkbox, Grid, ListItemText, OutlinedInput, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import Header from "../../../Component/Header";
import Menu from "../../../Component/Menu";
import Footer from "../../../Component/Footer";
import { Link, useNavigate } from "react-router-dom";
import TourServices from '../../Invoice/TourServices';
import { currentTenant, currentUser } from "../../../Common/Credentials";
import moment from "moment";
import withRouter from "../../../withRouter";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MasterService from "../../../Masters/MasterService";



const Partner = (props) => {
  const [checked, setChecked] = useState(true);
  const [checkedSupplier,setCheckedSupplier] = useState(true);
  const [checkedCustomer,setCheckedCustomer] = useState(true);
  const [checkedEmployee,setCheckedEmployee] = useState(true);
  const [checkedCreditRule,setCheckedCreditrule] = useState(true);
  const [date,setDate]=useState('');
  const [dob,setDob]=useState('');
  const [idorcrexpiryon,setIdorcrexpiryon] = useState('');
  const nevigate = useNavigate();

//   const [code,setcode]=useState('');
//   const [date,setdate]=useState('');
//   const [name,setname]=useState('');
//   const [arabicname,setarabicname]=useState('');
//   const [arabicaddress,setarabicaddress]=useState('');
//  const [dob,setdob]=useState('');
//   const [address,setaddress]=useState('');
//   const [overseasaddress,setoverseasaddress]=useState('');
//   const [overseano,setoverseano]=useState('');
//   const [nationality,setnationality]=useState('');
//   const [mobile,setmobile]=useState('');
//   const [phoneno,setphoneno]=useState('');
//   const [email,setemail]=useState('');
//   const [fax,setfax]=useState('');
//   const [idorcrno,setidorcrno]=useState('');
//   const [idorcrexpiryon,setidorcrexpiryon]=useState('');
//   const [corodetail,setcorodetail]=useState('');
//   const [cont,setcont]=useState('');
//   const [contactperson,setcontactperson]=useState('');
//   const [sponsor,setsponsor]=useState('');
//   const [accountcode,setaccountcode]=useState('');
//   const [accountname,setaccountname]=useState('');
//   const [fileno,setfileno]=useState('');
//   const [remarks,setremarks]=useState('');
//   const [createdata,setcreatedata]=useState('');
//   const [modifieddate,setmodifieddate]=useState('');
//   const [tenantid,settenantid]=useState('');
 
  const [data, setData] = useState({
    id: 0,
    code: "",
    date: "",
    name: "",
    arabicname: "",
    profilecategory: "",
    profilegroup: "",
    bob: "",
    address: "",
    arabicaddress:"",
    overseasaddress: "",
    overseasno: "",
    nationality: "",
    mobile: "",
    phoneno: "",
    email: "",
    fax: "",
    idorcrno: "",
    corodetail: "",
    contactperson: "",
    sponsor: "",
    currency: "",
    accountcode: "",
    accountname: "",
    fileno: "",
    remarks: "",
    createddate: new Date(),
    modifieddate: new Date(),
    tenantid: currentTenant,
  });
  const [prefixtab, setPrefixtab] = useState('');
  const [profilecategory, setProfilecategory] = useState('');
  const [profilegroup, setProfilegroup] = useState('');
  const [sex, setSex] = useState('');
  const [role,setRole] = useState('');
  const [currency, setCurrency] = useState([]);
  const [currencyd, setCurrencyd] = useState([]);



  useEffect(() => {
    getCurrencyHandler();

    if (props.location.state.id !== -1) {
      TourServices.getByIdMPartner(props.location.state.id).then((r) => {
        setData({
          code: r.data.code,
          date: r.data.date,
          prifx: r.data.prifix,
          name: r.data.name,
          arabicname: r.data.arabicname,
          profilecategory: r.data.profilecategory,
          profilegroup: r.data.profilegroup,
          sex: r.data.sex,
          bob: r.data.bob,
          address: r.data.address,
          overseasaddress: r.data.overseasaddress,
          overseasno: r.data.overseasno,
          nationality: r.data.nationality,
          mobile: r.data.mobile,
          phoneno: r.data.phoneno,
          email: r.data.email,
          fax: r.data.email,
          idorcrno: r.data.idorcrno,
          idorcrexpiryon: r.data.idorcrexpiryon,
          corodetail: r.data.corodetail,
          contactperson: r.data.contactperson,
          sponsor: r.data.sponsor,
          currency: r.data.currency,
          role: r.data.role,
          primaryrole: r.data.primaryrole,
          accountcode: r.data.accountcode,
          accountname: r.data.accountname,
          creditrule: r.data.creditrule,
          fileno: r.data.fileno,
          remarks: r.data.remarks,
          createddate: r.data.createddate,
          modifieddate: new Date(),
          tenantid: currentTenant,
        })

      })
    }
    console.log(currency);
  }, [])

  //  ----------------------------------------------------------------------------
  // Drop DownList....

  const handleChangeprefix = (e) => {
    setPrefixtab(e.target.value);
  };
  const handleChangeProfileCategory = (e) => {
    setProfilecategory(e.target.value);
  };
  const handleChangeProfileGroup = (e) => {
    setProfilegroup(e.target.value);
  };
  const handleChangeSex = (e) => {
    setSex(e.target.value);
  };

  const handleChangePrimaryrole = (e) => {
    if(e.target.value !== undefined ||e.target.value !== null || e.target.value !== "")
    {
      setRole(e.target.value);
    }
  };
  const changeHandlerDOB = (e)=>{
    console.log(e);
    setDob(moment(new Date(e)).format('DD/MM/YYYY'))
  }
  const changeHandlerDate = (e)=>{
    console.log(e);
    setDate(moment(new Date(e)).format('DD/MM/YYYY'))
  }
  const changeHandlerIdorcr = (e)=>{
    console.log(e);
    setIdorcrexpiryon(moment(new Date(e)).format('DD/MM/YYYY'))
  }

  // -------------------------------------------------------------
  const changeHandler = (e) => {
    console.log(e.target.value,"98")
    if(e.target.value === undefined)
    {
      setData(prevValues => {
        return { ...prevValues, [e.target.name]: moment(e.target.value).format('DD-MM-YYYY') }
      })
    }
    else
    {
      setData(prevValues => {
        return { ...prevValues, [e.target.name]: e.target.value }
      })
    }
  }
 
  const AddHandler = (e) => {

    e.preventDefault();
    if (props.location.state.id === -1) {


      const sdata = {
        code: data.code,
        date: date,
        prifx: prefixtab,
        name: data.name,
        arabicname: data.arabicname,
        profilecategory: profilecategory,
        profilegroup: profilegroup,
        sex: sex,
        dob: dob,
        address: data.address,
        arabicaddress: data.arabicaddress,
        overseasaddress: data.overseasaddress,
        overseasno: data.overseasno,
        nationality: data.nationality,
        mobile: data.mobile,
        phoneno: data.phoneno,
        email: data.email,
        fax: data.email,
        idorcrno: data.idorcrno,
        idorcrexpiryon: idorcrexpiryon,
        corodetail: data.corodetail,
        contactperson: data.contactperson,
        sponsor: data.sponsor,
        currency: currencyd.toString(),
        suprole:checkedSupplier,
        custrole:checkedCustomer,
        emprole:checkedEmployee,

        primaryrole: role,
        accountcode: data.accountcode,
        accountname: data.accountname,
        creditrule: checkedCreditRule,
        fileno: data.fileno,
        remarks: data.remarks,
        crediteby:currentUser,
        modifiedby: currentUser,
        createddate: new Date(),
        modifieddate: new Date(),
        tenantid: currentTenant,
      }
      TourServices.addMPartner(sdata).then((r) => {
        console.log(r.data);
      }).catch(err => console.log(err));
      nevigate("/masterpartnerlist");
    }
    else {
      const sdata = {
        code: data.code,
        date: date,
        prifx: prefixtab,
        name: data.name,
        arabicname: data.arabicname,
        profilecategory: profilecategory,
        profilegroup: profilegroup,
        sex: sex,
        dob: dob,
        address: data.address,
        arabicaddress: data.arabicaddress,
        overseasaddress: data.overseasaddress,
        overseasno: data.overseasno,
        nationality: data.nationality,
        mobile: data.mobile,
        phoneno: data.phoneno,
        email: data.email,
        fax: data.email,
        idorcrno: data.idorcrno,
        idorcrexpiryon: idorcrexpiryon,
        corodetail: data.corodetail,
        contactperson: data.contactperson,
        sponsor: data.sponsor,
        currency: currencyd.toString(),
        suprole:checkedSupplier,
        custrole:checkedCustomer,
        emprole:checkedEmployee,

        primaryrole: role,
        accountcode: data.accountcode,
        accountname: data.accountname,
        creditrule: checkedCreditRule,
        fileno: data.fileno,
        remarks: data.remarks,

        modifiedby: currentUser,

        modifieddate: new Date(),
        tenantid: currentTenant,
      }
      TourServices.updateMPartner(sdata, props.location.state.id).then((r) => console.log(r))
      nevigate("/masterpartnerlist");
    }
  }

  const getCurrencyHandler = () => {
    MasterService.GetAllCurrency().then(r => {
      setCurrency(r.data);
    })
  }

  const changeHandlerSupplier = (event) => {
    setCheckedSupplier(event.target.checked);
  
  };

  const changeHandlerCustomer = (event) => {
    setCheckedCustomer(event.target.checked);
  
  };

  const changeHandlerEmployee = (event) => {
    setCheckedEmployee(event.target.checked);
  
  };

  const changeHandlerCreditrule = (event) => {
    setCheckedCreditrule(event.target.checked);
  
  };

  const handleChangeCurrency = (event) => {
    const {
      target: { value },
    } = event;
    setCurrencyd(
      typeof value === 'string' ? value.split(',') : value,
    );
  }
  return (
    <>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/masterpartnerlist">Partner List </Link>
            </li>
            <li className="breadcrumb-item active">Partner</li>
          </ol>
          <div className="">
            <form onSubmit={AddHandler}>
              <div className="card-body p-6">
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="code"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="code"
                      value={data.code}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      
                        <DatePicker
                          variant="inline"
                          id="date-picker-dialog"
                          label="Date"
                          // format="dd/MM/yyyy"
                          // maxDate={new Date()}
                          value={date}
                          onChange={changeHandlerDate}
                          renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                          autoOk={true}
                        />
                     
                    </LocalizationProvider>
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-2 col-sm-12">
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                      <InputLabel id="demo-simple-select-helper-label">Prifx</InputLabel>
                      <Select
                        value={prefixtab}
                        onChange={handleChangeprefix}
                        inputProps={{ 'aria-label': 'Without label' }}
                        size="small"
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={"Mr"}>Mr</MenuItem>
                        <MenuItem value={"Mrs"}>Mrs</MenuItem>
                        <MenuItem value={"M/s"}>M/s</MenuItem>
                        <MenuItem value={"Miss"}>Miss</MenuItem>
                        <MenuItem value={"SH"}>SH</MenuItem>
                        <MenuItem value={"Dr"}>Dr</MenuItem>
                        <MenuItem value={"HH"}>HH</MenuItem>
                        <MenuItem value={"HE"}>HE</MenuItem>
                      </Select>

                    </FormControl>
                  </fieldset>
                  <fieldset className="col-md-10 col-sm-12 my-2">
                  <TextField
                      fullWidth
                      required="true"
                      label="Name"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="name"
                      value={data.name}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-12 col-sm-12">
                    <TextField
                      fullWidth
                      label="Arabic Name"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="arabicname"
                      value={data.arabicname}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-6 col-sm-12">
                    <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">Profile Category</InputLabel>
                      <Select
                        value={profilecategory}
                        onChange={handleChangeProfileCategory}
                        inputProps={{ 'aria-label': 'Without label' }}
                        size="small"
                      >
                        <MenuItem value="">
                          <em>Choose profile category</em>
                        </MenuItem>
                        <MenuItem value={"Default"}>default</MenuItem>

                      </Select>

                    </FormControl>
                  </fieldset>
                  <fieldset className="col-md-6 col-sm-12">
                    <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">Profile Group</InputLabel>
                      <Select
                        value={profilegroup}
                        onChange={handleChangeProfileGroup}
                        inputProps={{ 'aria-label': 'Without label' }}
                        size="small"
                      >
                        <MenuItem value="">
                          <em>Choose Profile Group</em>
                        </MenuItem>
                        <MenuItem value={"Default"}>Default</MenuItem>

                      </Select>

                    </FormControl>
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-6 col-sm-12">
                    <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">Sex</InputLabel>
                      <Select
                        value={sex}
                        onChange={handleChangeSex}
                        inputProps={{ 'aria-label': 'Without label' }}
                        size="small"
                      >
                        <MenuItem value="">
                          <em>Sex</em>
                        </MenuItem>
                        <MenuItem value={"Male"}>Male</MenuItem>
                        <MenuItem value={"Female"}>Female</MenuItem>
                        <MenuItem value={"Others"}>Others</MenuItem>
                      </Select>
                    </FormControl>
                  </fieldset>
                  <fieldset className="col-md-6 col-sm-12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      
                        <DatePicker
                          variant="inline"
                          id="date-picker-dialog"
                          label="Date of birth"
                          // format="DD/MM/YYYY"
                          // maxDate={new Date()}
                          value={dob}
                          onChange={changeHandlerDOB}
                          renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                          
                        />
                     
                    </LocalizationProvider>
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      label="Address"
                      id="outlined-size-small"
                      size="small"
                      multiline
                      rows={3}
                      maxRows={3}
                      variant="outlined"
                      name="address"
                      value={data.address}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      label="Arabic Address"
                      id="outlined-size-small"
                      size="small"
                      rows={3}
                      maxRows={3}
                      multiline
                      variant="outlined"
                      name="arabicaddress"
                      value={data.arabicaddress}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      label="Overseas Address"
                      id="outlined-size-small"
                      size="small"
                      multiline
                      rows={3}
                      maxRows={3}
                      variant="outlined"
                      name="overseasaddress"
                      value={data.overseasaddress}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-6 col-sm-12">
                    <div className="col-md-12 col-sm-12">
                      <TextField
                        label="Overseas No"
                        id="outlined-size-small"
                        size="small"
                        fullWidth
                        variant="outlined"
                        name="overseasno"
                        value={data.overseasno}
                        onChange={changeHandler}
                      />
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <TextField
                        fullWidth
                        label="Nationality"
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        name="nationality"
                        value={data.nationality}
                        onChange={changeHandler}
                      />
                    </div>
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Mobile"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="mobile"
                      value={data.mobile}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="phoneno"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="phoneno"
                      value={data.phoneno}
                      onChange={changeHandler}
                    />
                  </fieldset>

                </div>
                <div className="row my-1">
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Email"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="email"
                      value={data.email}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Fax"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="fax"
                      value={data.fax}
                      onChange={changeHandler}
                    />
                  </fieldset>

                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="ID/CR No"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="idorcrno"
                      value={data.idorcrno}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      
                        <DatePicker
                          variant="inline"
                          id="date-picker-dialog"
                          label="ID/CR Expiry On"
                         
                          value={idorcrexpiryon}
                          onChange={changeHandlerIdorcr}
                          renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                          
                        />
                    
                    </LocalizationProvider>
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="C/o Detail"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="corodetail"
                      value={data.corodetail}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Contact Person"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="contactperson"
                      value={data.contactperson}
                      onChange={changeHandler}
                    />
                  </fieldset>

                </div>
                <div className="row my-1">
                  <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Sponsor"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="sponsor"
                      value={data.sponsor}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-6 col-sm-12">

                    <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-multiple-checkbox-label">Currency</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={currencyd}
                        onChange={handleChangeCurrency}
                        input={<OutlinedInput label="Currency" />}
                        renderValue={(selected) => selected.join(', ')}
                        // MenuProps={MenuProps}
                        size="small"
                      >
                        {currency.map((c, i) => (
                          <MenuItem key={i} value={c.currency}>
                            <Checkbox checked={currencyd.indexOf(c.currency) > -1} />
                            <ListItemText primary={c.currency} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="d-flex my-1">
                    <InputLabel>Role</InputLabel>
                  </fieldset>
                  <fieldset className="">
                    <lable className="my-2 ml-4">Customer</lable>
                    <Checkbox
                    size="sm"
                      checked={checkedCustomer}
                      onChange={changeHandlerCustomer}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </fieldset>
                  <fieldset className="">
                    <lable className="my-2 ml-4">Supplier</lable>
                    <Checkbox
                    size="sm"
                      checked={checkedSupplier}
                      onChange={changeHandlerSupplier}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </fieldset>
                  <fieldset className="">
                    <lable className="my-2 ml-4">Employee</lable>
                    <Checkbox
                    size="sm"
                      checked={checkedEmployee}
                      onChange={changeHandlerEmployee}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </fieldset>
                  <fieldset className="col-md-5 col-sm-12">
                    <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
                      <InputLabel id="demo-simple-select-helper-label">primary Role</InputLabel>
                      <Select
                        value={role}
                        onChange={handleChangePrimaryrole}
                        inputProps={{ 'aria-label': 'Without label' }}
                        size="small"
                      >
                        { <MenuItem value="">
                          <em>Choose Primary Role </em>
                        </MenuItem>}
                        {checkedCustomer===true? <MenuItem value={"Customer"}>Customer</MenuItem>:""}
                        {checkedSupplier===true? <MenuItem value={"Supplier"}>Supplier</MenuItem> :""}
                        {checkedEmployee===true? <MenuItem value={"Employee"}>Employee</MenuItem>:""}
                      </Select>
                    </FormControl>
                  </fieldset>

                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      label="Account Code"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="accountcode"
                      value={data.accountcode}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      label="Account Number"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="accountname"
                      value={data.accountname}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1 ">
                <fieldset className="col-md-12 col-sm-12 ">
                    <lable className="my-2 ml-4">Apply different credit rule for main customer and sub customer</lable>
                    <Checkbox
                    size="sm"
                      checked={checkedCreditRule}
                      onChange={changeHandlerCreditrule}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                <div className="col-md-6 col-sm-12">
                <div className="d-flex-coloumn ">
                <fieldset className="">
                    <TextField
                      fullWidth
                      label="File Number"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="fileno"
                      value={data.fileno}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="my-1">
                    <TextField
                      fullWidth
                      label="File Number"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="fileno"
                      value={data.fileno}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                </div>
                  {/* <fieldset className="col-md-6 col-sm-12">
                    <TextField
                      fullWidth
                      label="Remarks"
                      id="outlined-size-small"
                      size="small"
                      multiline
                      maxRows={3}
                      rows={3}
                      variant="outlined"
                      name="remarks"
                      value={data.remarks}
                      onChange={changeHandler}
                    />
                  </fieldset> */}
                </div>



                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <InputLabel>Created By : {currentUser}</InputLabel>
                    </div>

                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <InputLabel>Modified By : {currentUser} </InputLabel>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <InputLabel>Created Time : {moment(data.createddate).format("DD-MM-YYYY")}</InputLabel>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <InputLabel>Modified Time : {moment(data.modifieddate).format("DD-MM-YYYY")} </InputLabel>
                    </div>
                  </div>
                  <div className="btn d-flex justify-context-center">
                    <button className="btn buttonColour btn-sm  " >ADD</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(Partner);
