import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  ccountry,
  cstate,
  currency,
  currentTenant,
  financialyearend,
  financialyearstart,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {NumericFormat} from "react-number-format";
import MUIDataTable from "mui-datatables";
import SalesServices from "../../Sales/SalesServices";
import PurchaseServices from "../../Purchase/PurchseServices";
import moment from "moment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import "date-fns";
import { Col, Modal, Spinner } from "react-bootstrap";
import { FormLabel, Radio, RadioGroup, ThemeProvider } from "@mui/material";
import {
  createTheme,
  TextField,
  InputLabel,
  FormControl,
  Grid,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  InputAdornment,
    Table,
    TableBody,
    TableHead,
    TableCell,
    TableRow,
} from "@mui/material";
import CountUp from "react-countup";
import InventoryService from "../../inventory/InventoryService";
import { Autocomplete } from "@mui/material";
import { purchaseid, salesid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import { accounts } from "../../Common/Products";
import CategoryServices from "../../Masters/Category/CategoryServices";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";import AgentServices from "../../Masters/Agents/AgentServices";
import Swal from "sweetalert2";
import MasterService from "../../Masters/MasterService";
import UserService from "../../users/UserService";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
class Directsales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      iid: "",
      filterdata: [],
      loading: false,
      selected: 0,
      salesquantity: 0,
      purchasequantity: 0,
      purchasereportsdata: [],
      purchasefilterdata: [],
      directsales: [],
      purchasesales: [],
      openingstock: 0,
      closingstock: 0,
      showmodel: false,
      trade: false,
      tradetype: "Sales",
      agentreq: false,
      agentsdata: [],
      agent: "",
      agentid: "",
      customers: [],
      customer: "",
      custid: "",
      suppliers: [],
      supplier: "",
      supplierid: "",
      CategoryList: [],
      category: "",
      categoryid: "",
      itemslist: [],
      filteritemlist: [],
      itemname: "",
      itemid: "",
      cost: 0,
      quantity: 0,
      hsn: "",
      uom: "",
      uomid: "",
      tax: "",
      taxtype: "GST",
      gsttype: false,
      totalprice: 0,
      taxamount:0,
      totalpricewtax:0,
      expecteddelivery: new Date(),
      graceperiod: 0,
      days: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      currencylist: [],
      currency: "",
      currencyid: "",
      whid:'',
      itemsfor: [
        { id: "2", name: "Raw Material" },
        { id: "1", name: "Reselling" },
      ],
      poitemstatus: "2",
    };

    this.validate = this.validate.bind(this);
  }

  handleAgent = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        agent: values.name,
        agentid: values.id,
      });
    } else {
      this.setState({ agent: "", agentid: "" });
    }
  };

  handleCustomer = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      let lv = "Personal";
      if (values.ledgerid === null || values.ledgerid === undefined) {
        if (accounts === "YES") {
          if (
            window.confirm(
              `Ledger account is not created for supplier ${values.companyname}, so no payment or receipt can be done. Press 'ok' to create ledger or 'cancel' to continue`
            )
          ) {
            this.props.navigate("/newparty", {
              id: -1,
              name: "New Party Ledger",
              lv,
            });
          }
        } else {
          alert(
            `Ledger account is not created for Customer ${values.companyname}, so no payment or receipt can be done`
          );
        }
      }
      this.setState({
        custid: values.id,
        customer: values.companyname,
        ledgerid: values.ledgerid,
        currency: values.currency,
        currencyid: this.state.currencylist.find(
          (e) => e.currency === values.currency
        ).id,
        gsttype:
          values.statename === cstate && values.countryname === ccountry
            ? false
            : true,
        custcountry: values.countryname,
        convertionreq:
          values.currency === this.state.basecurrency ? false : true,
        ordertype: false,
        taxtype: values.taxtype,
      });
    } else {
      this.setState({
        custid: "",
        customer: "",
        ledgerid: "",
      });
    }
  };

  handlesupplier = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      let lv = "Personal";

      if (values.ledgerid === null || values.ledgerid === undefined) {
        if (accounts === "YES") {
          if (
            window.confirm(
              `Ledger account is not created for supplier ${values.companyname}, so no payment or receipt can be done. Press 'ok' to create ledger or 'cancel' to continue`
            )
          ) {
            this.props.navigate("/newparty", {
              id: -1,
              name: "New Party Ledger",
              lv,
            });
          }
        } else {
          alert(
            `Ledger account is not created for supplier ${values.companyname}, so no payment or receipt can be done`
          );
        }
      }
      this.setState({
        supplierid: values.id,
        supplier: values.companyname,
        ledgerid: values.ledgerid,
      });
    } else {
      this.setState({
        supplierid: "",
        supplier: "",
        ledgerid: "",
      });
    }
  };

  handleCategory = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        catid: values.id,
        category: values.category,
        filteritemlist: this.state.itemslist.filter(
          (e) => e.catid === values.id
        ),
        itemid: "",
        itemname: "",
        hsn: "",
        uom: "",
        uomid: "",
        cost: "",
        tax: "",
        quantity: "",
      });
    } else {
      this.setState({
        catid: "",
        category: "",
        filteritemlist: [],
        itemid: "",
        itemname: "",
        hsn: "",
        uom: "",
        uomid: "",
        cost: "",
        tax: "",
        quantity: "",
      });
    }
  };

  handleitem = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        itemid: values.id,
        itemname: values.name,
        cost: values.cost,
        hsn: values.hsn,
        uom: values.uomname,
        uomid: values.uom,
        tax: values.tax,
        taxamount:0,
        quantity: 0,
        totalprice: "",
        additionalparams: values.additionalparams,
      });
    } else {
      this.setState({
        itemid: "",
        itemname: "",
        cost: 0,
        uom: "",
        uomid: "",
        hsn: "",
        tax: "",
        quantity: 0,
        totalprice: 0,
      });
    }
  };

  handleexpecteddeliveryChange = (date) => {
    this.setState({ expecteddelivery: date });
  };

  handlepoitemstatus = (e) => {
    this.setState({ poitemstatus: e.target.value });
  };

  handleSubmit = () => {
    if (this.state.tradetype === "Sales") {
      let itemslist = [
        {
          id: this.state.iid,
          itemid: parseFloat(this.state.itemid),
          name: this.state.itemname,
          hsn: this.state.hsn,
          price: parseFloat(this.state.cost),
          salesenum: "LIFO",
          quantity: parseFloat(this.state.quantity),
          pricevariance: 0,
          totalprice: this.state.totalprice,
          tax: parseFloat(this.state.tax),
          taxamount:
            (parseFloat(this.state.tax) *
              (this.state.cost * parseFloat(this.state.quantity))) /
            100,
          totalpricewtax:
            this.state.cost * parseFloat(this.state.quantity) +
            (parseFloat(this.state.tax) *
              (this.state.cost * parseFloat(this.state.quantity))) /
              100,
          size: this.state.size,
          brand: this.state.brand,
          model: this.state.model,
          uom: this.state.uom,
          colour: this.state.colour,
          thickness: this.state.thickness,
          totalmtrroll: this.state.totalmtrroll,
          description: this.state.description,
          catid: this.state.catid,
          category: this.state.category,
          additionalparams: this.state.additionalparams,
          insurancereq: false,
          insurance: 0,
          insuranceamount: 0,
          discount: 0,
          discountamount: 0,
        },
      ];

      let data = {
        soid: this.state.id,
        orderfrom: this.state.orderfrom,
        quotation: this.state.quotation === "" ? null : this.state.quotation,
        agentreq: this.state.agentreq,
        agentid: this.state.agentid,
        agent:
          this.state.agentid === "" || this.state.agentid === undefined
            ? null
            : this.state.agentid,

        sotype: 0,
        creditstatus: "NotRequired",
        customer: this.state.customer,
        custid: this.state.custid,
        status: 0,
        creditperiod: 0,
        creditvalue: 0,
        servicel: [],
        gsttype: this.state.gsttype === false ? 0 : 1,
        grandtotal:
          this.state.cost * parseFloat(this.state.quantity) +
          (parseFloat(this.state.tax) *
            (this.state.cost * parseFloat(this.state.quantity))) /
            100,
        grandservicetotal: 0,
        whid: this.state.whid,
        createdby: userid,
        updatedby: userid,
        tenantid: currentTenant,
        totaltaxamount:
          (parseFloat(this.state.tax) *
            (this.state.cost * parseFloat(this.state.quantity))) /
          100,
        paymentterms: this.state.paymentterms,
        itemlist: itemslist,
        advancepaymentreq: 0,
        advanceamountreq: 0,
        directso: true,
        // currencyid: '',
        currency: currency,
        curconversionrate: 1,
        taxrequired: true,
        taxtype: this.state.taxtype === "GST" ? 0 : 1,
        totalinsuranceamount: 0,
        totaldiscount: 0,
        expecteddelivery: this.state.expecteddelivery,
        graceperiod: this.state.graceperiod,
      };

      Swal.fire({
        title: "Save Direct Sale?",
        text: "Are you sure you want to Add Direct Sale?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          SalesServices.NewOrdersssssssss(data).then(() => {
            this.setState({ trade: false });
            Swal.fire({
              title: "Direct Sale Saved successfully",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => this.refresh());
          });
        }
      });
    } else {
      let data = [
        {
          id: this.state.iid,
          indentitemid: this.state.indentitemid,
          itemid: this.state.itemid,
          name: this.state.itemname,
          hsn: this.state.hsn,
          price: parseFloat(this.state.cost),
          quantity: parseFloat(this.state.quantity),
          uom: this.state.uom,
          uomid: parseInt(this.state.uomid),
          catid: this.state.catid,
          category: this.state.category,
          tax: parseFloat(this.state.tax),
          taxamount:
            (parseFloat(this.state.tax) *
              (this.state.cost * parseFloat(this.state.quantity))) /
            100,
          totalprice:
            this.state.cost * parseFloat(this.state.quantity) +
            (parseFloat(this.state.tax) *
              (this.state.cost * parseFloat(this.state.quantity))) /
              100,
          itemprice: this.state.totalprice,
          poitemstatus: this.state.poitemstatus,
          poitemtype: "0",
          parameters: this.state.additionalparams,
          brand: this.state.brand,
          size: this.state.size,
          colour: this.state.colour,
          thickness: this.state.thickness,
          totalmtrroll: this.state.totalmtrroll,
        },
      ];

      let podata = {
        poid: this.state.id,
        agent: this.state.agentid,
        orderfrom: 0,
        indentid: "",
        potype: 0,
        importpo: false,
        supplier: this.state.supplier,
        supplierid: this.state.supplierid,
        grandtotal: data.find((e) => e).totalprice,
        whid: this.state.whid,
        advanceamountreq: 0,
        advancepaymentreq: 0,
        createdby: userid,
        updatedby: userid,
        tenantid: currentTenant,
        status: 0,
        totaltaxamount: data.find((e) => e).taxamount,
        paymentterms: 0,
        podata: data,
        servicel: [],
        grandservicetotal: 0,
        taxtype: this.state.taxtype,
        taxrequired: true,
        gsttype: this.state.gsttype === false ? 0 : 1,
        porecurring: "Onetime",
        recurringtype: "",
        postartdate: new Date(),
        poenddate: new Date(),
        tcsreq: 0,
        tcs: 0,
        tcsamount: 0,
        currency: currency,
        curconversionrate: 1,
        directpo: true,
        customPOdate:false
      };

      Swal.fire({
        title: "Save Direct Purchase?",
        text: "Are you sure you want to Add Direct Purchase?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor: "#152f5f",
        cancelButtonText: "No",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          PurchaseServices.NewOrder(podata).then(() => {
            this.setState({ trade: false });
            Swal.fire({
              title: "Direct Purchase Saved successfully",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => this.refresh());
          });
        }
      });
    }
  };

  handleConfirm = (id) => {
    let statusdata = {
      id: id,
      status: 1,
      updatedby: userid,
    };
    let postatusdata = {
      id: id,
      postatus: 1,
      updatedby: userid,
    };

    Swal.fire({
      title: `Confirm Direct ${this.state.tradetype}?`,
      text: "Are you sure you want to confirm?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        (this.state.tradetype === "Sales"
          ? SalesServices.statusData(statusdata)
          : PurchaseServices.statusData(postatusdata)
        )
          .then((response) => {
            Swal.fire({
              title: `Direct ${this.state.tradetype} confirmed successfully`,
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => this.refresh())
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });
  };

  handleDelete = (id) => {
    let statusdata = {
      id: id,
      postatus: 3,
      updatedby: userid,
    };
    Swal.fire({
      title: `Delete Direct ${this.state.tradetype}?`,
      text: "Are you sure you want to Delete?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        (this.state.tradetype === "Sales"
          ? SalesServices.DeleteSaleOrder(id)
          : PurchaseServices.statusData(statusdata)
        ).then(() => {
          this.setState({ trade: false });
          Swal.fire({
            title: `Direct ${this.state.tradetype} Deleted successfully`,
            text: ".",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          }).then(() => this.refresh());
        });
      }
    });
  };

  refresh = () => {


      this.setState({
        agent: "",
        agentid: "",
        customer: "",
        custid: "",
        supplier: "",
        supplierid: "",
        category: "",
        categoryid: "",
        itemname: "",
        itemid: "",
        cost: 0,
        quantity: 0,
        hsn: "",
        uom: "",
        uomid: "",
        tax: "",
        currency: "",
      currencyid: "",
      whid:'',
      totalprice:0,
      graceperiod: 0,
      })

    SalesServices.getAllDirectSales(currentTenant).then((res) => {
      this.setState({ directsales: res.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)) });
    });

    PurchaseServices.getAllDirectPurchase(currentTenant).then((res) => {
      this.setState({ directpurchase: res.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)) });
    });

    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
    }
    SalesServices.getSalesReport(datadao)
      .then((response) => {
        this.setState(
          {
            filterdata: response.data.filter(
              (e) =>
                moment(e.createdAt).format("YYYY-MM-DD") ===
                moment(this.state.fromdate).format("YYYY-MM-DD")
            ).sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
            loading: false,
          },
          () =>
            this.setState({
              salesquantity: this.state.filterdata.reduce(
                (total, a) => (total = total + a.quantity),
                0
              ),
            })
        );
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    PurchaseServices.getPurchaseReport(datadao).then((response) => {
      this.setState(
        {
          purchasefilterdata: response.data.filter(
            (e) =>
              moment(e.createdAt).format("YYYY-MM-DD") ===
              moment(this.state.fromdate).format("YYYY-MM-DD")
          ),
          loading: false,
        },
        () =>
          this.setState({
            purchasequantity: this.state.purchasefilterdata.reduce(
              (total, a) => (total = total + a.quantity),
              0
            ),
          })
      );
    });

    MasterService.GetAllCurrency().then((response) => {
      this.setState({ currencylist: response.data });
    });

    AgentServices.getAgents(currentTenant).then((res) => {
      this.setState({ agentsdata: res.data });
    });

    InventoryService.retriveAllItems(currentTenant).then((response) => {
      this.setState({
        itemslist: response.data.sort((a, b) => (a.name > b.name ? 1 : -1)),
        openingstock: response.data.reduce(
          (total, a) => (total = total + a.openingstock),
          0
        ),
        closingstock: response.data.reduce(
          (total, a) => (total = total + a.closingstock),
          0
        ),
      });
    });

    SalesServices.retriveAllCustomers(currentTenant).then((res) =>
      this.setState({
        customers: res.data,
      })
    );
    CategoryServices.getCategory(currentTenant).then((response) =>
      this.setState({
        CategoryList: response.data.filter((e) => e.categorytype === "Product"),
      })
    );

    PurchaseServices.GetAllSellers(currentTenant).then((res) =>
      this.setState({
        suppliers: res.data,
      })
    );

    UserService.retriveUser(userid).then(res=>this.setState({whid:res.data.user.whid}))
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.refresh();
  }

  validate(values) {
    let errors = {};
    if (values.from === null) {
      alert("Please Select From date");
      errors.from = "Please Select From date";
    }

    if(this.state.whid === nulll){
      alert("Please Add warehoue for the user in User Update");
    }
    return errors;
  }
  render() {
    let {
      agentreq,
      agentsdata,
      agent,
      agentid,
      customers,
      customer,
      custid,
      suppliers,
      supplier,
      supplierid,
      CategoryList,
      category,
      categoryid,
      itemslist,
      filteritemlist,
      itemname,
      itemid,
      cost,
      hsn,
      uom,
      quantity,
      totalprice,
      expecteddelivery,
      graceperiod,
      days,
    } = this.state;

    const columns = [
      {
        name: "id",
        lable: "id",
        options: {
          filter: false,
          viewColumns: false,
          sort: false,
          display: false,
        },
      },
      {
        name: "customid",
        label: "Id",
        options: { filter: true, sort: true },
      },
      {
        name: "customer",
        label: "Customer",
        options: { filter: true, sort: true },
        
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => value===null||value===undefined || value===""?"Direct":value
        },
      },
      {
        name: "itemname",
        label: "Item name",
        options: { filter: false, sort: true },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: false, sort: true },
      },
      {
        name: "uom",
        label: "UOM",
        options: { filter: false, sort: true },
      },
      {
        name: "price",
        label: "Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalprice",
        label: "Total Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "taxamount",
        label: "Tax Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "pickupdate",
        label: "Delivery/Pickup date",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY");
          },
        },
      },
      {
        name: "graceperiod",
        label: "Grace Period",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY");
          },
        },
      },
      {
        name: "status",
        label: "ACTIONS",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: salesid === "2" || salesid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>{value==="Pending"?
              <>
                <span
                  as="button"
                  className="textColour mx-2 cursorPointer"
                  onClick={() =>
                    this.handleConfirm(tableMeta.rowData.find((a) => a))
                  }
                >
                  <i className="fa fa-check" />
                </span>

                <span
                  as="button"
                  className="text-danger cursorPointer"
                  onClick={() =>
                    this.handleDelete(tableMeta.rowData.find((a) => a))
                  }
                >
                  <i className="fa fa-trash" />
                </span>
              </>:value
          }</>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {salesid === "2" || salesid === "3" ? (
              <span
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                onClick={() =>
                  this.setState({ trade: true, tradetype: "Sales", id: -1 })
                }
              >
                <CustomToolbar />
              </span>
            ) : (
              ""
            )}
          </>
        );
      },
    };

    const pcolumns = [
      {
        name: "id",
        lable: "id",
        options: {
          filter: false,
          viewColumns: false,
          sort: false,
          display: false,
        },
      },
      {
        name: "customid",
        lable: "Id",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "supplier",
        label: "Supplier",
        options: { filter: false, sort: true },
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => value===null||value===undefined || value===""?"Direct":value
        },
      },
      {
        name: "itemname",
        label: "Item name",
        options: { filter: false, sort: true },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "uom",
        label: "UOM",
        options: { filter: false, sort: true },
      },
      {
        name: "price",
        label: "Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalprice",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "taxamount",
        label: "Tax Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "poitemtype",
        label: "Type",
        options: {
          filter: false,
          sort: true,
        }
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY");
          },
        },
      },
      {
        name: "status",
        label:"Actions",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: purchaseid === "2" || purchaseid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
             <>{value==="Pending"?
              <>
                <span
                  as="button"
                  className="text-Colour cursorPointer mx-2"
                  onClick={() =>
                    this.handleConfirm(tableMeta.rowData.find((a) => a))
                  }
                >
                  <i className="fa fa-check" />
                </span>

                <span
                  as="button"
                  className="text-danger cursorPointer"
                  onClick={() =>
                    this.handleDelete(tableMeta.rowData.find((a) => a))
                  }
                >
                  <i className="fa fa-trash" />
                </span>
              </>:value
                }
                </> 
            );
          },
        },
      },
    ];

    const options2 = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {salesid === "2" || salesid === "3" ? (
              <span
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                onClick={() =>
                  this.setState({ trade: true, tradetype: "Purchase", id: -1 })
                }
              >
                <CustomToolbar />
              </span>
            ) : (
              ""
            )}
          </>
        );
      },
    };
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item active">Direct Trade</li>
            </ol>
            <div className="card-body">
              <div>
                {this.state.loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <>
                    <div className="form-row g-0">
                      <div className="form-group col-md-12 text-sm" />

                      <Col sm={6} lg={3}>
                        <div className={`stats-card mb-4`}>
                          {this.state.loading === true ? (
                            <span className="d-flex justify-content-center">
                              <Spinner animation="border" size="lg" />
                            </span>
                          ) : (
                            <span
                              as="button"
                              onClick={() =>
                                this.setState({
                                  showmodel: !this.state.showmodel,
                                })
                              }
                            >
                              <h3>
                                <CountUp
                                  start={0}
                                  end={this.state.openingstock}
                                  duration={1.5}
                                  className={`${this.state.openingstock<0?'text-danger':'text-success'}`}
                                />
                              </h3>
                              <p className="text-dark">
                                Opening Stock{" "}
                                <i className="fas fa-link text-dark"></i>
                              </p>
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col sm={6} lg={3}>
                        <div className={`stats-card mb-4`}>
                          {this.state.loading === true ? (
                            <span className="d-flex justify-content-center">
                              <Spinner animation="border" size="lg" />
                            </span>
                          ) : (
                            <>
                              <h3>
                                <CountUp
                                  start={0}
                                  end={this.state.salesquantity}
                                  duration={1.5}
                                  className="text-blue"
                                />
                              </h3>
                              <p>Sales</p>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col sm={6} lg={3}>
                        <div className={`stats-card mb-4`}>
                          {this.state.loading === true ? (
                            <span className="d-flex justify-content-center">
                              <Spinner animation="border" size="lg" />
                            </span>
                          ) : (
                            <>
                              <h3>
                                <CountUp
                                  start={0}
                                  end={this.state.purchasequantity}
                                  duration={1.5}
                                  className="text-success"
                                />
                              </h3>
                              <p>Purchase</p>
                            </>
                          )}
                        </div>
                      </Col>
                      <Col sm={6} lg={3}>
                        <div className={`stats-card mb-4`}>
                          {this.state.loading === true ? (
                            <span className="d-flex justify-content-center">
                              <Spinner animation="border" size="lg" />
                            </span>
                          ) : (
                            <span
                              as="button"
                              onClick={() =>
                                this.setState({
                                  showmodel: !this.state.showmodel,
                                })
                              }
                            >
                              <h3>
                                <CountUp
                                  start={0}
                                  end={(this.state.openingstock + this.state.purchasequantity) - this.state.salesquantity }
                                  duration={1.5}
                                  className={`${((this.state.openingstock + this.state.purchasequantity) - this.state.salesquantity)<0?'text-danger':'text-success'}`}
                                />
                              </h3>
                              <p className="text-dark">
                                Closing Stock{" "}
                                <i className="fas fa-link text-dark"></i>
                              </p>
                            </span>
                          )}
                        </div>
                      </Col>

                      <fieldset className="form-group col-md-12">
                        <fieldset
                          className={`form-group col-md-3 shadow-sm  text-center btn ${
                            this.state.tradetype === "Sales"
                              ? "borderedbutton2"
                              : " hovbuttonColour"
                          }`}
                          disabled={this.state.tradetype === "Sales"}
                          onClick={() =>
                            this.setState({
                              tradetype: "Sales",
                              trade: false,
                              agent: "",
                              agentid: "",
                              category: "",
                              catid: "",
                              customer: "",
                              custid: "",
                              graceperiod: "",
                              hsn: "",
                              id: "",
                              itemid: "",
                              itemname: "",
                              pickupdate: "",
                              cost: "",
                              quantity: "",
                              totalprice: "",
                              uom: "",
                              uomid: "",
                            })
                          }
                        >
                          Sales
                        </fieldset>
                        <fieldset
                          className={`form-group col-md-3  btn shadow-sm text-center ${
                            this.state.tradetype === "Purchase"
                              ? "borderedbutton2"
                              : " hovbuttonColour"
                          }`}
                          disabled={this.state.tradetype === "Purchase"}
                          onClick={() =>
                            this.setState({
                              tradetype: "Purchase",
                              trade: false,
                              agent: "",
                              agentid: "",
                              category: "",
                              catid: "",
                              customer: "",
                              custid: "",
                              graceperiod: "",
                              hsn: "",
                              id: "",
                              itemid: "",
                              itemname: "",
                              pickupdate: "",
                              cost: "",
                              quantity: "",
                              totalprice: "",
                              uom: "",
                              uomid: "",
                            })
                          }
                        >
                          Purchase
                        </fieldset>
                      </fieldset>
                      {this.state.trade === true ? (
                        <div className="form-group col-md-12">
                          <fieldset className="form-group col-md-12 m-0">
                            {/* <FormControl variant="standard" className="w-100"> */}
                            <InputLabel
                              id="demo-simple-select-standard-label"
                              className="m-0"
                            >
                              {this.state.tradetype}
                            </InputLabel>
                            {/* </FormControl> */}
                          </fieldset>
                          <Formik
                            initialValues={{
                              agentreq,
                              agentsdata,
                              agent,
                              agentid,
                              customers,
                              customer,
                              custid,
                              suppliers,
                              supplier,
                              supplierid,
                              CategoryList,
                              category,
                              categoryid,
                              itemslist,
                              filteritemlist,
                              itemname,
                              itemid,
                              cost,
                              hsn,
                              uom,
                              quantity,
                              totalprice,
                              expecteddelivery,
                              graceperiod,
                              days,
                            }}
                            onSubmit={this.onSubmit}
                            validateOnChange={false}
                            validate={this.validate}
                            enableReinitialize={true}
                          >
                            {({ setFieldValue, values }) => (
                              <Form autoComplete="off">
                                <div className="form-row g-0">
                                  <fieldset className="form-group col-md-12 m-0">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          checked={this.state.agentreq}
                                          onChange={() =>
                                            this.setState({
                                              agentreq: !this.state.agentreq,
                                              agent: "",
                                              agentid: "",
                                            })
                                          }
                                          name="ordertype"
                                          color="primary"
                                          // disabled={this.state.orderfrom === 1}
                                        />
                                      }
                                      label="Through Agent?"
                                    />
                                  </fieldset>

                                  {this.state.agentreq === true ? (
                                    <fieldset className="form-group col-md-3 mb-4">
                                      <Autocomplete
                                        id="subaccount"
                                        className="subacc-select"
                                        name="agent"
                                        options={agentsdata}
                                        getOptionLabel={(option) => option.name}
                                        
                                        getOptionSelected={(option) =>
                                          option.name
                                        }
                                        value={
                                          values.agent ? undefined : undefined
                                        }
                                        onChange={this.handleAgent}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            className="form-control"
                                            type="text"
                                            name="agent"
                                            value={this.state.agent}
                                            label={`Select Agent (${this.state.agent})`}
                                            variant="standard"
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </fieldset>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.tradetype === "Sales" ? (
                                    <fieldset className="form-group col-md-3 mb-4">
                                      <Autocomplete
                                        id="subaccount"
                                        className="subacc-select"
                                        name="customer"
                                        options={this.state.customers}
                                        getOptionLabel={(option) =>
                                          option.companyname
                                        }
                                        value={
                                          values.customer
                                            ? undefined
                                            : undefined
                                        }
                                        onChange={this.handleCustomer}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            className="form-control"
                                            type="text"
                                            name="customer"
                                            value={this.state.customer}
                                            label={`Select Customer (${this.state.customer})`}
                                            variant="standard"
                                            fullwhidth="true"
                                            error={
                                              this.state.customer === "" &&
                                              this.state.error === true
                                            }
                                          />
                                        )}
                                      />
                                    </fieldset>
                                  ) : (
                                    <fieldset className="form-group col-md-3 mb-4">
                                      <Autocomplete
                                        id="subaccount"
                                        className="subacc-select"
                                        name="supplier"
                                        options={suppliers}
                                        getOptionLabel={(option) =>
                                          option.companyname
                                        }
                                        value={
                                          values.supplier
                                            ? undefined
                                            : undefined
                                        }
                                        onChange={this.handlesupplier}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            className="form-control"
                                            type="text"
                                            name="supplier"
                                            value={this.state.supplier}
                                            label={`Select  Supplier(${this.state.supplier})`}
                                            variant="standard"
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </fieldset>
                                  )}

                                  <fieldset className="form-group col-md-3">
                                    <Autocomplete
                                      id="subaccount"
                                      className="subacc-select"
                                      name="category"
                                      options={CategoryList}
                                      getOptionLabel={(option) =>
                                        option.category
                                      }
                                      
                                      getOptionSelected={(option) =>
                                        option.category
                                      }
                                      value={
                                        values.category ? undefined : undefined
                                      }
                                      onChange={this.handleCategory}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="form-control"
                                          type="text"
                                          name="category"
                                          value={this.state.category}
                                          label={`Select Category (${this.state.category})`}
                                          variant="standard"
                                          fullwhidth="true"
                                        />
                                      )}
                                    />
                                  </fieldset>
                                  <fieldset className="form-group col-md-3 ">
                                    <Autocomplete
                                      id="subaccount"
                                      className="subacc-select"
                                      name="itemname"
                                      options={filteritemlist}
                                      getOptionLabel={(option) => option.name}
                                      
                                      getOptionSelected={(option) =>
                                        option.name
                                      }
                                      value={
                                        values.itemname ? undefined : undefined
                                      }
                                      onChange={this.handleitem}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className="form-control"
                                          type="text"
                                          name="itemname"
                                          value={this.state.itemname}
                                          label={`Select Item (${this.state.itemname})`}
                                          variant="standard"
                                          fullWidth
                                        />
                                      )}
                                    />
                                  </fieldset>
                                  <fieldset className="form-group col-md-3 ">
                                    <TextField
                                      className="form-control"
                                      type="text"
                                      name="cost"
                                      value={this.state.cost}
                                      label={`Price`}
                                      variant="standard"
                                      onChange={(e) =>
                                        this.setState({
                                          cost: e.target.value,
                                          totalprice:
                                            this.state.quantity *
                                            e.target.value,
                                          taxamount: this.state.quantity *
                                          ((e.target.value * this.state.tax) / 100),
                                          totalpricewtax:   (this.state.quantity * e.target.value) + (this.state.quantity *
                                          ((e.target.value * this.state.tax) / 100))
                                        })
                                      }
                                      fullWidth
                                    />
                                  </fieldset>
                                  <fieldset className="form-group col-md-3 ">
                                    <TextField
                                      className="form-control"
                                      type="text"
                                      name="quantity"
                                      value={this.state.quantity}
                                      label={`Quantity`}
                                      variant="standard"
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end">
                                            {this.state.uom}
                                          </InputAdornment>
                                        ),
                                      }}
                                      onChange={(e) =>
                                        this.setState({
                                          quantity: e.target.value,
                                          totalprice:this.state.cost * e.target.value,
                                          taxamount:e.target.value * ((this.state.cost * this.state.tax) / 100),
                                          totalpricewtax:   (e.target.value * this.state.cost) + (e.target.value *
                                          ((this.state.cost * this.state.tax) / 100)) 


                                        })
                                      }
                                      fullWidth
                                    />
                                  </fieldset>
                                  <fieldset className="form-group col-md-3">
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Grid container>
                                        <DatePicker
                                          variant="inline"
                                          id="date-picker-dialog"
                                          label="Pickup Date"
                                          format="dd/MM/yyyy"
                                          minDate={new Date()}
                                          value={this.state.expecteddelivery}
                                          onChange={
                                            this.handleexpecteddeliveryChange
                                          }
                                          renderInput={(params) => <TextField {...params} variant="standard"/>}
                                          autoOk={true}
                                          fullWidth
                                        />
                                      </Grid>
                                    </LocalizationProvider>
                                  </fieldset>
                                  <fieldset className="form-group col-md-3">
                                    <FormControl
                                      sx={{ m: 1, minWidth: 120 }}
                                      className="col-md-12"
                                    >
                                      <InputLabel id="demo-simple-select-filled-label">
                                        Select grace period
                                      </InputLabel>
                                      <Select
                                        value={this.state.graceperiod}
                                        onChange={(e) =>
                                          this.setState({
                                            graceperiod: e.target.value,
                                          })
                                        }
                                      >
                                        {this.state.days.map((option) => {
                                          return (
                                            <MenuItem
                                              key={option}
                                              value={option}
                                            >
                                              {option}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                  </fieldset>
                                  {this.state.tradetype === "Sales" ? "":
                                  <fieldset className="col-md-6">
                            <FormControl className="mt-3">
                              <FormLabel id="demo-row-radio-buttons-group-label">
                                Item For :
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={this.state.poitemstatus}
                                onChange={this.handlepoitemstatus}
                              >
                                {this.state.itemsfor.map((e, index) => {
                                  return (
                                    <FormControlLabel
                                      key={index}
                                      value={e.id}
                                      control={
                                        <Radio style={{ color: "#152f5f" }} />
                                      }
                                      label={e.name}
                                      disabled={this.state.orderfrom === 1}
                                    />
                                  );
                                })}
                              </RadioGroup>
                            </FormControl>
                                </fieldset>
                                  }
                                  <fieldset className="form-group col-md-12"></fieldset>
                                  <fieldset className="form-group col-md-3">
                                    <InputLabel id="demo-simple-select-standard-label">
                                      Total :&nbsp;{" "}
                                      <NumericFormat
                                        displayType={"text"}
                                        value={
                                          this.state.totalprice === ""
                                            ? 0
                                            : this.state.totalprice
                                        }
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </InputLabel>
                                  </fieldset>
                                  {(this.state.customer !=="" || this.state.supplier !== "") && this.state.taxtype !== "NA"?
                                   this.state.taxtype === "GST" && this.state.gsttype === false &&  ccountry ==="India" ?
                                   <>     
                                  <fieldset className="form-group col-md-3">
                                    <InputLabel id="demo-simple-select-standard-label">
                                      CGST ({this.state.tax/2}%) :&nbsp;{" "}
                                      <NumericFormat
                                        displayType={"text"}
                                        value={
                                          this.state.taxamount === ""
                                            ? 0
                                            : this.state.taxamount/2
                                        }
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </InputLabel>
                                  </fieldset>

                                  <fieldset className="form-group col-md-3">
                                    <InputLabel id="demo-simple-select-standard-label">
                                      SGST ({this.state.tax/2}%) :&nbsp;{" "}
                                      <NumericFormat
                                        displayType={"text"}
                                        value={
                                          this.state.taxamount === ""
                                            ? 0
                                            : this.state.taxamount/2
                                        }
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </InputLabel>
                                  </fieldset>
                                  </>:
                                  <fieldset className="form-group col-md-3">
                                    <InputLabel id="demo-simple-select-standard-label">
                                      {this.state.taxtype === "GST" && ccountry ==="India" ?"IGST": this.state.taxtype === "GST"?"GST":"VAT" } ({this.state.tax}%) :&nbsp;{" "}
                                      <NumericFormat
                                        displayType={"text"}
                                        value={
                                          this.state.taxamount === ""
                                            ? 0
                                            : this.state.taxamount
                                        }
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </InputLabel>
                                  </fieldset>
                                  :''
                                    }

                                  <fieldset className="form-group col-md-3">
                                    <InputLabel id="demo-simple-select-standard-label">
                                      Total (Inc. Tax) :&nbsp;{" "}
                                      <NumericFormat
                                        displayType={"text"}
                                        value={
                                          this.state.totalpricewtax === ""
                                            ? 0
                                            : this.state.totalpricewtax
                                        }
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </InputLabel>
                                  </fieldset>

                                  <fieldset className="form-group col-md-12" />
                                  <button
                                    className="btn btn-sm hovbuttonColour"
                                    onClick={this.handleSubmit}
                                    type="button"
                                    disabled={this.state.sloader === true}
                                  >
                                    {this.state.sloader === true ? (
                                      <>
                                        <Spinner
                                          animation="border"
                                          size="sm"
                                          className="mr-1"
                                        />
                                        {"Saving..."}{" "}
                                      </>
                                    ) : (
                                      <>
                                        <i className="fa fa-save mr-1" />{" "}
                                        {"Save"}
                                      </>
                                    )}
                                  </button>
                                  <span
                                    as="button"
                                    className="btn btn-sm ml-1 deletebutton"
                                    onClick={() =>
                                      this.setState({ trade: false })
                                    }
                                  >
                                    <b style={{ color: "#FA5733" }}> X</b> Close
                                  </span>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      ) : (
                        ""
                      )}

                      {this.state.tradetype === "Sales" ? (
                        <div className="form-group col-md-12 text-sm">
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Sales"}
                              data={this.state.directsales}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                        </div>
                      ) : (
                        <div className="form-group col-md-12 text-sm">
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Purchase"}
                              data={this.state.directpurchase}
                              columns={pcolumns}
                              options={options2}
                            />
                          </ThemeProvider>
                        </div>
                      )}
                    </div>
                  </>
                )}



<Modal
                  show={this.state.showmodel}
                  onHide={() =>
                    this.setState({ showmodel: !this.state.showmodel })
                  }
                  backdrop="static"
                  keyboard={false}
                  centered
                  size="lg"
                >
                  <Modal.Header closeButton>Item Stock</Modal.Header>
                  <Modal.Body>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="text-center">Item</TableCell>
                          <TableCell className="text-center">UOM</TableCell>
                          <TableCell className="text-center">Price</TableCell>
                          <TableCell className="text-center">Avilable Stock</TableCell>
                          <TableCell className="text-center">Opening Stock</TableCell>
                          <TableCell className="text-center">Closing Stock</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.itemslist.map((e,i) => (
                          <TableRow key={i}>
                            <TableCell className="text-center">{e.name}</TableCell>
                            <TableCell className="text-center">{e.uomname}</TableCell>
                            <TableCell className="text-right">{e.cost === null || e.cost === undefined?0:
                              <NumericFormat
                                className="pr-3"
                                displayType={"text"}
                                value={e.cost}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            }
                            </TableCell>
                            <TableCell className="text-center">{e.stock === null || e.stock === undefined ?0:e.stock}</TableCell>
                            <TableCell className="text-center">{e.openingstock === null || e.openingstock === undefined ?0:e.openingstock}</TableCell>
                            <TableCell className="text-center">{e.closingstock === null || e.closingstock === undefined ?0:e.closingstock}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Modal.Body>
                </Modal>

              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Directsales);
