import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import moment from "moment";
  import React, { useEffect, useState } from "react";
  import { Navigate, useLocation, useNavigate } from "react-router";
  import { Link } from "react-router-dom";
  
  import {
    address,
    address2,
    ccity,
    ccountry,
    clientname,
    companyemail,
    contactnumber,
    cstate,
    czip,
    logo,
    thousandsdisplay,
    userid,
  } from "../../Common/Credentials";
  import { spin } from "../../Common/NewLoader";
  import Header from "../../Component/Header";
  import Menu from "../../Component/Menu";
  import ProductionServices from "../ProductionServices";
  import jsPDF from "jspdf";
  import autoTable from "jspdf-autotable";
  import {NumericFormat} from "react-number-format";
  import Footer from "../../Component/Footer";
  import Swal from "sweetalert2";
  import { productionid } from "../../Common/Permissions";
  
  const ImmediateDetails = (props) => {
    const history = useLocation();
    const navigate = useNavigate()
    const [id] = useState(history.state);
    const [data, setData] = useState("");
    const [status, setStatus] = useState();
    // const [whlist,setWhList] = useState([]);
    // const [usersList,setUsersList] = useState([])
    // const [storeslist,setStoresList] = useState([])
    useEffect(() => {
      ProductionServices.getImmediateproductionbyId(id).then((response) => {
        setData(response.data);
        setStatus(response.data.status);
        // WarehouseServices.getWarehouses(response.data.tenantid).then(res=>{
        //   setWhList(res.data)
        // })
        // UserService.retriveAllUsers(response.data.tenantid).then(res=>{
        //   setUsersList(res.data)
        // })
        // ProductionServices.GetallStoreNames(response.data.tenantid).then(res=>{
        //   setStoresList(res.data)
        // })
      });
    }, [id]);
  
    const handleApprove = () => {
      let sdata = {
        id: data.id,
        updatedby: userid,
        status: 3,
      };
      Swal.fire({
        title: "Approve Immediate Production?",
        text: "Are you sure you want to Approve?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor:'#152f5f',
        cancelButtonText: "No",
        reverseButtons: true
    }).then((result)=>{
        if (result.value) {
          ProductionServices.UpdateImmediateProductionStatus(sdata).then
          ((response) =>{
            setStatus("Approved")
            Swal.fire({
              title: "Immediate Production Approved Successfully",
              text: ".",
              icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor:'#152f5f',
            })
          })
        }
    });
    };
  
    const handleCancel = () => {
      Swal.fire({
        title: "Cacel Immediate Production?",
        text: "Are you sure you want Cancel?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        confirmButtonColor:'#152f5f',
        cancelButtonText: "No",
        reverseButtons: true
    }).then((result)=>{
        if (result.value) {
          ProductionServices.CancelImmediateProduction(data.id).then
          ((response) =>{
            Swal.fire({
              title: "Immediate Production Cancelled Successfully",
              text: "",
              icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor:'#152f5f',
            }).then(() =>
            navigate("/immediateproduction"))
          })
        }
    });
    };

    // const handleAddstock=()=>{
    //   if(data.tostoretype==="Warehouse Store"){
    //     ProductionServices.AddFinialProductstoStock(data.id).then(()=>{

    //     })
    //   }
    // }
  
    const handlepdfDownload = () => {
      let date = moment(data.createdAt).format("DD-MM-yyyy");
      let bomitems = [
        { header: "Item", dataKey: "itemname" },
        { header: "Quantity", dataKey: "quantity" },
        {header:"Price", dataKey:"price"},
        { header: "UOM", dataKey: "uom" },
      ];
      let finalproducts = [
        { header: "Item", dataKey: "itemname" },
        { header: "Quantity", dataKey: "quantity" },
        { header: "UOM", dataKey: "uom" },
      ];
  
      // let declaration = "Declaration: We declare that the invoice shows the acctual price of the goods described and the particulers are true and correct."
  
      // const toWords = new ToWords();
      // var totalinwords = toWords.convert(data.sototalwtax)
      const doc = new jsPDF("p", "pt", "a4");
      doc.addImage(logo, "GIF", 40, 20, 100, 100);
      doc.setFontSize(16);
      doc.text(`${clientname}`, 560, 40, "right");
      doc.setFontSize(11);
      doc.text(`${address}, ${address2}`, 560, 55, "right");
      doc.text(`${ccity}, ${cstate} - ${czip}`, 560, 70, "right");
      doc.text(`${ccountry}`, 560, 85, "right");
      doc.text(`${companyemail}`, 560, 100, "right");
      doc.text(`${contactnumber}`, 560, 115, "right");
      doc.setFontSize(16);
      doc.text("Immediate Production", 200, 140);
      doc.line(0, 150, 600, 150);
      doc.setFontSize(11);
      doc.text(`ID : ${data.customid}`, 40, 180);
      doc.text(`Date : ${date}`, 40, 195);
      doc.text(`Soid : ${data.soid}`, 40, 210);
      doc.text(`Workcenter : ${data.warehouse}`, 40, 225);
      doc.text(`Workcenter : ${data.workcenter}`, 40, 240);
      doc.text(`Amount : ${parseFloat(data.appxamount).toFixed(2)}`,40,255)
     
  
      // let context=doc.splitTextToSize(this.state.templete.content,510);
      // doc.text(context,40,300)
      doc.text(`Bom Items`, 40, 350);
      autoTable(doc, {
        theme: "grid",
        startY: 360,
        body: data.bomitems,
        columns: bomitems,
      });
  
      doc.text(`Final Products`, 40, doc.autoTable.previous.finalY + 30);
      autoTable(doc, {
        theme: "grid",
        startY: doc.autoTable.previous.finalY + 40,
        body: data.finalproduct,
        columns: finalproducts,
      });
  
      doc.text("Sd/-.", 40, doc.autoTable.previous.finalY + 50);
      doc.text("Production Team,", 40, doc.autoTable.previous.finalY + 65);
      doc.text(`${clientname}`, 40, doc.autoTable.previous.finalY + 80);
      doc.save(`Immediateproduction-${data.customid}.pdf`);
    };
  
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <span
                  as="button"
                  className=" text-secondary cursorPointer"
                  onClick={() => navigate(-1)}
                >
                  ❮ Back &nbsp;&nbsp;
                </span>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/immediateproduction">Immediate Production</Link>
              </li>
              <li className="breadcrumb-item active">
                Immediate Production Details
              </li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                {data === "" ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title"> Immediate Production Details</h3>
                      {status === "Cancelled" || status === "Completed" ? (
                        ""
                      ) : (
                        <>
                          <button
                            className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-envelope text-md ml-1"
                            data-tip
                            data-for="mail"
                            onClick={() => this.handleShow()}
                          ></button>
                          <button
                            className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md"
                            data-tip
                            data-for="pdf"
                            onClick={handlepdfDownload}
                          ></button>
                          {/* //<Tooltip id="mail" place="top" effect="solid">
                            Send Mail
                          </Tooltip>
                          //<Tooltip id="pdf" place="top" effect="solid">
                            Download pdf
                          </Tooltip> */}
                        </>
                      )}
                    </div>
                    <div className="card-body">
                      <table className="float-left m-0 mb-3 ">
                        <tbody>
                          <tr>
                            <td>Id</td>
                            <td>:&nbsp; {data.customid}</td>
                          </tr>
                          {data.soid==="NA"?'':
                          <tr>
                            <td>Sale Order Id</td>
                            <td>
                              <Link
                                to={{
                                  pathname: "/saleorderdetails"}}
                                  state={ {id: data.soid, bvstatus: 1 }}
                              >
                                :&nbsp; {data.soid}
                              </Link>
                            </td>
                          </tr>}
                          <tr>
                            <td>Date</td>
                            <td>
                              :&nbsp;{" "}
                              {moment(data.createdAt).format("DD-MM-YYYY hh:mm")}
                            </td>
                          </tr>
                          <tr>
                            <td>From Warehouse</td>
                            <td>:&nbsp; {data.warehouse}</td>
                          </tr>
                          <tr>
                            <td>To Warehouse</td>
                            <td>:&nbsp; {data.towarehouse}</td>
                          </tr>
                          {data.workcenter===null || data.workcenter === undefined || data.workcenter ===""?
                          "":<tr>
                           <td>From Workcenter </td>
                           <td>:&nbsp; {data.workcenter}</td>
                         </tr> }
                          <tr>
                            <td>To Workcenter </td>
                            <td>:&nbsp; {data.toworkcenter}</td>
                          </tr>
                          {data.store===null || data.store === undefined || data.store === ""?
                          "":<tr>
                          <td>From Store </td>
                          <td>:&nbsp; {data.storename}</td>
                        </tr>}
                          <tr>
                            <td>To Store </td>
                            <td>:&nbsp; {data.tostorename}</td>
                          </tr>

                          
                          <tr>
                            <td>Approximate Amount</td>
                            <td>:&nbsp; <NumericFormat className="pr-3" displayType={'text'} value={data.appxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                          </tr>
                          <tr>
                            <td>Created by</td>
                            <td>:&nbsp; {data.createdbyname}</td>
                          </tr>
                            {data.status ==="Pending" || data.updatedbyname===""|| data.updatedbyname === null || data.updatedbyname ===undefined?'':
                              <tr>
                                <td>Approved by</td>
                                <td>:&nbsp; {data.updatedbyname}</td>
                              </tr> 
                            }
                        </tbody>
                      </table>
                      <fieldset className="col-md-12"/>
                      <div className="form-row">
                      {data.bomitems.length === 0 ? '':
                      <fieldset className="col-md-6 p-3">
                        <TableContainer>
                          <Typography> Raw Materials List</Typography>
                          <Table className="table-sm bordered">
                            <TableHead >
                              <TableRow>
                                <TableCell className="text-center">
                                  Itemname
                                </TableCell>
                                <TableCell className="text-center">
                                  Price
                                </TableCell>
                                <TableCell className="text-center">
                                  Quantity
                                </TableCell>
                                <TableCell className="text-center">
                                  UOM
                                </TableCell>
                                <TableCell className="text-center">
                                  Total
                                </TableCell>
                                <TableCell className="text-center">
                                  Losses
                                </TableCell>
                                <TableCell className="text-center">
                                  Comment
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.bomitems.map((e, index) => (
                                <TableRow key={index}>
                                  <TableCell className="text-center">
                                    {e.itemname}
                                  </TableCell>
                                  <TableCell className="text-right">
                                  <NumericFormat className="pr-3" displayType={'text'} value={e.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                </TableCell>
                                  <TableCell className="text-center">
                                    {e.quantity}
                                  </TableCell>
                                  <TableCell className="text-center">
                                    {e.uom}
                                  </TableCell>
                                <TableCell className="text-right">
                                <NumericFormat className="pr-3" displayType={'text'} value={e.totalprice} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                </TableCell>
                                <TableCell className="text-center">
                                    {e.losses}
                                  </TableCell>
                                  <TableCell className="text-center">
                                    {e.comment}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </fieldset>
                      }
                      {data.finalproduct.length === 0?'':
                      <fieldset className="col-md-6 p-3">
                        <TableContainer>
                          <Typography>Final/End Products</Typography>
                          <Table className="table-sm">
                            <TableHead>
                              <TableRow>
                                <TableCell className="text-center">
                                  Product
                                </TableCell>
                              
                                <TableCell className="text-center">
                                  Quantity
                                </TableCell>
                                <TableCell className="text-center">
                                  UOM
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {data.finalproduct.map((e, index) => (
                                <TableRow key={index}>
                                  <TableCell className="text-center">
                                    {e.itemname}
                                  </TableCell>
                                  <TableCell className="text-center">
                                    {e.quantity}
                                  </TableCell>
                                  <TableCell className="text-center">
                                    {e.uom}
                                </TableCell>
                              
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </fieldset>
                    }
                    </div>
                    {productionid === '2' || productionid === '3'?<>
                      {status === "Cancelled" || status === "Completed" ? (
                        ""
                      ) : (
                        <>
                          {status === "Pending" ? (
                            <>
                              <fieldset className="col-md-12 mt-3">
                              {/* <Link
                                  className="btn btn-sm hovbuttonColour mr-1"
                                  to={{
                                    pathname: "/newimmediateproduction",
                                    state: {
                                      id: id,
                                      pagename: "Edit Immediate Production",
                                      salestype: false,
                                    },
                                  }}
                                >
                                  <i className="fa fa-edit mr-1" />
                                  Edit
                                </Link> */}
                                {productionid === '3'?
                                <button
                                  className="btn btn-sm hovbuttonColour mr-1"
                                  onClick={handleApprove}
                                >
                                  <i className="fa fa-check mr-1" />
                                  Approve & Complete
                                </button>
                                :''}
                                <button
                                  className="btn btn-sm deletebutton"
                                  onClick={handleCancel}
                                >
                                  <i className="fa fa-trash m-0" /> Cancel
                                </button>
                              </fieldset>
                            </>
                          ) :''}
                        </>
                      )}</>:''}
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
        <Footer/>
      </div>
    );
  };
  
  export default ImmediateDetails;
  