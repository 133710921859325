import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import {
  ccountry,
  clientname,
  currentTenant,
  logo,
  thousandsdisplay,
  userid,
} from "../Common/Credentials.js";
import { spin } from "../Common/NewLoader.jsx";
import { inventoryid } from "../Common/Permissions.js";
import InventoryService from "./InventoryService.js";
import MUIDataTable from "mui-datatables";
import CustomToolbar from "../CommonComponents/CustomToolbar.js";

import {NumericFormat} from "react-number-format";
import { ThemeProvider } from "@mui/material";
import { createTheme, IconButton, Tooltip } from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"

import moment from "moment";
import Swal from "sweetalert2";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class ItemsDataTable extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
      items: [],
      tenantdetails: {},
      message: "",
      loading: false,
    };
    this.refreshItems = this.refreshItems.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }

  // handleChange=()=>{
  //   this.setState({producttype:!this.state.producttype})
  // }

  
  pdf() {
    const curdate = moment().format('DD-MM-YYYY HH:mm:ss')
    const columns = [{header:"Item Name",dataKey:'name'},{header:"Item Code",dataKey:'itemcode'},{header:"HSN Code",dataKey:'hsn'},{header:"Tax(%)",dataKey:'tax'},{header:"Total Stock",dataKey:"stock"},{header:"Unit of Measurement",dataKey:"uomname"},{header:"Category",dataKey:'category'},{header:"Latest Purchase Price",dataKey:"cost"}]
    var doc = new jsPDF('p', 'pt',[842, 595]);
    autoTable(doc,{
      theme:'grid',
      startY: 120,
      columnStyles: { 7: { halign: 'right'} },
      didDrawPage: (HookData) => {
        HookData.settings.margin.top = 120;
      },
      body: this.state.items,
      columns:columns,
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;

      doc.addImage(logo, "GIF", 40, 20, 60, 60, { baseline: "top" });
      doc.setFontSize(8);
      doc.text(`${curdate}`,560,20,'right')
      doc.setFontSize(16);
      doc.text(`${clientname}`, 180, 40, { baseline: "top" });
      doc.setFontSize(14);
      doc.text(`Items/Products`, 210, 70, { baseline: "top" });
      doc.line(0, 90, 600, 90);
      doc.setFontSize(10);

      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }
    doc.save("ItemTable.pdf");
  }



  updateItemClicked(id) {
    this.props.navigate({
      pathname: `/newitems`,
      state: {
        id: { id },
      },
    });
  }

  deleteItem(id) {
    Swal.fire({
      title: `Are you sure you want to delete this Item?`,
      text: ".",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor:'#152f5f',
      cancelButtonText: "No",
    }).then((result)=>{
    if(result.value) {
    InventoryService.deleteItem(id).then((resposne) => {
      this.setState({ message: `Item deleted Successfull`, loading: true },() =>
      Swal.fire({
        title: `Item deleted Successfull`,
        text: ".",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
    }).then(()=>{
      InventoryService.retriveAllItems(currentTenant).then((response) => {
        this.setState({ items: response.data.sort((a, b) => a.name > b.name ? 1 : -1)});
        this.setState({ loading: false });
      })
    })
      )
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          Swal.fire({
            title: `Your Session is Lost: Please login Back to resume`,
            text: ".",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
        }).then(()=>this.props.navigate(`/`))
      }else{
        Swal.fire({
          title: `Error occured while deleting`,
          text: ".",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
      })
      }
  })
  }})
  }

  refreshItems() {
    InventoryService.GetTenantdetails(currentTenant).then((res) =>
      this.setState({ tenantdetails: res.data.country })
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
    if (this.state.items.length === 0)
      InventoryService.retriveAllItems(currentTenant).then((response) => {
        this.setState({ items: response.data.sort((a, b) => a.name > b.name ? 1 : -1)});
        this.setState({ loading: false });
      })
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    // if (this.props.history.location.state.producttype===null){
    // }else{
    //   this.setState({producttype:this.props.history.location.state.producttype})
    // }

    this.refreshItems();

    if (this.props.location.state !== undefined) {
      this.setState({ message: this.props.location.state.message });
    }
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Item Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "name",
        label: "Item Name",
        options: { filter: false, sort: true,},
      },
      {
        name: "brand",
        label: "Brand",
        options: { filter: false, sort: true,},
      },
      {
        name: "model",
        label: "Model",
        options: { filter: false, sort: true,},
      },
      {
        name: "itemcode",
        label: "Item Code",
        options: { filter: false, sort: true },
      },
      {
        name: "hsn",
        label: `${ccountry === "India" ? "HSN Code" : "Item Code"}`,
        options: { filter: false, sort: true },
      },
      { name: "tax", label: "Tax (%)", options: { filter: false, sort: true, 
        customBodyRender: (value) => {
          return(<>{value}%</>)
        }
      }},
      {
        name: "stock",
        label: "Total Stock",
        options: { filter: false, sort: true,
        customBodyRender: (value,tableMeta) => {
         return (<>
         {value === null || value === undefined? 
         tableMeta.rowData[9] === null || tableMeta.rowData[9] === undefined ?'N/A':
          <Link className="btn btn-sm textColour" to={{pathname: "/addstock"}} state ={ { id: -1, pagename: "Add Stock" }}>
            <i className="fa fa-plus mr-1"/> Stock
        </Link>:
         <>{value}</>
        
        }</>)
        },
      }
      },
      {
        name: "uomname",
        label: "Unit of Measurement",
        options: { filter: false, sort: true },
      },
      {
        name: "mcategory",
        label: "Category",
        options: { filter: true, sort: true },
      },
      {
        name: "categorylvl2",
        label: "Category level 2",
        options: { filter: true, sort: true },
      },
      {
        name: "category",
        label: "Category level 3",
        options: { filter: true, sort: true },
      },
      
      {
        name: "cost",
        label: "Latest Purchase Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return (<>
              {tableMeta === null || tableMeta === undefined?
              <Link className="btn btn-sm textColour pr-3" to={{pathname: "/addcost"}} state ={ { id: -1, pagename: 'Add Price' }}>
               <i className="fa fa-plus mr-1"/>Price
              </Link>: 
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
              }
           </> );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "description",
        label: "Specification / Description",
        options: { filter: false, sort: false,
          customBodyRender:(value)=>{
            return(<span>{value?.length > 100 ? value.substr(0, 100 - 1) + "..." : value}</span>)
          }
        },
      },

      
      // {name: "createdAt",label: "Date",options: {filter: false,sort: false,sortOrder: 'asc',  display:false,viewColumns:false,
      // customBodyRender:(tableMeta)=>{
      //   return(moment(tableMeta).format('DD-MM-YYYY'))
      // }}},
      {
        name: "Actions",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: inventoryid === "2" || inventoryid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (<>
             
               <Link
                data-tip
                data-for="edit"
                className="fa fa-edit textColour mr-2"
                to={{pathname: "/newitems"}}
                  state={{
                    id: tableMeta.rowData.find((e) => e),
                    pagename: "Update Item"
                }}
              ></Link>
              {this.state.items.find((data) => data.id === tableMeta.rowData.find((e) => e)).cost === null ||
              this.state.items.find((data) => data.id === tableMeta.rowData.find((e) => e)).cost === undefined?
                <span
                className="fa fa-trash text-danger ml-2 cursorPointer"
                data-tip
                data-for="delete"
                onClick={(e) => {
                  const id = tableMeta.rowData.find((e) => e);
                  // if (window.confirm("Are you sure you wish to delete Item?"))
                    this.deleteItem(id);
                  // const data = this.state.items.filter(
                  //   (data) => data.id !== id
                  // );

                  // this.setState({ items: data });
                }}
              ></span>:''}
               {/* <Tooltip id="edit" place="top" effect="solid">
                  Edit
                </Tooltip>
                <Tooltip id="delete" place="top" effect="solid">
                  Delete
                </Tooltip> */}
            </>);
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } })
        },
      },
      // {
      //   name: "Edit",
      //   options: {
      //     filter: false,
      //     sort: false,
      //     empty: true,
      //     display: inventoryid === "2" || inventoryid === "3" ? true : false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
             
      //       );
      //     },
      //     setCellProps: (value) => ({ style: { textAlign: "center" } })
      //   },
      // },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      print:false,
      customToolbar: () => {
        return (
          <>
            {inventoryid === "2" || inventoryid === "3" ? (
              <Link
                className="float-right btn btn-sm m-0 p-0"
                to={{pathname: "/newitems"}}
                  state={{ id: -1, pagename: "New Item"}}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
             <span as="button" onClick={this.pdf.bind(this)}>
             <React.Fragment>
        <Tooltip title={"pdf"}>
          <IconButton>
          <i className="fa fa-file-pdf"></i>
          </IconButton>
        </Tooltip>
      </React.Fragment>

             </span>
          </>
        );
      },
      // setRowProps: row => { 
      //   if ((row[3].substr(0, 10)) === moment().format('DD-MM-YYYY')) {
      //     return {
      //       style: { background: "#ecf8fb" }
      //     };
      //   }
      // }
    };

    return (
      <div>
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/inventory" className="text-info">
                Home
              </Link>
            </li>
            <li className="breadcrumb-item active">Items / Products</li>
          </ol>

          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  {/* /.card */}

                  {/* <div className="card"> */}
                    <div className="card-header m-0 p-2">
                      <div className="text-center text-green">
                        {/* {this.state.message} */}
                      </div>
                    </div>

                    {/* <div className="card-body p-0"> */}
                      <div>
                        {this.state.loading === true ? (
                          <div className="text-center">{spin}</div>
                        ) : (
                          <ThemeProvider theme={theme}> 
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Items / Products"}
                            id="itemstable"
                            data={this.state.items}
                            columns={columns}
                            options={options}
                          />
                          </ThemeProvider>
                        )}
                      </div>
                    {/* </div> */}
                  {/* </div> */}

                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
        </div>
      </div>
    );
  }
}
export default withRouter(ItemsDataTable);
