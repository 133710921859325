import { Field, Form, Formik } from "formik";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import {userid, currentTenant, thousandsdisplay,} from "../../Common/Credentials";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import { Table, TableCell,} from "@mui/material";
import Spinner from 'react-bootstrap/Spinner'
import { TableHead,} from "@mui/material";
import { TableBody } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableContainer } from "@mui/material";
import InventoryService from "../InventoryService.js";
import UserService from "../../users/UserService";
import WarehouseServices from "../../Masters/Warehouse/WarehouseServices";
import {NumericFormat} from "react-number-format";

let datalist = [];
let count = 0;

class acceptingstockissue extends Component {
  constructor(props) {
    super(props);
    // const id = this.props.location.state.id;
    this.state = {
      // id: id,
      // pagename:this.props.location.state.pagename,
      users:[],
      userdata:'',
      warehouses:'',
      stockissue:[],
      itemid: "",
      itemname: "",
      whid:'',
      whaddress:'',
      categoryid:'',
      quantity: "",
      cost:0,
      orderedquantity: "",
      pendingquantity:"",
      acceptedquantity:'',
      returnquantity:'',
      status: "0",
      damagestatus:"0",
      pending:'',
      prstatus:'0',
      show:0,
      accepteditems:[],

    };

    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  removeItemFromList(id) {
    const deleteitem = datalist.filter((item,index)=>item.id === id);
    // deleteitem.map(data=>this.setState({pendingquantity:this.state.pendingquantity + parseInt(data.quantity)}))
    let delquantity = deleteitem.map(data=>data.quantity)
    const newlist = datalist.filter((item, index) => item.id !== id);
    
    this.setState({
      accepteditems: newlist,
    });
    datalist = newlist;
    let itemsdetails = this.state.stockissue.find((data) => data.id === id);
      let deletedata={
        id: itemsdetails.id,
        createdbyname:itemsdetails.createdbyname,
        createdby:itemsdetails.createdby,
        whid: itemsdetails.whid,
        whaddress:itemsdetails.whaddress,
        deptid:itemsdetails.deptid,
        deptname:itemsdetails.deptname,
        catid:itemsdetails.catid,
        category:itemsdetails.category,
        itemid:itemsdetails.itemid,
        itemname:itemsdetails.itemname,
        cost:itemsdetails.cost,
        receivedby:itemsdetails.reciverid,
        totalquantity:itemsdetails.totalquantity,
        pendingquantity: itemsdetails.pendingquantity+parseFloat(delquantity),
              }
           let remlist=this.state.stockissue.filter((data) => data.id !==id)
              remlist.push(deletedata)
              this.setState({stockissue:remlist})
  }

  handleSelect = (e) => {
    this.setState({ status: e.target.value });
  };

  handleAccept = (id) => {
    let itemsdetails = this.state.stockissue.filter((data) => data.id === id);
    this.setState({ quantity: "", description: "",show:1 });
    itemsdetails.map((data) =>
      this.setState(
        {
          id: data.id,
          createdbyname:data.createdbyname,
          createdby:data.createdby,
          whid: data.whid,
          whaddress:data.whaddress,
          deptid:data.deptid,
          deptname:data.deptname,
          catid:data.catid,
          category:data.category,
          cost:data.cost,
          itemid:data.itemid,
          uomid:data.uomid,
          itemname:data.itemname,
          receivedby:data.reciverid,
          orderedquantity:data.totalquantity,
          pending: data.pendingquantity,
        })
    );
  };

  handelSubmit = () => {
    
    this.setState({loader:true})  
    InventoryService.AcceptStockIssue(this.state.accepteditems).then(() => {
    this.setState({loader:false})
    this.props.navigate(`/acceptingstockissuetable`)
    }
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  onSubmit() {
      count = count + 1;

      let list = {
        id: this.state.id,
        createdbyname:this.state.createdbyname,
        createdby:this.state.createdby,
        whid: this.state.whid,
        whaddress:this.state.whaddress,
        deptid:this.state.deptid,
        deptname:this.state.deptname,
        itemid: this.state.itemid,
        itemname: this.state.itemname,  
        catid:this.state.catid,
        category:this.state.category,
        cost:this.state.cost,
        quantity: parseFloat(this.state.quantity),
        acceptreturn: parseInt(this.state.status),
        description: this.state.description,
        returnquantity:this.state.status ==='1'? parseFloat(this.state.quantity):0, 
        acceptedquantity:this.state.status ==='0'? parseFloat(this.state.quantity):0, 
        damagestatus:this.state.status ==='1'?this.state.damagestatus:'',
        receivedby:userid,
        tenantid:currentTenant
      };

      datalist.push(list);
      
      let data = { id: this.state.id,
        createdbyname:this.state.createdbyname,
        createdby:this.state.createdby,
        whid: this.state.whid,
        whaddress:this.state.whaddress,
        deptid:this.state.deptid,
        deptname:this.state.deptname,
        itemid: this.state.itemid,
        itemname: this.state.itemname,  
        catid:this.state.catid,
        cost:this.state.cost,
        category:this.state.category,
        totalquantity: this.state.orderedquantity,
        pendingquantity: this.state.pending - parseFloat(this.state.quantity),
      }
      if(this.state.pendingquantity=== 0){
        this.setState({stockissue:this.state.stockissue.filter((data) => data.id !== this.state.id)})
      }else{
      this.setState({stockissue:this.state.stockissue.filter((data) => data.id !== this.state.id)},
      ()=>this.state.stockissue.push(data)
    
   );
    }
    this.setState({ accepteditems: datalist, quantity: "", description: "",show:0});
   }

  refreshData() {
    WarehouseServices.getWarehouses(currentTenant)
    .then(
      response => {
          this.setState({warehouses:response.data})
        }
      )  
       
    UserService.Userwhanddpt(currentTenant).then(
        response => {
            this.setState({users:response.data,userdata:response.data.filter((e)=>e.id === userid)},()=>
                InventoryService.GetStockIssue(currentTenant).then(
                    response => {
                        this.setState({stockissue:response.data.filter((e)=>e.pendingquantity>0 && ((e.whid ===this.state.userdata.map(e=>e.whid).toLocaleString()) && (e.deptid ===this.state.userdata.map(e=>e.deptid).toLocaleString())))}
                    )} 
                )
            )
        } 
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
    this.setState({loading:false})
  }

  onfocus(){
    document.getElementById('quantity').addEventListener('mousewheel', function(event) {
     if (window.document.activeElement.type === 'number') {
       event.preventDefault()
     }
   })
   document.getElementById('quantity').addEventListener('keydown', function(e) {
    if (e.which === 38 || e.which === 40) {
        e.preventDefault();
    }
  });
   }

  componentDidMount() {
    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    this.refreshData();
    datalist = [];
    count = 0;
  }

  validate(values) {

   let errors = {}

    if (this.state.quantity === '' || this.state.quantity === undefined || this.state.quantity ==='0'||this.state.quantity ===0) {
      errors.quantity = "Quantity is required"
    } 
    if (this.state.quantity > this.state.pending) {
      errors.quantity = `Avilable quantity is ${this.state.pending}`
    }
    if (this.state.description === "") {
      errors.description = "Pelese provide Description"
    }
    return errors;
  }


  render() {
    let {
      sellers,
      poid,
      purchaseorderid,
      sellerid,
      seller,
      itemid,
      itemname,
      quantity,
      pendingquantity,
      status,
      description,
      prstatus,
      show,
      cost,

    
    } = this.state;

    return (
      <div>
        <Header />
        <Menu/>
        <div className="content-wrapper">
          <div className="card">
           
            <ol className="breadcrumb float-sm-right">
              <li>
              <Link
              className="text-secondary"
                to={{ pathname: "/acceptingstockissuetable" }}
              >
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/acceptingstockissuetable">Accept Stock Issue </Link>
              </li>
              <li className="breadcrumb-item active">Accept Stock</li>
            </ol>
            <div className="card-body">
              <Formik
                initialValues={{
                  sellers,
                  poid,
                  purchaseorderid,
                  sellerid,
                  seller,
                  itemid,
                  itemname,
                  quantity,
                  status,
                  pendingquantity,
                  description,
                  prstatus,
                  show,
                  cost,
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({setFieldValue,values}) => (
                  <Form>
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="itemid"
                          placeholder="Id"
                        />
                      </fieldset>
                     
                          <fieldset className="form-group col-md-12"/>
                          {this.state.stockissue.length === 0 ?
                          <h4 className="text-center">No data to show</h4>:
                          <TableContainer >
                          <Table aria-label="customized table" className="table-sm">
                            <TableHead>
                              <TableRow>
                                <TableCell  className="text-center">Item Name</TableCell>
                                <TableCell  className="text-center">From</TableCell>
                                <TableCell  className="text-center">Warehouse/Branch</TableCell>
                                <TableCell  className="text-center">Department/Store</TableCell>
                                <TableCell  className="text-center">Quantity</TableCell>
                                <TableCell  className="text-center">Pending</TableCell>
                                <TableCell  className="text-center">Price</TableCell>
                                <TableCell  className="text-center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.stockissue.map((data, index) => (
                                <TableRow key={index}>
                                  <TableCell  className="text-center">{data.itemname}</TableCell>
                                  <TableCell  className="text-center">{data.createdbyname}</TableCell>
                                  <TableCell  className="text-center">{data.whaddress}</TableCell>
                                  <TableCell  className="text-center">{data.deptname}</TableCell>
                                  <TableCell  className="text-center">{data.totalquantity}</TableCell>
                                  <TableCell  className="text-center">{data.pendingquantity}</TableCell>
                                  <TableCell  className="text-center"> <NumericFormat
                                    className="pr-3"
                                    displayType={"text"}
                                    value={data.cost}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat></TableCell>
                                  <TableCell  className="text-center">
                                    {this.state.accepteditems.map((data)=>data.id).includes(data.id)?'':
                                    <span
                                      as="button"
                                      data-tip
                                      data-for="return"
                                      className="text-primary"
                                      style={{ paddingLeft: "7px" }}
                                      onClick={() => this.handleAccept(data.id)}
                                    >
                                     Accept item
                                    </span>
                                  }
                                  </TableCell>
                                </TableRow>
                              ))}

                            </TableBody>
                          </Table>
                          </TableContainer>
                            }
                      
                      {this.state.show === 0?"":<>
     
                        <div className="form-group col-md-12"> </div>

                        <fieldset className="form-group col-md-3">
                          <span className="text-dark">Item Name</span>
                          <> : &nbsp; {this.state.itemname}</>
                        </fieldset>
                        <fieldset className="form-group col-md-3">
                          <span className="text-dark">Cost</span>
                          <> : &nbsp; <NumericFormat className="pr-3" displayType={"text"} value={this.state.cost} decimalScale={2} fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat></>
                        </fieldset>
                        <fieldset className="form-group col-md-3"><span>{`Remaining Quantity : ${this.state.pending-this.state.quantity}`} </span></fieldset>

                        <fieldset className="form-group col-md-6"/>
                        <fieldset className="form-group col-md-12">
                          <div className=" from-row ">
                            <div className="col-sm-12">
                              <div className="form-check form-check-inline">
                                <span className="radio-inline">
                                  <Field
                                    name="status"
                                    value="0"
                                    id="active"
                                    clicked={this.state.status}
                                    onClick={this.handleSelect}
                                    className="mr-2 leading-tight"
                                    type="radio"
                                  />
                                  Accept
                                </span>
                              </div>
                              <div className="form-check form-check-inline">
                                <span className="radio-inline">
                                  <Field
                                    name="status"
                                    id="inactive"
                                    value="1"
                                    clicked={this.state.status}
                                    onClick={this.handleSelect}
                                    className=" mr-2 leading-tight "
                                    type="radio"
                                  />
                                  Return
                                </span>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        {this.state.status === "1" ? 
                          <>
                         <fieldset className="form-group col-md-3">
                            <span className="text-dark">Reason for Return</span>
                          </fieldset>

                          <fieldset className="form-group col-md-6 ">
                            <div className=" from-row ">
                              <div className="col-sm-12">
                                <div className="form-check form-check-inline">
                                  <span className="radio-inline">
                                    <Field
                                      name="damagestatus"
                                      value="0"
                                      id="active"
                                      clicked={this.state.damagestatus}
                                      onClick={this.handleReturnReason}
                                      className="mr-2 leading-tight"
                                      type="radio"
                                    />
                                    Damaged
                                  </span>
                                </div>

                                <div className="form-check form-check-inline">
                                  <span className="radio-inline">
                                    <Field
                                      name="damagestatus"
                                      id="inactive"
                                      value="1"
                                      clicked={this.state.damagestatus}
                                      onClick={this.handleReturnReason}
                                      className=" mr-2 leading-tight "
                                      type="radio"
                                    />
                                    Other
                                  </span>
                                </div>
                              </div>
                            </div>
                          </fieldset>
                          <fieldset className="form-group col-md-3"></fieldset>

                          </>
                          :""}
                        

                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            id="quantity"
                            className="form-control"
                            type="number"
                            name="quantity"
                            label="Quantity"
                            onChange={(e) => this.setState({ quantity: e.target.value })}
                            onFocus={this.onfocus}
                          />
                        </fieldset>

                        <fieldset className="form-group col-md-3">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="description"
                            label="Description"
                            onChange={(e) =>this.setState({ description: e.target.value })}
                          />
                        </fieldset>
                        <fieldset className="form-group col-md-12"/>
                        <button className="btn btn-sm hovbuttonColour" type ="submit">
                          Submit
                        </button>
                      </>}

                      <div className="form-group col-md-3" />
                      {count === 0 || this.state.accepteditems.length === 0?'': <>
                        <div style={{ paddingTop: "25px" }} className="form-group col-md-12">
                          {this.state.accepteditems === [] || this.state.accepteditems.length === 0?'':<>
                          <span className="form-group col-md-12 ">Items List:</span>
                          <TableContainer >
                          <Table aria-label="customized table" className="table-sm">
                            <TableHead>
                              <TableRow>
                                <TableCell  className="text-center">Item Name</TableCell>
                                <TableCell  className="text-center">From</TableCell>
                                <TableCell  className="text-center">Warehouse</TableCell>
                                <TableCell  className="text-center">Accepted Quantity</TableCell>
                                <TableCell  className="text-center">Returned Quantity</TableCell>
                                <TableCell  className="text-center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.accepteditems.map((data, index) => (
                                <TableRow key={index}>
                                  <TableCell  className="text-center">{data.itemname}</TableCell>
                                  <TableCell  className="text-center">{data.createdbyname}</TableCell>
                                  <TableCell  className="text-center">{data.whaddress}</TableCell>
                                  <TableCell  className="text-center">{data.acceptedquantity===''?0:data.acceptedquantity}</TableCell>
                                  <TableCell  className="text-center">{data.returnquantity===''?0:data.returnquantity}</TableCell>
                                  <TableCell className="text-center">
                                        <span
                                          as="button"
                                          data-tip
                                          data-for="delete"
                                          className="fa fa-trash deletebutton btn btn-sm"
                                          style={{ paddingLeft: "7px" }}
                                          onClick={() => this.removeItemFromList(data.id)}
                                        />
                                        {/* //<Tooltip id="delete" place="top" effect="solid">
                                          Delete
                                        </Tooltip> */}
                                      </TableCell>
                                </TableRow>
                              ))}

                            </TableBody>
                          </Table>
                          </TableContainer>
                          </>}
                        </div>
                    </>}
                      <div className="form-group col-md-3" />
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="form-group col-md-12" />
              {count === 0 || this.state.accepteditems === []?'': <>
                
                {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>:
                 <button className="float-center btn btn-sm hovbuttonColour" onClick={() => this.handelSubmit()}><i className="fa fa-save mr-1"/> Save</button>}
                <Link as="button" className="btn btn-sm deletebutton ml-2" to={{ pathname: "/acceptingstockissuetable" }}>
                  <b className="text-danger mr-1"> X </b> Cancel 
                </Link>
              </>}
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

        <Footer />
      </div>
    );
  }
}
export default withRouter(acceptingstockissue);
