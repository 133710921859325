import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import {
  clientname,
  currentTenant,
  logo,
  thousandsdisplay,
  userid,
} from "../Common/Credentials.js";
import { spin } from "../Common/NewLoader.jsx";
import { assetmanagementid } from "../Common/Permissions.js";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Menu from "../Component/Menu";

import MUIDataTable from "mui-datatables";
import CustomToolbar from "../CommonComponents/CustomToolbar.js";

import {NumericFormat} from "react-number-format";
import { ThemeProvider } from "@mui/material";
import {
  createTheme,
  FormControlLabel,
  IconButton,
  Tooltip,
} from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import AssetsService from "./AssetsService.js";
import moment from "moment";
import IOSSwitch from "../CommonComponents/IOSSwitch.js";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class AssetTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      thirdpartyassets:[],
      rentdata: [],
      rentalquotation:[],
      solddata: [],
      soldquotations:[],
      message: "",
      active: this.props===undefined?true:this.props.location.state.active,
      rent: this.props===undefined?false:this.props.location.state.rent,
      sold: this.props===undefined?false:this.props.location.state.sold,
      loading: false,
      as: true,
      sa: false,
      type: this.props===undefined?'Rental':this.props.location.state.type,   //'Rental',
      stype: this.props===undefined?'Sales':this.props.location.state.stype,  //'Sales'
      assettype:'Own'
    };
  }

  // handleChange=()=>{
  //   this.setState({producttype:!this.state.producttype})
  // }

  pdf(id) {
    const curdate = moment().format('DD-MM-YYYY HH:mm:ss')
    
    let data,columns,title
    if(id === "Assets"){
      title = "Assets"
      data = this.state.data
      data.map(e=>
        data.find((i) => i.id === e.id).createdAt = moment(
        data.find((i) => i.id === e.id).createdAt
        ).format("DD-MM-YYYY HH:mm"))
      columns =  [
        {header:"Id", dataKey:"customid"},
        { header: "Item Name", dataKey: "itemname" },
        {header:"Amount",dataKey:"baseamount"},
        { header: "Asset Type", dataKey: "assettype" },
        { header: "Serial No.", dataKey: "serialno" },
        { header: "Type", dataKey: "type" },
        { header: "Depreciation(%)", dataKey: "depreciation" },
        { header: "Depreciation Period(Years)", dataKey: "depreciationperiod" },
        { header: "Location", dataKey: "location" },
        { header: "Date", dataKey: "createdAt" },
        { header: "Status", dataKey: "assetstatus" },
      ];
    }

    if(id==="Rental" && this.state.type === "Rental"){
      title = "Rental"
      data = this.state.rentdata
      data.map(e=>
        data.find((i) => i.id === e.id).createdAt = moment(
        data.find((i) => i.id === e.id).createdAt
        ).format("DD-MM-YYYY HH:mm"))
      
        columns = [
          {header:"Id", dataKey:"customid"},
          {header:'Quotation',dataKey:'quotation'},
          {header:'Customer',dataKey:'customer'},
          {header:'Amount',dataKey:'totalamountinctax'},
          {header:'Created By',dataKey:'createdby'},
          {header:'Date',dataKey:'createdAt'},
          {header:'Invoice Status',dataKey:'invstatus'},
        ]
    }

    if(id==="Rental" && this.state.type === "Quotation"){
      title = "Rental Quotations"
      data = this.state.rentalquotation
      data.map(e=>
        data.find((i) => i.id === e.id).createdAt = moment(
          data.find((i) => i.id === e.id).createdAt
        ).format("DD-MM-YYYY HH:mm"))

        columns = [
          {header:'Id',dataKey:'id'},
          {header:'Customer',dataKey:'customer'},
          {header:'Amount',dataKey:'totalamountinctax'},
          {header:'Created By',dataKey:'createdby'},
          {header:'Date',dataKey:'createdAt'},
        ]
    }

    if(id==="Sales" && this.state.stype === "Sales"){
      title = "Sales"
      data = this.state.solddata
      data.map(e=>
        data.find((i) => i.id === e.id).createdAt = moment(
        data.find((i) => i.id === e.id).createdAt
        ).format("DD-MM-YYYY HH:mm"))

        columns = [
          {header:"Id", dataKey:"customid"},
          {header:'Quotation',dataKey:'quotation'},
          {header:'Customer',dataKey:'customer'},
          {header:'Amount',dataKey:'totalamountinctax'},
          {header:'Created By',dataKey:'createdby'},
          {header:'Date',dataKey:'createdAt'},
          {header:'Invoice Status',dataKey:'invstatus'},
        ]
    }

    if(id==="Sales" && this.state.stype === "Quotation"){
      title = "Sales Quotations"
      data = this.state.soldquotations
       data.map(e=>
        data.find((i) => i.id === e.id).createdAt = moment(
          data.find((i) => i.id === e.id).createdAt
        ).format("DD-MM-YYYY HH:mm"))

        columns = [
          {header:'Id',dataKey:'id'},
          {header:'Customer',dataKey:'customer'},
          {header:'Amount',dataKey:'totalamountinctax'},
          {header:'Created By',dataKey:'createdby'},
          {header:'Date',dataKey:'createdAt'},
        ]
    }

    var doc = new jsPDF('p', 'pt',[842, 595]);
    autoTable(doc,{
      theme:'grid',
      startY: 120,
      columnStyles: { 1: { halign: 'right'} },
      didDrawPage: (HookData) => {
        HookData.settings.margin.top = 120;
      },
      body: data,
      columns:columns,
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;

      doc.addImage(logo, "GIF", 40, 20, 60, 60, { baseline: "top" });
      doc.setFontSize(8);
      doc.text(`${curdate}`,560,20,'right')
      doc.setFontSize(16);
      doc.text(`${clientname}`, 180, 40, { baseline: "top" });
      doc.setFontSize(14);
      doc.text(`${title}`, 210, 70, { baseline: "top" });
      doc.line(0, 90, 600, 90);
      doc.setFontSize(10);

      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }
    doc.save(`${title}.pdf`);
    this.Refresh()
  }

  updateItemClicked(id) {
    this.props.navigate({
      pathname: `/newitems`},
     {state: {
        id: { id },
      },
    });
  }

  handleactiveChange = () => {
    this.setState({ active: true, rent: false, sold: false });
  };
  handlerentChange = () => {
    this.setState({ rent: true, active: false, sold: false });
  };
  handlesoldChange = () => {
    this.setState({ sold: true, rent: false, active: false });
  };

  componentDidMount() {
    
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.Refresh()
    // if (this.props !== undefined) {
    //   this.setState({ message: this.props.location.state.message });
    // }
  }

  Refresh=()=>{
    
    AssetsService.getallAssets(currentTenant).then((response) => {
      this.setState({ data: response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)), loading: false });
    });

    AssetsService.GetThirdpartyassets(currentTenant).then((response) => {
      this.setState({thirdpartyassets: response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))})
    });

    AssetsService.GetRentassetsoftTenant(currentTenant).then((response) => {
      this.setState({ rentdata: response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)) });
    });
    AssetsService.GetSoldassetsoftTnant(currentTenant).then((response) => {
      this.setState({ solddata: response.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)) });
    });

    AssetsService.GetSoldQuotationofTenant(currentTenant).then(res=>
      this.setState({soldquotations:res.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))})
      )

      AssetsService.GetRentalQuotationofTenant(currentTenant).then(res=>
        this.setState({rentalquotation:res.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))})
        )

  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display:false,
          viewColum:false,
        },
      },
      {
        name: "customid",
        label: "Id",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "itemname",
        label: "Item Name",
        options: { filter: false, sort: true },
      },
      {
        name: "category",
        label: "Category",
        options: { filter: false, sort: true },
      },
      {
        name: "assettype",
        label: "Asset Type",
        options: { filter: false, sort: true },
      },
      {
        name: "serialno",
        label: "Serial No.",
        options: { filter: true, sort: true },
      },
      {
        name: "warehouse",
        label: "Warehouse",
        options: { filter: false, sort: true },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: false, sort: true },
      },
      {
        name: "baseamount",
        label: "Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "type",
        label: "Type",
        options: { filter: true, sort: true },
      },
      {
        name: "depreciation",
        label: "Depreciation per Year (%)",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <span>{value}%</span>;
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "depreciationperiod",
        label: "Depreciation Period In Year",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return <span>{value}&nbsp;Years</span>;
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {
        name: "location",
        label: "Location",
        options: { filter: true, sort: true },
      },
      {
        name: "user",
        label: "User",
        options: { filter: true, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:mm");
          },
        },
      },
      {
        name: "assetstatus",
        label: "Status",
        options: { filter: true, sort: true },
      },
      {
        name: "Action ",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                {/* <Link
                as="button"
                data-tip
                data-for="edit"
                className="fa fa-edit textColour mr-2"
                to={{
                  pathname: "/newasset",
                  state: {
                    id: tableMeta.rowData.find((e) => e),
                    pagename: "Update Item",
                  },
                }}
              ></Link>
              | */}
                <Link
                  as="button"
                  data-tip
                  data-for="view"
                  className="fa fa-list-alt textColour textColour ml-2"
                  to={{pathname: "/assetdetails"}}
                  state={{data:this.state.data.find((e) => e.id === tableMeta.rowData[0]),stype:this.state.stype,type:this.state.type,sold:this.state.sold,rent:this.state.rent,active:this.state.active}}
                ></Link>
                {/* //<Tooltip id="edit" place="top" effect="solid">
                  Edit
                </Tooltip> */}
                {/* <Tooltip id="view" place="top" effect="solid">
                  View
                </Tooltip> */}
              </>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
    ];


    const thirdpartyassetscolumns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display:false,
          viewColum:false,
        },
      },
      {
        name: "customid",
        label: "Id",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: "itemname",
        label: "Item Name",
        options: { filter: false, sort: true },
      },
      {
        name: "category",
        label: "Category",
        options: { filter: false, sort: true },
      },
      {
        name: "assettype",
        label: "Asset Type",
        options: { filter: false, sort: true },
      },
      {
        name: "warehouse",
        label: "Warehouse",
        options: { filter: false, sort: true },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: false, sort: true },
      },
      {
        name: "baseamount",
        label: "Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "location",
        label: "Location",
        options: { filter: true, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:mm");
          },
        },
      },
      {
        name: "assetstatus",
        label: "Status",
        options: { filter: true, sort: true },
      },
      {
        name: "Action ",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view"
                  className="fa fa-list-alt textColour textColour ml-2"
                  to={{pathname: "/assetdetails"}}
                  state={{data:this.state.thirdpartyassets.find((e) => e.id === tableMeta.rowData[0]),stype:this.state.stype,type:this.state.type,sold:this.state.sold,rent:this.state.rent,active:this.state.active,assettype:this.state.assettype}}
                ></Link>
              </>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      // print: false,
      customToolbar: () => {
        return (
          <>
          {assetmanagementid === "2" || assetmanagementid === "3" ?
            <Link
              as="button"
              className="float-right btn btn-sm m-0 p-0"
              to={{pathname: this.state.assettype==="Own"?"/newasset":"/newthirdpartyasset"}}
                state={{ id: -1, pagename: "New Assets",stype:this.state.stype,type:this.state.type,sold:this.state.sold,rent:this.state.rent,active:this.state.active 
              }}
            >
              <CustomToolbar />
            </Link>
            :''}

            {/* <span as="button" onClick={()=>this.pdf("Assets")}>
              <React.Fragment>
                <Tooltip title={"pdf"}>
                  <IconButton>
                    <i className="fa fa-file-pdf"></i>
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            </span> */}
          </>
        );
      },
      setRowProps: row => { 
        if (row[15] === "OnTrip" ) {
          return {
            style: { background: "#ecf8fb" }
          };
        }
        if (row[15] === "Maintenance") {
          return {
            style: { background: "#f7dddd" }
          };
        }
      },
    };

    const rentcolumns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: this.state.type === "Quotation"?true:false,
          sort: this.state.type === "Quotation"?true:false,
          display:this.state.type === "Quotation"?true:false,
          viewColum:this.state.type === "Quotation"?true:false,
        },
      },
      {
        name:"customid",
        label:"Id",
        options: {
          filter: false,
          sort: true,
          display:this.state.type === "Rental"?true:false,
        }
      },
      {
        name: "quotation",
        label: "Quotation",
        options: {
          filter: false,
          sort: true,
          display:this.state.type === "Rental"?true:false,
          customBodyRender:(value)=>value === null?"Direct":value
        },
      },

      {
        name: "customer",
        label: "Customer",
        options: { filter: false, sort: true },
      },
      {
        name: "totalamountinctax",
        label: "Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "createdby",
        label: "Created By",
        options: { filter: false, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return moment(value).format("DD-MM-YYYY HH:mm");
          },
        },
      },
      {
        name: "invstatus",
        label: "Invoice Status",
        options: { filter: false, sort: true,display:this.state.type === "Rental"?true:false },
      },
      {
        name: "Action ",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view"
                  className="fa fa-list-alt textColour ml-2"
                  to={{pathname: "/assetdetails2"}}
                    state={{
                      data:this.state.type==="Rental"?this.state.rentdata.find((e) => e.id === tableMeta.rowData[0])
                      :this.state.rentalquotation.find(e=>e.id===tableMeta.rowData[0]),
                      pagename: this.state.type==="Rental"?"Rental Details":"Rental Quotation Details",
                      stype:this.state.stype,type:this.state.type,sold:this.state.sold,rent:this.state.rent,active:this.state.active
                  }}
                ></Link>
                {assetmanagementid === "2" || assetmanagementid === "3" ?<>
                {this.state.type ==="Rental"?'':
                 <Link
                 as="button"
                 data-tip
                 data-for="view"
                 className="fa fa-edit textColour ml-2"
                 to={{pathname: "/rentasset"}}
                   state={{
                     data:this.state.rentalquotation.find(e=>e.id===tableMeta.rowData[0]),
                     pagename: "Edit Rental Quotation",
                     stype:this.state.stype,type:this.state.type,sold:this.state.sold,rent:this.state.rent,active:this.state.active
                 }}
               ></Link>}
               </>:''}
              </>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
    ];

    const rentoptions = {
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      customToolbar: () => {
        return (
          <>
          {assetmanagementid === "2" || assetmanagementid === "3" ?
            <Link
              as="button"
              className="float-right btn btn-sm m-0 p-0"
              to={{pathname: "/rentasset"}}
                state={{ data: "", pagename: this.state.type ==="Rental"?"New Rent Asset":"New Rental Quotation",
                stype:this.state.stype,type:this.state.type,sold:this.state.sold,rent:this.state.rent,active:this.state.active 
              }}
            >
              <CustomToolbar />
            </Link>
              :''}
            <span as="button" onClick={()=>this.pdf("Rental")}>
              <React.Fragment>
                <Tooltip title={"pdf"}>
                  <IconButton>
                    <i className="fa fa-file-pdf"></i>
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            </span>
          </>
        );
      },
    };

    const soldcolumns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: this.state.stype === "Quotation"?true:false,
          sort: this.state.stype === "Quotation"?true:false,
          display:this.state.stype === "Quotation"?true:false,
          viewColum:this.state.stype === "Quotation"?true:false,
        },
      },
      {
        name:"customid",
        label:"Id",
        options: {
          filter: false,
          sort: true,
          display:this.state.stype === "Sales"?true:false,
        }
      },
      {
        name:"quotation",
        label:"Quotation",
        options:{
          filter:true,
          sort:true,
          display:this.state.stype === "Sales"?true:false
        }
      },
      {
        name: "customer",
        label: "Customer",
        options: { filter: false, sort: true },
      },
      {
        name: "totalamountinctax",
        label: "Amount",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "createdby",
        label: "Created By",
        options: { filter: false, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return moment(value).format("DD-MM-YYYY HH:mm");
          },
        },
      },
      {
        name: "invstatus",
        label: "Invoice Status",
        options: { filter: false, sort: true ,display:this.state.stype === "Sales"?true:false},
      },
      {
        name: "Action ",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view"
                  className="fa fa-list-alt textColour textColour ml-2"
                  to={{pathname: "/assetdetails2"}}
                  state= {{data: (this.state.stype==="Sales"?this.state.solddata.find((e) => e.id === tableMeta.rowData[0])
                      :this.state.soldquotations.find((e) => e.id === tableMeta.rowData[0])),
                    pagename: (this.state.stype==="Sales"?"Sales Assets Details":"Sales Quotation Details"),
                    type:this.state.type,stype:this.state.stype,sold:this.state.sold,rent:this.state.rent,active:this.state.active
                  }}
                ></Link>

                {assetmanagementid === "2" || assetmanagementid === "3" ?<>
                {this.state.stype ==="Sales"?'':
                 <Link
                 as="button"
                 data-tip
                 data-for="view"
                 className="fa fa-edit textColour ml-2"
                 to={{pathname: "/rentasset"}}
                  state={{
                     data:this.state.soldquotations.find(e=>e.id===tableMeta.rowData[0]),
                     pagename: "Edit Sales Quotation",type:this.state.type,
                     stype:this.state.stype,sold:this.state.sold,rent:this.state.rent,active:this.state.active
                 }}
               ></Link>}
               </>:''}

              </>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
    ];

    const soldoptions = {
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
      customToolbar: () => {
        return (
          <>
          {assetmanagementid === "2" || assetmanagementid === "3" ?
            <Link
              as="button"
              className="float-right btn btn-sm m-0 p-0"
              to={{pathname: "/rentasset"}}
                state= {{data: "", pagename:this.state.stype ==="Sales"? "New Sales Asset" :"New Sales Quotation",
                type:this.state.type,stype:this.state.stype,sold:this.state.sold,rent:this.state.rent,active:this.state.active
              }}
            >
              <CustomToolbar />
            </Link>:''}

            <Link as="button" onClick={()=>this.pdf("Sales")}>
              <React.Fragment>
                <Tooltip title={"pdf"}>
                  <IconButton>
                    <i className="fa fa-file-pdf"></i>
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            </Link>
          </>
        );
      },
    };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/assetmanagement" className="text-info">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">Assets</li>
            </ol>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    {/* /.card */}

                    {/* <div className="card"> */}
                    <div className="card-header m-0 p-2">
                      <div className="text-center text-green">
                        {/* {this.state.message} */}
                      </div>
                    </div>

                    {/* <div className="card-body p-0"> */}
                    <div>
                      {this.state.loading === true ? (
                        <div className="text-center">{spin}</div>
                      ) : (
                        <>
                          <div className="form-row">
                            <fieldset className="form-group col-md-4">
                              <span className="mx-3">Assets</span>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    className="sm"
                                    checked={this.state.active}
                                    onChange={this.handleactiveChange}
                                    name="checkedB"
                                  />
                                }
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-4">
                              <span className="mx-3">Rental</span>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    className="sm"
                                    checked={this.state.rent}
                                    onChange={this.handlerentChange}
                                    name="checkedB"
                                  />
                                }
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-4">
                              <span className="mx-3">Sales</span>
                              <FormControlLabel
                                control={
                                  <IOSSwitch
                                    className="sm"
                                    checked={this.state.sold}
                                    onChange={this.handlesoldChange}
                                    name="checkedB"
                                  />
                                }
                              />
                            </fieldset>
                          </div>

                          {this.state.active ? (<>

                            <fieldset className="form-group col-md-12">
                            <fieldset
                              className={`form-group col-md-3  shadow-sm text-center btn ${
                                this.state.assettype === "Own"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                              }`}
                              disabled={this.state.assettype==="Own"}
                              onClick={() => this.setState({assettype:"Own"})}
                            >
                              Own
                            </fieldset>
                            <fieldset
                              className={`form-group col-md-3 shadow-sm btn text-center ${
                                this.state.assettype === "Thirdparty"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                              }`}
                              disabled={this.state.assettype==="Thirdparty"}
                              onClick={() => this.setState({assettype:"Thirdparty"})}
                            >
                              Thirdparty
                            </fieldset>
                          </fieldset>
                          {this.state.assettype==="Own"?
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Assets"}
                                id="assets"
                                data={this.state.data}
                                columns={columns}
                                options={options}
                              />
                            </ThemeProvider>
                            :
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"ThirdParty Assets"}
                                id="assets"
                                data={this.state.thirdpartyassets}
                                columns={thirdpartyassetscolumns}
                                options={options}
                              />
                            </ThemeProvider>
                            }
                          </>) : (
                            ""
                          )}

                          {this.state.rent ? (
                            <>
                              <fieldset className="form-group col-md-12">
                                <fieldset
                                  className={`form-group col-md-3 shadow-sm  text-center btn ${
                                    this.state.type === "Rental"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === "Rental"}
                                  onClick={() => this.setState({type:"Rental"})}
                                >
                                  Rental
                                </fieldset>
                                <fieldset
                                  className={`form-group col-md-3  btn shadow-sm text-center ${
                                    this.state.type === "Quotation"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === "Quotation"}
                                  onClick={() => this.setState({type:"Quotation"})}
                                >
                                  Rental Quotation
                                </fieldset>
                              </fieldset>
                              {this.state.type === "Rental"?
                              <ThemeProvider theme={theme}>
                                <MUIDataTable
                                  className="table-sm px-3"
                                  title={"Rental Assets"}
                                  id="assets"
                                  data={this.state.rentdata}
                                  columns={rentcolumns}
                                  options={rentoptions}
                                />
                              </ThemeProvider>
                              :
                              <ThemeProvider theme={theme}>
                                <MUIDataTable
                                  className="table-sm px-3"
                                  title={"Rental Quotation"}
                                  id="assets"
                                  data={this.state.rentalquotation}
                                  columns={rentcolumns}
                                  options={rentoptions}
                                />
                              </ThemeProvider>
                            }
                            </>
                          ) : (
                            ""
                          )}
                          {this.state.sold ? (<>
                            <fieldset className="form-group col-md-12">
                            <fieldset
                              className={`form-group col-md-3  shadow-sm text-center btn ${
                                this.state.stype === "Sales"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                              }`}
                              disabled={this.state.stype==="Sales"}
                              onClick={() => this.setState({stype:"Sales"})}
                            >
                              Sales
                            </fieldset>
                            <fieldset
                              className={`form-group col-md-3 shadow-sm btn text-center ${
                                this.state.stype === "Quotation"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                              }`}
                              disabled={this.state.stype==="Quotation"}
                              onClick={() => this.setState({stype:"Quotation"})}
                            >
                              Sales Quotation
                            </fieldset>
                          </fieldset>
                          {this.state.stype === "Sales"?
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Sales Assets"}
                                id="assets"
                                data={this.state.solddata}
                                columns={soldcolumns}
                                options={soldoptions}
                              />
                            </ThemeProvider>
                            :
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Sales Quotation"}
                                id="assets"
                                data={this.state.soldquotations}
                                columns={soldcolumns}
                                options={soldoptions}
                              />
                            </ThemeProvider>
                                }
                         </> ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                    {/* </div> */}
                    {/* </div> */}

                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(AssetTable);
