import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TourServices from '../../Invoice/TourServices';
import Header from "../../../Component/Header";
import Menu from "../../../Component/Menu";
import Footer from "../../../Component/Footer";
import Checkbox from '@mui/material/Checkbox';
import moment from "moment";
import { currentUser } from "../../../Common/Credentials";
import withRouter from "../../../withRouter";
import Swal from "sweetalert2"


const MAirline = (props) => {
  const [checkedsupplier, setCheckedsupplier] = useState(false);
  const [checkedcorporate, setCheckedcorporate] = useState(true);
  const nevigate = useNavigate();

  const [checkedAcceptedCustCard, setCheckedAcceptedCustCard] = useState(true);
  const [checkedsCreditDebit, setCheckedCreditDebit] = useState(true);
  const [checkedAcceptAgencyCard, setCheckedAcceptAgencyCard] = useState(true);
  const [checkedInterCompany, setCheckedInterCompany] = useState(true);
  const [data, setData] = useState({
    id: 0,
    chrcode: "",
    airlinename: "",
    numericcode: "",
    accountcode: "",
    airlineaddress: "",
    airlinevatno: "",
    currency: "",
    suppliercode: "",
    stdcommisionformula: "",
    suppliercategory: "",
    suppliergroup: "",
    roundingrule: "",
    corporate: "",
    beneficiaryname: "",
    beneficiaryaddress: "",
    beneficiarybank: "",
    beneficiaryaccountno: "",
    bankswiftcode: "",
    bankibancode: "",
    beneficiaryabncode: "",
    beneficiaryifsccode: "",
    beneficiarybicno: "",
    registrationno: "",
    beneficiaryabano: "",
    beneficiarymicrcode: "",
    correspondencebank: "",
    acceptcustomercard: "",
    creditdebitSupplierduringreconciliation: "",
    acceptagencycard: "",
    intercompanyaccount: "",
    mainledgercode: "",
    mainledgername: "",
    createddate: "",
    modifieddate: "",
  });
  
  useEffect(() => {
    if(props.location.state.id !== -1)
    {
        TourServices.getByIdMAirline(props.location.state.id).then((r)=>{
            setData({
                chrcode: r.data.chrcode,
                airlinename: r.data.airlinename,
                numericcode: r.data.numericcode,
                accountcode: r.data.accountcode,
                airlineaddress: r.data.airlineaddress,
                airlinevatno: r.data.airlinevatno,
                currency: r.data.currency,
                suppliercode: r.data.suppliercode,
                stdcommisionformula: r.data.stdcommisionformula,
                actassupplier:r.data.actassupplier,
                suppliercategory: r.data.suppliercategory,
                suppliergroup: r.data.suppliergroup,
                roundingrule: r.data.roundingrule,
                corporate: r.data.corporate,
                beneficiaryname: r.data.beneficiaryname,
                beneficiaryaddress: r.data.beneficiaryaddress,
                beneficiarybank: r.data.beneficiarybank,
                beneficiaryaccountno: r.data.beneficiaryaccountno,
                bankswiftcode: r.data.bankswiftcode,
                bankibancode: r.data.bankibancode,
                beneficiaryabncode: r.data.beneficiaryabncode,
                beneficiaryifsccode: r.data.beneficiaryifsccode,
                beneficiarybicno: r.data.beneficiarybicno,
                registrationno: r.data.registrationno,
                beneficiaryabano: r.data.beneficiaryabano,
                beneficiarymicrcode: r.data.beneficiarymicrcode,
                correspondencebank: r.data.correspondencebank,
                acceptcustomercard: r.data.acceptcustomercard,
                creditdebitSupplierduringreconciliation: r.data.creditdebitSupplierduringreconciliation,
                acceptagencycard: r.data.acceptagencycard,
                intercompanyaccount: r.data.intercompanyaccount,
                mainledgercode: r.data.mainledgercode,
                mainledgername: r.data.mainledgername,
                createddate: r.data.createddate,
                modifieddate: moment(new Date()).format("DD-MM-YYYY"),
            
            })
        })
    }
  },[])
  

  const handleChangeSupplier = (event) => {
    setCheckedsupplier(event.target.checked);
  
  };

  const changeHandlerCorporate = (event) => {
    setCheckedcorporate(event.target.checked);
  };
// --------------------------------------------------------
  const handleChangeAcceptedCustCard = (event) => {
    setCheckedAcceptedCustCard(event.target.checked);
    console.log(event.target.checked);
  };

  const handleChangeCreditDebit = (event) => {
    setCheckedCreditDebit(event.target.checked);
  };

  const handleChangeAcceptAgencyCard = (event) => {
    setCheckedAcceptAgencyCard(event.target.checked);
  };

  const handleChangeInterCompany = (event) => {
    setCheckedInterCompany(event.target.checked);
  };

  const changeHandler = (e) => {
    setData(prevValues => {
      return { ...prevValues, [e.target.name]: e.target.value }
    })
  }
  


  const AddHandler = (e) => {

    e.preventDefault();

   if(props.location.state.id === -1)
   {

    const sdata = {
      chrcode: data.chrcode,
      airlinename: data.airlinename,
      numericcode: data.numericcode,
      accountcode: data.accountcode,
      airlineaddress: data.airlineaddress,
      airlinevatno: data.airlinevatno,
      currency: data.currency,
      suppliercode: data.suppliercode,
      stdcommisionformula: data.stdcommisionformula,
      actassupplier:data.actassupplier,
      suppliercategory: data.suppliercategory,
      suppliergroup: data.suppliergroup,
      roundingrule: data.roundingrule,
      corporate: data.corporate,
      beneficiaryname: data.beneficiaryname,
      beneficiaryaddress: data.beneficiaryaddress,
      beneficiarybank: data.beneficiarybank,
      beneficiaryaccountno: data.beneficiaryaccountno,
      bankswiftcode: data.bankswiftcode,
      bankibancode: data.bankibancode,
      beneficiaryabncode: data.beneficiaryabncode,
      beneficiaryifsccode: data.beneficiaryifsccode,
      beneficiarybicno: data.beneficiarybicno,
      registrationno: data.registrationno,
      beneficiaryabano: data.beneficiaryabano,
      beneficiarymicrcode: data.beneficiarymicrcode,
      correspondencebank: data.correspondencebank,
      acceptcustomercard: data.acceptcustomercard,
      creditdebitSupplierduringreconciliation: data.creditdebitSupplierduringreconciliation,
      acceptagencycard: data.acceptagencycard,
      intercompanyaccount: data.intercompanyaccount,
      mainledgercode: data.mainledgercode,
      mainledgername: data.mainledgername,
      createddate: new Date(),
      modifieddate: new Date(),
    }

    
    Swal.fire({
      title: "Add Airline ?",
      text: "Are you sure you want to Add ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        TourServices.addMAirline(sdata)
          .then((response) => {
            Swal.fire({
              title: "Airline added successfuly",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then((r)=>{
              console.log(r.data,"Successfull");
            })
            nevigate("/masterairlinelist");
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });

    
   }
  
   else
   {
    const sdata = {
      chrcode: data.chrcode,
      airlinename: data.airlinename,
      numericcode: data.numericcode,
      accountcode: data.accountcode,
      airlineaddress: data.airlineaddress,
      airlinevatno: data.airlinevatno,
      currency: data.currency,
      suppliercode: data.suppliercode,
      stdcommisionformula: data.stdcommisionformula,
      actassupplier:data.actassupplier,
      suppliercategory: data.suppliercategory,
      suppliergroup: data.suppliergroup,
      roundingrule: data.roundingrule,
      corporate: data.corporate,
      beneficiaryname: data.beneficiaryname,
      beneficiaryaddress: data.beneficiaryaddress,
      beneficiarybank: data.beneficiarybank,
      beneficiaryaccountno: data.beneficiaryaccountno,
      bankswiftcode: data.bankswiftcode,
      bankibancode: data.bankibancode,
      beneficiaryabncode: data.beneficiaryabncode,
      beneficiaryifsccode: data.beneficiaryifsccode,
      beneficiarybicno: data.beneficiarybicno,
      registrationno: data.registrationno,
      beneficiaryabano: data.beneficiaryabano,
      beneficiarymicrcode: data.beneficiarymicrcode,
      correspondencebank: data.correspondencebank,
      acceptcustomercard: data.acceptcustomercard,
      creditdebitSupplierduringreconciliation: data.creditdebitSupplierduringreconciliation,
      acceptagencycard: data.acceptagencycard,
      intercompanyaccount: data.intercompanyaccount,
      mainledgercode: data.mainledgercode,
      mainledgername: data.mainledgername,
      modifieddate: new Date(),
    }
    Swal.fire({
      title: "update Airline ?",
      text: "Are you sure you want to Add ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        TourServices.updateMAirline(sdata,props.location.state.id)
          .then((response) => {
            Swal.fire({
              title: "Airline Updated successfuly",
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then((r)=>{
              console.log(r.data,"Successfull");
              nevigate(-1);
            })
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });


    // TourServices.updateMAirline(sdata,props.location.state.id).then((r) => {
    //   console.log(r.data);
    // })
    
   }

  }


  return (
    <>
    <Header/>
    <Menu/>
      <div className="content-wrapper w-100% h-100%">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
          <span
                as="button"
                className=" text-secondary cursorPointer"
                
              >
                <Link to="/masterairlinelist" style={{color:"grey"}}>❮ Back &nbsp;&nbsp;</Link>
                
              </span>
            <li className="breadcrumb-item text-secondary">
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/masterairlinelist">Airline list</Link>
            </li>
            <li className="breadcrumb-item active">Master Airline</li>
          </ol>
     
            <form >
              <div className="card-body p-6">
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                     required="true"
                      label="Chr. Code"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="chrcode"
                      value={data.chrcode}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                     required="true"
                      label="Airline Name"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="airlinename"
                      value={data.airlinename}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                     required="true"
                      label="Numeric Code"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="numericcode"
                      value={data.numericcode}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                     required="true"
                      label="Account Code"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="accountcode"
                      value={data.accountcode}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <textarea
                      fullWidth
                      rows="2"
                      maxRow={4}
                      multiline
                      name="airlineaddress"
                      value={data.airlineaddress}
                      onChange={changeHandler}
                      style={{width:"100%"}}
                      placeholder="Airline Address"
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Airline vat No"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="airlinevatno"
                      value={data.airlinevatno}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Country"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="country"
                      value={data.country}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Currency"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="currency"
                      value={data.currency}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                     
                      label="Supplier Code"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="suppliercode"
                      value={data.suppliercode}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1 d-flex">
                  <lable className="my-2 ml-4">Act as supplier </lable>
                <Checkbox
                  checked={checkedsupplier}
                  onChange={handleChangeSupplier}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>

              {checkedsupplier &&
              <div>
              <div className="Card-body p-3 ">
               <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                     
                      label="Supplier Category"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="suppliercategory"
                      value={data.suppliercategory}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                     
                      label="Supplier Group "
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="suppliergroup"
                      value={data.suppliergroup}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                     
                      label="Rounding Rule"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="roundingrule"
                      value={data.roundingrule}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                  <lable className="my-2 ml-4">Corporate</lable>
                    <Checkbox
                      checked={checkedcorporate}
                      onChange={changeHandlerCorporate}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-12 col-sm-12">
                    <TextField
                      fullWidth
                      label="Beneficiary Namer"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="beneficiaryname"
                      value={data.beneficiaryname}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12 ">
                    <textarea
                      rows="2"
                      maxRow={4}
                      multiline
                      placeholder="Beneficiary Address"
                      name="beneficiaryaddress"
                      value={data.beneficiaryaddress}
                      onChange={changeHandler}
                      style={{width:"100%"}}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      label="Beneficiary Bank "
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="beneficiarybank"
                      value={data.beneficiarybank}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      label="Beneficiary Account No"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="beneficiaryaccountno"
                      value={data.beneficiaryaccountno}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      label="Bank SWIFT Code"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="bankswiftcode"
                      value={data.bankswiftcode}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      label="Bank IBAN Coder"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="bankibancode"
                      value={data.bankibancode}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      label="Beneficiary ABN Code "
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="beneficiaryabncode"
                      value={data.beneficiaryabncode}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      label="Beneficiary IFSC Code"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="beneficiaryifsccode"
                      value={data.beneficiaryifsccode}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      label="Beneficiary BIC No "
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="beneficiarybicno"
                      value={data.beneficiarybicno}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      label="Registration No"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="registrationno"
                      value={data.registrationno}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      label="Beneficiary ABA No "
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="beneficiaryabano"
                      value={data.beneficiaryabano}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      label="Beneficiary MICR Code"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="beneficiarymicrcode"
                      value={data.beneficiarymicrcode}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      label="Correspondence Bank "
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="correspondencebank"
                      value={data.correspondencebank}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
               </div>
               <div className="d-flex p-3 my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      label="Main Ledger Code"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="mainledgercode"
                      value={data.mainledgercode}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      label="Main Ledger Name "
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="mainledgername"
                      value={data.mainledgername}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1 d-flex">
                <div className="">
                <lable className="my-2 ml-4">Accept Customer Card </lable>
                <Checkbox
                  checked={checkedAcceptedCustCard}
                  onChange={handleChangeAcceptedCustCard}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                </div>
                <div>
                <lable className="my-2 ml-4">Credit/Debit Supplier during Reconciliation</lable>
                <Checkbox
                  checked={checkedsCreditDebit}
                  onChange={handleChangeCreditDebit}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                </div>
              </div>
             

              <div className="row my-1 d-flex">
                 <div>
                 <lable className="my-2 ml-4">Accept Agency Card </lable>
                <Checkbox
                  checked={checkedAcceptAgencyCard}
                  onChange={handleChangeAcceptAgencyCard}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                 </div>
                 <div>
                 <lable className="my-2 ml-4">Intercompany Account </lable>
                <Checkbox
                  checked={checkedInterCompany}
                  onChange={handleChangeInterCompany}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                 </div>
              </div>
              </div>
              } 
                
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="my-2">
                      <label>Created By : Jnanasoftware</label>
                    </div>
                 
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className=" my-2">
                      <label>Modified By : {currentUser }</label>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <label>Created Time : {moment(data.createddate).format("DD-MM-YYYY")}</label>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <label>Modified Time : {moment(data.modifieddate).format("DD-MM-yyyy")} </label>
                    </div>
                  </div>
                  <div className="btn d-flex justify-context-center">
                    <button className="btn buttonColour btn-sm  "  onClick={AddHandler}>ADD</button>
                  </div>
                </div>
              </div>
            </form>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default withRouter(MAirline);
