import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

class SubAccService{
    
   

   retriveAllSubAcc(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getsubaccts/${tenantId}`)
      }

      retriveSubAcc(id){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getsubacc/${id}`)
      }

      retriveSubIdName(tenantId){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getsubidname/${tenantId}`)
      }

      retriveSubHead(tenantId){
         return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getsubheads/${tenantId}`)

      }

      
     
      createSubAcc(newSubAcc){
         return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/addnewsubacc`,newSubAcc)
      }

      updateSubAcc(updateSubAcc){
         return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/updatesubacc`,updateSubAcc)
      }

   
      deleteSubAcc(id){
         return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/deletesubacc/${id}`,)
      }


}

export default new SubAccService();
