import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import Menu from '../../Component/Menu'
import { clientname, currentTenant, dataaccess, financialyearend, financialyearstart, logo, thousandsdisplay, userid } from '../../Common/Credentials'
import moment from 'moment'
import { spin } from '../../Common/NewLoader'
import SalesServices from '../SalesServices'
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import { salesid } from '../../Common/Permissions'
import {NumericFormat} from 'react-number-format';
import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from "@mui/material";
import { createTheme, FormControlLabel, IconButton, Tooltip } from "@mui/material";
import IOSSwitch from '../../CommonComponents/IOSSwitch'
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable" 
import AssetsService from '../../AssetManagement/AssetsService'
import UsercustomerService from '../../Masters/Usercustomers/UsercustomerService'
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import AddIcon from '@mui/icons-material/Add';
import { createCSVDownload, downloadCSV } from './downloadcsv.js'
import DownloadIcon from '@mui/icons-material/CloudDownload';
import { screens } from '../../Common/Products.js';

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});
class InvoiceTable extends Component {

  constructor(props) {
    super(props)
    this.state = {
      Invoice: [],
      pendingInvoice:[],
      CompletedInvoice:[],
      CancelledInvoice:[],
      proformaInvoice:[],
      proformaInvoiceCompleted:[],
      loading:false,
      active:true,
      pending:false,
      comp:false,
      cancel:false,
      type:this.props.location.state.type,
      rentInvoice:[],
      soldInvoice:[],
      rent:true,
      sales:false,
      usercustomers:[]
    }
  }


  
 handleCSVDownload = (displayData) => {
  const { data, columns, options, columnOrder } = this.props;
  let dataToDownload = []; //cloneDeep(data);
  let columnsToDownload = [];
  let columnOrderCopy = Array.isArray(columnOrder) ? columnOrder.slice(0) : [];

  if (columnOrderCopy.length === 0) {
    columnOrderCopy = columns.map((item, idx) => idx);
  }

  data.forEach(row => {
    let newRow = { index: row.index, data: [] };
    columnOrderCopy.forEach(idx => {
      newRow.data.push(row.data[idx]);
    });
    dataToDownload.push(newRow);
  });

  columnOrderCopy.forEach(idx => {
    columnsToDownload.push(columns[idx]);
  });

  if (options.downloadOptions && options.downloadOptions.filterOptions) {
    // check rows first:
    if (options.downloadOptions.filterOptions.useDisplayedRowsOnly) {
      let filteredDataToDownload = displayData.map((row, index) => {
        let i = -1;

        // Help to preserve sort order in custom render columns
        row.index = index;

        return {
          data: row.data.map(column => {
            i += 1;

            // if we have a custom render, which will appear as a react element, we must grab the actual value from data
            // that matches the dataIndex and column
            // TODO: Create a utility function for checking whether or not something is a react object
            let val =
              typeof column === 'object' && column !== null && !Array.isArray(column)
                ? find(data, d => d.index === row.dataIndex).data[i]
                : column;
            val = typeof val === 'function' ? find(data, d => d.index === row.dataIndex).data[i] : val;
            return val;
          }),
        };
      });

      dataToDownload = [];
      filteredDataToDownload.forEach(row => {
        let newRow = { index: row.index, data: [] };
        columnOrderCopy.forEach(idx => {
          newRow.data.push(row.data[idx]);
        });
        dataToDownload.push(newRow);
      });
    }

    // now, check columns:
    if (options.downloadOptions.filterOptions.useDisplayedColumnsOnly) {
      columnsToDownload = columnsToDownload.filter(_ => _.display === 'true');

      dataToDownload = dataToDownload.map(row => {
        row.data = row.data.filter((_, index) => columns[columnOrderCopy[index]].display === 'true');
        return row;
      });
    }
  }
  createCSVDownload(columnsToDownload, dataToDownload, options, downloadCSV);
};



  pdf() {
    this.state.Invoice.map(
      (e) =>
        this.state.Invoice.find((i) => i.invid === e.invid).createdAt = moment(
          this.state.Invoice.find((i) => i.invid === e.invid).createdAt
        ).format("DD-MM-YYYY HH:mm")
    );
       const curdate = moment().format('DD-MM-YYYY HH:mm:ss')
    const columns = [{header:"Invoice Id",dataKey:'custominvid'},{header:"Saleorder Id",dataKey:'customsoid'},{header:"Customer",dataKey:'customer'},{header:"Total Amount",dataKey:'totalinvoice'},{header:"Amount Received",dataKey:'paid'},{header:"Amount Due",dataKey:'due'},{header:"Date",dataKey:"createdAt"}]
    var doc = new jsPDF('p', 'pt',[842, 595]);
    autoTable(doc,{
      theme:'grid',
      startY: 120,
      columnStyles: { 7: { halign: 'right'} },
      didDrawPage: (HookData) => {
        HookData.settings.margin.top = 120;
      },
      body:this.state.Invoice,
      columns:columns,
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;

      doc.addImage(logo, "G IF", 40, 20, 60, 60, { baseline: "top" });
      doc.setFontSize(8);
      doc.text(`${curdate}`,560,20,'right')
      doc.setFontSize(16);
      doc.text(`${clientname}`, 180, 40, { baseline: "top" });
      doc.setFontSize(14);
      doc.text(`Active Sales Invoices`, 210, 70, { baseline: "top" });
      doc.line(0, 90, 600, 90);
      doc.setFontSize(10);

      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }
    doc.save("ActiveSalesInvoices.pdf");
    this.refreshSalesorder()
  }

  handlePendingChange=()=>{
    this.setState({pending:true,active:false,comp:false,cancel:false}) 
  }
  handleActiveChange=()=>{
    this.setState({pending:false,active:true,comp:false,cancel:false})
  }
  handlecompChange=()=>{
    this.setState({pending:false,comp:true,active:false,cancel:false})
  }
  handleCancelChange=()=>{
    this.setState({pending:false,cancel:true,comp:false,active:false})
  }

  refreshSalesorder(){

    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
      userid: userid,
    dataaccess:dataaccess
    }

    SalesServices.GetSalesInvoiceDetails(datadao)
      .then(
        response => {  
          this.setState({ Invoice: response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).filter(e=>this.state.usercustomers.length ===0?e:this.state.usercustomers.includes(e.customer)).map(e=>Object.assign(e,{month:moment(e.createdAt).format("YYYY-MM-DD") >= moment(financialyearstart).format("YYYY-MM-DD") && moment(e.createdAt).format("YYYY-MM-DD") <= moment(financialyearend).format("YYYY-MM-DD")? moment(e.createdAt).format("MMMM"):""})),loading:false})
        }   
    ).catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })

  SalesServices.GetPendingInvoiceDetails(datadao).then(response=>{
    this.setState({ pendingInvoice: response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).filter(e=>this.state.usercustomers.length ===0?e:this.state.usercustomers.includes(e.customer)),loading:false})
  }   
).catch((e)=>{
if(e.response.data.error ==="Unauthorized"){
    alert("Your Session is Lost: Please login Back to resume");
    this.props.navigate(`/`);
}
  })

    SalesServices.GetCompletedInvoice(datadao).then(
      response => {  
        this.setState({ CompletedInvoice: response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).filter(e=>this.state.usercustomers.length ===0?e:this.state.usercustomers.includes(e.customer)).map(e=>Object.assign(e,{month:moment(e.createdAt).format("YYYY-MM-DD") >= moment(financialyearstart).format("YYYY-MM-DD") && moment(e.createdAt).format("YYYY-MM-DD") <= moment(financialyearend).format("YYYY-MM-DD")? moment(e.createdAt).format("MMMM"):""})),loading:false})
      }   
  )

  SalesServices.GetCancelleddInvoices(datadao).then(
    response => {  
      this.setState({ CancelledInvoice: response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).filter(e=>this.state.usercustomers.length ===0?e:this.state.usercustomers.includes(e.customer)),loading:false})
    }   
    )

  SalesServices.getProformaInvoiceofTenant(datadao).then(res=>{
    this.setState({proformaInvoice:res.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).filter(e=>this.state.usercustomers.length ===0?e:this.state.usercustomers.includes(e.customer))})
  })

  SalesServices.getCompletedProformaInvoiceofTenant(datadao).then(res=>{
    this.setState({proformaInvoiceCompleted:res.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).filter(e=>this.state.usercustomers.length ===0?e:this.state.usercustomers.includes(e.customer))})
  })

  AssetsService.GetAssetInvoicebyTenantid(currentTenant).then(res=>{
    this.setState({
      rentInvoice:res.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).filter(e=>(this.state.usercustomers.length ===0?e:this.state.usercustomers.includes(e.customer)) && e.rentasset !== null),
      soldInvoice:res.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).filter(e=>(this.state.usercustomers.length ===0?e:this.state.usercustomers.includes(e.customer)) && e.soldassets !== null)
    })
  })
  }
  
  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    UsercustomerService.GetUserCustomersbyUser(userid).then((res)=>this.setState({usercustomers:res.data===''||res.data===undefined?[]:res.data.customers.split(",")}))

    this.setState({loading:true})

    this.refreshSalesorder()
  }
    
  render() {
let columns=[
    {name: "invid",label: "Invoice Id",options: {filter: false,sort: true, display: false}},
    {name:  this.state.type==="Tax Invoice"?"custominvid":"invid",label: "Invoice Id",options: {filter: false,sort: true}},
    {name: "customsoid",label: "Saleorder Id",options: {filter: false,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(
            <>{value===null?<span>Direct</span>:
              <Link to={{pathname: "/saleorderdetails"}} state ={{id:this.state.type === "Tax Invoice"?this.state.active?this.state.Invoice.find(e=>e.customsoid===value)?.soid:this.state.CompletedInvoice.find(e=>e.customsoid===value)?.soid :this.state.proformaInvoice.find(e=>e.customsoid===value)?.soid,bvstatus:0}}>{value}</Link>
              }
              </>
          )
        }
    }},
    // {name: "custid",label:"Customer Id",options: {filter: true,sort: true,}},
    {name: "customer",label: "Customer",options: {filter: true,sort: true,}},
    {name: "agent",label: "Agent",options: {filter: true,sort: true, 
      customBodyRender:(value,tableMeta)=>{
      return(
        <>
          <span as="button" data-tip data-for="phno">{value === null?"Direct":value}</span>
          {/* {value === null?'Direct':
          <Tooltip id="phno" place="top" effect="solid">Ph. {tableMeta.rowData[5]}</Tooltip>} */}
          </>
      )
    }}},
    {name: "agentphno",label: "Agent Ph",options: {filter: false,sort: true,sortOrder : 'asc', display:false,viewColumns:false}},
    {name: this.state.type==="Tax Invoice"?"taxamount":"advance",label: this.state.type==="Tax Invoice"?"Tax Amount":"Advance",options: {filter: false,sort: true,
      customBodyRender:(tableMeta)=>{
        return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
    {name: "totalinvoice",label: "Total Amount",options: {filter: false,sort: true,
      customBodyRender:(tableMeta)=>{
        return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
    {name: "paid",label: "Amount Received",options: {filter: false,sort: true,
      customBodyRender:(tableMeta)=>{
        return(<NumericFormat className={`pr-3  ${this.state.type==="Tax Invoice" && this.state.active === true?"text-green":""}`} displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
    {name: "due",label: "Due",options: {filter: false,sort: true,display:this.state.type==="Tax Invoice"?true:false,
      customBodyRender:(tableMeta)=>{
        return(<NumericFormat className={`pr-3  ${this.state.type==="Tax Invoice" && this.state.active === true?"text-red":""}`} displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
    {name: "createdbyname",label: "Created By",options: {filter: true,sort: true,}},
    {name: "month",label: "Month",options: {filter: true,sort: true,display:false}},
    {name: "createdAt",label: "Date",options: {filter: false,sort: true, 
    customBodyRender:(tableMeta)=>{
      return(moment(tableMeta).format('DD-MM-YYYY HH:mm'))
    }}},
    // {name: "approvestatus",label: "Approve Status",options: {filter: true,sort: true,display:this.state.type==="Tax Invoice"?true:false}},
    {name: "payment",label: "Receipts",options: {filter: true,sort: true,display:this.state.type==="Tax Invoice"?true:false}},
    {name: "deliverystatus",label: "Delivery",options: {filter: true,sort: true,display:this.state.type==="Tax Invoice"?true:false},
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
        return (<>
        { this.state.active===true && value === "Pending"?
        this.state.Invoice.find(i=>i.invid === tableMeta.rowData.find(e=>e)).ledgerentry==="Pending"?"pending JE"
        :
        (salesid === '2' || salesid === '3') && screens.includes("Delivery/Shipment Note")?
         <Link className="m-0 p-0"  to={{pathname: '/newdeliverynote'}} state ={ {id: -1,pagename:'New Delivery Note',invid:this.state.Invoice.find(i=>i.invid === tableMeta.rowData.find(e=>e)).custominvid}}>
          {value}
            </Link>:value:value}
          </>
        );
        }
      }
  },
    {
      name: "View",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
         return (<>
           <Link as="button" data-tip data-for="view details" className="fa fa-list-alt" 
           to={{pathname: "/viewsalesinvoice"}} state ={{id:tableMeta.rowData.find(e=>e),pagename:this.state.type==="Tax Invoice"?'Tax Invoice Details':'Proforma Invoice Details',type:this.state.type,temp:false}}></Link>
          {/* <Tooltip id="view details" place="top" effect="solid">View</Tooltip> */}
           </>
         );
        }
      }
    },
  ];

  let pendingcolumns=[
    {name: "invid",label: "Invoice Id",options: {filter: false,sort: true, display: false}},
    {name:  this.state.type==="Tax Invoice"?"custominvid":"invid",label: "Invoice Id",options: {filter: false,sort: true}},
    {name: "customsoid",label: "Saleorder Id",options: {filter: false,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(
            <>
            {value===null?<span>Direct</span>:
              <Link to={{pathname: "/saleorderdetails"}} state ={{id:value,bvstatus:0}}>{value}</Link>
              }
              </>
          )
        }
    }},
    // {name: "custid",label:"Customer Id",options: {filter: true,sort: true,}},
    {name: "customer",label: "Customer",options: {filter: true,sort: true,}},
    {name: "agent",label: "Agent",options: {filter: true,sort: true, 
      customBodyRender:(value,tableMeta)=>{
      return(
        <>
          <span as="button" data-tip data-for="phno">{value === null?"Direct":value}</span>
          {/* {value === null?'Direct':
          <Tooltip id="phno" place="top" effect="solid">Ph. {tableMeta.rowData[5]}</Tooltip>} */}
          </>
      )
    }}},
    {name: "agentphno",label: "Agent Ph",options: {filter: false,sort: true,sortOrder : 'asc', display:false,viewColumns:false}},
    {name: "taxamount",label: "Tax Amount",options: {filter: false,sort: true,
      customBodyRender:(tableMeta)=>{
        return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
    {name: "totalinvoice",label: "Total Amount",options: {filter: false,sort: true,
      customBodyRender:(tableMeta)=>{
        return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
    // {name: "paid",label: "Amount Received",options: {filter: false,sort: true,
    //   customBodyRender:(tableMeta)=>{
    //     return(<NumericFormat className="pr-3 text-green" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
    //   },
    //   setCellProps: value => ({ style: { textAlign:'right'}}),
    // }},
    // {name: "due",label: "Due",options: {filter: false,sort: true,
    //   customBodyRender:(tableMeta)=>{
    //     return(<NumericFormat className="pr-3 text-red" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
    //   },
    //   setCellProps: value => ({ style: { textAlign:'right'}}),
    // }},
    {name: "createdbyname",label: "Created By",options: {filter: true,sort: true,}},
    {name: "createdAt",label: "Date",options: {filter: false,sort: true, 
    customBodyRender:(tableMeta)=>{
      return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
    }}},
    {name: "approvestatus",label: "Approve Status",options: {filter: true,sort: true,display:this.state.type==="Tax Invoice"?true:false}},
    // {name: "payment",label: "Payment",options: {filter: true,sort: true,display:this.state.type==="Tax Invoice"?true:false}},
    // {name: "deliverystatus",label: "Delivery",options: {filter: true,sort: true,display:this.state.type==="Tax Invoice"?true:false}},
    {
      name: "View",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
         return (<>
           <Link as="button" data-tip data-for="view details" className="fa fa-list-alt" 
           to={{pathname: "/viewsalesinvoice"}} state ={{id:tableMeta.rowData.find(e=>e),pagename:this.state.type==="Tax Invoice"?'Tax Invoice Details':'Proforma Invoice Details',type:this.state.type,temp:true}}></Link>
          {/* <Tooltip id="view details" place="top" effect="solid">View</Tooltip> */}
           </>
         );
        }
      }
    },
  ];

   const options = {
    filterType: 'checkbox',
    selectableRows: "none",
    customToolbar: (selectedRows) => {
     return (<>
             {salesid === '2' || salesid === '3'?
     <Link as="button" className="float-right btn btn-sm m-0 p-0"  to={{pathname: '/newsalesinvoice'}} state ={ {id: -1,pagename:'New Invoice'}}>
      <Tooltip title="Add New">
          <IconButton>
            <AddIcon/>
          </IconButton>
        </Tooltip>
     </Link>
     :''
      }
     </>
     );
   },

  onDownload: (buildHead, buildBody, columns, data) => {
    return "\uFEFF" + buildHead(columns) + buildBody(data); 
} ,
downloadOptions:{
  filename:"Invoice",
  separator:",",
  filterOptions:{
    useDisplayedColumnsOnly:true,
    useDisplayedRowsOnly:true
  }
},

  // customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {
  //   const handleClick = () => {
  //      const recordsToDownload = selectedRows?.data?.map(
  //          ({ index }) => displayData[index]
  //      ).map(
  //          ({ data }) => data
  //      );

  //   };
  
  //   return (
  //     <Box mr={4}>
  //       <Fab
  //         variant="extended"
  //         size="small"
  //         color="primary"
  //         aria-label="add"
  //         onClick={handleClick}
  //       >
  //         <FileDownloadRounded />
  //         Exporter
  //       </Fab>
  //     </Box>
  //   );
  //   }
  }

  const optionsc = {
    filterType: 'checkbox',
    selectableRows: "none",
    print: false,
    customToolbar: () => {
     return (<>
         <span type="button" onClick={this.pdf.bind(this)}>
             <React.Fragment>
        <Tooltip title={"pdf"}>
          <IconButton>
          <PictureAsPdfOutlinedIcon/>
          </IconButton>
        </Tooltip>
      </React.Fragment>

             </span>
     </>
     );
   },

   onDownload: (buildHead, buildBody, columns, data) => {
    return "\uFEFF" + buildHead(columns) + buildBody(data); 
} ,
downloadOptions:{
  filename:"Invoice",
  separator:",",
  filterOptions:{
    useDisplayedColumnsOnly:true,
    useDisplayedRowsOnly:true
  }
},

  }

  const options2 = {
    filterType: 'checkbox',
    selectableRows: "none",
    customToolbar: () => {
     return (<>
      {salesid === '2' || salesid === '3'?
     <Link as="button" className="float-right btn btn-sm m-0 p-0"  to={{pathname: '/newsalesinvoice'}} state ={ {id: -1,pagename:'New Proforma Invoice'}}>
       <CustomToolbar/>
     </Link>
     :''
      }
     </>
     );
   },
   onDownload: (buildHead, buildBody, columns, data) => {
    return "\uFEFF" + buildHead(columns) + buildBody(data); 
} ,
downloadOptions:{
  filename:"Invoice",
  separator:",",
  filterOptions:{
    useDisplayedColumnsOnly:true,
    useDisplayedRowsOnly:true
  }
},
  }


  
  let rentcolumns=[
    {name: "custominvid",label: "Invoice Id",options: {filter: false,sort: true,}},
    // {name: "rentasset",label:"Id",options: {filter: false,sort: false,viewColumns:false}},
    // {name: "custid",label:"Customer Id",options: {filter: true,sort: true,}},
    {name: "customer",label: "Customer",options: {filter: true,sort: true,}},
    {name: "totalamountinctax",label: "Total Amount",options: {filter: false,sort: true,
      customBodyRender:(value)=>{
        return(<NumericFormat className="pr-3" displayType={'text'} value={value} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
      },
      setCellProps: value => ({ style: { textAlign:'right'}}),
    }},
    {name: "createdAt",label: "Date",options: {filter: false,sort: true, 
    customBodyRender:(value)=>{
      return(moment(value).format('DD-MM-YYYY HH:mm:ss'))
    }}},

    {name: "rentasset",label: "Invoicetype",options: {filter: true,sort: true,
      customBodyRender:(value)=>{
      return(<span>{value === null || value === undefined?"Excess Sales" :"Rental"}</span>)
    },}},
    {
      name: "View",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
         return (<>
           <Link as="button" data-tip data-for="view details" className="fa fa-list-alt" 
           to={{pathname: "/assetinvoicedetails"}} state ={{data:this.state.rent===true?this.state.rentInvoice.find(e=>e.custominvid === tableMeta.rowData.find(e=>e)):this.state.soldInvoice.find(e=>e.custominvid === tableMeta.rowData.find(e=>e)),pagename:this.state.rent===true?"Rental Invoice":"Excess Sales Invoice",type:this.state.type,temp:false}}></Link>
           {/* <Tooltip id="view details" place="top" effect="solid">View</Tooltip> */}
           </>
         );
        }
      }
    },
  ];
   const rentoptions = {
    filterType: 'checkbox',
    selectableRows: "none",
  }

     
    return (
          
      <div>
         <Header />
         <Menu/>
        <div className="content-wrapper">  
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              
              <li className="breadcrumb-item active">Sales Invoice</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">              
              
                    <div className="card">                
                      <div className="card-header">
                        <h3 className="card-title">
                          Sales Invoice
                        </h3>
                      </div>
                       
                      <div className="card-body">
                      <div>{this.state.loading ===true ?<div className="text-center">{spin}</div> :<> 
                      <div className="form-row"> 
                      <fieldset className="form-group col-md-12">
                                <fieldset
                                  className={`form-group col-md-4  shadow-sm text-center btn ${
                                    this.state.type === "Tax Invoice"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === "Tax Invoice"}
                                  onClick={() => this.setState({type:"Tax Invoice"})}
                                >
                                  Tax Invoice
                                </fieldset>
                                <fieldset
                                  className={`form-group col-md-4 shadow-sm btn text-center ${
                                    this.state.type === "Proforma Invoice"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === "Proforma Invoice"}
                                  onClick={() => this.setState({type:"Proforma Invoice"})}
                                >
                                  Proforma Invoice
                                </fieldset>
                                <fieldset
                                  className={`form-group col-md-4 shadow-sm btn text-center ${
                                    this.state.type === "Asset Invoice"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === "Asset Invoice"}
                                  onClick={() => this.setState({type:"Asset Invoice"})}
                                >
                                  Asset Invoice
                                </fieldset>
                              </fieldset>
                                  {this.state.type==="Asset Invoice"?<>
                                  <fieldset className="form-group col-md-4">
                              <span className="mx-3">Rental</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.rent} onChange={()=>this.setState({rent:true,sales:false})} name="checkedB" />}
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-4">
                              <span className="mx-3">Excess Sales</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.sales} onChange={()=>this.setState({rent:false,sales:true})} name="checkedB" />}
                              />
                            </fieldset>
                                  </>:
                                  <ul className='skewstyledbuttons'>
                            <li className="">
                              <a onClick={this.handleActiveChange} className={`${this.state.active?"bg-yellow text-newcolor":""}`}>
                              <span className="mx-3">Active</span>
                              </a>
                              {/* <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.active} onChange={this.handleActiveChange} name="checkedB" />}
                              /> */}
                            </li>
                            {this.state.type==="Proforma Invoice"?'':
                            <li className="">
                              <a onClick={this.handlePendingChange} className={`${this.state.pending?"bg-yellow text-newcolor":""}`}>
                              <span className="mx-3">Approval Pending</span>
                              </a>
                              {/* <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.pending} onChange={this.handlePendingChange} name="checkedB" />}
                              /> */}
                            </li>
                            }
                            <li className="">
                              <a onClick={this.handlecompChange} className={`${this.state.comp?"bg-yellow text-newcolor":""}`}>
                              <span className="mx-3">Completed</span>
                              </a>
                              {/* <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.comp} onChange={this.handlecompChange} name="checkedB" />}
                              /> */}
                            </li>
                            {this.state.type === "Proforma Invoice"?"":
                            <li className="">
                              <a onClick={this.handleCancelChange} className={`${this.state.cancel?"bg-yellow text-newcolor":""}`}>
                              <span className="mx-3">Cancelled</span>
                              </a>
                              {/* <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.cancel} onChange={this.handleCancelChange} name="checkedB" />}
                              /> */}
                            </li>
                          }
                            </ul>}
                            </div> 
                            {this.state.type==="Tax Invoice"?<>
                            {this.state.pending === true?
                        <ThemeProvider theme={theme}> 
                        <MUIDataTable
                          className="table-sm px-3"
                          title={"Approval Pending Tax Invoice"}
                          data={this.state.pendingInvoice}
                          columns={pendingcolumns}
                          options={options}
                        />
                        </ThemeProvider>
                        :''}
                            {this.state.active === true?
                        <ThemeProvider theme={theme}> 
                        <MUIDataTable
                          className="table-sm px-3"
                          title={"Active Tax Invoice"}
                          data={this.state.Invoice}
                          columns={columns}
                          options={options}
                        />
                        </ThemeProvider>
                        :''}
                        {this.state.comp === true?
                        <ThemeProvider theme={theme}> 
                        <MUIDataTable
                          className="table-sm px-3"
                          title={"Completed Tax Invoice"}
                          data={this.state.CompletedInvoice}
                          columns={columns}
                          options={optionsc}
                        />
                        </ThemeProvider>
                        :''}
                         {this.state.cancel === true?
                        <ThemeProvider theme={theme}> 
                        <MUIDataTable
                          className="table-sm px-3"
                          title={"Cancelled Tax Invoice"}
                          data={this.state.CancelledInvoice}
                          columns={columns}
                          options={optionsc}
                        />
                        </ThemeProvider>
                        :''}
                        </>:this.state.type==="Proforma Invoice"?<>
                        {this.state.active === true?
                        <ThemeProvider theme={theme}> 
                        <MUIDataTable
                          className="table-sm px-3"
                          title={"Active Proforma Invoice"}
                          data={this.state.proformaInvoice}
                          columns={columns}
                          options={options2}
                        />
                        </ThemeProvider>
                        :''}
                        {this.state.comp === true?
                        <ThemeProvider theme={theme}> 
                        <MUIDataTable
                          className="table-sm px-3"
                          title={"Completed Proforma Invoice"}
                          data={this.state.proformaInvoiceCompleted}
                          columns={columns}
                          options={optionsc}
                        />
                        </ThemeProvider>
                        :''}
                        </>:<>
                         {this.state.rent === true?
                          <ThemeProvider theme={theme}> 
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Rental Invoice"}
                            data={this.state.rentInvoice}
                            columns={rentcolumns}
                            options={rentoptions}
                          />
                          </ThemeProvider>
                          :''}
                          {this.state.sales === true?
                          <ThemeProvider theme={theme}> 
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"Excess Sales Invoice"}
                            data={this.state.soldInvoice}
                            columns={rentcolumns}
                            options={rentoptions}
                          />
                          </ThemeProvider>
                          :''}
                        </>
                        }
                        </>}
                        </div>
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          
          </div>
        </div>
        <Footer/>  
      </div>        
    )  
  }  
}
export default withRouter(InvoiceTable)


