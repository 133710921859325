import { prefix1 } from "../Credentials";
import FileServices from "./FileServices";

class UploadFilesService {
  upload(file,year,curmonth,doctype,id,day, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("client", prefix1);
    formData.append("id", id);
    formData.append("year",year);
    formData.append("month",curmonth);
    formData.append("type",doctype);
    formData.append("day",day);


  return FileServices.fileUpload(formData, 
          onUploadProgress,
        );
  }

  logoupload(file,year,curmonth,doctype,id,day, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    formData.append("client", id);
    formData.append("id", id);
    formData.append("year",year);
    formData.append("month",curmonth);
    formData.append("type",doctype);
    formData.append("day",day);


  return FileServices.fileUpload(formData, 
          onUploadProgress,
        );
  }

}
export default new UploadFilesService();
