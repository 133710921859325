import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import TourServices from '../../Invoice/TourServices';
import Header from "../../../Component/Header";
import Menu from "../../../Component/Menu";
import Footer from "../../../Component/Footer";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import withRouter from "../../../withRouter";
import { currentTenant, currentUser } from "../../../Common/Credentials";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";


const MCar = (props) => {
  const [checked, setChecked] = useState(true);
  const nevigate = useNavigate()
  const [data, setData] = useState({
    id: 0,
    plateno: "",
    make: "",
    model: "",
    colour: "",
    type: "",
    
    createddate: new Date(),
    modifieddate: new Date(),
  });
  const [data1, setData1] = useState({
    id: "",
    plateno: "",
    make: "",
    model: "",
    year: "",
    colour: "",
    type: "",
    regexpdate: "",
    createddate: "",
    modifieddate: "",
  });

  const [insurancedate,setInsurancedate]=useState('');
  const [regexpdate,setRegexpdate]=useState('');
  const [year,setYear]=useState('');



  useEffect(() => {
    console.log(props.location.state.id);
    if(props.location.state.id !== -1)
    { 
      TourServices.getByCarId(props.location.state.id).then(r=>{
        console.log(r.data,"console");
        setData({
          plateno: r.data.plateno,
          make: r.data.make,
          model: r.data.model,

          colour: r.data.colour,
          type: r.data.type,

          createddate: r.data.createddate,
          modifieddate: r.data.modifieddate,
        })
        setInsurancedate(moment(r.data.insurancedate).format("YYYY-MM-DD"))
        setRegexpdate(moment(r.data.regexpdate).format("YYYY-MM-DD"))
        setYear( moment(r.data.year).format("YYYY")); 
      })


    }
  },[])
  const handleChange = (event) => {
    setChecked(event.target.checked);

  };
  const changeHandler = (e) => {
    setData(prevValues => {
      return { ...prevValues, [e.target.name]: e.target.value }
    })
  }

  const AddHandler = (e) => {
    console.log("sdasd");
    e.preventDefault();
    if (props.location.state.id === -1) {

      const sdata = {
        id: data.id,
        plateno: data.plateno,
        make: data.make,
        model: data.model,
        year: year,
        colour: data.colour,
        type: data.type,
        place: data.place,
        insuranceexpdate: insurancedate,
        regexpdate: regexpdate,
        createddate: new Date(),
        modifieddate: new Date(),
        tenantid: currentTenant,
      }
      TourServices.addMCar(sdata).then((r) => {
        console.log(r.data);

      })
      nevigate("/mastercarlist");
    }
    else
    {
      const sdata = {
        id: data.id,
        plateno: data.plateno,
        make: data.make,
        model: data.model,
        year: year,
        colour: data.colour,
        typeno: data.type,
        place: data.place,
        insuranceexpdate: insurancedate,
        regexpdate: regexpdate,
        createddate: data.createddate,
        modifieddate: new Date(),
        tenantid: currentTenant,
      }
      TourServices.updateMCar(sdata,props.location.state.id).then(r=>{
        console.log(r.data);
        nevigate("/mastercarlist");
      })
    }

  }
const changeHadlerInsurancedate=(e)=>{
  setInsurancedate(moment(new Date(e)).format("YYYY-MM-DD"))
}
const changeHadlerInsuranceExpdate=(e)=>{
  setRegexpdate(moment(new Date(e)).format("YYYY-MM-DD"))
}
const changeHadlerYear=(e)=>{
  setYear(moment(new Date(e)).format("YYYY"))
}

  return (
    <>
    <Header/>
    <Menu/>
      <div className="content-wrapper w-100% h-100%">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
          <span
                as="button"
                className=" text-secondary cursorPointer"
                
              >
                <Link to="/mastercarlist" style={{color:"grey"}}>❮ Back &nbsp;&nbsp;</Link>
                
              </span>
            <li className="breadcrumb-item text-secondary">
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/mastercarlist">MCar List </Link>
            </li>
            <li className="breadcrumb-item active">Master Car</li>
          </ol>
     
            <form >
              <div className="card-body p-6">
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Plate No"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="plateno"
                      value={data.plateno}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Make"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="make"
                      value={data.make}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Model"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="model"
                      value={data.model}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <Grid container >
                    <DatePicker
                      variant="inline"
                      id="date-picker-dialog"
                      label="Year"
                      views={['year']}
                      value={year}
                      onChange={changeHadlerYear}
                      renderInput={(params) => <TextField {...params} size="small"  />}
                      autoOk={true}
                    />
                  </Grid>
                </LocalizationProvider>
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Car Colour"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="colour"
                      value={data.colour}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      required="true"
                      label="Car Type "
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="type"
                      value={data.type}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12 my-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                  <Grid container >
                    <DatePicker
                      variant="inline"
                      id="date-picker-dialog"
                      label="Insurance Exp Date "
                      
                      value={insurancedate}
                      onChange={changeHadlerInsurancedate}
                      renderInput={(params) => <TextField {...params} size="small"  />}
                      autoOk={true}
                    />
                  </Grid>
                </LocalizationProvider>
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12 my-2">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container >
                    <DatePicker
                      variant="inline"
                      id="date-picker-dialog"
                      label="Reg. Exp date"
                      // format="dd/MM/yyyy"
                      // maxDate={new Date()}
                      value={regexpdate}
                      onChange={(e)=>changeHadlerInsuranceExpdate(e)}
                      renderInput={(params) => <TextField {...params} size="small" fullWidth />}
                      autoOk={true}
                    />
                  </Grid>
                </LocalizationProvider>
                  </fieldset>
                </div>
                
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="my-2">
                      <span>Created By : Jnanasoftware</span>
                    </div>
                 
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className=" my-2">
                      <span>Modified By : {currentUser}</span>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <span>Created Time : {moment(data.createddate).format("DD-MM-YYYY")}</span>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <span>Modified Time : {moment(data.modifieddate).format("DD-MM-yyyy")} </span>
                    </div>
                  </div>
                  <div className="btn d-flex justify-context-center">
                    <button className="btn buttonColour btn-sm  "  onClick={AddHandler}>Save</button>
                  </div>
                </div>
              </div>
            </form>
          
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default withRouter(MCar);
