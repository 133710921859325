import { Form, Formik } from "formik";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import {NumericFormat} from "react-number-format";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import { thousandsdisplay, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";

class SalesReportDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.location.state.data,
      data2: this.props.location.state.data2,
      item: this.props.location.state.item,
      pagename: this.props.location.state.pagename,
      selected: "1",
      filterOptions: [
        { id: "1", name: "Item" },
        { id: "2", name: "Customer" },
      ],
    };
  }

  onChange(option) {
    this.setState({ selected: option.id });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
  }
  render() {
    const column1 = [
      {
        name: "customer",
        label: "Customer",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="text-info"
                  to={{pathname: "/salesreportdetails2"}}
                    state={{
                      data: this.state.data2.filter(
                        (e) => e.customer === value
                      ),
                      item: value,
                      pagename: "Sales of Perticular Item by Customer"
                  }}
                >
                  {value}
                </Link>
                {/* //<Tooltip id="view details" place="top" effect="solid">
                  View Details
                </Tooltip> */}
              </>
            );
          },
        },
      },
      // {
      //   name: "custid",
      //   label: "Customer ID",
      //   options: { filter: false, sort: true,sortOrder : 'asc',},
      // },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "pending",
        label: "Pending Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "accepted",
        label: "Invoiced Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "delivered",
        label: "Delivered Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "cancelled",
        label: "Cancelled/Preclose Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "totalprice",
        label: "Total Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total(Inc.Tax)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    ];

    const column2 = [
      {
        name: "itemname",
        label: "Item",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="text-info"
                  to={{pathname: "/salesreportdetails2"}}
                    state= {{
                      data: this.state.data2.filter(
                        (e) => e.itemname === value
                      ),
                      item: value,
                      pagename: "Sales of Perticular Customer by Item"
                  }}
                >
                  {value}
                </Link>
                {/* //<Tooltip id="view details" place="top" effect="solid">
                  View Details
                </Tooltip> */}
              </>
            );
          },
        },
      },
      // {
      //   name: "itemid",
      //   label: "Item ID",
      //   options: { filter: false, sort: true,sortOrder : 'asc',},
      // },

      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "pending",
        label: "Pending Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "accepted",
        label: "Invoiced Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "delivered",
        label: "Delivered Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "cancelled",
        label: "Cancelled/Preclose Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "totalprice",
        label: "Total Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total(Inc.Tax)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    ];

    const column3 = [
      {
        name: this.state.selected === "1" ? "itemname" : "customer",
        label: this.state.selected === "1" ? "ItemName" : "Customer",
        options: { filter: false, sort: true, sortOrder: "asc" },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "totalprice",
        label: "Total Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total(Inc.Tax)",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
           
            <ol className="breadcrumb float-sm-right">
              <li className="text-secondary">
              <span
                as="button"
                className="float-left text-secondary "
                onClick={() => this.props.navigate(-1)}
              >
               ❮ Back &nbsp;&nbsp;
              </span>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/salesreport">Sales Reports</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>

            <div className="card-body">
              <div className="text-center">
                {this.state.loading === true ? spin : ""}
              </div>
              <Formik initialValues={{}} enableReinitialize={true}>
                {() => (
                  <Form autoComplete="off">
                    {this.state.pagename === "Sales by User" ? (
                      <>
                        {this.state.filterOptions.map((option) => {
                          return (
                            <label key={option.id}>
                              <div className="form-group col-md-9 text-sm">
                                <div className="btn-group">
                                  <input
                                    className={
                                      this.state.selected === option.id
                                      ? "btn hovborderbuttonColour"
                                      : "btn hovbuttonColour"
                                    }
                                    type="button"
                                    key={option.id}
                                    onClick={this.onChange.bind(this, option)}
                                    style={{ marginRight: 8, width: 180 }}
                                    value={option.name}
                                  />
                                </div>
                              </div>
                            </label>
                          );
                        })}
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.pagename === "Sales by Products" ? (
                      <MUIDataTable
                        className="table-sm px-3"
                        title={`${this.state.item}`}
                        data={this.state.data}
                        columns={column1}
                        options={options}
                      />
                    ) : (
                      ""
                    )}

                    {this.state.pagename === "Sales by Customer" ? (
                      <MUIDataTable
                        className="table-sm px-3"
                        title={`${this.state.item}`}
                        data={this.state.data}
                        columns={column2}
                        options={options}
                      />
                    ) : (
                      ""
                    )}

                    {this.state.pagename === "Sales by User" ? (
                      <MUIDataTable
                        className="table-sm px-3"
                        title={`${this.state.item}`}
                        data={
                          this.state.selected === "1"
                            ? this.state.data.item
                            : this.state.data.cust
                        }
                        columns={column3}
                        options={options}
                      />
                    ) : (
                      ""
                    )}

                    {this.state.pagename === "Sales by Agent" ? (
                      <MUIDataTable
                        className="table-sm px-3"
                        title={`${this.state.item}`}
                        data={this.state.data}
                        columns={column2}
                        options={options}
                      />
                    ) : (
                      ""
                    )}
                    {console.log(this.state.item)}
                    {console.log(this.state.data)}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(SalesReportDetails);
