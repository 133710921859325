import React, { Component } from 'react'
import AuthenticationService from '../Authentication/AuthenticationService'


export default class Maincontent extends Component {

    constructor(props){
        super(props)
        this.state = {
            welcomeMessage: '',
            errorMessage: '',
            user: sessionStorage.getItem('authenticatedUser')

        }
        this.RestWelcome = this.RestWelcome.bind(this)
        this.successfullResponse= this.successfullResponse.bind(this)

    }

    RestWelcome(){
        // HelloWorldSerice.executeHelloWorldService()
        // .then(response => this.successfullResponse(response))

        // HelloWorldSerice.executeHelloWorldBeanService()
        // .then(response => this.successfullResponse(response))

        AuthenticationService.executeHelloWorldService(this.state.user)
        .then(response => this.successfullResponse(response))


    }

    successfullResponse(response){
        this.setState({welcomeMessage:response.data.message})
    }

   

    render() {
        return (
            <div>
              <div className="content-wrapper">

               
               </div>
            </div>
        )
    }
}
