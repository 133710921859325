import React, { useState, useEffect } from "react";
// import { Link } from 'react-router-dom';
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import EmployeeService from "./EmployeeService";
import CustomToolbar from "../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";
import {
  Checkbox,
  createTheme,
  FormControlLabel,
  TableFooter,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ThemeProvider,
  Backdrop,
  CircularProgress,
  Box,
  Paper,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  currentTenant,
  financialyearend,
  financialyearstart,
  thousandsdisplay,
  userid,
} from "../Common/Credentials";
import Employeeservices from "../Masters/Employees/Employeeservices";
import { NumericFormat } from "react-number-format";
import Swal from "sweetalert2";
import { CSVLink } from "react-csv";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
// -------------------------

// import font from './font.css'
const SalaryComponent = () => {
  const [selected, setSelected] = useState(0);
  const [salary, setSalary] = useState([]);
  const [salaryBk, setSalaryBk] = useState([]);
  const [salaryprogress, setSalaryProgress] = useState([]);
  // const [pendingsalaryprogress, setPendingalaryProgress] = useState([]);
  // const [allsalaryprogress, setAllsalaryProgress] = useState([]);
  const [smsalaryprogress, setSmsalaryprogress] = useState("");
  const [overall, setOverall] = useState(0);
  const [month, setMonth] = useState(new Date());
  const [empadvances, setEmpadvances] = useState([]);
  const [monthoverview, setMonthoverview] = useState(false);
  const [selectedmonth, setSelectedMonth] = useState([]);
  const [fysalaries, setFySalaries] = useState([]);
  const [emplist, setEmplist] = useState([]);
  const [startyear, setStartYear] = useState(financialyearstart);
  const [endyear, setEndYear] = useState(financialyearend);
  const [loader, setLoader] = useState(false);
  const [selectedSalaries, setSelectedSalaries] = useState([]);
  const [SalariesTableData, setSalariesTableData] = useState(
    []
  );
  const [salarytype, setSalarytype] = useState("Pending Salaries");

  useEffect(() => {
    // EmployeeService.getMonthwiseSalryProgress(currentTenant).then((res)=>{
    //   setAllsalaryProgress(res.data)
    // })
    let employeeslist = [];
    let sallist = [];
    let empadvlist = [];
    async function fetchMyAPI() {
      await Employeeservices.getAllEmployees(currentTenant).then((res) => {
        setEmplist(res.data);
        employeeslist = res.data;
      });
      await EmployeeService.getAllSalaries(currentTenant).then((response) => {
        setSalary(response.data);
        sallist = response.data;
      });

      await EmployeeService.GetEmployeeAdvancesoftenant(currentTenant).then(
        (response) => {
          setEmpadvances(response.data);
          empadvlist = response.data.filter((e) => e.status === "Paid");
        }
      );
    }

    let data = {
      tenantid: currentTenant,
      startdate: moment(financialyearstart),
      enddate: moment(financialyearend),
    };
    EmployeeService.getallfyempsalaryoftenant(data).then((res) => {
      setFySalaries(res.data);
    });
    // .catch(()=>Refresh(employeeslist,[],new Date()))

    //   EmployeeService.getAllSalaryBrkup(currentTenant).then((response) => {
    //     setSalaryBk(response.data);
    // })
    //     .catch(err => console.log(err));
    // EmployeeService.getAllSalProgress(currentTenant)
    // .then((response) => {
    //   setSalaProgress(response.data);
    // })
    // .catch((err) => console.log(err));
    fetchMyAPI().then(() => {
      // console.log(employeeslist,sallist,empadvlist)
      Refresh(employeeslist, sallist, empadvlist, new Date());
    });
  }, []);

  const Refresh = (orgemployeeslist, sallist, adv, month) => {
    let spdata = [];
    let emplist = [];
    let employeeslist = [];
    let salarydata = [];
    let data = {
      tenantid: currentTenant,
      startdate: moment(month).set("day", 1),
      enddate: moment(month).set("day", 4),
    };
    let empadv = adv.filter(
      (e) =>
        parseInt(moment(new Date(e.startdate)).format("MM")) <=
          parseInt(moment(new Date(month)).format("MM")) &&
        parseInt(moment(new Date(e.startdate)).format("yyyy")) <=
          parseInt(moment(new Date(month)).format("yyyy"))
    );
    setSelectedSalaries([]);
    setSalariesTableData([]);
    EmployeeService.getMonthwiseSalryProgress(data).then((res) => {
      // setAllsalaryProgress(res.data)
      // setFySalaries(res.data.filter(e=>((moment(`${e.year} ${e.month}`).format('yyyy MM')>=moment(financialyearstart).format('yyyy MM')) && (moment(`${e.year} ${e.month}`).format('yyyy MM')<=moment(financialyearend).format('yyyy'))) ));

      if (res.data !== null && res.data !== undefined && res.data.length > 0) {
        spdata = res.data.find(
          (e) =>
            e.month === parseInt(moment(new Date(month)).format("MM")) &&
            e.year === parseInt(moment(new Date(month)).format("YYYY"))
        );
        if (spdata !== null && spdata !== undefined) {
          setSmsalaryprogress(spdata);
          setOverall(spdata.total);
          setSalaryProgress(spdata.salaryprogress);
          spdata = spdata.salaryprogress;
        }
      }
      employeeslist = orgemployeeslist.filter((e) =>
        moment(new Date(e.doj), "yyyy-mm").isSame(
          moment(new Date(month), "yyyy-mm")
        )
      );
      emplist = orgemployeeslist.filter((e) =>
        moment(new Date(e.doj), "yyyy-mm").isBefore(
          moment(new Date(month), "yyyy-mm")
        )
      );
      emplist = emplist.map((e) => e.customid);

      if (spdata === null || spdata === undefined || spdata.length === 0) {
        sallist.map((e) => {
          salarydata.push({
            // id: 2,
            radio: "Full",
            attendance: 30,
            month: parseInt(moment(month).format("MM")),
            year: parseInt(moment(month).format("YYYY")),
            status: null,
            basicSalary: parseFloat(e?.basicSalary / 12).toFixed(2),
            totalVal: (
              parseFloat(e?.netpay / 12) -
              parseFloat(
                empadv.length === 0
                  ? 0
                  : empadv.find((a) => a.empid === e.empid) === undefined
                  ? 0
                  : empadv.find((a) => a.empid === e.empid)?.deductionamount
              )
            ).toFixed(2),
            additionalbenefits: parseFloat(e?.additionalbenefits / 12).toFixed(
              2
            ),
            grossSalary: parseFloat(e?.grossSalary / 12).toFixed(2),
            epf: parseFloat(e?.epf / 12).toFixed(2),
            healthInsurance: parseFloat(e?.healthInsurance / 12).toFixed(2),
            professionalTax: parseFloat(e?.professionalTax / 12).toFixed(2),
            incometax: parseFloat(e?.incometax / 12).toFixed(2),
            netpay: parseFloat(e?.netpay / 12).toFixed(2),
            advance:
              empadv.length === 0
                ? 0
                : empadv.find((a) => a.empid === e.empid) === undefined
                ? 0
                : empadv.find((a) => a.empid === e.empid)?.deductionamount,
            totalDeduction: parseFloat(e?.totalDeduction / 12).toFixed(2),
            tenantid: e.tenantid,
            empid: e.empid,
            empname: e.empname,
            firstname: e.firstname,
            lastname: e.lastname,
            createdby: e.createdby,
            updatedby: e.updatedby,
          });
        });
        salarydata = salarydata.filter((d) => emplist.includes(d.empid));
        setSalaryProgress(salarydata);
        setSmsalaryprogress("");
        let total = salarydata.reduce(
          (a, b) => (a = a + parseFloat(b.totalVal)),
          0
        );
        setOverall(total);
      } else {
        let idlist = spdata.map((d) => d.empid);
        sallist.map((e) => {
          if (!idlist.includes(e.empid)) {
            spdata.push({
              // id: 2,
              radio: "Full",
              attendance: 30,
              month: parseInt(moment(month).format("MM")),
              year: parseInt(moment(month).format("YYYY")),
              status: null,
              button: true,
              basicSalary: parseFloat(e?.basicSalary / 12).toFixed(2),
              totalVal: (
                parseFloat(e?.netpay / 12) -
                parseFloat(
                  empadv.length === 0
                    ? 0
                    : empadv.find((a) => a.empid === e.empid) === undefined
                    ? 0
                    : empadv.find((a) => a.empid === e.empid)?.deductionamount
                )
              ).toFixed(2),
              additionalbenefits: parseFloat(
                e?.additionalbenefits / 12
              ).toFixed(2),
              grossSalary: parseFloat(e?.grossSalary / 12).toFixed(2),
              epf: parseFloat(e?.epf / 12).toFixed(2),
              healthInsurance: parseFloat(e?.healthInsurance / 12).toFixed(2),
              professionalTax: parseFloat(e?.professionalTax / 12).toFixed(2),
              incometax: parseFloat(e?.incometax / 12).toFixed(2),
              netpay: parseFloat(e?.netpay / 12).toFixed(2),
              advance:
                empadv.length === 0
                  ? 0
                  : empadv.find((a) => a.empid === e.empid) === undefined
                  ? 0
                  : empadv.find((a) => a.empid === e.empid)?.deductionamount,
              totalDeduction: parseFloat(e?.totalDeduction / 12).toFixed(2),
              tenantid: e.tenantid,
              empid: e.empid,
              empname: e.empname,
              firstname: e.firstname,
              lastname: e.lastname,
              createdby: e.createdby,
              updatedby: e.updatedby,
            });
          }
        });
        setSalaryProgress(spdata);
        onSalaryTypeChange(salarytype,spdata)
        if (spdata.length > 0) {
          let total = spdata.reduce(
            (a, b) => (a = a + parseFloat(b.totalVal)),
            0
          );
          setOverall(total);
        } else {
          setOverall(0);
        }
      }
    });
  };


  // ---------- Salary Type Change --------------------------

  const onSalaryTypeChange = (value,sp)=>{
        setSalarytype(value);
        setSelectedSalaries([]);
        setSalariesTableData(sp.filter((e) =>(e.status === "Approved" &&value === "Approved Salaries") ||
            (e.status === "Pending" && value === "Pending Approval Salaries") ||
            ((e.status === null || e.status === "Revise") && value === "Pending Salaries")
        ))
  }
                        


  // ------------------------- calculate Over All amount  ------------------------

  const CalcOverAllAmt = (sp) => {
    let total = sp.reduce(
      (a, b) => (a = a + parseFloat(b.totalVal)),
      0
    );
    setOverall(total);
  };

  // --------------------------- Handle Attendenc Type  Change --------------------

  const handleChange = (event, i, e) => {
    if (event === "Full") {
      console.log(salaryprogress)
      SalariesTableData.find((e, index) => index === i).radio = "Full";
      SalariesTableData.find((emp, index) => index === i).attendance =
        moment(month).daysInMonth();
      SalariesTableData.find((emp, index) => index === i).totalVal = parseFloat(
        e.basicSalary
      ).toFixed(2);
      setSalariesTableData([...SalariesTableData]);
      CalcOverAllAmt(SalariesTableData);
    } else {
      let data = SalariesTableData;
      data.find((e, index) => index === i).radio = "Manually";
      setSalariesTableData([...data]);
    }
  };

  // ------------------- MANUALLY NO OF DAYS PRESENT CHANGED FUNCTION ------------------------
  const ManualChange = (event, i, e) => {
    if(event < 32){
    SalariesTableData.find((emp, index) => index === i).attendance = event;
    SalariesTableData.find((emp, index) => index === i).totalVal = (
      parseFloat(e.basicSalary / 30) * event
    ).toFixed(2);
    setSalariesTableData([...SalariesTableData]);
    CalcOverAllAmt(SalariesTableData);
    }
  };

  const monthHandler = (e) => {
    setMonth(new Date(e));
    Refresh(emplist, salary, empadvances, new Date(e));
  };

  const yearHandler = (e) => {
    let diff =
      moment(financialyearstart).format("yyyy") ===
      moment(financialyearend).format("yyyy");
    let smonth = moment(financialyearstart).format("MM");
    let emonth = moment(financialyearend).format("MM");
    let syear = moment(new Date(e)).format("yyyy");
    let eyear = moment(new Date(e))
      .add(diff ? 0 : 1, "y")
      .format("yyyy");
    setStartYear(moment(`${syear} ${smonth}`));
    setEndYear(moment(`${eyear} ${emonth}`).format("yyyy MM"));
    // setFySalaries(allsalaryprogress.filter(e=>((moment(`${e.year} ${e.month}`).format('yyyy MM')>=moment(`${syear} ${smonth}`).format('yyyy MM')) && (moment(`${e.year} ${e.month}`).format('yyyy MM')<= moment(`${eyear} ${emonth}`).format('yyyy MM'))) ));

    let data = {
      tenantid: currentTenant,
      startdate: moment(new Date(e)),
      enddate: moment(new Date(e)).add(diff ? 0 : 1, "y"),
    };

    EmployeeService.getallfyempsalaryoftenant(data).then((res) => {
      setFySalaries(res.data);
    });
  };

  // PAY HANDLER
  const payHandler = () => {
    // setSalaryProgress(employee);
    // employee.map((e) => {
    setLoader(true);

    if (smsalaryprogress.id !== undefined && smsalaryprogress.id !== null) {
      let data = {
        id: smsalaryprogress.id,
        tenantid: currentTenant,
        month: smsalaryprogress.month,
        year: smsalaryprogress.year,
        total: overall,
        createdby: userid,
        updatedby: userid,
        salaryprogress: selectedSalaries,
      };
      EmployeeService.UpdateSalaryProgress(data).then((r) => {
        setLoader(false);
        Swal.fire({
          title: r.data,
          text: ".",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        }).then(() => Refresh(emplist, salary, empadvances, month));
      });
    } else {
      let data = {
        tenantid: currentTenant,
        month: parseInt(moment(month).format("MM")),
        year: parseInt(moment(month).format("YYYY")),
        total: overall,
        createdby: userid,
        updatedby: userid,
        salaryprogress: selectedSalaries,
      };
      EmployeeService.addingSalaryProgress(data).then((r) => {
        setLoader(false);
        Swal.fire({
          title: r.data,
          text: ".",
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        }).then(() => Refresh(emplist, salary, empadvances, month));
      });
    }
  };

  const HandleStatusUpdate = (s) => {
    setLoader(true);
    let data = {
      updatedby: userid,
      id: smsalaryprogress.id,
      status: s,
      salaryprogress: selectedSalaries,
    };
    EmployeeService.UpdateSPStatus(data).then((r) => {
      setLoader(false);
      Swal.fire({
        title: r.data,
        text: ".",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      }).then(() => Refresh(emplist, salary, empadvances, month));
    });
  };

  // const viewHandler = () => {
  //   // EmployeeService.()
  // };

  const delteHandler = (id) => {
    Swal.fire({
      title: "Delete Employee Advances?",
      text: "Are you sure you want to delete?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        EmployeeService.deleteemployeeadvance(id)
          .then((response) => {
            Swal.fire({
              title: response.data,
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => {
              EmployeeService.GetEmployeeAdvancesoftenant(currentTenant).then(
                (response) => {
                  setEmpadvances(response.data);
                }
              );
            });
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });
  };

  const AdvanceApproveStatus = (id) => {
    let data = empadvances.find((e) => e.id === id);
    Swal.fire({
      title: "Approve Employee Advances?",
      text: "Are you sure you want to Approve?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        EmployeeService.UpdateEmployeeAdvanceStatus(data)
          .then((response) => {
            Swal.fire({
              title: response.data,
              text: ".",
              icon: "success",
              confirmButtonText: "Ok",
              confirmButtonColor: "#152f5f",
            }).then(() => {
              EmployeeService.GetEmployeeAdvancesoftenant(currentTenant).then(
                (response) => {
                  setEmpadvances(response.data);
                }
              );
            });
          })
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
      }
    });
  };

  const AddtoSalaryProgress = () => {
    EmployeeService.UpdateSalaryProgress(smsalaryprogress).then(() =>
      Refresh(emplist, salary, empadvances, month)
    );
  };

  function TableToolbar() {
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Salary Payment
        </Typography>
        <Tooltip title={"download"}>
          <IconButton>
            <CSVLink data={salaryprogress} headers={excelcolumns}>
              <i className="fa fa-file-excel text-green"></i>
            </CSVLink>
          </IconButton>
        </Tooltip>
      </Toolbar>
    );
  }

  const excelcolumns = [
    { label: "Employee Name", key: "empname" },
    { label: "Employee ID", key: "empid" },
    { label: "Account No", key: "accno" },
    { label: "Account Name", key: "accname" },
    { label: "Bank Name", key: "bankname" },
    { label: "Branch Name", key: "branchname" },
    { label: "IFSC Code", key: "ifsc" },
    { label: "Amount", key: "totalVal" },
  ];

  const columns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        sortOrder: "asc",
      },
    },
    {
      name: "empname",
      label: "Employee",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: "basicSalary",
      label: "Basic Salary",
      options: {
        filter: true,
        sort: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    // {
    //   name: "houseRentAllowances",
    //   label: "House Rent Allowances",
    //   options: { filter: true, sort: true },
    // },

    // {
    //   name: "conveyanceAllowances",
    //   label: "Conveyance Allowances",
    //   options: { filter: true, sort: true },
    // },
    // {
    //   name: "medicalAllowances",
    //   label: "medical Allowances",
    //   options: { filter: true, sort: true },
    // },
    // {
    //   name: "specialAllowances",
    //   label: "Special Allowances",
    //   options: { filter: true, sort: true },
    // },
    {
      name: "additionalbenefits",
      label: "Additional Benefits",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "grossSalary",
      label: "Gross Salary",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },

    {
      name: "epf",
      label: "EPF",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },

    {
      name: "healthInsurance",
      label: "Health Insurance",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "professionalTax",
      label: "Professional Tax",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "incometax",
      label: "Income Tax",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "totalDeduction",
      label: "Total Deduction",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },

    {
      name: "netpay",
      label: "Net Salary",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    customToolbar: () => {
      return (
        <Link
          as="button"
          className="float-right btn btn-sm m-0 p-0"
          to={{ pathname: "/addemployeesalary" }}
          state={{
            id: -1,
            pagename: "New Employee Salary",
            emplist: salary.map((e) => e.empid),
          }}
        >
          <CustomToolbar />
        </Link>
      );
    },
  };

  const sbcolumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        sortOrder: "asc",
      },
    },
    {
      name: "salaryfor",
      label: "Salary For",
      options: { filter: true, sort: true },
    },
    {
      name: "houserentallowance",
      label: "House rent Allowance (%)",
      options: { filter: true, sort: true },
    },
    {
      name: "conveyanceallowance",
      label: "Conveyance Allowance (%)",
      options: { filter: true, sort: true },
    },
    {
      name: "medicalallowance",
      label: "Meidcal Allowance (%)",
      options: { filter: true, sort: true },
    },
    {
      name: "specialallowance",
      label: "Special Allowance (%)",
      options: { filter: true, sort: true },
    },
    {
      name: "epf",
      label: "EPF (%)",
      options: { filter: true, sort: true },
    },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <button
                as="button"
                data-tip
                data-for="delete"
                className="btn btn-sm deletebutton"
                // to={{ state: {id: tableMeta.rowData.find(e=>e) ,pagename:"salaryBk" }  }}
                onClick={() =>
                  delteDataHandler(tableMeta.rowData.find((e) => e))
                }
              >
                Delete
              </button>
            </>
          );
        },
      },
    },
  ];

  const sboptions = {
    filterType: "checkbox",
    selectableRows: "none",
    customToolbar: () => {
      return (
        <Link
          as="button"
          className="float-right btn btn-sm m-0 p-0"
          to={{ pathname: "/newemployeesalarybreakup" }}
          state={{
            id: -1,
            pagename: "New Salary Breakup",
            emplist: salary.map((e) => e.customid),
          }}
        >
          <CustomToolbar />
        </Link>
      );
    },
  };

  const spcolumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        sortOrder: "asc",
      },
    },
    {
      name: "month",
      label: "Month",
      options: {
        filter: true,
        sort: true,

        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span
              className="cursorPointer text-info"
              onClick={() => {
                setMonthoverview(true);
                setSelectedMonth(
                  fysalaries?.find((e) => e.id == tableMeta.rowData[0])
                );
              }}
            >
              {moment(value, "M").format("MMMM")}
            </span>
          );
        },
      },
    },
    {
      name: "salaryprogress",
      label: "Basic Salary",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value.reduce((a, b) => (a = a + b.basicSalary), 0)}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "salaryprogress",
      label: "Additional Benefits",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value.reduce((a, b) => (a = a + b.additionalbenefits), 0)}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "salaryprogress",
      label: "Gross Salary",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value.reduce((a, b) => (a = a + b.grossSalary), 0)}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "salaryprogress",
      label: "Total Deduction",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value.reduce((a, b) => (a = a + b.totalDeduction), 0)}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },

    {
      name: "salaryprogress",
      label: "Net Salary",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value.reduce((a, b) => (a = a + b.netpay), 0)}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "salaryprogress",
      label: "Advance",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value.reduce((a, b) => (a = a + b.advance), 0)}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "total",
      label: "Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3 text-right"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: { filter: true, sort: true },
    },
  ];

  const spoptions = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    rowsPerPage: 10,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <Table className="text-md">
          <TableBody>
            <TableRow>
              <TableCell className="font-weight-bold">Total</TableCell>
              <TableCell align="right" className="font-weight-bold">
                <NumericFormat
                  className="pr-3"
                  displayType={"text"}
                  value={fysalaries.reduce((a, b) => (a = a + b.total), 0)}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={true}
                  thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      );
    },
  };

  const monthcolumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        sortOrder: "asc",
      },
    },
    {
      name: "empname",
      label: "Employee Name",
      options: {
        filter: true,
        sort: true,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            left: 0,
            background: "white",
            zIndex: 101,
          },
        }),
      },
    },
    {
      name: "basicSalary",
      label: "Basic Salary",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "additionalbenefits",
      label: "Additional Benefits",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "epf",
      label: "EPF",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "healthInsurance",
      label: "Health Insurance",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "professionalTax",
      label: "Professional Tax",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "professionalTax",
      label: "Professional Tax",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "incometax",
      label: "Income Tax",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "totalDeduction",
      label: "Total Deduction",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "totalDeduction",
      label: "Total Deduction",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },

    {
      name: "netpay",
      label: "Net Salary",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },

    {
      name: "advance",
      label: "Advance",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "totalVal",
      label: "Total",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },

    {
      name: "status",
      label: "Status",
      options: { filter: true, sort: true },
    },
  ];

  const monthoptions = {
    filter: true,
    selectableRows: "none",
    filterType: "dropdown",
    rowsPerPage: 10,
    customFooter: (count, page, rowsPerPage, changeRowsPerPage, changePage) => {
      return (
        <TableFooter>
          <TableRow>
            <TableCell>
              <button
                className="btn btn-sm hovbuttonColour"
                onClick={() => setMonthoverview(false)}
              >
                {" "}
                ❮ {moment(selectedmonth.year, "yyyy").format("yyyy")}{" "}
              </button>
            </TableCell>
          </TableRow>
        </TableFooter>
      );
    },
  };

  const advcolumns = [
    {
      name: "id",
      label: "Id",
      options: {
        filter: false,
        sort: false,
        display: false,
        sortOrder: "asc",
      },
    },
    {
      name: "empname",
      label: "Employee",
      options: { filter: true, sort: true },
    },
    {
      name: "advance",
      label: "Advance",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "deduction",
      label: "No of months",
      options: { filter: true, sort: true },
    },
    {
      name: "deductionamount",
      label: "Amount per month(%)",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "balance",
      label: "Balance",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <NumericFormat
              className="pr-3"
              displayType={"text"}
              value={value}
              decimalScale={2}
              fixedDecimalScale={true}
              disabled={true}
              thousandSeparator={true}
              thousandsGroupStyle={thousandsdisplay}
            ></NumericFormat>
          );
        },
      },
    },
    {
      name: "createdAt",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{moment(value).format("DD-MM-yyyy hh:mm")}</span>;
        },
      },
    },
    {
      name: "status",
      label: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return value === "Pending" || value === null ? (
            <>
              <Link
                className="btn btn-sm hovbuttonColour mr-1"
                to={{ pathname: "/addemployeeadvace" }}
                state={{
                  id: tableMeta.rowData.find((e) => e),
                  pagename: "Edit Employee Advance",
                }}
              >
                Edit
              </Link>
              <button
                as="button"
                data-tip
                data-for="delete"
                className="btn btn-sm hovbuttonColour mr-1"
                onClick={() =>
                  AdvanceApproveStatus(tableMeta.rowData.find((e) => e))
                }
              >
                Approve
              </button>
              <button
                as="button"
                data-tip
                data-for="delete"
                className="btn btn-sm deletebutton mr-1"
                onClick={() => delteHandler(tableMeta.rowData.find((e) => e))}
              >
                Delete
              </button>
            </>
          ) : (
            <span>{value}</span>
          );
        },
      },
    },
  ];

  const advoptions = {
    filterType: "checkbox",
    selectableRows: "none",
    customToolbar: () => {
      return (
        <Link
          as="button"
          className="float-right btn btn-sm m-0 p-0"
          to={{ pathname: "/addemployeeadvace" }}
          state={{
            id: -1,
            pagename: "Add Employee Advance",
            emplist: empadvances.map((e) => e.empid),
          }}
        >
          <CustomToolbar />
        </Link>
      );
    },
  };

  // Function ends

  return (
    <>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/accounts">Home</Link>
            </li>
            <li className="breadcrumb-item active">Employees Salary</li>
          </ol>
          <div className="card-body">
            <div className="row">
              <fieldset
                className={`form-group col-md-3  shadow-sm text-center btn ${
                  selected === 0 ? "" : " hovbuttonColour"
                }`}
                onClick={() => setSelected(0)}
                disabled={selected === 0}
              >
                Employees Salary
              </fieldset>
              {/* <fieldset className={`form-group col-md-3 shadow-sm btn text-center ${selected === 1?'':' hovbuttonColour'}`} onClick={()=>setSelected(1)} disabled={selected === 1}>
                      Salary Breakup
                      </fieldset> */}
              <fieldset
                className={`form-group col-md-3 shadow-sm btn text-center ${
                  selected === 2 ? "" : " hovbuttonColour"
                }`}
                onClick={() => setSelected(2)}
                disabled={selected === 2}
              >
                Salary Payment
              </fieldset>
              <fieldset
                className={`form-group col-md-3 shadow-sm btn text-center ${
                  selected === 3 ? "" : " hovbuttonColour"
                }`}
                onClick={() => setSelected(3)}
                disabled={selected === 3}
              >
                Salary View
              </fieldset>
              <fieldset
                className={`form-group col-md-3 shadow-sm btn text-center ${
                  selected === 4 ? "" : " hovbuttonColour"
                }`}
                onClick={() => setSelected(4)}
                disabled={selected === 4}
              >
                Employee Advances
              </fieldset>
            </div>

            {/* Mapping the employeeDetails */}
            {/* {salary.map(em=>{
                            //console.log(em.empployeeDetails);

                            a=[...a,];
                            a.push(em.salary);
                            //console.log(a);
                        })} */}
            {selected === 0 ? (
              <ThemeProvider theme={theme}>
                <MUIDataTable
                  className="table-sm px-3"
                  title={"Employees Annual Salary (CTC)"}
                  data={salary}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>
            ) : (
              ""
            )}

            {selected === 1 ? (
              <ThemeProvider theme={theme}>
                <MUIDataTable
                  className="table-sm px-3"
                  title={"Salary Breakup"}
                  data={salaryBk}
                  columns={sbcolumns}
                  options={sboptions}
                />
              </ThemeProvider>
            ) : (
              ""
            )}

            {selected === 3 ? (
              monthoverview ? (
                <ThemeProvider theme={theme}>
                  <MUIDataTable
                    className="table-sm px-3"
                    title={`Salary Overview of ${moment(
                      selectedmonth.month,
                      "M"
                    ).format("MMMM")} - ${moment(
                      selectedmonth.year,
                      "yyyy"
                    ).format("yyyy")}`}
                    data={selectedmonth?.salaryprogress}
                    columns={monthcolumns}
                    options={monthoptions}
                  />
                </ThemeProvider>
              ) : (
                <>
                  <div className="form-row">
                    <fieldset className="col-md-3 my-2">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container justifyContent="space-around">
                          <DatePicker
                            variant="inline"
                            id="date-picker-dialog"
                            label="Select Financial Start Year"
                            openTo="year"
                            views={["year"]}
                            minDate={"2022"}
                            maxDate={new Date()}
                            value={startyear}
                            onChange={yearHandler}
                            renderInput={(params) => (
                              <TextField {...params} variant="standard" />
                            )}
                          />
                        </Grid>
                      </LocalizationProvider>
                    </fieldset>
                    {/* <fieldset className="col-md-3 my-2">
                         <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container justifyContent="space-around">
                              <DatePicker
                                variant="inline"
                                id="date-picker-dialog"
                                label="Select Financial End Year"
                                // openTo="month"
                                views={["year"]}
                                // minDate={"2022"}
                                // maxDate={new Date()}
                                value={endyear}
                                // onChange={monthHandler}
                                renderInput={(params) => (
                                  <TextField {...params} variant="standard" disabled/>
                                )}
                                disabled
                              />
                            </Grid>
                          </LocalizationProvider>
                          </fieldset> */}
                    <fieldset className="col-md-12" />
                  </div>
                  <ThemeProvider theme={theme}>
                    <MUIDataTable
                      className="table-sm px-3"
                      title={`Salary view of financial year ${moment(
                        startyear
                      ).format("MMM yyyy")} - ${moment(endyear).format(
                        "MMM yyyy"
                      )}`}
                      data={fysalaries}
                      columns={spcolumns}
                      options={spoptions}
                    />
                  </ThemeProvider>
                </>
              )
            ) : (
              ""
            )}

            {selected === 4 ? (
              <ThemeProvider theme={theme}>
                <MUIDataTable
                  className="table-sm px-3"
                  title={"Employee Advances"}
                  data={empadvances}
                  columns={advcolumns}
                  options={advoptions}
                />
              </ThemeProvider>
            ) : (
              ""
            )}

            {selected === 2 ? (
              <div className="form-row">
                <fieldset className="form-group col-md-3">
                  <FormControl variant="standard" className="col-md-12">
                    <InputLabel id="demo-simple-select-standard-label">
                      Select
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={salarytype}
                      onChange={(e) => { 
                        onSalaryTypeChange(e.target.value,salaryprogress)
                        setSalarytype(e.target.value);
                        setSelectedSalaries([]);
                      }}
                      label="Vehicle Type"
                      fullWidth
                    >
                      <MenuItem value={"Pending Salaries"}>
                        Pending Salaries
                      </MenuItem>
                      <MenuItem value={"Pending Approval Salaries"}>
                        Pending Approval Salaries
                      </MenuItem>
                      <MenuItem value={"Approved Salaries"}>
                        Approved Salaries
                      </MenuItem>
                    </Select>
                  </FormControl>
                </fieldset>
                <fieldset className="col-md-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container justifyContent="space-around">
                      <DatePicker
                        variant="inline"
                        id="date-picker-dialog"
                        label="Select Month and Year"
                        openTo="month"
                        views={["year", "month"]}
                        minDate={moment().add(-1, "month")}
                        maxDate={new Date()}
                        value={month}
                        onChange={monthHandler}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" />
                        )}
                      />
                    </Grid>
                  </LocalizationProvider>
                </fieldset>
                <fieldset className="col-md-12" />

                {/* <fieldset className="col-md-12">
                        <ThemeProvider theme={theme}>
                       <MUIDataTable
                         className="table-sm px-3"
                         title={"Salary Breakup"}
                         data={employee}
                         columns={spcolumns}
                         options={spoptions}
                       />
                        </ThemeProvider>

                        </fieldset> */}
                <div className="col-md-12 card mt-3">
                  <TableContainer>
                    <TableToolbar />
                    <Table className="table-sm">
                      <TableHead>
                        <TableRow>
                          {salarytype === "Approved Salaries" ||  SalariesTableData.length === 0 ? (
                            ""
                          ) : (
                            <TableCell padding="checkbox">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={
                                      selectedSalaries.length ===
                                      SalariesTableData.length
                                    }
                                    onChange={() => {
                                      if (
                                        selectedSalaries.length ===
                                        SalariesTableData.length
                                      ) {
                                        setSelectedSalaries([]);
                                      } else {
                                        setSelectedSalaries(SalariesTableData);
                                      }
                                    }}
                                    inputProps={{
                                      "aria-label": "select all desserts",
                                    }}
                                  />
                                }
                              />
                            </TableCell>
                          )}
                          <TableCell align="center" className="stickyColumn">
                            Employee
                          </TableCell>
                          <TableCell align="center">Basic Salary</TableCell>
                          <TableCell align="center">
                            Additional Benefits{" "}
                          </TableCell>
                          <TableCell align="center">Gross Salary</TableCell>
                          <TableCell align="center">EPF</TableCell>
                          <TableCell align="center">Health Insurance</TableCell>
                          <TableCell align="center">Professional Tax</TableCell>
                          <TableCell align="center">Income Tax</TableCell>
                          <TableCell align="center">Netpay</TableCell>
                          <TableCell align="center">Advance</TableCell>
                          <TableCell align="center">Present DAYS</TableCell>
                          <TableCell align="center">Total Payable</TableCell>
                          {salarytype === "Approved Salaries" ? (
                            <TableCell align="center">Payment Status</TableCell>
                          ) : (
                            ""
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {SalariesTableData.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={12} className="text-center">
                              Sorry, no matching records found
                            </TableCell>
                          </TableRow>
                        ) : (
                          SalariesTableData.map((e, i) => (
                              <TableRow
                                key={i}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                {salarytype === "Approved Salaries" || SalariesTableData.length === 0 ? (
                                  ""
                                ) : (
                                  <TableCell padding="checkbox">
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          color="primary"
                                          // indeterminate={numSelected > 0 && numSelected < rowCount}
                                          checked={
                                            selectedSalaries.filter(
                                              (el) => el.empid === e.empid
                                            ).length > 0
                                          }
                                          onChange={() => {
                                            if (
                                              selectedSalaries.filter(
                                                (el) => el.empid === e.empid
                                              ).length > 0
                                            ) {
                                              setSelectedSalaries(
                                                SalariesTableData.filter(
                                                  (el) => el.empid !== e.empid
                                                )
                                              );
                                            } else {
                                              setSelectedSalaries((prev) => [
                                                ...prev,
                                                e,
                                              ]);
                                            }
                                          }}
                                          inputProps={{
                                            "aria-label": "select all desserts",
                                          }}
                                        />
                                      }
                                    />
                                  </TableCell>
                                )}
                                <TableCell
                                  align="center"
                                  className="stickyColumn"
                                >
                                  {e.empname}
                                </TableCell>
                                <TableCell align="right">
                                  <NumericFormat
                                    className="pr-3"
                                    displayType={"text"}
                                    value={e.basicSalary}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                </TableCell>
                                <TableCell align="right">
                                  <NumericFormat
                                    className="pr-3"
                                    displayType={"text"}
                                    value={e.additionalbenefits}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                </TableCell>
                                <TableCell align="right">
                                  <NumericFormat
                                    className="pr-3"
                                    displayType={"text"}
                                    value={e.grossSalary}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                </TableCell>
                                <TableCell align="right">
                                  <NumericFormat
                                    className="pr-3"
                                    displayType={"text"}
                                    value={e.epf}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                </TableCell>
                                <TableCell align="right">
                                  <NumericFormat
                                    className="pr-3"
                                    displayType={"text"}
                                    value={e.healthInsurance}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                </TableCell>
                                <TableCell align="right">
                                  <NumericFormat
                                    className="pr-3"
                                    displayType={"text"}
                                    value={e.professionalTax}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                </TableCell>
                                <TableCell align="right">
                                  <NumericFormat
                                    className="pr-3"
                                    displayType={"text"}
                                    value={e.incometax}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                </TableCell>
                                {/* <TableCell align="center">{(parseFloat(e.professionalTax)/12).toFixed(3)}</TableCell> */}
                                <TableCell align="right">
                                  <NumericFormat
                                    className="pr-3"
                                    displayType={"text"}
                                    value={e.netpay}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                </TableCell>
                                <TableCell align="right">
                                  <NumericFormat
                                    className="pr-3"
                                    displayType={"text"}
                                    value={e.advance}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                </TableCell>

                                <TableCell align="center">
                                  {e.button !== undefined ||
                                  e.status === "Revise" || e.status === null ? (
                                    <div className="m-0 d-flex">
                                      <div className="col-md-6">
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              checked={e.radio === "Full"}
                                              onChange={(t) =>
                                                handleChange("Full", i, e)
                                              }
                                            />
                                          }
                                          size="small"
                                          label="Full"
                                          className="m-0"
                                        />
                                      </div>
                                      {e.radio === "Manually" ? (
                                        <div className="col-md-6">
                                          <TextField
                                            type="number"
                                            name={`days${i}`}
                                            label="Days"
                                            value={e.attendance}
                                            inputProps={{ min: 0, max: 30 }}
                                            onChange={(x) =>
                                              ManualChange(x.target.value, i, e)
                                            }
                                            variant="standard"
                                            fullWidth
                                          />
                                        </div>
                                      ) : (
                                        <div className="col-md-6">
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={e.radio === "Manually"}
                                                onChange={(t) =>
                                                  handleChange("Manually", i, e)
                                                }
                                              />
                                            }
                                            size="small"
                                            label="No. of Days"
                                            className="m-0"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    e.attendance
                                  )}
                                </TableCell>

                                <TableCell align="right">
                                  <NumericFormat
                                    className="pr-3"
                                    displayType={"text"}
                                    value={e.totalVal}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                </TableCell>

                                {salarytype === "Approved Salaries" ? (
                                  <TableCell align="right">
                                    {e.status}
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                              </TableRow>
                            ))
                        )}
                        <TableRow>
                          <TableCell
                            className="font-weight-bold"
                            colSpan={
                              salarytype === "Approved Salaries" || SalariesTableData.length === 0 ? 11 : 12
                            }
                          >
                            Total{" "}
                          </TableCell>
                          <TableCell align="right" className="font-weight-bold">
                            <NumericFormat
                              className="pr-3"
                              displayType={"text"}
                              value={SalariesTableData.reduce((a, b) => a + Number(b.totalVal), 0)}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              disabled={true}
                              thousandSeparator={true}
                              thousandsGroupStyle={thousandsdisplay}
                            ></NumericFormat>
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <div className="d-flex justify-content-end me-4 my-2">
                    {selectedSalaries.length > 0 ? (
                      <>
                        {salarytype === "Pending Salaries" ? (
                          <button
                            className="btn btn-sm hovbuttonColour mr-2"
                            onClick={payHandler}
                          >
                            Send Payment to Approve
                          </button>
                        ) : salarytype === "Pending Approval Salaries" ? (
                          smsalaryprogress.approvestatus === "Processing" ? (
                            <>
                              <button
                                className="btn btn-sm hovbuttonColour mr-2"
                                onClick={() => HandleStatusUpdate(1)}
                              >
                                Approve
                              </button>
                              <button
                                className="btn btn-sm hovbuttonColour mr-2"
                                onClick={() => HandleStatusUpdate(2)}
                              >
                                Revise
                              </button>
                            </>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default withRouter(SalaryComponent);
