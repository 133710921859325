import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, userid } from "../../Common/Credentials.js";
import { spin } from "../../Common/NewLoader.jsx";
import { appmasterid } from "../../Common/Permissions.js";
import CustomToolbar from "../../CommonComponents/CustomToolbar.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import UserService from "../../users/UserService.js";
import CategoryServices from "./CategoryServices.js";
import { ThemeProvider,Tooltip } from "@mui/material";
import { createTheme } from "@mui/material";



const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});
class Category extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: "",
      category: [],
      loading: false,
    };
    // this.refreshCustomer=this.refreshCustomer.bind(this)
    this.deletecategory = this.deletecategory.bind(this);
  }

  deletecategory(id) {
    CategoryServices.deleteMainCategory(id).then(
        ()=>this.refreshcategory()
    //   CategoryServices.getCategory(currentTenant).then(
    //     (response) => this.setState({ category: response.data }),
    //     this.setState({ message: "Category Deleted Successfully" })
    //   )
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  refreshcategory() {
    CategoryServices.getMainCategory(currentTenant).then((response) => {
      this.setState({ category: response.data });
    //   const script = document.createElement("script");
    //   script.src = "../../../js/content.js";
    //   script.async = true;
    //   document.body.appendChild(script);
      this.setState({ loading: false });
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    UserService.UserPermissions(userid).then((response) =>
      this.setState({ up: response.data })
    );

    this.setState({ loading: true });

    this.refreshcategory();
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: true,
          display: false,
          viewColumns: false,
          sortOrder: "asc",
        },
      },
      {
        name: "categorytype",
        label: "Category Type",
        options: { filter: false, sort: true },
      },
      {
        name: "category",
        label: "Category",
        options: { filter: true, sort: true },
      },
     
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          // empty: true,
          // display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
              <Link
                as="button"
                data-tip
                data-for="view"
                className="btn btn-sm hovbuttonColour mr-1"
                to={{pathname: "/categorylvl2"}}
                  state={ this.state.category.find(e=>e.id===tableMeta.rowData.find((e) => e))}
              > level 2 Categories</Link>
              {/* <Tooltip id="view" place="top" effect="solid">
              view level 2 Categories
              </Tooltip> */}
              {this.state.category.find(e=>e.id===tableMeta.rowData.find((e) => e)).categorylvltwo.length === 0?
              <span
                as="button"
                data-tip
                data-for="delete"
                className="btn btn-sm deletebutton"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you wish to delete Category ${tableMeta.rowData.find(
                        (e, i) => i.valueOf(e)
                      )}?`
                    )
                  )
                    this.deletecategory(tableMeta.rowData.find((e) => e));
                }}
              > Delete</span>
              :''}
               </>
            );
          },
        },
      },
      // {
      //   name: "View Sub Categories",
      //   options: {
      //     filter: false,
      //     sort: false,
      //     empty: true,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       return (
      //         <>
      //           <Link
      //             as="button"
      //             data-tip
      //             data-for="view"
      //             className="btn btn-sm hovbuttonColour"
      //             to={{
      //               pathname: "/categorylvl2",
      //               state: this.state.category.find(e=>e.id===tableMeta.rowData.find((e) => e)),
      //             }}
      //           > Sub Categories</Link>
      //           //<Tooltip id="view" place="top" effect="solid">
      //             Sub Categories
      //           </Tooltip>
      //         </>
      //       );
      //     },
      //   },
      // },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{pathname: "/newcategory"}}
                  state={{ id: -1, name: "New Category"}}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
          
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>

              <li className="breadcrumb-item active">Category </li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    {/* /.card */}
                      <div className="card-body">
                        <div>
                          {this.state.loading === true ? (
                            <div className="text-center">{spin}</div>
                          ) : (
                            <ThemeProvider theme={theme}> 
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Category"}
                              data={this.state.category}
                              columns={columns}
                              options={options}
                            />
                            </ThemeProvider>
                          )}
                        </div>
                      </div>
                      {/* /.card-body */}
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Category);
