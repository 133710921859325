import { Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, userid } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { FormikTextField } from '../../CommonComponents/FormField.js';

import CustomerTermsServices from './CustomerTermsServices.js';
import { Autocomplete } from '@mui/material';
import { Spinner } from 'react-bootstrap';
import { FormControlLabel } from '@mui/material';
import IOSSwitch from '../../CommonComponents/IOSSwitch.js';
import Swal from 'sweetalert2';


class CustomerTerms extends Component {
  constructor(props) {
    super(props)
    const  pagename  = this.props.location.state.pagename;
    const  id  = this.props.location.state.id;
    this.state = {
      pagename: pagename,
      id:id,
      customer: '',
      custid: '',
      creditValue: '',
      creditPeriod: '',
      customersdata: [],
      status:false,
      loader:false,
    }
    this.onSubmit = this.onSubmit.bind(this)
    this.validate = this.validate.bind(this);
  }

  handleSelect = () => {
    this.setState({status: !this.state.status});
  }

  onSubmit(values) {
    this.setState({loader:true})
    let customerterms = {
      custid: values.custid,
      customer: values.customer,
      creditvalue: parseFloat(values.creditValue),
      creditperiod: parseFloat(values.creditPeriod),
      creditstatus: this.state.status === false?'0':'1',
      tenantid: currentTenant
    }
    
    if (this.state.id === -1) {
      CustomerTermsServices.NewCustomerTerms(customerterms)
      .then((res) => {
        this.setState({loader:false})
        if(res.data === "Customer Already exists"){
          Swal.fire({
            title:'Customer terms already exists for this customer.',
            text:'',
            icon:'warning',
            confirmButtonText:'Ok',
            confirmButtonColor:'#152f5f'
          })
        }else{
          this.props.navigate(`/customerterms`) 
        }
      }
      )
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    } else {
      CustomerTermsServices.UpdateCustomerTerms(customerterms)
      .then(() => 
        this.props.navigate(`/customerterms`)   
      )
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    }

   
  };

  refreshCustomers() {
    CustomerTermsServices.retriveAllCustomers(currentTenant)
    .then((res) => this.setState({
      customersdata: res.data
    }))
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
    if (this.state.id === -1) {
      return
    }
    
    CustomerTermsServices.GetCustomerTermsById(this.state.id)
      .then(res => this.setState({
        customer: res.data.customer,
        custid: res.data.custid,
        creditValue: res.data.creditvalue,
        creditPeriod: res.data.creditperiod,
        status: res.data.creditstatus === 'Required' ? true : false 
      }))
  }

  componentDidMount() {
    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    this.refreshCustomers()
  }

  validate(values) {

    let errors = {}

    if (values.customer === ''| values.customer==='Select Customer'){
      errors.customer="Customer is required";
    }

    if( this.state.status === true){
      if(this.state.creditValue ===''){
        errors.creditValue="CreditValue is required";
      }
       if(this.state.creditPeriod ===''){
        errors.creditPeriod="CreditPeriod is required";
      }
    }
    
    return errors;
  }

  render() {
    let { custid, customer, creditPeriod, creditValue, status,customersdata } = this.state
 
    return (
      <div>
        <Header />
         <Menu/>
      
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className='text-Secondary'>
            <Link as="button" className=" text-secondary" to={{pathname: '/customerterms'}} >  ❮ Back &nbsp;&nbsp;</Link> 
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/masters">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/customerterms">Customer Terms</Link>
            </li>
              <li className="breadcrumb-item active">{ this.state.pagename}</li>
            </ol>
            <div className="card">
              <div className="card-body">
                <Formik
                  initialValues={{custid,customer,creditPeriod,creditValue,status,customersdata}}
                  onSubmit={this.onSubmit}
                  validateOnChange={false}
                  validate={this.validate}
                  enableReinitialize={true}>
                  {({setFieldValue,values}) => (
                    <Form autoComplete="off">
                      <div className="form-row">
                      {this.state.pagename === 'Edit Customer Terms'? 
                        <fieldset className="form-group col-md-4" disabled>
                          <FormikTextField className="form-control" type="text" name="customer" label="Customer" value={this.state.customer} />
                        </fieldset>
                     :
                        <fieldset className="form-group col-md-4">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="customer"
                            options={customersdata}
                            getOptionLabel={(option) => option.companyname+"("+option.id+")"}
                            value={values.customer?undefined:undefined}
                            onChange={(e, value) => {
                              setFieldValue("customer", value);
                            }}
                            renderInput={params => (  
                              <FormikTextField
                                {...params} 
                                className="form-control"
                                type="text"
                                name="customer"
                                onBlur={(e) =>{ this.setState({ custid: values.customer.id, customer:values.customer.companyname })}}
                                value={this.state.customer}
                                label="Select Customer"
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>
                      }
                        <div className="form-group col-md-12"/>
                  
                        <fieldset className="form-group col-md-6" >
                          <div className=" from-row ">
                            <span> Require Credit ?</span>
                            <FormControlLabel
                          control={<IOSSwitch className="sm ml-5" checked={this.state.status} onChange={this.handleSelect} name="checkedB" />}
                        />
                          </div>
                        </fieldset> 
                        <div className="form-group col-md-6"/>

                        { this.state.status === true?
                          <>
                              <fieldset className="form-group col-md-4">
                                <FormikTextField className="form-control" type="number" name="creditValue" label="CreditValue" value={this.state.creditValue} onChange={(e) => this.setState({ creditValue: e.target.value === undefined || e.target.value === null || e.target.value ===''|| e.target.value<0?0:e.target.value })} />
                              </fieldset>
                            <div className="form-group col-md-12" />
                           
                              <fieldset className="form-group col-md-4">
                                <FormikTextField className="form-control" type="number" name="creditPeriod" label="CreditPeriod" value={this.state.creditPeriod} onChange={(e) => this.setState({ creditPeriod: e.target.value === undefined || e.target.value === null || e.target.value ===''|| e.target.value<0?0:e.target.value })}>Days</FormikTextField>
                              </fieldset>
                           
                          </>
                          :''
                        }
                      </div>
                      <div className="form-group col-md-12" />
                      <button className="btn btn-sm hovbuttonColour" type="onSubmit" >
                      {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</>:"Save"}
                      </button>
                      <Link as="button" className="btn btn-sm deletebutton ml-3" to={{pathname: '/customerterms'}} ><b style={{color:"#FA5733"}}> X</b> Cancel </Link> 
                    </Form>
                  )}  
                </Formik>
              </div>
            </div>
          </div>
        </div>
      <Footer /> 
    </div>
    )
  }
}

export default withRouter(CustomerTerms)
