import React, { useState } from "react";
import NewImmediateprocess from "./NewImmediateprocess";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter";
import TemplateImmediateProduction from "./TemplateImmediateProduction";

const ImmediateProduction = () => {
  const [type, setType] = useState("Default");
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to="/immediateproduction"
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/production">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/immediateproduction">Immediate production</Link>
            </li>
            <li className="breadcrumb-item active">New Immediate production</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">New Immediate production</h3>
                    </div>
                    <div className="card-body">
                      <div className="form-row">
                        <fieldset className="form-group col-md-12">
                          <fieldset
                            className={`form-group col-md-3 shadow-sm  text-center btn ${
                              type === "Default"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                            }`}
                            disabled={type === "Default"}
                            onClick={() => setType("Default")}
                          >
                            Default
                          </fieldset>
                          <fieldset
                            className={`form-group col-md-3  btn shadow-sm text-center ${
                              type === "Using Template"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                            }`}
                            disabled={type === "Using Template"}
                            onClick={() => setType("Using Template")}
                          >
                            Using Template
                          </fieldset>
                        </fieldset>
                      </div>

                      {type === "Using Template" ? <TemplateImmediateProduction/> : <NewImmediateprocess />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(ImmediateProduction);
