import { Form, Formik } from "formik";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import {NumericFormat} from "react-number-format";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import InventoryService from "../InventoryService";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
class StockReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportsdata: [],
      filterOptions: [
        { id: 0, name: "OverAll" },
        { id: 1, name: "Items / Products" },
        { id: 2, name: "Warehouse" },
      ],
      selected: 0,
      loading: true,
      products: "",
      warehouses: "",
      productslist: [],
      warehouselist: [],
      custbyproducts: [],
      filterdata: [],
    };
  }

  // handlefilter(value){
  // }

  onChange(option) {
    this.setState({ selected: option.id });
  }
  Productdata = () => {
    this.setState({ productslist: [], custbyproducts: [] }, () => {
      // Sorting  Products
      const products = this.state.filterdata.reduce(
        (
          item,
          {
            itemname,
            itemid,
            category,
            stockitemtype,
            stocktype,
            cost,
            currentStock,
            totalStock,
            createdAt,
          }
        ) => {
          if (!item[itemname]) item[itemname] = [];
          item[itemname].push({
            itemid: itemid,
            itemname: itemname,
            category: category,
            createdAt: createdAt,
            stockitemtype: stockitemtype,
            stocktype: stocktype,
            cost: cost,
            currentStock: currentStock,
            totalStock: totalStock,
          });
          return item;
        },
        {}
      );
      this.setState({ products: products });
      // End of Sorting Products

      // Calcuating total products
      Object.entries(products).forEach(([key, value]) => {
        let a = {
          itemname: key,
          itemid: value.map((e) => e.itemid)[0],
          currentStock: value.map((e) => e.currentStock)[0],
          category: value.map((e) => e.category)[0],
          totalStock: value.map((e) => e.totalStock)[0],
          // internaluse:value.reduce((a, v) => (a = a + v.currentStock), 0),
          // resale:value.reduce((a, v) => (a = a + v.currentStock), 0)
        };
        this.state.productslist.push(a);
      });
      // End of Calcuating total products
    });
  };

  Warehousedata = () => {
    this.setState({ warehouselist: [] }, () => {
      const warehouses = this.state.filterdata.reduce(
        (
          wh,
          {
            wid,
            whaddress,
            itemid,
            itemname,
            category,
            stockitemtype,
            stocktype,
            cost,
            currentStock,
            totalStock,
            whstock,
          }
        ) => {
          if (!wh[whaddress]) wh[whaddress] = [];
          wh[whaddress].push({
            wid,
            whaddress,
            itemid,
            itemname,
            category,
            stockitemtype,
            stocktype,
            cost,
            currentStock,
            totalStock,
            whstock,
          });
          return wh;
        },
        {}
      );
      this.setState({ warehouses: warehouses });
      // End of Sorting Products

      // Calcuating total products
      Object.entries(warehouses).forEach(([key, value]) => {
        let a = {
          whaddress: key,
          whid: value.map((e) => e.whid)[0],
          whstock:value.map(e=>e.whstock)[0]
          // whstock: value.reduce((a, v) => (a = a + v.whstock), 0),
          // currentStock: value.map((e) => e.currentStock)[0],
          // category: value.map((e) => e.category)[0],
          // totalStock: value.map((e) => e.totalStock)[0],
        };
        this.state.warehouselist.push(a);
      });
    });
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    InventoryService.GetStockReports(currentTenant)
      .then((response) => {
        this.setState(
          {
            reportsdata: response.data.sort((a, b) =>
              a.itemname > b.itemname ? 1 : -1
            ),
            filterdata: response.data.sort((a, b) =>
              a.itemname > b.itemname ? 1 : -1
            ),
            loading: false,
          },
          () => {
            this.Productdata();
            this.Warehousedata();
          }
        );
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }
  render() {
    const columns = [
      {
        name: "itemid",
        label: "ItemId",
        options: {
          filter: false,
          sort: false,
          viewColumns: false,
          display: false,
        },
      },
      {
        name: "itemname",
        label: "Item name",
        options: { filter: false, sort: true },
      },
      {
        name: "category",
        label: "Category",
        options: { filter: true, sort: true },
      },
      {
        name: "cost",
        label: "Unit Cost",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "whaddress",
        label: "Warehouse",
        options: { filter: true, sort: true },
      },
      {
        name: "addedStock",
        label: "Added Stock",
        options: { filter: false, sort: true, setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: "currentStock",
        label: "Current Stock",
        options: { filter: false, sort: true, setCellProps: (value) => ({ style: { textAlign: "center" } }),},
      },
      // {
      //   name: "whstock",
      //   label: "Org Unit Stock",
      //   options: {
      //     filter: false,
      //     sort: true,
      //     customBodyRender: (value) => {
      //       return <>{value === null || value === undefined ? 0 : value}</>;
      //     },
      //     setCellProps: (value) => ({ style: { textAlign: "center" } }),
      //   },
      // },
      {
        name: "totalStock",
        label: "Total Stock",
        options: { filter: false, sort: true,setCellProps: (value) => ({ style: { textAlign: "center" } }), },
      },
      {
        name: "stocktype",
        label: "Type",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "stockfrom",
        label: "From",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];

    const productscolumns = [
      {
        name: "itemid",
        label: "ItemId",
        options: {
          filter: false,
          sort: false,
          viewColumns: false,
          display: false,
        },
      },
      {
        name: "itemname",
        label: "Item / Product",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "category",
        label: "Category",
        options: { filter: true, sort: true },
      },
      {
        name: "totalStock",
        label: "Total Stock",
        options: { filter: false, sort: true },
      },
    ];

    const warehousecolumns = [
      {
        name: "whaddress",
        label: "Warehouse",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="text-info"
                  to={{pathname: "/warehousereport"}}
                    state = {{
                      data: Object.entries(this.state.warehouses).find(
                        ([key]) => key.includes(value)
                      )[1],
                      item: value,
                  }}
                >
                  {value}
                </Link>
                {/* //<Tooltip id="view details" place="top" effect="solid">
                  View Details
                </Tooltip> */}
              </>
            );
          },
        },
      },
      {
        name: "whstock",
        label: "Org Unit Stock",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return <>{value === null || value === undefined ? 0 : value}</>;
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    return (
      <div>
      <Header/>
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item active">Stock Report</li>
            </ol>

            <div className="card-body">
              <div>
                {this.state.loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <Formik initialValues={{}} enableReinitialize={true}>
                    {({ setFieldValue, values }) => (
                      <Form autoComplete="off">
                        {this.state.filterOptions.map((option) => {
                          return (
                            <label key={option.id}>
                              <div className="form-group col-md-9 text-sm">
                                <div className="btn-group">
                                  <input
                                    className={`shadow-sm text-center btn
                                      ${this.state.selected === option.id
                                        ? "hovborderbuttonColour"
                                        : "hovbuttonColour"}
                                    `}
                                    type="button"
                                    key={option.id}
                                    onClick={this.onChange.bind(this, option)}
                                    style={{ marginRight: 8, width: 180 }}
                                    value={option.name}
                                  />
                                </div>
                              </div>
                            </label>
                          );
                        })}
                        {this.state.selected === 0 ? (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Stock Report"}
                              data={this.state.filterdata}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                        ) : (
                          ""
                        )}

                        {this.state.selected === 1 ? (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Stock Report(Products)"}
                              data={this.state.productslist}
                              columns={productscolumns}
                              options={options}
                            />
                          </ThemeProvider>
                        ) : (
                          ""
                        )}

                        {this.state.selected === 2 ? (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Stock Report(Warehouse)"}
                              data={this.state.warehouselist}
                              columns={warehousecolumns}
                              options={options}
                            />
                          </ThemeProvider>
                        ) : (
                          ""
                        )}
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(StockReport);
