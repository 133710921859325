import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { spin } from "../../Common/NewLoader";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import ProductionServices from "../ProductionServices";
import { currentTenant, thousandsdisplay, userid } from "../../Common/Credentials";
import { Autocomplete } from "@mui/material";
import Footer from "../../Component/Footer";
import { Form, Formik } from "formik";
import {NumericFormat} from "react-number-format";
import withRouter from "../../withRouter";

const NewIndenting =()=> {
  const navigate = useNavigate();
  const [joborder, setJoborder] = useState([]);
  const [joid, setJoId] = useState("");
  const [joborderList, setJoborderList] = useState("");
  const [joborderitemsList, setJobOrderitemsList] = useState([]);
  const [itemname, setItemName] = React.useState("");
  const [itemid, setItemId] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [iquantity, setIQuantity] = React.useState("");
  const [storeList, setStoreList] = React.useState([]);
  const [store,setStore] = React.useState("");
  const [storeid,setStoreId]= React.useState("");
  const [indentitemsList,setIndentItemsList] = React.useState([]);
  const [selectall,SetSelectAll] = useState(false);
  const [againstpo,setAgainstpo] = React.useState(false) 
  const [againststore,setAgainststore] = React.useState(true) 
  

  useEffect(() => {
    ProductionServices.getJobOrderIdList(currentTenant).then((response) => {
      setJoborder(response.data);
    });

  }, []);

  const handleSelectall = () =>{
    SetSelectAll(!selectall)
    if(!selectall === true){
      setIndentItemsList(joborderitemsList)
      setJobOrderitemsList([])
    }else{
      setIndentItemsList([])
      setJobOrderitemsList(indentitemsList)
    }
    setItemId("")
    setItemName("")
    setIQuantity(0)
    setQuantity(0)
  }

  const handlejoidChange = (newValue) => {
    newValue === "" || newValue === null || newValue === undefined
      ? setJoId("")
      : setJoId(newValue);
    setJoborderList([]);
    setIndentItemsList([])
    SetSelectAll(false)
    setItemId("")
    setItemName("")
    setIQuantity(0)
    setQuantity(0)
    if (newValue) {
      ProductionServices.getallJobOrderItems(newValue).then((response) => {
        setJoborderList(response.data);
        setJobOrderitemsList(response.data.bomitems)
        ProductionServices.GetallStoreNames(response.data.workcenterid).then(res=>setStoreList(res.data))
      });
    }
  };

  const handlestoreChange =  (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setStore("")
      setStoreId("")
    setIndentItemsList([])
    }else{
      setStore(newValue.name)
      setStoreId(newValue.id)
    setIndentItemsList([])
    }
  }

  const handleIndentItem = (e)=>{
    setItemName(e.itemname)
    setItemId(e.itemid)
    setQuantity(e.pendingquantity)
  }

  const handleIquantityChange = e=>{
    setIQuantity(e.target.value)
  }

  const handleAddtoList =() =>{
   if(itemid !== "" && itemname !== "" && iquantity !== "" && parseFloat(iquantity)>0 && parseFloat(iquantity)<=quantity ){
    if(indentitemsList.find(e=>e.itemid === itemid) === undefined){   
     let data = {
      itemid:itemid,
      itemname:itemname,
      quantity:parseFloat(iquantity),
      price:joborderitemsList.find(e=>e.itemid === itemid).price,
      uom:joborderitemsList.find(e=>e.itemid === itemid).uom,
      frequency:joborderitemsList.find(e=>e.itemid === itemid).frequency,
    }
    indentitemsList.push(data)
  }else(
    indentitemsList.find(e=>e.itemid === itemid).quantity = indentitemsList.find(e=>e.itemid === itemid).quantity + parseFloat(iquantity) 
  )
    joborderitemsList.find(a=>a.itemid === itemid).pendingquantity = joborderitemsList.find(a=>a.itemid === itemid).pendingquantity - parseFloat(iquantity)
    setItemId("")
    setItemName("")
    setIQuantity(0)
    setQuantity(0)
  }
}
const handleagainstpo=()=>{
  setAgainstpo(true)
  setAgainststore(false)
  setJobOrderitemsList(joborderList.bomitems)
  setIndentItemsList([])
  SetSelectAll(false)
  setItemId("")
    setItemName("")
    setIQuantity(0)
    setQuantity(0)
}
const handleagainststore =()=>{
 setAgainstpo(false)
 setAgainststore(true)
 setJobOrderitemsList(joborderList.bomitems)
 setIndentItemsList([])
 SetSelectAll(false)
 setItemId("")
    setItemName("")
    setIQuantity(0)
    setQuantity(0)
}


  const handleSubmit = () => {
    if(indentitemsList.length !== 0 && (againststore === true? store !== '':true)){
    let data = {
      joid: joborderList.joborder.id,
      tenantid: currentTenant,
      createdby: userid,
      updatedby: userid,
      storeid:storeid,
      store:store,
      indentingitems:indentitemsList,
      indentfrom:againstpo === true?1:0
    };
    ProductionServices.NewIndenting(data).then((response) =>
      navigate("/indenting")
    );
  }
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/indenting" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li> 
            <li className="breadcrumb-item text-secondary">
              <Link to="/production">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/indenting">Indenting</Link>
            </li>
            <li className="breadcrumb-item active">New Indenting</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              {joborder === [] ? (
                <div className="text-center">{spin}</div>
              ) : (
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title"> New Indenting</h3>
                  </div>
                  <div className="card-body">
                    <Formik
                      initialValues={{}}
                      // onSubmit={onSubmit}
                      validateOnChange={false}
                      // validate={validate}
                      enableReinitialize={true}
                    >
                      {({ setFieldValue, values }) => (
                        <Form
                          autoComplete="off"
                          style={{ paddingLeft: "30px" }}
                        >
                          <div className="form-row mt-3">
                            <fieldset className="col-md-3">
                              <Autocomplete
                                id="controlled-demo"
                                options={joborder}
                                getOptionLabel={(option) => option || ""}
                                
                                
                                value={joid}
                                onChange={(event, newValue) =>
                                  handlejoidChange(newValue)
                                }
                                error={joid === "" || joid === undefined}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select JobOrder Id"
                                    variant="standard"
                                    error={joid === "" || joid === undefined}
                                    helperText={
                                      joid === "" || joid === undefined
                                        ? "Empty field!"
                                        : " "
                                    }
                                  />
                                )}
                              />
                            </fieldset>
                            {againststore === true?
                            <fieldset className="form-group col-md-3">
                              <Autocomplete
                                id="subaccount"
                                className="subacc-select"
                                name="store"
                                options={storeList}
                                getOptionLabel={(option) => option.name}
                                
                                
                                value={values.store ? undefined : undefined}
                                onChange={handlestoreChange}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="form-control"
                                    type="text"
                                    name="store"
                                    value={store}
                                    label={`Select Store (${store})`}
                                    variant="standard"
                                    error={store === ""}
                                    helperText={
                                      store === "" ? "Empty field!" : " "
                                    }
                                    fullWidth
                                  />
                                )}
                              />
                            </fieldset>
                            :''}
                            <div  className="col-md-12">
                           
                                            <FormGroup row>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={againststore}
                                                    onChange={handleagainststore}
                                                    name="taxrequired"
                                                    color="primary"
                                                  />
                                                }
                                                label="Store Indent"
                                              />
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={againstpo}
                                                    onChange={handleagainstpo}
                                                    name="taxrequired"
                                                    color="primary"
                                                  />
                                                }
                                                label="Purchase Indent"
                                              />
                                            </FormGroup>
                                         
                            </div>
                         

                          {joborderitemsList.length === 0 ? (
                            ""
                          ) : (
                            <>
                              <fieldset className="col-md-12">
                                            <FormGroup row>
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={selectall}
                                                    onChange={handleSelectall}
                                                    name="taxrequired"
                                                    color="primary"
                                                  />
                                                }
                                                label="Select All?"
                                              />
                                            </FormGroup>
                                          </fieldset> 
                              <TableContainer>
                                <Typography> Items List</Typography>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="text-center">
                                        Itemname
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Price
                                      </TableCell>
                                      <TableCell className="text-center">
                                        UOM
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                       Pending Quantity
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Action
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {joborderitemsList.map((e, index) => (
                                      <TableRow key={index}>
                                        <TableCell className="text-center">
                                          {e.itemname}
                                        </TableCell>
                                        <TableCell className="text-right">
                                          <NumericFormat className="pr-3" displayType={'text'} value={e.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                        </TableCell>
                                        <TableCell className="text-center">
                                        {e.uom}
                                      </TableCell>
                                        <TableCell className="text-center">
                                          {e.quantity}
                                        </TableCell>
                                        <TableCell className="text-center">
                                          {e.pendingquantity}
                                        </TableCell>
                                        <TableCell className="text-center">
                                         {e.quantity === 0 ? '':<button className="btn btn-sm hovbuttonColour" onClick={()=>handleIndentItem(e)}>
                                          Indent 
                                            </button>
                                          }
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                              </>
                          )}
                      
                              {itemname === ""?'':<>
                              <fieldset className="col-md-3 mt-3">
                              <TextField
                                    className="form-control"
                                    type="text"
                                    name="itemname"
                                    value={itemname}
                                    label={`Item Name (${itemname})`}
                                    variant="standard"
                                    fullWidth
                                    disabled
                                  />
                              </fieldset>

                              <fieldset className="col-md-3 mt-3">
                              <TextField
                                    className="form-control"
                                    type="text"
                                    name="quantity"
                                    value={iquantity}
                                    label={`Indenting Quantity (Avilable quantity: ${quantity})`}
                                    onChange={handleIquantityChange}
                                    variant="standard"
                                    error={iquantity === "" || iquantity>quantity}
                                    helperText={
                                      iquantity === "" ? "Empty field!" : " "
                                    }
                                    fullWidth
                                  />
                              </fieldset>
                              
                              <fieldset className="col-md-12 mt-3">
                                <button
                                  className="btn btn-sm hovbuttonColour"
                                  onClick={handleAddtoList}
                                  type="button"
                                >
                                  Add to List
                                </button>
                              </fieldset>
                              </>}
                             {indentitemsList.length ===0?'':<>
                              <TableContainer>
                                <Typography>Indenting Items List</Typography>
                                <Table className="table-sm">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="text-center">
                                        Itemname
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Price
                                      </TableCell>
                                      <TableCell className="text-center">
                                        UOM
                                      </TableCell>
                                      <TableCell className="text-center">
                                        Quantity
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {indentitemsList.map((e, index) => (
                                      <TableRow key={index}>
                                        <TableCell className="text-center">
                                          {e.itemname}
                                        </TableCell>
                                        <TableCell className="text-right">
                                          <NumericFormat className="pr-3" displayType={'text'} value={e.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                                        </TableCell>
                                        <TableCell className="text-center">
                                        {e.uom}
                                      </TableCell>
                                        <TableCell className="text-center">
                                          {e.quantity}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>

                              <fieldset className="col-md-12 mt-3">
                                <button
                                  className="btn btn-sm hovbuttonColour"
                                  onClick={handleSubmit}
                                  type="button"
                                >
                                  Submit
                                </button>
                              </fieldset>
                              </>
                                }
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default withRouter(NewIndenting);
