import React, { Component } from 'react'
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import { FormikTextField } from '../CommonComponents/FormField.js';
import {Autocomplete,TextField} from '@mui/material';
// import { combdata} from './Journal';
import { NumericFormat } from 'react-number-format';
import { ccountry, currentTenant, currentUser, thousandsdisplay, userid ,financialyearend} from '../Common/Credentials';
import { FormLabel } from '@mui/material';
import { Typography} from '@mui/material';
import moment from 'moment';
import FileServices from '../Common/upload/FileServices';
import UploadFilesService from '../Common/upload/UploadFilesService';
import NewJournalService from './NewJournalService';
import { TableHead,TableBody,TableRow,TableContainer,Table, TableCell,Grid,FormControlLabel,Backdrop,CircularProgress} from "@mui/material";
import AuthenticationService from '../Authentication/AuthenticationService';
import { Spinner } from 'react-bootstrap';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { spin } from '../Common/NewLoader';
import Footer from '../Component/Footer';
import IOSSwitch from "../CommonComponents/IOSSwitch.js";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Swal from 'sweetalert2';
import SalesServices from '../Sales/SalesServices.js';

const getTotal = (totamt) => {
  let total = 0;
  totamt.forEach(totamt => {
    total += totamt;
  });
  return total;
};
let curmonth,year,day,pmtcheck,advstatus=0,totalcombdata=[]
let doctype = "supplierinvoices",paymentfor="",tcschecked=0,crdate
let selchk=[],gst,supname, totalpending=[],advancepaid,pmtid=null,advpoamt=0,debdata,val=[],poid=null,poadvance

let ppaymennts,ppaymennts2,jvtype=3,tcsdata
//,pgenpayments=[]

const Checkbox = props => (
  <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" {...props} />
)
class AccountsPayableEntry extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            pendingdata:this.props.location.state,
            partyid:null,poid:null,pmtid:null,
            tdsreq:[{ id: 0, name: "No" },{ id: 1, name: "Yes" }],
            // paymenttype: [{ id: 1, name: "GRN/SCN-Invoice" },{ id: 2, name: "Direct Invoice" },{ id: 3, name: "Advance" }],
            // pmttype:[{ id: 0, name: "Purchase" },{ id: 1, name: "Non-Purchase" }],
            debselval:'',pmtchecked:'',pnpchecked:'',tcschecked:0,selectedFiles: undefined,serviceamount:null,serviceledger:"",
            currentFile: undefined,progress: 0,message: "",isError: false,fileInfos: [],jvid:null,cjvid:null,
            fileInfo:"",pendingPayments:[],selectedPP:[],selectedPP2:[],selectedPPO:[],selectedPPO2:[],
            selchk:[],selchk1:[],selamt:[],selamt2:[],displaychecked:false,prevpmtschecked:false,advancepo:[],selectedadv:[],selamt3:[],
            selserv:[],selpmts:[],genpayment:[],Genadv:false,paymenttype:false,
            totalcombdata:[],SDdate:false,fed:'',debdata:[],transpending:'',custompoid:null,
            loader:false,loader1:true,jvinsertiontype:0,genadvancecurrentamt:null,selectedoption:null,
            genadvance:[{"id":"genadv","name":"General Advance","amount":0.00,"entrytype":0,"subacc":"","tenantId":"","rule":0,"behaviour":0,"subname":"z - NA","totamount":null,"th":"","taxtype":0}],
          }
        this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
        this.selectFile=this.selectFile.bind(this);this.upload=this.upload.bind(this);
        // this.CheckBoxSelect=this.CheckBoxSelect.bind(this);
        this.handleAdvpaid=this.handleAdvpaid.bind(this)
        this.loadData=this.loadData.bind(this)
        this.AddData=this.AddData.bind(this)
    }

    selectFile=event=> {
        this.setState({selectedFiles: event.target.files,});
      }

      upload() {
        let currentFile = this.state.selectedFiles[0];
         this.setState({progress: 100,currentFile: currentFile,});
        UploadFilesService.upload(currentFile,year,curmonth,doctype,this.state.partyid,day, (event) => {
          this.setState({progress: Math.round((100 * event.loaded) / event.total)});
        }).then((response) => {
         this.setState({message: response.data.message,isError: false});
            return FileServices.getFiles1(currentFile.name);
          }).then((files) => {this.setState({fileInfos: files.data,});
          }).catch(() => {
            this.setState({progress: 0,message: "Could not upload the file!",currentFile: undefined,
              isError: true}); });
        this.setState({selectedFiles: undefined,});
      }

      onDebBlur(values){
        this.setState({debselval:values.debit})
        if(values.debit!==''){
          if(values.debit!==null ){
          this.setState({partyid:values.debit.id})
          }
        }
      }

      goBack() {
        this.props.navigate(-1);
      }

    onSubmit(values){
      this.setState({loader:true})
      let selected
      let mode,bank,bankid//,poadvance
      let tcsid,tcsname,tcsvalue,tcspercentage,tcsamt,advledger=null
      // console.log(values.credit)
      if(values.credit.addltype===0){
        mode=0
      }else if (values.credit.addltype===1){
        mode=1
        bank=values.credit.name
        bankid=values.credit.id
      }
      if(this.state.tcschecked===1){
        tcsid=values.tcs.id
        tcsname=values.tcs.name
        tcsvalue=values.tcsval
        tcspercentage=values.tcspercent
        tcsamt=values.tcstotal

      }

      if(pmtcheck===1){
        if(this.state.selchk!==undefined){
          if(this.state.selchk.length!==0){
          selected=this.state.selchk
          }else{
          selected=this.state.selserv
          }
        }
      }else if (pmtcheck===2){
        if(this.state.selchk1!==undefined){
          selected=this.state.selchk1
        }
      }
      if(this.state.pendingdata!==undefined){
        // if(poadvance1===null){
        // poadvance=poadvance1.find((e,i)=>e.valueOf(i))
        // }else{
        //   poadvance=poadvance1.find((e,i)=>e.valueOf(i))
 
        // }
        this.state.pendingdata.map((e)=>{ return advledger=e.advledger})

      }
      let pmtid
      this.state.pendingdata.map((e)=>{return pmtid=e.selectedoption!==4?e.id:null })
      let canreq={
        pmtid:pmtid,
        tenantid:currentTenant
      }
      // if(this.state.selectedoption===4){
      //   pmtid=null
      // }
      let jv = {
        debitid: values.debit.id,debitname: values.debit.name,debitamt: values.debitval,debdth:values.debit.th,
        creditid: values.credit.id,creditname: values.credit.name,creditamt:values.creditvalue,credth:values.credit.th,
        narration: values.narration,createdBy: userid,tenantId: currentTenant,
        selectedval:selected,jvtype:jvtype,poid:this.state.poid,mode:mode,paymentref:values.paymentref,
        poadvance:advpoamt,genadv:this.state.Genadv,
        advledger:advledger,advstatus:advstatus,jvid:this.state.jvid,cjvid:this.state.cjvid,
        bank:bank,bankid:bankid,pmtid:this.state.pmtid,tcsid:tcsid,tcsname:tcsname,tcsvalue:tcsvalue,tcspercentage:tcspercentage,
        tcsamt:tcsamt,sdebitamt:this.state.serviceamount,sdebitid:this.state.serviceledger,paymentfor:paymentfor,custompoid:this.state.custompoid,
        frompath:this.props.location.pathname,date:this.state.fed!==''?this.state.fed:null,jvinsertiontype:this.state.jvinsertiontype,pmtselectedoption:this.state.selectedoption
      }
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }else if(values.debitval>this.state.transpending){
        this.setState({loader:false})
        Swal.fire({
          title: "Error",
          text: "Value cannot be more than pending amount + allowed margin",
          icon: "error",
          confirmButtonText: "Ok",

          confirmButtonColor: "#152f5f",
        })
      }else if(this.state.Genadv===true && parseFloat(this.state.genadvancecurrentamt)>0 && parseFloat(values.debitval)>parseFloat(this.state.genadvancecurrentamt)){
        this.setState({loader:false})
        Swal.fire({
          title: "Error",
          text: "Value cannot be more than advance amount",
          icon: "error",
          confirmButtonText: "Ok",
          // showCancelButton: true,
          // cancelButtonText: "No",
          confirmButtonColor: "#152f5f",
        })
    }else if (values.credit.th==="TH3"){
        let a=values.credit.amount-values.creditvalue
        if(a<0){
          alert("In-sufficient balance in the account");
        } else{
          this.props.navigate({pathname:`/journalconfirm`}, {state:jv});
        }
        this.setState({loader:false})
      }else {
        if(this.state.poid!==null && this.state.selectedoption!==4){
          let daodata={
        poid:this.state.poid,
         tenantId:currentTenant
          }
       NewJournalService.getPoStatus(daodata)
      .then(response=>{
        this.setState({loader:false})
        if(response.data!==2){
        if(this.state.jvinsertiontype==="PURADV" || this.state.jvinsertiontype==="PURINV" ){
          Swal.fire({
            title: "Information",
            text: "This action is irreversible, please recheck before confirming in CONFIRMATION screen ",
            icon: "info",
            confirmButtonText: "Ok",
            showCancelButton: true,
            cancelButtonText: "No",
            confirmButtonColor: "#152f5f",
          }).then((result) => {
            if (result.value === true) {
              this.props.navigate({pathname:`/journalconfirm`}, {state:jv});
              }else{
              this.setState({loader:false})
             // this.goBack()
              }
            }) 
          }else{
            this.props.navigate({pathname:`/journalconfirm`}, {state:jv});
          }
      }else{
        this.setState({loader:false})
        Swal.fire({
          title: "Cancellation",
          text: "This PO is Cancelled and cannot continue, Initiate Entry cancellation request ?",
          icon: "error",
          confirmButtonText: 'Yes',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonColor: "#152f5f",
        }).then((result) => {
          if (result.value === true) {
            this.setState({loader:true})
            NewJournalService.rectificatinEntry(canreq)
            .then(response=>{
              if(response.status===200){
                Swal.fire({
                  title: "Confirmation",
                  text: "Entry cancellation Request Raised",
                  icon: "info",
                confirmButtonText: "Ok",
                confirmButtonColor:'#152f5f',
                })

              this.props.navigate(-1);
              }
            })
          this.setState({loader:false})
          }else{
            this.setState({loader:false})
          }
        })
     }
     })
  }else{
    this.props.navigate({pathname:`/journalconfirm`}, {state:jv});
  }
}
}

    componentDidMount(){
     // alert(this.state.selectedoption)
      console.log(this.state.pendingdata)
      crdate=null
      this.loadData()
    
      if(this.state.totalcombdata.length===0){
        this.loadData()
       // this.setState({loading:true})
       }else{
        this.setState({loader1:true})
       }
      //alert(this.props.history.location.pathname)
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }
      
      year = new Date().getFullYear()
      day= new Date().getDate()
      let mn = new Date().getMonth()+1,transpending
      curmonth= moment(mn,'M').format('MMMM')

      
      this.setState({selamt:this.state.pendingdata.map((e)=>e.transpendingamount!==undefined?e.transpendingamount:e.genadvancecurrent)})
      gst = [...new Set(this.state.pendingdata.map((e) => { return e.gstin })),];
      supname = [...new Set(this.state.pendingdata.map((e) => { return e.supname!==undefined?e.supname:e.name})),]
      this.state.pendingdata.map((e) => { return poadvance=e.supname!==undefined?e.supname:e.name })
      this.state.pendingdata.map((e) => { return totalpending=e.pendingamount!==undefined?e.pendingamount:e.genadvancecurrent})
      this.state.pendingdata.map((e) => { return transpending=e.transpendingamount!==0?e.transpendingamount:e.genadvancecurrent})
      this.state.pendingdata.map((e) => { return this.setState({jvinsertiontype:e.jvinsertiontype}) })
      this.state.pendingdata.map((e) => { return this.setState({selectedoption:e.selectedoption}) })
      this.state.pendingdata.map((e) => { return this.setState({jvid:e.jvid,cjvid:e.cjvid}) })


      this.setState({transpending:transpending})
      
      let ap=[...new Set(this.state.pendingdata.map((e) => { return e.advancepaid})),];
            advancepaid=''
            if(ap.find((e)=>e!==null)){
              advancepaid=ap.find((e,i)=>e.valueOf(i));
            }
       this.state.pendingdata.map((e)=>{return pmtid=e.selectedoption!==4?e.id:null})
       this.setState({pmtid:pmtid})
       this.state.pendingdata.map((e)=>{return poid=e.poid})
       this.setState({poid:poid})
       let cpoid
       this.state.pendingdata.map((e)=>{return cpoid=e.custompoid})
       this.setState({custompoid:cpoid})
       this.state.pendingdata.map((e)=>{return advpoamt=e.poadvance})
       let selpmts
       this.state.pendingdata.map((e)=> {return selpmts=e.pmttrans})
       this.state.pendingdata.map((e)=> {return paymentfor=e.paymentfor})
       if(selpmts!==undefined){
        this.setState({selpmts:selpmts})
       }
      if(advpoamt!==0){
      this.setState({selectedadv:this.state.pendingdata})
      }

      if(this.state.poid===null){
        // debdata=null
        // let ledid=""
        // this.state.pendingdata.map(e=>{return ledid=e.ledgerid})
        // // debdata=val.filter((e)=> e.id===ledid)
        // this.setState({debdata:val.filter((e)=> e.id===ledid)})
       
        // console.log(debdata)
      }
      
        this.setState({loader:false})
       }

    loadData(){
      NewJournalService.getAllLedgers(currentTenant)
      .then(response=>{
        this.setState({totalcombdata:response.data},()=>this.AddData())
        totalcombdata=response.data
       })
       .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
      })
    }
    handleSDDate=()=>{
      this.setState({SDdate:this.state.SDdate===true?false:true})
    }
    handleGenadv=()=>{
      this.setState({Genadv:this.state.Genadv===true?false:true})
    }

    handlePaymenttype=()=>{
      this.setState({paymenttype:this.state.paymenttype===true?false:true})
    }


    handleFsdDateChange = (date) => {
      if(moment(date.$d).format("yyyy-MM-DD HH:mm:ss")<=moment(crdate).format("yyyy-MM-DD HH:mm:ss")){
        alert("Payment Date should be greater than JV date")
      }

      if(date!==null){
      this.setState({ fsd: date });
      this.setState({
        fed: moment(date.$d).format("yyyy-MM-DD HH:mm:ss"),
      });
    }else{
      this.setState({ SDdate:false })
    }
    };
    AddData(){
      let ledid=""
      this.state.pendingdata.map(e=>{return ledid=e.ledgerid!==undefined?e.ledgerid:e.id})
      // debdata=val.filter((e)=> e.id===ledid)
      this.setState({debdata:this.state.totalcombdata.filter((e)=> e.id===ledid)},()=>
      this.setState({genadvancecurrentamt:this.state.debdata.map((e)=> e.genadvcuramount)}))
      // this.setState({debdata:this.state.totalcombdata.filter((e)=> e.id===ledid)})
      if(totalcombdata!==undefined){
        val=totalcombdata 
        this.setState({loader1:false})
      }else(
        this.loadData()
      )
     
    }
     validate(values){
      let errors = {},deb = values.debitval,cre = values.creditvalue
      if (!values.debit && values.debit==='') {errors.debit = "Accounts is required";}
      if(!values.debitval){errors.debitval="Value Cannot be Blank"}else if(deb!==cre){
        errors.creditvalue="Value Cannot be be different from debit Value"}

        if (!values.credit && values.credit==='') {errors.credit = "Accounts is required"}
       if(!values.creditvalue){errors.creditvalue="Value Cannot be Blank"}else if(deb!==cre){
        errors.creditvalue="Value Cannot be be different from debit Value"}
       if (!values.narration) {errors.narration = "Narration is required";}
       if(this.state.paymenttype===false){
       if (!values.paymentref) {errors.paymentref = "Payment Reference is required";}
       }
       return errors;
     }
     onTcsChange(option){
      this.setState({ tcschecked: option.id })
     tcschecked=option.id;
    }

   

  handleCheckboxChange= event =>{
    this.setState({ displaychecked: event.target.checked })
    let a 
    this.state.pendingdata.map((e)=>{return a=e.pitems})
    this.setState({selchk:a}) 
    selchk=a

 }
 handlePrevBalChange= event =>{
  this.setState({ prevpmtschecked: event.target.checked })
  //let a 
    //this.state.pendingdata.map((e)=>{return a=e.pmttrans})
 // pgenpayments=a
}
handleAdvpaid(){
  advstatus=1
}
    render() {
      if(this.state.totalcombdata.length!==0){
        val=this.state.totalcombdata 
      }
      let totamt,cred1
      const {isError,message} = this.state;
      let credvalue,selpp,selpp2      // console.log(this.state.pendingdata)
    // val=this.state.pendingdata
     // entrytype=1
      // if(this.state.pendingdata.map((e)=> ))

 

      if(val!==undefined){
      tcsdata=val.filter((e) => (e.taxtype === 1))
      }
      cred1=val.filter((e) => e)
      if(this.state.paymenttype!==true){
      credvalue=cred1.filter((e)=>e.shid===100004 || e.shid===100003 || e.shid===100013 || e.shid===100014)
      }else{
        let one = cred1.filter((e)=>e.th==="TH1") 
        credvalue=cred1.filter((e)=>e.th==="TH6").concat(one) 
      }
    
      if(advancepaid!==''){
            if(totalpending<=totamt){
              this.handleAdvpaid();
            } 
          }
// -------------------NEW ----------------

//this.state.pendingdata.map((e)=>{return this.state.serviceamount=e.serviceamount})
//this.state.pendingdata.map((e)=>{return this.state.serviceledger=e.servledger})
totamt=this.state.selamt.map(e=>e)
this.state.pendingdata.map((e)=>{return crdate=e.updatedAt})

        return (
         <>
          <Header />
<Menu/>
            <div>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className="content-wrapper">

<div className="card card-info">

  <ol className="breadcrumb float-sm-right">
  <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={-1}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/ap'}} state= {{message:''}} >
                Accounts Payable
              </Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/ape'}} state= {{message:''}} >
                Accounts Payable Entry
              </Link>
    </li>

  </ol>

  <div className="text-center text-danger">{this.state.error}</div>
  <div className="card-body">
  <div className="text-center">
  {this.state.loader===true?spin:''}
  </div>
              
        
    <Formik
      initialValues={{ debdata,credvalue,debitval:'',paymentref:'',narration:'',selpp,selpp2,ppaymennts,ppaymennts2,selchk,debit:'',credit:''}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({ values,setFieldValue}) => (

        <Form autoComplete="off">




<div className="form-row mt-0 mb-0 text-sm sm">
{poid!==null?
 <fieldset className="form-group col-md-12">
 {/* {this.state.selectedPPO.length!==0 || this.state.selchk.length!==0? */}
<Table className="table-sm" aria-label="customized table" >
  <TableHead>
    <TableRow>
  <TableCell className="text-bold">Party</TableCell>
  <TableCell className="text-bold ">GST </TableCell>
  <TableCell className="text-bold" >Advance</TableCell>
  {advancepaid!==null?
  <TableCell className="text-bold" >Adv Trans Pending</TableCell>:
  <TableCell className="text-bold ">Less Advance </TableCell>}
  <TableCell className="text-bold ">Total Pending </TableCell>
  </TableRow>
    </TableHead>
   <TableBody>
<TableRow>
  <TableCell>{supname}</TableCell>
 <TableCell>{gst}</TableCell>
 <TableCell>{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {poadvance!==null?poadvance:0} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</TableCell>
{advancepaid===null ?
totalpending<=getTotal(totamt)?
<TableCell >{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {advancepaid} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</TableCell>:"":<TableCell >{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {advpoamt} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</TableCell>}
<TableCell >{<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {totalpending!==0?Math.abs(totalpending):this.state.transpending} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
 </NumericFormat>}</TableCell></TableRow>
</TableBody></Table></fieldset>
:""}



{poid!==null?<>
<fieldset className="form-group col-md-12">
<TableContainer ><Table className="table-sm" aria-label="customized table" >
  <TableHead className="text-bold text-blue">
  <TableRow  style={{ textAlign: "center" }}>
    <TableCell>Date</TableCell>
  <TableCell>PO Amt</TableCell><TableCell>GRN/SCN Amt Pending</TableCell>
   {/* <TableCell>Invoice Amt</TableCell> */}
  {this.state.serviceamount!==null?<TableCell>Service Amount</TableCell>:""}
  <TableCell>Tax</TableCell>
  <TableCell>TransPending</TableCell></TableRow></TableHead><TableBody>
  {this.state.pendingdata !== undefined || this.state.pendingdata.length!==0? 
  this.state.pendingdata.map((e) => (
   <TableRow key={e.id} style={{ textAlign: "center" }}>
<TableCell>{moment(e.createdAt).format('DD-MM-YYYY HH:mm')}</TableCell>
<TableCell>{e.poamount}</TableCell><TableCell>{this.state.serviceamount!==null?e.transpendingamount-e.tax-this.state.serviceamount:e.transpendingamount-e.tax}</TableCell>
{this.state.serviceamount!==null?<TableCell>{this.state.serviceamount}</TableCell>:""}
{/* <TableCell>{e.proinvoiceamt!==null?e.proinvoiceamt:e.transpendingamount}</TableCell> */}
<TableCell>{e.tax}</TableCell> <TableCell>{totalpending===0 || totalpending<0?this.state.transpending:Math.abs(totalpending)}</TableCell>
 </TableRow> 
 )):""}
 </TableBody></Table></TableContainer></fieldset>
<>
{advpoamt===0 ?<>
 <fieldset className="form-group col-md-2 mt-0 mb-0"> 
  <div className="form-check form-switch mt-0 mb-0">  
   <label className="text-sm">
          <Checkbox
            checked={this.state.displaychecked}
            onChange={this.handleCheckboxChange}
          />
          {this.state.displaychecked===true?"Hide Grn/Srcn-Items":"Show Grn/Scn-Items"}
        </label>
           </div>
           </fieldset>
            <fieldset className="form-group col-md-2 mt-0 mb-0"> 
  <div className="form-check form-switch mt-0 mb-0">  
   <label className="text-sm">
          <Checkbox
            checked={this.state.prevpmtschecked}
            onChange={this.handlePrevBalChange}
          />
          {this.state.prevpmtschecked===true?"Hide Prev Payments":"Show Prev Payments"}
        </label>
           </div>
           </fieldset></>:""}</></>:""}

           <fieldset className="form-group col-md-4 mt-0 mb-0">  
  {poid!==null && this.state.genadvancecurrentamt>0 && this.state.selectedoption!==4?<>
  <label className="text-sm"> General Current Advance : &nbsp;&nbsp;&nbsp;</label>
  <label className="text-sm"> {this.state.genadvancecurrentamt} </label></>:""}      
  </fieldset>
 
  {this.state.displaychecked===true?

 <fieldset className="form-group col-md-12"><TableContainer ><Table className="table-sm" aria-label="customized table" >
{ this.state.selchk.length!==0?<>
<TableHead className="text-bold">
   <TableRow>
  <TableCell>
  </TableCell>
 <TableCell>Item</TableCell><TableCell>GRN</TableCell><TableCell>Date</TableCell><TableCell>Price</TableCell>
 <TableCell>Tax</TableCell><TableCell>Qty</TableCell>
 <TableCell>Total</TableCell></TableRow></TableHead>
 <TableBody>
  {this.state.selchk.map((e) => (
  <TableRow key={e.id} style={{ textAlign: "center" }}><TableCell>
  </TableCell><TableCell>{e.name}</TableCell><TableCell>{e.grnid}</TableCell>
  <TableCell>{moment(e.updatedAt).format('DD-MM-YYYY HH:mm')}</TableCell><TableCell>{e.unitprice}</TableCell>
  <TableCell>{e.taxamount}</TableCell>
  <TableCell>{e.quantity}</TableCell><TableCell>{(e.unitprice * e.quantity )+ e.taxamount}</TableCell>
  </TableRow>
  ))}
  </TableBody></>:<>
    <TableHead><TableRow className="text-sm" >
            <TableCell>Service Name</TableCell>
            <TableCell>Milestone</TableCell><TableCell>Amount</TableCell><TableCell>Percentage</TableCell>
             <TableCell>Completion Date</TableCell></TableRow></TableHead>
              <TableBody>
               {this.state.selserv.map((e) => (
               <TableRow >
                 <TableCell>{e.scnname}</TableCell><TableCell>{e.milestone}</TableCell>
                  <TableCell>{e.totalwithtax}</TableCell>
                  <TableCell>{e.percentage}</TableCell>
                   <TableCell>{moment(e.completiondate).format('DD-MM-yyyy HH:mm')}</TableCell>
                  </TableRow>))} </TableBody></>}
  </Table></TableContainer></fieldset>:""}

  {poid===null?

<fieldset className="form-group col-md-12"><TableContainer ><Table className="table-sm" aria-label="customized table" >
<TableHead className="text-bold">
 <TableRow>
<TableCell>PMT ID</TableCell><TableCell>Provision Ledger</TableCell><TableCell>Date</TableCell><TableCell>Amount</TableCell>
</TableRow></TableHead>
<TableBody>
{this.state.pendingdata.map((e)=>(
<TableRow key={e.id} style={{ textAlign: "center" }}><TableCell>{e.id}</TableCell><TableCell>{e.supname}</TableCell>
<TableCell>{moment(e.updatedAt).format('DD-MM-YYYY HH:mm')}</TableCell><TableCell>{e.transpendingamount}</TableCell>
</TableRow>))}</TableBody>
</Table></TableContainer></fieldset>:""}
  {this.state.prevpmtschecked===true?
  <fieldset className="form-group col-md-12"><TableContainer ><Table className="table-sm" aria-label="customized table" >
<TableHead className="text-bold">
   <TableRow style={{ textAlign: "center" }}>
 <TableCell>POID</TableCell><TableCell>Trans ID</TableCell><TableCell>Date</TableCell><TableCell>Amount</TableCell>
 <TableCell>Mode</TableCell><TableCell>Bank Name</TableCell><TableCell>Branch</TableCell></TableRow></TableHead>
 <TableBody>
 {this.state.selpmts.map((e)=>(
  <TableRow key={e.id} style={{ textAlign: "center" }}><TableCell>{e.poid}</TableCell><TableCell>{e.transid}</TableCell>
  <TableCell>{moment(e.updatedAt).format('DD-MM-YYYY HH:mm')}</TableCell><TableCell>{e.paymentamount}</TableCell>
  <TableCell>{e.mode}</TableCell><TableCell>{e.bank}</TableCell><TableCell>{e.branch}</TableCell>
  </TableRow>))}</TableBody>
  </Table></TableContainer></fieldset>:""}

  </div>

  {ccountry==="India"?<>
{poid!==null && advpoamt===0 ?
 <div className="form-row mt-0 mb-0 sm "> 
  <fieldset className="form-group col-md-2 text-sm mt-6">
  <FormLabel>TDS :</FormLabel>{this.state.tdsreq.map((option) => {return (
  <label key={option.id}><div className="form-group col-md-12 mt-0 mb-0"><div className="radio-item mt-0 mb-0 text-sm">
  <input type="radio" checked={this.state.tcschecked === option.id ? true : false} key={option.id}
  onChange={this.onTcsChange.bind(this, option)} style={{ marginRight: 8 ,marginTop: 25 }} value={option.id}/>
  {option.name}</div></div> </label>); })} </fieldset>

  {this.state.tcschecked===1?<>
  <fieldset  className="form-group col-md-4 mt-0 mb-0"> 
  <Autocomplete id="itcs" className="tcs-select text-sm" name="atcs" options={tcsdata}
  getOptionLabel={(option) => option.name}
    
   value={values.tcs?undefined:undefined} onChange={(e, value) => { setFieldValue("tcs",value);}}
   renderInput={params => (
   <FormikTextField {...params}  type="text" name="tcs" className="text-sm" label="Select TDS"
   onBlur={()=>
    totamt!==undefined?
    totalpending>=getTotal(totamt)
    ?setFieldValue("tcsval",Math.abs(getTotal(totamt))):
    setFieldValue("tcsval",Math.abs(totalpending)):setFieldValue("tcsval",Math.abs(values.tcsval))} 
   variant="standard" fullWidth />)}/>
  </fieldset>
   <fieldset  className="form-group col-md-3">   
   <FormikTextField name="tcsval"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.tcsval?" ":"Value"} type="number" 
  //onBlur={()=>setFieldValue("creditvalue",values.debitval)}
  variant="standard"> </FormikTextField>
   </fieldset>
   <fieldset className="form-group col-md-1 mt-0 mt-0 test-sm">
    <FormikTextField name="tcspercent" className="form-control" label={values.tcspercent ? " " : "%"}
      type="number" onBlur={() => {setFieldValue("tcstotal",(values.tcsval * values.tcspercent) / 100);}}
      variant="standard"/></fieldset>
    <fieldset className="form-group col-md-2 mt-0 mt-0 test-sm" disabled>
      <NumericFormat name="tcstotal" className="form-control" value={(values.tcsval * values.tcspercent) / 100}
       label={values.tcspercent ? " " : "Value"} displayType={"input"} customInput={FormikTextField} decimalScale={2}
       fixedDecimalScale={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
     </fieldset>
   
   </>:""}
</div>:""}</>:""}

<div className="form-row col-md-12 text-left form-switch mt-3">
 <div className="form-group col-md-3 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.SDdate} onChange={this.handleSDDate} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.SDdate===false?"System Date":"Custom Date"}</span>
       </div>
    {this.state.SDdate===true?
     <div className="form-group col-md-3 text-left">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid  justifyContent="space-around">
    <DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
    id="date-picker-dialog" inputVariant="standard"
    minDate={new Date(moment(crdate).format("yyyy-MM-DD HH:mm"))}
    maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
    format="dd/MM/yyyy HH:mm" value={this.state.fsd}
    // label="Custom Date" 
    //crdate financialyearstart
    InputAdornmentProps={{position: "start"}} onChange={this.handleFsdDateChange}
    renderInput={(params) => <TextField {...params} variant="standard"/>}autoOk={true}/>
    </Grid></LocalizationProvider></div>
     :""}
    

    {poid!==null && this.state.genadvancecurrentamt>0 && this.state.selectedoption!==4?
    //<div className="form-row col-md-6 text-left form-switch mt-3">
 <div className="form-group col-md-3 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.Genadv} onChange={this.handleGenadv} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.Genadv===false?"Against Bank":"Against Gen Advance"}</span>
       {/* </div> */}
       </div>:""}
  
  {this.state.selectedoption===4?
 <div className="form-group col-md-3 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.paymenttype} onChange={this.handlePaymenttype} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.paymenttype===false?"Customer Payment":"Withhold Entry"}</span>
       </div>:""}
       </div>

 <div className="form-row" > 
 <fieldset  className="form-group col-md-7 sm mt-0 mb-0"> 
  <Autocomplete id="debit" className="debit-select text-sm sm" name="adebit" options={this.state.debdata}
  getOptionLabel={(option) => option.name } 
  // getOptionSelected={(option) => option.name } 
  value={values.debit?undefined:undefined} 
  onChange={(e, value) => {setFieldValue("debit",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="debit" label="Debit" className="text-sm"
  onBlur={()=>
    tcschecked===0  ?advpoamt && advpoamt!==0?setFieldValue("debitval",advpoamt) && this.onDebBlur(values):
    totalpending>=getTotal(totamt)?setFieldValue("debitval",getTotal(totamt))  && 
    this.onDebBlur(values):setFieldValue("debitval",this.state.transpending!==0?this.state.transpending:totalpending) &&
     this.onDebBlur(values):
    setFieldValue("debitval",getTotal(totamt)-values.tcstotal) && this.onDebBlur(values)
  } 

  // :setFieldValue("debitval",values.debitval)
   variant="standard" fullWidth />)}/></fieldset>
     
  <fieldset  className="form-group col-md-5"> 
  <FormikTextField name="debitval"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.debitval?" ":"Value"}  type="number" onBlur={()=>setFieldValue("creditvalue",values.debitval)}
  variant="standard"> </FormikTextField></fieldset>
  </div>     

<div className="form-row mt-0 mb-0"> 
<fieldset  className="form-group col-md-1 mt-0 mb-0"> </fieldset>
<fieldset  className="form-group col-md-6 mt-0 mb-4"> 
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" options={this.state.Genadv===false?credvalue:this.state.genadvance}
 // getOptionLabel={(option) => option.name +" ("+option.amount+")"}
 groupBy={(option) => option.subname}// option.th==="TH3"?option.subname:<span className='text-red'>{option.subname}</span> }
  getOptionLabel={(option) => option.name+"("+option.amount+")"} 
  value={values.credit?undefined:undefined} onChange={(e, value) => 
    setFieldValue("credit",value)}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" variant="standard" fullWidth />)}/>
</fieldset>
 <fieldset  className="form-group col-md-5" disabled>   
 <NumericFormat  name="creditvalue" className="form-control text-sm mt-0 mb-0" value={values.debitval} 
 label={values.debitval?" ":"Value"} displayType={'input'} customInput={FormikTextField} decimalScale={2} 
 fixedDecimalScale={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
 </fieldset>
 </div>




{this.state.paymenttype!==true?
 <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/></fieldset></div>:""}

  <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="narration" className="form-control" multiline rows={2} label="Narration"
  variant="outlined"/></fieldset></div>
  {/* </>:""} */}


<div className="form-group col-md-9"> 
<Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
<span className="text-green">{message}</span></Typography></div>

<fieldset className="form-group col-md-12">
 <button className="hovbuttonColour btn btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button></fieldset>
</Form> )}
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     

 </div>
 </>
)
}
}

export default withRouter(AccountsPayableEntry);              



 
