import React, { Component } from "react";
import withRouter from "../../withRouter";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import SalesServices from "../SalesServices";
import {
  currentTenant,
  financialyearend,
  financialyearstart,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Form, Formik } from "formik";
import moment from "moment";
import * as XLSX from "xlsx";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
class HSNsummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      productslist: [],
      fromdate: financialyearstart,
      todate: new Date(),
    };
  }

  handleFromDateChange = (date) => {
    this.setState({
      fromdate: date,
      todate:
        moment(date).diff(this.state.todate, "days") >= 0
          ? this.state.todate
          : date,
    });
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  onSubmit = () => {
    let datadao = {
      tenantid: currentTenant,
      startdate: this.state.fromdate,
      enddate: this.state.todate,
      userid: userid,
      dataaccess: "",
    };

    SalesServices.getSalesReport(datadao).then((response) => {
      this.setState({ data: response.data });
      this.Productdata();
    });
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
      userid: userid,
      dataaccess: "",
    };

    SalesServices.getSalesReport(datadao).then((response) => {
      this.setState({ data: response.data });
      this.Productdata();
    });
  }

  Productdata = () => {
    this.setState({ productslist: [], custbyproducts: [] }, () => {
      // Sorting Sales Products

      let data = []

      const products = this.state.data.reduce(
        (
          item,
          {
            itemname,
            itemid,
            quantity,
            totalprice,
            totalpricewtax,
            createdAt,
            customer,
            custid,
            hsn,
            igstamount,
            cgstamount,
            sgstamount,
            tax,
            gsttype,
            uom,
          }
        ) => {
          if (!item[hsn]) item[hsn] = [];
          item[hsn].push({
            itemid: itemid,
            itemname: itemname,
            quantity: quantity,
            totalprice: totalprice,
            totalpricewtax: totalpricewtax,
            createdAt: createdAt,
            customer: customer,
            custid: custid,
            hsn: hsn,
            igstamount: igstamount,
            cgstamount: cgstamount,
            sgstamount: sgstamount,
            tax: tax,
            gsttype: gsttype,
            uom: uom,
            cgst: tax / 2,
            sgst: tax / 2,
            igst: tax,
          });
          return item;
        },
        {}
      );
      this.setState({ products: products });
      // End of Sorting Products

      // Calcuating total sales by products
      Object.entries(products).forEach(([key, value]) => {
        let a = {
          hsn: key,
          itemname: value.map((e) => e.itemname)[0],
          itemid: value.map((e) => e.itemid)[0],
          tax: value.map((e) => e.tax)[0],
          quantity: value.reduce((a, v) => (a = a + v.quantity), 0),
          totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
          totalpricewtax: value.reduce((a, v) => (a = a + v.totalpricewtax), 0),
          igstamount: value.reduce((a, v) => (a = a + v.igstamount), 0),
          cgstamount: value.reduce((a, v) => (a = a + v.cgstamount), 0),
          sgstamount: value.reduce((a, v) => (a = a + v.sgstamount), 0),
          gsttype: value.map((e) => e.gsttype)[0],
          uom: value.map((e) => e.uom)[0],
          igst: value.map((e) => e.igst)[0],
          cgst: value.map((e) => e.cgst)[0],
          sgst: value.map((e) => e.sgst)[0],
        };
       data.push(a);
      });

      this.setState({productslist:data});

      // End of Calcuating total sales products
    });

  };

  exportToExcel = () => {
    var data = [];
    const selectedColumns = ["hsn", "itemname", "quantity","uom", "totalprice","igst","igstamount","cgst","cgstamount","sgst","sgstamount"]; // Specify the columns to select

    data = this.state.productslist.map((item) => {
      const selectedItem = {};
      selectedColumns.forEach((column) => {
        if (item.hasOwnProperty(column)) {
          selectedItem[column] = item[column];
        }
      });
      return selectedItem;
    });
    var ws = XLSX.utils.json_to_sheet(data);

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "People");
    XLSX.utils.book_append_sheet(wb, ws, "People2");

    /* write workbook (use type 'binary') */
    // XLSX is a ZIP-based format,
    //  Attempts to write a ZIP-based format as type "string" are explicitly blocked due to various issues with getting other browser APIs to play nice with the string.
    //   The "string" output format is primarily for text-based formats like HTML and CSV where you don't want to convert Chinese and other characters back to raw bytes.
    var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    /* generate a download */
    function s2ab(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    }
   let date=`${moment(new Date(this.state.fromdate)).format("DD-MM-YYYY")} - ${moment(new Date(this.state.todate)).format("DD-MM-YYYY")}`
    // The preference for application/octet-stream probably has to do with IE6 compatibility.
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      `Disclosure in GSTR-1 HSN Summary (${date}) .xlsx`
    );
  };

  render() {
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item active">HSN Summary</li>
            </ol>
            <div className="card-body">
              <div>
                <Formik
                  initialValues={{
                    from: this.state.fromdate,
                    to: this.state.todate,
                  }}
                  onSubmit={this.onSubmit}
                  validateOnChange={false}
                  validate={this.validate}
                  enableReinitialize={true}
                >
                  {({ setFieldValue, values }) => (
                    <Form autoComplete="off">
                      <div className="form-row g-0">
                        <fieldset>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container justifyContent="space-around">
                              <DatePicker
                                variant="inline"
                                id="date-picker-dialog"
                                label="From Date"
                                name="from"
                                format="dd/MM/yyyy"
                                maxDate={new Date()}
                                value={this.state.fromdate}
                                onChange={this.handleFromDateChange}
                                renderInput={(params) => (
                                  <TextField {...params} variant="standard" />
                                )}
                                autoOk={true}
                              />
                            </Grid>
                          </LocalizationProvider>
                        </fieldset>

                        <fieldset style={{ marginLeft: "30px" }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container justifyContent="space-around">
                              <DatePicker
                                variant="inline"
                                id="date-picker-dialog"
                                label="To Date"
                                name="to"
                                format="dd/MM/yyyy"
                                minDate={this.state.fromdate}
                                value={this.state.todate}
                                onChange={this.handleToDateChange}
                                maxDate={new Date()}
                                renderInput={(params) => (
                                  <TextField {...params} variant="standard" />
                                )}
                                autoOk={true}
                              />
                            </Grid>
                          </LocalizationProvider>
                        </fieldset>
                        <fieldset className="form-group col-md-1">
                          <button
                            className="btn btn-sm hovbuttonColour mt-3"
                            type="submit"
                          >
                            <i className="fas fa-filter" />
                          </button>
                        </fieldset>
                      </div>
                    </Form>
                  )}
                </Formik>
                <Paper sx={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer sx={{ maxHeight: 440 }}>
                    <Typography className="px-5 pt-3 d-flex justify-content-between">
                      <h5>Disclosure in GSTR-1 HSN Summary</h5>

                      <Button onClick={this.exportToExcel}>
                        <CloudDownloadIcon />
                      </Button>
                    </Typography>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      className="table-sm p-2 pb-4"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>HSN</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Quantity</TableCell>
                          <TableCell>UOM</TableCell>
                          <TableCell className="text-center">Taxable Amount</TableCell>
                          <TableCell className="text-center">IGST</TableCell>
                          <TableCell className="text-center">CGST</TableCell>
                          <TableCell className="text-center">SGST</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.data.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={7} align="center">
                              Sorry, no data avilable
                            </TableCell>
                          </TableRow>
                        ) : (
                          this.state.productslist.map((e, index) => (
                            <TableRow key={index}>
                              <TableCell>{e.hsn}</TableCell>
                              <TableCell>{e.itemname}</TableCell>
                              <TableCell className="text-right">
                                {e.quantity.toFixed(3)}
                              </TableCell>
                              <TableCell>{e.uom}</TableCell>
                              <TableCell className="text-right">
                                {" "}
                                <NumericFormat
                                  className="px-3"
                                  displayType={"text"}
                                  value={e.totalprice}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </TableCell>
                              <TableCell className="text-right">
                                {" "}
                                ({e.gsttype === "INTERSTATE" ? e.tax : 0}%)
                                <NumericFormat
                                  className="px-3"
                                  displayType={"text"}
                                  value={e.igstamount}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </TableCell>
                              <TableCell className="text-right">
                                {" "}
                                ({e.gsttype === "INTERSTATE" ? 0 : e.tax / 2}%)
                                <NumericFormat
                                  className="px-3"
                                  displayType={"text"}
                                  value={e.cgstamount}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </TableCell>
                              <TableCell className="text-right">
                                {" "}
                                ({e.gsttype === "INTERSTATE" ? 0 : e.tax / 2}%)
                                <NumericFormat
                                  className="px-3"
                                  displayType={"text"}
                                  value={e.sgstamount}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </TableCell>
                            </TableRow>
                          ))
                        )}
                        {this.state.data.length === 0 ? (
                          ""
                        ) : (
                          <TableRow>
                            <TableCell colSpan={2} ><b>Total</b></TableCell>
                            <TableCell className="text-right">
                              <b>{this.state.productslist
                                .reduce((a, b) => (a = a + b.quantity), 0)
                                .toFixed(3)}</b>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell className="text-right">
                              <NumericFormat
                                className="px-3 text-bold"
                                displayType={"text"}
                                value={this.state.productslist.reduce(
                                  (a, b) => (a = a + b.totalprice),
                                  0
                                )}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </TableCell>
                            <TableCell className="text-right">
                              <NumericFormat
                                className="px-3 text-bold"
                                displayType={"text"}
                                value={this.state.productslist.reduce(
                                  (a, b) => (a = a + b.igstamount),
                                  0
                                )}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </TableCell>
                            <TableCell className="text-right">
                              <NumericFormat
                                className="px-3 text-bold"
                                displayType={"text"}
                                value={this.state.productslist.reduce(
                                  (a, b) => (a = a + b.cgstamount),
                                  0
                                )}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </TableCell>
                            <TableCell className="text-right">
                              <NumericFormat
                                className="px-3 text-bold"
                                displayType={"text"}
                                value={this.state.productslist.reduce(
                                  (a, b) => (a = a + b.sgstamount),
                                  0
                                )}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(HSNsummary);
