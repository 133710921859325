import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { spin } from "../../Common/NewLoader";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import ProductionServices from "../ProductionServices";
import { currentTenant, thousandsdisplay, userid } from "../../Common/Credentials";
import { Autocomplete } from "@mui/material";
import moment from "moment";
import Footer from "../../Component/Footer";
import {NumericFormat} from "react-number-format";
import Swal from "sweetalert2";
import UserService from "../../users/UserService";
import { productionid } from "../../Common/Permissions";

function NewJobOrder() {
  const history = useLocation();
  const navigate = useNavigate()
  const [estidList, setEstIdList] = useState([]);
  const [estid, setEstId] = useState("");
  const [customestid,setCustomestid] = useState('');
  const [estimationList, setEstimationList] = useState([]);
  const [assignto, setAssignTo] = useState("");
  const [assigntoname,setAssignToName] = useState('')
  const [userlist,setUserList] = useState([])

  useEffect(() => {
    ProductionServices.getEstimationsIdListOfUser(currentTenant,userid).then((response) => {
      setEstIdList(response.data);
    });
   
  }, []);

  const handleEstIdChange = (newValue) => {
    newValue === "" || newValue === null || newValue === undefined
      ?setCustomestid('')
      : setCustomestid(newValue)
    setEstimationList([]);
    if (newValue) {
      ProductionServices.getEstimationsbyCustomId(newValue).then((response) => {
        setEstimationList(response.data)
        setEstId(response.data.id)
        UserService.retriveAllUsers(currentTenant).then(res=>
          setUserList(res.data.filter(e=>e.wcid ===response.data.workcenterid )))
      })
    }
  };

  const handleAssigntoChange = (e,value) => {
    console.log(value)
    if(value === "" || value === null || value === undefined){
       setAssignTo("")
      setAssignToName('')
  }else{
    // let userdata =userlist.find(u=>u.first +" "+u.last+"("+u.id+")" ===value)
  setAssignTo(value.id);
  setAssignToName(value.first+" "+value.last)
  }
  };

  const handleSubmit = () => {
    let data = {
      estimation: estid,
      assignto: assignto,
      assigntoname:assigntoname,
      tenantid: currentTenant,
      createdby: userid,
      updatedby: userid,
    };

    Swal.fire({
      title: "Approve Joborder?",
      text: "Are you sure you want to Approve?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor:'#152f5f',
      cancelButtonText: "No",
      reverseButtons: true
  }).then((result)=>{
      if (result.value) {
        ProductionServices.NewJobOrder(data).then
        (() =>{
          Swal.fire({
            title: "Joborder Approved Successfully",
            text: ".",
            icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor:'#152f5f',
          }).then(() =>
          navigate("/joborder")
        );
        })
      }
  });
    // ProductionServices.NewJobOrder(data).then((response) =>
    //   history.push("/joborder")
    // );
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/joborder" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/production">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/joborder">JobOrder</Link>
            </li>
            <li className="breadcrumb-item active">New JobOrder</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              {estidList === [] ? (
                <div className="text-center">{spin}</div>
              ) : (
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title"> New JobOrder</h3>
                  </div>
                  <div className="card-body">
                    <div className="form-row mt-3">
                      <fieldset className="col-md-3">
                        <Autocomplete
                          id="controlled-demo"
                          options={estidList}
                          getOptionLabel={(option) => option}
                          value={customestid}
                          onChange={(event, newValue) =>
                            handleEstIdChange(newValue)
                          }
                          error={customestid === "" || customestid === undefined}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Estimation Id"
                              variant="standard"
                              error={customestid === "" || customestid === undefined}
                              helperText={
                                customestid === "" || customestid === undefined
                                  ? "Empty field!"
                                  : " "
                              }
                            />
                          )}
                        />
                      </fieldset>
                      {/* <fieldset className="col-md-3">
                        <Autocomplete
                          id="controlled-demo"
                          options={userlist}
                          getOptionLabel={(option) =>
                            option.first+" "+option.last+"("+option.id+")"
                        }
                        renderOption={(option) =>
                          option.first+" "+option.last+"("+option.id+")"
                        }
                        getOptionSelected={(option) =>
                          option.first+" "+option.last+"("+option.id+")"
                        }
                          value={assigntoname}
                          onChange={handleAssigntoChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Assign To"
                              variant="standard"
                              error={assignto === "" || assignto === undefined}
                              helperText={
                                assignto === "" || assignto === undefined
                                  ? "Empty field!"
                                  : " "
                              }
                            />
                          )}
                        />
                      </fieldset> */}
                      <fieldset className="form-group col-md-3">
                                            <Autocomplete
                                              id="subaccount"
                                              className="subacc-select"
                                              name="toname"
                                              options={userlist}
                                              getOptionLabel={(option) =>
                                                  option.first+" "+option.last+"("+option.id+")"
                                              }
                                              value={
                                                assignto
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={handleAssigntoChange}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  label={`Select To (${assigntoname})`}
                                                  type="text"
                                                  value={assigntoname}
                                                  variant="standard"
                                                  error={assignto === ""}
                                                  helperText={
                                                    assignto === "" ? "Empty field!" : " "
                                                  }
                                                  fullWidth
                                                />
                                              )}
                                            />
                                          </fieldset>
                    </div>
                    {estimationList.length === 0 ? (
                      ""
                    ) : (
                      <>
                        <table className="float-left m-0 mb-3 ">
                          <tbody>
                            <tr>
                              <td>Estimation Id</td>
                              <td>:&nbsp; {estimationList.id}</td>
                            </tr>

                            {estimationList.soid === "NA"?"":
                            <tr>
                              <td>Sale Order Id</td>
                              <td>
                                <Link
                                  to={{
                                    pathname: "/saleorderdetails"}}
                                    state= {{
                                      id: estimationList.soid,
                                      bvstatus: 1,
                                  }}
                                >
                                  :&nbsp; {estimationList.soid}
                                </Link>
                              </td>
                            </tr>
                            }
                            <tr>
                              <td>Date</td>
                              <td>
                                :&nbsp;{" "}
                                {moment(estimationList.createdAt).format(
                                  "DD-MM-YYYY hh:mm"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Estimation Type</td>
                              <td>:&nbsp; {estimationList.estimationtype}</td>
                            </tr>
                            <tr>
                              <td>Workcenter </td>
                              <td>:&nbsp; {estimationList.workcenter}</td>
                            </tr>
                            <tr>
                              <td>Start Date</td>
                              <td>
                                :&nbsp;{" "}
                                {moment(estimationList.startdate).format(
                                  "DD-MM-YYYY"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Duration</td>
                              <td>:&nbsp; {estimationList.duration}</td>
                            </tr>
                            <tr>
                              <td>Approximate Amount</td>
                              <td>:&nbsp; <NumericFormat className="pr-3" displayType={'text'} value={estimationList.appxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                            </tr>
                            <tr>
                            <td>Advance Amount</td>
                              <td>:&nbsp; <NumericFormat className="pr-3" displayType={'text'} value={estimationList.advance} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                            </tr>
                            <tr>
                              <td>Created by</td>
                              <td>:&nbsp; {estimationList.createdby}</td>
                            </tr>
                            <tr>
                              <td>QA Required?</td>
                              <td>
                                :&nbsp;{" "}
                                {estimationList.qastatus === true
                                  ? "Yes"
                                  : "No"}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {estimationList.bomitems.length === 0?'':
                        <TableContainer>
                          <Typography> BOM List</Typography>
                          <Table className="table-sm">
                            <TableHead>
                              <TableRow>
                                <TableCell className="text-center">
                                  Itemname
                                </TableCell>
                                <TableCell className="text-center">
                                 Price
                                </TableCell>
                                <TableCell className="text-center">
                                  Quantity
                                </TableCell>
                                <TableCell className="text-center">
                              UOM
                            </TableCell>
                            {estimationList.estimationtype==="Continuous"?
                              <TableCell className="text-center">
                              Frequency
                              </TableCell>
                            :""}
                             <TableCell className="text-center">
                                 Total
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {estimationList.bomitems.map((e, index) => (
                                <TableRow key={index}>
                                  <TableCell className="text-center">
                                    {e.itemname}
                                  </TableCell>
                                  <TableCell className="text-right">
                              <NumericFormat className="pr-3" displayType={'text'} value={e.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                              </TableCell>
                                  <TableCell className="text-center">
                                    {e.quantity}
                                  </TableCell>
                                  <TableCell className="text-center">
                                    {e.uom}
                                  </TableCell>
                                  {estimationList.estimationtype==="Continuous"?
                                  <TableCell className="text-center">
                                    {e.frequency}
                                  </TableCell>
                                :""}
                                 <TableCell className="text-right">
                              <NumericFormat className="pr-3" displayType={'text'} value={e.totalprice} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                              </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        }
                        {estimationList.equipments.length === 0?'':
                        <TableContainer>
                          <Typography>Equipments</Typography>
                          <Table className="table-sm">
                            <TableHead>
                              <TableRow>
                                <TableCell className="text-center">
                                  Equipment
                                </TableCell>
                                <TableCell className="text-center">
                                 Amount
                                </TableCell>
                                <TableCell className="text-center">
                                  Quantity
                                </TableCell>
                                <TableCell className="text-center">
                                 Total
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {estimationList.equipments.map((e, index) => (
                                <TableRow key={index}>
                                  <TableCell className="text-center">
                                    {e.name}
                                  </TableCell>
                                  <TableCell className="text-right">
                              <NumericFormat className="pr-3" displayType={'text'} value={e.amount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                              </TableCell>
                                  <TableCell className="text-center">
                                    {e.quantity}
                                  </TableCell>
                                  <TableCell className="text-right">
                              <NumericFormat className="pr-3" displayType={'text'} value={e.totalamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                              </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        }
                        {estimationList.manpower.length === 0?'':
                        <TableContainer>
                          <Typography>Man Power</Typography>
                          <Table className="table-sm">
                            <TableHead>
                              <TableRow>
                                <TableCell className="text-center">
                                  Position/Type
                                </TableCell>
                                <TableCell className="text-center">
                                 Amount
                                </TableCell>
                                <TableCell className="text-center">
                                  No. of Employees/Workers/Count
                                </TableCell>
                                <TableCell className="text-center">
                                  Total
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {estimationList.manpower.map((e, index) => (
                                <TableRow key={index}>
                                  <TableCell className="text-center">
                                    {e.mantype}
                                  </TableCell>
                                  <TableCell className="text-right">
                              <NumericFormat className="pr-3" displayType={'text'} value={e.amount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                              </TableCell>
                                  <TableCell className="text-center">
                                    {e.mancount}
                                  </TableCell>
                                  <TableCell className="text-right">
                              <NumericFormat className="pr-3" displayType={'text'} value={e.totalamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                              </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                          }
                        {estimationList.finalproduct.length === 0?'':
                        <TableContainer>
                          <Typography>Final/End Products</Typography>
                          <Table className="table-sm">
                            <TableHead>
                              <TableRow>
                                <TableCell className="text-center">
                                  Product
                                </TableCell>
                            <TableCell className="text-center">
                              Quantity
                            </TableCell>
                            <TableCell className="text-center">
                              UOM
                            </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {estimationList.finalproduct.map((e, index) => (
                                <TableRow key={index}>
                                  <TableCell className="text-center">
                                    {e.itemname}
                                  </TableCell>
                              <TableCell className="text-center">
                                {e.quantity}
                              </TableCell>
                              <TableCell className="text-center">
                                {e.uom}
                            </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        }
                        {productionid === '2' || productionid === '3'?
                        <fieldset className="col-md-3 mt-3">
                          <button
                            className="btn btn-sm hovbuttonColour"
                            onClick={handleSubmit}
                            disabled={assignto === ""}
                          >
                            Approve
                          </button>
                        </fieldset>:'Dosen\'t have permission to approve'}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default NewJobOrder;
