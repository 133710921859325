import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import { currentTenant, userid } from '../../Common/Credentials.js';
import { spin } from '../../Common/NewLoader.jsx';
import { appmasterid } from '../../Common/Permissions.js';
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import BrandsServices from './BrandsServices.js';
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});


class BrandsTable extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        message:'',
        brandsdata:[],
        loading:false,

    }
    // this.refreshCustomer=this.refreshCustomer.bind(this)
    this.deletebrands=this.deletebrands.bind(this)
  }



  deletebrands(id){
   BrandsServices.deleteBrand(id)
   .then(()=>
    BrandsServices.getBrands(currentTenant)
    .then(response =>
    this.setState({brandsdata:response.data}),
     this.setState({message:"brands Deleted Successfully"})
    )
   )
   .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})
   
}

  
refreshbrands(){
  BrandsServices.getBrands(currentTenant)
    .then(
      response => {
          this.setState({brandsdata:response.data})
        this.setState({loading:false})
        }
      )  
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })  
  }

    componentDidMount () {

      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }

    //   UserService.UserPermissions(userid).then(response=>this.setState({up:response.data}))
      
      this.setState({loading:true})

      this.refreshbrands();

     }

    render() {

        const columns = [
            {name: "id",label: "Id",options: {filter: false,sort: true,display:false}},
            {name: "brand",label:"Brand",options: {filter: true,sort: true,}},
            {
              name: "Delete",
              options: {
                filter: false,
                sort: false,
                empty: true,
                display: appmasterid === "2" || appmasterid === "3" ? true : false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <span
                      as="button"
                      data-tip
                      data-for="delete"
                      className="fa fa-trash text-danger"
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure you wish to delete brand ${tableMeta.rowData.find(
                              (e) => e
                            )}?`
                          )
                        )
                          this.deletebrands(tableMeta.rowData.find((e) => e));
                      }}
                    ></span>
                  );
                },
              },
            },
        ];
        const options = {
            filterType: 'checkbox',
            selectableRows: "none",
            customToolbar: () => {
             return (<>
              {appmasterid === '2' || appmasterid === '3'?
              <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/newbrand"}}>
                <CustomToolbar />
              </Link>
                :''  
              }
             </>
             );
           }
          }
        return (
            <div>
                <Header/>
                <Menu/>
                <div className="content-wrapper">
                    <div className="card">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item text-secondary">
                                <Link to="/inventory">Home</Link>
                            </li>
                            
                            <li className="breadcrumb-item active">brands</li>
                        </ol>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                
                                            <div className="card-body">
                                                <div className="text-center">{this.state.loading === true ? spin : ''}</div>
                                                <ThemeProvider theme={theme}> 
                                                <MUIDataTable
                                                    className="table-sm px-3"
                                                    title={"brands"}
                                                    data={this.state.brandsdata}
                                                    columns={columns}
                                                    options={options}
                                                />  
                                                </ThemeProvider>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>    
          
        )
       
    }
    
}
export default withRouter(BrandsTable)