import { Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { FormikTextField } from '../../CommonComponents/FormField.js';
import SizeServices from './SizeServices.js';
import { Spinner } from 'react-bootstrap';
import * as XLSX from 'xlsx'; 
import size from '../../Excel Templets/Size.xlsx'


class NewSize extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sizelist:[],
      size: '',
      message:'',
      loader:false,
      type:false
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  downloadExcel=()=>{
    var link = document.createElement("a");
    link.href = size
    link.download = 'Size';

    document.body.appendChild(link);

    link.click();
    setTimeout(function () {
      window.URL.revokeObjectURL(link);
    }, 200);
  }

  readExcel=(file)=>{
    const promise = new Promise((resolve,reject)=>{
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file)

      fileReader.onload=(e)=>{
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray,{type:'buffer'});
        const wsname  = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      }
      fileReader.onerror = error=>{
        reject(error);
      }
    })
   promise.then((d)=>{
    this.setState({sizelist:d.map(e=>e.Size)})
   })
  }

  onSubmit(values) {
     this.setState({loader:true})
    let addsize = { 
      name:this.state.size,
      tenantid: currentTenant
    }
    SizeServices.addsize(addsize).then(() => 
    this.props.navigate('/size'))
    .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
      this.setState({message:e.response.data.message,loader:false})
    })

  }

  uploadSize=()=>{
    if(this.state.sizelist.length!==0){
    this.setState({loader:true})
    let uploadsize = { 
      names:this.state.sizelist,
      tenantid: currentTenant
    }
    SizeServices.UploadSizes(uploadsize).then(() => 
    this.props.navigate('/size'))
    .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
      this.setState({message:e.response.data.message,loader:false})
    })
  }
  }

  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    
 }


  validate(values) {

    let errors = {}

     if (!values.size|| values.size==='') {
     errors.size = "Size is Required";
      }
    return errors;
  }

  render() {
     let {id,size} = this.state;
 
    return (
      
      <div>
      <Header />
         <Menu/>
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/size"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/size">Size</Link>
              </li>
              <li className="breadcrumb-item active">New Size</li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">New Size</h3>
            </div>

            <div className="card-body">

            <fieldset className="form-group col-md-12">
                          <fieldset
                                  className={`form-group col-md-3 shadow-sm  text-center btn ${
                                    this.state.type === false
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === false}
                                  onClick={() => this.setState({type:false,sizelist:[]})}
                                >
                                  Add manualy
                                </fieldset>
                                <fieldset
                                  className={`form-group col-md-3  btn shadow-sm text-center ${
                                    this.state.type === true
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.type === true}
                                  onClick={() => this.setState({type:true,size:''})}
                                >
                                  By upload
                                </fieldset>
                             
                              </fieldset>
            {this.state.type===false?
              <Formik
                initialValues={{id,size}}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}>
                {() => (
                  <Form autoComplete="off">
                    <div className="form-row">

                      <fieldset className="form-group col-md-3">
                      <FormikTextField className="form-control"  type="text" name="size" label="Size" value={this.state.size} onChange={(e)=>this.setState({size:e.target.value,message:''})}/>
                      </fieldset>
                    </div>
                    <div className="form-group col-md-12 text-red">{this.state.message}</div>
                    {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>: <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fas fa-save mr-2"/>Save</button>}
                    <Link as="button" className="btn btn-sm deletebutton ml-1"  to={{pathname: '/size'}} ><b style={{color:"#FA5733"}}> X</b> Cancel</Link> 
                  
                  </Form>
                )}
              </Formik>
              :
              <div className="form-row ml-2">
              <fieldset className="form-group col-md-12">
                <label className="text-red">Note: The Excel Sheet must contain only one column with name 'Size', accepts only ".xlsx, .xls, .csv"</label>
            </fieldset>
            <fieldset className="form-group col-md-4">
              <label>Download Excel format &nbsp;&nbsp;&nbsp;</label>
            <button className="btn btn-sm hovbuttonColour shadow-sm fa fa-file-excel text-md" type='buton' data-tip data-for="pdf" onClick={this.downloadExcel}>&nbsp;Download</button>
              </fieldset>
              <fieldset className="form-group col-md-12 m-0"></fieldset>
                  <fieldset className="form-group col-md-1">
                  <label>Upload here </label>
                  </fieldset>
                  <fieldset className="form-group col-md-4">
                    <label htmlFor="btn-upload">
                      <input
                        className="btn-choose "
                        id="btn-upload"
                        name="btn-upload"
                        type="file"
                        onChange={e=>{
                          if(e.target.files[0] !== undefined){
                          const file = e.target.files[0];
                          this.readExcel(file)
                          }
                        }}
                        accept=".xlsx, .xls, .csv"
                      />
                    </label>
                  </fieldset>
                  <fieldset className="form-group col-md-12">
                    {this.state.sizelist.length === 0?'':<label>Sizes({this.state.sizelist.length}):&nbsp;</label>}
                          {this.state.sizelist.map((e,i)=>
                              <span key={i}>{i+1}.{e}{this.state.sizelist.length-1===i?'':', '}</span>
                          )}
                  </fieldset>

                  {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>: <span as='buton' className="btn btn-sm hovbuttonColour" onClick={this.uploadSize}><i className="fas fa-save mr-2"/>Save</span>}
          <Link as="button" className="btn btn-sm deletebutton ml-1"  to={{pathname: '/size'}} ><b style={{color:"#FA5733"}}> X</b> Cancel</Link> 
        
                </div>
                }
            </div>
          </div>
        </div>

      <Footer/>
      </div>
    );
  }
}
export default withRouter(NewSize)