import React, { useState, Fragment, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import FormControl from "@mui/material/FormControl";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Autocomplete, Grid } from "@mui/material";
import { currency, currentTenant } from "../../Common/Credentials";
import SupplierService from "../../Masters/Suppliers/SupplierService";

function createData(Sl_No, Code, Name, Account) {
  return {Sl_No, Code, Name, Account };
}
const rows = [
  // createData(1, 159, "Name", 24, 4.0)
];

const Services = (props) => {
  const [age, setAge] = useState("None");
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [open2, setOpen2] = useState(false);
  const [fullWidth2, setFullWidth1] = useState(true);
  const [maxWidth2, setMaxWidth1] = useState("md");
  const [open3, setOpen3] = useState(false);
  const [fullWidth3, setFullWidth3] = useState(true);
  const [maxWidth3, setMaxWidth3] = useState("sm");

  const [open4, setOpen4] = useState(false);
  const [fullWidth4, setFullWidth4] = useState(true);
  const [maxWidth4, setMaxWidth4] = useState("sm");


  // ---------------------------------- CAl --------------------------------------------------------------------------
  
  const [roeofomr, setRoeofomr] = useState(1);
  const [fareinomr1, setFareinomr1] = useState(0);
  const [fareinomr2, setFareinomr2] = useState(0);
  const [fareinomrlable, setFareinomrlable] = useState(0);

  const [othertax1, setOthertax1] = useState(0);
  const [othertax2, setOthertax2] = useState(0);

  const [totaltaxinomr1, setTotaltaxinomr1] = useState(0);
  const [totaltaxinomr2, setTotaltaxinomr2] = useState(0);
  const [totaltaxinomrlable, setTotaltaxinomrlable] = useState(0);

  const [commisioninomr, setCommisioninomr] = useState(0);
  const [commisioninomrpercent, setCommisioninomrpercent] = useState(0);

  const [supplieramt, setSupplieramt] = useState(0);

  const [inputvat, setInputvat] = useState(0);
  const [inputvatpercent, setInputvatpercent] = useState(0);

  const [profit, setProfit] = useState(0);


  const [servicefee, setServicefee] = useState(0);
  const [servicefeepercent, setServicefeepercent] = useState(0);

  const [extraearing, setExtraearing] = useState(0);
  const [extraearningpercent, setExtraearningpercent] = useState(0);

  const [addmarkup, setAddmarkup] = useState(0);
  const [addmarkuppercent, setAddmarkuppercent] = useState(0);

  const [disscount, setDisscount] = useState(0);
  const [disscountpercent, setDisscountpercent] = useState(0);

  const [selling, setSelling] = useState(0);
  const [sellinglable, setSellinglable] = useState(0);
  

 
  const [checkedcommission, setCheckedcommission] = useState(true);
// ------------------------------------------------------------------------------------------------------------------------------------
  const [type,setType]=useState('');
  const [issuedate,setIssuedate]=useState('');
  const [service,setService]=useState('');
  const [fromdate,setFromdate]=useState('');

  const [supplierdata,setSupplierdata]=useState([]);
  const [suppliername,setSuppliername]=useState('');
  const [suppliercode,setSuppliercode]=useState('');
  const [todate,setTodate]=useState('');
  const [serviceprovercode,setServiceprovercode]=useState('');
  const [serviceprovername,setServiceprovername]=useState('');
  const [noofdays,setNoofdays]=useState('');
  const [city,setCity]=useState('');
  const [country,setCountry]=useState('');

  const [supplierconfno,setSupplierconfno]=useState('');
  const [corpccnickname,setCorpccnickname]=useState('');
  const [corpccname,setCorpccname]=useState('');
  const [remarks,setRemarks]=useState('');
  const [particulars,setParticulars]=useState('');
  const [custempno,setCustempno]=useState('');
  const [travelerid,setTravelerid]=useState('');
  const [paxname,setPaxname]=useState('');
  const [adult,setAdult]=useState('');
  const [child,setChild]=useState('');
  const [addpax,setAddpax]=useState(false);
  const [lpoortono,setLpoortono]=useState('');
  const [staff,setStaff]=useState('');
  const [refno,setRefno]=useState('');
  const [costcenter,setCostcenter]=useState('');
  const [pnr,setPnr]=useState('');
  const [voucherno,setVoucherno]=useState('');
  const [addpaxname,setAddpaxname]=useState('');
const [servicestore,setServicestore]=useState([]);









// ===============================================================================================================================================================

useEffect(()=>{
  AllDataHandler();
},[])



  // -----------------------------------------------------------------------------------------------------------------
  const handleSupplierCodeChange = (e) => {
    setSuppliercode(e.target.value)
    let findsupplier = supplierdata.find(a => a.id === e.target.value)
    if (findsupplier !== null && findsupplier !== undefined) {
      setSuppliername(findsupplier.companyname)
    } else {
      setSuppliername('')
      setSuppliercode('');
    }


  }

  const handleSupplierNameChange = (value, values) => {
    if (values !== null && typeof values === 'object' && values !== undefined) {
      setSuppliername(values.companyname);
      setSuppliercode(values.id);
      // console.log(values.companyname);
    }
  }
  const AllDataHandler = () => {
    SupplierService.retriveAllSuppliers(currentTenant).then(r => {
      setSupplierdata(r.data);
      console.log(r.data, "Supplier Data");
    });

   
  }











  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
const handleClickOpen2 =()=>{
    setOpen2(true);
  }
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleClickOpen4 = () => {
    setOpen4(true);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ CAL ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


const changeHandlerFare1 = (e) => {
  setFareinomr1(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

  setFareinomrlable((parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + (parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)));

  setCommisioninomrpercent((parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)) / (parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)))) * parseFloat(100))

  if (checkedcommission === false) {
    setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
  }
  else {
    setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
  }

  // setServicefee(    ((   parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt))    )*( parseFloat(parseFloat(servicefeepercent) === undefined || parseFloat(servicefeepercent) === null || parseFloat(servicefeepercent) === "" ? 0.00 : parseFloat(servicefeepercent)) )) / 100     )

  setServicefeepercent(((parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
  setExtraearningpercent(((parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
  setAddmarkuppercent(((parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
  setDisscountpercent(((parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)


  setSelling(     parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))  + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))   + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)))



}

const changeHandlerFare2 = (e) => {
  if (e.target.value === null || e.target.value === "" || e.target.value === undefined) {
    setFareinomr2(0);
  }
  else {

    setFareinomr2(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value));



    setFareinomrlable(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1) + parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value));

    setCommisioninomrpercent((parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)) / (parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))) * parseFloat(100))

    if (checkedcommission === false) {
      setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) + parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
    }
    else {
      setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
    }


    setServicefeepercent(((parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
    setExtraearningpercent(((parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
    setAddmarkuppercent(((parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
    setDisscountpercent(((parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))) / (parseFloat(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt)))) * 100)
  
    setSelling(     parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))  + parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1))   + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)))
  
  
  }



}

const changeHadlerOthertax1 = (e) => {
  setOthertax1((parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
  setTotaltaxinomr1(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)))
  if (checkedcommission === false) {
    setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) + parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
  }
  else {
    setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
  }

  setOthertax2(parseFloat(0));

  setSelling(     parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))  + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))   + parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)))


}

const changeHadlerOthertax2 = (e) => {
  setOthertax2((parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
  setTotaltaxinomr1((parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + (parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)))

  if (checkedcommission === false) {
    setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
  }
  else {
    setSupplieramt(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
  }

  setInputvat(parseFloat(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
  setInputvatpercent((parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) / (parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)))) * parseFloat(100))

}

const changeHadlerTotalTax1 = (e) => {
  setTotaltaxinomrlable(parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)));

}

const changeHadlerTotalTax2 = (e) => {
  // if(   (parseFloat(totaltaxinomr1) === undefined || parseFloat(totaltaxinomr1) === null || parseFloat(totaltaxinomr1) === "" ? 0.00 : parseFloat(totaltaxinomr1))  === (parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + (parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2))  )
  // {
  setTotaltaxinomr2(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)));
  if (parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) > 0) {

    setTotaltaxinomr1((parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + (parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - (parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

  }
  else {
    setTotaltaxinomr1((parseFloat(totaltaxinomr1) === undefined || parseFloat(totaltaxinomr1) === null || parseFloat(totaltaxinomr1) === "" ? 0.00 : parseFloat(totaltaxinomr1)))
  }

  setInputvatpercent((parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) / (parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)))) * parseFloat(100))


}


const changeHandlerCommission = (e) => {

  setCommisioninomr((parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)));

  setCommisioninomrpercent((parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) / (parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)))) * parseFloat(100))

  
  if (parseFloat(e.target.value !== undefined || e.target.value !== null || e.target.value !== "" ? 0.00 : e.target.value))
  {
    setProfit(parseFloat(e.target.value === undefined || e.target.value === null || e.target.value === "" ? 0.00 : e.target.value));
  }
  else
  {
    setInputvat(inputvat);
  }


}

const changeHandlerCommissionPercent = (e) => {
  setCommisioninomrpercent((parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

  setCommisioninomr(((parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))) * parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))) / parseFloat(100))

}


const changeHandlerSupplierAmt = (e) => {
  setSupplieramt(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.0 : parseFloat(e.target.value));

  
  setFareinomr2(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.0 : parseFloat(e.target.value));

  setServicefeepercent(      (  parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee))       /       parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) ) * 100)



}

const changeHadlerInputVat = (e) => {
 
 if(e.target.values!==null || e.target.values!==undefined || e.target.values!=="")
 { 
  setInputvat(e.target.value);
}
else
{
  setInputvat(0);
}
}

const handleChangeCommissionCheck = (e) => {
  setCheckedcommission(e.target.checked);
  console.log(e.target.checked);
  if ((e.target.checked) === true) {
    setSupplieramt(parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) + parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
  }
  else {
    setSupplieramt(parseFloat(parseFloat(othertax1) === null || parseFloat(othertax1) === undefined || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)) + parseFloat(parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax2) === null || parseFloat(othertax2) === undefined || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)))
  }

}


const changeHadlerServicefee = (e) => {

  setServicefee(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

  setServicefeepercent((   parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) / (parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)))    ) * parseFloat(100))

  setSelling(     parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))  + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))   + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1))  + parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)))

}

const changeHadlerServicefeePercent = (e) => {
  setServicefeepercent(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
  setServicefee(((parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))) * parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))) / parseFloat(100))

}



const changehandlerExtraEarning=(e)=>{
  setExtraearing(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

  setExtraearningpercent( (   parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) / (parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)))    ) * parseFloat(100))

  setSelling(     parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))  + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))   + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)))

  

}


const changeHandlerExtraEraningPercent = (e) => {
  setExtraearningpercent(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
  setExtraearing(((parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))) * parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))) / parseFloat(100))

}


const changeHandlerAddmarkup=(e)=>{
  setAddmarkup(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

  setAddmarkuppercent((   parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) / (parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)))    ) * parseFloat(100))

  setSelling(     parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))  + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))   + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)))


}
const changeHandlerAddmarkupPercent = (e) => {
  setAddmarkuppercent(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
  setAddmarkup(((parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))) * parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))) / parseFloat(100))

}


const changeHandlerDisscount=(e)=>{
  setDisscount(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

  setDisscountpercent((   parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)) / (parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2)))    ) * parseFloat(100))

  setSelling(     parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1))  + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))   + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))

}
const changeHandlerDisscountPercent = (e) => {
  setDisscountpercent(parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
  setDisscount(((parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))) * parseFloat(parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === null || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))) / parseFloat(100))

}

const changeHandlerSelling = (e)=>{
setSelling(e.target.value===null || e.target.value===undefined || e.target.value==="" ?0 : e.target.value)
}

// ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

const changeHandlerType=(e)=>{
  setType(e.target.value)
}
const changeHandlerIssuedate=(e)=>{
  setIssuedate(              moment(new Date(e)).format("DD-MM-YYYY"))
}
const changeHandlerFromdate=(e)=>{
  setFromdate(moment(new Date(e)).format("DD-MM-YYYY"))
}
const changeHandlerTodate=(e)=>{
  setTodate(moment(new Date(e)).format("DD-MM-YYYY"))
}
const changeHandlerCostcenter=(e)=>{
  setCostcenter(e.target.value)
}



const changeHandlerClear=(e)=>{
  e.preventDefault();
    setType('');
    setVoucherno('');
    setIssuedate('');
    setService('');
    setFromdate('');
    setSuppliercode('');
    setSuppliername('');
    setTodate('');
    setServiceprovercode('');
    setServiceprovername('');
    setNoofdays('');
    setCity('');
    setCountry('');
    setSupplierconfno('');
    setCorpccnickname('');
    setCorpccname('');
    setRemarks('');
    setParticulars('');
    setCustempno('');
    //setTtravelerid(0);
    setPaxname('');
    setAdult('');
    setChild('');
    setLpoortono('');
    setStaff('');
    setRefno('');
    setSetCostcenter('');
    setPnr('');

    setRoeofomr(0);
    setFareinomr1(0);
    setFareinomr2(0);
    setOthertax1(0);
    setOthertax2(0);
    setTotaltaxinomr1(0);
    setTotaltaxinomr2(0);
    setCommisioninomr(0);
    setCommisioninomrpercent(0);
    setCheckedcommission(false);
    setSupplieramt(0);
    setInputvat(0);
    //roeofromreceived:"1",
    //profit: parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)) + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)),
    setServicefee(0);
    setServicefeepercent(0);
    setExtraearing(0);
    setExtraearningpercent(0);
    setAddmarkup(0);
    setAddmarkuppercent(0);
    setDisscount(0);
    setDisscountpercent(0);
    setSelling(0);
    //sellingpriceinomrlabel: parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)),
    setDisscountpercent(0);
}

const clickHandlerAddPaxName = ()=>{
  setAddpax(!addpax);
}





const AddHandler =(e)=>{
  e.preventDefault();
  let sdata ={


    type: type,
    voucherno:voucherno,
    issuedate: issuedate,
    service: service,
    fromdate: fromdate,
    suppliercode: suppliercode,
    suppliername: suppliername,
    todate: todate,
    serviceprovercode: serviceprovercode,
    serviceprovername: serviceprovername,
    noofdays: noofdays,
    city: city,
    country: country,
    supplierconfno: supplierconfno,
    corpccnickname: corpccnickname,
    corpccname: corpccname,
    remarks: remarks,
    particulars: particulars,
    custempno: custempno,
    travelerid: travelerid,
    paxname: paxname,
    addpaxname:addpaxname,
    adult: adult,
    child: child,
    lpoortono: lpoortono,
    staff: staff,
    refno: refno,
    costcenter: costcenter,
    pnr: pnr,

    roeofrompayable: roeofomr,
    fareinomr1: fareinomr1,
    fareinomr2: fareinomr2,
    othertaxes: othertax1,
    vat: othertax2,
    totaltaxinomr1: totaltaxinomr1,
    totaltaxinomr12: totaltaxinomr2,
    commissioninomr: commisioninomr,
    commissioninomrpercent: commisioninomrpercent,
    commissioninstatus: checkedcommission,
    supplieramtinomr: supplieramt,
    inputvatintomr: inputvat,
    roeofromreceived: "1",
    profit: parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)) + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)),
    servicefeeinomr: servicefee,
    servicefeeinomrpecent: servicefeepercent,
    extraearninginomr: extraearing,
    extraearninginomrpercent: extraearningpercent,
    additionalmarkupinomr: addmarkup,
    additionalmarkupinomrpercent: addmarkuppercent,
    discountinomr: disscount,
    discountinomrpercent: disscountpercent,
    sellingpriceinomr: selling,
    sellingpriceinomrlabel: parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)),
    
    discountinomrpercent: disscountpercent,
    tenantid: currentTenant

  }

  setServicestore([...servicestore,sdata])
  console.log( [...servicestore,sdata]);
  
    
    props.funcService([...servicestore,sdata])
  


  

}

// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ CAL END +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  return (
    <div>
     
      <div className="small-container mp-2 padding-2 w-100%">
        <div className="card">
          <div className="card">
            <div className="card p-4">
              <div className="row my-1">
                <fieldset className="col-md-3 col-sm-12">
                  <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">Stock type</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={type}
                      label="Stock Type"
                      onChange={changeHandlerType}
                      size="small"
                      variant="outlined"
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="VS">VS</MenuItem>
                      <MenuItem value="STOCK">STOCK</MenuItem>
                      <MenuItem value="XO">XO</MenuItem>
                    </Select>
                  </FormControl>
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    label="Voucher No."
                    required
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={voucherno}
                    onChange={(e)=>setVoucherno(e.target.value)}

                  />
                </fieldset>

                <div className="col-md-4 col-sm-12">
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker slotProps={{ textField: { size: 'small' } }} label="Issue Date" />
                  </LocalizationProvider> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container >
                    <DatePicker
                      variant="inline"
                      id="date-picker-dialog"
                      label="Issue Date "
                      // format="dd/MM/yyyy"
                      // maxDate={new Date()}

                      renderInput={(params) => <TextField {...params}   size="small"/>}
                      autoOk={true}
                      value={issuedate}
                      onChange={(e)=>changeHandlerIssuedate(e)}
                    />
                  </Grid>
                </LocalizationProvider>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-7 col-sm-12">
                  <TextField
                    required
                    label="Service"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={service}
                    onChange={(e)=>setService(e.target.value)}
                  />
                </fieldset>

                <div className="col-md-4 col-sm-12">
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker slotProps={{ textField: { size: 'small' } }} label="From Date" />
                  </LocalizationProvider> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container >
                    <DatePicker
                      variant="inline"
                      id="date-picker-dialog"
                      label="From Date "
                      renderInput={(params) => <TextField {...params}   size="small"/>}
                      autoOk={true}
                      value={fromdate}
                      onChange={changeHandlerFromdate}
                    
                    />
                  </Grid>
                </LocalizationProvider>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    label="Supplier Code"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={suppliercode}
                      onChange={handleSupplierCodeChange}
                    

                    fullWidth
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                <Autocomplete
                    id="supname"
                    className="subacc-select"
                    name="Supplier Name"
                    options={supplierdata}
                    getOptionLabel={(option) => option.companyname}
                    value={suppliername ? undefined : undefined}
                    onChange={handleSupplierNameChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        className="form-control"
                        type="text"
                        name="suppliername"
                        value={suppliername}
                        label="Select Supplier Name"
                        variant="outlined"
                        size="small"
                        
                      />
                    )}
                  />
                </fieldset>

                <div className="col-md-4 col-sm-12">
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker slotProps={{ textField: { size: 'small' } }} label="To Date" />
                  </LocalizationProvider> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid container >
                    <DatePicker
                      variant="inline"
                      id="date-picker-dialog"
                      label="To Date "
                      renderInput={(params) => <TextField {...params}   size="small"/>}
                      autoOk={true}
                      value={todate}
                      onChange={(e)=>changeHandlerTodate(e)}
                    />
                  </Grid>
                </LocalizationProvider>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    label="Service Provider Code"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={serviceprovercode}
                      onChange={(e)=>setServiceprovercode(e.target.value)}
                    fullWidth
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    label="Service Provider Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={serviceprovername}
                    onChange={(e)=>setServiceprovername(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    fullWidth="true"
                    label="No. of Days"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={noofdays}
                    onChange={(e)=>setNoofdays(e.target.value)}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    required
                    label="City"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={city}
                    onChange={(e)=>setCity(e.target.value)}
                  
                  />
                </fieldset>
                
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    required
                    label="Country"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={country}
                    onChange={(e)=>setCountry(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    label="Supplier Conf. No."
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={supplierconfno}
                    onChange={(e)=>setSupplierconfno(e.target.value)}
                  />
                </fieldset>
               
              </div>

              <div className="row my-1">
                <fieldset className="col-md-3 col-sm-12 my-1">
                  <TextField
                    label="Corp. CC Nick Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={corpccnickname}
                    onChange={(e)=>setCorpccnickname(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12 my-1">
                  <TextField
                    label="Corp. CC Full Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={corpccname}
                    onChange={(e)=>setCorpccname(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12 my-1">
                  <TextField
                    fullWidth
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    label="Remarks"
                    value={remarks}
                    onChange={(e)=>setRemarks(e.target.value)}
                  />
                </fieldset>
                
              </div>

              <div className="row my-1">
                <fieldset className="col-md-7 col-sm-12 ">
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Particulars"
                    variant="outlined"
                    multiline
                    size="small"
                    fullWidth
                    maxRows={4}
                    value={particulars}
                    onChange={(e)=>setParticulars(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    fullWidth
                    label="Cust. Emp. No."
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={custempno}
                    onChange={(e)=>setCustempno(e.target.value)}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    label="Traveler ID"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={travelerid}
                    onChange={(e)=>setTravelerid(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    required
                    fullWidth
                    label="Pax Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={paxname}
                    onChange={(e)=>setPaxname(e.target.value)}
                 
                  />
                </fieldset>
                <div className="col-md-3 col-sm-12">
                  <span className="btn hovbuttonColour btn-sm" onClick={clickHandlerAddPaxName}>
                    <i
                      className="fa fa-plus fa-xl "
                    ></i>
                  </span>
                </div>
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    label="Adult"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={adult}
                    onChange={(e)=>setAdult(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-1 col-sm-12">
                  <TextField
                    label="Child"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={child}
                    onChange={(e)=>setChild(e.target.value)}
                  />
                </fieldset>
              </div>
              <div className="row my-1">
              <fieldset className="col-md-6 col-sm-12">
                  <TextField
                    required
                    fullWidth
                    label="Additional Pax Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={addpaxname}
                    onChange={(e)=>setAddpaxname(e.target.value)}
                 
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    label="LPO/TO No."
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={lpoortono}
                    onChange={(e)=>setLpoortono(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    label="Staff"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={staff}
                    onChange={(e)=>setStaff(e.target.value)}
                  />
                </fieldset>

                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    label="Ref.No"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={refno}
                    onChange={(e)=>setRefno(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12">
                  <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">Cost Centre</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={costcenter}
                      label="Cost Centre"
                      onChange={changeHandlerCostcenter}
                      size="small"
                    >
                
                      <MenuItem value={10}>Head Office</MenuItem>
                    </Select>
                  </FormControl>
                </fieldset>
              </div>
              <div className="row my-1">
                <fieldset className="col-md-4 col-sm-12 my-1">
                  <TextField
                    fullWidth="true"
                    label="PNR "
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={pnr}
                    onChange={(e)=>setPnr(e.target.value)}
                  />
                </fieldset>
              </div>
            </div>
          </div>

          {/* --------------------------------------------------- */}
          <div className="row my-1">
            <div className="card p-4 col-md-6 col-sm-12">
              <div className="row my-1">
                <fieldset className="col-md-11">
                  <TextField
                    required
                    fullWidth
                    label={`ROE of ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={roeofomr}
                    onChange={(e) => setRoeofomr(1.00)}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-6 col-sm-12">
                  <TextField
                    label={`Fare in ${currency} `}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    onChange={changeHandlerFare1}
                    value={fareinomr1 === undefined || fareinomr1 === null || fareinomr1 === "" ? 0.00 : fareinomr1}
                  />

                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    onChange={changeHandlerFare2}
                    value={fareinomr2 === undefined || fareinomr2 === null || fareinomr2 === "" ? 0.00 : fareinomr2}
                  />

                </fieldset>
                <div className="col-md-2 col-sm-12">
                  <InputLabel>{(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + (parseFloat(fareinomr2) === undefined || parseFloat(fareinomr2) === null || parseFloat(fareinomr2) === "" ? 0.00 : parseFloat(fareinomr2))}</InputLabel>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-6 col-sm-12">
                  <TextField
                    label="Other Taxes"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)}
                    onChange={changeHadlerOthertax1}
                  />
                </fieldset>
                <fieldset className="col-md-6 col-sm-12">
                  <TextField
                    label="VAT/GSTIN"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)}
                    onChange={changeHadlerOthertax2}
                  />
                </fieldset>

              </div>

              {/* <div className="row my-1">
              <fieldset className="col-md-6 col-sm-12">
                  <TextField
                    label="Total Tax in OMR"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                  />
                </fieldset>
                <div className="col-md-2 col-sm-12">
                    <label>0.00</label>
                </div>
              </div> */}

              <div className="row my-1">
                <fieldset className="col-md-6 col-sm-12">
                  <TextField
                    label={`Toal Tax in ${currency} `}
                    id="outlined-size-small"
                    size="small"
                    disabled
                    variant="outlined"
                    type="number"
                    value={parseFloat(totaltaxinomr1) === undefined || parseFloat(totaltaxinomr1) === null || parseFloat(totaltaxinomr1) === "" ? 0.00 : parseFloat(totaltaxinomr1)}
                    onChange={changeHadlerTotalTax1}
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={parseFloat(totaltaxinomr2) === undefined || parseFloat(totaltaxinomr2) === null || parseFloat(totaltaxinomr2) === "" ? 0.00 : parseFloat(totaltaxinomr2)}
                    onChange={changeHadlerTotalTax2}
                  />
                </fieldset>
                <div className="col-md-2 col-sm-12">
                  <label>{(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + (parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2))}</label>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1 col-sm-12">
                  <TextField
                    label={`Commission in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr)}
                    onChange={changeHandlerCommission}
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-12">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-4 col-sm-12 my-1 col-sm-12">
                  <TextField
                    label=""
                    type="number"
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={parseFloat(commisioninomrpercent) === undefined || parseFloat(commisioninomrpercent) === null || parseFloat(commisioninomrpercent) === "" ? 0.00 : parseFloat(commisioninomrpercent)}
                    onChange={changeHandlerCommissionPercent}
                  />
                </fieldset>
                <fieldset className="col-md-1 col-sm-12">
                  <Checkbox
                    defaultChecked
                    checked={checkedcommission}
                    onChange={handleChangeCommissionCheck}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-10 col-sm-12">
                  <TextField
                    fullWidth
                    label={`Supplier Amount in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                    type="number"
                    value={(parseFloat(supplieramt) === undefined || parseFloat(supplieramt) === null || parseFloat(supplieramt) === "" ? 0.00 : parseFloat(supplieramt))}
                    onChange={changeHandlerSupplierAmt}
                  />
                </fieldset>
                <div className="col-md-2 col-sm-12">
                  <InputLabel>{parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(othertax2) === undefined || parseFloat(othertax2) === null || parseFloat(othertax2) === "" ? 0.00 : parseFloat(othertax2)) - parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr))}</InputLabel>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label={`Input VAT/GSTIN in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    disabled
                    type="number"
                    variant="outlined"
                    value={parseFloat(inputvat) === undefined || parseFloat(inputvat) === null || parseFloat(inputvat) === "" ? 0.00 : parseFloat(inputvat)}
                    onChange={changeHadlerInputVat}
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-12">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    InputLabel=""
                    type="number"
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={parseFloat(inputvatpercent) === undefined || parseFloat(inputvatpercent) === null || parseFloat(inputvatpercent) === "" ? 0.00 : parseFloat(inputvatpercent)}
                  // onChange={changeHadlerInputVatPercent}

                  />
                </fieldset>
              </div>


            </div>
            {/* ------------------- */}
            <div className="card p-4 col-md-6 col-sm-12">
              <div className="row my-1">
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    label={`ROE of ${currency}` } 
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                  />
                </fieldset>

                <div className="col-md-2 my-3 col-sm-12">
                  <InputLabel>1</InputLabel>
                </div>

                <div className="col-md-4 my-3 col-sm-12">
                  <InputLabel>Profit in {currency} </InputLabel>
                </div>

                <div className="col-md-2 my-3 col-sm-12">
                  <InputLabel>{    parseFloat(parseFloat(commisioninomr) === undefined || parseFloat(commisioninomr) === null || parseFloat(commisioninomr) === "" ? 0.00 : parseFloat(commisioninomr))  + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup)) - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount)) }</InputLabel>
                </div>

              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label={`Service Fee in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={servicefee}
                    onChange={changeHadlerServicefee}
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-12">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label=""
                    
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={servicefeepercent}
                    onChange={changeHadlerServicefeePercent}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label={`Extra Earning in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={extraearing}
                    onChange={changehandlerExtraEarning}
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-12">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label=""
                    
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={extraearningpercent}
                    onChange={changeHandlerExtraEraningPercent}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <div className="col-md-4 ms-4">
                  <InputLabel>Payback Account</InputLabel>
                </div>
                <div className="col-md-3 col-sm-12 y-1">
                  <Fragment>
                    <button>
                      <i
                        class="fas fa-folder-plus"
                        variant="outlined"
                        onClick={handleClickOpen2}
                      ></i>
                    </button>
                    <Dialog
                      fullWidth={fullWidth2}
                      maxWidth={maxWidth2}
                      open={open2}
                      onClose={handleClose2}
                    >
                      <DialogTitle
                        className="ms-4"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        Multiple Payback Account
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          noValidate
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                            minWidth: "400px",
                          }}
                        >
                          <div className="row my-1 ms-2">
                            <fieldset className="col-md-4 col-sm-12">
                              <TextField
                                fullWidth="true"
                                label="Payback Account"
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                              />
                            </fieldset>
                            <fieldset className="col-md-3 col-sm-12">
                              <TextField
                                fullWidth="true"
                                label=""
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                              />
                            </fieldset>
                            <fieldset className="col-md-3 col-sm-12">
                              <TextField
                                fullWidth="true"
                                label="Account"
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                              />
                            </fieldset>
                            <div className="col-md-2 col-sm-12">
                              {/* <button
                                className="btn btn-sm btnbuttonColour d-flex"
                                style={{ float: "right" }}
                              >
                                Add{" "}
                                <i
                                  className="fa fa-plus fa-xl ms-2"
                                  style={{
                                    color: "#1fe035",
                                    fontSize: "25px",
                                  }}
                                >
                                  {" "}
                                </i>
                              </button> */}
                            </div>
                          </div>
                          <div className="row my-1 ms-2">
                            <div className="col-md-12 col-sm-12">
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead>
                                    <TableRow>

                                      <TableCell>
                                        Sl No.
                                      </TableCell>
                                      <TableCell align="right">
                                        Code
                                      </TableCell>
                                      <TableCell align="right">
                                        Name
                                      </TableCell>
                                      <TableCell align="right">
                                        Ammount(OMR)
                                      </TableCell>

                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {rows.map((row) => (
                                      <TableRow
                                        key={row.Sl_No}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >

                                        <TableCell component="th" scope="row">
                                          {row.Sl_No}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.Code}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.Name}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.Account}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose2}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label={`Payback Amount in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                  />
                </fieldset>
                <div className="col-md-1 col-sm-12 my-2">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-4 my-1">
                  <TextField
                    label=""
                    type="number"
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                  />
                </fieldset>
                <div className="col-md-2 my-1">
                  {/* <button className="btn btn-sm">
                    <i className="fas fa-sync" />
                  </button> */}
                </div>
              </div>
              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label={`Additional Mark up in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={addmarkup}
                    onChange={changeHandlerAddmarkup}
                  />
                </fieldset>
                <div className="col-md-1 col-sm-12 my-2">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label=""
                    
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={addmarkuppercent}
                    onChange={changeHandlerAddmarkupPercent}
                  />
                </fieldset>
              </div>
              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label={`Discount in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={disscount}
                    onChange={changeHandlerDisscount}
                  />
                </fieldset>
                <div className="col-md-1 col-sm-12 my-2">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-4 col-sm-12 my-1">
                  <TextField
                    defaultValue={0.00}
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={disscountpercent}
                    onChange={changeHandlerDisscountPercent}
                  />
                </fieldset>
                <div className="col-md-2 col-sm-12 my-1">
                  <Fragment>
                    <button className="btn">
                      <i
                        className="fas fa-caret-square-up"
                        variant="outlined"
                        onClick={handleClickOpen3}
                      ></i>
                    </button>
                    <Dialog
                      fullWidth={fullWidth3}
                      maxWidth={maxWidth3}
                      open={open3}
                      onClose={handleClose3}
                    >
                      <DialogTitle
                        className="ms-4"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        Multi-Discount-Details (OMR)
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          noValidate
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                          }}
                          multiline
                        >
                          <div className="card" >
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-10 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label="Normal Discount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-10 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label="PLB Discount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-10 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label="Deal Discount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              {/* <Button className="btn hovbuttonColour btn-sm col-md-5 align-content-center col-sm-12" style={{color:"white"  }}>Set</Button> */}
                            </div>
                          </div>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose3}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div>
              </div>
              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-12 my-1">
                  <TextField
                    label={`Output VAT in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                  />
                </fieldset>
                <div className="col-md-1 col-sm-12 my-2">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-4 col-sm-12 my-1">
                  <TextField
                    label=""
                    defaultValue={0.00}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                  />
                </fieldset>
                <div className="col-md-2 col-sm-12 my-1">
                  <Fragment>
                    <button className="btn">
                      <i
                        class="fas fa-undo-alt"
                        variant="outlined"
                        onClick={handleClickOpen4}
                      ></i>
                    </button>
                    <Dialog
                      fullWidth={fullWidth4}
                      maxWidth={maxWidth4}
                      open={open4}
                      onClose={handleClose4}
                    >
                      <DialogTitle
                        className="ms-4"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        Tax Details
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          noValidate
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                          }}
                          multiline
                        >
                          <div className="my-2" >
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-4 col-sm-12">
                                <InputLabel> Tax Code </InputLabel>
                              </fieldset>
                              <fieldset className="col-md-4 col-sm-12">
                                <InputLabel>Amount in OMR </InputLabel>
                              </fieldset>
                              <fieldset className="col-md-4 col-sm-12">
                                <InputLabel>Amount in OMR </InputLabel>
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-4 col-sm-12 my-1">
                                <InputLabel>OMV (OUT OF SCOPE) </InputLabel>
                              </fieldset>
                              <fieldset className="col-md-4 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-md-4 col-sm-12 my-1">
                                <InputLabel>0.00 </InputLabel>
                              </fieldset>

                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-4 col-sm-12 my-1">
                                <InputLabel> Total </InputLabel>
                              </fieldset>
                              <fieldset className="col-md-4 col-sm-12 my-1">
                                <InputLabel>0.00 </InputLabel>
                              </fieldset>
                              <fieldset className="col-md-4 col-sm-12 my-1">
                                <InputLabel>0.00 </InputLabel>
                              </fieldset>

                            </div>

                          </div>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose4}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div>
              </div>
              <div className="row my-1">
                <fieldset className="col-md-6 col-sm-12 my-1">
                  <TextField
                    label={`Selling Price in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                    type="number"
                    value={selling}
                    onChange={changeHandlerSelling}
                  />
                </fieldset>
                <div className="col-md-3 col-sm-12">
                  <InputLabel>{  parseFloat(parseFloat(fareinomr1) === undefined || parseFloat(fareinomr1) === null || parseFloat(fareinomr1) === "" ? 0.00 : parseFloat(fareinomr1)) + parseFloat(parseFloat(othertax1) === undefined || parseFloat(othertax1) === null || parseFloat(othertax1) === "" ? 0.00 : parseFloat(othertax1)) + parseFloat(parseFloat(servicefee) === undefined || parseFloat(servicefee) === null || parseFloat(servicefee) === "" ? 0.00 : parseFloat(servicefee)) + parseFloat(parseFloat(extraearing) === undefined || parseFloat(extraearing) === null || parseFloat(extraearing) === "" ? 0.00 : parseFloat(extraearing)) + parseFloat(parseFloat(addmarkup) === undefined || parseFloat(addmarkup) === null || parseFloat(addmarkup) === "" ? 0.00 : parseFloat(addmarkup))  - parseFloat(parseFloat(disscount) === undefined || parseFloat(disscount) === null || parseFloat(disscount) === "" ? 0.00 : parseFloat(disscount))}</InputLabel>
                </div>
                <div className="col-md-3 my-1">
                  <Fragment>
                    <button >
                      <i class="fas fa-folder-plus" variant="outlined" onClick={handleClickOpen}></i>
                    </button>
                    <Dialog
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}
                      open={open}
                      onClose={handleClose}
                    >
                      <DialogTitle className="ms-4" style={{ fontFamily: "sans-serif" }}>Fare Details</DialogTitle>
                      <DialogContent>
                        <Box
                          noValidate
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                          }}
                          multiline
                        >
                          <FormControl
                            sx={{ mt: 2, minWidth: 120 }}
                          ></FormControl>
                          <div className="card ms-4">
                            <div className="row my-1 ms-2">
                              <InputLabel className="" style={{ textAlign: "center", fontStretch: "ultra-condensed" }}>OMR</InputLabel>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-9">
                                <TextField
                                  fullWidth="true"
                                  label="ROE"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Market fare"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-2 my-4">
                                <InputLabel>0.00</InputLabel>
                              </div>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Tax"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-2 my-3">
                                <InputLabel>0.00</InputLabel>
                              </div>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="STD Commission"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-8">
                                <TextField
                                  fullWidth="true"
                                  label="Supplier Amount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>

                              <div className="col-2 my-3">
                                <InputLabel>0.00</InputLabel>
                              </div>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Service Fee"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Extra Earning"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Payback Amount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Additional Mark up"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Discount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-8">
                                <TextField
                                  fullWidth="true"
                                  label="Selling Price"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>

                              <div className="col-2 my-3">
                                <InputLabel>0.00</InputLabel>
                              </div>
                            </div>
                          </div>

                          {/* <FormControlLabel
                            sx={{ mt: 1 }}
                            control={
                              <Switch
                                checked={fullWidth}
                                onChange={handleFullWidthChange}
                              />
                            }
                            label="Full width"
                          /> */}
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div>
              </div>
            </div>
            <div className="d-flex ">
              <button className="btn hovbuttonColour btn-sm ml-3 " onClick={AddHandler}>Add</button>
              <button className="btn hovbuttonColour btn-sm ml-3 bg-danger text-color-red" onClick={changeHandlerClear} >Clear</button>
            </div>
          </div>
          <div className="p-1">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer component={Paper} sx={{ maxHeight: 350 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>

                      <TableCell align="right">Type</TableCell>
                      <TableCell align="right">Supplier Name</TableCell>
                      <TableCell align="right">Voucher No</TableCell>
                      <TableCell align="right">Output VAT/GSTIN</TableCell>
                      <TableCell align="right">Profit</TableCell>
                      <TableCell align="right">Selling Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {servicestore.map((r) => (
                      <TableRow
                        key={r.type}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {r.type}
                        </TableCell>
                        <TableCell>
                          {r.suppliername}
                        </TableCell>
                        <TableCell align="right">{r.voucherno}</TableCell>
                        <TableCell align="right">{r.outputvatinomr}</TableCell>
                        <TableCell align="right">{r.profit}</TableCell>
                        <TableCell align="right">{r.sellingpriceinomr}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
