import React, { useEffect, useState } from 'react'
import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import Menu from '../../Component/Menu'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import ProjectManagementServices from '../ProjectManagementServices'
import { spin } from '../../Common/NewLoader'

const ProjectEstimationDetails = () => {
    const params = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  const [loader,setLoader] = useState(false)

  useEffect(() => {
    setLoading(true);
    ProjectManagementServices.GetProjectEstimationById(params.id)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [params]);

  return (
    <div>
    <Header />
    <Menu />
    <div className="content-wrapper">
      <div className="card card-info">
        <ol className="breadcrumb float-sm-right">
        <Link as="button" className=" text-secondary" to="/projectestimation">
              ❮ Back &nbsp;&nbsp;
            </Link>
          <li className="breadcrumb-item text-secondary">
            <Link to="/projectmanagement">Home</Link>
          </li>
          <li className="breadcrumb-item text-secondary">
            <Link to="/projectestimation">Project Estimation</Link>
          </li>
          <li className="breadcrumb-item active">Project Estimation Details</li>
        </ol>
        <section className="content">
          <div className="container-fluid">
                <div className="card-body">
                    {loading === true ? (
                      <div className="text-center">{spin}</div>
                    ) : (
                      <div className="row"> 
                          <div className="col-md-12">
                        <table className="float-left">
                          <tbody>
                          <tr>
                              <td>Estimation Id</td>
                              <td>{data.estimationId}</td>
                            </tr>
                            <tr>
                              <td>Project</td>
                              <td>{data.project.projectName}</td>
                            </tr>
                            <tr>
                              <td>Customer</td>
                              <td>{data.project.customerName}</td>
                            </tr>
                            <tr>
                              <td>Project Manager</td>
                              <td>{data.project.projectManagerName}</td>
                            </tr>
                            <tr>
                              <td>Prepared By</td>
                              <td>
                                {data.preparedBy}
                              </td>
                            </tr>
                            <tr>
                              <td>Remarks</td>
                              <td>{data.remark}</td>
                            </tr>
                          </tbody>
                        </table>
                          </div>
                          <div className="col-md-12">
                            {data.acti}
                          </div>
                          <div className="col-md-12">
                          <button
                            type="button"
                            className="btn btn-sm hovbuttonColour mr-2"
                          //   onClick={}
                            disabled={loader}
                          >
                            Submit
                          </button>
                        <Link 
                          as="button"
                          className="btn btn-sm deletebutton"
                          to={{ pathname: "/customers" }}
                          state={{ message: "" }}
                        >
                          <b className="text-danger"> X </b>Close
                        </Link>
                        </div> 
                      </div>
                    )}
                </div>
          </div>
        </section>
      </div>
    </div>
    <Footer />
  </div>
  )
}

export default ProjectEstimationDetails