import React, { Component } from 'react'
import { Formik, Form } from "formik";
import withRouter from '../withRouter';
import { FormikTextField } from '../CommonComponents/FormField.js';
import {Autocomplete, Backdrop, CircularProgress} from '@mui/material';
import { jname, jtype,combdata,personal,real,ctype, gsttype, totalcombdata} from './Journal';
import { NumericFormat } from 'react-number-format';import { ccountry, currentTenant, thousandsdisplay, userid ,financialyearend,financialyearstart, transactionFrom} from '../Common/Credentials';
import { TextField,Grid,FormControlLabel } from '@mui/material';
import { Spinner } from 'react-bootstrap';
import moment from "moment";
import IOSSwitch from "../CommonComponents/IOSSwitch.js";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Swal from 'sweetalert2';

let jvtype=2
let entrytype
//let typegst
class JournalForms extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
       
             firstcount:[],
             secondcount:[],
             gsttype:[{id:1,name:"Within State"},
             {id:2,name:"Inter State"}],
           
              jname:'',
              jtype:'',
              message:'',
              debselval:'',
              SDdate:false,
              fed:'',
              loader:false,
              loader1:true,
              jvinsertiontype:10,
            
        
        }
        this.validate=this.validate.bind(this);
        this.onSubmit=this.onSubmit.bind(this);

    }

    onDebBlur(values){

      //console.log(values.debit)
      this.setState({debselval:values.debit})


    }

  

    onSubmit(values){
      this.setState({loader:true})
      let gst,gstpercent,taxval1
      let cgst,cgstpercent,taxval2
      let igst,igstpercent,itaxval


      if(values.taxvalue1!==undefined){
        gst=values.sgst.name
        gstpercent=values.taxpercent1
        taxval1=values.taxvalue1
      }
      if(values.taxvalue2!==undefined){
        cgst=values.cgst.name
        cgstpercent=values.taxpercent2
        taxval2=values.taxvalue2
      }
      if(values.itaxvalue1!==undefined){
        igst=values.igst.name
        igstpercent=values.itaxpercent1
        itaxval=values.itaxvalue1
      }

      let jv={
        debitid:values.debit.id,
        debitname:values.debit.name,
        debitamt:values.debitval,
        gst:gst,
        gstpercent:gstpercent,
        taxval1:taxval1,
        cgst:cgst,
        cgstpercent:cgstpercent,
        taxval2:taxval2,
        igst:igst,
        igstpercent:igstpercent,
        itaxval:itaxval,
        creditid:values.credit.id,
        creditname:values.credit.name,
        creditamt:values.creditvalue,
        narration:values.narration,
        jvtype:jvtype,
        createdBy:userid,
        tenantId:currentTenant,
        entrytype:entrytype,
        jvinsertiontype:this.state.jvinsertiontype,
        date:this.state.fed!==''?this.state.fed:null
      }

      // NewJournalService.insertJv(jv)
      // .then(
      //   () =>
      // console.log(jv)

      if(values.debit.id===values.credit.id){
        Swal.fire({
          title: "Please Check !!!",
          text: "Debit and Credit Ledgers Cannot be Same",
          icon: "warning",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
        });
        this.setState({loader:false})

      }else{
        this.props.navigate({pathname:`/journalconfirm`},{state:jv})
      }
    }

    componentDidMount(){
      this.setState({jname:jname})
      this.setState({jtype:jtype})
      this.setState({loader1:false})
    }

    componentDidUpdate(){
   
     if(jtype!==this.state.jtype){
      this.setState({jtype:jtype})

     }}

     componentWillUnmount(){
        this.render()
     }

     handleSDDate=()=>{
      this.setState({SDdate:this.state.SDdate===true?false:true})
    }
    handleFsdDateChange = (date) => {
      if(date!==null){
      this.setState({ fsd: date });
      this.setState({
        fed: moment(date.$d).format("yyyy-MM-DD HH:mm:ss"),
      });
    }else{
      this.setState({ SDdate:false })
    }
    };
   
     validate(values){
      let errors = {};
       let deb = values.debtotal
       let cre = values.creditvalue
      // let type = real
       let type = combdata
       //let cash = type.filter((e) => e.subacc === "AH11") 
       //let bank = type.filter((e) => e.subacc === "AH12") 
       let ledgers= type.filter((e) => e.type === 1)

       //let creamt

       if (!values.debit || values.debit==='') {
        errors.debit = "Accounts is required";
      }else if(values.debit!==undefined){
        let creamt = values.debit.amount
         if(values.credit === ledgers.id){
          if(cre>creamt){
           errors.creditvalue="Insufficient Balance"
          }
        }
      }
       if(!values.debitval){
        errors.debitval="Value Cannot be Blank"
       }else if(deb!==cre){
        errors.creditvalue="Value Cannot be be different from debit Value"
       }
       
       if (!values.credit || values.credit==='') {
        errors.credit = "Accounts is required";
      }
       if(!values.creditvalue){
        errors.creditvalue="Value Cannot be Blank"
       }else if(deb!==cre){
        errors.creditvalue="Value Cannot be be different from debit Value"
       }
      
       if (!values.narration) {
        errors.narration = "Narration is required";
      }
       return errors;
     }
    
     calc(values){
       let tot
       tot=values.debitval+values.taxvalue
       return tot;
     }

     onChange(option){
      // console.log(option.name)
     this.setState({checked: option.id});
     //console.log(this.state.checked)

     //typegst=option.id
    //  console.log(jtype)
     //jname=option.name
    //  console.log(jname)
  }
     

    render(values,setFieldValue) {
      let debvalue
      let credvalue
           




      //console.log(ccountry)

   
      

       
        //let narration

        if (jtype===1){
          entrytype=1
          debvalue=combdata
          credvalue=combdata
         // console.log(combdata)
          // if(this.state.debselval===undefined){
          //   credvalue=combdata

          // }else{         

          //   credvalue=combdata.filter((e) => e.name !== this.state.debselval.name)
          // }
        } else if (jtype===2 ) {
          entrytype=1
          let val=combdata 
          //let cre=personal
          debvalue=val.filter((e) => e.subacc === "AH10" )
          credvalue=val.filter((e) => e.subacc === "AH13")
        }
        else if (jtype===3 ) {
          entrytype=1
          // let deb=personal 
          // let cre=nom
          let val=combdata 

          debvalue=val.filter((e) => e.subacc === "AH5")
          credvalue=val.filter((e) => e.name === "Sales")
        }
        else  if(jtype===4) {
          let val1=totalcombdata.filter((e)=> e.type===2)
            let val=val1.concat(totalcombdata.filter((e)=> e.type===3))
          if(ctype===1 || this.state.checked===1){
            entrytype=1
            // let deb = real
            // let cre = real
            
            debvalue=val.filter((e) => e.shid===100004 || e.shid===100003 ||  e.subheadid==="AH000006")
            credvalue=val.filter((e) => e.shid===100004 || e.shid===100003 || e.subheadid==="AH000006")
           
          
          
          }else if(ctype===2) {
            entrytype=1
            // let deb = real
            // let cre = real
            //let val=combdata.filter((e)=> e.type===2)
            debvalue=val.filter((e) => e.shid===100004 || e.shid===100003 || e.subheadid==="AH000006")
            credvalue=val.filter((e) => e.shid===100004 || e.shid===100003 || e.subheadid==="AH000006")
          }

        }
        else if (jtype===5 ) {
          entrytype=1
          // let deb=personal 
          // let cre=real
          let val=combdata 
          let cr1 = val.filter((e) => e.subacc === "AH11") 
          let cr2 = val.filter((e) => e.subacc === "AH12") 
          debvalue=val.filter((e)=> e.rule===0)
          credvalue=cr1.concat(cr2)


        }
        else if (jtype===6 ) {
          entrytype=1
          let val=combdata 
         // let deb=real
          let dr1 = val.filter((e) => e.subacc === "AH11") 
          let dr2 = val.filter((e) => e.subacc === "AH12")
          //let cre=personal
          debvalue=dr1.concat(dr2)
          credvalue=val.filter((e)=> e.rule===0)
        }
        return (
            <div>
              <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
        
    <Formik
      initialValues={{ personal,real,debitval:'',creditvalue:'',debvalue,credvalue,narration:'',gst:''}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

     

{({ values,setFieldValue}) => (

        <Form autoComplete="off">

{/* {ccountry==="India" && jtype===2?
<div className="form-row" > 
{this.state.gsttype.map((option)=>{

return <label key={option.id} >

    <div className="form-group col-md-12">
      <div className="radio-item">

             <input 
                type="radio" 
                checked={this.state.checked === option.id ? true : false} 
                key={option.id}
                onChange={this.onChange.bind(this,option)} 
                style={{marginRight:8}}
                value={option.id} 
                />
            {option.name}
        
            </div></div>
        </label>
       

})}</div>
     
      :''} */}

<div className="form-row" > 
<div className="form-row col-md-12 text-left form-switch mt-3">
 <div className="col-md-3 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.SDdate} onChange={this.handleSDDate} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.SDdate===false?"System Date":"Custom Date"}</span>
       </div>
    {this.state.SDdate===true?
     <div className="col-md-3 text-left">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid  justifyContent="space-around">
    <DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
    id="date-picker-dialog" inputVariant="standard"
    minDate={new Date(moment(transactionFrom).format("yyyy-MM-DD"))}
    maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
    format="dd/MM/yyyy HH:mm" value={this.state.fsd}
    // label="Custom Date" 
    InputAdornmentProps={{position: "start"}} onChange={this.handleFsdDateChange}
    renderInput={(params) => <TextField {...params} />}autoOk={true}/>
    </Grid></LocalizationProvider></div>
     :""}
    </div>

      <fieldset  className="form-group col-md-5"> 
       
      <Autocomplete id="debit" className="debit-select" name="adebit" options={debvalue}
      getOptionLabel={(option) => option.name +" ("+option.amount+")"} 
     onChange={(e, value) => {setFieldValue("debit",value)}} 
     renderInput={params => (
     <FormikTextField {...params}  type="text" name="debit" label="Debit" 
     onBlur={()=>{this.onDebBlur(values)}} variant="standard" fullWidth />)}/></fieldset>
     
     <fieldset  className="form-group col-md-4"> 

     {/* <NumericFormat  name="debitval"  className="form-control" label="Value"  displayType={'input'} customInput={TextField} decimalScale={2} fixedDecimalScale={true}   thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
      onBlur={()=>setFieldValue("creditvalue",values.debitval) &&
      setFieldValue("debtotal",values.debitval)} 
     ></NumericFormat> */}

     <FormikTextField name="debitval" className="form-control" label="Value" type="number" 
    onBlur={()=>setFieldValue("creditvalue",values.debitval) &&
                setFieldValue("debtotal",values.debitval)} variant="standard">

                </FormikTextField>
   </fieldset> </div>     
                    
{ccountry==="India" && jtype===2  && gsttype===1?
<>
<div className="form-row" >
   <fieldset  className="form-group col-md-5"> 
       
       <Autocomplete id="SGST" className="Tax-select" name="sgst" options={debvalue}
       getOptionLabel={(option) => option.name +" ("+option.amount+")"} 
        value={values.sgst?undefined:undefined} 
      onChange={(e, value) => {setFieldValue("sgst",value)}} 
      renderInput={params => (
      <FormikTextField {...params}  type="text" name="sgst" label="SGST" 
      onBlur={()=>{this.onDebBlur(values)}} variant="standard" fullWidth />)}/></fieldset>
      
      <fieldset  className="form-group col-md-1"> 
      {/* <NumericFormat  name="taxpercent1" className="form-control" label="%"  displayType={'input'} customInput={TextField} 
      onBlur={()=>setFieldValue("taxvalue1",values.debitval*values.taxpercent1/100) && 
      setFieldValue("taxpercent2",values.taxpercent1) && 
      setFieldValue("taxvalue2",values.debitval*values.taxpercent1/100)} 
     ></NumericFormat> */}

      <FormikTextField name="taxpercent1" className="form-control" label="%" type="number" 
     onBlur={()=> setFieldValue("taxvalue1",values.debitval*values.taxpercent1/100) && 
                  setFieldValue("taxpercent2",values.taxpercent1) && 
                  setFieldValue("taxvalue2",values.debitval*values.taxpercent1/100)} 
      
      variant="standard"/> </fieldset>

      <fieldset  className="form-group col-md-3"> 
      {/* <FormikTextField name="taxvalue1" className="form-control" label={values.taxpercent1?" ":"Value"} type="number"
       
     onBlur={()=> setFieldValue("creditvalue",values.taxvalue1+values.debitval+values.taxvalue2) &&
                  setFieldValue("debtotal",values.taxvalue1+values.debitval+values.taxvalue2)} 
      variant="standard"/> */}
      
      <NumericFormat  name="taxvalue1" className="form-control" value={values.debitval*values.taxpercent1/100} label={values.taxpercent1?" ":"Value"} displayType={'input'} customInput={TextField} decimalScale={2} fixedDecimalScale={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
      onBlur={()=>setFieldValue("creditvalue",values.taxvalue1+values.debitval+values.taxvalue2) &&
      setFieldValue("debtotal",values.taxvalue1+values.debitval+values.taxvalue2)} 
     ></NumericFormat>

      </fieldset> 
</div>
<div className="form-row" >
   <fieldset  className="form-group col-md-5"> 
       
       <Autocomplete id="CGST" className="Tax-select" name="CGST" options={debvalue}
       getOptionLabel={(option) => option.name +" ("+option.amount+")"} 
        value={values.cgst?undefined:undefined} 
      onChange={(e, value) => {setFieldValue("cgst",value)}} 
      renderInput={params => (
      <FormikTextField {...params}  type="text" name="cgst" label="CGST" 
      onBlur={()=>{this.onDebBlur(values)}} variant="standard" fullWidth />)}/></fieldset>

<fieldset  className="form-group col-md-1" disabled> 
      <FormikTextField name="taxpercent2" className="form-control" label={values.taxpercent1?" ":"%"} type="number"
     value={values.taxpercent1}
     onBlur={()=> {setFieldValue("taxvalue2",values.debitval*values.taxpercent2/100);}} variant="standard"/></fieldset> 
      
      <fieldset  className="form-group col-md-3" disabled> 
      {/* <FormikTextField name="taxvalue2" className="form-control" label={values.taxpercent1?" ":"Value"}  type="number"
     variant="standard"/> */}
     <NumericFormat  name="taxvalue2" className="form-control" value={values.debitval*values.taxpercent2/100} label={values.taxpercent1?" ":"Value"} displayType={'input'} customInput={TextField} decimalScale={2} fixedDecimalScale={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
      onBlur={()=>setFieldValue("creditvalue",values.taxvalue1+values.debitval+values.taxvalue2) &&
      setFieldValue("debtotal",values.taxvalue1+values.debitval+values.taxvalue2)} 
     ></NumericFormat>
     </fieldset> </div></>

:''}

{ccountry==="India" && jtype===(2 || 3) && gsttype===2?

<div className="form-row" >
   <fieldset  className="form-group col-md-5"> 
       
       <Autocomplete id="IGST" className="Tax-select" name="igst" options={debvalue}
       getOptionLabel={(option) => option.name +" ("+option.amount+")"} 
      value={values.igst?undefined:undefined} 
      onChange={(e, value) => {setFieldValue("igst",value)}} 
      renderInput={params => (
      <FormikTextField {...params}  type="text" name="igst" label="IGST" 
      onBlur={()=>{this.onDebBlur(values)}} variant="standard" fullWidth />)}/></fieldset>
      
      <fieldset  className="form-group col-md-1"> 
      <FormikTextField name="itaxpercent1" className="form-control" label="%" type="number"
     onBlur={()=> setFieldValue("itaxvalue1",values.debitval*values.itaxpercent1/100)} 
      
      variant="standard"/></fieldset> 

      <fieldset  className="form-group col-md-3"> 
      {/* <FormikTextField name="itaxvalue1" className="form-control" label={values.itaxpercent1?" ":"Value"} type="number"
       
     onBlur={()=> setFieldValue("creditvalue",values.itaxvalue1+values.debitval) &&
                  setFieldValue("debtotal",values.itaxvalue1+values.debitval)} 
      variant="standard"/> */}

<NumericFormat  name="itaxvalue1" className="form-control" value={values.debitval*values.itaxpercent1/100} label={values.itaxpercent1?" ":"Value"} displayType={'input'} customInput={TextField} decimalScale={2} fixedDecimalScale={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
      onBlur={()=>setFieldValue("creditvalue",values.itaxvalue1+values.debitval) &&
      setFieldValue("debtotal",values.itaxvalue1+values.debitval)} 
     ></NumericFormat>
      
      </fieldset> 
</div>:''}


{ccountry!==1 && jtype===(2||3)? <div className="form-row" >
   <fieldset  className="form-group col-md-5"> 
       
       <Autocomplete id="tax" className="Tax-select" name="tax" options={debvalue}
       getOptionLabel={(option) => option.name +" ("+option.amount+")"} 
     value={values.tax?undefined:undefined} 
      onChange={(e, value) => {setFieldValue("tax",value)}} 
      renderInput={params => (
      <FormikTextField {...params}  type="text" name="tax" label="Tax" 
      onBlur={()=>{this.onDebBlur(values)}} variant="standard" fullWidth />)}/></fieldset>

<fieldset  className="form-group col-md-1"> 
      <FormikTextField name="gtaxpercent1" className="form-control" label="%" type="number"
     onBlur={()=> setFieldValue("gtaxvalue1",values.debitval*values.gtaxpercent1/100)}
      
      variant="standard"/></fieldset> 
      
      <fieldset  className="form-group col-md-3" > 
      <FormikTextField name="gtaxvalue1" className="form-control" label={values.gtaxpercent1?" ":"Value"} type="number"
     onBlur={()=> setFieldValue("creditvalue",values.gtaxvalue1+values.debitval) &&
                  setFieldValue("debtotal",values.gtaxvalue1+values.debitval)} 
      variant="standard"/></fieldset>  
</div>:''}

<fieldset  className="form-group col-md-1" hidden> 
      <FormikTextField name="debtotal" className="form-control" label="%" type="number"
      variant="standard"/></fieldset> 

    <div className="form-row"> 
     <fieldset  className="form-group col-md-2"> </fieldset>
    <fieldset  className="form-group col-md-5"> 
     <Autocomplete id="credit" className="credit-select" name="acredit" options={credvalue}
     getOptionLabel={(option) => option.name +" ("+option.amount+")"}
     value={values.credit?undefined:undefined} onChange={(e, value) => { setFieldValue("credit",value);}}
    renderInput={params => (
      <FormikTextField {...params}  type="text" name="credit" label="Credit" variant="standard" fullWidth />)}/>
      </fieldset>
      <fieldset  className="form-group col-md-2" disabled>   
       <NumericFormat  name="creditvalue" className="form-control" value={values.debtotal} label={values.debitval?" ":"Value"} displayType={'input'} customInput={TextField} decimalScale={2} fixedDecimalScale={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}
      variant="standard"
     ></NumericFormat>
       </fieldset>

{/* <fieldset  className="form-group col-md-2"> 
<FormikTextField name="current" type="text" className="form-control" 
                  variant="standard"
                  /></fieldset> */}

                 </div>
                
                  <div className="form-row">
                  <fieldset  className="form-group col-md-8">   
                  <FormikTextField 
                  name="narration"
                  className="form-control"
                  type="text"
                  label="Narration"
                  variant="filled"
                  /></fieldset>

                  <fieldset className="form-group col-md-1">
                  <button className="btn hovbuttonColour btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button>
                  </fieldset></div>
                 

        </Form>

          )}
        
    </Formik>
    </div>
         
        )
    }
}

export default withRouter(JournalForms);              