import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  clientname,
  currentTenant,
  logo,
  userid,
} from "../../Common/Credentials";
// import moment from "moment";
import { spin } from "../../Common/NewLoader";
import { productionid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {
  FormControlLabel,
  IconButton,
  Tooltip,
} from "@mui/material";
import IOSSwitch from "../../CommonComponents/IOSSwitch";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ProductionServices from "../ProductionServices";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class JobOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: false,
      salestype: false,
      active: [],
      completed: [],
      cancelled: [],
      so: true,
      comp: false,
      canl: false,
  }
}

  pdf = () => {
    let pdfdata = this.state.data;
    pdfdata.map(
      (e) =>
        (pdfdata.find((i) => i.id === e.id).createdAt = moment(
          pdfdata.find((i) => i.id === e.id).createdAt
        ).format("DD-MM-YYYY hh:mm"))
    );
    pdfdata.map(
      (e) =>
        (pdfdata.find((i) => i.id === e.id).startdate = moment(
          pdfdata.find((i) => i.id === e.id).startdate
        ).format("DD-MM-YYYY"))
    );
    const columns = [
      { header: "JobOrder Id", dataKey:"id"},
      { header: "Estimation Id", dataKey: "estid" },
      { header: "Assign To", dataKey: "assignto" },
      { header: "Date", dataKey: "createdAt" },
    ];
    var doc = new jsPDF("p", "pt");
    doc.addImage(logo, "GIF", 40, 20, 60, 60);
    doc.setFontSize(16);
    doc.setFont("Times-Roman");
    doc.text(`${clientname}`, 180, 40);
    doc.setFontSize(14);
    doc.text(`Active Sale Orders`, 210, 80);
    doc.setFontSize(8);
    doc.text(`${moment().format("DD-MM-YYYY hh:mm")}`, 510, 80);
    doc.line(0, 90, 600, 90);
    autoTable(doc, {
      theme: "grid",
      startY: 100,
      columnStyles: { 7: { halign: "right" } },
      body: pdfdata,
      columns: columns,
    });
    doc.save("ActiveSaleordes.pdf");
    this.refresh();
  };

  handlesoChange = () => {
    this.setState({ so: true, comp: false, canl: false });
  };
  handlecompChange = () => {
    this.setState({ comp: true, so: false, canl: false });
  };
  handlecanlChange = () => {
    this.setState({ canl: true, so: false, comp: false });
  };

  refresh() {
    ProductionServices.getallJobOrders(currentTenant).then((response) => {
      this.setState({
        data: response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1),
        active: response.data.filter((e) => e.status === "Pending" || e.status === "Partial").sort((a, b) => a.createdAt < b.createdAt ? 1 : -1),
        completed: response.data.filter((e) => e.status === "Completed").sort((a, b) => a.createdAt < b.createdAt ? 1 : -1),
        cancelled: response.data.filter((e) => e.status === "Cancelled").sort((a, b) => a.createdAt < b.createdAt ? 1 : -1),
        loading: false,
      });
    });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.refresh();
  }



  render() {
    const columns = [
      {
        name: "id",
        label: "Job Order Id",
        options: { filter: false, sort: true,display:false,viewColumns:false },
      },
      {
        name: "customjoid",
        label: "Job Order Id",
        options: { filter: false, sort: true },
      },
      {
        name: "customestid",
        label: "Estimation Id",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              <Link
                as="button"
                data-tip
                data-for="view details"
                to={{
                  pathname: "/estimationdetails",
                  state: value,
                }}
              >
                {value}
              </Link>
            );
          },
        },
      },
      {
        name: "assigntoname",
        label: "Assign To",
        options: { filter: true, sort: true },
      },
      {
        name: "indstatus",
        label: "Indenting Status",
        options: { filter: true, sort: true, display:this.state.so}
      },
      {
        name: "jostatus",
        label: "Inprocess Status",
        options: { filter: true, sort: true, display:this.state.so}
      },
      {
        name: "status",
        label: "Status",
        options: { filter: true, sort: true,display:this.state.so}
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-yyyy hh:mm");
          },
        },
      },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {productionid === '2' || productionid === '3'? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{
                  pathname: "/newjoborder"}}
                  state= {{
                    id: -1,
                    pagename: "New JobOrder",
                    salestype: false,
                }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
            <span as="button" onClick={this.pdf}>
              <React.Fragment>
                <Tooltip title={"pdf"}>
                  <IconButton>
                    <i className="fa fa-file-pdf"></i>
                  </IconButton>
                </Tooltip>
              </React.Fragment>
            </span>
          </>
        );
      },
    };
    const options1 = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>

              <li className="breadcrumb-item active">JobOrder</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Job Order</h3>
                      </div>

                      <div className="card-body">
                        {this.state.loading === true ? (
                          <span className="text-center">{spin}</span>
                        ) : (<>
                        <div className="form-row">
                              <fieldset className="form-group col-md-4">
                                <span className="mx-3">Active</span>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      className="sm"
                                      checked={this.state.so}
                                      onChange={this.handlesoChange}
                                      name="checkedB"
                                    />
                                  }
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-4">
                                <span className="mx-3">Completed</span>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      className="sm"
                                      checked={this.state.comp}
                                      onChange={this.handlecompChange}
                                      name="checkedB"
                                    />
                                  }
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-4">
                                <span className="mx-3">Cancelled</span>
                                <FormControlLabel
                                  control={
                                    <IOSSwitch
                                      className="sm"
                                      checked={this.state.canl}
                                      onChange={this.handlecanlChange}
                                      name="checkedB"
                                    />
                                  }
                                />
                              </fieldset>
                            </div>
                            {this.state.so === true ? (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"JobOrders"}
                              data={this.state.active}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                            ):''}

                          {this.state.comp === true ? (
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Completed Job Orders"}
                                data={this.state.completed}
                                columns={columns}
                                options={options1}
                              />
                            ) : (
                              ""
                            )}

                            {this.state.canl === true ? (
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Cancelled Job Orders"}
                                data={this.state.cancelled}
                                columns={columns}
                                options={options1}
                              />
                            ) : (
                              ""
                            )}
                        </>)}
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(JobOrder);
