import {
  Table,
  createTheme,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { ThemeProvider } from "@mui/material";
import MasterService from "../MasterService";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class ViewRoles extends Component {
  constructor(props) {
    super(props);
    const id = this.props.location.state.id;

    this.state = {
      id: id,
      roles: [],
      screenList: [],
      loading: false,
    };
  }
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    const id = this.props.location.state.id;
    MasterService.getRolesScreens(id).then((res) => {
      const products = res.data.screenslist.reduce(
        (pro, { id, screenid, screenname, productid, product }) => {
          if (!pro[product]) pro[product] = [];
          pro[product].push({
            id: id,
            screenid: screenid,
            screenname: screenname,
            productid: productid,
            product: product,
          });
          return pro;
        },
        {}
      );
     
      this.setState({ roles: res.data, screenList: products });
    });
  }
  render() {
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <span
                  as="button"
                  className=" text-secondary cursorPointer"
                  onClick={() => this.props.navigate(-1)}
                >
                  ❮ Back &nbsp;&nbsp;
                </span>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/roles">Roles</Link>
              </li>
              <li className="breadcrumb-item active">View Roles</li>
            </ol>
            <div className="card-header">
              <h4 className="card-title">Role : {this.state.roles.name}</h4>
            </div>
            <div className="form-row">
              <div className="card-body">
                <div>
                  {this.state.loading === true ? (
                    <div className="text-center">{spin}</div>
                  ) : (
                    <ThemeProvider theme={theme}>
                        {console.log(this.state.screenList)}
                      <Table className="table-sm border">
                        <TableHead>
                          <TableRow>
                            <TableCell className="text-center">
                              Product
                            </TableCell>
                            <TableCell className="text-center">
                              Screens
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            Object.entries(this.state.screenList).map(([key, value],idx) => 
                            <TableRow key={idx}>
                              <TableCell className="text-center">
                                {key}({value.length})
                              </TableCell>
                              <TableCell className="text-center">
                                <table>
                                  <tbody>
                                    {value.map((e,i)=>
                                    <tr key={i}>
                                    <td>{e.screenname}</td>
                                    </tr>
                                    )}
                                  </tbody>
                                </table>
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </ThemeProvider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ViewRoles);
