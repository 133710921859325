import axios from 'axios';
import { Auth_Proto, Auth_Domain, Auth_Port } from "../Component/consts";

class AssetsService  {
           
    getassetTypes(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getassettypes/${currentTenant}`
    );
  }
    getmoveableTypes(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getmovabletype/${currentTenant}`
    );
  }

  getallAssets(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getallassetsoftenant/${currentTenant}`
    );
  }

  NewAsset(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/newasset`,
      data
    );
  }

  AssetIds(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getallassetsid/${currentTenant}`,);
  }

  GetAssetById(id){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getassetsbyid/${id}`,);
  }

  GetMaintenanceTypes(){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getmaintenancetypes`,);
  }
    
  NewAssetMaintenance(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/newassetmaintenance`,
      data
    );
  }
  
  
  GetAssetsMaintenanceofTenant(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getassetsmaintenanceoftenant/${currentTenant}`,);
  }

  
  
// -----------Accounts------------

  getallAssetsofTenantForAccounts(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getallassetsoftenantforaccounts/${currentTenant}`
    );
  }
    
  GetAssetsMaintenanceofTenantForAccounts(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getassetsmaintenanceoftenantforaccounts/${currentTenant}`,);
  }

  CheckAvilability(currentTenant,itemid,wcid){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/checkavilability/${currentTenant}/${itemid}/${wcid}`,);
  }

  NewProductionUsage(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/newproductionusage`,
      data
    );
  }

  GetProductionusageoftTnant(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getproductionusageoftenant/${currentTenant}`,);
  }

  GetAssetsidforRentandSold(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getassetsidforrentandsold/${currentTenant}`,);
  }

  GetThirdpartyassetsforRental(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getthirdpartyassetsidforrent/${currentTenant}`,);
  }
  NewRentAssets(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/newrentassets`,
      data
    );
  }
  NewRentalQuotation(data){
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/newrentassetsquotation`,
      data
    );
  }

  EditRentalQuotation(data){
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/editrentassetsquotation`,
      data
    );
  }

  GetRentassetsoftTenant(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getrentassetsoftenant/${currentTenant}`,);
  }

  GetRentassetByid(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getrentassetbyid/${id}`);
  }
  GetRentalQuotationofTenant(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getrentalquotationoftenant/${currentTenant}`,);
  }

  GetSoldAssetById(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getsoldassetbyid/${id}`);
  }

  NewSoldAssets(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/newsoldassets`,
      data
    );
  }

  NewSalesQuotation(data){
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/newsoldassetsquotation`,
      data
    );
  }

  EditSalesQuotation(data){
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/editsoldassetsquotation`,
      data
    );
  }

  GetSoldassetsoftTnant(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getsoldassetsoftenant/${currentTenant}`,);
  }

  GetSoldQuotationofTenant(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getsoldquotationoftenant/${currentTenant}`,);
  }
  

  NewAssetInvoice(data){
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/newassetinvoice`,
      data
    );
  }

  GetAssetInvoicebyTenantid(tenantid){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getassetinvoicebytenantid/${tenantid}`,);
  }


GetAssetInvoiceById(id){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getassetinvoicebyid/${id}`,);
  }
  GetInvoiceofRentById(id){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getassetinvoiceofrentasset/${id}`,);
  }

  GetInvoiceofSoldById(id){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getassetinvoiceofsoldasset/${id}`,);
  }

  updateproductionusagestatus(id){
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/updateproductionusagestatus/${id}`,);
  }

  CompleteRentalasset(id){
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/completerentalasset/${id}`,);
  }
  TerminateRentalasset(id){
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/terminaterentalasset/${id}`,);
  }

  getAllRentalQuotationId(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getrentalquotationid/${currentTenant}`,);
  } 

  getRentalQuotationbyId(id){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getrentalquotationbyid/${id}`,);
  }

  getAllSoldQuotationId(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getsoldquotationid/${currentTenant}`,);
  } 

  getSoldQuotationbyId(id){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getsoldassetquotationbyid/${id}`,);
  }

  
  MaintenanceStatus(id,status){
    return axios.put(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/assetmaintenancestatus/${id}/${status}`,);
  }

  getAssetsData(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getassetsdata/${currentTenant}`,);
  }

  GetTripsforAcc(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/gettripsforacc/${currentTenant}`,);
  }

  GetThirdpartyassets(currentTenant){
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getthirdpartyassets/${currentTenant}`);
  }

  NewThirdPartyAsset(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/newthirdpartassets`,data)
  }
  GetThirdpartyAssetById(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/getthirdpartyassetbyid/${id}`);
  }
  RetunThirdPartyAssets(data){
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/assetmanagement/retunthirdpartyassets`, data)
  }
}

export default new AssetsService()
