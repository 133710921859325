import React, { Component } from 'react'
import { Field, Form, Formik } from 'formik';
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import Footer from '../Component/Footer';
import UserService from './UserService';
import Eye from './Eye';
import { FormikTextField } from '../CommonComponents/FormField.js';
import { userid } from '../Common/Credentials';

class ChangePassword extends Component {

    constructor(props) {
        super(props)
    
        this.state = {
            type: 'password',
            name:this.props.location.state.name,
             id:this.props.location.state.id,
             password:''
         
        }
        this.onSubmit=this.onSubmit.bind(this)
        this.handleClick=this.handleClick.bind(this)
    }
    
    handleClick = () => this.setState(({type}) => ({
        type: type === 'text' ? 'password' : 'text'
      }))

    validate(values) {
        let errors = {};
       
        if (!values.password) {
          errors.password = "Password is required";
        }
    
        return errors;
      }
      onSubmit(values){
          let details={
              id:values.id,
              password:values.password
          }
          UserService.changepassword(details).then(() => this.props.navigate(`/users`))

      }

componentDidMount(){
  if(userid===undefined){
    window.confirm('Your Session is Lost: Please login Back to resume')
    this.props.navigate(`/`)
  }
}    

    render() {


        let {id,password } = this.state;

        return (
            <div>
          <Header/>
               <Menu/>
        <div className="content-wrapper mt-3">
          <div className="card card-info">
            {/* <div className="card-header">
              <h3 className="card-title">{this.state.name}</h3>
            </div> */}
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/index">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/users">Users</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.name}</li>
            </ol>
            <div>
              <Link as="button" className="float-left btn btn-sm btn-outline-secondary" style={{ marginLeft: "20px" }} to={{ pathname: "/users" }}>
                <b><i className="fas fa-arrow-left"></i> Back</b>
              </Link>
            </div>
            <div className="card-body">

            
              <Formik
                initialValues={{id,password } }
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}>
                {({props,setFieldValue,values}) => (
                  <Form>

                    <div className="form-row">
                      <fieldset hidden  className="form-group col-md-6">
                        <label>Id</label>
                        <Field className="form-control" type="text" name="id" placeholder="Id" />
                        </fieldset>
       
    

                      <fieldset className="form-group col-md-6">
                        <FormikTextField className="form-control"  type={this.state.type} id="password-field" name="password" label="Password"/>
                        <span style={{position: 'absolute',width: '20px',right:'20px',top:"20px"}} onClick={this.handleClick}><i ><Eye/></i></span>

                      {/* <label>Change Password<span className="text-danger float-sm-right">*</span> </label>
                      <span style={{position: 'absolute',width: '20px',right:'20px', top:'40px'}} onClick={this.handleClick}><i ><Eye/></i></span>
                      <Field className="form-control  " 
                      type={this.state.type}
                      id="password-field"
                      name="password" placeholder="Enter New Password" 
                      />                   
                      {/* <span className="container-fluid" onClick={this.handleClick}>{this.state.type === 'text' ? 'Hide' : 'Show Password'}</span> */}
                      {/* <span toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password" onClick={this.handleClick}>{this.state.type === 'text' ?  '' :  '' }</span> */}

                      {/* <ErrorMessage name="password" component="div" className="text-danger float-sm-right" /> */}

                    </fieldset>

                    </div>
                    <fieldset className="form-group col-md-12"/>
                    <button className="btn btn-success" type="submit">Save</button>

                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

      <Footer/>
      </div>
        )
    }
}

export default withRouter(ChangePassword)

