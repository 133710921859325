import React, { Component } from "react";
import withRouter from "../withRouter.js";
import { currentTenant, userid } from "../Common/Credentials";
import SettingsServices from "./SettingsServices";
import {
    Card,
    CardContent,
    Typography,
    CardActions,
    TextField,
} from "@mui/material";
import { Modal } from "react-bootstrap";
import { spin } from "../Common/NewLoader";
import { Spinner } from "react-bootstrap";
let edittemplete;
class PdfTemplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templete: [],
            edit: false,
            showModal: false,
            templateid: "",
            content: "",
            subject: "",
            signature: "",
            terms:'',
            loading: false,
            loader: false,
            i: 3,
            id: '',
            activestatus: '',
            options: [],
            data: [],
            selected: '',
        };
    }
    onChange(option) {
        this.setState({
            selected: option,
            templete: this.state.data.find(e => e.type === option).pdftemplate===null?[]:this.state.data.find(e => e.type === option).pdftemplate
        }, () => this.setState({ i: this.state.templete.length }));
    }

    activetemp = (id) => {
        SettingsServices.ActivePdfTemplete(id).then(() => {
            this.setState({ loading: true, loader: false, showModal: false, selected: '', options: [], templete: [], i: 5 }, () => this.refresh())
        });
    }

    deletetemp = (id) => {
        SettingsServices.deletePdfTemplete(id).then(() => {
            this.setState({ loading: true, loader: false, showModal: false, selected: '', options: [], templete: [], i: 5 }, () => this.refresh())
        });
    };

    handleShowModal = (id) => {
        if (id === -1) {
            this.setState({
                id: id,
                content: "Add Your Content here",
                product: this.state.selected,
                signature: "SIGNATURE",
                subject: "Add Your Subject here",
                terms:"Add your  terms and conditions",
                tenantid: currentTenant,
                loader:false
            });
        } else {
            edittemplete = this.state.templete.filter((e) => e.id === id);
            edittemplete.map((e) =>
                this.setState({
                    id: e.id,
                    content: e.content,
                    product: this.state.selected,
                    subject: e.subject,
                    signature: e.signature,
                    tenantid: currentTenant,
                    terms:e.terms,
                    activestatus: e.activestatus,
                    loader:false,
                })
            );
        }
        this.setState({ showModal: true });
    };

    handleModalClose = () => {
        this.setState({ showModal: false });
    };

    handleFinalSubmit = () => {
        this.setState({ loader: true })
        let newtemplete = {
            id: this.state.id,
            content: this.state.content,
            templatetype: this.state.selected,
            signature: this.state.signature,
            subject: this.state.subject,
            terms:this.state.terms,
            tenantid: currentTenant,
            activestatus: this.state.activestatus
        };
        if (this.state.id === -1) {
            SettingsServices.addPdfTemplete(newtemplete).then(() => {
                this.setState({ loading: true, loader: false, showModal: false, selected: '', options: [], templete: [], i: 5 }, () => this.refresh())
            })
                .catch((e) => {
                    if (e.response.data.error === "Unauthorized") {
                        alert("Your Session is Lost: Please login Back to resume");
                        this.props.navigate(`/`);
                    }
                })
        } else {
            SettingsServices.EditPdfTemplete(newtemplete).then(() => {
                this.setState({ loading: true, loader: false, showModal: false, selected: '', options: [], templete: [], i: 5 }, () => this.refresh())
            })
                .catch((e) => {
                    if (e.response.data.error === "Unauthorized") {
                        alert("Your Session is Lost: Please login Back to resume");
                        this.props.navigate(`/`);
                    }
                })
        };
    }

    refresh() {
        SettingsServices.retrivePdfTemplets(currentTenant).then((response) =>
            this.setState({
                data: response.data,
                options: response.data.map(e => e.type),
                loading: false,
            })
        )
            .catch((e) => {
                if (e.response.data.error === "Unauthorized") {
                    alert("Your Session is Lost: Please login Back to resume");
                    this.props.navigate(`/`);
                }
            })
    }
    componentDidMount() {
        if (userid === undefined) {
            window.confirm("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
        this.setState({ loading: true });
        this.refresh();
    }
    render() {
        return (
            <div>
                   <div className="card-header text-center text-md text-bold textColour">
                        Pdf Templates Settings
                      </div>
                <div className="card-body">
                    {this.state.loading === true ? (
                        <span className="text-center">{spin}</span>
                    ) : (
                        <>
                            <div className="form row">
                                {this.state.options.map((option) => {
                                    return (
                                        <label key={option}>
                                            <div className="form-group col-md-9 text-sm">
                                                <div className="btn-group">
                                                    <input
                                                        className={`shadow-sm text-center btn  ${this.state.selected === option? 'hovborderbuttonColour':' hovbuttonColour'}`}
                                                        type="button"
                                                        key={option}
                                                        onClick={() => this.onChange(option)}
                                                        style={{ width: 180 }}
                                                        value={option}
                                                    />
                                                </div>
                                            </div>
                                        </label>
                                    );
                                })}
                             <div className="col-md-12"></div>

                                {this.state.templete.map((e, index) => (
                                    <fieldset className="form-group col-md-6" key={index}>
                                        <Card className={`${e.activestatus === "ACTIVE" ? "borderedbutton" : ''}`}>
                                            <CardContent>
                                                <Typography component="label" className="form-group col-md-12 pb-2 text-center border-bottom">
                                                   {this.state.selected} Pdf Template 
                                                </Typography>

                                                {/* <fieldset className="form-group col-md-12  mt-3">
                                                <b>Context:&nbsp; </b>{e.content}
                                                </fieldset>
                                                <fieldset className="form-group col-md-12  mt-3">
                                                <b>Subject:&nbsp; </b>{e.subject}
                                                </fieldset> */}
                                                <fieldset className="form-group col-md-12  mt-3">
                                                <b>Terms & Conditions:&nbsp; </b>{e.terms}
                                                </fieldset>
                                                <fieldset className="form-group col-md-12  mt-3">
                                                <b>Signature:&nbsp; </b>{e.signature}
                                                </fieldset>
                                            </CardContent>
                                            <CardActions className="justify-content-center">
                                                <span
                                                as="button"
                                                    size="small"
                                                    onClick={() =>
                                                        this.handleShowModal(e.id)
                                                    }
                                                    className="btn btn-sm hovbuttonColour"
                                                >
                                                    <i className="fa fa-edit mr-1" />
                                                    Edit
                                                </span>
                                                {e.activestatus === "ACTIVE" ?
                                                    '' : <>
                                                        <span as="button"
                                                            size="small"
                                                            onClick={() => this.deletetemp(e.id)}
                                                            className="btn btn-sm deletebutton"
                                                        >
                                                            <i className="fas fa-trash mr-1"></i>
                                                            Delete
                                                        </span>
                                                        <span 
                                                        as="button"
                                                            size="small"
                                                            onClick={() => this.activetemp(e.id)}
                                                            className="btn btn-sm hovbuttonColour"
                                                        >
                                                            Set as Active
                                                        </span>

                                                    </>
                                                }
                                            </CardActions>
                                        </Card>
                                    </fieldset>
                                ))}
                                <fieldset className="form-group col-md-12"></fieldset>
                                {this.state.i < 2 ? (
                                    <button
                                        className="btn btn-sm hovbuttonColour ml-1"
                                        onClick={() => this.handleShowModal(-1)}
                                    >
                                        Add New
                                    </button>
                                ) : (
                                    ""
                                )}

                            </div>
                        </>
                    )}

                    {/* Start of Modal */}
                    <Modal
                        show={this.state.showModal}
                        onHide={this.handleModalClose}
                        backdrop="static"
                        keyboard={false}
                        centered
                        size="lg"
                    >
                        <Modal.Header closeButton>Edit Template</Modal.Header>

                        <Modal.Body>
                            <div className="form row">
                                <Typography gutterBottom component="div">
                                    {this.state.templateid}
                                </Typography>
                                {/* <fieldset className="form-group col-md-12  mt-3 mb-5">
                                    <TextField
                                        name="content"
                                        className="form-control text-sm"
                                        multiline
                                        rows={2}
                                        label="Context"
                                        variant="outlined"
                                        value={this.state.content}
                                        onChange={(e) =>
                                            this.setState({ content: e.target.value })
                                        }
                                    />
                                </fieldset>
                                <fieldset className="form-group col-md-12  mt-3 mb-5">
                                    <TextField
                                        name="subject"
                                        className="form-control text-sm"
                                        multiline
                                        rows={2}
                                        label="Subject"
                                        variant="outlined"
                                        value={this.state.subject}
                                        onChange={(e) =>
                                            this.setState({ subject: e.target.value })
                                        }
                                    />
                                </fieldset> */}
                                <fieldset className="form-group col-md-12  mt-3">
                                    <TextField
                                        name="terms"
                                        className="form-group col-md-12 text-sm"
                                        multiline
                                        rows={10}
                                        label="Terms & Conditions"
                                        variant="outlined"
                                        value={this.state.terms}
                                        onChange={(e) =>
                                            this.setState({ terms: e.target.value })
                                        }
                                    />
                                </fieldset>
                                <fieldset className="form-group col-md-12  mt-3 mb-3">
                                    <TextField
                                        name="signature"
                                        className=" form-group col-md-12 text-sm"
                                        multiline
                                        label="Signature"
                                        variant="outlined"
                                        value={this.state.signature}
                                        onChange={(e) =>
                                            this.setState({ signature: e.target.value })
                                        }
                                    />
                                </fieldset>
                                <div className="form-group col-md-9">
                                    {this.state.loader === false ? (
                                        <button
                                            className={`btn btn-sm hovbuttonColour float-center`}
                                            onClick={this.handleFinalSubmit}
                                        >
                                            <i className="fa fa-save" /> Save
                                        </button>
                                    ) : (
                                        <button
                                            className={`btn btn-sm hovbuttonColour float-center`}
                                            onClick={this.handleFinalSubmit}
                                            disabled
                                        >
                                            <>
                                                <Spinner animation="border" size="sm" />
                                                &nbsp;{"Saving"}
                                            </>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* End of Modal */}
                </div>
            </div>
        );
    }
}
export default withRouter(PdfTemplete);
