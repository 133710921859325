import React from 'react';


export default class Eye extends React.Component{
  state = {isPasswordShown:false};
  togglePasswordVisibality =()=>{
    const {isPasswordShown}=this.state
    this.setState({isPasswordShown:!isPasswordShown})
  }
  
  render(){
    const {isPasswordShown} =this.state;
    return(

      <i className = {`fa ${isPasswordShown? "fa-eye textColour":"fa-eye-slash"} password-icon`} onClick={this.togglePasswordVisibality}/>
    )
  }
}