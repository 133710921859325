import { createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { currentTenant, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import { appmasterid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { ThemeProvider } from "@mui/material";
import MasterService from "../MasterService";
import UserService from "../../users/UserService";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class Roles extends Component {
  constructor(props) {
    super(props);

    this.state = {
        roles: [],
        loading:false,
    };
  }
  deleteRole(id){
    MasterService.DeleteRole(id).then((resposne) => {
      UserService.DeleteAuthRoles(id)
      this.setState({
        message: `Role Deleted Successfully`,
        loading: true,
      });
      this.refresh();
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  refresh(){
    MasterService.getRoles(currentTenant).then(res=>
      this.setState({roles:res.data,loading:false})
      )
  }
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({loading:true})
   this.refresh()
  }
  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          sortOrder: "asc",
        },
      },
      {
        name: "name",
        label: "Role",
        options: { filter: true, sort: true },
      },
      {
        name: "View",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (<>
              <Link as="button" className="btn btn-sm hovbuttonColour"
              to={{pathname: "/viewroles"}} state ={{id:tableMeta.rowData.find(e=>e)}}>View</Link>
              </>
            );
          },
        }
      },
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
            tableMeta.rowData[1] === "ROLE_ADMIN"?"":<>
             <Link  className="btn btn-sm hovbuttonColour mr-2"
              to={{pathname: "/newroles"}} state ={{id:tableMeta.rowData.find(e=>e)}}>Edit</Link>

              <span
                as="button"
                data-tip
                data-for="delete"
                className="btn btn-sm deletebutton"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you wish to delete Role ?`
                    )
                  )
                    this.deleteRole(tableMeta.rowData.find((e) => e));
                }}
              >Delete</span>
              </>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{
                  pathname: "/newroles"}}
                  state= {{ id: -1}}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };
    return (
      <div>
      <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item active">Roles</li>
            </ol>
            <div className="form-row">
              <div className="card-body">
                <div>
                  {this.state.loading === true ? (
                    <div className="text-center">{spin}</div>
                  ) : (
                    <ThemeProvider theme={theme}>
                      <MUIDataTable
                        className="table-sm px-3"
                        title={"Roles"}
                        data={this.state.roles}
                        columns={columns}
                        options={options}
                      />
                    </ThemeProvider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Roles);
