import { FormControlLabel } from '@mui/material'
import { Form, Formik } from 'formik'
import { FormikTextField } from '../CommonComponents/FormField.js';
import React, { Component } from 'react'
import withRouter from "../withRouter.js";
import { currentTenant, userid } from '../Common/Credentials'
import { spin } from '../Common/NewLoader'
import IOSSwitch from '../CommonComponents/IOSSwitch'
import SettingsServices from './SettingsServices'

class template extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            host: '',
            port: 587,
            email: '',
            password: '',
            loading: false,
            starttls: true,
            success: false,
            message: ''
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.validate = this.validate.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentDidMount() {
        if (userid === undefined) {
            window.confirm('Your Session is Lost: Please login Back to resume')
            this.props.navigate(`/`)
        }
        this.setState({ loading: true })

        SettingsServices.GetMailConfig(currentTenant).then((response) => {
            this.setState({ 
                data: response.data, 
                host:response.data.host,
                email:response.data.username,
                starttls:response.data.starttls1,
                port:response.data.port,
                loading: false 
            })
        })
    }

    handleStartTLS = () => {
        this.setState({ starttls: !this.state.starttls, port: !this.state.starttls === true ? 587 : 25 })
    }

    onSubmit() {
        let data = {
            host: this.state.host,
            port: this.state.port,
            username: this.state.email,
            password: this.state.password,
            starttls1: this.state.starttls
        }
        SettingsServices.TestMail(data).then((response) => {
            this.setState({ success: response.data, message: response.data === true ? "Mail Configuration Settings are valid please submit" : "Invalid Mail Configuration Settings" })
        })
    }

    handleSubmit() {
        let data = {
            host: this.state.host,
            port: this.state.port,
            username: this.state.email,
            password: this.state.password,
            starttls1: this.state.starttls,
            tenantid: currentTenant,
            createdby: userid
        }
        SettingsServices.AddMailConfig(data).then((response) => {
            this.setState({ success: response.data,message:'' })
        })
    }

    validate() {

    }

    render() {
        let { host, port, email, password, starttls } = this.state
        return (
            <div>
                {this.state.loading === true ? <div className="text-center">{spin}</div> :
                    <Formik
                        initialValues={{ host, port, email, password, starttls }}
                        onSubmit={this.onSubmit}
                        validateOnChange={false}
                        validate={this.validate}
                        enableReinitialize={true}>
                        {() => (
                            <Form autoComplete="off">
                                <div className="form-row">
                                    <fieldset hidden className="form-group col-md-6">
                                        <label>Id</label>
                                        <FormikTextField className="form-control" type="text" name="id" placeholder="Id" />
                                    </fieldset>


                                    <fieldset className="form-group col-md-4">
                                        <FormikTextField className="form-control" type="text" name="host" label="Mail Server Host" value={this.state.host} onChange={(e) => this.setState({ host: e.target.value })} />
                                    </fieldset>
                                    <fieldset className="form-group col-md-4">
                                        <FormikTextField className="form-control" type="email" name="email" label="E-mail" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                    </fieldset>
                                    <fieldset className="form-group col-md-4">
                                        <FormikTextField className="form-control" type="password" name="password" label="Password" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                    </fieldset>

                                    <fieldset className="form-group col-md-2 mt-3">
                                        Start TLS :
                                        <FormControlLabel
                                            className="float-right my-0 mr-0"
                                            control={
                                                <IOSSwitch
                                                    className="sm my-0 mr-0"
                                                    checked={this.state.starttls}
                                                    onChange={this.handleStartTLS}
                                                    name="checkedB"
                                                />
                                            }
                                        />
                                    </fieldset>
                                    <fieldset className="form-group col-md-1" />
                                    <fieldset className="form-group col-md-4">
                                        <FormikTextField clabtnssName="form-control" type="text" name="port" label="Port" value={this.state.port} onChange={(e) => this.setState({ port: e.target.value })} />
                                    </fieldset>
                                    <fieldset className={`form-group col-md-12 ${this.state.success===true?'text-success':'text-danger'}`}>{this.state.message}</fieldset>
                                    {this.state.success === true ?
                                        <span as="button" className="btn btn-sm hovbuttonColour" onClick={this.handleSubmit}><i className="fa fa-save" /> Save Settings</span> :
                                        <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fas fa-file" />  Test</button>
                                    }
                                </div>
                            </Form>
                        )}
                    </Formik>
                }
            </div>
        )
    }
}

export default withRouter(template)
