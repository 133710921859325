import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";

import Header from "../../../Component/Header";
import Menu from "../../../Component/Menu";
import Footer from "../../../Component/Footer";
import { Link, useNavigate } from "react-router-dom";
import TourServices from '../../Invoice/TourServices';
import { currentTenant, currentUser } from "../../../Common/Credentials";
import moment from "moment";
import withRouter from "../../../withRouter";



const MAirport = (props) => {
  const [checked, setChecked] = useState(true);
  const nevigate = useNavigate();
  const [data, setData] = useState({
    id: 0,
    code: "",
    city: "",
    country: "",
    latitude: "",
    longitude: "",
    remarks: "",
    createddate:new Date(),
    modifieddate:new Date(),
    tenantid:currentTenant,
  });
 

  useEffect(() => {
    
    console.log(props.location.state.id);
    if(props.location.state.id !== -1)
    {
        TourServices.getByIdMAirport(props.location.state.id).then((r)=>{
            setData({
              id: r.data.id,
              code: r.data.code,
              city: r.data.city,
              country: r.data.country,
              latitude: r.data.latitude,
              longitude: r.data.longitude,
              createddate:r.data.createddate,
              modifieddate:r.data.modifieddate,
              tenantid:r.data.tenantid
            })
            
        })
    }
  },[])

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const changeHandler = (e) => {
    setData(prevValues => {
      return { ...prevValues, [e.target.name]: e.target.value }
    })
  }

  const AddHandler = (e) => {

    e.preventDefault();
    if(props.location.state.id === -1)
    {
        
            
            const sdata = {
              id: data.id,
              code: data.code,
              city: data.city,
              country: data.country,
              latitude: data.latitude,
              longitude: data.longitude,
              createddate:new Date(),
              modifieddate:new Date(),
              tenantid:currentTenant,  
            }
            TourServices.addMAirport(sdata).then((r) => {
              console.log(r.data);
            }).catch(err => console.log(err));
            nevigate("/masterairportlist");
    }   
    else
    {
      const sdata = {
        id: data.id,
        code: data.code,
        city: data.city,
        country: data.country,
        latitude: data.latitude,
        longitude: data.longitude,
        modifieddate:new Date(),
        tenantid:currentTenant,  
        
      }
      TourServices.updateMAirport(sdata,props.location.state.id).then((r)=>console.log(r.data,"Airport Data"))
      nevigate("/masterairportlist");
    }

  }


  return (
    <>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li><Link to="/masterairportlist" style={{ color: "grey" }}>❮ Back &nbsp;&nbsp;</Link></li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/masterairportlist">Airport List </Link>
            </li>
            <li className="breadcrumb-item active">Master Airport</li>
          </ol>
          <div className="">
            <form onSubmit={AddHandler}>
              <div className="card-body p-6">
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                     required="true"
                      label="Airport Codes"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="code"
                      value={data.code}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                      required
                      label="city"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="city"
                      value={data.city}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      required
                      label="country"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="country"
                      value={data.country}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  
                </div>
                <div className="row my-1">
                  <fieldset className="col-md-4 col-sm-12">
                    <TextField
                      fullWidth
                      
                      label="Latitude"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="latitude"
                      type="number"
                      value={data.latitude}
                      onChange={changeHandler}
                    />
                  </fieldset>
                  <fieldset className="col-md-8 col-sm-12">
                    <TextField
                      fullWidth
                     
                      label="Longitude"
                      id="outlined-size-small"
                      size="small"
                      variant="outlined"
                      name="longitude"
                      type="number"
                      value={data.longitude}
                      onChange={changeHandler}
                    />
                  </fieldset>
                </div>
 
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="my-2">
                      <label>Created By : Jnanasoftware</label>
                    </div>
                 
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className=" my-2">
                      <label>Modified By : {currentUser} </label>
                    </div>
                  </div>
                </div>
                <div className="row my-1">
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <label>Created Time : {moment(data.createddate).format("DD-MM-YYYY")}</label>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12 d-flex ">
                    <div className="">
                      <label>Modified Time : {moment(data.modifieddate).format("DD-MM-YYYY")} </label>
                    </div>
                  </div>
                  <div className="btn d-flex justify-context-center">
                    <button className="btn buttonColour btn-sm  " >ADD</button>
                  </div>
                </div>
              </div>
            </form>
            
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(MAirport);
