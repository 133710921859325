import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

class NewLedgerService {

    retrivePartyLedgers(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getdataforledger/${tenantId}`)
    }

    retriveEmployees(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getempdata/${tenantId}`)
    }

    // getCurrentBal(tenantId,Name){
     //   return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getcashbal/${tenantId}&${Name}`)
   // }

    getCurrentBal(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getcashbal/${tenantId}`)
    }

    addCahOpening(newCash){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/addcashopening`,newCash)
    }

    

    delCash(id){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/delcash/${id}`)
    }

    addBank(newOrgbank){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/addbank`,newOrgbank
        )}
    
    getBanks(tenantId){
            return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getbanks/${tenantId}`)
        }

     getBankTrans(tenantId){
            return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getbanktrans/${tenantId}`)
        }

    getMasterBanks(tenantId){
            return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getbanksforledgerentry/${tenantId}`)
        }

    getPledgers(tenantId){
            return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getpledgers/${tenantId}`)
        }

    getPendingOpenings(tenantId){
            return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getpendingopenings/${tenantId}`)
        }

    createPartyLedger(newpledger){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/addnewpledger`,newpledger
        )}

    delParty(value){
            return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/delParty/${value}`)
        }

    getAlLedgers(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getlaledgers/${tenantId}`)
    }

    createLA(newLA){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/addnewLA`,newLA)
    }

    delLA(value){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/delLA/${value}`)
 
    }
    
    delBA(ledban){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/delBank`,ledban)

    }

    getnominalledgers(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getnominalledgers/${tenantId}`)

    }
    createIE(newIE){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/addnewIE`,newIE)

    }

    createCatIE(newCatIE){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/addcatie`,newCatIE)
    }

    // addcatie
    delNom(value){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/delNom/${value}`)
    }

    getSelLedger(idtenid){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getparty/`,idtenid)
    }
    
    getLedgerBehaviour(){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getledbehav`)
    }

    getDirectTaxes(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getPendingDirectTaxes/${tenantId}`)
    }


}
export default new NewLedgerService();