import React, { Component } from 'react'
import { Formik, Form } from "formik";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import { FormikTextField } from '../CommonComponents/FormField.js';
import {Autocomplete,Backdrop,CircularProgress,TextField} from '@mui/material';
import { NumericFormat } from 'react-number-format';import {currentTenant, currentUser, thousandsdisplay, userid,financialyearend,financialyearstart, transactionFrom} from '../Common/Credentials';
import { Typography} from '@mui/material';
import moment from 'moment';
import NewJournalService from './NewJournalService';
import { TableHead,TableBody,TableRow,Table, TableCell,Grid,FormControlLabel} from "@mui/material";
import AuthenticationService from '../Authentication/AuthenticationService';
import { Spinner } from 'react-bootstrap';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { spin } from '../Common/NewLoader';
import Footer from '../Component/Footer';
import IOSSwitch from "../CommonComponents/IOSSwitch.js";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const getTotal = (totamt) => {
  let total = 0;
  totamt.forEach(totamt1 => {
    console.log(totamt)
    total += totamt1;
  });
  return total;
};
let totalcombdata=[]//,entrytype,pmtcheck,advpoamt,totalpending=[],poid=null
let selchk=[],pmtid,debdata,val=[]

let ppaymennts,ppaymennts2,jvtype=6


// const Checkbox = props => (
//   <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" {...props} />
// )
class DtaxesPayable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            data:this.props.location.state,
            pendingdata:[],
            pen:0,sur:0,
            partyid:null,poid:null,pmtid:null,total:'',
            tdsreq:[{ id: 0, name: "No" },{ id: 1, name: "Yes" }],
            debselval:'',pmtchecked:'',pnpchecked:'',tcschecked:0,selectedFiles: undefined,serviceamount:null,serviceledger:"",
            currentFile: undefined,progress: 0,message: "",isError: false,fileInfos: [],
            fileInfo:"",pendingPayments:[],selectedPP:[],selectedPP2:[],selectedPPO:[],selectedPPO2:[],
            selchk:[],selchk1:[],selamt:[],selamt2:[],displaychecked:false,prevpmtschecked:false,genpayment:[],
            totalcombdata:[],SDdate:false,fed:'',jvinsertiontype:14,
            loader:false,taxpending:null,loader1:true
             
          }
        this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
        this.loadData=this.loadData.bind(this)
        this.AddData=this.AddData.bind(this)
    }

    onDebBlur(values){
      this.setState({debselval:values.debit})
      if(values.debit!==undefined && values.debit.length!==0){
        this.setState({partyid:values.debit.id})
      }
    }

    onSubmit(values){
      this.setState({loader:true})
     // let mode,bank
    //  let tcsname,tcsvalue,tcspercentage,tcsamt//,advledger=null,tcsid,
      // if(values.credit.addltype===0){
      //   mode=0
      // }else if (values.credit.addltype===1){
      //   mode=1
      //   bank=values.credit.name
      // }
      // if(this.state.tcschecked===1){
      //   tcsid=values.tcs.id
      //   tcsname=values.tcs.name
      //   tcsvalue=values.tcsval
      //   tcspercentage=values.tcspercent
      //   tcsamt=values.tcstotal
      //  }

      let surchargeid,surchargename,surval
      let penaltyid,penaltyname,penval
      if(values.surcharge!==undefined){
        surchargeid=values.surcharge.id
        surchargename=values.surcharge.name
        surval=values.surval
        
      }
      if(values.penalty!==undefined){
        penaltyid=values.penalty.id
        penaltyname=values.penalty.name
        penval=values.penval
      
      }
      let jv = {
        debitid: values.debit.id,debitname: values.debit.name,debitamt: values.debitval,
        surchargeid:surchargeid, surchargename:surchargename, surval:surval,
        penaltyid:penaltyid, penaltyname:penaltyname, penval:penval,
        creditid: values.credit.id,creditname: values.credit.name,creditamt: values.creditvalue,
        narration: values.narration,jvtype:jvtype,createdBy: userid,tenantId: currentTenant,paymentref:values.paymentref,
        pendingdata:this.state.pendingdata,frompage:this.props.location.pathname,jvinsertiontype:this.state.jvinsertiontype,

        date:this.state.fed!==''?this.state.fed:null
      }
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      } 
      else {
        this.props.navigate({pathname:`/journalconfirm`}, {state:jv});
      }
    }
    handleSDDate=()=>{
      this.setState({SDdate:this.state.SDdate===true?false:true})
    }
    handleFsdDateChange = (date) => {
      if(date!==null){
      this.setState({ fsd: date });
      this.setState({
        fed: moment(date.$d).format("yyyy-MM-DD HH:mm:ss"),
      });
    }else{
      this.setState({ SDdate:false})
    }
    };
    componentDidMount(){
      this.loadData()
    
      if(this.state.totalcombdata.length===0){
        this.loadData()
        // this.AddData()
        this.setState({loading:true})
       }else{
        this.setState({loading:false})
       }
     
      this.setState({loading:false})
       }

    loadData(){
      NewJournalService.getAllLedgers(currentTenant)
      .then(response=>{
        this.setState({totalcombdata:response.data},()=>this.AddData())
        // totalcombdata=response.data
       })
       .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
      })

    }

    AddData(){

      let ledid//,cred1 credvalue
      this.state.pendingdata.map(e=>{return ledid=e.itemid})
      // debdata=val.filter((e)=> e.id===ledid)
      debdata=val.filter((e)=> e.taxtype===1)

      
     // cred1=val.filter((e) => e.type === 2)
     // credvalue=cred1.filter((e)=>e.sh===100004 || e.sh===100003)
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      AuthenticationService.logout(currentUser);
    }
    
    this.setState({pendingdata:this.state.data.pendingdtaxes,total:this.state.data.total},()=>
    // pendingdata=this.state.data.pendingdtaxes
   // totalpending=[...new Set(this.state.pendingdata.map((e) => { return e.totaltaxamount})),];
   pmtid=this.state.pendingdata.map((e)=>e.id),
   this.setState({selamt:this.state.pendingdata.map((e)=>e.transpendingamount),pmtid:pmtid}))
      
    let p,s
  this.state.pendingdata.map((e)=>{return p=e.penalty})
  this.state.pendingdata.map((e)=>{return s=e.surcharge})
  this.setState({pen:p,sur:s})

    // console.log(this.state.pendingdata)

      if(totalcombdata!==undefined){
        val=totalcombdata 
        this.setState({loader1:false})
      }else(
        this.loadData()
      )
      let a=[]
       a= this.state.pendingdata.map((e)=>parseFloat(e.totaltaxamount))
      this.setState({taxpending:getTotal(a)})
    }
     validate(values){
      let errors = {}
      //,type = totalcombdata

       if (!values.debit || values.debit==='') {errors.debit = "Accounts is required";}
       if(this.state.sur!==undefined){
       if (!values.surcharge || values.surcharge==='') {errors.surcharge = "Accounts is required";}}
       if(this.state.pen!==undefined){
        if (!values.penalty || values.penalty==='') {errors.penalty = "Accounts is required";}}

      //  if(!values.debitval){errors.debitval="Value Cannot be Blank"}else if(deb+this.state.sur+this.state.pen!==cre){
      //   errors.creditvalue="Value Cannot be be different from debit Value"}
       if (!values.credit || values.credit==='') {errors.credit = "Accounts is required"}
      //  if(!values.creditvalue){errors.creditvalue="Value Cannot be Blank"}else if(deb+this.state.sur+this.state.pen!==cre){
      //   errors.creditvalue="Value Cannot be be different from debit Value"}
       if (!values.narration) {errors.narration = "Narration is required";}
       if (!values.paymentref) {errors.paymentref = "Payment Reference is required";}return errors;
     }
     onTcsChange(option){this.setState({ tcschecked: option.id });}

   

  handleCheckboxChange= event =>{
    this.setState({ displaychecked: event.target.checked })
    let a 
    this.state.pendingdata.map((e)=>{return a=e.pitems})
    this.setState({selchk:a}) 
    selchk=a

 }

    render() {
      if(this.state.totalcombdata.length!==0){
        val=this.state.totalcombdata 
      }
      let cred1 //totamt
      const {isError,message} = this.state;
      let credvalue,selpp,selpp2
     // entrytype=1
      // if(poid!==null && advpoamt===0 ){
      //   debdata=null
      //   let ledid
      //   this.state.pendingdata.map(e=>{return ledid=e.itemid})
      //   debdata=val.filter((e)=> e.id===ledid)
      
      // }else if(this.state.poid===null){
        debdata=null
        let ledid
        this.state.pendingdata.map(e=>{return ledid=e.itemid})
        debdata=val.filter((e)=> e.id===ledid)
      // }
    
      
      // cred1=val.filter((e) => e.type === 2)
      credvalue=val.filter((e)=>e.shid===100004 || e.shid===100003 || e.shid===100013 || e.shid===100014)
      
      
         
// -------------------NEW ----------------

//this.state.pendingdata.map((e)=>{return this.state.serviceamount=e.serviceamount})
//this.state.pendingdata.map((e)=>{return this.state.serviceledger=e.servledger})
// totamt=this.state.selamt.map(e=>e)
//this.state.pendingdata.map((e)=>{return this.state.pen=e.penalty})
//this.state.pendingdata.map((e)=>{return this.state.sur=e.surcharge})
//this.state.pendingdata.map((e)=>{return taxpending=e.totaltaxamount})
let otherled
otherled=val.filter((e)=>e.th==="TH2")


        return (
         <>
          <Header />
<Menu/>
            <div>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className="content-wrapper">

<div className="card card-info">

  <ol className="breadcrumb float-sm-right">
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/dtaxes'}} state= {{message:''}} >
                Taxes Payable
              </Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/dte'}} state= {{message:''}} >
                Taxes Payment Entry
              </Link>
    </li>

  </ol>

  <div className="text-center text-danger">{this.state.error}</div>
  <div className="card-body">
  <div className="text-center">
  {this.state.loading===true?spin:''}
  </div>
              
        
    <Formik
      initialValues={{ debdata,credvalue,narration:'',selpp,selpp2,ppaymennts,ppaymennts2,selchk,
      debitval:"",surcharge:"",surval:"",penalty:"",penval:"",creditvalue:"",paymentref:""}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({ values,setFieldValue}) => (

        <Form autoComplete="off">




<div className="form-row mt-0 mb-0 text-sm sm">

  <fieldset className="form-group col-md-12">
 {/* {this.state.selectedPPO.length!==0 || this.state.selchk.length!==0? */}
 

<Table className="table-sm" aria-label="customized table">
  <TableHead>
    <TableRow>
    <TableCell className="col-md-2 text-sm">Item Name</TableCell>
 <TableCell className="col-md-2 text-sm">For</TableCell>
  <TableCell className="col-md-2 text-sm">Towards</TableCell>
 <TableCell className="col-md-2 text-sm">Date</TableCell>
 <TableCell className="col-md-2 text-sm">Pan</TableCell>
 <TableCell className="col-md-2 text-sm">Pending Amt</TableCell>
 <TableCell className="col-md-1 text-sm">Surcharge</TableCell>
 <TableCell className="col-md-1 text-sm">Penalty</TableCell>
 <TableCell className="col-md-1 text-sm">Total</TableCell>
  </TableRow>
    </TableHead>
  
   <TableBody>
   {this.state.pendingdata.map((e,i)=>
  <TableRow key={i}>
 <TableCell  className="col-md-2 text-sm">{e.itemname}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.ledgername}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.ieledger}</TableCell>
  <TableCell className="col-md-2 text-sm">{moment(e.createdAt).format('DD-MM-YYYY HH:mm')}</TableCell>
  <TableCell className="col-md-2 text-sm">{e.pan}</TableCell>
  <TableCell  className="col-md-1 text-right">
  <NumericFormat className="align-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.totaltaxamount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
  </TableCell>
  <TableCell className="form-group col-md-1 text-sm">{e.surcharge}</TableCell>
  <TableCell className="form-group col-md-1 text-sm">{e.penalty}</TableCell>

  <TableCell className="form-group col-md-1 text-sm text-right">
  <NumericFormat className="text-right" displayType={'text'} decimalScale={2} fixedDecimalScale={true}  disabled ={true} value = {e.totalamount}
   thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
  </TableCell>
  </TableRow>)}
</TableBody></Table>

</fieldset>



  </div>



 <div className="form-row" > 

 <div className="form-row col-md-12 text-left form-switch mt-3">
 <div className="col-md-3 text-left">
  <FormControlLabel
   control={<IOSSwitch className="sm" checked={this.state.SDdate} onChange={this.handleSDDate} name="checkedB" />}/>
     <span className="text-left textColour">{this.state.SDdate===false?"System Date":"Custom Date"}</span>
       </div>
    {this.state.SDdate===true?
     <div className="col-md-3 text-left">
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Grid  justifyContent="space-around">
    <DateTimePicker disableFuture showTodayButton showCancelButton variant="standard" 
    id="date-picker-dialog" inputVariant="standard"
    minDate={new Date(moment(transactionFrom).format("yyyy-MM-DD"))}
    maxDate={new Date(moment(financialyearend).format("yyyy-MM-DD"))}
    format="dd/MM/yyyy HH:mm" value={this.state.fsd}
    // label="Custom Date" 
    InputAdornmentProps={{position: "start"}} onChange={this.handleFsdDateChange}
    renderInput={(params) => <TextField {...params} />}autoOk={true}/>
    </Grid></LocalizationProvider></div>
     :""}
    </div>



 <fieldset  className="form-group col-md-7 sm mt-0 mb-0"> 
  <Autocomplete id="debit" className="debit-select text-sm sm" name="adebit" options={debdata.sort((a, b) => -a.subname.localeCompare(b.subname))}
  getOptionLabel={(option) => option.name }  groupBy={(option) => option.subname}
  value={values.debit?undefined:undefined} 
  onChange={(e, value) => {setFieldValue("debit",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="debit" label="Debit" className="text-sm"
  onBlur={()=>setFieldValue("debitval",this.state.taxpending)  
   && this.onDebBlur(values)} 
   variant="standard" fullWidth />)}/></fieldset>


  <fieldset  className="form-group col-md-5"> 
  <FormikTextField name="debitval"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.debitval?" ":"Value"}  type="number" 
  variant="standard"> </FormikTextField></fieldset>
  </div>     
{this.state.sur!==undefined?
  <div className="form-row" > 
 <fieldset  className="form-group col-md-7 sm mt-0 mb-0"> 
  <Autocomplete id="surcharge" className="debit-select text-sm sm" name="adebit" options={otherled.sort((a, b) => -a.subname.localeCompare(b.subname))}
  getOptionLabel={(option) => option.name }  groupBy={(option) => option.subname}
  value={values.debit?undefined:undefined} 
  onChange={(e, value) => {setFieldValue("surcharge",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="surcharge" label="Surcharge" className="text-sm"
  onBlur={()=>setFieldValue("surval",this.state.sur)  
   && this.onDebBlur(values)} 
   variant="standard" fullWidth />)}/></fieldset>
     
  <fieldset  className="form-group col-md-5"> 
  <FormikTextField name="surval"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.surval?" ":"Value"}  type="number" 
  variant="standard"> </FormikTextField></fieldset>
  </div> :""}
  
  {this.state.pen!==undefined?
  <div className="form-row" > 
 <fieldset  className="form-group col-md-7 sm mt-0 mb-0"> 
  <Autocomplete id="penalty" className="debit-select text-sm sm" name="adebit" options={!otherled?this.loadData():otherled.sort((a, b) => -a.subname.localeCompare(b.subname))}
  getOptionLabel={(option) => option.name }  groupBy={(option) => option.subname}
  value={values.debit?undefined:undefined} 
  onChange={(e, value) => {setFieldValue("penalty",value)}} renderInput={params => (
  <FormikTextField {...params}  type="text" name="penalty" label="Penalty" className="text-sm"
  onBlur={()=>setFieldValue("penval",this.state.pen)  
   && this.onDebBlur(values)} 
   variant="standard" fullWidth />)}/></fieldset>
     
  <fieldset  className="form-group col-md-5"> 
  <FormikTextField name="penval"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.penval?" ":"Value"}  type="number" 
  variant="standard"> </FormikTextField></fieldset>
  </div> :""}

<div className="form-row mt-0 mb-0"> 
<fieldset  className="form-group col-md-1 mt-0 mb-0"> </fieldset>
<fieldset  className="form-group col-md-6 mt-0 mb-4"> 
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" options={!credvalue?this.loadData():credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
getOptionLabel={(option) => option.name +" ("+option.amount+")"} groupBy={(option) => option.subname}
 value={values.credit?undefined:undefined} onChange={(e, value) => { setFieldValue("credit",value);}}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" onBlur={()=>setFieldValue("creditvalue",this.state.total)}
  variant="standard" fullWidth />)}/>
</fieldset>
 <fieldset  className="form-group col-md-5" disabled>   
 <NumericFormat  name="creditvalue" className="form-control text-sm mt-0 mb-0" value={values.debitval} 
 label={values.debitval?" ":"Value"} displayType={'input'} customInput={FormikTextField} decimalScale={2} 
 fixedDecimalScale={true}  thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
 </fieldset>
 </div>


 <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/></fieldset></div>

  <div className="form-row mb-5 mt-0 mb-0"> <fieldset className="form-group col-md-12">
  <FormikTextField name="narration" className="form-control" multiline rows={2} label="Narration"
  variant="outlined"/></fieldset></div>
  {/* </>:""} */}


<div className="form-group col-md-9"> 
<Typography variant="subtitle2" className={`upload-message ${isError ? "error" : ""}`}>
<span className="text-green">{message}</span></Typography></div>

<fieldset className="form-group col-md-12">
 <button className="btn btn-info btn-sm" type="submit"> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Submit"}</button></fieldset>
</Form> )}
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     

 </div>
 </>
)
}
}

export default withRouter(DtaxesPayable);              



 
