import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import {FormControlLabel,Checkbox,FormGroup ,InputAdornment,Grid} from "@mui/material";
import Employeeservices from "./Employeeservices.js";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import { Autocomplete } from "@mui/material";
import { currentTenant, userid } from "../../Common/Credentials.js";
import { Spinner } from "react-bootstrap";
import DepartmentServices from "../../Masters/Department/DepartmentServices.js"
import WorkCenterServices from "../../Masters/WorkCenter/WorkCenterServices.js";
import SupplierService from "../Suppliers/SupplierService.js";
import UserService from "../../users/UserService.js";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";


class Newemployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
        name: this.props.location.state.name,
        id: this.props.location.state.id,
        employeetype:this.props.location.state.employeetype,
        first: "",
        last: "",
        username: "",
        contactno:'',
        email: "",
        password: "",
        active: "",
        address:'',
        pan:'',
        idno:'',
        zip:'',
        CountryData:[],
        StateData:[],
        CityData:[],
        StateId: "",
        state:'',
        CountryId: "",
        country:'',
        city:'',
        CityId: "",
        dlno:'',
        show: false,
        uperror: false,
        loader: false,
        errormsg:'',
        login:false,
        userid:'',
        user:'',
        usersList:[],
        doj:new Date(),
        pfno:'',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleClose = () => {
    this.setState({ show: false });
  };
  

  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));

    handleWarehouse = (e,values) => {
      if(typeof values !== 'object' || values === undefined ||values ==='' || values===null ){
        this.setState({ warehouse: '',whid:'', workcenter: '',wcid:'',department:'',deptid:''  });
      }else{  
      this.setState({ warehouse: values.name,whid:values.id, workcenter: '',wcid:'',department:'',deptid:''  });
      const id = values.id;
      DepartmentServices.deptbywarehouse(id).then((response) =>
        this.setState({
          departmentList: response.data
        })
      )
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    
    WorkCenterServices.WorkCenterbywarehouse(id).then((response) => {
      this.setState({ workcenterList: response.data });
    })
      }
    };

    handleSubChange = (e) =>{
      this.setState({subtype:e.target.value})
    }

    
  handleChange = (pro, value) => {
    let productid = pro.id;
    let permissions = value;
    this.setState({permissionlist:this.state.permissionlist.filter(
      (e) => e.productid !== productid
    )},()=> this.state.permissionlist.push({
      productid: productid,
      permissionenum: permissions,
    })
    )
  };

  ChangeUser = (value,values)=>{
    if(values!=='' && typeof values === 'object' && values!==null){
        this.setState({user:values.first +" "+ values.last,userid:values.id})
    }else{
        this.setState({user:'',userid:''})
    }
  }

  
  ChangeteCountry = (value, values) => {
    if(values!=='' && typeof values === 'object' && values!==null){
    this.setState({country:values.name, CountryId: values.id,phonecode:values.phonecode,state :'', StateId: '',city:'',CityId:'',CityData:[] });
    SupplierService.getStates(values.id).then((response) => {
      this.setState({
        StateData: response.data,
      });
    });
  }else{
    this.setState({
      country:'', CountryId:'', state :'',StateData:[],CityData:[], StateId: '',city:'',CityId:''
    })
  }
  };
  ChangeState = (value, values) => {
    if(values!=='' && typeof values === 'object' && values !== null){
    this.setState({state:values.name, StateId: values.id });
    SupplierService.getCities(values.id).then((response) => {
      this.setState({
        CityData: response.data,city:'',CityId:''
      });
    });
   }else{
     this.setState({
    state:'', StateId: '',city:'',CityId:''
     })
   }
  };

  handleCity=(value,values)=>{
    if(values!=='' && typeof values === 'object' && values !== null){
    this.setState({city: values.name,CityId: values.id})
  }else{
    this.setState({city:'',CityId:''})
  }
  }

  onSubmit(values) {
    // let username = AuthenticationService.getLoggedInUser()
    this.setState({ loader: true });
    let newusers = {
        employeetype:this.state.employeetype,
        first:this.state.first,
        last:this.state.last,
        contactnumber:this.state.contactno,
        email:this.state.email,
        address:this.state.address,
        pan:this.state.pan,
        idno:this.state.idno,
        zip:this.state.zip,
        country:this.state.CountryId,
        state:this.state.StateId,
        city:this.state.CityId,
        countryname:this.state.country,
        statename:this.state.state,
        cityname:this.state.city,
        dlno:this.state.dlno,
        login:this.state.login,
        phonecode:this.state.phonecode,
        createdby:userid,
        userid:this.state.userid,
        tenantId:currentTenant,
        doj:this.state.doj,
        pfno:this.state.pfno,
    };
    let updateusers = {
        id:this.state.id,
        employeetype:this.state.employeetype,
        first:this.state.first,
        last:this.state.last,
        contactnumber:this.state.contactno,
        email:this.state.email,
        address:this.state.address,
        pan:this.state.pan,
        idno:this.state.idno,
        zip:this.state.zip,
        country:this.state.CountryId,
        state:this.state.StateId,
        city:this.state.CityId,
        countryname:this.state.country,
        statename:this.state.state,
        cityname:this.state.city,
        dlno:this.state.dlno,
        login:this.state.login,
        phonecode:this.state.phonecode,
        userid:this.state.userid,
        tenantId:currentTenant,
        createdby:userid,
        updatedby:userid,
        doj:this.state.doj,
        pfno:this.state.pfno,
    };
    if (this.state.id < 1) {
      Employeeservices.AddEmployee(newusers).then(() =>
        this.props.navigate({pathname:`/employees`},{state:{message:'',employeetype:this.state.employeetype}})
      )
      .catch((e)=>{
        this.setState({loader:false,errormsg:e.response.data.message})
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    } else {
      Employeeservices.UpdateEmployee(updateusers).then(() =>
        this.props.navigate({pathname:`/employees`},{state:{message:'',employeetype:this.state.employeetype}})
      )
      .catch((e)=>{
        this.setState({loader:false,errormsg:e.response.data.message})
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    }
  }

  componentDidMount() {
    const id = this.props.location.state.id;
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    SupplierService.getCountries().then((response) =>
      this.setState({
        CountryData: response.data,
      })
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
    })

    UserService.retriveAllUsers(currentTenant).then(res=>{
        this.setState({usersList:res.data})
    })

    if(id !==-1){
        Employeeservices.getEmployeeById(id).then(res=>{
            this.setState({
                id:res.data.id,
                employeetype:res.data.employeetype,
                first:res.data.first,
                last:res.data.last,
                contactno:res.data.contactnumber,
                email:res.data.email,
                address:res.data.address,
                pan:res.data.pan,
                idno:res.data.idno,
                zip:res.data.zip,
                CountryId:res.data.country,
                StateId:res.data.state,
                CityId:res.data.city,
                country:res.data.countryname,
                state:res.data.statename,
                city:res.data.cityname,
                dlno:res.data.dlno,
                login:res.data.login,
                userid:res.data.userid,
                phonecode:res.data.phonecode,
                doj:res.data.doj,
                pfno:res.data.pfno
            })
        })
    }
 
  }

  validate(values) {
    let errors = {};
    // var chars = /^[A-Za-z ]+$/;
    var num = /^[0-9]+$/;

    if (!values.first) {
      errors.first = "First Name is required";
    }
    // if(this.state.employeetype===0){
    //     if (!values.email) {
    //         errors.email = "Email is required";
    //     }
    // }
    if (!values.last) {
      errors.last = "Last Name is required";
    }

    if (this.state.contactno===''|| this.state.contactno===undefined) {
        errors.contactno = "Contact number is required";
      }

    // if (!values.email) {
    //     errors.email = "Email is required";
    //   } else if (
    //     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    //   ) {
    //     errors.email = "Invalid email address";
    //   }
      if (!values.address) {
        errors.address = "Address is required";
      }
  

    if(this.state.country ==='India'){
      if (!values.zip) {
        errors.zip = "Zip is required";
      } else if (!num.test(values.zip)) {
        errors.zip = "Only Numbers allowed";
      } else if (!/^[0-9]{5,6}$/i.test(values.zip)) {
        errors.zip = "Should be 5 or 6 Numbers";
      }
    }else{
      if (!values.zip|| this.state.zip === '') {
        errors.zip = "PO Box No. is required";
      }
    }
      if (this.state.CountryId === "") {
        errors.country = "Please Select Country";
      }
      if (this.state.StateId === "") {
        errors.state = "Please Select State";
      }
      if (this.state.CityId === "") {
        errors.city = "Please Select City";
      }

      if (this.state.idno === "") {
        errors.idno = "Please Enter Id number";
      }

      if(this.state.employeetype===1){
        if (this.state.dlno === "") {
            errors.dlno = "Please enter driving licence number";
          }
      }
      if(this.state.login===true){
        if(this.state.userid===''|| this.state.userid===undefined){
            errors.user="Please select user"
        }
      }

    return errors;
  }

  render() {
    let {
        id,
        employeetype,
        first,
        last,
        contactno,
        username,
        email,
        password,
        active,
        address,
        pan,
        idno,
        zip,
        CountryData,
        StateData,
        CityData,
        country,
        state,
        city,
        show,
        dlno,
        login,
        user,
        userid,
        doj,
        pfno,
    } = this.state;
    return (
      <div>
      <Header/>
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            
            <ol className="breadcrumb float-sm-right">
            <li className="text-secondary">
            <Link as="button" className=" text-secondary" to={{ pathname: "/employees"}} state= {{ message: "",employeetype:this.state.employeetype}}>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to={{pathname: "/employees"}} state ={ { message: "",employeetype:this.state.employeetype }}>Employees</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.name}</li>
            </ol>
            <div className="text-center text-danger">{this.state.errormsg}</div>

            <div className="card-body">
              <Formik
                initialValues={{
                  id,
                  employeetype,
                  first,
                  last,
                  username,
                  contactno,
                  email,
                  password,
                  active,
                  address,
                  pan,
                  idno,
                  zip,
                  CountryData,
                  StateData,
                  CityData,
                  country,
                  state,
                  city,
                  show,
                  dlno,
                  login,
                  user,
                  userid,
                  doj,
                  pfno
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ props, setFieldValue, values }) => (
                  <Form >
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="id"
                          placeholder="Id"
                        />
                      </fieldset>
                      {/* <fieldset className={`form-group col-md-3  shadow-sm text-center btn ${this.state.employeetype === 0?'borderedbutton2':' hovbuttonColour'}`} onClick={()=>this.setState({employeetype:0})} disabled={this.state.employeetype===0}>
                        Employee 
                      </fieldset>
                      <fieldset className={`form-group col-md-3 shadow-sm btn text-center ${this.state.employeetype === 1?'borderedbutton2':' hovbuttonColour'}`} onClick={()=>this.setState({employeetype:1})} disabled={this.state.employeetype===1}>
                        Driver
                      </fieldset> */}
                      <fieldset className="form-group col-md-12 m-0"/>
                      <fieldset className="form-group col-md-3 pb-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="first"
                          label="First Name"
                          value={this.state.first}
                          onChange={(e) =>
                            this.setState({ first: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3 pb-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="last"
                          label="Last Name"
                          value={this.state.last}
                          onChange={(e) =>
                            this.setState({ last: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3 pb-3">
                        <FormikTextField
                            className="form-control"
                            type="text"
                            name="email"
                            label="Email"
                            value={this.state.email}
                            onChange={(e) => this.setState({ email: e.target.value,errormsg:'' })}
                        />
                      </fieldset>
                          
                      <fieldset className="form-group col-md-3 pb-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="address"
                          label="Address"
                          value={this.state.address}
                          onChange={(e) =>
                            this.setState({ address: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="country"
                          options={CountryData}
                          getOptionLabel={(option) => option.name}
                          value={values.country ? undefined : undefined}
                          onChange={this.ChangeteCountry}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="country"
                              value={this.state.country}
                              label={`Select Country (${this.state.country})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="country"
                          options={StateData}
                          getOptionLabel={(option) => option.name}                          
                          value={values.state ? undefined : undefined}
                          onChange={this.ChangeState}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="state"
                              value={this.state.state}
                              label={`Select State (${this.state.state})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3 pb-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="city"
                          options={CityData}
                          getOptionLabel={(option) => option.name}
                          value={values.city ? undefined : undefined}
                          onChange={this.handleCity}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name='city'
                              value={this.state.city}
                              label={`Select City (${this.state.city})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3 pb-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="zip"
                          label={this.state.country==="India"?"Zip/Pincode":"PO BOX No."}
                          value={this.state.zip}
                          onChange={(e) =>
                            this.setState({ zip: e.target.value })
                          }
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-3 pb-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="contactno"
                          label="Contact Number"
                          value={this.state.contactno}
                          onChange={(e) =>
                            this.setState({ contactno: e.target.value.replace(/[^0-9]+/ig, "")})
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +{this.state.phonecode}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-3 pb-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="idno"
                          label={`${this.state.country==="India"?"Adhar/Id":"ID card no"}`}
                          value={this.state.idno}
                          onChange={(e) =>
                            this.setState({ idno: e.target.value })
                          }
                        />
                      </fieldset>
                      {this.state.country==="India"?
                      <>
                      <fieldset className="form-group col-md-3 pb-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="pan"
                          label="PAN"
                          value={this.state.pan}
                          onChange={(e) =>
                            this.setState({ pan: e.target.value })
                          }
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </fieldset>
                      
                      </>:''}

                      <fieldset className="form-group col-md-3 pb-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="pfno"
                          label="Employee Provident Fund No"
                          value={this.state.pfno}
                          onChange={(e) =>
                            this.setState({ pfno: e.target.value })
                          }
                          fullWidth
                        />
                      </fieldset>

                      <fieldset className="col-md-3">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container justifyContent="space-around">
                              <DatePicker
                                variant="inline"
                                id="date-picker-dialog"
                                label="Select Date of joining"
                                maxDate={new Date()}
                                value={this.state.doj}
                                format="dd/MM/yyyy"
                                onChange={(d)=>this.setState({ doj: d })}
                                renderInput={(params) => (
                                  <FormikTextField {...params} name="doj" variant="standard" />
                                )}
                              />
                            </Grid>
                          </LocalizationProvider>
                        </fieldset>

                     
                      {this.state.employeetype === 1?
                      <fieldset className="form-group col-md-3 pb-3">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="dlno"
                          label="Driving licence"
                          value={this.state.dlno}
                          onChange={(e) =>
                            this.setState({ dlno: e.target.value })
                          }
                        />
                      </fieldset>:<>
                      <fieldset className="form-group col-md-12 m-0"></fieldset>
                      <fieldset className="form-group col-md-3 mt-2 m-0">
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={this.state.login}
                              onChange={()=>this.setState({login:!this.state.login})}
                              name="login"
                              color="primary"
                            />
                          }
                          label="Enable Login?"
                        />
                      </FormGroup>
                    </fieldset>
                    {this.state.login===true?
                    <fieldset className="form-group col-md-3">
                    <Autocomplete
                      id="subaccount"
                      className="subacc-select"
                      name="user"
                      options={this.state.usersList}
                      getOptionLabel={(option) => (`${option.first} ${option.last}`)}
                      value={values.user ? undefined : undefined}
                      onChange={this.ChangeUser}
                      renderInput={(params) => (
                        <FormikTextField
                          {...params}
                          className="form-control"
                          type="text"
                          name="user"
                          value={this.state.user}
                          label={`Select User (${this.state.user})`}
                          variant="standard"
                          fullWidth
                        />
                      )}
                    />
                  </fieldset>:''}
                  </>  }
                    </div>
                    <fieldset className="form-group col-md-12" />
                    {this.state.loader === true ? (
                        <button className="btn btn-sm hovbuttonColour" disabled >
                          <Spinner animation="border" size="sm" />
                          &nbsp;{"Saving"}
                          </button>
                      ) : (
                        <button className="btn btn-sm hovbuttonColour" type="submit"><i className="fa fa-save"/> Save</button>
                      )
                    }
                    <Link
                      as="button"
                      className="btn btn-sm deletebutton ml-1"
                      to={{ pathname: "/employees"}} state={{ message: "",employeetype:this.state.employeetype }}
                    >
                      <b className="text-danger"> X </b>Close
                    </Link>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

        <Footer />
      </div>
    );
  }
}
export default withRouter(Newemployee);
