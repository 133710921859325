import React, { useEffect, useState } from "react";
import withRouter from "../../withRouter";
import Menu from "../../Component/Menu";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { Link, useNavigate } from "react-router-dom";
import { FormikTextField } from "../../CommonComponents/FormField";
import { Form, Formik } from "formik";
import { Autocomplete } from "@mui/material";
import SalesServices from "../../Sales/SalesServices";
import { accounts } from "../../Common/Products";
import Swal from "sweetalert2";
import { currentTenant, userid } from "../../Common/Credentials";
import UserService from "../../users/UserService";
import CustomerService from "../../Masters/Customers/CustomerService";
import ProjectManagementServices from "../ProjectManagementServices";
import { Spinner } from "react-bootstrap";

const ProjectsCreation = () => {
  const navigate = useNavigate()
  const [loader, setLoader] = React.useState(false);
  const [projectId, setProjectId] = useState("");
  const [loading, setLoading] = useState(false);
  const [projectName, setprojectName] = useState("");
  const [projectCode, setprojectCode] = useState("");
  
  const [customersList, setCustomersList] = useState([]);
  const [customer, setCustomer] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [customerCompanyName, setCustomerCompanyName] = useState("");
  const [customerContactNo, setCustomerContactNo] = useState("");
  const [customerEmailId, setCustomerEmailId] = useState("");
  const [userList, setUserList] = useState([]);
  const [projectManagerId, setProjectManagerId] = useState("");
  const [projectManagerName, setProjectManagerName] = useState("");
  const [projectManagerContactNo, setProjectManagerContactNo] = useState("");
  const [projectManagerEmail, setProjectManagerEmail] = useState("");
  const [siteInchargeId, setSiteInchargeId] = useState("");
  const [siteInchargeName, setSiteInchargeName] = useState("");
  const [siteInchargeContactNo, setSiteInchargeContactNo] = useState("");
  const [siteInchargeEmail, setSiteInchargeEmail] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryId, setCountryId] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [state, setState] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [citiesList, setCitiesList] = useState([]);
  const [city, setCity] = useState([]);
  const [cityId, setCityId] = useState([]);
  const [remark, setRemark] = useState("");

  useEffect(() => {
    SalesServices.retriveAllCustomers(currentTenant).then((res) =>
      setCustomersList(res.data)
    );
    UserService.retriveAllUsers(currentTenant).then((response) =>
      setUserList(response.data)
    );
    CustomerService.getCountries().then((response) =>
      setCountryList(response.data)
    );
  }, []);

  const handleCustomer = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      if (values.ledgerid === null || values.ledgerid === undefined) {
        if (accounts === "YES") {
          Swal.fire({
            text: `Ledger account is not created for Customer ${values.companyname}, so no payment or receipt can be done`,
            icon: "warning",
            confirmButtonColor: "#152f5f",
          });
        }
      }
      setCustomer(values.companyname);
      setCustomerId(values.id);
      setCustomerContactNo(values.contactnumber);
      setCustomerEmailId(values.email);
    } else {
      setCustomer("");
      setCustomerId("");
    }
  };

  const handleProjectManagerChange = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      setProjectManagerId(values.id);
      setProjectManagerName(values.first + " " + values.last);
      setProjectManagerContactNo(values.contactnumber);
      setProjectManagerEmail(values.email);
    } else {
    }
  };

  const handlesiteInchargeChange = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      setSiteInchargeId(values.id);
      setSiteInchargeName(values.first + " " + values.last);
      setSiteInchargeContactNo(values.contactnumber);
      setSiteInchargeEmail(values.email);
    } else {
    }
  };

  const handleCountryChange = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      setCountry(values.name);
      setCountryId(values.id);
      setStateList(values.states);
    } else {
      setCountry("");
      setCountryId("");
      setStateList([]);
    }
  };

  const handleStateChange = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      setState(values.name);
      setStateId(values.id);
      setCitiesList(values.cities);
    } else {
      setState("");
      setStateId("");
      setCitiesList([]);
    }
  };

  const handleCityChange = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      setCity(values.name);
      setCityId(values.id);
    } else {
      setCity("");
      setCityId("");
    }
  };

  const onSubmit = () => {
    let data = {
      projectName: projectName,
      projectCode:projectCode,
      // siteName: siteName,
      // customerId: customerId,
      // customerName: customer,
      // customerCompanyName: customerCompanyName,
      // customerContactNo: customerContactNo,
      // customerEmailId: customerEmailId,
      // projectManagerId: projectManagerId,
      // projectManagerName: projectManagerName,
      // projectManagerContactNo: projectManagerContactNo,
      // projectManagerEmail: projectManagerEmail,
      // siteInchargeId: siteInchargeId,
      // siteInchargeName: siteInchargeName,
      // siteInchargeContactNo: siteInchargeContactNo,
      // siteInchargeEmail: siteInchargeEmail,
      // cityId: cityId,
      // city: city,
      // stateId: stateId,
      // state: state,
      // countryId: countryId,
      // country: country,
      tenantId: currentTenant,
      remark: remark,
      createdby: userid,
      updatedby: userid,
    };

    ProjectManagementServices.NewProject(data)
    .then(() => navigate(-1))
    .catch(()=>setLoader(false))
  };

  const validate = () => {
    let errors = {};
    if (projectName === "") {
      errors.projectName = "Please Select projectName";
    }

    if (projectCode === "") {
      errors.projectCode = "Please Select projectCode";
    }

    return errors;
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
          <Link as="button" className=" text-secondary" to={-1}>
              ❮ Back &nbsp;&nbsp;
            </Link>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projectmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projects">Projects</Link>
            </li>
            <li className="breadcrumb-item active">New Projects</li>
          </ol>
          <section className="content">
            <div className="container-fluid p-3">
              <div className="row">
                <div className="col-12">
                  <Formik
                    initialValues={{}}
                    onSubmit={onSubmit}
                    validateOnChange={false}
                    validate={validate}
                    enableReinitialize={true}
                  >
                    {({ setFieldValue, values }) => (
                      <Form>
                        <div className="form-row">
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="projectName"
                              label="Project Name"
                              value={projectName}
                              onChange={(e) => setprojectName(e.target.value)}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="projectCode"
                              label="Project Code"
                              value={projectCode}
                              onChange={(e) => setprojectCode(e.target.value)}
                            />
                          </fieldset>
                          {/* 
                          <fieldset className="form-group col-md-4">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="customer"
                              options={customersList}
                              getOptionLabel={(option) => option.companyname}
                              value={values.customer ? undefined : undefined}
                              onChange={handleCustomer}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="customer"
                                  value={customer}
                                  label={`${
                                    customer === "" ||
                                    customer === undefined ||
                                    customer === null
                                      ? "Select Customer"
                                      : customer
                                  }`}
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="customerCompanyName"
                              label="Customer Company Name"
                              value={customerCompanyName}
                              onChange={(e) =>
                                setCustomerCompanyName(e.target.value)
                              }
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="customerContactNo"
                              label="Customer Contact No."
                              value={customerContactNo}
                              onChange={(e) =>
                                setCustomerContactNo(e.target.value)
                              }
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="customerEmailId"
                              label="Customer Email"
                              value={customerEmailId}
                              onChange={(e) =>
                                setCustomerEmailId(e.target.value)
                              }
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="projectManagerName"
                              options={userList}
                              getOptionLabel={(option) =>
                                option.first + " " + option.last
                              }
                              value={values.customer ? undefined : undefined}
                              onChange={handleProjectManagerChange}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="projectManagerName"
                                  value={projectManagerName}
                                  label={`${
                                    projectManagerName === "" ||
                                    projectManagerName === undefined ||
                                    projectManagerName === null
                                      ? "Project Manager"
                                      : projectManagerName
                                  }`}
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="projectManagerContactNo"
                              label="Project Manager Contact No."
                              value={projectManagerContactNo}
                              onChange={(e) =>
                                setProjectManagerContactNo(e.target.value)
                              }
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="projectManagerEmail"
                              label="Project Manager Email"
                              value={projectManagerEmail}
                              onChange={(e) =>
                                setProjectManagerEmail(e.target.value)
                              }
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="siteInchargeName"
                              options={userList}
                              getOptionLabel={(option) =>
                                option.first + " " + option.last
                              }
                              value={values.customer ? undefined : undefined}
                              onChange={handlesiteInchargeChange}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="siteInchargeName"
                                  value={siteInchargeName}
                                  label={`${
                                    siteInchargeName === "" ||
                                    siteInchargeName === undefined ||
                                    siteInchargeName === null
                                      ? "Site Incharge"
                                      : siteInchargeName
                                  }`}
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="siteInchargeContactNo"
                              label="Site Incharge Contact No."
                              value={siteInchargeContactNo}
                              onChange={(e) =>
                                setSiteInchargeContactNo(e.target.value)
                              }
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="siteInchargeEmail"
                              label="Site Incharge Email"
                              value={siteInchargeEmail}
                              onChange={(e) =>
                                setSiteInchargeEmail(e.target.value)
                              }
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="country"
                              options={countryList}
                              getOptionLabel={(option) => option.name}
                              value={values.country ? undefined : undefined}
                              onChange={handleCountryChange}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="country"
                                  value={country}
                                  label="Country"
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="state"
                              options={stateList}
                              getOptionLabel={(option) => option.name}
                              value={values.state ? undefined : undefined}
                              onChange={handleStateChange}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="state"
                                  value={state}
                                  label="State"
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-4">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="city"
                              options={citiesList}
                              getOptionLabel={(option) => option.name}
                              value={values.city ? undefined : undefined}
                              onChange={handleCityChange}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="city"
                                  value={city}
                                  label="City"
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset> */}

                          <fieldset className="form-group col-md-12  mt-3 mb-5">
                            <FormikTextField
                              name="description"
                              className="form-control"
                              multiline
                              rows={2}
                              label="Remarks"
                              variant="outlined"
                              value={remark}
                              onChange={(e) => setRemark(e.target.value)}
                            />
                          </fieldset>
                                                  <button
                                                    type="submit"
                                                    className="btn btn-sm hovbuttonColour mr-2"
                                                    disabled={loader}
                                                  >
                                                    {loader? <Spinner
                                                      animation="border"
                                                      size="sm"
                                                      className="mr-1"
                                                    />:""}
                                                    Submit
                                                  </button>
                          <Link
                            as="button"
                            className="btn btn-sm deletebutton"
                            to={{ pathname: "/projects" }}
                            state={{ message: "" }}
                          >
                            <b className="text-danger"> X </b>Close
                          </Link>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default withRouter(ProjectsCreation);
