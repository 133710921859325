import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import {userid } from "../Common/Credentials";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import Menu from "../Component/Menu";
import MailTempletes from "./MailTempletes";
import MailConfig from "./MailConfig";

class MailSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      i: 0,
      mailOptions: [{ id: 0, name: "Mail Configuration" }, { id: 1, name: "Templates" }],
      mselected: 0,
    };
  }
  monChange(option) {
    this.setState({ mselected: option.id });
  }
  
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
  }
  render() {
    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
            <Link as="button" className=" text-secondary"  to={{ pathname: "/settings" }}>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/index">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/settings">Settings</Link>
              </li>
              <li className="breadcrumb-item active">Mail Settings</li>
            </ol>

            <div className="card">
              <div className="card-header">
                <h3 className="card-title text-secondary">
                  <i className="fas fa-cog mr-2 textColour"></i>
                  Mail Settings / {this.state.mselected === 0?"Mail Configuration":"Templets"} 
                </h3>
              </div>
              <div className="card-body">
              
                    <div className="form row">

                      {this.state.mailOptions.map((option) => {
                        return (
                          <label key={option.id}>
                           
                              <div className="btn-group">
                                <input
                                  className={`shadow-sm text-center btn  ${this.state.mselected === option.id? 'hovborderbuttonColour':' hovbuttonColour'}`}
                                  type="button"
                                  key={option.id}
                                  onClick={this.monChange.bind(this, option)}
                                  style={{ marginRight: 8, width: 250 }}
                                  value={option.name}
                                />
                              </div>
                          </label>
                        );
                      })}
                      <div className="col-md-12"></div>
                      {this.state.mselected === 0 ?
                         <div className="col-md-12"><MailConfig/></div>
                        :
                        <div className="col-md-12"><MailTempletes /></div>
                      }
                    </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(MailSettings);
