import { createTheme } from "@mui/material";
import { Form, Formik } from "formik";
import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import { appmasterid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { ThemeProvider } from "@mui/material";
import MasterService from "../MasterService";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import { Spinner } from "react-bootstrap";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class Products extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ProductsList: [],
      product: '',
      newproduct:false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
   this.refresh()
  }
  refresh(){
    MasterService.getAllProducts().then((response) => {
      this.setState({ ProductsList: response.data,newproduct:false})
    })
  }

  onSubmit() {
        MasterService.addproduct(this.state.product).then((response) => this.refresh())
  }
  validate(values) {
    let errors = {};
    if (this.state.product === '') {
      errors.product = "Please Enter Product";
    }
    return errors;
  }
  render() {
    let { product } = this.state
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          sortOrder: "desc",
        },
      },
      {
        name: "products",
        label: "Product",
        options: { filter: true, sort: true },
      },
      {
        name: "Delete",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: appmasterid === "2" || appmasterid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <span
                as="button"
                data-tip
                data-for="delete"
                className="btn btn-sm deletebutton"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you wish to delete Screen ${tableMeta.rowData.find(
                        (e) => e
                      )}?`
                    )
                  )
                    this.deleteScreen(tableMeta.rowData.find((e) => e));
                }}
              ><i className="fa fa-trash"></i> Delete</span>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <span
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                onClick={() => this.setState({ newproduct: true })}
              >
                <CustomToolbar />
              </span>
            ) : (
              ""
            )}
          </>
        );
      },
    };
    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/superadminmasters">Home</Link>
              </li>
              <li className="breadcrumb-item active">products</li>
            </ol>
            <div className="form-row">
              <div className="card-body">
                <div>
                  {this.state.loading === true ? (
                    <div className="text-center">{spin}</div>
                  ) : (<>
                    <Formik
                      initialValues={{ product }}
                      onSubmit={this.onSubmit}
                      validateOnChange={false}
                      validate={this.validate}
                      enableReinitialize={true}>
                      {({ setFieldValue, values }) => (
                        <Form autoComplete="off">
                          <div className="form-row mb-4">
                            {this.state.newproduct === false ? '' : <>
                              <fieldset className="form-group col-md-4">
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="product"
                                  value={this.state.screen}
                                  label="Product"
                                  variant="standard"
                                  onChange={(e) =>
                                    this.setState({
                                      product: e.target.value,
                                    })
                                  }
                                  fullWidth
                                />
                              </fieldset>
                              <fieldset className="form-group col-md-4">
                                {this.state.loader === true ? <button className="btn btn-sm btn-info" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button> : <button className="btn btn-sm btn-info" type="submit"><i className="fas fa-save mr-2" />Save</button>}
                                <span as="button" className="btn btn-sm bg-white shadow-sm ml-3" onClick={()=>this.setState({newproduct:false})} ><b style={{ color: "#FA5733" }}> X</b> Cancel</span>
                              </fieldset>
                            </>
                            }
                          </div>
                        </Form>
                      )}
                    </Formik>
                    <ThemeProvider theme={theme}>
                      <MUIDataTable
                        className="table-sm px-3"
                        title={"products"}
                        data={this.state.ProductsList}
                        columns={columns}
                        options={options}
                      />
                    </ThemeProvider>
                  </>)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Products);
