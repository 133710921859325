import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import { FormikTextField } from "../../CommonComponents/FormField.js";

// import { Autocomplete } from '@mui/material';
import { Spinner } from "react-bootstrap";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Grid, TextField } from "@mui/material";
import SalesServices from "../SalesServices.js";
import moment from "moment";
import { NumericFormat } from "react-number-format";
import { accounts } from "../../Common/Products.js";
import { spin } from "../../Common/NewLoader.jsx";

class DepositeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.location.state.data,
      receiptdata: "",
      loader: false,
      id: this.props.location.state.id,
      bank: "",
      branch: "",
      chequeddno: "",
      accno: "",
      amount: this.props.location.state.data.receiptamount,
      date: new Date(),
      depositedetails: "",
      loading: true,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleConfirmPayment = () => {
    SalesServices.ApprovereceiptforNonAcc(this.state.id).then(() => {
      this.setState({ loading: true });
      SalesServices.getReceiptbyId(this.state.id).then(
        (response) => {
          this.setState({
            receiptdata: response.data,
            depositedetails: response.data.depositedetails,
            chequeddno: response.data.chequenumber,
            loading: false,
          });
        }
      );
    });
  };

  onSubmit(values) {
    let data = {
      amount: this.state.amount,
      bank: this.state.bank,
      branch: this.state.branch,
      accno: this.state.accno,
      depositedate: this.state.date,
      chequeddno:
        this.state.data.paymentmode === "CHEQUE"
          ? this.state.chequeddno
          : "Cash",
      createdby: userid,
      updatedby: userid,
      tenantid: currentTenant,
      receipts: this.state.id,
    };
    SalesServices.addDepositDetails(data).then(() =>
      this.props.navigate(`/salesreceipts`)
    );
  }

  handleDateChange = (date) => {
    this.setState({ date: date });
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    SalesServices.getReceiptbyId(this.props.location.state.id).then(
      (response) => {
        this.setState({
          receiptdata: response.data,
          depositedetails: response.data.depositedetails,
          chequeddno: response.data.chequenumber,
          loading: false,
        });
      }
    );
  }

  validate(values) {
    let errors = {};

    //  if (!values.name|| values.name==='') {
    //  errors.name = "Please Select An Item";
    //   }
    // if (!values.cost) {
    //   errors.cost = 'Price value is required';
    // }
    return errors;
  }

  render() {
    let { id, bank, branch, chequeddno, accno, amount } = this.state;

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <Link
                  as="button"
                  className="text-secondary"
                  to={{ pathname: "/salesreceipts" }}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/salesreceipts">Receipts</Link>
              </li>
              <li className="breadcrumb-item active">Deposite Details</li>
            </ol>

            <div className="card-body">
              {this.state.loading ? (
                <div>{spin}</div>
              ) : (
                <>
                  {this.state.depositedetails === "" ||
                  this.state.depositedetails === null ||
                  this.state.depositedetails === undefined ? (
                    <Formik
                      initialValues={{
                        id,
                        bank,
                        branch,
                        chequeddno,
                        accno,
                        amount,
                      }}
                      onSubmit={this.onSubmit}
                      validateOnChange={false}
                      validate={this.validate}
                      enableReinitialize={true}
                    >
                      {() => (
                        <Form autoComplete="off">
                          <div className="form-row">
                            <fieldset className="form-group col-md-4">
                              <FormikTextField
                                className="form-control"
                                type="text"
                                name="id"
                                label="Receipt Id"
                                value={this.state.id}
                                disabled
                              />
                            </fieldset>

                            {this.state.data.paymentmode === "CHEQUE" ? (
                              <fieldset className="form-group col-md-4">
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="chequeddno"
                                  value={this.state.chequeddno}
                                  label="Cheque/DD no."
                                  variant="standard"
                                  fullWidth
                                  disabled
                                />
                              </fieldset>
                            ) : (
                              <fieldset className="form-group col-md-4">
                                <FormikTextField
                                  className="form-control"
                                  type="text"
                                  name="cash"
                                  value="Cash"
                                  label="Cash"
                                  variant="standard"
                                  fullWidth
                                  disabled
                                />
                              </fieldset>
                            )}

                            <fieldset className="form-group col-md-4">
                              <FormikTextField
                                className="form-control"
                                type="number"
                                name="amount"
                                label="Amount"
                                value={this.state.amount}
                                disabled
                              />
                            </fieldset>

                            <fieldset className="form-group col-md-4">
                              <FormikTextField
                                className="form-control"
                                type="text"
                                name="bank"
                                onChange={(e) => {
                                  this.setState({ bank: e.target.value });
                                }}
                                label="Bank Name"
                                variant="standard"
                                fullWidth
                              />
                            </fieldset>

                            <fieldset className="form-group col-md-4">
                              <FormikTextField
                                className="form-control"
                                type="text"
                                name="branch"
                                onChange={(e) => {
                                  this.setState({ branch: e.target.value });
                                }}
                                label="Branch"
                                variant="standard"
                                fullWidth
                              />
                            </fieldset>

                            <fieldset className="form-group col-md-4">
                              <FormikTextField
                                className="form-control"
                                type="text"
                                name="accno"
                                onChange={(e) => {
                                  this.setState({ accno: e.target.value });
                                }}
                                label="Account no."
                                variant="standard"
                                fullWidth
                              />
                            </fieldset>

                            <fieldset className="form-group col-md-4">
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid container>
                                  <DatePicker
                                    variant="inline"
                                    id="date-picker-dialog"
                                    label="Deposit Date"
                                    format="dd/MM/yyyy"
                                    minDate={new Date()}
                                    value={this.state.date}
                                    onChange={this.handleDateChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                      />
                                    )}
                                  />
                                </Grid>
                              </LocalizationProvider>
                            </fieldset>
                          </div>
                          <div className="form-group col-md-12" />
                          <button
                            className="btn btn-sm hovbuttonColour mr-1"
                            type="submit"
                          >
                            {" "}
                            {this.state.loader === true ? (
                              <>
                                <Spinner animation="border" size="sm" />
                                {"Saving"}
                              </>
                            ) : (
                              <>
                                <i className="fa fa-save mr-1" />
                                {"Save"}
                              </>
                            )}
                          </button>
                          <Link
                            as="button"
                            className="btn btn-sm deletebutton"
                            to={{ pathname: "/salesreceipts" }}
                          >
                            <b className="text-danger"> X</b> Cancel
                          </Link>
                        </Form>
                      )}
                    </Formik>
                  ) : (
                    <div className="form-row">
                      <fieldset className="col-md-6">
                        <h5 className="border-bottom">Receipt Details:</h5>
                        <table className="float-left">
                          <tbody>
                            <tr>
                              <td>Receipt Id </td>
                              <td>:&nbsp;&nbsp;{this.state.data.receiptid}</td>
                            </tr>
                            <tr>
                              <td>Customer </td>
                              <td>:&nbsp;&nbsp;{this.state.data.customer}</td>
                            </tr>
                            <tr>
                              <td>Sale Order Id </td>
                              <td>:&nbsp;&nbsp;{this.state.data.soid}</td>
                            </tr>
                            <tr>
                              <td>Invoice Id </td>
                              <td>:&nbsp;&nbsp;{this.state.data.invid}</td>
                            </tr>
                            <tr>
                              <td>Payment Mode</td>
                              <td>
                                :&nbsp;&nbsp;{this.state.data.paymentmode}
                              </td>
                            </tr>
                            <tr>
                              <td>Receipt Date </td>
                              <td>
                                :&nbsp;&nbsp;
                                {moment(this.state.data.createdAt).format(
                                  "DD-MM-YYYY hh:mm"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Receipt Amount </td>
                              <td>
                                :&nbsp;&nbsp;
                                <NumericFormat
                                  displayType={"text"}
                                  value={this.state.data.receiptamount}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </fieldset>

                      <fieldset className="col-md-6">
                        <h5 className="border-bottom">Deposit Details :</h5>
                        <table className="float-left">
                          <tbody>
                            {/* <tr>
                    <td>Deposit Id </td>
                    <td>:&nbsp;&nbsp;{this.state.depositedetails.id}</td>
                  </tr> */}
                            <tr>
                              <td>Deposited By </td>
                              <td>
                                :&nbsp;&nbsp;
                                {this.state.depositedetails.createdby}
                              </td>
                            </tr>
                            <tr>
                              <td> Date </td>
                              <td>
                                :&nbsp;&nbsp;
                                {moment(
                                  this.state.depositedetails.depositedate
                                ).format("DD-MM-YYYY hh:mm")}
                              </td>
                            </tr>
                            <tr>
                              <td>Bank Name </td>
                              <td>
                                :&nbsp;&nbsp;{this.state.depositedetails.bank}
                              </td>
                            </tr>
                            <tr>
                              <td>Baranch </td>
                              <td>
                                :&nbsp;&nbsp;{this.state.depositedetails.branch}
                              </td>
                            </tr>
                            <tr>
                              <td>Account No. </td>
                              <td>
                                :&nbsp;&nbsp;{this.state.depositedetails.accno}
                              </td>
                            </tr>
                            <tr>
                              <td>Cheque/DD No.</td>
                              <td>
                                :&nbsp;&nbsp;
                                {this.state.depositedetails.chequeddno}
                              </td>
                            </tr>
                            <tr>
                              <td>Deposit Date </td>
                              <td>
                                :&nbsp;&nbsp;
                                {moment(
                                  this.state.depositedetails.depositedate
                                ).format("DD-MM-YYYY")}
                              </td>
                            </tr>
                            <tr>
                              <td>Amount </td>
                              <td>
                                :&nbsp;&nbsp;
                                <NumericFormat
                                  displayType={"text"}
                                  value={this.state.data.receiptamount}
                                  decimalScale={2}
                                  fixedDecimalScale={true}
                                  disabled={true}
                                  thousandSeparator={true}
                                  thousandsGroupStyle={thousandsdisplay}
                                ></NumericFormat>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </fieldset>
                      {(accounts === "NO" ||
                      accounts === "" ||
                      accounts === null) && this.state.receiptdata.accstatus === "Pending" ? (
                        <button
                          className="btn btn-sm hovbuttonColour"
                          onClick={this.handleConfirmPayment}
                        >
                          Confirm Payment
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}
export default withRouter(DepositeDetails);
