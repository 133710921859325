import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../Component/consts'

class NewVoucherService {

    retriveVoucherTypes(tenantId){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/accounts/getvouchertypes/${tenantId}`)
    }

   
}
export default new NewVoucherService();