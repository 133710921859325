import React from "react";
import { Link } from "react-router-dom";

import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  clientname,
  currentTenant,
  logo,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
// import moment from "moment";
import { spin } from "../../Common/NewLoader";
import { productionid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";
import {
  Autocomplete,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  TableFooter,
  Tooltip,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import ProductionServices from "../ProductionServices";
import withRouter from "../../withRouter";
import Swal from "sweetalert2";
import { FormikTextField } from "../../CommonComponents/FormField";
import InventoryService from "../../inventory/InventoryService";
import { Form, Formik } from "formik";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class ProductionStoreDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.location.state.id,
      storeitems: [],
      warehouse: this.props.location.state.warehouse,
      whid: this.props.location.state.whid,
      workcenter: this.props.location.state.workcenter,
      wcid: this.props.location.state.wcid,
      name: this.props.location.state.name,
      Options: ["Store Items", "Indents Issue", "Losses"],
      indentissued: this.props.location.state.indentissue,
      lossesdata: this.props.location.state.losses,
      selected: "Store Items",
      loading: false,
      comments: ["Damaged", "Shortage"],
      comment: "",
      losses: "",
      price: 0,
      itemlist:[],
      filterdstoreitems:[],
      item:'',
      return:''
    };
  }

  // pdf = () => {
  //   let pdfdata = this.state.active;
  //   pdfdata.map(
  //     (e) =>
  //       (pdfdata.find((i) => i.id === e.id).createdAt = moment(
  //         pdfdata.find((i) => i.id === e.id).createdAt
  //       ).format("DD-MM-YYYY hh:mm"))
  //   );
  //   pdfdata.map(
  //     (e) =>
  //       (pdfdata.find((i) => i.id === e.id).startdate = moment(
  //         pdfdata.find((i) => i.id === e.id).startdate
  //       ).format("DD-MM-YYYY"))
  //   );
  //   const columns = [
  //     { header: "Estimation Id", dataKey: "id" },
  //     { header: "SaleOrder Id", dataKey: "soid" },
  //     { header: "Workcenter", dataKey: "workcenter" },
  //     { header: "Type", dataKey: "estimationtype" },
  //     { header: "Status", dataKey: "status" },
  //     { header: "Satrt Date", dataKey: "startdate" },
  //     { header: "Duration", dataKey: "duration" },
  //     { header: "Date", dataKey: "createdAt" },
  //   ];
  //   var doc = new jsPDF("p", "pt");
  //   doc.addImage(logo, "GIF", 40, 20, 60, 60);
  //   doc.setFontSize(16);
  //   doc.setFont("Times-Roman");
  //   doc.text(`${clientname}`, 180, 40);
  //   doc.setFontSize(14);
  //   doc.text(`Active Sale Orders`, 210, 80);
  //   doc.setFontSize(8);
  //   doc.text(`${moment().format("DD-MM-YYYY hh:mm")}`, 510, 80);
  //   doc.line(0, 90, 600, 90);
  //   autoTable(doc, {
  //     theme: "grid",
  //     startY: 100,
  //     columnStyles: { 7: { halign: "right" } },
  //     body: pdfdata,
  //     columns: columns,
  //   });
  //   doc.save("ActiveSaleordes.pdf");
  //   this.refresh();
  // };

  refresh() {

    InventoryService.retriveAllItems(currentTenant).then((response) =>
    this.setState({ itemlist: response.data })
  );

    ProductionServices.getStoreItems(this.props.location.state.id).then(
      (response) => {
        this.setState({
          data: response.data,
          loading: false,
          storeitems: response.data.storeitems
            .filter((e) => e.quantity !== 0)
            .map((e) => Object.assign(e, { lossstatus: false,returnstatus:false }))
            .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1)),
          indentissued: response.data.indentissue,
          lossesdata: response.data.losses,
        });
      }
    );
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.refresh();
  }

  handleCommentChange = (e) => {
    this.setState({ comment: e.target.value });
  };

  handleAddLosses = (data) => {
    let lossesdata = {
      id: data,
      batchid: this.state.storeitems.find((e) => e.id === data).batchid,
      itemid: this.state.storeitems.find((e) => e.id === data).itemid,
      itemname: this.state.storeitems.find((e) => e.id === data).itemname,
      quantity: this.state.losses,
      price: this.state.storeitems.find((e) => e.id === data).price,
      totalloss:
        this.state.storeitems.find((e) => e.id === data).price *
        parseFloat(this.state.losses),
      tenantid: currentTenant,
      comments: this.state.comment,
      createdby: userid,
      store: this.state.id,
    };
    Swal.fire({
      title: "Add to losses?",
      text: "Are you sure you want to Add?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        ProductionServices.AddLosses(lossesdata).then((response) => {
          this.refresh();
          this.setState({ selected: "Losses" });
          Swal.fire({
            title: "Item added to losses successfully",
            text: ".",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          });
        });
      }
    });
  };

  handleAddReturn = (data)=>{
    let returndata = {
      id: data,
      batchid: this.state.storeitems.find((e) => e.id === data).batchid,
      itemid: this.state.storeitems.find((e) => e.id === data).itemid,
      itemname: this.state.storeitems.find((e) => e.id === data).itemname,
      quantity: this.state.return,
      price: this.state.storeitems.find((e) => e.id === data).price,
      tenantid: currentTenant,
      createdby: userid,
      store: this.state.id,
    };
    Swal.fire({
      title: "Add to back inventory?",
      text: "Are you sure you want to return back to Inventory?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        ProductionServices.AddToInventory(returndata).then((response) => {
          this.refresh();
          // this.setState({ selected: "Losses" });
          Swal.fire({
            title: "Item Returned to Inventory successfully",
            text: ".",
            icon: "success",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
          });
        });
      }
    });
  }

  handlelossstatus = (data) => {
    this.state.storeitems.find((e) => e.id === data).lossstatus = true;
    this.state.storeitems.find(e=>e.id===data).returnstatus = false
    this.setState({ storeitems: this.state.storeitems });
  };

  handlereturnstatus = (data) =>{
    this.state.storeitems.find(e=>e.id===data).returnstatus = true
    this.state.storeitems.find((e) => e.id === data).lossstatus = false;
    this.setState({ storeitems: this.state.storeitems });
  }

  handleclose = (data) => {
    this.state.storeitems.find((e) => e.id === data).lossstatus = false;
    this.state.storeitems.find(e=>e.id===data).returnstatus = false
    this.setState({ storeitems: this.state.storeitems });
  };

  handleitemname = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({item:values.name,filterdstoreitems:this.state.storeitems.filter(e=>e.itemid === values.id)})
    }
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumn: false,
        },
      },
      {
        name: "itemname",
        label: "Item Name",
        options: { filter: true, sort: true },
      },
      {
        name: "supplier",
        label: "Supplier",
        options: { filter: true, sort: true },
      },
      {
        name: "custombatchid",
        label: "Batch",
        options: { filter: true, sort: true },
      },

      {
        name: "price",
        label: "Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "addedquantity",
        label: "Added Quantity",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return value && value.toFixed(3);
          },
        },
      },
      {
        name: "quantity",
        label: "Avilable Quantity",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return value.toFixed(3);
          },
        },
      },
      {
        name: "totalquantity",
        label: "Total Quantity",
        options: { filter: true, sort: true,
         customBodyRender:(value)=>{
            return(value===null?"NA":value.toFixed(3))
          },
        },
      },
      {
        name: "lossstatus",
        label: "Losses",
        options: {
          filter: true,
          sort: true,
          display: productionid === "2" || productionid === "3",
          customBodyRender: (value, tableMeta, updateValue) => {
            return value === true ? (
              <div className="row">
                <fieldset className="col-md-4">
                  <TextField
                    id="standard-error-helper-text"
                    type="number"
                    label="Quantity"
                    onChange={(e) => this.setState({ losses: e.target.value })}
                    variant="standard"
                    error={this.state.losses > tableMeta.rowData[3]}
                    helperText={
                      this.state.losses > tableMeta.rowData[3]
                        ? "Exceeds avilable quantity"
                        : ""
                    }
                    fullWidth
                  />
                </fieldset>
                <fieldset className="col-md-4">
                  <FormControl className="col-md-12" variant="standard">
                    <InputLabel id="demo-simple-select-filled-label">
                      Comments
                    </InputLabel>
                    <Select
                      value={this.state.comment}
                      onChange={this.handleCommentChange}
                    >
                      {this.state.comments.map((option) => {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </fieldset>
                <fieldset className="col-md-4 mt-2">
                  <button
                    as="button"
                    className="hovbuttonColour btn btn-sm fa fa-save mr-1"
                    onClick={() => this.handleAddLosses(tableMeta.rowData[0])}
                    disabled={
                      this.state.losses === undefined ||
                      this.state.losses === "" ||
                      this.state.losses === 0 ||
                      this.state.comment === "" ||
                      this.state.losses > tableMeta.rowData[3]
                    }
                  ></button>
                  <button
                    as="button"
                    className="deletebutton btn btn-sm"
                    onClick={() => this.handleclose(tableMeta.rowData[0])}
                  >
                    {" "}
                    X
                  </button>
                </fieldset>
              </div>
            ) : (
              <button
                as="button"
                className="hovbuttonColour btn btn-sm mr-1"
                onClick={() => this.handlelossstatus(tableMeta.rowData[0])}
              >
                +
              </button>
            );
          },
        },
      },


      {
        name: "returnstatus",
        label: "Return",
        options: {
          filter: true,
          sort: true,
          display: productionid === "2" || productionid === "3",
          customBodyRender: (value, tableMeta, updateValue) => {
            return value === true ? (
              <div className="row">
                <fieldset className="col-md-4">
                  <TextField
                    id="standard-error-helper-text"
                    type="number"
                    label="Quantity"
                    onChange={(e) => this.setState({ return: e.target.value })}
                    variant="standard"
                    error={this.state.return > tableMeta.rowData[3]}
                    helperText={
                      this.state.return > tableMeta.rowData[3]
                        ? "Exceeds avilable quantity"
                        : ""
                    }
                    fullWidth
                  />
                </fieldset>
                <fieldset className="col-md-4 mt-2">
                  <button
                    as="button"
                    className="hovbuttonColour btn btn-sm fa fa-save mr-1"
                    onClick={() => this.handleAddReturn(tableMeta.rowData[0])}
                    disabled={
                      this.state.return === undefined ||
                      this.state.return === "" ||
                      this.state.return === 0 ||
                      this.state.return > tableMeta.rowData[3]
                    }
                  ></button>
                  <button
                    as="button"
                    className="deletebutton btn btn-sm"
                    onClick={() => this.handleclose(tableMeta.rowData[0])}
                  >
                    {" "}
                    X
                  </button>
                </fieldset>
              </div>
            ) : (
              <button
                as="button"
                className="hovbuttonColour btn btn-sm mr-1"
                onClick={() => this.handlereturnstatus(tableMeta.rowData[0])}
              >
                +
              </button>
            );
          },
        },
      },


    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {productionid === "2" || productionid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{
                  pathname: "/newstore",
                }}
                state={{
                  id: this.state.id,
                  wcid: this.state.wcid,
                  workcenter: this.state.workcenter,
                  whid: this.state.whid,
                  warehouse: this.state.warehouse,
                  name: this.state.name,
                  pagename: "New Store",
                }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };

    const columns1 = [
      {
        name: "id",
        label: "Indent Issued Id",
        options: { filter: true, sort: true },
      },
      {
        name: "indentid",
        label: "Indent Id",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value) => {
            return (
              <Link
                as="button"
                data-tip
                data-for="view details"
                to={{
                  pathname: "/indentingdetails",
                }}
                state={{ id: value }}
              >
                {value}
              </Link>
            );
          },
        },
      },
      {
        name: "createdby",
        lable: "Issued By",
        options: { filter: true, sort: true },
      },
      {
        name: "id",
        label: "Issed Details",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="view details"
                  className="hovbuttonColour btn btn-sm"
                  to={{
                    pathname: "/indentissueddetails",
                  }}
                  state={this.state.indentissued.find((e) => e.id === value)}
                >
                  {" "}
                  View Details
                </Link>
                {/* <Tooltip id="view details" place="top" effect="solid">
                  View Details
                </Tooltip> */}
              </>
            );
          },
        },
      },
    ];
    const options1 = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {productionid === "2" || productionid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{
                  pathname: "/indentissue",
                }}
                state={{
                  id: this.state.id,
                  wcid: this.state.wcid,
                  workcenter: this.state.workcenter,
                  whid: this.state.whid,
                  warehouse: this.state.warehouse,
                  name: this.state.name,
                  pagename: "Indent Issue",
                }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };

    const locolumns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: false,
          display: false,
          viewColumn: false,
        },
      },
      {
        name: "itemname",
        label: "Item Name",
        options: { filter: true, sort: true },
      },
      {
        name: "price",
        label: "Unit Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "totalloss",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "comments",
        label: "Comments",
        options: { filter: true, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return moment(value).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      },
    ];
    const looptions = {
      filterType: "checkbox",
      selectableRows: "none",
    };

    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <Link
                  as="button"
                  className=" text-secondary"
                  to={{ pathname: "/store" }}
                >
                  ❮ Back &nbsp;&nbsp;
                </Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/store">Store</Link>
              </li>
              <li className="breadcrumb-item active">Store Details</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">
                          {this.state.name} - {this.state.selected}
                        </h3>
                      </div>

                      <div className="card-body">
                        {this.state.loading === true ? (
                          <span className="text-center">{spin}</span>
                        ) : (
                          <>
                            {this.state.Options.map((option) => {
                              return (
                                <label key={option}>
                                  <div className="btn-group">
                                    <input
                                      className={
                                        this.state.selected === option
                                          ? "btn hovborderbuttonColour"
                                          : "btn hovbuttonColour"
                                      }
                                      type="button"
                                      key={option}
                                      onClick={() =>
                                        this.setState({ selected: option })
                                      }
                                      style={{ marginRight: 8, width: 250 }}
                                      value={option}
                                    />
                                  </div>
                                </label>
                              );
                            })}
                            {this.state.selected === "Store Items" ? (
                              <Formik
                                initialValues={{
                                 item:this.state.item,itemlist:this.state.itemlist
                                }}
                                onSubmit={this.onSubmit}
                                validateOnChange={false}
                                validate={this.validate}
                                enableReinitialize={true}
                              >
                                {(values) => (
                                  <Form autoComplete="off" className="row">
                                    <fieldset className="col-md-3 mb-4">
                                      <Autocomplete
                                        id="subaccount"
                                        className="subacc-select"
                                        name="item"
                                        options={this.state.itemlist}
                                        getOptionLabel={(option) => option.name}
                                        value={
                                          values.item ? undefined : undefined
                                        }
                                        onChange={this.handleitemname}
                                        renderInput={(params) => (
                                          <FormikTextField
                                            {...params}
                                            className="form-control"
                                            type="text"
                                            name="name"
                                            value={this.state.item}
                                            label={`Select Item (${this.state.item})`}
                                            variant="standard"
                                            fullWidth
                                          />
                                        )}
                                      />
                                    </fieldset>
                                    <fieldset className="col-md-12">
                                    <ThemeProvider theme={theme}>
                                      <MUIDataTable
                                        className="table-sm px-3"
                                        title={`Store Items`}
                                        data={this.state.filterdstoreitems}
                                        columns={columns}
                                        options={options}
                                      />
                                    </ThemeProvider>
                                    </fieldset>
                                  </Form>
                                )}
                              </Formik>
                            ) : this.state.selected === "Indents Issue" ? (
                              <ThemeProvider theme={theme}>
                                <MUIDataTable
                                  className="table-sm px-3"
                                  title={`Indents Issued`}
                                  data={this.state.indentissued}
                                  columns={columns1}
                                  options={options1}
                                />
                              </ThemeProvider>
                            ) : (
                              <ThemeProvider theme={theme}>
                                <MUIDataTable
                                  className="table-sm px-3"
                                  title={`Losses`}
                                  data={this.state.lossesdata}
                                  columns={locolumns}
                                  options={looptions}
                                />
                              </ThemeProvider>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ProductionStoreDetails);
