import axios from "axios";
// const employee_all_api_url = '${Auth_Proto}${Auth_Domain}${Auth_Port}/all';
import { Auth_Proto, Auth_Domain, Auth_Port } from '../../Component/consts'
export let currentTenant
class TourServices {


    // -------------------------------------------------------------------------------------------------------------------------

    // MHotel
    addMHotel(data) {
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/addMHotel`, data)
    }

    getAllMHotels() {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getallmasterhotel`,)
    }
    
    deleteMHotel(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/deleteMHotel/${id}`)
    }

    updateMHotel(data, id) {
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/updateMHotel/${id}`, data)
    }
    getByIdMHotel(id) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getbyidMHotel/${id}`)
    }


    // MHotelTax

    addMHotelTax(data) {
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/addMHoteltax`, data)
    }

    getAllMHotelTaxList() {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getallmasterhoteltaxlist`);
    }

    deleteMHotelTax(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/deleteMHoteltax/${id}`)
    }
    updateMHotelTax(data, id) {
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/updateMHoteltax/${id}`, data)
    }
    getByIdMHotelTax(id) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getbyidMHotelTax/${id}`)
    }

    //  MHotelChain

    addMHotelChain(data) {
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/addMHotelchain`, data)
    }

    getAllMHotelChainList() {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getallmasterhotelchainlist`);
    }

    deleteMHotelChain(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/deleteMHotelchain/${id}`)
    }

    updateMHotelChain(data, id) {
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/updateMHotelchain/${id}`, data)
    }
    getByIdMHotelChain(id) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getbyidMHotelchain/${id}`)
    }



    //  MRoomtype

    addMRoomType(data) {
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/addMRoomtype`, data)
    }

    getAllMRoomTypeList() {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getallmasterroomtypelist`);
    }

    deleteMRoomType(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/deleteMRoomtype/${id}`)
    }

    updateMRoomType(data, id) {
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/updatemasterroomtype/${id}`, data)
    }
    getByIdMRoomType(id) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getbyidmasterroomtype/${id}`)
    }


    // Mcar
    addMCar(data) {
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/addmastercar`, data)
    }

    getAllMCarList() {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getallmastercar`)
    }

    deleteMCar(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/deletemastercar/${id}`)
    }

    updateMCar(data, id) {
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/updatemastercar/${id}`, data)
    }
    getByIdMCar(id) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getbyidmastercar/${id}`)
    }

    // MHotelMealsPlans

    addMMealsPlan(data) {
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/addMealsplan`, data)
    }

    getAllMMealsPlanist() {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getallMealsPlan`)
    }

    deleteMMealsPlan(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/deleteMealsPlan/${id}`)
    }

    updateMMealsPlan(data, id) {
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/updatemastermealsplan/${id}`, data)
    }
    getByIdMMealsPlan(id) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getbyidmastermealsplan/${id}`)
    }

    // --------------------------------------------------------------------------------------------------------------------------------------------------

    // -------------------------------------------------------Travellers Services -----------------------------------------------------------------------

// ------------------------------------------------------Master Airline Started------------------------------------------------------------------

    addMAirline(data) {
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/addmasterairline`, data)
    }

    getAllMAirlineList() {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getallmasterairline`)
    }

    deleteMAirline(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/deletemasterairline/${id}`)
    }
    updateMAirline(data, id) {
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/updateMAirline/${id}`, data)
    }
    getByIdMAirline(id) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getbyidMAirline/${id}`)
    }

// ------------------------------------------------------Master Airline Ended--------------------------------------------------------------------
// ------------------------------------------------------Master Airport Started------------------------------------------------------------------

    addMAirport(data) {
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/addmasterairport`, data)
    }

    getAllMAirportList() {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getallmasterairport`)
    }

    deleteMAirport(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/deletemasterairport/${id}`)
    }
    updateMAirport(data, id) {
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/updateMAirport/${id}`, data)
    }
    getByIdMAirport(id) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getbyidMAirport/${id}`)
    }

// ------------------------------------------------------Master Airport Ended--------------------------------------------------------------------


// ------------------------------------------------------Master Airport Started------------------------------------------------------------------

addMPartner(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/addmasterpartner`, data)
}

getAllMPartnerList() {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getallpartner`)
}

deleteMPartner(id) {
    return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/deletemasterpartner/${id}`)
}
updateMPartner(data, id) {
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/updatemasterpartner/${id}`, data)
}
getByIdMPartner(id) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getbyidmasterpartner/${id}`)
}

// ------------------------------------------------------Master Airport Ended--------------------------------------------------------------------

// -------------------------------------------------------------------Invoice Service------------------------------------------------------------------------

addTicketInvoice(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/multipleticketadd`, data)
}
addhotelInvoice(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/addmultiHotel`, data)
}

addCarInvoice(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/addingmulticar`, data)
}
addServiceInvoice(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/addmultiService`, data)
}
addAdjustmentInvoice(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/addmultiAdjustment`, data)
}

addInvoiceRequest(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/saveinvoice`, data)
}
// ------------------------------------------------------------------------- GET APIS --------------------------------------------------------------------------------
getAllMasterList(){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getmasterList`)
}
getAllTicketList(){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getAllTicketList`)
}
getAllHotelList(){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getAllHotelList`)
}
getAllCarList(){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getallcarList`)
}
getAllServiceList(){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getallserviceList`)
}

// ------------------------------------------------------------------------- GET BY ID APIS --------------------------------------------------------------------------------
getByTicketId(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getTicket/${id}`)
}
getByHotelId(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getHotelByid/${id}`)
}
getByCarId(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getbyidmastercar/${id}`)
}
getByServiceId(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getServiceId/${id}`)
}

// 
getByInvoiceTenantId(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/gettravelinvoices/${id}`)
}
// 

getByInvoiceId(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/gettravelinvoicebyid/${id}`)
}
deleteByInvoiceId(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/deleteInviServiceById/${id}`)
}
// -------------------------------------------------------------------Invoice Service End------------------------------------------------------------------------
// ------------------------------------------------------------------------- Temp start APIS --------------------------------------------------------------------------------
getByPNRorNoId(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/getdatabypnrticket/${id}`)
}


getByHotelVoucherNo(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/ttinv/gethoteldatabyvoucher/${id}`)
}


// ------------------------------------------------------------------------- TempTicket Ends APIS --------------------------------------------------------------------------------




}

export default new TourServices();