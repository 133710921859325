import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
// 
import { currentTenant, userid } from '../../Common/Credentials.js';
import { spin } from '../../Common/NewLoader.jsx';
import { appmasterid } from '../../Common/Permissions.js';
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import TransporterServices from './TransporterServices.js';
import hsn from '../../Excel Templets/HSN.xlsx';
import * as XLSX from 'xlsx'; 


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});


class Transporter extends Component {


  constructor(props) {
    super(props) 

      this.state = {
        message:'',
        translist:[],
        hsnexcellist:[],
        loading:false,
        showadd:false,

    }
   
  }
Refresh(){ 

  TransporterServices.getTransporters(currentTenant)
    .then(
      response => {
          this.setState({translist:response.data})
        this.setState({loading:false})
        }
      )  
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })  
  }


    componentDidMount () {

      if(userid===undefined){
        window.confirm('Your Session is Lost: Please login Back to resume')
        this.props.navigate(`/`)
      }

    //   UserService.UserPermissions(userid).then(response=>this.setState({up:response.data}))
      
      this.setState({loading:true})

      this.Refresh();

     }

    render() {

        const columns = [
            {name: "id",label: "Id",options: {filter: false,sort: true,display:false}},
            {name: "transname",label:"Transporter Name",options: {filter: true,sort: true,}},
            {name: "transid",label:"Transporter Id",options: {filter: true,sort: true,}},
        ];
        const options = {
            filterType: 'checkbox',
            selectableRows: "none",
            customToolbar: () => {
             return (<>
              {appmasterid === '2' || appmasterid === '3'?
              <Link className="float-right btn btn-sm m-0 p-0" to="/addtransporter" >
                <CustomToolbar />
              </Link>
                :''  
              }
             </>
             );
           }
          }
        return (
            <div>
                <Header/>
                <Menu/>
                <div className="content-wrapper">
                    <div className="card">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item text-secondary">
                                <Link to="/masters">Home</Link>
                            </li>
                            
                            <li className="breadcrumb-item active">Transporter</li>
                        </ol>
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                
                                            <div className="card-body">
                                                <div>{this.state.loading === true ? <div className="text-center"> {spin}</div> :
                                                <>
                                                <ThemeProvider theme={theme}> 
                                                <MUIDataTable
                                                    className="table-sm px-3"
                                                    title={"Transporter"}
                                                    data={this.state.translist}
                                                    columns={columns}
                                                    options={options}
                                                />  
                                                </ThemeProvider>
                                                </>
                                                }</div>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>    
          
        )
       
    }
    
}
export default withRouter(Transporter)