import React, { Component } from 'react'
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import Menu from '../../Component/Menu'
import { currentTenant, thousandsdisplay, userid } from '../../Common/Credentials'
import moment from 'moment'
import { spin } from '../../Common/NewLoader'
import SalesServices from '../SalesServices'
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import { salesid } from '../../Common/Permissions'
import {NumericFormat} from 'react-number-format';
import MUIDataTable from 'mui-datatables'
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import UsercustomerService from '../../Masters/Usercustomers/UsercustomerService'



const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});
class ReceiptsTable extends Component {

  constructor(props) {
    super(props)

    this.state = {
      Receiptsdata: [],
      usercustomers:[],
      loading:false,
    }
  }
    
  refreshSalesorder(){
    SalesServices.GetSalesReceiptsdataDetails(currentTenant).then(response => {  
        console.log(this.state.usercustomers)
          this.setState({ Receiptsdata: response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1).filter(e=>this.state.usercustomers.includes(e.customer))})
          this.setState({loading:false})
        }   
    )
  }
  
  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    // this.setState({loading:true})
    UsercustomerService.GetUserCustomersbyUser(userid).then((res)=>
    this.setState({usercustomers:res.data===''||res.data===undefined?[]:res.data.customers.split(",")},()=>this.refreshSalesorder())
    )
    
  }
    
  render() {
let columns=[
    {name: "receiptid",label: "Receipt Id",options: {filter: false,sort: true,}},
    {name: "custid",label:"Customer Id",options: {filter: true,sort: true,}},
    {name: "customer",label: "Customer",options: {filter: true,sort: true,}},
    {name: "soid",label: "Saleorder Id",options: {filter: false,sort: true,}},
    {name: "invid",label: "Invoice Id",options: {filter: false,sort: true,}},
    {name: "receiptamount",label: "Receipt Amount",options: {filter: false,sort: true,
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
    {name: "paymentmode",label: "Payment",options: {filter: false,sort: true,}},
    {name: "depositestatus",label: "Deposite Status",options: {filter: false,sort: true,}},
    {name: "accstatus",label: "Accounts Entry",options: {filter: false,sort: true,}},
    {name: "createdAt",label: "Date",options: {filter: false,sort: true, 
    customBodyRender:(tableMeta)=>{
      return(moment(tableMeta).format('DD-MM-YYYY hh:mm'))
    }}},

    // {name: "invoicetype",label: "Invoicetype",options: {filter: true,sort: true,}},
    {
      name: "View",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
         return (<>
           <Link as="button" data-tip data-for="view details" className={`${tableMeta.rowData[7] === "Pending"?'fas fa-landmark	':'fa fa-list-alt'}`} 
           to={{pathname: "/depositedetails"}} state ={{id:tableMeta.rowData.find(e=>e),data:this.state.Receiptsdata.find(e=>e.receiptid === tableMeta.rowData.find(e=>e))}}></Link>
           {/* <Tooltip id="view details" place="top" effect="solid">Deposite</Tooltip> */}
           </>
         );
        }
      }
    },
  ];
   const options = {
    filterType: 'checkbox',
    selectableRows: "none",
    customToolbar: () => {
     return (<>
      {salesid === '2' || salesid === '3'?
     <Link as="button" className="float-right btn btn-sm m-0 p-0"  to={{pathname: '/newsalesreceipts'}} state ={ {id: -1,pagename:'Sales Receipts'}}>
       <CustomToolbar/>
     </Link>
     :''
      }
     </>
     );
   }
  }

     
    return (
          
      <div>
         <Header />
         <Menu/>
        <div className="content-wrapper">  
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              
              <li className="breadcrumb-item active">Receipts</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">              
              
                    <div className="card">                
                      <div className="card-header">
                        <h3 className="card-title">
                          Receipts - <span className='text-danger font-weight-bold'>Receipt of Agent collection only</span>
                        </h3>
                      </div>
                       
                      <div className="card-body">
                      <div>{this.state.loading === true ?<div className="text-center">{spin}</div>:
                         <ThemeProvider theme={theme}> 
                        <MUIDataTable
                          className="table-sm px-3"
                          title={"Receipts"}
                          data={this.state.Receiptsdata}
                          columns={columns}
                          options={options}
                        />
                        </ThemeProvider>
                      }</div>
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer/>  
      </div>        
    )  
  }  
}
export default withRouter(ReceiptsTable)


