import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import moment from "moment";

import SalesServices from "../SalesServices";
import {
  address,
  address2,
  ccity,
  ccountry,
  clientname,
  companyemail,
  contactnumber,
  cstate,
  currency,
  currentTenant,
  czip,
  gstin,
  logo,
  prefix1,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { ToWords } from "to-words";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {NumericFormat} from "react-number-format";

import { Modal } from "react-bootstrap";
import UploadFilesService from "../../Common/upload/UploadFilesService";
import FileServices from "../../Common/upload/FileServices";
import SettingsServices from "../../Settings/SettingsServices";
import Swal from "sweetalert2";

let curmonth, year, day, path;
let doctype = "invoice";

class ViewDn extends Component {
  constructor(props) {
    super(props);
    const id = this.props.location.state.id;
    this.state = {
      dndata: {},
      saleorderitems: [],
      invoiceservicelist: "",
      milestones: [],
      id: id,
      stat: 0,
      loading: false,
      show: false,
      mail1: "",
      mail2: "",
      templete: "",
      // dndata:'',
      dnitems: [],
      precloseitems: [],
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: "",
      isError: false,
      fileInfos: [],
      fileInfo: "",
      partyid: "",
      error: false,
      loader:false
    };
    this.selectFile = this.selectFile.bind(this);
    this.upload = this.upload.bind(this);
  }
  handleShow = () => {
    this.setState({ show: true, partyid: this.state.dndata.customer });
  };
  handleClose = () => {
    this.setState({
      show: false,
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: "",
      isError: false,
      fileInfos: [],
      fileInfo: "",
      partyid: this.state.sellerid,
      error: false,
    });
  };
  handleDownload(dndata) {
    let data;
    let curdate = moment().format("DD-MM-YYYY HH:mm:ss");
    data = dndata.deliveryitems;
    data = data.map((v) => ({ ...v, cgstamt: v.taxamount / 2 }));
    data = data.map((v) => ({ ...v, cgst: v.tax / 2 }));
    data = data.map((v) => ({ ...v, sgstamt: v.taxamount / 2 }));
    data = data.map((v) => ({ ...v, sgst: v.tax / 2 }));

    if (dndata.currency !== currency) {
      data.find((e) => e).price =
        data.find((e) => e).price * dndata.curconversionrate;
      data.find((e) => e).taxamount =
        data.find((e) => e).taxamount * dndata.curconversionrate;
      data.find((e) => e).cgstamt =
        data.find((e) => e).cgstamt * dndata.curconversionrate;
      data.find((e) => e).sgstamt =
        data.find((e) => e).sgstamt * dndata.curconversionrate;
      data.find((e) => e).totalpricewtax =
        data.find((e) => e).totalpricewtax * dndata.curconversionrate;
    }

    let tablecolp;
    let total = dndata.totalamount;
    let grandtotal = dndata.totalamountwtax;
    let taxamount = dndata.taxamount;
    // let advance = dndata.advanceamountreq

    if (dndata.currency !== currency) {
      total = total * dndata.curconversionrate;
      grandtotal = grandtotal * dndata.curconversionrate;
      taxamount = taxamount * dndata.curconversionrate;
      // advance = advance * dndata.curconversionrate
    }

    if (dndata.taxtype !== "NA") {
      if (dndata.taxtype === "GST" && ccountry === "India") {
        if (dndata.gsttype === "INTRASTATE") {
          tablecolp = [
            { header: "Item", dataKey: "name" },
            { header: `Unit Price (${dndata.currency})`, dataKey: "price" },
            { header: "Quantity", dataKey: "quantity" },
            { header: "UOM", dataKey: "uom" },
            { header: "CGST (%)", dataKey: "cgst" },
            { header: "SGST (%)", dataKey: "sgst" },
            { header: `CGST Amt. (${dndata.currency})`, dataKey: "cgstamt" },
            { header: `SGST Amt. (${dndata.currency})`, dataKey: "sgstamt" },
            { header: `Total (${dndata.currency})`, dataKey: "totalpricewtax" },
          ];
        } else {
          tablecolp = [
            { header: "Item", dataKey: "name" },
            { header: `Unit Price (${dndata.currency})`, dataKey: "price" },
            { header: "Quantity", dataKey: "quantity" },
            { header: "UOM", dataKey: "uom" },
            { header: "IGST (%)", dataKey: "tax" },
            { header: `IGST Amt. (${dndata.currency})`, dataKey: "taxamount" },
            { header: `Total (${dndata.currency})`, dataKey: "totalpricewtax" },
          ];
        }
      } else {
        if (dndata.taxtype === "GST") {
          tablecolp = [
            { header: "Item", dataKey: "name" },
            { header: `Unit Price (${dndata.currency})`, dataKey: "price" },
            { header: "Quantity", dataKey: "quantity" },
            { header: "UOM", dataKey: "uom" },
            { header: "GST(%)", dataKey: "tax" },
            { header: `GST Amt. (${dndata.currency})`, dataKey: "taxamount" },
            { header: `Total (${dndata.currency})`, dataKey: "totalpricewtax" },
          ];
        } else {
          tablecolp = [
            { header: "Item", dataKey: "name" },
            { header: `Unit Price (${dndata.currency})`, dataKey: "price" },
            { header: "Quantity", dataKey: "quantity" },
            { header: "UOM", dataKey: "uom" },
            { header: "VAT(%)", dataKey: "tax" },
            { header: `VAT Amt. (${dndata.currency})`, dataKey: "taxamount" },
            { header: `Total (${dndata.currency})`, dataKey: "totalpricewtax" },
          ];
        }
      }
    } else {
      tablecolp = [
        { header: "Item", dataKey: "name" },
        { header: `Unit Price (${dndata.currency})`, dataKey: "price" },
        { header: "Quantity", dataKey: "quantity" },
        { header: "UOM", dataKey: "uom" },
        { header: `Total (${dndata.currency})`, dataKey: "totalpricewtax" },
      ];
    }

    //  dndata.deliveryitems.find(e=>e).totalpricewtax =
    let order = "Delivery/Shipment Note";
    // let context1 = "Context : The Delivery Note will be referenced throughout the transaction process by both buyer and seller."
    let date = moment(this.state.createdAt).format("DD - MM - yyyy");
    let declaration =
      "Declaration: We declare that the invoice shows the acctual price of the goods described and the particulers are true and correct.";

    const toWords = new ToWords();
    var totalinwords = toWords.convert(dndata.totalamountwtax);
    const doc = new jsPDF("p", "pt", [842, 595]);
    let y = 0;

    doc.setFontSize(10);
    doc.text(`Delivery ID : ${dndata.customdnid}`, 560, 180, "right");
    doc.text(`Sale Order ID : ${dndata.customsoid}`, 560, 195, "right");
    doc.text(`Tax Invoice ID : ${dndata.invoice}`, 560, 210, "right");
    doc.text(`Date : ${date}`, 560, 225, "right");
    doc.text(`Transporter ID : ${dndata.transporterid}`, 560, 240, "right");
    doc.text(`Vehicle no. : ${dndata.vehiclenum}`, 560, 255, "right");
    doc.text(`Supply Type : ${dndata.gsttype}`, 560, 270, "right");

    doc.text("Shipping address,", 40, 180);
    doc.text(`${dndata.name}`, 50, 195);
    doc.text(`${dndata.houseno},${dndata.areaname}`, 50, 210);
    doc.text(`${dndata.city}, ${dndata.state}`, 50, 225);
    doc.text(`${dndata.country} - ${dndata.pincode}`, 50, 240);
    // doc.text(`${dndata.custemail}`, 50, 255)
    doc.text(`${dndata.phoneno}`, 50, 255);

    let subject = doc.splitTextToSize(this.state.templete.subject, 510);
    doc.text(subject, 40, 300);

    let context = doc.splitTextToSize(this.state.templete.content, 490);
    doc.text(context, 60, 350);
    autoTable(doc, {
      theme: "grid",
      startY: 400,
      columnStyles: { 1: { halign: "right" }, 6: { halign: "right" } },
      body: data,
      columns: tablecolp,
      didDrawPage: (HookData) => {
        HookData.settings.margin.top = 180;
      },
    });
    // doc.text(`Tax amount : ${dndata}`,360, doc.autoTable.previous.finalY + 20)
    // doc.text(`,460, doc.autoTable.previous.finalY + 20)
    y = doc.autoTable.previous.finalY + 15;
    if (y > 841) {
      doc.addPage([595.28, 841.89]);
      y = 180;
    }
    doc.text(`Total (${dndata.currency}) :`, 480, y, "right");
    doc.text(`${total}`, 550, y, "right");
    if (dndata.taxtype === "GST" && ccountry === "India") {
      if (dndata.gsttype === "INTRASTATE") {
        y = y + 15;
        if (y > 841) {
          doc.addPage([595.28, 841.89]);
          y = 180;
        }
        doc.text(`SGST (${dndata.currency}) :`, 480, y, "right");
        doc.text(`${taxamount / 2}`, 550, y, "right");
        y = y + 15;
        if (y > 841) {
          doc.addPage([595.28, 841.89]);
          y = 180;
        }
        doc.text(`CGST (${dndata.currency}) :`, 480, y, "right");
        doc.text(`${taxamount / 2}`, 550, y, "right");
      } else {
        y = y + 15;
        if (y > 841) {
          doc.addPage([595.28, 841.89]);
          y = 180;
        }
        doc.text(`IGST (${dndata.currency}) :`, 480, y, "right");
        doc.text(`${taxamount}`, 550, y, "right");
      }
    } else {
      if (dndata.taxtype === "GST") {
        y = y + 15;
        if (y > 841) {
          doc.addPage([595.28, 841.89]);
          y = 180;
        }
        doc.text(`GST (${dndata.currency}) :`, 480, y, "right");
        doc.text(`${taxamount}`, 550, y, "right");
      } else {
        y = y + 15;
        if (y > 841) {
          doc.addPage([595.28, 841.89]);
          y = 180;
        }
        doc.text(`VAT (${dndata.currency}) :`, 480, y, "right");
        doc.text(`${taxamount}`, 550, y, "right");
      }
    }
    y = y + 15;
    if (y > 841) {
      doc.addPage([595.28, 841.89]);
      y = 180;
    }
    doc.text(`Grand Total (${dndata.currency}) :`, 480, y, "right");
    doc.text(`${grandtotal}`, 550, y, "right");
    y = y + 15;
    if (y > 841) {
      doc.addPage([595.28, 841.89]);
      y = 180;
    }
    doc.text(`Grand Total in words : ${totalinwords}`, 40, y);
    // doc.text(`Payments Terms : ${paymentterms} Days`,40, doc.autoTable.previous.finalY + 75)
    y = y + 30;
    if (y + 30 > 841) {
      doc.addPage([595.28, 841.89]);
      y = 180;
    }
    let declaration1 = doc.splitTextToSize(declaration, 510);
    doc.text(declaration1, 40, y);
    y = y + 50;
    if (y + 45 > 841) {
      doc.addPage([595.28, 841.89]);
      y = 180;
    }
    doc.text("Sd/-.", 40, y + 15);
    doc.text("Sales Team,", 40, y + 30);
    doc.text(`${this.state.templete.signature}`, 40, y + 45);

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;
      doc.setFontSize(8);
      doc.text(`${curdate}`, 560, 20, "right");
      doc.addImage(logo, "GIF", 40, 20, 100, 100);
      // doc.addImage(qr,"JPG",200,20,70,70)
      doc.setFontSize(14);
      doc.text(`${clientname}`, 560, 40, "right");
      doc.setFontSize(10);
      doc.text(`${address}, ${address2}`, 560, 55, "right");
      doc.text(`${ccity}, ${cstate}`, 560, 70, "right");
      doc.text(`${ccountry} - ${czip}`, 560, 85, "right");
      doc.text(`${companyemail}`, 560, 100, "right");
      doc.text(`${contactnumber}`, 560, 115, "right");
      doc.text(`${gstin}`, 560, 130, "right");
      doc.setFontSize(15);
      doc.text(`${order}`, 200, 140);
      doc.line(0, 150, 600, 150);

      doc.setFontSize(10);
      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }

    doc.save(`${dndata.customdnid}.pdf`);
  }

  selectFile = (event) => {
    this.setState({
      selectedFiles: event.target.files,
    });
  };

  upload() {
    let currentFile = this.state.selectedFiles[0];

    this.setState({ progress: 100, currentFile: currentFile });

    UploadFilesService.upload(
      currentFile,
      year,
      curmonth,
      doctype,
      this.state.partyid,
      day
      // (event) => {
      //   this.setState({
      //     progress: Math.round((100 * event.loaded) / event.total),
      //   });
      //   progress = Math.round((100 * event.loaded) / event.total);
      // }
    )
      .then((response) => {
        this.setState({
          message: response.data.message,
          isError: false,
        });
        return FileServices.getFiles1(currentFile.name);
      })
      .then((files) => {
        this.setState({ fileInfos: files.data });
      })

      .catch(() => {
        this.setState({
          progress: 0,
          message: "Could not upload the file!",
          currentFile: undefined,
          isError: true,
        });
      });

    this.setState({ selectedFiles: undefined });
  }

  handleSendMail = () => {
    if (this.state.fileInfos.length === 0) {
      alert("Please Upload File");
    } else {
      let filedata = this.state.fileInfos.map((e) => e.name);
      path =
        prefix1 +
        "/" +
        doctype +
        "/" +
        this.state.partyid +
        "/" +
        year +
        "/" +
        curmonth +
        "/" +
        day;
      let data = {
        file: filedata.toLocaleString(),
        path: path,
        poid: this.state.id,
      };
      SalesServices.UploadPo(data).then(() =>
        this.setState({
          show: false,
          selectedFiles: undefined,
          currentFile: undefined,
          progress: 0,
          message: "",
          isError: false,
          fileInfos: [],
          fileInfo: "",
          partyid: "",
          error: false,
        })
      );
    }
  };



  handleApprove= () => {
  let data = {
    id:this.state.dndata.dnid,
    updatedby:userid
  }
  Swal.fire({
    title: "Approve Delivery Note",
    text: "Are you sure?",
    icon: "question",
    allowOutsideClick: false,
    showCancelButton: true,
    confirmButtonText: "Yes",
    confirmButtonColor: "#152f5f",
    cancelButtonText: "No",
    reverseButtons: true,
  }).then((result) => {
    if (result.value) {
      this.setState({loader:true})
  // this.setState({loading:true})
    SalesServices.ApproveDeliveryNote(data).then(()=>{
    this.setState({ loader: false })
    Swal.fire({
      title: "Delivery Note Approved successfully",
      text: ".",
      icon: "success",
      confirmButtonText: "Ok",
      confirmButtonColor: "#152f5f",
    }).then(() =>this.Refresh())})
    .catch(()=>{
    this.setState({loader:false})
    Swal.fire({
      title: "Error occurred, please try again",
      text: ".",
      icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#152f5f",
    });
    if (e.response.data.error === "Unauthorized") {
      alert("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
  })
  }
})
}

Refresh = () => {
  const { id } = this.props.location.state;
  if(this.props.location.state.soid !== null){
  SalesServices.GetDnData(id)
    .then((response) => {
      this.setState({
        dndata: response.data,
        dnitems: response.data.deliveryitems,
        precloseitems: response.data.precloseitems,
        loading: false 
      });
    })
    .catch((e) => {
      if (e.response.data.error === "Unauthorized") {
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
      }
    });
  }else{
    SalesServices.GetDirectDnData(id)
    .then((response) => {
      this.setState({
        dndata: response.data,
        dnitems: response.data.deliveryitems,
        precloseitems: response.data.precloseitems,
        loading: false 
      });
    })
    .catch((e) => {
      if (e.response.data.error === "Unauthorized") {
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
      }
    });

  }

  let data = "Delivery Note";
  SettingsServices.GetActivePdfTemplate(data, currentTenant).then(
    (response) => this.setState({ templete: response.data })
  );
}
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    
    this.setState({ loading: true });
    this.Refresh()

    year = new Date().getFullYear();
    day = new Date().getDate();
    let mn = new Date().getMonth() + 1;
    curmonth = moment(mn, "M").format("MMMM");
  }

  render() {
    let { selectedFiles, message, isError } = this.state;
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <span
                className="text-secondary cursorPointer"
                onClick={() => this.props.location.state.goback === undefined?this.props.navigate(-1):this.props.navigate("/deliverynote")}
              >
                ❮ Back &nbsp;&nbsp;
              </span>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/deliverynote">Delivery Note</Link>
              </li>
              <li className="breadcrumb-item active"> Delivery Note</li>
            </ol>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Delivery Note</h3>
                        {this.state.loading ===true ?'':<>
                        {this.state.dndata.status === "Pending" ? (
                          
                            <button
                              className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-envelope text-md ml-1"
                              onClick={() => this.handleShow()}
                            ></button>
                             ) : (
                          ""
                        )}
                        </>}
                        
                            <button
                              className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md"
                              onClick={() =>
                                this.handleDownload(this.state.dndata)
                              }
                            ></button>
                      </div>
                      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

                      <div className="card-body">
                      {this.state.loading ===true ?<div className="text-center">{spin}</div> :
                        <div>
                          {this.state.loading === true ? (
                            <div className="text-center">{spin}</div>
                          ) : (
                            <>
                              <div className="form-row">
                                <div className="form-column col-md-6">
                                  <table className="float-left mb-3">
                                    <tbody>
                                      <tr>
                                        <td>DN&nbsp;Id</td>
                                        <td>
                                          :&nbsp;{this.state.dndata.customdnid}
                                        </td>
                                      </tr>
                                      {this.state.dndata.customsoid===null?'':
                                      <tr>
                                        <td>Order&nbsp;Id</td>
                                        <td>
                                          :&nbsp;
                                          <Link
                                            to={{pathname: "/saleorderdetails"}}
                                              state={{
                                                id: this.state.dndata.soid,
                                                bvstatus: 0,
                                            }}
                                          >
                                            {this.state.dndata.customsoid}
                                          </Link>
                                        </td>
                                      </tr>
                                        }
                                      <tr>
                                        <td>Tax Invoice Id</td>
                                        <td>
                                          :&nbsp;
                                          <Link
                                            to={{pathname: "/viewsalesinvoice"}}
                                              state={ {
                                                id: this.state.dndata.invid,
                                                pagename: "Tax Invoice Details",
                                                type: "Tax Invoice"
                                            }}
                                          >
                                            {this.state.dndata.invoice}
                                          </Link>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Created By</td>
                                        <td>
                                          :&nbsp;
                                          {this.state.dndata.createdbyname}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Customer</td>
                                        <td data-tip data-for="custphno">
                                          :&nbsp;&nbsp;
                                          {this.state.dndata.customer}
                                        </td>
                                        {/* //<Tooltip
                                          id="custphno"
                                          place="top"
                                          effect="solid"
                                        >
                                          Customer Ph.{" "}
                                          {this.state.dndata.custcontactnumber}
                                        </Tooltip> */}
                                      </tr>
                                      {this.state.dndata.agent === null ||
                                      this.state.dndata.agent === undefined ||
                                      this.state.dndata.agent === "" ? (
                                        ""
                                      ) : (
                                        <tr>
                                          <td>Agent</td>
                                          <td data-tip data-for="agentphno">
                                            :&nbsp;&nbsp;
                                            {this.state.dndata.agent}
                                          </td>
                                          {/* //<Tooltip
                                            id="agentphno"
                                            place="top"
                                            effect="solid"
                                          >
                                            Agent Ph.{" "}
                                            {this.state.dndata.agentphno}
                                          </Tooltip> */}
                                        </tr>
                                      )}
                                      <tr>
                                        <td>Invoice Date</td>
                                        <td>
                                          :&nbsp;
                                          {moment(
                                            this.state.dndata.createdAt
                                          ).format("DD-MM-yyyy")}
                                        </td>
                                      </tr>
                                      {this.state.dndata.sotype ===
                                      "Services" ? (
                                        ""
                                      ) : (
                                        <tr>
                                          <td>Org Unit</td>
                                          <td>
                                            :&nbsp;{this.state.dndata.warehouse}
                                          </td>
                                        </tr>
                                      )}
                                      {this.state.dnitems.length > 0 ? (
                                        <tr>
                                          <td>Total Amount&nbsp;</td>
                                          <td>
                                            :&nbsp;
                                            <NumericFormat
                                              className="pr-3"
                                              displayType={"text"}
                                              value={
                                                this.state.dndata
                                                  .totalamountwtax
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                      {this.state.precloseitems.length > 0 ? (
                                        <tr>
                                          <td>Total Preclose Amount&nbsp;</td>
                                          <td>
                                            :&nbsp;
                                            <NumericFormat
                                              className="pr-3"
                                              displayType={"text"}
                                              value={
                                                this.state.dndata
                                                  .ptotalamountwtax
                                              }
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                      <tr>
                                        <td>Tax Type</td>
                                        <td>
                                          :&nbsp;{this.state.dndata.taxtype}
                                        </td>
                                      </tr>
                                      {this.state.dndata.taxtype !== "NA" ? (
                                        <tr>
                                          <td>GST Type</td>
                                          <td>
                                            :&nbsp;{this.state.dndata.gsttype}
                                          </td>
                                        </tr>
                                      ) : (
                                        ""
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                                <div className="form-group col-md-6">
                                  <table className="float-left mb-3">
                                    <tbody>
                                      <tr>
                                        <td>Name</td>
                                        <td>:&nbsp;{this.state.dndata.name}</td>
                                      </tr>
                                      <tr>
                                        <td>Address</td>
                                        <td>
                                          :&nbsp;{this.state.dndata.houseno},
                                          {this.state.dndata.areaname},
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>
                                          &nbsp;&nbsp;{this.state.dndata.city},
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>&nbsp;</td>
                                        <td>
                                          &nbsp;&nbsp;{this.state.dndata.state}-
                                          {this.state.dndata.pincode}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td></td>
                                        <td>
                                          &nbsp;&nbsp;
                                          {this.state.dndata.country},
                                        </td>
                                      </tr>
                                      {/* <tr>
                                        <td>Transporter ID&nbsp;</td>
                                        <td>
                                          :&nbsp;
                                          {this.state.dndata.transporterid}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Vehicle no.</td>
                                        <td>
                                          :&nbsp;{this.state.dndata.vehiclenum}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Distance</td>
                                        <td>
                                          :&nbsp;{this.state.dndata.distance} km
                                        </td>
                                      </tr> */}
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <div className="form-group col-md-12"></div>

                              {this.state.dnitems.length > 0 ? (
                                <TableContainer className="mt-5">
                                  <Typography>Delivered Items List</Typography>
                                  <Table
                                    aria-label="collapsible table"
                                    className="table-sm"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell />
                                        <TableCell className="text-center">
                                          Item Name
                                        </TableCell>
                                        {this.state.dndata.taxtype === "NA" ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            HSN
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Price
                                        </TableCell>
                                        {currency ===
                                        this.state.dndata.currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Price({this.state.dndata.currency})
                                          </TableCell>
                                        )}
                                        {currency ===
                                        this.state.dndata.currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Price Variance (%)
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Type
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Quantity
                                        </TableCell>
                                        <TableCell className="text-center">
                                          UOM
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Discount
                                        </TableCell>
                                        {this.state.dndata.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Discount (
                                            {this.state.dndata.currency})
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Insurance
                                        </TableCell>
                                        {this.state.dndata.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Insurance (
                                            {this.state.dndata.currency})
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Total{" "}
                                        </TableCell>
                                        {this.state.dndata.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Total ({this.state.dndata.currency})
                                          </TableCell>
                                        )}
                                        {this.state.dndata.taxtype === "NA" ? (
                                          ""
                                        ) : (
                                          <>
                                            {this.state.dndata.taxtype ===
                                              "GST" && ccountry === "India" ? (
                                              this.state.dndata.gsttype ===
                                              "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    CGST
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    SGST
                                                  </TableCell>
                                                  {this.state.dndata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <>
                                                      {" "}
                                                      <TableCell className="text-center">
                                                        CGST(
                                                        {
                                                          this.state.dndata
                                                            .currency
                                                        }
                                                        )
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                        SGST(
                                                        {
                                                          this.state.dndata
                                                            .currency
                                                        }
                                                        )
                                                      </TableCell>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <TableCell className="text-center">
                                                    IGST
                                                  </TableCell>
                                                  {this.state.dndata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-center">
                                                      IGST(
                                                      {
                                                        this.state.dndata
                                                          .currency
                                                      }
                                                      )
                                                    </TableCell>
                                                  )}
                                                </>
                                              )
                                            ) : this.state.dndata.taxtype ===
                                              "GST" ? (
                                              <>
                                                <TableCell className="text-center">
                                                  GST
                                                </TableCell>
                                                {this.state.dndata.currency ===
                                                currency ? (
                                                  ""
                                                ) : (
                                                  <TableCell className="text-center">
                                                    GST(
                                                    {this.state.dndata.currency}
                                                    )
                                                  </TableCell>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {" "}
                                                <TableCell className="text-center">
                                                  VAT
                                                </TableCell>
                                                {this.state.dndata.currency ===
                                                currency ? (
                                                  ""
                                                ) : (
                                                  <TableCell className="text-center">
                                                    VAT(
                                                    {this.state.dndata.currency}
                                                    )
                                                  </TableCell>
                                                )}
                                              </>
                                            )}
                                            <TableCell className="text-center">
                                              Total Price
                                            </TableCell>
                                            {this.state.dndata.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-center">
                                                Total Price(
                                                {this.state.dndata.currency})
                                              </TableCell>
                                            )}
                                          </>
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.dnitems.map((row, index) => (
                                        <React.Fragment key={index}>
                                          <TableRow >
                                            <TableCell>
                                              <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() =>
                                                  this.setState({
                                                    open: !this.state.open,
                                                  })
                                                }
                                              >
                                                {this.state.open ? (
                                                  <KeyboardArrowUpIcon />
                                                ) : (
                                                  <KeyboardArrowDownIcon />
                                                )}
                                              </IconButton>
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {row.name}
                                            </TableCell>
                                            {this.state.dndata.taxtype ===
                                            "NA" ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-center">
                                                {row.hsn}
                                              </TableCell>
                                            )}
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={row.price}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                            {currency ===
                                            this.state.dndata.currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    row.price *
                                                    this.state.dndata
                                                      .curconversionrate
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                            )}
                                            {currency ===
                                            this.state.dndata.currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-center">
                                                {row.pricevariance}
                                              </TableCell>
                                            )}
                                            <TableCell className="text-center">
                                              {row.salesenum}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {row.quantity}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {row.uom}
                                            </TableCell>
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={row.discountamount}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                              &nbsp;({row.discount}%)
                                            </TableCell>
                                            {this.state.dndata.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    row.discountamount *
                                                    this.state.dndata
                                                      .curconversionrate
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                                &nbsp;({row.discount}%)
                                              </TableCell>
                                            )}
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={row.insuranceamount}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                              &nbsp;({row.insurance}%)
                                            </TableCell>
                                            {this.state.dndata.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    row.insuranceamount *
                                                    this.state.dndata
                                                      .curconversionrate
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                                {row.insurance}%
                                              </TableCell>
                                            )}
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={row.totalprice}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                            {this.state.dndata.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={
                                                    row.totalprice *
                                                    this.state.dndata
                                                      .curconversionrate
                                                  }
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                            )}
                                            {this.state.dndata.taxtype ===
                                            "NA" ? (
                                              ""
                                            ) : (
                                              <>
                                                {this.state.dndata.taxtype ===
                                                  "GST" &&
                                                this.state.dndata.gsttype ===
                                                  "INTRASTATE" ? (
                                                  <>
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={
                                                          row.taxamount * 0.5
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                      &nbsp;({row.tax / 2}%)
                                                    </TableCell>
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={
                                                          row.taxamount / 2
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                      &nbsp;({row.tax / 2}%)
                                                    </TableCell>
                                                    {this.state.dndata
                                                      .currency === currency ? (
                                                      ""
                                                    ) : (
                                                      <>
                                                        <TableCell className="text-right">
                                                          <NumericFormat
                                                            displayType={"text"}
                                                            value={
                                                              (row.taxamount /
                                                                2) *
                                                              this.state.dndata
                                                                .curconversionrate
                                                            }
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                              true
                                                            }
                                                            disabled={true}
                                                            thousandSeparator={
                                                              true
                                                            }
                                                            thousandsGroupStyle={
                                                              thousandsdisplay
                                                            }
                                                          ></NumericFormat>
                                                          &nbsp;({row.tax / 2}%)
                                                        </TableCell>
                                                        <TableCell className="text-right">
                                                          <NumericFormat
                                                            displayType={"text"}
                                                            value={
                                                              (row.taxamount /
                                                                2) *
                                                              this.state.dndata
                                                                .curconversionrate
                                                            }
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                              true
                                                            }
                                                            disabled={true}
                                                            thousandSeparator={
                                                              true
                                                            }
                                                            thousandsGroupStyle={
                                                              thousandsdisplay
                                                            }
                                                          ></NumericFormat>
                                                          &nbsp;({row.tax / 2}%)
                                                        </TableCell>
                                                      </>
                                                    )}
                                                  </>
                                                ) : (
                                                  <>
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={row.taxamount}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                      &nbsp;({row.tax}%)
                                                    </TableCell>
                                                    {this.state.dndata
                                                      .currency === currency ? (
                                                      ""
                                                    ) : (
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            row.taxamount *
                                                            this.state.dndata
                                                              .curconversionrate
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                        &nbsp;({row.tax}%)
                                                      </TableCell>
                                                    )}
                                                  </>
                                                )}
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={row.totalpricewtax}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                                {this.state.dndata.currency ===
                                                currency ? (
                                                  ""
                                                ) : (
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        row.totalpricewtax *
                                                        this.state.dndata
                                                          .curconversionrate
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                )}
                                              </>
                                            )}
                                          </TableRow>
                                          <TableRow>
                                            <TableCell
                                              style={{
                                                paddingBottom: 0,
                                                paddingTop: 0,
                                              }}
                                              colSpan={6}
                                            >
                                              <Collapse
                                                in={this.state.open}
                                                timeout="auto"
                                                unmountOnExit
                                              >
                                                <Box margin={1}>
                                                  <Table
                                                    size="small"
                                                    aria-label="purchases"
                                                  >
                                                    <TableHead>
                                                      <TableRow>
                                                        <TableCell align="center">
                                                          Brand
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          Colour
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          Size
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          Thickness
                                                        </TableCell>
                                                        {/* <TableCell align="center">UOM</TableCell> */}
                                                        <TableCell align="center">
                                                          TotalMtrRoll
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          Description
                                                        </TableCell>
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      <TableRow>
                                                        <TableCell
                                                          component="th"
                                                          scope="row"
                                                          align="center"
                                                        >
                                                          {row.brand}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {row.colour}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {row.size}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {row.thickness}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {row.totalmtrroll}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                          {row.description}
                                                        </TableCell>
                                                      </TableRow>
                                                    </TableBody>
                                                  </Table>
                                                </Box>
                                              </Collapse>
                                            </TableCell>
                                          </TableRow>
                                        </React.Fragment>
                                      ))}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              ) : (
                                ""
                              )}
                              {this.state.precloseitems.length > 0 ? (
                                <TableContainer className="mt-5">
                                  <Typography>Preclose Items List</Typography>
                                  <Table
                                    aria-label="collapsible table"
                                    className="table-sm"
                                  >
                                    <TableHead>
                                      <TableRow>
                                        <TableCell />
                                        <TableCell className="text-center">
                                          Item Name
                                        </TableCell>
                                        {this.state.dndata.taxtype === "NA" ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            HSN
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Price
                                        </TableCell>
                                        {currency ===
                                        this.state.dndata.currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Price({this.state.dndata.currency})
                                          </TableCell>
                                        )}
                                        {currency ===
                                        this.state.dndata.currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Price Variance (%)
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Type
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Quantity
                                        </TableCell>
                                        <TableCell className="text-center">
                                          UOM
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Discount
                                        </TableCell>
                                        {this.state.dndata.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Discount (
                                            {this.state.dndata.currency})
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Insurance
                                        </TableCell>
                                        {this.state.dndata.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Insurance (
                                            {this.state.dndata.currency})
                                          </TableCell>
                                        )}
                                        <TableCell className="text-center">
                                          Total{" "}
                                        </TableCell>
                                        {this.state.dndata.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Total ({this.state.dndata.currency})
                                          </TableCell>
                                        )}
                                        {this.state.dndata.taxtype === "NA" ? (
                                          ""
                                        ) : (
                                          <>
                                            {this.state.dndata.taxtype ===
                                              "GST" && ccountry === "India" ? (
                                              this.state.dndata.gsttype ===
                                              "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    CGST
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    SGST
                                                  </TableCell>
                                                  {this.state.dndata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <>
                                                      {" "}
                                                      <TableCell className="text-center">
                                                        CGST(
                                                        {
                                                          this.state.dndata
                                                            .currency
                                                        }
                                                        )
                                                      </TableCell>
                                                      <TableCell className="text-center">
                                                        SGST(
                                                        {
                                                          this.state.dndata
                                                            .currency
                                                        }
                                                        )
                                                      </TableCell>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <TableCell className="text-center">
                                                    IGST
                                                  </TableCell>
                                                  {this.state.dndata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-center">
                                                      IGST(
                                                      {
                                                        this.state.dndata
                                                          .currency
                                                      }
                                                      )
                                                    </TableCell>
                                                  )}
                                                </>
                                              )
                                            ) : this.state.dndata.taxtype ===
                                              "GST" ? (
                                              <>
                                                <TableCell className="text-center">
                                                  GST
                                                </TableCell>
                                                {this.state.dndata.currency ===
                                                currency ? (
                                                  ""
                                                ) : (
                                                  <TableCell className="text-center">
                                                    GST(
                                                    {this.state.dndata.currency}
                                                    )
                                                  </TableCell>
                                                )}
                                              </>
                                            ) : (
                                              <>
                                                {" "}
                                                <TableCell className="text-center">
                                                  VAT
                                                </TableCell>
                                                {this.state.dndata.currency ===
                                                currency ? (
                                                  ""
                                                ) : (
                                                  <TableCell className="text-center">
                                                    VAT(
                                                    {this.state.dndata.currency}
                                                    )
                                                  </TableCell>
                                                )}
                                              </>
                                            )}
                                            <TableCell className="text-center">
                                              Total Price
                                            </TableCell>
                                            {this.state.dndata.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-center">
                                                Total Price(
                                                {this.state.dndata.currency})
                                              </TableCell>
                                            )}
                                          </>
                                        )}
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.precloseitems.map(
                                        (row, index) => (
                                          <React.Fragment  key={index}>
                                            <TableRow>
                                              <TableCell>
                                                <IconButton
                                                  aria-label="expand row"
                                                  size="small"
                                                  onClick={() =>
                                                    this.setState({
                                                      open: !this.state.open,
                                                    })
                                                  }
                                                >
                                                  {this.state.open ? (
                                                    <KeyboardArrowUpIcon />
                                                  ) : (
                                                    <KeyboardArrowDownIcon />
                                                  )}
                                                </IconButton>
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {row.name}
                                              </TableCell>
                                              {this.state.dndata.taxtype ===
                                              "NA" ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  {row.hsn}
                                                </TableCell>
                                              )}
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.price}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {currency ===
                                              this.state.dndata.currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.price *
                                                      this.state.dndata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                              {currency ===
                                              this.state.dndata.currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  {row.pricevariance}
                                                </TableCell>
                                              )}
                                              <TableCell className="text-center">
                                                {row.salesenum}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {row.quantity}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {row.uom}
                                              </TableCell>
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.discountamount}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                                &nbsp;({row.discount}%)
                                              </TableCell>
                                              {this.state.dndata.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.discountamount *
                                                      this.state.dndata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                  &nbsp;({row.discount}%)
                                                </TableCell>
                                              )}
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.insuranceamount}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                                &nbsp;({row.insurance}%)
                                              </TableCell>
                                              {this.state.dndata.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.insuranceamount *
                                                      this.state.dndata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                  {row.insurance}%
                                                </TableCell>
                                              )}
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.totalprice}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.dndata.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.totalprice *
                                                      this.state.dndata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                              {this.state.dndata.taxtype ===
                                              "NA" ? (
                                                ""
                                              ) : (
                                                <>
                                                  {this.state.dndata.taxtype ===
                                                    "GST" &&
                                                  this.state.dndata.gsttype ===
                                                    "INTRASTATE" ? (
                                                    <>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            row.taxamount * 0.5
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                        &nbsp;({row.tax / 2}%)
                                                      </TableCell>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            row.taxamount / 2
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                        &nbsp;({row.tax / 2}%)
                                                      </TableCell>
                                                      {this.state.dndata
                                                        .currency ===
                                                      currency ? (
                                                        ""
                                                      ) : (
                                                        <>
                                                          <TableCell className="text-right">
                                                            <NumericFormat
                                                              displayType={
                                                                "text"
                                                              }
                                                              value={
                                                                (row.taxamount /
                                                                  2) *
                                                                this.state
                                                                  .dndata
                                                                  .curconversionrate
                                                              }
                                                              decimalScale={2}
                                                              fixedDecimalScale={
                                                                true
                                                              }
                                                              disabled={true}
                                                              thousandSeparator={
                                                                true
                                                              }
                                                              thousandsGroupStyle={
                                                                thousandsdisplay
                                                              }
                                                            ></NumericFormat>
                                                            &nbsp;({row.tax / 2}
                                                            %)
                                                          </TableCell>
                                                          <TableCell className="text-right">
                                                            <NumericFormat
                                                              displayType={
                                                                "text"
                                                              }
                                                              value={
                                                                (row.taxamount /
                                                                  2) *
                                                                this.state
                                                                  .dndata
                                                                  .curconversionrate
                                                              }
                                                              decimalScale={2}
                                                              fixedDecimalScale={
                                                                true
                                                              }
                                                              disabled={true}
                                                              thousandSeparator={
                                                                true
                                                              }
                                                              thousandsGroupStyle={
                                                                thousandsdisplay
                                                              }
                                                            ></NumericFormat>
                                                            &nbsp;({row.tax / 2}
                                                            %)
                                                          </TableCell>
                                                        </>
                                                      )}
                                                    </>
                                                  ) : (
                                                    <>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={row.taxamount}
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                        &nbsp;({row.tax}%)
                                                      </TableCell>
                                                      {this.state.dndata
                                                        .currency ===
                                                      currency ? (
                                                        ""
                                                      ) : (
                                                        <TableCell className="text-right">
                                                          <NumericFormat
                                                            displayType={"text"}
                                                            value={
                                                              row.taxamount *
                                                              this.state.dndata
                                                                .curconversionrate
                                                            }
                                                            decimalScale={2}
                                                            fixedDecimalScale={
                                                              true
                                                            }
                                                            disabled={true}
                                                            thousandSeparator={
                                                              true
                                                            }
                                                            thousandsGroupStyle={
                                                              thousandsdisplay
                                                            }
                                                          ></NumericFormat>
                                                          &nbsp;({row.tax}%)
                                                        </TableCell>
                                                      )}
                                                    </>
                                                  )}
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={row.totalpricewtax}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  {this.state.dndata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={
                                                          row.totalpricewtax *
                                                          this.state.dndata
                                                            .curconversionrate
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    </TableCell>
                                                  )}
                                                </>
                                              )}
                                            </TableRow>
                                            <TableRow>
                                              <TableCell
                                                style={{
                                                  paddingBottom: 0,
                                                  paddingTop: 0,
                                                }}
                                                colSpan={6}
                                              >
                                                <Collapse
                                                  in={this.state.open}
                                                  timeout="auto"
                                                  unmountOnExit
                                                >
                                                  <Box margin={1}>
                                                    <Table
                                                      size="small"
                                                      aria-label="purchases"
                                                    >
                                                      <TableHead>
                                                        <TableRow>
                                                          <TableCell align="center">
                                                            Brand
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            Colour
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            Size
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            Thickness
                                                          </TableCell>
                                                          {/* <TableCell align="center">UOM</TableCell> */}
                                                          <TableCell align="center">
                                                            TotalMtrRoll
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            Description
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableHead>
                                                      <TableBody>
                                                        <TableRow>
                                                          <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                          >
                                                            {row.brand}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.colour}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.size}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.thickness}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.totalmtrroll}
                                                          </TableCell>
                                                          <TableCell align="center">
                                                            {row.description}
                                                          </TableCell>
                                                        </TableRow>
                                                      </TableBody>
                                                    </Table>
                                                  </Box>
                                                </Collapse>
                                              </TableCell>
                                            </TableRow>
                                          </React.Fragment>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              ) : (
                                ""
                              )}

                          {this.state.dndata.approvestatus === "Pending"?
                              <button className="btn btn-sm hovbuttonColour mr-1" onClick={this.handleApprove}>
                              <i className="fa fa-check  m-0" /> Approve
                             </button>
                              :''}
                            </>
                          )}

                          {/* E-mail modal */}
                          <Modal
                            show={this.state.show}
                            onHide={this.handleClose}
                            backdrop="static"
                            keyboard={false}
                            centered
                            size="lg"
                          >
                            <Modal.Header closeButton>
                              <b>Mail</b>
                            </Modal.Header>
                            <div className="form-row mt-5 ml-5">
                              <fieldset className="form-group col-md-4">
                                <label htmlFor="btn-upload">
                                  <input
                                    className="btn-choose"
                                    id="btn-upload"
                                    name="btn-upload"
                                    style={{ display: "none" }}
                                    type="file"
                                    onChange={this.selectFile}
                                  />

                                  <Button
                                    className="btn-close"
                                    variant="outlined"
                                    type="upload"
                                    component="span"
                                  >
                                    <span className="text-blue">
                                      Select File to Upload
                                    </span>
                                  </Button>
                                </label>
                              </fieldset>

                              <fieldset className="form-group col-md-3">
                                <Button
                                  className="btn-upload"
                                  color="primary"
                                  variant="contained"
                                  component="span"
                                  disabled={!selectedFiles}
                                  onClick={this.upload}
                                >
                                  Upload
                                </Button>
                              </fieldset>

                              <div className="form-group col-md-9">
                                <Typography
                                  variant="subtitle2"
                                  className={`upload-message ${
                                    isError ? "error" : ""
                                  }`}
                                >
                                  {message === "Could not upload the file!" ? (
                                    <span className="text-red">{message}</span>
                                  ) : (
                                    <span className="text-green">
                                      {message}
                                    </span>
                                  )}
                                  {/* <span className="text-red">{message}</span> } */}
                                </Typography>
                              </div>
                              <div className="form-group col-md-3" />

                              <fieldset className="form-group col-md-5">
                                <TextField
                                  className="form-control"
                                  type="email"
                                  name="mail1"
                                  value={this.state.mail1}
                                  label="Mail 1"
                                  variant="standard"
                                  fullWidth
                                />
                              </fieldset>

                              <fieldset className="form-group col-md-5">
                                <TextField
                                  className="form-control"
                                  type="email"
                                  name="mail2"
                                  value={this.state.mail2}
                                  label="Mail 2"
                                  variant="standard"
                                  fullWidth
                                />
                              </fieldset>

                              <div className="form-group col-md-9">
                                <button
                                  className="btn btn-sm btn-info float-center"
                                  onClick={this.handleSendMail}
                                >
                                  Send{" "}
                                  <i
                                    className="fa fa-paper-plane"
                                    aria-hidden="true"
                                  />
                                </button>
                              </div>
                            </div>

                            <Modal.Body></Modal.Body>
                          </Modal>
                        </div>
                      }
                      </div>
                    </div>

                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
            <Footer />
      </div>
    );
  }
}
export default withRouter(ViewDn);
