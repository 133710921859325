import React, { Suspense, lazy, useEffect, useState } from "react";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import Footer from "../Component/Footer";
import withRouter from "../withRouter";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Typography,
} from "@mui/material";
import { currentTenant } from "../Common/Credentials";
import NewJournalService from "../Accounts/NewJournalService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { spin } from "../Common/NewLoader";
const Tcsledger = lazy(()=>import("./PurchaseAutoJRE/Tcsledger"))
const Creditledger = lazy(() => import("./PurchaseAutoJRE/Creditledger"));
const Taxledger = lazy(() => import("./PurchaseAutoJRE/Taxledger"));
const Insuranceledger = lazy(() => import("./PurchaseAutoJRE/Insuranceledger"));

const PurchaseAutoJREsetting = (props) => {
  const [ledgerList, setLedgerList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(1);

  useEffect(() => {
    setLoading(true);
    NewJournalService.getAllLedgers(currentTenant).then((response) => {
      setLedgerList(response.data);
      setLoading(false);
    });
  }, []);

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card ">
          <ol className="breadcrumb float-sm-right">
            <span
              className=" text-secondary cursorPointer"
              onClick={() => props.navigate(-1)}
            >
              ❮ Back &nbsp;&nbsp;
            </span>
            <li className="breadcrumb-item text-secondary">
              <Link to="/settings">Home</Link>
            </li>
            <li className="breadcrumb-item active">Settings</li>
          </ol>
          <div className="">
            <div className="card-header">
              <h3 className="card-title text-secondary">
                <i className="fas fa-cog mr-2"></i>
               Purchase Auto JRE Settings
              </h3>
            </div>
            <div className="card-body">
              {loading?<>{spin}</>:<>
              <Accordion
                expanded={expanded === 1}
                onChange={() => setExpanded(1)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor: "#e8ecef" }}
                >
                  <Typography>Debit Ledgers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="my-2">
                    <Suspense fallback={"Loading..."}>
                      <Creditledger
                        ledgers={ledgerList.filter(
                          (e) => e.subheadid === "AH000010"
                        )}
                      />
                    </Suspense>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 2}
                onChange={() => setExpanded(2)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor: "#e8ecef" }}
                >
                  <Typography>Tax Ledgers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="my-2">
                    <Suspense fallback={"Loading..."}>
                      <Taxledger ledgers={ledgerList} />
                    </Suspense>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 3}
                onChange={() => setExpanded(3)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor: "#e8ecef" }}
                >
                  <Typography>Insurance Ledgers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="my-2">
                    <Suspense fallback={"Loading..."}>
                      <Insuranceledger ledgers={ledgerList} />
                    </Suspense>
                  </div>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === 4}
                onChange={() => setExpanded(4)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{ backgroundColor: "#e8ecef" }}
                >
                  <Typography>TCS Ledgers</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="my-2">
                    <Suspense fallback={"Loading..."}>
                      <Tcsledger ledgers={ledgerList} />
                    </Suspense>
                  </div>
                </AccordionDetails>
              </Accordion>
              </>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default new withRouter(PurchaseAutoJREsetting);
