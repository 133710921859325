import FileServices from "./FileServices";

class downloadfiles{

    download(path,filename){
        let formData = new FormData();

        formData.append("path", path);
        formData.append("filename", filename);

        FileServices.getonefile(formData) 
              .then((response) => {
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(
                  new Blob([response.data], { type: "application/octet-stream" })
                );
                link.download = filename;
            
                document.body.appendChild(link);
            
                link.click();
                setTimeout(function () {
                  window.URL.revokeObjectURL(link);
                }, 200);
              })
              // .catch((error) => {})
              .catch((e)=>{
                if(e.response.data.error ==="Unauthorized"){
                    alert("Your Session is Lost: Please login Back to resume");
                    this.props.navigate(`/`);
                }
            })
    }

}
export default new downloadfiles();
