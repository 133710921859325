import React from "react";
import moment from 'moment'
import pdf from "../../pdf/pdf";
import Menu from "../../Component/Menu";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { thousandsdisplay, userid } from "../../Common/Credentials";
import { ToWords } from "to-words";
import {NumericFormat} from "react-number-format";

class Printpdf extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      datalist: this.props.location.state.data,
    };
  }

  handlePrint(podata){
    let order = "Purchase Order"
    let context1 = "Context : The purchase order number will be referenced throughout the transaction process by both buyer and seller."
    let date = moment(podata.date).format("DD - MM - yyyy")
    let tablecolp = [{header:"Item",dataKey:'name'},{header:"HSN",dataKey:"hsn"},{header:"Unit Price",dataKey:"price"},{header:"Quantity",dataKey:"quantity"},{header:"Tax(%)",dataKey:"tax"},{header:"Total",dataKey:"totalpricewtax"}]
    let purchaseitem = podata.purchaseitem
    let servicelist = podata.servicelist
    let milestones = podata.servicelist.length === 0?[]:podata.servicelist.find(e=>e).milestones
    let tablecols =  [{header:"Service",dataKey:'service'},{header:"HSN/SAC",dataKey:"servicecode"},{header:"Tax (%)",dataKey:"servicetax"},{header:"Service Cost",dataKey:"servicecost"},{header:"Total",dataKey:"servicetotalwtax"}]
    let tablecolm = [{header:"Name",dataKey:'name'},{header:"Expected Date",dataKey:'expecteddate'},{header:"Percentage",dataKey:'percentage'},{header:"Amount",dataKey:'amount'},]
    let pototal = podata.pototalwtax
    pdf.downloadpdf(tablecolp,tablecols,tablecolm,purchaseitem,servicelist,milestones,order,date,context1,podata,pototal)
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
  }
  render() {
    return (
      <div>
        <Menu/>
        <Header/>
        <div className="content-wrapper bg-white">
        <div className="card-body">
        <div>  
         <button className="float-left btn btn-sm bg-white shadow-sm ml-2"  onClick={()=>this.props.navigate(-1)}>
            <i className="fas fa-arrow-left"></i> Back
        </button>
        </div>
        <div ref={el => (this.componentRef = el)}  id="print" className="text-center">
          Purchase Order        
          <hr />
          <div className="form-row col-md-12 d-flex">
          <div className="form-group col-md-6 text-left"><span style={{border:'1px solid black'}}>Logo</span></div>
            <div className="form-group col-md-6 " >
              <table className="float-right" id='date'>
                <thead></thead>
                <tbody>
                <tr className="text-right">
                  <td className="text-right">Date : </td>
                  <td>&nbsp;{moment(this.state.datalist.date).format("DD - MM - yyyy")}</td>
                </tr>
                <tr>
                  <td>Order ID : </td>
                  <td className="text-left">&nbsp;{this.state.datalist.id}</td>
                </tr>
                </tbody>
                
              </table>
              {/* <div className="float-right" >
              <label className="text-right text-bold">Date :</label>
              <div>&nbsp;{moment(this.state.datalist.date).format("DD - MM - yyyy")}</div>
              </div>
              <div className="float-right">
              <label className="text-right text-bold">Order Id :</label> 
              <label>&nbsp;{this.state.datalist.id}</label>
              </div> */}
              </div>
              <div className="form-group col-md-12 text-left mb-0">
                To,
              </div>
              <div  className="form-group col-md-12 text-left mb-3">
              &nbsp;{this.state.datalist.supplier}
              <div>&nbsp;{this.state.datalist.supaddress2}</div>
              </div>
              <div className="form-group col-md-12 text-left mt-0">
                <span  id ="context" >
                  Context : The purchase order number will be referenced throughout the transaction process by both buyer and seller.
                </span>

                </div>

          </div>
          <div  className="form-group col-md-12 text-left mt-2">
          {this.state.datalist.purchaseitem === [] || this.state.datalist.purchaseitem.length === 0 ?'':
            <TableContainer>
              <Typography>Products</Typography>
              <Table className="table-sm">
              <TableHead >
                <TableRow >
                  <TableCell className="text-center">Sl.No</TableCell>
                  <TableCell className="text-center">Item Name</TableCell>
                  <TableCell className="text-center">HSN Code</TableCell>
                  <TableCell className="text-center">Price</TableCell>
                  <TableCell className="text-center">Quantity</TableCell>
                  <TableCell className="text-center">Tax(%)</TableCell>
                  <TableCell className="text-center">Tax Amount</TableCell>
                  <TableCell className="text-center">TotalPrice</TableCell>
                </TableRow>
              </TableHead>
              <TableBody> 
                {
                  this.state.datalist.purchaseitem.map((data, index) => (
                    <TableRow key={index}>
                      <TableCell className="text-center">{index +1}</TableCell>
                      <TableCell className="text-center">{data.name}</TableCell>
                      <TableCell className="text-center">{data.hsn}</TableCell>
                      <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                      <TableCell className="text-center">{data.quantity}</TableCell>
                      <TableCell className="text-center">{data.tax}</TableCell>
                      <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.taxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                      <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.totalpricewtax} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                    </TableRow>
                  ))
                }
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className="text-center">Total</TableCell>
                  <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={this.state.datalist.totaltaxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                  <TableCell className="text-right">
                    <NumericFormat className="pr-3" displayType={'text'} value={this.state.datalist.grandproducttotal} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                  </TableCell>
                </TableRow>
                <TableRow></TableRow>
              </TableBody>
            </Table>  
            </TableContainer>
            }
            {this.state.datalist.servicelist ===[] || this.state.datalist.servicelist.length === 0 ?'':<>
              <TableContainer> 
              <Typography>Services</Typography>
              <Table className="table-sm">
                <TableHead className="text-sm">
                  <TableRow style={{ textAlign: "center" }}>
                    <TableCell className="text-center">Sl. No.</TableCell>
                    <TableCell className="text-center">Service</TableCell>
                    <TableCell className="text-center">HSN/SAC Code</TableCell>
                    <TableCell className="text-center">Price</TableCell>
                    <TableCell className="text-center">Tax (%)</TableCell>
                    <TableCell className="text-center">TotalPrice</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="text-sm">
                  {this.state.datalist.servicelist.map((data, index) => (
                    <TableRow key={index} className="text-center">
                      <TableCell className="text-center">{index + 1}</TableCell>
                      <TableCell className="text-center">{data.service}</TableCell>
                      <TableCell className="text-center">{data.servicecode}</TableCell>
                      <TableCell className="text-right"> <NumericFormat className="pr-3" displayType={'text'} value= {data.servicecost} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                      <TableCell className="text-center">{data.servicetax} %</TableCell>
                      <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.servicetotalwtax} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                    </TableRow>
                  ))}
                  <TableRow className="text-center">
                    <TableCell/><TableCell/><TableCell/><TableCell/>
                    <TableCell className="text-center"  >Total</TableCell>
                    <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value= {this.state.datalist.grandservicetotal} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              </TableContainer> 
              {this.state.datalist.servicelist.find(e=>e).milestones.length === 0?'':
                            <TableContainer>
                              <Typography>Milestones</Typography>
                              <Table className="table-sm mt-4">
                                <TableHead className="text-sm">
                                  <TableRow style={{textAlign: "center"}}>
                                    <TableCell className="text-center">Name</TableCell>
                                    <TableCell className="text-center">Expected Date</TableCell>
                                    <TableCell className="text-center">Percentage</TableCell>
                                    <TableCell className="text-center">Amount</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                {this.state.datalist.servicelist.find(e=>e).milestones.map((data,index) => (
                                  <TableRow key={index}>
                                    <TableCell className="text-center">{data.name}</TableCell>
                                    <TableCell className="text-center">{moment(data.expecteddate).format('DD-MM-YYYY')}</TableCell>
                                    <TableCell className="text-center">{data.percentage}</TableCell>
                                    <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.amount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                  </TableRow>
                                ))}
                                </TableBody>
                              </Table>
                            </TableContainer>     
                          }
            </>  }
            </div> 
            <table className="table-sm float-left text-left m-0">
              <tr>
                <td>Total</td>
                <td>:&nbsp;<NumericFormat className="pr-3" displayType={'text'} value={this.state.datalist.pototalwtax} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
              </tr>
              <tr>
                <td>Total in words</td>
                <td>:&nbsp;{new ToWords().convert(this.state.datalist.pototalwtax)}</td>
              </tr> 
              <tr>
                <td>Advance Amount</td>
                <td>:&nbsp;<NumericFormat className="pr-3" displayType={'text'} value={this.state.datalist.advanceamountreq} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
              </tr> 
              <tr>
                <td>Payment Terms</td>
                <td>:&nbsp;&nbsp;{" "}{this.state.datalist.paymentterms}</td>
              </tr>
            </table>
            <div className="form-row col-md-12 text-left mb-0">  </div>
  
          <button className="btn btn-info btn-sm" onClick={()=>this.handlePrint(this.state.datalist)}>Print</button>
      
          </div>
          </div>
          </div>
          <Footer/>
      </div>
    );
  }
}

export default Printpdf