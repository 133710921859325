import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  clientname,
  currentTenant,
  logo,
  userid,
} from "../../Common/Credentials.js";
import { spin } from "../../Common/NewLoader.jsx";
import { inventoryid } from "../../Common/Permissions.js";
import InventoryService from "../InventoryService.js";
import MUIDataTable from "mui-datatables";
import CustomToolbar from "../../CommonComponents/CustomToolbar.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { ThemeProvider } from "@mui/material";
import { createTheme, IconButton, Tooltip } from "@mui/material";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable"
import moment from "moment";
import Swal from "sweetalert2";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});
class ServiceDataTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      services: [],
      tenantdetails: {},
      message: "",
      loading: false,
    };
    this.refreshServices = this.refreshServices.bind(this);
  }

  
  pdf() {
    const curdate = moment().format('DD-MM-YYYY HH:mm:ss')
    const columns = [{header:"Service",dataKey:'service'},{header:"HSN/SAC Code",dataKey:'servicecode'},{header:"Service Tax(%)",dataKey:'servicetax'},{header:"Category",dataKey:"category"},{header:"Service Description",dataKey:"description"}]
    var doc = new jsPDF("p", "pt", [842, 595]);
    autoTable(doc,{
      theme:'grid',
      startY: 120,
      columnStyles: { 7: { halign: "right" } },
      didDrawPage: (HookData) => {
        HookData.settings.margin.top = 120;
      },
      body: this.state.services,
      columns:columns,
    });

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;

      doc.addImage(logo, "GIF", 40, 20, 60, 60, { baseline: "top" });
      doc.setFontSize(8);
      doc.text(`${curdate}`,560,20,'right')
      doc.setFontSize(16);
      doc.text(`${clientname}`, 180, 40, { baseline: "top" });
      doc.setFontSize(14);
      doc.text(`Services`, 210, 70, { baseline: "top" });
      doc.line(0, 90, 600, 90);
      doc.setFontSize(10);

      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }
    doc.save("Services.pdf");
  }


  deleteService(id) {
    Swal.fire({
      title: `Are you sure you want to delete this Service?`,
      text: ".",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor:'#152f5f',
      cancelButtonText: "No",
    }).then((result)=>{
    if(result.value) {
    InventoryService.deleteService(id).then((resposne) => {
      Swal.fire({
        title: `Service deleted Successfull`,
        text: ".",
        icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
    }).then(()=>{
      this.setState({ message: `Service deleted Successfull`, loading: true });
      this.refreshServices();
    })
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          Swal.fire({
            title: `Your Session is Lost: Please login Back to resume`,
            text: ".",
            icon: "error",
            confirmButtonText: "Ok",
            confirmButtonColor: "#152f5f",
        }).then(()=>this.props.navigate(`/`))
      }else{
        Swal.fire({
          title: `Error occured while deleting`,
          text: ".",
          icon: "error",
          confirmButtonText: "Ok",
          confirmButtonColor: "#152f5f",
      })
      }
  })
  }
  })
  }

  refreshServices() {
    InventoryService.getServices(currentTenant).then((res) => {
      this.setState({ services: res.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1), loading: false });
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
    InventoryService.GetTenantdetails(currentTenant).then((res) =>
      this.setState({ tenantdetails: res.data.country })
    );
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });

    this.refreshServices();

    // if (this.props.location.state !== undefined) {
    //   this.setState({ message: this.props.location.state.message });
    // }
  }

  render() {
    const columns2 = [
      {
        name: "id",
        label: "Service Id",
        options: {
          filter: true,
          sort: true,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "service",
        label: "Service",
        options: { filter: true, sort: true, sortOrder: "dec" },
      },
      {
        name: "servicecode",
        label: "HSN/SAC Code",
        options: { filter: false, sort: true },
      },
      {
        name: "servicetax",
        label: "Service Tax (%)",
        options: { filter: false, sort: true,
          customBodyRender: (value) => {
            return(<>{value}%</>)
          }
        },
      },
      {
        name: "mcategory",
        label: "Category",
        options: { filter: true, sort: true },
      },
      {
        name: "categorylvl2",
        label: "Category level 2",
        options: { filter: true, sort: true },
      },
      {
        name: "category",
        label: "Category level 3",
        options: { filter: true, sort: true },
      },
      
      {
        name: "description",
        label: "Service Description",
        options: { filter: false, sort: false },
      },
      {
        name: "Delete",
        options: {
          filter: false,
          sort: false,
          empty: true,
          display: inventoryid === "2" || inventoryid === "3" ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <span
                className="fa fa-trash text-danger cursorPointer"
                onClick={(e) =>this.deleteService(tableMeta.rowData.find((e) => e))}
              ></span>
            );
          },
        },
      },
    ];

    const options2 = {
      filterType: "checkbox",
      selectableRows: "none",
      print:false,
      customToolbar: () => {
        return (
          <>
            {inventoryid === "2" || inventoryid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{pathname: "/newservices"}}
                  state= {{ id: -1, pagename: "New Service"}}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
             <span as="button" onClick={this.pdf.bind(this)}>
             <React.Fragment>
        <Tooltip title={"pdf"}>
          <IconButton>
          <i className="fa fa-file-pdf"></i>
          </IconButton>
        </Tooltip>
      </React.Fragment>

             </span>
          </>
        );
      },
    };
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory" className="text-info">
                  Home
                </Link>
              </li>

              <li className="breadcrumb-item active">
               Services
              </li>
            </ol>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    {/* /.card */}

                    <div className="card">
                        {/* <div className="card-header m-0 p-2">
                          <div className="text-center text-green">
                            {this.state.message}
                          </div>
                        </div> */}

                      <div className="card-body p-0">
                        <div>
                          {this.state.loading === true ? (
                            <div className="text-center">{spin}</div>
                          ) : (
                            <ThemeProvider theme={theme}> 
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Service"}
                              data={this.state.services}
                              columns={columns2}
                              options={options2}
                            />
                            </ThemeProvider>
                          )}
                        </div>
                      </div>
                    </div>

                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(ServiceDataTable);
