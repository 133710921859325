import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../../withRouter.js";
import { userid } from "../../../Common/Credentials.js";
import { appmasterid } from "../../../Common/Permissions.js";
import CustomToolbar from "../../../CommonComponents/CustomToolbar.js";
import Header from "../../../Component/Header.js";
import Menu from "../../../Component/Menu.js";
import UserService from "../../../users/UserService.js";
import CategoryServices from "../CategoryServices.js";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});
class Categorylvl2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      mcategory:'',
      mcatid:this.props.location.state=== undefined?'':this.props.location.state.id,
      categorylvltwo:[],
      categorytype:'',
      loading: false,
    };
    this.deletecategory = this.deletecategory.bind(this);
  }

  deletecategory(id) {
    CategoryServices.deleteCategorylvl2(id).then(
        ()=>this.props.navigate(`/category`)
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    UserService.UserPermissions(userid).then((response) =>
      this.setState({ up: response.data })
    );

    CategoryServices.getMainCategoryById(this.props.location.state.id).then(res=>{
      this.setState({mcategory:res.data.category,categorytype:res.data.categorytype,categorylvltwo:res.data.categorylvltwo})
    })
  }

  render() {
    const columns = [
      {
        name: "id",
        label: "Id",
        options: {
          filter: false,
          sort: true,
          display: false,
          viewColumns: false,
          sortOrder: "asc",
        },
      },
      {
        name: "name",
        label: "Category",
        options: { filter: true, sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <span>
                      {this.state.mcategory}
                  </span>)
             },
        },
      },
      {
        name: "name",
        label: "Category lvl 2",
        options: { filter: true, sort: true },
      },
      {
        name: "Action",
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
              <Link
                as="button"
                className="btn btn-sm hovbuttonColour mr-1"
                to={{pathname: "/categorylvl3"}} state={{categorylvl2:this.state.categorylvltwo.find(e=>e.id===tableMeta.rowData.find((e) => e)).id,
                    mcategory:this.state.mcategory,mcatid:this.state.mcatid,categoryType:this.state.categorytype
                }}
              > Level 3 categories</Link>
              {this.state.categorylvltwo.find(e=>e.id===tableMeta.rowData.find((e) => e)).category.length === 0?
              <span
                as="button"
                data-tip
                data-for="delete"
                className="btn btn-sm deletebutton"
                onClick={() => {
                  if (
                    window.confirm(
                      `Are you sure you wish to delete Category ${tableMeta.rowData.find(
                        (e, i) => i.valueOf(e)
                      )}?`
                    )
                  )
                    this.deletecategory(tableMeta.rowData.find((e) => e));
                }}
              > Delete</span>
              :''}
               </>
            );
          },
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {appmasterid === "2" || appmasterid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{pathname: "/newcategorylvl2"}}
                  state= {{ id: -1, name: "New Sub Category",catid:this.state.mcatid,category: this.state.mcategory }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
    };
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
          
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/category"}} state= {{ message: ""}}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to={{ pathname: "/category"}} state= {{ message: ""}}>Category</Link>
              </li>
              <li className="breadcrumb-item active">Category level 2</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    {/* /.card */}
                      <div className="card-body">
                        <div>
                            <ThemeProvider theme={theme}> 
                            <MUIDataTable
                              className="table-sm px-3"
                              title={`${this.state.mcategory} - Category level 2`}
                              data={this.state.categorylvltwo}
                              columns={columns}
                              options={options}
                            />
                            </ThemeProvider>
                        </div>
                      </div>
                      {/* /.card-body */}
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Categorylvl2);
