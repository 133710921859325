import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import {
  currentTenant,
  thousandsdisplay,
  userid,
  financialyearstart,
  financialyearend,
} from "../Common/Credentials";
import { spin } from "../Common/NewLoader";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import {NumericFormat} from "react-number-format";
import MUIDataTable from "mui-datatables";
import SalesServices from "../Sales/SalesServices";
import PurchaseServices from "../Purchase/PurchseServices";
import moment from "moment";

import "date-fns";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import { Col, Modal, Spinner } from "react-bootstrap";
import { TextField, ThemeProvider } from "@mui/material";
import {
  createTheme,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import CountUp from "react-countup";
import InventoryService from "../inventory/InventoryService";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
class SalesvsPurchase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportsdata: [],
      filterdata: [],
      loading: false,
      selected: 0,
      fromdate: financialyearstart,
      todate: new Date(),
      salesquantity: 0,
      purchasequantity: 0,
      purchasereportsdata: [],
      purchasefilterdata: [],
      itemslist: [],
      invoicedata: [],
      grndata: [],
      openingstock: 0,
      closingstock: 0,
      showmodel: false,
      reporttype:"Order"
    };
   
  }

  handleFromDateChange = (date) => {
    this.setState({ fromdate: moment(new Date(date))});
    // let salesfilterdata = this.state.reportsdata.filter(
    //   (e) =>
    //     moment(new Date(e.createdAt)).format("yyyy-MM-dd") === (moment(new Date(date)).format("yyyy-MM-dd"))
    // );
    // let pofilterdata = this.state.purchasereportsdata.filter(
    //   (e) =>
    //     moment(new Date(e.createdAt)).format("yyyy-MM-dd") === (moment(new Date(date)).format("YYYY-MM-dd"))
    // );
    // this.setState({
    //   filterdata: salesfilterdata,
    //   purchasefilterdata: pofilterdata,
    //   salesquantity: salesfilterdata.reduce(
    //     (total, a) => (total = total + a.quantity),
    //     0
    //   ),
    //   purchasequantity: pofilterdata.reduce(
    //     (total, a) => (total = total + a.quantity),
    //     0
    //   ),
    // });
  };
  handleToDateChange = (date) =>{
    this.setState({ todate: moment(new Date(date))});
  }

  Submit = () =>{
    this.Refresh(this.state.fromdate,this.state.todate)
  }


  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.Refresh(financialyearstart,financialyearend)
  }

  Refresh=(start,end)=>{
    let datadao = {
      tenantid: currentTenant,
      startdate: start,
      enddate: end,
    }
    SalesServices.getSalesReport(datadao)
      .then((response) => {
        this.setState(
          {
            reportsdata: response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1),
            filterdata: response.data.filter(
              (e) =>
                moment(new Date(e.createdAt)).format("yyyy-MM-dd") ===
                moment(new Date(this.state.fromdate)).format("yyyy-MM-dd")
            ),
            loading: false,
          },
          () =>
            this.setState({
              salesquantity: this.state.filterdata.reduce(
                (total, a) => (total = total + a.quantity),
                0
              ),
            })
        );
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    PurchaseServices.getPurchaseReport(datadao).then((response) => {
      this.setState(
        {
          purchasereportsdata: response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1),
          purchasefilterdata: response.data.filter(
            (e) =>
              moment(new Date(e.createdAt)).format("yyyy-MM-DD") ===
              moment(new Date(this.state.fromdate)).format("yyyy-MM-DD")
          ),
          loading: false,
        },
        () =>
          this.setState({
            purchasequantity: this.state.purchasefilterdata.reduce(
              (total, a) => (total = total + a.quantity),
              0
            ),
          })
      );
    });
    InventoryService.retriveAllItems(currentTenant).then((response) => {
      this.setState({
        itemslist: response.data.sort((a, b) => (a.name > b.name ? 1 : -1)),
        openingstock: response.data.reduce(
          (total, a) => (total = total + a.openingstock),
          0
        ),
        closingstock: response.data.reduce(
          (total, a) => (total = total + a.closingstock),
          0
        ),
      });
    });


    SalesServices.getInvociereportes(datadao).then((response) => {
        this.setState({invoicedata:response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)})  
    })

    PurchaseServices.getGrnreportes(datadao).then((response) => {
      this.setState({grndata:response.data.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)}) 
    })
  }

  render() {
    const columns = [
      {
        name: "customer",
        label: "Customer",
        options: { filter: true, sort: true },
      },
      {
        name: "soid",
        label: "Order Id/No",
        options: { filter: true, sort: true,
          customBodyRender:(value,tableMeta)=>{
            return(
              <>
              {value==="Direct"?<span>Direct</span>:
                <Link to={{pathname: "/saleorderdetails"}} state ={{id:value,bvstatus:0}}>{value}</Link>
                }
                </>
            )
          }
        },
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          display:false
        },
      },
      {
        name: "itemname",
        label: "Item name",
        options: { filter: false, sort: true },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: false, sort: true },
      },
      {
        name: "uom",
        label: "UOM",
        options: { filter: false, sort: true },
      },
      {
        name: "price",
        label: "Unit Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "createdAt",
        label: "Date",
        display:false,
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (moment(value).format("YYYY-MM-DD HH:mm"));
          },
        },
      },
    ];

    const pcolumns = [
      {
        name: "supplier",
        label: "Supplier",
        options: { filter: false, sort: true },
      },
      {
        name: "poid",
        label: "Order Id/No",
        options: { filter: true, sort: true,
          customBodyRender: (value,tableMeta) => 
          <Link as="button" to={{pathname: "/viewpurchaseorder"}} state={{id:value}}>{value}</Link>
        },
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          display:false
        },
      },
      {
        name: "itemname",
        label: "Item name",
        options: { filter: false, sort: true },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: true, sort: true },
      },
      {
        name: "uom",
        label: "UOM",
        options: { filter: false, sort: true },
      },
      {
        name: "price",
        label: "Unit Price",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "createdAt",
        label: "Date",
        display:false,
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (moment(value).format("YYYY-MM-DD HH:mm"));
          },
        },
      },
    ];

    const invoicecolumns =  [
      {
        name: "invid",
        label: "Invoice Id/No",
        options: { filter: true, sort: true,
          customBodyRender: (value) =>
          <Link as="button" to={{pathname: "/viewsalesinvoice"}} state ={{id:value,pagename:'Tax Invoice Details',temp:false}}>{value}</Link>
        },
      },
      {
        name: "soid",
        label: "Order Id/No",
        options: { filter: true, sort: true,
          customBodyRender:(value,tableMeta)=>{
            return(
              <>
              {value==="Direct"?<span>Direct</span>:
                <Link to={{pathname: "/saleorderdetails"}} state ={{id:value,bvstatus:0}}>{value}</Link>
                }
                </>
            )
          }
        },
      },
      {
        name: "customer",
        label: "Customer",
        options: { filter: true, sort: true },
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          display:false,
        },
      },
      {
        name: "createdAt",
        label: "Date",
        display:false,
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (moment(value).format("YYYY-MM-DD HH:mm"));
          },
        },
      },
      {
        name: "totalprice",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          display:false,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "taxamount",
        label: "Tax amount",
        options: {
          filter: false,
          sort: true,
          display:false,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    ];


    const grncolumns =  [

      {
        name: "grnid",
        label: "GRN Id/No",
        options: { filter: true, sort: true,
          customBodyRender: (value,tableMeta) => 
            <Link as="button" to={{pathname: "/viewgrnitems"}} state ={{id:value,temp:false}}>{value}</Link>
        },
      },
      {
        name: "poid",
        label: "Order Id/No",
        options: { filter: true, sort: true,
          customBodyRender: (value,tableMeta) => 
          <Link as="button" to={{pathname: "/viewpurchaseorder"}} state={{id:value}}>{value}</Link>
        },
      },
      {
        name: "supplier",
        label: "Supplier",
        options: { filter: true, sort: true },
      },
      {
        name: "agent",
        label: "Agent",
        options: {
          filter: true,
          sort: true,
          display:false,
        },
      },
      {
        name: "createdAt",
        label: "Date",
        display:false,
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (moment(value).format("YYYY-MM-DD HH:mm"));
          },
        },
      },
      {
        name: "totalprice",
        label: "Total",
        display:false,
        options: {
          filter: false,
          sort: true,
          display:false,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "taxamount",
        label: "Tax amount",
        options: {
          filter: false,
          sort: true,
          display:false,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {
        name: "totalpricewtax",
        label: "Total",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item active">
                Sales vs Purchase Report
              </li>
            </ol>
            <div className="card-body">
              <div>
                {this.state.loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : 
                        <div className="form-row g-0">

                            <fieldset className="form-group col-md-12">
                                <fieldset
                                  className={`form-group col-md-4  shadow-sm text-center btn ${
                                    this.state.reporttype === "Order"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.reporttype === "Order"}
                                  onClick={() => this.setState({reporttype:"Order"})}
                                >
                                  Order
                                </fieldset>
                                <fieldset
                                  className={`form-group col-md-4 shadow-sm btn text-center ${
                                    this.state.reporttype === "Invoice"
                                      ? "borderedbutton2"
                                      : " hovbuttonColour"
                                  }`}
                                  disabled={this.state.reporttype === "Invoice"}
                                  onClick={() => this.setState({reporttype:"Invoice"})}
                                >
                                  Invoice
                                </fieldset>
                              </fieldset>


                          <fieldset className="mr-2">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justifyContent="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="Slect From Date"
                                  name="from"
                                  format="dd-MM-yyyy"
                                  maxDate={new Date()}
                                  minDate={financialyearstart}
                                  value={this.state.fromdate}
                                  onChange={this.handleFromDateChange}
                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                  autoOk={true}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>

                          <fieldset className="mr-2">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justifyContent="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="Slect To Date"
                                  name="to"
                                  format="DD-MM-YYYY"
                                  maxDate={new Date()}
                                  minDate={this.state.fromdate}
                                  value={this.state.todate}
                                  onChange={this.handleToDateChange}
                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                  autoOk={true}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>
                          <fieldset>
                            <button className="btn btn-sm hovbuttonColour" style={{marginTop:"16px"}} onClick={this.Submit}>
                              Apply
                            </button>
                          </fieldset>
                          <div className="form-group col-md-12 text-sm" />


                          {this.state.reporttype === "Order"?<>        

                          {/* <div className="col-md-12 mb-2">
                            <Typography
                              className=" text-center textColour pt-2 pb-2"
                              style={{ backgroundColor: "rgb(189,199,238)" }}
                            >
                              <b>
                                {" "}
                                {moment(this.state.fromdate).format("dddd")}
                                &nbsp;-&nbsp;
                                {moment(this.state.fromdate).format("LL")}
                              </b>
                            </Typography>
                          </div> */}
                          {/* {this.state.fromdate} */}
                          {/* {moment(this.state.fromdate).format("dd-MM-yyyy") === moment().format("dd-MM-yyyy")? */}
                          <Col sm={6} lg={3}>
                            <div className={`stats-card mb-4`}>
                              {this.state.loading === true ? (
                                <span className="d-flex justify-content-center">
                                  <Spinner animation="border" size="lg" />
                                </span>
                              ) : (
                                <span
                                  as="button"
                                  onClick={() =>
                                    this.setState({
                                      showmodel: !this.state.showmodel,
                                    })
                                  }
                                >
                                  <h3>
                                    <CountUp
                                      start={0}
                                      end={this.state.openingstock}
                                      duration={1.5}
                                      className={`${this.state.openingstock<0?'text-danger':'text-success'}`}
                                    />
                                  </h3>
                                  <p className="text-dark">Opening Stock   <i className="fas fa-link text-dark"></i></p>
                                </span>
                              )}
                            </div>
                          </Col>
                          {/* :''} */}
                          <Col sm={6} lg={3}>
                            <div className={`stats-card mb-4`}>
                              {this.state.loading === true ? (
                                <span className="d-flex justify-content-center">
                                  <Spinner animation="border" size="lg" />
                                </span>
                              ) : (
                                <>
                                  <h3>
                                    <CountUp
                                      start={0}
                                      end={this.state.salesquantity}
                                      duration={1.5}
                                      className="text-blue"
                                    />
                                  </h3>
                                  <p>Sales</p>
                                </>
                              )}
                            </div>
                          </Col>
                          <Col sm={6} lg={3}>
                            <div className={`stats-card mb-4`}>
                              {this.state.loading === true ? (
                                <span className="d-flex justify-content-center">
                                  <Spinner animation="border" size="lg" />
                                </span>
                              ) : (
                                <>
                                  <h3>
                                    <CountUp
                                      start={0}
                                      end={this.state.purchasequantity}
                                      duration={1.5}
                                      className="text-success"
                                    />
                                  </h3>
                                  <p>Purchase</p>
                                </>
                              )}
                            </div>
                          </Col>
                          {/* {moment(this.state.fromdate).format("dd-MM-yyyy") === moment().format("dd-MM-yyyy")? */}
                          <Col sm={6} lg={3}>
                            <div className={`stats-card mb-4`}>
                              {this.state.loading === true ? (
                                <span className="d-flex justify-content-center">
                                  <Spinner animation="border" size="lg" />
                                </span>
                              ) : (
                                <span
                                  as="button"
                                  onClick={() =>
                                    this.setState({
                                      showmodel: !this.state.showmodel,
                                    })
                                  }
                                >
                                  <h3>
                                    <CountUp
                                      start={0}
                                      end={(this.state.openingstock + this.state.purchasequantity) - this.state.salesquantity }
                                      duration={1.5}
                                      className={`${((this.state.openingstock + this.state.purchasequantity) - this.state.salesquantity)<0?'text-danger':'text-success'}`}
                                    />  
                                  </h3>
                                  <p className="text-dark">Closing Stock   <i className="fas fa-link text-dark"></i></p>
                                </span>
                              )}
                            </div>
                          </Col>
                          {/* :''} */}

                          <div className="form-group col-md-12 text-sm"/>
                          <div className="form-group col-md-6 text-sm">
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Sale Order"}
                                data={this.state.reportsdata}
                                columns={columns}
                                options={options}
                              />
                            </ThemeProvider>
                          </div>
                          <div className="form-group col-md-6 text-sm">
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Purchase Order"}
                                data={this.state.purchasereportsdata}
                                columns={pcolumns}
                                options={options}
                              />
                            </ThemeProvider>
                          </div>
                        

                        </>
                        :
                        <>
                        <div className="form-group col-md-6 text-sm">
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"Sales Invoice"}
                                data={this.state.invoicedata}
                                columns={invoicecolumns}
                                options={options}
                              />
                            </ThemeProvider>
                          </div>
                          <div className="form-group col-md-6 text-sm">
                            <ThemeProvider theme={theme}>
                              <MUIDataTable
                                className="table-sm px-3"
                                title={"GRN (Goods Received Note)"}
                                data={this.state.grndata}
                                columns={grncolumns}
                                options={options}
                              />
                            </ThemeProvider>
                          </div>
                        </>}
                        </div>
          } 
                     

                <Modal
                  show={this.state.showmodel}
                  onHide={() =>
                    this.setState({ showmodel: !this.state.showmodel })
                  }
                  backdrop="static"
                  keyboard={false}
                  centered
                  size="lg"
                >
                  <Modal.Header closeButton>Item Stock</Modal.Header>
                  <Modal.Body>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="text-center">Item</TableCell>
                          <TableCell className="text-center">UOM</TableCell>
                          <TableCell className="text-center">Price</TableCell>
                          <TableCell className="text-center">Avilable Stock</TableCell>
                          <TableCell className="text-center">Opening Stock</TableCell>
                          <TableCell className="text-center">Closing Stock</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.itemslist.map((e,i) => (
                          <TableRow key={i}>
                            <TableCell className="text-center">{e.name}</TableCell>
                            <TableCell className="text-center">{e.uomname}</TableCell>
                            <TableCell className="text-right">{e.cost === null || e.cost === undefined?0:
                              <NumericFormat
                                className="pr-3"
                                displayType={"text"}
                                value={e.cost}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            }
                            </TableCell>
                            <TableCell className="text-center">{e.stock === null || e.stock === undefined ?0:e.stock}</TableCell>
                            <TableCell className="text-center">{e.openingstock === null || e.openingstock === undefined ?0:e.openingstock}</TableCell>
                            <TableCell className="text-center">{e.closingstock === null || e.closingstock === undefined ?0:e.closingstock}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(SalesvsPurchase);
