import React, { useState } from "react";
import { InputLabel, Table, TextField } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { currency, currentTenant } from "../../Common/Credentials";
import withRouter from "../../withRouter";
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}



const Adjustment = (props) => {
  const [adjustmentno, setAdjustmentno] = useState('');
  const [commamtomr, setCommamtomr] = useState('');
  const [commamtomrpercent, setCommamtomrpercent] = useState(0.00);
  const [commamtomrlabel, setCommamtomrlabel] = useState('');
  const [narration1, setNarration1] = useState('');
  const [paxname, setPaxname] = useState('');
  const [narration2, setNarration2] = useState('');

  const [accountcode, setAccountcode] = useState('');
  const [accountname, setAccountname] = useState('');
  const [subcustcode, setSubcustcode] = useState('');
  const [subcustname, setSubcustname] = useState('');
  const [amountinomr1, setAmountinomr1] = useState('');
  const [amountinomrpercent1, setAmountinomrpercent1] = useState('');
  const [roeofomr, setRoeofomr] = useState('');
  const [amountinomr2, setAmountinomr2] = useState('');
  const [amountinomrpercent2, setAmountinomrpercent2] = useState('');
  const [reference, setReference] = useState('');
  const [commamt,setCommamt]=useState('');

  const [adjuststore,setAdjuststore]=useState([]);

  const AddHandlerAdjustment=(e)=>{
    e.preventDefault();
    let data = {
      adjustmentno:adjustmentno,
      commamtomr:commamtomr,
      commamtomrpercent:commamtomrpercent,
      commamtomrlabel:commamtomrlabel,
      narration1:narration1,
      paxname:paxname,
      narration2:narration2,
      accountcode:accountcode,
      accountname:accountname,
      subcustcode:subcustcode,
      subcustname:subcustname,
      amountinomr1:amountinomr1,
      amountinomrpercent1:amountinomrpercent1,
      roeofomr:roeofomr,
      amountinomr2:amountinomr2,
      amountinomrpercent2:amountinomrpercent2,
      reference:reference,
      commamt:commamt,
      tenantid:currentTenant,
    }
    console.log(data,"HHH");
    setAdjuststore([...adjuststore, data]);
    console.log([...adjuststore, data],"adjust");
    props.funcAdjusment([...adjuststore, data]);

  }

  const changeHandlerCommamtOmrpercent=(e)=>{
    setCommamtomrpercent(e.target.value<0 ||e.target.value===null ||  e.target.value===undefined ||e.target.value===""?0: e.target.value);
    setCommamt(e.target.value<0 ||e.target.value===null ||  e.target.value===undefined ||e.target.value===""?0: e.target.value);
  }

  const rows = [
    createData("Original Amt	", 0.0, 0.0, 0.0),
    createData("Transfer Amt", 0.0, 0.0, 0.0),
    createData("Comm. Amt ", 0.0, 0.0, commamt),
    createData("Paid Amt", 0.0, 0.0, 0.0),
    createData("Balance Amt", 0.0, 0.0, 0.0),
  ];

  const changeHandlerClear=(e)=>{
    setAdjustmentno('');
    setCommamtomr('');
    setCommamtomrpercent('');
    setCommamtomrlabel('');
    setNarration1('');
    setPaxname('');
    setNarration2('');

    setAccountcode('');
    setAccountname('');
    setSubcustcode('');
    setSubcustname('');
    setAmountinomr1('');
    setAmountinomrpercent1('');
    setRoeofomr('');
    setAmountinomr2('');
    setAmountinomrpercent2('');
    setReference('');
    setCommamt('');
  }

  return (
    <>

      <div className="small-container mp-2 padding-2 w-100%">
        <div className="card">

          <div className="card">
            <div className="card p-4">
              <div className="row p-4 my-2">
                <div className="col-sm-6">
                  <div className="row my-2">
                    <fieldset className="col-md-10 col-sm-6">
                      <TextField
                        fullWidth
                        label="Adjustment No."
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        value={adjustmentno}
                        onChange={(e) => setAdjustmentno(e.target.value)}
                      />
                    </fieldset>
                  </div>

                  <div className="row my-2">
                    <fieldset className="col-md-4 col-sm-6 my-2">
                      <TextField
                        fullWidth
                        label="Comm. Amt. in OMR"
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        type="number"
                        value={commamtomr<0 ||commamtomr===null ||  commamtomr===undefined?0: commamtomr}
                        onChange={(e) => setCommamtomr(e.target.value)}
                      />
                    </fieldset>
                    <div className="col-md-1 col-sm-6 my-2">
                      <InputLabel>%</InputLabel>
                    </div>
                    <fieldset className="col-md-2 col-sm-6 my-2">
                      <TextField
                        label=""
                        id="outlined-size-small"
                        size="small"
                        type="number"
                        variant="outlined"
                        value={ commamtomrpercent<0 ||commamtomrpercent===null ||  commamtomrpercent===undefined?0: commamtomrpercent}
                        onChange={changeHandlerCommamtOmrpercent }
                      />
                    </fieldset>
                    <div className="col-md-2 col-sm-6 my-2">
                      <InputLabel>{commamtomrlabel}</InputLabel>
                    </div>
                    <div className="col-md-2 my-2">
                      <InputLabel>{currency}</InputLabel>
                    </div>
                  </div>

                  <div className="row my-2">
                    <fieldset className="col-md-10 col-sm-6 my-2">
                      <TextField
                        fullWidth
                        multiline
                        label="Narration"
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        rows={5}
                        maxRows={5}
                        value={narration1}
                        onChange={(e) => setNarration1(e.target.value)}
                      />
                    </fieldset>
                  </div>

                  <div className="row my-2">
                    <fieldset className="col-md-10 col-sm-6 my-2">
                      <TextField
                        fullWidth
                        label="Pax Name"
                        id="outlined-size-small"
                        size="small"
                        variant="outlined"
                        value={paxname}
                        onChange={(e) => setPaxname(e.target.value)}

                      />
                    </fieldset>
                  </div>
                </div>
                <div className="col-sm-6 my-2">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="caption table">
                      {/* <caption>A basic table example with a caption</caption> */}
                      <TableHead>
                        <TableRow>
                          <TableCell> </TableCell>
                          <TableCell align="right"> {currency} </TableCell>
                          <TableCell align="right"> OMR </TableCell>
                          <TableCell align="right"> % </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="right">{row.calories}</TableCell>
                            <TableCell align="right">{row.fat}</TableCell>
                            <TableCell align="right">{row.carbs}</TableCell>
                            <TableCell align="right">{row.protein}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
            <div className="card p-4">
              <div className="row">
                <div className=" col-sm-6" style={{ textAlign: "center" }}>
                  <InputLabel> Transfer </InputLabel>
                </div>

              </div>
              <div className="row my-2">
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    fullWidth
                    label="Account Code"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={accountcode}
                    onChange={(e)=>setAccountcode(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    label="Account Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={accountname}
                    onChange={(e)=>setAccountname(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-5 col-sm-12">
                  <TextField
                    fullWidth
                    label="ROE of OMR"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={roeofomr}
                    onChange={(e)=>setRoeofomr(e.target.value)}
                  />
                </fieldset>
              </div>
              <div className="row my-2">
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    fullWidth
                    label="Sub Customer Code"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={subcustcode}
                    onChange={(e)=>setSubcustcode(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    label="Sub Customer Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={subcustname}
                    onChange={(e)=>setSubcustname(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    fullWidth
                    label={`Amount in ${currency} 1`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={amountinomr1}
                    onChange={(e)=>setAmountinomr1(e.target.value)}
                  />
                </fieldset>
                <div className="col-md-1 col-sm-12 ">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-2 col-sm-12">
                <TextField
                    fullWidth
                    label={`Amount in ${currency} Percent 1`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={amountinomrpercent1}
                    onChange={(e)=>setAmountinomrpercent1(e.target.value)}
                  />
                </fieldset>
              </div>
              <div className="row my-2">
                <fieldset className="col-md-7 col-sm-12">
                  <TextField
                    fullWidth
                    label="Reference"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={reference}
                    onChange={(e)=>setReference(e.target.value)}
                    
                  />
                </fieldset>
                <fieldset className="col-md-2 col-sm-12">
                <TextField
                    fullWidth
                    label={`Amount in ${currency} 2`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={amountinomr2}
                    onChange={(e)=>setAmountinomr2(e.target.value)}
                  />
                </fieldset>
                <div className="col-md-1 col-sm-12 ">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-2 col-sm-12">
                <TextField
                    fullWidth
                    label={`Amount in ${currency} Percent 2`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={amountinomrpercent2}
                    onChange={(e)=>setAmountinomrpercent2(e.target.value)}
                  />
                </fieldset>
              </div>
              <div className="row my-2">
                <fieldset className="col-md-7 col-sm-6">
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    maxRows={3}
                    label="Narration"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={narration2}
                    onChange={(e)=>setNarration2(e.target.value)}
                    

                  />
                </fieldset>
              </div>
            </div>
            <div className="row btn btn-sm" >
              <button className="btn hovbuttonColour btn-sm mr-2  "  onClick={AddHandlerAdjustment}>Add</button>
              <button className="btn hovbuttonColour  btn-sm mr-2  bg-danger text-color-red" onClick={changeHandlerClear}  >Clear</button>
            </div>
            <div className="p-1">
            <Paper sx={{ width: '100%' , overflow: 'hidden' }}>
              <TableContainer component={Paper} sx={{ maxHeight: 350 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="right">Adjustment Number</TableCell>
                      <TableCell align="right">Common Amount</TableCell>
                      <TableCell align="right">Common Amount Percent</TableCell>
                      <TableCell align="right">Narration</TableCell>
                      <TableCell align="right">Pax Name</TableCell>
                      <TableCell align="right">Original Amount</TableCell>
                      <TableCell align="right">Transfere Amount</TableCell>
                      
                      <TableCell align="right">paid Amount</TableCell>
                      <TableCell align="right">Balance Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {adjuststore.map((r) => (
                      <TableRow
                       
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {r.adjustmentno}
                        </TableCell>
                        <TableCell>
                          {r.commamtomr}
                        </TableCell>
                        <TableCell align="right">{r.commamtomrpercent}</TableCell>
                        <TableCell align="right">{r.narration1}</TableCell>
                        <TableCell align="right">{r.paxname}</TableCell>
                        <TableCell align="right">{r.originalamt}</TableCell>
                        <TableCell align="right">{r.transferamt}</TableCell>
                        <TableCell align="right">{r.datewisecomm}</TableCell>
                        <TableCell align="right">{r.paidamt}</TableCell>
                        <TableCell align="right">{r.balanceamt}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
          </div>
        </div>
        
      </div>

    </>
  );
};
export default withRouter(Adjustment);
