import {
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import { currentTenant, userid } from "../Common/Credentials.js";
import { Autocomplete } from "@mui/material";
import { React, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import withRouter from "../withRouter.js";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import Footer from "../Component/Footer";
import InventoryService from "../inventory/InventoryService";
import AssetsService from "./AssetsService.js";
import WarehouseServices from "../Masters/Warehouse/WarehouseServices.js";
import { spin } from "../Common/NewLoader.jsx";
import { Form, Formik } from "formik";

import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import { Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ProductionServices from "../Production/ProductionServices.js";
import moment from "moment";
import Swal from "sweetalert2";

const NewProductionUseage = (props) => {
  const history = useLocation();
  const navigate = useNavigate()
  const [warehouseList, setWarehouseList] = useState([]);
  const [warehouse, setWarehouse] = useState("");
  const [whid, setWhid] = useState("");
  const [JoborderItemsList, setJoborderItemsList] = useState([]);
  const [joborder, setJoborder] = useState([]);
  const [joid, setJoid] = useState("");
  const [data, setData] = useState([]);
  // const [itemlist, setitemlist] = useState([]);
  const [itemid, setitemid] = useState("");
  const [itemname, setitemname] = useState("");
  const [equipid,setEquipid]=useState("");
  const [estid,Setestid] = useState("")
  
 
  const [selectbutton, setSelectbutton] = useState(true);
 
  const [usagetypes] = useState(["Full Time", "Part Time"]);
  const [usagetype, setUsagetype] = useState("");

  const [wcid, setWcid] = useState("");
  const [workcenter, setWorkcenter] = useState("");
  const [workcenterList, setWorkcenterList] = useState([]);
  const [enddate, setenddate] = useState(new Date());
  const [startdate, setstartdate] = useState(new Date());
  const [fromtime, setFromtime] = useState(new Date());
  const [totime, setTotime] = useState(new Date());
  const [loader, setLoader] = useState(false);
  const [avilableassets,setAvilableAssets] =  useState([]);
  const [assetid,setAssetId]=useState("");
  const [customassetid,setCustomassetid] = useState("")

  useEffect(() => {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      navigate(`/`);
    }
    WarehouseServices.getWarehouses(currentTenant)
      .then((response) => setWarehouseList(response.data))
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          navigate(`/`);
        }
      });
    InventoryService.GetAssetsItems(currentTenant).then((response) => {
      setData(response.data);
      // setitemlist([...new Set(response.data.map((e) => e.itemname))]);
    });
  }, [history]);

  const handleWarehouseChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setWarehouse("");
      setWhid("");
      setWorkcenterList("");
    } else {
      setWarehouse(newValue.name);
      setWhid(newValue.id);
      setWorkcenterList(newValue.workcentre);
    }
  };

  const handleWorkcenterChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
      setWorkcenter("");
      setWcid("");
    } else {
      setWorkcenter(newValue.name);
      setWcid(newValue.id);
      ProductionServices.getJobOrderIdListByWcId(newValue.id).then(
        (response) => {
          setJoborder(response.data);
        }
      );
    }
  };

  const handlejoidChange = (newValue) => {
    newValue === "" || newValue === null || newValue === undefined
      ? setJoid("")
      : setJoid(newValue);
    setJoborderItemsList([]);
    setitemid("");
    setitemname("");
    if (newValue) {
      ProductionServices.getallJobOrderItems(newValue).then((response) => {
        Setestid(response.data.id)
        setJoborderItemsList(response.data.equipments);
        setSelectbutton(true);
      });
    }
  };

  const handleSelect = (e) => {
    if (e !== undefined) {
      setEquipid(e.id)
      setitemname(e.name);
      setitemid(e.itemid);
      setSelectbutton(false);
    }
  };

  // const handleQuantityChange = (e) => {
  //   setQuantity(e.target.value);
  // };


  const handleChange = (e) => {
    setUsagetype(e.target.value);
    setAssetId('')
    setAvilableAssets([])
  };


  const handleenddateChange = (date) => {
    setenddate(date);
    setAssetId('')
    setAvilableAssets([])
  };

  const handlestartdateChange = (date) => {
    setstartdate(date);
    setenddate(date)
    setAssetId('')
    setAvilableAssets([])
  };

  const handleFromtime = (time) => {
    setFromtime(time);
    setTotime(time);
    setAssetId('')
    setAvilableAssets([])
  };
  const handleTotime = (time) => {
    if(moment(time).format('HH:mm')>moment(fromtime).format('HH:mm')){
    setTotime(time);
    setAssetId('')
    setAvilableAssets([])
    }
  };

  const handleCheckAvilability =()=>{
    
    AssetsService.CheckAvilability(currentTenant,itemid,wcid).then(response=>{
      let data = response.data.filter(e=> e.productionusage.length === 0)

      let data1 =  response.data.filter(e=> e.productionusage.length !== 0 )
      let avldata = data1.map(d=>d.productionusage.filter(e=>e.status==="Avilable")) 
      let data2 = data1.map(d=>d.productionusage.filter(e=>e.status==="NotAvilable"))
        data2 = data1.map(d=>d.productionusage.find(e=>(moment(startdate).format('YYYY-MM-DD')>=moment(e.startdate).format('YYYY-MM-DD') && moment(startdate).format('YYYY-MM-DD')<= moment(e.enddate).format('YYYY-MM-DD')) || (moment(enddate).format('YYYY-MM-DD')>=moment(e.starend).format('YYYY-MM-DD') && moment(enddate).format('YYYY-MM-DD')<= moment(e.enddate).format('YYYY-MM-DD')) ))
  
      let data3 =data2[0] === undefined?data2 : data2.filter(e=>(moment(fromtime).format('HH:mm')>=moment(e.fromtime).format('HH:mm') && moment(fromtime).format('HH:mm')<= moment(e.totime).format('HH:mm'))||(moment(totime).format('HH:mm')>=moment(e.fromtime).format('HH:mm') && moment(totime).format('HH:mm')<= moment(e.totime).format('HH:mm')))
    
      if(usagetype==="Part Time"){
        data1 = data3[0] === undefined?data1 : data1.filter(e=>e.id!==data3.find(a=>a).assetid)
      }else{
        data1 = data2[0] === undefined?data1 : data1.filter(e=>e.id!==data2.find(a=>a).assetid)
      }

     let avildata =[]
     if(data.length === 0 ){
      avildata = data
     }
     if(avldata.length !==0){
      avildata.push(avldata.find(e=>e))
     }

      if(data.length !== 0){
        avildata.push(data.find(e=>e))
      }
      if(data1.length !== 0){
        avildata.push(data1.find(e=>e))
      }
      setAvilableAssets(avildata)

      if(avildata.length === 0 ){
      Swal.fire({
        title: "",
        text: "No assets are avilable at the moment",
        icon: "info",
        confirmButtonText: "Ok",
        confirmButtonColor: "#152f5f",
      });
    }
  })}

 const  handleAssetSelect=(e)=>{
    setAssetId(e.id)
    setCustomassetid(e.customid)
  }
  const onSubmit = () => {
    setLoader(true);
    let data = {
      tenantid : currentTenant,
      wcid : wcid,
      whid : whid,
      joid : joid,
      usagetype : usagetype,
      startdate : startdate,
      enddate : enddate,
      fromtime : usagetype ==="Full Time"?moment().set({hour:0,minute:0,second:0,millisecond:0}) : fromtime,
      totime : usagetype ==="Full Time"? moment().set({hour:23,minute:59,second:59,millisecond:999}) :totime,
      quantity:1,
      createdby : userid,
      updatedby : userid,
      assetid : assetid,
      equipid:equipid,
      estid:estid,
    }
    AssetsService.NewProductionUsage(data).then(()=>
      navigate('/productionusage')
    )
  };

  
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/productionusage" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/assetmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/productionusage">Production Usage</Link>
            </li>
            <li className="breadcrumb-item active">Production Usage</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              {data === [] || data.length === 0 ? (
                <div className="text-center">{spin}</div>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Production Usage</h3>
                      </div>
                      <div className="card-body">
                        <Formik
                          initialValues={{}}
                          // onSubmit={onSubmit}
                          validateOnChange={false}
                          // validate={validate}
                          enableReinitialize={true}
                        >
                          {({ setFieldValue, values }) => (
                            <Form
                              autoComplete="off"
                              style={{ paddingLeft: "30px" }}
                            >
                              <div className="form-row">
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="warehouse"
                                    options={warehouseList}
                                    getOptionLabel={(option) => option.name}
                                    
                                    
                                    value={
                                      values.warehouse ? undefined : undefined
                                    }
                                    onChange={handleWarehouseChange}
                                    disabled={itemname !== ""}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="warehouse"
                                        value={warehouse}
                                        label={`Select Org Unit (${warehouse})`}
                                        variant="standard"
                                        error={whid === ""}
                                        helperText={
                                          whid === "" ? "Empty field!" : " "
                                        }
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>
                                <fieldset className="form-group col-md-3">
                                  <Autocomplete
                                    id="workcenter"
                                    className="subacc-select"
                                    name="workcenter"
                                    options={workcenterList}
                                    getOptionLabel={(option) => option.name}
                                    
                                    
                                    value={
                                      values.workcenter ? undefined : undefined
                                    }
                                    onChange={handleWorkcenterChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="workcenter"
                                        value={workcenter}
                                        label={`Select Workcenter/Production Unit (${workcenter})`}
                                        variant="standard"
                                        error={wcid === ""}
                                        helperText={
                                          wcid === "" ? "Empty field!" : " "
                                        }
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>
                                <fieldset className="col-md-3">
                                  <Autocomplete
                                    id="controlled-demo"
                                    options={joborder}
                                    getOptionLabel={(option) => option || ""}
                                    
                                    
                                    value={joid}
                                    onChange={(event, newValue) =>
                                      handlejoidChange(newValue)
                                    }
                                    error={joid === "" || joid === undefined}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Select JobOrder Id"
                                        variant="standard"
                                        error={
                                          joid === "" || joid === undefined
                                        }
                                        helperText={
                                          joid === "" || joid === undefined
                                            ? "Empty field!"
                                            : " "
                                        }
                                      />
                                    )}
                                  />
                                </fieldset>

                                {/* {filteritems.length !== 0 ? (
                                  <> */}
                                {selectbutton === true ? (
                                  JoborderItemsList.length === 0 ? (
                                    <div className="col-md-12">
                                      Sorry, no matching records found
                                    </div>
                                  ) : (
                                    <TableContainer>
                                      <Table className="table-sm">
                                        <TableHead>
                                          <TableRow>
                                            <TableCell className="text-center">
                                              Itemname
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Quantity
                                            </TableCell>
                                            {selectbutton === true ? (
                                              <TableCell className="text-center">
                                                Actions
                                              </TableCell>
                                            ) : (
                                              ""
                                            )}
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {JoborderItemsList.map((e, index) => (
                                            <TableRow key={index}>
                                              <TableCell className="text-center">
                                                {e.name}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.pendingquantity}
                                              </TableCell>

                                              <TableCell className="text-center">
                                                <span
                                                  as="buttom"
                                                  className="btn btn-sm hovbuttonColour"
                                                  onClick={() =>
                                                    handleSelect(e)
                                                  }
                                                >
                                                  Select
                                                </span>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  )
                                ) : (
                                  ""
                                )}
                                {/* </>
                                ) : (
                                  ""
                                )} */}

                                {selectbutton === false ? (
                                  <>
                                    <fieldset className="form-group col-md-3">
                                      <TextField
                                        name="itemname"
                                        label={`Itemname`}
                                        variant="standard"
                                        value={itemname}
                                        error={
                                          itemname === "" ||
                                          itemname === undefined
                                        }
                                        helperText={
                                          itemname === "" ||
                                          itemname === undefined
                                            ? "Empty field!"
                                            : " "
                                        }
                                        fullWidth
                                        disabled
                                      />
                                    </fieldset>
                                    {/* <fieldset className="form-group col-md-3">
                                      <TextField
                                        className="form-control"
                                        type="text"
                                        name="baseamount"
                                        label={`Price`}
                                        value={baseamount}
                                        error={
                                          baseamount === "" ||
                                          baseamount === undefined ||
                                          baseamount === 0
                                        }
                                        helperText={
                                          baseamount === "" ||
                                          baseamount === 0 ||
                                          baseamount === undefined
                                            ? "Enter price"
                                            : " "
                                        }
                                      />
                                    </fieldset> */}

                                    <fieldset className="form-group col-md-3">
                                          <FormControl
                                            sx={{ m: 1, minWidth: 120 }}
                                            className="col-md-12"
                                          >
                                            <InputLabel id="demo-simple-select-filled-label">
                                              Select Usage Type
                                            </InputLabel>
                                            <Select
                                              value={usagetype}
                                              onChange={handleChange}
                                            >
                                              {usagetypes.map(
                                                (option) => {
                                                  return (
                                                    <MenuItem
                                                      key={option}
                                                      value={option}
                                                    >
                                                      {option}
                                                    </MenuItem>
                                                  );
                                                }
                                              )}
                                            </Select>
                                          </FormControl>
                                        </fieldset>

                                    <fieldset className="form-group col-md-2">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <Grid container justify="space-around">
                                          <DatePicker
                                            variant="inline"
                                            id="date-picker-dialog"
                                            label="Startdate Date"
                                            format="dd/MM/yyyy"
                                            value={startdate}
                                            onChange={handlestartdateChange}
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                            autoOk={true}
                                          />
                                        </Grid>
                                      </LocalizationProvider>
                                    </fieldset>

                                    <fieldset className="form-group col-md-2">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <Grid container justify="space-around">
                                          <DatePicker
                                            variant="inline"
                                            id="date-picker-dialog"
                                            label="End Date "
                                            format="dd/MM/yyyy"
                                            value={enddate} 
                                            minDate={startdate}
                                            onChange={handleenddateChange}
                                            KeyboardButtonProps={{
                                              "aria-label": "change date",
                                            }}
                                            autoOk={true}
                                          />
                                        </Grid>
                                      </LocalizationProvider>
                                    </fieldset>

                                    {usagetype ==="Part Time"?<>
                                    <fieldset className="form-group col-md-2">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <Grid container justify="space-around">
                                          <TimePicker
                                            ampm={false}
                                            variant="inline"
                                            label="From (24h Timing)"
                                            value={fromtime}
                                            onChange={handleFromtime}
                                            autoOk={true}
                                            defaultValue="00:00"
                                          />
                                        </Grid>
                                      </LocalizationProvider>
                                    </fieldset>
                                    <fieldset className="form-group col-md-2">
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <Grid container justify="space-around">
                                          <TimePicker
                                            ampm={false}
                                            variant="inline"
                                            label="To (24h Timing)"
                                            value={totime}
                                            onChange={handleTotime}
                                            autoOk={true}
                                            defaultValue="23:59"
                                          />
                                        </Grid>
                                      </LocalizationProvider>
                                    </fieldset>
                                    </>:''}

                                    {avilableassets.length ===0 || assetid !=='' ?'':
                                    <TableContainer>
                                      <Table className="table-sm">
                                        <TableHead>
                                          <TableRow>
                                          <TableCell className="text-center">
                                              Asset Id
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Itemname
                                            </TableCell>
                                            <TableCell className="text-center">
                                              Quantity
                                            </TableCell>
                                           
                                              <TableCell className="text-center">
                                                Actions
                                              </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {avilableassets.map((e, index) => (
                                            <TableRow key={index}>
                                              <TableCell className="text-center">
                                              {e.customid}
                                            </TableCell>
                                              <TableCell className="text-center">
                                                {e.itemname}
                                              </TableCell>
                                              <TableCell className="text-center">
                                                {e.quantity}
                                              </TableCell>

                                              <TableCell className="text-center">
                                                <span
                                                  as="button"
                                                  className="btn btn-sm hovbuttonColour"
                                                  onClick={() =>
                                                    handleAssetSelect(e)
                                                  }
                                                >
                                                  Select
                                                </span>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                   }
                                    {assetid ===''?'':<>
                                    <fieldset className="form-group col-md-3">
                                      <TextField
                                        name="assetid"
                                        label={`Asset Id`}
                                        variant="standard"
                                        value={customassetid}
                                        fullWidth
                                        disabled
                                      />
                                    </fieldset>
                                    {/* <fieldset className="form-group col-md-3">
                                    <TextField
                                      className="form-control"
                                      type="text"
                                      name="quantity"
                                      label={`Quantity (Pending Quantity: ${pendingquantity})`}
                                      value={quantity}
                                      onChange={handleQuantityChange}
                                      error={
                                        quantity === "" ||
                                        quantity === undefined ||
                                        quantity > pendingquantity ||
                                        quantity === 0
                                      }
                                      helperText={
                                        quantity === "" ||
                                        quantity === 0 ||
                                        quantity === undefined
                                          ? "Enter Quantity"
                                          : quantity > pendingquantity
                                          ? `Pending Quantity is ${pendingquantity}`
                                          : " "
                                      }
                                    />
                                  </fieldset> */}
                                    </>}
                                    <div className="form-group col-md-12" />
                                   {assetid===""?
                                    <button
                                      className="btn btn-sm hovbuttonColour mr-1"
                                      type="button"
                                      onClick={handleCheckAvilability}
                                    >Check Avilability</button>
                                      :
                                    <button
                                      className="btn btn-sm hovbuttonColour mr-1"
                                      type="button"
                                      onClick={onSubmit}
                                      disabled={loader === true}
                                    >
                                      {" "}
                                      {loader === true ? (
                                        <>
                                          <Spinner
                                            animation="border"
                                            size="sm"
                                          />
                                          {" Saving"}
                                        </>
                                      ) : (
                                        <>
                                          <i className="fa fa-save mr-1" />
                                          {"Save"}
                                        </>
                                      )}
                                    </button>
                                      }
                                    <Link
                                      as="button"
                                      className="btn btn-sm deletebutton"
                                      to={{ pathname: "/productionusage" }}
                                    >
                                      <b className="text-danger"> X</b> Cancel
                                    </Link>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(NewProductionUseage);
