import React from "react";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from '@mui/icons-material/Add';
// import { styled } from "@mui/material/styles";
import { Toolbar } from '@mui/material';

const defaultToolbarStyles = {
  iconButton: {
  },
};

const CustomToolbar = (props) => {
    const { theme } = props;

    return (
      // <Toolbar>
        <Tooltip title="Add New">
          <IconButton>
            <AddIcon/>
          </IconButton>
        </Tooltip>
      // </Toolbar>
    );
  }

export default CustomToolbar;