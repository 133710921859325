export let appmasterid
export let authid
export let accountsid
export let inventoryid
export let salesid
export let purchaseid
export let productionid
export let assetmanagementid

class Permissions {

  
  setAppmaster(appmaster) {
    appmasterid = appmaster.toLocaleString()
  }
  getAppmaster() {
   return this.appmasterid
  }
  
  setAuth(auth) {
    authid = auth.toLocaleString()
  }

  getAuth() {
    return this.authid
   }

  setAccounts(accounts) {
    accountsid = accounts.toLocaleString()
  }
  getAccounts() {
    return this.accountsid
   }

  setInventory(inventory) {
    inventoryid = inventory.toLocaleString()
  }

  setSales(sales) {
    salesid = sales.toLocaleString()
  }

  setPurchase(purchase) {
    purchaseid = purchase.toLocaleString()
  }

  setProduction(production){
    productionid = production.toLocaleString()
  }

  setAssetmanagement(asset){
    assetmanagementid = asset.toLocaleString()
  }

}

export default new Permissions()