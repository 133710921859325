import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  currentTenant,
  financialyearend,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {NumericFormat} from "react-number-format";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import "date-fns";
import Grid from "@mui/material/Grid";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";import { Button } from "react-bootstrap";
import { TextField, ThemeProvider } from "@mui/material";
import {
  createTheme,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ProductionServices from "../ProductionServices";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
class ProcesswiseReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      reportsdata: [],
      filterdata: [],
      impreportsdata: [],
      impfilterdata: [],
      filterbydate: [],
      filterOptions: [
        { id: 0, name: "Production" },
        { id: 1, name: "Full Process" },
        { id: 2, name: "Immediate Process" },
      ],
      overall: true,
      loading: false,
      selected: 0,
      fromdate: moment().subtract(7,"days"),
      todate: new Date(),
      reportsfiltereddata:[],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
    this.clearfilter = this.clearfilter.bind(this);
  }

  onChange(option) {
    this.setState({ selected: option.id });
  }

  handleFromDateChange = (date) => {
    this.setState({ fromdate: date });
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  clearfilter() {
    this.getData(moment(new Date()).subtract(7, 'day'),moment(new Date()).add(1,'day'))
  }

  onSubmit() {
    this.getData(this.state.fromdate,this.state.todate)
  }

  getData = (start,end) => {
    let data = {    
        tenantid:currentTenant,
        startdate:start,
        enddate:end
    }

    ProductionServices.ProcesswiseReports(data)
      .then((response) => {
        this.setState({
            filterdata: response.data,
            impfilterdata: response.data,
            reportsdata: response.data.length>0?response.data?.map(e=>
                Object.assign(e,
                    {bomitems:e.bomitems.map(i=>
                        Object.assign(i,{
                            total:e.bomitems.reduce((a,b)=>a=a+(i.itemid === b.itemid?b.quantity:0),0),
                            totallosses:e.bomitems.reduce((a,b)=>a=a+(i.itemid === b.itemid?b.losses:0),0)
                        })
                        )
                    .filter((obj, index) => {
                        return index === e.bomitems.findIndex(o => obj.itemid === o.itemid);
                    })
                }
                    ))?.sort((a, b) => (a.date < b.date ? 1 : -1)):[],
            reportsfiltereddata:response.data,
            loading: false,
          }
        );
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }

    this.setState({ loading: true });

    this.getData(moment(new Date()).subtract(7, 'day'),moment(new Date()).add(1,'day'))
  }

  validate(values) {
    let errors = {};
    if (values.from <= values.to && values.to === false) {
      alert("To date must be greater than From date");
      errors.to = "To date must be greater than From date";
    }
    if (values.from === null) {
      alert("Please Select From date");
      errors.from = "Please Select From date";
    }
    if (!values.to === null) {
      alert("Please Select to date");
      errors.to = "Please Select to date";
    }
    return errors;
  }
  render() {
    const columns = [
      {
        name: "itemname",
        label: "Item name",
        options: { filter: true, sort: true, sortOrder: "asc" },
      },
      {
        name: "quantity",
        label: "Quantity",
        options: { filter: false, sort: true },
      },
      {
        name: "createdAt",
        label: "Date",
        options: {
          filter: false,
          sort: true,
          sortOrder: "asc",
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY hh:mm");
          },
        },
      },
    ];


    const column = [
        {
            name: "type",
            label: "Type",
            options: { filter: true, sort: true, },
          },
          {
            name: "id",
            label: "ID",
            options: { filter: true, sort: true, },
          },
          {
            name: "date",
            label: "Date",
            options: {
              filter: false,
              sort: true,
              sortOrder: "asc",
              customBodyRender: (value) => {
                return moment(value).format("DD-MM-YYYY hh:mm");
              },
            },
          },
        //   {
        //   name: "status",
        //   label: "Status",
        //   options: {
        //     filter: true,
        //     sort: true,
        //   },
        // },
          {
            name: "bomitems",
            label: "Raw material",
            options: { filter: false, sort: false,
                customBodyRender: (value) => {
                    return(
                    <Table className="border">
                        <TableHead>
                            <TableRow>
                            <TableCell className="text-center">Item</TableCell>
                            <TableCell className="text-center">Quantity</TableCell>
                            <TableCell className="text-center">losses</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {value.map((e,i)=>
                        <TableRow key={i}>
                            <TableCell className="text-center">{e.itemname}</TableCell>
                            <TableCell className="text-center">{e.total} {e.uom}</TableCell>
                            <TableCell className="text-center">{e.totallosses} {e.uom}</TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                  )},
        },
          },
          {
            name: "finalproduct",
            label: "Finished Products",
            options: { filter: false, sort: false,
                customBodyRender: (value) => {
                    return(
                    <Table className="border">
                        <TableHead>
                            <TableRow>
                            <TableCell className="text-center">Item</TableCell>
                            <TableCell className="text-center">Quantity</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {value.map((e,i)=>
                        <TableRow key={i}>
                            <TableCell className="text-center">{e.itemname}</TableCell>
                            <TableCell className="text-center">{e.quantity} {e.uom}</TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                  )},
            },
          },
    ]



    const options = {
      filterType: "checkbox",
      selectableRows: "none",
    };


    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>
              <li className="breadcrumb-item active">Production Report</li>
            </ol>
            <div className="card-body">
              <div>
                {this.state.loading === true ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <Formik
                    initialValues={{
                      from: this.state.fromdate,
                      to: this.state.todate,
                    }}
                    onSubmit={this.onSubmit}
                    validateOnChange={false}
                    validate={this.validate}
                    enableReinitialize={true}
                  >
                    {({ setFieldValue, values }) => (
                      <Form autoComplete="off">
                        <div className="form-row g-0">
                          <fieldset>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justifyContent="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="From Date"
                                  name="from"
                                  format="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  value={this.state.fromdate}
                                  onChange={this.handleFromDateChange}
                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                  autoOk={true}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>

                          <fieldset style={{ marginLeft: "30px" }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Grid container justifyContent="space-around">
                                <DatePicker
                                  variant="inline"
                                  id="date-picker-dialog"
                                  label="To Date"
                                  name="to"
                                  format="dd/MM/yyyy"
                                  maxDate={new Date()}
                                  value={this.state.todate}
                                  onChange={this.handleToDateChange}
                                  renderInput={(params) => <TextField {...params} variant="standard"/>}
                                  autoOk={true}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </fieldset>
                          <fieldset className="form-group col-md-1">
                            <button
                              className="btn btn-sm hovbuttonColour mt-3"
                              type="submit"
                            >
                              <i className="fas fa-filter" />
                            </button>
                          </fieldset>
                          {this.state.todate !== null ? (
                            <fieldset className="form-group col-md-2">
                              <Button
                                className="btn btn-sm bg-white shadow-sm mt-3"
                                onClick={this.clearfilter}
                              >
                                {" "}
                                <b style={{ color: "#FA5733" }}> X</b> Clear
                                Filter
                              </Button>
                            </fieldset>
                          ) : (
                            ""
                          )}
                          <div className="form-group col-md-12 text-sm" />

                          {/* {this.state.filterOptions.map((option,idx) => {
                            return (
                              <label key={idx}>
                                <div className="form-group col-md-9 text-sm">
                                  <div className="btn-group">
                                    <input
                                      className={
                                        this.state.selected === option.id
                                          ? "btn hovborderbuttonColour"
                                          : "btn hovbuttonColour"
                                      }
                                      type="button"
                                      key={option.id}
                                      onClick={this.onChange.bind(this, option)}
                                      style={{ marginRight: 8, width: 180 }}
                                      value={option.name}
                                    />
                                  </div>
                                </div>
                              </label>
                            );
                          })} */}
                        </div>

                        {/* {this.state.selected === 1 ? ( */}
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Process Report"}
                              data={this.state.reportsdata}
                              columns={column}
                              options={options}
                            />
                          </ThemeProvider>
                        {/* ) : (
                          ""
                        )} */}

                        {/* {this.state.selected === 2 ? (
                          <ThemeProvider theme={theme}>
                            <MUIDataTable
                              className="table-sm px-3"
                              title={"Immediate process Report"}
                              data={this.state.impfilterdata}
                              columns={columns}
                              options={options}
                            />
                          </ThemeProvider>
                        ) : (
                          ""
                        )} */}
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(ProcesswiseReport);
