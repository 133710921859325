import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { FormikTextField } from '../../CommonComponents/FormField.js';
import DepartmentServices from './DepartmentServices';
import { Spinner } from 'react-bootstrap';
import { Autocomplete } from '@mui/material';
import WarehouseServices from '../Warehouse/WarehouseServices.js';


class NewDepartment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      warehouseList:[],
      name: '',
      warehouse:'',
      whid:'',
      loader:false,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(values) {
     this.setState({loader:true})
    let addwarehouse = { 
      name:values.name,
      whid:this.state.whid,
      tenantid:currentTenant
    }
    DepartmentServices.addDepartment(addwarehouse).then(() => 
    this.props.navigate('/department'))
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })

  }

  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    WarehouseServices.getWarehouses(currentTenant).then((response)=>{
        this.setState({warehouseList:response.data})
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
    
 }


  validate(values) {

    let errors = {}

     if (!values.name|| values.name==='') {
     errors.name = "Department Name is Required";
      }
    if (!values.warehouse || values.warehouse === '') {
      errors.warehouse = 'Org Unit is Required';
    }
    if (!this.state.whid||this.state.whid === '') {
      errors.warehouse = 'Org Unit is Required';
    }
    return errors;
  }

  render() {
     let {id,name,warehouse,warehouseList,whid} = this.state;
 
    return (
      
      <div>
         <Header/>
         <Menu/>
         
        <div className="content-wrapper">
          <div className="card">
            
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/department"}} state= {{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/department">Department</Link>
              </li>
              <li className="breadcrumb-item active"> New Department </li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">New Department</h3>
            </div>

            <div className="card-body">
            
              <Formik
                initialValues={{id,name,warehouse,warehouseList,whid}}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}>
                {({ setFieldValue, values }) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field className="form-control" type="text" name="id" placeholder="Id"/>
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                      <FormikTextField className="form-control"  type="text" name="name" label="Department" onChange={(e)=>this.setState({name:e.target.value})}/>
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                                  <Autocomplete
                                    id="subaccount"
                                    className="subacc-select"
                                    name="warehouse"
                                    options={warehouseList}
                                    getOptionLabel={(option) => option.name}
                                    
                                    getOptionSelected={(option) =>
                                      option.name
                                    }
                                    value={
                                      values.warehouse ? undefined : undefined
                                    }
                                    onChange={(e, value) => {
                                      setFieldValue("warehouse", value);
                                    }}
                                    renderInput={(params) => (
                                      <FormikTextField
                                        {...params}
                                        className="form-control"
                                        type="text"
                                        name="warehouse"
                                        onBlur={(e) =>e.target.value !== ""?
                                          this.setState({
                                            warehouse: e.target.value,
                                            whid: values.warehouse.id,
                                          }):''
                                        }
                                        label="Select Org Unit"
                                        variant="standard"
                                        fullWidth
                                      />
                                    )}
                                  />
                                </fieldset>

                    </div>
                    <div className="form-group col-md-12"/>
                   {this.state.loader===true? <button className="btn btn-sm hovbuttonColour" disabled><Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>:<button className="btn btn-sm hovbuttonColour" type="submit"><i className="fa fa-save mr-1"/>Save</button>}
                    <Link as="button" className="btn btn-sm deletebutton ml-1" to={{pathname: '/department'}} ><b style={{color:"#FA5733"}}> X</b> Cancel </Link> 
                  
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

      <Footer/>
      </div>
    );
  }
}
export default withRouter(NewDepartment)