import React, { useState, Fragment, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

import FormControl from "@mui/material/FormControl";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Autocomplete, Grid } from "@mui/material";
import { currency, currentTenant, currentUser } from "../../Common/Credentials";
import SupplierService from "../../Masters/Suppliers/SupplierService";
import TourServices from "./TourServices";
import moment from "moment";
import AirlineFile from "../JSON/AirlineFile.json"


// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

const rows = [
  // createData(1, 159, "Name", 24, 4.0)
];

const Ticket = (props) => {

const [ticketstore,setTicketstore]=useState([]);



  const [stocktype, setStocktype] = useState("");
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [fullWidth1, setFullWidth1] = React.useState(true);
  const [maxWidth1, setMaxWidth1] = React.useState("md");

  const [open2, setOpen2] = useState(false);
  const [fullWidth2, setFullWidth2] = useState(true);
  const [maxWidth2, setMaxWidth2] = useState("md");

  const [open3, setOpen3] = useState(false);
  const [fullWidth3, setFullWidth3] = useState(true);
  const [maxWidth3, setMaxWidth3] = useState("sm");
  const [supplierdata, setSupplierdata] = useState([]);
  const [open4, setOpen4] = useState(false);
  const [fullWidth4, setFullWidth4] = useState(true);
  const [maxWidth4, setMaxWidth4] = useState("sm");
  const [age, setAge] = useState('');
  const [originalissue, setOriginalissue] = useState("");

  const [supplier, setSupplier] = useState('')
  const [suppliercode, setSuppliercode] = useState('')
  const [supplierName, setSupplierName] = useState('');
  const [airlineData, setAirlineData] = useState([]);

  const [airlinecode, setAirlinecode] = useState("");
  const [airlinename, setAirlinename] = useState("");
  const [chrcode, setChrcode] = useState("");
  const [cropccnicname, setCropccnicname] = useState();
  const [cropccname, setCropccname] = useState();
  const [sector, setSector] = useState();
  const [tickettype, setTickettype] = useState('');
  const [issuedate, setIssuedate] = useState('');
  const [traveldate, setTraveldate] = useState('');
  
  const [returndate, setReturndate] = useState('');

  const [travelclass, setTravelclass] = useState("");
  const [travelclasscode, setTravelclasscode] = useState("");
  const [travelid, setTravelid] = useState("");
  const [paxname, setPaxname] = useState("");
  const [gender, setGender] = useState("");
  const [returnclass, setReturnclass] = useState("");
  const [returnclasscode, setReturnclasscode] = useState("");
  const [bookingstaffname, setBookingstaffname] = useState("");
  const [bookingstaffcode, setBookingstaffcode] = useState("");
  const [noofpax, setNoofpax] = useState(0);
  const [pnrno, setPnrno] = useState("");
  const [ticketingstaffcode, setTicketingstaffcode] = useState("");
  const [ticketingstaffname, setTicketingstaffname] = useState("");
  const [gsd, setGsd] = useState("");
  const [tourcode, setTourcode] = useState("");
  const [loportono, setLoportono] = useState("");
  const [refno, setRefno] = useState("");
  const [custempno, setCustempno] = useState("");
  const [conjticket, setConjticket] = useState("");
  const [region, setRegion] = useState("");
  const [duplicateno, setDuplicateno] = useState("");
  const [ticketno,setTicketno]=useState("");
  const [remarks,setRemarks]=useState("");







  // ------------------- ---------------------------------CHECKEDBOX -----------------------
  const [checkedAdjustment, setCheckedAdjustment] = useState(true);
  const [checkedRestore, setCheckedRestore] = useState(true);
  const [checkedDuplicate, setCheckedDuplicate] = useState(true);



  // ---------------------------------------------- NUMERIC STATE ------------------------------------------------
  const [roeofomr, setRoeofomr] = useState(1.000);
  const [admoracmexp, setAdmoracmexp] = useState(0.000);
  const [publishedinomr, setPublishedinomr] = useState(0.000);
  const [marketfareinomr1, setMarketfareinomr1] = useState(0.000);
  const [marketfareinomr2, setMarketfareinomr2] = useState(0.000);
  const [marketfareinomrlable, setMarketfareinomrlable] = useState(0.000);
  const [taxdetails, setTaxdetails] = useState("");
  const [taxinomr1, setTaxinomr1] = useState(0.000);
  const [taxinomr2, setTaxinomr2] = useState(0.000);
  const [taxinomrlabel, setTaxinomrlable] = useState(0.000);
  const [stdcommisioninomr, setStdcommisioninomr] = useState(0.000);
  const [stdcommisioninomrpercent, setStdcommisioninomrpercent] = useState(0.000);
  const [supplieramount, setSupplieramount] = useState(0.000);

  const [supplieramountlabel, setSupplieramountlable] = useState(0.000);
  const [inputvatorgstn, setInputvatorgstn] = useState(0.000);
  const [inputvatorgstnpercent, setInputvatorgstnpercent] = useState(0.000);
  const [costcenter, setCostCenter] = useState('');
  

  const [numbervalue, setNumbervalue] = useState(1);


  const [servicefee, setServicefee] = useState(0.00);
  const [servicefeePercent, setServicefeePercent] = useState(0.00);

  const [extraearning, setExtraearning] = useState(0.00);
  const [extraearningpercent, setExtraearningpercent] = useState(0.00);

  const [additionalmarkup, setAdditionalmarkup] = useState(0.00);
  const [additionalmarkuppercent, setAdditionalmarkuppercent] = useState(0.00);
  const [airlinedata,setAirlinedata]=useState([]);


  const [disscount, setDisscount] = useState(0.00);
  const [disscountpercent, setDisscountpercent] = useState(0.00);

  const [sellingprice,setSellingprice] =useState(0.00);

  const [Profit, setProfit] = useState(0.00);

  const [airline,setairline]=useState('');

  const [tempTicket,setTempTicket]=useState([]);
  // ---------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------

  useEffect(() => {
    AllDataHandler();
  },[])
// ---------------------------------------------------------------------------------------------------------------------------

const addButtonHandler=(e)=>{
  e.preventDefault();

  console.log('jj')
  let sdata ={
    stocktype:stocktype,
    ticketno:ticketno,
    originalissue:originalissue,
    airlinecode:airlinecode,
    iatadesignator:chrcode,
    airlinename:airlinename,
    issuedate:issuedate.toString(),
    suppliercode:suppliercode,
    suppliername:supplierName,
    traveldate:traveldate,
    corpccnickname:cropccnicname,
    corpccfullname:cropccname,
    returndate:returndate,
    sector:sector,
    tickettype:tickettype,
    travelclass:travelclass,
    travelid:travelid,
    paxname:paxname,
    gender:gender,
    returnclass:returnclass,
    bookingstaffcode:bookingstaffcode,
    bookingstaffname:bookingstaffname,
    totalpaxno:noofpax,
    pnrno:pnrno,
    ticketingstaffcode:ticketingstaffcode,
    ticketingstaffname:ticketingstaffname,
    gds:gsd,
    tourcode:tourcode,
    lopno: loportono,
    custempno:custempno,
    refno:refno,
    remarks:remarks,
    costcenter:costcenter,
    conjtkt:conjticket,
    adjuestmentstatus: checkedAdjustment.toString(),
    restorestatus: checkedRestore.toString(),
    duplicatestatus: checkedDuplicate.toString(),
    roeofrompayable:roeofomr,
    admoracmexp:admoracmexp,
    publishedfareinomr:publishedinomr,
    marketfareinomr1:marketfareinomr1,
    marketfareinomr2:marketfareinomr2,
    basicfare:marketfareinomrlable,
    taxdetails:taxdetails,
    taxinomr1:taxinomr1,
    taxinomr2:taxinomr2,
    stdcommissioninomr:stdcommisioninomr,
    stdcommissioninomrpercent:stdcommisioninomrpercent,
    supplieramtinomr:supplieramount,
    profit:Profit,
    servicefeeinomr:servicefee,
    servicefeeinomrpercent:servicefeePercent,
    extraearninginomr:extraearning,
    extraearninginomrpercent:extraearningpercent,
    additionalmarkupinomr:additionalmarkup,
    additionalmarkupinomrpercent:additionalmarkuppercent,
    discountinomr:disscount,
    discountinomrpercent:disscountpercent,
    sellingpriceinomr:sellingprice,
    tenantid:currentTenant,
 

  }

  setTicketstore([...ticketstore,sdata]);

  props.func([...ticketstore,sdata]);

  
}





  const AllDataHandler = () => {
    SupplierService.retriveAllSuppliers(currentTenant).then(r => {
      setSupplierdata(r.data);
      console.log(r.data, "Supplier Data");
    })
    // TourServices.getAllMAirlineList().then(r=>{
    //   setAirlinedata(r.data);

    // })

    TourServices.getAllMAirlineList().then(r => {
      setAirlinedata(r.data);
      console.log(r.data, "Airline Data");
    })

  }

  // -------------------------------LOGICS for CALCULATION ------------------------
  const changeHandleradmoracm = (e) => {

    setAdmoracmexp(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value).toFixed(3));
    setSupplieramount(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value).toFixed(3))
    setSupplieramountlable(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value))
  }

  const changeHandlerWithSetDataTicketNo=(e)=>{
    setTicketno(e.target.value);
    getticket(e.target.value);
  } 
  const changeHandlerPNR=(e)=>{
    setPnrno(e)
    getticket(e)
  }

  const getticket = (e) =>{
    
    TourServices.getByPNRorNoId(e).then(r=>{
      if(r.data !== "" && r.data !==null && r.data !==undefined){
        console.log(r.data)
      setPnrno(r.data.pnrno);
      setTicketno(r.data.ticketno);
      setAirlinecode(r.data.airlinecode);
      // setAirlinename(r.data.airlinename);
      setChrcode(r.data.iatadesignator);
      setPaxname(r.data.paxname);
      setNoofpax( parseInt(r.data.totalpaxno) );
      setSector(r.data.sector);
      setSupplierName();
      setTravelid(r.data.travelid);
      setTraveldate(moment(new Date(r.data.traveldate)))
      setIssuedate(moment(new Date(r.data.issuedate)).format('DD/MM/YYYY'))
      setReturndate(moment(new Date(r.data.traveldate)))
      setGsd(r.data.gds);
      setTourcode(r.data.tourcode);
      setCustempno(r.data.custempno);
      setSuppliercode(r.data.suppliercode);
      setTaxinomr1(parseFloat(r.data.taxinomr1===undefined || r.data.taxinomr1===null || r.data.taxinomr1==="" ? parseFloat(0) : parseFloat(r.data.taxinomr1)))
      setTaxinomr2(parseFloat(r.data.taxinomr2===undefined || r.data.taxinomr2===null || r.data.taxinomr2==="" ? parseFloat(0) : parseFloat(r.data.taxinomr2)))
      setTaxinomrlable( parseFloat(r.data.taxinomr1===undefined || r.data.taxinomr1===null || r.data.taxinomr1==="" ? parseFloat(0) : parseFloat(r.data.taxinomr1)) + parseFloat(r.data.taxinomr2===undefined || r.data.taxinomr2===null || r.data.taxinomr2==="" ? parseFloat(0) : parseFloat(r.data.taxinomr2)) )
      
      setStdcommisioninomr(  parseFloat(r.data.stdcommissioninomr===undefined || r.data.stdcommissioninomr===null || r.data.stdcommissioninomr==="" ? parseFloat(0) : parseFloat(r.data.stdcommissioninomr))   )
      setStdcommisioninomrpercent(  parseFloat(r.data.stdcommissioninomrpercent===undefined || r.data.stdcommissioninomrpercent===null || r.data.stdcommissioninomrpercent==="" ? parseFloat(0) : parseFloat(r.data.stdcommissioninomrpercent))   )
     
      setSellingprice( parseFloat(r.data.sellingpriceinomr===undefined || r.data.sellingpriceinomr===null || r.data.sellingpriceinomr==="" ? parseFloat(0) : parseFloat(r.data.sellingpriceinomr)))
      setMarketfareinomr1(r.data.basicfare);
      setMarketfareinomrlable(parseFloat(r.data.basicfare) + parseFloat(marketfareinomr2) );
      
      setAirlinename(AirlineFile.find(e=>e.Code === r.data.iatadesignator)?.text)
      
      setSupplieramount( (parseFloat(r.data.basicfare) + parseFloat(marketfareinomr2) + parseFloat(r.data.taxinomr1===undefined || r.data.taxinomr1===null || r.data.taxinomr1==="" ? parseFloat(0) : parseFloat(r.data.taxinomr1)) + parseFloat(r.data.taxinomr2===undefined || r.data.taxinomr2===null || r.data.taxinomr2==="" ? parseFloat(0) : parseFloat(r.data.taxinomr2))) - parseFloat(r.data.stdcommissioninomr===null || r.data.stdcommissioninomr===undefined || r.data.stdcommissioninomr==="" ? 0.0 : r.data.stdcommissioninomr)  )
      setProfit( parseFloat(r.data.stdcommissioninomr===undefined || r.data.stdcommissioninomr===null || r.data.stdcommissioninomr==="" ? parseFloat(0) : parseFloat(r.data.stdcommissioninomr)) );


// ------------------------------Travel class Starts----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

      setTravelclasscode(r.data.travelclasscode===null || r.data.travelclasscode==="" || r.data.travelclasscode===undefined ? "" :(r.data.travelclasscode).toUpperCase())
      if(r.data.travelclasscode==='F' || r.data.travelclasscode==='P' || r.data.travelclasscode==='A' || r.data.travelclasscode==='a' || r.data.travelclasscode==='f' || r.data.travelclasscode==='p' || r.data.travelclasscode==='R')
      {
        setTravelclass("First Class");
        setReturnclass("First Class");
        // if(r.data.returnclasscode==="" || r.data.returnclasscode===null || r.data.returnclasscode===undefined )
        // {
        //   setReturnclass("First Class")
        //   setReturnclasscode(r.data.travelclass);
        // }
        
      }
      else if(r.data.travelclasscode==='J' || r.data.travelclasscode==='C' || r.data.travelclasscode==='j' || r.data.travelclasscode==='c' || r.data.travelclasscode==='D' || r.data.travelclasscode==='I' || r.data.travelclasscode==='Z')
      {
        setTravelclass("Business Class");
        setReturnclass("Business Class");
        // if(r.data.returnclasscode==="" || r.data.returnclasscode===null || r.data.returnclasscode===undefined )
        // {
        //   setReturnclass("First Class")
        //   setReturnclasscode(r.data.travelclass);
        // }
      }
      else if(r.data.travelclasscode==='W' || r.data.travelclasscode==='Y' || r.data.travelclasscode==='w' || r.data.travelclasscode==='y' || r.data.travelclasscode==='B' || r.data.travelclasscode==='E' || r.data.travelclasscode==='G' || r.data.travelclasscode==='H' || r.data.travelclasscode==='K' || r.data.travelclasscode==='L' || r.data.travelclasscode==='M' || r.data.travelclasscode==='N' || r.data.travelclasscode==='O' || r.data.travelclasscode==='Q' || r.data.travelclasscode==='S' || r.data.travelclasscode==='T' || r.data.travelclasscode==='U' || r.data.travelclasscode==='V' || r.data.travelclasscode==='X' || r.data.travelclasscode==='Z')
      {
        setTravelclass("Economy Class");
        setReturnclass("Economy Class");
        // if(r.data.returnclasscode==="" || r.data.returnclasscode===null || r.data.returnclasscode===undefined )
        // {
        //   setReturnclass("First Class")
        //   setReturnclasscode(r.data.travelclass);
        // }
      }
       else
      {
  
        setTravelclass("");
      }
// ------------------------------Travel class end ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
     
// ------------------------------Gender Starts----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
     
      var name = r.data.paxname;
    let res = name.split(" ");
    if(res.includes('MISS') || res.includes('MS') || res.includes('MRS') )
    {
      setGender("Female");
    }
    else if(res.includes('MR'))
    {
      setGender("Male");
    }

  }})


// ------------------------------Gender ends------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
     


  }


  const chnageHandlerIssuedate = (e) => {
    setIssuedate(moment(new Date(e)).format('DD/MM/YYYY'))
  }

  const chnageHandlerTraveldate = (e) => {
    setTraveldate(moment(new Date(e)).format('DD/MM/YYYY'))
  }


  const changeHandlerReturndate = (e) => {
    setReturndate(moment(new Date(e)).format('DD/MM/YYYY'))
    console.log(moment(new Date(e)).format('DD/MM/YYYY'));
  }

  const changeHandlerPublished = (e) => {
    setPublishedinomr(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value))
    if (marketfareinomrlable <= 0) {
      setSupplieramount((parseFloat((parseFloat(admoracmexp === undefined || admoracmexp === null || admoracmexp === "" ? 0.00 : admoracmexp)) + parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value))).toFixed(3))
      setMarketfareinomr1(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value))
      setMarketfareinomrlable((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)))
      setSupplieramountlable(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value) + parseFloat(admoracmexp === undefined || admoracmexp === null || admoracmexp === "" ? 0.00 : admoracmexp))
    }
  }

  const changeHandlerMarkeFare1 = (e) => {
    setMarketfareinomr1(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value).toFixed(3))
    setMarketfareinomrlable((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)).toFixed(3));
    setSupplieramount((parseFloat((parseFloat(admoracmexp === undefined || admoracmexp === null || admoracmexp === "" ? 0.00 : admoracmexp)) + parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value))).toFixed(3))
    setSupplieramountlable(parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value) + parseFloat(admoracmexp === undefined || admoracmexp === null || admoracmexp === "" ? 0.00 : admoracmexp))

    setSellingprice(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) +  parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup)    + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2) + parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1) + parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning)  - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) );

  }
  const changeHandlerMarkeFare2 = (e) => {
   
    setMarketfareinomr2(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value))
    setMarketfareinomrlable((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) + parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)).toFixed(3));
    setSupplieramount((parseFloat((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)) + parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) + (parseFloat(admoracmexp === undefined || admoracmexp === null || admoracmexp === "" ? 0.00 : admoracmexp))).toFixed(3))
  
    setSellingprice(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) +  parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup)    + parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1) + parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning)  - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) );

  }

  const changeHandlerTaxinomr1 = (e) => {
    setTaxinomr1(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value))
    setTaxinomrlable((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) + parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2)).toFixed(3));
    setSupplieramount((parseFloat(admoracmexp === undefined || admoracmexp === null || admoracmexp === "" ? 0.00 : admoracmexp)) + (parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)) + (parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)) + (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) + (parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2)))
    setSupplieramountlable((parseFloat(e.target.value) === null || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value) + parseFloat(admoracmexp === undefined || admoracmexp === null || admoracmexp === "" ? 0.00 : admoracmexp) + parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)).toFixed(3));

    setStdcommisioninomrpercent(((parseFloat(stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr)) / (((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) + ((parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)))))).toFixed(3) * parseFloat(100));
  
    setSellingprice(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) +  parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup)    + parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2) + parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning)  - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) );
  
  }

  const changeHandlerTaxinomr2 = (e) => {
    setTaxinomr2(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value))
    setTaxinomrlable((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) + parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1)).toFixed(3));
    setSupplieramount((parseFloat(admoracmexp === undefined || admoracmexp === null || admoracmexp === "" ? 0.00 : admoracmexp)) + (parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)) + (parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)) + (parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1)) + (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) + (parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2)) + (parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2)))
    setSellingprice(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) +  parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup)    + parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1) + parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning)  - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) );
  
  setSellingprice(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) +  parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup)    + parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1) + parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning)  - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) );
  }

  const changeHandlerstd = (e) => {
    setStdcommisioninomr(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value));
    setStdcommisioninomrpercent((((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) / (((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)) + ((parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)))))).toFixed(3)) * parseFloat(100));

    setSupplieramount(((parseFloat(admoracmexp === undefined || admoracmexp === null || admoracmexp === "" ? 0.00 : admoracmexp)) + (parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)) + (parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)) + (parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1)) + (parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2))) - (parseFloat(e.target.value === null) || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
    setSupplieramountlable(((parseFloat(admoracmexp === undefined || admoracmexp === null || admoracmexp === "" ? 0.00 : admoracmexp)) + (parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)) + (parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1))) - (parseFloat(e.target.value === null) || parseFloat(e.target.value) === undefined || parseFloat(e.target.value) === "" ? 0.00 : parseFloat(e.target.value)))
    setProfit(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value).toFixed(3));


  }

  const changeHandlerstdPercent = (e) => {
    setStdcommisioninomrpercent(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value));
    setStdcommisioninomr((((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) * ((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)) + (parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)))) / parseFloat(100)).toFixed(3));

    setProfit((parseFloat(stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr).toFixed(3)));



    setSupplieramount((((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)) + (parseFloat(admoracmexp === undefined || admoracmexp === null || admoracmexp === "" ? 0.00 : admoracmexp)) + (parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)) + (parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1)) + (parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2))) - (((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) * parseFloat(10)) / parseFloat(100)))).toFixed(3))
    setSupplieramountlable((((parseFloat(admoracmexp === undefined || admoracmexp === null || admoracmexp === "" ? 0.00 : admoracmexp)) + (parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)) + (parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1))) - (((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) * parseFloat(10)) / parseFloat(100)))).toFixed(3))

  }

  const changeHandlerServicefee = (e) => {

    setServicefee(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value));
    setServicefeePercent((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)).toFixed(3) / ((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)) + ((parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)))).toFixed(3) * parseFloat(100).toFixed(3))
    if (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) > 0) 
    {
      setProfit(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) + parseFloat(stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup) )
     
    }
    else {
      setProfit(parseFloat(stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) )
    }
    setSellingprice(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) +  parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)    + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2) + parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1) + parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2) +  parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) );

  }

  const changeHandlerServicefeePercent = (e) => {
    setServicefeePercent(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value).toFixed(3));
    setServicefee((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)).toFixed(3) * (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) / parseFloat(100).toFixed(3))
    if (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) > 0) {
      setProfit((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)).toFixed(3) * (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) / parseFloat(100).toFixed(3) + parseFloat((stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr)) + parseFloat((extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning)) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) )
    }
    else {
      setProfit(parseFloat(stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) )
    }

    setSellingprice((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)).toFixed(3) * (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) / parseFloat(100).toFixed(3) +  parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)    + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2) + parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1) + parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2) +  parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) );
  }

  const changeHandlerExtraEraning = (e) => {
    setExtraearning(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value));
    setExtraearningpercent((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)).toFixed(3) / ((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)) + ((parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)))).toFixed(3) * parseFloat(100).toFixed(3))
    if (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) > 0) {
      setProfit(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) + parseFloat((stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr)) + parseFloat((servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee)) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup) )

    }
    else {
      setProfit(parseFloat(stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup)   )
    }

    setSellingprice(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) +  parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)    + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2) + parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1) + parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) );
    
  }

  const changeHandlerExtraEraningPercent = (e) => {
    setExtraearningpercent(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value).toFixed(3));
    setExtraearning((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)).toFixed(3) * (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) / parseFloat(100).toFixed(3))
    if (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) > 0) {
      setProfit((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)).toFixed(3) * (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) / parseFloat(100).toFixed(3) + parseFloat(stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup)  )
    }
    else {
      setProfit((parseFloat((stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr)) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee)) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup)   )
    }

    setSellingprice((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)).toFixed(3) * (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) / parseFloat(100).toFixed(3) +  parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)    + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2) + parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1) + parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) );
  }

  const chnageHandlerAdditionalMarkup = (e) => {
    setAdditionalmarkup(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value));
    setAdditionalmarkuppercent((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)).toFixed(3) / ((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)) + ((parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)))).toFixed(3) * parseFloat(100).toFixed(3))
    if (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) > 0) {
      setProfit(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) + parseFloat(stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount)  )
    }
    else {
      setProfit(parseFloat(stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount)  )
    }

    setSellingprice(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) +  parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)    + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2) + parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1) + parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning)  - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) );
  }

  const chnageHandlerAdditionalMarkupPercent = (e) => {
    setAdditionalmarkuppercent(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value).toFixed(3));
    setAdditionalmarkup((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)).toFixed(3) * (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) / parseFloat(100).toFixed(3))
    if (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) > 0) {
      setProfit((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)).toFixed(3) * (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) / parseFloat(100).toFixed(3) + parseFloat(stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) )
    }
    else {
      setProfit(parseFloat((stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr)) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) )
    }

    setSellingprice((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)).toFixed(3) * (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) / parseFloat(100).toFixed(3) +  parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)    + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2) + parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1) + parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning)  - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount) );

  }



  const changeHandlerDisscount = (e) => {
    setDisscount(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value));
    setDisscountpercent((parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)).toFixed(3) / ((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)) + ((parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)))).toFixed(3) * parseFloat(100).toFixed(3))
    if (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) > 0) {
      setProfit( parseFloat(stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup) - parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value).toFixed(3) )
    }
    else {
      setProfit(parseFloat(stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup).toFixed(3)  )
    }

    setSellingprice( parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1)    + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2) + parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1) + parseFloat(taxinomr2 === undefined || taxinomr2 === null || taxinomr2 === "" ? 0.00 : taxinomr2) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup) - parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) );
  }

  const changeHandlerdisscountPercent = (e) => {
    setDisscountpercent(parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value).toFixed(3));
    setDisscount((parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)).toFixed(3) * (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) / parseFloat(100).toFixed(3))
    if (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value) > 0) {
      setProfit( (parseFloat(stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup)) - (parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2)).toFixed(3) * (parseFloat(e.target.value === null || e.target.value === undefined || e.target.value === "" ? 0.00 : e.target.value)) / parseFloat(100).toFixed(3))
    }
    else {
      setProfit(parseFloat((stdcommisioninomr === undefined || stdcommisioninomr === null || stdcommisioninomr === "" ? 0.00 : stdcommisioninomr)) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup)  )
    }
  }





  //  -----------------------------------------------------------------------------------

  const changeHandlerClear=(e)=>{
    e.preventDefault();
    setStocktype('');
    setTicketno('');
    setOriginalissue('');
    setAirlinecode(''),
    setChrcode('');
    setAirlinename('')
    setIssuedate('');
    setSuppliercode('')
    setSupplierName('')
    setTraveldate();
    setCropccnicname(''),
    setCropccname('')
    setReturndate(''),
    setSector('');
    setTickettype('');
    setTravelclass('');
    setTravelid('');
    setPaxname('');
    setGender('');
    setMarketfareinomrlable(0);
    setReturnclass('');
    setBookingstaffcode('');
    setBookingstaffname('');
    setNoofpax(''),
    setPnrno('');
    setTicketingstaffcode('');
    setTicketingstaffname('');
    setGsd('');
    setTourcode('');
    setLoportono('');
    setCustempno('');
    setRefno();
    setRemarks('');
    // setCostcenter('');
    setConjticket('');
    
    setRoeofomr(0);
    setAdmoracmexp(0);
    setPublishedinomr(0);
    setMarketfareinomr1(0);
    setMarketfareinomr2(0);
    setTaxdetails("");
    setTaxinomr1(0);
    setTaxinomr2(0);
    setStdcommisioninomr(0);
    setStdcommisioninomrpercent(0);
    setSupplieramount(0);
    setProfit(0);
    setServicefee(0);
    setServicefeePercent(0);
    setExtraearning(0);
    setExtraearningpercent(0);
    setAdditionalmarkup(0);
    setAdditionalmarkuppercent(0);
    setDisscount(0);
    setDisscountpercent(0);
    setSellingprice(0);
  
  }


  // ---------- ---------------------------checked Box ----------------

  const changeHandlerAdjustment = (event) => {
    setCheckedAdjustment(event.target.checked);
  }

  const changeHandlerRestore = (event) => {
    setCheckedRestore(event.target.checked);
  }

  const changeHandlerDuplicate = (event) => {
    setCheckedDuplicate(event.target.checked);
    setNumbervalue()
  }




  // ------------------------------------------------------------------
  //  -----------------------------------------------------------------------------------
  // const changeHandlerStock = (e) => {
  //   setStocktype(e.target.value);
  // }
  const OriginalIssueHandler = (e) => {
    setOriginalissue(e.target.value);
  }

  const handleChange = (event) => {
    setStocktype(event.target.value);
  };

  const handleChangeTicket = (event) => {
    setTickettype(event.target.value);
  };

  // const handleChangeTravelclasscode = (event) => {
  //   setTravelclasscode(event.target.value);
  // };


  const handleChangeTravelclass = (event) => {
    setTravelclass(event.target.value);
  };

  const changeHandlerPaxName = (event) => {
    setPaxname(event.target.value);
  };

  const changeHandlerReturnClass = (event) => {
    setReturnclass(event.target.value);
  };

  const changeHandlerBookingStaffCode = (event) => {
    setBookingstaffcode(event.target.value);
  };
  const changeHandlerBookingStaffName = (event) => {
    setBookingstaffname(event.target.value);
  };

  const changeHandlerGsd = (event) => {
    setGsd(event.target.value);
  };
  const changeHandlerGender = (event) => {
    setGender(event.target.value);
    

  };

  
  const changeHandlerRegion = (event) => {
    setRegion(event.target.value);
  };



  // -----------------------------------------------------------------
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen1 = () => {
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };

  // const handleClickOpen2 = () => {
  //   setOpen2(true);
  // };
  // const handleClose2 = () => {
  //   setOpen2(false);
  // };

  const handleClickOpen3 = () => {
    setOpen3(true);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  // const handleClickOpen4 = () => {
  //   setOpen4(true);
  // };

  // const handleClose4 = () => {
  //   setOpen4(false);
  // };

  const validButtonHandler = () => {
      window.alert("This feature will soon been avaliable");
  }

  // console.log( new Date(),'datreee')
  console.log( moment()
  .utcOffset('+05:30')
  .format('MM-DD-YYYY'),'datreee222')

  // const handleAirlinerNameChange = (value, values) => {
    
  //   if (values !== null && typeof values === 'object' && values !== undefined) {
  //     setAirlinename(values.airlinename);
  //     setAirlinecode(values.id);
  //     setChrcode(values.chrcode)
  //     console.log(values.airlinename);
  //   }
  // }


  const handleAirlinerNameChange = (value, values) => {
    if (values !== null && typeof values === 'object' && values !== undefined) {
      setAirlinename(values.airlinename);
      setAirlinecode(values.id);
      setChrcode(values.chrcode);
      // console.log(values.companyname);
    }
  }
  const handleAirlineCodeChange = (e) => {
    setAirlinecode(e.target.value)
    let findariline = airlinedata.find(a => a.id === e.target.value)
    if (findariline !== null && findariline !== undefined) {
      setAirlinename(findariline.airlinename)
      setAirlinecode(findariline.airlinecode)
      setChrcode(findariline.iatadesignator)

    } else {
      setAirlinename('')
      setAirlinecode('')
      setChrcode('');
    }


  }

// ---------------------------------------------------------------------------------------------------------------------

  const handleSupplierNameChange = (value, values) => {
    if (values !== null && typeof values === 'object' && values !== undefined) {
      setSupplierName(values.companyname);
      setSuppliercode(values.id);
      // console.log(values.companyname);
    }
  }
  const handleSupplierCodeChange = (e) => {
    setSuppliercode(e.target.value)
    let findsupplier = supplierdata.find(a => a.id === e.target.value)
    if (findsupplier !== null && findsupplier !== undefined) {
      setSupplierName(findsupplier.companyname)
    } else {
      setSupplierName('')
      setSuppliercode('')
    }

  }

  const changeHadlerCostCenter = (e) => {
    setCostCenter("HEAD OFFICE")
  }

  const ChangeHandlerTravelClasscode=(e)=>{
    setTravelclasscode(e.target.value===null || e.target.value==="" || e.target.value===undefined ? "" :(e.target.value).toUpperCase())
    if(e.target.value==='F' || e.target.value==='P' || e.target.value==='A' || e.target.value==='a' || e.target.value==='f' || e.target.value==='p')
    {
      setTravelclass("First Class");
    }
    else if(e.target.value==='J' || e.target.value==='C' || e.target.value==='j' || e.target.value==='c')
    {
      setTravelclass("Business Class");
    }
    else if(e.target.value==='W' || e.target.value==='Y' || e.target.value==='w' || e.target.value==='y')
    {
      setTravelclass("Economy Class");
    }
     else
    {

      setTravelclass("");
    }


    

  }

  const ChangeHandlerReturnClasscode=(e)=>{
    setReturnclasscode(e.target.value===null || e.target.value==="" || e.target.value===undefined ? "" :(e.target.value).toUpperCase())
    if(e.target.value==='F' || e.target.value==='P' || e.target.value==='A' || e.target.value==='a' || e.target.value==='f' || e.target.value==='p')
    {
      setReturnclass("First Class");
    }
    else if(e.target.value==='J' || e.target.value==='C' || e.target.value==='j' || e.target.value==='c')
    {
      setReturnclass("Business Class");
    }
    else if(e.target.value==='W' || e.target.value==='Y' || e.target.value==='w' || e.target.value==='y')
    {
      setReturnclass("Economy Class");
    }
    else
    {
      setReturnclass("");
    }

  }





  return (
    <div>
      <div className="small-container mp-2 padding-2 w-100%" style={{ fontFamily: "Nunitoregula" }}>
        <div className="">
          <div className="">
            <div className="card p-4">
              <div className="row my-1">
                <fieldset className="col-md-3 col-sm-12">
                  <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">Stock type</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={stocktype===null || stocktype==="" || stocktype===undefined ? "" : stocktype}
                      label="Stock Type"
                      onChange={handleChange}
                      size="small"
                      variant="outlined"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value="STOCK">STOCK</MenuItem>
                      <MenuItem value="LCC">LCC</MenuItem>
                    </Select>
                  </FormControl>
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    fullWidth
                    label="Ticket No."
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={ticketno===null||ticketno===""||ticketno===undefined?"":ticketno}
                    onChange={(e)=>changeHandlerWithSetDataTicketNo(e)}
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    fullWidth
                    label="Original Issue"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={originalissue===null||originalissue===""||originalissue===undefined?"":originalissue}
                    onChange={OriginalIssueHandler}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    fullWidth
                    label="Airline code"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={airlinecode===null||airlinecode===""||airlinecode===undefined?"":airlinecode}
                    onChange={handleAirlineCodeChange}
                  />
                </fieldset>
                <fieldset className="col-md-1 col-sm-12">
                  <TextField
                    fullWidth
                    label="IATA Designator"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={chrcode===null||chrcode===""||chrcode===undefined?"":chrcode}
                    onChange={(e)=>setChrcode(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  {/* <Autocomplete
                    id="airname"
                    className="subacc-select"
                    name="Airlinename"
                    options={airlinedata}
                    getOptionLabel={(option) => option.airlinename}
                    value={airlinename ? undefined : undefined}
                    onChange={handleAirlinerNameChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        className="form-control"
                        type="text"
                        size="small"
                        name="Airlinename"
                        value={airlinename===null||airlinename===""||airlinename===undefined?"":airlinename}
                        label="Select Airline "
                        variant="outlined"
                        fullWidth
                      />
                    )}
                  /> */}
                  <TextField
                    fullWidth
                    label="Airline Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={airlinename===null||airlinename===""||airlinename===undefined?"":airlinename}
                    onChange={(e)=>setAirlinename(e.target.value)}
                  />
                </fieldset>
                <div className="col-md-4 col-sm-12">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container >
                      <DatePicker
                      // defaultValue={moment()
                      //   .utcOffset('+05:30')
                      //   .format('YYYY-MM-DD')}
                        variant="inline"
                        id="date-picker-dialog"
                        label="Issue Date "
                        value={issuedate}
                        onChange={(e)=>chnageHandlerIssuedate(e)}
                        renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                        autoOk={true}
                      />
                    </Grid>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="row ">
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    label=" Supplier Code"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    onChange={handleSupplierCodeChange}
                    value={suppliercode===null||suppliercode===""||suppliercode===undefined?"":suppliercode}
                  />
                </fieldset>

                <fieldset className="form-group col-md-5 col-sm-12">
                  <Autocomplete
                    id="supname"
                    className="subacc-select"
                    name="Supplier Name"
                    options={supplierdata}
                    getOptionLabel={(option) => option.companyname}
                    value={supplierName ? undefined : undefined}
                    onChange={handleSupplierNameChange}
                    renderInput={params => (
                      <TextField
                        {...params}
                        className="form-control"
                        type="text"
                        name="SupplierName"
                        value={supplierName===null||supplierName===""||supplierName===undefined?"":supplierName}
                        label="Select Supplier Name"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  {/* <TextField
                    label=" Supplier Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    onChange={(e)=>setSupplierName(e.target.value)}
                    value={supplierName===null||supplierName===""||supplierName===undefined?"":supplierName}
                  /> */}
                  
                </fieldset>
                <div className="col-md-4 col-sm-12">
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      slotProps={{ textField: { size: "small" } }}
                      required
                      label="Travel Date"
                    />
                  </LocalizationProvider> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container >
                      <DatePicker
                        variant="inline"
                        id="date-picker-dialog"
                        label="Travel Date "
                        value={traveldate}
                        onChange={(e)=>chnageHandlerTraveldate(e)}
                        renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                        autoOk={true}
                      />
                    </Grid>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    label="Corp. CC Nick Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={cropccnicname===null||cropccnicname===""||cropccnicname===undefined?"":cropccnicname}
                    onChange={(e)=>{
                      setCropccname(e.target.value)
                    }}
                  />
                </fieldset>
                <fieldset className="col-md-5 col-sm-12">
                  <TextField
                    fullWidth
                    label="Corp. CC Full Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={cropccname===null||cropccname===""||cropccname===undefined?"":cropccname}
                    onChange={(e)=>setCropccname(e.target.value) }
                  />
                </fieldset>
                <div className="col-md-4 col-sm-12" >
                  
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container >
                      <DatePicker
                        variant="inline"
                        id="date-picker-dialog"
                        label="Return Date "
                        format="MM/DD/YYYY"
                        // maxDate={new Date()}
                        value={returndate}
                        onChange={(e)=>changeHandlerReturndate(e)}
                        renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                        autoOk={true}
                      />
                    </Grid>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    fullWidth
                    label="Sector"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={sector===null||sector===""||sector===undefined?"":sector}
                    onChange={(e)=>setSector(e.target.value===null||e.target.value===""||e.target.value===undefined?"":e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12">
                  <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">Ticket type</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={tickettype}
                      label="Ticket type"
                      onChange={(e)=>handleChangeTicket(e)}
                      size="small"
                    >
                      <MenuItem value="">
                        <em>Choose</em>
                      </MenuItem>
                      <MenuItem value="VMPD">VMPD</MenuItem>
                      <MenuItem value="Business class">Business class</MenuItem>
                      <MenuItem value="MPD">MPD</MenuItem>
                      <MenuItem value="ET">ET</MenuItem>
                      <MenuItem value="EMD">EMD</MenuItem>
                      <MenuItem value="AUTO">AUTO</MenuItem>
                      <MenuItem value="ATB2">ATB2</MenuItem>
                    </Select>
                  </FormControl>
                </fieldset>

                <fieldset className="col-md-1 col-sm-12">
                  <TextField
                    label="Travel Class Code"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={travelclasscode}
                    onChange={(e)=>ChangeHandlerTravelClasscode(e)}
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">Travel Class</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={travelclass}
                      label="Travel Class"
                      onChange={handleChangeTravelclass}
                      size="small"
                    >
                      <MenuItem value="">
                        <em>Choose</em>
                      </MenuItem>
                      <MenuItem value="First Class">First Class</MenuItem>
                      <MenuItem value="Business Class">Business Class</MenuItem>
                      <MenuItem value="Economy Class">Economy Class</MenuItem>
                    </Select>
                  </FormControl>
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-2 my-1 col-sm-12">
                  <TextField
                    fullWidth
                    label="Traveler ID"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value ={travelid===null||travelid===""||travelid===undefined?"":travelid}
                    onChange={(e)=>setTravelid(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-3 my-1 col-sm-12">
                  <TextField
                    fullWidth
                    label="Pax Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={paxname===null||paxname===""||paxname===undefined?"":paxname}
                    onChange={changeHandlerPaxName}
                  />
                </fieldset>
                
                <fieldset className="col-md-3 col-sm-12">
                  <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">Gender</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={gender}
                      label="Gender"
                      onChange={changeHandlerGender}
                      size="small"
                    >
                      <MenuItem value="">
                        <em>Choose</em>
                      </MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Transgender">Transgender</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </fieldset>
                {/* <div className="col-md-1 col-sm-12 my-1">
                  <button className="btn btn-sm " onClick={validButtonHandler}>
                    <i
                      className="fas fa-arrow-up fa-lg"
                      style={{ color: "#48f465" }}
                    ></i>
                  </button>
                </div> */}
                <fieldset className="col-md-1 col-sm-12 my-1">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    tytpe="text"
                    label="Return Class Code"
                    value={returnclasscode}
                    onChange={(e)=>ChangeHandlerReturnClasscode(e)}

                  />
                </fieldset>
                <fieldset className="col-md-3 col-sm-12">
                  <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">Return Class</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={returnclass}
                      label="Return Class"
                      onChange={changeHandlerReturnClass}
                      size="small"
                    >
                      <MenuItem value="">
                        <em>Choose</em>
                      </MenuItem>
                      <MenuItem value="First Class">First Class</MenuItem>
                      <MenuItem value="Business class">Business class</MenuItem>
                      <MenuItem value="Economy Class">Economy Class</MenuItem>
                    </Select>
                  </FormControl>
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-2">
                  <TextField
                    label="Booking Staff Code"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={bookingstaffcode===null||bookingstaffcode===""||bookingstaffcode===undefined?"":bookingstaffcode}
                    onChange={changeHandlerBookingStaffCode}
                  />
                </fieldset>
                {/* <fieldset className="col-md-1 col-sm-12">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                  />
                </fieldset> */}
                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    fullWidth="true"
                    label="Booking Staff Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={bookingstaffname===null||bookingstaffname===""||bookingstaffname===undefined?"":bookingstaffname}
                    onChange={changeHandlerBookingStaffName}
                  />
                </fieldset>
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    fullWidth
                    label="No. of Pax"
                    id="outlined-size-small"
                    size="small"
                    type="number"
                    variant="outlined"
                    value={noofpax===null||noofpax===""||noofpax===undefined?"":noofpax}
                    onChange={(e)=>setNoofpax(e.target.value)}
                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    fullWidth
                    label="PNR No."
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={pnrno===null||pnrno===""||pnrno===undefined?"":pnrno}
                    onChange={(e)=>changeHandlerPNR(e.target.value)}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    label="Ticketing Staff Code"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={ticketingstaffcode===null||ticketingstaffcode===""||ticketingstaffcode===undefined?"":ticketingstaffcode}
                    onChange={(e)=>setTicketingstaffcode(e.target.value)}
                  />
                </fieldset>
                
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    fullWidth
                    label="Ticketing Staff Name"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={ticketingstaffname===null||ticketingstaffname===""||ticketingstaffname===undefined?"":ticketingstaffname}
                    onChange={(e)=>setTicketingstaffname(e.target.value)}

                  />
                </fieldset>

                <fieldset className="col-md-3 col-sm-12">
                  <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">GDS</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={gsd}
                      label="GDS"
                      onChange={changeHandlerGsd}
                      size="small"
                    >
                      <MenuItem value="">
                        <em>Choose GDS</em>
                      </MenuItem>
                      <MenuItem value="Amadeus">Amadeus</MenuItem>
                      <MenuItem value="Galileo">Galileo</MenuItem>
                      <MenuItem value="Sabre">Sabre</MenuItem>
                      <MenuItem value="World Span">World Span</MenuItem>
                      <MenuItem value="Farelogix">Farelogix</MenuItem>
                      <MenuItem value="Airline">Airline</MenuItem>
                      <MenuItem value="Online">Online</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </fieldset>

                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    fullWidth
                    label="Tour Code"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={tourcode===null||tourcode===""||tourcode===undefined?"":tourcode}
                    onChange={(e)=>setTourcode(e.target.value)}

                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    label="LPO/TO No."
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={loportono===null||loportono===""||loportono===undefined?"":loportono}
                    onChange={(e)=>setLoportono(e.target.value)}


                  />
                </fieldset>
                <fieldset className="col-md-2 col-sm-12">
                  <TextField
                    label=" Cust. Emp. No"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={custempno===null||custempno===""||custempno===undefined?"":custempno}
                    onChange={(e)=>setCustempno(e.target.value)}


                  />
                </fieldset>

                <fieldset className="col-md-3 col-sm-12">
                  <TextField
                    label="Ref.No"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={refno===null||refno===""||refno===undefined?"":refno}
                    onChange={(e)=>setRefno(e.target.value)}

                  />
                </fieldset>
                <fieldset className="col-md-4 col-sm-12">
                  <TextField
                    fullWidth
                    label="Remarks"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={remarks===null||remarks===""||remarks===undefined?"":remarks}
                    onChange={(e)=>setRemarks(e.target.value)}
                  />
                </fieldset>
              </div>

              <div className="row ">
                <fieldset className="col-md-3 col-sm-12">
                  <FormControl sx={{ minWidth: 150 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">Cost Centre</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={costcenter}
                      label="Cost Centre"
                      onChange={changeHadlerCostCenter}
                      size="small"
                    >

                      <MenuItem value="HEAD OFFICE">HEAD OFFICE</MenuItem>
                    </Select>
                  </FormControl>
                </fieldset>
                <fieldset className="col-md-1 my-1 col-sm-12">
                  <TextField
                    lable="Conj.Tkt"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={conjticket===null||conjticket===""||conjticket===undefined?"":conjticket}
                    onChange={(e)=>setConjticket(e.target.value)}
                  />
                </fieldset>
                <div className="col-md-1 my-1 col-sm-12">
                  <InputLabel>0</InputLabel>
                </div>
                <fieldset className="col-md-1 col-sm-12">
                  <FormControl sx={{ minWidth: 120 }} size="small" fullWidth>
                    <InputLabel id="demo-select-small">Region</InputLabel>
                    <Select
                      disabled
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={region}
                      label="Region"
                      onChange={changeHandlerRegion}
                      size="small"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value=" "> - </MenuItem>
                    </Select>
                  </FormControl>
                </fieldset>
                <fieldset className="col-md-1 col-sm-12">
                  <InputLabel> Adjustment</InputLabel>
                  <Checkbox
                    size="sm"
                    checked={checkedAdjustment}
                    onChange={changeHandlerAdjustment}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </fieldset>
                <fieldset className="col-md-1 col-sm-12">
                  <InputLabel>Restore </InputLabel>
                  <Checkbox
                    size="sm"
                    checked={checkedRestore}
                    onChange={changeHandlerRestore}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </fieldset>
                <fieldset className="col-md-1 col-sm-12">
                  <InputLabel>Duplicate</InputLabel>
                  <Checkbox
                    size="sm"
                    checked={checkedDuplicate}
                    onChange={changeHandlerDuplicate}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </fieldset>
                <fieldset className="col-md-1 col-sm-12 my-1">
                  <TextField
                    label=""
                    type="number"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    defaultValue="1"
                    value={duplicateno }
                    onChange={(e)=>setDuplicateno(e.target.value)}
                    disabled={checkedDuplicate === false}
                  />
                </fieldset>
              </div>
            </div>
          </div>

          {/* --------------------------------------------------- */}
          <div className="row mx-1">
            <div className="card p-4 col-md-6">
              <div className="row my-1">
                <fieldset className="col-md-6">
                  <TextField
                    label={`ROE of ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={roeofomr}
                    onChange={(e) => setRoeofomr(parseFloat(1).toFixed(3))}
                  />
                </fieldset>
                <fieldset className="col-md-6">
                  <TextField
                    label="ADM/ACM Exp"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={admoracmexp}
                    onChange={changeHandleradmoracm}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-6">
                  <TextField
                    label={`Published Fare in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={publishedinomr}
                    onChange={changeHandlerPublished}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-4">
                  <TextField
                    label={`Market Fare 1 in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    onChange={changeHandlerMarkeFare1}
                    value={marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1}

                  />
                </fieldset>
                <fieldset className="col-4">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    label={`Market Fare 2 in ${currency}`}
                    variant="outlined"
                    type="number"
                    onChange={changeHandlerMarkeFare2}
                    value={marketfareinomr2 === undefined || marketfareinomr2 === null || marketfareinomr2 === "" ? 0.00 : marketfareinomr2}
                  />
                </fieldset>
                <div className="col-3">
                  <InputLabel >{marketfareinomrlable === undefined || marketfareinomrlable === null || marketfareinomrlable === "" ? 0.00 : marketfareinomrlable}</InputLabel>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-9">
                  <TextField
                    fullWidth
                    label="Tax Details"
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="text"
                    value={taxdetails}
                    onChange={(e) => setTaxdetails(e.target.value === null || e.target.value === undefined || e.target.value === "" ? "" : e.target.value)}
                  />
                </fieldset>
                {/* <div className="col-md-3 my-1">
                  <Fragment>
                    <button>
                      <i
                        class="fas fa-folder-plus"
                        variant="outlined"
                        onClick={handleClickOpen4}
                      ></i>
                    </button>
                    <Dialog
                      fullWidth={fullWidth4}
                      maxWidth={maxWidth4}
                      open={open4}
                      onClose={handleClose4}
                    >
                      <DialogTitle
                        className="ms-4"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        Tax Details
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          noValidate
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                          }}
                          multiline
                        >
                          <div className="card ">
                            <div className="row my-1 ms-2">
                              <label
                                className="col-md-6 col-sm-12"
                                style={{
                                  textAlign: "center",
                                  fontStretch: "ultra-condensed",
                                }}
                              >
                                Code
                              </InputLabel>
                              <label
                                className="col-md-6 col-sm-12"
                                style={{
                                  textAlign: "center",
                                  fontStretch: "ultra-condensed",
                                }}
                              >
                                Value
                              </InputLabel>
                            </div>
                            <div className="row my-1 ms-1 me-1">
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-1 me-1">
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-1 me-1">
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-1 me-1">
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-1 me-1">
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-1 me-1">
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-1 me-1">
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-1 me-1">
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-1 me-1">
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-1 me-1">
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-md-6 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-1 me-1">
                              <button
                                className="ms-2 col-md-2 col-sm-12 "
                                style={{ alignItems: "center" }}
                              >
                                {" "}
                                Set
                              </button>
                            </div>
                          </div>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose4}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div> */}
              </div>

              <div className="row my-1">
                <fieldset className="col-md-6">
                  <TextField
                    label={`Tax in  ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    value={taxinomr1}
                    onChange={changeHandlerTaxinomr1}
                  />
                </fieldset>
                <fieldset className="col-3">
                  <TextField
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={taxinomr2}
                    onChange={changeHandlerTaxinomr2}
                  />
                </fieldset>
                <div className="col-3">
                  <InputLabel>{taxinomrlabel === undefined || taxinomrlabel === null || taxinomrlabel === "" ? 0.00 : taxinomrlabel}</InputLabel>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 my-1">
                  <TextField
                    label={` STD Commission in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={stdcommisioninomr}
                    onChange={changeHandlerstd}
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-12">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-6 my-1">
                  <TextField
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={stdcommisioninomrpercent}
                    onChange={changeHandlerstdPercent}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-6">
                  <TextField
                    label={` Supplier Amount in  ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={supplieramount}
                    onChange={(e) => setSupplieramount(e.target.value)}
                  />
                </fieldset>
                <div className="col-3">
                  <InputLabel>{supplieramountlabel}</InputLabel>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-5">
                  <TextField
                    label={`Input VAT in  ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    disabled
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-12">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-3">
                  <TextField
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    disabled
                  />
                </fieldset>
                <div className="col-3">
                  <button className="btn">
                    <i class="fas fa-redo-alt"></i>
                  </button>
                </div>
              </div>
            </div>
            {/* ------------------- */}
            <div className="card p-4 col-md-6 col-md-6">
              <div className="row my-1">
                <fieldset className="col-md-4 col-sm-6">
                  <TextField
                    label={`ROE of ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    disabled
                  />
                </fieldset>

                <div className="col-md-2 col-sm-6">
                  <InputLabel>1</InputLabel>
                </div>
                <div className="col-md-3 col-sm-6">
                  <InputLabel>Profit : </InputLabel>
                </div>
                <div className="col-md-2 col-sm-6">
                  <InputLabel>{Profit}</InputLabel>
                </div>
                <div className="col-md-2 col-sm-6">
                  {/* <button>
                    <i class="far fa-pen"></i>
                  </button> */}
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-6">
                  <TextField
                    label={`Service Fee in ${currency} `}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={servicefee}
                    onChange={changeHandlerServicefee}
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-6">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-6 col-sm-6">
                  <TextField
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={servicefeePercent}
                    onChange={changeHandlerServicefeePercent}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 col-sm-6">
                  <TextField
                    label={` Extra Earning in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={extraearning}
                    onChange={changeHandlerExtraEraning}
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-6">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-6 col-sm-6">
                  <TextField
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={extraearningpercent}
                    onChange={changeHandlerExtraEraningPercent}
                  />
                </fieldset>
              </div>

              <div className="row my-1">
                <div className="col-3 ms-4">
                  <InputLabel>Payback Account</InputLabel>
                </div>
                <div className="col-md-3 my-1">
                  <Fragment>
                    <span>
                      <i
                        class="fas fa-folder-plus"
                        variant="outlined"
                        onClick={handleClickOpen1}
                      ></i>
                    </span>
                    <Dialog
                      fullWidth={fullWidth1}
                      maxWidth={maxWidth1}
                      open={open1}
                      onClose={handleClose1}
                    >
                      <DialogTitle
                        className="ms-4"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        Multiple Payback Account
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          noValidate
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                            minWidth: "400px",
                          }}
                          multiline
                        >
                          <div className="row my-1 ms-2">
                            <fieldset className="col-md-4 col-sm-12">
                              <TextField
                                fullWidth="true"
                                label="Payback Account"
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                              />
                            </fieldset>
                            <fieldset className="col-md-3 col-sm-12">
                              <TextField
                                fullWidth="true"
                                label=""
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                              />
                            </fieldset>
                            <fieldset className="col-md-3 col-sm-12">
                              <TextField
                                fullWidth="true"
                                label="Account"
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                              />
                            </fieldset>
                            <div className="col-md-2 col-sm-12">
                              {/* <span
                                className="btn btn-sm btnbuttonColour d-flex"
                                style={{ float: "right" }}
                              >
                                Add{" "}
                                <i
                                  className="fa fa-plus fa-xl ms-2"
                                  style={{
                                    color: "#1fe035",
                                    fontSize: "25px",
                                  }}
                                >
                                  {" "}
                                </i>
                              </span> */}
                            </div>
                          </div>
                          <div className="row my-1 ms-2">
                            <div className="col-md-12 col-sm-12">
                              <TableContainer component={Paper}>
                                <Table
                                  sx={{ minWidth: 650 }}
                                  aria-label="simple table"
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Sl No.</TableCell>
                                      <TableCell align="right">Code</TableCell>
                                      <TableCell align="right">Name</TableCell>
                                      <TableCell align="right">
                                        {`Ammount ${currency} `}
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {rows.map((row) => (
                                      <TableRow
                                        // key={row.Sl_No}
                                        sx={{
                                          "&:last-child td, &:last-child th": {
                                            border: 0,
                                          },
                                        }}
                                      >
                                        <TableCell component="th" scope="row">
                                          {row.Sl_No}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.Code}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.Name}
                                        </TableCell>
                                        <TableCell align="right">
                                          {row.Account}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </div>
                          </div>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose1}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div>
              </div>

              <div className="row my-1">
                <fieldset className="col-md-5 my-1 col-sm-6">
                  <TextField
                    label={`Payback Amount in  ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-6">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-4 my-1 col-sm-6">
                  <TextField
                    fullWidth
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                  />
                </fieldset>
                <div className="col-md-2 my-1">
                  <button className="btn">
                    <i className="fas fa-sync" />
                  </button>
                </div>
              </div>
              <div className="row my-1">
                <fieldset className="col-md-5 my-1 col-sm-6">
                  <TextField
                    label={`Additional Mark up in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={additionalmarkup}
                    onChange={chnageHandlerAdditionalMarkup}
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-6">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-6 my-1 col-sm-6">
                  <TextField
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={additionalmarkuppercent}
                    onChange={chnageHandlerAdditionalMarkupPercent}

                  />
                </fieldset>
              </div>
              <div className="row my-1">
                <fieldset className="col-md-5 my-1 col-sm-6">
                  <TextField
                    label={`Discount in ${currency} `}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={disscount}
                    onChange={changeHandlerDisscount}
s
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-6">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-4 my-1 col-sm-6">
                  <TextField
                    fullWidth
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={disscountpercent}
                    onChange={changeHandlerdisscountPercent}
                  />
                </fieldset>
                <div className="col-md-2 col-sm-12 my-1">
                  <Fragment>
                    <button className="btn">
                      <i
                        class="fas fa-caret-square-up"
                        variant="outlined"
                        onClick={handleClickOpen3}
                      ></i>
                    </button>
                    <Dialog
                      fullWidth={fullWidth3}
                      maxWidth={maxWidth3}
                      open={open3}
                      onClose={handleClose3}
                    >
                      <DialogTitle
                        className="ms-4"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        {`Multi-Discount-Details ${currency}`}
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          noValidate
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                          }}
                          multiline
                        >
                          <div className="card">
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-10 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label="Normal Discount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-10 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label="PLB Discount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-md-10 col-sm-12">
                                <TextField
                                  fullWidth="true"
                                  label="Deal Discount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <Button
                                className="btn hovbuttonColour btn-sm col-md-5 align-content-center col-sm-12"
                                style={{ color: "white" }}
                              >
                                Set
                              </Button>
                            </div>
                          </div>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose3}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div>
              </div>
              <div className="row my-1">
                <fieldset className="col-md-5 my-1 col-sm-6">
                  <TextField
                    label={`Output VAT in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    disabled
                  />
                </fieldset>
                <div className="col-md-1 my-2 col-sm-6">
                  <InputLabel>%</InputLabel>
                </div>
                <fieldset className="col-md-4 my-1 col-sm-6">
                  <TextField
                    fullWidth
                    label=""
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    disabled
                  />
                </fieldset>
                <div className="col-md-2 my-1">
                  <button className="btn">
                    <i className="fas fa-undo-alt"></i>
                  </button>
                </div>
                {/* <div className="col-md-2 col-sm-12 my-1">
                <Fragment>
                    <button className="btn">
                      <i
                        class="fas fa-undo-alt"
                        variant="outlined"
                        onClick={handleClickOpen2}
                      ></i>
                    </button>
                    <Dialog
                      fullWidth={fullWidth2}
                      maxWidth={maxWidth2}
                      open={open2}
                      onClose={handleClose2}
                    >
                      <DialogTitle
                        className="ms-4"
                        style={{ fontFamily: "sans-serif" }}
                      >
                       Tax Details
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          noValidate
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                          }}
                          multiline
                        >
                          <div className="my-2" >
                            <div className="row my-1 ms-2">
                                <fieldset className="col-md-4 col-sm-12">
                                  <InputLabel> Tax Code </InputLabel>
                                </fieldset>
                                <fieldset className="col-md-4 col-sm-12">
                                  <InputLabel>Amount in OMR </InputLabel>
                                </fieldset>
                                <fieldset className="col-md-4 col-sm-12">
                                  <InputLabel>Amount in OMR </InputLabel>
                                </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                            <fieldset className="col-md-4 col-sm-12 my-1">
                                  <InputLabel>OMV (OUT OF SCOPE) </InputLabel>
                                </fieldset>
                                <fieldset className="col-md-4 col-sm-12">
                              <TextField
                                fullWidth="true"
                                label=""
                                id="outlined-size-small"
                                size="small"
                                variant="outlined"
                              />
                            </fieldset>
                                <fieldset className="col-md-4 col-sm-12 my-1">
                                  <InputLabel>0.00 </InputLabel>
                                </fieldset>
                           
                            </div>
                            <div className="row my-1 ms-2">
                                <fieldset className="col-md-4 col-sm-12 my-1">
                                  <InputLabel> Total </InputLabel>
                                </fieldset>
                                <fieldset className="col-md-4 col-sm-12 my-1">
                                  <InputLabel>0.00 </InputLabel>
                                </fieldset>
                                <fieldset className="col-md-4 col-sm-12 my-1">
                                  <InputLabel>0.00 </InputLabel>
                                </fieldset>
                           
                            </div>
                            
                          </div>
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose2}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div> */}
              </div>
              <div className="row my-1">
                <fieldset className="col-md-6 my-1 col-sm-6">
                  <TextField
                    label={`Selling Price in ${currency}`}
                    id="outlined-size-small"
                    size="small"
                    variant="outlined"
                    type="number"
                    value={sellingprice}
                  />
                </fieldset>
                <div className="col-md-2 col-sm-6">
                  <InputLabel>{parseFloat(marketfareinomr1 === undefined || marketfareinomr1 === null || marketfareinomr1 === "" ? 0.00 : marketfareinomr1) + parseFloat(taxinomr1 === undefined || taxinomr1 === null || taxinomr1 === "" ? 0.00 : taxinomr1) + parseFloat(servicefee === undefined || servicefee === null || servicefee === "" ? 0.00 : servicefee) + parseFloat(extraearning === undefined || extraearning === null || extraearning === "" ? 0.00 : extraearning) + parseFloat(additionalmarkup === undefined || additionalmarkup === null || additionalmarkup === "" ? 0.00 : additionalmarkup) - parseFloat(disscount === undefined || disscount === null || disscount === "" ? 0.00 : disscount)}</InputLabel>
                </div>
                <div className="col-md-3 my-1">
                  <Fragment>
                    <button>
                      <i
                        class="fas fa-folder-plus"
                        variant="outlined"
                        onClick={handleClickOpen}
                      ></i>
                    </button>
                    <Dialog
                      fullWidth={fullWidth}
                      maxWidth={maxWidth}
                      open={open}
                      onClose={handleClose}
                    >
                      <DialogTitle
                        className="ms-4"
                        style={{ fontFamily: "sans-serif" }}
                      >
                        Fare Details
                      </DialogTitle>
                      <DialogContent>
                        <Box
                          noValidate
                          component="form"
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            m: "auto",
                            width: "fit-content",
                          }}
                          multiline
                        >
                          <FormControl
                            sx={{ mt: 2, minWidth: 100 }}
                          ></FormControl>
                          <div className="card ">
                            <div className="row my-1 ms-2">
                              <InputLabel
                                className=""
                                style={{
                                  textAlign: "center",
                                  fontStretch: "ultra-condensed",
                                }}
                              >
                                {`${currency}`}
                              </InputLabel>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-9">
                                <TextField
                                  fullWidth="true"
                                  label="ROE"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-9">
                                <TextField
                                  fullWidth="true"
                                  label="ADM/ACM Exp"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-9">
                                <TextField
                                  fullWidth="true"
                                  label="Published Fare"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Market fare"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-2 my-4">
                                <InputLabel>0.00</InputLabel>
                              </div>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Tax"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-2 my-3">
                                <InputLabel>0.00</InputLabel>
                              </div>
                            </div>
                            <div className="row my-1 ms-2 ">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="STD Commission"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-8">
                                <TextField
                                  fullWidth="true"
                                  label="Supplier Amount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>

                              <div className="col-2 my-3">
                                <InputLabel>0.00</InputLabel>
                              </div>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Service Fee"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Extra Earning"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Payback Amount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Additional Mark up"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-4">
                                <TextField
                                  fullWidth="true"
                                  label="Discount"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                              <div className="col-1 my-3">
                                <InputLabel>%</InputLabel>
                              </div>
                              <fieldset className="col-3">
                                <TextField
                                  fullWidth="true"
                                  label=""
                                  defaultValue="0.00"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>
                            </div>
                            <div className="row my-1 ms-2">
                              <fieldset className="col-8">
                                <TextField
                                  fullWidth="true"
                                  label="Selling Price"
                                  id="outlined-size-small"
                                  size="small"
                                  variant="outlined"
                                />
                              </fieldset>

                              <div className="col-2 my-3">
                                <InputLabel> </InputLabel>
                              </div>
                            </div>
                          </div>

                          {/* <FormControlLabel
                            sx={{ mt: 1 }}
                            control={
                              <Switch
                                checked={fullWidth}
                                onChange={handleFullWidthChange}
                              />
                            }
                            label="Full width"
                          /> */}
                        </Box>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                      </DialogActions>
                    </Dialog>
                  </Fragment>
                </div>
              </div>
            </div>
            <div className="d-flex ">
              <button className="btn hovbuttonColour ml-3 btn-sm " onClick={addButtonHandler}>Add</button>
              <button className="btn hovbuttonColour ml-3 btn-sm bg-danger text-color-red" onClick={changeHandlerClear}>Clear</button>
            </div>
          </div>
          {ticketstore.length>0?
          <div className="p-1">
          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer component={Paper}  sx={{ maxHeight: 350}}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Supplier Name</TableCell>
            <TableCell align="right">Ticket No</TableCell>
            {/* <TableCell align="right">Output VAT/GSTIN</TableCell> */}
            <TableCell align="right">Profit</TableCell>
            <TableCell align="right">Selling Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ticketstore.map((r) => (
            <TableRow
              // key={r.stocktype}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {r.stocktype}
              </TableCell>
              <TableCell>
                {r.suppliername}
              </TableCell>
              <TableCell align="right">{r.ticketno}</TableCell>
              {/* <TableCell align="right">{r.outputvatinomr}</TableCell> */}
              <TableCell align="right">{r.profit}</TableCell>
              <TableCell align="right">{r.sellingpriceinomr}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
            </Paper>
          </div>
          :""}
        </div>
      </div>
    </div>
  );
};
export default Ticket;



// ATBBnDBe5DdkXP5r95ZgHphg69Hv418D707E

