import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import AssetsService from "../AssetManagement/AssetsService";
import { currentTenant, userid, thousandsdisplay } from "../Common/Credentials";
import { spin } from "../Common/NewLoader";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import MUIDataTable from "mui-datatables";
import { Backdrop, CircularProgress, ThemeProvider } from "@mui/material";
import {
  createTheme,
} from "@mui/material";
import moment from "moment";
import {NumericFormat} from "react-number-format";
const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
//let jvtype
class DepreciationList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assets: [],
      assetexpenses: [],
      transexpenses:[],
      loader:true
    };
  }
  componentDidMount() {
    // this.setState({ loading: true })
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    AssetsService.getallAssetsofTenantForAccounts(currentTenant).then(
      (response) => this.setState({ assets: response.data})
    );
    AssetsService.GetAssetsMaintenanceofTenantForAccounts(currentTenant).then(
      (response) => this.setState({ assetexpenses: response.data })
    );
    AssetsService.GetTripsforAcc(currentTenant).then(
      (response) => this.setState({ transexpenses: response.data },()=>this.setState({loader:false}))
    );

  }

  addDepriciation(value){
    let de

    if(value!==undefined){
    de=this.state.assets.filter(e=>e.id===value.rowData[0])
    } 
    this.props.navigate({pathname:`/deprientry`}, {state:{de:de}});
  }

  assetExpense(value){
    let ae=[]
    //,a=[]
   // jvtype=8
    if(value!==undefined){
    ae=this.state.assetexpenses.filter(e=>e.id===value.rowData[0] )
    } 
  
    this.props.navigate({pathname:`/deprientry`},{state:{ae:ae}});
  }

  tripExpense(value){
    let te

    if(value!==undefined){
    te=this.state.transexpenses.filter(e=>e.id===value.rowData[0])
    } 
    this.props.navigate({pathname:`/expenseentry`}, {state:{te:te}});
  }
  render() {
    const columns = [
      {name: "id",label: "Id",options: {filter: false,sort: false,display: false,viewColumns: false,},},
      {name: "itemname",label: "Item Name",options: { filter: true, sort: true },},
      {name: "assettype",label: "Asset Type",options: { filter: true, sort: true },},
      {name: "warehouse",label: "Org Unit",options: { filter: true, sort: true },},
      {name: "quantity",label: "Quantity",options: { filter: false, sort: true },},
      {name: "baseamount",label: "Amount",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2}
                fixedDecimalScale={true} disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "right" } }),
        },
      },
      {name: "type",label: "Type",options: { filter: true, sort: true },},
      {name: "depreciation",label: "Depreciation per Year (%)",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return <span>{value}%</span>;
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {name: "depreciationperiod",label: "Depreciation Period In Year", options: {filter: false, sort: true,
          customBodyRender: (value) => {
            return <span>{value}&nbsp;Years</span>;
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
      {name: "location",label: "Location",options: { filter: false, sort: true },},
      {name: "user",label: "User",options: { filter: true, sort: true },},
      {name: "createdAt",label: "Date",options: {filter: false,sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:mm");
          },
        },
      },
      {name: "Action ",options: {filter: false,sort: false,empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <button as="button" className="hovbuttonColour btn btn-sm" onClick={() => this.addDepriciation(tableMeta)}>
                Entry
              </button>
            );
          },
          setCellProps: (value) => ({ style: { textAlign: "center" } }),
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      print: false,
    };

    
      const columns1 = [
        {name: "id", label: "Id", options: {filter: false,sort: false, display: false,viewColumns: false,},},
        {name: "asset",label: "Asset Id", options: { filter: false, sort: true },},
        {name: "itemname",label: "Item Name",options: { filter: true, sort: true },},
        {name: "quantity",label: "Quantity", options: { filter: false, sort: true },},
        {name: "type",label: "Type",options: { filter: true, sort: true },},
        {name: "amount",label: "Amount",options: {filter: false,sort: true,
            customBodyRender: (value) => {
              return (
                <NumericFormat className="pr-3" displayType={"text"}value={value} decimalScale={2} fixedDecimalScale={true}
                  disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
              );
            },
            setCellProps: (value) => ({ style: { textAlign: "right" } }),
          },
        },
        {name: "createdAt",label: "Date",options: {filter: false, sort: true,
            customBodyRender: (tableMeta) => {
              return moment(tableMeta).format("DD-MM-YYYY HH:mm");
            },
          },
        },
        {name: "description",label: "Description", options: { filter: false, sort: true }, },
        {name: "Action ", options: {filter: false,sort: false,empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <button as="button" className="hovbuttonColour btn btn-sm" onClick={() => this.assetExpense(tableMeta)} >
                  Entry
                </button>
              );
            },
            setCellProps: (value) => ({ style: { textAlign: "center" } }),
          },
        },
      ];
  
      const options1 = {
          filterType: "checkbox",
          selectableRows: "none",
          print: false,
        };


        const transportexp = [
          {name: "id", label: "Id", options: { filter: false, sort: false,  display: false,  viewColumns: false, },},
          {name: "customid",label: "Trans Id",options: { filter: false, sort: true },},
          { name: "vehicle",label: "Vehicle NO",options: { filter: true, sort: true },},
          {name: "originname",label: "From", options: { filter: false, sort: true },},
          {name: "destinationname",label: "To",options: { filter: true, sort: true },},
          { name: "outdate", label: "Started", options: {filter: false, sort: true,
            customBodyRender: (tableMeta) => {
              return moment(tableMeta).format("DD-MM-YYYY HH:mm");
            },
          },
        },
        { name: "indate", label: "Completed", options: {filter: false, sort: true,
          customBodyRender: (tableMeta) => {
            return moment(tableMeta).format("DD-MM-YYYY HH:mm");
          },
        },
      },
          {name: "totalexp",label: "Tot Exp", options: { filter: false,sort: true,
              customBodyRender: (value) => {
                return (
                  <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
                    disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>
                );
              },
              setCellProps: (value) => ({ style: { textAlign: "right" } }),
            },
          },
          {name: "Action ", options: {filter: false, sort: false,empty: true,
              customBodyRender: (value, tableMeta, updateValue) => {
                return ( <button as="button" className="hovbuttonColour btn btn-sm" onClick={() => this.tripExpense(tableMeta)} >
                    Entry
                  </button>
                );
              },
              setCellProps: (value) => ({ style: { textAlign: "center" } }),
            },
          },
        ];
    
        const assetexp = {
            filterType: "checkbox",
            selectableRows: "none",
            print: false,
          };



    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader}>
          <CircularProgress color="inherit" />
        </Backdrop>
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/accounts">Home</Link>
              </li>
              <li className="breadcrumb-item active">Asset Loses & Exp</li>
            </ol>
            <div className="form-row">
              <div className="card-body">
              {/* {this.state.loading===true?spin:''} */}

                {/* <div>
                  {this.state.loading === true ? (
                    <div className="text-center">{spin}</div>
                  ) : ( */}
                    <>
                       <div className="mb-5">
                      <ThemeProvider theme={theme}>
                        <MUIDataTable className="table-sm px-3" title={"Transport Expenses"}
                          id="transexp" data={this.state.transexpenses} columns={transportexp} options={assetexp}/>
                      </ThemeProvider>
                      </div>
                    <div className="mb-5">
                      <ThemeProvider theme={theme}>
                        <MUIDataTable className="table-sm px-3" title={"Assets Depreciation"}
                          id="assets" data={this.state.assets} columns={columns}options={options}/>
                      </ThemeProvider>
                      </div>
                      <div className="mb-5">
                      <ThemeProvider theme={theme}>
                        <MUIDataTable className="table-sm px-3" title={"Assets Expenses"}
                          id="assets" data={this.state.assetexpenses} columns={columns1} options={options1}/>
                      </ThemeProvider>
                      </div>
             
                    </>
                  {/* )} */}
{/* </div> */}
              
                {/* <TableContainer>
                   <Table className="table-sm">
                       <TableHead>
                            <TableRow>
                                <TableCell>Asset Type</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell>Quantity</TableCell>
                                <TableCell>Maintenance Type</TableCell>
                                <TableCell>Depreciation</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>    
                       </TableHead>
                       <TableBody>
                            {this.state.assetexpenses.map(e=>
                                <TableRow>
                                    <TableCell>{e.asset}</TableCell>
                                    <TableCell>{e.itemname}</TableCell>
                                    <TableCell>{e.quantity}</TableCell>
                                    <TableCell>{e.typr}</TableCell>
                                    <TableCell>{e.description}</TableCell>
                                    <TableCell>{e.amount}</TableCell>
                                    <TableCell>
                                        <button
                                            as="button"
                                            className="hovbuttonColour btn btn-sm"
                                        >
                                            Select
                                        </button>
                                    </TableCell>
                                </TableRow>
                            )}
                       </TableBody>
                   </Table>
               </TableContainer> */}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(DepreciationList);
