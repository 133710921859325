import React, { Component } from "react";
import { Formik, Form, FieldArray, FastField } from "formik";
import withRouter from "../withRouter.js";
import { FormikTextField } from '../CommonComponents/FormField.js';
import { Autocomplete, Backdrop, CircularProgress } from "@mui/material";
import {
  jname,
  jtype,
  personal,
  real,
  uom,
  appinv,
  saedata,
  totalcombdata,
  expcombdata,
  iecombdata
} from "./Journal.jsx";
import { NumericFormat } from "react-number-format";
import {
  ccountry,
  currentTenant,
  currentUser,
  financialyearend,
  financialyearstart,
  taxtype,
  thousandsdisplay,
  transactionFrom,
  userid,
} from "../Common/Credentials.js";
import { FormControlLabel, Table, TableBody, TableCell, TableHead, TableRow, Grid } from "@mui/material";
import { TextField } from '@mui/material';
import InventoryService from "../inventory/InventoryService.js";
import { sales } from "../Common/Products.js";
import downloadfiles from "../Common/upload/downloadfiles.js";
import WarehouseServices from "../Masters/Warehouse/WarehouseServices.js";
import { Spinner } from "react-bootstrap";//Modal
import CategoryServices from "../Masters/Category/CategoryServices.js";
import UploadFilesService from "../Common/upload/UploadFilesService.js";
import FileServices from "../Common/upload/FileServices.js";
import moment from "moment";
import AuthenticationService from "../Authentication/AuthenticationService.jsx";
import IOSSwitch from "../CommonComponents/IOSSwitch.js";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import SalesServices from "../Sales/SalesServices.js";


let jvtype = 1; // Type of Jv 0 for Purchase
let custbalance = 0;
let entrytype, debcustamount; // for Orginal or Rectification
let invcustomer, invso, invcustid, invcustomerid, gsttype, customeinvso;
let aesup, aeamount, aefile, aedate, aesupid, aepath, custname, custid, sodata, debvalue, aecustsoid, othercharge = []
let  tcspercent, tcsamount, invoices
let curmonth, year, day, deb, cre, prodservicetaxamount = null
let doctype = "supplierinvoices"


const Checkbox = props => (
  <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" {...props} />
)

const getTotal = (value) => {
  let total = 0;
  value.forEach(vtotal => {
    total += vtotal;
  });
  return total;
};
class JournalDirectSales extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selinv: "",
      invtype: [
        { id: 1, name: "Advance" },
        { id: 0, name: "From Invoice" },
      ],
      inventrytypes: [
        { id: 0, name: "SO Transactions" },
        { id: 1, name: "Direct Transactions" },
      ],
      inventrychecked:0,
      gsttypes: [{ id: 1, name: "Within State" }, { id: 2, name: "Inter State" }],
      jname: "",
      jtype: "",
      message: "",
      debselval: "",
      credselval: "",
      advanceamountreq: 0,
      wh: [],
      SDdate: false,
      fed: '',
      itemstock: [],
      directinvoices:[],
      uom: [],
      invchecked: '',
      displaychecked: false, GRNchecked: false,
      currentFile: undefined, progress: 0, isError: false, fileInfos: [], selectedFiles: undefined, partyid: '',
      grnvaldata: [], datagrn: [], addedGRN: [], addedGRNamt: '', CategoryList: [], filteritemlist: [],
      scnvaldata: [], datascn: [], scnitemlist: [], scnitems: [], addedSCN: [], addedSCNamt: '', scnid: [], milestone: [], ssdata: [],
      gstchecked: '',
      selectedSO: '', SelectedAeSoid: '', aepoamount: '', soadvancerecd: null,
      aeval: '',
      invtaxval: null, invtotal: null, invwithtax: null, invfile: [], invpath: [], sototal: [], invsototal: null,
      scntaxval: null, scnunittot: [], scnwithtot: [],
      invoicepath: [], invoicefile: [], invoicedate: [], invoiceamt:'',
      proinvoicedate: [], proinvoicefile: [], proinvoicepath: [], proinvoiceamt: [],
      podate: [], pofile: [], popath: [], soamount: [], invid: '', custominvid: '', sobalance: '',
      modal: false,
      sodata: [], invoicelist: [],invoicelist1: [], invoiceid: [], custinvoiceid: [], invitemlist: [], invitems: [], invprodtax: null, addlcharges: [],
      grandservicetotal: null, servicetotal: null, servicetax: null, servicetaxamount: null,
      productservicetotal: null, productservicetax: null, prodservicetaxamount: null, salesvaltype: null,
      tcspercent: '', tcsamount: '', addlchargetaxtot: "", addlamounttot: "",
      insurancereq: false, insuranceamount: '', insurancetax: '',
      selectedInvoice: '', selectedInvoiceData: '', advanceadjusted: '',openadjusted:'', invservicename: '',dcadjusted:'',
      loader: false,loader1:true,jvinsertiontype:0
    };
    this.validate = this.validate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.download = this.download.bind(this);
    // this.onSoChange = this.onSoChange.bind(this);
    this.addSelect = this.addSelect.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.addValues = this.addValues.bind(this);
    this.onInvoiceChange = this.onInvoiceChange.bind(this);
    this.selectFile = this.selectFile.bind(this); this.upload = this.upload.bind(this);

  }
  handleClose() {
    this.setState({ modal: false })
  }
  handleShow() {
    this.setState({ modal: true })
  }
  handleCategory = (value, values) => {
    if (value !== undefined && values.length !== 0) {
      this.setState({
        catid: values.category.id,
        category: value,
        filteritemlist: this.state.itemstock.filter(e => e.categoryid === values.category.id)
      });
    }
  };
  handleSDDate = () => {
    this.setState({ SDdate: this.state.SDdate === true ? false : true })
  }
  handleFsdDateChange = (date) => {
    if(date!==null){
    this.setState({ fsd: date });
    this.setState({
      fed: moment(date.$d).format("yyyy-MM-DD HH:mm:ss"),
    });
  }else{
    this.setState({ SDdate:false })
  }
  };
  onCreditChange(values) {
    if (values.credit !== undefined && values.credit.length !== 0) {
      this.setState({ credselval: values.credit })
    }

  }

  onDebChange(values) {
    if (values.debit !== undefined && values.debit.length !== 0) {
      this.setState({ partyid: values.debit.id })
      invcustomerid = values.debit.pid
      this.setState({ debselval: values.debit });
      debcustamount = values.debit.advancerecd
      if (this.state.invchecked === 0 && debcustamount > 0) {
        let a = window.confirm("There is a excess credit balance of " + debcustamount + " of Customer with you ! Do you want to use");
        if (a === true) {
          custbalance = 1
        }
      }
    }

  }
  onSubmit(values) {
    this.setState({ loader: true })
    let gst, gstpercent, taxval1, addl;
    let cgst, cgstpercent, taxval2;
    let igst, igstpercent, itaxval;
    let vat, vatpercent, vatval, soid
    let gpdata = []
    let sdebitid, sdebitname, sdebitamt
    let invitemled, invitemid, invitemname
    let insuranceamt, insupercent, insurancetot, insuledger
    addl = values.addl

    if (this.state.insurancereq === true) {
      insuranceamt = values.insuranceamt
      insupercent = values.insupercent
      insurancetot = values.insurancetot
      insuledger = values.insuledger
    }

    let income
    if (values.credit.rule === 2) {
      income = values.credit.id;
    }
    // if (this.state.invchecked === 0 && this.state.invservicename === undefined) {
    //   invitemled = values.invitem.id
    //   invitemid = values.invitem.itemid
    //   invitemname = values.invitem.itemname
    // }

    if (values.taxvalue1 !== undefined) {
      gst = values.sgst;
      gstpercent = values.taxpercent1;
      taxval1 = values.taxvalue1;
    }
    if (values.taxvalue2 !== undefined) {
      cgst = values.cgst;
      cgstpercent = values.taxpercent2;
      taxval2 = values.taxvalue2;
    }
    if (values.itaxvalue1 !== undefined) {
      igst = values.igst;
      igstpercent = values.itaxpercent1;
      itaxval = values.itaxvalue1;
    }
    if (values.gtaxvalue1 !== undefined) {
      vat = values.tax;
      vatpercent = values.gtaxpercent1;
      vatval = values.gtaxvalue1;
    }
    if (values.sdebit !== undefined && values.sdebit.length!=0) {
      sdebitid = values.sdebit.id
      sdebitname = values.sdebit.name
      sdebitamt = values.sdebitval
    }
    // if (ccountry === "India" && 3 && gsttype === 1) {
    //   tax1 = values.taxvalue1 * 2;
    // } else if (ccountry === "India" && 3 && gsttype === 2) {
    //   tax1 = values.itaxvalue1;
    // } else {
    //   tax1 = values.gtaxvalue1;
    // }

    // if (this.state.invchecked === 0) {
      gpdata = this.state.invitemlist
      custname = invcustomer.toLocaleString();
      custid = invcustid
     // soid = invso.toLocaleString()
    // }
   
    // else {

    //   tax1 = vatpercent
    // }
    let jv
    let invoicepath, invoicefile
    if (this.state.invchecked === 0) {
      invoicepath = this.state.invoicepath.toLocaleString() !== '' ? this.state.invoicepath.find(((e, i) => e.valueOf(i))) : ''
      invoicefile = this.state.invoicefile.toLocaleString() !== '' ? this.state.invoicefile.find(((e, i) => e.valueOf(i))) : ''
    }
    // if (this.state.invchecked === 0) {
      jv = {
        debitid: values.debit.id, debitname: values.debit.name, debitamt: values.debitval,
        sdebitid: sdebitid, sdebitname: sdebitname, sdebitamt: sdebitamt,
        gst: gst, gstpercent: gstpercent, taxval1: taxval1, cgst: cgst, cgstpercent: cgstpercent,
        taxval2: taxval2, igst: igst, igstpercent: igstpercent, itaxval: itaxval, vat: vat,
        vatpercent: vatpercent, vatval: vatval, creditid: values.credit.id, creditname: values.credit.name,
        creditamt: values.creditvalue, gstin: values.debit.gstin, pan: values.debit.pan,
        contactnumber: values.debit.contactnumber, email: values.debit.email, narration: values.narration,
        jvtype: jvtype, createdBy: userid, tenantId: currentTenant, entrytype: entrytype, whid: values.whouse,
        income: income,
        gpdata: gpdata, gsttype: gsttype, customer: custname, soid: soid, customerid: custid, ssdata: this.state.ssdata,
        selinv: this.state.invoiceid, soadvance: aeamount.toLocaleString(),
        invoicepath: invoicepath,
        invoicefile: invoicefile,
        customeinvso: customeinvso,
        podate: this.state.podate.toLocaleString() !== '' ? this.state.podate.find(((e, i) => e.valueOf(i))) : '',
        pofile: this.state.pofile.toLocaleString() !== '' ? this.state.pofile.find(((e, i) => e.valueOf(i))) : '',
        popath: this.state.popath.toLocaleString() !== '' ? this.state.popath.find(((e, i) => e.valueOf(i))) : '',
        soamount: this.state.soamount !== undefined ? this.state.soamount.find(((e, i) => e.valueOf(i))) : '',
        insuranceamt: insuranceamt, insupercent: insupercent, insurancetot: insurancetot, insuledger: insuledger,
        tcspercent: tcspercent, tcsamount: tcsamount,
        invitemled: invitemled, invitemid: invitemid, invitemname: invitemname,invoiceamt:this.state.invoiceamt,
        balance: this.state.sobalance,
        saletype:0, invid: this.state.invid, salesvaltype: this.state.salesvaltype, custbalance: custbalance, debcustamount: debcustamount,
        date: this.state.fed !== '' ? this.state.fed : null, custominvid: this.state.custominvid, addl: addl,
        openadjusted:this.state.openadjusted,dcadjusted:this.state.dcadjusted,jvinsertiontype:this.state.jvinsertiontype
      };
    // } else if (this.state.invchecked === 1) {
    //   soid = this.state.SelectedAeSoid
    //   custname = values.debit.pname
    //   custid = values.debit.pid
    //   jv = {
    //     debitid: values.debit.id, debitname: values.debit.name, debitamt: values.debitval,
    //     creditid: values.credit.id, creditname: values.credit.name, customsoid: aecustsoid,
    //     creditamt: values.creditvalue, gstin: values.debit.gstin, pan: values.debit.pan,
    //     contactnumber: values.debit.contactnumber, email: values.debit.email, narration: values.narration,
    //     createdBy: userid, soid: soid, customer: custname, customerid: custid, soadvance: values.creditvalue,
    //     jvtype: jvtype, entrytype: entrytype, tenantId: currentTenant, saletype: this.state.invchecked,
    //     balance: this.state.sobalance, custbalance: custbalance,invoiceamt:this.state.invoiceamt,
    //     insuranceamt: insuranceamt, insupercent: insupercent, insurancetot: insurancetot, insuledger: insuledger,
    //     soamount: this.state.aepoamount !== undefined ? this.state.aepoamount.find(((e, i) => e.valueOf(i))) : '',
    //     date: this.state.fed !== '' ? this.state.fed : null,dcadjusted:this.state.dcadjusted,openadjusted:this.state.openadjusted,
    //     jvinsertiontype:this.state.jvinsertiontype
    //   }
    // } else {
    //   custname = values.credit.pname
    //   custid = values.credit.pid
    //   jv = {
    //     debitid: values.debit.id, debitname: values.debit.name, debitamt: values.debitval,
    //     gst: gst, gstpercent: gstpercent, taxval1: taxval1, cgst: cgst, cgstpercent: cgstpercent,
    //     taxval2: taxval2, igst: igst, igstpercent: igstpercent, itaxval: itaxval, vat: vat,
    //     vatpercent: vatpercent, vatval: vatval, creditid: values.credit.id, creditname: values.credit.name,
    //     creditamt: values.creditvalue, gstin: values.debit.gstin, pan: values.debit.pan,
    //     contactnumber: values.debit.contactnumber, email: values.debit.email, narration: values.narration,
    //     createdBy: userid, customer: custname, customerid: custid,invoiceamt:this.state.invoiceamt,
    //     jvtype: jvtype, entrytype: entrytype, tenantId: currentTenant, saletype: this.state.invchecked,jvinsertiontype:this.state.jvinsertiontype,
    //     date: this.state.fed !== '' ? this.state.fed : null
    //   }

    // }
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      AuthenticationService.logout(currentUser);

    }
    else {
      this.props.navigate({ pathname: `/journalsaleconfirm` }, { state: jv });
    }
  }


  componentDidMount() {
    year = new Date().getFullYear()
    day = new Date().getDate()
    let mn = new Date().getMonth() + 1
    curmonth = moment(mn, 'M').format('MMMM')
    this.setState({ jname: jname });
    this.setState({ jtype: jtype });
    invcustomer = "";
    invso = "";

    SalesServices.getDirectInvoices(currentTenant)
      .then(response=>{
        this.setState({directinvoices:response.data},()=>this.setState({loader1:false}))
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      })

  

    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      AuthenticationService.logout(currentUser);
    }



  }
  componentDidUpdate() {
    if (jtype !== this.state.jtype) {
      this.setState({ jtype: jtype });
    }

  }
  componentWillUnmount() {
    this.render();
  }
  onInvChange(option) {
    this.setState({ invchecked: option.id })
  }

  onInvoiceChange(option, values) {
    // console.log(values)
    // console.log(values)
  //   if(this.state.directinvoices.length===0){
  //     this.setState({loader1:true})
  //     SalesServices.getDirectInvoices(currentTenant)
  //     .then(response=>{
  //       this.setState({directinvoices:response.data},()=>this.setState({loader1:false}))
  //     })
  // }else{
    if (this.state.selectedInvoice === '') {
      this.setState({ selectedInvoice: option, selectedInvoiceData: values, custominvid: option }, () => this.invBehav())
    }
    else {
      this.setState({ selectedInvoice: '', selectedInvoiceData: [] })
    }
  }
  invBehav() {
    if (this.state.invchecked !== 1) {
      this.setState({ aeval: '' })
      aesup = ''
      aeamount = ''
      aefile = ''
      aedate = ''
      aesupid = ''
      aepath = ''
    }

    let invitemslist, scnitemlist, st, b = true, advanceadjusted,dcadjusted//openadjusted=null
    let gtype, taxtype
    if (this.state.selectedInvoice !== '') {
      if (this.state.selectedInvoice !== undefined) {
        this.setState({invoicelist1:this.state.selectedInvoiceData})
        taxtype = this.state.selectedInvoiceData.taxtype 
        gtype = this.state.selectedInvoiceData.gsttype 
        tcspercent = this.state.selectedInvoiceData.tcs
        tcsamount = this.state.selectedInvoiceData.tcsamount
        othercharge = this.state.selectedInvoiceData.additionalcharges
        st = this.state.selectedInvoiceData.salestype
        advanceadjusted = this.state.selectedInvoiceData.advanceadjusted
        dcadjusted = this.state.selectedInvoiceData.debitadjusted
        invcustomer= this.state.selectedInvoiceData.customer
        invcustid = this.state.selectedInvoiceData.custid
        if (taxtype === "GST" && gtype === "INTRASTATE") {
          this.setState({ gstchecked: 1 })
          gsttype = 1;
        } else if (taxtype === "GST" && gtype === "INTERSTATE") {
          this.setState({ gstchecked: 2 })
          gsttype = 2;
        }else{
          gsttype = 3;
        }
        if (this.state.selectedInvoiceData.invoiceitems !== null 
          && (this.state.selectedInvoiceData.invoiceitems !== undefined && this.state.selectedInvoiceData.invoiceitems.length !== 0)) {
          invitemslist = this.state.selectedInvoiceData.invoiceitems.map((e) => e) 
          this.setState({ invoiceid: [] }, () =>
            this.state.invoiceid.push({
              name: this.state.selectedInvoiceData.invid,
              custinvname: this.state.custominvid,
              isChecked: b
            }))
    
        

      // sodata.map((e) => { return gtype = e.gsttype })
   

          this.setState({ dcadjusted: dcadjusted,jvinsertiontype:7})

         // openadjusted = this.state.selectedInvoiceData.openadjusted

        } else {
          this.setState({ scntaxval: null, scnunittot: [], scnwithtot: [], sototal: [] })
          this.setState({ invoiceid: [], scnid: [] })
          this.setState({ invitemlist: [], milestone: [] }, () => this.addValuesSCN())
        }
        if (this.state.selectedInvoiceData.invoicemilestone !== undefined) {
          if (this.state.selectedInvoiceData.invoicemilestone !== null) {
            scnitemlist = this.state.selectedInvoiceData.invoicemilestone.map((e) => e)
          }
        }

        this.setState({ insurancetax: this.state.selectedInvoiceData.insurancetax, insurancereq: this.state.selectedInvoiceData.insurancereq, insuranceamount: this.state.selectedInvoiceData.insuranceamount, advanceadjusted: advanceadjusted,openadjusted:this.state.selectedInvoiceData.openadjusted })
        this.setState({ invitemlist: invitemslist, invitems: invitemslist, addlcharges: othercharge, salesvaltype: st ,invoiceamt:this.state.selectedInvoiceData.totalinvoicewtax}, () => this.addValues())

        if (scnitemlist !== undefined) {
          if (scnitemlist !== null) {
            if (scnitemlist.length !== 0) {
              this.setState({ invoiceid: [], invitemlist: [], salesvaltype: null })
              let dd
              sodata.map((e) => { return dd = e.servicename })
              this.setState({
                scnitemlist: scnitemlist, scnitems: scnitemlist, milestone: scnitemlist,
                invservicename: dd
              }, () => this.addValuesSCN())
              let a1 = [...new Set(scnitemlist.map((e) => { return e.invid })),];
              let b1 = true
              a1.map((f1) =>
                this.setState({ scnid: [] }, () =>
                  this.state.scnid.push({
                    name: f1,
                    isChecked: b1
                  }))
              )
            }

          }
          else {
            this.setState({ invoiceid: [], scnid: [] })
            this.setState({ invitemlist: [], milestone: [] }, () => this.addValuesSCN())
          }

        }
      }

    }
  }
  onGstChange(gstoption) {
    this.setState({ gstchecked: gstoption.id });
    gsttype = gstoption.id
  }
  validate(values) {
    let errors = {};
    deb = values.debitval;
    cre = values.debtotal;
    if (this.state.gstchecked === 1) {
      if (!values.debit || values.debit === "" || values.debit === undefined) {
        errors.debit = "Accounts is required";
      }
      if (!values.debitval || values.debitval === "" || values.debitval === undefined || values.debitval === 0) {
        errors.debitval = "Value Cannot be Blank";
      }
      else if (deb !== cre) {
        errors.creditvalue = "Value Cannot be be different from debit Value";
      }
      if (this.state.invchecked === 0) {
        if (invcustomerid !== invcustid) {
          errors.debit = "Customer Cannot be different from Invoice   "
        }
      }
    }

    if (!values.so && values.so === undefined) {
      errors.so = "So is Required "
    }
    if (!values.invnew && values.so === undefined) {
      errors.invnew = "invnew is Required"
    }
    if (ccountry === "India" && this.state.gstchecked === 1) {
      if (!values.sgst) {
        errors.sgst = "Tax Cannot be Blank";
      }
      if (!values.cgst) {
        errors.cgst = "Tax Cannot be Blank";
      }
      if (values.sgst === values.cgst) {
        errors.cgst = "Tax Cannot be Same";
        errors.sgst = "Tax Cannot be Same";
      }
    } else if (ccountry === "India" && this.state.gstchecked === 2) {
      if (!values.igst) {
        errors.igst = "Tax Cannot be Blank";
      }

    } else if (ccountry !== "India") {
      if (!values.tax) {
        errors.tax = "Tax Cannot be Blank";
      }
    }

    if (!values.credit || values.credit === "") {
      errors.credit = "Accounts is required";
    }
    if (values.creditvalue === undefined) {
      errors.creditvalue = "Value Cannot be Blank";
    } else if (deb !== cre) {
      errors.creditvalue = "Value Cannot be be different from debit Value";
    }
    // if (this.state.invchecked === 0 && this.state.invservicename === undefined) {
    //   if (!values.invitem) {
    //     errors.invitem = "Invoice item is required";
    //   }
    // }
    if (!values.narration) {
      errors.narration = "Narration is required";
    }
    if(this.state.insurancereq === true){
      if (!values.insuledger) {
      errors.insuledger = "Insurance Ledger is required";
      }
    }
    return errors;
  }
  calc(values) {
    let tot;
    tot = values.debitval + values.taxvalue;
    return tot;
  }
  onChange(option) {
    this.setState({ checked: option.id });
  }
  // onSoChange(value, values) {
  //   this.setState({ selectedInvoice: '', invitems: [], addlcharges: [], grandservicetotal: null, servicetotal: null, servicetax: null, servicetaxamount: null })
  //   if (value !== undefined || value !== "" || value !== null || values.so !== undefined) {
  //     let grandservicetotal, servicetotal, servicetax, servicetaxamount, productservicetotal, productservicetax, advanceamountreq, sobalance
  //     sodata = appinv.filter((e) => e.customsoid === value)
  //     this.setState({ sodata: sodata })
  //     invso = sodata.map((e) => e.id)
  //     customeinvso = sodata.map((e) => e.customsoid)
  //     let gtype, taxtype
  //     sodata.map((e) => { return taxtype = e.taxtype })
  //     sodata.map((e) => { return gtype = e.gsttype })
  //     if (taxtype === "GST" && gtype === "INTRASTATE") {
  //       this.setState({ gstchecked: 1 })
  //       gsttype = 1;
  //     } else if (taxtype === "GST" && gtype === "INTERSTATE") {
  //       this.setState({ gstchecked: 2 })
  //       gsttype = 2;
  //     }
  //     sodata.map((e) => { return grandservicetotal = e.grandservicetotal })
  //     sodata.map((e) => { return productservicetotal = e.productservicetotal })
  //     sodata.map((e) => { return servicetotal = e.serviceamount })
  //     sodata.map((e) => { return servicetax = e.servicetax })
  //     sodata.map((e) => { return productservicetax = e.productservicetax })
  //     sodata.map((e) => { return advanceamountreq = e.advanceamountreq })
  //     sodata.map((e) => { return sobalance = e.balance })

  //     prodservicetaxamount = productservicetotal * productservicetax / 100
  //     servicetaxamount = servicetotal * servicetax / 100
  //     this.setState({
  //       grandservicetotal: grandservicetotal, servicetotal: servicetotal,
  //       servicetax: servicetax, servicetaxamount: servicetaxamount, productservicetotal: productservicetotal,
  //       productservicetax: productservicetax, prodservicetaxamount: prodservicetaxamount, sobalance: sobalance
  //     })
  //     this.setState({ selectedSO: invso.toLocaleString(), advanceamountreq: advanceamountreq })
  //     invcustomer = sodata.map((e) => e.customer);

  //     invcustid = sodata.map((e) => e.custid).toLocaleString();

  //     this.setState({
  //       invoicepath: sodata.map((e) => e.finalinvoicepath),
  //       invoicefile: sodata.map((e) => e.finalinvoicefile),
  //       invoicedate: sodata.map((e) => e.finalinvoicepath),
  //       // invoiceamt: sodata.map((e) => e.invoiceamountupdated),
  //       proinvoicedate: sodata.map((e) => { return e.proformainvoicedate }),
  //       proinvoicefile: sodata.map((e) => e.proformainvoicefile),
  //       proinvoicepath: sodata.map((e) => e.proformainvoicepath),
  //       proinvoiceamt: sodata.map((e) => e.invoiceamount),
  //       podate: sodata.map((e) => e.pouploaddate),
  //       pofile: sodata.map((e) => e.pofile),
  //       popath: sodata.map((e) => e.popath),
  //       soamount: sodata.map((e) => e.grandtotal),
  //     })
  //     if (sodata !== undefined) {
  //       if (sodata !== null) {
  //         if (sodata.length !== 0) {
  //           sodata.map((e) => { return invoices = e.invoicelist })
  //           this.setState({ invoicelist: invoices })
  //         }
  //       }
  //     }
  //   }
  // }



  addSelected() {
    let a = [...new Set(this.state.invoicelist.map((e) => { return e.invid })),];
    let g = [...new Set(this.state.invoicelist.map((e) => { return e.custominvid })),];

    let custinv
    this.state.invoicelist.map((e) => { return custinv = e.customeinvso })
    this.state.invoicelist.map((e) => { return debitadjusted = e.debitadjusted })
    this.setState({ invid: a.toLocaleString() })
    this.setState({ custominvid: g.toLocaleString() })


    let b = true
    a.map((f) =>
      this.setState({ invoiceid: [] }, () =>
        this.state.invoiceid.push({
          name: f,
          custinvname: custinv,
          isChecked: b
        }))
    )
    g.map((z) =>
      this.setState({ custinvoiceid: [] }, () =>
        this.state.custinvoiceid.push({
          name: z,
          custinvname: custinv,
          isChecked: b
        }))
    )


  }

  addValues() {
   
    if (this.state.addlcharges !== undefined) {
      if (this.state.addlcharges !== null) {
        let taxtotal, addltotal
        taxtotal = this.state.addlcharges.map((e) => e.additionalchargestaxamount)
        addltotal = this.state.addlcharges.map((e) => e.additionalcharges)

        if (this.state.insurancereq === false) {
          this.setState({ addlchargetaxtot: getTotal(taxtotal), addlamounttot: getTotal(addltotal) })
        } else {
          this.setState({ addlchargetaxtot: getTotal(taxtotal) + (this.state.insuranceamount*this.state.insurancetax/100) , addlamounttot: getTotal(addltotal) + this.state.insuranceamount })
        }
      }
    }
    let gta, gutp, gtp, sotot, soadvrecd, aa
    if (this.state.invitems !== undefined) {
      if (this.state.invitems !== null) {
        if (this.state.invitems.length !== 0) {
          this.setState({ scntaxval: null, scnunittot: [], scnwithtot: [], sototal: [] })
          gta=getTotal(this.state.invitems.map((e) => e.taxamount ))
          if (prodservicetaxamount !== null) {
            this.setState({ invprodtax: gta + prodservicetaxamount })
          }
          gutp =getTotal(this.state.invitems.map((e) => e.totalprice))
          this.state.invitems.map((e) => { return sotot = e.totalpricewtax })
          this.state.sodata.map((e) => { return gtp = e.totaliv })
          this.state.sodata.map((e) => { return soadvrecd = e.advanceamountpaid })
          this.state.invitems.map((e) => { return aa = e.servicename })
          this.setState({
            invtaxval: gta, invtotal: gutp, invwithtax: sotot, invsototal: gtp, soadvancerecd: soadvrecd
            , invservicename: aa
          })
        } else {
          this.setState({ invtaxval: null, invtotal: null, invwithtax: null, invsototal: null, soadvancerecd: null })
        }
      }
    }
  }

  addValuesSCN() {
    // alert("bbb")
    // console.log(this.state.invoicelist1)

    let invoiceservice=this.state.invoicelist1.invoiceservice
    let sta, sutp, stp, sotot
    if (this.state.milestone !== undefined) {
      if (this.state.milestone !== null) {
        if (this.state.milestone.length !== 0) {
          this.setState({ invtaxval: null, invtotal: null, invwithtax: null, invsototal: null })
          this.state.milestone.map((e) => { return sta = e.taxamount })
          sutp = getTotal(this.state.milestone.map((e) => e.totalprice))
          stp = getTotal(this.state.milestone.map((e) => e.totalpricewtax))
          sotot = this.state.sodata.map((e) => e.grandtotal)
          this.setState({ scntaxval: sta, scnunittot: sutp, scnwithtot: stp, sototal: sotot })
          let tax, a, total, b, invid, c
          let mname, completiondate, percentage
          a = this.state.milestone.map((e) => { return e.taxamount })
          tax = a.find((e, i) => e.valueOf(i))
          b = this.state.milestone.map((e) => { return e.totalprice })
          total = b.find((e, i) => e.valueOf(i))
          c = this.state.milestone.map((e) => { return e.invid })
          invid = c.find((e, i) => e.valueOf(i))
          // console.log(this.state.milestone)
          if (this.state.milestone !== undefined) {
            mname = this.state.milestone.map((e) => e.name)
            completiondate = this.state.milestone.map((e) => e.createdAt)
            percentage = this.state.milestone.map((e) => e.percentage)
          }
          let sadata1 = [{
            invid: invid,
            invfile: this.state.milestone.find((e) => e.completionfile),
            invpath: this.state.milestone.completionpath,
            invname: this.state.invservicename,
            milestone: mname.toLocaleString(),
            percentage: percentage.find((e) => e),
            tax: tax,
            total: total,
            completiondate: completiondate.find((e) => e),
          }]
          this.setState({ ssdata: sadata1 })
          //this.state.ssdata.push(sadata1)
          // }

        } else {
           this.setState({ scntaxval: null, scnunittot: [], scnwithtot: [], sototal: [] })
            this.setState({ invtaxval: null, invtotal: null, invwithtax: null, invsototal: null })
            sutp=null,sta=null,stp=null
            // console.log(this.state.selectedInvoiceData)
            
            invcustomer=this.state.invoicelist1.customer

            invoiceservice.map((e) => { return sta = e.servicecost*e.servicetax/100 })
            sutp = invoiceservice.map((e) => e.servicecost)
            stp =  invoiceservice.map((e) => e.servicetotalwtax)
            // alert(sutp)

            this.setState({ scntaxval: sta, scnunittot: sutp, scnwithtot: stp, sototal: sotot }) 
        }
      }
    }
  }



  handleCheckboxChange = event => {
    this.setState({ displaychecked: event.target.checked })

  }
  selectFile = event => {
    this.setState({ selectedFiles: event.target.files, });
  }
  upload() {
    let currentFile = this.state.selectedFiles[0];
    this.setState({ progress: 100, currentFile: currentFile, });
    UploadFilesService.upload(currentFile, year, curmonth, doctype, this.state.partyid, day, (event) => {
      this.setState({ progress: Math.round((100 * event.loaded) / event.total) });
    }).then((response) => {
      this.setState({ message: response.data.message, isError: false });
      return FileServices.getFiles1(currentFile.name);
    }).then((files) => {
      this.setState({ fileInfos: files.data, });
    }).catch(() => {
      this.setState({
        progress: 0, message: "Could not upload the file!", currentFile: undefined,
        isError: true
      });
    });
    this.setState({ selectedFiles: undefined, });
  }
  onAeChange(value, values) {
    if (value !== undefined || this.state.invchecked === 1) {
      let ae = saedata.filter((e) => e.customsoid === value)
      let a
      ae.map(e => { return a = e.soid })
      this.setState({ SelectedAeSoid: a })
      aesup = ae.map(e => e.customer)
      ae.map(e => { return aecustsoid = e.customsoid })
      ae.map(e => { return aeamount = e.amountreq })
      this.setState({ aeval: aeamount })
      aefile = ae.map(e => e.proformainvoicefile)
      aedate = ae.map(e => e.proformainvoicedate)
      ae.map(e => { return aesupid = e.custid })
      this.setState({ aepoamount: ae.map(e => e.totalwtax),jvinsertiontype:6 })
      aepath = ae.map(e => e.proformainvoicepath)

    }
  }

  addSelect(e, id) {
    let val = this.state.invitems.filter((e) => e.id !== id)
    this.setState({ invitems: val })
    this.state.addedGRN.push(e)
  }

  itemSelected(value) {
    let itax
    if (value !== undefined) {
      let val = this.state.filteritemlist.filter((e) => e.name === value);
      itax = val.map((e) => e.tax)
      this.setState({ itemtax: itax.toLocaleString() })
    }
  }
  download(value) {
    if (this.state.invchecked === 0) {
      if (this.state.proinvoicefile === value) {
        return downloadfiles.download(this.state.proinvoicepath, this.state.proinvoicefile)
      } else if (this.state.invoicefile === value) {
        return downloadfiles.download(this.state.invoicepath, this.state.invoicefile)
      } else if (this.state.pofile === value) {
        return downloadfiles.download(this.state.popath, this.state.pofile)
      }
    } else if (this.state.invchecked === 1) {
      return downloadfiles.download(aepath, aefile)
    }
  }



  render() {
    let wh = this.state.wh;
    let grnval
    let expval1 = expcombdata.filter((e) => e.th === "TH2")
    entrytype = 0; // 0 Purchase
    let val = totalcombdata;
    let uomdata = uom;
    let so = appinv
    let credvalue, debservice
    let suppliers = val.filter((e) => e.type === 1 && e.shid === 100011)
    let insuledgers = iecombdata.concat(suppliers)
    let invitemdata

    if (val !== undefined) {
      invitemdata = totalcombdata.filter(
        (e) => (e.itemid !== null) & (e.tenantId === currentTenant)
      );
      credvalue = totalcombdata.filter((e) => e.subheadid === "AH000009")
      debservice = totalcombdata.filter((e) => e.subheadid === "AH000009")
    }
    // if (this.state.invchecked === 0) {
      debvalue = val.filter((e) => e.pid === invcustid);
    // } else {
    //   debvalue = val.filter((e) => e.pid === aesupid);
    // }
    let tax, taxsecond;
    // alert(jtype)
    if (gsttype === 1) {
      tax = val.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype === 0) & (e.indirectsubtaxtype === 0));

      taxsecond = val.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype === 0) & (e.indirectsubtaxtype === 1));
    }
    else if (gsttype === 2) {
      tax = val.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype === 0) & (e.indirectsubtaxtype === 2));
    }else if (gsttype === 3) {
      tax = val.filter((e) => (e.taxtype === 2) & (e.indirecttaxtype===1) & (e.indirectsubtaxtype===0) );
    } 
    else if (gsttype === undefined) {
      tax = val.filter((e) => e.taxtype === 2 & (e.indirecttaxtype === 1));
    }
    let grnvaldata = this.state.grnvaldata;
    let invitems = this.state.invitems;


    return (
      <div>
         <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Formik
          initialValues={{
            grnvaldata,
            invcustomer,
            grnval,
            invitems,
            uomdata,
            uom,
            wh,
            uprice: "",
            itemsqty: "",
            personal,
            real,
            debvalue,
            credvalue,
            credit: "",
            creditvalue: "",
            insurancelabel: "",
            insuranceamt: "",
            insupercent: "",
            insurancetot: "",
            insuledger: "",
            sdebit: "",
            sdebitval: "",
            sgst: "",
            taxpercent1: "",
            taxvalue1: "",
            cgst: "",
            taxpercent2: "",
            taxvalue2: "",
            igst: "",
            itaxpercent1: "",
            itaxvalue1: "",
            gtaxpercent1: "",
            gtaxvalue1: "",
            debit: "",
            debitval: "",
            // invitem: "",
            narration: "",
            so: "",
            invnew1: "",
            addl: [
              {
                name: null,
                tax: null,
                value: null,
                totamt: null,
                led: null,
                expled: null,
              },
            ],
          }}
          onSubmit={this.onSubmit}
          validateOnChange={true}
          validate={this.validate}
          enableReinitialize={true}
        >
          {({ values, setFieldValue }) => (
            <Form autoComplete="off">
              <div className="form-row mt-0 mb-0">

                  <div className="form-row col-md-12">
                    {sales === "YES" ? (
                      <>
                 <fieldset className="form-group col-md-3 mt-0 mb-3">
                <Autocomplete
                  id="invnew1"
                  className="invoices1 "
                  name="invnew1"
                  options={this.state.directinvoices}
                  getOptionLabel={(option) => option.custominvid}
                  isOptionEqualToValue={(option) => !option.value}
                  value={values.invnew ? undefined : undefined}
                  onChange={(e, value) => {
                    setFieldValue("invnew1", value);
                  }}
                  renderInput={(params) => (
                    <FormikTextField
                      {...params}
                      className="form-control mb-3"
                      type="text"
                      name="invnew1"
                      label="Direct Invoices"
                      onBlur={(e) => {
                        this.onInvoiceChange(
                          e.target.value,
                          values.invnew1
                        );
                      }}
                      variant="standard"
                      fullWidth
                    />
                  )}
                />
              </fieldset>

                        {this.state.selectedInvoice !== "" ? (
                          <div className="form-row col-md-12 bordered ">
                            <fieldset className="form-group col-md-3 align-left text-sm mt-0 mb-0">
                              <label>Customer</label>
                            </fieldset>
                            <fieldset className="form-group col-md-3 align-right text-sm mt-0 mb-0">
                              {invcustomer}
                            </fieldset>
                            <fieldset className="form-group col-md-3 align-left text-sm mt-0 mb-0">
                              <label>Total Tax</label>
                            </fieldset>
                            <fieldset className="form-group col-md-3 align-right text-sm mt-0 mb-0">
                              <NumericFormat
                                displayType={"text"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                value={
                                  this.state.invtaxval !== null
                                    ? this.state.invtaxval
                                    : this.state.scntaxval
                                }
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </fieldset>
                            <fieldset className="form-group col-md-3 align-left text-sm mt-0 mb-0">
                              <label>Total price</label>
                            </fieldset>
                            <fieldset className="form-group col-md-3 align-right text-sm mt-0 mb-0">
                              <NumericFormat
                                className="text-right"
                                displayType={"text"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                value={
                                  this.state.invtotal !== null
                                    ? this.state.invtotal
                                    : parseFloat(getTotal(this.state.scnunittot))
                                }
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </fieldset>
                           
                            <fieldset className="form-group col-md-3 align-left text-sm mt-0 mb-0">
                              <label>Taxed Price</label>
                            </fieldset>
                            <fieldset className="form-group col-md-3 align-right text-sm mt-0 mb-0">
                              <NumericFormat
                                displayType={"text"}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                disabled={true}
                                value={
                                  this.state.invwithtax !== null
                                    ? this.state.invwithtax
                                    : getTotal(this.state.scnwithtot)
                                }
                                thousandSeparator={true}
                                thousandsGroupStyle={thousandsdisplay}
                              ></NumericFormat>
                            </fieldset>
                            {/* <fieldset className="form-group col-md-3 align-left text-sm mt-0 mb-0">
                              <label>SO Total</label>
                            </fieldset>
                            <fieldset className="form-group col-md-3 align-left text-sm mt-0 mb-0">
                              {this.state.pofile.toLocaleString() !== "" ? (
                                <>
                                  <span className="text-sm mt-0 mb-0">
                                    Quotation :
                                    <span
                                      as="button"
                                      className="text-blue btn btn-link text-sm mt-0 mb-0"
                                      onClick={() =>
                                        this.download(this.state.pofile)
                                      }
                                    >
                                      {this.state.pofile}
                                    </span>
                                  </span>
                                  <ul>
                                    <span className="text-sm mt-0 mb-0">
                                      Date : &nbsp;
                                      {this.state.podate.toLocaleString() !== ""
                                        ? moment(
                                            this.state.podate.find((e, i) =>
                                              e.valueOf(i)
                                            )
                                          ).format("DD-MM-yyyy HH:mm")
                                        : ""}
                                    </span>
                                  </ul>
                                </>
                              ) : (
                                ""
                              )}
                            </fieldset> */}
                            <hr />
                          </div>
                        ) : (
                          ""
                        )}
                        {this.state.selectedInvoice !== "" ? (
                          <>
                            <fieldset className="form-group col-md-12 mt-0 ">
                              <Table className="table-sm">
                                <TableHead>
                                  <TableRow className="text-sm">
                                    <TableCell>Invoice</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Tax</TableCell>
                                    <TableCell>{this.state.selectedInvoiceData
                                            .totalinsuranceamount!==0?"Insurance":""}</TableCell>
                                    <TableCell>{this.state.selectedInvoiceData
                                            .totaladditionalcharges!==0?"Addl":""}</TableCell>
                                    <TableCell>Adjusted Advance</TableCell>
                                    <TableCell>Adjusted Opening</TableCell>
                                    <TableCell>Adjusted DN</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>Balance</TableCell>
                                    <TableCell>Tcs</TableCell>
                                    <TableCell>Tcs Amt</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell>
                                      {
                                        this.state.selectedInvoiceData
                                          .custominvid
                                      }
                                    </TableCell>
                                    <TableCell>
                                      <NumericFormat
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        value={
                                          this.state.selectedInvoiceData
                                            .totalinvoice
                                        }
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell>
                                      <NumericFormat
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        value={
                                          this.state.selectedInvoiceData
                                            .taxamount
                                        }
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell>
                                      <NumericFormat
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        value={
                                          this.state.selectedInvoiceData
                                            .totalinsuranceamount!==0?this.state.selectedInvoiceData
                                            .totalinsuranceamount:""
                                        }
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell>
                                      <NumericFormat
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        value={
                                          this.state.selectedInvoiceData
                                            .totaladditionalcharges!==0?
                                            this.state.selectedInvoiceData
                                            .totaladditionalcharges:""
                                        }
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell>
                                      <NumericFormat
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        value={
                                          this.state.advanceadjusted !== ""
                                            ? this.state.advanceadjusted
                                            : 0
                                        }
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell>
                                      <NumericFormat
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        value={
                                           this.state.openadjusted !== undefined || this.state.openadjusted !== "" 
                                            ? this.state.openadjusted
                                            : 0
                                        }
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell>
                                      <NumericFormat
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        value={
                                           this.state.dcadjusted !== undefined || this.state.openadjusted !== ""
                                            ? this.state.dcadjusted
                                            : 0
                                        }
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell>
                                      <NumericFormat
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        value={
                                          this.state.selectedInvoiceData
                                            .totalinvoicewtax
                                        }
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell>
                                      <NumericFormat
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        value={
                                          this.state.selectedInvoiceData
                                            .totalinvoicewtax -
                                          (this.state.advanceadjusted+this.state.openadjusted+this.state.dcadjusted)
                                        }
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell>
                                      <NumericFormat
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        value={
                                          this.state.selectedInvoiceData.tcs
                                        }
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell>
                                      <NumericFormat
                                        displayType={"text"}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        value={
                                          this.state.selectedInvoiceData
                                            .tcsamount
                                        }
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </fieldset>
                          </>
                        ) : (
                          ""
                        )}
                        {this.state.selectedInvoice !== "" ? (
                          <fieldset className="form-group col-md-12 mt-0 ">
                            <Table className="table-sm">
                              {this.state.invitemlist !== null ? (
                                <>
                                  <TableHead>
                                    <TableRow className="text-sm">
                                      <TableCell>Item</TableCell>
                                      <TableCell>Unit</TableCell>
                                      <TableCell>Quantity</TableCell>
                                      <TableCell>Tax</TableCell>
                                      <TableCell>Tax Amount</TableCell>
                                      <TableCell>Amount</TableCell>
                                      <TableCell>Category</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  
                                  <TableBody>
                                    {this.state.invitemlist!==undefined?<>
                                    {this.state.invitemlist.map((e, i) => (
                                      <TableRow key={i}>
                                        <TableCell>{e.name}</TableCell>
                                        <TableCell>
                                          <NumericFormat
                                            displayType={"text"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            value={e.price}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                        <TableCell>{e.quantity}</TableCell>
                                        <TableCell>{e.tax}%</TableCell>
                                        <TableCell>
                                          <NumericFormat
                                            displayType={"text"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            value={
                                              (e.price * e.quantity * e.tax) /
                                              100
                                            }
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                        <TableCell>
                                          <NumericFormat
                                            displayType={"text"}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            disabled={true}
                                            value={e.totalpricewtax}
                                            thousandSeparator={true}
                                            thousandsGroupStyle={
                                              thousandsdisplay
                                            }
                                          ></NumericFormat>
                                        </TableCell>
                                        <TableCell>{e.category}</TableCell>
                                      </TableRow>
                                    ))}</>:""}
                                  </TableBody>
                                </>
                              ) : (
                                <>
                                  <TableHead>
                                    <TableRow className="text-sm">
                                      <TableCell>Service Name</TableCell>
                                      <TableCell>Milestone</TableCell>
                                      <TableCell>Status</TableCell>
                                      <TableCell>Percentage</TableCell>
                                      <TableCell>Completion Date</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {this.state.milestone.map((e, i) => (
                                      <TableRow key={i}>
                                        <TableCell>
                                          {this.state.invservicename}
                                        </TableCell>
                                        <TableCell>{e.name}</TableCell>
                                        <TableCell>{e.servicestatus}</TableCell>
                                        <TableCell>{e.percentage}</TableCell>
                                        <TableCell>
                                          {moment(e.expecteddate).format(
                                            "DD-MM-yyyy HH:mm"
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </>
                              )}
                            </Table>
                          </fieldset>
                        ) : (
                          ""
                        )}
                        {this.state.productservicetotal !== undefined ? (
                          <>
                            {this.state.productservicetotal !== null ? (
                              <fieldset className="form-group col-md-12 align-right">
                                <Table className="table-sm table-equal table-bordered">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className="col-md-1 text-sm">
                                        Sevice price
                                      </TableCell>
                                      <TableCell className="col-md-1 text-sm">
                                        Sevice Tax %
                                      </TableCell>
                                      <TableCell className="col-md-1 text-sm">
                                        Tax Amount
                                      </TableCell>
                                      <TableCell className="col-md-1 text-sm">
                                        Total Amount
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell className="col-md-1 text-sm">
                                        <NumericFormat
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          value={this.state.productservicetotal}
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                      <TableCell className="col-md-1 text-sm">
                                        {this.state.productservicetax}
                                      </TableCell>
                                      <TableCell className="col-md-1 text-sm">
                                        <NumericFormat
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          value={
                                            this.state.prodservicetaxamount
                                          }
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                      <TableCell className="col-md-1 text-sm">
                                        <NumericFormat
                                          displayType={"text"}
                                          decimalScale={2}
                                          fixedDecimalScale={true}
                                          disabled={true}
                                          value={
                                            this.state.productservicetotal +
                                            this.state.prodservicetaxamount
                                          }
                                          thousandSeparator={true}
                                          thousandsGroupStyle={thousandsdisplay}
                                        ></NumericFormat>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </fieldset>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {this.state.displaychecked === true ? (
                          <fieldset className="form-group col-md-12 mt-0 mb-0">
                            <Table className="table-sm">
                              <TableHead>
                                <TableRow className="text-sm">
                                  <TableCell>
                                    Invoice ID : &nbsp;&nbsp;&nbsp;&nbsp;
                                    {this.state.custinvoiceid.length !== 0
                                      ? this.state.custinvoiceid.map((e, i) => (
                                          <label className="text-sm m-3">
                                            <Checkbox
                                              checked={e.isChecked}
                                              name={e.name}
                                              onChange={this.handleInvChange(i)}
                                            />
                                            {e.name}
                                          </label>
                                        ))
                                      : this.state.scnid.map((e, i) => (
                                          <label className="text-sm m-3">
                                            <Checkbox
                                              checked={e.isChecked}
                                              name={e.name}
                                              onChange={this.handleInvChange(i)}
                                            />
                                            {e.name}
                                          </label>
                                        ))}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          </fieldset>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                 {/* :""} */}
                {/* {this.state.invchecked === 1 ? 
                  <div className="form-row col-md-12">
                    <fieldset className="form-group col-md-4 mt-0 mt-0 test-sm">
                      <Autocomplete
                        id="ae1"
                        className="ae-select"
                        name="ae1"
                        options={saedata?saedata:[]}
                        getOptionLabel={(option) => option.customsoid}
                        value={values.ae1 ? undefined : undefined}
                        onChange={(e, value) => {
                          setFieldValue("ae", value);
                        }}
                        renderInput={(params) => (
                          <FormikTextField
                            {...params}
                            type="text"
                            name="ae"
                            label="Select SO/INVOICE"
                            variant="standard"
                            onBlur={(e) => {
                              this.onAeChange(e.target.value, values);
                            }}
                            fullWidth
                          />
                        )}
                      />
                    </fieldset>
                    {this.state.aeval !== "" ? 
                      <fieldset className="form-group col-md-8 mt-0 mt-0 test-sm">
                        <Table className="table-sm">
                          <TableHead>
                            <TableRow>
                              <TableCell>Customer</TableCell>
                              <TableCell>Date</TableCell>
                              <TableCell>Advance Amount</TableCell>
                              <TableCell>Invoice</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>{aesup}</TableCell>
                              <TableCell>
                                {moment({ aedate }).format("DD-MM-yyyy")}
                              </TableCell>
                              <TableCell>{aeamount}</TableCell>
                              <TableCell className="sm">
                                <span
                                  as="button"
                                  className="text-blue btn btn-link"
                                  onClick={this.download}
                                >
                                  {aefile}
                                </span>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </fieldset>
                    :""}
                  </div>
   
                :""} */}
                
               
              </div>
              {this.state.invchecked !== "" ? (
                <div className="form-row col-md-12 text-left form-switch mt-3">
                  <div className="col-md-3 text-left">
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          className="sm"
                          checked={this.state.SDdate}
                          onChange={this.handleSDDate}
                          name="checkedB"
                        />
                      }
                    />
                    <span className="text-left textColour">
                      {this.state.SDdate === false
                        ? "System Date"
                        : "Custom Date"}
                    </span>
                  </div>
                  {this.state.SDdate === true ? (
                    <div className="col-md-3 text-left">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid justifyContent="space-around">
                          <DateTimePicker
                            disableFuture
                            showTodayButton
                            showCancelButton
                            variant="standard"
                            id="date-picker-dialog"
                            inputVariant="standard"
                            minDate={
                              new Date(
                                moment(transactionFrom).format("yyyy-MM-DD")
                              )
                            }
                            maxDate={
                              new Date(
                                moment(financialyearend).format("yyyy-MM-DD")
                              )
                            }
                            format="dd/MM/yyyy HH:mm"
                            value={this.state.fsd}
                            InputAdornmentProps={{ position: "start" }}
                            onChange={this.handleFsdDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            autoOk={true}
                          />
                        </Grid>
                      </LocalizationProvider>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {(this.state.selectedInvoice !== "") ? (
                <>
                  <div className="form-row">
                    <fieldset className="form-group col-md-6 mt-0 mb-2 test-sm">
                      <Autocomplete
                        id="credit1"
                        className="credit-select"
                        name="credit1"
                        options={credvalue.sort(
                          (a, b) => -a.subname.localeCompare(b.subname)
                        )}
                        groupBy={(option) => option.subname}
                        getOptionLabel={(option) => option.name}
                        value={values.credit ? undefined : undefined}
                        onChange={(e, value) => {
                          setFieldValue("credit", value);
                        }}
                        renderInput={(params) => (
                          <FormikTextField
                            {...params}
                            type="text"
                            name="credit"
                            label="Credit(Sales Category Ledger)"
                            variant="standard"
                            onBlur={() =>
                              gsttype === 1
                                ? this.state.invtaxval !== null
                                  ? 
                                  setFieldValue("sdebitval",this.state.productservicetotal!==null?parseFloat(this.state.productservicetotal).toFixed(2):null) &&
                                  setFieldValue("creditvalue",this.state.advanceadjusted!== "" ? parseFloat((this.state.invtotal-
                                  this.state.advanceadjusted+this.state.openadjusted+this.state.dcadjusted).toFixed(2))
                                      : parseFloat(this.state.invtotal).toFixed(2)) &&
                                  setFieldValue( "taxvalue1",this.state.prodservicetaxamount !== null? parseFloat((this.state.invtaxval / 2).toFixed(2))
                                      : this.state.addlchargetaxtot !== 0? parseFloat(((this.state.addlchargetaxtot + this.state.invtaxval) / 2).toFixed(2))
                                      : parseFloat(this.state.invprodtax / 2 ).toFixed(2)) &&
                                 setFieldValue("taxvalue2", this.state.prodservicetaxamount !== null? parseFloat((this.state.invtaxval / 2).toFixed(2))
                                      : this.state.addlchargetaxtot !== "" ? parseFloat(((this.state.addlchargetaxtot +
                                          this.state.invtaxval) / 2).toFixed(2)): parseFloat((this.state.invprodtax / 2).toFixed(2))) &&
                                  setFieldValue( "debtotal",this.state.prodservicetaxamount === null? parseFloat((this.state.invwithtax).toFixed(2))
                                      : this.state.addlamounttot !== "" ? parseFloat((this.state.addlamounttot +
                                        this.state.invwithtax + this.state.productservicetotal +this.state.invtaxval).toFixed(2))
                                      :parseFloat(this.state.invwithtax + this.state.productservicetotal +this.state.invtaxval).toFixed(2))
                                : setFieldValue("sdebitval", this.state.productservicetotal !== null?parseFloat(this.state.productservicetotal).toFixed(2)
                                      :  setFieldValue( "creditvalue", this.state.scnunittot !== null && this.state.scnunittot.length !== 0
                                      ? parseFloat(getTotal(this.state.scnunittot)): parseFloat(getTotal(this.state.scnunittot))
                                  ) &&
                                  setFieldValue("taxvalue1",this.state.scntaxval !== null? parseFloat((this.state.scntaxval / 2 ).toFixed(2)): 0) &&
                                  setFieldValue("taxvalue2",this.state.scntaxval !== null? parseFloat((this.state.scntaxval / 2 ).toFixed(2)): 0) &&
                                  setFieldValue("debitval", this.state.scnwithtot.length !== 0 ? parseFloat(getTotal(this.state.scnwithtot).toFixed(2)): parseFloat(this.state.scnwithtot).toFixed(2)
                                  && parseFloat((this.state.productservicetotal).toFixed(2))))
                              : (gsttype === 2 || gsttype===3)? this.state.invtaxval !== null?setFieldValue("creditvalue", this.state.advanceadjusted !== undefined ? 
                              parseFloat((this.state.invtotal -this.state.advanceadjusted).toFixed(2))
                                      :this.state.dcadjusted !== undefined? parseFloat((this.state.invtotal - this.state.dcadjusted).toFixed(2))
                                      :parseFloat((this.state.invtotal).toFixed(2))
                                  ) &&
                              setFieldValue("sdebitval",this.state.productservicetotal) &&
                              setFieldValue(gsttype === 2?"itaxvalue1":"gtaxvalue1",this.state.addlchargetaxtot !== 0? this.state.addlchargetaxtot +
                                          this.state.invtaxval
                                      : parseFloat((this.state.invtaxval + this.state.prodservicetaxamount).toFixed(2))
                                  ) &&
                              // setFieldValue( "taxvalue1",this.state.prodservicetaxamount === null? parseFloat((this.state.invtaxval ).toFixed(2))
                              // : this.state.addlchargetaxtot !== 0? parseFloat(((this.state.addlchargetaxtot + this.state.invprodtax)).toFixed(2))
                              // : parseFloat(this.state.invprodtax).toFixed(2)) &&
                            setFieldValue("debtotal",this.state.addlamounttot !== ""? parseFloat((getTotal(this.state.scnwithtot) +
                                          this.state.scntaxval +this.state.grandservicetotal +this.state.addlamounttot))
                                      : parseFloat((getTotal(this.state.scnwithtot) + this.state.scntaxval + this.state.grandservicetotal).toFixed(2))
                                  ) &&
                           setFieldValue("debitval",this.state.addlamounttot !== ""?parseFloat((this.state.addlamounttot + getTotal(this.state.scnunittot) +
                                          this.state.grandservicetotal))
                                      : parseFloat((getTotal(this.state.scnunittot) +this.state.grandservicetotal).toFixed(2))
                                  )
                                : setFieldValue("creditvalue",this.state.scnunittot !== null && this.state.scnunittot.length !== 0
                                ? parseFloat(getTotal(this.state.scnunittot)): parseFloat(getTotal(this.state.scnunittot))) &&
                                  setFieldValue(gsttype === 2?"itaxvalue1":"gtaxvalue1",this.state.scntaxval !== null? parseFloat((this.state.scntaxval).toFixed(2))
                                      : parseFloat((this.state.servicetaxamount).toFixed(2))
                                  )
                              : this.state.invchecked === 1 &&
                                aeamount !== undefined
                              ? setFieldValue("creditvalue", aeamount)
                              //  && setFieldValue("debitval", aeamount)
                              : "" && this.onCreditChange(values)
                          }
                          fullWidth
                          />
                        )}
                      />
                    </fieldset>
                    <fieldset className="form-group col-md-6 mt-0 mb-2 test-sm">
                      <FormikTextField
                        name="creditvalue"
                        className="form-control"
                        label={values.debitval ? " " : "Value"}
                        type="text"
                        variant="standard"
                      ></FormikTextField>
                    </fieldset>
                  </div>
                  {this.state.insurancereq === true? (
                    <div className="form-row">
                      <fieldset className="form-group col-md-3 mt-2 mb-2 test-sm">
                        <FormikTextField
                          name="insurancelabel"
                          label="Insurance"
                          type="text"
                          variant="standard"
                          fullWidth
                          disabled
                        ></FormikTextField>
                      </fieldset>
                      <fieldset className="form-group col-md-2 mt-2 mb-2 test-sm">
                        <FormikTextField
                          name="insuranceamt"
                          label={
                            this.state.insuranceamount !== null
                              ? "Insurance Amount "
                              : "Amount"
                          }
                          type="text"
                          variant="standard"
                          value={this.state.insuranceamount}
                          fullWidth
                        ></FormikTextField>
                      </fieldset>

                      <fieldset className="form-group col-md-2 mt-2 mb-2 test-sm">
                        <FormikTextField
                          name="insupercent"
                          label={
                            this.state.insurancetax !== null
                              ? "Tax @" + this.state.insurancetax + " %"
                              : "Tax Amount"
                          }
                          type="text"
                          variant="standard"
                          value={
                            (this.state.insuranceamount *
                              this.state.insurancetax) /
                            100
                          }
                          fullWidth
                        ></FormikTextField>
                      </fieldset>

                      <fieldset className="form-group col-md-2 mt-2 mb-2 test-sm">
                        <FormikTextField
                          name="insurancetot"
                          label={
                            this.state.insurancetax !== null
                              ? "Total Amount"
                              : "Tot Amount"
                          }
                          type="text"
                          variant="standard"
                          value={
                            (this.state.insuranceamount *
                              this.state.insurancetax) /
                              100 +
                            this.state.insuranceamount
                          }
                          fullWidth
                        ></FormikTextField>
                      </fieldset>

                      <fieldset className="form-group col-md-3 mt-2 mb-2 test-sm">
                        <FastField
                          name="insuledger"
                          validate={(value) =>
                            value === null ||
                            value === undefined ||
                            value === "" ||
                            value.length === 0
                              ? "Please Select a valid Ledger"
                              : undefined
                          }
                        >
                          {() => (
                            <Autocomplete
                              id="insuledger"
                              name="insuledger"
                              options={insuledgers.sort(
                                (a, b) => -a.subname.localeCompare(b.subname)
                              )}
                              groupBy={(option) => option.subname}
                              getOptionLabel={(option) => option.name}
                              value={values.expense ? undefined : undefined}
                              onChange={(e, value) => {
                                setFieldValue("insuledger", value);
                              }}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  type="text"
                                  name="insuledger"
                                  label="Map Insurance Ledger"
                                  variant="standard"
                                  onBlur={() => {
                                    setFieldValue(
                                      "insuranceamt",
                                      this.state.insuranceamount
                                    ) &&
                                      setFieldValue(
                                        "insupercent",
                                        (this.state.insuranceamount *
                                          this.state.insurancetax) /
                                          100
                                      ) &&
                                      setFieldValue(
                                        "insurancetot",
                                        (this.state.insuranceamount *
                                          this.state.insurancetax) /
                                          100 +
                                          this.state.insuranceamount
                                      );
                                  }}
                                  fullWidth
                                />
                              )}
                            />
                          )}
                        </FastField>
                      </fieldset>
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.addlcharges !== undefined &&
                  this.state.invitemlist!==undefined && this.state.invitemlist.length !== 0  ? (
                    <>
                      <div className="form-row">
                        <FieldArray
                          name="addl"
                          render={(arrayHelpers) => (
                            <>
                              {this.state.addlcharges &&
                              this.state.addlcharges.length > 0
                                ? this.state.addlcharges.map((addl, index) => (
                                    <>
                                      <fieldset className="form-group col-md-2 mt-0 mb-0 test-sm">
                                        <FormikTextField
                                          name={`addl[${index}].name`}
                                          label={
                                            addl.natureofcharge !== null
                                              ? " "
                                              : "Addl Charge"
                                          }
                                          type="text"
                                          variant="standard"
                                          value={addl.natureofcharge}
                                          fullWidth
                                        ></FormikTextField>
                                      </fieldset>

                                      <fieldset className="form-group col-md-2 mt-0 mb-0 test-sm">
                                        <FormikTextField
                                          name={`addl[${index}].value`}
                                          label={
                                            addl.additionalcharges !== null
                                              ? "Charge Amount "
                                              : "Amount"
                                          }
                                          type="text"
                                          variant="standard"
                                          value={addl.additionalcharges}
                                          fullWidth
                                        ></FormikTextField>
                                      </fieldset>
                                      <fieldset className="form-group col-md-1 mt-0 mb-0 test-sm">
                                        <FormikTextField
                                          name={`addl[${index}].tax`}
                                          label={
                                            addl.additionalchargestaxamount !==
                                            null
                                              ? "Tax @" +
                                                addl.additionalchargestax +
                                                " %"
                                              : "Tax Amount"
                                          }
                                          type="text"
                                          variant="standard"
                                          value={
                                            addl.additionalchargestaxamount
                                          }
                                          fullWidth
                                        ></FormikTextField>
                                      </fieldset>
                                      <fieldset className="form-group col-md-1 mt-0 mb-0 test-sm">
                                        <FormikTextField
                                          name={`addl[${index}].totamt`}
                                          label={
                                            addl.totaladditionalcharges !== null
                                              ? "Total Amount "
                                              : "TOT Amount"
                                          }
                                          type="text"
                                          variant="standard"
                                          value={addl.totaladditionalcharges}
                                          fullWidth
                                        ></FormikTextField>
                                      </fieldset>
                                      <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm">
                                        <FastField
                                          name={`addl[${index}].led`}
                                          validate={(value) =>
                                            value === null ||
                                            value === undefined ||
                                            value === "" ||
                                            value.length === 0
                                              ? "Please Select a valid Ledger"
                                              : undefined
                                          }
                                        >
                                          {() => (
                                            <Autocomplete
                                              id="additional"
                                              name={`addl[${index}].led`}
                                              options={suppliers.sort(
                                                (a, b) =>
                                                  -a.subname.localeCompare(
                                                    b.subname
                                                  )
                                              )}
                                              groupBy={(option) =>
                                                option.subname
                                              }
                                              getOptionLabel={(option) =>
                                                option.name
                                              }
                                              value={
                                                values.expense
                                                  ? undefined
                                                  : undefined
                                              }
                                              onChange={(e, value) => {
                                                setFieldValue(
                                                  `addl[${index}].led`,
                                                  value
                                                );
                                              }}
                                              renderInput={(params) => (
                                                <FormikTextField
                                                  {...params}
                                                  type="text"
                                                  name={`addl[${index}].led`}
                                                  label="Exp Ledger"
                                                  variant="standard"
                                                  onBlur={(e) => {
                                                    setFieldValue(
                                                      `addl[${index}].name`,
                                                      addl.natureofcharge
                                                    ) &&
                                                      setFieldValue(
                                                        `addl[${index}].tax`,
                                                        addl.additionalchargestaxamount
                                                      ) &&
                                                      setFieldValue(
                                                        `addl[${index}].value`,
                                                        addl.additionalcharges
                                                      ) &&
                                                      setFieldValue(
                                                        `addl[${index}].totamt`,
                                                        addl.totaladditionalcharges
                                                      );
                                                  }}
                                                  fullWidth
                                                />
                                              )}
                                            />
                                          )}
                                        </FastField>
                                      </fieldset>
                                      <fieldset className="form-group col-md-3 mt-0 mb-0 test-sm">
                                        <Autocomplete
                                          id="expense"
                                          className="expense-select"
                                          name={`addl[${index}].expled`}
                                          options={expval1.sort(
                                            (a, b) =>
                                              -a.subname.localeCompare(
                                                b.subname
                                              )
                                          )}
                                          groupBy={(option) => option.subname}
                                          getOptionLabel={(option) =>
                                            option.name
                                          }
                                          value={
                                            values.income
                                              ? undefined
                                              : undefined
                                          }
                                          onChange={(e, value) => {
                                            setFieldValue(
                                              `addl[${index}].expled`,
                                              value
                                            );
                                          }}
                                          renderInput={(params) => (
                                            <FormikTextField
                                              {...params}
                                              type="text"
                                              name={`addl[${index}].expled`}
                                              label="Towards"
                                              variant="standard"
                                              fullWidth
                                            />
                                          )}
                                        />
                                      </fieldset>
                                    </>
                                  ))
                                : ""}
                            </>
                          )}
                        />
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  <div className="form-row mt-0 mb-0 ">
                    {this.state.selectedSO !== "" &&
                    this.state.productservicetotal !== null &&
                    this.state.aeval === "" ? (
                      <>
                        <fieldset className="form-group col-md-1 mt-0 mb-0 test-sm"></fieldset>
                        <fieldset className="form-group col-md-5 mt-0 mt-0 test-sm">
                          <Autocomplete
                            id="sdebit"
                            className="sdebit-select"
                            name="sadebit"
                            options={
                              debservice !== undefined
                                ? debservice.sort(
                                    (a, b) =>
                                      -a.subname.localeCompare(b.subname)
                                  )
                                : ""
                            }
                            groupBy={(option) => option.subname}
                            getOptionLabel={(option) =>
                              option.name + " (" + option.amount + ")"
                            }
                            value={values.sdebit ? undefined : undefined}
                            onChange={(e, value) => {
                              setFieldValue("sdebit", value);
                            }}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                type="text"
                                name="sdebit"
                                label="Service Debit"
                                variant="standard"
                                onBlur={() =>
                                  setFieldValue(
                                    "sdebitval",
                                    this.state.productservicetotal
                                  )
                                }
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>
                        <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
                          <FormikTextField
                            name="sdebitval"
                            className="form-control"
                            label={
                              this.state.productservicetotal !== null
                                ? " "
                                : "Value"
                            }
                            type="text"
                            onBlur={() =>
                              this.state.invchecked !== 0
                                ? setFieldValue(
                                    "debitval",
                                    values.creditvalue
                                  ) &&
                                  setFieldValue("debtotal", values.creditvalue)
                                : ""
                            }
                            variant="standard"
                          ></FormikTextField>
                        </fieldset>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {ccountry === "India" &&
                  jtype === 3 &&
                  gsttype === 1 ? (
                    <>
                      <div className="form-row mt-0 mb-0 ">
                        <fieldset className="form-group col-md-1 mt-0 mb-0 test-sm"></fieldset>
                        <fieldset className="form-group col-md-5 mt-0 mb-0 test-sm">
                          <Autocomplete
                            id="SGST"
                            className="Tax-select"
                            name="sgst"
                            options={tax}
                            getOptionLabel={(option) => option.name}
                            value={values.sgst ? undefined : undefined}
                            onChange={(e, value) => {
                              setFieldValue("sgst", value);
                            }}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                type="text"
                                name="sgst"
                                label="Select Tax"
                                onBlur={() => this.onCreditChange(values)}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>

                        {/* {this.state.invchecked !== 0 ? (
                          <fieldset className="form-group col-md-1 mt-0 mb-0 test-sm">
                            <FormikTextField
                              name="taxpercent1"
                              className="form-control"
                              label={values.taxpercent1 ? " " : "%"}
                              type="text"
                              onBlur={() =>
                                setFieldValue(
                                  "taxvalue1",
                                  (values.debitval * values.taxpercent1) / 100
                                ) &&
                                setFieldValue(
                                  "taxpercent2",
                                  values.taxpercent1
                                ) &&
                                setFieldValue(
                                  "taxvalue2",
                                  (values.creditvalue * values.taxpercent1) /
                                    100
                                )
                              }
                              variant="standard"
                            />
                          </fieldset>
                        ) : (
                          ""
                        )} */}
                        {/* {inventrychecked === 1 ? ( */}
                          <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
                            <FormikTextField
                              name="taxvalue1"
                              className="form-control"
                              label={
                                this.state.invtaxval !== null
                                  ? this.state.invtaxval
                                    ? " "
                                    : "Value"
                                  : this.state.scntaxval
                                  ? " "
                                  : "Value"
                              }
                              type="text"
                              variant="standard"
                            ></FormikTextField>
                          </fieldset> 
                    
                         {/*  <fieldset className="form-group col-md-5 mt-0 mb-0 test-sm">
                            <NumericFormat
                              name="taxvalue1"
                              className="form-control"
                              value={
                                (values.debitval * values.taxpercent1) / 100
                              }
                              label={values.taxpercent1 ? " " : "Value"}
                              displayType={"input"}
                              customInput={TextField}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              thousandsGroupStyle={thousandsdisplay}
                              onBlur={() =>
                                setFieldValue(
                                  "debtotal",
                                  values.taxvalue1 +
                                    values.creditvalue +
                                    values.taxvalue2
                                )
                              }
                            ></NumericFormat>
                          </fieldset>
                        {/* )} */}
                      </div>
                      <div className="form-row">
                        <fieldset className="form-group col-md-1 mt-0 mb-0 test-sm"></fieldset>
                        <fieldset className="form-group col-md-5 mt-0 mb-0 test-sm">
                          <Autocomplete
                            id="CGST"
                            className="Tax-select"
                            name="CGST"
                            options={taxsecond}
                            getOptionLabel={(option) => option.name}
                            value={values.cgst ? undefined : undefined}
                            onChange={(e, value) => {
                              setFieldValue("cgst", value);
                            }}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                type="text"
                                name="cgst"
                                label="Select Tax"
                                onBlur={() => {
                                  this.onCreditChange(values) &&
                                    setFieldValue(
                                      "taxpercent2",
                                      values.taxpercent1
                                    ) &&
                                    setFieldValue(
                                      "taxvalue2",
                                      (values.creditvalue *
                                        values.taxpercent1) /
                                        100
                                    );
                                }}
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>
                        {/* {this.state.invchecked !== 0 ? ( */}
                          {/* <fieldset
                            className="form-group col-md-1 mt-0 mb-0 test-sm"
                            disabled
                          >
                            <FormikTextField
                              name="taxpercent2"
                              className="form-control"
                              label={values.taxpercent1 ? " " : "%"}
                              type="text"
                              value={values.taxpercent1}
                              onBlur={() => {
                                setFieldValue(
                                  "taxvalue2",
                                  (values.creditvalue * values.taxpercent2) /
                                    100
                                );
                              }}
                              variant="standard"
                            />
                          </fieldset> */}
                        {/* ) : (
                          ""
                        )} */}
                        {/* {this.state.invchecked === 0 ? ( */}
                          <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
                            <FormikTextField
                              name="taxvalue2"
                              className="form-control"
                              label={
                                this.state.invtaxval !== null
                                  ? this.state.invtaxval
                                    ? " "
                                    : "Value"
                                  : this.state.scntaxval
                                  ? " "
                                  : "Value"
                              }
                              type="text"
                              variant="standard"
                            ></FormikTextField>
                          </fieldset>
                        {/* ) : ( */}
                          {/* <fieldset
                            className="form-group col-md-5 mt-0 mb-0 test-sm"
                            disabled
                          >
                            <NumericFormat
                              name="taxvalue2"
                              className="form-control"
                              value={
                                (values.debitval * values.taxpercent2) / 100
                              }
                              label={values.taxpercent1 ? " " : "Value"}
                              displayType={"input"}
                              customInput={TextField}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              thousandSeparator={true}
                              thousandsGroupStyle={thousandsdisplay}
                              onBlur={() =>
                                setFieldValue(
                                  "debitval",
                                  values.taxvalue1 +
                                    values.creditvalue +
                                    values.taxvalue2 +
                                    this.state.addlamounttot
                                ) &&
                                setFieldValue(
                                  "debtotal",
                                  values.taxvalue1 +
                                    values.creditvalue +
                                    values.taxvalue2
                                )
                              }
                            ></NumericFormat>
                          </fieldset> */}
                        {/* )} */}
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {ccountry === "India" &&
                  jtype === 3 &&
                  gsttype === 2  ? (
                    <div className="form-row">
                      <fieldset className="form-group col-md-1 mt-0 mb-0 test-sm"></fieldset>
                      <fieldset className="form-group col-md-5 mt-0 mb-0 test-sm">
                        <Autocomplete
                          id="IGST"
                          className="Tax-select"
                          name="igst"
                          options={tax}
                          getOptionLabel={(option) => option.name}
                          value={values.igst ? undefined : undefined}
                          onChange={(e, value) => {
                            setFieldValue("igst", value);
                          }}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              type="text"
                              name="igst"
                              label="Select Tax"
                              onBlur={() => {
                                this.onCreditChange(values);
                              }}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      {/* {this.state.invchecked !== 0 ? (
                        <fieldset className="form-group col-md-1 mt-0 mb-0 test-sm">
                          <FormikTextField
                            name="itaxpercent1"
                            className="form-control"
                            label={values.itaxpercent1 ? " " : "%"}
                            type="text"
                            onBlur={() =>
                              setFieldValue(
                                "itaxvalue1",
                                (values.creditvalue * values.itaxpercent1) / 100
                              )
                            }
                            variant="standard"
                          />
                        </fieldset>
                      ) : (
                        ""
                      )} */}

                      {/* {this.state.invchecked === 0 ? ( */}
                        <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
                          <FormikTextField
                            name="itaxvalue1"
                            className="form-control"
                            label={
                              this.state.invtaxval !== null
                                ? this.state.invtaxval
                                  ? " "
                                  : "Value"
                                : this.state.scntaxval
                                ? " "
                                : "Value"
                            }
                            type="text"
                            variant="standard"
                          ></FormikTextField>
                        </fieldset>
                      {/* ) : (
                        <fieldset className="form-group col-md-5 mt-0 mb-0 test-sm">
                          <NumericFormat
                            name="itaxvalue1"
                            className="form-control"
                            value={
                              (values.debitval * values.itaxpercent1) / 100
                            }
                            label={values.itaxpercent1 ? " " : "Value"}
                            displayType={"input"}
                            customInput={TextField}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            thousandSeparator={true}
                            thousandsGroupStyle={thousandsdisplay}
                            onBlur={() =>
                              setFieldValue(
                                "debitval",
                                this.state.addlamounttot !== ""
                                  ? this.state.addlamounttot +
                                      values.itaxvalue1 +
                                      values.creditvalue
                                  : values.itaxvalue1 + values.creditvalue
                              ) &&
                              setFieldValue(
                                "debtotal",
                                this.state.addlamounttot !== ""
                                  ? this.state.addlamounttot +
                                      values.itaxvalue1 +
                                      values.creditvalue
                                  : values.itaxvalue1 + values.creditvalue
                              )
                            }
                          ></NumericFormat>
                        </fieldset>
                      )} */}
                    </div>
                  ) : (
                    ""
                  )}

                  {taxtype !== "GST" &&
                  jtype === 3 ? (
                    <div className="form-row">
                      <fieldset className="form-group col-md-1 mt-0 mb-0 test-sm"></fieldset>
                      <fieldset className="form-group col-md-5 mt-0 mb-0 test-sm">
                        <Autocomplete
                          id="tax"
                          className="Tax-select"
                          name="tax"
                          options={tax}
                          getOptionLabel={(option) =>
                            option.name + " (" + option.amount + ")"
                          }
                          value={values.tax ? undefined : undefined}
                          onChange={(e, value) => {
                            setFieldValue("tax", value);
                          }}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              type="text"
                              name="tax"
                              label="Select Vat"
                              onBlur={() => {
                                this.onCreditChange(values);
                              }}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
{/* 
                      {this.state.invchecked !== 0 ? (
                        <fieldset className="form-group col-md-1 mt-0 mb-0 test-sm">
                          <FormikTextField
                            name="gtaxpercent1"
                            className="form-control"
                            label={values.gtaxpercent1 ? " " : "%"}
                            type="text"
                            onBlur={() =>
                              setFieldValue(
                                "gtaxvalue1",
                                (values.creditvalue * values.gtaxpercent1) / 100
                              )
                            }
                            variant="standard"
                          />
                        </fieldset>
                      ) : (
                        ""
                      )} */}

                      {/* {this.state.invchecked === 0 ? ( */}
                        <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
                          <FormikTextField
                            name="gtaxvalue1"
                            className="form-control"
                            label={
                              this.state.invtaxval !== null
                                ? this.state.invtaxval
                                  ? " "
                                  : "Value"
                                : this.state.scntaxval
                                ? " "
                                : "Value"
                            }
                            type="text"
                            variant="standard"
                          ></FormikTextField>
                        </fieldset>
                      {/* ) : (
                        <fieldset className="form-group col-md-5 mt-0 mt-0 test-sm">
                          <FormikTextField
                            name="gtaxvalue1"
                            className="form-control"
                            label={values.gtaxpercent1 ? " " : "Value"}
                            type="text"
                            onBlur={() =>
                              setFieldValue(
                                "debitval",
                                this.state.addlamounttot !== ""
                                  ? this.state.addlamounttot +
                                      values.gtaxvalue1 +
                                      values.debitval
                                  : values.gtaxvalue1 + values.debitval
                              ) &&
                              setFieldValue(
                                "debtotal",
                                this.state.addlamounttot !== ""
                                  ? this.state.addlamounttot +
                                      values.gtaxvalue1 +
                                      values.debitval
                                  : values.gtaxvalue1 + values.debitval
                              )
                            }
                            variant="standard"
                          />
                        </fieldset>
                      )} */}
                    </div>
                  ) : (
                    ""
                  )}

                  <fieldset
                    className="form-group col-md-1 mt-0 mb-0 test-sm"
                    hidden
                  >
                    <FormikTextField
                      name="debtotal"
                      className="form-control"
                      label="%"
                      type="text"
                      variant="standard"
                    />
                  </fieldset>
                  <div className="form-row mt-0 mb-0">
                    <fieldset className="form-group col-md-1 mt-0 mb-0 test-sm"></fieldset>
                    <fieldset className="form-group col-md-5 mt-0 mt-0 test-sm">
                      <Autocomplete
                        id="debit"
                        className="debit-select"
                        name="adebit"
                        options={debvalue.sort(
                          (a, b) => -a.subname.localeCompare(b.subname)
                        )}
                        groupBy={(option) => option.subname}
                        // options={debvalue}
                        getOptionLabel={(option) =>
                          option.name + " (" + option.amount + ")"
                        }
                        value={values.debit ? undefined : undefined}
                        onChange={(e, value) => {
                          setFieldValue("debit", value);
                        }}
                        renderInput={(params) => (
                          <FormikTextField
                            {...params}
                            type="text"
                            name="debit"
                            label="Debit"
                            onBlur={() => (
                              <>
                                {jtype === 3 && gsttype === 1
                                  ? 

                                  setFieldValue( "debitval", 
                                  this.state.addlamounttot !== undefined
                                    ? parseFloat(this.state.addlamounttot + values.taxvalue1 + values.creditvalue + values.taxvalue2 +
                                    (this.state.productservicetotal!==null?this.state.productservicetotal:0)).toFixed(2)
                                    :this.state.productservicetotal !== undefined ||this.state.productservicetotal !== null?
                                     parseFloat(values.taxvalue1 + values.creditvalue+ values.taxvalue2 + this.state.productservicetotal).toFixed(2)
                                    : parseFloat(values.taxvalue1 +values.creditvalue +
                                    values.taxvalue2).toFixed(2)
                                ) &&
                                setFieldValue( "debtotal",
                                  this.state.addlamounttot !== undefined
                                  ? parseFloat(this.state.addlamounttot +
                                 values.taxvalue1 +values.creditvalue +values.taxvalue2 +
                                  (this.state.productservicetotal!==null?this.state.productservicetotal:0)).toFixed(2)
                                  :this.state.productservicetotal !== undefined ||this.state.productservicetotal !== null?
                                   parseFloat(values.taxvalue1 +values.creditvalue+values.taxvalue2 +
                                 this.state.productservicetotal).toFixed(2)
                                  : parseFloat(values.taxvalue1 +values.creditvalue +values.taxvalue2).toFixed(2)
                                ) &&
                                this.onDebChange(values)
                              : ccountry === "India" &&
                                jtype === 3 &&
                                gsttype === 2
                              ? setFieldValue(
                                  "debitval",
                                  this.state.addlamounttot !== undefined
                                  ? parseFloat(this.state.addlamounttot + values.itaxvalue1 + values.creditvalue + values.sdebitval).toFixed(2)
                                  : parseFloat(values.itaxvalue1 +values.creditvalue + values.sdebitval).toFixed(2)
                                ) &&
                                setFieldValue(
                                  "debtotal",
                                  this.state.addlamounttot !== undefined
                                  ? parseFloat(this.state.addlamounttot + values.itaxvalue1 + values.creditvalue + values.sdebitval).toFixed(2)
                                  : parseFloat(values.itaxvalue1 +values.creditvalue + values.sdebitval).toFixed(2)
                                )
                              : ccountry !== "India" && jtype === 3
                              ? setFieldValue(
                                  "debitval",
                                  this.state.addlamounttot !== undefined
                                  ? parseFloat(this.state.addlamounttot + values.gtaxvalue1 + values.creditvalue).toFixed(2)
                                  : parseFloat(values.gtaxvalue1 +values.creditvalue).toFixed(2)
                                ) &&
                                setFieldValue(
                                  "debtotal",
                                  this.state.addlamounttot !== undefined
                                  ? parseFloat(this.state.addlamounttot + values.gtaxvalue1 + values.creditvalue).toFixed(2)
                                  : parseFloat(values.gtaxvalue1 +values.creditvalue).toFixed(2)
                                )
                              : this.state.invchecked === 1
                              ? setFieldValue(
                                  "debitval",
                                  parseFloat(values.creditvalue).toFixed(2)
                                )




                                  // setFieldValue(
                                  //     "debitval",
                                  //     this.state.addlamounttot !== ""
                                  //       ? this.state.addlamounttot +
                                  //           values.taxvalue1 +
                                  //           values.creditvalue +
                                  //           values.taxvalue2 +
                                  //           this.state.productservicetotal
                                  //       : values.taxvalue1 +
                                  //           values.creditvalue +
                                  //           values.taxvalue2 +
                                  //           this.state.productservicetotal
                                  //   ) &&
                                  //   setFieldValue(
                                  //     "debtotal",
                                  //     this.state.addlamounttot !== ""
                                  //       ? this.state.addlamounttot +
                                  //           values.taxvalue1 +
                                  //           values.creditvalue +
                                  //           values.taxvalue2 +
                                  //           this.state.productservicetotal
                                  //       : values.taxvalue1 +
                                  //           values.creditvalue +
                                  //           values.taxvalue2 +
                                  //           this.state.productservicetotal
                                  //   ) &&
                                  //   this.onDebChange(values)
                                  // : ccountry === "India" &&
                                  //   jtype === 3 &&
                                  //   gsttype === 2
                                  // ? setFieldValue(
                                  //     "debitval",
                                  //     this.state.addlamounttot !== ""
                                  //       ? this.state.addlamounttot +
                                  //           values.itaxvalue1 +
                                  //           values.creditvalue +
                                  //           values.sdebitval
                                  //       : values.itaxvalue1 +
                                  //           values.creditvalue +
                                  //           values.sdebitval
                                  //   ) &&
                                  //   setFieldValue(
                                  //     "debtotal",
                                  //     this.state.addlamounttot !== ""
                                  //       ? this.state.addlamounttot +
                                  //           values.itaxvalue1 +
                                  //           values.creditvalue +
                                  //           values.sdebitval
                                  //       : values.itaxvalue1 +
                                  //           values.creditvalue +
                                  //           values.sdebitval
                                  //   )
                                  // : ccountry !== "India" && jtype === 3
                                  // ? setFieldValue(
                                  //     "debitval",
                                  //     this.state.addlamounttot !== ""
                                  //       ? this.state.addlamounttot +
                                  //           values.gtaxvalue1 +
                                  //           values.creditvalue
                                  //       : values.gtaxvalue1 + values.creditvalue
                                  //   ) &&
                                  //   setFieldValue(
                                  //     "debtotal",
                                  //     this.state.addlamounttot !== ""
                                  //       ? this.state.addlamounttot +
                                  //           values.gtaxvalue1 +
                                  //           values.creditvalue
                                  //       : values.gtaxvalue1 + values.creditvalue
                                  //   )
                                  // : this.state.invchecked === 1
                                  // ? setFieldValue(
                                  //     "debitval",
                                  //     values.creditvalue
                                  //   )
                                  : ""}
                              </>
                            )}
                            variant="standard"
                            fullWidth
                          />
                        )}
                      />
                    </fieldset>
                    <fieldset className="form-group col-md-6 mt-0 mb-0 test-sm">
                      <FormikTextField
                        name="debitval"
                        className="form-control"
                        label="Value"
                        type="text"
                        onBlur={() =>
                            setFieldValue("debtotal", values.debitval)
                        }
                        variant="standard"
                      ></FormikTextField>
                    </fieldset>
                  </div>
                  {/* {this.state.invservicename === undefined ? (
                    <>
                      <fieldset className="form-group col-md-6 mt-2 mb-2 test-sm">
                        <Autocomplete
                          id="invitem"
                          className="invitem-select"
                          name="invitem"
                          options={invitemdata.sort(
                            (a, b) => -a.subname.localeCompare(b.subname)
                          )}
                          groupBy={(option) => option.subname}
                          getOptionLabel={(option) => option.name}
                          value={values.expense ? undefined : undefined}
                          onChange={(e, value) => {
                            setFieldValue("invitem", value);
                          }}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              type="text"
                              name="invitem"
                              label="Map Inventory Ledger"
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                    </>
                  ) : (
                    ""
                  )} */}
                  <div className="form-row">
                    <fieldset className="form-group col-md-12 mt-3 mb-3 ">
                      <FormikTextField
                        name="narration"
                        className="form-control"
                        multiline
                        rows={2}
                        label="Narration"
                        variant="outlined"
                      />
                    </fieldset>
                    <fieldset className="form-group col-md-12 mt-5">
                      <button
                        className="btn hovbuttonColour btn-sm"
                        type="submit"
                      >
                        {this.state.loader === true ? (
                          <>
                            <Spinner animation="border" size="sm" />
                            &nbsp;{"Submitting"}
                          </>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </fieldset>
                  </div>
                </>
              ) : (
                ""
              )}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default withRouter(JournalDirectSales);
