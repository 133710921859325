import React, { Component } from 'react'
import { Formik, Form, FastField } from "formik";
import { Link } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import { currentTenant, currentUser, thousandsdisplay, userid } from '../Common/Credentials';
import {Autocomplete, Backdrop, CircularProgress, createTheme, TableCell, TableContainer, TableRow, TextField, ThemeProvider,TableBody, TableHead ,Table, FormControlLabel, Switch} from '@mui/material';
import moment from 'moment';
import NewJournalService from './NewJournalService';
import AuthenticationService from '../Authentication/AuthenticationService';
import { Button, Spinner } from 'react-bootstrap';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import MUIDataTable from 'mui-datatables';
import { spin } from '../Common/NewLoader';
import Footer from '../Component/Footer';
import withRouter from '../withRouter';
import Swal from "sweetalert2";
import { FormikTextField } from '../CommonComponents/FormField';

let actualData = [];

const getTotal = (totamt) => {
  let total = 0;
  totamt.forEach(totamt => {
    total += totamt;
  });
  return total;
};


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});
let ppaymennts,val=[]
class AccountsPayable extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
          pendingPayments:[],
          salpendingData:[],
          pendingSalaries:[],
          paidSalaries:[],
          pendingPf:[],
          pendingPt:[],
          totalcombdata:[],
          partyadvances:[],
          actualData:[],
          totalval:'',pftot:'',pttot:'',insuranceTot:'',
          pendingInsurance:[],
            loader:false,            
            loader1:true, ppayments:[],genppayments:[],
            selectbtn:[
              {id:1,name:"PO Payments"},
              {id:2,name:"Non PO Payments"},
              {id:3,name:"Salary Payments"},
              {id:4,name:"Party Advances"}],
             paymentbtn:[
                {id:1,name:"Salaries"},
                {id:2,name:"Providend Fund"},
                {id:3,name:"Insurance"},
                {id:4,name:"Professional Tax"}],
              selectedpmtoption:'',
              selectedoption:''
          }
        this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
       this.onSalChange=this.onSalChange.bind(this);this.onUpdateVal=this.onUpdateVal.bind(this);
     //   this.removeRecord=this.removeRecord.bind(this);
        // this.CheckBoxSelect=this.CheckBoxSelect.bind(this);this.handleAdvpaid=this.handleAdvpaid.bind(this)
        this.loadData=this.loadData.bind(this);this.addData=this.addData.bind(this)
    }

    onSubmit(values){



      // console.log(values)
      this.setState({loader:true})

      let pendings, pmtledgerid,pmtledgername
      if(this.state.selectedpmtoption===1){
       // if(this.state.actualData!=undefined && this.state.actualData.length!==0 ){
      pendings = this.state.actualData
       // }else{
      //pendings = this.state.pendingSalaries
       // }
      }else if(this.state.selectedpmtoption===2){
        pendings = this.state.pendingPf
      }else if(this.state.selectedpmtoption===3){
        pendings = this.state.pendingInsurance
      }else if(this.state.selectedpmtoption===4){
        pendings = this.state.pendingPt
      }

      if(values.credit!==undefined){
        pmtledgerid=values.credit.id
        pmtledgername=values.credit.name
      }

       let jv ={
        pendingval:pendings,
        pmtledgerid:pmtledgerid,
        pmtledgername:pmtledgername,
        tenantid:currentTenant,
        createdBy:currentUser,
        amount:values.creditvalue,
        creth:values.credit.th,
        paymentref:values.paymentref,
        type:this.state.selectedpmtoption
       }
      
       if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
  
      }else  if(!values.creditvalue){
        alert("Please select records")
        this.setState({loader:false})
       }
      else {
       // console.log(jv)
      this.props.navigate({ pathname: `/salconfirm` }, { state: jv });
      }
     
    }

    componentDidMount(){
      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }else{ 
        this.loadData()
      }
      
     
       }

      

    loadData(){
      NewJournalService.getAllLedgers(currentTenant)
      .then(response=>{
        this.setState({totalcombdata:response.data})
       })
    
      NewJournalService.getSalaryPendings(currentTenant)
      .then(response =>{
        this.setState({salpendingData:response.data})  
      }) 
      NewJournalService.getAllCustAdvances(currentTenant)
      .then(response =>{
        this.setState({partyadvances:response.data})  
      })
      NewJournalService.getPendingPayments(currentTenant)
      .then(response =>{
        this.setState({pendingPayments:response.data},()=>this.addData())  
      })  
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })

    }

    onChange(option){
      this.setState({selectedoption: option.id});
    }

    onSalChange(option){
      this.setState({selectedpmtoption: option.id});
      let salval
          if(option.id===1 && this.state.salpendingData.salary!==null){
           salval =this.state.salpendingData.salary.map((e)=>e.amount) 
          //  if(this.state.actualData!=undefined && this.state.actualData.length!==0 ){
          //   this.setState({totalval:parseFloat(getTotal(this.state.actualData.map(e=>e.amount))).toFixed(2)})
          //  }else{
          //  this.setState({totalval:parseFloat(getTotal(salval)).toFixed(2)})
          //  }
          // console.log(this.state.actualData)
           //console.log(this.state.actualData[6])

          }else if(option.id===2 && this.state.salpendingData.pf!==null){
          //  salval =this.state.salpendingData.pf.map((e)=>e.amount)
          //  this.setState({totalval:getTotal(salval)})
          }else if(option.id===3 && this.state.salpendingData.insurance!==null){
          //  salval =this.state.salpendingData.insurance.map((e)=>e.amount)
          //  this.setState({totalval:getTotal(salval)})
          }else if(option.id===4 && this.state.salpendingData.pt!==undefined){
          //  salval =this.state.salpendingData.pt.map((e)=>e.amount)
          //  this.setState({totalval:getTotal(salval)})
          }
    }

    onUpdateVal(rowState){
     // console.log(rowState)

        let totval
        if(this.state.selectedpmtoption===1){
          if(this.state.salpendingData.salary!==null){
            let totval1=this.state.salpendingData.salary.filter(e=>e.amount!==0)
           rowState.map(f=>
            {return totval=totval1.filter((e,i)=>i===f.index)})
           if(totval!==undefined && totval.length!==0){
           totval.map(f=>
          this.state.actualData.filter((e)=>e===null && e.id!==f.id))?this.state.actualData.push(totval.find(n=>n))
          :this.state.actualData.reduce(totval.find(m=>m))
          this.setState({totalval:0})
          this.setState({totalval:parseFloat(getTotal(this.state.actualData.map(e=>e.amount))).toFixed(2)})
           }else{
            this.setState({actualData:[]})
            this.setState({totalval:getTotal([])})
          }
          }
        }else if (this.state.selectedpmtoption===2){
          if(this.state.salpendingData.pf!==null){
            let totval1=this.state.salpendingData.pf.filter(e=>e.amount!==0)
            rowState.map(f=>
             {return totval=totval1.filter((e,i)=>i===f.index)})
            if(totval!==undefined && totval.length!==0 ){
            totval.map(f=>
           this.state.actualData.filter((e,i)=>e===null && i===f.index))?this.state.actualData.push(totval.find(n=>n))
           : this.state.actualData.push(totval.find(m=>m))
           this.setState({totalval:0})
           this.setState({totalval:parseFloat(getTotal(this.state.actualData.map(e=>e.amount))).toFixed(2)})
            }else{
             this.setState({actualData:[]})
             this.setState({totalval:getTotal([])})
           }
           }
        }else if (this.state.selectedpmtoption===3){
          if(this.state.salpendingData.insurance!==null){
            let totval1=this.state.salpendingData.insurance.filter(e=>e.amount!==0)
            rowState.map(f=>
             {return totval=totval1.filter((e,i)=>i===f.index)})
            if(totval!==undefined && totval.length!==0){
            totval.map(f=>
           this.state.actualData.filter((e)=>e===null && e.id!==f.id))?this.state.actualData.push(totval.find(n=>n))
           :this.state.actualData.reduce(totval.find(m=>m))
           this.setState({totalval:0})
           this.setState({totalval:parseFloat(getTotal(this.state.actualData.map(e=>e.amount))).toFixed(2)})
            }else{
             this.setState({actualData:[]})
             this.setState({totalval:getTotal([])})
           }
           }
        }else if (this.state.selectedpmtoption===4){
          if(this.state.salpendingData.pt!==null){
            let totval1=this.state.salpendingData.pt.filter(e=>e.amount!==0)
            rowState.map(f=>
             {return totval=totval1.filter((e,i)=>i===f.index)})
            if(totval!==undefined && totval.length!==0){
            totval.map(f=>
           this.state.actualData.filter((e)=>e===null && e.id!==f.id))?this.state.actualData.push(totval.find(n=>n))
           :this.state.actualData.reduce(totval.find(m=>m))
           this.setState({totalval:0})
           this.setState({totalval:parseFloat(getTotal(this.state.actualData.map(e=>e.amount))).toFixed(2)})
            }else{
             this.setState({actualData:[]})
             this.setState({totalval:getTotal([])})
           }
           }
        }
    }

    addData(){
      this.setState({pendingSalaries:this.state.salpendingData.salary!==undefined?this.state.salpendingData.salary.filter(e=>e.amount>0):"",pendingPf:this.state.salpendingData.pf!==undefined?this.state.salpendingData.pf.filter(e=>e.amount>0):"",
        pendingInsurance:this.state.salpendingData.insurance!==undefined?this.state.salpendingData.insurance.filter(e=>e.amount>0):"",pendingPt:this.state.salpendingData.pt!==undefined?this.state.salpendingData.pt.filter(e=>e.amount>0):""})

      
       
      this.setState({ppayments:this.state.pendingPayments.filter((e)=>e.poid!==null && e.transpendingamount!==0).sort((a,b)=> a.createdAt<b.createdAt ? 1:-1),
        genppayments:this.state.pendingPayments.filter((e)=>e.poid===null && e.transpendingamount!==0).sort((a,b)=> a.createdAt<b.createdAt ? 1:-1)},()=>this.setState({loader1:false}))
      }
    
        validate(values){
      
     }

  
    
     submitpnpRecord(value){

      let pendingpo
      if(value!==undefined){
      pendingpo=this.state.pendingPayments.filter(e=>e.id===value)
      } 
      this.props.navigate({pathname:`/ape`},{state:pendingpo});
     }

     submitadvRecord(value){

      let pendingpo
      if(value!==undefined){
      pendingpo=this.state.partyadvances.filter(e=>e.id===value)
      pendingpo[0].selectedoption=this.state.selectedoption
      } 
      this.props.navigate({pathname:`/ape`},{state:pendingpo});
     }

     loaderFunction(){
      let a = this.state.loader
     }

     onClickData(values){
     }
     submitRecordClose(value){
      Swal.fire({
        title: "Information",
        text: "This action is for final close of differences and not for regular transactions.Do you want to Continue ?",
        icon: "info",
        confirmButtonText: "Ok",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonColor: "#152f5f",
      }).then((result) => {
        if (result.value === true) {
          let pendingpo,frompath
          if(value!==undefined){
          pendingpo=this.state.pendingPayments.filter(e=>e.id===value)
          pendingpo=pendingpo.map((e)=> Object.assign(e,{frompath:this.props.location.pathname}))
          
         // pendingpo.concat(frompath=this.props.location.pathname)
          } 
          this.props.navigate({pathname:`/settlement`},{state:pendingpo});
          }else{
          this.setState({loader:false})
         // this.goBack()
          }
        })
   
     }

     submitRecord(value){
      let pendingpo
      if(value!==undefined){
      pendingpo=this.state.pendingPayments.filter(e=>e.id===value)
      } 
      this.props.navigate({pathname:`/ape`},{state:pendingpo});
     }
    
    render() {
 
      let credvalue,cred1
      if(this.state.totalcombdata.length!==0){
        val=this.state.totalcombdata 
      }
      cred1=val.filter((e) => e)
      credvalue=cred1.filter((e)=>e.shid===100004 || e.shid===100003 || e.shid===100013 || e.shid===100014)
      const pendingpmts = [
        {name: 'id',label: 'Id',options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "supname",label: "Supplier",options: {filter: true,sort: true}},
        {name: "custompoid",label: "PO ID",options: {filter: false,sort: true,}},
        {name: "createdAt",label: "Date",options: {filter: false,sort: true,sortOrder: 'asc',
          customBodyRender:(tableMeta)=>{
            return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
          }}},
          {name: "poamount",label:"PO Amt",options: {filter: false,sort: true,
            customBodyRender: (value) => {
              return (
                <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
                disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
              );
            },
            setCellProps: () => ({ style: { textAlign:'right'}}),
          }},
          // {name: "transpendingamount",label:"Invoice Amt",options: {filter: false,sort: true,
          //   customBodyRender: (value) => {
          //     return (
          //       <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
          // disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
          //     );
          //   },
          //   setCellProps: () => ({ style: { textAlign:'right'}}),
          // }},
          {name: "poadvance",label: "Type",options: {filter: false,sort: false,empty: true,
              customBodyRender: (value) => {
           return (
          value!==0 ?"Purchase Advance":"Purchase"
                )},
              setCellProps: () => ({ style: { textAlign: "center" } } ),
            }},
      {name: "poadvance",label: "Adv Pending",
            options: {filter: false,sort: false,empty: true,
             
              customBodyRender: (value) => {
        return (
          value!==0 ?
          <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
          disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>:0
         );},
              setCellProps: () => ({ style: { textAlign: "right" } } ),
            }},
      {name: "advancepaid",label: "Adv Paid",
            options: {filter: false,sort: false,empty: true,
              customBodyRender: (value) => {
        return (
          value!==0 ? 
          <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
          disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>:0
          );},
              setCellProps: () => ({ style: { textAlign: "right" } } ),
            }},
      {name: "transpendingamount",label: "Tranaction Pending",options: {filter: false,sort: false,empty: true,
              customBodyRender: (value) => {
                return (
                  <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
                  disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
                );
              },
              setCellProps: () => ({ style: { textAlign:'right'}}),
            }},
      {name: "poadvance",label: "Adv-Action",options: {filter: false,sort: false,empty: true,
              customBodyRender: (value,tableMeta) => {
        return (
          value!==0 ?<button className="hovbuttonColour btn btn-sm"
          onClick={() => this.submitRecord(tableMeta.rowData[0])}>  {this.loaderFunction.a===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"pay"}
          </button>:<span className="text-red text-bold">No Pending</span>
                );},
              setCellProps: () => ({ style: { textAlign: "center" } } ),
            }},
      
            
      
      {name: "pitems",label: "Action",options: {filter: false,sort: false,display:true,viewColumns:false,
        
              customBodyRender:(value,tableMeta)=>{
                return (
                  value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
                  onClick={() => this.submitRecord(tableMeta.rowData[0])}>  {this.loaderFunction.a===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"pay"}
                  </button>:<span className="text-red text-bold">No Invoices</span>
                );      
      
              }
            
            }},

        

            {name: "savailed",label: "Serv-Action",options: {filter: false,sort: false,display:true,viewColumns:false,
        
              customBodyRender:(value,tableMeta)=>{
                return (
                  value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
                  onClick={() => this.submitRecord(tableMeta.rowData[0])}>  {this.loaderFunction.a===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"pay"}
                  </button>:<span className="text-red text-bold">No Invoices</span>
                );      
      
              }
            
            }},
            {name: "pitems",label: "Close",options: {filter: false,sort: false,display:true,viewColumns:false,
        
              customBodyRender:(value,tableMeta)=>{
                return (
                  value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
                  onClick={() => this.submitRecordClose(tableMeta.rowData[0])}>  {this.loaderFunction.a===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Settle"}
                  </button>:<span className="text-red text-bold">No Invoices</span>
                );      
      
              }
            
            }},
      
      ]
      const ppoptions = {
        filterType: 'checkbox',
        selectableRows: "none",
       // responsive: "scroll",
        
      }
      
      const pendingnonpmts = [
        {name: "id",label:"PMT ID" ,options: {filter: false,sort: false}},
        {name: "supname",label:"Pending Ledger",options: {filter: true,sort: true}},
        {name: "paymentfor",label:"Expense For",options: {filter: false,sort: true,viewColumns:false}},
        {name: "createdAt",label:"Date",options: {filter: false,sort: true,sortOrder: 'asc',
          customBodyRender:(tableMeta)=>{
            return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
          }}},
          {name: "transpendingamount",label:"Amount",options: {filter: false,sort: true,
            customBodyRender: (value) => {
              return (
                
                <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
                disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
              );
            },
            setCellProps: () => ({ style: { textAlign:'right'}}),
          }},
         
      
          {name: "id",label:"Action",options: {filter: false,sort: false,display:true,viewColumns:false,
        
            customBodyRender:(value,tableMeta)=>{
              return (
                value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
                onClick={() => this.submitpnpRecord(tableMeta.rowData[0])}>  {this.loaderFunction.a===false? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"pay"}
                </button>:<span className="text-red text-bold">No Invoices</span>
              );      
      
            }
          
          }},
      ]
      const pnpoptions = {
        filterType: 'checkbox',
        selectableRows: "none",
        // responsive: "scroll",
        resizableColumns: true,
        
      }



      const  partyadvances1  = [
        {name: "createdAt",label:"Date",options: {filter: false,sort: true,sortOrder: 'asc',
          customBodyRender:(tableMeta)=>{
            return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
          }}},
        {name: "id",label:"Ledger ID" ,options: {filter: false,sort: false,hidden:true}},
        {name: "name",label:"Party",options: {filter: true,sort: true}},
        // {name: "paymentfor",label:"Expense For",options: {filter: false,sort: true,viewColumns:false}},
          {name: "genadvancecurrent",label:"Pending Advance",options: {filter: false,sort: true,
            customBodyRender: (value) => {
              return (
                
                <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
                disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
              );
            },
            setCellProps: () => ({ style: { textAlign:'right'}}),
          }},
         
      
          {name: "id",label:"Action",options: {filter: false,sort: false,display:true,viewColumns:false,
        
            customBodyRender:(value,tableMeta)=>{
              return (
                value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
                onClick={() => this.submitadvRecord(tableMeta.rowData[1])}>  {this.loaderFunction.a===false? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"pay"}
                </button>:<span className="text-red text-bold">No Invoices</span>
              );      
      
            }
          
          }},
      ]
      const advoptions = {
        filterType: 'checkbox',
        selectableRows: "none",
        // responsive: "scroll",
        resizableColumns: true,
        
      }


      const pendingSal = [
         {name: "id",label:"PMT ID" ,options: {filter: false,sort: false}},
         {name: "empname",label:"Employee",options: {filter: true,sort: true}},
        {name: "empname",label:"To Ledger",options: {filter: true,sort: true}},
        {name: "expledname",label:"Exp Ledger",options: {filter: true,sort: true}},
        {name: "month",label: "For Month",options: {filter: true,sort: true,
          customBodyRender:(tableMeta)=>{
            return(moment(tableMeta,'M').format('MMMM'))
        }}},
        {name: "year",label: "Year",options: {filter: false,sort: false, }},
        {name: "amount",label:"Amount",options: {filter: false,sort: true,
            customBodyRender: (value) => {
              return (
                <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
                disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
              );
            },
            setCellProps: () => ({ style: { textAlign:'right'}}),
          }},
      ]
      const pendingOthers = [
        {name: "id",label:"PMT ID" ,options: {filter: false,sort: false}},
        {name: "empname",label:"Employee",options: {filter: true,sort: true}},
       {name: "name",label:"To Ledger",options: {filter: true,sort: true}},
       {name: "expledname",label:"Exp Ledger",options: {filter: true,sort: true}},
       {name: "month",label: "For Month",options: {filter: true,sort: true,
         customBodyRender:(tableMeta)=>{
          
           return(moment(!tableMeta?"":tableMeta,'M').format('MMMM'))
       }}},
       {name: "year",label: "Year",options: {filter: false,sort: false, }},
       {name: "amount",label:"Amount",options: {filter: false,sort: true,
           customBodyRender: (value) => {
             return (
               <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
               disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
             );
           },
           setCellProps: () => ({ style: { textAlign:'right'}}),
         }},
        //  {
        //   name: "Active",
        //   options: {
        //     filter: true,
        //     customBodyRender: (value, tableMeta, updateValue) => {
        //       return (
        //         <FormControlLabel
        //           label={value ? "Yes" : "No"}
        //           value={value ? "Yes" : "No"}
        //           control={
        //             <Switch
        //               color="primary"
        //               checked={value}
        //               value={value ? "Yes" : "No"}
        //             />
        //           }
        //           onChange={event => {
        //             updateValue(event.target.value === "Yes" ? false : true);
        //           }}
        //         />
        //       );
        //     }
        //   }
        // }
 
     ]
      const saloptions = {
       
        filterType: 'checkbox',
        //selectableRows: "nome",
        selectableRows: "multiple",
       // disableSelectionOnClick:true,
        // onRowClick: onTableChange,
        // selectableRowsHeader: this.state.actualData > 0,
                // responsive: "scroll",
        resizableColumns: true,
       
       
        onRowSelectionChange: (rowData, rowState) => {
          this.onUpdateVal(rowState);

        //console.log(rowState);
        }
        // isRowSelectable: (dataIndex, selectedRows) => {
        //   if (selectedRows.data.length >= selectRowMax &&
        //     selectedRows.data.filter((d) => d.dataIndex === dataIndex).length === 0)
        //      return false;
        //    return true;}
      //   onRowSelectionChange: (action, dataObj) => {
      //     if (dataObj.selectedRows.data.length > 0) {
      //       var selectedRowIndices = Object.keys(dataObj.selectedRows.lookup);
      //       selectedRowIndices.map(value => {
      //           this.state.actualData.push(dataObj.data[value].data);
      //        });
      //      } else {
      //       console.log("No rows selected");
      //     }
      //  }
      }

        return (
         <>
          <Header />
<Menu/>
            <div>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className="content-wrapper">

<div className="card card-info">
  <ol className="breadcrumb float-sm-right">
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/journals'}} state= {{message:''}} >
                Accounts Payable
              </Link>
    </li>
  </ol>

  <div className="text-center text-danger">{this.state.error}</div>
  <div className="card-body">
  <div className="text-center">
  {/* {this.state.loading===true?spin:''} */}
  </div>
              
        
    <Formik
      initialValues={{ ppaymennts}}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({values,setFieldValue}) => (

        <Form autoComplete="off">


{this.state.selectbtn.map((option)=>{
return <label key={option.id} >

    <div className="text-sm col-md-12">
      <div className="form-group col-md-12">
      {/* <div className="form-control bg-light border-light"> */}

             <input 
                className={
                  this.state.selectedoption === option.id
                    ? "btn buttonColour text-yellow text-bold col-md-12"
                    : "btn hovbuttonColour text-white col-md-12"
                }
                type="button" 
                // checked={this.state.checked === option.id ? true : false} 
                key={option.id}
                onClick={this.onChange.bind(this,option)} 
               style={{marginRight: 4,width:250}}
                value={option.name} 
                />
            {/* {option.name} */}
        
            </div></div>
            {/* </div> */}
        </label>
       

})
}

{this.state.selectedoption===1?
<div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"PO Payments"}
                            data={this.state.ppayments}
                            columns={pendingpmts}
                            options={ppoptions}
                          />
                        </ThemeProvider>
                      </div>
                    :this.state.selectedoption===2?
 <div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"NON PO Payments"}
                            data={this.state.genppayments}
                            columns={pendingnonpmts}
                            options={pnpoptions}
                          />
                        </ThemeProvider>
                      </div>
                      :this.state.selectedoption===3?<div>
{this.state.paymentbtn.map((option)=>{
return <label key={option.id} >

    <div className="text-sm col-md-12">
      <div className="form-group col-md-12">
      {/* <div className="form-control bg-light border-light"> */}

             <input 
                className={
                  this.state.selectedpmtoption === option.id
                    ? "btn buttonColour text-yellow text-bold col-md-12"
                    : "btn hovbuttonColour text-white col-md-12"
                }
                type="button" 
                // checked={this.state.checked === option.id ? true : false} 
                key={option.id}
                onClick={this.onSalChange.bind(this,option)} 
               style={{marginRight: 4,width:250}}
                value={option.name} 
                />
            {/* {option.name} */}
        
            </div></div>
            {/* </div> */}
        </label>
       

})
} </div>:this.state.selectedoption===4?
 <div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`Party Direct Advances`}
                            data={this.state.partyadvances}
                            columns={partyadvances1}
                            options={advoptions}
                          />
                        </ThemeProvider>
                      </div>:""}
{this.state.selectedoption===3?<>
{this.state.selectedpmtoption===1?


<div className="form-row mb-12">
<fieldset className="form-group col-md-12 mt-0 mb-0">
<ThemeProvider theme={theme}>
    <MUIDataTable
      className="table-sm px-3"
      title={"Salary Payments"}
      data={this.state.pendingSalaries}
      columns={pendingSal}
      options={saloptions}
    />
  </ThemeProvider>
</fieldset>

 <fieldset className="form-group col-md-4 mt-2 mb-2">
 <FastField name="paymentref" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/>)}
  </FastField></fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2"> 
<FastField name="credit" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" 
options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.th==="TH3"?option.subname:<span className='text-red'>{option.subname}</span>}
 isOptionEqualToValue={(option) => !option.value}
  getOptionLabel={(option) => option.name+"("+option.amount+")"} 
  value={values.credit?undefined:undefined} onChange={(e, value) => 
    setFieldValue("credit",value)}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" variant="standard"
   onBlur={()=>setFieldValue("creditvalue",this.state.totalval) 
  }
   fullWidth />)}/>
   )}
  </FastField>
</fieldset>
{/* <FastField name="paymentref" validate={(value) => value === null || value === undefined || value === "" || value === 0
        ? "Please Enter Value" : undefined } >
                    {({ meta }) => ( */}
<fieldset  className="form-group col-md-4 mt-2 mb-2" disabled>
  <FormikTextField name="creditvalue"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.creditvalue?" ":"Value"}  type="number" 
  variant="standard"> </FormikTextField></fieldset>
                    {/* )}
</FastField> */}
  <fieldset className="form-group col-md-12 mt-5">
<button className="btn hovbuttonColour btn-sm"  type="submit">
{/* onClicl={this.onClickData(values)} */}
  {this.state.loader === true ? 
    <>
      <Spinner animation="border" size="sm" /> &nbsp;{"Submitting"}
    </>:"Submit"}
</button>
</fieldset>
</div>:this.state.selectedpmtoption===2?
<div className="form-row mb-12">
<fieldset className="form-group col-md-12 mt-0 mb-0">
<ThemeProvider theme={theme}>
    <MUIDataTable
      className="table-sm px-3"
      title={"PF Payments"}
      data={this.state.pendingPf}
      columns={pendingOthers}
      options={saloptions}
      RowSelectionOnClick
    />
  </ThemeProvider>
  </fieldset>
  <fieldset className="form-group col-md-4 mt-2 mb-2">
 <FastField name="paymentref" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/>)}
  </FastField></fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2"> 
<FastField name="credit" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" 
options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.th==="TH3"?option.subname:<span className='text-red'>{option.subname}</span>}
 isOptionEqualToValue={(option) => !option.value}
  getOptionLabel={(option) => option.name+"("+option.amount+")"} 
  value={values.credit?undefined:undefined} onChange={(e, value) => 
    setFieldValue("credit",value)}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" variant="standard"
   onBlur={()=>setFieldValue("creditvalue",this.state.totalval)}
   fullWidth />)}/>
   )}
  </FastField>
</fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2" disabled>
  <FormikTextField name="creditvalue"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.creditvalue?" ":"Value"}  type="number" 
  variant="standard"> </FormikTextField></fieldset>


  <fieldset className="form-group col-md-12 mt-5">
<button className="btn hovbuttonColour btn-sm"  type="submit">
{/* onClicl={this.onClickData(values)} */}
  {this.state.loader === true ? 
    <>
      <Spinner animation="border" size="sm" /> &nbsp;{"Submitting"}
    </>:"Submit"}
</button>
</fieldset>
</div>:this.state.selectedpmtoption===3?
<div className="form-row mb-12">
<fieldset className="form-group col-md-12 mt-0 mb-0">
  <ThemeProvider theme={theme}>
    <MUIDataTable
      className="table-sm px-3"
      title={"Insurance Payments"}
      data={this.state.pendingInsurance}
      columns={pendingOthers}
      options={saloptions}
    />
  </ThemeProvider>
  </fieldset>
  <fieldset className="form-group col-md-4 mt-2 mb-2">
 <FastField name="paymentref" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/>)}
  </FastField></fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2"> 
<FastField name="credit" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" 
options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.th==="TH3"?option.subname:<span className='text-red'>{option.subname}</span>}
 isOptionEqualToValue={(option) => !option.value}
  getOptionLabel={(option) => option.name+"("+option.amount+")"} 
  value={values.credit?undefined:undefined} onChange={(e, value) => 
    setFieldValue("credit",value)}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" variant="standard"
   onBlur={()=>setFieldValue("creditvalue",this.state.totalval)}
   fullWidth />)}/>
   )}
  </FastField>
</fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2" disabled>

  <FormikTextField name="creditvalue"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.creditvalue?" ":"Value"}  type="number" 
  variant="standard"> </FormikTextField>
  </fieldset>


  <fieldset className="form-group col-md-12 mt-5">
<button className="btn hovbuttonColour btn-sm" type="submit"> 
{/* onCliclk={this.onClickData(values)}  */}
  {this.state.loader === true ? 
    <>
      <Spinner animation="border" size="sm" /> &nbsp;{"Submitting"}
    </>:"Submit"}
</button>
</fieldset>
</div>:this.state.selectedpmtoption===4 &&  this.state.pendingPt.length!==0 ?
<div className="form-row mb-12">
<fieldset className="form-group col-md-12 mt-0 mb-0">
  <ThemeProvider theme={theme}>
    <MUIDataTable
      className="table-sm px-3"
      title={"PT Payments"}
      data={this.state.pendingPt}
      columns={pendingOthers}
      options={saloptions}
    />
  </ThemeProvider>
  </fieldset>
  <fieldset className="form-group col-md-4 mt-2 mb-2">
 <FastField name="paymentref" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
  <FormikTextField name="paymentref" className="form-control" label="Payment Ref No"
  variant="standard"/>)}
  </FastField></fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2"> 
<FastField name="credit" validate={(value) => value === null || value === undefined || value === "" 
        ? "Please Enter Reference Value" : undefined } >
                    {({ meta }) => (
<Autocomplete id="credit" className="credit-select text-sm" name="acredit" 
options={credvalue.sort((a, b) => -a.subname.localeCompare(b.subname))}
 groupBy={(option) => option.th==="TH3"?option.subname:<span className='text-red'>{option.subname}</span>}
 isOptionEqualToValue={(option) => !option.value}
  getOptionLabel={(option) => option.name+"("+option.amount+")"} 
  value={values.credit?undefined:undefined} onChange={(e, value) => 
    setFieldValue("credit",value)}
 renderInput={params => (
 <FormikTextField {...params}  type="text" name="credit" className="text-sm" label="Credit" variant="standard"
   onBlur={()=>setFieldValue("creditvalue",this.state.totalval)}
   fullWidth />)}/>
   )}
  </FastField>
</fieldset>
<fieldset  className="form-group col-md-4 mt-2 mb-2" disabled>
  <FormikTextField name="creditvalue"  className="form-control text-sm sm mt-0 mb-0" 
  label={values.creditvalue?" ":"Value"}  type="number" 
  variant="standard"> </FormikTextField></fieldset>


  <fieldset className="form-group col-md-12 mt-5">
<button className="btn hovbuttonColour btn-sm"  type="submit">
  {this.state.loader === true ? 
    <>
      <Spinner animation="border" size="sm" /> &nbsp;{"Submitting"}
    </>:"Submit"}
</button>
</fieldset>
</div>
:""}
</>:""}


                    
</Form>)} 
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     
 </div>
 </>
)
  }
}

export default withRouter(AccountsPayable);              



 
