import axios from "axios";
import { Auth_Proto, Auth_Domain, Auth_Port } from "../Component/consts";

class EmployeeService {

    // getEmployee() {
    //     return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/all`);
    // }

    // getEmployeedata(empid) {
    //     return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getEmp/${empid}`)
    // }
    getAllSalaries(currentTenant) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallsalaries/${currentTenant}`);
    }

    getforByid(ii) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getbyid/${ii}`);
    }

    SaveEmployeeSalary(emp) {
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addsalaryofemp`, emp);
    }

    updateEmployee(empid, emp) {
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateEmp/${empid}`, emp)
    }



    //----------------------------Salary Breakup----------------------------

    AddSalaryBreakUp(data) {
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addsalarybreakup`, data)
    }

    getAllSalaryBrkup(currentTenant){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallsalarybrkups/${currentTenant}`);
    }


// ----------------------------------Salary Progress----------------------------------------------
    getAllSalProgress(currentTenant)
    {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/salaryProgress/${currentTenant}`);
    }
    addingSalaryProgress(data)
    {
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/newsalaryProgress`,data);
    }

    getMonthwiseSalryProgress(data)
    {
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/monthwisesalaryProgress`,data);
    }

    getallfyempsalaryoftenant(data)
    {
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallfyempsalaryoftenant`,data);
    }

    UpdateSPStatus(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatesalaryprogressstatus`,data);
    }

    UpdateSalaryProgress(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updatesalaryprogress`,data);
    }

    //--------------------------------Employee Advances -----------------------------

    GetEmployeeAdvancesoftenant(currentTenant){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallemployeeadvancesoftenant/${currentTenant}`);
    }

    getEmployeeAdvanceById(id){
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getemployeeadvancebyid/${id}`);
    }

    AddEmployeeAdvance(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addemployeeadvance`,data);
    }
    UpdateEmployeeAdvance(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateemployeeadvance`,data);
    }

    UpdateEmployeeAdvanceStatus(data){
        return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updateemployeeadvancestatus`,data);
    }

    deleteemployeeadvance(id){
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deleteemployeeadvance/${id}`);
    }
}
export default new EmployeeService();