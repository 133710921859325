
import moment from "moment";
import React, {  useState } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  clientname,
  logo,
  thousandsdisplay,
} from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import jsPDF from "jspdf";
import {NumericFormat} from "react-number-format";
import Footer from "../../Component/Footer";
import TransportationServices from "./TransportationServices";
import Swal from "sweetalert2";
import { assetmanagementid } from "../../Common/Permissions.js";

const Transportationdetails = (props) => {
  const [id] = useState(props.location.state.id);
  const [data] = useState(props.location.state);
  const [status,setStatus] = useState(props.location.state.status)

  const handlepdfDownload = () => {
    const curdate = moment().format('DD-MM-YYYY HH:mm:ss')
    let date = moment(data.createdAt).format('DD-MM-YYYY')
    let startdate = moment(data.startdate).format('DD-MM-YYYY')
    let mfdate = moment(data.manufacturedate).format('DD-MM-YYYY')

    // const toWords = new ToWords();
    var doc = new jsPDF('p', 'pt',[842, 595]);
    doc.setFontSize(10)
    doc.text(`Asset Id : ${id}`,40,130)
    doc.text(`Itemname : ${data.itemname}`,40,145)
    doc.text(`Asset Type : ${data.assettype}`,40,160)
    if(data.immoveabletype !== "NA"){ 
    doc.text(`Immoveable Type : ${data.immoveabletype}`,40,175)
    }
    if(data.moveabletype !== "NA"){ 
      doc.text(`Moveable Type : ${data.moveabletype}`,40,175)
      }
      doc.text(`Date : ${date}`,40,190)
      doc.text(`Amount : ${data.baseamount}`,40,205)
      doc.text(`Org Unit : ${data.warehouse}`,40,220)
      doc.text(`User : ${data.user}`,40,235)
      doc.text(`Location : ${data.location}`,40,250)
      doc.text(`Depreciation(%) : ${data.depreciation}%`,40,265)
      doc.text(`Depreciation Period : ${data.depreciationperiod} Years`,40,280)
      doc.text(`Purchase Date : ${startdate}`,40,295)
      doc.text(`Type : ${data.type}`,40,310)
      doc.text(`Manufacture Date : ${mfdate}`,40,325)
      doc.text(`Warranty : ${data.warranty} Months`,40,340)
      
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;

      doc.addImage(logo, "GIF", 40, 20, 60, 60, { baseline: "top" });
      doc.setFontSize(8);
      doc.text(`${curdate}`,560,20,'right')
      doc.setFontSize(16);
      doc.text(`${clientname}`, 180, 40, { baseline: "top" });
      doc.setFontSize(14);
      doc.text("Asset Details", 210, 70, { baseline: "top" });
      doc.line(0, 90, 600, 90);
      doc.setFontSize(10);

      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 15,
        { baseline: "bottom" }
      );
    }
    doc.save(`${id} Details.pdf`);
  };

const handleConfirm = () =>{
  Swal.fire({
    title: "Confirm trip?",
    text: "Are you sure you want to confirm trip?",
    icon: "question",
    showCancelButton: true,
    confirmButtonText: "Yes",
    confirmButtonColor:'#152f5f',
    cancelButtonText: "No",
    reverseButtons: true
}).then((result)=>{
    if (result.value) {
      TransportationServices.Confirmtrip(id).then
      ((response) =>{
        setStatus("Completed")
        Swal.fire({
          title: "Trip confirmed successfully",
          text: ".",
          icon: "success",
        confirmButtonText: "Ok",
        confirmButtonColor:'#152f5f',
        })
      })
      .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
    }
})
}
  // const handledownloaddoc = ()=>{
  //   downloadfiles.download(data.path, data.file);
  // }

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/transportation"}} state ={ { message: "",type:0 }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/assetmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to={{ pathname: "/transportation"}} state ={ { message: "",type:0 }}>Transportation</Link>
            </li>
            <li className="breadcrumb-item active">Trip Details</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              {data === "" ? (
                <div>{spin}</div>
              ) : (
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Trip Details</h3>
                    <>
                      <button
                        className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-envelope text-md ml-1"
                        data-tip
                        data-for="mail"
                        onClick={() => this.handleShow()}
                      ></button>
                      <button
                        className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md"
                        data-tip
                        data-for="pdf"
                        onClick={handlepdfDownload}
                      ></button>
                      {/* //<Tooltip id="mail" place="top" effect="solid">
                        Send Mail
                      </Tooltip>
                      //<Tooltip id="pdf" place="top" effect="solid">
                        Download pdf
                      </Tooltip> */}
                    </>
                  </div>
                  <div className="card-body">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                    <table className="float-left">
                      <tbody>
                      <tr>
                          <td>Trip Id</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.customid}</td>
                        </tr>
                        <tr>
                          <td>Date</td>
                          <td>
                            &nbsp;&nbsp;:&nbsp;&nbsp;{" "}
                            {moment(data.createdAt).format(
                              "DD-MM-YYYY HH:mm"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>CreatedBy</td>
                          <td>
                            &nbsp;&nbsp;:&nbsp;&nbsp;{
                            data.createdbyname}
                          </td>
                        </tr>
                        <tr>
                          <td>Vehicle</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.vehicle}</td>
                        </tr>
                        <tr>
                          <td>Origin</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.originname}</td>
                        </tr>
                        <tr>
                          <td>Destination</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.destinationname}</td>
                        </tr>
                         <tr>
                          <td>Out date</td>
                          <td>
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {moment(data.outdate).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>In date</td>
                          <td>
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {moment(data.indate).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td>Out Reading</td>
                          <td>
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {data.outreading}
                          </td>
                        </tr>
                        <tr>
                          <td>In Reading</td>
                          <td>
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {data.inreading}
                          </td>
                        </tr>
                        <tr>
                          <td>Total Kms</td>
                          <td>
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {data.totalkms}
                          </td>
                        </tr>
                        <tr>
                          <td>Diesel(in ltrs)</td>
                          <td>
                            &nbsp;&nbsp;:&nbsp;&nbsp;
                            {data.dieselinltr}
                          </td>
                        </tr>
                        <tr>
                          <td>Millage</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;{data.millage}</td>
                        </tr>
                        <tr>
                          <td>Driver 1</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp; {data.driver1name}</td>
                        </tr>
                        <tr>
                          <td>Driver 2</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp; {data.driver2name}</td>
                        </tr>
                        {data.loadingstatus === true?<>
                            <tr>
                          <td>Loading</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp; {data.loading} Kgs</td>
                        </tr>
                        <tr>
                          <td>Unloading</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp; {data.unloading} Kgs</td>
                        </tr>
                        <tr>
                          <td>Differance</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp; {data.differance} Kgs</td>
                        </tr>
                        </>:''
                        }
                        <tr>
                          <td>Type</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp; {data.type===true?"One-way trip":'Tow way trip'}</td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                    <div className="form-group col-md-6">
                    <table className="float-left">
                      <tbody>
                      <tr>
                          <td>Diesel per Ltr</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;<NumericFormat className="pr-3" displayType={'text'} value={data.dieselperltr} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                        </tr>
                        <tr>
                          <td>Diesel Amount</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;<NumericFormat className="pr-3" displayType={'text'} value={data.dieselinamt} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                        </tr>
                        <tr>
                          <td>Toll Charges</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;<NumericFormat className="pr-3" displayType={'text'} value={data.tollamt} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                        </tr>
                        <tr>
                          <td>Driver1 Wages</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;<NumericFormat className="pr-3" displayType={'text'} value={data.wages1} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                        </tr>
                        <tr>
                          <td>Driver2 Wages</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;<NumericFormat className="pr-3" displayType={'text'} value={data.wages2} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                        </tr>
                        <tr>
                          <td>Asco/Dreas</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;<NumericFormat className="pr-3" displayType={'text'} value={data.asocamt} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                        </tr>
                        <tr>
                          <td>Other Expenses</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;<NumericFormat className="pr-3" displayType={'text'} value={data.otherexpenses} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                        </tr>
                        <tr>
                          <td>Total Expenses</td>
                          <td>&nbsp;&nbsp;:&nbsp;&nbsp;<NumericFormat className="pr-3" displayType={'text'} value={data.totalexp} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></td>
                        </tr>
                       
                      </tbody>
                    </table>
                    </div>
                    <div className="from-group col-md-12">
                    {status==="Pending" && (assetmanagementid ==='3' || assetmanagementid ===3 )?
                    <button className="btn btn-sm hovbuttonColour" type="button" onClick={handleConfirm}><i className="fa fa-check"/>&nbsp; Confirm</button>
                      :''}
                      </div>
                    </div>
                  </div>
                  
                </div>
              )}
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(Transportationdetails);
