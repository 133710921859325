import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import AuthenticationService from "../Authentication/AuthenticationService";
import { spin } from "../Common/NewLoader";
import Eye from "../users/Eye";
import Swal from "sweetalert2";

class template extends Component {
  constructor(props) {
    super(props);
    const username = this.props.location.state.username;
    const clientname = this.props.location.state.clientname;
    this.state = {
      data: [],
      username: username,
      clientname:clientname,
      otp: "",
      confirmstatus: '',
      message: "",
      newpassword: "",
      confirmpassword: "",
      errmsg:'',
      loading:false,
      type:'password'
    };
    this.handleOTPVerify = this.handleOTPVerify.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }
  handleSubmit(){
    if(this.state.newpassword === this.state.confirmpassword ){
      let data = {
        id:this.state.username,
        password:this.state.newpassword
      }
      AuthenticationService.ChangePassword(data).then(()=>this.props.navigate(`/`))
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    }else{
      this.setState({errmsg:"Passwords dosen't match"})
    }
  }

  handleClick = () => this.setState(({type}) => ({
    type: type === 'text' ? 'password' : 'text'
  }))

  handleChange(e){
    this.setState({username:e.target.value})
  }

  handleOTP(e) {
    this.setState({ otp: e.target.value,confirmstatus:'' });
  }

  handleOTPVerify() {
     
    let data = {
      username:this.state.username,
      otp:parseInt(this.state.otp)
    }
    AuthenticationService.VerifyOTP(data).then((response) => {
      this.setState({ confirmstatus: response.data });
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }
  regenerateotp(){
    this.setState({loading:true})
    AuthenticationService.GenerateOTP(this.state.username).then((response) => {
      this.setState({ message: response.data,loading:false,confirmstatus:'' });
    }).catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }
  generateotp() {
    const id = this.props.location.state.username;
    console.log(id)
    if (id === "") {
      alert("Please Enter Username Or Email")
      this.props.navigate(`/`);
    }
    this.setState({loading:true})
    AuthenticationService.GenerateOTP(id).then((response) => {
      this.setState({ message: response.data,loading:false });
      if(response.data === "Please configure mail settings" || response.data === "User not active." || response.data === "User Deleted."){
        Swal.fire({
          title:response.data,
          text:"cannot reset password",
          icon:'warning'
        }).then(()=>
          this.props.navigate(`/`)
        )
      }
    });
  }
  componentDidMount() {
    if (this.props.location.state === undefined) {
      alert("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.generateotp();
  }

  render() {
    let {type} = this.state
    return (
      <div>
        <div style={{ width: 400, margin: "3% auto" }}>
          <Formik initialValues={{type}} enableReinitialize={true}>
            {() => (
              <Form autoComplete="off">
                <div className="card text-center">
                  <div className="card-body login-card-body align-center">
                    <div className="brand-text text-secondary text-center my-3">
                      <b>FINANZZ</b>
                    </div>
                    <p style={{fontSize:"20px"}} className="login-box-msg text-info brand-text">{this.state.clientname}</p>
                    <div className="brand-text text-info text-center mb-3">
                          <b>Forgot Password</b>
                        </div>
                        <div>
                    {this.state.loading === true ?<span className="text-center">{spin}</span> :<>
                      {this.state.confirmstatus === "Invalid OTP"||this.state.confirmstatus==='' ? (
                      <>
                        {this.state.message==="Invalid Username or Email"?<>
                        <p className="m-0 mb-2"><span className="text-danger mr-1">{this.state.message},</span><span>Enter a valid UserId Or Email</span></p>
                        <div className="input-group mb-3">
                          <input type="text" name="usernameOrEmail" value={this.state.username} onChange={e=>this.handleChange(e)} className="form-control" placeholder="User Id"></input>
                          <div className="input-group-append">
                                <div className="input-group-text">
                                  <span className="fas fa-user" />
                                </div>
                              </div>
                        </div>
                        <soan
                          as="button"
                          className="btn btn-sm btn-info ml-2"
                          onClick={() => this.regenerateotp()}
                        >
                          Submit
                        </soan>

                        <Link
                          as="button"
                          className="btn btn-sm bg-white shadow-sm ml-2"
                          to={{ pathname: "/" }}
                        >
                          <b className="text-danger"> X </b> Cancel
                        </Link>
                          </>
                          :<>
                            
                        <p className="m-0 mb-2">{this.state.message}</p>
                        <p className="m-0 mb-2 text-danger">{this.state.confirmstatus}</p>
                        <div className="form-row p-0 m-0">
                          <div style={{ margin: "0 auto" }}>
                            <input
                              type="text"
                              placeholder="Enter OTP"
                              className="text-center col-xs-4"
                              name="pincode"
                              minLength="4"
                              maxLength="4"
                              pattern="\d{4}"
                              onChange={(e) => this.handleOTP(e)}
                              required
                            />
              
                          </div>
                        </div>
                        <fieldset className="form-group col-md-8" />
                        <button
                          className="btn btn-sm btn-info"
                          onClick={this.handleOTPVerify}
                        >
                          Verify OTP
                        </button>
                        <span
                          as="button"
                          className="btn btn-sm btn-warning ml-2"
                          onClick={() => this.regenerateotp()}
                        >
                          Resend-OTP
                        </span>
                        <Link
                          as="button"
                          className="btn btn-sm bg-white shadow-sm ml-2"
                          to={{ pathname: "/" }}
                        >
                          <b className="text-danger"> X </b> Cancel
                        </Link>
                        </>}
                      </>
                    ) : (
                      <>
                        <div className="input-group mb-3">
                          <input
                            type={this.state.type}
                            name="newpassword"
                            value={this.state.newpassword}
                            onChange={(e) => {
                              this.setState({ newpassword: e.target.value });
                            }}
                            className="form-control"
                            placeholder="New Password"
                          ></input>
                          <div className="input-group-append">
                            <div className="input-group-text">
                              <span className="mr-2" onClick={this.handleClick}>
                                <i>
                                  <Eye />
                                </i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="input-group mb-3">
                          <input
                            type="password"
                            name="confirmpassword"
                            value={this.state.confirmpassword}
                            onChange={(e) =>
                              this.setState({ confirmpassword: e.target.value })
                            }
                            className="form-control"
                            placeholder="Confirm Password"
                          ></input>
                          <div className="input-group-append">
                            <div className="input-group-text"></div>
                          </div>
                        </div>
                        <p className="text-danger">{this.state.errmsg}</p>
                        <span as="button" className="btn btn-sm btn-info" onClick={this.handleSubmit}>Submit</span>
                        <Link
                          as="button"
                          className="btn btn-sm bg-white shadow-sm ml-2"
                          to={{ pathname: "/" }}
                        >
                          <b className="text-danger"> X </b> Cancel
                        </Link>
                      </>
                    )}
            </>}
            <div><p></p></div>
        <div><p></p></div>
        <p className="m-0 text-center" style={{fontSize:"12px"}}>Powerd by</p>
        <p className="brand-text text-secondary text-center" style={{fontSize:"14px"}}>Jnana Technologies @2021</p>

            </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default withRouter(template);
