import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

export let currentTenant

class UomService{
    getUOM(currentTenant) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getuomlist/${currentTenant}`,)
     }
     adduom(adduom){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/adduom`,adduom)
     }
     deleteUom(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deleteuom/${id}`,)
     }
     UploadUOMs(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/uploaduoms`,data)
     }
}
export default new UomService()