import { Autocomplete, CardHeader, IconButton, Skeleton, TextField, Tooltip, Typography } from "@mui/material";
import { Formik } from "formik";
// import { FormikTextField } from '../../../CommonComponents/FormField.js';
import React from "react";
import Chart from "react-apexcharts";
import { Col, Row,Form } from "react-bootstrap";
import { currency, currentTenant, financialyearend, financialyearstart } from "../../../Common/Credentials";
import PurchaseServices from "../../../Purchase/PurchseServices";
import moment from "moment";
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';

class SuppliersPurchasebyYear extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierid: "",
      supplier: "",
      SuppliersData: [],
      productslist: [],
      reportsdata: [],
      supbyproducts: [],
      supplierlist: [],
      productsbysup: [],
      topproducts: [],
      topsuppliers: [],
      purchasesbyuser: [],
      supbyusers: [],
      itemsbyusers: [],
      yearamount: [],
      yearquantity:[],
      topsuppliersbyquantity:[],
      type:"Amount",
      startyear:financialyearstart,
      endyear:financialyearend,
      loader:false,
      toggle:false
    };
  }

  handleSeller = (value,values) => {
    if(values !== '' && typeof values === 'object' && values !==null) {
      this.setState({ supplier: values });
      let supdata = this.state.reportsdata.filter((e) => e.supplier === values.companyname);
      let monthlyamount = [];
      let monthlyquantity = [];
      
      for (let k = 0; k < 12; k++) {
        let am = 0;
        let qu = 0;
        supdata.map((e) =>
          (moment(e.createdAt).format("M") === moment(financialyearstart).add(k, "month").startOf("month").format("M"))?
            am = am + e.totalprice : am = am + 0
          );
          supdata.map((e) =>
          (moment(e.createdAt).format("M") === moment(financialyearstart).add(k, "month").startOf("month").format("M"))?
            qu = qu + e.quantity : qu = qu + 0
          );
        monthlyamount.push(am);
        monthlyquantity.push(qu)
      }
      this.setState({ yearamount: monthlyamount,yearquantity:monthlyquantity });
    }else{
      this.setState({ supplier: '',yearamount:[] });
    }
  };

  componentDidMount() {
    PurchaseServices.GetAllSellers(currentTenant).then((res) =>{
      this.setState({
        SuppliersData: res.data,
      })
      this.Refresh()
  });
  }

    Refresh=()=>{
      this.setState({ loader: true,startyear: this.props.start,endyear: this.props.end, });
    let datadao = {
      tenantid: currentTenant,
      startdate: moment(this.props.start),
      enddate: moment(this.props.end),
    }
    PurchaseServices.Getallfygrndata(datadao).then((response) => {
      this.setState(
        {
          reportsdata: response.data,
          filterdata: response.data,
          
          supplier : "",
          loader: false,
        },
        () => {
          this.Supplierdata();
        }
      );
    });
  }

  Supplierdata = () => {
    this.setState({ supplierlist: [], productsbysup: [] }, () => {
      const suppliers = this.state.filterdata.reduce(
        (sup, { totalprice, supplier, supid,quantity }) => {
          if (!sup[supplier]) sup[supplier] = [];
          sup[supplier].push({
            totalprice: totalprice,
            supplier: supplier,
            supid: supid,
            quantity:quantity,
          });
          return sup;
        },
        {}
      );
      this.setState({ suppliers: suppliers });

      Object.entries(suppliers).forEach(([key, value]) => {
        let a = {
          supplier: key,
          supid: value.map((e) => e.supid)[0],
          totalprice: value.reduce((a, v) => (a = a + v.totalprice), 0),
          totalpricewtax: value.reduce((a, v) => (a = a + v.totalpricewtax), 0),
          quantity:value.reduce((a, v) => (a = a + v.quantity), 0),
        };
        if(this.state.supplierlist.filter(siuu=>siuu.supplier === a.supplier).length === 0){
          this.state.supplierlist.push(a);
        }
      });

      // Top 10 suppliers
      const topsuppliers = []
        .concat(this.state.supplierlist)
        .sort((a, b) => (a.totalprice < b.totalprice ? 1 : -1));

      this.setState({ topsuppliers: topsuppliers.slice(0, 5) });
      if (this.state.supplier === "") {
        if (topsuppliers.length !== 0) {
          let value = topsuppliers[0];
          Object.assign(value,{companyname:value.supplier})
          this.handleSeller(1,value);
        }
      }

      // Top 10 suppliers by Quantity
      const topsuppliersbyquantity = []
      .concat(this.state.supplierlist)
      .sort((a, b) => (a.quantity < b.quantity ? 1 : -1));

    this.setState({ topsuppliersbyquantity: topsuppliersbyquantity.slice(0, 5) });
    });
  };

  render() {
    let { supplierid, supplier, SuppliersData } = this.state;
    return (
      <div>
        <CardHeader
        title={<Typography>{this.state.loader?<Skeleton variant="rectangular" width="100%" height={20}/>:
        this.state.toggle ? "Top Suppliers":              
                        <Autocomplete
                        name="supplier"
                        options={SuppliersData}
                        getOptionLabel={(option) => option.companyname}
                        id="controlled-demo"
                        value={this.state.supplier}
                        onChange={this.handleSeller}
                        renderInput={(params) => (
                          <TextField {...params} placeholder={`Select Supplier`} variant="standard" size="small" />
                        )}
                      />
        }</Typography>}
        subheader=""
        action={<div className="d-flex">
         <Tooltip
                    title={
                      this.state.toggle ? "Top 5 Suppliers":"Chart view"
                    }
                    arrow
                    placement={"top"}
                    sx={{
                        '& .MuiTooltip-arrow': {
                            marginTop: '-0.65em'
                        }
                    }}
                >
                    <IconButton onClick={() => this.setState({toggle:!this.state.toggle})}>
                        <InsertChartOutlinedIcon/>
                    </IconButton>
                </Tooltip>
          </div>
        }
        // avatar={avatar}
        sx={{
          zIndex: 2,
          position: "relative",
        }}
      />
         {moment(this.state.startyear).format('yyyy') !== moment(this.props.start).format('yyyy')?this.Refresh():''}
         {this.state.loader?<Skeleton variant="rectangular" width="100%" height={200}/>:
         this.state.toggle?   
           <div id="chart" className="apexcharts-content">
         <Chart
             options={{
               labels: this.state.topsuppliers.map((e) => e.supplier),
               responsive: [
                 {
                   options: {
                     legend: {
                       position: "bottom",
                     },
                   },
                 },
               ],
               fill: {
                 type: "gradient",
                 gradient: {
                   shade: "dark",
                   type: "vertical",
                   shadeIntensity: 0.5,
                   inverseColors: false,
                   opacityFrom: 1,
                   opacityTo: 0.8,
                   stops: [0, 100],
                 },
               },
               theme: {
                 mode: this.props.theme===false?'light':'dark', 
                 palette: 'palette1', 
                 monochrome: {
                     enabled: false,
                     color: '#255aee',
                     shadeTo: this.props.theme===false?'light':'dark', 
                     shadeIntensity: 0.65
                 },
             },
               dropShadow: {
                 enabled: true,
                 opacity: 0.3,
                 blur: 5,
                 left: -7,
                 top: 22,
               },
               legend: {
                 horizontalAlign: "center",
                 position: "top",
                 offsetY: 10,
               },
             }}
             series={this.state.type==="Amount"?this.state.topsuppliers.map((e) => e.totalprice): this.state.topsuppliersbyquantity.map((e) => e.quantity)}
             type="pie"
             height={200}
           />
         </div>:
            <div id="chart" className="apexcharts-content">
            <Chart
              options={{
                chart: {
                  stacked: false,
                },
                plotOptions: {
                  bar: {
                    columnWidth: "50%",
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  width: [0, 5, 5],
                  curve: "smooth",
                },
                title: {
                  text: "",
                  align: "left",
                },
                grid: {
                  row: {
                    colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
                  },
                },
                fill: {
                  type: "gradient",
                  gradient: {
                    shade: "dark",
                    type: "vertical",
                    shadeIntensity: 0.5,
                    inverseColors: false,
                    opacityFrom: 1,
                    opacityTo: 0.8,
                    stops: [0, 100],
                  },
                },
                dropShadow: {
                  enabled: true,
                  opacity: 0.3,
                  blur: 5,
                  left: -7,
                  top: 22,
                },
                legend: {
                  show: true,
                  floating: true,
                  horizontalAlign: "left",
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                  position: "top",
                  offsetY: -33,
                  offsetX: 60,
                },
                theme: {
                  mode: this.props.theme === false ? "light" : "dark",
                  palette: "palette1",
                  monochrome: {
                    enabled: false,
                    color: "#255aee",
                    shadeTo:
                      this.props.theme === false ? "light" : "dark",
                    shadeIntensity: 0.65,
                  },
                },
                tooltip: {
                  theme: "light",
                },
                xaxis: {
                  categories: [
                    moment(financialyearstart)
                      .add(0, "month")
                      .startOf("month")
                      .format("MMM"),
                    moment(financialyearstart)
                      .add(1, "month")
                      .startOf("month")
                      .format("MMM"),
                    moment(financialyearstart)
                      .add(2, "month")
                      .startOf("month")
                      .format("MMM"),
                    moment(financialyearstart)
                      .add(3, "month")
                      .startOf("month")
                      .format("MMM"),
                    moment(financialyearstart)
                      .add(4, "month")
                      .startOf("month")
                      .format("MMM"),
                    moment(financialyearstart)
                      .add(5, "month")
                      .startOf("month")
                      .format("MMM"),
                    moment(financialyearstart)
                      .add(6, "month")
                      .startOf("month")
                      .format("MMM"),
                    moment(financialyearstart)
                      .add(7, "month")
                      .startOf("month")
                      .format("MMM"),
                    moment(financialyearstart)
                      .add(8, "month")
                      .startOf("month")
                      .format("MMM"),
                    moment(financialyearstart)
                      .add(9, "month")
                      .startOf("month")
                      .format("MMM"),
                    moment(financialyearstart)
                      .add(10, "month")
                      .startOf("month")
                      .format("MMM"),
                    moment(financialyearstart)
                      .add(11, "month")
                      .startOf("month")
                      .format("MMM"),
                  ],
                },
                yaxis: [
                  {
                    title: {
                      text: `Quantity`,
                    },
                  },
                  {
                    opposite: true,
                    title: {
                      text: `Amount ( ${currency} )`,
                    },
                  },
                ],
              }}
              series={[
                {
                  type: "column",
                  name: "Quantity",
                  data:
                    this.state.yearquantity === undefined
                      ? []
                      : this.state.yearquantity,
                },
                {
                  type: "line",
                  name: "Amount",
                  data:
                    this.state.yearamount === undefined
                      ? []
                      : this.state.yearamount,
                },
              ]}
              type="line"
              height={200}
            />
          </div>}

        {/* <Row>
          <Col lg={5}>
            <div className={`card mb-4 ${this.props.theme===false?'':'bg-black5'}`}>
              <div
                className="card-body"
                style={{
                  boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)",
                }}
              >
                <div className="card-header">
                  <h5 className={`card-title ${this.props.theme===false?'text-black':'text-white'}`}>Top 5 Suppliers</h5>
                  <Form className="select-month-form">
                  <Form.Control
                    as="select"
                    className={`${this.props.theme===false?'':'bg-black5 text-white'}`}
                    value={this.state.type}
                    onChange={(e) => this.setState({ type: e.target.value })}
                  >
                    <option value="Quantity">Quantity</option>
                    <option value="Amount">Amount</option>
                  </Form.Control>
                </Form>
                </div>
                
                <div id="chart" className="apexcharts-content">
                  <Chart
                    options={{
                      labels: this.state.topsuppliers.map((e) => e.supplier),
                      responsive: [
                        {
                          options: {
                            legend: {
                              position: "bottom",
                            },
                          },
                        },
                      ],
                      fill: {
                        type: "gradient",
                        gradient: {
                          shade: "dark",
                          type: "vertical",
                          shadeIntensity: 0.5,
                          inverseColors: false,
                          opacityFrom: 1,
                          opacityTo: 0.8,
                          stops: [0, 100],
                        },
                      },
                      theme: {
                        mode: this.props.theme===false?'light':'dark', 
                        palette: 'palette1', 
                        monochrome: {
                            enabled: false,
                            color: '#255aee',
                            shadeTo: this.props.theme===false?'light':'dark', 
                            shadeIntensity: 0.65
                        },
                    },
                      dropShadow: {
                        enabled: true,
                        opacity: 0.3,
                        blur: 5,
                        left: -7,
                        top: 22,
                      },
                      legend: {
                        horizontalAlign: "center",
                        position: "top",
                        offsetY: 10,
                      },
                    }}
                    series={this.state.type==="Amount"?this.state.topsuppliers.map((e) => e.totalprice): this.state.topsuppliersbyquantity.map((e) => e.quantity)}
                    type="pie"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={7}>
            <div  className={`card mb-4 ${this.props.theme===false?'':'bg-black5'}`}>
              <div
                className="card-body"
                style={{ boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.2)" }}
              >
                <div className="card-header">
                  <h5 className={`card-title ${this.props.theme===false?'text-black':'text-white'}`}>Supplier Purchases by Year</h5>
                </div>
                <div className="card-header">
                  <Formik
                    initialValues={{
                      SuppliersData,
                      supplier,
                      supplierid,
                    }}
                    // onSubmit={this.onSubmit}
                    // validateOnChange={false}
                    // validate={this.validate}
                    enableReinitialize={true}
                  >
                    {({ setFieldValue, values }) => (
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="seller"
                          options={SuppliersData}
                          getOptionLabel={(option) => option.companyname}
                          value={values.seller ? undefined : undefined}
                          onChange={this.handleSeller}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              type="text"
                              name="seller"
                              // inputProps={{ style: { color: this.props.theme===false?'black':'white' } }}
                              value={this.state.supplier}
                              label={`Select Supplier(${this.state.supplier})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                    )}
                  </Formik>
                </div>

                <div id="chart" className="apexcharts-content">
                  <Chart
                    options={{
                      chart: {
                        stacked: false,
                      },
                      plotOptions: {
                        bar: {
                          columnWidth: "50%",
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      stroke: {
                        width: [0, 5, 5],
                        curve: "smooth",
                      },
                      title: {
                        text: "",
                        align: "left",
                      },
                      grid: {
                        row: {
                          colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
                        },
                      },
                      fill: {
                        type: "gradient",
                        gradient: {
                          shade: "dark",
                          type: "vertical",
                          shadeIntensity: 0.5,
                          inverseColors: false,
                          opacityFrom: 1,
                          opacityTo: 0.8,
                          stops: [0, 100],
                        },
                      },
                      dropShadow: {
                        enabled: true,
                        opacity: 0.3,
                        blur: 5,
                        left: -7,
                        top: 22,
                      },
                      legend: {
                        show: true,
                        floating: true,
                        horizontalAlign: "left",
                        onItemClick: {
                          toggleDataSeries: false,
                        },
                        position: "top",
                        offsetY: -33,
                        offsetX: 60,
                      },
                      theme: {
                        mode: this.props.theme === false ? "light" : "dark",
                        palette: "palette1",
                        monochrome: {
                          enabled: false,
                          color: "#255aee",
                          shadeTo:
                            this.props.theme === false ? "light" : "dark",
                          shadeIntensity: 0.65,
                        },
                      },
                      tooltip: {
                        theme: "light",
                      },
                      xaxis: {
                        categories: [
                          moment(financialyearstart)
                            .add(0, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(1, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(2, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(3, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(4, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(5, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(6, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(7, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(8, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(9, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(10, "month")
                            .startOf("month")
                            .format("MMM"),
                          moment(financialyearstart)
                            .add(11, "month")
                            .startOf("month")
                            .format("MMM"),
                        ],
                      },
                      yaxis: [
                        {
                          title: {
                            text: `Quantity`,
                          },
                        },
                        {
                          opposite: true,
                          title: {
                            text: `Amount ( ${currency} )`,
                          },
                        },
                      ],
                    }}
                    series={[
                      {
                        type: "column",
                        name: "Quantity",
                        data:
                          this.state.yearquantity === undefined
                            ? []
                            : this.state.yearquantity,
                      },
                      {
                        type: "line",
                        name: "Amount",
                        data:
                          this.state.yearamount === undefined
                            ? []
                            : this.state.yearamount,
                      },
                    ]}
                    type="line"
                    height={335}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row> */}
      </div>
    );
  }
}

export default SuppliersPurchasebyYear;
