 import { Component } from 'react'
import Footer from '../../Component/Footer'
import Header from '../../Component/Header'
import Menu from '../../Component/Menu'
import StocksDataTable from './StockDataTable'

export default class Stock extends Component {
    render() {
        return (
            <div>
                 <Header />
                <Menu/>
                <>
              <div className="content-wrapper">
              <StocksDataTable/>
              
              </div>
              </>
              <Footer/>

            </div>
        )
    }
}
