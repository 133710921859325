import axios from 'axios'
import { Auth_Proto, Auth_Domain, Auth_Port } from '../Component/consts'

class MasterService {

   AddCountry(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/addcountry`,data)
   }

   AddState(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/addstate`,data)
   }
   AddCity(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/addcity`,data)
   }

   DeleteCountry(id){
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/deletecountry/${id}`,)
   }

   DeleteState(id){
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/deletestate/${id}`,)
   }
   DeleteCity(id){
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/deletecity/${id}`,)
   }
   retriveCountryId() {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/loadcountryid`)
   }

   retriveAllTenants() {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/gettenants`)
   }

   //       retriveAuthUsers(){
   //          return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/auth/getauthuser`)
   //       }

   retriveTenant(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/gettenants/${id}`)
   }

   //       getRoles(){
   //          return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/users/getroles`)
   //       }

   getCurrency() {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getallcurrency`,)
   }

   getTaxtypes() {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getalltaxtypes`,)
   }
   createTenant(newtenant) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/addnewtenant`,newtenant)
   }

   updateTenant(updatetenant) {
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/updatetenant`, updatetenant)
   }

   addAdminUser(tenantid) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/addadminuser/${tenantid}`,)
   }

   addproduct(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/addproduct`, data)
   }
   addnewTenantProducts(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/addnewtenantproducts`,data)
   }

   getZoneIdList() {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getallzonelist`,)
   }

   getCountries() {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/loadCountries`,)
   }

   getAllCountries() {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getallcountries`,)
   }
   getAllStates(){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getallstates`,)
   }

   getAllCities(){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getallcities`,)
   }

   getStates(value) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/loadStatesByCountry/${value}`,)
   }

   getCities(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/loadCitiesByState/${id}`,)
   }
   deleteTenant(id) {
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/deletetenant/${id}`,)
   }

   getProducts(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getproductsenabled/${id}`,)
   }

   getProductsScreens(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getproductwtscreens/${id}`,)
   }
   getRoles(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getrolesname/${id}`,)
   }

   getRolesScreens(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getrole/${id}`,)
   }
   getallRoles(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getroleslist/${id}`,)
   }

   addRole(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addrole`,data)
   }
   UpdateRole(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/updaterole`,data)
   }
   DeleteRole(id){
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deleterole/${id}`,)
   }

   getRolesProducts(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getrolesproducts/${id}`,)
   }

   getHSNbyCode(code) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/gethsngst/${code}`)
   }

   getParameters(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getparameters/${id}`)
   }

   AddScreen(data) {
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/addscreens`,data)
   }


   GetAllCurrency(){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getallcurrency`)
   }

   GetCurrencyOfTenant(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getcurrencyoftenant/${id}`)
   }

   

   getAllProducts() {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getproducts`,)
   }

   getAllScreens(data) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getscreens`,data)
   }


   addclientssettings(id){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addclientssettings/${id}`)
   }

   adddefaultprefixes(id){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/adddefaultprefixes/${id}`)
   }

   updateTenantProducts(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/updtaetenantproducts`,data)
   }

   GetClientsProducts(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getclientproducts/${id}`)
   }

   extendTrailPeriod(id){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/extendtrailperiod/${id}`,)
   }

   InactiveClient(id){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/inactiveclient/${id}`,)
   }

   PaymentStatusUpdate(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/paymentstatusofclient`,data)
   }

   ChangeEnguagment(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/changeenguagmenttype`,data)  
   }

   GetNoofUsersLinit(tenantid){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/gettenantuserlimit/${tenantid}`)
   }

   getDistance(pin1,pin2){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/getdistance/${pin1}/${pin2}`)
   }
   UpdateransactionStartDate(data){
      return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/updateransactionfrom`,data)  
   }
   
   //     settenantid(TenantID){
   //       currentTenant = TenantID
   //     }
   // 
}

export default new MasterService();
