import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  Table,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { Component } from "react";
import Credentials, { userid } from "../Common/Credentials";
import { spin } from "../Common/NewLoader";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import withRouter from "../withRouter";
import NotificationServices from "./NotificationServices";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tab from "@mui/material/Tab";
import {TabContext, TabPanel} from "@mui/lab";
import TabList from "@mui/lab/TabList";

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      unread: "",
      notificationtype: "Product Based",
    };
  }

  Markallasread() {
    NotificationServices.MarkAllasRead(userid)
      .then(
        (res) => this.refresh()
        //   this.state.data.map(e=>
        //  this.state.data.find(i=>i.id===e.id).status=1
        // )
      )
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

  handleRead(value) {
    NotificationServices.NotificationsReadStatus(value)
      .then((res) =>
        //  this.state.data.find(e=>e.id===value).status=1
        this.refresh()
      )
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }

  refresh() {
    NotificationServices.GetAllNotifications(userid)
      .then((res) =>
        this.setState({
          data: res.data.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)),
          unread: res.data.filter((a) => a.notificationstatus === 0).length,
        })
      )
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
  }
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    Credentials.setSelectedMenu("Notifications")
    this.refresh();
  }
  render() {
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title textColour">
                <i className="fas fa-bell mr-2"></i>Notifications
              </h3>
            </div>
            <div className="form-row m-0">
              <div className="card-body">
                <div>
                  {this.state.loading === true ? (
                    <div className="text-center">{spin}</div>
                  ) : (
                    <Formik initialValues={{}} enableReinitialize={true}>
                      {() => (
                        <Form autoComplete="off">
                          {/* <fieldset
                            className={`form-group col-md-3  shadow-sm text-center btn mr-2 ${
                              this.state.notificationtype === "Product Based"
                                ? ""
                                : " hovbuttonColour"
                            }`}
                            onClick={() =>
                              this.setState({
                                notificationtype: "Product Based",
                              })
                            }
                          >
                            Product Based
                          </fieldset>
                          <fieldset
                            className={`form-group col-md-3 shadow-sm btn text-center ${
                              this.state.notificationtype === "All"
                                ? ""
                                : " hovbuttonColour"
                            }`}
                            onClick={() =>
                              this.setState({ notificationtype: "All" })
                            }
                          >
                            Show All
                          </fieldset> */}

                          <TabContext value={this.state.notificationtype}>
                          <TabList
                    sx={{borderBottom: 1, borderColor: '#ffdd15'}}
                    onChange={(event, newValue) => this.setState({
                      notificationtype: newValue,
                    })}
                >
                    <Tab label={"Product Based"} value={"Product Based"} sx={{flex: '1 1 auto'}}/>
                    <Tab label={"All"} value={"All"} sx={{flex: '1 1 auto'}}/>
                </TabList>
                <TabPanel value="notification" sx={{p: 0}}>
                  </TabPanel>
                  </TabContext>

                        

                          {this.state.notificationtype === "Product Based" ? (
                            <>
                              {/* <Typography className="breadcrumb mb-3">
                                <span
                                  style={{
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    marginLeft: "12px",
                                  }}
                                >
                                  {this.state.notificationtype}{" "}
                                </span>
                              </Typography> */}
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <span className="textColour">
                                    Masters (
                                    {this.state.data.filter(
                                      (e) =>
                                        e.notificationstatus === 0 &&
                                        e.product === "Masters"
                                    ).length > 0 ? (
                                      <>
                                        {
                                          this.state.data.filter(
                                            (e) =>
                                              e.notificationstatus === 0 &&
                                              e.product === "Masters"
                                          ).length
                                        }
                                        /
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {
                                      this.state.data.filter(
                                        (e) => e.product === "Masters"
                                      ).length
                                    }
                                    )
                                  </span>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Table>
                                    <TableBody>
                                      {this.state.data.map((data, index) =>
                                        data.product === "Masters" ? (
                                          <TableRow key={index}>
                                            <TableCell
                                        onClick={() => data.notificationstatus === 0 ?this.handleRead(data.id):''}
                                        className="cursorPointer"
                                      >
                                        {data.notificationstatus === 0 ? (
                                          <i
                                            className="fas fa-circle textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        ) : (
                                          <i
                                            className="fas fa-check textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        )}
                                        {data.createdbyname === null ? (
                                          ""
                                        ) : (
                                          <span className="text-bold">
                                            &nbsp;{data.createdbyname} -
                                          </span>
                                        )}
                                                &nbsp;
                                                {data.subject}
                                                <br />
                                                <span
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {data.content}
                                                </span>
                                                <span
                                                  style={{
                                                    fontSize: "12px",
                                                  }}
                                                  className="float-right"
                                                >
                                                  {moment(
                                                    data.createdAt
                                                  ).format("DD-MM-YYYY HH:mm")}
                                                </span>
                                              </TableCell>
                                          </TableRow>
                                        ) : (
                                          ""
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <span className="textColour">
                                    Accounts (
                                    {this.state.data.filter(
                                      (e) =>
                                        e.notificationstatus === 0 &&
                                        e.product === "Accounts"
                                    ).length > 0 ? (
                                      <>
                                        {
                                          this.state.data.filter(
                                            (e) =>
                                              e.notificationstatus === 0 &&
                                              e.product === "Accounts"
                                          ).length
                                        }
                                        /
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {
                                      this.state.data.filter(
                                        (e) => e.product === "Accounts"
                                      ).length
                                    }
                                    )
                                  </span>
                                </AccordionSummary>
                                <AccordionDetails></AccordionDetails>
                              </Accordion>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <span className="textColour">
                                    Inventory (
                                    {this.state.data.filter(
                                      (e) =>
                                        e.notificationstatus === 0 &&
                                        e.product === "Inventory"
                                    ).length > 0 ? (
                                      <>
                                        {
                                          this.state.data.filter(
                                            (e) =>
                                              e.notificationstatus === 0 &&
                                              e.product === "Inventory"
                                          ).length
                                        }
                                        /
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {
                                      this.state.data.filter(
                                        (e) => e.product === "Inventory"
                                      ).length
                                    }
                                    )
                                  </span>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Table>
                                    <TableBody>
                                      {this.state.data.map((data, index) =>
                                        data.product === "Inventory" ? (
                                          <TableRow key={index}>
                                            <TableCell
                                        onClick={() => data.notificationstatus === 0 ?this.handleRead(data.id):''}
                                        className="cursorPointer"
                                      >
                                        {data.notificationstatus === 0 ? (
                                          <i
                                            className="fas fa-circle textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        ) : (
                                          <i
                                            className="fas fa-check textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        )}
                                        {data.createdbyname === null ? (
                                          ""
                                        ) : (
                                          <span className="text-bold">
                                            &nbsp;{data.createdbyname} -
                                          </span>
                                        )}
                                                &nbsp;
                                                {data.subject}
                                                <br />
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  {data.content}
                                                </span>
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                  className="float-right"
                                                >
                                                  {moment(
                                                    data.createdAt
                                                  ).format("DD-MM-YYYY HH:mm")}
                                                </span>
                                              </TableCell>
                                          </TableRow>
                                        ) : (
                                          ""
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <span className="textColour">
                                    Sales (
                                    {this.state.data.filter(
                                      (e) =>
                                        e.notificationstatus === 0 &&
                                        e.product === "Sales"
                                    ).length > 0 ? (
                                      <>
                                        {
                                          this.state.data.filter(
                                            (e) =>
                                              e.notificationstatus === 0 &&
                                              e.product === "Sales"
                                          ).length
                                        }
                                        /
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {
                                      this.state.data.filter(
                                        (e) => e.product === "Sales"
                                      ).length
                                    }
                                    )
                                  </span>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Table>
                                    <TableBody>
                                      {this.state.data.map((data, index) =>
                                        data.product === "Sales" ? (
                                          <TableRow key={index}>
                                            <TableCell
                                        onClick={() => data.notificationstatus === 0 ?this.handleRead(data.id):''}
                                        className="cursorPointer"
                                      >
                                        {data.notificationstatus === 0 ? (
                                          <i
                                            className="fas fa-circle textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        ) : (
                                          <i
                                            className="fas fa-check textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        )}
                                        {data.createdbyname === null ? (
                                          ""
                                        ) : (
                                          <span className="text-bold">
                                            &nbsp;{data.createdbyname} -
                                          </span>
                                        )}
                                                &nbsp;
                                                {data.subject}
                                                <br />
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  {data.content}
                                                </span>
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                  className="float-right"
                                                >
                                                  {moment(
                                                    data.createdAt
                                                  ).format("DD-MM-YYYY HH:mm")}
                                                </span>
                                              </TableCell>
                                           
                                          </TableRow>
                                        ) : (
                                          ""
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <span className="textColour">
                                    Purchase (
                                    {this.state.data.filter(
                                      (e) =>
                                        e.notificationstatus === 0 &&
                                        e.product === "Purchase"
                                    ).length > 0 ? (
                                      <>
                                        {
                                          this.state.data.filter(
                                            (e) =>
                                              e.notificationstatus === 0 &&
                                              e.product === "Purchase"
                                          ).length
                                        }
                                        /
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {
                                      this.state.data.filter(
                                        (e) => e.product === "Purchase"
                                      ).length
                                    }
                                    )
                                  </span>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Table>
                                    <TableBody>
                                      {this.state.data.map((data, index) =>
                                        data.product === "Purchase" ? (
                                          <TableRow key={index}>
                                            <TableCell
                                        onClick={() => data.notificationstatus === 0 ?this.handleRead(data.id):''}
                                        className="cursorPointer"
                                      >
                                        {data.notificationstatus === 0 ? (
                                          <i
                                            className="fas fa-circle textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        ) : (
                                          <i
                                            className="fas fa-check textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        )}
                                        {data.createdbyname === null ? (
                                          ""
                                        ) : (
                                          <span className="text-bold">
                                            &nbsp;{data.createdbyname} -
                                          </span>
                                        )}
                                                &nbsp;
                                                {data.subject}
                                                <br />
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  {data.content}
                                                </span>
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                  className="float-right"
                                                >
                                                  {moment(
                                                    data.createdAt
                                                  ).format("DD-MM-YYYY HH:mm")}
                                                </span>
                                              </TableCell>
                                          
                                          </TableRow>
                                        ) : (
                                          ""
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <span className="textColour">
                                    Production (
                                    {this.state.data.filter(
                                      (e) =>
                                        e.notificationstatus === 0 &&
                                        e.product === "Production"
                                    ).length > 0 ? (
                                      <>
                                        {
                                          this.state.data.filter(
                                            (e) =>
                                              e.notificationstatus === 0 &&
                                              e.product === "Production"
                                          ).length
                                        }
                                        /
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {
                                      this.state.data.filter(
                                        (e) => e.product === "Production"
                                      ).length
                                    }
                                    )
                                  </span>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Table>
                                    <TableBody>
                                      {this.state.data.map((data, index) =>
                                        data.product === "Production" ? (
                                          <TableRow key={index}>
                                            <TableCell
                                        onClick={() => data.notificationstatus === 0 ?this.handleRead(data.id):''}
                                        className="cursorPointer"
                                      >
                                        {data.notificationstatus === 0 ? (
                                          <i
                                            className="fas fa-circle textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        ) : (
                                          <i
                                            className="fas fa-check textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        )}
                                        {data.createdbyname === null ? (
                                          ""
                                        ) : (
                                          <span className="text-bold">
                                            &nbsp;{data.createdbyname} -
                                          </span>
                                        )}
                                                &nbsp;
                                                {data.subject}
                                                <br />
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  {data.content}
                                                </span>
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                  className="float-right"
                                                >
                                                  {moment(
                                                    data.createdAt
                                                  ).format("DD-MM-YYYY HH:mm")}
                                                </span>
                                              </TableCell>
                                           
                                          </TableRow>
                                        ) : (
                                          ""
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </AccordionDetails>
                              </Accordion>
                              <Accordion>
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <span className="textColour">
                                    Assets (
                                    {this.state.data.filter(
                                      (e) =>
                                        e.notificationstatus === 0 &&
                                        e.product === "Assets"
                                    ).length > 0 ? (
                                      <>
                                        {
                                          this.state.data.filter(
                                            (e) =>
                                              e.notificationstatus === 0 &&
                                              e.product === "Assets"
                                          ).length
                                        }
                                        /
                                      </>
                                    ) : (
                                      ""
                                    )}
                                    {
                                      this.state.data.filter(
                                        (e) => e.product === "Assets"
                                      ).length
                                    }
                                    )
                                  </span>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Table>
                                    <TableBody>
                                      {this.state.data.map((data, index) =>
                                        data.product === "Assets" ? (
                                          <TableRow key={index}>
                                           <TableCell
                                        onClick={() => data.notificationstatus === 0 ?this.handleRead(data.id):''}
                                        className="cursorPointer"
                                      >
                                        {data.notificationstatus === 0 ? (
                                          <i
                                            className="fas fa-circle textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        ) : (
                                          <i
                                            className="fas fa-check textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        )}
                                        {data.createdbyname === null ? (
                                          ""
                                        ) : (
                                          <span className="text-bold">
                                            &nbsp;{data.createdbyname} -
                                          </span>
                                        )}
                                                &nbsp;
                                                {data.subject}
                                                <br />
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                >
                                                  {data.content}
                                                </span>
                                                <span
                                                  style={{ fontSize: "12px" }}
                                                  className="float-right"
                                                >
                                                  {moment(
                                                    data.createdAt
                                                  ).format("DD-MM-YYYY HH:mm")}
                                                </span>
                                              </TableCell>
                                           
                                          </TableRow>
                                        ) : (
                                          ""
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          ) : (
                            <TableContainer>
                              {/* <Typography className="breadcrumb">
                                <span
                                  style={{
                                    marginTop: "auto",
                                    marginBottom: "auto",
                                    marginLeft: "12px",
                                  }}
                                >
                                  {this.state.notificationtype} (
                                  {this.state.data.filter(
                                    (e) => e.notificationstatus === 0
                                  ).length > 0 ? (
                                    <>
                                      {
                                        this.state.data.filter(
                                          (e) => e.notificationstatus === 0
                                        ).length
                                      }
                                      /
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {this.state.data.length})
                                </span>
                                {this.state.unread === 0 ? (
                                  ""
                                ) : (
                                  <span
                                    className="btn btn-sm backgroundColour text-white shadow-sm ml-auto"
                                    onClick={this.Markallasread.bind(this)}
                                  >
                                    &nbsp;&nbsp;
                                    <i className="fas fa-check" />
                                    &nbsp;Mark all as read
                                  </span>
                                )}
                              </Typography> */}
                              <Table>
                                <TableBody>
                                  {this.state.data.map((data, index) => (
                                    <TableRow key={index}>
                                      {/* {data.notificationstatus === 0 ? ( */}
                                      <TableCell
                                        onClick={() => data.notificationstatus === 0 ?this.handleRead(data.id):''}
                                        className="cursorPointer"
                                      >
                                        {data.notificationstatus === 0 ? (
                                          <i
                                            className="fas fa-circle textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        ) : (
                                          <i
                                            className="fas fa-check textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                        )}
                                        {data.createdbyname === null ? (
                                          ""
                                        ) : (
                                          <span className="text-bold">
                                            &nbsp;{data.createdbyname} -
                                          </span>
                                        )}
                                        &nbsp;{data.subject}
                                        <br />
                                        <span style={{ fontSize: "12px" }}>
                                          {data.content}
                                        </span>
                                        <span
                                          style={{ fontSize: "12px" }}
                                          className="float-right"
                                        >
                                          {moment(data.createdAt).format(
                                            "DD-MM-YYYY HH:mm"
                                          )}
                                        </span>
                                      </TableCell>
                                      {/* ) : (
                                        <TableCell>
                                          <i
                                            className="fas fa-check textColour"
                                            style={{ fontSize: "10px" }}
                                          />
                                          {data.createdbyname === null?"":<>&nbsp;{data.createdbyname}</>}
                                          &nbsp;{data.subject}
                                          <br />
                                          <span style={{ fontSize: "12px" }}>
                                            {data.content}
                                          </span>
                                          <span
                                            style={{ fontSize: "12px" }}
                                            className="float-right"
                                          >
                                            {moment(data.createdAt).format(
                                              "DD-MM-YYYY HH:mm"
                                            )}
                                          </span>
                                        </TableCell> 
                                      )}*/}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Notifications);
