import { createTheme, ThemeProvider } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import CustomToolbar from '../../../CommonComponents/CustomToolbar'
import MUIDataTable from 'mui-datatables';
import TourServices from '../../Invoice/TourServices';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import withRouter from '../../../withRouter';


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});


const MAirportList = () => {
  const [airport, setAirport] = useState([]); 

  useEffect(() => {
    getMAirportList();
  },[])

  const delteDataHandler = (e) => {
    if (confirm('Are you sure you want to delete this thing into the database?')) {
      TourServices.deleteMAirport(e).then((r) => {
        console.log(r.data);
        getMAirportList();
      })
      console.log('Thing was Deleted from database.');
    } else {
      
      console.log('Thing was not Deleted to the database.');
    }
    
  }

  const getMAirportList = () => {
    TourServices.getAllMAirportList().then((r) => {
      console.log(r.data);
      setAirport(r.data);

    })
  }
  const columns = [
    {
      name: "id",
      label: "ID",
      options: { filter: true, sort: true },
    },
    {
      name: "code",
      label: "Code",
      options: { filter: true, sort: true },
    },
    {
      name: "city",
      label: "city",
      options: { filter: true, sort: true },
    },
    {
        name: "country",
        label: "country",
        options: { filter: true, sort: true },
      },
      {
        name: "latitude",
        label: "latitude",
        options: { filter: true, sort: true },
      },
      {
        name: "longitude",
        label: "logitude",
        options: { filter: true, sort: true },
      },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <button
                  as="button"
                  data-tip
                  data-for="delete"
                  className="btn btn-sm deletebutton"
                 // to={{ state: {id: tableMeta.rowData.find(e=>e) ,pagename:"Employee" }  }}
                  onClick={()=>
                delteDataHandler(tableMeta.rowData.find(e=>e))
                  }
                >Delete</button>
             
             <Link as="button" className="btn btn-sm hovbuttonColour mr-2"
                  to={{ pathname: "/masterairport"}} state= {{id: tableMeta.rowData.find(e=>e) ,pagename:"Edit Master Airport" }}>Edit</Link>

            </>
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    customToolbar: () => {
      return (
        <>
          (
            <Link
            as="button"
            className="float-right btn btn-sm m-0 p-0"
            to={{ pathname: "/masterairport"}} 
            state= {{id: -1,pagename:"Add Airport" }}
          >
            <CustomToolbar />
          </Link>
          )
        </>
      );
    },
  };

  return (
    <>
      <Header />
      <Menu />
      <div className="content-wrapper w-100% h-100%">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
          <span
                as="button"
                className=" text-secondary cursorPointer"
                
              >
                <Link to="/masterpage" style={{color:"grey"}}>❮ Back &nbsp;&nbsp;</Link>
                
              </span>
            <li className="breadcrumb-item text-secondary">
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/masterpage">Tours & Travellers </Link>
            </li>
            <li className="breadcrumb-item active">Master Airport List</li>
          </ol>
          <div className='card'>
            <div className='card-body'>

              <ThemeProvider theme={theme}>
                <MUIDataTable
                  className="table-sm px-3"
                  title={"Airport List"}
                  data={airport}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default withRouter(MAirportList);