import React, { Component } from 'react'
import Footer from '../Component/Footer'
import Header from '../Component/Header'
import Menu from '../Component/Menu'
import MasterDataTable from './MasterDataTable'

export default class Masters extends Component {
    render() {
        return (
            <div>
            <Header />
                 <Menu/>
                <>
              <div className="content-wrapper">
              <MasterDataTable/>
              </div>
              </>
              <Footer/>

            </div>
        )
    }
}
