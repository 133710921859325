import { TextField } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { Link, useHistory, useNavigate } from "react-router-dom";
import { currentTenant, userid } from "../Common/Credentials";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import withRouter from "../withRouter";
import EmployeeService from "./EmployeeService";
import "./font.css";

const SalaryBreakup = () => {
  const [basicSalaryb, setBasicSalaryb] = useState(0);
  const [houseRentAllowancesb, setHouseRentAllownaceb] = useState(0);
  const [conveyanceAllowancesb, setConveyanceAllowancesb] = useState(0);
  const [medicalAllowancesb, setMedicalAllowancesb] = useState(0);
  const [specialAllowancesb, setSpecialAllowancesb] = useState(0);
  const [epfb, setEpfb] = useState(0);
  const [healthInsuranceb, setHealthInsuranceb] = useState(0);
  const [salaryfor, setSalaryfor] = useState("");
  const [professionaltax,setProfessionalTax] = useState(0)
  const navigate = useNavigate();

  const calctt = (e, e1) => {
    let ll = (basicSalaryb * e) / 100;
  };
  const addDepartment = async (e) => {
    e.preventDefault();
    console.log("adding function");
    let salbk = {
      basicsalary: basicSalaryb,
      houserentallowance: houseRentAllowancesb,
      conveyanceallowance: conveyanceAllowancesb,
      medicalallowance: medicalAllowancesb,
      specialallowance: specialAllowancesb,
      epf: epfb,
      healthinsurance: healthInsuranceb,
      tenantid: currentTenant,
      createdby: userid,
      salaryfor: salaryfor,
      professionaltax:professionaltax
    };
    EmployeeService.AddSalaryBreakUp(salbk).then(() => navigate(-1));
  };

  return (
    <>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
          <li className=" text-secondary">
            <Link as="button" className=" text-secondary"  to={{ pathname: "/employeesalary" }}>
                  ❮ Back &nbsp;&nbsp;
              </Link>
              </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/accounts">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/employeesalary">Employees Salary</Link>
            </li>
            <li className="breadcrumb-item active">Salary Breakup</li>
            <br />
          </ol>
          <div className="card-body">
            <form onSubmit={addDepartment} className="Card ms-5 me-5">
              <div className="row ">
                <fieldset className="col-md-3 my-2">
                  <TextField
                    label="Enter Identity "
                    placeholder="Enter Identity"
                    type="text"
                    variant="standard"
                    onChange={(e) => {
                      setSalaryfor(e.target.value);
                    }}
                    fullWidth
                  />
                </fieldset>
                
                    <fieldset className="col-md-3 my-2">
                      <TextField
                        label="Enter HRA(%)"
                        type="text"
                        variant="standard"
                        name="houseRentAllowancesb"
                        value={houseRentAllowancesb}
                        onChange={(e) => {
                          setHouseRentAllownaceb(e.target.value);
                        }}
                        fullWidth
                      />
                    </fieldset>
                  <fieldset className="col-md-3 my-2">
                    <TextField
                      label="Enter Conveyance Allowance(%)"
                      type="text"
                      variant="standard"
                      name="conveyanceAllowancesb"
                      value={conveyanceAllowancesb}
                      onChange={(e) => {
                        setConveyanceAllowancesb(e.target.value);
                      }}
                      fullWidth
                    />
                  </fieldset>
              
               
                  <fieldset className="col-md-3 my-2">
                    <TextField
                      label="Enter Medical Allowance (%)"
                      type="text"
                      variant="standard"
                      name="medicalAllowancesb"
                      value={medicalAllowancesb}
                      onChange={(e) => {
                        setMedicalAllowancesb(e.target.value);
                      }}
                      fullWidth
                    />
                  </fieldset>
              
              
                  <fieldset className="col-md-3 my-2">
                    <TextField
                      label="Enter Special Allowance (%)"
                      type="text"
                      variant="standard"
                      name="specialAllowancesb"
                      value={specialAllowancesb}
                      onChange={(e) => {
                        setSpecialAllowancesb(e.target.value);
                      }}
                      fullWidth
                    />
                  </fieldset>
                
              
              
             
              
                  <fieldset className="col-md-3 my-2">
                    <TextField
                      label="Enter EPF (%)"
                      type="text"
                      variant="standard"
                      name="epfb"
                      value={epfb}
                      onChange={(e) => {
                        setEpfb(e.target.value);
                      }}
                      fullWidth
                    />
                  </fieldset>
              
                  <fieldset className="col-md-3 my-2">
                    <TextField
                      label="Enter Health Insurance(%)"
                      type="text"
                      variant="standard"
                      name="healthInsuranceb"
                      value={healthInsuranceb}
                      onChange={(e) => {
                        setHealthInsuranceb(e.target.value);
                      }}
                      fullWidth
                    />
                  </fieldset>

                  <fieldset className="col-md-3 my-2">
                    <TextField
                      label="Enter Professional Tax"
                      type="text"
                      variant="standard"
                      name="professionaltax"
                      value={professionaltax}
                      onChange={(e) => {
                        setProfessionalTax(e.target.value);
                      }}
                      fullWidth
                    />
                  </fieldset>
                  </div>

              <div className="pt-4">
                  <button type="submit" className="btn btn-sm hovbuttonColour mr-1" value="Add" >
                    <i className="fa fa-save"></i>  Submit
                  </button>
                  <Link
                      as="button"
                      className="btn btn-sm deletebutton col-md-auto col-sm-12"
                      to={{ pathname: "/employeesalary" }}
                    >
                      <b style={{ color: "#FA5733" }}> X</b> Cancel
                    </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default withRouter(SalaryBreakup);
