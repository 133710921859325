import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant } from '../../Common/Credentials.js';
import Footer from '../../Component/Footer.js';
import Header from '../../Component/Header.js';
import Menu from '../../Component/Menu.js';
import { FormikTextField } from '../../CommonComponents/FormField.js';
import AgentServices from './AgentServices.js';
import { Spinner } from 'react-bootstrap';
import { Autocomplete } from '@mui/material';
import CustomerService from '../Customers/CustomerService.js';
import { InputAdornment } from '@mui/material';


class NewAgent extends Component {
  constructor(props) {
    super(props)
    this.state = {
        id:'',
        name:'',
        contactnumber:'',
        email:'',
        address:'',
        address2:'',
        pan:'',
        gstin:'',
        zip:'',
        CountryData:[],
        StateData:[],
        CityData:[],
        StateId: "",
        state:'',
        CountryId: "",
        country:'',
        CityId: "",
        city:'',
        alternatenumber:'',
        phonecode:'',
        message:'',
        loader:false,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(values) {
    this.setState({ loader: true });
    let newCustomer = {
      name: values.name,
      contactnumber: values.contactnumber.replace(/[^0-9]+/ig, "") ,
      email: values.email,
      address: values.address,
      address2: values.address2,
      pan: values.pan,
      gstin:values.gstin,
      zip: values.zip,
      country:this.state.CountryId,
      state: this.state.StateId,
      city: this.state.CityId,
      countryname:this.state.country,
      statename: this.state.state,
      cityname: this.state.city,
      tenantId: currentTenant,
      createdby: userid,
      alternatenumber:this.state.alternatenumber.replace(/[^0-9]+/ig, "") ,
      phonecode:this.state.phonecode,
    };
    let updateCustomer = {
      id: this.state.id,
      name: values.name,
      contactnumber: values.contactnumber,
      email: values.email,
      address: values.address,
      address2: values.address2,
      pan: values.pan,
      gstin:values.gstin,
      zip: values.zip,
      country:this.state.CountryId,
      state: this.state.StateId,
      city: this.state.CityId,
      countryname:this.state.country,
      statename: this.state.state,
      cityname: this.state.city,
      updatedby: userid,
      tenantId: currentTenant,
      alternatenumber:this.state.alternatenumber.replace(/[^0-9]+/ig, "") ,
      phonecode:this.state.phonecode,
    };

    //  MasterService.createTenant(newtenant).then(() => this.props.navigate(`/tenants`))
    if (this.state.id === ''|| this.state.id ===-1) {
        AgentServices.addAgent(newCustomer)
        .then(() =>
          this.props.navigate(`/agents`, {
            message: values.companyname + "   : Created Successfully",
          })
        )
        .catch((error) =>{
          if(error.response.data===undefined ||error.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
          this.setState({
            error: error.response.data.message,
            loader: false,
          })
        }
        );
    } else {
        AgentServices.updateAgent(updateCustomer).then(() =>
        this.props.navigate("/agents", {
          message: values.companyname + "   : Updated Successfully",
        })
      )
      .catch((e)=>{
        if(e.response.data===undefined || e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })
    }
    }

  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    let id = this.props.location.state.id

    CustomerService.getCountries().then((response) =>
    this.setState({
      CountryData: response.data,
    })
  );

  if(id!==-1){
    AgentServices.getAgentById(id).then((response) =>{
      this.setState({
        id:response.data.id,
        name:response.data.name,
        contactnumber:response.data.contactnumber,
        email:response.data.email,
        address:response.data.address,
        address2:response.data.address2,
        pan:response.data.pan,
        gstin:response.data.gstin,
        zip:response.data.zip,
        StateId: response.data.state,
        state:response.data.statename,
        CountryId: response.data.country,
        country:response.data.countryname,
        CityId: response.data.city,
        city:response.data.cityname,
        alternatenumber:response.data.alternatenumber,
        phonecode:response.data.phonecode,
        loader:false,
      })
    })
  }

    
 }

 ChangeteCountry = (value, values) => {
    if(values!=='' && typeof values === 'object' && values!==null){
    this.setState({country:values.name, CountryId: values.id,phonecode:values.phonecode, state :'', StateId: '',city:'',CityId:'',CityData:[] });
    CustomerService.getStates(values.id).then((response) => {
      this.setState({
        StateData: response.data,
      });
    });
  }else{
    this.setState({
      country:'', CountryId:'', state :'',StateData:[],CityData:[], StateId: '',city:'',CityId:''
    })
  }
  };
  ChangeState = (value, values) => {
    if(values!=='' && typeof values === 'object' && values !== null){
    this.setState({state:values.name, StateId: values.id });
    CustomerService.getCities(values.id).then((response) => {
      this.setState({
        CityData: response.data,city:'',CityId:''
      });
    });
   }else{
     this.setState({
    state:'', StateId: '',city:'',CityId:''
     })
   }
  };

  handleCity=(value,values)=>{
    if(values!=='' && typeof values === 'object' && values !== null){
    this.setState({city: values.name,CityId: values.id})
  }else{
    this.setState({city:'',CityId:''})
  }
  }

  validate(values) {
    let errors = {};
    var chars = /^[A-Za-z ]+$/;
    var num = /^[0-9]+$/;
    if (!values.name || this.state.name==='') {
      errors.name = "Company Name is required";
    } 
    // else if (!chars.test(values.name)) {
    //   errors.name = "Only Characters are allowed";
    // }

    if (!values.contactnumber) {
      errors.contactnumber = "Contact Number is required";
    } 
   else if(values.country === "India"){
    if (!/^[7-8-9][0-9]{9}$/i.test(values.contactnumber)) {
      errors.contactnumber = "Contact Number should be 10 Numbers";
    }
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (!values.address) {
      errors.address = "Address is required";
    }
    if (!values.address2) {
      errors.address2 = "Area/Colony/Street is required";
    }
    if(this.state.country ==='' || this.state.country === undefined){
        errors.country = "Country is required"
    }
    if(this.state.state ===''||this.state.state === undefined){
        errors.state = "State is required"
    }
    if(this.state.city ===''||this.state.city===undefined){
        errors.city = "City is required"
    }
  if(this.state.country ==='India'){
    if (!values.zip) {
      errors.zip = "Zip is required";
    } else if (!num.test(values.zip)) {
      errors.zip = "Only Numbers allowed";
    } else if (!/^[0-9]{5,6}$/i.test(values.zip)) {
      errors.zip = "Should be 5 or 6 Numbers";
    }
  }else{
    if (!values.zip|| this.state.zip === '') {
      errors.zip = "PO Box No. is required";
    }
  }

  // if(this.state.country ==='India'){
  //   if (!values.gstin) {
  //     errors.gstin = "GSTIN is required";
  //   } else if (!/^[A-Z0-9]{15}$/i.test(values.gstin)) {
  //     errors.gstin = "Invalid GST should be 15 Charactors EX: 22ABCDE1234F1Z5";
  //   } else if (
  //     !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[Z]{1}[A-Z0-9]{1}/i.test(
  //       values.gstin
  //     )
  //   ) {
  //     errors.gstin = "Invalid Ex: 22ABCDE1234F1Z5";
  //   }
  // }else{
  //   if (!values.gstin) {
  //       errors.gstin = "GSTIN/VAT No. is required";
  //     }
  // }

    return errors;
  }
  render() {
     let {
        id,
        name,
        contactnumber,
        email,
        address,
        address2,
        pan,
        gstin,
        zip,
        CountryData,
        StateData,
        CityData,
        country,
        state,
        city,
        alternatenumber,
        phonecode
     } = this.state;
 
    return (
      
      <div>
         <Header/>
         <Menu/>
        <div className="content-wrapper">
          <div className="card">
           
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{ pathname: "/agents"}} state={{ message: "" }}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/masters">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/agents">Agent</Link>
              </li>
              <li className="breadcrumb-item active">New Agent</li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">New Agent</h3>
            </div>
            <div className="card-body">
              <Formik
                initialValues={{
                  id,
                  name,
                  contactnumber,
                  email,
                  address,
                  address2,
                  pan,
                  gstin,
                  zip,
                  CountryData,
                  StateData,
                  CityData,
                  country,
                  state,
                  city,
                  alternatenumber,
                  phonecode
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="id"
                          placeholder="Id"
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="name"
                          label="Name"
                          value={this.state.name}
                          onChange={(e) =>
                            this.setState({ name: e.target.value })
                          }
                          autoComplete="off"
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="email"
                          name="email"
                          label="Email Id"
                          value={this.state.email}
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="address"
                          label="Address"
                          value={this.state.address}
                          onChange={(e) =>
                            this.setState({ address: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="address2"
                          label="Area/Colony/Street"
                          value={this.state.address2}
                          onChange={(e) =>
                            this.setState({ address2: e.target.value })
                          }
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="country"
                          options={CountryData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.country ? undefined : undefined}
                          onChange={this.ChangeteCountry}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="country"
                              value={this.state.country}
                              label={`Select Country (${this.state.country})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="state"
                          options={StateData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.state ? undefined : undefined}
                          onChange={this.ChangeState}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="state"
                              value={this.state.state}
                              label={`Select State (${this.state.state})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="city"
                          options={CityData}
                          getOptionLabel={(option) => option.name}
                          
                          
                          value={values.city ? undefined : undefined}
                          onChange={this.handleCity}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name='city'
                              value={this.state.city}
                              label={`Select City (${this.state.city})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="zip"
                          label={this.state.country==="India"?"Zip/Pincode":"PO BOX No."}
                          value={this.state.zip}
                          onChange={(e) =>
                            this.setState({ zip: e.target.value })
                          }
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="number"
                          name="contactnumber"
                          label="Contact Number"
                          value={this.state.contactnumber}
                          onChange={(e) =>
                            this.setState({ contactnumber: e.target.value.replace(/[^0-9]+/ig, "")  })
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +{this.state.phonecode}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </fieldset>

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="number"
                          name="alternatenumber"
                          label="Alternate Number"
                          value={this.state.alternatenumber}
                          onChange={(e) =>
                            this.setState({ alternatenumber: e.target.value.replace(/[^0-9]+/ig, "")  })
                          }
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                +{this.state.phonecode}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </fieldset>
                     {this.state.country==="India"?
                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="pan"
                          label="PAN"
                          value={this.state.pan}
                          onChange={(e) =>
                            this.setState({ pan: e.target.value })
                          }
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </fieldset>:''}

                      <fieldset className="form-group col-md-4">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="gstin"
                          label={this.state.country==="India"?"GSTIN No.":"VAT No."}
                          value={this.state.gstin}
                          onChange={(e) =>
                            this.setState({ gstin: e.target.value,error:'' })
                          }
                          inputProps={{ style: { textTransform: "uppercase" } }}
                        />
                      </fieldset>


                      <div className="form-group col-md-12" />
                      <fieldset className="form-group col-md-12">
                        
                          {this.state.loader === true ? (
                            <button className="btn btn-sm hovbuttonColour mr-1 " disabled>
                              <Spinner animation="border" size="sm" />
                              &nbsp;{"Saving"}
                            </button>
                          ) : (
                            <button className="btn btn-sm hovbuttonColour mr-1" type="submit"><i className="fas fa-save mr-2"/>Save</button>
                          )}
                        <Link
                          as="button"
                          className="btn btn-sm deletebutton"
                          to={{pathname: "/agents"}}
                            state= {{ message: "" }}
                        >
                          <b className="text-danger"> X </b>Close
                        </Link>
                      </fieldset>
                    </div>
                    {/* <Link as="button" type="submit" className="btn btn-success" to={{pathname: '/ledgers' }} state ={ {message:this.state.message}}}>Save</Link> */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

      <Footer/>
      </div>
    );
  }
}
export default withRouter(NewAgent)