import React, { useEffect, useState } from 'react'
import Footer from '../../../Component/Footer'
import ProjectManagementServices from '../../ProjectManagementServices';
import { currentTenant, userid } from '../../../Common/Credentials';
import Header from '../../../Component/Header';
import Menu from '../../../Component/Menu';
import withRouter from '../../../withRouter';
import { Form, Formik } from 'formik';
import { FormikTextField } from '../../../CommonComponents/FormField';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { Autocomplete } from '@mui/material';
import UserService from '../../../users/UserService';
import SalesServices from '../../../Sales/SalesServices';

const ClientAssign = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loader,setLoader] = useState(false)
    const [projectId,setProjectId] = useState("")
    const [project,setProject] = useState("")
    const [customersList, setCustomersList] = useState([]);
    const [customer, setCustomer] = useState("");
    const [customerId, setCustomerId] = useState("");
    const [customerContactNo, setCustomerContactNo] = useState("");
    const [customerEmailId, setCustomerEmailId] = useState("");
  const [remark,setRemark] = useState("")

    useEffect(()=>{
        setProjectId(location.state.projectId)
        setProject(location.state.projectName +" ("+location.state.projectCode+")")
        SalesServices.retriveAllCustomers(currentTenant).then((res) =>
            setCustomersList(res.data)
        );    
    },[])

    const handleCustomer = (value, values) => {
        if (values !== "" && typeof values === "object" && values !== null) {
          if (values.ledgerid === null || values.ledgerid === undefined) {
            if (accounts === "YES") {
              Swal.fire({
                text: `Ledger account is not created for Customer ${values.companyname}, so no payment or receipt can be done`,
                icon: "warning",
                confirmButtonColor: "#152f5f",
              });
            }
          }
          setCustomer(values.companyname);
          setCustomerId(values.id);
          setCustomerContactNo(values.contactnumber);
          setCustomerEmailId(values.email);
        } else {
          setCustomer("");
          setCustomerId("");
        }
      };
    const onSubmit = () => {
        setLoader(true)
        let data = {
            projectId: projectId,
            customerId: customerId,
            customerName: customer,
            customerContactNo: customerContactNo,
            customerEmailId: customerEmailId,
            tenantId: currentTenant,
            remark: remark,
            createdby: userid,
            updatedby: userid,
        }
        ProjectManagementServices.AddClientAssign(data)
        .then(()=>navigate(-1))
        .catch(()=>setLoader(false))
    }

  return (
    <div>
         <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card card-info">
          <ol className="breadcrumb float-sm-right">
          <Link
                as="button"
                className=" text-secondary"
                to={-1}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projectmanagement">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/projects">Projects</Link>
            </li>
            <li className="breadcrumb-item active">Assign Client</li>
          </ol>
          <section className="content">
            <div className="container-fluid p-3">
              <div className="row">
                <div className="col-12">
                      <div>
                      <Formik
                    initialValues={{}}
                    onSubmit={onSubmit}
                    validateOnChange={false}
                    // validate={validate}
                    enableReinitialize={true}
                  >
                    {({ setFieldValue, values }) => (
                      <Form className='row'>
                        <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="project"
                              label="Project "
                              value={project}
                              disabled
                            //   onChange={(e) => setSiteName(e.target.value)}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-12 m-0 p-0"/>
                          <fieldset className="form-group col-md-4">
                            <Autocomplete
                              id="subaccount"
                              className="subacc-select"
                              name="customer"
                              options={customersList}
                              getOptionLabel={(option) => option.companyname}
                              value={values.customer ? undefined : undefined}
                              onChange={handleCustomer}
                              renderInput={(params) => (
                                <FormikTextField
                                  {...params}
                                  className="form-control"
                                  type="text"
                                  name="customer"
                                  value={customer}
                                  label="Select Customer"
                                  variant="standard"
                                  fullwidth="true"
                                />
                              )}
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="customerContactNo"
                              label="Customer Contact No."
                              value={customerContactNo}
                              onChange={(e) =>
                                setCustomerContactNo(e.target.value)
                              }
                            />
                          </fieldset>
                          <fieldset className="form-group col-md-4">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="customerEmailId"
                              label="Customer Email"
                              value={customerEmailId}
                              onChange={(e) =>
                                setCustomerEmailId(e.target.value)
                              }
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-12  mt-3 mb-5">
                            <FormikTextField
                              name="description"
                              className="form-control"
                              multiline
                              rows={2}
                              label="Remarks"
                              variant="outlined"
                              value={remark}
                              onChange={(e) => setRemark(e.target.value)}
                            />
                          </fieldset>
                                                  <button
                                                    className="btn btn-sm hovbuttonColour mr-2"
                                                    disabled = {loader}
                                                  >
                                                    {loader?
                                                    <Spinner
                                                      animation="border"
                                                      size="sm"
                                                      className="mr-1"
                                                    />:""}
                                                    Submit
                                                  </button>
                          <Link
                            as="button"
                            className="btn btn-sm deletebutton"
                            to={-1}
                          >
                            <b className="text-danger"> X </b>Cancel
                          </Link>
                        </Form>
                    )}
                    </Formik>
                    </div>
                    </div>
                    </div>
                    </div>
                    </section>
                    </div>
                    </div>
                    <Footer/>  
    </div>
  )
}

export default withRouter(ClientAssign)