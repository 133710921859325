import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import moment from "moment";
import SalesServices from "../SalesServices";
import { thousandsdisplay, userid } from "../../Common/Credentials";
import { spin } from "../../Common/NewLoader";
import { Table } from "@mui/material";
import { TableHead } from "@mui/material";
import { TableRow } from "@mui/material";
import { TableBody } from "@mui/material";
import { TableCell } from "@mui/material";
import {NumericFormat} from "react-number-format";

class ViewSalesReturns extends Component {
  constructor(props) {
    super(props);
    console.log(this.props)
    const id = this.props.location.state.id;
    const customer = this.props.location.state.customer;
    const date = this.props.location.state.date;
    const grandtotal = this.props.location.state.grandtotal;
    this.state = {
      dndata:'',
      itemlist: [],
      id: id,
      customer: customer,
      date: date,
      stat: 0,
      grandtotal: grandtotal,
      message: "",
      loading:false,
    };
  }

  refreshSalesorder() {
    // let username = AuthenticationService.getLoggedInUser()
    SalesServices.GetDnReturnItemData(this.state.id).then((response) => {
      this.setState({dndata:response.data ,itemlist: response.data.itemlist });
      const script = document.createElement("script");
      script.src = "js/content.js";
      script.async = true;
      document.body.appendChild(script);
      this.setState({loading:false})
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }


  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
    this.setState({loading:true})
    this.refreshSalesorder()
  }

  render() {
    return (
      <div>
         <Header />
         <Menu/>
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
            <span
                className="text-secondary mr-2 cursorpointer"
                onClick={() => this.props.navigate(-1)}
              >
                ❮ Back
              </span>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/salesreturns">Sales Returns</Link>
              </li>
              <li className="breadcrumb-item active"> Sales Returns Order Details</li>
            </ol>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="">
                      <div className="card-body">
                      {this.state.loading === true ? <div className="text-center">{spin}</div>
                      :<>
                        <table className="float-left">
                          {this.state.dndata.customsaleorderid!==null && this.state.dndata.customsaleorderid!=undefined?
                          <tr>
                            <td>Saleorder Id</td>
                            <td>:&nbsp;{this.state.dndata.customsaleorderid}</td>
                          </tr>
                          :''}
                          <tr>
                            <td>Invoice Id</td>
                            <td>:&nbsp;{this.state.dndata.custominvid}</td>
                          </tr>
                          <tr>
                            <td>DeliveryNote Id</td>
                            <td>:&nbsp;{this.state.id}</td>
                          </tr>
                          <tr>
                            <td>Customer</td>
                            <td>:&nbsp; {this.state.dndata.customer}</td>
                          </tr>
                          <tr>
                            <td>User</td>
                            <td>:&nbsp;{this.state.dndata.createdbyname}({this.state.dndata.createdby})</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td>:&nbsp; {moment(this.state.dndata.createdAt).format("DD-MM-yyyy")}</td>
                          </tr>
                        </table>

                        <Table className="table-sm">
                          <TableHead>
                            <TableRow>
                              <TableCell>Id</TableCell>
                              <TableCell>Item Name</TableCell>
                              <TableCell>HSN</TableCell>
                              <TableCell>Price</TableCell>
                              <TableCell>Quantity</TableCell>
                              <TableCell>UOM</TableCell>
                              <TableCell>TotalPrice</TableCell>
                              <TableCell>Tax</TableCell>
                              <TableCell>TotalPrice(Inc tax)</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Type</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.itemlist.map((data, index) => (
                             <TableRow key={index}>
                             <TableCell>{data.id}</TableCell>
                             <TableCell>{data.name}</TableCell>
                             <TableCell>{data.hsn}</TableCell>
                             <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={data.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                             <TableCell>{data.quantity}</TableCell>
                             <TableCell>{data.uom}</TableCell>
                             <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={(data.price * data.quantity)} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                             <TableCell>{data.taxamount}({data.tax}%)</TableCell>
                             <TableCell className="text-right"><NumericFormat className="pr-3" displayType={'text'} value={(data.totalpricewtax)} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                             <TableCell>{data.damagestatusenum}</TableCell>
                             <TableCell>{data.refund === 0?"Refund":data.refund === 0?"Adjust":"NotApplicable"}</TableCell>
                           </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <div>
                          {/* {displaybutton}
                          {displaydeletebutton} */}
                        </div>
                     </>}
                      </div>
                    </div>

                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(ViewSalesReturns);
