import React, { Component } from 'react'
import {Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';import NewLedgerService from '../Ledgers/NewLedgerService';
import Swal from 'sweetalert2'
import { currentTenant , thousandsdisplay} from '../../Common/Credentials';
import { accountsid } from "../../Common/Permissions.js";
import MUIDataTable from 'mui-datatables';
import { spin } from "../../Common/NewLoader.jsx";
import {createTheme} from '@mui/material';
import { ThemeProvider } from "@mui/material";
import CustomToolbar from '../../CommonComponents/CustomToolbar.js';

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});
// const getTotal = (data) => {
//   let total=0 ;
//   //  console.log(data)
//   data.forEach(data => {
//     total += data;
//   });
//   // console.log(total)
//   return total;
// };
// const getTotal1 = (data1) => {
//   let total=0 ;
//   // console.log(data)
//   data1.forEach(data1 => {
//     total+= data1;
//   });
//   // console.log(total)
//   return total;
// };
export let assetledgers

export default class AssetsLiability extends Component {

  constructor(props) {
    super(props)


    //  const {pagename} = this.props.location.state;
    this.state = {
      // type: 'password',
      //  pagename:pagename,
   
    rledgers:[]
     

    }
  
    this.addData=this.addData.bind(this)
  }

  componentDidMount() {
    this.addData()


}

addData(){
  NewLedgerService.getAlLedgers(currentTenant)
  .then(response =>{
  this.setState({rledgers:response.data})
    assetledgers=response.data
    const script = document.createElement("script");
    script.src= '../../js/content.js';
    script.async= true;
   document.body.appendChild(script);
   
   })
   .catch((e)=>{
    if(e.response.data.error ==="Unauthorized"){
        alert("Your Session is Lost: Please login Back to resume");
        this.props.navigate(`/`);
    }
})
}


deleteLedger(value){
  NewLedgerService.delLA(value)
  .then(response=>{
    Swal.fire({
      title: "Response",
      text: response.data,
      icon: "info",
    confirmButtonText: "Ok",
    confirmButtonColor:'#152f5f',
    })
   // alert(response.data)
    this.addData()
  })
}


    render() {
      // const data= this.state.rledgers.map(row=> {return row.entrytype!==0?0:row.amount} ) 
      // const data1= this.state.rledgers.map(row=> {return row.entrytype!==1?0:row.amount} )  
 
      const columns = [
        {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "entrytype",label: "Entrytype",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "name",label: "Account Name",options: {filter: true,sort: true}},
        {name: "shname",label: "Head",options: {filter: true,sort: true,}},
        {name: "amount",label:"Opening Balance Debit",options: {filter: false,sort: true,
          customBodyRender: (value,tableMeta) => {
            return (
              <NumericFormat
                className="text-green"
                displayType={"text"}
                value={tableMeta.rowData[1]===0?value:0}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: value => ({ style: { textAlign:'right',textColor:'green'}}),
        }},
        {name: "amount",label:"Opening Balance Credit",options: {filter: false,sort: true,
          //  result.entrytype===0?result.amount:''
            customBodyRender: (value,tableMeta) => {
              return (
                <NumericFormat
                  className="text-danger" 
                  displayType={"text"}
                  value={tableMeta.rowData[1]===1?value:0}
                  decimalScale={2}
                  fixedDecimalScale={true}
                  disabled={true}
                  thousandSeparator={true}
                  thousandsGroupStyle={thousandsdisplay}
                ></NumericFormat>
              );
            },
            setCellProps: value => ({ style: { textAlign:'right'}}),
          }},
          {name: "Action", options: { filter: false,sort: false,empty: true,
              display: accountsid === "2" || accountsid === "3" ? true : false,
              customBodyRender: (value, tableMeta, updateValue) => {
                return (<>
                  {/* <Link
                    as="button"
                    data-tip
                    data-for="edit"
                    className="fa fa-edit mr-1"
                    to={{
                      pathname: "/newparty",
                      state: {
                        id: tableMeta.rowData.find((e) => e),
                        name: "Update Customer",
                      },
                    }}
                  ></Link>
                  &nbsp;&nbsp; */}
                   <span
                    as="button"
                    data-tip
                    data-for="delete"
                    className="fa fa-trash text-danger"
                    onClick={() => {
                      if (
                        window.confirm(
                          `Are you sure you wish to delete Customer ${tableMeta.rowData.find(
                            (e) => e
                          )}?`
                        )
                      )
                        this.deleteLedger(tableMeta.rowData.find((e) => e));

                    }}
                  ></span>
                  </>
                );
              },
              setCellProps: value => ({ style: { textAlign:'center'}}),
            },
          }
        // {name: "sitems",label: "Pendings",options: {filter: false,sort: false,display:true,viewColumns:false,
        
        //   customBodyRender:(value,tableMeta)=>{
        //     return (
        //       value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
        //       onClick={() => this.submitRecord(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Receipt"}
        //       </button>:<span className="text-red text-bold">No Pending</span>
        //     );      

        //   }
        
        // }},
      ]
      const options = {
        filterType: 'checkbox',
        selectableRows: "none",
        customToolbar: () => {
          return (
            <>
              {accountsid === "2" || accountsid === "3" ?  (
                <Link
                  as="button"
                  className="float-right btn btn-sm m-0 p-0"
                  to={{
                    pathname: "/newassets"}}
                    state= {{id: -1, name: "New Ledger" }}
                >
                  <CustomToolbar />
                </Link>
                ) : (
                 ""
              )} 
            </>
          );
        },
        resizableColumns: true,
      }

        return (

          


<div className="form-group col-md-12">
{/* <div>



</div> */}
     <div>
                  {this.state.loading === true ? (
                          <div className="text-center">{spin}</div>
                        ) : (
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`Ledgers`}
                            data={this.state.rledgers}
                            columns={columns}
                            options={options}
                          />
                        </ThemeProvider>
                        )}
                      </div>
     </div>
         
        )
    }
  
}
