import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";

import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import {
  currentTenant,
  dataaccess,
  financialyearend,
  financialyearstart,
  userid,
  userwhid,
} from "../../Common/Credentials";
// import moment from "moment";
import PurchaseServices from "../PurchseServices";
import { spin } from "../../Common/NewLoader";
// import UserService from "../../users/UserService";
import { purchaseid } from "../../Common/Permissions";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import MUIDataTable from "mui-datatables";
import { Grid, TextField, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class PurchaseReturns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PurchaseReturnsData: [],
      GrnReturnsData: [],
      loading: false,
      // returntype: this.props.location.state.returntype
      returntype: "GRN Item Returns",
      // UserPermissions:''
      fromdate: moment(new Date()).subtract(7, "days"),
      todate: new Date(),
    };
  }

  refreshSalesorder = (from, to) => {
    // let username = AuthenticationService.getLoggedInUser()

    let datadao = {
      tenantid: currentTenant,
      startdate: from,
      enddate: to,
      userid: userid,
      dataaccess: dataaccess,
      whid: userwhid,
    };
    PurchaseServices.GetPurchaseReturns(datadao)
      .then((response) => {
        this.setState({ PurchaseReturnsData: response.data });
        const script = document.createElement("script");
        script.src = "js/content.js";
        script.async = true;
        document.body.appendChild(script);
        this.setState({ loading: false });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    PurchaseServices.GetGrnReturns(datadao).then((res) => {
      this.setState({ GrnReturnsData: res.data });
    });
  };

  handleFromDateChange = (date) => {
    this.setState({
      fromdate: date,
      todate:
        moment(date).diff(this.state.todate, "days") >= 0
          ? this.state.todate
          : date,
    });
  };

  handleToDateChange = (date) => {
    this.setState({ todate: date });
  };

  handlecompletedDateChange = () => {
    this.refreshSalesorder(this.state.fromdate, this.state.todate);
  };

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    // UserService.UserPermissions(userid).then(response=>this.setState({UserPermissions:response.data}))
    this.setState({ loading: true });
    this.refreshSalesorder(moment(new Date()).subtract(14, "days"), new Date());
  }

  render() {
    const columns = [
      {
        name: "poid",
        label: "PO Id",
        options: {
          filter: true,
          sort: true,
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "custompoid",
        label: "PO Id",
        options: { filter: true, sort: true },
      },
      // {name: "supplierid",label:"Supplier Id",options: {filter: true,sort: true,}},
      {
        name: "supplier",
        label: "Supplier",
        options: { filter: true, sort: true },
      },
      // {name: "pototal",label: "Total Price",options: {filter: false,sort: true,}},
      {
        name: "date",
        label: "Date of Purchase",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return moment(value).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      },
      // {name: "postatus",label: "PO Status",options: {filter: true,sort: true,}},
      {
        name: "View",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="vieworder"
                  className="fa fa-list-alt"
                  style={{ paddingRight: "5px" }}
                  to={{ pathname: "/purchasereturnsdetails" }}
                  state={{
                    id: tableMeta.rowData.find((e) => e),
                    pagename: "Purchase Returns Details",
                  }}
                ></Link>
                {/* //<Tooltip id="vieworder" place="top" effect="solid">View Orders</Tooltip> */}
              </>
            );
          },
        },
      },
    ];
    const options = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {purchaseid === "2" || purchaseid === "3" ? (
              <Link
                className="float-right btn btn-sm m-0 p-0"
                to={{ pathname: "/newpurchasereturn" }}
                state={{
                  id: -1,
                  returntype: 0,
                  pagename: "New Purchase Returns",
                }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      downloadOptions: {
        filename: "Purchase Returns",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
    };

    const columns1 = [
      {
        name: "grnid",
        label: "GRN Id",
        options: {
          filter: true,
          sort: true,
          sortOrder: "asc",
          display: false,
          viewColumns: false,
        },
      },
      {
        name: "customgrnid",
        label: "GRN Id",
        options: { filter: true, sort: true },
      },
      {
        name: "custompoid",
        label: "PO Id",
        options: { filter: true, sort: true },
      },
      // {name: "supplierid",label:"Supplier Id",options: {filter: true,sort: true,}},
      {
        name: "supplier",
        label: "Supplier",
        options: { filter: true, sort: true },
      },
      // {name: "pototal",label: "Total Price",options: {filter: false,sort: true,}},
      {
        name: "date",
        label: "Date of Purchase",
        options: {
          filter: false,
          sort: true,
          customBodyRender: (value) => {
            return moment(value).format("DD-MM-YYYY HH:mm:ss");
          },
        },
      },
      // {name: "postatus",label: "PO Status",options: {filter: true,sort: true,}},
      {
        name: "View",
        options: {
          filter: false,
          sort: false,
          empty: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <>
                <Link
                  as="button"
                  data-tip
                  data-for="vieworder"
                  className="fa fa-list-alt"
                  style={{ paddingRight: "5px" }}
                  to={{ pathname: "/purchasereturnsdetails" }}
                  state={{
                    id: tableMeta.rowData.find((e) => e),
                    pagename: "GRN Returns Details",
                  }}
                ></Link>
                {/* //<Tooltip id="vieworder" place="top" effect="solid">View Orders</Tooltip> */}
              </>
            );
          },
        },
      },
    ];
    const options1 = {
      filterType: "checkbox",
      selectableRows: "none",
      customToolbar: () => {
        return (
          <>
            {purchaseid === "2" || purchaseid === "3" ? (
              <Link
                as="button"
                className="float-right btn btn-sm m-0 p-0"
                to={{ pathname: "/newpurchasereturn" }}
                state={{ id: -1, returntype: 1, pagename: "New GRN Returns" }}
              >
                <CustomToolbar />
              </Link>
            ) : (
              ""
            )}
          </>
        );
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      downloadOptions: {
        filename: "GRN Returns",
        separator: ",",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
    };
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>

              <li className="breadcrumb-item active">
                {this.state.returntype}
              </li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header m-0 p-2">
                        <fieldset className="form-group col-md-12">
                          <fieldset
                            className={`form-group col-md-6  shadow-sm text-center btn ${
                              this.state.returntype === "Purchase Returns"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                            }`}
                            disabled={
                              this.state.returntype === "Purchase Returns"
                            }
                            onClick={() =>
                              this.setState({ returntype: "Purchase Returns" })
                            }
                          >
                            Purchase Returns
                          </fieldset>
                          <fieldset
                            className={`form-group col-md-6 shadow-sm btn text-center ${
                              this.state.returntype === "GRN Item Returns"
                                ? "borderedbutton2"
                                : " hovbuttonColour"
                            }`}
                            disabled={
                              this.state.returntype === "GRN Item Returns"
                            }
                            onClick={() =>
                              this.setState({ returntype: "GRN Item Returns" })
                            }
                          >
                            GRN Item Returns
                          </fieldset>
                        </fieldset>
                      </div>

                      <div className="card-body">
                        {this.state.loading === true ? (
                          <div className="text-center">{spin}</div>
                        ) : (
                          <>
                            <div className="form-row g-0 mb-3">
                              <fieldset>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Grid container justifyContent="space-around">
                                    <DatePicker
                                      variant="inline"
                                      id="date-picker-dialog"
                                      label="From Date"
                                      name="from"
                                      format="dd/MM/yyyy"
                                      maxDate={new Date()}
                                      value={this.state.fromdate}
                                      onChange={this.handleFromDateChange}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                        />
                                      )}
                                      autoOk={true}
                                    />
                                  </Grid>
                                </LocalizationProvider>
                              </fieldset>

                              <fieldset style={{ marginLeft: "30px" }}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <Grid container justifyContent="space-around">
                                    <DatePicker
                                      variant="inline"
                                      id="date-picker-dialog"
                                      label="To Date"
                                      name="to"
                                      format="dd/MM/yyyy"
                                      minDate={this.state.fromdate}
                                      value={this.state.todate}
                                      onChange={this.handleToDateChange}
                                      maxDate={new Date()}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="standard"
                                        />
                                      )}
                                      autoOk={true}
                                    />
                                  </Grid>
                                </LocalizationProvider>
                              </fieldset>
                              <fieldset className="form-group col-md-1">
                                <button
                                  className="btn btn-sm hovbuttonColour mt-3"
                                  onClick={this.handlecompletedDateChange}
                                >
                                  <i className="fas fa-filter" />
                                </button>
                              </fieldset>
                            </div>
                            {this.state.returntype === "Purchase Returns" ? (
                              <>
                                <ThemeProvider theme={theme}>
                                  <MUIDataTable
                                    className="table-sm px-3"
                                    title={"Purchase Returns"}
                                    data={this.state.PurchaseReturnsData}
                                    columns={columns}
                                    options={options}
                                  />
                                </ThemeProvider>
                              </>
                            ) : (
                              <>
                                <ThemeProvider theme={theme}>
                                  <MUIDataTable
                                    className="table-sm px-3"
                                    title={"GRN Returns"}
                                    data={this.state.GrnReturnsData}
                                    columns={columns1}
                                    options={options1}
                                  />
                                </ThemeProvider>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(PurchaseReturns);
