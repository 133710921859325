import React, { useEffect, useState } from "react";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
import { NumericFormat } from "react-number-format";
import {
  address,
  address2,
  ccity,
  ccountry,
  clientname,
  companyemail,
  contactnumber,
  cstate,
  czip,
  gstin,
  logo,
  pan,
  thousandsdisplay,
} from "../../Common/Credentials";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter";
import moment from "moment";
import QRCode from "qrcode";
import autoTable from "jspdf-autotable";
import SalesServices from "../SalesServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { ToWords } from "to-words";
import jsPDF from "jspdf";

const Salescreditnote = (props) => {
  const [Invoicedata, setInvoicedata] = useState("");
  const [creditnote, setCreditnote] = useState("");
  const [creditnoteitems, setCreditnoteitems] = useState([]);
  const [QRcode, setQRcode] = useState("");
  const [imgwidth, setImgwidth] = useState(0);
  const [imgheight, setImgheight] = useState(0);
  useEffect(() => {
    let i = new Image();
    i.src = logo;
    setCreditnote(props.location.state.creditnote);
    setInvoicedata(props.location.state.invdata);

    if (props.location.state.creditnote.type === "Pricediff") {
      if(props.location.state.creditnote.rowitemid !== null)
      setCreditnoteitems(
        props.location.state.invdata.invoiceitemlist.filter(
          (e) => e.id === props.location.state.creditnote.rowitemid
        )
      );
      else if(props.location.state.creditnote.additionalrowid !== null) {
        setCreditnoteitems(props.location.state.invdata.additionalchares.filter(
          (e) => e.id === props.location.state.creditnote.additionalrowid
        ))
      }
    } else {
      SalesServices.getCreditNoteitemsbyId(
        props.location.state.creditnote.id
      ).then((response) => setCreditnoteitems(response.data));
    }

    QRCode.toDataURL(props.location.state.creditnote.signedQRcode).then(
      (url) => {
        setQRcode(url);
      }
    );

    setTimeout(() => {
      setImgwidth(i.naturalWidth);
      setImgheight(i.naturalHeight);
    }, 100);
  }, []);

  const handleDownload = (type) => {
    let totawtax = creditnote.totalamountwtax.toFixed(2);

    let data = [];
    console.log(creditnoteitems);
    if (creditnote.type === "Returns") {
      data = creditnoteitems;
      data = data.map((v) => ({ ...v, cgstamt: (v.taxamount / 2).toFixed(2) }));
      data = data.map((v) => ({ ...v, cgst: (v.tax / 2).toFixed(2) }));
      data = data.map((v) => ({ ...v, sgstamt: (v.taxamount / 2).toFixed(2) }));
      data = data.map((v) => ({ ...v, sgst: (v.tax / 2).toFixed(2) }));
      data.map((e) => (e.price = e.price.toFixed(2)));
      data.map((e) => (e.taxamount = e.taxamount.toFixed(2)));
      data.map((e) => (e.totalpricewtax = e.totalpricewtax?.toFixed(2)));
      data.map((e) => (e.totalprice = e?.totalprice?.toFixed(2)));
    } else {
      data = creditnoteitems;
      data = data.map((v) => ({
        ...v,
        cgstamt: (creditnote.taxamount / 2).toFixed(2),
      }));
      data = data.map((v) => ({ ...v, cgst: (creditnote.tax / 2).toFixed(2) }));
      data = data.map((v) => ({
        ...v,
        sgstamt: (creditnote.taxamount / 2).toFixed(2),
      }));
      data = data.map((v) => ({ ...v, sgst: (creditnote.tax / 2).toFixed(2) }));
      data = data.map((v) => ({
        ...v,
        totalprice: creditnote.totalamount.toFixed(2),
      }));
      data = data.map((v) => ({
        ...v,
        taxamount: creditnote.taxamount.toFixed(2),
      }));
      data = data.map((v) => ({
        ...v,
        totalpricewtax: creditnote.totalamountwtax.toFixed(2),
      }));
    }

    let columns;
    if (Invoicedata.taxtype !== "NA") {
      if (Invoicedata.taxtype === "GST" && ccountry === "India") {
        if (Invoicedata.gsttype === "INTRASTATE") {
          columns = [
            { header: "Description", dataKey: "name" },
            { header: "Price", dataKey: "totalprice" },
            { header: "CGST (%)", dataKey: "cgst" },
            { header: "SGST (%)", dataKey: "sgst" },
            {
              header: `CGST Amt. (${Invoicedata.currency})`,
              dataKey: "cgstamt",
            },
            {
              header: `SGST Amt. (${Invoicedata.currency})`,
              dataKey: "sgstamt",
            },
            {
              header: `Amount (${Invoicedata.currency})`,
              dataKey: "totalpricewtax",
            },
          ];
        } else {
          columns = [
            { header: "Description", dataKey: "name" },
            { header: "Price", dataKey: "totalprice" },
            { header: "IGST (%)", dataKey: "tax" },
            {
              header: `IGST Amt. (${Invoicedata.currency})`,
              dataKey: "taxamount",
            },
            {
              header: `Amount (${Invoicedata.currency})`,
              dataKey: "totalpricewtax",
            },
          ];
        }
      } else {
        if (Invoicedata.taxtype === "GST") {
          columns = [
            { header: "Description", dataKey: "name" },
            { header: "Price", dataKey: "totalprice" },
            { header: "GST (%)", dataKey: "tax" },
            {
              header: `GST Amt. (${Invoicedata.currency})`,
              dataKey: "taxamount",
            },
            {
              header: `Amount (${Invoicedata.currency})`,
              dataKey: "totalpricewtax",
            },
          ];
        } else {
          columns = [
            { header: "Description", dataKey: "name" },
            { header: "Price", dataKey: "totalprice" },
            { header: "VAT (%)", dataKey: "tax" },
            {
              header: `VAT Amt. (${Invoicedata.currency})`,
              dataKey: "taxamount",
            },
            {
              header: `Amount (${Invoicedata.currency})`,
              dataKey: "totalpricewtax",
            },
          ];
        }
      }
    } else {
      columns = [
        { header: "Description", dataKey: "name" },
        { header: "Price", dataKey: "totalprice" },
        {
          header: `Amount (${Invoicedata.currency})`,
          dataKey: "totalpricewtax",
        },
      ];
    }

    let date = moment(creditnote.createdAt).format("DD - MM - yyyy");
    let curdate = moment().format("DD-MM-YYYY HH:mm:ss");

    let logowidth = 0;
    logowidth = ((imgwidth - imgheight) / imgwidth) * 100;

    const toWords = new ToWords();
    // var totalinwords = toWords.convert(totalinvoicewtax);
    // var advanceinwords = toWords.convert(advance);
    const doc = new jsPDF("p", "pt", [842, 595], true);
    let y = 0;
    doc.setFont("helvetica", "normal");
    doc.setTextColor("#000");
    doc.setDrawColor(192, 192, 192);

    doc.setFontSize(8);

    if (
      creditnote.irn !== null &&
      creditnote.irn !== "" &&
      creditnote.irn !== undefined
    ) {
      doc.text("IRN", 20, 190);
      doc.text(`-`, 58, 190);
      doc.text(`${creditnote.irn}`, 65, 190);
      doc.text(`Ack No.`, 20, 205);
      doc.text(`-`, 58, 205);
      doc.text(`${creditnote.ackno}`, 65, 205);
      doc.text(`Ack Date`, 20, 220);
      doc.text(`-`, 58, 220);
      doc.text(`${creditnote.ackDt}`, 65, 220);
      // doc.text(`e-invoice`, 520, 190);
      // doc.addImage(QRcode, "GIF", 495, 190, 80, 80);
      doc.line(10, 240, 585, 240);
      y = 60;
    }

    doc.text(`CUSTOMER DETAILS (Bill To)`, 20, y + 190);
    doc.line(10, y + 195, 585, y + 195);
    doc.text("M/S", 20, y + 205);
    doc.text(`-`, 65, y + 205);
    doc.text(`${Invoicedata.customer}`, 75, y + 205);
    doc.text(`Address`, 20, y + 215);
    doc.text(`-`, 65, y + 215);
    doc.text(`${Invoicedata.custadress}`, 75, y + 215);
    doc.text(`${Invoicedata.custaddress2}`, 75, y + 225);
    doc.text(`${Invoicedata.cityname}, ${Invoicedata.state} `, 75, y + 235);
    doc.text(`${Invoicedata.country} - ${Invoicedata.zip}`, 75, y + 245);
    doc.text(`Phone No.`, 20, y + 255);
    doc.text(`-`, 65, y + 255);
    doc.text(
      `${Invoicedata.custphonecode}-${Invoicedata.custphno}`,
      75,
      y + 255
    );
    if (Invoicedata.custgstin !== "" && Invoicedata.custgstin !== undefined) {
      doc.text(`GSTIN`, 20, y + 265);
      doc.text(`-`, 65, y + 265);
      doc.text(`${Invoicedata.custgstin}`, 75, y + 265);
      y = y + 10;
      doc.text(`PAN`, 20, y + 265);
      doc.text(`-`, 65, y + 265);
      doc.text(`${Invoicedata.custgstin?.substring(2, 12)}`, 75, y + 265);
    }

    doc.setLineWidth(1.0);
    doc.line(400, y + 170, 400, y + 270);
    if (Invoicedata.custgstin !== "" && Invoicedata.custgstin !== undefined) {
      y = y - 10;
    }
    doc.text("Credit Note No", 410, y + 205);
    doc.text(`-`, 470, y + 205);
    doc.text(`${creditnote.id}`, 480, y + 205);
    doc.text(`Issue Date`, 410, y + 215);
    doc.text(`-`, 470, y + 215);
    doc.text(`${date}`, 480, y + 215);

    if (Invoicedata.custgstin !== "" && Invoicedata.custgstin !== undefined) {
      y = y + 10;
    }
    doc.setLineWidth(1.0);
    doc.line(10, y + 270, 585, y + 270);

    // doc.text(`Products`, 20, y + 280);
    autoTable(doc, {
      theme: "grid",
      startY: y + 290,
      margin: 20,
      didDrawPage: (HookData) => {
        HookData.settings.margin.top = 180;
      },
      styles: { textColor: [0, 0, 0], fontSize: "8" },
      headStyles: {
        fillColor: [256, 256, 256],
        textColor: [0, 0, 0],
        fontStyle: "default",
      },
      columnStyles: {
        1: { halign: "right" },
        3: {
          halign:
            Invoicedata.taxtype === "GST" &&
            ccountry === "India" &&
            Invoicedata.gsttype === "INTRASTATE"
              ? "left"
              : "right",
        },
        4: { halign: "right" },
        5: { halign: "right" },
      },
      body: data,
      columns: columns,
    });
    y = doc.autoTable.previous.finalY + 10;

    doc.text(`Total (${Invoicedata.currency}) :`, 490, y + 10, "right");
    doc.text(`${totawtax}`, 570, y + 10, "right");

    doc.setLineWidth(1.0);
    doc.line(10, y + 20, 585, y + 20);
    doc.text(`Issued by, signature`, 570, y + 35, "right");

    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      const pageSize = doc.internal.pageSize;
      const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      const footer = `Page ${i} of ${pageCount}`;
      doc.line(10, 50, 585, 50);
      doc.line(10, pageHeight - 40, 585, pageHeight - 40);
      doc.line(10, 50, 10, pageHeight - 40);
      doc.line(pageWidth - 10, 50, pageWidth - 10, pageHeight - 40);
      doc.setFontSize(8);
      doc.text(`${curdate}`, 20, 60);
      doc.addImage(
        logo,
        "GIF",
        pageWidth - 100 - logowidth,
        60,
        80 + logowidth,
        80
      );
      doc.setFontSize(12);
      doc.text(`${clientname}`, 20, 75);
      doc.setFontSize(8);
      doc.text(`${address}`, 20, 85);
      doc.text(`${address2}`, 20, 95);
      doc.text(`${ccity}, ${cstate}`, 20, 105);
      doc.text(`${ccountry} - ${czip}`, 20, 115);
      doc.text(`${companyemail}`, 20, 125);
      doc.text(`${contactnumber}`, 20, 135);
      doc.text(`${pan}`, 20, 145);
      let yax = 145;
      // if (
      //   licenses !== null &&
      //   licenses !== undefined &&
      //   licenses !== ""
      // ) {
      //   yax = 155;
      //   licenses.map((e, idx) => {
      //     yax = yax + idx * 10;
      //     doc.text(`${e.name} - ${e.number}`, 20, yax);
      //   });
      // }
      doc.setLineWidth(1.0);
      doc.line(10, yax + 5, 585, yax + 5);
      doc.setFontSize(10);
      doc.text(`GSTIN | ${gstin}`, 20, yax + 20);
      doc.setFontSize(12);
      doc.text(`Credit Note`, 230, yax + 20);
      // if (order === "TAX INVOICE") {
      //   doc.setFontSize(8);
      //   doc.text(`Original Invoice`, 500, yax + 20);
      // }
      doc.setFontSize(8);
      doc.setLineWidth(1.0);
      doc.line(10, yax + 30, 585, yax + 30);
      doc.setFontSize(8);
      doc.text(
        footer,
        pageWidth / 2 - doc.getTextWidth(footer) / 2,
        pageHeight - 25,
        { baseline: "bottom" }
      );
    }
    if (type === "pdf") {
      doc.autoPrint({ variant: "non-conform" });
      doc.save(`${creditnote.id}.pdf`);
    }
  };

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <span
                as="button"
                className=" text-secondary cursorPointer"
                onClick={() => props.navigate(-1)}
              >
                ❮ Back &nbsp;&nbsp;
              </span>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/inventory">Home</Link>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/viewsalesinvoice">Invoice</Link>
            </li>
            <li className="breadcrumb-item active">Customer Credit Note</li>
          </ol>
          <div className="card-header">
            <h3 className="card-title">Customer Credit Note</h3>
            <button
              className="btn btn-sm hovbuttonColour shadow-sm float-right text-md ml-1"
              onClick={() => handleDownload("mail")}
            >
              <i className="fa fa-envelope"></i>
            </button>
            <button
              className="btn btn-sm hovbuttonColour shadow-sm float-right text-md ml-1"
              onClick={() => handleDownload("pdf")}
            >
              <i className="fa fa-file-invoice-dollar"></i>
            </button>
          </div>
          <div className="card-body">
            <div className="form-row">
              {creditnote.irn !== null &&
              creditnote.irn !== "" &&
              creditnote.irn !== undefined ? (
                <>
                  <div className="form-column col-md-8">
                    <table className="float-left pb-3 col-md-12">
                      <tbody>
                        <tr>
                          <td>IRN</td>
                          <td>
                            :&nbsp;
                            {creditnote.irn}
                          </td>
                        </tr>
                        <tr>
                          <td>Ack no</td>
                          <td>
                            :&nbsp;
                            {creditnote.ackno}
                          </td>
                        </tr>
                        <tr>
                          <td>Ack Date</td>
                          <td>
                            :&nbsp;
                            {moment(creditnote.ackDt).format(
                              "DD-MM-yyyy HH:mm"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="form-column col-md-4">
                    <img
                      src={QRcode}
                      alt="qrcode"
                      className="d-block mx-auto"
                      width="180"
                      height="100%"
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
              <div className="form-column col-md-6">
                <table className="float-left mb-3">
                  <tbody>
                    <tr>
                      <td>Credit Note no</td>
                      <td>
                        :&nbsp;&nbsp;
                        {Invoicedata?.einvid === null ||
                        Invoicedata?.einvid === undefined
                          ? Invoicedata?.invid
                          : Invoicedata?.einvid}
                      </td>
                    </tr>
                    <tr>
                      <td>Invoice Id</td>
                      <td>
                        :&nbsp;&nbsp;
                        {Invoicedata?.einvid === null ||
                        Invoicedata?.einvid === undefined
                          ? Invoicedata?.invid
                          : Invoicedata?.einvid}
                      </td>
                    </tr>
                    <tr>
                      <td>Detl. Invoice Id</td>
                      <td>
                        :&nbsp;&nbsp;
                        {Invoicedata?.custominvid}
                      </td>
                    </tr>
                    <tr>
                      <td>Created By</td>
                      <td>
                        :&nbsp;&nbsp;
                        {Invoicedata?.createdbyname}
                      </td>
                    </tr>
                    <tr>
                      <td>Customer</td>
                      <td data-tip data-for="custphno">
                        :&nbsp;&nbsp;
                        {Invoicedata?.customer}
                      </td>
                    </tr>
                    <tr>
                      <td>Invoice&nbsp;Date</td>
                      <td>
                        :&nbsp;&nbsp;
                        {moment(Invoicedata?.createdAt).format("DD-MM-yyyy")}
                      </td>
                    </tr>
                    <tr>
                      <td>Total&nbsp;Amount&nbsp;</td>
                      <td>
                        :&nbsp;&nbsp;
                        <NumericFormat
                          className="pr-3"
                          displayType={"text"}
                          value={Invoicedata?.totalinvoicewtax}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          disabled={true}
                          thousandSeparator={true}
                          thousandsGroupStyle={thousandsdisplay}
                        ></NumericFormat>
                      </td>
                    </tr>

                    <tr>
                      <td>Tax&nbsp;Type</td>
                      <td>
                        :&nbsp;&nbsp;
                        {Invoicedata?.taxtype}
                        {Invoicedata?.taxtype === "GST"
                          ? ` (${Invoicedata?.gsttype})`
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Type</td>
                      <td>
                        :&nbsp;&nbsp;
                        {creditnote?.type}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="form-group col-md-3">
                <table className="float-left">
                  <tbody>
                    <tr>
                      <td>Customer Details </td>
                    </tr>
                    <tr>
                      <td>
                        &nbsp;
                        {Invoicedata?.customer}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        &nbsp;
                        {Invoicedata?.custadress}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        &nbsp;
                        {Invoicedata?.cityname}
                        ,&nbsp;{Invoicedata?.state}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        &nbsp;{Invoicedata?.country} - {Invoicedata?.zip}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        &nbsp;
                        {Invoicedata?.custmail}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        &nbsp;
                        {Invoicedata?.custphonecode}-{Invoicedata?.custphno}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {creditnoteitems.length > 0 ? (
              props.location.state.creditnote.type === "Pricediff" ? (
                <TableContainer>
                  <Table className="table-sm border" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        {/* <TableCell>Quantity</TableCell> */}
                        <TableCell>Amount</TableCell>
                        <TableCell>Tax Amount</TableCell>
                        <TableCell>Total Amount</TableCell>
                        <TableCell>Description</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {creditnoteitems.map((e, i) => (
                        <TableRow key={i}>
                          <TableCell>{e.name || e.natureofcharge}</TableCell>
                          {/* <TableCell>
                          {e.quantity} {e.uom}
                        </TableCell> */}
                          <TableCell>
                            {creditnote.totalamount.toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {creditnote.taxamount.toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {creditnote.totalamountwtax.toFixed(2)} (
                            {creditnote.tax}%)
                          </TableCell>
                          <TableCell>{creditnote.description}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <TableContainer>
                  <Table className="table-sm border" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Item Name</TableCell>
                        <TableCell>HSN</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell>UOM</TableCell>
                        <TableCell>TotalPrice</TableCell>
                        <TableCell>Tax</TableCell>
                        <TableCell>TotalPrice(Inc tax)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {creditnoteitems.map((data, index) => (
                        <TableRow key={index}>
                          <TableCell>{data.id}</TableCell>
                          <TableCell>{data.name}</TableCell>
                          <TableCell>{data.hsn}</TableCell>
                          <TableCell className="text-right">
                            <NumericFormat
                              className="pr-3"
                              displayType={"text"}
                              value={data.price}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              disabled={true}
                              thousandSeparator={true}
                              thousandsGroupStyle={thousandsdisplay}
                            ></NumericFormat>
                          </TableCell>
                          <TableCell>{data.returnquantity}</TableCell>
                          <TableCell>{data.uom}</TableCell>
                          <TableCell className="text-right">
                            <NumericFormat
                              className="pr-3"
                              displayType={"text"}
                              value={data.totalprice}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              disabled={true}
                              thousandSeparator={true}
                              thousandsGroupStyle={thousandsdisplay}
                            ></NumericFormat>
                          </TableCell>
                          <TableCell>
                            {data.taxamount}({data.tax}%)
                          </TableCell>
                          <TableCell className="text-right">
                            <NumericFormat
                              className="pr-3"
                              displayType={"text"}
                              value={data.totalpricewtax}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              disabled={true}
                              thousandSeparator={true}
                              thousandsGroupStyle={thousandsdisplay}
                            ></NumericFormat>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            ) : (
              <TableContainer>
                  <Table  className="table-sm border" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        {/* <TableCell>Quantity</TableCell> */}
                        <TableCell>Amount</TableCell>
                        <TableCell>Tax Amount</TableCell>
                        <TableCell>Total Amount</TableCell>
                        <TableCell>Description</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                          <TableCell>Insurance</TableCell>
                          <TableCell>
                            {creditnote?.totalamount?.toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {creditnote?.taxamount?.toFixed(2)}
                          </TableCell>
                          <TableCell>
                            {creditnote?.totalamountwtax?.toFixed(2)} (
                            {creditnote?.tax}%)
                          </TableCell>
                          <TableCell>{creditnote?.description}</TableCell>
                        </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(Salescreditnote);
