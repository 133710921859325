import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

export let currentTenant

class CategoryService{
    getCategory(currentTenant) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallcategories/${currentTenant}`)
     }

     getNaCategory(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getnacategories/${currentTenant}`)
   }

     
     addCategory(addcategory){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addcategory`,addcategory)
     }
     deleteCategory(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletecategory/${id}`)
     }
     addCategorylvl2(addcategory){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addcategorylvl2`,addcategory)
     }
     
     getCategorylvl2(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallcategorieslvl2/${currentTenant}`)
      }
   getCategorylvl2ById(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getcategorylvl2/${id}`)
   }

   deleteCategorylvl2(id) {
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletecategorylvl2/${id}`)
   }


   getMainCategory(currentTenant) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallmaincategories/${currentTenant}`)
   }

   getMainCategoryById(id) {
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getmaincategory/${id}`)
   }

   addMainCategory(addcategory){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addmaincategory`,addcategory)
   }

   deleteMainCategory(id) {
      return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletemaincategory/${id}`)
   }
}
export default new CategoryService()