import axios from "axios";
import { Auth_Proto, Auth_Domain, Auth_Port } from "../../Component/consts";

class WorkCenterServices {
  getWorkCenter(currentTenant) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallwcoftenant/${currentTenant}`
    );
  }
  addWorkCenter(data) {
    return axios.post(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addworkcentre`,
      data
    );
  }

  deleteWorkCenter(id) {
    return axios.delete(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletewc/${id}`
    );
  }

  WorkCenterbywarehouse(id) {
    return axios.get(
      `${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getwcdatabywh/${id}`
    );
  }
}
export default new WorkCenterServices();
