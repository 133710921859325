import React, { Component } from 'react'
import { Formik, Form } from "formik";
import withRouter from '../withRouter';
import {NumericFormat} from 'react-number-format';
import { currentTenant, currentUser, thousandsdisplay, userid } from '../Common/Credentials';
import { ThemeProvider,createTheme, Backdrop, CircularProgress} from '@mui/material';
import moment from 'moment';
import NewJournalService from './NewJournalService';
import { Link } from 'react-router-dom';
import AuthenticationService from '../Authentication/AuthenticationService';
import { Spinner } from 'react-bootstrap';
import Header from '../Component/Header';
import Menu from '../Component/Menu';
import { spin } from '../Common/NewLoader';
import Footer from '../Component/Footer';
import MUIDataTable from 'mui-datatables';
import { right } from '@popperjs/core';
import { Autocomplete } from '@mui/material';
import { FormikTextField } from '../CommonComponents/FormField';
import Swal from 'sweetalert2';

// const getTotal = (totamt) => {
//   let total = 0;
//   totamt.forEach(totamt => {
//     total += totamt;
//   });
//   return total;
// };
let genpreceipts=null,invreceipts=null

const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
        border: [[1, "solid", "#ece6e6"]],
      },
      body: {
        border: [[1, "solid", "#ece6e6"]],
      },
    },
  },
});

class AccountReceivables extends Component {
  constructor(props) {
    super(props)

    this.state = {
      pendingReceipts:[],
      selectedSo:[],
      selectedInv:[],
      suppadvances:[],
      selectbtn:[
        {id:1,name:"SO Receipts"},
        {id:3,name:"Direct Inv Receipts"},
        {id:2,name:"Non SO Receipts"},
        {id:4,name:"Supplier Advances"}],
        selectedoption:'',
        loader:false,
        loader1:true,
        jvinsertiontype:0,
        new1:[],

      }
    this.validate=this.validate.bind(this);this.onSubmit=this.onSubmit.bind(this);
    // this.selectFile=this.selectFile.bind(this);this.upload=this.upload.bind(this);
    // this.CheckBoxSelect=this.CheckBoxSelect.bind(this);this.handleAdvpaid=this.handleAdvpaid.bind(this)
    this.loadData=this.loadData.bind(this);
    this.onSoSelect=this.onSoSelect.bind(this)
    this.setReceiptdate=this.setReceiptdate.bind(this)
    }

    onSubmit(values){
      this.setState({loader:true})
     
    }

    componentDidMount(){

      if (userid === undefined) {
        window.confirm("Your Session is Lost: Please login Back to resume");
        AuthenticationService.logout(currentUser);
      }
      this.loadData()
      
     
      
        this.setState({loading:false})
       }

   
       onChange(option){
        this.setState({selectedoption: option.id});
      }

    loadData(){
    
      NewJournalService.getAllSuppAdvances(currentTenant)
      .then(response =>{
        this.setState({suppadvances:response.data})  
      })
      NewJournalService.getPendingReceipts(currentTenant)
      .then(response =>{
        this.setState({pendingReceipts:response.data},()=>this.setReceiptdate())  
      })
      
      .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
    })

    }

    setReceiptdate(){
      let soids=null,preceipts=null,newblank=[]
      // 
       preceipts=this.state.pendingReceipts.filter((e)=>e.soid!==null  && e.pendingamount!==0).sort((a,b)=> a.createdAt<b.createdAt ? 1:-1)
      genpreceipts=this.state.pendingReceipts.filter((e)=>e.soid===null && e.custominvid===null && e.pendingamount!==0).sort((a,b)=> a.createdAt<b.createdAt ? 1:-1)
      invreceipts=this.state.pendingReceipts.filter((e)=>e.soid===null && e.custominvid!==null && e.pendingamount!==0).sort((a,b)=> a.createdAt<b.createdAt ? 1:-1)

      let soids1=preceipts.map((e)=>e.customsoid)
      soids=[...new Set(
        soids1.filter((value, index, self) => self.indexOf(value) === index && value!==null))]        
        soids.map((e)=>
        newblank.push({id:preceipts.find((f)=>e===f.customsoid).customsoid,customer:preceipts.find((f)=>e===f.customsoid)?.customer})
        )
        this.setState({new1:newblank})
        
        this.setState({loader1:false})
    }

     validate(values){
      
     }

     onSoSelect(values,value){
      // console.log(values)
      if(values!==undefined && values!==null &&  typeof values=="object"){
        let so,advamount,inv
        so=this.state.pendingReceipts.filter(e=>e.customsoid===values.id )
        inv=this.state.pendingReceipts.filter(e=>e.customsoid===null  &&  e.custominvid!==null )
        this.setState({selectedSo:so,selectedInv:inv})
      }else{
        this.setState({selectedSo:[],selectedInv:[]})

      }
     }
     submitRecordSettle(value){
      Swal.fire({
        title: "Information",
        text: "This action is for final close of differences and not for regular transactions.Do you want to Continue ?",
        icon: "info",
        confirmButtonText: "Ok",
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonColor: "#152f5f",
      }).then((result) => {
        if (result.value === true) {
          let pendingso,frompath
          if(value!==undefined){
          pendingso=this.state.pendingReceipts.filter(e=>e.id===value.rowData[0])
          pendingso=pendingso.map((e)=> Object.assign(e,{frompath:this.props.location.pathname}))
          } 
          this.props.navigate({pathname:`/settlements`},{state:pendingso});
          }else{
          this.setState({loader:false})
         // this.goBack()
          }
        })
      }

     submitRecord(value){
      let pendingso
      if(value!==undefined){
      pendingso=this.state.pendingReceipts.filter(e=>e.id===value.rowData[0])
      } 
     // pendingso=

      this.props.navigate({pathname:`/are`}, {state:pendingso});


      // if(id!==undefined ){
      // let pendingso 
      // pendingso= this.state.pendingReceipts.filter((e)=>e.id===id)
      // this.props.history.push(`/are`, {pendingso});

      // }
     }
   
    render() {

      

        // console.log(new1)
      const prendingreceipts = [
        {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "customer",label: "Customer",options: {filter: true,sort: true}},
        {name: "custominvid",label: "Invoice",options: {filter: false,sort: true,}},
        {name: "createdAt",label: "Date",options: {filter: false,sort: true, sortOrder: 'asc',
          customBodyRender:(tableMeta)=>{
            return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
          }}},
        {name: "soamount",label:"SO Amt",options: {filter: false,sort: true,align:right,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        {name: "invoiceamt",label:"Inv Amt",options: {filter: false,sort: true,align:right,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        {name: "soadvance",label: "AdvanceAmt",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              value!==0?
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>:"NA"
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        {name: "advancerecd",label: "advancerecd",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "openadjusted",label: "adjusted",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "dcadjusted",label: "Receipts/Adjustments",options: {filter: false,sort: true,
          customBodyRender: (value,tableMeta) => {
            return (
              // value!==null?
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta.rowData[7]+tableMeta.rowData[8]+tableMeta.rowData[9]}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
             );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        {name: "transpendingamount",label: "Pending",options: {filter: false,sort: true,
          customBodyRender: (value,tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                // value={tableMeta.rowData[5]-tableMeta.rowData[9]}
                value={tableMeta.rowData[5]===value && tableMeta.rowData[9]!==null?tableMeta.rowData[5]-tableMeta.rowData[9]:value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        


        {
          name: "soadvance",
          
          label: "Advance",
          options: {
            filter: false,
            sort: false,
            empty: true,
           
            customBodyRender: (value, tableMeta) => {
            //  console.log(tableMeta.tableData.map((e)=>e.sitems))
            // console.log(tableMeta.tableData[10].sitems)

      return (
        value!==0 ?<button className="hovbuttonColour btn btn-sm"
        onClick={() => this.submitRecord(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Receipt"}
        </button>:<span className="text-red text-bold">No Pending</span>

              );
              
            },
          
            setCellProps: () => ({ style: { textAlign: "center" } } ),
          },
          
        },

        {name: "sitems",label: "Pendings",options: {filter: false,sort: false,display:true,viewColumns:false,
        
          customBodyRender:(value,tableMeta)=>{
            return (
              value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
              onClick={() => this.submitRecord(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Receipt"}
              </button>:<span className="text-red text-bold">No Pending</span>
            );      

          }
        
        }},
        {name: "ssold",label: "Servcice Pendings",options: {filter: false,sort: false,display:true,viewColumns:false,
        
          customBodyRender:(value,tableMeta)=>{
            return (
              value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
              onClick={() => this.submitRecord(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Receipt"}
              </button>:<span className="text-red text-bold">No Pending</span>
            );      

          }
        
        }},
        {name: "sitems",label: "Close",options: {filter: false,sort: false,display:true,viewColumns:false,
        
          customBodyRender:(value,tableMeta)=>{
            return (
              value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
              onClick={() => this.submitRecordSettle(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Settle"}
              </button>:<span className="text-red text-bold">No Pending</span>
            );      

          }
        
        }},
      ]

      const Pendingptions = {
        filterType: 'checkbox',
        selectableRows: "none",
      }

      const invprendingreceipts = [
        {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "customer",label: "Customer",options: {filter: true,sort: true}},
        {name: "custominvid",label: "Invoice",options: {filter: false,sort: true,}},
        {name: "createdAt",label: "Date",options: {filter: false,sort: true, sortOrder: 'asc',
          customBodyRender:(tableMeta)=>{
            return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
          }}},
        {name: "soamount",label:"SO Amt",options: {filter: false,sort: true,align:right,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        {name: "invoiceamt",label:"Inv Amt",options: {filter: false,sort: true,align:right,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        {name: "soadvance",label: "AdvanceAmt",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              value!==0?
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>:"NA"
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        {name: "advancerecd",label: "advancerecd",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "openadjusted",label: "adjusted",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "dcadjusted",label: "Receipts/Adjustments",options: {filter: false,sort: true,
          customBodyRender: (value,tableMeta) => {
            return (
              // value!==null?
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                value={tableMeta.rowData[7]+tableMeta.rowData[8]+tableMeta.rowData[9]}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
             );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        {name: "transpendingamount",label: "Pending",options: {filter: false,sort: true,
          customBodyRender: (value,tableMeta) => {
            return (
              <NumericFormat
                className="pr-3"
                displayType={"text"}
                // value={tableMeta.rowData[5]-tableMeta.rowData[9]}
                value={tableMeta.rowData[5]===value && tableMeta.rowData[9]!==null?tableMeta.rowData[5]-tableMeta.rowData[9]:value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        


      //   {
      //     name: "soadvance",
          
      //     label: "Advance",
      //     options: {
      //       filter: false,
      //       sort: false,
      //       empty: true,
           
      //       customBodyRender: (value, tableMeta) => {
      //       //  console.log(tableMeta.tableData.map((e)=>e.sitems))
      //       // console.log(tableMeta.tableData[10].sitems)

      // return (
      //   value!==0 ?<button className="hovbuttonColour btn btn-sm"
      //   onClick={() => this.submitRecord(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Receipt"}
      //   </button>:<span className="text-red text-bold">No Pending</span>

      //         );
              
      //       },
          
      //       setCellProps: () => ({ style: { textAlign: "center" } } ),
      //     },
          
      //   },

        {name: "sitems",label: "Pendings",options: {filter: false,sort: false,display:true,viewColumns:false,
        
          customBodyRender:(value,tableMeta)=>{
            return (
              value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
              onClick={() => this.submitRecord(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Receipt"}
              </button>:<span className="text-red text-bold">No Pending</span>
            );      

          }
        
        }},
        {name: "ssold",label: "Servcice Pendings",options: {filter: false,sort: false,display:true,viewColumns:false,
        
          customBodyRender:(value,tableMeta)=>{
            return (
              value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
              onClick={() => this.submitRecord(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Receipt"}
              </button>:<span className="text-red text-bold">No Pending</span>
            );      

          }
        
        }},
      ]

      const InvPendingptions = {
        filterType: 'checkbox',
        selectableRows: "none",
      }

      const suppadvances1= [
        {name: "createdAt",label:"Date",options: {filter: false,sort: true,sortOrder: 'asc',
          customBodyRender:(tableMeta)=>{
            return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
          }}},
        // {name: "id",label:"Ledger ID" ,options: {filter: false,sort: false}},
        {name: "name",label:"Party",options: {filter: true,sort: true}},
        // {name: "paymentfor",label:"Expense For",options: {filter: false,sort: true,viewColumns:false}},
          {name: "genadvancecurrent",label:"Pending Advance",options: {filter: false,sort: true,
            customBodyRender: (value) => {
              return (
                
                <NumericFormat className="pr-3" displayType={"text"} value={value} decimalScale={2} fixedDecimalScale={true}
                disabled={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay} ></NumericFormat>
              );
            },
            setCellProps: () => ({ style: { textAlign:'right'}}),
          }},
         
      
          // {name: "id",label:"Action",options: {filter: false,sort: false,display:true,viewColumns:false,
        
          //   customBodyRender:(value,tableMeta)=>{
          //     return (
          //       value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
          //       onClick={() => this.submitpnpRecord(tableMeta.rowData[0])}>  {this.loaderFunction.a===false? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"pay"}
          //       </button>:<span className="text-red text-bold">No Invoices</span>
          //     );      
      
          //   }
          
          // }},
      ]
      const advoptions = {
        filterType: 'checkbox',
        selectableRows: "none",
        // responsive: "scroll",
        resizableColumns: true,
        
      }



      const npprendingreceipts = [
        {name: "id",label: "Id",options: {filter: false,sort: false,display:false,viewColumns:false}},
        {name: "customer",label: "From",options: {filter: true,sort: true}},
        {name: "createdAt",label: "Date",options: {filter: false,sort: true, sortOrder: 'asc',
          customBodyRender:(tableMeta)=>{
            return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
          }}},
     
        {name: "pendingamount",label: "Amount",options: {filter: false,sort: true,
          customBodyRender: (value) => {
            return (
              <NumericFormat
                className="pr-3 align-center"
                displayType={"text"}
                value={value}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled={true}
                thousandSeparator={true}
                thousandsGroupStyle={thousandsdisplay}
              ></NumericFormat>
            );
          },
          setCellProps: () => ({ style: { textAlign:'right'}}),
        }},
        {name: "pendingamount",label: "Pendings",options: {filter: false,sort: false,display:true,viewColumns:false,
          customBodyRender:(value,tableMeta)=>{
            return (
              value.length!==0 ?<button className="hovbuttonColour btn btn-sm"
              onClick={() => this.submitRecord(tableMeta)}>  {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Receipt"}
              </button>:<span className="text-red text-bold">No Pending</span>
            );   
          },
          setCellProps: () => ({ style: { textAlign:'center'}}),   
        }},
      ]
      const npPendingptions = {
        filterType: 'checkbox',
        selectableRows: "none",
        resizableColumns: true,
      }


      // preceipts=this.state.pendingReceipts.filter((e)=>e.poid!==null && e.transpendingamount!==0 && e.poadvance===0)
      // if(this.state.pendingReceipts.filter((e)=>e.poid!==null && e.transpendingamount!==0 &&e.poadvance!==0 )){
      //   type="Purchase Advance"
      //   advanaceamt=this.state.pendingReceipts.map((e)=>e.poadvance )
      //   advancepayments=this.state.pendingReceipts.filter((e)=>e.poid!==null && e.transpendingamount!==0 &&e.poadvance!==0 )
      // }else if(this.state.pendingReceipts.filter((e)=>e.poid!==null && e.transpendingamount!==0 && e.poadvance===0 )){
      //   type="Purchase Amt"
      //   // advanaceamt=this.state.pendingReceipts.map((e)=>e.poadvance )
      //   preceipts=this.state.pendingReceipts.filter((e)=>e.poid!==null && e.transpendingamount!==0 &&e.poadvance===0 )

      // }
      // console.log(this.state.pendingReceipts)

        return (
         <>
          <Header />
<Menu/>
            <div>
            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader1}>
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className="content-wrapper">

<div className="card card-info">
  <ol className="breadcrumb float-sm-right">
    <li className="breadcrumb-item text-secondary">
      <Link to="/accounts">Home</Link>
    </li>
    <li className="breadcrumb-item text-secondary">
    <Link to={{pathname: '/ar'}} state= {{message:''}} >
                Accounts Receivable
              </Link>
    </li>
  </ol>

  <div className="text-center text-danger">{this.state.error}</div>
  <div className="card-body">
  <div className="text-center">
  {this.state.loading===true?spin:''}
  </div>
              
        
    <Formik
      initialValues={{ }}
      onSubmit={this.onSubmit}
      validateOnChange={true}
      validate={this.validate}
      enableReinitialize={true}>

{({ values, setFieldValue }) => (

        <Form autoComplete="off">
{/* <fieldset className="form-group col-md-12 mt-0 mb-5">
  <FastField name="solist" validate={(value) => value === null ||  value === undefined ||
   value === "" || value.length===0 ? "Please Select a valid SO"                           
   : undefined }>{({ meta }) => 
  <Autocomplete id="solist"className="so-select" name="solist" options={this.state.pendingReceipts}
  // groupBy={(option) =>  option.ledgroupname!==null?option.subname + " - " +option.ledgroupname:option.subname} 
  getOptionLabel={(option) => option.id}
  renderOption={(option) =>option.id} 
  value={values.solist? undefined: undefined} onChange={(e, value) => {
  setFieldValue("solist",value); }}
  renderInput={(params) => (
  <FormikTextField {...params} type="text" name="solist" label="Select SO"
  className="form-control" error={ meta.touched && meta.error} size='small'
  variant="standard" fullWidth />)} /> }
  </FastField></fieldset> */}

{this.state.selectbtn.map((option)=>{
return <label key={option.id} >

    <div className="text-sm col-md-12">
      <div className="form-group col-md-12">
      {/* <div className="form-control bg-light border-light"> */}

             <input 
                className={
                  this.state.selectedoption === option.id
                    ? "btn buttonColour text-yellow text-bold col-md-12"
                    : "btn hovbuttonColour text-white col-md-12"
                }
                type="button" 
                // checked={this.state.checked === option.id ? true : false} 
                key={option.id}
                onClick={this.onChange.bind(this,option)} 
               style={{marginRight: 4,width:250}}
                value={option.name} 
                />
            {/* {option.name} */}
        
            </div></div>
            {/* </div> */}
        </label>
       

})
}
{this.state.selectedoption===1?<>

  <fieldset className="form-group col-md-4 mt-0 mb-3">
<Autocomplete id="solist" className="so-select " name="solist" options={this.state.new1} getOptionLabel={(option) => option.id + " ( "+option.customer+" ) "}
 // isOptionEqualToValue ={(option) => option.id}
value={values.solist? undefined: undefined} onChange={(e, value) => {setFieldValue("solist", value) && this.onSoSelect(value)}}
renderInput={(params) => (
<FormikTextField {...params} className="form-control mb-3" type="text" name="solist" label="Select SO" 
  // onBlur={(e) => {this.onAeChange(e.target.value, values)}}
//  onBlur={(e) => {this.onSoSelect(values)}} 
  variant="standard" fullWidth/>)}/></fieldset>


<div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`SO Receipts`}
                            data={this.state.selectedSo}
                            columns={prendingreceipts}
                            options={Pendingptions}
                          />
                        </ThemeProvider>
                      </div>
                      </>
                      :this.state.selectedoption===2?
                      <div className="mb-12">
                                     <ThemeProvider theme={theme}>
                                         <MUIDataTable
                                           className="table-sm px-3"
                                           title={`Non SO Receipts`}
                                           data={genpreceipts}
                                           columns={npprendingreceipts}
                                           options={npPendingptions}
                                         />
                                       </ThemeProvider>
                                     </div>
                      :this.state.selectedoption===3?
                      <div className="mb-12">
                      <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={`Direct Invoice Receipts`}
                            data={invreceipts}
                            columns={invprendingreceipts}
                            options={InvPendingptions}
                          />
                        </ThemeProvider>
                      </div>:this.state.selectedoption===4?
                      <div className="mb-12">
                                     <ThemeProvider theme={theme}>
                                         <MUIDataTable
                                          className="table-sm px-3"
                                          title={"Supplier Direct Advances"}
                                          data={this.state.suppadvances}
                                          columns={suppadvances1}
                                          options={advoptions}
                                         />
                                       </ThemeProvider>
                                     </div>:""}

{/* <div className="form-row mt-0 mb-0"> */}


 {/* <fieldset className="form-group col-md-12 align-right">
 <span className=" align-center text-green">SO Receipts</span>
 <TableContainer >
 <Table className="table-sm" aria-label="customized table" responsive>
   <TableHead><TableRow>
  <TableCell className="col-md-1 text-sm text-left">Customer</TableCell>
 <TableCell className="col-md-1 text-sm text-left">SO ID</TableCell>
 <TableCell className="col-md-2 text-sm text-left">Date</TableCell>
 <TableCell className="col-md-1 text-sm text-center">SO Amt</TableCell>
 <TableCell className="col-md-1 text-sm text-center">AdvanceAmt</TableCell>
 <TableCell className="col-md-1 text-sm text-center">Receipts</TableCell>
 <TableCell className="col-md-1 text-sm text-center">Pending</TableCell>
 <TableCell className="col-md-1 text-sm">Type</TableCell>
 
 <TableCell>Action</TableCell>
   </TableRow></TableHead>
      <TableBody>
        {preceipts.length!==0?
  preceipts.map((e)=>
    <TableRow>
 <TableCell  className="col-md-1 text-sm text-left">{e.customer}</TableCell>
  <TableCell className="col-md-1 text-sm text-left">{e.customsoid}</TableCell>
  <TableCell className="col-md-2 text-sm text-center">{moment(e.createdAt).format('DD-MM-YYYY HH:MM')}</TableCell>
  <TableCell className="col-md-1 text-sm text-right">
  {<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {e.soamount} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</TableCell>
  
  <TableCell className="col-md-1 text-sm text-right">
  {<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {e.soadvance!==0?e.soadvance:0} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</TableCell>
  <TableCell className="col-md-1 text-sm text-right">
  {<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {e.receiptamount!==0?e.receiptamount:0} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>}</TableCell>
  <TableCell className="col-md-1 text-sm text-right">
  {<NumericFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} disabled ={true} 
 value = {e.pendingamount!==0?e.pendingamount:0} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}>
</NumericFormat>} </TableCell>
  <TableCell className="col-md-1 text-sm">{e.soadvance!==0?"Sales Advance":"Sales"}</TableCell>
  <TableCell className="col-md-1 text-sm">{e.soadvance!==0 || e.sitems.length!==0 ? <button className="btn btn-info btn-sm" type="Submit" onClick={() => this.submitRecord(e.id)}> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Receipt"}</button>:<span className="text-red">No Invoice</span>}</TableCell>
  </TableRow>):<span className=" align-center text-green">No Receipts Pending</span>}  
  </TableBody>
 </Table>
 </TableContainer>
 <br></br>
 </fieldset> */}

 {/* <fieldset className="form-group col-md-12"><TableContainer >
 <span className=" align-center text-green">NON SO Receipts</span>

   <Table className="table-sm" aria-label="customized table" responsive>
<TableHead className="text-bold">
 <TableRow style={{ textAlign: "center" }}>
<TableCell>PMT ID</TableCell><TableCell>From</TableCell><TableCell>Date</TableCell><TableCell>Amount</TableCell> <TableCell>Action</TableCell>
</TableRow></TableHead>
<TableBody>
{genpreceipts.map((e)=>(
<TableRow key={e.id} style={{ textAlign: "center" }}><TableCell>{e.id}</TableCell><TableCell>{e.customer}</TableCell>
<TableCell>{moment(e.updatedAt).format('DD-MM-YYYY HH:MM')}</TableCell><TableCell>{e.pendingamount}</TableCell>
<TableCell> <button className="btn btn-info btn-sm" type="Submit" onClick={() => this.submitRecord(e.id)}> {this.state.loader===true? <><Spinner animation="border" size="sm" />&nbsp;{"Submitting"}</>:"Pay"}</button></TableCell>
</TableRow>))}</TableBody>
</Table></TableContainer></fieldset> */}

{/* </div> */}



</Form>)} 
 </Formik>
 </div>
 </div>
 </div>
 <Footer/>     

 </div>
 </>
)
}
}

export default withRouter(AccountReceivables);              



 
