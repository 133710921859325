import React, { Component } from "react";
import { Link } from "react-router-dom";
import Credentials, {
  currentTenant,
  currentUser,
  logo,
  roles,
  selectedMenu,
  userid,
} from "../Common/Credentials";
import {
  accounts,
  appmaster,
  assetmanagement,
  inventory,
  production,
  purchase,
  sales,
  salestravels,
  screens,
} from "../Common/Products";
import { loadTree } from "../Component/MenuTreeHelper";
import defaultlogo from "../images/no-logo.png";
import finanzz from "../images/BLUE FEV.png";
import SettingsServices from "../Settings/SettingsServices";
import UsercustomerService from "../Masters/Usercustomers/UsercustomerService";

import dashboardIcon from "../images/Icons/dashboard.png"
import activedashboardIcon from "../images/Icons/dashboardactive.png"
import accountingIcom from "../images/Icons/accounting.png"
import activeaccountingIcom from "../images/Icons/accountingactive.png"
import mastersIcon from "../images/Icons/generic.png"
import activemastersIcon from "../images/Icons/genericactive.png"
import assetIcon from "../images/Icons/assets.png"
import activeassetIcon from "../images/Icons/assetsactive.png"
import inventoryIcon from "../images/Icons/warehouse.png"
import activeinventoryIcon from "../images/Icons/warehouseactive.png"
import salesIcon from "../images/Icons/swipe-card.png"
import activesalesIcon from "../images/Icons/swipe-cardactive.png"
import travelInvoicesIcon from "../images/Icons/paper-plane.png"
import activetravelInvoicesIcon from "../images/Icons/paper-planeactive.png"
import purchaseIcon from "../images/Icons/buy.png"
import activepurchaseIcon from "../images/Icons/buyactive.png"
import productionIcon from "../images/Icons/production.png"
import activeproductionIcon from "../images/Icons/productionactive.png"
import superadminIcon from "../images/Icons/management.png"
import activesuperadminIcon from "../images/Icons/managementactive.png"
import settingsIcon from "../images/Icons/setting.png"
import activesettingsIcon from "../images/Icons/settingactive.png" 


export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      User: currentUser,
      show: false,
      showReports: false,
      selected: 0,
      dnrequired: false,
      usercustomers: false,
    };
  }

  componentDidMount() {
    loadTree();
    if (logo === undefined) {
      Credentials.setLogo(defaultlogo);
    }



    SettingsServices.GetSettings(currentTenant).then((response) =>
      this.setState({ dnrequired: response.data.dnreq })
    );

    UsercustomerService.GetUserCustomersbyUser(userid).then((res) =>
      this.setState({
        usercustomers:
          res.data === "" || res.data === undefined || res.data === null
            ? false
            : true,
      })
    );
  }

  handleshow = () => {
    this.setState({ show: !this.state.show, selected: 1, showReports: false });
  };
  handleshowReports = () => {
    this.setState({
      showReports: !this.state.showReports,
      selected: 8,
      show: false,
    });
  };

  render() {
    // let role = roles;
    return (
      <div style={{ height: "92%", position: "fixed", zIndex: "3" }}>
        {userid === undefined || currentUser === undefined ? (
          ""
        ) : (
          <aside className="main-sidebar sidebar-light-gray elevation-4">
            <div
              className="sidebar p-0"
              style={{ height: "calc(95% - (3.5rem + 1px))" }}
            >
              <nav className="mt-2 ml-auto">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="true"
                >
                  <li
                    className={`nav-item active ${
                      selectedMenu=== "Home" ? "menuselected" : ""
                    }`}
                    onClick={() => this.setState({ selected: 0 })}
                  >
                    <Link to="/index" className="text-dark nav-link ">
                      {/* <i className="text-dark nav-icon fas fa-tachometer-alt textColour" /> */}
                      {selectedMenu === "Home" ?
                            <img src={activedashboardIcon} alt="d" className="nav-icon pr-1"/>:
                            <img src={dashboardIcon} alt="d" className="nav-icon pr-1"/>
                            }
                      <p className={`cursorPointer ${selectedMenu==="Home"?"text-bold":""}`} >Dashboard</p>
                    </Link>
                  </li>
                  {/* -------------------Masters Section --------------------------------- */}
                  {appmaster === "YES" &&
                  (screens.includes("Admin Masters") ||
                    screens.includes("Accounts Masters") ||
                    screens.includes("General Masters") ||
                    screens.includes("Inventory Masters")) ? (
                    <li
                      className={`nav-item active ${
                        selectedMenu=== "Masters" ? "menuselected" : ""
                      }`}
                    >
                      <div
                        className={`nav-link text-dark`}
                        // onClick={this.handleshow}
                      >
                        <Link
                          to={{
                            pathname: "/masters",
                          }}
                          state={{ message: "" }}
                          className="text-dark"
                        >
                          {/* <i className="nav-icon fas fas fa-th textColour text-sm" /> */}
                          {selectedMenu === "Masters" ?
                            <img src={activemastersIcon} alt="d" className="nav-icon pr-1"/>:
                            <img src={mastersIcon} alt="d" className="nav-icon pr-1"/>
                            }
                          <p className={`cursorPointer ${selectedMenu==="Masters"?"text-bold":""}`}>
                            Masters
                          </p>
                        </Link>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  {/* -------------------End Masters Section --------------------------------- */}

                  {/* -------------------Tenant Section --------------------------------- */}
                  {roles === "ROLE_SUPERADMIN" ? (
                    <li
                      className={`nav-item has-treeview  ${
                        selectedMenu=== "Tenants" ? "menuselected" : ""
                      }`}
                      onClick={() =>
                        this.setState({
                          selected: 2,
                          show: false,
                          showReports: false,
                        })
                      }
                    >
                      <div className=" nav-link text-dark">
                        <Link
                          to={{
                            pathname: "/superadminmasters",
                          }}
                          state={{ message: "" }}
                          className="text-dark"
                        >
                          {/* <i className=" nav-icon fas fa-table textColour text-sm" /> */}

                          {selectedMenu === "Tenants" ?
                            <img src={activesuperadminIcon} alt="d" className="nav-icon pr-1"/>:
                            <img src={superadminIcon} alt="d" className="nav-icon pr-1"/>
                            }

                          <p className={`cursorPointer ${selectedMenu==="Tenants"?"text-bold":""}`}>
                            Super Admin
                            {/* <i className=" right fas fa-angle-left" /> */}
                          </p>
                        </Link>
                      </div>
                    </li>
                  ) : (
                    ""
                  )}
                  {/* --------------------End Tenant Section ------------------------------ */}

                  {/* -------------------Accounts Section --------------------------------- */}

                  {accounts === "YES" &&
                  (screens.includes("Sub Heads / Groups") ||
                    screens.includes("Create Ledger") ||
                    screens.includes("Journal Entry") ||
                    screens.includes("Salary Entry") ||
                    screens.includes("Direct Stocks") ||
                    screens.includes("Accounts Payable") ||
                    screens.includes("Accounts Receivable") ||
                    screens.includes("Direct Taxes") ||
                    screens.includes("Indirect Taxes") ||
                    screens.includes("Losses Entry") ||
                    screens.includes("Assets Loses/Expenses") ||
                    screens.includes("Rectification Entries") ||
                    screens.includes("Debit Credit Notes") ||
                    screens.includes("Employees Salary")) ? (
                    <>
                      <li
                        className={`nav-item has-treeview  ${
                          selectedMenu === "Accounts" ? "menuselected" : ""
                        }`}
                        onClick={() =>
                          this.setState({
                            selected: 3,
                            show: false,
                            showReports: false,
                          })
                        }
                      >
                        <div className="  nav-link text-dark">
                          <Link to="/accounts" className="text-dark">
                            {/* <i className=" nav-icon fas fa-money-bill-alt textColour text-sm" /> */}
                            {selectedMenu === "Accounts" ?
                            <img src={activeaccountingIcom} alt="d" className="nav-icon pr-1"/>:
                            <img src={accountingIcom} alt="d" className="nav-icon pr-1"/>
                            }
                            <p className={`cursorPointer ${selectedMenu==="Accounts"?"text-bold":""}`}>
                              Accounts
                              {/* <i className="right fas fa-angle-left" /> */}
                            </p>
                          </Link>
                        </div>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  {/* --------------------End Accounts Section ------------------------------ */}

                  {/* --------------------Assets Section start------------------------------ */}
                  {assetmanagement === "YES" &&
                  (screens.includes("Assets") ||
                    screens.includes("Maintenance") ||
                    screens.includes("Production Usage") ||
                    screens.includes("Production Usage") ||
                    screens.includes("Transportation")) ? (
                    <>
                      <li
                        className={`nav-item has-treeview  ${
                          selectedMenu === "Assets" ? "menuselected" : ""
                        }`}
                        onClick={() =>
                          this.setState({
                            selected: 10,
                            show: false,
                            showReports: false,
                          })
                        }
                      >
                        <div className="  nav-link text-dark">
                          <Link
                            to={{
                              pathname: "/assetmanagement",
                            }}
                            state={{
                              message: "",
                              sold: false,
                              rent: false,
                              active: true,
                              type: "Rental",
                              stype: "Sales",
                            }}
                            className="text-dark"
                          >
                            {/* <i className=" nav-icon fas fa-toolbox textColour text-sm" /> */}
                           
                            {selectedMenu === "Assets" ?
                            <img src={activeassetIcon} alt="d" className="nav-icon pr-1"/>:
                            <img src={assetIcon} alt="d" className="nav-icon pr-1"/>
                            }
                            <p className={`cursorPointer ${selectedMenu==="Assets"?"text-bold":""}`}>
                              Assets Management
                              {/* <i className="right fas fa-angle-left" /> */}
                            </p>
                          </Link>
                        </div>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  {/* --------------------Assets Section end------------------------------ */}

                  {/* -------------------Inventory Section --------------------------------- */}
                  {inventory === "YES" &&
                  (screens.includes("Items") ||
                    screens.includes("Services") ||
                    screens.includes("Price") ||
                    screens.includes("Stock") ||
                    screens.includes("Stock Issue")) ? (
                    <>
                      <li
                        className={`nav-item has-treeview  ${
                          selectedMenu === "Inventory" ? "menuselected" : ""
                        }`}
                        onClick={() =>
                          this.setState({
                            selected: 4,
                            show: false,
                            showReports: false,
                          })
                        }
                      >
                        <div className="  nav-link text-dark">
                          <Link
                            to={{
                              pathname: "/inventory",
                            }}
                            state={{ message: "" }}
                            className="text-dark"
                          >
                            {/* <i className=" nav-icon fas fa-boxes textColour text-sm" /> */}

                            {selectedMenu === "Inventory" ?
                            <img src={activeinventoryIcon} alt="d" className="nav-icon pr-1"/>:
                            <img src={inventoryIcon} alt="d" className="nav-icon pr-1"/>
                            }
                            <p className={`cursorPointer ${selectedMenu==="Inventory"?"text-bold":""}`}>
                              Inventory
                              {/* <i className="right fas fa-angle-left" /> */}
                            </p>
                          </Link>
                        </div>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  {/* ---------------------- End of Inventory Section ----------------- */}

                  {/* -------------------Sales Section --------------------------------- */}
                  {sales === "YES" &&
                  (screens.includes("Sales") ||
                    screens.includes("Sale Order") ||
                    screens.includes("Quotation") ||
                    screens.includes("Invoice") ||
                    screens.includes("Delivery/Shipment Note") ||
                    screens.includes("Sales Returns") ||
                    screens.includes("Receipts") ||
                    screens.includes("Debit Note") ||
                    screens.includes("Point of Sale") ||
                    screens.includes("Stock Acceptance") ||
                    screens.includes("Pos Price Master") ||
                    screens.includes("Direct Trade")) ? (
                    <>
                      <li
                        className={`nav-item has-treeview  ${
                          selectedMenu === "Sales" ? "menuselected" : ""
                        }`}
                        onClick={() =>
                          this.setState({
                            selected: 5,
                            show: false,
                            showReports: false,
                          })
                        }
                      >
                        <div className="  nav-link text-dark">
                          <Link
                            to={{
                              pathname: "/sales",
                            }}
                            state={{ message: "" }}
                            className="text-dark"
                          >
                            {/* <i className=" nav-icon fab fa-opencart textColour text-sm" /> */}
                            {selectedMenu === "Sales" ?
                            <img src={activesalesIcon} alt="d" className="nav-icon pr-1"/>:
                            <img src={salesIcon} alt="d" className="nav-icon pr-1"/>
                            }
                            <p className={`cursorPointer ${selectedMenu==="Sales"?"text-bold":""}`}>
                              Sales
                              {/* <i className="right fas fa-angle-left" /> */}
                            </p>
                          </Link>
                        </div>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  {/*--------------------End of Sales Section -----------------------*/}

                  {/* ----------------------------------------------------------------------START TOURS & TRAVELLERS STARTS -------------------------------------------------- */}

                  {salestravels==="YES"?
                  <li
                    className={`nav-item has-treeview  ${
                      selectedMenu === "Salestravel" ? "menuselected" : ""
                    }`}
                    onClick={() =>
                      this.setState({
                        selected: 9,
                        show: false,
                        showReports: false,
                      })
                    }
                  >
                    <div className=" nav-link text-dark">
                      {/* <i className=" nav-icon fa fa-plane textColour text-sm" /> */}
                      <Link
                        to={{
                          pathname: "/salestravelinvoice",
                        }}
                        state={{ message: "" }}
                        className="text-dark"
                      >
                        {selectedMenu === "Salestravel" ?
                            <img src={activetravelInvoicesIcon} alt="d" className="nav-icon pr-1"/>:
                            <img src={travelInvoicesIcon} alt="d" className="nav-icon pr-1"/>
                            }
                        <p className={`cursorPointer ${selectedMenu==="Salestravel"?"text-bold":""}`}>Sales Travel </p>
                      </Link>
                    </div>
                  </li>:""}
                  {/* -----------------------------------End of Direct Trade -------------------- */}

                  {/* --------------------Purchase Section ------------------------------ */}
                  {purchase === "YES" &&
                  (screens.includes("Purchase") ||
                    screens.includes("Purchase Returns") ||
                    screens.includes("GRN") ||
                    screens.includes("SCN") ||
                    screens.includes("Bill of Entry") ||
                    screens.includes("Credit Note")) ? (
                    <>
                      <li
                        className={`nav-item has-treeview  ${
                          selectedMenu === "Purchase" ? "menuselected" : ""
                        }`}
                        onClick={() =>
                          this.setState({
                            selected: 7,
                            show: false,
                            showReports: false,
                          })
                        }
                      >
                        <div className="  nav-link text-dark">
                          <Link
                            to={{
                              pathname: "/purchase",
                            }}
                            state={{ message: "" }}
                            className="text-dark"
                          >
                            {/* <i className=" nav-icon fas fa-cart-arrow-down textColour text-sm" /> */}
                            {selectedMenu === "Purchase" ?
                            <img src={activepurchaseIcon} alt="d" className="nav-icon pr-1"/>:
                            <img src={purchaseIcon} alt="d" className="nav-icon pr-1"/>
                            }
                            <p className={`cursorPointer ${selectedMenu==="Purchase"?"text-bold":""}`}>
                              Purchase
                              {/* <i className="right fas fa-angle-left" /> */}
                            </p>
                          </Link>
                        </div>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  {/* -------------------------- End of Purchase Section-------------------*/}

                  {/* ---------------------------Production------------------------------- */}
                  {production === "YES" &&
                  (screens.includes("Estimation") ||
                    screens.includes("Job Order") ||
                    screens.includes("Indenting") ||
                    screens.includes("InProcess") ||
                    screens.includes("Quality Analysis") ||
                    screens.includes("Store") ||
                    screens.includes("Immediate Production")) ? (
                    <>
                      <li
                        className={`nav-item has-treeview  ${
                          selectedMenu === "Production" ? "menuselected" : ""
                        }`}
                        onClick={() =>
                          this.setState({
                            selected: 9,
                            show: false,
                            showReports: false,
                          })
                        }
                      >
                        <div className=" nav-link text-dark">
                          <Link
                            to={{
                              pathname: "/production",
                            }}
                            state={{ message: "" }}
                            className="text-dark"
                          >
                            {/* <i className=" nav-icon fa fa-industry textColour text-sm" /> */}
                            {selectedMenu === "Production" ?
                            <img src={activeproductionIcon} alt="d" className="nav-icon pr-1"/>:
                            <img src={productionIcon} alt="d" className="nav-icon pr-1"/>
                            }
                            <p className={`cursorPointer ${selectedMenu==="Production"?"text-bold":""}`}>Production</p>
                          </Link>
                        </div>
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  {/* ----------------------------End of Production----------------------- */}

                      {/* ----------------------Project Management------------------------- */}
                      {/* <li
                        className={`nav-item has-treeview  ${
                          selectedMenu === "Project Management" ? "menuselected" : ""
                        }`}
                        onClick={() =>
                          this.setState({
                            selected: 9,
                            show: false,
                            showReports: false,
                          })
                        }
                      >
                        <div className=" nav-link text-dark">
                          <Link
                            to={{
                              pathname: "/projectmanagement",
                            }}
                            state={{ message: "" }}
                            className="text-dark"
                          >
                            {selectedMenu === "Project Management" ?
                            <img src={activeproductionIcon} alt="d" className="nav-icon pr-1"/>:
                            <img src={productionIcon} alt="d" className="nav-icon pr-1"/>
                            }
                            <p className={`cursorPointer ${selectedMenu==="Project Management"?"text-bold":""}`}>Project Management</p>
                          </Link>
                        </div>
                      </li> */}
                  {/* -------------------- End of Project Management -------------------- */}
                  {/* ---------------------Settings------------------ */}

                  <li
                        className={`nav-item has-treeview  ${
                          selectedMenu === "Settings" ? "menuselected" : ""
                        }`}
                      >
                        <div className=" nav-link text-dark">
                          <Link
                            to={{
                              pathname: "/settings",
                            }}
                            state={{ message: "" }}
                            className="text-dark"
                          >
                            {selectedMenu === "Settings" ?
                            <img src={activesettingsIcon} alt="d" className="nav-icon pr-1"/>:
                            <img src={settingsIcon} alt="d" className="nav-icon pr-1"/>
                            }
                            <p className={`cursorPointer ${selectedMenu==="Settings"?"text-bold":""}`}>Settings</p>
                          </Link>
                        </div>
                      </li>

              
                  {/* ---------------------End of Settings------------------ */}

                </ul>
              </nav>
              {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
            <div>
              <img
                src={finanzz}
                alt="Logo..."
                width="100%"
                height="80"
                className="finanzzlogo"
              />
            </div>
          </aside>
        )}
      </div>
    );
  }
}
