import React, { Component } from "react";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant, thousandsdisplay } from "../../Common/Credentials";
import Footer from "../../Component/Footer";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import moment from "moment";
import { spin } from "../../Common/NewLoader.jsx";
import { purchaseid } from "../../Common/Permissions.js";
import CustomToolbar from "../../CommonComponents/CustomToolbar.js";

import MUIDataTable from "mui-datatables";
import PurchseServices from "../PurchseServices.js";
import {NumericFormat} from "react-number-format";
import { ThemeProvider } from "@mui/material";
import { createTheme, FormControlLabel } from "@mui/material";
import IOSSwitch from "../../CommonComponents/IOSSwitch";


const theme = createTheme({
  overrides: {
    MuiTableCell: {
      head: {
        backgroundColor: "#e9ecef !important",
          border: [[1, 'solid', '#ece6e6']], 
      },
      body:
      {
        border: [[1, 'solid', '#ece6e6']],
      },
    },
  },
});

class SCN extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SCNdata: [],
      SCNCompleteddata:[],
      message: "",
      loading: false,
      active:true,
      comp:false,
    };
    this.refreshItems = this.refreshItems.bind(this);
  }
  handleActiveChange=()=>{
    this.setState({active:true,comp:false})
  }
  handlecompChange=()=>{
    this.setState({comp:true,active:false})
  }

  refreshItems() {
    PurchseServices.retriveAllSCN(currentTenant).then((response) => {
      this.setState({ SCNdata: response.data });
    })
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
    PurchseServices.retriveAllCompletedSCN(currentTenant).then((response) => {
      this.setState({ SCNCompleteddata: response.data });
    });
    this.setState({ loading: false });
  }
  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    this.setState({ loading: true });
    this.refreshItems();
  }

  render() {

    const columns = [
      {name: "scnid",label: "SCN Id",options: {filter: false,sort: true,display:false}},
      {name: "customscnid",label: "SCN Id",options: {filter: false,sort: true,}},
      {name: "custompoid",label: "PO Id",options: {filter: true,sort: true,}},
      {name: "msid",label: "MileStone Id",options: {filter: false,sort: true,}},
      // {name: "customsupid",label:"Supplier Id",options: {filter: true,sort: true,}},
      {name: "supplier",label: "Supplier",options: {filter: true,sort: true,}},
      // {name: "supaddress",label: "Seller Address",options: {filter: false,sort: true,}},
      {name: "createdAt",label: "Date",options: {filter: false,sort: true,sortOrder: 'asc', 
      customBodyRender:(tableMeta)=>{
        return(moment(tableMeta).format('DD-MM-YYYY HH:mm:ss'))
      }}},      
      {name: "totalpricewtax",label: "Amount",options: {filter: false,sort: true,
        customBodyRender:(tableMeta)=>{
          return(<NumericFormat className="pr-3" displayType={'text'} value={tableMeta} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>)
        },
        setCellProps: value => ({ style: { textAlign:'right'}}),
      }},
      // {name: "postatus",label: "Status",options: {filter: true,sort: true,}},
     {
       name: "View",
       options: {
         filter: false,
         sort: false,
         empty: true,
         customBodyRender: (value, tableMeta, updateValue) => {
           return (<>
             <Link as="button" data-tip data-for="view details" className="fa fa-list-alt"
             to={{pathname: "/viewscn"}} state={{id:tableMeta.rowData.find(e=>e)}}></Link>
             {/* <Tooltip id="view details" place="top" effect="solid">View</Tooltip> */}
             </>
           );
         }
       }
     },
     ];
     const options = {
      filterType: 'checkbox',
      selectableRows: "none",
      customToolbar: () => {
       return (<>
        {purchaseid === '2' || purchaseid === '3'?
          <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname:"/newscn"}} state={{id: -1, pagename:"New Service Complition Note"}}>
            <CustomToolbar />
          </Link>
          :''
        }    
       </>
       );
     }
    }

    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card ">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>

              <li className="breadcrumb-item active">SCN-Service Complition Note</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    {/* /.card */}
                    <div className="card">
                        <div>
                          {this.state.loading === true ?<span className="text-center">{spin}</span> :<>
                          <div className="form-row"> 
                            <fieldset className="form-group col-md-4">
                              <span className="mx-3">Active</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.active} onChange={this.handleActiveChange} name="checkedB" />}
                              />
                            </fieldset>
                            <fieldset className="form-group col-md-4">
                              <span className="mx-3">Completed</span>
                              <FormControlLabel
                                control={<IOSSwitch className="sm" checked={this.state.comp} onChange={this.handlecompChange} name="checkedB" />}
                              />
                            </fieldset>
                            </div> 
                            {this.state.active === true?
                           <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"SCN-Service Complition Note (Active)"}
                            data={this.state.SCNdata}
                            columns={columns}
                            options={options}
                          />
                          </ThemeProvider>
                          :''
                            }
                            {this.state.comp === true?
                           <ThemeProvider theme={theme}>
                          <MUIDataTable
                            className="table-sm px-3"
                            title={"SCN-Service Complition Note (Completed)"}
                            data={this.state.SCNCompleteddata}
                            columns={columns}
                            options={options}
                          />
                          </ThemeProvider>
                          :''
                            }
                         </> }
                        </div>
                    </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </section>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default withRouter(SCN);
