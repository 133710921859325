import React from "react";
import { FormikTextField } from "../../CommonComponents/FormField.js";
import { Autocomplete } from "@mui/material";
import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import {
  ccountry,
  currency,
  currentTenant,
  dataaccess,
  financialyearend,
  financialyearstart,
  thousandsdisplay,
  userid,
  userwhid,
} from "../../Common/Credentials.js";
import { Form, Formik } from "formik";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
import SalesServices from "../SalesServices";
import downloadfiles from "../../Common/upload/downloadfiles.js";
import {NumericFormat} from "react-number-format";
import {
  Box,
  Collapse,
  FormLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Modal, Spinner } from "react-bootstrap";
import MasterService from "../../Masters/MasterService.js";
import UsercustomerService from "../../Masters/Usercustomers/UsercustomerService.js";
import SettingsServices from "../../Settings/SettingsServices.js";
import InventoryService from "../../inventory/InventoryService.js";
import moment from "moment";

let datalist = [];
let precloselist = [];

class newDn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      soitemid:'',
      usercustomers: [],
      saleorderdata: [],
      saleorderid: "",
      saleorderitems: [],
      invoiceidlist: [],
      invoiceid: "",
      custom: [],
      customer: "",
      custid: "",
      customerdetails: "",
      itemid: "",
      itemname: "",
      salesenum: "",
      quantity: "",
      orderedquantity: "",
      pendingquantity:"",
      preclosequantity:'',
      grandtotal: "",
      status: "0",
      sodata: [],
      serviceList: [],
      completed: "",
      sotype: "",
      show: 0,
      open: false,
      open1: false,
      vehiclenum: "",
      transporterid: "",
      accepteditems: [],
      loader: false,
      distance: "",
      insurance: 0,
      selectall: false,
      discountamount: 0,
      discount: 0,
      pricevariance: 0,
      name: "",
      phoneno: "",
      pincode: "",
      state: "",
      city: "",
      houseno: "",
      areaname: "",
      country: "",
      err: false,
      CountryData: [],
      StatesData: [],
      CitiesData: [],
      pouploadm: "",
      quantitywindow:false,
      itemquantitylist:[],
      batchidlist: [],
      batchid: "",
      batchprice:0,
      whdata:'',
      showmodel: false,
      preclosedata:[],
      precloseitems:[],
      type: "",
      selectedbatch:{},
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }


  handleModalShow = () => {
    this.setState({ showmodel: true ,preclosedata:this.state.saleorderitems});
  };
  handleModalClose = () => {
    this.setState({ showmodel: false });
  };


  handleCustomer = (value, values) => {
    if (values !== "" && typeof values === "object" && values !== null) {
      this.setState({
        custid: values.id,
        customer: values.companyname,
        saleorderid: "",
      });

      const id = values.id;

      let selectedcustomer = this.state.custom.filter(
        (customer) => customer.id === id
      );
      this.setState({ customerdetails: selectedcustomer });
      // if (this.state.pouploadm === "YES") {

      let datadao = {
        tenantid: currentTenant,
        startdate: financialyearstart,
        enddate: financialyearend,
        userid:userid,
        dataaccess: dataaccess,
        whid:userwhid,
        id:id,
        poupload:this.state.pouploadm === "YES"?true:false,
      }

        SalesServices.GetInvidByCustidForDn(datadao)
          .then((res) =>
            this.setState({
              invoiceidlist: res.data,
            })
          )
          .catch((e) => {
            if (e.response.data.error === "Unauthorized") {
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
            }
          });
    } else {
      this.setState({
        custid: "",
        customer: "",
        saleorderid: "",
        saleorderdata:[],
        invoiceid:'',
        invoiceidlist: [],
        accepteditems: [],
        preclosedata:[],
        precloseitems:[]
      });
      datalist=[]
      precloselist=[]
    }
  };

  handleSaleOrder = (value,values) => {
    if (values !== "" && this.state.saleorderdata.includes(values) && values !== null)  {
      this.setState({ saleorderid: values });
      const id = values;

       let datadao = {
        tenantid: currentTenant,
        startdate: financialyearstart,
        enddate: financialyearend,
        userid:userid,
        dataaccess: dataaccess,
        whid:userwhid,
        id:id,
        poupload:this.state.pouploadm === "YES"?true:false,
      }
      SalesServices.getInvoiceIdfoDN(datadao)
        .then((response) => this.setState({ invoiceidlist: response.data}))
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
    }else{
      this.setState({
        saleorderid: "",
        invoiceid:'',
        invoiceidlist: [],
        accepteditems: [],
        preclosedata:[],
        precloseitems:[]
      })
      datalist=[]
      precloselist=[]
    }
  };

  handleInvoice = (value,values) => {
    if (values !== "" && this.state.invoiceidlist.includes(values) && values !== null){
      this.setState({ invoiceid: values });
      const id = values;
      SalesServices.GetInvoiceByCustomid(id)
        .then((response) =>
          this.setState({
            sodata: response.data,
            saleorderitems: response.data.invoiceitemlist.map((e) => Object.assign(e, { preclosequantity: 0, pending:e.quantity-e.accepted-e.returnquantity-e.preclose})),
            // accepteditems: response.data.invoiceitemlist,
            serviceList: response.data.servicelist,
            milestones:
              response.data.sotype === 1
                ? response.data.servicelist.find((e) => e).milestones
                : [],
            totaltaxamount: response.data.totaltaxamount,
            totaliv: response.data.totaliv,
            grandtotal: response.data.grandtotalproducttotal,
            grandservicetotal: response.data.grandservicetotal,
            sotype: response.data.sotype,
            name:response.data.dname,
            phoneno:response.data.dphoneno,
            pincode:response.data.dpincode,
            state:response.data.dstate,
            city:response.data.dcity,
            houseno:response.data.dhouseno,
            areaname:response.data.dareaname,
            country:response.data.dcountry,
            vehiclenum:response.data.vehiclenum,
            transporterid:response.data.transporterid,
            distance:response.data.distance,
            saleorderid:response.data.customsoid,
          })
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });

        InventoryService.GetWHdata(currentTenant).then((response) => {
          this.setState({ whdata: response.data });
        });

    }else{
      this.setState({
        invoiceid:'',
        accepteditems: [],
        preclosedata:[],
        precloseitems:[]
      })
      datalist=[]
      precloselist=[]
    }
  };

  handleAccept = (id,type) => {
    let itemsdetails = this.state.saleorderitems.filter(
      (data) => data.id === id
    );

    this.setState({ quantity: "", description: "", show: 1,type: type });
    itemsdetails.map((data) =>
      this.setState({
        id: data.id,
        soitemid:data.soitemid,
        itemid: data.itemid,
        itemname: data.name,
        hsn: data.hsn,
        price: data.price,
        salesenum: data.salesenum,
        tax: data.tax,
        categoryid: data.categoryid,
        taxamount: data.taxamount,
        orderedquantity: data.quantity,
        pendingquantity: data.pending,
        preclosequantity: data.preclose,
        uom: data.uom,
        totalprice: data.totalprice,
        brand: data.brand,
        colour: data.colour,
        totalpricewtax: data.totalpricewtax,
        size: data.size,
        thickness: data.thickness,
        totalmtrroll: data.totalmtrroll,
        description: data.description,
        insurance: data.insurance,
        discount: data.discount,
        discountamount: data.discountamount,
        pricevariance: data.pricevariance,
      },
      () =>
        this.setState({
          itemquantitylist: this.state.whdata
            .find((e) => e.whid === this.state.sodata.whid)
            .saleslist.filter(
              (sl) => sl.itemid === this.state.itemid && sl.currentstock !== 0
            )
            .map((e) => Object.assign(e, { enteredquantity: 0 })),
            quantitywindow: type==='A'?true:false
        })
    ))
  };

  handleCountryChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
    } else {
      this.setState({
        country: newValue.name,
        StatesData: newValue.states,
        err: false,
      });
    }
  };

  handleStateChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
    } else {
      this.setState({
        state: newValue.name,
        CitiesData: newValue.cities,
        err: false,
      });
    }
  };
  handleCityChange = (e, newValue) => {
    if (newValue === "" || newValue === null || newValue === undefined) {
    } else {
      this.setState({ city: newValue.name, err: false });
    }
  };

  handleSelect = () => {
    // let a =this.state.itemquantitylist
    this.setState({ quantitywindow: true });
  };

  
  handleQuantityChange = (e, i) => {
    // this.setState({ quantity: e });

    let q;
    let data = this.state.itemquantitylist;
    if (e === "" || e === undefined || parseFloat(e) < 0) {
      q = 0;
    }
    // else if(this.state.pendingquantity<(parseFloat(e)+this.state.quantity)){
    //   q=this.state.pendingquantity-this.state.quantity
    // }
    else {
      q = parseFloat(e);
    }
    if (q > data.find((e, index) => index === i).currentstock) {
      data.find((e, index) => index === i).enteredquantity = data.find(
        (e, index) => index === i
      ).currentstock;
    } else {
      data.find((e, index) => index === i).enteredquantity = q;
    }
    let qa = data.reduce((a, b) => a + b.enteredquantity, 0);

    this.setState({ quantity: qa, itemquantitylist: data });
  };

  handlepreCloseQuantityChange = (e,i) => {
    let q;
    let data = this.state.preclosedata;
    if (e === "" || e === undefined || parseFloat(e) < 0) {
      q = 0;
    }else {
      q = parseFloat(e);
    }
    if (q > (data.find((e, index) => index === i).pending )) {
      data.find((e, index) => index === i).preclosequantity = (data.find((e, index) => index === i).pending )
    } else {
      data.find((e, index) => index === i).preclosequantity = q;
    }
    this.setState({saleorderitems: data});
  }

  // handleSaveQuantity = () => {
  //   if (this.state.pendingquantity < this.state.quantity) {
  //   } else {
  //     let data = this.state.itemquantitylist;
  //     let am = data.filter((a) => a.enteredquantity !== 0);
  //     if (this.state.batchidlist.length !== 0) {
  //       let bid = this.state.batchidlist.map((a) => a.batchid);
  //       am.map((a) =>
  //         bid.includes(a.batchid)
  //           ? (this.state.batchidlist.find(
  //               (b) => b.batchid === a.batchid
  //             ).quantity =
  //               this.state.batchidlist.find((b) => b.batchid === a.batchid)
  //                 .quantity - a.enteredquantity)
  //           : this.state.batchidlist.push({
  //               batchid: a.batchid,
  //               quantity: a.enteredquantity,
  //               stockid: a.stockid,
  //               tenantid: currentTenant,
  //               whid: this.state.sodata.whid,
  //               itemid: this.state.itemid,
  //             })
  //       );
  //     } else {
  //       am.map((a) =>
  //         this.state.batchidlist.push({
  //           batchid: a.batchid,
  //           quantity: a.enteredquantity,
  //           stockid: a.stockid,
  //           tenantid: currentTenant,
  //           whid: this.state.sodata.whid,
  //           itemid: this.state.itemid,
  //         })
  //       );
  //     }
  //     this.setState({ quantitywindow: false, itemquantitylist: data });
  //   }
  // };

  onSubmit() {
    if (this.state.quantity === "0" || this.state.quantity===0) {
    } else {
      console.log(this.state.quantity)
      let disc =
        (parseFloat(this.state.quantity) *
          this.state.price *
          this.state.discount) /
        100;

      let list = {
        id: this.state.id,
        soitemid:this.state.soitemid,
        itemid: this.state.itemid,
        name: this.state.itemname,
        hsn: this.state.hsn,
        categoryid: this.state.categoryid,
        quantity: parseFloat(this.state.quantity),
        price: this.state.price,
        salesenum: this.state.salesenum,
        tax: this.state.tax,
        discount: this.state.discount,
        discountamount: disc,
        taxamount:
        (parseFloat(this.state.quantity) *
            (this.state.price - disc) *
            this.state.tax) /
          100,
        description: this.state.description,
        totalprice: parseFloat(this.state.quantity) * (this.state.price - disc),
        pending: this.state.pendingquantity - this.state.quantity,
        uom: this.state.uom,
        brand: this.state.brand,
        colour: this.state.colour,
        totalpricewtax:
        (parseFloat(this.state.quantity) *
        (this.state.price - disc) *
            this.state.tax) /
            100 +
            parseFloat(this.state.quantity) * (this.state.price - disc) +
            (parseFloat(this.state.quantity) *
            (this.state.price - disc) *
            this.state.insurance) /
            100,
        size: this.state.size,
        thickness: this.state.thickness,
        totalmtrroll: this.state.totalmtrroll,
        insurance: this.state.insurance,
        insuranceamount:
        (parseFloat(this.state.quantity) *
            (this.state.price - disc) *
            this.state.insurance) /
          100,
        pricevariance: this.state.pricevariance,
        batchid: this.state.selectedbatch.batchid,
        batchprice:this.state.selectedbatch.price
      };

      if(this.state.type === "A"){
      datalist.push(list);
      this.state.batchidlist.push({
        batchid: this.state.selectedbatch.batchid,
        batchprice:this.state.selectedbatch.price,
        quantity: parseFloat(this.state.quantity),
        stockid: this.state.selectedbatch.stockid,
        tenantid: currentTenant,
        whid: this.state.sodata.whid,
        itemid: this.state.itemid,
        whdata:this.state.whdata.find((e) => e.whid === this.state.sodata.whid).saleslist.find(
          (sl) => sl.batchid === this.state.selectedbatch.batchid).currentstock = 
          this.state.whdata.find((e) => e.whid === this.state.sodata.whid).saleslist.find(
            (sl) => sl.batchid === this.state.selectedbatch.batchid).currentstock - parseFloat(this.state.quantity)
      })
      }else{
        precloselist.push(list)
      }

      if (this.state.pendingquantity === 0) {
        this.setState({
          saleorderitems: this.state.saleorderitems.filter(
            (data) => data.id !== this.state.id
          ),
        });
      } else {
        this.state.saleorderitems.find((e) => (e.id === this.state.id)).pending =
          this.state.saleorderitems.find((e) => (e.id === this.state.id))
            .pending - this.state.quantity;
      }
      this.setState({
        accepteditems: datalist,
        precloseitems:precloselist,
        quantity: "",
        description: "",
        show: 0,
      });
    }
  }

  handleSelectAll = () => {
    this.setState({ selectall: !this.state.selectall }, () =>
      this.setState({
        accepteditems:
          this.state.selectall === true ? this.state.saleorderitems : datalist,
      })
    );
  };

  removeItemFromList(id) {
    const deleteitem = datalist.filter((item) => item.id === id);
    let delquantity = deleteitem.map((data) => data.quantity);
    const newlist = datalist.filter((item) => item.id !== id);

    this.setState({
      accepteditems: newlist,
    });
    this.state.saleorderitems.find((e) => e.id === id).pending =
      this.state.saleorderitems.find((e) => e.id === id).pending +
      parseFloat(delquantity);
    datalist = newlist;
  }

  refreshData() {
    MasterService.getCountries()
      .then((response) =>
        this.setState({
          CountryData: response.data,
        })
      )
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });

    SalesServices.retriveAllCustomers(currentTenant)
      .then((res) => {
        this.setState({
          custom: res.data.filter((e) =>
            this.state.usercustomers.length === 0
              ? e
              : this.state.usercustomers.includes(e.companyname)
          ),
        });
      })
      .catch((e) => {
        if (e.response.data.error === "Unauthorized") {
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
        }
      });
    SettingsServices.GetSettings(currentTenant).then((response) =>
      this.setState({ pouploadm: response.data.pouploadm })
    );

    InventoryService.GetWHdata(currentTenant).then((response) => {
      this.setState({ whdata: response.data });
    });
      if(this.props.location.state?.invid !== undefined && this.props.location.state?.invid !== null && this.props.location.state?.invid !== ""){
        let invid = this.props.location.state.invid

        SalesServices.GetInvoiceByCustomid(invid)
        .then((response) =>
          this.setState({
            customer: response.data.customer,
            custid: response.data.custid,
            invoiceid: response.data.custominvid,
            sodata: response.data,
            saleorderitems: response.data.invoiceitemlist.map((e) => Object.assign(e, { preclosequantity: 0, pending:e.quantity-e.accepted-e.returnquantity-e.preclose})),
            // accepteditems: response.data.invoiceitemlist,
            serviceList: response.data.servicelist,
            milestones:
              response.data.sotype === 1
                ? response.data.servicelist.find((e) => e).milestones
                : [],
            totaltaxamount: response.data.totaltaxamount,
            totaliv: response.data.totaliv,
            grandtotal: response.data.grandtotalproducttotal,
            grandservicetotal: response.data.grandservicetotal,
            sotype: response.data.sotype,
            name:response.data.dname,
            phoneno:response.data.dphoneno,
            pincode:response.data.dpincode,
            state:response.data.dstate,
            city:response.data.dcity,
            houseno:response.data.dhouseno,
            areaname:response.data.dareaname,
            country:response.data.dcountry,
            vehiclenum:response.data.vehiclenum,
            transporterid:response.data.transporterid,
            distance:response.data.distance,
            saleorderid:response.data.customsoid,
          })
        )
        .catch((e) => {
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
      }

  }

  handledownload = (file) => {
    downloadfiles.download(this.state.sodata.popath, this.state.sodata.pofile);
  };


  handlepreClose = () => {
    let data =this.state.preclosedata
    data.map((e) => e.pending = e.pending - e.preclosequantity) 
    this.setState({saleorderitems:this.state.preclosedata,showmodel:false,precloseitems:data.filter(e => e.preclosequantity !== 0)})
  
  }

  handlesubmit = () => {
    
    if(this.state.accepteditems.length !== 0 || this.state.precloseitems.length !== 0){
      this.setState({ loader: true });
      let data = {
        soid: this.state.saleorderid,
        invoice: this.state.sodata.invid,
        itemlist: this.state.accepteditems,
        precloseitems:this.state.precloseitems,
        vehiclenum: this.state.vehiclenum,
        transporterid: this.state.transporterid,
        createdby: userid,
        distance: parseInt(this.state.distance),
        name: this.state.name,
        phoneno: this.state.phoneno,
        pincode: this.state.pincode,
        state: this.state.state,
        city: this.state.city,
        houseno: this.state.houseno,
        areaname: this.state.areaname,
        country: this.state.country,
        batnchidlist: this.state.batchidlist,
        tenantid:currentTenant,
      };
      SalesServices.addDn(data)
        .then((res) => this.props.navigate({pathname:"/viewdeliverynote"},{state:{id:res.data,goback:false}}))
        .catch((e) => {
          this.setState({loader:false})
          if (e.response.data.error === "Unauthorized") {
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
          }
        });
      }
  };

  onfocus() {
    document
      .getElementById("quantity")
      .addEventListener("mousewheel", function (event) {
        if (window.document.activeElement.type === "number") {
          event.preventDefault();
        }
      });
    document
      .getElementById("quantity")
      .addEventListener("keydown", function (e) {
        if (e.which === 38 || e.which === 40) {
          e.preventDefault();
        }
      });
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    UsercustomerService.GetUserCustomersbyUser(userid).then((res) =>
      this.setState({
        usercustomers:
          res.data === "" || res.data === undefined
            ? []
            : res.data.customers.split(","),
      },()=>this.refreshData())
    )
    datalist = [];
    precloselist=[];
  }

  validate(values) {
    let errors = {};
    //  let isError = false;

    if (this.state.customer === "") {
      errors.customer = "Please Select Customer";
    }

    // if (this.state.saleorderid === "") {
    //   errors.saleorder = "Please Select Sale Order";
    // }
    if (
      this.state.quantity === "" ||
      this.state.quantity === undefined ||
      this.state.quantity === "0"
    ) {
      errors.quantity = "Quantity is required";
    }
    if (this.state.quantity > this.state.pendingquantity) {
      errors.quantity = `Avilable quantity is ${this.state.pendingquantity}`;
    }
    if(this.state.quantity> this.state.selectedbatch.currentstock){
      errors.quantity = `Stock avilable in batch is ${this.state.selectedbatch.currentstock}`;
    }
    //  if (this.state.poid === '') {
    //    errors.poid = "Pelese provide PO Ref"
    //  }
    return errors;
  }

  render() {
    let {
      custom,
      customer,
      custid,
      saleorderdata,
      itemid,
      itemname,
      quantity,
      orderedquantity,
      grandtotal,
      status,
      saleorderitems,
      poid,
      invoiceidlist,
      invoiceid,
    } = this.state;
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
              <span
                as="button"
                className=" text-secondary cursorPointer"
                onClick={() => this.props.navigate(-1)}
              >
                ❮ Back &nbsp;&nbsp;
              </span>
              <li className="breadcrumb-item text-secondary">
                <Link to="/sales">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary"><Link to="/deliverynote">Delivery Note</Link></li>
              <li className="breadcrumb-item active">New Delivery Note</li>
            </ol>

            <div className="card">
              <div className="card-header">
                <h3 className="card-title">New Delivery Note</h3>
              </div>

              <div className="card-body">
                <Formik
                  initialValues={{
                    custom,
                    customer,
                    custid,
                    saleorderdata,
                    itemid,
                    itemname,
                    quantity,
                    orderedquantity,
                    grandtotal,
                    status,
                    saleorderitems,
                    poid,
                    invoiceidlist,
                    invoiceid,
                  }}
                  validateOnChange={false}
                  validate={this.validate}
                  onSubmit={this.onSubmit}
                  enableReinitialize={true}
                >
                  {({ setFieldValue, values }) => (
                    <Form className="form-group row">
                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="customer"
                          options={custom}
                          getOptionLabel={(option) => option.companyname}
                          
                          value={values.customer ? undefined : undefined}
                          onChange={this.handleCustomer}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="customer"
                              // onBlur={(e) =>{this.handleCustomer(e.target.value,values)}}
                              label={`Select Customer (${this.state.customer})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>

                      {/* <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="saleorder"
                          options={saleorderdata}
                          getOptionLabel={(option) => option}
                          
                          
                          value={values.saleorder ? undefined : undefined}
                          onChange={this.handleSaleOrder}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="saleorder"
                              label={`Select SaleOrder (${this.state.saleorderid})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset> */}

                      <fieldset className="form-group col-md-4">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="invoice"
                          options={invoiceidlist}
                          getOptionLabel={(option) => option}
                          
                          
                          value={values.invoice ? undefined : undefined}
                          onChange={this.handleInvoice}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="invoice"
                              label={`Select Invoice (${this.state.invoiceid})`}
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      {/* <fieldset className="form-group col-md-4">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="poid"
                            onChange={(e) =>this.setState({poid:e.target.value})}
                            label="PO Ref"
                            variant="standard"
                            fullWidth
                              />
                         </fieldset>  */}

                      <fieldset className="form-group col-md-12" />

                      {this.state.invoiceid === "" ? (
                        ""
                      ) : (
                        <>
                          {/* <div className="form-group col-md-12">
                            <u>Order Details : </u>
                          </div> */}
                          <div className="form-group col-md-4">
                            <table className="float-left col-md-6">
                              <tbody>
                            <tr>
                              <td>Order Details</td>
                            </tr>
                            {this.state.sodata.customsoid === null || this.state.sodata.customsoid === undefined || this.state.sodata.customsoid ===""?
                              "":
                            <tr>
                                  <td>Order Id</td>
                                  <td>:&nbsp;{this.state.sodata.customsoid}</td>
                                </tr>
                                  }
                               {this.state.sodata.agent === null ||
                              this.state.sodata.agent === undefined ||
                              this.state.sodata.agent === "" ? (
                                ""
                              ) : (
                                <tr>
                                  <td>Agent</td>
                                  <td>:&nbsp;{this.state.sodata.agent}</td>
                                </tr>
                              )}
                              <tr>
                                <td>Org Unit</td>
                                <td>:&nbsp;{this.state.sodata.warehouse}</td>
                              </tr>
                              <tr>
                                <td>GST Type</td>
                                <td>:&nbsp;{this.state.sodata.gsttype}</td>
                              </tr>
                              <tr>
                                <td>Total Amount&nbsp;</td>
                                <td>
                                  :&nbsp;
                                  <NumericFormat
                                    className="pr-3"
                                    displayType={"text"}
                                    value={this.state.sodata.totalinvoicewtax}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    disabled={true}
                                    thousandSeparator={true}
                                    thousandsGroupStyle={thousandsdisplay}
                                  ></NumericFormat>
                                </td>
                              </tr>
                              <tr>
                                <td>Tax Type</td>
                                <td>:&nbsp;{this.state.sodata.taxtype}</td>
                              </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="form-group col-md-4">
                          <table className="float-left col-md-6">
                                    <tbody>
                                      <tr>
                                        <td>Shipping Address</td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.name}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.houseno}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;{this.state.areaname}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.city},&nbsp;{this.state.state}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;{this.state.country}{" "}
                                          - {this.state.pincode}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          &nbsp;
                                          {this.state.phoneno}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                          </div>
                          <div className="form-group col-md-4">
                          <table className="float-left col-md-6">
                                    <tbody>
                                      <tr>
                                        <td>Transportation Details :</td>
                                      </tr>
                                      <tr>
                                        <td>Vehicle No.</td>
                                        <td>
                                          :&nbsp;
                                          {this.state.vehiclenum}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Transporter Id</td>
                                        <td>
                                          :&nbsp;
                                          {this.state.transporterid}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Distance</td>
                                        <td>
                                          :&nbsp;
                                          {this.state.distance}
                                       </td>
                                      </tr>
                                    </tbody>
                                  </table>
                          </div>

                          {/* {this.state.sotype !== 1 ?<> */}
                        <TableContainer>
                          <Typography>Items / Products</Typography>
                          <Table aria-label="collapsible table" className="table-sm">
                            <TableHead>
                              <TableRow>
                              <TableCell  className="text-center">Action</TableCell>
                                <TableCell />
                                  <TableCell className="text-center">Item Name</TableCell>
                                  {this.state.sodata.taxtype === "NA"?'':<TableCell className="text-center">HSN</TableCell>}
                                  <TableCell className="text-center">Price</TableCell>
                                  {currency === this.state.sodata.currency?'':<TableCell className="text-center">Price({this.state.sodata.currency})</TableCell>}
                                  {currency === this.state.sodata.currency?'':<TableCell className="text-center">Price Variance (%)</TableCell>}
                                  <TableCell className="text-center">Type</TableCell>
                                  <TableCell className="text-center">Quantity</TableCell>
                                  <TableCell className="text-center">Pending Quantity</TableCell>
                                  <TableCell className="text-center">UOM</TableCell>
                                  <TableCell className="text-center">Discount</TableCell>
                                  {this.state.sodata.currency === currency?'':<TableCell className="text-center">Discount ({this.state.sodata.currency})</TableCell>}
                                  <TableCell className="text-center">Iscurance</TableCell>
                                  {this.state.sodata.currency === currency?'':<TableCell className="text-center">Iscurance ({this.state.sodata.currency})</TableCell>}
                                  <TableCell className="text-center">Total </TableCell>
                                    {this.state.sodata.currency === currency?'':<TableCell className="text-center">Total ({this.state.sodata.currency})</TableCell>}
                                  {this.state.sodata.taxtype === "NA"?'':<>
                                {this.state.sodata.taxtype === "GST" && ccountry ==="India" ? this.state.sodata.gsttype === "INTRASTATE" ?
                                  (<><TableCell className="text-center">CGST</TableCell><TableCell className="text-center">SGST</TableCell>
                                     {this.state.sodata.currency === currency?'':<> <TableCell className="text-center">CGST({this.state.sodata.currency})</TableCell><TableCell className="text-center">SGST({this.state.sodata.currency})</TableCell></>}
                                  </>) 
                                  : (<><TableCell className="text-center">IGST</TableCell>
                                  {this.state.sodata.currency === currency?'':<TableCell className="text-center">IGST({this.state.sodata.currency})</TableCell>}</>) :
                                  this.state.sodata.taxtype === "GST" ?<><TableCell className="text-center">GST</TableCell>
                                  {this.state.sodata.currency === currency?'':<TableCell className="text-center">GST({this.state.sodata.currency})</TableCell>}</>
                                  :<> <TableCell className="text-center">VAT</TableCell>
                                  {this.state.sodata.currency === currency?'':<TableCell className="text-center">VAT({this.state.sodata.currency})</TableCell>}</>}
                                <TableCell className="text-center">Total Price</TableCell>
                                {this.state.sodata.currency === currency?'':<TableCell className="text-center">Total Price({this.state.sodata.currency})</TableCell>}
                                </>}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.saleorderitems.map((row,index) => (
                                <React.Fragment>
                                <TableRow key={index}>
                                <TableCell  className="text-center">
                                   {row.pending<0 || row.pending===0?'':<>
                                    <span as="button" className=" btn btn-sm hovbuttonColour mr-1" onClick={()=>this.handleAccept(row.id,"A")}>Add</span>
                                    <span as="button" className=" btn btn-sm hovbuttonColour" onClick={()=>this.handleAccept(row.id,"P")}>PreClose</span>
                                    </>
                                  }
                                </TableCell>
                                  <TableCell>
                                    <IconButton aria-label="expand row" size="small" onClick={() => this.setState({open:!this.state.open})}>
                                      {this.state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                    </IconButton>
                                  </TableCell>
                                  <TableCell className="text-center">{row.name}</TableCell>
                                  {this.state.sodata.taxtype === "NA"?'':<TableCell className="text-center">{row.hsn}</TableCell>}
                                  <TableCell className="text-right"><NumericFormat displayType={'text'} value={row.price} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                  {currency === this.state.sodata.currency?'':<TableCell className="text-right"><NumericFormat displayType={'text'} value={row.price* this.state.sodata.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>}
                                  {currency === this.state.sodata.currency?'':<TableCell className="text-center">{row.pricevariance}</TableCell>}
                                  <TableCell className="text-center">{row.salesenum}</TableCell>
                                  <TableCell className="text-center">{row.quantity}</TableCell>
                                  <TableCell className="text-center">{row.pending}</TableCell>
                                  <TableCell className="text-center">{row.uom}</TableCell>
                                  <TableCell className="text-right"><NumericFormat  displayType={'text'} value={row.discountamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({row.discount}%)</TableCell>
                                  {this.state.sodata.currency === currency?'':<TableCell className="text-right"><NumericFormat displayType={'text'} value={row.discountamount * this.state.sodata.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({row.discount}%)</TableCell>}
                            
                                  <TableCell className="text-right"><NumericFormat  displayType={'text'} value={row.insuranceamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({row.insurance}%)</TableCell>
                                  {this.state.sodata.currency === currency?'':<TableCell className="text-right"><NumericFormat displayType={'text'} value={row.insuranceamount * this.state.sodata.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({row.insurance}%)</TableCell>}
                                  <TableCell className="text-right"><NumericFormat displayType={'text'} value={row.totalprice} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                   {this.state.sodata.currency === currency?'':<TableCell className="text-right"><NumericFormat displayType={'text'} value={row.totalprice * this.state.sodata.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>}
                                  {this.state.sodata.taxtype === "NA"?'':<>
                                      {this.state.sodata.taxtype === "GST" &&  this.state.sodata.gsttype === "INTRASTATE"  ?
                                        (<>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={row.taxamount*0.5} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(row.tax / 2)}%)</TableCell>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={row.taxamount/2} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(row.tax / 2)}%)</TableCell>
                                         {this.state.sodata.currency === currency?'':<>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={(row.taxamount/2)* this.state.sodata.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(row.tax / 2)}%)</TableCell>
                                         <TableCell className="text-right"><NumericFormat  displayType={'text'} value={(row.taxamount/2)* this.state.sodata.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(row.tax / 2)}%)</TableCell>
                                         </>}
                                        </>) : (<>
                                          <TableCell className="text-right"><NumericFormat  displayType={'text'} value={row.taxamount} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(row.tax)}%)</TableCell>
                                          {this.state.sodata.currency === currency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={row.taxamount*this.state.sodata.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat>&nbsp;({(row.tax)}%)</TableCell>}
                                       </> )
                                        }
                                      <TableCell className="text-right"><NumericFormat  displayType={'text'} value={row.totalpricewtax} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>
                                      {this.state.sodata.currency === currency?'':<TableCell className="text-right"><NumericFormat  displayType={'text'} value={row.totalpricewtax * this.state.sodata.curconversionrate} decimalScale={2} fixedDecimalScale={true} disabled ={true} thousandSeparator={true} thousandsGroupStyle={thousandsdisplay}></NumericFormat></TableCell>}
                                      </>}
                                </TableRow>  
                                 <TableRow>
                                 <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                   <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                                     <Box margin={1}>
                                     <Table size="small" aria-label="purchases">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell align="center">Brand</TableCell>
                                          <TableCell align="center">Colour</TableCell>
                                          <TableCell align="center">Size</TableCell>
                                          <TableCell align="center">Thickness</TableCell>
                                          <TableCell align="center">TotalMtrRoll</TableCell> 
                                          <TableCell align="center">Descreption</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                          <TableRow>
                                            <TableCell component="th" scope="row" align="center">
                                              {row.brand}
                                            </TableCell>
                                            <TableCell  align="center">{row.colour}</TableCell>
                                            <TableCell  align="center">{row.size}</TableCell>
                                            <TableCell  align="center">{row.thickness}</TableCell>  
                                            <TableCell  align="center">{row.totalmtrroll}</TableCell>
                                            <TableCell  align="center">{row.description}</TableCell>
                                          </TableRow>
                                      </TableBody>
                                    </Table>
                                     </Box>
                                   </Collapse>
                                 </TableCell>
                               </TableRow>
                               </React.Fragment>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>

                        {this.state.show === 0?"":<>    
                        <div className="form-group col-md-12"/>
                            <div className="form-group col-md-12"><u>Add to List</u></div>

                            <fieldset className="form-group col-md-3">
                              <span className="text-dark">Item Name</span><>:&nbsp;{this.state.itemname}</>
                            </fieldset>
                            <fieldset className="form-group col-md-3"><span>{`Remaining Quantity : ${this.state.pendingquantity-this.state.quantity}`} </span></fieldset>
                            <fieldset className="form-group col-md-6"/>
                          {/* {this.state.type ==="P"? */}
                            <fieldset className="form-group col-md-3">
                                  <FormikTextField
                                    id="quantity"
                                    className="form-control"
                                    name="quantity"
                                    type="number"
                                    label="Quantity"
                                    value={this.state.quantity}
                                    onChange={(e) =>
                                      this.setState({
                                        quantity: e.target.value,
                                      })
                                    }
                                    onFocus={this.onfocus}
                                  />
                                </fieldset>
                            {/* :
                            <fieldset className="form-group col-md-3">
                              <FormikTextField
                                id="quantity"
                                className="form-control"
                                type="text"
                                name="quantity"
                                label="Quantity"
                                value={this.state.quantity}
                                // onChange={(e) => this.setState({ quantity: e.target.value })}
                                // onFocus={this.onfocus}
                                onClick={this.handleSelect}
                                // required
                                disabled
                              />
                            </fieldset>
                          } */}
                             <Modal
                        show={this.state.quantitywindow}
                        onHide={() => this.setState({ quantitywindow: false,show: 0 })}
                        backdrop="static"
                        keyboard={false}
                        centered
                        size="lg"
                      >
                        <Modal.Header closeButton>Select Quantity</Modal.Header>
                        <Modal.Body>
                          <div className="form-row">
                            <fieldset className="form-group col-md-4 m-0">
                              <table className="float-left">
                                <tbody>
                                  <tr>
                                    <td>
                                      
                                      <FormLabel>Pending Quantity :</FormLabel>
                                    </td>
                                    <td>&nbsp;{this.state.pendingquantity}</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <FormLabel>Total Quantity :</FormLabel>
                                    </td>
                                    <td>&nbsp;{this.state.quantity}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </fieldset>
                            <fieldset className="col-md-12">
                              {this.state.itemquantitylist.length === 0 ? (
                                <FormLabel>Out of Stock</FormLabel>
                              ) : (
                                <TableContainer>
                                  <Table>
                                    <TableHead>
                                      <TableRow>
                                        <TableCell className="text-center">
                                          Batch Id
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Price
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Date
                                        </TableCell>
                                        <TableCell className="text-center">
                                          Avilable Quantity
                                        </TableCell>
                                        <TableCell>Select Batch</TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      {this.state.itemquantitylist.map(
                                        (e, i) => (
                                          <TableRow key={i}>
                                            <TableCell className="text-center">
                                              {e.custombatchid}
                                            </TableCell>
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={e.price}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {moment(e.createdAt).format(
                                                "DD-MM-YYYY hh:mm"
                                              )}
                                            </TableCell>
                                            <TableCell className="text-center">
                                              {e.currentstock}
                                            </TableCell>
                                            <TableCell>
                                              {/* <fieldset className="col-md-12">
                                                <TextField
                                                  className="form-control"
                                                  type="text"
                                                  name={`enteredquantity${i}`}
                                                  value={e.enteredquantity}
                                                  InputProps={{
                                                    inputProps: {
                                                      min: 0,
                                                      max: e.currentstock,
                                                    },
                                                  }}
                                                  onChange={(e) =>
                                                    this.handleQuantityChange(
                                                      e.target.value,
                                                      i
                                                    )
                                                  }
                                                  error={
                                                    e.enteredquantity >
                                                      e.currentstock ||
                                                    e.enteredquantity < 0
                                                  }
                                                  helperText={
                                                    e.enteredquantity >
                                                    e.currentstock
                                                      ? "Exceeds Avliable Quantity"
                                                      : e.enteredquantity < 0
                                                      ? "Enter Positive Quantity"
                                                      : ""
                                                  }
                                                />
                                              </fieldset> */}
                                              <button className="btn btn-sm hovbuttonColour" onClick={()=>this.setState({selectedbatch:e,quantitywindow: false})}>Select</button>
                                            </TableCell>
                                          </TableRow>
                                        )
                                      )}
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              )}
                            </fieldset>
                            {/* <fieldset className="col-md-12 mt-3">
                              <Button
                                className="btn btn-sm hovbuttonColour text-white col-md-1 col-sm-12"
                                onClick={this.handleSaveQuantity}
                              >
                                Submit
                              </Button>
                            </fieldset> */}
                            {(this.state.pendingquantity <
                            this.state.quantity)  || this.state.quantity> this.state.selectedbatch.currentstock? (
                              <fieldset className="col-md-12 text-danger mt-2">
                                Exceeds quantity
                              </fieldset>
                            ) : (
                              ""
                            )}
                          </div>
                        </Modal.Body>
                      </Modal>
                            <fieldset className="form-group col-md-12"/>
                            <button className="btn btn-sm hovbuttonColour mr-1" type = "submit">
                              Add Item
                            </button>
                            <span
                        as="button"
                        className="btn btn-sm deletebutton "
                        onClick={() => this.setState({
                          // accepteditems: datalist,
                          quantity: "",
                          description: "",
                          show: 0,
                        })}
                      >
                        <b className=""> X </b>Close
                      </span>
                          </>}

                          {this.state.accepteditems.length === 0 ? (
                            ""
                          ) : (
                            <TableContainer className="mt-5">
                              <Typography>DN Items / Products List</Typography>
                              <Table
                                aria-label="collapsible table"
                                className="table-sm"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell />
                                    <TableCell className="text-center">
                                      Item Name
                                    </TableCell>
                                    {this.state.sodata.taxtype === "NA" ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        HSN
                                      </TableCell>
                                    )}
                                    <TableCell className="text-center">
                                      Price
                                    </TableCell>
                                    {currency === this.state.sodata.currency ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        Price({this.state.sodata.currency})
                                      </TableCell>
                                    )}
                                    {currency === this.state.sodata.currency ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        Price Variance (%)
                                      </TableCell>
                                    )}
                                    <TableCell className="text-center">
                                      Type
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Quantity
                                    </TableCell>
                                    <TableCell className="text-center">
                                      UOM
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Discount (%)
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Discount Amount
                                    </TableCell>
                                    {this.state.sodata.currency === currency ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        Discount Amount (
                                        {this.state.sodata.currency})
                                      </TableCell>
                                    )}
                                    <TableCell className="text-center">
                                      Insurance
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Insurance Amount
                                    </TableCell>
                                    {this.state.sodata.currency === currency ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        Insurance Amount (
                                        {this.state.sodata.currency})
                                      </TableCell>
                                    )}
                                    <TableCell className="text-center">
                                      Total{" "}
                                    </TableCell>
                                    {this.state.sodata.currency === currency ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        Total ({this.state.sodata.currency})
                                      </TableCell>
                                    )}
                                    {this.state.sodata.taxtype === "NA" ? (
                                      ""
                                    ) : (
                                      <>
                                        {this.state.sodata.taxtype === "GST" &&
                                        ccountry === "India" ? (
                                          this.state.sodata.gsttype ===
                                          "INTRASTATE" ? (
                                            <>
                                              <TableCell className="text-center">
                                                CGST(%)
                                              </TableCell>
                                              <TableCell className="text-center">
                                                SGST(%)
                                              </TableCell>
                                            </>
                                          ) : (
                                            <TableCell className="text-center">
                                              IGST(%)
                                            </TableCell>
                                          )
                                        ) : this.state.sodata.taxtype ===
                                          "GST" ? (
                                          <TableCell className="text-center">
                                            GST(%)
                                          </TableCell>
                                        ) : (
                                          <TableCell className="text-center">
                                            VAT(%)
                                          </TableCell>
                                        )}
                                        {this.state.sodata.taxtype === "GST" &&
                                        ccountry === "India" ? (
                                          this.state.sodata.gsttype ===
                                          "INTRASTATE" ? (
                                            <>
                                              <TableCell className="text-center">
                                                CGST Amount
                                              </TableCell>
                                              <TableCell className="text-center">
                                                SGST Amount
                                              </TableCell>
                                              {this.state.sodata.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <>
                                                  {" "}
                                                  <TableCell className="text-center">
                                                    CGST Amount(
                                                    {this.state.sodata.currency}
                                                    )
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    SGST Amount(
                                                    {this.state.sodata.currency}
                                                    )
                                                  </TableCell>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <TableCell className="text-center">
                                                IGST Amount
                                              </TableCell>
                                              {this.state.sodata.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  IGST Amount(
                                                  {this.state.sodata.currency})
                                                </TableCell>
                                              )}
                                            </>
                                          )
                                        ) : this.state.sodata.taxtype ===
                                          "GST" ? (
                                          <>
                                            <TableCell className="text-center">
                                              GST Amount
                                            </TableCell>
                                            {this.state.sodata.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-center">
                                                GST Amount(
                                                {this.state.sodata.currency})
                                              </TableCell>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <TableCell className="text-center">
                                              VAT Amount
                                            </TableCell>
                                            {this.state.sodata.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-center">
                                                VAT Amount(
                                                {this.state.sodata.currency})
                                              </TableCell>
                                            )}
                                          </>
                                        )}
                                        <TableCell className="text-center">
                                          Total Price
                                        </TableCell>
                                        {this.state.sodata.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Total Price(
                                            {this.state.sodata.currency})
                                          </TableCell>
                                        )}
                                      </>
                                    )}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.accepteditems.map(
                                    (row, index) => (
                                      <React.Fragment>
                                        <TableRow key={index}>
                                          <TableCell>
                                            <IconButton
                                              aria-label="expand row"
                                              size="small"
                                              onClick={() =>
                                                this.setState({
                                                  open: !this.state.open,
                                                })
                                              }
                                            >
                                              {this.state.open ? (
                                                <KeyboardArrowUpIcon />
                                              ) : (
                                                <KeyboardArrowDownIcon />
                                              )}
                                            </IconButton>
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {row.name}
                                          </TableCell>
                                          {this.state.sodata.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              {row.hsn}
                                            </TableCell>
                                          )}
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={row.price}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {currency ===
                                          this.state.sodata.currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  row.price *
                                                  this.state.sodata
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          {currency ===
                                          this.state.sodata.currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              {row.pricevariance}
                                            </TableCell>
                                          )}
                                          <TableCell className="text-center">
                                            {row.salesenum}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {row.quantity}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {row.uom}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {row.discount}%
                                          </TableCell>
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={row.discountamount}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.sodata.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  row.discountamount *
                                                  this.state.sodata
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          <TableCell className="text-center">
                                            {row.insurance}%
                                          </TableCell>
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={row.insuranceamount}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.sodata.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  row.insuranceamount *
                                                  this.state.sodata
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={row.totalprice}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.sodata.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  row.totalprice *
                                                  this.state.sodata
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          {this.state.sodata.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : (
                                            <>
                                              {this.state.sodata.taxtype ===
                                                "GST" &&
                                              this.state.sodata.gsttype ===
                                                "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    {row.tax / 2}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {row.tax / 2}
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <>
                                                  <TableCell className="text-center">
                                                    {row.tax}
                                                  </TableCell>
                                                </>
                                              )}
                                              {this.state.sodata.taxtype ===
                                                "GST" &&
                                              this.state.sodata.gsttype ===
                                                "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        row.taxamount * 0.5
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={row.taxamount / 2}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  {this.state.sodata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            (row.taxamount /
                                                              2) *
                                                            this.state.sodata
                                                              .curconversionrate
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                      </TableCell>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            (row.taxamount /
                                                              2) *
                                                            this.state.sodata
                                                              .curconversionrate
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                      </TableCell>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={row.taxamount}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  {this.state.sodata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={
                                                          row.taxamount *
                                                          this.state.sodata
                                                            .curconversionrate
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    </TableCell>
                                                  )}
                                                </>
                                              )}
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.totalpricewtax}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.sodata.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.totalpricewtax *
                                                      this.state.sodata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                            </>
                                          )}
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            style={{
                                              paddingBottom: 0,
                                              paddingTop: 0,
                                            }}
                                            colSpan={6}
                                          >
                                            <Collapse
                                              in={this.state.open}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <Box margin={1}>
                                                <Table
                                                  size="small"
                                                  aria-label="purchases"
                                                >
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell align="center">
                                                        Brand
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        Colour
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        Size
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        Thickness
                                                      </TableCell>
                                                      {/* <TableCell align="center">UOM</TableCell> */}
                                                      <TableCell align="center">
                                                        TotalMtrRoll
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        Description
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    <TableRow>
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                      >
                                                        {row.brand}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {row.colour}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {row.size}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {row.thickness}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {row.totalmtrroll}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {row.description}
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableBody>
                                                </Table>
                                              </Box>
                                            </Collapse>
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}
                        </>
                      )}

{this.state.precloseitems.length === 0 ? (
                            ""
                          ) : (
                            <TableContainer className="mt-5">
                              <Typography>Preclose Items / Products List</Typography>
                              <Table
                                aria-label="collapsible table"
                                className="table-sm"
                              >
                                <TableHead>
                                  <TableRow>
                                    <TableCell />
                                    <TableCell className="text-center">
                                      Item Name
                                    </TableCell>
                                    {this.state.sodata.taxtype === "NA" ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        HSN
                                      </TableCell>
                                    )}
                                    <TableCell className="text-center">
                                      Price
                                    </TableCell>
                                    {currency === this.state.sodata.currency ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        Price({this.state.sodata.currency})
                                      </TableCell>
                                    )}
                                    {currency === this.state.sodata.currency ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        Price Variance (%)
                                      </TableCell>
                                    )}
                                    <TableCell className="text-center">
                                      Type
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Preclose Quantity
                                    </TableCell>
                                    <TableCell className="text-center">
                                      UOM
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Discount (%)
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Discount Amount
                                    </TableCell>
                                    {this.state.sodata.currency === currency ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        Discount Amount (
                                        {this.state.sodata.currency})
                                      </TableCell>
                                    )}
                                    <TableCell className="text-center">
                                      Insurance
                                    </TableCell>
                                    <TableCell className="text-center">
                                      Insurance Amount
                                    </TableCell>
                                    {this.state.sodata.currency === currency ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        Insurance Amount (
                                        {this.state.sodata.currency})
                                      </TableCell>
                                    )}
                                    <TableCell className="text-center">
                                      Total{" "}
                                    </TableCell>
                                    {this.state.sodata.currency === currency ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        Total ({this.state.sodata.currency})
                                      </TableCell>
                                    )}
                                    {this.state.sodata.taxtype === "NA" ? (
                                      ""
                                    ) : (
                                      <>
                                        {this.state.sodata.taxtype === "GST" &&
                                        ccountry === "India" ? (
                                          this.state.sodata.gsttype ===
                                          "INTRASTATE" ? (
                                            <>
                                              <TableCell className="text-center">
                                                CGST(%)
                                              </TableCell>
                                              <TableCell className="text-center">
                                                SGST(%)
                                              </TableCell>
                                            </>
                                          ) : (
                                            <TableCell className="text-center">
                                              IGST(%)
                                            </TableCell>
                                          )
                                        ) : this.state.sodata.taxtype ===
                                          "GST" ? (
                                          <TableCell className="text-center">
                                            GST(%)
                                          </TableCell>
                                        ) : (
                                          <TableCell className="text-center">
                                            VAT(%)
                                          </TableCell>
                                        )}
                                        {this.state.sodata.taxtype === "GST" &&
                                        ccountry === "India" ? (
                                          this.state.sodata.gsttype ===
                                          "INTRASTATE" ? (
                                            <>
                                              <TableCell className="text-center">
                                                CGST Amount
                                              </TableCell>
                                              <TableCell className="text-center">
                                                SGST Amount
                                              </TableCell>
                                              {this.state.sodata.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <>
                                                  {" "}
                                                  <TableCell className="text-center">
                                                    CGST Amount(
                                                    {this.state.sodata.currency}
                                                    )
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    SGST Amount(
                                                    {this.state.sodata.currency}
                                                    )
                                                  </TableCell>
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <TableCell className="text-center">
                                                IGST Amount
                                              </TableCell>
                                              {this.state.sodata.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-center">
                                                  IGST Amount(
                                                  {this.state.sodata.currency})
                                                </TableCell>
                                              )}
                                            </>
                                          )
                                        ) : this.state.sodata.taxtype ===
                                          "GST" ? (
                                          <>
                                            <TableCell className="text-center">
                                              GST Amount
                                            </TableCell>
                                            {this.state.sodata.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-center">
                                                GST Amount(
                                                {this.state.sodata.currency})
                                              </TableCell>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {" "}
                                            <TableCell className="text-center">
                                              VAT Amount
                                            </TableCell>
                                            {this.state.sodata.currency ===
                                            currency ? (
                                              ""
                                            ) : (
                                              <TableCell className="text-center">
                                                VAT Amount(
                                                {this.state.sodata.currency})
                                              </TableCell>
                                            )}
                                          </>
                                        )}
                                        <TableCell className="text-center">
                                          Total Price
                                        </TableCell>
                                        {this.state.sodata.currency ===
                                        currency ? (
                                          ""
                                        ) : (
                                          <TableCell className="text-center">
                                            Total Price(
                                            {this.state.sodata.currency})
                                          </TableCell>
                                        )}
                                      </>
                                    )}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.precloseitems.map(
                                    (row, index) => (
                                      <React.Fragment>
                                        <TableRow key={index}>
                                          <TableCell>
                                            <IconButton
                                              aria-label="expand row"
                                              size="small"
                                              onClick={() =>
                                                this.setState({
                                                  open: !this.state.open,
                                                })
                                              }
                                            >
                                              {this.state.open ? (
                                                <KeyboardArrowUpIcon />
                                              ) : (
                                                <KeyboardArrowDownIcon />
                                              )}
                                            </IconButton>
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {row.name}
                                          </TableCell>
                                          {this.state.sodata.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              {row.hsn}
                                            </TableCell>
                                          )}
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={row.price}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {currency ===
                                          this.state.sodata.currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  row.price *
                                                  this.state.sodata
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          {currency ===
                                          this.state.sodata.currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-center">
                                              {row.pricevariance}
                                            </TableCell>
                                          )}
                                          <TableCell className="text-center">
                                            {row.salesenum}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {row.quantity}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {row.uom}
                                          </TableCell>
                                          <TableCell className="text-center">
                                            {row.discount}%
                                          </TableCell>
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={row.discountamount}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.sodata.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  row.discountamount *
                                                  this.state.sodata
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          <TableCell className="text-center">
                                            {row.insurance}%
                                          </TableCell>
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={row.insuranceamount}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.sodata.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  row.insuranceamount *
                                                  this.state.sodata
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          <TableCell className="text-right">
                                            <NumericFormat
                                              displayType={"text"}
                                              value={row.totalprice}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              disabled={true}
                                              thousandSeparator={true}
                                              thousandsGroupStyle={
                                                thousandsdisplay
                                              }
                                            ></NumericFormat>
                                          </TableCell>
                                          {this.state.sodata.currency ===
                                          currency ? (
                                            ""
                                          ) : (
                                            <TableCell className="text-right">
                                              <NumericFormat
                                                displayType={"text"}
                                                value={
                                                  row.totalprice *
                                                  this.state.sodata
                                                    .curconversionrate
                                                }
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                disabled={true}
                                                thousandSeparator={true}
                                                thousandsGroupStyle={
                                                  thousandsdisplay
                                                }
                                              ></NumericFormat>
                                            </TableCell>
                                          )}
                                          {this.state.sodata.taxtype ===
                                          "NA" ? (
                                            ""
                                          ) : (
                                            <>
                                              {this.state.sodata.taxtype ===
                                                "GST" &&
                                              this.state.sodata.gsttype ===
                                                "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-center">
                                                    {row.tax / 2}
                                                  </TableCell>
                                                  <TableCell className="text-center">
                                                    {row.tax / 2}
                                                  </TableCell>
                                                </>
                                              ) : (
                                                <>
                                                  <TableCell className="text-center">
                                                    {row.tax}
                                                  </TableCell>
                                                </>
                                              )}
                                              {this.state.sodata.taxtype ===
                                                "GST" &&
                                              this.state.sodata.gsttype ===
                                                "INTRASTATE" ? (
                                                <>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={
                                                        row.taxamount * 0.5
                                                      }
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={row.taxamount / 2}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  {this.state.sodata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            (row.taxamount /
                                                              2) *
                                                            this.state.sodata
                                                              .curconversionrate
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                      </TableCell>
                                                      <TableCell className="text-right">
                                                        <NumericFormat
                                                          displayType={"text"}
                                                          value={
                                                            (row.taxamount /
                                                              2) *
                                                            this.state.sodata
                                                              .curconversionrate
                                                          }
                                                          decimalScale={2}
                                                          fixedDecimalScale={
                                                            true
                                                          }
                                                          disabled={true}
                                                          thousandSeparator={
                                                            true
                                                          }
                                                          thousandsGroupStyle={
                                                            thousandsdisplay
                                                          }
                                                        ></NumericFormat>
                                                      </TableCell>
                                                    </>
                                                  )}
                                                </>
                                              ) : (
                                                <>
                                                  <TableCell className="text-right">
                                                    <NumericFormat
                                                      displayType={"text"}
                                                      value={row.taxamount}
                                                      decimalScale={2}
                                                      fixedDecimalScale={true}
                                                      disabled={true}
                                                      thousandSeparator={true}
                                                      thousandsGroupStyle={
                                                        thousandsdisplay
                                                      }
                                                    ></NumericFormat>
                                                  </TableCell>
                                                  {this.state.sodata
                                                    .currency === currency ? (
                                                    ""
                                                  ) : (
                                                    <TableCell className="text-right">
                                                      <NumericFormat
                                                        displayType={"text"}
                                                        value={
                                                          row.taxamount *
                                                          this.state.sodata
                                                            .curconversionrate
                                                        }
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        disabled={true}
                                                        thousandSeparator={true}
                                                        thousandsGroupStyle={
                                                          thousandsdisplay
                                                        }
                                                      ></NumericFormat>
                                                    </TableCell>
                                                  )}
                                                </>
                                              )}
                                              <TableCell className="text-right">
                                                <NumericFormat
                                                  displayType={"text"}
                                                  value={row.totalpricewtax}
                                                  decimalScale={2}
                                                  fixedDecimalScale={true}
                                                  disabled={true}
                                                  thousandSeparator={true}
                                                  thousandsGroupStyle={
                                                    thousandsdisplay
                                                  }
                                                ></NumericFormat>
                                              </TableCell>
                                              {this.state.sodata.currency ===
                                              currency ? (
                                                ""
                                              ) : (
                                                <TableCell className="text-right">
                                                  <NumericFormat
                                                    displayType={"text"}
                                                    value={
                                                      row.totalpricewtax *
                                                      this.state.sodata
                                                        .curconversionrate
                                                    }
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                    disabled={true}
                                                    thousandSeparator={true}
                                                    thousandsGroupStyle={
                                                      thousandsdisplay
                                                    }
                                                  ></NumericFormat>
                                                </TableCell>
                                              )}
                                            </>
                                          )}
                                        </TableRow>
                                        <TableRow>
                                          <TableCell
                                            style={{
                                              paddingBottom: 0,
                                              paddingTop: 0,
                                            }}
                                            colSpan={6}
                                          >
                                            <Collapse
                                              in={this.state.open}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <Box margin={1}>
                                                <Table
                                                  size="small"
                                                  aria-label="purchases"
                                                >
                                                  <TableHead>
                                                    <TableRow>
                                                      <TableCell align="center">
                                                        Brand
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        Colour
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        Size
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        Thickness
                                                      </TableCell>
                                                      {/* <TableCell align="center">UOM</TableCell> */}
                                                      <TableCell align="center">
                                                        TotalMtrRoll
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        Description
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    <TableRow>
                                                      <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align="center"
                                                      >
                                                        {row.brand}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {row.colour}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {row.size}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {row.thickness}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {row.totalmtrroll}
                                                      </TableCell>
                                                      <TableCell align="center">
                                                        {row.description}
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableBody>
                                                </Table>
                                              </Box>
                                            </Collapse>
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          )}

                      <fieldset className="form-group col-md-12" />
                    

                      <button
                        as="button"
                        type="button"
                        className="btn btn-sm hovbuttonColour mr-1"
                        onClick={this.handlesubmit}
                        disabled={(this.state.accepteditems.length === 0 && this.state.precloseitems.length ===0) || this.state.loader === true}
                      >
                        {this.state.loader === true ? (
                          <>
                            <Spinner
                              className="mr-1"
                              animation="border"
                              size="sm"
                            />
                            {"Saving"}
                          </>
                        ) : (
                          <>
                            <i className="fas fa-save mr-1" />
                            {"Save"}
                          </>
                        )}
                      </button>

                      <Link
                        as="button"
                        className="btn btn-sm deletebutton "
                        to={{ pathname: "/deliverynote" }}
                      >
                        <b className=""> X </b>Cancel
                      </Link>

                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(newDn);
