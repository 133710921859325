import { Field, Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import withRouter from "../../../withRouter.js";
import { userid, currentTenant } from '../../../Common/Credentials.js';
import Footer from '../../../Component/Footer.js';
import Header from '../../../Component/Header.js';
import Menu from '../../../Component/Menu.js';
import { FormikTextField } from '../../../CommonComponents/FormField.js';
import CategoryServices from '../CategoryServices.js';
import { Spinner } from 'react-bootstrap';

class NewCategorylvl3 extends Component {

  
  constructor(props) {
    super(props)
    this.state = {
        pagename:this.props.location.state=== undefined?'':this.props.location.state.pagename,
        subcatid:this.props.location.state=== undefined?'':this.props.location.state.catidlvl2,
        category:this.props.location.state=== undefined?'':this.props.location.state.mcategory,
        catid:this.props.location.state=== undefined?'':this.props.location.state.mcatid,
        categoryType:this.props.location.state=== undefined?'':this.props.location.state.categoryType,
        subcategory:this.props.location.state=== undefined?'':this.props.location.state.categorylvl2,
        name: '',
        message:'',
        loader:false,
    }
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  onSubmit(values) {
    this.setState({loader:true})
    let addcategory = { 
      category:this.state.name,
      catid:this.state.subcatid,
      categorytype:this.state.categoryType==="Service"?1:0,
      tenantid: currentTenant
    }
    CategoryServices.addCategory(addcategory).then(() => 
    this.props.navigate({pathname:'/categorylvl3'} ,{state :{categorylvl2:this.state.subcatid,
      mcategory:this.state.category,mcatid:this.state.catid,categoryType:this.state.categoryType}}))
    .catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
      this.setState({message:e.response.data.message,loader:false})
    })
  }

  handletype = (value,values) => {
    if(value !== '' && typeof values.type==='object' ){
    this.setState({ typeid: values.type.id, type: value,message:'' });
    }else{
      this.setState({ typeid:'', type: '',message:'' });
    }
  };  

  componentDidMount() {

    if(userid===undefined){
      window.confirm('Your Session is Lost: Please login Back to resume')
      this.props.navigate(`/`)
    }
 }


  validate(values) {

    let errors = {}

     if (!values.name|| values.name==='') {
     errors.name = "Sub Category Name is Required";
      }
    return errors;
  }

  render() {
     let {name,category,subcatid,subcategory} = this.state;
 
    return (
      
      <div>
         <Header/>
         <Menu/>
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <Link
                as="button"
                className=" text-secondary"
                to={{pathname:'/categorylvl3'}}state={{categorylvl2:this.state.subcatid,
                  mcategory:this.state.category,mcatid:this.state.catid,categoryType:this.state.categoryType}}
              >
                ❮ Back &nbsp;&nbsp;
              </Link>
            </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/inventory">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link  to={{pathname:'/categorylvl3'}} state ={{categorylvl2:this.state.subcatid,
                  mcategory:this.state.category,mcatid:this.state.catid,categoryType:this.state.categoryType}}>Category Level 3</Link>
              </li>
              <li className="breadcrumb-item active">New Category Level 3</li>
            </ol>
            <div className="card-header">
              <h3 className="card-title">New Category level 3</h3>
            </div>
            <div className="card-body">
            
              <Formik
                initialValues={{name,category,subcategory,subcatid}}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}>
                {({setFieldValue,values}) => (
                  <Form autoComplete="off">
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-6">
                        <label>Id</label>
                        <Field className="form-control" type="text" name="id" placeholder="Id"/>
                      </fieldset>

                      <fieldset className="form-group col-md-6">  
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="category"
                              label="Category"
                              value={this.state.category}
                              variant="standard"
                              fullWidth
                              disabled
                            />
                      </fieldset>
                      <fieldset className="form-group col-md-6">  
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="subcategory"
                              label="Sub Category"
                              variant="standard"
                              value={this.state.subcategory}
                              fullWidth
                              disabled
                            />
                      </fieldset>

                      <fieldset className="form-group col-md-6">
                      <FormikTextField className="form-control"  type="text" name="name" label="Sub Category Name" onChange={(e)=>this.setState({name:e.target.value,message:''})}/>
                      </fieldset>

                    </div>
                    <div className="form-group col-md-12 text-red">{this.state.message}</div>
                   {this.state.loader===true?  <button className="btn btn-sm hovbuttonColour" disabled> <Spinner animation="border" size="sm" />&nbsp;{"Saving"}</button>:<button className="btn btn-sm hovbuttonColour" type="submit">  <i className="fa fa-save mr-2"/>Save</button>}
                    <Link as="button" className="btn btn-sm deletebutton ml-1"  to={{pathname:'/categorylvl3'}} state ={{categorylvl2:this.state.subcatid,
                  mcategory:this.state.category,mcatid:this.state.catid,categoryType:this.state.categoryType}} ><b style={{color:"#FA5733"}}> X</b> Cancel</Link> 
                  
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>

      <Footer/>
      </div>
    );
  }
}
export default withRouter(NewCategorylvl3)