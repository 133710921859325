import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

export let currentTenant

class HSNServices{
    getHSNs(currentTenant) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/gethsngst/${currentTenant}`)
     }
     add(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/addhsn`,data)
     }
     uploadHSN(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/uploadhsn`,data)
   }
     delete(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/masters/deletehsn/${id}`)
     }
}
export default new HSNServices()