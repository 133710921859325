import React, { useEffect, useState } from "react";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import { Form, Formik } from "formik";
import { FormikTextField } from "../../CommonComponents/FormField";
import withRouter from "../../withRouter";
import { Link } from "react-router-dom";
import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Footer from "../../Component/Footer";
import SalesServices from "../SalesServices";
import {
  currentTenant,
  thousandsdisplay,
  userid,
} from "../../Common/Credentials";
import Swal from "sweetalert2";
import { NumericFormat } from "react-number-format";

const CustomerDebitnote = (props) => {
  const [invdata, setInvdata] = useState("");
  const [customer, setCustomer] = useState("");
  const [custid, setCustid] = useState("");
  const [tax, setTax] = useState("");
  const [sgst, setSgst] = useState("");
  const [cgst, setCgst] = useState("");
  const [amount, setAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [description, setDescription] = useState("");
  const [types] = useState(["Pricediff", "Returns"]);
  const [type, setType] = useState("Pricediff");
  const [loader, setLoader] = useState(false);
  const [selected, setSelected] = useState();
  const [rowitemid, setRowitemid] = useState("");
  const [additionalrowid, setAdditionalrowid] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [totalAmountwtax, setTotalAmountwtax] = useState(0);
  const [creditnoteitems, setCreditnoteitems] = useState([]);

  useEffect(() => {
    setInvdata(props.location.state.invdata);
    setCustomer(props.location.state.customer);
    setCustid(props.location.state.custid);
  }, []);

  const handleSelect = (val) => {
    setSelected(val);
    setSgst(val.tax / 2);
    setCgst(val.tax / 2);
    setTax(val.tax);
    setRowitemid(val.id);
    setQuantity(val.quantity);
  };

  const onSubmit = () => {
    let data = {
      invid: invdata.custominvid,
      updatedby: userid,
      amount: amount,
      tax: tax,
      totalamount: totalAmount,
      totalAmountwtax: totalAmountwtax,
      tenanid: currentTenant,
      id: null,
      type: type,
      description: description,
      rowitemid: rowitemid,
      additionalrowid: additionalrowid,
      quantity: 0,
    };
    Swal.fire({
      title: `Generate Debit Note?`,
      text: "Are you sure?",
      icon: "question",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#152f5f",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        setLoader(true);
        SalesServices.GenerateDebitNote(data)
          .then(() => props.navigate(-1))
          .catch((error) => setLoader(false));
      }
    });
  };

  const validate = () => {
    let errors = {};
    if (amount === "" || amount === 0) {
      errors.amount = "Please Enter amount";
    }
    if (description === "") {
      errors.description = "Please Enter Reason/Narration";
    }
    return errors;
  };
  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className=" text-secondary">
              <span
                as="button"
                className=" text-secondary cursorPointer"
                onClick={() => props.navigate(-1)}
              >
                ❮ Back &nbsp;&nbsp;
              </span>
            </li>
            <li className="breadcrumb-item text-secondary">
              <Link to="/inventory">Home</Link>
            </li>
            {/* <li className="breadcrumb-item text-secondary">
              <Link to="/customerDebitnote">Customer Credit Note</Link>
            </li> */}
            <li className="breadcrumb-item active">Customer Credit Note</li>
          </ol>
          <div className="card-header">
            <h3 className="card-title">Customer Credit Note</h3>
          </div>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loader}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <div className="card-body">
            <Formik
              initialValues={{
                customer,
                amount,
                cgst,
                sgst,
                tax,
                totalAmount,
                description,
              }}
              onSubmit={onSubmit}
              validateOnChange={false}
              validate={validate}
              enableReinitialize={true}
            >
              {({ setFieldValue, values }) => (
                <Form autoComplete="off">
                  <div className="form-row">
                    <fieldset className="col-md-3">
                      <FormikTextField
                        className="form-control"
                        type="text"
                        name="customer"
                        value={customer}
                        label={`Customer`}
                        variant="standard"
                        fullWidth
                        disabled
                      />
                    </fieldset>

                    <fieldset className="col-md-3">
                      <FormikTextField
                        className="form-control"
                        type="text"
                        name="invoice"
                        value={invdata.custominvid}
                        label={`Invoice`}
                        variant="standard"
                        fullWidth
                        disabled
                      />
                    </fieldset>

                    {/* <fieldset className="form-group col-md-3">
                      <FormControl variant="standard" className="col-md-12">
                        <InputLabel id="demo-simple-select-standard-label">
                          Type
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          value={type}
                          onChange={(e) => {
                            setType(e.target.value);
                            setSelected("");
                            setSgst("");
                            setCgst("");
                            setTax("");
                            setRowitemid("");
                            setQuantity(0);
                            setCreditnoteitems([])
                          }}
                          label="Type"
                          fullWidth
                        >
                          {types.map((e, i) => (
                            <MenuItem value={e} key={i}>
                              {e}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </fieldset> */}
                    <>
                      {rowitemid === null ||
                      rowitemid === "" ||
                      rowitemid === undefined ? (
                        <TableContainer className="mt-5">
                          <Typography>Products</Typography>
                          <Table
                            aria-label="collapsible table"
                            className="table-sm"
                          >
                            <TableHead>
                              <TableRow>
                                {/* <TableCell /> */}
                                <TableCell className="text-center">
                                  Item Name
                                </TableCell>
                                {invdata.taxtype === "NA" ? (
                                  ""
                                ) : (
                                  <TableCell className="text-center">
                                    HSN
                                  </TableCell>
                                )}
                                <TableCell className="text-center">
                                  Price
                                </TableCell>
                                <TableCell className="text-center">
                                  Quantity
                                </TableCell>

                                <TableCell className="text-center">
                                  Total{" "}
                                </TableCell>
                                <TableCell className="text-center">
                                  Grand Total{" "}
                                </TableCell>
                                <TableCell>Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {invdata?.invoiceitemlist?.map((row, index) => (
                                <React.Fragment key={index}>
                                  <TableRow>
                                    <TableCell className="text-center">
                                      {row.name}
                                    </TableCell>
                                    {invdata.taxtype === "NA" ? (
                                      ""
                                    ) : (
                                      <TableCell className="text-center">
                                        {row.hsn}
                                      </TableCell>
                                    )}
                                    <TableCell className="text-right">
                                      <NumericFormat
                                        displayType={"text"}
                                        value={row.price}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell className="text-center">
                                      {row.quantity}
                                      {row.uom}
                                    </TableCell>

                                    <TableCell className="text-right">
                                      <NumericFormat
                                        displayType={"text"}
                                        value={row.totalprice}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>

                                    <TableCell className="text-right">
                                      <NumericFormat
                                        displayType={"text"}
                                        value={row.totalpricewtax}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        disabled={true}
                                        thousandSeparator={true}
                                        thousandsGroupStyle={thousandsdisplay}
                                      ></NumericFormat>
                                    </TableCell>
                                    <TableCell>
                                      <span
                                        className="btn btn-sm hovbuttonColour cursorPointer"
                                        onClick={() => handleSelect(row)}
                                      >
                                        Select
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ) : (
                        ""
                      )}
                      <fieldset className="form-group col-md-12" />
                      <fieldset className="col-md-2">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="amount"
                          value={amount}
                          label={`Diff Amount`}
                          onChange={(e) => {
                            setAmount(e.target.value);
                            setTotalAmount(
                              parseFloat(
                                e.target.value === null || e.target.value === ""
                                  ? 0
                                  : e.target.value
                              ).toFixed(2)
                            );
                            setTotalAmountwtax(
                              (
                                parseFloat(
                                  e.target.value === null ||
                                    e.target.value === ""
                                    ? 0
                                    : e.target.value
                                ) +
                                (parseFloat(
                                  tax === null ||
                                    tax === "" ||
                                    tax === undefined
                                    ? 0
                                    : tax
                                ) *
                                  parseFloat(
                                    e.target.value === null ||
                                      e.target.value === ""
                                      ? 0
                                      : e.target.value
                                  )) /
                                  100
                              ).toFixed(2)
                            );
                          }}
                          variant="standard"
                          fullWidth
                        />
                      </fieldset>
                      {invdata.gsttype === "INTRASTATE" ? (
                        <>
                          <fieldset className="col-md-2">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="cgst"
                              onChange={(e) => {
                                setCgst(e.target.value);
                                setSgst(e.target.value);
                                setTax(parseFloat(e.target.value * 2));
                                setTotalAmount(
                                  parseFloat(
                                    amount === null || amount === ""
                                      ? 0
                                      : amount
                                  ).toFixed(2)
                                );
                                setTotalAmountwtax(
                                  (
                                    parseFloat(
                                      amount === null || amount === ""
                                        ? 0
                                        : amount
                                    ) +
                                    (parseFloat(
                                      e.target.value === null ||
                                        e.target.value === ""
                                        ? 0
                                        : e.target.value
                                    ) *
                                      2 *
                                      parseFloat(
                                        amount === null || amount === ""
                                          ? 0
                                          : amount
                                      )) /
                                      100
                                  ).toFixed(2)
                                );
                              }}
                              value={cgst}
                              label={`CGST`}
                              variant="standard"
                              fullWidth
                            />
                          </fieldset>
                          <fieldset className="col-md-2">
                            <FormikTextField
                              className="form-control"
                              type="text"
                              name="sgst"
                              onChange={(e) => {
                                setCgst(e.target.value);
                                setSgst(e.target.value);
                                setTax(parseFloat(e.target.value * 2));
                                setTotalAmount(
                                  parseFloat(
                                    amount === null || amount === ""
                                      ? 0
                                      : amount
                                  ).toFixed(2)
                                );
                                setTotalAmountwtax(
                                  (
                                    parseFloat(
                                      amount === null || amount === ""
                                        ? 0
                                        : amount
                                    ) +
                                    (parseFloat(
                                      e.target.value === null ||
                                        e.target.value === ""
                                        ? 0
                                        : e.target.value
                                    ) *
                                      2 *
                                      parseFloat(
                                        amount === null || amount === ""
                                          ? 0
                                          : amount
                                      )) /
                                      100
                                  ).toFixed(2)
                                );
                              }}
                              value={sgst}
                              label={`SGST`}
                              variant="standard"
                              fullWidth
                            />
                          </fieldset>
                        </>
                      ) : (
                        <fieldset className="col-md-2">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="tax"
                            onChange={(e) => {
                              setCgst(parseFloat(e.target.value) / 2);
                              setSgst(parseFloat(e.target.value) / 2);
                              setTax(e.target.value);
                              setTotalAmount(
                                parseFloat(
                                  amount === null || amount === "" ? 0 : amount
                                ).toFixed(2)
                              );
                              setTotalAmountwtax(
                                (
                                  parseFloat(
                                    amount === null || amount === ""
                                      ? 0
                                      : amount
                                  ) +
                                  (parseFloat(
                                    e.target.value === null ||
                                      e.target.value === ""
                                      ? 0
                                      : e.target.value
                                  ) *
                                    parseFloat(
                                      amount === null || amount === ""
                                        ? 0
                                        : amount
                                    )) /
                                    100
                                ).toFixed(2)
                              );
                            }}
                            value={tax}
                            label={`IGST`}
                            variant="standard"
                            fullWidth
                          />
                        </fieldset>
                      )}
                      {/* <fieldset className="col-md-2">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="quantity"
                            value={quantity}
                            label={`Quantity`}
                            variant="standard"
                            fullWidth
                            disabled
                          />
                        </fieldset>
                        <fieldset className="col-md-2">
                          <FormikTextField
                            className="form-control"
                            type="text"
                            name="totalAmount"
                            value={totalAmount}
                            label={`Total Amount`}
                            variant="standard"
                            fullWidth
                            disabled
                          />
                        </fieldset> */}
                      <fieldset className="col-md-2">
                        <FormikTextField
                          className="form-control"
                          type="text"
                          name="totalAmountwtax"
                          value={totalAmountwtax}
                          label={`Total`}
                          variant="standard"
                          fullWidth
                          disabled
                        />
                      </fieldset>
                      <fieldset className="form-group col-md-12  mt-3 mb-5">
                        <FormikTextField
                          name="description"
                          className="form-control"
                          multiline
                          rows={1}
                          label="Reason/Narration"
                          variant="outlined"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </fieldset>
                    </>
                    <button type="submit" className="btn btn-sm hovbuttonColour">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(CustomerDebitnote);
