import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import moment from "moment";
  import React, { useEffect, useState } from "react";
  import { useNavigate } from "react-router";
  import { Link } from "react-router-dom";
  
  
  import { spin } from "../../Common/NewLoader";
  import Header from "../../Component/Header";
  import Menu from "../../Component/Menu";
  import ProductionServices from "../ProductionServices";
  import Footer from "../../Component/Footer";
import withRouter from "../../withRouter";
  
  const IndentingDetails = (props) => {
    const navigate = useNavigate();
    const [predata] = useState(props.location.state);  
    const [data, setData] = useState("");
    const [status, setStatus] = useState();
  
    useEffect(() => {
      ProductionServices.getIndentingbyId(predata.id).then((response) => {
        setData(response.data);
        setStatus(response.data.status);
      });
    }, [predata]);
  
   
    // const handlepdfDownload = () => {
    //   let context1 =
    //     "Context : The order number will be referenced throughout the transaction process by both buyer and seller.";
    //   let date = moment(data.createdAt).format("DD-MM-yyyy");
    //   let startdate = moment(data.startdate).format("DD-MM-yyyy");
    //   let bomitems = [
    //     { header: "Item", dataKey: "itemname" },
    //     { header: "Quantity", dataKey: "quantity" },
    //   ];
    //   let equipments = [
    //     { header: "Equipments", dataKey: "name" },
    //     { header: "Quantity", dataKey: "quantity" },
    //   ];
    //   let manpower = [
    //     { header: "Man Type", dataKey: "mantype" },
    //     { header: "Man Count", dataKey: "mancount" },
    //   ];
    //   let finalproducts = [
    //     { header: "Item", dataKey: "productname" },
    //     { header: "Quantity", dataKey: "quantity" },
    //   ];
    //   let qastatus = data.qastatus === true ? "YES" : "NO";
  
    //   // let declaration = "Declaration: We declare that the invoice shows the acctual price of the goods described and the particulers are true and correct."
  
    //   const toWords = new ToWords();
    //   // var totalinwords = toWords.convert(data.sototalwtax)
    //   const doc = new jsPDF("p", "pt", "a4");
    //   doc.addImage(logo, "GIF", 40, 20, 100, 100);
    //   doc.setFontSize(16);
    //   doc.setFont("Times-Bold");
    //   doc.text(`${clientname}`, 560, 40, "right");
    //   doc.setFontSize(11);
    //   doc.setFont("Times-Roman");
    //   doc.text(`${address}, ${address2}`, 560, 55, "right");
    //   doc.text(`${ccity}, ${cstate} - ${czip}`, 560, 70, "right");
    //   doc.text(`${ccountry}`, 560, 85, "right");
    //   doc.text(`${companyemail}`, 560, 100, "right");
    //   doc.text(`${contactnumber}`, 560, 115, "right");
    //   doc.setFontSize(16);
    //   doc.setFont("Times-Bold");
    //   doc.text("Indenting", 200, 140);
    //   doc.line(0, 150, 600, 150);
    //   doc.setFontSize(11);
    //   doc.setFont("Times-Roman");
    //   doc.text(`Estimation ID : ${data.id}`, 560, 180, "right");
    //   doc.text(`Date : ${date}`, 560, 195, "right");
    //   doc.text(`Soid : ${data.soid}`, 560, 210, "right");
    //   doc.text(`Workcenter : ${data.workcenter}`, 560, 225, "right");
    //   doc.text(`Type : ${data.estimationtype}`, 560, 240, "right");
    //   doc.text(`Start date : ${startdate}`, 560, 255, "right");
    //   doc.text(`Duration : ${data.duration}`, 560, 270, "right");
    //   doc.text(`QA Required? : ${qastatus}`, 560, 285, "right");
    //   doc.text("To,", 40, 180);
    //   doc.text(`${data.to}`, 50, 195);
  
    //   // let context=doc.splitTextToSize(this.state.templete.content,510);
    //   // doc.text(context,40,300)
    //   doc.text(`Bom Items`, 40, 330);
    //   autoTable(doc, {
    //     theme: "grid",
    //     startY: 340,
    //     body: data.bomitems,
    //     columns: bomitems,
    //   });
  
    //   doc.text(`Equipments`, 40, doc.autoTable.previous.finalY + 30);
    //   autoTable(doc, {
    //     theme: "grid",
    //     startY: doc.autoTable.previous.finalY + 40,
    //     body: data.equipments,
    //     columns: equipments,
    //   });
  
    //   doc.text(`Man Power`, 40, doc.autoTable.previous.finalY + 30);
    //   autoTable(doc, {
    //     theme: "grid",
    //     startY: doc.autoTable.previous.finalY + 40,
    //     body: data.manpower,
    //     columns: manpower,
    //   });
    //   doc.text(`Final Products`, 40, doc.autoTable.previous.finalY + 30);
    //   autoTable(doc, {
    //     theme: "grid",
    //     startY: doc.autoTable.previous.finalY + 40,
    //     body: data.finalproduct,
    //     columns: finalproducts,
    //   });
  
    //   doc.text("Sd/-.", 40, doc.autoTable.previous.finalY + 100);
    //   doc.text("Sales Team,", 40, doc.autoTable.previous.finalY + 115);
    //   doc.text(`${clientname}`, 40, doc.autoTable.previous.finalY + 130);
    //   doc.save(`Estimation-${data.id}.pdf`);
    // };
  
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className=" text-secondary">
                <span
                  as="button"
                  className=" text-secondary cursorPointer"
                  onClick={() => navigate(-1)}
                >
                  ❮ Back &nbsp;&nbsp;
                </span>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/production">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to="/indenting">Indenting</Link>
              </li>
              <li className="breadcrumb-item active">
                Indenting Details
              </li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                {data === "" ? (
                  <div className="text-center">{spin}</div>
                ) : (
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title"> Indenting Details</h3>
                      {status === "Cancelled" || status === "Completed" ? (
                        ""
                      ) : (
                        <>
                          <button
                            className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-envelope text-md ml-1"
                            data-tip
                            data-for="mail"
                            onClick={() => this.handleShow()}
                          ></button>
                          <button
                            className="btn btn-sm hovbuttonColour shadow-sm float-right fa fa-file-pdf text-md"
                            data-tip
                            data-for="pdf"
                            // onClick={handlepdfDownload}
                          ></button>
                          {/* //<Tooltip id="mail" place="top" effect="solid">
                            Send Mail
                          </Tooltip>
                          //<Tooltip id="pdf" place="top" effect="solid">
                            Download pdf
                          </Tooltip> */}
                        </>
                      )}
                    </div>
                    <div className="card-body">
                      <table className="float-left m-0 mb-3 ">
                        <tbody>
                          <tr>
                            <td>Indenting Id</td>
                            <td>:&nbsp; {data.id}</td>
                          </tr>
                        
                          {/* <tr>
                            <td>JobOrder Id</td>
                            <td>:&nbsp; {joid}</td>
                          </tr> */}
                          <tr>
                            <td>Store</td>
                            <td>:&nbsp; {data.store}</td>
                          </tr>
                          <tr>
                            <td>CreatedBy</td>
                            <td>:&nbsp; {data.createdby}</td>
                          </tr>
                          <tr>
                            <td>Date</td>
                            <td>
                              :&nbsp;{" "}
                              {moment(data.startdate).format("DD-MM-YYYY")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <TableContainer>
                        <Typography> Items List</Typography>
                        <Table className="table-sm">
                          <TableHead>
                            <TableRow>
                              <TableCell className="text-center">
                                Itemname
                              </TableCell>
                              <TableCell className="text-center">
                                Quantity
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {data.indentingitems.map((e, index) => (
                              <TableRow key={index}>
                                <TableCell className="text-center">
                                  {e.itemname}
                                </TableCell>
                                <TableCell className="text-center">
                                  {e.quantity}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
        <Footer/>
      </div>
    );
  };
  
  export default withRouter(IndentingDetails);
  