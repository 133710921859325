import axios from 'axios'
import { Auth_Proto, Auth_Domain, Auth_Port } from '../Component/consts'

export let currentTenant
class PurchaseService {

  // Get Data of All Purchases 
  GetAllPurchases(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getallpurchasedata`,data)
  }

  // Get Po by items
  GetPObyItems(currentTenant) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpobyitems/${currentTenant}`)
  }

   // Get Data of Completed Purchase orders 
  GetCompletedPO(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpaymentcompletedpo`,data)
  }

  GetCancelledPO(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getcancelledpo`,data)
  }

  // Get Data of All Purchases TO View
  GetPurchases(id) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpurchaseorder/${id}`)
  }

  GetPurchaseOrderForScn(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpurchaseorderforscn/${id}`)
  }

  //Get Sellers list
  GetAllSellers(currentTenant) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/findsuppliers/${currentTenant}`)

  }

  //Get item data
  GetItemlist(currentTenant) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getitemidname/${currentTenant}`)
  }

  //Get UOMList
  GetUOM() {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/inventory/getuom`)
  }

  //Add PurchaseOrder(Save)
  NewOrder(neworder) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/addpurchaseitems`, neworder)
  }

  //Confrim PO(Confirm)
  ConfirmOrders(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/confirmpurchaseitems`, data)
  }

  //PO Status 
  statusData(statusdata) {
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/po/orderstatus`, statusdata)
  }

  //PO Returns
  GetPurchaseReturns(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getporbytenant`,data)
  }

  //GRN returns
  GetGrnReturns(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getgrnreturns`,data)
  }

  GetallPurchaseOrderOfseller(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpoofsellerid`,data)
  }

  GetPOofsellerforGrnReturns(data) { 
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpoofselleridforgrnreturns`,data)
  }

  GetPurchaseDataOfOrder(id) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpodata/${id}`)
  }

  GetPurchaseReturnsItems(id) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getporeturn/${id}`)
  }

  GetGrnReturnItems(id) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getgrnreturnitem/${id}`)
  }

  NewPurchaseReturnOrder(datalist) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/addporitem`, datalist)
  }

  getApprovedGrn(currentTenant) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getgrn/noledgerentry/${currentTenant}`)
    // return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getgrn/noledgerentry2/${currentTenant}`)
    
  }

  getAdvancePayments(currentTenant) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getadvansepayment/${currentTenant}`)
  }
  // GetInvoiceData(currentTenant) {
  //   return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/purchaseordersofseller/${currentTenant}`)
  // }

  UploadInvoicepdf(uploaddata) {
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/uploadinvoice`, uploaddata)
  }

  UploadFinalInvoice(uploaddata) {
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/uploadfinalinvoice`, uploaddata)
  }

  DeleteProformaInvoice(id) {
    return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/deleteproformainvoice/${id}`)
  }
  // upload po to send mail
  SendPOmail(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/sendpomail`, data)
  }
  // get GRN Id
  Getgrnid(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getgrnidlist`,data)
  }

  //grn returns
  GRNreturns(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/addgrnreturnitem`, data)
  }

  GetRecurringduration() {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getrecurringtype`)
  }

  GetContrctType() {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getcontracttype`)
  }

  retrivepoidofservice(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getservicesofsup`,data)
  }

  retriveAllSCN(currentTenant) {
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpendingscn/${currentTenant}`)
  }
  addscn(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/addscn`, data)
  }

  UploadContractFile(data) {
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/uploadcontract`, data)
  }
  GetSCNdata(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getscn/${id}`)
  }
  retriveAllCompletedSCN(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getcompletedscn/${currentTenant}`)
  }
  getPurchaseReport(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpurchasereports`,data)
  }

  getPurchaseReturnsReport(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpurchasereturnreports/${id}`)
  }

  getGrnreportes(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getgrnreports`,data)
  }

  GetIdforbillofentry(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpoidforbillofentry/${currentTenant}`)
  }

  newBillofEntry(data) {
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/newbillofentry`, data)
  }

  Getbillofentry(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getboeoftenant/${currentTenant}`)
  }

  Getallfygrndata(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getallfygrndata`,data)
  }

    // Add Direct Purchase
    addDirectPurchase(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/adddirectpurchase`,data)
   }

   //get All Direct Purchase
   getAllDirectPurchase(currentTenant){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getdirectpurchases/${currentTenant}`) 
   }

   // Delete Direct Purchase

   DeleteDirectPurchase(id){
      return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/deletedirectpurchase/${id}`) 
   }
   getPurchaseRefunds(tenantId){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpurchasereturnsforrefund/${tenantId}`)
}

  getAllDebitNotes(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getdebitnotesofpurchase`,data)
  }

  AddAdvance(data){
    return axios.put(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/addadvance`,data)
  }

  GetAgentsCompletedOrders(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getagentscompletedpurchases`,data)
  }
  GetAgentsPendingOrders(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getpendingagentspurchases`,data)
  }

  POpendingsuppliers(id){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/popendingsuppliers/${id}`,)
  }
}


export default new PurchaseService();