import React, { useEffect, useState } from "react";
import withRouter from "../../withRouter";
import Menu from "../../Component/Menu";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { spin } from "../../Common/NewLoader";
import { Link } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import MUIDataTable from "mui-datatables";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import ProjectManagementServices from "../ProjectManagementServices";
import { currentTenant } from "../../Common/Credentials";
import moment from "moment";

const theme = createTheme({
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: "#e9ecef !important",
            border: [[1, 'solid', '#ece6e6']], 
        },
        body:
        {
          border: [[1, 'solid', '#ece6e6']],
        },
      },
    },
  });

const ProjectEstimation = () => {
    const [loading, setLoading] = useState(false);
    const [data,setData] = useState([])

    useEffect(()=>{
      setLoading(true)
      ProjectManagementServices.GetActiveEstimations(currentTenant).then((response)=>{
       setData(response.data)
       setLoading(false)
      }).catch(()=>setLoading(false))
    },[])
  
    const columns =[
      {name:"estimationId",label:"Estimation Id", options: { filter: true, sort: true } },
      {name:"projectName",label:"Project", options: { filter: true, sort: true } },
      {name:"customerName",label:"Customer", options: { filter: true, sort: true } },
      { name:"projectManagerName", label: "Project Manager", options: { filter: true, sort: true } },
      {name:"preparedBy",label:"Prepared By", options: { filter: true, sort: true } },
      { name: "createdAt", label: "Date", options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => {
          return moment(value).format("DD-MM-yyyy hh:mm");
        },
      }, },
      {
          name: "View",
          options: {
            filter: false,
            sort: false,
            empty: true,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <Link
                  as="button"
                  data-tip
                  data-for="edit"
                  className="fa fa-list-alt"
                  to={{
                    pathname: `/projectestimation/${tableMeta.rowData.find((e) => e)}`
                  }}
                ></Link>
              );
            },
          },
        },
    ]
    const options = {
      filterType: 'checkbox',
      selectableRows: "none",
      customToolbar: () => {
       return (
       <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/newprojectestimation"}} state ={{ id: -1, pagename: 'New Project Estimation'  }}>
         <CustomToolbar />
       </Link>
       );
     },
     onDownload: (buildHead, buildBody, columns, data) => {
      return "\uFEFF" + buildHead(columns) + buildBody(data);
    },
    downloadOptions: {
      filename: "Projects",
      separator: ",",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    }
    return (
      <div>
        <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card card-info">
            <ol className="breadcrumb float-sm-right">
              <li className="breadcrumb-item text-secondary">
                <Link to="/projectmanagement">Home</Link>
              </li>
              <li className="breadcrumb-item active">Project Estimation</li>
            </ol>
            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body">
                        <div>
                          {loading === true ? (
                            <div className="text-center">{spin}</div>
                          ) : (
                            <div className="form-group col-md-12">
                                 <ThemeProvider theme={theme}> 
                          <MUIDataTable
                                className="table-sm px-3"
                                title={"Project Estimation"}
                                data={data}
                                columns={columns}
                                options={options}
                              />
                              </ThemeProvider>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
  
        <Footer />
      </div>
    );
}

export default withRouter(ProjectEstimation)