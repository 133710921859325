import { Skeleton, Stack, Typography } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ReceiptIcon from "@mui/icons-material/Receipt";
// import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import React from "react";
import { styled } from "@mui/material/styles";
import { NumericFormat } from "react-number-format";
import { currency, thousandsdisplay } from "../../../Common/Credentials";

const Item = styled("div")(({ theme }) => ({
  color: "#022533",
  marginBottom: 16,
  width: "25%",
  [theme.breakpoints.down("lg")]: {
    width: "25%",
  },
  [theme.breakpoints.down("md")]: {
    width: "33.3%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "50%",
  },
  padding: theme.spacing(0, 2),
}));

const RenderPurchaseData = ({ activeChart, orders, invoice, loader }) => {
  return (
    <>
      <Stack
        direction={"row"}
        flexWrap={"wrap"}
        sx={{ p: (theme) => theme.spacing(0, 1, 0) }}
      >
        {activeChart === "Orders"
          ? orders.map((item, key) => (
              <Item key={key}>
                <Typography variant={"body1"} fontSize={"1rem"} mb={0}>
                  {item.name}
                </Typography>
                {loader ? (
                  <>
                    <Skeleton
                      variant="rounded"
                      width={150}
                      height={30}
                      sx={{ marginBottom: 0.5 }}
                    />
                    <Skeleton variant="rounded" width={150} height={20} />
                  </>
                ) : (
                  <>
                    <Typography
                      variant={"h2"}
                      fontSize={"2rem"}
                      fontFamily={"Montserrat-Bold"}
                      mb={0}
                    >
                      {/* <ReceiptIcon fontSize={"inherit"} sx={{verticalAlign: "middle", mr: 1, mt: -.5 }}/> */}
                      {item.order}
                    </Typography>
                    <Typography
                      variant={"h5"}
                      fontSize={"1rem"}
                      fontFamily={"Montserrat-Bold"}
                      mb={0}
                    >
                      {/* <AttachMoneyIcon
                        fontSize={"inherit"}
                        sx={{ verticalAlign: "middle", mt: -0.5 }}
                      /> */}
                      <span>{currency}</span>
                      <NumericFormat
                        className="pl-1"
                        displayType={"text"}
                        value={item.amount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        disabled={true}
                        thousandSeparator={true}
                        thousandsGroupStyle={thousandsdisplay}
                      ></NumericFormat>
                    </Typography>
                  </>
                )}
              </Item>
            ))
          : invoice.map((item, key) => (
              <Item key={key}>
                <Typography variant={"body1"} fontSize={"1rem"} mb={0}>
                  {item.name}
                </Typography>
                {loader ? (
                  <>
                    <Skeleton
                      variant="rounded"
                      width={150}
                      height={30}
                      sx={{ marginBottom: 0.5 }}
                    />
                    <Skeleton variant="rounded" width={150} height={20} />
                  </>
                ) : (
                  <>
                    <Typography
                      variant={"h2"}
                      fontSize={"2rem"}
                      fontFamily={"Montserrat-Bold"}
                      mb={0}
                    >
                      {/* <ReceiptIcon fontSize={"inherit"} sx={{verticalAlign: "middle", mr: 1, mt: -.5}}/> */}
                      {item.order}
                    </Typography>
                    <Typography
                      variant={"h5"}
                      fontSize={"1rem"}
                      fontFamily={"Montserrat-Bold"}
                      mb={0}
                    >
                      {/* <AttachMoneyIcon
                        fontSize={"inherit"}
                        sx={{ verticalAlign: "middle", mt: -0.5 }}
                      /> */}
                      <span>{currency}</span>
                      <NumericFormat
                      className="pl-1"
                        displayType={"text"}
                        value={item.amount}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        disabled={true}
                        thousandSeparator={true}
                        thousandsGroupStyle={thousandsdisplay}
                      ></NumericFormat>
                    </Typography>
                  </>
               )}
              </Item>
            ))}
      </Stack>
    </>
  );
};
export default RenderPurchaseData;
