import { Field, Form, Formik } from "formik";
import React, { Component } from "react";

import { Link } from "react-router-dom";
import withRouter from "../../withRouter.js";
import { userid, currentTenant, userwhid, dataaccess, financialyearstart, financialyearend } from "../../Common/Credentials.js";
import Footer from "../../Component/Footer.js";
import Header from "../../Component/Header.js";
import Menu from "../../Component/Menu.js";
import { FormikTextField } from '../../CommonComponents/FormField.js';
import PurchaseServices from "../PurchseServices";
import { Autocomplete,Backdrop,Checkbox,CircularProgress,FormControlLabel,FormLabel } from "@mui/material";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import GRNServices from "../GRN/GRNServices.js";
import { Spinner } from 'react-bootstrap';
import SettingsServices from "../../Settings/SettingsServices.js";

let datalist = [];
let count = 0;
class NewPurchaseReturn extends Component {
  constructor(props) {
    super(props);
    console.log(this.props,props)
    const returntype = this.props.location.state.returntype;
    const pagename = this.props.location.state.pagename
    this.state = {
      sellersdata: [],
      purchaseorderid: "",
      purchaseitems: [],
      purchasedata: [],
      grnidlist:[],
      seller: "",
      sellerid: "",
      iid: "",
      grnid:'',
      itemid: "",
      itemname: "",
      categoryid:'',
      hsn: "",
      quantity: "",
      pendingquantity: 0,
      orderedquantity:'',
      tax: "",
      uom:"",
      taxamount: "",
      grandtotal: "",
      status: "0",
      refund:true,
      description: "",
      returnlist: [],
      returntype:returntype,
      pagename:pagename,
      updatedby:'',
      loader:false,
      invuploadm:''
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.validate = this.validate.bind(this);
  }

  handleSeller = (value,values) => {
    if(values!==null && typeof values === 'object'  && values!==undefined){
    this.setState({
      sellerid: values.id,
      seller: values.companyname,
      purchaseorderid: "",
      purchasedata: [],
      purchaseitems: [],
      returnlist: [],
    });
    datalist = [];
    count = 0;
    const id = values.id;
    let datadao = {
      tenantid: currentTenant,
      startdate: financialyearstart,
      enddate: financialyearend,
      userid:userid,
      dataaccess: dataaccess,
      whid:userwhid,
      id:id
    }
    if(this.state.pagename === "New Purchase Returns"){
    PurchaseServices.GetallPurchaseOrderOfseller(datadao).then((res) =>
      this.setState({
        purchasedata: res.data,
      })
    )
    .catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
}else{
  PurchaseServices.GetPOofsellerforGrnReturns(datadao).then((res) =>
  this.setState({
    purchasedata: res.data,
  })
)
.catch((e)=>{
  if(e.response.data.error ==="Unauthorized"){
      alert("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
  }
})
}
    }else{
      this.setState({
      sellerid:'',
      seller: '',
      purchaseorderid: "",
      purchasedata: [],
      purchaseitems: [],
      returnlist: [],
      })
    }
}

  handlePurchaseOrder = (value,values) => {
    if(values !==null && values !== undefined && values !=='' && this.state.purchasedata.includes(values) ){
    this.setState({
      purchaseorderid: values,
      purchaseitems: [],
      returnlist: [],
    });
    datalist = [];
    count = 0;
    const id = values;
    if(this.state.returntype ===1){
      let datadao = {
        tenantid: currentTenant,
        startdate: financialyearstart,
        enddate: financialyearend,
        userid:userid,
        dataaccess: dataaccess,
        whid:userwhid,
        id:id
      }
      PurchaseServices.Getgrnid(datadao).then(res=>
        this.setState({grnidlist:res.data})
        ).catch((e)=>{
          if(e.response.data.error ==="Unauthorized"){
              alert("Your Session is Lost: Please login Back to resume");
              this.props.navigate(`/`);
          }
      })
    }else{
    PurchaseServices.GetPurchaseDataOfOrder(id).then((response) =>
      this.setState({
        purchaseitems: response.data.purchaseItemDTO,
        grandtotal: response.data.grandtotal,
      })
    ).catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
    }
   }else{
    this.setState({
      purchaseorderid: '',
      purchaseitems: [],
      returnlist: [],
    });
    datalist = [];
    count = 0;
   }
  }

  handlegrnOrder=(value,values)=>{
    if(values !== '' && values !== null && values !== undefined && this.state.grnidlist.includes(values)){
    this.setState({
      grnid: values,
      purchaseitems: [],
      returnlist: [],
    });
    datalist = [];
    count = 0;
    GRNServices.retriveGRNItems(values).then((response) => {
      this.setState({
        purchaseitems:response.data.grnitemslist
      });
    }).catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }else{
    this.setState({
      grnid: value,
      purchaseitems: [],
      returnlist: [],
    });
    datalist = [];
    count = 0;
  }
  }

  handleReturn = (id) => {
    let itemsdetails = this.state.purchaseitems.filter(
      (data) => data.id === id
    );

    this.setState({ quantity: "", description: "" });
    itemsdetails.map(data =>
      this.setState({
        updatedby:userid,
        iid: data.id,
        categoryid:data.categoryid,
        itemid: data.itemid,
        itemname: data.name,
        cost: data.price,
        hsn: data.hsn,
        tax: data.tax,
        uom:data.uom,
        createdby: userid,
        taxamount: data.taxamount,
        pendingquantity:this.state.returntype===0? data.pending:data.accepted,
        orderedquantity:data.quantity,
        totalprice:data.totalprice,
      })
    )
  };

  handleSelect = (e) => {
    this.setState({ status: e.target.value });
  };

  handleReturnType =(e)=>{
    this.setState({returntype:e.target.value})
  }

  onSubmit() {
    if (this.state.quantity === "0") {
    } else {
      count = count + 1;

      let list = {
        updatedby:userid,
        poid: this.state.purchaseorderid,
        grnid:this.state.grnid,
        id:this.state.id,
        iid: this.state.iid,
        itemid: this.state.itemid,
        name: this.state.itemname,
        hsn: this.state.hsn,
        uom:this.state.uom,
        categoryid:this.state.categoryid,
        quantity: parseFloat(this.state.quantity),
        purchaseprice: this.state.cost,
        tax: this.state.tax,
        exchangereturn: parseInt(this.state.status),
        refund:this.state.refund,
        description: this.state.description,
        sellerid: this.state.sellerid,
        seller: this.state.seller,
        tennatid: currentTenant,
        taxamount: (parseFloat(this.state.quantity)* this.state.cost) * (this.state.tax/100),
        totalprice: (parseFloat(this.state.quantity) * this.state.cost) + ((parseFloat(this.state.quantity)* this.state.cost) * (this.state.tax/100)),
      };
      datalist.push(list);

      let data = { 
        updatedby:userid,
        iid: this.state.iid,
        itemid: this.state.itemid,
        name: this.state.itemname,
        hsn: this.state.hsn,
        price: this.state.cost,
        tax: this.state.tax,
        uom:this.state.uom,
        categoryid:this.state.categoryid,
        taxamount:this.state.taxamount,
        quantity: this.state.orderedquantity,
        pending: this.state.pendingquantity - this.state.quantity,
        accepted: this.state.pendingquantity - this.state.quantity,
        totalprice: this.state.totalprice,
      }
        if(this.state.pendingquantity-this.state.quantity === 0){
          this.setState({purchaseitems:this.state.purchaseitems.filter((data) => data.id !== this.state.iid)})
        }else{
          this.setState({purchaseitems:this.state.purchaseitems.filter((data) => data.id !== this.state.iid)},
          ()=>this.state.purchaseitems.push(data)
          ) 
        } 
        this.setState({ returnlist: datalist,  pendingquantity: this.state.pendingquantity - this.state.quantity ,quantity: "", description: "",itemid:'' });
      }
    }

  removeItemFromList(id) {
    const deleteitem = datalist.filter((item, index) => item.iid === id);
      let delquantity =  deleteitem.map((data) =>data.quantity)

    const newlist = datalist.filter((item, index) => item.iid !== id);
    this.setState({
      returnlist: newlist,
    });
    datalist = newlist;

    let itemsdetails = this.state.purchaseitems.find((data) => data.iid === id);
    let deletedata={
      updatedby:userid,
      id: itemsdetails.iid,
      itemid:itemsdetails.itemid,
      name: itemsdetails.name,
      hsn: itemsdetails.hsn,
      price: itemsdetails.price,
      uom: itemsdetails.uom,
      categoryid: itemsdetails.categoryid,
      tax: itemsdetails.tax,
      taxamount:itemsdetails.taxamount,
      quantity: itemsdetails.quantity,
      pending: itemsdetails.pending + parseFloat(delquantity),
      accepted:itemsdetails.pending + parseFloat(delquantity),
      totalprice: itemsdetails.totalprice
            }
         let remlist=this.state.purchaseitems.filter((data) => data.iid !==id)
            remlist.push(deletedata)
            this.setState({purchaseitems:remlist})
  }

  handelSubmit = () => {
    this.setState({loader:true})
    if(this.state.returntype === 0){
    PurchaseServices.NewPurchaseReturnOrder(datalist).then(() =>
      this.props.navigate(`/purchasereturns`,{state:{message:'',returntype:'Purchase Returns'}})
    ).catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
      this.setState({loader:false})
  })
    }else{
      PurchaseServices.GRNreturns(datalist).then(() =>
      this.props.navigate(`/purchasereturns`,{state:{message:'',returntype:'GRN Item Returns'}})
      ).catch((e)=>{
        if(e.response.data.error ==="Unauthorized"){
            alert("Your Session is Lost: Please login Back to resume");
            this.props.navigate(`/`);
        }
        this.setState({loader:false})
    })
    }
  };
  refreshData() {
    PurchaseServices.GetAllSellers(currentTenant).then((res) =>
      this.setState({
        sellersdata: res.data,
      })
    ).catch((e)=>{
      if(e.response.data.error ==="Unauthorized"){
          alert("Your Session is Lost: Please login Back to resume");
          this.props.navigate(`/`);
      }
  })
  }

  componentDidMount() {
    if (userid === undefined) {
      window.confirm("Your Session is Lost: Please login Back to resume");
      this.props.navigate(`/`);
    }
    SettingsServices.GetSettings(currentTenant).then((response) => this.setState({invuploadm: response.data.invuploadm}))

    this.refreshData();
    datalist = [];
    count = 0;
  }

  validate(values) {
    let errors = {};
    if (
      this.state.quantity === "" ||
      this.state.quantity === undefined ||
      this.state.quantity === "0"
    ) {
      errors.quantity = "Quantity is required";
    } else if (this.state.quantity > this.state.pendingquantity) {
      errors.quantity = `Avilable quantity is ${this.state.pendingquantity}`;
    }
    if (this.state.description === "") {
      errors.description = "Pelese provide description";
    }
    return errors;
  }

  onfocus(){
    document.getElementById('quantity').addEventListener('mousewheel', function(event) {
     if (window.document.activeElement.type === 'number') {
       event.preventDefault()
     }
   })
   document.getElementById('quantity').addEventListener('keydown', function(e) {
    if (e.which === 38 || e.which === 40) {
        e.preventDefault();
    }
  });
   }


  render() {
    let {
      sellersdata,
      purchasedata,
      purchaseorderid,
      quantity,
      itemname,
      seller,
      sellerid,
      itemid,
      status,
      description,
      returntype,
      grnidlist,
      grnid,
    } = this.state;
    return (
      <div>
         <Header />
        <Menu />
        <div className="content-wrapper">
          <div className="card">
            <ol className="breadcrumb float-sm-right">
            <Link as="button" to={{ pathname: "/purchasereturns"}} state={{message:'',returntype:this.state.returntype===0?"Purchase Returns":"GRN Item Returns"}} className="text-secondary"> 
                ❮ Back &nbsp;&nbsp;
              </Link>
           
              <li className="breadcrumb-item text-secondary">
                <Link to="/purchase">Home</Link>
              </li>
              <li className="breadcrumb-item text-secondary">
                <Link to={{pathname: "/purchasereturns"}} state={{message:'',returntype:this.state.returntype===0?"Purchase Returns":"GRN Item Returns"}}>{this.state.pagename}</Link>
              </li>
              <li className="breadcrumb-item active">{this.state.pagename}</li>
            </ol>

            <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state.loader}
          // onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
            <div className="card-body">
              <Formik
                initialValues={{
                  sellersdata,
                  purchasedata,
                  purchaseorderid,
                  seller,
                  sellerid,
                  itemid,
                  itemname,
                  quantity,
                  status,
                  description,
                  returntype,
                  grnidlist,
                  grnid
                }}
                onSubmit={this.onSubmit}
                validateOnChange={false}
                validate={this.validate}
                enableReinitialize={true}
              >
                {({ setFieldValue, values }) => (
                  <Form>
                    <div className="form-row">
                      <fieldset hidden className="form-group col-md-4">
                        <label>Id</label>
                        <Field
                          className="form-control"
                          type="text"
                          name="itemid"
                          placeholder="Id"
                        />
                      </fieldset>
{/* 
                      {this.state.purchaseorderid ===''?
                      <fieldset className="form-group col-md-12 m-0">
                            <div className=" from-row ">
                              <div className="col-sm-12">
                                <div className="form-check form-check-inline">
                                  <spam className="radio-inline">
                                    <Field
                                      name="returntype"
                                      value="0"
                                      id="active"
                                      clicked={this.state.returntype}
                                      onClick={this.handleReturnType}
                                      className="mr-2 leading-tight"
                                      type="radio"
                                    />
                                    Purchase item
                                  </spam>
                                </div>

                                <div className="form-check form-check-inline">
                                  <spam className="radio-inline">
                                    <Field
                                      name="returntype"
                                      id="inactive"
                                      value="1"
                                      clicked={this.state.returntype}
                                      onClick={this.handleReturnType}
                                      className=" mr-2 leading-tight "
                                      type="radio"
                                    />
                                    GRN/Accepted Goods
                                  </spam>
                                </div>
                              </div>
                            </div>
                          </fieldset>:''} */}

                      <fieldset className="form-group col-md-3">
                        <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="seller"
                          options={sellersdata}
                          getOptionLabel={(option) => option.companyname}
                          value={values.seller ? undefined : undefined}
                          onChange={ this.handleSeller}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="seller"
                              label="Select  Supplier"
                              variant="standard"
                              fullWidth
                            />
                          )}
                        />
                      </fieldset>
                      {this.state.seller === "" ? (
                        ""
                      ) : (<>
                        <fieldset className="form-group col-md-3">
                          <Autocomplete
                            id="subaccount"
                            className="subacc-select"
                            name="seller"
                            options={purchasedata}
                            getOptionLabel={(option) => option}
                            
                            
                            value={values.purchaseorder ? undefined : undefined}
                            onChange={ this.handlePurchaseOrder}
                            renderInput={(params) => (
                              <FormikTextField
                                {...params}
                                className="form-control"
                                type="text"
                                name="purchaseorder"
                                label="Select  PurchaseOrder"
                                variant="standard"
                                fullWidth
                              />
                            )}
                          />
                        </fieldset>
                         {this.state.returntype ===1?
                        <fieldset className="form-group col-md-3">
                          <Autocomplete
                          id="subaccount"
                          className="subacc-select"
                          name="seller"
                          options={grnidlist}
                          getOptionLabel={(option) => option}
                          
                          
                          value={values.grnorder ? undefined : undefined}
                          onChange={this.handlegrnOrder}
                          renderInput={(params) => (
                            <FormikTextField
                              {...params}
                              className="form-control"
                              type="text"
                              name="grnorder"
                              label="Select GRN ID"
                              variant="standard"
                              fullWidth
                            />
                          )}
                          />
                          </fieldset>:""}
                       </>
                      )}
                      <div className="form-group col-md-4" />
                      {this.state.purchaseorderid === "" || this.state.purchaseitems.length === 0 ? (
                        ""
                      ) : (
                          <TableContainer>
                          <Table className="table-sm">
                            <TableHead>
                              <TableRow>
                                <TableCell className="text-center">Item Name</TableCell>
                                {/* <TableCell>HSN</TableCell> */}
                                <TableCell className="text-right">Price</TableCell>
                                <TableCell className="text-center">Quantity</TableCell>
                                <TableCell className="text-center">Pending Quantity</TableCell>
                                <TableCell className="text-center">Tax(%)</TableCell>
                                <TableCell className="text-right">Tax Amount</TableCell>
                                <TableCell className="text-right">Total Price</TableCell>
                                <TableCell className="text-center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.purchaseitems.map((data, index) => (
                                <TableRow key={index}>
                                  <TableCell className="text-center">{data.name}</TableCell>
                                  {/* <TableCell>{data.hsn}</TableCell> */}
                                  <TableCell className="text-right">{data.price}</TableCell>
                                  <TableCell className="text-center">{data.quantity}</TableCell>
                                  <TableCell className="text-center">{this.state.returntype===0?data.pending:data.accepted}</TableCell>
                                  <TableCell className="text-center">{data.tax}</TableCell>
                                  <TableCell className="text-right">{data.taxamount}</TableCell>
                                  <TableCell className="text-right">{data.totalprice}</TableCell>
                                  <TableCell className="text-center">
                                    <span
                                      as="button"
                                      data-tip
                                      data-for="return"
                                      className="text-info cursorPointer"
                                      style={{ paddingLeft: "7px" }}
                                      onClick={() => this.handleReturn(data.id)}
                                    >
                                      Return/Exchange
                                    </span>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          </TableContainer>
                       
                      )}
                      
                      {this.state.itemid === "" ? (
                        ""
                      ) : (
                        
                        <>
                       
                       <u className="form-group col-md-12 mt-3">
                            <FormLabel style={{display:'flex',alignItems:'center'}} > Return or Exchange Order Details</FormLabel>
                          </u>

                            <FormLabel style={{display:'flex',alignItems:'center'}} >Itemname : &nbsp; {this.state.itemname} &nbsp;&nbsp;&nbsp; </FormLabel>
                            <FormControlLabel control={<Checkbox checked={this.state.status==="0"} onChange={()=>this.setState({status:"0"})}/>} label="Return" />
                          <FormControlLabel control={<Checkbox checked={this.state.status==="1"} onChange={()=>this.setState({status:"1"})}/>} label="Exchange" />

                          
                             <fieldset className="form-group col-md-12 m-0"></fieldset>
                     {this.state.status==="0" && this.state.pagename==="New GRN Returns"?
                              < >
                              <FormLabel style={{display:'flex',alignItems:'center'}} >Amount should be :&nbsp;&nbsp;&nbsp; </FormLabel>
                                <FormControlLabel control={<Checkbox checked={this.state.refund===true} onChange={()=>this.setState({refund:true})}/>} label="Refund" />
                                <FormControlLabel control={<Checkbox checked={this.state.refund===false} onChange={()=>this.setState({refund:false})}/>} label="Adjust amount in next Order" />
                              </>:''
                            }
                          <div className="form-group col-md-12 m-0" />

                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              id="quantity"
                              className="form-control"
                              type="number"
                              name="quantity"
                              label="Quantity"
                              onChange={(e) =>this.setState({ quantity: e.target.value })}
                              onFocus={this.onfocus}
                            />
                          </fieldset>

                          <fieldset className="form-group col-md-3">
                            <FormikTextField
                              className="form-control"
                              type="textarea"
                              name="description"
                              label="Description"
                              onChange={(e) =>
                                this.setState({ description: e.target.value })
                              }
                            />
                          </fieldset>

                          <div className="form-group col-md-12 my-2" />

                          <button
                            className="btn btn-sm hovbuttonColour"
                            type="onSubmit"
                          > 
                            {this.state.status === "0"
                              ?"Return Item"
                              :"Exchange Item"}
                          </button>
                        </>
                      )}

                      {count === 0 || this.state.returnlist.length === 0 ? (
                        ""
                      ) : (<>
                        <hr size="30"/>
                        <u className="form-group col-md-12 mt-3">
                        Return or Exchange Order Details
                        </u>
                        <TableContainer>
                          <Table className="table-sm">
                            <TableHead>
                              <TableRow>
                                <TableCell className="text-center">Item Name</TableCell>
                                {/* <TableCell className="text-center">HSN</TableCell> */}
                                <TableCell className="text-right">Price</TableCell>
                                <TableCell className="text-center">Quantity</TableCell>
                                <TableCell className="text-center">Tax(%)</TableCell>
                                <TableCell className="text-right">Tax Amount</TableCell> 
                                <TableCell className="text-right">Total Price</TableCell>
                                <TableCell className="text-center">Exchange or Return</TableCell>
                                <TableCell className="text-center">Description</TableCell>
                                <TableCell className="text-center">Refund</TableCell>
                                <TableCell className="text-center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.returnlist.map((data, index) => (
                                <TableRow key={index}>
                                  <TableCell className="text-center">{data.name}</TableCell>
                                  {/* <TableCell className="text-center">{data.hsn}</TableCell> */}
                                  <TableCell className="text-right">{data.purchaseprice}</TableCell>
                                  <TableCell className="text-center">{data.quantity}</TableCell>
                                  <TableCell className="text-center">{data.tax}</TableCell>
                                  <TableCell className="text-right">
                                    {data.taxamount === "" ? 0 : data.taxamount}
                                  </TableCell>
                                  <TableCell className="text-right">{data.totalprice}</TableCell>
                                  <TableCell className="text-center">
                                    {data.exchangereturn === 1
                                      ? "Exchange"
                                      : "Return"}
                                  </TableCell>
                                  <TableCell className="text-center">{data.description}</TableCell>
                                  <TableCell className="text-center">{data.refund?"Refund":"Adjust"}</TableCell>
                                  <TableCell className="text-center">
                                    <span
                                      as="button"
                                      data-tip
                                      data-for="delete"
                                      className="fa fa-trash text-danger"
                                      style={{ paddingLeft: "7px" }}
                                      onClick={() =>
                                        this.removeItemFromList(data.iid)
                                      }
                                    />
                                    {/* <Tooltip
                                      id="delete"
                                      place="top"
                                      effect="solid"
                                    >
                                      Delete
                                    </Tooltip> */}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          </TableContainer>
                          </>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="form-group col-md-12" />
              {count === 0 || this.state.returnlist.length ===0 ? (
                ""
              ) : (
                <button
                  className="btn btn-sm hovbuttonColour"
                  onClick={this.handelSubmit}
                  disabled={this.state.loader===true}
                >
                {this.state.loader===false?<><i className="fa fa-save"/> Submit</>:<><Spinner animation="border" size="sm" />&nbsp;{"Submitting..."}</>}
                </button>
              )}
              <Link as="button" to={{ pathname: "/purchasereturns"}} state={{message:'',returntype:this.state.returntype===0?"Purchase Returns":"GRN Item Returns"}} 
              className="btn btn-sm ml-2 deletebutton"> 
              <b> X </b> Cancel 
              </Link>
            </div>
          </div>
        </div>

        {/* /.card-header */}
        {/* form start */}

        <Footer />
      </div>
    );
  }
}

export default withRouter(NewPurchaseReturn);
