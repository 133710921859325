import React, { useEffect } from "react";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Link } from "react-router-dom";
import withRouter from "../withRouter.js";
import Footer from "../Component/Footer.js";
import Menu from "../Component/Menu.js";
import Header from "../Component/Header.js";
import { screens } from "../Common/Products.js";
import Credentials from "../Common/Credentials.js";
import mastersIcom from "../images/Icons/generic.png"
import tenantsIcom from "../images/Icons/user-account.png"
import AccountsIcon from "../images/Icons/accounting.png"
const SuperadminMenu = () => {

  useEffect(() =>{
    Credentials.setSelectedMenu("Tenants")
  },[])

  return (
    <div>
      <Header />
      <Menu />
      <section className="content-wrapper">
        <ol className="breadcrumb mb-0">
          <li className="breadcrumb-item text-secondary">
            <Link to="/index">Home</Link>
          </li>
          <li className="breadcrumb-item active">Super Admin Masters</li>
        </ol>
        <div className="h-max border-slate-300 bg-white p-4 rounded-lg ">
          {/* <h4 className="text-lg mt-8 font-weight-bold">Super Admin Masters</h4> */}
          {/* <ul className="d-flex flex-row pl-4">
              <li className="p-1 mr-4">
                <Link
                  to={{ pathname: "/index" }}
                  state={{ message: "" }}
                  className="textColour py-1"
                >
                  Dashboard
                </Link>
              </li>
            </ul> */}
          <div className="row gap-x-8 gap-y-6 mt-2">
          
            <div className="col-sm-12 col-md-6 col-lg-4 w-full">
              <span className="font-weight-bold my-3">
                {/* <i className="nav-icon fas fas fa-th textColour" /> */}
                <img className="nav-icon" width="25" src={tenantsIcom}/>
                <span className="pl-2">Tenants</span>
              </span>
              <ul className="menuul  pl-4 ml-3">
                <li>
                  <Link
                    className="textColour p-1"
                    to={{pathname: "/tenants"}}
                    state={{ message: ""}}
                  >
                    Tenants
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-full col-sm-12 col-md-6 col-lg-4">
              <span className="font-weight-bold my-3">
                {/* <TextSnippetIcon sx={{ fontSize: 18 }} />{" "} */}
                <img className="nav-icon" width="25" src={mastersIcom}/>
                <span className="pl-2">Masters</span>
              </span>
              <ul className=" menuul ml-3 pl-4">
              <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/screens" }}
                    state={{ message: "" }}
                  >
                    Screens
                  </Link>
                </li> 
                <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/products" }}
                    state={{ message: "" }}
                  >
                    Products
                  </Link>
                  </li>
                <li >
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/countries" }}
                    state={{ message: "" }}
                  >
                    Countries
                  </Link>
                </li>
                <li >
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/states" }}
                    state={{ message: "" }}
                  >
                    States
                  </Link>
                </li>
                <li >
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/cities" }}
                    state={{ message: "" }}
                  >
                    Cities
                  </Link>
                </li>
                <li >
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/hsn" }}
                    state={{ message: "" }}
                  >
                    HSN
                  </Link>
                </li>
              </ul>
            </div>
            {screens.includes("Accounts Masters") ?
            <div className="w-full col-sm-12 col-md-6 col-lg-4">
              <span className="font-weight-bold my-3">
                {/* <TextSnippetIcon sx={{ fontSize: 18 }} />{" "} */}
                <img className="nav-icon" width="20" src={AccountsIcon}/>
                <span className="pl-2">Accounts Masters</span>
              </span>
              <ul className="menuul ml-3 pl-4">
              <li>
                  <Link
                    className="textColour p-1"
                    to={{ pathname: "/subacc" }}
                    state={{ message: "" }}
                  >
                    Ledger Group
                  </Link>
                </li> 
              </ul>
            </div>:""}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default withRouter(SuperadminMenu);
