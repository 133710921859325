import axios from 'axios'
import {Auth_Proto,Auth_Domain,Auth_Port} from '../../Component/consts'

export let currentTenant

class BrandsServices{
    getBrands(currentTenant) {
        return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/getallbrands/${currentTenant}`)
     }
     addBrand(data){
        return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/addbrand`,data)
     }
     Uploadbrands(data){
      return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/uploadbrands`,data)
   }
     deleteBrand(id) {
        return axios.delete(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/appmasters/deletebrand/${id}`)
     }
}
export default new BrandsServices()