import React, { useEffect } from "react";
import Header from "../Component/Header";
import Menu from "../Component/Menu";
import Footer from "../Component/Footer";
import { Link } from "react-router-dom";
import { useState } from "react";
import Credentials from "../Common/Credentials";
import transactionsIcon from "../images/Icons/transaction.png";
const ToursAndTravells = () => {
  const [selected, setSelected] = useState();
  useEffect(() => {
    Credentials.setSelectedMenu("Salestravel");
  }, []);

  return (
    <>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/index">Home</Link>
            </li>
            <li className="breadcrumb-item active">Sales Travellers </li>
          </ol>
          <div className="col layout-main-section-wrapper">
            <div>
              <div className="card-body row">
                <div className="w-full col-sm-12 col-md-6 col-lg-4">
                  <span className="font-weight-bold my-3">
                    {/* <TextSnippetIcon sx={{ fontSize: 18 }} />{" "} */}
                    <img
                      className="nav-icon"
                      width="25"
                      src={transactionsIcon}
                    />
                    <span className="pl-2"> Transactions</span>
                  </span>
                  <ul className=" menuul pl-4 ml-3">
                    <li>
                      <Link
                        className="textColour pl-1"
                        to={{ pathname: "/salestravelinvoice" }}
                      >
                        Invoice
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className=" col-md-4 col-sm-12">
                  <ul>
                    <h5 className="ml-2">Service Master</h5>
                    <span>
                      <ul className="d-flex-row">
                        <li>
                          <Link className="text-dark " to="/mastercarlist">
                            Car
                          </Link>
                        </li>
                        <li>
                          <Link className="text-dark" to="/hotelMasterList">
                            Hotel
                          </Link>
                        </li>
                        <li>
                          <Link className="text-dark" to="/hotelMastertaxList">
                            HotelTax
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="text-dark"
                            to="/hotelMasterchainlist"
                          >
                            Hotel Chain
                          </Link>
                        </li>
                        <li>
                          <Link className="text-dark" to="/hotelroomtypelist">
                            Hotel Room Type{" "}
                          </Link>
                        </li>
                        <li>
                          <Link className="text-dark" to="/hotelmealsplanlist">
                            Hotel Meals Plans
                          </Link>
                        </li>
                      </ul>
                    </span>
                  </ul>
                </div>
                <div className=" col-md-4 col-sm-12">
                  <ul>
                    <h5 className="ml-2">Travel Master</h5>
                    <span>
                      <ul className="d-flex-row">
                        <li>
                          <Link className="text-dark " to="/masterairlinelist">
                            Airline
                          </Link>
                        </li>
                        <li>
                          <Link className="text-dark " to="/masterairportlist">
                            Airport
                          </Link>
                        </li>
                      </ul>
                    </span>
                  </ul>
                </div>
                <div className=" col-md-4 col-sm-12">
                  <ul>
                    <h5 className="ml-2">Partner Master</h5>
                    <span>
                      <ul className="d-flex-row">
                        <li>
                          <Link className="text-dark " to="/masterpartnerlist">
                            Partner
                          </Link>
                        </li>
                      </ul>
                    </span>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ToursAndTravells;
