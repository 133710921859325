import React from "react";
import withRouter from "../../withRouter";
import Header from "../../Component/Header";
import Menu from "../../Component/Menu";
import Footer from "../../Component/Footer";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { Link } from "react-router-dom";
import CustomToolbar from "../../CommonComponents/CustomToolbar";
import { productionid } from "../../Common/Permissions";
import { useEffect } from "react";
import { currentTenant, userid } from "../../Common/Credentials";
import ProductionServices from "../ProductionServices";
import { spin } from "../../Common/NewLoader";
import { Table,TableHead, TableBody, TableCell, TableRow, FormControlLabel } from "@mui/material";
import IOSSwitch from "../../CommonComponents/IOSSwitch";

const ProductionTemplate = () => {

  const [templates,setTemplates] = useState([])
  const [loading,setLoading] = useState()

  useEffect(() => {
    Refresh()
  }, [])

  const Refresh = () =>{
    setLoading(true)
    ProductionServices.getallproductiontemplates(currentTenant).then(response=>{
      setTemplates(response.data)
      setLoading(false)
    })
  }

  const handleActiveStatusChange = (val) => {
    let data={
      itemid:val,
      userid:userid,
    }
    ProductionServices.UpdateTemplateStatus(data).then(()=>Refresh())
  }

  const handleDelete = (id)=>{
    ProductionServices.DeleteTemplate(id).then(()=>Refresh())
  }
   


  const columns = [
    {name:"id",label:"Id",options: {filter: false,sort: false,display: false,viewColumns: false}},
    {name: 'templatename',label:"Template Name",options: {filter: true,sort: true,}},
    {name: 'category',label:"Category",options: {filter: true,sort: true}},
    {name: 'itemname',label:"Output Product",options: {filter: true,sort: true}},
    {name: 'uom',label:"UOM",options: {filter: true,sort: true}},
    {name: 'productiontemplaterawmaterials',label:"Raw Materials",options: {filter: true,sort: true,
      customBodyRender:(value,tableMeta)=>{
        return(
          <>
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Item/Product</TableCell>
                <TableCell>Quantity</TableCell>
                <TableCell>UOM</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {value.map(e=>
              <TableRow>
              <TableCell>{e.category}</TableCell>
              <TableCell>{e.itemname}</TableCell>
              <TableCell>{e.quantity}</TableCell>
              <TableCell>{e.uom}</TableCell>
               </TableRow>
              )}
            </TableBody>
          </Table>
          </>
        )
      }}},
      {name: 'status',label:"Status",options: {filter: true,sort: true,}},
      {name: 'inuse',label:"Action",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return(
            value==="False"?<span className="fa fa-trash cursorPointer text-danger" onClick={()=>handleDelete(tableMeta.rowData.find(e=>e))}></span>:
            <FormControlLabel control={<IOSSwitch className="sm" checked={tableMeta.rowData[6]==="Active"} onChange={()=>handleActiveStatusChange(tableMeta.rowData.find(e=>e))} name="checkedB" />}/>
            )}
      }},
  ]

  const options = {
    filterType: 'checkbox',
    selectableRows: "none",
    customToolbar: () => {
      return (<>
        {productionid === '2' || productionid === '3'?
          <Link as="button" className="float-right btn btn-sm m-0 p-0" to={{pathname: "/newproductiontemplate"}} state ={ { id: -1, pagename:  "New Production Template"}}>
            <CustomToolbar />
          </Link>
          :''
        }  
      </>);
   },
  }

  return (
    <div>
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card ">
          <ol className="breadcrumb float-sm-right">
            <li className="breadcrumb-item text-secondary">
              <Link to="/production">Home</Link>
            </li>
            <li className="breadcrumb-item active">Production Template</li>
          </ol>
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div>
                      {loading === true ? (
                        <span className="text-center">{spin}</span>
                      ) : (
                        <MUIDataTable
                        className="table-sm px-3"
                        title={"Production Templates"}
                        data={templates}
                        columns={columns}
                        options={options}
                      />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(ProductionTemplate);
