import axios from 'axios'
import { Auth_Proto, Auth_Domain, Auth_Port } from '../../Component/consts';

export let currentTenant

class AdminDashboardServices {

  // Get Data of All GRN 

  blockedpurchasequantity(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/blockedpurchasequantity/${currentTenant}`)
  }

  blockedsalesquantity(currentTenant){
    return axios.get(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/blockedsalesquantity/${currentTenant}`)
  }

  LastYearsales(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/sales/getfyso`,data)
  }

  LastYearPurchase(data){
    return axios.post(`${Auth_Proto}${Auth_Domain}${Auth_Port}/api/purchase/getfypo`,data)
  }

}
export default new AdminDashboardServices();